import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_PRESET } from '../config/pickers/';
import { REPORT_PRESET_PARAM_NAME } from '../routes';
import { REPORT_ID } from './useSelectedReportId';

export const useReportPresetName = queryParamHookFactory(REPORT_PRESET_PARAM_NAME, {
    defaultValue: () => REPORT_PRESET.DEFAULT,
    persist: PERSISTENCE_TYPE.LOCAL_STORAGE,
    dependencies: {
        queryParams: [REPORT_ID],
    },
});
