import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useBidStrategyFormField = () => {
    const isMaxConversionsEnabled = useConfigMatch({ [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: 'true' });
    return useFormFieldValue(
        {
            field: 'bidStrategy',
        },
        { isMaxConversionsEnabled }
    );
};
