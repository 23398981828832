import React from 'react';
import classnames from 'classnames/bind';
import { ErrorOutlinedIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import styles from './tooltipMessageIndication.module.scss';

const classNameBuilder = classnames.bind(styles);

export const TooltipMessageIndication = ({ type, children }) => {
    return (
        <div className={styles['indication-wrapper']}>
            <ErrorOutlinedIcon className={classNameBuilder('indication-icon', type)} aria-label="indication icon" />
            <Tooltip position={TOOLTIP_POSITION.TOP_RIGHT} arrow>
                {children}
            </Tooltip>
        </div>
    );
};
