"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgBudget(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "evenodd",
            d: "M3.62 12a8.38 8.38 0 1 1 16.76 0 8.38 8.38 0 0 1-16.76 0zm1.663 0a6.717 6.717 0 1 0 13.434 0 6.717 6.717 0 0 0-13.434 0zm4.65 2.181c0 .763.926 1.33 1.683 1.461.059-2.063.014-3.136-.134-3.22-1.256-.281-2.737-1.51-2.737-2.712 0-.716.27-1.525.86-2.037.487-.435 1.185-.686 1.877-.788.025-.256.262-.493.518-.493.282 0 .459.237.485.493 1.563.205 2.77 1.443 2.77 2.825 0 .282-.276.304-.557.304-.282 0-.468-.022-.468-.304 0-.819-.413-1.498-1.387-1.677v3.517c.692.128 1.053.31 1.643.872.743.743.912 1.635.528 2.557-.41.997-1.443 1.861-2.597 2.015-.025.255-.16.57-.417.57-.282 0-.358-.315-.384-.57-1.563-.205-2.87-1.399-2.87-2.78 0-.282.23-.512.512-.512.282 0 .675.198.675.48zm4.066.15c-.078.517-.879 1.16-1.266 1.257v-2.895c.682.118 1.104.33 1.266.638.114.218.039.74 0 1zm-3.98-4.819c0-.375.045-.88.352-1.118.342-.267 1.086-.33 1.245-.36v3.379c-.363-.091-.942-.437-1.245-.737-.215-.212-.352-.732-.352-1.164z"
        })
    );
}
exports.default = SvgBudget;