import React from 'react';
import { isNil } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { requestFetchAccount, fetchAccountError, fetchAccountSuccess } from '../actions';
import { isIframeMode } from '../modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { accountsApi } from '../services/api';
import { transformAccountFromGW } from '../services/transformers';

export const createFetchAccountErrorIndication = values => ({
    message: (
        <FormattedMessage
            id="account.fetch.error"
            values={values}
            defaultMessage="Currently you do not have permission to access this account ({accountId}), or it does not exist."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
});

export const MissedAccountIdError = new Error('Missed account id.');

export const fetchAccount = selectedAccountId => async dispatch => {
    dispatch(requestFetchAccount());
    try {
        if (isIframeMode() && (isNil(selectedAccountId) || selectedAccountId === '')) {
            throw MissedAccountIdError;
        }

        const response = await accountsApi.getAccountById(selectedAccountId);
        const selectedAccount = transformAccountFromGW(response);

        dispatch(fetchAccountSuccess(selectedAccount));

        return selectedAccount;
    } catch (error) {
        dispatch(fetchAccountError(!selectedAccountId || isIframeMode() ? error : undefined));
        throw error;
    }
};
