/**
 * Created by oreuveni
 * Date: 2019-05-16
 * Time: 11:23
 */
import {
    REQUEST_UPLOAD_CREATIVE_THUMBNAIL,
    UPLOAD_CREATIVE_THUMBNAIL_SUCCESS,
    UPLOAD_CREATIVE_THUMBNAIL_ERROR,
} from './actionTypes';

export const requestUploadCreativeThumbnail = () => ({ type: REQUEST_UPLOAD_CREATIVE_THUMBNAIL });
export const uploadCreativeThumbnailSuccess = () => ({ type: UPLOAD_CREATIVE_THUMBNAIL_SUCCESS });
export const uploadCreativeThumbnailError = () => ({ type: UPLOAD_CREATIVE_THUMBNAIL_ERROR });
