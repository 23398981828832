import React from 'react';
import { CardIcon, InvoiceIcon } from 'taboola-ultimate-ui';
import { BILLING_AND_PAYMENTS_ROUTE, INVOICES_AND_PAYMENTS_ROUTE } from '../../config';
import BillingNavigationItem from './BillingNavigationItem';
import styles from './billingSidebarContainer.module.scss';

export const BillingSidebarContainer = () => (
    <div className={styles['header-wrapper']}>
        <BillingNavigationItem
            messageId="navigation.caption.billing-management"
            defaultMessage="Billing Management"
            Icon={CardIcon}
            path={BILLING_AND_PAYMENTS_ROUTE}
        />
        <BillingNavigationItem
            messageId="navigation.caption.invoices-and-payments"
            defaultMessage="Invoices and Payments"
            Icon={InvoiceIcon}
            path={INVOICES_AND_PAYMENTS_ROUTE}
        />
    </div>
);
