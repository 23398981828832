import React from 'react';
import classnames from 'classnames/bind';
import {
    OkIcon,
    CompletionCircle25Icon,
    CompletionCircle33Icon,
    CompletionCircle50Icon,
    CompletionCircle66Icon,
    CompletionCircle75Icon,
} from 'taboola-ultimate-ui';
import styles from './completionStatus.module.scss';

const classNameBuilder = classnames.bind(styles);

const validProgressPercentages = {
    25: <CompletionCircle25Icon className={styles['progress-icon']} />,
    33: <CompletionCircle33Icon className={styles['progress-icon']} />,
    50: <CompletionCircle50Icon className={styles['progress-icon']} />,
    66: <CompletionCircle66Icon className={styles['progress-icon']} />,
    75: <CompletionCircle75Icon className={styles['progress-icon']} />,
    100: <OkIcon className={classNameBuilder('progress-icon')} />,
};

const getValidProgress = progress => {
    let currentValidProgress = 0;
    for (const nextValidProgress in validProgressPercentages) {
        if (progress < nextValidProgress) {
            return currentValidProgress;
        }
        currentValidProgress = nextValidProgress;
    }
    return 100;
};

export const CompletionStatus = ({ progress, isSelected, isCollapsed }) => {
    const currentValidProgress = getValidProgress(progress);
    return (
        <div className={classNameBuilder('container', { selected: isSelected }, { collapsed: isCollapsed })}>
            {validProgressPercentages[currentValidProgress] || (
                <OkIcon className={classNameBuilder('progress-icon', 'incomplete')} />
            )}
        </div>
    );
};
