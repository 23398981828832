import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import {
    TooltipV2 as Tooltip,
    AlertOIcon,
    INDICATION_TYPES,
    OkOIcon,
    BlockIcon,
    DeletableItem,
} from 'taboola-ultimate-ui';
import TargetingTypes from '../../../config/TargetingTypes';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import SegmentListItemSizeTooltip from '../SegmentListItemSizeTooltip/SegmentListItemSizeTooltip';
import SegmentListItemTooltip from '../SegmentListItemTooltip/SegmentListItemTooltip';
import { getMinSizeAndMsgPostfix, getSizeContentRenderer } from '../utils/MarketplaceAudiencesUtils';
import commonStyles from '../../commonAudienceListItemContent.module.scss';
import styles from './segmentListItemContent.module.scss';

const SegmentListItemContent = ({ item, targetingType, deleteItem, searchText, indicationType, message }) => {
    const { size, provider, label, loading } = item;
    const handleOnClick = () => {
        deleteItem(item);
    };

    if (loading) {
        return (
            <ContentLoader width={580} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="12" rx="8" ry="8" width="250" height="12" />
                <rect x="510" y="12" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    const [minSize, sizeIdPostfix] = getMinSizeAndMsgPostfix(label);

    return (
        <DeletableItem onDelete={handleOnClick} aria-label={`Removable ${label}`}>
            <div className={commonStyles['container']}>
                <div>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={commonStyles['include']} />
                    ) : (
                        <BlockIcon className={commonStyles['exclude']} />
                    )}
                </div>
                <AudienceDetails
                    name={label}
                    detail={provider}
                    minSize={minSize}
                    size={size}
                    showRemoveButton={false}
                    highLightText={searchText}
                    tooltip={<SegmentListItemTooltip item={item} />}
                    renderSizeContent={getSizeContentRenderer}
                    sizeTooltip={<SegmentListItemSizeTooltip sizeMsgIdPostfix={sizeIdPostfix} />}
                    sizeIdPostfix={sizeIdPostfix}
                />
                {!loading && indicationType && (
                    <span className={commonStyles['error-icon-wrapper']} aria-label={`${label} ${indicationType} icon`}>
                        <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                        <Tooltip delay={800}>{message}</Tooltip>
                    </span>
                )}
            </div>
        </DeletableItem>
    );
};

SegmentListItemContent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    targetingType: PropTypes.string,
    deleteItem: PropTypes.func,
    showRemoveButton: PropTypes.bool,
    searchText: PropTypes.string,
    indicationType: PropTypes.oneOf(Object.values(INDICATION_TYPES)),
    message: PropTypes.node,
};

export default SegmentListItemContent;
