import React, { useMemo } from 'react';
import { BlockIcon, DeletableItem, Highlighter, OkOIcon } from 'taboola-ultimate-ui';
import TargetingTypes from '../../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import styles from '../../../../../campaigns/modules/common-campaign-form/components/commonAudienceListItemContent.module.scss';

export const MailDomainListItem = ({ item, deleteItem, searchText, rejectedKeywords }) => {
    const { targetingType, label, value } = item;

    const isRejected = useMemo(() => {
        return label && rejectedKeywords && rejectedKeywords.includes(label.toLowerCase());
    }, [label, rejectedKeywords]);

    const handleDelete = () => {
        deleteItem(value, targetingType);
    };

    return (
        <div className={styles['wrapper']}>
            <div className={styles['container']}>
                <span>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={styles['include']} />
                    ) : (
                        <BlockIcon className={styles['exclude']} />
                    )}
                </span>
                <DeletableItem
                    onDelete={handleDelete}
                    isItemDeletable={true}
                    className={isRejected ? styles['rejected-item'] : ''}
                >
                    <Highlighter
                        text={label}
                        highlightedText={searchText}
                        highlightedTextClassName={styles['highlight']}
                    />
                </DeletableItem>
            </div>
        </div>
    );
};
