import React from 'react';
import { isPricingModelCpmOrVcpm } from 'modules/campaigns/config/pricingModel';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BidField } from '../Bid';
import { BidTooltip } from '../Bid/BidTooltip';
import { BidAdjustmentsSection } from '../BidAdjustmentsSection/BidAdjustmentsSection';
import { BidStrategyV2 } from '../BidStrategyV2/BidStrategyV2';
import { useHighImpactPackageIndication } from '../BidStrategyV2/hooks/useHighImpactPackageIndication';
import { useShouldRenderBidStrategyV2 } from '../BidStrategyV2/hooks/useShouldRenderBidStrategyV2';
import { CampaignBudget } from '../Budget/CampaignBudget';
import { BudgetSectionFrame } from '../BudgetSectionFrame';
import CpaGoal from '../CpaGoal/CpaGoal';
import CreativeOptimization from '../CreativeOptimization/CreativeOptimization';
import ProfitMargin from '../ProfitMargin/ProfitMargin';
import { SiteExplorationField } from '../SiteExploration/SiteExplorationField';
import { SpendGuardField } from '../SpendGuard/SpendGuardField';
import { UserFrequencyCappingField } from '../UserFrequencyCapping';
import { useCampaignBudgetRestrictions } from './hooks/useCampaignBudgetRestrictions';

const BudgetSectionSponsored = ({ onlyBudget } = {}) => {
    const {
        formAccount: { currency },
    } = useFormDataContext();
    const { value: pricingModel } = useFormFieldValue({ field: 'pricingModel' });
    const { value: targetCpaData } = useFormFieldValue({ field: 'targetCpaData' });
    const isTargetCpaEnabled = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' });
    const isSupplyExplorationEnabled = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_EXPLORATION_ENABLED]: 'true' });
    const isSpendGuardActiveOnAccountLevel = useConfigMatch({
        [FEATURE_FLAGS.SAFETY_NETS_ENABLED_CONFIG_ATTRIBUTE]: 'true',
    });
    const supplyExplorationPermission = usePermissions('SUPPLY_EXPLORATION');
    const shouldRenderSpendGuardActivation = usePermissions('SAFETY_NETS_VIEW') && isSpendGuardActiveOnAccountLevel;
    const isAllowedFrequencyCapping = useConfigMatch({
        [FEATURE_FLAGS.FREQUENCY_CAPPING_ENABLED_CONFIG_ATTRIBUTE]: 'true',
    });
    const shouldRenderFrequencyCapping = usePermissions('FREQUENCY_CAPPING') && isAllowedFrequencyCapping;

    const shouldRenderCpaGoal = !targetCpaData && !isTargetCpaEnabled;
    const shouldRenderSupplyExploration = isSupplyExplorationEnabled && supplyExplorationPermission;
    const hasHighImpactPackage = useHighImpactPackageIndication();
    const shouldRenderBidStrategyV2 = useShouldRenderBidStrategyV2();
    const budgetRestrictions = useCampaignBudgetRestrictions();

    if (onlyBudget) {
        return (
            <BudgetSectionFrame>
                {shouldRenderBidStrategyV2 && <BidStrategyV2 />}
                <CampaignBudget budgetRestrictions={budgetRestrictions} />
            </BudgetSectionFrame>
        );
    }

    return (
        <BudgetSectionFrame>
            {shouldRenderBidStrategyV2 && <BidStrategyV2 />}
            <CampaignBudget budgetRestrictions={budgetRestrictions} />
            {!shouldRenderBidStrategyV2 && (
                <BidField
                    label={<FormattedMessage id="campaign.editor.bid.title" defaultMessage="Bid" />}
                    description={
                        <>
                            <FormattedMessage
                                id="campaign.editor.bid.description"
                                defaultMessage="Select delivery method and bid amount for your campaign."
                            />
                            {hasHighImpactPackage ? (
                                <>
                                    <br />
                                    <FormattedMessage
                                        id="campaign.editor.bid.highImpactException"
                                        defaultMessage="When choosing a High Impact Package the pricing model must be vCPM."
                                    />
                                </>
                            ) : null}
                        </>
                    }
                    helpText={<BidTooltip hasHighImpactPackage={hasHighImpactPackage} />}
                    hasHighImpactPackage={hasHighImpactPackage}
                />
            )}
            <ProfitMargin />
            {!isPricingModelCpmOrVcpm(pricingModel) && (
                <>
                    <CreativeOptimization />
                    {shouldRenderCpaGoal && <CpaGoal currency={currency} />}
                </>
            )}
            {shouldRenderSupplyExploration && <SiteExplorationField />}
            {shouldRenderSpendGuardActivation && <SpendGuardField />}
            <BidAdjustmentsSection />
            {isPricingModelCpmOrVcpm(pricingModel) && shouldRenderFrequencyCapping && <UserFrequencyCappingField />}
        </BudgetSectionFrame>
    );
};

export default BudgetSectionSponsored;
