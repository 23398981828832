'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.isnil');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.noop');

var _lodash4 = _interopRequireDefault(_lodash3);

var _Icons = require('../../Icons/Icons');

var _SortDown = require('../../Icons/Icons/SortDown');

var _SortDown2 = _interopRequireDefault(_SortDown);

var _SortUp = require('../../Icons/Icons/SortUp');

var _SortUp2 = _interopRequireDefault(_SortUp);

var _Filter = require('../../Icons/Icons/Filter');

var _Filter2 = _interopRequireDefault(_Filter);

var _DataGridBase = require('../DataGridBase');

var _ColumnMenu = require('../ColumnMenu/ColumnMenu');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var TaboolaCustomHeader = function (_Component) {
    _inherits(TaboolaCustomHeader, _Component);

    function TaboolaCustomHeader(props) {
        _classCallCheck(this, TaboolaCustomHeader);

        var _this = _possibleConstructorReturn(this, (TaboolaCustomHeader.__proto__ || Object.getPrototypeOf(TaboolaCustomHeader)).call(this, props));

        _this.handleSort = function (e) {
            e.stopPropagation();
            var progressSort = _this.props.progressSort;


            progressSort(e.shiftKey);
        };

        _this.handleSortChanged = function () {
            _this.setState({});
        };

        _this.toggleColumnMenu = function () {
            return _this.setState(function (_ref) {
                var columnMenuOpen = _ref.columnMenuOpen;
                return { columnMenuOpen: !columnMenuOpen };
            });
        };

        _this.hideColumnMenu = function () {
            _this.setState({ columnMenuOpen: false });
        };

        _this.renderSortingSection = function () {
            var column = _this.props.column;

            var sortOrder = column.getSort();
            switch (sortOrder) {
                case _DataGridBase.SORTING_ORDER_TYPES.ASCENDANT:
                    return _react2.default.createElement(_SortUp2.default, { onClick: _this.handleSort, className: sortOrder });
                case _DataGridBase.SORTING_ORDER_TYPES.DESCENDANT:
                    return _react2.default.createElement(_SortDown2.default, { onClick: _this.handleSort, className: sortOrder });
                default:
                    return null;
            }
        };

        _this.state = { columnMenuOpen: false };
        return _this;
    }

    _createClass(TaboolaCustomHeader, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var api = this.props.api;


            api.addEventListener('sortChanged', this.handleSortChanged);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            var api = this.props.api;


            api.removeEventListener('sortChanged', this.handleSortChanged);
        }
    }, {
        key: 'getSortOrderValue',
        value: function getSortOrderValue() {
            var _props = this.props,
                columnApi = _props.columnApi,
                sort = _props.column.sort;
            var sortIndex = this.props.column.sortIndex;

            var columnState = columnApi.getColumnState();
            var sortedColumnList = columnState.filter(function (_ref2) {
                var order = _ref2.sortIndex;
                return !(0, _lodash2.default)(order);
            });

            if (sortedColumnList.length <= 1 || (0, _lodash2.default)(sort)) {
                return '';
            }

            return sortIndex + 1;
        }
    }, {
        key: 'renderHeader',
        value: function renderHeader() {
            var _props2 = this.props,
                displayName = _props2.displayName,
                enableSorting = _props2.enableSorting,
                column = _props2.column,
                getContainerProps = _props2.getContainerProps;

            var sortProps = enableSorting ? { onClick: this.handleSort, role: 'columnheader' } : {};
            var _column$colDef = column.colDef,
                filter = _column$colDef.filter,
                _column$colDef$suppre = _column$colDef.suppressMovable,
                suppressMovable = _column$colDef$suppre === undefined ? false : _column$colDef$suppre;
            var columnMenuOpen = this.state.columnMenuOpen;


            return _react2.default.createElement(
                'div',
                Object.assign({
                    className: 'ag-cell-label-container',
                    role: 'presentation'
                }, getContainerProps(this.props), {
                    onMouseLeave: this.hideColumnMenu
                }),
                _react2.default.createElement(
                    'span',
                    { className: 'ag-icon-container' },
                    !suppressMovable ? _react2.default.createElement(_Icons.DragAndDropIcon, { className: 'drag-and-drop-icon' }) : null
                ),
                _react2.default.createElement(
                    'div',
                    Object.assign({ className: 'ag-header-cell-label' }, sortProps),
                    _react2.default.createElement(
                        'span',
                        { className: 'ag-icon-container' },
                        enableSorting ? this.renderSortingSection() : null
                    ),
                    _react2.default.createElement(
                        'span',
                        Object.assign({ className: 'ag-sort-order' }, sortProps),
                        this.getSortOrderValue()
                    ),
                    _react2.default.createElement(
                        'span',
                        Object.assign({ className: 'ag-header-cell-text' }, sortProps),
                        displayName
                    ),
                    filter ? _react2.default.createElement(_Filter2.default, null) : null
                ),
                _react2.default.createElement(
                    'span',
                    { className: 'ag-header-icon ag-header-cell-menu-button' },
                    _react2.default.createElement(_ColumnMenu.ColumnMenu, Object.assign({}, this.props, { open: columnMenuOpen, toggleColumnMenu: this.toggleColumnMenu, suppressMovable: suppressMovable }))
                )
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var _props3 = this.props,
                context = _props3.context,
                disableLoading = _props3.disableLoading;
            var LoadingCellRenderer = context.LoadingCellRenderer,
                headerLoading = context.headerLoading;


            return headerLoading && !disableLoading && LoadingCellRenderer ? _react2.default.createElement(LoadingCellRenderer, null) : this.renderHeader();
        }
    }]);

    return TaboolaCustomHeader;
}(_react.Component);

TaboolaCustomHeader.propTypes = {
    /** the display name */
    displayName: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** the current column definition */
    column: _propTypes2.default.object,
    /** is column enabled sorting */
    enableSorting: _propTypes2.default.bool,
    /** column sorting api */
    progressSort: _propTypes2.default.func,
    /** grid api */
    api: _propTypes2.default.object,
    /** column api */
    columnApi: _propTypes2.default.object,
    /** grid context */
    context: _propTypes2.default.object,
    /** disable loading */
    disableLoading: _propTypes2.default.bool,
    /** spreads the resulting object into the containing div */
    getContainerProps: _propTypes2.default.func
};

TaboolaCustomHeader.defaultProps = {
    context: {},
    getContainerProps: _lodash4.default
};

exports.default = TaboolaCustomHeader;