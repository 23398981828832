import { AddIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addConditionButton.module.scss';

export const AddConditionButton = ({ onClick, index }) => {
    return (
        <div role="button" className={styles['container']} onClick={onClick}>
            <AddIcon />
            <FormattedMessage
                id="app.create.performance.rules.add.condition"
                defaultMessage="Add Condition ({index}/5)"
                values={{ index }}
            />
        </div>
    );
};
