'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledCssGrid = require('styled-css-grid');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /**
                                                                                                                                                                                                                              * Created by oreuveni
                                                                                                                                                                                                                              * Date: 2019-01-01
                                                                                                                                                                                                                              * Time: 13:49
                                                                                                                                                                                                                              */


var GalleryItem = function GalleryItem(_ref) {
    var children = _ref.children,
        rest = _objectWithoutProperties(_ref, ['children']);

    return _react2.default.createElement(
        _styledCssGrid.Cell,
        rest,
        children
    );
};

GalleryItem.propTypes = {
    children: _propTypes2.default.node,
    height: _propTypes2.default.number,
    width: _propTypes2.default.number
};

GalleryItem.defaultProps = {
    height: 1,
    width: 1
};

exports.default = GalleryItem;