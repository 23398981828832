import React from 'react';
import PropTypes from 'prop-types';

const CountView = ({ data, countingRule }) => {
    const count = countingRule(data);

    return count ? <span>{` (${count})`}</span> : null;
};

CountView.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
    countingRule: PropTypes.func.isRequired,
};

CountView.defProps = {
    data: [],
};

export default CountView;
