import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import JSPixelCodeSnippet from './JSPixelCodeSnippet';
import styles from './pixelBasedEventJSTagField.module.scss';

export const PixelBasedEventJSTagField = ({ eventName, showTitle }) => (
    <FormField
        inputId="pixel-based-event-script-tag-description"
        label={
            showTitle && (
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.events.code.script.tag.label"
                    defaultMessage="Event Code Snippet"
                />
            )
        }
        description={
            <FormattedMessage
                id="audience.editor.pixel.based.audience.events.code.script.tag.description"
                defaultMessage="Add the event code below the '</head>' tag in case you wish to track the event on page load. Otherwise, if you wish to track the event for an inline action, attach the event code to the relevant HTML element. Please make sure your Taboola Pixel is installed on the page. Otherwise, your event will not be recorded."
            />
        }
        descriptionClass={styles['code-event-description']}
        subtext={
            <FormattedMessage
                id="audience.editor.pixel.based.audience.events.code.section.snippet.code.subDescription"
                defaultMessage="It may take up to 20 minutes for your pixel status to change after installing it. {br} You can always go to the Help Center to learn more about pixel management."
            />
        }
    >
        <JSPixelCodeSnippet eventName={eventName} />
    </FormField>
);

PixelBasedEventJSTagField.propTypes = {
    eventName: PropTypes.string,
    showTitle: PropTypes.bool,
};

PixelBasedEventJSTagField.defaultProps = {
    showTitle: true,
};

export default PixelBasedEventJSTagField;
