import React from 'react';
import PropTypes from 'prop-types';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withFormDataProvider';
import FormLayout from '../../components/Form/FormLayout';
import CampaignEditorFormBase from '../common-campaign-form/CampaignEditorFormBase';
import { pmpDealDuplicateValues } from '../common-campaign-form/config/campaignDuplicateValues';
import PmpDealEditorContent from './components/PmpDealEditorContent';

const PmpDealEditor = ({ onCancel }) => (
    <CampaignEditorFormBase onCancel={onCancel} ContentComponent={PmpDealEditorContent} />
);

PmpDealEditor.propTypes = { onCancel: PropTypes.func };

const PmpDealEditorWithFormDataProvider = withFormDataProvider(PmpDealEditor, {
    defaultValuesConfig: pmpDealDuplicateValues,
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { PmpDealEditorWithFormDataProvider as PmpDealEditor, PmpDealEditor as PmpDealEditorUnwrapped };
