const MAP_ENTRIES_DELIMITER = ',';
const MAP_KEY_VALUE_DELIMITER = ':';
const MAP_ARRAY_VALUE_DELIMITER = '|';

// e.g the config value: x:a|b,y:c,z:d|e|f is transformed to a map of a structure [[x,[a,b]],[y,[c]],[z,[d,e,f]]]
const configMapValueTransformer = configValue =>
    new Map(
        configValue
            ?.split(MAP_ENTRIES_DELIMITER)
            .map(entry => entry.split(MAP_KEY_VALUE_DELIMITER))
            ?.map(([key, value]) => [key, value?.split(MAP_ARRAY_VALUE_DELIMITER)])
    );

export { configMapValueTransformer };
