import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../i18n';
import LoadingOverlay from '../LoadingOverlay';
import { useFormLoadingOverlayLoader } from './useFormLoadingOverlayLoader';
import styles from '../../../../audiences/components/CodeSnippet/codeSnippet.scss';
import style from './FormLoadingOverlay.module.scss';

const classNameBuilder = classnames.bind(styles);

export const FormLoadingOverlay = ({ loadingOverlayText, className, loader: loaderProp }) => {
    const defaultLoader = useFormLoadingOverlayLoader();
    const Loader = loaderProp || defaultLoader;

    return (
        <LoadingOverlay className={classNameBuilder(style.formLoadingOverlayContainer, className)}>
            <Loader />
            {loadingOverlayText && <FormattedMessage id={loadingOverlayText} defaultMessage="LOADING ..." />}
        </LoadingOverlay>
    );
};

FormLoadingOverlay.propTypes = {
    /** Text to be displayed */
    loadingOverlayText: PropTypes.string,
};
