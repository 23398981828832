import React from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES } from '../../../config';
import CreativesABTestRadioButton from '../CreativesABTestRadioButton/CreativesABTestRadioButton';

const EvenOptimizationRadioButton = () => {
    const isCreativesABTestEnabled = useConfigMatch({ [FEATURE_FLAGS.IS_CREATIVES_AB_TEST_ENABLED]: 'true' });

    return !isCreativesABTestEnabled ? (
        <Radio
            key="creative-optimization-even"
            value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN}
            title={<FormattedMessage id="campaign.editor.creative.optimization.even" defaultMessage="Even" />}
            description={
                <FormattedMessage
                    id="campaign.editor.creative.optimization.even.description"
                    defaultMessage="Even - Ads will be exposed evenly, based on traffic to your campaign."
                />
            }
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN}
        />
    ) : (
        <CreativesABTestRadioButton />
    );
};

export default EvenOptimizationRadioButton;
