import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CollapsibleList, useDebouncedValue, useElementSize } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { useSendSegmentSearchEvent } from '../../../utils/useSendSegmentSearchEvent';
import {
    excludeErrorListValidations,
    excludeWarningListValidations,
    includeErrorListValidations,
    includeWarningListValidations,
} from '../../MarketplaceAudiences/MarketplaceAudienceValidations';
import { MarketplaceAudiencesProvider } from '../../MarketplaceAudiences/MarketplaceAudiencesContext';
import ProvidersDropdown from '../../MarketplaceAudiences/ProvidersDropdown/ProvidersDropDown';
import {
    useProvidersApi,
    useProvidersState,
    useMarketplaceAudiencesHiddenIdsSet,
    useSelectedSegmentsGroup,
} from '../../MarketplaceAudiences/hooks';
import {
    getTargetingDropdownProps,
    getTargetingTypeFieldName,
    getTargetingValuesFieldName,
} from '../../MarketplaceAudiences/utils/andLogicUtils';
import { TargetingTypeBareDropdown } from '../../TargetingTypeDropdown';
import { LoadingMode, MAX_SEGMENTS_TO_SELECT } from '../../Tree';
import { useBundleData } from '../../Tree/hooks/useBundleData';
import SegmentListItemContentWithBundling from '../SegmentListContentWithBundling/SegmentListItemContentWithBundling';
import { SegmentsDropdownWithBundling } from '../SegmentsDropdownWithBundling/SegmentsDropdownWithBundling';
import { useBundleNodeHandlersWithMetrics } from '../hooks/useBundleNodeHandlersWithMetrics';
import { useMarketplaceAudiencesBundleTreeState } from '../hooks/useMarketplaceAudiencesBundleTreeState';
import { useMarketplaceAudiencesWithBundlingEffects } from '../hooks/useMarketplaceAudiencesWithBundlingEffects';
import { useSegmentsWithBundlingApi } from '../hooks/useSegmentsWithBundlingApi';
import commonStyles from '../../commonEditor.module.scss';
import styles from '../MarketplaceAudiencesTargetingWithBundling/marketplaceAudiencesTargetingWithBundling.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
export const MarketplaceAudiencesTargetingGroupWithBundling = ({
    selectedAccountId,
    isAdvancedSearch,
    index,
    marketplaceAudienceSegmentTypeCount,
    setMarketplaceAudienceSegmentTypeByIndex,
    shouldDisableExcludeType,
}) => {
    const hiddenIdsSet = useMarketplaceAudiencesHiddenIdsSet(index);
    const { value: targetingType, onChange: setTargetingType } = useFormFieldValue({
        field: getTargetingTypeFieldName(index),
    });
    const isIncludeTargetingType = targetingType === TARGETING_TYPES.INCLUDE;

    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const [searchText, setSearchText] = useState('');
    const { unbundledNodes, unbundleNode, getIsNodeBundled } = useBundleData();
    const search = useDebouncedValue(searchText.length > 2 ? searchText : '');

    const idsFormFieldName = getTargetingValuesFieldName(index);

    const { providers, reloadProviders, isProvidersReady } = useProvidersApi({ selectedAccountId });
    const {
        selectedProviders,
        isProviderEnabled,
        getPartnerByProvider,
        enabledPartnerGroup,
        handleChangeSelectedProviders,
        handleChangeEnabledProviders,
        setDefaultEnabledProviders,
        enabledProvidersMap,
    } = useProvidersState({ providers });

    const { segments, fullSegments, ...treeStateParams } = useSegmentsWithBundlingApi({
        search,
        unbundledNodes,
        providers: selectedProviders,
        selectedAccountId,
        isAdvancedSearch,
    });

    const {
        selectedNodes,
        handleRemoveItem,
        handleReload,
        setSelectedNodesByIds,
        handleSelectNode,
        handleSelectAll,
        expandAll,
        setIsReady,
        ...treeProps
    } = useMarketplaceAudiencesBundleTreeState({
        search,
        nodes: segments,
        fullNodes: fullSegments,
        marketplaceAudienceSegmentTypeCount,
        setMarketplaceAudienceSegmentTypeByIndex,
        index,
        hiddenIdsSet,
        ...treeStateParams,
    });

    const { leaves, removeLeaves, failedValidationData, scrollRef } = useSelectedSegmentsGroup({
        selectedLeaves: selectedNodes,
        setSelectedLeavesByIds: setSelectedNodesByIds,
        idsFormFieldName,
        enabledProvidersMap,
        errorListValidations: isIncludeTargetingType ? includeErrorListValidations : excludeErrorListValidations,
        warningListValidations: isIncludeTargetingType ? includeWarningListValidations : excludeWarningListValidations,
        setIsReady,
        isReadyToValidate: treeProps.isReady && isProvidersReady,
    });

    useMarketplaceAudiencesWithBundlingEffects({
        handleReload,
        reloadProviders,
        handleChangeEnabledProviders,
        selectedNodes,
    });

    useSendSegmentSearchEvent({
        component: 'Marketplace Audiences Segments Search',
        searchText: search,
        resultsCount: segments.length,
        providers: selectedProviders,
        isLoading: treeProps.loadingMode !== LoadingMode.NONE,
    });

    const { handleSelectNodeWrapper, handleSelectAllWrapper } = useBundleNodeHandlersWithMetrics({
        handleSelectNode,
        handleSelectAll,
        search,
        selectedNodes,
        setDefaultEnabledProviders,
    });

    const { width, ref } = useElementSize();
    const menuStyles = useMemo(() => ({ width }), [width]);

    const targetingDropdownProps = getTargetingDropdownProps(shouldDisableExcludeType, targetingType);

    return (
        <div className={styles['container']} data-testid={`marketplace-audiences-targeting-group-${index}`}>
            <div className={styles['dropdown-container']} ref={ref}>
                <MarketplaceAudiencesProvider
                    limit={MAX_SEGMENTS_TO_SELECT}
                    handleSelectNode={handleSelectNodeWrapper}
                    handleSelectAll={handleSelectAllWrapper}
                    isProviderEnabled={isProviderEnabled}
                    getPartnerByProvider={getPartnerByProvider}
                    enabledPartnerGroup={enabledPartnerGroup}
                    campaignId={campaignId}
                    getIsNodeBundled={getIsNodeBundled}
                    unbundleNode={unbundleNode}
                    nodes={segments}
                    {...treeProps}
                >
                    <TargetingTypeBareDropdown
                        onChange={({ value }) => {
                            setTargetingType(value);
                        }}
                        value={targetingType}
                        {...targetingDropdownProps}
                    />
                    <div className={styles['delimiter']} />
                    <SegmentsDropdownWithBundling
                        search={searchText}
                        onSearchChange={setSearchText}
                        menuStyles={menuStyles}
                    />
                    <ProvidersDropdown
                        selectedValues={selectedProviders}
                        options={providers}
                        onChange={providerIds => handleChangeSelectedProviders(providerIds)}
                    />
                </MarketplaceAudiencesProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting"
                data-testid="marketplace-audiences-targeting-list"
                items={leaves}
                ItemComponent={SegmentListItemContentWithBundling}
                itemHeight={70}
                metadata={{
                    targetingType,
                    field: idsFormFieldName,
                    enabledProvidersMap,
                    isProvidersReady,
                }}
                listHeaderTitle={
                    isIncludeTargetingType ? (
                        <FormattedMessage
                            id="campaign.editor.marketplace.exclude.audiences.marketplace.include"
                            defaultMessage="<b>Target</b> audiences with at least the following contexts"
                        />
                    ) : (
                        <FormattedMessage
                            id="campaign.editor.marketplace.exclude.audiences.marketplace.exclude"
                            defaultMessage="<b>Exclude</b> audiences with at least the following contexts"
                        />
                    )
                }
                deleteItem={handleRemoveItem}
                clearItems={removeLeaves}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...failedValidationData}
                indicationContainerClass={commonStyles['warning-indication']}
                ref={scrollRef}
            />
        </div>
    );
};

MarketplaceAudiencesTargetingGroupWithBundling.propTypes = {
    selectedAccountId: PropTypes.string,
};
