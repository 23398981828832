import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { AUDIENCE_TYPE, CRM_FILE_TYPE, CRM_TARGETING_TYPE } from '../../config';
import { useAudienceTypeFormField } from '../../hooks/useAudienceTypeFormField';
import AudienceDescriptionField from '../AudienceDescriptionField/AudienceDescriptionField';
import BusinessOfExtendingCreditField from '../BusinessOfExtendingCreditField/BusinessOfExtendingCreditField';
import CreateAdditionalAudienceFromFileField from '../CreateAdditionalAudienceFromFileField/CreateAdditionalAudienceFromFileField';
import ExcludeAudienceField from '../ExcludeAudienceField/ExcludeAudienceField';

export const PredictiveAudiencePropertiesSection = () => {
    const { mode, value: type } = useAudienceTypeFormField();
    const { value: crmTargetingType } = useFormFieldValue({ field: 'crmTargetingType' });
    const { value: crmFileType } = useFormFieldValue({ field: 'crmFileType' });

    return (
        <CommonCollapsibleCard
            id="LOOKALIKE_AUDIENCE_PROPERTIES"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.properties.section.title"
                            defaultMessage="Audience Properties"
                        />
                    }
                />
            }
        >
            {type === AUDIENCE_TYPE.LOOKALIKE &&
                mode === FORM_MODES.CREATE &&
                crmFileType !== CRM_FILE_TYPE.ZIP_CODE && (
                    <CreateAdditionalAudienceFromFileField
                        msgId="audience.editor.predictive.audience.setup.checkbox.label"
                        defaultMessage="Create an additional targeting audience from file"
                        checkedValue={CRM_TARGETING_TYPE.ALL}
                        uncheckedValue={CRM_TARGETING_TYPE.LOOKALIKE}
                        headerMsgId="audience.editor.predictive.audience.setup.checkbox.header"
                        headerDefaultMessage="Additional Targeting Audience"
                    />
                )}
            {type === AUDIENCE_TYPE.LOOKALIKE && mode === FORM_MODES.CREATE && (
                <>
                    <BusinessOfExtendingCreditField />
                    <br />
                    {crmTargetingType === CRM_TARGETING_TYPE.ALL && <ExcludeAudienceField />}
                    <br />
                </>
            )}
            <AudienceDescriptionField />
        </CommonCollapsibleCard>
    );
};
