import encodeurl from 'encodeurl';
import { reduce } from 'lodash';
import { CREATIVE_TYPE } from '../../../config';
import { FIELDS, THUMBNAIL_URL_SUBFIELDS } from './index';

const calculateImagesChanges = (selectedFieldsMatrix, creative) => {
    return reduce(
        selectedFieldsMatrix,
        (result, value, key) => {
            if (!value) {
                return result;
            }
            if (key === FIELDS.THUMBNAIL_URL) {
                return {
                    ...result,
                    [FIELDS.THUMBNAIL_URL]: creative[FIELDS.THUMBNAIL_URL],
                    [THUMBNAIL_URL_SUBFIELDS.CREATIVE_FOCUS]: creative[THUMBNAIL_URL_SUBFIELDS.CREATIVE_FOCUS],
                    [THUMBNAIL_URL_SUBFIELDS.RECOMMENDED_IMAGE]: creative[THUMBNAIL_URL_SUBFIELDS.RECOMMENDED_IMAGE],
                };
            }
            return { ...result, [key]: creative[key] };
        },
        {}
    );
};

const calculateMotionsChanges = (selectedFieldsMatrix, creative) => {
    return reduce(
        selectedFieldsMatrix,
        (result, value, key) => {
            if (!value) {
                return result;
            }
            if (key === FIELDS.VIDEO_URL) {
                return {
                    ...result,
                    [FIELDS.VIDEO_URL]: creative[FIELDS.VIDEO_URL],
                    [THUMBNAIL_URL_SUBFIELDS.MEDIA_UPLOAD_SOURCE]:
                        creative[THUMBNAIL_URL_SUBFIELDS.MEDIA_UPLOAD_SOURCE],
                    [THUMBNAIL_URL_SUBFIELDS.MOTION_ADS_STUDIO]: creative[THUMBNAIL_URL_SUBFIELDS.MOTION_ADS_STUDIO],
                };
            }
            return { ...result, [key]: creative[key] };
        },
        {}
    );
};
export const calculateBulkChanges = (selectedFieldsMatrix, creative, creativeType) => {
    const calculateBulkChangesMap = {
        [CREATIVE_TYPE.IMAGE]: calculateImagesChanges,
        [CREATIVE_TYPE.MOTION]: calculateMotionsChanges,
    };
    const changes = calculateBulkChangesMap[creativeType](selectedFieldsMatrix, creative);
    const { url } = changes;
    if (url) {
        changes.url = encodeurl(url);
    }
    return changes;
};
