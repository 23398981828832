import React, { useMemo } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CampaignStatusPhaseService, PHASES } from '../../services/campaignStatusPhaseService';
import StatusTrackerPhaseTooltip from '../Tooltips/StatusTrackerPhaseTooltip';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

const hasAdditionalInfo = [PHASES.LEARNING_LIMITED, PHASES.EXPEDITED_LEARNING, PHASES.EXTENDED_LEARNING];
const hideTooltip = [
    PHASES.MISSING_ADS,
    PHASES.ADS_PENDING_REVIEW,
    PHASES.NO_APPROVED_ADS,
    PHASES.NO_ACTIVE_ADS,
    PHASES.NO_ADS,
];

const StatusTrackerRenderer = props => {
    const {
        data: { campaignStatusTrackerData = {} } = {},
        campaignStatusMessages,
        value: { status, isActive },
    } = props;

    const { phaseMsgIdPrefix } = campaignStatusMessages;

    const { phase, progress, progressBarColor, timeLeftProps } = useMemo(() => {
        const campaignStatusTrackerDataProps = new CampaignStatusPhaseService(
            campaignStatusTrackerData || {},
            status,
            !isActive
        );

        return campaignStatusTrackerDataProps.phaseProps;
    }, [campaignStatusTrackerData, status, isActive]);

    const { id, value } = timeLeftProps;
    const timeLeft = id ? (
        <FormattedMessage id={`app.campaigns.campaign.status.time.left.${id}`} values={{ value }} />
    ) : null;

    const { hasActivePixel = false, hasConversions = false } = campaignStatusTrackerData || {};
    const hidePhaseLabel = phase === PHASES.PENDING_REVIEW;

    const tooltipBody = phase ? (
        <StatusTrackerPhaseTooltip
            phase={phase}
            phaseMsgIdPrefix={phaseMsgIdPrefix}
            hasActivePixel={hasActivePixel}
            hasConversions={hasConversions}
            hasAdditionalInfo={hasAdditionalInfo.includes(phase)}
        />
    ) : null;

    return (
        <StatusCellRenderer
            phase={phase}
            progress={progress}
            progressBarColor={progressBarColor}
            timeLeft={timeLeft}
            tooltipBody={tooltipBody}
            hidePhaseLabel={hidePhaseLabel}
            hideTooltip={hideTooltip.includes(phase)}
            {...props}
            {...campaignStatusMessages}
            value={status}
        />
    );
};

export default StatusTrackerRenderer;
