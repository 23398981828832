import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RssIcon,
    Tag,
    StyledButton,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    TooltipV2 as Tooltip,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { selectedAccountSelector } from '../../../../../../selectors';
import { deleteRssFeed } from '../../../../flows';
import styles from './rssLink.module.scss';

const RemoveRssButton = ({ onClick }) => (
    <div>
        <StyledButton
            className={styles['close-icon']}
            iconBefore="delete"
            onClick={onClick}
            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
            size={STYLED_BUTTON_SIZE.SMALL}
        />
        <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} arrow>
            <FormattedMessage
                id="app.campaigns.reports.rss.toolbar.link.tooltip"
                defaultMessage="Delete RSS Feed - this will remove all RSS feed ads."
            />
        </Tooltip>
    </div>
);

const getModalViewData = feedUrl => ({
    title: (
        <FormattedMessage
            id="rss.modal.delete.header"
            tagName="h2"
            defaultMessage="Delete RSS Feed?"
            values={{ feedUrl }}
        />
    ),
    content: (
        <FormattedMessage
            id="rss.modal.delete.content"
            defaultMessage='You are about to delete all Ads linked to "{feedUrl}", this action cannot be undone.'
            values={{ feedUrl }}
        />
    ),
    formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" /> },
});

const RssLink = ({ rssData }) => {
    const dispatch = useDispatch();
    const { accountName } = useSelector(selectedAccountSelector);
    const { open: openModal } = useModal();
    const handleClick = useCallback(async () => {
        const result = await openModal(getModalViewData(rssData.feedUrl));

        if (result) {
            dispatch(deleteRssFeed({ ...rssData, accountId: accountName }));
        }
    }, [rssData, accountName, openModal, dispatch]);

    return (
        <Tag containerClassName={styles['tag-container']} closeButton={<RemoveRssButton onClick={handleClick} />}>
            <div className={styles['container']}>
                <RssIcon className={styles['rss-icon']} />
                <div>{rssData.feedUrl}</div>
            </div>
        </Tag>
    );
};

export default RssLink;
