import { useCallback, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cloneDeep, isEmpty, isFunction } from 'lodash';
import { clearHasUnsavedChanges, navigate, setHasUnsavedChanges } from 'actions';
import { FORM_MODES } from 'config/formModes';
import { useCurrentValueGetter, useMergeWithValueCallback } from 'hooks';
import { useDrawerStack } from 'hooks/queryParams/useDrawerStack';
import { applyRecommendationSuccess } from 'modules/campaigns/actions/applyRecommendationSuccess';
import formValidationErrorIndication from 'modules/campaigns/config/indications/validationIndication';
import { useRecommendationService } from 'modules/campaigns/modules/performance-recommendations/hooks/useRecommendationService';
import { clearRecommendations } from 'modules/recommendations';
import { recommendationsDataSelector, recommendationsIdSelector } from 'modules/recommendations/selectors';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, useFormWizardSteps } from 'modules/taboola-common-frontend-modules/formData';
import { getRrandFromData } from 'services/callAjax/responseParserUtils';
import { COMPONENT_STATUS } from 'services/constants';
import useUnsavedChangesController from '../../../campaigns/hooks/useUnsavedChangesController';
import { useMultiLayerPathParams } from '../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { removeIndication } from '../../Indications';
import { AccountContext } from '../../account-configurations';
import { APP_EVENT_TYPE, useAppEventContext } from '../../app-events-aggregator';
import { CHAT_AGENT_ACTION_STATUS } from '../../chat-agent/config/chatAgentActionStatus';
import { useIsLastLayer } from '../../formData/hooks/useIsLastLayer';
import { addAutoScrollCallback, allKeysList } from '../../formData/scroll-utils';
import { throwCriticalError } from '../../formData/utils/throwCriticalError';
import { gtmTracker } from '../../gtmTracker';
import { immutableMergeWith } from '../../utils/customMergers';
import { useValidationContext } from '../../validations/ValidationContext';
import { getInvalidFieldsWithMessages } from '../utils/getInvalidFieldsWithMessages';

const emptyProps = {};
const noopPromise = () => Promise.resolve();
export const useFormState = ({
    formDataFetcher: formDataFetcherProp,
    fetchedFormData,
    recommendationData: recommendationDataProp,
    autoScroll = true,
    skipChatContextRegistration,
} = emptyProps) => {
    const {
        data,
        setData,
        setFetchedData,
        mode,
        isFormTouched,
        setSubmitStatus,
        setFetchStatus,
        fetchStatus,
        formDataFetcher = formDataFetcherProp,
        hasNoEditChanges,
        setExternalData,
        waitDelayedFields,
        submitStatus,
        hasDataChanges,
        path,
    } = useFormDataContext();

    const isLastLayer = useIsLastLayer(path);

    const hasUnsavedChanges = hasDataChanges();

    const { campaignId, campaignsGroupId, entity } = useMultiLayerPathParams();
    const entityGetter = useCurrentValueGetter(entity);

    const { isAccountTouched: isFormAccountTouchedCallback, account } = useContext(AccountContext);
    const { isAccountFetched } = account;
    //TODO: DEV-162332 - change this condition after DEV-159230 is resolved
    const waitAccountFetched = !!campaignsGroupId ? isAccountFetched : true;
    const { pop, isMultiLayerMode } = useDrawerStack();

    const { validate: originValidate, optionalValidate, validationService } = useValidationContext();
    const validate = useCallback(async () => {
        await waitDelayedFields();
        return originValidate();
    }, [waitDelayedFields, originValidate]);
    const {
        isError: formInitError,
        isSuccess,
        isFetching: formInitFetching,
        data: formData,
    } = useQuery(
        ['init-form-data-fetcher', recommendationDataProp],
        async () => {
            const resolvedDataFetcher = formDataFetcher || noopPromise;
            const formData = await resolvedDataFetcher();

            if (formDataFetcher && !formData) {
                throw new Error('Error initializing form data');
            }

            return formData ?? {};
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
            enabled: !!waitAccountFetched,
        }
    );

    const formInitSuccess = waitAccountFetched && isSuccess;
    const dispatch = useDispatch();
    const history = useHistory();
    const { applyRecommendation } = useRecommendationService();
    const recommendationDataFromCache = useSelector(recommendationsDataSelector);
    const recommendationId = useSelector(recommendationsIdSelector);
    const recommendationData = recommendationDataProp ?? recommendationDataFromCache;
    const isFormAccountTouched = isFormAccountTouchedCallback();
    const customMergeWith = useMergeWithValueCallback();
    const { push: pushAppEvent } = useAppEventContext();
    const initFormData = useCallback(
        passedData => {
            batch(() => {
                const safeRecommendationData = cloneDeep(recommendationData);

                if (passedData || recommendationData) {
                    const mergedData = customMergeWith(cloneDeep(passedData || {}), safeRecommendationData);

                    setData(mergedData);
                    setFetchedData(passedData);
                }
                if (recommendationData) {
                    setExternalData(safeRecommendationData, customMergeWith);
                }
            });
        },
        [setData, setFetchedData, recommendationData, setExternalData, customMergeWith]
    );

    useUnsavedChangesController(path, hasUnsavedChanges, isLastLayer);

    const handleValidationError = useCallback(() => {
        dispatch(addIndication(formValidationErrorIndication));
    }, [dispatch]);
    const dataGetter = useCurrentValueGetter(data);
    const save = useCallback(
        async (saveFormData, successUrl) => {
            let createdFormData;
            let rrand;
            try {
                createdFormData = await saveFormData(dataGetter());
                rrand = getRrandFromData(createdFormData);
                if (!createdFormData) {
                    throwCriticalError(
                        new Error("Wrong form submit flow, doesn't return data and doesn't throw error")
                    );
                }
                if (!rrand) {
                    gtmTracker.trackError('Empty rrand in form submit');
                }
            } catch (error) {
                rrand = error?.rrand;
            }

            if (!createdFormData) {
                setSubmitStatus(COMPONENT_STATUS.ERROR);
                pushAppEvent({
                    type: APP_EVENT_TYPE.FORM_ACTION,
                    data: { [entityGetter()]: dataGetter() },
                    status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                    rrand,
                });
                return;
            }

            if (!isEmpty(createdFormData)) {
                pushAppEvent({
                    type: APP_EVENT_TYPE.FORM_ACTION,
                    status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                    data: { [entityGetter()]: createdFormData },
                    rrand,
                });
            }

            setSubmitStatus(COMPONENT_STATUS.ACTIVE);
            dispatch(clearHasUnsavedChanges());
            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            if (recommendationId) {
                applyRecommendation(recommendationId);

                if (campaignId) {
                    dispatch(applyRecommendationSuccess({ id: campaignId }));
                }
            }

            const navigatePath = isFunction(successUrl) ? await successUrl(createdFormData) : successUrl;
            if (isMultiLayerMode) {
                pop({ [entityGetter()]: createdFormData });
            } else if (navigatePath) {
                dispatch(navigate(history, navigatePath));
            }

            return createdFormData;
        },
        [
            applyRecommendation,
            campaignId,
            dataGetter,
            dispatch,
            history,
            recommendationId,
            setSubmitStatus,
            pushAppEvent,
            isMultiLayerMode,
            pop,
            entityGetter,
        ]
    );

    const submit = useCallback(
        async (saveFormData, successUrl, skipValidation) => {
            if (mode === FORM_MODES.PREVIEW) {
                return;
            }

            setSubmitStatus(COMPONENT_STATUS.LOADING);
            const isFormValid = skipValidation ?? (await validate());
            if (!isFormValid) {
                setSubmitStatus(COMPONENT_STATUS.INITIAL);
                handleValidationError();
                pushAppEvent({
                    type: APP_EVENT_TYPE.FORM_ACTION,
                    status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                    data: getInvalidFieldsWithMessages(validationService.getInvalidFields(), dataGetter()),
                });
                return;
            }
            const saveCallback = () => save(saveFormData, successUrl);
            const cancelCallback = () => setSubmitStatus(COMPONENT_STATUS.INITIAL);

            const isOptionalValid = await optionalValidate(saveCallback, cancelCallback);
            if (isOptionalValid) {
                return save(saveFormData, successUrl);
            }
        },
        [
            handleValidationError,
            validate,
            optionalValidate,
            save,
            mode,
            setSubmitStatus,
            pushAppEvent,
            dataGetter,
            validationService,
        ]
    );
    const { step, setStep, prevStep, nextStep, registerStep } = useFormWizardSteps({
        validate,
        optionalValidate,
        handleValidationError,
        initialStep: recommendationData?.initialStep,
        skipChatContextRegistration,
    });

    // This effect handles formDataFetcher and fetchedFormData statuses
    useEffect(() => {
        if (fetchStatus === COMPONENT_STATUS.ACTIVE) {
            return;
        }

        const { isSuccess, isError, data } = fetchedFormData ?? { isSuccess: true, data: {} };

        if (formInitFetching && fetchStatus !== COMPONENT_STATUS.LOADING) {
            setFetchStatus(COMPONENT_STATUS.LOADING);
            return;
        }

        if (formInitFetching) {
            return;
        }

        if ((formInitError || isError) && fetchStatus !== COMPONENT_STATUS.ERROR) {
            setFetchStatus(COMPONENT_STATUS.ERROR);
            return;
        }
        if (formInitSuccess && isSuccess && fetchStatus !== COMPONENT_STATUS.ACTIVE) {
            const resolvedFormData = immutableMergeWith(formData, data);
            initFormData(resolvedFormData);
            setFetchStatus(COMPONENT_STATUS.ACTIVE);
        }
    }, [
        fetchedFormData,
        formData,
        setFetchStatus,
        fetchStatus,
        initFormData,
        formInitError,
        formInitSuccess,
        formInitFetching,
    ]);

    // Scrolling to first recommended data field
    useEffect(() => {
        if (isEmpty(recommendationData) || fetchStatus !== COMPONENT_STATUS.ACTIVE || !autoScroll) {
            return;
        }

        const fieldNames = allKeysList(recommendationData);
        const callback = () => validationService.manuallyTriggerValidationFocus(fieldNames);
        addAutoScrollCallback(callback);
    }, [recommendationData, fetchStatus, validationService, autoScroll]);

    // Unsaved changes trigger
    useEffect(() => {
        const notTouched = !(isFormTouched || isFormAccountTouched);

        if (mode === FORM_MODES.PREVIEW || notTouched || hasNoEditChanges) {
            dispatch(clearHasUnsavedChanges());
            return;
        }

        dispatch(setHasUnsavedChanges());
    }, [isFormTouched, isFormAccountTouched, hasNoEditChanges, mode, dispatch]);

    // Clearing recommendations and changes tracker
    useEffect(
        () => () => {
            if (autoScroll) {
                dispatch(clearRecommendations());
            }
        },
        [dispatch, autoScroll]
    );
    useEffect(
        () => () => {
            dispatch(clearHasUnsavedChanges());
        },
        [dispatch]
    );

    const submitStatusGetter = useCurrentValueGetter(submitStatus);

    useEffect(() => {
        pushAppEvent({ status: CHAT_AGENT_ACTION_STATUS.IN_PROGRESS, type: APP_EVENT_TYPE.FORM_ACTION });

        return () => {
            if (submitStatusGetter() !== COMPONENT_STATUS.ACTIVE) {
                setTimeout(() =>
                    pushAppEvent({
                        status: CHAT_AGENT_ACTION_STATUS.CANCEL,
                        type: APP_EVENT_TYPE.FORM_ACTION,
                    })
                );
            }
        };
    }, [pushAppEvent, submitStatusGetter]);

    return {
        submit,
        step,
        setStep,
        prevStep,
        nextStep,
        registerStep,
    };
};
