const CREATIVE_EDITOR_TABS = {
    MEDIA_LIBRARY: 'mediaLibrary',
    URL_BASED: 'urlBased',
    RECOMMENDED_MEDIA: 'recommendedMedia',
    AI_GENERATED: 'aiGenerated',
};

export { CREATIVE_EDITOR_TABS };
export * from './focusTypes';
export { default as FORM_MODE_CONFIG } from './formModeConfig';
export * from './creativeInitialValues';
