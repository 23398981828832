import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TipText from '../TipText';
import TooltipSection from '../TooltipSection';

const WifiTargetingTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.wifi.help.overview"
                defaultMessage="Wi-Fi Only lets you target users who are using Wi-Fi when they see your ad on the Taboola platform."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.wifi.help.example"
                defaultMessage="For example, maybe your campaign contains video and it runs in a country with a slow mobile connection. Targeting users who use a Wi-Fi connection can ensure that users have a good experience."
            />
        </TooltipSection>
        <TooltipSection>
            <TipText>
                <FormattedMessage
                    id="campaign.editor.targeting.wifi.help.tip"
                    defaultMessage="Similarly, maybe you are running a campaign to drive app downloads. If your App requires a wi-fi connection to download, this is probably a required option for your campaign."
                />
            </TipText>
        </TooltipSection>
    </div>
);

export default WifiTargetingTooltip;
