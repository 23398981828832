import { FormLayout } from 'modules/campaigns/components';
import useUnsavedChangesController from 'modules/campaigns/hooks/useUnsavedChangesController';
import { AdvancedSettingsSection } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/AdvancedSettingsSection';
import { PerformanceRulesConditionAndActionSection } from 'modules/performance-rules/components/PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/PerformanceRulesConditionAndActionSection';
import { PerformanceRulesSetupSection } from 'modules/performance-rules/components/PerformanceRuleSetupSection/PerformanceRulesSetupSection';
import { PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH } from 'modules/performance-rules/config';
import { performanceRuleDuplicateInitialValues } from 'modules/performance-rules/config/initialValues/performanceRuleDuplicateInitialValues';
import { usePerformanceRulesDuplicateState } from 'modules/performance-rules/hooks/usePerformanceRulesDuplicateState';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './PerformanceRulesEditPage.module.scss';

const PerformanceRulesDuplicatePage = ({ onCancel }) => {
    const { submit } = usePerformanceRulesDuplicateState();
    const { isSubmitDisabled, submitInProgress } = useFormDataContext();
    useUnsavedChangesController(PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH);

    return (
        <FormInitBase className={styles['wrapper']}>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                header={
                    <div className={styles['subheader']}>
                        <FormattedMessage
                            id="performance.rule.editor.duplicate.title"
                            tagName="h1"
                            defaultMessage="Duplicate Rule"
                        />
                    </div>
                }
            >
                <div className={styles['form-wrapper']}>
                    <PerformanceRulesSetupSection />
                    <PerformanceRulesConditionAndActionSection />
                    <AdvancedSettingsSection />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};
const PerformanceRulesDuplicatorWithFormDataProvider = withFormDataProvider(PerformanceRulesDuplicatePage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    defaultValuesConfig: performanceRuleDuplicateInitialValues,
});
export { PerformanceRulesDuplicatorWithFormDataProvider as PerformanceRulesDuplicatePage };
