import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';
import { appStateSelector } from './baseSelectors';

const defaultFormSectionState = {};
const defaultModuleState = {};
const defaultEntityState = {};
const defaultSectionState = {};

export const formSectionStateSelector = createSelector(
    appStateSelector,
    appState => appState.formSectionState || defaultFormSectionState
);
export const formSectionStateByModuleSelector = memoizeOne(module =>
    createSelector(formSectionStateSelector, formSectionState => formSectionState[module] || defaultModuleState)
);

export const formSectionStateByEntitySelector = memoizeOne((module, entity) =>
    createSelector(formSectionStateByModuleSelector(module), moduleState => moduleState[entity] || defaultEntityState)
);

export const formSectionStateBySectionSelector = memoizeOne((module, entity, section) =>
    createSelector(
        formSectionStateByEntitySelector(module, entity),
        entityState => entityState[section] || defaultSectionState
    )
);
