import { CTA as CTA_CONSTS } from 'modules/campaigns/config';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';

export const sendVariationsMetrics = ({ content = [], thumbnailList = [], campaigns }) => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        value: campaigns?.length,
        component: 'Selected Campaigns',
        pageName: getPageName(),
    });

    content.forEach(({ descriptionEnabled = false, ctaEnabled = false, cta = CTA_CONSTS.NONE }) => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: String(descriptionEnabled),
            component: 'Description checkbox',
            pageName: getPageName(),
        });
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: String(ctaEnabled),
            columnName: cta,
            component: 'CTA checkbox',
            pageName: getPageName(),
        });
    });
    thumbnailList.forEach(({ mediaUploadSource }) => {
        if (mediaUploadSource) {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                value: mediaUploadSource,
                columnName: String(thumbnailList.length),
                component: 'CTA checkbox',
                pageName: getPageName(),
            });
        }
    });
};
