'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var STYLED_WITH_MODE_BUTTON_SIZE = {
    REGULAR: 'regular',
    SMALL: 'small'
};

exports.default = STYLED_WITH_MODE_BUTTON_SIZE;