import React from 'react';
import { batch } from 'react-redux';
import { removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { campaignsApi } from 'services/api';
import { errorMessagesUtils } from '../../../services/utils';
import { recommendationsIdSelector, recommendationsTypeSelector } from '../../recommendations/selectors';
import { requestCreateCampaign, createCampaignSuccess, createCampaignError } from '../actions';
import { REPORT_TYPE } from '../config';
// this import is not according to standards due to circular dependency
import { toGWtransformers } from './flowsUtils';

const successIndication = {
    message: <FormattedMessage id="campaign.create.success" defaultMessage="Campaign successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="campaign.create.error.highlight" defaultMessage="Unable to save changes." />,
});

export const postCampaignCreationSortState = [{ colId: `${REPORT_TYPE.CAMPAIGN}_startDate`, sort: 'desc' }];

const createCampaign = (account, campaign, entityType, fullResponse) => async (dispatch, getState) => {
    dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
    dispatch(requestCreateCampaign());
    const { accountName, accountDescription } = account;
    const campaignToCreate = toGWtransformers[entityType](campaign);
    const state = getState();
    const recommendationType = recommendationsTypeSelector(state);
    const recommendationId = recommendationsIdSelector(state);
    let createdCampaign;

    try {
        const response = await campaignsApi.createCampaign({
            accountId: accountName,
            campaign: campaignToCreate,
            entityType,
            recommendationType,
            recommendationId,
        });

        // TODO: DEV-65984 - should be removed after this ticket.
        createdCampaign = {
            id: response.id,
            name: response.name,
            ...(fullResponse ? response : {}),
            advertiserName: response.accountName || accountName,
            advertiserDescription: accountDescription,
        };
        batch(() => {
            dispatch(createCampaignSuccess(response));
            dispatch(addIndication(successIndication));
        });
    } catch (error) {
        batch(() => {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(createCampaignError(error));
        });
        throw error;
    }

    return createdCampaign;
};

export default createCampaign;
