import React, { useState } from 'react';
import { DelayMessage } from './BodySection/DelayMessage';
import { OnboardingCTA } from './BodySection/OnboardingCTA';
import { OnboardingStepDescription } from './OnboardingStepDescription';
import styles from './oneStepDescription.module.scss';

export const OneStepDescription = ({
    titleId,
    descriptionId,
    delayMsgId,
    delayStateDurationMinutes,
    motivationId,
    learnMoreLinkId,
    onClickCTA,
    isComplete,
    isCollapsed,
    isCTADisabled,
}) => {
    const [isOneDisabled, setIsOneDisabled] = useState(false);
    return (
        <div className={styles['container']}>
            <OnboardingStepDescription
                titleId={titleId}
                descriptionId={descriptionId}
                motivationId={motivationId}
                learnMoreLinkId={learnMoreLinkId}
                isCollapsed={isCollapsed}
                isComplete={isComplete}
            />
            {!isCTADisabled && (
                <div className={styles['action-section']}>
                    {isOneDisabled && delayMsgId ? (
                        <DelayMessage delayMsgId={delayMsgId} className={styles['delay-section']} />
                    ) : null}
                    <OnboardingCTA
                        stepId={titleId}
                        delayStateDurationMinutes={delayStateDurationMinutes}
                        isComplete={isComplete}
                        onClick={onClickCTA}
                        className={styles['cta']}
                        setIsOneDisabled={setIsOneDisabled}
                    />
                </div>
            )}
        </div>
    );
};
