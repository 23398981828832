import React from 'react';

export const VisaSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path
                d="M9.35267 9.12463L6.4725 15.8943H4.59333L3.17617 10.491C3.09 10.1587 3.01575 10.0368 2.75358 9.89679C2.32642 9.6683 1.62058 9.45426 1 9.3206L1.04217 9.12463H4.06717C4.26484 9.12443 4.45608 9.19387 4.60636 9.32039C4.75664 9.44692 4.85605 9.6222 4.88667 9.8146L5.63558 13.7323L7.48542 9.12463H9.35267ZM16.7163 13.6844C16.7236 11.8972 14.2083 11.7987 14.2257 11.0004C14.2312 10.7575 14.4658 10.4992 14.9792 10.4332C15.5808 10.3769 16.1866 10.4818 16.7328 10.7367L17.0444 9.30074C16.5127 9.10381 15.9496 9.00197 15.3816 9C13.6243 9 12.3878 9.92117 12.3768 11.2388C12.3658 12.2133 13.2595 12.756 13.9333 13.0812C14.6263 13.4126 14.8591 13.6257 14.8554 13.9219C14.8508 14.3771 14.3036 14.5767 13.7921 14.5848C12.8983 14.5984 12.3804 14.3473 11.9661 14.1577L11.6443 15.6406C12.0596 15.8284 12.8259 15.9928 13.6207 16C15.4879 16 16.7098 15.0915 16.7163 13.6844ZM21.3555 15.8943H23L21.5654 9.12463H20.0474C19.8852 9.12315 19.7263 9.16971 19.5913 9.25828C19.4563 9.34685 19.3514 9.47333 19.2902 9.62134L16.6237 15.8943H18.49L18.8613 14.8829H21.1419L21.3555 15.8943ZM19.3727 13.4957L20.3078 10.9534L20.8467 13.4957H19.3727ZM11.8928 9.12463L10.4233 15.8943H8.645L10.1162 9.12463H11.8928Z"
                fill="#1F5499"
            />
        </svg>
    );
};
