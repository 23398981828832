import { get } from 'lodash';
import { validateContent } from 'modules/campaigns/components/ContentEditableText/services';

export const getRejectReasonFromResults = results => get(results, '0.reason', '');

const defaultMutator = results => getRejectReasonFromResults(results) === '';

export const getAutoReviewValidationResults = async (apiBody, resultsMutator = defaultMutator) => {
    let results;

    try {
        results = await validateContent(apiBody);
    } catch (err) {
        // on API failure, don't fail the validation b/c we dont want to block the user
        // if an API error occurs
        return true;
    }

    return resultsMutator(results);
};
