import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import Counter from 'components/Counter';
import styles from './oneByOneLoader.module.scss';

const OneByOneLoader = ({ showCounter, current, total }) => {
    return (
        <Fragment>
            <Spinner size={40} className={styles['spinner-container']} />
            {showCounter && <Counter value={current} max={total} disableThresholds className={styles['counter']} />}
        </Fragment>
    );
};

OneByOneLoader.propTypes = {
    showCounter: PropTypes.bool,
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

export default OneByOneLoader;
