import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigate = () => {
    const history = useHistory();
    const navigate = useCallback(
        (destination, { state, replace } = {}) => {
            if (replace) {
                history.replace(destination, state);
                return;
            }
            history.push(destination, state);
        },
        [history]
    );

    return navigate;
};
