export const sortArrayWithoutMutation = array => [...(array ?? [])].sort();

export const sortTerms = terms => {
    const sortedTerms = [];
    const includeTermGroupCount = (terms && terms.include && terms.include.length) || 0;
    for (let i = 0; i < includeTermGroupCount; i++) {
        const sortedTermsGroup =
            (terms && terms.include && terms.include[i] && terms.include[i].length && [...terms.include[i]].sort()) ||
            false;
        if (sortedTermsGroup) {
            sortedTerms.push(sortedTermsGroup);
        }
    }
    const sortedExcludeTerms = (terms && terms.exclude && terms.exclude.length && [...terms.exclude].sort()) || false;
    if (sortedExcludeTerms) {
        sortedTerms.push(sortedExcludeTerms);
    }
    return sortedTerms;
};

export const topicToNameAndLabel = topic => ({ ...topic, label: topic.term, name: topic.term });

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
