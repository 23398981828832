import { queryClient } from '../../query';
import { callDeleteApi, callGetApi, callPostApi, callPutApi } from '../apiCallerProxy';
import { createApiHook } from '../hooks';
import { performanceRulesApiFactory } from './performanceRulesApiFactory';

export const usePerformanceRulesApi = createApiHook(performanceRulesApiFactory);

export const performanceRulesApi = performanceRulesApiFactory({
    callGetApi,
    callPutApi,
    callPostApi,
    callDeleteApi,
    queryClient,
});
