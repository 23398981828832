import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ActionRecommendationButton = ({
    onClick,
    recommendation,
    textCode,
    className,
    size = Button.size.sm,
    dataMetricsComponent = 'RecommendationCardEditCampaignButton',
    defaultMessage = 'Edit Campaign',
    variant = Button.variant.outline,
    disabled = false,
}) => {
    const { id: recommendationId, numericAccountId, campaignId } = recommendation;
    return (
        <Button
            variant={variant}
            size={size}
            onClick={onClick}
            className={className}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-account-id={numericAccountId}
            data-metrics-taboola-campaign-id={campaignId}
            data-metrics-value={recommendationId}
            data-metrics-component={dataMetricsComponent}
            disabled={disabled}
        >
            <FormattedMessage id={textCode} defaultMessage={defaultMessage} />
        </Button>
    );
};

ActionRecommendationButton.propTypes = {
    disabled: PropTypes.bool,
};
