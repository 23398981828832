import React from 'react';
import { ManuallyIcon, ServerIcon, ShopifyIcon, UrlIcon } from 'tuui';
import { Radio, RadioGroup } from 'taboola-ultimate-ui';
import { useAvailableItems } from '../../../../hooks';
import { useAccountTotalConversions } from '../../../campaigns/modules/common-campaign-form/components/ConversionOptimization/hook/useAccountTotalConversions';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { ENTITY } from '../../config';
import styles from './trackingSetupMethodRadioGroup.module.scss';

const configs = [
    {
        id: ENTITY.GTM_SETUP,
        icon: UrlIcon,
        label: 'Google Tag Manager (recommended)',
        subtext:
            'The Tag Manager is a tool that allows marketers to add and update tags and code snippets such as conversion tracking, site analytics, and remarketing code quickly and easily, without editing the website’s code.',
    },
    {
        id: ENTITY.MANUALLY_SETUP,
        icon: ManuallyIcon,
        label: 'Manually',
        subtext: 'Manually install the Taboola Pixel by pasting the code in the head section of your website.',
    },
    {
        id: ENTITY.S2S_SETUP,
        icon: ServerIcon,
        label: 'Server to Server',
        subtext: 'Server to server conversion tracking.',
    },
    {
        id: ENTITY.SHOPIFY_SETUP,
        icon: ShopifyIcon,
        label: 'Shopify',
        subtext:
            'Use the official Taboola Pixel app for Shopify to seamlessly track actions and conversions on your store.',
        visibilityCondition: ({ isShopifyEnabled, totalConversions }) => isShopifyEnabled && totalConversions === 0,
    },
];

const TrackingSetupMethodRadioItem = ({ id, Icon, label, subtext }) => {
    return (
        <div className={styles['radio-container']}>
            <div className={styles['icon-wrapper']}>
                <Icon color="#4D86EC" />
            </div>
            <div className={styles['label']}>
                <FormattedMessage id={`tracking.setup.modal.${id}.label`} tagName="h4" defaultMessage={label} />
                <span className={styles['desc']}>
                    <FormattedMessage id={`tracking.setup.modal.${id}.description`} defaultMessage={subtext} />
                </span>
            </div>
        </div>
    );
};

export const TrackingSetupMethodRadioGroup = ({ type, setType }) => {
    const { metadata: { total: totalConversions } = {} } = useAccountTotalConversions() || { metadata: {} };
    const isShopifyEnabled = useConfigMatch({
        [FEATURE_FLAGS.SHOW_SHOPIFY_SETUP]: 'true',
    });
    const options = useAvailableItems(configs, { isShopifyEnabled, totalConversions });

    return (
        <RadioGroup
            id="tracking_setup_method_radio_group"
            name="tracking_setup_method_radio_group"
            selectedValue={type}
            onChange={setType}
            className={styles['group']}
        >
            {options.map(({ id, icon: Icon, label, subtext }) => {
                return (
                    <Radio
                        key={id}
                        value={id}
                        labelClassName={styles['radio']}
                        title=""
                        childrenContainerClassName={styles['children-container']}
                    >
                        <TrackingSetupMethodRadioItem id={id} Icon={Icon} label={label} subtext={subtext} />
                    </Radio>
                );
            })}
        </RadioGroup>
    );
};

export default TrackingSetupMethodRadioGroup;
