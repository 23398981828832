import React from 'react';
import { IndicationContent, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './uploadMediaError.module.scss';

export const UploadMediaError = ({ errorMessageId }) => {
    return errorMessageId ? (
        <IndicationContent containerClassName={styles['error']} type={INDICATION_TYPES.ERROR}>
            <FormattedMessage id={errorMessageId} defaultMessage="Upload Error" />
        </IndicationContent>
    ) : null;
};
