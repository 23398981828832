'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'image-placeholder': 'imagePlaceholder__image-placeholder___36HqF',
    'image-placeholder-auto': 'imagePlaceholder__image-placeholder-auto___120xb'
};


var ImagePlaceholder = function ImagePlaceholder(_ref) {
    var _ref$className = _ref.className,
        className = _ref$className === undefined ? '' : _ref$className;
    return _react2.default.createElement(
        'svg',
        {
            xmlns: 'http://www.w3.org/2000/svg',
            className: styles['image-placeholder'] + ' ' + className,
            xmlnsXlink: 'http://www.w3.org/1999/xlink',
            width: '157',
            height: '105',
            viewBox: '0 0 157 105'
        },
        _react2.default.createElement(
            'defs',
            null,
            _react2.default.createElement('path', { id: 'a', d: 'M2 0h143a2 2 0 0 1 2 2v91a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z' })
        ),
        _react2.default.createElement(
            'g',
            { fill: 'none', fillRule: 'evenodd' },
            _react2.default.createElement(
                'g',
                { strokeLinejoin: 'square' },
                _react2.default.createElement('rect', { width: '154', height: '102', x: '1.5', y: '1.5', stroke: '#FFF', strokeWidth: '3', rx: '6' }),
                _react2.default.createElement('rect', { width: '156', height: '104', x: '.5', y: '.5', stroke: '#D4D9DF', rx: '6' })
            ),
            _react2.default.createElement(
                'g',
                { transform: 'translate(5 5)' },
                _react2.default.createElement(
                    'mask',
                    { id: 'b', fill: '#fff' },
                    _react2.default.createElement('use', { xlinkHref: '#a' })
                ),
                _react2.default.createElement('use', { fill: '#FAFBFB', xlinkHref: '#a' }),
                _react2.default.createElement('path', {
                    fill: '#F2F3F5',
                    d: 'M54 32c0 6.074-5.148 11-11.5 11S31 38.074 31 32s5.148-11 11.5-11S54 25.926 54 32M91.798 38.153L61 68.535 87.684 94H159l-56.617-55.847c-2.91-2.87-7.673-2.87-10.585 0M34.889 59.931L0 94h79L54.573 69.904l-10.082-9.948c-2.634-2.599-6.956-2.609-9.602-.025',
                    mask: 'url(#b)'
                })
            )
        )
    );
};

ImagePlaceholder.propTypes = {
    className: _propTypes2.default.string
};

exports.default = ImagePlaceholder;