'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by oreuveni
 * Date: 2019-07-15
 * Time: 10:51
 */
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'graphTooltip__taboola-svg-icon___1LCjo',
    'container': 'graphTooltip__container___XoCrM',
    'label': 'graphTooltip__label___dg9J_',
    'row': 'graphTooltip__row___3i4r5',
    'name': 'graphTooltip__name___2cDpe'
};


var defaultFormatter = function defaultFormatter(value) {
    return value;
};

var GraphTooltip = function GraphTooltip(_ref) {
    var label = _ref.label,
        payload = _ref.payload,
        labelFormatter = _ref.labelFormatter;
    return payload && payload.length ? _react2.default.createElement(
        'div',
        { className: styles['container'] },
        _react2.default.createElement(
            'div',
            { className: styles['label'] },
            labelFormatter(label)
        ),
        payload.map(function (_ref2) {
            var dataKey = _ref2.dataKey,
                stroke = _ref2.stroke,
                fill = _ref2.fill,
                value = _ref2.value,
                name = _ref2.name,
                _ref2$formatter = _ref2.formatter,
                formatter = _ref2$formatter === undefined ? defaultFormatter : _ref2$formatter;
            return _react2.default.createElement(
                'div',
                { key: dataKey + 'Tooltip', className: styles['row'] },
                _react2.default.createElement(
                    'span',
                    { className: styles['name'] },
                    name
                ),
                _react2.default.createElement(
                    'span',
                    { style: { color: stroke || fill } },
                    formatter(value)
                )
            );
        })
    ) : null;
};

GraphTooltip.propTypes = {
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    payload: _propTypes2.default.array,
    labelFormatter: _propTypes2.default.func
};

GraphTooltip.defaultProps = {
    labelFormatter: defaultFormatter
};

exports.default = GraphTooltip;