'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Collapsible = require('../Collapsible/Collapsible');

var _Collapsible2 = _interopRequireDefault(_Collapsible);

var _Icons = require('../Icons/Icons');

var _useCollapseAnimation3 = require('../Collapsible/useCollapseAnimation');

var _useCollapseAnimation4 = _interopRequireDefault(_useCollapseAnimation3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'collapsibleCard-module__taboola-svg-icon___1q8-W',
    'container': 'collapsibleCard-module__container___1aDIE',
    'header': 'collapsibleCard-module__header___2aGlL',
    'border': 'collapsibleCard-module__border___rvOVw',
    'body': 'collapsibleCard-module__body___3H7wz',
    'icon': 'collapsibleCard-module__icon___bJIPJ',
    'icon-collapsed': 'collapsibleCard-module__icon-collapsed___26qwk',
    'no-overflow': 'collapsibleCard-module__no-overflow___2iFfs'
};


var classNameBuilder = _bind2.default.bind(styles);

var CollapsibleCard = function CollapsibleCard(_ref) {
    var header = _ref.header,
        children = _ref.children,
        collapsed = _ref.collapsed,
        setCollapsed = _ref.setCollapsed,
        IconComponent = _ref.IconComponent,
        iconClassName = _ref.iconClassName,
        containerClassName = _ref.containerClassName,
        headerClassName = _ref.headerClassName,
        bodyClassName = _ref.bodyClassName,
        iconHoverClass = _ref.iconHoverClass,
        transitionDuration = _ref.transitionDuration,
        collapsedSize = _ref.collapsedSize;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        hovered = _useState2[0],
        setHovered = _useState2[1];

    var _useState3 = (0, _react.useState)(false),
        _useState4 = _slicedToArray(_useState3, 2),
        withAnimation = _useState4[0],
        setWithAnimation = _useState4[1];

    var _useCollapseAnimation = (0, _useCollapseAnimation4.default)(collapsed),
        _useCollapseAnimation2 = _slicedToArray(_useCollapseAnimation, 3),
        collapsibleRef = _useCollapseAnimation2[0],
        animationOpen = _useCollapseAnimation2[1],
        animationClosed = _useCollapseAnimation2[2];

    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('container', containerClassName) },
        _react2.default.createElement(
            'div',
            {
                className: classNameBuilder('header', headerClassName, {
                    border: !animationClosed
                }),
                role: 'button',
                tabIndex: 0,
                onClick: function onClick() {
                    return setCollapsed(function (val) {
                        setWithAnimation(true);
                        return !val;
                    });
                },
                onMouseEnter: function onMouseEnter() {
                    return setHovered(true);
                },
                onMouseLeave: function onMouseLeave() {
                    return setHovered(false);
                },
                'aria-expanded': !collapsed
            },
            header,
            _react2.default.createElement(IconComponent, {
                className: classNameBuilder('icon', collapsed && styles['icon-collapsed'], hovered && iconHoverClass, iconClassName)
            })
        ),
        _react2.default.createElement(
            _Collapsible2.default,
            {
                ref: collapsibleRef,
                collapsed: collapsed,
                transitionDuration: transitionDuration,
                withAnimation: withAnimation,
                collapsedSize: collapsedSize,
                className: classNameBuilder({ 'no-overflow': animationOpen })
            },
            _react2.default.createElement(
                'div',
                { className: classNameBuilder('body', bodyClassName) },
                children
            )
        )
    );
};

CollapsibleCard.propTypes = {
    header: _propTypes2.default.node,
    children: _propTypes2.default.node,
    collapsed: _propTypes2.default.bool,
    setCollapsed: _propTypes2.default.func,
    IconComponent: _propTypes2.default.func,
    iconClassName: _propTypes2.default.string,
    containerClassName: _propTypes2.default.string,
    headerClassName: _propTypes2.default.string,
    bodyClassName: _propTypes2.default.string,
    iconHoverClass: _propTypes2.default.string,
    transitionDuration: _propTypes2.default.number,
    collapsedSize: _propTypes2.default.number
};

CollapsibleCard.defaultProps = {
    setCollapsed: _lodash2.default,
    IconComponent: _Icons.DropdownLargeIcon,
    collapsed: false,
    transitionDuration: 250
};

exports.default = CollapsibleCard;