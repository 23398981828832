import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { isEmpty } from 'lodash';
import { FormField, Radio, RadioGroup, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks';
import {
    defaultSubtitlesType,
    VIDEO_CREATIVE_SUBTITLES_OPTIONS,
} from 'modules/campaigns/modules/common-campaign-form/config/videoCreativeSubtitlesOptions';
import { withIndication } from 'modules/errors';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreativesApi } from 'services/api';
import { callAjax } from 'services/callAjax';
import { SrtFileUpload } from './SrtFileUpload';
import { SubtitlesTooltip } from './SubtitlesTooltip';
import styles from './subtitlesField.module.scss';

const subtitlesOptions = [
    {
        id: VIDEO_CREATIVE_SUBTITLES_OPTIONS.NONE,
        title: 'None',
    },
    {
        id: VIDEO_CREATIVE_SUBTITLES_OPTIONS.AUTO,
        title: 'Automatic (English only)',
    },
    {
        id: VIDEO_CREATIVE_SUBTITLES_OPTIONS.FILE,
        title: 'Upload File',
    },
];

const RadioGroupWithIndication = withIndication(RadioGroup);

export const SubtitlesField = () => {
    const {
        value: subtitleType = defaultSubtitlesType,
        onChange: setSubtitlesType,
        isDirty: isSubtitlesTypeDirty,
    } = useFormFieldValue({
        field: 'videoUploadAssets.subtitles.type',
    });
    const { value: srtFileUrl } = useFormFieldValue({
        field: 'videoUploadAssets.subtitles.url',
    });
    const { value: videoUploadItems, onChange: setVideoUploadItems } = useFormFieldValue({
        field: 'videoUploadItems',
    });

    const videoFileUrl = videoUploadItems && videoUploadItems[0]?.url;
    const [srtFile, setSrtFile] = useState();
    const { uploadVideoCreativeSubtitles } = useCreativesApi();
    const getAssetsValue = useCurrentValueGetter(videoUploadItems);

    const { mutate: updateSubtitles, isLoading: isSubtitlesUploading } = useMutation({
        mutationFn: ({ srtFile, hashIds, subtitleType }) =>
            uploadVideoCreativeSubtitles(srtFile, hashIds, subtitleType),
        onSuccess: setVideoUploadItems,
    });

    const { value: isSubtitlesUploadingFromContext = false, onChange: setIsSubtitlesUploading } = useFormFieldValue({
        field: 'isSubtitlesUploading',
    });

    useEffect(() => {
        if (isSubtitlesUploadingFromContext === isSubtitlesUploading) {
            return;
        }
        setIsSubtitlesUploading(isSubtitlesUploading);
    }, [setIsSubtitlesUploading, isSubtitlesUploading, isSubtitlesUploadingFromContext]);

    const subtitlesUpdateHandler = useCallback(async () => {
        const hashIds = getAssetsValue()?.map(va => va?.hashId);
        if (
            subtitleType === VIDEO_CREATIVE_SUBTITLES_OPTIONS.AUTO ||
            (subtitleType === VIDEO_CREATIVE_SUBTITLES_OPTIONS.FILE && srtFile) ||
            (subtitleType === VIDEO_CREATIVE_SUBTITLES_OPTIONS.NONE && isSubtitlesTypeDirty)
        ) {
            updateSubtitles({ srtFile, hashIds, subtitleType });
        }
    }, [updateSubtitles, subtitleType, getAssetsValue, srtFile, isSubtitlesTypeDirty]);

    useEffect(() => {
        subtitlesUpdateHandler();
    }, [subtitlesUpdateHandler]);

    const setSubtitleContentFromUrl = useCallback(async () => {
        if (srtFileUrl) {
            const response = await callAjax(srtFileUrl, {}, true);
            const blob = await response.blob();
            setSrtFile(blob);
        }
    }, [srtFileUrl, setSrtFile]);

    useEffect(() => {
        setSubtitleContentFromUrl();
    }, [setSubtitleContentFromUrl]);

    return (
        <div>
            <FormField
                label={
                    <FormattedMessage id="creative.creator.subtitles.header" defaultMessage="Subtitles (Optional)" />
                }
                helpText={<SubtitlesTooltip />}
            >
                <RadioGroupWithIndication
                    indicationType={INDICATION_TYPES.INFO}
                    indicationContainerClass={styles['infoMessage']}
                    message={
                        isSubtitlesUploading &&
                        subtitleType === VIDEO_CREATIVE_SUBTITLES_OPTIONS.AUTO && (
                            <FormattedMessage
                                id="video.creative.creator.logo.subtitles.automatic.upload"
                                defaultMessage="Choosing 'Automatic (English only)' may take up to 30 seconds to apply"
                            />
                        )
                    }
                    id="subtitles-options"
                    selectedValue={subtitleType}
                    onChange={setSubtitlesType}
                    disabled={isEmpty(videoFileUrl)}
                >
                    {subtitlesOptions.map(({ id, title }) => (
                        <Radio
                            key={id}
                            value={id}
                            title={
                                <FormattedMessage
                                    id={`video.creative.creator.logo.subtitles.${id}`}
                                    defaultMessage={title}
                                />
                            }
                        />
                    ))}
                </RadioGroupWithIndication>
            </FormField>
            {subtitleType === VIDEO_CREATIVE_SUBTITLES_OPTIONS.FILE && (
                <SrtFileUpload setSrtFile={setSrtFile} srtFile={srtFile} />
            )}
        </div>
    );
};
