import {
    FETCH_PERFORMANCE_RULES_FOR_EDIT_ERROR,
    FETCH_PERFORMANCE_RULES_FOR_EDIT_SUCCESS,
    REQUEST_FETCH_PERFORMANCE_RULES_FOR_EDIT,
} from './actionTypes';

export const requestFetchPerformanceRulesForEdit = () => ({ type: REQUEST_FETCH_PERFORMANCE_RULES_FOR_EDIT });
export const fetchPerformanceRulesSuccess = rule => ({
    type: FETCH_PERFORMANCE_RULES_FOR_EDIT_SUCCESS,
    payload: rule,
});
export const fetchPerformanceRulesError = error => ({
    type: FETCH_PERFORMANCE_RULES_FOR_EDIT_ERROR,
    payload: error,
});
