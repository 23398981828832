import React from 'react';
import PropTypes from 'prop-types';
import { IconCheckbox } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './customCheckbox.module.scss';

const PLATFORM_MESSAGE_PREFIX = 'app.platform.code';

export const CustomCheckbox = ({ id, icon: Icon, isSelected, disabled, checkboxColorOverride }) => {
    const { formatMessage } = useIntl();
    const label = formatMessage({ id: `${PLATFORM_MESSAGE_PREFIX}.${id}` });
    return (
        <IconCheckbox
            aria-label={`${isSelected ? 'Selected - ' : ''}${label}`}
            value={id}
            title={label}
            iconComponent={<Icon />}
            labelClassName={styles['label']}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={id}
            checkboxColor={checkboxColorOverride}
            disabled={disabled}
        />
    );
};

CustomCheckbox.propTypes = {
    platformInfo: PropTypes.object,
    isSelected: PropTypes.bool,
    checkboxColorOverride: PropTypes.string,
    disabled: PropTypes.bool,
};

export default CustomCheckbox;
