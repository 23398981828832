import React from 'react';
import { get, keyBy, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { CollapsibleList, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { COUNTRY_TARGETING_LEVELS, TARGETING_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingDropdownBox from '../../TargetingDropdownBox/TargetingDropdownBox';
import { RenderItem } from '../RenderItem/RenderItem';
import TargetingItemDropdownOption from '../TargetingItemDropdownOption/TargetingItemDropdownOption';
import CityTargeting from './CityTargeting';
import ZipCodeTargeting from './ZipCodeTargeting/ZipCodeTargeting';
import styles from './subCountryTargeting.module.scss';

const DMA_SPLIT_COUNTRY = 'US';
const ITEM_HEIGHT = 40;

const getDataMetricAttrs = value => ({
    'data-metrics-component': 'Location Targeting',
    'data-metrics-value': value,
    'data-metrics-event-name': GTM_EVENTS.USABILITY,
});

class SubCountryTargeting extends React.Component {
    componentDidMount() {
        this.fetchRegionsIfNeeded();
        this.fetchDMAIfNeeded();
    }

    componentDidUpdate(prevProps) {
        const { countryTargetingLevel } = this.props;
        const { countryTargetingLevel: prev } = prevProps;

        if (prev !== countryTargetingLevel) {
            this.fetchRegionsIfNeeded();
            this.fetchDMAIfNeeded();
        }
    }

    getSelectedRegions() {
        const { allRegions, selectedRegions } = this.props;

        return this.prepareListView(allRegions, selectedRegions, this.getRegionTargetingType());
    }

    getSelectableRegions() {
        const { allRegions, selectedRegions } = this.props;

        return allRegions.filter(el => !selectedRegions.includes(el.code));
    }

    getSelectedDMAS() {
        const { allDMAs, selectedDMAs } = this.props;

        return this.prepareListView(allDMAs, selectedDMAs, this.getDMATargetingType());
    }

    getSelectableDMAS() {
        const { allDMAs, selectedDMAs } = this.props;

        return allDMAs.filter(el => !selectedDMAs.includes(el.code));
    }

    getSelectedCities() {
        const { selectedCities } = this.props;

        return selectedCities;
    }

    getRegionTargetingType = () => this.props.regionTargetingType;

    setRegionTargetingType = ({ value }) => this.props.onRegionTargetingTypeChange(value);

    getDMATargetingType = () => this.props.DMATargetingType;

    setDMATargetingType = ({ value }) => this.props.onDMATargetingTypeChange(value);

    getCityTargetingType = () => this.props.cityTargetingType;

    setCityTargetingType = ({ value }) => this.props.onCityTargetingTypeChange(value);

    getCountryCode = () => {
        const { selectedCountries } = this.props;

        return selectedCountries.length === 1 ? selectedCountries[0] : '';
    };

    addRegion = region => {
        const { selectedRegions, onRegionTargetingChange } = this.props;

        const codesToAdd = region.group ? region.group : [region.code];
        onRegionTargetingChange({
            type: this.getRegionTargetingType(),
            values: uniq([...codesToAdd, ...selectedRegions]),
        });
    };

    removeRegion = region => {
        const { selectedRegions, onRegionTargetingChange } = this.props;

        onRegionTargetingChange({
            type: this.getRegionTargetingType(),
            values: selectedRegions.filter(code => code !== region.code),
        });
    };

    clearListOfRegions = () => {
        const { onRegionTargetingChange } = this.props;

        onRegionTargetingChange({
            type: this.getRegionTargetingType(),
            values: [],
        });
    };

    addDMA = DMA => {
        const { selectedDMAs, onDMATargetingChange } = this.props;

        onDMATargetingChange({
            type: this.getDMATargetingType(),
            values: [DMA.code, ...selectedDMAs],
        });
    };

    removeDMA = DMA => {
        const { selectedDMAs, onDMATargetingChange } = this.props;

        onDMATargetingChange({
            type: this.getDMATargetingType(),
            values: selectedDMAs.filter(code => code !== DMA.code),
        });
    };

    clearListOfDMA = () => {
        const { onDMATargetingChange } = this.props;

        onDMATargetingChange({
            type: this.getDMATargetingType(),
            values: [],
        });
    };

    addCity = (city = {}) => {
        const { selectedCities, onCityTargetingChange } = this.props;
        const citiesToAdd = city.group ? city.group.map(el => ({ ...el, value: el.id })) : [city];

        onCityTargetingChange({
            type: this.getCityTargetingType(),
            values: [...citiesToAdd, ...selectedCities],
        });
    };

    removeCity = ({ id: idToRemove }) => {
        const { selectedCities, onCityTargetingChange } = this.props;

        onCityTargetingChange({
            type: this.getCityTargetingType(),
            values: selectedCities.filter(({ id }) => id !== idToRemove),
        });
    };

    clearListOfCities = () => {
        const { onCityTargetingChange } = this.props;

        onCityTargetingChange({
            type: this.getCityTargetingType(),
            values: [],
        });
    };

    isCountrySplittingLocked = () => {
        const { countryTargetingType, selectedCountries } = this.props;

        return countryTargetingType === TARGETING_TYPES.EXCLUDE || selectedCountries.length !== 1;
    };

    isDMAHiddenForCountry = () => !this.props.selectedCountries.includes(DMA_SPLIT_COUNTRY);

    prepareListView = (wholeList, codeList, targetingType) => {
        const codeMap = keyBy(wholeList, 'code');
        return codeList
            .filter(selectedCode => codeMap[selectedCode])
            .map(selectedCode => ({ ...codeMap[selectedCode], targetingType, id: selectedCode }));
    };

    fetchRegionsIfNeeded() {
        const { selectedCountries, fetchRegionsFlow, isListOfRegionsLoading, allRegions, countryTargetingLevel } =
            this.props;

        if (
            !isListOfRegionsLoading &&
            selectedCountries.length === 1 &&
            !allRegions.length &&
            countryTargetingLevel === COUNTRY_TARGETING_LEVELS.REGIONS
        ) {
            fetchRegionsFlow(selectedCountries[0]);
        }
    }

    fetchDMAIfNeeded() {
        const { selectedCountries, fetchDMAsFlow, isListOfDMAsLoading, allDMAs, countryTargetingLevel } = this.props;

        if (
            !isListOfDMAsLoading &&
            selectedCountries.length === 1 &&
            selectedCountries[0] === DMA_SPLIT_COUNTRY &&
            !allDMAs.length &&
            countryTargetingLevel === COUNTRY_TARGETING_LEVELS.DMAS
        ) {
            fetchDMAsFlow(selectedCountries[0]);
        }
    }

    render() {
        const {
            countryTargetingLevel,
            onCountryTargetingLevelChange,
            isListOfRegionsLoading,
            isListOfDMAsLoading,
            selectedCountries,
            fetchCitiesFlow,
            showDma = true,
            showPostalCode,
            showCities = true,
        } = this.props;
        const regionMsgIdPrefix = `app.country.code.${this.getCountryCode()}.region.code`;
        const dmaMsgIdPrefix = `app.country.code.${this.getCountryCode()}.dma.code`;

        return (
            <div className="sub-country-container" data-testid="sub-country-container">
                <RadioGroup
                    id="country-level"
                    selectedValue={countryTargetingLevel}
                    onChange={onCountryTargetingLevelChange}
                    Component={({ children }) => <div className={styles['radio-group']}>{children}</div>}
                >
                    <Radio
                        labelClassName={styles['radio-label']}
                        value={COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY}
                        title={
                            <FormattedMessage
                                id="campaign.editor.geo.targeting.level.country"
                                defaultMessage="Entire Country"
                            />
                        }
                        {...getDataMetricAttrs(COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY)}
                    />
                    <Radio
                        labelClassName={styles['radio-label']}
                        value={COUNTRY_TARGETING_LEVELS.REGIONS}
                        title={
                            <FormattedMessage
                                id="campaign.editor.geo.targeting.level.region"
                                defaultMessage="Region/State"
                            />
                        }
                        {...getDataMetricAttrs(COUNTRY_TARGETING_LEVELS.REGIONS)}
                        disabled={this.isCountrySplittingLocked()}
                    />
                    {showDma && !this.isDMAHiddenForCountry() && (
                        <Radio
                            labelClassName={styles['radio-label']}
                            value={COUNTRY_TARGETING_LEVELS.DMAS}
                            title="DMA"
                            {...getDataMetricAttrs(COUNTRY_TARGETING_LEVELS.DMAS)}
                            disabled={this.isCountrySplittingLocked()}
                        />
                    )}
                    {showCities && (
                        <Radio
                            labelClassName={styles['radio-label']}
                            value={COUNTRY_TARGETING_LEVELS.CITIES}
                            title={
                                <FormattedMessage id="campaign.editor.geo.targeting.level.city" defaultMessage="City" />
                            }
                            {...getDataMetricAttrs(COUNTRY_TARGETING_LEVELS.CITIES)}
                            disabled={this.isCountrySplittingLocked()}
                        />
                    )}
                    {showPostalCode && (
                        <Radio
                            labelClassName={styles['radio-label']}
                            value={COUNTRY_TARGETING_LEVELS.ZIPCODES}
                            title={
                                <FormattedMessage
                                    id="campaign.editor.geo.targeting.level.zipcode"
                                    defaultMessage="Postal Code"
                                />
                            }
                            {...getDataMetricAttrs(COUNTRY_TARGETING_LEVELS.ZIPCODES)}
                            disabled={this.isCountrySplittingLocked()}
                        />
                    )}
                </RadioGroup>

                {countryTargetingLevel === COUNTRY_TARGETING_LEVELS.REGIONS && (
                    <>
                        <TargetingDropdownBox
                            itemType={this.getRegionTargetingType()}
                            onSelectItemType={this.setRegionTargetingType}
                            selectedItems={this.getSelectedRegions()}
                            itemPlaceholder={
                                <FormattedMessage
                                    id="campaign.editor.geo.targeting.regions.placeholder"
                                    defaultMessage="Select Regions..."
                                />
                            }
                            onAddItem={this.addRegion}
                            allItems={this.getSelectableRegions()}
                            itemOptionRenderer={args =>
                                TargetingItemDropdownOption({ ...args, mrgIdPrefix: regionMsgIdPrefix })
                            }
                            isItemsLoading={isListOfRegionsLoading}
                        />
                        <CollapsibleList
                            data-testid="list-of-regions"
                            items={this.getSelectedRegions()}
                            ItemComponent={RenderItem}
                            metadata={{ targetingType: this.getRegionTargetingType(), msgIdPrefix: regionMsgIdPrefix }}
                            deleteItem={this.removeRegion}
                            itemHeight={ITEM_HEIGHT}
                            clearItems={this.clearListOfRegions}
                            listHeaderTitle={
                                <FormattedMessage
                                    id="campaign.editor.geo.targeting.regions"
                                    defaultMessage="Selected regions"
                                />
                            }
                            showLessLabel={
                                <FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />
                            }
                            showAllLabel={
                                <FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />
                            }
                            containerClassName={styles['list']}
                        />
                    </>
                )}
                {countryTargetingLevel === COUNTRY_TARGETING_LEVELS.DMAS && (
                    <>
                        <TargetingDropdownBox
                            itemType={this.getDMATargetingType()}
                            onSelectItemType={this.setDMATargetingType}
                            selectedItems={this.getSelectedDMAS()}
                            itemPlaceholder={
                                <FormattedMessage
                                    id="campaign.editor.geo.targeting.DMAs.placeholder"
                                    defaultMessage="Select DMAs..."
                                />
                            }
                            onAddItem={this.addDMA}
                            allItems={this.getSelectableDMAS()}
                            itemOptionRenderer={args =>
                                TargetingItemDropdownOption({ ...args, msgIdPrefix: dmaMsgIdPrefix })
                            }
                            isItemsLoading={isListOfDMAsLoading}
                        />
                        <CollapsibleList
                            data-testid="list-of-dma"
                            items={this.getSelectedDMAS()}
                            ItemComponent={RenderItem}
                            metadata={{ targetingType: this.getDMATargetingType(), mrgIdPrefix: dmaMsgIdPrefix }}
                            deleteItem={this.removeDMA}
                            itemHeight={ITEM_HEIGHT}
                            clearItems={this.clearListOfDMA}
                            listHeaderTitle={
                                <FormattedMessage
                                    id="campaign.editor.geo.targeting.DMAs"
                                    defaultMessage="Selected DMA"
                                />
                            }
                            showLessLabel={
                                <FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />
                            }
                            showAllLabel={
                                <FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />
                            }
                            containerClassName={styles['list']}
                        />
                    </>
                )}
                {countryTargetingLevel === COUNTRY_TARGETING_LEVELS.CITIES && (
                    <CityTargeting
                        type={this.getCityTargetingType()}
                        values={this.getSelectedCities()}
                        country={get(selectedCountries, '0', null)}
                        fetchCitiesFlow={fetchCitiesFlow}
                        addCity={this.addCity}
                        clearItems={this.clearListOfCities}
                        removeCity={this.removeCity}
                        setTargetingType={this.setCityTargetingType}
                    />
                )}
                {countryTargetingLevel === COUNTRY_TARGETING_LEVELS.ZIPCODES && <ZipCodeTargeting />}
            </div>
        );
    }
}

SubCountryTargeting.propTypes = {
    countryTargetingType: PropTypes.string.isRequired,
    regionTargetingType: PropTypes.string.isRequired,
    DMATargetingType: PropTypes.string.isRequired,
    cityTargetingType: PropTypes.string.isRequired,
    countryTargetingLevel: PropTypes.string,
    selectedCountries: PropTypes.array,
    allRegions: PropTypes.array,
    selectedRegions: PropTypes.array,
    allDMAs: PropTypes.array,
    selectedDMAs: PropTypes.array,
    selectedCities: PropTypes.array,
    onCountryTargetingLevelChange: PropTypes.func.isRequired,
    onRegionTargetingTypeChange: PropTypes.func.isRequired,
    onDMATargetingTypeChange: PropTypes.func.isRequired,
    onCityTargetingTypeChange: PropTypes.func.isRequired,
    onRegionTargetingChange: PropTypes.func.isRequired,
    onDMATargetingChange: PropTypes.func.isRequired,
    onCityTargetingChange: PropTypes.func.isRequired,
    fetchRegionsFlow: PropTypes.func.isRequired,
    fetchDMAsFlow: PropTypes.func.isRequired,
    isListOfRegionsLoading: PropTypes.bool,
    isListOfDMAsLoading: PropTypes.bool,
    showDma: PropTypes.bool,
    showPostalCode: PropTypes.bool,
};

SubCountryTargeting.defaultProps = {
    countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
    selectedCountries: [],
    allRegions: [],
    selectedRegions: [],
    selectedCities: [],
    allDMAs: [],
    selectedDMAs: [],
};

export default SubCountryTargeting;
