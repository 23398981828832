import { HIGH_IMPACT_PACKAGE_OPTIONS } from '../../modules/common-campaign-form/config';

const ADS_CONSOLE_POLICY_STATE = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
};

const transformHighImpactPackageFromGW = campaign => {
    const { campaignTargeting } = campaign;
    if (!campaignTargeting) {
        return campaign;
    }
    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            highImpactPackage:
                campaignTargeting.highImpactPackage === ADS_CONSOLE_POLICY_STATE.ENABLED
                    ? [HIGH_IMPACT_PACKAGE_OPTIONS.HIGH_IMPACT_PACKAGE]
                    : [],
        },
    };
};
const transformHighImpactPackageToGW = campaign => {
    const { campaignTargeting } = campaign;
    if (campaignTargeting) {
        const { highImpactPackage } = campaignTargeting;
        if (!highImpactPackage) {
            return campaign;
        }

        return {
            ...campaign,
            campaignTargeting: {
                ...campaignTargeting,
                highImpactPackage: campaignTargeting.highImpactPackage?.length
                    ? ADS_CONSOLE_POLICY_STATE.ENABLED
                    : ADS_CONSOLE_POLICY_STATE.DISABLED,
            },
        };
    } else {
        return campaign;
    }
};

export { transformHighImpactPackageFromGW, transformHighImpactPackageToGW, ADS_CONSOLE_POLICY_STATE };
