import { useDispatch } from 'react-redux';
import { favoriteMedia } from 'modules/campaigns/flows';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { GALLERY_ITEM_DROPDOWN_OPTIONS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';

export const useStudioGalleryItemDropdown = () => {
    const { galleryItemDropdownOptions, setMode, accountId, campaignId, mode } = useCreativeStudioContext();
    const dispatch = useDispatch();
    const eventsLocationPrefix = CREATIVE_STUDIO_MODE_EVENTS_MAP[mode];

    const onClick = async (selectedOption, { itemUrl, isFavorited }) => {
        if (selectedOption === GALLERY_ITEM_DROPDOWN_OPTIONS.SAVE_TO_MEDIA_LIBRARY) {
            dispatch(favoriteMedia({ accountId, thumbnailUrl: itemUrl, isFavorited }));
        } else {
            setMode(selectedOption);
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${eventsLocationPrefix}: ${selectedOption} Option Clicked`,
            taboolaCampaignId: campaignId,
            value: `Item URL: ${itemUrl}`,
        });
    };

    return { options: galleryItemDropdownOptions, onClick };
};
