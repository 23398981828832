import { isNetworkAccountType } from 'modules/account-management';
import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    EllipsisCellRenderer,
    FormattedMessageHeaderRenderer,
    PerformanceRulesActionsCellRenderer,
    StateCellRenderer,
    withCampaignEditIndication,
} from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    leftPinnedColumn,
    lockedColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { duplicatePerformanceRule } from 'modules/performance-rules/flows/duplicatePerformanceRule';
import { savePerformanceRule } from '../../../flows/savePerformanceRule';

const performanceRulesReportColumnDefinition = [
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
        cellRendererParams: {
            ...FIELDS.IS_ACTIVE.renderParams,
            reportId: REPORT_TYPE.PERFORMANCE_RULES,
            defaultMessage: 'Rule Status',
        },
        cellRendererFramework: StateCellRenderer,
        valueChangeHandler: savePerformanceRule,
        csv: [
            {
                headerName: 'Is Active',
                field: FIELDS.IS_ACTIVE.field,
            },
        ],
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_NAME.label,
        field: FIELDS.PERFORMANCE_RULE_NAME.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_NAME.cellRenderer,
        cellRendererParams: FIELDS.PERFORMANCE_RULE_NAME.renderParams,
    },
    {
        headerName: FIELDS.ACCOUNT_NAME.label,
        field: FIELDS.ACCOUNT_NAME.field,
        cellRendererFramework: EllipsisCellRenderer,
        headerComponentParams: FIELDS.ACCOUNT_NAME.headerComponentParams,
        visibilityCondition: ({ selectedAccount: { type } }) => isNetworkAccountType(type),
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_TYPE.label,
        field: FIELDS.PERFORMANCE_RULE_TYPE.field,
        cellRendererParams: {
            msgIdPrefix: `performance.rules`,
        },
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_TYPE.cellRenderer,
        csvValueGetter: FIELDS.PERFORMANCE_RULE_TYPE.csvValueGetter,
    },
    {
        headerName: FIELDS.LAST_MODIFIED_BY_PERFORMER.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.LAST_MODIFIED_BY_PERFORMER.headerComponentParams,
        field: FIELDS.LAST_MODIFIED_BY_PERFORMER.field,
        cellRendererFramework: FIELDS.LAST_MODIFIED_BY_PERFORMER.cellRendererFramework,
    },
    {
        headerName: FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.label,
        field: FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.field,
        valueFormatter: FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.valueFormatter,
        csvValueGetter: FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.csvValueGetter,
        headerComponentParams: FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.headerComponentParams,
    },
    {
        headerName: FIELDS.APPLIES_TO_CAMPAIGNS.label,
        field: FIELDS.APPLIES_TO_CAMPAIGNS.field,
        cellRendererFramework: FIELDS.APPLIES_TO_CAMPAIGNS.cellRenderer,
        headerComponentParams: FIELDS.APPLIES_TO_CAMPAIGNS.headerComponentParams,
        sortable: false,
        csv: false,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        cellRendererFramework: PerformanceRulesActionsCellRenderer,
        csv: false,
        cellRendererParams: {
            onDuplicate: duplicatePerformanceRule,
        },
    },
];
export default normalizeColumnDefs(
    performanceRulesReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULES].id,
    withCampaignEditIndication
);
