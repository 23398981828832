import React, { useState } from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import styles from './timeSelector.module.scss';

const minuteOptions = [0o0, 15, 30, 45].map(minute => ({
    value: minute,
    label: minute === 0 ? '00' : minute,
}));

const formatHour = hour => {
    if (hour === 24) {
        return '00';
    }
    return `${hour < 10 ? '0' : ''}${hour}`;
};

const hourOptions = Array.from({ length: 24 }, (_, index) => ({
    value: index,
    label: formatHour(index),
}));

export const TimeSelector = ({ initialDate, date, onChangeDate }) => {
    const [startHour, onStartHourChange] = useState(initialDate ? date.getUTCHours() : null);
    const [startMinute, onStartMinuteChange] = useState(initialDate ? date.getUTCMinutes() : null);
    const handleHourChange = ({ value }) => {
        onStartHourChange(value);
        date.setUTCHours(value);
        onChangeDate(date);
    };
    const handleMinuteChange = ({ value }) => {
        onStartMinuteChange(value);
        date.setUTCMinutes(value);
        onChangeDate(date);
    };

    return (
        <>
            <td className={styles['column-body-hour']}>
                <DropdownMenu
                    dropdownWrapperClassName={styles['dropdown-time']}
                    onChange={handleHourChange}
                    selectedValueObject={{ value: startHour }}
                    options={hourOptions}
                />
            </td>
            <td className={styles['column-body']}>
                <div className={styles['column-end-container']}>
                    <span>:</span>
                    <DropdownMenu
                        dropdownWrapperClassName={styles['dropdown-time']}
                        className={styles['drop-down-menu']}
                        onChange={handleMinuteChange}
                        selectedValueObject={{ value: startMinute }}
                        options={minuteOptions}
                    />
                </div>
            </td>
        </>
    );
};
