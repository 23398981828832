import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { ScheduledReportDeliverySection } from '../components/ScheduledReportsDeliverySection/ScheduledReportDeliverySection';
import { ScheduledReportsEditHeader } from '../components/ScheduledReportsEditHeader/ScheduledReportsEditHeader';
import { ScheduledReportsFormButtons } from '../components/ScheduledReportsFormButtons/ScheduledReportsFormButtons';
import { ScheduledReportSetupSection } from '../components/ScheduledReportsSetupSection/ScheduledReportSetupSection';
import { SCHEDULED_REPORTS_EDIT_ROUTE_PATH } from '../config';
import { useScheduledReportsEditorState } from '../hooks/useScheduledReportEditorState';
import styles from './ScheduledReportEditorForm.module.scss';

const ScheduledReportEditorForm = ({ onCancel }) => {
    const { submit, submitAndExecuteWrapper } = useScheduledReportsEditorState();
    const { isSubmitDisabled, submitInProgress, fetchStatus } = useFormDataContext();
    useUnsavedChangesController(SCHEDULED_REPORTS_EDIT_ROUTE_PATH);

    return (
        <FormInitBase className={styles['wrapper']}>
            <FormLayout
                formButtonComponent={props => (
                    <ScheduledReportsFormButtons {...props} submitAndExecuteWrapper={submitAndExecuteWrapper} />
                )}
                onCancel={onCancel}
                onSubmit={submit}
                header={<ScheduledReportsEditHeader status={fetchStatus} />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['scheduled-reports-editor']}
                bodyContainerClassName={styles['scheduled-reports-editor-body']}
                footerContainerClassName={styles['scheduled-reports-editor-footer']}
            >
                <ScheduledReportSetupSection showNameField={false} />
                <ScheduledReportDeliverySection />
            </FormLayout>
        </FormInitBase>
    );
};
const ScheduledReportsEditorWithFormDataProvider = withFormDataProvider(ScheduledReportEditorForm, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { ScheduledReportsEditorWithFormDataProvider as ScheduledReportEditorForm };
