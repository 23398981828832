import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations/flags';
import {
    isNetworkAccountType,
    isVideoAccount,
    isPmpAccount,
    isOpenExchangeAccount,
} from '../../../../account-management/accountType.js';

export const getIsAndLogicAllowedOnAccount = selectedAccount => {
    const { type, partnerTypes } = selectedAccount;
    const isNetworkAccount = isNetworkAccountType(type);
    const isSupportedAccountType =
        !isVideoAccount(partnerTypes) && !isPmpAccount(selectedAccount) && !isOpenExchangeAccount(selectedAccount);

    // Enable it on network accounts and supported account types
    return isNetworkAccount || isSupportedAccountType;
};
export const getIsMarketplaceAudiencesAndLogicEnabled = (accountConfig, selectedAccount) => {
    const isAndLogicEnabled = accountConfig?.[FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED] === 'true';

    return isAndLogicEnabled && getIsAndLogicAllowedOnAccount(selectedAccount);
};
