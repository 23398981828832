import { isUndefined, isObject, pickBy, mapValues, negate, isArray } from 'lodash';
import updateObject from './updateObject';

export const immutableAddItemByIndex = (array, index, updatedItem) => {
    const arr = [...array];
    arr[index] = updatedItem;
    return arr;
};

export const arrayReduce = arr =>
    arr.reduce((res, item, index) => {
        res[item.id] = { ...item, index };
        return res;
    }, {});

const sortByIndex = (a, b) => a.index - b.index;

export const reduceAndSort = (listObject, sortFn = sortByIndex) =>
    Object.values(listObject)
        .sort(sortFn)
        .map(({ index, ...rest }) => rest);

export const reduceAndSortObjectKeys = (obj = {}, sortFn) =>
    Object.keys(obj).reduce((res, key) => {
        res[key] = reduceAndSort(obj[key], sortFn);
        return res;
    }, {});

export const extractMaxIndex = collection =>
    !collection ? 0 : Object.values(collection).reduce((max, item) => (max > item.index ? max : item.index), -1);

export const append = (collection, items) => {
    if (!items || !items.length) {
        return collection;
    }

    const newItems = items.reduce((res, item) => updateObject(res, { [item.id]: item }), {});
    return updateObject(collection, newItems);
};

export const appendLast = (collection, itemId, item, getIndexFn = extractMaxIndex) => {
    if (!item) {
        return collection;
    }

    return append(collection, [{ id: itemId, ...item, index: getIndexFn(collection) + 1 }]);
};

export const removeItem = (collection, item) => {
    if (!item) {
        return collection;
    }

    const { [item.id]: removedItem, ...restCollection } = collection;
    return restCollection;
};

export const updateListItem = (list, itemId, itemChanges) => {
    const item = list[itemId];

    return !item
        ? list
        : {
              ...list,
              [itemId]: updateObject(item, itemChanges),
          };
};

export const deepClearUndefined = obj => {
    if (!isObject(obj)) {
        return obj;
    }

    if (isArray(obj)) {
        return obj.map(deepClearUndefined).filter(negate(isUndefined));
    }

    return pickBy(mapValues(obj, deepClearUndefined), negate(isUndefined));
};
