'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var TOOLTIP_POSITION = {
    TOP: 'top',
    TOP_START: 'top-start',
    TOP_END: 'top-end',
    BOTTOM: 'bottom',
    BOTTOM_START: 'bottom-start',
    BOTTOM_END: 'bottom-end',
    LEFT: 'left',
    LEFT_START: 'left-start',
    LEFT_END: 'left-end',
    RIGHT: 'right',
    RIGHT_START: 'right-start',
    RIGHT_END: 'right-end'
};

exports.default = TOOLTIP_POSITION;