import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useResourcesApi } from '../../../../../../../services/api';

export const usePresetsOptions = selectedPresetsIds => {
    const [presets, setPresets] = useState([]);
    const { getPresets, getPresetsByIds } = useResourcesApi();

    const options = useMemo(() => presets.map(preset => (preset ? preset : {})), [presets]);

    const loadPresetsPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getPresets(searchText, page, pageSize);
            const list = results.filter(preset => !selectedPresetsIds.includes(preset.id));
            return [list, total, results.length - list.length];
        },
        [getPresets, selectedPresetsIds]
    );

    const { data: initialSelectedPresets } = useQuery(
        ['presets-by-ids'],
        () => (!isEmpty(selectedPresetsIds) ? getPresetsByIds({ ids: selectedPresetsIds }) : []),
        {
            refetchOnWindowFocus: false,
            select: data =>
                data?.results?.map(({ value, name, id, ...rest }) => ({
                    label: value,
                    value,
                    id,
                    ...rest,
                })),
        }
    );

    return {
        options,
        setPresets,
        loadPresetsPage,
        initialSelectedPresets,
    };
};
