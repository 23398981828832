import React from 'react';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownField } from '../../../campaigns/components/Form';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { useAudienceSeed } from './hooks/useAudienceSeed';
import styles from './audienceSeedField.module.scss';

const DropdownFieldWithIndication = withIndication(DropdownField);

export const AudienceSeedField = ({ account: { accountName: selectedAccountId } }) => {
    const {
        audienceSeedId,
        audienceChangeHandler,
        audienceList = [],
        isLoadingAudienceList,
        failedValidationData,
    } = useAudienceSeed(selectedAccountId);
    const { mode } = useFormFieldValue({});

    return (
        <DropdownFieldWithIndication
            label={<FormattedMessage id="audience.editor.select.audience.title" defaultMessage="Select Audience" />}
            description={
                <FormattedMessage
                    id="audience.editor.select.audience.tooltip.text"
                    defaultMessage="Select an audience that will be used as seed for the Predictive Audience"
                />
            }
            disabled={isLoadingAudienceList || mode === FORM_MODES.EDIT}
            required={true}
            id="validateAudienceSeedFieldMandatory"
            placeholder={
                <FormattedMessage id="audience.editor.select.audience.placeholder" defaultMessage="Click to Select" />
            }
            selectedValueObject={{
                value: audienceSeedId,
            }}
            options={audienceList}
            onChange={audienceChangeHandler}
            searchable={true}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'CreatePredictiveAudienceSeedField',
            }}
            containerClass={styles['input']}
            {...failedValidationData}
        />
    );
};

AudienceSeedField.propTypes = {
    account: PropTypes.object.isRequired,
};

export default AudienceSeedField;
