import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { PaginatedDropdownField } from 'modules/campaigns/components/Form';
import { ErrorMessageIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import useValidationContext from 'modules/taboola-common-frontend-modules/validations/hooks/useValidationContext';
import { useCampaignsOptions } from '../../hooks';
import CampaignsSmartListItem from './CampaignsSmartListItem';
import styles from './campaignsSmartList.module.scss';

const classNameBuilder = classnames.bind(styles);

const defaultListHeaderTitle = <FormattedMessage id="creative.editor.setup" defaultMessage="Selected Campaigns" />;

const CampaignsSmartList = ({
    validations,

    selectedCampaigns,
    selectedAccount,

    onRemove,
    onAdd,
    onClearAll,

    isDisabled,

    validationId,
    metricsAttributes,
    placeholderProps,

    label,
    placeholder,
    helpText,
    listHeaderTitle = defaultListHeaderTitle,
    listItemComponent,
}) => {
    const [error, setError] = useState(null);

    const { loadCampaignsPage, options, optionsLoaded, setOptions, fetchedCampaignList } = useCampaignsOptions(
        selectedCampaigns,
        selectedAccount
    );

    const finalPlaceholderProps = useMemo(() => {
        const { className, loaderClassName, ...rest } = placeholderProps;

        return {
            className: classNameBuilder('placeholder-container', className),
            loaderClassName: classNameBuilder('loader', loaderClassName),
            preserveAspectRatio: 'none',
            ...rest,
        };
    }, [placeholderProps]);
    const disabledByOptions = !optionsLoaded || !options.length;
    const validationContext = useValidationContext(fetchedCampaignList);
    return (
        <div>
            <PaginatedDropdownField
                id={validationId}
                validationId={validationId}
                label={label}
                placeholder={placeholder}
                helpText={helpText}
                searchable
                enabledWhileSearching
                validations={validations}
                containerClass={styles['form-field']}
                dropdownWrapperClassName={styles['dropdown']}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'CommonCampaignsSmartList',
                    ...metricsAttributes,
                }}
                placeholderProps={finalPlaceholderProps}
                valueToValidate={selectedCampaigns}
                validationContext={validationContext}
                styles={{
                    control: defaultStyles => ({
                        ...defaultStyles,
                        width: '100%',
                        border: 0,
                    }),
                }}
                onChange={onAdd}
                disabled={isDisabled || disabledByOptions}
                disableMessageIndication
                controlShouldRenderValue={false}
                options={options}
                totalItemCount={optionsLoaded ? options.length : null}
                loadPage={loadCampaignsPage}
                onOptionsLoaded={setOptions}
                onErrorStateChange={setError}
            />
            <div className={styles['error']}>
                <ErrorMessageIndication {...error} />
            </div>
            <CollapsibleList
                items={selectedCampaigns}
                id="pixel-based-audience-url-condition-list"
                ItemComponent={listItemComponent}
                deleteItem={isDisabled ? null : onRemove}
                clearItems={isDisabled ? null : onClearAll}
                listHeaderTitle={listHeaderTitle}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
            />
        </div>
    );
};

CampaignsSmartList.defaultProps = {
    placeholderProps: {},
    listItemComponent: CampaignsSmartListItem,
};

export default CampaignsSmartList;
