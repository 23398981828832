import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { UrlValidatedInput } from '../../../../creative-creator/components/UrlList/UrlValidatedInput';
import styles from './videoCreativeCreatorLandingPageField.module.scss';

const validations = [];

export const VideoCreativeCreatorLandingPageField = () => {
    const { formatMessage } = useIntl();

    return (
        <FormField
            containerClass={styles['input']}
            label={formatMessage({
                id: 'video.creative.creator.setup.landing.page.title',
                defaultMessage: 'Landing Page URL',
            })}
            helpText={
                <FormattedMessage
                    id="video.creative.creator.setup.landing.page.helptext"
                    defaultMessage="Landing pages are web pages where you want to drive users  after they click on your creative. <a>Landing Page Best Practices</a>"
                />
            }
        >
            <UrlValidatedInput
                className={styles['editable-text']}
                field="landingPageUrl"
                validations={validations}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="VideoCreativeCreatorLandingPageField"
                isResetFieldToInitialOnUnmount={true}
            />
        </FormField>
    );
};
