import React from 'react';
import classnames from 'classnames/bind';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { SideDrawer as ExternalSideDrawer, useIsMobile } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from '../../modules/taboola-common-frontend-modules/account-configurations';
import styles from './sideDrawer.module.scss';

const classNameBuilder = classnames.bind(styles);

export const DRAWER_MODE = {
    SMALL: 'side-drawer',
    MEDIUM: 'medium-drawer',
    LARGE: 'wider-drawer',
};

export const SideDrawer = ({ drawerMode = DRAWER_MODE.SMALL, openDrawer, ...restProps }) => {
    const isMobile = useIsMobile();

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    //TODO: remove this unnecessary component DEV-167693;
    if (isMultiLayerDrawerEnabled) {
        if (isFunction(restProps.children)) {
            return close => restProps.children(close);
        }
        return <>{restProps.children}</>;
    }

    return (
        <ExternalSideDrawer
            anchorDirection="right"
            drawerClassName={classNameBuilder(drawerMode)}
            openDrawer={openDrawer}
            fitToScreen={isMobile}
            {...restProps}
        />
    );
};

SideDrawer.propTypes = {
    drawerMode: PropTypes.string,
    ...ExternalSideDrawer.propTypes,
};
