import React from 'react';
import { errorMessagesUtils } from 'services/utils';
import { creativesApi } from '../../../services/api';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

const successIndication = {
    message: <FormattedMessage id="creatives.save.success" defaultMessage="Ads successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="creatives.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const updateCreativesBase =
    (accountId, creativeIds, changes, { suppressSuccessIndication, suppressErrorIndication } = {}) =>
    dispatch => {
        const bulkUpdate = {
            staticImagePojo: {
                ids: creativeIds,
                update: changes,
            },
        };

        return creativesApi
            .bulkEditCreatives(accountId, bulkUpdate)
            .then(({ sponsoredItems: imagesResults }) => {
                !suppressSuccessIndication && dispatch(addIndication(successIndication));
                return { results: imagesResults };
            })
            .catch(error => {
                !suppressErrorIndication && dispatch(addIndication(generateErrorIndication(error)));
                return { error };
            });
    };

export default updateCreativesBase;
