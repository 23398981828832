import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { campaignsApi } from 'services/api';

const emptyList = [];

export const useFetchedCampaignList = (campaignIds, advertiserName) => {
    const { data } = useQuery(
        ['fetchedCampaignList', advertiserName],
        () =>
            campaignsApi.getCampaigns({
                accountId: advertiserName,
                campaignIds,
            }),
        {
            enabled: !!advertiserName && !isEmpty(campaignIds),
            select: data => data?.results,
        }
    );
    if (!campaignIds || isEmpty(campaignIds)) {
        return emptyList;
    }
    return data;
};
