import React from 'react';

export const DinersLogo = () => {
    return (
        <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.1002 30.1589C48.2686 30.1984 55.7243 23.4366 55.7243 15.2105C55.7243 6.2149 48.2686 -0.00299088 40.1002 1.07929e-06H33.0703C24.804 -0.00299088 18 6.21675 18 15.2105C18 23.4383 24.804 30.1984 33.0703 30.1589H40.1002Z"
                fill="url(#paint0_linear_2005_151507)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.0994 1.25665C25.5458 1.25905 19.425 7.43744 19.4232 15.0628C19.425 22.6869 25.5458 28.8647 33.0994 28.867C40.6546 28.8647 46.7767 22.6869 46.7778 15.0628C46.7767 7.43744 40.6546 1.25905 33.0994 1.25665ZM24.4304 15.0628C24.4375 11.3366 26.7436 8.15917 29.9956 6.8965V23.2272C26.7436 21.9653 24.4374 18.7895 24.4304 15.0628ZM36.2019 23.2308V6.89601C39.455 8.15562 41.7647 11.3349 41.7706 15.0629C41.7647 18.792 39.455 21.9688 36.2019 23.2308Z"
                fill="#F4F6F7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2005_151507"
                    x1="63.5528"
                    y1="24.3873"
                    x2="53.878"
                    y2="-7.22969"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#009BE0" />
                    <stop offset="1" stopColor="#006BA8" />
                </linearGradient>
            </defs>
        </svg>
    );
};
