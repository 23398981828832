import React, { useState, useRef } from 'react';
import styles from './imageWithInfo.module.scss';

export const ImageWithInfo = ({ src, fileName }) => {
    const imageElement = useRef(null);
    const [imageDimensions, setImageDimensions] = useState(null);

    return (
        <div className={styles['container']}>
            <img
                src={src}
                alt="logo"
                ref={imageElement}
                onLoad={() => {
                    setImageDimensions({
                        height: imageElement.current.naturalHeight,
                        width: imageElement.current.naturalWidth,
                    });
                }}
                className={styles['image']}
            />

            <div>
                <div className={styles['file-name']}>{fileName}</div>
                {imageDimensions && (
                    <div className={styles['dimensions']}>
                        {imageDimensions.width} x {imageDimensions.height}
                    </div>
                )}
            </div>
        </div>
    );
};
