import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AppHeader, TooltipV2Root as TooltipRoot } from 'taboola-ultimate-ui';
import { resetStateToInitial } from 'actions';
import { UserMenu } from 'components';
import { localizeLinks } from 'components/ButtonsPanel/utils';
import { HOME_PATH } from 'components/RouteList/RouteList';
import { useNonAuthAccess } from 'hooks/useNonAuthAccess';
import { logout } from 'modules/taboola-common-frontend-modules/authentication';
import { userSelector } from 'modules/taboola-common-frontend-modules/authentication/selectors/baseSelectors';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountIdSelector } from 'selectors/selectedAccount';
import Logo from '../../../../components/AppHeader/components/Logo';
import { isIframeMode } from '../../../taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { isSeparateAppInstanceForbidden } from '../../../taboola-common-frontend-modules/iframe/utils/isSeparateAppInstanceForbidden';
import GenericError from '../GenericError/GenericError';
import svg from './error404.svg';
import styles from './errorPage.module.scss';

const ErrorPage = ({ config: { legal } }) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const logoutHandler = useCallback(() => dispatch(logout), [dispatch]);
    const user = useSelector(userSelector);
    const accountId = useSelector(selectedAccountIdSelector);
    const shouldRenderUserMenu = !isSeparateAppInstanceForbidden();
    const localLegal = useMemo(
        () => localizeLinks(legal, formatMessage, { accountId }),
        [legal, formatMessage, accountId]
    );
    const nonAuthAccess = useNonAuthAccess();
    // Do reset on unmount
    useEffect(
        () => () => {
            dispatch(resetStateToInitial());
        },
        [dispatch]
    );

    return (
        <TooltipRoot className={styles['error-container']}>
            {!isIframeMode() && (
                <AppHeader
                    brandingWidth={10}
                    logoComponent={
                        <Logo
                            className={styles['taboola-logo']}
                            color={styles.defaultLogoColor}
                            path={HOME_PATH}
                            onClick={() => window.location.reload()}
                        />
                    }
                >
                    {!nonAuthAccess && user['full_name'] && shouldRenderUserMenu && (
                        <div className={styles['buttons-panel']}>
                            <UserMenu
                                className={styles['user-name']}
                                userName={user['full_name']}
                                email={user.username}
                                topLinks={[localLegal.privacy]}
                                bottomLinks={[localLegal.termOfUse]}
                                buttonText={<FormattedMessage id="app.actionButtons.signOut" />}
                                onButtonClick={logoutHandler}
                            />
                        </div>
                    )}
                </AppHeader>
            )}
            <GenericError
                heading={<FormattedMessage id="errorPage.heading" />}
                message={<FormattedMessage id="errorPage.message" />}
                errorImgUrl={svg}
            />
        </TooltipRoot>
    );
};

ErrorPage.propTypes = { config: PropTypes.object };

export default ErrorPage;
