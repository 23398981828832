import React from 'react';
import PropTypes from 'prop-types';
import { HelpTooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import ReloadButton from '../../../ReloadButton/ReloadButton';
import EmptyThumbnailSVG from '../EmptyThumbnailSVG/EmptyThumbnailSVG';
import styles from './thumbnailError.module.scss';

const ThumbnailError = ({ onButtonClick, buttonLabel, disabled, messageId, defaultMessage, helpText }) => (
    <div className={styles['container']}>
        <EmptyThumbnailSVG />
        <div className={styles['content']}>
            <div className={styles['heading']}>
                <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                {helpText && (
                    <span className={styles['help-tooltip-container']}>
                        <HelpTooltip iconClassName={styles['help-tooltip-icon']}>{helpText}</HelpTooltip>
                    </span>
                )}
            </div>
            {onButtonClick && (
                <ReloadButton onButtonClick={onButtonClick} buttonLabel={buttonLabel} disabled={disabled} />
            )}
        </div>
    </div>
);

ThumbnailError.propTypes = {
    onButtonClick: PropTypes.func,
    buttonLabel: PropTypes.node,
    disabled: PropTypes.bool,
    helpText: PropTypes.node,
    error: PropTypes.object,
};

ThumbnailError.defaultProps = {
    disabled: false,
    error: {},
};

export default ThumbnailError;
