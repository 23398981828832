import { useCallback, useEffect } from 'react';
import { identity } from 'lodash';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { APP_EVENT_TYPE } from '../appEventType';
import { transformChangeEvent, transformClickEvent, transformHoverEvent } from '../utils/UIEventTransformers';
import { parseUIEvent } from '../utils/parseUIEvent';

const eventConfig = [
    { type: APP_EVENT_TYPE.CLICK, DOMEventType: 'click', transformer: transformClickEvent },
    { type: APP_EVENT_TYPE.CHANGE, DOMEventType: 'change', transformer: transformChangeEvent },
    { type: APP_EVENT_TYPE.HOVER, DOMEventType: 'mouseenter', transformer: transformHoverEvent },
];

export const useAppEventUICollector = push => {
    const isEnabled = useConfigMatch({ [FEATURE_FLAGS.CUSTOM_EVENT_AGGREAGATOR_ENABLED]: 'true' });
    const listener = useCallback(
        (event, type, transformer = identity) => {
            const transformedEvent = transformer(parseUIEvent(event));
            if (transformedEvent) {
                transformedEvent.type = type;
                push(transformedEvent);
            }
        },
        [push]
    );

    useEffect(() => {
        if (!isEnabled) {
            return;
        }
        const mappedConfig = eventConfig.map(({ DOMEventType, type, transformer }) => ({
            DOMEventType,
            listener: event => listener(event, type, transformer),
        }));

        mappedConfig.forEach(({ DOMEventType, listener }) => {
            document.body.addEventListener(DOMEventType, listener, {
                capture: true,
                passive: true,
            });
        });

        const cleaner = () => {
            mappedConfig.forEach(({ DOMEventType, listener }) => {
                document.body.removeEventListener(DOMEventType, listener, {
                    capture: true,
                    passive: true,
                });
            });
        };

        return cleaner;
    }, [listener, isEnabled]);
};
