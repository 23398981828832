import React, { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { errorMessagesUtils } from 'services/utils';
import { useDBStorage } from '../../../../../../taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { REPORT_PRESET } from '../../../config/pickers';
import { getPresetDBKey } from '../../../hooks/useColumnState';
import { useReportPresetName } from '../../../hooks/useReportPresetName';

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.campaigns.reports.columnPicker.delete.error"
            defaultMessage="Could not delete preset"
        />
    ),
});

export const useDeletePresetCallback = selectedReport => {
    const [reportPresetName, setReportPresetName] = useReportPresetName();
    const { remover } = useDBStorage();
    const { open: openModal } = useModal();
    const dispatch = useDispatch();

    const deleteCustomPreset = useCallback(
        (presetName, label) => {
            return async e => {
                e.stopPropagation();
                const result = await openModal({
                    title: (
                        <FormattedMessage
                            id="app.campaigns.reports.columnPicker.delete.modal.title"
                            tagName="h2"
                            values={{ preset: label }}
                            defaultMessage={'Delete Preset "{preset}"?'}
                        />
                    ),
                    content: (
                        <FormattedMessage
                            id="app.campaigns.reports.columnPicker.delete.modal.content"
                            values={{ preset: label }}
                            defaultMessage={
                                'You are about to delete the columns preset "{preset}", do you wish to proceed?'
                            }
                        />
                    ),
                    formProps: {
                        submitButtonText: (
                            <FormattedMessage
                                id="app.campaigns.reports.columnPicker.delete.modal.button.delete"
                                defaultMessage="Delete"
                            />
                        ),
                    },
                });
                if (!result) {
                    return;
                }
                batch(async () => {
                    try {
                        if (reportPresetName === presetName) {
                            setReportPresetName(REPORT_PRESET.DEFAULT);
                        }
                        const presetDBKey = getPresetDBKey(selectedReport, presetName);
                        await remover(presetDBKey);
                    } catch (error) {
                        dispatch(addIndication(generateErrorIndication(error)));
                    }
                });
            };
        },
        [remover, openModal, reportPresetName, selectedReport, setReportPresetName, dispatch]
    );

    return deleteCustomPreset;
};
