import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Button, CircleCloseIcon, IconButton } from 'tuui';
import { DropdownMenu } from 'components';
import { DayTimeBoostColumn } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/components/DayTimeBoostColumn/DayTimeBoostColumn';
import { HOUR_OPTIONS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/consts/dayTimeDaysOptions';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { servingTypeOptions } from '../consts/dayTimeServingTypeOptions';
import { dayTimeValidations } from '../validations/dayTimePartingValidations';
import styles from './dayTimeInfo.module.scss';

const Border = () => <div className={styles['border']} />;

const errorStyle = styles.errorStyling;

export const DayTimeInfo = ({ id, rows, onDelete, showBoost = true, field, showType = true, dayOptions }) => {
    const { value: fromHour, onChange: setFromHour } = useFormFieldValue({
        field: `fromHour`,
    });
    const { value: untilHour, onChange: setUntilHour } = useFormFieldValue({
        field: `untilHour`,
    });
    const { value: type, onChange: setType } = useFormFieldValue({
        field: `type`,
    });
    const {
        value: day,
        onChange: setDay,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'day',
        validations: dayTimeValidations,
        validationDependencies: { rows, fromHour, untilHour, id, field, type },
        validateOnParentChange: true,
    });

    const onFromHourChange = useCallback(
        ({ value }) => {
            if (value > untilHour) {
                setUntilHour(value + 1);
            }
            setFromHour(value);
        },
        [setUntilHour, setFromHour, untilHour]
    );

    const fromHourOptions = useMemo(() => HOUR_OPTIONS.slice(0, 24), []);
    const endHourOptions = useMemo(() => HOUR_OPTIONS.slice(fromHour + 1), [fromHour]);

    const dropDownStyles = useMemo(
        () => ({
            control: props => ({
                ...props,
                ...(isEmpty(failedValidationData) ? {} : { border: errorStyle }),
            }),
        }),
        [failedValidationData]
    );

    return (
        <tr>
            {showType && (
                <td className={styles['column-body']}>
                    <DropdownMenu
                        id="dropdown-serving-type"
                        dropdownWrapperClassName={styles['dropdown-serving-types']}
                        selectedValueObject={{ value: type }}
                        styles={dropDownStyles}
                        options={servingTypeOptions}
                        onChange={({ value }) => setType(value)}
                    />
                </td>
            )}
            <td className={styles['column-body']}>
                <DropdownMenu
                    id="dropdown-days"
                    dropdownWrapperClassName={styles['dropdown-days']}
                    selectedValueObject={{ value: day }}
                    styles={dropDownStyles}
                    options={dayOptions}
                    onChange={({ value }) => setDay(value)}
                />
            </td>
            <td className={styles['column-body']}>
                <DropdownMenu
                    id="dropdown-from-hour"
                    dropdownWrapperClassName={styles['dropdown-hours']}
                    selectedValueObject={{ value: fromHour }}
                    styles={dropDownStyles}
                    options={fromHourOptions}
                    onChange={onFromHourChange}
                />
            </td>
            <td className={styles['column-body']}>
                <div className={styles['column-end-container']}>
                    <span>-</span>
                    <DropdownMenu
                        id="until-from-hour"
                        dropdownWrapperClassName={styles['dropdown-hours']}
                        styles={dropDownStyles}
                        selectedValueObject={{ value: untilHour }}
                        options={endHourOptions}
                        onChange={({ value }) => setUntilHour(value)}
                    />
                </div>
            </td>
            <td className={styles['buttons-container']}>
                <Border />
                {showBoost && <DayTimeBoostColumn failedValidationData={failedValidationData} />}
                <IconButton
                    variant={Button.variant.ghost}
                    size={Button.size.lg}
                    onClick={() => onDelete(id)}
                    aria-label="delete-day"
                >
                    <CircleCloseIcon />
                </IconButton>
            </td>
        </tr>
    );
};
