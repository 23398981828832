import React from 'react';
import { keyBy } from 'lodash';
import { ImageFormatIcon, MobileSimpleIcon } from 'tuui';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { CONTENT_METHODS } from '../../config';
import { CarouselIcon } from '../../icons/CarouselIcon';
import { hasCampaignCreatives } from '../CarouselCardList/utils/hasCampaignCreatives';

export const CREATIVE_FORMAT_TYPE = {
    IMAGE_AND_MOTION_AD: 'IMAGE_AND_MOTION_AD',
    APP_INSTALL: 'APP_INSTALL',
    CAROUSEL: 'CAROUSEL',
};

export const formatTypes = [
    {
        id: CREATIVE_FORMAT_TYPE.APP_INSTALL,
        icon: MobileSimpleIcon,
        label: 'APP INSTALL',
        visibilityCondition: ({ renderAppInstallFormat }) => renderAppInstallFormat,
        contentMethod: CONTENT_METHODS.VARIATIONS,
        subText: (
            <FormattedMessage
                id="creative.creator.ad.formats.app.ads.description"
                defaultMessage="Drive app promotion across networks and get more people to install your app"
            />
        ),
    },
    {
        id: CREATIVE_FORMAT_TYPE.IMAGE_AND_MOTION_AD,
        icon: ImageFormatIcon,
        label: 'IMAGE / MOTION AD',
        subText: (
            <FormattedMessage
                id="creative.creator.image.motion.format.type.description"
                defaultMessage="Create ads that test your assets with one image or motion-ads."
            />
        ),
    },
    {
        id: CREATIVE_FORMAT_TYPE.CAROUSEL,
        icon: CarouselIcon,
        label: 'CAROUSEL',
        accountConfigurations: { [FEATURE_FLAGS.CREATIVE_CAROUSEL_ENABLED]: 'true' },
        visibilityCondition: ({ campaigns }) => !hasCampaignCreatives(campaigns),
        contentMethod: CONTENT_METHODS.CAROUSEL,
        subText: (
            <FormattedMessage
                id="creative.creator.carousel.format.type.description"
                defaultMessage="Use carousel ads to tell a story, share a process, or highlight a set of products, features, or services (images & videos)"
            />
        ),
    },
];

export const formatTypesMap = keyBy(formatTypes, 'id');
