import { get } from 'lodash';
import uuid from 'uuid/v1';

export const createConditionObject = conditionData => ({
    ruleMetric: conditionData.metricName?.defaultValue,
    lookbackWindowInDays: parseInt(conditionData.timeFrame?.defaultValue) || undefined,
    predicateType: conditionData.predicateType.defaultValue,
    conditionValue: conditionData.value?.defaultValue,
    subMetric: {
        property: conditionData.metricName?.conversionEvent ? 'CONVERSION_RULE' : undefined,
    },
    id: uuid(),
});
export const getInitialConditionsByTemplate = template => {
    const conditions = [];
    const ifCondition = get(template?.value, 'ifCondition[0]');
    if (ifCondition) {
        conditions.push(createConditionObject(ifCondition));
    }

    const andCondition = get(template?.value, 'andCondition[0]');
    if (andCondition) {
        conditions.push(createConditionObject(andCondition));
    }
    return conditions;
};
