"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCard(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 18,
            height: 14,
            viewBox: "0 0 18 14",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", { d: "M6 8H4V10H6V8Z", fill: "currentColor" }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M3 0H15C16.6569 0 18 1.34314 18 3V11C18 12.6569 16.6569 14 15 14H3C1.34315 14 0 12.6569 0 11V3C0 1.34315 1.34315 0 3 0ZM3 2C2.44772 2 2 2.44772 2 3V4H16V3C16 2.44772 15.5523 2 15 2H3ZM2 11V6H16V11C16 11.5523 15.5523 12 15 12H3C2.44772 12 2 11.5523 2 11Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgCard;