"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLead(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M5.31105 11.4293L5.82372 10.5707L14.2252 15.5873C15.0116 16.0569 15.9918 16.0588 16.7801 15.5923L25.2671 10.5697L25.7764 11.4303L17.2894 16.4529C16.1858 17.106 14.8135 17.1033 13.7125 16.4459L5.31105 11.4293Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M23 10H8C6.89543 10 6 10.8954 6 12V20C6 21.1046 6.89543 22 8 22H23C24.1046 22 25 21.1046 25 20V12C25 10.8954 24.1046 10 23 10ZM8 9C6.34315 9 5 10.3431 5 12V20C5 21.6569 6.34315 23 8 23H23C24.6569 23 26 21.6569 26 20V12C26 10.3431 24.6569 9 23 9H8Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgLead;