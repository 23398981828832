import React from 'react';

export const Check = props => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10.1313 14.8896L7.04946 11.78L6 12.8315L10.1313 17L19 8.05145L17.9579 7L10.1313 14.8896Z"
            fill="#4D86EC"
        />
    </svg>
);
