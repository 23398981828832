import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AUDIENCE_TYPE } from '../../../../../audiences/config/audiencesConsts';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './predictiveAudienceDetailsCellRenderer.module.scss';

const PredictiveAudienceDetailsCellRenderer = ({ value, data }) => {
    const { countries, type } = data;

    let cell = '-';
    if (type === AUDIENCE_TYPE.PIXEL_LOOKALIKE) {
        cell = (
            <div>
                <FormattedMessage
                    id="app.campaigns.reports.grid.audience.basedOn"
                    defaultMessage="Based on: {sourceAudience}"
                    values={{ sourceAudience: value }}
                />
                <br />
                <FormattedMessage
                    id="app.campaigns.reports.grid.audience.country"
                    defaultMessage="Country: {country}"
                    values={{ country: countries }}
                />
            </div>
        );
    } else if (value) {
        cell = <div>{value}</div>;
    }

    return (
        <div>
            <div className={styles['container']}>
                <EllipsisCellRenderer value={cell} lines={2} />
            </div>
        </div>
    );
};

PredictiveAudienceDetailsCellRenderer.propTypes = {
    data: PropTypes.object,
    ...EllipsisCellRenderer.propTypes,
};

export default PredictiveAudienceDetailsCellRenderer;
