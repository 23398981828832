import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { TooltipV2 as Tooltip, CollapsibleList } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormNamespaceProvider } from '../../../../../../taboola-common-frontend-modules/formData/FormNamespaceProvider';
import TargetingDropdownBox from '../../TargetingDropdownBox/TargetingDropdownBox';
import { TargetingTypeDropdownOption } from '../../TargetingTypeDropdown';
import MyAudienceDropDownOption from '../MyAudienceDropDownOption/MyAudienceDropDownOption';
import { MyAudiencesListItem } from '../MyAudiencesListItem/MyAudiencesListItem';
import { MyAudiencesListItemContentWithValidations } from '../MyAudiencesListItem/MyAudiencesListItemContentWithValidations';
import { useMyAudiences } from './hooks/useMyAudiences';
import { useMyAudiencesValidation } from './hooks/useMyAudiencesValidation';
import styles from '../../BrowserTargeting/browserTargetingSmartList.module.scss';
import commonStyles from '../../commonEditor.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const IncludeItemComponent = ({ index, ...rest }) => {
    return (
        <FormNamespaceProvider field={index} itemKey={rest.item.id}>
            <MyAudiencesListItemContentWithValidations index={index} {...rest} />
        </FormNamespaceProvider>
    );
};

const IncludeCollapsibleList = ({
    includeAudiences,
    targetingType,
    removeMyAudience,
    clearIncludedItems,
    allSegmentsByIdMap,
    isOptionsLoadedSuccessfully,
    rejectedAudiencesList,
    isRejectedAudiencesLoadedSuccessfully,
    isDirty,
}) => {
    const { includeFailedValidationData } = useMyAudiencesValidation(
        allSegmentsByIdMap,
        includeAudiences,
        isOptionsLoadedSuccessfully,
        isDirty
    );

    const getItemHeight = useCallback(item => {
        return item.similarityLevelToSize ? 100 : 55;
    }, []);

    return (
        <FormNamespaceProvider field="campaignTargeting.myAudienceTargeting.include">
            <CollapsibleListWithIndication
                id="campaignTargeting.myAudienceTargeting.include"
                containerClassName={styles['list']}
                items={includeAudiences}
                itemHeight={getItemHeight}
                ItemComponent={IncludeItemComponent}
                metadata={{
                    targetingType,
                    isReady: isOptionsLoadedSuccessfully && isRejectedAudiencesLoadedSuccessfully,
                    allSegmentsByIdMap,
                    rejectedAudiencesList,
                    isDirty,
                }}
                deleteItem={removeMyAudience}
                clearItems={clearIncludedItems}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.my.audiences.title.included"
                        defaultMessage="Included Audiences"
                    />
                }
                aria-label="include-list"
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                indicationContainerClass={commonStyles['warning-indication']}
                {...includeFailedValidationData}
            />
        </FormNamespaceProvider>
    );
};

export const MyAudiences = props => {
    const { selectedAccountId } = props;

    const {
        includeAudiences,
        excludeAudiences,
        clearExcludedItems,
        clearIncludedItems,
        removeIncludeAudience,
        removeExcludeAudience,
        myAudiences,
        setTargetingTypeValue,
        addItem,
        isDisabled,
        isLoadingMyAudiences,
        targetingType,
        allSegmentsByIdMap,
        isOptionsLoadedSuccessfully,
        rejectedAudiencesList,
        isRejectedAudiencesLoadedSuccessfully,
        isDirty,
    } = useMyAudiences(selectedAccountId);

    const shouldRenderTargetingLists =
        isOptionsLoadedSuccessfully && (!isEmpty(includeAudiences) || !isEmpty(excludeAudiences));

    return (
        <div data-testid="myAudienceListContainer">
            {isDisabled && Tooltip}
            <TargetingDropdownBox
                itemType={targetingType}
                onSelectItemType={setTargetingTypeValue}
                itemPlaceholder={
                    <FormattedMessage
                        id="campaign.editor.targeting.my.audiences.select.placeholder"
                        defaultMessage="Start typing to select an audience..."
                    />
                }
                onAddItem={addItem}
                allItems={myAudiences}
                itemOptionRenderer={MyAudienceDropDownOption}
                itemTypeOptionRenderer={props => <TargetingTypeDropdownOption isDisabled={isDisabled} {...props} />}
                itemSelectionDisabled={isDisabled}
                isItemTypeDisabled={isDisabled}
                isItemsLoading={isLoadingMyAudiences}
            />
            {shouldRenderTargetingLists ? (
                <>
                    <IncludeCollapsibleList
                        includeAudiences={includeAudiences}
                        targetingType={targetingType}
                        removeMyAudience={removeIncludeAudience}
                        clearIncludedItems={clearIncludedItems}
                        allSegmentsByIdMap={allSegmentsByIdMap}
                        isOptionsLoadedSuccessfully={isOptionsLoadedSuccessfully}
                        rejectedAudiencesList={rejectedAudiencesList}
                        isRejectedAudiencesLoadedSuccessfully={isRejectedAudiencesLoadedSuccessfully}
                        isDirty={isDirty}
                    />
                    <CollapsibleListWithIndication
                        items={excludeAudiences}
                        ItemComponent={MyAudiencesListItem}
                        metadata={{ targetingType }}
                        deleteItem={removeExcludeAudience}
                        clearItems={clearExcludedItems}
                        listHeaderTitle={
                            <FormattedMessage
                                id="campaign.editor.targeting.my.audiences.title.excluded"
                                defaultMessage="Excluded Audiences"
                            />
                        }
                        aria-label="exclude-list"
                        showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                        showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                        containerClassName={styles['list']}
                    />
                </>
            ) : null}
        </div>
    );
};
