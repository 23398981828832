import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { FormField, TaboolaDataGrid, Indication } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from '../../../../components';
import { LoadingBar, SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { CONDITION_DIMENSIONS } from '../PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/DimensionDropdown/DimensionDropdown';
import { RulePreviewEmptyState } from './RulePreviewEmptyState';
import { usePerformanceRuleSimulation } from './hooks/usePerformanceRuleSimulation';
import styles from './ruleSimulator.module.scss';

const getLoadingBar = dimension => {
    const defaultMessage =
        dimension === CONDITION_DIMENSIONS.SITE_PER_CAMPAIGN
            ? 'We’re going to show you how many sites will be blocked after the rule’s next run, it will only take a few seconds'
            : 'We’re going to show you how many ads will be paused after the rule’s next run, it will only take a few seconds';

    return (
        <CommonCollapsibleCard
            id="RULE_PREVIEW"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="performance.rule.preview.header.text" defaultMessage="Rule Preview" />
                    }
                />
            }
            containerClassName={styles['card-container']}
        >
            <LoadingBar
                durationInSeconds={15}
                loadingMessage={
                    <FormattedMessage
                        id={`performance.rule.summary.loading.${dimension}`}
                        defaultMessage={defaultMessage}
                    />
                }
                containerClassName={styles['loading-bar']}
            />
        </CommonCollapsibleCard>
    );
};

const getHeader = (dimension, metadata) =>
    dimension === CONDITION_DIMENSIONS.SITE_PER_CAMPAIGN ? (
        <FormField
            inputId="performance-rule-preview-total-sites"
            labelClass={styles['header']}
            label={
                <FormattedMessage
                    id="performance.rule.preview.total.sites"
                    defaultMessage={`Approximately ${metadata.total} sites across ${metadata.count} campaigns will be blocked after the rule's next run`}
                    values={{ total: metadata.total, count: metadata.count }}
                />
            }
        ></FormField>
    ) : (
        <FormField
            inputId="performance-rule-preview-total-ads"
            labelClass={styles['header']}
            label={
                <FormattedMessage
                    id="performance.rule.preview.total.ads"
                    defaultMessage={`Approximately ${metadata.total} ads across ${metadata.count} campaigns will be paused after the rule's next run`}
                    values={{ total: metadata.total, count: metadata.count }}
                />
            }
        ></FormField>
    );

const sitesColumnDefs = [
    {
        field: 'affectedEntityName',
        headerName: 'Sites',
        width: 100,
        pinned: 'left',
    },
    {
        field: 'campaignsCount',
        headerName: 'Number of Campaigns',
        width: 100,
        pinned: 'left',
    },
];

const adsColumnDefs = [
    {
        field: 'affectedEntityName',
        headerName: 'Ads',
        width: 100,
        pinned: 'left',
    },
    {
        field: 'campaignId',
        headerName: 'Campaigns',
        width: 100,
        pinned: 'left',
    },
];

export const RuleSimulator = ({ setIsLoading }) => {
    const { data, isLoading, rule, isError } = usePerformanceRuleSimulation();
    useEffect(() => {
        if (!isLoading) {
            setIsLoading(false);
        }
    }, [isLoading, setIsLoading]);

    const { dimension } = rule;

    if (isLoading) {
        return getLoadingBar(dimension);
    }

    const showEmptyState = isEmpty(data?.results) || isError;

    return (
        <CommonCollapsibleCard
            id="RULE_PREVIEW"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="performance.rule.preview.header.text" defaultMessage="Rule Preview" />
                    }
                />
            }
            containerClassName={styles['card-container']}
        >
            {showEmptyState ? (
                <RulePreviewEmptyState dimension={dimension} />
            ) : (
                <div className={styles['list-with-header']}>
                    <Indication
                        type=""
                        message={getHeader(dimension, data?.metadata)}
                        displayDismissComponent={false}
                        className={styles['indication']}
                    />
                    <div className={styles['list']}>
                        <TaboolaDataGrid
                            rowsPerPage={25}
                            reportData={data?.results}
                            columnDefs={
                                dimension === CONDITION_DIMENSIONS.SITE_PER_CAMPAIGN ? sitesColumnDefs : adsColumnDefs
                            }
                            pagination={true}
                            rowHeight={60}
                        />
                    </div>
                </div>
            )}
        </CommonCollapsibleCard>
    );
};
