import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import bidStrategies from '../../../modules/common-campaign-form/config/bidStrategies';
import { BUDGET_TYPES } from '../../../modules/common-campaign-form/config/budgetTypes';

const NUMBER_OF_DAYS_IN_MONTH = 31;

export const budgetValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.budget.empty',
        defaultMessage: 'Enter a Budget',
    },
    {
        validationFn: validationFunctions.isFloat,
        options: { min: 1 },
        messageId: 'validations.error.campaign.budget.minValue',
        defaultMessage: 'Budget must be a positive number above 1.',
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'spendingLimitMaxThreshold' },
        messageId: 'validations.error.campaign.budget.spendingLimitMaxThreshold',
        defaultMessage: 'Budget cannot be higher than {spendingLimitMaxThreshold} {currency} with this spending limit',
    },
    {
        validationFn: (value, options, { data }) => {
            return validateRangeExcludingNonCpcStrategy(value, options, { data });
        },
        options: { min: 'bidMinThreshold' },
        messageId: 'validations.error.campaign.budget.bidMinThreshold',
        defaultMessage: 'Budget cannot be lower than {bidMinThreshold} {currency} with this campaign bid',
    },
    {
        validationFn: (value, options, { data }) => {
            return validateRangeExcludingNonCpcStrategy(value, options, { data });
        },
        options: { gt: 'bid' },
        messageId: 'validations.error.campaign.budget.largerThanBid',
        defaultMessage: 'Budget must be larger than bid ({bid})',
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'maxLowTierDailyLimit' },
        messageId: 'validations.error.campaign.budget.maxLowTierDailyLimit',
        defaultMessage: 'Budget must be less than {maxLowTierDailyLimit} {currency}',
    },
    {
        validationFn: (value, options, { data }) => {
            const { targetCpa, bidStrategy, budget, minConversionsForTargetCpa } = data;
            return validateBudgetForTargetCpa(budget, targetCpa, bidStrategy, minConversionsForTargetCpa);
        },
        messageId: 'validations.error.campaign.budget.minimumForTargetCpa',
        defaultMessage:
            'Based on the daily budget and CPA goal, it seems like conversion volume is going to be too low to meet the minimum required for TCPA to work properly, please increase budget',
        severity: INDICATION_TYPES.WARNING,
    },
];

export const validateBudgetForTargetCpa = (budget, targetCpa, bidStrategy, minConversionsForTargetCpa) => {
    if (bidStrategy !== bidStrategies.TARGET_CPA || budget?.value === '' || targetCpa <= 0) {
        return true;
    }
    if (budget?.type === BUDGET_TYPES.DAILY) {
        return budget?.value / targetCpa >= Math.ceil(minConversionsForTargetCpa);
    }
    if (budget?.type === BUDGET_TYPES.MONTHLY) {
        return budget?.value / NUMBER_OF_DAYS_IN_MONTH / targetCpa >= Math.ceil(minConversionsForTargetCpa);
    }
    return true;
};

export const validateRangeExcludingNonCpcStrategy = (value, options, { data }) => {
    const { bidStrategy } = data;
    if (bidStrategy === bidStrategies.MAX_CONVERSIONS || bidStrategy === bidStrategies.TARGET_CPA) {
        return true;
    }

    return validationFunctions.range(value, options, { data });
};
