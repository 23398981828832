import { SAVE_CREATIVE_ERROR, SAVE_CREATIVE_SUCCESS } from './actionTypes';

export const saveCreativeSuccess = creative => ({
    type: SAVE_CREATIVE_SUCCESS,
    payload: creative,
});

export const saveCreativeError = (id, error) => ({
    type: SAVE_CREATIVE_ERROR,
    payload: { id, error },
});
