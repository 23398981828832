import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import YesNoRadioGroup from '../../../campaigns/components/Form/FormFields/YesNoRadioGroup/YesNoRadioGroup';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import BusinessOfExtendingCreditFieldTooltip from './BusinessOfExtendingCreditFieldTooltip';

export const BusinessOfExtendingCreditField = () => {
    const { value: businessOfExtendingCredit, onChange: onBusinessOfExtendingCreditChange } = useFormFieldValue({
        field: 'businessOfExtendingCredit',
    });

    return (
        <FormField
            inputId="business-of-extending-credit"
            label={
                <FormattedMessage
                    id="audience.editor.business.of.extending.credit.title"
                    defaultMessage="Advertiser is in the Business of Extending Credit:"
                />
            }
            helpText={<BusinessOfExtendingCreditFieldTooltip />}
        >
            <YesNoRadioGroup
                selectedValue={businessOfExtendingCredit}
                onChange={onBusinessOfExtendingCreditChange}
                id="business-of-extending-credit"
                name="business-of-extending-credit"
            />
        </FormField>
    );
};

export default BusinessOfExtendingCreditField;
