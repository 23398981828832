import { useCallback, useRef } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import { AspectRatioKeeper, TaboolaVideo, Image } from 'taboola-ultimate-ui';
import { MOTIONS } from 'modules/campaigns/modules/creative-creator/config';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './MediaPreview.module.scss';

const classNameBuilder = classnames.bind(styles);

export const MediaPreview = ({ thumbnail, className, onLoad: onLoadProp = noop }) => {
    const ref = useRef();
    const onLoad = useCallback(() => {
        if (!ref.current) {
            return;
        }
        onLoadProp(ref.current.naturalWidth, ref.current.naturalHeight);
    }, [onLoadProp]);
    const onLoadedMetadata = useCallback(() => {
        if (!ref.current) {
            return;
        }
        onLoadProp(ref.current.videoWidth, ref.current.videoHeight);
    }, [onLoadProp]);
    return (
        <div className={classNameBuilder('media-wrapper', className)}>
            <AspectRatioKeeper>
                {thumbnail?.mediaCategory === MOTIONS ? (
                    <TaboolaVideo
                        src={thumbnail?.displayUrl}
                        ref={ref}
                        onLoadedMetadata={onLoadedMetadata}
                        renderWatermark
                        containerClassName={styles['media']}
                        status={COMPONENT_STATUS.ACTIVE}
                    />
                ) : (
                    <Image
                        ref={ref}
                        onLoad={onLoad}
                        className={styles['media']}
                        src={thumbnail?.displayUrl}
                        alt="Card media"
                    />
                )}
            </AspectRatioKeeper>
        </div>
    );
};
