import React from 'react';
import { batch } from 'react-redux';
import {
    updateCombinedAudience,
    updateCustomContextualSegment,
    updateFirstPartyAudience,
    updatePredictiveAudience,
    updateMRTAudience,
    updatePixelBasedAudience,
    updateSRTAudience,
} from 'services/api/audiencesApi';
import { errorMessagesUtils } from 'services/utils';
import { REPORT_TYPE } from '../../campaigns/config';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { requestUpdateAudience, updateAudienceError, updateAudienceSuccess } from '../actions';
import { AUDIENCE_STATUS, AUDIENCE_TYPE, ENTITY } from '../config';
import {
    transformCombinedAudiencesSegmentToGW,
    transformCustomContextualSegmentToGW,
    transformExternalAudiencesSegmentToGW,
    transformPixelBasedAudienceToGW,
} from '../transformers';

const successIndication = {
    message: <FormattedMessage id="audience.save.success" defaultMessage="Audience successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="audience.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const apis = {
    [REPORT_TYPE.PIXEL_BASED]: updatePixelBasedAudience,
    [REPORT_TYPE.PREDICTIVE]: updatePredictiveAudience,
    [REPORT_TYPE.FIRST_PARTY]: updateFirstPartyAudience,
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: updateCustomContextualSegment,
    [REPORT_TYPE.COMBINED_AUDIENCES]: updateCombinedAudience,
    [ENTITY.SEARCH_KEYWORDS]: updateSRTAudience,
    [ENTITY.MAIL_DOMAINS]: updateMRTAudience,
};

const transformers = {
    [REPORT_TYPE.PIXEL_BASED]: transformPixelBasedAudienceToGW,
    [REPORT_TYPE.PREDICTIVE]: audienceDetails,
    [REPORT_TYPE.FIRST_PARTY]: audienceDetails,
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: transformCustomContextualSegmentToGW,
    [REPORT_TYPE.COMBINED_AUDIENCES]: transformCombinedAudiencesSegmentToGW,
    [AUDIENCE_TYPE.SEARCH_KEYWORDS]: transformExternalAudiencesSegmentToGW,
    [AUDIENCE_TYPE.MAIL_DOMAINS]: transformExternalAudiencesSegmentToGW,
};

const updateAudience =
    ({ accountName, audience, reportType, onError }) =>
    async dispatch => {
        dispatch(requestUpdateAudience());
        const { id: audienceId, segmentType: type } = audience;

        try {
            let updatedAudience = await updateAndTransformAudience(audience, accountName, audienceId, reportType, type);
            batch(() => {
                dispatch(updateAudienceSuccess(updatedAudience));
                dispatch(addIndication(successIndication));
            });
            return updatedAudience;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateAudienceError(error));
            if (onError) {
                onError();
            }
            throw error;
        }
    };

const updateAndTransformAudience = async (audience, accountName, audienceId, reportType, audienceType) => {
    if (audienceType === AUDIENCE_TYPE.SEARCH_KEYWORDS || audienceType === AUDIENCE_TYPE.MAIL_DOMAINS) {
        return updateAndTransformAudienceByType(audience, accountName, audienceId, audienceType);
    }
    return updateAndTransformAudienceByType(audience, accountName, audienceId, reportType);
};

const updateAndTransformAudienceByType = async (audience, accountName, audienceId, type) => {
    const updatedAudience = transformers[type](audience);
    return await apis[type](accountName, audienceId, updatedAudience);
};

function audienceDetails(audience) {
    let audienceDetails = {
        name: audience.name,
        description: audience.description,
        excludeFromCampaigns: audience.excludeFromCampaigns,
    };

    if (audience.status !== undefined) {
        audienceDetails = { ...audienceDetails, isArchived: audience.status === AUDIENCE_STATUS.ARCHIVED };
    }

    return audienceDetails;
}

export default updateAudience;
