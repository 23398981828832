import React from 'react';
import { HelpTooltip } from 'taboola-ultimate-ui';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './containerDropdown.module.scss';

export const ContainerDropdownTooltip = () => {
    return (
        <HelpTooltip
            iconClassName={styles['help-icon']}
            content={
                <TooltipSection>
                    <FormattedMessage
                        id="tracking.setup.gtm.container.label.tooltip"
                        defaultMessage="Select your Google Tag Manager container"
                    />
                </TooltipSection>
            }
        />
    );
};
