import React, { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import {
    Input,
    InputTypes,
    FormField,
    StyledButton,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    useEventListener,
} from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { getQueryParam } from 'modules/taboola-common-frontend-modules/query-params';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { VastTagTooltip } from './VastTagTooltip';
import vastUrlStyles from '../VastUrlField/vastUrlField.module.scss';

const classNameBuilder = classnames.bind(vastUrlStyles);

const InputWithIndication = withIndication(Input);

const baseCreativeUploaderUrl = 'https://creative-uploader.taboola.com/?isExternal=true';

const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.creative.video-creative-url.empty',
        defaultMessage: 'enter a Video Creative URL',
    },
    {
        validationFn: validationFunctions.isURL,
        messageId: 'validations.error.creative.video-creative-url.invalid',
        defaultMessage: 'Video Creative URL is invalid',
    },
];

export const VastUrlField = () => {
    const [externalPopup, setExternalPopup] = useState(null);

    const {
        value = '',
        onChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: `vastUrl`,
        validations,
    });
    const { formatMessage } = useIntl();
    const creativeId = validationFunctions.isURL(value) ? getQueryParam(new URL(value).search, 'adId') : '';
    const creativeUploaderExternalUrl = creativeId
        ? baseCreativeUploaderUrl + `&creativeDisplayId=${creativeId}`
        : baseCreativeUploaderUrl;
    const onClick = () => setExternalPopup(window.open(creativeUploaderExternalUrl, '_blank'));
    const messageHandler = useCallback(
        e => {
            if (externalPopup) {
                externalPopup.close();
            }
            if (e.data.url) {
                onChange(e.data.url);
            }
        },
        [onChange, externalPopup]
    );

    useEventListener('message', messageHandler);
    return (
        <FormField
            ref={scrollRef}
            inputId="vastUrlField"
            label={<FormattedMessage id="video.creative.editor.video-creative.title" defaultMessage="Video Creative" />}
            description={
                <FormattedMessage
                    id="video.creative.editor.video-creative.description"
                    defaultMessage="Click “Generate Video” to upload MP4 file & tags to create Taboola Vast."
                />
            }
            helpText={<VastTagTooltip />}
            containerClass={classNameBuilder('input')}
        >
            <InputWithIndication
                value={value}
                type={InputTypes.URL}
                onChange={e => onChange(e.target.value)}
                id="vastUrlField"
                placeholder={formatMessage({
                    id: 'video.creative.editor.video-creative.placeholder',
                    defaultMessage:
                        'Paste existing Taboola Vast tag from previous video campaign OR generate a new one',
                })}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="Vast URL"
                inputWrapperClass={vastUrlStyles['input-wrapper']}
                mainClass={classNameBuilder('item-input-wrapper')}
                inputClass={classNameBuilder('prioritized', 'item-input')}
                {...indicationData}
            >
                <StyledButton
                    className={vastUrlStyles['button']}
                    size={STYLED_BUTTON_SIZE.MEDIUM}
                    type={STYLED_BUTTON_TYPE.STRONG}
                    onClick={onClick}
                >
                    <FormattedMessage id="video.creative.editor.creative-uploader" defaultMessage="Generate video" />
                </StyledButton>
            </InputWithIndication>
        </FormField>
    );
};

export default VastUrlField;
