import { dimensionSelector } from '../../../selectors';
import { campaignsApi } from '../../../services/api';
import { fetchCampaignsError, fetchCampaignsSuccess, requestFetchCampaigns } from '../actions';

const PAGE_SIZE = 20;

const fetchCampaigns =
    ({
        accountName,
        searchText,
        page,
        pageSize = PAGE_SIZE,
        sort,
        status,
        dimension: passedDimension,
        apiCacheKey,
        allowMultiRequestCache,
    }) =>
    async (dispatch, getState) => {
        dispatch(requestFetchCampaigns());

        try {
            const dimension = passedDimension || dimensionSelector(getState());
            const { results, metadata } = await campaignsApi.getCampaigns({
                accountId: accountName,
                page,
                pageSize,
                searchText,
                sort,
                dimension,
                status,
                apiCacheKey,
                allowMultiRequestCache,
            });

            dispatch(fetchCampaignsSuccess({ results, metadata }));
            return [results, metadata.total];
        } catch (error) {
            dispatch(fetchCampaignsError(error));
            return [];
        }
    };

export default fetchCampaigns;
