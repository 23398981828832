'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.usePresetConfigs = undefined;

var _react = require('react');

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _utils = require('../DashboardRangePicker/utils');

var _constants = require('../DashboardRangePicker/constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var usePresetConfigs = exports.usePresetConfigs = function usePresetConfigs(timeZoneName) {
    var labels = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.PRESET_LABELS;

    (0, _react.useMemo)(function () {
        if (timeZoneName) {
            _momentTimezone2.default.tz.setDefault(timeZoneName);
        }
    }, [timeZoneName]);

    // preset configs only need to be recalculated whenever the day changes. This code has to be
    // executed after the above Timezone code in order to ensure that if the timezone change causes the current day
    // to change, the presets are re-calculated.
    // in addition, this code will cause a re-calculation if the user is on the app from 23:59 Jan 01 -> 00:01 Jan 02.
    var curDay = (0, _momentTimezone2.default)().date();

    var presetConfigs = (0, _react.useMemo)(function () {
        return (0, _utils.getPresetConfigs)(labels);
    }, [curDay]);

    return presetConfigs;
};

exports.default = usePresetConfigs;