import { isNil, isEmpty, isMatch } from 'lodash';
import { CHAT_AGENT_ACTION_STATUS } from '../config/chatAgentActionStatus';
import { CHAT_AGENT_ACTION_TYPE } from '../config/chatAgentActionType';
import { CHAT_AGENT_COMPONENT } from '../config/chatAgentComponentGA';

export const getChatAgentGAValue = (
    { actionType, actionData, actionMetadata: { type } = {}, status },
    event = null
) => {
    let isApplied;
    if (status === CHAT_AGENT_ACTION_STATUS.SUCCESS && event) {
        const { actionData: recommendedValues } =
            event?.actions.find(action => action.actionType === CHAT_AGENT_ACTION_TYPE.SET_RECOMMENDED_DATA) || {};
        const currentValues = actionData?.result || actionData || {};
        isApplied = !isEmpty(currentValues) && isMatch(currentValues, recommendedValues);
    }
    const values = [CHAT_AGENT_COMPONENT, actionType, type, status, isApplied];
    return values.filter(val => !isNil(val)).join('_');
};
