'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useMobileContext = exports.MobileContextProvider = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _useEventListener = require('../../hooks/useEventListener');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px'
};


var query = '(max-width: ' + styles.maxWidthMobile + ')';
var MobileContext = (0, _react.createContext)();

var MobileContextProvider = exports.MobileContextProvider = function MobileContextProvider(_ref) {
    var children = _ref.children;

    var mediaQuery = (0, _react.useMemo)(function () {
        return window.matchMedia(query);
    });

    var _useState = (0, _react.useState)(!!mediaQuery.matches),
        _useState2 = _slicedToArray(_useState, 2),
        match = _useState2[0],
        setMatch = _useState2[1];

    (0, _useEventListener.useEventListener)('change', function (event) {
        return setMatch(event.matches);
    }, mediaQuery);

    return _react2.default.createElement(
        MobileContext.Provider,
        { value: match },
        children
    );
};

MobileContextProvider.propTypes = {
    children: _propTypes2.default.node
};

var useMobileContext = exports.useMobileContext = function useMobileContext() {
    return (0, _react.useContext)(MobileContext);
};