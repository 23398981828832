import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { FILTER_TYPE } from '../../filters';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const performanceRuleExecutedActionsReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS],
    endpoint: 'performance-rule-executed-actions',
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    searchPlaceholderMsgId: 'performance.rules.report.search.placeholder',
    searchField: 'rule_name_search',
    filters: [
        {
            id: FILTER_TYPE.CAMPAIGN_NAME,
        },
        {
            id: FILTER_TYPE.RULE_NAME,
        },
    ],
    initialFilters: [],
};
