import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AlertIcon, TOOLTIP_POSITION, HelpTooltip as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './rejectionReasonTooltip.module.scss';

const RejectionReasonTooltip = ({ rejectReason, reviewerNotes, msgIdPrefix }) => {
    const [hovered, setHovered] = useState(false);
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    return (
        <Tooltip
            IconComponent={AlertIcon}
            iconClassName={styles['alert-icon']}
            position={TOOLTIP_POSITION.RIGHT}
            className={styles['tooltip']}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            showing={hovered}
        >
            <div className={styles['tooltip-header']}>
                <FormattedMessage id={`${msgIdPrefix}.reject.reason.${rejectReason}`} defaultMessage={rejectReason} />
            </div>
            <div>
                {reviewerNotes && (
                    <div className={styles['reviewer-notes']}>
                        <FormattedMessage
                            id={`${msgIdPrefix}.reject.main.note`}
                            values={{ reviewerNotes }}
                            defaultMessage="<b>Rejection details</b>: {reviewerNotes}"
                        />
                    </div>
                )}
                <FormattedMessage id={`${msgIdPrefix}.reject.main.violation`} />
                <FormattedMessage id={`${msgIdPrefix}.reject.main.learnMore`} />
            </div>
        </Tooltip>
    );
};

RejectionReasonTooltip.propTypes = {
    rejectReason: PropTypes.string,
    reviewerNotes: PropTypes.string,
    msgIdPrefix: PropTypes.string,
};

export default RejectionReasonTooltip;
