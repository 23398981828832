"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSortUp(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M8.813 10a1.1 1.1 0 0 1-1.502 0 .98.98 0 0 1 0-1.433l3.563-3.123c.302-.286.7-.444 1.127-.444.426 0 .827.158 1.126.444l3.562 3.123a.98.98 0 0 1 0 1.433 1.1 1.1 0 0 1-1.502 0l-2.122-1.753L13 16c0 .552-.411 1-.998 1A.98.98 0 0 1 11 16V8.247L8.813 10z"
            })
        )
    );
}
exports.default = SvgSortUp;