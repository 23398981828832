import React from 'react';

export const AmericanExpressSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3Z"
                fill="url(#paint0_radial_2007_166337)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.65981 12.4538L4.26516 10.986H4.27818L4.86399 12.4538H3.65981ZM3.75745 10L2 14H3.02843L3.39294 13.1092H5.13087L5.48236 14H6.54334L4.80541 10H3.75745ZM6.9469 10V14H7.90373V11.1933H7.91675L9.05584 14H9.84344L10.9825 11.1653H10.9955V14H11.9524V10H10.5139L9.48544 12.7507H9.47242L8.38541 10H6.9469ZM12.8506 10V14H16.3786V13.2605H13.8726V12.2801H16.1247V11.5966H13.8726V10.7395H16.3265V10H12.8506ZM18.2076 11.9048L16.5868 14H17.7324L18.7674 12.6162L19.7828 14H21L19.3792 11.9104L20.8698 10H19.7503L18.8064 11.2773L17.8952 10H16.7105L18.2076 11.9048Z"
                fill="#1793C0"
                fillOpacity="0.01"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.17408 10H4.14563L2.42091 14H3.43019L3.78791 13.1092H5.49347L5.83841 14H6.87963L5.17408 10ZM4.65666 10.986H4.64389L4.04982 12.4538H5.23157L4.65666 10.986ZM6.38481 10.5232L5.73534 9H3.58708L1 15H4.00581L4.36353 14.1092H4.90932L5.25426 15H18.2807L18.8695 14.1978L19.4471 15H23L20.6497 11.9123L22.8798 9H19.417L18.9266 9.6764L18.453 9H10.1797L9.73802 10.2037L9.27121 9H6.38481V10.5232ZM7.27568 14H8.2147V11.1933H8.22747L9.34535 14H10.1183L11.2362 11.1653H11.2489V14H12.1879V10H10.7762L9.76694 12.7507H9.75417L8.6874 10H7.27568V14ZM13.0695 10V14H16.5317V13.2605H14.0724V12.2801H16.2826V11.5966H14.0724V10.7395H16.4806V10H13.0695ZM19.8725 14H21.0671L19.4765 11.9104L20.9393 10H19.8406L18.9144 11.2773L18.0201 10H16.8575L18.3267 11.9048L16.7361 14H17.8604L18.876 12.6162L19.8725 14Z"
                fill="white"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_2007_166337"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(8.05957 4.54911) rotate(90) scale(24)"
                >
                    <stop stopColor="#71C8F2" />
                    <stop offset="1" stopColor="#0071BD" />
                </radialGradient>
            </defs>
        </svg>
    );
};
