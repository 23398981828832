import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import LearnMore from '../LearnMore';
import TooltipSection from '../TooltipSection';
import editorStyles from '../commonEditor.module.scss';
import styles from './trackingCodeTooltip.module.scss';

const TrackingCodeTooltip = () => (
    <div className={editorStyles['italic-text']}>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.tracking.code.help.overview"
                defaultMessage="Use Taboola macros to dynamically collect serving data, such as:"
            />
        </TooltipSection>
        <TooltipSection bodyClass={styles['section-body']}>
            <strong>{'{campaign_name}'}</strong>
            <FormattedMessage
                id="campaign.editor.tracking.code.help.param.campaign.name"
                defaultMessage=" The campaign name, as defined in the Campaigns tab."
            />
            <br />
            <strong>{'{site}'}</strong>
            <FormattedMessage
                id="campaign.editor.tracking.code.help.param.site"
                defaultMessage=" The publisher site or application name."
            />
            <br />
            <strong>{'{thumbnail}'}</strong>
            <FormattedMessage
                id="campaign.editor.tracking.code.help.param.thumbnail"
                defaultMessage=" The image or video URL."
            />
            <br />
            <strong>{'{title}'}</strong>
            <FormattedMessage id="campaign.editor.tracking.code.help.param.title" defaultMessage=" The ad headline." />
        </TooltipSection>
        <TooltipSection containerClass={styles['example']}>
            <strong>
                <FormattedMessage
                    id="campaign.editor.tracking.code.help.example.label"
                    defaultMessage="Tracking code example:"
                />
            </strong>
            {
                'utm_source=Taboola&utm_term={site}_{site_id}&utm_content={campaign_item_id}&utm_medium=referral&utm_campaign={campaign_name}'
            }
            <LearnMore href="https://help.taboola.com/hc/en-us/articles/115006030688-Creating-and-Adding-URL-Parameters-for-Tracking" />
        </TooltipSection>
    </div>
);

export default TrackingCodeTooltip;
