import { useCallback } from 'react';
import { reduce, get, isNil, isEmpty, noop } from 'lodash';
import { checkBundlingNodeIsHidden } from '../ utils/bundlingAndLogicUtils';
import { EMPTY_HIDDEN_IDS_SET, EMPTY_SEGMENT_GROUP_COUNT } from '../../MarketplaceAudiences/utils/andLogicUtils';
import { LoadingMode, useBundleTreeState } from '../../Tree';
import { useHiddenSegmentType } from '../../Tree/hooks/useHiddenSegmentType';
import { useSegmentTypeGroupIds } from '../../Tree/hooks/useSegmentTypeGroupIds';
import { splitTaxonomy } from '../../Tree/utils/treeUtils';

const getSplitTaxonomyList = node => {
    const { taxonomy, id } = node;

    const splitTaxonomyList = [...splitTaxonomy(taxonomy), String(id)];

    return splitTaxonomyList;
};

const nodeTransformer = ({ provider, size, articles, description, allowedCountries }) => ({
    provider,
    size,
    articles,
    description,
    allowedCountries,
});

export const useMarketplaceAudiencesBundleTreeState = ({
    search,
    nodes,
    fullNodes,
    marketplaceAudienceSegmentTypeCount = EMPTY_SEGMENT_GROUP_COUNT,
    setMarketplaceAudienceSegmentTypeByIndex = noop,
    index,
    totals,
    loadMore,
    reload,
    loadByIds,
    loadingPaths,
    hiddenIdsSet = EMPTY_HIDDEN_IDS_SET,
}) => {
    const treeProps = useBundleTreeState({
        search,
        nodes,
        fullNodes,
        nodeTransformer,
        totals,
        loadMore,
        reload,
        loadByIds,
        loadingPaths,
    });

    const { selectedNodes, loadingMode, totalCount, nodesTree } = treeProps;

    const isFullLoadingMode = loadingMode === LoadingMode.FULL;
    const { ageGroupIdsList, genderGroupIdsList, ageGroupTaxonomyParentsSet, genderGroupTaxonomyParentsSet } =
        useSegmentTypeGroupIds();

    const { shouldHideAge, shouldHideGender, shouldHideOther } = useHiddenSegmentType({
        selectedNodes,
        marketplaceAudienceSegmentTypeCount,
        setMarketplaceAudienceSegmentTypeByIndex,
        index,
        isFullLoadingMode,
        getSplitTaxonomyList,
        ageGroupTaxonomyList: ageGroupIdsList,
        genderGroupTaxonomyList: genderGroupIdsList,
    });

    const filterTreeNode = useCallback(
        (filteredNodes, node, counter) => {
            const isLoading = get(node, 'loading');

            // a loading node should not be filtered
            if (isLoading) {
                filteredNodes.push(node);
                return filteredNodes;
            }

            const targetableNodeCount = get(node, 'isTargetable', false) ? 1 : 0;

            const isNodeHidden = checkBundlingNodeIsHidden({
                node,
                hiddenIdsSet,
                shouldHideAge,
                shouldHideGender,
                shouldHideOther,
                ageGroupTaxonomyList: ageGroupIdsList,
                genderGroupTaxonomyList: genderGroupIdsList,
                ageGroupTaxonomyParentsSet,
                genderGroupTaxonomyParentsSet,
            });

            // if the node is hidden, prune this node and its children from the tree
            if (!isNodeHidden) {
                const children = get(node, 'nodes');

                // if the node is NOT hidden and it has no children, then it is a visible leaf
                if (isNil(children)) {
                    counter.current += targetableNodeCount;
                    filteredNodes.push(node);
                } else {
                    const filteredChildren = children.reduce((acc, node) => filterTreeNode(acc, node, counter), []);

                    // if there are still children after filtering, keep this node.
                    if (!isEmpty(filteredChildren)) {
                        counter.current += targetableNodeCount;
                        filteredNodes.push({ ...node, nodes: filteredChildren });
                    }
                }
            }

            return filteredNodes;
        },
        [
            ageGroupIdsList,
            ageGroupTaxonomyParentsSet,
            genderGroupIdsList,
            genderGroupTaxonomyParentsSet,
            hiddenIdsSet,
            shouldHideAge,
            shouldHideGender,
            shouldHideOther,
        ]
    );
    const getNodesTree = useCallback(() => {
        if (hiddenIdsSet.size === 0 && !shouldHideAge && !shouldHideGender && !shouldHideOther) {
            return { tree: nodesTree, count: totalCount };
        }

        const counter = { current: 0 };

        const filteredTree = reduce(nodesTree, (acc, node) => filterTreeNode(acc, node, counter), []);

        return { tree: filteredTree, count: counter.current };
    }, [hiddenIdsSet.size, nodesTree, filterTreeNode, shouldHideAge, shouldHideGender, shouldHideOther, totalCount]);

    return {
        ...treeProps,
        getNodesTree,
    };
};
