import { isEmpty } from 'lodash';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { FORM_MODES } from '../../../../../config';
import { BID_STRATEGIES } from '../../../config';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import { useSiteTargeting } from './useSiteTargeting';

export const siteListKey = 'accountId';

function getActiveExplorations(el) {
    return !el.explorationStatus || el.explorationStatus === 'IN_PROGRESS';
}
const validations = [
    {
        validationFn: value => isEmpty(value) || value.filter(getActiveExplorations).length <= 10,
        messageId: 'validations.error.campaign.site.exploration.active.explorations.number',
        defaultMessage: 'More than 10 active explorations are already defined',
    },
    {
        validationFn: (value, options, { bidStrategy }) =>
            isEmpty(value) || isEmpty(value.filter(getActiveExplorations)) || bidStrategy !== BID_STRATEGIES.FIXED,

        messageId: 'validations.error.campaign.site.exploration.bid.strategy',
        defaultMessage: 'Supply Exploration is not allowed for FIXED bid strategy',
    },
];

export const useSiteExploration = () => {
    const { mode } = useFormDataContext();
    const { value: bidStrategy } = useBidStrategyFormField();
    const readonly = mode !== FORM_MODES.EDIT && mode !== FORM_MODES.CREATE;
    const props = useSiteTargeting({
        field: 'segmentExplorations.publisherExplorations',
        readonly,
        siteListKey,
        validations,
        validationDependencies: { bidStrategy },
    });
    return props;
};
