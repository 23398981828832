import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { S22GenerateTestLink } from 'modules/tracking/components/S22GenerateTestLink/S22GenerateTestLink';
import styles from './trackingS2STestLink.module.scss';

export const S2STestLink = () => {
    return (
        <CommonCollapsibleCard
            id="S2S Test"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="tracking.setup.s2s.Test.label" defaultMessage="Test" />}
                />
            }
        >
            <FormField
                description={
                    <div>
                        <FormattedMessage
                            id="tracking.setup.s2s.test.description.intro"
                            defaultMessage="Verify that your server-to-server tracking is working properly."
                        />
                        <ol className={styles['ol-style']}>
                            <li className={styles['item']}>
                                <FormattedMessage
                                    id="tracking.setup.s2s.test.description.first"
                                    defaultMessage="Add the parameter to the relevant campaign or ads."
                                />
                            </li>
                            <li className={styles['item']}>
                                <FormattedMessage
                                    id="tracking.setup.s2s.test.description.second"
                                    defaultMessage="Select and copy the campaign test link."
                                />
                            </li>

                            <S22GenerateTestLink />

                            <li className={styles['item']}>
                                <FormattedMessage
                                    id="tracking.setup.s2s.test.description.third"
                                    defaultMessage="Past the link in your mobile or web browser. Navigate to your app or website, Perform the flow that ends up with a conversion(s)."
                                />
                            </li>
                            <li className={styles['item']}>
                                <FormattedMessage
                                    id="tracking.setup.s2s.test.description.fourth"
                                    defaultMessage="In Taboola Ads you should see the activity in the Conversion Table, under the Event Received (might take up to 20 min)."
                                />
                            </li>
                            <li className={styles['item']}>
                                <FormattedMessage
                                    id="tracking.setup.s2s.test.description.fifth"
                                    defaultMessage="Make sure the events are showing in your measurement platform as well."
                                />
                            </li>
                        </ol>
                    </div>
                }
            />
        </CommonCollapsibleCard>
    );
};
