import { useMemo, useState } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { supplyTargetingRadioOptionsByKeys } from '../const';

export const useSupplyTargettingSubtext = (supplyTargeting, msgPrefix) => {
    const [hovered, setHovered] = useState();
    const modeKey = hovered || supplyTargeting;

    const subtext = useMemo(
        () =>
            modeKey ? (
                <FormattedMessage
                    id={`${msgPrefix}.${modeKey}.subtext`}
                    defaultMessage={supplyTargetingRadioOptionsByKeys[modeKey]?.subtext}
                />
            ) : null,
        [modeKey, msgPrefix]
    );

    return {
        subtext,
        setHovered,
    };
};
