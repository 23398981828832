import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useAvailableItems } from 'hooks';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { bidStrategiesCategory } from './useBidStrategiesOptions';
import { useBidStrategyValidatedFormValue } from './useBidStrategyValidatedFormValue';
import { useTargetCpaAvailability } from './useTargetCpaAvailability';

const bidStrategiesOptions = [
    {
        id: BID_STRATEGIES.TARGET_CPA,
        visibilityCondition: ({ shouldRenderTargetCpa }) => shouldRenderTargetCpa,
    },
    {
        id: bidStrategiesCategory.MAX_CONVERSIONS,
        accountConfigurations: { [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: 'true' },
    },
    {
        id: BID_STRATEGIES.TARGET_ROAS,
        accountConfigurations: { [FEATURE_FLAGS.TARGET_ROAS_ENABLED]: 'true' },
    },
    {
        id: BID_STRATEGIES.SMART,
    },
    {
        id: BID_STRATEGIES.FIXED,
    },
];

export const useBidStrategyState = () => {
    const {
        value: bidStrategy,
        onChange: onChangeBidStrategy,
        indicationData,
        scrollRef,
    } = useBidStrategyValidatedFormValue();
    const { shouldRenderTargetCpa } = useTargetCpaAvailability();
    const availableItems = useAvailableItems(bidStrategiesOptions, { shouldRenderTargetCpa });
    const availableBidStrategies = useMemo(() => keyBy(availableItems, 'id'), [availableItems]);

    return {
        indicationData,
        scrollRef,
        bidStrategy,
        onChangeBidStrategy,
        availableBidStrategies,
    };
};
