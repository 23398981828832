import { AUDIENCE_TYPE, CRM_FILE_TYPE, CRM_TARGETING_TYPE } from './audiencesConsts';

export const firstPartyAudienceInitialValues = {
    type: AUDIENCE_TYPE.LOOKALIKE,
    crmFileType: CRM_FILE_TYPE.EMAIL,
    crmTargetingType: CRM_TARGETING_TYPE.TARGETING_SUPPRESSION,
    businessOfExtendingCredit: false,
    excludeFromCampaigns: false,
};

export default firstPartyAudienceInitialValues;
