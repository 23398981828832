import React from 'react';

export const Lighting = props => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.51091 15.7604L8.31855 16.568L9.34385 15.537L8.53621 14.7294L7.51091 15.7604ZM11.7782 16.2816H12.9238V18H11.7782V16.2816ZM6.05029 11.1265H7.76867V12.2721H6.05029V11.1265ZM12.9238 7.14558V9.41384L13.4966 9.74606C14.2069 10.1585 14.6422 10.9146 14.6422 11.7279C14.6422 12.9938 13.6169 14.0191 12.351 14.0191C11.0851 14.0191 10.0598 12.9938 10.0598 11.7279C10.0598 10.9146 10.5009 10.1585 11.2054 9.74606L11.7782 9.41384V7.14558H12.9238ZM14.0694 6H10.6326V8.75513C9.60733 9.35084 8.91425 10.4563 8.91425 11.7279C8.91425 13.6239 10.4551 15.1647 12.351 15.1647C14.247 15.1647 15.7878 13.6239 15.7878 11.7279C15.7878 10.4563 15.0947 9.35084 14.0694 8.75513V6ZM16.9333 11.1265H18.6517V12.2721H16.9333V11.1265ZM15.3524 15.5427L16.3777 16.5737L17.1854 15.7661L16.1543 14.7408L15.3524 15.5427Z"
            fill="#667686"
        />
    </svg>
);
