import React from 'react';
import { FormField, RadioIcon, RadioGroup } from 'taboola-ultimate-ui';
import { LOGO_POSITIONS_CONFIG } from 'modules/campaigns/modules/common-campaign-form/config/videoCreativeLogoPositionOptions';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './logoPosition.module.scss';

export const LogoPosition = ({ selected, onChange, isDisabled }) => {
    return (
        <FormField
            containerClass={styles['logoPositionContainer']}
            label={
                <FormattedMessage id="video.creative.creator.logo.logoPosition.title" defaultMessage="Logo Position" />
            }
            description={
                <FormattedMessage
                    id="video.creative.creator.logo.logoPosition.description"
                    defaultMessage="Choose the logo location"
                />
            }
        >
            <RadioGroup selectedValue={selected} disabled={isDisabled} className={styles['group']} onChange={onChange}>
                {Object.entries(LOGO_POSITIONS_CONFIG).map(([key, { icon: Icon, value }]) => {
                    return (
                        <RadioIcon
                            key={key}
                            value={value}
                            title={<FormattedMessage id={`video.creative.creator.logo.logoPosition.options.${key}`} />}
                            iconComponent={<Icon />}
                            checkedRadioColor={isDisabled ? styles.disabledColor : styles.selectedColor}
                            disabled={isDisabled}
                            labelClassName={styles['radio']}
                            titleClassName={styles['title']}
                        />
                    );
                })}
            </RadioGroup>
        </FormField>
    );
};
