import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.DAY_OF_WEEK].id;

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.SPENT.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.CLICKS.field,
        FIELDS.VCTR.field,
        FIELDS.CTR.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.CVR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.CPA.field,
        FIELDS.VCPM.field,
        FIELDS.CPM.field,
        FIELDS.CONVERSIONS_VALUE.field,
        FIELDS.ROAS.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.DAY_OF_WEEK.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.VCTR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
