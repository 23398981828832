import React from 'react';
import { FormField, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { usePermissions } from '../../../taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { TTL_TYPE } from '../../config';
import LookBackWindowInput from './LookBackWindowInput';
import LookBackWindowTitle from './LookBackWindowTitle';
import TtlTypeTooltip from './TtlTypeTooltip';

export const TtlTypeField = () => {
    const { value: ttlType, onChange: onTtlTypeChange } = useFormFieldValue({ field: 'ttlType' });
    const isAudienceExchangeUser = usePermissions('AUDIENCE_EXCHANGE_USER');

    return (
        <FormField
            inputId="include-people-from"
            label={
                <FormattedMessage
                    id="audience.editor.audience.include.people.from.title"
                    defaultMessage="Include People from:"
                />
            }
            helpText={<TtlTypeTooltip />}
            description={!isAudienceExchangeUser && <LookBackWindowTitle />}
        >
            {!isAudienceExchangeUser && <LookBackWindowInput />}
            {isAudienceExchangeUser && (
                <RadioGroup
                    id="include-people-from"
                    name="include-people-from"
                    onChange={onTtlTypeChange}
                    selectedValue={ttlType}
                >
                    <Radio value={TTL_TYPE.SPECIFIC_TIMEFRAME} title={<LookBackWindowTitle />} />
                    {ttlType === TTL_TYPE.SPECIFIC_TIMEFRAME && <LookBackWindowInput />}
                    <Radio
                        value={TTL_TYPE.CURRENT_MONTH}
                        title={
                            <FormattedMessage
                                id="audience.editor.audience.include.people.from.type.current.month"
                                defaultMessage={TTL_TYPE.CURRENT_MONTH}
                            />
                        }
                    />
                </RadioGroup>
            )}
        </FormField>
    );
};

export default TtlTypeField;
