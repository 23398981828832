import React from 'react';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './segmentPickerItemTooltip.module.scss';

const SegmentPickerItemTooltip = ({ name, uddId }) => (
    <Tooltip className={styles['tooltip-content']} arrow position={TOOLTIP_POSITION.TOP_START}>
        <div className={styles['name']}>{name}</div>
        <div className={styles['id']}>
            <FormattedMessage id="segment.picker.segment.displayName" values={{ uddId }} />
        </div>
    </Tooltip>
);

export default SegmentPickerItemTooltip;
