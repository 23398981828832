import React from 'react';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import SegmentListItemTooltip from '../../MarketplaceAudiences/SegmentListItemTooltip/SegmentListItemTooltip';
import { BundleDetailTooltip } from './BundleDetailTooltip/BundleDetailTooltip';

export const SegmentNodeTooltip = ({ item, formattedSize }) => {
    const { isTargetable, isBundle: isBundleType } = item;

    if (!isTargetable) {
        return null;
    } else if (isBundleType) {
        return <BundleDetailTooltip item={item} formattedSize={formattedSize} position={TOOLTIP_POSITION.TOP} />;
    } else {
        return <SegmentListItemTooltip item={item} />;
    }
};
