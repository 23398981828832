import {
    BACKSTAGE_CONTENT_PERMISSION,
    PERMISSIONS_OPERATORS,
} from 'modules/taboola-common-frontend-modules/authentication';
import { hybridModuleFactory } from '../backstage-content';

const MODULE_NAME = 'light-account-management';
const externalPath = 'reports/admin/light-publisher-management';
const ModuleComponent = hybridModuleFactory(externalPath);
const modulePermissions = {
    permissions: ['LIGHT_PUBLISHER_EDIT'],
};
const permissions = {
    permissions: [BACKSTAGE_CONTENT_PERMISSION, modulePermissions],
    operator: PERMISSIONS_OPERATORS.AND,
};

export { MODULE_NAME, ModuleComponent, permissions, externalPath };
