import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { HtmlOutlinedIcon, MovieCreationOutlinedIcon, AppInstallIcon, ViewCarouselOutlinedIcon } from 'tuui';
import { CloudinaryImage, Image } from 'taboola-ultimate-ui';
import { ICON_TYPES } from 'modules/campaigns/config/iconTypes';
import { isMotionAd } from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import { modifyExternalUrl } from '../../../../../services/utils';
import { isCarouselCreative } from '../../../../creative-creator/components/CarouselCardList/utils/isCarouselCreative';
import TaboolaVideoWrapper from '../TaboolaVideoWrapper';
import { IMAGE_CELL_RENDERER_MODES } from './imageCellRendererModes';
import styles from './imageCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);
const isLocalFile = value => value?.startsWith('blob:');
const isCloudinaryImage = value => !isLocalFile(value);

const defaultImageConfig = {
    width: styles.defaultWidth,
    height: styles.defaultHeight,
    imageClassName: styles['image'],
    containerClassName: styles['image-container'],
};

const comfortableImageConfig = {
    width: styles.comfortableWidth,
    height: styles.comfortableHeight,
    imageClassName: styles['image-comfortable'],
    containerClassName: styles['image-container-comfortable'],
};

const itemPreviewGridImageConfig = {
    width: styles.itemPreviewWidth,
    height: styles.itemPreviewHeight,
    imageClassName: styles['image'],
    containerClassName: styles['image-container-item-preview'],
};

const modesConfigMap = {
    [IMAGE_CELL_RENDERER_MODES.DEFAULT]: defaultImageConfig,
    [IMAGE_CELL_RENDERER_MODES.COMFORTABLE]: comfortableImageConfig,
    [IMAGE_CELL_RENDERER_MODES.ITEM_PREVIEW_GRID]: itemPreviewGridImageConfig,
};

const iconTypeComponentMap = {
    [ICON_TYPES.APP_INSTALL]: { CreativeIcon: AppInstallIcon, ariaLabel: 'App Install indication' },
    [ICON_TYPES.PERFORMANCE_VIDEO]: { CreativeIcon: MovieCreationOutlinedIcon, ariaLabel: 'Motion Ad indication' },
    [ICON_TYPES.HTML_CARD]: { CreativeIcon: HtmlOutlinedIcon, ariaLabel: 'HTML Card Ad indication' },
};
const EmptyComponent = () => null;

function ImageCellRenderer(props) {
    const { value, data, mode = IMAGE_CELL_RENDERER_MODES.DEFAULT, format } = props;
    const modifiedImageSrc = modifyExternalUrl(value);
    const encodedImageSrc = encodeURIComponent(modifiedImageSrc);
    const imageConfig = modesConfigMap[mode];
    const isComfortableView = mode === IMAGE_CELL_RENDERER_MODES.COMFORTABLE;
    const { height, width, imageClassName, containerClassName } = imageConfig;

    const imageContent = isCloudinaryImage(value) ? (
        <CloudinaryImage
            className={imageClassName}
            src={encodedImageSrc}
            height={height}
            width={width}
            sharpen
            gravity="faces:auto"
            format={format}
        />
    ) : (
        <Image className={imageClassName} src={value} />
    );
    const { CreativeIcon, ariaLabel } = iconTypeComponentMap[data.iconType] || {
        CreativeIcon: EmptyComponent,
        ariaLabel: 'No icon',
    };
    const isMotionAdCreative = isMotionAd(data);

    return (
        <div
            className={classNameBuilder(containerClassName, {
                'video-layer': isMotionAdCreative,
                'display-flex': !isMotionAdCreative,
            })}
        >
            {isMotionAdCreative ? (
                <TaboolaVideoWrapper
                    videoUrl={data.videoUrl}
                    imageSource={value}
                    modifiedImageSrc={modifiedImageSrc}
                    isComfortableView={isComfortableView}
                />
            ) : (
                imageContent
            )}
            <div
                className={classNameBuilder('watermark', {
                    'watermark-comfortable': isComfortableView,
                    'watermark-default': !isComfortableView,
                })}
            >
                <CreativeIcon className={classNameBuilder('creative-type-icon')} aria-label={ariaLabel} />
                {isCarouselCreative(data) && (
                    <ViewCarouselOutlinedIcon
                        className={classNameBuilder('creative-type-icon')}
                        aria-label="Carousel indication"
                    />
                )}
            </div>
        </div>
    );
}

ImageCellRenderer.propTypes = {
    value: PropTypes.string,
    data: PropTypes.object,
    mode: PropTypes.oneOf(Object.values(IMAGE_CELL_RENDERER_MODES)),
    format: PropTypes.string,
};

export { ImageCellRenderer };
export default React.forwardRef((props, ref) => <ImageCellRenderer {...props} />);
