"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgExport(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 25,
            fill: "currentColor",
            viewBox: "0 0 24 25"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "evenodd",
            d: "M17 13.328c0 .403.256.513.57.248l4.193-3.87a.609.609 0 000-.964L17.57 4.865c-.315-.266-.57-.155-.57.248V7.72h-.75c-2.865 0-5.556 2.375-6.153 4.94-.044.19-.076.384-.094.578-.022.2.075.245.219.104 0 0 .04-.036.075-.075 1.516-1.538 3.797-2.448 5.954-2.55h.75L17 13.328z",
            clipRule: "evenodd"
        }),
        React.createElement("path", {
            fill: "currentColor",
            d: "M17 17.727l-9.998-.007C7 17.72 7 7.714 7 7.714c0 .007 2.99.006 2.99.006a1 1 0 100-2H6.996A1.999 1.999 0 005 7.714v10.013c0 1.103.899 1.993 2.002 1.993h9.995A1.992 1.992 0 0019 17.727V15.72a1 1 0 10-2 0v2.007z"
        })
    );
}
exports.default = SvgExport;