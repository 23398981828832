import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CollapsibleFormSection } from 'components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { DAY_TIME_BID_OPTION } from '../DayTimeBidBoost/consts/dayTimeBidOptions';
import { PLATFORM_BID_OPTION } from '../PlatformBidBoost/consts/platformBidOptions';

export const BidAdjustmentsSectionFrame = ({ children }) => {
    const { value: dayTimeBidMode } = useFormFieldValue({
        field: 'dayTimeBidModifier.mode',
    });
    const { value: bidPlatformMode } = useFormValidatedValue({
        field: 'platformBidModifier.mode',
    });

    const isCollapsed = useMemo(
        () => dayTimeBidMode === DAY_TIME_BID_OPTION.CUSTOM || bidPlatformMode === PLATFORM_BID_OPTION.CUSTOM,
        [dayTimeBidMode, bidPlatformMode]
    );

    return (
        <CollapsibleFormSection
            isInitiallyCollapsed={!isCollapsed}
            title={
                <FormattedMessage id="campaign.editor.bid.adjustments" defaultMessage="Bid Adjustments (advanced)" />
            }
            subtitle={
                <FormattedMessage
                    id="campaign.editor.bid.adjustments.subtitle"
                    defaultMessage="Days and Hours, Platform"
                />
            }
            sectionId="CAMPAIGN_BIDDING-ADJUSTMENTS"
            testId="bid-adjustments-advanced-options"
        >
            {children}
        </CollapsibleFormSection>
    );
};

BidAdjustmentsSectionFrame.propTypes = {
    children: PropTypes.node,
};
