import { invert } from 'lodash';
import { PARTNER_TYPE } from '../modules/account-management/accountType';
import { DEMAND_TYPE } from '../modules/campaigns/constants';

export const CAMPAIGN_DIMENSION = {
    SPONSORED: 'SPONSORED',
    VIDEO: 'VIDEO',
};
const PARTNER_TYPE_TO_CAMPAIGN_DIMENSION_MAP = {
    [PARTNER_TYPE.ADVERTISER]: CAMPAIGN_DIMENSION.SPONSORED,
    [PARTNER_TYPE.VIDEO_ADVERTISER]: CAMPAIGN_DIMENSION.VIDEO,
};

const CAMPAIGN_DIMENSION_TO_PARTNER_TYPE_MAP = invert(PARTNER_TYPE_TO_CAMPAIGN_DIMENSION_MAP);

export const getInitialCampaignDimension = (partnerTypes = [], selectedCampaignDimension) => {
    if (partnerTypes.length === 1) {
        return PARTNER_TYPE_TO_CAMPAIGN_DIMENSION_MAP[partnerTypes[0]];
    }

    const partnerType = CAMPAIGN_DIMENSION_TO_PARTNER_TYPE_MAP[selectedCampaignDimension];

    if (partnerTypes.includes(partnerType)) {
        return selectedCampaignDimension;
    }

    return CAMPAIGN_DIMENSION.SPONSORED;
};

// Partner types have correlation with campaign dimensions
export const hasMultipleCampaignDimensions = partnerTypes => partnerTypes?.length > 1;
export const getSecondaryCampaignDimension = selectedCampaignDimension =>
    isSponsoredCampaign(selectedCampaignDimension) ? CAMPAIGN_DIMENSION.VIDEO : CAMPAIGN_DIMENSION.SPONSORED;

export const isVideoCampaign = dimension => dimension === CAMPAIGN_DIMENSION.VIDEO;
export const isSponsoredCampaign = dimension => dimension === CAMPAIGN_DIMENSION.SPONSORED;
export const isOpenExchangeCampaign = demandType => demandType === DEMAND_TYPE.RTB_OPEN_EXCHANGE;
export const isPmpDealCampaign = demandType => demandType === DEMAND_TYPE.PMP_DEAL;
export const isVideoPmpCampaign = demandType => demandType === DEMAND_TYPE.VIDEO_PMP_DEAL;
export const isProgrammaticCampaign = demandType =>
    isOpenExchangeCampaign(demandType) || isPmpDealCampaign(demandType) || isVideoPmpCampaign(demandType);
