import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { filter, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { NavigationDropdownItem } from './NavigationDropdownItem';
import { NavigationItem } from './NavigationItem';
import styles from './navigationItems.module.scss';

const classNameBuilder = classnames.bind(styles);
const getNavItems = items => {
    const navItems = filter(items, ({ hideNavItem, hidden }) => !hideNavItem && !hidden);
    const navItemsByGroupId = groupBy(navItems, item => item.groupId || item.id);
    return Object.entries(navItemsByGroupId).map(([groupId, navItems]) => ({
        groupId,
        navItems,
        isDropdown: navItems.length > 1,
    }));
};

export const NavigationItems = ({ navItems, className, activeClassName, itemsClassName, iconClass }) => {
    const linkGroups = useMemo(() => getNavItems(navItems), [navItems]);
    return (
        <div className={classNameBuilder(styles['container'], className)}>
            {linkGroups.map(({ groupId, navItems, isDropdown }) => {
                if (isDropdown) {
                    return (
                        <NavigationDropdownItem
                            key={groupId}
                            navItems={navItems}
                            activeClassName={activeClassName}
                            itemsClassName={itemsClassName}
                            iconClass={iconClass}
                        />
                    );
                }
                const [linkNavItem] = navItems;
                return (
                    <NavigationItem
                        key={linkNavItem.id}
                        navItem={linkNavItem}
                        activeClassName={activeClassName}
                        itemsClassName={itemsClassName}
                        iconClass={iconClass}
                    />
                );
            })}
        </div>
    );
};

NavigationItems.propTypes = {
    navItems: PropTypes.array,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    iconClass: PropTypes.string,
};
