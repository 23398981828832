import { ENTITY } from '../../../config';
import { SHEET_NAME } from '../consts/sheetName';
import { toGWCreativeTransformers } from '../transformers/toGWTransformers/toGWCreativeTransformers';
import { creativeNeverUploadPOJOFields } from './creativeNeverUploadPOJOFields';
import * as excelColumns from './excelColumns';

export const creativeConfig = {
    entity: ENTITY.CREATIVE,
    sheetName: SHEET_NAME.CREATIVES,
    exampleRowIndex: 1,
    headerRowIndex: 2,
    columns: [
        excelColumns.creativeId,
        excelColumns.campaignId,
        excelColumns.landingPageUrl,
        excelColumns.creativeName,
        excelColumns.customId,
        excelColumns.title,
        excelColumns.creativeDescription,
        excelColumns.cta,
        excelColumns.thumbnailFileName,
        excelColumns.thumbnailUrl,
        excelColumns.motionAdFileName,
        excelColumns.motionAdVideoUrl,
        excelColumns.motionAdGifUrl,
        excelColumns.motionAdDefaultImageFileName,
        excelColumns.motionAdDefaultImageUrl,
        excelColumns.logoFileName,
        excelColumns.logoUrl,
        excelColumns.creativeStatus,
        excelColumns.thirdPartyTrackingPixelUrl(0),
        excelColumns.thirdPartyTrackingTagType(0),
        excelColumns.thirdPartyTrackingPixelEvent(0),
        excelColumns.thirdPartyTrackingPixelUrl(1),
        excelColumns.thirdPartyTrackingTagType(1),
        excelColumns.thirdPartyTrackingPixelEvent(1),
        excelColumns.thirdPartyTrackingPixelUrl(2),
        excelColumns.thirdPartyTrackingTagType(2),
        excelColumns.thirdPartyTrackingPixelEvent(2),
        excelColumns.thirdPartyTrackingPixelUrl(3),
        excelColumns.thirdPartyTrackingTagType(3),
        excelColumns.thirdPartyTrackingPixelEvent(3),
        excelColumns.thirdPartyTrackingPixelUrl(4),
        excelColumns.thirdPartyTrackingTagType(4),
        excelColumns.thirdPartyTrackingPixelEvent(4),
    ],
    neverUploadPOJOFields: creativeNeverUploadPOJOFields,
    toGWTransformers: toGWCreativeTransformers,
};
