'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var TOOLTIP_BOUNDARY = {
    SCROLL_PARENT: 'scrollParent',
    WINDOW: 'window',
    VIEWPORT: 'viewport',
    HTML_ELEMENT: 'HTMLElement'
};

exports.default = TOOLTIP_BOUNDARY;