import { isNetworkAccountType } from 'modules/account-management';
import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    FormattedMessageHeaderRenderer,
    withCampaignEditIndication,
} from 'modules/campaigns/modules/campaigns-reports/components';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';

const performanceRuleExecutedActionsReportColumnDefinitions = [
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_DATE.label,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_DATE.field,
        valueFormatter: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_DATE.valueFormatter,
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.label,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.cellRendererFramework,
        cellRendererParams: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.renderParams,
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.label,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.cellRendererFramework,
        headerComponentParams: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.headerComponentParams,
        visibilityCondition: ({ selectedAccount: { type } }) => isNetworkAccountType(type),
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME.cellRendererFramework,
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY.label,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY.cellRendererFramework,
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS.label,
        field: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS.field,
        cellRendererFramework: FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS.cellRendererFramework,
        cellRendererParams: { isSearchable: true },
    },
];

export default normalizeColumnDefs(
    performanceRuleExecutedActionsReportColumnDefinitions,
    reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS].id,
    withCampaignEditIndication
);
