import React, { useCallback } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { CTA_OPTIONS, CTA_OPTIONS_NO_NONE } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownField } from '../../../../components/Form';

const placeholder = <FormattedMessage id="creative.creator.content.cta.placeholder" defaultMessage="Click to select" />;

export const CTA_FIELD_ID = 'cta';
export const CTA_TYPE_PATH = 'ctaType';

const CTADropdown = ({
    language,
    selected,
    onSelect,
    disabled,
    disabledPlaceholderId,
    checkboxLabel,
    styles: stylesProp,
    selectClassPrefixOverride,
    onChange: propsOnChange,
    shouldIncludeNoneOption,
    label,
    ...rest
}) => {
    const { value, onChange } = useFormFieldValue({ field: CTA_FIELD_ID });
    const { formatMessage } = useIntl();
    const ctaType = get(value, CTA_TYPE_PATH);

    const changeHandler = useCallback(
        ({ value: ctaType }) => {
            onChange({ ctaType });
            propsOnChange(ctaType);
        },
        [onChange, propsOnChange]
    );

    const disabledProps = disabled && {
        disabled,
        ...(disabledPlaceholderId &&
            !ctaType && {
                placeholder: formatMessage({ id: disabledPlaceholderId }),
                selectedValueObject: { value: null },
            }),
    };

    return (
        <DropdownField
            label={checkboxLabel ? null : label}
            checkboxLabel={checkboxLabel}
            placeholder={placeholder}
            options={shouldIncludeNoneOption ? CTA_OPTIONS : CTA_OPTIONS_NO_NONE}
            selectedValueObject={{ value: ctaType }}
            onChange={changeHandler}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'CTA',
            }}
            selected={selected}
            onSelect={onSelect}
            styles={stylesProp}
            selectClassPrefixOverride={selectClassPrefixOverride}
            {...disabledProps}
            {...rest}
        />
    );
};

CTADropdown.protoTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    language: PropTypes.string,
    disabled: PropTypes.bool,
    disabledPlaceholderId: PropTypes.string,
    styles: PropTypes.object,
    selectClassPrefixOverride: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.node,
};

CTADropdown.defaultProps = {
    onChange: () => {},
    shouldIncludeNoneOption: true,
    label: <FormattedMessage id="creative.creator.content.cta.optional.label" />,
};

export default CTADropdown;
