import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { startCase } from 'lodash';
import { MODULE_NAME } from 'modules/billing-and-payments/config/routes';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import * as reportConfigIndex from 'modules/campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from 'modules/campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import {
    addStaticIndication,
    INDICATION_TYPES,
    removeStaticIndication,
} from 'modules/taboola-common-frontend-modules/Indications';
import { useIntl, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BillingBreadcrumbs } from '../BillingBreadcrumbs';
import styles from './invoicesAndPaymentsReportsPage.module.scss';

const buildReportNameForExport = selectedReport => ` - ${startCase(selectedReport)}`;
const EMPTY_RANGE_PICKER = {};
const getInvoicesAndPaymentsReportsPageIndication = () => ({
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.invoicesAndPayments.highlight"
            defaultMessage="Invoices appear on the 7th of every month"
        />
    ),
    message: (
        <FormattedMessage
            defaultMessage="- The monthly invoice is sent by mail at the beginning of the month and will also appear here on the 7th day of every month."
            id="app.campaign.indication.invoicesAndPayments.message"
        />
    ),
});

export const InvoicesAndPaymentsReportsPage = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const containerRef = useRef();
    const { state } = useLocation();
    const title = formatMessage({
        id: 'navigation.caption.invoices-and-payments',
    });
    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    useEffect(() => {
        dispatch(addStaticIndication(getInvoicesAndPaymentsReportsPageIndication()));
        return () => {
            dispatch(removeStaticIndication(getInvoicesAndPaymentsReportsPageIndication()));
        };
    }, [dispatch]);

    return (
        <div className={styles['container']} ref={containerRef}>
            <CampaignReports
                skipFetchReport={state && state.skipFetchReport}
                parentContainerRef={containerRef}
                module={MODULE_NAME}
                header={<BillingBreadcrumbs title={title} />}
                rangePickerPresetConfigs={EMPTY_RANGE_PICKER}
                buildReportNameForExport={buildReportNameForExport}
                reportConfigMap={reportConfigMap}
            />
        </div>
    );
};
