import React, { useState } from 'react';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './videoPreviewImages.module.scss';

export const VideoPreviewImages = ({ previewMode, imageSetNumber }) => {
    const [videoStatus, setVideoStatus] = useState(COMPONENT_STATUS.LOADING);
    return (
        <>
            {videoStatus === COMPONENT_STATUS.LOADING && <LoadingAnimation className={styles['container']} />}
            <video
                className={styles['container']}
                src={`${process.env.PUBLIC_URL}/previewsDrawerAssets/${previewMode}-animation-${imageSetNumber}.mov`}
                onLoadedData={() => {
                    setVideoStatus(COMPONENT_STATUS.ACTIVE);
                }}
                onMouseEnter={e => e.target.play()}
            />
        </>
    );
};
