import { useCallback, useMemo, useState } from 'react';
import { isEmpty, keyBy, uniq, partition } from 'lodash';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

const DELIMITER = /[,;\n\t]/g;
const EMPTY_SET = new Set();

const useResources = ({ validateInput }) => {
    const [resourcesById, setResourcesById] = useState({});
    const [loadingIdsSet, setLoadingIdsSet] = useState(EMPTY_SET);
    const getResourcesByIds = useCallback(
        async idsToFetch => {
            setLoadingIdsSet(prev => {
                return new Set([...prev, ...idsToFetch]);
            });

            const validValues = await validateInput(idsToFetch);
            const additionalResourcesById = keyBy(validValues, 'name');

            const idsToFetchSet = new Set(idsToFetch);
            setLoadingIdsSet(prev => new Set([...prev].filter(id => !idsToFetchSet.has(id))));

            if (isEmpty(additionalResourcesById)) {
                return additionalResourcesById;
            }

            setResourcesById(prev => ({ ...prev, ...additionalResourcesById }));

            return additionalResourcesById;
        },
        [validateInput]
    );

    const clear = useCallback(() => {
        setResourcesById({});
        setLoadingIdsSet(EMPTY_SET);
    }, []);

    return {
        resourcesById,
        loadingIdsSet,
        clear,
        getResourcesByIds,
    };
};

const useListWithMultilineInputSelectorState = ({ valueField, validateInput, validations, hasInitialData }) => {
    const [isReady, setIsReady] = useState(false);

    const { resourcesById, loadingIdsSet, clear, getResourcesByIds } = useResources({
        validateInput,
    });

    const {
        value: selectedIds,
        onChange: onChangeSelectedIds,
        failedValidationData,
        scrollRef,
    } = useFormValidatedValue({
        field: valueField,
        validations,
        validationDependencies: { resourcesById, loadingIdsSet },
        isReady,
        hasInitialData,
    });

    const [text, setText] = useState('');
    const inputValues = useMemo(
        () =>
            text
                .split(DELIMITER)
                .map(val => val.trim())
                .filter(val => !isEmpty(val)),
        [text]
    );
    const [invalidInputValues, setInvalidInputValues] = useState([]);

    const selectedValues = useMemo(() => {
        const fetchedValues = uniq([...selectedIds, ...loadingIdsSet]).map(id => {
            if (!resourcesById[id] || !isReady) {
                return { id, name: id, loading: loadingIdsSet.has(id) || !isReady };
            }

            return { ...resourcesById[id], id, loading: loadingIdsSet.has(id) };
        });

        return fetchedValues;
    }, [selectedIds, loadingIdsSet, resourcesById, isReady]);

    const invalidSelectedValuesSet = useMemo(() => {
        const invalidIds = selectedIds.filter(id => !resourcesById[id] && !loadingIdsSet.has(id)).map(id => id);
        return new Set(invalidIds);
    }, [resourcesById, loadingIdsSet, selectedIds]);

    const handleInputChange = useCallback(e => {
        setText(e.target.value);
        setInvalidInputValues([]);
    }, []);

    const handleDeleteItem = useCallback(
        ({ name }) => {
            onChangeSelectedIds(prevSelectedIds => prevSelectedIds.filter(id => name !== id));
        },
        [onChangeSelectedIds]
    );

    const handleClearItems = useCallback(() => {
        clear();
        onChangeSelectedIds([]);
    }, [clear, onChangeSelectedIds]);

    const handleClearInvalidItems = useCallback(() => {
        onChangeSelectedIds(prevSelectedIds => prevSelectedIds.filter(id => !invalidSelectedValuesSet.has(id)));
    }, [invalidSelectedValuesSet, onChangeSelectedIds]);

    const handleAddItems = useCallback(async () => {
        const selectedValuesMap = keyBy(selectedValues, 'name');
        const idsToFetch = inputValues.filter(id => !selectedValuesMap[id]);
        if (isEmpty(idsToFetch)) {
            setText('');
            return;
        }

        const additionalResourcesMap = await getResourcesByIds(idsToFetch);

        const [validIds, invalidIds] = partition(idsToFetch, id => additionalResourcesMap[id]);
        if (!isEmpty(validIds)) {
            onChangeSelectedIds(prevSelectedIds => uniq([...prevSelectedIds, ...validIds]));
        }

        setText(invalidIds.join('\n'));
        setInvalidInputValues(invalidIds);
    }, [selectedValues, inputValues, getResourcesByIds, onChangeSelectedIds]);

    const initResources = useCallback(async () => {
        if (isReady) {
            return;
        }

        if (isEmpty(selectedIds)) {
            setIsReady(true);
            return;
        }

        await getResourcesByIds(selectedIds);
        setIsReady(true);
    }, [getResourcesByIds, isReady, selectedIds]);

    return {
        selectedValues,
        invalidSelectedValuesSet,
        invalidInputValues,
        inputValues,
        text,
        initResources,
        resourcesById,
        failedValidationData,
        scrollRef,
        isLoading: !isReady || !isEmpty(loadingIdsSet),
        handleInputChange,
        handleDeleteItem,
        handleClearItems,
        handleClearInvalidItems,
        handleAddItems,
    };
};

export default useListWithMultilineInputSelectorState;
