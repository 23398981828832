import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage, useIntl } from '../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../taboola-common-frontend-modules/modals';
import { CREDIT_LIMIT_MODEL } from '../config/CreditLimitModel';

const successIndication = {
    message: (
        <FormattedMessage
            id="billingAndPayments.paymentMethod.delete.success"
            defaultMessage="Payment method successfully removed."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="billingAndPayments.paymentMethod.delete.error.highlight"
            defaultMessage="Unable to remove the payment method."
        />
    ),
});

const dateFormat = 'MMMM D, YYYY';

export const usePaymentDeleteConfirmation = ({
    onDelete,
    accountName,
    currency,
    paymentMethodId,
    paymentMethodType,
    billingData,
}) => {
    const { open: openModal } = useModal();
    const dispatch = useDispatch();
    const { formatNumber } = useIntl();

    const deletePaymentMethod = useCallback(async () => {
        try {
            await onDelete({
                accountId: accountName,
                paymentMethodId,
                paymentMethodType,
            });
            dispatch(addIndication(successIndication));
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
        }
    }, [accountName, dispatch, onDelete, paymentMethodId, paymentMethodType]);

    const confirmPaymentDelete = useCallback(async () => {
        const { paymentMethodsV2, creditLimitModel, ccbBillingCycle } = billingData;
        const isLastPaymentMethod = paymentMethodsV2.length === 1;
        const isCCB = creditLimitModel === CREDIT_LIMIT_MODEL.CCB;
        const nextBillingDate = isCCB ? moment().add(1, 'months').startOf('month').format(dateFormat) : null;

        const result = await openModal({
            title: (
                <FormattedMessage
                    id={`billingAndPayments.paymentMethod.delete${
                        isLastPaymentMethod ? 'Last' : ''
                    }.confirmation.title`}
                    defaultMessage="Remove Payment Method?"
                    tagName="h2"
                />
            ),
            content:
                isLastPaymentMethod && isCCB ? (
                    <FormattedMessage
                        id="billingAndPayments.paymentMethod.deleteLastCCB.confirmation.message"
                        defaultMessage="You are about to remove the only payment method."
                        values={{
                            nextBillingDate,
                            ccbBillingCycle: formatNumber(ccbBillingCycle, {
                                style: 'currency',
                                currency,
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }),
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="billingAndPayments.paymentMethod.delete.confirmation.message"
                        defaultMessage="You are about to remove the payment method."
                    />
                ),
            formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" /> },
        });

        if (result) {
            await deletePaymentMethod();
        }
    }, [billingData, currency, deletePaymentMethod, formatNumber, openModal]);

    return {
        confirmPaymentDelete,
    };
};
