'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useDebouncedAsyncCallback = undefined;

var _react = require('react');

var _lodash = require('lodash.debounce');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var useDebouncedAsyncCallback = exports.useDebouncedAsyncCallback = function useDebouncedAsyncCallback(cb, wait) {
    var fn = (0, _react.useMemo)(function () {
        return (0, _lodash2.default)(function (_ref) {
            for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                args[_key - 1] = arguments[_key];
            }

            var resolve = _ref.resolve,
                reject = _ref.reject;

            cb.apply(undefined, args).then(resolve).catch(reject);
        }, wait);
    }, [wait, cb]);
    return (0, _react.useCallback)(function () {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
        }

        return new Promise(function (resolve, reject) {
            return fn.apply(undefined, [{ resolve: resolve, reject: reject }].concat(args));
        });
    }, [fn]);
};

exports.default = useDebouncedAsyncCallback;