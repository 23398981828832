import ReactDOM from 'react-dom';
import { get, uniqBy } from 'lodash';

const addUrlHeadersAndFilter = urlHeader => {
    const items = document.querySelectorAll('.videoCube');
    const shouldInsertHeaders = uniqBy([...items], item => get(item, ['video_data', 'placementUrl'], '')).length > 1;
    const urlSet = new Set();
    items.forEach(item => {
        const url = get(item, ['video_data', 'placementUrl'], '');
        const hideMe = get(item, ['video_data', 'hideMe'], false);
        if (hideMe) {
            item.style.visibility = 'hidden';
        }
        if (!urlSet.has(url) && shouldInsertHeaders) {
            urlSet.add(url);
            const firstHeader = urlSet.size === 1;
            const parent = item.parentNode;
            const container = document.createElement('div');
            parent.insertBefore(container, item);
            ReactDOM.render(urlHeader({ url, firstHeader }), container);
        }
    });
};

export default addUrlHeadersAndFilter;
