import React from 'react';
import { UIC } from 'tuui/lib/utils/typing';

export interface StepProps {
    title: string;
}

export const Step: UIC<StepProps> = () => {
    return <></>;
};
