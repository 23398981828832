import React, { useEffect } from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import BrandingTooltip from 'modules/campaigns/modules/common-campaign-form/components/Branding/BrandingTooltip';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './brandNameFormField.module.scss';

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: 60 },
        messageId: 'video.creative.creator.content.brand.validation.error',
        defaultMessage: 'Branding Text can not exceed 60 characters',
    },
];

export const BrandNameFormField = () => {
    const {
        value: brandingText,
        onChange: onBrandingTextChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'brandName',
        validations,
    });

    const changeHandler = useEventValueHandler(onBrandingTextChange);
    const { formatMessage } = useIntl();

    useEffect(() => {
        return () => onBrandingTextChange('');
    }, [onBrandingTextChange]);

    return (
        <FormField
            ref={scrollRef}
            inputId="branding"
            containerClass={styles['input']}
            label={<FormattedMessage id="video.creative.creator.content.brand.label" defaultMessage="Brand Name" />}
            description={
                <FormattedMessage
                    id="video.creative.creator.content.brand.description"
                    defaultMessage="The name of your product, brand or website."
                />
            }
            subDescription={
                <FormattedMessage
                    id="video.creative.creator.content.brand.subdescription"
                    defaultMessage="This name will appear in your ad, below the title."
                />
            }
            helpText={<BrandingTooltip />}
        >
            <div className={styles['inline']}>
                <InputWithIndication
                    id="branding"
                    placeholder={formatMessage({
                        id: 'video.creative.creator.content.brand.palceholder',
                        defaultMessage: 'Enter Brand Name',
                    })}
                    type={InputTypes.TEXT}
                    value={brandingText}
                    onChange={changeHandler}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Branding"
                    inputWrapperClass={styles['inline']}
                    mainClass={styles['inline']}
                    {...indicationData}
                />
            </div>
        </FormField>
    );
};
