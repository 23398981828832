import { get, isEqual, pick, set } from 'lodash';
import { ACCOUNT_ID, DIMENSION } from 'hooks';
import { isProgrammaticAccount } from 'modules/account-management/accountType';
import { campaignNameValidations, REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { CAMPAIGNS_ROUTE } from 'modules/campaigns/config/routes';
import { CAMPAIGN_ID } from 'modules/campaigns/hooks';
import { setSelectedRows } from 'modules/campaigns/modules/campaigns-reports/actions';
import { duplicateCampaign, saveCampaign } from 'modules/campaigns/modules/campaigns-reports/flows';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { SPENDING_LIMITS } from 'modules/campaigns/modules/common-campaign-form/config/spendingLimits';
import { hasCampaignCarousel } from 'modules/campaigns/modules/creative-creator/components/CarouselCardList/utils/hasCampaignCarousel';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PERMISSIONS_OPERATORS } from 'modules/taboola-common-frontend-modules/authentication';
import {
    ActionableTextWithInternalLinkCellRenderer,
    CampaignActionsCellRenderer,
    CampaignStatusRenderer,
    CheckboxCellRenderer,
    CheckboxHeaderRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    StateCellRenderer,
    withCampaignEditIndication,
} from '../../../components';
import { DATE_PRESET, END_DATE, REPORT_ID, START_DATE } from '../../../hooks';
import {
    actionsColumnType,
    checkBoxSelectionColumn,
    editableColumn,
    keyColumn,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from '../../columns';
import * as FIELDS from '../../fields';
import { nonDefaultHeaderComponentParams } from '../../fields';
import {
    isBidEditable,
    isBudgetEditable,
    isCampaignEditable,
    isCpaGoalEditable,
    isCpcCapEditable,
    isSpendingLimitEditable,
    onlyNetworkColumn,
} from '../../predicates';

const isDisabled = data => !isCampaignEditable({ data });

const campaignReportColumnDefinition = [
    ...[
        {
            headerComponentFramework: CheckboxHeaderRenderer,
            headerName: FIELDS.IS_SELECTED.label,
            field: FIELDS.IS_SELECTED.field,
            type: [leftPinnedColumn, checkBoxSelectionColumn, staticColumn],
            cellRendererParams: {
                onClick: setSelectedRows,
                isDisabled,
            },
            headerComponentParams: {
                onClick: setSelectedRows,
                isDisabled,
            },
            cellRendererFramework: CheckboxCellRenderer,
            csv: false,
            permissions: {
                permissions: ['CAMPAIGNS_BULK_UPDATE', 'CAMPAIGNS_BULK_UPDATE_NON_ATOMIC'],
                operator: PERMISSIONS_OPERATORS.AND,
            },
            accountConfigurations: {
                [FEATURE_FLAGS.CAMPAIGNS_BULK_UPDATE_API_ENABLED_KEY]: 'true',
            },
        },
        {
            headerName: FIELDS.IS_ACTIVE.label,
            field: FIELDS.IS_ACTIVE.field,
            type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
            cellRendererParams: {
                ...FIELDS.IS_ACTIVE.renderParams,
                isDisabled,
                reportId: REPORT_TYPE.CAMPAIGN,
            },
            cellRendererFramework: StateCellRenderer,
            csv: false,
        },
        {
            headerName: FIELDS.ID.label,
            field: FIELDS.ID.field,
            type: [numericColumn],
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.CAMPAIGN),
        },
        {
            headerName: FIELDS.DEMAND_TYPE.label,
            field: FIELDS.DEMAND_TYPE.field,
            cellRendererParams: {
                msgIdPrefix: 'app.campaigns.campaign.demand.type',
            },
            valueFormatter: FIELDS.DEMAND_TYPE.valueFormatter,
            csvValueGetter: FIELDS.DEMAND_TYPE.csvValueGetter,
            visibilityCondition: ({ selectedAccount }) => isProgrammaticAccount(selectedAccount),
            type: [leftPinnedColumn],
        },
        {
            headerName: FIELDS.NAME.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.NAME.headerComponentParams,
            field: FIELDS.NAME.field,
            type: [leftPinnedColumn, keyColumn, editableColumn],
            editable: isCampaignEditable,
            cellRendererFramework: ActionableTextWithInternalLinkCellRenderer,
            cellRendererParams: {
                lines: 2,
                to: CAMPAIGNS_ROUTE,
                paramsToKeep: [ACCOUNT_ID, DIMENSION, START_DATE, END_DATE, DATE_PRESET],
                paramsFactory: ({ id, campaignItemType }) => {
                    if (hasCampaignCarousel({ campaignItemType })) {
                        return {
                            [REPORT_ID]: REPORT_TYPE.HIERARCHY_CAMPAIGN,
                            [CAMPAIGN_ID]: id,
                        };
                    }
                    return {
                        [REPORT_ID]: REPORT_TYPE.CREATIVE,
                        [CAMPAIGN_ID]: id,
                    };
                },
                isSearchable: true,
            },
            cellEditorFramework: FIELDS.NAME.cellEditorFramework,
            cellEditorParams: {
                validations: campaignNameValidations,
                editableTextClassName: 'with-formatted-spaces',
            },
        },
        {
            headerName: FIELDS.STATUS.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.STATUS.headerComponentParams,
            field: FIELDS.STATUS.field,
            type: [leftPinnedColumn],
            cellRendererFramework: CampaignStatusRenderer,
            width: 180,
            minWidth: 56,
            cellRendererParams: FIELDS.STATUS.renderParams,
            csvValueGetter: FIELDS.STATUS.csvValueGetter,
            // TODO: DEV-71981 change valueGetter/equals once a generic solution is implemented
            valueGetter: ({ data }) =>
                pick(data, [
                    'status',
                    'isActive',
                    'creativeTrafficAllocationMethod',
                    'creativeTrafficAllocationABTestEndDate',
                    'bidStrategy',
                ]),
            equals: isEqual,
        },
        {
            headerName: FIELDS.ACCOUNT_ID.label,
            field: FIELDS.ACCOUNT_ID.field,
            visibilityCondition: onlyNetworkColumn,
        },
        {
            headerName: FIELDS.ACCOUNT_DESCRIPTION.label,
            field: FIELDS.ACCOUNT_DESCRIPTION.field,
            cellRendererFramework: FIELDS.ACCOUNT_DESCRIPTION.cellRendererFramework,
            cellRendererParams: FIELDS.ACCOUNT_DESCRIPTION.cellRendererParams,
            visibilityCondition: onlyNetworkColumn,
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_ID.label,
            field: FIELDS.CAMPAIGNS_GROUP_ID.field,
            type: [numericColumn],
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_ID.headerComponentParams,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_NAME.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_NAME.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_NAME.field,
            cellRendererFramework: FIELDS.CAMPAIGNS_GROUP_NAME.cellRendererFramework,
            cellRendererParams: FIELDS.CAMPAIGNS_GROUP_NAME.cellRendererParams,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
        },
        {
            headerName: FIELDS.CAMPAIGN_CONVERSION_RULES.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGN_CONVERSION_RULES.headerComponentParams,
            field: FIELDS.CAMPAIGN_CONVERSION_RULES.field,
            cellRendererFramework: FIELDS.CAMPAIGN_CONVERSION_RULES.cellRendererFramework,
            valueFormatter: FIELDS.CAMPAIGN_CONVERSION_RULES.valueFormatter,
            accountConfigurations: FIELDS.CAMPAIGN_CONVERSION_RULES.accountConfigurations,
        },
        {
            headerName: FIELDS.BUDGET.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.BUDGET.headerComponentParams,
            field: FIELDS.BUDGET.field,
            type: [numericColumn, editableColumn],
            editable: isBudgetEditable,
            cellRendererFramework: FIELDS.BUDGET.cellRenderer,
            cellRendererParams: FIELDS.BUDGET.renderParams,
            csv: FIELDS.BUDGET.csv,
            cellEditorFramework: FIELDS.BUDGET.cellEditorFramework,
            cellEditorParams: {
                fieldPath: 'budget.value',
            },
            valueGetter: 'data.budget.value',
            valueSetter: params => set(params, 'data.budget.value', +params.newValue),
        },
        {
            headerName: FIELDS.SPENDING_LIMIT.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.SPENDING_LIMIT.headerComponentParams,
            field: FIELDS.SPENDING_LIMIT.field,
            dataField: FIELDS.BUDGET.field,
            type: [editableColumn, numericColumn],
            editable: isSpendingLimitEditable,
            cellRendererFramework: FIELDS.SPENDING_LIMIT.cellRenderer,
            cellRendererParams: FIELDS.SPENDING_LIMIT.renderParams,
            csv: FIELDS.SPENDING_LIMIT.csv,
            cellEditorFramework: FIELDS.SPENDING_LIMIT.cellEditorFramework,
            valueGetter: 'data.budget.spendingLimit.value',
            cellEditorParams: {
                fieldPath: 'budget.spendingLimit.value',
            },
            valueSetter: params => set(params, 'data.budget.spendingLimit.value', +params.newValue),
        },
        {
            headerName: FIELDS.BID.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.BID.headerComponentParams,
            field: FIELDS.BID.field,
            type: [editableColumn, numericColumn],
            editable: isBidEditable,
            cellRendererFramework: FIELDS.BID.cellRenderer,
            cellRendererParams: FIELDS.BID.renderParams,
            cellEditorFramework: FIELDS.BID.cellEditorFramework,
            valueParser: ({ newValue }) => Number(newValue),
        },
        {
            headerName: FIELDS.CPA_GOAL.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CPA_GOAL.headerComponentParams,
            field: FIELDS.CPA_GOAL.field,
            type: [editableColumn, numericColumn],
            editable: isCpaGoalEditable,
            cellRendererFramework: FIELDS.CPA_GOAL.cellRenderer,
            cellRendererParams: FIELDS.CPA_GOAL.renderParams,
            cellEditorFramework: FIELDS.CPA_GOAL.cellEditorFramework,
            valueParser: ({ newValue }) => Number(newValue),
        },
        {
            headerName: FIELDS.CPC_CAP.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CPC_CAP.headerComponentParams,
            field: FIELDS.CPC_CAP.field,
            type: [editableColumn, numericColumn],
            editable: isCpcCapEditable,
            cellRendererFramework: FIELDS.CPC_CAP.cellRenderer,
            cellRendererParams: FIELDS.CPC_CAP.renderParams,
            cellEditorFramework: FIELDS.CPC_CAP.cellEditorFramework,
            valueParser: ({ newValue }) => Number(newValue),
        },
        {
            headerName: FIELDS.START_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.START_DATE.headerComponentParams,
            field: FIELDS.START_DATE.field,
            valueFormatter: FIELDS.START_DATE.valueFormatter,
            csvValueGetter: FIELDS.START_DATE.csvValueGetter,
        },
        {
            headerName: FIELDS.END_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.END_DATE.headerComponentParams,
            field: FIELDS.END_DATE.field,
            type: [editableColumn],
            editable: isCampaignEditable,
            valueFormatter: FIELDS.END_DATE.valueFormatter,
            csvValueGetter: FIELDS.END_DATE.csvValueGetter,
            cellEditorFramework: FIELDS.END_DATE.cellEditorFramework,
            cellEditorParams: {
                placeholderMsgId: 'campaign.time.frame.no.end.date',
                defaultPlaceholderMsg: 'No End Date',
                inputIconMsgId: 'campaign.time.frame.end',
                defaultInputIconMsg: 'End',
            },
        },
        {
            headerName: FIELDS.BID_STRATEGY.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.BID_STRATEGY.headerComponentParams,
            field: FIELDS.BID_STRATEGY.field,
            cellRendererFramework: FIELDS.BID_STRATEGY.cellRenderer,
            csvValueGetter: FIELDS.BID_STRATEGY.csvValueGetter,
        },
        {
            headerName: FIELDS.SAFETY_LEVEL.label,
            headerValueGetter: FIELDS.SAFETY_LEVEL.headerValueGetter,
            headerComponentParams: FIELDS.SAFETY_LEVEL.headerComponentParams,
            field: FIELDS.SAFETY_LEVEL.field,
            cellRendererFramework: FIELDS.SAFETY_LEVEL.cellRenderer,
            cellRendererParams: FIELDS.SAFETY_LEVEL.renderParams,
            csvValueGetter: FIELDS.SAFETY_LEVEL.csvValueGetter,
        },
    ].map(colDef => ({ valueChangeHandler: saveCampaign, ...colDef })),
    ...[
        FIELDS.ESTIMATED_DAILY_CAP,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.AVERAGE_CPC,
        FIELDS.VCTR,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.UNIQUE_IMPRESSIONS,
        FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS,
        FIELDS.UNIQUE_CLICKS,
        FIELDS.PROFIT_MARGIN,
        FIELDS.ESTIMATED_BILLABLE_AMOUNT,
        {
            ...FIELDS.SPENT,
            renderParams: {
                ...FIELDS.SPENT.renderParams,
                barVisibilityCondition: data => get(data, 'budget.type') === SPENDING_LIMITS.LIFETIME,
            },
        },
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        accountConfigurations: fieldConfig.accountConfigurations,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        valueGetter: params => params.data,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererParams: {
            onDuplicate: duplicateCampaign,
            onTogglePolicyState: saveCampaign,
        },
        cellRendererFramework: CampaignActionsCellRenderer,
        csv: false,
        minWidth: 100,
        maxWidth: 150,
        width: 150,
    },
];

export default normalizeColumnDefs(
    campaignReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CAMPAIGN].id,
    withCampaignEditIndication
);
