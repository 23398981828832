import { useEffect } from 'react';
import { FormField, Radio } from 'taboola-ultimate-ui';
import { RadioGroupField } from '../../../campaigns/components/Form';
import { useCampaignsFormFieldValue } from '../../../campaigns/hooks';
import { CampaignsField } from '../../../campaigns/modules/creative-creator/components';
import { CONTENT_METHODS } from '../../../campaigns/modules/creative-creator/config';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { scheduledReportsCampaignListValidations } from '../../validations/scheduledReportsCampaignListValidations';
import { ALL_CAMPAIGNS, CAMPAIGNS_LIST_TYPES, SPECIFIC_CAMPAIGNS } from './scheduledReportsCampaignListTypes';
import styles from './scheduledReportsCampaignListType.module.scss';

export const ScheduledReportsCampaignListSelect = ({ account }) => {
    const {
        value: campaignSelectType,
        onChange: campaignSelectTypeChange,
        resetFieldToInitial,
    } = useFormFieldValue({
        field: 'campaignSelectType',
    });
    const {
        value: campaigns,
        resetFieldToInitial: resetCampaigns,
        isFieldEqualToInitial: isCampaignsInitValue,
    } = useCampaignsFormFieldValue();

    useEffect(() => resetFieldToInitial, [resetFieldToInitial]);
    useEffect(() => {
        if (campaignSelectType === ALL_CAMPAIGNS && !isCampaignsInitValue) {
            resetCampaigns();
        }
    }, [campaignSelectType, isCampaignsInitValue, resetCampaigns]);

    return (
        <>
            <FormField
                inputId="campaign-select-type"
                label={
                    <FormattedMessage
                        id="app.create.scheduled.reports.campaign.select.title"
                        defaultMessage="Campaign"
                    />
                }
            >
                <RadioGroupField
                    id="campaignSelectType"
                    name="select-type-"
                    radioGroupName="listType"
                    className={styles['group']}
                    onChange={campaignSelectTypeChange}
                    selectedValue={campaignSelectType}
                >
                    {CAMPAIGNS_LIST_TYPES.map(campaignListType => (
                        <Radio
                            key={campaignListType}
                            value={campaignListType}
                            title={
                                <FormattedMessage
                                    id={`app.create.scheduled.reports.campaign.select.type.${campaignListType}`}
                                />
                            }
                            labelClassName={styles['radio']}
                        />
                    ))}
                </RadioGroupField>
            </FormField>
            {campaignSelectType === SPECIFIC_CAMPAIGNS && (
                <CampaignsField
                    className={styles['campaigns-field']}
                    selectedCampaigns={campaigns}
                    accountForCampaign={account}
                    contentMethod={CONTENT_METHODS.VARIATIONS}
                    helpText=""
                    validations={scheduledReportsCampaignListValidations}
                />
            )}
        </>
    );
};
