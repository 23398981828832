'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Icons = require('../Icons/Icons');

var _BaseButton = require('../Buttons/BaseButton/BaseButton');

var _BaseButton2 = _interopRequireDefault(_BaseButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'collapsibleSideBar__taboola-svg-icon___5V-pm',
    'container': 'collapsibleSideBar__container___34D1s',
    'isPinned': 'collapsibleSideBar__isPinned___2OlR9',
    'button-wrapper': 'collapsibleSideBar__button-wrapper___3vuCH',
    'button': 'collapsibleSideBar__button___3jt1T',
    'sidebar': 'collapsibleSideBar__sidebar___20xt1',
    'isOpen': 'collapsibleSideBar__isOpen___yXPsJ',
    'sidebar-content': 'collapsibleSideBar__sidebar-content___3x0au',
    'main-content': 'collapsibleSideBar__main-content___F1scD'
};


var classNameBuilder = _bind2.default.bind(styles);

var CollapsibleSideBar = function (_PureComponent) {
    _inherits(CollapsibleSideBar, _PureComponent);

    function CollapsibleSideBar(props) {
        _classCallCheck(this, CollapsibleSideBar);

        var _this = _possibleConstructorReturn(this, (CollapsibleSideBar.__proto__ || Object.getPrototypeOf(CollapsibleSideBar)).call(this, props));

        _initialiseProps.call(_this);

        _this.buttonWrapperRef = _react2.default.createRef();
        _this.sideBarRef = _react2.default.createRef();

        _this.state = {
            isOpen: false
        };
        return _this;
    }

    _createClass(CollapsibleSideBar, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps, prevState) {
            var wasVisible = this.isVisible(prevProps, prevState);
            var isVisible = this.isVisible(this.props, this.state);

            if (isVisible !== wasVisible) {
                this.props.onVisibilityChange(isVisible);
            }
        }
    }, {
        key: 'renderCollapsibleContent',
        value: function renderCollapsibleContent() {
            var isOpen = this.state.isOpen;
            var _props = this.props,
                content = _props.content,
                isPinned = _props.isPinned,
                sideBarClassName = _props.sideBarClassName,
                pinnedButtonIcon = _props.pinnedButtonIcon,
                pinnedButtonClassName = _props.pinnedButtonClassName,
                unpinnedButtonIcon = _props.unpinnedButtonIcon,
                unpinnedButtonClassName = _props.unpinnedButtonClassName,
                buttonWrapperClassName = _props.buttonWrapperClassName,
                buttonAttributes = _props.buttonAttributes,
                collapsedSize = _props.collapsedSize,
                expandedContentMinSize = _props.expandedContentMinSize,
                expandedContentSize = _props.expandedContentSize,
                expandedContentMaxSize = _props.expandedContentMaxSize;

            var sidebarClassName = classNameBuilder('sidebar', sideBarClassName, {
                isOpen: isOpen && !isPinned,
                isPinned: isPinned
            });

            var sidebarContentClassName = classNameBuilder('sidebar-content', {
                isOpen: isOpen && !isPinned,
                isPinned: isPinned
            });

            var calculatedButtonWrapperClassName = classNameBuilder('button-wrapper', buttonWrapperClassName);

            var buttonClassName = classNameBuilder('button', isPinned ? pinnedButtonClassName : unpinnedButtonClassName, { isPinned: isPinned });
            var isSideBarVisible = isOpen || isPinned;

            var sideBarStyle = {
                width: isSideBarVisible ? expandedContentSize : collapsedSize,
                minWidth: isSideBarVisible ? expandedContentMinSize : collapsedSize,
                maxWidth: expandedContentMaxSize
            };

            return _react2.default.createElement(
                'div',
                {
                    'aria-label': 'Sidebar',
                    className: sidebarClassName,
                    style: sideBarStyle,
                    onMouseEnter: this.onMouseEnter,
                    onMouseLeave: this.onMouseLeave,
                    ref: this.sideBarRef
                },
                _react2.default.createElement(
                    'div',
                    { className: sidebarContentClassName },
                    content
                ),
                _react2.default.createElement(
                    'div',
                    { className: calculatedButtonWrapperClassName, onMouseLeave: this.onMouseLeave, ref: this.buttonWrapperRef },
                    _react2.default.createElement(
                        _BaseButton2.default,
                        Object.assign({
                            className: buttonClassName,
                            onClick: this.onPinButtonClick
                        }, buttonAttributes),
                        isPinned ? pinnedButtonIcon : unpinnedButtonIcon
                    )
                )
            );
        }
    }, {
        key: 'renderMainContent',
        value: function renderMainContent() {
            var _props2 = this.props,
                isPinned = _props2.isPinned,
                children = _props2.children,
                collapsedSize = _props2.collapsedSize;
            var isOpen = this.state.isOpen;

            var handleMouseMove = isOpen && !isPinned;
            var mainContentStyle = {
                marginLeft: isPinned ? 'unset' : collapsedSize,
                width: isPinned ? 'unset' : 'calc(100% - ' + collapsedSize + ')'
            };
            return _react2.default.createElement(
                'div',
                {
                    className: styles['main-content'],
                    style: mainContentStyle,
                    onMouseMove: handleMouseMove ? this.onMouseMove : undefined
                },
                children
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var _props3 = this.props,
                containerClassName = _props3.containerClassName,
                isPinned = _props3.isPinned;

            var calculatedContainerClassName = classNameBuilder('container', containerClassName, { isPinned: isPinned });

            return _react2.default.createElement(
                'div',
                {
                    'aria-label': 'CollapsibleSideBarContainer',
                    className: calculatedContainerClassName
                },
                this.renderMainContent(),
                this.renderCollapsibleContent()
            );
        }
    }]);

    return CollapsibleSideBar;
}(_react.PureComponent);

var _initialiseProps = function _initialiseProps() {
    var _this2 = this;

    this.onPinButtonClick = function () {
        var _props4 = _this2.props,
            onPinClicked = _props4.onPinClicked,
            isPinned = _props4.isPinned;


        _this2.setState({ isOpen: !isPinned });

        onPinClicked(!isPinned);
    };

    this.onMouseEnter = function (e) {
        var isPinned = _this2.props.isPinned;


        if (isPinned) {
            return;
        }

        if (!_this2.buttonWrapperRef.current.contains(e.target)) {
            _this2.setState({ isOpen: true });
        }
    };

    this.onMouseLeave = function (e) {
        var isPinned = _this2.props.isPinned;


        if (isPinned) {
            return;
        }

        var isOpen = _this2.buttonWrapperRef.current.contains(e.target) && e.relatedTarget.nodeName && _this2.sideBarRef.current.contains(e.relatedTarget);

        _this2.setState({ isOpen: isOpen });
    };

    this.onMouseMove = function () {
        _this2.setState({ isOpen: false });
    };

    this.isVisible = function (props, state) {
        return props.isPinned || state.isOpen;
    };
};

CollapsibleSideBar.propTypes = {
    /** the main page content's */
    children: _propTypes2.default.node.isRequired,
    /** the collapsible sidebar's content */
    content: _propTypes2.default.node.isRequired,
    /** size of sidebar (can be px, rem, % and so on) */
    expandedContentSize: _propTypes2.default.string,
    /** touch zone (can be px, rem, % and so on) to open side-bar when it is closed */
    collapsedSize: _propTypes2.default.string,
    /** max size of sidebar (can be px, rem, % and so on) */
    expandedContentMaxSize: _propTypes2.default.string,
    /** min size of sidebar (can be px, rem, % and so on) */
    expandedContentMinSize: _propTypes2.default.string,
    /** icon for pinned button */
    pinnedButtonIcon: _propTypes2.default.node,
    /** class name for the pinned button */
    pinnedButtonClassName: _propTypes2.default.string,
    /** icon for unpinned button */
    unpinnedButtonIcon: _propTypes2.default.node,
    /** class name for the unpinned button */
    unpinnedButtonClassName: _propTypes2.default.string,
    /** class name for the button wrapper button */
    buttonWrapperClassName: _propTypes2.default.string,
    /** class name for the container */
    containerClassName: _propTypes2.default.string,
    /** is collapsible layout pinned */
    isPinned: _propTypes2.default.bool,
    /** on pin clicked callback */
    onPinClicked: _propTypes2.default.func,
    /** classname from the sideBar */
    sideBarClassName: _propTypes2.default.string,
    /** Button attributes */
    buttonAttributes: _propTypes2.default.any,
    /** Visibility Change */
    onVisibilityChange: _propTypes2.default.func
};

CollapsibleSideBar.defaultProps = {
    expandedContentSize: '20%',
    collapsedSize: '30px',
    expandedContentMaxSize: 'unset',
    expandedContentMinSize: 'unset',
    isPinned: false,
    pinnedButtonIcon: _react2.default.createElement(_Icons.LeftIcon, null),
    pinnedButtonClassName: '',
    unpinnedButtonIcon: _react2.default.createElement(_Icons.RightIcon, null),
    unpinnedButtonClassName: '',
    buttonWrapperClassName: '',
    sideBarClassName: '',
    containerClassName: '',
    onPinClicked: function onPinClicked() {},
    buttonAttributes: {},
    onVisibilityChange: function onVisibilityChange() {}
};

exports.default = CollapsibleSideBar;