import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { addMeasuredByMoatToMetric } from '../../../utils/addMeasuredByMoat';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-dma-performance',
    metrics: [
        metrics.SPENT,
        metrics.COMPLETED_VIEWS,
        addMeasuredByMoatToMetric(metrics.IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.CPM),
        addMeasuredByMoatToMetric(metrics.VCPM),
        metrics.CPCV,
        addMeasuredByMoatToMetric(metrics.VIEWABILITY_RATE),
        addMeasuredByMoatToMetric(metrics.HUNDRED_PCT_VIEWABILITY_RATE),
        metrics.COMPLETION_RATE,
        metrics.CLICKS,
        metrics.CTR,
        metrics.VCTR,
        metrics.CPA,
        metrics.CVR,
        metrics.CONVERSIONS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKeyWithIntl:
            intl =>
            ({ [FIELDS.DMA_CODE.field]: dmaCode, [FIELDS.DMA_NAME.field]: dmaName }) =>
                intl.formatMessage({
                    id: `${FIELDS.DMA_CODE.renderParams.msgIdPrefix}.${dmaCode}`,
                    defaultMessage: dmaName,
                }),
    },
};

export default graph;
