import React from 'react';
import { stubTrue } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { ContentTitle } from 'modules/campaigns/components/ContentEditableText';
import { creativeTitleValidations } from 'modules/campaigns/config/validations/creative';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './contentField.module.scss';

const trueValidations = [{ validationFn: stubTrue }];

export const ContentTitleField = ({
    selected,
    onSelect,
    label,
    checkboxLabel,
    disabledPlaceholderId,
    validationDependencies,
    ...rest
}) => {
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.editor.content.title.placeholder',
        defaultMessage: 'Enter Title',
    });

    const disabled = selected === false;

    return (
        <FormField
            label={label}
            checkboxLabel={checkboxLabel}
            containerClass={styles['container']}
            selected={selected}
            onSelect={onSelect}
        >
            <ContentTitle
                validations={disabled ? trueValidations : creativeTitleValidations}
                validationDependencies={validationDependencies}
                placeholder={placeholder}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'Title',
                }}
                disabled={disabled}
                disabledPlaceholderId={disabledPlaceholderId}
                {...rest}
            />
        </FormField>
    );
};

ContentTitleField.protoTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    label: PropTypes.node,
    checkboxLabel: PropTypes.node,
    disabledPlaceholderId: PropTypes.string,
};
