import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { HOUR_OF_DAY } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const hourOfDayVideoReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.HOUR_OF_DAY_VIDEO],
    endpoint: 'campaign-report-by-hour-of-day-performance',
    graph,
    rowIdField: HOUR_OF_DAY.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    filters: [
        { id: FILTER_TYPE.COUNTRY },
        { id: FILTER_TYPE.PLATFORM },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default hourOfDayVideoReportConfig;
