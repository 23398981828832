import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { EllipsisCellRenderer } from '../../../components/CellRenderers';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import {
    gridNumberFormatter,
    formattedNumberValueGetter,
    csvDateHeaderValueGetter,
} from '../../../components/ValueFormatters';
import createCSVValueGetter from '../../../components/ValueFormatters/createCSVValueGetter';
import createGridValueFormatter from '../../../components/ValueFormatters/createGridValueFormatter';
import dateWithLastUpdateTimeFormatter from '../../../components/ValueFormatters/dateWithLastUpdateTimeFormatter';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, keyColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const dayReportColumnDefinition = [
    {
        headerName: FIELDS.DATE.label,
        headerValueGetter: FIELDS.DATE.headerValueGetter,
        headerComponentParams: FIELDS.DATE.headerComponentParams,
        field: FIELDS.DATE.field,
        type: [leftPinnedColumn, keyColumn],
        valueFormatter: createGridValueFormatter(dateWithLastUpdateTimeFormatter),
        maxWidth: 180,
        csvValueGetter: createCSVValueGetter(dateWithLastUpdateTimeFormatter),
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        csvHeaderValueGetter: csvDateHeaderValueGetter,
    },
    ...[
        FIELDS.IMPRESSIONS,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.VCPM,
        FIELDS.CPM,
        FIELDS.VCTR,
        FIELDS.CTR,
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
        FIELDS.TARGET_CPA,
    ].map(
        ({
            label,
            headerValueGetter,
            headerComponentParams,
            field,
            renderParams,
            csvValueGetter,
            referenceColId,
            permissions,
            visibilityCondition,
        }) => ({
            headerName: label,
            headerValueGetter: headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams,
            field,
            referenceColId,
            type: [numericColumn],
            valueFormatter: gridNumberFormatter,
            cellRendererParams: renderParams,
            csvValueGetter: csvValueGetter || formattedNumberValueGetter,
            permissions,
            visibilityCondition,
        })
    ),
];

export default normalizeColumnDefs(dayReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.DAY].id);
