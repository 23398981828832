'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.omit');

var _lodash2 = _interopRequireDefault(_lodash);

var _DataGrid = require('../DataGrid');

var _TaboolaCustomHeader = require('../TaboolaCustomHeader');

var _TaboolaCustomHeader2 = _interopRequireDefault(_TaboolaCustomHeader);

var _DataGridBase = require('../DataGridBase');

var _EmptyStateOverlay = require('../EmptyStateOverlay');

var _EmptyStateOverlay2 = _interopRequireDefault(_EmptyStateOverlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'taboolaDataGrid__taboola-svg-icon___2QbQv',
    'grid-container': 'taboolaDataGrid__grid-container___TXkp2',
    'ag-ltr': 'taboolaDataGrid__ag-ltr___34qRK',
    'ag-rtl': 'taboolaDataGrid__ag-rtl___4frl5',
    'with-footer': 'taboolaDataGrid__with-footer___39Ulz',
    'taboola-grid': 'taboolaDataGrid__taboola-grid___uP5tw',
    'sticky': 'taboolaDataGrid__sticky___3rKzh',
    'disable-selected-rows': 'taboolaDataGrid__disable-selected-rows___3VKiX',
    'footer': 'taboolaDataGrid__footer___2nELq',
    'empty-footer': 'taboolaDataGrid__empty-footer___1-gyK',
    'rows-loading': 'taboolaDataGrid__rows-loading___2WAb9',
    'header-loading': 'taboolaDataGrid__header-loading___2Tge7',
    'pagination': 'taboolaDataGrid__pagination___3o1O0',
    'hidden': 'taboolaDataGrid__hidden___2mWyp',
    'ag-shake-left-to-right': 'taboolaDataGrid__ag-shake-left-to-right___nZ9NN'
};
var exportedStyles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'rowHeight': '38px',
    'headerHeight': '54px',
    'footerHeight': '45px',
    'taboola-svg-icon': '_export__taboola-svg-icon___3dU8T'
};


var classNameBuilder = _bind2.default.bind(styles);

var TaboolaDataGrid = function TaboolaDataGrid(_ref) {
    var className = _ref.className,
        emptyStateData = _ref.emptyStateData,
        reportData = _ref.reportData,
        footerClass = _ref.footerClass,
        gridContainerClass = _ref.gridContainerClass,
        stickyFooter = _ref.stickyFooter,
        stickyHeader = _ref.stickyHeader,
        withFooter = _ref.withFooter,
        isEmptyFooter = _ref.isEmptyFooter,
        frameworkComponents = _ref.frameworkComponents,
        headerLoading = _ref.headerLoading,
        rowsLoading = _ref.rowsLoading,
        animateRows = _ref.animateRows,
        shouldDisableSelectedRows = _ref.shouldDisableSelectedRows,
        customHeaderComponent = _ref.customHeaderComponent,
        rest = _objectWithoutProperties(_ref, ['className', 'emptyStateData', 'reportData', 'footerClass', 'gridContainerClass', 'stickyFooter', 'stickyHeader', 'withFooter', 'isEmptyFooter', 'frameworkComponents', 'headerLoading', 'rowsLoading', 'animateRows', 'shouldDisableSelectedRows', 'customHeaderComponent']);

    var mergedFrameworkComponents = (0, _react.useMemo)(function () {
        return Object.assign({
            agColumnHeader: customHeaderComponent
        }, frameworkComponents);
    }, [frameworkComponents]);
    var gridContainerClassName = classNameBuilder(gridContainerClass, 'grid-container', {
        'with-footer': withFooter,
        'header-loading': headerLoading,
        'rows-loading': rowsLoading
    });
    var gridClassName = classNameBuilder(className, 'taboola-grid', {
        sticky: stickyHeader,
        'disable-selected-rows': shouldDisableSelectedRows
    });
    var footerClassName = classNameBuilder(footerClass, 'footer', {
        sticky: stickyFooter,
        'empty-footer': isEmptyFooter
    });
    var paginationClassName = classNameBuilder('pagination', {
        hidden: !(reportData && reportData.length > 0)
    });

    return _react2.default.createElement(_DataGrid.DataGrid, Object.assign({}, rest, {
        className: gridClassName,
        headerLoading: headerLoading,
        rowsLoading: rowsLoading,
        animateRows: animateRows,
        reportData: reportData,
        paginationClassName: paginationClassName,
        noRowsOverlayComponentParams: emptyStateData,
        gridContainerClass: gridContainerClassName,
        withFooter: withFooter,
        isEmptyFooter: isEmptyFooter,
        footerClass: footerClassName,
        frameworkComponents: mergedFrameworkComponents
    }));
};

TaboolaDataGrid.propTypes = Object.assign({}, (0, _lodash2.default)(_DataGrid.DataGrid.propTypes, 'gridHeight'), {
    /** Flag to disable rows selected in the grid */
    shouldDisableSelectedRows: _propTypes2.default.bool,
    /** Determines whether the footer will be sticky to the bottom of the view port or not */
    stickyFooter: _propTypes2.default.bool,
    /** Determines whether the header row will be sticky to the top of the view port or not */
    stickyHeader: _propTypes2.default.bool,
    /** Determines default sorting order behavior on user actions with column in header */
    sortingOrder: _propTypes2.default.arrayOf(_propTypes2.default.oneOf([_DataGridBase.SORTING_ORDER_TYPES.DESCENDANT, _DataGridBase.SORTING_ORDER_TYPES.ASCENDANT, _DataGridBase.SORTING_ORDER_TYPES.NON_SORTED])),
    /** Custom component to customize the header renderers */
    customHeaderComponent: _propTypes2.default.func
});

TaboolaDataGrid.defaultProps = Object.assign({}, (0, _lodash2.default)(_DataGrid.DataGrid.defaultProps, 'gridHeight'), {
    gridContainerClass: '',
    footerClass: '',
    rowHeight: exportedStyles.rowHeight.replace('px', ''),
    headerHeight: parseFloat(exportedStyles.headerHeight),
    footerHeight: parseFloat(exportedStyles.footerHeight),
    theme: 'ag-theme-material taboola-theme',
    rowSelection: 'multiple',
    withPagination: true,
    suppressClickEdit: true,
    stickyFooter: false,
    stickyHeader: false,
    shouldDisableSelectedRows: false,
    noRowsOverlayComponentFramework: _EmptyStateOverlay2.default,
    sortingOrder: [_DataGridBase.SORTING_ORDER_TYPES.DESCENDANT, _DataGridBase.SORTING_ORDER_TYPES.ASCENDANT, _DataGridBase.SORTING_ORDER_TYPES.NON_SORTED],
    customHeaderComponent: _TaboolaCustomHeader2.default
});

exports.default = TaboolaDataGrid;