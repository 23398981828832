import React, { useMemo, useState } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from '../../../taboola-common-frontend-modules/validations';
import { ExcludedTopicsList } from './TopicsListFieldComponents/ExcludedTopicsList';
import { IncludedTopicsList } from './TopicsListFieldComponents/IncludedTopicsList';
import { ListLoadingIndicator } from './TopicsListFieldComponents/ListLoadingIndicator';
import { TopicsListFieldDropdown } from './TopicsListFieldComponents/TopicsListFieldDropdown';
import { TopicsLanguageFieldTooltip } from './TopicsListFieldTooltip';
import { useTopicsListField } from './hooks/useTopicsListField';

export const TopicsListField = () => {
    const { [COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER]: maxAllowedTopics } = useCommonConfig([
        COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER,
    ]);

    const validations = [
        {
            validationFn: validationFunctions.arrayLengthRange,
            options: { min: 1 },
            messageId: 'custom.contextual.editor.topics.list.error',
            defaultMessage: 'Choose at least one topic to include',
        },
        {
            validationFn: value => value && value[0] && value[0].length <= maxAllowedTopics,
            options: { maxAllowedTopics },
            messageId: 'custom.contextual.editor.topics.list.max.number.error',
            defaultMessage: 'Maximum allowed number of topics to choose is {maxAllowedTopics}',
        },
    ];

    const { value: includeTerms } = useFormFieldValue({
        field: 'terms.include',
    });

    const validationDependencies = useMemo(() => {
        return { includeTerms };
    }, [includeTerms]);

    const [newSelection, setNewSelection] = useState([]);

    const { include, exclude, setIncludedTopics, setExcludedTopics, isLoading } = useTopicsListField(newSelection);

    const { indicationData, scrollRef } = useFormValidatedValue({
        field: 'terms.include',
        validations,
        validationDependencies,
    });

    return (
        <FormField
            dataTestId="topics-list-field"
            inputId="custom-contextual-topics-list"
            label={<FormattedMessage id="custom.contextual.editor.topics.name.title" defaultMessage="Topics" />}
            description={
                <FormattedMessage
                    id="custom.contextual.editor.topics.name.description"
                    defaultMessage="Choose the article topics you want your ads to appear in."
                />
            }
            ref={scrollRef}
            helpText={<TopicsLanguageFieldTooltip />}
        >
            <TopicsListFieldDropdown
                indicationData={indicationData}
                newSelection={newSelection}
                setNewSelection={setNewSelection}
            />
            {isLoading ? (
                <ListLoadingIndicator />
            ) : (
                <>
                    <IncludedTopicsList terms={include} setIncludedTopics={setIncludedTopics} />
                    <ExcludedTopicsList terms={exclude} setExcludedTopics={setExcludedTopics} />
                </>
            )}
        </FormField>
    );
};
export default TopicsListField;
