import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { REPORT_ID } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { REPORT_TYPE } from '../../campaigns/config';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { AUDIENCES_ROUTE, ENTITY } from '../config';
import { fetchAudienceForEdit } from '../flows';
import updateAudience from '../flows/updateAudience';

// TODO DEV-90389 clearing resources
const useAudienceEditorState = ({ reportType, entity }) => {
    const dispatch = useDispatch();
    const { audienceId } = useMultiLayerPathParams();

    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const formDataFetcher = useCallback(
        () => dispatch(fetchAudienceForEdit(accountId, audienceId, reportType, entity)),
        [accountId, audienceId, reportType, entity, dispatch]
    );

    const { submit } = useFormState({ formDataFetcher });

    const handleAudienceSubmitWrapper = useCallback(() => {
        const saveAudienceWrapper = audience =>
            dispatch(
                updateAudience({
                    accountName: accountId,
                    audience,
                    reportType,
                })
            );
        submit(saveAudienceWrapper, getSuccessUrl({ entity, reportType }));
    }, [submit, entity, reportType, dispatch, accountId]);

    return { submit: handleAudienceSubmitWrapper };
};

const getSuccessUrl = ({ entity, reportType }) => {
    if (entity === ENTITY.MAIL_DOMAINS || entity === ENTITY.SEARCH_KEYWORDS) {
        return `${AUDIENCES_ROUTE}?${REPORT_TYPE.FIRST_PARTY}=${reportType}`;
    }
    return `${AUDIENCES_ROUTE}?${REPORT_ID}=${reportType}`;
};

export default useAudienceEditorState;
