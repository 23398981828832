import { isAdminNetworkAccountType, isProgrammaticAccount } from '../../../../../../account-management/accountType';
import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { customColumns, defaultColumns, performanceColumns } from './reportPresets';

export const accountReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.ACCOUNT],
    endpoint: 'campaign-report-by-account-performance',
    rowIdField: FIELDS.ACCOUNT_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.byAccount.search.placeholder',
    searchField: 'content_provider_name',
    sortMap: {
        [FIELDS.ACCOUNT_DESCRIPTION.field]: 'content_provider',
        [FIELDS.ACCOUNT_ID.field]: 'content_provider_id',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
    },
    filters: [
        {
            id: FILTER_TYPE.COUNTRY,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.SITE,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.OS_FAMILY,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.OS_VERSION,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.BROWSER,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.TARGET_PAGE_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.IAB_CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.LANGUAGE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.DEMAND_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            visibilityCondition: ({ selectedAccount }) => isProgrammaticAccount(selectedAccount),
        },
    ],
};

export default accountReportConfig;
