import { get, isEmpty, keyBy, isNil, isNumber } from 'lodash';
import moment from 'moment-timezone';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { modifyExternalUrl } from '../../../../services/utils';
import { BID_STRATEGIES } from '../../../common-campaign-form';
import createCSVValueGetter from './createCSVValueGetter';
import createGridValueFormatter from './createGridValueFormatter';

export const VALUE_TYPES = {
    CURRENCY: 'currency',
    SITE_BID_PERCENT: 'percent',
    STRING: 'string',
    BOOLEAN: 'boolean',
    TRANSLATABLE_RESOURCE: 'translatable-resource',
    IMAGE: 'IMAGE',
    MOTION: 'MOTION',
    CELL_WITH_TOOLTIP: 'cell-with-tooltip',
    DATE_WITH_CUSTOMIZATION: 'date-with-customization',
    CURRENCY_WITH_TRANSLATABLE_DETAILS: 'currency-with-translatable-details',
    CUSTOM_BUDGET: 'budget-custom',
    SPENDING_LIMIT: 'spending-limit',
};

export const activityCodeToValueTypeMap = {
    cpc: VALUE_TYPES.CURRENCY,
    campaignSiteResetBid: VALUE_TYPES.SITE_BID_PERCENT,
    campaignSiteBidBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignSiteBidDeBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignPlatformBidBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignPlatformBidDeBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignSitePlatformBidBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignSitePlatformBidDeBoost: VALUE_TYPES.SITE_BID_PERCENT,
    campaignSiteBlocked: VALUE_TYPES.STRING,
    campaignSiteUnBlocked: VALUE_TYPES.STRING,
    campaignIsPaused: VALUE_TYPES.BOOLEAN,
    ctaTextNew: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    adCtaText: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    adInstructionStatus: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    adThumbnailUrl: VALUE_TYPES.IMAGE,
    adVideoUrl: VALUE_TYPES.MOTION,
    videoUrlNew: VALUE_TYPES.MOTION,
    adDescription: VALUE_TYPES.CELL_WITH_TOOLTIP,
    cpcOptimizationType: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    subTrafficAllocationMode: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    campaignName: VALUE_TYPES.STRING,
    campaignEndDate: VALUE_TYPES.DATE_WITH_CUSTOMIZATION,
    campaignStartDate: VALUE_TYPES.DATE_WITH_CUSTOMIZATION,
    targetCpaBid: VALUE_TYPES.CURRENCY_WITH_TRANSLATABLE_DETAILS,
    budgetAmount: VALUE_TYPES.CURRENCY,
    budgetType: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    spendingAmount: VALUE_TYPES.CURRENCY,
    spendingType: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    conversionGoal: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    bidStrategy: VALUE_TYPES.TRANSLATABLE_RESOURCE,
    accountSpendingLimit: VALUE_TYPES.SPENDING_LIMIT,
    networkSpendingLimit: VALUE_TYPES.SPENDING_LIMIT,
    accountPrepaymentDelta: VALUE_TYPES.CURRENCY,
    accountManualFreeze: VALUE_TYPES.BOOLEAN,
    maxConversionsCpcCap: VALUE_TYPES.CURRENCY_WITH_TRANSLATABLE_DETAILS,
};

const ACTIVITY_CODE = {
    NEW_CAMPAIGN: 'newCampaign',
    DUPLICATE_CAMPAIGN: 'duplicateCampaign',
    BUDGET_TYPE: 'budgetType',
    BUDGET_AMOUNT: 'budgetAmount',
    CPC: 'cpc',
};

const CAMPAIGN_NO_END_DATE = Date.parse('9999-12-31');
const CURRENCY_NONE_VALUE = 'NONE';
const CAMPAIGN_HISTORY_DETAILS_CODE_TRANSLATABLE_PREFIX =
    'app.campaigns.reports.grid.campaign_history.column.value.details.';
const CPC_SUB_TYPE_FIELD_NAME = 'cpc_optimization_sub_type';
const NON_BID_CPC_TYPE_NAMES = [BID_STRATEGIES.MAX_CONVERSIONS, BID_STRATEGIES.TARGET_CPA];

const translatablePrefixToActivityCodeMap = {
    ctaTextNew: 'creative.creator.content.cta.option',
    adCtaText: 'creative.creator.content.cta.option',
    adInstructionStatus: 'app.campaigns.reports.grid.campaign_history.column.value.adStatus',
    cpcOptimizationType: 'app.campaigns.reports.grid.campaign_history.column.value.cpcOptimizationType',
    subTrafficAllocationMode: 'app.campaigns.reports.grid.campaign_history.column.value.subTrafficAllocationMode',
    bidStrategy: 'app.campaigns.reports.grid.campaign_history.column.value.bidStrategy',
    campaignEndDate: 'campaign.time.frame.no.end.date',
    budgetType: 'app.campaigns.reports.grid.campaign_history.column.value.budgetType',
    spendingType: 'app.campaigns.reports.grid.campaign_history.column.value.spendingType',
    budgetAmount: 'app.campaigns.reports.grid.campaign_history.column.value.spendingType',
    spendingAmount: 'app.campaigns.reports.grid.campaign_history.column.value.spendingType',
    conversionGoal: 'app.campaigns.reports.grid.campaign_history.column.value.conversionGoal',
    accountSpendingLimit: 'app.campaigns.reports.grid.campaign_history.column.value.accountSpendingLimit',
    cpc: 'app.campaigns.reports.grid.campaign_history.column.value.cpc',
};

const getCustomValueType = (customFromDetails, activityCode) =>
    customFromDetails &&
    (activityCode === ACTIVITY_CODE.NEW_CAMPAIGN || activityCode === ACTIVITY_CODE.DUPLICATE_CAMPAIGN)
        ? VALUE_TYPES.CUSTOM_BUDGET
        : null;

const resolveValue = valueData => {
    const { value, valueType, valueDetailsParams, activityCode } = valueData;
    switch (valueType) {
        case VALUE_TYPES.CURRENCY:
        case VALUE_TYPES.CURRENCY_WITH_TRANSLATABLE_DETAILS:
            return resolveCurrency(value);
        case VALUE_TYPES.SITE_BID_PERCENT:
            return handleBidValue(value, activityCode);
        case VALUE_TYPES.CUSTOM_BUDGET:
            return valueDetailsParams;
        case VALUE_TYPES.BOOLEAN:
        case VALUE_TYPES.MOTION:
        case VALUE_TYPES.IMAGE:
        case VALUE_TYPES.TRANSLATABLE_RESOURCE:
        case VALUE_TYPES.CELL_WITH_TOOLTIP:
        case VALUE_TYPES.STRING:
        case VALUE_TYPES.DATE_WITH_CUSTOMIZATION:
        case VALUE_TYPES.SPENDING_LIMIT:
        default:
            return value;
    }
};

const resolveCurrency = value => {
    if (value === 'null') {
        return Number(0);
    }

    if (value === CURRENCY_NONE_VALUE) {
        return CURRENCY_NONE_VALUE;
    }

    if (isNil(value)) {
        return value;
    }

    return Number(value);
};

const campaignHistoryValueFormatter = ({
    value,
    rowData,
    i18n,
    currency,
    timeZoneName,
    formatOptions: { customFromDetails } = {},
}) => {
    const activityCode = get(rowData, ['activityDetails', 'activityCode']);
    const valueDetailsParams = get(rowData, ['activityDetails', 'activityDetailsParameters']);
    const valueType =
        getCustomValueType(customFromDetails, activityCode) ||
        activityCodeToValueTypeMap[activityCode] ||
        VALUE_TYPES.STRING;
    const valueData = { value, valueType, valueDetailsParams, activityCode };
    const resolvedValue = resolveValue(valueData);

    let format;
    switch (valueType) {
        case VALUE_TYPES.CURRENCY:
        case VALUE_TYPES.CURRENCY_WITH_TRANSLATABLE_DETAILS:
            if (value === CURRENCY_NONE_VALUE) {
                return i18n.formatMessage({
                    id: `${translatablePrefixToActivityCodeMap[activityCode]}.${resolvedValue}`,
                    defaultMessage: resolvedValue || EMPTY_FIELD_VALUE,
                });
            }

            if (isNil(resolvedValue)) {
                return i18n.formatMessage({
                    id: `${translatablePrefixToActivityCodeMap[activityCode]}.empty`,
                    defaultMessage: EMPTY_FIELD_VALUE,
                });
            }

            format = currency ? { style: VALUE_TYPES.CURRENCY, currency, maximumFractionDigits: 4 } : {};
            break;
        case VALUE_TYPES.BOOLEAN:
            return i18n.formatMessage({
                id: `app.campaigns.reports.grid.campaign_history.column.value.${activityCode}.${resolvedValue}`,
                defaultMessage: resolvedValue || EMPTY_FIELD_VALUE,
            });
        case VALUE_TYPES.MOTION:
            return modifyExternalUrl(resolvedValue);
        case VALUE_TYPES.IMAGE:
            return modifyExternalUrl(resolvedValue);
        case VALUE_TYPES.TRANSLATABLE_RESOURCE:
            return i18n.formatMessage({
                id: `${translatablePrefixToActivityCodeMap[activityCode]}.${resolvedValue}`,
                defaultMessage: resolvedValue || EMPTY_FIELD_VALUE,
            });
        case VALUE_TYPES.CELL_WITH_TOOLTIP:
            return resolvedValue;
        case VALUE_TYPES.DATE_WITH_CUSTOMIZATION:
            return handleCustomizedDate(resolvedValue, activityCode, i18n, timeZoneName);
        case VALUE_TYPES.CUSTOM_BUDGET:
            return handleCustomBudget(resolvedValue, activityCode, i18n, currency);
        case VALUE_TYPES.SPENDING_LIMIT:
            return handleSpendingLimit(resolvedValue, activityCode, i18n, currency);
        case VALUE_TYPES.STRING:
        case VALUE_TYPES.SITE_BID_PERCENT:
        default:
            format = { style: VALUE_TYPES.SITE_BID_PERCENT };
    }
    return valueType === VALUE_TYPES.STRING ? resolvedValue : i18n.formatNumber(resolvedValue, format);
};
const campaignHistoryChangeTimeGetter = ({ rowData, i18n }) => {
    const createTimeUtc = get(rowData, ['createTimeUtc']);
    const momentUtc = moment.utc(createTimeUtc);
    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return i18n.formatDate(momentUtc, options);
};

const campaignHistoryChangeTimeGetterNoTzShift = ({ rowData, i18n }) => {
    const createTime = get(rowData, ['createTime']);
    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return i18n.formatDate(createTime, options);
};
const handleCustomizedDate = (value, activityCode, i18n, timeZoneName) => {
    const date = Date.parse(value);
    if (moment(date).isAfter(moment(CAMPAIGN_NO_END_DATE))) {
        return i18n.formatMessage({
            id: `${translatablePrefixToActivityCodeMap[activityCode]}`,
            defaultMessage: value,
        });
    }
    const m = moment.tz(value, timeZoneName);
    const options = {
        timeZone: m.tz(),
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    };
    return i18n.formatDate(value, options);
};

const handleCustomBudget = (value, activityCode, i18n, currency) => {
    if (isEmpty(value)) {
        return null;
    }

    const valueMap = keyBy(value, 'name');

    let budgetValue;
    let bidValue;
    if (valueMap[ACTIVITY_CODE.BUDGET_TYPE] && valueMap[ACTIVITY_CODE.BUDGET_AMOUNT]) {
        const budgetTypeTranslated = i18n.formatMessage({
            id: `${translatablePrefixToActivityCodeMap[ACTIVITY_CODE.BUDGET_TYPE]}.${
                valueMap[ACTIVITY_CODE.BUDGET_TYPE].value
            }`,
        });
        const budgetTypeCodeTranslated = i18n.formatMessage({
            id: `${CAMPAIGN_HISTORY_DETAILS_CODE_TRANSLATABLE_PREFIX}${ACTIVITY_CODE.BUDGET_TYPE}`,
        });
        const budgetAmountFormatted = i18n.formatNumber(
            valueMap[ACTIVITY_CODE.BUDGET_AMOUNT].value,
            currency ? { style: VALUE_TYPES.CURRENCY, currency, maximumFractionDigits: 0 } : {}
        );
        budgetValue = `${budgetTypeTranslated} ${budgetTypeCodeTranslated}: ${budgetAmountFormatted}`;
    }

    if (valueMap[ACTIVITY_CODE.CPC] && !NON_BID_CPC_TYPE_NAMES.includes(valueMap[CPC_SUB_TYPE_FIELD_NAME]?.value)) {
        const cpcCodeTranslated = i18n.formatMessage({
            id: `${CAMPAIGN_HISTORY_DETAILS_CODE_TRANSLATABLE_PREFIX}${ACTIVITY_CODE.CPC}`,
        });
        const cpcValueFormatted = i18n.formatNumber(
            valueMap[ACTIVITY_CODE.CPC].value,
            currency ? { style: VALUE_TYPES.CURRENCY, currency, maximumFractionDigits: 4 } : {}
        );
        bidValue = `${cpcCodeTranslated}: ${cpcValueFormatted}`;
    }

    return budgetValue && bidValue ? `${budgetValue}; ${bidValue}` : budgetValue ? `${budgetValue}` : null;
};

const handleSpendingLimit = (value, activityCode, i18n, currency) => {
    if (isEmpty(value)) {
        return null;
    }
    if (value.includes(' ')) {
        const spendingLimitArr = value.split(' ');
        const spendingLimitTypeRaw = spendingLimitArr[0];
        const spendingLimitRaw = spendingLimitArr[1];
        const spendingLimitTypeTranslated = i18n.formatMessage({
            id: `${translatablePrefixToActivityCodeMap[activityCode]}.${spendingLimitTypeRaw}`,
            defaultMessage: spendingLimitTypeRaw || EMPTY_FIELD_VALUE,
        });
        const spendingLimitFormatted = formatCurrencyValue(spendingLimitRaw, i18n, currency);
        return `${spendingLimitTypeTranslated} ${spendingLimitFormatted}`;
    } else if (isNumber(value) || isStringNumeric(value)) {
        return formatCurrencyValue(value, i18n, currency);
    } else {
        return i18n.formatMessage({
            id: `${translatablePrefixToActivityCodeMap[activityCode]}.${value}`,
            defaultMessage: value || EMPTY_FIELD_VALUE,
        });
    }
};

const formatCurrencyValue = (value, i18n, currency) => {
    return i18n.formatNumber(
        value,
        currency ? { style: VALUE_TYPES.CURRENCY, currency, maximumFractionDigits: 0 } : {}
    );
};

const isStringNumeric = str => {
    if (typeof str !== 'string') {
        return false;
    }
    return !isNaN(str) && !isNaN(parseFloat(str));
};

const handleBidValue = (value, activityCode) => {
    if (value) {
        if (value.endsWith('.00')) {
            if (activityCode === 'campaignSiteBidDeBoost') {
                const numericValue = Number(value / 100);
                return numericValue > 0 ? numericValue * -1 : numericValue;
            }
            return Number(value / 100);
        }
        return Number(value - 1);
    }
    return null;
};

export const campaignHistoryActivityGetter = ({ rowData, i18n }) => {
    const activityCode = get(rowData, ['activityDetails', 'activityCode']);
    return i18n.formatMessage({
        id: `app.campaigns.reports.grid.campaign_history.activity_code.${activityCode || EMPTY_FIELD_VALUE}`,
    });
};
export const campaignHistoryActivityDetailsGetter = ({ rowData, i18n }) => {
    const activityCode = get(rowData, ['activityDetails', 'activityCode']);
    const activityDetailsCodeDescription = get(rowData, ['activityDetails', 'activityDetailsCodeDescription']);

    if (
        activityCodeToValueTypeMap[activityCode] === VALUE_TYPES.CURRENCY_WITH_TRANSLATABLE_DETAILS ||
        activityCodeToValueTypeMap[activityCode] === VALUE_TYPES.TRANSLATABLE_RESOURCE
    ) {
        return i18n.formatMessage({
            id: `app.campaigns.reports.grid.campaign_history.activity_details.${activityCode}`,
            defaultMessage: activityDetailsCodeDescription,
        });
    }

    return activityDetailsCodeDescription;
};

const formatters = [
    campaignHistoryValueFormatter,
    campaignHistoryChangeTimeGetter,
    campaignHistoryActivityGetter,
    campaignHistoryActivityDetailsGetter,
    campaignHistoryChangeTimeGetterNoTzShift,
];
export const [
    gridCampaignHistoryValueFormatter,
    gridCampaignHistoryChangeTimeGetter,
    gridCampaignHistoryActivityGetter,
    gridCampaignHistoryActivityDetailsGetter,
    gridCampaignHistoryChangeTimeGetterNoTzShift,
] = formatters.map(createGridValueFormatter);
export const [
    formattedCampaignHistoryValueGetter,
    formattedCampaignHistoryChangeTimeGetter,
    formattedCampaignHistoryActivityGetter,
    formattedCampaignHistoryActivityDetailsGetter,
    formattedCampaignHistoryChangeTimeGetterNoTzShift,
] = formatters.map(createCSVValueGetter);
