import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { useEntityType } from 'hooks';
import {
    addIndication,
    INDICATION_TYPES,
    StaticIndications,
} from 'modules/taboola-common-frontend-modules/Indications';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { GoogleApiProvider, useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import config from 'modules/taboola-common-frontend-modules/googleApi/GoogleApiConfig';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TrackingFormBreadcrumbs } from 'modules/tracking/components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import { TrackingSetupButtons } from 'modules/tracking/components/TrackingSetupButtons';
import { ENTITY, PATH_TO_ENTITY_MAP } from 'modules/tracking/config';
import { useTrackingSetupFormState } from 'modules/tracking/hooks/useTrackingSetupFormState';
import { useTrackingApi } from 'services/api';
import { Configuration } from '../../components/GtmConfiguration';
import { TrackWithGTM } from '../../components/TrackWithGTM';
import { useGtmIndicator } from '../../hooks/useGtmIndicator';
import styles from './gtmSetupPage.module.scss';

const GTM_SCOPES = [
    'https://www.googleapis.com/auth/tagmanager.edit.containers',
    'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
    'https://www.googleapis.com/auth/tagmanager.publish',
];

const successIndication = {
    message: (
        <FormattedMessage
            id="tracking.setup.gtm.configuration.tag.created.success"
            defaultMessage="Taboola Pixel created successfully"
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateFailureIndication = message => ({
    message,
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="tracking.setup.gtm.configuration.tag.created.failure"
            defaultMessage="Taboola Pixel creation failed"
        />
    ),
});

const GtmSetupPage = ({ onCancel }) => {
    const entity = useEntityType(PATH_TO_ENTITY_MAP);
    const { createTag } = useTrackingApi();
    const { accountId } = useAccount();
    const { getToken } = useGoogleApi();
    const dispatch = useDispatch();
    const { value: gtmAccount } = useFormFieldValue({ field: 'gtmAccount' });
    const { value: gtmContainer } = useFormFieldValue({ field: 'gtmContainer' });
    const { value: gtmWorkspace } = useFormFieldValue({ field: 'gtmWorkspace' });
    const { isTaboolaPixelInstalled } = useGtmIndicator();

    const handleSubmit = useCallback(async () => {
        if (entity === ENTITY.GTM_SETUP) {
            try {
                await createTag(
                    getToken()?.access_token,
                    accountId,
                    gtmAccount?.value,
                    gtmContainer?.value,
                    gtmWorkspace?.value
                );
                dispatch(addIndication(successIndication));
                return true;
            } catch (error) {
                const messageArray = error.message.split('. ');
                const editedMessage = `${messageArray[0]}. ${messageArray[2]}`;
                dispatch(addIndication(generateFailureIndication(editedMessage)));
                throw error;
            }
        }
    }, [accountId, createTag, dispatch, entity, getToken, gtmAccount?.value, gtmContainer?.value, gtmWorkspace?.value]);

    const { step, handleSetStep, submit, wizardTemplateColumns, submitNavigateTracking } =
        useTrackingSetupFormState(handleSubmit);

    const gtmAutomationEnabled = useConfigMatch({
        [FEATURE_FLAGS.GTM_AUTOMATED_SETUP_ENABLED]: 'true',
    });

    const isSubmitEnabled = gtmAccount && gtmContainer && gtmWorkspace && !isTaboolaPixelInstalled;

    return (
        <GoogleApiProvider clientId={config.getGtmClientId()} scopes={GTM_SCOPES}>
            <FormInitBase>
                <Wizard
                    wizardContentClassName={styles['wizard-content']}
                    step={step}
                    onStepChange={handleSetStep}
                    wizardTemplateColumns={wizardTemplateColumns}
                >
                    <WizardStep
                        label={
                            <FormattedMessage
                                id="tracking.setup.gtm.first.step.label"
                                defaultMessage="Google Tag Manager"
                            />
                        }
                        id="google-tag-manager"
                    >
                        <StaticIndications />
                        <TrackingFormBreadcrumbs msgId="tracking.setup.gtm.label" defaultMessage="Google Tag Manager" />
                        <div className={styles['form-with-widget-wrapper']}>
                            <div className={styles['form-wrapper']}>
                                {gtmAutomationEnabled && (
                                    <WizardStep
                                        label={
                                            <FormattedMessage
                                                id="tracking.setup.gtm.configuration"
                                                defaultMessage="GTM Quick Integration"
                                            />
                                        }
                                        id="gtm-configuration"
                                    >
                                        <Configuration />
                                    </WizardStep>
                                )}
                                <WizardStep
                                    label={
                                        <FormattedMessage
                                            id="tracking.setup.gtm.track.label"
                                            defaultMessage="Track with Google Tag Manager"
                                        />
                                    }
                                    id="gtm-track"
                                >
                                    <TrackWithGTM />
                                </WizardStep>
                                <TrackingSetupButtons
                                    onSubmit={submitNavigateTracking}
                                    onSubmitAndCreateConversion={submit}
                                    onCancel={onCancel}
                                    disableSubmit={!isSubmitEnabled}
                                />
                            </div>
                        </div>
                    </WizardStep>
                </Wizard>
            </FormInitBase>
        </GoogleApiProvider>
    );
};

const GtmSetupPageWithFormDataProvider = withFormDataProvider(GtmSetupPage, {});

export { GtmSetupPageWithFormDataProvider as GtmSetupPage };
