import encodeurl from 'encodeurl';
import duplicateCreative from 'modules/campaigns/modules/creative-editor/flows/duplicateCreative';

export const duplicateCreativeHandler =
    (accountId, data, dispatch) =>
    async ({ selectedAccount, matchingCreativeIds }) => {
        const savedCreative = { ...data, url: encodeurl(data.url) };
        const { campaignId } = data;

        const result = await dispatch(
            duplicateCreative({ accountId, selectedAccount, campaignId }, savedCreative, matchingCreativeIds)
        );
        if (!result?.error) {
            return result;
        }
    };
