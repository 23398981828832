import React, { useMemo } from 'react';
import moment from 'moment';
import { FormattedRelative } from 'modules/taboola-common-frontend-modules/i18n';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';

const PER_MINUTE = 60;

export const LastReceivedCellRenderer = ({ value }) => {
    const lastEvent = useMemo(() => {
        return value ? moment().diff(value, 'second') : null;
    }, [value]);
    if (!value) {
        return EMPTY_FIELD_VALUE;
    }
    return <FormattedRelative value={-lastEvent} numeric="auto" updateIntervalInSeconds={PER_MINUTE} />;
};
