import { useEffect, useState, useRef } from 'react';
import { useScroll } from 'react-use';

const DEFAULT_REF = { current: null };

export const useBaseGalleryItemDropdownTooltip = ({ containerRef = DEFAULT_REF, gridRef = DEFAULT_REF } = {}) => {
    const [showingTooltip, setShowingTooltip] = useState(true);
    const prevGridScroll = useRef(null);
    const { y: gridScroll } = useScroll(gridRef);

    // whenever the grid scroll changes, trigger a re-render
    // and hide the tooltip if the content is offscreen
    useEffect(() => {
        if (!gridRef?.current || !containerRef?.current) {
            return;
        }

        let shouldHide = false;

        const gridScrollTop = gridRef.current?.scrollTop;
        const containerTop = containerRef.current?.offsetTop;
        const containerHeight = containerRef.current?.clientHeight;
        // if the grid's scrollTop is showing the bottom half of the element
        if (gridScrollTop > containerTop + containerHeight / 2) {
            shouldHide = true;
        }

        setShowingTooltip(!shouldHide);

        prevGridScroll.current = gridScroll;
    }, [containerRef, gridRef, gridScroll]);

    return {
        showingTooltip,
        setShowingTooltip,
    };
};
