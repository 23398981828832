import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { DelimiterWrapper, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE } from 'taboola-ultimate-ui';
import { StyledButton } from 'taboola-ultimate-ui';
import { FormNamespaceProvider } from '../../../taboola-common-frontend-modules/formData/FormNamespaceProvider';
import KeySection from '../KeySection/KeySection';
import ValueSection from '../ValueSection/ValueSection';
import styles from './listItem.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ListItem = ({ item, deleteItem, index, loading }) => {
    const handleOnClick = useCallback(() => {
        deleteItem(item);
    }, [item, deleteItem]);

    const { isRemovable, isEditable } = item;

    return (
        <FormNamespaceProvider field={index} itemKey={item.id}>
            <div className={styles['container']}>
                <DelimiterWrapper delimiterClassName={styles['delimiter']}>
                    <KeySection
                        className={styles['value']}
                        loaderWidth={styles.valueWidth}
                        loading={loading}
                        ariaLabel="key section"
                    />
                    <ValueSection
                        className={styles['value']}
                        loaderWidth={styles.valueWidth}
                        isDisabled={!isEditable}
                        loading={loading}
                        ariaLabel="value section"
                    />
                </DelimiterWrapper>
                {isRemovable && isEditable && (
                    <StyledButton
                        className={classNameBuilder('remove-button', { hidden: loading })}
                        size={STYLED_BUTTON_SIZE.MEDIUM}
                        type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                        iconBefore="close"
                        onClick={handleOnClick}
                    />
                )}
            </div>
        </FormNamespaceProvider>
    );
};

const ExternalMetadata = PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
});

ListItem.propTypes = {
    item: ExternalMetadata,
    deleteItem: PropTypes.func,
    loading: PropTypes.bool,
};
