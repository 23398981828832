import classnames from 'classnames/bind';
import { StatusIndicator } from 'tuui';
import { Dotdotdot } from 'taboola-ultimate-ui';
import { isCampaignGroupDeleted } from 'modules/campaigns/modules/campaigns-reports/config/predicates/campaignGroupPredicates';
import styles from './CampaignGroupOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CampaignGroupOption = ({ data: { label, status } }) => {
    const isDeleted = isCampaignGroupDeleted({ status });
    const type = isDeleted ? StatusIndicator.type.error : StatusIndicator.type.success;
    return (
        <div className={classNameBuilder('item', { deleted: isDeleted })} role="listitem">
            <div className={classNameBuilder('status')}>
                <StatusIndicator type={type} />
            </div>
            <div className={classNameBuilder('label')}>
                <Dotdotdot>{label}</Dotdotdot>
            </div>
        </div>
    );
};
