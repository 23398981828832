import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { last } from 'lodash';
import { Button, SelectAllIcon, DeselectIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { HistoryButtons } from 'modules/campaigns/components/HistoryButtons/HistoryButtons';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    CREATIVE_STUDIO_EVENT_PREFIX,
    CREATIVE_STUDIO_MODE_EVENTS_MAP,
    SEGMENT_ANYTHING_EVENT_PREFIX,
} from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { SELECTION_MODE } from './utils';
import styles from './stageButtons.module.scss';

const classNameBuilder = classnames.bind(styles);

export const StageButtons = ({
    debouncedMouseMove,
    clicks,
    setPoints,
    history,
    setHistory,
    selectMode,
    setSelectMode,
}) => {
    const { campaignId, mode } = useCreativeStudioContext();
    const SEGMENT_EVENTS_PREFIX = `${CREATIVE_STUDIO_EVENT_PREFIX}_${CREATIVE_STUDIO_MODE_EVENTS_MAP[mode]}_${SEGMENT_ANYTHING_EVENT_PREFIX}`;

    const resetHistory = useCallback(() => {
        debouncedMouseMove.cancel();
        setSelectMode(SELECTION_MODE.INCLUDE);
        setPoints([]);
        setHistory([]);
    }, [debouncedMouseMove, setPoints, setHistory, setSelectMode]);

    const undo = () => {
        if (clicks.length > 0) {
            setHistory([...history, last(clicks)]);
            setPoints(clicks.slice(0, -1));
        }
    };

    const redo = () => {
        if (history.length > 0) {
            setPoints([...clicks, last(history)]);
            setHistory(history.slice(0, -1));
        }
    };

    const hasUndo = clicks.length > 0;
    const hasRedo = history.length > 0;

    //GTM Events
    const sendSelectModeClicked = () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${SEGMENT_EVENTS_PREFIX}: Select Subject Clicked`,
            taboolaCampaignId: campaignId,
        });
    };

    const sendExcludeModeClicked = () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${SEGMENT_EVENTS_PREFIX}: Exclude From Selection Clicked`,
            taboolaCampaignId: campaignId,
        });
    };

    return (
        <div className={styles['container']} data-testid="segment-anything-buttons">
            <div className={styles['stage-nav']}>
                <Button
                    variant={Button.variant.outline}
                    onClick={() => {
                        setSelectMode(SELECTION_MODE.INCLUDE);
                        sendSelectModeClicked();
                    }}
                    className={classNameBuilder('segment-mode-select', {
                        inactive: selectMode !== SELECTION_MODE.INCLUDE,
                    })}
                >
                    <Tooltip position={TOOLTIP_POSITION.TOP_RIGHT} className={styles['stage-tooltip']}>
                        <FormattedMessage
                            id="creative.studio.replace.background.segmentAnythingStage.selectMode.tooltip"
                            defaultMessage={
                                "Select the objects you'd like to keep and enter a new prompt below to regenerate the background"
                            }
                        />
                    </Tooltip>
                    <SelectAllIcon />
                    <FormattedMessage
                        id="creative.studio.replace.background.segmentAnythingStage.selectMode.button"
                        defaultMessage="Select Subject"
                    />
                </Button>

                {hasUndo && (
                    <Button
                        variant={Button.variant.ghost}
                        className={classNameBuilder('segment-mode-exclude', {
                            inactive: selectMode !== SELECTION_MODE.EXCLUDE,
                        })}
                        onClick={() => {
                            setSelectMode(SELECTION_MODE.EXCLUDE);
                            sendExcludeModeClicked();
                        }}
                    >
                        <Tooltip position={TOOLTIP_POSITION.TOP_RIGHT} className={styles['stage-tooltip']}>
                            <FormattedMessage
                                id="creative.studio.replace.background.segmentAnythingStage.excludeMode.tooltip"
                                defaultMessage="Remove specific areas from the selection by clicking on them"
                            />
                        </Tooltip>
                        <DeselectIcon />
                        <FormattedMessage
                            id="creative.studio.replace.background.segmentAnythingStage.excludeMode.button"
                            defaultMessage="Exclude From Selection"
                        />
                    </Button>
                )}
            </div>
            {(hasUndo || hasRedo) && (
                <HistoryButtons
                    hasUndo={hasUndo}
                    undo={undo}
                    hasRedo={hasRedo}
                    redo={redo}
                    resetHistory={resetHistory}
                    gtmEventPrefix={SEGMENT_EVENTS_PREFIX}
                />
            )}
        </div>
    );
};
