import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import DisabledProviderLeafTooltip from '../DisabledProviderLeafTooltip/DisabledProviderLeafTooltip';
import { useMarketplaceAudiencesContext } from '../MarketplaceAudiencesContext';
import SegmentListItemTooltip from '../SegmentListItemTooltip/SegmentListItemTooltip';
import { getMinSizeAndMsgPostfix, getSizeContentRenderer } from '../utils/MarketplaceAudiencesUtils';
import styles from './segmentLeafContent.module.scss';

const SegmentLeafContent = ({ item, searchText }) => {
    const { size, provider, label, loading } = item;

    const { isProviderEnabled } = useMarketplaceAudiencesContext();

    if (loading) {
        return (
            <ContentLoader width={460} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="8" rx="8" ry="8" width="250" height="12" />
                <rect x="390" y="8" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    const [minSize, sizeIdPostfix] = getMinSizeAndMsgPostfix(label);

    return (
        <AudienceDetails
            name={label}
            detail={provider}
            size={size}
            minSize={minSize}
            renderSizeContent={getSizeContentRenderer}
            showRemoveButton={false}
            highLightText={searchText}
            tooltip={
                !isProviderEnabled(provider) ? (
                    <DisabledProviderLeafTooltip provider={provider} type="segment" />
                ) : (
                    <SegmentListItemTooltip item={item} />
                )
            }
            sizeIdPostfix={sizeIdPostfix}
        />
    );
};

SegmentLeafContent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    searchText: PropTypes.string,
};

export default SegmentLeafContent;
