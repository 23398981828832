import { useCallback, useEffect, useMemo, useRef } from 'react';
import { difference, reject, isEmpty, keyBy, map, compact, concat } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { useSelectedSegmentsValidationDependencies } from './useSelectedSegmentsValidationDependencies';

export const useSelectedSegmentsGroup = ({
    selectedLeaves,
    setSelectedLeavesByIds,
    idsFormFieldName,
    enabledProvidersMap,
    errorListValidations,
    warningListValidations,
    setIsReady,
    isReadyToValidate,
}) => {
    const { mode } = useFormDataContext();
    const leavesByIdMap = useMemo(() => keyBy(selectedLeaves, 'id'), [selectedLeaves]);

    const validationDependencies = useSelectedSegmentsValidationDependencies({
        enabledProvidersMap,
        leavesByIdMap,
        field: idsFormFieldName,
    });

    const fieldValidations = useMemo(
        () => compact(concat(errorListValidations, warningListValidations)),
        [errorListValidations, warningListValidations]
    );

    const {
        value: selectedIds,
        onChange: onChangeSelectedIds,
        failedValidationData,
        scrollRef,
    } = useFormValidatedValue({
        field: idsFormFieldName,
        validations: fieldValidations,
        validationDependencies,
        isReady: mode === FORM_MODES.CREATE || isReadyToValidate,
    });

    // flow to sync selectedLeaves with selectedIds
    const leavesInitRef = useRef(false);
    const initLeavesCallback = async () => {
        if (!isEmpty(selectedIds)) {
            await setSelectedLeavesByIds(selectedIds);
        } else {
            setIsReady(true);
        }
        leavesInitRef.current = true;
    };

    useEffect(() => {
        if (leavesInitRef.current) {
            return;
        }

        initLeavesCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!leavesInitRef.current) {
            return;
        }

        const treeSelectedLeafIds = selectedLeaves.map(item => item.id);
        const addedIds = difference(treeSelectedLeafIds, selectedIds);
        if (addedIds.length) {
            onChangeSelectedIds(prev => [...prev, ...addedIds]);
        }

        const removedIds = difference(selectedIds, treeSelectedLeafIds);
        if (removedIds.length) {
            const removedIdsSet = new Set(removedIds);
            onChangeSelectedIds(prev => reject(prev, id => removedIdsSet.has(id)));
        }
    }, [selectedIds, onChangeSelectedIds, selectedLeaves]);

    const leaves = useMemo(
        () =>
            map(selectedIds, id => ({
                id,
                ...leavesByIdMap[id],
                loading: !leavesByIdMap[id],
            })),
        [selectedIds, leavesByIdMap]
    );

    const removeLeaves = useCallback(() => setSelectedLeavesByIds([]), [setSelectedLeavesByIds]);

    return {
        leaves,
        removeLeaves,
        failedValidationData,
        scrollRef,
    };
};
