import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useAudiencesApi } from '../../../../../../services/api';
import TargetingTypes from '../../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { useAccount } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';

export const useValidateSearchKeywordsOnAdd = ({
    rejectedKeywords,
    setRejectedKeywords,
    rejectedKeywordsTopics,
    setRejectedKeywordsTopics,
}) => {
    const { value: keywords = [], isDirty } = useFormFieldValue({
        field: 'keywords',
    });

    const { accountName } = useAccount();

    const { validateExternalAudienceAddedValues } = useAudiencesApi();
    const { data = {} } = useQuery(
        ['validateExternalAudienceAddedValues', accountName, keywords],
        () =>
            validateExternalAudienceAddedValues(accountName, {
                collection: [{ type: TargetingTypes.INCLUDE, values: keywords }],
            }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !isEmpty(keywords) || (isEmpty(keywords) && !isEmpty(rejectedKeywords)),
        }
    );

    const { rejectedValues = [] } = data;

    const allKeywords = useMemo(() => {
        return rejectedValues?.reduce((all, { keywords }) => {
            all.push(...keywords);
            return all;
        }, []);
    }, [rejectedValues]);

    useEffect(() => {
        if (!isDirty) {
            return;
        }
        if (!isEmpty(rejectedValues)) {
            setRejectedKeywords(allKeywords);
            setRejectedKeywordsTopics(rejectedValues);
        } else {
            if (!isEmpty(keywords)) {
                setRejectedKeywords([]);
                setRejectedKeywordsTopics({});
            }
        }
    }, [allKeywords, isDirty, keywords, rejectedValues, setRejectedKeywords, setRejectedKeywordsTopics]);

    return { rejectedKeywords, rejectedKeywordsTopics };
};
