import React, { useMemo } from 'react';
import { CheckboxGroup, FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { DuplicateIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from '../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import useFormFieldValue from '../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../components/Form';
import AdsTooltip from '../../../common-campaign-form/components/DuplicateCampaignSettings/AdsSetting/AdsTooltip';
import { DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../../common-campaign-form/config';
import styles from '../../../common-campaign-form/components/DuplicateCampaignSettings/duplicateCampaignSettings.module.scss';

export const DuplicateVideoCampaignSettingsSection = () => {
    const { value: includeCreatives, onChange: onChangeIncludeCreatives } = useFormFieldValue({
        field: 'duplicateCampaignSettings.includeCreatives',
    });

    const selectedSettings = useMemo(() => {
        const settings = [];
        if (includeCreatives) {
            settings.push(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.ADS);
        }
        return settings;
    }, [includeCreatives]);

    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_DUPLICATE_SETTINGS"
            header={
                <SectionHeader
                    headerIcon={<DuplicateIcon />}
                    headerText={
                        <FormattedMessage
                            id="campaign.editor.duplicateCampaignSettings"
                            defaultMessage="Duplicate Campaign Settings"
                        />
                    }
                />
            }
        >
            <FormField
                inputId="duplicate-campaign-settings"
                label={
                    <FormattedMessage id="campaign.editor.duplicateCampaignSettings.label" defaultMessage="Settings" />
                }
                description={
                    <FormattedMessage
                        id="campaign.editor.duplicateCampaignSettings.description"
                        defaultMessage="Choose the setting you would like to duplicate for this campaign."
                    />
                }
            >
                <CheckboxGroup
                    id="duplicate-campaign-settings"
                    className={styles['group']}
                    selectedValues={selectedSettings}
                    onSelectCheckbox={() => onChangeIncludeCreatives(true)}
                    onUnselectCheckbox={() => onChangeIncludeCreatives(false)}
                    data-metrics-component="DuplicateCampaignSettings"
                >
                    <TextCheckbox
                        helpText={<AdsTooltip />}
                        value={DUPLICATE_CAMPAIGN_SETTINGS_TYPE.ADS}
                        title={
                            <FormattedMessage id="campaign.editor.duplicateCampaignSettings.ads" defaultMessage="Ads" />
                        }
                        labelClassName={styles['label']}
                    />
                </CheckboxGroup>
            </FormField>
        </CommonCollapsibleCard>
    );
};

export default DuplicateVideoCampaignSettingsSection;
