import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import classnames from 'classnames/bind';
import { some } from 'lodash';
import PropTypes from 'prop-types';
import { scrollToElement } from 'modules/taboola-common-frontend-modules/formData/scroll-utils';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useMarketplaceAudiencesContext } from '../../MarketplaceAudiences/MarketplaceAudiencesContext';
import { MemoizedTree } from '../../Tree';
import LoadMoreNode from '../../Tree/LoadMoreNode/LoadMoreNode';
import SelectAll from '../../Tree/SelectAll/SelectAll';
import { generatePath } from '../../Tree/utils';
import { SegmentNodeContent } from '../SegmentNodeContent/SegmentNodeContent';
import styles from './segmentsTreeWithBundling.module.scss';

const classNameBuilder = classnames.bind(styles);

const getIsNodeSelectable = ({ node }) => {
    const { isTargetable } = node;
    return isTargetable;
};

const NO_OPTIONS_CONTENT = <FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />;
const LOAD_MORE_CONTENT = <FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />;

export const SegmentsTreeWithBundling = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        collapsedPathMap,
        handleCollapseChange,
        handleLoadMore,
        limit,
        getIsNodeBundled,
        unbundleNode,
        getNodesTree,
        nodes,
    } = useMarketplaceAudiencesContext();
    const scrollRef = useRef(null);
    const { tree: displayedTree, count: displayedTreeCount } = useMemo(() => getNodesTree(), [getNodesTree]);
    const isSelectAllVisible = isSearchMode && displayedTreeCount > 0;

    const hasSelectableNode = useMemo(
        () =>
            some(nodes, ({ isTargetable, id, taxonomy }) => {
                const path = generatePath(id, taxonomy);
                return isTargetable && !selectedPathsMap[path];
            }),
        [nodes, selectedPathsMap]
    );

    const getLabelClassName = useCallback(
        ({ node, hasChildren }) => {
            const { loading } = node;
            const isBundled = getIsNodeBundled(node, hasChildren);

            return classNameBuilder('label', { loading, bundled: isBundled });
        },
        [getIsNodeBundled]
    );

    const renderNodeText = useCallback(
        (domProps, nodeProps) => {
            const { hasMore, id } = nodeProps.node;
            if (hasMore) {
                const { path } = nodeProps.parent;
                return (
                    <LoadMoreNode
                        key={id}
                        onClick={() => handleLoadMore(path)}
                        title={<FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />}
                    />
                );
            }
            const { hasChildren, node } = nodeProps;
            const isBundled = getIsNodeBundled(node, hasChildren);

            return (
                <SegmentNodeContent
                    searchText={search}
                    nodeProps={nodeProps}
                    isBundled={isBundled}
                    unbundleNode={unbundleNode}
                />
            );
        },
        [getIsNodeBundled, handleLoadMore, search, unbundleNode]
    );

    useEffect(() => {
        scrollToElement(scrollRef.current, { behavior: 'smooth', block: 'nearest' });
    }, []);

    return (
        <>
            {isSelectAllVisible && (
                <div className={styles['select-all-container']}>
                    <SelectAll onClick={() => handleSelectAll(hasSelectableNode)}>
                        <FormattedMessage
                            id={`campaign.editor.marketplace.audiences.${
                                hasSelectableNode ? 'selectAll' : 'deselectAll'
                            }.title`}
                            defaultMessage="Select all {totalItems} results (limited to {limit})"
                            values={{ totalItems: displayedTreeCount, limit }}
                        />
                    </SelectAll>
                </div>
            )}
            <div
                className={classNameBuilder('tree-container', { 'below-select-all': isSelectAllVisible })}
                ref={innerRef}
                data-testid="segments-tree-with-bundling"
            >
                <MemoizedTree
                    nodesTree={displayedTree}
                    onSelect={handleSelectNode}
                    selectedItemsMap={selectedPathsMap}
                    totalItems={displayedTreeCount}
                    collapsed={collapsedPathMap}
                    onCollapsedChange={handleCollapseChange}
                    renderNodeText={renderNodeText}
                    onLoadMore={handleLoadMore}
                    noOptionsContent={NO_OPTIONS_CONTENT}
                    loadMoreContent={LOAD_MORE_CONTENT}
                    loadingMode={loadingMode}
                    needRenderCheckbox={getIsNodeSelectable}
                    getIsNodeSelectable={getIsNodeSelectable}
                    labelClassName={getLabelClassName}
                    checkNodesRecursive={false}
                />
            </div>
            <div ref={scrollRef} />
        </>
    );
};

SegmentsTreeWithBundling.propTypes = {
    innerRef: PropTypes.func,
};
