import { useCallback } from 'react';
import { isNil, isUndefined } from 'lodash';
import { useDBStorage } from './DBStorage/useDBStorage';
import { PERSISTENCE_TYPE } from './persistenceType';
import { useLocalStorage } from './useLocalStorage';
import { useMemoryStorage } from './useMemoryStorage';

export const useStorage = () => {
    const [localStorageGetter, localStorageSetter, isLocalStorageReady] = useLocalStorage();
    const [memoryStorageGetter, memoryStorageSetter] = useMemoryStorage();
    const { getter: dbStorageGetter, setter: dbStorageSetter } = useDBStorage();

    const initMemoryViaLocalStorage = useCallback(
        (key, value) => {
            if (isUndefined(memoryStorageGetter(key)) && !isUndefined(value)) {
                memoryStorageSetter(key, value);
            }
        },
        [memoryStorageGetter, memoryStorageSetter]
    );

    const get = useCallback(
        (source, key) => {
            if (isNil(key)) {
                return;
            }

            if (source === PERSISTENCE_TYPE.LOCAL_STORAGE) {
                const value = memoryStorageGetter(key) ?? localStorageGetter(key);
                initMemoryViaLocalStorage(key, value);

                return value;
            }

            if (source === PERSISTENCE_TYPE.DB_STORAGE) {
                return dbStorageGetter(key);
            }

            return memoryStorageGetter(key);
        },
        [localStorageGetter, memoryStorageGetter, dbStorageGetter, initMemoryViaLocalStorage]
    );
    const set = useCallback(
        (source, key, value) => {
            if (isNil(key)) {
                return;
            }

            if (source === PERSISTENCE_TYPE.LOCAL_STORAGE) {
                const wasSet = localStorageSetter(key, value);
                if (!wasSet) {
                    return;
                }
            }

            if (source === PERSISTENCE_TYPE.DB_STORAGE) {
                dbStorageSetter(key, value);
                return;
            }

            memoryStorageSetter(key, value);
        },
        [localStorageSetter, memoryStorageSetter, dbStorageSetter]
    );

    const remove = useCallback(
        (source, key) => {
            if (isNil(key)) {
                return;
            }

            if (source === PERSISTENCE_TYPE.LOCAL_STORAGE) {
                const wasSet = localStorageSetter(key);
                if (!wasSet) {
                    return;
                }
            }

            if (source === PERSISTENCE_TYPE.DB_STORAGE) {
                dbStorageSetter(key);
                return;
            }

            memoryStorageSetter(key);
        },
        [localStorageSetter, memoryStorageSetter, dbStorageSetter]
    );

    return [get, set, remove, isLocalStorageReady];
};
