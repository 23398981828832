import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ContentEditableText } from 'modules/campaigns/components/ContentEditableText';
import { useInsignificantChangesValidation } from 'modules/campaigns/config';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useCurrentValueGetter } from '../../../../../../hooks';
import useFormFieldValue from '../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { useValidationContext } from '../../../../../taboola-common-frontend-modules/validations/ValidationContext';
import BaseCellEditor from './BaseCellEditor';
import styles from './editableTextCellEditor.module.scss';

const classNameBuilder = classnames.bind(styles);

const ContentFieldCellEditor = ({
    placeholder,
    guiAttached,
    editableTextClassName,
    onCancel,
    onSave,
    validations,
    validationDependencies,
    maxLength,
    warningThreshold,
    EditableTextComponent: PropsEditableTextComponent,
    ...restProps
}) => {
    const { colDef, data = {} } = restProps;
    const { field } = colDef;
    const { campaignId } = data;
    const { validate } = useValidationContext();
    const { value } = useFormFieldValue({ field: colDef.field });
    const valueGetter = useCurrentValueGetter(value);
    const onSubmit = async () => {
        const isValid = await validate();
        if (isValid) {
            onSave(valueGetter());
        }
    };
    const EditableTextComponent = PropsEditableTextComponent || ContentEditableText;

    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const baseValidations = useMemo(
        () =>
            validations.map(item => {
                const { isExcludeFromSubmitOnlyValidating } = item;
                return isExcludeFromSubmitOnlyValidating ? item : { ...item, events: ['submitOnly'] };
            }),
        [validations]
    );

    const finalValidations = useInsignificantChangesValidation({ baseValidations });
    const finalValidationDependencies = useMemo(
        () => ({ isContentValidationEnabled, accountId, ...validationDependencies }),
        [isContentValidationEnabled, accountId, validationDependencies]
    );

    return (
        <BaseCellEditor onCancel={onCancel} onSubmit={onSubmit} colDef={colDef}>
            <div className={classNameBuilder('input-container', editableTextClassName)}>
                <EditableTextComponent
                    id="cell-editor-input"
                    data-testid="text-cell-editor-input"
                    field={field}
                    autoFocus={guiAttached}
                    placeholder={placeholder}
                    hideDeleteButton
                    validations={finalValidations}
                    validationDependencies={finalValidationDependencies}
                    throttleValue={false}
                    maxLength={maxLength}
                    warningThreshold={warningThreshold}
                    {...(campaignId ? { campaignId } : {})}
                />
            </div>
        </BaseCellEditor>
    );
};

ContentFieldCellEditor.propTypes = {
    placeholder: PropTypes.string,
    guiAttached: PropTypes.bool,
    editableTextClassName: PropTypes.string,
    onCancel: PropTypes.func,
};

export const ContentFieldCellEditorWithFormData = withGridCellFormDataProvider(ContentFieldCellEditor);
