import React from 'react';
import { Divider } from 'taboola-ultimate-ui';
import styles from './dropdownMenuListWithFooter.module.scss';

export const createMenuListWithFooter = FooterComponent => props =>
    (
        <div className={styles['container']}>
            {props.children}
            <Divider className={styles['divider']} />
            <FooterComponent {...props} />
        </div>
    );
