import React, { useCallback } from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import CurrencyIcon from '../../../campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedNumber } from '../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../taboola-common-frontend-modules/validations';
import useValidationContext from '../../../taboola-common-frontend-modules/validations/hooks/useValidationContext';
import lookBackWindowValidations from '../../validations/lookBackWindowValidations';

const InputWithValidations = withInputValidations(withIndication(Input), lookBackWindowValidations, {
    onChange: { validateEventValue: true, eventValueGetter: e => e.target.value },
});

export const LookBackWindowInput = props => {
    const { value: lookBackWindow, onChange: onLookBackWindowChange } = useFormFieldValue({
        field: 'lookBackWindow',
    });

    const { minDays = 1, maxDays = 180, ...rest } = props;

    const validationContext = useValidationContext({
        minDays,
        maxDays,
    });

    const changeHandler = useCallback(
        e => {
            const value = parseInt(e.target.value);
            onLookBackWindowChange(Number.isNaN(value) ? null : value);
        },
        [onLookBackWindowChange]
    );

    return (
        <FormattedNumber value={0} minimumIntegerDigits={2}>
            {placeholder => (
                <InputWithValidations
                    id="lookBackWindow"
                    type={InputTypes.TEXT}
                    placeholder={placeholder}
                    value={lookBackWindow}
                    onChange={changeHandler}
                    validationContext={validationContext}
                    icon={<CurrencyIcon currency="DAYS" />}
                    inputPadding={50}
                    pattern="\d*"
                    {...rest}
                />
            )}
        </FormattedNumber>
    );
};

export default LookBackWindowInput;
