'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _dataGridUtils = require('../dataGridUtils');

var withLoadingValueFormatter = function withLoadingValueFormatter(valueFormatter) {
  return function (params) {
    return (0, _dataGridUtils.isRowMockData)(params.data) ? null : valueFormatter(params);
  };
};

exports.default = withLoadingValueFormatter;