import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './networkConversionsNote.module.scss';

export const NetworkConversionsNote = () => (
    <div className={styles['conversionsNote']}>
        <FormattedMessage
            id="app.campaigns.reports.columnPicker.networkConversions.note"
            defaultMessage="Network level conversions are visible on the account level only."
        />
    </div>
);
