import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations/services';
import { audienceTargetingBidValidator, bidValidationsBase, selfFactorValidations } from './bidValidations';

const vcpmDataValidations = [
    {
        validationFn: audienceTargetingBidValidator,
        options: { min: 'vcpmDataMinValue' },
        messageId: 'validations.error.campaign.vcpm.data.specificAccountMinValue',
        defaultMessage:
            'vCPM Amount cannot be lower than {vcpmDataMinValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: audienceTargetingBidValidator,
        options: { max: 'vcpmDataMaxValue' },
        messageId: 'validations.error.campaign.vcpm.data.specificAccountMaxValue',
        defaultMessage:
            'vCPM Amount cannot be higher than {vcpmDataMaxValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const vcpmValidationsForCreate = [
    ...bidValidationsBase,
    {
        validationFn: validationFunctions.range,
        options: { min: 'vcpmMinValue' },
        messageId: 'validations.error.campaign.vcpm.specificAccountMinValue',
        defaultMessage: 'Bid has to be higher than {vcpmMinValue} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'vcpmMaxValue' },
        messageId: 'validations.error.campaign.vcpm.specificAccountMaxValue',
        defaultMessage: 'Bid has to be lower than {vcpmMaxValue} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 'vcpmMinWarn' },
        messageId: 'validations.error.campaign.vcpm.network.minValue',
        defaultMessage: 'Please note: Bid is lower than normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'vcpmMaxWarn' },
        messageId: 'validations.error.campaign.vcpm.network.maxValue',
        defaultMessage: 'Please note: Bid is above normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    ...vcpmDataValidations,
];

export const vcpmValidationsForEdit = [...bidValidationsBase, ...vcpmDataValidations, ...selfFactorValidations];
