import uuid from 'uuid/v1';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import { PLACEMENTS } from '../../creative-preview/config/placements';
import { hasCampaignCarousel } from '../components/CarouselCardList/utils/hasCampaignCarousel';
import { AI_VARIATIONS_FIELD_NAMES } from '../constants/aiVariationsConstants';
import { generateDisabledContentItem, generateEmptyContentItem } from '../services/utils';

export const creativeInitialValues = {
    campaigns: ({ initialCampaign, formAccountName }) =>
        initialCampaign &&
        formAccountName &&
        initialCampaign.accountName === formAccountName &&
        !hasCampaignCarousel(initialCampaign)
            ? [initialCampaign]
            : [],
    urls: [{ id: uuid(), value: '' }],
    content: [generateEmptyContentItem(), generateDisabledContentItem()],
    [AI_VARIATIONS_FIELD_NAMES.CONTENT]: [],
    [AI_VARIATIONS_FIELD_NAMES.CREATIVE_PREVIEWS]: {},
    [AI_VARIATIONS_FIELD_NAMES.TITLES]: [''],
    thumbnails: [],
    carouselCards: [],
    creativePreviews: {},
    oneByOneData: {
        urls: '',
        urlsCrawled: 0,
        numUrls: 0,
        validatedUrls: [],
    },
    rssData: {
        urls: '',
        validatedUrls: [],
    },
    creativeName: '',
    customId: '',
    appUrl: '',
    formatType: ({ renderAppInstallFormatInCreateMode } = {}) =>
        renderAppInstallFormatInCreateMode
            ? CREATIVE_FORMAT_TYPE.APP_INSTALL
            : CREATIVE_FORMAT_TYPE.IMAGE_AND_MOTION_AD,
    selectedPlacement: ({ isAppInstall, isTargetingApple }) => {
        if (isAppInstall) {
            return PLACEMENTS.TWO_BY_ONE;
        }
        if (isTargetingApple) {
            return PLACEMENTS.ONE_DOT_NINETY_ONE_BY_ONE;
        }
        return PLACEMENTS.SIXTEEN_BY_NINE;
    },
};
