'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _StyledButton = require('../StyledButton/StyledButton');

var _StyledButton2 = _interopRequireDefault(_StyledButton);

var _StyledButtonType = require('../StyledButton/StyledButtonType');

var _StyledButtonType2 = _interopRequireDefault(_StyledButtonType);

var _StyledButtonSize = require('../StyledButton/StyledButtonSize');

var _StyledButtonSize2 = _interopRequireDefault(_StyledButtonSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var LinkButton = function LinkButton(props) {
    return _react2.default.createElement(_StyledButton2.default, props);
};

LinkButton.propTypes = _StyledButton2.default.propTypes;

LinkButton.defaultProps = Object.assign({}, _StyledButton2.default.defaultProps, {
    type: _StyledButtonType2.default.LINK,
    size: _StyledButtonSize2.default.SMALL
});

exports.default = LinkButton;