import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { CREDIT_LIMIT_MODEL } from '../../config/CreditLimitModel';
import { PAYMENT_TYPE } from '../../config/PaymentType';
import { getPaymentMethodComponent } from '../../config/PaymentTypeMetadata';
import { useAddPaymentMethods } from '../../hooks/useAddPaymentMethods';
import { useBillingActions, useBillingData } from '../../hooks/useBillingData';
import { AddPaymentMethod } from '../AddPaymentMethod/AddPaymentMethod';
import CreditLimit from '../CreditLimit/CreditLimit';
import LoadingCard from '../LoadingCard/LoadingCard';
import { SinglePaymentMethod } from '../SinglePaymentMethod/SinglePaymentMethod';
import styles from './paymentMethodsSection.module.scss';

const PaymentMethods = ({ currency }) => {
    const { billingData } = useBillingData();
    const { deletePaymentMethod: onDelete, deletePaymentMethodV2: onDeleteV2 } = useBillingActions();
    const { formatMessage } = useIntl();
    const { paymentMethodTypesToAdd } = useAddPaymentMethods();

    const { creditLimitModel, creditLimit, paymentMethodsV2 = [] } = billingData;

    const renewedDate = useMemo(() => {
        return moment().add(1, 'months').startOf('month');
    }, []);

    //TODO: when moving card payment method to use api v2 - can use deleteV2 always and remove this dictionary
    const deleteMethod = useMemo(() => {
        return {
            [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: onDeleteV2,
            [PAYMENT_TYPE.CARD]: onDelete,
        };
    }, [onDelete, onDeleteV2]);

    return (
        <div className={styles['payment-methods']}>
            {creditLimitModel === CREDIT_LIMIT_MODEL.MONTHLY && (
                <CreditLimit
                    className={styles['payment-method']}
                    currency={currency}
                    creditLimit={creditLimit}
                    limitTitle={formatMessage({
                        id: `billingAndPayments.creditLimit.${creditLimitModel}`,
                    })}
                    renewedOnDate={creditLimitModel === CREDIT_LIMIT_MODEL.MONTHLY ? renewedDate : null}
                />
            )}
            {paymentMethodsV2.map((paymentMethodData, index) => (
                <SinglePaymentMethod
                    key={index}
                    billingData={billingData}
                    currency={currency}
                    paymentMethodData={paymentMethodData}
                    onDelete={deleteMethod[paymentMethodData.paymentMethodType]}
                    Component={getPaymentMethodComponent(paymentMethodData.paymentMethodType)}
                />
            ))}
            {paymentMethodTypesToAdd.map(paymentMethodType => (
                <AddPaymentMethod key={paymentMethodType} paymentMethodType={paymentMethodType} />
            ))}
        </div>
    );
};

const classNameBuilder = classnames.bind(styles);
export const PaymentMethodsSection = ({ className, currency }) => {
    const { isFetching, billingData } = useBillingData();
    const isShowReconciliationText = useConfigMatch({
        [FEATURE_FLAGS.IS_BILLING_RECONCILIATION_V2_ENABLED]: 'true',
    });
    const { formatMessage } = useIntl();

    return (
        <div className={classNameBuilder('container', className)}>
            <div className={styles['title']}>
                {isShowReconciliationText ? (
                    <b>
                        {formatMessage({
                            id: 'billingAndPayments.paymentMethodsPanel.reconciliation.title',
                            defaultMessage:
                                'How Prepayments works: Prepayments are applied first, then credit limit funds are used to cover any remaining balance.',
                        })}
                    </b>
                ) : (
                    formatMessage({
                        id: 'billingAndPayments.paymentMethodsPanel.title',
                        defaultMessage: 'How You Pay',
                    })
                )}
            </div>
            <div className={styles['scroll-container']}>
                <div className={styles['payment-methods-container']}>
                    {isFetching || isEmpty(billingData) ? <LoadingCard /> : <PaymentMethods currency={currency} />}
                </div>
            </div>
        </div>
    );
};
