import { ERROR_CODES } from '../../../../../services/api/apiConstants';
import { getGraphDataAbort, getGraphDataError as getGraphDataErrorAction } from '../actions';

const getGraphDataError = error => dispatch => {
    if (error.status === ERROR_CODES.ABORT) {
        dispatch(getGraphDataAbort());
    } else {
        dispatch(getGraphDataErrorAction(error));
    }
};

export default getGraphDataError;
