import { useCallback, useMemo, useRef } from 'react';
import { isEmpty, keys } from 'lodash';
import { useModal } from 'modules/taboola-common-frontend-modules/modals/hooks/useModal';
import { ValidationService } from 'modules/taboola-common-frontend-modules/validations/services/validationService';
import { GTM_EVENTS, gtmTracker } from '../../gtmTracker';
import { getPageName } from '../../gtmTracker/urlPageNameUtil';

export const GAComponent = 'FormValidation';

const useFormValidation = () => {
    const validationService = useMemo(() => new ValidationService(), []);
    const optionalValidationResults = useRef({});
    const optionalValidationsConfirmed = useRef({});
    const { open: openModal } = useModal();
    const scrollAreaRef = useRef();

    const reset = useCallback(() => {
        validationService.reset();
        optionalValidationResults.current = {};
        optionalValidationsConfirmed.current = {};
    }, [validationService]);

    const deleteOptionalValidationResults = name => {
        const { [name]: _, ...rest } = optionalValidationResults.current;
        optionalValidationResults.current = rest;
    };

    const setOptionalValidationNote = useCallback((name, optionalValidationMessage, optionalValidatedFieldChanged) => {
        if (
            optionalValidationMessage &&
            (optionalValidatedFieldChanged || !optionalValidationsConfirmed.current[name])
        ) {
            // only update optional validation results if message is provided and is a result of a value change, or message has not already been confirmed
            optionalValidationResults.current[name] = optionalValidationMessage;
        } else if (!optionalValidationMessage && optionalValidationResults.current[name]) {
            // if message for this field exists but setOptionalValidationNote is called with null message, delete message
            deleteOptionalValidationResults(name);
        }
    }, []);

    const onValidationUnmount = useCallback(
        name => {
            validationService.clearValidation(name);
        },
        [validationService]
    );

    const validate = useCallback(async () => {
        const invalidFields = await validationService.validate();

        if (isEmpty(invalidFields)) {
            return true;
        }

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: GAComponent,
            pageName: getPageName(),
            additionalEventInfo: keys(invalidFields).join(','),
        });

        return false;
    }, [validationService]);

    const optionalValidate = useCallback(
        async (confirmationCallback, cancelCallback) => {
            if (isEmpty(optionalValidationResults.current)) {
                return true;
            }

            const warningMessages = Object.keys(optionalValidationResults.current).map(name => ({
                name,
                messages: optionalValidationResults.current[name],
            }));

            const postConfirmation = warningMessage => {
                optionalValidationsConfirmed.current[warningMessage.name] = true;
                deleteOptionalValidationResults(warningMessage.name);
            };
            const result = [];

            for (const warningMessage of warningMessages) {
                const modalResult = await openModal(warningMessage.messages);
                result.push(modalResult);
                if (!modalResult) {
                    break;
                }
            }

            if (result.every(Boolean)) {
                warningMessages.forEach(warningMessages => postConfirmation(warningMessages));
                confirmationCallback();
            } else {
                cancelCallback();
            }

            return false;
        },
        [openModal]
    );

    const validationContext = useMemo(
        () => ({
            setOptionalValidationNote,
            onValidationUnmount,
            reset,
            validate,
            optionalValidate,
            validationService,
            scrollAreaRef,
        }),
        [
            validate,
            optionalValidate,
            setOptionalValidationNote,
            onValidationUnmount,
            reset,
            validationService,
            scrollAreaRef,
        ]
    );

    return {
        validationContext,
        validate,
        optionalValidate,
        scrollAreaRef,
    };
};

export default useFormValidation;
