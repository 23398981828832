import React from 'react';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useReportFilters } from '../../hooks/useReportFilters';
import { useReportFiltersStatus } from '../../hooks/useReportFiltersStatus';
import styles from './campaignHistoryFlagTooltip.module.scss';

const campaignHistoryActivityMessagePrefix = 'app.campaigns.reports.grid.campaign_history.activity_code.';

export const CampaignHistoryFlagTooltip = ({ date, activityDetails }) => {
    const { setReportFilters } = useReportFilters();
    const [, setFilterActive] = useReportFiltersStatus();
    const intl = useIntl();
    const { formatMessage } = intl;
    const handleClick = key => {
        const filter = {
            type: 'activityCode',
            values: [
                {
                    value: key,
                    label: formatMessage({
                        id: `${campaignHistoryActivityMessagePrefix}${key}`,
                        defaultMessage: key,
                    }),
                },
            ],
        };
        setReportFilters([filter]);
        setFilterActive(true);
    };

    return (
        <Tooltip
            className={styles['tooltip']}
            position={TOOLTIP_POSITION.LEFT_END}
            offsetBody={-20}
            offset={-1}
            interactive
        >
            <div className={styles['date']} aria-label="Date">
                {date}
            </div>
            {activityDetails.map(({ activityCode, activityCount }) => (
                <div className={styles['activity-line']} key={activityCode} aria-label="Activity">
                    <div
                        className={styles['activity']}
                        onClick={() => {
                            handleClick(activityCode);
                        }}
                        data-metrics-component="Timeline event link"
                        data-metrics-value={activityCode}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                    >
                        {formatMessage({
                            id: `${campaignHistoryActivityMessagePrefix}${activityCode}`,
                            defaultMessage: activityCode,
                        })}
                    </div>
                    <div className={styles['data']} aria-label="Data">
                        {activityCount}
                    </div>
                </div>
            ))}
        </Tooltip>
    );
};
