import { useQuery } from 'react-query';
import { useOnboardingApi } from 'services/api';
import { CAMPAIGN_STATUS } from '../../../../../../../config';
import { PLATFORMS } from '../../../../../../common-campaign-form/config';
import { STEP_DATA } from '../../../consts';
import { getCreateCampaignData } from './getCreateCampaignData';
import { getLaunchCampaignData } from './getLaunchCampaignData';

const { CREATE_CAMPAIGN_DATA, lAUNCH_CAMPAIGN_DATA } = STEP_DATA;
const { DESKTOP, MOBILE } = PLATFORMS;
const { RUNNING, PENDING_APPROVAL, PENDING_START_DATE, PAUSED } = CAMPAIGN_STATUS;
const validStatuses = new Set([RUNNING, PENDING_APPROVAL, PENDING_START_DATE, PAUSED]);

const hasDesktopOrMobilePlatform = campaign => {
    const { platforms } = campaign;
    return platforms.some(platform => platform === DESKTOP || platform === MOBILE);
};

const hasNonStoppedStatus = campaign => {
    const { status } = campaign;
    return validStatuses.has(status);
};

const getDesktopOrMobileCampaigns = campaignsData => {
    return campaignsData
        ? campaignsData.filter(campaign => hasDesktopOrMobilePlatform(campaign) && hasNonStoppedStatus(campaign))
        : [];
};

export const useCampaignCompletionData = ({ accountId }) => {
    const onboardingApi = useOnboardingApi();

    const {
        isLoading,
        isError,
        data: campaignsData,
    } = useQuery(['onboardingAssistant', accountId], () => onboardingApi.getOnboardingData({ accountId }), {
        select: data => data.campaigns,
    });

    const desktopOrMobileCampaigns = getDesktopOrMobileCampaigns(campaignsData);
    const createCampaignData = getCreateCampaignData(desktopOrMobileCampaigns);
    const { lowestCreativesStatus, ...launchCampaignData } = getLaunchCampaignData(desktopOrMobileCampaigns);
    return {
        [CREATE_CAMPAIGN_DATA]: createCampaignData,
        [lAUNCH_CAMPAIGN_DATA]: launchCampaignData,
        lowestCreativesStatus,
        isLoading,
        isError,
    };
};
