import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const TtlTypeTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.audience.include.people.from.tooltip.overview"
                defaultMessage="The number of days people will remain in your audience after they engage with your content."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.audience.include.people.from.tooltip.note"
                defaultMessage="People will be removed from your audience after the set time period unless they engage with the content again."
            />
        </TooltipSection>
    </div>
);

export default TtlTypeTooltip;
