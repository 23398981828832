import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-context',
    metrics: [{ ...metrics.IMPRESSIONS, active: true }, metrics.VISIBLE_IMPRESSIONS, metrics.COMPLETED_VIEWS],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.CONTEXT.field,
    },
};

export default graph;
