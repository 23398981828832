import React, { useCallback } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import {
    FEATURE_FLAGS,
    useConfigMatch,
    COMMON_FLAGS,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useTrackingDataForBidStrategy } from 'modules/tracking/hooks/useTrackingDataForBidStrategy';
import { ApplyCapSection } from '../ApplyCapSection/ApplyCapSection';
import { TargetCpaStrategy } from '../TargetCpaStrategy/TargetCpaStrategy';
import { TargetCpaTitle } from '../TargetCpaStrategy/TargetCpaTitle/TargetCpaTitle';
import { useTargetCpaAvailability } from '../hooks/useTargetCpaAvailability';
import { useMaxConversionsState } from './useMaxConversionsState';
import styles from './maxConversionsCategory.module.scss';

export const MaxConversionsCategory = ({ availableBidStrategies }) => {
    const { bidStrategy, onChangeBidStrategy } = useMaxConversionsState();
    const targetCpaAvailabilityProps = useTargetCpaAvailability();
    const { isPixelOrS2sInstalled, hasAtLeastOneActiveConversion, isTcpaPixelValidationEnabled } =
        useTrackingDataForBidStrategy();
    const isTcpaDisabledDueToPixel =
        isTcpaPixelValidationEnabled &&
        (!isPixelOrS2sInstalled || !hasAtLeastOneActiveConversion) &&
        bidStrategy !== BID_STRATEGIES.TARGET_CPA;
    const isCpcCapEnabled = useConfigMatch(
        { [FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER]: 'true' },
        {
            [COMMON_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED]: 'true',
        }
    );
    const shouldForceTargetCpa = !availableBidStrategies[BID_STRATEGIES.MAX_CONVERSIONS];
    const shouldRenderTargetCpa = availableBidStrategies[BID_STRATEGIES.TARGET_CPA];

    const { disableCpa, shouldDisableCpaOnDuplicate } = targetCpaAvailabilityProps;

    const handleCheckboxSelect = useCallback(
        () => onChangeBidStrategy(BID_STRATEGIES.TARGET_CPA),
        [onChangeBidStrategy]
    );
    const handleCheckboxUnselect = useCallback(
        () => onChangeBidStrategy(BID_STRATEGIES.MAX_CONVERSIONS),
        [onChangeBidStrategy]
    );

    if (isCpcCapEnabled) {
        return <ApplyCapSection />;
    }

    return shouldRenderTargetCpa ? (
        <div className={styles['container']}>
            <>
                {!shouldForceTargetCpa && (
                    <CheckboxGroup
                        onSelectCheckbox={handleCheckboxSelect}
                        onUnselectCheckbox={handleCheckboxUnselect}
                        selectedValues={[bidStrategy === BID_STRATEGIES.TARGET_CPA]}
                    >
                        <TextCheckbox
                            disabled={isTcpaDisabledDueToPixel || disableCpa || shouldDisableCpaOnDuplicate}
                            value
                            title={
                                <TargetCpaTitle
                                    {...targetCpaAvailabilityProps}
                                    isPixelOrS2sInstalled={isPixelOrS2sInstalled}
                                    hasAtLeastOneActiveConversion={hasAtLeastOneActiveConversion}
                                    isTcpaPixelValidationEnabled={isTcpaPixelValidationEnabled}
                                />
                            }
                        />
                    </CheckboxGroup>
                )}
                {bidStrategy === BID_STRATEGIES.TARGET_CPA && (
                    <TargetCpaStrategy containerClass={styles['tcpa-input-container']} />
                )}
            </>
        </div>
    ) : null;
};
