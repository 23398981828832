export const CAMPAIGNS_FETCH_LEVEL = {
    ACTIVE: 'A',
    RECENT: 'R',
    RECENT_AND_PAUSED: 'RAP',
};

export const CREATIVE_STATUS_FILTER = {
    ACTIVE: 'active',
    PAUSED: 'paused',
};
