import { get } from 'lodash';
import abortControllerFactory from '../services/api/abortControllerFactory';

const abortRequest = () => next => action => {
    const abortRequestsCacheKey = get(action, 'metadata.abortRequestsCacheKey');

    if (abortRequestsCacheKey) {
        abortControllerFactory.abort(abortRequestsCacheKey);
    }

    return next(action);
};

export default abortRequest;
