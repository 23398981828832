import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { FORM_MODES } from '../../../config';
import { replacePathParams } from '../../../services/utils';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';
import { REPORT_PARAM_TEMPLATE, REPORT_PRESET_PARAM_NAME } from './reportPresetName';

const KEY_TO_PATH_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const COLUMN_PICKER_ROUTE_PATH = replacePathParams(
    `${BASE_FORM_ROUTE_PATH}${REPORT_PARAM_TEMPLATE}`,
    {
        entity: [ENTITY.COLUMN_PICKER],
        mode: [FORM_MODES.CREATE, FORM_MODES.EDIT],
    },
    [],
    KEY_TO_PATH_MAP
);

export const COLUMN_PICKER_CREATOR_ROUTE_PATH = replacePathParams(
    COLUMN_PICKER_ROUTE_PATH,
    {
        entity: [ENTITY.COLUMN_PICKER],
        mode: [FORM_MODES.CREATE],

        // TODO: Rename the view query param string as part of https://jira.taboola.com/browse/LA-47351
        reportViewName: [],
    },
    [],
    KEY_TO_PATH_MAP
);

export const COLUMN_PICKER_EDITOR_ROUTE_PATH = replacePathParams(
    COLUMN_PICKER_ROUTE_PATH,
    {
        entity: [ENTITY.COLUMN_PICKER],
        mode: [FORM_MODES.EDIT],
    },
    [REPORT_PRESET_PARAM_NAME],
    KEY_TO_PATH_MAP
);
