import { flatten, groupBy, initial, isEqual, last, partition, sortBy } from 'lodash';
import { TARGETING_TYPES } from '../../config';
import {
    DAYS_OF_WEEK_ORDER,
    GROUPS_OF_DAYS_ORDER,
    GROUPS_TO_DAYS_MAP,
} from '../DayTimeBidBoost/consts/dayTimeDaysOptions';

const createRule = ({ day, fromHour, untilHour }) => ({
    type: TARGETING_TYPES.INCLUDE,
    day,
    fromHour,
    untilHour,
});

const convertSingleRuleToInclude = rule => {
    const day = rule.day;
    const fromHour = rule.fromHour;
    const untilHour = rule.untilHour;
    if (fromHour === 0 && untilHour === 24) {
        return [];
    }
    if (fromHour === 0) {
        return [createRule({ day, fromHour: untilHour, untilHour: 24 })];
    }
    if (untilHour === 24) {
        return [createRule({ day, fromHour: 0, untilHour: fromHour })];
    }
    return [
        createRule({ day, fromHour: 0, untilHour: fromHour }),
        createRule({ day, fromHour: untilHour, untilHour: 24 }),
    ];
};

const intersectExcludes = (excludeRulesList, excludeRule2) => {
    if (!excludeRule2) {
        return excludeRulesList;
    }
    const excludeRule1 = excludeRulesList[excludeRulesList.length - 1];
    if (excludeRule1.fromHour >= excludeRule2.untilHour || excludeRule2.fromHour >= excludeRule1.untilHour) {
        return [...excludeRulesList, excludeRule2];
    }
    const objectsExceptLast = initial(excludeRulesList);
    return [
        ...objectsExceptLast,
        {
            day: excludeRule1.day,
            fromHour: Math.max(excludeRule1.fromHour, excludeRule2.fromHour),
            untilHour: Math.min(excludeRule1.untilHour, excludeRule2.untilHour),
        },
    ];
};

const resolveIntersections = rules => {
    const daysToIncludeRulesMap = groupBy(rules, 'day');
    for (const day in daysToIncludeRulesMap) {
        const [firstRule, ...restRules] = daysToIncludeRulesMap[day];
        daysToIncludeRulesMap[day] = restRules.reduce(intersectExcludes, [firstRule]);
    }
    return flatten(Object.values(daysToIncludeRulesMap));
};

const convertRulesToInclude = rules => {
    const convertedIncludeRules = rules.flatMap(convertSingleRuleToInclude);
    return resolveIntersections(convertedIncludeRules);
};

const getIntervals = rules => {
    return rules.map(rule => ({ fromHour: rule.fromHour, untilHour: rule.untilHour }));
};

const isConsecutiveDys = (day1, day2) => {
    return DAYS_OF_WEEK_ORDER.indexOf(day1) - DAYS_OF_WEEK_ORDER.indexOf(day2) === 1;
};

const sortByHour = rules => {
    return sortBy(rules, ['fromHour']);
};

const getSortedDaysAndRulesList = rules => {
    return sortBy(Object.entries(groupBy(sortByHour(rules), 'day')), ([day, rules]) => DAYS_OF_WEEK_ORDER.indexOf(day));
};

const groupByIntervals = rules => {
    const sortedDaysAndRulesList = getSortedDaysAndRulesList(rules);
    const groupedList = [];
    sortedDaysAndRulesList.forEach(([day, rules]) => {
        const currInterval = getIntervals(rules);
        if (groupedList.length === 0) {
            groupedList.push({
                startDay: day,
                endDay: day,
                intervals: currInterval,
            });
            return;
        }
        const lastItem = last(groupedList);
        const consecutiveDays = isConsecutiveDys(day, lastItem.endDay);
        const sameIntervals = isEqual(lastItem.intervals, currInterval);
        if (consecutiveDays && sameIntervals) {
            lastItem.endDay = day;
            return;
        }
        groupedList.push({
            startDay: day,
            endDay: day,
            intervals: currInterval,
        });
    });

    return groupedList;
};

const ungroupSingleRule = rule => {
    if (GROUPS_OF_DAYS_ORDER.includes(rule.day)) {
        return GROUPS_TO_DAYS_MAP[rule.day].map(day => ({ ...rule, day }));
    }
    return rule;
};

const ungroupRules = rules => {
    return rules.flatMap(ungroupSingleRule);
};

const emptyArray = [];
export const getDayTimeRulesSummary = ({ campaignScheduleRules }) => {
    if (campaignScheduleRules.length === 0) {
        return emptyArray;
    }
    const ungroupedRules = ungroupRules(campaignScheduleRules);
    const [excludeRules, includeRules] = partition(ungroupedRules, { type: TARGETING_TYPES.EXCLUDE });
    const convertedIncludeRules = convertRulesToInclude(excludeRules);
    const allRules = [...includeRules, ...convertedIncludeRules];
    return groupByIntervals(allRules);
};
