import { keyBy } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { config as logRocketConfig } from 'modules/taboola-common-frontend-modules/log-rocket';
import { fetchCurrentRootAccount } from '../../../../flows';
import { addIndication } from '../../Indications';
import { FormattedMessage } from '../../i18n';
import { fetchUserSuccess, logout } from '../actions';
import Config from '../config';
import { userSelector } from '../selectors';
import { authTokenManager, EXPIRED_TOKEN_ERROR, MISSED_TOKEN_ERROR } from '../services/AuthTokenManager';
import { loginAndBackStartPage } from '../services/pageRedirect';
import { cleanAuthCounterData } from '../utils/auth-utils';

const ONE_SECOND = 1000;

const userFetchErrorIndication = {
    message: <FormattedMessage id="app.user.fetch.error" defaultMessage="Unable to fetch user. Please try later." />,
    type: INDICATION_TYPES.ERROR,
    timeoutActive: false,
};
const userHasPermissionToAdsConsole = permissions => permissions.includes(Config.getAdsConsolePermissionType());

export const fetchUser = (authenticationApi, userId, updateTokenExp) => async (dispatch, getState) => {
    const oldUser = userSelector(getState());

    try {
        const user = await authenticationApi.getTokenDetails();

        const { permissions } = user;

        if (!userHasPermissionToAdsConsole(permissions)) {
            authTokenManager.clear();
            window.location.replace(Config.getBackstageExternalUrl());
            return;
        }

        if (oldUser.user_id && oldUser.user_id !== user.user_id) {
            loginAndBackStartPage(userId);
            return;
        }

        logRocketConfig.setUser(user);
        dispatch(fetchUserSuccess({ ...user, permissions: keyBy(permissions) }));
        if (updateTokenExp) {
            authTokenManager.setToken(authTokenManager.getToken(), Date.now() + user.expires_in * ONE_SECOND);
        }
        // TODO: should be removed in DEV-80912 after full rollout.
        dispatch(fetchCurrentRootAccount());
        cleanAuthCounterData();
    } catch (error) {
        if (error === MISSED_TOKEN_ERROR || error === EXPIRED_TOKEN_ERROR) {
            return;
        }
        dispatch(logout(error));
        dispatch(addIndication(userFetchErrorIndication));
        authTokenManager.clear();
    }
};
