import { useCallback, useMemo, useRef, useState } from 'react';
import uuid from 'uuid/v1';
import { INPUT_GROUP_TYPES, InputTypes, useEnterHandler } from 'taboola-ultimate-ui';
import {
    useFormValidatedValue,
    useValidations,
    validationFunctions,
} from 'modules/taboola-common-frontend-modules/validations';
import { useCurrentValueGetter, useEventValueHandler } from '../../../hooks';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';
import { scheduledReportEmailListValidations } from '../validations/scheduledReportEmailListValidations';
import { scheduledReportEmailValidations } from '../validations/scheduledReportEmailValidations';
import styles from '../components/ScheduledReportsSentToField/scheduledReportSentToInput.module.scss';

const emailRecipientsLimit = 10;

export const useScheduledReportsSentTo = () => {
    const { formatMessage } = useIntl();
    const inputRef = useRef();

    const {
        value: sentTo,
        onChange: onSentToChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'sentTo',
        validations: scheduledReportEmailListValidations,
    });

    const [email, setEmail] = useState('');

    const { failedValidationData, isValidationsRunning, isValid } = useValidations({
        validations: scheduledReportEmailValidations,
        validationId: 'email',
        valueToValidate: email,
        validationDependencies: { sentTo },
        isDirty: true,
        isReady: true,
    });

    const handleEmailChange = useEventValueHandler(setEmail);

    const mergedConfig = useMemo(() => {
        const emailInputConfig = {
            type: INPUT_GROUP_TYPES.INPUT,
            dataKey: 'email',
            props: {
                placeholder: formatMessage({
                    id: 'app.scheduled.reports.sent.to.text.placeholder',
                    defaultMessage: 'Enter email...',
                }),
                value: email,
                type: InputTypes.TEXT,
                onChange: handleEmailChange,
                inputClass: styles['input'],
                inputWrapperClass: styles['input-wrapper'],
                ref: inputRef,
            },
        };

        const inputGroupConfig = [emailInputConfig];

        return {
            emailInputConfig,
            inputGroupConfig,
        };
    }, [formatMessage, email, handleEmailChange]);

    const disabled = useMemo(() => {
        return (
            sentTo?.length >= emailRecipientsLimit ||
            isValidationsRunning ||
            !isValid ||
            !validationFunctions.isNotEmptyString(email)
        );
    }, [sentTo, email, isValidationsRunning, isValid]);

    const disabledGetter = useCurrentValueGetter(disabled);
    const emailGetter = useCurrentValueGetter(email);

    const handleAddSentTo = useCallback(() => {
        if (disabledGetter()) {
            return;
        }
        const emailToAdd = {
            id: uuid(),
            email: emailGetter(),
        };
        onSentToChange(prev => [...prev, emailToAdd]);
        setEmail('');
    }, [emailGetter, disabledGetter, onSentToChange]);

    const handleRemoveEmail = useCallback(
        ({ email: emailToRemove }) => {
            onSentToChange(email => email.filter(({ email }) => email !== emailToRemove));
        },
        [onSentToChange]
    );

    const handleRemoveAllEmails = useCallback(() => onSentToChange([]), [onSentToChange]);
    useEnterHandler(handleAddSentTo, { stopPropagation: true, target: inputRef.current });

    return {
        sentTo,
        handleAddSentTo,
        handleRemoveEmail,
        handleRemoveAllEmails,
        indicationData,
        scrollRef,
        mergedConfig,
        email,
        setEmail,
        failedValidationData,
        disabled,
    };
};
