import { useCallback, useState } from 'react';

// should be global state and should reset on page refresh
const dismissedRef = { current: false };

export const useTryGenAiTooltipDismissedForSession = () => {
    const [dismissed, rawSetDismissed] = useState(dismissedRef.current);

    const setDismissed = useCallback(val => {
        rawSetDismissed(val);
        dismissedRef.current = val;
    }, []);

    return {
        dismissed,
        setDismissed,
        dismissedRef,
    };
};
