import React from 'react';
import classnames from 'classnames/bind';
import { ErrorOutlinedIcon, OfflineBoltOutlinedIcon, InformationIcon } from 'tuui';
import styles from './notificationTypeIcons.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ActionIcon = ({ isInactive }) => (
    <OfflineBoltOutlinedIcon className={classNameBuilder('icon', 'action-icon', { 'is-inactive': isInactive })} />
);
export const WarningIcon = ({ isInactive }) => (
    <ErrorOutlinedIcon className={classNameBuilder('icon', 'warning-icon', { 'is-inactive': isInactive })} />
);
export const InfoIcon = ({ isInactive }) => (
    <InformationIcon className={classNameBuilder('icon', 'info-icon', { 'is-inactive': isInactive })} />
);
export const AlertIcon = ({ isInactive }) => (
    <ErrorOutlinedIcon className={classNameBuilder('icon', 'alert-icon', { 'is-inactive': isInactive })} />
);
