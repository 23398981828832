'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _DropdownMenu = require('../Dropdown/DropdownMenu');

var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

var _PlusSmall = require('../../Icons/Icons/PlusSmall');

var _PlusSmall2 = _interopRequireDefault(_PlusSmall);

var _Buttons = require('../../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'menuBorderRadius': '0 0 0.7rem 0.7rem',
    'menuBorder': '1px solid var(--green-5)',
    'white': 'var(--white)',
    'gray2': 'var(--gray-2)',
    'gray4': 'var(--gray-4)',
    'taboola-svg-icon': 'dropdownButton__taboola-svg-icon___GVNwu',
    'container': 'dropdownButton__container___3az30',
    'button': 'dropdownButton__button___noQuI',
    'is-open': 'dropdownButton__is-open___1O740'
};


var classNameBuilder = _bind2.default.bind(styles);

var customStyles = {
    control: function control() {
        return {
            height: '100%',
            '--border': 'none'
        };
    },
    valueContainer: function valueContainer() {
        return {
            padding: 0
        };
    },
    placeholder: function placeholder() {
        return {
            padding: 0,
            position: 'static',
            width: '100%',
            marginLeft: 0
        };
    },
    menu: function menu(provided) {
        return Object.assign({}, provided, {
            borderTop: styles.menuBorder,
            borderLeft: styles.menuBorder,
            borderRight: styles.menuBorder,
            borderBottom: styles.menuBorder,
            borderRadius: styles.menuBorderRadius,
            marginTop: '0',
            boxShadow: 'none'
        });
    }
};

var customTheme = function customTheme(theme) {
    return Object.assign({}, theme, {
        colors: Object.assign({}, theme.colors, {
            primary25: styles.gray2,
            primary50: styles.gray4
        })
    });
};

var DropdownButton = function (_Component) {
    _inherits(DropdownButton, _Component);

    function DropdownButton(props) {
        _classCallCheck(this, DropdownButton);

        var _this = _possibleConstructorReturn(this, (DropdownButton.__proto__ || Object.getPrototypeOf(DropdownButton)).call(this, props));

        _this.handleOnChange = function (inputValue) {
            var onChange = _this.props.onChange;


            _this.closeOptions();
            onChange(inputValue);
        };

        _this.handleOutsideClick = function (e) {
            // ignore clicks on the component itself
            if (!_this.select.current || _this.select.current.contains(e.target)) {
                return;
            }

            _this.closeOptions();
        };

        _this.toggleDropdown = function () {
            var isOpen = _this.state.isOpen;


            if (!isOpen) {
                document.addEventListener('click', _this.handleOutsideClick, false);
            } else {
                document.removeEventListener('click', _this.handleOutsideClick, false);
            }

            _this.setState(function (prevState) {
                return {
                    isOpen: !prevState.isOpen
                };
            });
        };

        _this.renderButton = function () {
            var _this$props = _this.props,
                disabled = _this$props.disabled,
                buttonText = _this$props.buttonText,
                buttonIconBefore = _this$props.buttonIconBefore,
                buttonSize = _this$props.buttonSize,
                buttonType = _this$props.buttonType,
                ButtonComponent = _this$props.ButtonComponent;
            var isOpen = _this.state.isOpen;

            var btnClassName = classNameBuilder('button', {
                'is-open': isOpen
            });

            return _react2.default.createElement(
                ButtonComponent,
                {
                    disabled: disabled,
                    iconBefore: buttonIconBefore,
                    onClick: _this.toggleDropdown,
                    onTouchStart: _this.toggleDropdown,
                    size: buttonSize,
                    type: buttonType,
                    className: btnClassName
                },
                buttonText
            );
        };

        _this.state = {
            isOpen: false
        };
        _this.select = _react2.default.createRef();
        return _this;
    }

    _createClass(DropdownButton, [{
        key: 'closeOptions',
        value: function closeOptions() {
            this.setState({
                isOpen: false
            });
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
    }, {
        key: 'render',
        value: function render() {
            return _react2.default.createElement(
                'div',
                { className: styles['container'], ref: this.select },
                _react2.default.createElement(_DropdownMenu2.default, Object.assign({}, this.props, {
                    menuIsOpen: this.state.isOpen,
                    valueRenderer: this.renderButton,
                    placeholder: this.renderButton(),
                    selectedValueObject: null,
                    arrowRenderer: null,
                    styles: customStyles,
                    theme: customTheme,
                    onChange: this.handleOnChange
                }))
            );
        }
    }]);

    return DropdownButton;
}(_react.Component);

DropdownButton.propTypes = Object.assign({
    /** button text */
    buttonText: _propTypes2.default.node,
    /** button icon before element */
    buttonIconBefore: _propTypes2.default.node,
    /** Determines button size */
    buttonSize: _propTypes2.default.oneOf(Object.values(_Buttons.STYLED_BUTTON_SIZE)),
    /** Determines button type */
    buttonType: _propTypes2.default.oneOf(Object.values(_Buttons.STYLED_BUTTON_TYPE)),
    ButtonComponent: _propTypes2.default.elementType
}, _DropdownMenu2.default.propTypes);

DropdownButton.defaultProps = {
    buttonIconBefore: _react2.default.createElement(_PlusSmall2.default, null),
    buttonSize: _Buttons.STYLED_BUTTON_SIZE.PRIMARY,
    buttonType: _Buttons.STYLED_BUTTON_TYPE.STRONG,
    ButtonComponent: _Buttons.StyledButton
};

exports.default = DropdownButton;