import { ENTITY } from 'modules/campaigns/config/routes';
import { stringifyQueryParams } from 'modules/taboola-common-frontend-modules/query-params/queryParamUtils';
import { ENTITY_TO_API_PATH } from '../campaignsApi/campaignsApiFactory';

const bulkOperationsApiPrefix = '/bulk';
const bulkCampaignsApiPrefix = ({ accountId }) => `${bulkOperationsApiPrefix}/${accountId}/campaigns`;
const bulkCreativesApiPrefix = ({ accountId }) => `${bulkOperationsApiPrefix}/${accountId}/creatives`;
const bulkCreateCampaignsAPIPrefix = ({ entityType = ENTITY.CAMPAIGN } = {}) => {
    const apiPath = ENTITY_TO_API_PATH[entityType] || ENTITY_TO_API_PATH[ENTITY.CAMPAIGN];
    return `${bulkOperationsApiPrefix}/${apiPath}`;
};

export const bulkOperationsApiFactory = ({ callGetApi, callPostApi }) => ({
    getBulkAccountCampaigns: ({ accountId, fetchLevel, selectedCampaignGroupIds }) =>
        callGetApi(
            `${bulkCampaignsApiPrefix({ accountId })}${stringifyQueryParams(
                { campaign_groups_filter: selectedCampaignGroupIds, fetch_level: fetchLevel },
                { skipNulls: true }
            )}`
        ),
    createBulkCampaigns: ({ entityType, requestBody }) =>
        callPostApi(bulkCreateCampaignsAPIPrefix({ entityType }), requestBody),
    getBulkAccountCreatives: ({ accountId, statusFilter, selectedCampaignGroupIds }) =>
        callGetApi(
            `${bulkCreativesApiPrefix({ accountId })}${stringifyQueryParams(
                { campaign_groups_filter: selectedCampaignGroupIds, status_filter: statusFilter },
                { skipNulls: true }
            )}`
        ),
    bulkCreateAndUpdateCreatives: ({ requestBody }) => callPostApi(`${bulkOperationsApiPrefix}/creatives`, requestBody),
});
