import { dimensionSelector, selectedAccountSelector } from '../../../../../selectors';
import { graphsApi } from '../../../../../services/api';
import { baseReportDataSelector } from '../../../selectors';
import { requestGetGraphData } from '../actions';
import { getGraphDataSuccess as getGraphDataSuccessAction } from '../actions';
import { getReportFiltersParam, getDateRangeQueryParam } from '../utils/queryFilterUtils';
import clearGraphResources from './clearGraphResources';
import getGraphDataError from './getGraphDataError';
import getGraphDataSuccess from './getGraphDataSuccess';

export const getGraphData =
    ({
        accountId: accountName,
        startDate,
        endDate,
        campaignId,
        initial = false,
        dateChanged = false,
        reportQueryParams,
        reportFilters,
        locale,
        currency,
        graphEdgesState,
        setGraphEdgesState,
        graphConfig: graph = {},
        requestHashGetter,
    }) =>
    (dispatch, getState) => {
        if (!initial && !dateChanged) {
            return;
        }
        const {
            defaultSort,
            maxXAxisValues,
            displayTotalsInLegendRow = true,
            getGridDataDependentFilters = () => ({}),
        } = graph;

        const state = getState();
        const { accountId } = selectedAccountSelector(state);
        const reportData = baseReportDataSelector(state);
        const dimension = dimensionSelector(state);
        const resolvedAccountName = accountName || accountId;
        const sort = defaultSort ? { sort: JSON.stringify(defaultSort) } : {};

        dispatch(clearGraphResources());
        const gridDataDependentFilters = getGridDataDependentFilters(reportData);
        const queryParams = {
            ...getDateRangeQueryParam(startDate, endDate),
            ...getReportFiltersParam({
                campaignId,
                dynamicFilters: reportFilters,
                campaignIdFilterName: graph.campaignIdFilterName,
                ...gridDataDependentFilters,
            }),
            ...sort,
            dimension,
            ...reportQueryParams,
        };
        if (!gridDataDependentFilters) {
            dispatch(getGraphDataSuccessAction({ results: [], total: {}, metadata: {} }));
            return;
        }
        dispatch(requestGetGraphData());
        const requestHash = requestHashGetter();
        graphsApi(graph.endpoint, resolvedAccountName, queryParams)
            .then(graphResponse => {
                if (requestHash !== requestHashGetter()) {
                    return;
                }

                dispatch(
                    getGraphDataSuccess({
                        graphConfig: graph,
                        graphResponse,
                        graphEdgesState,
                        setGraphEdgesState,
                        locale,
                        currency,
                        options: { maxXAxisValues, displayTotalsInLegendRow },
                        startDate,
                    })
                );
            })
            .catch(error => {
                dispatch(getGraphDataError(error));
            });
    };
