import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { CampaignIcon, VideoCampaignIcon } from 'taboola-ultimate-ui';
import { InternalLink } from 'components';
import { DIMENSION } from 'hooks';
import { permissionsSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { dimensionSelector, selectedAccountPartnerTypesSelector } from 'selectors';
import { getAllCampaignsName, getAllCampaignsNameId } from 'selectors/allCampaignsNameIdSelector';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { COMPONENT_STATUS } from 'services/constants';
import AllCampaignsObject from '../../config/allCampaignsObject';
import { CAMPAIGN_ID } from '../../hooks';
import { AllCampaignsContentLoader } from '../CampaignPicker/CampaignLoader';
import styles from './campaignPickerHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const CampaignPickerHeader = ({
    campaignsCount,
    status,
    disabled,
    shouldShowTotals,
    dimension,
    selectedCampaignId,
}) => {
    const selectedCampaignDimension = useSelector(dimensionSelector);
    const partnerTypes = useSelector(selectedAccountPartnerTypesSelector);
    const permissions = useSelector(permissionsSelector);
    const allCampaignsNameId = getAllCampaignsNameId(dimension, partnerTypes, permissions);
    const allCampaignsName = getAllCampaignsName(dimension, partnerTypes, permissions);

    const isLoading = status === COMPONENT_STATUS.INITIAL || status === COMPONENT_STATUS.LOADING;

    if (isLoading && !disabled) {
        return <AllCampaignsContentLoader />;
    }

    const containerClassNames = classNameBuilder('container', {
        selected: selectedCampaignDimension === dimension,
        'is-selected-campaign': selectedCampaignId !== AllCampaignsObject.id,
    });

    return (
        <InternalLink
            className={containerClassNames}
            params={{ [DIMENSION]: dimension, [CAMPAIGN_ID]: AllCampaignsObject.id }}
            role="menuitem"
            tabIndex="-1"
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={allCampaignsName}
        >
            {dimension === CAMPAIGN_DIMENSION.VIDEO ? (
                <VideoCampaignIcon className={styles['campaign-icon']} />
            ) : (
                <CampaignIcon className={styles['campaign-icon']} />
            )}
            <div className={styles['text']}>
                <FormattedMessage id={allCampaignsNameId} defaultMessage={allCampaignsName} />
            </div>
            {shouldShowTotals && <div className={styles['count']}>({campaignsCount}) </div>}
        </InternalLink>
    );
};

CampaignPickerHeader.propTypes = {
    campaignsCount: PropTypes.number,
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    shouldShowTotals: PropTypes.bool,
    dimension: PropTypes.oneOf(Object.values(CAMPAIGN_DIMENSION)),
};

CampaignPickerHeader.defaultProps = {
    campaignsCount: 0,
};

export default CampaignPickerHeader;
