/**
 * Created by oreuveni
 * Date: 2019-04-17
 * Time: 17:48
 */
export const BID_STRATEGIES = {
    SMART: 'SMART',
    FIXED: 'FIXED',
    TARGET_CPA: 'TARGET_CPA',
    MAX_CONVERSIONS: 'MAX_CONVERSIONS',
    MAX_REVENUE: 'MAX_REVENUE',
    TARGET_ROAS: 'TARGET_ROAS',
};

export default BID_STRATEGIES;
