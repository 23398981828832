import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    StyledButton,
    INDICATION_TYPES,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    IndicationContent,
} from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { isNetworkAccountType } from 'modules/account-management';
import { totalCampaignsCountSelector } from 'modules/campaigns/selectors/baseSelectors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './audienceTargetingFirstCampaignIndication.module.scss';

export const AudienceTargetingFirstCampaignIndication = () => {
    const totalCampaignsCount = useSelector(totalCampaignsCountSelector);
    const isFirstCampaign = totalCampaignsCount === 0;
    const [showIndication, setShowIndication] = useState(true);
    const removeIndication = () => {
        setShowIndication(false);
    };
    const [{ type }] = useSelectedAccount();
    const isNetworkAccount = isNetworkAccountType(type);
    const isIndicationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUDIENCE_TARGETING_FIRST_CAMPAIGN_MESSAGE_ENABLED]: 'true',
    });

    const shouldShowIndication = isIndicationEnabled && showIndication && isFirstCampaign && !isNetworkAccount;

    return shouldShowIndication ? (
        <div className={styles['info-indication']}>
            <IndicationContent type={INDICATION_TYPES.INFO}>
                <div className={styles['tip-text']}>Tip -</div>
                <FormattedMessage
                    id="campaign.editor.audience.targeting.first.campaign"
                    defaultMessage="We do not recommend to target any audience right now so you get as much data as possible from your 1st campaign."
                />
                <StyledButton
                    aria-label="Remove"
                    className={styles['remove-button']}
                    size={STYLED_BUTTON_SIZE.SMALL}
                    type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                    iconBefore="close"
                    onClick={removeIndication}
                />
            </IndicationContent>
        </div>
    ) : null;
};
