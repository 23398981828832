import { useMemo } from 'react';

/*
Maps values to a corresponding option using a unique key.
Options param allows you to change the unique key and the
behavior when a value doesn't have a matching option

EXAMPLE:
    a = ['b'];
    b = [{ value: 'a', label: 'Foo' }, { value: 'b', label: 'Bar' }]
    c = useSelectedValueToOptionMapping(a, b)
    console.log(c) -> [{ value: 'b', label: 'Bar' }]

    d = ['Foo', 'INVALID']
    e = useSelectedValueToOptionMapping(d, b, {
        optionKey: 'label',
        defaultValueMapping: (value) => `${value} not found`
    })
    console.log(e) -> [{ value: 'a', label: 'Foo' }, 'INVALID not found']

PARAMS:
{
    selected: PropTypes.arrayOf(PropTypes.string).isRequired

    listOfOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired
    })).isRequired

    options: PropTypes.shape({
        optionKey: PropTypes.string,
        defaultValueMapping: PropTypes.func
    })
}
 */
const useSelectedValueToOptionMapping = (
    selected,
    listOfOptions,
    { optionKey = 'value', defaultValueMapping } = {}
) => {
    const resourceMapping = useMemo(
        () =>
            listOfOptions.reduce((mapping, option) => {
                const uniqueVal = option[optionKey];
                mapping[uniqueVal] = { ...option };
                mapping[uniqueVal].id = option.value;

                return mapping;
            }, {}),
        [listOfOptions, optionKey]
    );

    return useMemo(() => {
        const result = selected.map(
            value => resourceMapping[value] || (defaultValueMapping ? defaultValueMapping(value) : null)
        );

        return result.filter(val => val);
    }, [selected, resourceMapping, defaultValueMapping]);
};

export default useSelectedValueToOptionMapping;
