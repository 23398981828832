import { useLayoutEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { configurationsApi } from '../../../../services/api';
import { isDefined } from '../../formData/utils/isDefined';
import FALLBACK_POLICY from '../fallbackPolicy';
import { COMMON_FLAGS, fallbackPolicies, FEATURE_FLAGS } from '../flags';
import { transformConfigFromGW, transformConfigToGW } from '../services/transformers';
import { getConfigKeyData } from '../utils/getConfigKeyData';

const injectPolicy = fallbackPolicies => (feature, configStructure) => ({
    ...configStructure,
    fallbackPolicy: fallbackPolicies[feature] || FALLBACK_POLICY.FULL,
});

const generateConfigRequest = ({ accountId, features, commonFeatures, fallbackPolicies = {}, configKeyData = {} }) => ({
    numericAccountId: accountId,
    publisherConfig: transformConfigToGW(features, configKeyData, injectPolicy(fallbackPolicies)),
    commonConfig: transformConfigToGW(commonFeatures, configKeyData),
});

export const getAccountConfigRequestPayload = account =>
    generateConfigRequest({
        accountId: account.id,
        features: FEATURE_FLAGS,
        commonFeatures: COMMON_FLAGS,
        fallbackPolicies,
        configKeyData: getConfigKeyData(account),
    });

export const fetchAccountConfig = async ({ id, currency }) => {
    const requestPayload = getAccountConfigRequestPayload({ id, currency });

    const { publisherConfig, commonConfig } = await configurationsApi.getConfigByAccountId(id, requestPayload);
    const transformedConfig = {
        selectedAccountConfig: transformConfigFromGW(publisherConfig),
        commonConfig: transformConfigFromGW(commonConfig),
    };
    return transformedConfig;
};

const ACCOUNT_CONFIG_QUERY_KEY = 'ACCOUNT_CONFIG';
const staleTime = 5 * 60 * 1000;
const cacheTime = staleTime;

const emptyConfig = {};

export const useFetchedConfigData = (account, retryCounter = 0) => {
    const accountNumericId = +account?.accountId;
    const failureCounter = useRef(0);

    useLayoutEffect(() => {
        failureCounter.current = 0;
    }, [accountNumericId]);

    const { data, isSuccess, isLoading, isError } = useQuery(
        [ACCOUNT_CONFIG_QUERY_KEY, accountNumericId],
        async () => {
            try {
                const config = await fetchAccountConfig({ ...account, id: accountNumericId });
                return config;
            } catch (error) {
                failureCounter.current++;
                throw error;
            }
        },
        {
            enabled: !!account?.isAccountFetched && isDefined(accountNumericId),
            staleTime,
            cacheTime,
            retry: retryCounter,
        }
    );

    const result = useMemo(
        () => ({
            isReady: isSuccess,
            isLoading,
            isError: isError && failureCounter.current >= retryCounter,
            selectedAccountConfig: data?.selectedAccountConfig || emptyConfig,
            commonConfig: data?.commonConfig || emptyConfig,
        }),
        [isSuccess, isLoading, isError, data, retryCounter]
    );

    return result;
};
