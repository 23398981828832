import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';

const reportId = reportsBaseConfig[REPORT_TYPE.SCHEDULED_REPORTS].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.ACCOUNT_DESCRIPTION.field,
        FIELDS.REPORT_NAME.field,
        FIELDS.FREQUENCY.field,
        FIELDS.DATE_RANGE.field,
        FIELDS.SENT_TO.field,
        FIELDS.LAST_MODIFY_BY.field,
        FIELDS.LAST_MODIFY_ON_UPDATE_TIME.field,
    ].map(field => generateColumnId(reportId, field)),
};

const defaultColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.REPORT_NAME.field,
    FIELDS.FREQUENCY.field,
    FIELDS.DATE_RANGE.field,
    FIELDS.SENT_TO.field,
    FIELDS.LAST_MODIFY_BY.field,
    FIELDS.LAST_MODIFY_ON_UPDATE_TIME.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
