import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_METHODS } from '../../config';
import { BulkUrlsField } from '../BulkUrlsField';
import UrlsFieldTooltip from '../UrlsField/UrlsFieldTooltip';
import CrawlUrlsCheckbox from './CrawlUrlsCheckbox/CrawlUrlsCheckbox';

const OneByOneFields = ({ urls, shouldCrawlUrls, updateUrls, updateShouldCrawlUrls, updateValidatedUrls }) => (
    <div>
        <BulkUrlsField
            urls={urls}
            onUrlsChange={updateUrls}
            updateValidatedUrls={updateValidatedUrls}
            contentMethod={CONTENT_METHODS.ONE_BY_ONE}
            HelpTooltipComponent={UrlsFieldTooltip}
        />
        <CrawlUrlsCheckbox shouldCrawlUrls={shouldCrawlUrls} onChange={updateShouldCrawlUrls} />
    </div>
);

OneByOneFields.propTypes = {
    urls: PropTypes.string,
    shouldCrawlUrls: PropTypes.bool,
    updateUrls: PropTypes.func,
    updateShouldCrawlUrls: PropTypes.func,
    updateOneByOneValidatedUrls: PropTypes.func,
};

export default OneByOneFields;
