import React from 'react';
import { OkOIcon, BlockIcon, TOOLTIP_POSITION, TooltipV2 as Tooltip, AlertOIcon } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CustomAudiencesRejectedStatusTooltip } from '../../../../../../audiences/components/AudiencesReportCustomCellRenderers/CustomAudiencesRejectedStatusTooltip';
import { MY_AUDIENCES_TYPES } from '../../../config/MyAudiencesTypes';
import TargetingTypes from '../../../config/TargetingTypes';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import { CRMLookalikeSimilarityRangeSlider } from '../SimilarityRangeSlider/CRMLookalikeSimilarityRangeSlider';
import { PixelBasedPredictiveSimilarityRangeSlider } from '../SimilarityRangeSlider/PixelBasedPredictiveSimilarityRangeSlider';
import { PixelLookalikeSimilarityRangeSlider } from '../SimilarityRangeSlider/PixelLookalikeSimilarityRangeSlider';
import { isPredictiveAudience } from '../utils/MyAudienceUtils';
import styles from '../../commonAudienceListItemContent.module.scss';
import commonStyles from '../../commonAudienceListItemContent.module.scss';

const sizeTooltip = (
    <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow duration={0} delay={800}>
        <FormattedMessage
            id="campaign.editor.targeting.my.audiences.size.tooltip.size"
            defaultMessage="The number of users in this audience, who had visible activity in the selected countries in the last 30 days. Does not take into account other campaign restrictions such as platform, blocked publishers etc."
        />
    </Tooltip>
);

const lookalikeAudienceToSliderComponent = {
    [MY_AUDIENCES_TYPES.PIXEL_BASED_PREDICTIVE]: PixelBasedPredictiveSimilarityRangeSlider,
    [MY_AUDIENCES_TYPES.PIXEL_LOOKALIKE]: PixelLookalikeSimilarityRangeSlider,
    [MY_AUDIENCES_TYPES.CRM_LOOKALIKE]: CRMLookalikeSimilarityRangeSlider,
};

export const MyAudiencesListItem = ({ item, deleteItem, index, indicationType, message, isRejectedAudience }) => {
    const { targetingType, label, audienceType, audienceSize, similarityLevelToSize, value, id } = item;

    const handleOnClick = () => {
        deleteItem(value, targetingType);
    };

    const { value: similarityLevel, onChange: onSimilarityChange } = useFormFieldValue({
        field: 'similarityLevel',
    });
    const size =
        isPredictiveAudience(audienceType) && similarityLevel ? similarityLevelToSize[similarityLevel] : audienceSize;
    const LookalikeSimilarityRangeSlider = lookalikeAudienceToSliderComponent[audienceType];

    return (
        <div className={styles['wrapper']}>
            <div className={styles['container']}>
                <span>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={styles['include']} />
                    ) : (
                        <BlockIcon className={styles['exclude']} />
                    )}
                </span>
                <AudienceDetails
                    name={label}
                    detail={audienceType}
                    size={size}
                    showRemoveButton
                    handleOnClick={handleOnClick}
                    minSize={100}
                    sizeTooltip={sizeTooltip}
                />
                {indicationType && (
                    <span className={commonStyles['error-icon-wrapper']} aria-label={`${label} ${indicationType} icon`}>
                        <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                        <Tooltip delay={800} arrow interactive>
                            {isRejectedAudience ? (
                                <CustomAudiencesRejectedStatusTooltip id={id} target="_blank" />
                            ) : (
                                message
                            )}
                        </Tooltip>
                    </span>
                )}
            </div>
            {LookalikeSimilarityRangeSlider && (
                <LookalikeSimilarityRangeSlider
                    audienceType={audienceType}
                    similarityLevel={similarityLevel}
                    onSimilarityChange={onSimilarityChange}
                />
            )}
        </div>
    );
};
