'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _MissingFeature = require('./MissingFeature');

var _MissingFeature2 = _interopRequireDefault(_MissingFeature);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _MissingFeature2.default; /**
                                             * Created by dotan.l on 11/04/2019.
                                             */