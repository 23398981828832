import React, { useEffect } from 'react';
import { FormField, Radio, RadioGroup, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useAvailableItems } from '../../../../../../hooks';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData/hooks';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config';
import { DELIVERY_METHODS } from '../../../common-campaign-form/config';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const DELIVERY_METHODS_OPTIONS = [
    {
        value: DELIVERY_METHODS.BALANCED,
        title: <FormattedMessage id="campaign.editor.delivery.method.balanced" defaultMessage="Balanced" />,
        description: (
            <FormattedMessage
                id="video.campaign.editor.delivery.method.balanced.description"
                defaultMessage="Spend budget evenly across the selected timeframe."
            />
        ),
        enableCondition: ({ endDate }) => endDate,
    },
    {
        value: DELIVERY_METHODS.ACCELERATED,
        title: <FormattedMessage id="campaign.editor.delivery.method.accelerated" defaultMessage="Accelerated" />,
        description: (
            <FormattedMessage
                id="campaign.editor.delivery.method.accelerated.description"
                defaultMessage="Spend your budget more quickly. This may cause your budget to run out early."
            />
        ),
    },
];

export const DeliveryMethod = () => {
    const {
        value: endDate,
        isDirty: endDateChanged,
        mode,
    } = useFormFieldValue({
        field: 'endDate',
    });

    const options = useAvailableItems(DELIVERY_METHODS_OPTIONS, { endDate });

    const { value: selection, onChange } = useFormFieldValue({
        field: 'videoBudget.deliveryMethod',
    });

    useEffect(() => {
        if (!endDate && (mode !== FORM_MODES.EDIT || endDateChanged)) {
            onChange(DELIVERY_METHODS.ACCELERATED);
        }
    }, [onChange, endDate, endDateChanged, mode]);

    return (
        <FormField
            label={<FormattedMessage id="campaign.editor.delivery.method.title" defaultMessage="Delivery Method" />}
            containerClass={styles['input']}
            inputId="deliveryMethod"
        >
            <RadioGroup name="delivery-method" onChange={onChange} selectedValue={selection}>
                {options.map(({ value, title, description, disabled }) => (
                    <div data-testid="radio-group-container">
                        <Radio
                            key={`delivery-method-${value}`}
                            value={value}
                            title={title}
                            description={description}
                            disabled={disabled}
                        />
                        {disabled && (
                            <Tooltip
                                position={TOOLTIP_POSITION.BOTTOM_START}
                                duration={0}
                                delay={800}
                                arrow
                                interactive
                            >
                                <FormattedMessage
                                    id="video.campaign.editor.deliveryMethod.option.balanced.disabled.tiptop"
                                    defaultMessage="Choosing Balanced Delivery Method is not supported when the campaign has no end date. To select Balanced Delivery Method please enter an end date."
                                />
                            </Tooltip>
                        )}
                    </div>
                ))}
            </RadioGroup>
        </FormField>
    );
};

export default DELIVERY_METHODS_OPTIONS;
