import { StatusIndicator } from 'tuui';

export const STATUS_TYPE = {
    SUCCESS: 'SUCCESS',
    DANGER: 'DANGER',
    INFO: 'INFO',
    WARNING: 'WARNING',
};

/** Given a status config and a status, returns the statusType for this status */
export const getStatusType = (statusConfig, status) => {
    const config = statusConfig[status] || {};
    return config.statusType;
};

const statusIndicatorTypeMap = {
    [STATUS_TYPE.SUCCESS]: StatusIndicator.type.success,
    [STATUS_TYPE.DANGER]: StatusIndicator.type.error,
    [STATUS_TYPE.WARNING]: StatusIndicator.type.warning,
};

export const getStatusIndicatorType = statusType => statusIndicatorTypeMap[statusType];
