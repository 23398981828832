import React from 'react';
import classnames from 'classnames/bind';
import { CircleProgressIndicator } from 'tuui';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useGetOnboardingStatus } from '../hooks';
import { HeaderSection } from './HeaderSection';
import styles from './onboardingSummary.module.scss';

const classNameBuilder = classnames.bind(styles);

export const OnboardingSummary = ({ isCollapsed, progressPercent }) => {
    const onboardingStatus = useGetOnboardingStatus(progressPercent);
    return (
        <div className={styles['container']}>
            <HeaderSection progressPercent={progressPercent} isCollapsed={isCollapsed} />
            <div className={classNameBuilder('completion-status', { hidden: isCollapsed })}>
                {onboardingStatus && <h2 className={styles['status']}>{onboardingStatus}</h2>}
                <div className={styles['circle-progress-container']}>
                    <CircleProgressIndicator progress={progressPercent} strokeWidth={40} />
                    <div className={styles['percentage']}>
                        <FormattedNumber value={progressPercent / 100} variant="percent" />
                    </div>
                </div>
            </div>
        </div>
    );
};
