'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'moreAndLessButton__taboola-svg-icon___1BhU8',
    'container': 'moreAndLessButton__container___2JY9v',
    'button': 'moreAndLessButton__button___2jOWW',
    'total': 'moreAndLessButton__total___2rZOv'
};


var MoreAndLessButton = function MoreAndLessButton(_ref) {
    var isCollapsible = _ref.isCollapsible,
        showAll = _ref.showAll,
        showAllLabel = _ref.showAllLabel,
        showLessLabel = _ref.showLessLabel,
        totalItems = _ref.totalItems,
        onClick = _ref.onClick;

    if (!isCollapsible) {
        return undefined;
    }

    return _react2.default.createElement(
        'div',
        {
            key: 'show-more-button',
            className: styles['container']
        },
        _react2.default.createElement(
            'div',
            {
                role: 'button',
                type: 'button',
                tabIndex: 0,
                onClick: onClick,
                className: styles['button']
            },
            showAll ? showLessLabel : _react2.default.createElement(
                _react2.default.Fragment,
                null,
                showAllLabel,
                _react2.default.createElement(
                    'span',
                    { className: styles['total'] },
                    '(' + totalItems + ')'
                )
            )
        )
    );
};

MoreAndLessButton.propTypes = {
    isCollapsible: _propTypes2.default.bool,
    showAll: _propTypes2.default.bool,
    showLessLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    showAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    totalItems: _propTypes2.default.number,
    onClick: _propTypes2.default.func
};

exports.default = MoreAndLessButton;