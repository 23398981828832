import {
    DEFAULT_CAMPAIGN_ID,
    DEFAULT_CREATIVE_ID,
    FIELD_TYPES,
} from 'modules/campaigns/components/ContentEditableText/services';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { dynamicKeywordInsertionRegEx, whitelistedEmojiRegex } from 'services/utils/regExUtils';
import { isEmojiInString } from 'services/utils/stringUtils';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';
import { getAutoReviewValidationResults, getRejectReasonFromResults } from './autoReviewValidationUtils';

const maxCreativeTitleLength = 250;

const getContentDescriptionValidationError = results => {
    const reason = getRejectReasonFromResults(results);

    if (!reason) {
        return true;
    }

    throw new CustomValidationError({
        messageCode: `app.campaigns.campaign.status.reject.reason.${reason}`,
        message: 'This description is not compliant with our policy, please replace it',
    });
};

const contentDescriptionValidation = {
    validationFn: async (
        value,
        _,
        { isContentValidationEnabled, accountId, campaignId = DEFAULT_CAMPAIGN_ID, creativeId = DEFAULT_CREATIVE_ID }
    ) => {
        if (!isContentValidationEnabled || validationFunctions.isEmpty(value) || accountId === undefined) {
            return true;
        }

        return getAutoReviewValidationResults(
            {
                accountId,
                campaignId,
                fieldToValidate: FIELD_TYPES.DESCRIPTION,
                item: {
                    id: creativeId,
                    description: value,
                },
            },
            getContentDescriptionValidationError
        );
    },
    messageId: 'validations.error.field.content.description.not.compliant.with.policy',
    defaultMessage: 'This description is not compliant with our policy, please replace it',
};

const bulkDescriptionContentValidation = {
    validationFn: async (value, _, { isContentValidationEnabled, validatedDescriptions }) => {
        if (!isContentValidationEnabled || validationFunctions.isEmpty(value)) {
            return true;
        }
        return getContentDescriptionValidationError([validatedDescriptions[value]]);
    },
    messageId: 'validations.error.field.content.description.not.compliant.with.policy',
    defaultMessage: 'This description is not compliant with our policy, please replace it',
};

const baseCreativeDescriptionValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: maxCreativeTitleLength },
        messageId: 'validations.error.creative.description.length',
    },
    {
        validationFn: description => !description.match(dynamicKeywordInsertionRegEx),
        messageId: 'validations.error.field.description.dynamicKeywordInjection',
        defaultMessage: 'Dynamic Keyword Insertion is not supported for ad Descriptions.',
    },
    {
        validationFn: description => !isEmojiInString(description, whitelistedEmojiRegex),
        messageId: 'validations.error.field.description.emoji',
        defaultMessage: 'Emojis are not supported for ad description.',
    },
];

export const creativeDescriptionValidations = [...baseCreativeDescriptionValidations, contentDescriptionValidation];
export const csvCreativeDescriptionValidations = [
    ...baseCreativeDescriptionValidations,
    bulkDescriptionContentValidation,
];
