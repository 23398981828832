import React from 'react';
import PropTypes from 'prop-types';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { FORM_MODES, REPORT_TYPE } from 'modules/campaigns/config';
import useCampaignBreadcrumbs from 'modules/campaigns/hooks/useCampaignBreadcrumbs';
import { FormBreadcrumbs } from 'modules/campaigns/modules/common-campaign-form';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import CampaignsGroupBudgetSection from '../../components/CampaignsGroupBudgetSection/CampaignsGroupBudgetSection';
import { CampaignsGroupCreatorFormButtons } from '../../components/CampaignsGroupCreatorFormButtons/CampaignsGroupCreatorFormButtons';
import CampaignsGroupScheduleSection from '../../components/CampaignsGroupScheduleSection/CampaignsGroupScheduleSection';
import CampaignsGroupSetupSection from '../../components/CampaignsGroupSetupSection/CampaignsGroupSetupSection';
import { SharedBudgetCampaignsSection } from '../../components/SharedBudgetCampaignsSection/SharedBudgetCampaignsSection';
import { campaignsGroupInitialValues } from '../../config';
import { useCampaignsGroupCreatorState } from '../../hooks';
import { useCampaignGroupExtensionEnabled } from '../../hooks/useCampaignGroupExtensionEnabled';
import styles from './campaignsGroupCreator.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const CampaignsGroupCreator = ({ onCancel }) => {
    const { submit, submitAndNavigateCampaign } = useCampaignsGroupCreatorState();
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'campaigns.group.breadcrumbs.create', defaultMessage: 'New Group' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGNS_GROUPS,
        title,
    });
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={0}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="campaign.group.creator.campaign.settings.step.label"
                            defaultMessage="Group Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaigns.group.editor.setup" defaultMessage="Group Setup" />}
                            id="setup"
                        >
                            <CampaignsGroupSetupSection mode={FORM_MODES.CREATE} />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="campaigns.group.editor.schedule"
                                    defaultMessage="Group Schedule"
                                />
                            }
                            id="schedule"
                        >
                            <CampaignsGroupScheduleSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage id="campaigns.group.editor.budget" defaultMessage="Group Budget" />
                            }
                            id="budget"
                        >
                            <CampaignsGroupBudgetSection />
                        </WizardStep>
                        {!campaignGroupExtensionEnabled && (
                            <WizardStep
                                label={
                                    <FormattedMessage id="campaigns.group.shared.budgets" defaultMessage="Campaigns" />
                                }
                                id="sharedBudgets"
                            >
                                <SharedBudgetCampaignsSection />
                            </WizardStep>
                        )}

                        <CampaignsGroupCreatorFormButtons
                            onSubmit={submit}
                            onSubmitNavigateCampaign={submitAndNavigateCampaign}
                            onCancel={onCancel}
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

CampaignsGroupCreator.propTypes = { onCancel: PropTypes.func };

const CampaignsGroupCreatorWithFormDataProvider = withFormDataProvider(CampaignsGroupCreator, {
    defaultValuesConfig: campaignsGroupInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { CampaignsGroupCreatorWithFormDataProvider as CampaignsGroupCreator };
