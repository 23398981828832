import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_ID } from './useSelectedReportId';

export const REPORT_VIEW_NAME_COMFORTABLE = 'reportViewComfortable';

const useReportViewComfortableParamInner = queryParamHookFactory(REPORT_VIEW_NAME_COMFORTABLE, {
    deserializer: value => {
        if (!value) {
            return;
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {}
    },
    persist: PERSISTENCE_TYPE.LOCAL_STORAGE,
    dependencies: {
        queryParams: [REPORT_ID],
    },
});

export const useReportComfortableViewParam = () => {
    const [value = true, ...rest] = useReportViewComfortableParamInner();

    return [value, ...rest];
};
