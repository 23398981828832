'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TextRadioButton = require('./TextRadioButton');

var _TextRadioButton2 = _interopRequireDefault(_TextRadioButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TextRadioButton2.default;