import React from 'react';
import { AI_VARIATIONS_FIELD_NAMES } from '../../../constants/aiVariationsConstants';
import { VariationsPreviewSection } from '../../VariationsCreation/components/VariationsPreviewSection';

export const AiVariationsPreviewSection = () => (
    <VariationsPreviewSection
        creativePreviewsFieldName={AI_VARIATIONS_FIELD_NAMES.CREATIVE_PREVIEWS}
        formContentFieldName={AI_VARIATIONS_FIELD_NAMES.CONTENT}
    />
);
