'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.StepperButton = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Button = require('../Button/Button');

var _Button2 = _interopRequireDefault(_Button);

var _hoc = require('../../hoc');

var _TooltipV = require('../../Tooltips/TooltipV2/TooltipV2');

var _TooltipV2 = _interopRequireDefault(_TooltipV);

var _StyledButton = require('../StyledButton');

var _Icons = require('../../Icons/Icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'stepperButton__taboola-svg-icon___3w1T0',
    'container': 'stepperButton__container___daro-',
    'disabled': 'stepperButton__disabled___I337F',
    'button': 'stepperButton__button___PlnKR',
    'down-button': 'stepperButton__down-button___OGg3e',
    'up-button': 'stepperButton__up-button___3BZHE',
    'content': 'stepperButton__content___3BHNy'
};
var StepperButton = exports.StepperButton = function StepperButton(_ref) {
    var downIcon = _ref.downIcon,
        downButtonClassName = _ref.downButtonClassName,
        onStepDown = _ref.onStepDown,
        downDisabled = _ref.downDisabled,
        downTooltipProps = _ref.downTooltipProps,
        upIcon = _ref.upIcon,
        upButtonClassName = _ref.upButtonClassName,
        onStepUp = _ref.onStepUp,
        upDisabled = _ref.upDisabled,
        upTooltipProps = _ref.upTooltipProps,
        disabled = _ref.disabled,
        children = _ref.children,
        className = _ref.className,
        classes = _ref.classes;

    var containerCls = (0, _classnames2.default)(classes.main, className);
    var downBtnCls = (0, _classnames2.default)(classes.button, classes.downButton, downButtonClassName);
    var upBtnCls = (0, _classnames2.default)(classes.button, classes.upButton, upButtonClassName);
    var contentCls = (0, _classnames2.default)(classes.content, _defineProperty({}, classes.contentDisabled, disabled));

    return _react2.default.createElement(
        'div',
        { className: containerCls },
        _react2.default.createElement(
            _Button2.default,
            {
                className: downBtnCls,
                iconBefore: downIcon,
                type: _StyledButton.STYLED_BUTTON_TYPE.GHOST,
                size: _StyledButton.STYLED_BUTTON_SIZE.SMALL,
                disabled: downDisabled || disabled,
                onClick: onStepDown
            },
            downTooltipProps && _react2.default.createElement(_TooltipV2.default, downTooltipProps)
        ),
        children && _react2.default.createElement(
            'div',
            { className: contentCls },
            children
        ),
        _react2.default.createElement(
            _Button2.default,
            {
                className: upBtnCls,
                iconBefore: upIcon,
                type: _StyledButton.STYLED_BUTTON_TYPE.GHOST,
                size: _StyledButton.STYLED_BUTTON_SIZE.SMALL,
                disabled: upDisabled || disabled,
                onClick: onStepUp
            },
            upTooltipProps && _react2.default.createElement(_TooltipV2.default, upTooltipProps)
        )
    );
};

StepperButton.classNames = {
    main: 'container',
    button: 'button',
    downButton: 'down-button',
    upButton: 'up-button',
    content: 'content',
    contentDisabled: 'disabled'
};

StepperButton.propTypes = {
    /** Icon for the down button */
    downIcon: _propTypes2.default.node,
    /** Apply class to style the down button */
    downButtonClassName: _propTypes2.default.string,
    /** Step down callback */
    onStepDown: _propTypes2.default.func,
    /** Disable / Enable down button flag */
    downDisabled: _propTypes2.default.bool,
    /** object props for tooltip for down button */
    downTooltipProps: _propTypes2.default.object,
    /** Icon for the up button */
    upIcon: _propTypes2.default.node,
    /** Apply class to style the up button */
    upButtonClassName: _propTypes2.default.string,
    /** Step up callback */
    onStepUp: _propTypes2.default.func,
    /** Disable / Enable up button flag */
    upDisabled: _propTypes2.default.bool,
    /** object props for tooltip for up button */
    upTooltipProps: _propTypes2.default.object,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Element inside the button */
    children: _propTypes2.default.node,
    /** Apply class to style the button */
    className: _propTypes2.default.string,
    /** Stylesheet to apply to the component */
    stylesheet: _propTypes2.default.object,
    classes: _propTypes2.default.object
};

StepperButton.defaultProps = {
    className: '',
    downButtonClassName: '',
    upButtonClassName: '',
    downIcon: _react2.default.createElement(_Icons.SmallMinusIcon, null),
    onStepDown: function onStepDown() {},
    upIcon: _react2.default.createElement(_Icons.SmallPlusIcon, null),
    onStepUp: function onStepUp() {},
    classes: StepperButton.classNames
};

exports.default = (0, _hoc.withMergedStyles)(StepperButton, styles);