import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import { gridNumberFormatter, formattedNumberValueGetter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const countryReportColumnDefinition = [
    {
        headerName: FIELDS.COUNTRY_CODE.label,
        field: FIELDS.COUNTRY_CODE.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.COUNTRY_CODE.cellRendererFramework,
        cellRendererParams: FIELDS.COUNTRY_CODE.renderParams,
        csvValueGetter: FIELDS.COUNTRY_CODE.csvValueGetter,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.VCTR,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(countryReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.COUNTRY].id);
