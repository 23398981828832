import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import { SuccessIcon } from 'tuui';
import {
    INDICATION_TYPES,
    MediaGalleryItem,
    TOOLTIP_POSITION,
    TooltipV2 as Tooltip,
    WarningCircleIcon,
} from 'taboola-ultimate-ui';
import { thumbnailAutoReviewValidations } from 'modules/campaigns/config/validations/creative';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import styles from './validatedMediaGalleryItem.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ValidatedMediaGalleryItem = ({ index, ...rest }) => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });
    const validations = useMemo(() => {
        if (!isContentValidationEnabled) {
            return [];
        }

        return thumbnailAutoReviewValidations;
    }, [isContentValidationEnabled]);
    const validationDependencies = useMemo(
        () => ({
            accountId,
        }),
        [accountId]
    );

    const { scrollRef, indicationData, onChange, value } = useFormValidatedValue({
        field: 'value',
        validations,
        validationDependencies,
    });

    const { value: cropped } = useFormFieldValue({ field: 'cropped' });

    useEffect(() => {
        if (!value || !onChange) {
            return;
        }

        onChange(value);
    }, [value, onChange]);

    const failedValidationIndicationType = indicationData?.indicationType;
    const hasValidationError = failedValidationIndicationType === INDICATION_TYPES.ERROR;
    const hasValidationWarning = failedValidationIndicationType === INDICATION_TYPES.WARNING;

    return (
        <MediaGalleryItem
            {...rest}
            {...indicationData}
            className={styles[`container-${failedValidationIndicationType}`]}
            role="cell"
        >
            <span ref={scrollRef} />
            {(hasValidationError || hasValidationWarning) && (
                <div className={styles['badge-container']} aria-label="media-gallery-item-error">
                    <WarningCircleIcon
                        className={classNameBuilder('badge', {
                            error: hasValidationError,
                            warning: hasValidationWarning,
                        })}
                    />
                    <Tooltip position={TOOLTIP_POSITION.RIGHT}>{indicationData?.message}</Tooltip>
                </div>
            )}
            {cropped && (
                <div className={styles['cropped']}>
                    <SuccessIcon />
                    <FormattedMessage id="video.creative.creator.media.upload.file.cropped" defaultMessage="Cropped" />
                </div>
            )}
        </MediaGalleryItem>
    );
};
