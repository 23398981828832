import { ExtendedComponent } from 'tuui/lib/utils/typing';
import { Button as Base, ButtonProps } from './Button';

export const Button: ExtendedComponent<typeof Base, ButtonProps> = Object.assign(Base, {
    variant: { primary: 'primary', outline: 'outline', ghost: 'ghost', text: 'text' },
    size: { lg: 'lg', md: 'md', sm: 'sm' },
    displayName: 'Button',
} as const);

export type { ButtonProps } from './Button';
