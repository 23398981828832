import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { useAccount, useIsAppAccountNetwork } from 'modules/taboola-common-frontend-modules/account-configurations';
import { AllCampaignsId, getDefaultReportId } from '../modules/campaigns/config';
import { CAMPAIGN_ID } from '../modules/campaigns/hooks';
import { REPORT_ID } from '../modules/campaigns/modules/campaigns-reports/hooks';
import { userSelector } from '../modules/taboola-common-frontend-modules/authentication';
import { isIframeMode } from '../modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { ACCOUNT_ID, useDimension } from './queryParams';
import { useAccountAdditionalData } from './useAccountAdditionalData';

export const MAX_CHARACTER_COUNT = 60;

const useBreadcrumbsAccountStep = moduleRoute => {
    const dimension = useDimension();
    const { accountAdditionalData = {} } = useAccountAdditionalData();
    const { parentNetworkAccountId, parentNetworkAccountName, parentNetworkAccountDescription } = accountAdditionalData;
    const { name: selectedAccountName, id: selectedAccountId, accountName: selectedAccountAccountName } = useAccount();
    const { account_id: userAccountId } = useSelector(userSelector);
    const isAppAccountNetwork = useIsAppAccountNetwork();

    const skipNetworkStep = userAccountId === selectedAccountAccountName && !isAppAccountNetwork;

    const networkStep = useMemo(() => {
        if (isNil(parentNetworkAccountId) || skipNetworkStep) {
            return null;
        }

        return {
            label: parentNetworkAccountDescription || parentNetworkAccountName,
            path: moduleRoute,
            params: {
                [ACCOUNT_ID]: parentNetworkAccountId,
                [CAMPAIGN_ID]: AllCampaignsId,
                [REPORT_ID]: getDefaultReportId(dimension),
            },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };
    }, [
        parentNetworkAccountId,
        skipNetworkStep,
        parentNetworkAccountDescription,
        parentNetworkAccountName,
        moduleRoute,
        dimension,
    ]);

    const accountStep = useMemo(() => {
        return {
            label: selectedAccountName,
            path: moduleRoute,
            params: {
                [ACCOUNT_ID]: selectedAccountId,
                [CAMPAIGN_ID]: AllCampaignsId,
                [REPORT_ID]: getDefaultReportId(dimension),
            },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };
    }, [selectedAccountName, moduleRoute, selectedAccountId, dimension]);

    const steps = useMemo(() => {
        if (isIframeMode()) {
            return [];
        }
        const baseSteps = networkStep ? [networkStep] : [];
        baseSteps.push(accountStep);
        return baseSteps;
    }, [networkStep, accountStep]);

    return steps;
};

export default useBreadcrumbsAccountStep;
