import { useEffect } from 'react';
import { pushRBoxListener } from '../utils';

const useRBoxListenerEffect = (type, callback) => {
    useEffect(() => {
        window.pushedRBoxListeners = window.pushedRBoxListeners || {};
        if (!window.pushedRBoxListeners[type]) {
            pushRBoxListener(type, callback);
            window.pushedRBoxListeners[type] = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);
};

export default useRBoxListenerEffect;
