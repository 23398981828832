import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from '../topicsListField.module.scss';

export const ListLoadingIndicator = () => {
    return (
        <div className={styles['loading-container']}>
            <ContentLoader height={3} width={112} speed={2} className={styles['loading']} />
            <ContentLoader height={3} width={112} speed={2} className={styles['loading']} />
            <ContentLoader height={3} width={112} speed={2} className={styles['loading']} />
            <ContentLoader height={3} width={112} speed={2} className={styles['loading']} />
        </div>
    );
};
