export const campaignNeverUploadPOJOFields = [
    'status',
    'dimension',
    'demandType',
    'advertiserDescription',
    'campaignTargeting.contextualSegmentsTargeting.validTargeting',
    'campaignTargeting.contextualSegmentsTargeting.ignoreDefaultExclude',
    'campaignTargeting.marketplaceAudienceTargeting.validTargeting',
    'campaignTargeting.marketplaceAudienceTargeting.ignoreDefaultExclude',
    'campaignTargeting.customAudienceTargeting.validTargeting',
    'campaignTargeting.customAudienceTargeting.ignoreDefaultExclude',
    'campaignTargeting.customContextualTargeting.validTargeting',
    'campaignTargeting.customContextualTargeting.ignoreDefaultExclude',
    'campaignTargeting.lookalikeAudienceTargeting.validTargeting',
    'campaignTargeting.lookalikeAudienceTargeting.ignoreDefaultExclude',
    'conversionRulesMode',
    'campaignSchedule.mode',
    'campaignItemType',
    'budget.deliveryMethod',
    'isPartOfSharedBudgetCampaignsGroup',
    'campaignSchedule.isMultipleSlicesPerDay',
    'dayTimeBidModifier.mode',
    'platformBidModifier.mode',
    // These fields potentially may be excluded
    'campaignProfile',
    'thirdPartyTags',
    'policyState',
    'funnel',
    'blockedSitesOnAccountLevel',
    'segmentExplorations',
    'conversionConfiguration',
    'isSpendGuardActive',
    'creativeTrafficAllocationABTestStartTime',
    'minExpectedConversionsForCpaGoal',
    'targetCpaData',
    'roasGoal',
    'hasEndDate',
    'comments',
    'adsConsoleBidModifications',
    'premiumSiteTargeting',
    'thirdPartyBrandSafetyTargeting',
    'campaignTargeting.isMarketplaceAudiencesAndLogicEnabled',
    'campaignTargeting.userFrequencyCapping',
    'campaignTargeting.autoPublisherTargeting',
    'campaignTargeting.connectionTypeTargeting',
    'campaignTargeting.highImpactPackageTargetingId',
    'isApplyCapActive',
];
