import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { isNetworkAccountType } from '../../../modules/account-management';
import { AccountItemIcon } from '../AccountItemIcon/AccountItemIcon';
import styles from './accountPickerSelectedItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const AccountPickerSelectedItem = ({ data, className }) => {
    const { name, type, id } = data;
    const isNetworkAccount = isNetworkAccountType(type);
    const customType = isNetworkAccount ? 'network' : 'account';

    const { formatMessage } = useIntl();
    const description = formatMessage(
        {
            id: 'account.picker.selected.item.description',
            defaultMessage: '{type} ID: {id}',
        },
        {
            type: customType,
            id,
        }
    );

    return (
        <div className={classNameBuilder('selected-item', className)}>
            <AccountItemIcon {...data} className={styles['icon']} />
            {name && <Tooltip delay={250}>{name}</Tooltip>}
            <div className={styles['label']} data-testid="account-name">
                {name}
            </div>
            <div className={styles['description']}>{description}</div>
        </div>
    );
};

AccountPickerSelectedItem.propTypes = {
    data: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    className: PropTypes.string,
};

export default AccountPickerSelectedItem;
