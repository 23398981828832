import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedRelative } from '../../../../../taboola-common-frontend-modules/i18n/index';

class FormattedRelativeCellRenderer extends Component {
    render() {
        const { value } = this.props;
        return <FormattedRelative value={value} />;
    }
}

FormattedRelativeCellRenderer.propTypes = {
    /** value which is injected by the grid */
    value: PropTypes.instanceOf(Date),
};

export default FormattedRelativeCellRenderer;
