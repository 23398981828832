import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useBillingApi } from '../../../services/api';
import { COMMON_FLAGS, useCommonConfig } from '../../taboola-common-frontend-modules/account-configurations';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { PAYMENT_PROVIDER_NAME } from '../config/PaymentProviderName';
import { PAYMENT_TYPE } from '../config/PaymentType';

export const usePaymentProviderAccount = ({ paymentMethodType }) => {
    const { accountName } = useAccount();
    const { [COMMON_FLAGS.STRIPE_PUBLIC_API_KEY]: stripeApiKey } = useCommonConfig(COMMON_FLAGS.STRIPE_PUBLIC_API_KEY);

    const { createSetupIntent: createSetupIntentApi, getPaymentMethodRequest: getPaymentMethodRequestApi } =
        useBillingApi();

    const paymentTypeToPaymentMethodRequest = useMemo(
        () => ({
            [PAYMENT_TYPE.CARD]: {
                paymentMethodRequest: createSetupIntentApi,
                callback: data => data,
            },
            [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: {
                paymentMethodRequest: getPaymentMethodRequestApi,
                callback: data => ({
                    stripeAccountId: data?.additionalData.stripeAccountId,
                    clientSecret: data?.additionalData.clientSecret,
                    setupIntentId: data?.additionalData.paymentMethodRequestId,
                }),
            },
        }),
        [createSetupIntentApi, getPaymentMethodRequestApi]
    );

    const getPaymentMethodRequest = paymentTypeToPaymentMethodRequest[paymentMethodType]?.paymentMethodRequest;
    const getPaymentMethodRequestCallback = paymentTypeToPaymentMethodRequest[paymentMethodType]?.callback;

    const createSetupIntent = useCallback(
        async args => {
            const providerData = await getPaymentMethodRequest(args);
            return { ...getPaymentMethodRequestCallback(providerData), stripeApiKey };
        },
        [getPaymentMethodRequest, getPaymentMethodRequestCallback, stripeApiKey]
    );

    const {
        data = {},
        isError,
        isSuccess,
        isLoading,
    } = useQuery(
        ['getPaymentProviderAccount', PAYMENT_PROVIDER_NAME.STRIPE, accountName],
        () => createSetupIntent({ accountId: accountName }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !!paymentMethodType,
        }
    );

    return {
        ...data,
        isError,
        isSuccess,
        isLoading,
    };
};
