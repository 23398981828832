import React from 'react';
import PropTypes from 'prop-types';
import { isEditCreativeColumnAllowed, isHTMLCardCreative } from '../../config/predicates';
import withEditIndication from './withEditIndication';

const withCreativeEditIndication = WrappedComponent => {
    const ComponentWithEditIndication = withEditIndication(WrappedComponent);

    class CreativeWithEditIndication extends React.Component {
        render() {
            const { data, forwardedRef, ...rest } = this.props;
            const { column } = rest;
            const isEditDisabled = !isEditCreativeColumnAllowed(data, column?.colId) || isHTMLCardCreative(data);
            return (
                <ComponentWithEditIndication {...rest} data={data} isEditDisabled={isEditDisabled} ref={forwardedRef} />
            );
        }
    }

    CreativeWithEditIndication.propTypes = {
        data: PropTypes.object,
        forwardedRef: PropTypes.object,
    };

    return React.forwardRef((props, ref) => <CreativeWithEditIndication {...props} forwardedRef={ref} />);
};

export default withCreativeEditIndication;
