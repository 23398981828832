import React from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { useCampaignGroupExtensionEnabled } from '../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import MarketingObjective from '../MarketingObjective/MarketingObjective';

const CampaignMarketingObjective = () => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const { value: isPartOfSharedBudgetCampaignsGroup } = useFormFieldValue({
        field: 'isPartOfSharedBudgetCampaignsGroup',
    });
    const { formatMessage } = useIntl();

    const helpText = isPartOfSharedBudgetCampaignsGroup
        ? formatMessage({
              id: 'campaign.in.group.disabled.marketing.objective.help',
              defaultMessage:
                  'You cannot adjust your Marketing Objective here because your Campaign belongs to a Campaign Group. Review the relevant Campaign Group to make edits.',
          })
        : formatMessage({
              id: 'campaign.marketing.objective.help',
              defaultMessage:
                  'Based on your marketing objective, Taboola will optimize your campaign for the best possible results.',
          });
    return (
        <MarketingObjective
            disabled={isPartOfSharedBudgetCampaignsGroup && !campaignGroupExtensionEnabled}
            helpText={helpText}
        />
    );
};
export default CampaignMarketingObjective;
