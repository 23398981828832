import React, { forwardRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { CloseIcon } from 'tuui/lib/atoms/Icon';
import { IconButton } from 'tuui/lib/atoms/IconButton/IconButton';
import {
    applyActiveState,
    applyDisabledState,
    applyEnableState,
    TagColor,
    TagSize,
    TagStyledProps,
    TagVariant,
    tagVariants,
} from 'tuui/lib/molecules/Tag/tagVariants';
import { scale, theme } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
    variant?: TagVariant;
    size?: TagSize;
    color?: TagColor;
    disabled?: boolean;

    onRemove?(): void;
}

export const Tag = forwardRef<HTMLDivElement, TagProps>(
    ({ onClick, onRemove, children, variant = 'filled', size = 'md', color = 'secondary', disabled }, ref) => (
        <TagBase
            ref={ref}
            $variant={variant}
            tabIndex={0}
            $color={color}
            $size={size}
            onClick={onClick}
            $disabled={disabled}
            $clickable={!!onClick}
        >
            {children}
            {onRemove && (
                <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    size="lg"
                    variant="text"
                    disabled={disabled}
                >
                    <Icon $variant={variant} />
                </IconButton>
            )}
        </TagBase>
    )
) as UIC<HTMLAttributes<HTMLDivElement> & TagProps>;

const TagBase = styled.div<TagStyledProps>`
    --text: ${({ $variant, ...tagBaseProps }) => tagVariants[$variant].text(tagBaseProps)};
    --fill: ${({ $variant, ...tagBaseProps }) => tagVariants[$variant].fill(tagBaseProps)};
    --fontSize: ${({ $variant, ...tagBaseProps }) => tagVariants[$variant].fontSize(tagBaseProps)};
    --padding: ${({ $variant, ...tagBaseProps }) => tagVariants[$variant].padding(tagBaseProps)};
    --boxShadow: ${({ $variant, ...tagBaseProps }) => tagVariants[$variant].boxShadow(tagBaseProps)};
    border: none;
    background-color: var(--fill);
    color: var(--text);
    user-select: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    column-gap: ${scale('sm')};
    border-radius: ${theme('curves.xl')};
    font-family: ${theme('typography.font')};
    padding: var(--padding);
    cursor: pointer;
    font-size: ${theme('typography.sizes.md')};
    width: fit-content;
    font-size: var(--fontSize);
    white-space: nowrap;
    height: fit-content;
    box-shadow: var(--boxShadow);

    ${applyEnableState()};
    ${applyActiveState()};
    ${applyDisabledState()};
`;

const Icon = styled(CloseIcon)<{ $variant: TagVariant }>`
    color: ${({ theme }) => theme.palette.primary.text};
`;
