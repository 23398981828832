import { useState, useMemo } from 'react';
import { noop } from 'lodash';

const useFocus = (init, { onFocus = noop, onBlur = noop } = {}) => {
    const [focused, setFocused] = useState(init);

    const focusHandlers = useMemo(
        () => ({
            onFocus: e => setFocused(true) || onFocus(e),
            onBlur: e => setFocused(false) || onBlur(e),
        }),
        [setFocused, onFocus, onBlur]
    );

    return [focused, focusHandlers, setFocused];
};

export default useFocus;
