import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const CountryTargetingTooltip = () => (
    <TooltipSection
        tip={
            <FormattedMessage
                id="campaign.editor.geo.targeting.location.help.tip"
                defaultMessage="consider setting up a separate campaign for each country. This allows you to compare results and optimize performance."
            />
        }
    >
        <FormattedMessage
            id="campaign.editor.geo.targeting.location.help.title"
            defaultMessage="With location targeting, you can target specific geographic areas. For example, if you ship only to Canada and France, you can target only those countries. {br} Location accuracy will vary depending on different factors, including, but not limited to, country, device and platform."
        />
    </TooltipSection>
);

export default CountryTargetingTooltip;
