const getDateFormat = timeZoneName => {
    switch (timeZoneName) {
        case 'US/Eastern':
        case 'US/Central':
        case 'US/Pacific':
        case 'US/Mountain':
            return 'MM/DD/YYYY';
        default:
            return 'DD/MM/YYYY';
    }
};

export default getDateFormat;
