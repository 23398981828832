import React, { useMemo, useCallback, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { debounce, last } from 'lodash';
import { CreativeStudioContentBanner } from '../CreativeStudioContentBanner';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { SegmentAnythingGalleryItemDropdown } from './SegmentAnythingGalleryItemDropdown';
import { StageButtons } from './StageButtons';
import { Tool } from './Tool';
import { getClicks, appendToClicks, SELECTION_MODE } from './utils';
import styles from './stage.module.scss';

const classNameBuilder = classnames.bind(styles);

const arePointsEqual = (pointA, pointB) => pointA?.x === pointB?.x && pointA?.y === pointB?.y;

export const Stage = props => {
    const { points, setPoints, history, setHistory, image, bodyClassName, unmountedRef, maskError, setMaskError } =
        props;
    const { originalImage } = useCreativeStudioContext();
    const [selectMode, setSelectMode] = useState(SELECTION_MODE.INCLUDE);

    const id = useRef(0);
    const getId = () => id.current++;
    const clicks = useMemo(() => getClicks(points), [points]);

    const getPoint = useCallback(
        (e, hovered) => {
            let el = e.nativeEvent.target;
            const rect = el.getBoundingClientRect();
            let x = e.clientX - rect.left;
            let y = e.clientY - rect.top;

            // Scaling by the smaller number since flexbox puts a cap on the scale
            // Whichever ever dimension is smaller will be the boundary
            // ex) if height is smaller, the entire height will be used and the ends of the width are cropped off
            // We need to scale the point coords, but we also need to account for the part of the image not in the viewer - the part that was cropped off
            // Scale the viewing box to the image, then calculate the offset
            const imageScaleWidth = image ? image.width / el.offsetWidth : 1;
            const imageScaleHeight = image ? image.height / el.offsetHeight : 1;

            const imageScale = imageScaleWidth <= imageScaleHeight ? imageScaleWidth : imageScaleHeight;
            x *= imageScale;
            y *= imageScale;
            const xImageOffset = (image.width - el.offsetWidth * imageScale) / 2.0;
            const yImageOffset = (image.height - el.offsetHeight * imageScale) / 2.0;
            const clickType = selectMode;
            return {
                x: Math.round(x),
                y: Math.round(y),
                clickType,
                imageScale,
                id: getId(),
                mask: null,
                hovered,
                xImageOffset: Math.round(xImageOffset),
                yImageOffset: Math.round(yImageOffset),
            };
        },
        [image, selectMode]
    );

    const onClick = e => {
        // prevents adding new points on generated images
        if (originalImage.url !== image?.src) return;

        // Reset the maskError state so that the API can try again with the new points
        setMaskError(false);

        const point = getPoint(e, false);
        const lastPoint = last(points);
        const pointsAreEqual = arePointsEqual(lastPoint, point);

        // only update points if the last point is different - either diff position or hovered vs click
        // otherwise, don't add a new point
        // this is for the case where the user immediately clicks after a mouse move, there is no reason to regenerate the mask
        if (lastPoint && pointsAreEqual && lastPoint.hovered === true) {
            setPoints(appendToClicks({ ...lastPoint, hovered: false }));
        } else if (!pointsAreEqual) {
            setPoints(appendToClicks(point));
        }
        setHistory([]);
    };

    const debouncedMouseMove = useMemo(
        () =>
            debounce(e => {
                if (unmountedRef.current) {
                    return;
                }
                const point = getPoint(e, true);
                setPoints(appendToClicks(point));

                // Reset the maskError state so that the API can try again with the new points
                setMaskError(false);
            }, 400),
        [setPoints, getPoint, unmountedRef, setMaskError]
    );

    const handleMouseMove = useCallback(
        e => {
            e.persist();
            debouncedMouseMove(e);
        },
        [debouncedMouseMove]
    );

    const onMouseOut = useCallback(() => {
        debouncedMouseMove.cancel();
        if (unmountedRef.current) {
            return;
        }
        setPoints(getClicks);
    }, [debouncedMouseMove, setPoints, unmountedRef]);

    return (
        <div className={styles['container']}>
            {maskError && (
                <CreativeStudioContentBanner
                    id="creative.studio.replace.background.segmentAnythingStage.mask.banner.error"
                    defaultMessage="We were unable to generate the mask. Please try again by moving your mouse or clicking a new point on the image."
                />
            )}

            <div className={classNameBuilder('body', bodyClassName)} data-testid="segment-anything-tool">
                <Tool
                    clicks={clicks}
                    onClick={onClick}
                    handleMouseMove={handleMouseMove}
                    onMouseOut={onMouseOut}
                    {...props}
                />
            </div>

            {originalImage.url !== image?.src ? (
                <div className={styles['dropdown-container']} data-testid="segment-anything-dropdown">
                    <SegmentAnythingGalleryItemDropdown itemUrl={image?.src} />
                </div>
            ) : (
                <StageButtons
                    debouncedMouseMove={debouncedMouseMove}
                    clicks={clicks}
                    setPoints={setPoints}
                    history={history}
                    setHistory={setHistory}
                    selectMode={selectMode}
                    setSelectMode={setSelectMode}
                />
            )}
        </div>
    );
};
