import React from 'react';
import { isCashBalanceRefund } from '../../../../../billing-and-payments/config/PaymentAction';
import {
    isAchBankDirectDebit,
    isBankTransfer,
    isHostedInvoice,
} from '../../../../../billing-and-payments/config/PaymentType';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import createGridValueFormatter from './createGridValueFormatter';

const NOT_DIGIT_CHARS_REGEX = /[\D]/g;
export const paymentMethodValueFormatter = ({ value }) => {
    if (value == null) {
        return ' ';
    }

    if (isBankTransfer(value)) {
        return (
            <FormattedMessage
                id="billingAndPayments.reports.paymentMethod.bankTransfer"
                defaultMessage="Bank Transfer"
            />
        );
    }

    if (isCashBalanceRefund(value)) {
        return (
            <FormattedMessage
                id="billingAndPayments.reports.paymentMethod.cashBalanceRefund"
                defaultMessage="Bank Transfer Refund"
            />
        );
    }

    if (isHostedInvoice(value)) {
        return (
            <FormattedMessage
                id="billingAndPayments.reports.paymentMethod.hostedInvoice"
                defaultMessage="Local Payment"
            />
        );
    }

    if (isAchBankDirectDebit(value)) {
        return (
            <FormattedMessage
                id="billingAndPayments.reports.paymentMethod.achBankDirectDebit"
                defaultMessage="Ach Bank Debit"
            />
        );
    }

    const lastFourDigits = value.replace(NOT_DIGIT_CHARS_REGEX, '');
    return `XXXX-${lastFourDigits}`;
};
export const gridPaymentMethodValueFormatter = createGridValueFormatter(paymentMethodValueFormatter);
