import React from 'react';
import PropTypes from 'prop-types';
import { Button, ChevronLeftOutlinedIcon } from 'tuui';
import FormButtons from 'modules/campaigns/components/Form/FormButtons/FormButtons';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './doneAndCreateConversionButton.module.scss';

const DoneAndCreateConversionButton = ({ onSubmit, onSubmitAndCreateConversion, disableSubmit, onBack, hasBack }) => (
    <div className={styles['buttons-wrapper']}>
        {hasBack && (
            <Button
                onClick={onBack}
                size={Button.size.md}
                variant={Button.variant.ghost}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="BackButton"
            >
                <ChevronLeftOutlinedIcon />
                <FormattedMessage id="app.actionButtons.back" />
            </Button>
        )}
        <FormButtons
            className={styles['form-buttons']}
            onSubmit={onSubmit}
            disableSubmit={disableSubmit}
            submitLabel={<FormattedMessage id="tracking.setup.done.button" defaultMessage="Done" />}
            submitButtonType="submit"
            outlineSubmitButton
            showFooterValidations
            hasCancel={false}
        />
        <Button
            className={styles['create-conversion-button']}
            onClick={onSubmitAndCreateConversion}
            size={Button.variant.md}
            variant={Button.variant.primary}
            disabled={disableSubmit}
            data-mnetrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="CreateConversionButton"
            type="submit"
        >
            <FormattedMessage id="tracking.setup.create.conversion.button" defaultMessage="Create Conversion" />
        </Button>
    </div>
);

DoneAndCreateConversionButton.propTypes = {
    onBack: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitAndCreateConversion: PropTypes.func,
    disableSubmit: PropTypes.bool,
};

export default DoneAndCreateConversionButton;
