import React, { useRef } from 'react';
import { useTextChangeTranslateDetector } from '../../hooks/useTextChangeTranslateDetector';
import styles from './textChangeTranslateDetector.module.scss';

const translationDetectionTextArray = [
    'Text to detect translation', // English
    'Texto para detectar la traducción', // Spanish
    'Texte pour détecter la traduction', // French
    'Text zur Erkennung der Übersetzung', // German
    'Testo per rilevare la traduzione', // Italian
    'Texto para detetar a tradução', // Portuguese
    'Tekst om vertaling te detecteren', // Dutch
    'Текст для виявлення перекладу', // Ukrainian
    'Текст для обнаружения перевода', // Russian
    '检测翻译的文本', // Chinese
    '翻訳を検出するテキスト', // Japanese
    '번역을 감지하는 텍스트', // Korean
    'טקסט לזיהוי תרגום', // Hebrew
    'النص لاكتشاف الترجمة', // Arabic
    'अनुवाद की पहचान के लिए पाठ', // Hindi
    'অনুবাদ চেনার জন্য টেক্সট', // Bengali
    'ਅਨੁਵਾਦ ਨੂੰ ਖੋਜਣ ਲਈ ਪਾਠ', // Punjabi
    'மொழிபெயர்ப்பு கண்டறி', // Tamil
    'Çeviriyi tespit etmek için metin', // Turkish
    'Κείμενο για ανίχνευση μετάφρασης', // Greek
    'ข้อความสำหรับตรวจสอบการแปล', // Thai
    'Văn bản để phát hiện dịch', // Vietnamese
    'Tekst do wykrywania tłumaczenia', // Polish
    'Text pentru detectarea traducerii', // Romanian
    'Text pro detekci překladu', // Czech
    'Fordítás észlelésére szolgáló szöveg', // Hungarian
    'Text för att upptäcka översättning', // Swedish
    'అనువాదం నిర్వచనానికి పాఠ్యం', // Telugu
    'Nakala ya kugundua tafsiri', // Swahili
    'Teksto para sa pagtukoy ng pagsasalin', // Filipino
    'Tekst til at opdage oversættelse', // Danish
    'Teksti käännöksen havaitsemiseen', // Finnish
    'Tekst for å oppdage oversettelse', // Norwegian
    'Teks untuk mengesan terjemahan', // Malay
    'Teks untuk mendeteksi terjemahan', // Indonesian
    'അനുവാദം കണ്ടെത്താൻ ഉപയോഗിക്കുന്ന പാഠം', // Malayalam
    'ဘာသာစကားအရပ်မျှသ', // Burmese
    'अनुवाद पत्ता लगाउनका लागि पाठ', // Nepali
    'Очиж хэлхэлхийг шалгах текст', // Mongolian
    'Терепті анықтау үшін мәтін', // Kazakh
];

const joinedTranslationText = translationDetectionTextArray.join('');
export const TextChangeTranslateDetector = () => {
    const textRef = useRef(null);

    useTextChangeTranslateDetector(textRef.current, joinedTranslationText);

    return (
        <div
            aria-hidden
            className={styles['translation-detector']}
            ref={textRef}
            data-testid="text-change-translate-detector"
        >
            {translationDetectionTextArray.map(text => (
                <span key={text}>{text}</span>
            ))}
        </div>
    );
};
