import { useMemo } from 'react';
import { isUndefined } from 'lodash';
import moment from 'moment/moment';
import {
    EXPLORATION_STATUS,
    PUBLISHER_BLOCKING_LEVEL_SUBTEXT,
    SAFETY_NETS_STATUS,
} from 'modules/campaigns/config/siteConsts';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { calculateProgress } from '../../../services/campaignStatusPhaseService';

export const useSubSiteStatus = data => {
    const {
        publisherBlockingLevel,
        safetyNetsStatus,
        explorationStatus,
        isPublisherBlocked,
        explorationStartDateInUtc,
        explorationEndDateInUtc,
    } = data;
    const { formatMessage } = useIntl();
    const isExplorationStatusPermitted = usePermissions('EXPLORATION_BAR_AND_STATUS');
    const isSpendGuardActiveOnAccountLevel = useConfigMatch({
        [FEATURE_FLAGS.SAFETY_NETS_ENABLED_CONFIG_ATTRIBUTE]: 'true',
    });
    const isSafetyNetsPermitted = usePermissions('SAFETY_NETS_VIEW');
    const isExplorationStatus =
        !isPublisherBlocked && explorationStatus === EXPLORATION_STATUS.IN_PROGRESS && isExplorationStatusPermitted;
    const isPublisherBlockingLevel = publisherBlockingLevel && PUBLISHER_BLOCKING_LEVEL_SUBTEXT[publisherBlockingLevel];
    const isSafetyNets =
        isSafetyNetsPermitted && isSpendGuardActiveOnAccountLevel && SAFETY_NETS_STATUS[safetyNetsStatus];

    const safetyNets = useMemo(
        () => (isSafetyNets ? SAFETY_NETS_STATUS[safetyNetsStatus] : null),
        [safetyNetsStatus, isSafetyNets]
    );

    const phaseTooltip = (() => {
        if (!isPublisherBlockingLevel) {
            if (isExplorationStatus) {
                return 'explorationStatus';
            } else if (isSafetyNets && SAFETY_NETS_STATUS[safetyNetsStatus] === SAFETY_NETS_STATUS.CAPPING_ENABLED) {
                return 'cappedBySpendGuards';
            } else if (isSafetyNets && SAFETY_NETS_STATUS[safetyNetsStatus] === SAFETY_NETS_STATUS.CAPPING_DISABLED) {
                return 'spendGuardsDisabled';
            } else if (
                isSafetyNets &&
                SAFETY_NETS_STATUS[safetyNetsStatus] === SAFETY_NETS_STATUS.SPEND_GUARDS_DISABLED
            ) {
                return 'spendGuardsDisabled';
            }
        }
        return null;
    })();

    const isProgressBar = !isPublisherBlockingLevel && isExplorationStatus;

    const [showProgressBar, progress, remainingTimeInMs] = useMemo(() => {
        if (isProgressBar) {
            const maxTimeInMs =
                explorationEndDateInUtc > explorationStartDateInUtc
                    ? moment(explorationEndDateInUtc).diff(moment(explorationStartDateInUtc))
                    : 0;
            const remainingTimeInMs = Math.max(0, moment(explorationEndDateInUtc).diff(moment()));

            const progress = calculateProgress(remainingTimeInMs, maxTimeInMs);
            return [!isUndefined(progress), progress, remainingTimeInMs];
        }
        return [false, null, null];
    }, [explorationEndDateInUtc, explorationStartDateInUtc, isProgressBar]);

    const subStatus = useMemo(() => {
        let msgId;
        if (isPublisherBlockingLevel) {
            msgId = PUBLISHER_BLOCKING_LEVEL_SUBTEXT[publisherBlockingLevel];
        } else if (isExplorationStatus && isProgressBar) {
            msgId = explorationStatus;
        } else {
            msgId = safetyNets;
        }
        return msgId
            ? formatMessage({
                  id: `app.campaigns.site.status.subtext.${msgId}`,
              })
            : null;
    }, [
        publisherBlockingLevel,
        isExplorationStatus,
        explorationStatus,
        safetyNets,
        formatMessage,
        isPublisherBlockingLevel,
        isProgressBar,
    ]);

    return { subStatus, showProgressBar, phaseTooltip, progress, remainingTimeInMs };
};
