import { invert } from 'lodash';

export const ENTITY = {
    COLUMN_PICKER: 'COLUMN_PICKER',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.COLUMN_PICKER]: 'column-picker',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
