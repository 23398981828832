import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckboxGroup, FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import styles from './audiencePropertiesCheckBox.module.scss';

const categoriesForDefaultExclude = ['LEAD', 'COMPLETE_REGISTRATION', 'APP_INSTALL'];

export const AudiencePropertiesCheckBox = () => {
    const { value: categoryValue } = useFormFieldValue({ field: 'category' });
    const { value: IITValue, isDirty: IITIsDirty } = useFormFieldValue({ field: 'includeInTotalConversions' });
    const {
        value: excludeFromCampaignsValue,
        onChange,
        isDirty,
        mode,
    } = useFormFieldValue({ field: 'excludeFromCampaigns' });

    const [userMadeChoice, setUserMadeChoice] = useState(false);

    const changeExcludeValueAndSetUserChoice = useCallback(
        (value, changeUserChoice = true) => {
            onChange(value);
            if (changeUserChoice) {
                setUserMadeChoice(true);
            }
        },
        [onChange]
    );

    const onSelect = useCallback(() => changeExcludeValueAndSetUserChoice(true), [changeExcludeValueAndSetUserChoice]);
    const onUnSelect = useCallback(
        () => changeExcludeValueAndSetUserChoice(false),
        [changeExcludeValueAndSetUserChoice]
    );

    const defaultExclude = useMemo(
        () => IITValue && categoriesForDefaultExclude.some(v => v === categoryValue),
        [IITValue, categoryValue]
    );

    useEffect(() => {
        if (userMadeChoice) {
            return;
        }

        // don't update excludeFromCampaign if there is no need on first render
        if (mode === FORM_MODES.EDIT && !isDirty && !IITIsDirty) {
            return;
        }

        if (!isDirty && !defaultExclude) {
            return;
        }

        changeExcludeValueAndSetUserChoice(defaultExclude, false);
    }, [IITIsDirty, defaultExclude, changeExcludeValueAndSetUserChoice, isDirty, userMadeChoice, mode]);

    return (
        <FormField
            inputId="audience-properties"
            label={
                <FormattedMessage
                    id="tracking.conversion.properties.audience.properties.checkbox.label"
                    defaultMessage="Audience Properties"
                />
            }
            description={
                <FormattedMessage
                    id="tracking.conversion.properties.audience.properties.checkbox.description"
                    defaultMessage="An audience will be created based on people that performed this conversion."
                />
            }
        >
            <CheckboxGroup
                onSelectCheckbox={onSelect}
                onUnselectCheckbox={onUnSelect}
                selectedValues={[excludeFromCampaignsValue]}
                className={styles['checkbox-group']}
            >
                <TextCheckbox
                    labelClassName={styles['checkbox-label']}
                    value
                    title={
                        <div className={styles['container']}>
                            <FormattedMessage
                                id="tracking.conversion.properties.audience.properties.checkbox.title"
                                defaultMessage="Exclude Audience from all campaigns"
                            />
                            <div className={styles['details']}>
                                <FormattedMessage
                                    id="tracking.conversion.properties.audience.properties.checkbox.details"
                                    defaultMessage="People that performed this conversion will not be targeted after they convert."
                                />
                            </div>
                        </div>
                    }
                    data-metrics-component="Audience properties checkbox"
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-value={!excludeFromCampaignsValue}
                />
            </CheckboxGroup>
        </FormField>
    );
};
