import { Children } from 'react';
import { Switch } from 'react-router';
import { FEATURE_FLAGS, useConfigMatch } from '../../modules/taboola-common-frontend-modules/account-configurations';

export const CustomizedSwitchWrapper = ({ children }) => {
    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });
    const childrenArray = Children.toArray(children);

    if (isMultiLayerDrawerEnabled) {
        return (
            <>
                <Switch>{childrenArray.slice(0, childrenArray.length - 1)}</Switch>
                {childrenArray[childrenArray.length - 1]}
            </>
        );
    }

    return <Switch>{children}</Switch>;
};
