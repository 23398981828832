import TargetingTypes from '../../modules/common-campaign-form/config/TargetingTypes';

const SUBCATEGORY_ALL = 'ALL';

const subCategoriesDistributor = (acc, currentElement) => {
    if (!currentElement || !currentElement.osFamily || !currentElement.subCategories) {
        return acc;
    }
    // TODO: DEV-80488 - remove unnecessary addition of subcategory because of old component
    if (currentElement.subCategories.length > 0) {
        currentElement.subCategories.forEach(cur =>
            acc.push({ osFamily: currentElement.osFamily, subCategory: cur, id: cur })
        );
    } else {
        acc.push({ osFamily: currentElement.osFamily, subCategory: SUBCATEGORY_ALL, id: SUBCATEGORY_ALL });
    }

    return acc;
};

const subCategoryAggregator = (acc, currentElement) => {
    const familyIndex = acc.findIndex(item => item.osFamily === currentElement.osFamily);
    if (familyIndex >= 0) {
        acc[familyIndex].subCategories = acc[familyIndex].subCategories.concat(currentElement.subCategory);
        return acc;
    }
    const obj = {
        osFamily: currentElement.osFamily,
        subCategories: currentElement.subCategory === SUBCATEGORY_ALL ? [] : [currentElement.subCategory],
    };
    return [...acc, obj];
};

const transformOsTargetingToGW = campaign => {
    const { campaignTargeting } = campaign;

    if (!campaignTargeting) {
        return campaign;
    }

    const { osTargeting } = campaignTargeting;

    if (!osTargeting) {
        return campaign;
    }

    const { type = TargetingTypes.INCLUDE, values = [] } = osTargeting;

    const aggregatedOsList = values.reduce(subCategoryAggregator, []);

    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            osTargeting: { type: values.length ? type : TargetingTypes.ALL, values: aggregatedOsList },
        },
    };
};

const transformOsTargetingFromGW = campaign => {
    const { campaignTargeting } = campaign;

    if (!campaignTargeting) {
        return campaign;
    }

    const { osTargeting } = campaignTargeting;
    const { type = TargetingTypes.INCLUDE, values = [] } = osTargeting || {};

    const flatOsList = values.reduce(subCategoriesDistributor, []);

    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            osTargeting: { type: type === TargetingTypes.ALL ? TargetingTypes.INCLUDE : type, values: flatOsList },
        },
    };
};

export { subCategoryAggregator, transformOsTargetingToGW, transformOsTargetingFromGW, SUBCATEGORY_ALL };
