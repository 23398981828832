import React from 'react';
import { FormField, InputTypes, Input } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import styles from './readOnlyExpirationDate.module.scss';

const InputWithIndication = withIndication(Input);

export const ReadOnlyExpirationDate = () => {
    const { value: expDate } = useFormFieldValue({
        field: 'expDate',
    });

    return (
        <FormField
            inputId="expirationDate"
            containerClass={styles['field']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.expirationDate" defaultMessage="Exp. Date" />}
        >
            <InputWithIndication
                value={expDate}
                disabled
                inputClass={styles['input']}
                type={InputTypes.TEXT}
                size={Input.SIZE.LARGE}
                placeholder="MM / YY"
            />
        </FormField>
    );
};
