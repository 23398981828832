import { useMemo } from 'react';
import { castArray } from 'lodash';
import { MODULE_PARAM_NAME } from 'config/routes/module';
import { useAvailableItems } from 'hooks';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_ID } from './useSelectedReportId';

export const SELECTED_GRAPH = 'selectedGraph';
const useGraphType = queryParamHookFactory(SELECTED_GRAPH, {
    persist: PERSISTENCE_TYPE.LOCAL_STORAGE,
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
        queryParams: [REPORT_ID],
    },
});

export const useSelectedGraphConfig = reportConfig => {
    const { graph: graphConfig = [] } = reportConfig;
    const graphConfigsArr = useMemo(() => {
        const configsArr = castArray(graphConfig);
        // we need to add an ID to the graph config(s) so that useAvailableItems can parse based on permissions
        return configsArr.map(({ id, ...rest }) => ({ ...rest, id: id || rest.type }));
    }, [graphConfig]);
    const availableGraphConfigs = useAvailableItems(graphConfigsArr);
    const isMultipleGraphs = availableGraphConfigs.length > 1;
    const [selectedGraph, setSelectedGraph] = useGraphType();

    const selectedConfig = useMemo(
        () => availableGraphConfigs.find(config => config.type === selectedGraph) || availableGraphConfigs[0],
        [availableGraphConfigs, selectedGraph]
    );

    return {
        selectedConfig,
        isMultipleGraphs,
        setSelectedGraph,
        selectedGraph,
    };
};
