import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { LogoUploadBox } from './LogoUploadBox';
import { UploadLogoError } from './UploadLogoError';
import { UploadedLogoItem } from './UploadedLogoItem';
import { LOGO_EMPTY_OBJECT, LOGO_FIELD_ID } from './logoConsts';
import styles from './logoField.module.scss';

export const LogoField = () => {
    const { value: uploadedLogo = LOGO_EMPTY_OBJECT, onChange: setUploadedLogo } = useFormFieldValue({
        field: LOGO_FIELD_ID,
        isAbsolute: true,
    });
    const [logoFileName, setLogoFileName] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(COMPONENT_STATUS.ACTIVE);
    const [error, setError] = useState();
    const isUrlPopulated = !isEmpty(uploadedLogo?.logo);

    return (
        <FormField
            label={<FormattedMessage id="creative.creator.thumbnails.logo.label" defaultMessage="Logo (Optional)" />}
            description={
                <FormattedMessage
                    id="creative.creator.thumbnails.logo.description"
                    defaultMessage="The logo will appear on Yahoo Mail Ads"
                />
            }
            helpText={
                <FormattedMessage
                    id="creative.creator.thumbnails.logo.tooltip"
                    defaultMessage="Add a square logo 1x1, min dimensions: 180 x 180, PNG and JPG, max size 5MB"
                />
            }
            inputId="creative-creator-creatives"
            containerClass={styles['container']}
        >
            {isUrlPopulated ? (
                <UploadedLogoItem
                    uploadedLogo={uploadedLogo}
                    setUploadedLogo={setUploadedLogo}
                    logoFileName={logoFileName}
                    setLogoFileName={setLogoFileName}
                />
            ) : (
                <LogoUploadBox
                    setUploadedLogo={setUploadedLogo}
                    setLogoFileName={setLogoFileName}
                    uploadStatus={uploadStatus}
                    setUploadStatus={setUploadStatus}
                    setError={setError}
                />
            )}
            <UploadLogoError error={error} />
        </FormField>
    );
};
