import React from 'react';
import classnames from 'classnames/bind';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { FormField, TrackingSectionIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import ThirdPartyTags from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags';
import styles from 'modules/campaigns/modules/creative-bulk-editor/components/BulkEditTrackingSection/bulkEditTrackingSection.module.scss';
import { FIELDS } from 'modules/campaigns/modules/creative-bulk-editor/utils';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const classNameBuilder = classnames.bind(styles);

const EmptyComponent = () => null;
const checkboxLabel = (
    <FormattedMessage id="creative.editor.bulk.content.tracking.label" defaultMessage="Edit 3rd Party Tags" />
);

export const BulkEditTrackingSection = ({
    selectedFieldsMatrix,
    setSelectedFieldsMatrix: setSelectedFieldsMatrixProp,
}) => {
    const { thirdPartyTags: isTrackingSelected } = selectedFieldsMatrix;
    const generateSetSelectedFieldsMatrix = key => newKeyValue => {
        setSelectedFieldsMatrixProp(prevState => ({
            ...prevState,
            [key]: isNil(newKeyValue) ? !prevState[key] : newKeyValue,
        }));
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: newKeyValue,
            component: 'BULK_EDIT_CREATIVE_TRACKING_CHECKBOX',
        });
    };

    return (
        <CommonCollapsibleCard
            id="BULK_EDIT_CREATIVE_TRACKING"
            header={
                <SectionHeader
                    headerIcon={<TrackingSectionIcon />}
                    headerText={<FormattedMessage id="creative.editor.tracking" defaultMessage="Tracking" />}
                />
            }
        >
            <FormField
                checkboxLabel={checkboxLabel}
                selected={isTrackingSelected}
                onSelect={generateSetSelectedFieldsMatrix(FIELDS.THIRD_PARTY_TAGS)}
                labelClass={classNameBuilder('edit-third-party-tags-label')}
                description={
                    <FormattedMessage
                        id="creative.editor.bulk.content.tracking.description"
                        defaultMessage="Bulk edits of 3rd party tags will result in existing tags being replaced by the new tags"
                    />
                }
            >
                <div
                    className={classNameBuilder('tracking-container', {
                        disabled: !isTrackingSelected,
                    })}
                >
                    <ThirdPartyTags label={EmptyComponent} helpText={null} />
                </div>
            </FormField>
        </CommonCollapsibleCard>
    );
};

BulkEditTrackingSection.protoTypes = {
    selectedFieldsMatrix: PropTypes.object,
    setSelectedFieldsMatrix: PropTypes.func,
};
