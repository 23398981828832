import { isArray, isObject, keys } from 'lodash';

const maxScrollAttempts = 10;
const timeoutBetweenScrollAttempts = 200;
const defaultScrollIntoViewOptions = { behavior: 'smooth', block: 'center', maxAttempts: maxScrollAttempts };

function isElementInViewport(elementRect, scrollArea) {
    if (!scrollArea) {
        return true;
    }
    return scrollArea.scrollTop > elementRect.offsetHeight;
}

export const scrollToElement = (element, scrollArea, scrollIntoViewOptions = defaultScrollIntoViewOptions) => {
    if (!element?.scrollIntoView) {
        return;
    }
    const maxAttempts = scrollIntoViewOptions.maxAttempts || maxScrollAttempts;
    const attempts = 0;

    const doScroll = attempts => {
        element.scrollIntoView(scrollIntoViewOptions);

        if (!isElementInViewport(element, scrollArea) && attempts < maxAttempts) {
            element.scrollIntoView(scrollIntoViewOptions);
            setTimeout(() => doScroll(attempts + 1), timeoutBetweenScrollAttempts);
        }
    };

    doScroll(attempts);
};

export const allKeysList = (data, parentKey) =>
    keys(data).reduce((prevResult, key) => {
        const fullKey = `${parentKey ? parentKey + '.' : ''}${key}`;
        let result = [...prevResult];
        result.push(fullKey);

        if (isObject(data[key]) && !isArray(data[key])) {
            result = [...result, ...allKeysList(data[key], fullKey)];
        }

        return result;
    }, []);

export const addAutoScrollCallback = callback => {
    // We need to do auto scrolling once render tree has been painted
    // with requestAnimationFrame we wait next frame and the with timeout we guarantee that all animations end in the form
    // before we started calculation positions of element to scroll to
    setTimeout(callback);
};
