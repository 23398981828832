import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const PixelBasedAudienceUrlConditionsTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.conditions.tooltip.title"
                defaultMessage="Managing conditions will define the URL match of this audience."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.conditions.tooltip.text"
                defaultMessage="Enter the URL or part of the URL of the destination page whose visitors should count as part of this custom audience."
            />
        </TooltipSection>
    </div>
);

export default PixelBasedAudienceUrlConditionsTooltip;
