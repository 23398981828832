import lodashGet from 'lodash.get';

type PathImpl<T, K extends keyof T> = K extends string
    ? T[K] extends Record<string, any>
        ? T[K] extends ArrayLike<any>
            ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
            : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
        : K
    : never;

export type Path<T> = PathImpl<T, keyof T>;

type GetIndexedField<T, K> = K extends keyof T
    ? T[K]
    : K extends `${number}`
        ? '0' extends keyof T
            ? undefined
            : number extends keyof T
                ? T[number]
                : undefined
        : undefined

type FieldWithPossiblyUndefined<T, Key> =
    | GetFieldType<Exclude<T, undefined>, Key>
    | Extract<T, undefined>

type IndexedFieldWithPossiblyUndefined<T, Key> =
    | GetIndexedField<Exclude<T, undefined>, Key>
    | Extract<T, undefined>

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
    ? Left extends keyof T
        ? FieldWithPossiblyUndefined<T[Left], Right>
        : Left extends `${infer FieldKey}[${infer IndexKey}]`
            ? FieldKey extends keyof T
                ? FieldWithPossiblyUndefined<IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>, Right>
                : undefined
            : undefined
    : P extends keyof T
        ? T[P]
        : P extends `${infer FieldKey}[${infer IndexKey}]`
            ? FieldKey extends keyof T
                ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
                : undefined
            : undefined


export function get<T, P extends Path<T>>(obj: T, path: P) {
    return lodashGet(obj, path) as GetFieldType<T,P>;
}

export const prop =
    <T, P extends Path<T>>(path: P) =>
    (obj: T) =>
        get(obj, path);
