import { every, isEmpty, isEqual, keys, transform } from 'lodash';
import { DEFAULT_ZOOM, MINIMUM_COORD_VALUE, ORIGIN_POINT } from '../constants/croppingConsts';

const ratiosMaxSizeMap = {
    [16 / 9]: {
        maxWidth: 564,
        maxHeight: 317,
    },
    1: {
        maxWidth: 317,
        maxHeight: 317,
    },
    [6 / 5]: {
        maxWidth: 381,
        maxHeight: 317,
    },
    2: {
        maxWidth: 564,
        maxHeight: 282,
    },
    1.91: {
        maxWidth: 564,
        maxHeight: 295,
    },
    [4 / 3]: {
        maxHeight: 317,
        maxWidth: 423,
    },
};

export const cropDataTransformer = cropData =>
    transform(
        cropData,
        (res, singleRatioCropData) => {
            const { croppedAreaPixels } = singleRatioCropData;
            if (!croppedAreaPixels) {
                return;
            }
            res.push(singleRatioCropDataTransformer(singleRatioCropData));
        },
        []
    );

export const cloudinaryResponseToExactPositionTransformer = ({ mediaSize, ratio, cropAreaPixels }) => {
    const { maxWidth, maxHeight } = ratiosMaxSizeMap[ratio];

    const isWidthGreaterThanHeight = mediaSize.width > mediaSize.height;

    const cropperWidth = isWidthGreaterThanHeight
        ? Math.min(mediaSize.height * ratio, mediaSize.width)
        : mediaSize.width;
    const cropperMinWidth = Math.min(cropperWidth, maxWidth);

    const cropperHeight = isWidthGreaterThanHeight
        ? Math.min(mediaSize.height, cropperMinWidth / ratio)
        : cropperMinWidth / ratio;

    const cropperMinHeight = Math.min(cropperHeight, maxHeight);

    const maxX = Math.abs(mediaSize.width - cropperMinWidth) / 2;
    const maxY = Math.abs(mediaSize.height - cropperMinHeight) / 2;

    return {
        y: maxY - (cropAreaPixels.y * cropperMinHeight) / cropAreaPixels.height, // transform the y value to the actual image
        x: maxX - (cropAreaPixels.x * cropperMinWidth) / cropAreaPixels.width, // transform the x value to the actual image
    };
};

const DEFAULT_CROP_AREA_WIDTH = 1200;
const getDefaultHeightFromAspect = ({ width, height }) => (DEFAULT_CROP_AREA_WIDTH * height) / width;

export const singleRatioCropDataTransformer = ({ croppedAreaPixels, aspect }) => {
    if (!aspect) {
        return null;
    }
    if (!croppedAreaPixels) {
        return {
            ratio: aspect,
            area: {
                topLeftX: 0,
                topLeftY: 0,
                width: DEFAULT_CROP_AREA_WIDTH,
                height: getDefaultHeightFromAspect(aspect),
            },
        };
    }
    return {
        ratio: aspect,
        area: {
            topLeftX: croppedAreaPixels.x,
            topLeftY: croppedAreaPixels.y,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
        },
    };
};

const isSimilar = (a, b) => Math.abs(a - b) <= 2;
export const isNewCropDataSimilarToPrevious = (prevCropData, newCropData) =>
    prevCropData && every(keys(prevCropData), prevKey => isSimilar(prevCropData[prevKey], newCropData[prevKey]));

const calculateIntersectAreaOfCropAreas = (oldCropArea, newCropArea) => {
    const oldCropBottomRightY = oldCropArea.height + oldCropArea.topLeftY;
    const oldCropBottomRightX = oldCropArea.width + oldCropArea.topLeftX;
    const newCropBottomRightY = newCropArea.height + newCropArea.topLeftY;
    const newCropBottomRightX = newCropArea.width + newCropArea.topLeftX;

    // based on coordinate system, (x1, y1) top left and (x2, y2) bottom right for each crop
    // intersection height = smaller y2 - bigger y1 (the shared length on the y-axis)
    const newCropWithinOldCropHeight = Math.max(
        Math.min(oldCropBottomRightY, newCropBottomRightY) - Math.max(oldCropArea.topLeftY, newCropArea.topLeftY),
        0
    );
    // intersection width = smaller x2 - bigger x2 (the shared length on the x-axis)
    const newCropWithinOldCropWidth = Math.max(
        Math.min(oldCropBottomRightX, newCropBottomRightX) - Math.max(oldCropArea.topLeftX, newCropArea.topLeftX),
        0
    );

    return newCropWithinOldCropHeight * newCropWithinOldCropWidth;
};

export const isSignificantChange = (oldCropData, newCropData, significantChangeThreshold) => {
    if (!oldCropData || isEmpty(oldCropData)) {
        return true;
    }

    const intersectArea = calculateIntersectAreaOfCropAreas(oldCropData.area, newCropData.area);

    const newArea = newCropData.area.height * newCropData.area.width;
    const oldArea = oldCropData.area.height * oldCropData.area.width;

    // if intersection of new and old cropping makes up less than x% of old area or makes up less than x% of new area, then move to pending
    return (
        intersectArea <= oldArea * significantChangeThreshold || intersectArea <= newArea * significantChangeThreshold
    );
};

// if the absolute value of either x or y is less than MINIMUM_COORD_VALUE, then set to 0
export const getSanitizedCrop = ({ x, y }) => {
    return {
        x: Math.abs(x) < MINIMUM_COORD_VALUE ? 0 : x,
        y: Math.abs(y) < MINIMUM_COORD_VALUE ? 0 : y,
    };
};

export const isImageCropped = ({ crop = ORIGIN_POINT, zoom = DEFAULT_ZOOM } = {}) => {
    return !isEqual(crop, ORIGIN_POINT) || zoom !== DEFAULT_ZOOM;
};
