import React from 'react';
import PropTypes from 'prop-types';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import FormLayout from '../../../../components/Form/FormLayout';
import PerformanceRecommendationsContent from '../../components/PerformanceRecommendationsContent/PerformanceRecommendationsContent';
import PerformanceRecommendationsHeader from '../../components/PerformanceRecommendationsHeader/PerformanceRecommendationsHeader';
import { usePerformanceRecommendationsReviewerState } from '../../hooks';
import styles from './performanceRecommendationsReviewer.module.scss';

export const PerformanceRecommendationsReviewer = ({ onCancel }) => {
    const { isLoading, recommendations } = usePerformanceRecommendationsReviewerState();
    return (
        <div className={styles['drawer']}>
            <PerformanceRecommendationsHeader />
            <FormLayout
                onSubmit={onCancel}
                formClassName={styles['form-wrapper']}
                footerContainerClassName={styles['footer']}
                contentContainerClassName={styles['feed']}
                submitButtonText={
                    <FormattedMessage id="performance-recommendations.footer.done" defaultMessage="Done" />
                }
                hasCancel={false}
                showFooterValidations={false}
            >
                {isLoading ? (
                    <LoadingAnimation
                        className={styles['feed-loading']}
                        label={
                            <FormattedMessage
                                id="app.performance-recommendations.loading"
                                className={styles['loading']}
                                defaultMessage="Loading..."
                            />
                        }
                    />
                ) : (
                    <PerformanceRecommendationsContent recommendations={recommendations} />
                )}
            </FormLayout>
        </div>
    );
};

PerformanceRecommendationsReviewer.propTypes = {
    onCancel: PropTypes.func,
};

export default PerformanceRecommendationsReviewer;
