import React from 'react';
import { get } from 'lodash';
import { XAxis } from 'recharts';
import { CampaignHistoryFlag } from './CampaignHistoryFlag';

export const xAxisWithTimelineRenderer = ({
    dataKey,
    tickFormatter,
    tick,
    axisLine,
    tickLine,
    tickMargin,
    padding,
    scale,
    type,
    height,
    minTickGap,
    domain,
    data,
}) => {
    const renderTimelineTick = ({ index, x, y }) => {
        const activityData = get(data, [index, 'activityData']) || {};
        return <CampaignHistoryFlag activityData={activityData} x={x} y={y} />;
    };
    return [
        <XAxis
            key="dateAxis"
            xAxisId="dateAxis"
            dataKey={dataKey}
            height={height}
            tickFormatter={tickFormatter}
            tick={tick}
            axisLine={axisLine}
            tickLine={tickLine}
            padding={padding}
            tickMargin={tickMargin}
            scale={scale}
            type={type}
            domain={domain}
            minTickGap={minTickGap}
        />,
        <XAxis
            key="timelineAxis"
            xAxisId="0"
            dataKey={dataKey}
            height={25}
            padding={padding}
            tickMargin={15}
            interval={0}
            scale={scale}
            type={type}
            domain={domain}
            axisLine={false}
            tickLine={false}
            tick={renderTimelineTick}
        />,
    ];
};
