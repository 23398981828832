import URI from 'urijs';

export const accountLevelConversionsAPIPrefix = accountId => `/${accountId}/conversions`;
export const campaignLevelConversionsAPIPrefix = (accountId, campaignId) =>
    `/${accountId}/campaigns/${campaignId}/conversions`;
const PAGE_SIZE = 20;
export const campaignLevelConversionsApiFactory = ({ callGetApi }) => ({
    getAccountLevelConversionRulesData: (accountId, page, pageSize = PAGE_SIZE, searchText) => {
        const url = URI(accountLevelConversionsAPIPrefix(accountId, page, pageSize));
        return callGetApi(url.toString(), {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
        });
    },
    getCampaignLevelConversionRulesData: (accountId, campaignId, page, pageSize = PAGE_SIZE, searchText) => {
        const url = URI(campaignLevelConversionsAPIPrefix(accountId, campaignId, page, pageSize));
        return callGetApi(url.toString(), {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
        });
    },
});
