'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.noop');

var _lodash4 = _interopRequireDefault(_lodash3);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _constants = require('./constants');

var _ButtonsGroupOption = require('./ButtonsGroupOption');

var _ButtonsGroupOption2 = _interopRequireDefault(_ButtonsGroupOption);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'buttonsGroup-module__taboola-svg-icon___3Gul3',
    'container': 'buttonsGroup-module__container___2Aw2n'
};


var classNameBuilder = _bind2.default.bind(styles);

// because the selected object can be a sub option (one of the items in a dropdown),
// this function finds the actual selectedValueObject's top level index
var findSelectedObjectIndexes = function findSelectedObjectIndexes(options, selectedValueObject) {
    var selectedValue = selectedValueObject.value;
    // subOptionIndex not used but still calculated if we ever need it later

    var subOptionIndex = -1;
    var optionIndex = options.findIndex(function (option) {
        var subOptions = option.options,
            value = option.value;

        if (value === selectedValue) {
            return true;
        }
        subOptionIndex = subOptions && subOptions.findIndex(function (_ref) {
            var v = _ref.value;
            return v === selectedValue;
        });
        return subOptionIndex > -1;
    });
    return optionIndex;
};

var buildMoreOptions = function buildMoreOptions(options, optionWidths, maxWidth, selectedIndex) {
    // we initialize with the more button width since this calculation is to determine if it's necessary to keep it
    var moreWidth = (optionWidths[_constants.MORE_BUTTON_VALUE] || _constants.MORE_BUTTON_WIDTH) + _constants.DIVIDER_WIDTH;
    var totalWidth = moreWidth;
    // we also initialize with the selectedIndex item's width, since the selected button will *always* be
    // pushed onto the rendered list so we can just account for it here.
    // note we use selectedIndex instead of the selectedValueObject since the object could be nested. the selectedIndex
    // will give us the value of the actual button being rendered, which the optionWidths map needs
    var selectedValue = (0, _lodash2.default)(options, [selectedIndex, 'value']);
    totalWidth += optionWidths[selectedValue] || 0;
    var moreOptions = [];
    return [options.reduce(function (newOptions, option, index) {
        var value = option.value;

        if (value === selectedValue) {
            return [].concat(_toConsumableArray(newOptions), [option]);
        }
        totalWidth += optionWidths[value] + _constants.DIVIDER_WIDTH;
        var isLastOptionAndCanFitWithoutMoreOptions = index === options.length - 1 && moreOptions.length === 0 && totalWidth - moreWidth <= maxWidth;
        if (totalWidth <= maxWidth || isLastOptionAndCanFitWithoutMoreOptions) {
            return [].concat(_toConsumableArray(newOptions), [option]);
        }
        if (option.options) {
            moreOptions.push.apply(moreOptions, _toConsumableArray(option.options));
        } else {
            moreOptions.push(option);
        }
        return newOptions;
    }, []), moreOptions];
};

var ButtonsGroup = function ButtonsGroup(_ref2) {
    var optionsProp = _ref2.options,
        containerClassName = _ref2.containerClassName,
        maxWidth = _ref2.maxWidth,
        moreLabel = _ref2.moreLabel,
        selectedValueObject = _ref2.selectedValueObject,
        rest = _objectWithoutProperties(_ref2, ['options', 'containerClassName', 'maxWidth', 'moreLabel', 'selectedValueObject']);

    var buttonsContainerClassName = classNameBuilder('ButtonsGroup', 'container', containerClassName);

    var _useState = (0, _react.useState)({}),
        _useState2 = _slicedToArray(_useState, 2),
        optionWidths = _useState2[0],
        setOptionWidths = _useState2[1];

    var hasMaxWidth = Boolean(maxWidth);
    (0, _react.useEffect)(function () {
        if (hasMaxWidth) {
            setOptionWidths({});
        }
    }, [hasMaxWidth, optionsProp]);
    var selectedIndex = (0, _react.useMemo)(function () {
        return findSelectedObjectIndexes(optionsProp, selectedValueObject);
    }, [optionsProp, selectedValueObject]);
    var options = (0, _react.useMemo)(function () {
        if (maxWidth && Object.keys(optionWidths).length >= optionsProp.length) {
            var _buildMoreOptions = buildMoreOptions(optionsProp, optionWidths, maxWidth, selectedIndex),
                _buildMoreOptions2 = _slicedToArray(_buildMoreOptions, 2),
                newOptions = _buildMoreOptions2[0],
                moreOptions = _buildMoreOptions2[1];

            if (moreOptions.length > 1) {
                newOptions.push({
                    label: moreLabel,
                    value: _constants.MORE_BUTTON_VALUE,
                    showLabel: true,
                    options: moreOptions
                });
                return newOptions;
            }
        }
        return optionsProp;
    }, [maxWidth, optionWidths, optionsProp, selectedIndex, moreLabel]);

    var newOptionWidths = optionWidths;
    return _react2.default.createElement(
        'div',
        { className: buttonsContainerClassName },
        options.map(function (option, index) {
            var value = option.value;

            var setWidth = function setWidth(newWidth) {
                var prevWidth = optionWidths[value];
                if (prevWidth !== newWidth) {
                    newOptionWidths = Object.assign({}, newOptionWidths, _defineProperty({}, value, newWidth));
                }
                // only call setOptionWidths on the last item being iterated on. this is to prevent rerendering n times since
                // setWidth could get called with every iterable but we only need it to update after all of them have been called
                // if newOptionWidths has not changed react will not rerender so no infinite loops
                if (index === options.length - 1) {
                    setOptionWidths(newOptionWidths);
                }
            };
            return _react2.default.createElement(_ButtonsGroupOption2.default, Object.assign({
                key: value,
                option: option,
                isFirstButton: index === 0,
                isLastButton: index === options.length - 1,
                setWidth: maxWidth && setWidth,
                selectedValueObject: selectedValueObject
            }, rest));
        })
    );
};

var itemPropType = {
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
    options: _propTypes2.default.array
};

ButtonsGroup.propTypes = {
    options: _propTypes2.default.arrayOf(_propTypes2.default.shape(itemPropType)),
    divider: _propTypes2.default.node,
    selectedValueObject: _propTypes2.default.shape(itemPropType),
    onChange: _propTypes2.default.func,
    maxWidth: _propTypes2.default.number,
    moreLabel: _propTypes2.default.node,
    containerClassName: _propTypes2.default.string,
    buttonGroupDropdownContainerClassName: _propTypes2.default.string,
    getHref: _propTypes2.default.func
};

ButtonsGroup.defaultProps = {
    moreLabel: 'More',
    divider: '|',
    onChange: _lodash4.default,
    selectedValueObject: {},
    getHref: _lodash4.default
};

ButtonsGroup.MORE_BUTTON_VALUE = _constants.MORE_BUTTON_VALUE;

exports.default = ButtonsGroup;