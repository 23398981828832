import { isEqual } from 'lodash';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { DEFAULT_CURRENCY } from '../constants';

export const campaignsStateSelector = state => state.campaignsReducer;
export const appStateSelector = state => state.appReducer;

export const accountsMapSelector = createSelector(campaignsStateSelector, campaignsState => campaignsState.accounts);

export const transformAccountObject = accountObj => {
    if (!accountObj) {
        return {
            currency: DEFAULT_CURRENCY,
        };
    }

    const account = {
        ...accountObj,
        id: accountObj.accountId,
        // This field gives us confusing every time when we need to call api, prefer using 'accountName' name where we need alphabetical id
        accountId: accountObj.accountName,
        name: accountObj.accountDescription,
        currency: accountObj.currency || DEFAULT_CURRENCY,
    };

    return account;
};

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
