import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { addProtocol } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { copyToClipboard } from '../../../../services/utils/clipboard';
import OnSitePreviewModalContent from './components/OnSitePreviewModalContent/OnSitePreviewModalContent';
import { useCreativePreviewParameters } from './hooks/useCreativePreviewParameters';

const defaultUrl = 'https://feed.taboola.com';

export const OnSitePreviewModal = ({ onClose }) => {
    const [url, changePreviewUrl] = useState(defaultUrl);
    const pageName = getPageName();
    const submitEvent = useMemo(
        () => ({ component: 'Preview on-site demo', pageName, value: 'On-site preview' }),
        [pageName]
    );

    const getParameters = useCreativePreviewParameters();

    const handleSubmit = useCallback(() => {
        // append the query param to the URL before opening
        const numberOfAllowedParameters = 1;
        const params = getParameters(numberOfAllowedParameters);
        let previewUrl = url;
        previewUrl += url.indexOf('?') > 0 ? '&' : '?';
        previewUrl += params;
        previewUrl = addProtocol(previewUrl);

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, submitEvent);

        window.open(previewUrl, '_blank');
        onClose();
    }, [getParameters, onClose, submitEvent, url]);

    const copyParameters = data => {
        copyToClipboard(data);
        onClose();
    };

    const handleCopy = () => {
        const numberOfAllowedParameters = 1;
        copyParameters(`?${getParameters(numberOfAllowedParameters)}`);
    };

    return (
        <ModalFormLayout
            onCancel={onClose}
            onSubmit={handleSubmit}
            submitButtonText={<FormattedMessage id="app.modal.onSitePreview.button.positive" defaultMessage="Open" />}
            cancelButtonText={<FormattedMessage id="app.modal.onSitePreview.button.negative" defaultMessage="Close" />}
        >
            <OnSitePreviewModalContent url={url} changePreviewUrl={changePreviewUrl} handleCopy={handleCopy} />
        </ModalFormLayout>
    );
};

OnSitePreviewModal.propTypes = { onClose: PropTypes.func };
