import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { isEmpty, keyBy, get } from 'lodash';
import { useCurrentValueGetter } from 'hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useSitesApi } from 'services/api';
import ACCOUNT_TYPE from '../../../../../../account-management/accountType';
import { useSupplyTargetingFormField } from '../../SupplyTargeting/hooks/useSupplyTargetingFormField';
import { siteResourceFieldKeyName } from '../const';

const STALE_TIME = 10 * 60 * 1000;
const RESOURCES_PREFIX = 'site-resources';

export const useAllowedSites = (listKey, field) => {
    const {
        accountName: campaignAccountName,
        formAccount: { accountName: formAccountName },
        data: formData,
    } = useFormDataContext();
    const accountName = campaignAccountName || formAccountName;
    const masterListGetter = useCurrentValueGetter(get(formData, field, []));
    const queryClient = useQueryClient();
    const { checkValidSites } = useSitesApi();
    const { value: supplyGroup } = useSupplyTargetingFormField();
    const { data, isSuccess } = useQuery(
        [RESOURCES_PREFIX, listKey, accountName],
        () => {
            const siteKeyList = masterListGetter()
                .filter(site => site.type !== ACCOUNT_TYPE.NETWORK)
                .map(site => site[siteResourceFieldKeyName]);

            if (isEmpty(siteKeyList)) {
                return { results: [] };
            }
            return checkValidSites(accountName, siteKeyList, supplyGroup);
        },
        { select: response => response?.results, staleTime: STALE_TIME }
    );

    useEffect(
        () => () => queryClient.removeQueries([RESOURCES_PREFIX, listKey, accountName]),

        [queryClient, listKey, accountName]
    );

    const transformedData = useMemo(() => keyBy(data, siteResourceFieldKeyName), [data]);

    return { data: transformedData, isReady: isSuccess };
};
