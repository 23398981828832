import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n/index';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import EllipsisCellRenderer from './EllipsisCellRenderer';

class FormattedNumberCellRenderer extends Component {
    render() {
        const {
            value,
            style,
            currency,
            minimumFractionDigits,
            maximumFractionDigits,
            forceSign,
            lines,
            context,
            showEmptyWhenZero,
        } = this.props;

        if (value === null && showEmptyWhenZero) {
            return EMPTY_FIELD_VALUE;
        }

        return (
            <EllipsisCellRenderer
                lines={lines}
                value={
                    <FormattedNumber
                        value={value == null ? 0 : value}
                        variant={style}
                        currency={(context && context.currency) || currency}
                        minimumFractionDigits={minimumFractionDigits}
                        maximumFractionDigits={maximumFractionDigits}
                        forceSign={forceSign}
                    />
                }
            />
        );
    }
}

FormattedNumberCellRenderer.propTypes = {
    /** Value to display */
    value: PropTypes.number,
    /** The style of this number, one of: 'decimal' | 'currency' | 'percent' */
    style: PropTypes.oneOf(['decimal', 'currency', 'percent']),
    /** The currency format of the value to display */
    currency: PropTypes.string,
    /** Minimal number of decimal digits */
    minimumFractionDigits: PropTypes.number,
    /** Maximal number of decimal digits */
    maximumFractionDigits: PropTypes.number,
    /** Display + sign for positive numbers */
    forceSign: PropTypes.bool,
    lines: PropTypes.number,
    /** context of AgGrid */
    context: PropTypes.shape({
        /** selected account currency */
        currency: PropTypes.string,
    }),
};

FormattedNumberCellRenderer.defaultProps = {
    maximumFractionDigits: 3,
    forceSign: false,
    lines: 1,
};

export default FormattedNumberCellRenderer;
