import React from 'react';
import { CheckboxGroup, FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { useCampaignGroupExtensionEnabled } from 'modules/campaigns/modules/campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { vtaLookBackWindowValidations } from 'modules/tracking/validations/vtaLookBackWindowValidations';
import { useVtaLookBackWindowValue } from './UseVtaLookBackWindowValue';
import { VtaLookbackWindowInputWithDropdown } from './VtaLookbackWindowInputWithDropdown/VtaLookbackWindowInputWithDropdown';
import { useVtaLookBackWindowField } from './useVtaLookBackWindowField';
import styles from './vtaLookBackWindowInput.module.scss';

export const VtaLookBackWindowField = () => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    const {
        lookBackFormFieldType: { value: vtaLookBackWindowType },
        lookBackFormFieldValue: { onChange: onVtaLookBackWindowChange },
    } = useVtaLookBackWindowField();

    const { value: isViewThroughWindowDisabled, onChange: setIsViewThroughWindowDisabled } = useFormFieldValue({
        field: 'isViewThroughWindowDisabled',
    });
    const lookBackMaxValue = useVtaLookBackWindowValue();

    return (
        <div className={styles['body']}>
            <FormField
                inputId="vta-look-back-window"
                label={
                    <FormattedMessage
                        id="tracking.conversion.properties.vta.window.title"
                        defaultMessage="View Through Conversion Window"
                    />
                }
                description={
                    <FormattedMessage
                        id="tracking.conversion.properties.vta.window.description"
                        defaultMessage="Set the time after a view during which a conversion will be recorded.{newline}Notice: this will only apply to accounts that have checked the ‘Clicks and view-through’ attribution settings."
                    />
                }
                subtext={
                    <FormattedMessage
                        id={`tracking.conversion.properties.vta.window.subtext.${vtaLookBackWindowType}`}
                        defaultMessage="Max: {max} days"
                        values={{
                            max: lookBackMaxValue[vtaLookBackWindowType],
                        }}
                    />
                }
            >
                <VtaLookbackWindowInputWithDropdown
                    validations={vtaLookBackWindowValidations}
                    isDisabled={isViewThroughWindowDisabled}
                />
            </FormField>
            {campaignGroupExtensionEnabled && (
                <CheckboxGroup
                    onSelectCheckbox={() => {
                        onVtaLookBackWindowChange(0);
                        setIsViewThroughWindowDisabled(true);
                    }}
                    onUnselectCheckbox={() => setIsViewThroughWindowDisabled(false)}
                    selectedValues={[isViewThroughWindowDisabled]}
                >
                    <TextCheckbox
                        value
                        title={
                            <FormattedMessage
                                id="tracking.conversion.properties.vta.window.checkbox.title"
                                defaultMessage="Don't count View Through Conversions for this campaign"
                            />
                        }
                    />
                </CheckboxGroup>
            )}
        </div>
    );
};
