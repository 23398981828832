import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import graph from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const contextualVideoReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CONTEXTUAL_VIDEO],
    endpoint: 'campaign-report-by-context',
    graph,
    rowIdField: FIELDS.CONTEXT.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    isEmptyFooter: true,
    sortMap: {},
    searchPlaceholderMsgId: 'app.reports.byContext.search.placeholder',
    searchField: FIELDS.CONTEXT.field,
    filters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP100, label: 'Top 100', messageId: 'report.topResults.100' }],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
    ],
};

export default contextualVideoReportConfig;
