import { entries, isEmpty } from 'lodash';
import { set as fpSet } from 'lodash/fp';
import { batchApiCall } from '../utils/batchApiCall';
import { wrapApiCallWithEvent } from '../utils/bulkUploadGtmUtils';

export const getUploadExcelRequestMap = async ({
    entitiesData,
    apiCallerMap,
    setMaxEntitiesCount,
    setProcessedEntitiesCount,
    formatMessage,
}) => {
    const requestMap = entries(entitiesData).reduce((acc, [entity, entityDataList]) => {
        const requestBody = { elements: entityDataList };
        const { apiCall, batchSize, eventName } = apiCallerMap[entity];
        const batchedApiCall = batchApiCall({ apiCall, batchSize, setProcessedEntitiesCount, formatMessage });
        const wrappedApiCall = wrapApiCallWithEvent(batchedApiCall, eventName);

        setMaxEntitiesCount(fpSet(entity, entityDataList.length));

        acc[entity] = isEmpty(entityDataList) ? requestBody : wrappedApiCall({ entity, requestBody });

        return acc;
    }, {});

    return requestMap;
};
