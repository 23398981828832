import React from 'react';
import { TargetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../components/Form';
import { VastUrlField } from '../VastUrlField/VastUrlField';

export const VideoSection = () => (
    <CommonCollapsibleCard
        id="VIDEO_CREATIVE_VIDEO"
        header={
            <SectionHeader
                headerIcon={<TargetIcon />}
                headerText={<FormattedMessage id="video.creative.editor.section.video" defaultMessage="Video" />}
            />
        }
    >
        <VastUrlField />
    </CommonCollapsibleCard>
);

export default VideoSection;
