import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-hour-of-day-performance',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.CTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.VCPM,
        metrics.CPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
        metrics.ROAS_CLICKS,
        metrics.ROAS_VIEWS,
    ],
    defaultSort: [{ field: 'hour_of_day', order: SORTING_ORDER_TYPES.ASCENDANT }],
    maxAllowedSelectedMetrics: 3,
    graphProps: {
        xAxisDataKey: FIELDS.HOUR_OF_DAY.field,
    },
};

export default graph;
