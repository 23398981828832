import React from 'react';
import PropTypes from 'prop-types';
import { FocalPointEditor } from '../../../../../creative-editor/components/ThumbnailEditor/FocalPoint';
import { FOCUS_TYPES } from '../../../../../creative-editor/config';
import styles from './focalPointEditorWrapper.module.scss';

const FocalPointEditorWrapper = ({
    stopEditing,
    selectedImgSrc,
    creativeFocus,
    setSelectedImgSrc,
    setCreativeFocus,
    setCreativeFocusInReducer,
}) => {
    const { value, creativeFocus: imgCreativeFocus, displayUrl } = selectedImgSrc;

    const encodedImgSrc = encodeURIComponent(displayUrl);

    const onCancelEditing = () => {
        setCreativeFocusInReducer({
            thumbnailUrl: value,
            creativeFocus: imgCreativeFocus,
        });
        setSelectedImgSrc({});
        stopEditing();
    };

    const onChangeCoordinates = coordinates => {
        const newCreativeFocus = {
            type: FOCUS_TYPES.COORDINATES,
            coordinates,
        };
        setCreativeFocus(newCreativeFocus);
        setCreativeFocusInReducer({
            thumbnailUrl: displayUrl,
            creativeFocus: newCreativeFocus,
        });
    };

    const onResetFocalPoint = () => {
        const defaultCreativeFocus = {
            type: FOCUS_TYPES.AUTOMATIC,
            coordinates: null,
        };
        setCreativeFocus(defaultCreativeFocus);
        setCreativeFocusInReducer({
            thumbnailUrl: displayUrl,
            creativeFocus: defaultCreativeFocus,
        });
    };

    return (
        <div className={styles['container']}>
            <FocalPointEditor
                imageSrc={encodedImgSrc}
                creativeFocus={creativeFocus}
                imageContainerClassName={styles['thumbnail-container']}
                imageClassName={styles['thumbnail-image']}
                onCancel={onCancelEditing}
                onDone={stopEditing}
                onChangeCoordinates={onChangeCoordinates}
                onResetFocalPoint={onResetFocalPoint}
            />
        </div>
    );
};

FocalPointEditorWrapper.propTypes = {
    stopEditing: PropTypes.func,
    selectedImgSrc: PropTypes.object,
    creativeFocus: PropTypes.object,
    setSelectedImgSrc: PropTypes.func,
    setCreativeFocus: PropTypes.func,
    setCreativeFocusInReducer: PropTypes.func,
};

FocalPointEditorWrapper.defaultProps = {
    creativeFocus: {},
};

export default FocalPointEditorWrapper;
