import React from 'react';
import { FORM_MODES } from 'config/formModes';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';
import { usePermissions } from '../../../taboola-common-frontend-modules/authentication';
import { generatePaymentMethodFormPath } from '../../config/routes/generatePaymentMethodFormPath';
import { usePaymentDeleteConfirmation } from '../../hooks/usePaymentDeleteConfirmation';
import styles from './singlePaymentMethod.module.scss';

export const SinglePaymentMethod = ({ Component, onDelete, currency, billingData, paymentMethodData }) => {
    const { id: paymentMethodId, paymentMethodType } = paymentMethodData;
    const { accountName } = useAccount();
    const isEditPermitted = usePermissions('BUDGET_MANAGEMENT_PAYMENT_METHODS_EDIT');

    const editPath = isEditPermitted
        ? generatePaymentMethodFormPath({
              accountName,
              mode: FORM_MODES.EDIT,
              paymentMethodId,
              paymentMethodType,
          })
        : null;

    const { confirmPaymentDelete } = usePaymentDeleteConfirmation({
        onDelete,
        accountName,
        currency,
        paymentMethodId,
        paymentMethodType,
        billingData,
    });

    return (
        <Component
            {...paymentMethodData}
            className={styles['payment-method']}
            editPath={editPath}
            onDelete={isEditPermitted ? confirmPaymentDelete : null}
        />
    );
};
