"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgStarO(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M7.086 18.813a.99.99 0 0 1-.383-1.067l1.164-4.243L4.38 10.74c-.33-.255-.46-.69-.33-1.084.132-.396.495-.67.913-.69l4.485-.227 1.6-4.1a1.025 1.025 0 0 1 1.9.001l1.607 4.1 4.484.227c.417.02.78.293.914.69.13.394 0 .827-.328 1.084L16.14 13.5l1.165 4.242a.992.992 0 0 1-.384 1.067c-.34.242-.795.254-1.15.03l-3.766-2.4-3.766 2.4a1.028 1.028 0 0 1-1.152-.026h-.001zm5.47-4.394l2.098 1.333-.647-2.354a.989.989 0 0 1 .342-1.038l1.933-1.53-2.487-.128a1.013 1.013 0 0 1-.895-.638L12 7.78l-.896 2.286c-.147.372-.5.623-.9.64l-2.488.126 1.934 1.53a.992.992 0 0 1 .343 1.038l-.647 2.36 2.096-1.335a1.038 1.038 0 0 1 1.115-.007l-.001.001z"
            })
        )
    );
}
exports.default = SvgStarO;