import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGNS_ROUTE, CREATIVE_BULK_EDITOR_ROUTE_PATH, FORM_MODES } from 'modules/campaigns/config';
import { replacePathParams } from 'modules/campaigns/services/utils';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CreativeBulkEditor } from '../modules/creative-bulk-editor/CreativeBulkEditor';

export const CreativeBulkEditorPage = ({ onCancel }) => {
    const sponsoredBulkEditPath = replacePathParams(CREATIVE_BULK_EDITOR_ROUTE_PATH, {
        dimension: [CAMPAIGN_DIMENSION.SPONSORED],
        mode: [FORM_MODES.BULK_EDIT],
    });

    useUnsavedChangesController(CREATIVE_BULK_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                path={sponsoredBulkEditPath}
                render={props => <CreativeBulkEditor onCancel={onCancel} sourcePath={CAMPAIGNS_ROUTE} {...props} />}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CreativeBulkEditorPage.propTypes = {
    onCancel: PropTypes.func,
};

export default CreativeBulkEditorPage;
