import { useQuery } from 'react-query';
import { useUnipFunnelApi } from 'services/api/unipFunnelAPI';
import { isNetworkAccountType } from '../../account-management';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';

export const useIsTotalAccountConversionsValidForFunnel = () => {
    const { getConversionRulesData } = useUnipFunnelApi();
    const { accountName, type } = useAccount();

    const { data: TotalNumberOfAccountConversions } = useQuery(
        [accountName, 'funnelEmptyState'],
        () => getConversionRulesData(accountName, isNetworkAccountType(type), 1, 30),
        {
            select: data => data?.metadata?.total,
        }
    );

    return TotalNumberOfAccountConversions > 1;
};
