import React, { useCallback, useMemo } from 'react';
import { useSelectedAccount } from 'hooks';
import PERFORMANCE_RECOMMENDATIONS from 'modules/campaigns/modules/performance-recommendations/config/performanceRecommendations';
import { useRecommendationService } from 'modules/campaigns/modules/performance-recommendations/hooks/useRecommendationService';
import { useCpaRecommendationMetadata } from '../../../../../modules/campaigns/modules/performance-recommendations/hooks/useCpaRecommendationMetadata';
import {
    COMMON_FLAGS,
    useConfigMatch,
} from '../../../../../modules/taboola-common-frontend-modules/account-configurations';
import { FormattedRelativeTime } from '../../../../../modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';
import { truncateTargetName } from '../../utils';

export const UnrealisticTargetNotification = ({ notification, timeDiffInMinutes, closeNotificationCenter }) => {
    const {
        attributes: { descriptionParams: messageParams, recommendationTargetName, recommendationTargetId: campaignId },
        referredEntityId: recommendationId,
    } = notification;

    const [{ id: accountId }] = useSelectedAccount();
    const { editCampaignWithRecommendation } = useRecommendationService();
    const shouldRecommendTarget = useConfigMatch(
        {},
        { [COMMON_FLAGS.UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET]: 'true' }
    );

    const recommendationMetadata = useCpaRecommendationMetadata(messageParams, shouldRecommendTarget);
    const { formattedRecommendedTargetCpa } = recommendationMetadata;
    const formattedNotification = formatCpaGoal(notification, formattedRecommendedTargetCpa);

    const onCardClick = useCallback(
        props => editCampaignWithRecommendation({ ...props, recommendationMetadata }),
        [editCampaignWithRecommendation, recommendationMetadata]
    );

    const finalRecommendationTargetName = useMemo(
        () => truncateTargetName(recommendationTargetName),
        [recommendationTargetName]
    );

    const descriptionCodeSuffix = shouldRecommendTarget ? 'with-target' : '';

    return (
        <NotificationCard
            notification={formattedNotification}
            cardClick={onCardClick}
            cardClickParams={{
                recommendation: {
                    type: PERFORMANCE_RECOMMENDATIONS.UNREALISTIC_TCPA,
                    id: recommendationId,
                },
                recommendationData: { bidStrategy: undefined },
                accountId,
                campaignId,
            }}
            closeNotificationCenter={closeNotificationCenter}
            descriptionCodeSuffix={descriptionCodeSuffix}
            dataMetricsComponent="NotificationAlertCard"
            formattedMessageId="app.notificationsCenter.recommendationCampaignTargetDetails"
            formattedMessageValues={{
                recommendationTargetName: finalRecommendationTargetName,
                recommendationTargetId: campaignId,
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
        />
    );
};

const formatCpaGoal = (notification, formattedCpaGoal) => {
    return {
        ...notification,
        attributes: {
            ...notification.attributes,
            descriptionParams: {
                ...notification.attributes.descriptionParams,
                cpaGoal: formattedCpaGoal,
            },
        },
    };
};
