import moment from 'moment';

const DEFAULT_MIN_INTERVAL = 5;
export const getGraphTimeScale = ({
    startPoint,
    endPoint,
    intervalDuration = DEFAULT_MIN_INTERVAL,
    intervalUnit = 'minutes',
}) => {
    let point = moment(startPoint).utc().startOf('date');
    const end = endPoint || moment(point).utc().add('day', 1);
    const scale = [];

    while (point.isBefore(end)) {
        scale.push(point.valueOf());
        point = point.add(intervalDuration, intervalUnit);
    }
    return scale;
};
