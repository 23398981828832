import React from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

const CpcvCompletedViewsSubText = ({ currency }) => {
    const { value: businessModel } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });
    const { value: budget } = useFormFieldValue({ field: 'videoBudget.budget' });
    const { value: rate } = useFormFieldValue({ field: 'videoBudget.rate' });

    const expectedCompletedViews = Math.floor(budget / rate);
    const displayExpectedCompletedViews =
        businessModel === VIDEO_PRICING_MODEL.CPCV && expectedCompletedViews > 0 && isFinite(expectedCompletedViews);

    if (!displayExpectedCompletedViews) {
        return null;
    }

    return (
        <FormattedMessage
            id="video.campaign.editor.cpcvCompletedViews.estimate"
            defaultValue=""
            values={{
                budget: (
                    <span className={styles['bold-text']}>
                        <FormattedNumber value={budget} variant="currency" currency={currency} />
                    </span>
                ),
                completions: (
                    <span className={styles['bold-text']}>
                        <FormattedNumber value={expectedCompletedViews} />
                    </span>
                ),
            }}
        />
    );
};

export default CpcvCompletedViewsSubText;
