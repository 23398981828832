import { useQuery } from 'react-query';
import { map } from 'lodash';
import { DAY } from '../modules/audiences/components/SegmentTopics/customContextualTopicsUtils';
import { useResourcesApi } from '../services/api';

export const useCountries = () => {
    const { getCountries } = useResourcesApi();

    const { data: countries, isLoading } = useQuery(['countries'], () => getCountries(), {
        refetchOnWindowFocus: false,
        select: data => map(data.results, countryData => ({ value: countryData.name, label: countryData.value })),
        cacheTime: DAY,
        staleTime: DAY,
    });

    return {
        countries,
        isLoading,
    };
};
