import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const maxCreativeNameLength = 255;
const maxCustomIDLength = 30;

export const creativeNameValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: maxCreativeNameLength },
        messageId: 'creative.creator.creation.creative.name.validations.length.error',
    },
];

export const customIDValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: maxCustomIDLength },
        messageId: 'creative.creator.creation.custom.name.validations.length.error',
    },
];
