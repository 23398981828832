import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations/services';

const emailSyntaxValidation = {
    validationFn: validationFunctions.isEmail,
    messageId: 'validations.error.field.email.invalid',
};

const emailValidations = [emailSyntaxValidation];

export { emailValidations };
