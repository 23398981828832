import React from 'react';
import styles from './DSPLoader.module.scss';

export const DSPLoader = () => (
    <div className={styles['loader']}>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
        <div className={styles['dot']}></div>
    </div>
);
