import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { AccountDropdown } from 'modules/campaigns/modules/common-campaign-form';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './accountsSection.module.scss';

const dropdownStyles = {
    control: provided => ({
        ...provided,
        borderColor: styles.gray5,
        '&:hover': {},
    }),
    placeholder: provided => ({
        ...provided,
        color: styles.gray5,
    }),
};

const AccountsSections = () => {
    const { formAccount, setFormAccount } = useFormDataContext();

    return (
        <FormField inputId="creative-duplicate-account-selector" containerClass={classnames(styles['section'])}>
            <AccountDropdown
                accountForCampaign={formAccount}
                onAccountChange={setFormAccount}
                helpText={
                    <FormattedMessage
                        id="creative.editor.duplicate.section.accounts.tooltip"
                        defaultMessage="This ad will be duplicated into the selected account."
                    />
                }
                dropdownStyles={dropdownStyles}
                skipFormResetOnAccountChange
            />
        </FormField>
    );
};

export default AccountsSections;
