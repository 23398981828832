import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { useEventListener } from 'taboola-ultimate-ui';
import { useNonAuthAccess } from 'hooks/useNonAuthAccess';
import { useCurrentValueGetter } from '../../../../hooks';
import { useUserId } from '../../../../hooks/queryParams/useUserId';
import { useAuthenticationApi } from '../../../../services/api';
import setFatalError from '../../../errors/actions/setFatalError';
import { isMessageBasedAuth } from '../../iframe/utils/isMessageBasedAuth';
import { isSeparateAppInstanceForbidden } from '../../iframe/utils/isSeparateAppInstanceForbidden';
import { useAuthenticationType } from '../../iframe/utils/useAuthenticationType';
import { fetchUser } from '../index';
import { authTokenManager } from '../services/AuthTokenManager';
import { loginAndBackStartPage } from '../services/pageRedirect';
import { parseAndProcessToken } from '../utils/auth-utils';
import { STORAGE_EVENT_DEBOUNCE_TIME } from './storageEventDebounceTime';
import { useTokenRefreshTrigger } from './useTokenRefreshTigger';

export const useAuth = () => {
    const dispatch = useDispatch();
    const authenticationApi = useAuthenticationApi();
    const [userId] = useUserId();
    const userIdGetter = useCurrentValueGetter(userId);
    const [tokenRefreshTrigger] = useTokenRefreshTrigger();
    const nonAuthAccess = useNonAuthAccess();
    // we need to call this hook to cache value correctly for isMessageBasedAuth
    useAuthenticationType();

    useEffect(() => {
        if (isSeparateAppInstanceForbidden() || isMessageBasedAuth() || nonAuthAccess) {
            return;
        }
        // Once app. live and we don't have token two cases are possible
        // 1) We should forward user to auth page to authenticate
        // 2) We are back from auth page and need to parse token from hash
        try {
            if (!authTokenManager.getToken()) {
                parseAndProcessToken(userIdGetter());
            }
        } catch (error) {
            dispatch(setFatalError(error));
        }

        // Once app. live and we have token we need to fetch user
        if (authTokenManager.getToken()) {
            dispatch(fetchUser(authenticationApi, userIdGetter()));
        }
    }, [dispatch, authenticationApi, userIdGetter, nonAuthAccess]);

    useEffect(() => {
        if (tokenRefreshTrigger && authTokenManager.getToken()) {
            dispatch(fetchUser(authenticationApi, userIdGetter(), isMessageBasedAuth()));
        }
    }, [tokenRefreshTrigger, userIdGetter, authenticationApi, dispatch]);

    // If user did sign out on another tab and switched to unauthenticated tab go auth page
    useEventListener(
        'visibilitychange',
        () => {
            if (nonAuthAccess) {
                return;
            }
            if (!document.hidden && !authTokenManager.getToken()) {
                loginAndBackStartPage(userIdGetter());
            }
        },
        document
    );

    const tokenDebouncedHandler = useMemo(
        () =>
            debounce(event => {
                authTokenManager.updateTokenOnStorageChange(event);
                dispatch(fetchUser(authenticationApi));
            }, STORAGE_EVENT_DEBOUNCE_TIME),
        [dispatch, authenticationApi]
    );

    // If user did log out/in on other tab all tabs should be aligned
    useEventListener('storage', event => {
        if (nonAuthAccess) {
            return;
        }
        if (!authTokenManager.isRelevantStorageEvent(event)) {
            return;
        }

        if (authTokenManager.clearOnStorageEvent(event)) {
            return;
        }

        tokenDebouncedHandler(event);
    });
};
