import { useCallback, useEffect, useMemo, useRef } from 'react';
import { difference, isEmpty, keyBy } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { TARGETING_TYPES } from '../CombinedTargetingTypes';

export const useSelectedCombinedSegments = ({
    selectedNodes,
    setSelectedNodesByIds,
    targetingType,
    setIsReady,
    isReady,
}) => {
    const {
        value: audiences,
        onChange: onChangeAudiences,
        indicationData,
        scrollRef,
        isDirty,
    } = useFormFieldValue({
        field: 'audiences',
    });

    const leavesByIdMap = useMemo(() => keyBy(selectedNodes, 'id'), [selectedNodes]);

    const leavesInitRef = useRef(false);
    const initLeavesCallback = async () => {
        leavesInitRef.current = true;

        if (!isEmpty(audiences)) {
            setSelectedNodesByIds(audiences);
            return;
        }

        setIsReady(true);
    };

    useEffect(() => {
        if (leavesInitRef.current) {
            return;
        }

        initLeavesCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDirty) {
            return;
        }
        setSelectedNodesByIds(audiences);
    }, [audiences, isDirty, setSelectedNodesByIds]);

    useEffect(() => {
        if (!leavesInitRef.current) {
            return;
        }
        const treeSelectedLeafIds = selectedNodes.map(item => item.id);
        const addedIds = difference(treeSelectedLeafIds, audiences);

        if (addedIds.length) {
            onChangeAudiences(prev => [...prev, ...addedIds]);
        }
    }, [audiences, onChangeAudiences, selectedNodes, targetingType, isReady, isDirty]);

    const { nodes } = useMemo(
        () => ({
            nodes: audiences.map(id => ({
                id,
                ...leavesByIdMap[id],
                loading: !leavesByIdMap[id],
                targetingType: TARGETING_TYPES.AND,
            })),
        }),
        [audiences, leavesByIdMap]
    );

    const removeAudiencesNodes = useCallback(() => {
        setSelectedNodesByIds([]);
        onChangeAudiences([]);
    }, [setSelectedNodesByIds, onChangeAudiences]);

    return {
        nodes,
        removeNodes: removeAudiencesNodes,
        indicationData,
        scrollRef,
    };
};
