import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isCarouselCreative } from '../../../creative-creator/components/CarouselCardList/utils/isCarouselCreative';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import ToggleSwitchCellRenderer from './ToggleSwitchCellRenderer';

const TooltipMessage = ({ value, reportId, defaultMessage = 'Campaign Status' } = {}) => (
    <FormattedMessage id={`app.campaigns.${reportId}.state.tooltip.${value}`} defaultMessage={defaultMessage} />
);

const StateCellRenderer = ({ data, isDisabled, ...rest }) => {
    const { isGridEditDisabled } = useGridContext();
    const { context } = rest;
    const tooltipContent = isCarouselCreative(data) ? (
        <FormattedMessage
            id="app.campaigns.creative.state.hierarchy.tooltip"
            defaultMessage="To change a carousel card status please go to Edit Carousel"
        />
    ) : (
        <TooltipMessage data={data} {...rest} />
    );

    return (
        <TooltipWrapper
            tooltipContent={tooltipContent}
            tooltipOptions={{ interactive: false, arrow: true, placement: TOOLTIP_POSITION.RIGHT }}
        >
            <ToggleSwitchCellRenderer
                {...rest}
                data={data}
                disabled={isGridEditDisabled || isDisabled(data, context)}
            />
        </TooltipWrapper>
    );
};

StateCellRenderer.propTypes = {
    ...ToggleSwitchCellRenderer.propTypes,
    isDisabled: PropTypes.func,
};

StateCellRenderer.defaultProps = {
    isDisabled: () => false,
};

export default StateCellRenderer;
