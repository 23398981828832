import { getCampaignEntity } from '../../../config';
import { removeFromCampaignProperty } from '../../../flows';
import { reportDataSelector, selectedCampaignSelector } from '../../../selectors';
import setReportData from '../actions/setReportData';
import { prepareRollBackData } from './saveCampaign';

export const saveCampaignExplorationStatusModification =
    ({ accountName, siteName }, oldValue, rowIndex) =>
    (dispatch, getState) => {
        const state = getState();

        const { explorationStatus: explorationOldValue } = oldValue;
        const reportData = reportDataSelector(state);
        const { id: campaignId, demandType } = selectedCampaignSelector(state);
        const rollBackData = prepareRollBackData(rowIndex, { explorationStatus: explorationOldValue }, reportData);
        const rollBack = () => dispatch(setReportData(rollBackData));
        const publisherData = {
            segmentExplorationSiteName: siteName,
        };

        dispatch(
            removeFromCampaignProperty({
                accountId: accountName,
                campaignId,
                changes: publisherData,
                onError: rollBack,
                entityType: getCampaignEntity({ demandType }),
            })
        );
    };
