import React from 'react';
import { isEmpty } from 'lodash';
import { Button, UploadOutlinedIcon } from 'tuui';
import { FormField, DragAndDropFile, DragAndDropFileContext } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FileSection } from '../../../FileSection/FileSection';
import { ImageDropSVGIcon } from './ImageDropSVGIcon';
import styles from './brandLogo.module.scss';

const IMAGE_FILES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
};
const DragAndDropWithIndication = withIndication(DragAndDropFile);

export const SelectBrandLogo = ({ onDrop, isLoading, cleanValue, fileName, failedValidationData, disabled }) => (
    <FormField
        containerClass={styles['brandLogoContainer']}
        label={
            <FormattedMessage
                id="video.creative.creator.logo.brandLogo.title"
                defaultMessage="Brand Logo Overlay (Optional)"
            />
        }
        description={
            <FormattedMessage
                id="video.creative.creator.logo.brandLogo.description"
                defaultMessage="Add your company logo"
            />
        }
    >
        <DragAndDropWithIndication
            multiple={false}
            onDrop={onDrop}
            fileTypes={IMAGE_FILES}
            disabled={disabled}
            errorMessageClass={styles['errorMesssage']}
            {...failedValidationData}
        >
            <DragAndDropFileContext.Consumer>
                {({ openFileDialog }) => (
                    <>
                        <div className={styles['dropZone']}>
                            <FileSection
                                icon={ImageDropSVGIcon}
                                fileName={fileName}
                                isLoading={isLoading}
                                onRemoveImage={cleanValue}
                                message={{
                                    id: 'video.creative.creator.logo.brandLogo.dragdrop.description',
                                    defaultMessage: 'Drag & Drop PNG, JPEG, JPG file',
                                }}
                            />
                        </div>
                        <Button
                            onClick={openFileDialog}
                            size={Button.size.md}
                            variant={Button.variant.ghost}
                            disabled={disabled}
                            data-automation="logoFileUploadButton"
                        >
                            <>
                                <UploadOutlinedIcon />
                                <FormattedMessage
                                    id={`video.creative.creator.logo.brandLogo.${
                                        isEmpty(fileName) ? 'button' : 'change'
                                    }`}
                                    defaultMessage="Upload File"
                                />
                            </>
                        </Button>
                    </>
                )}
            </DragAndDropFileContext.Consumer>
        </DragAndDropWithIndication>
    </FormField>
);
