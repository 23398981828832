export const PRICING_MODEL = {
    CPC: 'CPC',
    CPM: 'CPM',
    VCPM: 'VCPM',
};

export const isPricingModelCpmOrVcpm = pricingModel =>
    pricingModel === PRICING_MODEL.CPM || pricingModel === PRICING_MODEL.VCPM;

export default PRICING_MODEL;
