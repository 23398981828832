import { MOAT_BILLING_OPTIONS } from '../../modules/common-campaign-form/config';

const transformMoatBillingFromGW = campaign => {
    const { videoBudget } = campaign;
    if (!videoBudget) {
        return campaign;
    }
    return {
        ...campaign,
        videoBudget: {
            ...videoBudget,
            moatBilling: videoBudget.moatBilling ? [MOAT_BILLING_OPTIONS.MOAT_BILLING] : [],
        },
    };
};
const transformMoatBillingToGW = campaign => {
    const { videoBudget } = campaign;
    if (videoBudget) {
        const { moatBilling } = videoBudget;
        if (!moatBilling) {
            return campaign;
        }

        return {
            ...campaign,
            videoBudget: { ...videoBudget, moatBilling: !!videoBudget.moatBilling.length },
        };
    } else {
        return campaign;
    }
};

export { transformMoatBillingFromGW, transformMoatBillingToGW };
