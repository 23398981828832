import { useQuery } from 'react-query';
import { useRecommendationsApi } from '../../../../../services/api/recommendationsApi';
import { useMultiLayerPathParams } from '../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useRecommendationsQueryKey } from './useRecommendationsQueryKey';

const usePerformanceRecommendationsReviewerState = () => {
    const recommendationsApi = useRecommendationsApi();
    const key = useRecommendationsQueryKey();
    const { accountId, campaignId } = useMultiLayerPathParams();
    const { data: { results: recommendations } = {}, isLoading } = useQuery(key, () =>
        recommendationsApi.getCampaignRecommendations(accountId, campaignId)
    );

    return {
        recommendations,
        isLoading,
    };
};

export default usePerformanceRecommendationsReviewerState;
