import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './focalPointApplyCheckbox.module.scss';

const classNameBuilder = classnames.bind(styles);

const applyToAllValue = 'APPLY_TO_ALL';

export const FocalPointApplyCheckbox = ({ className, setApply, isApplied, creativeCount }) => {
    const handleSelectCheckbox = useCallback(() => setApply(true), [setApply]);
    const handleUnselectCheckbox = useCallback(() => setApply(false), [setApply]);
    const creativeCountString = isNil(creativeCount) ? '' : `${creativeCount} `;

    return (
        <CheckboxGroup
            className={classNameBuilder('container', className)}
            onSelectCheckbox={handleSelectCheckbox}
            onUnselectCheckbox={handleUnselectCheckbox}
            selectedValues={isApplied ? [applyToAllValue] : []}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={!isApplied}
            data-metrics-component="FocalPointApplyCheckbox"
        >
            <TextCheckbox
                labelClassName={styles['checkbox-label']}
                value={applyToAllValue}
                title={
                    <FormattedMessage
                        id="focal.point.apply.to.all"
                        defaultMessage="Apply to all {count}ads using this image"
                        values={{ count: creativeCountString }}
                    />
                }
            />
        </CheckboxGroup>
    );
};

FocalPointApplyCheckbox.propTypes = {
    className: PropTypes.string,
    setApply: PropTypes.func,
    isApplied: PropTypes.bool,
    creativeCount: PropTypes.number,
};
