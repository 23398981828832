import React, { useMemo } from 'react';
import { CheckCircleOutlineIcon } from 'tuui';
import { Divider } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PreviewsDrawerCard } from './PreviewsDrawerCard';
import { usePreviewDrawerOptions } from './hooks/usePreviewDrawerOptions';
import styles from './previewsDrawer.module.scss';

export const PreviewsDrawer = ({
    closeModal,
    openMotionStudioModal,
    openCreativeStudioModal,
    src,
    originalItem,
    accountId,
    campaignId,
    addImageHandler,
    closeCreativeStudioModal,
    urlTransformer,
    shouldDisableMotionAdsButton,
}) => {
    // Want the number to be re-generated on a different render
    const imageSetNumber = useMemo(() => Math.floor(Math.random() * 4), []);

    const options = usePreviewDrawerOptions({
        closeModal,
        openMotionStudioModal,
        openCreativeStudioModal,
        src,
        originalItem,
        accountId,
        campaignId,
        addImageHandler,
        closeCreativeStudioModal,
        urlTransformer,
        shouldDisableMotionAdsButton,
    });

    return (
        <div className={styles['previews-drawer-container']}>
            <div className={styles['previews-header']}>
                <div className={styles['previews-header-body']}>
                    <CheckCircleOutlineIcon className={styles['previews-header-body-icon']} />
                    <div className={styles['previews-header-body-title']}>
                        <FormattedMessage id="previews.drawer.header.title" defaultMessage="Your Image Was Added!" />
                    </div>
                    <FormattedMessage
                        id="previews.drawer.header.subtitle"
                        defaultMessage="You can find it under the media section."
                    />
                </div>

                <div className={styles['previews-header-divider']}>
                    <Divider />
                    <FormattedMessage
                        id="previews.drawer.header.divider"
                        defaultMessage="Add Diverse Variations to Effortlessly Enhance Your Campaign"
                    />
                    <Divider />
                </div>
            </div>
            <div className={styles['previews-container']}>
                {options.map(cardConfig => {
                    return (
                        <li key={cardConfig.id}>
                            <PreviewsDrawerCard {...cardConfig} imageSetNumber={imageSetNumber} />
                        </li>
                    );
                })}
            </div>
            <div className={styles['previews-footer']}>
                <FormattedMessage
                    id="previews.drawer.footer"
                    defaultMessage="*You can always find these advanced options in the media section in the 3 dot menu under the images"
                />
            </div>
        </div>
    );
};
