function formatNumberCompact(value, maximumFractionDigits, minimumFractionDigits = 0, locale = 'en') {
    const formatter = new Intl.NumberFormat(locale, {
        notation: 'compact',
        maximumFractionDigits,
        minimumFractionDigits,
    });
    return formatter.format(value);
}

export default formatNumberCompact;
