import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { PATH_TO_FORM_MODE_MAP } from 'config/routes';
import { SideDrawer } from '../../../../components';
import { LayerRoute } from '../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import {
    CAMPAIGN_EDITOR_ROUTE_PATH,
    CAMPAIGN_ROUTE,
    CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH,
    CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH,
    CREATIVE_BULK_DUPLICATE_ROUTE_PATH,
    CREATIVE_BULK_EDITOR_ROUTE_PATH,
    CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH,
    CREATIVE_EDITOR_ROUTE_PATH,
    EDITOR_ROUTE_PATH,
    PATH_TO_ENTITY_MAP,
    PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH,
} from '../../config';
import { CampaignEditorPage } from '../../pages/CampaignEditorPage';
import { CampaignsGroupBulkEditorPage } from '../../pages/CampaignsGroupBulkEditorPage';
import { CampaignsGroupEditorPage } from '../../pages/CampaignsGroupEditorPage';
import { CreativeBulkDuplicatePage } from '../../pages/CreativeBulkDuplicatePage';
import { CreativeBulkEditorPage } from '../../pages/CreativeBulkEditorPage';
import { CreativeCarouselEditorPage } from '../../pages/CreativeCarouselEditorPage';
import { CreativeEditorPage } from '../../pages/CreativeEditorPage';
import { PerformanceRecommendationsPage } from '../../pages/PerformanceRecommendationsPage';
import { useEditDrawerMode } from './hooks/useEditDrawerMode';

export const EditDrawer = ({ onCancel, ...rest }) => {
    const drawerMode = useEditDrawerMode();
    const { params: { mode: modeParam, entity: entityParam } = {} } = useRouteMatch(EDITOR_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const mode = PATH_TO_FORM_MODE_MAP[modeParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });
    const isCampaignsGroupBulkActionsEnabled = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGNS_GROUP_BULK_ACTIONS_ENABLED]: 'true',
    });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel} drawerMode={drawerMode}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={CAMPAIGN_EDITOR_ROUTE_PATH}
                        render={props => <CampaignEditorPage {...props} onCancel={close} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_EDITOR_ROUTE_PATH}
                        render={props => <CreativeEditorPage {...props} onCancel={close} {...rest} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH}
                        render={props => <CreativeCarouselEditorPage {...props} onCancel={close} {...rest} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_BULK_EDITOR_ROUTE_PATH}
                        render={props => <CreativeBulkEditorPage {...props} onCancel={close} {...rest} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_BULK_DUPLICATE_ROUTE_PATH}
                        render={props => <CreativeBulkDuplicatePage {...props} onCancel={close} {...rest} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH}
                        render={props => <CampaignsGroupEditorPage {...props} onCancel={close} {...rest} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH}
                        render={props => <PerformanceRecommendationsPage {...props} onCancel={close} {...rest} />}
                    />
                    {isCampaignsGroupBulkActionsEnabled && (
                        <LayerRoute
                            path={CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH}
                            render={props => <CampaignsGroupBulkEditorPage {...props} onCancel={close} {...rest} />}
                            hasNestedRoutes
                        />
                    )}
                    <Redirect to={CAMPAIGN_ROUTE} />
                </Switch>
            )}
        </SideDrawer>
    );
};

EditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
    match: PropTypes.object,
};

export default EditDrawer;
