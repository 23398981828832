import React, { useMemo } from 'react';
import { noop } from 'lodash';
import { UploadOutlinedIcon } from 'tuui';
import { DragAndDropFileContext, pxToRem } from 'taboola-ultimate-ui';
import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { ALL_MEDIA_FILES, ONLY_IMAGE_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID } from 'modules/campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import { isFileTypeImage } from 'modules/campaigns/modules/creative-creator/services/utils';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { transformImageItem, transformVideoItem } from '../../../utils/fetchedMediaTransformationUtils';
import { MediaDragAndDrop } from '../../MediaDragAndDrop/MediaDragAndDrop';
import { GALLERY_ITEM_SOURCES } from '../consts';
import styles from './uploadGalleryItem.module.scss';

export const UploadGalleryItem = ({
    onInitUpload,
    onLoadedMedia,
    onRemoveUpload,
    setUploadErrorMessageId,
    itemHeight: getItemHeight = noop,
}) => {
    const itemHeight = useMemo(() => getItemHeight(), [getItemHeight]);
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    const fileTypes =
        creativeType === CREATIVE_TYPE.IMAGE && !isUnifiedCreativesFormDuplicateMode
            ? ONLY_IMAGE_FILES
            : ALL_MEDIA_FILES;
    const onDrop = async (mediaResolver, { fileType }) => {
        const uploadId = onInitUpload();
        try {
            const { url, gifUrl, fallbackImageUrl } = await mediaResolver();
            const isImage = isFileTypeImage(fileType);

            if (url) {
                const uploadedIem = isImage
                    ? transformImageItem({ thumbnailUrl: url }, GALLERY_ITEM_SOURCES.MEDIA_LIBRARY)
                    : transformVideoItem(
                          {
                              videoUrl: modifyExternalUrl(url),
                              gifUrl: modifyExternalUrl(gifUrl),
                              thumbnailUrl: fallbackImageUrl,
                          },
                          GALLERY_ITEM_SOURCES.MEDIA_LIBRARY
                      );
                onLoadedMedia(uploadId, uploadedIem);
            }
        } catch (e) {
            if (e.status === MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID) {
                setUploadErrorMessageId('creative.creator.thumbnails.fetching.thumbnailSizeTooSmall');
            } else if (e.messageCode) {
                setUploadErrorMessageId(e.messageCode);
            } else {
                setUploadErrorMessageId(`creative.creator.thumbnails.fetching.${e?.status}`);
            }
            onRemoveUpload(uploadId);
        }
    };

    return (
        <>
            <MediaDragAndDrop
                fileTypes={fileTypes}
                onDrop={onDrop}
                onFileTypeFailure={() => setUploadErrorMessageId('file.upload.server.error.format')}
                onBeforeFileProcessing={() => setUploadErrorMessageId(null)}
                className={styles['drag-and-drop-container']}
            >
                <div className={styles['container']} style={{ height: pxToRem(itemHeight) }}>
                    <UploadOutlinedIcon className={styles['upload-icon']} />
                    <div className={styles['title']}>
                        <FormattedMessage
                            id="creative.editor.tab.mediaLibrary.upload.item.title"
                            defaultMessage="Upload"
                        />
                    </div>
                    <div className={styles['subtitle']}>
                        <FormattedMessage
                            id="creative.editor.tab.mediaLibrary.upload.item.description"
                            defaultMessage="or drag and drop files here"
                        />
                    </div>
                    <DragAndDropFileContext.Consumer>
                        {({ openFileDialog }) => (
                            <div className={styles['open-dialogue-container']} onClick={() => openFileDialog()} />
                        )}
                    </DragAndDropFileContext.Consumer>
                </div>
            </MediaDragAndDrop>
        </>
    );
};
