"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgMail(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M18 10.033l-5.294 2.634a2 2 0 0 1-1.822-.02L6 10.077V16h12v-5.967zM17.596 8H6.348l5.467 2.877L17.595 8zM4 8.44V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v.34a.996.996 0 0 1 0 .153V16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8.44z"
        })
    );
}
exports.default = SvgMail;