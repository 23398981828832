import { getStatusType, STATUS_TYPE } from './statusConsts';

export const CAMPAIGN_STATUS = {
    RECENT: 'RECENT',
    RUNNING: 'RUNNING',
    PAUSED: 'PAUSED',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    DEPLETED: 'DEPLETED',
    DEPLETED_MONTHLY: 'DEPLETED_MONTHLY',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    PENDING_START_DATE: 'PENDING_START_DATE',
    DISABLED: 'DISABLED',
    TERMINATED: 'TERMINATED',
    FROZEN: 'FROZEN',
};

export const CAMPAIGN_GROUP_STATUS = {
    PAUSED: 'PAUSED',
    EXPIRED: 'EXPIRED',
    RUNNING: 'RUNNING',
    DEPLETED: 'DEPLETED',
    TERMINATED: 'TERMINATED',
};

export const CAMPAIGN_GROUP_STATUS_KEYS = Object.keys(CAMPAIGN_GROUP_STATUS);

export const CAMPAIGN_STATUS_KEYS = Object.keys(CAMPAIGN_STATUS);

const campaignStatusConfig = {
    [CAMPAIGN_STATUS.RUNNING]: { statusType: STATUS_TYPE.SUCCESS },
    [CAMPAIGN_STATUS.PAUSED]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGN_STATUS.PENDING_APPROVAL]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGN_STATUS.PENDING_START_DATE]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGN_STATUS.TERMINATED]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGN_STATUS.PENDING_DFP_SETUP]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGN_STATUS.DFP_INTEGRATION_ERROR]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.DEPLETED_MONTHLY]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.DEPLETED]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.EXPIRED]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.FROZEN]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.REJECTED]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGN_STATUS.DISABLED]: { statusType: STATUS_TYPE.DANGER },
};

export const getCampaignStatusType = campaignStatus => getStatusType(campaignStatusConfig, campaignStatus);

export const isCampaignStatusRejected = ({ status, rejectReason }) =>
    status === CAMPAIGN_STATUS.REJECTED && rejectReason;

export const CAMPAIGN_INSTRUCTION_TYPE = {
    HAS_NO_ITEMS: 'HAS_NO_ITEMS',
    HAS_REGULAR_ITEMS: 'HAS_REGULAR_ITEMS',
    HAS_HIERARCHY_ITEMS: 'HAS_HIERARCHY_ITEMS',
};
