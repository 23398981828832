import { useEffect, useState } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import CONVERSION_RULE_MODES from '../../../config/ConversionRuleModes';
import { useIsConversionRuleDisable } from '../../ConversionOptimization/hook/useIsConversionRuleDisable';
import { useBidStrategyFormField } from './useBidStrategyFormField';

export const useUpdateConversionGoalOnBidStrategyChange = () => {
    const { isDirty: isBidStrategyDirty } = useBidStrategyFormField();
    const { value: conversionRulesMode, onChange: onConversionRulesModeChange } = useFormFieldValue({
        field: 'conversionRulesMode',
    });
    const { value: conversionRules, onChange: onChangeConversionRules } = useFormFieldValue({
        field: 'conversionRules.rules',
    });
    const [cachedConversionRules, setCachedConversionRules] = useState(conversionRules);
    const [cachedConversionRulesMode, setCachedConversionRulesMode] = useState(conversionRulesMode);

    const isConversionGoalDisabled = useIsConversionRuleDisable();

    useEffect(() => {
        if (!isConversionGoalDisabled && isBidStrategyDirty) {
            onConversionRulesModeChange(cachedConversionRulesMode);
            onChangeConversionRules(cachedConversionRules);
        }

        if (isConversionGoalDisabled && isBidStrategyDirty) {
            onConversionRulesModeChange(CONVERSION_RULE_MODES.DEFAULT);
            onChangeConversionRules([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConversionGoalDisabled, onConversionRulesModeChange, onChangeConversionRules, isBidStrategyDirty]);

    // This effect updates the cache when CLC is enabled
    useEffect(() => {
        if (!isConversionGoalDisabled) {
            setCachedConversionRules(conversionRules);
            setCachedConversionRulesMode(conversionRulesMode);
        }
    }, [isConversionGoalDisabled, conversionRules, conversionRulesMode]);
};
