"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgPixel(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M17.889 15.78v-3.336c0-.583 2.111-.583 2.111 0v3.336c0 2.332-1.89 4.22-4.226 4.22H8.226A4.224 4.224 0 0 1 4 15.774V8.226A4.227 4.227 0 0 1 8.225 4h2.803c.583 0 .583 2.111 0 2.111H8.225a2.116 2.116 0 0 0-2.114 2.115v7.548c0 1.17.946 2.115 2.115 2.115h7.548a2.11 2.11 0 0 0 2.115-2.11zM18 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
            })
        )
    );
}
exports.default = SvgPixel;