import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import { columnDefs } from './columnDefinitions';
import { defaultColumns } from './reportPresets';

export const invoicesReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.INVOICES],
    endpoint: 'billing/invoices',
    rowIdField: FIELDS.INVOICE_ID.field,
    searchField: FIELDS.INVOICE_NAME.field,
    searchPlaceholderMsgId: 'billingAndPayments.reports.byInvoiceName.search.placeHolder',
    columnsDef: columnDefs,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    footerColumnDef: [],
    autoFitColumnsToScreen: true,
};
