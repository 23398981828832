import { isNil, map } from 'lodash';
import TargetingTypes from '../../modules/common-campaign-form/config/TargetingTypes';

const findTargetingByType = (targeting, targetingType) =>
    targeting.find(value => {
        const { type } = value;
        return type === targetingType;
    });

const extractAudiencesFromDB = (targeting, targetingType) => {
    // finds first include or exclude targeting - assuming maximum one.
    const { value } = findTargetingByType(targeting, targetingType) || {};
    return value || [];
};

const extractAudiences = (targeting, targetingType) => {
    // finds first include or exclude targeting - assuming maximum one.
    const { values } = findTargetingByType(targeting, targetingType) || {};
    return values || [];
};

const transformMyAudienceTargetingFromDB = campaignOverrideValues => {
    const {
        custom_audience_targeting: { state, value: targetedCustomAudiences },
    } = campaignOverrideValues;
    const includeAudiences = extractAudiencesFromDB(targetedCustomAudiences, TargetingTypes.INCLUDE);

    const excludeAudiences = extractAudiencesFromDB(targetedCustomAudiences, TargetingTypes.EXCLUDE);

    const includeCustomAudiences = map(includeAudiences, audience => ({ id: audience }));

    const excludeCustomAudiences = map(excludeAudiences, audience => ({ id: audience }));

    return {
        campaignTargeting: {
            myAudienceTargeting: {
                include: includeCustomAudiences,
                exclude: excludeCustomAudiences,
                validTargeting: state === 'EXISTS',
            },
        },
    };
};

const transformMyAudienceTargetingFromGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { customAudienceTargeting, lookalikeAudienceTargeting, ...restCampaignTargeting } = campaignTargeting;
    const { collection: targetedCustomAudiences = [], validTargeting: validTargetingCustomAudience = true } =
        customAudienceTargeting || {};
    const { collection: targetedLookalikeAudiences = [], validTargeting: validTargetingLookalike = true } =
        lookalikeAudienceTargeting || {};

    const includeAudiences = extractAudiences(targetedCustomAudiences, TargetingTypes.INCLUDE);
    const excludeAudiences = extractAudiences(targetedCustomAudiences, TargetingTypes.EXCLUDE);
    const lookalikeAudiences = extractAudiences(targetedLookalikeAudiences, TargetingTypes.INCLUDE);
    const includeCustomAudiences = [];
    includeAudiences.forEach(audience => includeCustomAudiences.push({ id: audience }));

    const excludeCustomAudiences = [];
    excludeAudiences.forEach(audience => excludeCustomAudiences.push({ id: audience }));

    return {
        ...campaign,
        campaignTargeting: {
            ...restCampaignTargeting,
            myAudienceTargeting: {
                include: [...includeCustomAudiences, ...lookalikeAudiences],
                exclude: excludeCustomAudiences,
                validTargeting: validTargetingCustomAudience && validTargetingLookalike,
            },
        },
    };
};

const transformMyAudienceTargetingToGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { myAudienceTargeting = {}, ...restCampaignTargeting } = campaignTargeting;
    const { include, exclude } = myAudienceTargeting;
    if (!include && !exclude) {
        return campaign;
    }

    const customAudiencesCollection = [];
    const lookalikeAudiencesCollection = [];

    if (Array.isArray(include) && include.length > 0) {
        const lookalikeAudiences = include.filter(audience => !isNil(audience.similarityLevel));
        if (lookalikeAudiences.length > 0) {
            lookalikeAudiencesCollection.push({ type: TargetingTypes.INCLUDE, values: lookalikeAudiences });
        }

        const customAudiences = include
            .filter(audience => isNil(audience.similarityLevel))
            .map(audience => audience.id);
        if (customAudiences.length > 0) {
            customAudiencesCollection.push({ type: TargetingTypes.INCLUDE, values: customAudiences });
        }
    }

    if (Array.isArray(exclude) && exclude.length > 0) {
        customAudiencesCollection.push({ type: TargetingTypes.EXCLUDE, values: exclude.map(audience => audience.id) });
    }

    return {
        ...campaign,
        campaignTargeting: {
            ...restCampaignTargeting,
            customAudienceTargeting: { collection: customAudiencesCollection, ignoreDefaultExclude: true },
            lookalikeAudienceTargeting: { collection: lookalikeAudiencesCollection },
        },
    };
};

export { transformMyAudienceTargetingFromGW, transformMyAudienceTargetingToGW, transformMyAudienceTargetingFromDB };
