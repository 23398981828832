import { REPORT_TYPE } from 'modules/campaigns/config';
import {
    FormattedMessageWithTooltipHeaderRenderer,
    gridNumberFormatter,
    formattedNumberValueGetter,
} from '../../../components';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import EMPTY_FIELD_VALUE from '../../defaultValues';
import * as FIELDS from '../../fields';

const advertiserDomainReportColumnDefinition = [
    {
        headerName: FIELDS.ADVERTISER_DOMAIN_NAME.label,
        field: FIELDS.ADVERTISER_DOMAIN_NAME.field,
        type: [leftPinnedColumn],
        valueFormatter: ({ value }) => value || EMPTY_FIELD_VALUE,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.VCPM,
        FIELDS.CONVERSIONS_VALUE,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(advertiserDomainReportColumnDefinition, REPORT_TYPE.ADVERTISER_DOMAIN);
