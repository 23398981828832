import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { FormField, InputGroupWithButton, SetupIcon } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useEventValueHandler } from 'hooks';
import { useAppUrl } from 'hooks/useAppUrl';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { withIndication } from '../../../../../errors';
import { CustomDataSection } from '../../creativeCustomData/CustomDataSection';
import { VariationsSetupSection } from '../VariationsCreation/components/VariationsSetupSection';
import AppAdsTooltip from './AppAdsTooltip';
import { isValidAppStoreURL } from './adSetUpSectionValidation';
import { OS_FAMILY_KEYS, useAdSetupUrlInputGroupConfig } from './useAdSetupUrlInputGroupConfig';
import { useOsFamilyCampaign } from './useOsFamilyCampaign';
import styles from './adSetUpSection.module.scss';

const classNameBuilder = classnames.bind(styles);
const InputGroupWithButtonWithIndication = withIndication(InputGroupWithButton);

export const AdSetupSection = () => {
    const osFamilyCampaign = useOsFamilyCampaign();
    const [osFamily = OS_FAMILY_KEYS.ANDROID] = osFamilyCampaign;
    const isAndroidOsFamily = osFamily?.includes(OS_FAMILY_KEYS.ANDROID);

    const { appUrl = '', onChangeAppUrl, indicationData } = useAppUrl(isAndroidOsFamily, 'appUrl');

    const { value: urls } = useFormFieldValue({
        field: 'urls',
    });

    const { isAppInstall: isAppInstallCreativeInEditMode } = useIsAppInstallCreativeInEditMode();
    const onChangeAppUrlHandler = useEventValueHandler(onChangeAppUrl);
    const { inputGroupConfig } = useAdSetupUrlInputGroupConfig({
        osFamily,
        onChangeAppUrlHandler,
        appUrl,
        additionalConfig: {
            isAppInstallCreativeInEditMode,
            msgIdPrefix: 'creative.creator.ad.setup.app.url.dropdown',
            inputClass: styles['input'],
            inputWrapperClass: styles['input-wrapper'],
            disabledDropdown: true,
            placeholderId: 'creative.creator.ad.setup.app.url.placeholder',
            placeholderDefaultMessage: 'App URL',
        },
    });

    useEffect(() => {
        if (!isAppInstallCreativeInEditMode) {
            const landingPageInFirstPlace = urls[0]?.value || '';
            const isValidAppStoreUrl = isValidAppStoreURL(landingPageInFirstPlace, osFamily);
            if (isValidAppStoreUrl) {
                onChangeAppUrlHandler({ target: { value: landingPageInFirstPlace } });
            }
        }
    }, [isAppInstallCreativeInEditMode, urls, osFamily, onChangeAppUrlHandler]);

    return (
        <CommonCollapsibleCard
            id="AD_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SetupIcon />}
                    headerText={<FormattedMessage id="creative.creator.app.setup" defaultMessage="Ad setup" />}
                />
            }
            containerClassName={classNameBuilder('card-container', {
                'edit-app-install-mode': isAppInstallCreativeInEditMode,
            })}
        >
            {!isAppInstallCreativeInEditMode && (
                <>
                    <CustomDataSection />
                    <VariationsSetupSection showDescription />
                </>
            )}
            <FormField
                containerClass={styles['app-url-container']}
                label={<FormattedMessage id="creative.creator.ad.setup.app.url" defaultMessage="App URL" />}
                description={
                    <FormattedMessage
                        id="creative.creator.ad.setup.app.url.description"
                        defaultMessage="Please enter your app store direct URL. From the app store url, your app name, logo and the average rating will be retrieved and displayed as appears in the app (e.g. https://apps.apple.com/AU/app/id1xxxxxxxxx)."
                    />
                }
                helpText={<AppAdsTooltip />}
            />
            <InputGroupWithButtonWithIndication
                config={inputGroupConfig}
                className={styles['input']}
                delimiterClassName={styles['input-delimiter']}
                buttonProps={{
                    className: styles['remove-button'],
                }}
                {...indicationData}
            />
        </CommonCollapsibleCard>
    );
};
