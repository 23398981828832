import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import ImpressionType from './ImpressionType';
import ImpressionTypeFieldTooltip from './ImpressionTypeFieldTooltip';
import styles from '../radioBtnComponents.module.scss';

export const ImpressionTypeField = () => {
    const isImpressionTypeMatch = useConfigMatch({ [FEATURE_FLAGS.IMPRESSION_TYPE_FIELD]: 'VIDEO AND NATIVE' });

    if (!isImpressionTypeMatch) {
        return null;
    }

    return (
        <FormField
            inputId="impression-type"
            label={
                <FormattedMessage id="video.pmpDeal.creator.impressionType.title" defaultMessage="Impression Type" />
            }
            description={
                <FormattedMessage
                    id="video.pmpDeal.creator.impressionType.description"
                    defaultMessage="Distinguish between Video and Native Video protocols."
                />
            }
            helpText={<ImpressionTypeFieldTooltip />}
            containerClass={styles['input']}
        >
            <ImpressionType />
        </FormField>
    );
};

export default ImpressionTypeField;
