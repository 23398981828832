import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { isEmpty } from 'lodash';
import { ErrorOutlinedIcon, Button, CheckCircleOutlinedIcon } from 'tuui';
import { FormField, INPUT_GROUP_TYPES, InputGroupWithButton, InputTypes } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { FormLayout } from 'modules/campaigns/components';
import { withIndication } from 'modules/errors';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useTrackingApi } from 'services/api';
import { emailValidations } from './emailValidations';
import styles from './emailPixelCode.module.scss';

const EmailInput = withIndication(InputGroupWithButton);

const Border = () => <div className={styles['border']} />;

const Indication = ({ isSuccess, isError, error }) => {
    if (isSuccess) {
        return (
            <div className={styles['indication']}>
                <CheckCircleOutlinedIcon className={styles['ok-icon']} />
                <FormattedMessage id="tracking.send.pixel.code.success" defaultMessage="Email successfully sent" />
            </div>
        );
    }
    if (isError) {
        return (
            <div className={styles['indication']}>
                <ErrorOutlinedIcon className={styles['alert-icon']} />
                {error?.status === 400 ? (
                    <FormattedMessage
                        id="tracking.send.pixel.code.api.bad.request"
                        defaultMessage="Email is invalid."
                    />
                ) : (
                    <FormattedMessage
                        id="tracking.send.pixel.code.error"
                        defaultMessage="Something went wrong, please try sending the email again."
                    />
                )}
            </div>
        );
    }
    return <div />;
};

const EmailPixelCode = ({ onClose }) => {
    const [{ accountName }] = useSelectedAccount();

    const { sendPixelCode } = useTrackingApi();

    const { mutate: sendPixelMutation, isSuccess, isError, error } = useMutation(sendPixelCode);

    const {
        value: email,
        onChange: onChangeEmail,
        indicationData,
    } = useFormValidatedValue({
        field: 'email',
        validations: emailValidations,
        throttleValue: true,
        throttleTiming: 1050,
    });

    const { formatMessage } = useIntl();

    const handleOnChangeEmail = useCallback(e => onChangeEmail(e.target.value), [onChangeEmail]);

    const emailInputConfig = useMemo(
        () => [
            {
                type: INPUT_GROUP_TYPES.INPUT,
                dataKey: 'email',
                props: {
                    placeholder: formatMessage({
                        id: 'tracking.send.pixel.code.text.placeholder',
                        defaultMessage: 'example@email.com',
                    }),
                    value: email,
                    type: InputTypes.TEXT,
                    onChange: handleOnChangeEmail,
                    inputClass: styles['input'],
                    inputWrapperClass: styles['input-wrapper'],
                },
            },
        ],
        [email, handleOnChangeEmail, formatMessage]
    );

    return (
        <FormField inputId="email">
            <EmailInput
                config={emailInputConfig}
                blurOnEnter
                className={styles['container']}
                buttonProps={{
                    disabled: indicationData.indicationType === 'error' || isEmpty(email),
                    onClick: () => {
                        sendPixelMutation({
                            accountId: accountName,
                            emails: [email],
                        });
                    },
                    children: <FormattedMessage id="tracking.send.pixel.code.button" defaultMessage="Send" />,
                }}
                {...indicationData}
            />
            <Border />
            <div className={styles['footer']}>
                <Indication isSuccess={isSuccess} isError={isError} error={error} />
                <div className={styles['close-button-wrapper']}>
                    <Button
                        onClick={onClose}
                        size={Button.size.sm}
                        variant={Button.variant.ghost}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="CloseButton"
                        data-automation="FormCloseButton"
                    >
                        <FormattedMessage id="app.actionButtons.CLOSE" />
                    </Button>
                </div>
            </div>
        </FormField>
    );
};

const EmailPixelCodeProvider = withFormDataProvider(EmailPixelCode, {
    defaultValuesConfig: { email: '' },
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { EmailPixelCodeProvider as EmailPixelCode };
