import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const BlockedPublishersTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.duplicateCampaignSettings.siteBlocks.tooltip.main"
            defaultMessage="Duplicate the original campaign’s list of blocked sites."
        />
    </TooltipSection>
);

export default BlockedPublishersTooltip;
