/**
 * Created by oreuveni
 * Date: 2019-04-18
 * Time: 11:12
 */
import React from 'react';
import { ProfileIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { PERMISSIONS_OPERATORS, usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { FORM_MODES } from '../../../../config';
import Profile from '../Profile/Profile';

const ProfileSection = () => {
    const isPermitted = usePermissions(['CAMPAIGN_PROFILE_EDIT', 'CAMPAIGN_ADMIN_EDIT'], PERMISSIONS_OPERATORS.OR);
    const { mode } = useFormDataContext();

    return isPermitted ? (
        <CommonCollapsibleCard
            id="CAMPAIGN_PROFILE"
            header={
                <SectionHeader
                    headerIcon={<ProfileIcon />}
                    headerText={<FormattedMessage id="campaign.editor.profile" defaultMessage="Profile" />}
                />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.profile.description"
                    defaultMessage="Visible to internal users only"
                />
            }
        >
            <Profile shouldShowStatuses={mode === FORM_MODES.EDIT} />
        </CommonCollapsibleCard>
    ) : null;
};

export default ProfileSection;
