import React, { useMemo } from 'react';
import { generatePath } from 'react-router';
import { PlusIcon, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { DropdownMenuLinkOption } from 'components/Dropdown';
import { FORM_MODES } from 'config/formModes';
import {
    BASE_FORM_ROUTE_PATH,
    ENTITY_PARAM_NAME,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_NAME,
    MODULE_PARAM_NAME,
} from 'config/routes';
import { useModuleName } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ENTITY, ENTITY_TO_PATH_MAP } from '../../../routes';
import styles from './dropdownCreatePresetOption.module.scss';

const NOT_PERMITTED_MAX_NUM_PRESETS = 1;
const MAX_NUM_PRESETS = 10;

const generateCreatePresetPath = module =>
    generatePath(BASE_FORM_ROUTE_PATH, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: ENTITY_TO_PATH_MAP[ENTITY.COLUMN_PICKER],
        [MODE_PARAM_NAME]: FORM_MODE_TO_PATH_MAP[FORM_MODES.CREATE],
    });

export const DropdownCreatePresetOption = ({ options }) => {
    const module = useModuleName();
    const isCustomPresetsPermitted = usePermissions('ADS_CONSOLE_CUSTOM_REPORT_PRESETS');
    const numCustomPresets = useMemo(() => options.filter(({ isCustom }) => isCustom).length, [options]);

    const hidden = !isCustomPresetsPermitted && numCustomPresets >= NOT_PERMITTED_MAX_NUM_PRESETS;
    if (hidden) {
        return null;
    }

    const disabled = isCustomPresetsPermitted && numCustomPresets >= MAX_NUM_PRESETS;

    return (
        <div>
            <DropdownMenuLinkOption
                to={generateCreatePresetPath(module)}
                disabled={disabled}
                data-metrics-component="ColumnPicker"
                data-metrics-value="New Preset"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
            >
                <PlusIcon className={styles['icon']} />
                <FormattedMessage
                    id="app.campaigns.reports.columnPicker.create.view"
                    defaultMessage="New Columns Preset"
                />
            </DropdownMenuLinkOption>
            {disabled && (
                <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow>
                    <FormattedMessage
                        id="app.campaigns.reports.columnPicker.create.max.views.tooltip"
                        defaultMessage="You have reached the maximum number of saved presets"
                    />
                </Tooltip>
            )}
        </div>
    );
};
