'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.isnil');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactRadioGroup = require('react-radio-group');

var _Indications = require('../../Indications');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var RadioGroup = function RadioGroup(_ref) {
    var children = _ref.children,
        error = _ref.error,
        disabled = _ref.disabled,
        indicationType = _ref.indicationType,
        rest = _objectWithoutProperties(_ref, ['children', 'error', 'disabled', 'indicationType']);

    return _react2.default.createElement(
        _reactRadioGroup.RadioGroup,
        Object.assign({}, rest, { disabled: disabled }),
        _react2.default.Children.map(children, function (child) {
            if ((0, _lodash2.default)(child)) {
                return null;
            }
            var indicationTypeValue = child.props.indicationType || indicationType;
            var indicationTypeProp = indicationTypeValue ? { indicationType: indicationTypeValue } : {};

            return _react2.default.cloneElement(child, Object.assign({
                error: child.props.error || error,
                disabled: child.props.disabled || disabled
            }, indicationTypeProp));
        })
    );
};

RadioGroup.propTypes = {
    /** Name of the inputs inside this radio group */
    name: _propTypes2.default.string,
    /** Currently selected value */
    selectedValue: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.bool]),
    /** On change action */
    onChange: _propTypes2.default.func,
    /** Radio buttons inside the group */
    children: _propTypes2.default.node.isRequired,
    /** Defines what tag or component is used for rendering the RadioGroup */
    Component: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func, _propTypes2.default.object]),
    /** Error control */
    error: _propTypes2.default.bool,
    /** Disable radio buttons */
    disabled: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES))
};

RadioGroup.defaultProps = {
    onChange: function onChange() {}
};

exports.default = RadioGroup;