import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

export const lookBackWindowValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'tracking.conversion.creator.validations.error.include.people.from.days.empty',
        defaultMessage: 'Enter a specific timeframe',
    },
    {
        validationFn: validationFunctions.isInt,
        messageId: 'tracking.conversion.creator.validations.error.include.people.from.not.int',
        defaultMessage: 'Click Through Conversion Window must be a whole number',
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 1 },
        messageId: 'tracking.conversion.creator.validations.error.include.people.min.days.outside.range',
        defaultMessage: 'Click Through Conversion Window cannot be smaller than {min} days',
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 30 },
        messageId: 'tracking.conversion.creator.validations.error.include.people.max.days.outside.range',
        defaultMessage: 'Click Through Conversion Window cannot be larger than {max}',
    },
];
