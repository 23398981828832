import { useDispatch, useSelector } from 'react-redux';
import { CAMPAIGNS_ROUTE, REPORT_TYPE } from 'modules/campaigns/config';
import { CAMPAIGN_ID, useAddPaymentModal } from 'modules/campaigns/hooks';
import { REPORT_ID } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { CONTENT_METHODS } from 'modules/campaigns/modules/creative-creator/config';
import { shouldCrawlOneByOneUrlsSelector } from 'modules/campaigns/modules/creative-creator/selectors/selectors';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { reduceAndSortObjectKeys } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { useCreativeCreatorSubmitHandlersMap } from './useCreativeCreatorSubmitHandlersMap';

const SUCCESSFUL_PATH = `${CAMPAIGNS_ROUTE}`;

const sendPostSubmitEvent = (contentMethod, { shouldCrawlOneByOneUrls }, data) => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        value: String(shouldCrawlOneByOneUrls),
        columnName: contentMethod,
        component: 'Load image and headline checkbox',
        pageName: getPageName(),
    });

    const { oneByOneData, rssData, creativePreviews } = data;
    let numCreativesCreated = null;
    switch (contentMethod) {
        case CONTENT_METHODS.ONE_BY_ONE:
            const { validatedUrls: oneByOneValidatedUrls } = oneByOneData;
            numCreativesCreated = oneByOneValidatedUrls.length;
            break;
        case CONTENT_METHODS.RSS:
            const { validatedUrls: rssValidatedUrls } = rssData;
            numCreativesCreated = rssValidatedUrls.length;
            break;
        case CONTENT_METHODS.VARIATIONS:
        case CONTENT_METHODS.CAROUSEL:
            const creativePreviewList = reduceAndSortObjectKeys(creativePreviews);
            numCreativesCreated = creativePreviewList.length;
            break;
        default:
    }

    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        value: numCreativesCreated,
        component: contentMethod,
        pageName: getPageName(),
    });
};

export const useCreativeCreatorState = () => {
    const dispatch = useDispatch();
    const { submit, step, setStep, nextStep } = useFormState();
    const {
        formAccount: { accountId },
        data,
    } = useFormDataContext();
    const { openModalAndGetPath: openAddPaymentModalAndGetPath } = useAddPaymentModal();
    const shouldCrawlOneByOneUrls = useSelector(shouldCrawlOneByOneUrlsSelector);
    const handlersMap = useCreativeCreatorSubmitHandlersMap(accountId, data, dispatch);

    const submitHandler = async contentMethod => {
        const saveCreative = handlersMap[contentMethod];
        const getSuccessfulPath = async ({ campaignId }) =>
            (await openAddPaymentModalAndGetPath()) ||
            mergeQueryParamsWithLocation(
                { pathname: SUCCESSFUL_PATH },
                {
                    [CAMPAIGN_ID]: campaignId,
                    [REPORT_ID]: REPORT_TYPE.CREATIVE,
                }
            );

        await submit(() => saveCreative({ shouldCrawlOneByOneUrls, contentMethod }), getSuccessfulPath);
        sendPostSubmitEvent(contentMethod, { shouldCrawlOneByOneUrls }, data);
    };

    return {
        submit: submitHandler,
        step,
        handleSetStep: setStep,
        nextStep,
    };
};
