import { useContext, useMemo } from 'react';
import useAccountConfig from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountConfig';
import useCommonConfig from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useCommonConfig';
import PermissionsContext from 'modules/taboola-common-frontend-modules/authentication/PermissionsContext';
import { COMMON_FLAGS } from '../modules/taboola-common-frontend-modules/./account-configurations/flags/commonFlags';
import { FEATURE_FLAGS } from '../modules/taboola-common-frontend-modules/./account-configurations/flags/featureFlags';
import useShallowObject from './useShallowObject';

const emptyObj = {};

const allFeatureFlags = Object.values(FEATURE_FLAGS);
const allCommonFlags = Object.values(COMMON_FLAGS);

export const useItemsConditionConfigData = (conditionData = emptyObj) => {
    const fullAccountConfig = useAccountConfig(allFeatureFlags);
    const fullCommonConfig = useCommonConfig(allCommonFlags);
    const fullPermissionsMap = useContext(PermissionsContext);

    const conditionDataMemo = useShallowObject(conditionData);
    const conditionDataWithConfig = useMemo(
        () => ({
            ...conditionDataMemo,
            accountConfig: fullAccountConfig,
            commonConfig: fullCommonConfig,
            permissionsMap: fullPermissionsMap,
        }),
        [conditionDataMemo, fullAccountConfig, fullCommonConfig, fullPermissionsMap]
    );

    return conditionDataWithConfig;
};
