import { isEmpty } from 'lodash';
import TargetingTypes from '../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { getAudiencesByTargetingType } from '../../campaigns/services/transformers/audienceTransformerUtils';
import { AUDIENCE_TYPE } from '../config';

export const externalAudiencesTransformerToGW = audience => {
    const { domains = {}, keywords = [], type, ...rest } = audience;

    const collection = [];

    if (type === AUDIENCE_TYPE.SEARCH_KEYWORDS) {
        if (!isEmpty(keywords)) {
            collection.push({ type: TargetingTypes.INCLUDE, values: keywords });
        }
    }

    if (type === AUDIENCE_TYPE.MAIL_DOMAINS) {
        if (domains.include.length) {
            collection.push({ type: TargetingTypes.INCLUDE, values: domains.include || [] });
        }
        if (domains.exclude.length) {
            collection.push({ type: TargetingTypes.EXCLUDE, values: domains.exclude || [] });
        }
    }

    return {
        ...rest,
        ...(collection && collection.length > 0 ? { targetingValues: { collection } } : {}),
        segmentType: type,
        isArchived: rest.status === 'ARCHIVED',
    };
};

export const externalAudiencesTransformerFromGW = audience => {
    const { targetingValues = {}, segmentType } = audience;

    const includeSegments = getAudiencesByTargetingType(targetingValues?.collection, TargetingTypes.INCLUDE);
    const excludeSegments = getAudiencesByTargetingType(targetingValues?.collection, TargetingTypes.EXCLUDE);

    let targetingObj = {};
    if (segmentType === AUDIENCE_TYPE.SEARCH_KEYWORDS) {
        targetingObj = { keywords: includeSegments };
    }

    if (segmentType === AUDIENCE_TYPE.MAIL_DOMAINS) {
        targetingObj = {
            domains: {
                include: includeSegments,
                exclude: excludeSegments,
            },
        };
    }

    return {
        ...audience,
        ...targetingObj,
        type: segmentType,
    };
};
