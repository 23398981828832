import { useMemo, useContext } from 'react';
import { isEmpty } from 'lodash';
import PermissionsContext from '../PermissionsContext';
import parseRecursivePermissions from '../services/parseRecursivePermissions';

/*
usePermissions:

Parameters:
    permissions
        3 possible values

        String:
            usePermissions('X')

            check if X is in the set of all the user's permissions

        Array of Strings:
            usePermissions(['X', 'Y', 'Z'], PERMISSIONS_OPERATORS.OR)

            check if (X || Y || Z) is in the set of all the user's permissions

        Nested Permissions Object (no operator param required):
            usePermissions({
                permissions: ['X', 'Y', 'Z'],
                operator: PERMISSIONS_OPERATORS.OR
            })

            check if (X || Y || Z) is in the set of all the user's permissions

            usePermissions({
                permissions: ['X', {
                    permissions: ['Y', 'Z'],
                    operator: PERMISSIONS_OPERATORS.OR
                },
                operator: PERMISSIONS_OPERATORS.AND
            })

            check if (X && (Y || Z)) is satisfied by the set of all the user's permissions

    operator
        one of PERMISSIONS_OPERATORS (only required if permissions is an Array)


Returns:
    true if the conditions defined by the permissions and operator param are met by the user's permissions
*/

export const usePermissions = (permissions = [], operator) => {
    const allUserPermissions = useContext(PermissionsContext);

    let formattedPermissions = permissions;

    if (typeof permissions === 'string') {
        formattedPermissions = {
            permissions: [permissions],
            operator,
        };
    } else if (Array.isArray(permissions)) {
        formattedPermissions = {
            permissions,
            operator,
        };
    }

    const isPermitted = useMemo(
        () =>
            isEmpty(allUserPermissions) ? false : parseRecursivePermissions(formattedPermissions, allUserPermissions),
        [formattedPermissions, allUserPermissions]
    );

    return isPermitted;
};

export default usePermissions;
