import React from 'react';
import { FilterAltOutlinedIcon } from 'tuui';
import { FormField, Indication } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FORM_MODES } from 'modules/campaigns/config';
import { useAccountFunnels } from 'modules/campaigns/modules/common-campaign-form/components/Funnel/hooks/useAccountFunnels';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FunnelConversions } from '../FunnelConversions/FunnelConversions';
import { FunnelNameField } from './FunnelNameField';
import styles from './funnelSetup.module.scss';

export const FunnelSetup = () => {
    const isDefaultFunnelEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_DEFAULT_FUNNEL_ENABLED]: 'true',
    });
    const { mode } = useFormDataContext();
    const { data: accountFunnels } = useAccountFunnels();
    return (
        <CommonCollapsibleCard
            id="FUNNEL_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="tracking.unip.funnel.setup.step.title" defaultMessage="Funnel Setup" />
                    }
                    headerIcon={<FilterAltOutlinedIcon />}
                />
            }
        >
            <div className={styles['col-list']}>
                <FormField
                    inputId="funnel-name-input"
                    description={
                        <FormattedMessage
                            id="tracking.unip.funnel.setup.name.description"
                            defaultMessage="Creating a campaign funnel enables SmartBid to better optimize towards your primary, most valuable conversion. This is especially helpful when data for the primary conversion is low.{newline}{newline}
                            A funnel is composed of a sequence of events that lead to the primary conversion, such as a Purchase."
                        />
                    }
                    descriptionClass={styles['description']}
                >
                    <FormField
                        description={
                            <FormattedMessage
                                id="tracking.unip.funnel.setup.name.description.indication"
                                defaultMessage="<i>* For internal data collection and model training purposes at this stage.</i>"
                            />
                        }
                    />

                    {mode === FORM_MODES.CREATE && <FunnelNameField />}
                    <FunnelConversions />
                    {isDefaultFunnelEnabled && !accountFunnels.length && (
                        <Indication
                            type="info"
                            message={
                                <FormField
                                    description={
                                        <FormattedMessage
                                            id="tracking.unip.funnel.setup.create.default.funnel.indication"
                                            defaultMessage="<i>This funnel will be set as your account default for all existing and new campaigns. You can easily select a different funnel on campaign creation/edit.</i>"
                                        />
                                    }
                                    descriptionClass={styles['description']}
                                />
                            }
                        />
                    )}
                </FormField>
            </div>
        </CommonCollapsibleCard>
    );
};
