import React from 'react';
import classnames from 'classnames/bind';
import { StatusIndicator } from 'tuui';
import { Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import { getStatusIndicatorType } from 'modules/campaigns/config';
import { getConversionRulesStatusType } from 'modules/campaigns/config/conversionRulesConsts';
import { ConversionItemTooltip } from 'modules/campaigns/modules/common-campaign-form/components/ConversionOptimization/ConversionItemTooltip/ConversionItemTooltip';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from '../CampaignConversionRules/CampaignConversionRules.module.scss';

const classNameBuilder = classnames.bind(styles);
const NO_CONVERSIONS = '-';

export const CampaignRuleItemRender = ({ data, selectProps = {} }) => {
    const { displayName, avgCvr, status, numDays, conversions } = data;
    const { inputValue } = selectProps;
    const { formatMessage } = useIntl();
    const shortAvgCvr = avgCvr?.toFixed(2);
    const formattedAvgCvr = formatMessage(
        {
            id: 'conversion.goal.avg.cvr',
            defaultMessage: `Avg CVR: ${shortAvgCvr}%`,
        },
        { shortAvgCvr }
    );
    const statusType = getConversionRulesStatusType(status);

    const statusIndicatorType = getStatusIndicatorType(statusType);
    const conversionsToDisplay = conversions ?? NO_CONVERSIONS;

    return (
        <div className={styles['container']} role="listitem">
            <ConversionItemTooltip
                status={status}
                avgCvr={shortAvgCvr}
                conversions={conversionsToDisplay}
                numDays={numDays}
            />
            {statusIndicatorType && (
                <div className={styles['indicator']}>
                    <StatusIndicator type={statusIndicatorType} />
                </div>
            )}
            <div className={styles['name']}>
                <Dotdotdot lines={2}>
                    <Highlighter
                        text={`${displayName}`}
                        highlightedText={inputValue}
                        className={styles['highlighter']}
                    />
                </Dotdotdot>
            </div>
            <div className={classNameBuilder('avg-cvr')}>{formattedAvgCvr}</div>
        </div>
    );
};
