import React from 'react';

const OneByOneSVGIcon = props => (
    <svg width="81" height="31" viewBox="0 0 81 31" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 7H2a1 1 0 00-1 1v15a1 1 0 001 1h16a1 1 0 001-1V8a1 1 0 00-1-1zM2 6a2 2 0 00-2 2v15a2 2 0 002 2h16a2 2 0 002-2V8a2 2 0 00-2-2H2z"
            fill="#667686"
        />
        <path d="M2 9a1 1 0 011-1h14a1 1 0 011 1v9a1 1 0 01-1 1H3a1 1 0 01-1-1V9z" fill="#EEF0F2" />
        <path
            d="M10.06 19H3.143A1.133 1.133 0 012 17.876v-.662L3.779 15.5a1.915 1.915 0 012.648 0L10.06 19zM18 16.454v1.422c0 .62-.512 1.124-1.143 1.124H11.18l-3.29-3.202 4.088-3.98c.386-.46 1.018-.46 1.486 0L18 16.454zm-9.942-5.082c0 .799-.666 1.447-1.487 1.447-.82 0-1.486-.648-1.486-1.447 0-.8.665-1.447 1.486-1.447s1.487.648 1.487 1.447zM2 21h12v1H2v-1z"
            fill="#B8C1CA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79 7H63a1 1 0 00-1 1v15a1 1 0 001 1h16a1 1 0 001-1V8a1 1 0 00-1-1zM63 6a2 2 0 00-2 2v15a2 2 0 002 2h16a2 2 0 002-2V8a2 2 0 00-2-2H63z"
            fill="#667686"
        />
        <path d="M63 9a1 1 0 011-1h14a1 1 0 011 1v9a1 1 0 01-1 1H64a1 1 0 01-1-1V9z" fill="#EEF0F2" />
        <path
            d="M71.06 19h-6.917A1.134 1.134 0 0163 17.876v-.662l1.779-1.714a1.915 1.915 0 012.648 0L71.06 19zM79 16.454v1.422c0 .62-.512 1.124-1.143 1.124H72.18l-3.29-3.202 4.088-3.98c.386-.46 1.018-.46 1.487 0L79 16.454zm-9.942-5.082c0 .799-.666 1.447-1.487 1.447-.82 0-1.486-.648-1.486-1.447 0-.8.665-1.447 1.486-1.447.822 0 1.487.648 1.487 1.447zM63 21h12v1H63v-1z"
            fill="#B8C1CA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55 1H26a1 1 0 00-1 1v27a1 1 0 001 1h29a1 1 0 001-1V2a1 1 0 00-1-1zM26 0a2 2 0 00-2 2v27a2 2 0 002 2h29a2 2 0 002-2V2a2 2 0 00-2-2H26z"
            fill="#667686"
        />
        <path d="M26 4.053a2 2 0 012-2h25a2 2 0 012 2v15a2 2 0 01-2 2H28a2 2 0 01-2-2v-15z" fill="#EEF0F2" />
        <path
            d="M40.61 21H28.07c-1.143 0-2.07-.942-2.07-2.105v-1.241l3.224-3.21a3.39 3.39 0 014.8 0L40.608 21zM55 16.23v2.665C55 20.058 54.073 21 52.929 21h-10.29l-5.963-5.999 7.41-7.454c.7-.862 1.845-.862 2.693 0L55 16.23zM36.98 6.71a2.703 2.703 0 01-2.694 2.711 2.703 2.703 0 01-2.695-2.71A2.703 2.703 0 0134.286 4a2.703 2.703 0 012.694 2.71zM27 24h20v1H27zm0 3h12v1H27z"
            fill="#B8C1CA"
        />
    </svg>
);

export default OneByOneSVGIcon;
