import { noop } from 'lodash';
import { FileTypes } from 'taboola-ultimate-ui';
import { aiGenerationApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { modifyExternalUrl } from '../../../services/utils';
import THUMBNAIL_SOURCE_TYPES from '../../creative-creator/config/thumbnailSourceTypes';

const fetchAIGeneratedImages = async ({
    accountId,
    campaignId,
    textPrompt,
    promptHelperParams,
    setStatus,
    onFailure = noop,
}) => {
    setStatus(COMPONENT_STATUS.LOADING);

    if (textPrompt == null || textPrompt === '') {
        setStatus(COMPONENT_STATUS.EMPTY);
        return { results: [] };
    }

    try {
        const { results: response } = await aiGenerationApi.getAIGeneratedImagesFromPrompt({
            accountId,
            campaignId,
            textPrompt,
            promptHelperParams,
        });

        const results = response.map(item => ({
            url: item.url,
            id: item.id,
            cdnUrl: modifyExternalUrl(item.url),
            thumbnailUrl: modifyExternalUrl(item.url),
            fileType: FileTypes.IMAGE,
            source: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION,
        }));

        setStatus(COMPONENT_STATUS.ACTIVE);

        return { results };
    } catch (error) {
        setStatus(COMPONENT_STATUS.ERROR);
        onFailure(error);
        return { error, results: [] };
    }
};

export default fetchAIGeneratedImages;
