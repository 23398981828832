import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const CpmVcpmTooltip = () => (
    <>
        <TooltipSection>
            <strong>
                <FormattedMessage
                    id="campaign.editor.cpm.tooltip"
                    defaultMesage="CPM - Available (Cost Per One Thousand Impressions)"
                />
            </strong>
            <FormattedMessage
                id="campaign.editor.cpm.tooltip.details"
                defaultMesage="The amount you pay per one thousand available impressions of your campaign items. Example: If your CPM is $0.25, it means you have paid $0.25 for every 1,000 available impressions of your campaign ads."
            />
        </TooltipSection>
        <TooltipSection>
            <strong>
                <FormattedMessage
                    id="campaign.editor.vcpm.tooltip"
                    defaultMesage="CPM (Cost Per One Thousand Impressions)"
                />
            </strong>
            <FormattedMessage
                id="campaign.editor.vcpm.tooltip.details"
                defaultMesage="The amount you pay per one thousand impressions of your campaign items. For example, if your Viewable CPM is $0.25, it means you have paid $0.25 for every 1,000 impressions of your campaign ads."
            />
        </TooltipSection>
    </>
);

export default CpmVcpmTooltip;
