import { LaunchOutlinedIcon } from 'tuui';
import { CommonCollapsibleCard, ExternalLink } from 'components';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CopyAccountId } from '../CopyAccountId/CopyAccountId';
import styles from './trackingShopifySetup.module.scss';

export const TaboolaPixelAppButton = () => (
    <ExternalLink href="https://apps.shopify.com/taboola-ads-pixel" className={styles['taboola-pixel-app-link']}>
        <FormattedMessage
            id="tracking.setup.shopify.set.up.install.taboola.pixel.app"
            defaultMessage="<b>Taboola Pixel app</b>"
        />
        <LaunchOutlinedIcon style={{ fontSize: '14' }} className={styles['linkIcon']} />
    </ExternalLink>
);

export const TrackingShopifySetup = () => {
    return (
        <CommonCollapsibleCard
            id="Shopify_Integration"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="tracking.setup.shopify.set.up"
                            defaultMessage="Set Up Taboola Pixel On Shopify"
                        />
                    }
                />
            }
        >
            <ol className={styles['ol-style']}>
                <li>
                    <CopyAccountId />
                </li>
                <li>
                    <FormattedMessage
                        id="tracking.setup.shopify.set.up.second.step"
                        defaultMessage="Install the {linkComp} from the Shopify marketplace, and follow instructions"
                        values={{ linkComp: <TaboolaPixelAppButton /> }}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="tracking.setup.shopify.set.up.third.step"
                        defaultMessage="Click continue after Your Taboola Pixel Is Ready!"
                    />
                </li>
            </ol>
        </CommonCollapsibleCard>
    );
};
