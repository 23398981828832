"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgBrand(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M18.0027 10.5665H10C7.79086 10.5665 6 12.3574 6 14.5665C6 16.7757 7.79086 18.5665 10 18.5665H12.0027V23.0573C12.0027 24.438 13.122 25.5573 14.5027 25.5573C15.8834 25.5573 17.0027 24.438 17.0027 23.0573V18.5665H18.0274L24.6516 22.0506C24.7638 22.0927 24.8828 22.1143 25.0027 22.1143C25.555 22.1143 26.0027 21.6666 26.0027 21.1143V8.00027C26.0027 7.88036 25.9811 7.76142 25.939 7.64915C25.7451 7.13203 25.1687 6.87002 24.6516 7.06394L18.0027 10.5573V10.5665ZM19.0027 11.2503L25.0027 8.00027V21.1143L19 17.9797L19.0027 11.2503ZM18 11.5665L18.0029 11.5576L19.0027 11.2503L18 11.5665ZM13 17.5665H10C8.34315 17.5665 7 16.2234 7 14.5665C7 12.9097 8.34315 11.5665 10 11.5665H18V17.5665H16.0027V23.0573C16.0027 23.8857 15.3311 24.5573 14.5027 24.5573C13.6743 24.5573 13.0027 23.8857 13.0027 23.0573V18.5573H16V17.5573H13V17.5665ZM13 17.5665V18.5573H13.0027V17.5665H13Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgBrand;