import React from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { isNetworkAccountType } from '../../../../../account-management';
import { FORM_MODES } from '../../../../config';
import { AudienceTargetingSectionCampaignEditor } from '../../../common-campaign-form/components/AudienceTargetingSection/AudienceTargetingSectionCampaignEditor';
import { BudgetSection } from '../BudgetSection/BudgetSection';
import DuplicateVideoCampaignSettingsSection from '../DuplicateVideoCampaignSettingSection/DuplicateVideoCampaignSettingsSection';
import { InventorySection } from '../InventorySection/InventorySection';
import { ScheduleSection } from '../ScheduleSection/ScheduleSection';
import { SetupSection } from '../SetupSection/SetupSection';
import { TargetingSection } from '../TargetingSection/TargetingSection';

export const VideoCampaignEditorContent = () => {
    const { mode, formAccount } = useFormDataContext();

    return (
        <>
            {mode === FORM_MODES.DUPLICATE && <DuplicateVideoCampaignSettingsSection />}
            {isNetworkAccountType(formAccount.type) && <SetupSection isAccountSelectionEnabled />}
            <ScheduleSection />
            <TargetingSection />
            <AudienceTargetingSectionCampaignEditor />
            <InventorySection account={formAccount} />
            <BudgetSection account={formAccount} />
        </>
    );
};

export default VideoCampaignEditorContent;
