import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { validateBudgetForTargetCpa } from 'modules/campaigns/config';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

export const targetCpaInputValidations = [
    {
        validationFn: (value, options, { maxTargetCpa, shouldDisplayWarnings }) =>
            !maxTargetCpa || shouldDisplayWarnings || value <= maxTargetCpa,
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: (value, options, { minTargetCpa, shouldDisplayWarnings }) =>
            !minTargetCpa || shouldDisplayWarnings || value >= minTargetCpa,
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: (value, options, { maxTargetCpa, shouldDisplayWarnings }) =>
            !maxTargetCpa || !shouldDisplayWarnings || value <= maxTargetCpa,
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, options, { minTargetCpa, shouldDisplayWarnings }) =>
            !minTargetCpa || !shouldDisplayWarnings || value >= minTargetCpa,
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, options, { bidStrategy }) =>
            bidStrategy !== BID_STRATEGIES.TARGET_CPA || validationFunctions.isFloat(value, options),
        options: { min: 0.0001 },
        messageId: 'validations.error.campaign.target.cpa.minValue',
        defaultMessage: 'Target CPA must be a positive number',
    },
    {
        validationFn: (value, options, { recommendedTargetCpa }) =>
            !recommendedTargetCpa || parseFloat(value) >= recommendedTargetCpa,
        messageId: 'validations.error.campaign.target.cpa.belowRecommendedTargetCpa',
        defaultMessage: 'In order to optimize this campaign more efficiently, we recommend adjusting the target',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (
            value,
            options,
            { ignoreSignificantChangeWarning, initialValue, tcpaSignificantChangePercent, formMode }
        ) =>
            ignoreSignificantChangeWarning ||
            !initialValue ||
            formMode === FORM_MODES.DUPLICATE ||
            Math.abs(value - initialValue) / initialValue < tcpaSignificantChangePercent,
        messageId: 'validations.error.campaign.target.cpa.significantChange',
        defaultMessage: 'Significant change',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, options, { minConversionsForTargetCpa, bidStrategy, budget }) =>
            validateBudgetForTargetCpa(budget, value, bidStrategy, minConversionsForTargetCpa),
        messageId: 'validations.error.campaign.budget.minimumForTargetCpa',
        defaultMessage:
            'Based on the daily budget and CPA goal, it seems like conversion volume is going to be too low to meet the minimum required for TCPA to work properly, please increase budget',
        severity: INDICATION_TYPES.WARNING,
    },
];
