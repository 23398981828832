import React, { Fragment } from 'react';
import { ExternalLink } from 'components';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const ContentCtaTooltip = () => (
    <Fragment>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.cta.help.text"
                defaultMessage="Adding a Call To Action to the ad encourages users to take action while seeing your ad. Make sure to select the CTA that is the best fit with your marketing objective and campaign goal."
            />
        </TooltipSection>
        <FormattedMessage
            id="creative.creator.content.cta.help.linkIntro"
            defaultMessage="Visit the {link} for additional information and best practices."
            values={{
                link: (
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/360056459894-CTA-Button">
                        <FormattedMessage id="creative.creator.content.cta.help.link" defaultMessage="Help Center" />
                    </ExternalLink>
                ),
            }}
        />
    </Fragment>
);

export default ContentCtaTooltip;
