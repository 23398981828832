import React from 'react';
import { isNil, get, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { ContentIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { creativeTitleBulkEditValidations } from 'modules/campaigns/config';
import ExternalLinkWithIcon from 'modules/campaigns/modules/creative-editor/components/ExternalLinkWithIcon/ExternalLinkWithIcon';
import {
    ContentDescriptionField,
    ContentTitleField,
    CreativeURL,
} from 'modules/campaigns/modules/creative-editor/components/fields/index';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { FIELDS } from '../../utils';
import BulkEditCTADropdown from '../BulkEditCTADropdown/BulkEditCTADropdown';

const contentSectionTitle = <FormattedMessage id="creative.editor.content" defaultMessage="Content" />;

const titleLabel = <FormattedMessage id="creative.editor.bulk.content.title.label" defaultMessage="Edit Title" />;
const urlLabel = <FormattedMessage id="creative.editor.bulk.content.url.label" defaultMessage="Edit URL" />;
const descriptionLabel = (
    <FormattedMessage
        id="creative.editor.bulk.content.description.label"
        defaultMessage="Edit Description (Optional)"
    />
);

export const ContentSection = ({
    selectedRowsValues,
    selectedFieldsMatrix,
    setSelectedFieldsMatrix: setSelectedFieldsMatrixProp,
}) => {
    const {
        title: isTitleSelected,
        url: isUrlSelected,
        description: isDescriptionSelected,
        cta: isCtaSelected,
    } = selectedFieldsMatrix;

    const generateSetSelectedFieldsMatrix = key => newKeyValue =>
        setSelectedFieldsMatrixProp(prevState => ({
            ...prevState,
            [key]: isNil(newKeyValue) ? !prevState[key] : newKeyValue,
        }));

    const isDescriptionEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_DESCRIPTION_ENABLED]: 'true' });
    const isCTAEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_FORM_CTA_ENABLED]: 'true',
        [FEATURE_FLAGS.BACKSTAGE_ADVERTISER_ENABLE_CTA]: 'true',
    });

    const { value: sharedDescription } = useFormFieldValue({ field: 'description' });

    const isDescriptionPermitted = usePermissions('CAMPAIGN_ITEM_DESCRIPTION') && isDescriptionEnabled;
    const isCTAEditPermitted = usePermissions('CREATIVE_CTA_EDIT') && isCTAEnabled;
    const { formatMessage } = useIntl();
    const mixedValuesPlaceholder =
        uniq(get(selectedRowsValues, 'urls', [])).length > 1
            ? formatMessage({
                  id: 'creative.editor.bulk.content.placeholder',
                  defaultMessage: 'Mixed Values',
              })
            : null;

    return (
        <CommonCollapsibleCard
            id="CREATIVE_CONTENT"
            header={<SectionHeader headerIcon={<ContentIcon />} headerText={contentSectionTitle} />}
        >
            <ContentTitleField
                checkboxLabel={titleLabel}
                selected={isTitleSelected}
                onSelect={generateSetSelectedFieldsMatrix(FIELDS.TITLE)}
                disabledPlaceholderId="creative.editor.bulk.content.placeholder"
                onClick={() => setSelectedFieldsMatrixProp(prevState => ({ ...prevState, [FIELDS.TITLE]: true }))}
                autoFocus={isTitleSelected}
                validationDependencies={{ selectedRowsValues: selectedRowsValues.titles, isTitleSelected }}
                validations={creativeTitleBulkEditValidations}
            />
            <CreativeURL
                checkboxLabel={urlLabel}
                renderLabel={url => <ExternalLinkWithIcon url={url} />}
                selected={isUrlSelected}
                onSelect={generateSetSelectedFieldsMatrix(FIELDS.URL)}
                placeholder={mixedValuesPlaceholder}
                onClick={() => setSelectedFieldsMatrixProp(prevState => ({ ...prevState, [FIELDS.URL]: true }))}
                autoFocus={isUrlSelected}
                validationDependencies={{ selectedRowsValues: selectedRowsValues.urls, isUrlSelected }}
            />
            {isDescriptionPermitted ? (
                <ContentDescriptionField
                    checkboxLabel={descriptionLabel}
                    selected={isDescriptionSelected}
                    onSelect={generateSetSelectedFieldsMatrix(FIELDS.DESCRIPTION)}
                    disabledPlaceholderId={
                        sharedDescription === ''
                            ? 'creative.editor.content.description.placeholder'
                            : 'creative.editor.bulk.content.placeholder'
                    }
                    onClick={() =>
                        setSelectedFieldsMatrixProp(prevState => ({ ...prevState, [FIELDS.DESCRIPTION]: true }))
                    }
                    autoFocus={isDescriptionSelected}
                    validationDependencies={{
                        selectedRowsValues: selectedRowsValues.descriptions,
                        isDescriptionSelected,
                    }}
                />
            ) : null}
            {isCTAEditPermitted ? (
                <BulkEditCTADropdown
                    isSelected={isCtaSelected}
                    setIsSelected={generateSetSelectedFieldsMatrix(FIELDS.CTA)}
                />
            ) : null}
        </CommonCollapsibleCard>
    );
};

ContentSection.protoTypes = {
    selectedFieldsMatrix: PropTypes.object,
    setSelectedFieldsMatrix: PropTypes.func,
};
