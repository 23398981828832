import ACCOUNT_TYPE from '../../../../../../account-management/accountType';
import { siteResourceFieldKeyName } from '../const';

export const isValidItem = ({ item, allowedSites, readonly }) =>
    allowedSites[item[siteResourceFieldKeyName]] ||
    item?.isTrusted ||
    item?.type === ACCOUNT_TYPE.NETWORK ||
    item?.loading ||
    readonly ||
    false;
