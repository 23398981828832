import React from 'react';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { FormLayout, useVerticalScrollDirection } from '../../../campaigns/components';
import BodyLoading from '../../../campaigns/modules/common-campaign-form/components/BodyLoading/BodyLoading';
import { useFormDataContext, withFormDataProvider } from '../../../taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { usePaymentMethodEditorState } from '../../hooks/usePaymentMethodEditorState';
import { PaymentMethodAddressSection } from '../PaymentMethodAddressSection/PaymentMethodAddressSection';
import { PaymentMethodCreditCardSection } from '../PaymentMethodCreditCardSection/PaymentMethodCreditCardSection';
import { PaymentMethodEditorHeader } from '../PaymentMethodEditorHeader/PaymentMethodEditorHeader';
import styles from './paymentMethodEditor.module.scss';

const PaymentMethodEditor = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = usePaymentMethodEditorState();
    const { scrollElementRef: scrollRef, down: collapsedHeader } = useVerticalScrollDirection();

    return (
        <FormInitBase loadingOverlayProps={{ loadingOverlayText: 'paymentMethod.please-wait' }}>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<PaymentMethodEditorHeader fetchStatus={fetchStatus} collapsed={collapsedHeader} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['editor']}
                bodyContainerClassName={styles['editor-body']}
                footerContainerClassName={styles['editor-footer']}
                data-automation="PaymentMethodEditor"
                bodyRef={scrollRef}
            >
                {fetchStatus !== COMPONENT_STATUS.ACTIVE ? (
                    <BodyLoading />
                ) : (
                    <>
                        <PaymentMethodCreditCardSection />
                        <PaymentMethodAddressSection />
                    </>
                )}
            </FormLayout>
        </FormInitBase>
    );
};

const PaymentMethodEditorWithFormDataProvider = withFormDataProvider(PaymentMethodEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { PaymentMethodEditorWithFormDataProvider as PaymentMethodEditor };
