import { navigate } from 'actions';
import { FORM_MODES } from 'modules/campaigns/config/index';
import { generateCreativeEditorPath } from 'modules/campaigns/services/utils';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { cleanCreativesEditingResources } from '../../creative-editor/flows';
import { INITIAL_CREATIVE_TYPE } from '../../creative-editor/hooks/useInitialCreativeType';

const baseEditCreative = (accountId, campaignId, creativeId, history, creativeType, dimension) => dispatch => {
    const editCreativePath = generateCreativeEditorPath({
        accountId,
        campaignId,
        creativeId,
        mode: FORM_MODES.EDIT,
        dimension,
    });

    const pathWithCreativeType = `${editCreativePath}${mergeQueryParams('', {
        [INITIAL_CREATIVE_TYPE]: creativeType,
    })}`;

    dispatch(cleanCreativesEditingResources());
    dispatch(navigate(history, pathWithCreativeType));
};

export default baseEditCreative;
