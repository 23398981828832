/**
 * Created by oreuveni
 * Date: 2019-04-18
 * Time: 14:56
 */
const CONTENT_SAFTIES = {
    PREMIUM: 'PREMIUM',
    SAFE_POSITIVE: 'SAFE_POSITIVE',
    SAFE_SENSITIVE: 'SAFE_SENSITIVE',
    LOW_BROW: 'LOW_BROW',
    RACY: 'RACY',
};

export default CONTENT_SAFTIES;
