import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import createCSVValueGetter from './createCSVValueGetter';

const regionValueFormatter = ({
    rowData: { countryCode, regionCode, regionName, countryName },
    formatOptions: { msgIdPrefix },
    i18n,
}) => {
    const countryMsgId = `${msgIdPrefix}.${countryCode}`;
    const country = i18n.formatMessage({ id: countryMsgId, defaultMessage: countryName || EMPTY_FIELD_VALUE });
    const region = i18n.formatMessage({
        id: `${countryMsgId}.region.code.${regionCode}`,
        defaultMessage: regionName || EMPTY_FIELD_VALUE,
    });

    return `${region} (${country})`;
};

const regionValueGetter = createCSVValueGetter(regionValueFormatter);

export { regionValueFormatter, regionValueGetter };
