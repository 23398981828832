import { cloneDeep } from 'lodash';
import { createSelector } from 'reselect';
import { createDeepEqualSelector } from 'selectors/baseSelectors';
import { selectedAccountTypeSelector } from 'selectors/selectedAccount';
import { getGraphOpenState } from '../modules/campaigns-reports/services/graphStateService';
import { campaignsStateSelector, reportDataMapSelector, selectedReportSelector } from './baseSelectors';

const baseReportDataMapper = ({ baseReportData, reportDataMap = {}, selectedRows, selectedAccountType }) =>
    baseReportData.map(reportItem => {
        const id = reportItem.id || reportItem.campaignsGroupId;
        const dataObject = reportDataMap[id] || {};
        // we need to use cloneDeep to avoid direct deep setting data in store while inline editing of the grid
        const unlinkedResult = cloneDeep({
            ...reportItem,
            ...dataObject,
            isSelected: Boolean(selectedRows[id]),
            accountType: selectedAccountType,
        });
        return unlinkedResult;
    });

export const reportGraphStateSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.reportGraphState
);

export const graphConfigSelector = createSelector(campaignsStateSelector, campaignsState => campaignsState.graphConfig);

export const baseReportDataSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.reportData
);

export const reportDataRawSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.reportDataRaw
);

export const reportDataStatusSelector = createSelector(
    campaignsStateSelector,
    ({ reportDataStatus }) => reportDataStatus
);

export const graphDataStatusSelector = createSelector(campaignsStateSelector, ({ graphDataStatus }) => graphDataStatus);

const reportMetadataSelectorInner = createSelector(campaignsStateSelector, ({ reportMetadata }) => reportMetadata);

export const reportMetadataSelector = createSelector(reportMetadataSelectorInner, reportMetadata => reportMetadata);

const graphMetadataSelectorInner = createSelector(campaignsStateSelector, ({ graphMetadata }) => graphMetadata);

export const graphMetadataSelector = createDeepEqualSelector(
    graphMetadataSelectorInner,
    graphMetadata => graphMetadata
);

export const columnDefsStateSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.columnDefs
);

const emptyMetrics = [];
export const graphMetricsSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.graphMetrics || emptyMetrics
);

const emptyGraphData = [];
export const graphDataSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.graphData || emptyGraphData
);

export const selectedRowsSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.selectedRows
);

export const reportDataSelector = createSelector(
    selectedAccountTypeSelector,
    baseReportDataSelector,
    selectedReportSelector,
    reportDataMapSelector,
    selectedRowsSelector,
    (selectedAccountType, baseReportData, selectedReport, reportDataMap, selectedRows) => {
        if (!reportDataMap) {
            return baseReportDataMapper({ baseReportData, selectedRows, selectedAccountType });
        }
        return baseReportDataMapper({ baseReportData, reportDataMap, selectedRows, selectedAccountType });
    }
);

export const reportRssMetadataSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.reportRssMetadata
);

export const isGraphOpenSelector = createSelector(
    reportGraphStateSelector,
    selectedReportSelector,
    (reportGraphState, selectedReport) => getGraphOpenState(reportGraphState, selectedReport)
);
