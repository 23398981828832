import React from 'react';
import PropTypes from 'prop-types';
import { ImageIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { CreativeEditorField } from 'modules/campaigns/modules/creative-editor/components/fields/index';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { FIELDS } from '../../utils';

const mediaSectionTitle = <FormattedMessage id="creative.editor.media" defaultMessage="Media" />;

function onEditMediaCheckboxSelect(setSelectedFieldsMatrix) {
    return () =>
        setSelectedFieldsMatrix(prevState => ({
            ...prevState,
            [FIELDS.THUMBNAIL_URL]: !prevState[FIELDS.THUMBNAIL_URL],
            [FIELDS.VIDEO_URL]: !prevState[FIELDS.VIDEO_URL],
        }));
}

export const MediaSection = ({ selectedFieldsMatrix, setSelectedFieldsMatrix, ...rest }) => {
    const { thumbnailUrl: isThumbnailUrlSelected, videoUrl: isVideoUrlSelected } = selectedFieldsMatrix;
    const isVideoOrThumbnailSelected = isVideoUrlSelected || isThumbnailUrlSelected;

    return (
        <CommonCollapsibleCard
            id="CREATIVE_MEDIA"
            header={<SectionHeader headerIcon={<ImageIcon />} headerText={mediaSectionTitle} />}
        >
            <CreativeEditorField
                selected={isVideoOrThumbnailSelected}
                disabled={!isVideoOrThumbnailSelected}
                onSelect={onEditMediaCheckboxSelect(setSelectedFieldsMatrix)}
                isFocalPointEditorEnabled={false}
                checkboxLabel={<FormattedMessage id="creative.editor.bulk.media.label" />}
                {...rest}
            />
        </CommonCollapsibleCard>
    );
};

MediaSection.protoTypes = {
    selectedFieldsMatrix: PropTypes.object,
    setSelectedFieldsMatrix: PropTypes.func,
};
