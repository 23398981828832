'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _agGridReact = require('ag-grid-react');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var DataGridBase = function DataGridBase(_ref) {
    var gridHeight = _ref.gridHeight,
        gridWidth = _ref.gridWidth,
        className = _ref.className,
        gridRef = _ref.gridRef,
        dataTestId = _ref.dataTestId,
        rest = _objectWithoutProperties(_ref, ['gridHeight', 'gridWidth', 'className', 'gridRef', 'dataTestId']);

    var sizeStyle = (0, _react.useMemo)(function () {
        return { height: gridHeight, width: gridWidth };
    }, [gridHeight, gridWidth]);

    return _react2.default.createElement(
        'div',
        { style: sizeStyle, className: className, ref: gridRef, 'data-testid': dataTestId },
        _react2.default.createElement(_agGridReact.AgGridReact, rest)
    );
};

DataGridBase.propTypes = {
    /** The height of the grid */
    gridHeight: _propTypes2.default.string,
    /** The width of the grid */
    gridWidth: _propTypes2.default.string.isRequired,
    /** The className of the grid container */
    className: _propTypes2.default.string,
    /** Grid ref */
    gridRef: _propTypes2.default.any,
    /** Utility test id */
    dataTestId: _propTypes2.default.string
};

DataGridBase.defaultProps = {
    className: '',
    gridRef: _react2.default.createRef()
};

exports.default = _react2.default.memo(DataGridBase);