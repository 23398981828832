import { createReducer } from '../../utils/reducerUtils';
import updateObject from '../../utils/updateObject';
import { FETCH_USER_SUCCESS, LOGOUT_USER } from '../actions/actionTypes';

const initialState = {
    user: {},
};

const authenticationReducer = createReducer(initialState, {
    [FETCH_USER_SUCCESS]: (state, action) => updateObject(state, { user: { ...action.payload, isFetched: true } }),
    [LOGOUT_USER]: state => updateObject(state, initialState),
});

export default authenticationReducer;
