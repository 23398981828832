import { Color, ITheme, SizeUnit } from 'tuui/lib/theme';
import { COLORS } from 'tuui/lib/theme/primitives';
import { getScaledSize, mapColorByHSL, theme } from 'tuui/lib/utils/styles';

export type TagVariant = 'filled' | 'outlined';
export type TagSize = keyof Pick<ITheme['sizes']['scales'], 'md' | 'sm'>;
export type TagColor = keyof Pick<ITheme['palette'], 'primary' | 'secondary' | 'warning' | 'error'>;
export type Pixel = '0' | `${number}px`;

type ThemeColorProducer = ({ theme, $color }: Pick<TagStyledProps, 'theme' | '$color'>) => Color;
type ThemePaddingProducer = ({ theme, $size }: Pick<TagStyledProps, 'theme' | '$size'>) => `${SizeUnit} ${SizeUnit}`;
type ThemeFontSizeProducer = ({ theme, $size }: Pick<TagStyledProps, 'theme' | '$size'>) => SizeUnit;
type ThemeBoxShadowProducer = ({
    theme,
    $color,
}: {
    theme: ITheme;
    $color: TagColor;
}) => 'none' | `${Pixel} ${Pixel} ${Pixel} ${Pixel} ${Color} ${string}`;

export type TagStyledProps = {
    theme: ITheme;
    $variant: TagVariant;
    $color: TagColor;
    $size: TagSize;
    $disabled?: boolean;
    $clickable?: boolean;
};

export interface TagVariantSpec {
    fill: ThemeColorProducer;
    text: ThemeColorProducer;
    padding: ThemePaddingProducer;
    fontSize: ThemeFontSizeProducer;
    boxShadow: ThemeBoxShadowProducer;
    disabled?: Partial<Pick<TagVariantSpec, TagVariantValue>>;
    active?: Partial<Pick<TagVariantSpec, TagVariantValue>>;
}

type TagVariantValue = keyof Pick<TagVariantSpec, 'fill' | 'text' | 'padding' | 'fontSize' | 'boxShadow'>;
type TagVariantState = keyof Omit<TagVariantSpec, TagVariantValue>;

const getTagPadding: ThemePaddingProducer = ({ theme, $size }) =>
    $size === 'md'
        ? `${getScaledSize(theme, 'sm')} ${getScaledSize(theme, 'md')}`
        : `${getScaledSize(theme, 'xs')} ${getScaledSize(theme, 'sm')}`;

const getTagFontSize: ThemeFontSizeProducer = ({ theme, $size }) =>
    $size === 'md' ? theme.typography.sizes.lg : theme.typography.sizes.md;

const getTagFill: ThemeColorProducer = ({ theme, $color }) =>
    mapColorByHSL(theme.palette[$color].fill, hsl => {
        hsl.l -= 10;
    });

const tagDisabledState: Pick<TagVariantSpec, 'fill' | 'text' | 'boxShadow'> = {
    fill: theme('palette.disabled.fill'),
    text: theme('palette.grayscale.5'),
    boxShadow: _ => 'none',
};

const filled: TagVariantSpec = {
    text: _ => COLORS.White,
    fill: ({ theme, $color }) => theme.palette[$color].fill,
    padding: getTagPadding,
    fontSize: getTagFontSize,
    boxShadow: _ => 'none',
    disabled: tagDisabledState,
    active: {
        fill: getTagFill,
        boxShadow: _ => 'none',
    },
};

const outlined: TagVariantSpec = {
    text: ({ theme, $color }) => theme.palette[$color].fill,
    fill: _ => COLORS.White,
    padding: getTagPadding,
    fontSize: getTagFontSize,
    boxShadow: ({ theme, $color }) => `0 0 0 1px ${outlined['text']({ theme, $color })} inset`,
    active: {
        fill: getTagFill,
        boxShadow: ({ theme, $color }) => `0 0 0 1px ${outlined.fill({ theme, $color })} inset`,
    },
    disabled: tagDisabledState,
};

export const tagVariants: Record<TagVariant, TagVariantSpec> = {
    filled,
    outlined,
};

export const tagVariables = (
    state: TagVariantState,
    varName: TagVariantValue,
    { theme, $color, $variant, $size }: TagStyledProps
) => {
    const variantSpec = tagVariants[$variant];
    const varValueForState = variantSpec[state]?.[varName]?.({ theme, $color, $size });
    if (!varValueForState) {
        return null;
    }
    return `--${varName}: ${varValueForState};`;
};

export const applyDisabledState = () => ({ $disabled, ...tagBaseProps }: TagStyledProps) => {
    if (!$disabled) {
        return '';
    }

    return `    
    ${tagVariables('disabled', 'fill', tagBaseProps)};
    ${tagVariables('disabled', 'text', tagBaseProps)};
    ${tagVariables('disabled', 'boxShadow', tagBaseProps)};

    button {
        svg {
            color: ${tagBaseProps.theme.palette.disabled.text}
        }
    }`;
};

export const applyEnableState = () => ({ $variant, $clickable, $disabled, ...tagBaseProps }: TagStyledProps) => {
    if ($disabled || $clickable) {
        return '';
    }

    return `button {
        --text: ${tagVariants[$variant].text(tagBaseProps)};
        --fill: ${tagVariants[$variant].fill(tagBaseProps)};

        svg {
            color: var(--text);
        }

        &:hover:enabled {
            background-color: inherit;
            box-shadow: none;
            --text: ${mapColorByHSL(tagVariants[$variant].text(tagBaseProps), hsl => {
                hsl.l -= 10;
            })};
            color: var(--text);
        }

        &:active {
            background-color: var(--fill);
        }

        &:disabled {
            box-shadow: none;
        }
    }`;
};

export const applyActiveState = () => ({ $disabled, $clickable, ...tagBaseProps }: TagStyledProps) => {
    if ($disabled || !$clickable) {
        return '';
    }
    return `
    &:active {
        ${tagVariables('active', 'boxShadow', tagBaseProps)};
        ${tagVariables('active', 'fill', tagBaseProps)};
        background-color: var(--fill);
        color: ${COLORS.White};

        button {
            ${tagVariables('active', 'fill', tagBaseProps)};
            background-color: var(--fill);

            svg {
                color: ${COLORS.White};
            }
        }
    }
    ${applyEnableState()({ $disabled, ...tagBaseProps })};
    `;
};
