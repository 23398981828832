import React from 'react';
import { TextCheckbox, CheckboxGroup } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from 'modules/tracking/components/TrackWithGTM/trackWithGTM.module.scss';
import EVERGREEN_OPTIONS from '../../config/InitialValues/evergreenOptions';

export const Evergreen = () => {
    const {
        value: evergreen,
        onChange: onEvergreenChange,
        mode,
    } = useFormFieldValue({
        field: 'evergreen',
    });

    return (
        <CheckboxGroup
            onSelectCheckbox={() => onEvergreenChange([EVERGREEN_OPTIONS.EVERGREEN])}
            onUnselectCheckbox={() => onEvergreenChange([])}
            selectedValues={evergreen}
        >
            <TextCheckbox
                value={EVERGREEN_OPTIONS.EVERGREEN}
                title={
                    <FormattedMessage id="pmpDeal.evergreen.checkBox.description" defaultMessage="Set as Evergreen" />
                }
                disabled={mode === FORM_MODES.EDIT}
                helpText={
                    mode === FORM_MODES.EDIT && (
                        <div className={styles['tag']}>
                            <FormattedMessage
                                id="pmpDeal.evergreen.edit.mode.note"
                                defaultMessage="This configuration is not editable after creation. <a>Contact support</a>"
                            />
                        </div>
                    )
                }
            />
        </CheckboxGroup>
    );
};
