import { useEffect, useMemo } from 'react';
import { useEventListener } from 'taboola-ultimate-ui';
import { useAppEventContext } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useChatAgentPermitted } from '../../../components/ChatAgent/hooks/useChatAgentPermitted';
import { ChatAgentContext } from './components/ChatAgentContext/ChatAgentContext';
import { CHAT_AGENT_ACTION_STATUS } from './config/chatAgentActionStatus';
import { CHAT_AGENT_ACTION_TYPE, CHAT_AGENT_MESSAGE_TYPE } from './config/chatAgentActionType';
import { CHAT_AGENT_VERSION_V2 } from './config/chatAgentVersionV2';
import { useChatAgentMessageHandler } from './hooks/useChatAgentMessageHandler';
import { useChatAgentMessageSender } from './hooks/useChatAgentMessageSender';
import { useChatAgentService } from './hooks/useChatAgentService';
import { useUserActionService } from './hooks/useUserActionService';

const CHAT_REPLY_TIMEOUT = 20000;

export const ChatAgentProvider = ({ children }) => {
    const isPermitted = useChatAgentPermitted();
    const { register } = useAppEventContext();
    const {
        isOpened: isChatOpened,
        isIntercomOpened,
        open: openChat,
        close: closeChat,
        container: chatContainer,
        isReady: isChatReady,
        setReady: setChatReady,
        client,
        sessionId,
        setSessionId,
        chatRenderMode,
        isConnected: isChatConnected,
    } = useChatAgentService();
    const {
        registerAgent,
        unregisterAgent,
        clearWindowList,
        sendResponseToChatAgent,
        registerGrid,
        setCellToEdit,
        registerWizard,
        setWizardStep,
        registerChatTextMessageListener,
        notifyChatTextMessageListeners,
    } = useChatAgentMessageSender({ client });
    const { userAction, setCurrentEvent, getCurrentEvent, setCurrentAction } = useUserActionService({
        setCellToEdit,
        sendResponseToChatAgent,
        client,
    });
    const messageHandler = useChatAgentMessageHandler({
        registerAgent,
        unregisterAgent,
        sendResponseToChatAgent,
        setCellToEdit,
        setWizardStep,
        userAction,
        setCurrentEvent,
        getCurrentEvent,
        setCurrentAction,
        openChat,
        client,
        setSessionId,
        notifyChatTextMessageListeners,
    });

    useEventListener('message', messageHandler);

    const value = useMemo(
        () => ({
            userAction,
            registerAgent,
            unregisterAgent,
            registerGrid,
            registerWizard,
            registerChatTextMessageListener,
            isChatOpened,
            isIntercomOpened,
            openChat,
            closeChat,
            chatContainer,
            clearWindowList,
            isChatReady,
            setChatReady,
            client,
            sessionId,
            chatRenderMode,
            isChatConnected,
            sendResponseToChatAgent,
        }),
        [
            userAction,
            registerAgent,
            unregisterAgent,
            registerGrid,
            registerWizard,
            registerChatTextMessageListener,
            isChatOpened,
            isIntercomOpened,
            openChat,
            closeChat,
            chatContainer,
            clearWindowList,
            isChatReady,
            setChatReady,
            client,
            sessionId,
            chatRenderMode,
            isChatConnected,
            sendResponseToChatAgent,
        ]
    );

    useEffect(() => {
        if (!isPermitted) {
            return;
        }

        const unregister = register(fullEvent => {
            const event = fullEvent.event;

            if (
                !CHAT_AGENT_ACTION_TYPE[event.type] ||
                (client === CHAT_AGENT_VERSION_V2 && event.type !== CHAT_AGENT_ACTION_TYPE.FORM_ACTION)
            ) {
                return;
            }

            if (event.type === CHAT_AGENT_ACTION_TYPE.NAVIGATE) {
                sendResponseToChatAgent({
                    actions: [
                        {
                            status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                            actionType: CHAT_AGENT_ACTION_TYPE.NAVIGATE,
                        },
                    ],
                });
                return;
            }
            const { type: actionType, data: actionData, status, message } = event;
            userAction({ actionType, actionData, status, message });
        });

        return unregister;
    }, [register, isPermitted, userAction, sendResponseToChatAgent, client]);

    useEffect(() => {
        let timer;
        return registerChatTextMessageListener(messageAction => {
            const actionType = messageAction?.actionMetadata?.type;
            if (actionType === CHAT_AGENT_MESSAGE_TYPE.OUTBOUND) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    gtmTracker.trackError('Chat agent message timeout');
                }, CHAT_REPLY_TIMEOUT);
                return;
            }

            if (actionType === CHAT_AGENT_MESSAGE_TYPE.INBOUND) {
                clearTimeout(timer);
                return;
            }
        });
    }, [registerChatTextMessageListener, sendResponseToChatAgent]);

    return <ChatAgentContext.Provider value={value}>{children}</ChatAgentContext.Provider>;
};
