import { MODULE_PARAM_NAME } from 'config/routes/module';
import { MODULE_NAME as CAMPAIGNS_MODULE_NAME } from 'modules/campaigns/config/routes';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { ACCOUNT_ID } from './useSelectedAccountId';

const DEFAULT_DIMENSIONS = {
    [CAMPAIGNS_MODULE_NAME]: CAMPAIGN_DIMENSION.SPONSORED,
};

export const DIMENSION = 'dimension';

export const useDimension = queryParamHookFactory(DIMENSION, {
    defaultValue: ({ [MODULE_PARAM_NAME]: module = CAMPAIGNS_MODULE_NAME }) => DEFAULT_DIMENSIONS[module],
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
        queryParams: [ACCOUNT_ID],
    },
});
