import { BarGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import CreativeGraphTooltip from '../../../components/Tooltips/CreativeGraphTooltip';
import { addMeasuredByMoatToMetric } from '../../../utils/addMeasuredByMoat';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const barGraph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-creative',
    metrics: [
        metrics.SPENT,
        metrics.COMPLETED_VIEWS,
        addMeasuredByMoatToMetric(metrics.IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.CPM),
        addMeasuredByMoatToMetric(metrics.VCPM),
        metrics.CPCV,
        addMeasuredByMoatToMetric(metrics.VIEWABILITY_RATE),
        addMeasuredByMoatToMetric(metrics.HUNDRED_PCT_VIEWABILITY_RATE),
        metrics.COMPLETION_RATE,
        metrics.CLICKS,
        metrics.CTR,
        metrics.VCTR,
        metrics.CPA,
        metrics.CVR,
        metrics.CONVERSIONS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.CREATIVE_NAME.field,
    },
    graphTooltip: CreativeGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

export const graph = [barGraph];
