import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { STRIPE_ELEMENT } from 'modules/taboola-common-frontend-modules/stripe';
import { useStripeCardElement } from '../../../../../taboola-common-frontend-modules/stripe/hooks/useStripeCardElement';
import { StripeCardNumber } from './StripeCardNumber';
import commonStyles from '../../../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';

const StripeCardNumberWithIndication = withIndication(StripeCardNumber);

export const StripeCardNumberField = () => {
    const { scrollRef, ...stripeElementProps } = useStripeCardElement(STRIPE_ELEMENT.CARD_NUMBER);

    return (
        <FormField
            inputId="cardNumber"
            ref={scrollRef}
            containerClass={commonStyles['input']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.cardNumber" defaultMessage="Card Number" />}
        >
            <StripeCardNumberWithIndication {...stripeElementProps} />
        </FormField>
    );
};
