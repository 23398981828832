'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _asyncCreatable = require('react-select/async-creatable');

var _asyncCreatable2 = _interopRequireDefault(_asyncCreatable);

var _styleUtils = require('../../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

var _ArrowIcon = require('../ArrowIcon/ArrowIcon');

var _ArrowIcon2 = _interopRequireDefault(_ArrowIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'blueColor': 'var(--blue)',
    'whiteColor': 'var(--white)',
    'taboola-svg-icon': 'asyncTagsDropdown__taboola-svg-icon___3UzkF',
    'arrow': 'asyncTagsDropdown__arrow___1il5d',
    'container': 'asyncTagsDropdown__container___hQjNg',
    'TagsInput': 'asyncTagsDropdown__TagsInput___15-MD'
};


var classNameBuilder = _bind2.default.bind(styles);

var defaultComponents = {
    IndicatorSeparator: null,
    DropdownIndicator: _ArrowIcon2.default
};

var defaultStyles = {
    multiValue: function multiValue(provided) {
        return Object.assign({}, provided, {
            backgroundColor: styles.blueColor,
            color: styles.whiteColor,
            borderRadius: (0, _styleUtils2.default)(6)
        });
    },
    multiValueLabel: function multiValueLabel(provided) {
        return Object.assign({}, provided, {
            color: styles.whiteColor,
            fontSize: (0, _styleUtils2.default)(13)
        });
    },
    multiValueRemove: function multiValueRemove(provided) {
        return Object.assign({}, provided, {
            '&:hover': {
                backgroundColor: styles.whiteColor,
                color: styles.blueColor,
                borderWidth: (0, _styleUtils2.default)(1),
                borderStyle: 'solid',
                borderColor: styles.blueColor,
                borderRadius: (0, _styleUtils2.default)(6)
            },
            color: styles.whiteColor
        });
    }
};

var AsyncTagsDropdown = function AsyncTagsDropdown(props) {
    var loadOptions = props.loadOptions,
        defaultOptions = props.defaultOptions,
        className = props.className,
        customStyles = props.styles,
        components = props.components,
        rest = _objectWithoutProperties(props, ['loadOptions', 'defaultOptions', 'className', 'styles', 'components']);

    var containerClassName = classNameBuilder('TagsInput', className);

    return _react2.default.createElement(_asyncCreatable2.default, Object.assign({
        loadOptions: loadOptions,
        isMulti: true,
        styles: Object.assign({}, defaultStyles, customStyles),
        isSearchable: true,
        classNamePrefix: 'TagsInput',
        defaultOptions: defaultOptions,
        className: containerClassName,
        components: Object.assign({}, defaultComponents, components)
    }, rest));
};

var valueShape = _propTypes2.default.shape({
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.string
});

AsyncTagsDropdown.propTypes = {
    /** html id to set on the input element for accessibility or tests */
    id: _propTypes2.default.string,
    /** autofocus the component on mount */
    autoFocus: _propTypes2.default.bool,
    /** should it be possible to reset value */
    clearable: _propTypes2.default.bool,
    /** field name, for hidden <input /> tag */
    name: _propTypes2.default.string,
    /** whether the component is disabled or not */
    disabled: _propTypes2.default.bool,
    /** lose focus on backspace pressed */
    backspaceRemoves: _propTypes2.default.bool,
    /** onChange handler: function(newOption) {} */
    onChange: _propTypes2.default.func.isRequired,
    /** whether to enable searching feature or not */
    searchable: _propTypes2.default.bool,
    /** className for the outer element */
    className: _propTypes2.default.string,
    /** field placeholder, displayed when there's no value */
    placeholder: _propTypes2.default.node,
    /** selected field value */
    value: _propTypes2.default.oneOfType([valueShape, _propTypes2.default.arrayOf(valueShape), _propTypes2.default.array]),
    /** the components of the async tags input */
    components: _propTypes2.default.object,
    /** function which returns a promise for getting the options */
    loadOptions: _propTypes2.default.func.isRequired,
    /** When set to true, the results for loadOptions('') will be autoloaded * */
    defaultOptions: _propTypes2.default.bool,
    /** custom styles object * */
    styles: _propTypes2.default.object
};

AsyncTagsDropdown.defaultProps = {
    defaultOptions: false,
    autoFocus: false,
    clearable: false,
    disabled: false,
    placeholder: 'Select...'
};

exports.default = AsyncTagsDropdown;