import { useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { CreativeEditorFormBody } from 'modules/campaigns/modules/creative-editor/components/EditorForm/Body/CreativeEditorFormBody';
import { useUnifiedCreativeEditorFormState } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormState';
import { CreativePreviewBase } from 'modules/campaigns/modules/creative-preview';
import CreativePreviewMotion from 'modules/campaigns/modules/creative-preview/components/CreativePreviewMotion/CreativePreviewMotion';
import { UnifiedCreativeEditorFormMediaSection } from './UnifiedCreativeEditorFormMediaSection';
import styles from './unifiedCreativeEditorForm.module.scss';

const previewComponentMap = {
    [CREATIVE_TYPE.MOTION]: CreativePreviewMotion,
    [CREATIVE_TYPE.IMAGE]: CreativePreviewBase,
};

export const UnifiedCreativeEditorForm = ({
    accountId,
    campaignId,
    onCancel,
    headerLabel,
    hideStatusSection,
    showSetupSection = false,
    selectedAccount,
    creativeId,
    campaign: { brandingText, campaignProfile, startDate: startDateCampaign, endDate: endDateCampaign } = {},
    addIdToMatchingCreativeIds,
}) => {
    const { creative, creativeType, isTrackingSectionEnabled } = useUnifiedCreativeEditorFormState();
    const { title, description, cta, thumbnailUrl, creativeFocus } = creative;
    const language = get(campaignProfile, 'language', 'EN');

    const PreviewComponent = previewComponentMap[creativeType];
    const unifiedMediaSection = useMemo(
        () => (
            <UnifiedCreativeEditorFormMediaSection
                accountId={accountId}
                campaignId={campaignId}
                creativeId={creativeId}
                addIdToMatchingCreativeIds={addIdToMatchingCreativeIds}
                onCancel={onCancel}
            />
        ),
        [accountId, campaignId, creativeId, addIdToMatchingCreativeIds, onCancel]
    );

    return (
        <>
            <CreativeEditorFormBody
                mediaSection={unifiedMediaSection}
                className={styles['editor']}
                headerLabel={headerLabel}
                hideStatusSection={hideStatusSection}
                selectedAccount={selectedAccount}
                showSetupSection={showSetupSection}
                language={language}
                isIncludeTrackingSection={isTrackingSectionEnabled}
                startDateCampaign={startDateCampaign}
                endDateCampaign={endDateCampaign}
            />
            <PreviewComponent
                onCancel={onCancel}
                thumbnailUrl={thumbnailUrl}
                title={title}
                description={description}
                cta={cta}
                creativeId={creativeId}
                brandingText={brandingText}
                creativeFocus={creativeFocus}
                language={language}
            />
        </>
    );
};

UnifiedCreativeEditorForm.propTypes = {
    accountId: PropTypes.string,
    campaignId: PropTypes.string,
    onCancel: PropTypes.func,
    headerLabel: PropTypes.node,
    hideStatusSection: PropTypes.bool,
    showSetupSection: PropTypes.bool,
    selectedAccount: PropTypes.object,
    creativeId: PropTypes.string,
    campaign: PropTypes.object,
    addIdToMatchingCreativeIds: PropTypes.bool,
};
