import { isFunction } from 'lodash';

class ValueStore {
    setValue = newValue => {
        if (isFunction(newValue)) {
            this.value = newValue(this.value);
            return;
        }

        this.value = newValue;
    };
    getValue = () => this.value;
}

export const valueStoreFactory = () => new ValueStore();
