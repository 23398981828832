import React from 'react';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { MEDIA_COLUMN_COUNT } from '../../../../creative-creator/config';
import { PaginatedRecommendedMediaTab } from './PaginatedRecommendedMediaTab';

const RecommendedMediaTab = ({ keywords, columnCount, onSelectMedia }) => (
    <PaginatedRecommendedMediaTab keywords={keywords} columnCount={columnCount} onSelectMedia={onSelectMedia} />
);

RecommendedMediaTab.propTypes = {
    keywords: PropTypes.array,
    onSelectMedia: PropTypes.func,
    columnCount: PropTypes.number,
};

RecommendedMediaTab.defaultProps = {
    keywords: [],
    onSelectMedia: noop,
    columnCount: MEDIA_COLUMN_COUNT,
};

const mapStateToProps = state => {
    const { campaignsReducer } = state;

    return {
        recommendedMedia: campaignsReducer.recommendedMedia,
        recommendedMediaStatus: campaignsReducer.recommendedMediaStatus,
        keywords: campaignsReducer.keywords,
    };
};

export { RecommendedMediaTab };
export default connect(mapStateToProps)(RecommendedMediaTab);
