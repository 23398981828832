import { createSelector } from 'reselect';
import { accountsMapSelector, appStateSelector, transformAccountObject } from './baseSelectors';

export const accountsUnderNetworkDataSelector = createSelector(
    appStateSelector,
    appState => appState.accountsUnderNetwork
);

const accountsUnderNetworkSelector = createSelector(
    accountsUnderNetworkDataSelector,
    accountsMapSelector,
    (accountsData, accountsMap) => accountsData.map(account => transformAccountObject(accountsMap[account.accountId]))
);

export default accountsUnderNetworkSelector;
