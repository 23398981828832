import React from 'react';
import { NotificationCard } from 'components/NotificationsCenter/NotificationCard/NotificationCard';
import { useNavigate } from 'hooks';
import { TRACKING_ROUTE } from 'modules/tracking/config';

export const NoBasicTrackingNotification = ({
    notification,
    closeNotificationCenter,
    formattedMessageId,
    formattedMessageValues,
    dataMetricsComponent,
}) => {
    const navigate = useNavigate();
    return (
        <NotificationCard
            notification={notification}
            cardClick={() => navigate(TRACKING_ROUTE)}
            closeNotificationCenter={closeNotificationCenter}
            formattedMessageId={formattedMessageId}
            formattedMessageValues={formattedMessageValues}
            dataMetricsComponent={dataMetricsComponent}
        />
    );
};
