import { get, isEmpty } from 'lodash';
import { set as fpSet } from 'lodash/fp';
import uuid from 'uuid/v1';
import TARGETING_TYPES from '../../modules/common-campaign-form/config/TargetingTypes';

const transformMarketplaceAudienceTargetingFromGW = campaign => {
    const { campaignTargeting = {} } = campaign;

    const rawTargetingCollection = get(campaignTargeting, 'marketplaceAudienceTargeting.collection', []);
    const validTargeting = get(campaignTargeting, 'marketplaceAudienceTargeting.validTargeting', true);

    const collection = isEmpty(rawTargetingCollection)
        ? [{ type: TARGETING_TYPES.INCLUDE, values: [], id: uuid() }]
        : rawTargetingCollection.map(rawTargetingGroup => ({ ...rawTargetingGroup, id: uuid() }));

    const marketplaceAudienceTargeting = { collection, validTargeting };

    return fpSet('campaignTargeting.marketplaceAudienceTargeting', marketplaceAudienceTargeting, campaign);
};

const transformMarketplaceAudienceTargetingToGW = campaign => {
    const { campaignTargeting } = campaign;
    const marketplaceAudienceTargeting = get(campaignTargeting, 'marketplaceAudienceTargeting');

    if (!campaignTargeting || !marketplaceAudienceTargeting) {
        return campaign;
    }

    const { collection } = marketplaceAudienceTargeting;

    const result = collection
        .filter(({ values }) => Array.isArray(values) && values.length > 0)
        .map(({ type, values }) => ({ type, values }));

    return {
        ...campaign,
        campaignTargeting: { ...campaignTargeting, marketplaceAudienceTargeting: { collection: result } },
    };
};

export { transformMarketplaceAudienceTargetingFromGW, transformMarketplaceAudienceTargetingToGW };
