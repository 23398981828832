import React from 'react';
import classnames from 'classnames/bind';
import { Button, CloseIcon, InsertDriveFileOutlinedIcon as FileIcon, Spinner } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './fileDropZone.module.scss';

const classNameBuilder = classnames.bind(styles);

export const FileDropZone = ({
    isLoading,
    filename,
    removeFile,
    children,
    containerClassName,
    description,
    Icon = FileIcon,
}) => {
    let content = (
        <span className={styles['description']}>
            {description}
            {children}
        </span>
    );

    if (isLoading) {
        content = <Spinner className={styles['spinner']} size={21} />;
    } else if (filename) {
        content = (
            <div className={styles['filename-container']}>
                <FileIcon className={styles['file-icon']} fontSize="small" />
                <div className={styles['filename']}>{filename}</div>
                <Button
                    data-testid="close"
                    size={Button.size.sm}
                    variant={Button.variant.text}
                    onClick={removeFile}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                >
                    <CloseIcon fontSize="small" />
                </Button>
            </div>
        );
    }

    return (
        <div className={classNameBuilder('container', containerClassName)}>
            <Icon className={styles['icon']} />
            {content}
        </div>
    );
};
