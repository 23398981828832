import { noop, keys } from 'lodash';
import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import { bulkEditCampaignsGroup } from '../../../flows';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graph } from './graph';
import { defaultColumns, performanceColumns, setupColumns } from './reportPresets';

export const campaignsGroupReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CAMPAIGNS_GROUPS],
    endpoint: 'campaign-group-report',
    graph,
    rowIdField: FIELDS.CAMPAIGNS_GROUP_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.byCampaignsGroup.search.placeholder',
    searchField: FIELDS.CAMPAIGNS_GROUP_NAME.field,
    inlineEditPermissions: ['CAMPAIGN_EDIT', 'SHARED_BUDGET_EDIT'],
    sortMap: {
        [FIELDS.BUDGET.field]: 'budget_limit',
    },
    initialFilters: [],
    filters: [{ id: FILTER_TYPE.CAMPAIGN_GROUP_STATUS, multiSelect: true }],
    bulkActions: [
        {
            key: BULK_ACTION.PLAY,
            //ToDo: implement play action handler
            handler: noop,
        },
        {
            key: BULK_ACTION.PAUSE,
            //ToDo: implement pause action handler
            handler: noop,
        },
        {
            key: BULK_ACTION.EDIT,
            handler:
                ({ selectedRows, accountName, history }) =>
                dispatch => {
                    dispatch(
                        bulkEditCampaignsGroup({
                            history,
                            accountId: accountName,
                            selectedRowsIds: keys(selectedRows),
                        })
                    );
                },
        },
    ],
};

export default campaignsGroupReportConfig;
