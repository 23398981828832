import { SUPPLY_TARGETING_TYPES } from '../../../common-campaign-form/components/SupplyTargeting/const';

const checkSupplyTargettingValidation = ({ data, supplyType }) => {
    if (!data) {
        return true;
    }
    const campaignsArr = Object.values(data);
    if (campaignsArr.length < 2) {
        return true;
    }
    const hasSupplyTargetingType = campaignsArr.some(obj => obj?.campaignTargeting?.supplyTargeting === supplyType);
    if (hasSupplyTargetingType) {
        const filteredObjects = campaignsArr.filter(obj => obj?.campaignTargeting?.supplyTargeting === supplyType);
        return filteredObjects.length === campaignsArr.length;
    }
    return true;
};

export const differentSupplyTargettingValidation = [
    {
        validationFn: (value, _, { data }) =>
            checkSupplyTargettingValidation({ data, supplyType: SUPPLY_TARGETING_TYPES.APPLE_NEWS }),
        messageId: 'validations.error.creative.different.environment.apple',
        defaultMessage:
            'Cannot edit campaigns with different environment targeting simultaneously. Please finish editing the Apple campaign before switching to another.',
    },
    {
        validationFn: (value, _, { data }) =>
            checkSupplyTargettingValidation({ data, supplyType: SUPPLY_TARGETING_TYPES.YAHOO_MAIL }),
        messageId: 'validations.error.creative.different.environment.yahoo.mail',
        defaultMessage:
            'Cannot edit campaigns with different environment targeting simultaneously. Please finish editing the Mail campaign before switching to another.',
    },
];
