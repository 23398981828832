import React from 'react';

const MonthlySVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
        <g fill="#000" fillRule="evenodd">
            <path fillRule="nonzero" d="M5 19.876v-.752h41v.752z" />
            <path d="M45 10H7c-1.161 0-2 .843-2 2v27c0 1.157.839 2 2 2h38c1.162 0 2-.843 2-2V12c0-1.157-.838-2-2-2m0 1c.503 0 1 .497 1 1v27c0 .503-.497 1-1 1H7c-.503 0-1-.497-1-1V12c0-.503.497-1 1-1h38" />
            <path d="M34.55 14h-17.1c-.248 0-.45.224-.45.5s.202.5.45.5h17.1c.248 0 .45-.224.45-.5s-.202-.5-.45-.5" />
            <path
                fillRule="nonzero"
                d="M37.5 26a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM37.5 31a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM28.5 26a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM28.5 31a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM19.5 26a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM19.5 31a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM10.5 26a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM10.5 31a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM37.5 36a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM28.5 36a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM19.5 36a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM10.5 36a.5.5 0 1 1 0-1h4a.5.5 0 1 1 0 1h-4zM14 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM38 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            />
        </g>
    </svg>
);

export default MonthlySVGIcon;
