import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox, FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { UsingGoogleTagManagerCheckBoxToolTip } from './UsingGoogleTagManagerCheckBoxToolTip';
import styles from './usingGoogleTagManagerCheckBox.module.scss';

export const UsingGoogleTagManagerCheckBox = ({ onChange, value }) => {
    const selectedValue = useMemo(() => (value ? [value] : []), [value]);
    return (
        <FormField inputId="is-using-google-tag-manager">
            <CheckboxGroup
                onSelectCheckbox={() => onChange(true)}
                onUnselectCheckbox={() => onChange(false)}
                selectedValues={selectedValue}
            >
                <TextCheckbox
                    labelClassName={styles['content']}
                    value={value}
                    title={
                        <FormattedMessage
                            id="tracking.setup.google.tag.manager.checkbox"
                            defaultMessage="I'm using Google Tag Manager"
                        />
                    }
                    helpText={<UsingGoogleTagManagerCheckBoxToolTip />}
                />
            </CheckboxGroup>
        </FormField>
    );
};

UsingGoogleTagManagerCheckBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
};
