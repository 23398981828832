import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useElementSize } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { COMPONENT_STATUS } from 'services/constants';
import { EmptyGallery } from '../ImageGallery';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import PaginatedGalleryItem from './PaginatedGalleryItem';
import PaginatedGrid from './PaginatedGrid';
import styles from './paginatedGallery.module.scss';

const classNameBuilder = classnames.bind(styles);

const SPACE_FOR_GALLERY_ITEM_DROPDOWN = 20;

const PaginatedGallery = ({
    accountId,
    className,
    gap,
    itemOnClick,
    columnCount,
    totalItemCount,
    scrollbarWidth,
    status,
    items,
    emptyGalleryMessagesPrefix,
    emptyGalleryType,
    gridItemComponent = PaginatedGalleryItem,
    GalleryItemDropdown,
    ...rest
}) => {
    const { setStatus, setItems } = useMediaTabsContext();
    const { height, width, ref: containerRef } = useElementSize();
    const columnWidth = (width - gap * (columnCount - 1)) / columnCount;
    const isGalleryItemDropdownEmpty = useConfigMatch({
        [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND]: 'false',
        [FEATURE_FLAGS.CREATIVE_STUDIO_GENERATE_VARIATIONS]: 'false',
        [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'false',
    });
    const shouldHideGalleryItemDropdown = isGalleryItemDropdownEmpty || !GalleryItemDropdown;
    const galleryItemDropdownHeight = shouldHideGalleryItemDropdown ? 0 : SPACE_FOR_GALLERY_ITEM_DROPDOWN;
    const itemHeight = columnWidth * (9 / 16);
    const rowHeight = itemHeight + galleryItemDropdownHeight;
    const getColumnWidth = useCallback(() => columnWidth, [columnWidth]);
    const getItemHeight = useCallback(() => itemHeight, [itemHeight]);
    const getRowHeight = useCallback(() => rowHeight, [rowHeight]);
    const gridStyleOverride = useMemo(
        () => ({ width: scrollbarWidth ? width + scrollbarWidth : width }),
        [scrollbarWidth, width]
    );

    const isGalleryEmpty = !accountId || (status === COMPONENT_STATUS.ACTIVE && items.length <= 0);

    useEffect(() => {
        setStatus(status);
        setItems(items);
    }, [items, setItems, setStatus, status]);

    return !isGalleryEmpty ? (
        <div ref={containerRef} className={classNameBuilder('container', className)} aria-label="paginated gallery">
            <PaginatedGrid
                items={items}
                gridItemComponent={gridItemComponent}
                gap={gap}
                width={width}
                height={height}
                columnCount={columnCount}
                columnWidth={getColumnWidth}
                estimatedColumnWidth={columnWidth + gap}
                itemHeight={getItemHeight}
                rowHeight={getRowHeight}
                estimatedRowHeight={rowHeight + gap}
                totalItemCount={totalItemCount}
                gridStyleOverride={gridStyleOverride}
                itemOnClick={itemOnClick}
                GalleryItemDropdown={GalleryItemDropdown}
                {...rest}
            />
        </div>
    ) : (
        <EmptyGallery messagesPrefix={emptyGalleryMessagesPrefix} type={emptyGalleryType} />
    );
};

PaginatedGallery.propTypes = {
    accountId: PropTypes.string,
    className: PropTypes.string,
    gap: PropTypes.number,
    itemOnClick: PropTypes.func,
    columnCount: PropTypes.number,
    totalItemCount: PropTypes.number,
    scrollbarWidth: PropTypes.number,
    items: PropTypes.array,
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    emptyGalleryMessagesPrefix: PropTypes.string,
    emptyGalleryType: PropTypes.string,
};

PaginatedGallery.defaultProps = {
    gap: 12,
    columnCount: 3,
    totalItemCount: 120,
};

export default PaginatedGallery;
