import { useMemo } from 'react';
import { filter, flatMap, toString } from 'lodash';
import { useMarketplaceAudienceTargetingCollection } from './useMarketplaceAudienceTargetingCollection';

// Given the index of this targeting collection group, return the segment ids that should be hidden for this group
export const useMarketplaceAudiencesHiddenIdsSet = index => {
    const { marketplaceAudienceTargetingCollection } = useMarketplaceAudienceTargetingCollection();

    const hiddenIdsSet = useMemo(() => {
        const otherTargetings = filter(marketplaceAudienceTargetingCollection, (_, otherIndex) => otherIndex !== index);

        const otherTargetedIdsList = flatMap(otherTargetings, 'values').map(toString);

        return new Set(otherTargetedIdsList);
    }, [index, marketplaceAudienceTargetingCollection]);

    return hiddenIdsSet;
};
