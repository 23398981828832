import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { isEmpty, head } from 'lodash';
import { CollapsibleList, useDebouncedValue, useElementSize } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { TargetingTypeBareDropdown } from '../../../../../campaigns/modules/common-campaign-form/components/TargetingTypeDropdown';
import {
    LoadingMode,
    MAX_SEGMENTS_TO_SELECT,
    TAXONOMY_SEPARATOR,
} from '../../../../../campaigns/modules/common-campaign-form/components/Tree';
import { getSubTaxonomy } from '../../../../../campaigns/modules/common-campaign-form/components/Tree/utils/treeUtils';
import { useSendSegmentSearchEvent } from '../../../../../campaigns/modules/common-campaign-form/utils/useSendSegmentSearchEvent';
import { TARGETING_OPTIONS } from '../../../CombinedAudiences/CombinedTargetingTypes';
import { TargetingTypeDropdownOption } from '../../../CombinedAudiencesTargetingTypeDropdown/CombinedAudiencesTargetingTypeDropdown';
import { CombinedAudiencesWithoutBundlingProvider } from '../CombinedAudiencesWithoutBundlingContext';
import SegmentListItemContentWithValidations from '../SegmentListItemContent/SegmentListItemContentWithValidations';
import SegmentsDropdown from '../SegmentsDropdown/SegmentsDropdown';
import { useCombinedAudienceIngredientsHiddenIdsSet, useTaxonomiesResources } from '../hooks';
import { useSegmentsApi, useSelectedSegments } from '../hooks';
import { getFieldNames, getTargetingDropdownProps } from '../utils/andLogicUtils';
import { useCombinedAudienceIngredientsTreeState } from './hooks';
import commonStyles from '../../../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import styles from './combinedAudiencesWithoutBundlingTargeting.module.scss';

const leafTransformer = ({ provider, size, articles, description, taxonomy, allowedCountries }) => ({
    provider,
    size,
    articles,
    description,
    taxonomy: taxonomy.join(TAXONOMY_SEPARATOR),
    allowedCountries,
});

const parentTransformer = ({ itemsInGroup, level }) => ({
    taxonomy: getSubTaxonomy(head(itemsInGroup), level),
});

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const CombinedAudiencesWithoutBundlingTargeting = ({
    selectedAccountId,
    index,
    combinedAudienceIngredientsTypeCounts,
    setCombinedAudienceIngredientsTypeByIndex,
    targetingType,
    setTargetingType,
    shouldDisableExcludeType,
}) => {
    const hiddenIdsSet = useCombinedAudienceIngredientsHiddenIdsSet(index);

    const [searchText, setSearchText] = useState('');
    const { formatMessage } = useIntl();
    const search = useDebouncedValue(searchText.length > 2 ? searchText : '');

    const { combinedAudienceIngredientsFieldName } = getFieldNames(index);

    const [includeErrorListValidations] = useState([]);
    const [includeWarningListValidations] = useState([]);

    const { segments, ...treeStateParams } = useSegmentsApi({
        search,
        selectedAccountId,
    });

    const { getTaxonomyValue, reloadTaxonomies } = useTaxonomiesResources({ selectedAccountId });

    const {
        selectedLeaves,
        handleRemoveItem,
        handleReload,
        setSelectedLeavesByIds,
        handleSelectNode,
        handleSelectAll,
        expandAll,
        setIsReady,
        ...treeProps
    } = useCombinedAudienceIngredientsTreeState({
        search,
        leaves: segments,
        leafTransformer,
        parentTransformer,
        getTaxonomyValue,
        formatMessage,
        index,
        hiddenIdsSet,
        ...treeStateParams,
    });

    const { includeLeaves, removeIncludeLeaves, includeFailedValidationData, scrollRef } = useSelectedSegments({
        targetingType,
        selectedLeaves,
        setSelectedLeavesByIds,
        includeIdsFormFieldName: combinedAudienceIngredientsFieldName,
        includeErrorListValidations,
        includeWarningListValidations,
        setIsReady,
        isReady: treeProps.isReady,
    });

    // flow of initial load, pagination, search and providers change
    useLayoutEffect(() => {
        handleReload();
    }, [handleReload]);

    // flow of initial load of taxonomies
    useEffect(() => {
        reloadTaxonomies();
    }, [reloadTaxonomies]);

    // expand all in case of search change and not empty
    useEffect(() => {
        if (!isEmpty(search)) {
            expandAll();
        }
    }, [search, expandAll]);

    useSendSegmentSearchEvent({
        component: 'combined Audiences Search',
        searchText: search,
        resultsCount: segments.length,
        isLoading: treeProps.loadingMode !== LoadingMode.NONE,
    });

    // when selected leaves are empty need to set defaultProviders in case of node select
    // it needed for tree to check only enabled leaves
    const handleSelectNodeWrapper = useCallback(
        (...params) => {
            const [path, { label }] = params;
            const component = search ? 'Combined Search' : 'Combined Browse';
            const columnName = path.split('>').slice(0, -1).join('>');
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component,
                value: label,
                columnName,
            });

            handleSelectNode(...params, MAX_SEGMENTS_TO_SELECT);
        },
        [handleSelectNode, search]
    );

    const handleSelectAllWrapper = useCallback(
        check => {
            handleSelectAll(check, MAX_SEGMENTS_TO_SELECT);
        },
        [handleSelectAll]
    );

    const { width, ref } = useElementSize();

    const targetingDropdownProps = getTargetingDropdownProps(shouldDisableExcludeType, targetingType);

    return (
        <div className={styles['container']} data-testid="combined-audience-ingredients-targeting">
            <div className={styles['dropdown-container']} ref={ref}>
                <CombinedAudiencesWithoutBundlingProvider
                    limit={MAX_SEGMENTS_TO_SELECT}
                    handleSelectNode={handleSelectNodeWrapper}
                    handleSelectAll={handleSelectAllWrapper}
                    {...treeProps}
                >
                    <TargetingTypeBareDropdown
                        onChange={({ value }) => setTargetingType(value)}
                        value={targetingType}
                        aria-label="toggle targeting type dropdown"
                        options={TARGETING_OPTIONS}
                        optionItemRenderer={TargetingTypeDropdownOption}
                        {...targetingDropdownProps}
                    />
                    <div className={styles['delimiter']} />
                    <SegmentsDropdown search={searchText} onSearchChange={text => setSearchText(text)} width={width} />
                </CombinedAudiencesWithoutBundlingProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting.include"
                items={includeLeaves}
                ItemComponent={SegmentListItemContentWithValidations}
                metadata={{
                    field: combinedAudienceIngredientsFieldName,
                }}
                deleteItem={handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.marketplace.include.audiences.title"
                        defaultMessage="Included Marketplace Audiences"
                    />
                }
                clearItems={removeIncludeLeaves}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...includeFailedValidationData}
                indicationContainerClass={commonStyles['warning-indication']}
                ref={scrollRef}
            />
        </div>
    );
};
