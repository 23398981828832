import React from 'react';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const AiVariationsUrlFieldDescription = () => (
    <>
        <FormattedMessage id="creative.creator.urls.label.0" defaultMessage="Access " />
        <span
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="UrlField - Best Practice Template Link Clicked"
        >
            <FormattedMessage id="creative.creator.urls.label.1" defaultMessage="<a>best practice templates</a>" />
        </span>
        <FormattedMessage
            id="creative.creator.urls.label.2"
            defaultMessage=" through Taboola’s trusted landing page partners"
        />
    </>
);
