import { forEach, get, isUndefined } from 'lodash';
import { initiateStateFromStorage, SAVE_STATE_TO_STORAGE } from '../actions';
import {
    propsToPersist as campaignReducerProps,
    REDUCER_NAME as CAMPAIGNS_REDUCER_NAME,
} from '../modules/campaigns/reducer';
import { FETCH_USER_SUCCESS } from '../modules/taboola-common-frontend-modules/authentication/actions';
import { propsToPersist as appReducerProps, REDUCER_NAME as APP_REDUCER_NAME } from '../reducer';
import { getItem, saveItem } from '../services/persister';

const reducerWithPropsToPersist = {
    [CAMPAIGNS_REDUCER_NAME]: campaignReducerProps,
    [APP_REDUCER_NAME]: appReducerProps,
};

export const getStorageItemKey = username => `PersistedState_${username}`;

const calculateSubsetOfStateByConfiguration = state => {
    const filteredState = {};

    forEach(reducerWithPropsToPersist, (propsToPersist, reducerName) => {
        const reducerState = {};

        propsToPersist.forEach(propName => {
            const currentPropValue = get(state, `${reducerName}.${propName}`);

            if (!isUndefined(currentPropValue)) {
                reducerState[propName] = currentPropValue;
            }
        });

        filteredState[reducerName] = reducerState;
    });

    return filteredState;
};

const getStateFromStorage = username => {
    const currentStateInStorage = getItem(getStorageItemKey(username), {});

    return calculateSubsetOfStateByConfiguration(currentStateInStorage);
};

const saveStateToStorage = (username, state) => {
    const subsetOfState = calculateSubsetOfStateByConfiguration(state);

    saveItem(getStorageItemKey(username), subsetOfState);
};

const statePersister = store => next => action => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_USER_SUCCESS: {
            const { username } = payload;
            const stateFromStorage = getStateFromStorage(username);

            store.dispatch(initiateStateFromStorage(stateFromStorage));

            break;
        }
        case SAVE_STATE_TO_STORAGE: {
            const {
                authenticationReducer: {
                    user: { username },
                },
            } = store.getState();

            saveStateToStorage(username, store.getState());

            break;
        }
        default: {
            break;
        }
    }

    return next(action);
};

export default statePersister;
