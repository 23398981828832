import { getLocationSearch } from 'modules/taboola-common-frontend-modules/query-params';
import history from 'services/history';
import { NAVIGATE, REPLACE_NAVIGATION_STATE, WINDOW_NAVIGATE } from '../actions';

const actionOperationMap = {
    [NAVIGATE]: 'push',
    [REPLACE_NAVIGATION_STATE]: 'replace',
};

const handleMetaQuery = action => {
    const { meta } = action;
    const newSearch = meta?.query;
    if (!newSearch) {
        return;
    }

    const search = getLocationSearch();
    if (newSearch === search) {
        return;
    }

    const { pathname, hash } = window.location;

    history.push({
        pathname,
        search: newSearch,
        hash,
    });
};

const navigation = store => {
    window.addEventListener('popstate', () => store.dispatch({ type: WINDOW_NAVIGATE }));

    return next => action => {
        const { type, payload } = action;
        const operation = actionOperationMap[type];

        handleMetaQuery(action);

        if (!operation) {
            return next(action);
        }

        const { history, destination: pathname, routerLocationState } = payload;
        history[operation](pathname, routerLocationState);

        return next(action);
    };
};

export default navigation;
