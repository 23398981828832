const PAGE_SIZE = 20;

const unipFunnelAPIPrefix = accountId => `/${accountId}/funnels`;

export const unipFunnelApiFactory = ({ callGetApi, callPostApi, queryClient, callPatchApi, callDeleteApi }) => ({
    createUnipFunnel: (accountId, queryParams) => {
        return callPostApi(`${unipFunnelAPIPrefix(accountId)}`, queryParams);
    },
    updateUnipFunnel: (accountId, funnelId, queryParams) => {
        return callPatchApi(`${unipFunnelAPIPrefix(accountId)}/${funnelId}`, queryParams);
    },
    getAllFunnels: accountId => {
        return callGetApi(`${unipFunnelAPIPrefix(accountId)}`);
    },
    getFunnel: (accountId, funnelId) => {
        return callGetApi(`${unipFunnelAPIPrefix(accountId)}/${funnelId}`);
    },
    deleteUnipFunnel: (accountName, funnelId) => {
        return callDeleteApi(`${unipFunnelAPIPrefix(accountName)}/${funnelId}`);
    },
    getConversionRulesData: (accountId, isNetwork, page, pageSize = PAGE_SIZE) => {
        return callGetApi(`${unipFunnelAPIPrefix(accountId)}/conversions`, {
            isNetwork,
            paginationEnabled: true,
            page,
            pageSize,
        });
    },
});
