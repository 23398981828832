import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const PerformanceRuleExecutedActionAccountNameCellRenderer = ({ data: { accountName, accountId } }) => (
    <FormattedMessage
        id="performance.rule.executed.actions.report.account.name"
        defaultMessage="{accountName} (ID: {accountId})"
        values={{ accountName, accountId }}
    />
);
