import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { DeletableItem } from 'taboola-ultimate-ui';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonAudienceListItemContent.module.scss';
import { AudienceDetailsWithBundling } from '../../../campaigns/modules/common-campaign-form/components/AudienceDetailsWithBundling/AudienceDetailsWithBundling';
import { getFormattedSizeAndArticles } from '../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import { SegmentNodeTooltip } from '../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiencesWithBundling/SegmentNodeTooltip/SegmentNodeTooltip';
import styles from './combinedSegmentListItemContentWithBundling.module.scss';

export const CombinedSegmentListItemContentWithBundling = ({ item, deleteItem, searchText }) => {
    const { size, articles, label, loading } = item;

    const handleOnClick = () => {
        deleteItem(item);
    };

    if (loading) {
        return (
            <ContentLoader width={580} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="12" rx="8" ry="8" width="250" height="12" />
                <rect x="510" y="12" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    const { formattedSize, formattedArticles } = getFormattedSizeAndArticles({
        size,
        decimalCount: 1,
    });

    return (
        <DeletableItem
            onDelete={handleOnClick}
            aria-label={`Removable ${label}`}
            buttonProps={{ 'aria-label': `Remove ${label} segment` }}
        >
            <div className={commonStyles['container']}>
                <AudienceDetailsWithBundling
                    className={styles['audience-details']}
                    label={label}
                    highLightText={searchText}
                    labelTooltip={<SegmentNodeTooltip item={item} formattedSize={formattedSize} />}
                    showSizes
                    size={size}
                    articles={articles}
                    formattedSize={formattedSize}
                    formattedArticles={formattedArticles}
                />
            </div>
        </DeletableItem>
    );
};

CombinedSegmentListItemContentWithBundling.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    deleteItem: PropTypes.func,
    showRemoveButton: PropTypes.bool,
    searchText: PropTypes.string,
};
