import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { every } from 'lodash/collection';
import { CollapsibleList, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { DropdownOption } from 'components/Dropdown';
import { fetchBrowsers } from 'modules/campaigns/modules/common-campaign-form/flows';
import { useSelectedValueToOptionMapping } from 'modules/campaigns/modules/common-campaign-form/hooks';
import { listOfBrowsersSelector } from 'modules/campaigns/selectors';
import { withIndication } from 'modules/errors';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import TargetingTypes from '../../config/TargetingTypes';
import { useCurrentCampaignTargetingByType } from '../SiteTargeting/utils/useCurrentCampaignTargetingByType';
import { ListItem } from '../Targeting';
import TargetingDropdownBox from '../TargetingDropdownBox/TargetingDropdownBox';
import { isBrowserTargetingRestrictionEnforced } from './validations/isBrowserTargetingRestrictionEnforced';
import styles from './browserTargetingSmartList.module.scss';

const MSG_ID_PREFIX = 'campaign.editor.targeting.browser.options';
const ITEM_HEIGHT = 40;
export const LIST_PLACEHOLDER = [
    {
        name: 'ALL',
        value: 'ALL',
        id: 'ALL',
    },
];

const validations = [
    {
        validationFn: (list, _, dependencies) =>
            every(list, item => isBrowserTargetingRestrictionEnforced({ item, ...dependencies })),
        messageId: 'campaign.editor.targeting.browser.unsupported.browser.targeting',
        defaultMessage:
            'Please note: This campaign will not serve on the selected Browser because one or more of the following are' +
            ' applied: DMA targeting, Region targeting, City targeting, Zip Code targeting, Connection Type targeting,' +
            ' Topic Segments targeting, Audience or 3rd Party Trackers.',
        severity: INDICATION_TYPES.WARNING,
    },
];

const ListHeaderTitle = () => (
    <FormattedMessage id="campaign.editor.targeting.browser.title.selected" defaultMessage="Selected Browsers" />
);
const Placeholder = () => (
    <FormattedMessage id="campaign.editor.targeting.browser.select.placeholder" defaultMessage="Select Browsers..." />
);

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const BrowserTargetingSmartList = () => {
    const dispatch = useDispatch();
    const listOfBrowsers = useSelector(listOfBrowsersSelector);
    const { value: type, onChange: setType } = useFormFieldValue({
        field: 'campaignTargeting.browserTargeting.type',
    });
    const displayType = !type || type === TargetingTypes.ALL ? TargetingTypes.INCLUDE : type;
    const currentCampaignTargetingByType = useCurrentCampaignTargetingByType();

    const {
        value: values,
        onChange: setValues,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'campaignTargeting.browserTargeting.values',
        validations,
        validationDependencies: {
            type,
            listOfBrowsers,
            currentCampaignTargetingByType,
        },
    });

    const availableOptions = useMemo(() => {
        const valueSet = new Set(values);
        return listOfBrowsers.filter(({ value }) => !valueSet.has(value));
    }, [listOfBrowsers, values]);

    const selectedOptions = useSelectedValueToOptionMapping(values, listOfBrowsers, {
        defaultValueMapping: value => ({ value, label: value, id: value }),
    });

    const deleteItem = useCallback(({ value }) => setValues(prev => prev.filter(val => val !== value)), [setValues]);
    const addItem = useCallback(
        ({ value }) => {
            if (type === TargetingTypes.ALL) {
                setType(TargetingTypes.INCLUDE);
            }
            setValues(prev => [value, ...prev]);
        },
        [setType, setValues, type]
    );
    const clearItems = useCallback(() => {
        setValues([]);
    }, [setValues]);
    const handleTargetingTypeChange = useCallback(({ value }) => setType(value), [setType]);

    useEffect(() => {
        dispatch(fetchBrowsers());
    }, [dispatch]);

    const metadata = useMemo(() => ({ targetingType: displayType, msgIdPrefix: MSG_ID_PREFIX }), [displayType]);
    const isListFilled = Boolean(selectedOptions.length);
    return (
        <>
            <TargetingDropdownBox
                itemType={displayType}
                onSelectItemType={handleTargetingTypeChange}
                selectedItems={selectedOptions}
                itemPlaceholder={<Placeholder />}
                onAddItem={addItem}
                allItems={availableOptions}
                itemOptionRenderer={props => <DropdownOption {...props} msgIdPrefix={MSG_ID_PREFIX} />}
            />
            <CollapsibleListWithIndication
                items={isListFilled ? selectedOptions : LIST_PLACEHOLDER}
                data-automation={!isListFilled && 'placeholder-collapsible-list'}
                ItemComponent={ListItem}
                metadata={metadata}
                itemHeight={ITEM_HEIGHT}
                deleteItem={isListFilled ? deleteItem : undefined}
                clearItems={isListFilled ? clearItems : undefined}
                listHeaderTitle={<ListHeaderTitle />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                {...failedValidationData}
            />
        </>
    );
};

export default BrowserTargetingSmartList;
