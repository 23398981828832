import React from 'react';
import { isEqual, keys, size, values } from 'lodash';
import memoizeOne from 'memoize-one';
import { COMMON_FLAGS, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { HISTORY_METHOD } from 'modules/taboola-common-frontend-modules/query-params';
import PERFORMANCE_STATUS from '../../../../../config/performanceStatus';
import { bulkResubmitCreatives, deleteCreatives, updateCreatives } from '../../../../../flows';
import { campaignsStateSelector } from '../../../../../selectors';
import { isCarouselCreative } from '../../../../creative-creator/components/CarouselCardList/utils/isCarouselCreative';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import { bulkDuplicateCreative, bulkEditCreative } from '../../../flows';
import { getIsEligibleForResubmission, resubmitModalPrompt, sendGtmResubmitEvent } from '../../../hooks';
import { PAGE_SIZE } from '../../../utils';
import { hasResubmittableData, isAppInstall, isCreativeResubmittable, isEditCreativeAllowed } from '../../predicates';

const hasCarousel = memoizeOne(selectedRows => values(selectedRows).some(isCarouselCreative));

export const getEditDisabledTooltip = memoizeOne(selectedRows => {
    const rows = values(selectedRows);
    const hasVideoAds = rows.some(({ performanceStatus }) => performanceStatus === PERFORMANCE_STATUS.MOTION);
    const hasImageAds = rows.some(({ performanceStatus }) => performanceStatus === PERFORMANCE_STATUS.IMAGE);
    if (hasVideoAds && hasImageAds) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.edit.creatives.tooltip"
                    defaultMessage="Motion Ads can not be edited with Single Image Ads in bulk. Please select only one ad type."
                />
            ),
        };
    }
    const hasUnresubmittableAds = rows.some(row => hasResubmittableData(row) && !isCreativeResubmittable(row));
    if (hasUnresubmittableAds) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.edit.creatives.tooltip.resubmit"
                    defaultMessage="At least one ad on this list has reached the submission limit. Please remove the ad(s) from the list in order to try again. Before attempting to submit new ads, please be sure you have reviewed our <a>Advertiser Content Policies</a>."
                />
            ),
        };
    }
    const hasUneditableRows = rows.some(row => !isEditCreativeAllowed(row));
    if (hasUneditableRows) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.edit.creatives.tooltip.general"
                    defaultMessage="Not all selected ads can be edited."
                />
            ),
        };
    }
});
export const getDuplicateDisabledTooltip = memoizeOne(selectedRows => {
    if (hasCarousel(selectedRows)) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.carousel"
                    defaultMessage="At least one carousel card selected, action is unavailable."
                />
            ),
        };
    }
    if (values(selectedRows).some(isAppInstall)) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.appInstall"
                    defaultMessage="At least one app install card selected, action is unavailable."
                />
            ),
        };
    }
});
export const getResubmitDisabledTooltip = memoizeOne((selectedRows, commonConfig = {}) => {
    const rejectReasonsRaw = commonConfig[COMMON_FLAGS.RESUBMIT_CREATIVES_LANDING_PAGE_REJECTION_REASONS] || '';
    const eligibleRejectReasons = rejectReasonsRaw.split(',');

    const isEligibleForResubmission = getIsEligibleForResubmission(
        selectedRows,
        keys(selectedRows),
        eligibleRejectReasons
    );

    if (!isEligibleForResubmission) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.resubmit"
                    defaultMessage="Not all selected ads can be resubmitted."
                />
            ),
        };
    }
    if (hasCarousel(selectedRows)) {
        return {
            disabled: true,
            message: (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.carousel"
                    defaultMessage="At least one carousel card selected, action is unavailable."
                />
            ),
        };
    }
});

const getDeleteModalMessages = selectedCount => ({
    title: (
        <FormattedMessage
            id="creative.delete.header"
            tagName="h2"
            defaultMessage="{selectedCount, plural, one{Delete Ad?} other{Delete Ads?}}"
            values={{ selectedCount }}
        />
    ),
    content: (
        <FormattedMessage
            id="app.modal.deleteCreatives.content"
            defaultMessage="{selectedCount, plural, one{You are about to delete {selectedCount} ad. Once an ad is deleted, it can never run again. Instead of deleting, you can pause ads. Do you wish to proceed?} other{You are about to delete {selectedCount} ads. Once an ad is deleted, it can never run again. Instead of deleting, you can pause ads. Do you wish to proceed?}}"
            values={{ selectedCount }}
        />
    ),
    formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" /> },
});

const generateSharedValues = selectedRows => {
    const creativeValues = Object.values(selectedRows).map(
        ({ title, description, url, cta, thumbnailUrl, videoUrl }) => ({
            title,
            description,
            url,
            cta,
            thumbnailUrl,
            videoUrl,
        })
    );
    return creativeValues.length
        ? creativeValues.reduce((prevSharedValues, creative) => {
              let sharedValues = prevSharedValues;
              Object.keys(prevSharedValues).forEach(key => {
                  if (!isEqual(prevSharedValues[key], creative[key])) {
                      const { [key]: value, ...rest } = sharedValues;
                      sharedValues = rest;
                  }
              });
              return sharedValues;
          })
        : {};
};

export const bulkActions = [
    {
        key: BULK_ACTION.PLAY,
        handler:
            ({ accountName, selectedRows }) =>
            dispatch =>
                dispatch(updateCreatives(accountName, keys(selectedRows), { isActive: true })),
        enableCondition: ({ selectedRows = {} }) => !hasCarousel(selectedRows),
        getTooltip: ({ selectedRows = {} }) =>
            hasCarousel(selectedRows) ? (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.carousel"
                    defaultMessage="At least one carousel card selected, action is unavailable."
                />
            ) : null,
    },
    {
        key: BULK_ACTION.PAUSE,
        handler:
            ({ accountName, selectedRows }) =>
            dispatch =>
                dispatch(updateCreatives(accountName, keys(selectedRows), { isActive: false })),
        enableCondition: ({ selectedRows = {} }) => !hasCarousel(selectedRows),
        getTooltip: ({ selectedRows = {} }) =>
            hasCarousel(selectedRows) ? (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.carousel"
                    defaultMessage="At least one carousel card selected, action is unavailable."
                />
            ) : null,
    },
    {
        key: BULK_ACTION.EDIT,
        handler:
            ({ selectedRows, accountName, history }) =>
            dispatch => {
                const bulkEditCreativesSharedValues = generateSharedValues(selectedRows);

                dispatch(
                    bulkEditCreative({
                        history,
                        accountId: accountName,
                        selectedRowsIds: keys(selectedRows),
                        bulkEditCreativesSharedValues,
                        selectedRows,
                    })
                );
            },
        enableCondition: ({ selectedRows = {} }) => !getEditDisabledTooltip(selectedRows)?.disabled,
        getTooltip: ({ selectedRows = {} }) => getEditDisabledTooltip(selectedRows)?.message,
        tooltipOptions: { interactive: true },
    },
    {
        key: BULK_ACTION.DUPLICATE,
        handler:
            ({ accountName, history, selectedRows }) =>
            dispatch =>
                dispatch(
                    bulkDuplicateCreative({ history, accountId: accountName, selectedRowsIds: keys(selectedRows) })
                ),
        enableCondition: ({ selectedRows = {} }) => !getDuplicateDisabledTooltip(selectedRows)?.disabled,
        getTooltip: ({ selectedRows = {} }) => getDuplicateDisabledTooltip(selectedRows)?.message,
    },
    {
        key: BULK_ACTION.DELETE,
        getPrompt: ({ selectedRows }) => getDeleteModalMessages(size(selectedRows)),
        handler:
            ({ selectedRows, accountName, forceFetchReport, reportPage, setReportPage }) =>
            async (dispatch, getState) => {
                const { reportTotalRowsCount } = campaignsStateSelector(getState());
                await dispatch(deleteCreatives(accountName, keys(selectedRows)));
                const lastReportPage = Math.ceil((reportTotalRowsCount - size(selectedRows)) / PAGE_SIZE);
                const newReportPage = Math.min(reportPage, lastReportPage);
                if (newReportPage !== 1 && newReportPage !== reportPage) {
                    setReportPage(newReportPage, HISTORY_METHOD.REPLACE);
                    return;
                }
                forceFetchReport(Date.now());
            },
        enableCondition: ({ selectedRows = {} }) => !hasCarousel(selectedRows),
        getTooltip: ({ selectedRows = {} }) =>
            hasCarousel(selectedRows) ? (
                <FormattedMessage
                    id="creative.bulk.button.disabled.tooltip.carousel"
                    defaultMessage="At least one carousel card selected, action is unavailable."
                />
            ) : null,
    },
    {
        key: BULK_ACTION.RESUBMIT,
        handler:
            ({ selectedRows, accountName }) =>
            dispatch => {
                const creativeIds = keys(selectedRows);
                sendGtmResubmitEvent(creativeIds.length);
                dispatch(bulkResubmitCreatives({ creativeIds, accountId: accountName }));
            },
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVE_RESUBMISSION_ENABLED]: 'true',
            [FEATURE_FLAGS.SUBMISSION_DATA_ALLOWED_IN_ADS_REPORT]: 'true',
        },
        getPrompt: () => resubmitModalPrompt,
        enableCondition: ({ selectedRows, commonConfig }) =>
            !getResubmitDisabledTooltip(selectedRows, commonConfig)?.disabled,
        getTooltip: ({ selectedRows, commonConfig }) => getResubmitDisabledTooltip(selectedRows, commonConfig)?.message,
    },
];
