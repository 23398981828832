import { map } from 'lodash';
import { SPECIFIC_CAMPAIGN_GROUPS } from 'modules/scheduled-reports/components/ScheduledReportsCampaignGroupsListTypeFiled/scheduledReportsCampaignGroupsListTypes';
import { SPECIFIC_CAMPAIGNS } from 'modules/scheduled-reports/components/ScheduledReportsCampaignsListFiled/scheduledReportsCampaignListTypes';
import {
    dimensionEntityTypeMap,
    ENTITY_TYPES,
} from 'modules/scheduled-reports/components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';

const getEntityIds = (scheduleReport, entityType) => {
    switch (entityType) {
        case ENTITY_TYPES.CAMPAIGN:
            return scheduleReport.campaignSelectType === SPECIFIC_CAMPAIGNS ? map(scheduleReport.campaigns, 'id') : [];
        case ENTITY_TYPES.CAMPAIGNS_GROUP_ID:
            return scheduleReport.campaignGroupsSelectType === SPECIFIC_CAMPAIGN_GROUPS
                ? map(scheduleReport.campaignGroupList, 'id')
                : [];
        case ENTITY_TYPES.CUSTOM_RULE:
            return map(scheduleReport.performanceRuleIds);
        default:
            return [];
    }
};

export const transformScheduledReportToGw = scheduleReport => {
    const sentTo = map(scheduleReport.sentTo, 'email');
    const entityType = dimensionEntityTypeMap[scheduleReport.dimension];
    const entityIds = getEntityIds(scheduleReport, entityType);

    return {
        ...scheduleReport,
        sentTo,
        entityIds,
        entityType,
    };
};
