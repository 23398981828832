import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { get, isEmpty } from 'lodash';
import { withFormDataProvider, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import FormInitBase from 'modules/taboola-common-frontend-modules/forms/components/FormInitBase/FormInitBase';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { FormButtons } from '../../components';
import { useCampaignsFormFieldValue } from '../../hooks';
import { creativeInitialValues } from '../common-campaign-form/config/creativeInitialValues';
import { VideoCreativeContentSection } from '../video-campaigns-form/components/VideoCreativeContentSection/VideoCreativeContentSection';
import { VideoCreativeCreatorPreview } from '../video-campaigns-form/components/VideoCreativeCreatorPreview/VideoCreativeCreatorPreview';
import { VideoCreativePixelManagementSection } from '../video-campaigns-form/components/VideoCreativePixelManagementSection/VideoCreativePixelManagementSection';
import { VideoCreativeSetupSection } from '../video-campaigns-form/components/VideoCreativeSetupSection/VideoCreativeSetupSection.new';
import { VideoLogoSection } from '../video-campaigns-form/components/VideoLogoSection/VideoLogoSection';
import { VideoMediaFileUploadSection } from '../video-campaigns-form/components/VideoMediaFileUploadSection/VideoMediaFileUploadSection';
import VideoCreativeBreadcrumbs from './VideoCreativeBreadcrumbs';
import { VIDEO_CREATIVE_CREATION_METHOD } from './config/CreationMethod';
import useVideoCreativeCreatorState from './hooks/useVideoCreativeCreatorState';
import styleConsts from '../../../../globalStyle/styleConsts.module.scss';
import styles from './videoCreativeCreator.new.module.scss';

const classNameBuilder = classnames.bind(styles);
const creationMethodContentComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoCreativeContentSection,
};

const creationMethodMediaComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoMediaFileUploadSection,
};

const creationMethodLogoComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoLogoSection,
};

const EmptyComponent = () => null;
const dimension = CAMPAIGN_DIMENSION.VIDEO;

const VideoCreativeCreator = ({ onCancel }) => {
    const containerTemplateColumns = `calc(${styleConsts.leftPaneWidth}% + ${styleConsts.collapsibleFormColumn}) 1fr`;
    const gridTemplateRows = `min-content ${styleConsts.creativeCreatorTopMiddleRowWidth} ${styleConsts.creativeCreatorBottomRowWidth}`;
    const creativeContainerRef = React.createRef();

    const { value: campaign } = useCampaignsFormFieldValue();
    const campaignId = get(campaign, [0, 'id']);

    const { value: videoCreationType } = useFormFieldValue({
        field: 'mediaType',
    });

    const { value: vastUrl } = useFormFieldValue({
        field: 'vastTagUrl',
    });

    const { submit } = useVideoCreativeCreatorState({
        dimension,
        campaignId,
    });

    const { value: isSubtitlesUploading } = useFormFieldValue({
        field: 'isSubtitlesUploading',
    });

    const ContentComponent = creationMethodContentComponentMap[videoCreationType] || EmptyComponent;
    const MediaUploadComponent = creationMethodMediaComponentMap[videoCreationType] || EmptyComponent;
    const LogoComponent = creationMethodLogoComponentMap[videoCreationType] || EmptyComponent;

    const disableSubmit = useMemo(() => {
        if (videoCreationType === VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO) {
            return isSubtitlesUploading;
        }
        return isEmpty(vastUrl?.trim());
    }, [vastUrl, videoCreationType, isSubtitlesUploading]);

    return (
        <FormInitBase>
            <div className={styles['form']}>
                <div
                    className={classNameBuilder('container')}
                    style={{
                        gridTemplateColumns: containerTemplateColumns,
                        gridTemplateRows,
                    }}
                >
                    <div className={classNameBuilder('section-container')}>
                        <VideoCreativeBreadcrumbs
                            className={styles['breadcrumbs-container']}
                            containerClass={classNameBuilder('breadcrumbs-content-container')}
                        />
                        <div className={classNameBuilder('form-container', 'overflow')} ref={creativeContainerRef}>
                            <VideoCreativeSetupSection />
                            <ContentComponent />
                            <MediaUploadComponent />
                            <LogoComponent />
                            <VideoCreativePixelManagementSection />
                        </div>
                    </div>
                    <VideoCreativeCreatorPreview />
                    <FormButtons
                        className={classNameBuilder('form-content', 'buttons')}
                        onSubmit={submit}
                        onCancel={onCancel}
                        submitLabel={<FormattedMessage id="app.actionButtons.submit" defaultMessage="Submit" />}
                        cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                        submitDataMetricAttrs={{
                            'data-metrics-component': 'SubmitButton',
                        }}
                        disableSubmit={disableSubmit}
                        submitButtonType="submit"
                    />
                </div>
            </div>
        </FormInitBase>
    );
};

const VideoCreativeCreatorWithFormDataProvider = withFormDataProvider(VideoCreativeCreator, {
    defaultValuesConfig: creativeInitialValues,
    skipInitFormAccount: true,
});

export {
    VideoCreativeCreatorWithFormDataProvider as VideoCreativeCreator,
    VideoCreativeCreator as VideoCreativeCreatorUnwrapped,
};
