import React from 'react';
import PropTypes from 'prop-types';
import { BudgetIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CommonCollapsibleCard from '../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';

const BudgetSectionFrame = ({ children }) => (
    <CommonCollapsibleCard
        id="CAMPAIGN_BUDGET_BIDDING"
        header={
            <SectionHeader
                headerIcon={<BudgetIcon />}
                headerText={<FormattedMessage id="campaign.editor.budget.and.bidding" />}
            />
        }
    >
        {children}
    </CommonCollapsibleCard>
);

BudgetSectionFrame.propTypes = {
    children: PropTypes.node,
};

export default BudgetSectionFrame;
