import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, groupBy, isEmpty, isNil } from 'lodash';
import memoize from 'memoize-one';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { useCurrentValueGetter } from '../../../../../../../hooks';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../../../../../taboola-common-frontend-modules/modals';
import { getVideoData } from '../../../../../../taboola-common-frontend-modules/rbox-loader';
import { immutableMergeWith } from '../../../../../../taboola-common-frontend-modules/utils/customMergers';
import { useCampaignsFormFieldValue } from '../../../../../hooks';
import { CreativePreviewMultiItem } from '../../../../creative-preview';
import { useFormatTypeFormFieldValueCreateMode } from '../../../hooks/useFormatTypeFormFieldValueCreateMode';
import { shouldShowRemoveVariationModalSelector } from '../../../selectors/selectors';
import creativePreviewGenerator from '../../../services/creativePreviewGenerator';
import { getCleanContent } from '../../../services/utils';
import { useAppData } from '../../AdSetupSection/useAppData';
import { LOGO_FIELD_ID } from '../../LogoField/logoConsts';
import { RemoveVariationModal } from '../../index';
import styles from '../../../creativeCreator.module.scss';

const hasAtLeastOneUrl = urls => {
    return urls.some(({ value }) => value !== '');
};

export const VariationsPreviewSection = ({ creativePreviewsFieldName, formContentFieldName }) => {
    const { value: creativePreviews, onChange: setCreativePreviews } = useFormFieldValue({
        field: creativePreviewsFieldName,
    });
    const { value: creativeCreatorSelectedCampaigns } = useCampaignsFormFieldValue();
    const { value: formContent } = useFormFieldValue({ field: formContentFieldName });
    const { value: urls } = useFormFieldValue({ field: 'urls' });
    const { value: thumbnails } = useFormFieldValue({ field: 'thumbnails' });
    const { value: creativeName } = useFormFieldValue({ field: 'creativeName' });
    const { value: customId } = useFormFieldValue({ field: 'customId' });
    const { value: creativeLogo } = useFormFieldValue({ field: LOGO_FIELD_ID });
    const { open: openModal } = useModal();
    const shouldShowRemoveVariationModal = useSelector(shouldShowRemoveVariationModalSelector);
    const isRecommendedCtaEnabled = useConfigMatch({
        [FEATURE_FLAGS.DEFAULT_CTA_ENABLED]: 'true',
    });
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();

    const creativePreviewsGetter = useCurrentValueGetter(creativePreviews);

    const brandingText = get(creativeCreatorSelectedCampaigns, '0.brandingText', '');
    const language = get(creativeCreatorSelectedCampaigns, '0.campaignProfile.language', 'EN');
    const { data: appData } = useAppData();

    const getUrlsForNativePreview = memoize(creativePreviews => {
        return Object.keys(creativePreviews);
    });

    const content = useMemo(
        () => formContent.filter(({ status }) => status === COMPONENT_STATUS.ACTIVE),
        [formContent]
    );

    useEffect(() => {
        if (!hasAtLeastOneUrl(urls) || isEmpty(content) || isEmpty(thumbnails)) {
            return;
        }
        const newPreviews = creativePreviewGenerator({
            urls,
            content: getCleanContent(content, isRecommendedCtaEnabled),
            thumbnails,
            creativePreviewsGetter,
            appData,
            formatType,
            creativeName,
            customId,
            creativeLogo,
        });

        setCreativePreviews(newPreviews);
    }, [
        urls,
        content,
        thumbnails,
        setCreativePreviews,
        creativePreviewsGetter,
        isRecommendedCtaEnabled,
        appData,
        formatType,
        creativeName,
        customId,
        creativeLogo,
    ]);

    const getItemsForNativePreview = memoize((creativePreviews, brandingText, language) => {
        const itemsPerUrl = Object.values(creativePreviews);
        const itemsArrPerUrl = itemsPerUrl.map(urlItems => Object.values(urlItems));
        const sortedItemsArrPerUrl = itemsArrPerUrl
            .map(urlItemsArr => Object.values(groupBy(urlItemsArr, 'thumbnailUrl')))
            .flat();

        return sortedItemsArrPerUrl.flat().map(item => ({ ...item, brandingText, language }));
    });

    const handleExcludeButtonClick = async e => {
        const { placementUrl: url, uploader: id } = getVideoData(e);
        const callback = () => toggleCreativePreviewStatus(url, id);

        e.preventDefault();

        if (!shouldShowRemoveVariationModal) {
            callback();
            return;
        }

        const result = await openModal({
            title: (
                <FormattedMessage
                    id="app.modal.removeVariation.title"
                    tagName="h2"
                    defaultMessage="Remove variation from all placements?"
                />
            ),
            contentRenderer: RemoveVariationModal,
        });

        if (result) {
            callback();
        }
    };

    const toggleCreativePreviewStatus = (url, id) =>
        setCreativePreviews(prevValue => {
            const deleted = get(prevValue, [url, id, 'deleted']);
            if (isNil(deleted)) {
                return;
            }

            const result = immutableMergeWith(prevValue, { [url]: { [id]: { deleted: !deleted } } });

            return result;
        });

    return (
        <CreativePreviewMultiItem
            formLayoutProps={{
                className: styles['preview-wrapper'],
                headerContainerClassName: styles['preview-header'],
                contentContainerClassName: styles['preview-container'],
            }}
            items={getItemsForNativePreview(creativePreviews, brandingText, language)}
            urls={getUrlsForNativePreview(creativePreviews)}
            onExcludeButtonClick={handleExcludeButtonClick}
            id="creative-creator-native-preview"
        />
    );
};

VariationsPreviewSection.defaultProps = {
    creativePreviewsFieldName: 'creativePreviews',
    formContentFieldName: 'content',
};
