import React from 'react';
import { map, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Button, AddIcon } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './contentAiSuggestionsResultsSection.module.scss';

export const ContentAiSuggestionsResultsSection = ({ header, gaWord, campaignId, results, onItemClick }) => {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>{header}</div>
            {map(results, (result, index) => (
                <Button
                    onClick={() => onItemClick(result)}
                    key={index}
                    variant={Button.variant.text}
                    className={styles['item-tag']}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component={`AIContent${gaWord}Suggestions - ${gaWord} selected`}
                    data-metrics-value={`${gaWord}: ${result}`}
                    data-metrics-taboola-campaign-id={campaignId}
                >
                    {result}
                    <AddIcon className={styles['plus-icon']} />
                </Button>
            ))}
        </div>
    );
};

ContentAiSuggestionsResultsSection.propTypes = {
    header: PropTypes.node,
    gaWord: PropTypes.string,
    campaignId: PropTypes.number,
    results: PropTypes.array,
    onItemClick: PropTypes.func,
};

ContentAiSuggestionsResultsSection.defaultProps = {
    campaignId: -1,
    results: [],
    onItemClick: noop,
};
