import React from 'react';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './AddCombinedAudiencesWithoutBundlingTargetingButton.module.scss';

export const AddCombinedAudiencesWithoutBundlingTargetingButton = ({ onClick }) => {
    return (
        <Button className={styles['button']} onClick={onClick} variant={Button.variant.outline}>
            <FormattedMessage
                defaultMessage="Narrow Your Segment (And)"
                id="audience.creator.combined.audiences.targeting.button"
            />
        </Button>
    );
};
