import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityType } from 'hooks/index';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useCampaignGroupApi } from '../../../../../services/api';
import { useMultiLayerPathParams } from '../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { CAMPAIGNS_ROUTE, PATH_TO_ENTITY_MAP } from '../../../config';
import { fetchCampaignsGroupForEdit, updateCampaignGroup } from '../flows';
import { useCampaignGroupExtensionEnabled } from './useCampaignGroupExtensionEnabled';

export const useCampaignsGroupEditorState = () => {
    const dispatch = useDispatch();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const { campaignsGroupId } = useMultiLayerPathParams();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const campaignGroupApi = useCampaignGroupApi();
    const api = campaignGroupExtensionEnabled ? campaignGroupApi : undefined;

    const formDataFetcher = useCallback(
        () => dispatch(fetchCampaignsGroupForEdit(accountId, campaignsGroupId, entityType, api)),
        [accountId, campaignsGroupId, dispatch, entityType, api]
    );
    const { submit } = useFormState({ formDataFetcher });

    const handleCampaignsGroupSubmitWrapper = useCallback(() => {
        const saveCampaignsGroupWrapper = campaignsGroup =>
            dispatch(updateCampaignGroup(accountId, campaignsGroup, api));

        submit(saveCampaignsGroupWrapper, CAMPAIGNS_ROUTE);
    }, [accountId, dispatch, submit, api]);

    return { submit: handleCampaignsGroupSubmitWrapper };
};
