'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _reactDates = require('react-dates');

require('react-dates/initialize');

var _DatePickerNavigationIcons = require('./DatePickerNavigationIcons');

var _defaultPhrases = require('./defaultPhrases');

var _DatePickerDay = require('./DatePickerDay');

var _DatePickerDay2 = _interopRequireDefault(_DatePickerDay);

var _Indications = require('../../Indications');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DatePicker = function (_Component) {
    _inherits(DatePicker, _Component);

    function DatePicker() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, DatePicker);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = DatePicker.__proto__ || Object.getPrototypeOf(DatePicker)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            focused: false
        }, _this.handleFocusChange = function (_ref2) {
            var focused = _ref2.focused,
                fromWrapper = _ref2.fromWrapper;
            var _this$props = _this.props,
                onFocusChange = _this$props.onFocusChange,
                disabled = _this$props.disabled;


            if (!disabled && (fromWrapper || !focused)) {
                _this.setState({ focused: focused }, function () {
                    return onFocusChange(focused);
                });
            }
        }, _this.handleInputChange = function (e) {
            var onInputChange = _this.props.onInputChange;


            onInputChange(e.target.value);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(DatePicker, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                disabled = _props.disabled,
                onInputChange = _props.onInputChange,
                propFocused = _props.focused,
                indicationType = _props.indicationType,
                renderWeekHeaderElement = _props.renderWeekHeaderElement,
                rest = _objectWithoutProperties(_props, ['disabled', 'onInputChange', 'focused', 'indicationType', 'renderWeekHeaderElement']);

            var stateFocused = this.state.focused;

            var focused = propFocused !== undefined ? propFocused : stateFocused;
            return _react2.default.createElement(
                'div',
                {
                    className: focused ? 'DatePickerContainer focused' : 'DatePickerContainer',
                    onClick: function onClick() {
                        return _this2.handleFocusChange({ focused: !focused, fromWrapper: true });
                    },
                    onChange: this.handleInputChange,
                    role: 'button',
                    tabIndex: 0,
                    disabled: disabled
                },
                _react2.default.createElement(_reactDates.SingleDatePicker, Object.assign({}, rest, {
                    focused: focused && !disabled,
                    disabled: disabled,
                    onFocusChange: this.handleFocusChange,
                    phrases: _defaultPhrases.SingleDatePickerPhrases,
                    renderWeekHeaderElement: renderWeekHeaderElement,
                    weekDayFormat: 'ddd'
                }))
            );
        }
    }]);

    return DatePicker;
}(_react.Component);

DatePicker.propTypes = {
    /** Current input date */
    date: _reactMomentProptypes2.default.momentObj,
    /** Opens the calendar */
    focused: _propTypes2.default.bool,
    /** Fires when input text changed */
    onInputChange: _propTypes2.default.func,
    /** Fires when input gains/loses focus */
    onFocusChange: _propTypes2.default.func,
    /** Fires when a new date is selected */
    onDateChange: _propTypes2.default.func.isRequired,
    /** Text that appears when no date is selected */
    placeholder: _propTypes2.default.string,
    /** Disables input */
    disabled: _propTypes2.default.bool,
    /** Turns input to a required field in a form */
    required: _propTypes2.default.bool,
    /** Disables the option to choose a new date */
    readOnly: _propTypes2.default.bool,
    /** A message to be passed to screen readers for accessibility */
    screenReaderInputMessage: _propTypes2.default.string,
    /** Adds a button to clear the chosen date  */
    showClearDate: _propTypes2.default.bool,
    /** Custom icon for the "Clear Date" button */
    customCloseIcon: _propTypes2.default.node,
    /** Custom node for the input button */
    customInputIcon: _propTypes2.default.node,
    /** Eliminate border */
    noBorder: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES)),

    // calendar presentation and interaction related props
    /** Id of the input element */
    id: _propTypes2.default.string,
    /** Custom rendering of the month */
    renderMonth: _propTypes2.default.func,
    /** Specify horizontal margin */
    horizontalMargin: _propTypes2.default.number,
    /** Specify the vertical spacing */
    verticalSpacing: _propTypes2.default.number,
    /** Specify the first visible month */
    initialVisibleMonth: _propTypes2.default.func,
    /** Specify the first visible day of the week */
    firstDayOfWeek: _propTypes2.default.oneOf([0, 1, 2, 3, 4, 5, 6]),
    /** Specify the number of months */
    numberOfMonths: _propTypes2.default.number,
    /** Keep calendar open when date is selected */
    keepOpenOnDateSelect: _propTypes2.default.bool,
    /** Open calendar when date is cleared */
    reopenPickerOnClearDate: _propTypes2.default.bool,
    /** Add info to calendar */
    renderCalendarInfo: _propTypes2.default.func,
    /** Hide/show keyboard shortcuts panel */
    hideKeyboardShortcutsPanel: _propTypes2.default.bool,
    /** Control the size of the day in pixels */
    daySize: _propTypes2.default.number,
    /** Control direction */
    isRTL: _propTypes2.default.bool,
    /** Change the visibility of the previous-month button */
    navPrev: _propTypes2.default.object,
    /** Change the visibility of the next-month button */
    navNext: _propTypes2.default.object,
    /** Show the default icon next to the input */
    showDefaultInputIcon: _propTypes2.default.bool,
    /** Input will get a "display: block" property */
    block: _propTypes2.default.bool,
    /** Small Calendar */
    small: _propTypes2.default.bool,

    // navigation related props
    /** Fires when navigating to the previous month */
    onPrevMonthClick: _propTypes2.default.func,
    /** Fires when navigating to the next month */
    onNextMonthClick: _propTypes2.default.func,
    /** Fires when calendar closes */
    onClose: _propTypes2.default.func,
    /** In milliseconds, sets the delay before the calendar opens */
    transitionDuration: _propTypes2.default.number,

    // day presentation and interaction related props
    renderCalendarDay: _propTypes2.default.func,
    /** Enables the selection of out-of-range dates */
    enableOutsideDays: _propTypes2.default.bool,
    /** Determines which dates cannot be selected */
    isDayBlocked: _propTypes2.default.func,
    /** Determines which dates are out of the range of selection */
    isOutsideRange: _propTypes2.default.func,
    /** Determines which dates highlighted */
    isDayHighlighted: _propTypes2.default.func,
    /** Change the visibility of a day */
    renderDayContents: _propTypes2.default.func,
    /** Change labels in week header */
    renderWeekHeaderElement: _propTypes2.default.func,

    // internationalization props
    /** Intl - formats the display of the selected date */
    displayFormat: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /** Intl - format of month (MomentJS formats) */
    monthFormat: _propTypes2.default.string
};

DatePicker.defaultProps = {
    id: 'date-input',
    displayFormat: 'DD/MM/YY',
    numberOfMonths: 1,
    customInputIcon: _react2.default.createElement(
        'span',
        null,
        'START'
    ),
    daySize: 38,
    hideKeyboardShortcutsPanel: true,
    verticalSpacing: 10,
    monthFormat: 'MMMM YYYY',
    navPrev: _DatePickerNavigationIcons.NavLeftIconSmall,
    navNext: _DatePickerNavigationIcons.NavRightIconSmall,
    showDefaultInputIcon: false,
    block: false,
    small: false,
    renderDayContents: function renderDayContents(day) {
        return (0, _DatePickerDay2.default)({ day: day });
    },
    renderWeekHeaderElement: function renderWeekHeaderElement(day) {
        return day.toUpperCase();
    },
    onFocusChange: function onFocusChange() {},
    onInputChange: function onInputChange() {}
};

exports.default = DatePicker;