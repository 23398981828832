import { isEmpty } from 'lodash';
import { MINIMUM_ADS, SUB_STEP_DATA } from '../../../consts';

const { HAS_DESKTOP_OR_MOBILE_CAMPAIGNS, HAS_MINIMUM_ADS_PER_CAMPAIGN } = SUB_STEP_DATA;

const getHasDesktopOrMobileCampaigns = desktopOrMobileCampaigns => {
    return desktopOrMobileCampaigns.length > 0;
};

const getHasMinimumCreativesPerCampaign = desktopOrMobileCampaigns => {
    return isEmpty(desktopOrMobileCampaigns)
        ? false
        : desktopOrMobileCampaigns.every(campaign => {
              const { creatives } = campaign;
              return creatives.length >= MINIMUM_ADS;
          });
};

export const getCreateCampaignData = desktopOrMobileCampaigns => {
    const hasDesktopOrMobileCampaigns = getHasDesktopOrMobileCampaigns(desktopOrMobileCampaigns);
    const hasMinimumCreativesPerCampaign = getHasMinimumCreativesPerCampaign(desktopOrMobileCampaigns);

    return {
        [HAS_DESKTOP_OR_MOBILE_CAMPAIGNS]: hasDesktopOrMobileCampaigns,
        [HAS_MINIMUM_ADS_PER_CAMPAIGN]: hasMinimumCreativesPerCampaign,
    };
};
