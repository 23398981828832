import React from 'react';
import { ExternalLink } from '../../../../../../components';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const guidelinesLink = (
    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/115005944828-Campaign-Branding-Text">
        <FormattedMessage id="campaign.branding.help.link" defaultMessage="Branding Text Guidelines" />
    </ExternalLink>
);

const BrandingTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.branding.help.definition"
            defaultMessage='The <i>name</i> of your product, brand or website (without "www" or "http").'
        />
        <FormattedMessage
            id="campaign.branding.help.placement"
            defaultMessage="This name will appear in your ad, below the title."
        />
        <FormattedMessage
            id="campaign.branding.help.link.prefix"
            defaultMessage="Read our {guidelinesLink}."
            values={{ guidelinesLink }}
        />
    </TooltipSection>
);

export default BrandingTooltip;
