import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';

export const useIsApplyCapActiveField = () => {
    const isCpcCapEnabled = useConfigMatch(
        { [FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER]: 'true' },
        {
            [COMMON_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED]: 'true',
        }
    );
    return useFormFieldValue({ field: 'isApplyCapActive', isPermitted: isCpcCapEnabled });
};
