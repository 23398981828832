"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSetup(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M19 17a3 3 0 01-3 3H8a3 3 0 01-3-3V7a3 3 0 013-3h5l6 6v7zM7 17V7a1 1 0 011-1h3v3a3 3 0 003 3h3v5a1 1 0 01-1 1H8a1 1 0 01-1-1zm9.172-7L13 6.828V9a1 1 0 001 1h2.172z",
            fill: "#212832"
        })
    );
}
exports.default = SvgSetup;