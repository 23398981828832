import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { AlertIcon, HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './cvrVsAvgCellRenderer.module.scss';

const classNameBuilder = classNames.bind(styles);

const MAX_BAR_VALUE = 300;
const HIGH_CONFIDENCE = 3;

const CvrVsAvgCellRenderer = ({ data: { cvrUplift, cvrUpliftConfidence } }) => {
    if (cvrUplift == null || cvrUpliftConfidence == null || cvrUplift < 1) {
        return <span />;
    }

    const cvrUpliftPercent = Math.round((cvrUplift - 1) * 100);
    const formattedCvrUplift = `+${cvrUpliftPercent}%`;
    const barValue = Math.min(cvrUpliftPercent, MAX_BAR_VALUE);
    const isHighTrust = cvrUpliftConfidence === HIGH_CONFIDENCE;
    const progressBarColor = isHighTrust ? 'high-trust-progress' : 'low-trust-progress';
    const progressBarClassName = classNameBuilder('progress-bar', progressBarColor);

    return (
        <div className={styles['container']}>
            <div className={styles['bar-and-value-container']}>
                <div className={styles['uplift-value']}>{formattedCvrUplift}</div>
                <div className={styles['progress-bar-container']}>
                    <progress className={progressBarClassName} value={barValue} max={MAX_BAR_VALUE} />
                </div>
            </div>
            <div className={styles['low-trust-icon-container']}>
                {!isHighTrust && (
                    <HelpTooltip
                        className={styles['help-tooltip-min-width']}
                        arrow={false}
                        position={TOOLTIP_POSITION.BOTTOM_END}
                        offset={10}
                        IconComponent={AlertIcon}
                        iconClassName={styles['alert-icon']}
                    >
                        <div className={styles['tooltip-content']}>
                            <AlertIcon className={styles['alert-icon-tooltip']} />
                            <FormattedMessage
                                id="cvrVsAvg.lowTrust.tooltip"
                                defaultMessage="Confidence Level: <strong>Low</strong>"
                            />
                        </div>
                    </HelpTooltip>
                )}
            </div>
        </div>
    );
};

CvrVsAvgCellRenderer.propTypes = {
    data: PropTypes.object,
};

export default CvrVsAvgCellRenderer;
