import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './schedulerDateTimeHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SchedulerDateTimeHeader = ({ header }) => {
    return (
        <thead>
            <tr className={styles['header']}>
                <th className={classNameBuilder(styles['column-header'], styles['date-header'])}>{header}</th>
                <th className={classNameBuilder(styles['column-header'], styles['hour-header'])}>
                    <FormattedMessage id="creative.editor.schedule.day.time.header.hour" defaultMessage="Hour" />
                </th>
                <th className={classNameBuilder(styles['column-header'], styles['minute-header'])}>
                    <FormattedMessage id="creative.editor.schedule.day.time.header.minute" defaultMessage="Minute" />
                </th>
            </tr>
        </thead>
    );
};
