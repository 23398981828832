import React from 'react';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { AUDIENCE_TYPE } from '../../config';
import PixelBasedAudienceEventsSection from '../PixelBasedAudienceEventsSection/PixelBasedAudienceEventsSection';
import PixelBasedAudienceUrlConditionsSection from '../PixelBasedAudienceUrlConditionsSection/PixelBasedAudienceUrlConditionsSection';

export const PixelBasedAudienceSetupDetailsSection = () => {
    const { value: type } = useFormFieldValue({
        field: 'type',
    });

    return type === AUDIENCE_TYPE.BASIC ? (
        <PixelBasedAudienceUrlConditionsSection />
    ) : (
        <PixelBasedAudienceEventsSection />
    );
};

export default PixelBasedAudienceSetupDetailsSection;
