import React from 'react';

export const ImageDropSVGIcon = (props = {}) => (
    <svg width="68" height="48" viewBox="0 0 68 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 1H62C64.7614 1 67 3.23858 67 6V42C67 44.7614 64.7614 47 62 47H6C3.23858 47 1 44.7614 1 42V6C1 3.23858 3.23858 1 6 1Z"
            stroke="#9CA8B5"
            strokeWidth="2"
        />
        <rect x="4" y="4" width="60" height="40" rx="2" fill="#EAECEF" />
        <path
            d="M36.6666 18L31.6666 24.6667L35.4666 29.7333L33.3333 31.3333C31.0799 28.3333 27.3333 23.3333 27.3333 23.3333L19.3333 34H48.6666L36.6666 18Z"
            fill="#9CA8B5"
        />
        <path
            d="M29.9999 16.5C29.9999 17.8807 28.8806 19 27.4999 19C26.1192 19 24.9999 17.8807 24.9999 16.5C24.9999 15.1193 26.1192 14 27.4999 14C28.8806 14 29.9999 15.1193 29.9999 16.5Z"
            fill="#9CA8B5"
        />
    </svg>
);
