const THUMBNAIL_SOURCE_TYPES = {
    FILE: 'FILE',
    URL_TA: 'URL_TA',
    RECOMMENDED_MEDIA: 'RECOMMENDED_MEDIA',
    VIDEO: 'VIDEO',
    MEDIA_LIBRARY: 'MEDIA_LIBRARY',
    WOCHIT: 'WOCHIT',
    URL_BULK_UPLOAD: 'URL_BULK_UPLOAD',
    STABLE_DIFFUSION: 'STABLE_DIFFUSION',
    STABLE_DIFFUSION_BACKGROUND: 'STABLE_DIFFUSION_BACKGROUND',
    FAVORITED_IMAGES: 'FAVORITED_IMAGES',
};

export default THUMBNAIL_SOURCE_TYPES;
