import React from 'react';
import { get } from 'lodash';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import PaginatedGalleryItem from '../PaginatedGallery/PaginatedGalleryItem';

export const RecentlyUsedMediaTabItem = ({ itemIndex, ...props }) => {
    const { items } = useMediaTabsContext();
    const item = items[itemIndex];
    const ItemComponent = get(item, 'component', false);

    if (ItemComponent) {
        return <ItemComponent {...props} />;
    }

    return <PaginatedGalleryItem itemIndex={itemIndex} {...props} />;
};
