import React from 'react';
import PropTypes from 'prop-types';
import { AlertIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import recommendationsApi from 'services/api/recommendationsApi';
import { useCampaignRecommendation } from '../../hooks/useCampaignRecommendation';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const LowPerformingSingleItemRecommendation = ({
    itemId,
    recommendation,
    removeRecommendationIndication,
    applyRecommendationIndication,
}) => {
    const { accountId, campaignId } = useCampaignRecommendation({ recommendation });
    const { actions, id: recommendationId, messageParams } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const dismissItemRecommendation = recommendationsApi.dismissItemPerformanceRecommendation;
    const applyItemRecommendation = recommendationsApi.executeItemRecommendationAction;
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        markAsApplied: false,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId,
            itemId,
        },
        dismissParams: {
            accountId,
            campaignId,
            recommendationId,
            itemId,
        },
        onApply: applyItemRecommendation,
        onApplySuccess: applyRecommendationIndication,
        onDismiss: dismissItemRecommendation,
        onDismissSuccess: removeRecommendationIndication,
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageParams}
            titleCode="performance-recommendations.low-performing-single-item.title"
            descriptionCode="performance-recommendations.low-performing-single-item.description"
            isApplied={apply.isSuccess}
            isRenderedInReportCell
            iconImg={AlertIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode="performance-recommendations.low-performing-single-item.action"
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode="performance-recommendations.low-performing-single-item.action.tooltip"
                    onClick={apply.invoke}
                    isItemRec={true}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

LowPerformingSingleItemRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
