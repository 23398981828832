import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { mergeQueryParamsWithLocation } from '../../../../taboola-common-frontend-modules/query-params';
import { CREATIVE_TYPE, FORM_MODES } from '../../../config';
import { CAMPAIGN_ID } from '../../../hooks';
import { selectedRowsSelector } from '../../../selectors';
import { resetSelectedRows } from '../../campaigns-reports/actions';
import { useBulkEditCreativesSharedValues } from '../../campaigns-reports/components/BulkActionToolbar/hooks/useBulkEditCreativesSharedValues';
import { REPORT_ID } from '../../campaigns-reports/hooks';
import { REPORT_FORCE_FETCH_FLAG } from '../../campaigns-reports/hooks/useReportForceFetchFlag';
import { bulkDuplicateCreatives } from '../../creative-bulk-duplicator/flows/bulkDuplicateCreatives';
import { BYPASS_URL_RESPONSE_VALIDATION_FIELD } from '../../creative-creator/components/UrlList/UrlValidatedInput';
import { useInitialCreativeType } from '../../creative-editor/hooks/useInitialCreativeType';
import { updateMotionCreatives } from '../flows/updateMotionCreatives';
import { updateThumbnailCreatives } from '../flows/updateThumbnailCreatives';
import { calculateBulkChanges } from '../utils/calculateBulkChanges';
import useCreativesIdsBeingEdited from './useCreativesIdsBeingEdited';

const useImageBulkEditHandler =
    ({ accountName: accountId, data, dispatch, creativeIds }) =>
    async selectedFieldsMatrix => {
        const changes = calculateBulkChanges(selectedFieldsMatrix, data, CREATIVE_TYPE.IMAGE);
        if (isEmpty(changes)) {
            return true;
        }
        const result = await dispatch(updateThumbnailCreatives({ accountId, creativeIds, changes }));
        if (!result?.error) {
            dispatch(resetSelectedRows());
            return result;
        }
    };

const useCreativesBulkDuplicateHandler =
    ({ accountName: accountId, data, dispatch, selectedRows }) =>
    async () => {
        const { selectedCampaigns } = data;
        if (isEmpty(selectedCampaigns)) {
            return true;
        }
        const result = await dispatch(
            bulkDuplicateCreatives({
                accountId,
                selectedCampaigns: selectedCampaigns.map(({ value }) => ({ id: value })),
                selectedCreativeRows: selectedRows,
            })
        );
        if (!result?.error) {
            return result;
        }
    };

const useMotionAdBulkEditHandler =
    ({ accountName: accountId, data, dispatch, creativeIds }) =>
    async selectedFieldsMatrix => {
        const changes = calculateBulkChanges(selectedFieldsMatrix, data, CREATIVE_TYPE.MOTION);
        if (isEmpty(changes)) {
            return true;
        }
        const result = await dispatch(updateMotionCreatives({ accountId, creativeIds, changes }));
        if (!result?.error) {
            dispatch(resetSelectedRows());
            return result;
        }
    };

export const useCreativeBulkEditorState = ({ sourcePath }) => {
    const dispatch = useDispatch();
    const [creativesSharedValues] = useBulkEditCreativesSharedValues();
    const [creativeType = CREATIVE_TYPE.IMAGE] = useInitialCreativeType();
    const fetchedFormData = useMemo(
        () => ({
            data: { ...creativesSharedValues, creativeType, [BYPASS_URL_RESPONSE_VALIDATION_FIELD]: false },
            isSuccess: true,
        }),
        [creativesSharedValues, creativeType]
    );
    const { submit } = useFormState({ fetchedFormData });
    const {
        formAccount: { accountName },
        data,
        mode,
    } = useFormDataContext();
    const creativeIds = useCreativesIdsBeingEdited();
    const selectedRows = useSelector(selectedRowsSelector);

    const submitHandlerEditModeMap = {
        [CREATIVE_TYPE.IMAGE]: useImageBulkEditHandler({ accountName, data, dispatch, creativeIds }),
        [CREATIVE_TYPE.MOTION]: useMotionAdBulkEditHandler({ accountName, data, dispatch, creativeIds }),
    };

    const submitHandlerDuplicateMode = useCreativesBulkDuplicateHandler({ accountName, data, dispatch, selectedRows });

    const successfulURL = useMemo(() => {
        if (mode === FORM_MODES.BULK_EDIT) {
            return sourcePath;
        }
        return ({ campaignId, reportId }) =>
            mergeQueryParamsWithLocation(
                { pathname: sourcePath },
                {
                    [CAMPAIGN_ID]: campaignId,
                    [REPORT_ID]: reportId,
                    //When we duplicate creatives to the same campaign, none of the other report params change so we must trigger the
                    // report fetch manually
                    [REPORT_FORCE_FETCH_FLAG]: Date.now(),
                }
            );
    }, [sourcePath, mode]);

    const submitHandler = async props => {
        const saveCreativeWrapper =
            mode === FORM_MODES.BULK_EDIT ? submitHandlerEditModeMap[creativeType] : submitHandlerDuplicateMode;
        await submit(() => saveCreativeWrapper(props), successfulURL);
    };

    return { submit: submitHandler };
};
