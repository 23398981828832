import React from 'react';
import styles from './buttonsSection.module.scss';

const variants = { CARD: 'card', RIBBON: 'ribbon' };
const ButtonsSection = ({ children, variant = variants.CARD }) => {
    return (
        <>
            <div className={styles['separator']} />
            <div className={styles[variant]}>{children}</div>
        </>
    );
};

ButtonsSection.variants = variants;

export default ButtonsSection;
