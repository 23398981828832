import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Input } from 'taboola-ultimate-ui';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import styles from './valueSection.module.scss';

export const ValueSection = ({ className, loading, loaderWidth, ariaLabel, isDisabled }) => {
    const { value: sectionValue, onChange } = useFormFieldValue({ field: 'value' });
    return (
        <div className={classnames(styles['label'], className)} aria-label={ariaLabel}>
            {loading ? (
                <ContentLoader speed={2} height={10} width={loaderWidth} className={styles['placeholder']} />
            ) : (
                <Input
                    inputWrapperClass={styles['input_field']}
                    value={sectionValue}
                    onChange={e => onChange(e.target.value)}
                    disabled={isDisabled}
                />
            )}
        </div>
    );
};

ValueSection.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    loading: PropTypes.bool,
    loaderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    testId: PropTypes.string,
    ariaLabel: PropTypes.string,
};

export default ValueSection;
