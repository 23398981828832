'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.COMPARE_DATE_FORMAT = exports.PRESET_LABELS = exports.PRESETS_LIST = exports.PRESETS = exports.SHORT_WEEKDAY_FORMAT = exports.FULL_WEEKDAY_FORMAT = exports.MONTH_YEAR_FORMAT = exports.DAY_OF_MONTH_FORMAT = exports.END_DATE = exports.START_DATE = undefined;

var _PRESET_LABELS;

var _lodash = require('lodash.omit');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var START_DATE = exports.START_DATE = 'startDate';
var END_DATE = exports.END_DATE = 'endDate';
var DAY_OF_MONTH_FORMAT = exports.DAY_OF_MONTH_FORMAT = 'DD';
var MONTH_YEAR_FORMAT = exports.MONTH_YEAR_FORMAT = 'MMM YYYY';
var FULL_WEEKDAY_FORMAT = exports.FULL_WEEKDAY_FORMAT = 'dddd';
var SHORT_WEEKDAY_FORMAT = exports.SHORT_WEEKDAY_FORMAT = 'ddd';

var PRESETS = exports.PRESETS = {
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    SEVEN_DAYS: 'SEVEN_DAYS',
    FOURTEEN_DAYS: 'FOURTEEN_DAYS',
    THIRTY_DAYS: 'THIRTY_DAYS',
    NINETY_DAYS: 'NINETY_DAYS',
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    THIS_QUARTER: 'THIS_QUARTER',
    LAST_QUARTER: 'LAST_QUARTER',
    THIS_YEAR: 'THIS_YEAR',
    CUSTOM: 'CUSTOM'
};

var PRESETS_LIST = exports.PRESETS_LIST = Object.values((0, _lodash2.default)(PRESETS, [PRESETS.CUSTOM]));

var YESTERDAY = PRESETS.YESTERDAY,
    TODAY = PRESETS.TODAY,
    SEVEN_DAYS = PRESETS.SEVEN_DAYS,
    FOURTEEN_DAYS = PRESETS.FOURTEEN_DAYS,
    THIRTY_DAYS = PRESETS.THIRTY_DAYS,
    NINETY_DAYS = PRESETS.NINETY_DAYS,
    LAST_MONTH = PRESETS.LAST_MONTH,
    THIS_MONTH = PRESETS.THIS_MONTH,
    THIS_YEAR = PRESETS.THIS_YEAR,
    THIS_QUARTER = PRESETS.THIS_QUARTER,
    LAST_QUARTER = PRESETS.LAST_QUARTER,
    CUSTOM = PRESETS.CUSTOM;
var PRESET_LABELS = exports.PRESET_LABELS = (_PRESET_LABELS = {}, _defineProperty(_PRESET_LABELS, YESTERDAY, 'Yesterday'), _defineProperty(_PRESET_LABELS, TODAY, 'Today'), _defineProperty(_PRESET_LABELS, SEVEN_DAYS, 'Last 7 Days'), _defineProperty(_PRESET_LABELS, FOURTEEN_DAYS, 'Last 14 Days'), _defineProperty(_PRESET_LABELS, THIRTY_DAYS, 'Last 30 Days'), _defineProperty(_PRESET_LABELS, NINETY_DAYS, 'Last 90 Days'), _defineProperty(_PRESET_LABELS, LAST_MONTH, 'Last Month'), _defineProperty(_PRESET_LABELS, THIS_MONTH, 'This Month'), _defineProperty(_PRESET_LABELS, THIS_YEAR, 'This Year'), _defineProperty(_PRESET_LABELS, THIS_QUARTER, 'This Quarter'), _defineProperty(_PRESET_LABELS, LAST_QUARTER, 'Last Quarter'), _defineProperty(_PRESET_LABELS, CUSTOM, 'Custom'), _PRESET_LABELS);

var COMPARE_DATE_FORMAT = exports.COMPARE_DATE_FORMAT = 'YYYYMMDDDD';