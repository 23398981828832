import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FunnelConversionsList } from './FunnelConversionsList';
import { FunnelConversionTooltip } from './FunnelConversionsTooltip';

export const FunnelConversions = () => (
    <FormField
        inputId="conversions-in-funnel"
        label={
            <FormattedMessage
                id="tracking.unip.funnel.setup.conversion.in.funnel.title"
                defaultMessage="Conversions in Funnel"
            />
        }
        description={
            <FormattedMessage
                id="tracking.unip.funnel.setup.conversion.in.funnel.description"
                defaultMessage="Sort conversions in a sequence from upper to lower funnel, with the lowest being the most important conversion contributing to your KPI."
            />
        }
        helpText={<FunnelConversionTooltip />}
    >
        <FunnelConversionsList />
    </FormField>
);
