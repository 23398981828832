import React from 'react';
import { useNavigate } from 'hooks';
import { generateCampaignCreatorPath, generateCreativeCreatorPath } from 'modules/campaigns/services/utils';
import { ENTITY } from '../../../../../../config';
import { MultiStepDescription } from '../../OnboardingStepDescription/MultiStepDescription';
import { STEP_IDS, SUB_STEP_IDS } from '../../consts';

const { CREATE_CAMPAIGN } = STEP_IDS;
const { CREATE_CAMPAIGN_1, CREATE_CAMPAIGN_2 } = SUB_STEP_IDS;

export const CreateCampaignStep = ({ isCollapsed, stepCompleteData }) => {
    const { hasDesktopOrMobileCampaigns, hasMinimumAdsPerCampaign } = stepCompleteData;
    const navigate = useNavigate();
    const campaignFormPath = generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN });
    const creativeFormPath = generateCreativeCreatorPath({ entity: ENTITY.CREATIVE });
    const navigateToCampaignForm = () => navigate(campaignFormPath);
    const navigateToCreativeForm = () => navigate(creativeFormPath);

    return (
        <MultiStepDescription
            titleId={CREATE_CAMPAIGN}
            subStepData={[
                {
                    learnMoreLinkId: CREATE_CAMPAIGN_1,
                    descriptionId: CREATE_CAMPAIGN_1,
                    motivationId: CREATE_CAMPAIGN_1,
                    buttons: [
                        {
                            stepId: CREATE_CAMPAIGN_1,
                            onClickCTA: navigateToCampaignForm,
                            isComplete: hasDesktopOrMobileCampaigns,
                        },
                    ],
                },
                {
                    learnMoreLinkId: CREATE_CAMPAIGN_2,
                    descriptionId: CREATE_CAMPAIGN_2,
                    buttons: [
                        {
                            stepId: CREATE_CAMPAIGN_2,
                            onClickCTA: navigateToCreativeForm,
                            isComplete: hasMinimumAdsPerCampaign,
                        },
                    ],
                },
            ]}
            isCollapsed={isCollapsed}
        />
    );
};
