'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.isundefined');

var _lodash2 = _interopRequireDefault(_lodash);

var _Tooltips = require('../Tooltips');

var _CheckboxButtons = require('../CheckboxButtons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by oreuveni
 * Date: 17/10/2018
 * Time: 11:09
 */
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'formField__taboola-svg-icon___35lZI',
    'container': 'formField__container___1xO6a',
    'label-container': 'formField__label-container___3IaJ7',
    'label': 'formField__label___26Z07',
    'checkbox-label': 'formField__checkbox-label___2hHKg',
    'help-tooltip': 'formField__help-tooltip___1dDdZ',
    'description-wrapper': 'formField__description-wrapper___2aOT5',
    'description': 'formField__description___1mDMB',
    'subtext': 'formField__subtext___28kxM'
};


var classNameBuilder = _bind2.default.bind(styles);

var FormField = (0, _react.forwardRef)(function (props, ref) {
    var children = props.children,
        label = props.label,
        description = props.description,
        subDescription = props.subDescription,
        inputId = props.inputId,
        labelClass = props.labelClass,
        labelContainerClass = props.labelContainerClass,
        containerClass = props.containerClass,
        descriptionClass = props.descriptionClass,
        descriptionContainerClass = props.descriptionContainerClass,
        helpText = props.helpText,
        subtext = props.subtext,
        subtextClass = props.subtextClass,
        selected = props.selected,
        onSelect = props.onSelect,
        checkboxLabel = props.checkboxLabel,
        dataTestId = props.dataTestId,
        helpIconLabel = props.helpIconLabel;

    return _react2.default.createElement(
        'div',
        {
            className: classNameBuilder('container', containerClass),
            ref: ref,
            'data-testid': dataTestId
        },
        _react2.default.createElement(
            'div',
            {
                className: classNameBuilder('label-container', labelContainerClass)
            },
            !(0, _lodash2.default)(selected) && _react2.default.createElement(
                _CheckboxButtons.CheckboxGroup,
                {
                    onSelectCheckbox: onSelect && function () {
                        return onSelect(true);
                    },
                    onUnselectCheckbox: onSelect && function () {
                        return onSelect(false);
                    },
                    selectedValues: [selected]
                },
                _react2.default.createElement(_CheckboxButtons.TextCheckbox, {
                    value: true,
                    labelClassName: classNameBuilder('label', 'checkbox-label', labelClass),
                    title: checkboxLabel
                })
            ),
            label && _react2.default.createElement(
                'label',
                {
                    className: classNameBuilder('label', labelClass),
                    htmlFor: inputId
                },
                label
            ),
            helpText && _react2.default.createElement(
                _Tooltips.HelpTooltip,
                { iconClassName: styles['help-tooltip'], iconLabel: helpIconLabel },
                helpText
            )
        ),
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('description-wrapper', descriptionContainerClass) },
            description && _react2.default.createElement(
                'div',
                {
                    className: classNameBuilder('description', descriptionClass)
                },
                description
            ),
            subDescription && _react2.default.createElement(
                'div',
                {
                    className: classNameBuilder('description', descriptionClass)
                },
                subDescription
            )
        ),
        _react2.default.createElement(
            'div',
            null,
            children
        ),
        subtext && _react2.default.createElement(
            'div',
            { className: classNameBuilder('subtext', subtextClass) },
            subtext
        )
    );
});

FormField.propTypes = {
    /** Component children - usually an input */
    children: _propTypes2.default.node,
    /** Label text/component */
    label: _propTypes2.default.node,
    /** checkbox text/component */
    checkboxLabel: _propTypes2.default.node,
    /** Description text/component */
    description: _propTypes2.default.node,
    /** Text/component below the description */
    subDescription: _propTypes2.default.node,
    /** Id of the input child component */
    inputId: _propTypes2.default.string,
    /** Id for testing */
    dataTestId: _propTypes2.default.string,
    /** Class added to label element */
    labelClass: _propTypes2.default.string,
    /** Class added to label container element */
    labelContainerClass: _propTypes2.default.string,
    /** Class added to description element */
    descriptionClass: _propTypes2.default.string,
    /** Class added to description container element */
    descriptionContainerClass: _propTypes2.default.string,
    /** Class added to the component container */
    containerClass: _propTypes2.default.string,
    /** help tooltip text */
    helpText: _propTypes2.default.node,
    /** help icon aria-label */
    helpIconLabel: _propTypes2.default.string,
    /** subtext to display below the input */
    subtext: _propTypes2.default.node,
    /** Class added to subtext element */
    subtextClass: _propTypes2.default.string,
    /** Toggle state of checkbox next to label. If undefined, does not render checkbox */
    selected: _propTypes2.default.bool,
    /** Callback called when clicking on above mentioned checkbox */
    onSelect: _propTypes2.default.func
};

FormField.defaultProps = {
    containerClass: '',
    labelClass: '',
    descriptionClass: '',
    descriptionContainerClass: ''
};

exports.default = FormField;