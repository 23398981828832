'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var UploadFileGalleryButtonSVG = function UploadFileGalleryButtonSVG(_ref) {
    var className = _ref.className;
    return _react2.default.createElement(
        'svg',
        { className: className, preserveAspectRatio: 'none', width: '100%', height: '105px', viewBox: '0 0 157 105', version: '1.1', xmlns: 'http://www.w3.org/2000/svg' },
        _react2.default.createElement(
            'title',
            null,
            '17224164-3BD8-4116-82CA-4059F33D2BE9'
        ),
        _react2.default.createElement(
            'desc',
            null,
            'Created with sketchtool.'
        ),
        _react2.default.createElement(
            'defs',
            null,
            _react2.default.createElement('rect', { id: 'path-1', x: '0', y: '0', width: '157', height: '105', rx: '6' }),
            _react2.default.createElement('path', { d: 'M2,0 L145,0 C146.104569,-2.02906125e-16 147,0.8954305 147,2 L147,93 C147,94.1045695 146.104569,95 145,95 L2,95 C0.8954305,95 7.98370909e-15,94.1045695 0,93 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z', id: 'path-2' })
        ),
        _react2.default.createElement(
            'g',
            { id: 'Style-Guides', stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
            _react2.default.createElement(
                'g',
                { id: 'BS_Styleguide_Side-Bar', transform: 'translate(-802.000000, -3671.000000)' },
                _react2.default.createElement(
                    'g',
                    { id: 'Group-17-Copy-2', transform: 'translate(63.000000, 3671.000000)' },
                    _react2.default.createElement(
                        'g',
                        { id: 'Group-28', transform: 'translate(739.000000, 0.000000)' },
                        _react2.default.createElement(
                            'g',
                            { id: 'Rectangle', strokeLinejoin: 'square' },
                            _react2.default.createElement('rect', { stroke: '#FFFFFF', strokeWidth: '3', x: '1.5', y: '1.5', width: '154', height: '102', rx: '6' }),
                            _react2.default.createElement('rect', { stroke: '#D4D9DF', strokeWidth: '1', x: '0.5', y: '0.5', width: '156', height: '104', rx: '6' })
                        ),
                        _react2.default.createElement(
                            'g',
                            { id: 'Group-7', transform: 'translate(5.000000, 5.000000)' },
                            _react2.default.createElement(
                                'mask',
                                { id: 'mask-3', fill: 'white' },
                                _react2.default.createElement('use', { xlinkHref: '#path-2' })
                            ),
                            _react2.default.createElement('use', { id: 'Rectangle-Copy-2', fill: '#FAFBFB', xlinkHref: '#path-2' }),
                            _react2.default.createElement('path', { d: 'M54,32 C54,38.0735839 48.8516852,43 42.5,43 C36.1483148,43 31,38.0735839 31,32 C31,25.9264161 36.1483148,21 42.5,21 C48.8516852,21 54,25.9264161 54,32', id: 'Fill-12', fill: '#F2F3F5', mask: 'url(#mask-3)' }),
                            _react2.default.createElement('path', { d: 'M91.7983154,38.1529682 L61,68.5351467 L87.6843923,94 L159,94 L102.382681,38.1529682 C99.4726934,35.2823439 94.7102046,35.2823439 91.7983154,38.1529682', id: 'Fill-1', fill: '#F2F3F5', mask: 'url(#mask-3)' }),
                            _react2.default.createElement('path', { d: 'M34.8886759,59.9311056 L0,94 L30.9446574,94 L79,94 L54.5732538,69.9042527 L44.4907723,59.9558387 C41.8570651,57.3569606 37.5354675,57.3474479 34.8886759,59.9311056', id: 'Fill-4', fill: '#F2F3F5', mask: 'url(#mask-3)' })
                        )
                    )
                )
            )
        )
    );
};

UploadFileGalleryButtonSVG.propTypes = {
    className: _propTypes2.default.string
};

UploadFileGalleryButtonSVG.defaultProps = {
    className: ''
};

exports.default = UploadFileGalleryButtonSVG;