import { useCallback, useState } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useAudiencesApi } from 'services/api/audiencesApi';
import TARGETING_TYPES from '../../../config/TargetingTypes';

const chargeType = 'CPC';

const fetchContextualSegmentsApi = ({
    selectedAccountId,
    countryCodes,
    countryTargetingType,
    ids,
    getContextualSegments,
}) => {
    const accountId = selectedAccountId || {};

    return getContextualSegments(accountId, { countryCodes, countryTargetingType, chargeType, ids });
};

const useContextualSegmentsApi = ({ selectedAccountId }) => {
    const { getContextualSegments } = useAudiencesApi();
    const [contextualSegments, setContextualSegments] = useState([]);
    const [isSegmentsLoading, setIsSegmentsLoading] = useState(true);

    const { value: countryTargetingType = TARGETING_TYPES.ALL } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });

    const fetchContextualSegments = useCallback(async () => {
        setIsSegmentsLoading(true);
        try {
            const { results } = await fetchContextualSegmentsApi({
                selectedAccountId,
                countryCodes,
                countryTargetingType,
                getContextualSegments,
            });
            setContextualSegments(results);

            return results;
        } finally {
            setIsSegmentsLoading(false);
        }
    }, [selectedAccountId, countryCodes, countryTargetingType, getContextualSegments]);

    const fetchContextualSegmentsByIds = useCallback(
        async ids => {
            const { results } = await fetchContextualSegmentsApi({
                selectedAccountId,
                countryCodes,
                countryTargetingType,
                ids,
                getContextualSegments,
            });

            return results;
        },
        [selectedAccountId, countryCodes, countryTargetingType, getContextualSegments]
    );

    return {
        contextualSegments,
        fetchContextualSegments,
        isSegmentsLoading,
        fetchContextualSegmentsByIds,
    };
};

export default useContextualSegmentsApi;
