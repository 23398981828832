'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Right = require('../../../Icons/Icons/Right');

var _Right2 = _interopRequireDefault(_Right);

var _Dropdown = require('../../../Icons/Icons/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

var _DashboardRangePickerDate = require('./DashboardRangePickerDate');

var _DashboardRangePickerDate2 = _interopRequireDefault(_DashboardRangePickerDate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dashboardRangePickerHeader__taboola-svg-icon___1E1Pg',
    'dashboard-range-picker-input': 'dashboardRangePickerHeader__dashboard-range-picker-input___3nvG1',
    'dashboard-range-picker-preset': 'dashboardRangePickerHeader__dashboard-range-picker-preset___26Q4v',
    'dashboard-range-picker-indicator': 'dashboardRangePickerHeader__dashboard-range-picker-indicator___2zrpH',
    'dropup': 'dashboardRangePickerHeader__dropup___2abxJ'
};


var DashboardRangePickerHeader = function DashboardRangePickerHeader(_ref) {
    var startDate = _ref.startDate,
        endDate = _ref.endDate,
        pickerOpen = _ref.pickerOpen,
        togglePicker = _ref.togglePicker,
        preset = _ref.preset;
    return _react2.default.createElement(
        'div',
        {
            role: 'button',
            tabIndex: 0,
            type: 'button',
            onClick: togglePicker,
            className: styles['dashboard-range-picker-input']
        },
        _react2.default.createElement(_DashboardRangePickerDate2.default, { date: startDate }),
        _react2.default.createElement(_Right2.default, null),
        _react2.default.createElement(_DashboardRangePickerDate2.default, { date: endDate }),
        _react2.default.createElement(
            'div',
            { className: styles['dashboard-range-picker-preset'] },
            preset
        ),
        _react2.default.createElement(
            'div',
            { className: styles['dashboard-range-picker-indicator'] },
            pickerOpen ? _react2.default.createElement(_Dropdown2.default, { className: styles['dropup'] }) : _react2.default.createElement(_Dropdown2.default, null)
        )
    );
};

DashboardRangePickerHeader.propTypes = {
    startDate: _reactMomentProptypes2.default.momentObj,
    endDate: _reactMomentProptypes2.default.momentObj,
    togglePicker: _propTypes2.default.func,
    pickerOpen: _propTypes2.default.bool,
    preset: _propTypes2.default.string
};

exports.default = DashboardRangePickerHeader;