import React from 'react';

export const VisaLogo = () => {
    return (
        <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.224 0.276001L11.94 15.268H7.84L4.748 3.302C4.56 2.566 4.398 2.296 3.826 1.986C2.894 1.48 1.354 1.006 0 0.710001L0.092 0.276001H6.692C7.1233 0.275568 7.54054 0.42933 7.86842 0.70953C8.19629 0.98973 8.41321 1.37791 8.48 1.804L10.114 10.48L14.15 0.276001H18.224ZM34.29 10.374C34.306 6.416 28.818 6.198 28.856 4.43C28.868 3.892 29.38 3.32 30.5 3.174C31.8126 3.04934 33.1345 3.28152 34.326 3.846L35.006 0.666C33.8459 0.229891 32.6173 0.00435323 31.378 0C27.544 0 24.846 2.04 24.822 4.958C24.798 7.116 26.748 8.318 28.218 9.038C29.73 9.772 30.238 10.244 30.23 10.9C30.22 11.908 29.026 12.35 27.91 12.368C25.96 12.398 24.83 11.842 23.926 11.422L23.224 14.706C24.13 15.122 25.802 15.486 27.536 15.502C31.61 15.502 34.276 13.49 34.29 10.374ZM44.412 15.268H48L44.87 0.276001H41.558C41.2041 0.272721 40.8574 0.375838 40.5629 0.571978C40.2683 0.768117 40.0394 1.04823 39.906 1.376L34.088 15.268H38.16L38.97 13.028H43.946L44.412 15.268ZM40.086 9.956L42.126 4.326L43.302 9.956H40.086ZM23.766 0.276001L20.56 15.268H16.68L19.89 0.276001H23.766Z"
                fill="#1F5499"
            />
        </svg>
    );
};
