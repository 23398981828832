import React from 'react';

const RssSVGIcon = props => {
    return (
        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.75 23C23.7835 23 23 23.7835 23 24.75V35.25C23 36.2165 23.7835 37 24.75 37H35.25C36.2165 37 37 36.2165 37 35.25V24.75C37 23.7835 36.2165 23 35.25 23H24.75ZM24.75 29.125C28.6875 29.125 30.875 31.3125 30.875 35.25L29.125 35.2499C29.125 33.0625 27.8125 30.875 24.75 30.875V29.125ZM32.625 35.2499L34.375 35.25C34.375 29.125 30.875 25.625 24.75 25.625V27.375C29.5625 27.375 32.625 30.4375 32.625 35.2499ZM26.0625 35.25C26.7874 35.25 27.375 34.6624 27.375 33.9375C27.375 33.2126 26.7874 32.625 26.0625 32.625C25.3376 32.625 24.75 33.2126 24.75 33.9375C24.75 34.6624 25.3376 35.25 26.0625 35.25Z"
                fill="#667686"
            />
            <path
                d="M2 4.05261C2 2.94804 2.89543 2.05261 4 2.05261H29C30.1046 2.05261 31 2.94804 31 4.05261V19.0526C31 20.1572 30.1046 21.0526 29 21.0526H4C2.89543 21.0526 2 20.1572 2 19.0526V4.05261Z"
                fill="#EEF0F2"
            />
            <path
                d="M16.6093 21H4.07143C2.92741 21 2 20.0575 2 18.8948V17.6537L5.22442 14.4439C6.55759 13.1167 8.69029 13.1167 10.0235 14.4439L16.6093 21Z"
                fill="#B8C1CA"
            />
            <path
                d="M31 16.2309V18.8948C31 20.0575 30.0726 21 28.9286 21H18.6383L12.6758 15.0011L20.0851 7.5467C20.7852 6.68455 21.9309 6.68455 22.7794 7.5467L31 16.2309Z"
                fill="#B8C1CA"
            />
            <path
                d="M12.98 6.71071C12.98 8.20741 11.7738 9.42141 10.2857 9.42141C8.7976 9.42141 7.59143 8.20741 7.59143 6.71071C7.59143 5.21401 8.7976 4 10.2857 4C11.7738 4 12.98 5.21401 12.98 6.71071Z"
                fill="#B8C1CA"
            />
            <rect x="3" y="24" width="16" height="1" fill="#B8C1CA" />
            <rect x="3" y="27" width="12" height="1" fill="#B8C1CA" />
            <path
                d="M2 1H31C31.5523 1 32 1.44772 32 2V21H33V2C33 0.89543 32.1046 0 31 0H2C0.895431 0 0 0.89543 0 2V29C0 30.1046 0.895431 31 2 31H21V30H2C1.44772 30 1 29.5523 1 29V2C1 1.44772 1.44772 1 2 1Z"
                fill="#667686"
            />
        </svg>
    );
};

export default RssSVGIcon;
