import { useCallback, useEffect, useMemo, useRef } from 'react';
import { difference, identity, isEmpty, keyBy, map, compact, concat, noop } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import TargetingTypes from '../../../config/TargetingTypes';
import { useSelectedSegmentsValidationDependencies } from './useSelectedSegmentsValidationDependencies';

const emptyIds = [];
const useSelectedSegments = ({
    selectedLeaves,
    setSelectedLeavesByIds,
    targetingType,
    includeIdsFormFieldName,
    excludeIdsFormFieldName,
    onAddIds = noop,
    enabledProvidersMap,
    includeErrorListValidations = [],
    includeWarningListValidations = [],
    excludeErrorListValidations = [],
    excludeWarningListValidations = [],
    setIsReady,
    isReadyToValidate,
}) => {
    const { mode } = useFormDataContext();
    const leavesByIdMap = useMemo(() => keyBy(selectedLeaves, 'id'), [selectedLeaves]);

    const validationDependencies = useSelectedSegmentsValidationDependencies({
        enabledProvidersMap,
        leavesByIdMap,
        field: includeIdsFormFieldName,
    });

    const includeValidations = useMemo(
        () => [...includeErrorListValidations, ...includeWarningListValidations],
        [includeErrorListValidations, includeWarningListValidations]
    );

    const {
        value: includeIds = emptyIds,
        onChange: onChangeIncludeIds,
        failedValidationData: includeFailedValidationData,
        scrollRef: includeScrollRef,
    } = useFormValidatedValue({
        field: includeIdsFormFieldName,
        validations: includeValidations,
        validationDependencies,
        isReady: mode === FORM_MODES.CREATE || isReadyToValidate,
    });

    const excludeValidations = useMemo(
        () => [...excludeErrorListValidations, ...excludeWarningListValidations],
        [excludeErrorListValidations, excludeWarningListValidations]
    );

    const {
        value: excludeIds = emptyIds,
        onChange: onChangeExcludeIds,
        failedValidationData: excludeFailedValidationData,
        scrollRef: excludeScrollRef,
    } = useFormValidatedValue({
        field: excludeIdsFormFieldName,
        validations: excludeValidations,
        validationDependencies,
        isReady: mode === FORM_MODES.CREATE || isReadyToValidate,
    });

    // flow to sync selectedLeaves with selectedIncludeSegmentsIds and selectedExcludeSegmentsIds
    const leavesInitRef = useRef(false);
    const initLeavesCallback = async () => {
        const selectedIds = [...includeIds, ...excludeIds];
        if (!isEmpty(selectedIds)) {
            await setSelectedLeavesByIds(selectedIds);
        } else {
            setIsReady(true);
        }
        leavesInitRef.current = true;
    };

    useEffect(() => {
        if (leavesInitRef.current) {
            return;
        }

        initLeavesCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!leavesInitRef.current) {
            return;
        }

        const treeSelectedLeafIds = selectedLeaves.map(item => item.id);
        const listSelectedIds = [...includeIds, ...excludeIds];
        const addedIds = difference(treeSelectedLeafIds, listSelectedIds);
        if (addedIds.length) {
            onAddIds(targetingType, addedIds);
            if (targetingType === TARGETING_TYPES.INCLUDE) {
                onChangeIncludeIds(prev => compact(concat(prev, addedIds)));
            } else {
                onChangeExcludeIds(prev => compact(concat(prev, addedIds)));
            }
        }

        const removedIds = difference(listSelectedIds, treeSelectedLeafIds);
        if (removedIds.length === 0) {
            return;
        }

        const treeSelectedLeafIdsMap = keyBy(treeSelectedLeafIds, identity);
        const removedIncludeIds = difference(includeIds, treeSelectedLeafIds);
        if (removedIncludeIds.length) {
            onChangeIncludeIds(prev => prev.filter(id => treeSelectedLeafIdsMap[id]));
        }

        const removedExcludeIds = difference(excludeIds, treeSelectedLeafIds);
        if (removedExcludeIds.length) {
            onChangeExcludeIds(prev => prev.filter(id => treeSelectedLeafIdsMap[id]));
        }
    }, [includeIds, excludeIds, onChangeIncludeIds, onChangeExcludeIds, selectedLeaves, targetingType, onAddIds]);

    const { includeLeaves, excludeLeaves } = useMemo(() => {
        return {
            includeLeaves: map(includeIds, id => ({
                id,
                ...leavesByIdMap[id],
                loading: !leavesByIdMap[id],
                targetingType: TargetingTypes.INCLUDE,
            })),
            excludeLeaves: map(excludeIds, id => ({
                id,
                ...leavesByIdMap[id],
                loading: !leavesByIdMap[id],
                targetingType: TargetingTypes.EXCLUDE,
            })),
        };
    }, [includeIds, excludeIds, leavesByIdMap]);

    const removeIncludeLeaves = useCallback(
        () => setSelectedLeavesByIds(excludeIds),
        [excludeIds, setSelectedLeavesByIds]
    );

    const removeExcludeLeaves = useCallback(
        () => setSelectedLeavesByIds(includeIds),
        [includeIds, setSelectedLeavesByIds]
    );

    return {
        includeLeaves,
        excludeLeaves,
        removeIncludeLeaves,
        removeExcludeLeaves,
        includeFailedValidationData,
        excludeFailedValidationData,
        scrollRef: includeScrollRef || excludeScrollRef,
    };
};

export default useSelectedSegments;
