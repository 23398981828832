import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { COMPONENT_STATUS } from 'services/constants';
import { CollapsiblePickerToolbar, CollapsiblePickerList } from './components';
import styles from './collapsiblePicker.module.scss';

const classNameBuilder = classnames.bind(styles);

const CollapsiblePicker = ({
    className,
    toolbarClassName,
    listClassName,
    disabled,

    sortType,
    sortField,
    sortOptions,
    sortMenuStyles,
    sortStyles,
    onSelectSortField,
    onSelectSortType,
    sortFooter,
    sortTooltip,

    listItems,
    listItemProps,
    selectedItem,
    isSelectedItem,
    totalItems,
    loadNextPage,
    isItemLoaded,
    paginationStatus,
    onSelectListItem,
    listItemComponent,
    emptyListComponent,
    loadingListItemComponent,
    getItemSize,
    getItemKey,
    virtualizationState,

    onSearch,
    searchText,
    searchPlaceholder,
}) => {
    return (
        <div className={classNameBuilder('container', className)}>
            <CollapsiblePickerToolbar
                className={toolbarClassName}
                sortOptions={sortOptions}
                sortType={sortType}
                sortField={sortField}
                sortMenuStyles={sortMenuStyles}
                sortStyles={sortStyles}
                onSelectSortField={onSelectSortField}
                onSelectSortType={onSelectSortType}
                disabled={disabled}
                onSearch={e => onSearch(e.target.value)}
                searchText={searchText}
                sortTooltip={sortTooltip}
                sortFooter={sortFooter}
                placeholder={searchPlaceholder}
            />
            <CollapsiblePickerList
                className={listClassName}
                items={listItems}
                selectedItem={selectedItem}
                isSelectedItem={isSelectedItem}
                total={totalItems}
                disabled={disabled}
                listItemProps={listItemProps}
                listItemComponent={listItemComponent}
                emptyListComponent={emptyListComponent}
                loadingListItemComponent={loadingListItemComponent}
                getItemSize={getItemSize}
                getItemKey={getItemKey}
                loadNextPage={loadNextPage}
                isItemLoaded={isItemLoaded}
                virtualizationState={virtualizationState}
                paginationStatus={paginationStatus}
                onSelect={onSelectListItem}
            />
        </div>
    );
};
CollapsiblePicker.propTypes = {
    /* class name for container */
    className: PropTypes.string,
    /* class name for toolbar container */
    toolbarClassName: PropTypes.string,
    /* class name for list container */
    listClassName: PropTypes.string,

    /* boolean to disable the picker */
    disabled: PropTypes.bool,

    /* toolbar props */
    sortType: PropTypes.oneOf(Object.values(SORTING_ORDER_TYPES)),
    sortField: PropTypes.any,
    sortOptions: PropTypes.array,
    sortMenuStyles: PropTypes.object,
    sortStyles: PropTypes.object,
    onSelectSortField: PropTypes.func,
    onSelectSortType: PropTypes.func,
    searchText: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    onSearch: PropTypes.func,
    sortFooter: PropTypes.node,
    sortTooltip: PropTypes.node,

    /* list props */
    listItems: PropTypes.array,
    listItemProps: PropTypes.object,
    selectedItem: PropTypes.any,
    isSelectedItem: PropTypes.func,
    totalItems: PropTypes.number,
    loadNextPage: PropTypes.func,
    isItemLoaded: PropTypes.func,
    paginationStatus: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    onSelectListItem: PropTypes.func,
    getItemSize: PropTypes.func,
    getItemKey: PropTypes.func,
    virtualizationState: PropTypes.any,
    /* must be render-able Components */
    listItemComponent: PropTypes.any,
    emptyListComponent: PropTypes.any,
    loadingListItemComponent: PropTypes.any,
};

CollapsiblePickerToolbar.defaultProps = {
    listItems: [],
    sortOptions: [],
};

export default CollapsiblePicker;
