import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { appStatusSelector } from '../selectors';
import { COMPONENT_STATUS } from '../services/constants';

export const useComponentStatus = (moduleStatus = COMPONENT_STATUS.ACTIVE) => {
    const appStatus = useSelector(appStatusSelector);
    const [componentStatus, setComponentStatus] = useState(COMPONENT_STATUS.INITIAL);

    const isModuleReady = appStatus === COMPONENT_STATUS.ACTIVE && moduleStatus === COMPONENT_STATUS.ACTIVE;

    const status = useMemo(() => {
        if (isModuleReady) {
            return componentStatus;
        }

        const allStatuses = [appStatus, moduleStatus];

        return allStatuses.find(status => status !== COMPONENT_STATUS.ACTIVE);
    }, [appStatus, componentStatus, isModuleReady, moduleStatus]);

    return {
        status,
        isModuleReady,
        setStatus: setComponentStatus,
    };
};

export default useComponentStatus;
