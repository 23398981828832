import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import FormattedMessageCellRenderer from './FormattedMessageCellRenderer';
import styles from './labelLayoutCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);

const LabelLayoutCellRenderer = ({ msgId, className, labelClassName, defaultValue, children }) => (
    <div className={classNameBuilder('container', className)}>
        {children}
        {msgId ? (
            <div className={classNameBuilder('label', labelClassName)}>
                <FormattedMessageCellRenderer value={msgId} defaultValue={defaultValue} />
            </div>
        ) : null}
    </div>
);

LabelLayoutCellRenderer.propTypes = {
    msgId: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    defaultValue: PropTypes.string,
};

LabelLayoutCellRenderer.defaultProps = {
    defaultValue: '',
};

export default LabelLayoutCellRenderer;
