import React from 'react';
import { errorMessagesUtils } from 'services/utils';
import { creativesApi } from '../../../services/api';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { deleteCreativesSuccess, deleteCreativesError, requestDeleteCreatives } from '../actions';

const successIndication = {
    message: <FormattedMessage id="creatives.delete.success" defaultMessage="Ads successfully deleted" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="creatives.delete.error.highlight" defaultMessage="Unable to delete ads." />,
});

const deleteCreatives = (accountId, creativeIds) => dispatch => {
    const update = {
        ids: creativeIds,
    };
    dispatch(requestDeleteCreatives());
    return creativesApi
        .deleteCreatives(accountId, update)
        .then(({ results }) => {
            dispatch(addIndication(successIndication));
            dispatch(deleteCreativesSuccess(results));
        })
        .catch(error => {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(deleteCreativesError(creativeIds, error));
        });
};

export default deleteCreatives;
