import React from 'react';
import { LinkButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from '../SiteTargetingList/siteTargetingList.module.scss';

export const MessageWithRemoveIndication = ({ invalidItems, handleClearInvalidItems }) => (
    <div className={styles['error-container']}>
        <FormattedMessage
            id="campaign.editor.targeting.site.invalid"
            defaultMessage="Some Sites are unavailable anymore"
        />
        <LinkButton onClick={() => handleClearInvalidItems(invalidItems)} className={styles['clear-invalid']}>
            <FormattedMessage
                id="campaign.editor.targeting.site.clearUnavailable"
                defaultMessage="Click here to remove {count} unavailable site{count, plural, one {} other {s} }"
                values={{ count: invalidItems.length }}
            />
        </LinkButton>
    </div>
);
