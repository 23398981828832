import moment from 'moment-timezone';
import { BUDGET_TYPES, SPENDING_LIMITS } from '../../common-campaign-form/config';

export const campaignsGroupInitialValues = {
    campaignsGroupName: '',
    poNumber: '',
    startDate: () => moment(),
    budget: ({ isNoneSpendingLimitDisplayed, campaignGroupExtensionEnabled }) => ({
        type: BUDGET_TYPES.DAILY,
        value: '',
        spendingLimit: {
            type:
                isNoneSpendingLimitDisplayed && campaignGroupExtensionEnabled
                    ? SPENDING_LIMITS.NONE
                    : SPENDING_LIMITS.MONTHLY,
            value: '',
        },
    }),
    campaigns: [],
};
