import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import { HelpTooltip } from 'taboola-ultimate-ui';
import { ExternalLink } from 'components';
import { useAvailableItems } from 'hooks';
import { useCreativeCampaignData } from 'modules/campaigns/modules/creative-creator/hooks/useCreativeCampaignData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isAppleTargeting } from '../../../creative-creator/utils/isAppleTargeting';
import { PLACEMENTS } from '../../config/placements';
import { OneByOne, OneDotNinetyOneByOne, SixByFive, SixteenByNine, TwoByOne } from './Buttons';
import styles from './placementToolbar.module.scss';

const classNameBuilder = classnames.bind(styles);

const bestPracticesLink = (
    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/115006880507-Title-and-Thumbnail-Best-Practices">
        <FormattedMessage id="creative.preview.placement.toolbar.link" defaultMessage="best practices" />
    </ExternalLink>
);

const sizeButtonsOptions = [
    {
        Button: OneDotNinetyOneByOne,
    },
    {
        Button: OneByOne,
    },
    {
        Button: SixByFive,
        visibilityCondition: ({ isTargetingApple }) => !isTargetingApple,
    },
    {
        Button: SixteenByNine,
        visibilityCondition: ({ isTargetingApple }) => {
            return !isTargetingApple;
        },
    },
    {
        Button: TwoByOne,
        visibilityCondition: ({ isTargetingApple }) => !isTargetingApple,
    },
];

const PlacementToolbar = ({ className, isDisabled, onSelect, selected }) => {
    const getIsActive = useCallback(name => !isDisabled && name === selected, [isDisabled, selected]);
    const getDataMetrics = useCallback(
        name => ({
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'Preview Placements Buttons',
            'data-metrics-value': name,
        }),
        []
    );
    const { fetchedCampaign } = useCreativeCampaignData();
    const isTargetingApple = isAppleTargeting(fetchedCampaign);
    const buttonsAspectRatios = useAvailableItems(sizeButtonsOptions, { isTargetingApple });
    return (
        <div className={classNameBuilder('container', className)}>
            <div className={styles['label']}>
                <FormattedMessage id="creative.preview.placement.toolbar.label" defaultMessage="Placements" />
                <HelpTooltip iconClassName={styles['icon']}>
                    <FormattedMessage
                        id="creative.preview.placement.toolbar.tooltip"
                        defaultMessage="See how your ads will appear on the most common placements across the Taboola network. Please make sure your ad is following Taboola’s {bestPracticesLink}. In case you are using an Ad Blocker please turn it off or whitelist this page. Note: ads may be cropped to fit different, additional aspect ratios on our network."
                        values={{ bestPracticesLink }}
                    />
                </HelpTooltip>
            </div>
            <div className={styles['button-group']}>
                {buttonsAspectRatios.map(({ Button }, index) => (
                    <Button
                        key={index}
                        isDisabled={isDisabled}
                        onClick={onSelect}
                        getIsActive={getIsActive}
                        getDataMetrics={getDataMetrics}
                    />
                ))}
            </div>
        </div>
    );
};

PlacementToolbar.propTypes = {
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    onSelect: PropTypes.func,
    selected: PropTypes.oneOf(values(PLACEMENTS)),
};

export default PlacementToolbar;
