import { FORM_MODES } from 'config/formModes';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

export const useUnifiedCreativeEditorFormConfig = () => {
    const { mode } = useFormDataContext();
    const isUnifiedCreativesFormDuplicateEnabled = useConfigMatch({
        [FEATURE_FLAGS.UNIFIED_CREATIVE_FORM_DUPLICATE_ENABLED]: 'true',
    });

    const isUnifiedCreativesFormDuplicateMode = mode === FORM_MODES.DUPLICATE && isUnifiedCreativesFormDuplicateEnabled;

    return isUnifiedCreativesFormDuplicateMode;
};
