'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AspectRatioKeeper = require('../AspectRatioKeeper/AspectRatioKeeper');

var _AspectRatioKeeper2 = _interopRequireDefault(_AspectRatioKeeper);

var _Video = require('../../Video');

var _Tooltips = require('../../Tooltips');

var _Image = require('../../Image');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'mediaGalleryItem__taboola-svg-icon___1Gv9P',
    'container': 'mediaGalleryItem__container___3aXkd',
    'media': 'mediaGalleryItem__media___Lmx4l',
    'fallback-img': 'mediaGalleryItem__fallback-img___2jVKZ',
    'selection-overlay': 'mediaGalleryItem__selection-overlay___1EBgk',
    'selected': 'mediaGalleryItem__selected___tIC0w',
    'overlay': 'mediaGalleryItem__overlay___3MZUV',
    'tooltip': 'mediaGalleryItem__tooltip___2FyoW'
};

// eslint-disable-next-line react/prefer-stateless-function
var MediaGalleryTooltip = function (_Component) {
    _inherits(MediaGalleryTooltip, _Component);

    function MediaGalleryTooltip() {
        _classCallCheck(this, MediaGalleryTooltip);

        return _possibleConstructorReturn(this, (MediaGalleryTooltip.__proto__ || Object.getPrototypeOf(MediaGalleryTooltip)).apply(this, arguments));
    }

    _createClass(MediaGalleryTooltip, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                previewPositionClass = _props.previewPositionClass,
                element = _props.element,
                largePreviewWidth = _props.largePreviewWidth,
                largePreviewHeight = _props.largePreviewHeight,
                isVideo = _props.isVideo,
                imgSrc = _props.imgSrc,
                largePreviewErrorComponent = _props.largePreviewErrorComponent,
                tooltipContentStyle = _props.tooltipContentStyle;

            return _react2.default.createElement(
                _Tooltips.TooltipV2,
                {
                    position: previewPositionClass,
                    duration: 0,
                    delay: [500, 0],
                    boxClassName: styles['tooltip'],
                    scrollRef: element,
                    offsetBody: -2
                },
                _react2.default.createElement(
                    'div',
                    { style: { width: largePreviewWidth, height: largePreviewHeight } },
                    isVideo ? _react2.default.createElement(
                        _AspectRatioKeeper2.default,
                        null,
                        _react2.default.createElement(_Video.Video, {
                            className: styles['media'],
                            src: imgSrc,
                            errorComponent: largePreviewErrorComponent,
                            loadingComponent: largePreviewErrorComponent,
                            style: tooltipContentStyle,
                            autoplay: true,
                            play: true,
                            renderWatermark: false
                        })
                    ) : _react2.default.createElement(_Image.Image, {
                        className: styles['media'],
                        errorComponent: largePreviewErrorComponent,
                        loadingComponent: largePreviewErrorComponent,
                        src: imgSrc,
                        style: tooltipContentStyle
                    })
                )
            );
        }
    }]);

    return MediaGalleryTooltip;
}(_react.Component);

MediaGalleryTooltip.propTypes = {
    element: _propTypes2.default.object,
    previewPositionClass: _propTypes2.default.string,
    largePreviewWidth: _propTypes2.default.number,
    largePreviewHeight: _propTypes2.default.number,
    className: _propTypes2.default.string,
    isVideo: _propTypes2.default.bool,
    imgSrc: _propTypes2.default.string,
    largePreviewErrorComponent: _propTypes2.default.object,
    tooltipContentStyle: _propTypes2.default.object
};

exports.default = MediaGalleryTooltip;