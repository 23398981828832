import React from 'react';
import classnames from 'classnames/bind';
import { DeletableItem, INDICATION_TYPES, IndicationContent } from 'taboola-ultimate-ui';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ConversionEvent } from '../ConversionEvent/ConversionEvent';
import { Metric } from '../Metric/Metric';
import { PredicateTypeDropDown } from '../PredicateTypeDropdown/PredicateTypeDropDown';
import { TimeFrame } from '../TimeFrame/TimeFrame';
import { ValueType } from '../ValueType/ValueType';
import styles from './condition.module.scss';

const classNameBuilder = classnames.bind(styles);
const MIN_INDEX_TO_RENDER_DELETABLE = 1;
export const Condition = ({ settingValue, setConditions, conditions, index, onDeleteCondition }) => {
    const { value: metricNameValue } = useFormFieldValue({ field: 'ruleMetric' });
    const { value: timeFrameValue } = useFormFieldValue({ field: 'timeFrame' });

    const timeFrame = settingValue[metricNameValue]?.timeFrame || null;
    const conversionEvent = settingValue[metricNameValue]?.conversionEvent || null;
    const predicateGroupType = settingValue[metricNameValue]?.predicateGroupType || null;
    const valueType = settingValue[metricNameValue]?.valueType || null;

    return (
        <div>
            <DeletableItem
                className={styles['delete-container']}
                onDelete={onDeleteCondition}
                isItemDeletable={index >= MIN_INDEX_TO_RENDER_DELETABLE}
                aria-label="Removable item"
            >
                <div className={styles['metric']}>
                    <span className={classNameBuilder('condition-icon', { if: index === 0 })}>
                        <FormattedMessage
                            id={
                                index === 0
                                    ? 'app.create.performance.rules.if.condition'
                                    : 'app.create.performance.rules.and.condition'
                            }
                        />
                    </span>
                    <Metric
                        settingValue={settingValue}
                        setConditions={setConditions}
                        conditions={conditions}
                        conditionIndex={index}
                        hasConversionEvent={!!conversionEvent}
                    />
                    {conversionEvent && <ConversionEvent index={index} />}
                    {timeFrame && <TimeFrame />}
                    {predicateGroupType && <PredicateTypeDropDown predicateGroupType={predicateGroupType} />}
                    {valueType && <ValueType valueType={valueType} />}
                </div>
            </DeletableItem>
            {timeFrameValue === 0 && (
                <IndicationContent
                    containerClassName={classNameBuilder('indication-wrapper', commonStyles['warning-indication'])}
                    type={INDICATION_TYPES.WARNING}
                >
                    <FormattedMessage
                        id="app.create.performance.rules.time.frame.warning"
                        defaultMessage="Data may be less predictable when using the “Today” time range. We recommend using a larger time range."
                    />
                </IndicationContent>
            )}
        </div>
    );
};
