import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import uuid from 'uuid/v1';
import { Gallery, MediaGalleryItem } from 'taboola-ultimate-ui';
import { COMPONENT_STATUS } from 'services/constants';
import { MediaTabsContext } from '../MediaTabsContextProvider';
import EmptyGallery from './EmptyGallery';
import { GalleryItem } from './GalleryItem';
import styles from './imageGallery.module.scss';

const classNameBuilder = classnames.bind(styles);

const gridColumns = 3;
const placeholderCount = 12;

const ImageGallery = ({
    status,
    recommendedImages,
    addImageHandler,
    className,
    emptyGalleryMessagesPrefix,
    emptyGalleryType,
    columnCount,
    hideEmptyGallery,
    getItemMetricsAttributes,
    GalleryItemDropdown,
    shouldHideOnError,
    gap = '12px',
}) => {
    const imageGalleryClass = classNameBuilder('image-gallery', className);
    let gallery;

    const isGalleryEmpty = recommendedImages.length === 0;

    if (status === COMPONENT_STATUS.LOADING) {
        gallery = (
            <Gallery gap={gap} columns={columnCount}>
                {Array(placeholderCount)
                    .fill()
                    .map(() => (
                        <div key={uuid()}>
                            <MediaGalleryItem status={COMPONENT_STATUS.LOADING} />
                        </div>
                    ))}
            </Gallery>
        );
    } else {
        gallery = !isGalleryEmpty ? (
            <Gallery gap={gap} columns={columnCount}>
                {recommendedImages.map(image => (
                    <MediaTabsContext.Consumer key={image.id}>
                        {({ thumbnailsMap }) => {
                            const selected = Boolean(thumbnailsMap[image.id]);
                            return (
                                <GalleryItem
                                    image={image}
                                    selected={selected}
                                    addImageHandler={addImageHandler}
                                    getItemMetricsAttributes={getItemMetricsAttributes}
                                    GalleryItemDropdown={GalleryItemDropdown}
                                    shouldHideOnError={shouldHideOnError}
                                />
                            );
                        }}
                    </MediaTabsContext.Consumer>
                ))}
            </Gallery>
        ) : (
            !hideEmptyGallery && <EmptyGallery messagesPrefix={emptyGalleryMessagesPrefix} type={emptyGalleryType} />
        );
    }

    return <div className={imageGalleryClass}>{gallery}</div>;
};

ImageGallery.propTypes = {
    status: PropTypes.string,
    recommendedImages: PropTypes.array,
    addImageHandler: PropTypes.func,
    className: PropTypes.string,
    emptyGalleryMessagesPrefix: PropTypes.string,
    emptyGalleryType: PropTypes.string,
    columnCount: PropTypes.number,
    getItemMetricsAttribute: PropTypes.func,
};

ImageGallery.defaultProps = {
    columnCount: gridColumns,
};

export default ImageGallery;
