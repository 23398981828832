import { getIsBundlingAllowedOnAccount } from 'modules/campaigns/services/utils/audiences';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';

export const useIsMarketplaceAudiencesBundlingEnabled = () => {
    const isBundlingEnabled = useConfigMatch({ [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_BUNDLING_ENABLED]: 'true' });
    const accountToCheck = useAccount();

    return isBundlingEnabled && getIsBundlingAllowedOnAccount(accountToCheck);
};
