import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './trackWithGTM.module.scss';

export const TrackWithGTM = () => {
    return (
        <CommonCollapsibleCard
            id="TRACK WITH GTM"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="tracking.setup.gtm.track.label" defaultMessage="Manual Integration" />
                    }
                />
            }
            isInitiallyCollapsed
        >
            <Indication
                type="info"
                message={
                    <FormField
                        label={
                            <FormattedMessage
                                id="tracking.setup.gtm.track.integration.label"
                                defaultMessage="Manual GTM integration"
                            />
                        }
                        description={
                            <div className={styles['tag']}>
                                <FormattedMessage
                                    id="tracking.setup.gtm.track.integration.description.help.center"
                                    defaultMessage="If you wish to integrate manually, please refer to the <b><a>help center</a></b> for step-by-step guidance."
                                />
                                <FormattedMessage
                                    id="tracking.setup.gtm.track.integration.description.click.here"
                                    defaultMessage=" For image pixel <b><a>click here</a></b>."
                                />
                            </div>
                        }
                    />
                }
                displayDismissComponent={false}
            />
        </CommonCollapsibleCard>
    );
};
