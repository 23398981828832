import { compact, invert, keyBy } from 'lodash';
import { getEntityMetadataFromWorkbook, getSheetName } from '../utils/excelBulkWorkbookUtils';

export const getFileNamesFromWorkbook = ({ workbook, excelConfig, formatMessage }) => {
    const workbookFileNames = [];

    excelConfig.entityList.forEach(sheetConfig => {
        const metadata = getEntityMetadataFromWorkbook(workbook, sheetConfig);
        const headerNameToFieldMap = invert(metadata);
        const fieldToColumnMap = keyBy(sheetConfig.columns, 'field');

        const sheetName = getSheetName(sheetConfig.sheetName, formatMessage);
        const sheet = workbook.getWorksheet(sheetName);

        if (!sheet) {
            return;
        }

        const headerRowValues = sheet.getRow(sheetConfig.headerRowIndex).values;
        const startingReadRowNumber = sheetConfig.headerRowIndex + 1;

        headerRowValues.forEach((headerName, index) => {
            const field = headerNameToFieldMap[headerName];
            const columnConfig = fieldToColumnMap[field];

            if (columnConfig?.isFileName) {
                const columnValues = sheet.getColumn(index).values;
                const fileNames = columnValues.slice(startingReadRowNumber);

                workbookFileNames.push(...fileNames);
            }
        });
    });

    return compact(workbookFileNames);
};

export const getUniqueFileNamesFromWorkbook = ({ workbook, excelConfig, formatMessage }) =>
    Array.from(new Set(getFileNamesFromWorkbook({ workbook, excelConfig, formatMessage })));
