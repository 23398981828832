import React, { useCallback } from 'react';
import { DeletableItem } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { PrimaryConversion } from './PrimaryConversion';
import styles from './funnelConversionsList.module.scss';

export const ConversionItem = ({ item, deleteItem, index, totalItems }) => {
    const { formatMessage } = useIntl();
    const { label } = item;

    const handleOnClick = useCallback(() => deleteItem(item), [item, deleteItem]);

    return (
        <DeletableItem
            buttonProps={{
                'aria-label': `${formatMessage({ id: 'app.modal.removeVariation.button.remove' })} - ${label}`,
            }}
            onDelete={handleOnClick}
            isItemDeletable={!!deleteItem}
            contentClassName={styles['rule-content']}
        >
            <div className={styles['rule-container']}>
                <span className={styles['label']}>{label}</span>
                {index === totalItems - 1 && <PrimaryConversion />}
            </div>
        </DeletableItem>
    );
};
