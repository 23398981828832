import React, { useState } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { CollapsibleFormSection } from 'components';
import { COMMON_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TagManagerCodeSnippet } from './TagManagerCodeSnippet';
import { UsingGoogleTagManagerCheckBox } from './UsingGoogleTagManagerCheckBox';
import styles from './advancedOptions.module.scss';

export const AdvancedOptions = ({ isEventImagePixel, eventName }) => {
    const [isUsingGoogleTagManager, setIsUsingGoogleTagManager] = useState(false);
    let flagName = isUsingGoogleTagManager
        ? COMMON_FLAGS.UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITH_GTM
        : COMMON_FLAGS.UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITHOUT_GTM;

    if (isEventImagePixel) {
        flagName = isUsingGoogleTagManager
            ? COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITH_GTM
            : COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITHOUT_GTM;
    }

    return (
        <CollapsibleFormSection
            isInitiallyCollapsed
            title={<FormattedMessage id="tracking.setup.advanced.options" defaultMessage="Advanced Options" />}
            subtitle={<FormattedMessage id="tracking.setup.advanced.options.subtitle" defaultMessage="Image Pixel" />}
            sectionId="MANUAL SETUP ADVANCED OPTIONS"
        >
            <div className={styles['container']}>
                <div className={styles['content']}>
                    <FormField
                        label={
                            <FormattedMessage
                                id="tracking.setup.advanced.options.image.pixel.code"
                                defaultMessage="Image Pixel Code"
                            />
                        }
                        description={
                            <FormattedMessage
                                id="tracking.setup.advanced.options.image.pixel.code.description"
                                defaultMessage="Use this code only if you have limitations using the JavaScript pixel.
                        Any changes in the code could cause the pixel not to work properly and is not recommended."
                            />
                        }
                    />
                    <UsingGoogleTagManagerCheckBox
                        onChange={setIsUsingGoogleTagManager}
                        value={isUsingGoogleTagManager}
                    />
                </div>
                <TagManagerCodeSnippet flagName={flagName} eventName={eventName} />
            </div>
        </CollapsibleFormSection>
    );
};
