import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config';
import { TooltipSection } from '../../../common-campaign-form';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import RUNTIME_TYPES from '../../../common-campaign-form/config/runtimeTypes';
import styles from '../radioBtnComponents.module.scss';

const classNameBuilder = classnames.bind(styles);

export const BusinessModel = () => {
    const { value: selectedBusinessModel, onChange: onSelectedBusinessModelChange } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });

    const { value: campaignScheduleMode, mode } = useFormFieldValue({
        field: 'campaignSchedule.mode',
    });

    const { value: preferredViewabilityRate } = useFormFieldValue({
        field: 'campaignTargeting.viewabilityTargeting',
    });

    const { value: endDate } = useFormFieldValue({ field: 'endDate' });

    const isSelectedEndDate = endDate !== null && endDate !== undefined;
    const isCampaignScheduleModeCustom = campaignScheduleMode === RUNTIME_TYPES.CUSTOM;
    const isSelectedPreferredViewabilityRate = preferredViewabilityRate && preferredViewabilityRate !== 'NONE';

    if (
        (mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE) &&
        selectedBusinessModel === VIDEO_PRICING_MODEL.CPCV
    ) {
        const toChange = !isSelectedEndDate ? VIDEO_PRICING_MODEL.VCPM : selectedBusinessModel;
        onSelectedBusinessModelChange(toChange);
    }

    return (
        <div className="video-business-model-options">
            <RadioGroup
                id="video-business-model"
                className={styles['radio-group']}
                selectedValue={selectedBusinessModel}
                onChange={onSelectedBusinessModelChange}
                disabled={mode === FORM_MODES.EDIT}
            >
                <div
                    className={classNameBuilder({
                        'option-not-disabled': !isCampaignScheduleModeCustom && !isSelectedPreferredViewabilityRate,
                        isCampaignHasEndDate: isSelectedEndDate,
                    })}
                    data-testid="video-business-model-options"
                >
                    <Radio
                        labelClassName={styles['radio-label']}
                        value={VIDEO_PRICING_MODEL.CPCV}
                        title={
                            <FormattedMessage
                                id="video.campaign.editor.BusinessModel.option.cpcv"
                                defaultMessage="CPCV"
                            />
                        }
                        disabled={
                            isCampaignScheduleModeCustom ||
                            isSelectedPreferredViewabilityRate ||
                            mode === FORM_MODES.EDIT ||
                            !isSelectedEndDate
                        }
                    />

                    <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} duration={0} delay={800} arrow interactive>
                        {isCampaignScheduleModeCustom && (
                            <TooltipSection>
                                <FormattedMessage
                                    id="video.campaign.editor.BusinessModel.option.cpcv.disabled.tiptop"
                                    defaultMessage="Choosing CPCV is currently not supported when using specific days and hours. Please remove
                                                    the campaign schedule to use CPCV Pricing."
                                />
                            </TooltipSection>
                        )}
                        {!isSelectedEndDate && (
                            <TooltipSection>
                                <FormattedMessage
                                    id="validations.error.campaign.businessModel.endDate.empty"
                                    defaultMessage="Start AND end date are required for CPCV campaigns. Please return to previous screen and add end date."
                                />
                            </TooltipSection>
                        )}
                        {isSelectedPreferredViewabilityRate && (
                            <TooltipSection>
                                <FormattedMessage
                                    id="video.campaign.editor.BusinessModel.option.preferredViewability.disabled.tooltip"
                                    defaultMessage="Choosing CPCV or vCPM is currently not supported when using preferred viewability rate. Please remove the preferred viewability rate to use CPCV or vCPM Pricing."
                                />
                            </TooltipSection>
                        )}
                    </Tooltip>
                </div>

                <div className={classNameBuilder({ 'option-not-disabled': !isSelectedPreferredViewabilityRate })}>
                    <Radio
                        labelClassName={styles['radio-label']}
                        value={VIDEO_PRICING_MODEL.VCPM}
                        title={
                            <FormattedMessage
                                id="video.campaign.editor.BusinessModel.option.vcpm"
                                defaultMessage="vCPM"
                            />
                        }
                        disabled={isSelectedPreferredViewabilityRate || mode === FORM_MODES.EDIT}
                    />
                    {isSelectedPreferredViewabilityRate && (
                        <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} duration={0} delay={800} arrow interactive>
                            <FormattedMessage
                                id="video.campaign.editor.BusinessModel.option.preferredViewability.disabled.tooltip"
                                defaultMessage="Choosing CPCV or vCPM is currently not supported when using preferred viewability rate. Please remove the preferred viewability rate to use CPCV or vCPM Pricing."
                            />
                        </Tooltip>
                    )}
                </div>
                <Radio
                    labelClassName={styles['radio-label']}
                    value={VIDEO_PRICING_MODEL.CPM}
                    title={
                        <FormattedMessage id="video.campaign.editor.BusinessModel.option.cpm" defaultMessage="CPM" />
                    }
                />
            </RadioGroup>
        </div>
    );
};

BusinessModel.propTypes = {
    account: PropTypes.object,
};

export default BusinessModel;
