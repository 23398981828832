class Init {
    init() {
        window._taboola = window._taboola || [];

        // This script is the loader for the rbox - which allows us to render the creative preview
        const element = document.createElement('script');
        element.async = false;
        element.src = 'https://cdn.taboola.com/libtrc/creativepreview/loader.js';
        element.id = 'tbl_loader_script';

        // This script sets a cookie that ensures we receive production rbox (not expo test version)
        const expoElement = document.createElement('script');
        expoElement.async = false;
        expoElement.onload = () => document.head.appendChild(element);
        expoElement.onerror = () => document.head.appendChild(element);
        expoElement.src = 'https://assets.taboola.com/set-expo-ab?ab=99';
        expoElement.id = 'expo_script';

        document.head.appendChild(expoElement);
    }
}

export default new Init();
