import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const LanguageFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.targeting.language.tooltip"
            defaultMessage="Language targeting lets you target sites of certain languages."
        />
    </TooltipSection>
);

export default LanguageFieldTooltip;
