const SupportedImageType = {
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    BMP: 'bmp',
    WEBMP: 'webmp',
    APNG: 'apng',
    IC0: 'ico',
    CUR: 'cur',
    JFIF: 'jfif',
    PJPEG: 'pjpeg',
    PJP: 'pjp',
    SVG: 'svg',
    TIF: 'tif',
    TIFF: 'tiff',
};

export default SupportedImageType;
