'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var BaseButton = function BaseButton(_ref) {
    var children = _ref.children,
        type = _ref.type,
        buttonType = _ref.buttonType,
        rest = _objectWithoutProperties(_ref, ['children', 'type', 'buttonType']);

    return _react2.default.createElement(
        'button',
        Object.assign({ type: buttonType || type }, rest),
        children
    );
};

BaseButton.propTypes = {
    /** Click callback */
    onClick: _propTypes2.default.func,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Elements inside button tag */
    children: _propTypes2.default.node,
    /** Apply class to style the button */
    className: _propTypes2.default.string,
    /** button tabIndex attribute */
    tabIndex: _propTypes2.default.string,
    /** used for CSS variables */
    style: _propTypes2.default.object,
    /** type in StyledButton */
    type: _propTypes2.default.string,
    /** for button type attribute ie.submit */
    buttonType: _propTypes2.default.string
};

BaseButton.defaultProps = {
    disabled: false,
    tabIndex: '0',
    type: 'button'
};

exports.default = BaseButton;