import { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { Button, ContentCopyOutlinedIcon, IconButton } from 'tuui';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { OutlineEditableText } from '../../../campaigns/components';
import styles from './copyAccountId.module.scss';

export const CopyAccountId = () => {
    const { id: accountId } = useAccount();

    const handleCopyToClipboard = useCallback(() => copy(accountId), [accountId]);

    return (
        <div>
            <FormattedMessage
                id="tracking.setup.shopify.set.up.first.step"
                defaultMessage="Copy your <b>Account ID</b>"
            />
            <OutlineEditableText value={accountId} disabled={true} className={styles['input-wrapper']}>
                <IconButton
                    onClick={handleCopyToClipboard}
                    variant={Button.variant.ghost}
                    className={styles['icon-button']}
                    size={Button.size.sm}
                >
                    <ContentCopyOutlinedIcon fontSize="small" />
                </IconButton>
            </OutlineEditableText>
        </div>
    );
};
