import React from 'react';
import { MovieCreationOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MotionAdsPreviewImages } from './MotionAdsPreviewImages';
import { VideoPreviewImages } from './VideoPreviewImages';

export const PREVIEWS_DRAWER_OPTIONS = {
    MOTION_ADS: 'motion_ads',
    IMAGE_VARIATIONS: 'image_variations',
    REPLACE_BACKGROUND: 'replace_background',
};

export const PREVIEWS_DRAWER_OPTIONS_CONFIGS = {
    MOTION_ADS: {
        id: PREVIEWS_DRAWER_OPTIONS.MOTION_ADS,
        titleText: <FormattedMessage id="previews.drawer.card.title.motion.ads" defaultMessage="Add Motion" />,
        bodyText: (
            <FormattedMessage
                id="previews.drawer.card.body.motion.ads"
                defaultMessage="Transform your image to a short video to drive optimal performance (CVR) with multiple templates."
            />
        ),
        bannerText: (
            <FormattedMessage id="previews.drawer.card.body.motion.ads.banner" defaultMessage="Recommended for You" />
        ),
        cardIcon: <MovieCreationOutlinedIcon />,
        PreviewImages: ({ imageSetNumber }) => {
            return <MotionAdsPreviewImages imageSetNumber={imageSetNumber} />;
        },
        visibilityCondition: ({ shouldRenderMotionAdsOption }) => shouldRenderMotionAdsOption,
    },
    IMAGE_VARIATIONS: {
        id: PREVIEWS_DRAWER_OPTIONS.IMAGE_VARIATIONS,
        titleText: (
            <FormattedMessage
                id="previews.drawer.card.title.image.variations"
                defaultMessage="Diversify with Variations"
            />
        ),
        bodyText: (
            <FormattedMessage
                id="previews.drawer.card.body.image.variations"
                defaultMessage="Effortlessly generate image variations to help fight ad fatigue and improve campaign's diversity."
            />
        ),
        PreviewImages: ({ imageSetNumber }) => {
            return <VideoPreviewImages previewMode="imageVariations" imageSetNumber={imageSetNumber} />;
        },
    },
    REPLACE_BACKGROUND: {
        id: PREVIEWS_DRAWER_OPTIONS.REPLACE_BACKGROUND,
        titleText: (
            <FormattedMessage
                id="previews.drawer.card.title.replace.background"
                defaultMessage="Create Seasonal Background"
            />
        ),
        bodyText: (
            <FormattedMessage
                id="previews.drawer.card.body.replace.background"
                defaultMessage="Add a refreshing touch to your visuals with a new background."
            />
        ),
        PreviewImages: ({ imageSetNumber }) => {
            return <VideoPreviewImages previewMode="replaceBackground" imageSetNumber={imageSetNumber} />;
        },
    },
};
