import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { DEFAULT_SEGMENT_ID } from '../../config';
import { selectedSegmentSelector } from '../../selectors';
import styles from './audienceInsightsReportHeader.module.scss';

const AudienceInsightsReportHeader = () => {
    const { name, id } = useSelector(selectedSegmentSelector);

    return (
        <div className={styles['container']}>
            <FormattedMessage
                className={styles['super-text']}
                id="app.audienceInsights.header.superText"
                defaultMessage="Audience Insight"
            />
            {id === DEFAULT_SEGMENT_ID ? (
                <FormattedMessage
                    className={styles['no-selected-segment']}
                    id="app.audienceInsights.header.noSelectedSegment"
                    defaultMessage="No segment selected"
                />
            ) : (
                <div className={styles['audience-name']}>{name}</div>
            )}
        </div>
    );
};

export default AudienceInsightsReportHeader;
