import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BaseUploader } from './BaseUploader';
import { ZIP_FILE_TYPE, ZIP_MULTIPLE_FILES_INDICATION, ZIP_WRONG_FORMAT_INDICATION } from './consts/uploadConsts';

const dragAndDropDescription = <FormattedMessage id="zip.drag.drop.file" defaultMessage="Drag & drop zip file" />;

export const ZipUpload = props => (
    <BaseUploader
        fileTypes={ZIP_FILE_TYPE}
        dragAndDropDescription={dragAndDropDescription}
        multipleFilesIndication={ZIP_MULTIPLE_FILES_INDICATION}
        wrongFormatIndication={ZIP_WRONG_FORMAT_INDICATION}
        {...props}
    />
);
