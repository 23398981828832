export const STATIC_INDICATION_TYPES = {
    FROZEN_ACCOUNT: 'isFrozen',
    INACTIVE_ACCOUNT: 'isInactive',
    NEAR_CREDIT_LIMIT: 'nearCreditLimit',
    AD_BLOCKER_DETECTED: 'adBlockerDetected',
    CATEGORY_REPORT_MISSING_DATA: 'categoryReportMissingData',
    PARTIAL_REPORT_DATA: 'partialReportData',
    HEAVY_ACCOUNT_REPORT_FRESHNESS: 'heavyAccountReportFreshness',
    NO_ACTIVE_PIXEL: 'noActivePixel',
    NO_ACTIVE_CONVERSIONS: 'noActiveConversions',
    REALTIME_REPORT_DISCLAIMER: 'realtimeReportDisclaimer',
    SITE_MSV2_DISCLAIMER: 'realtimeReportDisclaimer',
    NETWORK_UNAVAILABLE: 'NETWORK_UNAVAILABLE',
    AUDIENCE_LONG_CREATION_TIME: 'audienceLongCreationTimeIndication',
};

export default STATIC_INDICATION_TYPES;
