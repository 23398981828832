import { AUDIENCE_TYPE, MATCH_TYPES } from './audiencesConsts';

export const mrtAudiencesInitialValues = {
    type: AUDIENCE_TYPE.MAIL_DOMAINS,
    matchType: MATCH_TYPES.BROAD,
    lookBackWindow: 30,
    domains: {
        include: [],
        exclude: [],
    },
};

export const srtAudiencesInitialValues = {
    type: AUDIENCE_TYPE.SEARCH_KEYWORDS,
    matchType: MATCH_TYPES.BROAD,
    lookBackWindow: 30,
    keywords: [],
};
