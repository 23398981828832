import { gtmTracker } from '../gtmTracker';

const validateEventsCreators = eventsCreators => {
    if (!eventsCreators || typeof eventsCreators !== 'object') {
        throw Error('eventsCreators should be a defined object');
    }

    Object.keys(eventsCreators).forEach(key => {
        if (typeof eventsCreators[key] !== 'function') {
            throw Error(
                `eventsCreators values should be functions. type of value for '${key}' is '${typeof eventsCreators}'`
            );
        }
    });
};

const analyticsMiddlewareCreator = eventsCreators => {
    validateEventsCreators(eventsCreators);

    return store => next => action => {
        const selectedEventCreator = eventsCreators[action.type];
        if (!selectedEventCreator) {
            next(action);
            return;
        }

        const event = selectedEventCreator(action, store.getState());
        gtmTracker.set(event);

        next(action);
    };
};

export default analyticsMiddlewareCreator;
