import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDimension } from '../../../../../hooks/queryParams';
import { selectedAccountPartnerTypesSelector, selectedAccountSelector } from '../../../../../selectors';
import { campaignsApi } from '../../../../../services/api';
import { hasMultipleCampaignDimensions } from '../../../../../services/campaignDimension';
import { fetchCampaigns } from '../../../flows';
import { campaignsMapSelector } from '../../../selectors';

const useRequestPoolController = () => {
    const dependenciesRef = useRef();
    const setDependencies = useCallback((...dependencies) => {
        const newHash = dependencies.join('-');
        const changed = dependenciesRef.current !== newHash;
        dependenciesRef.current = newHash;

        return changed;
    }, []);

    return setDependencies;
};

// TODO fix useCampaignFetchService to use new fetching methods
const useCampaignsFetchService = selectedAccountOverride => {
    const dispatch = useDispatch();
    const { accountId } = useSelector(selectedAccountSelector) || {};
    const { accountId: accountIdOverride } = selectedAccountOverride || {};
    const selectedAccountId = accountIdOverride || accountId;
    const campaignsMap = useSelector(campaignsMapSelector);
    const selectedAccountPartnerTypes = useSelector(selectedAccountPartnerTypesSelector);
    const [dimension] = useDimension();

    const fetchCampaignsFlow = useCallback(params => dispatch(fetchCampaigns(params)), [dispatch]);
    const setRequestPoolDependencies = useRequestPoolController();

    const loadCampaignsPage = useCallback(
        async ({ page, searchText, pageSize, sort, status, apiCacheKey }) => {
            if (!selectedAccountId) {
                return [[], 0];
            }

            const wasDependenciesChanged = setRequestPoolDependencies(
                selectedAccountId,
                dimension,
                JSON.stringify(sort),
                searchText
            );
            const allowMultiRequestCache = !wasDependenciesChanged;

            const [results, total] = await fetchCampaignsFlow({
                accountName: selectedAccountId,
                searchText,
                page,
                pageSize,
                sort,
                status,
                dimension,
                apiCacheKey,
                allowMultiRequestCache,
            });
            return [results, total];
        },
        [fetchCampaignsFlow, selectedAccountId, dimension, setRequestPoolDependencies]
    );

    const loadCampaignsTotalsByDimension = useCallback(
        async dimension => {
            if (!hasMultipleCampaignDimensions(selectedAccountPartnerTypes)) {
                return null;
            }

            try {
                const { metadata } = await campaignsApi.getCampaigns({
                    accountId: selectedAccountId,
                    page: 1,
                    pageSize: 1,
                    searchText: '',
                    sort: '',
                    dimension,
                });
                return metadata.total;
            } catch (e) {
                return null;
            }
        },
        [selectedAccountId, selectedAccountPartnerTypes]
    );

    const getFullCampaign = useCallback(campaign => campaign && campaignsMap[campaign.id], [campaignsMap]);

    return {
        getFullCampaign,
        loadCampaignsPage,
        loadCampaignsTotalsByDimension,
    };
};

export default useCampaignsFetchService;
