'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.InputGroupWithButton = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Buttons = require('../Buttons');

var _InputGroup = require('./InputGroup');

var _InputGroup2 = _interopRequireDefault(_InputGroup);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'inputGroupWithButton-module__taboola-svg-icon___aFz7U',
    'container': 'inputGroupWithButton-module__container___2kEez',
    'input-group-container': 'inputGroupWithButton-module__input-group-container___2_qW0',
    'button': 'inputGroupWithButton-module__button___1CbvW',
    'disabled': 'inputGroupWithButton-module__disabled___2jjdJ'
};
var InputGroupWithButton = function InputGroupWithButton(_ref) {
    var className = _ref.className,
        inputGroupClassName = _ref.inputGroupClassName,
        buttonProps = _ref.buttonProps,
        inputGroupRef = _ref.inputGroupRef,
        rest = _objectWithoutProperties(_ref, ['className', 'inputGroupClassName', 'buttonProps', 'inputGroupRef']);

    var buttonClassName = buttonProps.className,
        restButtonProps = _objectWithoutProperties(buttonProps, ['className']);

    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(styles['container'], className) },
        _react2.default.createElement(_InputGroup2.default, Object.assign({ className: (0, _classnames2.default)(styles['input-group-container'], inputGroupClassName), ref: inputGroupRef }, rest)),
        _react2.default.createElement(_Buttons.StyledButton, Object.assign({
            className: (0, _classnames2.default)(styles['button'], buttonClassName),
            size: _Buttons.STYLED_BUTTON_SIZE.MEDIUM,
            type: _Buttons.STYLED_BUTTON_TYPE.STRONG
        }, restButtonProps))
    );
};

exports.InputGroupWithButton = InputGroupWithButton;
InputGroupWithButton.propTypes = {
    /** class name for outermost container */
    className: _propTypes2.default.string,
    /** class name for input group */
    inputGroupClassName: _propTypes2.default.string,
    /** props to be passed to styled button */
    buttonProps: _propTypes2.default.object,
    /** ref for the input group */
    inputGroupRef: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.shape({ current: _propTypes2.default.instanceOf(Element) })])
};

InputGroupWithButton.defaultProps = {
    buttonProps: {}
};

exports.default = InputGroupWithButton;