import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SiteExplorationList } from './SiteExplorationList/SiteExplorationList';
import styles from '../commonEditor.module.scss';

export const SiteExplorationField = () => (
    <FormField
        inputId="site-exploration"
        label={<FormattedMessage id="campaign.editor.site.exploration.title" defaultMessage="Site Exploration" />}
        description={
            <FormattedMessage
                id="campaign.editor.site.exploration.description"
                defaultMessage="Select sites will be prioritized for a limited amount of time (X clicks), to explore performance."
            />
        }
        helpText={
            <FormattedMessage
                id="campaign.editor.site.exploration.help.text"
                defaultMessage="Site exploration allows you to manually explore a specific site that you wish to see performing in your campaign. The site will gain traffic by increasing CPC and improving competitiveness."
            />
        }
        containerClass={styles['input']}
    >
        <SiteExplorationList />
    </FormField>
);
