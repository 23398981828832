'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSpinners = require('react-spinners');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'defaultColor': 'var(--blue-5)',
    'taboola-svg-icon': 'spinner__taboola-svg-icon___33tZY',
    'spinner': 'spinner__spinner___1kCzx'
};

/**
 * @deprecated - use tuui Spinner
 */

var Spinner = function Spinner(props) {
    return _react2.default.createElement(
        'div',
        { 'aria-label': 'loading', className: styles['spinner'] + ' ' + props.className },
        _react2.default.createElement(_reactSpinners.MoonLoader, props)
    );
};

Spinner.propTypes = {
    size: _propTypes2.default.number,
    className: _propTypes2.default.string,
    sizeUnit: _propTypes2.default.oneOf(['px', 'em', '%']),
    color: _propTypes2.default.string,
    loading: _propTypes2.default.bool
};

Spinner.defaultProps = {
    size: 60,
    sizeUnit: 'px',
    loading: true,
    color: styles.defaultColor,
    className: ''
};

exports.default = Spinner;