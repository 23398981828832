import React, { forwardRef, useCallback, useEffect } from 'react';
import { FEATURE_FLAGS, useConfigMatch } from '../taboola-common-frontend-modules/account-configurations';
import { config } from '../taboola-common-frontend-modules/authentication';

const HEADER_PARAMS = { 'X-PREVENT-FORCE-REDIRECT': true };
let emptySrc;

export const IframeWrapper = forwardRef((props, ref) => {
    const isHeaderParamsEnabled = useConfigMatch({
        [FEATURE_FLAGS.HYBRID_MODULE_HEADER_PARAMS_ENABLED]: 'true',
    });
    const { src, title, ...rest } = props;

    const fetchPage = useCallback(async () => {
        if (!isHeaderParamsEnabled) {
            return;
        }
        try {
            const response = await fetch(src, { headers: HEADER_PARAMS, responseType: 'document' });
            const iframeEl = ref.current;
            const parsedResponse = await response.text();

            const parsedResponseWithBase = parsedResponse.replace(
                '<head>',
                `<head><base href="${config.getBackstageExternalUrl()}" />`
            );
            iframeEl.contentDocument.open();
            iframeEl.contentDocument.write(parsedResponseWithBase);
            iframeEl.contentDocument.close();
        } catch (error) {}
    }, [src, ref, isHeaderParamsEnabled]);

    useEffect(() => {
        fetchPage();
    }, [fetchPage]);

    return <iframe src={isHeaderParamsEnabled ? emptySrc : src} title={title} ref={ref} {...rest} />;
});
