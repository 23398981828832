import React from 'react';
import {
    addUrlHeadersAndFilter,
    appendExcludeButtons,
    useRBoxListenerEffect,
} from 'modules/taboola-common-frontend-modules/rbox-loader';
import { ExcludeButton, UrlHeader } from '../components/';

const renderUrlHeadersAndExcludeBtns = excludeButtonOnclick => {
    addUrlHeadersAndFilter(UrlHeader);

    appendExcludeButtons(<ExcludeButton onClick={excludeButtonOnclick} />);
};

const useCreativePreviewRBoxRenderListenerEffect = excludeButtonOnclick => {
    useRBoxListenerEffect('render', () => {
        if (excludeButtonOnclick) {
            renderUrlHeadersAndExcludeBtns(excludeButtonOnclick);
        }
    });
};

export default useCreativePreviewRBoxRenderListenerEffect;
