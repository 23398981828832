import React, { Component, createRef } from 'react';
import { identity, isEqual, isNil } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import withCollapsibleCardContextConsumer from 'components/CommonCollapsibleCard/withCollapsibleCardContextConsumer';
import { scrollToElement } from 'modules/taboola-common-frontend-modules/formData/scroll-utils';
import { ValidationService } from 'modules/taboola-common-frontend-modules/validations/services/validationService';
import { getFailedValidation, getValidationIndicationType, getValidationMessage } from '../../utils';
import { withRegisterOnMount } from '../ValidationSection';
import withValidationContextConsumer from './withValidationContextConsumer';

const defaultEventValueGetter = e => e.value;
const emptyObj = {};

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withValidations = (
    InnerComponent,
    {
        validations = [],
        validationTriggerEvents = emptyObj,
        validateOnValueChange = false,
        options = emptyObj,
    } = emptyObj
) => {
    const ComponentWithValidations = class extends Component {
        constructor(props) {
            super(props);

            const eventsWithHandlers = this.createEventsWithHandlers();

            this.state = {
                failedValidation: null,
                eventsWithHandlers,
            };

            this.fallbackValidationService = props.validationService ?? new ValidationService();
            this.mount = true;
        }

        scrollRef = createRef(null);

        memoizedGetFailedValidation = memoizeOne(getFailedValidation);

        get validationService() {
            return (
                this.props.validationService ??
                this.props.validationContext?.validationService ??
                this.fallbackValidationService
            );
        }

        setupValidationInService() {
            const { validationService } = this;
            const { setCollapsed, validationId, validationsModifier } = this.props;
            const getFocusPriorityValue = () => this.scrollRef.current?.getBoundingClientRect().y;
            validationService.registerValidation(validationId, {
                getFocusPriorityValue,
                validations: validationsModifier(validations),
            });
            validationService.onInvalid(this.props.validationId, ({ triggeredManually, isTopPriorityError }) => {
                setCollapsed?.(() => false);
                if (triggeredManually && isTopPriorityError) {
                    scrollToElement(this.scrollRef.current);
                }
            });
            validationService.onInvalid(
                this.props.validationId,
                ({ failedValidation, failedValidationData, forceIgnore }) => {
                    if (forceIgnore || !this.mount) {
                        return;
                    }
                    this.setState({ failedValidation });
                    this.props.onErrorStateChange(
                        failedValidation
                            ? {
                                  ...failedValidation,
                                  ...failedValidationData,
                              }
                            : null
                    );
                }
            );
            validationService.onValid(this.props.validationId, () => {
                if (!this.mount) {
                    return;
                }
                this.setState({ failedValidation: null });
                this.props.onErrorStateChange(null);
            });
        }

        componentDidMount() {
            const { valueToValidate, updateStateOnMount, skipWarningsOnMount } = this.props;
            this.setupValidationInService();
            const showErrorsAsWarnings = !skipWarningsOnMount && !isNil(valueToValidate);
            const shouldTriggerValidations = updateStateOnMount;
            this.validate(valueToValidate, shouldTriggerValidations, showErrorsAsWarnings);
        }

        componentDidUpdate(prevProps) {
            const { valueToValidate, validationsModifierHasChanged } = this.props;
            if (validationsModifierHasChanged) {
                this.validationService.setValidations(this.props.validationsModifier(validations));
            }

            this.validate(valueToValidate, this.shouldValidate(prevProps));
        }

        componentWillUnmount() {
            const { validationId, onValidationUnmount } = this.props;

            this.mount = false;
            if (onValidationUnmount) {
                onValidationUnmount(validationId);
            }
        }

        shouldValidate = prevProps =>
            this.validationWasTriggered(prevProps) ||
            this.validationContextHasChanged(prevProps) ||
            this.validationsModifierHasChanged(prevProps) ||
            this.validationValueHasChanged(prevProps);

        validationWasTriggered = prevProps => {
            const { triggerValidation } = this.props;

            return triggerValidation !== prevProps.triggerValidation;
        };

        validationsModifierHasChanged = prevProps => {
            const { validationsModifier } = this.props;

            return validationsModifier !== prevProps.validationsModifier;
        };

        validationContextHasChanged = prevProps => {
            const { validationContext, valueToValidate } = this.props;

            return valueToValidate && !isEqual(validationContext, prevProps.validationContext);
        };

        validationValueHasChanged = prevProps => {
            const { valueToValidate } = this.props;

            return validateOnValueChange && !isEqual(valueToValidate, prevProps.valueToValidate);
        };

        createEventsWithHandlers = () =>
            Object.keys(validationTriggerEvents).reduce(
                (res, eventName) => ({
                    ...res,
                    [eventName]: e =>
                        this.callEventAndValidate(
                            eventName,
                            e,
                            validationTriggerEvents[eventName].validateEventValue,
                            validationTriggerEvents[eventName].eventValueGetter
                        ),
                }),
                {}
            );

        callEventAndValidate = (name, e, validateEventValue = false, eventValueGetter = defaultEventValueGetter) => {
            const { [name]: event, valueToValidate } = this.props;

            if (event) {
                event(e);
            }

            const validationValue = validateEventValue ? eventValueGetter(e) : valueToValidate;
            this.validate(validationValue);
        };

        validate = (value, triggerValidation = true, treatErrorsAsWarning = false) => {
            const { validationService } = this;
            const { validationId, validationContext } = this.props;
            validationService.applyChange(validationId, value, validationContext, {
                triggerValidation,
                metadata: { treatErrorsAsWarning },
            });
        };

        render() {
            const { failedValidation, eventsWithHandlers } = this.state;
            const {
                innerRef,
                valueToValidate,
                validationsModifier,
                updateStateOnMount,
                onValidationChange,
                onValidationUnmount,
                validationId,
                triggerValidation,
                validationContext,
                onErrorStateChange,
                skipWarningsOnMount,
                onRegisterScrollRef,
                scrollTriggerId,
                clearScrollTriggerId,
                setCollapsed,
                reset,
                validate,
                optionalValidate,
                validationService,
                validationCardRefs,
                formContainerId,
                validationScrollRefs,
                setOptionalValidationNote,
                ...rest
            } = this.props;
            const { removeScrollElement } = options || {};
            const message = getValidationMessage(failedValidation, null, validationContext);
            const indicationType = getValidationIndicationType(failedValidation);

            const innerComponent = (
                <InnerComponent
                    ref={innerRef}
                    indicationType={indicationType}
                    message={message}
                    {...rest}
                    {...eventsWithHandlers}
                />
            );

            return removeScrollElement ? (
                innerComponent
            ) : (
                <div ref={this.scrollRef} name={validationId}>
                    {innerComponent}
                </div>
            );
        }
    };

    ComponentWithValidations.propTypes = {
        innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        valueToValidate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
        onValidationChange: PropTypes.func,
        onValidationUnmount: PropTypes.func,
        validationId: PropTypes.string.isRequired,
        onErrorStateChange: PropTypes.func,
        triggerValidation: PropTypes.number,
        validationContext: PropTypes.object,
        validationsModifier: PropTypes.func,
        updateStateOnMount: PropTypes.bool,
        setCollapsed: PropTypes.func,
    };

    ComponentWithValidations.defaultProps = {
        onValidationChange: () => {},
        onValidationUnmount: () => {},
        onErrorStateChange: () => {},
        updateStateOnMount: false,
        skipWarningsOnMount: false,
        validationsModifier: identity,
    };

    return withValidationContextConsumer(
        withCollapsibleCardContextConsumer(withRegisterOnMount(ComponentWithValidations))
    );
};

export default withValidations;
