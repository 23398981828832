import React from 'react';
import { RemoveRedEyeOutlinedIcon } from 'tuui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './TopicSample.module.scss';

export const TopicSample = ({ handleContentClick }) => {
    return (
        <button className={styles['button']} onClick={handleContentClick} aria-label="Click to view">
            <RemoveRedEyeOutlinedIcon className={styles['icon']} data-testid="eye-icon" aria-label="Eye icon" />
            <FormattedMessage
                id="custom.contextual.editor.topics.preview.articles.samples"
                defaultMessage="Samples"
                values="Samples"
                className={styles['text']}
            />
        </button>
    );
};
