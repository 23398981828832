"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEmail(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M24 7H8a3 3 0 00-3 3v12a3 3 0 003 3h16a3 3 0 003-3V10a3 3 0 00-3-3zM8 6a4 4 0 00-4 4v12a4 4 0 004 4h16a4 4 0 004-4V10a4 4 0 00-4-4H8z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M4.5 11l-.342-.475L16 14.473l11.842-3.948-.04.747v.32L16 15.527 4.158 11.58 4.5 11z",
            fill: "#212832"
        })
    );
}
exports.default = SvgEmail;