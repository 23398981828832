import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelectedAccount } from 'hooks';
import { useNotificationsApi } from 'services/api/notificationsApi';
import { COMMON_FLAGS, useCommonConfig } from '../../../modules/taboola-common-frontend-modules/account-configurations';
import { NOTIFICATION_STATE } from '../config/NotificationState';
import { NOTIFICATION_SUB_TYPES } from '../config/NotificationSubTypes';

export const useNotificationsService = () => {
    const notificationsApi = useNotificationsApi();

    const { [COMMON_FLAGS.NOTIFICATIONS_CENTER_REFECTH_TIME]: refetchInterval } = useCommonConfig([
        COMMON_FLAGS.NOTIFICATIONS_CENTER_REFECTH_TIME,
    ]);

    const [{ accountName }] = useSelectedAccount();
    const queryClient = useQueryClient();

    const { data: { results: notifications = [] } = {}, isLoading } = useQuery(
        ['notifications', accountName],
        () => notificationsApi.readNotifications(accountName),
        { refetchOnWindowFocus: false, refetchInterval }
    );

    const refetch = useCallback(() => {
        queryClient.invalidateQueries([accountName, 'notifications']);
    }, [queryClient, accountName]);

    const removeNotification = notificationId => {
        queryClient.setQueryData(['notifications', accountName], data => ({
            ...data,
            results: data.results.filter(notification => notification.id !== notificationId),
        }));
    };

    const getNotificationStatus = ({ state }) => ({
        isNew: state === NOTIFICATION_STATE.NONE,
        isInactive: state === NOTIFICATION_STATE.INACTIVE,
    });

    return {
        notifications: notifications?.filter(({ subType }) => !!NOTIFICATION_SUB_TYPES[subType]) ?? [],
        removeNotification,
        getNotificationStatus,
        refetch,
        isLoading,
    };
};
