import React, { Children, ReactElement } from 'react';
import styled from 'styled-components';
import { BaseStep, StepProps, ActiveStepType, StepType } from 'tuui/lib/molecules/ProgressStepper/Step';
import { ITheme } from 'tuui/lib/theme';
import { scale, theme } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';
import { IntRange } from 'tuui/lib/utils/typing/number';

export interface ProgressStepperProps {
    activeStepIndex: number;
    children?: ReactElement<StepProps> | ReactElement<StepProps>[];
    progress?: IntRange<0, 100>;
    progressTitle?: string;
    activeStepType?: ActiveStepType;
    hasTail?: boolean;
    className?: string;
    variant?: ProgressStepperVariant;
}

export type ProgressStepperVariant = 'dark' | 'light';

const variantToTheme = (theme: ITheme): Record<ProgressStepperVariant, string> => ({
    light: `--active-step-background: ${theme.palette.base.fill};
            --inactive-step-background: ${theme.palette.base.fill};
            --done-step-background: ${theme.palette.base.fill};
            --warn-step-background: ${theme.palette.base.fill};
            --error-step-background: ${theme.palette.base.fill};
            --active-step-color: ${theme.palette.primary.fill};
            --inactive-step-color: ${theme.palette.grayscale[4]};
            --done-step-color: ${theme.palette.primary.fill};
            --warn-step-color: ${theme.palette.warning.fill};
            --error-step-color: ${theme.palette.error.fill};
            --active-progress-color: ${theme.palette.primary.fill};
            --inactive-progress-color: ${theme.palette.grayscale[4]};
            --done-progress-color: ${theme.palette.primary.fill};
            --warn-progress-color: ${theme.palette.warning.fill};
            --error-progress-color: ${theme.palette.error.fill};
            --text-color: ${theme.palette.base.text};
            --progress-background: ${theme.palette.grayscale[3]};`,
    dark: `--active-step-background: ${theme.palette.base.fill};
            --inactive-step-background: ${theme.palette.base.fill};
            --done-step-background: ${theme.palette.base.fill};
            --warn-step-background: ${theme.palette.base.fill};
            --error-step-background: ${theme.palette.base.fill};
            --active-step-color: ${theme.palette.primary.fill};
            --inactive-step-color: ${theme.palette.grayscale[4]};
            --done-step-color: ${theme.palette.primary.fill};
            --warn-step-color: ${theme.palette.warning.fill};
            --error-step-color: ${theme.palette.error.fill};
            --active-progress-color: ${theme.palette.primary.fill};
            --inactive-progress-color: ${theme.palette.grayscale[4]};
            --done-progress-color: ${theme.palette.primary.fill};
            --warn-progress-color: ${theme.palette.warning.fill};
            --error-progress-color: ${theme.palette.error.fill};
            --text-color: ${theme.palette.base.fill};
            --progress-background: ${theme.palette.base.fill};`,
});

export const ProgressStepper: UIC<ProgressStepperProps> = ({
    activeStepIndex = 0,
    progress = 0,
    progressTitle,
    children,
    activeStepType = 'regular',
    hasTail = false,
    className,
    variant = 'dark',
}) => {
    const stepsCount = Children.toArray(children).length;
    const calculatedActiveStepIndex = Math.max(0, Math.min(activeStepIndex, stepsCount - 1));

    return (
        <ProgressStepperContainer $variant={variant} className={className}>
            {Children.map(children, (child, index) => {
                const isLast = index === stepsCount - 1;
                const calculatedStepType = calculateStepType(index, isLast, calculatedActiveStepIndex, activeStepType);

                return (
                    <BaseStep
                        key={index}
                        isLast={isLast}
                        stepType={calculatedStepType}
                        progress={progress}
                        progressTitle={progressTitle}
                        hasTail={hasTail && index === 0}
                        {...child!.props}
                    />
                );
            })}
        </ProgressStepperContainer>
    );
};

const calculateStepType = (
    index: number,
    isLast: boolean,
    activeStepIndex: number,
    activeStepType: ActiveStepType
): StepType => {
    const isStepActive = index === activeStepIndex;

    if (isLast && isStepActive) {
        return 'done';
    }

    if (isStepActive) {
        return activeStepType === 'regular' ? 'active' : activeStepType;
    }

    return index < activeStepIndex ? 'done' : 'inactive';
};

const ProgressStepperContainer = styled.div<{ $variant: ProgressStepperVariant }>`
    ${({ theme, $variant }) => variantToTheme(theme)[$variant]}

    margin: ${scale('xxl')};
    display: flex;
    align-items: center;
    position: relative;
    font-family: ${theme('typography.font')};
`;
