import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './controlledLoadingBar.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ControlledLoadingBar = ({
    containerClassName,
    loadingHeaderClassName,
    loadingBarClassName,
    loadingMessageClassName,
    value,
    max,
    loadingHeader,
    loadingMessage,
}) => {
    return (
        <div className={classNameBuilder('loading-bar-container', containerClassName)}>
            {loadingHeader ? (
                <div className={classNameBuilder('loading-header', loadingHeaderClassName)}>{loadingHeader}</div>
            ) : null}
            <progress value={value} max={max} className={classNameBuilder('loading-bar', loadingBarClassName)} />
            <div className={classNameBuilder('loading-message', loadingMessageClassName)}>{loadingMessage}</div>
        </div>
    );
};

ControlledLoadingBar.propTypes = {
    durationInSeconds: PropTypes.number,
    loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    loadingHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    containerClassName: PropTypes.string,
    loadingHeaderClassName: PropTypes.string,
    loadingBarClassName: PropTypes.string,
    loadingMessageClassName: PropTypes.string,
    value: PropTypes.number,
    max: PropTypes.number,
};
