import React from 'react';
import { getCampaignStatusType, STATUS_TYPE } from '../../../../config';
import { DISPLAY_PHASES } from '../../services/campaignStatusPhaseService';
import PhaseStatusTooltip from '../Tooltips/PhaseStatusTooltip';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

const VideoCampaignStatusRenderer = props => {
    const {
        data: { campaignStatusTrackerData },
        campaignStatusMessages,
        value: { status, isActive },
    } = props;

    const { phaseMsgIdPrefix } = campaignStatusMessages;
    const { phaseName } = campaignStatusTrackerData || {};

    const phase = getVideoPhase(phaseName, isActive);

    return (
        <StatusCellRenderer
            phase={phase}
            tooltipBody={<PhaseStatusTooltip phase={phase} phaseMsgIdPrefix={phaseMsgIdPrefix} />}
            hideTooltip={!phase}
            {...props}
            {...campaignStatusMessages}
            getStatusType={phase ? getWarningStatusType : getCampaignStatusType}
            value={status}
        />
    );
};

const getWarningStatusType = () => STATUS_TYPE.WARNING;

const getVideoPhase = (phaseName, isActive) => {
    if (DISPLAY_PHASES[phaseName] !== undefined && isActive) {
        return DISPLAY_PHASES[phaseName];
    }
    return DISPLAY_PHASES.EMPTY_PHASE;
};

export default VideoCampaignStatusRenderer;
