import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const BusinessModelFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.BusinessModel.tooltip"
            defaultMessage="This will affect the billing event of the campaign.
                            CPM (cost per one thousand impressions) The amount you pay per one thousand impressions of your campaign items.
                            vCPM (cost per thousand viewable impressions) Calculated by dividing the amount spent by the number of viewable impressions multiplied by 1000.
                            CPCV (cost per completed view) Your campaign will be optimized to achieve a CPCV of X, but you'll pay each time your ad is served. This metric is based on Taboola Completion.
                            CPM Programmatic , CPCV Programmatic  Are used for PMPs only."
        />
    </TooltipSection>
);

export default BusinessModelFieldTooltip;
