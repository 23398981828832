import React, { useState, useMemo } from 'react';
import { DelayMessage } from './BodySection/DelayMessage';
import { OnboardingCTA } from './BodySection/OnboardingCTA';
import { SubStepNavigator } from './BodySection/SubStepNavigator';
import { OnboardingStepDescription } from './OnboardingStepDescription';
import styles from './multiStepDescription.module.scss';

const getNextIncompleteSubStep = subStepData => {
    const nextIncompleteSubStep = subStepData.findIndex(subStep => !subStep.isComplete);
    return nextIncompleteSubStep >= 0 ? nextIncompleteSubStep : 0;
};

export const MultiStepDescription = ({ titleId, subStepData, isCollapsed, delayMsgId }) => {
    const nextIncompleteSubStep = useMemo(() => getNextIncompleteSubStep(subStepData), [subStepData]);
    const [currentSubStepIndex, setCurrentSubStepIndex] = useState(nextIncompleteSubStep);
    const { descriptionId, motivationId, learnMoreLinkId, buttons, isComplete } = subStepData[currentSubStepIndex];
    const [isCTADisabled, setIsCTADisabled] = useState(false);
    const totalSubSteps = subStepData.length;

    const goToNextStep = () => {
        const nextStep = currentSubStepIndex + 1;
        if (nextStep < totalSubSteps) {
            setCurrentSubStepIndex(nextStep);
        }
    };

    const goToPreviousStep = () => {
        const previousStep = currentSubStepIndex - 1;
        if (previousStep >= 0) {
            setCurrentSubStepIndex(previousStep);
        }
    };

    return (
        <div className={styles['container']}>
            <OnboardingStepDescription
                titleId={titleId}
                descriptionId={descriptionId}
                motivationId={motivationId}
                learnMoreLinkId={learnMoreLinkId}
                isCollapsed={isCollapsed}
                isComplete={isComplete}
            />
            <div className={styles['action-section']}>
                {isCTADisabled && delayMsgId ? (
                    <DelayMessage delayMsgId={delayMsgId} className={styles['delay-section']} />
                ) : null}
                <div className={styles['buttons-section']}>
                    <SubStepNavigator
                        currentSubStepIndex={currentSubStepIndex}
                        totalSubSteps={totalSubSteps}
                        onNextStep={goToNextStep}
                        onPreviousStep={goToPreviousStep}
                    />
                    <div className={styles['cta-section']}>
                        {buttons.map(button => (
                            <OnboardingCTA
                                key={button.CTAId ? button.CTAId : button.stepId}
                                delayStateDurationMinutes={button.delayStateDurationMinutes}
                                stepId={button.stepId}
                                CTAId={button.CTAId}
                                variant={button.variant}
                                iconBefore={button.iconBefore}
                                isComplete={button.isComplete}
                                setIsCTADisabled={setIsCTADisabled}
                                onClick={button.onClickCTA}
                                tooltip={button.tooltip}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
