import { ADJACENCY_CONTROL_OPTIONS } from '../../modules/common-campaign-form/config';

const transformAdjacencyControlFromGW = campaign => {
    const { campaignTargeting } = campaign;
    if (!campaignTargeting) {
        return campaign;
    }
    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            adjacencyControl: campaignTargeting.adjacencyControl ? [ADJACENCY_CONTROL_OPTIONS.ADJACENCY_CONTROL] : [],
        },
    };
};
const transformAdjacencyControlToGW = campaign => {
    const { campaignTargeting } = campaign;
    if (campaignTargeting) {
        const { adjacencyControl } = campaignTargeting;
        if (!adjacencyControl) {
            return campaign;
        }

        return {
            ...campaign,
            campaignTargeting: { ...campaignTargeting, adjacencyControl: !!campaignTargeting.adjacencyControl.length },
        };
    } else {
        return campaign;
    }
};

export { transformAdjacencyControlFromGW, transformAdjacencyControlToGW };
