import { DELETE_CREATIVE_ERROR, DELETE_CREATIVE_SUCCESS, REQUEST_DELETE_CREATIVE } from './actionTypes';

export const requestDeleteCreative = () => ({
    type: REQUEST_DELETE_CREATIVE,
});

export const deleteCreativeSuccess = creativeItem => ({
    type: DELETE_CREATIVE_SUCCESS,
    payload: creativeItem,
});

export const deleteCreativeError = error => ({
    type: DELETE_CREATIVE_ERROR,
    payload: error,
});
