export const CarouselIcon = props => (
    <svg width="81" height="33" viewBox="0 0 81 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.25 8.25H5.75C4.92157 8.25 4.25 8.92157 4.25 9.75V23.25C4.25 24.0784 4.92157 24.75 5.75 24.75H19.25C20.0784 24.75 20.75 24.0784 20.75 23.25V9.75C20.75 8.92157 20.0784 8.25 19.25 8.25ZM5.75 7.5C4.50736 7.5 3.5 8.50736 3.5 9.75V23.25C3.5 24.4926 4.50736 25.5 5.75 25.5H19.25C20.4926 25.5 21.5 24.4926 21.5 23.25V9.75C21.5 8.50736 20.4926 7.5 19.25 7.5H5.75Z"
            fill="#212832"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9824 10.875C16.3688 10.875 16.7456 11.043 17.0487 11.3412L17.0509 11.3434L21.3902 15.6827L20.8598 16.213L16.5227 11.8759L16.5216 11.8748C16.3392 11.6958 16.1454 11.625 15.9824 11.625C15.8228 11.625 15.6623 11.692 15.5302 11.8495C15.5254 11.8552 15.5204 11.8608 15.5153 11.8663L11.2264 16.3994L13.4519 18.4758L12.9403 19.0242L9.65408 15.9582C8.93528 15.2876 7.82092 15.2862 7.10046 15.954L4.0393 19.0152L3.50897 18.4848L6.57493 15.4189C6.57799 15.4158 6.58111 15.4128 6.58428 15.4098C7.59272 14.469 9.15727 14.469 10.1657 15.4098L10.678 15.8877L14.9633 11.3584C15.2314 11.0437 15.596 10.875 15.9824 10.875Z"
            fill="#212832"
        />
        <path d="M5.75 22.5H17V23.25H5.75V22.5Z" fill="#212832" />
        <path d="M5.75 20.625H14V21.375H5.75V20.625Z" fill="#212832" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.28127 13.0004C8.85055 13.0004 9.29913 12.5454 9.29913 12.0003C9.29913 11.4552 8.85055 11.0002 8.28127 11.0002C7.71199 11.0002 7.26341 11.4552 7.26341 12.0003C7.26341 12.5454 7.71199 13.0004 8.28127 13.0004ZM10.0491 12.0003C10.0491 12.9666 9.25769 13.7504 8.28127 13.7504C7.30485 13.7504 6.51341 12.9666 6.51341 12.0003C6.51341 11.034 7.30485 10.2502 8.28127 10.2502C9.25769 10.2502 10.0491 11.034 10.0491 12.0003Z"
            fill="#212832"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.5 5.5H31.5C30.3954 5.5 29.5 6.39543 29.5 7.5V25.5C29.5 26.6046 30.3954 27.5 31.5 27.5H49.5C50.6046 27.5 51.5 26.6046 51.5 25.5V7.5C51.5 6.39543 50.6046 5.5 49.5 5.5ZM31.5 4.5C29.8431 4.5 28.5 5.84315 28.5 7.5V25.5C28.5 27.1569 29.8431 28.5 31.5 28.5H49.5C51.1569 28.5 52.5 27.1569 52.5 25.5V7.5C52.5 5.84315 51.1569 4.5 49.5 4.5H31.5Z"
            fill="#212832"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.1432 8.99999C45.6584 8.99999 46.1608 9.22399 46.5649 9.62166L46.5678 9.6245L52.3535 15.4102L51.6464 16.1173L45.8636 10.3345L45.8622 10.3331C45.6189 10.0943 45.3605 9.99999 45.1432 9.99999C44.9304 9.99999 44.7163 10.0894 44.5403 10.2993C44.5338 10.307 44.5272 10.3144 44.5203 10.3217L38.8018 16.3659L41.7692 19.1344L41.0871 19.8656L36.7054 15.7776C35.747 14.8835 34.2612 14.8816 33.3006 15.772L29.2191 19.8535L28.512 19.1464L32.5999 15.0585C32.604 15.0544 32.6081 15.0504 32.6124 15.0465C33.957 13.792 36.043 13.792 37.3876 15.0464L38.0706 15.6837L43.7844 9.64456C44.1419 9.22496 44.628 8.99999 45.1432 8.99999Z"
            fill="#212832"
        />
        <path d="M31.5 24.5H46.5V25.5H31.5V24.5Z" fill="#212832" />
        <path d="M31.5 22H42.5V23H31.5V22Z" fill="#212832" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.875 11.8339C35.6341 11.8339 36.2322 11.2271 36.2322 10.5004C36.2322 9.77365 35.6341 9.16693 34.875 9.16693C34.116 9.16693 33.5179 9.77365 33.5179 10.5004C33.5179 11.2271 34.116 11.8339 34.875 11.8339ZM37.2322 10.5004C37.2322 11.7888 36.1769 12.8339 34.875 12.8339C33.5731 12.8339 32.5179 11.7888 32.5179 10.5004C32.5179 9.21199 33.5731 8.16693 34.875 8.16693C36.1769 8.16693 37.2322 9.21199 37.2322 10.5004Z"
            fill="#212832"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.25 8.25H61.75C60.9216 8.25 60.25 8.92157 60.25 9.75V23.25C60.25 24.0784 60.9216 24.75 61.75 24.75H75.25C76.0784 24.75 76.75 24.0784 76.75 23.25V9.75C76.75 8.92157 76.0784 8.25 75.25 8.25ZM61.75 7.5C60.5074 7.5 59.5 8.50736 59.5 9.75V23.25C59.5 24.4926 60.5074 25.5 61.75 25.5H75.25C76.4926 25.5 77.5 24.4926 77.5 23.25V9.75C77.5 8.50736 76.4926 7.5 75.25 7.5H61.75Z"
            fill="#212832"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71.9824 10.875C72.3688 10.875 72.7456 11.043 73.0487 11.3412L73.0509 11.3434L77.3902 15.6827L76.8598 16.213L72.5227 11.8759L72.5216 11.8748C72.3392 11.6958 72.1454 11.625 71.9824 11.625C71.8228 11.625 71.6623 11.692 71.5302 11.8495C71.5254 11.8552 71.5204 11.8608 71.5153 11.8663L67.2264 16.3994L69.4519 18.4758L68.9403 19.0242L65.6541 15.9582C64.9353 15.2876 63.8209 15.2862 63.1005 15.954L60.0393 19.0152L59.509 18.4848L62.5749 15.4189C62.578 15.4158 62.5811 15.4128 62.5843 15.4098C63.5927 14.469 65.1573 14.469 66.1657 15.4098L66.678 15.8877L70.9633 11.3584C71.2314 11.0437 71.596 10.875 71.9824 10.875Z"
            fill="#212832"
        />
        <path d="M61.75 22.5H73V23.25H61.75V22.5Z" fill="#212832" />
        <path d="M61.75 20.625H70V21.375H61.75V20.625Z" fill="#212832" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.2813 13.0004C64.8505 13.0004 65.2991 12.5454 65.2991 12.0003C65.2991 11.4552 64.8505 11.0002 64.2813 11.0002C63.712 11.0002 63.2634 11.4552 63.2634 12.0003C63.2634 12.5454 63.712 13.0004 64.2813 13.0004ZM66.0491 12.0003C66.0491 12.9666 65.2577 13.7504 64.2813 13.7504C63.3048 13.7504 62.5134 12.9666 62.5134 12.0003C62.5134 11.034 63.3048 10.2502 64.2813 10.2502C65.2577 10.2502 66.0491 11.034 66.0491 12.0003Z"
            fill="#212832"
        />
    </svg>
);
