import { hybridModuleFactory } from '../backstage-content';
import { PERMISSIONS_OPERATORS, BACKSTAGE_CONTENT_PERMISSION } from '../taboola-common-frontend-modules/authentication';

const MODULE_NAME = 'campaign-review';
const externalPath = 'reports/campaigns/campaign-approvals';
const ModuleComponent = hybridModuleFactory(externalPath);
const permissions = {
    permissions: ['CAMPAIGN_ADMIN_EDIT', BACKSTAGE_CONTENT_PERMISSION],
    operator: PERMISSIONS_OPERATORS.AND,
};

export { MODULE_NAME, ModuleComponent, permissions, externalPath };
