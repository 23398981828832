import moment from 'moment-timezone';
import { PHASES, PROGRESS_COLOR } from './config';
import { calculateProgress, getTimeLeftProps } from './serviceUtils';

export const getCreativesABTestStatusProps = ({
    creativeTrafficAllocationABTestEndDate,
    creativeTrafficAllocationABTestStartTime,
}) => {
    const remainingTimeInMs = moment(creativeTrafficAllocationABTestEndDate).diff(moment());
    const totalTimeInMs =
        (creativeTrafficAllocationABTestStartTime &&
            moment(creativeTrafficAllocationABTestEndDate).diff(moment(creativeTrafficAllocationABTestStartTime))) ||
        null;
    const progress = calculateProgress(remainingTimeInMs, totalTimeInMs);
    const timeLeftProps = getTimeLeftProps(remainingTimeInMs);
    return {
        phase: PHASES.AB_TESTING,
        timeLeftProps,
        progress,
        progressBarColor: PROGRESS_COLOR.PRIMARY,
    };
};

export default getCreativesABTestStatusProps;
