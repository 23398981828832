import React from 'react';
import { AudiencesIcon, ArticleIcon } from 'tuui';
import styles from './segmentSizes.module.scss';

export const SegmentSizes = ({ formattedSize, formattedArticles }) => (
    <div className={styles['container']} aria-label="sizes">
        {formattedArticles && (
            <div className={styles['metric-container']} aria-label="articles">
                <ArticleIcon />
                {formattedArticles}
            </div>
        )}
        {formattedSize && (
            <div className={styles['metric-container']} aria-label="size">
                <AudiencesIcon />
                {formattedSize}
            </div>
        )}
    </div>
);
