import React, { useMemo, useState, useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { PaginatedDropdown, BareDropdown, CollapsibleList } from 'taboola-ultimate-ui';
import { COUNTRY_TARGETING_LEVELS } from 'modules/campaigns/modules/common-campaign-form/config';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useResourcesApi } from 'services/api';
import {
    FEATURE_FLAGS,
    useAccountConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormValidatedValue } from '../../../../../../taboola-common-frontend-modules/validations';
import TargetingDropdownBox from '../../TargetingDropdownBox/TargetingDropdownBox';
import TargetingItemDropdownOption from '../TargetingItemDropdownOption/TargetingItemDropdownOption';
import CountryItem from './CountryItem';
import styles from './countryTargeting.module.scss';

const COUNTRY_MSG_ID_PREFIX = 'app.country.code';
const ITEM_HEIGHT = 40;
export const LIST_PLACEHOLDER = [
    {
        label: 'All Countries',
        code: 'ALL',
        id: 'ALL',
    },
];

const ItemOptionRenderer = args => TargetingItemDropdownOption({ ...args, msgIdPrefix: COUNTRY_MSG_ID_PREFIX });

const EMPTY_ARRAY = [];
const TargetingDropdownBoxWithIndication = withIndication(TargetingDropdownBox);
const CountryTargeting = ({ onTargetingChange, countryValidations = EMPTY_ARRAY }) => {
    const { [FEATURE_FLAGS.MAX_EXCLUDE_COUNTRIES]: maxExcludeCountries } = useAccountConfig([
        FEATURE_FLAGS.MAX_EXCLUDE_COUNTRIES,
    ]);
    const { value: targetingType, onChange: onTargetingTypeChange } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: targetingLevel } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargetingLevel',
    });
    const {
        value: selectedCountries,
        onChange: onSelectedCountriesChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
        validations: countryValidations,
    });
    const { getCountries } = useResourcesApi();
    const isCountrySelectionLocked = !!targetingLevel && targetingLevel !== COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY;
    const [rawItems, setItems] = useState([]);
    const items = useMemo(() => rawItems.map(item => item || {}), [rawItems]);
    const itemsByCode = useMemo(() => keyBy(items, 'value'), [items]);
    const selectedCountriesSet = useMemo(() => new Set(selectedCountries), [selectedCountries]);
    const setTargetingType = ({ value }) => {
        onTargetingTypeChange(value);
        onTargetingChange({ type: value, values: selectedCountries });
    };
    const isListFilled = Boolean(selectedCountries.length);
    const selectedItems = useMemo(
        () =>
            isListFilled
                ? selectedCountries.map(countryCode => ({
                      targetingType,
                      countryCode,
                      code: countryCode,
                      label: (itemsByCode[countryCode] || {}).label,
                      id: countryCode,
                  }))
                : LIST_PLACEHOLDER,
        [selectedCountries, itemsByCode, targetingType, isListFilled]
    );
    const updateTargeting = listProcessor => {
        const newList = listProcessor(selectedCountries);
        onSelectedCountriesChange(newList);
        onTargetingChange?.({ type: targetingType, values: newList });
    };
    const addCountry = ({ value: countryCode, group }) => {
        if (group) {
            updateTargeting(list => uniq([...group, ...list]));
        } else {
            updateTargeting(list => [countryCode, ...list]);
        }
    };
    const removeCountry = ({ countryCode }) => {
        updateTargeting(list => list.filter(code => code !== countryCode));
    };
    const clearListOfCountries = () => {
        updateTargeting(() => []);
    };
    const loadCountriesPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getCountries(searchText, page, pageSize);
            const list = results
                .filter(country => {
                    if (country.group) {
                        return !country.group.every(code => selectedCountriesSet.has(code));
                    }
                    return !selectedCountriesSet.has(country.name);
                })
                .map(country => ({
                    value: country.name,
                    label: country.value,
                    code: country.name,
                    group: country.group,
                }));
            return [list, total, results.length - list.length];
        },
        [getCountries, selectedCountriesSet]
    );
    const metadata = useMemo(() => ({ targetingType, msgIdPrefix: COUNTRY_MSG_ID_PREFIX }), [targetingType]);
    const isExcludeReachLimit = useMemo(
        () => selectedItems.length >= maxExcludeCountries && targetingType === 'EXCLUDE',
        [selectedItems, targetingType, maxExcludeCountries]
    );
    return (
        <>
            <TargetingDropdownBoxWithIndication
                itemType={targetingType}
                onSelectItemType={setTargetingType}
                selectedItems={selectedItems}
                itemPlaceholder={
                    <FormattedMessage
                        id="campaign.editor.geo.targeting.countries.placeholder"
                        defaultMessage="Select Country..."
                    />
                }
                onAddItem={addCountry}
                allItems={items}
                itemOptionRenderer={ItemOptionRenderer}
                MainDropDownComponent={PaginatedDropdown}
                mainDropDownComponentProps={{
                    id: 'country-targeting-select',
                    DropdownMenuComponent: BareDropdown,
                    enabledWhileSearching: true,
                    estimatedTotal: 300,
                    pageSize: 300,
                    onOptionsLoaded: setItems,
                    loadPage: loadCountriesPage,
                }}
                itemSelectionDisabled={isCountrySelectionLocked || isExcludeReachLimit}
                ref={scrollRef}
                {...indicationData}
            />
            <CollapsibleList
                items={selectedItems}
                data-automation={!isListFilled && 'placeholder-collapsible-list'}
                ItemComponent={CountryItem}
                itemHeight={ITEM_HEIGHT}
                metadata={metadata}
                deleteItem={isListFilled ? removeCountry : undefined}
                clearItems={isListFilled ? clearListOfCountries : undefined}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.geo.targeting.countries"
                        defaultMessage="Selected countries"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
            />
        </>
    );
};

CountryTargeting.propTypes = {
    onTargetingChange: PropTypes.func,
};

export default CountryTargeting;
