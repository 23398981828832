import { CollapsibleList } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFunnelState } from 'modules/tracking/hooks/useFunnelState';
import { RuleItem } from './RuleItem';
import styles from './funnelRules.module.scss';

export const FunnelRules = ({ funnelId }) => {
    const { rules, isLoading } = useFunnelState(funnelId);
    if (isLoading) {
        return null;
    }

    return (
        <CollapsibleList
            items={rules}
            containerClassName={styles['conversions-list']}
            data-automation="conversion-collapsible-list"
            ItemComponent={RuleItem}
            metadata={{ totalItems: rules?.length }}
            listHeaderTitle={
                <FormattedMessage
                    id="tracking.unip.funnel.setup.conversion.in.funnel.header"
                    defaultMessage="Upper Funnel"
                />
            }
            listFooterTitle={
                <FormattedMessage
                    id="tracking.unip.funnel.setup.conversion.in.funnel.footer"
                    defaultMessage="Lower Funnel"
                />
            }
        />
    );
};
