import React from 'react';
import { ErrorResultsMessages } from './ErrorResultsMessages';
import { SuccessResultsMessages } from './SuccessResultsMessages';

export const BulkUploadResultMessages = ({ responseSummary }) => {
    const { errors, successes } = responseSummary;

    return (
        <div>
            {successes.length > 0 && <SuccessResultsMessages successes={successes} />}
            {errors.length > 0 && <ErrorResultsMessages errors={errors} />}
        </div>
    );
};
