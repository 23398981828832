import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const AttributionSettingsTooltip = () => {
    return (
        <TooltipSection>
            <FormattedMessage
                id="tracking.settings.attribution.settings.tooltip"
                defaultMessage="Define here which touch points in the user journey Taboola will track for actions measurement. Taboola will count actions people take for up to 30 days after clicking your ad, or up to one day after viewing your ad. Note that changing this setting will affect reports from the past 30 days."
            />
        </TooltipSection>
    );
};
