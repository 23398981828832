import React from 'react';
import { Switch } from 'react-router';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ACCOUNT_EDITOR_ROUTE_PATH } from '../config';
import { AccountPage } from './AccountPage';

export const Accounts = () => (
    <Switch>
        <LayerRoute path={ACCOUNT_EDITOR_ROUTE_PATH} render={props => <AccountPage {...props} />} />
    </Switch>
);

export default Accounts;
