import React from 'react';
import formatNumberCompact from '../../../../../campaigns/services/utils/formatNumberCompact';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

const CONTEXTUAL_MIN_SIZE = 100;
const CONTEXTUAL_POSTFIX = 'sizeArticles';
export const MARKETPLACE_MIN_SIZE = 100000;
const MARKETPLACE_POSTFIX = 'size';
const NO_SIZE_DISPLAY = '—';

const getMinSizeAndMsgPostfix = (name = '') => {
    return name.indexOf('Contextual') !== -1
        ? [CONTEXTUAL_MIN_SIZE, CONTEXTUAL_POSTFIX]
        : [MARKETPLACE_MIN_SIZE, MARKETPLACE_POSTFIX];
};

const getSizeContentRenderer = ({ formattedSize, formattedArticles, sizeIdPostfix }) => {
    return (
        <FormattedMessage
            id={`campaign.editor.targeting.audience.details.template.new.${sizeIdPostfix}`}
            defaultMessage="Size: {size}"
            values={{ size: formattedSize, articles: formattedArticles }}
        />
    );
};

const getFormattedSizeAndArticles = ({
    size = 0,
    articles = 0,
    minSize = 100,
    minArticles = 100,
    noScaleSize = 0,
    decimalCount = 2,
}) => {
    // react-intl doesn't implement compact notation for FormattedNumber yet - issue: https://github.com/formatjs/react-intl/issues/1501
    const sizePrefix = size < minSize ? '<' : '';
    const formattedSize =
        size === noScaleSize
            ? NO_SIZE_DISPLAY
            : `${sizePrefix}${formatNumberCompact(Math.max(size, minSize), decimalCount)}`;
    const articlesPrefix = articles < minArticles ? '<' : '';
    const formattedArticles =
        articles === noScaleSize
            ? NO_SIZE_DISPLAY
            : `${articlesPrefix}${formatNumberCompact(Math.max(articles, minArticles), decimalCount)}`;
    return { formattedSize, formattedArticles };
};

export {
    getMinSizeAndMsgPostfix,
    getSizeContentRenderer,
    getFormattedSizeAndArticles,
    CONTEXTUAL_MIN_SIZE,
    CONTEXTUAL_POSTFIX,
};
