'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Tooltip = require('@material-ui/core/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'valueLabelComponent__taboola-svg-icon___1mdL-',
    'tooltip': 'valueLabelComponent__tooltip___18-qj'
};

// TODO: will be fixed in DEV-75464.

var ValueLabelComponent = function ValueLabelComponent(_ref) {
    var children = _ref.children,
        open = _ref.open,
        value = _ref.value;
    return _react2.default.createElement(
        _Tooltip2.default,
        {
            open: open,
            enterTouchDelay: 0,
            placement: 'top',
            title: value,
            classes: { tooltip: styles['tooltip'] }
        },
        children
    );
};

ValueLabelComponent.propTypes = {
    children: _propTypes2.default.element.isRequired,
    open: _propTypes2.default.bool.isRequired,
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]).isRequired
};

exports.default = ValueLabelComponent;