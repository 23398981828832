"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgComfortableList(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 14,
            viewBox: "0 0 16 14",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", { d: "M0 0H7V6H0V0Z", fill: "currentColor" }),
        React.createElement("path", { d: "M0 8H7V14H0V8Z", fill: "currentColor" }),
        React.createElement("path", {
            d: "M9 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H9V0Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H9V4Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9 8H15C15.5523 8 16 8.44772 16 9C16 9.55228 15.5523 10 15 10H9V8Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9V12Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgComfortableList;