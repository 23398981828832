import { REPORT_TYPE } from 'modules/campaigns/config';
import {
    actionsColumnType,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import {
    csvDateHeaderValueGetter,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedUTCDateValueGetter,
    gridNumberFormatter,
    gridUTCDateFormatter,
} from '../../../components';
import PaymentActionsCellRenderer from '../../../components/CellRenderers/PaymentActionsCellRenderer';
import { FormattedMessageHeaderRenderer } from '../../../components/HeaderRenderers';
import * as FIELDS from '../../fields';

const contextReportColumnDefinition = [
    {
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerName: FIELDS.PAYMENT_METHOD.label,
        headerComponentParams: FIELDS.PAYMENT_METHOD.headerComponentParams,
        field: FIELDS.PAYMENT_METHOD.field,
        valueFormatter: FIELDS.PAYMENT_METHOD.valueFormatter,
        cellRendererFramework: FIELDS.PAYMENT_METHOD.cellRendererFramework,
    },
    {
        headerName: FIELDS.BILLING_DATE.label,
        headerValueGetter: FIELDS.BILLING_DATE.headerValueGetter,
        headerComponentParams: FIELDS.BILLING_DATE.headerComponentParams,
        field: FIELDS.BILLING_DATE.field,
        valueFormatter: gridUTCDateFormatter,
        csvValueGetter: formattedUTCDateValueGetter,
        csvHeaderValueGetter: csvDateHeaderValueGetter,
    },
    {
        headerName: FIELDS.PAYMENT_AMOUNT.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.PAYMENT_AMOUNT.headerComponentParams,
        field: FIELDS.PAYMENT_AMOUNT.field,
        cellRendererParams: FIELDS.PAYMENT_AMOUNT.renderParams,
        valueFormatter: gridNumberFormatter,
        type: [numericColumn],
    },
    {
        headerName: FIELDS.FEES.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.FEES.headerComponentParams,
        field: FIELDS.FEES.field,
        cellRendererParams: FIELDS.FEES.renderParams,
        valueFormatter: gridNumberFormatter,
        type: [numericColumn],
    },
    {
        headerName: FIELDS.VAT.label,
        headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: FIELDS.VAT.headerComponentParams,
        field: FIELDS.VAT.field,
        cellRendererParams: FIELDS.VAT.renderParams,
        valueFormatter: gridNumberFormatter,
        type: [numericColumn],
    },
    {
        headerName: FIELDS.PAYMENT_STATUS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.PAYMENT_STATUS.headerComponentParams,
        field: FIELDS.PAYMENT_STATUS.field,
        cellRendererParams: FIELDS.PAYMENT_STATUS.renderParams,
        cellRendererFramework: FIELDS.PAYMENT_STATUS.cellRendererFramework,
        csvValueGetter: FIELDS.PAYMENT_STATUS.csvValueGetter,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        valueGetter: params => params.data,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererFramework: PaymentActionsCellRenderer,
    },
];
export const columnDefs = normalizeColumnDefs(contextReportColumnDefinition, REPORT_TYPE.PAYMENTS);
