import { get } from 'lodash';
import { campaignGroupApi, sharedBudgetApi } from 'services/api';
import { selectedAccountSelector } from '../../../../../selectors';
import { FEATURE_FLAGS } from '../../../../taboola-common-frontend-modules/account-configurations';
import { saveCampaignsGroup as flowSaveCampaignsGroup } from '../../campaigns-group/flows';

const saveCampaignsGroup = (campaignsGroupChanges, rowData, _, __, ___, context) => (dispatch, getState) => {
    const { campaignsGroupId } = rowData;
    const { accountId } = selectedAccountSelector(getState());
    const campaignGroupExtensionEnabled =
        get(context, `selectedAccountConfig.${FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED}`) === 'true';
    const api = campaignGroupExtensionEnabled ? campaignGroupApi : sharedBudgetApi;

    dispatch(flowSaveCampaignsGroup(accountId, campaignsGroupId, campaignsGroupChanges, null, null, api));
};

export default saveCampaignsGroup;
