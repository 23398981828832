import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { CHECKED, UNCHECKED } from 'modules/campaigns/config';
import { selectedRowsSelector } from 'modules/campaigns/selectors';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import BaseCheckboxCellRenderer from './BaseCheckboxCellRenderer';

const CheckboxCellRenderer = props => {
    const { isGridEditDisabled } = useGridContext();
    const {
        data = {},
        isSelected = data.isSelected,
        context: { dispatch },
        colDef: {
            cellRendererParams: { onClick, isDisabled, tooltipContent = () => null },
        },
    } = props;
    const isChecked = Number(isSelected);
    const rowData = useMemo(() => ({ ...data, isSelected: isChecked }), [data, isChecked]);
    const handleClick = useCallback((value, data) => dispatch(onClick(value, data)), [onClick, dispatch]);
    const onSelectCheckbox = useCallback(() => handleClick(CHECKED, [rowData]), [handleClick, rowData]);
    const onUnselectCheckbox = useCallback(() => handleClick(UNCHECKED, [rowData]), [handleClick, rowData]);
    return (
        <BaseCheckboxCellRenderer
            onSelectCheckbox={onSelectCheckbox}
            onUnselectCheckbox={onUnselectCheckbox}
            selected={isChecked}
            selectedValues={[CHECKED]}
            disabled={isGridEditDisabled || (isDisabled && isDisabled(rowData))}
            tooltipContent={tooltipContent(rowData)}
        />
    );
};

CheckboxCellRenderer.propTypes = {
    data: PropTypes.object,
    rowIndex: PropTypes.number,
    id: PropTypes.number,
    isSelected: PropTypes.bool,
    context: PropTypes.object,
    colDef: PropTypes.object,
};

const mapStateToProps = (state, props) => {
    const selectedRows = selectedRowsSelector(state);
    const id = get(props, ['data', 'id']);
    if (id) {
        const isSelected = Boolean(selectedRows[id]);
        return { isSelected };
    }
    return {};
};

export { CheckboxCellRenderer };
export default connect(mapStateToProps)(CheckboxCellRenderer);
