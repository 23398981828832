import { generateBulkCampaignsGroupEditorPath } from 'modules/campaigns/services/utils';
import { navigate } from '../../../../../actions';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { BULK_EDIT_CAMPAIGNS_GROUP } from '../../campaigns-group-form/forms/campaigns-group-bulk-editor/hooks/useBulkEditCampaignsGroupIds';

export const bulkEditCampaignsGroup =
    ({ history, accountId, selectedRowsIds }) =>
    dispatch => {
        const bulkEditCampaignsGroupPath = generateBulkCampaignsGroupEditorPath({
            accountId,
        });
        const pathWithQuery = `${bulkEditCampaignsGroupPath}${mergeQueryParams('', {
            [BULK_EDIT_CAMPAIGNS_GROUP]: selectedRowsIds,
        })}`;

        dispatch(navigate(history, pathWithQuery));
    };
