import { filter } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

export const HIERARCHY_CHILDREN = {
    MAX: 10,
    MIN: 3,
};

const isMinActiveChildren = (value, options, { formMode }) => {
    const shouldValidate = formMode === FORM_MODES.EDIT;
    if (!shouldValidate) {
        return true;
    }
    const { min } = options;
    const activeChildren = filter(value, 'isActive');
    return activeChildren.length >= min;
};
export const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: HIERARCHY_CHILDREN.MIN },
        messageId: 'creative.creator.carousel.cards.min.length',
        defaultMessage: 'Add at least {min} cards.',
        events: ['submit'],
    },
    {
        validationFn: isMinActiveChildren,
        options: { min: HIERARCHY_CHILDREN.MIN },
        messageId: 'creative.creator.carousel.cards.min.active.length',
        defaultMessage: 'Carousel ads should have at least {min} active cards.',
    },
];
