import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './galleryItemDropdownOption.module.scss';

export const GalleryItemDropdownOption = ({ icon: Icon, messageId, defaultMessage }) => {
    return (
        <div className={styles['label-container']}>
            <div className={styles['icon-container']}>
                <Icon className={styles['icon']} />
            </div>
            <FormattedMessage id={messageId} defaultMessage={defaultMessage}>
                {([text]) => <span className={styles['label']}>{text}</span>}
            </FormattedMessage>
        </div>
    );
};
