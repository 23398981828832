"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgImage(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M4 7a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V7zm2 0v10h12V7H6z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M11 10.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm1.763 2.304a1 1 0 011.474 0L19 18H8l4.763-5.196z",
            fill: "#212832"
        })
    );
}
exports.default = SvgImage;