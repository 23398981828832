import { ENTITY } from '../config/routes';
import { transformFromGW, transformToGW, transformVideoFromGw, transformVideoToGW } from '../services/transformers';

const toGWtransformers = {
    [ENTITY.CAMPAIGN_VIDEO]: transformVideoToGW,
    [ENTITY.CAMPAIGN]: transformToGW,
    [ENTITY.CAMPAIGNS_GROUP]: transformToGW,
    [ENTITY.CREATIVE]: transformToGW,
    [ENTITY.CREATIVE_VIDEO]: transformToGW,
    [ENTITY.THUMBNAIL]: transformToGW,
    [ENTITY.RTB_OPEN_EXCHANGE]: transformToGW,
    [ENTITY.PMP_DEAL]: transformToGW,
    [ENTITY.VIDEO_PMP_DEAL]: transformVideoToGW,
};

const fromGWtransformers = {
    [ENTITY.CAMPAIGN_VIDEO]: transformVideoFromGw,
    [ENTITY.CAMPAIGN]: transformFromGW,
    [ENTITY.CAMPAIGNS_GROUP]: transformFromGW,
    [ENTITY.CREATIVE]: transformFromGW,
    [ENTITY.CREATIVE_VIDEO]: transformFromGW,
    [ENTITY.THUMBNAIL]: transformFromGW,
    [ENTITY.RTB_OPEN_EXCHANGE]: transformFromGW,
    [ENTITY.PMP_DEAL]: transformFromGW,
    [ENTITY.VIDEO_PMP_DEAL]: transformVideoFromGw,
};

export { toGWtransformers, fromGWtransformers };
