import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'tuui';
import { withIndication } from 'modules/errors/components/withIndication';
import TargetingDropdownBox from '../../../../campaigns/modules/common-campaign-form/components/TargetingDropdownBox/TargetingDropdownBox';
import TargetingTypes from '../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { isValidTextLength } from '../../CustomContextualTopicsSection/useSearchCustomContextualTopics';
import { TopicsDropdownOption } from '../TopicsDropdown/TopicsDropdownOption';
import NoResultComponent from './NoResultComponent';
import { useCustomContextualTopicsDropdown } from './useCustomContextualTopicsDropdown';
import styles from '../topicsListField.module.scss';

const TargetingDropdownBoxWithValidation = withIndication(TargetingDropdownBox);
const EMPTY_ARRAY = [];

const EMPTY_STRING = '';
export const TopicsListFieldDropdown = ({ indicationData, newSelection, setNewSelection }) => {
    const { value: includedTopics = EMPTY_ARRAY } = useFormFieldValue({
        field: 'terms.include',
    });
    const { value: terms = { include: EMPTY_ARRAY, exclude: EMPTY_ARRAY }, onChange: setTerms } = useFormFieldValue({
        field: 'terms',
    });

    const {
        searchTopics,
        relatedTopics,
        searchInputValue,
        setSearchInputValue,
        onSelectItemType,
        isSearchTopicsLoading,
        isRelatedTopicsLoading,
        targetingTypeOption,
    } = useCustomContextualTopicsDropdown();

    const [dropDownOptions, setDropDownOptions] = useState(EMPTY_ARRAY);
    const [tempSelection, setTempSelection] = useState(EMPTY_ARRAY);

    const isInputValid = searchInputValue && isValidTextLength(searchInputValue);
    const isLoading = isSearchTopicsLoading || isRelatedTopicsLoading;
    const isExcludeTargetingType = TargetingTypes.EXCLUDE === targetingTypeOption;

    const noCustomContextualTopicsOptions = () => {
        // Whenever this method returns null, menuListRenderer won't be rendered.
        if (isLoading) {
            return (
                <div className={styles['spinner']}>
                    <Spinner size={21} />
                </div>
            );
        }

        const noTopics = !relatedTopics.length && !searchTopics.length;
        const noActiveSelection = !includedTopics.length;

        if ((noTopics && noActiveSelection && isInputValid) || (isInputValid && isExcludeTargetingType)) {
            return <></>;
        }
        return null;
    };

    const calculateDropDownOptions = useCallback(() => {
        if (searchInputValue.length) {
            if (searchTopics.length > 0) {
                return searchTopics;
            } else {
                return relatedTopics;
            }
        } else {
            return relatedTopics.length ? relatedTopics : searchTopics;
        }
    }, [relatedTopics, searchInputValue.length, searchTopics]);

    useEffect(() => {
        const calculatedDropDownOptions = calculateDropDownOptions();
        setDropDownOptions(calculatedDropDownOptions);
    }, [calculateDropDownOptions]);

    const customContextualMenuListRenderer = params => (
        <NoResultComponent
            relatedTopics={!!relatedTopics.length}
            searchTopics={!!searchTopics.length}
            isFetching={isLoading}
            isInputValid={isInputValid}
            isExcludeTargetingType={isExcludeTargetingType}
            {...params}
        />
    );

    const customContextualOptionRenderer = ({ data, selectProps }) => (
        <TopicsDropdownOption data={data} selectProps={selectProps} includedTopics={tempSelection || EMPTY_ARRAY} />
    );

    const defaultFilterOptions = useCallback(
        (option, inputValue) => option.label?.toLowerCase().includes(inputValue.toLowerCase()),
        []
    );

    const filterOptions = useCallback(
        (option, inputValue) => {
            if (isExcludeTargetingType || (inputValue.length && searchTopics.length)) {
                return defaultFilterOptions(option, inputValue);
            }
            return !!relatedTopics.length;
        },
        [defaultFilterOptions, isExcludeTargetingType, relatedTopics.length, searchTopics.length]
    );

    const addToTempSelect = useCallback(
        term => {
            tempSelection.includes(term.name)
                ? setTempSelection(tempSelection.filter(t => t !== term.name))
                : setTempSelection([...tempSelection, term.name]);
        },
        [tempSelection]
    );

    const onMenuClose = useCallback(() => {
        setSearchInputValue(EMPTY_STRING);
        const newTerms = { ...terms };
        for (const t of tempSelection) {
            if (isExcludeTargetingType) {
                if (newTerms.exclude) {
                    newTerms.exclude = [t, ...newTerms.exclude];
                } else {
                    newTerms.exclude = [t];
                }
            } else {
                if (newTerms.include && newTerms.include[0]) {
                    newTerms.include = [[t, ...newTerms.include[0]]];
                } else {
                    newTerms.include = [[t]];
                }
            }
        }
        setTerms(newTerms);
        setNewSelection([...tempSelection, ...newSelection]);
        setTempSelection(EMPTY_ARRAY);
    }, [isExcludeTargetingType, newSelection, setNewSelection, setSearchInputValue, setTerms, tempSelection, terms]);

    const handleInputChange = (query, { action }) => {
        if (action !== 'set-value') {
            setSearchInputValue(query);
        }
        return searchInputValue;
    };

    return (
        <TargetingDropdownBoxWithValidation
            itemType={targetingTypeOption}
            itemPlaceholder={
                <FormattedMessage
                    id="custom.contextual.editor.topics.name.placeholder"
                    defaultMessage="Search for topic..."
                />
            }
            onAddItem={addToTempSelect}
            allItems={isExcludeTargetingType ? searchTopics : dropDownOptions}
            itemOptionRenderer={customContextualOptionRenderer}
            isItemsLoading={isLoading}
            onSelectItemType={onSelectItemType}
            data-metrics-component="topics-dropdown"
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            mainDropDownComponentProps={{
                inputValue: searchInputValue,
                onMenuClose,
                onInputChange: handleInputChange,
                noOptionsMessage: noCustomContextualTopicsOptions,
                filterOption: filterOptions,
                menuListRenderer: customContextualMenuListRenderer,
                closeMenuOnSelect: false,
                blurInputOnSelect: false,
                styles: {
                    menu: base => ({
                        ...base,
                        maxHeight: '400px',
                        overflow: 'auto',
                        width: '578px',
                        right: '0',
                    }),
                },
            }}
            {...indicationData}
        />
    );
};
