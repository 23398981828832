import React, { useMemo } from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedNumber } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './lastAddedFund.module.scss';

const dateFormat = 'D MMMM, YYYY';
export const LastAddedFund = ({ currency, dateLastFundAdded, lastAddedAmount }) => {
    const formattedDate = useMemo(() => {
        return moment(dateLastFundAdded).format(dateFormat);
    }, [dateLastFundAdded]);
    return lastAddedAmount ? (
        <FormattedMessage
            id="billingAndPayments.balancePanel.limitBalance.bottom.text"
            defaultMessage="{lastAddedAmount} on {date}"
            values={{
                date: formattedDate,
                lastAddedAmount: (
                    <span className={styles['bold-text']}>
                        <FormattedNumber
                            value={lastAddedAmount}
                            variant="currency"
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    </span>
                ),
            }}
        />
    ) : (
        <FormattedMessage
            id="billingAndPayments.balancePanel.limitBalance.bottom.text.fallback"
            defaultMessage="No funds added"
        />
    );
};
