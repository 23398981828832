'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSwitch = require('react-switch');

var _reactSwitch2 = _interopRequireDefault(_reactSwitch);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'blue': 'var(--blue)',
    'taboola-svg-icon': 'toggleSwitch__taboola-svg-icon___2Gaev',
    'disabled': 'toggleSwitch__disabled___SbwmH'
};


var classNameBuilder = _bind2.default.bind(styles);

/**
 * @deprecated - use ToggleSwitch from tuui
 */
var ToggleSwitch = function ToggleSwitch(_ref) {
    var id = _ref.id,
        className = _ref.className,
        onChange = _ref.onChange,
        disabled = _ref.disabled,
        checked = _ref.checked,
        color = _ref.color,
        offColor = _ref.offColor,
        checkedIcon = _ref.checkedIcon,
        uncheckedIcon = _ref.uncheckedIcon,
        height = _ref.height,
        width = _ref.width,
        rest = _objectWithoutProperties(_ref, ['id', 'className', 'onChange', 'disabled', 'checked', 'color', 'offColor', 'checkedIcon', 'uncheckedIcon', 'height', 'width']);

    var wrapperClassName = classNameBuilder(className, { disabled: disabled });

    return _react2.default.createElement(_reactSwitch2.default, Object.assign({}, rest, {
        id: id,
        className: wrapperClassName,
        onChange: onChange,
        disabled: disabled,
        onColor: color,
        offColor: offColor,
        checked: checked,
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        height: height,
        width: width
    }));
};

ToggleSwitch.propTypes = {
    /** Set as an attribute to the embedded checkbox */
    id: _propTypes2.default.string,
    /** Set as the className of the outer shell of the switch */
    className: _propTypes2.default.string,
    /** Callback function that is fired when the toggle switch is toggled */
    onChange: _propTypes2.default.func,
    /** Will disable the toggle if true */
    disabled: _propTypes2.default.bool,
    /** Toggled if set to true */
    checked: _propTypes2.default.bool,
    /** Toggle's color * */
    color: _propTypes2.default.string,
    /** Toggle's off mode color * */
    offColor: _propTypes2.default.string,
    /** An icon that will be shown on the switch when it is checked. Pass in false if you don't want any icon. */
    checkedIcon: _propTypes2.default.oneOfType([_propTypes2.default.element, _propTypes2.default.bool]),
    /** An icon that will be shown on the switch when it isn't checked. Pass in false if you don't want any icon. */
    uncheckedIcon: _propTypes2.default.oneOfType([_propTypes2.default.element, _propTypes2.default.bool]),
    /** The height of the toggle in pixels */
    height: _propTypes2.default.number,
    /** The width of the toggle in pixels */
    width: _propTypes2.default.number
};

ToggleSwitch.defaultProps = {
    disabled: false,
    checked: false,
    color: styles.blue,
    height: 11,
    width: 21,
    checkedIcon: false,
    uncheckedIcon: false
};

exports.default = ToggleSwitch;