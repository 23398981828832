"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgExclamation(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M7.875 0C3.52576 0 0 3.52576 0 7.875C0 12.2242 3.52576 15.75 7.875 15.75C12.2242 15.75 15.75 12.2242 15.75 7.875C15.75 3.52576 12.2242 0 7.875 0ZM6.51805 3.8768C6.51805 3.25548 7.25368 2.91969 7.875 2.91969C8.49632 2.91969 9.21499 3.25548 9.21499 3.8768L9 8.11148C9 8.7328 8.49632 8.73324 7.875 8.73324C7.25368 8.73324 6.75 8.7328 6.75 8.11148L6.51805 3.8768ZM6.75 12.375V10.125H9V12.375H6.75Z",
            fill: "#E23E57"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M6.52661 3.87234C6.52661 3.25102 7.26224 2.91522 7.88356 2.91522C8.50488 2.91522 9.22355 3.25102 9.22355 3.87234L9.00856 8.10702C9.00856 8.72834 8.50488 8.72878 7.88356 8.72878C7.26224 8.72878 6.75856 8.72834 6.75856 8.10702L6.52661 3.87234ZM6.75856 12.3705V10.1205H9.00856V12.3705H6.75856Z",
            fill: "white"
        })
    );
}
exports.default = SvgExclamation;