import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    STYLED_BUTTON_SIZE,
    StyledButton,
    FilterIcon,
    TooltipV2 as Tooltip,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useReportFiltersStatus } from '../../../hooks/useReportFiltersStatus';
import useConfigFilters from '../../Filters/hooks/useConfigFilters';
import styles from './filterButton.module.scss';

const classNameBuilder = classnames.bind(styles);

const FilterButton = ({ reportConfig }) => {
    const [isFilterActive, setFilterActive] = useReportFiltersStatus();
    const className = classNameBuilder('button', { active: isFilterActive });
    const { permittedFilters } = useConfigFilters(reportConfig);

    return permittedFilters.length ? (
        <StyledButton
            className={className}
            size={STYLED_BUTTON_SIZE.MEDIUM}
            iconBefore={<FilterIcon className={styles['icon']} />}
            onClick={() => setFilterActive(prevStatus => !prevStatus)}
            aria-label={isFilterActive ? 'Disable filters' : 'Enable filters'}
        >
            {!isFilterActive ? (
                <Tooltip arrow interactive position={TOOLTIP_POSITION.BOTTOM_START}>
                    <FormattedMessage
                        id="app.gridToolbar.filterButton.unselected.tooltip"
                        defaultMessage="Add Filters"
                    />
                </Tooltip>
            ) : (
                <Tooltip arrow interactive position={TOOLTIP_POSITION.BOTTOM_START}>
                    <FormattedMessage
                        id="app.gridToolbar.filterButton.selected.tooltip"
                        defaultMessage="Clear Filters"
                    />
                </Tooltip>
            )}
        </StyledButton>
    ) : null;
};

FilterButton.propTypes = {
    isActive: PropTypes.bool,
    reportConfig: PropTypes.object.isRequired,
};

export default FilterButton;
