import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { PaginatedDropdown } from 'taboola-ultimate-ui';
import styles from './dropdownWrapper.module.scss';

const classNameBuilder = classnames.bind(styles);

const DropdownWrapper = ({ metricsAttributes, dropdownWrapperClassName, ...rest }) => (
    <div className={classNameBuilder('dropdown-container', dropdownWrapperClassName)} {...metricsAttributes}>
        <PaginatedDropdown {...rest} style={{ width: '100%' }} />
    </div>
);

DropdownWrapper.propTypes = {
    ...PaginatedDropdown.propTypes,
    metricsAttributes: PropTypes.object,
};

export default DropdownWrapper;
