import React from 'react';
import { get, identity } from 'lodash';
import PropTypes from 'prop-types';
import { CloudinaryImage, ImageContentLoader } from 'taboola-ultimate-ui';
import GraphTooltipMetrics from './GraphTooltipMetrics';
import styles from './creativeGraphTooltip.module.scss';

const defaultFormatter = identity;
const IMG_HEIGHT = 104;
const IMG_WIDTH = 188;

const CreativeGraphTooltip = ({ payload }) => {
    if (!payload || !payload.length) {
        return null;
    }
    const campaignName = get(payload, '0.payload.campaignName', null);
    const url = get(payload, '0.payload.thumbnailUrl', null);

    return (
        <div className={styles['wrapper']}>
            <div className={styles['creative-container']}>
                <CloudinaryImage
                    className={styles['image']}
                    src={url}
                    height={IMG_HEIGHT}
                    width={IMG_WIDTH}
                    sharpen
                    gravity="faces:auto"
                    loadingComponent={<ImageContentLoader height={IMG_HEIGHT} width={IMG_WIDTH} />}
                />
            </div>
            <div className={styles['sub-label']}>{campaignName && `Campaign: ${campaignName}`}</div>
            <GraphTooltipMetrics payload={payload} />
        </div>
    );
};

CreativeGraphTooltip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payload: PropTypes.array,
    labelFormatter: PropTypes.func,
};

CreativeGraphTooltip.defaultProps = {
    labelFormatter: defaultFormatter,
};

export default CreativeGraphTooltip;
