import { isEmpty, truncate, uniqBy } from 'lodash';
import { SORTING_ORDER_TYPES, TaboolaGraph } from 'taboola-ultimate-ui';
import { BarGraphIcon, LineGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { creativesMapSelector } from 'modules/campaigns/selectors';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import CampaignGraphTooltip from '../../../components/Tooltips/CampainGraphTooltip';
import { CreativeLegendTooltip, CreativeLegendTooltipClass } from '../../../components/Tooltips/CreativeGraphTooltip';
import CreativeGraphTooltip from '../../../components/Tooltips/CreativeGraphTooltip';
import { DEFAULT_GRAPH_PALETTE } from '../../../services/dynamicColumnsService';
import * as FIELDS from '../../fields';
import {
    dayWeekMonthGetXAxisFormatter,
    dayWeekMonthXAxisMinTickGap,
    tooltipDateFormatter,
} from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

const barGraph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-creative',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.CTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.VCPM,
        metrics.CPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
        metrics.ROAS_CLICKS,
        metrics.ROAS_VIEWS,
        metrics.CPCV,
        metrics.VIEWABILITY_RATE,
        metrics.COMPLETION_RATE,
        metrics.HUNDRED_PCT_VIEWABILITY_RATE,
        metrics.COMPLETED_VIEWS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.TITLE.field,
    },
    graphTooltip: CreativeGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

const singleMetricGraph = {
    type: GRAPHS.SINGLE_METRIC_LINE,
    endpoint: 'creative-report-by-creative-daily',
    metrics: [
        metrics.CLICKS,
        metrics.IMPRESSIONS,
        metrics.SPENT,
        metrics.VCTR,
        metrics.CTR,
        metrics.VCPM,
        metrics.CPM,
        metrics.AVERAGE_CPC,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.CVR,
        metrics.ROAS,
        metrics.ROAS_CLICKS,
        metrics.ROAS_VIEWS,
        metrics.ACTIONS_CONVERSIONS,
        metrics.ACTIONS_CONVERSIONS_CLICKS,
        metrics.ACTIONS_CONVERSIONS_VIEWS,
        metrics.CONVERSIONS_VALUE,
    ],
    maxAllowedSelectedMetrics: 10,
    collatingKey: 'itemId',
    skipSyncWithGrid: true,
    emptyMetricsMsgIdOverride: 'metrics.empty.compareCreatives',
    numLegendValues: 15,
    getGridDataDependentFilters: reportData => {
        if (!isEmpty(reportData)) {
            return {
                items: reportData.slice(0, 15).map(({ id }) => id),
            };
        }
    },
    defaultSort: [{ field: 'date', order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: FIELDS.DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: dayWeekMonthGetXAxisFormatter,
        toolTipLabelFormatter: tooltipDateFormatter,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
        isSingleYAxisDomain: true,
    },
    getMetrics: ({ graphData: { results }, state }) => {
        const sanitizedResults = uniqBy(results, 'itemId');
        const colors = DEFAULT_GRAPH_PALETTE;
        const creatives = creativesMapSelector(state);
        return sanitizedResults.map((dataPoint, index) => ({
            name: String(dataPoint.itemId),
            label: `[${dataPoint.itemId}] ${truncate(creatives[dataPoint.itemId].title, { length: 50 })}`,
            color: colors[index],
            active: index < 2,
            payload: {
                label: creatives[dataPoint.itemId].title,
                thumbnailUrl: creatives[dataPoint.itemId].thumbnailUrl,
                description: creatives[dataPoint.itemId].description,
                cta: creatives[dataPoint.itemId].cta,
                tooltipClass: CreativeLegendTooltipClass,
                tooltipProps: {
                    position: 'bottom',
                    arrow: false,
                },
            },
        }));
    },
    graphTooltip: CampaignGraphTooltip,
    graphLegendTooltip: CreativeLegendTooltip,
    graphToggleIcon: LineGraphIcon,
    accountConfigurations: {
        [FEATURE_FLAGS.SHOW_COMPARE_CREATIVES_GRAPH]: 'true',
    },
};

export const graph = [barGraph, singleMetricGraph];
