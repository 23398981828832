import { FEATURE_FLAGS } from '../../taboola-common-frontend-modules/account-configurations';
import { useUserPermissionOrFeatureFlagEnabled } from '../../taboola-common-frontend-modules/account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';

export const useYahooExternalAudiencesFF = () =>
    useUserPermissionOrFeatureFlagEnabled('YAHOO_SRT_MRT_AUDIENCE', FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_ALLOWED);

export const useYahooKeywordSuggestionsEnabled = () =>
    useUserPermissionOrFeatureFlagEnabled(
        'ADS_CONSOLE_YAHOO_AUDIENCES_KEYWORD_SUGGESTIONS_ALLOWED',
        FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_KEYWORD_SUGGESTIONS_ALLOWED
    );

export const useYahooDomainSuggestionsEnabled = () =>
    useUserPermissionOrFeatureFlagEnabled(
        'ADS_CONSOLE_YAHOO_AUDIENCES_DOMAIN_SUGGESTIONS_ALLOWED',
        FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_DOMAIN_SUGGESTIONS_ALLOWED
    );
