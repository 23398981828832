'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ListHeader = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icons = require('../../Icons/Icons');

var _Inputs = require('../../Inputs');

var _Buttons = require('../../Buttons');

var _hoc = require('../../hoc');

var _Tooltips = require('../../Tooltips');

var _DelimiterWrapper = require('../../DelimiterWrapper');

var _DelimiterWrapper2 = _interopRequireDefault(_DelimiterWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'listHeader-module__taboola-svg-icon___3KLsC',
    'container': 'listHeader-module__container___3K9Oe',
    'container-top-row': 'listHeader-module__container-top-row___3caPi',
    'total': 'listHeader-module__total___3BiGh',
    'title': 'listHeader-module__title___3zNJ8',
    'buttons': 'listHeader-module__buttons___42SEx',
    'delimiter': 'listHeader-module__delimiter___3y5Kl',
    'container-search-row': 'listHeader-module__container-search-row___3tKtN',
    'search-enabled': 'listHeader-module__search-enabled___2Ek3V',
    'search-button': 'listHeader-module__search-button___1xbTs',
    'search-input': 'listHeader-module__search-input___3pdq7'
};


var classNameBuilder = _bind2.default.bind(styles);

var StyledInput = (0, _hoc.withStylesheet)(_Inputs.SearchInput.defaultInput, styles, _defineProperty({}, _Inputs.SearchInput.defaultInput.classNames.input, 'search-input'), 'StyledInput');

var createButton = function createButton(_ref) {
    var isVisible = _ref.isVisible,
        onClick = _ref.onClick,
        label = _ref.label,
        tooltip = _ref.tooltip,
        buttonClassName = _ref.buttonClassName,
        labelClassName = _ref.labelClassName,
        rest = _objectWithoutProperties(_ref, ['isVisible', 'onClick', 'label', 'tooltip', 'buttonClassName', 'labelClassName']);

    return isVisible && _react2.default.createElement(
        _Buttons.StyledButton,
        Object.assign({
            className: buttonClassName,
            size: _Buttons.STYLED_BUTTON_SIZE.SMALL,
            type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON,
            onClick: onClick
        }, rest),
        _react2.default.createElement(
            'span',
            { className: labelClassName },
            label
        ),
        _react2.default.createElement(
            _Tooltips.TooltipV2,
            null,
            tooltip
        )
    );
};

var ListHeader = exports.ListHeader = function ListHeader(_ref2) {
    var listHeaderTitle = _ref2.listHeaderTitle,
        listHeaderClass = _ref2.listHeaderClass,
        clearItems = _ref2.clearItems,
        clearAllLabel = _ref2.clearAllLabel,
        clearAllLabelTooltip = _ref2.clearAllLabelTooltip,
        copyToClipboard = _ref2.copyToClipboard,
        copyToClipboardLabel = _ref2.copyToClipboardLabel,
        copyToClipboardLabelTooltip = _ref2.copyToClipboardLabelTooltip,
        exportToFile = _ref2.exportToFile,
        exportToFileTooltip = _ref2.exportToFileTooltip,
        totalItems = _ref2.totalItems,
        isSearchable = _ref2.isSearchable,
        searchText = _ref2.searchText,
        setSearchText = _ref2.setSearchText,
        searchPlaceholder = _ref2.searchPlaceholder,
        searchLabelTooltip = _ref2.searchLabelTooltip,
        _ref2$showTotal = _ref2.showTotal,
        showTotal = _ref2$showTotal === undefined ? true : _ref2$showTotal;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        isSearchBoxVisible = _useState2[0],
        setIsSearchBoxVisible = _useState2[1];

    if (!listHeaderTitle) {
        return null;
    }

    var searchIconClassName = classNameBuilder({
        'search-enabled': isSearchBoxVisible
    });

    var searchButton = createButton({
        isVisible: isSearchable,
        buttonClassName: styles['search-button'],
        'aria-label': 'search',
        onClick: function onClick() {
            setIsSearchBoxVisible(!isSearchBoxVisible);
            if (isSearchBoxVisible) {
                setSearchText('');
            }
        },
        label: _react2.default.createElement(_Icons.SearchIcon, null),
        labelClassName: searchIconClassName,
        tooltip: searchLabelTooltip
    });

    var exportToFileButton = createButton({
        isVisible: exportToFile,
        onClick: exportToFile,
        label: _react2.default.createElement(_Icons.ExportIcon, null),
        'aria-label': 'export',
        tooltip: exportToFileTooltip
    });

    var copyToClipboardButton = createButton({
        isVisible: copyToClipboard,
        onClick: copyToClipboard,
        label: copyToClipboardLabel,
        tooltip: copyToClipboardLabelTooltip
    });

    var clearItemsButton = createButton({
        isVisible: clearItems,
        onClick: function onClick() {
            setSearchText('');
            clearItems();
        },
        label: clearAllLabel,
        tooltip: clearAllLabelTooltip
    });

    var searchInput = isSearchBoxVisible && _react2.default.createElement(
        'div',
        { className: styles['container-search-row'] },
        _react2.default.createElement(_Inputs.SearchInput, {
            type: 'text',
            onChange: function onChange(event) {
                return setSearchText(event.target.value);
            },
            placeholder: searchPlaceholder,
            value: searchText,
            autoFocus: true,
            expandable: false,
            InputComponent: StyledInput,
            'aria-label': 'search-input'
        })
    );

    var containerClass = classNameBuilder(listHeaderClass, 'container');

    return _react2.default.createElement(
        'div',
        { className: containerClass },
        _react2.default.createElement(
            'div',
            { className: styles['container-top-row'] },
            listHeaderTitle && _react2.default.createElement(
                'div',
                { className: styles['title'] },
                listHeaderTitle,
                showTotal && _react2.default.createElement(
                    'span',
                    { className: styles['total'] },
                    '(',
                    totalItems,
                    ')'
                )
            ),
            _react2.default.createElement(
                'div',
                { className: styles['buttons'] },
                _react2.default.createElement(
                    _DelimiterWrapper2.default,
                    { delimiterClassName: styles['delimiter'] },
                    searchButton,
                    exportToFileButton,
                    copyToClipboardButton,
                    clearItemsButton
                )
            )
        ),
        searchInput
    );
};

ListHeader.propTypes = {
    /** List header title */
    listHeaderTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** List header class */
    listHeaderClass: _propTypes2.default.string,
    /** Fires when there is a change to the list of items with the updated items list */
    clearItems: _propTypes2.default.func,
    /** Clear all text */
    clearAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Clear all tooltip text */
    clearAllLabelTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Callback on copyToClipboardLabel */
    copyToClipboard: _propTypes2.default.func,
    /** Copy label */
    copyToClipboardLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Copy label tolltip text */
    copyToClipboardLabelTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Total items */
    totalItems: _propTypes2.default.number,
    /** Indicates whether search functionality is enabled */
    isSearchable: _propTypes2.default.bool,
    /** The current search text */
    searchText: _propTypes2.default.string,
    /** Callback function to update the search text */
    setSearchText: _propTypes2.default.func,
    /** Placeholder text for the search input */
    searchPlaceholder: _propTypes2.default.string,
    /** Tooltip text for the search label */
    searchLabelTooltip: _propTypes2.default.string,
    /** Function that exports the items content to a file */
    exportToFile: _propTypes2.default.func,
    /** Tooltip text or node for the export button */
    exportToFileTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Decide if you want to show the total selected number */
    showTotal: _propTypes2.default.bool
};

ListHeader.defaultProps = {
    clearAllLabel: 'Clear All',
    clearAllLabelTooltip: 'Clear All',
    copyToClipboardLabelTooltip: 'Copy All',
    exportToFileTooltip: 'Export to File',
    searchPlaceholder: 'Click to search...',
    searchLabelTooltip: 'Search'
};