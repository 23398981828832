import { toString } from 'lodash';

const sanitizeValue = (value = '') =>
    value
        .replace(/\s\s+/g, ' ')
        .replace(/\s+[. , ! ; ?]/g, match => match.trim())
        .replace(/^\s|\s$/g, '');

const insignificantChangeValidationsFn = (newVal, options, { initialValue }) => {
    if (!newVal || !initialValue || newVal === initialValue) {
        return true;
    }

    return sanitizeValue(newVal) !== sanitizeValue(initialValue);
};

export const insignificantChangeValidations = [
    {
        validationFn: insignificantChangeValidationsFn,
        messageId: 'validations.error.field.general.insignificantChange',
        defaultMessage: 'Invalid change',
    },
];

export const bulkInsignificantChangeValidations = [
    {
        validationFn: (newVal, options, { selectedRowsValues }) =>
            selectedRowsValues.every(selectedRowsValue =>
                insignificantChangeValidationsFn(newVal, null, { initialValue: selectedRowsValue })
            ),
        messageId: 'validations.error.field.general.insignificantChange',
        defaultMessage: 'Invalid change',
    },
];

const sanitizeURLValue = url => toString(url).replace(/\s+/g, '');

const insignificantURLChangeValidationsFn = (url, options, { initialValue }) => {
    if (url === initialValue) {
        return true;
    }
    return sanitizeURLValue(url) !== sanitizeURLValue(initialValue);
};

export const insignificantURLChangeValidations = [
    {
        validationFn: insignificantURLChangeValidationsFn,
        messageId: 'validations.error.field.general.insignificantChange',
        defaultMessage: 'Invalid change',
    },
];

export const bulkInsignificantURLChangeValidations = [
    {
        validationFn: (newVal, options, { selectedRowsValues = [] }) =>
            selectedRowsValues.every(selectedRowsValue =>
                insignificantURLChangeValidationsFn(newVal, null, { initialValue: selectedRowsValue })
            ),
        messageId: 'validations.error.field.general.insignificantChange',
        defaultMessage: 'Invalid change',
    },
];
