import { keys } from 'lodash';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { updateCampaigns } from '../../../../../flows';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { campaignRealtimeReportFooterColumnDefinition } from './footerColumnDefinitions';
import { graph } from './graph';
import { performanceColumns, customColumns, defaultColumns, setupColumns } from './reportPresets';

export const campaignRealtimeReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CAMPAIGN_REALTIME],
    endpoint: 'campaign-realtime-report-by-campaign',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: campaignRealtimeReportFooterColumnDefinition,
    inlineEditPermissions: ['CAMPAIGN_EDIT'],
    searchPlaceholderMsgId: 'app.reports.byCampaign.search.placeholder',
    searchField: FIELDS.CAMPAIGN_NAME.field,
    sortMap: {
        [FIELDS.NAME.field]: FIELDS.CAMPAIGN_NAME.field,
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.STATUS.field]: 'campaignStatus',
    },
    bulkActions: [
        {
            key: BULK_ACTION.PLAY,
            handler:
                ({ accountName, selectedRows }) =>
                dispatch =>
                    dispatch(updateCampaigns(accountName, keys(selectedRows), { isActive: true })),
        },
        {
            key: BULK_ACTION.PAUSE,
            handler:
                ({ accountName, selectedRows }) =>
                dispatch =>
                    dispatch(updateCampaigns(accountName, keys(selectedRows), { isActive: false })),
        },
    ],
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.PLATFORM,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.COUNTRY,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.SITE,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
    ],
};
