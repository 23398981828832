import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Badge } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { recommendationsIndicationSelector } from '../selectors';
import styles from './navigationItemContainer.module.scss';

const classNameBuilder = classnames.bind(styles);
const NavigationItemContent = ({ icon: IconComponent, caption, className }) => {
    const count = useSelector(recommendationsIndicationSelector);
    const hideBadge = !count;
    return (
        <Badge badgeContent={count} type={Badge.TYPE.INFO} hideBadge={hideBadge}>
            {IconComponent ? <IconComponent className={classNameBuilder(styles['icon'], className)} /> : null}
            <FormattedMessage id={caption.id} defaultMessage={caption.default} />
            {!hideBadge && (
                <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} arrow interactive>
                    <FormattedMessage
                        id={`${caption.id}.badge.tooltip`}
                        defaultMessage="You have {count} new recommendations"
                        values={{ count }}
                    />
                </Tooltip>
            )}
        </Badge>
    );
};

NavigationItemContent.propTypes = {
    icon: PropTypes.func,
    caption: PropTypes.object,
    className: PropTypes.string,
};

export default NavigationItemContent;
