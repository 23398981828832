import React, { createContext } from 'react';
//     eslint-disable-next-line no-restricted-syntax
import { IntlProvider } from 'react-intl';
import { useQuery } from 'react-query';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { defaultLocale } from './config/messages';
import { useLocale } from './hooks/useLocale';
import { useParentAppName } from './hooks/useParentAppName';
import { translateTermsByParentApp } from './utils/translateTermsByParentApp';

let messagesCache = {};

export const clearMessagesCache = () => {
    messagesCache = {};
};

export const CustomIntlContext = createContext({});

const getDefaultMessages = async parentAppName => {
    if (messagesCache[defaultLocale]) {
        return messagesCache[defaultLocale];
    }

    const baseMessages = await getBaseMessages(defaultLocale);
    const phraseMessages = await getPhraseMessages(defaultLocale, parentAppName);

    return { ...translateTermsByParentApp(baseMessages, parentAppName), ...phraseMessages };
};

const getBaseMessages = async locale => {
    try {
        const { default: messages } = await import(`./config/messages/messages.${locale}.json`);

        return messages;
    } catch (e) {
        gtmTracker.trackError(Error(`Messages for "${locale}" is not exists default messages`));
    }
};

const getPhraseMessages = async (locale, parentAppName) => {
    try {
        const { default: messages } = await import(`./config/messages/phrase/messages.${locale}.json`);
        if (parentAppName) {
            const { default: appParentChanges } = await import(
                `./config/messages/phrase/messages.${parentAppName}.${locale}.json`
            );
            const translatedAppParentChanges = translateTermsByParentApp(messages, parentAppName);
            return { ...translatedAppParentChanges, ...appParentChanges };
        }
        return messages;
    } catch (e) {
        gtmTracker.trackError(Error(`Messages for "${parentAppName}.${locale} or ${locale}" is not exists in Phrase`));
    }
};

const importMessages = async (locale, parentAppName) => {
    const defaultMessages = await getDefaultMessages(parentAppName);
    const phraseMessage = await getPhraseMessages(locale, parentAppName);

    const mergedMessages = { ...defaultMessages, ...phraseMessage };

    if (!phraseMessage) {
        throw Error(`No messages files found for ${locale}`);
    }

    messagesCache[locale] = mergedMessages;

    return mergedMessages;
};

export const I18nProvider = ({ children, onError }) => {
    const [locale, setLocale] = useLocale();
    const parentAppName = useParentAppName();

    const { data: messages, isLoading } = useQuery(
        ['locale', locale],
        async () => {
            const currentMessages = messagesCache[locale];
            if (currentMessages) {
                return currentMessages;
            }

            let mergedMessages;
            try {
                mergedMessages = await importMessages(locale, parentAppName);
            } catch (e) {
                mergedMessages = await getDefaultMessages(parentAppName);
                setLocale(defaultLocale);
            }

            return mergedMessages;
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!locale,
        }
    );

    return (
        <IntlProvider textComponent="span" locale={locale} messages={messages} onError={onError}>
            {isLoading ? null : children}
        </IntlProvider>
    );
};
