import React, { createContext, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { QueryParamBatcher } from './QueryParamBatcher';

export const QueryParamBatcherContext = createContext({});

export const QueryParamBatcherProvider = ({ children }) => {
    const history = useHistory();
    const batcher = useMemo(() => {
        const newBatcher = new QueryParamBatcher();
        newBatcher.setHistory(history);

        return newBatcher;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <QueryParamBatcherContext.Provider value={batcher}>{children}</QueryParamBatcherContext.Provider>;
};

export const useQueryParamBatcher = () => useContext(QueryParamBatcherContext);
