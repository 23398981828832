import React from 'react';
import { recommendationsIdSelector, recommendationsTypeSelector } from 'modules/recommendations/selectors';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { campaignsApi } from 'services/api';
import { errorMessagesUtils } from 'services/utils';
import { requestSaveCampaign, saveCampaignSuccess, saveCampaignError, updateCreativesCampaignName } from '../actions';
import { fromGWtransformers, toGWtransformers } from './flowsUtils';

const successIndication = {
    message: <FormattedMessage id="campaign.save.success" defaultMessage="Campaign successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="campaign.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const baseSaveCampaign =
    campaignApiCall =>
    ({ accountId, campaignId, changes, onError, onSuccess, entityType }) =>
    async (dispatch, getState) => {
        const campaignChanges = toGWtransformers[entityType](changes);
        const state = getState();
        const recommendationType = recommendationsTypeSelector(state);
        const recommendationId = recommendationsIdSelector(state);

        dispatch(requestSaveCampaign());
        try {
            const response = await campaignApiCall({
                accountId,
                campaignId,
                changes: campaignChanges,
                recommendationType,
                recommendationId,
                entityType,
            });
            dispatch(addIndication(successIndication));
            dispatch(saveCampaignSuccess(fromGWtransformers[entityType](response)));
            dispatch(updateCreativesCampaignName(response));
            if (onSuccess) {
                onSuccess();
            }

            return response;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(saveCampaignError(campaignId, error));
            if (onError) {
                onError(error);
            }

            throw error;
        }
    };

const saveCampaign = baseSaveCampaign(campaignsApi.updateCampaign);
const addToCampaignProperty = baseSaveCampaign(campaignsApi.addToCampaignProperty);
const removeFromCampaignProperty = baseSaveCampaign(campaignsApi.removeFromCampaignProperty);
const replaceCampaignProperty = baseSaveCampaign(campaignsApi.replaceCampaignProperty);

export { saveCampaign, addToCampaignProperty, removeFromCampaignProperty, replaceCampaignProperty };
