export const PHASES = {
    PENDING_REVIEW: 'PENDING_REVIEW',
    LEARNING: 'LEARNING',
    LEARNING_LIMITED: 'LEARNING_LIMITED',
    EXPEDITED_LEARNING: 'EXPEDITED_LEARNING',
    EXTENDED_LEARNING: 'EXTENDED_LEARNING',
    SCALING: 'SCALING',
    EMPTY_PHASE: '',
    MISSING_ADS: 'MISSING_ADS',
    ADS_PENDING_REVIEW: 'ADS_PENDING_REVIEW',
    NO_APPROVED_ADS: 'NO_APPROVED_ADS',
    NO_ACTIVE_ADS: 'NO_ACTIVE_ADS',
    NO_ADS: 'NO_ADS',
    AB_TESTING: 'AB_TESTING',
    CVR_LEARNING: 'CVR_LEARNING',
    CVR_LEARNING_COMPLETE: 'CVR_LEARNING_COMPLETE',
    CVR_LEARNING_LIMITED: 'CVR_LEARNING_LIMITED',
    CVR_RELEARNING: 'CVR_RELEARNING',
    UNDER_PACING: 'UNDER_PACING',
    NOT_SPENDING: 'NOT_SPENDING',
    PAUSED: 'PAUSED',
};

export const GW_PHASES = {
    GETTING_CONVERSIONS: 'GETTING_CONVERSIONS',
    COMPLETED_LEARNING: 'COMPLETED_LEARNING',
    CTR_LEARNING: 'CTR_LEARNING',
    OUT_OF_TRACKER: 'OUT_OF_TRACKER',
};

export const DISPLAY_PHASES = {
    [PHASES.PENDING_REVIEW]: PHASES.PENDING_REVIEW,
    [PHASES.LEARNING]: PHASES.LEARNING,
    [PHASES.LEARNING_LIMITED]: PHASES.LEARNING_LIMITED,
    [PHASES.EXPEDITED_LEARNING]: PHASES.EXPEDITED_LEARNING,
    [PHASES.EXTENDED_LEARNING]: PHASES.EXTENDED_LEARNING,
    [GW_PHASES.CTR_LEARNING]: PHASES.LEARNING,
    [GW_PHASES.GETTING_CONVERSIONS]: PHASES.SCALING,
    [GW_PHASES.COMPLETED_LEARNING]: PHASES.SCALING,
    [GW_PHASES.OUT_OF_TRACKER]: PHASES.EMPTY_PHASE,
    EMPTY_PHASE: PHASES.EMPTY_PHASE,
    [PHASES.MISSING_ADS]: PHASES.MISSING_ADS,
    [PHASES.ADS_PENDING_REVIEW]: PHASES.ADS_PENDING_REVIEW,
    [PHASES.NO_APPROVED_ADS]: PHASES.NO_APPROVED_ADS,
    [PHASES.NO_ACTIVE_ADS]: PHASES.NO_ACTIVE_ADS,
    [PHASES.NO_ADS]: PHASES.NO_ADS,
    [PHASES.CVR_LEARNING]: PHASES.CVR_LEARNING,
    [PHASES.CVR_LEARNING_COMPLETE]: PHASES.CVR_LEARNING_COMPLETE,
    [PHASES.CVR_LEARNING_LIMITED]: PHASES.CVR_LEARNING_LIMITED,
    [PHASES.CVR_RELEARNING]: PHASES.CVR_RELEARNING,
    [PHASES.UNDER_PACING]: PHASES.UNDER_PACING,
    [PHASES.NOT_SPENDING]: PHASES.NOT_SPENDING,
};
