import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import workInProgressSVG from './workInProgress.svg';
import styles from './workInProgrss.module.scss';

const WorkInProgress = () => (
    <div className={styles['container']}>
        <img src={workInProgressSVG} alt="work in progress" />
        <div className={styles['title']}>
            <FormattedMessage id="work.in.progress.title" defaultMessage="Work in progress..." />
        </div>
        <div className={styles['description']}>
            <FormattedMessage
                id="work.in.progress.description"
                defaultMessage="We are currently working on this feature"
            />
        </div>
    </div>
);
export default WorkInProgress;
