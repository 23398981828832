"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgAlert(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 24 24"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M4 12a8 8 0 1116 0 8 8 0 01-16 0zm7 3v2h2v-2h-2zm-.292-7.125a2.999 2.999 0 00-.135 1.28l.317 2.851a1.117 1.117 0 002.22 0l.317-2.851a2.999 2.999 0 00-.135-1.28L13 7h-2l-.292.875z"
        })
    );
}
exports.default = SvgAlert;