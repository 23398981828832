import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';

export const PHRASE = 'phrase';

export const usePhraseParam = queryParamHookFactory(PHRASE, {
    persist: PERSISTENCE_TYPE.MEMORY,
});

export const usePhrase = () => {
    const [phraseBranch] = usePhraseParam();

    return {
        phraseBranch,
    };
};
