'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _Image = require('../Image');

var _TaboolaVideoWatermarkSVG = require('./TaboolaVideoWatermarkSVG');

var _TaboolaVideoWatermarkSVG2 = _interopRequireDefault(_TaboolaVideoWatermarkSVG);

var _Buttons = require('../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'taboolaVideo__taboola-svg-icon___1jlxv',
    'taboola-vid': 'taboolaVideo__taboola-vid___1Gwzl',
    'watermark': 'taboolaVideo__watermark___1wjX3',
    'remove-button': 'taboolaVideo__remove-button___3TQsa'
};


var STATUS = {
    INITIAL: 'INITIAL',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    ACTIVE: 'ACTIVE'
};

var TaboolaVideo = (0, _react.forwardRef)(function (_ref, ref) {
    var renderWatermark = _ref.renderWatermark,
        autoplay = _ref.autoplay,
        className = _ref.className,
        src = _ref.src,
        onCloseButtonClick = _ref.onCloseButtonClick,
        errorComponent = _ref.errorComponent,
        loadingComponent = _ref.loadingComponent,
        status = _ref.status,
        canPlayHandler = _ref.canPlayHandler,
        containerClassName = _ref.containerClassName,
        playVideoOnHover = _ref.playVideoOnHover,
        onLoadedMetadata = _ref.onLoadedMetadata;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        isHovering = _useState2[0],
        setIsHovering = _useState2[1];

    if (status === STATUS.ERROR) {
        return errorComponent;
    }
    if (status === STATUS.LOADING) {
        return loadingComponent;
    }

    var videoClassName = styles['taboola-vid'] + ' ' + className;
    var isRenderWatermark = !isHovering && renderWatermark;

    var handleOnHover = function handleOnHover(event) {
        if (event.target.play && !autoplay) {
            if (playVideoOnHover) {
                event.target.play();
            }
        }
        setIsHovering(true);
    };

    var handleOnHoverEnd = function handleOnHoverEnd(event) {
        if (event.target.pause && !autoplay) {
            event.target.pause();
            // eslint-disable-next-line no-param-reassign
            event.target.currentTime = 0;
        }
        setIsHovering(false);
    };

    return _react2.default.createElement(
        'div',
        {
            className: containerClassName,
            onMouseEnter: function onMouseEnter(event) {
                return handleOnHover(event);
            },
            onFocus: function onFocus(event) {
                return handleOnHover(event);
            },
            onMouseLeave: function onMouseLeave(event) {
                return handleOnHoverEnd(event);
            },
            onBlur: function onBlur(event) {
                return handleOnHoverEnd(event);
            }
        },
        _react2.default.createElement('video', {
            ref: ref,
            className: videoClassName,
            src: src,
            loop: true,
            muted: true,
            autoPlay: autoplay,
            onCanPlay: canPlayHandler,
            onLoadedMetadata: onLoadedMetadata
        }),
        isRenderWatermark && _react2.default.createElement(_TaboolaVideoWatermarkSVG2.default, { className: styles['watermark'] }),
        isHovering && onCloseButtonClick && _react2.default.createElement(_Buttons.StyledButton, {
            iconBefore: 'close',
            size: _Buttons.STYLED_BUTTON_SIZE.SMALL,
            type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON,
            onClick: onCloseButtonClick,
            className: styles['remove-button']
        })
    );
});

TaboolaVideo.propTypes = {
    /** Class added to img element */
    className: _propTypes2.default.string,
    /** The video source */
    src: _propTypes2.default.string,
    /** load event handler */
    onLoad: _propTypes2.default.func,
    /** error event handler */
    onError: _propTypes2.default.func,
    /** To be shown on error state when image is not loaded */
    errorComponent: _propTypes2.default.node,
    /** To be shown when Loading the image */
    loadingComponent: _propTypes2.default.node,
    /** determines whether to play video */
    play: _propTypes2.default.bool,
    /** determines whether to render the Watermark component */
    renderWatermark: _propTypes2.default.bool,
    /** determines whether video will autoplay */
    autoplay: _propTypes2.default.bool,
    /** close button click event handler */
    onCloseButtonClick: _propTypes2.default.func,
    /** status of component - determind what to render */
    status: _propTypes2.default.oneOf(Object.values(STATUS)),
    /** handler that notifies when the video can play */
    canPlayHandler: _propTypes2.default.func,
    /** container class name from parent */
    containerClassName: _propTypes2.default.string,
    playVideoOnHover: _propTypes2.default.bool,
    /** handler to catch metadata */
    onLoadedMetadata: _propTypes2.default.func
};

TaboolaVideo.defaultProps = {
    className: '',
    onLoad: function onLoad() {},
    onError: function onError() {},
    play: false,
    errorComponent: _react2.default.createElement(_Image.ImagePlaceholder, null),
    loadingComponent: _react2.default.createElement(_Image.ImageContentLoader, null),
    renderWatermark: true,
    autoplay: false,
    playVideoOnHover: true,
    canPlayHandler: _lodash2.default,
    onLoadedMetadata: _lodash2.default
};

exports.default = TaboolaVideo;