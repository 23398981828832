import { ACCOUNT_ID } from 'hooks';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { CAMPAIGN_ID } from '../../../hooks';
import { REPORT_ID } from './useSelectedReportId';

export const SEARCH_TERM = 'search';

export const useSearchTerm = queryParamHookFactory(SEARCH_TERM, {
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        queryParams: [REPORT_ID, ACCOUNT_ID, CAMPAIGN_ID],
    },
    keepSingleValue: true,
});
