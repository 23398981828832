import reportsApiCreator from './reportsApiCreator';

const REPORTS_API_CACHE_KEY = 'reportsApiCache';
const CSV_REPORTS_API_CACHE_KEY = 'csvReportsApiCache';

const reportsApi = reportsApiCreator(REPORTS_API_CACHE_KEY);

const csvReportsApi = reportsApiCreator(CSV_REPORTS_API_CACHE_KEY);

export default reportsApi;
export { csvReportsApi };
