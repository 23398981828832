import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './generativeAiDisclaimerModalContent.module.scss';

export const GenerativeAiDisclaimerModalContent = () => {
    return (
        <div className={styles['content']}>
            <span>
                <FormattedMessage
                    id="creative.editor.tab.aiGenerated.disclaimer.part0"
                    defaultMessage="You acknowledge that this functionality, whilst made available via the Taboola platform,
                    uses third-party software tools provided by StabilityAI and OpenAI (the “AI Product”) and your use of
                    the AI Product is subject to StabilityAI’s ‘Terms of Use’ made available here: https://stability.ai/terms-of-use "
                />
                <FormattedMessage
                    id="creative.editor.tab.aiGenerated.disclaimer.part1"
                    defaultMessage="and OpenAI’s ‘Terms of Use’ made available here: https://openai.com/policies/terms-of-use."
                />
            </span>
            <FormattedMessage
                id="creative.editor.tab.aiGenerated.disclaimer.part2"
                defaultMessage="You understand that the AI Product may contain errors and/or bugs, and you use this
                AI Product entirely at your own risk and are advised to safeguard important data, to use caution
                (in particular in relation to the prompts you use) and not to rely in any way on the correct functioning
                or performance of the AI Product and/or accompanying materials."
            />
            <FormattedMessage
                id="creative.editor.tab.aiGenerated.disclaimer.part3"
                defaultMessage="We are not responsible or liable to you in any way whatsoever for the availability
                or accuracy of the AI Product, or the content, products or services the AI Product makes available.
                In particular, you hereby acknowledge and agree that:"
            />
            <ul className={styles['content']}>
                <li>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.part4"
                        defaultMessage='THIS AI PRODUCT, AND ANY UPDATES OR ENHANCEMENTS, ARE PROVIDED "AS IS"
                        WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE.
                        TABOOLA AND ITS AFFILIATES SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES OF MERCHANTABILITY
                        OR SATISFACTORY QUALITY, NON-INFRINGEMENT AND/OR FITNESS FOR A PARTICULAR PURPOSE
                        IN RELATION TO THE AI PRODUCT.'
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.part5"
                        defaultMessage="Any images or other content you generate using the AI Product (“AI Product Content”)
                        shall constitute “Advertiser Content” as defined in the Terms and Conditions in the
                        Digital Advertising Insertion Order agreed between you and Taboola."
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.part6"
                        defaultMessage="You understand that Taboola will not take any steps to check whether such content
                        infringes third party intellectual property or other rights. You are fully responsible for
                        ensuring any AI Product Content you upload or transmit to the Taboola platform complies with all
                        applicable laws (including advertising regulations) and does not infringe the rights
                        (including intellectual property rights) of any third party."
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.part7"
                        defaultMessage="To the fullest extent permissible by law, Taboola shall not be liable to you for
                        any losses, damages, liabilities, claims, demands, actions, penalties, fines, awards, costs and expenses
                        (including reasonable legal and other professional expenses) (“Losses”) that you incur as a result
                        of using this AI Product or any AI Product Content (including where such AI Product Content
                        infringes the intellectual property or other rights of a third party)."
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.part8"
                        defaultMessage="You will indemnify and hold harmless Taboola and its Affiliates from any and all
                        Losses Taboola and/or its affiliates may suffer or incur relating to your use of the AI Product
                        or any AI Product Content, including any third party claims alleging that the AI Product Content
                        you create (whether uploaded to the Taboola platform or otherwise) infringes the intellectual
                        property rights of such third party."
                    />
                </li>
            </ul>
        </div>
    );
};
