import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { EXPLORATION_STATUS } from '../../../../../config/siteConsts';
import { getTimeLeftProps, PROGRESS_COLOR } from '../../../services/campaignStatusPhaseService';
import PhaseStatusTooltip from '../../Tooltips/PhaseStatusTooltip';
import StatusCellRenderer from '../StatusCellRenderer/StatusCellRenderer';
import { useSubSiteStatus } from './useSubSiteStatus';
import styles from './siteStatusCellRenderer.module.scss';

export const SiteStatusCellRenderer = ({ value, data, ...rest }) => {
    const { isPublisherBlocked, explorationStatus } = value;

    const { formatMessage } = useIntl();
    const status =
        isPublisherBlocked !== null
            ? formatMessage({ id: `app.campaigns.site.blocked.status.${isPublisherBlocked}` })
            : null;

    const {
        subStatus: subStatusToPresent,
        showProgressBar,
        progress,
        remainingTimeInMs,
        phaseTooltip,
    } = useSubSiteStatus(data);

    const tooltipBody = phaseTooltip ? (
        <PhaseStatusTooltip
            phase={phaseTooltip}
            phaseMsgIdPrefix="app.campaigns.site.status.subtext"
            containerClassName={styles['help-tooltip-container']}
            headerClassName={styles['help-tooltip-header']}
        />
    ) : null;

    const [timeLeftMessage] = useMemo(() => {
        if (showProgressBar) {
            const { id: timeLeftId, value: timeLeft } = getTimeLeftProps(remainingTimeInMs);

            const timeLeftMessage =
                explorationStatus === EXPLORATION_STATUS.IN_PROGRESS ? (
                    <FormattedMessage
                        id={`app.campaigns.campaign.status.time.left.${timeLeftId}`}
                        values={{ value: timeLeft }}
                    />
                ) : null;
            return [timeLeftMessage];
        }
        return [null];
    }, [showProgressBar, explorationStatus, remainingTimeInMs]);

    return (
        <StatusCellRenderer
            phase={subStatusToPresent}
            value={status}
            tooltipBody={tooltipBody}
            data={data}
            progress={showProgressBar ? progress : null}
            progressBarColor={showProgressBar ? PROGRESS_COLOR.PRIMARY : null}
            timeLeft={showProgressBar ? timeLeftMessage : null}
            helpTooltipClassContainer={styles['help-tooltip-icon-container']}
            {...rest}
        />
    );
};
