import { COLORS } from './primitives';
import { Color, SizeUnit } from './types';

const grayscale = [
    COLORS.White,
    COLORS.Gray100,
    COLORS.Gray200,
    COLORS.Gray300,
    COLORS.Gray400,
    COLORS.Gray500,
    COLORS.Gray600,
    COLORS.Gray700,
    COLORS.Gray800,
    COLORS.Gray900,
    COLORS.Black,
] as const;

export const defaultTheme = {
    icons: {
        styleOverrides: {
            root: {
                fontSize: '2.4rem',
            },
            fontSizeSmall: {
                fontSize: '2rem',
            },
            fontSizeLarge: {
                fontSize: '2.8rem',
            },
        },
    },
    palette: {
        grayscale,
        none: 'transparent' as Color,
        base: {
            fill: grayscale[0],
            stroke: grayscale[0],
            text: grayscale[9],
        },
        primary: {
            fill: COLORS.Green500,
            stroke: COLORS.Green500,
            text: grayscale[0],
        },
        secondary: {
            fill: COLORS.Blue200,
            stroke: COLORS.Blue200,
            text: grayscale[7],
        },
        disabled: {
            fill: grayscale[3],
            stroke: grayscale[4],
            text: grayscale[5],
        },
        success: {
            fill: COLORS.Green500,
            stroke: COLORS.Green500,
            text: COLORS.Green100,
        },
        info: {
            fill: COLORS.Blue200,
            stroke: COLORS.Blue200,
            text: COLORS.White,
        },
        error: {
            fill: COLORS.Red300,
            stroke: COLORS.Red300,
            text: grayscale[10],
        },
        warning: {
            fill: COLORS.Yellow300,
            stroke: COLORS.Yellow300,
            text: grayscale[10],
        },
    },
    typography: {
        font: "'Roboto', 'Open Sans', Arial, sans-serif",
        sizes: {
            md: '12px' as SizeUnit,
            lg: '14px' as SizeUnit,
        },
        weight: {
            light: 300,
            regular: 400,
            regularBolder: 500,
            bold: 700,
        },
    },
    curves: {
        md: '4px' as SizeUnit,
        lg: '6px' as SizeUnit,
        xl: '16px' as SizeUnit,
    },
    sizes: {
        base: 8,
        scales: {
            xxxs: 0.125,
            xxs: 0.25,
            xs: 0.5,
            sm: 1,
            md: 1.5,
            lg: 2,
            xl: 2.5,
            xxl: 3,
            big: 4,
            max: 5,
        },
    },
    transitions: {
        // we don't have any aliases spec for delay in MUI
        delays: {
            instant: '0s',
        },
        duration: {
            shortest: 0.15,
            shorter: 0.2,
            short: 0.25,
            standard: 0.3,
            complex: 0.375,
            enteringScreen: 0.225,
            leavingScreen: 0.195,
        },
        easing: {
            easeInOut: 'ease-in-out',
            easeOut: 'ease-out',
            easeIn: 'ease-in',
            sharp: 'tbd',
        },
    },
} as const;

export type ITheme = typeof defaultTheme;

export type Scale = keyof ITheme['sizes']['scales'];

export type ThemeColor = keyof Omit<ITheme['palette'], 'none' | 'grayscale'>;

export type StatusType = keyof Pick<ITheme['palette'], 'success' | 'error' | 'warning' | 'info'>;
