import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import { trackingApi } from 'services/api';

const emptyList = [];

export const useFetchConversionRules = (conditions, advertiserName, isDirty = false) => {
    const conversionRuleIds = conditions
        ?.map(condition => condition?.subMetric?.propertyValue)
        ?.filter(id => !isNil(id));

    const { data } = useQuery(
        [advertiserName, conversionRuleIds],
        () => trackingApi.getConversionsList(advertiserName, conversionRuleIds),
        {
            enabled: advertiserName && !isNil(conversionRuleIds) && !isDirty,
        }
    );

    if (!conversionRuleIds || !advertiserName) {
        return emptyList;
    }
    return data?.results;
};
