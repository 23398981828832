import { useCallback, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useEventListener } from 'taboola-ultimate-ui';
import { authentificationSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { useConsentApi } from 'services/api';
import { hasPerformanceCookies, getConsent } from './consentUtils';

export const useCookieConsent = () => {
    const isAuthenticated = useSelector(authentificationSelector);
    const [prevPerformanceCookies, setPrevPerformanceCookies] = useState(() => hasPerformanceCookies());
    const { exclude, include } = useConsentApi();

    useEffect(() => {
        if (!isAuthenticated || !getConsent()) {
            return;
        }

        if (hasPerformanceCookies()) {
            include();
            return;
        }

        exclude();
    }, [exclude, include, isAuthenticated]);

    const updateConsent = useCallback(() => {
        const newHasPerformanceCookies = hasPerformanceCookies();

        if (prevPerformanceCookies === newHasPerformanceCookies) {
            return;
        }

        setPrevPerformanceCookies(newHasPerformanceCookies);
        if (newHasPerformanceCookies) {
            include();
            return;
        }

        exclude();
    }, [exclude, include, prevPerformanceCookies]);

    const { mutate } = useMutation(updateConsent);

    useEventListener('tbpConsentSaved', mutate);
};
