import React from 'react';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ExternalLink } from '../../../../../../components';
import { TooltipSection } from '../../../common-campaign-form';

const UrlsFieldTooltip = () => {
    const { formatMessage } = useIntl();
    const href = formatMessage({ id: 'creative.creator.titles.description.link.href' });

    return (
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.urls.help.overview"
                defaultMessage="Landing pages are where your users arrive after they click on your ad."
            />
            <ExternalLink href={href}>
                <FormattedMessage id="creative.creator.urls.help.link" defaultMessage="Landing Page Best Practices" />
            </ExternalLink>
        </TooltipSection>
    );
};

export default UrlsFieldTooltip;
