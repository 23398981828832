import React from 'react';
import PropTypes from 'prop-types';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import FormLayout from '../../../../components/Form/FormLayout';
import CampaignEditorFormBase from '../../../common-campaign-form/CampaignEditorFormBase';
import { videoCampaignDuplicateValues } from '../../../common-campaign-form/config/campaignDuplicateValues';
import { VideoPMPDealEditorContent } from '../../components';

const VideoPMPDealEditor = ({ onCancel }) => (
    <CampaignEditorFormBase onCancel={onCancel} ContentComponent={VideoPMPDealEditorContent} />
);

VideoPMPDealEditor.propTypes = { onCancel: PropTypes.func };

const VideoPMPDealEditorWithFormDataProvider = withFormDataProvider(VideoPMPDealEditor, {
    defaultValuesConfig: videoCampaignDuplicateValues,
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export {
    VideoPMPDealEditorWithFormDataProvider as VideoPMPDealEditor,
    VideoPMPDealEditor as VideoPMPDealEditorUnwrapped,
};
