import { createContext, useContext, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import * as apiCaller from './apiCallerProxy';

const defaultApiCtx = { ...apiCaller };

const ApiContext = createContext(defaultApiCtx);

export const ApiProvider = ApiContext.Provider;

export const useApi = () => {
    const apiCtx = useContext(ApiContext);
    const queryClient = useQueryClient();
    const finalApiCtx = useMemo(() => ({ ...apiCtx, queryClient }), [apiCtx, queryClient]);

    return finalApiCtx;
};

export const createApiHook = apiFactory => () => {
    const api = useApi();
    const apiMethods = useMemo(() => apiFactory(api), [api]);

    return apiMethods;
};
