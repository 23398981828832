import React, { useCallback, useEffect } from 'react';
import { BareDropdown } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownOption } from '../../../../../../../../../components/Dropdown';
import { CTA_OPTIONS, CTA_OPTIONS_NO_NONE } from '../../../../../../../config';
import { useAppInstallFormat } from '../../../../../hooks/useAppInstallFormat';
import useServerCtaData from '../../hooks/useServerCtaData';
import styles from '../contentCta.module.scss';

const dropdownStyles = {
    container: style => ({
        border: styles.dropdownBorder,
        borderRadius: styles.dropdownBorderRadius,
        width: '50%',
        ...style,
    }),
    valueContainer: () => ({
        padding: styles.dropdownPadding,
        display: 'flex',
        alignItems: 'center',
    }),
};

export const ContentCtaDropdown = ({ className }) => {
    const { value, onChange } = useFormFieldValue({ field: 'cta' });
    const isAppInstallFormat = useAppInstallFormat();
    const recommendedCTA = useServerCtaData();

    const handleChange = useCallback(
        ({ value }) => {
            onChange(value);
        },
        [onChange]
    );

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.creator.content.cta.placeholder',
        defaultMessage: 'Click to select',
    });

    useEffect(() => {
        const { defaultCta } = recommendedCTA;

        if (defaultCta) {
            onChange(defaultCta);
        }
    }, [recommendedCTA, onChange]);

    return (
        <BareDropdown
            value={value}
            options={isAppInstallFormat ? CTA_OPTIONS_NO_NONE : CTA_OPTIONS}
            styles={dropdownStyles}
            optionItemRenderer={DropdownOption}
            placeholder={placeholder}
            onChange={handleChange}
            className={className}
            searchable
        />
    );
};
