import React from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import STEPS from '../../../campaigns/modules/common-campaign-form/config/steps';
import { StaticIndications } from '../../../taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from '../../../taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../config/PaymentType';
import { paymentMethodInitialValues } from '../../config/paymentMethodInitialValues';
import { useCardPaymentMethodCreatorState } from '../../hooks/useCardPaymentMethodCreatorState';
import { withPaymentProvider } from '../../providers/payment-providers/PaymentProvider/PaymentProvider';
import BillingBreadcrumbs from '../BillingBreadcrumbs/BillingBreadcrumbs';
import { PaymentCreatorFormButtons } from '../PaymentCreatorFormButtons/PaymentCreatorFormButtons';
import { PaymentMethodAddressSection } from '../PaymentMethodAddressSection/PaymentMethodAddressSection';
import { PaymentMethodCreditCardSection } from '../PaymentMethodCreditCardSection/PaymentMethodCreditCardSection';
import styleConsts from '../../../../globalStyle/styleConsts.module.scss';
import styles from './cardPaymentMethodCreator.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const CardPaymentMethodCreator = ({ onCancel }) => {
    const { submit } = useCardPaymentMethodCreatorState();
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'paymentMethod.creator.title',
    });

    return (
        <FormInitBase loadingOverlayProps={{ loadingOverlayText: 'paymentMethod.please-wait' }}>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={STEPS.FIRST_STEP}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="paymentMethod.creator.paymentMethod.step.label"
                            defaultMessage="Payment Method"
                        />
                    }
                    id="paymentMethod"
                >
                    <StaticIndications />
                    <BillingBreadcrumbs className={styles['breadcrumbs-container']} title={title} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="paymentMethod.creator.CARD" defaultMessage="Credit Card" />}
                            id="creditCard"
                        >
                            <PaymentMethodCreditCardSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="paymentMethod.creator.CARD.billingAddress"
                                    defaultMessage="Billing Address"
                                />
                            }
                            id="billingAddress"
                        >
                            <PaymentMethodAddressSection />
                        </WizardStep>
                        <PaymentCreatorFormButtons onCancel={onCancel} onSubmit={submit} />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const CardPaymentMethodCreatorWithFormDataProvider = withPaymentProvider(
    withFormDataProvider(CardPaymentMethodCreator, {
        defaultValuesConfig: paymentMethodInitialValues,
        formContainerId: Wizard.WIZARD_CONTAINER_ID,
        isNetworkOwnerAllowed: true,
    }),
    { paymentMethodType: PAYMENT_TYPE.CARD }
);

export { CardPaymentMethodCreatorWithFormDataProvider as CardPaymentMethodCreator };
