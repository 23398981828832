import React, { useEffect, useState } from 'react';
import { random } from 'lodash';
import PropTypes from 'prop-types';
import { ControlledLoadingBar } from './ControlledLoadingBar';

export const LoadingBar = ({ durationInSeconds, ...rest }) => {
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    useEffect(() => {
        const randomInterval = random(0.1, 2.0);
        const progressTimer = setTimeout(() => {
            if (secondsElapsed < durationInSeconds) {
                setSecondsElapsed(seconds => seconds + randomInterval);
            }
        }, randomInterval * 1000);

        return () => clearTimeout(progressTimer);
    }, [durationInSeconds, secondsElapsed]);

    return <ControlledLoadingBar value={secondsElapsed} max={durationInSeconds} {...rest} />;
};

LoadingBar.propTypes = {
    durationInSeconds: PropTypes.number,
    ...ControlledLoadingBar.propTypes,
};
