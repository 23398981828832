import React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import { favoriteMedia } from 'modules/campaigns/flows';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { BaseGalleryItemDropdown } from '../../GalleryItemDropdown/BaseGalleryItemDropdown';
import { GALLERY_ITEM_DROPDOWN_OPTIONS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import styles from './imageVariationsItemDropdown.module.scss';

export const ImageVariationsItemDropdown = ({ onGenerateVariations = noop, ...rest }) => {
    const { galleryItemDropdownOptions, accountId, mode, setMode, campaignId } = useCreativeStudioContext();
    const dispatch = useDispatch();
    const eventsLocationPrefix = CREATIVE_STUDIO_MODE_EVENTS_MAP[mode];

    const onClick = async (selectedOption, { itemUrl, isFavorited }) => {
        if (selectedOption === GALLERY_ITEM_DROPDOWN_OPTIONS.SAVE_TO_MEDIA_LIBRARY) {
            dispatch(favoriteMedia({ accountId, thumbnailUrl: itemUrl, isFavorited }));
        } else if (selectedOption === GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS) {
            onGenerateVariations();
        } else {
            setMode(selectedOption);
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${eventsLocationPrefix}: ${selectedOption} Option Clicked`,
            taboolaCampaignId: campaignId,
            value: `Item URL: ${itemUrl}`,
        });
    };

    return (
        <BaseGalleryItemDropdown
            containerClassName={styles['container']}
            onClick={onClick}
            options={galleryItemDropdownOptions}
            eventsLocationPrefix={eventsLocationPrefix}
            {...rest}
        />
    );
};
