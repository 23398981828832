import { audienceInsightsApi } from '../../../services/api';
import { requestFetchSegments, fetchSegmentsSuccess, fetchSegmentsError } from '../actions';

const PAGE_SIZE = 20;

const fetchSegments =
    ({ accountId, searchText, page, pageSize = PAGE_SIZE, sort }) =>
    async dispatch => {
        dispatch(requestFetchSegments());
        try {
            const { results, metadata } = await audienceInsightsApi.getSegments({
                accountId,
                searchText,
                page,
                pageSize,
                sort,
            });

            dispatch(fetchSegmentsSuccess({ results, metadata }));
            return [results, metadata.total];
        } catch (error) {
            dispatch(fetchSegmentsError(error));
            return [error];
        }
    };

export default fetchSegments;
