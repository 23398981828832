import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './realtimeConversionsNote.module.scss';

export const RealtimeConversionsNote = () => (
    <div className={styles['conversionsNote']}>
        <FormattedMessage
            id="app.campaigns.reports.columnPicker.realtimeConversions.note"
            defaultMessage="Please be aware that we only display conversions with real-time performance data."
        />
    </div>
);
