import React, { useEffect, useLayoutEffect, useState, useCallback, useMemo } from 'react';
import { isEmpty, xorWith, noop } from 'lodash';
import { CollapsibleList, useDebouncedValue } from 'taboola-ultimate-ui';
import { useComponentStatus } from 'hooks';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { useFormValidatedValue } from '../../../../../taboola-common-frontend-modules/validations';
import { appInstallOSTargetingValidations } from '../../../../config/validations/campaign/osTargetingValidations';
import TargetingDropdownBox from '../TargetingDropdownBox/TargetingDropdownBox';
import { useTreeState } from '../Tree';
import OsTargetingDropdown from './OsTargetingDropdown/OsTargetingDropdown';
import OsTargetingListItem from './OsTargetingListItem/OsTargetingListItem';
import { OsTreeProvider } from './OsTreeContext';
import { useOsTreeData } from './hooks';
import styles from './osTargeting.module.scss';

const ITEM_HEIGHT = 40;
export const LIST_PLACEHOLDER = [
    {
        id: 'ALL',
        taxonomy: 'ALL',
    },
];

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const isEqualsLeaves = (a, b) => a.id === b.id && a.osFamily === b.osFamily;
const OsTargeting = ({ valuesToFilter }) => {
    const [searchText, setSearchText] = useState('');
    const { formatMessage } = useIntl();
    const search = useDebouncedValue(searchText);
    const { value: marketingObjective } = useFormFieldValue({
        field: 'marketingObjective',
    });

    const { value: campaignOsTargetingType, onChange: onChangeSelectedTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.osTargeting.type',
    });
    const {
        value: selectedValues,
        onChange: onChangeSelectedValues,
        indicationData,
    } = useFormValidatedValue({
        field: 'campaignTargeting.osTargeting.values',
        validations: appInstallOSTargetingValidations,
        validationDependencies: { marketingObjective },
    });

    const {
        status: dataStatus,
        valuesToLeaves,
        leavesToValues,
        getTaxonomyValue,
        selectedValuesForSmartList,
        ...treeStateParams
    } = useOsTreeData({ search, selectedValues, valuesToFilter });

    const {
        selectedLeaves,
        handleRemoveItem,
        handleRemoveAllItems,
        handleReload,
        setSelectedLeavesByIds,
        handleSelectNode,
        handleSelectAll,
        expandAll,
        ...treeProps
    } = useTreeState({
        search,
        getTaxonomyValue,
        formatMessage,
        ...treeStateParams,
    });

    const { status, setStatus } = useComponentStatus(dataStatus);

    const handleRemoveItemWrapper = useCallback(
        ({ id, osFamily }) => {
            handleSelectNode(osFamily, id, false);
        },
        [handleSelectNode]
    );

    // flow of initial load, pagination, search and providers change
    useLayoutEffect(() => {
        handleReload();
    }, [handleReload]);

    // flow to init selectedLeaves from saved campaign
    useEffect(() => {
        if (dataStatus !== COMPONENT_STATUS.ACTIVE || status === COMPONENT_STATUS.ACTIVE) {
            return;
        }

        const transformedSelectedValues = valuesToLeaves(selectedValues);
        setSelectedLeavesByIds(transformedSelectedValues.map(({ id }) => id));
        setStatus(COMPONENT_STATUS.ACTIVE);
    }, [dataStatus, selectedValues, setSelectedLeavesByIds, setStatus, status, valuesToLeaves]);

    // flow to sync selectedLeaves with osTargetingValues
    useEffect(() => {
        if (status !== COMPONENT_STATUS.ACTIVE) {
            return;
        }

        const selectedValuesFromTree = leavesToValues(selectedLeaves);
        const needToUpdateField = xorWith(selectedValuesFromTree, selectedValues, isEqualsLeaves).length;
        if (needToUpdateField) {
            onChangeSelectedValues(selectedValuesFromTree);
        }
    }, [leavesToValues, onChangeSelectedValues, selectedLeaves, selectedValues, status]);

    // expand all in case of search change and not empty
    useEffect(() => {
        if (!isEmpty(search)) {
            expandAll();
        }
    }, [search, expandAll]);

    const metadata = useMemo(() => ({ targetingType: campaignOsTargetingType }), [campaignOsTargetingType]);
    const isListFilled = Boolean(selectedValuesForSmartList.length);
    return (
        <>
            <OsTreeProvider handleSelectNode={handleSelectNode} handleSelectAll={handleSelectAll} {...treeProps}>
                <TargetingDropdownBox
                    onAddItem={noop}
                    itemType={campaignOsTargetingType}
                    onSelectItemType={({ value }) => onChangeSelectedTargetingType(value)}
                    selectedItems={selectedValuesForSmartList}
                    MainDropDownComponent={OsTargetingDropdown}
                    mainDropDownComponentProps={{
                        search: searchText,
                        onSearchChange: text => setSearchText(text),
                    }}
                />
            </OsTreeProvider>
            <CollapsibleListWithIndication
                items={isListFilled ? selectedValuesForSmartList : LIST_PLACEHOLDER}
                data-automation={!isListFilled && 'placeholder-collapsible-list'}
                ItemComponent={OsTargetingListItem}
                itemHeight={ITEM_HEIGHT}
                deleteItem={isListFilled ? handleRemoveItemWrapper : undefined}
                clearItems={isListFilled ? handleRemoveAllItems : undefined}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.os.title.selected"
                        defaultMessage="Selected Operating Systems"
                    />
                }
                metadata={metadata}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </>
    );
};

OsTargeting.propTypes = {};

export default OsTargeting;
