import React, { useLayoutEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { get, noop } from 'lodash';
import { MediaGalleryItem } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import styles from './galleryItem.module.scss';

const classNameBuilder = classnames.bind(styles);
const NullComponent = () => null;

export const GalleryItem = ({
    image,
    selected,
    addImageHandler,
    getItemMetricsAttributes = noop,
    GalleryItemDropdown = NullComponent,
    showLargePreview = true,
    containerClassName,
    itemDropdownMenuStyle,
    shouldHideOnError,
    onImageError = noop,
    shouldHideDropdown = false,
    ...rest
}) => {
    const url = get(image, 'url');
    const imageId = get(image, 'id');
    const [hasImageError, setHasImageError] = useState(false);

    const isGenAiEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });

    // reset error when component is re-rendered with new image
    useLayoutEffect(() => setHasImageError(false), [imageId]);

    if (hasImageError && shouldHideOnError) {
        return null;
    }

    return (
        <div className={classNameBuilder('item-container', containerClassName)}>
            <MediaGalleryItem
                imgSrc={url}
                selected={selected}
                clickHandler={() => addImageHandler(image, selected)}
                showLargePreview={showLargePreview}
                onError={() => {
                    setHasImageError(true);
                    onImageError();
                }}
                {...getItemMetricsAttributes(url)}
                {...rest}
            />
            {isGenAiEnabled && !shouldHideDropdown && (
                <GalleryItemDropdown
                    itemUrl={url}
                    dropdownMenuStyle={itemDropdownMenuStyle}
                    addImageHandler={({ image, options }) => addImageHandler(image, false, options)}
                />
            )}
        </div>
    );
};
