import { valueStoreFactory } from './utils/valueStoreFactory';

export const IS_DEBUG_MODE_QUERY_PARAM = '__IS_DEBUG_MODE__';

const allowedEnvs = new Set(['development', 'test']);

export const debugFlagStore = valueStoreFactory();
export const IS_DEBUG_MODE = () =>
    allowedEnvs.has(process.env.NODE_ENV) ||
    window.location.search.includes(IS_DEBUG_MODE_QUERY_PARAM) ||
    !!debugFlagStore.getValue();

export const throwOnDebug = message => {
    const err = new Error(message);
    if (IS_DEBUG_MODE()) {
        throw err;
    }
    console.error(err);
};
