import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { map, size } from 'lodash';
import { Button, AddIcon } from 'tuui';
import { withIndication } from 'modules/errors';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { DayTimeInfo } from './DayTimeInfo/DayTimeInfo';
import { dayTimeListValidations } from './validations/dayTimePartingValidations';
import styles from './dayTimeParting.module.scss';

const WithMessage = withIndication(({ children }) => <div>{children}</div>);
const classNameBuilder = classnames.bind(styles);

export const DayTimeParting = ({
    field,
    showBoost = true,
    newRowData,
    formFieldDependencies,
    showType = true,
    dayOptions,
}) => {
    const {
        value: rows,
        onChange: setDayTime,
        indicationData,
    } = useFormValidatedValue({
        field,
        validations: dayTimeListValidations,
        ...formFieldDependencies,
    });

    const onClickHandle = useCallback(() => {
        setDayTime((prev = []) => [...prev, newRowData]);
    }, [setDayTime, newRowData]);

    const onDelete = useCallback(
        id => {
            setDayTime(rows => rows.filter(row => row.id !== id));
        },

        [setDayTime]
    );

    return (
        <div>
            <WithMessage {...indicationData}>
                <table>
                    <thead>
                        <tr className={styles['header']}>
                            {showType && (
                                <th
                                    className={classNameBuilder(styles['column-header'], styles['serving-type-header'])}
                                ></th>
                            )}
                            <th className={classNameBuilder(styles['column-header'], styles['day-header'])}>
                                <FormattedMessage
                                    id="campaign.editor.schedule.dayparting.header.day"
                                    defaultMessage="Day"
                                />
                            </th>
                            <th className={classNameBuilder(styles['column-header'], styles['start-header'])}>
                                <FormattedMessage
                                    id="campaign.editor.schedule.dayparting.header.start"
                                    defaultMessage="Start"
                                />
                            </th>
                            <th className={classNameBuilder(styles['column-header'], styles['end-header'])}>
                                <FormattedMessage
                                    id="campaign.editor.schedule.dayparting.header.end"
                                    defaultMessage="End"
                                />
                            </th>
                            {showBoost && (
                                <th
                                    className={classNameBuilder(styles['column-header'], styles['boost-amount-header'])}
                                >
                                    <FormattedMessage
                                        id="campaign.editor.schedule.dayparting.header.boost.amount"
                                        defaultMessage="Boost Amount"
                                    />
                                </th>
                            )}
                        </tr>
                    </thead>
                    {!!size(rows) && (
                        <tbody className={styles['container']}>
                            {map(rows, (row, index) => (
                                <FormNamespaceProvider
                                    field={`${field}.${index}`}
                                    key={row.id}
                                    itemKey={`day_time_${row.id}`}
                                >
                                    <DayTimeInfo
                                        id={row.id}
                                        rows={rows}
                                        onDelete={onDelete}
                                        showBoost={showBoost}
                                        field={field}
                                        showType={showType}
                                        dayOptions={dayOptions}
                                    />
                                </FormNamespaceProvider>
                            ))}
                        </tbody>
                    )}
                </table>
            </WithMessage>
            <Button
                variant={Button.variant.ghost}
                onClick={onClickHandle}
                iconBefore="plus"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="addDayTime"
                data-metrics-value="Add"
                className={styles['button']}
            >
                <AddIcon />
                <FormattedMessage id="app.day.time.add.button" defaultMessage="Add" />
            </Button>
        </div>
    );
};
