import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, keyBy, omit, uniq, difference } from 'lodash';
import useZipCodesApi from './useZipCodesApi';

const DELIMITER = /[,;\n\t]/g;
const useZipCodesState = ({ selectedIds, onChangeSelectedIds }) => {
    // TODO: move the functionality of to prevent set state when unmounted
    const isMounted = useRef(true);
    useEffect(
        () => () => {
            isMounted.current = false;
        },
        []
    );

    const [text, setText] = useState('');
    const idsToSave = useMemo(
        () =>
            text
                .split(DELIMITER)
                .map(val => val.trim())
                .filter(val => !isEmpty(val)),
        [text]
    );
    const [idToValuesMap, setIdToValuesMap] = useState({});
    const [loadingIds, setLoadingIds] = useState([]);
    const selectedValues = useMemo(() => {
        const loadingValues = loadingIds.filter(id => !idToValuesMap[id]).map(id => ({ name: id, loading: true }));
        const fetchedValues = selectedIds.map(id => idToValuesMap[id] || { name: id, loading: true });
        return [...loadingValues, ...fetchedValues];
    }, [loadingIds, selectedIds, idToValuesMap]);

    const [hasInvalidValues, setHasInvalidValues] = useState(false);

    const fetchZipCodes = useZipCodesApi();

    const handleInputChange = useCallback(e => {
        setText(e.target.value);
        setHasInvalidValues(false);
    }, []);

    const handleDeleteItem = useCallback(
        ({ name }) => {
            setIdToValuesMap(prevIdToValuesMap => omit(prevIdToValuesMap, [name]));
            onChangeSelectedIds(prevSelectedIds => prevSelectedIds.filter(id => name !== id));
        },
        [onChangeSelectedIds]
    );

    const handleClearItems = useCallback(() => {
        setIdToValuesMap({});
        onChangeSelectedIds([]);
    }, [onChangeSelectedIds]);

    const getAndUpdateValuesByIds = useCallback(
        async idsToFetch => {
            setLoadingIds(prev => uniq([...idsToSave, ...prev]));
            const values = await fetchZipCodes(idsToFetch);
            if (!isMounted.current) {
                return;
            }

            setLoadingIds(prev => difference(prev, idsToFetch));
            const additionalValuesMap = keyBy(values, 'name');
            if (isEmpty(additionalValuesMap)) {
                return additionalValuesMap;
            }

            setIdToValuesMap(prev => ({ ...prev, ...additionalValuesMap }));

            return additionalValuesMap;
        },
        [fetchZipCodes, idsToSave]
    );

    const handleAddItems = useCallback(async () => {
        const idsToFetch = idsToSave.filter(id => !idToValuesMap[id]);
        if (idsToFetch.length === 0) {
            setText('');
            return;
        }

        const additionalResourcesMap = await getAndUpdateValuesByIds(idsToFetch);
        if (!isMounted.current) {
            return;
        }

        const validIds = idsToSave.filter(id => additionalResourcesMap[id]);
        if (!isEmpty(validIds)) {
            onChangeSelectedIds(prevSelectedIds => uniq([...validIds, ...prevSelectedIds]));
        }

        const invalidIds = idsToSave.filter(id => !additionalResourcesMap[id] && !idToValuesMap[id]);
        setText(invalidIds.join('\n'));
        setHasInvalidValues(invalidIds.length > 0);
    }, [idsToSave, getAndUpdateValuesByIds, onChangeSelectedIds, idToValuesMap]);

    const initResources = useCallback(
        (ids = []) => {
            const idsToFetch = ids.filter(id => !idToValuesMap[id]);
            if (idsToFetch.length === 0) {
                return;
            }

            getAndUpdateValuesByIds(idsToFetch);
        },
        [getAndUpdateValuesByIds, idToValuesMap]
    );

    return {
        selectedValues,
        hasInvalidValues,
        text,
        idsToSave,
        initResources,
        handleInputChange,
        handleDeleteItem,
        handleClearItems,
        handleAddItems,
    };
};

export default useZipCodesState;
