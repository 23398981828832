import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useBreadcrumbsAccountStep, { MAX_CHARACTER_COUNT } from '../../../hooks/useBreadcrumbsAccountStep';
import { REPORT_ID } from '../../campaigns/modules/campaigns-reports/hooks';
import { selectedReportSelector } from '../../campaigns/selectors';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';
import { MODULE_NAME } from '../config';

export const useTrackingBreadcrumbs = ({ selectedReport: coerceReport, title, isTitleAsLastStep = true }) => {
    const selectedReportState = useSelector(selectedReportSelector);
    const selectedReport = coerceReport || selectedReportState;
    const accountSteps = useBreadcrumbsAccountStep(MODULE_NAME);

    const { formatMessage } = useIntl();
    const trackingLabel = formatMessage({
        id: 'navigation.caption.tracking',
        defaultMessage: 'Tracking',
    });
    const steps = useMemo(() => {
        const allTrackingStep = {
            label: trackingLabel,
            path: isTitleAsLastStep ? MODULE_NAME : null,
            params: { [REPORT_ID]: selectedReport },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };
        const steps = [...accountSteps, allTrackingStep];

        if (isTitleAsLastStep) {
            steps.push({ label: title, maxCharacterCount: MAX_CHARACTER_COUNT });
        }
        return steps;
    }, [accountSteps, isTitleAsLastStep, selectedReport, title, trackingLabel]);

    return {
        steps,
    };
};
