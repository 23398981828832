export const TAXONOMY_SEPARATOR = '>';
export const DEFAULT_PAGE_SIZE = 10;
export const MAX_SEGMENTS_TO_SELECT = 200;
export const NO_DATA_LOADING = [
    {
        taxonomy: '1>1',
    },
    {
        taxonomy: '1>2',
    },
    {
        taxonomy: '1>3',
    },
    {
        taxonomy: '1>4',
    },
];

export const NO_DATA_LOADING_FOR_BUNDLES = [
    {
        taxonomy: '',
        id: 1,
        path: '1',
    },
    {
        taxonomy: '1',
        id: 2,
        path: '1>2',
    },
    {
        taxonomy: '1>2',
        id: 3,
        path: '1>2>3',
    },
    {
        taxonomy: '1>2',
        id: 4,
        path: '1>2>4',
    },
    {
        taxonomy: '1',
        id: 5,
        path: '1>5',
    },
];

export const NodeSelectionMode = {
    SELECTED: true,
    INDETERMINATE: null,
    UNSELECTED: undefined,
};

export const LoadingMode = {
    PARTIAL: 'PARTIAL',
    FULL: 'FULL',
    NONE: 'NONE',
};
