'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'textRadioLabel__taboola-svg-icon___2hZq0',
    'title': 'textRadioLabel__title___3KNfQ',
    'description': 'textRadioLabel__description___2H3K1'
};


var TextRadioLabel = function TextRadioLabel(_ref) {
    var title = _ref.title,
        titleClassName = _ref.titleClassName,
        description = _ref.description,
        descriptionClassName = _ref.descriptionClassName;
    return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
            'span',
            { className: styles['title'] + ' ' + titleClassName },
            title
        ),
        description && _react2.default.createElement(
            'span',
            { className: styles['description'] + ' ' + descriptionClassName },
            description
        )
    );
};

TextRadioLabel.propTypes = {
    /** Radio title */
    title: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.node]).isRequired,
    /** Radio description */
    description: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.node]),
    /** Apply class to style title */
    titleClassName: _propTypes2.default.string,
    /** Apply class to style to description */
    descriptionClassName: _propTypes2.default.string
};

TextRadioLabel.defaultProps = {
    titleClassName: '',
    descriptionClassName: ''
};

exports.default = TextRadioLabel;