import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxGroup, FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ValidatedVideoContentDescriptionTextarea } from './ValidatedVideoContentDescriptionTextarea';
import styles from './descriptionFormField.module.scss';

export const DescriptionFormField = () => {
    const [isShowDescription, setIsShowDescription] = useState(false);

    const onSelect = useCallback(() => {
        setIsShowDescription(true);
    }, [setIsShowDescription]);

    const onUnSelect = useCallback(() => {
        setIsShowDescription(false);
    }, [setIsShowDescription]);

    const { value: description, isDirty: isDescriptionDirty } = useFormFieldValue({
        field: 'description',
    });
    useEffect(() => {
        if (isDescriptionDirty) {
            return;
        }
        setIsShowDescription(!!description);
    }, [isDescriptionDirty, setIsShowDescription, description]);

    return (
        <>
            <CheckboxGroup
                className={styles['container']}
                onSelectCheckbox={onSelect}
                onUnselectCheckbox={onUnSelect}
                selectedValues={[isShowDescription]}
            >
                <TextCheckbox
                    disabled={false}
                    value
                    title={
                        <FormattedMessage
                            id="video.creative.creator.content.description.checkbox.title"
                            defaultMessage="description"
                        />
                    }
                    labelClassName={styles['label']}
                />
            </CheckboxGroup>
            {isShowDescription && (
                <FormField
                    label={
                        <FormattedMessage
                            id="video.creative.creator.content.description.title"
                            defaultMessage="Description"
                        />
                    }
                    helpText={
                        <FormattedMessage
                            id="video.creative.creator.content.description.tooltip"
                            defaultMessage="The description offers advertisers storytelling opportunities to highlight details about their product or service.{br}{br}Must-see details about your ad should be written in the title.{br}{br}Based on the specific environment and publishers, Taboola will auto-optimize between your ad with and without description to show the best ad for achieving strong performance.{br}{br}The visible length of the description depends on the placement it appears on. If shortened, the description will end with ellipses. Maximum length of the text is 180 characters.{br}{br}<a>See our best practices.</a>"
                        />
                    }
                    inputId="descriptionText"
                >
                    <ValidatedVideoContentDescriptionTextarea />
                </FormField>
            )}
        </>
    );
};
