import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useCustomContextualCountriesApi } from '../CustomContextualSizeEstimationWidget/hooks/useCustomContextualCountriesApi';

export const useCountriesField = () => {
    const { displayDefaultCountry } = useCustomContextualCountriesApi();
    const props = useFormFieldValue(
        {
            field: 'countries',
        },
        { defaultCountry: displayDefaultCountry?.value }
    );

    return props;
};
