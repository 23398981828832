import React from 'react';
import classnames from 'classnames/bind';
import { HelpTooltip, AudienceSectionIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData/FormDataContext';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import UnsupportedConfiguration from '../../../../../../components/UnsupportedConfiguration/UnsupportedConfiguration';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { ContextualSegmentsField } from '../ContextualSegments/ContextualSegmentsField';
import MarketplaceAudiencesField from '../MarketplaceAudiences/MarketplaceAudiencesField';
import { useMarketplaceAudienceTargetingValues } from '../MarketplaceAudiences/hooks/useMarketplaceAudienceTargetingValues';
import { MyAudiencesField } from '../MyAudiencesField/MyAudiencesField';
import { AudienceTargetingFirstCampaignIndication } from './AudienceTargetingFirstCampaignIndication';
import AudienceTargetingTooltipLegacy from './AudienceTargetingTooltipLegacy';
import styles from './audienceTargetingSectionLegacy.module.scss';

const classNameBuilder = classnames.bind(styles);

const AudienceTargetingSectionLegacy = () => {
    const { mode } = useFormDataContext();
    const { isValidTargeting: validTargetingMarketplace = true } = useMarketplaceAudienceTargetingValues();

    const { value: validTargetingContextual = true } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting.validTargeting',
    });
    const { value: validTargetingMyAudiences = true } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.validTargeting',
    });
    const containerClassName = classNameBuilder('container', { 'new-campaign': mode === FORM_MODES.CREATE });

    return (
        <div className={containerClassName}>
            <CommonCollapsibleCard
                id="CAMPAIGN_AUDIENCE_TARGETING"
                header={
                    <SectionHeader
                        headerIcon={<AudienceSectionIcon />}
                        headerText={
                            <FormattedMessage
                                id="campaign.editor.audience.targeting"
                                defaultMessage="Audience Targeting"
                            />
                        }
                    />
                }
                description={
                    <>
                        <FormattedMessage
                            id="campaign.editor.audience.targeting.description"
                            defaultMessage="Targeting more than one audience type (i.e. using both Marketplace Audiences and My Audiences) will narrow target audience and limit campaign reach."
                        />
                        <HelpTooltip iconClassName={styles['help-tooltip']}>
                            <AudienceTargetingTooltipLegacy />
                        </HelpTooltip>
                        <AudienceTargetingFirstCampaignIndication />
                    </>
                }
            >
                {validTargetingMarketplace && validTargetingMyAudiences && validTargetingContextual ? (
                    <div>
                        <MarketplaceAudiencesField />
                        <MyAudiencesField />
                        <ContextualSegmentsField />
                    </div>
                ) : (
                    <UnsupportedConfiguration />
                )}
            </CommonCollapsibleCard>
        </div>
    );
};

export default AudienceTargetingSectionLegacy;
