import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addTrackingToCampaign.module.scss';

export const AddTrackingToCampaign = () => {
    return (
        <FormField
            label={
                <FormattedMessage
                    className={styles['Tracking-campaign']}
                    id="tracking.setup.s2s.campaign.add.tracking"
                    defaultMessage="Add Tracking to your Taboola Campaign"
                />
            }
            description={
                <div>
                    <ol className={styles['ol-style']}>
                        <li className={styles['item']}>
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.description.first"
                                defaultMessage="Go to the <b> Campaign </b> tab."
                            />
                        </li>
                        <li className={styles['item']}>
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.description.second"
                                defaultMessage="Then click <b> Create Campaign </b> or edit an existing campaign."
                            />
                        </li>
                        <li className={styles['item']}>
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.description.third"
                                defaultMessage="In the Campaign set-up page, go to the <b> Tracking section </b> at the bottom of the page."
                            />
                        </li>
                        <li className={styles['item']}>
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.description.fourth"
                                defaultMessage="Add a parameter to your Tracking Code that is set to equal <b> '{click_id}' </b> as conversion cannot be recorded without it."
                            />
                        </li>
                        <li className={styles['item']}>
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.description.fifth"
                                defaultMessage="For item level tracking, add th <b>Tracking URL</b> to the ad URL field."
                            />
                        </li>
                    </ol>
                </div>
            }
            containerClass={styles['container']}
        />
    );
};
