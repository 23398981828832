import React from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker/config';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../../../taboola-common-frontend-modules/validations/components/hoc';
import attributionGroupValidations from '../../../../config/validations/pmp-deal/attributionGroupValidations';
import AttributionGroupTooltip from './AttribuitionGroupTooltip';
import styles from '../../../../components/Form/FormFields/formFields.module.scss';

const InputWithValidations = withInputValidations(withIndication(Input), attributionGroupValidations);

const AttributionGroup = () => {
    const { value: attributionGroup = '', onChange: onAttributionGroupChange } = useFormFieldValue({
        field: 'attributionGroup',
    });
    const handleChange = e => onAttributionGroupChange(e.target.value);

    return (
        <FormField
            inputId="attribution-group"
            containerClass={styles['input']}
            label={
                <FormattedMessage id="pmpDeal.attribution.group.label" defaultMessage="Attribution Group (optional)" />
            }
            description={
                <FormattedMessage
                    id="pmpDeal.attribution.group.description"
                    defaultMessage="Label all campaigns which should be attributed to the same buyer."
                />
            }
            helpText={<AttributionGroupTooltip />}
        >
            <InputWithValidations
                id="attributionGroup"
                onChange={handleChange}
                value={attributionGroup}
                type={InputTypes.TEXT}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="AttributionGroup"
                role="input"
            />
        </FormField>
    );
};

export default AttributionGroup;
