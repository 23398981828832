import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './tipText.module.scss';

const TipText = ({ className, children }) => (
    <div className={className}>
        <span className={styles['tip']}>
            <FormattedMessage id="campaign.editor.tip" defaultMessage="Tip:" />
        </span>
        {children}
    </div>
);

TipText.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default TipText;
