import React, { useCallback } from 'react';
import { get, isEmpty, noop } from 'lodash';
import { DragAndDropFile, FileTypes } from 'taboola-ultimate-ui';
import { operationsApi } from 'services/api';
import ApiError from '../../../../../errors/ApiError';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { validateFileDimensions } from '../../../../../taboola-common-frontend-modules/validations/services/validateFileDimensions';
import { IMAGE, THUMBNAIL_SOURCE_TYPES, VIDEO } from '../../../creative-creator/config';
import { ONLY_IMAGE_FILES } from '../../../creative-creator/config/fileTypeConfigs';
import {
    GIF_TYPE,
    MAX_GIF_SIZE,
    MAX_GIF_SIZE_VALIDATION_ERROR_MESSAGE_ID,
    MAX_VIDEO_SIZE,
    MAX_VIDEO_SIZE_VALIDATION_ERROR_MESSAGE_ID,
} from '../../../creative-creator/config/thumbnailValidationConsts';
import { isFileTypeImage, isMotionFile } from '../../../creative-creator/services/utils';
import { mapFileToThumbnail } from '../../../creative-creator/services/utils/mapFileToThumbnail';

const checkInvalidMotionSize = file => {
    if (isFileTypeImage(file.type)) {
        return;
    }
    if (file.type === GIF_TYPE) {
        if (file.size > MAX_GIF_SIZE) {
            throw new ApiError({
                messageCode: `creative.creator.thumbnails.fetching.${MAX_GIF_SIZE_VALIDATION_ERROR_MESSAGE_ID}`,
            });
        }
        return;
    }
    if (file.size > MAX_VIDEO_SIZE) {
        throw new ApiError({
            messageCode: `creative.creator.thumbnails.fetching.${MAX_VIDEO_SIZE_VALIDATION_ERROR_MESSAGE_ID}`,
        });
    }
};

export const MediaDragAndDrop = ({
    children,
    fileTypes = ONLY_IMAGE_FILES,
    onDrop: onDropProp,
    onCancel,
    dropActiveContainerClassName,
    disabled,
    suppressDropContainer,
    inputId,
    campaignId,
    titleList,
    onFileTypeFailure = noop,
    onBeforeFileProcessing = noop,
    className,
    accountId: propsAccountId,
}) => {
    const { formAccount } = useFormDataContext();
    const accountId = propsAccountId || get(formAccount, 'accountId');
    const onDrop = useCallback(
        (fileList, fileRejections, event) => {
            if (!isEmpty(fileRejections)) {
                onFileTypeFailure();
            }
            if (isEmpty(fileList)) {
                return;
            }

            if (event) {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { component: 'MediaDropZone' });
            }

            const thumbnailList = fileList.map(mapFileToThumbnail);
            onBeforeFileProcessing();
            thumbnailList.forEach(async thumbnail => {
                const { id, file } = thumbnail;
                const isImage = isFileTypeImage(file.type);

                if ((isImage && !fileTypes[FileTypes.IMAGE]) || (!isImage && !fileTypes[FileTypes.VIDEO])) {
                    onFileTypeFailure();
                    return;
                }

                const urlResolver = async () => {
                    checkInvalidMotionSize(file);

                    let isMotion = false;
                    try {
                        isMotion = await isMotionFile(thumbnail);
                    } catch {}

                    if (isMotion) {
                        return operationsApi
                            .uploadVideoThumbnailWithImageRecommendation(accountId, thumbnail, campaignId, titleList)
                            .then(({ url, gifUrl, fallbackImageUrl }) => ({
                                url,
                                gifUrl,
                                fallbackImageUrl,
                                recommendedFBImage: fallbackImageUrl,
                            }));
                    }

                    return validateFileDimensions(file)
                        .then(() => operationsApi.uploadThumbnail(accountId, file))
                        .then(({ value }) => ({ url: value }))
                        .catch(e => {
                            throw e;
                        });
                };

                onDropProp(urlResolver, {
                    id,
                    mediaUploadSource: THUMBNAIL_SOURCE_TYPES.FILE,
                    fileType: isFileTypeImage(thumbnail.fileType) ? IMAGE : VIDEO,
                    dropped: !!event?.dataTransfer,
                });
            });
        },
        [onBeforeFileProcessing, onFileTypeFailure, fileTypes, onDropProp, accountId, campaignId, titleList]
    );

    return (
        <DragAndDropFile
            fileTypes={fileTypes}
            onDrop={onDrop}
            onCancel={onCancel}
            dropActiveContainerClassName={dropActiveContainerClassName}
            disabled={disabled}
            suppressDropContainer={suppressDropContainer}
            inputId={inputId}
            className={className}
        >
            {children}
        </DragAndDropFile>
    );
};
