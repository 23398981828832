import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useFormDataContext } from '../../taboola-common-frontend-modules/formData';
import { useFormState } from '../../taboola-common-frontend-modules/forms';
import getChangesObject from '../../taboola-common-frontend-modules/utils/objectDiff';
import { PERFORMANCE_RULES_ROUTE } from '../config';
import { fetchPerformanceRule } from '../flows/fetchPerformanceRule';
import { updatePerformanceRule } from '../flows/updatePerformanceRule';
import { performanceRulesTransformerToGW } from '../transformers/performanceRulesTransformerToGW';

export const usePerformanceRulesEditorState = () => {
    const { ruleId, accountId } = useMultiLayerPathParams();
    const { fetchedData: originalRule } = useFormDataContext();
    const dispatch = useDispatch();

    const formDataFetcher = useCallback(
        () => dispatch(fetchPerformanceRule(accountId, ruleId)),
        [accountId, dispatch, ruleId]
    );
    const { submit } = useFormState({ formDataFetcher });

    const handlePerformanceRulesSubmitWrapper = useCallback(() => {
        const savePerformanceRulesWrapper = rule => {
            const transformedRule = performanceRulesTransformerToGW(rule);
            const ruleChanges = getChangesObject(transformedRule, originalRule);
            const ruleChangesWithAdditionalFields = { ...ruleChanges };
            return dispatch(
                updatePerformanceRule({
                    accountName: accountId,
                    changes: ruleChangesWithAdditionalFields,
                    ruleId,
                })
            );
        };
        submit(savePerformanceRulesWrapper, PERFORMANCE_RULES_ROUTE);
    }, [ruleId, accountId, submit, dispatch, originalRule]);

    return { submit: handlePerformanceRulesSubmitWrapper };
};
