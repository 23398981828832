import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import createCSVValueGetter from './createCSVValueGetter';
import createGridValueFormatter from './createGridValueFormatter';
import { statusFormatter } from './creativeStatusFormatter';

const enumFormatter = ({ value, formatOptions: { msgIdPrefix, emptyValue = EMPTY_FIELD_VALUE }, i18n }) =>
    i18n.formatMessage({ id: `${msgIdPrefix}.${value}`, defaultMessage: value || emptyValue });

export const gridEnumFormatter = createGridValueFormatter(enumFormatter);
export const csvEnumFormatter = createCSVValueGetter(enumFormatter);
export const csvStatusFormatter = createCSVValueGetter(statusFormatter);

export default csvEnumFormatter;
