import React from 'react';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import BidDealFloor from '../BidDealFloor/BidDealFloor';
import { CampaignBudget } from '../Budget/CampaignBudget';
import { BudgetSectionFrame } from '../BudgetSectionFrame';
import CpaGoal from '../CpaGoal/CpaGoal';
import RoasGoal from '../RoasGoal/RoasGoal';

const BudgetSectionOpenExchange = () => {
    const {
        formAccount: { currency },
    } = useFormDataContext();
    const isProgrammaticBudgetEnabled = useConfigMatch({ [FEATURE_FLAGS.PROGRAMMATIC_BUDGET_ENABLED]: 'true' });
    const isDcoAccount = useConfigMatch({ [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'true' });
    return (
        <BudgetSectionFrame>
            {isProgrammaticBudgetEnabled && <CampaignBudget />}
            <BidDealFloor />
            {isDcoAccount && <CpaGoal currency={currency} />}
            {isDcoAccount && <RoasGoal currency={currency} />}
        </BudgetSectionFrame>
    );
};

export default BudgetSectionOpenExchange;
