import encodeurl from 'encodeurl';
import { duplicateUnifiedCreative } from 'modules/campaigns/modules/creative-editor/flows/duplicateUnifiedCreative';

export const unifiedCreativeDuplicateHandler =
    (accountName, data, dispatch) =>
    async ({ selectedAccount, matchingCreativeIds }) => {
        const creativeToSave = { ...data, url: encodeurl(data.url) };
        const result = await dispatch(
            duplicateUnifiedCreative({ accountName, selectedAccount }, creativeToSave, matchingCreativeIds)
        );
        if (!result?.error) {
            return result;
        }
    };
