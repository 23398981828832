const getCurrencyForPlaceHolder = ({ isFilterWithCurrency, currency, isFilterWithPercent }) => {
    if (isFilterWithCurrency) {
        return `(${currency})`;
    } else if (isFilterWithPercent) {
        return '%';
    }
    return '';
};

export const comparableFiltersPlaceholdersResolver = ({
    isFilterWithCurrency,
    isFilterWithPercent,
    showSecondInput,
    currency,
    formatMessage,
}) => {
    const placeholders = {};
    if (isFilterWithCurrency && !showSecondInput && !isFilterWithPercent) {
        placeholders.firstPlaceholder = currency;
    } else if (!isFilterWithCurrency && !showSecondInput && !isFilterWithPercent) {
        placeholders.firstPlaceholder = '0';
    } else if (!isFilterWithCurrency && !showSecondInput && isFilterWithPercent) {
        placeholders.firstPlaceholder = '%';
    } else {
        placeholders.firstPlaceholder = `${formatMessage({
            id: 'app.campaigns.reports.filters.input.placeholder.from',
            defaultMessage: 'From',
        })} ${getCurrencyForPlaceHolder({ isFilterWithCurrency, currency, isFilterWithPercent })}`;
        placeholders.secondPlaceholder = `${formatMessage({
            id: 'app.campaigns.reports.filters.input.placeholder.to',
            defaultMessage: 'To',
        })} ${getCurrencyForPlaceHolder({ isFilterWithCurrency, currency, isFilterWithPercent })}`;
    }

    return placeholders;
};
