import { useMemo } from 'react';
import moment from 'moment-timezone';
import { useModuleName, useDimension, useAvailableItems } from 'hooks';
import { MODULE_NAME as CAMPAIGNS_MODULE_NAME } from 'modules/campaigns/config/routes';
import { FEATURE_FLAGS, useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { isAdminNetworkAccountType } from '../../account-management';
import { PERMISSIONS_OPERATORS } from '../../taboola-common-frontend-modules/authentication';
import { REAL_TIME } from '../config/realTimeConst';
import { useDateRange } from '../modules/campaigns-reports/hooks/useDateRange';

export const getRealtimePresetConfig = () => [
    {
        id: REAL_TIME,
        label: 'Real-Time',
        startDate: moment(),
        endDate: moment(),
        permissions: {
            permissions: [
                'TA_REAL_TIME_REPORT',
                'TA_REALTIME_CAMPAIGN_REPORT',
                'TA_REALTIME_SITE_REPORT',
                'TA_REALTIME_CREATIVE_REPORT',
                'TA_REALTIME_COUNTRY_REPORT',
            ],
            operator: PERMISSIONS_OPERATORS.OR,
        },
        accountConfigurations: { [FEATURE_FLAGS.TA_REALTIME]: 'true' },
        visibilityCondition: ({ dimension, module, selectedAccount }) =>
            module === CAMPAIGNS_MODULE_NAME &&
            dimension === CAMPAIGN_DIMENSION.SPONSORED &&
            !isAdminNetworkAccountType(selectedAccount?.type),
    },
];

const transformToUnifiedPresetConfigs = (acc, { id, ...rest }) => {
    acc[id] = { ...rest };
    return acc;
};

export const useRealtime = () => {
    const [dateRange] = useDateRange();
    const [dimension] = useDimension();
    const { selectedAccount } = useAccount();
    const module = useModuleName();
    const monthDay = moment().date();
    const resetConfig = useMemo(() => getRealtimePresetConfig(monthDay), [monthDay]);
    const availablePreset = useAvailableItems(resetConfig, { dimension, module, selectedAccount });
    const realtimeDatePreset = useMemo(
        () => availablePreset.reduce(transformToUnifiedPresetConfigs, {}),
        [availablePreset]
    );

    const isRealtimeAvailable = Boolean(availablePreset.length);

    const isRealtimeActive = isRealtimeAvailable && dateRange.datePreset === REAL_TIME;

    return useMemo(
        () => ({
            realtimeDatePreset,
            isRealtimeAvailable,
            isRealtimeActive,
        }),
        [isRealtimeAvailable, realtimeDatePreset, isRealtimeActive]
    );
};
