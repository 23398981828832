import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { CommonCollapsibleCard } from 'components';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { OnboardingContent } from './OnboardingContent';
import { LOCAL_STORAGE_ACCOUNT_KEYS } from './consts';
import { OnboardingAccountDataProvider } from './contextProvider/OnboardingAccountDataProvider';
import { useOnboardingAccountDataContext } from './contextProvider/onboardingAccountDataContext';
import { useIsOnboardingEnabled, useOnboardingLocalStorage } from './hooks';
import styles from './onboardingAssistant.module.scss';

const classNameBuilder = classnames.bind(styles);
const { IS_CLOSED, IS_COLLAPSED } = LOCAL_STORAGE_ACCOUNT_KEYS;

export const OnboardingAssistant = () => {
    const isEnabled = useIsOnboardingEnabled();
    const { isReady: isLocalStorageReady } = useOnboardingAccountDataContext();
    const [isCollapsed, setIsCollapsed] = useOnboardingLocalStorage({
        key: IS_COLLAPSED,
        defaultValue: false,
    });
    const [isAnimatedClosing, setIsAnimatedClosing] = useOnboardingLocalStorage({
        key: IS_CLOSED,
        defaultValue: false,
    });
    const [isClosed, setIsClosed] = useState(isAnimatedClosing);
    const closeOnboardingAssistant = () => {
        setIsAnimatedClosing(true);
    };

    const handleCollapse = getIsCollapsedUpdate => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            pageName: getPageName(),
            component: 'Onboarding Assistant',
            value: isCollapsed ? 'Show panel' : 'Hide panel',
        });
        const newCollapsedState = getIsCollapsedUpdate(isCollapsed);
        setIsCollapsed(newCollapsedState);
    };

    useEffect(() => {
        const timeout = setTimeout(() => setIsClosed(isAnimatedClosing), 1000);
        return () => {
            clearTimeout(timeout);
            setIsClosed(isAnimatedClosing);
        };
    }, [isAnimatedClosing]);

    const isHideOnboardingAssistant = !isEnabled || isClosed || !isLocalStorageReady;

    return isHideOnboardingAssistant ? null : (
        <CommonCollapsibleCard
            headerClassName={styles['header']}
            containerClassName={classNameBuilder('container', { 'animate-close': isAnimatedClosing })}
            bodyClassName={styles['body']}
            collapsedSize={55}
            collapsed={isCollapsed}
            setCollapsed={handleCollapse}
            id="ONBOARDING_ASSISTANT"
        >
            <OnboardingContent isCollapsed={isCollapsed} closeOnboardingAssistant={closeOnboardingAssistant} />
        </CommonCollapsibleCard>
    );
};

export const OnboardingAssistantWrapper = () => (
    <OnboardingAccountDataProvider>
        <OnboardingAssistant />
    </OnboardingAccountDataProvider>
);
