import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BidField } from '../Bid/BidField';
import CpmVcpmTooltip from '../Bid/BidTooltip/CpmVcpmTooltip';
import { useHighImpactPackageIndication } from '../BidStrategyV2/hooks/useHighImpactPackageIndication';

const BidDealFloor = () => {
    const hasHighImpactPackage = useHighImpactPackageIndication();
    return (
        <BidField
            label={<FormattedMessage id="campaign.editor.deal.floor.title" defaultMessage="Deal floor" />}
            description={
                <FormattedMessage
                    id="campaign.editor.deal.floor.description"
                    defaultMessage="Minimum bid for each impression, according to the CPM pricing model."
                />
            }
            helpText={<CpmVcpmTooltip />}
            hasHighImpactPackage={hasHighImpactPackage}
            isManaged={false}
            dataMetricsComponent="DealFloor"
        />
    );
};
export default BidDealFloor;
