import React, { useMemo } from 'react';
import CodeSnippet from 'modules/audiences/components/CodeSnippet/CodeSnippet';
import { useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { buildCodeSnippetWithParams } from 'modules/taboola-common-frontend-modules/account-configurations/utils/buildPixelCodeSnippet';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

const useTagManagerCodeSnippet = (flagName, eventName) => {
    const { [flagName]: codeSnippet } = useCommonConfig([flagName]);
    const {
        formAccount: { id },
    } = useFormDataContext();

    return useMemo(() => buildCodeSnippetWithParams(codeSnippet, id, eventName), [codeSnippet, id, eventName]);
};

export const TagManagerCodeSnippet = ({ flagName, eventName }) => {
    const tagManagerCodeSnippet = useTagManagerCodeSnippet(flagName, eventName);

    return <CodeSnippet codeSnippetText={tagManagerCodeSnippet} />;
};
