import React from 'react';
import { FormField, Input } from 'taboola-ultimate-ui';
import useEventValueHandler from 'hooks/useEventValueHandler';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue } from '../../../taboola-common-frontend-modules/validations';
import commonStyles from '../CardPaymentMethodCreator/cardPaymentMethodCreator.module.scss';

const tanValidation = {
    maxLength: 10,
    regExp: /^$|^[A-Z]{4}\d{5}[A-Z]$/,
};

const validations = [
    {
        validationFn: (value, options, { maxLength }) => !maxLength || value.length <= maxLength,
        messageId: 'validations.error.paymentMethod.tan.moreThen',
    },
    {
        validationFn: (value, options, { regExp }) => !regExp || regExp.test(value),
        messageId: 'validations.error.paymentMethod.tan.invalid',
    },
];

const InputWithIndication = withIndication(Input);

export const PaymentMethodTan = () => {
    const {
        value: tan = '',
        onChange,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'taxData.tanId',
        validations,
        validationDependencies: {
            maxLength: tanValidation.maxLength,
            regExp: tanValidation.regExp,
        },
    });
    const changeHandler = useEventValueHandler(onChange);
    const { formatMessage } = useIntl();

    return (
        <FormField
            inputId="tanId"
            containerClass={commonStyles['form-field']}
            ref={scrollRef}
            label={<FormattedMessage id="paymentMethod.creator.tan" defaultMessage="TAN" />}
        >
            <InputWithIndication
                id="tanId"
                value={tan}
                onChange={changeHandler}
                placeholder={formatMessage({
                    id: 'paymentMethod.creator.tan.placeholder',
                    defaultMessage: '12345',
                })}
                size={Input.SIZE.LARGE}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="PaymentMethodTan"
                disabled={mode !== FORM_MODES.CREATE}
                {...indicationData}
            />
        </FormField>
    );
};
