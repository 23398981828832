import { useCallback } from 'react';
import { useGoogleApiContext } from '../GoogleApiContext';
import { DISCOVERY_URL } from '../constants';

const useGoogleApi = () => {
    const context = useGoogleApiContext();
    const {
        googleSheetsLoaded,
        setGoogleSheetsLoaded,
        isGisLoaded,
        isGapiLoaded,
        getToken,
        revokeToken,
        getIsPermitted,
        requestToken,
        tokenExpirationTime,
        setTokenExpirationTime,
    } = context;

    const getIsTokenValid = useCallback(() => {
        return (
            isGapiLoaded &&
            isGisLoaded &&
            getToken() !== null &&
            Date.now() < tokenExpirationTime.current &&
            getIsPermitted()
        );
    }, [getIsPermitted, getToken, isGapiLoaded, isGisLoaded, tokenExpirationTime]);

    const checkAuth = useCallback(
        async (force = false) => {
            // if the user is already authenticated, do nothing
            if (getIsTokenValid() && !force) {
                return;
            }

            // attempt to sign in the user
            // will throw error if user closes the window
            try {
                const tokenResponse = await requestToken();
                setTokenExpirationTime(Date.now() + tokenResponse.expires_in * 1000);
                return tokenResponse;
            } catch (e) {
                throw new Error(`Failed to sign in user with pop up: ${e?.error}`);
            }
        },
        [getIsTokenValid, requestToken, setTokenExpirationTime]
    );

    const loadGoogleSheets = useCallback(async () => {
        // if google sheets already loaded, immediately resolve
        if (googleSheetsLoaded) {
            return;
        }

        // if the user isn't logged in yet, reject with descriptive error
        if (!getIsTokenValid()) {
            throw new Error("Couldn't load google sheets API. Not authenticated yet.");
        }

        // load the sheets URL
        try {
            await window.gapi?.client?.load(DISCOVERY_URL);

            setGoogleSheetsLoaded(true);
        } catch (err) {
            throw new Error(`Failed to load sheets URL: ${err?.error}`);
        }
    }, [googleSheetsLoaded, getIsTokenValid, setGoogleSheetsLoaded]);

    const logout = useCallback(async () => {
        try {
            revokeToken();
        } catch (err) {
            throw new Error(`Failed to sign user out: ${err?.error}`);
        }
    }, [revokeToken]);

    const exponentialBackoff = attempt => Math.min(attempt > 1 ? Math.pow(2, attempt) * 1000 : 1000, 30 * 1000);

    return {
        loadGoogleSheets,
        checkAuth,
        logout,
        isTokenValid: getIsTokenValid(),
        exponentialBackoff,
        staleTime: 2 * (60 * 1000),
        ...context,
    };
};

export default useGoogleApi;
