"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgGraph(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M16.572 5H15.15a1 1 0 0 1 0-2h3.35c.827 0 1.503.662 1.503 1.496v3.552a1 1 0 0 1-2 0V6.427l-2.407 3.23a1.516 1.516 0 0 1-2.074.338L9.578 7.269l-3.907 3.478a1 1 0 0 1-1.33-1.494L8.468 5.58a1.667 1.667 0 0 1 2.01-.12l3.798 2.624L16.572 5zM4 14.528a1 1 0 0 1 2 0V18a1 1 0 0 1-2 0v-3.472zM8.5 11a1 1 0 1 1 2 0v7.055a1 1 0 0 1-2 0V11zm5 3a1 1 0 0 1 2 0v4a1 1 0 0 1-2 0v-4zm4.503-2a1 1 0 0 1 2 0L20 18a1 1 0 0 1-2 0l.003-6z"
        })
    );
}
exports.default = SvgGraph;