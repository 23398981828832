import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { invert } from 'lodash';
import { usePagination, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import useComponentStatus from 'hooks/useComponentStatus';
import { COMPONENT_STATUS } from 'services/constants';
import { SEGMENT_SORTABLE_FIELDS, defaultSortType, defaultSortField } from '../../../config';
import { segmentPickerSortOptionsSelector } from '../../../selectors';
import useSegmentsFetchService from './useSegmentsFetchService';

const SEGMENTS_PAGE_SIZE = 30;
const SORTABLE_FIELD_VALUE_TO_KEY_MAP = invert(SEGMENT_SORTABLE_FIELDS);
const SORTING_ORDER_TYPE_TO_KEY_MAP = invert(SORTING_ORDER_TYPES);

const checkKey = (key, validMap, defaultValue) => (validMap[key] ? key : defaultValue);

const useSegmentPickerService = (searchText, status) => {
    const { status: paginationStatus, setStatus: setPaginationStatus, isModuleReady } = useComponentStatus(status);
    const { getSegmentFromMap, loadSegmentsPage } = useSegmentsFetchService();
    const { type, field } = useSelector(segmentPickerSortOptionsSelector);
    const sortField = checkKey(field, SORTABLE_FIELD_VALUE_TO_KEY_MAP, defaultSortField);
    const sortType = checkKey(type, SORTING_ORDER_TYPE_TO_KEY_MAP, defaultSortType);
    const sort = useMemo(
        () => [
            {
                field: sortField,
                order: sortType,
            },
        ],
        [sortField, sortType]
    );

    const {
        reload: innerReload,
        isItemLoaded,
        loadCurrentFrame,
        values: loadedSegments,
        reset,
    } = usePagination({
        loadPage: loadSegmentsPage,
        pageSize: SEGMENTS_PAGE_SIZE,
        loadParams: useMemo(() => ({ searchText, sort }), [searchText, sort]),
    });
    const segments = useMemo(() => loadedSegments.map(getSegmentFromMap), [getSegmentFromMap, loadedSegments]);

    const loadNextPage = useCallback(
        (...params) => {
            if (!isModuleReady) {
                return;
            }

            return loadCurrentFrame(...params);
        },
        [loadCurrentFrame, isModuleReady]
    );

    const reload = useCallback(async () => {
        setPaginationStatus(COMPONENT_STATUS.LOADING);
        await innerReload();
        setPaginationStatus(COMPONENT_STATUS.ACTIVE);
    }, [innerReload, setPaginationStatus]);

    return {
        segments,
        reload,
        reset,
        loadNextPage,
        isItemLoaded,
        paginationStatus,
        sortType,
        sortField,
    };
};

export default useSegmentPickerService;
