import React from 'react';
import { getInvoiceStatusIndicatorType } from '../../../../../billing-and-payments/config/invoiceStatusConsts';
import EnumValueCellRenderer from './EnumValueCellRenderer';
import { StatusIndicatorCellRenderer } from './StatusIndicatorCellRenderer';

export const InvoiceStatusCellRenderer = ({ value, msgIdPrefix, emptyValue }) => {
    const invoiceStatusIndicatorType = getInvoiceStatusIndicatorType(value);
    return (
        <StatusIndicatorCellRenderer
            value={value}
            msgIdPrefix={msgIdPrefix}
            emptyValue={emptyValue}
            indicatorType={invoiceStatusIndicatorType}
        />
    );
};

InvoiceStatusCellRenderer.propTypes = {
    ...EnumValueCellRenderer.propTypes,
};

InvoiceStatusCellRenderer.defaultProps = {
    ...EnumValueCellRenderer.defaultProps,
};
