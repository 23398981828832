'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Dotdotdot = require('./Dotdotdot');

var _Dotdotdot2 = _interopRequireDefault(_Dotdotdot);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Dotdotdot2.default;