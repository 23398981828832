import { useState } from 'react';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { useIsCarouselCreative } from './useIsCarouselCreative';

const useIsLogoFieldVisible = () => {
    const isLogoFieldEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' });
    const isCarouselCreative = useIsCarouselCreative();
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    return isLogoFieldEnabled && !isCarouselCreative && !isAppInstall;
};

const useIsTrackingSectionEnabled = () => {
    const isTrackingSectionEnabledByConfig = useConfigMatch({ [FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING]: 'true' });
    const isTrackingSectionEnabledByPermission = usePermissions('CREATIVE_EDIT_TRACKING');
    return isTrackingSectionEnabledByConfig || isTrackingSectionEnabledByPermission;
};

export const useUnifiedCreativeEditorFormState = () => {
    const { data: creative } = useFormDataContext();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const { onChange: onChangeMatchingIds } = useFormFieldValue({ field: 'matchingCreativeIds' });
    const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);
    const [applyFocusToMatches, setApplyFocusToMatches] = useState(false);
    const [thumbnailStatus, setThumbnailStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [videoStatus, setVideoStatus] = useState(COMPONENT_STATUS.INITIAL);
    const isLogoFieldVisible = useIsLogoFieldVisible();
    const isTrackingSectionEnabled = useIsTrackingSectionEnabled();

    return {
        creative,
        creativeType,
        isThumbnailLoading,
        setIsThumbnailLoading,
        applyFocusToMatches,
        setApplyFocusToMatches,
        thumbnailStatus,
        setThumbnailStatus,
        videoStatus,
        setVideoStatus,
        isLogoFieldVisible,
        isTrackingSectionEnabled,
        onChangeMatchingIds,
    };
};
