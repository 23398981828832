import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import EllipsisCellRenderer from './EllipsisCellRenderer';

export const CampaignHistoryCampaignNameCellRenderer = ({ value, data }) => {
    const { campaignId } = data;
    const shouldDisplayAllCampaigns = !value || !campaignId;

    const resolvedValue = (
        <span>
            {shouldDisplayAllCampaigns ? (
                <FormattedMessage
                    id="app.campaigns.reports.grid.campaign_history.allCampaigns"
                    defaultMessage="All Campaigns"
                />
            ) : (
                <>
                    <span>{value}</span>
                    <FormattedMessage
                        id="app.campaigns.reports.grid.campaign_history.campaign_name_id"
                        values={{ campaignId }}
                        defaultMessage=" (ID: {campaignId})"
                    />
                </>
            )}
        </span>
    );

    return <EllipsisCellRenderer value={resolvedValue} />;
};
