import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormField, INDICATION_TYPES, RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { useAvailableItems } from 'hooks';
import MARKETING_OBJECTIVES from 'modules/campaigns/modules/common-campaign-form/config/marketingObjectives';
import { withIndication } from 'modules/errors';
import {
    addStaticIndication,
    removeStaticIndication,
    STATIC_INDICATION_TYPES,
} from 'modules/taboola-common-frontend-modules/Indications';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TRACKING_STATUS } from 'modules/tracking/config';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { MarketingObjectiveDescription } from './MarketingObjectiveDescription';
import { NO_ACTIVE_CONVERSIONS_INDICATION, NO_ACTIVE_PIXEL_INDICATION } from './MarketingObjectiveMessages';
import { useMarketingObjectiveWithValidations } from './useMarketingObjectiveWithValidations';
import { usePixelWarningPermitted } from './usePixelWarningPermitted';
import commonStyles from '../commonEditor.module.scss';
import styles from './marketingObjective.module.scss';

const classNameBuilder = classnames.bind(styles);

const FormFieldWithIndication = withIndication(FormField);

const MarketingObjective = ({
    config = MARKETING_OBJECTIVES,
    disabled: disabledByParent = false,
    helpText,
    descriptionText,
    pixelAndConversionWarningEnabled,
    titleClassName,
}) => {
    const {
        value: marketingObjective = null,
        onChange: onMarketingObjectiveChange,
        indicationData,
        scrollRef,
    } = useMarketingObjectiveWithValidations();

    const { indicationType } = indicationData;
    const isPixelWarningPermitted = usePixelWarningPermitted();
    const isPixelWarningsTesting = useConfigMatch({ [FEATURE_FLAGS.TA_PIXEL_WARNINGS_TESTING]: 'true' });

    const dispatch = useDispatch();
    const {
        trackingStateData: { pixelTrackingData, hasAtLeastOneActiveConversion },
    } = useTrackingState(isPixelWarningPermitted);
    const { status: pixelStatus } = pixelTrackingData || {};

    const removeIndication = useCallback(
        staticIndicationType => dispatch(removeStaticIndication({ staticIndicationType })),
        [dispatch]
    );

    useEffect(() => {
        if (!isPixelWarningPermitted) {
            return;
        }
        if (pixelAndConversionWarningEnabled) {
            const noActivePixel =
                pixelStatus === TRACKING_STATUS.NOT_INSTALLED || pixelStatus === TRACKING_STATUS.NO_ACTIVITY;
            const noActiveConversions = !hasAtLeastOneActiveConversion;

            if (noActivePixel) {
                dispatch(addStaticIndication(NO_ACTIVE_PIXEL_INDICATION));
            } else if (noActiveConversions) {
                dispatch(addStaticIndication(NO_ACTIVE_CONVERSIONS_INDICATION));
            }
        }
        return () => {
            removeIndication(STATIC_INDICATION_TYPES.NO_ACTIVE_PIXEL);
            removeIndication(STATIC_INDICATION_TYPES.NO_ACTIVE_CONVERSIONS);
        };
    }, [
        pixelAndConversionWarningEnabled,
        pixelStatus,
        hasAtLeastOneActiveConversion,
        dispatch,
        removeIndication,
        isPixelWarningPermitted,
        isPixelWarningsTesting,
    ]);

    const [hovered, setHovered] = useState(null);

    const marketingObjectivesList = useAvailableItems(config);

    const handleMouseLeave = useCallback(
        key => {
            if (key !== hovered) {
                return;
            }
            setHovered(null);
        },
        [hovered, setHovered]
    );

    return (
        <FormFieldWithIndication
            inputId="marketing-objective"
            label={<FormattedMessage id="campaign.marketing.objective" defaultMessage="Marketing Objective" />}
            description={
                descriptionText ?? (
                    <FormattedMessage
                        id="campaign.marketing.objective.description"
                        defaultMessage="Select a primary goal for your campaign."
                    />
                )
            }
            helpText={
                helpText ?? (
                    <FormattedMessage
                        id="campaign.marketing.objective.help"
                        defaultMessage="Based on your marketing objective, Taboola will optimize your campaign for the best possible results."
                    />
                )
            }
            subtext={<MarketingObjectiveDescription hovered={hovered} selected={marketingObjective} />}
            subtextClass={styles['subtext-class']}
            ref={scrollRef}
            indicationContainerClass={
                indicationType === INDICATION_TYPES.WARNING ? commonStyles['warning-indication'] : null
            }
            {...indicationData}
        >
            <RadioGroup
                id="marketingObjective"
                name="marketing-objective"
                className={styles['group']}
                selectedValue={marketingObjective}
                onChange={onMarketingObjectiveChange}
                data-metrics-component="MarketingObjective"
                indicationType={indicationType}
            >
                {marketingObjectivesList.map(obj => {
                    const { id, icon: Icon, disabled } = obj;
                    const isDisabled = disabledByParent || disabled;

                    return (
                        <RadioIcon
                            key={id}
                            value={id}
                            title={<FormattedMessage id={`campaign.marketing.objective.${id}`} />}
                            iconComponent={
                                <Icon
                                    className={classNameBuilder({
                                        'disabled-icon': isDisabled,
                                        'enabled-icon': !isDisabled,
                                    })}
                                />
                            }
                            disabled={isDisabled}
                            labelClassName={styles['radio']}
                            titleClassName={classNameBuilder('title', titleClassName)}
                            onMouseEnter={() => setHovered(id)}
                            onMouseLeave={() => handleMouseLeave(id)}
                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                            data-metrics-value={id}
                        />
                    );
                })}
            </RadioGroup>
        </FormFieldWithIndication>
    );
};

MarketingObjective.propTypes = {
    config: PropTypes.array,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    descriptionText: PropTypes.string,
    pixelAndConversionWarningEnabled: PropTypes.bool,
};

export default MarketingObjective;
