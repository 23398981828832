import React, { useMemo } from 'react';
import { some } from 'lodash';
import { Button, AddIcon } from 'tuui';
import uuid from 'uuid/v1';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { UrlItem } from './UrlItem';
import styles from './urlList.module.scss';

const EMPTY_ARRAY = [];
const PLACEHOLDER_URL = { id: uuid(), value: '' };

export const UrlList = ({ fieldName, pixelEventType }) => {
    const { value: urls = EMPTY_ARRAY, onChange: setUrls } = useFormFieldValue({
        field: fieldName,
    });

    const actualUrls = useMemo(() => (urls.length ? urls : [PLACEHOLDER_URL]), [urls]);

    const hasEmptyItem = useMemo(() => some(actualUrls, item => !item.value), [actualUrls]);
    const addNewItem = () => setUrls(prevList => [...(prevList || []), PLACEHOLDER_URL]);
    const removeItem = idToDelete =>
        setUrls(prevList =>
            prevList.length === 1 ? [{ id: uuid(), value: '' }] : prevList.filter(({ id }) => id !== idToDelete)
        );

    const { formatMessage } = useIntl();
    const pixelType = formatMessage({ id: `video.creative.creator.pixel.${pixelEventType.pixelType}.label` });

    return (
        <>
            <FormNamespaceProvider field={fieldName}>
                {actualUrls?.map((item, index) => {
                    return (
                        <div key={item.id} className={styles['url-item']}>
                            <FormNamespaceProvider field={index} itemKey={item.id}>
                                <UrlItem existingItems={urls} deleteItem={removeItem} pixelEventType={pixelEventType} />
                            </FormNamespaceProvider>
                        </div>
                    );
                })}
            </FormNamespaceProvider>
            <Button
                onClick={addNewItem}
                variant={Button.variant.text}
                size={Button.size.md}
                disabled={hasEmptyItem}
                aria-label={`Add another ${pixelType} URL`}
            >
                <AddIcon />
                <FormattedMessage id="creative.creator.urls.button.label" defaultMessage="Add another URL" />
            </Button>
        </>
    );
};
