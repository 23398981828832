import { batch } from 'react-redux';
import { dimensionSelector, selectedAccountSelector } from 'selectors';
import { reportsApi } from 'services/api';
import { ERROR_CODES } from 'services/api/apiConstants';
import { COMPONENT_STATUS } from 'services/constants';
import { columnDefsStateSelector } from '../../../selectors';
import {
    getReportError,
    getReportSuccess,
    requestGetReport,
    getReportAbort,
    setReportDuration,
    setReportDataStatus,
    setColumnDef,
} from '../actions';
import { DEFAULT_REPORT_PAGE } from '../hooks';
import { filterReportSortByColumnDefs, flatDynamicFields } from '../services';
import { getColumnDefsWithDynamicColumns } from '../services/dynamicColumnsService';
import addLastUpdateTime from '../utils/lastUpdateTimeReportRowTransformer';
import { getReportFiltersParam, getDateRangeQueryParam, getReportQueryParams } from '../utils/queryFilterUtils';

const generateReportRowId = (row, reportId, rowFieldId) => `${reportId}_${row[rowFieldId]}`;

const transformReportData = ({
    results: reportData,
    lastUpdateTime,
    reportId,
    reportConfig: { rowIdField, lastUpdateTimeField },
    timeZoneName,
}) => {
    return reportData.map(({ dynamicFields, ...restRow }) => ({
        gridRowId: generateReportRowId(restRow, reportId, rowIdField),
        id: restRow[rowIdField],
        ...restRow,
        ...addLastUpdateTime({ rowData: restRow, lastUpdateTimeField, lastUpdateTime, timeZoneName }),
        ...flatDynamicFields(dynamicFields),
    }));
};

const defaultTransformTotal = ({ dynamicFields, ...restTotal } = {}) => ({
    ...restTotal,
    ...flatDynamicFields(dynamicFields),
});

const transformReport = ({
    data: { results = [], total, lastUpdateTime, metadata, ...rest },
    reportId,
    reportConfig,
    timeZoneName,
}) => {
    const transformTotal = reportConfig?.transformTotalOverride || defaultTransformTotal;
    return {
        results: transformReportData({ results, lastUpdateTime, reportId, reportConfig, timeZoneName }),
        total: total ? transformTotal(total) : null,
        metadata: { reportId, ...metadata },
        ...rest,
    };
};

const getReport =
    ({
        accountId,
        reportId,
        startDate,
        endDate,
        campaignId,
        reportPageSize,
        reportPage = DEFAULT_REPORT_PAGE,
        reportSort,
        searchTerm,
        reportFilters,
        reportQueryParams,
        reportConfig,
        allowedSortingColumnDefs = [],
        saveColumnStateByPresetAndColumnDefs,
        reportPresetName,
        reportPresetNameGetter,
        requestHashGetter,
    }) =>
    (dispatch, getState) => {
        const state = getState();
        const { timeZoneName } = selectedAccountSelector(state);
        const columnDefs = columnDefsStateSelector(state);
        const dimension = dimensionSelector(state);
        const resolvedReportSort = filterReportSortByColumnDefs(reportSort, columnDefs, allowedSortingColumnDefs);

        batch(() => {
            if (!columnDefs.length) {
                dispatch(setColumnDef(reportConfig.columnsDef));
            }
        });
        const queryParams = {
            ...getDateRangeQueryParam(startDate, endDate),
            ...getReportFiltersParam({
                campaignId,
                searchTerm,
                campaignIdFilterName: reportConfig.campaignIdFilterName,
                searchField: reportConfig.searchField,
                dynamicFilters: reportFilters,
            }),
            ...getReportQueryParams(reportPage, reportPageSize, {
                reportSort: resolvedReportSort,
                sortMap: reportConfig.sortMap,
            }),
            dimension,
            ...reportQueryParams,
        };
        const reportStart = Date.now();

        dispatch(requestGetReport());
        const requestHash = requestHashGetter();
        return reportsApi(reportConfig.endpoint, accountId, queryParams)
            .then(data =>
                batch(() => {
                    if (requestHash !== requestHashGetter()) {
                        return {};
                    }
                    const reportDuration = (Date.now() - reportStart) / 1000;
                    const report = transformReport({ data, reportId, reportConfig, timeZoneName });

                    dispatch(getReportSuccess({ reportId, ...report, reportConfig, reportDataRaw: data }));
                    dispatch(setReportDuration(reportDuration, reportPresetName));
                    dispatch(setReportDataStatus(COMPONENT_STATUS.ACTIVE));
                    const columnDefs = getColumnDefsWithDynamicColumns(reportId, reportConfig, report.metadata);
                    dispatch(setColumnDef(columnDefs));
                    saveColumnStateByPresetAndColumnDefs({
                        reportConfig,
                        reportId,
                        reportPresetName: reportPresetNameGetter(),
                        reportSorting: resolvedReportSort,
                        columnDefs,
                        autoUpdate: true,
                    });

                    return { data };
                })
            )
            .catch(error =>
                batch(() => {
                    if (error.status === ERROR_CODES.ABORT) {
                        dispatch(getReportAbort());
                    } else {
                        dispatch(getReportError(error));
                        dispatch(setReportDataStatus(COMPONENT_STATUS.ERROR));
                    }

                    return { error };
                })
            );
    };

export default getReport;
