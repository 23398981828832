import React from 'react';
import classnames from 'classnames/bind';
import { AlertOIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './rejectedKeywordsIncidation.module.scss';

const classNameBuilder = classnames.bind(styles);
const KeywordsMapDisplay = ({ keywordsMap }) => {
    return (
        <div data-testid="keywords-map-display" className={styles['keywords-map-display']}>
            <FormattedMessage
                id="audience.editor.external-audience.rejected-keywords-indication.restricted"
                defaultMessage="Moderately Restricted:"
            />
            {keywordsMap &&
                Object.values(keywordsMap).map((entry, index) => (
                    <div data-testid="keywords-display" key={index} className={styles['keywords-map']}>
                        <span className={styles['topics']}>{entry.topic} </span> -
                        <span> {entry.keywords.join(', ') + '.'}</span>
                    </div>
                ))}
        </div>
    );
};

export const RejectedKeywordsIndication = ({
    rejectedKeywords,
    keywordsMap,
    handleInvalidKeywords,
    className,
    showRemoveInvalidKeywordsButton,
}) => {
    return (
        <div className={classNameBuilder('container', className)} data-testid="rejected-audiences-indication">
            <div className={styles['body']}>
                <div className={styles['title']}>
                    <AlertOIcon data-testid="alert-icon" className={styles['error-icon']} />
                    <FormattedMessage
                        id="audience.editor.external-audience.rejected-keywords-indication.title"
                        defaultMessage="<b>Policy restrictions -</b>"
                    />
                </div>
                <FormattedMessage
                    id="audience.editor.external-audience.rejected-keywords-indication.description"
                    defaultMessage="Please address the invalid keywords to ensure the approval of your segment."
                />
                {showRemoveInvalidKeywordsButton && (
                    <button
                        data-testid="remove-button"
                        className={styles['remove-button']}
                        onClick={() => handleInvalidKeywords(rejectedKeywords)}
                    >
                        Remove invalid keywords
                    </button>
                )}
                <KeywordsMapDisplay keywordsMap={keywordsMap} />
            </div>
        </div>
    );
};
