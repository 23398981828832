import { compact, flatten, zip } from 'lodash';
import { FileTypes } from 'taboola-ultimate-ui';
import RECOMMENDED_MEDIA_DROPDOWN_TYPES from 'modules/campaigns/modules/creative-editor/components/MediaTabs/RecommendedMediaTab/RecommendedMediaDropdownTypes';
import { DEFAULT_MEDIA_PAGE_SIZE } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/services/useMediaFetchService';
import { operationsApi } from '../../../../../services/api';
import {
    requestFetchRecommendedMedia,
    fetchRecommendedMediaSuccess,
    fetchRecommendedMediaError,
    resetRecommendedMedia,
} from '../actions';

const emptyRecommendedMedia = [],
    emptyTotalRecommendedMedia = 0;

const getRecommendedMediaStateParams = getState => {
    const recommendedMedia = getState().campaignsReducer?.recommendedMedia,
        shouldFetchRecommendedVideosState = recommendedMedia?.shouldFetchRecommendedVideos ?? true,
        shouldFetchRecommendedImagesState = recommendedMedia?.shouldFetchRecommendedImages ?? true;
    return { shouldFetchRecommendedVideosState, shouldFetchRecommendedImagesState };
};

const getPageSizeForMediaTypeALL = ({ pageSize, shouldFetchImages, shouldFetchVideos }) => {
    if (shouldFetchImages && shouldFetchVideos) {
        return Math.floor(pageSize / 2);
    }
    return pageSize;
};

const shouldFetchRecommendedMedia = (responseItemsCount, mediaType) => {
    if (mediaType === RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL) {
        return responseItemsCount >= Math.floor(DEFAULT_MEDIA_PAGE_SIZE / 2);
    }
    return responseItemsCount >= DEFAULT_MEDIA_PAGE_SIZE;
};

const fetchImages = async ({ accountId, keywords, page, pageSize, mediaType }) => {
    const {
        results,
        metadata: { total, count },
    } = await operationsApi.getRecommendedImages({
        accountId,
        keywords,
        page,
        pageSize,
    });
    await results.map(item => (item.fileType = FileTypes.IMAGE));
    return { results, total, shouldFetchRecommendedImages: shouldFetchRecommendedMedia(count, mediaType) };
};

const fetchVideos = async ({ accountId, keywords, page, pageSize, mediaType }) => {
    const {
        results,
        metadata: { total, count },
    } = await operationsApi.getRecommendedVideos({
        accountId,
        keywords,
        page,
        pageSize,
    });
    await results.map(item => (item.fileType = FileTypes.VIDEO));
    return { results, total, shouldFetchRecommendedVideos: shouldFetchRecommendedMedia(count, mediaType) };
};

const fetchAll = async ({ accountId, keywords, page, pageSize, shouldFetchImages, shouldFetchVideos }) => {
    const pageSizeForMediaTypeALL = getPageSizeForMediaTypeALL({
        pageSize,
        shouldFetchImages,
        shouldFetchVideos,
    });
    let fetchImagesPromise = {},
        fetchVideosPromise = {};
    if (shouldFetchImages) {
        fetchImagesPromise = fetchImages({
            accountId,
            keywords,
            page,
            pageSize: pageSizeForMediaTypeALL,
            mediaType: RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL,
        });
    }
    if (shouldFetchVideos) {
        fetchVideosPromise = fetchVideos({
            accountId,
            keywords,
            page,
            pageSize: pageSizeForMediaTypeALL,
            mediaType: RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL,
        });
    }
    const [fetchImageResults, fetchVideoResults] = await Promise.all([fetchImagesPromise, fetchVideosPromise]);
    const { shouldFetchRecommendedImages = shouldFetchImages } = fetchImageResults;
    const { shouldFetchRecommendedVideos = shouldFetchVideos } = fetchVideoResults;
    const [results, total] = mergeImageAndVideoResults({
        fetchImageResults,
        fetchVideoResults,
    });
    return { results, total, shouldFetchRecommendedImages, shouldFetchRecommendedVideos };
};

const mergeImageAndVideoResults = ({ fetchImageResults, fetchVideoResults }) => {
    const { results: imageResults, total: totalImages } = fetchImageResults;
    const { results: videoResults, total: totalVideos } = fetchVideoResults;
    if (videoResults === undefined || videoResults.length === 0) {
        return [imageResults, totalImages];
    }
    const mergedResults = compact(flatten(zip(videoResults, imageResults)));
    const total = totalVideos + totalImages;
    return [mergedResults, total];
};

const fetchMap = {
    [RECOMMENDED_MEDIA_DROPDOWN_TYPES.IMAGES]: fetchImages,
    [RECOMMENDED_MEDIA_DROPDOWN_TYPES.VIDEOS]: fetchVideos,
    [RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL]: fetchAll,
};

const fetchRecommendedMedia =
    ({ accountId, mediaType, isMediaTypeChanged, tags, page, pageSize, maxTotalItems }) =>
    async (dispatch, getState) => {
        if (isMediaTypeChanged || tags.length === 0) {
            dispatch(resetRecommendedMedia());
        }
        if (!tags.length) {
            return [emptyRecommendedMedia, emptyTotalRecommendedMedia];
        }
        dispatch(requestFetchRecommendedMedia());
        try {
            const { shouldFetchRecommendedVideosState, shouldFetchRecommendedImagesState } =
                getRecommendedMediaStateParams(getState);
            const { results, total, shouldFetchRecommendedImages, shouldFetchRecommendedVideos } = await fetchMap[
                mediaType
            ]({
                accountId,
                keywords: tags.map(item => item.label),
                page,
                pageSize,
                shouldFetchImages: shouldFetchRecommendedImagesState,
                shouldFetchVideos: shouldFetchRecommendedVideosState,
            });
            dispatch(
                fetchRecommendedMediaSuccess({
                    recommendedMedia: results,
                    shouldFetchRecommendedImages,
                    shouldFetchRecommendedVideos,
                })
            );
            return [results, Math.min(total, maxTotalItems)];
        } catch (error) {
            dispatch(fetchRecommendedMediaError(error));
            return [error];
        }
    };
export default fetchRecommendedMedia;
