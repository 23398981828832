import { Color, Scale, ITheme, SizeUnit } from 'tuui/lib/theme';
import { Path, get } from 'tuui/lib/utils/typing/prop';

const HEX_RE = /#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/i;
const RGB_RANGE = 0xff;

export function colorToRGB(color: Color) {
    const hexMatch = color.match(HEX_RE);
    if (!hexMatch) {
        throw new Error('Currently, only hex colors are supported');
    }
    const [r, g, b] = hexMatch.slice(1).map(hex => parseInt(hex, 16)) as [number, number, number];

    return {
        r,
        g,
        b,
    };
}

export function rgbToHSL(red: number, green: number, blue: number) {
    red /= RGB_RANGE;
    green /= RGB_RANGE;
    blue /= RGB_RANGE;

    const maxColor = Math.max(red, green, blue);
    const minColor = Math.min(red, green, blue);
    const delta = maxColor - minColor;
    const lightness = (maxColor + minColor) / 2;

    let saturation = 0;
    let hue = 0;

    if (delta) {
        saturation = lightness > 0.5 ? delta / (2 - delta) : delta / (maxColor + minColor);
        switch (maxColor) {
            case red:
                hue = (green - blue) / delta + (green < blue ? 6 : 0);
                break;
            case green:
                hue = (blue - red) / delta + 2;
                break;
            case blue:
                hue = (red - green) / delta + 4;
                break;
        }
        hue /= 6;
    }
    return {
        h: Math.round(hue * 360),
        s: Math.round(saturation * 100),
        l: Math.round(lightness * 100),
    };
}

export function mapColorByHSL(
    color: Color,
    mapper: (hsl: ReturnType<typeof rgbToHSL>) => ReturnType<typeof rgbToHSL> | void
): Color {
    const rgb = colorToRGB(color);
    const hsl = rgbToHSL(rgb.r, rgb.g, rgb.b);
    const newHsl = mapper(hsl) ?? hsl;
    return `hsl(${newHsl.h}, ${newHsl.s}%, ${newHsl.l}%)`;
}

export const getScaledSize = (theme: ITheme, scale: Scale) =>
    `${theme.sizes.base * theme.sizes.scales[scale]}px` as SizeUnit;

export const theme = <T extends { theme: ITheme }, P extends Path<ITheme>>(path: P) => (obj: T) => get(obj.theme, path);

export const scale = (scale: Scale) => ({ theme }: { theme: ITheme }) => getScaledSize(theme, scale);
