import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOverlay } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import CancelCreationButton from './CancelCreationButton';
import OneByOneLoader from './OneByOneLoader';
import styles from './oneByOneLoadingOverlay.module.scss';

const SECOND = 1000;
const TEN_SECONDS = 10 * SECOND;

const allowCancelLabel = {
    id: 'creative.creator.creation.mode.ONE_BY_ONE.loading.overlay.warning',
    defaultMessage: 'Looks like this is taking longer than expected',
};

const crawlingUrlsLabel = {
    id: 'creative.creator.creation.mode.ONE_BY_ONE.loading.overlay.crawling',
    defaultMessage: 'Fetching Items From URLs',
};

const creatingCreativesLabel = {
    id: 'creative.creator.creation.mode.ONE_BY_ONE.loading.overlay.creating',
    defaultMessage: 'Creating Ads',
};

const OneByOneLoadingOverlay = ({ onCancel, shouldCrawlUrls, currentUrlIndex, totalUrls }) => {
    const [allowCancel, setAllowCancel] = useState(false);
    const [isCrawlingUrls, setIsCrawlingUrls] = useState(shouldCrawlUrls);
    const percentageOfUrlsCrawled = (currentUrlIndex / totalUrls) * 100;
    const label = isCrawlingUrls ? crawlingUrlsLabel : creatingCreativesLabel;
    const canCancel = allowCancel && isCrawlingUrls;

    useEffect(() => {
        if (isCrawlingUrls && percentageOfUrlsCrawled >= 100) {
            let timeout = setTimeout(() => {
                setIsCrawlingUrls(false);
            }, 300);
            return () => clearTimeout(timeout);
        }
    }, [percentageOfUrlsCrawled, isCrawlingUrls]);

    useEffect(() => {
        const timer = setTimeout(() => setAllowCancel(true), TEN_SECONDS);

        return () => clearTimeout(timer);
    }, []);

    return (
        <LoadingOverlay className={styles['container']}>
            <OneByOneLoader showCounter={isCrawlingUrls} current={currentUrlIndex} total={totalUrls} />
            <FormattedMessage className={styles['label']} {...(canCancel ? allowCancelLabel : label)} />
            {canCancel && <CancelCreationButton onClick={onCancel} />}
        </LoadingOverlay>
    );
};

OneByOneLoadingOverlay.propTypes = {
    onCancel: PropTypes.func,
    shouldCrawlUrls: PropTypes.bool,
    currentUrlIndex: PropTypes.number,
    totalUrls: PropTypes.number,
};

export default OneByOneLoadingOverlay;
