import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { clearRecommendations } from '../../recommendations';
import { recommendationsDataSelector } from '../../recommendations/selectors';
import { useAccount } from '.././account-configurations/hooks/useAccount';
import { AccountProvider } from '../account-configurations/AccountProvider';
import { ValidationContextProvider } from '../validations';
import { FormDataProvider } from './FormDataContext';
import useFormValidation from './hooks/useFormValidation';

export const withGridCellFormDataProvider = Component => props => {
    const { data } = props;
    const { accountName } = useAccount();
    const resolvedAccountName = props.data?.accountName ?? accountName;
    const dispatch = useDispatch();
    const recommendationData = useSelector(recommendationsDataSelector);
    const initialData = useRef(() => cloneDeep(recommendationData || data));
    const { validationContext } = useFormValidation();

    useEffect(() => () => dispatch(clearRecommendations()), [dispatch]);

    return (
        <AccountProvider accountName={resolvedAccountName} onError={props.stopEditing} fetchRetryCount={0}>
            <FormDataProvider initialData={initialData.current} mode={FORM_MODES.EDIT}>
                <ValidationContextProvider key={validationContext.version} value={validationContext}>
                    <Component {...props} />
                </ValidationContextProvider>
            </FormDataProvider>
        </AccountProvider>
    );
};
