import { useMemo } from 'react';
import { get } from 'lodash';
import { INPUT_GROUP_TYPES, InputTypes } from 'taboola-ultimate-ui';
import { useDropdownOptions, useDropdownValue } from 'hooks';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { AdSetupUrlConditionPredicateOption } from './AdSetupUrlConditionPredicateOption';

export const OS_FAMILY_KEYS = {
    ANDROID: 'Android',
    IOS: 'iOS',
};

const conversionSetupPredicateOptions = [
    { value: OS_FAMILY_KEYS.ANDROID, label: 'Play store (Android)' },
    { value: OS_FAMILY_KEYS.IOS, label: 'App store (iOS)' },
];

export const useAdSetupUrlInputGroupConfig = ({
    osFamily,
    onChangeOsFamily = () => {},
    onChangeAppUrlHandler,
    appUrl: formAppUrl,
    additionalConfig = {},
}) => {
    const { formatMessage } = useIntl();
    const { isAppInstall: isAppInstallCreativeInEditMode } = useIsAppInstallCreativeInEditMode();
    const { data: creative } = useFormDataContext();
    const appUrl = !isAppInstallCreativeInEditMode ? formAppUrl : get(creative, 'appInstall.appUrl', '');
    const msgIdPrefix = additionalConfig.msgIdPrefix || 'creative.creator.ad.setup.app.url.dropdown';
    const options = useDropdownOptions(
        additionalConfig.predicateOptions || conversionSetupPredicateOptions,
        msgIdPrefix
    );
    const selectedValue = useDropdownValue({ value: osFamily }, msgIdPrefix);

    const predicateDropdownConfig = useMemo(
        () => ({
            type: INPUT_GROUP_TYPES.DROPDOWN,
            dataKey: 'predicate',
            props: {
                value: osFamily,
                options,
                optionItemRenderer: additionalConfig.optionItemRenderer || AdSetupUrlConditionPredicateOption,
                selectedValueObject: selectedValue,
                width: additionalConfig.width || '240px',
                height: additionalConfig.height || '100%',
                disabled: additionalConfig.disabledDropdown ?? true,
                className: additionalConfig.dropDownClass || '',
                onChange: e => onChangeOsFamily(e.value),
            },
        }),
        [osFamily, options, selectedValue, additionalConfig, onChangeOsFamily]
    );

    const urlInputConfig = useMemo(
        () => ({
            type: INPUT_GROUP_TYPES.INPUT,
            dataKey: 'url',
            props: {
                placeholder: formatMessage({
                    id: additionalConfig.placeholderId || 'creative.creator.ad.setup.app.url.placeholder',
                    defaultMessage: additionalConfig.placeholderDefaultMessage || 'App URL',
                }),
                value: appUrl,
                type: InputTypes.TEXT,
                onChange: onChangeAppUrlHandler,
                inputClass: additionalConfig.inputClass || '',
                inputWrapperClass: additionalConfig.inputWrapperClass || '',
                disabled: isAppInstallCreativeInEditMode,
            },
        }),
        [appUrl, formatMessage, onChangeAppUrlHandler, isAppInstallCreativeInEditMode, additionalConfig]
    );

    const inputGroupConfig = useMemo(
        () => [predicateDropdownConfig, urlInputConfig],
        [predicateDropdownConfig, urlInputConfig]
    );

    return { inputGroupConfig };
};
