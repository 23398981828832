import { useCallback } from 'react';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { useDBStorage } from 'modules/taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { useCurrentValueGetter } from '../../../../../hooks';

const getPresetAccountIdDBKey = (reportId, presetName) =>
    `${REPORT_SETTINGS_PREFIX}.${reportId}.presets.${presetName}.accountId`;

export const useCustomPresetAccountId = (reportId, presetName) => {
    const key = getPresetAccountIdDBKey(reportId, presetName);
    const { getter, setter } = useDBStorage();
    const presetAccountId = getter(key);
    const reportIdGetter = useCurrentValueGetter(reportId);
    const presetNameGetter = useCurrentValueGetter(presetName);

    const setPresetAccountId = useCallback(
        (value, passedReportId, passedPresetName) => {
            const resolvedReportId = passedReportId || reportIdGetter();
            const resolvedPresetName = passedPresetName || presetNameGetter();

            const key = getPresetAccountIdDBKey(resolvedReportId, resolvedPresetName);
            setter(key, value);
        },
        [setter, reportIdGetter, presetNameGetter]
    );

    return [presetAccountId, setPresetAccountId];
};
