import { HelpTooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const SafetyNetsStatusCellEditorTooltip = () => (
    <HelpTooltip>
        <TooltipSection>
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.tooltip.title"
                defaultMessage="<strong>Capped by SpendGuard:</strong>"
            />
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.tooltip"
                defaultMessage="To disable SpendGuard on this site, click 'Disable SpendGuard'.
Disabling them means the algorithm won't cap or block it in the future.
You can re-enable SpendGuard using the same edit button."
            />
        </TooltipSection>
    </HelpTooltip>
);
