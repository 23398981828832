import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useNotificationsApi } from 'services/api/notificationsApi';
import { generateErrorIndication } from 'services/utils';

export const useNotificationsActions = ({
    viewParams,
    dismissParams,
    actionParams,
    cardClickParams,
    onViewSuccess = noop,
    onCardClickSuccess = noop,
    onDismissSuccess = noop,
    onActionSuccess = noop,
}) => {
    const dispatch = useDispatch();
    const notificationsApi = useNotificationsApi();

    const onView = useCallback(() => notificationsApi.viewNotifications(viewParams), [viewParams, notificationsApi]);

    const onViewError = () => {
        dispatch(addIndication(generateErrorIndication({ messageCode: viewParams.errorCode })));
    };

    const onAction = useCallback(
        () => notificationsApi.actionNotification(actionParams),
        [actionParams, notificationsApi]
    );

    const onActionError = () => {
        dispatch(addIndication(generateErrorIndication({ messageCode: actionParams.errorCode })));
    };

    const onCardClick = useCallback(
        () => notificationsApi.clickNotification(cardClickParams),
        [cardClickParams, notificationsApi]
    );

    const onCardClickError = () => {
        dispatch(addIndication(generateErrorIndication({ messageCode: cardClickParams.errorCode })));
    };

    const onDismiss = useCallback(
        () => notificationsApi.dismissNotification(dismissParams),
        [dismissParams, notificationsApi]
    );

    const onDismissError = () => {
        dispatch(addIndication(generateErrorIndication(dismissParams.errorCode)));
    };

    const { mutate: onMutateView } = useMutation(onView, {
        onSuccess: () => onViewSuccess(viewParams),
        onError: onViewError,
    });

    const { mutate: onMutateCardClickState } = useMutation(onCardClick, {
        onSuccess: () => onCardClickSuccess(cardClickParams),
        onError: onCardClickError,
    });

    const { mutate: onMutateDismiss } = useMutation(onDismiss, {
        onSuccess: () => onDismissSuccess(dismissParams),
        onError: onDismissError,
    });

    const { mutate: onMutateAction } = useMutation(onAction, {
        onSuccess: () => onActionSuccess(actionParams),
        onError: onActionError,
    });

    return {
        onView: onMutateView,
        onCardClick: onMutateCardClickState,
        onDismiss: onMutateDismiss,
        onAction: onMutateAction,
    };
};
