import React from 'react';
import { isEmpty } from 'lodash';
import { TrackingSectionIcon } from 'taboola-ultimate-ui';
import { CollapsibleFormSection } from 'components';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import ThirdPartyTags from '../ThirdPartyTags';
import { useThirdPartyTagsFormFieldValue } from '../ThirdPartyTags/useThirdPartyTagsFormFieldValue';

export const TrackingSection = ({ children }) => {
    const { value: thirdPartyTags, isPermitted } = useThirdPartyTagsFormFieldValue();

    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_TRACKING"
            header={
                <SectionHeader
                    headerIcon={<TrackingSectionIcon />}
                    headerText={<FormattedMessage id="campaign.editor.tracking" />}
                />
            }
        >
            {children}
            {isPermitted ? (
                <CollapsibleFormSection
                    isInitiallyCollapsed={isEmpty(thirdPartyTags)}
                    title={<FormattedMessage id="campaign.advancedOptions" defaultMessage="Advanced Options" />}
                    subtitle={
                        <FormattedMessage id="campaign.advancedOptions.tracking" defaultMessage="3rd Party Tags" />
                    }
                    sectionId="CAMPAIGN_SUBSECTION_ADVANCED_TRACKING"
                    testId="tracking-advanced-options"
                >
                    <ThirdPartyTags />
                </CollapsibleFormSection>
            ) : null}
        </CommonCollapsibleCard>
    );
};

export default TrackingSection;
