import React, { useCallback } from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useDropdownOptions } from '../../../../hooks';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { formFieldId, FormFieldWithIndication, msgIdPrefix } from './ScheduledReportDateRangeFieldConsts';
import { dateRangeOptions } from './scheduledReportDateRangeFieldOptions';
import styles from './scheduledReportDateRangeField.module.scss';

export const ScheduledReportDateRangeField = () => {
    const { value: dateRange, onChange: setDateRange } = useFormFieldValue({ field: 'dateRange' });
    const options = useDropdownOptions(dateRangeOptions, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: dateRange }) => {
            setDateRange(dateRange);
        },
        [setDateRange]
    );
    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['date-range-container']}
            label={
                <FormattedMessage
                    id="app.scheduled.reports.create.date.range.select.title"
                    defaultMessage="Date Range"
                />
            }
            labelClass={styles['label']}
        >
            <div className={styles['date-range']}>
                <DropdownMenu
                    id={formFieldId}
                    selectedValueObject={{
                        value: dateRange,
                    }}
                    options={options}
                    onChange={changeHandler}
                    searchable
                    aria-label="date-range-dropdown"
                />
            </div>
        </FormFieldWithIndication>
    );
};
