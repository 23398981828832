import React from 'react';
import classnames from 'classnames/bind';
import { ErrorOutlineIcon, WarningAmberIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './creativeStudioContentBanner.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CONTENT_BANNER_TYPES = {
    ERROR: 'error',
    WARNING: 'warning',
};

export const ICON_TYPE_MAP = {
    [CONTENT_BANNER_TYPES.ERROR]: ErrorOutlineIcon,
    [CONTENT_BANNER_TYPES.WARNING]: WarningAmberIcon,
};

export const CreativeStudioContentBanner = ({
    id,
    defaultMessage,
    type = CONTENT_BANNER_TYPES.ERROR,
    className,
    children,
    suffix,
}) => {
    const Icon = ICON_TYPE_MAP[type];

    return (
        <div className={classNameBuilder('container', type, className)} data-testid="content-banner">
            <Icon fontSize="large" className={styles['icon']} />
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
            {children}
            {suffix && <div className={styles['suffix']}>{suffix}</div>}
        </div>
    );
};
