import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { SectionHeader } from '../../../campaigns/components';
import { REPORT_TYPE } from '../../../campaigns/config';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { AUDIENCE_TYPE } from '../../config';
import { useAudienceTypeFormField } from '../../hooks/useAudienceTypeFormField';
import AudienceNameField from '../AudienceNameField/AudienceNameField';
import AudienceSeedField from '../AudienceSeedField/AudienceSeedField';
import AudienceTypeField from '../AudienceTypeField/AudienceTypeField';
import { CrmFileDataTypeField } from '../CrmFileDataTypeField/CrmFileDataTypeField';

export const PredictiveAudienceSetupSection = () => {
    const { mode, formAccount } = useFormDataContext();
    const { value: type } = useAudienceTypeFormField();

    return (
        <CommonCollapsibleCard
            id="LOOKALIKE_AUDIENCE_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.setup.section.title"
                            defaultMessage="Audience Setup"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && <AudienceNameField />}
            <br />
            <AudienceTypeField />
            {type === AUDIENCE_TYPE.PBP_LOOKALIKE && (
                <>
                    <br />
                    <AudienceSeedField account={formAccount} />
                </>
            )}
            {type === AUDIENCE_TYPE.LOOKALIKE && (
                <>
                    <br />
                    <CrmFileDataTypeField reportType={REPORT_TYPE.PREDICTIVE} />
                    <br />
                </>
            )}
        </CommonCollapsibleCard>
    );
};
