import React from 'react';
import { FormField, HelpTooltip } from 'taboola-ultimate-ui';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BetaTag } from '../../../../../../components';
import PRICING_MODEL from '../../../../config/pricingModel';
import { CustomContextualSegments } from './CustomContextualSegments';
import { CustomContextualSegmentsFieldTooltip } from './CustomContextualSegmentsFieldTooltip';
import styles from './customContextualSegmentsField.module.scss';

export const CustomContextualSegmentsField = () => {
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const { value: pricingModel } = useFormFieldValue({ field: 'pricingModel' });

    return (
        <FormField
            inputId="custom-contextual-segments"
            label={
                <div className={styles['label']}>
                    <div className={styles['label-tooltip-wrapper']}>
                        <FormattedMessage
                            id="campaign.editor.targeting.custom.contextual.segments.title"
                            defaultMessage="Topics Segments"
                        />
                        <HelpTooltip content={<CustomContextualSegmentsFieldTooltip />} />
                    </div>
                    {pricingModel === PRICING_MODEL.CPC && <BetaTag tagClassName={styles['tag']} />}
                </div>
            }
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.custom.contextual.segments.description"
                    defaultMessage="Target Topics based segments."
                />
            }
            containerClass={styles['input']}
        >
            <CustomContextualSegments selectedAccountId={accountName} />
        </FormField>
    );
};
