'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DateRangePickerDay = function DateRangePickerDay(_ref) {
    var day = _ref.day;

    var today = (0, _moment2.default)().isSame(day, 'days');
    return _react2.default.createElement(
        'div',
        { className: today ? 'single-date-div today' : 'single-date-div' },
        _react2.default.createElement(
            'span',
            null,
            day.format('DD')
        ),
        _react2.default.createElement('div', { className: 'selected-date-circle' }),
        _react2.default.createElement('div', { className: 'selected-date-background' })
    );
};

DateRangePickerDay.propTypes = {
    day: _reactMomentProptypes2.default.momentObj.isRequired
};

exports.default = DateRangePickerDay;