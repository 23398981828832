import React, { useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../../../../components/Form';
import { FOCUS_TYPES } from '../../../config';
import { FocalPointApplyCheckbox } from './FocalPointApplyCheckbox';
import FocalPointEditorHeader from './FocalPointEditorHeader';
import FocalPointImage from './FocalPointImage';
import styles from './focalPointEditor.module.scss';

const classNameBuilder = classnames.bind(styles);

const FocalPointEditor = ({
    className,
    imageSrc,
    imageClassName,
    imageContainerClassName,
    creativeFocus,
    applyFocusToMatches,
    setApplyFocusToMatches,
    numMatchingCreatives,
    onCancel,
    onDone,
    onChangeCoordinates,
    onResetFocalPoint,
}) => {
    const { type, coordinates } = creativeFocus;
    const [initialCreativeFocus] = useState(creativeFocus);

    const isFlagged = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_FOCUS_APPLY_TO_ALL]: 'true',
    });

    const handleCancel = () => {
        onCancel && onCancel(initialCreativeFocus);
    };

    return (
        <div className={className} aria-label="focal point editor">
            <FocalPointEditorHeader isResetVisible={type !== FOCUS_TYPES.AUTOMATIC} onReset={onResetFocalPoint} />
            <div className={classNameBuilder('image-container', imageContainerClassName)}>
                <FocalPointImage
                    imageClassName={imageClassName}
                    imageSrc={imageSrc}
                    focusType={type}
                    focusCoordinates={coordinates}
                    onChangeCoordinates={onChangeCoordinates}
                />
            </div>
            {isFlagged && numMatchingCreatives > 0 && (
                <FocalPointApplyCheckbox
                    className={styles['input-section']}
                    isApplied={applyFocusToMatches}
                    setApply={setApplyFocusToMatches}
                    creativeCount={numMatchingCreatives + 1}
                />
            )}
            <FormButtons
                className={styles['input-section']}
                onCancel={handleCancel}
                onSubmit={onDone}
                submitLabel={<FormattedMessage id="app.actionButtons.done" defaultMessage="Done" />}
                submitDataMetricAttrs={{
                    'data-metrics-component': 'FocalPointDoneButton',
                }}
                showFooterValidations={false}
            />
        </div>
    );
};

FocalPointEditor.propTypes = {
    className: PropTypes.string,
    imageSrc: PropTypes.string,
    imageClassName: PropTypes.string,
    imageContainerClassName: PropTypes.string,
    creativeFocus: PropTypes.object,
    applyFocusToMatches: PropTypes.bool,
    setApplyFocusToMatches: PropTypes.func,
    numMatchingCreatives: PropTypes.number,
    onCancel: PropTypes.func,
    onDone: PropTypes.func,
    onChangeCoordinates: PropTypes.func,
    onResetFocalPoint: PropTypes.func,
};

FocalPointEditor.defaultProps = {
    creativeFocus: {},
};

export default FocalPointEditor;
