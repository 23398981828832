import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TimeFramePicker } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { withValidations } from 'modules/taboola-common-frontend-modules/validations';

const validations = [
    {
        validationFn: (value, options, { startDate }) => startDate,
        messageId: 'validations.error.dashboard.range.startDate.required',
    },
    {
        validationFn: (value, options, { startDate }) => moment(startDate).isValid(),
        messageId: 'validations.error.dashboard.range.startDate.invalid',
    },
    {
        validationFn: (value, options, { endDate }) => !endDate || moment(endDate).isValid(),
        messageId: 'validations.error.dashboard.range.endDate.invalid',
    },
    {
        validationFn: (value, options, { endDate, startDate }) =>
            !endDate || moment(startDate).isBefore(moment(endDate).endOf('day')),
        messageId: 'validations.error.dashboard.range.endDate.beforeStartDate',
    },
];

const TimeFramePickerWithValidation = withValidations(withIndication(TimeFramePicker), { validations });

const TimeFrame = ({ startDate, endDate, ...rest }) => {
    const validationContext = { startDate, endDate };
    return (
        <div>
            <TimeFramePickerWithValidation
                startDate={startDate}
                endDate={endDate}
                validationContext={validationContext}
                valueToValidate={validationContext}
                validationId="dashboardRangePicker"
                {...rest}
            />
        </div>
    );
};

TimeFrame.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onStartDateChange: PropTypes.func,
    onEndDateChange: PropTypes.func,
};

export default TimeFrame;
