import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from 'taboola-ultimate-ui';
import { ExternalLink } from '../../../../../../components';
import styles from './externalLinkWithIcon.module.scss';

const ExternalLinkWithIcon = ({ url }) => (
    <ExternalLink className={styles['icon-container']} title={url} href={url}>
        <LinkIcon className={styles['icon']} width={16} height={16} />
    </ExternalLink>
);

ExternalLinkWithIcon.protoTypes = {
    url: PropTypes.string,
};

export default ExternalLinkWithIcon;
