import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const SiteTargetingTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.site.help.overview"
                defaultMessage="Block sites based on performance or brand safety. This means your campaign will not appear on those sites. If a site's performance is below average, a great optimisation is to reduce the bid for that site, this reduces your CPA from the site and allows you to still get traffic without blocking the source entirely."
            />
        </TooltipSection>
    </div>
);

export default SiteTargetingTooltip;
