import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import _ from 'lodash';
import { CollapsibleList, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { DropdownOption } from 'components/Dropdown';
import { useAvailableItems } from 'hooks';
import { withIndication } from 'modules/errors/components/withIndication';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { brandSafetyCategoriesSelector } from '../../../../selectors';
import BRAND_SAFETY_TYPES, { BRAND_SAFETY_TYPES_OPTIONS } from '../../config/BrandSafeTypes';
import BRAND_SAFETY_RISK_LEVEL from '../../config/BrandSafetyRiskLevel';
import { fetchBrandSafetyCategories } from '../../flows';
import { useSelectedValueToOptionMapping } from '../../hooks';
import { SUPPLY_TARGETING_TYPES } from '../SupplyTargeting/const';
import { useSupplyTargetingFormField } from '../SupplyTargeting/hooks/useSupplyTargetingFormField';
import TargetingDropdownBox from '../TargetingDropdownBox/TargetingDropdownBox';
import BrandSafetyItem from './BrandSafetyItem';
import BrandSafetyTypeDropdownOption from './BrandSafetyTypeDropdownOption';
import { useThirdPartyBrandSafetyTargetingType } from './useThirdPartyBrandSafetyTargetingType';
import { useThirdPartyBrandSafetyTargetingValues } from './useThirdPartyBrandSafetyTargetingValues';
import styles from './ThirdPartyBrandSafetySmartList.module.scss';

const NUMBER_IAS_RISK_LEVEL = 1;
const NUMBER_DV_RISK_LEVEL = 2;
const DEFAULT_RISK_LEVEL = BRAND_SAFETY_RISK_LEVEL.HIGH;
const MSG_ID_PREFIX = 'campaign.editor.targeting.3rd.party.brand.safety';
const CollapsibleListWithIndication = withIndication(CollapsibleList);
const validations = [
    {
        validationFn: value => value.length === 0,
        severity: INDICATION_TYPES.WARNING,
        messageId: 'campaign.editor.targeting.3rd.party.brand.safety.warning',
        defaultMessage: 'Targeting multiple Brand Safety segments and tiers will narrow your campaign’s scale & reach.',
    },
];

const ListHeaderTitle = () => (
    <FormattedMessage id={`${MSG_ID_PREFIX}.title.selected`} defaultMessage="Selected Brand Safety Categories" />
);
const Placeholder = () => (
    <FormattedMessage
        id={`${MSG_ID_PREFIX}.select.placeholder`}
        defaultMessage="Select Brand Safety and Suitability Segments..."
    />
);

export const useThirdPartyBrandSafety = (values, options) =>
    useMemo(() => {
        const valuesByCategoryName = values.map(({ categoryName }) => categoryName);
        const selectedCategoryNames = new Set(valuesByCategoryName);
        const allOptions = options.map(({ name, value, hasRiskLevel }) => ({
            value: name,
            label: value,
            shouldUseRiskLevelSlider: hasRiskLevel,
        }));
        const availableOptions = allOptions.filter(({ label }) => !selectedCategoryNames.has(label));

        return [valuesByCategoryName, availableOptions, allOptions];
    }, [values, options]);

const itemHeight = ({ shouldUseRiskLevelSlider }) => (shouldUseRiskLevelSlider ? 74 : 40);

const ThirdPartyBrandSafetySmartList = () => {
    const dispatch = useDispatch();

    const hasDvPermission = usePermissions('ADS_CONSOLE_THIRD_PARTY_BRAND_SAFETY_DV');
    const defaultThirdPartyBrandSafetyType = hasDvPermission ? BRAND_SAFETY_TYPES.DV : BRAND_SAFETY_TYPES.IAS;
    const { value: campaignBrandSafetyTypeFromDb, onChange: setType } = useThirdPartyBrandSafetyTargetingType();
    const { value: values, onChange: setValues } = useThirdPartyBrandSafetyTargetingValues();

    const type =
        campaignBrandSafetyTypeFromDb === BRAND_SAFETY_TYPES.NONE
            ? defaultThirdPartyBrandSafetyType
            : campaignBrandSafetyTypeFromDb;
    const brandSafetyCategories = useSelector(brandSafetyCategoriesSelector);
    const providerBrandSafetyCategories = useMemo(
        () => (brandSafetyCategories[type] ? brandSafetyCategories[type] : []),
        [type, brandSafetyCategories]
    );

    const [valuesByCategoryName, availableOptions, allOptions] = useThirdPartyBrandSafety(
        values,
        providerBrandSafetyCategories
    );

    const sortedAvailableOptions = _.sortBy(availableOptions, 'label');
    const availableBrandSafetyTypeOptions = useAvailableItems(BRAND_SAFETY_TYPES_OPTIONS);
    const { value: campaignSupplyTargeting } = useSupplyTargetingFormField();
    const isCampaignTargetingAppleNews = campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.APPLE_NEWS;
    const sensitiveTopicsProviderEnabled = useConfigMatch({
        [FEATURE_FLAGS.SENSITIVE_TOPICS_BRAND_SAFETY_ENABLED]: 'true',
    });
    const typeOptions =
        isCampaignTargetingAppleNews && sensitiveTopicsProviderEnabled
            ? availableBrandSafetyTypeOptions
            : availableBrandSafetyTypeOptions.filter(({ id }) => id !== BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS);

    const formattedValuesWORisks = useSelectedValueToOptionMapping(valuesByCategoryName, allOptions, {
        optionKey: 'label',
    });
    const formattedValues = useMemo(() => {
        const valuesMap = keyBy(values, 'categoryName');
        const result = formattedValuesWORisks.map(item => ({
            ...item,
            riskLevel: valuesMap[item.label]?.riskLevel,
        }));
        return result;
    }, [formattedValuesWORisks, values]);

    const deleteItem = ({ label: labelToDelete }) => {
        const filteredValues = values.filter(({ categoryName }) => categoryName !== labelToDelete);

        updateTargeting(type, filteredValues);
    };

    const addItem = ({ label }) => {
        const newValue = {
            categoryName: label,
            riskLevel: DEFAULT_RISK_LEVEL,
        };

        updateTargeting(type, [newValue, ...values]);
    };

    const updateTargeting = (newType, newValues) => {
        setType(newType);
        setValues(newValues);
    };

    const updateItemType = newType => {
        setType(newType.value);
        setValues([]);
    };

    const handleRemoveAllItems = () => setValues([]);

    const messagePrefix = useMemo(() => `${MSG_ID_PREFIX}.options.${type}`, [type]);
    const isItemTypeDisabled = values.length > 0 || typeOptions.length <= 1;
    const maxValue = useMemo(
        () => (type === BRAND_SAFETY_TYPES.IAS ? NUMBER_IAS_RISK_LEVEL : NUMBER_DV_RISK_LEVEL),
        [type]
    );
    const onRiskLevelsChangeHandler = useCallback(
        (index, value) => {
            setValues(currentValues => {
                const result = [...currentValues];
                result[index] = { ...result[index], riskLevel: value };

                return result;
            });
        },
        [setValues]
    );

    const metadata = useMemo(
        () => ({
            msgIdPrefix: messagePrefix,
            maxValue,
            onRiskLevelsChange: onRiskLevelsChangeHandler,
        }),
        [messagePrefix, maxValue, onRiskLevelsChangeHandler]
    );

    const { indicationData } = useFormValidatedValue({
        field: 'thirdPartyBrandSafetyTargeting.values',
        validations,
    });
    const {
        formAccount: { id },
    } = useFormDataContext();

    //Fetch once mounted
    useEffect(() => {
        dispatch(fetchBrandSafetyCategories(id));
    }, [dispatch, id]);

    return (
        <>
            <TargetingDropdownBox
                itemType={type}
                allItemTypes={typeOptions}
                itemTypeOptionRenderer={BrandSafetyTypeDropdownOption}
                onSelectItemType={updateItemType}
                isItemTypeDisabled={isItemTypeDisabled}
                allItems={sortedAvailableOptions}
                onAddItem={addItem}
                itemOptionRenderer={props => <DropdownOption {...props} msgIdPrefix={messagePrefix} />}
                itemPlaceholder={<Placeholder />}
            />
            <CollapsibleListWithIndication
                items={formattedValues}
                ItemComponent={BrandSafetyItem}
                itemHeight={itemHeight}
                deleteItem={deleteItem}
                clearItems={handleRemoveAllItems}
                listHeaderTitle={<ListHeaderTitle />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                containerClassName={styles['list']}
                {...indicationData}
                metadata={metadata}
            />
        </>
    );
};

export default ThirdPartyBrandSafetySmartList;
