import { useFormValidatedValue } from '../../../../../../../taboola-common-frontend-modules/validations';
import { useCpcCapValidations } from './useCpcCapValidations';

export const useCpcCapFormValidatedFieldValue = () => {
    const { validations, validationDependencies } = useCpcCapValidations();

    return useFormValidatedValue({
        field: 'cpcCap',
        validations,
        validationDependencies,
    });
};
