import React from 'react';
import classnames from 'classnames/bind';
import { InternalLink } from '../../InternalLink';
import styles from './dropdownMenuLinkOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const DropdownMenuLinkOption = ({ children, ...props }) => (
    <InternalLink className={classNameBuilder('link-container', { disabled: props.disabled })} {...props}>
        <div className={styles['children']}>{children}</div>
    </InternalLink>
);
