import React from 'react';
import { Switch } from 'react-router';
import { DatePresetContextProvider } from 'modules/campaigns/components/DatePresetContext/DatePresetContext';
import { ModuleRoute } from '../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import AudienceInsightsPage from './components/AudienceInsightsPage';
import { AUDIENCE_INSIGHTS_ROUTE } from './config/routes';

const AudienceInsights = ({ ...rest }) => (
    <Switch>
        <DatePresetContextProvider>
            <ModuleRoute
                path={AUDIENCE_INSIGHTS_ROUTE}
                render={props => <AudienceInsightsPage {...props} {...rest} />}
            />
        </DatePresetContextProvider>
    </Switch>
);

export default AudienceInsights;
