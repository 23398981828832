'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Tooltips = require('../Tooltips');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'overlay__taboola-svg-icon___3cLNH',
    'modal-backdrop': 'overlay__modal-backdrop___7p9ZP'
};


var classNameBuilder = _bind2.default.bind(styles);

var Overlay = function Overlay(_ref) {
    var onClose = _ref.onClose,
        overlayClassName = _ref.overlayClassName,
        children = _ref.children;

    var modalRef = (0, _react.useRef)(null);
    var onOverlayClick = function onOverlayClick(e) {
        if (modalRef.current.contains(e.target) && modalRef.current !== e.target) {
            return;
        }
        if (onClose) {
            onClose();
        }
    };
    var overlayClassNames = classNameBuilder('modal-backdrop', overlayClassName);
    return _react2.default.createElement(
        _Tooltips.TooltipV2Root,
        {
            role: 'dialog',
            ref: modalRef,
            className: overlayClassNames,
            onMouseDown: onOverlayClick
        },
        children
    );
};

Overlay.propTypes = {
    onClose: _propTypes2.default.func,
    overlayClassName: _propTypes2.default.string,
    children: _propTypes2.default.node
};

exports.default = Overlay;