import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import ContextualSegments from './ContextualSegments';
import ContextualSegmentsFieldTooltip from './ContextualSegmentsFieldTooltip';
import styles from '../commonEditor.module.scss';

export const ContextualSegmentsField = ({ messagePrefix = '' }) => {
    const { formAccount } = useFormDataContext();
    return (
        <FormField
            inputId="contextual-segments"
            label={<FormattedMessage id={`campaign.editor.targeting.${messagePrefix}contextual.segments.title`} />}
            description={
                <FormattedMessage id={`campaign.editor.targeting.${messagePrefix}contextual.segments.description`} />
            }
            helpText={<ContextualSegmentsFieldTooltip />}
            containerClass={styles['input']}
        >
            <ContextualSegments selectedAccountId={formAccount.accountName} />
        </FormField>
    );
};
