import { totalCampaignsCountSelector } from 'modules/campaigns/selectors/baseSelectors';
import {
    requestFetchAccountAdditionalData,
    fetchAccountAdditionalDataSuccess,
    fetchAccountAdditionalDataError,
} from '../actions';
import {
    getFrozenAccountIndication,
    getNearCreditLimitIndication,
    getInactiveAccountIndication,
} from '../modules/campaigns/config/indications/staticIndications';
import { addStaticIndication } from '../modules/taboola-common-frontend-modules/Indications';
import { accountsApi } from '../services/api';

export const fetchAccountAdditionalData =
    ({ accountName, currency } = {}) =>
    async (dispatch, getState) => {
        dispatch(requestFetchAccountAdditionalData());
        try {
            const additionalData = await accountsApi.getLegacyAccountAdditionalDataByName(accountName);
            const { isFrozen = false, isActive = true, creditData } = additionalData;
            const { reachedLimit = false, remaining } = creditData || {};
            const totalCampaignsCount = totalCampaignsCountSelector(getState());
            const isFirstCampaign = totalCampaignsCount === 0;
            dispatch(fetchAccountAdditionalDataSuccess(additionalData));

            if (isFrozen && !isFirstCampaign) {
                dispatch(addStaticIndication(getFrozenAccountIndication()));
            } else if (!isActive) {
                dispatch(addStaticIndication(getInactiveAccountIndication()));
            } else if (reachedLimit) {
                dispatch(addStaticIndication(getNearCreditLimitIndication(currency, remaining)));
            }
            return additionalData;
        } catch (error) {
            dispatch(fetchAccountAdditionalDataError(error));
        }
    };
