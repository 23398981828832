export const SET_COLUMN_DEF = 'SET_COLUMN_DEF';
export const REQUEST_GET_REPORT = 'REQUEST_GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const GET_REPORT_ABORT = 'GET_REPORT_ABORT';
export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const SET_REPORT_DURATION = 'SET_REPORT_DURATION';
export const REQUEST_GET_GRAPH_DATA = 'REQUEST_GET_GRAPH_DATA';
export const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
export const GET_GRAPH_DATA_ERROR = 'GET_GRAPH_DATA_ERROR';
export const GET_GRAPH_DATA_ABORT = 'GET_GRAPH_DATA_ABORT';
export const SET_REPORT_GRID_STATE = 'SET_REPORT_GRID_STATE';
export const SET_REPORT_DATA_STATUS = 'SET_REPORT_DATA_STATUS';
export const CLEAR_GRAPH_DATA = 'CLEAR_GRAPH_DATA';
export const SET_GRAPH_METRICS = 'SET_GRAPH_METRICS';
export const SET_REPORT_TOTALS = 'SET_REPORT_TOTALS';
export const RESET_REPORT_ROW_COUNT = 'RESET_REPORT_ROW_COUNT';
export const SET_REPORT_GRAPH_STATE = 'SET_REPORT_GRAPH_STATE';
export const REMOVE_CREATIVE = 'REMOVE_CREATIVE';
export const RESET_SELECTED_ROWS = 'RESET_SELECTED_ROWS';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';
export const REMOVE_RSS_ITEMS_ROWS = 'REMOVE_RSS_ITEMS_ROWS';
export const CLEAR_RSS_METADATA = 'CLEAR_RSS_METADATA';
