import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BaseUploader } from './BaseUploader';
import { EXCEL_FILE_TYPE, EXCEL_MULTIPLE_FILES_INDICATION, EXCEL_WRONG_FORMAT_INDICATION } from './consts/uploadConsts';

const dragAndDropDescription = <FormattedMessage id="excel.drag.drop.file" defaultMessage="Drag & drop excel file" />;

export const ExcelUpload = props => (
    <BaseUploader
        fileTypes={EXCEL_FILE_TYPE}
        dragAndDropDescription={dragAndDropDescription}
        multipleFilesIndication={EXCEL_MULTIPLE_FILES_INDICATION}
        wrongFormatIndication={EXCEL_WRONG_FORMAT_INDICATION}
        {...props}
    />
);
