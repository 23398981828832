import { useMemo } from 'react';
import { keyBy, values } from 'lodash';

const emptyIndex = {};
const useReportConfigMap = (reportConfigIndex = emptyIndex) => {
    const reportConfigMap = useMemo(
        () =>
            keyBy(
                values(reportConfigIndex).filter(value => value?.id && value?.endpoint),
                ({ id }) => id
            ),

        [reportConfigIndex]
    );

    return reportConfigMap;
};

export default useReportConfigMap;
