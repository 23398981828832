import { MERGE_AI_TAB_IMAGE_PROMPT_MAP, SET_AI_TAB_REFERENCE_IMAGE, SET_AI_TAB_TO_INITIAL_STATE } from './actionTypes';

export const mergeAITabImagePromptMap = imagePromptMap => ({
    type: MERGE_AI_TAB_IMAGE_PROMPT_MAP,
    payload: imagePromptMap,
});

export const setAITabReferenceImage = imageUrl => ({
    type: SET_AI_TAB_REFERENCE_IMAGE,
    payload: imageUrl,
});

export const setAITabToInitialState = () => ({
    type: SET_AI_TAB_TO_INITIAL_STATE,
});
