import React from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { SearchInput, withStylesheet, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { MSG_ID_PREFIX } from '../constants';
import CollapsiblePickerSortDropdown from './CollapsiblePickerSortDropdown';
import styles from './collapsiblePickerToolbar.module.scss';

const classNameBuilder = classnames.bind(styles);

const StyledInput = withStylesheet(
    SearchInput.defaultInput,
    styles,
    {
        [SearchInput.defaultInput.classNames.input]: 'filter-input',
    },
    'StyledInput'
);

const CollapsiblePickerToolbar = ({
    className,
    disabled,
    sortFooter,
    sortTooltip,
    sortType,
    sortField,
    sortOptions,
    sortMenuStyles,
    sortStyles,
    onSelectSortField,
    onSelectSortType,
    onSearch,
    searchText,
    placeholder: propPlaceholder,
}) => {
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({ id: `${MSG_ID_PREFIX}.search.placeholder`, defaultMessage: 'Search' });

    return (
        <div className={classNameBuilder('container', className)}>
            <SearchInput
                type="text"
                onChange={onSearch}
                placeholder={propPlaceholder || placeholder}
                value={searchText}
                expandable={false}
                InputComponent={StyledInput}
                size="large"
                disabled={disabled}
            />
            <CollapsiblePickerSortDropdown
                options={sortOptions}
                sortType={sortType}
                sortField={sortField}
                sortFooter={sortFooter}
                sortTooltip={sortTooltip}
                menuStyles={sortMenuStyles}
                sortStyles={sortStyles}
                onSelectSortField={onSelectSortField}
                onSelectSortType={onSelectSortType}
                disabled={disabled}
            />
        </div>
    );
};

CollapsiblePickerToolbar.propTypes = {
    /* class name for toolbar container */
    toolbarClassName: PropTypes.string,
    /* boolean to disable the toolbar */
    disabled: PropTypes.bool,
    /* sort direction (Ascending vs Descending vs Non Sorted) */
    sortType: PropTypes.oneOf(Object.values(SORTING_ORDER_TYPES)),
    /* selected sort value */
    sortField: PropTypes.any,
    /* list of sort field options */
    sortOptions: PropTypes.array,
    /* override styles for sort menu */
    sortMenuStyles: PropTypes.object,
    /* override styles for headless dropdown */
    sortStyles: PropTypes.object,
    /* func called when a sort field is selected */
    onSelectSortField: PropTypes.func,
    /* func called when sort type is selected */
    onSelectSortType: PropTypes.func,
    /* search text */
    searchText: PropTypes.string,
    /* on search text change (passes event, not search text) */
    onSearch: PropTypes.func,
    /* tooltip to render when dropdown is closed */
    sortTooltip: PropTypes.node,
    /* footer placed below sort menu */
    sortFooter: PropTypes.node,
    /* placeholder text for search component */
    placeholder: PropTypes.string,
};

CollapsiblePickerToolbar.defaultProps = {
    sortOptions: [],
    onSelectSortField: noop,
    onSelectSortType: noop,
    onSearch: noop,
};

export default CollapsiblePickerToolbar;
