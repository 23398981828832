import { useCallback } from 'react';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { usePaymentMethods } from '../components/AddFundsModal/PaymentMethods/usePaymentMethods';
import { useAllowedPrepaymentMethods } from './useAllowedPrepaymentMethods';
import { useBillingActions } from './useBillingData';

export const useAddFunds = ({ funds, paymentType }) => {
    const { accountName } = useAccount();
    const { financialRepEmail } = useAllowedPrepaymentMethods();
    const { addFunds: addFundsApi } = useBillingActions();
    const { paymentMethodHandlersMap } = usePaymentMethods({ financialRepEmail });

    const addFunds = useCallback(
        async addFundsContext => {
            const paymentMethodHandler = paymentMethodHandlersMap.get(paymentType);
            try {
                const addFundsResponse = await addFundsApi({
                    accountId: accountName,
                    amount: funds,
                    fundsType: paymentType,
                });
                return paymentMethodHandler.onAddFundsSuccess(addFundsResponse, addFundsContext);
            } catch (e) {
                paymentMethodHandler.onAddFundsFailure(paymentType, e);
            }
        },
        [addFundsApi, accountName, funds, paymentMethodHandlersMap, paymentType]
    );

    return { addFunds };
};
