import React from 'react';
import classnames from 'classnames/bind';
import { CheckboxGroup, TextCheckbox, Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import { TopicsDetails } from '../TopicsDetails';
import styles from './topicsDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const TopicsDropdownOption = ({
    data: { name, size, articles },
    selectProps: { inputValue },
    includedTopics,
}) => {
    return (
        <div className={styles['container']} aria-label={`topics-dropdown-option-${name}`}>
            <div className={classNameBuilder('label')}>
                <CheckboxGroup selectedValues={includedTopics}>
                    <TextCheckbox
                        label={`${name}-checkbox`}
                        value={name}
                        title={
                            <Dotdotdot lines={3}>
                                <Highlighter
                                    text={name}
                                    highlightedText={inputValue}
                                    className={styles['highlighter']}
                                />
                            </Dotdotdot>
                        }
                    />
                </CheckboxGroup>
            </div>
            <TopicsDetails name={name} highLightText={inputValue} size={size} articles={articles} />
        </div>
    );
};
