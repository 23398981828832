import { useCallback, useMemo, useState } from 'react';
import { set as fpSet } from 'lodash/fp';
import { TARGETING_TYPES } from '../../CombinedTargetingTypes';

export const useCombinedAudienceIngredientsTargetingTypes = ({
    combinedAudienceIngredientsTargetingCollection,
    onAddTargeting,
    onDeleteTargeting,
    onChangeTargetingType,
}) => {
    const [combinedAudienceIngredientsTypes, setCombinedAudienceIngredientsTypes] = useState(
        combinedAudienceIngredientsTargetingCollection.map(targeting => targeting.relation)
    );

    const hasExcludeTargeting = useMemo(
        () => combinedAudienceIngredientsTypes.some(type => type === TARGETING_TYPES.EXCLUDE),
        [combinedAudienceIngredientsTypes]
    );

    const setCombinedAudienceIngredientsType = useCallback(
        (type, index) => {
            setCombinedAudienceIngredientsTypes(prev => fpSet(index, type, prev));
            onChangeTargetingType(type, index);
        },
        [onChangeTargetingType]
    );

    const handleAddTargeting = useCallback(() => {
        onAddTargeting();
        setCombinedAudienceIngredientsTypes(prev => prev.concat([TARGETING_TYPES.OR]));
    }, [onAddTargeting, setCombinedAudienceIngredientsTypes]);

    const handleDeleteTargeting = useCallback(
        indexToDelete => {
            onDeleteTargeting(indexToDelete);
            setCombinedAudienceIngredientsTypes(prev => prev.filter((_, i) => indexToDelete !== i));
        },
        [onDeleteTargeting, setCombinedAudienceIngredientsTypes]
    );

    return {
        combinedAudienceIngredientsTypes,
        hasExcludeTargeting,
        setCombinedAudienceIngredientsType,
        handleAddTargeting,
        handleDeleteTargeting,
    };
};
