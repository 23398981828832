import React, { useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import {
    BlockIcon,
    OkOIcon,
    DeletableItem,
    AlertOIcon,
    TooltipV2 as Tooltip,
    useCollapsibleListContext,
} from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonAudienceListItemContent.module.scss';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useValidations } from 'modules/taboola-common-frontend-modules/validations';
import TargetingTypes from '../../../config/TargetingTypes';
import styles from './listWithMultilineInputListItem.module.scss';

export const ListWithMultilineInputListItem = ({
    item,
    deleteItem,
    typeField,
    valueField,
    resourcesById,
    itemValidations,
}) => {
    const { items } = useCollapsibleListContext();
    const { value: targetingType, mode } = useFormFieldValue({ field: typeField });
    const { isDirty } = useFormFieldValue({ field: valueField });
    const { value, name, loading } = item;
    const { failedValidationData: { message, indicationType } = {}, scrollRef } = useValidations({
        validations: itemValidations,
        validationId: `${valueField}.${name}`,
        valueToValidate: name,
        validationDependencies: { resourcesById, loading, items },
        isDirty,
        isReady: !loading,
        hasInitialData: mode !== FORM_MODES.CREATE,
    });

    const onDelete = useCallback(() => deleteItem(item), [item, deleteItem]);

    return (
        <DeletableItem onDelete={onDelete} aria-label={`Removable ${name}`}>
            <div className={styles['container']} ref={scrollRef}>
                {targetingType === TargetingTypes.INCLUDE ? (
                    <OkOIcon className={styles['include']} />
                ) : (
                    <BlockIcon className={styles['exclude']} />
                )}
                <div className={styles['content']}>
                    <div className={styles['label']}>{name}</div>
                    <div className={styles['detail']}>
                        {!loading && indicationType && (
                            <span
                                className={commonStyles['error-icon-wrapper']}
                                aria-label={`${name} ${indicationType} icon`}
                            >
                                <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                                <Tooltip delay={800}>{message}</Tooltip>
                            </span>
                        )}
                        {loading ? (
                            <ContentLoader width={80} height={26} speed={2} className={styles['loader']}>
                                <rect x="0" y="8" rx="8" ry="8" width="80" height="12" />
                            </ContentLoader>
                        ) : (
                            value
                        )}
                    </div>
                </div>
            </div>
        </DeletableItem>
    );
};

ListWithMultilineInputListItem.propTypes = {
    item: PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
};

export default ListWithMultilineInputListItem;
