import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CampaignGroupsSmartList } from './CampaignGroupsSmartList';

export const CampaignGroupsSection = () => (
    <FormField
        label={
            <FormattedMessage id="campaign.groups.select.section.description" defaultMessage="Select campaign groups" />
        }
        helpText={
            <FormattedMessage
                id="campaign.groups.select.section.tooltip"
                defaultMessage="If any groups are selected, only campaigns and creatives under the selected campaign groups will be exported"
            />
        }
    >
        <CampaignGroupsSmartList />
    </FormField>
);
