import { get } from 'lodash';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';

export const MediaPlanCountryTargeting = () => {
    const { value } = useFormFieldValue({ field: 'mediaPlan.countryTargeting' });
    const { formatMessage } = useIntl();

    return get(value, 'values', [])
        .map(countryCode => formatMessage({ id: `app.country.code.${countryCode}` }))
        .join(', ');
};
