import { FORM_MODES } from 'config/formModes';
import { CONVERSIONS_EDITOR_ROUTE_PATH, ENTITY } from 'modules/tracking/config';
import { generateConversionFormPath } from './generateTrackingFormPath';

export const generateGTMTrackingSetupMethodPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.GTM_SETUP,
        mode: FORM_MODES.CREATE,
    });

export const generateManuallyTrackingSetupMethodPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.MANUALLY_SETUP,
        mode: FORM_MODES.CREATE,
    });

export const generateS2STrackingSetupMethodPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.S2S_SETUP,
        mode: FORM_MODES.CREATE,
    });

export const generateShopifyTrackingSetupMethodPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.SHOPIFY_SETUP,
        mode: FORM_MODES.CREATE,
    });
