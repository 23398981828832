'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DataGrid = exports.PAGINATION_TYPE = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _memoizeOne = require('memoize-one');

var _memoizeOne2 = _interopRequireDefault(_memoizeOne);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.isequal');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.get');

var _lodash4 = _interopRequireDefault(_lodash3);

var _lodash5 = require('lodash.isempty');

var _lodash6 = _interopRequireDefault(_lodash5);

var _lodash7 = require('lodash.keyby');

var _lodash8 = _interopRequireDefault(_lodash7);

var _lodash9 = require('lodash.identity');

var _lodash10 = _interopRequireDefault(_lodash9);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _styleUtils = require('../../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

var _DataGridBase = require('../DataGridBase');

var _DataGridBase2 = _interopRequireDefault(_DataGridBase);

var _PaginationBar = require('../PaginationBar');

var _PaginationBar2 = _interopRequireDefault(_PaginationBar);

var _CellRenderers = require('../CellRenderers');

var _domLayouts = require('./domLayouts');

var _domLayouts2 = _interopRequireDefault(_domLayouts);

var _StickyContent = require('./StickyContent');

var _StickyContent2 = _interopRequireDefault(_StickyContent);

var _useGridPropsCache = require('./hooks/useGridPropsCache');

var _useGridPropsCache2 = _interopRequireDefault(_useGridPropsCache);

var _footerColumnDefProvider = require('./footerColumnDefProvider');

var _footerColumnDefProvider2 = _interopRequireDefault(_footerColumnDefProvider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var borderStyles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px'
};
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dataGrid__taboola-svg-icon___3681S',
    'grid-container': 'dataGrid__grid-container___17Mcy'
};


var defaultBorderThickness = Number.parseInt(borderStyles.defaultBorderThickness, 10);

var FOOTER_DEFAULT_HEIGHT = 45;
var EMPTY_FOOTER_DEFAULT_HEIGHT = 14;
var PAGINATION_HEIGHT = 40;
var ROWS_PER_PAGE_DEFAULT_VALUE = 50;
var COLUMN_MIN_WIDTH_IF_UNDEFINED = 100;

var COLUMN_TYPE = {
    NUMERIC_COLUMN: 'numericColumn'
};

var PAGINATION_TYPE = exports.PAGINATION_TYPE = {
    CLIENT: 'CLIENT',
    SERVER: 'SERVER'
};

var classNameBuilder = _bind2.default.bind(styles);

var getHeaderClass = function getHeaderClass(columnDef) {
    var headerName = columnDef.headerName,
        _columnDef$type = columnDef.type,
        type = _columnDef$type === undefined ? [] : _columnDef$type,
        headerClass = columnDef.headerClass;

    return classNameBuilder(headerClass, {
        'no-label-header': !headerName,
        'ag-numeric-header': type.includes(COLUMN_TYPE.NUMERIC_COLUMN)
    });
};

var getCellClass = function getCellClass(cellRendererFramework, _ref) {
    var cellClass = _ref.cellClass,
        _ref$type = _ref.type,
        type = _ref$type === undefined ? [] : _ref$type;
    return classNameBuilder(cellClass, {
        'ag-custom-numeric-cell': type.includes(COLUMN_TYPE.NUMERIC_COLUMN)
    });
};

var getCellStyle = function getCellStyle(cellRendererFramework, _ref2, rowHeight) {
    var cellStyle = _ref2.cellStyle;
    return cellRendererFramework || !rowHeight ? cellStyle : Object.assign({}, cellStyle, { lineHeight: rowHeight + 'px' });
};

var getColumnDefsWithLoading = function getColumnDefsWithLoading(columnDefs, rowHeight) {
    return columnDefs.map(function (_ref3) {
        var cellRendererFramework = _ref3.cellRendererFramework,
            valueFormatter = _ref3.valueFormatter,
            restColDef = _objectWithoutProperties(_ref3, ['cellRendererFramework', 'valueFormatter']);

        return Object.assign({}, restColDef, {
            cellRendererFramework: cellRendererFramework && (0, _CellRenderers.withLoadingCellRenderer)(cellRendererFramework),
            valueFormatter: valueFormatter && (0, _CellRenderers.withLoadingValueFormatter)(valueFormatter),
            headerClass: getHeaderClass(restColDef),
            cellClass: getCellClass(cellRendererFramework, restColDef),
            cellStyle: getCellStyle(cellRendererFramework, restColDef, rowHeight)
        });
    });
};

var SCROLLBAR_SPACE = 15;
var getFooterPureHeight = function getFooterPureHeight(footerHeight) {
    return footerHeight - SCROLLBAR_SPACE;
};

var SORT_EVENT_NAME = 'sortChanged';
var GRID_STATE_CHANGE_EVENT_NAME = 'componentStateChanged';

var DataGrid = function (_PureComponent) {
    _inherits(DataGrid, _PureComponent);

    function DataGrid(props) {
        _classCallCheck(this, DataGrid);

        var _this = _possibleConstructorReturn(this, (DataGrid.__proto__ || Object.getPrototypeOf(DataGrid)).call(this, props));

        _this.onGridReady = function (params) {
            var _this$props = _this.props,
                propsOnGridReady = _this$props.onGridReady,
                colResizeMethod = _this$props.colResizeMethod,
                columnState = _this$props.columnState;


            _this.gridApi = params.api;
            _this.columnApi = params.columnApi;
            _this.gridApi.addEventListener(SORT_EVENT_NAME, _this.handleSortChanged);
            _this.gridApi.addEventListener(GRID_STATE_CHANGE_EVENT_NAME, _this.redrawRowHeight);

            if (colResizeMethod) {
                _this[colResizeMethod]();
            }

            if (columnState && columnState.length) {
                _this.setColumnState(columnState);
            }

            _this.handlePaginationChange();

            if (propsOnGridReady) {
                propsOnGridReady(params);
            }
        };

        _this.onFooterGridReady = function (params) {
            var columnState = _this.props.columnState;


            _this.footerGridApi = params.api;
            _this.footerColumnApi = params.columnApi;

            if (columnState) {
                _this.setColumnState(columnState);
            }
        };

        _this.onCellValueChanged = function (event) {
            var onCellValueChanged = _this.props.onCellValueChanged;
            var oldValue = event.oldValue,
                newValue = event.newValue;

            // need this comparison because the grid will call "onCellValueChanged"
            // even if there wasn't an actual change in the cell value

            if (newValue !== oldValue) {
                onCellValueChanged(event);
            }
        };

        _this.onGridSizeChanged = function (params) {
            if (_this.containerWidth === params.clientWidth) {
                return;
            }
            _this.containerWidth = params.clientWidth;
            _this.sizeColumnsToFit();
        };

        _this.setColumnState = function (columnState) {
            var autoWidthColumns = _this.props.autoWidthColumns;

            var resolvedColumnState = columnState.map(function (item) {
                return autoWidthColumns.some(function (mask) {
                    return (item.colId || '').includes(mask);
                }) ? Object.assign({}, item, { width: null }) : item;
            });

            try {
                if (_this.columnApi) {
                    _this.columnApi.applyColumnState({ state: resolvedColumnState, applyOrder: true });
                }

                if (_this.footerColumnApi) {
                    _this.footerColumnApi.applyColumnState({ state: resolvedColumnState, applyOrder: true });
                }

                if (_this.gridApi) {
                    _this.gridApi.refreshHeader();
                }
            } catch (e) {
                // https://github.com/ag-grid/ag-grid/issues/3292#issue-474958369
            }
        };

        _this.getMemoContext = (0, _memoizeOne2.default)(_lodash10.default, _lodash2.default);
        _this.getMemoColumnDefs = (0, _memoizeOne2.default)(_lodash10.default, _lodash2.default);
        _this.getMemoFooterColumnDefs = (0, _memoizeOne2.default)(_lodash10.default, _lodash2.default);
        _this.getWrappedColumnDefs = (0, _memoizeOne2.default)(function (columnDefsProp, footerColumnDefsProp, rowHeight, footerHeight, normalizeFooterColDefs) {
            var columnDefs = getColumnDefsWithLoading(columnDefsProp, rowHeight);
            var generatedFooterColumnDefs = (0, _footerColumnDefProvider2.default)(columnDefs, footerColumnDefsProp).map(function (_ref4) {
                var cellStyle = _ref4.cellStyle,
                    rest = _objectWithoutProperties(_ref4, ['cellStyle']);

                return rest;
            });
            var normalizedFooterColumnDefs = generatedFooterColumnDefs && getColumnDefsWithLoading(normalizeFooterColDefs(generatedFooterColumnDefs), getFooterPureHeight(footerHeight));

            return {
                columnDefs: columnDefs,
                footerColumnDefs: normalizedFooterColumnDefs
            };
        });
        _this.getDefaultColumnDef = (0, _memoizeOne2.default)(function (enableSorting, enableColResize) {
            return {
                sortable: enableSorting,
                resizable: enableColResize
            };
        });

        _this.getRowHeight = function () {
            return _this.props.rowHeight;
        };

        _this.getContext = function () {
            return _this.getMemoContext(_this.props.context);
        };

        _this.resetColumnState = function () {
            try {
                if (_this.columnApi) {
                    _this.columnApi.resetColumnState();
                }

                if (_this.footerColumnApi) {
                    _this.footerColumnApi.resetColumnState();
                }
            } catch (e) {
                // https://github.com/ag-grid/ag-grid/issues/3292#issue-474958369
            }
        };

        _this.isColumnStatesEqual = (0, _memoizeOne2.default)(function (columnState, actualColumnState) {
            var shouldCheckOrder = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            if (columnState && actualColumnState && columnState.length !== actualColumnState.length) {
                return false;
            }
            if (shouldCheckOrder) {
                return (0, _lodash2.default)(columnState, actualColumnState);
            }
            return (0, _lodash2.default)((0, _lodash8.default)(columnState, 'colId'), (0, _lodash8.default)(actualColumnState, 'colId'));
        });
        _this.trackInconsistentColumnState = (0, _memoizeOne2.default)(function (columnState) {
            var onError = _this.props.onError;


            if (onError && _this.columnApi) {
                var finalColumnState = _this.columnApi.getColumnState();

                // Here we consider comparison only when length is the same to avoid interim comparison while report is being fetched
                // TODO it needs to apply more sophisticated error tracking algorithm to track inconsistencies with different lengths, loading check is not enough
                if (columnState.length === finalColumnState.length && !_this.isColumnStatesEqual(columnState, finalColumnState)) {
                    var wished = (0, _lodash8.default)(columnState, 'colId');
                    var actual = (0, _lodash8.default)(finalColumnState, 'colId');
                    var customFilter = function customFilter(singleColumn) {
                        return !(0, _lodash2.default)((0, _lodash4.default)(wished, (0, _lodash4.default)(singleColumn, 'colId')), (0, _lodash4.default)(actual, (0, _lodash4.default)(singleColumn, 'colId')));
                    };
                    onError(new Error('Unable to set columnState: expected ' + JSON.stringify(columnState.filter(customFilter)) + ', actual ' + JSON.stringify(finalColumnState.filter(customFilter))));
                }
            }
        });

        _this.redrawRowHeight = function (_ref5) {
            var api = _ref5.api,
                rowHeightChanged = _ref5.rowHeight,
                _ref5$rowsLoading = _ref5.rowsLoading;
            _ref5$rowsLoading = _ref5$rowsLoading === undefined ? {} : _ref5$rowsLoading;
            var isLoading = _ref5$rowsLoading.currentValue,
                wasLoading = _ref5$rowsLoading.previousValue;

            if (!rowHeightChanged || isLoading || wasLoading) {
                return;
            }
            api.redrawRows();
        };

        _this.handlePaginationChange = function () {
            var withPagination = _this.props.withPagination;

            if (!_this.gridApi || !_this.isClientPagination() || !withPagination) {
                return;
            }

            _this.setState({
                rowsPerPage: _this.gridApi.paginationProxy.getPageSize(),
                currentPage: _this.gridApi.paginationGetCurrentPage(),
                totalRows: _this.gridApi.paginationGetRowCount()
            });
        };

        _this.goToPreviousPage = function () {
            _this.goToPage(_this.getCurrentPage() - 1);
        };

        _this.goToNextPage = function () {
            _this.goToPage(_this.getCurrentPage() + 1);
        };

        _this.goToFirstPage = function () {
            _this.goToPage(0);
        };

        _this.goToLastPage = function () {
            var totalPages = _this.getTotalPages();

            _this.goToPage(totalPages - 1);
        };

        _this.handleOnDragStopped = function () {
            var onDragStopped = _this.props.onDragStopped;


            if (onDragStopped) {
                onDragStopped.apply(undefined, arguments);
            }
            _this.columnStateChangeHandler(true);
        };

        _this.handleSortChanged = function () {
            var _this$props2 = _this.props,
                onGridSort = _this$props2.onGridSort,
                getSortModelByColumnState = _this$props2.getSortModelByColumnState,
                columnState = _this$props2.columnState;


            if (!_this.columnApi || !onGridSort) {
                return;
            }

            var gridColumnState = _this.columnApi.getColumnState();

            if (!getSortModelByColumnState) {
                onGridSort(gridColumnState);
                return;
            }

            var prevSortModel = getSortModelByColumnState(columnState);
            var sortModel = getSortModelByColumnState(gridColumnState);

            if ((0, _lodash2.default)(sortModel, prevSortModel)) {
                return;
            }

            onGridSort(gridColumnState);
        };

        _this.containerWidth = 0;
        _this.createState();
        _this.gridRef = _react2.default.createRef();
        return _this;
    }

    _createClass(DataGrid, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var gridOptions = this.state.gridOptions;
            var getRowNodeId = this.props.getRowNodeId;


            if (getRowNodeId) {
                gridOptions.getRowNodeId = getRowNodeId;
                gridOptions.immutableData = true;
            }
        }

        // We do grid async api calls based on changed props which we can\'t pass as a props to grid

    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            // this tracker has to be first while updating component to avoid footer apis calling
            this.trackFooterGridRemove();
            this.updateColumnState(prevProps);
            this.updateQuickFilter(prevProps);
            this.updateSelectedRows(prevProps);
            this.updateRowHeight(prevProps);
            this.refreshHeaderOnceLoaded(prevProps);
            this.redrawFooter(prevProps);
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            if (this.gridApi) {
                this.gridApi.removeEventListener(SORT_EVENT_NAME, this.handleSortChanged);
                this.gridApi.removeEventListener(GRID_STATE_CHANGE_EVENT_NAME, this.redrawRowHeight);
            }
        }

        // We use long list of arguments > 3 due to memoization until we migrate to functional component

    }, {
        key: 'getCurrentPage',
        value: function getCurrentPage() {
            var currentPage = this.state.currentPage;
            var _props$currentPage = this.props.currentPage,
                currentPageProp = _props$currentPage === undefined ? currentPage : _props$currentPage;


            return currentPage || currentPageProp;
        }
    }, {
        key: 'getTotalPages',
        value: function getTotalPages() {
            var _props = this.props,
                totalRowsProp = _props.totalRows,
                rowsPerPageProp = _props.rowsPerPage;
            var _state = this.state,
                totalRows = _state.totalRows,
                rowsPerPage = _state.rowsPerPage;


            return Math.ceil((totalRows || totalRowsProp) / (rowsPerPage || rowsPerPageProp));
        }
    }, {
        key: 'redrawFooter',


        // We need force redraw due to grid caches somehow cellRenderer for column from prev render and doesn't apply changed cellRenderer
        value: function redrawFooter(_ref6) {
            var prevFooterColumnDefs = _ref6.footerColumnDefs;
            var footerColumnDefs = this.props.footerColumnDefs;


            if (footerColumnDefs === prevFooterColumnDefs || !this.footerGridApi) {
                return;
            }

            this.footerGridApi.redrawRows();
        }
    }, {
        key: 'refreshHeaderOnceLoaded',
        value: function refreshHeaderOnceLoaded(prevProps) {
            // We need this refresh to force header cells have new context as grid can omit needed update
            if (prevProps.headerLoading && !this.props.headerLoading && this.gridApi) {
                this.gridApi.refreshHeader();
            }
        }
    }, {
        key: 'trackFooterGridRemove',
        value: function trackFooterGridRemove() {
            var withFooter = this.props.withFooter;


            if (!withFooter && this.footerGridApi) {
                this.footerGridApi = undefined;
                this.footerColumnApi = undefined;
            }
        }

        // We are calling keyBy depending on whether order matters or not for our equality check


        // memoizeOne added to avoid throwing error on every blink only for every new columnState

    }, {
        key: 'updateColumnState',
        value: function updateColumnState(prevProps) {
            var _this2 = this;

            var _props2 = this.props,
                columnState = _props2.columnState,
                columnDefs = _props2.columnDefs;
            var prevColumnState = prevProps.columnState,
                prevColumnDefs = prevProps.columnDefs;

            var columnDefsChanged = columnDefs !== prevColumnDefs && !(0, _lodash2.default)(columnDefs, prevColumnDefs);
            var columnStatePropChanged = columnState !== prevColumnState && !(0, _lodash2.default)(columnState, prevColumnState);

            if (!columnStatePropChanged) {
                if (columnDefsChanged) {
                    this.addOnceGridStateChangeCallback(function () {
                        return _this2.sizeColumnsToFit();
                    }, 'columnDefs');
                }
                return;
            }

            var actualColumnState = this.columnApi ? this.columnApi.getColumnState() : prevColumnState;
            var columnStateChanged = !this.isColumnStatesEqual(columnState, actualColumnState);

            if (!columnStateChanged) {
                this.sizeColumnsToFit();
                return;
            }

            var columnStateCallback = function columnStateCallback() {
                if (columnState) {
                    /** We need to set column state via columnApi call in case actual grid column state isn't equal to that passed in props */
                    _this2.setColumnState(columnState);
                    _this2.trackInconsistentColumnState(columnState);
                } else if (!columnState && !!prevColumnState) {
                    /** We should reset column state only when switch to another report (columnDefChanged === true), otherwise extra calls to reset column will trigger
                     * extra rendering and provide grid blink visual effect */
                    _this2.resetColumnState();
                }
                _this2.sizeColumnsToFit();
            };

            if (columnDefsChanged) {
                // Once column defs and column state changed at a time we need to run columns state update once grid processed columnDefs changes
                this.addOnceGridStateChangeCallback(columnStateCallback, 'columnDefs');
                return;
            }
            columnStateCallback();
        }
    }, {
        key: 'addOnceGridStateChangeCallback',
        value: function addOnceGridStateChangeCallback(callback, fieldName) {
            var _this3 = this;

            if (!this.gridApi) {
                return;
            }
            var runDoneFlag = false;
            var onceRunWrappedCallback = function onceRunWrappedCallback(event) {
                if (!event[fieldName]) {
                    return;
                }
                if (runDoneFlag) {
                    return;
                }
                runDoneFlag = true;
                callback();
                _this3.gridApi.removeEventListener(GRID_STATE_CHANGE_EVENT_NAME, onceRunWrappedCallback);
            };

            this.gridApi.addEventListener(GRID_STATE_CHANGE_EVENT_NAME, onceRunWrappedCallback);
        }
    }, {
        key: 'updateQuickFilter',
        value: function updateQuickFilter(prevProps) {
            var quickFilterText = this.props.quickFilterText;
            var prevQuickFilterText = prevProps.quickFilterText;

            var quickFilterTextChanged = quickFilterText !== prevQuickFilterText;

            if (this.gridApi && quickFilterTextChanged) {
                this.gridApi.paginationGoToFirstPage();
            }
        }
    }, {
        key: 'updateRowHeight',
        value: function updateRowHeight(_ref7) {
            var prevRowHeight = _ref7.rowHeight;
            var rowHeight = this.props.rowHeight;


            if (!this.gridApi || prevRowHeight === rowHeight) {
                return;
            }

            this.gridApi.resetRowHeights();
        }
    }, {
        key: 'updateSelectedRows',
        value: function updateSelectedRows(prevProps) {
            var _props3 = this.props,
                selectedRows = _props3.selectedRows,
                rowsLoading = _props3.rowsLoading;

            if (!this.gridApi || rowsLoading) {
                return;
            }
            var prevSelectedRows = prevProps.selectedRows,
                prevRowsLoading = prevProps.rowsLoading;

            var updateRows = selectedRows !== prevSelectedRows || prevRowsLoading && !rowsLoading;
            if (updateRows) {
                this.gridApi.forEachNode(function (node) {
                    var selected = Boolean(selectedRows[node.data.id]);
                    if (selected !== node.selected) {
                        node.setSelected(selected);
                    }
                });
            }
        }
    }, {
        key: 'createState',
        value: function createState() {
            var gridOptions = { alignedGrids: [] };
            var footerOptions = { alignedGrids: [] };

            gridOptions.alignedGrids.push(footerOptions);
            footerOptions.alignedGrids.push(gridOptions);
            this.state = {
                gridOptions: gridOptions,
                footerOptions: footerOptions,
                rowsPerPage: 0,
                currentPage: 0,
                totalRows: 0
            };
        }
    }, {
        key: 'isClientPagination',
        value: function isClientPagination() {
            var paginationType = this.props.paginationType;


            return paginationType === PAGINATION_TYPE.CLIENT;
        }
    }, {
        key: 'sizeColumnsToFit',
        value: function sizeColumnsToFit() {
            var rowsLoading = this.props.rowsLoading;


            if (!this.canNarrowColumns() && !this.hasGapColumn()) {
                return;
            }

            this.gridApi.sizeColumnsToFit();

            if (rowsLoading) {
                return;
            }

            this.columnStateChangeHandler();
        }
    }, {
        key: 'autoSizeColumns',
        value: function autoSizeColumns() {
            var allColumnIds = this.columnApi.getAllColumns().map(function (col) {
                return col.colId;
            });

            this.columnApi.autoSizeColumns(allColumnIds);
        }
    }, {
        key: 'getMinWidth',
        value: function getMinWidth(columnDef, actualColumnState) {
            if (!columnDef) {
                return actualColumnState.actualWidth;
            }
            if (columnDef.minWidth) {
                return columnDef.minWidth;
            }
            if (columnDef.maxWidth < COLUMN_MIN_WIDTH_IF_UNDEFINED) {
                return columnDef.maxWidth;
            }
            return COLUMN_MIN_WIDTH_IF_UNDEFINED;
        }
    }, {
        key: 'canNarrowColumns',
        value: function canNarrowColumns() {
            var _this4 = this;

            var autoFitColumnsToScreen = this.props.autoFitColumnsToScreen;

            if (!autoFitColumnsToScreen) {
                return false;
            }
            if (!this.containerWidth || !this.columnApi || !this.gridApi || !this.gridApi.gridOptionsWrapper) {
                return false;
            }
            if (!this.gridApi.gridOptionsWrapper.gridOptions || !this.gridApi.gridOptionsWrapper.gridOptions.columnDefs) {
                return false;
            }

            var originalColumnDefsMap = (0, _lodash8.default)(this.gridApi.gridOptionsWrapper.gridOptions.columnDefs, 'colId');

            var columns = this.columnApi.getAllColumns().filter(function (column) {
                return column.visible;
            });
            if (!columns.length) {
                return false;
            }

            var totalMinWidth = columns.reduce(function (total, column) {
                return total + _this4.getMinWidth(originalColumnDefsMap[column.colId], column);
            }, 0);

            // We subtract left and right border width to have correct match
            var containerWidthNoBorders = this.containerWidth - 2 * defaultBorderThickness;

            // TODO: should be updated when implement flex on columnDef DEV-138915.
            return totalMinWidth < containerWidthNoBorders;
        }
    }, {
        key: 'hasGapColumn',
        value: function hasGapColumn() {
            if (!this.containerWidth || !this.columnApi) {
                return false;
            }

            var columns = this.columnApi.getAllColumns().filter(function (column) {
                return column.visible;
            });
            if (!columns.length) {
                return false;
            }

            var totalColumnsWidth = columns.reduce(function (total, column) {
                return total + column.actualWidth;
            }, 0);

            // We subtract left and right border width to have correct match
            var containerWidthNoBorders = this.containerWidth - 2 * defaultBorderThickness;
            return totalColumnsWidth < containerWidthNoBorders;
        }
    }, {
        key: 'calcHeight',
        value: function calcHeight() {
            var _props4 = this.props,
                withFooter = _props4.withFooter,
                footerHeight = _props4.footerHeight,
                withPagination = _props4.withPagination;

            var offset = withFooter ? footerHeight : 0;

            offset += withPagination ? PAGINATION_HEIGHT : 0;

            return offset ? 'calc(100% - ' + offset + 'px)' : '100%';
        }
    }, {
        key: 'createPaginationProps',
        value: function createPaginationProps() {
            var _props5 = this.props,
                withPagination = _props5.withPagination,
                rowsPerPage = _props5.rowsPerPage;


            return this.isClientPagination() && withPagination ? {
                pagination: withPagination,
                paginationPageSize: rowsPerPage,
                onPaginationChanged: this.handlePaginationChange
            } : {};
        }
    }, {
        key: 'goToPage',
        value: function goToPage(page) {
            var onPaginationChange = this.props.onPaginationChange;


            if (this.isClientPagination()) {
                this.gridApi.paginationGoToPage(page);
                return;
            }

            onPaginationChange(page);
        }
    }, {
        key: 'columnStateChangeHandler',
        value: function columnStateChangeHandler(shouldCheckOrder) {
            var _props6 = this.props,
                onColumnStateChanged = _props6.onColumnStateChanged,
                columnState = _props6.columnState;


            if (!onColumnStateChanged || !this.columnApi) {
                return;
            }

            var actualColumnState = this.columnApi.getColumnState();

            if (!this.isColumnStatesEqual(columnState, actualColumnState, shouldCheckOrder)) {
                onColumnStateChanged(actualColumnState);
            }
        }
    }, {
        key: 'calcFooterHeight',
        value: function calcFooterHeight() {
            var _props7 = this.props,
                isEmptyFooter = _props7.isEmptyFooter,
                footerHeight = _props7.footerHeight;


            return isEmptyFooter ? EMPTY_FOOTER_DEFAULT_HEIGHT : footerHeight;
        }
    }, {
        key: 'renderPagination',
        value: function renderPagination() {
            var _props8 = this.props,
                PaginationBar = _props8.PaginationBar,
                paginationClassName = _props8.paginationClassName,
                totalRowsProp = _props8.totalRows,
                rowsPerPageProp = _props8.rowsPerPage;
            var _state2 = this.state,
                totalRows = _state2.totalRows,
                rowsPerPage = _state2.rowsPerPage;


            var totalPages = this.getTotalPages();

            return totalRows || totalRowsProp ? _react2.default.createElement(PaginationBar, {
                className: paginationClassName,
                currentPage: this.getCurrentPage(),
                totalRows: totalRows || totalRowsProp,
                rowsPerPage: rowsPerPage || rowsPerPageProp,
                totalPages: totalPages,
                goToPreviousPage: this.goToPreviousPage,
                goToNextPage: this.goToNextPage,
                goToFirstPage: this.goToFirstPage,
                goToLastPage: this.goToLastPage
            }) : null;
        }
    }, {
        key: 'renderFooter',
        value: function renderFooter(footerColumnDefs) {
            var _props9 = this.props,
                theme = _props9.theme,
                footerData = _props9.footerData,
                footerHeight = _props9.footerHeight,
                rowClass = _props9.rowClass,
                gridWidth = _props9.gridWidth,
                footerClass = _props9.footerClass,
                columnTypes = _props9.columnTypes,
                defaultColDef = _props9.defaultColDef;
            var footerOptions = this.state.footerOptions;

            var context = this.getContext();

            return _react2.default.createElement(_DataGridBase2.default, {
                suppressContextMenu: true,
                suppressNoRowsOverlay: true,
                suppressLoadingOverlay: true,
                className: theme + ' ' + footerClass,
                rowData: footerData,
                onGridReady: this.onFooterGridReady,
                columnDefs: footerColumnDefs,
                gridHeight: (0, _styleUtils2.default)(this.calcFooterHeight()),
                gridWidth: gridWidth,
                headerHeight: 0,
                rowHeight: '' + getFooterPureHeight(footerHeight),
                gridOptions: footerOptions,
                rowClass: rowClass ? rowClass + ' ' + rowClass + '-footer' : null,
                columnTypes: columnTypes,
                defaultColDef: defaultColDef,
                context: context
            });
        }
    }, {
        key: 'renderBottomSection',
        value: function renderBottomSection(footerColumnDefs) {
            var _props10 = this.props,
                withFooter = _props10.withFooter,
                withPagination = _props10.withPagination,
                reportRef = _props10.reportRef,
                reportData = _props10.reportData;

            var footerHeight = this.calcFooterHeight();
            var stickerHeight = (withFooter ? footerHeight : 0) + (withPagination ? PAGINATION_HEIGHT : 0);

            if (!withFooter && !withPagination || (0, _lodash6.default)(reportData)) {
                return null;
            }

            return _react2.default.createElement(
                _StickyContent2.default,
                {
                    stickerHeight: stickerHeight,
                    reportRef: reportRef,
                    gridRef: this.gridRef,
                    eventPublisher: this.gridApi,
                    dataChangeEventName: GRID_STATE_CHANGE_EVENT_NAME
                },
                withFooter && this.renderFooter(footerColumnDefs),
                withPagination && this.renderPagination()
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var _props11 = this.props,
                theme = _props11.theme,
                className = _props11.className,
                withFooter = _props11.withFooter,
                isEmptyFooter = _props11.isEmptyFooter,
                withPagination = _props11.withPagination,
                gridContainerClass = _props11.gridContainerClass,
                domLayout = _props11.domLayout,
                rowsPerPage = _props11.rowsPerPage,
                onGridSort = _props11.onGridSort,
                headerLoading = _props11.headerLoading,
                rowsLoading = _props11.rowsLoading,
                LoadingCellRenderer = _props11.LoadingCellRenderer,
                enableSorting = _props11.enableSorting,
                enableColResize = _props11.enableColResize,
                columnState = _props11.columnState,
                suppressHorizontalScroll = _props11.suppressHorizontalScroll,
                selectedRows = _props11.selectedRows,
                id = _props11.id,
                footerClass = _props11.footerClass,
                totalRows = _props11.totalRows,
                reportData = _props11.reportData,
                noRowsOverlayComponentParams = _props11.noRowsOverlayComponentParams,
                contextSkip = _props11.context,
                rowHeight = _props11.rowHeight,
                footerHeight = _props11.footerHeight,
                columnDefsProp = _props11.columnDefs,
                footerColumnDefsProp = _props11.footerColumnDefs,
                normalizeFooterColDefs = _props11.normalizeFooterColDefs,
                rest = _objectWithoutProperties(_props11, ['theme', 'className', 'withFooter', 'isEmptyFooter', 'withPagination', 'gridContainerClass', 'domLayout', 'rowsPerPage', 'onGridSort', 'headerLoading', 'rowsLoading', 'LoadingCellRenderer', 'enableSorting', 'enableColResize', 'columnState', 'suppressHorizontalScroll', 'selectedRows', 'id', 'footerClass', 'totalRows', 'reportData', 'noRowsOverlayComponentParams', 'context', 'rowHeight', 'footerHeight', 'columnDefs', 'footerColumnDefs', 'normalizeFooterColDefs']);

            var gridOptions = this.state.gridOptions;

            var paginationProps = this.createPaginationProps();
            var defaultColDef = this.getDefaultColumnDef(enableSorting, enableColResize);

            var _getWrappedColumnDefs = this.getWrappedColumnDefs(this.getMemoColumnDefs(columnDefsProp), this.getMemoFooterColumnDefs(footerColumnDefsProp), rowHeight, footerHeight, normalizeFooterColDefs),
                columnDefs = _getWrappedColumnDefs.columnDefs,
                footerColumnDefs = _getWrappedColumnDefs.footerColumnDefs;

            var context = this.getContext();

            return _react2.default.createElement(
                'div',
                { className: classNameBuilder('grid-container', gridContainerClass) },
                _react2.default.createElement(_DataGridBase2.default, Object.assign({
                    dataTestId: 'data-grid',
                    defaultColDef: defaultColDef
                }, rest, {
                    suppressPaginationPanel: true,
                    className: theme + ' ' + className,
                    suppressHorizontalScroll: withFooter || suppressHorizontalScroll,
                    rowData: reportData,
                    columnDefs: columnDefs,
                    onGridReady: this.onGridReady,
                    onCellValueChanged: this.onCellValueChanged,
                    onGridSizeChanged: this.onGridSizeChanged,
                    gridOptions: gridOptions
                }, paginationProps, {
                    domLayout: domLayout,
                    gridHeight: domLayout !== _domLayouts2.default.AUTO_HEIGHT ? this.calcHeight() : undefined,
                    context: context,
                    gridRef: this.gridRef,
                    onDragStopped: this.handleOnDragStopped,
                    rowHeight: rowHeight,
                    noRowsOverlayComponentParams: noRowsOverlayComponentParams,
                    getRowHeight: this.getRowHeight
                })),
                this.renderBottomSection(footerColumnDefs)
            );
        }
    }]);

    return DataGrid;
}(_react.PureComponent);

DataGrid.propTypes = {
    /** The grid ID */
    id: _propTypes2.default.string,
    /** The grid class name */
    className: _propTypes2.default.string,
    /** The data presented in the grid */
    reportData: _propTypes2.default.array,
    /** Fires when the grid and its APIs are ready */
    onGridReady: _propTypes2.default.func,
    /** Fires when cell value changed (for inline editing) */
    onCellValueChanged: _propTypes2.default.func,
    /** Method to be used to determine the columns width */
    colResizeMethod: _propTypes2.default.oneOf(['sizeColumnsToFit', 'autoSizeColumns']),
    /** Columns definition (meta data) */
    columnDefs: _propTypes2.default.array,
    /** a default column definition with properties that get applied to every column */
    defaultColDef: _propTypes2.default.object,
    /** define a column type (you can define as many as you like) */
    columnTypes: _propTypes2.default.object,
    /** Footer Columns definition (meta data) */
    footerColumnDefs: _propTypes2.default.array,
    /** Should render an empty footer grid to allow horizontal scrolling */
    isEmptyFooter: _propTypes2.default.bool,
    /** Enable/disable multiple row selection by passing multiple or single (default is multiple) */
    rowSelection: _propTypes2.default.oneOf(['multiple', 'single']),
    /** Enable/Disable sorting (default is enabled) */
    enableSorting: _propTypes2.default.bool,
    /** Enable/Disable column resize (default is enabled) */
    enableColResize: _propTypes2.default.bool,
    /**  Enable animation of rows after sorting (default is enabled) */
    animateRows: _propTypes2.default.bool,
    /** Theme css class of the grid */
    theme: _propTypes2.default.string,
    /** The width of the grid (in pixels or percentages) */
    gridWidth: _propTypes2.default.string,
    /** Enable/Disable pagination */
    withPagination: _propTypes2.default.bool,
    /** Class name to be added to the pagination component */
    paginationClassName: _propTypes2.default.string,
    /** Enable/Disable footer row */
    withFooter: _propTypes2.default.bool,
    /** The class name to assign to the grid footer */
    footerClass: _propTypes2.default.string,
    /** The height of the footer (in pixels) */
    footerHeight: _propTypes2.default.number,
    /** Footer data */
    footerData: _propTypes2.default.array,
    /** Height of the grid's header */
    headerHeight: _propTypes2.default.number,
    /** The class name to be assigned on a grid row */
    rowClass: _propTypes2.default.string,
    /** The class name to be assigned to the grid container */
    gridContainerClass: _propTypes2.default.string,
    /** Filter text in grid */
    quickFilterText: _propTypes2.default.string,
    /**  if true, determines the number of visible rows per the allocated grid height */
    paginationAutoPageSize: _propTypes2.default.bool,
    /** If true, rows won't be selected when clicked */
    suppressRowClickSelection: _propTypes2.default.bool,
    /** The number of rows per page in pagination */
    rowsPerPage: _propTypes2.default.number,
    /** domLayout string either "autoHeight" or "normal" */
    domLayout: _propTypes2.default.string,
    /** context to pass data to other grid parts (cells, columns and etc.) */
    context: _propTypes2.default.object,
    /** The state of the columns - used to set the state from local storage */
    columnState: _propTypes2.default.array,
    /** Show loading for data rows, headers and footer */
    headerLoading: _propTypes2.default.bool,
    /** Show loading for data rows and footer */
    rowsLoading: _propTypes2.default.bool,
    /** Loading cell renderer used to show loading */
    LoadingCellRenderer: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    /** Pagination type, could be either client or server */
    paginationType: _propTypes2.default.oneOf(Object.values(PAGINATION_TYPE)),
    /** on go to page callback */
    onPaginationChange: _propTypes2.default.func,
    /** Current page index */
    currentPage: _propTypes2.default.number,
    /** Total rows in data */
    totalRows: _propTypes2.default.number,
    /** on grid sort callback */
    onGridSort: _propTypes2.default.func,
    /** Enable user selection of cell content */
    enableCellTextSelection: _propTypes2.default.bool,
    /** Row data id getter */
    getRowNodeId: _propTypes2.default.func,
    /** Horizontal scroll suppress option in main table */
    suppressHorizontalScroll: _propTypes2.default.bool,
    /** On error callback */
    onError: _propTypes2.default.func,
    /** Boolean flag to enable row selection */
    hasRowSelection: _propTypes2.default.bool,
    /** Object of selected rows using grid IDs as keys */
    selectedRows: _propTypes2.default.object,
    /** Ref to report container */
    reportRef: _propTypes2.default.object,
    /** onDragStopped cascaded callback to ag-grid */
    onDragStopped: _propTypes2.default.func,
    /** Column state change callback */
    onColumnStateChanged: _propTypes2.default.func,
    /** no row overlay params */
    noRowsOverlayComponentParams: _propTypes2.default.object,
    /** Function to modify footer column defs after generated */
    normalizeFooterColDefs: _propTypes2.default.func,
    /** Custom PaginationBar component */
    PaginationBar: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    /** Sort order parser for column state */
    getSortModelByColumnState: _propTypes2.default.func,
    /** Row Height */
    rowHeight: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    /** Columns which width should be autosized */
    autoWidthColumns: _propTypes2.default.array,
    /** Should fit all columns to grid width if true */
    autoFitColumnsToScreen: _propTypes2.default.bool
};

DataGrid.defaultProps = {
    className: '',
    enableSorting: true,
    enableColResize: true,
    animateRows: true,
    onCellValueChanged: function onCellValueChanged() {},
    reportData: [],
    columnDefs: [],
    footerColumnDefs: [],
    gridWidth: '100%',
    gridContainerClass: '',
    withFooter: false,
    isEmptyFooter: false,
    footerHeight: FOOTER_DEFAULT_HEIGHT,
    footerData: [],
    paginationClassName: '',
    theme: '',
    footerClass: '',
    suppressRowClickSelection: true,
    rowsPerPage: ROWS_PER_PAGE_DEFAULT_VALUE,
    domLayout: _domLayouts2.default.AUTO_HEIGHT,
    headerLoading: false,
    rowsLoading: false,
    LoadingCellRenderer: _CellRenderers.DefaultLoadingCellRenderer,
    context: {},
    paginationType: PAGINATION_TYPE.CLIENT,
    suppressHorizontalScroll: false,
    selectedRows: {},
    reportRef: _react2.default.createRef(),
    normalizeFooterColDefs: _lodash10.default,
    PaginationBar: _PaginationBar2.default,
    autoWidthColumns: []
};

DataGrid.COLUMN_TYPE = COLUMN_TYPE;

exports.DataGrid = DataGrid;


var DataGridWithCachedProps = function DataGridWithCachedProps(props) {
    var cachedProps = (0, _useGridPropsCache2.default)(props);

    return _react2.default.createElement(DataGrid, cachedProps);
};

DataGridWithCachedProps.COLUMN_TYPE = COLUMN_TYPE;

exports.default = DataGridWithCachedProps;