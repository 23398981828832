import { useMemo } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useFilteredPlatformBoosts = () => {
    const { value: platformBoosts } = useFormFieldValue({
        field: 'platformBidModifier.values',
    });

    const { value: platformTargeting } = useFormFieldValue({ field: 'platformTargeting' });

    return useMemo(
        () => platformBoosts.filter(platformBoost => platformTargeting?.includes(platformBoost.platform)),
        [platformBoosts, platformTargeting]
    );
};
