import { DeleteButton } from 'modules/campaigns/components/ContentEditableText/components/DeleteButton';
import { PerformanceRuleDetails } from './PerformanceRuleDetails';
import styles from './PerformanceRuleCellEditor.module.scss';

export const PerformanceRuleCellEditor = ({ data, onCancel }) => {
    return (
        <div className={styles['blocked-container']}>
            <DeleteButton className={styles['button']} onClick={onCancel} />
            <PerformanceRuleDetails details={data}> </PerformanceRuleDetails>
        </div>
    );
};
