import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ConversionSetupUrlConditionsTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.setup.conditions.tooltip.title"
                defaultMessage="Managing conditions will define the URL match of this conversion. Enter the URL or part of the URL of the destination page on which a visit should count as a conversion."
            />
        </TooltipSection>
    </div>
);
