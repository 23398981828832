import LANGUAGE from '../config/language';

const ASIAN_LANGUAGES = {
    [LANGUAGE.ja]: [LANGUAGE.ja],
    [LANGUAGE.ko]: [LANGUAGE.ko],
};

const isAsianLanguage = language => !!ASIAN_LANGUAGES[language];

export default isAsianLanguage;
