import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAccountAdditionalData } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useBillingApi } from 'services/api';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';
import { isDefined } from '../../../taboola-common-frontend-modules/formData/utils/isDefined';
import { CREDIT_LIMIT_MODEL } from '../../config/CreditLimitModel';
import { useBillingQueryKeys } from './useBillingQueryKeys';

const useBillingDataState = ({ creditData, isFetchingAdditionalData }) => {
    const { accountName } = useAccount();
    const {
        getCcbBillingData,
        getCreditLimitBillingData,
        getAllowedPrepaymentMethodsV2: getAllowedPrepaymentMethodsV2Api,
        getPaymentMethods: getPaymentMethodsV2Api,
    } = useBillingApi();
    const isPermitted = usePermissions('BUDGET_MANAGEMENT_VIEW');

    const { creditLimitModel } = creditData ?? {};
    const getBillingDataApi =
        creditLimitModel === CREDIT_LIMIT_MODEL.CCB ? getCcbBillingData : getCreditLimitBillingData;

    const { billingDataQueryKey, getAllowedPrepaymentMethodsV2QueryKey, getPaymentMethodsV2QueryKey } =
        useBillingQueryKeys();
    const isAccountDefined = isDefined(accountName);

    const {
        data: billingData,
        isError: isBillingDataError,
        isSuccess: isBillingDataSuccess,
        isFetching: isFetchingBillingData,
        dataUpdatedAt,
    } = useQuery(billingDataQueryKey, () => getBillingDataApi({ accountId: accountName }), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: isAccountDefined && isPermitted && !isFetchingAdditionalData && !!creditData?.creditLimitModel,
    });
    const {
        data: allowedPrepaymentMethodsV2,
        isError: isGetAllowedPrepaymentMethodsError,
        isSuccess: isGetAllowedPrepaymentMethodsSuccess,
        isFetching: isFetchingGetAllowedPrepaymentMethods,
    } = useQuery(
        getAllowedPrepaymentMethodsV2QueryKey,
        () => getAllowedPrepaymentMethodsV2Api({ accountId: accountName }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: isAccountDefined,
        }
    );
    const {
        data: paymentMethodsV2,
        isError: isGetPaymentMethodsError,
        isSuccess: isGetPaymentMethodsSuccess,
        isFetching: isFetchingGetPaymentMethods,
    } = useQuery(getPaymentMethodsV2QueryKey, () => getPaymentMethodsV2Api({ accountId: accountName }), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: isAccountDefined && isPermitted,
    });

    return {
        billingData,
        isBillingDataError,
        isBillingDataSuccess,
        isFetchingBillingData,
        allowedPrepaymentMethodsV2,
        isGetAllowedPrepaymentMethodsError,
        isGetAllowedPrepaymentMethodsSuccess,
        isFetchingGetAllowedPrepaymentMethods,
        paymentMethodsV2,
        isGetPaymentMethodsError,
        isGetPaymentMethodsSuccess,
        isFetchingGetPaymentMethods,
        dataUpdatedAt,
        billingDataQueryKey,
        isPermitted,
    };
};

export const useBillingData = () => {
    const {
        accountAdditionalData = {},
        isFetchingAdditionalData,
        isAdditionalDataSuccess,
        isAdditionalDataError,
    } = useAccountAdditionalData();
    const { creditData } = accountAdditionalData;

    const {
        billingData,
        isBillingDataError,
        isBillingDataSuccess,
        isFetchingBillingData,
        allowedPrepaymentMethodsV2,
        isGetAllowedPrepaymentMethodsError,
        isGetAllowedPrepaymentMethodsSuccess,
        isFetchingGetAllowedPrepaymentMethods,
        paymentMethodsV2,
        isGetPaymentMethodsError,
        isGetPaymentMethodsSuccess,
        isFetchingGetPaymentMethods,
        dataUpdatedAt,
        isPermitted,
    } = useBillingDataState({ creditData, isFetchingAdditionalData });

    const resolvedBillingData = useMemo(
        () =>
            isBillingDataSuccess && isGetAllowedPrepaymentMethodsSuccess && isGetPaymentMethodsSuccess
                ? {
                      ...billingData,
                      ...creditData,
                      allowedPrepaymentMethodsV2,
                      paymentMethodsV2,
                  }
                : {},
        [
            billingData,
            allowedPrepaymentMethodsV2,
            paymentMethodsV2,
            isBillingDataSuccess,
            isGetAllowedPrepaymentMethodsSuccess,
            isGetPaymentMethodsSuccess,
            creditData,
        ]
    );

    return {
        billingData: resolvedBillingData,
        isError:
            isBillingDataError ||
            isAdditionalDataError ||
            isGetAllowedPrepaymentMethodsError ||
            isGetPaymentMethodsError,
        isFetching:
            isFetchingBillingData ||
            isFetchingAdditionalData ||
            isFetchingGetAllowedPrepaymentMethods ||
            isFetchingGetPaymentMethods,
        isSuccess:
            isBillingDataSuccess &&
            isAdditionalDataSuccess &&
            isGetAllowedPrepaymentMethodsSuccess &&
            isGetPaymentMethodsSuccess,
        isManagingBilling: isPermitted && !!creditData?.creditLimitModel,
        dataUpdatedAt,
    };
};
