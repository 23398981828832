import React, { useMemo } from 'react';
import { groupBy, mapValues } from 'lodash';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { useModuleName } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { reportUnderscoreRegexp } from '../../../../../services/dynamicColumnsService';
import styles from './columnGroup.module.scss';

export const resolveTitle = (col, columnDefsMap, moduleName) => {
    const colIdShort = col.id.replace(reportUnderscoreRegexp, '');
    const messageIdPrefix =
        columnDefsMap[col.id]?.headerComponentParams?.messageIdPrefix ||
        `app.${moduleName}.reports.grid.column.header.message`;

    return <FormattedMessage id={`${messageIdPrefix}.${colIdShort}`} defaultMessage={col.label} />;
};

const ColumnGroup = ({ name, columns, selectedColumns, onToggleColumn, columnsDef }) => {
    const module = useModuleName();
    const selectedColumnsSet = useMemo(() => new Set(selectedColumns), [selectedColumns]);
    const selectedColumnsCount = useMemo(() => {
        return columns.filter(col => selectedColumnsSet.has(col.id)).length;
    }, [columns, selectedColumnsSet]);
    const colIdsBySelectionState = useMemo(() => {
        const colsByState = groupBy(columns, col => (selectedColumnsSet.has(col.id) ? 'on' : 'off'));
        return mapValues(colsByState, cols => cols.map(col => col.id));
    }, [columns, selectedColumnsSet]);
    const areAllColumnsSelected = selectedColumnsCount === columns.length;
    const areNoColumnsSelected = selectedColumnsCount === 0;
    const isIntermediate = !(areAllColumnsSelected || areNoColumnsSelected);
    const selectAll = () => onToggleColumn(colIdsBySelectionState.off || []);
    const deselectAll = () => onToggleColumn(colIdsBySelectionState.on || []);
    const columnDefsMap = useMemo(() => keyBy(columnsDef, 'colId'), [columnsDef]);

    return (
        <div className={styles['col-list']}>
            <CheckboxGroup
                id={`col-list-${name}`}
                selectedValues={selectedColumns}
                onSelectCheckbox={onToggleColumn}
                onUnselectCheckbox={onToggleColumn}
            >
                <TextCheckbox
                    independent
                    selectedValues={areAllColumnsSelected ? [name] : []}
                    onSelectCheckbox={selectAll}
                    onUnselectCheckbox={deselectAll}
                    onIntermediateSelect={selectAll}
                    value={name}
                    isIntermediate={isIntermediate}
                    title={
                        <h4>
                            <FormattedMessage
                                id={`app.${module}.reports.columnPicker.view.${name}`}
                                defaultMessage={name}
                            />
                        </h4>
                    }
                    labelClassName={styles['title']}
                />
                {columns.map(col => (
                    <TextCheckbox
                        key={col.id}
                        value={col.id}
                        title={resolveTitle(col, columnDefsMap, module)}
                        labelClassName={styles['label']}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="ColumnGroup"
                        data-metrics-value={col.label}
                    />
                ))}
            </CheckboxGroup>
        </div>
    );
};

ColumnGroup.propTypes = {
    name: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    onToggleColumn: PropTypes.func,
    columnsDef: PropTypes.arrayOf(PropTypes.object),
};

export default ColumnGroup;
