import React, { useCallback } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { StripeBankTransferDetailsModal } from '../../../../../billing-and-payments/components/StripeBankTransferDetailsModal/StripeBankTransferDetailsModal';
import { isBankTransfer } from '../../../../../billing-and-payments/config/PaymentType';
import { PAYMENT_STATUS_TYPE } from '../../../../../billing-and-payments/config/paymentStatusConsts';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../../../../taboola-common-frontend-modules/modals';
import ActionableTextCellRenderer from './ActionableTextCellRenderer';
import ActionsCellRenderer from './ActionsCellRenderer';
import styles from '../../../../../billing-and-payments/components/BalanceAndPaymentsPanel/balanceAndPayments.module.scss';

export const PaymentMethodCellRenderer = ({ data, context, valueFormatted, ...rest }) => {
    context.dispatch = noop;
    const { paymentMethod, paymentStatus, bankTransferUrl } = data;
    const { open: openModal } = useModal();

    const onBankTransferUrlClick = useCallback(
        () =>
            openModal({
                title: (
                    <FormField
                        label={
                            <FormattedMessage
                                id="billingAndPayments.modal.bankTransferDetails.title"
                                defaultMessage="Bank Transfer Details"
                            />
                        }
                    />
                ),
                contentRenderer: props => <StripeBankTransferDetailsModal pageUrl={bankTransferUrl} />,
                className: styles['stripe-bank-transfer-details-modal'],
                hasCloseButton: true,
            }),
        [openModal, bankTransferUrl]
    );

    if (isBankTransfer(paymentMethod)) {
        if (
            (paymentStatus === PAYMENT_STATUS_TYPE.PENDING_FUNDS || paymentStatus === PAYMENT_STATUS_TYPE.PAID) &&
            bankTransferUrl
        ) {
            return (
                <ActionableTextCellRenderer clickAction={onBankTransferUrlClick} context={context} {...rest}>
                    {valueFormatted}
                </ActionableTextCellRenderer>
            );
        }
    }

    return [valueFormatted];
};

PaymentMethodCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    ...ActionsCellRenderer.propTypes,
};
