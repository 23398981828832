import { useMemo } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { useFormValidatedValue } from '../../../../../../../taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { useMarketplaceAudienceTargetingValues } from '../../../MarketplaceAudiences/hooks/useMarketplaceAudienceTargetingValues';
import { MAX_SEGMENTS_TO_SELECT } from '../../../Tree';
import { listValidations } from '../myAudiencesValidations';

export const useMyAudiencesValidation = (
    allSegmentsByIdMap,
    includeAudiences,
    isOptionsLoadedSuccessfully,
    isDirty
) => {
    const { includeLength: marketPlaceAudienceValuesLength } = useMarketplaceAudienceTargetingValues();

    const { value: { length: myAudienceIncludeValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
    });

    const { value: { length: contextualSegmentsValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting.include',
    });

    const { value: { length: customContextualSegmentsValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.include',
    });

    const warningValidationDependencies = useMemo(() => {
        return {
            data: {
                myAudienceIncludeValuesLength,
                marketPlaceAudienceValuesLength,
                contextualSegmentsValuesLength,
                customContextualSegmentsValuesLength,
                fieldLength: myAudienceIncludeValuesLength,
            },
        };
    }, [
        myAudienceIncludeValuesLength,
        marketPlaceAudienceValuesLength,
        contextualSegmentsValuesLength,
        customContextualSegmentsValuesLength,
    ]);

    const errorValidationDependencies = useMemo(() => {
        return {
            limit: MAX_SEGMENTS_TO_SELECT,
            allSegmentsIdMap: allSegmentsByIdMap,
            includeAudiences,
        };
    }, [allSegmentsByIdMap, includeAudiences]);

    const validationDependencies = useMemo(
        () => ({ ...errorValidationDependencies, ...warningValidationDependencies }),
        [errorValidationDependencies, warningValidationDependencies]
    );

    const includeValidations = listValidations;
    const isReady = useMemo(() => isOptionsLoadedSuccessfully, [isOptionsLoadedSuccessfully]);
    const { failedValidationData: includeFailedValidationData } = useFormValidatedValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
        validations: includeValidations,
        validationDependencies,
        isReady,
        isDirty,
    });

    return {
        includeFailedValidationData,
    };
};
