import React, { useMemo } from 'react';
import uuid from 'uuid/v1';
import { useSelectedAccount } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { GLOBAL_PRESET_ACCOUNT_ID } from '../../ReportPresetPicker/constants';
import { BaseColumnPicker } from './BaseColumnPicker';

export const ColumnPresetCreator = ({ onCancel, reportConfig }) => {
    const { formatMessage } = useIntl();
    const isCustomPresetsPermitted = usePermissions('ADS_CONSOLE_CUSTOM_REPORT_PRESETS');
    const [{ id: accountId }] = useSelectedAccount();

    const presetAccountId = isCustomPresetsPermitted ? accountId : GLOBAL_PRESET_ACCOUNT_ID;

    const newReportPresetName = useMemo(() => `Custom_${uuid()}`, []);
    const defaultPresetDisplayName = formatMessage({
        id: 'app.campaigns.reports.columnPicker.title.default',
        defaultMessage: 'New Preset',
    });

    return (
        <BaseColumnPicker
            onCancel={onCancel}
            reportConfig={reportConfig}
            reportPresetName={newReportPresetName}
            reportPresetDisplayName={defaultPresetDisplayName}
            reportPresetAccountId={presetAccountId}
        />
    );
};
