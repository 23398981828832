import React from 'react';
import { INDICATION_TYPES, IndicationContent } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './uploadLogoError.module.scss';

export const UploadLogoError = ({ error }) => {
    if (!error) {
        return null;
    }
    const { defaultMessage, id } = error;

    return (
        <IndicationContent
            type={INDICATION_TYPES.ERROR}
            containerClassName={styles['container']}
            contentClassName={styles['content']}
        >
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </IndicationContent>
    );
};
