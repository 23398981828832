import React, { useCallback } from 'react';
import { DeletableItem } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CampaignRuleItemRender } from '../ConversionRulesDropDown/CampaignRuleItemRender';
import styles from './CampaignConversionRules.module.scss';

export const CampaignConversionRuleItem = ({ item, deleteItem }) => {
    const { displayName } = item;
    const { formatMessage } = useIntl();

    const handleOnClick = useCallback(() => deleteItem(item), [item, deleteItem]);

    return (
        <DeletableItem
            buttonProps={{
                'aria-label': `${formatMessage({ id: 'app.modal.removeVariation.button.remove' })} - ${displayName}`,
            }}
            onDelete={handleOnClick}
            contentClassName={styles['container']}
        >
            <CampaignRuleItemRender data={item} />
        </DeletableItem>
    );
};
