import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CustomPresetsList from './CustomPresetsList';
import { CustomPresetsTooltip } from './CustomPresetsTooltip';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';

const CustomPresets = () => {
    const isPermitted = usePermissions('ADS_CONSOLE_VIDEO_CUSTOM_PRESETS');

    return isPermitted ? (
        <FormField
            inputId="custom-presets"
            label={<FormattedMessage id="video.campaign.editor.customPresets.title" defaultMessage="Custom Presets" />}
            description={
                <FormattedMessage
                    id="video.campaign.editor.customPresets.description"
                    defaultMessage="Add custom targeting options."
                />
            }
            containerClass={classnames(commonStyles['input'])}
            helpText={<CustomPresetsTooltip />}
        >
            <CustomPresetsList />
        </FormField>
    ) : null;
};

export default CustomPresets;
