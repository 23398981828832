'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var STYLED_WITH_MODE_BUTTON_TYPE = {
    SYSTEM: 'system',
    ICON_BUTTON: 'icon-button'
};

exports.default = STYLED_WITH_MODE_BUTTON_TYPE;