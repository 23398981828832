import React from 'react';
import { Button } from 'tuui';
import { EmailIcon } from 'tuui';
import { FormField, Indication } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { EmailPixelCode } from 'modules/tracking/components/EmailPixelCode/EmailPixelCode';
import styles from './emailInstructions.module.scss';

export const EmailInstructions = () => {
    const { open: openModal } = useModal();

    const openModalDialog = async () => {
        await openModal({
            title: (
                <FormField
                    label={
                        <FormattedMessage
                            id="app.modal.tracking.sendEmail.title"
                            defaultMessage="Email the Pixel Code"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="app.modal.tracking.sendEmail.description"
                            defaultMessage="Send the tag and detailed instructions to the email account associated with your account.\nThis email can be easily forwarded to a developer so they may add the code to your site."
                        />
                    }
                />
            ),
            contentRenderer: EmailPixelCode,
            className: styles['modal'],
        });
    };
    return (
        <Indication
            type="info"
            message={
                <FormField
                    label={
                        <FormattedMessage id="tracking.email.instructions.title" defaultMessage="Email instructions" />
                    }
                    description={
                        <FormattedMessage
                            id="tracking.email.instructions.subtitle"
                            defaultMessage="You can easily share detailed instructions and the code to any email by clicking on the button below."
                        />
                    }
                >
                    <Button onClick={openModalDialog} size={Button.size.lg} variant={Button.variant.ghost}>
                        <EmailIcon className={styles['email-icon']} />
                        <FormattedMessage
                            id="tracking.email.instructions.button"
                            defaultMessage="Select Email Address"
                        />
                    </Button>
                </FormField>
            }
            displayDismissComponent={false}
        />
    );
};
