import {
    DELETE_CAMPAIGN_GROUP_ERROR,
    DELETE_CAMPAIGN_GROUP_SUCCESS,
    REQUEST_DELETE_CAMPAIGN_GROUP,
} from './actionTypes';

export const requestDeleteCampaignGroup = () => ({ type: REQUEST_DELETE_CAMPAIGN_GROUP });

export const deleteCampaignGroupSuccess = campaign => ({ type: DELETE_CAMPAIGN_GROUP_SUCCESS, payload: campaign });

export const deleteCampaignGroupError = error => ({ type: DELETE_CAMPAIGN_GROUP_ERROR, payload: error });
