import React, { useState, useEffect } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { LoadingBar } from 'modules/campaigns/components';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { COMPONENT_STATUS } from 'services/constants';
import {
    AiVariationsContextProvider,
    getHasPageProductDetails,
    useAiVariationsContext,
} from '../../../AiVariationsContextProvider';
import { TitlesGenerationDetailsFormBase } from '../SetupSection/TitlesGenerationDetailsFormBase';
import { HelpWriteFooter } from './HelpWriteFooter';
import styles from './helpWriteModal.module.scss';

const HelpWriteModal = ({ onClose, initialData }) => {
    const [generationStatus, setGenerationStatus] = useState(COMPONENT_STATUS.INITIAL);
    const { url, campaignId, pageProductDetails: initialPageProductDetails, language: initialLanguage } = initialData;
    const data = useAiVariationsContext();
    const {
        fetchPageProductDetails,
        setPageProductDetails,
        cancelFetchPageProductDetails,
        crawlLpStatus,
        landingPageData,
        language,
        onLanguageChange,
    } = data;
    const hasInitialProductDetails = getHasPageProductDetails(initialPageProductDetails);
    const hasProductDetails = getHasPageProductDetails(data);

    // values passed in here should be static (from initialData) so this is essentially an onMount
    useEffect(() => {
        setPageProductDetails(initialPageProductDetails);
        onLanguageChange(initialLanguage);
        if (!hasInitialProductDetails && url) {
            fetchPageProductDetails(url);
        }
    }, [
        url,
        fetchPageProductDetails,
        hasInitialProductDetails,
        setPageProductDetails,
        initialPageProductDetails,
        onLanguageChange,
        initialLanguage,
    ]);

    const isCrawling = crawlLpStatus === COMPONENT_STATUS.LOADING;
    const isGenerating = generationStatus === COMPONENT_STATUS.LOADING;
    const closeData = { landingPageData, pageProductDetails: data, crawlLpStatus, language };
    const onCancel = () => onClose(closeData);
    return (
        <ModalFormLayout
            onCancel={onCancel}
            formClassName={styles['form']}
            footer={
                <HelpWriteFooter
                    isGenerating={isGenerating}
                    setGenerationStatus={setGenerationStatus}
                    isCrawling={isCrawling}
                    campaignId={campaignId}
                    onSuccess={result => onClose({ suggestedText: result, ...closeData })}
                    onError={error => onClose({ error, ...closeData })}
                    onCancel={onCancel}
                    hasProductDetails={hasProductDetails}
                />
            }
        >
            {isCrawling ? (
                <div className={styles['loading-container']}>
                    <LoadingBar
                        durationInSeconds={30}
                        loadingHeader={
                            <FormattedMessage
                                id="creative.creator.aiVariationsSetup.modal.generate.loading.header"
                                defaultMessage="Analyzing landing page URL"
                            />
                        }
                        loadingMessage={
                            <div className={styles['loading-message-container']}>
                                <FormattedMessage
                                    id="creative.creator.aiVariationsSetup.modal.generate.loading.message"
                                    defaultMessage="Please wait a few seconds while we analyze your landing page URL to create{br}effective AI headlines. Stay tuned for engaging content!"
                                />
                                <div className={styles['loading-message-skip']} onClick={cancelFetchPageProductDetails}>
                                    <FormattedMessage
                                        id="creative.creator.aiVariationsSetup.modal.generate.loading.skip"
                                        defaultMessage="Skip automated analysis"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            ) : (
                <FormField
                    label={
                        <FormattedMessage
                            id="creative.creator.aiVariationsSetup.modal.generate.content.label"
                            defaultMessage="Help me write"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="creative.creator.aiVariationsSetup.modal.generate.content.description"
                            defaultMessage="Share your product details to unlock AI Generated titles & descriptions"
                        />
                    }
                    labelClass={styles['form-label']}
                    descriptionClass={styles['form-description']}
                >
                    <TitlesGenerationDetailsFormBase {...data} isGenerating={isGenerating} />
                </FormField>
            )}
        </ModalFormLayout>
    );
};

export const HelpWriteModalWithProviders = withFormDataProvider(
    props => (
        <AiVariationsContextProvider {...props.initialData}>
            <HelpWriteModal {...props} />
        </AiVariationsContextProvider>
    ),
    { isNetworkOwnerAllowed: true }
);
