import { useCallback, useMemo, useState } from 'react';
import { some } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { isNetworkAccountType } from 'modules/account-management';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useUnipFunnelApi } from 'services/api/unipFunnelAPI';

export const useUnipConversions = (accountId, rules) => {
    const { [COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW]: numDays } = useCommonConfig(
        COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW
    );

    const [{ type }] = useSelectedAccount();

    const [currentOptions, setCurrentOptions] = useState([]);
    const { getConversionRulesData } = useUnipFunnelApi();

    const { formatMessage } = useIntl();

    const loadConversionRulesData = useCallback(
        (accountId, page, pageSize) => {
            return getConversionRulesData(accountId, isNetworkAccountType(type), page, pageSize);
        },
        [type, getConversionRulesData]
    );

    const loadConversionRules = useCallback(
        async ({ page, pageSize }) => {
            const { results } = await loadConversionRulesData(accountId, page, pageSize);
            const filteredResults = results?.filter(
                item => !some(rules, rule => item.apiUnipRuleConfiguration?.id === rule.id)
            );
            return [filteredResults, filteredResults ? filteredResults.length : 0];
        },
        [loadConversionRulesData, accountId, rules]
    );

    const options = useMemo(
        () =>
            currentOptions.map(conversionRule => {
                if (!conversionRule) {
                    return {};
                }

                const { avgCvr, clicks, conversions, ruleCategory, apiUnipRuleConfiguration } = conversionRule;
                const { id, displayName: conversionName, status } = apiUnipRuleConfiguration;
                const formattedRuleCategory = formatMessage({
                    id: `tracking.conversions.report.category.${ruleCategory}`,
                    defaultMessage: ruleCategory,
                });
                const displayName = `${conversionName} (${formattedRuleCategory})`;
                return {
                    id,
                    displayName,
                    status,
                    avgCvr,
                    clicks,
                    conversions,
                    ruleCategory,
                    value: id.toString(),
                    label: displayName,
                    numDays,
                    apiUnipRuleConfiguration,
                };
            }),
        [currentOptions, numDays, formatMessage]
    );
    return {
        options,
        loadConversionRules,
        setCurrentOptions,
    };
};
