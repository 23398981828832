import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { INPUT_GROUP_TYPES as TYPES, InputGroupWithButton } from 'taboola-ultimate-ui';
import { DropdownOption } from 'components/Dropdown';
import { useAccountAdditionalData, useAvailableItems, useSelectedAccount } from 'hooks';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import {
    EVENT_TYPE_OPTIONS,
    EVENT_TYPE_OPTIONS_MAP,
    EVENT_TYPES,
    TAG_TYPE_OPTIONS,
    TAG_TYPES,
    useThirdPartyTagsConfig,
} from '../config';
import { EventTypeTooltip, InputTooltip, TagTypeTooltip } from './Tooltips';
import delimiterStyle from '../delimiter.module.scss';
import styles from './collapsibleListInput.module.scss';

const classNameBuilder = classnames.bind(styles);

const impressionEventTypeOption = EVENT_TYPE_OPTIONS_MAP[EVENT_TYPES.IMPRESSION];

const TagTypePlaceholder = () => {
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();
    return (
        <div className={styles['placeholder-text']}>
            <FormattedMessage id={`${MSG_ID_PREFIX}.placeholder.tagType`} defaultMessage="Tag Type" />
        </div>
    );
};
const EventTypePlaceholder = () => {
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();
    return (
        <div className={styles['placeholder-text']}>
            <FormattedMessage id={`${MSG_ID_PREFIX}.placeholder.eventType`} defaultMessage="Event Type" />
        </div>
    );
};

const initialState = {
    tagType: {},
    tagValue: '',
    eventType: '',
};

const INPUT_STEPS = {
    TAG_TYPE: 0,
    TAG_VALUE: 1,
    EVENT_TYPE: 2,
    ADD: 3,
};

export const CollapsibleListInput = ({ validateAddedItem, setIsInputFilled }) => {
    const { formatMessage } = useIntl();
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();
    const [data, setData] = useState({ ...initialState });
    const { tagType, tagValue, eventType } = data;
    const isDisabled = !tagType.value || !tagValue || !eventType.value;
    const [selectedAccount] = useSelectedAccount();
    const { accountAdditionalData: { advertiserGroupTier } = {} } = useAccountAdditionalData();

    const step = useMemo(() => {
        if (!tagType || isEmpty(tagType)) {
            return INPUT_STEPS.TAG_TYPE;
        } else if (!tagValue) {
            return INPUT_STEPS.TAG_VALUE;
        } else if (!eventType) {
            return INPUT_STEPS.EVENT_TYPE;
        }
        return INPUT_STEPS.ADD;
    }, [tagType, tagValue, eventType]);

    const getSelectedValueObject = useCallback(key => (get(data, [key, 'value']) ? data[key] : null), [data]);

    const onAddItem = useCallback(() => {
        const tagToAdd = {
            type: get(data, 'tagType.value'),
            eventType: get(data, 'eventType.value'),
            tagValue: {
                type: get(data, 'tagType.valueType'),
                value: get(data, 'tagValue'),
            },
        };

        validateAddedItem(tagToAdd);
        // clear all inputs
        setData({ ...initialState });
    }, [data, validateAddedItem]);

    const getGradientClassFromStep = step => {
        switch (step) {
            case INPUT_STEPS.TAG_TYPE:
                return 'one-third';
            case INPUT_STEPS.TAG_VALUE:
                return 'two-thirds';
            default:
                return '';
        }
    };

    // for THIRD_PARTY_PIXEL tag type: all event types are possible
    // for all other tag types: only Impression is enabled
    const isEventTypeOptionDisabled = useCallback(
        ({ value }) => {
            const isThirdPartyTagType =
                get(tagType, 'value', TAG_TYPES.THIRD_PARTY_PIXEL) === TAG_TYPES.THIRD_PARTY_PIXEL;
            const isImpressionEventType = value === EVENT_TYPES.IMPRESSION;

            return (!isThirdPartyTagType && !isImpressionEventType) || step < INPUT_STEPS.EVENT_TYPE;
        },
        [tagType, step]
    );

    const onChangeTagType = useCallback(
        (newTagType = {}) => {
            setData(prevData => ({
                ...prevData,
                tagType: newTagType,
                // if the new tag type is NOT 3rd Party Pixel, change the Event Type to
                // Impression, otherwise keep it as is
                eventType:
                    newTagType.value !== TAG_TYPES.THIRD_PARTY_PIXEL ? impressionEventTypeOption : prevData.eventType,
            }));
        },
        [setData]
    );
    const options = useAvailableItems(TAG_TYPE_OPTIONS, { selectedAccount, advertiserGroupTier });

    const tagTypeDropdownConfig = useMemo(
        () => ({
            type: TYPES.DROPDOWN,
            dataKey: 'tagType',
            props: {
                id: 'test',
                tooltip: <TagTypeTooltip />,
                selectedValueObject: getSelectedValueObject('tagType'),
                placeholder: <TagTypePlaceholder />,
                optionItemRenderer: props => <DropdownOption msgIdPrefix={`${MSG_ID_PREFIX}.tagType`} {...props} />,
                onChange: onChangeTagType,
                width: '150px',
                options,
            },
        }),
        [onChangeTagType, getSelectedValueObject, options, MSG_ID_PREFIX]
    );

    const tagValueConfig = useMemo(
        () => ({
            type: TYPES.INPUT,
            dataKey: 'tagValue',
            tooltip: <InputTooltip />,
            props: {
                placeholder: formatMessage({
                    id: `${MSG_ID_PREFIX}.placeholder.add.your.tag`,
                    defaultMessage: 'Add your 3rd party tag here',
                }),
                inputClass: styles['input'],
                mainClass: styles['input-container'],
                value: data.tagValue,
                disabled: step < INPUT_STEPS.TAG_VALUE,
            },
        }),
        [data, formatMessage, MSG_ID_PREFIX, step]
    );

    const eventTypeConfig = useMemo(
        () => ({
            type: TYPES.DROPDOWN,
            dataKey: 'eventType',
            props: {
                placeholder: <EventTypePlaceholder />,
                tooltip: <EventTypeTooltip />,
                selectedValueObject: getSelectedValueObject('eventType'),
                optionItemRenderer: props => <DropdownOption msgIdPrefix={`${MSG_ID_PREFIX}.eventType`} {...props} />,
                isOptionDisabled: isEventTypeOptionDisabled,
                disabled: step < INPUT_STEPS.EVENT_TYPE,
                width: '180px',
                options: EVENT_TYPE_OPTIONS,
                styles: {
                    control: (provided, state) => ({
                        ...provided,
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        backgroundColor: state.isDisabled ? styles.dropdownBackgroundColor : '#fff',
                        height: '50px',
                    }),
                },
            },
        }),
        [getSelectedValueObject, isEventTypeOptionDisabled, MSG_ID_PREFIX, step]
    );

    const fullConfig = useMemo(
        () => [tagTypeDropdownConfig, tagValueConfig, eventTypeConfig],
        [tagTypeDropdownConfig, tagValueConfig, eventTypeConfig]
    );

    useEffect(() => {
        setIsInputFilled(!isDisabled);
    }, [isDisabled, setIsInputFilled]);

    return (
        <InputGroupWithButton
            data={data}
            setData={setData}
            config={fullConfig}
            className={classNameBuilder('container', getGradientClassFromStep(step))}
            delimiterClassName={delimiterStyle['delimiter']}
            buttonProps={{
                disabled: isDisabled,
                onClick: onAddItem,
                className: styles['add-button'],
                children: <FormattedMessage id={`${MSG_ID_PREFIX}.input.group.button.text`} defaultMessage="SUBMIT" />,
            }}
        />
    );
};

CollapsibleListInput.propTypes = {
    validateAddedItem: PropTypes.func,
    setIsInputFilled: PropTypes.func,
};
