import React from 'react';
import { isEmpty, sum, values } from 'lodash';
import { ControlledLoadingBar } from 'modules/campaigns/components';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { LoadingOverlay } from 'modules/taboola-common-frontend-modules/forms';
import { FormLoadingOverlay } from 'modules/taboola-common-frontend-modules/forms/components/FormLoadingOverlay/FormLoadingOverlay';
import { useFormLoadingOverlayLoader } from 'modules/taboola-common-frontend-modules/forms/components/FormLoadingOverlay/useFormLoadingOverlayLoader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './excelBulkLoadingOverlay.module.scss';

export const ExcelBulkLoadingOverlay = ({ valuePerEntity = {}, maxPerEntity = {} }) => {
    const Loader = useFormLoadingOverlayLoader();
    const { isAccountFetched } = useAccount();

    if (!isAccountFetched) {
        return <FormLoadingOverlay />;
    }

    const hasValue = !isEmpty(valuePerEntity);
    const hasMax = !isEmpty(maxPerEntity);

    let displayValue = 0;
    let displayMax = 1;

    if (!isEmpty(maxPerEntity)) {
        displayMax = sum(values(maxPerEntity));
    }

    if (!isEmpty(valuePerEntity)) {
        displayValue = Math.min(sum(values(valuePerEntity)), displayMax);
    }

    return (
        <LoadingOverlay className={styles['loading-overlay']}>
            <div className={styles['loading-overlay-background']} />
            <div className={styles['loading-content-container']}>
                <div className={styles['loading-content']}>
                    <Loader fill="currentColor" />
                    <ControlledLoadingBar
                        value={displayValue}
                        max={displayMax}
                        containerClassName={styles['loading-bar']}
                        loadingMessageClassName={styles['loading-message']}
                        loadingMessage={
                            <>
                                <FormattedMessage
                                    id="excel.bulk.report.loading.default"
                                    values={{
                                        suffix:
                                            hasValue || hasMax ? (
                                                <FormattedMessage
                                                    id="excel.bulk.report.loading.progress"
                                                    values={{
                                                        value: displayValue,
                                                        max: displayMax,
                                                    }}
                                                />
                                            ) : (
                                                ''
                                            ),
                                    }}
                                />
                                <FormattedMessage
                                    id="excel.bulk.report.loading.warning"
                                    defaultMessage="Please do not close the page. This may take a few minutes"
                                />
                            </>
                        }
                    />
                </div>
            </div>
        </LoadingOverlay>
    );
};
