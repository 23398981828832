import React from 'react';
import { CropIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './croppingContentHeader.module.scss';

export const CroppingContentHeader = () => (
    <>
        <div className={styles['header-label']}>
            <CropIcon />
            <FormattedMessage
                id="cropping.content.header.title"
                defaultMessage="Crop your images for better composition"
            />
        </div>
        <div className={styles['header-sub-label']}>
            <FormattedMessage
                id="cropping.content.header.sub.title"
                defaultMessage="Optimize each aspect ratio to improve your ad appearance"
            />
        </div>
    </>
);
