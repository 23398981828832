'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.NavRightIconSmall = exports.NavLeftIconSmall = exports.NavRightIcon = exports.NavLeftIcon = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Icons = require('../../Icons/Icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'datePickerNavigationIcons__taboola-svg-icon___1fbNm',
    'icon': 'datePickerNavigationIcons__icon___6gx1s',
    'small': 'datePickerNavigationIcons__small___3mCr0',
    'left': 'datePickerNavigationIcons__left___mwLke',
    'right': 'datePickerNavigationIcons__right___2Yuy_'
};


var NavLeftIcon = _react2.default.createElement(_Icons.LeftIcon, { className: styles['icon'] + ' ' + styles['left'] });
var NavRightIcon = _react2.default.createElement(_Icons.RightIcon, { className: styles['icon'] + ' ' + styles['right'] });

var NavLeftIconSmall = _react2.default.createElement(_Icons.LeftSmallIcon, { className: styles['small'] + ' ' + styles['icon'] + ' ' + styles['left'] });
var NavRightIconSmall = _react2.default.createElement(_Icons.RightSmallIcon, { className: styles['small'] + ' ' + styles['icon'] + ' ' + styles['right'] });

exports.NavLeftIcon = NavLeftIcon;
exports.NavRightIcon = NavRightIcon;
exports.NavLeftIconSmall = NavLeftIconSmall;
exports.NavRightIconSmall = NavRightIconSmall;