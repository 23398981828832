import { useQuery } from 'react-query';
import { useAudiencesApi } from '../../../../../../services/api';
import { useAccount } from '../../../../../taboola-common-frontend-modules/account-configurations';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const useMailDomainsAutocomplete = input => {
    const { getMRTAutoComplete } = useAudiencesApi();
    const { accountName } = useAccount();

    const domainToValueAndLabel = ({ domain }) => ({ label: domain, value: domain });

    const { isLoading, data = [] } = useQuery(
        ['mailDomains', input],
        () => getMRTAutoComplete(accountName, { search_text: input }),
        {
            select: ({ results }) => results.map(domainToValueAndLabel),
            refetchOnWindowFocus: false,
            staleTime: DAY,
            enabled: !!input && input.length > 1,
        }
    );

    return { isLoadingAutoComplete: isLoading, autocompletedDomains: data };
};
