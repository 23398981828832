import { useState } from 'react';

const INDICATION_STATUS = { INITIAL: 'INITIAL', ACTIVE: 'ACTIVE', DISMISSED: 'DISMISSED' };

export const useSuccessIndicationState = showSuccessIndication => {
    const [successIndicationStatus, setSuccessIndicationStatus] = useState(INDICATION_STATUS.INITIAL);

    const isSuccessIndicationVisible = successIndicationStatus === INDICATION_STATUS.ACTIVE;

    const triggerSuccessIndication = () => {
        if (showSuccessIndication && successIndicationStatus === INDICATION_STATUS.INITIAL) {
            setSuccessIndicationStatus(INDICATION_STATUS.ACTIVE);
        }
    };
    const dismissSuccessIndication = () => {
        setSuccessIndicationStatus(INDICATION_STATUS.DISMISSED);
    };

    return { isSuccessIndicationVisible, triggerSuccessIndication, dismissSuccessIndication };
};
