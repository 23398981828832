import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { SetupIcon } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components';
import { FormLayout, SectionHeader, useVerticalScrollDirection } from 'modules/campaigns/components/Form';
import { AdFormatsSection } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/AdFormatsSection';
import { AdSetupSection } from 'modules/campaigns/modules/creative-creator/components/AdSetupSection/AdSetupSection';
import { NameEdit } from 'modules/campaigns/modules/creative-creator/components/NameEdit/NameEdit';
import { customIDValidations } from 'modules/campaigns/modules/creative-creator/components/NameSetup/validations';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { useAdScheduleConfig } from 'modules/campaigns/modules/creative-editor/hooks/useAdScheduleConfig';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { AdScheduler } from '../../AdScheduler/AdScheduler';
import { ContentSection } from '../../ContentSection';
import { CreativeEditorHeader } from '../../CreativeEditorHeader/CreativeEditorHeader';
import { SetupSection } from '../../SetupSection';
import { TrackingSection } from '../../TrackingSection';
import styles from './creativeEditorFormBody.module.scss';

export const CreativeEditorFormBody = ({
    className,
    showSetupSection,
    headerLabel,
    hideStatusSection,
    selectedAccount,
    language,
    mediaSection,
    isIncludeTrackingSection,
    startDateCampaign,
    endDateCampaign,
}) => {
    const { fetchStatus } = useFormDataContext();
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    const isCreativeCustomDataEnabled = useConfigMatch({ [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' });
    const showAdScheduler = useAdScheduleConfig();
    const { timeZoneName } = selectedAccount;
    const { scrollElementRef: scrollRef, down: collapsedHeader } = useVerticalScrollDirection();

    return (
        <FormLayout
            className={className}
            header={
                <CreativeEditorHeader
                    creativeStatusClassName={styles['creative-status']}
                    collapsed={collapsedHeader}
                    headerLabel={headerLabel}
                    hideStatusSection={hideStatusSection}
                    isCreativeCustomDataEnabled={isCreativeCustomDataEnabled}
                />
            }
            headerContainerClassName={styles['editor-header']}
            contentContainerClassName={styles['content-container']}
            bodyContainerClassName={styles['body-container']}
            hideFooter
            bodyRef={scrollRef}
        >
            {showSetupSection && <SetupSection selectedAccount={selectedAccount} />}
            {isCreativeCustomDataEnabled && (
                <CommonCollapsibleCard
                    id="CREATIVE_SETUP"
                    header={<SectionHeader headerIcon={<SetupIcon />} headerText="Setup" />}
                    containerClassName={styles['collapsible-card']}
                >
                    <div name="custom-id">
                        <NameEdit
                            field="customId"
                            messageIdPrefix="creative.creator.creation.custom"
                            validations={customIDValidations}
                            innerClassName={styles['name-edit-container']}
                            inputId="customId"
                        />
                        {showAdScheduler && (
                            <AdScheduler
                                startDateCampaign={startDateCampaign}
                                endDateCampaign={endDateCampaign}
                                timeZoneName={timeZoneName}
                            />
                        )}
                    </div>
                </CommonCollapsibleCard>
            )}
            {mediaSection}
            <ContentSection language={language} />
            {isAppInstall && (
                <div>
                    <AdFormatsSection renderAppInstallFormat={isAppInstall} />
                    <AdSetupSection />
                </div>
            )}
            {isIncludeTrackingSection && <TrackingSection />}
            {fetchStatus === COMPONENT_STATUS.PROCESSING && <Spinner className={styles['overlay']} />}
        </FormLayout>
    );
};

CreativeEditorFormBody.propTypes = {
    className: PropTypes.string,
    headerLabel: PropTypes.node,
    showSetupSection: PropTypes.bool,
    hideStatusSection: PropTypes.bool,
    selectedAccount: PropTypes.object,
    language: PropTypes.string,
    mediaSection: PropTypes.node,
    isIncludeTrackingSection: PropTypes.bool,
};
