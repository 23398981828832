import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from '../../../../../hooks';
import { setRecommendations } from '../../../../recommendations';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP } from '../../../config';
import { ENTITY } from '../../../config';
import {
    generateCampaignCreatorPath,
    generateCampaignEditorPath,
    generateCreativeCreatorPath,
    generateCreativeEditorPath,
} from '../../../services/utils';
import { INITIAL_CREATIVE_TYPE } from '../../creative-editor/hooks/useInitialCreativeType';
import { useRecommendationsQueryKey } from './useRecommendationsQueryKey';

export const useRecommendationService = () => {
    const queryClient = useQueryClient();
    const key = useRecommendationsQueryKey();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const editCreativeWithRecommendation = ({ recommendationId, accountId, campaignId, itemId, data }) => {
        dispatch(
            setRecommendations({
                recommendationId,
            })
        );

        const { performanceStatus } = data;
        const editCreativePath = generateCreativeEditorPath({ accountId, campaignId, creativeId: itemId });
        const pathWithCreativeType = `${editCreativePath}${mergeQueryParams('', {
            [INITIAL_CREATIVE_TYPE]: PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP[performanceStatus],
        })}`;
        navigate(pathWithCreativeType);
    };

    const editCampaignWithRecommendation = ({
        recommendation,
        recommendationData,
        recommendationMetadata,
        accountId,
        campaignId,
    }) => {
        dispatch(
            setRecommendations({
                recommendationType: recommendation.type,
                recommendationId: recommendation.id,
                recommendationData,
                recommendationMetadata,
            })
        );
        navigate(generateCampaignEditorPath({ accountId, campaignId }));
    };

    const createCampaignWithRecommendation = ({ recommendation, recommendationData }) => {
        dispatch(
            setRecommendations({
                recommendationType: recommendation.type,
                recommendationId: recommendation.id,
                recommendationData,
            })
        );
        navigate(generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN }));
    };

    const createCreativeWithRecommendation = ({ recommendation, recommendationData }) => {
        dispatch(
            setRecommendations({
                recommendationType: recommendation.type,
                recommendationId: recommendation.id,
                recommendationData,
            })
        );
        navigate(generateCreativeCreatorPath({ entity: ENTITY.CREATIVE }));
    };

    const removeRecommendationFromActiveList = async recommendationIdToDismiss => {
        const data = queryClient.getQueryData(key);
        await queryClient.invalidateQueries(key);
        queryClient.setQueryData(key, () => {
            return {
                results: data?.results?.filter(recommendation => {
                    return recommendation.id !== recommendationIdToDismiss;
                }),
            };
        });
    };

    const addToAppliedRecommendationsList = async appliedRecommendationId => {
        const data = queryClient.getQueryData(key);
        await queryClient.invalidateQueries(key);
        queryClient.setQueryData(key, () => {
            return {
                results: data?.results.map(recommendation => {
                    if (recommendation.id === appliedRecommendationId) {
                        recommendation.usedTimeUtc = Date.now();
                    }
                    return recommendation;
                }),
            };
        });
    };

    const dismissRecommendation = recommendationIdToDismiss => {
        removeRecommendationFromActiveList(recommendationIdToDismiss);
    };

    const applyRecommendation = appliedRecommendationId => {
        addToAppliedRecommendationsList(appliedRecommendationId);
    };

    return {
        applyRecommendation,
        dismissRecommendation,
        editCampaignWithRecommendation,
        editCreativeWithRecommendation,
        createCampaignWithRecommendation,
        createCreativeWithRecommendation,
    };
};
