import React from 'react';
import { withStylesheet } from 'taboola-ultimate-ui';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './wordBreakEllipsisCellRenderer.module.scss';

const EllipsisCellRendererWithStyles = withStylesheet(EllipsisCellRenderer, styles, {
    [EllipsisCellRenderer.classNames.container]: 'word-break',
});

export const WordBreakEllipsisCellRenderer = props => <EllipsisCellRendererWithStyles {...props} />;

WordBreakEllipsisCellRenderer.propTypes = {
    ...EllipsisCellRenderer.propTypes,
};

WordBreakEllipsisCellRenderer.defaultProps = {
    lines: 5,
};
