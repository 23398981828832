import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import ToggleSwitchCellRenderer from './ToggleSwitchCellRenderer';

const TooltipMessage = ({ value, reportId, defaultMessage = 'Campaign Status', data } = {}) => {
    const { accountName: currConversionAccountName } = data;
    const [{ accountName }] = useSelectedAccount();
    const isAnotherAccountConversion = accountName !== currConversionAccountName;
    return isAnotherAccountConversion ? (
        <FormattedMessage
            id="app.campaigns.reports.filters.type.conversionStatus.otherPublisher"
            defaultMessage="Conversion status of other accounts cannot be toggled"
        />
    ) : (
        <FormattedMessage id={`app.campaigns.${reportId}.state.tooltip.${value}`} defaultMessage={defaultMessage} />
    );
};

const ConversionStatusCellRenderer = ({ data, isDisabled, ...rest }) => {
    const { isGridEditDisabled } = useGridContext();
    return (
        <TooltipWrapper
            tooltipContent={<TooltipMessage data={data} {...rest} />}
            tooltipOptions={{ arrow: true, placement: TOOLTIP_POSITION.RIGHT }}
        >
            <ToggleSwitchCellRenderer {...rest} data={data} disabled={isGridEditDisabled || isDisabled(data)} />
        </TooltipWrapper>
    );
};

ConversionStatusCellRenderer.propTypes = {
    ...ToggleSwitchCellRenderer.propTypes,
    isDisabled: PropTypes.func,
};

ConversionStatusCellRenderer.defaultProps = {
    isDisabled: () => false,
};

export default ConversionStatusCellRenderer;
