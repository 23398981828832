import React from 'react';
import { errorMessagesUtils } from 'services/utils';
import { campaignsApi } from '../../../services/api';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { updateCampaignsSuccess, updateCampaignsError, requestUpdateCampaigns } from '../actions';

const successIndication = {
    message: <FormattedMessage id="campaigns.save.success" defaultMessage="Campaigns successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="campaign.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const updateCampaigns = (accountId, campaignIds, changes) => dispatch => {
    const update = {
        ids: campaignIds,
        update: changes,
    };
    dispatch(requestUpdateCampaigns());
    campaignsApi
        .updateCampaigns(accountId, update)
        .then(({ results }) => {
            dispatch(addIndication(successIndication));
            dispatch(updateCampaignsSuccess(results));
        })
        .catch(error => {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateCampaignsError(campaignIds, error));
        });
};

export default updateCampaigns;
