import {
    BACKSTAGE_CONTENT_PERMISSION,
    PERMISSIONS_OPERATORS,
} from 'modules/taboola-common-frontend-modules/authentication/constants/index';
import { hybridModuleFactory } from '../backstage-content';
import ACCOUNT_TYPES, {
    isNetworkAccountType,
    isAdminNetworkAccountType,
    isGroupAccountType,
    isPartnerAccountType,
    isVideoAccount,
    isSponsoredAccount,
    PARTNER_TYPE,
    isLowTouchAccount,
} from './accountType';

const MODULE_NAME = 'account-management';
const externalPath = 'reports/admin/publisher-management';
const ModuleComponent = hybridModuleFactory(externalPath);
const modulePermissions = {
    permissions: ['PUBLISHER_EDIT'],
};
const permissions = {
    permissions: [BACKSTAGE_CONTENT_PERMISSION, modulePermissions],
    operator: PERMISSIONS_OPERATORS.AND,
};

export {
    MODULE_NAME,
    ModuleComponent,
    permissions,
    externalPath,
    ACCOUNT_TYPES,
    PARTNER_TYPE,
    isNetworkAccountType,
    isAdminNetworkAccountType,
    isGroupAccountType,
    isPartnerAccountType,
    isVideoAccount,
    isSponsoredAccount,
    isLowTouchAccount,
};
