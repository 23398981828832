import React from 'react';
import { WeekIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CampaignsGroupTimeFrame from '../CampaignsGroupTimeFrame/CampaignsGroupTimeFrame';

const CampaignsGroupScheduleSection = () => (
    <CommonCollapsibleCard
        id="CAMPAIGNS_GROUP_SCHEDULE"
        header={
            <SectionHeader
                headerIcon={<WeekIcon />}
                headerText={<FormattedMessage id="campaigns.group.editor.schedule" />}
            />
        }
    >
        <CampaignsGroupTimeFrame />
    </CommonCollapsibleCard>
);

export default CampaignsGroupScheduleSection;
