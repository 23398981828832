/**
 * Created by oreuveni
 * Date: 2019-04-18
 * Time: 15:07
 */
const CATEGORIES = {
    NONE: 'NONE',
    ALCOHOL: 'ALCOHOL',
    BEAUTY: 'BEAUTY',
    BUSINESS: 'BUSINESS',
    DATING: 'DATING',
    EDUCATION: 'EDUCATION',
    ENVIRONMENT: 'ENVIRONMENT',
    FAMILY: 'FAMILY',
    FASHION: 'FASHION',
    FITNESS: 'FITNESS',
    FOOD: 'FOOD',
    GAMBLING: 'GAMBLING',
    HEALTH: 'HEALTH',
    HOME: 'HOME',
    INVESTMENT_PRODUCTS: 'INVESTMENT_PRODUCTS',
    LIFESTYLE: 'LIFESTYLE',
    MUSIC: 'MUSIC',
    NEWS: 'NEWS',
    FINANCE: 'FINANCE',
    PETS: 'PETS',
    POLITICS: 'POLITICS',
    RELIGION: 'RELIGION',
    SPORTS: 'SPORTS',
    TECH: 'TECH',
    TRAVEL: 'TRAVEL',
};

export default CATEGORIES;
