import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import AudienceFormBreadcrumbs from '../components/AudienceFormBreadcrumbs/AudienceFormBreadcrumbs';
import CustomContextualSetupSection from '../components/CustomContextualSetupSection/CustomContextualSetupSection';
import CustomContextualTopicsSection from '../components/CustomContextualTopicsSection/CustomContextualTopicsSection';
import CustomContextualSizeEstimationWidget from '../components/SegmentTopics/CustomContextualSizeEstimationWidget/CustomContextualSizeEstimationWidget';
import { customContextualInitialValues } from '../config/customContextualInitialValues';
import { createAudience } from '../flows';
import useAudienceCreatorState from '../hooks/useAudienceCreatorState';
import styleConsts from '../../../globalStyle/styleConsts.module.scss';
import styles from './audiencesCreatorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const CustomContextualCreatorPage = ({ onCancel }) => {
    const dispatch = useDispatch();
    const reportId = REPORT_TYPE.CUSTOM_CONTEXTUAL;

    const submitAudience = useCallback(
        (audience, accountId) => dispatch(createAudience(accountId, audience, reportId)),
        [dispatch, reportId]
    );
    const { formatMessage } = useIntl();
    const { step, setStep, submit } = useAudienceCreatorState({
        submitAudience,
    });

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={formatMessage({
                        id: 'custom.contextual.creator.first.step.title',
                        defaultMessage: 'Segment Settings',
                    })}
                    id="settings"
                >
                    <StaticIndications />
                    <AudienceFormBreadcrumbs
                        reportId={reportId}
                        title={formatMessage({
                            id: 'audience.creator.new.custom.contextual.segment',
                            defaultMessage: 'New Topics Segment',
                        })}
                    />
                    <div className={classNameBuilder('form-wrapper', 'widget')}>
                        <div>
                            <WizardStep
                                label={formatMessage({
                                    id: 'custom.contextual.creator.setup.step.title',
                                    defaultMessage: 'Setup',
                                })}
                                id="audience-setup"
                            >
                                <CustomContextualSetupSection />
                            </WizardStep>
                            <WizardStep
                                label={formatMessage({
                                    id: 'custom.contextual.creator.topics.step.title',
                                    defaultMessage: 'Topics',
                                })}
                                id="audience-topics"
                            >
                                <CustomContextualTopicsSection />
                                <div className={styles['buttons-wrapper']}>
                                    <FormButtons
                                        className={styles['form-buttons']}
                                        onSubmit={submit}
                                        onCancel={onCancel}
                                        submitLabel={formatMessage({ id: 'app.actionButtons.create.segment' })}
                                        submitIcon={<ChevronRightOutlinedIcon />}
                                        submitDataMetricsComponent="NextButton"
                                        cancelLabel={formatMessage({ id: 'app.actionButtons.cancel' })}
                                    />
                                </div>
                            </WizardStep>
                        </div>
                        <CustomContextualSizeEstimationWidget />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const CustomContextualCreatorPageWithFormDataProvider = withFormDataProvider(CustomContextualCreatorPage, {
    defaultValuesConfig: customContextualInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { CustomContextualCreatorPageWithFormDataProvider as CustomContextualTargetingCreatorPage };
