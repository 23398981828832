import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const AdUnitTypeFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.adUnitTypeFieldTooltip.tooltip"
            defaultMessage="Can be used to target or block Video ad units.
                            In-Feed: includes all In-Feed video units excluding units that start from a slider.
                            Slider: includes all Slider video units (including Feed that starts from slider).
                            Mid-Article: includes all mid-Article video units.
                            Note: Most campaigns should target all ad units, unless specifically requested by the client."
        />
    </TooltipSection>
);

export default AdUnitTypeFieldTooltip;
