import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isNil } from 'lodash';
import { userSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { fetchAccountSuccess } from '../../../../actions';
import { useChatAgentPermitted } from '../../../../components/ChatAgent/hooks/useChatAgentPermitted';
import { fetchAppAccountDependencies, fetchAccount, fetchAccountAdditionalData } from '../../../../flows';
import { useDimension } from '../../../../hooks/queryParams';
import { useSelectedAccountId } from '../../../../hooks/queryParams/useSelectedAccountId';
import { appInitFormLocalStorageSelector } from '../../../../selectors';
import { accountsApi } from '../../../../services/api';
import { transformAccountFromGW } from '../../../../services/transformers';
import { isIframeMode } from '../../iframe/utils/isIframeMode';
import { getAccountFromQueryCache } from '../utils/getAccountFromQueryCache';
import { getStringAccountId } from '../utils/getStringAccountId';
import { isEmptyAccountId } from '../utils/isEmptyAccountId';
import { ACCOUNT_CACHE_KEY } from './useAccountCache';

const TEMP_CACHE_KEY = 'TEMP_CACHE_KEY';

export const useFetchedAccount = ({
    accountId,
    accountName,
    networkAccountName,
    setAccountId: setContextAccountId,
    isGlobal,
}) => {
    const dispatch = useDispatch();
    const isAppInitFromLocalStorage = useSelector(appInitFormLocalStorageSelector);
    const { username } = useSelector(userSelector);
    const isAuthorized = !!username;
    const resolvedAccountId = getStringAccountId(accountId);
    const [urlAccountId, setUrlAccountId] = useSelectedAccountId();
    const chatAgentPermitted = useChatAgentPermitted();
    const queryClient = useQueryClient();
    const [dimension] = useDimension();
    const [isError, setError] = useState(false);
    const enabled =
        isAuthorized &&
        isAppInitFromLocalStorage &&
        (!isEmptyAccountId(accountId) || (!accountName && !networkAccountName));

    const fetchAdditionalData = async fetchedAccount => {
        let additionalData = {};
        if (!isNil(fetchedAccount.accountId)) {
            try {
                if (isGlobal) {
                    dispatch(fetchAppAccountDependencies(fetchedAccount, chatAgentPermitted));
                }
                additionalData = await dispatch(fetchAccountAdditionalData(fetchedAccount));
            } catch {
                setError(true);
                return;
            }
            additionalData.isAccountFetched = true;
        }
        const fullAccount = { ...fetchedAccount, ...additionalData };

        const accountIdToSet = fetchedAccount.accountId;
        const accountIdToSetString = getStringAccountId(accountIdToSet);
        queryClient.setQueryData([ACCOUNT_CACHE_KEY, accountIdToSetString], fullAccount);
        if (isEmptyAccountId(accountId) && !isGlobal) {
            setContextAccountId(accountIdToSet);
        }

        return fullAccount;
    };

    // Fetch account for every resolvedAccountId if it was not fetched/cached
    const { data: account, isError: isAccountFetchError } = useQuery(
        [ACCOUNT_CACHE_KEY, resolvedAccountId],
        async () => {
            let fetchedAccount;
            try {
                fetchedAccount = await dispatch(fetchAccount(resolvedAccountId));
            } catch (error) {
                if (!isIframeMode() && !isEmptyAccountId(urlAccountId)) {
                    queryClient.removeQueries([ACCOUNT_CACHE_KEY, '']);
                    setUrlAccountId('');
                }
                throw error;
            }

            const fullAccount = await fetchAdditionalData(fetchedAccount);

            if (isEmptyAccountId(urlAccountId) && isGlobal) {
                setUrlAccountId(fetchedAccount.accountId);
            }

            // edge case to fetch account without id, we skip key [ACCOUNT_CACHE_KEY, '']
            if (isEmptyAccountId(resolvedAccountId)) {
                return null;
            }

            return fullAccount;
        },
        {
            enabled,
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            staleTime: Infinity,
            retry: 0,
        }
    );

    //This query is needed for scenarios when account id is unknown it does fetch by name and put into
    // cache [ACCOUNT_CACHE_KEY, accountId] by fetched account id
    useQuery(
        [TEMP_CACHE_KEY, accountName, networkAccountName],
        async () => {
            if (accountName) {
                const cachedAccount = getAccountFromQueryCache(queryClient, accountName);
                if (cachedAccount) {
                    setContextAccountId(cachedAccount.accountId);
                    return;
                }
            }

            let fetchedAccount;
            try {
                fetchedAccount = accountName
                    ? await accountsApi.getAccountByName(accountName)
                    : await accountsApi
                          .getAccountsUnderNetwork('', 1, 1, networkAccountName, dimension)
                          .then(response => response.results[0]);
            } catch {
                setError(true);
                return;
            }

            fetchedAccount = transformAccountFromGW(fetchedAccount);
            dispatch(fetchAccountSuccess(cloneDeep(fetchedAccount)));

            fetchAdditionalData(fetchedAccount);
        },
        {
            enabled: (!!accountName || !!networkAccountName) && isEmptyAccountId(accountId),
            cacheTime: 0,
            staleTime: 0,
            retry: 0,
        }
    );

    return account || { accountId, accountName, isError: isError || isAccountFetchError };
};
