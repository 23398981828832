import React from 'react';
import PropTypes from 'prop-types';
import { SpeakerIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { FORM_MODES } from '../../../../config';
import { AccountDropdown, NameCreate } from '../../../common-campaign-form';

export const SetupSection = ({ isAccountSelectionEnabled, children }) => {
    const { formAccount, setFormAccount, mode } = useFormDataContext();

    return (
        <CommonCollapsibleCard
            id="VIDEO_CAMPAIGNS_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SpeakerIcon />}
                    headerText={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                />
            }
        >
            {mode === FORM_MODES.CREATE && <NameCreate messageIdPrefix="campaign" />}
            {isAccountSelectionEnabled && (
                <AccountDropdown
                    accountForCampaign={formAccount}
                    onAccountChange={setFormAccount}
                    helpText={
                        <FormattedMessage
                            id="campaign.account.help"
                            defaultMessage="The new campaign will be created in the selected account."
                        />
                    }
                />
            )}
            {children}
        </CommonCollapsibleCard>
    );
};

SetupSection.propTypes = { isAccountSelectionEnabled: PropTypes.bool };

export default SetupSection;
