"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgBell(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M19.9539 20.4L13.9759 20.4C13.9759 21.974 12.5503 24 10.9827 24C9.41502 24 7.99788 21.974 7.99788 20.4L2.01989 20.4C0.196475 20.4-0.29223 18.953 0.196475 18.585C1.63129 17.5121 2.02057 15.5963 2.01989 13.8L2.01989 8.325C2.01989 3.60296 6.28826 0 10.9912 0C15.6942 0 19.9539 3.60296 19.9539 8.325L19.9539 13.8C19.949 15.5953 20.344 17.5118 21.7774 18.585C22.2655 18.9527 21.7774 20.4 19.9539 20.4ZM17.5627 8.28469C17.4432 5.06356 14.14 2.4 10.9898 2.4C7.83959 2.4 4.53057 5.06356 4.41108 8.28469L4.41108 13.8C4.41085 15.3205 4.72842 16.697 3.96248 18L18.0199 18C17.2524 16.6975 17.5631 15.3209 17.5627 13.8L17.5627 8.28469Z",
            fill: "currentColor",
            opacity: 1
        })
    );
}
exports.default = SvgBell;