import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { getStringAccountId } from '../utils/getStringAccountId';

export const ACCOUNT_CACHE_KEY = 'ACCOUNT_CACHE_KEY';

const emptyAccount = {};

export const useAccountCache = () => {
    const queryClient = useQueryClient();
    const getAccountFromCache = useCallback(
        accountId => {
            const resolvedAccountId = getStringAccountId(accountId);
            const account = queryClient.getQueryData([ACCOUNT_CACHE_KEY, resolvedAccountId]) || emptyAccount;

            return account;
        },
        [queryClient]
    );

    return getAccountFromCache;
};
