import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EditSmallIcon } from 'taboola-ultimate-ui';
import { APP_EVENT_TYPE } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { CHAT_AGENT_ACTION_STATUS } from 'modules/taboola-common-frontend-modules/chat-agent/config/chatAgentActionStatus';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { GridContext } from '../GridContextProvider/GridContextProvider';
import SimpleCellRenderer from './SimpleCellRenderer';
import styles from './withEditIndication.module.scss';

const withEditIndication = (WrappedComponent = SimpleCellRenderer) => {
    class EditIndicationHoc extends Component {
        componentDidUpdate(prevProps, prevState, snapshot) {
            const { cellToEdit } = this.props;
            if (prevProps.cellToEdit !== cellToEdit) {
                this.trackCellToEdit();
            }
        }

        trackCellToEdit = () => {
            const { cellToEdit, colDef, node, pushAppEvent } = this.props;

            if (cellToEdit?.colId === colDef.colId && cellToEdit?.rowId === node.id) {
                this.handleEditCell();
                pushAppEvent({
                    status: CHAT_AGENT_ACTION_STATUS.IN_PROGRESS,
                    type: APP_EVENT_TYPE.EDIT_GRID_CELL,
                });
            }
        };

        handleEditCell = () => {
            const { api, column, colDef, node } = this.props;
            const { singleClickEdit } = colDef;

            if (!singleClickEdit) {
                return;
            }

            api.startEditingCell({
                rowIndex: node.rowIndex,
                colKey: column.colId,
                rowPinned: colDef.pinned,
            });
        };

        render() {
            const {
                isEditDisabled: isRowEditDisabled,
                forwardedRef,
                isGridEditDisabled,
                hideEditIndication,
                ...rest
            } = this.props;
            const { column, context } = rest || {};
            const { colDef } = column || {};
            const { field, headerName, editable } = colDef || {};
            const { data } = rest;
            const needIndication =
                !hideEditIndication &&
                !isRowEditDisabled &&
                !isGridEditDisabled &&
                (typeof editable === 'function' ? editable({ data, context }) : editable);

            return (
                <Fragment>
                    {needIndication && (
                        <span
                            role="presentation"
                            className={styles['container']}
                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                            data-metrics-component="InlineEditor"
                            data-metrics-column-id={field}
                            data-metrics-column-name={headerName}
                            onClick={this.handleEditCell}
                        >
                            <span className={styles['edit-indicator']}>
                                <EditSmallIcon aria-label="Edit cell icon" />
                            </span>
                        </span>
                    )}
                    <WrappedComponent ref={forwardedRef} {...rest} />
                </Fragment>
            );
        }
    }

    EditIndicationHoc.propTypes = {
        value: PropTypes.any,
        api: PropTypes.any,
        column: PropTypes.any,
        colDef: PropTypes.any,
        node: PropTypes.any,
        isEditDisabled: PropTypes.bool,
        forwardedRef: PropTypes.object,
    };

    EditIndicationHoc.defaultProps = {
        isEditDisabled: false,
    };

    return React.forwardRef((props, ref) => (
        <GridContext.Consumer>
            {({ isGridEditDisabled, cellToEdit, pushAppEvent }) => (
                <EditIndicationHoc
                    {...props}
                    isGridEditDisabled={isGridEditDisabled}
                    forwardedRef={ref}
                    cellToEdit={cellToEdit}
                    pushAppEvent={pushAppEvent}
                />
            )}
        </GridContext.Consumer>
    ));
};

export default withEditIndication;
