import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ExcludeDaysToolTip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="app.create.performance.rules.advanced.settings.exclude.days.from.time.range.tooltip"
                defaultMessage="Excluding days from the rule's time frame is beneficial in cases where a known conversion delay exists. In such instances, the objective is to have the rule disregard the last X days when assessing the campaign’s performance."
            />
        </TooltipSection>
    </>
);
