import { useCallback, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { get, isEmpty, last } from 'lodash';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { mergeQueryParams, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { MODULE_TEMPLATE } from '../../config/routes';
import { useMultiLayerDrawerContext } from '../../modules/multi-layer-drawer/components/MultiLayerDrawerProvider/MultiLayerDrawerProvider';
import { useCurrentValueGetter } from '../useCurrentValueGetter';
import { useNavigate } from '../useNavigate';

export const DRAWER_STACK = 'drawerStack';

const emptyDrawerStack = [];

const useDrawerStackUrl = queryParamHookFactory(DRAWER_STACK, {
    persist: PERSISTENCE_TYPE.MEMORY,
    defaultValue: emptyDrawerStack,
    deserializer: drawerStackUrl => {
        try {
            if (!drawerStackUrl) {
                return emptyDrawerStack;
            }

            return JSON.parse(decodeURIComponent(drawerStackUrl));
        } catch (e) {
            console.error('Failed to parse drawerStackUrl:', e);
            return emptyDrawerStack;
        }
    },
    serializer: value => encodeURIComponent(JSON.stringify(value)),
});
export const useDrawerStack = () => {
    const [drawerStack] = useDrawerStackUrl();
    const { setEntityData } = useMultiLayerDrawerContext();

    const navigate = useNavigate();
    const location = useLocation();

    const getLocation = useCurrentValueGetter(location);
    const routeMatch = useRouteMatch(MODULE_TEMPLATE);

    const navigateToTopLayerPath = useCallback(
        stack => {
            const lastLayer = last(stack) || {};
            const moduleName = get(routeMatch, 'params.module');
            const path = isEmpty(stack) ? moduleName : lastLayer.path;

            if (getLocation().pathname === lastLayer.path) {
                return;
            }

            navigate(
                `${path}${mergeQueryParams(getLocation().search, {
                    [DRAWER_STACK]: stack,
                })}`
            );
        },
        [getLocation, navigate, routeMatch]
    );

    const push = useCallback(
        form => {
            const drawerForm = drawerStack.find(item => item.pattern === form.pattern);
            if (drawerForm) {
                gtmTracker.trackError(`${drawerForm.id} form already exists in the drawer stack`);
                return;
            }

            navigateToTopLayerPath([...drawerStack, form]);
        },
        [drawerStack, navigateToTopLayerPath]
    );

    const isMultiLayerMode = useMemo(() => drawerStack.length > 1, [drawerStack]);

    const pop = useCallback(
        data => {
            const newDrawerStack = [...drawerStack];
            newDrawerStack.pop();
            if (data) {
                setEntityData(data);
            }

            navigateToTopLayerPath(newDrawerStack);
        },
        [drawerStack, setEntityData, navigateToTopLayerPath]
    );

    return { stack: drawerStack, push, pop, isMultiLayerMode };
};
