import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { ExternalLink } from 'components';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const EXPORT_TYPES = {
    CSV: 'CSV',
    GOOGLE_DRIVE: 'GOOGLE_DRIVE',
};
export const DROPDOWN_OPTIONS = [
    {
        label: <FormattedMessage id="app.gridToolbar.export.options.csv" defaultMessage="Export to CSV" />,
        value: EXPORT_TYPES.CSV,
    },
    {
        label: (
            <FormattedMessage id="app.gridToolbar.export.options.googleDrive" defaultMessage="Export to Google Drive" />
        ),
        value: EXPORT_TYPES.GOOGLE_DRIVE,
        accountConfigurations: { [FEATURE_FLAGS.GOOGLE_DRIVE_EXPORT_ENABLED]: 'true' },
    },
];

export const ERROR_INDICATIONS = {
    [EXPORT_TYPES.GOOGLE_DRIVE]: {
        message: (
            <FormattedMessage
                id="app.campaigns.reports.csv.googleDrive.error"
                defaultMessage="Export to Google Drive failed. Please try again later or contact {link}."
                values={{
                    link: <ExternalLink href="mailto:support@taboola.com">support@taboola.com</ExternalLink>,
                }}
            />
        ),
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" />,
    },
    [EXPORT_TYPES.CSV]: {
        message: (
            <FormattedMessage
                id="app.campaigns.reports.csv.export.error"
                defaultMessage="Our apologies, something went wrong"
            />
        ),
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" />,
    },
};
