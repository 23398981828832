import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useBreadcrumbsAccountStep, { MAX_CHARACTER_COUNT } from '../../../hooks/useBreadcrumbsAccountStep';
import { reportsBaseConfig } from '../../campaigns/config';
import { REPORT_ID } from '../../campaigns/modules/campaigns-reports/hooks';
import { selectedReportSelector } from '../../campaigns/selectors';
import { AUDIENCES_ROUTE } from '../config';

const useAudienceBreadcrumbs = ({
    selectedReport: coerceReport,
    title,
    shouldAddAllAudiencesStep = true,
    isTitleAsLastStep = true,
}) => {
    const selectedReportState = useSelector(selectedReportSelector);
    const selectedReport = coerceReport || selectedReportState;
    const accountSteps = useBreadcrumbsAccountStep(AUDIENCES_ROUTE);

    const steps = useMemo(() => {
        const allAudiencesStep = {
            label: `${reportsBaseConfig[selectedReport].label}`,
            path: isTitleAsLastStep ? AUDIENCES_ROUTE : null,
            params: { [REPORT_ID]: selectedReport },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };

        const steps = [...accountSteps];

        if (shouldAddAllAudiencesStep) {
            steps.push(allAudiencesStep);
        }

        if (isTitleAsLastStep) {
            steps.push({ label: title, maxCharacterCount: MAX_CHARACTER_COUNT });
        }
        return steps;
    }, [accountSteps, shouldAddAllAudiencesStep, isTitleAsLastStep, selectedReport, title]);

    return {
        steps,
    };
};

export default useAudienceBreadcrumbs;
