import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import OutlineEditableText from '../../../../components/OutlineEditableText/OutlineEditableText';
import BaseCellEditor from './BaseCellEditor';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';
import styles from './editableTextCellEditor.module.scss';

const classNameBuilder = classnames.bind(styles);

export const EditableTextCellEditor = ({ placeholder, guiAttached, editableTextClassName, onCancel, ...restProps }) => {
    const { colDef } = restProps;
    const {
        hasError,
        submitHandler,
        triggerValidationEvent,
        isValidationsRunning,
        isDirty,
        failedValidationData,
        ...fieldData
    } = useSingleFieldCellEditorData(restProps);

    return (
        <BaseCellEditor onCancel={onCancel} onSubmit={submitHandler} disableSubmit={hasError} colDef={colDef}>
            <OutlineEditableText
                data-testid="text-cell-editor-input"
                className={classNameBuilder('input-container', editableTextClassName)}
                placeholder={placeholder}
                autoFocus={guiAttached}
                {...fieldData}
                {...failedValidationData}
            />
        </BaseCellEditor>
    );
};

EditableTextCellEditor.propTypes = {
    placeholder: PropTypes.string,
    guiAttached: PropTypes.bool,
    editableTextClassName: PropTypes.string,
    onCancel: PropTypes.func,
};

const EditableTextCellEditorWithFormData = withGridCellFormDataProvider(EditableTextCellEditor);

export default EditableTextCellEditorWithFormData;
