"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCart(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M10.732 16.75a2 2 0 1 1-2.63-.787c-1.072-.797-1.834-2.13-2.042-3.683L4.97 5.757h-.983a1 1 0 1 1 0-2h2.677l.14.836.193 1.155 11.063.37a1 1 0 0 1 .966 1v1.309c0 3.08-1.64 4.795-4.018 4.793l-6.407.371c.458.724 1.125 1.159 1.819 1.159H18a1 1 0 0 1 0 2h-.268a2 2 0 1 1-3.465 0h-3.535zm-3.4-8.99l.646 3.864c5.676-.33 5.713-.331 6.972-.402 1.312 0 2.076-.8 2.076-2.795v-.342L7.333 7.76z"
        })
    );
}
exports.default = SvgCart;