import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDebounce } from 'react-use';
import { APP_EVENT_TYPE } from '../appEventType';

export const DEBOUNCE_TIMEOUT = 2000;

export const useNavigationAppEvents = (push, debounceTimeout = DEBOUNCE_TIMEOUT) => {
    const { pathname, search } = useLocation();
    const [debouncedLocation, setDebouncedLocation] = useState(`${pathname}${search}`);

    useDebounce(() => setDebouncedLocation(`${pathname}${search}`), debounceTimeout, [pathname, search]);

    useEffect(() => {
        push({ type: APP_EVENT_TYPE.NAVIGATE });
    }, [debouncedLocation, push]);
};
