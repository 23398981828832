'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _DatePicker = require('../DatePicker/DatePicker');

var _DatePicker2 = _interopRequireDefault(_DatePicker);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TimeFramePicker = function TimeFramePicker(_ref) {
    var startDate = _ref.startDate,
        endDate = _ref.endDate,
        onStartDateChange = _ref.onStartDateChange,
        onStartDateInputChange = _ref.onStartDateInputChange,
        onEndDateChange = _ref.onEndDateChange,
        onEndDateInputChange = _ref.onEndDateInputChange,
        disableStartDate = _ref.disableStartDate,
        disableEndDate = _ref.disableEndDate,
        startDataInputIcon = _ref.startDataInputIcon,
        startDatePlaceholderText = _ref.startDatePlaceholderText,
        endDataInputIcon = _ref.endDataInputIcon,
        endDatePlaceholderText = _ref.endDatePlaceholderText,
        focusedStartDate = _ref.focusedStartDate,
        focusedEndDate = _ref.focusedEndDate,
        isOutsideRange = _ref.isOutsideRange,
        displayFormat = _ref.displayFormat;
    return _react2.default.createElement(
        'div',
        { className: 'time-frame-picker' },
        _react2.default.createElement(
            'div',
            { className: 'time-frame-picker-start' },
            _react2.default.createElement(_DatePicker2.default, {
                date: startDate,
                onDateChange: onStartDateChange,
                disabled: disableStartDate,
                displayFormat: displayFormat,
                customInputIcon: startDataInputIcon,
                placeholder: startDatePlaceholderText,
                showClearDate: true,
                id: 'time-frame-date-input-start',
                onInputChange: onStartDateInputChange,
                focused: focusedStartDate,
                isOutsideRange: isOutsideRange
            })
        ),
        _react2.default.createElement(
            'div',
            { className: 'time-frame-picker-end' },
            _react2.default.createElement(_DatePicker2.default, {
                date: endDate,
                customInputIcon: endDataInputIcon,
                placeholder: endDatePlaceholderText,
                disabled: disableEndDate,
                onDateChange: onEndDateChange,
                displayFormat: displayFormat,
                showClearDate: true,
                id: 'time-frame-date-input-end',
                onInputChange: onEndDateInputChange,
                focused: focusedEndDate,
                isOutsideRange: isOutsideRange
            })
        )
    );
};

TimeFramePicker.propTypes = {
    /** Input value for start date */
    startDate: _reactMomentProptypes2.default.momentObj,
    /** Input value for start date */
    endDate: _reactMomentProptypes2.default.momentObj,
    /** Custom node for the start date input icon */
    startDataInputIcon: _propTypes2.default.node,
    /** Placeholder for end start input */
    startDatePlaceholderText: _propTypes2.default.string,
    /** Custom node for the end date input icon */
    endDataInputIcon: _propTypes2.default.node,
    /** Placeholder for end date input */
    endDatePlaceholderText: _propTypes2.default.string,
    /** startDate Disable input */
    disableStartDate: _propTypes2.default.bool,
    /** endDate Disable input */
    disableEndDate: _propTypes2.default.bool,
    /** Fires when a new start date is selected */
    onStartDateChange: _propTypes2.default.func,
    /** Fires when a new end date is selected */
    onEndDateChange: _propTypes2.default.func,
    /** Fires when a new start date input changed */
    onStartDateInputChange: _propTypes2.default.func,
    /** Fires when a new end date input changed */
    onEndDateInputChange: _propTypes2.default.func,
    /** startDate focused input */
    focusedStartDate: _propTypes2.default.bool,
    /** endDate focused input */
    focusedEndDate: _propTypes2.default.bool,
    /** Fires when a new end date is selected */
    displayFormat: _propTypes2.default.string,
    /** Determines which dates are out of the range of selection */
    isOutsideRange: _propTypes2.default.func
};

TimeFramePicker.defaultProps = {
    startDate: (0, _moment2.default)(),
    startDataInputIcon: _react2.default.createElement(
        'span',
        null,
        'START'
    ),
    endDataInputIcon: _react2.default.createElement(
        'span',
        null,
        'END'
    ),
    endDatePlaceholderText: 'No End Date',
    displayFormat: 'DD MMMM YYYY',
    onStartDateChange: _lodash2.default,
    onEndDateChange: _lodash2.default
};

exports.default = TimeFramePicker;