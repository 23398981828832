import { PHASES } from './phases';

export const PROGRESS_VALUE = {
    MAX_PROGRESS: 100,
    NINETY_PROGRESS: 90,
    NEGATIVE_INFINITY: -Infinity,
    NO_PIXEL_SCALING_MAX_PROGRESS: 10,
};

export const PROGRESS_PHASE_MAX = {
    [PHASES.PENDING_REVIEW]: PROGRESS_VALUE.NINETY_PROGRESS,
    [PHASES.LEARNING_LIMITED]: PROGRESS_VALUE.NINETY_PROGRESS,
    [PHASES.EXPEDITED_LEARNING]: PROGRESS_VALUE.NINETY_PROGRESS,
    [PHASES.EXTENDED_LEARNING]: PROGRESS_VALUE.NINETY_PROGRESS,
    [PHASES.LEARNING]: PROGRESS_VALUE.NINETY_PROGRESS,
    [PHASES.SCALING]: PROGRESS_VALUE.MAX_PROGRESS,
    NO_PIXEL_SCALING_MAX_PROGRESS: PROGRESS_VALUE.NO_PIXEL_SCALING_MAX_PROGRESS,
};

export const PROGRESS_COLOR = {
    PRIMARY: 'primary-progress',
    WARNING: 'warning-progress',
    STOPPED: 'stopped-progress',
};
