import React from 'react';
import { Button, DownloadOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BulkUploadResultMessages } from './components/BulkUploadResultMessages/BulkUploadResultMessages';
import styles from './bulkUploadResult.module.scss';

export const BulkUploadResult = ({ filename, responseSummary, onReportDownloadClick }) => (
    <div className={styles['container']}>
        <FormattedMessage
            id="campaign.creator.bulk.upload.file.title"
            defaultMessage="Uploaded Excel File"
            className={styles['title']}
        />
        <div className={styles['filename']}>{filename}</div>
        <FormattedMessage
            id="campaign.creator.bulk.upload.result.title"
            defaultMessage="File Submission Status"
            className={styles['title']}
        />
        <BulkUploadResultMessages responseSummary={responseSummary} />
        <FormattedMessage
            id="campaign.creator.bulk.upload.result.download.report.description"
            defaultMessage="In order to view failure reasons and retrieve the status of the operation, please download and view the following report."
        />
        <Button variant={Button.variant.outline} onClick={onReportDownloadClick} className={styles['download-button']}>
            <DownloadOutlinedIcon fontSize="small" />
            <FormattedMessage
                id="campaign.creator.bulk.download.report"
                defaultMessage="Download Bulk Upload Summary Report"
            />
        </Button>
    </div>
);
