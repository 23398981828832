import React, { Fragment, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { isFunction, startCase } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { navigate } from 'actions';
import { MODULE_NAME } from 'modules/audiences/config/routes';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import { removeIndication, StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import * as reportConfigIndex from '../../campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from '../../campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import AudienceReportBreadcrumbs from '../components/AudienceReportBreadcrumbs/AudienceReportBreadcrumbs';
import EditDrawer from '../components/EditDrawer/EditDrawer';
import styles from './audiencesReportsPage.module.scss';

const AudiencesReportsPage = () => {
    const location = useLocation();
    const containerRef = useRef();
    const { state } = location;
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch(null);

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, match.path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history, match.path]
    );

    const buildReportNameForExport = selectedReport => ` - ${startCase(selectedReport)} Audiences`;
    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    return (
        <Fragment>
            <div className={styles['container']} ref={containerRef}>
                <StaticIndications />
                <CampaignReports
                    skipFetchReport={state && state.skipFetchReport}
                    parentContainerRef={containerRef}
                    module={MODULE_NAME}
                    header={<AudienceReportBreadcrumbs />}
                    rangePickerPresetConfigs={{}}
                    buildReportNameForExport={buildReportNameForExport}
                    reportConfigMap={reportConfigMap}
                    closeSideDrawer={handleNavigation}
                />
            </div>
            <EditDrawer onCancel={handleNavigation} />
        </Fragment>
    );
};

AudiencesReportsPage.propTypes = {};

export default AudiencesReportsPage;
