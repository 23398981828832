import { get } from 'lodash';
import { checkNodeTypeIsHidden } from '../../MarketplaceAudiences/utils/andLogicUtils';
import { hasIntersection } from '../../MarketplaceAudiences/utils/andLogicUtils';
import { splitTaxonomy } from '../../Tree/utils/treeUtils';

export const checkBundlingNodeIsHidden = ({
    node,
    hiddenIdsSet,
    shouldHideAge,
    shouldHideGender,
    shouldHideOther,
    ageGroupTaxonomyList = [],
    genderGroupTaxonomyList = [],
    ageGroupTaxonomyParentsSet = new Set(),
    genderGroupTaxonomyParentsSet = new Set(),
}) => {
    const nodeId = String(get(node, 'id', ''));
    const pathIdsList = splitTaxonomy(get(node, 'path', ''));

    const checkIsAgeType = () => hasIntersection(ageGroupTaxonomyList, pathIdsList);
    const checkIsGenderType = () => hasIntersection(genderGroupTaxonomyList, pathIdsList);
    const checkIsAgeParent = () => ageGroupTaxonomyParentsSet.has(nodeId);
    const checkIsGenderParent = () => genderGroupTaxonomyParentsSet.has(nodeId);

    const isNodeTypeHidden = checkNodeTypeIsHidden({
        shouldHideAge,
        shouldHideGender,
        shouldHideOther,
        checkIsAgeType,
        checkIsGenderType,
        checkIsAgeParent,
        checkIsGenderParent,
    });

    if (isNodeTypeHidden) {
        return true;
    }
    // if an id on the path of the node is hidden (includes itself), it should be filtered
    return pathIdsList.some(id => hiddenIdsSet.has(id));
};
