import encodeurl from 'encodeurl';
import { createOneByOneCreatives } from 'modules/campaigns/modules/creative-creator/flows';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useSaveOneByOneHandler = (accountId, data, dispatch) => {
    const { onChange: setOneByOneData } = useFormFieldValue({ field: 'oneByOneData' });
    const updateOneByOneData = newData =>
        setOneByOneData(oneByOneData => {
            return { ...oneByOneData, ...newData };
        });

    const onOneByOneUrlCrawl = () => {
        setOneByOneData(prevState => {
            const { urlsCrawled } = prevState;
            return {
                ...prevState,
                urlsCrawled: urlsCrawled + 1,
            };
        });
    };
    return ({ shouldCrawlOneByOneUrls }) => {
        const {
            oneByOneData: { validatedUrls },
            campaigns: [campaign],
            creativeName,
            customId,
        } = data;
        const validatedAndEncodedUrls = validatedUrls.map(url => encodeurl(url));
        return dispatch(
            createOneByOneCreatives({
                accountId,
                campaign,
                urlList: validatedAndEncodedUrls,
                shouldCrawlUrls: shouldCrawlOneByOneUrls,
                onCrawlSuccess: onOneByOneUrlCrawl,
                onCrawlFailure: onOneByOneUrlCrawl,
                onCreationFailure: updateOneByOneData,
                creativeName,
                customId,
            })
        );
    };
};
