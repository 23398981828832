import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import AudienceDescriptionField from '../AudienceDescriptionField/AudienceDescriptionField';
import ExcludeAudienceField from '../ExcludeAudienceField/ExcludeAudienceField';
import TtlTypeField from '../TtlTypeField/TtlTypeField';

export const PixelBasedAudiencePropertiesSection = () => (
    <CommonCollapsibleCard
        id="PIXEL_AUDIENCE_PROPERTIES"
        header={
            <SectionHeader
                headerText={
                    <FormattedMessage
                        id="audience.editor.audience.properties.section.title"
                        defaultMessage="Audience Properties"
                    />
                }
            />
        }
    >
        <TtlTypeField />
        <br />
        <ExcludeAudienceField />
        <br />
        <AudienceDescriptionField />
    </CommonCollapsibleCard>
);

export default PixelBasedAudiencePropertiesSection;
