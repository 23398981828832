'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Inputs = require('../Inputs');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'textarea__taboola-svg-icon___1vWjT',
    'container': 'textarea__container___1Isry',
    'focus': 'textarea__focus___3Rusx',
    'textarea': 'textarea__textarea___ZqO5p'
};


var classNameBuilder = _bind2.default.bind(styles);

/**
 * @deprecated - use Textarea from tuui
 */
var Textarea = function Textarea(_ref) {
    var value = _ref.value,
        className = _ref.className,
        textareaClassName = _ref.textareaClassName,
        autoFocus = _ref.autoFocus,
        pre = _ref.pre,
        post = _ref.post,
        rest = _objectWithoutProperties(_ref, ['value', 'className', 'textareaClassName', 'autoFocus', 'pre', 'post']);

    var inputRef = (0, _react.useRef)();

    (0, _react.useEffect)(function () {
        if (autoFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus, inputRef]);

    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('container', className) },
        pre,
        _react2.default.createElement('textarea', Object.assign({
            ref: inputRef
        }, rest, {
            value: (0, _Inputs.replaceNull)(value),
            className: classNameBuilder('textarea', textareaClassName)
        })),
        post
    );
};

Textarea.propTypes = {
    /** Textarea's rows */
    rows: _propTypes2.default.number,
    /** Textarea's placeholder */
    placeholder: _propTypes2.default.string,
    /** Textarea's onChange event handler */
    onChange: _propTypes2.default.func.isRequired,
    /** Textarea's value */
    value: _propTypes2.default.string,
    /** Textarea's container classname value */
    className: _propTypes2.default.string,
    /** Textarea's textarea classname value */
    textareaClassName: _propTypes2.default.string,
    /** Textarea automatically focuses on render */
    autoFocus: _propTypes2.default.bool,
    /** Custom optional sibling pre element */
    pre: _propTypes2.default.PropTypes.element,
    /** Custom optional sibling post element */
    post: _propTypes2.default.PropTypes.element
};

Textarea.defaultProps = {
    rows: 5,
    placeholder: 'Enter your text here...'
};

exports.default = Textarea;