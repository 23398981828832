import React, { useMemo, useEffect, useLayoutEffect } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { LoadingBar } from 'modules/campaigns/components';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { addProtocol } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { COMPONENT_STATUS } from 'services/constants';
import { urlValidations } from '../../../../../../config';
import { useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { UrlValidatedInput } from '../../../UrlList/UrlValidatedInput';
import styles from './aiVariationsUrlItem.module.scss';

const validations = [...urlValidations];

export const AiVariationsUrlItem = ({ existingItems = [], fetchPageMetadata }) => {
    const { crawlLpStatus } = useAiVariationsContext();
    const { value: id } = useFormFieldValue({ field: 'id' });
    const { value, isDirty: isUrlDirty } = useFormFieldValue({
        field: 'value',
    });
    const { value: shouldLoadMetadata, onChange: setShouldLoadMetadata } = useFormFieldValue({
        field: 'shouldLoadMetadata',
    });
    const { onChange: setStatus } = useFormFieldValue({
        field: 'status',
    });
    const validationDependencies = useMemo(
        () => ({
            list: existingItems.filter(el => el.id !== id).map(({ value }) => addProtocol(value)),
        }),
        [id, existingItems]
    );

    const { formAccount } = useFormDataContext();
    const isCheckboxDisabled = !formAccount?.accountId;

    const handleLoadFromUrl = newValue => {
        const checked = Boolean(newValue);

        setShouldLoadMetadata(checked);
    };

    useLayoutEffect(() => {
        if (shouldLoadMetadata) {
            fetchPageMetadata(id, value);
        }
    }, [shouldLoadMetadata, id, value, fetchPageMetadata]);

    useEffect(() => {
        if (!isUrlDirty || !value) {
            return;
        }
        setStatus(COMPONENT_STATUS.ACTIVE);
    }, [setStatus, isUrlDirty, value]);

    return (
        <div className={styles['item-container']}>
            <UrlValidatedInput
                field="value"
                validations={validations}
                validationDependencies={validationDependencies}
                className={styles['input']}
                errorMessageClass={styles['error-msg']}
            />
            <CheckboxGroup
                onSelectCheckbox={handleLoadFromUrl}
                onUnselectCheckbox={() => handleLoadFromUrl()}
                selectedValues={[shouldLoadMetadata]}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={!shouldLoadMetadata}
                data-metrics-component="shouldLoadLandingPageMetadata"
            >
                <TextCheckbox
                    value
                    title={
                        <FormattedMessage
                            id="creative.creator.load.page.metadata"
                            defaultMessage="Load image and headline from the landing page URL"
                        />
                    }
                    disabled={isCheckboxDisabled}
                />
            </CheckboxGroup>
            {crawlLpStatus === COMPONENT_STATUS.LOADING && (
                <LoadingBar
                    durationInSeconds={30}
                    loadingMessage={
                        <FormattedMessage
                            id="creative.creator.load.page.loading.message"
                            defaultMessage="We're working on populating the product details from your landing page. It will only take a few seconds."
                        />
                    }
                    containerClassName={styles['loading-container']}
                />
            )}
            {crawlLpStatus === COMPONENT_STATUS.ERROR && (
                <div className={styles['retrieval-error']}>
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.landingPage.errorMessage"
                        defaultMessage="We’re unable to retrieve data from your landing page. Please assist by answering the following questions."
                    />
                </div>
            )}
        </div>
    );
};
