import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useSelectedAccount } from 'hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import AudienceNameField from '../AudienceNameField/AudienceNameField';
import { CombinedAudiences } from '../CombinedAudiences/CombinedAudiences';
import { CombinedAudiencesWithoutBundling } from '../CombinedAudiencesWithoutBundling/Tree/CombinedAudiencesWithoutBundling';
import { useIsCombinedWithBundlingEnabled } from '../CombinedAudiencesWithoutBundling/Tree/hooks/useIsCombinedWithBundlingEnabled';
import styles from './combinedAudiencesCollapsibleCard.module.scss';

export const CombinedAudiencesCollapsibleCard = () => {
    const [{ accountId }] = useSelectedAccount();
    const isBundlingEnabled = useIsCombinedWithBundlingEnabled();
    const { mode } = useFormDataContext();

    return (
        <CommonCollapsibleCard
            id="COMBINED_AUDIENCE_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.setup.section.title"
                            defaultMessage="Audience Setup"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && (
                <div className={styles['name-container']}>
                    <AudienceNameField />
                </div>
            )}
            <FormField
                label={
                    <FormattedMessage
                        id="audience.editor.combined.audience.setup.section.label"
                        defaultMessage="Audience segments"
                    />
                }
                description={
                    <FormattedMessage
                        id="audience.editor.combined.audience.setup.section.description"
                        defaultMessage="Select the audience segments to combine into one audience segment."
                    />
                }
            >
                {isBundlingEnabled ? (
                    <CombinedAudiences selectedAccountId={accountId} />
                ) : (
                    <CombinedAudiencesWithoutBundling selectedAccountId={accountId} />
                )}
            </FormField>
        </CommonCollapsibleCard>
    );
};
