import { useCallback, useMemo, useRef } from 'react';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { IS_DEBUG_MODE } from '../../../services/isDebugMode';
import { AppEventsContext } from './hooks/useAppEventContext';
import { useAppEventExtension } from './hooks/useAppEventExtension';
import { useAppEventQueueRelease } from './hooks/useAppEventQueueRelease';
import { useAppEventUICollector } from './hooks/useAppEventUICollector';
import { useIdleAppEvents } from './hooks/useIdleAppEvents';
import { useLogRocketLinkedEvents } from './hooks/useLogRocketLinkedEvents';
import { useNavigationAppEvents } from './hooks/useNavigationAppEvents';
import { convertToAppEventContext } from './utils/convertToAppEventContext';
import { serializeAppEvent } from './utils/serialezeAppEvent';

window.TA_APP_EVENTS = [];

export const AppEventsProvider = ({ children }) => {
    const queue = useRef([]);
    const listeners = useRef([]);
    const register = useCallback(callback => {
        listeners.current.push(callback);
        return () => {
            listeners.current = listeners.current.filter(listener => listener !== callback);
        };
    }, []);
    const extendEvent = useAppEventExtension();
    const isEnabled = useConfigMatch({ [FEATURE_FLAGS.CUSTOM_EVENT_AGGREAGATOR_ENABLED]: 'true' });
    const push = useCallback(
        async event => {
            const fullEvent = await extendEvent({ event });

            listeners.current.forEach(listener => listener(fullEvent));

            fullEvent.event = serializeAppEvent(event);

            if (!isEnabled) {
                return;
            }
            const eventToPush = convertToAppEventContext(fullEvent);

            queue.current.push(eventToPush);
            if (IS_DEBUG_MODE()) {
                window.TA_APP_EVENTS.push(eventToPush);
            }
        },
        [extendEvent, isEnabled]
    );

    useAppEventUICollector(push);
    useIdleAppEvents(push);
    useNavigationAppEvents(push);
    useAppEventQueueRelease(queue, register);
    useLogRocketLinkedEvents(register);

    const value = useMemo(() => ({ register, push }), [register, push]);

    return <AppEventsContext.Provider value={value}>{children}</AppEventsContext.Provider>;
};
