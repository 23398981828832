import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import {
    CONTEXTUAL_MIN_SIZE,
    CONTEXTUAL_POSTFIX,
    MARKETPLACE_MIN_SIZE,
    getSizeContentRenderer,
} from '../../MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import styles from './contextualSegmentsLeafContent.module.scss';

const ContextualSegmentsLeafContent = ({ item, searchText }) => {
    const { size, articles, label, loading } = item;

    if (loading) {
        return (
            <ContentLoader width={460} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="8" rx="8" ry="8" width="250" height="12" />
                <rect x="390" y="8" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    return (
        <AudienceDetails
            name={label}
            size={size}
            minSize={MARKETPLACE_MIN_SIZE}
            articles={articles}
            minArticles={CONTEXTUAL_MIN_SIZE}
            renderSizeContent={getSizeContentRenderer}
            showRemoveButton={false}
            highLightText={searchText}
            sizeIdPostfix={CONTEXTUAL_POSTFIX}
        />
    );
};

ContextualSegmentsLeafContent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.array,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    searchText: PropTypes.string,
};

export default ContextualSegmentsLeafContent;
