class Initiator {
    constructor() {
        this.objectsToInit = [];
    }

    register(obj) {
        this.objectsToInit.push(obj);
    }

    initAll(config) {
        this.objectsToInit.forEach(obj => {
            if (obj.init) {
                obj.init(config);
            }
        });
    }
}

export default new Initiator();
