import { invert } from 'lodash';

export const ENTITY = {
    SCHEDULED_REPORT: 'SCHEDULED_REPORT',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.SCHEDULED_REPORT]: 'scheduled-report',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
