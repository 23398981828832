import { batch } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { deleteScheduledReport } from '../../../services/api/scheduledReportApi';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { deleteScheduledReportError, deleteScheduledReportSuccess } from '../actions/deleteScheduledReport';

export const successIndication = {
    message: (
        <FormattedMessage
            id="app.create.scheduled.reports.delete.success"
            defaultMessage="Scheduled report successfully deleted."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.create.scheduled.reports.delete.error"
            defaultMessage="Unable to delete the scheduled report."
        />
    ),
});

export const deleteScheduledReportFlow = (accountName, scheduledReportId) => async dispatch => {
    try {
        const response = await deleteScheduledReport(accountName, scheduledReportId);
        batch(() => {
            dispatch(deleteScheduledReportSuccess(response));
            dispatch(addIndication(successIndication));
        });
    } catch (error) {
        batch(() => {
            dispatch(deleteScheduledReportError(error));
            dispatch(addIndication(generateErrorIndication(error)));
        });
    }
};
