import React, { useMemo } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { getCreativesABTestStatusProps } from '../../services/campaignStatusPhaseService';
import PhaseStatusTooltip from '../Tooltips/PhaseStatusTooltip';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

const CreativesABTestStatusRenderer = props => {
    const {
        data: { creativeTrafficAllocationABTestEndDate, creativeTrafficAllocationABTestStartTime },
        campaignStatusMessages,
        value: { status },
    } = props;

    const { phaseMsgIdPrefix } = campaignStatusMessages;

    const {
        phase,
        progress,
        progressBarColor,
        timeLeftProps: { id, value },
    } = useMemo(
        () =>
            getCreativesABTestStatusProps({
                creativeTrafficAllocationABTestEndDate,
                creativeTrafficAllocationABTestStartTime,
            }),
        [creativeTrafficAllocationABTestEndDate, creativeTrafficAllocationABTestStartTime]
    );

    return (
        <StatusCellRenderer
            phase={phase}
            timeLeft={<FormattedMessage id={`app.campaigns.campaign.status.time.left.${id}`} values={{ value }} />}
            tooltipBody={<PhaseStatusTooltip phase={phase} phaseMsgIdPrefix={phaseMsgIdPrefix} />}
            progress={progress}
            progressBarColor={progressBarColor}
            {...props}
            {...campaignStatusMessages}
            value={status}
        />
    );
};

export default CreativesABTestStatusRenderer;
