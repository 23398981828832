import React, { useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { GlobeIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreativesApi } from 'services/api';
import { useFileFieldValue } from '../../../common-campaign-form/hooks/useFileFieldValue';
import { SelectBrandLogo } from './Components/BrandLogo/SelectBrandLogo';
import { LogoPosition } from './Components/LogoPosition/LogoPosition';
import { SubtitlesField } from './SubtitlesField';

const LOGO_POSITION_NONE = 'NONE';
const MAX_FILE_SIZE = 10_000_000;
const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg'];

const validations = [
    {
        validationFn: value => !value?.file || value.file.trusted || ACCEPTED_FILE_TYPES.includes(value.file.type),
        messageId: 'video.creative.creator.logo.upload.file.type.error',
        defaultMessage: 'Wrong file format was selected, please add a single JPEG, JPG or PNG file.',
    },
    {
        validationFn: value => !value?.file || value.file.trusted || value.file.size < MAX_FILE_SIZE,
        messageId: 'video.creative.creator.logo.upload.file.size.error',
        defaultMessage: 'File size cannot exceed 10 MB.',
    },
];
export const VideoLogoSection = () => {
    const { value: videoItemUploadedData } = useFormFieldValue({
        field: 'videoItem.uploadedData',
    });
    const hashIds = useMemo(() => videoItemUploadedData?.map(a => a?.hashId), [videoItemUploadedData]);
    const { uploadManagedLogo } = useCreativesApi();
    const { value: logoPosition = LOGO_POSITION_NONE, onChange: setLogoPosition } = useFormFieldValue({
        field: 'videoUploadAssets.logo.position',
    });
    const { onChange: setVideoUploadedItems } = useFormFieldValue({
        field: 'videoUploadItems',
    });

    const { onDrop, value, cleanValue, isLoading, failedValidationData, cleanCache } = useFileFieldValue({
        field: 'logoItem',
        validations,
        fileUploader: ({ file }) => uploadManagedLogo(file, hashIds, logoPosition),
        dependencies: [logoPosition],
    });
    const cleanLogo = useCallback(() => {
        cleanCache();
        cleanValue();
        setVideoUploadedItems(null);
    }, [cleanCache, cleanValue, setVideoUploadedItems]);
    const changeLogoPosition = useCallback(
        (...args) => {
            cleanCache();
            setLogoPosition(...args);
        },
        [cleanCache, setLogoPosition]
    );

    useEffect(() => {
        if (isEmpty(value?.uploadedData)) {
            return;
        }
        setVideoUploadedItems(value.uploadedData);
    }, [value?.uploadedData, setVideoUploadedItems]);

    return (
        <CommonCollapsibleCard
            id="videoLogoSection"
            header={
                <SectionHeader
                    headerIcon={<GlobeIcon />}
                    headerText={
                        <FormattedMessage
                            id="creative.creator.logo.label"
                            defaultMessage="Logo & Subtitles (Optional)"
                        />
                    }
                />
            }
        >
            <SelectBrandLogo
                onDrop={onDrop}
                isLoading={isLoading}
                cleanValue={cleanLogo}
                fileName={value?.file?.name}
                failedValidationData={failedValidationData}
                disabled={isEmpty(videoItemUploadedData)}
            />
            <LogoPosition selected={logoPosition} onChange={changeLogoPosition} isDisabled={!value?.file} />
            <SubtitlesField />
        </CommonCollapsibleCard>
    );
};
