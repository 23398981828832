"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSpeaker(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M7.018 15.2c-1.623-.357-2.965-1.632-3.156-3.184-.266-2.17 1.406-4.084 3.679-4.3l4.546-.435 4.592-3.439c.836-.626 1.928-.159 2.053.874l1.319 10.858c.124 1.028-.804 1.748-1.77 1.353l-5.39-2.205-.614.075.286 2.33a2.464 2.464 0 0 1-1.96 2.7l-.282.054a2.509 2.509 0 0 1-2.988-2.16L7.018 15.2zm9.877-9.02l-4.06 3.039-5.104.487c-1.188.113-2.008 1.052-1.883 2.066.105.855 1.212 1.65 2.343 1.512l4.974-.61 4.755 1.944-1.025-8.439zM9.317 17.471a.51.51 0 0 0 .623.446l.281-.055a.465.465 0 0 0 .357-.493l-.262-2.134-1.259.155.26 2.081z"
        })
    );
}
exports.default = SvgSpeaker;