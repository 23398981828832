import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions, withInputValidations } from 'modules/taboola-common-frontend-modules/validations';
import { useEventValueHandler } from '../../../../../../hooks';
import { useProfitMarginFormFieldValue } from './useProfitMarginFormFieldValue';
import styles from '../commonEditor.module.scss';
import commonStyles from '../commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: validationFunctions.isFloatOrEmpty,
        options: { min: 0, max: 99 },
        messageId: 'validations.error.campaign.profitMargin.invalid',
        defaultMessage: 'Profit margin cannot be negative and must be less than 100%',
    },
    {
        validationFn: value => value === null || value === '' || /^[0-9]+(\.[0-9]{1,4})?$/.test(String(value)),
        messageId: 'validations.error.campaign.profitMargin.max.four.digits',
        defaultMessage: 'Profit margin value can have at most 4 digits after the decimal point',
    },
]);

const ProfitMargin = () => {
    const { value: profitMargin, onChange, isPermitted } = useProfitMarginFormFieldValue();
    const changeHandler = useEventValueHandler(onChange);

    if (!isPermitted) {
        return null;
    }
    return (
        <FormField
            inputId="profit-margin"
            label={<FormattedMessage id="campaign.editor.profit.margin.title" defaultMessage="Profit Margin" />}
            description={
                <FormattedMessage
                    id="campaign.editor.profit.margin.description"
                    defaultMessage="Insert profit margin to be reduced out of the campaign's Bid"
                />
            }
            containerClass={styles['input']}
        >
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <InputWithValidations
                        id="profitMargin"
                        onChange={changeHandler}
                        icon="%"
                        inputPadding={inputPadding}
                        placeholder={placeholder}
                        value={profitMargin}
                        type={InputTypes.NUMBER}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="ProfitMargin"
                    />
                )}
            </FormattedNumber>
        </FormField>
    );
};

ProfitMargin.propTypes = {
    currency: PropTypes.string,
};

export default ProfitMargin;
