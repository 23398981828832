import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from '../../../actions';
import { CustomizedSwitchWrapper } from '../../../components/CustomizedSwitchWrapper/CustomizedSwitchWrapper';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ModuleRoute } from '../../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import {
    REPORT_SCHEDULE_ROUTE,
    SCHEDULED_REPORTS_CREATOR_ROUTE_PATH,
    SCHEDULED_REPORTS_EDIT_ROUTE_PATH,
} from '../config';
import { ScheduledReportCreatorPage } from './ScheduledReportCreatorPage';
import { ScheduledReportEditorForm } from './ScheduledReportEditorForm';
import { ScheduledReportsPage } from './ScheduledReportsPage';

const path = REPORT_SCHEDULE_ROUTE;

export const ScheduledReports = ({ history }) => {
    const dispatch = useDispatch();
    const handleNavigation = useCallback(() => {
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        dispatch(
            navigate(history, path, {
                skipFetchReport: true,
            })
        );
    }, [dispatch, history]);

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    return (
        <CustomizedSwitchWrapper>
            <LayerRoute
                strict
                exact
                path={SCHEDULED_REPORTS_CREATOR_ROUTE_PATH}
                render={props => <ScheduledReportCreatorPage {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            {isMultiLayerDrawerEnabled && (
                <LayerRoute
                    exact
                    path={SCHEDULED_REPORTS_EDIT_ROUTE_PATH}
                    render={props => <ScheduledReportEditorForm {...props} />}
                />
            )}
            <ModuleRoute path={path} render={() => <ScheduledReportsPage />} />
        </CustomizedSwitchWrapper>
    );
};

ScheduledReports.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
};
