import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const CTA = {
    NONE: 'NONE',
    DOWNLOAD: 'DOWNLOAD',
    INSTALL_NOW: 'INSTALL_NOW',
    LEARN_MORE: 'LEARN_MORE',
    SHOP_NOW: 'SHOP_NOW',
    CLICK_HERE: 'CLICK_HERE',
    SIGN_UP: 'SIGN_UP',
    PLAY_NOW: 'PLAY_NOW',
    READ_MORE: 'READ_MORE',
    GET_QUOTE: 'GET_QUOTE',
    GET_OFFER: 'GET_OFFER',
    TRY_NOW: 'TRY_NOW',
    SEARCH_NOW: 'SEARCH_NOW',
    BOOK_NOW: 'BOOK_NOW',
    WATCH_NOW: 'WATCH_NOW',
    SWITCH_NOW: 'SWITCH_NOW',
    PRE_ORDER: 'PRE_ORDER',
    APPLY_NOW: 'APPLY_NOW',
    BET_NOW: 'BET_NOW',
    BROWSE_NOW: 'BROWSE_NOW',
    BUY_NOW: 'BUY_NOW',
    CALL_NOW: 'CALL_NOW',
    CAMERA_3D: 'CAMERA_3D',
    COMPARE: 'COMPARE',
    CONTACT_US: 'CONTACT_US',
    DIRECTIONS: 'DIRECTIONS',
    DONATE_NOW: 'DONATE_NOW',
    ENROLL_NOW: 'ENROLL_NOW',
    ENTER_NOW: 'ENTER_NOW',
    EXPLORE: 'EXPLORE',
    FIND_NOW: 'FIND_NOW',
    FOLLOW_NOW: 'FOLLOW_NOW',
    GET_APP: 'GET_APP',
    GET_COUPON: 'GET_COUPON',
    GET_DEALS: 'GET_DEALS',
    GET_INFO: 'GET_INFO',
    GET_NOW: 'GET_NOW',
    GET_RATES: 'GET_RATES',
    GET_RECIPE: 'GET_RECIPE',
    GET_SAMPLE: 'GET_SAMPLE',
    GIFT_NOW: 'GIFT_NOW',
    GO_TO_QUIZ: 'GO_TO_QUIZ',
    GO_TO_TOOL: 'GO_TO_TOOL',
    INSTALL: 'INSTALL',
    JOIN_NOW: 'JOIN_NOW',
    LAUNCH: 'LAUNCH',
    LISTEN_NOW: 'LISTEN_NOW',
    ORDER_NOW: 'ORDER_NOW',
    PLAY_GAME: 'PLAY_GAME',
    READ_NOW: 'READ_NOW',
    RECORD_NOW: 'RECORD_NOW',
    REFER_NOW: 'REFER_NOW',
    REGISTER: 'REGISTER',
    REMIND_ME: 'REMIND_ME',
    RESERVE: 'RESERVE',
    SAVE_NOW: 'SAVE_NOW',
    SAVE_OFFER: 'SAVE_OFFER',
    SELL_NOW: 'SELL_NOW',
    START_NOW: 'START_NOW',
    STREAM_NOW: 'STREAM_NOW',
    SUBSCRIBE: 'SUBSCRIBE',
    TRY_FREE: 'TRY_FREE',
    UPGRADE: 'UPGRADE',
    USE_APP: 'USE_APP',
    VIEW_DEALS: 'VIEW_DEALS',
    VOTE_NOW: 'VOTE_NOW',
    WATCH_MORE: 'WATCH_MORE',
    ENQUIRE_NOW: 'ENQUIRE_NOW',
    CAR_CONFIGURATION: 'CAR_CONFIGURATION',
    TEST_DRIVE: 'TEST_DRIVE',
    VALUE_YOUR_CAR: 'VALUE_YOUR_CAR',
    BUILD_YOURS: 'BUILD_YOURS',
    GET_THE_LEAFLET: 'GET_THE_LEAFLET',
    RECEIVE_A_BROCHURE: 'RECEIVE_A_BROCHURE',
    FIND_A_STORE: 'FIND_A_STORE',
};

const createCtaOption = val => ({
    value: val,
    label: <FormattedMessage id={`creative.creator.content.cta.option.${val}`} defaultMessage={val} />,
});

export const CTA_VALUES = Object.values(CTA);

export const CTA_OPTIONS = CTA_VALUES.map(val => createCtaOption(val));

export const CTA_OPTIONS_NO_NONE = CTA_VALUES.filter(type => type !== CTA.NONE).map(val => createCtaOption(val));
