import React, { useState, useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { SuccessIcon } from 'tuui';
import { CloudinaryImage } from 'taboola-ultimate-ui';
import { useAppInstallFormat } from 'modules/campaigns/modules/creative-creator/hooks/useAppInstallFormat';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PermissionsGuard } from 'modules/taboola-common-frontend-modules/authentication';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FOCUS_TYPES } from '../../../config';
import { ThumbnailEditBanner } from '../Banner';
import styles from './cloudinaryImageWithEditBanner.module.scss';

const classNameBuilder = classnames.bind(styles);

const getImageGravity = creativeFocus => {
    if (!creativeFocus || creativeFocus.type !== FOCUS_TYPES.COORDINATES) {
        return null;
    }

    const {
        coordinates: { x, y },
    } = creativeFocus;
    const gravity = `xy_center,x_${x},y_${y}`;
    return encodeURIComponent(gravity);
};

const CloudinaryImageWithEditBanner = ({
    imageSrc,
    width,
    height,
    isHidden,
    onClickEdit,
    creativeFocus,
    editPermissions,
    isFocalPointEditorEnabled,
    openCropModal,
}) => {
    const [isHovering, setIsHovering] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const startHovering = useCallback(() => setIsHovering(true), [setIsHovering]);
    const stopHovering = useCallback(() => setIsHovering(false), [setIsHovering]);
    const setImageLoaded = useCallback(() => setIsLoaded(true), [setIsLoaded]);
    const { value: cropped } = useFormFieldValue({ field: 'creativeCrop' });
    const isCropped = cropped?.cropData?.length > 0;
    const isCroppingImageEnabled = useConfigMatch({ [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true' });
    const isAppInstallInCreateMode = useAppInstallFormat();
    const { isAppInstall: isAppInstallInEditMode } = useIsAppInstallCreativeInEditMode();
    const isAppInstallFormat = isAppInstallInCreateMode || isAppInstallInEditMode;
    const shouldRenderFocalPointBanner =
        isCroppingImageEnabled && !isAppInstallFormat ? false : isFocalPointEditorEnabled;

    const shouldShowBanner = isLoaded && isHovering;

    const onClickEditCropped = useCallback(
        () => openCropModal({ src: decodeURIComponent(imageSrc) }),
        [openCropModal, imageSrc]
    );

    return (
        <div
            className={classNameBuilder('image-container', { hidden: isHidden })}
            onMouseMove={startHovering}
            onMouseLeave={stopHovering}
        >
            <CloudinaryImage
                src={imageSrc}
                width={width}
                height={height}
                sharpen={false}
                gravity={getImageGravity(creativeFocus)}
                crop="fill"
                onLoad={setImageLoaded}
            />
            {shouldRenderFocalPointBanner && (
                <PermissionsGuard permissions={editPermissions}>
                    <ThumbnailEditBanner
                        className={classNameBuilder('banner', { hidden: !shouldShowBanner })}
                        onClick={onClickEdit}
                        text={<FormattedMessage id="focal.point.label" defaultMessage="Focal Point" />}
                        dataMetrics="Focal Point"
                    />
                </PermissionsGuard>
            )}
            {!shouldRenderFocalPointBanner && (
                <>
                    {isCropped && (
                        <div className={styles['cropped']}>
                            <SuccessIcon />
                            <FormattedMessage
                                id="video.creative.creator.media.upload.file.cropped"
                                defaultMessage="Cropped"
                            />
                        </div>
                    )}
                    <PermissionsGuard permissions={editPermissions}>
                        <ThumbnailEditBanner
                            className={classNameBuilder('banner', { hidden: !shouldShowBanner })}
                            onClick={onClickEditCropped}
                            text={<FormattedMessage id="crop.image.label" defaultMessage="Crop Image" />}
                            dataMetrics="Cropping"
                        />
                    </PermissionsGuard>
                </>
            )}
        </div>
    );
};

CloudinaryImageWithEditBanner.propTypes = {
    imageSrc: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isHidden: PropTypes.bool,
    onClickEdit: PropTypes.func,
    creativeFocus: PropTypes.object,
    editPermissions: permissionsShape,
    isFocalPointEditorEnabled: PropTypes.bool,
};

CloudinaryImageWithEditBanner.defaultProps = {
    isFocalPointEditorEnabled: true,
};

export default CloudinaryImageWithEditBanner;
