import { uniqueId } from 'lodash';
import { CONVERSION_TYPE } from '../config';

const generateUniqueId = () => {
    return uniqueId('condition');
};

const buildConditionFromGW = condition => {
    return {
        predicate: condition?.predicate,
        url: condition?.value,
        id: generateUniqueId(),
    };
};

const buildConditionsArrayFromGW = condition => {
    if (!condition?.children || !condition?.children?.length) {
        return [buildConditionFromGW(condition)];
    }
    if (condition?.children?.length) {
        return condition?.children.map(c => buildConditionFromGW(c));
    }
};

const buildUrlConditionToGW = condition => {
    return {
        property: 'URL',
        predicate: condition.predicate,
        value: condition.url,
        children: [],
    };
};

const buildConditionsWithParentOrToGW = condition => {
    const children = condition.map(condition => buildUrlConditionToGW(condition));
    return {
        predicate: 'OR',
        children,
    };
};

const buildConditionToGW = conversion => {
    if (conversion.type !== CONVERSION_TYPE.BASIC || !conversion.condition || !conversion.condition.length) {
        return {};
    }
    if (conversion.condition.length === 1) {
        return buildUrlConditionToGW(conversion.condition[0]);
    }
    return buildConditionsWithParentOrToGW(conversion.condition);
};

export const conversionConditionsTransformerFromGW = conversion => {
    const condition = buildConditionsArrayFromGW(conversion.condition);
    return {
        ...conversion,
        condition,
    };
};

export const conversionConditionsTransformerToGW = conversion => {
    if (!conversion.condition) {
        return conversion;
    }

    const condition = buildConditionToGW(conversion);
    return {
        ...conversion,
        condition,
    };
};
