"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgNewBadge(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 34,
            height: 33,
            viewBox: "0 0 34 33",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M34.0002 16.2229C34.0002 14.5766 30.2423 13.5387 29.777 12.0713C29.276 10.5323 31.7097 7.49021 30.7791 6.23757C29.8486 4.94914 26.1981 6.30915 24.9096 5.37862C23.6212 4.44809 23.8002 0.547009 22.2612 0.0459533C20.7938 -0.419313 18.6464 2.80176 17.0001 2.80176C15.3538 2.80176 13.2422 -0.419313 11.739 0.0459533C10.2001 0.547009 10.379 4.44809 9.12637 5.37862C7.83794 6.30915 4.1874 4.94914 3.25686 6.23757C2.32633 7.526 4.72424 10.5323 4.25897 12.0713C3.75792 13.5387 0 14.5766 0 16.2229C0 17.8692 3.75792 18.9071 4.22318 20.3745C4.72424 21.9135 2.29054 24.9556 3.22107 26.2082C4.15161 27.4967 7.80215 26.1366 9.09058 27.0672C10.379 27.9977 10.2001 31.8988 11.739 32.364C13.2064 32.8293 15.3538 29.6082 17.0001 29.6082C18.6464 29.6082 20.758 32.8293 22.2612 32.364C23.8002 31.863 23.6212 27.9977 24.9096 27.0672C26.1981 26.1366 29.8486 27.4967 30.7791 26.2082C31.7097 24.9198 29.3118 21.9135 29.777 20.3745C30.2423 18.9071 34.0002 17.8692 34.0002 16.2229Z",
            fill: "#FAC607"
        }),
        React.createElement("path", {
            d: "M12.418 12.2461V19H11.0264L8.31274 14.4727V19H6.92114V12.2461H8.31274L11.031 16.7781V12.2461H12.418ZM18.6606 17.9146V19H15.0657V17.9146H18.6606ZM15.5203 12.2461V19H14.1287V12.2461H15.5203ZM18.1921 14.9968V16.0544H15.0657V14.9968H18.1921ZM18.656 12.2461V13.3362H15.0657V12.2461H18.656ZM21.6006 17.9285L22.9458 12.2461H23.6973L23.8689 13.1924L22.4355 19H21.6284L21.6006 17.9285ZM20.9094 12.2461L22.0227 17.9285L21.9299 19H21.03L19.5317 12.2461H20.9094ZM25.0518 17.9053L26.1511 12.2461H27.5288L26.0352 19H25.1353L25.0518 17.9053ZM24.1194 12.2461L25.4739 17.9517L25.4368 19H24.6296L23.187 13.1877L23.3726 12.2461H24.1194Z",
            fill: "white"
        })
    );
}
exports.default = SvgNewBadge;