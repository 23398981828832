import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { get, noop } from 'lodash';
import { EllipsisIcon } from 'tuui';
import { HeadlessDropdown, pxToRem, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useAvailableItems } from 'hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { CREATIVE_STUDIO_EVENT_PREFIX } from '../CreativeStudio/creativeStudioEventsPrefix';
import { useBaseGalleryItemDropdownTooltip } from './hooks/useBaseGalleryItemDropdownTooltip';
import styles from './baseGalleryItemDropdown.module.scss';

const classNameBuilder = classnames.bind(styles);

const menuStyles = dropdownMenuStyle => ({
    minWidth: 'max-content',
    borderRadius: pxToRem('4px'),
    marginTop: pxToRem('0px'),
    ...dropdownMenuStyle,
});

const customStyles = {
    menuPortal: provided => ({
        ...provided,
        zIndex: 1000,
    }),

    option: base => ({
        ...base,
        color: get(base, 'color', 'inherit') === 'inherit' ? styles.gray : base.color,
    }),
};

export const BaseGalleryItemDropdown = ({
    options,
    onHover = noop,
    onUnhover = noop,
    onDropdownSelect = noop,
    itemUrl,
    dropdownContainerClassName,
    svgContainerClassName,
    dropdownMenuStyle,
    onClick,
    addImageHandler = noop,
    containerClassName,
    campaignId,
    eventsLocationPrefix,
    isImage = true,
    originalItem,
    urlTransformer,
    optionsConditionData,
    tooltipContent: TooltipContent,
    tooltipProps = {},
    // reference to the grid containing this component
    gridRef,
}) => {
    const containerRef = useRef();
    const { mode: formMode } = useFormDataContext();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const onClickValue = { itemUrl, originalItem, isFavorited: true, addImageHandler, urlTransformer, isImage };

    const onChange = ({ value: selectedOption }) => {
        setIsDropdownOpen(false);
        onDropdownSelect();
        onClick(selectedOption, onClickValue);
    };

    const enabledOptions = useAvailableItems(options, {
        formMode,
        isImage,
        ...optionsConditionData,
    });

    // re-use enable condition for react-select isDisabled flag on options
    const finalOptions = useMemo(
        () =>
            enabledOptions.map(({ disabled, ...rest }) => ({
                ...rest,
                isDisabled: disabled,
            })),
        [enabledOptions]
    );

    useLayoutEffect(() => {
        if (!isHovered) {
            setIsDropdownOpen(false);
        }
    }, [isHovered]);

    const { showingTooltip } = useBaseGalleryItemDropdownTooltip({
        containerRef,
        gridRef,
    });

    const onEllipsisClick = () => {
        if (!isDropdownOpen) {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${eventsLocationPrefix}: Ellipsis Clicked`,
                taboolaCampaignId: campaignId,
            });
        }
        setIsDropdownOpen(prev => !prev);
    };

    if (finalOptions.length < 1) {
        return null;
    }

    return (
        <div
            onMouseEnter={e => {
                setIsHovered(true);
                onHover(e);
            }}
            onMouseLeave={e => {
                setIsHovered(false);
                onUnhover(e);
            }}
            className={classNameBuilder('container', containerClassName)}
            ref={containerRef}
        >
            <HeadlessDropdown
                containerClassName={classNameBuilder('dropdown-container', dropdownContainerClassName, {
                    'is-open': isDropdownOpen,
                })}
                onChange={onChange}
                options={finalOptions}
                open={isDropdownOpen}
                onToggleDropdown={onEllipsisClick}
                menuStyles={menuStyles(dropdownMenuStyle)}
                menuPortalTarget={document.body}
                selectedValueObject={null}
                styles={customStyles}
            >
                <div className={classNameBuilder('svg-container', svgContainerClassName)}>
                    {showingTooltip && TooltipContent && (
                        <Tooltip {...tooltipProps}>
                            <TooltipContent
                                onClick={option => onClick(option, onClickValue)}
                                formMode={formMode}
                                value={itemUrl}
                            />
                        </Tooltip>
                    )}
                    <EllipsisIcon
                        width={24}
                        height={24}
                        className={styles['svg']}
                        data-testid="gallery-item-ellipsis-dropdown"
                    />
                </div>
            </HeadlessDropdown>
        </div>
    );
};
