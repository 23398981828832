export const APP_EVENT_TYPE = {
    CLICK: 'CLICK',
    CHANGE: 'CHANGE',
    INPUT: 'INPUT',
    HOVER: 'HOVER',
    INDICATION: 'INDICATION',
    FORM_FIELD_CHANGE: 'FORM_FIELD_CHANGE',
    USER_GET_INACTIVE: 'USER_GET_INACTIVE',
    USER_GET_ACTIVE: 'USER_GET_ACTIVE',
    NAVIGATE: 'NAVIGATE',
    FORM_ACTION: 'FORM_ACTION',
    EDIT_GRID_CELL: 'EDIT_GRID_CELL',
    CHAT_TRIGGERED_ACTION: 'CHAT',
};
