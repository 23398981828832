import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import HeaderLoading from 'modules/campaigns/modules/common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import styles from 'modules/campaigns/modules/common-campaign-form/components/CampaignEditorHeader/campaignEditorHeader.module.scss';
import { NameEdit } from 'modules/campaigns/modules/common-campaign-form/components/Name/NameEdit/NameEdit';
import { COMPONENT_STATUS } from 'services/constants';

const classNameBuilder = classnames.bind(styles);

export const ConversionEditorHeader = ({ status, headerClassName }) => {
    return status === COMPONENT_STATUS.LOADING ? (
        <HeaderLoading />
    ) : (
        <div className={classNameBuilder('section-container', headerClassName)}>
            <NameEdit field="conversionName" />
        </div>
    );
};

ConversionEditorHeader.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
};

ConversionEditorHeader.defaultProps = {
    collapsed: false,
};
