import React from 'react';
import { CommonCollapsibleCard } from 'components';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import AttributionSettings from './AttributionSettings';

export const Attribution = () => {
    return (
        <CommonCollapsibleCard
            id="Attribution"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="tracking.settings.attribution" defaultMessage="Attribution" />}
                />
            }
        >
            <AttributionSettings />
        </CommonCollapsibleCard>
    );
};
