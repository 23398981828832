import { cloneDeep, isNil, keys, unset, get, keyBy } from 'lodash';
import { GLOBAL_PRESET_ACCOUNT_ID } from '../../../campaigns/modules/campaigns-reports/components/ReportPresetPicker/constants';
import { gtmTracker } from '../../gtmTracker';
import { immutableMergeWith } from '../../utils/customMergers';
import { transformUserSettingsToGW } from './userSettingsTransformers';

export const BATCH_UPDATE_TIMEOUT = 500;
// These two are outside the getBatchDBSettingCaller to allow multi called instances with useUserSettingsApi work
// with one cache, possibly will be removed once Query-DB proxy added
let cache;
let timeoutId;

export const getBatchDBSettingCaller = ({
    apiCaller,
    collector = immutableMergeWith,
    timeout = BATCH_UPDATE_TIMEOUT,
}) => {
    const sendRequest = async () => {
        if (!cache) {
            return;
        }
        const { resolve, reject, data, accountId } = cache;
        cache = null;

        try {
            const response = await apiCaller(accountId, transformUserSettingsToGW(data));
            resolve(response);
        } catch (error) {
            reject(error);
        }
    };

    const batchedApiCaller = (accountId, data) => {
        if (!cache) {
            cache = { accountId };
            cache.promise = new Promise((resolve, reject) => {
                cache.resolve = resolve;
                cache.reject = reject;
            });
        }

        cache.data = collector(cache?.data || {}, data);

        clearTimeout(timeoutId);
        timeoutId = setTimeout(sendRequest, timeout);

        return cache.promise;
    };

    return batchedApiCaller;
};

export const omitAccountDependentData = data => {
    const newData = cloneDeep(data);

    unset(newData, 'accountId');

    const reportKeys = keys(newData?.reports);
    reportKeys.forEach(reportKey => {
        const presetKeys = keys(newData.reports[reportKey].presets);

        presetKeys.forEach(presetKey => {
            const key = `reports.${reportKey}.presets.${presetKey}`;
            const preset = get(newData, key);
            if (!isNil(preset.accountId) && preset.accountId !== GLOBAL_PRESET_ACCOUNT_ID) {
                unset(newData, key);
            }
        });
    });

    return newData;
};

export const isEmptyAccountId = accountId => accountId === '' || isNil(accountId);

const gridStateRegex = /^reports\.creative\.presets\.Custom[_\d\D]+gridColumnState$/;
const columnList = ['creative_title', 'creative_content'];

export const catchHiddenRequiredColumns = (data, key, value) => {
    if (!gridStateRegex.test(key)) {
        return;
    }

    const currentGriColumnState = keyBy(get(data, key), 'colId');
    const newGriColumnState = keyBy(value, 'colId');

    for (let colId of columnList) {
        const propPath = `${colId}.hide`;
        if (get(currentGriColumnState, propPath) === false && get(newGriColumnState, propPath) === true) {
            gtmTracker.trackError(new Error(`Hidden required column creative title`));
            break;
        }
    }
};
