import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useBudgetFormFieldValue } from '../../../../common-campaign-form/components/Budget/hooks/useBudgetFormFieldValue';

export const useSharedBudgetCampaignsResources = () => {
    const {
        value: { type: budgetType, value: groupBudget },
    } = useBudgetFormFieldValue();
    const { value: marketingObjective } = useFormFieldValue({
        field: 'marketingObjective',
    });

    return {
        marketingObjective,
        budgetType,
        groupBudget: groupBudget ? parseFloat(groupBudget) : null,
    };
};
