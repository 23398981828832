'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DefaultLoadingCellRenderer = exports.withLoadingCellRenderer = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactIs = require('react-is');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _dataGridUtils = require('../dataGridUtils');

var _SimpleCellRenderer = require('./SimpleCellRenderer');

var _SimpleCellRenderer2 = _interopRequireDefault(_SimpleCellRenderer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DefaultLoadingCellRenderer = function DefaultLoadingCellRenderer() {
    return _react2.default.createElement(
        'span',
        null,
        'loading'
    );
};
var EmptyCellRenderer = function EmptyCellRenderer() {
    return null;
};

var withLoadingCellRenderer = function withLoadingCellRenderer() {
    var CellRenderer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _SimpleCellRenderer2.default;

    var CellRendererWithLoading = function (_Component) {
        _inherits(CellRendererWithLoading, _Component);

        function CellRendererWithLoading(props) {
            _classCallCheck(this, CellRendererWithLoading);

            var _this = _possibleConstructorReturn(this, (CellRendererWithLoading.__proto__ || Object.getPrototypeOf(CellRendererWithLoading)).call(this, props));

            _this.childRef = _react2.default.createRef();
            return _this;
        }

        _createClass(CellRendererWithLoading, [{
            key: 'refresh',
            value: function refresh() {
                if (this.childRef.current && this.childRef.current.refresh) {
                    return this.childRef.current.refresh();
                }
                return false;
            }
        }, {
            key: 'render',
            value: function render() {
                var _props = this.props,
                    rowsLoading = _props.context.rowsLoading,
                    data = _props.data;

                var ref = (0, _reactIs.isForwardRef)(_react2.default.createElement(CellRenderer, this.props)) ? this.childRef : undefined;

                return rowsLoading || (0, _dataGridUtils.isRowMockData)(data) ? _react2.default.createElement(EmptyCellRenderer, null) : _react2.default.createElement(CellRenderer, Object.assign({}, this.props, { ref: ref }));
            }
        }]);

        return CellRendererWithLoading;
    }(_react.Component);

    CellRendererWithLoading.propTypes = {
        context: _propTypes2.default.object,
        data: _propTypes2.default.object
    };

    CellRendererWithLoading.defaultProps = {
        context: {}
    };

    return CellRendererWithLoading;
};

exports.withLoadingCellRenderer = withLoadingCellRenderer;
exports.DefaultLoadingCellRenderer = DefaultLoadingCellRenderer;