'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ArrowIcon = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Dropdown = require('../../Icons/Icons/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'gray6': 'var(--gray-6)',
    'taboola-svg-icon': 'arrowIcon__taboola-svg-icon___p2sNJ'
};
var ArrowIcon = exports.ArrowIcon = function ArrowIcon(_ref) {
    var className = _ref.className;
    return _react2.default.createElement(_Dropdown2.default, { color: styles.gray6, className: className });
};

ArrowIcon.propTypes = {
    className: _propTypes2.default.string
};

exports.default = ArrowIcon;