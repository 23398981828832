'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var RADIO_SIZE = {
    NORMAL: 'normal',
    SMALL: 'small'
};

exports.default = RADIO_SIZE;