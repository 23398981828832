import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    StyledButton,
    STYLED_BUTTON_SIZE,
    PlusIcon,
    TooltipV2 as Tooltip,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addFilterButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AddFilterButton = ({ disabled, ...restProps }) => (
    <div>
        <StyledButton
            className={classNameBuilder('button', { disabled })}
            size={STYLED_BUTTON_SIZE.MEDIUM}
            iconBefore={<PlusIcon className={styles['icon']} />}
            disabled={disabled}
            {...restProps}
        />
        {disabled ? (
            <Tooltip arrow position={TOOLTIP_POSITION.BOTTOM_START}>
                <FormattedMessage
                    id="app.campaigns.reports.filters.toolbar.add.disabled"
                    defaultMessage="There are currently no additional filters to present"
                />
            </Tooltip>
        ) : null}
    </div>
);

AddFilterButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    ...StyledButton.propTypes,
};

AddFilterButton.defaultProps = {
    onClick: () => {},
};
