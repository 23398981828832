import { ACCOUNT_ID, DIMENSION } from 'hooks';
import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { CAMPAIGNS_ROUTE } from 'modules/campaigns/config/routes';
import { CAMPAIGN_ID } from 'modules/campaigns/hooks';
import {
    ActionableTextWithInternalLinkCellRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
} from 'modules/campaigns/modules/campaigns-reports/components';
import { keyColumn, leftPinnedColumn, numericColumn } from 'modules/campaigns/modules/campaigns-reports/config/columns';
import { nonDefaultHeaderComponentParams } from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { DATE_PRESET, END_DATE, REPORT_ID, START_DATE } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';

const hierarchyCampaignReportColumnDefinition = [
    {
        field: FIELDS.ID.field,
        headerName: FIELDS.ID.label,
        headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.HIERARCHY_CAMPAIGN),
        type: [numericColumn],
        headerValueGetter: FormattedMessageHeaderRenderer,
    },
    {
        field: FIELDS.NAME.field,
        headerName: FIELDS.NAME.label,
        headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.HIERARCHY_CAMPAIGN),
        type: [leftPinnedColumn, keyColumn],
        headerValueGetter: FormattedMessageHeaderRenderer,
        editable: false,
        cellRendererFramework: ActionableTextWithInternalLinkCellRenderer,
        cellRendererParams: {
            lines: 2,
            to: CAMPAIGNS_ROUTE,
            paramsToKeep: [ACCOUNT_ID, DIMENSION, START_DATE, END_DATE, DATE_PRESET],
            paramsFactory: ({ id }) => ({
                [CAMPAIGN_ID]: id,
                [REPORT_ID]: REPORT_TYPE.CREATIVE,
            }),
            isSearchable: true,
        },
    },
    ...[
        FIELDS.PROFIT_MARGIN,
        FIELDS.ESTIMATED_BILLABLE_AMOUNT,
        FIELDS.CLICKS,
        FIELDS.IMPRESSIONS,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.CPM,
        FIELDS.VCPM,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.ROAS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        accountConfigurations: fieldConfig.accountConfigurations,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    hierarchyCampaignReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.HIERARCHY_CAMPAIGN].id
);
