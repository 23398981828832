'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _CollapsibleSideBar = require('./CollapsibleSideBar');

var _CollapsibleSideBar2 = _interopRequireDefault(_CollapsibleSideBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _CollapsibleSideBar2.default;