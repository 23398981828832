import React, { Fragment, useState, useMemo, useCallback } from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TARGETING_TYPES } from '../../config';
import {
    AddMarketplaceAudiencesTargetingButton,
    MarketplaceAudiencesTargetingDelimiter,
} from '../MarketplaceAudiences/MarketplaceAudiencesTargeting';
import {
    useMarketplaceAudienceSegmentTypeCounts,
    useMarketplaceAudienceTargetingCollection,
    useIsMarketplaceAudiencesAndLogicEnabled,
} from '../MarketplaceAudiences/hooks';
import { useSegmentTypeLimits } from '../Tree/hooks/useSegmentTypeLimits';
import { MarketplaceAudiencesTargetingGroupWithBundling } from './MarketplaceAudiencesTargetingGroupWithBundling/MarketplaceAudiencesTargetingGroupWithBundling';
import { MarketplaceAudiencesTargetingWithBundling } from './MarketplaceAudiencesTargetingWithBundling';
import styles from './marketplaceAudiencesWithBundling.module.scss';

export const MarketplaceAudiencesWithBundling = ({ selectedAccountId }) => {
    const { marketplaceAudienceTargetingCollection, onAddTargeting, onDeleteTargeting } =
        useMarketplaceAudienceTargetingCollection();
    const handleAddTargeting = useCallback(() => onAddTargeting(TARGETING_TYPES.INCLUDE), [onAddTargeting]);
    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();

    const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
    const isAdvancedSearchEnabled = useConfigMatch({
        [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_ADVANCED_SEARCH_ENABLED]: 'true',
    });

    const { maxTargetings } = useSegmentTypeLimits();

    const { setMarketplaceAudienceSegmentTypeByIndex, marketplaceAudienceSegmentTypeCounts } =
        useMarketplaceAudienceSegmentTypeCounts(marketplaceAudienceTargetingCollection);

    const hasExcludeTargeting = useMemo(
        () => marketplaceAudienceTargetingCollection.some(targeting => targeting.type === TARGETING_TYPES.EXCLUDE),
        [marketplaceAudienceTargetingCollection]
    );

    return (
        <div className={styles['container']}>
            {isAdvancedSearchEnabled && (
                <TextCheckbox
                    labelClassName={styles['checkbox']}
                    title={
                        <FormattedMessage
                            id="campaign.editor.marketplace.audiences.advanced.search"
                            defaultMessage="Advanced Search"
                        />
                    }
                    selectedValues={[true]}
                    value={isAdvancedSearch}
                    onSelectCheckbox={() => setIsAdvancedSearch(true)}
                    onUnselectCheckbox={() => setIsAdvancedSearch(false)}
                    independent
                    helpText={
                        <FormattedMessage
                            id="advanced.search.tooltip"
                            defaultMessage="Advanced search will return specific audience segments as well as audience bundles."
                        />
                    }
                />
            )}
            {isAndLogicEnabled ? (
                <>
                    {marketplaceAudienceTargetingCollection.map((targeting, index) => {
                        const { id } = targeting;
                        const handleDeleteTargetingByIndex = () => onDeleteTargeting(index);
                        const key = isNil(id) ? index : id;

                        return (
                            <Fragment key={key}>
                                {index > 0 && (
                                    <MarketplaceAudiencesTargetingDelimiter onDelete={handleDeleteTargetingByIndex} />
                                )}
                                <MarketplaceAudiencesTargetingGroupWithBundling
                                    selectedAccountId={selectedAccountId}
                                    marketplaceAudienceSegmentTypeCount={marketplaceAudienceSegmentTypeCounts[index]}
                                    setMarketplaceAudienceSegmentTypeByIndex={setMarketplaceAudienceSegmentTypeByIndex}
                                    index={index}
                                    isAdvancedSearch={isAdvancedSearch}
                                    shouldDisableExcludeType={hasExcludeTargeting}
                                />
                            </Fragment>
                        );
                    })}
                    {marketplaceAudienceTargetingCollection.length < maxTargetings && (
                        <AddMarketplaceAudiencesTargetingButton onClick={handleAddTargeting} />
                    )}
                </>
            ) : (
                <MarketplaceAudiencesTargetingWithBundling
                    selectedAccountId={selectedAccountId}
                    isAdvancedSearch={isAdvancedSearch}
                />
            )}
        </div>
    );
};

MarketplaceAudiencesWithBundling.propTypes = {
    selectedAccountId: PropTypes.string,
};
