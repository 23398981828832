import React from 'react';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './creditNumberWithExp.module.scss';

const CreditNumberWithExp = ({ fourLastDigits, expirationMonth, expirationYear }) => {
    const monthString = String(expirationMonth).padStart(2, '0');
    return (
        <div>
            <span>{`••••${fourLastDigits}`}</span>
            <span className={styles['exp']}>
                <FormattedMessage id="billingAndPayments.creditCard.expiration.label" defaultMessage="Exp" />
            </span>
            <span>{`${monthString}/${expirationYear}`}</span>
        </div>
    );
};

export default CreditNumberWithExp;
