import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

const emptyColumns = [
    FIELDS.IS_SELECTED,
    FIELDS.IS_ACTIVE,
    FIELDS.ID,
    FIELDS.NAME,
    FIELDS.BUDGET,
    FIELDS.BID,
    FIELDS.STATUS,
    FIELDS.DEMAND_TYPE,
    FIELDS.START_DATE,
    FIELDS.END_DATE,
    FIELDS.BID_STRATEGY,
    FIELDS.SAFETY_LEVEL,
    FIELDS.ACCOUNT_ID,
    FIELDS.ACCOUNT_DESCRIPTION,
    FIELDS.CAMPAIGNS_GROUP_NAME,
    FIELDS.SPENDING_LIMIT,
    FIELDS.CPA_GOAL,
    FIELDS.CAMPAIGN_CONVERSION_RULES,
    FIELDS.ACTIONS_COLUMN,
].map(createFooterMapper(REPORT_TYPE.CAMPAIGN_REALTIME));

export const campaignRealtimeReportFooterColumnDefinition = [...emptyColumns];
