import { useQuery } from 'react-query';
import { useSelectedAccount } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { useTrackingApi } from 'services/api';

const emptyArray = [];
const defaultResponse = { accounts: emptyArray };

export const useGtmAccountDropdown = () => {
    const { getGTMAccounts } = useTrackingApi();
    const [{ accountId }] = useSelectedAccount();
    const { getToken, isTokenValid, exponentialBackoff, staleTime } = useGoogleApi();

    const { value, onChange } = useFormFieldValue({ field: 'gtmAccount' });

    const { data = defaultResponse, isLoading } = useQuery(
        ['getGTMAccounts', accountId, isTokenValid],
        () => getGTMAccounts(getToken()?.access_token, accountId),
        {
            enabled: isTokenValid,
            retryDelay: exponentialBackoff,
            staleTime,
        }
    );

    const { accounts } = data;
    const options = accounts?.map(gtmAccount => ({ value: gtmAccount.id, label: gtmAccount.name }));

    return {
        value,
        options,
        isLoading,
        onChange,
    };
};
