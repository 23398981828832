import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { DropdownLargeIcon, HeadlessDropdown, pxToRem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isPathActive } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { useDropdownOptions } from '../../../hooks';
import OptionWrapper from '../../AdminDropdown/OptionWrapper';
import styles from './NavigationDropdownItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const menuStyles = {
    marginTop: pxToRem(styles['dropdown-marginTop']),
    color: styles['dropdown-color'],
    boxShadow: styles['dropdown-box-shadow'],
};

const navItemsToOptionsTransformer = navItems =>
    navItems.map(({ permissions, id, basePath = '', caption }) => ({
        permissions,
        href: `${basePath}/${id}`,
        value: caption?.id ?? id,
        label: caption?.default ?? id,
    }));

const getActiveItem = (navItems, location) =>
    navItems.find(({ basePath = '', id }) => {
        const destinationPath = `${basePath}/${id}`;
        return isPathActive(location.pathname, destinationPath);
    });

const NavigationDropdownItem = ({ navItems, activeClassName, itemsClassName, iconClass }) => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(navItems[0]);
    const options = useDropdownOptions(navItems, 'navigation.caption', navItemsToOptionsTransformer);

    const activeItem = useMemo(() => getActiveItem(navItems, location), [navItems, location]);
    const isActive = Boolean(activeItem);
    const className = classNameBuilder(
        'container',
        itemsClassName,
        { active: isActive },
        isActive ? activeClassName : null
    );
    const selectedItemClassName = classNameBuilder(
        'selected-item',
        { active: isActive },
        isActive ? activeClassName : null
    );

    const handleToggleDropdown = useCallback(() => setOpen(prevOpen => !prevOpen), []);

    const handleDropdownChange = useCallback(
        ({ value }) => {
            const newSelectedItem = navItems.find(navItem => navItem.id === value);
            if (newSelectedItem) {
                setSelectedItem(newSelectedItem);
            }
        },
        [navItems]
    );

    useEffect(() => {
        if (activeItem) {
            setSelectedItem(activeItem);
        }
    }, [activeItem]);

    return (
        <div className={className}>
            <HeadlessDropdown
                width={styles['dropdown-width']}
                menuStyles={menuStyles}
                className={styles['dropdown']}
                options={options}
                onChange={handleDropdownChange}
                optionWrapper={OptionWrapper}
                value={{}}
                open={open}
                onToggleDropdown={handleToggleDropdown}
            >
                <div className={selectedItemClassName}>
                    <FormattedMessage id={selectedItem.caption.id} defaultMessage={selectedItem.caption.default} />
                    <DropdownLargeIcon className={classNameBuilder(styles['icon'], iconClass)} />
                </div>
            </HeadlessDropdown>
        </div>
    );
};

NavigationDropdownItem.propTypes = {
    navItems: PropTypes.array,
    activeClassName: PropTypes.string,
    itemsClassName: PropTypes.string,
    iconClass: PropTypes.string,
};

export default NavigationDropdownItem;
