import { get, keyBy, map, trim } from 'lodash';
import { nonProgrammaticActiveAccount } from 'modules/account-management/accountType';
import BRAND_SAFETY_TYPES from '../../common-campaign-form/config/BrandSafeTypes';
import BRAND_SAFETY_RISK_LEVEL from '../../common-campaign-form/config/BrandSafetyRiskLevel';
import { getBrandSafetySegmentExcelLabel } from '../utils/excelBulkWorkbookUtils';

export const transformResponseResults = (data, dataPath) => ({ [dataPath]: get(data, 'results', []) });

export const transformResponseNames = (data, dataPath) => ({
    [dataPath]: data.map(item => item.name),
});

export const transformOSResponse = (osData, dataPath) => ({
    [dataPath]: osData?.operatingSystemTargetings,
    osLabelMap: keyBy(osData?.operatingSystemTargetings, 'subCategory.label'),
});

export const transformConversionRulesResponse = (conversionRulesData, dataPath) => {
    const { results: conversionRulesResults } = conversionRulesData;
    const mappedResults = map(conversionRulesResults, 'apiUnipRuleConfiguration');
    const result = { [dataPath]: mappedResults, conversionRulesMap: keyBy(mappedResults, 'displayName') };
    return result;
};

export const transformCampaignGroupsResponse = (campaignGroupsData, dataPath) => {
    const { results: campaignGroupsResults } = campaignGroupsData;
    const trimmedCampaignGroupsResults = map(campaignGroupsResults, ({ name, ...rest }) => ({
        ...rest,
        name: trim(name),
    }));

    const result = {
        [dataPath]: trimmedCampaignGroupsResults,
        campaignGroupsMapByID: keyBy(trimmedCampaignGroupsResults, 'id'),
        campaignGroupsMapByName: keyBy(trimmedCampaignGroupsResults, 'name'),
    };

    return result;
};

const RISK_LEVELS_BY_BRAND_SAFETY_TYPE = {
    [BRAND_SAFETY_TYPES.DV]: [
        BRAND_SAFETY_RISK_LEVEL.HIGH,
        BRAND_SAFETY_RISK_LEVEL.MEDIUM,
        BRAND_SAFETY_RISK_LEVEL.LOW,
    ],
    [BRAND_SAFETY_TYPES.IAS]: [BRAND_SAFETY_RISK_LEVEL.HIGH, BRAND_SAFETY_RISK_LEVEL.MEDIUM],
};

const transformThirdPartyBrandSafetyResponse = (data, brandSafetyType) => {
    const thirdPartyBrandSafetyLabelToItemMap = {};

    data.forEach(item => {
        const { value, hasRiskLevel } = item;

        if (!hasRiskLevel) {
            thirdPartyBrandSafetyLabelToItemMap[value] = {
                categoryName: value,
                riskLevel: BRAND_SAFETY_RISK_LEVEL.HIGH,
            };
            return;
        }

        for (const riskLevel of RISK_LEVELS_BY_BRAND_SAFETY_TYPE[brandSafetyType]) {
            const labelWithRisk = getBrandSafetySegmentExcelLabel(value, riskLevel);
            thirdPartyBrandSafetyLabelToItemMap[labelWithRisk] = { categoryName: value, riskLevel };
        }
    });

    return {
        result: Object.keys(thirdPartyBrandSafetyLabelToItemMap),
        thirdPartyBrandSafetyLabelToItemMap,
        thirdPartyBrandSafetyValueToDataMap: keyBy(data, 'value'),
    };
};

export const transformThirdPartyDVBrandSafetyResponse = (data, dataPath) => {
    const { result, thirdPartyBrandSafetyLabelToItemMap, thirdPartyBrandSafetyValueToDataMap } =
        transformThirdPartyBrandSafetyResponse(data, BRAND_SAFETY_TYPES.DV);

    return {
        [dataPath]: result,
        thirdPartyBrandSafetyLabelToItemMapDV: thirdPartyBrandSafetyLabelToItemMap,
        thirdPartyBrandSafetyValueToDataMapDV: thirdPartyBrandSafetyValueToDataMap,
    };
};

export const transformThirdPartyIASBrandSafetyResponse = (data, dataPath) => {
    const { result, thirdPartyBrandSafetyLabelToItemMap, thirdPartyBrandSafetyValueToDataMap } =
        transformThirdPartyBrandSafetyResponse(data, BRAND_SAFETY_TYPES.IAS);

    return {
        [dataPath]: result,
        thirdPartyBrandSafetyLabelToItemMapIAS: thirdPartyBrandSafetyLabelToItemMap,
        thirdPartyBrandSafetyValueToDataMapIAS: thirdPartyBrandSafetyValueToDataMap,
    };
};

export const transformAccountsResponse = (data, dataPath) => {
    const filteredAccount = get(data, 'results', []).filter(account => nonProgrammaticActiveAccount(account));
    return { [dataPath]: filteredAccount };
};
