import { HOURS_OF_DAY } from '../../../campaigns/modules/common-campaign-form/config';

const hoursToSkip = [
    HOURS_OF_DAY.ZERO,
    HOURS_OF_DAY.ONE,
    HOURS_OF_DAY.TWO,
    HOURS_OF_DAY.THREE,
    HOURS_OF_DAY.FOUR,
    HOURS_OF_DAY.FIVE,
    HOURS_OF_DAY.TWENTY_FOUR,
];

const deliveryTimeOptions = Object.values(HOURS_OF_DAY)
    .filter(hour => !hoursToSkip.includes(hour))
    .map(hour => ({
        label: hour,
        value: hour,
    }));

export const buildDeliveryTimeOptions = () => deliveryTimeOptions;
