import { AttentiveAudienceNotification } from '../NotificationCard/components/AttentiveAudienceNotification/AttentiveAudienceNotification';
import { BudgetReachedDepletionNotification } from '../NotificationCard/components/BudgetReachedDepletionNotificationCard/BudgetReachedDepletionNotification';
import { CampaignSegmentExplorationEndedNotification } from '../NotificationCard/components/CampaignSegmentExplorationEndedNotification/CampaignSegmentExplorationEndedNotification';
import { CvrLearningStatusNotification } from '../NotificationCard/components/CvrLearningStatusNotification/CvrLearningStatusNotification';
import { InaccessibleImageNotification } from '../NotificationCard/components/InaccessibleImageNotification/InaccessibleImageNotification';
import { ItemWithoutCTANotification } from '../NotificationCard/components/ItemWithoutCTANotification/ItemWithoutCTANotification';
import { NoBasicTrackingNotification } from '../NotificationCard/components/NoBasicTrackingNotification/NoBasicTrackingNotification';
import { PaidPrepaymentsNotification } from '../NotificationCard/components/PaidPrepaymentsNotification/PaidPrepaymentsNotification';
import { RetargetingAudienceNotification } from '../NotificationCard/components/RetargetingAudienceNotification/RetargetingAudienceNotification';
import { UnblockSitesNotification } from '../NotificationCard/components/UnblockSitesNotification/UnblockSitesNotification';
import { UnrealisticTargetNotification } from '../NotificationCard/components/UnrealisticTargetNotification/UnrealisticTargetNotification';
import { NOTIFICATION_SUB_TYPES } from './NotificationSubTypes';

export const NotificationCardsBySubType = {
    [NOTIFICATION_SUB_TYPES.NO_BASIC_TRACKING]: NoBasicTrackingNotification,
    [NOTIFICATION_SUB_TYPES.BUDGET_REACHED_DEPLETION_RECOMMENDATION]: BudgetReachedDepletionNotification,
    [NOTIFICATION_SUB_TYPES.INACCESSIBLE_IMAGES]: InaccessibleImageNotification,
    [NOTIFICATION_SUB_TYPES.RETARGET_AUDIENCE_RECOMMENDATION]: RetargetingAudienceNotification,
    [NOTIFICATION_SUB_TYPES.ATTENTIVE_AUDIENCE_RECOMMENDATION]: AttentiveAudienceNotification,
    [NOTIFICATION_SUB_TYPES.ITEM_WITHOUT_CTA_RECOMMENDATION]: ItemWithoutCTANotification,
    [NOTIFICATION_SUB_TYPES.UNBLOCK_SITES_RECOMMENDATION]: UnblockSitesNotification,
    [NOTIFICATION_SUB_TYPES.CVR_LEARNING_STATUS_RECOMMENDATION]: CvrLearningStatusNotification,
    [NOTIFICATION_SUB_TYPES.CAMPAIGN_SEGMENT_EXPLORATION_ENDED]: CampaignSegmentExplorationEndedNotification,
    [NOTIFICATION_SUB_TYPES.PAID_PREPAYMENTS]: PaidPrepaymentsNotification,
    [NOTIFICATION_SUB_TYPES.UNREALISTIC_TCPA_RECOMMENDATION]: UnrealisticTargetNotification,
};
