import React, { useCallback } from 'react';
import { ImageContentLoader } from 'taboola-ultimate-ui';
import { GalleryItem } from '../../ImageGallery/GalleryItem';
import { ImageVariationsItemDropdown } from './ImageVariationsItemDropdown';

export const ImageVariationsGalleryItem = ({ image, onGenerateVariations, isLoading, ...rest }) => {
    const onGenerate = useCallback(() => {
        onGenerateVariations(image);
    }, [image, onGenerateVariations]);

    const GalleryItemDropdown = useCallback(
        props => <ImageVariationsItemDropdown onGenerateVariations={onGenerate} {...props} />,
        [onGenerate]
    );

    return (
        <GalleryItem
            {...rest}
            image={image}
            GalleryItemDropdown={isLoading ? undefined : GalleryItemDropdown}
            loadingComponent={<ImageContentLoader width={1200} height={675} />}
        />
    );
};
