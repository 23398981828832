'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _constants = require('../constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dashboardRangePickerDate__taboola-svg-icon___2JMxD',
    'chosen-date-container': 'dashboardRangePickerDate__chosen-date-container___1Udxz',
    'chosen-date': 'dashboardRangePickerDate__chosen-date___17jze',
    'chosen-date-inner-container': 'dashboardRangePickerDate__chosen-date-inner-container___1Fbge',
    'chosen-month-year': 'dashboardRangePickerDate__chosen-month-year____ZFzo',
    'chosen-day': 'dashboardRangePickerDate__chosen-day___3ZHdw'
};


var DashboardRangePickerDate = function DashboardRangePickerDate(_ref) {
    var date = _ref.date;
    return _react2.default.createElement(
        'div',
        { className: styles['chosen-date-container'] },
        _react2.default.createElement(
            'span',
            { className: styles['chosen-date'] },
            (0, _moment2.default)(date).format(_constants.DAY_OF_MONTH_FORMAT)
        ),
        _react2.default.createElement(
            'div',
            { className: styles['chosen-date-inner-container'] },
            _react2.default.createElement(
                'span',
                { className: styles['chosen-month-year'] },
                (0, _moment2.default)(date).format(_constants.MONTH_YEAR_FORMAT)
            ),
            _react2.default.createElement(
                'span',
                { className: styles['chosen-day'] },
                (0, _moment2.default)(date).format(_constants.FULL_WEEKDAY_FORMAT)
            )
        )
    );
};

DashboardRangePickerDate.propTypes = {
    date: _reactMomentProptypes2.default.momentObj
};

exports.default = DashboardRangePickerDate;