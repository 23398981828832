import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const unipFunnelsTrackingReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.FUNNELS],
    endpoint: 'funnels',
    rowIdField: FIELDS.FUNNEL_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    searchPlaceholderMsgId: 'tracking.funnels.report.search.placeholder',
    searchField: FIELDS.FUNNEL_NAME.field,
    filters: [],
    initialFilters: [],
};
