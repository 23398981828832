'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BaseCheckbox = require('./BaseCheckbox');

var _BaseCheckbox2 = _interopRequireDefault(_BaseCheckbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _BaseCheckbox2.default;