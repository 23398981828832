import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.DCO_ITEM_PERFORMANCE].id;

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.SPENT.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.CLICKS.field,
        FIELDS.VCTR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.CVR.field,
        FIELDS.CPA.field,
        FIELDS.VCPM.field,
    ].map(field => generateColumnId(reportId, field)),
    [REPORT_PRESET.SETUP]: [
        FIELDS.ITEM_ID.field,
        FIELDS.TITLE.field,
        FIELDS.THUMBNAIL_URL.field,
        FIELDS.URL.field,
        FIELDS.CAMPAIGN_NAME.field,
    ].map(field => generateColumnId(reportId, field)),
};

const defaultColumns = [
    FIELDS.ITEM_ID.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.TITLE.field,
    FIELDS.URL.field,
    FIELDS.CAMPAIGN_NAME.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.VCTR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.ITEM_ID.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.VCTR.field,
    FIELDS.CLICKS.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
].map(field => generateColumnId(reportId, field));

const setupColumns = [
    FIELDS.ITEM_ID.field,
    FIELDS.TITLE.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.URL.field,
    FIELDS.CAMPAIGN_NAME.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns, performanceColumns, setupColumns };
