import React from 'react';
import PropTypes from 'prop-types';
import useSegmentListItemValidations from '../hooks/useSegmentListItemValidations';
import SegmentListItemContent from './SegmentListItemContent';

const SegmentListItemContentWithValidationsWrapper = ({
    item,
    field,
    enabledProvidersMap,
    isProvidersReady,
    ...rest
}) => {
    const { message, indicationType } = useSegmentListItemValidations({
        item,
        field,
        enabledProvidersMap,
        isProvidersReady,
    });

    return (
        <SegmentListItemContent
            id="marketplace-audiences-item"
            item={item}
            message={message}
            indicationType={indicationType}
            {...rest}
        />
    );
};

SegmentListItemContentWithValidationsWrapper.propTypes = {
    campaign: PropTypes.object,
    isProviderEnabled: PropTypes.func,
    ...SegmentListItemContent.propTypes,
};

export default SegmentListItemContentWithValidationsWrapper;
