import { useSelectedAccount } from 'hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { getIsSupplyTargetingSelectPermitted } from '../utils/supplyTargetingUtils';

export const useIsSupplyTargetingSelectPermitted = () => {
    const isTaboolaSelectEnabled = useConfigMatch({ [FEATURE_FLAGS.TABOOLA_SELECT_ENABLED]: 'true' });
    const isTaboolaSelectForPMPEnabled = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_TARGETING_PMP_ENABLED]: 'true' });
    const isTaboolaSelectForVideoEnabled = useConfigMatch({
        [FEATURE_FLAGS.TABOOLA_SELECT_ENABLED_FOR_VIDEO_ACCOUNT]: 'true',
    });
    const [selectedAccount] = useSelectedAccount();

    return getIsSupplyTargetingSelectPermitted({
        selectedAccount,
        isTaboolaSelectEnabled,
        isTaboolaSelectForPMPEnabled,
        isTaboolaSelectForVideoEnabled,
    });
};
