import { isNil } from 'lodash';
import uuid from 'uuid/v1';
import { getCachedUserId } from 'hooks/queryParams/userIdCache';
import { getQueryParam } from 'modules/taboola-common-frontend-modules/query-params/queryParamUtils';
import { getItem, saveItem } from 'services/persister/persister';
import { valueStoreFactory } from '../../../../services/utils/valueStoreFactory';
import { MESSAGE_TYPE } from '../../iframe/messageType';
import { isMessageBasedAuth } from '../../iframe/utils/isMessageBasedAuth';
import { notifyIfNotReceivedToken } from '../../iframe/utils/notifyIfNotReceivedToken';
import config from '../config';

export const wentToAuth = valueStoreFactory();
export const REDIRECT_URLS = 'redirectUrls';
export const REDIRECT_URI_HASH = 'redirect_uri_hash';
export const HOUR_IN_MILLIS = 8.64e7;

export const getAndClearRedirectUrlFromLocalStorage = () => {
    let locationObj = window.location;
    if (!locationObj.search.includes(REDIRECT_URI_HASH)) {
        return locationObj;
    }
    const hash = getQueryParam(locationObj.search, REDIRECT_URI_HASH);

    const urlFromLocalStorage = getItem(REDIRECT_URLS, locationObj);
    const notExpiredUrls = {};
    for (const [key, value] of Object.entries(urlFromLocalStorage)) {
        if (Date.now() < value.expire) {
            notExpiredUrls[key] = value;
        }
    }
    saveItem(REDIRECT_URLS, { ...notExpiredUrls });

    if (urlFromLocalStorage[hash]) {
        locationObj = new URL(urlFromLocalStorage[hash].url);
    }

    return locationObj;
};

export const getRedirectUrl = (url = window.location.href) => {
    const redirectUrlHash = `${uuid()}`;
    const redirectUrlsObj = getItem(REDIRECT_URLS, {});

    saveItem(REDIRECT_URLS, {
        ...redirectUrlsObj,
        [redirectUrlHash]: { url, expire: Date.now() + HOUR_IN_MILLIS },
    });

    return `${window.location.origin}?${REDIRECT_URI_HASH}=${redirectUrlHash}`;
};

const redirectToLoginPage = ({ url, userId } = {}) => {
    if (isMessageBasedAuth()) {
        window.parent.postMessage({ type: MESSAGE_TYPE.GET_AUTH_TOKEN });
        notifyIfNotReceivedToken();
        return;
    }

    if (wentToAuth.getValue()) {
        return;
    }
    const resolvedUserId = userId ?? getCachedUserId();
    const userIdParam = isNil(resolvedUserId) ? '' : `&current_user=${resolvedUserId}`;

    wentToAuth.setValue(true);
    const redirectUrl = getRedirectUrl(url);
    const authURL = `${config.getAuthenticationUrl()}?client_id=${config.getClientId()}${userIdParam}&redirect_uri=${redirectUrl}&response_type=token&appName=Taboola%20Ads`;

    window.location = authURL;
};

export const loginAndBackStartPage = userId => redirectToLoginPage({ url: window.location.origin, userId });

const redirectToLogout = () => {
    wentToAuth.setValue(true);
    window.location = config.getLogoutUrl(window.location.origin);
};

export { redirectToLoginPage, redirectToLogout };
