import React from 'react';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

export const LookBackWindowTitle = () => (
    <FormattedMessage
        id="audience.editor.audience.include.people.from.look-back-window"
        defaultMessage="Specific Timeframe (max 180 days)"
    />
);
export default LookBackWindowTitle;
