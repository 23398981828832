import React from 'react';
import { batch } from 'react-redux';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { errorMessagesUtils } from 'services/utils';
import { unipFunnelApi } from '../../../services/api';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { updateFunnelError, updateFunnelListSuccess } from '../actions';

export const successIndication = {
    message: (
        <FormattedMessage
            id="tracking.unip.funnel.update.default.success"
            defaultMessage="Default funnel successfully updated."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="tracking.unip.funnel.update.default.error.highlight"
            defaultMessage="Unable to update default funnel."
        />
    ),
});

export const updateDefaultFunnel =
    ({ accountId, funnel, onError }) =>
    async dispatch => {
        const { id: funnelId } = funnel;

        try {
            const updateFunnelResponse = await unipFunnelApi.updateUnipFunnel(accountId, funnelId, funnel);

            batch(() => {
                dispatch(updateFunnelListSuccess(updateFunnelResponse));
                dispatch(addIndication(successIndication));
            });
            return updateFunnelResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateFunnelError(error));
            if (onError) {
                onError();
            }
        }
    };
