import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

export const AudienceTargetingTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.audience.targeting.tooltip"
            defaultMessage="Targeting both Marketplace Audiences and My Audiences within the same campaign follows AND logic. Users must belong to both audience types to be targeted."
        />
    </TooltipSection>
);
