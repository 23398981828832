import { useCallback } from 'react';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { useDBStorage } from 'modules/taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { useCurrentValueGetter } from '../../../../../hooks';

const getColumnStateDBKey = (reportId, presetName) =>
    `${REPORT_SETTINGS_PREFIX}.${reportId}.presets.${presetName}.graphEdgesState`;

export const useGraphEdgesState = (reportId, presetName) => {
    const key = getColumnStateDBKey(reportId, presetName);
    const { getter, setter } = useDBStorage();
    const value = getter(key);
    const reportIdGetter = useCurrentValueGetter(reportId);
    const presetNameGetter = useCurrentValueGetter(presetName);

    const setValue = useCallback(
        ({ edgeState, passedReportId, passedPresetName, autoUpdate }) => {
            const resolvedReportId = passedReportId || reportIdGetter();
            const resolvedPresetName = passedPresetName || presetNameGetter();

            const key = getColumnStateDBKey(resolvedReportId, resolvedPresetName);
            setter(key, edgeState, { autoUpdate });
        },
        [setter, reportIdGetter, presetNameGetter]
    );

    return [value, setValue];
};
