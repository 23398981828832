import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useDropdownOptions } from 'hooks';
import { enumTransformer } from 'modules/campaigns/services/utils/dropdownUtils';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import resourcesApi from '../../../../../../services/api/resourcesApi';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import ProfileStatuses from './ProfileStatuses/ProfileStatuses';
import { CAMPAIGN_ADVERTISEMENT_TYPE, CAMPAIGN_CONTENT_SAFETY, CAMPAIGN_CONTENT_TYPE } from './utils/constants';

const languageErrorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.languages.fetch.error"
            defaultMessage="We were not able to fetch list of Languages"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const categoryErrorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.categories.fetch.error"
            defaultMessage="We were not able to fetch list of Category"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const Profile = ({ shouldShowStatuses, addIndicationFlow }) => {
    const contentTypePrefix = 'campaign.editor.content.type';
    const adTypePrefix = 'campaign.editor.ad.type';
    const contentSafetyPrefix = 'campaign.editor.content.safety';
    const contentTypeOptions = useDropdownOptions(CAMPAIGN_CONTENT_TYPE, contentTypePrefix, enumTransformer);
    const adTypeOptions = useDropdownOptions(CAMPAIGN_ADVERTISEMENT_TYPE, adTypePrefix, enumTransformer);
    const contentSafetyOptions = useDropdownOptions(CAMPAIGN_CONTENT_SAFETY, contentSafetyPrefix, enumTransformer);
    const shouldDisplayLegacyCategoryFilter = useConfigMatch({
        [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true',
    });
    const shouldDisplayIabCategoryFilter = useConfigMatch({
        [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true',
    });
    return (
        <>
            <ProfileDropdown
                addIndicationFlow={addIndicationFlow}
                id="content-type"
                field="contentType"
                staticOptions={contentTypeOptions}
                labelId="campaign.editor.profile.contentType"
                messageId={contentTypePrefix}
            />
            <ProfileDropdown
                addIndicationFlow={addIndicationFlow}
                id="ad-type"
                field="adType"
                staticOptions={adTypeOptions}
                labelId="campaign.editor.profile.advertisementType"
                messageId={adTypePrefix}
            />
            <ProfileDropdown
                addIndicationFlow={addIndicationFlow}
                id="content-safety"
                field="contentSafety"
                staticOptions={contentSafetyOptions}
                labelId="campaign.editor.profile.contentSafety"
                messageId={contentSafetyPrefix}
            />
            <ProfileDropdown
                addIndicationFlow={addIndicationFlow}
                id="language"
                field="language"
                labelId="campaign.editor.profile.language"
                fetcher={resourcesApi.getLanguages}
                messageId="app.language.code"
                errorIndication={languageErrorIndication}
            />
            {shouldDisplayLegacyCategoryFilter && (
                <ProfileDropdown
                    addIndicationFlow={addIndicationFlow}
                    id="category"
                    field="category"
                    labelId="campaign.editor.profile.category"
                    fetcher={resourcesApi.getCategories}
                    messageId="campaign.editor.category"
                    errorIndication={categoryErrorIndication}
                />
            )}
            {shouldDisplayIabCategoryFilter && (
                <ProfileDropdown
                    addIndicationFlow={addIndicationFlow}
                    id="iab-category"
                    field="iabCategory"
                    labelId="campaign.editor.profile.category"
                    fetcher={resourcesApi.getIabCategories}
                    messageId="campaign.editor.category.iab"
                    errorIndication={categoryErrorIndication}
                />
            )}
            {shouldShowStatuses ? <ProfileStatuses /> : null}
        </>
    );
};

Profile.propTypes = {
    shouldShowStatuses: PropTypes.bool,
    addIndicationFlow: PropTypes.func,
};

Profile.defaultProps = {
    shouldShowStatuses: false,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    addIndicationFlow: addIndication,
};

export { Profile };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
