import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, map } from 'lodash';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingApi } from 'services/api/trackingApi';
import { errorMessagesUtils } from 'services/utils';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { TRACKING_ROUTE } from '../config';
import { SHOPIFY_STATUS } from '../config/initialValues/shopifyStatus';

const successIndication = {
    message: (
        <FormattedMessage
            id="shopify.set.up.create.success"
            defaultMessage="<b>Successfully connected with Shopify!</b> - 7 new conversions have been created."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
};

const generateInfoIndication = (failRules = {}) => ({
    message: (
        <div>
            <FormattedMessage
                id="tracking.shopify.set.up.create.info"
                defaultMessage="Oops! We were unable to create {failRules} {newline}"
                values={{ failRules }}
            />
            <FormattedMessage
                id="tracking.shopify.set.up.create.info.guid"
                defaultMessage="Follow <a><b>this guide<</b>/a> to resolve the issue"
            />
        </div>
    ),
    type: INDICATION_TYPES.INFO,
});

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
});

const translateEventName = eventName => (
    <FormattedMessage id={`tracking.shopify.set.up.fail.create.${eventName}`} defaultMessage={eventName} />
);

const formatEventNames = failObjects => {
    const failEventNames = map(failObjects, obj => obj?.unipRule?.eventName);
    if (failEventNames.length === 0) return '';
    if (failEventNames.length === 1) return translateEventName(failEventNames[0]);
    const translatedNames = failEventNames.map((eventName, index) => (
        <span key={eventName}>
            {index > 0 && index === failEventNames.length - 1 ? ' and ' : index > 0 ? ', ' : ''}
            {translateEventName(eventName)}
        </span>
    ));
    return translatedNames;
};

export const useShopifyCreatorRulesState = () => {
    const { accountId } = useAccount();
    const dispatch = useDispatch();
    const { submit, step, setStep, nextStep, prevStep } = useFormState();

    const { createShopifyRules } = useTrackingApi();

    const submitShopifyRules = useCallback(async () => {
        try {
            const response = await createShopifyRules(accountId);
            const failRules = response.filter(({ status }) => status === SHOPIFY_STATUS.FAILURE);
            if (!isEmpty(failRules)) {
                const failEventNames = formatEventNames(failRules);
                dispatch(addIndication(generateInfoIndication(failEventNames)));
                return response;
            }
            dispatch(addIndication(successIndication));
            return response;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            throw error;
        }
    }, [createShopifyRules, accountId, dispatch]);

    const submitWrapper = useCallback(() => {
        const submitShopifyWrapper = () => submitShopifyRules();
        submit(submitShopifyWrapper, TRACKING_ROUTE);
    }, [submit, submitShopifyRules]);

    return { submit: submitWrapper, step, setStep, nextStep, prevStep };
};
