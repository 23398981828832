import { useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { BASE_FORM_ROUTE_PATH, PATH_TO_FORM_MODE_MAP } from 'config/routes';

export const useFormMode = () => {
    const routeMatch = useRouteMatch(BASE_FORM_ROUTE_PATH);
    const modePath = get(routeMatch, 'params.mode');

    return PATH_TO_FORM_MODE_MAP[modePath] || modePath;
};
