import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './combinedAudiencesTargetingTypeDropdown.module.scss';

export const TargetingTypeDropdownOption = ({ data: { value } }) => (
    <div className={styles['container']}>
        <span className={styles['label']}>
            <FormattedMessage
                id={`audience.creator.combined.audiences.targeting.type.${value}`}
                values={{
                    exclude: (
                        <span className={styles['exclude']}>
                            <FormattedMessage
                                id="audience.creator.combined.audiences.exclude"
                                defaultMessage="EXCLUDE"
                            />
                        </span>
                    ),
                }}
            />
        </span>
    </div>
);

TargetingTypeDropdownOption.propTypes = {
    data: PropTypes.object,
};
