import { resubmitCreativeSuccess } from '../actions';
import { resubmitCreativesBase } from './resubmitCreativesBase';

export const resubmitCreative =
    ({ creativeIds, accountId }) =>
    dispatch => {
        dispatch(
            resubmitCreativesBase({
                creativeIds,
                accountId,
                onSuccess: creative => {
                    dispatch(resubmitCreativeSuccess(creative));
                },
            })
        );
    };
