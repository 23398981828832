import { DELETE_CREATIVES_SUCCESS, DELETE_CREATIVES_ERROR, REQUEST_DELETE_CREATIVES } from './actionTypes';

export const requestDeleteCreatives = () => ({
    type: REQUEST_DELETE_CREATIVES,
});

export const deleteCreativesSuccess = creatives => ({
    type: DELETE_CREATIVES_SUCCESS,
    payload: creatives,
});

export const deleteCreativesError = (ids, error) => ({
    type: DELETE_CREATIVES_ERROR,
    payload: { ids, error },
});
