import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const emailRecipientsLimit = 10;

export const scheduledReportEmailListValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'app.scheduled.reports.sent.to.validations.error.email.empty',
        defaultMessage: 'Please enter at least one valid email address.',
    },
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: emailRecipientsLimit },
        messageId: 'app.scheduled.reports.sent.to.validations.error.email.limit',
        defaultMessage: 'A report can be scheduled for up to {max} email recipients',
    },
];
