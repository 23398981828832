import { useMemo } from 'react';
import { get, values, identity, isEqual, mapValues } from 'lodash';
import memoizeOne from 'memoize-one';
import { useAccountConfig, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

const matchFieldsWithData = (fieldsConfig, fieldsValues) =>
    mapValues(fieldsConfig, filedPath => get(fieldsValues, filedPath));

export const useMemoDeep = obj => {
    const getMemoizedDeep = useMemo(() => memoizeOne(identity, isEqual), []);
    const memoObj = getMemoizedDeep(obj);

    return memoObj;
};

const emptyObject = {};

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const useValidationContext = ({
    contextConfig: { formFields, accountConfigFields, commonConfigFields } = emptyObject,
    data = emptyObject,
    ...rest
}) => {
    const { data: formData } = useFormDataContext();
    const dataValuesSubset = matchFieldsWithData(formFields, formData || data);
    const dataValues = useMemoDeep(dataValuesSubset);
    const memoRest = useMemoDeep(rest);

    const accountConfigKeys = useMemo(() => values(accountConfigFields), [accountConfigFields]);
    const commonConfigKeys = useMemo(() => values(commonConfigFields), [commonConfigFields]);
    const accountConfigData = useAccountConfig(accountConfigKeys);
    const commonConfigData = useCommonConfig(commonConfigKeys);

    const contextData = useMemo(
        () => ({
            data: {
                ...dataValues,
                ...matchFieldsWithData(commonConfigFields, commonConfigData),
                ...matchFieldsWithData(accountConfigFields, accountConfigData),
                ...memoRest,
            },
        }),
        [dataValues, accountConfigFields, commonConfigFields, accountConfigData, commonConfigData, memoRest]
    );

    return contextData;
};

export default useValidationContext;
