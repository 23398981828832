import { useMemo } from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useAccount } from '../../../../../../../taboola-common-frontend-modules/account-configurations';
import { validationFunctions } from '../../../../../../../taboola-common-frontend-modules/validations';
import { bidValidationsBase } from '../../../../../../config/validations/campaign/bidValidations';
import { useCpcAccountConfig } from '../../../Bid/useCpcAccountConfig';
import { useBudgetFormFieldValue } from '../../../Budget/hooks/useBudgetFormFieldValue';

const cpcCapValidations = [
    ...bidValidationsBase,
    {
        validationFn: validationFunctions.range,
        options: { min: 'minAccountCpc' },
        messageId: 'validations.error.campaign.cpc.specificAccountMinValue',
        defaultMessage: 'CPC Amount cannot be lower than {minAccountCpc} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'maxAccountCpc' },
        messageId: 'validations.error.campaign.cpc.specificAccountMaxValue',
        defaultMessage: 'Bid has to be lower than {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 'cpcMinWarning' },
        messageId: 'validations.error.campaign.cpc.network.minValue',
        defaultMessage: 'Please note: Bid is lower than normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'cpcMaxWarning' },
        messageId: 'validations.error.campaign.cpc.network.maxValue',
        defaultMessage: 'Please note: Bid is above normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
];

export const useCpcCapValidations = () => {
    const { tier, currency } = useAccount();
    const { value: budget } = useBudgetFormFieldValue();
    const cpcAccountConfig = useCpcAccountConfig(tier);
    const validationDependencies = useMemo(
        () => ({
            data: { budgetValue: budget?.value, ...cpcAccountConfig, currency },
        }),
        [budget?.value, cpcAccountConfig, currency]
    );

    return {
        validations: cpcCapValidations,
        validationDependencies,
    };
};
