import { useEffect } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFetchedCampaign } from '../../../hooks/useFetchedCampaign';
import { useCreativePreviewProcessedItems } from '../../creative-preview/components/Body/hooks/useCreativePreviewProcessedItems';
import { useCreativePreviewQueryParams } from '../../creative-preview/components/Body/hooks/useCreativePreviewQueryParams';

export const useAdvancedPreviewEditCarouselParameters = () => {
    const { setCreativePreviewParams } = useCreativePreviewQueryParams();
    const { value: carouselCards } = useFormFieldValue({ field: 'carouselCards' });
    const { value: selectedCampaignId } = useFormFieldValue({ field: 'carouselCards[0].campaignId' });
    const { campaign: fetchedCampaign } = useFetchedCampaign(selectedCampaignId);
    const processedItems = useCreativePreviewProcessedItems(carouselCards, [fetchedCampaign]);

    useEffect(() => {
        setCreativePreviewParams({
            creativePreviewParams: processedItems,
        });
    }, [processedItems, setCreativePreviewParams]);
};
