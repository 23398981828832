import { useCallback, useMemo, useState } from 'react';
import { ENTITY_TYPE } from 'modules/performance-rules/utils/performanceRulesConsts';
import { usePerformanceRulesApi } from 'services/api';

export const useAccountPerformanceRules = (accountName, performanceRulesValue) => {
    const { getAccountRules } = usePerformanceRulesApi();
    const [rawOptions, setOptions] = useState([]);

    const options = useMemo(
        () => rawOptions.filter(item => !performanceRulesValue?.includes(item.id)),
        [performanceRulesValue, rawOptions]
    );

    const loadPerformanceRules = useCallback(async () => {
        const { results: rules } = await getAccountRules(accountName);
        const filteredRules = rules?.filter(rule => {
            return rule.entityType === ENTITY_TYPE.CAMPAIGN;
        });
        return [filteredRules, filteredRules.length];
    }, [accountName, getAccountRules]);

    return { loadPerformanceRules, options, setOptions, rawOptions };
};
