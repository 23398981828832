import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { round } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../FormattedMessage';

export const FormattedRelativeTime = ({ timeDiffInMinutes, msgIdPrefix }) => {
    if (timeDiffInMinutes < 60) {
        return (
            <FormattedMessage
                id={`${msgIdPrefix}.minutes.ago`}
                defaultMessage="{minutes} minutes ago"
                values={{ minutes: round(timeDiffInMinutes) }}
            />
        );
    }

    const hoursAgo = round(timeDiffInMinutes / 60);
    if (hoursAgo < 24) {
        return (
            <FormattedMessage
                id={`${msgIdPrefix}.hours.ago`}
                defaultMessage="{hours} hours ago"
                values={{ hours: hoursAgo }}
            />
        );
    }

    const daysAgo = round(hoursAgo / 24);
    if (daysAgo < 30) {
        return (
            <FormattedMessage
                id={`${msgIdPrefix}.days.ago`}
                defaultMessage="{days} days ago"
                values={{ days: daysAgo }}
            />
        );
    }

    const monthsAgo = round(daysAgo / 30);
    return (
        <FormattedMessage
            id={`${msgIdPrefix}.months.ago`}
            defaultMessage="{months} months ago"
            values={{ months: monthsAgo }}
        />
    );
};

FormattedRelativeTime.propTypes = {
    msgIdPrefix: PropTypes.string,
    timeDiffInMinutes: PropTypes.number,
};

FormattedRelativeTime.defaultProps = {
    msgIdPrefix: 'formatted-relative-time',
};
