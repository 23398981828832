import { useCallback, useContext } from 'react';
import { ModalContext } from '../components/ModalProvider/ModalProvider';

export const useModal = () => {
    const { data, setData } = useContext(ModalContext);
    const open = useCallback(options => new Promise(resolver => setData({ ...options, resolver })), [setData]);
    const close = useCallback(
        passedData =>
            setData(({ resolver }) => {
                resolver(passedData);
                return {};
            }),
        [setData]
    );

    return {
        open,
        close,
        data,
    };
};
