import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const AudienceNameFieldTooltip = ({ fieldName = 'audience' }) => (
    <>
        <TooltipSection>
            <FormattedMessage
                id={`audience.editor.${fieldName}.name.tooltip.title`}
                defaultMessage="Use a unique and descriptive name for your audience."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id={`audience.editor.${fieldName}.name.tooltip.text`}
                defaultMessage="This name will show up under available audiences for targeting in the Campaign Targeting section on the Campaign Setup page."
            />
        </TooltipSection>
    </>
);

export default AudienceNameFieldTooltip;
