/* eslint-disable */
// snippet from - https://developers.intercom.com/installing-intercom/docs/basic-javascript

const injectSnippet = intercomParameters => {
    window.intercomSettings = intercomParameters;
    var w = window;
    var d = document;
    var i = function () {
        i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
        i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = w.intercomSettings.url;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    };
    l();
};

export default injectSnippet;
