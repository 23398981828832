/**
 * Created by naorsolomon
 * Date: 08/04/2019
 */
import React from 'react';
import PropTypes from 'prop-types';
import { RadioIcon } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import BudgetData from './BudgetData/BudgetData';

const BudgetRadioChoice = ({
    onBudgetChange,
    iconComponent,
    title,
    type,
    className,
    onMouseEnter,
    onMouseLeave,
    failedValidationData,
    skipBudgetData,
    ...rest
}) => (
    <RadioIcon
        {...rest}
        value={type}
        title={title}
        labelClassName={className}
        iconComponent={iconComponent}
        onMouseEnter={() => onMouseEnter(type)}
        onMouseLeave={() => onMouseLeave(type)}
        data-metrics-value={type}
        data-metrics-event-name={GTM_EVENTS.USABILITY}
    >
        {!skipBudgetData && (
            <BudgetData
                {...rest}
                onBudgetChange={onBudgetChange}
                type={type}
                failedValidationData={failedValidationData}
            />
        )}
    </RadioIcon>
);

BudgetRadioChoice.propTypes = {
    onBudgetChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.node,
    title: PropTypes.node,
    iconComponent: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    currency: PropTypes.string,
    failedValidationData: PropTypes.object,
};

BudgetRadioChoice.defaultProps = {
    className: '',
    onMouseEnter: () => {},
    onMouseLeave: () => {},
};

export default BudgetRadioChoice;
