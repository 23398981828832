import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedAccountSelector } from '../../../../../selectors';
import { fetchSegments } from '../../../flows';
import { segmentsMapSelector } from '../../../selectors';

const useSegmentsFetchService = () => {
    const dispatch = useDispatch();
    const { accountId: selectedAccountId } = useSelector(selectedAccountSelector) || {};
    const segmentsMap = useSelector(segmentsMapSelector);

    const loadSegmentsPage = useCallback(
        async ({ page, searchText, pageSize, sort }) => {
            if (!selectedAccountId) {
                return [[], 0];
            }

            const results = await dispatch(
                fetchSegments({
                    accountId: selectedAccountId,
                    searchText,
                    page,
                    pageSize,
                    sort,
                })
            );

            return results;
        },
        [dispatch, selectedAccountId]
    );

    const getSegmentFromMap = useCallback(segment => segment && segmentsMap[segment.uddId], [segmentsMap]);

    return {
        getSegmentFromMap,
        loadSegmentsPage,
    };
};

export default useSegmentsFetchService;
