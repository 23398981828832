import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { SUCCESS_INDICATION_TYPES } from 'modules/campaigns/components/ContentTitleAISuggestions';
import ContentTitleAISuggestions from 'modules/campaigns/components/ContentTitleAISuggestions/ContentTitleAISuggestions';
import { useContentTitleSuggestionsState } from 'modules/campaigns/components/ContentTitleAISuggestions/hooks/useContentTitleSuggestionsState';
import { SuggestionContentTitle } from 'modules/campaigns/components/SuggestionContentTitle/SuggestionContentTitle';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreateFormSelectedCampaignId } from '../../../../../hooks/useCreateFormSelectedCampaignId';
import SmartListLoadingItem from '../../../../SmartListLoadingItem/SmartListLoadingItem';
import commonStyles from '../fields.module.scss';
import styles from './contentTitleItem.module.scss';

export const ContentTitleItem = ({
    id,
    label,
    placeholder,
    autoFocus,
    isLoading,
    isLoadingError,
    onChangeCallback,
    ...rest
}) => {
    const { value: campaigns = [] } = useCampaignsFormFieldValue();
    const campaignId = useCreateFormSelectedCampaignId();
    const { index } = rest;

    const isGenAiEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });

    const loadingItem = (
        <div className={styles['loading-item-container']}>
            <SmartListLoadingItem>
                <FormattedMessage id="creative.creator.title.loading" />
            </SmartListLoadingItem>
        </div>
    );

    const allowAiSuggestionsVisible = campaigns.length === 1;
    const { isAISuggestionsVisible, aiSuggestionsCollapsed, setAiSuggestionsCollapsed, openAiSuggestions } =
        useContentTitleSuggestionsState(allowAiSuggestionsVisible);

    const loadingErrorItem = (
        <div className={styles['error-wrapper']}>
            <FormattedMessage id="creative.creator.title.error" />
        </div>
    );

    return (
        <FormField containerClass={styles['container']} labelClass={commonStyles['header']} label={label}>
            <SuggestionContentTitle
                campaignId={campaignId}
                id={id}
                label={label}
                placeholder={placeholder}
                autoFocus={autoFocus}
                className={isLoading ? styles['loading-input'] : undefined}
                isLoading={isLoading}
                loadingComponent={loadingItem}
                isLoadingError={isLoadingError}
                loadingErrorComponent={loadingErrorItem}
                onChangeCallback={onChangeCallback}
                onFocus={openAiSuggestions}
                gaEventsPrefix="VARIATIONS_FORM"
                {...rest}
            />
            {isAISuggestionsVisible && isGenAiEnabled && (
                <ContentTitleAISuggestions
                    campaignId={campaignId}
                    collapsed={aiSuggestionsCollapsed}
                    setCollapsed={setAiSuggestionsCollapsed}
                    onChangeCallback={onChangeCallback}
                    showSuccessIndication={index === 0}
                    successMessageType={SUCCESS_INDICATION_TYPES.MULTIPLE}
                />
            )}
        </FormField>
    );
};

ContentTitleItem.propTypes = {
    label: PropTypes.node,
    id: PropTypes.string,
    status: PropTypes.string,
    existingTitles: PropTypes.array,
    autoFocus: PropTypes.bool,
    value: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    inputRef: PropTypes.func,
};
