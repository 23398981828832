import React from 'react';
import { DragAndDropFileContext, DragAndDropFile } from 'taboola-ultimate-ui';
import CSVFileIcon from '../../../../../campaigns/components/CSVUploadModule/CSVFileIcon';
import { CSV, uploadButton } from '../../../../../campaigns/components/CSVUploadModule/CSVUploadModule';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './uploadFile.module.scss';

export const UploadFile = ({ onDropFileHandler }) => {
    return (
        <div>
            <div className={styles['upload-file']}>
                <DragAndDropFile
                    fileTypes={CSV}
                    multiple={false}
                    onDrop={onDropFileHandler}
                    dropIcon={<CSVFileIcon className={styles['csv-file-icon']} />}
                    text={
                        <FormattedMessage
                            id="audience.editor.crm.upload.drag.and.drop.description"
                            defaultMessage="Drag & Drop CSV File"
                        />
                    }
                >
                    <DragAndDropFileContext.Consumer>
                        {({ openFileDialog }) => {
                            return <div>{uploadButton(openFileDialog)}</div>;
                        }}
                    </DragAndDropFileContext.Consumer>
                </DragAndDropFile>
            </div>

            <span className={styles['guidelines-title']}>
                <FormattedMessage
                    id="audience.editor.crm.upload.csv.file.guidelines.title"
                    defaultMessage="CSV Guidelines:"
                />
            </span>
            <div className={styles['guidelines']}>
                <FormattedMessage
                    id="audience.editor.external-audience.upload.csv.file.guidelines"
                    defaultMessage="One column only{newline}Up to 1,000,000 records"
                />
            </div>
        </div>
    );
};
