import { useMemo, useRef, useCallback } from 'react';
import { difference, keyBy, differenceBy } from 'lodash';
import updateObject from '../../../../taboola-common-frontend-modules/utils/updateObject';

const useSelectedItems = ({ items, isResourcesLoading, selectedValues, onChangeSelectedValues, targetingType }) => {
    const resourcesFallBackCache = useRef({});
    const ids = useMemo(() => {
        const itemsIds = keyBy(items, 'id');
        resourcesFallBackCache.current = updateObject(resourcesFallBackCache.current, itemsIds);
        return itemsIds;
    }, [items]);

    const { selectedItems } = useMemo(() => {
        if (isResourcesLoading) {
            return {
                selectedItems: [],
            };
        }

        return {
            selectedItems: selectedValues.map(id => ({
                id,
                ...(ids[id] ?? resourcesFallBackCache.current[id]),
                targetingType,
            })),
        };
    }, [ids, selectedValues, isResourcesLoading, targetingType]);

    const availableItems = useMemo(() => {
        return differenceBy(items, selectedItems, 'id');
    }, [items, selectedItems]);

    const handleAddItem = useCallback(
        ({ id }) => {
            const addedItem = difference([id], [...selectedValues]);
            if (addedItem.length) {
                onChangeSelectedValues([...selectedValues, ...addedItem]);
            }
        },
        [onChangeSelectedValues, selectedValues]
    );

    const handleRemoveItem = useCallback(
        ({ id }) => {
            onChangeSelectedValues(prev => prev.filter(segmentId => segmentId !== id));
        },
        [onChangeSelectedValues]
    );

    const handleRemoveAllItems = useCallback(() => onChangeSelectedValues([]), [onChangeSelectedValues]);

    return {
        handleAddItem,
        handleRemoveItem,
        handleRemoveAllItems,
        availableItems,
        selectedItems,
    };
};

export default useSelectedItems;
