import React from 'react';
import PropTypes from 'prop-types';
import { useCountryCodeQuery } from 'services/api';
import { RenderItem } from '../RenderItem/RenderItem';

const CountryItem = ({ item, ...props }) => {
    const query = useCountryCodeQuery(item?.code);
    const loadedItem = query.isSuccess && query.data ? { ...item, label: query.data.value } : item;
    return <RenderItem {...props} item={loadedItem} />;
};

CountryItem.propTypes = {
    item: PropTypes.object,
    deleteItem: PropTypes.func,
    mrgIdPrefix: PropTypes.string,
};

export default CountryItem;
