import { useQuery } from 'react-query';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useCampaignLevelConversionsApi } from 'services/api/campaignLevelConversionsApi';

const emptyObj = {};
export const useAccountTotalConversions = () => {
    const { accountId } = useAccount();
    const { getAccountLevelConversionRulesData } = useCampaignLevelConversionsApi();

    const { data = emptyObj } = useQuery(['totalConversions', accountId], () => {
        return getAccountLevelConversionRulesData(accountId);
    });

    return data;
};
