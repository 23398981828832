import React, { useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CollapsibleSideBar } from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { MODULE_NAME } from 'modules/campaigns/config/routes';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import { GraphMessage } from 'modules/campaigns/modules/campaigns-reports/components/Graph';
import * as reportConfigIndex from 'modules/campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from 'modules/campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import { useDateRange } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { DEFAULT_SEGMENT_ID } from '../config';
import { toggleSegmentPickerPinned } from '../flows';
import { useSelectedSegment } from '../hooks';
import { segmentPickerPinnedSelector } from '../selectors';
import { AudienceInsightsReportHeader } from './AudienceInsightsReportHeader';
import { SegmentPicker } from './SegmentPicker';
import styles from './audienceInsightsPage.module.scss';

const sideBarStyles = {
    collapsedSize: styleConsts.leftPanelTouchZone,
    expandedContentMaxSize: styleConsts.leftPaneMaxWidth,
    expandedContentMinSize: styleConsts.leftPaneMinWidth,
    expandedContentSize: `${styleConsts.leftPaneWidth}%`,
};

const AudienceInsightsPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const isSegmentPickerPinned = useSelector(segmentPickerPinnedSelector);
    const containerRef = useRef();
    const { state } = location;

    const [{ startDate, endDate, datePreset }, setDateRange, rangePickerPresetConfigs] = useDateRange();
    const [segment] = useSelectedSegment();

    const buttonAttributes = useMemo(
        () => ({
            'data-metrics-value': isSegmentPickerPinned ? 'Hide' : 'Show',
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'SegmentPicker',
        }),
        [isSegmentPickerPinned]
    );

    const onPinClicked = useCallback(
        isPinned => {
            dispatch(toggleSegmentPickerPinned(isPinned));
        },
        [dispatch]
    );

    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    return (
        <CollapsibleSideBar
            content={<SegmentPicker />}
            isPinned={isSegmentPickerPinned}
            {...sideBarStyles}
            onPinClicked={onPinClicked}
            buttonAttributes={buttonAttributes}
        >
            <div className={styles['container']} ref={containerRef}>
                <StaticIndications />
                <CampaignReports
                    skipFetchReport={state && state.skipFetchReport}
                    parentContainerRef={containerRef}
                    reportConfigMap={reportConfigMap}
                    module={MODULE_NAME}
                    header={<AudienceInsightsReportHeader />}
                    toolbar={<div />}
                    reportQueryParams={{
                        segment,
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    datePreset={datePreset}
                    setDateRange={setDateRange}
                    rangePickerPresetConfigs={rangePickerPresetConfigs}
                    graphOverlay={
                        Number(segment) === DEFAULT_SEGMENT_ID ? (
                            <GraphMessage msgIdOverride="noSelectedSegment" />
                        ) : null
                    }
                />
            </div>
        </CollapsibleSideBar>
    );
};

export default AudienceInsightsPage;
