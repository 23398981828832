'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'tbl-icon': 'calendarPresets__tbl-icon___2GZMj',
    'lg': 'calendarPresets__lg___1fwhr',
    'x2': 'calendarPresets__x2___1quAY',
    'x3': 'calendarPresets__x3___1MrYy',
    'square': 'calendarPresets__square___1dGAq',
    'circle': 'calendarPresets__circle___ibcgj',
    'tbl-icon-ul': 'calendarPresets__tbl-icon-ul___1JC_F',
    'spin': 'calendarPresets__spin___32erz',
    'tbl-icon-spin': 'calendarPresets__tbl-icon-spin___1x_3H',
    'rotate-90': 'calendarPresets__rotate-90___22PRM',
    'rotate-180': 'calendarPresets__rotate-180___3PjXk',
    'rotate-270': 'calendarPresets__rotate-270___2Jah2',
    'flip-y': 'calendarPresets__flip-y___1H6J2',
    'flip-x': 'calendarPresets__flip-x___AX9Uk',
    'tbl-week': 'calendarPresets__tbl-week___3E9ls',
    'tbl-warning': 'calendarPresets__tbl-warning___2CKRZ',
    'tbl-target': 'calendarPresets__tbl-target___26O1D',
    'tbl-status-rejected': 'calendarPresets__tbl-status-rejected___23zJZ',
    'tbl-star-o': 'calendarPresets__tbl-star-o___8o7zu',
    'tbl-sort': 'calendarPresets__tbl-sort___1WoTO',
    'tbl-sort-up': 'calendarPresets__tbl-sort-up___1rug8',
    'tbl-sort-down': 'calendarPresets__tbl-sort-down___2-trh',
    'tbl-show': 'calendarPresets__tbl-show___CQtXA',
    'tbl-settings': 'calendarPresets__tbl-settings___3caVg',
    'tbl-settings-sort': 'calendarPresets__tbl-settings-sort___2A_p-',
    'tbl-search': 'calendarPresets__tbl-search___1T7wJ',
    'tbl-save': 'calendarPresets__tbl-save___1p5YT',
    'tbl-right': 'calendarPresets__tbl-right___1E2pm',
    'tbl-publisher': 'calendarPresets__tbl-publisher___3_grD',
    'tbl-plus': 'calendarPresets__tbl-plus___2BP1R',
    'tbl-plus-button': 'calendarPresets__tbl-plus-button___jAzxB',
    'tbl-platform': 'calendarPresets__tbl-platform___3zY3w',
    'tbl-pixel': 'calendarPresets__tbl-pixel___377g0',
    'tbl-overview': 'calendarPresets__tbl-overview___1HbL8',
    'tbl-ok': 'calendarPresets__tbl-ok___2Lu-u',
    'tbl-ok-o': 'calendarPresets__tbl-ok-o___epWdD',
    'tbl-notification': 'calendarPresets__tbl-notification___2dyt-',
    'tbl-month': 'calendarPresets__tbl-month___2oOi6',
    'tbl-menu': 'calendarPresets__tbl-menu___2gxFz',
    'tbl-location': 'calendarPresets__tbl-location___2cwPf',
    'tbl-link': 'calendarPresets__tbl-link___1kBR_',
    'tbl-left': 'calendarPresets__tbl-left___fF8jj',
    'tbl-hide': 'calendarPresets__tbl-hide___eXwuf',
    'tbl-help-o': 'calendarPresets__tbl-help-o___2IPsA',
    'tbl-help-export': 'calendarPresets__tbl-help-export___U9Ajw',
    'tbl-header-notifications': 'calendarPresets__tbl-header-notifications___RRMgI',
    'tbl-filter': 'calendarPresets__tbl-filter___3EgLQ',
    'tbl-export': 'calendarPresets__tbl-export___2MZC9',
    'tbl-expend': 'calendarPresets__tbl-expend___3shSj',
    'tbl-edit': 'calendarPresets__tbl-edit___23Dzr',
    'tbl-edit-o': 'calendarPresets__tbl-edit-o___2V1No',
    'tbl-duplicate': 'calendarPresets__tbl-duplicate___10Dhe',
    'tbl-dropdown': 'calendarPresets__tbl-dropdown___1V9GX',
    'tbl-delete': 'calendarPresets__tbl-delete___11Md5',
    'tbl-day': 'calendarPresets__tbl-day___39_3n',
    'tbl-contract': 'calendarPresets__tbl-contract___2zklk',
    'tbl-close': 'calendarPresets__tbl-close___ZZ8DE',
    'tbl-close-button': 'calendarPresets__tbl-close-button___3-lWK',
    'tbl-campaigns': 'calendarPresets__tbl-campaigns___2Hveh',
    'tbl-campaign': 'calendarPresets__tbl-campaign___2rVJO',
    'tbl-budget': 'calendarPresets__tbl-budget___3xhkU',
    'tbl-block': 'calendarPresets__tbl-block___2d3dJ',
    'tbl-archive': 'calendarPresets__tbl-archive___Uyv86',
    'tbl-apps': 'calendarPresets__tbl-apps___1DjMV',
    'tbl-alert': 'calendarPresets__tbl-alert___LL7Eq',
    'tbl-taboola-small': 'calendarPresets__tbl-taboola-small___3FU8K',
    'tbl-mobile': 'calendarPresets__tbl-mobile___3OF6h',
    'tbl-cart': 'calendarPresets__tbl-cart___2dAPs',
    'tbl-mail': 'calendarPresets__tbl-mail___3O9wx',
    'tbl-speaker': 'calendarPresets__tbl-speaker___26WeV',
    'tbl-eye': 'calendarPresets__tbl-eye___2BQ8y',
    'taboola-svg-icon': 'calendarPresets__taboola-svg-icon___3NreA',
    'calendar-info-panel': 'calendarPresets__calendar-info-panel___2mA8A',
    'calendar-confirm-button': 'calendarPresets__calendar-confirm-button___1fQNh',
    'calendar-info-panel-buttons': 'calendarPresets__calendar-info-panel-buttons___LeW6D'
};

var CalendarPresets = function (_Component) {
    _inherits(CalendarPresets, _Component);

    function CalendarPresets(props) {
        _classCallCheck(this, CalendarPresets);

        var _this = _possibleConstructorReturn(this, (CalendarPresets.__proto__ || Object.getPrototypeOf(CalendarPresets)).call(this, props));

        _this.setDatePeriod = _this.setDatePeriod.bind(_this);
        return _this;
    }

    _createClass(CalendarPresets, [{
        key: 'setDatePeriod',
        value: function setDatePeriod(period) {
            var setDates = this.props.setDates;

            var periods = {
                today: { startDate: (0, _moment2.default)(), endDate: (0, _moment2.default)() },
                yesterday: { startDate: (0, _moment2.default)().subtract(1, 'days'), endDate: (0, _moment2.default)().subtract(1, 'days') },
                sevenDays: { startDate: (0, _moment2.default)().subtract(7, 'days'), endDate: (0, _moment2.default)() },
                currentMonth: { startDate: (0, _moment2.default)().date(1), endDate: (0, _moment2.default)().endOf('month') }
            };
            return setDates(periods[period].startDate, periods[period].endDate);
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                confirmEnabled = _props.confirmEnabled,
                confirm = _props.confirm,
                confirmButtonText = _props.confirmButtonText;

            return _react2.default.createElement(
                'div',
                { className: styles['calendar-info-panel'] },
                _react2.default.createElement(
                    'div',
                    { className: styles['calendar-info-panel-buttons'] },
                    _react2.default.createElement(
                        'button',
                        { onClick: function onClick() {
                                return _this2.setDatePeriod('today');
                            } },
                        'Today'
                    ),
                    _react2.default.createElement(
                        'button',
                        { onClick: function onClick() {
                                return _this2.setDatePeriod('yesterday');
                            } },
                        'Yesterday'
                    ),
                    _react2.default.createElement(
                        'button',
                        { onClick: function onClick() {
                                return _this2.setDatePeriod('sevenDays');
                            } },
                        'Last 7 Days'
                    ),
                    _react2.default.createElement(
                        'button',
                        { onClick: function onClick() {
                                return _this2.setDatePeriod('currentMonth');
                            } },
                        'This Month'
                    )
                ),
                _react2.default.createElement(
                    'button',
                    {
                        className: styles['calendar-confirm-button'],
                        disabled: confirmEnabled ? '' : 'disabled',
                        onClick: confirm
                    },
                    confirmButtonText
                )
            );
        }
    }]);

    return CalendarPresets;
}(_react.Component);

CalendarPresets.propTypes = {
    confirmEnabled: _propTypes2.default.bool.isRequired,
    confirmButtonText: _propTypes2.default.string,
    setDates: _propTypes2.default.func.isRequired,
    confirm: _propTypes2.default.func.isRequired
};

exports.default = CalendarPresets;