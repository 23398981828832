import React from 'react';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { useSelectedReportId } from '../../../../hooks/useSelectedReportId';
import { OneStepDescription } from '../../OnboardingStepDescription/OneStepDescription';
import { STEP_IDS } from '../../consts';

const { REVIEW_CAMPAIGN_PERFORMANCE } = STEP_IDS;
const { SITE } = REPORT_TYPE;

export const ReviewCampaignStep = ({ isCollapsed, stepCompleteData, completeStep }) => {
    const { hasVisitSiteReport } = stepCompleteData;
    const [selectedReport, setSelectedReport] = useSelectedReportId();

    const checkAndSetSiteReport = () => {
        if (selectedReport !== SITE) {
            setSelectedReport(SITE);
        }
        completeStep();
    };

    return (
        <OneStepDescription
            titleId={REVIEW_CAMPAIGN_PERFORMANCE}
            descriptionId={REVIEW_CAMPAIGN_PERFORMANCE}
            motivationId={REVIEW_CAMPAIGN_PERFORMANCE}
            learnMoreLinkId={REVIEW_CAMPAIGN_PERFORMANCE}
            isComplete={hasVisitSiteReport}
            isCollapsed={isCollapsed}
            onClickCTA={checkAndSetSiteReport}
        />
    );
};
