import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

const maxNameLength = 200;
const regexPattern = /[.]/;
export const invalidNameReg = new RegExp(regexPattern);
const validateName = value => {
    return !invalidNameReg.test(value);
};
export const scheduledReportNameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'app.scheduled.reports.validations.error.name.empty',
        defaultMessage: 'Enter a name for your report.',
    },
    {
        validationFn: validationFunctions.isLength,
        messageId: 'app.scheduled.reports.validations.error.name.length',
        options: { max: maxNameLength },
        defaultMessage: 'Scheduled Report name can not exceed {max} characters',
    },
    {
        validationFn: validateName,
        messageId: 'app.scheduled.reports.validations.error.name.not.allowed.char',
        defaultMessage: 'Report Name contains not allowed character "."',
    },
];
