import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CONTEXTUAL_VIDEO].id;

// columns that can be selectively toggled 'On' and 'Off' in the custom columns view
const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.VIEWABILITY_RATE_VS_AVG.field,
        FIELDS.COMPLETION_RATE_VS_AVG.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.COMPLETED_VIEWS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.VIEWABILITY_RATE.field,
        FIELDS.COMPLETION_RATE.field,
    ].map(field => generateColumnId(reportId, field)),
};

const defaultColumns = [
    FIELDS.CONTEXT.field,
    FIELDS.IMPRESSIONS.field,
    FIELDS.IMPRESSIONS_PCT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.COMPLETED_VIEWS.field,
    FIELDS.VIEWABILITY_RATE.field,
    FIELDS.COMPLETION_RATE.field,
    FIELDS.VIEWABILITY_RATE_VS_AVG.field,
    FIELDS.COMPLETION_RATE_VS_AVG.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
