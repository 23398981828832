import React, { useState } from 'react';
import { useTopicsPreviewTracker } from '../hooks/useTopicsPreviewTracker';
import { ArticleCard } from './ArticleCard';
import { EmptyArticle } from './EmptyArticle';
import { TopicArticlesDrawer } from './TopicArticlesDrawer';
import { TopicSample } from './TopicSample';

export const TopicArticlesPreview = ({ articleSamples, isLoading, topicName, totalArticles }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const hasAvailableArticles = !isLoading && articleSamples?.length > 0;
    const { reportTopicsPreviewEvents, reportTopicsUniquePublishers } = useTopicsPreviewTracker();
    const handleContentClick = () => {
        if (!isDrawerOpen) {
            reportTopicsPreviewEvents(`Drawer Opened, Topic Name: ${topicName}`, 'Topics Preview Drawer');
            reportTopicsUniquePublishers(articleSamples, topicName);
        }
        setIsDrawerOpen(!isDrawerOpen);
    };
    const handleCancel = () => {
        setIsDrawerOpen(false);
    };

    return (
        <div>
            <TopicSample handleContentClick={handleContentClick}></TopicSample>
            <TopicArticlesDrawer
                isOpen={isDrawerOpen}
                onClose={handleCancel}
                topicName={topicName}
                totalArticles={totalArticles}
            >
                {hasAvailableArticles ? (
                    articleSamples.map(article => {
                        return (
                            <ArticleCard key={article.url} articleDetails={article} topicName={topicName}></ArticleCard>
                        );
                    })
                ) : (
                    <EmptyArticle />
                )}
            </TopicArticlesDrawer>
        </div>
    );
};
