import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MAX_THUMBNAILS_COUNT } from '../../config';
import CountView from '../CountView/CountView';
import { ThumbnailsPicker } from '../ThumbnailsPicker/ThumbnailsPicker';
import ThumbnailsFieldDescription from './ThumbnailsFieldDescription';
import ThumbnailsFieldTooltip from './ThumbnailsFieldTooltip';

const getThumbnailCount = thumbnails => thumbnails.filter(thumbnail => thumbnail.value).length;

const ThumbnailsField = ({
    thumbnailList,
    className,
    deleteItem,
    minItems,
    maxItems,
    setCreativeFocus,
    onSelectMedia,
    motionIdFallbackSelecting,
    startSelectFallbackImage,
    cancelSelectFallbackImage,
    campaignId,
    titleList,
    openCropModal,
}) => (
    <FormField
        label={
            <FormattedMessage
                id="creative.creator.thumbnails.title"
                defaultMessage="Media{countView}"
                values={{ countView: <CountView data={thumbnailList} countingRule={getThumbnailCount} /> }}
            />
        }
        description={<ThumbnailsFieldDescription minItems={minItems} maxItems={maxItems} />}
        helpText={<ThumbnailsFieldTooltip />}
        inputId="creative-creator-creatives"
        containerClass={className}
    >
        <ThumbnailsPicker
            deleteItem={deleteItem}
            onSelectMedia={onSelectMedia}
            maxItems={maxItems}
            thumbnailList={thumbnailList}
            setCreativeFocus={setCreativeFocus}
            motionIdFallbackSelecting={motionIdFallbackSelecting}
            startSelectFallbackImage={startSelectFallbackImage}
            cancelSelectFallbackImage={cancelSelectFallbackImage}
            campaignId={campaignId}
            titleList={titleList}
            openCropModal={openCropModal}
        />
    </FormField>
);

ThumbnailsField.propTypes = {
    className: PropTypes.string,
    thumbnailList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            status: PropTypes.string,
        })
    ),
    deleteItem: PropTypes.func,
    onUpload: PropTypes.func,
    maxItems: PropTypes.number,
    setCreativeFocus: PropTypes.func,
    columnCount: PropTypes.number,
};

ThumbnailsField.defaultProps = {
    maxItems: MAX_THUMBNAILS_COUNT,
    className: '',
    thumbnailList: [],
};

export default ThumbnailsField;
