import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ReportSearchHighlight } from '../ReportSearchHighlight/ReportSearchHighlight';
import EllipsisCellRenderer from './EllipsisCellRenderer';

const FormattedMessageCellRenderer = ({ value, defaultValue = 1, lines, isSearchable }) => {
    const { formatMessage } = useIntl();
    const highlightText = formatMessage({
        id: value,
        defaultMessage: defaultValue.toString(),
    });

    return (
        <EllipsisCellRenderer
            lines={lines}
            value={isSearchable ? <ReportSearchHighlight text={highlightText} /> : <span>{highlightText}</span>}
        />
    );
};

FormattedMessageCellRenderer.propTypes = {
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    lines: PropTypes.number,
};

export default FormattedMessageCellRenderer;
