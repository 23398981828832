import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { Language } from './Language';
import LanguageFieldTooltip from './LanguageFieldTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const LanguageField = () => {
    return (
        <FormField
            inputId="language"
            label={<FormattedMessage id="video.campaign.editor.targeting.language.title" defaultMessage="Language" />}
            description={
                <FormattedMessage
                    id="video.campaign.editor.targeting.language.description"
                    defaultMessage="Target specific site languages."
                />
            }
            helpText={<LanguageFieldTooltip />}
            containerClass={styles['input']}
        >
            <Language />
        </FormField>
    );
};
