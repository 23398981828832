import {
    FEATURE_FLAGS,
    useConfigMatch,
    useIsAppAccountNetwork,
} from '../../taboola-common-frontend-modules/account-configurations';
import { pixelOrS2sInstalled } from './pixelOrS2sInstalled';
import { useTrackingState } from './useTrackingState';

export const useTrackingDataForBidStrategy = () => {
    const {
        trackingStateData: {
            pixelTrackingData,
            s2sTrackingData,
            hasAtLeastOneActiveConversion: accountHasAtLeastOneActiveConversion,
        },
        formAccountTrackingStateData: {
            pixelTrackingData: formAccountPixelTrackingData,
            s2sTrackingData: formAccountS2sTrackingData,
            hasAtLeastOneActiveConversion: formAccountHasAtLeastOneActiveConversion,
        },
    } = useTrackingState();
    const isTcpaPixelValidationEnabled = useConfigMatch({ [FEATURE_FLAGS.IS_TCPA_PIXEL_VALIDATION_ENABLED]: 'true' });
    const isNetworkAccount = useIsAppAccountNetwork();
    const accountHasPixelOrS2sInstalled = pixelOrS2sInstalled(pixelTrackingData, s2sTrackingData);
    const isPixelOrS2sInstalled = isNetworkAccount
        ? accountHasPixelOrS2sInstalled || pixelOrS2sInstalled(formAccountPixelTrackingData, formAccountS2sTrackingData)
        : accountHasPixelOrS2sInstalled;
    const hasAtLeastOneActiveConversion = isNetworkAccount
        ? accountHasAtLeastOneActiveConversion || formAccountHasAtLeastOneActiveConversion
        : accountHasAtLeastOneActiveConversion;

    return {
        isPixelOrS2sInstalled,
        hasAtLeastOneActiveConversion,
        isTcpaPixelValidationEnabled,
    };
};
