import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const messageConfig = actionType =>
    actionType === 'BLOCK_SITES'
        ? {
              id: 'performance.rule.executed.actions.report.actionType.details.site.blocked.name',
              defaultMessage: '{name} - ({id})',
          }
        : {
              id: 'performance.rule.executed.actions.report.actionType.details.ad.paused.name',
              defaultMessage: '{name} ID: {id}',
          };

export const PerformanceRuleExecutedActionActivityDetailsCellRenderer = ({
    data: {
        affectedEntity: { id, name },
        actionType,
    },
}) => (
    <FormattedMessage
        {...messageConfig(actionType)}
        values={{
            name,
            id,
        }}
    />
);
