import React, { Fragment } from 'react';
import { ExternalLink } from '../../../../../../components';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

const ContentFieldTooltip = () => (
    <Fragment>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.help.intro"
                defaultMessage="Titles must conform to Taboola guidelines and policies."
            />
        </TooltipSection>
        <FormattedMessage
            id="creative.creator.content.help.linkIntro"
            defaultMessage="Read our {link}."
            values={{
                link: (
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/115006880507-Title-and-Thumbnail-Best-Practices">
                        <FormattedMessage
                            id="creative.creator.content.help.link"
                            defaultMessage="Title and Thumbnail Best Practices"
                        />
                    </ExternalLink>
                ),
            }}
        />
    </Fragment>
);

export default ContentFieldTooltip;
