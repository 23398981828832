import { FORM_MODES } from 'config/formModes';
import { ENTITY, PERFORMANCE_RULES_ROUTE_PATH } from '../config';
import { generatePerformanceRulesFormPath } from './generatePerformanceRulesFormPath';

export const generatePerformanceCreatorPath = () =>
    generatePerformanceRulesFormPath(PERFORMANCE_RULES_ROUTE_PATH, {
        entity: ENTITY.RULE,
        mode: FORM_MODES.CREATE,
    });

export const generatePerformanceEditPath = (accountName, ruleId) =>
    generatePerformanceRulesFormPath(PERFORMANCE_RULES_ROUTE_PATH, {
        entity: ENTITY.RULE,
        mode: FORM_MODES.EDIT,
        accountName,
        ruleId,
    });

export const generatePerformanceDuplicatePath = (accountName, ruleId) =>
    generatePerformanceRulesFormPath(PERFORMANCE_RULES_ROUTE_PATH, {
        entity: ENTITY.RULE,
        mode: FORM_MODES.DUPLICATE,
        accountName,
        ruleId,
    });
