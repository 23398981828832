'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _DropdownMenu = require('../Dropdown/DropdownMenu');

var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'separatorDropdown__taboola-svg-icon___2jdMa',
    'separator-dropdown': 'separatorDropdown__separator-dropdown___1PUg6',
    'left-side': 'separatorDropdown__left-side___wx78s',
    'separator': 'separatorDropdown__separator___VLjDb',
    'right-side': 'separatorDropdown__right-side___2LLcb',
    'container': 'separatorDropdown__container___2r1EV'
};


var classNameBuilder = _bind2.default.bind(styles);

var SeparatorDropdown = function (_Component) {
    _inherits(SeparatorDropdown, _Component);

    function SeparatorDropdown() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SeparatorDropdown);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SeparatorDropdown.__proto__ || Object.getPrototypeOf(SeparatorDropdown)).call.apply(_ref, [this].concat(args))), _this), _this.getValue = function () {
            var _this$props = _this.props,
                selectedValue = _this$props.selectedValue,
                options = _this$props.options;

            return selectedValue ? options.find(function (option) {
                return option.value === selectedValue;
            }) : {};
        }, _this.renderValue = function (_ref2) {
            var _ref2$data = _ref2.data;
            _ref2$data = _ref2$data === undefined ? {} : _ref2$data;
            var _ref2$data$label = _ref2$data.label,
                label = _ref2$data$label === undefined ? '' : _ref2$data$label;
            var _this$props2 = _this.props,
                leftSide = _this$props2.leftSide,
                separator = _this$props2.separator;

            return _react2.default.createElement(
                'div',
                { className: styles['separator-dropdown'] },
                _react2.default.createElement(
                    'span',
                    { className: styles['left-side'] },
                    leftSide
                ),
                _react2.default.createElement(
                    'span',
                    { className: styles['separator'] },
                    separator
                ),
                _react2.default.createElement(
                    'span',
                    { className: styles['right-side'] },
                    label
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(SeparatorDropdown, [{
        key: 'render',
        value: function render() {
            var className = this.props.className;


            var containerClassName = classNameBuilder('container', _defineProperty({}, className, className));

            return _react2.default.createElement(_DropdownMenu2.default, Object.assign({
                valueRenderer: this.renderValue,
                selectedValueObject: this.getValue(),
                className: containerClassName
            }, this.props));
        }
    }]);

    return SeparatorDropdown;
}(_react.Component);

SeparatorDropdown.propTypes = Object.assign({
    /** left side text or component */
    leftSide: _propTypes2.default.node,
    /** separator element */
    separator: _propTypes2.default.node,
    /** selected value */
    selectedValue: _propTypes2.default.string,
    className: _propTypes2.default.string,
    style: _propTypes2.default.object
}, _DropdownMenu2.default.propTypes);

SeparatorDropdown.defaultProps = {
    separator: '|'
};

exports.default = SeparatorDropdown;