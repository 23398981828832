import React from 'react';
import { isEmpty } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ContentDescriptionItem } from '../../../../ContentField/ContentItem/fields';
import { AiTextSuggestions } from '../AiSuggestions/AiTextSuggestions';
import { AiContentBaseCard } from './AiContentBaseCard';
import styles from './aiContentCardCommon.module.scss';

export const AiContentDescriptionCard = ({ suggestedDescriptions, loadMoreDescriptions, descriptionStatus }) => {
    const { formatMessage } = useIntl();

    const { value: contentId } = useFormFieldValue({ field: 'id' });
    const { value: description, onChange: onChangeDescription } = useFormFieldValue({ field: 'description' });

    return (
        <AiContentBaseCard>
            <ContentDescriptionItem
                id={contentId}
                label={
                    <FormattedMessage id="creative.creator.content.description.label" defaultMessage="Description" />
                }
                labelClass={styles['label']}
                placeholder={formatMessage({
                    id: 'creative.creator.content.description.placeholder',
                    defaultMessage: 'Type to add description...',
                })}
                index="0"
                showHelpTooltip
                isShowLengthIndicatorAlways
            />
            {!isEmpty(suggestedDescriptions) && (
                <AiTextSuggestions
                    results={suggestedDescriptions}
                    labelType="description"
                    onSuggestionClick={onChangeDescription}
                    onShowMoreClick={loadMoreDescriptions}
                    status={descriptionStatus}
                    currentTexts={description}
                />
            )}
        </AiContentBaseCard>
    );
};
