import { forEach, mapValues, sortBy, upperFirst } from 'lodash';

export const dictionaryByParentApp = {
    yahoo: {
        'campaign group': 'campaign',
        group: 'campaign',
        campaign: 'line',
        'brand name': 'advertiser name',
        'marketing objective': 'objective',
        'bidding strategy': 'goal type',
        'conversion goal': 'online conversions',
        'conversion event': 'pixel rule',
        'days and hours': 'day parting',
        platform: 'device',
        'reach estimator': 'forecasting',
        'integral ad': 'integral ad',
        ad: 'creative',
    },
};

const COORDINATING_CONJUNCTIONS_SET = new Set(['and', 'but', 'or', 'nor', 'for', 'so', 'yet']);

const adjustCase = (oldWord, newWord) => {
    if (oldWord === oldWord.toUpperCase()) {
        return newWord.toUpperCase();
    }

    if (oldWord === upperFirst(oldWord)) {
        return upperFirst(newWord);
    }

    return newWord.toLowerCase();
};

const alignCases = (oldText, newText) => {
    let oldWords = oldText.split(' ');
    const newWords = newText.split(' ');

    if (oldWords.length > newWords.length) {
        oldWords = oldWords.filter(value => !COORDINATING_CONJUNCTIONS_SET.has(value));
    }

    return newWords
        .map((word, index) => {
            const oldWord = oldWords[index] || oldWords[0];
            return adjustCase(oldWord, word);
        })
        .join(' ');
};

const replace = (message, regex, newValue, placeholders) => {
    let match;
    let text = message;
    while ((match = regex.exec(text)) !== null) {
        const replacement = `{{${placeholders.length}}}`;
        const matchedWord = match[1];

        text = text.slice(0, match.index) + replacement + text.slice(match.index + matchedWord.length);
        regex.lastIndex = match.index + replacement.length;

        placeholders.push(alignCases(match[0], newValue));
    }
    regex.lastIndex = 0;

    return text;
};

export const translateTermsByParentApp = (messages, parentApp) => {
    const dictionary = dictionaryByParentApp[parentApp];

    if (!dictionary) {
        return messages;
    }

    const dictionaryKeys = sortBy(Object.keys(dictionary), 'length').reverse();
    const dictionaryKeyToRegexMap = mapValues(dictionary, (_, key) => new RegExp(`\\b(${key})('?s?)\\b`, 'gi'));

    return mapValues(messages, currentMessage => {
        const placeholders = [];
        let message = currentMessage || '';

        if (typeof message !== 'string') {
            return message;
        }

        forEach(dictionaryKeys, key => {
            const regex = dictionaryKeyToRegexMap[key];
            message = replace(message, regex, dictionary[key], placeholders);
        });

        message = message.replace(/\{\{\d+\}\}/g, match => {
            const index = match.replace(/{{|}}/g, '');
            return placeholders[index];
        });

        return message;
    });
};
