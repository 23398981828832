import { useCallback } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { ScheduledReportsCustomRuleListSelect } from 'modules/scheduled-reports/components/ScheduledReportsCustomRuleListFiled/ScheduledReportsCustomRuleListSelect';
import { AccountDropdown } from '../../../campaigns/modules/common-campaign-form';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { ScheduledReportsCampaignGroupsListTypeFiled } from '../ScheduledReportsCampaignGroupsListTypeFiled/ScheduledReportsCampaignGroupsListTypeFiled';
import { ScheduledReportsCampaignListSelect } from '../ScheduledReportsCampaignsListFiled/ScheduledReportsCampaignListSelect';
import { CONDITION_DIMENSIONS } from '../ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';
import styles from '../ScheduledReportsAccountSelectField/ScheduledReportsAccountSelectField.module.scss';

const getDimensionFilterSelectComponent = (dimension, account) => {
    switch (dimension) {
        case CONDITION_DIMENSIONS.CAMPAIGN_BY_DAY:
        case CONDITION_DIMENSIONS.ADS_BY_DAY:
            return <ScheduledReportsCampaignListSelect account={account} />;
        case CONDITION_DIMENSIONS.CAMPAIGNS_GROUP_BY_DAY:
            return <ScheduledReportsCampaignGroupsListTypeFiled />;
        case CONDITION_DIMENSIONS.CUSTOM_RULES_BY_DAY:
            return <ScheduledReportsCustomRuleListSelect />;
        default:
            return null;
    }
};

export const ScheduledReportsAccountWithCampaignsSelectField = () => {
    const account = useAccount();
    const { id } = account;
    const { onChange: accountIdChange } = useFormFieldValue({ field: 'accountId' }, { id });
    const onAccountChange = useCallback(
        value => {
            const { id } = value;
            accountIdChange([id]);
        },
        [accountIdChange]
    );
    const { value: dimension } = useFormFieldValue({
        field: 'dimension',
    });

    return (
        <>
            <FormField containerClass={styles['account-field']}>
                <AccountDropdown onAccountChange={onAccountChange} accountForCampaign={account} />
            </FormField>
            {getDimensionFilterSelectComponent(dimension, account)}
        </>
    );
};
