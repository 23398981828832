import { merge } from 'lodash';
import { config as logRocketConfig } from 'modules/taboola-common-frontend-modules/log-rocket/config';
import injectSnippet from './injectSnippet';

class IntercomService {
    constructor() {
        this.isBooted = false;
    }

    async boot(intercomBootParameters) {
        if (this.isBooted) {
            return;
        }

        window.intercomSettings = intercomBootParameters;
        window.Intercom('boot', intercomBootParameters);
        this.isBooted = true;

        const sessionURL = await logRocketConfig.getSessionUrlAsync();
        window.Intercom('trackEvent', 'LogRocket', { sessionURL });
    }

    isServiceBooted() {
        return this.isBooted;
    }

    initIntercomService(bootParameters, updateParameters) {
        if (!window.Intercom && bootParameters) {
            injectSnippet(bootParameters);
        }
        if (window.Intercom) {
            this.boot(bootParameters);
            this.update(updateParameters);
        }
    }

    shutdown() {
        if (!this.isBooted) {
            return;
        }

        window.Intercom('shutdown');
        this.isBooted = false;
    }

    update(intercomParameters) {
        if (!this.isBooted) {
            return;
        }

        const logRocketParams = { logrocketURL: logRocketConfig.getSessionsUrl() };

        if (!intercomParameters) {
            const lastRequestedAt = new Date().getTime() / 1000;
            window.Intercom('update', { last_requested_at: lastRequestedAt, ...logRocketParams });
            return;
        }

        window.intercomSettings = merge({ ...logRocketParams }, window.intercomSettings, intercomParameters);
        window.Intercom('update', intercomParameters);
    }

    hide() {
        if (!this.isBooted) {
            return;
        }

        window.Intercom('hide');
    }

    show() {
        if (!this.isBooted) {
            return;
        }

        window.Intercom('show');
    }

    onHide(callback) {
        if (!this.isBooted) {
            return;
        }

        window.Intercom('onHide', callback);
    }

    onShow(callback) {
        if (!this.isBooted) {
            return;
        }

        window.Intercom('onShow', callback);
    }

    hideIcon() {
        if (!this.isBooted) {
            return;
        }
        window.intercomSettings = { ...window.intercomSettings, hide_default_launcher: true };
        window.Intercom('update', {
            hide_default_launcher: true,
        });
    }

    showIcon() {
        if (!this.isBooted) {
            return;
        }
        window.intercomSettings = { ...window.intercomSettings, hide_default_launcher: false };
        window.Intercom('update', {
            hide_default_launcher: false,
        });
    }
}

export const intercomService = new IntercomService();
