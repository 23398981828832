import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

export const ConversionNameFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.setup.name.tooltip.title"
                defaultMessage="Use a unique and descriptive name for your conversion."
            />
        </TooltipSection>
    </>
);
