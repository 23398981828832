import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS,
    GALLERY_ITEM_DROPDOWN_OPTIONS,
} from '../GalleryItemDropdown/GalleryItemDropdownOptions';
import { EditFallbackImageContent } from './EditFallbackImage/EditFallbackImageContent';
import { EditFallbackImageContentHeader } from './EditFallbackImage/EditFallbackImageContentHeader';
import { ImageVariationsContent } from './ImageVariations/ImageVariationsContent';
import { ImageVariationsContentHeader } from './ImageVariations/ImageVariationsContentHeader';
import { ReplaceBackgroundContent } from './ReplaceBackground/ReplaceBackgroundContent';
import { ReplaceBackgroundContentHeader } from './ReplaceBackground/ReplaceBackgroundContentHeader';

export const CREATIVE_STUDIO_MODES = {
    IMAGE_VARIATIONS: GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS,
    REPLACE_BACKGROUND: GALLERY_ITEM_DROPDOWN_OPTIONS.REPLACE_BACKGROUND,
    EDIT_FALLBACK_IMAGE: GALLERY_ITEM_DROPDOWN_OPTIONS.EDIT_FALLBACK_IMAGE,
};
export const CREATIVE_STUDIO_MODES_LIST = Object.values(CREATIVE_STUDIO_MODES);
export const CREATIVE_STUDIO_MODES_SET = new Set(CREATIVE_STUDIO_MODES_LIST);

export const CREATIVE_STUDIO_MODE_CONFIG = {
    [CREATIVE_STUDIO_MODES.IMAGE_VARIATIONS]: {
        Header: ImageVariationsContentHeader,
        Content: ImageVariationsContent,
        galleryItemDropdownOptions: [
            GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.IMAGE_VARIATIONS,
            GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.SAVE_TO_MEDIA_LIBRARY,
        ],
        initialSelected: false,
    },
    [CREATIVE_STUDIO_MODES.REPLACE_BACKGROUND]: {
        Header: ReplaceBackgroundContentHeader,
        Content: ReplaceBackgroundContent,
        galleryItemDropdownOptions: [GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.SAVE_TO_MEDIA_LIBRARY],
        initialSelected: true,
        applyButtonText: (
            <FormattedMessage id="creative.studio.replace.background.submit.button" defaultMessage="Save & Continue" />
        ),
    },
    [CREATIVE_STUDIO_MODES.EDIT_FALLBACK_IMAGE]: {
        Header: EditFallbackImageContentHeader,
        Content: EditFallbackImageContent,
        galleryItemDropdownOptions: [GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.EDIT_FALLBACK_IMAGE],
    },
};

export const isValidCreativeStudioMode = mode => CREATIVE_STUDIO_MODES_SET.has(mode);
