import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { useCurrentValueGetter, useEntityType, useSelectedAccount } from '../../../hooks';
import { allCampaignsNameIdSelector } from '../../../selectors';
import { isNetworkAccountType } from '../../account-management';
import { fetchCampaignError } from '../actions';
import { CAMPAIGN_STATUS, getCampaignEntity, PATH_TO_ENTITY_MAP } from '../config';
import AllCampaignsObject from '../config/allCampaignsObject';
import { fetchCampaign } from '../flows/fetchCampaign';
import fetchCampaigns from '../flows/fetchCampaigns';
import { campaignsMapSelector } from '../selectors';
import { useSelectedCampaignId } from './useSelectedCampaignId';

const STALE_TIME = 10 * 60 * 1000;

const useCampaignEntity = campaign => {
    const pathEntity = useEntityType(PATH_TO_ENTITY_MAP);
    const entity = pathEntity || (campaign && getCampaignEntity({ demandType: campaign.demandType }));
    const getter = useCurrentValueGetter(entity);

    return getter;
};

export const useFetchedCampaign = (campaignId, advertiserName) => {
    const dispatch = useDispatch();
    const campaigns = useSelector(campaignsMapSelector);
    const cachedCampaign = campaigns[campaignId];
    const entityGetter = useCampaignEntity(cachedCampaign);
    const [{ type: selectedAccountType, accountName: selectedAccountName }] = useSelectedAccount();
    const [, setCampaignId] = useSelectedCampaignId();
    const allCampaignsNameId = useSelector(allCampaignsNameIdSelector);
    const campaignStatus = cachedCampaign?.status;
    const statusToFilter =
        campaignStatus === CAMPAIGN_STATUS.TERMINATED ? CAMPAIGN_STATUS.TERMINATED.toLowerCase() : undefined;

    const { isError, isSuccess } = useQuery(
        [
            'fetchedCampaign',
            campaignId,
            entityGetter,
            advertiserName,
            selectedAccountType,
            selectedAccountName,
            statusToFilter,
        ],
        async () => {
            if (isNil(campaignId) || campaignId === AllCampaignsObject.id || !selectedAccountName) {
                return;
            }
            let accountName = advertiserName || (!isNetworkAccountType(selectedAccountType) && selectedAccountName);
            let entityType = entityGetter();

            if (!accountName || !entityType) {
                const [campaigns] = await dispatch(
                    fetchCampaigns({
                        accountName: selectedAccountName,
                        searchText: campaignId,
                        page: 1,
                        status: statusToFilter,
                    })
                );
                const baseCampaign = campaigns.find(campaign => campaign.id === campaignId);
                if (!baseCampaign) {
                    setCampaignId(AllCampaignsObject.id);
                    dispatch(fetchCampaignError());
                    return;
                }
                accountName = baseCampaign.advertiserName;
                entityType = getCampaignEntity({ demandType: baseCampaign.demandType });
            }

            return dispatch(fetchCampaign(accountName, campaignId, entityType, { hideError: true }));
        },
        { staleTime: STALE_TIME }
    );

    const campaign = useMemo(() => {
        if (campaignId === AllCampaignsObject.id || isNil(campaignId)) {
            return { id: AllCampaignsObject.id, name: allCampaignsNameId };
        }

        if (!cachedCampaign || !selectedAccountName) {
            return { id: campaignId, name: '' };
        }

        return cachedCampaign;
    }, [allCampaignsNameId, campaignId, cachedCampaign, selectedAccountName]);

    return { isError, isSuccess, campaign };
};
