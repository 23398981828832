import {
    DELETE_PERFORMANCE_RULE_ERROR,
    DELETE_PERFORMANCE_RULE_SUCCESS,
    REQUEST_DELETE_PERFORMANCE_RULE,
} from './actionTypes';

export const requestDeletePerformanceRule = () => ({ type: REQUEST_DELETE_PERFORMANCE_RULE });

export const deletePerformanceRuleSuccess = rule => ({ type: DELETE_PERFORMANCE_RULE_SUCCESS, payload: rule });

export const deletePerformanceRuleError = error => ({ type: DELETE_PERFORMANCE_RULE_ERROR, payload: error });
