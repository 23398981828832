import React from 'react';
import classnames from 'classnames/bind';
import { FORM_MODES } from '../../../../../config/formModes';
import { useFormDataContext } from '../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { SizeEstimationField } from './SizeEstimationField';
import { useMailDomainsSizeEstimate } from './useMailDomainsSizeEstimate';
import styles from './mailDomainsSizeEstimationWidget.module.scss';

const classNameBuilder = classnames.bind(styles);

export const MailDomainsSizeEstimationWidget = () => {
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.EDIT;

    const { deviceCount, isLoading } = useMailDomainsSizeEstimate();
    return (
        <div
            className={classNameBuilder('container', {
                'edit-mode': isEditMode,
            })}
        >
            <div className={styles['header']}>
                <FormattedMessage
                    id="external-audience.size.estimation.widget.title"
                    defaultMessage="Reach (last 30 days)"
                />
            </div>
            <div className={styles['content']}>
                <SizeEstimationField field="Devices" isLoading={isLoading} count={deviceCount} />
            </div>
        </div>
    );
};
