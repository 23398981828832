import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import { DatePicker } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { SchedulerDateTimeHeader } from './SchedulerDateTimeHeader';
import { TimeSelector } from './TimeSelector';
import { endTimeFrameValidations, startTimeFrameValidations } from './timeFrameValidations';
import styles from './schedulerDateTime.module.scss';

const WithMessage = withIndication(({ children }) => <div>{children}</div>);

export const SchedulerDateTime = ({ startDateCampaign, endDateCampaign }) => {
    const {
        value: startDate,
        onChange: onChangeStartDate,
        failedValidationData: startFailedValidation,
    } = useFormValidatedValue({
        field: 'startDate',
        validations: startTimeFrameValidations,
        validationDependencies: { startDateCampaign },
    });

    const {
        value: endDate,
        onChange: onChangeEndDate,
        failedValidationData: endFailedValidation,
    } = useFormValidatedValue({
        field: 'endDate',
        validations: endTimeFrameValidations,
        validationDependencies: { endDateCampaign, startDate },
    });
    const adSchedulerStart = new Date(startDate);
    const adSchedulerEnd = new Date(endDate);

    const handleStartDateChange = date => {
        const existDate = new Date(startDate);
        if (date) {
            existDate.setUTCFullYear(date.year());
            existDate.setUTCMonth(date.month());
            existDate.setUTCDate(date.date());
            onChangeStartDate(existDate);
        }
    };
    const handleEndDateChange = date => {
        const existDate = new Date(endDate);
        if (date) {
            existDate.setUTCFullYear(date.year());
            existDate.setUTCMonth(date.month());
            existDate.setUTCDate(date.date());
            onChangeEndDate(existDate);
        }
    };

    const outsideRangeHandle = useCallback(
        day => {
            if (!startDateCampaign) return day.isBefore(moment().startOf('day'));
            return (
                day.isBefore(moment().startOf('day')) ||
                day.isBefore(moment(startDateCampaign)) ||
                day.isAfter(moment(endDateCampaign))
            );
        },
        [startDateCampaign, endDateCampaign]
    );
    return (
        <div>
            <WithMessage {...startFailedValidation}>
                <table className={styles['scheduler-date-time-start']}>
                    <SchedulerDateTimeHeader
                        header={
                            <FormattedMessage
                                id="creative.editor.schedule.day.time.header.start.day"
                                defaultMessage="Start date"
                            />
                        }
                    />
                    <tbody className={styles['container']}>
                        <tr>
                            <td className={styles['column-body-date']}>
                                <DatePicker
                                    date={startDate ? moment.tz(startDate, 'UTC') : null}
                                    onDateChange={handleStartDateChange}
                                    id="schedulerDayTime-date-input-start"
                                    isOutsideRange={outsideRangeHandle}
                                />
                            </td>
                            <TimeSelector
                                date={adSchedulerStart}
                                onChangeDate={onChangeStartDate}
                                initialDate={startDate}
                            />
                        </tr>
                    </tbody>
                </table>
            </WithMessage>
            <div>
                <WithMessage {...endFailedValidation}>
                    <table className={styles['scheduler-date-time-end']}>
                        <SchedulerDateTimeHeader
                            header={
                                <FormattedMessage
                                    id="creative.editor.schedule.day.time.header.end.day"
                                    defaultMessage="End date"
                                />
                            }
                        />
                        <tbody className={styles['container']}>
                            <tr>
                                <td className={styles['column-body-date']}>
                                    <DatePicker
                                        date={endDate ? moment.tz(endDate, 'UTC') : null}
                                        onDateChange={handleEndDateChange}
                                        customInputIcon={
                                            <FormattedMessage id="campaign.time.frame.end" defaultMessage="END" />
                                        }
                                        id="schedulerDayTime-date-input-end"
                                        isOutsideRange={outsideRangeHandle}
                                    />
                                </td>
                                <TimeSelector
                                    date={adSchedulerEnd}
                                    onChangeDate={onChangeEndDate}
                                    initialDate={endDate}
                                />
                            </tr>
                        </tbody>
                    </table>
                </WithMessage>
            </div>
        </div>
    );
};
