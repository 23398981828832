import { requestGetGraphData } from '../actions';
import clearGraphResources from './clearGraphResources';
import { getGraphData } from './getGraphData';
import getGraphDataError from './getGraphDataError';
import getGraphDataSuccess from './getGraphDataSuccess';
import getReport from './getReport';

export const getReportAndGraph = params => async dispatch => {
    const { graphConfig: graph = {}, locale, currency, graphEdgesState, setGraphEdgesState, startDate } = params;

    if (!graph?.endpoint) {
        dispatch(getReport(params));
        return;
    }

    const {
        shouldUseGridDataSrc,
        maxXAxisValues,
        displayTotalsInLegendRow = true,
        getGridDataDependentFilters,
    } = graph;

    if (!!getGridDataDependentFilters) {
        dispatch(clearGraphResources());
        dispatch(requestGetGraphData());
        await dispatch(getReport(params));
        dispatch(getGraphData(params));
        return;
    }

    if (!shouldUseGridDataSrc) {
        dispatch(getReport(params));
        dispatch(getGraphData(params));
        return;
    }

    dispatch(clearGraphResources());
    dispatch(requestGetGraphData());
    const { data, error } = await dispatch(getReport(params));

    if (data) {
        dispatch(
            getGraphDataSuccess({
                graphConfig: graph,
                graphResponse: data,
                graphEdgesState,
                setGraphEdgesState,
                locale,
                currency,
                options: { maxXAxisValues, displayTotalsInLegendRow },
                startDate,
            })
        );
    } else if (error) {
        dispatch(getGraphDataError(error));
    }
};
