import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations/flags';

export const dcoInTrialVisibilityCondition = ({ accountConfig, selectedCampaign, permissionsMap }) => {
    const isDcoAccount = accountConfig?.[FEATURE_FLAGS.IS_DCO_ACCOUNT] === 'true';
    const inTrial =
        selectedCampaign?.id === -1
            ? accountConfig?.[FEATURE_FLAGS.DCO_ACCOUNT_HAS_IN_TRIAL_CAMPAIGNS] === 'true'
            : selectedCampaign.dcoProperties?.inTrial;
    const shouldHideForUser = !permissionsMap?.['ADS_CONSOLE_DCO_SHOW_SPEND_COLUMNS'];

    return !isDcoAccount || !inTrial || !shouldHideForUser;
};
