import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useAccountConfig,
    useCommonConfig,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';

export const useBidModificationConfig = () => {
    const isInternalEditor = usePermissions('PUBLISHER_BID_INTERNAL');
    const { [FEATURE_FLAGS.CPC_MIN_VALUE]: minBid } = useAccountConfig(FEATURE_FLAGS.CPC_MIN_VALUE);

    const [minDefaultBoost, maxDefaultBoost, minInternalBoost, maxInternalBoost] = Object.values(
        useCommonConfig([
            COMMON_FLAGS.BID_MODIFICATION_BOOST_MIN_DEFAULT,
            COMMON_FLAGS.BID_MODIFICATION_BOOST_MAX_DEFAULT,
            COMMON_FLAGS.BID_MODIFICATION_BOOST_MIN_INTERNAL,
            COMMON_FLAGS.BID_MODIFICATION_BOOST_MAX_INTERNAL,
        ])
    );

    const minBoost = parseFloat(isInternalEditor ? minInternalBoost : minDefaultBoost);
    const maxBoost = parseFloat(isInternalEditor ? maxInternalBoost : maxDefaultBoost);

    return { maxBoost, minBoost, minBid: parseFloat(minBid) };
};
