"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEye(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M12.443 9.016c1.592.16 2.822 1.54 2.822 3.203 0 1.769-1.393 3.219-3.132 3.219-1.74 0-3.133-1.45-3.133-3.22 0-1.741 1.351-3.174 3.053-3.217L11.918 9c-1.935 0-3.49.574-4.622 1.533C6.494 11.21 6 12.045 6 12.353c0 1.168 2.469 3.14 5.918 3.14 1.933 0 3.552-.578 4.786-1.538.475-.37.863-.777 1.13-1.162.178-.258.266-.463.266-.44 0 .013-.094-.214-.278-.492a5.775 5.775 0 0 0-1.14-1.235c-1.12-.922-2.55-1.51-4.239-1.61zm7.657 3.336c0 2.198-3.783 5.14-8.182 5.14C7.45 17.493 4 14.738 4 12.353 4 9.925 7.46 7 11.918 7c4.392 0 8.182 3.116 8.182 5.352zm-7.967 1.085c.616 0 1.132-.536 1.132-1.218 0-.682-.516-1.219-1.132-1.219-.617 0-1.133.537-1.133 1.219s.516 1.219 1.133 1.219z"
        })
    );
}
exports.default = SvgEye;