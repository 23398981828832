import { set } from 'lodash';
import config from 'modules/taboola-common-frontend-modules/authentication/config';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { IS_DEBUG_MODE } from 'services/isDebugMode';

export const throwCriticalError = error => {
    if (config.getCrashClientOnFailure() || IS_DEBUG_MODE()) {
        set(error, 'fatal', true);
        setTimeout(() => {
            throw error;
        });
        throw error;
    }

    gtmTracker.trackError(error);
};
