import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import uuid from 'uuid/v1';
import { VTA_LOOKBACK_WINDOW_INPUT_TYPE } from 'modules/tracking/components/VtaLookBackWindowField/VtaLookbackWindowInputType';
import { AGGREGATION_TYPE } from 'modules/tracking/config/aggregationTypeOptions';
import { PRICING_MODEL } from '../../../../config';
import { getEmptyBudgetObject } from '../../components/Budget/const/emptyBudgetObject';
import { getScheduleDayPartingDefault } from '../../components/CampaignScheduler/consts/campaignSchedulerDayTimeDefaultValue';
import { getDefaultBidDayParting } from '../../components/DayTimeBidBoost/consts/bidModificationDayTimeDefaultValue';
import { DAY_TIME_BID_OPTION } from '../../components/DayTimeBidBoost/consts/dayTimeBidOptions';
import { TIME_ZONE_METHODS } from '../../components/DayTimeParting/consts/timeZoneOptions';
import DAYPARTING_DEFAULT from '../../components/Dayparting/config/daypartingDefault';
import { FUNNEL_MODE } from '../../components/Funnel/consts/FunnelModes';
import { PLATFORM_PARTING_DEFAULT } from '../../components/PlatformBidBoost/consts/bidModificationDeviceDefaultValues';
import { PLATFORM_BID_OPTION } from '../../components/PlatformBidBoost/consts/platformBidOptions';
import { SUPPLY_TARGETING_TYPES } from '../../components/SupplyTargeting/const';
import CONVERSION_RULE_MODES from '../ConversionRuleModes';
import TARGETING_TYPES from '../TargetingTypes';
import BID_STRATEGIES from '../bidStrategies';
import { BUDGET_TYPES } from '../budgetTypes';
import COUNTRY_TARGETING_LEVELS from '../countryTargetingLevels';
import CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES from '../creativeTrafficAllocationMethodTypes';
import { FREQUENCY } from '../frequency';
import { NO_HIP_TARGETING_ID } from '../highImpactPackageOptions';
import RUNTIME_TYPES from '../runtimeTypes';
import { SPENDING_LIMITS } from '../spendingLimits';

const defaultTrackingCode = 'utm_source=taboola&utm_medium=referral';

export const campaignInitialValues = {
    campaignGroupId: ({ initialCampaignGroupId }) => initialCampaignGroupId,
    startDate: ({ campaignGroupStartDate }) => (campaignGroupStartDate ? moment(campaignGroupStartDate) : moment()),
    budget: ({ isNoneSpendingLimitDisplayed, campaignGroup }) => {
        if (campaignGroup?.budget) {
            const initialBudget = cloneDeep(campaignGroup?.budget);
            return initialBudget;
        }
        if (campaignGroup?.budget?.type === BUDGET_TYPES.UNLIMITED) {
            return getEmptyBudgetObject({ type: BUDGET_TYPES.DAILY, isNoneSpendingLimitDisplayed });
        }
        return {
            type: BUDGET_TYPES.DAILY,
            value: null,
            spendingLimit: {
                type: isNoneSpendingLimitDisplayed ? SPENDING_LIMITS.NONE : SPENDING_LIMITS.MONTHLY,
                value: null,
            },
        };
    },
    premiumSiteTargeting: TARGETING_TYPES.ALL,
    brandSafetyTargeting: TARGETING_TYPES.ALL,
    campaignSchedule: {
        mode: RUNTIME_TYPES.ALWAYS,
        rules: ({ isDayPartingPermitted }) =>
            isDayPartingPermitted ? [getScheduleDayPartingDefault()] : [...DAYPARTING_DEFAULT],
        timeZone: ({ timeZoneEnabled, account }) =>
            timeZoneEnabled ? account?.timeZoneName : TIME_ZONE_METHODS.USER_TIME_ZONE,
        isMultipleSlicesPerDay: ({ isDayPartingPermitted }) => isDayPartingPermitted,
    },
    conversionRulesMode: ({ isCampaignConversionRulesEnabled }) =>
        isCampaignConversionRulesEnabled ? CONVERSION_RULE_MODES.CUSTOM : CONVERSION_RULE_MODES.DEFAULT,
    conversionRules: { rules: [] },
    campaignTargeting: {
        connectionTypeTargeting: {
            type: TARGETING_TYPES.ALL,
            values: [],
        },
        osTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        geoTargeting: {
            countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
            countryTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            regionTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            DMATargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            cityTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            postalCodeTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
        },
        marketplaceAudienceTargeting: {
            collection: [{ type: TARGETING_TYPES.INCLUDE, values: [], id: uuid() }],
            validTargeting: true,
        },
        contextualSegmentsTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        customContextualTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        myAudienceTargeting: {
            include: [],
            exclude: ({ defaultExcludedListOfMyAudience }) => defaultExcludedListOfMyAudience,
            validTargeting: true,
        },
        highImpactPackageTargetingId: NO_HIP_TARGETING_ID,
        browserTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        userFrequencyCapping: {
            frequency: FREQUENCY.NONE,
            userImpressionsAmount: null,
        },
        supplyTargeting: ({ isSupplyPermitted, isTaboolaDefaultValue, isPermitted }) => {
            if (!isPermitted) {
                return null;
            }
            if (isSupplyPermitted) {
                return isTaboolaDefaultValue ? SUPPLY_TARGETING_TYPES.TABOOLA_SELECT : SUPPLY_TARGETING_TYPES.YAHOO;
            }
            return SUPPLY_TARGETING_TYPES.ALL;
        },
    },
    campaignProfile: {
        language: 'EN',
    },
    thirdPartyTags: [],
    pricingModel: PRICING_MODEL.CPC,
    finance: {
        profitMargin: null,
    },
    trackingCode: defaultTrackingCode,
    bidStrategy: ({ isMaxConversionsEnabled }) =>
        isMaxConversionsEnabled ? BID_STRATEGIES.MAX_CONVERSIONS : BID_STRATEGIES.SMART,
    creativeTrafficAllocationMethod: CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED,
    creativeTrafficAllocationABTestEndDate: null,
    thirdPartyBrandSafetyTargeting: {
        type: ({ defaultThirdPartyBrandSafetyType }) => defaultThirdPartyBrandSafetyType,
        values: [],
    },
    platformTargeting: [],
    isActive: true,
    dayTimeBidModifier: {
        mode: DAY_TIME_BID_OPTION.NONE,
        values: [getDefaultBidDayParting()],
        timeZone: ({ timeZoneEnabled, account }) => (timeZoneEnabled ? account?.timeZoneName : null),
    },
    platformBidModifier: {
        mode: PLATFORM_BID_OPTION.NONE,
        values: [...PLATFORM_PARTING_DEFAULT],
    },
    conversionConfiguration: {
        lookBackWindow: 7,
        vtaLookBackWindow: {
            type: ({ shouldReturnDaysType }) =>
                shouldReturnDaysType ? VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS : VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS,
            value: ({ value }) => value,
        },
        aggregationType: AGGREGATION_TYPE.AGGREGATED,
    },
    funnel: {
        mode: FUNNEL_MODE.DEFAULT,
    },
    isSpendGuardActive: 'ACTIVE',
    performanceRuleIds: [],
};

export default campaignInitialValues;
