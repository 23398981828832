import React, { useEffect } from 'react';
import { Input, InputTypes, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { useAvailableItems, useDimension, useEventValueHandler } from 'hooks';
import { FREQUENCY } from 'modules/campaigns/modules/common-campaign-form/config';
import { withIndication } from 'modules/errors';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { isSponsoredCampaign } from 'services/campaignDimension';
import styles from './UserFrequencyCapping.module.scss';

export const FREQUENCY_VALUES = [
    {
        value: FREQUENCY.NONE,
        title: <FormattedMessage id="video.campaign.editor.UserFrequencyCapping.option.none" defaultMessage="None" />,
        visibilityCondition: data => isSponsoredCampaign(data.selectedCampaignDimension),
    },
    {
        value: FREQUENCY.DAY,
        title: <FormattedMessage id="video.campaign.editor.UserFrequencyCapping.option.day" defaultMessage="Day" />,
        visibilityCondition: () => true,
    },
    {
        value: FREQUENCY.WEEK,
        title: <FormattedMessage id="video.campaign.editor.UserFrequencyCapping.option.week" defaultMessage="Week" />,
        visibilityCondition: () => true,
    },
    {
        value: FREQUENCY.MONTH,
        title: <FormattedMessage id="video.campaign.editor.UserFrequencyCapping.option.month" defaultMessage="Month" />,
        visibilityCondition: () => true,
    },
];

const validations = [
    {
        validationFn: (value, options, { withFrequencyCapping }) =>
            !withFrequencyCapping || validationFunctions.isInt(value, options),
        messageId: 'video.campaign.editor.UserFrequencyCapping.validations.error.whole.number',
        defaultMessage: 'User Frequency Capping must be a whole number',
    },
    {
        validationFn: (value, options, { withFrequencyCapping }) =>
            !withFrequencyCapping || validationFunctions.isInt(value, options),
        options: { min: 0 },
        messageId: 'video.campaign.editor.UserFrequencyCapping.validations.error.negative.number',
        defaultMessage: 'Enter specific user frequency targeting',
    },
];

const InputWithIndications = withIndication(Input);

export const UserFrequencyCapping = () => {
    const [selectedCampaignDimension] = useDimension();
    const availableItems = useAvailableItems(FREQUENCY_VALUES, { selectedCampaignDimension });
    const { value: selectedUserFrequencyCapping, onChange: onSelectedUserFrequencyCappingChange } = useFormFieldValue({
        field: 'campaignTargeting.userFrequencyCapping.frequency',
    });
    const withFrequencyCapping = selectedUserFrequencyCapping !== FREQUENCY.NONE;

    const {
        value: userImpressionsAmount,
        onChange: setUserImpressionsAmount,
        indicationData,
    } = useFormValidatedValue({
        field: 'campaignTargeting.userFrequencyCapping.userImpressionsAmount',
        validations,
        validationDependencies: {
            withFrequencyCapping,
        },
    });

    useEffect(() => {
        if (!withFrequencyCapping) {
            setUserImpressionsAmount(null);
        }
    }, [withFrequencyCapping, setUserImpressionsAmount]);

    return (
        <div className="frequency-capping-options">
            <RadioGroup
                id="frequency-capping"
                selectedValue={selectedUserFrequencyCapping}
                onChange={onSelectedUserFrequencyCappingChange}
                className={styles['radio-group']}
            >
                {availableItems.map(({ value, title }) => (
                    <Radio labelClassName={styles['radio-label']} key={value} value={value} title={title} />
                ))}
            </RadioGroup>

            <InputWithIndications
                id="campaignTargeting.userFrequencyCapping.frequency"
                type={InputTypes.NUMBER}
                value={userImpressionsAmount}
                onChange={useEventValueHandler(setUserImpressionsAmount)}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="UserFrequencyCapping"
                inputWrapperClass={styles['inline']}
                mainClass={styles['inline']}
                disabled={!withFrequencyCapping}
                {...indicationData}
            />
        </div>
    );
};
