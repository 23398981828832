import React from 'react';
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const useTypeSubtext = (type, configs, msgPrefix) => {
    const [hovered, setHovered] = useState();
    const modeKey = hovered || type;

    const subtext = useMemo(
        () =>
            modeKey ? (
                <FormattedMessage id={`${msgPrefix}.${modeKey}.subtext`} defaultMessage={configs[modeKey]?.subtext} />
            ) : null,
        [modeKey, configs, msgPrefix]
    );

    return {
        subtext,
        setHovered,
    };
};
