import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from '../trackingEmptyState.module.scss';

export const ActiveEventsEmptyStateMessages = () => {
    return (
        <div className={styles['header']}>
            <FormattedMessage
                className={styles['header']}
                id="tracking.active.events.report.not.installed.title"
                defaultMessage="No Active Events"
            />
        </div>
    );
};
