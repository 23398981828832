import { Redirect, Route, Switch } from 'react-router';
import { OnboardingChatForm } from './OnboardingChatForm/OnboardingChatForm';
import { OnboardingJourneyForm } from './OnboardingJourneyForm/OnboardingJourneyForm';
import { ONBOARDING_FORM_PATH, ONBOARDING_JOURNEY_PATH, ONBOARDING_FORM_REDIRECT_PATH } from './config/routeTypes';

export const Onboarding = () => (
    <Switch>
        <Route path={ONBOARDING_FORM_PATH} exact render={() => <OnboardingChatForm />} />
        <Route path={ONBOARDING_JOURNEY_PATH} exact render={() => <OnboardingJourneyForm />} />
        <Redirect to={ONBOARDING_FORM_REDIRECT_PATH} />
    </Switch>
);
