import moment from 'moment-timezone';
import uuid from 'uuid/v1';
import DAYPARTING_DEFAULT from '../../components/Dayparting/config/daypartingDefault';
import { SUPPLY_TARGETING_TYPES } from '../../components/SupplyTargeting/const';
import TARGETING_TYPES from '../TargetingTypes';
import BID_STRATEGIES from '../bidStrategies';
import COUNTRY_TARGETING_LEVELS from '../countryTargetingLevels';
import CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES from '../creativeTrafficAllocationMethodTypes';
import { DELIVERY_METHODS } from '../deliveryMethods';
import { FREQUENCY } from '../frequency';
import MOAT_BILLING_OPTIONS from '../moatBillingOptions';
import { PLATFORMS_KEYS } from '../platforms';
import RUNTIME_TYPES from '../runtimeTypes';
import VIDEO_PRICING_MODEL from '../videoPricingModel';

const defaultTrackingCode = 'utm_source=taboola&utm_medium=referral';

export const videoCampaignInitialValues = {
    startDate: () => moment(),
    premiumSiteTargeting: TARGETING_TYPES.ALL,
    brandSafetyTargeting: TARGETING_TYPES.ALL,
    campaignSchedule: {
        mode: RUNTIME_TYPES.ALWAYS,
        rules: [...DAYPARTING_DEFAULT],
        timeZone: dependencies => dependencies?.account?.timeZoneName,
    },
    campaignTargeting: {
        osTargeting: { type: TARGETING_TYPES.INCLUDE, values: [] },
        geoTargeting: {
            countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
            countryTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            postalCodeTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
        },
        marketplaceAudienceTargeting: {
            collection: [{ type: TARGETING_TYPES.INCLUDE, values: [], id: uuid() }],
            validTargeting: true,
        },
        contextualSegmentsTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        myAudienceTargeting: {
            include: [],
            exclude: ({ defaultExcludedListOfMyAudience }) => defaultExcludedListOfMyAudience,
            validTargeting: true,
        },
        customContextualTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        languageTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        userFrequencyCapping: {
            frequency: FREQUENCY.DAY,
            userImpressionsAmount: 5,
        },
        adjacencyControl: [],
        highImpactPackage: [],
        publisherDomainTargeting: { type: TARGETING_TYPES.INCLUDE, values: [] },
        urlKeywordTargeting: { type: TARGETING_TYPES.INCLUDE, values: [] },
        appTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        adUnitTypeTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        presets: [],
        browserTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
        supplyTargeting: SUPPLY_TARGETING_TYPES.ALL,
    },
    videoBudget: {
        moatBilling: [MOAT_BILLING_OPTIONS.MOAT_BILLING],
        deliveryMethod: DELIVERY_METHODS.BALANCED,
        businessModel: VIDEO_PRICING_MODEL.CPCV,
        rate: '',
        budget: '',
        dailyRevenueCap: '',
    },
    campaignProfile: {
        language: 'EN',
    },
    thirdPartyTags: [],
    trackingCode: defaultTrackingCode,
    bidStrategy: BID_STRATEGIES.SMART,
    creativeTrafficAllocationMethod: CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED,
    thirdPartyBrandSafetyTargeting: {
        type: ({ defaultThirdPartyBrandSafetyType }) => defaultThirdPartyBrandSafetyType,
        values: [],
    },
    platformTargeting: PLATFORMS_KEYS,
};
