import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTestValue } from 'modules/taboola-common-frontend-modules/ab-test';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { VersionConfigContext } from 'modules/taboola-common-frontend-modules/version/hooks/useAppVersion';

export const FORCE_VERSION_PARAM = 'forceVersion';

const useCurrentForceVersion = () => {
    const VersionsConfig = useContext(VersionConfigContext);
    const assetsVersion = VersionsConfig.assetsVersion;
    const [get, set, isReady] = useLocalStorage();

    const value = useMemo(() => {
        if (!isReady) {
            return;
        }

        return get(FORCE_VERSION_PARAM);
    }, [get, isReady]);

    const setValueWrapper = useCallback(
        value => {
            set(FORCE_VERSION_PARAM, value);
            VersionsConfig.setAssetsVersion(value);
        },
        [VersionsConfig, set]
    );

    return [value ?? assetsVersion, setValueWrapper, isReady];
};

export const useHugeChangesVersion = () => {
    const [currentForceVersion, setCurrentForceVersion, forceVersionReady] = useCurrentForceVersion();
    const { value: newABTVersion } = useTestValue({ key: FORCE_VERSION_PARAM, fallback: currentForceVersion });

    useEffect(() => {
        if (!forceVersionReady) {
            return;
        }

        if (newABTVersion === currentForceVersion) {
            return;
        }

        setCurrentForceVersion(newABTVersion);
        window.location.reload();
    }, [newABTVersion, currentForceVersion, setCurrentForceVersion, forceVersionReady]);
};
