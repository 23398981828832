import { get, mapValues, size } from 'lodash';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { operationsApi } from 'services/api';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';
import { calcDurationInSeconds } from '../utils/bulkUploadGtmUtils';

export const buildMediaMap = async ({ mediaFile, accountName, mediaZip }) => {
    if (!mediaFile) {
        return {};
    }

    const uploadZipTimeStart = Date.now();

    const apiRes = await operationsApi.uploadZip(accountName, mediaFile);
    const apiResults = get(apiRes, 'results', {});

    /* 
        flattens entries to just contain {
            value: URL if valid, null otherwise
            error: { httpStatus, message } if invalid, null otherwise
        }

        e.g { value: 'test.jpg', error: null } or { value: null, error: ".txt is not supported" }
    */
    const result = mapValues(apiResults, ({ value, error }) => ({
        value: get(value, 'value'),
        error,
    }));

    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: `${BULK_UPLOAD_GTM_PREFIX}_Zip: Upload Duration (seconds)`,
        value: calcDurationInSeconds(uploadZipTimeStart),
        additionalEventInfo: size(mediaZip?.files),
    });

    return result;
};
