import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './allCampaignsContentLoader.module.scss';

const AllCampaignsContentLoader = () => (
    <ContentLoader height={40} width={300} speed={2} className={styles['content']}>
        <rect x="15" y="10" rx="9" ry="9" width="210" height="18" />
    </ContentLoader>
);

export default AllCampaignsContentLoader;
