import React from 'react';
import PropTypes from 'prop-types';
import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import CollapsiblePickerSortTypeToggle from './CollapsiblePickerSortTypeToggle';
import styles from './collapsiblePickerSortDropdownMenu.module.scss';

const CollapsiblePickerSortDropdownMenu = ({ selectProps, children }) => {
    const { sortType, onSelectSortType, sortFooter } = selectProps;

    return (
        <div className={styles['container']}>
            <CollapsiblePickerSortTypeToggle sortType={sortType} onSelectSortType={onSelectSortType} />
            {children}
            {sortFooter}
        </div>
    );
};

CollapsiblePickerSortDropdownMenu.propTypes = {
    selectProps: PropTypes.shape({
        sortType: PropTypes.oneOf(Object.values(SORTING_ORDER_TYPES)),
        onSelectSortType: PropTypes.func,
        sortFooter: PropTypes.node,
    }),
    children: PropTypes.node,
};

export default CollapsiblePickerSortDropdownMenu;
