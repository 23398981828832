'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _SmartList = require('../SmartList');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /**
                                                                                                                                                                                                                              * Created by itamar.c
                                                                                                                                                                                                                              * Date: 2019-29-12
                                                                                                                                                                                                                              */

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dropdownGroupSmartList__taboola-svg-icon___2Vale',
    'container': 'dropdownGroupSmartList__container___3aCmn',
    'list-header-title': 'dropdownGroupSmartList__list-header-title___3aXzC',
    'warning': 'dropdownGroupSmartList__warning___wVAF0',
    'item': 'dropdownGroupSmartList__item___2QeHz',
    'wo-header': 'dropdownGroupSmartList__wo-header___s3jQ9'
};


var classNameBuilder = _bind2.default.bind(styles);

function DefaultContainer(_ref) {
    var children = _ref.children;

    return _react2.default.createElement(
        'div',
        { className: styles['container'] },
        children
    );
}

DefaultContainer.propTypes = {
    children: _propTypes2.default.node
};

function DropdownGroupSmartList(_ref2) {
    var listHeaderTitle = _ref2.listHeaderTitle,
        listHeaderClass = _ref2.listHeaderClass,
        PropsContainer = _ref2.inputContainer,
        listOnly = _ref2.listOnly,
        children = _ref2.children,
        rest = _objectWithoutProperties(_ref2, ['listHeaderTitle', 'listHeaderClass', 'inputContainer', 'listOnly', 'children']);

    var indicationType = rest.indicationType;

    var listHeaderClassName = classNameBuilder(listHeaderClass || 'list-header-title', _defineProperty({}, indicationType, indicationType));
    var itemsClassName = classNameBuilder('item', _defineProperty({ 'wo-header': !listHeaderTitle }, indicationType, indicationType));
    var Container = PropsContainer || DefaultContainer;

    var smartListSiblings = listOnly ? null : _react2.default.createElement(
        Container,
        null,
        children
    );

    return _react2.default.createElement(
        _SmartList.SmartList,
        Object.assign({
            listHeaderTitle: listHeaderTitle,
            listHeaderClass: listHeaderClassName
        }, rest),
        smartListSiblings,
        _react2.default.createElement(_SmartList.SmartListItemsContainer, { className: itemsClassName })
    );
}

DropdownGroupSmartList.propTypes = {
    /** Boolean flag that forces only the list to render */
    listOnly: _propTypes2.default.bool,
    /** The children of the container */
    children: _propTypes2.default.node,
    /** List header title */
    listHeaderTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** List header class */
    listHeaderClass: _propTypes2.default.string,
    /** override for list input container */
    inputContainer: _propTypes2.default.elementType
};

exports.default = DropdownGroupSmartList;