import React, { useEffect, useMemo, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
    addStaticIndication,
    INDICATION_TYPES,
    STATIC_INDICATION_TYPES,
} from 'modules/taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { getLocationSearch, setQueryParam } from 'modules/taboola-common-frontend-modules/query-params';
import { config as VersionsConfig } from '../config';

export const VersionConfigContext = createContext(VersionsConfig);
export const LocationContext = createContext(window.location);

const ReloadLink = () => {
    const location = useContext(LocationContext);

    const href = useMemo(() => {
        const url = new URL(location.href);
        url.searchParams.set('cb', Date.now());
        return url;
    }, [location]);

    const onClick = e => {
        e.preventDefault();
        const newUrl = setQueryParam(getLocationSearch(), 'cb', Date.now());
        location.search = newUrl;
    };

    return (
        <a href={href} onClick={onClick}>
            <FormattedMessage id="app.version.reload.message" defaultMessage="Update" />
        </a>
    );
};

const getIncorrectVersionIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA,
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.version.update.message"
            defaultMessage="A new version of the application is available. Please update to the latest version."
        />
    ),
    message: <ReloadLink />,
});

export const useAppVersion = () => {
    const shouldShowOutdatedApiNotice = useConfigMatch({ [FEATURE_FLAGS.SHOW_OUTDATED_API_NOTICE]: 'true' });
    const VersionsConfig = useContext(VersionConfigContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!shouldShowOutdatedApiNotice) {
            return;
        }
        return VersionsConfig.onVersionUpdated(() => {
            dispatch(addStaticIndication(getIncorrectVersionIndication()));
        });
    }, [dispatch, VersionsConfig, shouldShowOutdatedApiNotice]);
};
