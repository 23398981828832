import React from 'react';
import { noop } from 'lodash';
import { useAvailableItems } from 'hooks';
import { BaseGalleryItemDropdown } from '../../GalleryItemDropdown/BaseGalleryItemDropdown';
import { GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';
import { useStudioGalleryItemDropdown } from '../StudioGalleryItemDropdown/useStudioGalleryItemDropdown';
import {
    CREATIVE_STUDIO_EVENT_PREFIX,
    CREATIVE_STUDIO_MODE_EVENTS_MAP,
    SEGMENT_ANYTHING_EVENT_PREFIX,
} from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';

const dropdownMenuStyle = { right: 0, left: 'unset' };
const dropdownOptionsList = [GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.SAVE_TO_MEDIA_LIBRARY];

export const SegmentAnythingGalleryItemDropdown = ({ ...rest }) => {
    const { campaignId, mode } = useCreativeStudioContext();
    const SEGMENT_EVENTS_PREFIX = `${CREATIVE_STUDIO_EVENT_PREFIX}_${CREATIVE_STUDIO_MODE_EVENTS_MAP[mode]}_${SEGMENT_ANYTHING_EVENT_PREFIX}`;

    const { onClick } = useStudioGalleryItemDropdown({
        campaignId,
        eventsLocationPrefix: SEGMENT_EVENTS_PREFIX,
        onSelectMedia: noop,
    });
    const options = useAvailableItems(dropdownOptionsList, { isImage: true });

    return (
        <BaseGalleryItemDropdown
            dropdownMenuStyle={dropdownMenuStyle}
            campaignId={campaignId}
            onClick={onClick}
            options={options}
            eventsLocationPrefix={SEGMENT_EVENTS_PREFIX}
            {...rest}
        />
    );
};
