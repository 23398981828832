import { useMemo } from 'react';
import { get, keyBy, mapValues, isArray } from 'lodash';
import { configKeyTransformer } from '../services/transformers';
import { getConfigKeyData } from '../utils/getConfigKeyData';
import { useAccount } from './useAccount';
import { useAccountFullConfigData } from './useAccountFullConfigData';

const emptyArr = [];
const useConfig = (attribute = emptyArr, defaultValues, configName) => {
    const configs = useAccountFullConfigData();
    const account = useAccount();

    const configSubset = useMemo(() => {
        const attributes = keyBy(isArray(attribute) ? attribute : [attribute], atr => atr);
        const configKeyData = getConfigKeyData(account);

        return mapValues(attributes, key => {
            const attributeName = configKeyTransformer(key, configKeyData);
            const attributeValue = get(configs[configName], attributeName, defaultValues[attributeName]);

            return attributeValue;
        });
    }, [attribute, account, configs, configName, defaultValues]);

    return configSubset;
};

export default useConfig;
