import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

const CampaignsSectionTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="creative.editor.duplicate.section.campaigns.tooltip.header"
                defaultMessage="Duplicate this ad into any selected campaigns."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="creative.editor.duplicate.section.campaigns.tooltip.body"
                defaultMessage="When duplicating ads between campaigns we will look at the initial campaign settings and labeling, in case they do not match the ad might be rejected. Make sure the selected campaign has the same targeting and context to keep the ad in approval status."
            />
        </TooltipSection>
    </>
);

export default CampaignsSectionTooltip;
