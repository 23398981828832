import React from 'react';
import { useSelector } from 'react-redux';
import { useAvailableItems } from 'hooks';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { selectedCampaignSelector } from 'modules/campaigns/selectors';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import RadioGroupCellEditor from '../RadioGroupCellEditor';
import { useBidStrategyMessage } from './useBidStrategyMessage';

const bidStrategyOptions = [
    {
        radioValue: BID_STRATEGIES.SMART,
    },
    {
        radioValue: BID_STRATEGIES.FIXED,
    },
    {
        radioValue: BID_STRATEGIES.TARGET_CPA,
        accountConfigurations: { [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' },
        visibilityCondition: ({ bidStrategy }) => bidStrategy === BID_STRATEGIES.TARGET_CPA,
    },
];

const getValueView = value => value || BID_STRATEGIES.SMART;

const BidStrategyCellEditor = props => {
    const { formatMessage } = useIntl();
    const selectedCampaign = useSelector(selectedCampaignSelector);
    const options = useAvailableItems(bidStrategyOptions, {
        bidStrategy: selectedCampaign.bidStrategy,
    });
    const { getMessageIdPrefix } = useBidStrategyMessage();
    const optionsWithTitle = options.map(option => ({
        ...option,
        title: formatMessage({ id: `${getMessageIdPrefix(option.radioValue)}.${option.radioValue}` }),
    }));

    return (
        <RadioGroupCellEditor
            radioOptions={optionsWithTitle}
            name="bid-strategy"
            {...props}
            getValueView={getValueView}
        />
    );
};

export const BidStrategyCellEditorWithFormData = withGridCellFormDataProvider(BidStrategyCellEditor);
