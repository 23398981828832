const thirdPartyTagPrefix = accountId => `/${accountId}/third-party-tags`;

export const thirdPartyTagsApiFactory = ({ callPostApi }) => ({
    replaceMacros: (accountId, tagValue, autoFix = 'false') => {
        return callPostApi(
            `${thirdPartyTagPrefix(accountId)}/macro-replacement/replace?autoFix=${autoFix}`,
            tagValue,
            {},
            true
        );
    },
});
