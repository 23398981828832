import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import {
    Divider,
    DropdownButton,
    DropdownMenu,
    STYLED_BUTTON_SIZE,
    StyledButton,
    withStylesheet,
} from 'taboola-ultimate-ui';
import { navigate } from 'actions';
import { useDropdownOptions, useModuleName } from 'hooks';
import { INITIAL_FORM_ACCOUNT_NAME } from 'hooks/queryParams/useInitialFormAccountName';
import useAvailableItems from 'hooks/useAvailableItems';
import CreateAudienceButton from 'modules/audiences/components/CreateAudienceButton/CreateAudienceButton';
import { MODULE_NAME as AUDIENCES_MODULE_NAME } from 'modules/audiences/config/routes';
import {
    generateBulkCampaignCreatorPath,
    generateCampaignCreatorPath,
    generateCampaignsGroupCreatorPath,
    generateCreativeCreatorPath,
    generateVideoPMPCreatorPath,
} from 'modules/campaigns/services/utils';
import { CreatePerformanceRulesButton } from 'modules/performance-rules/components/CreatePerformanceRulesButton/CreatePerformanceRulesButton';
import { MODULE_NAME as PERFORMANCE_RULES_MODULE } from 'modules/performance-rules/config/routes';
import { MODULE_NAME as SCHEDULED_REPORTS_MODULE } from 'modules/scheduled-reports/config/routes';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccountFullConfigData } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountFullConfigData';
import { PermissionsGuard } from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { CreateConversionButton } from 'modules/tracking/components/CreateConversionButton/CreateConversionButton';
import { MODULE_NAME as TRACKING_MODULE_NAME } from 'modules/tracking/config/routes';
import { dimensionSelector, selectedAccountSelector } from 'selectors';
import { isSponsoredCampaign, isVideoCampaign } from 'services/campaignDimension';
import {
    isNetworkAccountType,
    isOpenExchangeAccount,
    isPmpAccount,
    isProgrammaticAccount,
} from '../../../../../../account-management/accountType';
import { CreateScheduledReportsButton } from '../../../../../../scheduled-reports/CreateButton/CreateScheduledReportsButton';
import { ENTITY, MODULE_NAME as CAMPAIGNS_MODULE_NAME } from '../../../../../config';
import DEMAND_TYPE from '../../../../../constants/demandType';
import { useSelectedCampaign } from '../../../../../hooks/useSelectedCampaign';
import styles from '../gridToolbar.module.scss';

const isFeatureFlagEnabled = (accountConfig, featureFlagEnabled) => accountConfig?.[featureFlagEnabled] === 'true';

const optionsConfigurations = [
    {
        id: 'campaigns-group',
        value: 'campaigns-group',
        label: 'Campaign Group',
        path: generateCampaignsGroupCreatorPath(),
        dataMetricColumnName: 'Campaign Group',
        permissions: 'SHARED_BUDGET_EDIT',
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension, selectedAccountConfig }) =>
            !isProgrammaticAccount(selectedAccount) &&
            !isNetworkAccountType(selectedAccount.type) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            !isVideoCampaign(selectedCampaignDimension) &&
            (selectedAccountConfig[FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED] === 'true' ||
                selectedAccountConfig[FEATURE_FLAGS.SHARED_BUDGET_UA_ENABLED] === 'true'),
    },
    {
        id: 'campaign',
        value: 'campaign',
        label: 'Campaign',
        path: generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN }),
        dataMetricColumnName: 'Campaign',
        permissions: ['CAMPAIGN_EDIT', 'CAMPAIGN_ADD'],
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            !isProgrammaticAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            isSponsoredCampaign(selectedCampaignDimension),
    },
    {
        id: 'creative',
        value: 'creative',
        label: 'Ads',
        path: generateCreativeCreatorPath({ entity: ENTITY.CREATIVE }),
        queryParamGetter: ({ selectedCampaign }) =>
            selectedCampaign ? { [INITIAL_FORM_ACCOUNT_NAME]: selectedCampaign.accountName } : {},
        dataMetricColumnName: 'Ads',
        permissions: ['CREATIVE_EDIT'],
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            !isProgrammaticAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            isSponsoredCampaign(selectedCampaignDimension),
    },
    {
        id: 'excel-bulk-upload',
        value: 'excel-bulk-upload',
        label: 'Bulk Upload',
        path: generateBulkCampaignCreatorPath({ entity: ENTITY.EXCEL_BULK }),
        dataMetricColumnName: 'Bulk Upload',
        permissions: ['CAMPAIGN_EDIT', 'CAMPAIGN_ADD'],
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension, accountConfig }) => {
            const isBulkUploadEnabled = isFeatureFlagEnabled(accountConfig, FEATURE_FLAGS.CAMPAIGN_BULK_UPLOAD);

            return (
                isBulkUploadEnabled &&
                !isProgrammaticAccount(selectedAccount) &&
                moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
                isSponsoredCampaign(selectedCampaignDimension)
            );
        },
        hasDividerBefore: true,
    },
    {
        id: 'video-campaign',
        value: 'video-campaign',
        label: 'Video Campaign',
        path: generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN_VIDEO }),
        dataMetricColumnName: 'Video Campaign',
        permissions: 'ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN',
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            !isProgrammaticAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            isVideoCampaign(selectedCampaignDimension),
    },
    {
        id: 'video-creative',
        value: 'video-creative',
        label: 'Video Ads',
        path: generateCreativeCreatorPath({ entity: ENTITY.CREATIVE_VIDEO }),
        dataMetricColumnName: 'Video Ads',
        permissions: 'ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN',
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            !isProgrammaticAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            isVideoCampaign(selectedCampaignDimension),
    },
    {
        id: DEMAND_TYPE.RTB_OPEN_EXCHANGE,
        value: 'open-exchange',
        label: 'Open Exchange Campaign',
        path: generateCampaignCreatorPath({ entity: ENTITY.RTB_OPEN_EXCHANGE }),
        dataMetricColumnName: 'Open Exchange Campaign',
        permissions: ['CAMPAIGN_EDIT', 'CAMPAIGN_ADD'],
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            isOpenExchangeAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            !isVideoCampaign(selectedCampaignDimension),
    },
    {
        id: DEMAND_TYPE.PMP_DEAL,
        value: 'pmp-deal',
        label: 'PMP Deal',
        path: generateCampaignCreatorPath({ entity: ENTITY.PMP_DEAL }),
        dataMetricColumnName: 'PMP Deal',
        permissions: ['PMP_DEAL_ADD'],
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            isPmpAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            !isVideoCampaign(selectedCampaignDimension),
    },
    {
        id: 'video-pmp-deal',
        value: 'video-pmp-deal',
        label: 'Video Campaign PMP',
        path: generateVideoPMPCreatorPath(),
        dataMetricColumnName: 'Video PMP Campaign',
        permissions: 'ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN',
        visibilityCondition: ({ selectedAccount, moduleName, selectedCampaignDimension }) =>
            isPmpAccount(selectedAccount) &&
            moduleName.includes(CAMPAIGNS_MODULE_NAME) &&
            isVideoCampaign(selectedCampaignDimension),
    },
];

const OptionWrapper = ({ children, dataMetricColumnName, permissions, hasDividerBefore }) => (
    <PermissionsGuard permissions={permissions}>
        <div data-metrics-column-name={dataMetricColumnName}>
            {hasDividerBefore && <Divider className={styles['divider']} />}
            {children}
        </div>
    </PermissionsGuard>
);

const StyledAddButton = withStylesheet(StyledButton, styles, {
    [StyledButton.classNames.iconBefore]: 'dropdown-button-icon',
});

const CreateButton = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const moduleName = useModuleName();
    const config = useAccountFullConfigData();
    const selectedAccount = useSelector(selectedAccountSelector);
    const { campaign: selectedCampaign } = useSelectedCampaign();
    const selectedCampaignDimension = useSelector(dimensionSelector);
    const { isActive: isActiveAccount } = selectedAccount;
    const isDisabled = useConfigMatch({ [FEATURE_FLAGS.DISABLE_CREATE_BUTTON]: 'true' });
    const rawOptions = useAvailableItems(optionsConfigurations, {
        selectedAccount,
        moduleName,
        selectedCampaignDimension,
        ...config,
    });
    const options = useDropdownOptions(rawOptions, 'app.gridToolbar');

    const handleOnChange = ({ path, queryParamGetter = () => ({}) }) => {
        const pathWithParams = mergeQueryParamsWithLocation({ pathname: path }, queryParamGetter({ selectedCampaign }));
        dispatch(navigate(history, pathWithParams));
    };

    if (moduleName.includes(AUDIENCES_MODULE_NAME)) {
        return <CreateAudienceButton />;
    }
    if (moduleName.includes(TRACKING_MODULE_NAME)) {
        return <CreateConversionButton />;
    }
    if (moduleName.includes(PERFORMANCE_RULES_MODULE)) {
        return <CreatePerformanceRulesButton />;
    }
    if (moduleName.includes(SCHEDULED_REPORTS_MODULE)) {
        return <CreateScheduledReportsButton />;
    }

    if (isEmpty(rawOptions) || !isActiveAccount) {
        return null;
    }

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="CreateButton"
            data-automation="CreateButton"
        >
            <DropdownButton
                ButtonComponent={StyledAddButton}
                buttonText={<FormattedMessage id="app.gridToolbar.create" defaultMessage="Create" />}
                onChange={handleOnChange}
                buttonSize={STYLED_BUTTON_SIZE.MEDIUM}
                options={options}
                disabled={isDisabled}
                optionWrapper={OptionWrapper}
                shouldStretchedByContent
                strechedMenuPosition={DropdownMenu.MENU_POSITION.RIGHT}
            />
        </div>
    );
};

export default CreateButton;
