import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import SegmentPickerItemTooltip from './SegmentPickerItemTooltip';
import styles from './segmentPickerListItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const SegmentPickerListItem = props => {
    const { item, isSelected, onSelect } = props;
    const { name, uddId } = item;

    return (
        <div
            className={classNameBuilder('list-item', { selected: isSelected })}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value="Single"
            onClick={() => onSelect(item)}
        >
            <div className={styles['item-content']}>
                {name}
                <SegmentPickerItemTooltip name={name} uddId={uddId} />
            </div>
        </div>
    );
};

SegmentPickerListItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        uddId: PropTypes.number,
    }),
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
};

export default SegmentPickerListItem;
