'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Button = undefined;

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _Icons = require('../../Icons/Icons');

var icons = _interopRequireWildcard(_Icons);

var _hoc = require('../../hoc');

var _BaseButton = require('../BaseButton/BaseButton');

var _BaseButton2 = _interopRequireDefault(_BaseButton);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

var styles = {
    'tbl-btn': 'button__tbl-btn___1gaZS'
};


var createIcon = function createIcon(icon, iconClassName) {
    if (!icon) {
        return null;
    }
    if ((0, _react.isValidElement)(icon) && iconClassName) {
        return (0, _react.cloneElement)(icon, {
            className: (0, _classnames2.default)(icon.props.className, iconClassName)
        });
    }
    if ((typeof icon === 'undefined' ? 'undefined' : _typeof(icon)) === 'object') {
        return icon;
    }
    var iconType = icon.split('-').map(function (word) {
        var _word$split = word.split(''),
            _word$split2 = _toArray(_word$split),
            letter = _word$split2[0],
            rest = _word$split2.slice(1);

        return [letter.toUpperCase()].concat(_toConsumableArray(rest)).join('');
    }).join('');
    var IconGraphic = icons[iconType + 'Icon'];

    return _react2.default.createElement(IconGraphic, { 'aria-label': icon, className: iconClassName });
};

var Button = function Button(_ref) {
    var iconBefore = _ref.iconBefore,
        iconBeforeClassName = _ref.iconBeforeClassName,
        iconAfter = _ref.iconAfter,
        iconAfterClassName = _ref.iconAfterClassName,
        className = _ref.className,
        children = _ref.children,
        classes = _ref.classes,
        rest = _objectWithoutProperties(_ref, ['iconBefore', 'iconBeforeClassName', 'iconAfter', 'iconAfterClassName', 'className', 'children', 'classes']);

    return _react2.default.createElement(
        _BaseButton2.default,
        Object.assign({}, rest, { className: (0, _classnames2.default)(classes.main, className) }),
        createIcon(iconBefore, (0, _classnames2.default)([classes.iconBefore, iconBeforeClassName])),
        children,
        createIcon(iconAfter, (0, _classnames2.default)([classes.iconAfter, iconAfterClassName]))
    );
};

exports.Button = Button;
Button.classNames = {
    main: 'tbl-btn',
    iconBefore: 'iconBefore',
    iconAfter: 'iconAfter'
};

Button.propTypes = {
    /** Apply class to style the icon before children */
    iconBeforeClassName: _propTypes2.default.string,
    /** Apply class to style the icon after children */
    iconAfterClassName: _propTypes2.default.string,
    /** Icon class before children props */
    iconBefore: _propTypes2.default.node,
    /** Icon class after children props */
    iconAfter: _propTypes2.default.node,
    /** Click callback */
    onClick: _propTypes2.default.func,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Elements inside button tag */
    children: _propTypes2.default.node,
    /** Apply class to style the button */
    className: _propTypes2.default.string,
    /** button tabIndex */
    tabIndex: _propTypes2.default.string,
    stylesheet: _propTypes2.default.object,
    classes: _propTypes2.default.object
};

Button.defaultProps = {
    disabled: false,
    iconBeforeClassName: '',
    iconAfterClassName: '',
    classes: Button.classNames
};

exports.default = (0, _hoc.withMergedStyles)(Button, styles);