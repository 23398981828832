import CommonCollapsibleCard from '../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { ScheduledReportDeliveryTimeField } from '../ScheduledReportsDeliveryTimeField/ScheduledReportDeliveryTimeField';
import { ScheduledReportFileTypeField } from '../ScheduledReportsFileTypeField/ScheduledReportFileTypeField';
import { ScheduledReportFrequencyField } from '../ScheduledReportsFrequencyField/ScheduledReportFrequencyField';
import { ScheduledReportSentToInput } from '../ScheduledReportsSentToField/ScheduledReportSentToInput';
import styles from './scheduledReportDeliverySection.module.scss';

export const ScheduledReportDeliverySection = () => {
    return (
        <CommonCollapsibleCard
            id="SCHEDULED_REPORT_DELIVERY"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="app.create.scheduled.reports.delivery" defaultMessage="Delivery" />
                    }
                />
            }
        >
            <div className={styles['field-container']}>
                <ScheduledReportFrequencyField />
                <ScheduledReportDeliveryTimeField />
                <ScheduledReportFileTypeField />
                <ScheduledReportSentToInput />
            </div>
        </CommonCollapsibleCard>
    );
};
