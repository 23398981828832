import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingApi } from 'services/api/trackingApi';
import { errorMessagesUtils } from 'services/utils';
import { TRACKING_ROUTE } from '../config';
import { conversionConditionsTransformerToGW } from '../transformers/conversionConditionsTransformer';

const successIndication = {
    message: <FormattedMessage id="conversion.create.success" defaultMessage="Conversion successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage id="conversion.create.error.highlight" defaultMessage="Unable to create conversion." />
    ),
});

export const useConversionCreatorState = () => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const dispatch = useDispatch();
    const { submit, step, setStep } = useFormState();

    const { createConversion } = useTrackingApi();

    const submitConversion = useCallback(
        async conversion => {
            try {
                const transformConversion = await conversionConditionsTransformerToGW(conversion);
                await createConversion(accountId, transformConversion);
                dispatch(addIndication(successIndication));
                return true;
            } catch (error) {
                dispatch(addIndication(generateErrorIndication(error)));
                throw error;
            }
        },
        [createConversion, accountId, dispatch]
    );

    const submitWrapper = useCallback(() => {
        const submitConversionWrapper = conversion => {
            return submitConversion(conversion);
        };
        submit(submitConversionWrapper, TRACKING_ROUTE);
    }, [submit, submitConversion]);

    return { submit: submitWrapper, step, setStep };
};
