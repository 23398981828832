import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCampaignsFetchService } from 'modules/campaigns/components/CampaignPicker';
import { campaignToDropdownObjectTransformer } from 'modules/campaigns/services/utils';
import { campaignsMapSelector } from '../../../selectors';
import { hasCampaignCarousel } from '../../creative-creator/components/CarouselCardList/utils/hasCampaignCarousel';

export const DEFAULT_SELECTED_CAMPAIGNS = [];

const useCampaignsOptions = (selected = DEFAULT_SELECTED_CAMPAIGNS, account) => {
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [optionsLoaded, setOptionsLoaded] = useState(false);
    const selectedIds = useMemo(() => new Set(selected.map(({ value }) => value)), [selected]);

    const { getFullCampaign, loadCampaignsPage } = useCampaignsFetchService(account);
    const campaignCache = useSelector(campaignsMapSelector);

    const fetchedCampaignList = useMemo(
        () => Array.from(selectedIds).map(key => campaignCache[key]),
        [campaignCache, selectedIds]
    );

    const options = useMemo(
        () =>
            allCampaigns
                .map(campaign => campaign && campaignToDropdownObjectTransformer(getFullCampaign(campaign)))
                .filter(campaign => campaign && !selectedIds.has(campaign?.value) && !hasCampaignCarousel(campaign)),
        [allCampaigns, getFullCampaign, selectedIds]
    );

    const setOptions = useCallback(
        (options = []) => {
            if (options && options.length) {
                setOptionsLoaded(true);
            } else {
                setOptionsLoaded(false);
            }
            setAllCampaigns(options);
        },
        [setAllCampaigns]
    );

    return { loadCampaignsPage, options, optionsLoaded, setOptions, fetchedCampaignList };
};

export default useCampaignsOptions;
