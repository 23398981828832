import React from 'react';
import { HelpTooltip, Radio, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { RadioGroupField } from 'modules/campaigns/components/Form/FormFields/RadioGroupField/RadioGroupField';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BetaTag } from '../../../../../../../components';
import { BID_STRATEGIES } from '../../../config';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import TargetCpaRadio from '../TargetCpaRadio/TargetCpaRadio';
import styles from './smartBidRadio.module.scss';

export const eligibilityLabelsEnabled = { [COMMON_FLAGS.TARGET_CPA_ELIGIBILITY_LABELS]: 'true' };
export const cpaConfig = { [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' };
export const cpaAccountConfig = { [FEATURE_FLAGS.TARGET_CPA_ELIGIBLE_ACCOUNTS]: 'true' };
export const getTagProps = (
    isEligibilityLabelsEnabled,
    isTargetCpaBeta,
    isTargetCpaEligibleAccount,
    isEligibleCampaign
) => {
    if (isTargetCpaBeta) {
        return { toolTipMessage: '' };
    }
    if (isEligibleCampaign) {
        return {
            tagMessage: <FormattedMessage id="campaign.editor.target.cpa.label.early" defaultMessage="Early Access" />,
            toolTipMessage: '',
        };
    }
    if (isTargetCpaEligibleAccount) {
        return {
            tagMessage: (
                <FormattedMessage id="campaign.editor.target.cpa.label.limited" defaultMessage="Limited Access" />
            ),
            toolTipMessage: (
                <FormattedMessage
                    id="campaign.editor.target.cpa.label.limited.tooltip"
                    defaultMessage="Target CPA is only available for certain campaigns right now. We’ll let you know if it becomes available for this campaign. "
                />
            ),
        };
    } else {
        return false;
    }
};
export const SmartBidRadio = ({
    targetCpaValidations,
    targetCpaValidationDependencies,
    shouldDisableCpaOnDuplicate,
    ...props
}) => {
    const { value: bidStrategy, onChange } = useBidStrategyFormField();
    const { value: targetCpaData } = useFormFieldValue({
        field: 'targetCpaData',
    });

    const disabled = bidStrategy !== BID_STRATEGIES.SMART && bidStrategy !== BID_STRATEGIES.TARGET_CPA;
    const isEligibilityLabelsEnabled = useConfigMatch({}, eligibilityLabelsEnabled);
    const isTargetCpaBeta = useConfigMatch(cpaConfig);
    const isTargetCpaEligibleAccount = useConfigMatch(cpaAccountConfig);
    const isEligibleCampaign = targetCpaData !== undefined && targetCpaData !== null;
    const shouldRenderCPA =
        isTargetCpaBeta || ((isEligibleCampaign || isTargetCpaEligibleAccount) && isEligibilityLabelsEnabled);
    const resolvedValue = bidStrategy === BID_STRATEGIES.TARGET_CPA ? bidStrategy : BID_STRATEGIES.SMART;
    const disableCpa = !isEligibleCampaign && !isTargetCpaBeta;

    const tagProps = getTagProps(
        isEligibilityLabelsEnabled,
        isTargetCpaBeta,
        isTargetCpaEligibleAccount,
        isEligibleCampaign
    );
    const shouldRenderBetaTag = tagProps !== false && isEligibilityLabelsEnabled;

    return (
        <>
            <Radio
                {...props}
                value={resolvedValue}
                aria-label={`${bidStrategy === resolvedValue ? 'Selected - ' : ''}${bidStrategy}`}
            />
            {shouldRenderCPA ? (
                <RadioGroupField
                    className={`${styles['plain-group']} ${styles['plain-group-prioritized']}`}
                    inputId="bid-strategy-smart"
                    radioGroupName="bid-strategy-smart"
                    onChange={onChange}
                    selectedValue={bidStrategy}
                    data-metrics-component="bidStrategySmart"
                >
                    <Radio
                        disabled={disabled}
                        aria-label={`${bidStrategy === BID_STRATEGIES.SMART ? 'Selected smart - ' : ''}${
                            BID_STRATEGIES.SMART
                        }`}
                        key={`bid-strategy-smart-${BID_STRATEGIES.SMART}`}
                        value={BID_STRATEGIES.SMART}
                        title={
                            <FormattedMessage
                                id="campaign.editor.smart.standard.bid.title"
                                defaultMessage="Smart Bid"
                            />
                        }
                        description={
                            <FormattedMessage
                                id="campaign.editor.smart.standard.bid.description"
                                defaultMessage="Your bids will adjust to maximize your campaign's conversion rate."
                            />
                        }
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={BID_STRATEGIES.SMART}
                    />

                    <TargetCpaRadio
                        disabled={disabled || disableCpa || shouldDisableCpaOnDuplicate}
                        aria-label={`${bidStrategy === BID_STRATEGIES.TARGET_CPA ? 'Selected smart - ' : ''}${
                            BID_STRATEGIES.TARGET_CPA
                        }`}
                        key={`bid-strategy-smart-${BID_STRATEGIES.TARGET_CPA}`}
                        value={BID_STRATEGIES.TARGET_CPA}
                        title={
                            <div>
                                <FormattedMessage
                                    id="campaign.editor.target.cpa.bid.title"
                                    defaultMessage="Target CPA"
                                />
                                {shouldDisableCpaOnDuplicate && (
                                    <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                                        <FormattedMessage
                                            id="campaign.editor.target.cpa.bid.duplicate.tooltip"
                                            defaultMessage="Target CPA is not supported on duplicated campaigns yet, use Smart Bid to maximize the campaign results instead."
                                        />
                                    </HelpTooltip>
                                )}
                                {shouldRenderBetaTag && <BetaTag {...tagProps} />}
                            </div>
                        }
                        description={
                            <FormattedMessage
                                id="campaign.editor.target.cpa.bid.description"
                                defaultMessage="Your bid will adjust to reach your target cost per action."
                            />
                        }
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={BID_STRATEGIES.TARGET_CPA}
                        childrenContainerClassName={styles['target-cpa-container']}
                        targetCpaValidations={targetCpaValidations}
                        targetCpaValidationDependencies={targetCpaValidationDependencies}
                    />
                </RadioGroupField>
            ) : null}
        </>
    );
};
