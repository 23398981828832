import { useQuery } from 'react-query';
import { useAudiencesApi } from '../../../../../services/api';
import TargetingTypes from '../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { useFormDataContext, useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';

const transformDomainsToGW = domains => {
    const collection = [];
    domains?.include?.length && collection.push({ type: TargetingTypes.INCLUDE, values: domains.include || [] });
    domains?.exclude?.length && collection.push({ type: TargetingTypes.EXCLUDE, values: domains.exclude || [] });

    return { targetingValues: { collection } };
};

export const useMailDomainsSizeEstimate = () => {
    const { value: domains } = useFormFieldValue({
        field: 'domains',
    });

    const { value: lookBackWindow } = useFormFieldValue({
        field: 'lookBackWindow',
    });

    const {
        formAccount: { accountId: selectedAccountId },
    } = useFormDataContext();

    const { getMailDomainsSizeEstimate } = useAudiencesApi();

    const queryBody = {
        ...transformDomainsToGW(domains),
        lookBackWindow,
    };
    const { data: { deviceCount, peopleCount } = {}, isLoading } = useQuery(
        [domains, lookBackWindow],
        () => getMailDomainsSizeEstimate(selectedAccountId, queryBody),
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: !!domains?.include?.length,
        }
    );

    return {
        peopleCount,
        deviceCount,
        isLoading,
    };
};
