import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useIntl, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SimilarityRangeSliderBase } from './SimilarityRangeSliderBase';

const DisabledTooltip = () => (
    <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow duration={0} delay={800}>
        <FormattedMessage
            id="campaign.editor.targeting.my.audiences.lookalike.disabled.slider.tooltip"
            defaultMessage="Unavailable for Pixel Lookalike"
        />
    </Tooltip>
);

export const PixelLookalikeSimilarityRangeSlider = props => {
    const { formatMessage } = useIntl();
    const percentageValueLabelFormat = value =>
        formatMessage(
            {
                id: `campaign.editor.targeting.my.audiences.similarity.slider.value`,
                defaultMessage: 'Top {value}%',
            },
            { value }
        );

    return (
        <SimilarityRangeSliderBase
            isDisableSlider
            disabledSliderTooltip={DisabledTooltip}
            min={5}
            max={25}
            step={5}
            valueLabelFormat={percentageValueLabelFormat}
            {...props}
        />
    );
};
