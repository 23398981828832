import React, { createContext, useMemo, useState } from 'react';

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
    const [data, setData] = useState({});
    const value = useMemo(() => ({ data, setData }), [data]);

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
