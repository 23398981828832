import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

const emptyColumns = [
    FIELDS.IS_ACTIVE,
    FIELDS.CREATIVE_NAME,
    FIELDS.CAMPAIGN_NAME,
    FIELDS.ID,
    FIELDS.ACTIONS_COLUMN,
].map(createFooterMapper(REPORT_TYPE.CREATIVE_VIDEO));

const creativeReportFooterColumnDefinition = [...emptyColumns];

export default creativeReportFooterColumnDefinition;
