import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import styles from './typeRadioIconGroup.module.scss';

const RadioGroupWithIndication = withIndication(RadioGroup);

export const TypeRadioIconGroup = ({
    name,
    type,
    onTypeChange,
    configs,
    onHover,
    msgPrefix,
    titleClassName,
    disabled,
    validations,
    field = 'type',
    formFieldDependencies,
}) => {
    const { indicationData } = useFormValidatedValue({
        field,
        validations,
        formFieldDependencies,
    });
    return (
        <RadioGroupWithIndication
            id={name}
            name={name}
            className={styles['group']}
            selectedValue={type}
            onChange={onTypeChange}
            {...indicationData}
        >
            {configs.map(({ id, icon: Icon, iconClassName, label }) => {
                return (
                    <RadioIcon
                        key={id}
                        value={id}
                        title={<FormattedMessage id={`${msgPrefix}.${id}.label`} defaultMessage={`${label}`} />}
                        iconComponent={<Icon className={iconClassName} />}
                        labelClassName={styles['radio']}
                        titleClassName={`styles['title'] ${titleClassName}`}
                        onMouseEnter={() => onHover && onHover(id)}
                        onMouseLeave={() => onHover && onHover(null)}
                        disabled={disabled}
                    />
                );
            })}
        </RadioGroupWithIndication>
    );
};

TypeRadioIconGroup.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    configs: PropTypes.array.isRequired,
    onHover: PropTypes.func,
    msgPrefix: PropTypes.string.isRequired,
    titleClassName: PropTypes.string,
    disabled: PropTypes.bool,
};

TypeRadioIconGroup.defaultProps = {
    titleClassName: '',
    disabled: false,
};

export default TypeRadioIconGroup;
