import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './budgetData.module.scss';

const InputWithIndication = withIndication(Input);

const BudgetData = ({ type, onBudgetChange, label, value, currency, disabled, error, indicationType }) => {
    const changeHandler = event => {
        const { target } = event || {};
        const { value } = target || {};

        onBudgetChange(value, type);
    };
    return (
        <div className={styles['container']} data-automation="BudgetData">
            <div className={styles['label']}>{label}</div>
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <InputWithIndication
                        id="budget"
                        aria-label="Budget"
                        type={InputTypes.NUMBER}
                        onChange={changeHandler}
                        value={value}
                        placeholder={placeholder}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="BudgetValue"
                        disabled={disabled}
                        error={error}
                        indicationType={indicationType}
                    />
                )}
            </FormattedNumber>
            <div>{currency}</div>
        </div>
    );
};

BudgetData.propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    onBudgetChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    currency: PropTypes.string,
    error: PropTypes.bool,
    onErrorStateChange: PropTypes.func,
    failedValidationData: PropTypes.object,
};

BudgetData.defaultProps = {
    currency: 'USD',
    failedValidationData: {},
};

export default BudgetData;
