import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedDate } from '../../../../../taboola-common-frontend-modules/i18n/index';

class FormattedDateCellRenderer extends Component {
    render() {
        const { value } = this.props;
        const m = moment(value);
        return value ? (
            <FormattedDate value={m} timeZone={m.tz()} year="numeric" month="short" day="2-digit" />
        ) : (
            <span>-</span>
        );
    }
}

FormattedDateCellRenderer.propTypes = {
    /** value which is injected by the grid */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};

export default FormattedDateCellRenderer;
