import { useCallback } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { RadioGroup } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useMaxConversionsState } from '../MaxConversionsCategory/useMaxConversionsState';
import { CpcCapRadio } from './CpcCapRadio/CpcCapRadio';
import { TargetCpaRadio } from './TargetCpaRadio/TargetCpaRadio';
import { useApplyCapState } from './useApplyCapState';
import styles from './applyCapSection.module.scss';

export const ApplyCapSection = () => {
    const { bidStrategy, onChangeBidStrategy } = useMaxConversionsState();
    const { isApplyCapActive, handleCheckboxSelect, handleCheckboxUnselect } = useApplyCapState();

    const handleRadioChange = useCallback(
        value => {
            onChangeBidStrategy(value);
        },
        [onChangeBidStrategy]
    );

    return (
        <div className={styles['container']}>
            <CheckboxGroup
                onSelectCheckbox={handleCheckboxSelect}
                onUnselectCheckbox={handleCheckboxUnselect}
                selectedValues={[isApplyCapActive]}
            >
                <TextCheckbox
                    value
                    title={<FormattedMessage id="campaign.editor.applyCap.checkbox.title" defaultMessage="Apply Cap" />}
                />
            </CheckboxGroup>
            {isApplyCapActive && (
                <RadioGroup
                    name="apply-cap"
                    onChange={handleRadioChange}
                    selectedValue={bidStrategy}
                    className={styles['group']}
                >
                    <CpcCapRadio bidStrategy={bidStrategy} />
                    <TargetCpaRadio bidStrategy={bidStrategy} />
                </RadioGroup>
            )}
        </div>
    );
};
