import moment from 'moment';
import { DASHBOARD_RANGE_PICKER_UTILS } from 'taboola-ultimate-ui';

const { getMonthAbbreviation } = DASHBOARD_RANGE_PICKER_UTILS;

export const numberFormatter = options => locale => value => {
    const copiedOptions = { ...options };
    if (
        !options.minimumFractionDigits &&
        options.notation === 'compact' &&
        options.style === 'currency' &&
        value >= 1000
    ) {
        copiedOptions.minimumFractionDigits = 0;
    }
    return new Intl.NumberFormat(locale, copiedOptions).format(value);
};

export const dateFormatter = format => value => {
    const date = moment.utc(value);

    return date.isValid() ? date.format(format) : '';
};

export const tooltipDateFormatter = value => {
    const date = moment.utc(value);

    return date.isValid() ? `${date.format('ddd.')} ${getMonthAbbreviation(date)} ${date.format('DD, YYYY')}` : '';
};

export const dayWeekMonthGetXAxisFormatter = ({ reportStartDate, reportEndDate }) => {
    if (reportEndDate.diff(reportStartDate, 'years') >= 1) {
        return dateFormatter('MMM YYYY');
    }
    return dateFormatter('MMM DD');
};

export const dayWeekMonthXAxisMinTickGap = ({ reportStartDate, reportEndDate }) => {
    if (reportEndDate.diff(reportStartDate, 'years') >= 1) {
        return 25;
    }
    return 0;
};

export const tooltipHourFormatter = value => moment(value).format('HH:mm');

export const tooltipHourFormatterUTC = value => moment(value).utc().format('HH:mm');

export const hourXAxisFormatter = () => value => moment(value).format('HH:mm');

export const hourXAxisFormatterUTC = () => value => moment(value).utc().format('HH:mm');
