import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { OutlineEditableText } from '../../../campaigns/components/OutlineEditableText/OutlineEditableText';
import { withIndication } from '../../../errors';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from '../../../taboola-common-frontend-modules/validations';
import { scheduledReportNameValidations } from '../../validations/scheduledReportNameValidations';
import styles from './scheduledReportNameField.module.scss';

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);

export const ScheduledReportNameField = () => {
    const { value: dimension } = useFormFieldValue({ field: 'dimension' });
    const { value: dateRange } = useFormFieldValue({ field: 'dateRange' });
    const { formatMessage } = useIntl();
    const translatedDimension = formatMessage({
        id: `app.scheduled.reports.create.settings.dimension.${dimension}`,
        defaultMessage: dimension,
    });
    const translatedDateRange = formatMessage({
        id: `app.campaigns.campaign.date.range.${dateRange}`,
        defaultMessage: dateRange,
    });
    const reportDefaultName = `${translatedDimension} - ${translatedDateRange}`;
    const {
        value: name,
        onChange: onNameChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'reportName',
        validations: scheduledReportNameValidations,
        formFieldDependencies: { reportDefaultName },
    });
    const placeholderText = formatMessage({
        id: 'app.scheduled.reports.create.name.placeholder',
        defaultMessage: 'Please enter Scheduled Report name...',
    });

    return (
        <FormField
            inputId="scheduled-report-name-input"
            containerClass={styles['input-container']}
            label={<FormattedMessage id="app.scheduled.reports.create.name.title" defaultMessage="Report Name" />}
            description={
                <FormattedMessage
                    id="app.scheduled.reports.create.name.description"
                    defaultMessage="Give your report a clear and descriptive name to locate it in the scheduled report page."
                />
            }
        >
            <OutlineEditableTextWithIndication
                id="name"
                value={name}
                onChange={onNameChange}
                placeholder={placeholderText}
                {...indicationData}
            />
        </FormField>
    );
};
