import { saveAs as saveAsFile } from 'file-saver';
import { filter, flatMap, get, invert, isArray, isBoolean, isEmpty, keyBy, mapValues } from 'lodash';
import { unparse } from 'papaparse';
import * as googleApiUtils from 'modules/taboola-common-frontend-modules/googleApi/utils';
import { csvDefaultHeaderValueGetter } from '../../campaigns/modules/campaigns-reports/components/ValueFormatters/csvDefaultHeaderValueGetter';

const identityValueGetter = value => value;

const extractCsvColumnDefinition = (columnDef, injectables) => {
    const {
        csv,
        csvValueGetterParams: parentCsvValueGetterParams,
        cellRendererParams: parentCellRendererParams,
    } = columnDef;
    if (isBoolean(csv) && !csv) {
        return [];
    }
    if (isArray(csv)) {
        return csv.map(({ csvValueGetterParams, ...rest }) => ({
            ...rest,
            csvValueGetterParams: csvValueGetterParams || parentCsvValueGetterParams || parentCellRendererParams,
        }));
    }

    const {
        headerName,
        field,
        csvValueGetter,
        cellRendererParams,
        csvValueGetterParams,
        csvHeaderValueGetter = csvDefaultHeaderValueGetter,
    } = columnDef;

    return [
        {
            headerName: csvHeaderValueGetter ? csvHeaderValueGetter(headerName, injectables, field) : headerName,
            field,
            csvValueGetter,
            csvValueGetterParams: csvValueGetterParams || cellRendererParams,
        },
    ];
};

const buildCsvColumnsDefinitions = (columnDefinitions, injectables) =>
    flatMap(columnDefinitions, col => extractCsvColumnDefinition(col, injectables));

const generateHeaderRow = columnDefinitions => columnDefinitions.map(columnDef => columnDef.headerName);

const getCsvValue = (columnDef, dataRow, injectables) => {
    const { csvValueGetterParams = {}, csvValueGetter = identityValueGetter } = columnDef;

    return csvValueGetter(get(dataRow, columnDef.field), dataRow, csvValueGetterParams, injectables);
};

const buildCsvRows = (columnDefinitions, data, injectables) => {
    const header = generateHeaderRow(columnDefinitions);
    const rows = data.map(dataRowObj =>
        columnDefinitions.map(columnDef => getCsvValue(columnDef, dataRowObj, injectables))
    );

    return [header, ...rows];
};

const getSelectedColumnDefinitions = (columnDefinitions, selectedColumns) => {
    if (isEmpty(selectedColumns)) {
        return columnDefinitions;
    }

    const selectedColumnsMap = keyBy(selectedColumns);
    const selectedColumnsIndex = mapValues(invert({ ...selectedColumns }), val => +val);
    const selectedColumnsDef = filter(columnDefinitions, ({ colId }) => selectedColumnsMap[colId]).sort(
        (a, b) => selectedColumnsIndex[a.colId] - selectedColumnsIndex[b.colId]
    );

    return { ...selectedColumnsDef };
};

const getCSVString = async (dataProvider, columnDefinitions, selectedColumns, injectables) => {
    const { results } = await dataProvider();

    const selectedColumnDefinitions = getSelectedColumnDefinitions(columnDefinitions, selectedColumns);
    const csvColumnsDefinitions = buildCsvColumnsDefinitions(selectedColumnDefinitions, injectables);
    const csvRows = buildCsvRows(csvColumnsDefinitions, results, injectables);
    const csvString = unparse(csvRows, { escapeFormulae: true });

    // required in the beginning of the file, to force excel to open CSV in UTF-8 encoding
    const UTF8_BOM = '\ufeff';

    return UTF8_BOM + csvString;
};

const exportToGoogleDriveCSVFile = async (dataProvider, columnDefinitions, selectedColumns, filename, injectables) => {
    const csv = await getCSVString(dataProvider, columnDefinitions, selectedColumns, injectables);

    // create the google sheet
    const response = await googleApiUtils.createSpreadsheet({ filename });
    const {
        result: { spreadsheetId, spreadsheetUrl },
    } = response;

    // insert data into google sheet
    await googleApiUtils.pushCSVDataToSpreadsheet({
        spreadsheetId,
        csvData: csv,
    });

    return spreadsheetUrl;
};

async function exportToLocalCSVFile(dataProvider, columnDefinitions, selectedColumns, filename, injectables) {
    const csv = await getCSVString(dataProvider, columnDefinitions, selectedColumns, injectables);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    saveAsFile(blob, filename);
}

export { exportToLocalCSVFile, exportToGoogleDriveCSVFile, buildCsvColumnsDefinitions, buildCsvRows, getCSVString };
