import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './thumbnailEditorImageOverlay.module.scss';

const ThumbnailEditorImageOverlay = ({ isThumbnailLoading, imageError }) => {
    if (isThumbnailLoading || imageError) {
        return (
            <div className={styles['overlay']} aria-label="thumbnail editor image overlay">
                {isThumbnailLoading && <Spinner className={styles['spinner']} size={30} />}
                {imageError && (
                    <FormattedMessage
                        id={`creative.creator.thumbnails.fetching.${imageError.status}`}
                        defaultMessage="Upload thumbnail error"
                    />
                )}
            </div>
        );
    }

    return null;
};

ThumbnailEditorImageOverlay.propTypes = {
    isThumbnailLoading: PropTypes.bool,
    imageError: PropTypes.object,
};

export default ThumbnailEditorImageOverlay;
