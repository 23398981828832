import { useCallback, useMemo, useState } from 'react';
import { differenceBy } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useRejectedAudiencesIndication } from '../../../../../../../audiences/hooks/useRejectedAudiencesIndication';
import TargetingTypes from '../../../../config/TargetingTypes';
import { audienceTypeToDefaultSimilarityLevel } from '../../utils/MyAudienceUtils';
import { useMyAudiencesApi } from './useMyAudiencesApi';

export const useMyAudiences = accountId => {
    const { mode } = useFormDataContext();

    const { value: countryTargetingType, isDirty: countryTargetingTypeTouched } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: countryTargetingValues, isDirty: countryTargetingValuesTouched } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });

    const shouldInitExclude = useMemo(
        () => mode === FORM_MODES.CREATE && !countryTargetingTypeTouched && !countryTargetingValuesTouched,
        [countryTargetingTypeTouched, countryTargetingValuesTouched, mode]
    );

    const [targetingType, setTargetingType] = useState(TargetingTypes.INCLUDE);

    const {
        listOfMyAudiences,
        isSuccessMyAudiences,
        isSuccessAllMyAudiences,
        mySegmentsByIdMap,
        defaultExcludedListOfMyAudience,
        isDisabled,
        isLoadingMyAudiences,
        mapOfAllMyAudiences,
    } = useMyAudiencesApi(accountId, countryTargetingType, countryTargetingValues, targetingType);

    const { rejectedAudiences, isRejectedAudiencesSuccess } = useRejectedAudiencesIndication();

    const {
        value: includeList,
        onChange: setIncludeList,
        isDirty: myAudiencesTouched,
    } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
    });
    const { value: excludeList, onChange: setExcludeList } = useFormFieldValue(
        {
            field: 'campaignTargeting.myAudienceTargeting.exclude',
            isPermitted: shouldInitExclude,
        },
        { defaultExcludedListOfMyAudience }
    );

    const includeAudiences = useMemo(() => {
        return includeList.map(({ id }) => ({ ...mapOfAllMyAudiences[id], targetingType: TargetingTypes.INCLUDE }));
    }, [includeList, mapOfAllMyAudiences]);

    const excludeAudiences = useMemo(() => {
        return excludeList.map(({ id }) => ({ ...mapOfAllMyAudiences[id], targetingType: TargetingTypes.EXCLUDE }));
    }, [excludeList, mapOfAllMyAudiences]);

    const setTargetingTypeValue = useCallback(({ value }) => {
        setTargetingType(value);
    }, []);

    const addItem = useCallback(
        ({ id: audienceId }) => {
            const selectedAudience = listOfMyAudiences.find(audience => audience.id === audienceId);
            const newAudience = {
                id: audienceId,
                similarityLevel: audienceTypeToDefaultSimilarityLevel[selectedAudience.type],
            };
            if (targetingType === TargetingTypes.INCLUDE) {
                setIncludeList([...includeList, newAudience]);
            } else {
                setExcludeList([...excludeList, newAudience]);
            }
        },
        [excludeList, includeList, listOfMyAudiences, setExcludeList, setIncludeList, targetingType]
    );

    const myAudiences = useMemo(() => {
        const selectedAudiences = [...includeAudiences, ...excludeAudiences];
        return differenceBy(listOfMyAudiences, selectedAudiences, 'id');
    }, [excludeAudiences, includeAudiences, listOfMyAudiences]);

    const removeIncludeAudience = useCallback(
        value => {
            setIncludeList(includeList.filter(audience => audience.id !== value));
        },
        [includeList, setIncludeList]
    );

    const removeExcludeAudience = useCallback(
        value => {
            setExcludeList(excludeList.filter(audience => audience.id !== value));
        },
        [excludeList, setExcludeList]
    );

    const clearIncludedItems = useCallback(() => {
        setIncludeList([]);
    }, [setIncludeList]);

    const clearExcludedItems = useCallback(() => {
        setExcludeList([]);
    }, [setExcludeList]);

    return {
        includeAudiences,
        excludeAudiences,
        clearExcludedItems,
        clearIncludedItems,
        removeIncludeAudience,
        removeExcludeAudience,
        myAudiences,
        setTargetingTypeValue,
        addItem,
        isDisabled,
        isLoadingMyAudiences,
        targetingType,
        allSegmentsByIdMap: mySegmentsByIdMap,
        isOptionsLoadedSuccessfully: !!(isSuccessMyAudiences && isSuccessAllMyAudiences),
        rejectedAudiencesList: rejectedAudiences,
        isRejectedAudiencesLoadedSuccessfully: isRejectedAudiencesSuccess,
        isDirty: myAudiencesTouched || countryTargetingValuesTouched,
        mode,
    };
};
