import { invert } from 'lodash';

// CONVERSIONS was added to dynamically group conversions columns in custom side editor
// TODO add separate enum for visual column grouping in CustomColumnPicker
export const REPORT_PRESET = {
    DEFAULT: 'All',
    PERFORMANCE: 'Performance',
    SETUP: 'Setup',
    CONVERSIONS: 'Conversions',
};

export const invertedReportPreset = invert(REPORT_PRESET);
