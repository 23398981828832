import moment from 'moment';
import { isNetworkAccountType } from '../../account-management';
import { RECENT_ACTIVITIES_PERIOD, PIXEL_STATE } from '../config';

const ifHasRecentActivities = lastPixelEvent => {
    if (!lastPixelEvent) {
        return false;
    }

    const currentDate = moment.utc();
    const timeDiversity = currentDate.diff(moment.utc(lastPixelEvent), 'hours');

    return timeDiversity < RECENT_ACTIVITIES_PERIOD;
};

export const getPixelStatus = ({ pixelData = {}, type }) => {
    const { hasS2sPixelEvents = false, lastPixelEvent } = pixelData;
    const isNetworkAccount = isNetworkAccountType(type);
    const hasRecentActivities = ifHasRecentActivities(lastPixelEvent);

    if (isNetworkAccount || hasS2sPixelEvents || hasRecentActivities) {
        return null;
    }

    if (!lastPixelEvent) {
        return PIXEL_STATE.NO_ACTIVITY;
    }

    return PIXEL_STATE.NOT_INSTALLED;
};
