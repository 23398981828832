const METADATA_KEYS = {
    IS_FLA: 'IS_FLA',
    YAHOO_GEMINI: 'YAHOO_GEMINI',
    YAHOO_MDM: 'YAHOO_MDM',
    YAHOO_DSP_ACCOUNT: 'YAHOO_DSP_ACCOUNT',
    BOOKING_COUNTRY: 'BOOKING_COUNTRY',
    DSP_SEAT_ID: 'DSP_SEAT_ID',
    CATEGORY: 'CATEGORY',
    SUB_CATEGORY: 'SUB_CATEGORY',
    DSP_SEAT_CURRENCY: 'DSP_SEAT_CURRENCY',
    DSP_SEAT_TIMEZONE: 'DSP_SEAT_TIMEZONE',
    YAHOO_NATIVE: 'YAHOO_NATIVE',
    YAHOO_OMNI: 'YAHOO_OMNI',
};

export const KEY_OPTIONS = Object.values(METADATA_KEYS).map(value => ({ value }));
