import React from 'react';
import { DuplicateIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { isAdminNetworkAccountType, isNetworkAccountType } from 'modules/account-management';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { AccountsSection } from '../AccountsSection';
import { CampaignsSection } from '../CampaignsSection';

const setupSectionTitle = <FormattedMessage id="creative.editor.setup" defaultMessage="Selected Campaigns" />;

const SetupSection = ({ selectedAccount }) => {
    const { type: selectedAccountType } = selectedAccount;
    const selectedAccountIsNetwork =
        isNetworkAccountType(selectedAccountType) || isAdminNetworkAccountType(selectedAccountType);
    return (
        <CommonCollapsibleCard
            id="CREATIVE_SETUP"
            header={<SectionHeader headerIcon={<DuplicateIcon />} headerText={setupSectionTitle} />}
        >
            {selectedAccountIsNetwork && <AccountsSection />}
            <CampaignsSection />
        </CommonCollapsibleCard>
    );
};

export default SetupSection;
