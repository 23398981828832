import React from 'react';
import PropTypes from 'prop-types';
import { ClosedFilterUnit } from '../ClosedFilterUnit/ClosedFilterUnit';
import { OpenFilterUnit } from '../OpenFilterUnit/OpenFilterUnit';

export const FilterUnit = ({
    isOpen,
    filter,
    onRemoveFilter,
    onUpdateFilter,
    closeFilter,
    openFilter,
    availableTypes,
    disabledTypes,
    getValueOptions,
    isMultiSelectFilter,
    getApplyBtnMetricsAttrs,
}) =>
    isOpen ? (
        <OpenFilterUnit
            initialFilter={filter}
            onApply={onUpdateFilter}
            onCancel={closeFilter}
            types={availableTypes}
            disabledTypes={disabledTypes}
            getValueOptions={getValueOptions}
            isMultiSelectFilter={isMultiSelectFilter}
            getApplyBtnMetricsAttrs={getApplyBtnMetricsAttrs}
        />
    ) : (
        <ClosedFilterUnit filter={filter} onEditFilter={openFilter} onRemoveFilter={onRemoveFilter} />
    );

FilterUnit.propTypes = {
    /** whether the unit is in the open or closed state */
    isOpen: PropTypes.bool,
    /** func the filterUnit calls to close the filter: () => {} */
    closeFilter: PropTypes.func,
    /** func the filter unit calls to open the filter: () => {} */
    openFilter: PropTypes.func,
    /** the filter being represented - { type: STRING, values: ARRAY } */
    filter: PropTypes.object,
    /** func to call when updating the filter: (filter) => {} */
    onUpdateFilter: PropTypes.func,
    /** func to call when deleting a filter: () => {} */
    onRemoveFilter: PropTypes.func,
    /** all types that can be applied to the selectedReport */
    availableTypes: PropTypes.array,
    /** all types that are already in use by OTHER filters */
    disabledTypes: PropTypes.object,
    /** flag to disable the X button */
    closeDisabled: PropTypes.bool,
    /** async function (type, searchText, page), returns {values: [{value, label}], hasMore} */
    getValueOptions: PropTypes.func,
    /** return value is spread into apply button for metrics */
    getApplyBtnMetricsAttrs: PropTypes.func,
};

FilterUnit.defaultProps = {
    onUpdateFilter: () => {},
    onRemoveFilter: () => {},
    closeFilter: () => {},
    openFilter: () => {},
};
