import { isNaN } from 'lodash';
import { BUDGET_TYPES } from '../../../common-campaign-form/config';

export const getSpentTotalValue = ({ budget }) => {
    const { value: budgetValue, type, spendingLimit } = budget || {};
    const { value: spendingLimitValue } = spendingLimit || {};

    return type === BUDGET_TYPES.DAILY ? spendingLimitValue : budgetValue;
};

export const getBidModificationValues = (data, value) => {
    const bid = parseFloat(data['bid']);
    const parsedValue = parseFloat(value);
    const bidModification = isNaN(parsedValue) ? 1 : parsedValue;

    return {
        bid,
        bidModification,
        decimalPercent: bidModification - 1,
        actualBid: bid * bidModification,
    };
};

export const getNewBidModification = newValue => (newValue === 1 ? null : newValue);
