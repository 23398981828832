import React, { useCallback, useEffect, useState } from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PaginatedDropdownField } from '../../../../components/Form';
import { FORM_MODES } from '../../../../config';
import CustomPresetsListItem from './CustomPresetsListItem';
import { usePresetsOptions } from './hooks/usePresetsOptions';
import styles from './customPresetsList.module.scss';

const ITEM_HEIGHT = 40;

const CustomPresetsList = () => {
    const {
        value: selectedPresetsIds,
        onChange,
        mode,
    } = useFormFieldValue({
        field: 'campaignTargeting.presets',
    });

    const [selectedPresets, setSelectedPresets] = useState([]);
    const { options, setPresets, loadPresetsPage, initialSelectedPresets } = usePresetsOptions(selectedPresetsIds);

    useEffect(() => {
        mode !== FORM_MODES.CREATE && initialSelectedPresets
            ? setSelectedPresets(initialSelectedPresets)
            : setSelectedPresets([]);
    }, [initialSelectedPresets, mode]);

    const onAdd = useCallback(
        preset => {
            onChange(prevIds => [...prevIds, preset.id]);
            setSelectedPresets([...selectedPresets, preset]);
        },
        [onChange, selectedPresets]
    );

    const onRemove = useCallback(
        ({ id: idToRemove }) => {
            onChange(prevIds => prevIds.filter(id => id !== idToRemove));
            setSelectedPresets(selectedPresets.filter(preset => preset.id !== idToRemove));
        },
        [onChange, selectedPresets]
    );

    return (
        <>
            <PaginatedDropdownField
                id="custom-preset-list"
                placeholder={
                    <FormattedMessage
                        id="video.campaign.editor.customPresets.placeholder"
                        defaultMessage="Select Presets..."
                    />
                }
                options={options}
                onChange={onAdd}
                selectedValueObject={null}
                onOptionsLoaded={setPresets}
                loadPage={loadPresetsPage}
            />
            <CollapsibleList
                items={selectedPresets}
                ItemComponent={CustomPresetsListItem}
                itemHeight={ITEM_HEIGHT}
                deleteItem={onRemove}
                listHeaderTitle={
                    <FormattedMessage
                        id="video.campaign.editor.customPresets.header"
                        defaultMessage="Selected Presets"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
            />
        </>
    );
};

export default CustomPresetsList;
