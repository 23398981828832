import { cloneDeep, isNil, isEmpty, keyBy, mapValues, toPairs } from 'lodash';

const transformSinglePresetFromGW = ({ graphState, presetDisplayName, ...rest } = {}) => ({
    ...rest,
    ...(presetDisplayName ? { presetDisplayName } : {}),
    ...(isEmpty(graphState?.edges)
        ? {}
        : { graphEdgesState: mapValues(keyBy(graphState.edges, 'name'), ({ name, ...rest }) => rest) }),
});

export const transformUserSettingsFromGW = rawValue => {
    const transformedValue = cloneDeep(rawValue);

    transformedValue.reports = (transformedValue.reports || []).map(
        ({ presets, sorting, filters, filterActive, ...rest }) => ({
            ...rest,
            ...(sorting ? { sorting } : {}),
            ...(filters ? { filters } : {}),
            ...(filterActive === null ? {} : { filterActive }),
            ...(isEmpty(presets)
                ? {}
                : {
                      presets: mapValues(keyBy(presets, 'presetName'), ({ presetName, ...rest }) =>
                          transformSinglePresetFromGW(rest)
                      ),
                  }),
        })
    );

    transformedValue.reports = mapValues(
        keyBy(transformedValue.reports, 'reportName'),
        ({ reportName, ...rest }) => rest
    );

    return transformedValue;
};

const transformSinglePresetToGW = ({ graphEdgesState, presetDisplayName, ...rest }) => ({
    ...rest,
    presetDisplayName: isNil(presetDisplayName) ? null : presetDisplayName,
    graphState: isEmpty(graphEdgesState)
        ? undefined
        : { edges: toPairs(graphEdgesState).map(([name, value]) => ({ name, ...value })) },
});

const transformPresetsToGW = presets =>
    isEmpty(presets)
        ? undefined
        : toPairs(presets).map(([presetName, valueValue]) => ({
              presetName,
              ...transformSinglePresetToGW(valueValue),
          }));

export const transformUserSettingsToGW = ({ ready, ...rawValue } = {}) => {
    const transformedValue = cloneDeep(rawValue);

    transformedValue.reports = isEmpty(transformedValue.reports)
        ? undefined
        : toPairs(transformedValue.reports).map(([reportName, { presets, ...rest }]) => ({
              reportName,
              ...rest,
              presets: transformPresetsToGW(presets),
          }));

    return transformedValue;
};
