import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import formatNumberCompact from '../../../../../services/utils/formatNumberCompact';
import { useViewableImpressions } from '../hooks/useViewableImpressions';
import { ViewableImpressionsContent } from './ViewableImpressionsContent';
import { ViewableImpressionsFieldTooltip } from './ViewableImpressionsFieldTooltip';
import styles from './viewableImpressionsField.module.scss';

const BudgetDepletionNotice = ({ isBudgetDepleted = false, isEnabled }) => {
    return (
        isEnabled &&
        isBudgetDepleted && (
            <div className={styles['notice']} aria-label="budget-depletion-notice">
                <Indication
                    type="info"
                    className={styles['notice-container']}
                    message={
                        <FormattedMessage
                            id="campaign.reach.estimator.widget.budget.depletion.message"
                            //TODO: this message wasn't added to messages.en.json as it will be changed it few weeks.
                            //TODO: please add it after the final change
                            defaultMessage="<b>Estimate is imprecise</b>{newline}The estimated range provided may not accurately reflect changes in CPC bid due to the limited budget."
                        />
                    }
                    contentClassName={styles['notice-content']}
                    displayDismissComponent={false}
                />
            </div>
        )
    );
};

export const ViewableImpressionsField = ({ entityType }) => {
    const {
        lowerBound,
        upperBound,
        noFiltersChosen,
        isLoading,
        isSmallReach,
        isReachOverLimit,
        reachLimit,
        isError,
        isBudgetDepleted,
        reachThreshold,
    } = useViewableImpressions(entityType);
    const { formatMessage } = useIntl();

    const formattedMin = formatNumberCompact(lowerBound, 1);
    const formattedMax = formatNumberCompact(upperBound, 1);
    const formattedReachLimit = formatNumberCompact(reachLimit, 1);

    return (
        <div data-testid="viewable-impressions-field" className={styles['content']}>
            <FormField
                inputId="reach-min-max"
                label={formatMessage({
                    id: 'campaign.reach.estimator.widget.title',
                    defaultMessage: 'Available Viewable Impressions',
                })}
                containerClass={styles['border']}
                helpText={<ViewableImpressionsFieldTooltip />}
            >
                <div data-testid="reach-range" className={styles['reach-range']}>
                    <ViewableImpressionsContent
                        noFiltersChosen={noFiltersChosen}
                        isLoading={isLoading}
                        isSmallReach={isSmallReach}
                        isReachOverLimit={isReachOverLimit}
                        isError={isError}
                        formattedMin={formattedMin}
                        formattedMax={formattedMax}
                        formattedReachLimit={formattedReachLimit}
                        reachThreshold={reachThreshold}
                    />
                </div>
                <BudgetDepletionNotice isBudgetDepleted={isBudgetDepleted} isEnabled={!isError && !isSmallReach} />
            </FormField>
        </div>
    );
};
