import { INITIATE_STATE_FROM_STORAGE } from '../../actions';

const getStateByReducer = (state, reducerName) => state[reducerName] || {};

// fallbackState is needed for late low priority initialization if we missed needed default value in URL, REDUX store, Local Storage
export const addPersistHandler = (handlers, reducerName) => ({
    ...handlers,
    [INITIATE_STATE_FROM_STORAGE]: (state, { payload }) => ({
        ...state,
        ...getStateByReducer(payload, reducerName),
        isLocalStorageInitFinished: true,
    }),
});

export default addPersistHandler;
