import React from 'react';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../../../config/PaymentType';
import styles from '../../addFundsModal.module.scss';

export const InstructionsHostedInvoice = ({ financialRepEmail }) => {
    return (
        <div>
            <div className={styles['financial-rep-info']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.HOSTED_INVOICE}.instructions`}
                />
                <div className={styles['email-container']}>
                    <div className={styles['email']}> {financialRepEmail}</div>
                    <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                        <FormattedMessage
                            id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.HOSTED_INVOICE}.tooltip`}
                        />
                    </HelpTooltip>
                </div>
            </div>
            <div className={styles['hosted-invoice-instructions']}>
                <li className={styles['instruction-text']}>
                    <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.instructions" />
                    <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                        <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.tooltip" />
                    </HelpTooltip>
                </li>
            </div>
        </div>
    );
};
