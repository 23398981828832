"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgWarningCircle(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M-6.99382e-07 8C-1.08564e-06 3.58172 3.58172 1.08564e-06 8 6.99382e-07C12.4183 3.13124e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 -3.13124e-07 12.4183 -6.99382e-07 8ZM9 11L9 13L7 13L7 11L9 11ZM6.5 4L7 9L9 8.99999L9.5 3.99999C9.5 3.44771 9 2.99999 8 2.99999C7 3 6.5 3.44771 6.5 4Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgWarningCircle;