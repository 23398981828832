import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const SubtitlesTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="video.creative.creator.logo.subtitles.tooltip"
                defaultMessage="Subtitles will be displayed on the bottom of the video.{br}
                \'Automatic\' subtitles will provide English transcription by Google. To use a SRT subtitles file, use the \ֿֿֿ'Upload File\' option.{br}
                As a best practice we do recommended using subtitles as sound is user initiated."
            />
        </TooltipSection>
    </>
);
