import React, { useState } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CollapsibleList, useElementSize, useDebouncedValue } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TARGETING_TYPES from '../../config/TargetingTypes';
import { useSendSegmentSearchEvent } from '../../utils/useSendSegmentSearchEvent';
import { TargetingTypeBareDropdown } from '../TargetingTypeDropdown';
import { MAX_SEGMENTS_TO_SELECT } from '../Tree';
import ContextualSegmentListItemContentWithValidations from './ContextualSegmentListItemContent/ContextualSegmentListItemContentWithValidations';
import ContextualSegmentsDropdown from './ContextualSegmentsDropdown/ContextualSegmentsDropdown';
import { ContextualSegmentsTreeProvider } from './ContextualSegmentsTreeContext';
import useContextualSegments from './hooks/useContextualSegments';
import commonStyles from '../commonEditor.module.scss';
import styles from './contextualSegments.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const includeContextualSegmentsFieldName = 'campaignTargeting.contextualSegmentsTargeting.include';
const targetingTypeExclude = TARGETING_TYPES.EXCLUDE;
const excludeContextualSegmentsFieldName = 'campaignTargeting.contextualSegmentsTargeting.exclude';
const targetingTypeInclude = TARGETING_TYPES.INCLUDE;

const ContextualSegments = ({ selectedAccountId }) => {
    const [searchText, setSearchText] = useState('');
    const [targetingType, setTargetingType] = useState(TARGETING_TYPES.INCLUDE);
    const search = useDebouncedValue(searchText);

    const {
        isSegmentsLoading,
        treeStateParams,
        treeProps,
        includeLeaves,
        excludeLeaves,
        removeIncludeLeaves,
        removeExcludeLeaves,
        includeFailedValidationData,
        excludeFailedValidationData,
    } = useContextualSegments({
        search,
        targetingType,
        selectedAccountId,
        excludeContextualSegmentsFieldName,
        includeContextualSegmentsFieldName,
    });

    useSendSegmentSearchEvent({
        component: 'Contextual Segments Search',
        searchText: search,
        resultsCount: treeStateParams.leaves?.length,
        isLoading: isSegmentsLoading,
    });

    const { width, ref } = useElementSize();

    return (
        <div className={styles['container']}>
            <div className={styles['dropdown-container']} ref={ref}>
                <TargetingTypeBareDropdown onChange={({ value }) => setTargetingType(value)} value={targetingType} />
                <div className={styles['delimiter']} />
                <ContextualSegmentsTreeProvider limit={MAX_SEGMENTS_TO_SELECT} {...treeProps}>
                    <ContextualSegmentsDropdown
                        search={searchText}
                        onSearchChange={text => setSearchText(text)}
                        width={width}
                    />
                </ContextualSegmentsTreeProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.contextualSegmentsTargeting.include"
                items={includeLeaves}
                ItemComponent={ContextualSegmentListItemContentWithValidations}
                metadata={{
                    targetingType: targetingTypeInclude,
                    field: includeContextualSegmentsFieldName,
                }}
                deleteItem={treeProps.handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.contextual.segments.title.included"
                        defaultMessage="Included Targeted Context"
                    />
                }
                clearItems={removeIncludeLeaves}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...includeFailedValidationData}
                indicationContainerClass={commonStyles['warning-indication']}
            />
            <CollapsibleListWithIndication
                id="campaignTargeting.contextualSegmentsTargeting.exclude"
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.contextual.segments.title.excluded"
                        defaultMessage="Excluded Targeted Context"
                    />
                }
                items={excludeLeaves}
                ItemComponent={ContextualSegmentListItemContentWithValidations}
                metadata={{
                    targetingType: targetingTypeExclude,
                    field: excludeContextualSegmentsFieldName,
                }}
                addItem={noop}
                deleteItem={treeProps.handleRemoveItem}
                clearItems={removeExcludeLeaves}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...excludeFailedValidationData}
            />
        </div>
    );
};

ContextualSegments.propTypes = {
    selectedAccountId: PropTypes.string,
};

export { ContextualSegments };

export default ContextualSegments;
