import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Button, CloseIcon } from 'tuui';
import { IndicationContent, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './successIncidation.module.scss';

export const SUCCESS_INDICATION_TYPES = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE',
};

const modeMessageMap = {
    [SUCCESS_INDICATION_TYPES.MULTIPLE]: (
        <FormattedMessage
            id="creative.creator.aiTitleSuggestions.successIndication.multiple"
            defaultMessage="<b>Great!</b> <i>Please select a description or scroll down to select a second title.</i>"
        />
    ),
    [SUCCESS_INDICATION_TYPES.SINGLE]: (
        <FormattedMessage
            id="creative.creator.aiTitleSuggestions.successIndication.single"
            defaultMessage="<b>Great!</b> <i>Please select a description.</i>"
        />
    ),
};

export const SuccessIndication = ({ onCloseClick, successMessageType }) => {
    const indicationMessage = modeMessageMap[successMessageType];

    return (
        <IndicationContent
            type={INDICATION_TYPES.SUCCESS}
            containerClassName={styles['container']}
            contentClassName={styles['content']}
        >
            {indicationMessage}
            <Button
                size={Button.size.sm}
                variant={Button.variant.text}
                onClick={onCloseClick}
                className={styles['close-button']}
            >
                <CloseIcon className={styles['close-icon']} />
            </Button>
        </IndicationContent>
    );
};

SuccessIndication.propTypes = {
    onCloseClick: PropTypes.func,
    successMessageType: PropTypes.string,
};

SuccessIndication.defaultProps = {
    onCloseClick: noop,
    successMessageType: 'SINGLE',
};
