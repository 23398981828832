import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { setApplicationStatus } from '../actions';
import { appStatusSelector } from '../selectors';
import { COMPONENT_STATUS } from '../services/constants';
import { useCurrentValueGetter } from './useCurrentValueGetter';
import { useNonAuthAccess } from './useNonAuthAccess';

export const useSyncedAppState = () => {
    const dispatch = useDispatch();
    const { isAccountFetched } = useAccount();
    const appStatus = useSelector(appStatusSelector);
    const appStatusGetter = useCurrentValueGetter(appStatus);
    const nonAuthAccess = useNonAuthAccess();

    // Sync app status with config and account state
    useEffect(() => {
        if (isAccountFetched || nonAuthAccess) {
            dispatch(setApplicationStatus(COMPONENT_STATUS.ACTIVE));
            return;
        }
        const status = appStatusGetter();
        if (status !== COMPONENT_STATUS.INITIAL && status !== COMPONENT_STATUS.LOADING) {
            dispatch(setApplicationStatus(COMPONENT_STATUS.LOADING));
        }
    }, [isAccountFetched, nonAuthAccess, dispatch, appStatusGetter]);
};
