import React, { useCallback, useEffect } from 'react';
import { FormField, CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import commonStyles from '../CardPaymentMethodCreator/cardPaymentMethodCreator.module.scss';

export const PaymentMethodTaxRegistered = () => {
    const {
        value: taxRegistered,
        onChange,
        mode,
    } = useFormFieldValue({
        field: 'taxData.isTaxRegistered',
    });

    const onCheck = useCallback(() => onChange(true), [onChange]);
    const onUnCheck = useCallback(() => onChange(false), [onChange]);

    useEffect(() => {
        if (mode === FORM_MODES.CREATE) {
            onCheck();
        }
    }, [mode, onCheck]);

    return (
        <FormField containerClass={commonStyles['form-field']} inputId="isTaxRegistered">
            <CheckboxGroup
                onSelectCheckbox={onCheck}
                onUnselectCheckbox={onUnCheck}
                selectedValues={[taxRegistered] || []}
            >
                <TextCheckbox
                    id="isTaxRegistered"
                    value
                    title={
                        <FormattedMessage id="app.taxRegistered.checkbox.label" defaultMessage="I'm GST registered" />
                    }
                    disabled={mode !== FORM_MODES.CREATE}
                    data-metrics-component="PaymentMethodTaxRegistered"
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-value={!taxRegistered}
                />
            </CheckboxGroup>
        </FormField>
    );
};
