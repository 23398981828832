import { compact, entries, get, intersection, isEmpty, keys, mapValues, noop, orderBy, pickBy } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { throwOnDebug } from 'services/isDebugMode';
import { throwCriticalError } from '../../../formData/utils/throwCriticalError';
import {
    getValidationIndicationType,
    getValidationMessage,
    getValidationMessageParams,
    isErrorValidation,
    isWarningValidation,
    partitionValidationResults,
} from '../../utils';
import { ALL_VALIDATION_EVENTS, ERROR_MSG_PARAMS_SYM, ValidationState } from './constants';
import { SimpleEventEmitter } from './simpleEventEmitter';

const ensureArray = itemOrArray => (Array.isArray(itemOrArray) ? itemOrArray : [itemOrArray]);

export class ValidationService extends SimpleEventEmitter {
    static INVALID_EVENT = 'invalid';
    static VALID_EVENT = 'valid';
    static CHANGE_EVENT = 'change';
    static VALIDATION_START_EVENT = 'validationStart';
    static VALIDATION_END_EVENT = 'validationEnd';
    static EXPLICIT_VALIDATION_CALLED = 'explicitValidationCalled';
    static VALIDATION_REGISTERED_EVENT = 'validationRegistered';
    static VALIDATION_STATE_UPDATED = 'validationStateUpdated';

    constructor() {
        super();
        this.validationLastSuccessCache = new WeakMap();
        this.pendingValidationRunsByEvent = {};
        this.validationState = {};
        this.inTransition = false;
    }

    get isCurrentlyValid() {
        const allValidationStates = Object.values(this.validationState);
        return !allValidationStates.some(({ state }) => state === ValidationState.Failed);
    }

    *[Symbol.iterator]() {
        yield* Object.keys(this.validationState);
    }

    getCurrentValidationState = fieldId => {
        return this.validationState[fieldId];
    };

    getInvalidFields = () =>
        entries(this.validationState)
            .filter(([, { state }]) => state === ValidationState.Failed || state === ValidationState.Warning)
            .map(([fieldId, value]) => ({
                fieldId,
                failedValidationData: get(value, 'result.failedValidationData'),
            }));

    getChangeEvent = fieldId => {
        return `${fieldId}:${ValidationService.CHANGE_EVENT}`;
    };

    getRegisterEvent = fieldId => {
        return `${fieldId}:${ValidationService.VALIDATION_REGISTERED_EVENT}`;
    };

    onExplicitValidationCalled = callback => {
        return this.on(ValidationService.EXPLICIT_VALIDATION_CALLED, callback, Infinity);
    };

    onRegistered = (fieldId, callback) => {
        return this.on(this.getRegisterEvent(fieldId), callback);
    };

    onInvalid = (fieldId, callback) => {
        return this.on(`${fieldId}:${ValidationService.INVALID_EVENT}`, callback);
    };

    onValid = (fieldId, callback) => {
        return this.on(`${fieldId}:${ValidationService.VALID_EVENT}`, callback);
    };

    onChange = (fieldId, callback) => {
        return this.on(this.getChangeEvent(fieldId), callback);
    };

    onValidationStart = (fieldId, callback) => {
        return this.on(`${fieldId}:${ValidationService.VALIDATION_START_EVENT}`, callback);
    };

    onValidationEnd = (fieldId, callback) => {
        return this.on(`${fieldId}:${ValidationService.VALIDATION_END_EVENT}`, callback);
    };

    onValidationStateUpdated = callback => {
        return this.on(ValidationService.VALIDATION_STATE_UPDATED, callback, Infinity);
    };

    applyChange = (fieldId, value, dependencies = {}, { triggerValidation = true, metadata = null } = {}) => {
        const validationState = this.getCurrentValidationState(fieldId);
        if (metadata) {
            validationState.metadata = { ...(validationState.metadata ?? {}), ...metadata };
        }
        validationState.validationInput = { value, ...dependencies };
        if (triggerValidation) {
            return this.trigger(this.getChangeEvent(fieldId));
        }
    };

    reset = () => {
        Object.keys(this.validationState).forEach(fieldId => {
            const state = this.validationState[fieldId];
            state.state = ValidationState.Unknown;
            this.trigger(`${fieldId}:${ValidationService.VALID_EVENT}`);
        });
    };

    clearAllFieldListeners = fieldId => this.off(`^${fieldId}:`);

    clearValidation = fieldId => {
        this.clearAllFieldListeners(fieldId);
        if (this.inTransition) {
            this.annotateValidation(fieldId, { cleared: true });
        } else {
            delete this.validationState[fieldId];
            this.trigger(ValidationService.VALIDATION_STATE_UPDATED);
        }
    };

    registerValidation = (fieldId, { getFocusPriorityValue = noop, validations = [] } = {}) => {
        this.inTransition = false;
        if (this.validationState[fieldId]) {
            if (!this.validationState[fieldId].metadata?.cleared) {
                const message = `Attempted more than one register of "${fieldId}". This is currently unsupported.`;
                throwCriticalError(new Error({ message }));
                return console.warn(message);
            }
            delete this.validationState[fieldId].metadata.cleared;
        }
        const state = (this.validationState[fieldId] = {
            fieldId,
            getFocusPriorityValue,
            state: ValidationState.Unknown,
        });
        this.setValidations(fieldId, validations);
        state.clearChangeHandler = this.onChange(fieldId, () => {
            return this.triggerEventValidations(fieldId, null);
        });
        this.trigger(this.getRegisterEvent(fieldId));
    };

    annotateValidation = (fieldId, metadata = {}) => {
        const validationState = this.getCurrentValidationState(fieldId);
        if (!validationState) {
            return throwOnDebug(`Attempting to annotate a validation that doesn't exist: ${fieldId}`);
        }
        validationState.metadata = { ...(validationState.metadata ?? {}), ...metadata };
    };

    setValidations = (fieldId, validations) => {
        this.validationState[fieldId].validations = validations.map(validation => {
            let { events = [] } = validation;
            if (!Array.isArray(events)) {
                events = [events];
            }

            return {
                ...validation,
                events: new Set(events),
            };
        });
    };

    getValidations = (fieldId, validationEvent) => {
        const allValidations = this.validationState[fieldId]?.validations ?? [];
        if (validationEvent === undefined) {
            return allValidations;
        }
        const validationEvents = ensureArray(validationEvent);
        return allValidations.filter(
            validation => !validation.events.size || validationEvents.some(e => validation.events.has(e))
        );
    };

    runValidationsForFieldId = async (fieldId, validations) => {
        const { validationInput, metadata } = this.validationState[fieldId];
        const uncachedValidations = validations
            .filter(validation => {
                return this.validationLastSuccessCache.get(validation) !== validationInput;
            })
            .map(validation => {
                const forceWarningOnCurrentInput = metadata?.treatErrorsAsWarning;

                if (forceWarningOnCurrentInput) {
                    return {
                        ...validation,
                        severity: INDICATION_TYPES.WARNING,
                    };
                }
                return validation;
            });
        const { failure, partitions } = await partitionValidationResults(uncachedValidations, validationInput);
        const { validation: failedValidation, error: failedValidationError } = failure || {};
        const isFailedValidationOfErrorType = isErrorValidation(failedValidation);
        const isFailedValidationOfWarningType = isWarningValidation(failedValidation);
        const message = getValidationMessage(failedValidation, failedValidationError, validationInput);
        const messageParameters = getValidationMessageParams(failedValidation, failedValidationError, validationInput);
        const indicationType = getValidationIndicationType(failedValidation);

        partitions.errors.concat(partitions.warnings).forEach(({ validation }) => {
            this.validationLastSuccessCache.delete(validation);
        });
        partitions.passed.forEach(({ validation }) => {
            this.validationLastSuccessCache.set(validation, validationInput);
        });

        return {
            failedValidation,
            failedValidationData: {
                indicationType,
                message,
                [ERROR_MSG_PARAMS_SYM]: messageParameters,
            },
            isFailedValidationOfErrorType,
            isFailedValidationOfWarningType,
        };
    };

    processValidationsForId = async (fieldId, validations, resultMetadata) => {
        if (!validations.length) {
            return null;
        }
        const validationState = this.validationState[fieldId];
        validationState.state = ValidationState.Pending;
        this.trigger(`${fieldId}:${ValidationService.VALIDATION_START_EVENT}`);
        const validationResult = await this.runValidationsForFieldId(fieldId, validations, resultMetadata);

        if (validationResult.isFailedValidationOfErrorType) {
            validationState.result = validationResult;
            validationState.state = ValidationState.Failed;
        } else if (validationResult.isFailedValidationOfWarningType) {
            validationState.result = validationResult;
            validationState.state = ValidationState.Warning;
        } else {
            validationState.result = null;
            validationState.state = ValidationState.Passed;
        }
        this.trigger(`${fieldId}:${ValidationService.VALIDATION_END_EVENT}`);
        return [fieldId, { ...resultMetadata, ...validationResult }];
    };

    getTopPriorityField = fieldIds => {
        const fieldIdsSortedByFocusPriority = orderBy(fieldIds, vid =>
            this.validationState[vid].getFocusPriorityValue()
        );
        const [topPriorityFieldId] = fieldIdsSortedByFocusPriority;
        return topPriorityFieldId;
    };

    manuallyTriggerValidationFocus = fieldIds => {
        const onlyRegisteredFields = intersection(keys(this.validationState), fieldIds);

        if (isEmpty(onlyRegisteredFields)) {
            return;
        }

        const topPriorityInvalidField = this.getTopPriorityField(onlyRegisteredFields);
        onlyRegisteredFields.forEach(fieldId => {
            this.trigger(`${fieldId}:${ValidationService.INVALID_EVENT}`, {
                isTopPriorityError: fieldId === topPriorityInvalidField,
                triggeredManually: true,
                forceIgnore: true,
            });
        });
    };

    runValidations = async (fieldIdToValidationsMap, resultMetadata = {}) => {
        const fieldIdToValidationResultMap = new Map(
            compact(
                await Promise.all(
                    entries(fieldIdToValidationsMap).map(async ([fieldId, validations]) => {
                        return this.processValidationsForId(fieldId, validations, resultMetadata);
                    })
                )
            )
        );
        // fields might be unmounted mid validation
        for (let fieldId of fieldIdToValidationResultMap.keys()) {
            if (!this.validationState[fieldId]) {
                fieldIdToValidationResultMap.delete(fieldId);
            }
        }
        const fieldIds = Array.from(fieldIdToValidationResultMap.keys());
        const invalidFields = fieldIds.filter(vid => this.validationState[vid].state === ValidationState.Failed);
        const topPriorityInvalidField = this.getTopPriorityField(invalidFields);
        for (let fieldId of fieldIdToValidationResultMap.keys()) {
            const { state } = this.validationState[fieldId];
            const validity =
                state !== ValidationState.Passed ? ValidationService.INVALID_EVENT : ValidationService.VALID_EVENT;
            const event = `${fieldId}:${validity}`;
            const validityEventData = fieldIdToValidationResultMap.get(fieldId);
            if (topPriorityInvalidField === fieldId) {
                validityEventData.isTopPriorityError = true;
            }
            this.trigger(event, validityEventData);
        }
        const invalidKeys = Object.keys(this.validationState).filter(fieldId => {
            const field = this.validationState[fieldId];
            return field.state === ValidationState.Failed && !field.metadata?.cleared;
        });
        this.trigger(ValidationService.VALIDATION_STATE_UPDATED);
        return invalidKeys;
    };

    triggerEventValidations = (fieldId, validationEvent, metadata) => {
        const validations = this.getValidations(fieldId, validationEvent);
        if (!validations.length) {
            return;
        }
        return this.runValidations(
            {
                [fieldId]: validations,
            },
            metadata
        );
    };

    getValidationsMapByValidationEvents = validationEvents => {
        const validateAll = validationEvents.some(e => e === ALL_VALIDATION_EVENTS);
        const fieldIdToValidationsMap = mapValues(this.validationState, ({ validations }) => {
            if (validateAll) {
                return validations;
            }
            return validations?.filter(
                validation => !validation.events.size || validationEvents.some(e => validation.events.has(e))
            );
        });
        const onlyFieldsWithValidations = pickBy(fieldIdToValidationsMap, validations => !isEmpty(validations));
        return onlyFieldsWithValidations;
    };

    validateEvent = async (validationEvent, resultMetadata) => {
        const validationEvents = ensureArray(validationEvent);
        const validationEventsPendingKey = validationEvents.sort().join('|');
        if (this.pendingValidationRunsByEvent[validationEventsPendingKey]) {
            return this.pendingValidationRunsByEvent[validationEventsPendingKey];
        }
        const onlyFieldsWithValidations = this.getValidationsMapByValidationEvents(validationEvents);
        this.pendingValidationRunsByEvent[validationEventsPendingKey] = this.runValidations(
            onlyFieldsWithValidations,
            resultMetadata
        );
        this.pendingValidationRunsByEvent[validationEventsPendingKey].then(() => {
            delete this.pendingValidationRunsByEvent[validationEventsPendingKey];
        });
        return this.pendingValidationRunsByEvent[validationEventsPendingKey];
    };

    validate = async (validationEvents = ALL_VALIDATION_EVENTS) => {
        await this.trigger(ValidationService.EXPLICIT_VALIDATION_CALLED);
        const invalidFields = await this.validateEvent(validationEvents, { triggeredManually: true });

        if (!invalidFields.length) {
            this.inTransition = true;
        }

        return invalidFields;
    };
}
