export const defaultColumnDefinition = {
    minWidth: 45,
    width: 100,
    maxWidth: 800,
    editable: false,
    lockVisible: true,
    sortable: true,
    resizable: true,
};

export const editableColumnDefinition = {
    editable: true,
    singleClickEdit: true,
};

export const staticColumnDefinition = {
    editable: false,
    sortable: false,
    suppressMovable: true,
    resizable: false,
    headerComponentParams: {
        disableLoading: true,
    },
};

export const checkBoxColumnDefinition = {
    headerName: '',
    field: '',
    width: 48,
    minWidth: 48,
    maxWidth: 48,
    lockPosition: true,
};

export const lockedPositionColumnDefinition = {
    lockPosition: true,
};

export const toggleColumnDefinition = {
    width: 48,
    minWidth: 48,
    maxWidth: 48,
};

export const rightPinnedColumnDefinition = {
    pinned: 'right',
    lockPinned: true,
};

export const leftPinnedColumnDefinition = {
    pinned: 'left',
    lockPinned: true,
};

export const leftPinnedToggleColumnDefinition = {
    ...toggleColumnDefinition,
    pinned: 'left',
};

export const alwaysVisibleColumnDefinition = {
    lockVisible: true,
};

export const hiddenColumnDefinition = {
    hide: true,
};

export const actionsColumnDefinition = {
    width: 75,
    minWidth: 75,
    maxWidth: 75,
};

export const keyColumnDefinition = {
    width: 270,
};

export const bypassMetadataFilterTypeDefinition = {};
