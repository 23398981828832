import { keyBy } from 'lodash';
import { WorkspacePremiumOutlinedIcon, CellTowerIcon, AppleNewsIcon, EmailOutlinedIcon } from 'tuui';

export const SUPPLY_TARGETING_TYPES = {
    ALL: 'ALL',
    TABOOLA_SELECT: 'TABOOLA_SELECT',
    YAHOO: 'YAHOO',
    APPLE_NEWS: 'APPLE_NEWS',
    YAHOO_MAIL: 'YAHOO_MAIL',
    TABOOLA_CURATED: 'TABOOLA_CURATED',
};

const allOption = {
    id: SUPPLY_TARGETING_TYPES.ALL,
    value: SUPPLY_TARGETING_TYPES.ALL,
    label: 'Optimal Scale & Performance',
    icon: <CellTowerIcon />,
    enableCondition: ({ supplyTargetingResource }) => supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.ALL]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.ALL]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.ALL,
    subtext: 'All Taboola Publishers and placements. Use other sections in campaign set up to refine targeting.',
};

const taboolaSelectOption = {
    id: SUPPLY_TARGETING_TYPES.TABOOLA_SELECT,
    value: SUPPLY_TARGETING_TYPES.TABOOLA_SELECT,
    label: 'Taboola Select',
    icon: <WorkspacePremiumOutlinedIcon />,
    enableCondition: ({ supplyTargetingResource }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.TABOOLA_SELECT]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.TABOOLA_SELECT]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.TABOOLA_SELECT,
    subtext:
        'Package of premium placements on a curated list of  Taboola Publishers. Only for approved advertisers and must adhere to floor pricing.',
};

const yahooOption = {
    id: SUPPLY_TARGETING_TYPES.YAHOO,
    value: SUPPLY_TARGETING_TYPES.YAHOO,
    label: 'Yahoo O&O Only',
    enableCondition: ({ supplyTargetingResource }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.YAHOO]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.YAHOO]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.YAHOO,
};

const appleNewsOption = {
    id: SUPPLY_TARGETING_TYPES.APPLE_NEWS,
    value: SUPPLY_TARGETING_TYPES.APPLE_NEWS,
    label: 'Apple News',
    icon: <AppleNewsIcon />,
    enableCondition: ({ supplyTargetingResource }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.APPLE_NEWS]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.APPLE_NEWS]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.APPLE_NEWS,
    subtext:
        'Apple News And Apple Stocks provide highly-relevant, engaging, and brand-safe in-app content without sacrificing user privacy and trust. Available in US, CA, AU & UK only.',
};

const yahooMailOption = {
    id: SUPPLY_TARGETING_TYPES.YAHOO_MAIL,
    value: SUPPLY_TARGETING_TYPES.YAHOO_MAIL,
    label: 'Yahoo Mail',
    icon: <EmailOutlinedIcon />,
    enableCondition: ({ supplyTargetingResource }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.YAHOO_MAIL]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.YAHOO_MAIL]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.YAHOO_MAIL,
    subtext:
        'Run a separate Mail-only campaign to exclusively target Yahoo Mail supply and maximize efficiency. Available in US, UK, CA, AU, DE, FR, BR and SG',
};

const taboolaCuratedOption = {
    id: SUPPLY_TARGETING_TYPES.TABOOLA_CURATED,
    value: SUPPLY_TARGETING_TYPES.TABOOLA_CURATED,
    label: 'Taboola Curated',
    enableCondition: ({ supplyTargetingResource }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.TABOOLA_CURATED]?.isEditable,
    visibilityCondition: ({ supplyTargetingResource, campaignSupplyTargeting }) =>
        supplyTargetingResource?.[SUPPLY_TARGETING_TYPES.TABOOLA_CURATED]?.isVisible ||
        campaignSupplyTargeting === SUPPLY_TARGETING_TYPES.TABOOLA_CURATED,
};

export const supplyTargetingDropdownOptions = [taboolaSelectOption, yahooOption, taboolaCuratedOption];

export const supplyTargetingRadioOptions = [allOption, taboolaSelectOption, appleNewsOption, yahooMailOption];

export const supplyTargetingOptions = [
    allOption,
    taboolaSelectOption,
    yahooOption,
    appleNewsOption,
    yahooMailOption,
    taboolaCuratedOption,
];
export const supplyTargetingRadioOptionsByKeys = keyBy(supplyTargetingRadioOptions, 'value');
