import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown, FileTypes } from 'taboola-ultimate-ui';
import { TagsInputSearch } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useDropdownOptions, useDropdownValue } from 'hooks';
import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';
import { IMAGE, THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { emptyGalleryTypes } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/ImageGallery/EmptyGallery';
import { useMediaTabsContext } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/MediaTabsContextProvider/MediaTabsContextProvider';
import { PaginatedGallery } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/PaginatedGallery';
import RECOMMENDED_MEDIA_DROPDOWN_TYPES from 'modules/campaigns/modules/creative-editor/components/MediaTabs/RecommendedMediaTab/RecommendedMediaDropdownTypes';
import useRecommendedMediaFetchService from 'modules/campaigns/modules/creative-editor/components/MediaTabs/RecommendedMediaTab/services/useRecommendedMediaFetchService';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { operationsApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './paginatedRecommendedMediaTab.module.scss';

const emptyGalleryMessagesPrefix = 'creative.editor.recommended';
const classNameBuilder = classnames.bind(styles);
const NUM_MAX_ITEMS = 120;

export const PaginatedRecommendedMediaTab = ({ keywords, onSelectMedia, resetRecommendedMediaAction, columnCount }) => {
    const {
        mode,
        formAccount: { accountName: selectedAccountName },
    } = useFormDataContext();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const { campaignId, titleList } = useMediaTabsContext();
    const [tags, setTags] = useState(() =>
        keywords.length > 0 ? keywords.map(item => ({ label: item.name, value: item.id })) : []
    );
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    const initialRecommendedMediaDropdownValue = useMemo(() => {
        if (mode === FORM_MODES.CREATE || isUnifiedCreativesFormDuplicateMode) {
            return RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL;
        }

        if (creativeType === CREATIVE_TYPE.MOTION) {
            return RECOMMENDED_MEDIA_DROPDOWN_TYPES.VIDEOS;
        }

        return RECOMMENDED_MEDIA_DROPDOWN_TYPES.IMAGES;
    }, [mode, creativeType, isUnifiedCreativesFormDuplicateMode]);
    const [recommendedMediaDropdownValue, setRecommendedMediaDropdownValue] = useState(
        initialRecommendedMediaDropdownValue
    );

    const selectMediaHandler = (data, selected) => {
        const isMotion = data.fileType === FileTypes.VIDEO;
        const recommendedVideoPojo = {
            video: {
                url: data.url,
                cdnUrl: data.cdnUrl,
                id: data.id,
                fileType: data.fileType,
                source: data.source,
            },
            defaultImageInput: { campaignId, titles: titleList },
        };
        const urlResolver = () =>
            isMotion
                ? operationsApi
                      .uploadRecommendedVideo(selectedAccountName, recommendedVideoPojo)
                      .then(({ cdnUrl, fallbackImageUrl, gifUrl }) => ({
                          url: cdnUrl,
                          gifUrl,
                          fallbackImageUrl,
                          recommendedFBImage: fallbackImageUrl,
                      }))
                : operationsApi
                      .uploadRecommendedImages(selectedAccountName, data)
                      .then(response => ({ url: response.cdnUrl }));
        const motionMetadata = isMotion ? { motionAdsStudio: { vendorVideoId: data.id } } : {};

        onSelectMedia(urlResolver, {
            id: data.id,
            mediaUploadSource: THUMBNAIL_SOURCE_TYPES.RECOMMENDED_MEDIA,
            fileType: data.fileType || IMAGE,
            selected,
            ...motionMetadata,
        });
    };

    const dropdownValueRef = useRef(recommendedMediaDropdownValue);
    const changeHandlerDropdownInRecommendedMedia = useCallback(
        function (newVal) {
            dropdownValueRef.current = recommendedMediaDropdownValue;
            return setRecommendedMediaDropdownValue(newVal.value);
        },
        [recommendedMediaDropdownValue]
    );
    const isDropdownValueChanged = dropdownValueRef.current !== recommendedMediaDropdownValue;
    const {
        loadedMedia: loadedRecommendedMedia,
        reload,
        loadNextPage,
        isItemLoaded,
        paginationStatus: status,
        isModuleReady,
        totalValues,
    } = useRecommendedMediaFetchService({
        accountId: selectedAccountName,
        tags,
        mediaType: recommendedMediaDropdownValue,
        isMediaTypeChanged: isDropdownValueChanged,
        maxTotalItems: NUM_MAX_ITEMS,
    });

    const handleTagDelete = tagToDelete => {
        if (status === COMPONENT_STATUS.LOADING) {
            return;
        }
        setTags(() => tags.filter(tag => tag !== tagToDelete));
    };
    const handleTagAddition = tagToAdd => {
        if (status === COMPONENT_STATUS.LOADING) {
            return;
        }
        setTags(() => [...tags, tagToAdd]);
    };

    useEffect(() => {
        if (!isModuleReady) {
            return;
        }

        resetRecommendedMediaAction();
        dropdownValueRef.current = recommendedMediaDropdownValue;
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags, isModuleReady, recommendedMediaDropdownValue]);

    const recommendedMediaOptionForDropdown = [
        { value: RECOMMENDED_MEDIA_DROPDOWN_TYPES.ALL, label: 'All' },
        { value: RECOMMENDED_MEDIA_DROPDOWN_TYPES.VIDEOS, label: 'Videos' },
        { value: RECOMMENDED_MEDIA_DROPDOWN_TYPES.IMAGES, label: 'Images' },
    ];
    const optionsForDropdownInRecommendedMedia = useDropdownOptions(recommendedMediaOptionForDropdown);
    const selectedRecommendedMediaDropdownValue = useDropdownValue({ value: recommendedMediaDropdownValue });
    const isDisabled = useMemo(() => {
        if (isUnifiedCreativesFormDuplicateMode) {
            return false;
        }

        return mode !== FORM_MODES.CREATE;
    }, [isUnifiedCreativesFormDuplicateMode, mode]);

    return (
        <div className={styles['container']}>
            <div className={classNameBuilder('wrapper-for-dropdown-media-library', {})}>
                <BareDropdown
                    onChange={changeHandlerDropdownInRecommendedMedia}
                    options={optionsForDropdownInRecommendedMedia}
                    value={recommendedMediaDropdownValue}
                    selectedValueObject={selectedRecommendedMediaDropdownValue}
                    disabled={isDisabled}
                />
                <TagsInputSearch
                    tags={tags}
                    handleDelete={handleTagDelete}
                    handleAddition={handleTagAddition}
                    isDisabled={status === COMPONENT_STATUS.LOADING}
                />
            </div>
            <div className={styles['image-gallery-container']}>
                <PaginatedGallery
                    accountId={selectedAccountName}
                    items={loadedRecommendedMedia}
                    itemOnClick={selectMediaHandler}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadNextPage}
                    totalItemCount={Math.min(totalValues, NUM_MAX_ITEMS)}
                    scrollbarWidth={11}
                    emptyGalleryMessagesPrefix={emptyGalleryMessagesPrefix}
                    emptyGalleryType={
                        tags.length === 0
                            ? emptyGalleryTypes.RECOMMENDED_IMAGES_INITIAL
                            : emptyGalleryTypes.RECOMMENDED_IMAGES_UNKNOWN
                    }
                    status={tags.length === 0 ? COMPONENT_STATUS.ACTIVE : status}
                    columnCount={columnCount}
                />
            </div>
            <div className={styles['tab-description']}>
                <FormattedMessage
                    id="creative.creator.media.tab.stockImages.description"
                    defaultMessage="* By clicking SUBMIT, I acknowledge that I may only use this media in campaign thumbnails that I run on the Taboola Network. For any additional or different uses of this media, I must obtain all necessary licenses and permissions directly from Getty Images."
                />
            </div>
        </div>
    );
};

PaginatedRecommendedMediaTab.propTypes = {
    keywords: PropTypes.array,
    onSelectMedia: PropTypes.func,
    resetRecommendedMediaAction: PropTypes.func,
    columnCount: PropTypes.number,
};

PaginatedRecommendedMediaTab.defaultProps = {
    keywords: [],
    onSelectMedia: noop,
    resetRecommendedMediaAction: () => {},
};
