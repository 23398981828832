import React from 'react';
import ReportBreadcrumbs from '../../../campaigns/components/ReportBreadcrumbs/ReportBreadcrumbs';
import useBillingBreadcrumbs from '../../hooks/useBillingBreadcrumbs';

const BillingBreadcrumbs = ({ title, className }) => {
    const { steps } = useBillingBreadcrumbs({
        title,
        isTitleAsLastStep: false,
    });

    return (
        <div className={className}>
            <ReportBreadcrumbs title={title} steps={steps} />
        </div>
    );
};

export default BillingBreadcrumbs;
