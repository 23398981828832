import { useMemo } from 'react';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { PROGRESS_STATUS } from '../consts';

export const useGetOnboardingStatus = progressPercent => {
    const { formatMessage } = useIntl();
    const [, status] = useMemo(() => {
        return Object.entries(PROGRESS_STATUS).find(([progressCheckPoint]) => progressPercent <= progressCheckPoint);
    }, [progressPercent]);

    if (progressPercent < 20) {
        return;
    }
    return formatMessage({ id: `onboarding.assistant.status.${status}` });
};
