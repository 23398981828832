import { batch } from 'react-redux';
import { performanceRulesApi } from 'services/api';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import {
    deletePerformanceRuleError,
    deletePerformanceRuleSuccess,
    requestDeletePerformanceRule,
} from '../actions/deletePerformanceRule';

export const successIndication = {
    message: <FormattedMessage id="performance.rules.delete.success" defaultMessage="Rule successfully deleted" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Unable to delete" />,
});
export const deletePerformanceRuleFlow =
    ({ accountName, ruleId, shouldUndoActions }) =>
    async dispatch => {
        dispatch(requestDeletePerformanceRule());
        try {
            const response = await performanceRulesApi.deletePerformanceRule(accountName, ruleId, shouldUndoActions);
            batch(() => {
                dispatch(deletePerformanceRuleSuccess(response));
                dispatch(addIndication(successIndication));
            });
        } catch (error) {
            batch(() => {
                dispatch(addIndication(generateErrorIndication(error)));
                dispatch(deletePerformanceRuleError(error));
            });
        }
    };
