import { fromBlob } from 'file-type/browser';

export const isFileTypeVideo = fileType => Boolean(fileType && fileType.match('video'));
export const isFileTypeGif = fileType => Boolean(fileType && fileType.match('gif'));
export const isFileTypeImage = fileType => !isFileTypeGif(fileType) && !isFileTypeVideo(fileType);
export const isFileTypeFallbackImage = fileType => fileType === 'fallbackImage';
export const asyncPartition = async (collection, predicatePromise) => {
    const results = await Promise.all(collection.map(predicatePromise));
    const truthyArray = collection.filter((item, index) => results[index]);
    const falsyArray = collection.filter((item, index) => !results[index]);
    return [truthyArray, falsyArray];
};

const getMimeType = async file => {
    const { mime } = await fromBlob(file);
    return mime;
};
export const isMotionFile = async file => {
    if (isFileTypeVideo(file.fileType)) {
        return true;
    }
    const mime = await getMimeType(file.file);
    return isFileTypeGif(mime);
};
