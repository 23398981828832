import React from 'react';
import { useSelector } from 'react-redux';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../selectors';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import { PredictiveAudiencePropertiesSection } from '../components/PredictiveAudiencePropertiesSection/PredictiveAudiencePropertiesSection';
import { PredictiveAudienceSetupSection } from '../components/PredictiveAudienceSetupSection/PredictiveAudienceSetupSection';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const PredictiveAudiencesEditorPage = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = useAudienceEditorState({
        reportType: REPORT_TYPE.PREDICTIVE,
    });
    const selectedAccount = useSelector(selectedAccountSelector);
    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="AudienceEditor"
            >
                <PredictiveAudienceSetupSection account={selectedAccount} />
                <div id="audience-properties">
                    <PredictiveAudiencePropertiesSection />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const PredictiveAudiencesEditorPageWithFormDataProvider = withFormDataProvider(PredictiveAudiencesEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { PredictiveAudiencesEditorPageWithFormDataProvider as PredictiveAudiencesEditorPage };
