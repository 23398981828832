import React from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import RadioGroupField from '../../../../../../modules/campaigns/components/Form/FormFields/RadioGroupField/RadioGroupField';
import { CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES } from '../../config';
import EvenOptimizationRadioButton from './EvenOptimizationRadioButton/EvenOptimizationRadioButton';
import styles from '../commonEditor.module.scss';

const CreativeOptimization = () => {
    const { value: creativeTrafficAllocationMethod, onChange: creativeTrafficAllocationMethodChange } =
        useFormFieldValue({ field: 'creativeTrafficAllocationMethod' });

    return (
        <RadioGroupField
            inputId="creative-optimization"
            groupTitle={
                <FormattedMessage id="campaign.editor.creative.optimization" defaultMessage="Ad Optimization" />
            }
            radioGroupName="creative-optimization"
            className={styles['input']}
            onChange={creativeTrafficAllocationMethodChange}
            selectedValue={creativeTrafficAllocationMethod}
            data-metrics-component="CreativeOptimization"
        >
            <Radio
                key="creative-optimization-optimized"
                value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED}
                title={
                    <FormattedMessage id="campaign.editor.creative.optimization.optimized" defaultMessage="Optimized" />
                }
                description={
                    <FormattedMessage
                        id="campaign.editor.creative.optimization.optimized.description"
                        defaultMessage="Optimized - (Recommended) the exposure of the items in your campaign will be determined by our algorithm, and your most engaging ads will be served more than others."
                    />
                }
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED}
            />
            <EvenOptimizationRadioButton />
        </RadioGroupField>
    );
};

export default CreativeOptimization;
