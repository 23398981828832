import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './campaignContentLoader.module.scss';

const CampaignContentLoader = ({ style }) => (
    <div style={style}>
        <ContentLoader height={60} width={300} speed={2} className={styles['content']}>
            <rect x="15" y="8" rx="8" ry="8" width="250" height="13" />
            <rect x="15" y="30" rx="8" ry="8" width="182" height="13" />
        </ContentLoader>
    </div>
);

export default CampaignContentLoader;
