/**
 * Created by oreuveni
 * Date: 2019-04-18
 * Time: 12:40
 */
const CONTENT_TYPES = {
    VIDEO: 'VIDEO',
    TEXT: 'TEXT',
    PHOTO: 'PHOTO',
    OTHER: 'OTHER',
};

export default CONTENT_TYPES;
