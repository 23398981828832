import React from 'react';
import PropTypes from 'prop-types';
import { ToggleSwitch } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './status.module.scss';

const Status = ({ formattedStatusMsg, isActive, onChange }) => (
    <div className={styles['container']}>
        <span
            className={styles['switch']}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="Status"
        >
            <ToggleSwitch checked={isActive} onChange={onChange} />
        </span>
        {formattedStatusMsg}
    </div>
);

Status.propTypes = {
    formattedStatusMsg: PropTypes.node,
    isActive: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Status;
