import React from 'react';
import styled from 'styled-components';
import { zIndex } from 'tuui/lib/theme/primitives';
import { scale, theme } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';
import { StepType } from './stepTypes';

interface StepProgressProps {
    stepType: StepType;
    progress: number;
    progressTitle?: string;
    progressType: ProgressType;
}

export type ProgressType = 'tail' | 'regular' | 'expanded';

const progressTypeToFlexGrow: Record<ProgressType, number> = {
    tail: 0,
    regular: 1,
    expanded: 3,
};

export const StepProgress: UIC<StepProgressProps> = ({
    stepType,
    progress,
    progressTitle,
    progressType,
}: StepProgressProps) => {
    return (
        <ProgressContainer $progressType={progressType}>
            <StepProgressBar $stepType={stepType} $progress={progress} />
            {progressTitle && <StepProgressTitle>{progressTitle}</StepProgressTitle>}
        </ProgressContainer>
    );
};

const ProgressContainer = styled.div<{ $progressType: ProgressType }>`
    box-sizing: border-box;
    display: flex;
    position: relative;
    background: var(--progress-background);
    align-items: center;
    height: ${scale('sm')};
    flex-grow: ${({ $progressType }) => progressTypeToFlexGrow[$progressType]};
    z-index: ${zIndex.firstLayer};
    border: ${scale('xxs')} solid var(--progress-background);
    ${({ $progressType }) => $progressType === 'tail' && `width: 15%`};
`;

const StepProgressBar = styled.span<{ $stepType: StepType; $progress: number }>`
    height: 100%;
    width: ${({ $progress }) => `${$progress}%`};
    background: ${({ $stepType }) => `var(--${$stepType}-progress-color)`};
`;

const StepProgressTitle = styled.span`
    position: absolute;
    left: 0;
    right: 0;
    top: -${scale('xxl')};
    align-items: center;
    align-content: center;
    text-align: center;
    color: var(--text-color);
    font-size: ${theme('typography.sizes.md')};
    font-weight: ${theme('typography.weight.regular')};
`;
