import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { getQueryParamFromLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { SET_REPORT_DURATION } from '../modules/campaigns-reports/actions/actionTypes';
import { DATE_PRESET, END_DATE, START_DATE } from '../modules/campaigns-reports/hooks/useDateRange';
import { selectedReportSelector } from '../selectors';

const campaignsAnalyticsEventCreators = {
    [SET_REPORT_DURATION]: (action, state) => ({
        event: GTM_EVENTS.TABLE_LOAD_TIME,
        pageName: getPageName(),
        tableDates: `${new Date(getQueryParamFromLocation(START_DATE))} - ${new Date(
            getQueryParamFromLocation(END_DATE)
        )} (${getQueryParamFromLocation(DATE_PRESET)})`,
        columnsPreset: action.payload.reportPresetName,
        viewBy: selectedReportSelector(state),
        loadTime: action.payload.timing,
    }),
};

export default campaignsAnalyticsEventCreators;
