const ARRAY_ELEMENT_REGEXP = /\[\d+\]|\.\d+\.|\.\d+$/;

export const fieldToString = field => (typeof field === 'string' ? field : field.join('.'));
export const isArrayElementField = rawField => {
    const field = fieldToString(rawField);
    return ARRAY_ELEMENT_REGEXP.test(field);
};
export const getParentArrayField = rawField => {
    const field = fieldToString(rawField);
    const index = field.search(ARRAY_ELEMENT_REGEXP);

    return field.slice(0, index);
};

export const getFullFieldName = (field = '', getPrefix) => {
    const parentPrefix = getPrefix();

    return `${parentPrefix}${parentPrefix ? '.' : ''}${field}`;
};
