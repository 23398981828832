import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cleanCreativesEditingResources } from '../flows';

// Cleans creative editing resources on unmount
const useCleanCreativeEditingResources = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => dispatch(cleanCreativesEditingResources());
    }, [dispatch]);
};

export default useCleanCreativeEditingResources;
