import React from 'react';
import { useCallback, useMemo } from 'react';
import { Button } from 'tuui';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { MODULE_NAME as CONVERSIONS_ROUTE } from 'modules/tracking';
import { useIsScheduleMeetingPermitted } from 'modules/tracking/hooks/useIsScheduleMeetingPermitted';
import { GetHelpButtonTooltip } from '../../../../../../tracking/components/GetHelpFromExpert/GetHelpButtonTooltip';
import { LightningIcon } from '../OnboardingStepInformation/OnboardingStepData/LightningIcon';
import { CTA_IDS, SUB_STEP_IDS } from '../consts';

const { COMPLETE_ACCOUNT_SETUP_1, COMPLETE_ACCOUNT_SETUP_2 } = SUB_STEP_IDS;
const { SCHEDULE_HELP, INSTALL_PIXEL } = CTA_IDS;

export const useCompleteAccountStepData = ({ stepCompleteData }) => {
    const { [COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL]: scheduleMeetingUrl } = useCommonConfig([
        COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL,
    ]);
    const isSchedulePermitted = useIsScheduleMeetingPermitted();
    const { hasActiveConversion, hasInstalledPixel } = stepCompleteData;

    const navigate = useNavigate();
    const navigateToConversions = useCallback(() => navigate(CONVERSIONS_ROUTE), [navigate]);
    const navigateToScheduleHelp = useCallback(() => window.open(scheduleMeetingUrl, '_blank'), [scheduleMeetingUrl]);

    const subStepData = useMemo(
        () => [
            {
                descriptionId: COMPLETE_ACCOUNT_SETUP_1,
                motivationId: COMPLETE_ACCOUNT_SETUP_1,
                buttons: [
                    ...(isSchedulePermitted
                        ? [
                              {
                                  stepId: COMPLETE_ACCOUNT_SETUP_1,
                                  CTAId: SCHEDULE_HELP,
                                  onClickCTA: navigateToScheduleHelp,
                                  variant: Button.variant.ghost,
                                  iconBefore: <LightningIcon />,
                                  isComplete: true,
                                  tooltip: <GetHelpButtonTooltip position={TOOLTIP_POSITION.TOP} />,
                              },
                          ]
                        : []),
                    {
                        stepId: COMPLETE_ACCOUNT_SETUP_1,
                        CTAId: INSTALL_PIXEL,
                        onClickCTA: navigateToConversions,
                        isComplete: hasInstalledPixel,
                        delayMsgId: 'onboarding.assistant.CTA.delay.pixel',
                        delayStateDurationMinutes: 60,
                    },
                ],
                learnMoreLinkId: COMPLETE_ACCOUNT_SETUP_1,
            },
            {
                descriptionId: COMPLETE_ACCOUNT_SETUP_2,
                motivationId: COMPLETE_ACCOUNT_SETUP_2,
                buttons: [
                    {
                        stepId: COMPLETE_ACCOUNT_SETUP_2,
                        onClickCTA: navigateToConversions,
                        isComplete: hasActiveConversion,
                        delayMsgId: 'onboarding.assistant.CTA.delay.conversion',
                        delayStateDurationMinutes: 60,
                    },
                ],
            },
        ],
        [hasActiveConversion, hasInstalledPixel, isSchedulePermitted, navigateToConversions, navigateToScheduleHelp]
    );
    return { subStepData };
};
