import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useSelectedAccountId } from 'hooks';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { LOCAL_STORAGE_KEY } from '../consts';
import { OnboardingAccountDataContext } from './onboardingAccountDataContext';

export const OnboardingAccountDataProvider = ({ children }) => {
    const [accountId] = useSelectedAccountId();
    const [getLocalStorage, setLocalStorage, isLocalStorageReady] = useLocalStorage();
    const [onboardingUserData, setOnboardingUserData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const onboardingAccountData = get(onboardingUserData, accountId);

    useEffect(() => {
        if (!isLoaded && isLocalStorageReady) {
            const localStorageData = getLocalStorage(LOCAL_STORAGE_KEY);
            setOnboardingUserData(localStorageData);
            setIsLoaded(true);
        }
    }, [accountId, getLocalStorage, setIsLoaded, isLocalStorageReady, isLoaded]);

    const setAccountLocalStorage = useCallback(
        accountData => {
            const localStorageData = getLocalStorage(LOCAL_STORAGE_KEY);
            if (isEmpty(accountData)) {
                const { [accountId]: accountData, ...restLocalStorage } = localStorageData;
                setLocalStorage(LOCAL_STORAGE_KEY, restLocalStorage);
                setOnboardingUserData(restLocalStorage);
            } else {
                const newData = { ...localStorageData, [accountId]: accountData };
                setLocalStorage(LOCAL_STORAGE_KEY, newData);
                setOnboardingUserData(newData);
            }
        },
        [accountId, getLocalStorage, setLocalStorage]
    );

    const value = useMemo(
        () => ({
            onboardingAccountData,
            isReady: isLoaded,
            setAccountLocalStorage,
        }),
        [onboardingAccountData, isLoaded, setAccountLocalStorage]
    );
    return <OnboardingAccountDataContext.Provider value={value}>{children}</OnboardingAccountDataContext.Provider>;
};
