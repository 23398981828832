import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

export const DailyImpressionCapFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.DailyImpressionCap.tooltip"
            defaultMessage="A daily limitation of video impressions."
        />
    </TooltipSection>
);
