import { FormField } from 'taboola-ultimate-ui';
import styles from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/advancedSettingsSection.module.scss';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const PerformanceRuleFrequency = ({ hours }) => {
    return (
        <FormField
            inputId="frequency"
            label={
                <FormattedMessage
                    id="app.create.performance.rules.advanced.settings.frequency"
                    defaultMessage="Frequency"
                />
            }
            description={
                <FormattedMessage
                    id="app.create.performance.rules.advanced.settings.frequency.description"
                    defaultMessage="Every {hours} hours"
                    values={{ hours }}
                />
            }
            containerClass={styles['frequency']}
        />
    );
};
