import React, { useCallback, useEffect } from 'react';
import { identity } from 'lodash';
import { SuggestionContentTitle } from 'modules/campaigns/components/SuggestionContentTitle/SuggestionContentTitle';
import { useAiVariationsEventsPrefix } from 'modules/campaigns/modules/creative-creator/components/AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import { MAX_TITLES_COUNT } from 'modules/campaigns/modules/creative-creator/config';
import { AI_VARIATIONS_FIELD_NAMES } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { splitOnNewLine } from 'services/utils/stringUtils';
import { aiVariationsCreativeTitleValidations } from '../../../../../../../../config';
import { useCreateFormSelectedCampaignId } from '../../../../../../hooks/useCreateFormSelectedCampaignId';
import styles from './titleInput.module.scss';

export const TitleInput = ({ index, ignoreValidations, openModal }) => {
    const campaignId = useCreateFormSelectedCampaignId();
    const eventsPrefix = useAiVariationsEventsPrefix();
    const { onChange: onChangeTitles } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.TITLES });
    const { value: title, onChange: onChangeTitle } = useFormFieldValue({
        field: `${AI_VARIATIONS_FIELD_NAMES.TITLES}.${index}`,
    });

    const onPaste = useCallback(
        pasteEvent => {
            const pasteTitle = pasteEvent.clipboardData.getData('Text');
            const newTitles = splitOnNewLine(pasteTitle, { removeWhitespace: true });
            if (newTitles.length <= 1) {
                return false;
            }

            pasteEvent.preventDefault();
            onChangeTitles(prevTitles => {
                const numNewTitles = newTitles.length;

                // calc the number of titles that can be modified in the list of titles
                //      (if there's already populated titles, they must be replaced and
                //      we must not exceed the max amount of titles
                const numTitlesToAdd = Math.min(MAX_TITLES_COUNT - index, numNewTitles);

                // create a new array with only the number of titles we can add
                const finalNewTitles = newTitles.slice(0, numTitlesToAdd).filter(identity);

                const result = [...prevTitles];

                // delete the number of titles we're going to add and then insert the new titles
                result.splice(index, numTitlesToAdd, ...finalNewTitles);

                return result;
            });

            return true;
        },
        [onChangeTitles, index]
    );

    // forces validation to run when field is populated on mount
    useEffect(() => {
        if (title) {
            onChangeTitle(title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SuggestionContentTitle
            field={`${AI_VARIATIONS_FIELD_NAMES.TITLES}.${index}`}
            spinnerSize={12}
            campaignId={campaignId}
            validations={ignoreValidations ? [] : aiVariationsCreativeTitleValidations}
            containerClassName={styles['container']}
            className={styles['content-title']}
            gaEventsPrefix={eventsPrefix}
            openModal={openModal}
            onPaste={onPaste}
        />
    );
};
