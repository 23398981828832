import { callAjax } from 'services/callAjax';
import addRecommendationsQueryParams from '../../../modules/recommendations/utils/queryParamUtils';
import { removeHtmlPrefix } from '../../utils/stringUtils';
import { campaignsAPIPrefix } from '../campaignsApi/campaignsApiFactory';

const creativesAPIPrefix = (accountId, campaignId, creativeId) =>
    `${campaignsAPIPrefix({
        accountId,
        campaignId,
    })}/creatives${creativeId ? `/${creativeId}` : ''}`;
const videoCreativesAPIPrefix = (accountId, campaignId, creativeId) =>
    `${campaignsAPIPrefix({
        accountId,
        campaignId,
    })}/creatives/scvideo${creativeId ? `/${creativeId}` : ''}`;
const rssChildCreativeAPIPrefix = (accountId, campaignId, parentId, childId) =>
    `${campaignsAPIPrefix({
        accountId,
        campaignId,
    })}/creatives/${parentId}/rss/${childId}`;
const cloudinaryAPIPrefix = (src, ratio) =>
    `https://int-snap-cloudinary.taboola.com/taboola/image/fetch/f_jpg%2Cq_auto%2Car_${ratio}%2Cc_fill%2Cg_faces:auto%2Ce_sharpen/fl_getinfo/http%3A/${removeHtmlPrefix(
        src
    )}`;

const bulkActionsCreativesAPISuffix = accountId => `/${accountId}/creatives/bulk-action`;
const bulkActionsResubmitCreativesAPISuffix = accountId => `${bulkActionsCreativesAPISuffix(accountId)}/resubmit`;
const bulkActionsCombinedCreativesAPISuffix = (accountId, isDuplicated = false, recommendationId) =>
    `${bulkActionsCreativesAPISuffix(accountId)}/combined?is_duplicated=${isDuplicated}${
        recommendationId ? `&recommendation_id=${recommendationId}` : ''
    }`;
const matchingCreativesApi = (accountId, campaignId, creativeId) =>
    `${creativesAPIPrefix(accountId, campaignId, creativeId)}/matchingCreatives`;

const videoAssetUploadAPIPrefix = `/creative-video/upload-files`;
const VIDEO_ASSET_TYPES = {
    VIDEO: 'video',
    LOGO: 'logo',
    SUBTITLES: 'subtitles',
};
const getVideoAssetUploadRoute = assetType => `${videoAssetUploadAPIPrefix}/${assetType}`;

const getFormDataManagedVideoOptions = (file, videoUploadDetails) => {
    const formData = new FormData();
    if (file) {
        formData.append('file', file, file.name);
    }

    if (videoUploadDetails) {
        const videoUploadDetailsBlob = new Blob([JSON.stringify(videoUploadDetails)], {
            type: 'application/json',
        });
        formData.append('videoUploadDetails', videoUploadDetailsBlob);
    }

    return {
        body: formData,
        headers: { Accept: 'application/json' },
    };
};

export const creativesApiFactory = ({ callGetApi, callPutApi, callPostApi, callDeleteApi }) => ({
    getCreatives: (accountId, campaignId) => callGetApi(creativesAPIPrefix(accountId, campaignId)),
    getMatchingThumbnailCreatives: (accountId, campaignId, creativeId, thumbnailUrl) =>
        callGetApi(matchingCreativesApi(accountId, campaignId, creativeId), { thumbnailUrl }),
    getCreative: (accountId, campaignId, creativeId) =>
        callGetApi(creativesAPIPrefix(accountId, campaignId, creativeId)),
    getVideoCreative: (accountId, campaignId, creativeId) =>
        callGetApi(videoCreativesAPIPrefix(accountId, campaignId, creativeId)),
    updateCreative: (accountId, campaignId, creativeId, changes, recommendationId) =>
        callPutApi(
            addRecommendationsQueryParams(creativesAPIPrefix(accountId, campaignId, creativeId), {
                recommendationId,
            }),
            changes
        ),
    updateRssCreative: (accountId, campaignId, parentId, childId, changes) =>
        callPutApi(rssChildCreativeAPIPrefix(accountId, campaignId, parentId, childId), changes),
    updateVideoCreative: (accountId, campaignId, creativeId, changes) =>
        callPutApi(videoCreativesAPIPrefix(accountId, campaignId, creativeId), changes),
    bulkEditCreatives: (accountId, changes) => callPutApi(bulkActionsCreativesAPISuffix(accountId), changes),
    createCreatives: (accountId, campaignId, creatives) =>
        creatives.length && callPostApi(creativesAPIPrefix(accountId, campaignId), creatives),
    createRssCreatives: (accountId, campaignId, rssCreative) =>
        callPostApi(`${creativesAPIPrefix(accountId, campaignId)}/single`, rssCreative),
    createCombinedCreatives: (accountId, isDuplicated, body) =>
        callPostApi(bulkActionsCombinedCreativesAPISuffix(accountId, isDuplicated, null), body),
    updateCombinedCreatives: (accountId, isDuplicated, body) =>
        callPutApi(bulkActionsCombinedCreativesAPISuffix(accountId, isDuplicated, null), body),
    createCombinedCreativesFromRecommendation: ({ accountId, isDuplicated, recommendationId, body }) =>
        callPostApi(bulkActionsCombinedCreativesAPISuffix(accountId, isDuplicated, recommendationId), body),
    bulkResubmitCreatives: (accountId, body) =>
        body && callPostApi(bulkActionsResubmitCreativesAPISuffix(accountId), body),
    createVideoCreative: (accountId, campaignId, videoCreative) =>
        callPostApi(videoCreativesAPIPrefix(accountId, campaignId), videoCreative),
    deleteCreative: (accountName, campaignId, creativeId) =>
        callDeleteApi(creativesAPIPrefix(accountName, campaignId, creativeId)),
    deleteCreatives: (accountId, changes) => callDeleteApi(bulkActionsCreativesAPISuffix(accountId), changes),
    getRecentCreativesMedia: ({ accountId, ...options }) =>
        callGetApi(`/${accountId}/account-items/recent-creatives/media`, { paginationEnabled: true, ...options }),
    getFavoritedMedia: ({ accountId, ...options }) =>
        callGetApi(`/${accountId}/favorited-media-items/list-items`, { paginationEnabled: true, ...options }),
    uploadManagedVideo: (videoFile, itemsHashIds, options = {}) => {
        return callPostApi(
            getVideoAssetUploadRoute(VIDEO_ASSET_TYPES.VIDEO),
            options,
            getFormDataManagedVideoOptions(videoFile, { itemsHashIds })
        );
    },
    uploadVideoCreativeSubtitles: (subtitlesFile, itemsHashIds, subtitlesType, options = {}) => {
        return callPostApi(
            getVideoAssetUploadRoute(VIDEO_ASSET_TYPES.SUBTITLES),
            options,
            getFormDataManagedVideoOptions(subtitlesFile, {
                itemsHashIds,
                subtitlesType,
            })
        );
    },
    uploadManagedLogo: (logoFile, itemsHashIds, logoPosition, options = {}) => {
        return callPostApi(
            getVideoAssetUploadRoute(VIDEO_ASSET_TYPES.LOGO),
            options,
            getFormDataManagedVideoOptions(logoFile, {
                itemsHashIds,
                logoPosition,
            })
        );
    },
    getCropAreaSize: (src, ratios) => ratios.map(ratio => callAjax(cloudinaryAPIPrefix(src, ratio))),
});
