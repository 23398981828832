import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import resourcesApi from 'services/api/resourcesApi';
import {
    fetchBrandSafetyCategoriesError,
    fetchBrandSafetyCategoriesSuccess,
    requestFetchBrandSafetyCategories,
} from '../actions';
import BRAND_SAFETY_TYPES from '../config/BrandSafeTypes';

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.geo.targeting.third.party.brand.safety.fetch.error"
            defaultMessage="We were not able to fetch the list of third party brand safety categories"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const fetchBrandSafetyCategoriesForProvider = (accountId, brandSafetyType) => dispatch => {
    dispatch(requestFetchBrandSafetyCategories(brandSafetyType));
    resourcesApi
        .getBrandSafetyCategories(accountId, brandSafetyType)
        .then(categories => {
            dispatch(fetchBrandSafetyCategoriesSuccess(categories, brandSafetyType));
        })
        .catch(error => {
            dispatch(addIndication(errorIndication));
            dispatch(fetchBrandSafetyCategoriesError(error, brandSafetyType));
        });
};

export const fetchBrandSafetyCategories = accountId => dispatch => {
    dispatch(fetchBrandSafetyCategoriesForProvider(accountId, BRAND_SAFETY_TYPES.DV));
    dispatch(fetchBrandSafetyCategoriesForProvider(accountId, BRAND_SAFETY_TYPES.IAS));
    dispatch(fetchBrandSafetyCategoriesForProvider(accountId, BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS));
};

export default fetchBrandSafetyCategories;
