import { combineReducers } from 'redux';
import { audienceInsightsReducer, REDUCER_NAME as AUDIENCE_INSIGHTS_REDUCER_NAME } from './modules/audience-insights';
import { campaignsReducer, REDUCER_NAME as CAMPAIGNS_REDUCER_NAME } from './modules/campaigns';
import campaignsGroupReducer from './modules/campaigns/modules/campaigns-group/reducer';
import { errorReducer } from './modules/errors';
import { recommendationsReducer } from './modules/recommendations';
import { indicationsReducer } from './modules/taboola-common-frontend-modules/Indications';
import { authenticationReducer } from './modules/taboola-common-frontend-modules/authentication';
import appReducer from './reducer';

const allReducers = {
    appReducer,
    authenticationReducer,
    [CAMPAIGNS_REDUCER_NAME]: campaignsReducer,
    [AUDIENCE_INSIGHTS_REDUCER_NAME]: audienceInsightsReducer,
    indicationsReducer,
    errorReducer,
    recommendationsReducer,
    campaignsGroupReducer,
};

export default combineReducers(allReducers);
