import React from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import styles from 'modules/campaigns/modules/common-campaign-form/components/UserFrequencyCapping/UserFrequencyCapping.module.scss';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { withInputValidations } from 'modules/taboola-common-frontend-modules/validations/components/hoc';

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: (value, options) =>
            validationFunctions.isEmpty(value) || validationFunctions.isInt(value, options),
        options: { min: 0 },
        messageId: 'video.campaign.editor.DailyImpressionCap.validations.error',
        defaultMessage: 'Daily Impression Cap must be a whole positive number',
    },
]);

export const DailyImpressionCap = () => {
    const { value: dailyImpressionCap, onChange: onDailyImpressionCapChange } = useFormFieldValue({
        field: 'videoBudget.dailyImpressionCap',
    });

    return (
        <FormattedNumber value={0}>
            {placeholder => (
                <InputWithValidations
                    id="videoBudget.dailyImpressionCap"
                    type={InputTypes.NUMBER}
                    value={dailyImpressionCap}
                    placeholder={placeholder}
                    onChange={e => onDailyImpressionCapChange(e.target.value)}
                    inputWrapperClass={styles['inline']}
                    mainClass={styles['inline']}
                />
            )}
        </FormattedNumber>
    );
};
