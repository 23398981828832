import { useQuery } from 'react-query';
import { useDimension } from 'hooks';
import { AllCampaignsObject } from 'modules/campaigns/config';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks/usePermissions';
import { useRecommendationsApi } from 'services/api/recommendationsApi';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { PERFORMANCE_RECOMMENDATIONS } from '../config';

export const useRibbonRecommendation = (accountId, campaignId) => {
    const recommendationsApi = useRecommendationsApi();
    const isPermitted = usePermissions('PERFORMANCE_RECOMMENDATIONS_RIBBON');
    const isConfigured = useConfigMatch({
        [FEATURE_FLAGS.ENABLE_RIBBON_RECOMMENDATION]: 'true',
    });
    const shouldRecommendTarget = useConfigMatch(
        {},
        { [COMMON_FLAGS.UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET]: 'true' }
    );

    const [dimension] = useDimension();
    const { data: { results: activeAndAppliedRecommendations = [] } = {}, isLoading } = useQuery(
        [accountId, campaignId, 'recommendations', 'ribbon'],
        () => {
            if (dimension === CAMPAIGN_DIMENSION.SPONSORED) {
                const recommendationTypes = [
                    PERFORMANCE_RECOMMENDATIONS.UNREALISTIC_TCPA,
                    PERFORMANCE_RECOMMENDATIONS.TARGET_CPA,
                ];
                return recommendationsApi.getCampaignRecommendations(
                    accountId,
                    campaignId,
                    shouldRecommendTarget
                        ? recommendationTypes
                        : recommendationTypes.filter(type => type !== PERFORMANCE_RECOMMENDATIONS.UNREALISTIC_TCPA)
                );
            }
            return [];
        },
        {
            enabled: !!(campaignId && campaignId !== AllCampaignsObject.id && isPermitted && isConfigured),
            refetchOnWindowFocus: false,
        }
    );

    const ribbonRecommendations = activeAndAppliedRecommendations.filter(
        recommendation => recommendation.usedTimeUtc === null
    );

    return {
        ribbonRecommendations,
        isLoading,
    };
};
