import { useCallback, useMemo, useState } from 'react';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePerformanceRulesApi } from 'services/api';

export const useCustomRulesList = ({ customRulesIds }) => {
    const { getAccountRules } = usePerformanceRulesApi();
    const [rules, setRules] = useState([]);
    const { accountName } = useAccount();

    const options = useMemo(() => rules.filter(item => !customRulesIds?.includes(item?.id)), [rules, customRulesIds]);
    const selectedRules = useMemo(
        () => rules.filter(item => customRulesIds?.includes(item?.id)),
        [rules, customRulesIds]
    );

    const loadRules = useCallback(
        async ({ page, pageSize, searchText }) => {
            const { results } = await getAccountRules(accountName, page, pageSize, searchText);
            return [results, results?.length];
        },
        [getAccountRules, accountName]
    );

    return { options, selectedRules, loadRules, setRules };
};
