'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _recharts = require('recharts');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by oreuveni
 * Date: 2019-07-29
 * Time: 10:40
 */
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'fontFamily': 'var(--regular-font-stack)',
    'fontSize': '1.2rem',
    'yAxisColor': 'var(--gray-7)',
    'xAxisColor': 'var(--gray-8)',
    'activeDotFill': 'var(--white)',
    'cartesianGridColor': 'var(--gray-4)',
    'yAxisFontWeight': '500',
    'xAxisFontWeight': '500',
    'singleYAxisColor': 'var(--gray-4)',
    'taboola-svg-icon': 'taboolaGraph__taboola-svg-icon___36gUA',
    'graph-container': 'taboolaGraph__graph-container___1lmW8',
    'responsive-graph-container': 'taboolaGraph__responsive-graph-container___68A_j',
    'overlay-container': 'taboolaGraph__overlay-container___dmZF2',
    'label': 'taboolaGraph__label___1GQS2',
    'bar-preview': 'taboolaGraph__bar-preview___1T1Hh',
    'bar-preview-animation': 'taboolaGraph__bar-preview-animation___2CWuW',
    'invisible': 'taboolaGraph__invisible___1q9Y_'
};


var CartesianGrid = function CartesianGrid(props) {
    return _react2.default.createElement(_recharts.CartesianGrid, Object.assign({
        stroke: styles.cartesianGridColor,
        vertical: false
    }, props));
};

// https://github.com/recharts/recharts/blob/2.0/src/util/ReactUtils.js#L233
CartesianGrid.displayName = 'CartesianGrid';

exports.default = CartesianGrid;