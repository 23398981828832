import { useMemo } from 'react';
import { useApi } from '../hooks';
import { createSitesApi } from './sitesApiFactory';

export const useSitesApi = () => {
    const api = useApi();
    const sitesApi = useMemo(() => createSitesApi(api), [api]);

    return sitesApi;
};
