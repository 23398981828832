import React, { useState } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import PixelBasedEventImageTagField from './PixelBasedEventImageTagField';

export const PixelBasedEventImageTag = () => {
    const [showImagePixelSection, setShowImagePixelSection] = useState(false);

    const showImagePixelTitle = (
        <FormattedMessage
            id="audience.editor.pixel.based.audience.events.code.section.snippet.image.code.title"
            defaultMessage="Show Image Pixel"
        />
    );

    return (
        <>
            <CheckboxGroup
                onSelectCheckbox={() => setShowImagePixelSection(true)}
                onUnselectCheckbox={() => setShowImagePixelSection(false)}
                selectedValues={[showImagePixelSection]}
            >
                <TextCheckbox value title={showImagePixelTitle} />
            </CheckboxGroup>

            {showImagePixelSection ? (
                <>
                    <br />
                    <PixelBasedEventImageTagField />
                </>
            ) : null}
        </>
    );
};

export default PixelBasedEventImageTag;
