import { selectedAccountSelector } from '../../../../../selectors';
import { getCampaignEntity } from '../../../config';
import { saveCampaign as campaignsSaveCampaign } from '../../../flows';

export const prepareRollBackData = (rowIndex, oldValues, reportData) => [
    ...reportData.slice(0, rowIndex),
    { ...reportData[rowIndex], ...oldValues },
    ...reportData.slice(rowIndex + 1),
];

const saveCampaign =
    (campaignChanges, rowData, { onError, onSuccess } = {}) =>
    (dispatch, getState) => {
        const { accountName, id: campaignId, demandType } = rowData;
        const { accountId } = selectedAccountSelector(getState());

        return dispatch(
            campaignsSaveCampaign({
                accountId: accountName || accountId,
                campaignId,
                changes: campaignChanges,
                onError,
                onSuccess,
                entityType: getCampaignEntity({ demandType }),
            })
        );
    };

export default saveCampaign;
