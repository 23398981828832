import { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { intercomService } from 'modules/taboola-common-frontend-modules/intercom/services/IntercomService';
import { useChatAgentPermitted } from '../../../../components/ChatAgent/hooks/useChatAgentPermitted';
import { useCurrentValueGetter } from '../../../../hooks';
import config from '../../authentication/config';
import { gtmTracker, GTM_EVENTS } from '../../gtmTracker';
import { CHAT_AGENT_ACTION_TYPE } from '../config/chatAgentActionType';
import { CHAT_AGENT_COMPONENT } from '../config/chatAgentComponentGA';
import { getChatAgentGAValue } from '../utils/getChatAgentGAValue';

const INTERCOM_CONNECT_TIMEOUT = 1000;
const INTERCOM_CONNECT_MAX_ATTEMPTS = 10;

export const useChatAgentService = ({
    intercomConnectTimeout = INTERCOM_CONNECT_TIMEOUT,
    intercomConnectMaxAttempts = INTERCOM_CONNECT_MAX_ATTEMPTS,
} = {}) => {
    const [isReady, setReady] = useState(false);
    const [isIntercomOpened, setIntercomOpened] = useState(false);
    const [isOpened, setOpen] = useState(false);
    const [container, setContainer] = useState(null);
    const [chatRenderMode, setChatRenderMode] = useState(null);
    const [client, setClient] = useState(config.getChatAgentClient());
    const [sessionId, setSessionId] = useState();
    const containerGetter = useCurrentValueGetter(container);
    const isIntercomOpenedGetter = useCurrentValueGetter(isIntercomOpened);
    const open = useCallback(
        ({ container, client = config.getChatAgentClient(), chatRenderMode } = {}) => {
            if (containerGetter()) {
                return;
            }
            setOpen(isOpened => {
                if (!isOpened) {
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: CHAT_AGENT_COMPONENT,
                        value: getChatAgentGAValue({ actionType: CHAT_AGENT_ACTION_TYPE.OPEN_CHAT }),
                    });
                }
                return true;
            });

            if (container) {
                setClient(client);
                setContainer(container);
                setChatRenderMode(chatRenderMode);
                return;
            }
            if (!isIntercomOpenedGetter()) {
                intercomService.show();
            }
        },
        [isIntercomOpenedGetter, containerGetter]
    );
    const close = useCallback(() => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: CHAT_AGENT_COMPONENT,
            value: getChatAgentGAValue({ actionType: CHAT_AGENT_ACTION_TYPE.CLOSE_CHAT }),
        });
        setOpen(false);
        setContainer(null);
        setClient(config.getChatAgentClient());
        setChatRenderMode();
    }, []);
    const isPermitted = useChatAgentPermitted();
    const mounted = useRef(true);
    const isConnected = useCurrentValueGetter(!!sessionId);

    useLayoutEffect(
        () => () => {
            mounted.current = false;
        },
        []
    );

    useEffect(() => {
        if (!isPermitted) {
            return;
        }

        let counter = 0;
        let intervalId = setInterval(() => {
            counter++;
            if (counter > intercomConnectMaxAttempts) {
                clearInterval(intervalId);
                return;
            }

            if (!intercomService.isServiceBooted()) {
                return;
            }
            clearInterval(intervalId);
            const reflectIntercomState = value => {
                if (mounted.current) {
                    setIntercomOpened(value);
                }
            };
            intercomService.onShow(() => reflectIntercomState(true));
            intercomService.onHide(() => reflectIntercomState(false));
        }, intercomConnectTimeout);
    }, [open, close, isPermitted, intercomConnectTimeout, intercomConnectMaxAttempts]);

    return {
        isOpened,
        isIntercomOpened,
        open,
        close,
        container,
        isReady,
        setReady,
        client,
        sessionId,
        setSessionId,
        chatRenderMode,
        isConnected,
    };
};
