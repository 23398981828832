'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _hooks = require('../../hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function withStylesheet(BaseComponent, stylesheet, mapper, componentName) {
    var boundClassNames = _bind2.default.bind(stylesheet);
    var ComponentWithStylesheet = (0, _react.forwardRef)(function (_ref, ref) {
        var overrides = _ref.stylesheet,
            props = _objectWithoutProperties(_ref, ['stylesheet']);

        var mappedStylesheet = {};
        Object.keys(mapper).forEach(function (baseComponentClassname) {
            var classFactory = mapper[baseComponentClassname];
            if (typeof classFactory === 'function') {
                classFactory = classFactory(props);
            }
            mappedStylesheet[baseComponentClassname] = boundClassNames(classFactory);
        });
        var mergedStylesheet = (0, _hooks.useMergedStylesheets)(mappedStylesheet, overrides);
        return _react2.default.createElement(BaseComponent, Object.assign({
            ref: ref,
            stylesheet: mergedStylesheet
        }, props));
    });

    ComponentWithStylesheet.propTypes = BaseComponent.propTypes;
    ComponentWithStylesheet.classNames = BaseComponent.classNames;
    ComponentWithStylesheet.defaultProps = BaseComponent.defaultProps;
    ComponentWithStylesheet.displayName = componentName || (BaseComponent.name || BaseComponent.displayName) + '(Styled)';

    return ComponentWithStylesheet;
}

exports.default = withStylesheet;