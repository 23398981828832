import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { formatItemIds, useNavigateToAdsReport } from '../../hooks/useNavigateToAdsReport';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const LowPerformingItemPerformanceRecommendation = ({ recommendation }) => {
    const [{ currency }] = useSelectedAccount();
    const { accountName: accountId, campaignId, messageParams, actions, id: recommendationId } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const { numOfItems, totalItemsSpend, itemIds } = messageParams;
    const singleMessageIdAddition = numOfItems === 1 ? 'single.' : '';
    const params = {
        titleCode: `performance-recommendations.low-performing-item.${singleMessageIdAddition}title`,
        descriptionCode: `performance-recommendations.low-performing-item.${singleMessageIdAddition}description`,
        actionCode: `performance-recommendations.low-performing-item.${singleMessageIdAddition}action`,
        viewCode: `performance-recommendations.low-performing-item.${singleMessageIdAddition}view`,
        tooltipCode: `performance-recommendations.low-performing-item.action.${singleMessageIdAddition}tooltip`,
        dismissCode: `performance-recommendations.general-recommendation.dismiss`,
        successCode: `performance-recommendations.general-recommendation.success-message`,
        errorCode: `performance-recommendations.general-recommendation.error-message`,
        iconImg: LightningRoundIcon,
    };
    const actionParams = {
        itemsCount: numOfItems ?? null,
    };

    const formattedItemIds = useMemo(() => formatItemIds(itemIds), [itemIds]);

    const messageDetails = {
        ...messageParams,
        totalItemsSpend: <FormattedNumber value={totalItemsSpend} variant="currency" currency={currency} />,
        formattedItemIds,
    };

    const { navigateToAdsReport } = useNavigateToAdsReport({ formattedItemIds });

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: params.errorCode,
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageDetails}
            isApplied={apply.isSuccess}
            {...params}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode={params.dismissCode}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToAdsReport}
                    recommendation={recommendation}
                    textCode={params.viewCode}
                    dataMetricsComponent="RecommendationCardViewItemsButton"
                    defaultMessage="View Ads"
                />
                <ApplyButton
                    textCode={params.actionCode}
                    actionParams={actionParams}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode={params.tooltipCode}
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

LowPerformingItemPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default LowPerformingItemPerformanceRecommendation;
