import { map } from 'lodash';
import uuid from 'uuid/v1';

export const combinedAudiencesTransformerToGW = ({ audienceSetup, name, ...rest }) => {
    const combinedSegmentComponents = map(audienceSetup, ({ audiences, relation }) => ({
        uddIds: audiences,
        relation: relation === 'EXCLUDE' ? 'NOT' : relation,
    }));

    return {
        ...rest,
        label: name,
        combinedSegmentComponents,
    };
};

export const combinedAudiencesTransformerFromGW = ({ label, combinedSegmentComponents, ...rest }) => {
    const audienceSetup = map(combinedSegmentComponents, ({ uddIds, relation }) => ({
        id: uuid(),
        audiences: uddIds,
        relation: relation === 'NOT' ? 'EXCLUDE' : relation,
    }));
    return {
        ...rest,
        name: label,
        audienceSetup,
    };
};
