import React from 'react';
import { Button } from 'tuui';
import { ExternalLink } from 'components';
import { LightningIcon } from 'modules/campaigns/modules/campaigns-reports/components/OnboardingAssistant/OnboardingStepInformation/OnboardingStepData/LightningIcon';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIsScheduleMeetingPermitted } from '../../hooks/useIsScheduleMeetingPermitted';
import { GetHelpButtonTooltip } from './GetHelpButtonTooltip';
import styles from './getHelpFromExpertButton.module.scss';

export const GetHelpFromExpertButton = () => {
    const { [COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL]: scheduleMeetingUrl } = useCommonConfig([
        COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL,
    ]);

    const isSchedulePermitted = useIsScheduleMeetingPermitted();
    return (
        isSchedulePermitted && (
            <Button variant="ghost">
                <ExternalLink href={scheduleMeetingUrl} className={styles['get-help-button']}>
                    <LightningIcon />
                    <FormattedMessage
                        id="tracking.get.help.from.expert.button"
                        defaultMessage="Talk To A Tracking Expert"
                    />
                </ExternalLink>
                <GetHelpButtonTooltip />
            </Button>
        )
    );
};
