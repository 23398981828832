import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { stubFalse } from 'lodash';
import { StatusIndicator } from 'tuui';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { audiencesTypesToHideIndication } from '../../../../../audiences/config';
import { getStatusIndicatorType } from '../../../../config';
import EnumValueCellRenderer from './EnumValueCellRenderer';
import styles from './audienceStatusCellRenderer.module.scss';
import helpTooltipStyles from './helpTooltip.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AudienceStatusCellRenderer = ({
    getStatusType,
    value,
    msgIdPrefix,
    data,
    isStatusRejected = stubFalse,
    tooltipBody: propTooltipBody,
    helpTooltipClassContainer,
}) => {
    const statusType = getStatusType(value);
    const statusIndicatorType = getStatusIndicatorType(statusType);
    const isIndicatorVisible = statusIndicatorType && !audiencesTypesToHideIndication.includes(value);

    const { rejectReason, id } = data;
    const isRejected = isStatusRejected(data) && rejectReason;

    const [hovered, setHovered] = useState(false);
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    const hasValidTooltip = isRejected && propTooltipBody;

    let tooltipBody = null;
    if (hasValidTooltip) {
        const TooltipComponent = propTooltipBody;
        tooltipBody = <TooltipComponent id={id} />;
    }

    let subStatusProps = null;
    if (isRejected) {
        subStatusProps = {
            value: rejectReason,
            msgIdPrefix: `${msgIdPrefix}.reject.reason`,
        };
    }

    const indicationStyle = statusIndicatorType ?? 'warning-hidden-indication';
    return (
        <div className={classNameBuilder('container', indicationStyle)}>
            <div className={styles['left-container']}>
                <div className={styles['status-container']}>
                    <div className={styles['status-indicator-and-label-container']} aria-label="value">
                        <StatusIndicator
                            type={statusIndicatorType}
                            className={classNameBuilder('indicator', { hidden: !isIndicatorVisible })}
                        />
                        <EnumValueCellRenderer value={value} msgIdPrefix={msgIdPrefix} lines={1} />
                    </div>
                    {hasValidTooltip && (
                        <div className={helpTooltipClassContainer}>
                            <HelpTooltip
                                iconClassName={helpTooltipStyles['help-icon']}
                                position={TOOLTIP_POSITION.RIGHT}
                                className={helpTooltipStyles['help-tooltip']}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                showing={hovered}
                            >
                                {tooltipBody}
                            </HelpTooltip>
                        </div>
                    )}
                </div>
                {subStatusProps && (
                    <div className={styles['sub-status-container']} aria-label="subValue">
                        <div
                            className={classNameBuilder('sub-status-label')}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            role="heading"
                            aria-level={5}
                        >
                            <EnumValueCellRenderer lines={1} {...subStatusProps} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
