const myAudiencesTransformer = recommendationMetadata => {
    const { myAudiencesInclude = '', myAudiencesExclude = '' } = recommendationMetadata;
    const include = myAudiencesInclude
        .split(',')
        .filter(Boolean)
        .map(audienceId => ({ id: Number(audienceId) }));
    const exclude = myAudiencesExclude
        .split(',')
        .filter(Boolean)
        .map(audienceId => ({ id: Number(audienceId) }));
    return {
        campaignTargeting: {
            myAudienceTargeting: { include, exclude },
        },
    };
};

export default myAudiencesTransformer;
