import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { BlockIcon, OkOIcon } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingTypes from '../../../config/TargetingTypes';
import styles from './targetingTypeDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);
function TargetingTypeDropdownOption({ data: { value }, isDisabled }) {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: `campaign.editor.targeting.type.${value}`,
    });

    return (
        <div className={styles['container']}>
            <span>
                {value === TargetingTypes.INCLUDE ? (
                    <OkOIcon className={classNameBuilder('include', { disabled: isDisabled })} />
                ) : (
                    <BlockIcon className={classNameBuilder('exclude', { disabled: isDisabled })} />
                )}
            </span>
            <span className={styles['label']}>{label}</span>
        </div>
    );
}

TargetingTypeDropdownOption.propTypes = {
    data: PropTypes.object,
};

export default TargetingTypeDropdownOption;
