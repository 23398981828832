import React from 'react';
import classnames from 'classnames/bind';
import { InfoIcon } from 'tuui';
import styles from './noticeSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const NoticeSection = ({ title, description, containerClassName }) => {
    return (
        <div className={classNameBuilder('container', containerClassName)}>
            <div className={styles['title-section']}>
                <InfoIcon className={styles['icon']} fontSize="small" />
                {title}
            </div>
            <div className={styles['description-section']}>{description}</div>
        </div>
    );
};
