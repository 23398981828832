import { useCallback, useMemo } from 'react';
import { useFormFieldValue } from '../../taboola-common-frontend-modules/formData';
import { getInitialConditionsByTemplate } from '../config/initialValues/getInitialConditionsByTemplate';
import { useConditionsField } from './useConditionsField';
import { usePerformanceRulesSettingsData } from './usePerformanceRulesSettingsData';
import { useSettingValue } from './useSettingValue';
import { useTemplateInitialValues } from './useTemplateInitialValues';
import { useTemplatesData } from './useTemplatesData';

export const useConditions = () => {
    const { value: dimension, onChange: setDimension } = useFormFieldValue({ field: 'dimension' });
    const performanceRulesSettingsData = usePerformanceRulesSettingsData(dimension);
    const performanceRulesTemplatesData = useTemplatesData(dimension);
    const { settingValue } = useSettingValue(performanceRulesSettingsData);
    const { templateValue, action, templateKey } = useTemplateInitialValues(
        performanceRulesSettingsData,
        performanceRulesTemplatesData,
        settingValue
    );

    const extractedTemplateKeys = useMemo(
        () => Object.entries(templateValue).map(([key, value]) => ({ key, value })),
        [templateValue]
    );

    const { value: selectedTemplate, onChange: setSelectedTemplate } = useFormFieldValue(
        {
            field: 'templates',
        },
        { defaultTemplate: extractedTemplateKeys[0] }
    );

    const { value: conditions, onChange: setConditions } = useConditionsField({ selectedTemplate });

    const onTemplateChange = useCallback(
        template => {
            setSelectedTemplate(template);
            setConditions(getInitialConditionsByTemplate(template));
        },
        [setSelectedTemplate, setConditions]
    );

    return {
        setDimension,
        settingValue,
        templateValue,
        selectedTemplate,
        onTemplateChange,
        extractedTemplateKeys,
        templateKey,
        action,
        conditions,
        setConditions,
    };
};
