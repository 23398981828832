import React from 'react';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const FunnelConversionTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="tracking.unip.funnel.setup.conversion.in.funnel.tooltip"
            defaultMessage="Make sure to add different stages of the funnel, including the last stage where value is created, regardless of current optimization. If the funnel cannot be represented with existing events, please ask your client to add missing events.
                            {newline}{newline}
                            For example:
                            {newline}{newline}
                            Insurance funnel
                            Read more → Start form → Pre-approval → Policy offer → Purchase
                            {newline}{newline}
                            Education funnel
                            Request info → Application started → Application completed → Student enrolled
                            "
        />
    </TooltipSection>
);
