import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './cardStatus.module.scss';

const classNameBuilder = classnames.bind(styles);
const CardStatus = ({ statusType }) => {
    return (
        <div className={classNameBuilder('container', statusType)}>
            <FormattedMessage id={`billingAndPayments.baseCard.status.${statusType}`} />
        </div>
    );
};

export default CardStatus;
