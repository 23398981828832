import React from 'react';
import { HelpIcon } from 'taboola-ultimate-ui';
import { LearnMore } from 'modules/campaigns/modules/common-campaign-form';
import styles from './iconLearnMore.module.scss';

export const IconLearnMore = ({ learnMoreLink }) => {
    return (
        <div className={styles['container']}>
            <HelpIcon className={styles['help-icon']} />
            <LearnMore href={learnMoreLink} />
        </div>
    );
};
