export const AI_VARIATIONS_EVENTS_PREFIX = 'AI_Variations_Form';
export const AI_VARIATIONS_WIZARD_EVENTS_PREFIX = 'AI_Variations_Form_Wizard';
export const AI_CREATE_CAROUSEL_EVENTS_PREFIX = 'AI_Create_Carousel_Form';
export const NUM_TITLE_SUGGESTIONS = 3;
export const NUM_DESCRIPTION_SUGGESTIONS = 2;
export const AI_VARIATIONS_FIELD_NAMES = {
    PRODUCT_DESCRIPTION: 'aiSuggestionsProductDescription',
    PRODUCT_TARGET_AUDIENCE: 'aiSuggestionsTargetAudience',
    PRODUCT_USP: 'aiSuggestionsUniqueSellingProposition',
    SUGGESTIONS_HISTORY: 'aiGeneratedSuggestionsHistory',
    SUGGESTIONS_PAGE: 'aiGeneratedSelectedSuggestionsPage',
    CONTENT: 'aiVariationsContent',
    CREATIVE_PREVIEWS: 'aiVariationsCreativePreviews',
    TITLES: 'aiVariationsTitles',
    DESCRIPTION: 'description',
    CTA: 'cta',
    LANGUAGE: 'language',
};
