import {
    UPDATE_FUNNEL_ERROR,
    UPDATE_FUNNEL_SUCCESS,
    REQUEST_UPDATE_FUNNEL,
    UPDATE_FUNNEL_LIST_SUCCESS,
} from './actionTypes';

export const requestUpdateFunnel = () => ({ type: REQUEST_UPDATE_FUNNEL });

export const updateFunnelSuccess = funnel => ({
    type: UPDATE_FUNNEL_SUCCESS,
    payload: funnel,
});

export const updateFunnelListSuccess = funnel => ({
    type: UPDATE_FUNNEL_LIST_SUCCESS,
    payload: funnel,
});

export const updateFunnelError = error => ({ type: UPDATE_FUNNEL_ERROR, payload: error });
