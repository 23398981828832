import { omit, pick, sample } from 'lodash';
import URI from 'urijs';
import TARGETING_TYPES from '../../../modules/campaigns/modules/common-campaign-form/config/TargetingTypes';

const ONE_MINUTE = 60 * 1000;

export const API_CACHE_KEY = 'marketplaceSegmentsApiCache';
export const API_BUNDLES_CACHE_KEY = 'marketplaceSegmentsBundlesApiCache';
const audiencesAPIPrefix = accountId => `/${accountId}/audiences`;
const pixelBasedPredictiveAudiencesAPIPrefix = accountId => `/${accountId}/pixel_based_predictive_audiences`;
const marketplaceAPIPrefix = accountId => `/${accountId}/marketplace`;
const contextualAPIPrefix = accountId => `/${accountId}/contextual`;
const predictiveAPIPrefix = accountId => `/${accountId}/lookalike_unified_audiences`;
const firstPartyAPIPrefix = accountId => `/${accountId}/first_party_audiences`;
const crmAudiencesAPIPrefix = accountId => `/${accountId}/crm_audiences`;
const customContextualAudienceAPIPrefix = accountId => `/${accountId}/custom_contextual_segments`;
const customContextualResourcesAPIPrefix = `/custom_contextual_segments/resources`;
const srtAudiencesAPIPrefix = accountId => `/${accountId}/srt_audiences`;
const mrtAudiencesAPIPrefix = accountId => `/${accountId}/mrt_audiences`;
const combinedAudiencesAPIPrefix = accountId => `/${accountId}/combined-segments`;
const combinedAudiencesResourcesAPIPrefix = accountId => `/${accountId}/combined-segments/resources`;
const externalAudiencesResourcesAPIPrefix = accountId => `/${accountId}/external_audiences/resources`;

const delayedReturn = (returnValue, delayInMillisecond = 4000) =>
    new Promise(resolver => {
        setTimeout(() => {
            resolver(returnValue);
        }, delayInMillisecond);
    });

const buildFormDataForCrmFile = queryParams => {
    const formData = new FormData();

    const createPojo = pick(queryParams, [
        'name',
        'description',
        'crmTargetingType',
        'crmFileType',
        'excludeFromCampaigns',
        'businessOfExtendingCredit',
    ]);

    const createPojoBlob = new Blob([JSON.stringify(createPojo)], {
        type: 'application/json',
    });
    formData.append('create_pojo', createPojoBlob);
    formData.append('crm_file', queryParams.fileBlob, queryParams.file.name);
    return {
        body: formData,
        headers: { Accept: 'application/json' },
    };
};

export const audiencesApiFactory = ({ callGetApi, callPostApi, callPutApi, queryClient }) => ({
    getMyAudiences: (accountId, countryTargetingType, countryCodes) => {
        const url = URI(`${audiencesAPIPrefix(accountId)}/list-my-audiences`);
        const queryParams = {
            countryTargetingType: countryTargetingType || TARGETING_TYPES.ALL,
            countryCodes: countryCodes || [],
        };

        url.addSearch(queryParams);

        return queryClient.fetchQuery(['my-audience-list', accountId, countryTargetingType, countryCodes], () =>
            callGetApi(url.toString())
        );
    },
    getPredictiveSeedAudiences: (accountId, queryParams) => {
        const url = URI(`${pixelBasedPredictiveAudiencesAPIPrefix(accountId)}/list-seed-audiences`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString());
    },
    getCustomContextualSegmentArticlesPreview: queryParams => {
        const url = URI(`${customContextualResourcesAPIPrefix}/articles_preview`);
        return callPostApi(url.toString(), queryParams);
    },
    getCustomContextualSizeEstimate: (accountId, queryParams) => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/reach`);
        return callPostApi(url.toString(), queryParams);
    },
    getCustomContextualTopics: async queryParams => {
        const url = URI(
            `${customContextualResourcesAPIPrefix}/terms_list?paginationEnabled=true&page=1&page_size=100000&search_text=` +
                queryParams.search_text
        );
        const body = omit(queryParams, 'search_text');
        return callPostApi(url.toString(), body);
    },
    getCustomContextualFullTopicsData: async queryParams => {
        const url = URI(`${customContextualResourcesAPIPrefix}/terms_details`);
        return callPostApi(url.toString(), queryParams);
    },
    getCustomContextualLanguages: () => {
        const url = URI(`${customContextualResourcesAPIPrefix}/languages_list`);
        return callGetApi(url.toString());
    },
    getCustomContextualCountriesList: () => {
        const url = URI(`${customContextualResourcesAPIPrefix}/countries_list`);
        return callGetApi(url.toString());
    },
    listCustomContextualSegments: accountId => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/crud/list_segments`);
        return callGetApi(url.toString());
    },
    listCustomContextualSegmentsByIds: (accountId, queryParams) => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/crud/list_segments`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString(), {});
    },
    getCustomContextualRelatedTopics: queryParams => {
        const url = URI(
            `${customContextualResourcesAPIPrefix}/related_terms?paginationEnabled=true&page=1&page_size=100000`
        );
        return callPostApi(url.toString(), queryParams);
    },

    // Legacy

    getCustomContextualSegment: (accountId, audienceId) => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/crud/read/${audienceId}`);
        return callGetApi(url.toString());
    },
    getMyAudience: (accountId, audienceId) => {
        const url = URI(`${audiencesAPIPrefix(accountId)}/read-my-audience/${audienceId}`);
        return callGetApi(url.toString());
    },
    getPredictiveAudience: (accountId, audienceId) => {
        const url = URI(`${predictiveAPIPrefix(accountId)}/${audienceId}`);
        return callGetApi(url.toString());
    },
    getFirstPartyAudience: (accountId, audienceId) => {
        const url = URI(`${firstPartyAPIPrefix(accountId)}/${audienceId}`);
        return callGetApi(url.toString());
    },
    getMarketplaceSegments: (accountId, queryParams, options) => {
        const url = URI(`${marketplaceAPIPrefix(accountId)}/list-segments`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString(), options);
    },
    getMarketplaceSegmentsBundles: (accountId, queryParams, options) => {
        const url = URI(`${marketplaceAPIPrefix(accountId)}/list-segments-bundles`);
        if (queryParams) {
            url.addSearch(queryParams);
        }

        return queryClient.fetchQuery(
            ['marketplace-segments-bundles-tree', accountId, queryParams, options],
            () => callGetApi(url.toString(), options),
            {
                cacheTime: ONE_MINUTE,
                staleTime: ONE_MINUTE,
            }
        );
    },
    getMarketplaceProviders: (accountId, queryParams, searchText) => {
        const url = URI(`${marketplaceAPIPrefix(accountId)}/list-providers`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString(), { searchText });
    },
    getMarketplaceTaxonomies: accountId => {
        const url = URI(`${marketplaceAPIPrefix(accountId)}/list-taxonomies`);
        return callGetApi(url.toString());
    },
    getContextualSegments: (accountId, queryParams) => {
        const url = URI(`${contextualAPIPrefix(accountId)}/list-segments`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString());
    },
    createPixelBasedAudience: (accountId, queryParams) => {
        const url = URI(`${audiencesAPIPrefix(accountId)}/create-my-audience`);
        return callPostApi(url.toString(), queryParams);
    },
    createPixelBasedPredictiveAudience: (accountId, queryParams) => {
        const url = URI(`${pixelBasedPredictiveAudiencesAPIPrefix(accountId)}`);
        return callPostApi(url.toString(), queryParams);
    },
    createCrmFileAudience: (accountId, queryParams) =>
        callPostApi(`${crmAudiencesAPIPrefix(accountId)}`, queryParams.file, buildFormDataForCrmFile(queryParams)),
    sendCrmFileContentSampleForDebug: (accountId, queryParams) => {
        const pojo = {
            audienceName: queryParams.name,
            fileName: queryParams.file.name,
            fileContent: queryParams.fileRecordsForDebug,
            fileSizeInBytes: queryParams.file.size,
        };
        const url = URI(`${crmAudiencesAPIPrefix(accountId)}/crm_file_content_for_debug`);
        return callPostApi(url.toString(), pojo);
    },
    getRejectedAudiences: (accountId, queryParams) => {
        const url = URI(`${crmAudiencesAPIPrefix(accountId)}/rejected-audiences`);
        if (queryParams) {
            url.addSearch(queryParams);
        }
        return callGetApi(url.toString());
    },
    updatePixelBasedAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${audiencesAPIPrefix(accountId)}/update-my-audience/${audienceId}`);
        return callPutApi(url.toString(), queryParams);
    },
    updatePredictiveAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${predictiveAPIPrefix(accountId)}/${audienceId}`);
        return callPutApi(url.toString(), queryParams);
    },
    updateFirstPartyAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${firstPartyAPIPrefix(accountId)}/${audienceId}`);
        return callPutApi(url.toString(), queryParams);
    },
    createCustomContextualSegment: (accountId, queryParams) => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/crud/create`);
        return callPostApi(url.toString(), queryParams);
    },
    updateCustomContextualSegment: (accountId, audienceId, queryParams) => {
        const url = URI(`${customContextualAudienceAPIPrefix(accountId)}/crud/update/${audienceId}`);
        return callPutApi(url.toString(), queryParams);
    },
    createSRTAudience: (accountId, queryParams) => {
        const url = URI(`${srtAudiencesAPIPrefix(accountId)}`);
        return callPostApi(url.toString(), queryParams);
    },
    createMRTAudience: (accountId, queryParams) => {
        const url = URI(`${mrtAudiencesAPIPrefix(accountId)}`);
        return callPostApi(url.toString(), queryParams);
    },
    updateSRTAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${srtAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callPostApi(url.toString(), queryParams);
    },
    updateMRTAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${mrtAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callPostApi(url.toString(), queryParams);
    },
    getSRTAudience: (accountId, audienceId) => {
        const url = URI(`${srtAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callGetApi(url.toString());
    },
    getMRTAudience: (accountId, audienceId) => {
        const url = URI(`${mrtAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callGetApi(url.toString());
    },
    createCombinedAudience: (accountId, queryParams) => {
        const url = URI(`${combinedAudiencesAPIPrefix(accountId)}`);
        return callPostApi(url.toString(), queryParams);
    },
    getCombinedAudience: (accountId, audienceId) => {
        const url = URI(`${combinedAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callGetApi(url.toString());
    },
    updateCombinedAudience: (accountId, audienceId, queryParams) => {
        const url = URI(`${combinedAudiencesAPIPrefix(accountId)}/${audienceId}`);
        return callPostApi(url.toString(), queryParams);
    },
    getCombinedAudienceIngredients: async (accountId, queryParams, options) => {
        const url = URI(`${combinedAudiencesResourcesAPIPrefix(accountId)}/list-segments`);
        if (queryParams) {
            url.addSearch(queryParams);
        }

        return callGetApi(url.toString(), options);
    },
    getCombinedSegmentsSizeEstimate: (accountId, queryParams) => {
        const url = URI(`${combinedAudiencesResourcesAPIPrefix(accountId)}/reach`);
        return callPostApi(url.toString(), queryParams);
    },
    getCombinedTaxonomies: accountId => {
        const url = URI(`${combinedAudiencesResourcesAPIPrefix(accountId)}/taxonomies`);
        return callGetApi(url.toString());
    },
    getSRTRecommendations: (accountId, queryParams) => {
        // const url = URI(`${externalAudiencesAPIPrefix(accountId,
        // EXTERNAL_AUDIENCE_TYPE.SRT)}/resources/recommendations`); if (queryParams) { url.addSearch(queryParams); }
        // return callGetApi(url.toString());

        return delayedReturn(
            sample([
                {
                    values: [
                        'football',
                        'apple',
                        'manners',
                        'veryLongOneWordRecommendation',
                        'two words',
                        'three words recommendations',
                        'a',
                        'test',
                    ],
                },
                {
                    values: ['elephant', 'peacock', 'octopus', 'dinosaur', 'zebra', 'flamingo', 'parrot', 'toucan'],
                },
                {
                    values: ['mountain', 'river', 'forest', 'desert', 'ocean', 'island', 'canyon', 'volcano', 'sunset'],
                },
                {
                    values: [
                        'guitar',
                        'piano',
                        'violin',
                        'flute',
                        'drums',
                        'saxophone',
                        'trumpet',
                        'clarinet',
                        'harp',
                        'accordion',
                    ],
                },
                {
                    values: [
                        'vacation',
                        'summer',
                        'august',
                        'hotels',
                        'airbnb',
                        'vacation rentals',
                        'extended stay hotels',
                        'priceline hotels',
                        'all inclusive vacation packages',
                        'hopper hotels',
                    ],
                },
                {
                    values: [
                        'vacation',
                        'summer',
                        'august',
                        'hotels',
                        'airbnb',
                        'vacation rentals',
                        'extended stay hotels',
                        'priceline hotels',
                        'all inclusive vacation packages',
                        'hopper hotels',
                        'air b and b',
                        'ihg hotels',
                        'hyatt hotels',
                    ],
                },
                {
                    values: [
                        'vacation',
                        'summer',
                        'august',
                        'hotels',
                        'airbnb',
                        'vacation rentals',
                        'extended stay hotels',
                        'priceline hotels',
                        'all inclusive vacation packages',
                        'hopper hotels',
                        'air b and b',
                        'ihg hotels',
                        'hyatt hotels',
                        'candlewood suites',
                        'embassy suites',
                        'florida vacation rentals on the beach',
                        'marriott vacation club owner login',
                        'four seasons hotels and resorts',
                    ],
                },
            ]),
            sample([4000, 1000, 5000, 3000, 7000, 2000])
        );
    },
    getMRTRecommendations: (accountId, queryParams) => {
        // const url = URI(`${externalAudiencesAPIPrefix(accountId,
        // EXTERNAL_AUDIENCE_TYPE.MRT)}/resources/recommendations`); if (queryParams) { url.addSearch(queryParams); }
        // return callGetApi(url.toString());

        return delayedReturn(
            sample([
                {
                    values: [
                        'www.football.org',
                        'www.apple.org',
                        'www.manners.co.br',
                        'www.verylongonewordrecommendation.org',
                        'www.two-words.org',
                        'www.three-words-recommendations.org',
                        'www.a.org',
                        'www.test.org',
                    ],
                },
                {
                    values: [
                        'www.elephant.org',
                        'www.peacock.org',
                        'www.octopus.org',
                        'www.dinosaur.org',
                        'www.zebra.org',
                        'www.flamingo.org',
                        'www.parrot.org',
                        'www.toucan.org',
                    ],
                },
                {
                    values: [
                        'www.mountain.org',
                        'www.river.org',
                        'www.forest.org',
                        'www.desert.org',
                        'www.ocean.org',
                        'www.island.org',
                        'www.canyon.org',
                        'www.volcano.org',
                        'www.sunset.org',
                    ],
                },
                {
                    values: [
                        'www.guitar.org',
                        'www.piano.org',
                        'www.violin.org',
                        'www.flute.org',
                        'www.drums.org',
                        'www.saxophone.org',
                        'www.trumpet.org',
                        'www.clarinet.org',
                        'www.harp.org',
                        'www.accordion.org',
                    ],
                },
                {
                    values: [
                        'www.vacation.org',
                        'www.summer.org',
                        'www.august.org',
                        'www.hotels.org',
                        'www.airbnb.org',
                        'www.vacation-rentals.org',
                        'www.extended-stay-hotels.org',
                        'www.priceline-hotels.org',
                        'www.all-inclusive-vacation-packages.org',
                        'www.hopper-hotels.org',
                    ],
                },
                {
                    values: [
                        'www.vacation.org',
                        'www.summer.org',
                        'www.august.org',
                        'www.hotels.org',
                        'www.airbnb.org',
                        'www.vacation-rentals.org',
                        'www.extended-stay-hotels.org',
                        'www.priceline-hotels.org',
                        'www.all-inclusive-vacation-packages.org',
                        'www.hopper-hotels.org',
                        'www.air-b-and-b.org',
                        'www.ihg-hotels.org',
                        'www.hyatt-hotels.org',
                    ],
                },
                {
                    values: [
                        'www.vacation.org',
                        'www.summer.org',
                        'www.august.org',
                        'www.hotels.org',
                        'www.airbnb.org',
                        'www.vacation-rentals.org',
                        'www.extended-stay-hotels.org',
                        'www.priceline-hotels.org',
                        'www.all-inclusive-vacation-packages.org',
                        'www.hopper-hotels.org',
                        'www.air-b-and-b.org',
                        'www.ihg-hotels.org',
                        'www.hyatt-hotels.org',
                        'www.candlewood-suites.org',
                        'www.embassy-suites.org',
                        'www.florida-vacation-rentals-on-the-beach.org',
                        'www.marriott-vacation-club-owner-login.org',
                        'www.four-seasons-hotels-and-resorts.org',
                    ],
                },
            ]),
            sample([4000, 1000, 5000, 3000, 7000, 2000])
        );
    },
    getMRTAutoComplete: (accountId, queryParams) => {
        const url = URI(
            `${externalAudiencesResourcesAPIPrefix(
                accountId
            )}/domains_list?paginationEnabled=true&page=1&page_size=500&search_text=` + queryParams.search_text
        );
        return callGetApi(url.toString());
    },
    getMRTCategories: () => {
        // const url = URI(`${externalAudiencesResourcesAPIPrefix}/categories`);
        // return callGetApi(url.toString());

        return sample([
            ['news', 'gaming', 'medical'],
            ['fashion', 'tech', 'travel'],
            ['space', 'ads', 'farming'],
        ]);
    },
    getMailDomainsSizeEstimate: (accountId, queryParams) => {
        const url = URI(`${externalAudiencesResourcesAPIPrefix(accountId)}/mrt_reach_estimator`);
        return callPostApi(url.toString(), queryParams);
    },
    validateExternalAudienceAddedValues: (accountId, queryParams) => {
        const url = URI(`${externalAudiencesResourcesAPIPrefix(accountId)}/review_targeted_values`);
        return callPostApi(url.toString(), queryParams);
    },
});
