import React from 'react';
import { BackupTableIcon } from 'tuui';
import { pxToRem } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { CONDITION_DIMENSIONS } from '../PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/DimensionDropdown/DimensionDropdown';
import styles from './rulePreviewEmptyState.module.scss';

export const RulePreviewEmptyState = ({ dimension }) => {
    const headerMessage =
        dimension === CONDITION_DIMENSIONS.AD
            ? 'For the next run of this rule, there will be no paused ads. Next runs of this rule might pause ads, depending on the conditions you made and changes in the ads’ performance'
            : 'For the next run of this rule, there will be no blocked sites. Next runs of this rule might block sites, depending on the conditions you made and changes in the sites’ performance';

    const contentMessage = dimension === CONDITION_DIMENSIONS.AD ? 'No Ads will be paused' : 'No Sites will be blocked';

    return (
        <div className={styles['container']}>
            <BackupTableIcon className={styles['icon']} sx={{ fontSize: pxToRem(64) }} />
            <div className={styles['message']}>
                <div className={styles['header']}>
                    <FormattedMessage
                        id={`performance.rule.preview.empty.state.${dimension}.header`}
                        defaultMessage={contentMessage}
                    />
                </div>
                <div className={styles['content']}>
                    <FormattedMessage
                        id={`performance.rule.preview.empty.state.${dimension}.content`}
                        defaultMessage={headerMessage}
                    />
                </div>
            </div>
        </div>
    );
};
