import React, { useContext } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import PermissionsContext from 'modules/taboola-common-frontend-modules/authentication/PermissionsContext';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { LOCALE } from 'modules/taboola-common-frontend-modules/i18n/hooks/useLocale';
import { InternalLink } from '../../../../components/InternalLink/InternalLink';
import { ACCOUNT_ID, DIMENSION } from '../../../../hooks';
import { CAMPAIGN_ID } from '../../hooks/useSelectedCampaignId';
import {
    activeRecommendationsCount,
    isCampaignEditFormDisabled,
} from '../../modules/campaigns-reports/config/predicates';
import { DATE_PRESET, END_DATE, START_DATE } from '../../modules/campaigns-reports/hooks/useDateRange';
import { REPORT_ID } from '../../modules/campaigns-reports/hooks/useSelectedReportId';
import CampaignPickerListItemContent from './CampaignPickerListItemContent';
import CampaignPickerListItemEditButton from './CampaignPickerListItemEditButton';
import { CampaignPickerListItemRecommendationsButton } from './CampaignPickerListItemRecommendationsButton';
import CampaignPickerListItemTooltip from './CampaignPickerListItemTooltip';
import styles from './campaignPickerListItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const PARAMS_TO_KEEP = [START_DATE, END_DATE, DATE_PRESET, ACCOUNT_ID, DIMENSION, LOCALE, REPORT_ID];

const CampaignPickerListItem = ({ item, isSelected, onSelect, onEditCampaign, selectedAccountId, ...rest }) => {
    const { name, status, id, campaignId, dimension, advertiserName, bidStrategy } = item;
    const userPermissions = useContext(PermissionsContext);
    const isEditButtonHidden = isCampaignEditFormDisabled(item, userPermissions, dimension);
    const activeRecommendations = activeRecommendationsCount(item);
    const isRecommendationsButtonEnabled = userPermissions['CAMPAIGN_PICKER_RECOMMENDATIONS_ICON'];
    const shouldDisplayRecommendationsButton = isRecommendationsButtonEnabled && activeRecommendations;
    const handleEdit = e => {
        e.stopPropagation();
        onEditCampaign(item);
    };

    return (
        <div
            role="listitem"
            className={classNameBuilder('list-item', { selected: isSelected })}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value="Single"
        >
            <InternalLink
                params={{ [CAMPAIGN_ID]: id ?? campaignId }}
                paramsToKeep={PARAMS_TO_KEEP}
                className={classNameBuilder('campaign-link', { selected: isSelected })}
            >
                <div className={styles['item-content']}>
                    <CampaignPickerListItemContent
                        name={name}
                        id={id}
                        status={status}
                        isSelected={isSelected}
                        {...rest}
                    />
                    <CampaignPickerListItemTooltip name={name} id={id} status={status} bidStrategy={bidStrategy} />
                </div>
            </InternalLink>
            {!isEditButtonHidden && <CampaignPickerListItemEditButton onClick={handleEdit} />}
            {shouldDisplayRecommendationsButton ? (
                <CampaignPickerListItemRecommendationsButton
                    recommendationsCount={activeRecommendations}
                    campaignId={id}
                    accountId={selectedAccountId}
                    accountName={advertiserName}
                />
            ) : null}
        </div>
    );
};

CampaignPickerListItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        status: PropTypes.string,
        id: PropTypes.number,
        dimension: PropTypes.string,
        campaignId: PropTypes.number,
        advertiserName: PropTypes.string,
    }),
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    onEditCampaign: PropTypes.func,
    selectedAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CampaignPickerListItem.defaultProps = {
    onSelect: () => {},
    item: {},
};

export default CampaignPickerListItem;
