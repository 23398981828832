import React from 'react';
import EmptyPreviewWidgetGallery from 'modules/campaigns/modules/creative-creator/components/PreviewWidgetField/EmptyPreviewWidgetGallery/EmptyPreviewWidgetGallery';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { VideoUnitPlacement } from 'modules/taboola-common-frontend-modules/video-unit-loader';
import { EmptyVideoIcon } from './EmptyVideoIcon';

export const PreviewBody = ({ previewMode, vast }) => {
    if (vast) {
        return <VideoUnitPlacement previewMode={previewMode} containerId="ta-unit-placement" vast={vast} />;
    }
    return (
        <EmptyPreviewWidgetGallery
            title={<FormattedMessage id="video.preview.empty.title" defaultMessage="No media to show" />}
            description={
                <FormattedMessage
                    id="video.preview.empty.description"
                    defaultMessage="Add titles and media to create a new type of video"
                />
            }
            Icon={<EmptyVideoIcon />}
        />
    );
};
