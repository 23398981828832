import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useScheduledReportsApi } from '../../../services/api/scheduledReportApi';
import { errorMessagesUtils } from '../../../services/utils';
import { isNetworkAccountType } from '../../account-management';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { useFormState } from '../../taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { CONDITION_DIMENSIONS } from '../components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';
import { REPORT_SCHEDULE_ROUTE } from '../config';
import { transformScheduledReportToGw } from '../transformers/scheduledReportsToGWTransformer';

const successIndication = {
    message: (
        <FormattedMessage id="app.create.scheduled.reports.success" defaultMessage="Report successfully created" />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const expandedSuccessIndication = {
    message: (
        <FormattedMessage
            id="app.create.scheduled.reports.success.expanded"
            defaultMessage="Report successfully created. Please allow up to 5 minutes for the report processing and delivery."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.create.scheduled.reports.error.highlight"
            defaultMessage="Unable to create scheduled report"
        />
    ),
});

const useShouldIncludeConversionsRules = isAccountNetwork => {
    const { value: dimension } = useFormFieldValue({
        field: 'dimension',
    });
    return dimension !== CONDITION_DIMENSIONS.CUSTOM_RULES_BY_DAY && !isAccountNetwork;
};

export const useScheduledReportsCreatorState = () => {
    const { submit, step, setStep } = useFormState();
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const dispatch = useDispatch();
    const { id: selectedAccountId, accountName: selectedAccountName, type } = useAccount();
    const isAccountNetwork = isNetworkAccountType(type);
    const correctAccountName = isAccountNetwork ? selectedAccountName : accountName;
    const shouldIncludeConversionsRules = useShouldIncludeConversionsRules(isAccountNetwork);

    const { createScheduledReport } = useScheduledReportsApi();

    const submitScheduledReport = useCallback(
        async (scheduledReportWrapper, executeNow = false) => {
            try {
                const accountId = scheduledReportWrapper.accountId ?? selectedAccountId;
                const includeConversionsRules = shouldIncludeConversionsRules
                    ? scheduledReportWrapper.includeConversionsRules
                    : null;
                const scheduledReportToGW = transformScheduledReportToGw({
                    accountId,
                    ...scheduledReportWrapper,
                    includeConversionsRules,
                });
                await createScheduledReport(correctAccountName, scheduledReportToGW, executeNow);
                dispatch(addIndication(executeNow ? expandedSuccessIndication : successIndication));
                return scheduledReportWrapper;
            } catch (error) {
                dispatch(addIndication(generateErrorIndication(error)));
                throw error;
            }
        },
        [correctAccountName, createScheduledReport, dispatch, selectedAccountId, shouldIncludeConversionsRules]
    );

    const submitWrapper = useCallback(() => {
        const submitScheduledReportWrapper = scheduledReport => {
            return submitScheduledReport(scheduledReport);
        };
        submit(submitScheduledReportWrapper, REPORT_SCHEDULE_ROUTE);
    }, [submit, submitScheduledReport]);

    const submitAndExecuteWrapper = useCallback(() => {
        const submitScheduledReportWrapper = scheduledReport => {
            return submitScheduledReport(scheduledReport, true);
        };
        submit(submitScheduledReportWrapper, REPORT_SCHEDULE_ROUTE);
    }, [submit, submitScheduledReport]);

    return { submit: submitWrapper, step, setStep, submitAndExecuteWrapper };
};
