import React, { useState } from 'react';
import { Button, UploadIcon } from 'tuui';
import { MediaGalleryItem } from 'taboola-ultimate-ui';
import { DragAndDropFileContext } from 'taboola-ultimate-ui';
import { STATUS } from 'taboola-ultimate-ui/lib/src/components/Video/FallbackImage';
import { MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID } from 'modules/campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MediaDragAndDrop } from '../../../MediaDragAndDrop/MediaDragAndDrop';
import { GALLERY_ITEM_DROPDOWN_OPTIONS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';
import { CreativeStudioContentBanner } from '../CreativeStudioContentBanner';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import styles from './EditFallbackImageContent.module.scss';

export const EditFallbackImageContent = () => {
    const {
        selectedImageUrl,
        setSelectedImageUrl,
        originalImage: { url: originalUrl },
        campaignId,
        formAccount,
    } = useCreativeStudioContext();
    const [status, setStatus] = useState(STATUS.ACTIVE);
    const [errorMessageId, setErrorMessageId] = useState();

    const onDrop = async mediaResolver => {
        try {
            setStatus(STATUS.LOADING);
            setErrorMessageId(null);
            const { url } = await mediaResolver();

            if (url) {
                setSelectedImageUrl(url);
                setStatus(STATUS.ACTIVE);
            }
        } catch (e) {
            if (e.status === MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID) {
                setErrorMessageId('creative.creator.thumbnails.fetching.thumbnailSizeTooSmall');
            } else if (e.status) {
                setErrorMessageId(`creative.creator.thumbnails.fetching.${e.status}`);
            } else {
                setErrorMessageId(e.messageCode);
            }
            setStatus(STATUS.ACTIVE);
        }
    };

    return (
        <>
            {errorMessageId && (
                <CreativeStudioContentBanner
                    id={errorMessageId}
                    defaultMessage="Upload Error"
                    className={styles['error-banner']}
                />
            )}
            <MediaDragAndDrop onDrop={onDrop} className={styles['container']} accountId={formAccount?.accountId}>
                <MediaGalleryItem
                    status={status}
                    imgSrc={selectedImageUrl || originalUrl}
                    className={styles['media-container']}
                />
                <DragAndDropFileContext.Consumer>
                    {({ openFileDialog }) => (
                        <Button
                            variant={Button.variant.ghost}
                            onClick={() => {
                                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                                    component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${
                                        CREATIVE_STUDIO_MODE_EVENTS_MAP[
                                            GALLERY_ITEM_DROPDOWN_OPTIONS.EDIT_FALLBACK_IMAGE
                                        ]
                                    }_Replace_Image_Button`,
                                    taboolaCampaignId: campaignId,
                                });
                                openFileDialog();
                            }}
                            className={styles['btn']}
                        >
                            <UploadIcon />
                            <div className={styles['btn-text']}>
                                <FormattedMessage
                                    id="creative.creator.media.fallbackImage.link.replaceImage"
                                    defaultMessage="Replace Image"
                                />
                            </div>
                        </Button>
                    )}
                </DragAndDropFileContext.Consumer>
            </MediaDragAndDrop>
        </>
    );
};
