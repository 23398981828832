import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validateAudienceTargeting } from '../../../../config/validations/campaign/audienceValidations';

const FIELD_LENGTH_TO_OBJECT_MAP = {
    contextualSegmentsValuesLength: {
        index: 0,
        name: 'Contextual Categories',
        messageIdPrefix: 'contextual',
    },
    customContextualSegmentsValuesLength: {
        index: 1,
        name: 'Topics Segments',
        messageIdPrefix: 'custom_contextual',
    },
    marketPlaceAudienceValuesLength: {
        index: 2,
        name: 'Marketplace Audiences',
        messageIdPrefix: 'marketplace',
    },
    myAudienceIncludeValuesLength: {
        index: 3,
        name: 'My Audiences',
        messageIdPrefix: 'first_party',
    },
};

//createPowerSet => returns all the subsets of arr
const createPowerSet = (arr, i = 0) => {
    if (i === arr.length) {
        return [[]];
    }
    return createPowerSet(arr, i + 1).flatMap(subset => [subset, [arr[i]].concat(subset)]);
};

export const generateValidations = (fieldName, otherFields) => {
    const validationMatrix = createPowerSet(otherFields).sort(sortByLength);
    return validationMatrix
        .filter(fieldsArray => fieldsArray.length !== 0)
        .map(fields => {
            return {
                validationFn: validateAudienceTargeting(fieldName, fields),
                severity: INDICATION_TYPES.WARNING,
                messageId: generateString(messageIdFormatter, [fieldName, ...fields]),
                defaultMessage: generateString(messageFormatter, [fieldName, ...fields]),
            };
        });
};

const generateString = (generatorFunc, fields) => {
    const fieldObjects = fields.map(field => FIELD_LENGTH_TO_OBJECT_MAP[field]);
    fieldObjects.sort(sortByIndex);
    return generatorFunc(fieldObjects);
};

const sortByIndex = (a, b) => {
    return a.index > b.index ? 1 : -1;
};

const sortByLength = (a, b) => {
    return a.length > b.length ? -1 : 1;
};

const messageFormatter = fields => {
    const fieldsNames = fields.map(field => field.name);
    switch (fields.length) {
        case 2:
            return `Targeting ${fieldsNames[0]} AND ${fieldsNames[1]} is not recommended and will narrow your campaign's reach.`;
        case 3:
            return `Targeting  ${fieldsNames[0]} AND ${fieldsNames[1]} AND ${fieldsNames[2]} is not recommended and will narrow your campaign's reach.`;
        case 4:
            return `Targeting  ${fieldsNames[0]} AND ${fieldsNames[1]} AND ${fieldsNames[2]} AND ${fieldsNames[3]} is not recommended and will narrow your campaign's reach.`;
        default:
            return '';
    }
};

const messageIdFormatter = fields => {
    const fieldsNames = fields.map(field => field.messageIdPrefix);
    switch (fields.length) {
        case 2:
            return `campaign.targeting.audiences.collision.warning.${fieldsNames[0]}.${fieldsNames[1]}`;
        case 3:
            return `campaign.targeting.audiences.collision.warning.${fieldsNames[0]}.${fieldsNames[1]}.${fieldsNames[2]}`;
        case 4:
            return `campaign.targeting.audiences.collision.warning.${fieldsNames[0]}.${fieldsNames[1]}.${fieldsNames[2]}.${fieldsNames[3]}`;
        default:
            return '';
    }
};
