import { isNetworkAccountType } from 'modules/account-management';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CONVERSION_STATUS } from 'modules/tracking/config';
import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const conversionsTrackingReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CONVERSIONS],
    endpoint: 'conversions-by-rules',
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    searchPlaceholderMsgId: 'tracking.conversions.report.search.placeholder',
    searchField: FIELDS.CONVERSION_NAME.field,
    filters: [
        {
            id: FILTER_TYPE.CONVERSION_TYPE,
        },
        {
            id: FILTER_TYPE.CONVERSION_CATEGORY,
        },
        {
            id: FILTER_TYPE.CONVERSION_STATUS,
        },
        {
            id: FILTER_TYPE.CONVERSION_INCLUDE_IN_TOTAL,
        },
        {
            id: FILTER_TYPE.CONVERSION_INCLUDE_IN_TOTAL_VALUE,
        },
        {
            id: FILTER_TYPE.ACCOUNT_NAME,
            visibilityCondition: ({ selectedAccount: { type } }) => isNetworkAccountType(type),
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.CONVERSION_STATUS,
            type: FILTER_TYPE.CONVERSION_STATUS,
            values: [
                {
                    value: CONVERSION_STATUS.ALL_BUT_ARCHIVED,
                    label: 'All But Archived',
                    messageId: 'report.conversionStatus.ALL_BUT_ARCHIVED',
                },
            ],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
    ],
};

export default conversionsTrackingReportConfig;
