import React from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { RadioGroupField } from 'modules/campaigns/components/Form/FormFields/RadioGroupField/RadioGroupField';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AGGREGATION_TYPE } from '../../config/aggregationTypeOptions';
import { AggregatedConversionTooltip } from './AggregatedConversionTooltip/AggregatedConversionTooltip';

const aggregationTypeOptions = [
    {
        value: AGGREGATION_TYPE.AGGREGATED,
        title: (
            <FormattedMessage
                id="tracking.conversion.properties.aggregation.type.conversion.radio.title"
                defaultMessage="Aggregated"
            />
        ),
        description: (
            <FormattedMessage
                id="tracking.conversion.properties.aggregation.type.conversion.radio.description"
                defaultMessage="All conversions will be counted and conversion values will be summed up (Recommended)."
            />
        ),
    },
    {
        value: AGGREGATION_TYPE.LAST_VALUE,
        title: (
            <FormattedMessage
                id="tracking.conversion.properties.last.conversion.radio.title"
                defaultMessage="Last Value"
            />
        ),
        description: (
            <FormattedMessage
                id="tracking.conversion.properties.last.conversion.radio.description"
                defaultMessage="Only the last conversion attributed to a click/view will be counted and only the last conversion value will be reported."
            />
        ),
    },
];

export const AggregatedConversionRadio = () => {
    const { value: aggregationType, onChange } = useFormFieldValue({ field: 'aggregationType' });
    return (
        <RadioGroupField
            inputId="aggregationType"
            groupTitle={
                <FormattedMessage
                    id="tracking.conversion.properties.aggregation.type.conversion.title"
                    defaultMessage="Aggregation Type"
                />
            }
            groupDescription={
                <FormattedMessage
                    id="tracking.conversion.properties.aggregation.type.conversion.description"
                    defaultMessage="Choose how conversions are counted when there is more than one conversion attributed to the same click/view and conversion."
                />
            }
            groupHelpText={<AggregatedConversionTooltip />}
            radioGroupName="aggregationType"
            onChange={onChange}
            selectedValue={aggregationType}
            data-metrics-component="AggregationType"
        >
            {aggregationTypeOptions.map(({ value, title, description }) => {
                const isSelected = aggregationType === value;
                return (
                    <Radio
                        aria-label={`${isSelected ? 'Selected - ' : ''}${value}`}
                        key={`aggregation-type-${value}`}
                        value={value}
                        title={title}
                        description={description}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={value}
                    />
                );
            })}
        </RadioGroupField>
    );
};
