import { useCallback } from 'react';
import {
    COMMON_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';

const ADVANCED_PREVIEW_URL = 'https://cdn.taboola.com/demand-formats/ad-preview/app/index.html';
const ADVANCED_PREVIEW_URL_WITH_HASH = 'https://cdn.taboola.com/demand-formats/ad-preview/app/index.html#';
export const useOnSitePreviewModalContentState = getParameters => {
    const isAdvancedPreviewUrlWithHash = useConfigMatch(
        {},
        {
            [COMMON_FLAGS.ADVANCED_PREVIEW_URL_WITH_HASH]: 'true',
        }
    );

    const { [COMMON_FLAGS.ADVANCED_PREVIEW_MAX_VARIATIONS]: advancedPreviewMaxVariations } = useCommonConfig([
        COMMON_FLAGS.ADVANCED_PREVIEW_MAX_VARIATIONS,
    ]);

    const openAdvancedPreview = useCallback(() => {
        const openAdvancedPreviewEvent = {
            component: 'Preview on-site content',
            pageName: getPageName(),
            value: 'Advanced Preview',
        };
        const numberOfAllowedVariations = parseInt(advancedPreviewMaxVariations);
        const queryParams = getParameters(numberOfAllowedVariations) || '';
        let advancedPreviewUrl = isAdvancedPreviewUrlWithHash ? ADVANCED_PREVIEW_URL_WITH_HASH : ADVANCED_PREVIEW_URL;
        if (queryParams) {
            advancedPreviewUrl += `?${queryParams}`;
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, openAdvancedPreviewEvent);
        window.open(advancedPreviewUrl, '_blank');
    }, [getParameters, advancedPreviewMaxVariations, isAdvancedPreviewUrlWithHash]);

    return {
        openAdvancedPreview,
    };
};
