import { useCallback } from 'react';
import uuid from 'uuid/v1';
import { config as authConfig } from 'modules/taboola-common-frontend-modules/authentication';
import { useChatAgentContextService } from 'modules/taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContextService';
import { config as logRocketConfig } from 'modules/taboola-common-frontend-modules/log-rocket';
import { config } from 'modules/taboola-common-frontend-modules/version';

const tabId = uuid();

export const useAppEventExtension = () => {
    const { getContext } = useChatAgentContextService();
    const extend = useCallback(
        async event => {
            const data = {
                id: uuid(),
                timeStamp: new Date().toISOString(),
                appId: authConfig.getApplicationName(),
                tabId,
                context: await getContext(event),
                logRocketSessionUrl: logRocketConfig.getSessionsUrl(),
                feVersion: config.assetsVersion,
                gwVersion: config.gwVersion,
                rrand: event?.event?.rrand,
            };

            return { ...event, ...data };
        },
        [getContext]
    );

    return extend;
};
