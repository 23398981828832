'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var BUTTON_MODE = {
    NONE: 'none',
    ACTIVE_OPEN: 'active-open',
    ACTIVE_CLOSED: 'active-closed'
};

exports.default = BUTTON_MODE;