import { Button, ChevronRightOutlinedIcon } from 'tuui';
import FormButtons from '../../../campaigns/components/Form/FormButtons/FormButtons';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './ScheduledReportsFormButtons.module.scss';

export const ScheduledReportsFormButtons = props => {
    const { onCancel, onSubmit, submitInProgress, disableSubmit, submitAndExecuteWrapper } = props;
    return (
        <FormButtons
            onCancel={onCancel}
            onSubmit={onSubmit}
            submitInProgress={submitInProgress}
            disableSubmit={disableSubmit}
            className={styles['form-buttons']}
        >
            <Button
                onClick={submitAndExecuteWrapper}
                size={Button.size.md}
                variant={Button.variant.primary}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
            >
                <FormattedMessage id="app.scheduled.reports.save.and.send.btn.title" defaultMessage="Save & Send Now" />
                <ChevronRightOutlinedIcon />
            </Button>
        </FormButtons>
    );
};
