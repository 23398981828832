import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const SchedulerDayTimeToolTip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.runtime.tooltip.overview"
                defaultMessage="You can schedule your campaign to run on specific hours and days of the week."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.day.time.tooltip.example"
                defaultMessage="Select {tip} to set the day parting that your campaign will run on."
                values={{
                    tip: (
                        <strong>
                            <FormattedMessage
                                id="campaign.editor.schedule.runtime.radio.specific"
                                defaultMessage="Set Specific Days or Hours"
                            />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.runtime.tooltip.tip"
                defaultMessage="Selecting {time} will set the campaign to run twenty-four hours a day, seven days a week."
                values={{
                    time: <strong>24/7</strong>,
                }}
            />
        </TooltipSection>
    </div>
);

export default SchedulerDayTimeToolTip;
