import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AudienceDetailsWithBundling } from '../../AudienceDetailsWithBundling/AudienceDetailsWithBundling';
import { getFormattedSizeAndArticles } from '../../MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import { SegmentNodeTooltip } from '../SegmentNodeTooltip/SegmentNodeTooltip';

export const SegmentNodeContent = ({ searchText, nodeProps, isBundled, unbundleNode }) => {
    const { node = {}, disabled, onCollapsedChange, hasChildren } = nodeProps;
    const { label, loading: isLoading, isTargetable, size, provider, isBundle: isBundleType } = node;
    const { formattedSize } = getFormattedSizeAndArticles({ size, decimalCount: 1 });
    const isStandalone = !isBundleType && isTargetable;

    const handleUnbundle = useCallback(
        e => {
            e.stopPropagation();
            if (!disabled) {
                unbundleNode(node);
                onCollapsedChange({ props: nodeProps, collapsed: false });
            }
        },
        [disabled, unbundleNode, node, onCollapsedChange, nodeProps]
    );

    return (
        <AudienceDetailsWithBundling
            isLoading={isLoading}
            isParent={hasChildren}
            showUnbundleIcon={isBundled}
            onUnbundle={handleUnbundle}
            label={label}
            highlightText={searchText}
            labelTooltip={<SegmentNodeTooltip item={node} formattedSize={formattedSize} />}
            showSizes={isTargetable}
            formattedSize={formattedSize}
            provider={isStandalone && provider}
        />
    );
};

SegmentNodeContent.propTypes = {
    searchText: PropTypes.string,
    nodeProps: PropTypes.object,
    isBundled: PropTypes.bool,
    unbundleNode: PropTypes.func,
};
