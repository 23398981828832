"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLineGraph(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M20.5903 5.2C21.0321 5.53137 21.1216 6.15817 20.7903 6.6L14.7903 14.6C14.4807 15.0127 13.9081 15.1222 13.4681 14.8528L9.29795 12.2997L4.75219 17.6476C4.39451 18.0685 3.76341 18.1196 3.34261 17.7619C2.9218 17.4043 2.87063 16.7732 3.22832 16.3523L8.32832 10.3523C8.64737 9.97699 9.19226 9.88991 9.61241 10.1471L13.7363 12.672L19.1903 5.4C19.5216 4.95817 20.1484 4.86863 20.5903 5.2Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgLineGraph;