'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TaboolaVideoWatermarkSVG = function TaboolaVideoWatermarkSVG(_ref) {
    var className = _ref.className;
    return _react2.default.createElement(
        'svg',
        { className: className, width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        _react2.default.createElement('path', { d: 'M4 6.47L5.76 10H20V18H4V6.47ZM22 4H18L20 8H17L15 4H13L15 8H12L10 4H8L10 8H7L5 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V4Z', fill: 'currentColor' })
    );
};

TaboolaVideoWatermarkSVG.propTypes = {
    className: _propTypes2.default.string
};

TaboolaVideoWatermarkSVG.defaultProps = {
    className: ''
};

exports.default = TaboolaVideoWatermarkSVG;