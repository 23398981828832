import { selectedAccountSelector } from '../../../../../selectors';
import { getCampaignEntity } from '../../../config';
import { addToCampaignProperty, removeFromCampaignProperty } from '../../../flows';
import { selectedCampaignSelector } from '../../../selectors';
import BID_STRATEGIES from '../../common-campaign-form/config/bidStrategies';
import setReportData from '../actions/setReportData';
import { prepareRollBackData } from './saveCampaign';

// TODO DEV-66093 { bidStrategyPerAffiliate: oldValue }, rowIndex - have to be removed along with site data consistency issue solved
const saveCampaignBidStrategyPerAffiliate =
    ({ bidStrategyPerAffiliate: newValue }, rowData, { bidStrategyPerAffiliate: oldValue }, rowIndex) =>
    (dispatch, getState) => {
        const state = getState();
        const { campaignsReducer } = state;
        const { reportData } = campaignsReducer;
        const { accountId: selectedAccountId } = selectedAccountSelector(state);
        const { id: selectedCampaignId, demandType } = selectedCampaignSelector(state);
        const currRow = rowData || reportData[rowIndex];
        const resolvedAccountName = currRow.accountName || selectedAccountId;
        const rollBackData = prepareRollBackData(rowIndex, { bidStrategyPerAffiliate: oldValue }, reportData);
        const rollBack = () => dispatch(setReportData(rollBackData));
        const entityType = getCampaignEntity({ demandType });

        const bidStrategyChangesForServer = {
            bidStrategyPerAffiliate: {
                affiliate: currRow.siteName,
                bidStrategyPerAffiliate: newValue,
            },
        };

        if (oldValue === BID_STRATEGIES.FIXED) {
            dispatch(
                removeFromCampaignProperty({
                    accountId: resolvedAccountName,
                    campaignId: selectedCampaignId,
                    changes: bidStrategyChangesForServer,
                    onError: rollBack,
                    entityType,
                })
            );
        } else {
            dispatch(
                addToCampaignProperty({
                    accountId: resolvedAccountName,
                    campaignId: selectedCampaignId,
                    changes: bidStrategyChangesForServer,
                    onError: rollBack,
                    entityType,
                })
            );
        }
    };

export default saveCampaignBidStrategyPerAffiliate;
