import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDimension, useIsHeavyAccount, useSelectedAccount } from 'hooks';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { navigate } from '../../../actions';
import { CAMPAIGN_STATUS, FORM_MODES } from '../config';
import { getCampaignEntity } from '../config/routes/entity';
import { generateCampaignEditorPath } from '../services/utils';

export const terminatedPreviewIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.edit.terminated.campaign"
            defaultMessage="Deleted campaigns cannot be edited or enabled again."
        />
    ),
    type: INDICATION_TYPES.WARNING,
    highlight: <FormattedMessage id="note.highlight" />,
};

export const heavyAccountPreviewIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.edit.heavy.account.campaign"
            defaultMessage="Campaigns can not be edited on the network level."
        />
    ),
    type: INDICATION_TYPES.WARNING,
    highlight: <FormattedMessage id="note.highlight" />,
    timeoutDuration: 8000,
};

export const previewIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.edit.view.only.access"
            defaultMessage="Campaign edit is forbidden by user permission."
        />
    ),
    type: INDICATION_TYPES.WARNING,
    highlight: <FormattedMessage id="note.highlight" />,
};

const getPreviewIndication = ({ isTerminated, isHeavyAccount, hasEditAccess }) => {
    if (isHeavyAccount) {
        return heavyAccountPreviewIndication;
    }
    if (isTerminated) {
        return terminatedPreviewIndication;
    }
    if (!hasEditAccess) {
        return previewIndication;
    }
};

const useOpenCampaignEditor = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isHeavyAccount = useIsHeavyAccount();
    const [dimension] = useDimension();
    const hasEditAccess = usePermissions('CAMPAIGN_EDIT');
    const [{ accountId: selectedAccountId, isActive: isActiveAccount }] = useSelectedAccount();

    const openCampaignEditor = useCallback(
        campaign => {
            // TODO: remove advertiserName after DEV-65984
            const { status, id, accountName, advertiserName } = campaign;
            const isTerminated = status === CAMPAIGN_STATUS.TERMINATED;
            const mode =
                isHeavyAccount || isTerminated || !isActiveAccount || !hasEditAccess
                    ? FORM_MODES.PREVIEW
                    : FORM_MODES.EDIT;
            const resolvedAdvertiserName = accountName || advertiserName || selectedAccountId;
            const entity = getCampaignEntity(campaign);

            const editCampaignPath = generateCampaignEditorPath({
                accountId: resolvedAdvertiserName,
                campaignId: id,
                mode,
                dimension,
                entity,
            });

            dispatch(navigate(history, editCampaignPath));

            if (mode === FORM_MODES.PREVIEW) {
                const indication = getPreviewIndication({
                    isTerminated,
                    isHeavyAccount,
                    hasEditAccess,
                });
                if (indication) {
                    dispatch(addIndication(indication));
                }
            }
        },
        [isHeavyAccount, isActiveAccount, hasEditAccess, selectedAccountId, dimension, dispatch, history]
    );

    return openCampaignEditor;
};

export default useOpenCampaignEditor;
