import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AppsTargeting } from './AppsTargeting';
import { AppsTargetingFieldTooltip } from './AppsTargetingFieldTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const AppsTargetingField = () => {
    return (
        <FormField
            iputId="apps-targeting"
            label={<FormattedMessage id="video.campaign.editor.apps.targeting.title" defaultMessage="Apps" />}
            description={
                <FormattedMessage
                    id="video.campaign.editor.apps.targeting.description"
                    defaultMessage="Target specific applications."
                />
            }
            helpText={<AppsTargetingFieldTooltip />}
            containerClass={styles['input']}
        >
            <AppsTargeting />
        </FormField>
    );
};
