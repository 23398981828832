import { TaboolaGraph, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { xAxisWithTimelineRenderer } from '../../../components/CampaignHistory/xAxisWithTimelineRenderer';
import { generateGraphMetricDef } from '../../../utils/generateGraphMetricDef';
import * as FIELDS from '../../fields';
import {
    dayWeekMonthGetXAxisFormatter,
    dayWeekMonthXAxisMinTickGap,
    tooltipDateFormatter,
} from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

export const graph = {
    type: GRAPHS.LINE,
    endpoint: 'campaign-history-by-day-count-and-performance',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
    ],
    defaultSort: [{ field: 'date', order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: FIELDS.DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: dayWeekMonthGetXAxisFormatter,
        toolTipLabelFormatter: tooltipDateFormatter,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
        xAxisComponentRenderer: xAxisWithTimelineRenderer,
    },
    accountConfigurations: {
        [FEATURE_FLAGS.SHOW_CAMPAIGN_HISTORY_REPORT_GRAPH]: 'true',
    },
    getMetrics: ({ graphConfig, locale, currency }) => {
        const { metrics = [] } = graphConfig;
        return metrics.map(metric => generateGraphMetricDef(metric, locale, currency));
    },
    skipSyncWithGrid: true,
};
