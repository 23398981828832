import React, { useMemo } from 'react';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useValidations } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useValidations';
import { itemValidations } from '../ContextualSegmentsValidations';
import ContextualSegmentListItemContent from './ContextualSegmentListItemContent';

const ContextualSegmentListItemContentWithValidationsWrapper = ({ item, field, ...rest }) => {
    const { isDirty, mode } = useFormFieldValue({
        field,
    });
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });
    const countriesMap = useMemo(() => keyBy(countryCodes), [countryCodes]);

    const { loading, allowedCountries = [], provider, isActive, id } = item;
    const valueToValidate = useMemo(
        () => [allowedCountries, provider, isActive, id],
        [allowedCountries, provider, isActive, id]
    );
    const { value: countryTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });

    const validationDependencies = useMemo(() => {
        return {
            countriesMap,
            countryTargetingType,
        };
    }, [countriesMap, countryTargetingType]);

    const { failedValidationData: { message, indicationType } = {} } = useValidations({
        validations: itemValidations,
        validationId: `${field}.${id}`,
        valueToValidate,
        validationDependencies,
        isDirty,
        isReady: !loading,
        hasInitialData: mode !== FORM_MODES.CREATE,
    });

    return (
        <ContextualSegmentListItemContent
            id="contextual-segments-item"
            item={item}
            message={message}
            indicationType={indicationType}
            updateStateOnMount
            {...rest}
        />
    );
};

ContextualSegmentListItemContentWithValidationsWrapper.propTypes = {
    campaign: PropTypes.object,
    ...ContextualSegmentListItemContent.propTypes,
};

export default ContextualSegmentListItemContentWithValidationsWrapper;
