import ApiError from '../../modules/errors/ApiError';
import { ERROR_CODES } from '../api/apiConstants';
import { isOnline } from './isOnline';

const MAX_ATTEMPT_COUNT = 10;
const WAIT_TIMEOUT = 500;

export const connectionError = new ApiError({
    messageCode: 'app.internet.connection.is.unavailable',
    message:
        '<b>Internet Connection Not Stable</b> Your network seems to be disconnected. Please check your internet connection and try again.',
    status: ERROR_CODES.NETWORK_UNAVAILABLE,
});

export const waitNetworkConnection = (url, counter = MAX_ATTEMPT_COUNT, timout = WAIT_TIMEOUT) => {
    if (isOnline()) {
        return;
    }
    if (!counter) {
        console.error('Internet connection is unavailable.');
        throw connectionError;
    }
    console.info(`Waiting internet connection to send request (left attempts - ${counter}):`, url);
    return new Promise(resolve => setTimeout(() => resolve(waitNetworkConnection(url, counter - 1), timout), timout));
};
