import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';

const reportId = reportsBaseConfig[REPORT_TYPE.HIERARCHY_CAMPAIGN].id;

const defaultColumns = [
    FIELDS.NAME.field,
    FIELDS.ID.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.NAME.field,
    FIELDS.ID.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
    FIELDS.CONVERSIONS_VALUE.field,
    FIELDS.ROAS.field,
].map(field => generateColumnId(reportId, field));

const setupColumns = [FIELDS.NAME.field, FIELDS.ID.field].map(field => generateColumnId(reportId, field));
const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.CLICKS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.SPENT.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.CONVERSIONS_CLICKS.field,
        FIELDS.CONVERSIONS_VIEWS.field,
        FIELDS.CONVERSIONS_VALUE.field,
        FIELDS.CPM.field,
        FIELDS.VCPM.field,
        FIELDS.CTR.field,
        FIELDS.VCTR.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.CPA.field,
        FIELDS.CPA_CLICKS.field,
        FIELDS.CPA_VIEWS.field,
        FIELDS.CVR.field,
        FIELDS.CVR_CLICKS.field,
        FIELDS.CVR_VIEWS.field,
        FIELDS.ROAS.field,
    ].map(field => generateColumnId(reportId, field)),
    [REPORT_PRESET.SETUP]: [FIELDS.ID.field].map(field => generateColumnId(reportId, field)),
};

export { customColumns, defaultColumns, performanceColumns, setupColumns };
