import React, { useEffect } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CheckboxGroup, FormField, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { DesktopIcon, MobileSimpleIcon, TabletIcon } from 'taboola-ultimate-ui';
import { PLATFORMS } from 'modules/campaigns/modules/common-campaign-form/config';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { basePlatformValidations } from '../../../../config';
import CustomCheckbox from './CustomCheckbox/CustomCheckbox';
import PlatformTargetingTooltip from './PlatformTargetingTooltip';
import usePlatformTargeting from './hooks/usePlatformTargeting';
import commonStyles from '../commonEditor.module.scss';
import styles from './platformTargeting.module.scss';

const CheckboxGroupWithValidations = withIndication(CheckboxGroup);

const PLATFORMS_ICONS = [
    {
        id: PLATFORMS.DESKTOP,
        icon: DesktopIcon,
    },
    {
        id: PLATFORMS.MOBILE,
        icon: MobileSimpleIcon,
    },
    {
        id: PLATFORMS.TABLET,
        icon: TabletIcon,
    },
];

const PlatformTargeting = ({
    platforms = PLATFORMS_ICONS,
    validations = basePlatformValidations,
    getCheckboxColor = noop,
}) => {
    const {
        formValidatedValueData,
        platformInfoList,
        handleSelectValue,
        handleUnselectValue,
        setPlatformByMarketingObjective,
        getPlatformTargetingDescription,
    } = usePlatformTargeting(platforms, validations);

    const { platformTargeting, indicationData, scrollRef } = formValidatedValueData;
    const { indicationType } = indicationData;

    useEffect(() => {
        setPlatformByMarketingObjective();
    }, [setPlatformByMarketingObjective]);

    return (
        <FormField
            inputId="platform-targeting"
            containerClass={styles['input']}
            label={<FormattedMessage id="campaign.targeting.platform" defaultMessage="Platform" />}
            description={
                <FormattedMessage
                    id="campaign.targeting.platform.description"
                    defaultMessage="Target specific platforms."
                />
            }
            helpText={<PlatformTargetingTooltip />}
            ref={scrollRef}
            subtext={getPlatformTargetingDescription()}
            subtextClass={styles['subtext-class']}
        >
            <CheckboxGroupWithValidations
                id="platformTargeting"
                className={styles['group']}
                selectedValues={platformTargeting}
                onSelectCheckbox={handleSelectValue}
                onUnselectCheckbox={handleUnselectValue}
                data-metrics-component="PlatformTargeting"
                indicationContainerClass={
                    indicationType === INDICATION_TYPES.WARNING ? commonStyles['warning-indication'] : null
                }
                {...indicationData}
            >
                {platformInfoList.map(platformInfo => {
                    const isSelected = platformTargeting.includes(platformInfo.id);
                    const colorContext = { id: platformInfo.id, platformTargeting };
                    return (
                        <CustomCheckbox
                            id={platformInfo.id}
                            icon={platformInfo.icon}
                            key={platformInfo.id}
                            isSelected={isSelected}
                            checkboxColorOverride={getCheckboxColor(colorContext)}
                            disabled={platformInfo?.disabled}
                        />
                    );
                })}
            </CheckboxGroupWithValidations>
        </FormField>
    );
};

PlatformTargeting.propTypes = {
    platforms: PropTypes.array,
    extraValidation: PropTypes.object,
    extraCheckboxColor: PropTypes.func,
};

export default PlatformTargeting;
