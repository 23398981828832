import { marketingObjectiveValidations } from 'modules/campaigns/config/validations/campaign/marketingObjectiveValidations';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { pixelOrS2sInstalled } from '../../../../../tracking/hooks/pixelOrS2sInstalled';
import { usePixelWarningPermitted } from './usePixelWarningPermitted';

export const useMarketingObjectiveWithValidations = () => {
    const isPixelWarningPermitted = usePixelWarningPermitted();
    const isPixelWarningsTesting = useConfigMatch({ [FEATURE_FLAGS.TA_PIXEL_WARNINGS_TESTING]: 'true' });

    const {
        trackingStateData: { pixelTrackingData, s2sTrackingData, hasAtLeastOneActiveConversion },
    } = useTrackingState(isPixelWarningPermitted);
    const isPixelOrS2sInstalled = pixelOrS2sInstalled(pixelTrackingData, s2sTrackingData);

    return useFormValidatedValue({
        field: 'marketingObjective',
        validations: marketingObjectiveValidations,
        validationDependencies: {
            isPixelOrS2sInstalled,
            hasAtLeastOneActiveConversion,
            isPixelWarningsTesting,
            isPixelWarningPermitted,
        },
    });
};
