import uuid from 'uuid/v1';
import { THUMBNAIL_SOURCE_TYPES } from '../../config';

export const mapFileToThumbnail = file => ({
    src: URL.createObjectURL(file),
    id: uuid(),
    file,
    type: THUMBNAIL_SOURCE_TYPES.FILE,
    fileType: file ? file.type : '',
});
