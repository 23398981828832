import { useRef } from 'react';
import { shallowEqual } from 'react-redux';

export const useShallowObject = data => {
    const currentData = useRef(data);
    if (!shallowEqual(data, currentData.current)) {
        currentData.current = data;
    }

    return currentData.current;
};
export default useShallowObject;
