import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    EllipsisCellRenderer,
    FormattedMessageHeaderRenderer,
    gridDateFormatter,
    StateCellRenderer,
} from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    hiddenColumnType,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { defaultHeaderComponentParams } from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { isEditDisabled } from '../../../../../../scheduled-reports/config';
import { updateScheduledReports } from '../../../../../../scheduled-reports/flows/updateScheduledReports';
import { ReportsScheduleActionsCellRenderer } from '../../../components';
import { SentToCellRenderer } from '../../../components';
import { onlyNetworkColumn } from '../../predicates';

const isDisabled = (data, context) => {
    return isEditDisabled({ data, context });
};

const columnDefinitions = [
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
        cellRendererParams: {
            reportId: REPORT_TYPE.SCHEDULED_REPORTS,
            ...FIELDS.IS_ACTIVE.renderParams,
            defaultMessage: 'Report Status',
            isDisabled,
        },
        cellRendererFramework: StateCellRenderer,
        valueChangeHandler: updateScheduledReports,
    },
    {
        headerName: FIELDS.SCHEDULED_REPORTS_ID.label,
        field: FIELDS.SCHEDULED_REPORTS_ID.field,
        type: [hiddenColumnType, numericColumn],
    },
    {
        headerName: FIELDS.ACCOUNT_DESCRIPTION.label,
        field: FIELDS.ACCOUNT_DESCRIPTION.field,
        cellRendererFramework: FIELDS.ACCOUNT_DESCRIPTION.cellRendererFramework,
        cellRendererParams: FIELDS.ACCOUNT_DESCRIPTION.cellRendererParams,
        visibilityCondition: onlyNetworkColumn,
    },
    {
        headerName: FIELDS.REPORT_NAME.label,
        field: FIELDS.REPORT_NAME.field,
        cellRendererFramework: FIELDS.REPORT_NAME.cellRenderer,
        headerComponentParams: FIELDS.REPORT_NAME.headerComponentParams,
        headerValueGetter: FormattedMessageHeaderRenderer,
    },
    {
        headerName: FIELDS.FREQUENCY.label,
        field: FIELDS.FREQUENCY.field,
        cellRendererFramework: FIELDS.FREQUENCY.cellRenderer,
        cellRendererParams: { msgIdPrefix: 'app.scheduled.reports.create.settings.frequency' },
        headerComponentParams: defaultHeaderComponentParams,
    },
    {
        headerName: FIELDS.DATE_RANGE.label,
        field: FIELDS.DATE_RANGE.field,
        cellRendererFramework: FIELDS.DATE_RANGE.cellRenderer,
        cellRendererParams: { msgIdPrefix: 'app.campaigns.campaign.date.range' },
        headerComponentParams: defaultHeaderComponentParams,
    },
    {
        headerName: FIELDS.SENT_TO.label,
        field: FIELDS.SENT_TO.field,
        cellRendererFramework: SentToCellRenderer,
        headerComponentParams: defaultHeaderComponentParams,
    },
    {
        headerName: FIELDS.LAST_MODIFY_BY.label,
        field: FIELDS.LAST_MODIFY_BY.field,
        cellRendererFramework: EllipsisCellRenderer,
        headerComponentParams: defaultHeaderComponentParams,
        headerValueGetter: FormattedMessageHeaderRenderer,
    },
    {
        headerName: FIELDS.LAST_MODIFY_ON_UPDATE_TIME.label,
        field: FIELDS.LAST_MODIFY_ON_UPDATE_TIME.field,
        valueFormatter: gridDateFormatter,
        headerComponentParams: defaultHeaderComponentParams,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        cellRendererFramework: ReportsScheduleActionsCellRenderer,
    },
];
export const availableColumnDefinitions = normalizeColumnDefs(
    columnDefinitions,
    reportsBaseConfig[REPORT_TYPE.SCHEDULED_REPORTS].id
);
