import React from 'react';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { EditableHeaderFormField } from '../../../../../../taboola-common-frontend-modules/forms';

const nameEditValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.campaign.name.empty',
        defaultMessage: 'Enter a Campaign Name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 200 },
        messageId: 'validations.error.campaign.name.length',
        defaultMessage: 'Campaign name can not exceed 200 characters',
    },
];

export const NameEdit = ({ field = 'name', validations = nameEditValidations, placeholder }) => {
    const { value, onChange, indicationData } = useFormValidatedValue({
        field,
        validations,
        throttleValue: true,
    });

    return (
        <EditableHeaderFormField
            inputId="name"
            id="name"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="NameEdit"
            name="creative-name"
            {...indicationData}
        />
    );
};
