import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { LinkButton, Breadcrumbs } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { LinkBreadcrumb } from '../LinkBreadcrumb';
import styles from './systemBreadcrumbs.module.scss';

const SystemBreadcrumbs = ({ title, steps, shouldRenderEdit, handleEdit, containerClass }) => (
    <>
        <div className={containerClass}>
            <Breadcrumbs steps={steps} childProps={{ DisplayComponent: LinkBreadcrumb }} />
            <div className={styles['title-edit-container']}>
                <div className={styles['title']}>{title}</div>
                {shouldRenderEdit ? (
                    <div className={styles['edit-button-container']}>
                        <LinkButton onClick={handleEdit} className={styles['edit-button']}>
                            <FormattedMessage id="app.campaigns.editCampaign" defaultMessage="Edit Campaign" />
                        </LinkButton>
                    </div>
                ) : null}
            </div>
        </div>
    </>
);

SystemBreadcrumbs.propTypes = {
    title: PropTypes.string.isRequired,
    steps: PropTypes.array,
    shouldRenderEdit: PropTypes.bool,
    handleEdit: PropTypes.func,
};

export { SystemBreadcrumbs };

export default withRouter(SystemBreadcrumbs);
