import React, { useCallback } from 'react';
import { FormField, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BidModifierTooltip } from '../DayTimeBidBoost/components/BidModifierTooltip/BidModifierTooltip';
import { useBidModifierLock } from '../DayTimeBidBoost/useBidModifierLock';
import { PlatformParting } from './components/PlatformBoost/PlatformParting';
import { PLATFORM_PARTING_DEFAULT } from './consts/bidModificationDeviceDefaultValues';
import { PLATFORM_BID_OPTION } from './consts/platformBidOptions';
import styles from './platformBidBoost.module.scss';

export const PlatformBidBoost = () => {
    const { value: platformBidMode = PLATFORM_BID_OPTION.NONE, onChange: onModeChange } = useFormFieldValue({
        field: 'platformBidModifier.mode',
    });
    const { onChange: onBoostChange } = useFormFieldValue({
        field: 'platformBidModifier.values',
    });
    const isPlatformPermitted = useConfigMatch({ [FEATURE_FLAGS.BID_PLATFORM_ENABLED]: 'true' });
    const resetPlatformValues = useCallback(
        mode => {
            if (mode === PLATFORM_BID_OPTION.NONE) {
                onBoostChange([]);
            } else {
                onBoostChange([...PLATFORM_PARTING_DEFAULT]);
            }
            onModeChange(mode);
        },
        [onBoostChange, onModeChange]
    );
    const resetHandler = useCallback(() => {
        onModeChange(PLATFORM_BID_OPTION.NONE);
        onBoostChange([...PLATFORM_PARTING_DEFAULT]);
    }, [onModeChange, onBoostChange]);
    const disabled = useBidModifierLock({ resetHandler });

    if (!isPlatformPermitted) {
        return null;
    }

    return (
        <FormField
            inputId="platformBid"
            label={<FormattedMessage id="campaign.editor.bid.platformBid.title" defaultMessage="Bid per Platform" />}
            description={
                <div className={styles['description-container']}>
                    <FormattedMessage
                        id="campaign.editor.bid.platformBid.description.title"
                        defaultMessage="Increase or decrease your CPC Bid per Platform."
                    />
                    <div className={styles['description']}>
                        <FormattedMessage
                            id="campaign.editor.bid.platformBid.description.text"
                            defaultMessage="Note, this will not change the campaign’s platform targeting."
                        />
                    </div>
                </div>
            }
            containerClass={styles['list']}
        >
            <RadioGroup name="platformBidOptionSelected" selectedValue={platformBidMode} onChange={resetPlatformValues}>
                <BidModifierTooltip wrap={disabled}>
                    <Radio
                        value={PLATFORM_BID_OPTION.NONE}
                        title={<FormattedMessage id="campaign.editor.bid.platform.none" defaultMessage="None" />}
                        disabled={disabled}
                    />
                    <Radio
                        value={PLATFORM_BID_OPTION.CUSTOM}
                        title={
                            <FormattedMessage
                                id="campaign.editor.bid.platform.adjust"
                                defaultMessage="Adjust bids for specific platforms"
                            />
                        }
                        disabled={disabled}
                    />
                </BidModifierTooltip>
            </RadioGroup>
            <div className={styles['container']}>
                {platformBidMode === PLATFORM_BID_OPTION.CUSTOM && <PlatformParting />}
            </div>
        </FormField>
    );
};
