"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var moveCaretToEndOfInput = function moveCaretToEndOfInput(inputElement) {
    if (!document.getSelection) {
        // only happens in JsDOM & IE8
        return;
    }
    // DEV-67160 for safari, this issue is fixed by css, however try-catch added for extra safety
    try {
        var range = document.getSelection().getRangeAt(0);
        range.selectNodeContents(inputElement);
        range.collapse();
    } catch (err) {
        // silent
    }
};

var getSelection = function getSelection() {
    var selection = document.getSelection ? document.getSelection() : { anchorOffset: 0, extentOffset: 0 };

    return {
        startIndex: Math.min(selection.anchorOffset, selection.extentOffset),
        endIndex: Math.max(selection.anchorOffset, selection.extentOffset),
        length: Math.abs(selection.extentOffset - selection.anchorOffset)
    };
};

var setCursorPosition = function setCursorPosition(inputElement, position) {
    try {
        var range = document.createRange();
        var selection = window.getSelection();

        range.setStart(inputElement.childNodes[0], position);
        range.collapse(true);

        selection.removeAllRanges();
        selection.addRange(range);

        inputElement.focus();
    } catch (err) {
        // silent
    }
};

exports.moveCaretToEndOfInput = moveCaretToEndOfInput;
exports.getSelection = getSelection;
exports.setCursorPosition = setCursorPosition;