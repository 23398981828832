import React from 'react';
import { Button, TouchAppIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './popupTooltip.module.scss';

export const PopupTooltip = ({
    isTooltipOpen,
    onClick,
    tooltipTitle = <FormattedMessage id="popup.tooltip.default.title" defaultMessage="New!" />,
    tooltipIcon = <TouchAppIcon />,
    tooltipSubtitle = <FormattedMessage id="popup.tooltip.default.subtitle" defaultMessage="Hover & click" />,
    tooltipBodyText,
}) => {
    return (
        <Tooltip
            className={styles['popup-tooltip']}
            maskClassName={styles['popup-tooltip']}
            tailClassName={styles['popup-tooltip-tail']}
            tailStyle={{ zIndex: 2 }}
            trigger="manual"
            showing={isTooltipOpen}
            position={TOOLTIP_POSITION.TOP_START}
            offsetBody={10}
            interactive
            arrow
        >
            <div className={styles['popup-tooltip-container']} data-testid="popup-tooltip">
                <div className={styles['popup-tooltip-title']}>{tooltipTitle}</div>

                <div className={styles['popup-tooltip-subtitle']}>
                    {tooltipIcon}
                    {tooltipSubtitle}
                </div>

                {tooltipBodyText}

                <Button className={styles['popup-tooltip-button']} onClick={onClick}>
                    <FormattedMessage id="popup.tooltip.default.close" defaultMessage="Got It" />
                </Button>
            </div>
        </Tooltip>
    );
};
