'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Buttons = require('../../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'deletableItem-module__taboola-svg-icon___2Be28',
    'container': 'deletableItem-module__container___IZ6Uu',
    'content': 'deletableItem-module__content___2rgqv',
    'button': 'deletableItem-module__button___SSTgP',
    'delete-appear': 'deletableItem-module__delete-appear___3T9Mn'
};


var classNameBuilder = _bind2.default.bind(styles);

var DeletableItem = function DeletableItem(_ref) {
    var children = _ref.children,
        onDelete = _ref.onDelete,
        isItemDeletable = _ref.isItemDeletable,
        className = _ref.className,
        contentClassName = _ref.contentClassName,
        DeleteButton = _ref.DeleteButton,
        _ref$buttonProps = _ref.buttonProps,
        buttonProps = _ref$buttonProps === undefined ? {} : _ref$buttonProps,
        rest = _objectWithoutProperties(_ref, ['children', 'onDelete', 'isItemDeletable', 'className', 'contentClassName', 'DeleteButton', 'buttonProps']);

    return _react2.default.createElement(
        'div',
        Object.assign({ className: classNameBuilder('container', className) }, rest),
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('content', contentClassName) },
            children
        ),
        onDelete && isItemDeletable ? _react2.default.createElement(DeleteButton, Object.assign({}, buttonProps, {
            className: styles['button'],
            onClick: onDelete,
            iconBefore: 'close',
            size: _Buttons.STYLED_BUTTON_SIZE.SMALL,
            type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON
        })) : null
    );
};

DeletableItem.propTypes = {
    children: _propTypes2.default.node,
    onDelete: _propTypes2.default.func.isRequired,
    alwaysVisible: _propTypes2.default.bool,
    className: _propTypes2.default.string,
    contentClassName: _propTypes2.default.string,
    isItemDeletable: _propTypes2.default.bool,
    buttonProps: _propTypes2.default.object,
    deleteTooltip: _propTypes2.default.node,
    DeleteButton: _propTypes2.default.elementType
};

DeletableItem.defaultProps = {
    isItemDeletable: true,
    DeleteButton: _Buttons.StyledButton
};

exports.default = DeletableItem;