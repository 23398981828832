import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import styles from './keySection.module.scss';

export const KeySection = ({ className, loading, loaderWidth, ariaLabel }) => {
    const { value: key } = useFormFieldValue({ field: 'key' });
    const content = loading ? (
        <ContentLoader speed={2} height={10} width={loaderWidth} className={styles['placeholder']} />
    ) : (
        key
    );
    return (
        <div className={classnames(styles['label'], className)} aria-label={ariaLabel}>
            {content}
        </div>
    );
};

KeySection.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    loading: PropTypes.bool,
    loaderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    testId: PropTypes.string,
    ariaLabel: PropTypes.string,
};

export default KeySection;
