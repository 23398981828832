import { usePromptHelperFormFieldValue } from './usePromptHelperFormFieldValue';

export const usePromptHelper = () => {
    const helperFormFields = usePromptHelperFormFieldValue();

    const getPromptHelperParams = () => {
        const promptHelperParams = {};
        Object.keys(helperFormFields)?.forEach(category => {
            if (helperFormFields[category].value) {
                promptHelperParams[category] = helperFormFields[category].value;
            }
        });

        return promptHelperParams;
    };

    const clearPromptHelperFormFields = () => {
        Object.keys(helperFormFields)?.forEach(category => helperFormFields[category].onChange(null));
    };

    const clearPromptHelperFormField = category => {
        helperFormFields[category].onChange(null);
    };

    return { getPromptHelperParams, clearPromptHelperFormFields, clearPromptHelperFormField };
};
