"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDownload(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M10.9912 12.0075C10.1765 11.1917 9.15557 10.1696 9.15557 10.1696C8.76504 9.77911 8.13188 9.77911 7.74136 10.1696C7.35083 10.5602 7.35083 11.1933 7.74136 11.5839L10.5745 14.4169C11.3534 15.1959 12.6168 15.1937 13.3961 14.4144L16.2266 11.5839C16.6172 11.1933 16.6172 10.5602 16.2266 10.1696C15.8361 9.77911 15.2029 9.77911 14.8124 10.1696L12.9998 11.9823L12.9976 4.53281C13.001 3.97768 12.5551 3.53179 12 3.53521C11.4448 3.53862 10.993 3.99041 10.9896 4.54555L10.9912 12.0075Z",
            fill: "#667686"
        }),
        React.createElement("path", {
            d: "M18 18.0066L6.00246 18C6.00012 18 5.99986 15.4582 5.99991 13.9992C5.99993 13.4469 5.55222 13 4.99992 13C4.44768 13 4 13.4477 4 13.9999V18.0066C4 19.1094 4.89859 20 6.00246 20H17.9975C19.109 20 20 19.1113 20 18.0066V14C20 13.4477 19.5523 13 19 13C18.4477 13 18 13.4477 18 14V18.0066Z",
            fill: "#667686"
        })
    );
}
exports.default = SvgDownload;