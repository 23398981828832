'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Legend = require('./Legend');

var _Legend2 = _interopRequireDefault(_Legend);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Legend2.default; /**
                                     * Created by oreuveni
                                     * Date: 2019-07-28
                                     * Time: 13:08
                                     */