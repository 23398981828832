'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _TooltipV = require('../TooltipV2/TooltipV2');

var _TooltipV2 = _interopRequireDefault(_TooltipV);

var _TooltipPosition = require('../Tooltip/TooltipPosition');

var _TooltipPosition2 = _interopRequireDefault(_TooltipPosition);

var _Icons = require('../../Icons/Icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'helpTooltip__taboola-svg-icon___sbDHF',
    'container': 'helpTooltip__container___Gkrco',
    'icon': 'helpTooltip__icon___1XWGN'
};


var HelpTooltip = function HelpTooltip(_ref) {
    var iconClassName = _ref.iconClassName,
        IconComponent = _ref.IconComponent,
        content = _ref.content,
        children = _ref.children,
        onMouseEnter = _ref.onMouseEnter,
        onMouseLeave = _ref.onMouseLeave,
        role = _ref.role,
        iconLabel = _ref.iconLabel,
        tooltipProps = _objectWithoutProperties(_ref, ['iconClassName', 'IconComponent', 'content', 'children', 'onMouseEnter', 'onMouseLeave', 'role', 'iconLabel']);

    return _react2.default.createElement(
        'span',
        {
            className: styles['container'],
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave,
            'aria-label': iconLabel,
            role: role
        },
        _react2.default.createElement(IconComponent, {
            className: styles['icon'] + ' ' + iconClassName
        }),
        _react2.default.createElement(
            _TooltipV2.default,
            Object.assign({
                interactive: true
            }, tooltipProps),
            content || children
        )
    );
};

var _Tooltip$propTypes = _TooltipV2.default.propTypes,
    children = _Tooltip$propTypes.children,
    tooltipPropTypes = _objectWithoutProperties(_Tooltip$propTypes, ['children']);

HelpTooltip.propTypes = Object.assign({
    /** Apply class to style the icon */
    iconClassName: _propTypes2.default.string,
    /** Icon Component to render */
    IconComponent: _propTypes2.default.func,
    /** Adds an arrow pointing to the tooltipped element */
    arrow: _propTypes2.default.bool,
    /** Makes a tooltip interactive, i.e. will not close when the user hovers over or clicks on the tooltip */
    interactive: _propTypes2.default.bool,
    /** Specifies which direction to position the tooltip on the element */
    position: _propTypes2.default.oneOf(Object.values(_TooltipPosition2.default)),
    /** Contents inside tooltip */
    children: _propTypes2.default.node,
    /** Contents inside tooltip for backwards compatibility. It's more advised to use the children prop */
    content: _propTypes2.default.node,
    /** Event Listeners used when overriding default hover tooltip behavior */
    onMouseEnter: _propTypes2.default.func,
    onMouseLeave: _propTypes2.default.func,
    role: _propTypes2.default.string,
    iconLabel: _propTypes2.default.string
}, tooltipPropTypes);

HelpTooltip.defaultProps = {
    iconClassName: '',
    role: 'img',
    iconLabel: 'info',
    arrow: true,
    interactive: true,
    position: _TooltipPosition2.default.RIGHT,
    IconComponent: _Icons.HelpIcon
};

exports.default = HelpTooltip;