import { fromPairs } from 'lodash';
import configKeyTransformer from './configKeyTransformer';

const transformConfigToGW = (features, configKeyData, injectPolicy) =>
    Object.keys(features).map(feature => {
        const fieldName = configKeyTransformer(features[feature], configKeyData);
        const configStructure = { attribute: fieldName };
        if (!injectPolicy) {
            return configStructure;
        }
        return injectPolicy(feature, configStructure);
    });

const transformConfigFromGW = (config = []) => fromPairs(config.map(({ attribute, value }) => [attribute, value]));

export { transformConfigFromGW, transformConfigToGW };
