import { useMemo } from 'react';
import { countBy } from 'lodash';
import useContextualSegmentsApi from './useContextualSegmentsApi';

const useContextualSegmentsTreeData = ({ search, selectedAccountId }) => {
    const {
        contextualSegments,
        fetchContextualSegments: reload,
        isSegmentsLoading,
        fetchContextualSegmentsByIds: loadByIds,
    } = useContextualSegmentsApi({
        selectedAccountId,
    });
    const searchUpperCased = search ? search.toUpperCase() : '';
    const leaves = useMemo(
        () => contextualSegments.filter(s => s.label.toUpperCase().indexOf(searchUpperCased) !== -1),
        [contextualSegments, searchUpperCased]
    );
    const totals = useMemo(() => countBy(leaves, ({ taxonomy }) => taxonomy), [leaves]);

    return {
        leaves,
        totals,
        loadByIds,
        reload,
        isSegmentsLoading,
    };
};

export default useContextualSegmentsTreeData;
