import { transformCreatePreviews } from '../../../campaigns/modules/creative-creator/utils/creativeCreatorTransformerUtils';

export const transformCreativesToGw = creativeData => {
    const { activeCreatives, activeVideoCreatives } = transformCreatePreviews(
        creativeData.aiVariationsCreativePreviews
    );
    const creativeList = [...activeCreatives, ...activeVideoCreatives].map(
        ({ url, title, thumbnailUrl, description, cta }) => ({
            url,
            title,
            thumbnailUrl,
            description,
            ...(cta ? { cta } : {}),
        })
    );

    return creativeList;
};
