import { FormField } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { TrackingSetupMethodModal } from '../components/TrackingSetupMethodModal/TrackingSetupMethodModal';
import { ENTITY } from '../config';
import {
    generateGTMTrackingSetupMethodPath,
    generateManuallyTrackingSetupMethodPath,
    generateS2STrackingSetupMethodPath,
    generateShopifyTrackingSetupMethodPath,
} from '../utils/generateSetupMethodPath';
import styles from './useTrackingSetupMethodTypeModal.module.scss';

const paths = {
    [ENTITY.GTM_SETUP]: generateGTMTrackingSetupMethodPath(),
    [ENTITY.MANUALLY_SETUP]: generateManuallyTrackingSetupMethodPath(),
    [ENTITY.S2S_SETUP]: generateS2STrackingSetupMethodPath(),
    [ENTITY.SHOPIFY_SETUP]: generateShopifyTrackingSetupMethodPath(),
};
export const useTrackingSetupMethodTypeModal = openInNewTab => {
    const navigate = useNavigate();

    const { open: openModal } = useModal();

    const openTrackingSetupModal = async () => {
        const trackingSetupMethodType = await openModal({
            title: (
                <FormField
                    label={<FormattedMessage id="app.modal.trackingSetup.title" defaultMessage="Select Setup Method" />}
                    description={
                        <FormattedMessage
                            id="app.modal.trackingSetup.description"
                            defaultMessage="Choose a tracking method from the options below to start measuring the impact of your Taboola ads."
                        />
                    }
                    labelClass={styles['modal']}
                />
            ),
            contentRenderer: TrackingSetupMethodModal,
            className: styles['modal'],
        });

        if (!trackingSetupMethodType) {
            return;
        }

        if (openInNewTab) {
            const win = window.open(paths[trackingSetupMethodType], '_blank');
            win.focus();
            return;
        }

        navigate(paths[trackingSetupMethodType]);
    };

    return { openTrackingSetupModal };
};
