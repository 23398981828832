import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { CollapsibleCard } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { ValidationSection } from 'modules/taboola-common-frontend-modules/validations';
import { useFormDataContext } from '../../modules/taboola-common-frontend-modules/formData';
import { CollapsibleCardProvider } from './CollapsibleCardContext';
import { useCollapsedState } from './useCollapsedState';
import styles from './commonCollapsibleCard.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CommonCollapsibleCard = ({
    id,
    description,
    isInitiallyCollapsed = false,
    containerClassName,
    headerClassName,
    descriptionClassName,
    children,
    collapsed,
    setCollapsed,
    ...rest
}) => {
    const { mode } = useFormDataContext();
    const isPersisted = mode !== FORM_MODES.CREATE;
    const [correctCollapsed, setCorrectCollapsed] = useCollapsedState({
        sectionId: id,
        isInitiallyCollapsed,
        collapsed,
        setCollapsed,
        isPersisted,
    });
    return (
        <ValidationSection>
            <CollapsibleCardProvider setCollapsed={setCorrectCollapsed}>
                <CollapsibleCard
                    containerClassName={classNameBuilder('card-container', containerClassName)}
                    headerClassName={classNameBuilder('collapsible-header', headerClassName)}
                    iconHoverClass={styles['arrow-icon-hover']}
                    /* Create forms should not use redux/local storage */
                    collapsed={correctCollapsed}
                    setCollapsed={setCorrectCollapsed}
                    {...rest}
                >
                    {Boolean(description) && (
                        <div className={classNameBuilder('description', descriptionClassName)}>{description}</div>
                    )}
                    {children}
                </CollapsibleCard>
            </CollapsibleCardProvider>
        </ValidationSection>
    );
};

CommonCollapsibleCard.propTypes = {
    description: PropTypes.node,
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    isInitiallyCollapsed: PropTypes.bool,
    containerClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    descriptionClassName: PropTypes.string,
    customOnCollapse: PropTypes.func,
    customCollapsed: PropTypes.bool,
};

export default CommonCollapsibleCard;
