import React, { useCallback, useMemo } from 'react';
import { useNavigate } from '../../../../../hooks';
import { CAMPAIGNS_ROUTE, REPORT_TYPE } from '../../../../../modules/campaigns/config';
import { CAMPAIGN_ID } from '../../../../../modules/campaigns/hooks';
import { REPORT_ID } from '../../../../../modules/campaigns/modules/campaigns-reports/hooks';
import {
    COMMON_FLAGS,
    useCommonConfig,
} from '../../../../../modules/taboola-common-frontend-modules/account-configurations';
import { FormattedRelativeTime } from '../../../../../modules/taboola-common-frontend-modules/i18n';
import { mergeQueryParams } from '../../../../../modules/taboola-common-frontend-modules/query-params';
import { NotificationCard } from '../../NotificationCard';
import { truncateTargetName } from '../../utils';

export const CvrLearningStatusNotification = ({ notification, timeDiffInMinutes, closeNotificationCenter }) => {
    const {
        referredEntityId: campaignId,
        attributes: { referredEntityName: campaignName, cvrLearningPhase },
    } = notification;

    const navigate = useNavigate();

    const {
        [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]:
            numberOfConversionsInTimeframeForCompletion,
    } = useCommonConfig([COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]);

    const navigateToSelectedCampaignReport = useCallback(() => {
        const route = `${CAMPAIGNS_ROUTE}${mergeQueryParams('', {
            [REPORT_ID]: REPORT_TYPE.CAMPAIGN,
            [CAMPAIGN_ID]: campaignId,
        })}`;
        navigate(route);
    }, [campaignId, navigate]);

    const truncatedCampaignName = useMemo(() => truncateTargetName(campaignName), [campaignName]);
    const notificationWithDescriptionCode = useMemo(
        () => ({
            ...notification,
            attributes: {
                ...notification.attributes,
                descriptionParams: {
                    numberOfConversionsInTimeframeForCompletion,
                },
            },
        }),
        [notification, numberOfConversionsInTimeframeForCompletion]
    );

    return (
        <NotificationCard
            notification={notificationWithDescriptionCode}
            closeNotificationCenter={closeNotificationCenter}
            cardClick={navigateToSelectedCampaignReport}
            descriptionCodeSuffix={cvrLearningPhase}
            dataMetricsComponent="NotificationActionCard"
            formattedMessageId="app.notificationsCenter.recommendationCampaignTargetDetails"
            formattedMessageValues={{
                recommendationTargetName: truncatedCampaignName,
                recommendationTargetId: campaignId,
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
        />
    );
};
