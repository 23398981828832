export const updateThumbnailById = (collection, id, data) => {
    const index = collection.findIndex(el => el.id === id);

    if (index === -1) {
        return collection;
    }

    return [
        ...collection.slice(0, index),
        {
            ...collection[index],
            ...data,
        },
        ...collection.slice(index + 1),
    ];
};
