import React from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import BaseCellEditor from './BaseCellEditor';
import styles from './dropdownCellEditor.module.scss';

const DropdownCellEditor = ({ options, dropdownValue, onChange, onSubmit, onCancel, colDef }) => (
    <BaseCellEditor onCancel={onCancel} onSubmit={onSubmit} colDef={colDef}>
        <DropdownMenu
            options={options}
            value={dropdownValue}
            className={styles['dropdown-container']}
            onChange={onChange}
        />
    </BaseCellEditor>
);

const DropdownCellEditorWithFormData = withGridCellFormDataProvider(DropdownCellEditor);

export { DropdownCellEditor };
export default DropdownCellEditorWithFormData;
