import { createStore, applyMiddleware, compose } from 'redux';
import { config as logRocketConfig } from 'modules/taboola-common-frontend-modules/log-rocket';
import { IS_DEBUG_MODE } from 'services/isDebugMode';
import customMiddlewares from './rootMiddelware';
import rootReducer from './rootReducer';

let store;

export const initStore = () => {
    const enhancers = [];
    const __REDUX_DEVTOOLS_EXTENSION__ = '__REDUX_DEVTOOLS_EXTENSION__';

    if (IS_DEBUG_MODE()) {
        const { [__REDUX_DEVTOOLS_EXTENSION__]: devToolsExtension } = window;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    const additionalMiddlewares = [...customMiddlewares];
    if (logRocketConfig.isActive()) {
        additionalMiddlewares.push(logRocketConfig.getMiddleWare());
    }

    const composedEnhancers = compose(applyMiddleware(...additionalMiddlewares), ...enhancers);

    store = createStore(rootReducer, composedEnhancers);

    return store;
};

export const getStore = () => {
    if (!store) {
        throw new Error("Store wasn't initialized");
    }
    return store;
};
