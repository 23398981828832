import { forEach, partition, isEmpty } from 'lodash';

export const generateResponseSummary = (excelConfig, apiResponseMap) => {
    const responseSummary = { errors: [], successes: [] };

    forEach(excelConfig.entityList, sheetConfig => {
        const entity = sheetConfig.entity;
        const responseData = apiResponseMap[entity];

        if (responseData) {
            const [failedEntities, successfulEntities] = partition(responseData, 'error');
            const totalCount = failedEntities.length + successfulEntities.length;

            if (!isEmpty(failedEntities)) {
                responseSummary.errors.push({
                    entity,
                    errorCount: failedEntities.length,
                    totalCount,
                    failedEntities,
                });
            }

            if (!isEmpty(successfulEntities)) {
                responseSummary.successes.push({
                    entity,
                    successCount: successfulEntities.length,
                    totalCount,
                    successfulEntities,
                });
            }
        }
    });

    return responseSummary;
};
