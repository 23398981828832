import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { NodeLabel, Tree } from '../../Tree';
import SelectAll from '../../Tree/SelectAll/SelectAll';
import { useOsTreeContext } from '../OsTreeContext';
import styles from './osTree.module.scss';

const OsTree = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        nodesTree,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        disabledPathsMap,
        collapsedPathMap,
        totalCount,
        handleCollapseChange,
    } = useOsTreeContext();
    const [isAllChecked, setIsAllChecked] = useState(false);

    const renderNodeText = useCallback(
        (domProps, { node, depth }) => {
            const { label } = node;
            return <NodeLabel node={node} searchText={search} title={label} depth={depth} />;
        },
        [search]
    );

    const handleSelectAllWrapper = useCallback(() => {
        handleSelectAll(!isAllChecked);
        setIsAllChecked(prev => !prev);
    }, [handleSelectAll, isAllChecked]);

    const renderSelectAll = useCallback(
        () =>
            isSearchMode ? (
                <SelectAll onClick={handleSelectAllWrapper} disabled={totalCount === 0}>
                    <FormattedMessage
                        id={`campaign.editor.targeting.os.${!isAllChecked ? 'selectAll' : 'deselectAll'}.title`}
                        defaultMessage="Select all {totalItems} results"
                        values={{ totalItems: totalCount }}
                    />
                </SelectAll>
            ) : null,
        [isSearchMode, handleSelectAllWrapper, totalCount, isAllChecked]
    );

    return (
        <div className={styles['container']} ref={innerRef}>
            <Tree
                nodesTree={nodesTree}
                onSelect={handleSelectNode}
                selectedItemsMap={selectedPathsMap}
                totalItems={totalCount}
                collapsed={collapsedPathMap}
                onCollapsedChange={handleCollapseChange}
                renderNodeText={renderNodeText}
                renderSelectAll={renderSelectAll}
                disabledPathsMap={disabledPathsMap}
                noOptionsContent={<FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />}
                loadingMode={loadingMode}
            />
        </div>
    );
};

OsTree.propTypes = {
    innerRef: PropTypes.func,
};

export default OsTree;
