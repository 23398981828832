import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { LoadingMode } from '../../../../campaigns/modules/common-campaign-form/components/Tree';

const CombinedAudiencesWithoutBundlingContext = createContext({});

export function CombinedAudiencesWithoutBundlingProvider({ children, ...rest }) {
    return (
        <CombinedAudiencesWithoutBundlingContext.Provider value={rest}>
            {children}
        </CombinedAudiencesWithoutBundlingContext.Provider>
    );
}

CombinedAudiencesWithoutBundlingProvider.propTypes = {
    children: PropTypes.node,
    search: PropTypes.string,
    isSearchMode: PropTypes.bool,
    loadingMode: PropTypes.oneOf(Object.values(LoadingMode)),
    nodesTree: PropTypes.array,
    handleSelectNode: PropTypes.func,
    handleSelectAll: PropTypes.func,
    selectedPathsMap: PropTypes.object,
    collapsedPathMap: PropTypes.object,
    disabledPathsMap: PropTypes.object,
    totalCount: PropTypes.number,
    handleCollapseChange: PropTypes.func,
    handleLoadMore: PropTypes.func,
    forceLoadingAll: PropTypes.bool,
    limit: PropTypes.number,
    isProviderEnabled: PropTypes.func,
    getPartnerByProvider: PropTypes.func,
    enabledPartnerGroup: PropTypes.array,
    unbundleNode: PropTypes.func,
    getIsNodeBundled: PropTypes.func,
    nodes: PropTypes.array,
    getNodesTree: PropTypes.func,
};

export function useCombinedAudiencesWithoutBundlingContext() {
    return useContext(CombinedAudiencesWithoutBundlingContext);
}
