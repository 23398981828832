/**
 * Created by lawrencel
 * Date: 2020-03-22
 * Time: 10:51
 */
import React from 'react';
import classnames from 'classnames/bind';
import { get, identity, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { CloudinaryImage, ImageContentLoader } from 'taboola-ultimate-ui';
import { CTA } from '../../../../config';
import GraphTooltipMetrics from './GraphTooltipMetrics';
import styles from './creativeGraphTooltip.module.scss';

const classNameBuilder = classnames.bind(styles);
const defaultFormatter = identity;
const IMG_HEIGHT = 104;
const IMG_WIDTH = 188;

const CreativeGraphTooltip = ({ label, payload, labelFormatter }) => {
    if (isEmpty(payload)) {
        return null;
    }
    const creativeId = get(payload, '0.payload.id', null);
    const url = get(payload, '0.payload.thumbnailUrl', null);
    const encodedUrl = encodeURIComponent(url);
    const description = get(payload, '0.payload.description', null);

    return generateCreativeTooltip({
        creativeId,
        url: encodedUrl,
        description,
        label,
        labelFormatter,
        isLegend: false,
        payload,
    });
};

const generateCreativeTooltip = ({ creativeId, url, description, label, labelFormatter, isLegend, payload }) => {
    return (
        <div className={classNameBuilder({ wrapper: !isLegend })}>
            <div className={styles['creative-container']}>
                <CloudinaryImage
                    className={styles['image']}
                    src={url}
                    height={IMG_HEIGHT}
                    width={IMG_WIDTH}
                    sharpen
                    gravity="faces:auto"
                    loadingComponent={<ImageContentLoader height={IMG_HEIGHT} width={IMG_WIDTH} />}
                />
                <div className={styles['creative-label']}>{labelFormatter(label)}</div>
                {description && <div className={styles['description']}>{description}</div>}
            </div>
            {!isLegend && <div className={styles['sub-label']}>{creativeId && `ID: ${creativeId}`}</div>}
            {!isLegend && <GraphTooltipMetrics payload={payload} />}
        </div>
    );
};

export const CreativeLegendTooltip = payload => {
    if (isEmpty(payload)) {
        return null;
    }
    const ctaType = payload?.cta?.ctaType;
    const description = payload?.description || '';
    const ctaTypeValue = ctaType && ctaType !== CTA.NONE ? `[${ctaType}]` : '';
    return generateCreativeTooltip({
        url: payload.thumbnailUrl,
        description: `${description} ${ctaTypeValue}`,
        label: payload.label,
        labelFormatter: defaultFormatter,
        isLegend: true,
    });
};

export const CreativeLegendTooltipClass = classNameBuilder('tooltip-content', 'prioritized');

CreativeGraphTooltip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payload: PropTypes.array,
    labelFormatter: PropTypes.func,
};

CreativeGraphTooltip.defaultProps = {
    labelFormatter: defaultFormatter,
};

export default CreativeGraphTooltip;
