import React from 'react';
import { useRouteMatch } from 'react-router';
import { useSelectedReportId } from '../../../hooks';
import { useCustomPresetAccountId } from '../../../hooks/useCustomPresetAccountId';
import { useCustomPresetDisplayName } from '../../../hooks/useCustomPresetDisplayName';
import { COLUMN_PICKER_EDITOR_ROUTE_PATH, REPORT_PRESET_PARAM_NAME } from '../../../routes';
import { useReportPresets } from '../../ReportPresetPicker/hooks/useReportPresets';
import { BaseColumnPicker } from './BaseColumnPicker';

export const ColumnPresetEditor = ({ onCancel, reportConfig }) => {
    const [selectedReport] = useSelectedReportId();
    const { params: { [REPORT_PRESET_PARAM_NAME]: reportPresetName } = {} } =
        useRouteMatch(COLUMN_PICKER_EDITOR_ROUTE_PATH) || {};

    const [reportPresetDisplayName = reportPresetName] = useCustomPresetDisplayName(selectedReport, reportPresetName);
    const [presetAccountId] = useCustomPresetAccountId(selectedReport, reportPresetName);
    const reportPresets = useReportPresets(reportConfig);
    const isInvalidPreset = !reportPresets[reportPresetName];

    return (
        <BaseColumnPicker
            onCancel={onCancel}
            reportConfig={reportConfig}
            reportPresetName={reportPresetName}
            reportPresetDisplayName={reportPresetDisplayName}
            reportPresetAccountId={presetAccountId}
            isInvalidPreset={isInvalidPreset}
        />
    );
};
