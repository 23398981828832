import ReactDOM from 'react-dom';
import { get } from 'lodash';

const appendExcludeButtons = newExcludeButton => {
    const thumbBlocks = document.querySelectorAll('.thumbBlock');
    thumbBlocks.forEach(block => {
        const parent = window.TRC.dom.closest(block, '.videoCube');
        if (get(parent, ['video_data', 'placementUrl'], '')) {
            ReactDOM.render(newExcludeButton, block);
        }
    });
};

export default appendExcludeButtons;
