import { useCallback, useState } from 'react';
import { keyBy } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';

const fetchTaxonomies = ({ selectedAccountId, getMarketplaceTaxonomies }) => {
    const accountId = selectedAccountId || {};

    return getMarketplaceTaxonomies(accountId);
};

const transformTaxonomies = taxonomies => {
    const transformedTaxonomies = taxonomies.map(({ name, ...rest }) => ({ id: name, ...rest }));
    return keyBy(transformedTaxonomies, 'id');
};

const useTaxonomiesResources = ({ selectedAccountId }) => {
    const { getMarketplaceTaxonomies } = useAudiencesApi();
    const [taxonomies, setTaxonomies] = useState({});
    const taxonomiesFetcher = useCallback(async () => {
        const { results } = await fetchTaxonomies({ selectedAccountId, getMarketplaceTaxonomies });
        const transformedTaxonomies = transformTaxonomies(results);
        setTaxonomies(transformedTaxonomies);

        return transformedTaxonomies;
    }, [getMarketplaceTaxonomies, selectedAccountId]);

    const getTaxonomyValue = useCallback(
        ({ id, label }) => {
            return taxonomies[id]?.value || label || id;
        },
        [taxonomies]
    );

    return { getTaxonomyValue, reloadTaxonomies: taxonomiesFetcher };
};

export default useTaxonomiesResources;
