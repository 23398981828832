import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useBidStrategyFormField } from '../BidStrategyV2/hooks/useBidStrategyFormField';
import styles from './bidStrategyValue.module.scss';

export const BidStrategyValue = () => {
    const { value: bidStrategy } = useBidStrategyFormField();

    return bidStrategy != null ? (
        <div className={styles['container']}>
            <FormattedMessage
                id={`campaign.editor.campaignId.bidStrategy.${bidStrategy}`}
                defaultMessage={`Bid Strategy: ${bidStrategy}`}
            />
        </div>
    ) : null;
};
