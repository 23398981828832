import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations/services';

export const cpaValidations = (minRange, maxRange, shouldDisplayWarnings) => [
    {
        validationFn: validationFunctions.range,
        options: { max: maxRange },
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { min: minRange },
        messageId: 'performance-recommendations.set-cpa.wrong-input.message',
        defaultMessage: 'Set a CPA within the range',
        severity: INDICATION_TYPES.ERROR,
    },
];
