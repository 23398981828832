import { errorMessagesUtils } from '../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { fetchScheduledReportForEditError } from '../actions/fetchScheduledReport';
import { transformScheduledReportFromGw } from '../transformers/scheduledReportsFromGWTransformer';

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Unable to get report." />,
});

export const fetchScheduledReport =
    (accountName, scheduledReportId, getScheduledReportData, { hideError } = {}) =>
    async dispatch => {
        try {
            const response = await getScheduledReportData(accountName, scheduledReportId);
            const scheduleReport = transformScheduledReportFromGw(response);
            return scheduleReport;
        } catch (e) {
            if (!hideError) {
                dispatch(addIndication(generateErrorIndication(e)));
                dispatch(fetchScheduledReportForEditError(e));
            }
        }
    };
