import React from 'react';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION, LightningIcon, LightningRoundIcon } from 'taboola-ultimate-ui';
import { useTestValue } from 'modules/taboola-common-frontend-modules/ab-test';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { InternalLink } from '../../../../components';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { generatePerformanceRecommendationsReviewerPath } from '../../services/utils';
import styles from './campaignPickerListItemRecommendationsButton.module.scss';

export const CampaignPickerListItemRecommendationsButton = ({
    recommendationsCount,
    campaignId,
    accountId,
    accountName,
}) => {
    const { value: showOutlineIcon, analyticProps } = useTestValue({ key: 'showLightningOutlineIcon' });

    if (!accountName) {
        return null;
    }

    return (
        <div className={styles['recommendations-button-container']}>
            <InternalLink
                aria-label="Optimization Opportunities"
                className={styles['recommendations-button']}
                path={generatePerformanceRecommendationsReviewerPath({
                    accountId: accountName,
                    campaignId,
                })}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-visibility={GTM_EVENTS.VISIBLE}
                data-metrics-account-id={accountId}
                data-metrics-taboola-campaign-id={campaignId}
                data-metrics-component="CampaignPickerRecommendationsButton"
                {...analyticProps}
            >
                {showOutlineIcon ? <LightningRoundIcon /> : <LightningIcon />}
            </InternalLink>
            <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow offsetBody={1}>
                <FormattedMessage
                    id={`campaign.picker${recommendationsCount === 1 ? '.single' : ''}.recommendation`}
                    values={{ recommendationsCount }}
                />
            </Tooltip>
        </div>
    );
};
