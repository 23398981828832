import React from 'react';
import { FormField, useCollapsibleListContext } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ListWithMultilineInput } from '../ListWithMultilineInput';
import UrlKeywordsTooltip from './UrlKeywordsTooltip/UrlKeywordsTooltip';
import { validateKeyword } from './utlis/utils';
import styles from '../commonEditor.module.scss';

const ListHeader = () => {
    const { items } = useCollapsibleListContext();
    return (
        <FormattedMessage
            id="video.campaign.creator.url.keywords.words"
            defaultMessage="Selected URL Keywords"
            values={{ count: items.length }}
        />
    );
};

const topContentPlaceholder = 'Paste keywords here...';

const footerErrorMessage = (
    <FormattedMessage
        id="video.campaign.creator.url.keywords.urls.error.noResultsFound"
        defaultMessage="Enter at least 2 characters, English only"
    />
);

const footerButtonMessage = (
    <FormattedMessage id="video.campaign.creator.urls.keywords.addKeywords" defaultMessage="ADD KEYWORDS" />
);

const validations = [];

const typeField = 'campaignTargeting.urlKeywordTargeting.type';
const valueField = 'campaignTargeting.urlKeywordTargeting.values';

const UrlKeywords = () => {
    const validateKeywords = domains => {
        let results = [];
        domains.map(name => {
            return validateKeyword(name) ? results.push({ name }) : null;
        });
        return results;
    };
    return (
        <FormField
            inputId="url-keywords"
            label={<FormattedMessage id="video.campaign.creator.url.keywords.title" defaultMessage="URL Keywords" />}
            description={
                <FormattedMessage
                    id="video.campaign.creator.url.keywords.description"
                    defaultMessage="Target or exclude specific Keywords in the site's URL."
                />
            }
            subDescription={
                <FormattedMessage
                    id="video.campaign.creator.url.keywords.sub.description"
                    defaultMessage="Separate Keywords by a new line, commas or semicolons"
                />
            }
            helpText={<UrlKeywordsTooltip />}
            containerClass={styles['input']}
        >
            <ListWithMultilineInput
                typeField={typeField}
                valueField={valueField}
                validateInput={validateKeywords}
                listHeader={<ListHeader />}
                topContentPlaceholder={topContentPlaceholder}
                footerErrorMessage={footerErrorMessage}
                footerButtonMessage={footerButtonMessage}
                validations={validations}
            />
        </FormField>
    );
};

export default UrlKeywords;
