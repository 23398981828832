import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import {
    DAY,
    sortArrayWithoutMutation,
    sortTerms,
    topicToNameAndLabel,
} from '../SegmentTopics/customContextualTopicsUtils';
import { useCountriesField } from '../SegmentTopics/hooks/useCountriesField';

const EMPTY_ARRAY = [];
const latinLettersRegex = /^[a-zA-Z]+$/;
export const isValidTextLength = str => {
    if (latinLettersRegex.test(str)) {
        return str.length > 1;
    }
    return str.length > 0;
};

const useSearchCustomContextualTopics = searchInputValue => {
    const { value: languages } = useFormFieldValue({ field: 'languages' });
    const { value: platforms } = useFormFieldValue({ field: 'platforms' });
    const { value: countries } = useCountriesField();

    const { value: terms } = useFormFieldValue({ field: 'terms' });
    const queryKey = useMemo(
        () => ({
            languages: sortArrayWithoutMutation(languages),
            platforms: sortArrayWithoutMutation(platforms),
            countries: sortArrayWithoutMutation(countries),
            search_text: searchInputValue,
            terms: sortTerms(terms),
        }),
        [languages, platforms, terms, countries, searchInputValue]
    );
    const params = useMemo(
        () => ({
            languages,
            platforms,
            countries,
            search_text: searchInputValue,
            terms,
        }),
        [languages, platforms, terms, countries, searchInputValue]
    );
    const { getCustomContextualTopics } = useAudiencesApi();
    const { isLoading, data = EMPTY_ARRAY } = useQuery(
        ['listOfTopics', queryKey],
        () => {
            if (isValidTextLength(params.search_text)) {
                return getCustomContextualTopics(params);
            }
            return { results: EMPTY_ARRAY };
        },
        {
            select: ({ results }) => results.map(topicToNameAndLabel),
            refetchOnWindowFocus: false,
            staleTime: DAY,
        }
    );

    return { isSearchTopicsLoading: isLoading, searchTopics: data };
};

export default useSearchCustomContextualTopics;
