import { FormField } from 'taboola-ultimate-ui';
import { withIndication } from '../../../errors';

export const FormFieldWithIndication = withIndication(FormField);
export const msgIdPrefix = 'app.scheduled.reports.create.settings.frequency';
export const formFieldId = 'frequency-selector';
export const subFormFieldId = 'sub-frequency-selector';
export const helpTextDefaultMessage =
    "Daily: Previous day's data delivered each day{br}" +
    "Weekly: Previous week's data delivered each week on whichever day you choose{br}" +
    "Monthly: Previous month's data delivered on the first or last day of each month";
