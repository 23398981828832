"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgUpload(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M14 14.0065L2.00246 13.9999C2.00012 13.9999 1.99986 11.4581 1.99991 9.99912C1.99993 9.44681 1.55222 8.99994 0.999917 8.99994C0.447678 8.99994 0 9.44762 0 9.99986V14.0065C0 15.1094 0.898592 15.9999 2.00246 15.9999H13.9975C15.109 15.9999 16 15.1113 16 14.0065V9.99994C16 9.44766 15.5523 8.99995 15 8.99995C14.4477 8.99995 14 9.44766 14 9.99995V14.0065Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9.00873 3.02769C9.82341 3.84349 10.8444 4.86551 10.8444 4.86551C11.2349 5.25603 11.8681 5.25603 12.2586 4.86551C12.6491 4.47498 12.6491 3.84182 12.2586 3.45129L9.4255 0.618199C8.64656 -0.160743 7.38313 -0.15852 6.60384 0.620767L3.77331 3.45129C3.38279 3.84182 3.38279 4.47498 3.77331 4.86551C4.16384 5.25603 4.797 5.25603 5.18753 4.86551L7.00017 3.05286L7.0024 10.5023C6.99898 11.0575 7.44487 11.5034 8 11.4999C8.55513 11.4965 9.00692 11.0447 9.01034 10.4896L9.00873 3.02769Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgUpload;