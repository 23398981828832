import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../../selectors';
import { isNetworkAccountType } from '../../../account-management';
import styles from './invalidManageLevel.module.scss';

export const InvalidManageLevel = () => {
    const { type } = useSelector(selectedAccountSelector);
    const isNetwork = isNetworkAccountType(type);
    const errorTitleSuffix = isNetwork ? 'managedByAccount' : 'managedByNetwork';
    const descriptionMessageSuffix = isNetwork ? 'switchToAccount' : 'switchToNetwork';

    return (
        <div className={styles['container']}>
            <div className={styles['errorTitle']}>
                <FormattedMessage id={`billingAndPayments.billingManagement.${errorTitleSuffix}`} />
            </div>
            <div className={styles['errorDescription']}>
                <FormattedMessage id={`billingAndPayments.billingManagement.${descriptionMessageSuffix}`} />
            </div>
        </div>
    );
};
