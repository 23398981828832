import React, { useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Dotdotdot, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import styles from './listWithMultilineInputBoxFooter.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ListWithMultilineInputBoxFooter = ({
    error,
    selectedIdsCount,
    onSubmit,
    footerErrorMessage,
    footerButtonMessage,
    submitBtnMetricsAttributes,
}) => {
    const [submittedIdsCount, setSubmittedIdsCount] = useState(0);
    const handleClick = () => {
        setSubmittedIdsCount(selectedIdsCount);
        onSubmit();
    };

    return (
        <div className={styles['footer-container']}>
            {error && (
                <Dotdotdot lines={2} className={classNameBuilder('error-message')}>
                    {footerErrorMessage}
                </Dotdotdot>
            )}

            {error && (
                <div className={classNameBuilder('counter')}>
                    {selectedIdsCount}/{submittedIdsCount}
                </div>
            )}
            <StyledButton
                className={styles['button']}
                onClick={handleClick}
                size={STYLED_BUTTON_SIZE.SMALL}
                type={STYLED_BUTTON_TYPE.STRONG}
                disabled={selectedIdsCount === 0}
                {...submitBtnMetricsAttributes}
            >
                {footerButtonMessage}
            </StyledButton>
        </div>
    );
};

ListWithMultilineInputBoxFooter.propTypes = {
    error: PropTypes.bool,
    selectedIdsCount: PropTypes.number,
    onSubmit: PropTypes.func,
    submitBtnMetricsAttributes: PropTypes.object,
};

ListWithMultilineInputBoxFooter.defaultProps = {
    submitBtnMetricsAttributes: {},
};

export default ListWithMultilineInputBoxFooter;
