import { useSelectedAccount } from 'hooks';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';

export const usePixelWarningPermitted = () => {
    const [{ tier }] = useSelectedAccount();
    const { [COMMON_FLAGS.TA_PIXEL_WITH_TIER_WARNINGS]: allowedTiers } = useCommonConfig([
        COMMON_FLAGS.TA_PIXEL_WITH_TIER_WARNINGS,
    ]);

    const arrayOfTiers = allowedTiers.split(',');

    return arrayOfTiers.includes(tier);
};
