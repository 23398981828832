import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './phaseStatusTooltip.module.scss';

const classNameBuilder = classnames.bind(styles);
const PhaseStatusTooltip = ({ phase, phaseMsgIdPrefix, containerClassName, headerClassName }) => (
    <div className={classNameBuilder(styles['tooltip-text-container'], containerClassName)}>
        <div className={classNameBuilder(styles['tooltip-header'], headerClassName)}>
            <FormattedMessage id={`${phaseMsgIdPrefix}.${phase}`} defaultMessage={phase} />
        </div>
        <FormattedMessage id={`${phaseMsgIdPrefix}.tooltip.main.${phase}`} defaultMessage={phase} />
    </div>
);

PhaseStatusTooltip.propTypes = {
    phase: PropTypes.string,
    phaseMsgIdPrefix: PropTypes.string,
};

export default PhaseStatusTooltip;
