import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useProfitMarginFormFieldValue = () => {
    const isPermitted = useConfigMatch({ [FEATURE_FLAGS.PROFIT_MARGIN_ENABLED]: 'true' });
    const { value: profitMargin, onChange: onProfitMarginChange } = useFormFieldValue({
        field: 'finance.profitMargin',
        isPermitted,
    });

    return { value: profitMargin, onChange: onProfitMarginChange, isPermitted };
};
