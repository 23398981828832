import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { performanceRecommendationsSubTypeCards } from 'modules/campaigns/modules/performance-recommendations/components/BudgetPerformanceRecommendation/BudgetPerformanceRecommendationSubTypeConfig';
import ButtonsSection from 'modules/campaigns/modules/performance-recommendations/components/ButtonsSection/ButtonsSection';
import PerformanceRecommendationCard from 'modules/campaigns/modules/performance-recommendations/components/PerformanceRecommendationCard/PerformanceRecommendationCard';
import { FormattedNumber, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import usePerformanceRecommendationExecution from '../../hooks/usePerformanceRecommendationExecution';
import { useRecommendationService } from '../../hooks/useRecommendationService';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import DismissSection from '../DismissSection/DismissSection';

export const BudgetPerformanceRecommendationSubTypeCard = ({ recommendation, messageParams = {}, params }) => {
    const { formatMessage } = useIntl();
    const { id: recommendationId, subType, campaignId, accountName: accountId } = recommendation;

    const { editCampaignWithRecommendation: onApply } = useRecommendationService();

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        onApply,
        markAsApplied: false,
        onApplySuccess: noop,
        errorCode: params.errorCode,
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        applyParams: {
            recommendation,
            accountId,
            campaignId,
        },
    });
    const subTypeConfig = performanceRecommendationsSubTypeCards[subType];
    const { increaseBudgetAmount, publisherCurrency } = messageParams;
    const increaseBudgetWithCurrency = publisherCurrency && (
        <FormattedNumber value={increaseBudgetAmount} variant="currency" currency={publisherCurrency} />
    );
    const transformedMessageParams = {
        ...messageParams,
        timeOfTheDay: formatMessage({
            id: `${params.descriptionCode}.${messageParams.timeOfTheDay}`,
            defaultMessage: `${messageParams.timeOfTheDay}`,
        }),
        spendingLimitModel: formatMessage({
            id: `${params.descriptionCode}.${messageParams.spendingLimitModel}`,
            defaultMessage: `${messageParams.spendingLimitModel}`,
        }),
    };
    const messageDetails = {
        increaseBudgetAmountWithCurrency: increaseBudgetWithCurrency || increaseBudgetAmount,
        ...transformedMessageParams,
    };

    return (
        <PerformanceRecommendationCard
            isApplied={apply.isSuccess}
            recommendationId={recommendationId}
            messageParams={messageDetails}
            descriptionCode={subTypeConfig.descriptionCode}
            titleCode={params.titleCode}
            iconImg={params.iconImg}
        >
            <ButtonsSection>
                <DismissSection
                    dismissInvoker={dismiss.invoke}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissCode={params.dismissCode}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode={params.actionCode}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode={params.tooltipCode}
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

BudgetPerformanceRecommendationSubTypeCard.propTypes = {
    recommendation: PropTypes.object,
};

export default BudgetPerformanceRecommendationSubTypeCard;
