import { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { identity, isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { userSelector } from 'modules/taboola-common-frontend-modules/authentication/selectors/baseSelectors';
import { locationSearchSelector } from 'selectors/appSelectors';
import { HISTORY_METHOD } from '../QueryParamBatcher';
import { QueryParamsContext } from '../QueryParamsProvider';
import { getQueryParamForOptions } from '../queryParamsProviderUtils';

const existingParmaHooks = new Set();
export const queryParamHookFactory = (paramName, options) => {
    if (existingParmaHooks.has(paramName)) {
        throw new Error(`You already have existing hook for param: ${paramName}`);
    }

    existingParmaHooks.add(paramName);

    const useQueryParam = () => {
        const {
            serializer = identity,
            deserializer,
            comparator = isEqual,
            persist,
            defaultValue,
            dependencies,
            order,
            storageKeyGetter,
            keepSingleValue,
        } = options ?? {};
        const { set, get, registerParam, remove } = useContext(QueryParamsContext);

        const getContext = useCurrentValueGetter({ set, get, remove });

        const value = useMemo(
            () => get({ paramName, deserializer, defaultValue, persist, dependencies, storageKeyGetter }),
            [get, deserializer, defaultValue, persist, dependencies, storageKeyGetter]
        );
        const memoizedValue = useRef(value);

        // some users will want the value to be memoized if the deserialized value is an object
        // only update the memoized value IFF
        //      a comparator is not passed OR
        //      the comparator returns false for the memoized and new value
        if (!comparator || !comparator(value, memoizedValue.current)) {
            memoizedValue.current = value;
        }

        const setValue = useCallback(
            (value, method = HISTORY_METHOD.PUSH) => {
                if (!mounted.current) {
                    return;
                }
                const { set } = getContext();
                return set({ paramName, value, method, serializer, defaultValue, persist, dependencies });
            },
            [defaultValue, dependencies, getContext, persist, serializer]
        );

        const unsetValue = useCallback(() => {
            const { remove } = getContext();
            remove(paramName);
        }, [getContext]);

        const mounted = useRef(true);

        useLayoutEffect(
            () => () => {
                mounted.current = false;
            },
            []
        );

        useLayoutEffect(
            () =>
                registerParam({
                    paramName,
                    serializer,
                    deserializer,
                    persist,
                    defaultValue,
                    dependencies,
                    order,
                    storageKeyGetter,
                    keepSingleValue,
                }),
            [
                defaultValue,
                dependencies,
                deserializer,
                persist,
                registerParam,
                serializer,
                order,
                storageKeyGetter,
                keepSingleValue,
            ]
        );

        return [memoizedValue.current, setValue, unsetValue];
    };

    /**
     * @deprecated - will be removed after migration of all selectors
     */
    useQueryParam.createSelector = () => {
        return createSelector(locationSearchSelector, userSelector, (locationSearch, { username }) =>
            getQueryParamForOptions({ search: locationSearch, username, paramName, options })
        );
    };

    return useQueryParam;
};
