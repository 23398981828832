import React, { useCallback } from 'react';
import { BareDropdown, CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import styles from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/PerformanceRuleEmailNotificationsScheduledReportsList.module.scss';
import { ScheduledReportListItem } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/ScheduledReportListItem';
import { useScheduledReportsList } from 'modules/performance-rules/hooks/useScheduledReportsList';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
export const PerformanceRuleEmailNotificationsScheduledReportsList = ({
    scheduledReportIds,
    onScheduleReportIdsChange,
    failedValidationData,
}) => {
    const { options, scheduledReports, selectedReports, loadScheduledReportsList, setScheduledReports } =
        useScheduledReportsList({
            scheduledReportIds,
        });

    const addItem = useCallback(
        ({ id }) => onScheduleReportIdsChange(prev => [...prev, id]),
        [onScheduleReportIdsChange]
    );

    const deleteItem = useCallback(
        id => onScheduleReportIdsChange(prev => prev.filter(val => val !== id)),
        [onScheduleReportIdsChange]
    );

    const clearItems = useCallback(() => {
        onScheduleReportIdsChange([]);
    }, [onScheduleReportIdsChange]);

    return (
        <div className={styles['container']}>
            <div className={styles['dropdown-container']}>
                <PaginatedDropdown
                    id="scheduled-report-selector"
                    onChange={addItem}
                    searchable
                    containerClassName={styles['dropdown']}
                    enabledWhileSearching
                    options={options}
                    loadPage={loadScheduledReportsList}
                    onOptionsLoaded={setScheduledReports}
                    placeholder={
                        <FormattedMessage
                            id="app.performance.rules.setup.email.notifications.placeholder"
                            defaultMessage="Select Scheduled Report..."
                        />
                    }
                    DropdownMenuComponent={BareDropdown}
                    optionItemRenderer={({ data: { name } }) => name}
                    selectedValueObject={null}
                    totalItemCount={options ? options.length : null}
                />
            </div>
            <CollapsibleListWithIndication
                items={selectedReports}
                data-automation="conversion-collapsible-list"
                ItemComponent={ScheduledReportListItem}
                metadata={{ scheduledReports }}
                deleteItem={deleteItem}
                clearItems={clearItems}
                {...failedValidationData}
            />
        </div>
    );
};
