import React from 'react';
import { FormBreadcrumbs } from 'modules/campaigns/modules/common-campaign-form';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { usePerformanceRulesBreadcrumbs } from '../../hooks/usePerformanceRulesBreadcrumbs';
import styles from './performanceRulesFormBreadcrumbs.module.scss';

export const PerformanceRulesFormBreadcrumbs = ({ msgId, defaultMessage }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: msgId, defaultMessage });
    const { steps } = usePerformanceRulesBreadcrumbs({
        title,
    });

    return <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />;
};
