import React from 'react';
import { DropdownField } from 'modules/campaigns/components/Form';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIsSupplyTargetingSelectPermitted } from '../SupplyTargeting/hooks/useIsSupplyTargetingSelectPermitted';
import { HighImpactTargetingTooltip } from './HighImpactTargetingTooltip';
import { useHighImpactTargetingLogic } from './useHighImpactTargetingLogic';

const dropdownValidations = [];

export const HighImpactTargeting = () => {
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const { isPermitted, selectedValue, options, changeHandler } = useHighImpactTargetingLogic(accountName);
    const isTaboolaSelectPermitted = useIsSupplyTargetingSelectPermitted();

    if (!isPermitted || isTaboolaSelectPermitted) {
        return null;
    }

    return (
        <DropdownField
            id="high-impact-package"
            label={
                <FormattedMessage id="campaign.editor.highImpactTargeting.title" defaultMessage="High Impact Package" />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.highImpactTargeting.description"
                    defaultMessage="Package of high quality, non-disruptive placements. This offering comes at a premium rate. Visible to internal users only."
                />
            }
            placeholder={
                <FormattedMessage id="campaign.editor.highImpactTargeting.placeholder" defaultMessage="None" />
            }
            helpText={<HighImpactTargetingTooltip />}
            options={options}
            selectedValueObject={selectedValue}
            onChange={changeHandler}
            validations={dropdownValidations}
        />
    );
};
