import React from 'react';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { BillingBreadcrumbs } from '../BillingBreadcrumbs';
import { BillingManagementContent } from '../BillingManagementContent';
import { EditDrawer } from '../EditDrawer/EditDrawer';
import styles from './billingManagement.module.scss';

export const BillingManagement = ({ onCancel }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'navigation.caption.billing-management',
    });

    return (
        <div className={styles['container']}>
            <BillingBreadcrumbs title={title} />
            <BillingManagementContent />
            <EditDrawer onCancel={onCancel} />
        </div>
    );
};
