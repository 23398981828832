import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Collapsible } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { Id } from '../../../campaigns/modules/common-campaign-form';
import HeaderLoading from '../../../campaigns/modules/common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import { NameEdit } from '../../../campaigns/modules/common-campaign-form/components/Name/NameEdit/NameEdit';
import styles from './audienceEditorHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const AudienceEditorHeader = ({ status, headerClassName, collapsed, showId = false }) => {
    const { mode } = useFormDataContext();
    const { value: id = EMPTY_FIELD_VALUE } = useFormFieldValue({ field: 'id' });
    return status === COMPONENT_STATUS.LOADING || status === COMPONENT_STATUS.ERROR ? (
        <HeaderLoading />
    ) : (
        <div className={classNameBuilder('section-container', headerClassName)}>
            <NameEdit />
            {mode === FORM_MODES.EDIT && showId && (
                <Collapsible collapsed={collapsed}>
                    <div className={classNameBuilder('audience-status-container')}>
                        <Id id={id} className={styles['audience-id']} />
                    </div>
                </Collapsible>
            )}
        </div>
    );
};

AudienceEditorHeader.propTypes = {
    /** Status of the Component */
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
    collapsed: PropTypes.bool,
};

AudienceEditorHeader.defaultProps = {
    collapsed: false,
};

export default AudienceEditorHeader;
