import { useMemo } from 'react';
import moment from 'moment-timezone';

const useCurrentDate = ({ timeZoneName }) => {
    useMemo(() => {
        moment.tz.setDefault(timeZoneName);
    }, [timeZoneName]);

    const curDay = moment().date();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const curDate = useMemo(() => moment(), [curDay]);

    return curDate;
};

export default useCurrentDate;
