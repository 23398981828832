'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _StyledButton = require('../StyledButton/StyledButton');

var _StyledButton2 = _interopRequireDefault(_StyledButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var LoadingButton = function LoadingButton(_ref) {
    var iconBefore = _ref.iconBefore,
        iconAfter = _ref.iconAfter,
        isLoadingBefore = _ref.isLoadingBefore,
        isLoadingAfter = _ref.isLoadingAfter,
        loadingIcon = _ref.loadingIcon,
        disabled = _ref.disabled,
        rest = _objectWithoutProperties(_ref, ['iconBefore', 'iconAfter', 'isLoadingBefore', 'isLoadingAfter', 'loadingIcon', 'disabled']);

    var getIcon = function getIcon(loading, icon) {
        return loading ? loadingIcon : icon;
    };
    var before = getIcon(isLoadingBefore, iconBefore);
    var after = getIcon(isLoadingAfter, iconAfter);
    var isDisabled = Boolean(disabled || isLoadingBefore || isLoadingAfter);
    return _react2.default.createElement(_StyledButton2.default, Object.assign({ disabled: isDisabled, iconBefore: before, iconAfter: after }, rest));
};

exports.default = LoadingButton;


LoadingButton.propTypes = Object.assign({}, _StyledButton2.default.propTypes, {
    /** Icon class before children props */
    iconBefore: _propTypes2.default.node,
    /** Icon class after children props */
    iconAfter: _propTypes2.default.node,
    /** Loading flag for displaying loading icon before children */
    isLoadingBefore: _propTypes2.default.bool,
    /** Loading flag for displaying loading icon after children */
    isLoadingAfter: _propTypes2.default.bool,
    /** Icon to display when button is loading */
    loadingIcon: _propTypes2.default.node,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool
});