import React, { useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { SegmentsTreeWithBundling } from '../SegmentsTreeWithBundling/SegmentsTreeWithBundling';
import styles from './segmentsDropdownWithBundling.module.scss';

const getMenuStyle = menuStyles => ({
    menu: provided => ({
        ...provided,
        right: `-${styles.segmentDropdownWidth}`,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        ...menuStyles,
    }),
});

export const SegmentsDropdownWithBundling = ({
    search,
    onSearchChange,
    menuStyles,
    menuListRenderer = SegmentsTreeWithBundling,
}) => {
    const dropdownStyle = useMemo(() => getMenuStyle(menuStyles), [menuStyles]);
    return (
        <BareDropdown
            id="segments-dropdown"
            onChange={noop}
            placeholder={
                <FormattedMessage
                    id="campaign.editor.targeting.custom.audiences.select.placeholder"
                    defaultMessage="Click to select audience"
                />
            }
            searchable
            inputValue={search}
            onInputChange={onSearchChange}
            arrowRenderer={() => null}
            menuListRenderer={menuListRenderer}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            skipRightBorderRadius
            styles={dropdownStyle}
            menuShouldScrollIntoView={false}
        />
    );
};

SegmentsDropdownWithBundling.propTypes = {
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    menuStyles: PropTypes.object,
};
