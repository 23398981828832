import React from 'react';
import { FormBreadcrumbs } from 'modules/campaigns/modules/common-campaign-form';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useScheduledReportsBreadcrumbs } from '../../hooks/useScheduledReportsBreadcrumbs';
import styles from './ScheduledReportsFormBreadcrumbs.module.scss';

export const ScheduledReportsFormBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'navigation.caption.scheduledReports.title',
        defaultMessage: 'Scheduled Reports (Beta)',
    });
    const { steps } = useScheduledReportsBreadcrumbs({
        title,
    });

    return <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />;
};
