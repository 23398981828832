import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { useAvailableItems, useDropdownOptions } from 'hooks';
import {
    SUPPLY_TARGETING_TYPES,
    supplyTargetingOptions,
} from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/const';
import { useAPISupplyTargetingRestrictions } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useAPISupplyTargetingRestrictions';
import { useSupplyTargetingFormField } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useSupplyTargetingFormField';
import { useValidateSupplyTargetingFormField } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useValidateSupplyTargetingFormField';
import { useThirdPartyBrandSafetyTargetingType } from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyBrandSafety/useThirdPartyBrandSafetyTargetingType';
import { useThirdPartyBrandSafetyTargetingValues } from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyBrandSafety/useThirdPartyBrandSafetyTargetingValues';
import BRAND_SAFETY_TYPES from 'modules/campaigns/modules/common-campaign-form/config/BrandSafeTypes';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import style from './SupplyTargetingSelect.module.scss';

const RadioGroupWithIndication = withIndication(RadioGroup);

export function SupplyTargetingSelects({ onHover }) {
    const {
        value: supplyTargeting,
        onChange: setSupplyTargeting,
        indicationData,
    } = useValidateSupplyTargetingFormField();

    const { value: brandSafetyType, onChange: setBrandSafetyType } = useThirdPartyBrandSafetyTargetingType();
    const { value: brandSafetyValues, onChange: setBrandSafetyValues } = useThirdPartyBrandSafetyTargetingValues();

    const onchangeHandler = useCallback(
        value => {
            if (
                supplyTargeting === SUPPLY_TARGETING_TYPES.APPLE_NEWS &&
                brandSafetyType === BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS
            ) {
                setBrandSafetyType(BRAND_SAFETY_TYPES.DV);
                setBrandSafetyValues([]);
            }
            if (value === SUPPLY_TARGETING_TYPES.APPLE_NEWS && brandSafetyValues.length === 0) {
                setBrandSafetyType(BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS);
            }

            setSupplyTargeting(value);
        },
        [
            supplyTargeting,
            brandSafetyType,
            brandSafetyValues.length,
            setSupplyTargeting,
            setBrandSafetyType,
            setBrandSafetyValues,
        ]
    );

    const { supplyTargetingResource } = useAPISupplyTargetingRestrictions();
    const { value: campaignSupplyTargeting } = useSupplyTargetingFormField();
    const availableOptions = useAvailableItems(supplyTargetingOptions, {
        supplyTargetingResource,
        campaignSupplyTargeting,
    });

    const supplyOptions = useDropdownOptions(availableOptions, 'campaign.editor.targeting.supply');

    return (
        <RadioGroupWithIndication
            className={style['group']}
            onChange={onchangeHandler}
            selectedValue={supplyTargeting}
            {...indicationData}
        >
            {supplyOptions.map(({ value, label, icon, disabled }) => {
                return (
                    <RadioIcon
                        title={
                            <FormattedMessage id={`campaign.editor.targeting.supply.${value}`} defaultMessage={label} />
                        }
                        iconComponent={icon}
                        value={value}
                        disabled={disabled}
                        labelClassName={style['radio']}
                        titleClassName={style['title']}
                        iconContainerClassName={style['icon-container']}
                        onMouseEnter={() => onHover && onHover(value)}
                        onMouseLeave={() => onHover && onHover(null)}
                        key={value}
                    />
                );
            })}
        </RadioGroupWithIndication>
    );
}

SupplyTargetingSelects.propTypes = {
    onHover: PropTypes.func.isRequired,
};
