import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { flatMap, keyBy } from 'lodash';
import { useModuleName, useAvailableItems } from 'hooks';
import { REAL_TIME } from 'modules/campaigns/config';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccountFullConfigData } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountFullConfigData';
import PermissionsContext from 'modules/taboola-common-frontend-modules/authentication/PermissionsContext';
import { dimensionSelector } from 'selectors';
import reportsOrder from '../config/reports/reportsOrder';
import { useDateRange } from './useDateRange';

const mapGroupedReportWithSingleItemToRegularItem = report => {
    if (report.options?.length !== 1) {
        return report;
    }

    const singleReportInGroup = report.options[0];
    if (singleReportInGroup.options) {
        return mapGroupedReportWithSingleItemToRegularItem(singleReportInGroup);
    }

    return singleReportInGroup;
};

const groupReports = (reportsById, allReports) => {
    const groupedReports = allReports
        .map(report => ({
            ...report,
            options: report.options ? groupReports(reportsById, report.options) : undefined,
        }))
        .filter(report => (report.options?.length ?? 0) || reportsById[report.id]);

    return groupedReports.map(mapGroupedReportWithSingleItemToRegularItem);
};

const useAvailableReports = () => {
    const selectedAccount = useAccount();
    const permissions = useContext(PermissionsContext);
    const configs = useAccountFullConfigData();
    const dimension = useSelector(dimensionSelector);
    const module = useModuleName();
    const allReports = useMemo(() => flatMap(reportsOrder, report => report.options || report), []);
    const [{ datePreset }] = useDateRange();

    const isRealtime = datePreset === REAL_TIME;
    const allAvailableReports = useAvailableItems(allReports, {
        selectedAccount,
        dimension,
        module,
        permissions,
        configs,
        isRealtime,
    });
    const availableReportsMap = useMemo(() => keyBy(allAvailableReports, 'id'), [allAvailableReports]);
    const isReportVisible = useCallback(reportId => availableReportsMap[reportId], [availableReportsMap]);
    const availableReports = useMemo(() => groupReports(availableReportsMap, reportsOrder), [availableReportsMap]);

    return { availableReports, isReportVisible };
};

export default useAvailableReports;
