import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon, ChevronLeftOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from '../../../../../../services/campaignDimension';
import { isPmpAccount } from '../../../../../account-management/accountType';
import { useCustomContextualPermitted } from '../../../../../audiences/hooks/useCustomContextualPermitted';
import { FormButtons } from '../../../../components';
import { ENTITY_MESSAGE_PREFIX, REPORT_TYPE } from '../../../../config';
import useCampaignBreadcrumbs from '../../../../hooks/useCampaignBreadcrumbs';
import {
    AudienceTargetingSection,
    AudienceTargetingSectionLegacy,
    ContextualTargetingSection,
    FormBreadcrumbs,
    Representatives,
    useCampaignCreatorState,
} from '../../../common-campaign-form';
import { pmpDealVideoCampaignInitialValues } from '../../../common-campaign-form/config';
import { DealId, InventorySection, ScheduleSection, TargetingSection } from '../../components';
import BudgetSectionVideoPmpDeal from '../../components/PMPBudgetSection/BudgetSectionVideoPmpDeal';
import { SetupSection } from '../../components/SetupSection/SetupSection';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';
import styles from '../../../sponsored-campaign-creator/sponsoredCampaignCreator.module.scss';

const dimension = CAMPAIGN_DIMENSION.VIDEO;

const VideoPMPDealCreator = ({ onCancel }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'video,.pmpDeal.creator.new.pmp.deal', defaultMessage: 'New PMP Video Deal' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN,
        title,
    });

    const isCustomContextualPermitted = useCustomContextualPermitted();
    const audiencesTargetingSectionWithWizard = isCustomContextualPermitted ? (
        <>
            <WizardStep
                label={
                    <FormattedMessage id="campaign.editor.contextual.targeting" defaultMessage="Contextual Targeting" />
                }
                id="contextual-targeting"
            >
                <ContextualTargetingSection />
            </WizardStep>
            <WizardStep
                label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
                id="audiences-targeting"
            >
                <AudienceTargetingSection />
            </WizardStep>
        </>
    ) : (
        <WizardStep
            label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
            id="audiences-targeting"
        >
            <AudienceTargetingSectionLegacy />
        </WizardStep>
    );
    const { step, prevStep, nextStep, handleSetStep, submit, wizardTemplateColumns } = useCampaignCreatorState({
        dimension,
    });

    const { formAccount } = useFormDataContext();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="campaign.creator.campaign.settings.step.label"
                            defaultMessage="Campaign Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                            id="setup"
                        >
                            <SetupSection
                                isAccountSelectionEnabled
                                accountFilter={isPmpAccount}
                                messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL}
                                noAccountsMessageId="pmpDeal.editor.setup.no.pmp.deal.accounts"
                            >
                                <DealId />
                                <Representatives />
                            </SetupSection>
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.schedule" defaultMessage="Schedule" />}
                            id="schedule"
                        >
                            <ScheduleSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage id="campaign.editor.targeting" defaultMessage="Campaign Targeting" />
                            }
                            id="targeting"
                        >
                            <TargetingSection />
                        </WizardStep>
                        {audiencesTargetingSectionWithWizard}
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="video.campaign.editor.inventory"
                                    defaultMessage="Campaign Inventory"
                                />
                            }
                            id="inventory"
                        >
                            <InventorySection account={formAccount} />
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                                submitIcon={<ChevronRightOutlinedIcon />}
                                submitDataMetricsComponent="NextButton"
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                submitButtonType="submit"
                                className={commonStyles['form-buttons']}
                            />
                        </WizardStep>
                    </div>
                </WizardStep>
                <WizardStep
                    label={
                        <FormattedMessage id="campaign.creator.budget.bidding" defaultMessage="Budget and Bidding" />
                    }
                    id="budgeting"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="campaign.creator.budget.bidding"
                                    defaultMessage="Budget and Bidding"
                                />
                            }
                            id="budget"
                        >
                            <BudgetSectionVideoPmpDeal account={formAccount}></BudgetSectionVideoPmpDeal>
                        </WizardStep>
                        <FormButtons
                            onSubmit={submit}
                            onCancel={onCancel}
                            onBack={prevStep}
                            submitLabel={
                                <FormattedMessage
                                    id="campaign.creator.submit.button"
                                    defaultMessage="CREATE CAMPAIGN"
                                />
                            }
                            backLabel={<FormattedMessage id="app.actionButtons.back" />}
                            backIcon={<ChevronLeftOutlinedIcon />}
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            submitButtonType="submit"
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

VideoPMPDealCreator.propTypes = { onCancel: PropTypes.func };

const VideoPMPDealCreatorWithFormDataProvider = withFormDataProvider(VideoPMPDealCreator, {
    defaultValuesConfig: pmpDealVideoCampaignInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { VideoPMPDealCreatorWithFormDataProvider as VideoPMPDealCreator };
