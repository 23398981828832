import React from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownOption } from '../../../../../../components/Dropdown';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData/hooks';
import TargetingDropdownBox from '../../../common-campaign-form/components/TargetingDropdownBox/TargetingDropdownBox';
import useSelectedItems from '../../../common-campaign-form/hooks/useSelectedItems';
import AppsListItem from './AppsListItem';
import { useAppsApi } from './hooks';
import styles from './appsTargeting.module.scss';

const ITEM_HEIGHT = 40;

export const AppsTargeting = () => {
    const { value: selectedValues, onChange: onChangeSelectedValues } = useFormFieldValue({
        field: 'campaignTargeting.appTargeting.values',
    });

    const { value: targetingType, onChange: onChangeTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.appTargeting.type',
    });

    const { apps, isResourcesLoading } = useAppsApi();

    const { handleAddItem, handleRemoveItem, availableItems, selectedItems, handleRemoveAllItems } = useSelectedItems({
        items: apps,
        isResourcesLoading,
        selectedValues,
        onChangeSelectedValues,
        targetingType,
    });

    if (isResourcesLoading) {
        return null;
    }

    return (
        <>
            <TargetingDropdownBox
                itemType={targetingType}
                onSelectItemType={({ value }) => {
                    onChangeTargetingType(value);
                }}
                selectedItems={selectedItems}
                itemPlaceholder={
                    <FormattedMessage id="video.campaign.apps.targeting.placeholder" defaultMessage="Select Apps..." />
                }
                onAddItem={handleAddItem}
                allItems={availableItems}
                itemOptionRenderer={DropdownOption}
            />
            <CollapsibleList
                data-testid="apps-list"
                items={selectedItems}
                ItemComponent={AppsListItem}
                itemHeight={ITEM_HEIGHT}
                deleteItem={handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage id="video.campaign.apps.targeting.header" defaultMessage="Selected Apps" />
                }
                clearItems={handleRemoveAllItems}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                containerClassName={styles['list']}
            />
        </>
    );
};
