import { useMemo } from 'react';
import { useFormDataContext, useFormFieldValue } from '../../taboola-common-frontend-modules/formData';
import { useInitialCampaign } from '../modules/creative-creator/components/CampaignsField/hooks/useInitialCampaign';

export const useCampaignsFormFieldValue = () => {
    const {
        formAccount: { accountName: formAccountName },
    } = useFormDataContext();
    const initialCampaign = useInitialCampaign();
    // TODO DEV-130273
    // This is done due to inconsistency in single campaign response and list response
    const cutCampaign = useMemo(
        () => ({
            ...initialCampaign,
            advertiserId: null,
            advertiserName: null,
            advertiserDescription: null,
            safetyLevel: null,
            createTime: null,
        }),
        [initialCampaign]
    );
    const formProps = useFormFieldValue(
        { field: 'campaigns', isAbsolute: true },
        { initialCampaign: cutCampaign, formAccountName }
    );

    return formProps;
};
