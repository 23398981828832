"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgRefresh(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M19.8813 13.3733L19.8808 13.376C19.7641 14.0444 19.5637 14.6904 19.2877 15.3C18.811 16.3526 18.1086 17.2969 17.2211 18.0614C15.8187 19.2693 14.0409 19.9536 12.1906 19.9977C10.3403 20.0418 8.53194 19.443 7.07365 18.3033C5.61536 17.1635 4.59737 15.5534 4.19314 13.7472C3.78891 11.9411 4.02345 10.0506 4.85679 8.398C5.69013 6.74538 7.07072 5.43283 8.76331 4.68401C10.4559 3.93518 12.3558 3.7964 14.1392 4.29132C15.268 4.60456 16.3059 5.15938 17.1857 5.90834C17.6957 6.34247 18.1526 6.84182 18.5432 7.39716L18.544 7.39821L16.9063 8.54984L16.9056 8.54879C16.1074 7.41423 14.9406 6.59141 13.6039 6.22046C12.2667 5.8494 10.8423 5.95345 9.57331 6.51488C8.3043 7.07631 7.26922 8.06038 6.64442 9.29942C6.01963 10.5385 5.84379 11.9558 6.14686 13.31C6.44993 14.6641 7.21316 15.8713 8.3065 16.7258C9.39985 17.5803 10.7557 18.0293 12.1429 17.9962C13.5302 17.9632 14.8631 17.4501 15.9145 16.5445C16.9659 15.6389 17.6707 14.3967 17.9089 13.0296L19.8813 13.3733Z",
            fill: "currentColor"
        }),
        React.createElement("path", { d: "M20 4V10H14L20 4Z", fill: "currentColor" })
    );
}
exports.default = SvgRefresh;