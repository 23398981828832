import React from 'react';

export const MediaFileUploadIcon = (props = {}) => (
    <svg width="68" height="48" viewBox="0 0 68 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8001 7.2L8.4001 0H13L15.4 7.2H20.2L17.8 0H22L24.4 7.2H29.2L26.8 0H31L33.4 7.2H38.2L35.8 0H40L42.4 7.2H47.2L44.8 0H49L51.4 7.2H56.2L53.8 0H58L60.4 7.2H65.2L62.8 0H66C67.1046 0 68 0.895431 68 2V44C68 46.2091 66.2091 48 64 48H4C1.79086 48 0 46.2091 0 44V6C0 3.4837 1.549 1.32918 3.74547 0.437972C4.00819 1.52347 6.0001 7.2 6.0001 7.2H10.8001ZM42.3999 23.9215C43.9999 24.8453 43.9999 27.1547 42.3999 28.0785L31.5999 34.3138C29.9999 35.2376 27.9999 34.0829 27.9999 32.2354V19.7646C27.9999 17.9171 29.9999 16.7624 31.5999 17.6862L42.3999 23.9215Z"
            fill="currentColor"
        />
    </svg>
);
