/**
 * Created by dotan.l on 15/04/2019.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { MissingFeature } from 'components/MissingFeature';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CampaignMissingFeatureItem from './CampaignMissingFeatureItem';
import styles from './campaignMissingFeature.module.scss';

export const CampaignMissingFeature = ({ featuresData, className }) =>
    featuresData.length ? (
        <MissingFeature
            className={className}
            title={<FormattedMessage id="campaign.editor.missing.feature.title" />}
            description={<FormattedMessage id="campaign.editor.missing.feature.description" />}
        >
            <ul className={styles['list']}>
                {featuresData.map(item => (
                    <CampaignMissingFeatureItem key={item.key} {...item} />
                ))}
            </ul>
        </MissingFeature>
    ) : null;

CampaignMissingFeature.propTypes = {
    featuresData: PropTypes.arrayOf(
        PropTypes.shape({
            messageId: PropTypes.node.isRequired,
            value: PropTypes.node,
            key: PropTypes.string.isRequired,
            tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        })
    ),
    className: PropTypes.string,
};

CampaignMissingFeature.defaultProps = {
    featuresData: [],
};
