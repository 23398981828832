import React, { useMemo } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { isNetworkAccountType } from 'modules/account-management';
import { NoticeSection } from 'modules/campaigns/components/NoticeSection/NoticeSection';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ExportSelection } from './ExportSelection';
import styles from './exportSection.module.scss';

export const ExportSection = ({ entityList, downloadTemplate, isDisabled, isLoadingTemplate }) => {
    const { type } = useAccount();
    const isNetworkAccount = useMemo(() => isNetworkAccountType(type), [type]);
    const shouldDisplayDescription = !isNetworkAccount;

    return (
        <FormField
            label={
                <FormattedMessage
                    id="template.export.section.description"
                    defaultMessage="Option 2: Export existing data to excel file for bulk updates of the following:"
                />
            }
            description={
                shouldDisplayDescription ? (
                    <FormattedMessage
                        id="template.export.section.extra.description"
                        defaultMessage="Exporting existing data is intended for copying data to a template. Only submit the export file if you intend to perform a FULL UPDATE to your account. To change specific fields, fill in the campaign or ad ID along with the desired field for updating."
                    />
                ) : null
            }
        >
            {isNetworkAccount ? (
                <NoticeSection
                    containerClassName={styles['notice']}
                    title={
                        <FormattedMessage
                            id="bulk.upload.export.not.available.title"
                            defaultMessage="Exporting Is Unavailable For Network Accounts"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="bulk.upload.export.not.available.description"
                            defaultMessage="Exporting is unavailable for network accounts. To export account specific data, select the account from the dropdown above."
                        />
                    }
                />
            ) : (
                <ExportSelection
                    entityList={entityList}
                    downloadTemplate={downloadTemplate}
                    isDisabled={isDisabled}
                    isLoading={isLoadingTemplate}
                />
            )}
        </FormField>
    );
};
