"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDismiss(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M0.716627 3.62203C-0.0856783 2.81972-0.0856783 1.51893 0.716627 0.716627C1.51893-0.0856783 2.81972-0.0856783 3.62203 0.716627L8 5.09461L12.4613 0.633258C13.2637-0.169046 14.5644-0.169046 15.3667 0.633258C16.169 1.43556 16.169 2.73635 15.3667 3.53867L10.9054 8L15.2648 12.3595C16.0672 13.1618 16.0672 14.4626 15.2648 15.2648C14.4626 16.0672 13.1618 16.0672 12.3595 15.2648L8 10.9054L3.53867 15.3667C2.73635 16.169 1.43556 16.169 0.633258 15.3667C-0.169046 14.5644-0.169046 13.2637 0.633258 12.4613L5.09461 8L0.716627 3.62203Z",
            fill: "currentColor",
            opacity: 1
        })
    );
}
exports.default = SvgDismiss;