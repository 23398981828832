import { useState, useCallback } from 'react';
import { isObject } from 'lodash';
import { generateSubPathsMap } from '../utils';

const defaultGetAllCollapsedPathMap = data =>
    data.reduce((result, leaf) => {
        const subPathsMap = generateSubPathsMap(leaf);
        return { ...result, ...subPathsMap };
    }, {});

const useCollapseState = (getAllCollapsedPathMap = defaultGetAllCollapsedPathMap) => {
    const [collapsedPathMap, setCollapsedPathMap] = useState({});
    const expandAll = useCallback(() => setCollapsedPathMap({}), []);
    const collapseAll = useCallback(
        data => {
            const allCollapsedPath = getAllCollapsedPathMap(data);

            setCollapsedPathMap(allCollapsedPath);
        },
        [setCollapsedPathMap, getAllCollapsedPathMap]
    );

    const handleCollapseChange = useCallback(
        ({ collapsedMap }) => {
            if (isObject(collapsedMap)) {
                setCollapsedPathMap(collapsedMap);
            }
        },
        [setCollapsedPathMap]
    );

    return {
        handleCollapseChange,
        collapsedPathMap,
        expandAll,
        collapseAll,
    };
};

export default useCollapseState;
