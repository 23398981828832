import React from 'react';
import PropTypes from 'prop-types';
import { nonProgrammaticActiveAccount } from 'modules/account-management/accountType';
import { ConversionConfiguration } from 'modules/campaigns/components/ConversionConfiguration/ConversionConfiguration';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { ENTITY, FORM_MODES } from '../../../../config';
import { AudienceTargetingSectionCampaignEditor } from '../AudienceTargetingSection/AudienceTargetingSectionCampaignEditor';
import { useShouldRenderBidStrategyV2 } from '../BidStrategyV2/hooks/useShouldRenderBidStrategyV2';
import { BiddingConversionGoalSection } from '../BiddingConversionGoalSection';
import Branding from '../Branding/Branding';
import BudgetSectionSponsored from '../BudgetSectionSponsored/BudgetSectionSponsored';
import { CampaignGroupField } from '../CampaignGroupField/CampaignGroupField';
import CampaignMarketingObjective from '../CampaignMarketingObjective/CampaignMarketingObjective';
import { CampaignPerformanceRules } from '../CampaignPerformanceRules/CampaignPerformanceRules';
import CommentsSection from '../CommentsSection/CommentsSection';
import { ConversionOptimization } from '../ConversionOptimization/ConversionOptimization';
import DuplicateCampaignSettingsSection from '../DuplicateCampaignSettingsSection/DuplicateCampaignSettingsSection';
import { Funnel } from '../Funnel/Funnel';
import ProfileSection from '../ProfileSection/ProfileSection';
import { ReachEstimatorWidget } from '../ReachEstimator';
import { useReachEstimatorEnabled } from '../ReachEstimator/hooks/useReachEstimatorEnabled';
import ScheduleSection from '../ScheduleSection/ScheduleSection';
import SetupSection from '../SetupSection/SetupSection';
import TargetingSection from '../TargetingSection/TargetingSection';
import TrackingCode from '../TrackingCode/TrackingCode';
import TrackingSection from '../TrackingSection/TrackingSection';

const CampaignEditorContent = ({ shouldRenderAccountDropdown }) => {
    const { mode } = useFormDataContext();
    const isReachEstimatorEnabled = useReachEstimatorEnabled();
    const shouldRenderBidStrategyV2 = useShouldRenderBidStrategyV2();
    return (
        <>
            <div>
                {mode === FORM_MODES.DUPLICATE && <DuplicateCampaignSettingsSection />}
                <SetupSection
                    isAccountSelectionEnabled={shouldRenderAccountDropdown}
                    accountFilter={nonProgrammaticActiveAccount}
                >
                    <CampaignGroupField disabled={mode === FORM_MODES.EDIT} />
                    <Branding />
                    <CampaignMarketingObjective />
                    {shouldRenderBidStrategyV2 && (
                        <>
                            <ConversionOptimization />
                            <ConversionConfiguration />
                            <Funnel />
                        </>
                    )}
                </SetupSection>
                {!shouldRenderBidStrategyV2 && <BiddingConversionGoalSection />}
                <ScheduleSection />
                <TargetingSection isIncludeHighImpactTargeting={true} />
                <AudienceTargetingSectionCampaignEditor />
                <BudgetSectionSponsored />
                <CampaignPerformanceRules />
                <TrackingSection>
                    <TrackingCode />
                </TrackingSection>
                <ProfileSection />
                <CommentsSection />
            </div>
            {isReachEstimatorEnabled && <ReachEstimatorWidget entityType={ENTITY.CAMPAIGN} />}
        </>
    );
};

CampaignEditorContent.propTypes = {
    currency: PropTypes.string,
    /* only for duplicate mode */
    account: PropTypes.object,
    onAccountChange: PropTypes.func,
    shouldRenderAccountDropdown: PropTypes.bool,
};

export default CampaignEditorContent;
