'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _withStylesheet;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _hoc = require('../../hoc');

var _Button = require('../Button/Button');

var _Button2 = _interopRequireDefault(_Button);

var _StyledButtonType = require('./StyledButtonType');

var _StyledButtonType2 = _interopRequireDefault(_StyledButtonType);

var _StyledButtonSize = require('./StyledButtonSize');

var _StyledButtonSize2 = _interopRequireDefault(_StyledButtonSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'styledButton__taboola-svg-icon___xfiqF',
    'tbl-styled-btn': 'styledButton__tbl-styled-btn___21PTC',
    'tbl-styled-icon-before': 'styledButton__tbl-styled-icon-before___3Ylr9',
    'tbl-styled-icon-after': 'styledButton__tbl-styled-icon-after___1VrHi',
    'strong': 'styledButton__strong___O6rKa',
    'reject': 'styledButton__reject___2_Htl',
    'ghost': 'styledButton__ghost___3MmX6',
    'primary': 'styledButton__primary___3pZWw',
    'large': 'styledButton__large___2EoR9',
    'medium': 'styledButton__medium___1V72b',
    'small': 'styledButton__small___3QvsI',
    'borderless-icon': 'styledButton__borderless-icon___1NG_X',
    'link': 'styledButton__link___vgeU4'
};
var _Button$classNames = _Button2.default.classNames,
    main = _Button$classNames.main,
    iconAfter = _Button$classNames.iconAfter,
    iconBefore = _Button$classNames.iconBefore;

var StyledButton = (0, _hoc.withStylesheet)(_Button2.default, styles, (_withStylesheet = {}, _defineProperty(_withStylesheet, main, function (_ref) {
    var type = _ref.type,
        size = _ref.size;
    return ['tbl-styled-btn', size, type];
}), _defineProperty(_withStylesheet, iconBefore, 'tbl-styled-icon-before'), _defineProperty(_withStylesheet, iconAfter, 'tbl-styled-icon-after'), _withStylesheet), 'StyledButton');

StyledButton.propTypes = Object.assign({}, _Button2.default.propTypes, {
    /** Determines button type */
    type: _propTypes2.default.oneOf(Object.values(_StyledButtonType2.default)),
    /** Determines button size */
    size: _propTypes2.default.oneOf(Object.values(_StyledButtonSize2.default))
});

StyledButton.defaultProps = Object.assign({}, _Button2.default.defaultProps, {
    buttonType: 'button'
});

exports.default = StyledButton;