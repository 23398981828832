import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreativePreviewParameters } from '../../hooks/useCreativePreviewParameters';
import { useOnSitePreviewModalContentState } from '../../hooks/useOnSitePreviewModalContentState';
import styles from './advancedPreview.module.scss';

export const AdvancedPreview = () => {
    const getParameters = useCreativePreviewParameters();
    const { openAdvancedPreview } = useOnSitePreviewModalContentState(getParameters);

    return (
        <Button className={styles['advanced-preview-button']} onClick={openAdvancedPreview}>
            <FormattedMessage id="app.modal.onSitePreview.advancedPreview" defaultMessage="Preview on Yahoo" />
        </Button>
    );
};
