import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, TooltipV2 } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './audienceCampaignsUsingCellRenderer.module.scss';

const AudienceCampaignsUsingCellRenderer = ({ value, data }) => {
    const { campaignsUsingNames } = data;
    return (
        <div>
            <div className={styles['container']}>
                <EllipsisCellRenderer value={value} lines={1} />
                {campaignsUsingNames && (
                    <TooltipV2 position={TOOLTIP_POSITION.RIGHT} arrow>
                        <FormattedMessage id={campaignsUsingNames} defaultMessage={campaignsUsingNames} />
                    </TooltipV2>
                )}
            </div>
        </div>
    );
};

AudienceCampaignsUsingCellRenderer.propTypes = {
    data: PropTypes.object,
    ...EllipsisCellRenderer.propTypes,
};

export default AudienceCampaignsUsingCellRenderer;
