import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import graph from './graph';
import { defaultColumns } from './reportPresets';

export const segmentCategoryReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.SEGMENT_CATEGORY],
    endpoint: 'audience-insights-report-by-category',
    graph,
    rowIdField: FIELDS.CATEGORY.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    transformTotalOverride: () => null,
    footerColumnDef: [],
    isEmptyFooter: true,
    initialFilters: [
        {
            id: FILTER_TYPE.COUNTRY,
            type: FILTER_TYPE.COUNTRY,
            values: [{ value: 'US', label: 'United States', messageId: 'app.country.code.US' }],
        },
        {
            id: FILTER_TYPE.BASELINE,
            type: FILTER_TYPE.BASELINE,
            values: [{ value: '14', label: 'All Users', messageId: 'app.audienceInsights.filters.baseline.14' }],
        },
    ],
    filters: [
        { id: FILTER_TYPE.COUNTRY, required: true },
        { id: FILTER_TYPE.BASELINE, required: true },
    ],
    searchPlaceholderMsgId: 'app.reports.byCategory.search.placeholder',
    searchField: FIELDS.CATEGORY.field,
    defaultGridSort: [
        {
            colId: generateColumnId(reportsBaseConfig[REPORT_TYPE.CATEGORY].id, FIELDS.COMPARE.field),
            sort: SORTING_ORDER_TYPES.DESCENDANT,
        },
    ],
};

export default segmentCategoryReportConfig;
