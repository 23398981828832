import { PrimaryConversion } from 'modules/tracking/components/FunnelConversions/PrimaryConversion';
import styles from './funnelRules.module.scss';

export const RuleItem = ({ item, index, totalItems }) => {
    const { label } = item;
    return (
        <div className={styles['rule-container']}>
            <span className={styles['label']}>{label}</span>
            {index === totalItems - 1 && <PrimaryConversion />}
        </div>
    );
};
