import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

const CampaignsGroupTimeFrameTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaigns.group.time.frame.help.title"
            defaultMessage="Specify a start and end date (If you leave the End Date blank, the campaign group will run until its budget is depleted)."
        />
    </TooltipSection>
);

export default CampaignsGroupTimeFrameTooltip;
