import React, { useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import SegmentsTree from '../SegmentsTree/SegmentsTree';
import styles from './segmentsDropdown.module.scss';

const getMenuStyle = width => ({
    menu: provided => ({
        ...provided,
        width,
        right: `-${styles.segmentDropdownWidth}`,
    }),
});

const SegmentsDropdown = ({ search, onSearchChange, width }) => {
    const dropdownStyle = useMemo(() => getMenuStyle(width), [width]);
    return (
        <BareDropdown
            id="segments-dropdown"
            onChange={noop}
            placeholder={
                <FormattedMessage
                    id="campaign.editor.targeting.custom.audiences.select.placeholder"
                    defaultMessage="Click to select audience"
                />
            }
            searchable
            inputValue={search}
            onInputChange={text => onSearchChange(text)}
            arrowRenderer={() => null}
            menuListRenderer={SegmentsTree}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            skipRightBorderRadius
            styles={dropdownStyle}
        />
    );
};

SegmentsDropdown.propTypes = {
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    width: PropTypes.number,
};

export default SegmentsDropdown;
