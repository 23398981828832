import { isEmpty, keyBy, merge } from 'lodash';
import { createReducer } from 'modules/taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { COMPONENT_STATUS } from 'services/constants';
import { addPersistHandler } from 'services/persister';
import {
    REQUEST_FETCH_BASELINES,
    FETCH_BASELINES_SUCCESS,
    FETCH_BASELINES_ERROR,
    REQUEST_FETCH_SEGMENTS,
    FETCH_SEGMENTS_SUCCESS,
    FETCH_SEGMENTS_ERROR,
    TOGGLE_SEGMENT_PICKER_PINNED,
    SET_SEGMENT_PICKER_SORTING,
} from '../actions';
import { defaultSortField, defaultSortType } from '../config';

const REDUCER_NAME = 'audienceInsightsReducer';
const propsToPersist = ['isSegmentPickerPinned'];

const initialState = {
    moduleStatus: COMPONENT_STATUS.INITIAL,
    segments: {},
    baselines: [],
    isSegmentPickerPinned: true,
    segmentPickerSortingType: defaultSortType,
    segmentPickerSortingField: defaultSortField,
};

const fetchSegmentsRequestHandler = state => {
    if (!isEmpty(state.segments)) {
        return state;
    }
    return updateObject(state, { moduleStatus: COMPONENT_STATUS.LOADING });
};

const fetchSegmentsSuccessHandler = (state, action) => {
    const { results } = action.payload;
    const fetchedSegments = keyBy(results, 'uddId');
    const segments = merge({}, state.segments, fetchedSegments);
    const moduleStatus = COMPONENT_STATUS.ACTIVE;
    return updateObject(state, { segments, moduleStatus });
};

const handlers = {
    [REQUEST_FETCH_BASELINES]: state => updateObject(state, { baselines: [] }),
    [FETCH_BASELINES_SUCCESS]: (state, { payload }) => updateObject(state, { baselines: payload }),
    [FETCH_BASELINES_ERROR]: state => updateObject(state, { baselines: [] }),
    [REQUEST_FETCH_SEGMENTS]: fetchSegmentsRequestHandler,
    [FETCH_SEGMENTS_SUCCESS]: fetchSegmentsSuccessHandler,
    [FETCH_SEGMENTS_ERROR]: state => updateObject(state, { moduleStatus: COMPONENT_STATUS.ERROR }),
    [TOGGLE_SEGMENT_PICKER_PINNED]: (state, { payload }) => updateObject(state, { isSegmentPickerPinned: payload }),
    [SET_SEGMENT_PICKER_SORTING]: (state, action) => updateObject(state, action.payload),
};

const audienceInsightsReducer = createReducer(initialState, addPersistHandler(handlers, REDUCER_NAME));

export default audienceInsightsReducer;
export { initialState, REDUCER_NAME, propsToPersist };
