import { REPORT_TYPE } from 'modules/campaigns/config/reportsBaseConfig';
import { useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';

export const useIsIncludeSubmissionData = () => {
    const [selectedReport] = useSelectedReportId();
    const isIncludeSubmissionDataInAdsReport = useConfigMatch({
        [FEATURE_FLAGS.SUBMISSION_DATA_ALLOWED_IN_ADS_REPORT]: 'true',
    });

    return selectedReport === REPORT_TYPE.CREATIVE && isIncludeSubmissionDataInAdsReport;
};
