import React from 'react';
import { omit } from 'lodash';
import { PLACEMENTS } from '../../../config/placements';
import { SvgButton } from './SvgButton';

export const TwoByOne = props => (
    <SvgButton {...props} name={PLACEMENTS.TWO_BY_ONE}>
        <path
            d="M15.2031 16H10.3301V15.0332L12.6299 12.582C12.9456 12.237 13.1784 11.9359 13.3281 11.6787C13.4811 11.4215 13.5576 11.1774 13.5576 10.9463C13.5576 10.6305 13.4779 10.3831 13.3184 10.2041C13.1589 10.0218 12.931 9.93066 12.6348 9.93066C12.3158 9.93066 12.0635 10.0413 11.8779 10.2627C11.6956 10.4808 11.6045 10.7689 11.6045 11.127H10.1885C10.1885 10.694 10.291 10.2985 10.4961 9.94043C10.7044 9.58236 10.9974 9.30241 11.375 9.10059C11.7526 8.89551 12.1807 8.79297 12.6592 8.79297C13.3916 8.79297 13.9596 8.96875 14.3633 9.32031C14.7702 9.67188 14.9736 10.1683 14.9736 10.8096C14.9736 11.1611 14.8825 11.5192 14.7002 11.8838C14.5179 12.2484 14.2054 12.6732 13.7627 13.1582L12.1465 14.8623H15.2031V16ZM16.1992 15.3066C16.1992 15.082 16.2741 14.8997 16.4238 14.7598C16.5768 14.6198 16.7673 14.5498 16.9951 14.5498C17.2262 14.5498 17.4167 14.6198 17.5664 14.7598C17.7194 14.8997 17.7959 15.082 17.7959 15.3066C17.7959 15.528 17.721 15.7087 17.5713 15.8486C17.4215 15.9854 17.2295 16.0537 16.9951 16.0537C16.764 16.0537 16.5736 15.9854 16.4238 15.8486C16.2741 15.7087 16.1992 15.528 16.1992 15.3066ZM16.1992 11.3076C16.1992 11.083 16.2741 10.9007 16.4238 10.7607C16.5768 10.6208 16.7673 10.5508 16.9951 10.5508C17.2262 10.5508 17.4167 10.6208 17.5664 10.7607C17.7194 10.9007 17.7959 11.083 17.7959 11.3076C17.7959 11.529 17.721 11.7096 17.5713 11.8496C17.4215 11.9863 17.2295 12.0547 16.9951 12.0547C16.764 12.0547 16.5736 11.9863 16.4238 11.8496C16.2741 11.7096 16.1992 11.529 16.1992 11.3076ZM22.3223 16H20.9111V10.5605L19.2266 11.083V9.93555L22.1709 8.88086H22.3223V16Z"
            fill="white"
        />
    </SvgButton>
);

TwoByOne.propTypes = omit(SvgButton.propTypes, 'name');
