import React from 'react';
import { ExternalLink } from '../../../../../../components';
import { FormattedMessage, useIntl } from '../../../../../taboola-common-frontend-modules/i18n';

const UrlFieldDescription = () => {
    const { formatMessage } = useIntl();
    const href = formatMessage({ id: 'creative.creator.urls.description.link.href' });
    const Link = (
        <ExternalLink href={href}>
            <FormattedMessage id="creative.creator.urls.description.link" defaultMessage="Link" />
        </ExternalLink>
    );

    return (
        <FormattedMessage
            id="creative.creator.urls.description"
            defaultMessage="Please add third-party partner tracking (e.g AppsFlyer, adjust). In case you don't work with a third-party, enter the app store URL. {Link} to partners we accept"
            values={{ Link }}
        />
    );
};

export default UrlFieldDescription;
