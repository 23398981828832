import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { COMPONENT_STATUS } from 'services/constants';
import { splitOnNewLine } from 'services/utils/stringUtils';
import { ContentItem } from './ContentItem';
import styles from './contentList.module.scss';

const getTitledItem = title => {
    return {
        title,
        description: '',
        descriptionEnabled: false,
        cta: '',
        ctaEnabled: false,
    };
};

export const ContentList = ({ items, maxItems, ...rest }) => {
    const numItems = items.length;
    const numActiveItems = items.filter(({ status }) => status === COMPONENT_STATUS.ACTIVE).length;
    const { updateContentItem, addContentItem } = rest;

    const fillContentItemTitles = useCallback(
        (title, titleIndex) => {
            if (titleIndex < numItems) {
                updateContentItem(titleIndex, { title, status: COMPONENT_STATUS.ACTIVE });
            } else {
                const newItem = getTitledItem(title);
                addContentItem(newItem);
            }
        },
        [addContentItem, numItems, updateContentItem]
    );

    const handleTitlePaste = useCallback(
        (pasteEvent, itemIndex) => {
            const pasteTitle = pasteEvent.clipboardData.getData('Text');
            const titleArray = splitOnNewLine(pasteTitle, { removeWhitespace: true });
            if (titleArray.length <= 1) {
                return false;
            }

            pasteEvent.preventDefault();
            for (const [arrayIndex, newTitle] of titleArray.entries()) {
                const newTitleIndex = itemIndex + arrayIndex;
                if (newTitleIndex >= maxItems) {
                    break;
                }
                fillContentItemTitles(newTitle, newTitleIndex);
            }
            return true;
        },
        [fillContentItemTitles, maxItems]
    );

    const contentItems = useMemo(
        () => (
            <FormNamespaceProvider field="content">
                {items.map((item, i) => (
                    <FormNamespaceProvider field={i} itemKey={item.id} key={item.id}>
                        <ContentItem
                            {...rest}
                            numExistingItems={numActiveItems}
                            maxItems={maxItems}
                            item={item}
                            index={i}
                            autoFocus={false}
                            onTitlePaste={pasteEvent => handleTitlePaste(pasteEvent, i)}
                        />
                    </FormNamespaceProvider>
                ))}
            </FormNamespaceProvider>
        ),
        [items, rest, numActiveItems, maxItems, handleTitlePaste]
    );

    return <div className={styles['container']}>{contentItems}</div>;
};

ContentList.propTypes = {
    items: PropTypes.array,
    maxItems: PropTypes.number,

    updateContentItem: PropTypes.func,
    addContentItem: PropTypes.func,
    duplicateItem: PropTypes.func,
    deleteItem: PropTypes.func,

    shouldRenderDescription: PropTypes.bool,
    shouldRenderCta: PropTypes.bool,
};

ContentList.defaultProps = {
    items: [],
};
