import React from 'react';
import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { TargetCpaInput } from '../../BidStrategy/TargetCpaRadio/TargetCpaInput';
import { useTargetCpaAdditionalValidations } from '../MaxConversionsCategory/useTargetCpaAdditionalValidations';
import { CPCInput } from './CPCInput';

export const TargetCpaStrategy = inputProps => {
    const isTargetCPANoCpcEnabled = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_NO_CPC_ENABLED]: 'true' });
    const { targetCpaValidationDependencies, targetCpaValidations } = useTargetCpaAdditionalValidations();

    return (
        <>
            <TargetCpaInput
                additionalValidations={targetCpaValidations}
                additionalDependencies={targetCpaValidationDependencies}
                {...inputProps}
            />
            {!isTargetCPANoCpcEnabled && <CPCInput />}
        </>
    );
};
