import { useCallback, useMemo } from 'react';
import { get, noop } from 'lodash';
import { useAccountFullConfigData } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountFullConfigData';
import { FEATURE_FLAGS } from '../../modules/taboola-common-frontend-modules/account-configurations';
import { getLinkContextMenuOptions } from '../../modules/taboola-common-frontend-modules/iframe/utils/linkHandlers';
import { useCustomContextMenu } from '../CustomContextMenu/useCustomContextMenu';

export const useInternalLinkMenu = link => {
    const { close, open } = useCustomContextMenu();
    const configs = useAccountFullConfigData();

    //TODO: change this to useConfigMatch when we fix useConfigMatch
    const showInternalLinkCustomContextMenu =
        get(configs, `selectedAccountConfig.${FEATURE_FLAGS.SHOW_INTERNAL_LINK_CUSTOM_CONTEXT_MENU}`) === 'true';

    const menuItems = useMemo(
        () =>
            getLinkContextMenuOptions(link).map(({ onClick, ...rest }) => ({
                ...rest,
                onClick: (...args) => {
                    close();
                    onClick(...args);
                },
            })),
        [link, close]
    );

    const openLinkMenu = useCallback(
        event => (showInternalLinkCustomContextMenu ? open(event, { menuItems }) : noop),
        [open, menuItems, showInternalLinkCustomContextMenu]
    );

    return openLinkMenu;
};
