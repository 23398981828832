import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './originalImageIndication.module.scss';

const classNameBuilder = classnames.bind(styles);

export const OriginalImageIndication = ({ className }) => {
    return (
        <div className={classNameBuilder('indication', className)}>
            <FormattedMessage id="creative.studio.originalImage.label" defaultMessage="Original" />
        </div>
    );
};
