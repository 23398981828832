import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    ConversionActionsCellRenderer,
    ConversionStateCellRenderer,
    EllipsisCellRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    withCampaignEditIndication,
} from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    hiddenColumnType,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { isCampaignEditable } from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { CONVERSION_STATUS } from 'modules/tracking/config';
import { updateConversion } from 'modules/tracking/flows/updateConversion';

const isDisabled = data => {
    const { accountName: currAccountName, selectedAccountData } = data;
    const [{ accountName }] = selectedAccountData;

    return !isCampaignEditable({ data }) || accountName !== currAccountName;
};
const smallColumnDefaultWidth = 70;

const conversionsReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.IS_ACTIVE.label,
            field: FIELDS.IS_ACTIVE.field,
            type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
            cellRendererParams: {
                ...FIELDS.IS_ACTIVE.renderParams,
                isDisabled,
                reportId: REPORT_TYPE.CONVERSIONS,
            },
            cellRendererFramework: ConversionStateCellRenderer,
            csv: false,
            width: smallColumnDefaultWidth,
            minWidth: 36,
            maxWidth: 50,
            valueChangeHandler: ({ isActive }, conversion) =>
                updateConversion({
                    accountId: conversion.accountName,
                    conversion: {
                        ...conversion,
                        isActive,
                        status: isActive ? CONVERSION_STATUS.ACTIVE : CONVERSION_STATUS.DISABLED,
                    },
                }),
        },
        {
            headerName: FIELDS.ID.label,
            field: FIELDS.ID.field,
            type: [hiddenColumnType, numericColumn],
        },
        {
            headerName: FIELDS.CONVERSION_STATUS.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CONVERSION_STATUS.headerComponentParams,
            field: FIELDS.CONVERSION_STATUS.field,
            cellRendererFramework: FIELDS.CONVERSION_STATUS.cellRendererFramework,
            cellRendererParams: FIELDS.CONVERSION_STATUS.cellRendererParams,
            csvValueGetter: FIELDS.CONVERSION_STATUS.csvValueGetter,
            width: smallColumnDefaultWidth,
            minWidth: 56,
        },
        {
            headerName: FIELDS.CONVERSION_NAME.label,
            field: FIELDS.CONVERSION_NAME.field,
            cellRendererFramework: FIELDS.CONVERSION_NAME.cellRenderer,
        },
        {
            headerName: FIELDS.CONVERSION_TYPE.label,
            field: FIELDS.CONVERSION_TYPE.field,
            cellRendererParams: FIELDS.CONVERSION_TYPE.cellRendererParams,
            cellRendererFramework: FIELDS.CONVERSION_TYPE.cellRendererFramework,
            csvValueGetter: FIELDS.CONVERSION_TYPE.csvValueGetter,
            headerComponentParams: FIELDS.CONVERSION_TYPE.headerComponentParams,
            width: smallColumnDefaultWidth,
            minWidth: 56,
            maxWidth: 100,
        },
        {
            headerName: FIELDS.ACCOUNT_NAME.label,
            field: FIELDS.ACCOUNT_NAME.field,
            cellRendererFramework: EllipsisCellRenderer,
            cellRendererParams: { lines: 2 },
        },
        {
            headerName: FIELDS.CATEGORY.label,
            field: FIELDS.CATEGORY.field,
            cellRendererParams: { msgIdPrefix: `tracking.conversions.report.category` },
            cellRendererFramework: FIELDS.CATEGORY.cellRendererFramework,
            csvValueGetter: FIELDS.CATEGORY.csvValueGetter,
        },
        {
            headerName: FIELDS.CONVERSION_CONDITIONS.label,
            field: FIELDS.CONVERSION_CONDITIONS.field,
            cellRendererFramework: FIELDS.CONVERSION_CONDITIONS.cellRendererFramework,
            headerComponentParams: FIELDS.CONVERSION_CONDITIONS.headerComponentParams,
            csvValueGetter: FIELDS.CONVERSION_CONDITIONS.csvValueGetter,
        },
        {
            headerName: FIELDS.LAST_RECEIVED.label,
            field: FIELDS.LAST_RECEIVED.field,
            cellRendererFramework: FIELDS.LAST_RECEIVED.cellRendererFramework,
            csvValueGetter: FIELDS.LAST_MODIFY_ON.csvValueGetter,
        },
        {
            headerName: FIELDS.EVENT_RECEIVED.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            field: FIELDS.EVENT_RECEIVED.field,
            cellRendererFramework: FIELDS.EVENT_RECEIVED.cellRendererFramework,
            headerComponentParams: FIELDS.EVENT_RECEIVED.headerComponentParams,
            csv: false,
            sortable: false,
        },
        {
            headerName: FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.label,
            field: FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.field,
            cellRendererFramework: FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.cellRendererFramework,
            cellRendererParams: FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.cellRendererParams,
            width: smallColumnDefaultWidth,
            minWidth: 56,
            maxWidth: 100,
        },
        {
            headerName: FIELDS.INCLUDE_IN_TOTAL_VALUE.label,
            field: FIELDS.INCLUDE_IN_TOTAL_VALUE.field,
            cellRendererFramework: FIELDS.INCLUDE_IN_TOTAL_VALUE.cellRendererFramework,
            cellRendererParams: FIELDS.INCLUDE_IN_TOTAL_VALUE.cellRendererParams,
            width: smallColumnDefaultWidth,
            minWidth: 56,
            maxWidth: 100,
        },
        {
            headerName: FIELDS.LAST_MODIFY_ON.label,
            field: FIELDS.LAST_MODIFY_ON.field,
            valueFormatter: FIELDS.LAST_MODIFY_ON.valueFormatter,
            csvValueGetter: FIELDS.LAST_MODIFY_ON.csvValueGetter,
            headerComponentParams: FIELDS.LAST_MODIFY_ON.headerComponentParams,
            maxWidth: 100,
        },
        {
            headerName: FIELDS.ACTIONS_COLUMN.label,
            field: FIELDS.ACTIONS_COLUMN.field,
            type: [rightPinnedColumn, actionsColumnType, staticColumn],
            cellRendererParams: {
                onConversionArchive: updateConversion,
            },
            cellRendererFramework: ConversionActionsCellRenderer,
            csv: false,
            maxWidth: 100,
            minWidth: 100,
        },
    ],
];

export default normalizeColumnDefs(
    conversionsReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CONVERSIONS].id,
    withCampaignEditIndication
);
