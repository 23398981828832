import { useQuery } from 'react-query';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { usePerformanceRulesApi } from '../../../services/api/performanceRulesApi/usePerformanceRulesApi';

const emptyObj = {};
export const useTemplatesData = dimension => {
    const { getPerformanceRulesTemplates } = usePerformanceRulesApi();
    const {
        formAccount: { accountName },
    } = useFormDataContext();

    const { data: performanceRulesTemplatesData = emptyObj } = useQuery(
        ['performanceRulesTemplates', accountName, dimension],
        () => getPerformanceRulesTemplates(accountName, dimension),
        {
            enabled: !!accountName && !!dimension,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            retry: false,
        }
    );

    return performanceRulesTemplatesData;
};
