import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

export const ArticlesReachTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.size.estimation.articles.reach.tooltip.title"
                defaultMessage="Total articles with the selected topics."
            />
        </TooltipSection>
    </>
);
