import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import BrowserTargetingSmartList from './BrowserTargetingSmartList';
import BrowserTargetingTooltip from './BrowserTargetingTooltip';
import styles from '../commonEditor.module.scss';

const BrowserTargeting = () => (
    <FormField
        inputId="browser-targeting"
        label={<FormattedMessage id="campaign.editor.targeting.browser.title" defaultMessage="Browser" />}
        description={
            <FormattedMessage
                id="campaign.editor.targeting.browser.description"
                defaultMessage="Target specific Browsers."
            />
        }
        helpText={<BrowserTargetingTooltip />}
        containerClass={styles['input']}
    >
        <BrowserTargetingSmartList />
    </FormField>
);

export default BrowserTargeting;
