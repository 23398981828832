import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components';
import { FORM_MODES } from 'modules/campaigns/config';
import { LayerRoute } from '../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import { ACCOUNT_EDITOR_ROUTE_PATH, PATH_TO_ENTITY_MAP } from '../../config';
import { AccountEditorPage } from '../../pages/AccountEditorPage';

export const EditDrawer = ({ onCancel }) => {
    const { params: { mode, entity: entityParam } = {} } = useRouteMatch(ACCOUNT_EDITOR_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={ACCOUNT_EDITOR_ROUTE_PATH}
                        render={props => <AccountEditorPage {...props} onCancel={close} />}
                    />
                    <Redirect to={ACCOUNT_EDITOR_ROUTE_PATH} />
                </Switch>
            )}
        </SideDrawer>
    );
};

EditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
};
