import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../taboola-common-frontend-modules/modals';
import { deleteFunnelFlow } from '../flows/deleteFunnel';
import styles from './deleteFunnel.module.scss';

export const useDeleteFunnel = () => {
    const dispatch = useDispatch();
    const { open: openModal } = useModal();
    const deleteFunnel = useCallback(
        async funnel => {
            const { id, name, accountName, numberOfCampaignsUsing: numberOfCampaigns } = funnel;
            const result = await openModal({
                title: (
                    <FormattedMessage
                        id="app.funnels.delete.confirmation.title"
                        defaultMessage="Delete Funnel?"
                        tagName="h2"
                    />
                ),
                content: (
                    <FormattedMessage
                        id="app.funnels.delete.funnel.confirmation.message"
                        defaultMessage="You are about to delete {name}. This will remove the Funnel from {numberOfCampaigns} campaigns it's currently linked to.{newline} Please note that this action cannot be undone and the funnel will be permanently deleted."
                        values={{ name, numberOfCampaigns }}
                    />
                ),
                className: styles['content'],
                headerClassName: styles['title'],
                formProps: {
                    submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" defaultMessage="DELETE" />,
                },
            });

            if (result) {
                dispatch(deleteFunnelFlow(accountName, id));
            }
        },
        [openModal, dispatch]
    );
    return deleteFunnel;
};
