import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.QUARTILE_VIDEO].id;

const defaultColumns = [FIELDS.QUARTILE.field, FIELDS.IMPRESSIONS.field, FIELDS.QUARTILE_IMPRESSIONS_RATE.field].map(
    field => generateColumnId(reportId, field)
);

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.QUARTILE.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.QUARTILE_IMPRESSIONS_RATE.field,
    ].map(field => generateColumnId(reportId, field)),
};

export { customColumns, defaultColumns };
