import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MissingSVGIcon } from './MissingSVGIcon';
import styles from './missingFeature.scss';

const classNameBuilder = classNames.bind(styles);

export const MissingFeature = ({ className, children, title, description, missingIcon }) => (
    <div className={classNameBuilder('container', className)}>
        <div className={classNameBuilder('icon')}>{missingIcon}</div>
        <div className={classNameBuilder('content')}>
            <div className={classNameBuilder('title')}>{title}</div>
            <div className={classNameBuilder('description')}>{description}</div>
            <div>{children}</div>
        </div>
    </div>
);

MissingFeature.propTypes = {
    /** MissingFeature's class name */
    className: PropTypes.string,
    /** MissingFeature's title */
    title: PropTypes.node.isRequired,
    /** MissingFeature's description of what's missing */
    description: PropTypes.node.isRequired,
    /** MissingFeature's children is meant for rendering some values of the missing feature */
    children: PropTypes.node,
    /** MissingFeature's icon */
    missingIcon: PropTypes.node,
};

MissingFeature.defaultProps = {
    missingIcon: <MissingSVGIcon />,
    className: '',
};
