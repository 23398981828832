import { useCallback } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import TargetingTypes from '../../../config/TargetingTypes';
import { useSiteTargeting } from './useSiteTargeting';

const siteListKey = 'excluded-sites';

export const useExcludedSites = onChangeTargetingType => {
    const { isDirty: readonly } = useFormFieldValue({ field: 'campaignTargeting.publisherTargeting.include' });
    const onChangeHandler = useCallback(() => onChangeTargetingType(TargetingTypes.EXCLUDE), [onChangeTargetingType]);
    const props = useSiteTargeting({
        field: 'campaignTargeting.publisherTargeting.exclude',
        readonly,
        siteListKey,
        onChangeHandler,
    });

    return props;
};
