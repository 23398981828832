import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CAMPAIGNS_ROUTE, REPORT_TYPE, resolveReportIdByCampaignDimension } from 'modules/campaigns/config';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params/queryParamUtils';
import { CAMPAIGN_ID } from '../../../hooks';
import { REPORT_ID } from '../../campaigns-reports/hooks';
import { REPORT_SORTING } from '../../campaigns-reports/hooks/useReportSorting';
import createCreative, { postCreativeCreationSortState } from '../flows/createCreative';
import styleConsts from '../../../../../globalStyle/styleConsts.module.scss';

const useVideoCreativeCreatorState = ({ dimension, campaignId }) => {
    const dispatch = useDispatch();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { submit, step, setStep } = useFormState();

    const handleCreativeSubmitWrapper = useCallback(() => {
        const reportId = resolveReportIdByCampaignDimension(REPORT_TYPE.CREATIVE_VIDEO, dimension);

        const creativeReportPath = mergeQueryParamsWithLocation(
            { pathname: CAMPAIGNS_ROUTE },
            {
                [REPORT_ID]: reportId,
                [CAMPAIGN_ID]: campaignId,
                [REPORT_SORTING]: postCreativeCreationSortState,
            }
        );

        const saveCreative = creative => dispatch(createCreative({ accountId, campaignId, creative }));
        submit(saveCreative, creativeReportPath);
    }, [accountId, campaignId, dimension, dispatch, submit]);

    return {
        step,
        setStep,
        submit: handleCreativeSubmitWrapper,
        wizardTemplateColumns: `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`,
    };
};

export default useVideoCreativeCreatorState;
