import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BulkUploadResult } from './BulkUploadResult/BulkUploadResult';

export const BulkUploadResultSection = ({ responseSummary, onReportDownloadClick }) => {
    const { value: file } = useFormFieldValue({ field: 'file' });
    const filename = file?.name;

    return (
        <CommonCollapsibleCard
            id="bulk-operations-upload-section"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="excel.bulk.bulk.upload.report" defaultMessage="Bulk Upload Report" />
                    }
                />
            }
        >
            <BulkUploadResult
                responseSummary={responseSummary}
                filename={filename}
                onReportDownloadClick={onReportDownloadClick}
            />
        </CommonCollapsibleCard>
    );
};
