import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRealtime } from 'modules/campaigns/hooks';
import { isCarouselCreative } from 'modules/campaigns/modules/creative-creator/components/CarouselCardList/utils/isCarouselCreative';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import {
    isAppInstall,
    isBrandsVideoCreative,
    isCreativeResubmittable,
    isDeleteCreativeAllowed,
    isDuplicateCreativeAllowed,
    isEditCreativeAllowed,
    isHTMLCardCreative,
    isMotionAd,
    isPreviewCreativeAllowed,
    isRssChild,
} from '../../config/predicates';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import ActionsCellRenderer from './ActionsCellRenderer';

const ACTIONS = {
    EDIT: 'edit',
    DUPLICATE: 'duplicate',
    PREVIEW: 'preview',
    DELETE: 'delete',
};

const AD_TYPE = {
    MOTION_AD: 'video',
    APP_INSTALL: 'appinstall',
    RSS: 'rss',
    UNRESUBMITTABLE: 'unresubmittable',
    HIERARCHY: 'hierarchy',
    GENERAL: 'general',
};

const getTooltipMessage = (data, action, isMessageIdExists) => {
    const motionAdMessageId = isMotionAd(data) ? AD_TYPE.MOTION_AD : null;
    const appInstallMessageId = isAppInstall(data) ? AD_TYPE.APP_INSTALL : null;
    const rssMessageId = isRssChild(data) ? AD_TYPE.RSS : null;
    const hierarchyMessageId = isCarouselCreative(data) ? AD_TYPE.HIERARCHY : null;
    const unresubmittableMessageId =
        !isCreativeResubmittable(data) && action === ACTIONS.EDIT ? AD_TYPE.UNRESUBMITTABLE : null;
    const domain =
        hierarchyMessageId ||
        appInstallMessageId ||
        motionAdMessageId ||
        rssMessageId ||
        unresubmittableMessageId ||
        AD_TYPE.GENERAL;
    const messageId = `app.campaigns.creativeReports.actions.${action}.creative.${domain}`;
    if (isMessageIdExists(messageId)) {
        return <FormattedMessage id={messageId} />;
    }
    return null;
};

const getTooltipProps = (data, action) => {
    if (!isCreativeResubmittable(data) && action === ACTIONS.EDIT) {
        return { interactive: true };
    }
    return null;
};

const getDeleteModalMessages = title => ({
    title: (
        <FormattedMessage
            id="creative.delete.header"
            tagName="h2"
            defaultMessage="{selectedCount, plural, one{Delete Ad?} other{Delete Ads?}}"
            values={{ selectedCount: 1 }}
        />
    ),
    content: (
        <FormattedMessage
            id="creative.modal.content.delete"
            defaultMessage="You are about to delete: {title}. Once an ad is deleted, it can never run again. Instead of deleting, you can pause ads. Do you wish to proceed?"
            values={{ title }}
        />
    ),
    formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" defaultMessage="DELETE" /> },
});

const CreativeActionsCellRenderer = ({ data, onEdit, onPreview, onDelete, onDuplicate, context, ...rest }) => {
    const { isMessageIdExists } = useIntl();
    const { open: openModal } = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    const hasEditPermission = usePermissions('CREATIVE_EDIT');
    const { isGridEditDisabled } = useGridContext();
    const disableHTMLActionBtn = isHTMLCardCreative(data);

    const isCreativeDuplicatorDrawerEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_DUPLICATE_ENABLED]: 'true',
    });

    const isCreativeDuplicatorVideoScDrawerEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_EDIT_VIDEO_DUPLICATE_ENABLED]: 'true',
    });

    const { isRealtimeActive } = useRealtime();

    const shouldDisableDuplicateButton =
        !isDuplicateCreativeAllowed(data) ||
        (isMotionAd(data) && !isCreativeDuplicatorVideoScDrawerEnabled) ||
        isBrandsVideoCreative(data) ||
        !hasEditPermission ||
        isGridEditDisabled ||
        disableHTMLActionBtn ||
        isAppInstall(data) ||
        isRealtimeActive;

    const deleteHandler = async data => {
        const { title } = data;
        const result = await openModal(getDeleteModalMessages(title));

        if (result) {
            dispatch(onDelete(data));
        }
    };

    const buttonConfigs = [];
    buttonConfigs.push({
        onClick: () => onEdit({ data, context: { ...context, history } }),
        isDisabled: !isEditCreativeAllowed(data) || !hasEditPermission || isGridEditDisabled || disableHTMLActionBtn,
        dataMetricComponent: 'EditActionButton',
        iconLabel: 'edit-o',
        tooltipContent: getTooltipMessage(data, ACTIONS.EDIT, isMessageIdExists),
        tooltipProps: getTooltipProps(data, ACTIONS.EDIT),
    });

    if (isCreativeDuplicatorDrawerEnabled) {
        buttonConfigs.push({
            onClick: () => dispatch(onDuplicate(data, history)),
            isDisabled: shouldDisableDuplicateButton,
            dataMetricComponent: 'DuplicateActionButton',
            iconLabel: 'duplicate',
            tooltipContent: isRealtimeActive ? (
                <FormattedMessage
                    id="app.campaigns.creativeReports.actions.duplicate.creative.realtime"
                    defaultMessage="Duplicating ads is not supported yet in the “real-time” mode."
                />
            ) : (
                getTooltipMessage(data, ACTIONS.DUPLICATE, isMessageIdExists)
            ),
        });
    }

    buttonConfigs.push(
        {
            onClick: () => dispatch(onPreview(data, history)),
            isDisabled: !isPreviewCreativeAllowed(data) || disableHTMLActionBtn,
            dataMetricComponent: 'PreviewActionButton',
            iconLabel: 'eye',
            permissionsGuardProps: {
                permissions: 'CREATIVE_PREVIEW_VIEW',
            },
            tooltipContent: getTooltipMessage(data, ACTIONS.PREVIEW, isMessageIdExists),
        },
        {
            onKeyDown: e => e.preventDefault(),
            onClick: () => deleteHandler(data),
            isDisabled: isCarouselCreative(data) || !isDeleteCreativeAllowed(data) || !hasEditPermission,
            dataMetricComponent: 'DeleteActionButton',
            iconLabel: 'delete',
            tooltipContent: getTooltipMessage(data, ACTIONS.DELETE, isMessageIdExists),
        }
    );

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

CreativeActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    onDelete: PropTypes.func,
    onPreview: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default CreativeActionsCellRenderer;
