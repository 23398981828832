import { useMemo } from 'react';
import { PAYMENT_PROVIDER_NAME } from 'modules/billing-and-payments/config/PaymentProviderName';
import { useGetAccountAdditionalDataByName } from 'services/api/accountsApi/hooks';
import { useAccount } from '../modules/taboola-common-frontend-modules/account-configurations';

export const useAccountAdditionalData = () => {
    const { accountName } = useAccount();

    const { data, isFetching, isSuccess, isError } = useGetAccountAdditionalDataByName({ accountName });

    const resolvedData = useMemo(() => {
        if (!data) {
            return data;
        }

        const { creditData } = data;
        if (!creditData) {
            return data;
        }

        return {
            ...data,
            creditData: {
                ...creditData,
                paymentProviderName: creditData.paymentProviderName ?? PAYMENT_PROVIDER_NAME.STRIPE,
            },
        };
    }, [data]);

    return {
        accountAdditionalData: resolvedData,
        isFetchingAdditionalData: isFetching,
        isAdditionalDataSuccess: isSuccess,
        isAdditionalDataError: isError,
    };
};
