import React, { createContext, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { COMPONENT_STATUS } from '../../../../../../../services/constants';

export const MediaTabsContext = createContext({
    thumbnails: [],
    thumbnailsMap: {},
    status: COMPONENT_STATUS.INITIAL,
    setStatus: noop,
    items: [],
    setItems: noop,
});

/**
 * This provider is necessary to prevent all gallery images
 * from rerendering when one is selected. This impl circumvents
 * passing a thumbnailsMap prop to the Gallery, which caused it
 * to rerender whenever thumbnails (selected images) changed. By
 * using context stop passing this map as a prop and therefore
 * avoid these rerenders.
 * */
export const MediaTabsContextProvider = ({ openCropModal, thumbnails, children, campaignId, titleList }) => {
    const [status, setStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [items, setItems] = useState([]);
    const value = useMemo(() => {
        const thumbnailsMap = thumbnails.reduce((acc, cur) => {
            acc[cur.id] = true;
            return acc;
        }, {});
        return { thumbnails, thumbnailsMap, status, setStatus, items, setItems, campaignId, titleList, openCropModal };
    }, [items, status, thumbnails, campaignId, titleList, openCropModal]);

    return <MediaTabsContext.Provider value={value}>{children}</MediaTabsContext.Provider>;
};

export const useMediaTabsContext = () => useContext(MediaTabsContext);
