import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import classnames from 'classnames/bind';
import { STRIPE_ELEMENT } from '../../../../taboola-common-frontend-modules/stripe';
import { useStripePaymentElement } from '../../../../taboola-common-frontend-modules/stripe/hooks/useStripePaymentElement';
import commonStyles from '../common.module.scss';
import styles from './stripePaymentElement.module.scss';

const commonClassNameBuilder = classnames.bind(commonStyles);

export const StripePaymentElement = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { ...stripeElementProps } = useStripePaymentElement(STRIPE_ELEMENT.PAYMENT);

    if (!stripe || !elements) {
        return null;
    }

    return (
        <div className={commonClassNameBuilder(commonStyles['container'], styles['payment-element'])}>
            <PaymentElement id="payment-element" className={commonStyles['element']} {...stripeElementProps} />
        </div>
    );
};
