import React, { useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import {
    BlockIcon,
    DeletableItem,
    OkOIcon,
    TooltipV2 as Tooltip,
    useCollapsibleListContext,
    AlertOIcon,
    INDICATION_TYPES,
    Dotdotdot,
} from 'taboola-ultimate-ui';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonAudienceListItemContent.module.scss';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { isValidItem } from '../utils/isValidItem';
import styles from './siteTargetingListItem.module.scss';

const SiteTargetingListItem = ({ item }) => {
    const { formatMessage } = useIntl();

    const {
        deleteItem,
        metadata: { isAllowedSitesReady, isIncludedItem = false, readonly, allowedSites = {} },
    } = useCollapsibleListContext();
    const { label = '', loading } = item;

    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);
    const buttonLabel = formatMessage(
        {
            id: `campaign.editor.targeting.site.smartList.item.delete.${isIncludedItem ? 'included' : 'excluded'}`,
            defaultMessage: `Delete ${isIncludedItem ? 'targeted' : 'blocked'} site: ${label}`,
        },
        { label }
    );
    const { indicationType, message } = useMemo(() => {
        if (!isAllowedSitesReady || isValidItem({ item, allowedSites, readonly })) {
            return {};
        }
        return {
            indicationType: INDICATION_TYPES.WARNING,
            message: (
                <FormattedMessage id="campaign.editor.targeting.site.invalidItem" defaultMessage="Unavailable site" />
            ),
        };
    }, [isAllowedSitesReady, allowedSites, item, readonly]);

    return (
        <DeletableItem
            onDelete={onDelete}
            isItemDeletable={!readonly && !loading}
            buttonProps={{ 'aria-label': buttonLabel, 'data-testid': buttonLabel }}
        >
            <div className={styles['container']}>
                <div className={styles['icon-container']}>
                    {isIncludedItem ? (
                        <OkOIcon data-testid="okIcon" className={styles['include']} />
                    ) : (
                        <BlockIcon data-testid="blockIcon" className={styles['exclude']} />
                    )}
                </div>
                <div className={styles['content']}>
                    <div className={styles['label']}>
                        <Dotdotdot lines={2}>{label}</Dotdotdot>
                    </div>
                    {!loading && indicationType && (
                        <div className={styles['detail']}>
                            <span
                                className={commonStyles['error-icon-wrapper']}
                                aria-label={`${label} ${indicationType} icon`}
                            >
                                <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                                <Tooltip delay={800}>{message}</Tooltip>
                            </span>
                        </div>
                    )}
                    {loading && (
                        <div className={styles['loader']}>
                            <ContentLoader
                                data-testid="contentLoader"
                                width={80}
                                height={26}
                                speed={2}
                                className={styles['loader']}
                            >
                                <rect x="0" y="8" rx="8" ry="8" width="80" height="12" />
                            </ContentLoader>
                        </div>
                    )}
                </div>
            </div>
        </DeletableItem>
    );
};

SiteTargetingListItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        blockedBySmartBid: PropTypes.bool,
    }),
    hideDeleteButton: PropTypes.bool,
    deleteItem: PropTypes.func,
    isIncludedItem: PropTypes.bool,
};

export default SiteTargetingListItem;
