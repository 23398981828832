import React from 'react';
import { generateErrorIndication } from 'services/utils/errorMessagesUtils';
import { clearHasUnsavedChanges } from '../../../actions';
import { creativesApi, videoCreativesApi } from '../../../services/api';
import { recommendationsIdSelector } from '../../recommendations/selectors';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { saveCreativeError, saveCreativeSuccess } from '../actions/index';
import { isRssChild } from '../modules/campaigns-reports/config/predicates';

export const successIndication = {
    message: <FormattedMessage id="creative.save.success" defaultMessage="Ad successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const errorMessage = (
    <FormattedMessage id="creative.save.error" defaultMessage="We were not able to save changes to the ad" />
);

const updateCreative = async (creative, updateCreativeCall, accountId, creativeChanges, recommendationId) => {
    const { campaignId, id: creativeId, rssParentId } = creative;
    if (isRssChild(creative)) {
        return await updateCreativeCall(accountId, campaignId, rssParentId, creativeId, creativeChanges);
    }
    return await updateCreativeCall(accountId, campaignId, creativeId, creativeChanges, recommendationId);
};

const saveCreativeBase = updateCreativeCall => (accountId, creative, creativeChanges) => async (dispatch, getState) => {
    try {
        const state = getState();
        const recommendationId = recommendationsIdSelector(state);
        const result = await updateCreative(creative, updateCreativeCall, accountId, creativeChanges, recommendationId);
        dispatch(saveCreativeSuccess(result));
        dispatch(clearHasUnsavedChanges());
        dispatch(addIndication(successIndication));
        return { result };
    } catch (error) {
        dispatch(saveCreativeError(creative.id, error));
        dispatch(addIndication(generateErrorIndication(error, errorMessage)));
        throw error;
    }
};

const saveCreative = saveCreativeBase(creativesApi.updateCreative);
const saveVideoCreative = saveCreativeBase(creativesApi.updateVideoCreative);
const saveRssCreative = saveCreativeBase(creativesApi.updateRssCreative);
const saveBrandsVideoCreative = saveCreativeBase(videoCreativesApi.updateVideoCreative);

export { saveCreative, saveVideoCreative, saveRssCreative, saveBrandsVideoCreative };
