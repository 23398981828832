import React from 'react';
import YES_NO_TYPES from '../../../campaigns/config/yesNoConsts';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const BusinessOfExtendingCreditFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.business.of.extending.credit.tooltip.overview"
                defaultMessage="By clicking {yes} you confirm that you advertise on behalf of institutions that extend credit to individuals and therefore have not excluded any audience characteristics that are indicative of a protected class (ex: based on race, gender, or religion)."
                values={{
                    yes: (
                        <strong>
                            <FormattedMessage id="app.form.field.type.yes" defaultMessage={YES_NO_TYPES.YES} />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.business.of.extending.credit.tooltip.more"
                defaultMessage="In addition, Taboola will not create any lookalike models based on these protected characteristics."
            />
        </TooltipSection>
    </>
);

export default BusinessOfExtendingCreditFieldTooltip;
