import React from 'react';
import PropTypes from 'prop-types';
import { Button, ChevronLeftOutlinedIcon, ChevronRightOutlinedIcon } from 'tuui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../../../components/Form';
import styles from './campaignCreatorFormButtons.module.scss';

const CampaignCreatorFormButtons = ({ onBack, onCancel, onSubmit, onSubmitAndAddCreatives, disableSubmit }) => (
    <div className={styles['buttons-wrapper']}>
        <FormButtons
            className={styles['form-buttons']}
            onSubmit={onSubmit}
            onBack={onBack}
            disableSubmit={disableSubmit}
            submitLabel={<FormattedMessage id="campaign.creator.submit.button" defaultMessage="CREATE CAMPAIGN" />}
            backLabel={<FormattedMessage id="app.actionButtons.back" />}
            backIcon={<ChevronLeftOutlinedIcon />}
            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
            onCancel={onCancel}
            submitButtonType="submit"
            outlineSubmitButton
            showFooterValidations
        />
        <Button
            className={styles['add-creatives-button']}
            onClick={onSubmitAndAddCreatives}
            size={Button.variant.md}
            variant={Button.variant.primary}
            disabled={disableSubmit}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="SubmitAndAddCreativesButton"
            type="submit"
        >
            <FormattedMessage id="campaign.creator.submit.and.add.creatives.button" defaultMessage="CREATE & ADD ADS" />
            <ChevronRightOutlinedIcon />
        </Button>
    </div>
);

CampaignCreatorFormButtons.propTypes = {
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitAndAddCreatives: PropTypes.func,
    disableSubmit: PropTypes.bool,
};

export default CampaignCreatorFormButtons;
