import { gtmTracker, GTM_EVENTS } from '../../gtmTracker';
import { addIndication as addIndicationAction } from '../actions';
import NOTIFICATION_TYPES from '../config/notificationTypes';
import createEventViaIndication from './utils';

const addIndication = indication => dispatch => {
    const event = createEventViaIndication(NOTIFICATION_TYPES.TOAST, indication);
    gtmTracker.trackEvent(GTM_EVENTS.NOTIFICATION, event);
    dispatch(addIndicationAction(indication));
};

export default addIndication;
