import React, { Fragment } from 'react';
import { PromptHelperTag } from './PromptHelperTag';
import { usePromptHelper } from './hooks/usePromptHelper';

export const PromptHelperTagList = () => {
    const { getPromptHelperParams } = usePromptHelper();
    return (
        <Fragment>
            {Object.entries(getPromptHelperParams()).map(([key, value]) => {
                return <PromptHelperTag key={key} category={key} option={value} />;
            })}
        </Fragment>
    );
};
