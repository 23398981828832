import { useCallback } from 'react';

export const isMetaKeyEvent = event => event.ctrlKey || event.metaKey;

export const useMetaKeySkipHandler = callback => {
    const handler = useCallback(
        (event, ...rest) => {
            if (isMetaKeyEvent(event) || !callback) {
                return;
            }
            callback(event, ...rest);
        },
        [callback]
    );

    return handler;
};
