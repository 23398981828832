"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MissingSVGIcon = function MissingSVGIcon() {
    return _react2.default.createElement(
        "svg",
        { xmlns: "http://www.w3.org/2000/svg", width: "109", height: "88", viewBox: "0 0 109 88" },
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M40.25 80.86c.548-1.603 1.15-3.385 1.557-5.697h26.385c.407 2.312 1.01 4.094 1.557 5.697C70.42 82.826 71 84.523 71 86.584c0 .333-.309.602-.69.602H39.69c-.382 0-.69-.27-.69-.602 0-2.061.58-3.758 1.25-5.724" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M1.98 61h51.875V3c-.098-.82-.486-.997-1-1-32.27-.003-48.558-.003-48.86 0-.455.004-2.014.732-2.014 2v57zM55 0l.1 73H3.989C1.573 73 0 71.422 0 69V4C0 1.446 1.504.007 3.994 0H55z" }),
            _react2.default.createElement("path", { fill: "#FFF", d: "M54 2H2.98a1 1 0 0 0-1 1v58H54V2z" }),
            _react2.default.createElement("path", { fill: "#FAFAFA", d: "M50.354 30.18c0-.289.2-.521.467-.592v1.183a.614.614 0 0 1-.467-.591" }),
            _react2.default.createElement("path", { fill: "#EEF0F2", d: "M107.6 69.565a2.095 2.095 0 0 1-2.09 2.094L55 71.011v-1.86c32.704-.213 49.055-.572 49.055-1.076l.745-61.753a1.99 1.99 0 0 0-1.985-1.99H55V1.528h50.51c1.152 0 2.09.94 2.09 2.094v65.944z" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M102.609 71h2.895c1.154 0 1.496-.344 1.496-1.5v-66c0-1.156-.342-1.502-1.496-1.502H55.008V.168C55.008.107 55 .058 55 0h50.504A3.502 3.502 0 0 1 109 3.5v66c0 1.93-1.569 3.5-3.496 3.5H55.008v-2h47.6z" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M105 66.973a2.188 2.188 0 0 1-2.194 2.179H55V4.029h48.003c1.101 0 1.997.89 1.997 1.983v60.961z" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M72.255 59.274c.552 0 1.076-.868 1.429-1.228l2.314-2.874c.658-.676 1.52-1.049 2.427-1.049H82v1.05h-3.575c-.551 0-1.527.596-1.88.954L74 58.928c-.658.677-.837 1.207-1.745 1.207H55v-.861h17.255zM104.8 17.037V16.02H84.365c-.516 0-1.006-.663-1.382-1.019l-7.427-6.23c-.562-.531-1.297-.72-2.07-.72H55v.93h18.5c.414 0 .793-.285 1.093 0l7.948 7.04c.637.602.949 1.015 1.824 1.015H104.8z" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M71.466 15.967l1.35-.992-2.1-1.542c-1.058-.777-3.06-1.386-4.556-1.386H54.1v.973h12.06c.995.038 3.44.635 4.115 1.13l1.19 1.817zM92 67.148h-1v-8.015h1z" }),
            _react2.default.createElement("path", { fill: "#FFF", d: "M75.2 42.1h5.103C82.34 42.1 84 40.544 84 38.629v-4.723c0-3.39-2.058-5.58-5.243-5.58-2.366 0-4.29-1.808-4.29-4.03v-3.547c0-.381-.328-.689-.734-.689-.405 0-.733.308-.733.689v3.547c0 2.982 2.582 5.407 5.757 5.407 3.504 0 3.776 3.217 3.776 4.203v4.723c0 1.155-1 2.095-2.23 2.095H75.2c-.406 0-.733.308-.733.689 0 .38.327.689.733.689" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M69.333 48.222a1.403 1.403 0 0 1 .73 2.708 1.403 1.403 0 0 1-.73-2.708" }),
            _react2.default.createElement("path", { fill: "#FAFAFA", d: "M82.773 60.135h11.455c.978 0 1.772-.843 1.772-1.882v-9.262c0-1.039-.794-1.881-1.772-1.881H82.773c-.98 0-1.773.842-1.773 1.881v9.262c0 1.04.794 1.882 1.773 1.882" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M70.5 15.554a2.105 2.105 0 0 1 0 4.207 2.105 2.105 0 0 1 0-4.207" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M73.5 19.761a2.105 2.105 0 0 0 0-4.207 2.105 2.105 0 0 0 0 4.207m0-5.61a3.507 3.507 0 0 1 0 7.013 3.507 3.507 0 0 1 0-7.013M83.688 51.118h9.624c.38 0 .688-.224.688-.501s-.307-.501-.688-.501h-9.624c-.38 0-.688.224-.688.5 0 .278.307.502.688.502M83.688 54.123h9.624c.38 0 .688-.224.688-.5 0-.278-.307-.502-.688-.502h-9.624c-.38 0-.688.224-.688.501s.307.501.688.501M83.688 57.129h9.624c.38 0 .688-.224.688-.501s-.307-.501-.688-.501h-9.624c-.38 0-.688.224-.688.501s.307.5.688.5M69.005 54.123v-3.005H70v3.34c0 .369-.305.667-.682.667H55v-1.002h14.005zM54.743 21.74a24.064 24.064 0 0 1 1.095-.276h6.346c1.846 0 3.852 1.795 6.016 5.383 0 2.738.186 7.017 2.919 7.028 2.698.01 4.881 2.264 4.881 5.042v4.473a5.022 5.022 0 0 1-5.016 5.025H54.743V21.74z" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M54.904 46.412h.196v.303a.194.194 0 0 1-.139-.047c-.09-.086-.057-.256-.057-.256" }),
            _react2.default.createElement("path", { fill: "#FAFAFA", d: "M58.387 35.511c0 .893-.705 1.618-1.575 1.618s-1.575-.725-1.575-1.618c0-.894.706-1.618 1.575-1.618.87 0 1.575.724 1.575 1.618" }),
            _react2.default.createElement("path", { fill: "#FFF", d: "M54.743 39.952a4.605 4.605 0 0 0 2.07.504c2.657 0 4.812-2.214 4.812-4.945 0-2.73-2.155-4.946-4.812-4.946-.746 0-1.442.19-2.07.504v-1.973c.283-.096.571-.184.87-.24l.267-1.829c0-.528.417-.957.932-.957.515 0 .93.429.93.957l.269 1.828c.343.065.678.159 1 .277l1.122-1.446a.919.919 0 0 1 1.273-.351c.445.264.599.85.34 1.309l-.658 1.718c.264.231.508.484.734.755l1.674-.677a.918.918 0 0 1 1.273.35.973.973 0 0 1-.342 1.308l-1.408 1.153c.115.332.206.674.27 1.028l1.778.274c.514 0 .933.43.933.958a.946.946 0 0 1-.933.957l-1.778.274a6.957 6.957 0 0 1-.27 1.029l1.408 1.152c.446.264.599.85.342 1.309a.92.92 0 0 1-1.273.35l-1.674-.677a6.72 6.72 0 0 1-.734.753l.658 1.72a.972.972 0 0 1-.34 1.308.92.92 0 0 1-1.273-.35L59.01 41.89a6.439 6.439 0 0 1-1 .277l-.267 1.828c0 .529-.416.958-.93.958a.945.945 0 0 1-.933-.958l-.268-1.828a6.406 6.406 0 0 1-.87-.24v-1.974z" }),
            _react2.default.createElement("path", { fill: "#4D86EC", d: "M44 50.422V36.578c0-.32.284-.578.636-.578H50v15h-5.364c-.352 0-.636-.258-.636-.578" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M49.471 54H44.33c-.237 0-.429-.224-.429-.5s.192-.5.429-.5h5.142c.237 0 .429.224.429.5s-.192.5-.429.5" }),
            _react2.default.createElement("path", { fill: "#4D86EC", d: "M33.565 41h4.87c.312 0 .565.27.565.606v8.788c0 .335-.253.606-.565.606h-4.87c-.312 0-.565-.27-.565-.606v-8.788c0-.335.253-.606.565-.606" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M33.329 53h5.142c.237 0 .429.224.429.5s-.192.5-.429.5H33.33c-.237 0-.429-.224-.429-.5s.192-.5.429-.5" }),
            _react2.default.createElement("path", { fill: "#4D86EC", d: "M22.565 36h4.87c.312 0 .565.258.565.578v13.844c0 .32-.253.578-.565.578h-4.87a.571.571 0 0 1-.565-.578V36.578c0-.32.253-.578.565-.578" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M22.329 53h5.142c.237 0 .429.224.429.5s-.192.5-.429.5H22.33c-.237 0-.429-.224-.429-.5s.192-.5.429-.5" }),
            _react2.default.createElement("path", { fill: "#F6CB0F", d: "M11.565 18h4.87c.312 0 .565.266.565.595v31.81c0 .329-.253.595-.565.595h-4.87c-.312 0-.565-.266-.565-.595v-31.81c0-.329.253-.595.565-.595" }),
            _react2.default.createElement("path", { fill: "#D4D9DF", d: "M11.329 53h5.142c.237 0 .429.224.429.5s-.192.5-.429.5H11.33c-.237 0-.429-.224-.429-.5s.192-.5.429-.5" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M78 69.152l.9-.098V67.22l20.7-.19c.39 0 .441.583.441.19V35.14h4.759v-1.054h-4.96a.707.707 0 0 0-.704.71v31.26l-20.415.173c-.39 0-.721.144-.721.537v2.387z" })
        )
    );
}; /**
    * Created by dotan.l on 11/04/2019.
    */
exports.default = MissingSVGIcon;