import React from 'react';
import PropTypes from 'prop-types';
import withValidations from './withValidations';

const defaultDropdownEvents = { onBlur: { validateEventValue: false }, onChange: { validateEventValue: true } };

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withDropdownValidations = (
    DropdownComponent,
    validations = [],
    validateOnChange,
    validationTriggerEvents = defaultDropdownEvents
) => {
    const ValidationsWrapper = withValidations(DropdownComponent, {
        validations,
        validationTriggerEvents,
        validateOnValueChange: validateOnChange,
    });
    const DropdownWithValidations = ({ id, selectedValueObject, options, ...rest }) => (
        <ValidationsWrapper
            valueToValidate={selectedValueObject && selectedValueObject.value}
            validationId={id}
            validationContext={{ list: options.map(option => option && option.value) }}
            id={id}
            selectedValueObject={selectedValueObject}
            options={options}
            {...rest}
        />
    );

    DropdownWithValidations.propTypes = {
        options: PropTypes.array,
        selectedValueObject: PropTypes.object,
        onValidationChange: PropTypes.func,
        triggerValidation: PropTypes.bool,
        id: PropTypes.string.isRequired,
    };

    return DropdownWithValidations;
};

export default withDropdownValidations;
