import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Textarea } from 'tuui';
import { AlertOIcon, TooltipV2 as Tooltip, INDICATION_TYPES } from 'taboola-ultimate-ui';
import Counter from 'components/Counter';
import { useHover, useFocus } from 'hooks/elementState';
import {
    DESCRIPTION_WARNING_THRESHOLD,
    MAX_DESCRIPTION_LENGTH,
} from '../../../../../../creative-creator/components/ContentField/config';
import styles from './contentDescriptionTextarea.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ContentDescriptionTextarea = ({
    value,
    message,
    indicationType,
    max = MAX_DESCRIPTION_LENGTH,
    ...rest
}) => {
    const [focused, focusHandlers] = useFocus(false);
    const [hovered, hoverHandlers] = useHover(false);

    const shouldRenderCounter = focused || hovered;
    const isError = indicationType === INDICATION_TYPES.ERROR;

    return (
        <div className={classNameBuilder('container', { error: isError })} {...focusHandlers} {...hoverHandlers}>
            <Textarea
                className={styles['text-area-container']}
                textareaClassName={styles['text-area']}
                value={value}
                {...rest}
            />
            {shouldRenderCounter && (
                <Counter
                    className={styles['counter']}
                    value={value.length}
                    max={max}
                    warningThreshold={DESCRIPTION_WARNING_THRESHOLD}
                />
            )}
            {isError && (
                <div className={styles['error-icon-container']}>
                    <AlertOIcon className={styles['error-icon']} />
                    <Tooltip>{message || ''}</Tooltip>
                </div>
            )}
        </div>
    );
};

ContentDescriptionTextarea.propTypes = {
    value: PropTypes.string,
    indicationType: PropTypes.oneOf(Object.values(INDICATION_TYPES)),
    message: PropTypes.node,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    rows: PropTypes.number,
};

ContentDescriptionTextarea.defaultProps = {
    value: '',
};
