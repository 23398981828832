import React from 'react';
import { errorMessagesUtils } from 'services/utils';
import { accountsApi } from '../../../services/api';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="account.fetch.error.highlight" defaultMessage="Unable to fetch account." />,
});

export const fetchAccount =
    ({ accountName }) =>
    async dispatch => {
        try {
            return await accountsApi.getAdvertiser(accountName);
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
        }
    };
