import { useMemo } from 'react';
import { keyBy, last } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { VIDEO_PREVIEW_MODE } from '../../../../common-campaign-form/config/VideoPreviewMode';
import { VIDEO_CREATIVE_CREATION_METHOD } from '../../../../video-creative-creator/config/CreationMethod';

const extensionToMimeType = {
    mp4: 'mp4',
    mov: 'quicktime',
};

const previewModeToSize = {
    [VIDEO_PREVIEW_MODE.MOBILE]: { height: 219, width: 350 },
    [VIDEO_PREVIEW_MODE.DESKTOP]: { height: 394, width: 700 },
};

const creationTypeToDataFieldName = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: 'videoUploadItems',
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VAST]: 'vastTagUrl',
};

const vastUrlVastTemplate = vastUrl => {
    if (!vastUrl) {
        return;
    }

    return `<VAST version="3.0">
   <Ad>
      <Wrapper>
         <AdSystem></AdSystem>
         <VASTAdTagURI><![CDATA[${vastUrl}]]></VASTAdTagURI>
      </Wrapper>
   </Ad>
</VAST>`;
};

const mediaFileVastTemplate = (videoAssets, landingPageUrl, mode) => {
    if (!(videoAssets?.length > 0)) {
        return;
    }
    const videoAssetsDict = keyBy(videoAssets, 'type');
    const videoAssetsUploadedItem = videoAssetsDict[mode];

    if (!videoAssetsUploadedItem) {
        return;
    }
    const { url: videoAssetUrl } = videoAssetsUploadedItem;
    const type = extensionToMimeType[last(videoAssetUrl?.split('.'))];
    const sizes = previewModeToSize[mode];

    if (!sizes) {
        throwCriticalError(new Error(`Preview mode ${mode} is unknown, no size configuration exists for it.`));
        return;
    }

    return `<VAST version="2.0">
   <Ad>
      <InLine>
         <AdSystem></AdSystem>
         <AdTitle></AdTitle>
         <Creatives>
            <Creative>
               <Linear>
                  <MediaFiles>
                     <MediaFile delivery="progressive" type="video/${type}" bitrate="464" width="${
        sizes?.width
    }" height="${sizes?.height}"><![CDATA[${videoAssetUrl}]]></MediaFile>
                  </MediaFiles>
                  ${
                      landingPageUrl
                          ? `<VideoClicks>
                  <ClickThrough><![CDATA[${landingPageUrl}]]></ClickThrough>
               </VideoClicks>`
                          : ''
                  }
               </Linear>
            </Creative>
         </Creatives>
      </InLine>
   </Ad>
</VAST>`;
};

export const useVastForPreview = mode => {
    const { value: creationType } = useFormFieldValue({
        field: 'mediaType',
    });
    const fieldName = creationTypeToDataFieldName[creationType];
    const { value: creativeData } = useFormFieldValue({
        field: fieldName,
    });
    const { value: landingPageUrl } = useFormFieldValue({
        field: 'landingPageUrl',
    });
    return useMemo(
        () =>
            creationType === VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO
                ? mediaFileVastTemplate(creativeData, landingPageUrl, mode, creationType)
                : vastUrlVastTemplate(creativeData),
        [creativeData, landingPageUrl, mode, creationType]
    );
};
