import React from 'react';

export const EmptyVideoIcon = () => (
    <svg width="80" height="83" viewBox="0 0 80 83" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1302_90065)">
            <rect x="0.5" y="0.5" width="79" height="82" rx="5.5" stroke="#9CA8B5" />
            <rect x="5" y="53" width="62" height="4" rx="2" fill="#B8C1CA" />
            <rect x="5" y="61" width="30" height="4" rx="2" fill="#B8C1CA" />
            <rect x="5" y="69" width="16" height="4" rx="2" fill="#B8C1CA" />
            <rect x="5" y="5" width="70" height="43" rx="4" fill="#EEF0F2" />
            <path d="M13.6571 4.99983L16.9621 12.7025L11.7686 12.7023L8.4636 4.99963L13.6571 4.99983Z" fill="#9CA8B5" />
            <path
                d="M22.8639 4.99983L26.1689 12.7025L20.9754 12.7023L17.6704 4.99963L22.8639 4.99983Z"
                fill="#9CA8B5"
            />
            <path
                d="M32.3067 4.99983L35.6117 12.7025L30.4182 12.7023L27.1132 4.99963L32.3067 4.99983Z"
                fill="#9CA8B5"
            />
            <path
                d="M51.1923 4.99983L54.4973 12.7025L49.3038 12.7023L45.9988 4.99963L51.1923 4.99983Z"
                fill="#9CA8B5"
            />
            <path d="M41.7495 4.99983L45.0545 12.7025L39.861 12.7023L36.556 4.99963L41.7495 4.99983Z" fill="#9CA8B5" />
            <path
                d="M60.6351 4.99983L63.9401 12.7025L58.7466 12.7023L55.4416 4.99963L60.6351 4.99983Z"
                fill="#9CA8B5"
            />
            <path
                d="M70.0779 4.99983L73.3829 12.7025L68.1894 12.7023L64.8844 4.99963L70.0779 4.99983Z"
                fill="#9CA8B5"
            />
            <path
                d="M48.25 27.3349C49.9167 28.2972 49.9167 30.7028 48.25 31.6651L38.5 37.2942C36.8333 38.2565 34.75 37.0537 34.75 35.1292L34.75 23.8708C34.75 21.9463 36.8333 20.7435 38.5 21.7058L48.25 27.3349Z"
                fill="#9CA8B5"
            />
        </g>
        <defs>
            <clipPath id="clip0_1302_90065">
                <rect width="80" height="83" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
