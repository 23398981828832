import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const CATEGORY_REPORT_MISSING_DATA_START_DATE = Date.parse('2021-03-16');
export const CATEGORY_REPORT_MISSING_DATA_END_DATE = Date.parse('2021-03-22');

export const getCategoryReportMissingDataIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.CATEGORY_REPORT_MISSING_DATA,
    type: INDICATION_TYPES.INFO,
    iconTypeOverride: INDICATION_TYPES.INFO,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.categoryReportMissingData.highlight"
            defaultMessage="Missing Data."
        />
    ),
    message: (
        <FormattedMessage
            id="app.campaign.indication.categoryReportMissingData.message"
            defaultMessage="There is no data from March 16-21st"
        />
    ),
});
