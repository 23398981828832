import { campaignsApi } from '../../../services/api';
import { fetchCampaignError, fetchCampaignSuccess, requestFetchCampaign } from '../actions';
import { fromGWtransformers } from './flowsUtils';

export const fetchCampaign =
    (accountId, campaignId, entityType, { hideError } = {}) =>
    async dispatch => {
        dispatch(requestFetchCampaign());

        try {
            let campaign = await campaignsApi.getCampaign({
                accountId,
                campaignId,
                entityType,
            });
            campaign = fromGWtransformers[entityType](campaign);

            dispatch(fetchCampaignSuccess(campaign));

            return campaign;
        } catch (e) {
            if (!hideError) {
                dispatch(fetchCampaignError());
            }
        }
    };
