const FIELDS = {
    TITLE: 'title',
    URL: 'url',
    DESCRIPTION: 'description',
    CTA: 'cta',
    THUMBNAIL_URL: 'thumbnailUrl',
    VIDEO_URL: 'videoUrl',
    THIRD_PARTY_TAGS: 'thirdPartyTags',
};

export default FIELDS;
