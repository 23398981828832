import React from 'react';
import { Textarea } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from '../commonEditor.module.scss';

const AdditionalRequests = () => {
    const { value: comments, onChange } = useFormFieldValue({ field: 'comments' });
    const changeHandler = useEventValueHandler(onChange);

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'campaign.editor.comments.placeholder',
        defaultMessage: 'Type your request here',
    });

    return (
        <FormField
            inputId="additional-requests"
            containerClass={styles['input']}
            label={
                <FormattedMessage id="campaign.editor.additional.requests.title" defaultMessage="Additional Requests" />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.additional.requests.description"
                    defaultMessage="Your campaign will start running once your account manager has reviewed your request."
                />
            }
        >
            <Textarea
                value={comments}
                placeholder={placeholder}
                onChange={changeHandler}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="AdditionalRequests"
                rows={3}
            />
        </FormField>
    );
};

export default AdditionalRequests;
