import { AUDIENCE_TYPE } from '../config';

const buildConditionFromGW = conditions => {
    return {
        predicate: conditions.predicate,
        url: conditions.value,
    };
};

const buildConditionsArrayFromGW = conditions => {
    if (!conditions.children || conditions.children.length === 0) {
        return [buildConditionFromGW(conditions)];
    }
    if (conditions.children.length > 0) {
        return conditions.children.map(c => buildConditionFromGW(c));
    }
};

const buildUrlConditionToGW = conditions => {
    return {
        property: 'URL',
        predicate: conditions.predicate,
        value: conditions.url,
        children: [],
    };
};

const buildConditionsWithParentOrToGW = conditions => {
    const children = conditions.map(condition => buildUrlConditionToGW(condition));
    return {
        predicate: 'OR',
        children,
    };
};

const buildConditionToGW = audience => {
    if (audience.type !== AUDIENCE_TYPE.BASIC || !audience.conditions || audience.conditions.length < 1) {
        return {};
    }
    if (audience.conditions.length === 1) {
        return buildUrlConditionToGW(audience.conditions[0]);
    }
    return buildConditionsWithParentOrToGW(audience.conditions);
};

export const pixelBasedConditionsTransformerFromGW = audience => {
    const conditions = buildConditionsArrayFromGW(audience.conditions);

    return {
        ...audience,
        conditions,
    };
};

export const pixelBasedConditionsTransformerToGW = audience => {
    if (audience.conditions === undefined) {
        return audience;
    }

    const conditions = buildConditionToGW(audience);
    return {
        ...audience,
        conditions,
    };
};
