import React from 'react';

const PreviewWidgetsGalleryEmptySVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="83" viewBox="0 0 80 83">
        <g fill="none" fillRule="evenodd">
            <path fill="#EEF0F2" d="M11 7h57a4 4 0 0 1 4 4v33a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V11a4 4 0 0 1 4-4z" />
            <path
                fill="#B8C1CA"
                fillRule="nonzero"
                d="M6 0h68a6 6 0 0 1 6 6v71a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 2a4 4 0 0 0-4 4v71a4 4 0 0 0 4 4h68a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6zm2 53.5c0-1.38 1.128-2.5 2.496-2.5h49.008A2.492 2.492 0 0 1 62 55.5c0 1.38-1.128 2.5-2.496 2.5H10.496A2.492 2.492 0 0 1 8 55.5zm0 9a2.5 2.5 0 0 1 2.51-2.5h22.98a2.5 2.5 0 1 1 0 5H10.51A2.499 2.499 0 0 1 8 64.5zM8 73c0-1.105.893-2 1.992-2h11.016c1.1 0 1.992.888 1.992 2 0 1.105-.893 2-1.992 2H9.992C8.892 75 8 74.112 8 73zm22-55a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm3.949 16.38l16.193-16.225a3.942 3.942 0 0 1 5.565 0l16.309 16.34c.007 1.744.006 4.908-.005 9.493a4 4 0 0 1-4 3.99H47.979L33.949 34.38zM7.077 43.108L20.665 30.07c1.416-1.358 3.728-1.353 5.137.013l5.394 5.23 13.068 12.664H11.001a4 4 0 0 1-3.937-4.063l.013-.807z"
            />
        </g>
    </svg>
);

export default PreviewWidgetsGalleryEmptySVGIcon;
