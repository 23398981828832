import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { invert, keyBy, mapValues, values } from 'lodash';
import { baseReportDataSelector } from '../../../../../selectors';
import { generateGraphMetricDef } from '../../../utils/generateGraphMetricDef';

export const useFormattedLegendMetrics = ({ currency, locale, config, metrics, selectedLegendDropdownMetric }) => {
    const { metrics: metricsConfig, getGridDataDependentFilters } = config;
    const metricsConfigMap = useMemo(() => keyBy(metricsConfig, 'field'), [metricsConfig]);
    const reportData = useSelector(baseReportDataSelector);

    const formattedMetrics = useMemo(() => {
        const metricConfig = metricsConfigMap[selectedLegendDropdownMetric.field];
        const additionalMetricData = generateGraphMetricDef(metricConfig, locale, currency);
        const finalMetrics = metrics.map(metric => ({ ...additionalMetricData, ...metric }));
        const filterValues = values(getGridDataDependentFilters(reportData)).flat();
        const filterSortIndex = mapValues(invert(filterValues), value => +value);

        finalMetrics.sort((a, b) => filterSortIndex[a.name] - filterSortIndex[b.name]);

        return finalMetrics;
    }, [
        currency,
        locale,
        metrics,
        metricsConfigMap,
        selectedLegendDropdownMetric,
        getGridDataDependentFilters,
        reportData,
    ]);

    return formattedMetrics;
};
