import { createSelector } from 'reselect';
import { campaignsStateSelector } from '../../../../../selectors/baseSelectors';

export const contentMethodSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.contentMethod
);

export const shouldShowRemoveVariationModalSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.shouldShowRemoveVariationModal
);

export const shouldCrawlOneByOneUrlsSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.shouldCrawlOneByOneUrls
);
