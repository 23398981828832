"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSave(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M8 6H6v12h12V9.127a1 1 0 0 0-.247-.658L16 6.466V10a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V6zM6 4h9.139a3 3 0 0 1 2.257 1.024l1.862 2.128A3 3 0 0 1 20 9.127V18a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm4 5h4V6h-4v3zm-1 4h6a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2z"
            })
        )
    );
}
exports.default = SvgSave;