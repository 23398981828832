import { useSelector } from 'react-redux';
import encodeurl from 'encodeurl';
import { chain } from 'lodash';
import useServerCtaData from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/hooks/useServerCtaData';
import { aiTabImagePromptMapSelector } from 'modules/campaigns/modules/creative-editor';
import { reduceAndSortObjectKeys } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { CONTENT_METHODS } from '../config';
import { bulkCreateCreatives } from '../flows';
import { sendVariationsMetrics } from '../services/sendVariationsMetrics';
import { generateCreativeName } from '../services/utils/generateCreativeName';

export const useSaveVariationsCarouselHandler = (accountId, data, dispatch, onSuccess) => {
    const {
        thumbnails: thumbnailList,
        creativePreviews: variationsCreativePreviews,
        aiVariationsCreativePreviews,
        recommendationId,
    } = data;

    const ctaData = useServerCtaData();
    const aiImagesPromptData = useSelector(aiTabImagePromptMapSelector);

    const getActivePreviews = creativePreviewList =>
        chain(creativePreviewList)
            .values()
            .map(previews => previews.filter(preview => !preview.deleted))
            .flatten()
            .value();

    return ({ contentMethod }) => {
        const { campaigns, content: variationsContent, aiVariationsContent } = data;
        const creativePreviews =
            contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsCreativePreviews : variationsCreativePreviews;

        const creativePreviewList = reduceAndSortObjectKeys(creativePreviews);
        const content = contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsContent : variationsContent;
        const activePreviews = getActivePreviews(creativePreviewList);
        const encodedActivePreviews = activePreviews.map(({ url, ...rest }) => ({
            url: encodeurl(url),
            ...rest,
        }));
        const { title, url, thumbnailUrl } = encodedActivePreviews[0];

        const activeCreatives = {
            title,
            url,
            thumbnailUrl,
            type: 'ITEM_WITH_HIERARCHY',
            hierarchyData: {
                hierarchyChildren: encodedActivePreviews.map(
                    (
                        {
                            title,
                            thumbnailUrl,
                            description,
                            cta,
                            url,
                            appInstall,
                            rating,
                            logo,
                            creativeFocus,
                            creativeCrop,
                            thirdPartyTags,
                            creativeName,
                            customId,
                        },
                        index
                    ) => ({
                        title,
                        thumbnailUrl,
                        description,
                        cta,
                        url,
                        creativeFocus,
                        appInstall,
                        rating,
                        logo,
                        creativeCrop,
                        thirdPartyTags,
                        creativeName: generateCreativeName(activePreviews, creativeName, index),
                        customId,
                    })
                ),
            },
        };

        sendVariationsMetrics({
            content,
            thumbnailList,
            campaigns,
        });

        return dispatch(
            bulkCreateCreatives({
                accountId,
                selectedCampaigns: campaigns,
                creatives: [activeCreatives],
                ctaData,
                encodedActivePreviews,
                aiImagesPromptData,
                recommendationId,
                onSuccess,
            })
        );
    };
};
