import React, { useState } from 'react';
import { TouchAppIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage/useLocalStorage.js';
import { PopupTooltip } from '../PopupTooltip/PopupTooltip';

export const NewUserPopupTooltip = ({
    popupOrigin,
    tooltipTitle = <FormattedMessage id="popup.tooltip.default.title" defaultMessage="New!" />,
    tooltipIcon = <TouchAppIcon />,
    tooltipSubtitle = <FormattedMessage id="popup.tooltip.default.subtitle" defaultMessage="Hover & click" />,
    tooltipBodyText,
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const [getLocalStorageValue, setLocalStorageValue] = useLocalStorage();
    const localStorageKey = `${popupOrigin}PopupClosed`;

    const onClick = () => {
        setIsPopupOpen(false);
        setLocalStorageValue(localStorageKey, true);
    };

    const isPopupEnabled = isPopupOpen && !getLocalStorageValue(localStorageKey);

    return (
        <PopupTooltip
            isTooltipOpen={isPopupEnabled}
            onClick={onClick}
            tooltipTitle={tooltipTitle}
            tooltipIcon={tooltipIcon}
            tooltipSubtitle={tooltipSubtitle}
            tooltipBodyText={tooltipBodyText}
        />
    );
};
