import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import styles from './loadingAnimation.scss';

const classNameBuilder = classNames.bind(styles);

export const LoadingAnimation = ({ animaSizePx, label, className }) => (
    <div className={classNameBuilder('moon-loader-container', className)}>
        <Spinner size={animaSizePx} />
        {label && <div className={classNameBuilder('loading-label')}>{label}</div>}
    </div>
);

LoadingAnimation.propTypes = {
    /** Animation size in PX */
    animaSizePx: PropTypes.number,
    /** Text Label below the loading animation */
    label: PropTypes.node,
    /** Class added to the container */
    className: PropTypes.string,
};

LoadingAnimation.defaultProps = {
    animaSizePx: 20,
    className: '',
};
