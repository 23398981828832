import {
    MIN_THUMBNAIL_WIDTH,
    MIN_THUMBNAIL_HEIGHT,
    MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID,
    THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
} from '../../../campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import ValidationError from '../../../errors/ValidationError';

const validateImage = src =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            if (img.width >= MIN_THUMBNAIL_WIDTH && img.height >= MIN_THUMBNAIL_HEIGHT) {
                resolve(src);
            } else {
                reject(
                    new ValidationError(
                        'Minimum thumbnail size validation failed',
                        MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID
                    )
                );
            }
        };
        img.onerror = () =>
            reject(new ValidationError('Image failed to load', THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID));
        img.src = src;
    });

export default validateImage;
