import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withIndication } from '../../../errors';
import { FileSelect } from '../FileSelect/FileSelect';
import { validateSingleSelectedFile } from './utils/uploadUtils';

const IndicationContent = withIndication(() => <></>);

export const BaseUploader = ({
    isLoading,
    file,
    fileTypes,
    dragAndDropDescription,
    failedValidationData,
    onSelectFile,
    onRemoveFile,
    containerClassName,
    multipleFilesIndication,
    wrongFormatIndication,
}) => {
    const dispatch = useDispatch();

    const handleFileDrop = useCallback(
        async (acceptedFiles, rejectedFiles) => {
            if (
                validateSingleSelectedFile({
                    acceptedFiles,
                    rejectedFiles,
                    fileTypes,
                    dispatch,
                    multipleFilesIndication,
                    wrongFormatIndication,
                })
            ) {
                onSelectFile(acceptedFiles, rejectedFiles);
            }
        },
        [fileTypes, dispatch, multipleFilesIndication, wrongFormatIndication, onSelectFile]
    );

    return (
        <div className={containerClassName}>
            <FileSelect
                isLoading={isLoading}
                file={file}
                fileTypes={fileTypes}
                isMultipleFilesAllowed={false}
                description={dragAndDropDescription}
                onDrop={handleFileDrop}
                onRemove={onRemoveFile}
            />
            <IndicationContent {...failedValidationData} />
        </div>
    );
};
