import { createSelector } from 'reselect';
import { campaignsGroupStateSelector, campaignsGroupMapSelector } from './baseCampaignsGroupSelectors';

export const editedCampaignsGroupSelector = createSelector(
    campaignsGroupStateSelector,
    campaignsGroupMapSelector,
    (campaignsGroupState, campaignsGroupMap) =>
        (campaignsGroupState.campaignsGroupBeingEdited === null
            ? null
            : campaignsGroupMap[campaignsGroupState.campaignsGroupBeingEdited]) || null
);
