import React, { forwardRef } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { BidInput } from '../../Bid/BidInput';

export const BidFormField = forwardRef(
    ({ containerClass, label, labelClass, description, descriptionClass, helpText, ...rest }, ref) => {
        return (
            <FormField
                inputId="bid"
                containerClass={containerClass}
                label={label}
                labelClass={labelClass}
                description={description}
                descriptionClass={descriptionClass}
                helpText={helpText}
                ref={ref}
            >
                <BidInput {...rest} />
            </FormField>
        );
    }
);
