import { useCallback, useState } from 'react';
import { Button, ChevronLeftOutlinedIcon, ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { FormButtons } from 'modules/campaigns/components';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ConnectShopify } from 'modules/tracking/components/ConnectShopify/ConnectShopify';
import { TrackingFormBreadcrumbs } from 'modules/tracking/components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import { TrackingShopifySetup } from 'modules/tracking/components/TrackingShopifySetup/TrackingShopifySetup';
import { useShopifyCreatorRulesState } from 'modules/tracking/hooks/useShopifyCreatorRulesState';
import { ShopifySetupHeader } from '../../components/ShopifySetupHeader/ShopifySetupHeader';
import styles from './shopifySetupPage.module.scss';

const ShopifySetupPage = ({ onCancel }) => {
    const { step, handleSetStep, nextStep, prevStep, submit, wizardTemplateColumns } = useShopifyCreatorRulesState();
    const [showLoading, setLoading] = useState(false);

    const onSubmitHandler = useCallback(() => {
        setLoading(true);
        submit();
    }, [submit]);

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage id="tracking.setup.shopify.set.up" defaultmessage="Set up Taboola Pixel" />
                    }
                    id="shopify-setup"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.setup.shopify.integration"
                        defaultMessage="Shopify Integration"
                    />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <ShopifySetupHeader />
                            <TrackingShopifySetup />
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.continue" />}
                                submitIcon={<ChevronRightOutlinedIcon />}
                                submitDataMetricAttrs={{
                                    'data-metrics-component': 'ClickContinue',
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                submitButtonType="submit"
                                className={styles['form-buttons']}
                            />
                        </div>
                    </div>
                </WizardStep>
                <WizardStep
                    label={<FormattedMessage id="tracking.setup.shopify.connect" defaultMessage="Connect to Shopify" />}
                    id="taboola-pixel-on-shopify-setup"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.setup.shopify.integration"
                        defaultMessage="Shopify Integration"
                    />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <ShopifySetupHeader />
                            <ConnectShopify showLoading={showLoading} />
                            <div className={styles['buttons-wrapper']}>
                                <Button
                                    onClick={prevStep}
                                    size={Button.size.md}
                                    variant={Button.variant.ghost}
                                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                                    data-metrics-component="BackButton"
                                >
                                    <ChevronLeftOutlinedIcon />
                                    <FormattedMessage id="app.actionButtons.back" />
                                </Button>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={onSubmitHandler}
                                    onCancel={onCancel}
                                    submitLabel={<FormattedMessage id="app.actionButtons.connect.shopify" />}
                                    submitIcon={<ChevronRightOutlinedIcon />}
                                    submitDataMetricsComponent="connectShopify"
                                    cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                />
                            </div>
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ShopifySetupPageWithFormDataProvider = withFormDataProvider(ShopifySetupPage, {
    defaultValuesConfig: {},
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});
export { ShopifySetupPageWithFormDataProvider as ShopifySetupPage };
