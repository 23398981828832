import React from 'react';
import { NavLink } from 'react-router-dom';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker/config';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { MODULE_NAME as BILLING_AND_PAYMENTS_MODULE } from '../../config/routes';
import styles from './billingNavigationItem.module.scss';

const BillingNavigationItem = ({ Icon, messageId, defaultMessage, path }) => {
    return (
        <div className={styles['link-wrapper']}>
            <NavLink
                className={styles['container']}
                activeClassName={styles['selected']}
                to={path}
                exact
                tabIndex="-1"
                role="menuitem"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={BILLING_AND_PAYMENTS_MODULE}
            >
                <Icon className={styles['icon']} />
                <div className={styles['text']}>
                    <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
                </div>
            </NavLink>
        </div>
    );
};

export default BillingNavigationItem;
