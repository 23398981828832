import { useQuery } from 'react-query';
import { useAppDataApi } from '../../../../../../services/api/appDataApi';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';

export const useAppData = () => {
    const { value } = useFormFieldValue({ field: 'appUrl' });
    const { getAppData } = useAppDataApi();
    const { data = {} } = useQuery([value, 'appUrl'], () => getAppData(value), {
        enabled: !!value,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        staleTime: Infinity,
        retry: false,
    });

    return { data };
};
