import { FormField } from 'taboola-ultimate-ui';
import { OutlineEditableText } from '../../../campaigns/components/OutlineEditableText/OutlineEditableText';
import { withIndication } from '../../../errors';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from '../../../taboola-common-frontend-modules/validations';
import styles from './PerformanceRulesExcludeNewCampaigns.module.scss';

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);

const validations = [
    {
        validationFn: validationFunctions.range,
        options: { min: 0 },
        messageId: 'app.performance.rules.setup.min.campaign.age.validate',
        defaultMessage: 'Please enter a positive number of days.',
    },
];

export const PerformanceRulesExcludeMinAge = () => {
    const {
        value: minCampaignAgeInDays,
        onChange: onCampaignAgeChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'minCampaignAgeInDays',
        validations,
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `app.performance.rules.min.campaign.age.placeholder`,
        defaultMessage: 'Number of days',
    });

    return (
        <FormField
            inputId="performance-rules-min-campaign-age-input"
            descriptionClass={styles['description']}
            description={
                <FormattedMessage
                    id="app.performance.rules.setup.min.campaign.age.description"
                    defaultMessage="Insert minimum days"
                />
            }
        >
            <OutlineEditableTextWithIndication
                className={styles['input-container']}
                id="campaignAge"
                value={minCampaignAgeInDays}
                onChange={onCampaignAgeChange}
                placeholder={placeholder}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="performance-rule-min-campaign-age"
                {...indicationData}
            />
        </FormField>
    );
};
