import classnames from 'classnames/bind';
import { pxToRem } from 'taboola-ultimate-ui';
import styles from './suggestionTextButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SuggestionTextButton = ({
    className,
    onClick,
    disabled,
    metricProps,
    dataTestId,
    icon: Icon,
    iconSize = '13px',
    iconClassName,
    children,
}) => {
    return (
        <div
            className={classNameBuilder(className, 'container', {
                disabled,
            })}
            onClick={onClick}
            data-testid={dataTestId}
            {...metricProps}
        >
            {Icon && <Icon sx={{ fontSize: pxToRem(iconSize) }} className={iconClassName} />}
            {children}
        </div>
    );
};
