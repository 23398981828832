import { useLayoutEffect } from 'react';
import { matchPath } from 'react-router';
import { pick, isFunction, flatten, orderBy, first, isUndefined, isEmpty } from 'lodash';
import { BASE_FORM_ROUTE_PATH_OPTIONALS } from 'config/routes/routeTypes';
import {
    PERSISTENCE_TYPE,
    getFromLocalStorage,
    getQueryParamFromMemory,
} from 'modules/taboola-common-frontend-modules/storage';
import { getLocationSearch, getQueryParam } from './queryParamUtils';

export const getParamOptions = (paramName, { paramNamesMap }) => paramNamesMap[paramName] ?? {};

export const getParamDependentValues = (paramName, metadata) => {
    const { dependencies = {} } = getParamOptions(paramName, metadata);

    const queryParamsValues = (dependencies.queryParams ?? []).reduce((result, paramName) => {
        const paramOptions = getParamOptions(paramName, metadata);
        result[paramName] = getParamValue({ paramName, ...paramOptions }, metadata);
        return result;
    }, {});

    return {
        ...queryParamsValues,
        ...pick(metadata.pathParams, dependencies.pathParams ?? []),
    };
};

export const getParamDefaultValue = (paramName, metadata) => {
    const { defaultValue } = getParamOptions(paramName, metadata);
    if (isFunction(defaultValue)) {
        return defaultValue(getParamDependentValues(paramName, metadata));
    }

    return defaultValue;
};

export const defaultStorageKeyGetter = (paramName, metadata) => {
    const entries = Object.entries(getParamDependentValues(paramName, metadata));
    const keyPrefix = flatten(orderBy(entries, first)).join('_');
    if (isEmpty(keyPrefix)) {
        return paramName;
    }

    return keyPrefix + ':' + paramName;
};

export const getParamValue = (
    {
        search,
        paramName,
        deserializer,
        defaultValue,
        persist,
        dependencies,
        storageKeyGetter = defaultStorageKeyGetter,
    },
    metadata
) => {
    const { deleted } = getParamOptions(paramName, metadata);
    if (deleted) {
        return;
    }

    const queryValue = getQueryParam(search ?? metadata?.getLocationSearch(), paramName, deserializer);
    if (!isUndefined(queryValue)) {
        return queryValue;
    }

    if (!persist) {
        return getParamDefaultValue(paramName, metadata);
    }

    const storageKey = storageKeyGetter(paramName, metadata);
    const storageValue = metadata.getFromStorage(persist, storageKey);

    return storageValue ?? getParamDefaultValue(paramName, metadata);
};

export const getExtendedMetadataWithUnregisteredParamName = ({ paramName, ...rest }, metadata) => {
    const { paramNamesMap } = metadata;
    if (paramNamesMap[paramName]) {
        return metadata;
    }

    return {
        ...metadata,
        paramNamesMap: {
            ...paramNamesMap,
            [paramName]: { paramName, ...rest },
        },
    };
};

/**
 * @deprecated - will be removed after migration of all selectors
 */
let PARAM_NAMES_MAP = {};

/**
 * @deprecated - will be removed after migration of all selectors
 */
export const useSyncParamNamesMap = paramNamesMap => {
    useLayoutEffect(
        function syncParamNamesMap() {
            PARAM_NAMES_MAP = paramNamesMap;
        },
        [paramNamesMap]
    );
};

/**
 * @deprecated - will be removed after migration of all selectors
 */
const getFromStorage = (username, persist, storageKey) => {
    if (persist === PERSISTENCE_TYPE.LOCAL_STORAGE) {
        return getFromLocalStorage(username, storageKey);
    }

    if (persist === PERSISTENCE_TYPE.MEMORY) {
        return getQueryParamFromMemory(storageKey);
    }
};

/**
 * @deprecated - will be removed after migration of all selectors
 */
export const getQueryParamForOptions = ({ search, paramName, username, options = {} }) => {
    const { params: pathParams } = matchPath(window.location.pathname, { path: BASE_FORM_ROUTE_PATH_OPTIONALS }) || {
        params: {},
    };

    const metadata = {
        pathParams,
        paramNamesMap: PARAM_NAMES_MAP,
        getFromStorage: (...args) => getFromStorage(username, ...args),
        getLocationSearch,
    };

    const extendedMetadata = getExtendedMetadataWithUnregisteredParamName({ paramName, ...options }, metadata);

    return getParamValue(
        {
            search: search ?? getLocationSearch(),
            paramName,
            ...options,
        },
        extendedMetadata
    );
};
