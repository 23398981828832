import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { AdvancedSettingsSection } from '../components/PerformanceRuleSetupSection/AdvancedSettingsSection/AdvancedSettingsSection';
import { PerformanceRulesConditionAndActionSection } from '../components/PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/PerformanceRulesConditionAndActionSection';
import { PerformanceRulesSetupSection } from '../components/PerformanceRuleSetupSection/PerformanceRulesSetupSection';
import { PERFORMANCE_RULES_EDIT_ROUTE_PATH } from '../config';
import { usePerformanceRulesEditorState } from '../hooks/usePerformanceRulesEditorState';
import styles from './PerformanceRulesEditPage.module.scss';

const PerformanceRulesEditPage = ({ onCancel }) => {
    const { submit } = usePerformanceRulesEditorState();
    const { isSubmitDisabled, submitInProgress } = useFormDataContext();
    useUnsavedChangesController(PERFORMANCE_RULES_EDIT_ROUTE_PATH);

    return (
        <FormInitBase className={styles['wrapper']}>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                header={
                    <div className={styles['subheader']}>
                        <FormattedMessage
                            id="creative.editor.duplicate.bulk.subhea2der"
                            tagName="h1"
                            defaultMessage="Automated rule setup"
                        />
                    </div>
                }
            >
                <div className={styles['form-wrapper']}>
                    <PerformanceRulesSetupSection />
                    <PerformanceRulesConditionAndActionSection />
                    <AdvancedSettingsSection />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};
const PerformanceRulesEditorWithFormDataProvider = withFormDataProvider(PerformanceRulesEditPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { PerformanceRulesEditorWithFormDataProvider as PerformanceRulesEditPage };
