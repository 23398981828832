'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getSingleDomain = exports.calcDomainByValues = exports.getAllPickedValues = exports.getSelectedEdgeNames = undefined;

var _lodash = require('lodash.isnil');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.pick');

var _lodash4 = _interopRequireDefault(_lodash3);

var _memoizeOne = require('memoize-one');

var _memoizeOne2 = _interopRequireDefault(_memoizeOne);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var getSelectedEdgeNames = exports.getSelectedEdgeNames = function getSelectedEdgeNames(edges) {
    return Object.keys(edges).filter(function (key) {
        return edges[key].selected;
    });
};

var getAllPickedValues = exports.getAllPickedValues = function getAllPickedValues(values, pointNames) {
    return values.map(function (point) {
        return Object.values((0, _lodash4.default)(point, pointNames));
    }).flat().filter(function (value) {
        return !(0, _lodash2.default)(value);
    });
};

var calcDomainByValues = exports.calcDomainByValues = function calcDomainByValues(allValues) {
    return [Math.min.apply(Math, [0].concat(_toConsumableArray(allValues))), Math.max.apply(Math, _toConsumableArray(allValues)) || 'auto'];
};

var getSingleDomain = exports.getSingleDomain = (0, _memoizeOne2.default)(function (edges, previewedEdge, values) {
    var selectedNames = getSelectedEdgeNames(edges);

    if (previewedEdge) {
        selectedNames.push(previewedEdge);
    }

    var allValues = getAllPickedValues(values, selectedNames);
    var domain = calcDomainByValues(allValues);

    return domain;
});