import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { merge } from 'lodash';
import { useDropdownOptions } from '../../../../../hooks';
import { errorMessagesUtils } from '../../../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { useAccount } from '../../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { AddFundsContextToPaymentContext } from '../../../config/AddFundsContext';
import { PAYMENT_ACTION } from '../../../config/PaymentAction';
import { PAYMENT_TYPE, PUSH_PAYMENT_TYPES } from '../../../config/PaymentType';
import { useBillingData } from '../../../hooks/useBillingData';
import { BRAND_TYPE, BrandMark, LOGO_TYPE } from '../../BrandMark/BrandMark';
import DropDownSelectionOption from '../DropDownSelectionOption';

const paymentMethodTransformers = {
    [PAYMENT_TYPE.CARD]: method => {
        const { cardHolderName, brand, lastDigits } = method;
        return {
            type: PAYMENT_TYPE.CARD,
            value: method,
            label: (
                <DropDownSelectionOption
                    logo={<BrandMark type={LOGO_TYPE.SMALL_LOGO} brand={brand} />}
                    text={`${cardHolderName} (•••• ${lastDigits})`}
                />
            ),
        };
    },
    [PAYMENT_TYPE.HOSTED_INVOICE]: () => {
        return {
            type: PAYMENT_TYPE.HOSTED_INVOICE,
            value: PAYMENT_TYPE.HOSTED_INVOICE,
            label: (
                <DropDownSelectionOption
                    logo={<BrandMark type={LOGO_TYPE.SMALL_LOGO} brand={BRAND_TYPE.BOLETO} />}
                    text={
                        <FormattedMessage
                            id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.HOSTED_INVOICE}.dropdown.label`}
                        />
                    }
                />
            ),
        };
    },
    [PAYMENT_TYPE.BANK_TRANSFER]: ({ currency }) => {
        return {
            type: PAYMENT_TYPE.BANK_TRANSFER,
            value: PAYMENT_TYPE.BANK_TRANSFER,
            label: (
                <DropDownSelectionOption
                    text={
                        <FormattedMessage
                            id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.dropdown_beta.label`}
                            values={{ currency }}
                        />
                    }
                />
            ),
        };
    },
    [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: method => {
        const { accountHolderName, accountNumber } = method;
        return {
            type: PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT,
            value: method,
            label: (
                <DropDownSelectionOption
                    logo={<BrandMark type={LOGO_TYPE.ICON} brand={BRAND_TYPE.ACH} />}
                    text={`${accountHolderName} (${accountNumber})`}
                />
            ),
        };
    },
};

export const paymentMethodsTransformer = (paymentMethods = []) => {
    return paymentMethods.map(method => paymentMethodTransformers[method.paymentMethodType](method));
};

export const generateRefundsErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="billingAndPayments.modal.refund.error" defaultMessage="An error occurred" />,
});

export const generateRefundsSuccessIndication = () => ({
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    message: <FormattedMessage id="billingAndPayments.modal.refund.success" />,
    type: INDICATION_TYPES.SUCCESS,
});

export const generateAddFundsErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="billingAndPayments.modal.addFunds.error" defaultMessage="An error occurred" />,
});

export const generateAddFundsSuccessIndication = ({ paymentContext, msgParams }) => ({
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    message: <FormattedMessage id={`billingAndPayments.modal.addFunds.${paymentContext}.success`} values={msgParams} />,
    type: INDICATION_TYPES.SUCCESS,
});

export const usePaymentMethods = ({ financialRepEmail }) => {
    const dispatch = useDispatch();
    const { currency } = useAccount();
    const {
        billingData: { allowedPrepaymentMethodsV2, paymentMethodsV2 },
    } = useBillingData();

    const onAddFundsSuccessCommonHandler = (paymentContext, financialRepEmail) => {
        dispatch(
            addIndication(
                generateAddFundsSuccessIndication({
                    paymentContext,
                    msgParams: { email: financialRepEmail },
                })
            )
        );
        return { paymentContext, status: 'success', data: {} };
    };

    const onAddFundsFailureCommonHandler = (paymentType, error) => {
        dispatch(addIndication(generateAddFundsErrorIndication(error)));

        return { paymentType, status: 'failure', data: {} };
    };

    const paymentMethodHandlersMap = new Map([
        [
            PAYMENT_TYPE.CARD,
            {
                onAddFundsSuccess: () => {
                    return onAddFundsSuccessCommonHandler(PAYMENT_TYPE.CARD, financialRepEmail);
                },
                onAddFundsFailure: onAddFundsFailureCommonHandler,
            },
        ],
        [
            PAYMENT_TYPE.HOSTED_INVOICE,
            {
                onAddFundsSuccess: () => {
                    return onAddFundsSuccessCommonHandler(PAYMENT_TYPE.HOSTED_INVOICE, financialRepEmail);
                },
                onAddFundsFailure: onAddFundsFailureCommonHandler,
            },
        ],
        [
            PAYMENT_TYPE.BANK_TRANSFER,
            {
                onAddFundsSuccess: (response, addFundsContext) => {
                    const paymentContext = AddFundsContextToPaymentContext[addFundsContext];
                    const result = onAddFundsSuccessCommonHandler(paymentContext, financialRepEmail);
                    return merge(result, { data: response?.bankTransfer });
                },
                onAddFundsFailure: onAddFundsFailureCommonHandler,
            },
        ],
        [
            PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT,
            {
                onAddFundsSuccess: () => {
                    return onAddFundsSuccessCommonHandler(PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT, financialRepEmail);
                },
                onAddFundsFailure: onAddFundsFailureCommonHandler,
            },
        ],
        [
            PAYMENT_ACTION.CASH_BALANCE_REFUND,
            {
                onRefundSuccess: () => {
                    dispatch(addIndication(generateRefundsSuccessIndication()));
                },
                onRefundFailure: error => {
                    dispatch(addIndication(generateRefundsErrorIndication(error)));
                },
            },
        ],
    ]);

    const paymentMethods = useMemo(() => {
        const resolvedPaymentMethods = [];
        allowedPrepaymentMethodsV2?.forEach(paymentMethodType => {
            const paymentMethod = paymentMethodsV2.find(
                paymentsMethod => paymentsMethod.paymentMethodType === paymentMethodType
            );
            const isPushPaymentType = Object.keys(PUSH_PAYMENT_TYPES).includes(paymentMethodType);
            if (paymentMethod || isPushPaymentType) {
                resolvedPaymentMethods.push({ ...paymentMethod, paymentMethodType, currency });
            }
        });
        return resolvedPaymentMethods;
    }, [paymentMethodsV2, allowedPrepaymentMethodsV2, currency]);

    const dropdownOptions = useDropdownOptions(paymentMethods, null, paymentMethodsTransformer);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

    useEffect(() => {
        if (dropdownOptions && dropdownOptions?.length > 0) {
            setSelectedPaymentMethod(dropdownOptions[0]);
        }
    }, [dropdownOptions, setSelectedPaymentMethod]);

    return {
        paymentMethodHandlersMap,
        dropdownOptions,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
    };
};
