import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { PRICING_MODEL } from 'modules/campaigns/config';
import namedIdedObjectToDropdownObjectTransformer from 'modules/campaigns/services/utils/dropdownUtils';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useHighImpactTargetingApi } from 'services/api/highImpactTargeting';
import { usePermissions } from '../../../../../taboola-common-frontend-modules/authentication';
import { NO_HIP_TARGETING_ID, HIGH_IMPACT_PACKAGE_PERMISSION } from '../../config/highImpactPackageOptions';

const FALLBACK_PACKAGES = [];

const useHighImpactPackagesList = ({ accountName, isPermitted }) => {
    const { getHighImpactPackages } = useHighImpactTargetingApi();
    const { data } = useQuery(['highImpactPackages', accountName], () => getHighImpactPackages(accountName), {
        refetchOnWindowFocus: false,
        enabled: isPermitted,
        select: data => data.results,
    });

    return data ?? FALLBACK_PACKAGES;
};

export const useHighImpactTargetingLogic = accountName => {
    const { formatMessage } = useIntl();
    const isPermitted = usePermissions(HIGH_IMPACT_PACKAGE_PERMISSION);
    const { value, onChange: setHighImpactPackageTargeting } = useFormFieldValue({
        field: 'campaignTargeting.highImpactPackageTargetingId',
        isPermitted,
    });
    const highImpactPackage = value ?? NO_HIP_TARGETING_ID;
    const { value: accountNameFromCampaign } = useFormFieldValue({ field: 'accountName' });
    const { value: pricingModel, onChange: setPricingModel } = useFormFieldValue({ field: 'pricingModel' });
    const currentAccountName = accountNameFromCampaign ?? accountName;
    const highImpactPackages = useHighImpactPackagesList({
        isPermitted,
        accountName: currentAccountName,
    });

    const changeHandler = useCallback(
        ({ value: highImpactPackage }) => {
            setHighImpactPackageTargeting(highImpactPackage);
            if (pricingModel === PRICING_MODEL.CPC) {
                setPricingModel(PRICING_MODEL.VCPM);
            }
        },
        [setHighImpactPackageTargeting, pricingModel, setPricingModel]
    );
    const selectedValue = useMemo(() => {
        const selectedOption = highImpactPackages.find(pkg => pkg.id === highImpactPackage);

        return namedIdedObjectToDropdownObjectTransformer(selectedOption);
    }, [highImpactPackage, highImpactPackages]);
    const options = useMemo(
        () =>
            [
                {
                    value: NO_HIP_TARGETING_ID,
                    label: formatMessage({
                        id: 'campaign.editor.highImpactTargeting.placeholder',
                        defaultMessage: 'None',
                    }),
                },
            ].concat(highImpactPackages.map(namedIdedObjectToDropdownObjectTransformer)),
        [highImpactPackages, formatMessage]
    );

    return {
        isPermitted,
        selectedValue,
        options,
        changeHandler,
    };
};
