import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CAMPAIGNS_ROUTE, REPORT_TYPE } from 'modules/campaigns/config';
import { CAMPAIGN_ID } from 'modules/campaigns/hooks';
import { REPORT_ID } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { recommendationsIdSelector } from 'modules/recommendations/selectors';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { useCreativesApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { errorMessagesUtils } from 'services/utils';
import { transformCarouselCardFromGW } from '../transformers/transformCarouselCardFromGW';
import { transformCarouselCardToGW } from '../transformers/transformCarouselCardToGW';

const SUCCESSFUL_PATH = `${CAMPAIGNS_ROUTE}`;

export const generateErrorIndication = (errorObject = {}, id, defaultMessage) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id={id} defaultMessage={defaultMessage} />,
});

const successIndication = {
    message: (
        <FormattedMessage id="creative.creator.carousel.save.success" defaultMessage="Carousel successfully saved." />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const sendGAEvents = () => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        value: `Changing the order of the cards in carousel`,
        component: 'CarouselEditor',
        pageName: getPageName(),
    });
};

export const useCreativeCarouselEditorState = ({ campaignId, hierarchyRepItemId }) => {
    const {
        formAccount: { accountId },
        setFetchStatus,
        initialData: { carouselCards: initialCarouselCards },
    } = useFormDataContext();
    const { value: carouselCards } = useFormFieldValue({ field: 'carouselCards' });
    const dispatch = useDispatch();
    const recommendationId = useSelector(recommendationsIdSelector);
    const creativesApi = useCreativesApi();
    const formDataFetcher = useCallback(async () => {
        try {
            const results = await creativesApi.getCreative(accountId, campaignId, hierarchyRepItemId);
            const hierarchyChildrenArray = results.hierarchyData?.hierarchyChildren || [];

            const carouselCards = hierarchyChildrenArray.map(transformCarouselCardFromGW);
            const filteredCarouselParent = carouselCards.filter(card => card.id !== hierarchyRepItemId);
            return { carouselCards: filteredCarouselParent };
        } catch (error) {
            setFetchStatus(COMPONENT_STATUS.ERROR);
            dispatch(
                addIndication(
                    generateErrorIndication(
                        error,
                        'creative.edit.carousel.edit.fetch.error',
                        'Unable to fetch carousel data.'
                    )
                )
            );
        }
    }, [creativesApi, accountId, campaignId, hierarchyRepItemId, setFetchStatus, dispatch]);

    const { submit } = useFormState({ formDataFetcher });
    const submitHandler = useCallback(async () => {
        const transformedData = transformCarouselCardToGW(carouselCards, initialCarouselCards);
        const saveCarousel = async () => {
            try {
                await creativesApi.updateCreative(
                    accountId,
                    campaignId,
                    hierarchyRepItemId,
                    transformedData,
                    recommendationId
                );
                dispatch(addIndication(successIndication));
                sendGAEvents();
                return true;
            } catch (error) {
                dispatch(
                    addIndication(
                        generateErrorIndication(
                            error,
                            'creative.edit.carousel.edit.save.error',
                            'Unable to save carousel data.'
                        )
                    )
                );
                throw error;
            }
        };
        const successfulUrl = mergeQueryParamsWithLocation(
            { pathname: SUCCESSFUL_PATH },
            {
                [CAMPAIGN_ID]: campaignId,
                [REPORT_ID]: REPORT_TYPE.CREATIVE,
            }
        );

        submit(saveCarousel, successfulUrl);
    }, [
        carouselCards,
        campaignId,
        submit,
        creativesApi,
        accountId,
        hierarchyRepItemId,
        recommendationId,
        dispatch,
        initialCarouselCards,
    ]);

    return { submit: submitHandler };
};
