import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { InternalLink } from '../../../../components';

const Loader = () => (
    <div style={{ width: 50, height: 16 }}>
        <ContentLoader speed={2} style={{ borderRadius: 5 }} />
    </div>
);

const LinkBreadcrumb = ({ className, disabledClassName, children, ...rest }) => {
    const { path } = rest;
    const isDisabled = !path;

    if (!children) {
        return <Loader />;
    }

    return (
        <InternalLink disabled={isDisabled} className={isDisabled ? disabledClassName : className} {...rest}>
            {children}
        </InternalLink>
    );
};

LinkBreadcrumb.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    path: PropTypes.string,
};

export { LinkBreadcrumb };
