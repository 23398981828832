import React from 'react';
import { useAccountAdditionalData } from '../../../../hooks';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import useConfigMatch from '../../../taboola-common-frontend-modules/account-configurations/hooks/useConfigMatch';
import { LOGO_TYPE, BRAND_TYPE, BrandMark } from '../BrandMark/BrandMark';
import styles from './cardBrands.module.scss';

export const CardBrands = () => {
    const { accountAdditionalData: { legalEntity } = {} } = useAccountAdditionalData();
    const { [COMMON_FLAGS.LEGAL_ENTITIES_PARTIAL_CC_BRANDS_SUPPORT]: legalEntitiesPartialCcBrandsSupport } =
        useCommonConfig([COMMON_FLAGS.LEGAL_ENTITIES_PARTIAL_CC_BRANDS_SUPPORT]);
    const allowCartesBancaires = useConfigMatch({}, { [COMMON_FLAGS.ALLOW_CARTES_BANCAIRES]: 'true' });
    const isShowPartialBrands = legalEntitiesPartialCcBrandsSupport?.indexOf(legalEntity) !== -1;

    return (
        <div className={styles['brands-container']}>
            <BrandMark brand={BRAND_TYPE.VISA} type={LOGO_TYPE.ICON} />
            <BrandMark brand={BRAND_TYPE.MASTER_CARD} type={LOGO_TYPE.ICON} />
            {!isShowPartialBrands && <BrandMark brand={BRAND_TYPE.AMERICAN_EXPRESS} type={LOGO_TYPE.ICON} />}
            {!isShowPartialBrands && <BrandMark brand={BRAND_TYPE.DISCOVER} type={LOGO_TYPE.ICON} />}
            {!isShowPartialBrands && <BrandMark brand={BRAND_TYPE.DINERS} type={LOGO_TYPE.ICON} />}
            {!isShowPartialBrands && allowCartesBancaires && (
                <BrandMark brand={BRAND_TYPE.CARTES_BANCAIRES} type={LOGO_TYPE.ICON} />
            )}
        </div>
    );
};
