import { useMemo } from 'react';
import { useSelectedAccountId } from 'hooks';
import { useAccountCache } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useLocale, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useParentAppName } from 'modules/taboola-common-frontend-modules/i18n/hooks/useParentAppName';

export const useExcelBulkCommonMetadata = () => {
    const [locale] = useLocale();
    const parentAppName = useParentAppName();
    const { formatMessage } = useIntl();

    const [queryAccountId] = useSelectedAccountId();
    const getAccount = useAccountCache();
    const accountName = getAccount(queryAccountId)?.accountName;

    const metadata = useMemo(
        () => ({
            locale,
            parentAppName,
            formatMessage,
            accountName,
        }),
        [locale, parentAppName, formatMessage, accountName]
    );

    return metadata;
};
