"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgStatusRejectedPartial(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 25,
            viewBox: "0 0 24 25",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M5.54858 12.3743C5.54858 12.8018 5.20189 13.1486 4.77435 13.1486C4.34683 13.1486 4.00012 12.8019 4.00012 12.3743C4.00012 11.9468 4.34682 11.6001 4.77435 11.6001C5.20187 11.6001 5.54858 11.9468 5.54858 12.3743Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M6.58087 15.9869C6.58087 16.4146 6.23435 16.7612 5.80682 16.7612C5.37912 16.7612 5.03259 16.4147 5.03259 15.9869C5.03259 15.5594 5.37911 15.2129 5.80682 15.2129C6.23434 15.2129 6.58087 15.5594 6.58087 15.9869Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M6.58081 8.76154C6.58081 9.18906 6.23429 9.53558 5.80676 9.53558C5.37906 9.53558 5.03253 9.18907 5.03253 8.76154C5.03253 8.33384 5.37905 7.9873 5.80676 7.9873C6.23428 7.9873 6.58081 8.33382 6.58081 8.76154Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9.16144 6.18097C9.16144 6.60849 8.81492 6.95502 8.38739 6.95502C7.95969 6.95502 7.61316 6.6085 7.61316 6.18097C7.61316 5.75327 7.95968 5.40674 8.38739 5.40674C8.81491 5.40674 9.16144 5.75326 9.16144 6.18097Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M12.0001 4.37451V5.92297C14.305 5.92297 16.4348 7.15264 17.5872 9.14874C18.7398 11.1448 18.7398 13.6042 17.5872 15.6003C16.4347 17.5964 14.3048 18.8261 12.0001 18.8261V20.3745C14.1219 20.3745 16.1567 19.5315 17.6567 18.0313C19.157 16.5311 19.9999 14.4963 19.9999 12.3747C19.9999 10.2531 19.157 8.21811 17.6567 6.71808C16.1565 5.21787 14.1217 4.37488 12.0001 4.37488V4.37451Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9.16144 18.568C9.16144 18.9957 8.81492 19.3422 8.38739 19.3422C7.95969 19.3422 7.61316 18.9957 7.61316 18.568C7.61316 18.1405 7.95968 17.7939 8.38739 17.7939C8.81491 17.7939 9.16144 18.1405 9.16144 18.568Z",
            fill: "currentColor"
        }),
        React.createElement("rect", {
            x: 7.91711,
            y: 11.5635,
            width: 8.20267,
            height: 1.65897,
            rx: 0.829484,
            fill: "currentColor"
        })
    );
}
exports.default = SvgStatusRejectedPartial;