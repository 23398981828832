import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import AudienceFormBreadcrumbs from '../components/AudienceFormBreadcrumbs/AudienceFormBreadcrumbs';
import { MailDomainsPropertiesSection } from '../components/ExternalAudiencePropertiesSection/MailDomainsPropertiesSection/MailDomainsPropertiesSection';
import { ExternalAudiencesSetupSection } from '../components/ExternalAudienceSetupSection/ExternalAudiencesSetupSection';
import { MailDomainsSizeEstimationWidget } from '../components/ExternalAudienceSetupSection/MailDomainsSizeEstimation/MailDomainsSizeEstimationWidget';
import { mrtAudiencesInitialValues } from '../config/externalAudiencesInitialValues';
import { createAudience } from '../flows';
import useAudienceCreatorState from '../hooks/useAudienceCreatorState';
import { useAudienceLongCreationTimeIndication } from '../hooks/useAudienceLongCreationTimeIndication';
import styleConsts from '../../../globalStyle/styleConsts.module.scss';
import styles from './audiencesCreatorPage.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;
const classNameBuilder = classnames.bind(styles);

const MailDomainsAudiencesCreatorPage = ({ onCancel }) => {
    const dispatch = useDispatch();
    const reportId = REPORT_TYPE.FIRST_PARTY;

    const { addIndication, removeIndication, onCancelWrapper } = useAudienceLongCreationTimeIndication(onCancel);

    useEffect(() => {
        addIndication();
    }, [addIndication]);

    const submitAudience = async (audience, accountId) => {
        try {
            const createdAudience = await dispatch(createAudience(accountId, audience, reportId));
            removeIndication();
            return createdAudience;
        } catch (error) {
            throw error;
        }
    };

    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'audience.creator.new.mail.domain.audience',
        defaultMessage: 'New Email Domains Audience',
    });

    const { step, setStep, submit } = useAudienceCreatorState({
        submitAudience,
    });

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="audience.creator.audience.settings.step.label"
                            defaultMessage="Audience Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <AudienceFormBreadcrumbs reportId={reportId} title={title} />
                    <div className={classNameBuilder('form-wrapper', 'widget')}>
                        <div>
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="audience.creator.audience.setup.step.title"
                                        defaultMessage="Audience Setup"
                                    />
                                }
                                id="audience-setup"
                            >
                                <ExternalAudiencesSetupSection />
                            </WizardStep>
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="audience.creator.audience.properties.step.title"
                                        defaultMessage="Audience Properties"
                                    />
                                }
                                id="audience-properties"
                            >
                                <MailDomainsPropertiesSection />
                                <div className={styles['buttons-wrapper']}>
                                    <FormButtons
                                        className={styles['form-buttons']}
                                        onSubmit={submit}
                                        onCancel={onCancelWrapper}
                                        submitLabel={<FormattedMessage id="app.actionButtons.create.audience" />}
                                        submitIcon={<ChevronRightOutlinedIcon />}
                                        submitDataMetricsComponent="NextButton"
                                        cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                    />
                                </div>
                            </WizardStep>
                        </div>
                        <MailDomainsSizeEstimationWidget />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const MailDomainAudiencesCreatorPageWithFormDataProvider = withFormDataProvider(MailDomainsAudiencesCreatorPage, {
    defaultValuesConfig: mrtAudiencesInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { MailDomainAudiencesCreatorPageWithFormDataProvider as MailDomainAudiencesCreatorPage };
