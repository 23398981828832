import { DeletableItem } from 'taboola-ultimate-ui';
import { OkOIcon } from 'taboola-ultimate-ui';
import styles from './campaignGroupsSmartListItem.module.scss';

export const CampaignGroupsSmartListItem = ({ item, deleteItem }) => {
    const handleDelete = () => deleteItem(item);

    return (
        <DeletableItem onDelete={handleDelete}>
            <div className={styles['container']}>
                <OkOIcon className={styles['ok-icon']} />
                {item.label}
            </div>
        </DeletableItem>
    );
};
