import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { FORM_MODES } from '../../../../config/formModes';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from '../../../../config/routes';
import { ACCOUNT_ID_PARAM_TEMPLATE, SCHEDULED_REPORTS_ID_PARAM_TEMPLATE } from './account';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const SCHEDULED_REPORTS_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_ID_PARAM_TEMPLATE}${SCHEDULED_REPORTS_ID_PARAM_TEMPLATE}`;

export const SCHEDULED_REPORTS_CREATOR_ROUTE_PATH = replacePathParams(
    SCHEDULED_REPORTS_ROUTE_PATH,
    {
        entity: [ENTITY.SCHEDULED_REPORT],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const SCHEDULED_REPORTS_EDIT_ROUTE_PATH = replacePathParams(
    SCHEDULED_REPORTS_ROUTE_PATH,
    {
        entity: [ENTITY.SCHEDULED_REPORT],
        mode: [FORM_MODES.EDIT],
    },
    ['rsId'],
    KEY_TO_PATH_MAP_MAP
);
