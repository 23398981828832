import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    DragAndDropFileContext,
    Gallery,
    AspectRatioKeeper,
    GalleryItem,
    TaboolaVideo,
    FallbackImage,
} from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { DropContainer } from 'modules/campaigns/modules/creative-creator/components/DropContainer/DropContainer';
import ThumbnailError from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField/ThumbnailGallery/ThumbnailError/ThumbnailError';
import ThumbnailLoading from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import ExternalError from 'modules/campaigns/modules/creative-creator/components/ThumbnailsPicker/SelectedThumbnails/ExternalError';
import { VideoExternalError } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsPicker/SelectedThumbnails/VideoExternalError';
import { MediaDragAndDrop } from 'modules/campaigns/modules/creative-editor/components/MediaDragAndDrop/MediaDragAndDrop';
import ReplaceImageTooltip from 'modules/campaigns/modules/creative-editor/components/ThumbnailMotionEditor/Body/ReplaceImageTooltip';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './unifiedCreativeEditorBody.module.scss';

const classNameBuilder = classnames.bind(styles);
const FallbackImageError = ({ error }) => (
    <div className={styles['fallback-image']}>
        {error ? (
            <ExternalError error={error} />
        ) : (
            <ThumbnailError
                messageId="creative.creator.thumbnails.fallbackImage.empty"
                defaultMessage="Choose default image"
            />
        )}
    </div>
);

const FallbackImageLoading = () => (
    <div className={styles['fallback-image']}>
        <ThumbnailLoading />
    </div>
);

export const UnifiedCreativeMotionEditorBody = ({
    thumbnailStatus,
    thumbnailError,
    videoStatus,
    videoError,
    onSelectMedia,
    disabled: isBulkEditMotionModeDisabled = false,
    onFileDialogCancel,
    setEditFallbackImageMode,
}) => {
    const { value: thumbnailUrl } = useFormFieldValue({ field: 'thumbnailUrl' });
    const { value: videoUrl } = useFormFieldValue({ field: 'videoUrl' });

    const handleEditFallbackImageClick = openFileDialog => () => {
        // click on replace image link under fb image
        if (isBulkEditMotionModeDisabled) {
            return;
        }

        if (thumbnailStatus === COMPONENT_STATUS.LOADING) {
            return;
        }

        setEditFallbackImageMode(true);
        openFileDialog();
    };

    return (
        <div className={classNameBuilder('creative-editor', { disabled: isBulkEditMotionModeDisabled })}>
            <div className={styles['gallery-container']}>
                <DropContainer>
                    <Gallery gap={styleConsts.galleryGridGap} minRowHeight="155px" columns={2}>
                        <GalleryItem>
                            <AspectRatioKeeper>
                                <TaboolaVideo
                                    className={styles['video']}
                                    src={modifyExternalUrl(videoUrl)}
                                    status={videoStatus}
                                    errorComponent={<VideoExternalError error={videoError} />}
                                    loadingComponent={<ThumbnailLoading />}
                                />
                            </AspectRatioKeeper>
                        </GalleryItem>
                        <GalleryItem>
                            <AspectRatioKeeper>
                                <MediaDragAndDrop
                                    onDrop={onSelectMedia}
                                    onCancel={onFileDialogCancel}
                                    inputId="motion-editor-file-input"
                                >
                                    <DragAndDropFileContext.Consumer>
                                        {({ openFileDialog }) => (
                                            <FallbackImage
                                                imgSrc={modifyExternalUrl(thumbnailUrl)}
                                                loadingComponent={<FallbackImageLoading />}
                                                errorComponent={<FallbackImageError error={thumbnailError} />}
                                                status={thumbnailStatus}
                                                onEditFallbackImageClick={handleEditFallbackImageClick(openFileDialog)}
                                                editText={<ReplaceImageTooltip />}
                                            />
                                        )}
                                    </DragAndDropFileContext.Consumer>
                                </MediaDragAndDrop>
                            </AspectRatioKeeper>
                        </GalleryItem>
                    </Gallery>
                </DropContainer>
            </div>
        </div>
    );
};

UnifiedCreativeMotionEditorBody.propTypes = {
    url: PropTypes.string,
    setUrl: PropTypes.func,
    thumbnailLoading: PropTypes.bool,
    setThumbnailLoading: PropTypes.func,
    videoLoading: PropTypes.bool,
    setVideoLoading: PropTypes.func,
    headerLabel: PropTypes.node,
};
