import noop from 'lodash/noop';
import { addProtocol } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { operationsApi, ERROR_CODES } from 'services/api';
import createCreatives from './createCreatives';

export const pageMetadataCacheKey = 'createOneByOneCreatives:getPageMetadata';

export const convertUrlToPartialCreative = url => ({ url });
export const crawlURL = async (url, accountId, onSuccess, onError) => {
    try {
        const result = await operationsApi.getPageMetadata(accountId, url, {
            singleRequestCacheKey: pageMetadataCacheKey,
            allowMultiRequestCache: true,
        });
        onSuccess(result);
        return result;
    } catch (err) {
        if (err && err.status === ERROR_CODES.ABORT) {
            throw err;
        }

        onError(err);
        return convertUrlToPartialCreative(url);
    }
};

const createOneByOneCreatives =
    ({
        accountId,
        campaign,
        urlList,
        shouldCrawlUrls,
        onCrawlSuccess = noop,
        onCrawlFailure = noop,
        onCreationFailure = noop,
        creativeName,
        customId,
    }) =>
    async dispatch => {
        // convert URLs to creatives
        const sanitizedUrls = urlList.map(url => addProtocol(url));
        const creativeMapping = shouldCrawlUrls ? crawlURL : convertUrlToPartialCreative;

        return Promise.all(sanitizedUrls.map(url => creativeMapping(url, accountId, onCrawlSuccess, onCrawlFailure)))
            .then(creatives => creatives.map(creative => ({ ...creative, creativeName, customId })))
            .then(creatives => dispatch(createCreatives(accountId, campaign, creatives, [], onCreationFailure)))
            .catch(noop);
    };

export default createOneByOneCreatives;
