import React from 'react';
import { INDICATION_TYPES, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CREATIVE_STATUS, REPORT_TYPE } from '../../../config';
import { CREATE_TIME } from '../../campaigns-reports/config/fields';
import { FILTER_TYPE } from '../../campaigns-reports/config/filters';
import { generateReportSort } from '../../campaigns-reports/services';
import { createFilterState } from '../../campaigns-reports/utils/queryFilterUtils';

export const successIndication = {
    message: <FormattedMessage id="creative.editor.duplicate.success" defaultMessage="Ad successfully duplicated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const errorMessage = (
    <FormattedMessage id="creative.editor.duplicate.error" defaultMessage="We were not able to duplicate the ad" />
);

export const postDuplicationReportSort = generateReportSort(
    REPORT_TYPE.CREATIVE,
    CREATE_TIME.field,
    SORTING_ORDER_TYPES.DESCENDANT
);

export const postDuplicationFilter = createFilterState([
    {
        type: FILTER_TYPE.CAMPAIGN_ITEM_STATUS,
        value: CREATIVE_STATUS.RECENT,
        label: 'Recent',
        messageId: 'app.campaigns.creative.status.RECENT',
    },
]);
