import React from 'react';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './creativeStudioBaseHeader.module.scss';

export const CreativeStudioBaseHeader = ({ icon, titleMessageId, subTitleMessageId, helpTooltipMessageId }) => (
    <>
        <div className={styles['header-label']}>
            {icon}
            <FormattedMessage id={titleMessageId} />
        </div>
        <div className={styles['header-sub-label']}>
            <FormattedMessage id={subTitleMessageId} />
            {helpTooltipMessageId && (
                <HelpTooltip position={TOOLTIP_POSITION.BOTTOM}>
                    <FormattedMessage id={helpTooltipMessageId} />
                </HelpTooltip>
            )}
        </div>
    </>
);
