import React, { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import LabelLayoutCellRenderer from './LabelLayoutCellRenderer';

class SpendingLimitCellRenderer extends Component {
    render() {
        const { value, data, ...rest } = this.props;

        const msgIdPrefix = 'app.campaigns.campaign.budget.type';
        const spendingLimitType = get(data, 'budget.spendingLimit.type', null);
        const msgId = spendingLimitType ? `${msgIdPrefix}.${spendingLimitType}` : null;

        return (
            <LabelLayoutCellRenderer msgId={msgId}>
                <div>{value ? <FormattedNumberCellRenderer value={value} {...rest} /> : '-'}</div>
            </LabelLayoutCellRenderer>
        );
    }
}

SpendingLimitCellRenderer.propTypes = {
    /** spending limit value */
    value: PropTypes.number,
    /** data which is injected by the grid - The row data object */
    data: PropTypes.object,
};

export default SpendingLimitCellRenderer;
