import React from 'react';
import { INDICATION_TYPES, STATIC_INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

export const siteReportMSV2Disclaimer = {
    staticIndicationType: STATIC_INDICATION_TYPES.SITE_MSV2_DISCLAIMER,
    type: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.site.msv2.report.disclaimer.highlight"
            defaultMessage="This account is inactive"
        />
    ),
    message: (
        <FormattedMessage
            id="app.campaign.indication.site.msv2.report.disclaimer"
            defaultMessage="- Site report data is available starting November, 2022."
        />
    ),
};
