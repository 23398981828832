import React from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import DoneAndCreateConversionButton from 'modules/tracking/components/DoneAndCreateConversionButton/DoneAndCreateConversionButton';
import { useTrackingSetupFormState } from 'modules/tracking/hooks/useTrackingSetupFormState';
import { TrackingFormBreadcrumbs } from '../../components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import { S2SCampaignSetup } from '../../components/TrackingS2SCampaignSetup/S2SCampaignSetup';
import { S2SIntroduction } from '../../components/TrackingS2SIntrudction/S2SIntroduction';
import { S2STestLink } from '../../components/TrackingS2STestLink/TrackingS2STestLink';
import styles from './s2SSetupPage.module.scss';

const S2SSetupPage = ({ onCancel }) => {
    const { step, handleSetStep, submit, wizardTemplateColumns } = useTrackingSetupFormState();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage id="tracking.setup.s2s.first.step.label" defaultMessage="Server to Server" />
                    }
                    id="server-to-server"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs msgId="tracking.setup.s2s" defaultMessage="Server to server" />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <S2SIntroduction
                                headerMessageId="tracking.setup.s2s.introduction.label"
                                headerDefaultMessage="Server to Server"
                                descriptionMessageId="tracking.setup.s2s.introduction.description"
                                descriptionDefaultMessage="Server to server (S2S) is a common method to track events that the pixel can't capture, such as mobile app install or off-line conversion events."
                            />
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="tracking.setup.s2s.campaign.setup"
                                        defaultMessage="Campaign Setup"
                                    />
                                }
                                id="campaign-setup"
                            />
                            <S2SCampaignSetup />
                            <WizardStep
                                label={<FormattedMessage id="tracking.setup.s2s.test" defaultMessage="Test" />}
                                id="Test"
                            />
                            <S2STestLink />
                            <DoneAndCreateConversionButton
                                onSubmit={onCancel}
                                onSubmitAndCreateConversion={submit}
                                disableSubmit={false}
                                hasBack={false}
                            />
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const S2SSetupPageWithFormDataProvider = withFormDataProvider(S2SSetupPage, {});

export { S2SSetupPageWithFormDataProvider as S2SSetupPage };
