import React from 'react';
import { BareDropdown, CollapsibleList, FormField, PaginatedDropdown } from 'taboola-ultimate-ui';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { CampaignBudgetListItem } from './CampaignBudgetListItem/CampaignBudgetListItem';
import { CampaignItemDropdownOption } from './CampaignItemDropdownOption/CampaignItemDropdownOption';
import { useSharedBudgetCampaignsResources } from './hooks/useSharedBudgetCampaignsResources';
import { useSharedBudgetCampaignsState } from './hooks/useSharedBudgetCampaignsState';
import { useSharedBudgetCampaignsValidations } from './hooks/useSharedBudgetCampaignsValidations';
import styles from './sharedBudgetCampaigns.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const SharedBudgetCampaigns = () => {
    const { marketingObjective, budgetType, groupBudget } = useSharedBudgetCampaignsResources();
    const {
        campaigns,
        loadCampaigns,
        handleAddCampaign,
        handleRemoveCampaign,
        handleRemoveAllCampaigns,
        updateCampaign,
        options,
        setCurrentOptions,
        isCampaignOptionDisabled,
    } = useSharedBudgetCampaignsState({ marketingObjective, budgetType });
    const { indicationData, scrollRef } = useSharedBudgetCampaignsValidations({
        marketingObjective,
        budgetType,
        groupBudget,
    });

    const { formatMessage } = useIntl();

    return (
        <FormField
            ref={scrollRef}
            inputId="campaigns-budgets"
            containerClass={commonStyles['input']}
            label={
                <FormattedMessage
                    id="campaigns.group.editor.sharedBudgetCampaigns.includedCampaigns"
                    defaultMessage="Included Campaigns"
                />
            }
            helpText={
                <FormattedMessage
                    id="campaigns.group.editor.sharedBudgetCampaigns.help"
                    defaultMessage="Select the campaigns to be included in the Group. For each campaign you can enter an optional spending limit. You may only have campaigns of the same Marketing Objective in a single Group Budget. Depleted campaigns are not eligible."
                />
            }
        >
            <div className={styles['container']}>
                <PaginatedDropdown
                    id="campaign-selector"
                    onChange={handleAddCampaign}
                    searchable
                    value={null}
                    disabled={!options.length}
                    enabledWhileSearching
                    options={options}
                    loadPage={loadCampaigns}
                    placeholder={formatMessage({
                        id: 'campaigns.group.editor.sharedBudgetCampaigns.placeholder',
                    })}
                    onOptionsLoaded={setCurrentOptions}
                    DropdownMenuComponent={BareDropdown}
                    optionItemRenderer={CampaignItemDropdownOption}
                    valueRenderer={undefined}
                    isOptionDisabled={isCampaignOptionDisabled}
                />
            </div>
            <CollapsibleListWithIndication
                items={campaigns}
                deleteItem={handleRemoveCampaign}
                clearItems={handleRemoveAllCampaigns}
                updateItem={updateCampaign}
                metadata={{
                    marketingObjective,
                    groupBudget,
                    budgetType,
                }}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaigns.group.editor.sharedBudgetCampaigns.includedCampaigns"
                        defaultMessage="Included Campaigns"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                ItemComponent={CampaignBudgetListItem}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </FormField>
    );
};
