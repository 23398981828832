import React from 'react';
import { STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { CTA } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './ctaButton.module.scss';

export const CtaButton = ({ ctaMsgId, ctaType }) => (
    <div className={styles['container']}>
        <StyledButton type={STYLED_BUTTON_TYPE.GHOST} size={STYLED_BUTTON_SIZE.SMALL} className={styles['button']}>
            {CTA[ctaType] ? <FormattedMessage id={ctaMsgId} defaultMessage="" /> : ctaType}
        </StyledButton>
    </div>
);
