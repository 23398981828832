import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { buildCodeSnippetWithParams } from 'modules/taboola-common-frontend-modules/account-configurations/utils/buildPixelCodeSnippet';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from 'selectors';
import CodeSnippet from '../CodeSnippet/CodeSnippet';

export const ImagePixelCodeSnippet = () => {
    const [googleTagManager, setGoogleTagManager] = useState(false);
    const { value: eventName } = useFormFieldValue({ field: 'eventName' });
    const {
        [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITH_GTM]: imgCodeSnippetWithGTM,
        [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITHOUT_GTM]: imgCodeSnippetWithoutGTM,
    } = useCommonConfig([
        COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITH_GTM,
        COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITHOUT_GTM,
    ]);
    const { id: accountId } = useSelector(selectedAccountSelector);

    const googleTagManagerTitle = (
        <FormattedMessage
            id="audience.editor.pixel.based.audience.events.code.section.snippet.image.code.gtm"
            defaultMessage="I’m using Google Tag Manager"
        />
    );

    const googleTagManagerTooltip = (
        <FormattedMessage
            id="audience.editor.pixel.based.audience.events.code.section.snippet.image.code.gtm.tooltip"
            defaultMessage="Mark this checkbox when google tag manager the code is slightly different to match google tag manager parameters."
        />
    );

    const imgCodeSnippet = googleTagManager ? imgCodeSnippetWithGTM : imgCodeSnippetWithoutGTM;
    const codeSnippetWithParams = useMemo(
        () => buildCodeSnippetWithParams(imgCodeSnippet, accountId, eventName),
        [imgCodeSnippet, accountId, eventName]
    );

    return (
        <>
            <CheckboxGroup
                onSelectCheckbox={() => setGoogleTagManager(true)}
                onUnselectCheckbox={() => setGoogleTagManager(false)}
                selectedValues={[googleTagManager]}
            >
                <TextCheckbox value title={googleTagManagerTitle} helpText={googleTagManagerTooltip} />
            </CheckboxGroup>
            <br />
            <CodeSnippet codeSnippetText={codeSnippetWithParams} />
        </>
    );
};

export default ImagePixelCodeSnippet;
