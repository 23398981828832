import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { UserFrequencyCapping } from './UserFrequencyCapping';
import { UserFrequencyCappingFieldTooltip } from './UserFrequencyCappingFieldTooltip';
import commonStyles from '../../../../modules/common-campaign-form/components/commonEditor.module.scss';
import styles from '../UserFrequencyCapping/UserFrequencyCapping.module.scss';

const classNameBuilder = classnames.bind(styles);

export const UserFrequencyCappingField = () => {
    return (
        <FormField
            inputId="userImpressionsAmount"
            label={
                <FormattedMessage
                    id="video.campaign.editor.UserFrequencyCapping.title"
                    defaultMessage="User Frequency Capping."
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.UserFrequencyCapping.description"
                    defaultMessage="Use specific user frequency."
                />
            }
            helpText={<UserFrequencyCappingFieldTooltip />}
            containerClass={classNameBuilder(commonStyles['input'], styles['container'])}
        >
            <UserFrequencyCapping />
        </FormField>
    );
};

export default UserFrequencyCappingField;
