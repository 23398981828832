import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useBreadcrumbsAccountStep, { MAX_CHARACTER_COUNT } from '../../../hooks/useBreadcrumbsAccountStep';
import { REPORT_ID } from '../../campaigns/modules/campaigns-reports/hooks';
import { selectedReportSelector } from '../../campaigns/selectors';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';
import { BILLING_AND_PAYMENTS_ROUTE } from '../config';

const useBillingBreadcrumbs = ({ selectedReport: coerceReport, title, isTitleAsLastStep = true }) => {
    const { formatMessage } = useIntl();
    const selectedReportState = useSelector(selectedReportSelector);
    const selectedReport = coerceReport || selectedReportState;
    const accountSteps = useBreadcrumbsAccountStep(BILLING_AND_PAYMENTS_ROUTE);

    const steps = useMemo(() => {
        const billingLabel = formatMessage({
            id: `navigation.caption.billing`,
            defaultMessage: 'Billing',
        });

        const billingStep = {
            label: billingLabel,
            path: isTitleAsLastStep ? BILLING_AND_PAYMENTS_ROUTE : null,
            params: { [REPORT_ID]: selectedReport },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };
        const steps = [...accountSteps, billingStep];

        if (isTitleAsLastStep) {
            steps.push({ label: title, maxCharacterCount: MAX_CHARACTER_COUNT });
        }
        return steps;
    }, [accountSteps, formatMessage, isTitleAsLastStep, selectedReport, title]);

    return {
        steps,
    };
};

export default useBillingBreadcrumbs;
