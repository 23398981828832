import { isNil } from 'lodash';
import { useBulkEditCreativeIds } from '../../campaigns-reports/components/BulkActionToolbar/hooks/useBulkEditCreativeIds';

const useCreativesIdsBeingEdited = () => {
    const [creativeIdsBeingEdited] = useBulkEditCreativeIds();
    if (isNil(creativeIdsBeingEdited)) {
        return [];
    }
    return JSON.parse(creativeIdsBeingEdited);
};

export default useCreativesIdsBeingEdited;
