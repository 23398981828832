import React from 'react';
import { DropAreaIndicator, useDragAndDropFileContext } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './dropContainer.module.scss';

export const defaultDropFileText = (
    <>
        <FormattedMessage
            id="creative.drag.and.drop.active.state.header.firstLine"
            defaultMessage="Drop files to instantly"
        />
        <br />
        <FormattedMessage
            id="creative.drag.and.drop.active.state.header.nextLine"
            defaultMessage="add them to your thumbnails"
        />
    </>
);

export const DropContainer = ({ children, text = defaultDropFileText, dropIcon }) => {
    const { isDragActive } = useDragAndDropFileContext();

    if (isDragActive) {
        return (
            <div className={styles['wrapper']}>
                <DropAreaIndicator text={text} dropIcon={dropIcon} />
                {children}
            </div>
        );
    }

    return children;
};
