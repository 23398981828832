'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CollapsibleListProvider = CollapsibleListProvider;
exports.useCollapsibleListContext = useCollapsibleListContext;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CollapsibleListContext = (0, _react.createContext)({});

function CollapsibleListProvider(_ref) {
    var children = _ref.children,
        addItem = _ref.addItem,
        updateItem = _ref.updateItem,
        deleteItem = _ref.deleteItem,
        items = _ref.items,
        metadata = _ref.metadata,
        onReorder = _ref.onReorder;

    var value = (0, _react.useMemo)(function () {
        return {
            addItem: addItem,
            updateItem: updateItem,
            deleteItem: deleteItem,
            items: items,
            onReorder: onReorder,
            metadata: metadata
        };
    }, [addItem, updateItem, deleteItem, items, onReorder, metadata]);

    return _react2.default.createElement(
        CollapsibleListContext.Provider,
        { value: value },
        children
    );
}

CollapsibleListProvider.propTypes = {
    children: _propTypes2.default.node,
    addItem: _propTypes2.default.func,
    updateItem: _propTypes2.default.func,
    deleteItem: _propTypes2.default.func,
    onReorder: _propTypes2.default.func,
    items: _propTypes2.default.array,
    metadata: _propTypes2.default.object
};

function useCollapsibleListContext() {
    return (0, _react.useContext)(CollapsibleListContext);
}