import { NAVIGATE } from './actionTypes';

/**
 * @deprecated - use link or useNavigate hook instead
 * @param history
 * @param destination
 * @param routerLocationState
 * @returns {{payload: {routerLocationState, destination, history}, type: string}}
 */
const navigate = (history, destination, routerLocationState) => ({
    type: NAVIGATE,
    payload: { history, destination, routerLocationState },
});

export default navigate;
