import React from 'react';
import PropTypes from 'prop-types';
import SystemBreadcrumbs from '../../../../components/SystemBreadcrumbs';

const FormBreadcrumbs = ({ title, className, steps, containerClass }) => {
    return (
        <div className={className}>
            <SystemBreadcrumbs steps={steps} title={title} containerClass={containerClass} />
        </div>
    );
};

FormBreadcrumbs.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    steps: PropTypes.array,
};

export default FormBreadcrumbs;
