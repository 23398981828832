import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import { gridNumberFormatter, formattedNumberValueGetter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const quartileReportColumnDefinition = [
    {
        headerName: FIELDS.QUARTILE.label,
        field: FIELDS.QUARTILE.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.QUARTILE.cellRendererFramework,
        cellRendererParams: FIELDS.QUARTILE.renderParams,
        csvValueGetter: FIELDS.QUARTILE.csvValueGetter,
    },
    ...[FIELDS.IMPRESSIONS, FIELDS.QUARTILE_IMPRESSIONS_RATE].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(quartileReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.QUARTILE_VIDEO].id);
