import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { isFunction, startCase } from 'lodash';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import { navigate } from '../../../actions';
import * as reportConfigIndex from '../../campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from '../../campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import { INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { EditDrawer } from '../components/EditDrawer/EditDrawer';
import { TrackingReportBreadcrumbs } from '../components/TrackingReportBreadcrumbs';
import { TrackingStateRibbon } from '../components/TrackingStateRibbon/TrackingStateRibbon';
import { TrackingEmptyState } from '../components/pixel-empty-state/TrackingEmptyState';
import { MODULE_NAME } from '../config';
import styles from './trackingPage.module.scss';

export const TrackingPage = () => {
    const location = useLocation();
    const containerRef = useRef();
    const { state } = location;
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch(null);

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, match.path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history, match.path]
    );

    const buildReportNameForExport = selectedReport => ` - ${startCase(selectedReport)} Conversions`;
    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    return (
        <div className={styles['container']}>
            <TrackingStateRibbon />
            <div className={styles['pixel-page-container']} ref={containerRef}>
                <CampaignReports
                    skipFetchReport={state && state.skipFetchReport}
                    parentContainerRef={containerRef}
                    module={MODULE_NAME}
                    header={<TrackingReportBreadcrumbs />}
                    rangePickerPresetConfigs={{}}
                    buildReportNameForExport={buildReportNameForExport}
                    reportConfigMap={reportConfigMap}
                    closeSideDrawer={handleNavigation}
                    EmptyStateComponent={TrackingEmptyState}
                    datePreset={null}
                />
                <EditDrawer onCancel={handleNavigation} />
            </div>
        </div>
    );
};
