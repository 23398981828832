import React from 'react';
import { useBillingApi } from '../../../../services/api';
import { REPORT_TYPE } from '../../../campaigns/config';
import { columnDefs } from '../../../campaigns/modules/campaigns-reports/config/reports/invoices/columnDefinitions';
import { INVOICES_AND_PAYMENTS_ROUTE } from '../../config';
import { useReport } from '../../hooks/useReport';
import { BasePreviewRecent } from './BasePreviewRecent';

const columnDefinitions = columnDefs.map(columnDef => ({ ...columnDef, colId: columnDef.field }));
export const InvoicesReportPreview = ({ className }) => {
    const { getInvoicesReport } = useBillingApi();
    const { report: invoicesReport, isLoading } = useReport(getInvoicesReport, 'getInvoicesReport');
    return (
        <BasePreviewRecent
            className={className}
            linkPath={INVOICES_AND_PAYMENTS_ROUTE}
            reportId={REPORT_TYPE.INVOICES}
            titleMsgId="billingAndPayments.recentInvoices.title"
            linkMsgId="billingAndPayments.recentInvoices.linkText"
            columnDefinitions={columnDefinitions}
            isLoadingReport={isLoading}
            reportData={invoicesReport}
        />
    );
};
