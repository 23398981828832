import React, { useCallback } from 'react';
import { Button } from 'tuui';
import { useModuleName, useNavigate, useSelectedAccount } from 'hooks';
import useAvailableItems from 'hooks/useAvailableItems';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { generatePerformanceCreatorPath } from '../../utils/generatePerformanceRulesPath';

const optionsConfigurations = [
    {
        id: 'performanceRule',
        value: 'performanceRule',
        label: 'Performance Rule',
        path: generatePerformanceCreatorPath(),
        dataMetricColumnName: 'performanceRules',
    },
];

export const CreatePerformanceRulesButton = () => {
    const moduleName = useModuleName();
    const navigate = useNavigate();
    const [selectedAccount] = useSelectedAccount();

    const rawOptions = useAvailableItems(optionsConfigurations, {
        selectedAccount,
        moduleName,
    });

    const handleOnChange = useCallback(
        ({ path }) => {
            navigate(path);
        },
        [navigate]
    );

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="Create Rules Button"
            data-automation="Create Rules Button"
        >
            {rawOptions && (
                <Button onClick={() => handleOnChange(rawOptions[0])} data-metrics-event-name={GTM_EVENTS.USABILITY}>
                    <FormattedMessage id="app.gridToolbar.create.performance.rules" defaultMessage="+ NEW RULE" />
                </Button>
            )}
        </div>
    );
};
