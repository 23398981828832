import { useCallback, useMemo, useState } from 'react';
import { keyBy, mapValues } from 'lodash';
import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { useShallowObject } from 'hooks';
import { useCampaignsFetchService } from '../../../../../components/CampaignPicker';
import { CAMPAIGN_STATUS } from '../../../../../config';
import CAMPAIGN_SORTABLE_FIELDS from '../../../../../config/campaignSortableFields';

export const useCampaignsDropdownState = ({ campaigns, marketingObjective }) => {
    const [currentOptions, setCurrentOptions] = useState([]);
    const selectedCampaignsIds = useMemo(() => mapValues(keyBy(campaigns, 'id'), () => true), [campaigns]);
    const shallowSelectedCampaignsIds = useShallowObject(selectedCampaignsIds);
    const { loadCampaignsPage } = useCampaignsFetchService();

    const loadCampaigns = useCallback(
        async params => {
            const [results, total] = await loadCampaignsPage({
                ...params,
                status: [
                    CAMPAIGN_STATUS.RUNNING,
                    CAMPAIGN_STATUS.PAUSED,
                    CAMPAIGN_STATUS.PENDING_START_DATE,
                    CAMPAIGN_STATUS.PENDING_APPROVAL,
                    CAMPAIGN_STATUS.FROZEN,
                ],
                sort: [
                    {
                        field: CAMPAIGN_SORTABLE_FIELDS.CREATE_DATE,
                        order: SORTING_ORDER_TYPES.DESCENDANT,
                    },
                ],
            });
            const resultsExcludingFiltered = results.filter(({ id }) => !shallowSelectedCampaignsIds[id]);

            return [resultsExcludingFiltered, total, results.length - resultsExcludingFiltered.length];
        },
        [shallowSelectedCampaignsIds, loadCampaignsPage]
    );
    const options = useMemo(
        () =>
            currentOptions.map(campaign => {
                if (!campaign) {
                    return {};
                }

                return { ...campaign, label: campaign.name };
            }),
        [currentOptions]
    );

    const isCampaignOptionDisabled = useCallback(
        ({ marketingObjective: campaignMarketingObjective, isPartOfSharedBudgetCampaignsGroup }) =>
            marketingObjective !== campaignMarketingObjective || isPartOfSharedBudgetCampaignsGroup,
        [marketingObjective]
    );

    return {
        options,
        loadCampaigns,
        setCurrentOptions,
        isCampaignOptionDisabled,
    };
};
