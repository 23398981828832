import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CREATIVE_FORMAT_TYPE } from '../components/AdFormatsSection/formatType';
import { CONTENT_METHODS } from './contentMethods';

const contentMethodItems = [
    {
        id: CONTENT_METHODS.AI_VARIATIONS,
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVE_CREATION_AI_VARIATIONS_ENABLED]: 'true',
            [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
        },
        permissions: ['TA_AI_VARIATIONS'],
    },
    {
        id: CONTENT_METHODS.VARIATIONS,
        visibilityCondition: ({ accountConfig }) =>
            accountConfig[FEATURE_FLAGS.CREATIVE_CREATION_AI_VARIATIONS_ENABLED] === 'false' ||
            accountConfig[FEATURE_FLAGS.AI_VARIATIONS_REPLACEMENT_ENABLED] === 'false' ||
            accountConfig[FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI] === 'false',
    },
    {
        id: CONTENT_METHODS.ONE_BY_ONE,
        accountConfigurations: { [FEATURE_FLAGS.CREATIVE_ONE_BY_ONE_CREATION_ENABLED]: 'true' },
    },
    {
        id: CONTENT_METHODS.RSS,
    },
    {
        id: CONTENT_METHODS.CAROUSEL,
        visibilityCondition: ({ formatType }) => formatType === CREATIVE_FORMAT_TYPE.CAROUSEL,
    },
];

export default contentMethodItems;
