import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TotalImpressionCap } from './TotalImpressionCap';
import { TotalImpressionCapFieldTooltip } from './TotalImpressionCapFieldTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const TotalImpressionCapField = () => {
    return (
        <FormField
            inputId="total-impression-cap"
            label={
                <FormattedMessage
                    id="video.campaign.editor.TotalImpressionCap.title"
                    defaultMessage="Total Impression Cap (Optional)"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.TotalImpressionCap.description"
                    defaultMessage="Defines a lifetime limitation of Video Impressions. Relevant for PMPs only"
                />
            }
            helpText={<TotalImpressionCapFieldTooltip />}
            containerClass={styles['input']}
        >
            <TotalImpressionCap />
        </FormField>
    );
};
