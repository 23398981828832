import { useCallback, useMemo, useState } from 'react';
import { differenceWith, isEqual, uniq } from 'lodash';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue, validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import { getLabels, parseCSV } from '../../../../config';
import { addAllItemsToArrayIfAbsent, addSingleItemToArrayIfAbsent } from '../../../ItemSuggestions/ItemSuggestions';
import { useRejectedKeywords } from '../../RejectedKeywords/useRejectedKeywords';
import { useExternalAudienceValidations } from '../../useExternalAudienceValidations';
import { useSuggestionsApi } from '../../useSuggestionsApi';

const handleKeywordsOnAdd = text => {
    return [
        ...new Set(
            text
                .split(/[,\n;]/)
                .map(keyword => keyword.trim())
                .filter(keyword => !!keyword.length)
        ),
    ];
};

const MAX_KEYWORDS_TO_SELECT = 1_000_000;

const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'audience.editor.external-audience.keywords.validations.empty.error',
        defaultMessage: 'Choose at least one keyword.',
    },
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: MAX_KEYWORDS_TO_SELECT },
        messageId: 'audience.editor.external-audience.keywords.validations.max.error',
        defaultMessage: 'Maximum allowed number of keywords to choose is {max}',
    },
];

export const useSearchKeywordsField = () => {
    const { value: file } = useFormFieldValue({
        field: 'file',
    });

    const {
        value: keywords = [],
        onChange: onKeywordsChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'keywords',
        validations,
    });

    const { value: audienceName } = useFormFieldValue({ field: 'name' });

    const [text, setText] = useState('');

    const { validateFileFormat } = useExternalAudienceValidations();

    const addKeywords = useCallback(
        keywordsToAdd => {
            onKeywordsChange(uniq([...keywordsToAdd, ...keywords]));
        },
        [onKeywordsChange, keywords]
    );

    const removeInvalidKeywords = useCallback(
        keywordsToRemove => {
            const keywordsToRemoveLowerCase = keywordsToRemove.map(keywordsToRemove => keywordsToRemove.toLowerCase());
            const updatedKeywords = keywords.filter(keyword => {
                const lowercaseKeyword = keyword.toLowerCase();
                return !keywordsToRemoveLowerCase.includes(lowercaseKeyword);
            });

            onKeywordsChange(updatedKeywords);
        },
        [onKeywordsChange, keywords]
    );

    const keywordsToDisplay = useMemo(() => {
        return keywords.map(item => ({ label: item, value: item, id: item }));
    }, [keywords]);

    const onDropFileHandler = useCallback(
        async acceptedFiles => {
            const parsedCSV = await parseCSV(acceptedFiles[0]);
            const valid = validateFileFormat(parsedCSV);
            if (valid) {
                const extractedKeywords = getLabels(parsedCSV);
                addKeywords(extractedKeywords);
            }
        },
        [addKeywords, validateFileFormat]
    );

    const onSubmit = () => {
        const keywordsToAdd = handleKeywordsOnAdd(text);
        addKeywords(keywordsToAdd);
        setText('');
    };

    const handleDeleteItem = item => {
        onKeywordsChange(keywords => keywords.filter(i => i !== item.value));
    };

    const handleInputChange = async input => {
        setText(input.target.value);
    };

    const handleClearItems = () => onKeywordsChange([]);

    const { suggestionsFromApi, isLoadingSuggestions } = useSuggestionsApi({
        selectedValues: keywords,
        apiFunctionName: 'getSRTRecommendations',
    });
    const suggestions = differenceWith(suggestionsFromApi, keywords, isEqual);

    const handleSuggestionClick = clickedSuggestion => {
        onKeywordsChange(addSingleItemToArrayIfAbsent(keywords, clickedSuggestion.value));
    };

    const handleAddAllSuggestions = () => {
        const suggestionsValues = suggestions.map(suggestion => suggestion.value);
        onKeywordsChange(addAllItemsToArrayIfAbsent(keywords, suggestionsValues));
    };

    const { rejectedKeywords, setRejectedKeywords, rejectedKeywordsTopics, setRejectedKeywordsTopics } =
        useRejectedKeywords();

    return {
        text,
        file,
        keywords: keywordsToDisplay,
        handleInputChange,
        onSubmit,
        onDropFileHandler,
        handleDeleteItem,
        handleClearItems,
        audienceName,
        suggestions,
        isLoadingSuggestions,
        handleAddAllSuggestions,
        handleSuggestionClick,
        removeInvalidKeywords,
        indicationData,
        rejectedKeywords,
        setRejectedKeywords,
        rejectedKeywordsTopics,
        setRejectedKeywordsTopics,
    };
};
