import { useMemo } from 'react';
import { useFuzzySearch } from 'hooks';
import { groupColumnsByPresetName } from '../utils/columnUtils';

const useColumnGrouper = (columns, filter) => {
    const filteredColumns = useFuzzySearch(columns, 'label', filter);
    const groupedColumns = useMemo(() => groupColumnsByPresetName(filteredColumns), [filteredColumns]);
    return groupedColumns;
};

export default useColumnGrouper;
