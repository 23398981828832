"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgPublisher(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M11.992 4C7.576 4 4 7.584 4 12s3.576 8 7.992 8C16.416 20 20 16.416 20 12s-3.584-8-8.008-8zm-5.778 9A2.623 2.623 0 0 1 6 12c0-.345.082-.68.214-1H9a5.08 5.08 0 0 0-.115 1c0 .34.05.67.115 1H6.214zm7.658 0h-3.744A4.675 4.675 0 0 1 10 12c0-.34.056-.675.128-1h3.744c.072.325.128.66.128 1 0 .34-.056.67-.128 1zM15 13c.066-.33.115-.66.115-1 0-.34-.05-.67-.115-1h2.786c.132.32.214.655.214 1s-.082.68-.214 1H15zm-1 5a20.02 20.02 0 0 0 1.275-4H18c-.887 1.854-2.3 3.292-4 4zm-2 1a19.548 19.548 0 0 1-2-5h4a19.548 19.548 0 0 1-2 5zm-6-5h2.725A20.02 20.02 0 0 0 10 18c-1.7-.708-3.113-2.135-4-4zm2.725-4H6c.887-1.865 2.3-3.292 4-4a20.02 20.02 0 0 0-1.275 4zM12 5a19.548 19.548 0 0 1 2 5h-4a19.548 19.548 0 0 1 2-5zm6 5h-2.725A20.02 20.02 0 0 0 14 6c1.7.708 3.113 2.146 4 4z"
            })
        )
    );
}
exports.default = SvgPublisher;