import classnames from 'classnames/bind';
import { AlertOIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useRejectedAudiencesIndication } from '../../hooks/useRejectedAudiencesIndication';
import styles from './audiencesNavigationItemContent.module.scss';

const classNameBuilder = classnames.bind(styles);
export const AudiencesNavigationItemContent = ({ caption, icon: IconComponent, className }) => {
    const { hasRejectedAudiences } = useRejectedAudiencesIndication();

    return (
        <>
            <IconComponent data-testid="audiences-icon" className={classNameBuilder(styles['icon'], className)} />
            <FormattedMessage id={caption.id} defaultMessage={caption.default} />
            {hasRejectedAudiences && <AlertOIcon data-testid="alert-icon" className={styles[`warning-icon`]} />}
        </>
    );
};
