import React from 'react';
import PropTypes from 'prop-types';
import { WeekIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isPmpAccount } from '../../../../../account-management/accountType';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { FORM_MODES } from '../../../../config';
import CampaignScheduler from '../../../common-campaign-form/components/CampaignScheduler/CampaignScheduler';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import TimeFrame from '../TimeFrame/TimeFrame';

const useScheduleVisibility = account => {
    const { value: selectedBusinessModel, mode } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });

    const cpcvEditMode = mode === FORM_MODES.EDIT && selectedBusinessModel === VIDEO_PRICING_MODEL.CPCV;

    if (!isPmpAccount(account) && !cpcvEditMode) {
        return true;
    }

    return false;
};

export const ScheduleSection = ({ account }) => {
    const scheduleVisibility = useScheduleVisibility(account);

    return (
        <CommonCollapsibleCard
            id="VIDEO_CAMPAIGNS_SCHEDULE"
            header={
                <SectionHeader
                    headerIcon={<WeekIcon />}
                    headerText={<FormattedMessage id="campaign.editor.schedule" />}
                />
            }
        >
            <TimeFrame />
            {scheduleVisibility && (
                <CampaignScheduler
                    account={account}
                    showTimezones={false}
                    runtimeCustomDescription={
                        <FormattedMessage id="video.campaign.editor.schedule.runtime.specific.description" />
                    }
                />
            )}
        </CommonCollapsibleCard>
    );
};

ScheduleSection.propTypes = {
    children: PropTypes.node,
};
