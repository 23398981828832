import React, { useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { TimeFramePicker, FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { withValidations } from 'modules/taboola-common-frontend-modules/validations';
import { CAMPAIGN_STATUS } from '../../../../config';
import TimeFrameTooltip from '../../../common-campaign-form/components/TimeFrame/TimeFrameTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

const validations = [
    {
        validationFn: (value, options, { endDate }) => !endDate || moment(endDate).isValid(),
        messageId: 'validations.error.campaign.endDate.invalid',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) => isEditMode || startDate,
        messageId: 'validations.error.campaign.startDate.required',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) => isEditMode || moment(startDate).isValid(),
        messageId: 'validations.error.campaign.startDate.invalid',
    },
    {
        validationFn: (value, options, { endDate, startDate }) =>
            !endDate || moment(startDate).isBefore(moment(endDate).endOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeStartDate',
    },
    {
        validationFn: (value, options, { endDate }) =>
            !endDate || moment(endDate).isSameOrAfter(moment().startOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeNow',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) =>
            isEditMode || !startDate || moment(startDate).isAfter(moment().endOf('day').subtract(1, 'day')),
        messageId: 'validations.error.campaign.startDate.beforeNow',
    },
];

const TimeFramePickerWithValidations = withValidations(withIndication(TimeFramePicker), { validations });

const TimeFrame = () => {
    const { formatMessage } = useIntl();
    const { value: startDate, onChange: onChangeStartDate, mode } = useFormFieldValue({ field: 'startDate' });
    const { value: endDate, onChange: onChangeEndDate } = useFormFieldValue({ field: 'endDate' });
    const { value: status } = useFormFieldValue({ field: 'status' });
    const { onChange: onChangeHasEndDate } = useFormFieldValue({ field: 'hasEndDate' });

    useEffect(() => {
        if (mode === FORM_MODES.DUPLICATE) {
            onChangeStartDate(moment());
        }
    }, [mode, onChangeStartDate]);

    const handleEndDate = useCallback(
        date => {
            onChangeHasEndDate(date !== null);
            onChangeEndDate(date);
        },
        [onChangeEndDate, onChangeHasEndDate]
    );

    const isEditMode = mode === FORM_MODES.EDIT;
    const isStartDateDisabled =
        isEditMode && (status === CAMPAIGN_STATUS.RUNNING || status === CAMPAIGN_STATUS.PAUSED) && startDate < moment();

    const validationValueWithContext = {
        isEditMode,
        startDate: startDate || '',
        endDate: endDate || '',
    };

    return (
        <FormField
            containerClass={styles['input']}
            inputId="time-frame"
            label={<FormattedMessage id="campaign.time.frame.title" defaultMessage="Time Frame" />}
            helpText={<TimeFrameTooltip />}
        >
            <TimeFramePickerWithValidations
                startDate={startDate && moment(startDate)}
                endDate={endDate && moment(endDate)}
                onStartDateChange={onChangeStartDate}
                onEndDateChange={handleEndDate}
                disableStartDate={isStartDateDisabled}
                disableEndDate={false}
                validationContext={validationValueWithContext}
                valueToValidate={validationValueWithContext}
                validationId="timeFrame"
                endDatePlaceholderText={formatMessage({
                    id: 'campaign.time.frame.no.end.date',
                    defaultMessage: 'No End Date',
                })}
                startDataInputIcon={<FormattedMessage id="campaign.time.frame.start" defaultMessage="START" />}
                endDataInputIcon={<FormattedMessage id="campaign.time.frame.end" defaultMessage="END" />}
            />
        </FormField>
    );
};

export default TimeFrame;
