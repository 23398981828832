import { useCallback, useRef, useState, useMemo } from 'react';
import { groupBy, keyBy, isEmpty, get, keys } from 'lodash';

const getDefaultProvidersMap = providers => {
    const defaultProviders = providers.filter(({ partnerGroup }) => !partnerGroup);
    return keyBy(defaultProviders, 'id');
};

const getEnabledProvidersMap = (providers, selectedProviders, selectedSegments) => {
    const noSelectedProviders = isEmpty(selectedProviders) && isEmpty(selectedSegments);
    if (noSelectedProviders) {
        return keyBy(providers, 'id');
    }

    const partnerGroupToProvidersMap = groupBy(providers, 'partnerGroup');

    const providersMap = keyBy(providers, 'id');
    const segmentsGroupedByPartnerGroup = groupBy(selectedSegments, ({ provider }) =>
        get(providersMap, `${provider}.partnerGroup`)
    );
    const selectedPartnerGroupsFromSegments = Object.keys(segmentsGroupedByPartnerGroup);

    // if selected segments contains corrupted data return default providers
    if (selectedPartnerGroupsFromSegments.length > 1) {
        return getDefaultProvidersMap(providers);
    }

    const selectedGroupsFromProviders = selectedProviders.map(providerId =>
        get(providersMap, `${providerId}.partnerGroup`)
    );
    const allSelectedGroups = [...selectedPartnerGroupsFromSegments, ...selectedGroupsFromProviders];
    const [selectedGroup] = allSelectedGroups;
    const enabledProviders = partnerGroupToProvidersMap[selectedGroup] || [];

    return keyBy(enabledProviders, 'id');
};

const useProvidersState = ({ providers }) => {
    const enabledProvidersMap = useRef({});
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [enabledPartnerGroup, setEnabledPartnerGroup] = useState([]);
    const providersMap = useMemo(() => keyBy(providers, 'id'), [providers]);
    const selectedProvidersSet = useMemo(() => new Set(selectedProviders), [selectedProviders]);

    // when setDefaultEnabledProviders is called it updates enabledProvidersMap instantly
    // and even in sync call of these methods data will be correct
    // for the flows that dependent on updates of this method we need to make [enabledProvidersMap.current] to be a dependency

    const isProviderEnabled = useCallback(
        provider =>
            (providers.length > 0 && enabledProvidersMap.current[provider]) || selectedProvidersSet.has(provider),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [providers, enabledProvidersMap.current, selectedProvidersSet]
    );
    const getPartnerByProvider = useCallback(provider => get(providersMap, [provider, 'partnerGroup']), [providersMap]);

    const handleChangeEnabledProviders = useCallback(
        selectedSegments => {
            const enabledProviders = getEnabledProvidersMap(providers, selectedProviders, selectedSegments);
            enabledProvidersMap.current = enabledProviders;

            const enabledPartners = keys(groupBy(enabledProviders, 'partnerGroup'));
            setEnabledPartnerGroup(enabledPartners);
        },
        [providers, selectedProviders]
    );

    const setDefaultEnabledProviders = useCallback(() => {
        if (!isEmpty(selectedProviders)) {
            return;
        }

        enabledProvidersMap.current = getDefaultProvidersMap(providers);
    }, [providers, selectedProviders]);

    return {
        selectedProviders,
        isProviderEnabled,
        getPartnerByProvider,
        enabledPartnerGroup,
        handleChangeSelectedProviders: setSelectedProviders,
        handleChangeEnabledProviders,
        setDefaultEnabledProviders,
        enabledProvidersMap: enabledProvidersMap.current,
    };
};

export default useProvidersState;
