import React from 'react';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { FormButtons } from 'modules/campaigns/components';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormFieldValue, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ConversionPropertiesSection } from '../components/ConversionPropertiesSection/ConversionPropertiesSection';
import { ConversionSetupSection } from '../components/ConversionSetupSection/ConversionSetupSection';
import { EventCodeSection } from '../components/EventCodeSection/EventCodeSection';
import { TrackingFormBreadcrumbs } from '../components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import { CONVERSION_TYPE } from '../config';
import { conversionInitialValues } from '../config/conversionInitialValues';
import { useConversionCreatorState } from '../hooks/useConversionCreatorState';
import styles from './conversionCreatorPage.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const ConversionCreatorPage = ({ onCancel }) => {
    const { step, setStep, submit } = useConversionCreatorState();
    const { value: type } = useFormFieldValue({
        field: 'type',
    });
    const { value: eventName } = useFormFieldValue({
        field: 'eventName',
    });
    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="tracking.conversion.creator.step.label"
                            defaultMessage="Create Conversions"
                        />
                    }
                    id="setup"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.conversion.creator.step.label"
                        defaultMessage="Create Conversions"
                    />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="tracking.conversion.creator.setup.step.title"
                                    defaultMessage="Conversion Setup"
                                />
                            }
                            id="conversion-setup"
                        >
                            <ConversionSetupSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="tracking.conversion.creator.properties.step.title"
                                    defaultMessage="Conversion Properties"
                                />
                            }
                            id="conversion-properties"
                        >
                            <ConversionPropertiesSection type={type} />
                            {type === CONVERSION_TYPE.EVENT_BASED && <EventCodeSection eventName={eventName} />}
                            <div className={styles['buttons-wrapper']}>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={submit}
                                    onCancel={onCancel}
                                    submitLabel={<FormattedMessage id="app.actionButtons.create.conversion" />}
                                    submitIcon={<ChevronRightOutlinedIcon />}
                                    submitDataMetricsComponent="NextButton"
                                    cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                />
                            </div>
                        </WizardStep>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ConversionCreatorPageWithFormDataProvider = withFormDataProvider(ConversionCreatorPage, {
    defaultValuesConfig: conversionInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { ConversionCreatorPageWithFormDataProvider as ConversionCreatorPage };
