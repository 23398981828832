import React from 'react';
import { InformationIcon } from 'tuui';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { BID_STRATEGIES } from '../../../config';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';
import styles from './StrategySelectionIndication.module.scss';

export const StrategySelectionIndication = () => {
    const { value: bidStrategy } = useBidStrategyFormField();

    const { formatMessage } = useIntl();

    if (bidStrategy === BID_STRATEGIES.FIXED) {
        return null;
    }

    const formattedBiddingStrategy = formatMessage({ id: `campaign.editor.bid.strategy.${bidStrategy}` });

    return (
        <div className={styles['indication-wrapper']}>
            <InformationIcon className={styles['indication-icon']} />
            <FormattedMessage
                id="campaign.editor.bid.strategy.selected.name"
                values={{
                    bidStrategy: formattedBiddingStrategy,
                }}
            />
        </div>
    );
};
