import { clearHasUnsavedChanges } from 'actions';
import { saveCreativeError } from 'modules/campaigns/actions';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import creativesApi from 'services/api/creativesApi';
import { generateErrorIndication } from '../../../../../services/utils/errorMessagesUtils';
import { successIndication } from './duplicateCreativeCommon';

const duplicateMotionCreative =
    ({ accountId: originalCreativeAccountId, selectedAccount: { accountId: targetAccountId } }, creativeFormData) =>
    async dispatch => {
        const { selectedCampaigns = [], campaignId, ...creative } = creativeFormData;

        try {
            const result = await creativesApi.createCombinedCreatives(targetAccountId, true, {
                campaignIds: selectedCampaigns.map(({ value }) => value),
                motionAdPojo: [creative],
            });
            dispatch(clearHasUnsavedChanges());
            dispatch(addIndication(successIndication));

            return result;
        } catch (error) {
            dispatch(saveCreativeError(error));
            dispatch(addIndication(generateErrorIndication(error)));
            throw error;
        }
    };

export default duplicateMotionCreative;
