import { useEffect, useMemo, useState } from 'react';

export const useCropMaxZoom = src => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    const maxZoom = useMemo(() => Math.max(Math.min(size.width / 600, size.height / 400), 1), [size]);

    useEffect(() => {
        if (!src) {
            return;
        }
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setSize({
                width: img.width,
                height: img.height,
            });
        };
        return () => {
            img.onload = null;
        };
    }, [src, setSize]);

    return maxZoom;
};
