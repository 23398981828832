import React, { useCallback } from 'react';
import { Button } from 'tuui';
import { DropdownButton, DropdownMenu, STYLED_BUTTON_SIZE, StyledButton, withStylesheet } from 'taboola-ultimate-ui';
import { useDropdownOptions, useModuleName, useNavigate, useSelectedAccount } from 'hooks';
import useAvailableItems from 'hooks/useAvailableItems';
import styles from 'modules/campaigns/modules/campaigns-reports/components/GridToolbar/gridToolbar.module.scss';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    generateConversionCreatorPath,
    generateConversionFunnelCreatorPath,
} from 'modules/tracking/utils/generateTrackingPath';
import { FEATURE_FLAGS } from '../../../taboola-common-frontend-modules/account-configurations';

const StyledAddButton = withStylesheet(StyledButton, styles, {
    [StyledButton.classNames.iconBefore]: 'dropdown-button-icon',
});

const optionsConfigurations = [
    {
        id: 'conversion',
        value: 'conversion',
        label: 'Conversion',
        path: generateConversionCreatorPath(),
        dataMetricColumnName: 'Conversion',
    },
    {
        id: 'conversion-funnel',
        value: 'conversion-funnel',
        label: 'Conversion Funnel',
        path: generateConversionFunnelCreatorPath(),
        dataMetricColumnName: 'Conversion Funnel',
        permissions: ['TA_CONVERSION_FUNNEL'],
        accountConfigurations: { [FEATURE_FLAGS.CONVERSION_FUNNEL_ENABLED]: 'true' },
    },
];

export const CreateConversionButton = () => {
    const moduleName = useModuleName();
    const navigate = useNavigate();
    const [selectedAccount] = useSelectedAccount();

    const rawOptions = useAvailableItems(optionsConfigurations, {
        selectedAccount,
        moduleName,
    });

    const options = useDropdownOptions(rawOptions, 'app.gridToolbar');

    const handleOnChange = useCallback(
        ({ path }) => {
            navigate(path);
        },
        [navigate]
    );

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="CreateButton"
            data-automation="CreateButton"
        >
            {rawOptions?.length === 1 ? (
                <Button onClick={() => handleOnChange(rawOptions[0])} data-metrics-event-name={GTM_EVENTS.USABILITY}>
                    <FormattedMessage id="app.gridToolbar.create.conversion" defaultMessage="+ New Conversion" />
                </Button>
            ) : (
                <DropdownButton
                    ButtonComponent={StyledAddButton}
                    buttonText={<FormattedMessage id="app.gridToolbar.create" defaultMessage="Create" />}
                    onChange={handleOnChange}
                    buttonSize={STYLED_BUTTON_SIZE.MEDIUM}
                    options={options}
                    shouldStretchedByContent
                    strechedMenuPosition={DropdownMenu.MENU_POSITION.RIGHT}
                />
            )}
        </div>
    );
};
