import React from 'react';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import BaseCellEditor from './BaseCellEditor';
import RadioGroupEditField from './RadioGroupEditField';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';
import styles from './radioGroupCellEditor.module.scss';

const RadioGroupCellEditor = ({ name, radioOptions, label, children, onCancel, getValueView = identity, ...rest }) => {
    const { colDef } = rest;
    const {
        hasError,
        submitHandler,
        triggerValidationEvent,
        isValidationsRunning,
        isDirty,
        failedValidationData,
        ...fieldData
    } = useSingleFieldCellEditorData(rest);
    const { value } = fieldData;

    return (
        <BaseCellEditor
            onCancel={onCancel}
            onSubmit={submitHandler}
            disableSubmit={hasError}
            colDef={colDef}
            label={label}
        >
            {children}
            <RadioGroupEditField
                name={name}
                className={styles['radio-group']}
                radioOptions={radioOptions}
                {...fieldData}
                value={getValueView(value)}
                {...failedValidationData}
            />
        </BaseCellEditor>
    );
};

RadioGroupCellEditor.propTypes = {
    radioOptions: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.object,
    colDef: PropTypes.object,
    onCancel: PropTypes.func,
};

export default RadioGroupCellEditor;
