import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './previewItemLoading.module.scss';

const PreviewItemLoading = () => (
    <ContentLoader width={300} height={200} speed={2} className={styles['content']}>
        <rect x="0" y="8" rx="2" ry="2" width="60" height="6" />
        <rect x="0" y="22" rx="0" ry="0" width="280" height="140" />
        <rect x="0" y="170" rx="2" ry="2" width="100" height="6" />
        <rect x="0" y="182" rx="2" ry="2" width="40" height="4" />
    </ContentLoader>
);

export default PreviewItemLoading;
