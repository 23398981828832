import Big from 'big.js';

const ROUNDING = {
    ROUND_DOWN: 0,
    ROUND_HALF_UP: 1,
};

const countDecimals = number => (number.toString().split('.')[1] || []).length;

const getMaxDigitPointNumber = (precision1, precision2) =>
    Math.max(countDecimals(+precision1), countDecimals(+precision2));

// it`s help to avoid problem with not exact number in JS
// when we make simple math operation , like :
// 0.1+0.2 = 0.30000000000000004 instead 0.3
const restrictNumberWithDigits = (value, max_allowed_point_number, rounding = ROUNDING.ROUND_HALF_UP) => {
    try {
        return parseFloat(new Big(String(parseFloat(value))).toFixed(max_allowed_point_number, rounding));
    } catch (e) {
        console.error(e);
        return value;
    }
};

export { getMaxDigitPointNumber, restrictNumberWithDigits, ROUNDING };
