import { isNil } from 'lodash';

const serializedField = value => {
    if (isNil(value) || typeof value === 'string') {
        return value;
    }

    return JSON.stringify(value);
};
export const serializeAppEvent = event => {
    const serializedEvent = { ...event };

    serializedEvent.value = serializedField(event.value);
    serializedEvent.prevValue = serializedField(event.prevValue);
    serializedEvent.data = serializedField(event.data);

    return serializedEvent;
};
