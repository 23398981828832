import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { noop, keys } from 'lodash';
import { TabContainer, Tab, TabList, TabPanel } from 'tuui';
import { ClickZoneArea, FileTypes, HelpTooltip } from 'taboola-ultimate-ui';
import { BetaTag } from 'components';
import { useAvailableItems } from 'hooks';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import CONTENT_TYPES from 'modules/campaigns/modules/common-campaign-form/config/contentTypes';
import { DropContainer } from 'modules/campaigns/modules/creative-creator/components/DropContainer/DropContainer';
import { ONLY_IMAGE_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { MediaDragAndDrop } from 'modules/campaigns/modules/creative-editor/components/MediaDragAndDrop/MediaDragAndDrop';
import { MediaTabsContextProvider } from 'modules/campaigns/modules/creative-editor/components/MediaTabs';
import { CREATIVE_EDITOR_TABS } from 'modules/campaigns/modules/creative-editor/config';
import { thumbnailTabItems } from 'modules/campaigns/modules/creative-editor/config/thumbnailTabItems';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './mediaPicker.module.scss';

const classNameBuilder = classnames.bind(styles);

export const getContentTypeByFileTypes = fileTypes => {
    const hasImage = keys(fileTypes).includes(FileTypes.IMAGE);
    const hasMotion = keys(fileTypes).includes(FileTypes.VIDEO);

    if (hasImage) {
        if (hasMotion) {
            return;
        }
        return CONTENT_TYPES.PHOTO;
    }

    return CONTENT_TYPES.VIDEO;
};

export const MediaPicker = ({
    children,
    fileTypes = ONLY_IMAGE_FILES,
    value,
    onSelectMedia,
    onCancel,
    disabled,
    tabsConditionData,
    tabsContext,
    tabsDisabled,
    showFileBrowser = false,
    onBrowseFile,
    openCropModal = noop,
    clickZoneText = <FormattedMessage id="creative.drag.and.drop.header" defaultMessage="Drag and Drop or" />,
    clickZoneDescription = (
        <FormattedMessage
            id="creative.drag.and.drop.clickable.text.upload.from.computer"
            defaultMessage="upload from computer"
        />
    ),
    clickZoneTooltip = (
        <FormattedMessage
            id="creative.drag.and.drop.tooltip"
            defaultMessage="Image dimension must have a minimum width of 400 px. and a minimum length of 350 px. Your image file size must be no more than 5 Mb. Switching Images to Video or animated GIF files is not supported at the moment."
        />
    ),
}) => {
    const { campaignId, titleList } = tabsContext;
    const contentType = getContentTypeByFileTypes(fileTypes);
    const creativeType = tabsConditionData?.creativeType;
    const isUploadInMediaLibraryEnabled = useConfigMatch({ [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'true' });
    const tabItems = useMemo(
        () =>
            thumbnailTabItems.map(({ id, caption, component, ...rest }) => ({
                ...rest,
                id,
                caption: {
                    id:
                        id === CREATIVE_EDITOR_TABS.MEDIA_LIBRARY
                            ? caption.id
                            : `creative.editor.tab.${id}.caption.${creativeType || EMPTY_FIELD_VALUE}`,
                    default: caption.default,
                },
                Component: component,
            })),
        [creativeType]
    );

    const availableItems = useAvailableItems(tabItems, tabsConditionData);
    const defaultTabIndex = useMemo(() => {
        if (isUploadInMediaLibraryEnabled) {
            return availableItems.findIndex(tab => tab.id === CREATIVE_EDITOR_TABS.MEDIA_LIBRARY);
        } else {
            const defaultTab =
                availableItems.find(tab => tab.id === CREATIVE_EDITOR_TABS.AI_GENERATED) ||
                availableItems.find(tab => tab.id === CREATIVE_EDITOR_TABS.URL_BASED);
            return availableItems.indexOf(defaultTab);
        }
    }, [isUploadInMediaLibraryEnabled, availableItems]);

    const tabHeaders = availableItems.map(({ caption, id, metricsAttributes, isBeta = false, tagMessage, tooltip }) => (
        <Tab
            key={id}
            disabled={tabsDisabled || disabled}
            {...metricsAttributes({ campaignId })}
            className={styles['tab-headers']}
        >
            <FormattedMessage id={caption.id} defaultMessage={caption.defaultMessage} />
            {tooltip ? (
                <HelpTooltip iconClassName={styles['tooltip']}>
                    <TooltipSection>
                        <FormattedMessage {...tooltip} />
                    </TooltipSection>
                </HelpTooltip>
            ) : null}
            {isBeta ? (
                <BetaTag
                    containerClassName={styles['beta-tag']}
                    tagMessageClassName={styles['beta-tag-text']}
                    tagMessage={tagMessage}
                    tooltipMessage=""
                />
            ) : null}
        </Tab>
    ));

    const tabContent = availableItems.map(({ Component, id }) => (
        <TabPanel key={id} className={styles['content-container']}>
            <Component
                value={value}
                onSelectMedia={onSelectMedia}
                uploadButtonFileTypes={fileTypes}
                contentType={contentType}
                onCancel={onCancel}
                disabled={disabled}
            />
        </TabPanel>
    ));
    return (
        <div>
            {isUploadInMediaLibraryEnabled ? (
                children
            ) : (
                <MediaDragAndDrop
                    fileTypes={fileTypes}
                    onDrop={onSelectMedia}
                    onCancel={onCancel}
                    dropActiveContainerClassName={styles['drop-active-container']}
                    disabled={disabled}
                    suppressDropContainer
                    campaignId={campaignId}
                    titleList={titleList}
                    inputId="media-picker-file-input"
                >
                    {children}
                    {showFileBrowser && (
                        <div className={styles['click-zone']}>
                            <DropContainer dropIcon={null}>
                                <ClickZoneArea
                                    onOpenFileDialog={onBrowseFile}
                                    text={clickZoneText}
                                    clickDialogText={
                                        <span
                                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                                            data-metrics-component="BrowseFiles"
                                        >
                                            {clickZoneDescription}
                                        </span>
                                    }
                                    actionDescText={
                                        <FormattedMessage
                                            id="creative.drag.and.drop.description"
                                            defaultMessage="Upload image, GIF or video file"
                                        />
                                    }
                                    tooltipText={clickZoneTooltip}
                                    clickZoneIcon={null}
                                    disabled={tabsDisabled}
                                />
                            </DropContainer>
                        </div>
                    )}
                </MediaDragAndDrop>
            )}
            <MediaTabsContextProvider openCropModal={openCropModal} {...tabsContext}>
                <TabContainer
                    className={classNameBuilder('input-tabs', { disabled: tabsDisabled || disabled })}
                    defaultIndex={defaultTabIndex}
                >
                    <TabList>{tabHeaders}</TabList>
                    {tabContent}
                </TabContainer>
            </MediaTabsContextProvider>
        </div>
    );
};

MediaPicker.defaultProps = {
    onSelectMedia: noop,
    tabsContext: {
        thumbnails: [],
    },
    tabsConditionData: {},
};
