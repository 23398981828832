import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form/components';

const RssFieldsTooltip = () => {
    return (
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.RSS.urls.help.overview"
                defaultMessage="When you upload an RSS feed to your campaign, the items you add to your feed will automatically be added to your campaign. You can use this, for example, if you want to add a new ad for every new webpage on your website. If you have an RSS feed that is continually updated with your new webpages, simply use that RSS feed in your campaign."
            />
        </TooltipSection>
    );
};

export default RssFieldsTooltip;
