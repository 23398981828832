import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MARKETING_OBJECTIVE_KEYS } from '../../config';
import styles from './marketingObjective.module.scss';

const MARKETING_OBJECTIVE_KEYS_ARRAY = Object.values(MARKETING_OBJECTIVE_KEYS);

export const MarketingObjectiveDescription = ({ hovered, selected }) => {
    const objectiveKey = hovered || selected;

    return objectiveKey ? (
        <FormattedMessage id={`campaign.marketing.objective.${objectiveKey}.description`} />
    ) : (
        <div className={styles['subtext-placeholder']} />
    );
};

MarketingObjectiveDescription.propTypes = {
    hovered: PropTypes.oneOf(MARKETING_OBJECTIVE_KEYS_ARRAY),
    selected: PropTypes.oneOf(MARKETING_OBJECTIVE_KEYS_ARRAY),
};

export default MarketingObjectiveDescription;
