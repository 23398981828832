import HandlerMessageType from '../backstage-content/messages';
import { clearRecommendationsIndication } from './actions';
import {
    audienceHandler,
    audienceRetargetingHandler,
    attentiveAudienceHandler,
    budgetHandler,
    lowResolutionHandler,
    onDemandHandler,
} from './handlers';
import RECOMMENDATION_TYPE from './recommendationTypes';
import { myAudiencesTransformer, uddIdsTransformer, noopTransformer, budgetTransformer } from './transformers';

const recommendationsHandlersMap = {
    [RECOMMENDATION_TYPE.BUDGET]: budgetHandler,
    [RECOMMENDATION_TYPE.BUDGET_V2]: budgetHandler,
    [RECOMMENDATION_TYPE.AUDIENCE_RETARGETING]: audienceRetargetingHandler,
    [RECOMMENDATION_TYPE.ATTENTIVE_AUDIENCE]: attentiveAudienceHandler,
    [RECOMMENDATION_TYPE.AUDIENCE]: audienceHandler,
    [RECOMMENDATION_TYPE.LOW_RESOLUTION]: lowResolutionHandler,
    [RECOMMENDATION_TYPE.ON_DEMAND]: onDemandHandler,
};

const recommendationsTransformerMap = {
    [RECOMMENDATION_TYPE.BUDGET]: budgetTransformer,
    [RECOMMENDATION_TYPE.BUDGET_V2]: noopTransformer,
    [RECOMMENDATION_TYPE.AUDIENCE_RETARGETING]: myAudiencesTransformer,
    [RECOMMENDATION_TYPE.ATTENTIVE_AUDIENCE]: myAudiencesTransformer,
    [RECOMMENDATION_TYPE.AUDIENCE]: uddIdsTransformer,
    [RECOMMENDATION_TYPE.LOW_RESOLUTION]: noopTransformer,
    [RECOMMENDATION_TYPE.ON_DEMAND]: noopTransformer,
};

const externalMessageHandler =
    ({ history, type, value }) =>
    (dispatch, getState) => {
        if (type === HandlerMessageType.LOAD) {
            dispatch(clearRecommendationsIndication());
        }

        if (type === HandlerMessageType.RECOMMENDATIONS) {
            const { recommendationType = '', recommendationMetadata = {} } = value;
            const recommendationData = recommendationsTransformerMap[recommendationType](recommendationMetadata);
            recommendationsHandlersMap[recommendationType]({
                history,
                recommendationMetadata,
                recommendationData,
                recommendationType,
            })(dispatch, getState);
        }
    };

export default externalMessageHandler;
