import React from 'react';
import get from 'lodash/get';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { getCities } from '../../../../../services/api/geoTargetingApi';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications/actions';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { requestFetchCities, fetchCitiesSuccess, fetchCitiesError } from '../actions';

const pageSize = 10;

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.geo.targeting.cities.fetch.error"
            defaultMessage="We were not able to fetch the list of cities"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const fetchCities = (countryCode, searchText, page) => dispatch => {
    dispatch(requestFetchCities());
    return getCities(countryCode, searchText, page, pageSize)
        .then(listOfCities => {
            const result = get(listOfCities, 'results', []);

            dispatch(fetchCitiesSuccess(result));

            return result;
        })
        .catch(error => {
            dispatch(addIndication(errorIndication));
            dispatch(fetchCitiesError(error));

            return [];
        });
};

export default fetchCities;
