import { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { CHAT_RENDER_MODE } from '../../../../../components/ChatAgent/chatRenderMode';
import {
    CHAT_AGENT_ACTION_TYPE,
    CHAT_AGENT_MESSAGE_TYPE,
} from '../../../../taboola-common-frontend-modules/chat-agent/config/chatAgentActionType';
import { useChatAgentContext } from '../../../../taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { OnboardingLoader } from '../OnboardingLoader/OnboardingLoader';
import welcomeMessageIcon from './welcome-message-icon.svg';
import styles from './InitWidget.module.scss';

const classNameBuilder = classnames.bind(styles);

export const InitWidget = () => {
    const { isChatConnected, registerChatTextMessageListener, sendResponseToChatAgent } = useChatAgentContext();
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [released, setReleased] = useState();

    useEffect(() => {
        if (released) {
            return;
        }

        return registerChatTextMessageListener(messageAction => {
            const messageText = messageAction.actionData;

            if (messageAction?.actionMetadata?.type === CHAT_AGENT_MESSAGE_TYPE.OUTBOUND) {
                setReleased(true);
                sendResponseToChatAgent({
                    actions: [
                        {
                            actionType: CHAT_AGENT_ACTION_TYPE.CHANGE_RENDER_MODE,
                            actionData: CHAT_RENDER_MODE.FULL,
                        },
                    ],
                });
                return;
            }

            setWelcomeMessage(welcomeMessage => {
                if (!welcomeMessage && messageAction?.actionMetadata?.type === CHAT_AGENT_MESSAGE_TYPE.INBOUND) {
                    return messageText;
                }
                return welcomeMessage;
            });
        });
    }, [registerChatTextMessageListener, sendResponseToChatAgent, released]);

    if (released) {
        return null;
    }

    return (
        <div className={classNameBuilder('wrapper', { released, connected: isChatConnected() })}>
            <OnboardingLoader
                animate={!isChatConnected()}
                title={<FormattedMessage id="onboarding.form.hey.abby" defaultMessage="I’m Abby" />}
                content={
                    <FormattedMessage
                        id="onboarding.form.guide"
                        defaultMessage="As your AI assistant, I'll help you set up top-performing campaigns and great-looking ads to get you started with Taboola smoothly. I can't wait to get started!"
                    />
                }
            />
            {isChatConnected() && (
                <div className={styles['welcome-wrapper']}>
                    <div>
                        <img src={welcomeMessageIcon} alt="welcome-icon" className={styles['welcome-icon']} />
                    </div>
                    <span className={styles['welcome-message']}>{welcomeMessage}</span>
                </div>
            )}
        </div>
    );
};
