import React from 'react';
import { ExternalLink } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './CookieConsent.module.scss';

export const CookieConsent = () => {
    const tbp = window.tbp;
    if (!tbp) {
        return null;
    }

    const openConsentPopup = () => {
        tbp.initialized = false;
        tbp.consented = false;
        tbp.tbpShowConsent(tbp.userCountryCode, true);
    };

    return (
        <ExternalLink onClick={openConsentPopup} className={styles['container']}>
            <FormattedMessage id="app.legal.cookieConsent" defaultMessage="Cookie Consent" />
        </ExternalLink>
    );
};
