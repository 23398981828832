import React from 'react';
import AppliedText from '../AppliedText/AppliedText';
import DismissButton from '../Buttons/DismissButton/DismissButton';

const DismissSection = ({ isApplied, successCode, dismissCode, dismissInvoker, recommendation }) => {
    const { campaignId, id: recommendationId } = recommendation;

    return (
        <>
            {isApplied ? (
                <AppliedText successCode={successCode} />
            ) : (
                <DismissButton
                    dismissCode={dismissCode}
                    campaignId={campaignId}
                    onClick={dismissInvoker}
                    recommendationId={recommendationId}
                />
            )}
        </>
    );
};

export default DismissSection;
