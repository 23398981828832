import { useCpaGoalFormFieldValue } from '../../common-campaign-form/components/CpaGoal/useCpaGoalFormFieldValue';
import { useCpaRecommendation } from './useCpaRecommendation';
import usePerformanceRecommendationExecution from './usePerformanceRecommendationExecution';

const additionalDependencies = { ignoreSignificantChangeWarning: true };

export const useUnrealisticTCPARecommendation = ({ recommendation }) => {
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const { campaignId, accountId, applyParams, recommendationId, recommendationMetadata, navigateToEditCampaign } =
        useCpaRecommendation({
            recommendation,
        });
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams,
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    return {
        isApplyDisabled: !targetCpa || targetCpa <= 0,
        recommendationId,
        recommendationMetadata,
        navigateToEditCampaign,
        apply,
        dismiss,
        additionalDependencies,
    };
};
