import { values } from 'lodash';
import uuid from 'uuid/v1';
import { navigate } from '../../../../../../../actions';
import { FORM_MODES } from '../../../../../../../config/formModes';
import { CAMPAIGN_DIMENSION } from '../../../../../../../services/campaignDimension';
import { setRecommendations } from '../../../../../../recommendations';
import { REPORT_TYPE } from '../../../../../config';
import { generateCampaignEditorPath } from '../../../../../services/utils';
import { TARGETING_TYPES } from '../../../../common-campaign-form';

const reportTypeToSegmentData = include => ({
    [REPORT_TYPE.AUDIENCE]: {
        campaignTargeting: {
            marketplaceAudienceTargeting: {
                collection: [{ type: TARGETING_TYPES.INCLUDE, values: include, id: uuid() }],
                validTargeting: true,
            },
        },
    },
    [REPORT_TYPE.AUDIENCE_BUNDLES]: {
        campaignTargeting: {
            marketplaceAudienceTargeting: {
                collection: [{ type: TARGETING_TYPES.INCLUDE, values: include, id: uuid() }],
                validTargeting: true,
            },
        },
    },
    [REPORT_TYPE.CONTEXTUAL]: {
        campaignTargeting: {
            contextualSegmentsTargeting: { include },
        },
    },
});

export const generateBulkActionsOnDuplicateHandler =
    ({ history, selectedRows, selectedReport, selectedCampaign }) =>
    dispatch => {
        const selectedCampaignId = selectedCampaign?.id;
        const selectedCampaignOwner = selectedCampaign?.advertiserName;

        const selectedIds = values(selectedRows).map(({ id }) => id);

        const segmentsData = reportTypeToSegmentData(selectedIds)[selectedReport];

        const duplicateCampaignPath = generateCampaignEditorPath({
            mode: FORM_MODES.DUPLICATE,
            accountId: selectedCampaignOwner,
            campaignId: selectedCampaignId,
            dimension: CAMPAIGN_DIMENSION.SPONSORED,
        });

        const selectedSegmentsFromReport = {
            recommendationData: segmentsData,
        };

        dispatch(setRecommendations(selectedSegmentsFromReport));
        dispatch(navigate(history, duplicateCampaignPath));
    };
