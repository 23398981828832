import React, { useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { CollapsibleList, useDebouncedValue, useElementSize } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { useSendSegmentSearchEvent } from '../../../utils/useSendSegmentSearchEvent';
import { TargetingTypeBareDropdown } from '../../TargetingTypeDropdown';
import { LoadingMode, MAX_SEGMENTS_TO_SELECT } from '../../Tree';
import {
    excludeErrorListValidations,
    excludeWarningListValidations,
    includeErrorListValidations,
    includeWarningListValidations,
} from '../MarketplaceAudienceValidations';
import { MarketplaceAudiencesProvider } from '../MarketplaceAudiencesContext';
import { useMarketplaceAudiencesTreeState } from '../MarketplaceAudiencesTargeting/hooks';
import ProvidersDropdown from '../ProvidersDropdown/ProvidersDropDown';
import SegmentListItemContentWithValidations from '../SegmentListItemContent/SegmentListItemContentWithValidations';
import SegmentsDropdown from '../SegmentsDropdown/SegmentsDropdown';
import {
    useMarketplaceAudiencesHiddenIdsSet,
    useProvidersApi,
    useProvidersState,
    useSegmentsApi,
    useSelectedSegmentsGroup,
    useTaxonomiesResources,
} from '../hooks';
import { useMarketplaceAudienceTargetingEffects } from '../hooks/useMarketplaceAudienceTargetingEffects';
import { useNodeHandlersWithMetrics } from '../hooks/useNodeHandlersWithMetrics';
import {
    getTargetingDropdownProps,
    getTargetingTypeFieldName,
    getTargetingValuesFieldName,
} from '../utils/andLogicUtils';
import commonStyles from '../../commonEditor.module.scss';
import styles from '../MarketplaceAudiencesTargeting/marketplaceAudiencesTargeting.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const MarketplaceAudiencesTargetingGroup = ({
    index,
    selectedAccountId,
    marketplaceAudienceSegmentTypeCount,
    setMarketplaceAudienceSegmentTypeByIndex,
    shouldDisableExcludeType,
}) => {
    const hiddenIdsSet = useMarketplaceAudiencesHiddenIdsSet(index);
    const { value: targetingType, onChange: setTargetingType } = useFormFieldValue({
        field: getTargetingTypeFieldName(index),
    });
    const isIncludeTargetingType = targetingType === TARGETING_TYPES.INCLUDE;

    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const [searchText, setSearchText] = useState('');
    const { formatMessage } = useIntl();
    const search = useDebouncedValue(searchText.length > 2 ? searchText : '');

    const idsFormFieldName = getTargetingValuesFieldName(index);

    const { providers, reloadProviders, isProvidersReady } = useProvidersApi({ selectedAccountId });
    const {
        selectedProviders,
        isProviderEnabled,
        getPartnerByProvider,
        enabledPartnerGroup,
        handleChangeSelectedProviders,
        handleChangeEnabledProviders,
        setDefaultEnabledProviders,
        enabledProvidersMap,
    } = useProvidersState({ providers });
    const hasFilter = !isEmpty(selectedProviders);

    const { segments, ...treeStateParams } = useSegmentsApi({
        search,
        providers: selectedProviders,
        selectedAccountId,
    });

    const { getTaxonomyValue, reloadTaxonomies } = useTaxonomiesResources({ selectedAccountId });
    const isDisabledLeaf = useCallback(({ provider }) => !isProviderEnabled(provider), [isProviderEnabled]);

    const {
        selectedLeaves,
        handleRemoveItem,
        handleReload,
        setSelectedLeavesByIds,
        handleSelectNode,
        handleSelectAll,
        expandAll,
        setIsReady,
        ...treeProps
    } = useMarketplaceAudiencesTreeState({
        search,
        leaves: segments,
        getTaxonomyValue,
        formatMessage,
        isDisabledLeaf,
        hasFilter,
        index,
        marketplaceAudienceSegmentTypeCount,
        setMarketplaceAudienceSegmentTypeByIndex,
        hiddenIdsSet,
        ...treeStateParams,
    });

    const { leaves, removeLeaves, failedValidationData, scrollRef } = useSelectedSegmentsGroup({
        selectedLeaves,
        setSelectedLeavesByIds,
        idsFormFieldName,
        enabledProvidersMap,
        errorListValidations: isIncludeTargetingType ? includeErrorListValidations : excludeErrorListValidations,
        warningListValidations: isIncludeTargetingType ? includeWarningListValidations : excludeWarningListValidations,
        setIsReady,
        isReadyToValidate: treeProps.isReady && isProvidersReady,
    });

    useMarketplaceAudienceTargetingEffects({
        handleReload,
        reloadProviders,
        reloadTaxonomies,
        handleChangeEnabledProviders,
        selectedLeaves,
        search,
        expandAll,
    });

    useSendSegmentSearchEvent({
        component: 'Marketplace Audiences Segments Search',
        searchText: search,
        resultsCount: segments.length,
        providers: selectedProviders,
        isLoading: treeProps.loadingMode !== LoadingMode.NONE,
    });

    const { handleSelectNodeWrapper, handleSelectAllWrapper } = useNodeHandlersWithMetrics({
        handleSelectNode,
        handleSelectAll,
        search,
        selectedLeaves,
        setDefaultEnabledProviders,
    });

    const { width, ref } = useElementSize();

    const targetingDropdownProps = getTargetingDropdownProps(shouldDisableExcludeType, targetingType);

    return (
        <div className={styles['container']} data-testid={`marketplace-audiences-targeting-group-${index}`}>
            <div className={styles['dropdown-container']} ref={ref}>
                <MarketplaceAudiencesProvider
                    limit={MAX_SEGMENTS_TO_SELECT}
                    handleSelectNode={handleSelectNodeWrapper}
                    handleSelectAll={handleSelectAllWrapper}
                    isProviderEnabled={isProviderEnabled}
                    getPartnerByProvider={getPartnerByProvider}
                    enabledPartnerGroup={enabledPartnerGroup}
                    campaignId={campaignId}
                    {...treeProps}
                >
                    <TargetingTypeBareDropdown
                        onChange={({ value }) => setTargetingType(value)}
                        value={targetingType}
                        aria-label="toggle targeting type dropdown"
                        {...targetingDropdownProps}
                    />
                    <div className={styles['delimiter']} />
                    <SegmentsDropdown search={searchText} onSearchChange={text => setSearchText(text)} width={width} />
                    <ProvidersDropdown
                        selectedValues={selectedProviders}
                        options={providers}
                        onChange={providerIds => handleChangeSelectedProviders(providerIds)}
                    />
                </MarketplaceAudiencesProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting"
                data-testid="marketplace-audiences-targeting-list"
                items={leaves}
                ItemComponent={SegmentListItemContentWithValidations}
                metadata={{
                    targetingType,
                    field: idsFormFieldName,
                    enabledProvidersMap,
                    isProvidersReady,
                }}
                listHeaderTitle={
                    isIncludeTargetingType ? (
                        <FormattedMessage
                            id="campaign.editor.marketplace.include.audiences.title"
                            defaultMessage="Included Marketplace Audiences"
                        />
                    ) : (
                        <FormattedMessage
                            id="campaign.editor.marketplace.exclude.audiences.title"
                            defaultMessage="Excluded Marketplace Audiences"
                        />
                    )
                }
                deleteItem={handleRemoveItem}
                clearItems={removeLeaves}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...failedValidationData}
                indicationContainerClass={commonStyles['warning-indication']}
                ref={scrollRef}
            />
        </div>
    );
};
