import React from 'react';
import { Button, GTMIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { GtmConnectIndicator } from './GtmConnectIndicator';
import styles from './GtmConnectForm.module.scss';

export const GtmConnectButton = ({ handleConnect }) => {
    const { isTokenValid } = useGoogleApi();

    return (
        <FormField
            label={<FormattedMessage id="tracking.setup.gtm.first.step.label" defaultMessage="Google Tag Manager" />}
            description={
                <FormattedMessage
                    id="tracking.setup.gtm.configuration.gtm.description.connect"
                    defaultMessage="Connect to your Google Account"
                />
            }
        >
            <Button
                onClick={handleConnect}
                variant={Button.variant.ghost}
                size={Button.size.md}
                className={styles['connect-button']}
            >
                <GTMIcon />
                {isTokenValid ? (
                    <FormattedMessage
                        id="tracking.setup.gtm.configuration.gtm.button.switch"
                        defaultMessage="<b>Switch Account</b>"
                    />
                ) : (
                    <FormattedMessage
                        id="tracking.setup.gtm.configuration.gtm.button.connect"
                        defaultMessage="<b>Click To Connect</b>"
                    />
                )}
            </Button>

            <GtmConnectIndicator />
        </FormField>
    );
};
