import React from 'react';
import PropTypes from 'prop-types';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import styles from './reportPresetPickerOptionIcon.module.scss';

export const ReportPresetPickerOptionIcon = props => {
    const { handleClick, IconComponent, tooltip } = props;
    return (
        <div onClick={handleClick}>
            <IconComponent className={styles['option-icon']} />
            <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow>
                {tooltip}
            </Tooltip>
        </div>
    );
};

ReportPresetPickerOptionIcon.propTypes = {
    handleIconClick: PropTypes.func,
    IconComponent: PropTypes.func,
    tooltip: PropTypes.element,
};
