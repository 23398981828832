import config from 'modules/taboola-common-frontend-modules/authentication/config';
import { trustedParentHost } from '../hooks/useParentHostParam';
import { isIframeMode } from './isIframeMode';

export const sendMessage = (type, value) => {
    if (!isIframeMode()) {
        return;
    }

    const trustedHost = trustedParentHost.getValue();
    if (trustedHost) {
        window.parent.postMessage({ type, value }, trustedHost);
        return;
    }

    const targetOriginList = `${config.getParentAppDomain()}`.split(' ');

    targetOriginList.forEach(targetOrigin => {
        window.parent.postMessage({ type, value }, targetOrigin);
    });
};
