import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { Evergreen } from './Evergreen';
import { EvergreenFieldTooltip } from './EvergreenFieldTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const EvergreenField = () => {
    const { value: evergreen } = useFormFieldValue({
        field: 'evergreen',
    });
    return (
        <FormField
            inputId="evergreen"
            label={<FormattedMessage id="pmpDeal.evergreen.label" defaultMessage="Evergreen" />}
            helpText={<EvergreenFieldTooltip />}
            containerClass={styles['input']}
            subtext={
                !!evergreen?.length && (
                    <FormattedMessage
                        id="pmpDeal.evergreen.note"
                        defaultMessage="Please note, this can't be changed after campaign creation."
                    />
                )
            }
        >
            <Evergreen />
        </FormField>
    );
};
