import React from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import usePerformanceRecommendationExecution from '../../hooks/usePerformanceRecommendationExecution';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

const params = {
    titleCode: 'performance-recommendations.lower-cpc.title',
    descriptionCode: 'performance-recommendations.lower-cpc.description',
    actionCode: 'performance-recommendations.lower-cpc.action',
    tooltipCode: 'performance-recommendations.lower-cpc.action.tooltip',
    dismissCode: 'performance-recommendations.general-recommendation.dismiss',
    successCode: 'performance-recommendations.general-recommendation.success-message',
    errorCode: 'performance-recommendations.general-recommendation.error-message',
    iconImg: LightningRoundIcon,
};

export const LowerCPCPerformanceRecommendation = ({ recommendation, messageParams = {} }) => {
    const { campaignId, actions, id: recommendationId, accountName: accountId } = recommendation;
    const [action] = actions;
    const { reduce_bid_by, current_bid, currency } = messageParams;
    const targetCPC = current_bid - reduce_bid_by;
    const cpcWithCurrencySymbol = (
        <FormattedNumber value={targetCPC} variant="currency" currency={currency} maximumFractionDigits={3} />
    );

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId: action.actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: params.errorCode,
    });
    const actionDetails = {
        target_cpc: cpcWithCurrencySymbol,
    };
    const messageDetails = {
        target_cpc: cpcWithCurrencySymbol,
        ...messageParams,
    };
    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageDetails}
            isApplied={apply.isSuccess}
            {...params}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode={params.dismissCode}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode={params.actionCode}
                    actionParams={actionDetails}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode={params.tooltipCode}
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

LowerCPCPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default LowerCPCPerformanceRecommendation;
