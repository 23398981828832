import { get } from 'lodash';
import { DEFAULT_WIDTH, getSheetName } from './excelBulkWorkbookUtils';

export const addDictionarySheets = ({ workbook, formatMessage, dictionaryData, dictionarySheets }) => {
    dictionarySheets.forEach(sheetConfig => {
        const sheetName = getSheetName(sheetConfig.sheetName, formatMessage);
        const dataList = get(dictionaryData, sheetConfig.dataPath, []);
        const worksheet = workbook.addWorksheet(sheetName);

        // Add header row
        const headerRow = worksheet.addRow();
        sheetConfig.columns.forEach((columnConfig, index) => {
            const columnIndex = index + 1;
            const headerCell = headerRow.getCell(columnIndex);
            const columnName = formatMessage({
                id: `excel.bulk.dictionary.data.${sheetConfig.dataPath}.column.header${
                    columnConfig.dataPath ? `.${columnConfig.dataPath}` : ''
                }`,
            });
            headerCell.value = columnName;
            headerCell.font = { ...headerCell.font, bold: true };
            worksheet.getColumn(columnIndex).width = columnConfig.width || DEFAULT_WIDTH;
            worksheet.getColumn(columnIndex).alignment = {
                vertical: 'top',
                horizontal: 'left',
                wrapText: true,
            };
        });

        // Add data rows
        dataList.forEach(itemData => {
            const row = worksheet.addRow();

            sheetConfig.columns.forEach((columnConfig, index) => {
                const columnIndex = index + 1;
                const cellValue = columnConfig.dataPath ? get(itemData, columnConfig.dataPath) : itemData;
                row.getCell(columnIndex).value = cellValue;
            });
        });
    });

    return workbook;
};
