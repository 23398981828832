import { useLayoutEffect, useEffect } from 'react';

export const useMarketplaceAudiencesWithBundlingEffects = ({
    handleReload,
    reloadProviders,
    handleChangeEnabledProviders,
    selectedNodes,
}) => {
    // flow of initial load, pagination, search and providers change
    useLayoutEffect(() => {
        handleReload();
    }, [handleReload]);

    // flow of initial load of providers
    useEffect(() => {
        reloadProviders();
    }, [reloadProviders]);

    // flow to handle change of enabled providers according to selectedNodes
    useEffect(() => {
        handleChangeEnabledProviders(selectedNodes);
    }, [handleChangeEnabledProviders, selectedNodes]);
};
