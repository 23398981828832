import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes, Radio } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import CurrencyIcon from '../../CurrencyIcon/CurrencyIcon';
import commonStyles from '../../commonEditor.module.scss';
import styles from './spendingLimitRadioChoice.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);
const InputWithIndication = withIndication(Input);

const SpendingLimitRadioChoice = ({
    id,
    title,
    currency,
    renderInput,
    radioOption,
    onChange,
    disabled,
    spent,
    failedValidationData,
    ariaLabel,
}) => {
    const showSpentMessage = spent || spent === 0;

    return (
        <div>
            <Radio
                value={radioOption.type}
                title={title}
                disabled={disabled}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={radioOption.type}
            />
            {renderInput && (
                <div className={styles['container']}>
                    <FormattedNumber value={0} minimumIntegerDigits={2}>
                        {placeholder => (
                            <InputWithIndication
                                id={`${id}-${radioOption.type}-input`}
                                type={InputTypes.NUMBER}
                                value={radioOption.value}
                                placeholder={placeholder}
                                onChange={onChange}
                                icon={<CurrencyIcon currency={currency} />}
                                inputPadding={inputPadding}
                                disabled={disabled}
                                data-metrics-event-name={GTM_EVENTS.USABILITY}
                                data-metrics-component="SpendingLimitValue"
                                aria-label={ariaLabel}
                                {...failedValidationData}
                            />
                        )}
                    </FormattedNumber>
                    {showSpentMessage && (
                        <div className={styles['spent']}>
                            <FormattedMessage
                                id="campaign.editor.budget.spent"
                                values={{
                                    value: <FormattedNumber value={spent} variant="currency" currency={currency} />,
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

SpendingLimitRadioChoice.propTypes = {
    id: PropTypes.string,
    title: PropTypes.node,
    budget: PropTypes.object,
    currency: PropTypes.string,
    renderInput: PropTypes.bool,
    radioOption: PropTypes.shape({
        type: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    spent: PropTypes.number,
};

export default SpendingLimitRadioChoice;
