import React from 'react';
import classnames from 'classnames/bind';
import { COMPONENT_STATUS } from 'services/constants';
import BodyLoading from '../../../../campaigns/modules/common-campaign-form/components/BodyLoading/BodyLoading';
import { useAccount } from '../../.././account-configurations/hooks/useAccount';
import { useFormDataContext } from '../../../formData';
import { FormLoadingOverlay } from '../FormLoadingOverlay/FormLoadingOverlay';
import { CustomerSupportPrompt } from './CustomerSupportPrompt';
import styles from './formInitBase.module.scss';

const classNameBuilder = classnames.bind(styles);

//TODO: https://jira.taboola.com/browse/DEV-130322
export const FormInitBase = ({
    children,
    className,
    loadingComponent: LoadingComponent,
    loadingOverlay: LoadingOverlay = FormLoadingOverlay,
    loadingOverlayProps,
}) => {
    const { isError, isAccountFetched, accountName } = useAccount();
    const { submitStatus, fetchStatus } = useFormDataContext();

    return (
        <div
            className={classNameBuilder('creator', className)}
            data-automation="formInitBase"
            data-context-account-name={accountName}
        >
            {(fetchStatus === COMPONENT_STATUS.LOADING ||
                fetchStatus === COMPONENT_STATUS.INITIAL ||
                !isAccountFetched) &&
                !isError && <LoadingComponent className={styles['body-loading']} />}
            {fetchStatus === COMPONENT_STATUS.ACTIVE && isAccountFetched && children}
            {(fetchStatus === COMPONENT_STATUS.ERROR || isError) && <CustomerSupportPrompt />}
            {(submitStatus === COMPONENT_STATUS.LOADING || !isAccountFetched) && !isError && (
                <LoadingOverlay {...loadingOverlayProps} />
            )}
        </div>
    );
};

FormInitBase.defaultProps = {
    loadingComponent: BodyLoading,
};

export default FormInitBase;
