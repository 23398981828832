import { BarGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import CampaignGraphTooltip from 'modules/campaigns/modules/campaigns-reports/components/Tooltips/CampainGraphTooltip';
import metrics from 'modules/campaigns/modules/campaigns-reports/config/metrics';
import * as FIELDS from '../../fields';

const barGraph = {
    type: GRAPHS.BAR,
    endpoint: 'hierarchy-campaign-report-by-campaign-daily',
    metrics: [
        metrics.CLICKS,
        metrics.IMPRESSIONS,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.SPENT,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CONVERSIONS_VALUE,
        metrics.CPM,
        metrics.VCPM,
        metrics.CTR,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.NAME.field,
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

export const graph = [barGraph];
