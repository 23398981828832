import React from 'react';
import PropTypes from 'prop-types';
import { PAYMENT_STATUS_TYPE } from 'modules/billing-and-payments/config/paymentStatusConsts';
import { useCancelFunds } from '../../../../../billing-and-payments/hooks/useCancelFunds';
import { COMMON_FLAGS, useCommonConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { usePermissions } from '../../../../../taboola-common-frontend-modules/authentication';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import ActionsCellRenderer from './ActionsCellRenderer';

const PaymentCancelTooltipContent = ({ isDisabled, paymentMethod }) =>
    isDisabled ? (
        <FormattedMessage
            id={`app.payments.cancel.payment.${paymentMethod}.icon.disabled.tooltip`}
            defaultMessage="Currently you do not have the necessary permissions to cancel a payment. If you need to change the permissions, please contact your account manager or support."
        />
    ) : (
        <FormattedMessage
            id={`app.payments.cancel.payment.${paymentMethod}.icon.enabled.tooltip`}
            defaultMessage="Cancel Payment"
        />
    );

const PaymentActionsCellRenderer = ({ data, onCancel, context, ...rest }) => {
    const { [COMMON_FLAGS.CANCEL_PAYMENT_ALLOWED_PAYMENT_METHODS]: cancelPaymentAllowedPaymentMethodsConfig } =
        useCommonConfig([COMMON_FLAGS.CANCEL_PAYMENT_ALLOWED_PAYMENT_METHODS]);
    const hasCancelPaymentPermission = usePermissions('CANCEL_FUNDS');
    const paymentMethod = data.paymentMethod;
    const paymentStatus = data.paymentStatus;
    const cancelFunds = useCancelFunds({ paymentId: data.paymentId, paymentMethod: data.paymentMethod });

    const buttonConfigs = [
        {
            onClick: () => cancelFunds(data),
            isDisabled: !hasCancelPaymentPermission,
            dataMetricComponent: 'CancelPaymentActionButton',
            iconLabel: 'delete',
            tooltipContent: (
                <PaymentCancelTooltipContent isDisabled={!hasCancelPaymentPermission} paymentMethod={paymentMethod} />
            ),
        },
    ];

    if (
        cancelPaymentAllowedPaymentMethodsConfig?.includes(paymentMethod) &&
        (PAYMENT_STATUS_TYPE.PENDING === paymentStatus || PAYMENT_STATUS_TYPE.PENDING_FUNDS === paymentStatus)
    ) {
        return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
    }

    return <div />;
};

PaymentActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    onEdit: PropTypes.func,
    onDuplicate: PropTypes.func,
    onTogglePolicyState: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default PaymentActionsCellRenderer;
