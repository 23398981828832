import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ImageIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { CAMPAIGNS_ROUTE } from '../../../../config';
import { LogoField } from '../../../creative-creator/components/LogoField/LogoField';
import { useIsAppInstallCreativeInEditMode } from '../../../creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { CreativePreviewBase } from '../../../creative-preview';
import { useMatchingCreativeIds } from '../../hooks';
import { useIsCarouselCreative } from '../../hooks/useIsCarouselCreative';
import { ThumbnailEditorBaseWithIndication } from '../ThumbnailEditor/Base/ThumbnailEditorBase';
import { CreativeEditorFormBody } from './Body';
import styles from './creativeEditorForm.module.scss';

const mediaSectionTitle = <FormattedMessage id="creative.editor.media" defaultMessage="Media" />;

export const CreativeEditorForm = ({
    accountId,
    campaignId,
    creativeId,
    campaign: { brandingText, campaignProfile, startDate: startDateCampaign, endDate: endDateCampaign },
    onCancel,
    headerLabel,
    addIdToMatchingCreativeIds,
    showSetupSection,
    hideStatusSection,
    selectedAccount,
}) => {
    const { data: creative } = useFormDataContext();
    const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);
    const [applyFocusToMatches, setApplyFocusToMatches] = useState(false);
    const isTrackingSectionEnabledByConfig = useConfigMatch({ [FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING]: 'true' });
    const isLogoFieldEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' });
    const isCarouselCreative = useIsCarouselCreative();
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    const isLogoFieldVisible = isLogoFieldEnabled && !isCarouselCreative && !isAppInstall;
    const isTrackingSectionEnabledByPermission = usePermissions('CREATIVE_EDIT_TRACKING');
    const isTrackingSectionEnabled = isTrackingSectionEnabledByConfig || isTrackingSectionEnabledByPermission;
    const { title, description, cta, thumbnailUrl, creativeFocus } = creative;
    const { matchingCreativeIds, matchingCreativeIdsToSave } = useMatchingCreativeIds(
        accountId,
        campaignId,
        creativeId,
        thumbnailUrl,
        applyFocusToMatches,
        addIdToMatchingCreativeIds
    );
    const language = get(campaignProfile, 'language', 'EN');

    const { onChange: onChangeMatchingIds } = useFormFieldValue({ field: 'matchingCreativeIds' });

    useEffect(() => {
        if (!isEmpty(matchingCreativeIdsToSave)) {
            onChangeMatchingIds(matchingCreativeIdsToSave);
        }
    }, [matchingCreativeIdsToSave, onChangeMatchingIds]);

    return (
        <>
            <CreativeEditorFormBody
                className={styles['editor']}
                headerLabel={headerLabel}
                hideStatusSection={hideStatusSection}
                selectedAccount={selectedAccount}
                showSetupSection={showSetupSection}
                language={language}
                mediaSection={
                    <CommonCollapsibleCard
                        id="CREATIVE_MEDIA"
                        header={<SectionHeader headerIcon={<ImageIcon />} headerText={mediaSectionTitle} />}
                        sourcePath={CAMPAIGNS_ROUTE}
                        onCancel={onCancel}
                    >
                        <ThumbnailEditorBaseWithIndication
                            id="thumbnailUrl"
                            accountId={accountId}
                            campaignId={campaignId}
                            isThumbnailLoading={isThumbnailLoading}
                            setIsThumbnailLoading={setIsThumbnailLoading}
                            applyFocusToMatches={applyFocusToMatches}
                            setApplyFocusToMatches={setApplyFocusToMatches}
                            matchingCreativeId={matchingCreativeIds}
                            matchingCreativeIds={matchingCreativeIds}
                        />
                        {isLogoFieldVisible && <LogoField />}
                    </CommonCollapsibleCard>
                }
                isIncludeTrackingSection={isTrackingSectionEnabled}
                startDateCampaign={startDateCampaign}
                endDateCampaign={endDateCampaign}
            ></CreativeEditorFormBody>
            <CreativePreviewBase
                onCancel={onCancel}
                thumbnailUrl={thumbnailUrl}
                title={title}
                description={description}
                cta={cta}
                creativeId={creativeId}
                brandingText={brandingText}
                creativeFocus={creativeFocus}
                language={language}
            />
        </>
    );
};

CreativeEditorForm.propTypes = {
    accountId: PropTypes.string,
    campaignId: PropTypes.string,
    creativeId: PropTypes.string,
    match: PropTypes.object,
    campaign: PropTypes.object,
    onCancel: PropTypes.func,
    headerLabel: PropTypes.node,
    addIdToMatchingCreativeIds: PropTypes.bool,
    showSetupSection: PropTypes.bool,
    hideStatusSection: PropTypes.bool,
    selectedAccount: PropTypes.object,
};

CreativeEditorForm.defaultProps = {
    showSetupSection: false,
    campaign: {},
};
