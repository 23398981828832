import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './collapsiblePickerListItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const CollapsiblePickerListItem = props => {
    const { item, isSelected, onSelect } = props;
    const { label } = item;

    return (
        <div
            className={classNameBuilder('list-item', { selected: isSelected })}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value="Single"
            onClick={() => onSelect(item)}
        >
            <div className={styles['item-content']}>{label}</div>
        </div>
    );
};

CollapsiblePickerListItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
    }),
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
};

export default CollapsiblePickerListItem;
