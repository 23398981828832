import uuid from 'uuid/v1';
import { HOUR_OPTIONS, WEEKDAYS_AND_GROUPS } from './dayTimeDaysOptions';

export const getDefaultBidDayParting = () => ({
    day: WEEKDAYS_AND_GROUPS[0],
    fromHour: HOUR_OPTIONS[0].value,
    untilHour: HOUR_OPTIONS[24].value,
    cpcModification: 0,
    id: uuid(),
});
