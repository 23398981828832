import { AUDIENCE_TYPE, CRM_FILE_TYPE, CRM_TARGETING_TYPE } from './audiencesConsts';

export const predictiveAudienceInitialValues = {
    type: ({ pixelLookalikePermitted }) =>
        pixelLookalikePermitted ? AUDIENCE_TYPE.PBP_LOOKALIKE : AUDIENCE_TYPE.LOOKALIKE,
    crmFileType: CRM_FILE_TYPE.EMAIL,
    crmTargetingType: CRM_TARGETING_TYPE.LOOKALIKE,
    businessOfExtendingCredit: false,
    excludeFromCampaigns: false,
};
