import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks';
import { addStaticIndication, removeStaticIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { hasUnsavedChangesSelector } from 'selectors';

let counter = 0;
export const LIMIT = 200_000;
const ONE_MINUTE = 60_000;
const SECOND = 1000;

export const MAX_RELOAD_INTERVAL = 20 * ONE_MINUTE;
export const UPDATE_ACTIVE_TAB_INTERVAL = 30 * SECOND;
export const BACKGROUND_WAIT_FOR_RELOAD = 10 * SECOND;
export const IDLE_TIMEOUT = 30 * SECOND;

export const resetMemoryCounter = () => (counter = 0);
export const increaseMemoryCounter = () => counter++;
const isMemoryLimitReached = () => counter >= LIMIT;

const errorIndication = {
    message: (
        <FormattedMessage
            id="app.performance.error.message"
            defaultMessage="We’re aware of some temporary slowness. For the best possible experience, please <a>click here</a> to refresh the page."
        />
    ),
    type: INDICATION_TYPES.WARNING,
};

export const useMemoryControl = () => {
    const dispatch = useDispatch();
    const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);
    const hasUnsavedChangesGetter = useCurrentValueGetter(hasUnsavedChanges);
    const [totalActiveTime, setTotalActiveTime] = useState(0);

    const { getTotalActiveTime } = useIdleTimer({
        timeout: IDLE_TIMEOUT,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setTotalActiveTime(getTotalActiveTime());
        }, UPDATE_ACTIVE_TAB_INTERVAL);

        return () => clearInterval(interval);
    }, [getTotalActiveTime]);

    useEffect(
        function reloadHiddenTabOnMemoryLimitReached() {
            if (!document.hidden) {
                return;
            }
            const reloadTimerId = setTimeout(() => {
                if (
                    document.hidden &&
                    (isMemoryLimitReached() || totalActiveTime > MAX_RELOAD_INTERVAL) &&
                    !hasUnsavedChangesGetter()
                ) {
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { component: 'MemoryControl' });
                    window.location.reload();
                }
            }, BACKGROUND_WAIT_FOR_RELOAD);

            return () => {
                clearTimeout(reloadTimerId);
            };
        },
        [hasUnsavedChangesGetter, totalActiveTime]
    );

    useEffect(
        function showIndicationOnMemoryLimitReached() {
            if ((isMemoryLimitReached() || totalActiveTime > MAX_RELOAD_INTERVAL) && !hasUnsavedChangesGetter()) {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { component: 'MemoryControlNotification' });
                dispatch(removeStaticIndication(errorIndication));
                dispatch(addStaticIndication(errorIndication));
            }
        },
        [dispatch, hasUnsavedChangesGetter, totalActiveTime]
    );
};
