import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, merge } from 'lodash';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms/hooks/useFormState';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { errorMessagesUtils } from '../../../services/utils';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import getChangesObject from '../../taboola-common-frontend-modules/utils/objectDiff';
import { BILLING_AND_PAYMENTS_ROUTE } from '../config';
import { PAYMENT_METHOD_ID_PARAM_NAME } from '../config/routes/paymentMethod';
import { transformFromGW, transformToGW } from '../services/editorTransformers';
import { useBillingActions, useBillingData } from './useBillingData';

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="paymentMethod.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const successIndication = {
    message: <FormattedMessage id="paymentMethod.save.success" defaultMessage="Payment method successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const usePaymentMethodEditorState = () => {
    const dispatch = useDispatch();
    const { isError: isErrorBillingData, isSuccess: isSuccessBillingData, billingData } = useBillingData();
    const { updatePaymentMethod } = useBillingActions();
    const { formAccount } = useFormDataContext();
    const { [PAYMENT_METHOD_ID_PARAM_NAME]: paymentMethodId } = useMultiLayerPathParams();
    const fetchedFormData = useMemo(() => {
        const { paymentMethodsV2 = [] } = billingData;
        const paymentMethodBeingEdited = paymentMethodsV2.find(({ id }) => id === +paymentMethodId);

        return {
            isSuccess: isSuccessBillingData && !!paymentMethodBeingEdited,
            isError: isErrorBillingData || (isSuccessBillingData && !paymentMethodBeingEdited),
            data: paymentMethodBeingEdited ? transformFromGW(paymentMethodBeingEdited) : {},
        };
    }, [billingData, isErrorBillingData, isSuccessBillingData, paymentMethodId]);

    const { submit } = useFormState({ fetchedFormData });
    const handleSubmit = useCallback(() => {
        const updatePaymentMethodWrapper = async ({ billingAddress, taxData }) => {
            try {
                const billingAddressChanges = billingAddress
                    ? getChangesObject(billingAddress, fetchedFormData.data?.billingAddress)
                    : {};
                const taxDataChanges = taxData ? getChangesObject(taxData, fetchedFormData.data?.taxData) : {};
                const paymentMethodChanges = merge(
                    {},
                    isEmpty(billingAddressChanges) ? {} : { billingAddress: billingAddressChanges },
                    isEmpty(taxDataChanges) ? {} : { taxData: taxDataChanges }
                );

                await updatePaymentMethod({
                    accountId: formAccount.accountName,
                    paymentMethodId,
                    paymentMethodChanges: transformToGW(paymentMethodChanges),
                });
                dispatch(addIndication(successIndication));

                return true;
            } catch (e) {
                dispatch(addIndication(generateErrorIndication(e)));
                throw e;
            }
        };

        return submit(updatePaymentMethodWrapper, BILLING_AND_PAYMENTS_ROUTE);
    }, [dispatch, fetchedFormData.data, formAccount.accountName, paymentMethodId, submit, updatePaymentMethod]);

    return {
        submit: handleSubmit,
    };
};
