'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var SORTING_ORDER_TYPES = {
    ASCENDANT: 'asc',
    DESCENDANT: 'desc',
    NON_SORTED: null
};

exports.default = SORTING_ORDER_TYPES;