import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';

const TargetingItemDropdownOption = ({ data: { label, code }, mrgIdPrefix = '' }) => (
    <FormattedMessage id={`${mrgIdPrefix}.${code}`} defaultMessage={label} />
);

TargetingItemDropdownOption.propTypes = {
    data: PropTypes.object,
    mrgIdPrefix: PropTypes.string,
};

export default TargetingItemDropdownOption;
