"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgOnline(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M6.5 8H8.13912L10.1443 18.6176C10.3228 19.7644 10.9174 20.6929 11.7465 21.2849C11.165 21.6347 10.7759 22.2719 10.7759 23C10.7759 24.1046 11.6714 25 12.7759 25C13.8805 25 14.7759 24.1046 14.7759 23C14.7759 22.6357 14.6785 22.2942 14.5084 22H21.0435C20.8733 22.2942 20.7759 22.6357 20.7759 23C20.7759 24.1046 21.6714 25 22.7759 25C23.8805 25 24.7759 24.1046 24.7759 23C24.7759 22.6069 24.6625 22.2404 24.4667 21.9312C24.6143 21.8443 24.7134 21.6837 24.7134 21.5C24.7134 21.2239 24.4896 21 24.2134 21H14.0101C12.8843 21 11.8784 20.3814 11.3929 19.3313L20.6186 18.6332C23.266 18.568 25.2708 16.8919 25.2708 14.2651L25.385 10.5164C25.3943 10.234 25.1679 10 24.8853 10H9.5371L9.04907 7.41125L8.9749 7H6.5C6.22386 7 6 7.22386 6 7.5C6 7.77614 6.22386 8 6.5 8ZM22.7759 22C22.2759 22 22.0259 22 21.9009 22.125C21.7759 22.25 21.7759 22.5 21.7759 23C21.7759 23.5 21.7759 23.75 21.9009 23.875C22.0259 24 22.2759 24 22.7759 24C23.2759 24 23.5259 24 23.6509 23.875C23.7759 23.75 23.7759 23.5 23.7759 23C23.7759 22.5 23.7759 22.25 23.6509 22.125C23.5259 22 23.2759 22 22.7759 22ZM9.72562 11L11.1112 18.3497L20.5685 17.6348C22.7381 17.5808 24.2708 16.2995 24.2711 14.2487L24.3686 11H9.72562ZM13.6509 23.875C13.5259 24 13.2759 24 12.7759 24C12.2759 24 12.0259 24 11.9009 23.875C11.7759 23.75 11.7759 23.5 11.7759 23C11.7759 22.5 11.7759 22.25 11.9009 22.125C12.0259 22 12.2759 22 12.7759 22C13.2759 22 13.5259 22 13.6509 22.125C13.7759 22.25 13.7759 22.5 13.7759 23C13.7759 23.5 13.7759 23.75 13.6509 23.875Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgOnline;