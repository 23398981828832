import { AchBankDirectDebit } from '../components/AchBankDirectDebit/AchBankDirectDebit';
import { InstructionsAchBankDirectDebit } from '../components/AddFundsModal/PaymentMethods/Instructions/InstructionsAchBankDirectDebit';
import { InstructionsBankTransfer } from '../components/AddFundsModal/PaymentMethods/Instructions/InstructionsBankTransfer';
import { InstructionsCreditCard } from '../components/AddFundsModal/PaymentMethods/Instructions/InstructionsCreditCard';
import { InstructionsHostedInvoice } from '../components/AddFundsModal/PaymentMethods/Instructions/InstructionsHostedInvoice';
import { CreditCard } from '../components/CreditCard/CreditCard';
import { PAYMENT_TYPE } from './PaymentType';

const PULL_PAYMENT_METHOD_TYPE_COMPONENTS = {
    [PAYMENT_TYPE.CARD]: {
        paymentMethodComponent: CreditCard,
        defaultAddMessage: 'Add Credit Card Payment',
    },
    [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: {
        paymentMethodComponent: AchBankDirectDebit,
        defaultAddMessage: 'Connect Bank Account (ACH)',
    },
};

const PAYMENT_METHOD_TYPE_METADATA = {
    [PAYMENT_TYPE.CARD]: { instructionsComponent: InstructionsCreditCard },
    [PAYMENT_TYPE.HOSTED_INVOICE]: { instructionsComponent: InstructionsHostedInvoice },
    [PAYMENT_TYPE.BANK_TRANSFER]: { instructionsComponent: InstructionsBankTransfer },
    [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: { instructionsComponent: InstructionsAchBankDirectDebit },
};

const getPaymentMethodComponent = paymentType =>
    PULL_PAYMENT_METHOD_TYPE_COMPONENTS[paymentType]?.paymentMethodComponent;

const getPaymentMethodDefaultAddMessage = paymentType =>
    PULL_PAYMENT_METHOD_TYPE_COMPONENTS[paymentType]?.defaultAddMessage;

const getPaymentMethodInstructionsComponent = paymentType =>
    PAYMENT_METHOD_TYPE_METADATA[paymentType]?.instructionsComponent;

export { getPaymentMethodComponent, getPaymentMethodDefaultAddMessage, getPaymentMethodInstructionsComponent };
