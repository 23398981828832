import { FormField } from 'taboola-ultimate-ui';
import { useIsSupplyTargetingSelectPermitted } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useIsSupplyTargetingSelectPermitted';
import { useSupplyTargetingFormField } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useSupplyTargetingFormField';
import { useSupplyTargettingSubtext } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useSupplyTargettingSubtext';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SupplyTargetingSelects } from './SupplyTargetingSelect/SupplyTargetingSelect';
import styles from './SupplyTargetingSelect/SupplyTargetingSelect.module.scss';

export const SupplyTargetingSelectField = () => {
    const isPermitted = useIsSupplyTargetingSelectPermitted();
    const { value: supplyTargeting } = useSupplyTargetingFormField();
    const { subtext, setHovered } = useSupplyTargettingSubtext(supplyTargeting, 'campaign.editor.targeting.supply');
    if (!isPermitted) {
        return null;
    }

    return (
        <FormField
            inputId="targeting-supply-select"
            label={<FormattedMessage id="campaign.editor.targeting.supply.label" defaultMessage="Environment" />}
            subtext={subtext}
            containerClass={styles['container']}
            labelContainerClass={styles['label-container']}
        >
            <SupplyTargetingSelects onHover={setHovered} />
        </FormField>
    );
};
