import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import LearnMore from '../LearnMore';
import TooltipSection from '../TooltipSection';

const CpaGoalTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.cpa.goal.help.overview"
            defaultMessage="When a user takes a desired action on your landing page, it is called a conversion."
        />
        <FormattedMessage
            id="campaign.editor.cpa.goal.help.example"
            defaultMessage="This conversion could be a product purchase, a pageview, an email signup or any action that is valuable to your business."
        />
        <FormattedMessage
            id="campaign.editor.cpa.goal.help.definition"
            defaultMessage="Your Cost-Per-Action or CPA, is the amount you pay for each of these actions."
        />
        <LearnMore href="https://help.taboola.com/hc/en-us/articles/115006602167-Overview-Your-Cost-Per-Action-CPA-" />
    </TooltipSection>
);

export default CpaGoalTooltip;
