import React from 'react';
import { BackgroundIcon } from 'tuui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CreativeStudioBaseHeader } from '../CreativeStudioBaseHeader';

export const ReplaceBackgroundContentHeader = () => {
    const isSegmentAnythingEnabled = useConfigMatch({
        [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING]: 'true',
    });

    return (
        <CreativeStudioBaseHeader
            icon={<BackgroundIcon />}
            titleMessageId="creative.studio.replace.background.header.title"
            subTitleMessageId={
                isSegmentAnythingEnabled
                    ? 'creative.studio.replace.background.header.alternate.sub.title'
                    : 'creative.studio.replace.background.header.sub.title'
            }
        />
    );
};
