import React from 'react';
import { BaseGalleryItemDropdown } from '../../GalleryItemDropdown/BaseGalleryItemDropdown';
import { CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { useStudioGalleryItemDropdown } from './useStudioGalleryItemDropdown';
import styles from './studioGalleryItemDropdown.module.scss';

export const StudioGalleryItemDropdown = props => {
    const { options, onClick } = useStudioGalleryItemDropdown();
    const { mode, campaignId } = useCreativeStudioContext();
    const eventsLocationPrefix = CREATIVE_STUDIO_MODE_EVENTS_MAP[mode];

    return (
        <BaseGalleryItemDropdown
            containerClassName={styles['container']}
            onClick={onClick}
            options={options}
            campaignId={campaignId}
            eventsLocationPrefix={eventsLocationPrefix}
            {...props}
        />
    );
};
