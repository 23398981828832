import { useMemo } from 'react';
import { useApi } from '../hooks';
import { userSettingsApiFactory } from './userSettingsApiFactory';

export const useUserSettingsApi = () => {
    const api = useApi();
    const useSettingsApi = useMemo(() => userSettingsApiFactory(api), [api]);

    return useSettingsApi;
};
