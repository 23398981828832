import React, { useState, createContext, useMemo, useContext, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { scrollToElement } from 'modules/taboola-common-frontend-modules/formData/scroll-utils';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { aiGenerationApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { AI_VARIATIONS_FIELD_NAMES } from '../../constants/aiVariationsConstants';
import { useCreateFormSelectedCampaignId } from '../../hooks/useCreateFormSelectedCampaignId';
import { useAiVariationsEventsPrefix } from './hooks/useAiVariationsEventsPrefix';
import { languageOptions } from './languageOptions';

const successIndication = {
    message: (
        <FormattedMessage
            id="creative.creator.aiVariationsSetup.generate.successIndication"
            defaultMessage="Scroll down to see your AI generated suggestions"
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="done.highlight" />,
};

const errorIndication = {
    message: (
        <FormattedMessage
            id="creative.creator.aiVariationsSetup.generate.errorIndication"
            defaultMessage="We were unable to generate titles and descriptions. Please try again later or try using different product details."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const AiVariationsContext = createContext({});

export const useAiVariationsContext = () => useContext(AiVariationsContext);

export const AiVariationsContextProvider = ({
    children,
    campaignId,
    crawlLpStatus: initialCrawlLpStatus = COMPONENT_STATUS.INITIAL,
    landingPageData: initialLandingPageData,
}) => {
    const dispatch = useDispatch();
    const [crawlLpStatus, setCrawlLpStatus] = useState(initialCrawlLpStatus);
    const { value: productDescription = '', onChange: onProductDescriptionChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_DESCRIPTION,
    });
    const { value: targetAudience = '', onChange: onTargetAudienceChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_TARGET_AUDIENCE,
    });
    const { value: uniqueSellingProposition = '', onChange: onUniqueSellingPropositionChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_USP,
    });
    const { value: language = languageOptions[0], onChange: onLanguageChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.LANGUAGE,
    });
    const [aiTitleSuggestions, setAiTitleSuggestions] = useState([]);
    const [aiDescriptionSuggestions, setAiDescriptionSuggestions] = useState([]);
    const [numOfAiTitleSuggestions, setNumOfAiTitleSuggestions] = useState(0);
    const [numOfAiDescriptionSuggestions, setNumOfAiDescriptionSuggestions] = useState(0);
    const [latestGeneratedProductDetails, setLatestGeneratedProductDetails] = useState();
    const [landingPageData, setLandingPageData] = useState(initialLandingPageData);
    const aiVariationsContentSectionRef = useRef();
    const controllerRef = useRef(null);
    const { formAccount } = useFormDataContext();
    const formCampaignId = useCreateFormSelectedCampaignId();
    const currentCampaignId = campaignId || formCampaignId;
    const eventsPrefix = useAiVariationsEventsPrefix();

    const setPageProductDetails = useCallback(
        response => {
            onProductDescriptionChange(response.productDescription);
            onTargetAudienceChange(response.targetAudience);
            onUniqueSellingPropositionChange(response.uniqueSellingProposition);
        },
        [onProductDescriptionChange, onTargetAudienceChange, onUniqueSellingPropositionChange]
    );

    const fetchPageProductDetails = useCallback(
        async url => {
            controllerRef.current = new AbortController();
            setCrawlLpStatus(COMPONENT_STATUS.LOADING);
            try {
                const response = await aiGenerationApi.getPageProductDetails({
                    accountId: formAccount.accountName,
                    campaignId: currentCampaignId,
                    url,
                    signal: controllerRef.current.signal,
                });

                if (response) {
                    const {
                        productDescription,
                        targetAudience,
                        uniqueSellingProposition,
                        crawledTitle: landingPageTitle,
                        crawledDescription: landingPageDescription,
                        crawledContent: landingPageContent,
                    } = response;
                    setPageProductDetails(response);
                    setLatestGeneratedProductDetails({ productDescription, targetAudience, uniqueSellingProposition });
                    setLandingPageData({ landingPageTitle, landingPageDescription, landingPageContent });
                    setCrawlLpStatus(COMPONENT_STATUS.ACTIVE);
                } else {
                    setCrawlLpStatus(COMPONENT_STATUS.EMPTY);
                }
            } catch (e) {
                if (!controllerRef.current.signal.aborted) {
                    setCrawlLpStatus(COMPONENT_STATUS.ERROR);
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${eventsPrefix}_Landing_Page_Loader_Error`,
                        taboolaCampaignId: currentCampaignId,
                        value: url,
                        pageName: getPageName(),
                    });
                } else {
                    setCrawlLpStatus(COMPONENT_STATUS.EMPTY);
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${eventsPrefix}_Landing_Page_Loader_Skipped`,
                        taboolaCampaignId: currentCampaignId,
                        value: url,
                        pageName: getPageName(),
                    });
                }
            }
        },
        [currentCampaignId, formAccount.accountName, setPageProductDetails, eventsPrefix]
    );

    const cancelFetchPageProductDetails = useCallback(() => {
        controllerRef.current?.abort();
    }, []);

    const onGenerationSuccess = useCallback(
        results => {
            if (results?.titles?.length) {
                dispatch(addIndication(successIndication));
                scrollToElement(aiVariationsContentSectionRef.current, { behavior: 'smooth', block: 'start' });
            }
        },
        [aiVariationsContentSectionRef, dispatch]
    );

    const onGenerationError = useCallback(
        error => {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${eventsPrefix}_Generate_Titles_Error`,
                taboolaCampaignId: currentCampaignId,
                pageName: getPageName(),
                value: JSON.stringify(error),
            });
            dispatch(addIndication(errorIndication));
        },
        [dispatch, currentCampaignId, eventsPrefix]
    );

    const value = useMemo(
        () => ({
            crawlLpStatus,
            setCrawlLpStatus,
            fetchPageProductDetails,
            setPageProductDetails,
            cancelFetchPageProductDetails,
            productDescription,
            onProductDescriptionChange,
            targetAudience,
            onTargetAudienceChange,
            uniqueSellingProposition,
            onUniqueSellingPropositionChange,
            language,
            onLanguageChange,
            landingPageData,
            setLandingPageData,
            aiTitleSuggestions,
            setAiTitleSuggestions,
            aiDescriptionSuggestions,
            setAiDescriptionSuggestions,
            numOfAiTitleSuggestions,
            setNumOfAiTitleSuggestions,
            numOfAiDescriptionSuggestions,
            setNumOfAiDescriptionSuggestions,
            latestGeneratedProductDetails,
            setLatestGeneratedProductDetails,
            aiVariationsContentSectionRef,
            onGenerationSuccess,
            onGenerationError,
        }),
        [
            crawlLpStatus,
            setCrawlLpStatus,
            fetchPageProductDetails,
            setPageProductDetails,
            cancelFetchPageProductDetails,
            productDescription,
            onProductDescriptionChange,
            landingPageData,
            setLandingPageData,
            targetAudience,
            onTargetAudienceChange,
            uniqueSellingProposition,
            onUniqueSellingPropositionChange,
            language,
            onLanguageChange,
            aiTitleSuggestions,
            setAiTitleSuggestions,
            aiDescriptionSuggestions,
            setAiDescriptionSuggestions,
            numOfAiTitleSuggestions,
            setNumOfAiTitleSuggestions,
            numOfAiDescriptionSuggestions,
            setNumOfAiDescriptionSuggestions,
            latestGeneratedProductDetails,
            setLatestGeneratedProductDetails,
            aiVariationsContentSectionRef,
            onGenerationSuccess,
            onGenerationError,
        ]
    );

    return <AiVariationsContext.Provider value={value}>{children}</AiVariationsContext.Provider>;
};
