import React from 'react';
import { generateErrorIndication } from 'services/utils';
import { selectedAccountSelector } from '../../../selectors';
import { creativesApi, videoCreativesApi } from '../../../services/api';
import { isVideoCampaign } from '../../../services/campaignDimension';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { requestDeleteCreative, deleteCreativeError, deleteCreativeSuccess } from '../actions';
import { removeCreativeItemRow } from '../modules/campaigns-reports/actions';

const indications = {
    error: {
        message: <FormattedMessage id="creative.delete.error" defaultMessage="We were not able to delete the ad" />,
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
    },
    success: {
        message: <FormattedMessage id="creative.delete.success" defaultMessage="Ad successfully deleted" />,
        type: INDICATION_TYPES.SUCCESS,
        highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    },
};

const deleteCreative = data => (dispatch, getState) => {
    const { accountName, campaignId, id, dimension } = data;
    const { accountId: selectedAccountId } = selectedAccountSelector(getState());
    const accountId = accountName || selectedAccountId;

    dispatch(requestDeleteCreative());
    let deleteCreative = isVideoCampaign(dimension)
        ? videoCreativesApi.deleteCreative(accountId, campaignId, id)
        : creativesApi.deleteCreative(accountId, campaignId, id);

    deleteCreative
        .then(creativeItem => {
            dispatch(deleteCreativeSuccess(creativeItem));
            dispatch(addIndication(indications.success));
            dispatch(removeCreativeItemRow(id));
        })
        .catch(error => {
            dispatch(deleteCreativeError(error));
            dispatch(addIndication(generateErrorIndication(error, indications.error)));
        });
};

export default deleteCreative;
