import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dotdotdot, withMergedStyles, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';
import { ReportSearchHighlight } from '../ReportSearchHighlight/ReportSearchHighlight';
import styles from './ellipsisCellRenderer.module.scss';

class EllipsisCellRenderer extends Component {
    render() {
        const {
            value,
            valueFormatted,
            lines,
            classes: { container: containerClass, value: valueClass },
            isSearchable,
            hasTooltip,
        } = this.props;
        return (
            <>
                <Dotdotdot className={containerClass} lines={lines}>
                    <span className={valueClass}>
                        {valueFormatted ||
                            (isSearchable && value ? <ReportSearchHighlight text={String(value)} /> : value)}
                    </span>
                </Dotdotdot>
                {hasTooltip && value ? (
                    <Tooltip arrow position={TOOLTIP_POSITION.TOP} offset={-10}>
                        <TooltipSection>{value}</TooltipSection>
                    </Tooltip>
                ) : null}
            </>
        );
    }
}

EllipsisCellRenderer.classNames = {
    container: 'container',
    value: 'value',
};

EllipsisCellRenderer.propTypes = {
    value: PropTypes.node,
    valueFormatted: PropTypes.node,
    lines: PropTypes.number,
    classes: PropTypes.object,
    showTooltip: PropTypes.bool,
};

EllipsisCellRenderer.defaultValues = {
    lines: 1,
    classes: EllipsisCellRenderer.classNames,
    hasTooltip: false,
};
export { EllipsisCellRenderer };
export default withMergedStyles(EllipsisCellRenderer, styles);
