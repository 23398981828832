import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { OutlineEditableText } from 'modules/campaigns/components/OutlineEditableText/OutlineEditableText';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './performanceRulesNameField.module.scss';

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);
const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'performance.rules.validations.error.name.empty',
        defaultMessage: 'Please enter an Performance Rule Name',
    },
];
export const PerformanceRulesNameField = () => {
    const {
        value: name,
        onChange: onNameChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'ruleName',
        validations,
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `performance.rules.name.placeholder`,
        defaultMessage: 'Type name here...',
    });

    return (
        <FormField
            inputId="performance-rules-name-input"
            containerClass={styles['input-container']}
            label={<FormattedMessage id="app.create.performance.rules.setup.name.title" defaultMessage="Rule Name" />}
        >
            <OutlineEditableTextWithIndication
                id="name"
                value={name}
                onChange={onNameChange}
                placeholder={placeholder}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="performance-rule-name"
                {...indicationData}
            />
        </FormField>
    );
};

export default PerformanceRulesNameField;
