'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var STATUS_INDICATOR_TYPES = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    INTERMEDIARY: 'intermediary'
};

exports.default = STATUS_INDICATOR_TYPES;