import emojiRegex from 'emoji-regex';
import { identity, isEmpty, isNil } from 'lodash';

// https://stackoverflow.com/questions/958908/how-do-you-reverse-a-string-in-place-in-javascript
export const reverseStr = str => str.split('').reverse().join('');

export const DELIMITER_REGEXP = /[,;\n\t]/g;
export const LINE_DELIMITER = '\n';
export const parseMultilineContent = text =>
    text
        .split(DELIMITER_REGEXP)
        .map(val => val.trim())
        .filter(Boolean);

export const removePrefix = (str, prefix) => (str.indexOf(prefix) === 0 ? str.substring(prefix.length) : str);
export const removePrefixes = (str, prefixes) => prefixes.reduce(removePrefix, str);

export const removeHtmlPrefix = str => str.replace('http://', '').replace('https://', '');

export const splitOnNewLine = (str, options = {}) => {
    const { removeWhitespace } = options;

    let strArr = str.split(LINE_DELIMITER);

    if (!removeWhitespace) {
        return strArr;
    }

    return strArr.map(subStr => subStr.trim()).filter(identity);
};

export const isNilOrEmptyString = str => isNil(str) || isEmpty(str.trim());

export const isEmojiInString = (str, whiteListRegEx = null) =>
    !(str.match(whiteListRegEx)?.length === str.match(emojiRegex())?.length);

export const encodeBase64 = data => Buffer.from(data, 'utf8').toString('base64');

export const decodeBase64 = data => Buffer.from(data, 'base64').toString('utf8');

export default {
    reverseStr,
    removePrefix,
    removePrefixes,
    splitOnNewLine,
    isNilOrEmptyString,
    isEmojiInString,
    encodeBase64,
    decodeBase64,
    parseMultilineContent,
    removeHtmlPrefix,
    DELIMITER_REGEXP,
    LINE_DELIMITER,
};
