import React from 'react';
import { clearHasUnsavedChanges } from 'actions';
import { saveCreativeError } from 'modules/campaigns/actions/index';
import { FOCUS_TYPES } from 'modules/campaigns/modules/creative-editor/config';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import creativesApi from 'services/api/creativesApi';
import { generateErrorIndication } from 'services/utils/errorMessagesUtils';
import { isMediaLibraryItem } from '../components/MediaTabs/services/utils';
import saveFocalPoints from './saveFocalPoints';
import uploadCreativeRecommendedImage from './uploadCreativeRecommendedImage';
import uploadCreativeThumbnail from './uploadCreativeThumbnail';

const getThumbnailUrl = async (dispatch, accountId, initialCreative) => {
    const { thumbnailUrl, thumbnailFile, thumbnailRecommendedImage } = initialCreative;

    if (thumbnailFile) {
        return await dispatch(uploadCreativeThumbnail(accountId, thumbnailFile));
    } else if (thumbnailRecommendedImage) {
        return isMediaLibraryItem(thumbnailRecommendedImage)
            ? thumbnailRecommendedImage
            : await dispatch(uploadCreativeRecommendedImage(accountId, thumbnailRecommendedImage));
    }

    return { thumbnailUrl };
};

const updateCreativeFields = (initialCreative, thumbnailUrl) => {
    const { thumbnailFile, thumbnailRecommendedImage, creativeFocus, ...creative } = initialCreative;

    return {
        ...creative,
        ...(creativeFocus?.type !== FOCUS_TYPES.PADDING && { creativeFocus }),
        ...(thumbnailUrl && { thumbnailUrl }),
    };
};

const successIndication = {
    message: <FormattedMessage id="creative.editor.duplicate.success" defaultMessage="Ad successfully duplicated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const errorMessage = (
    <FormattedMessage id="creative.editor.duplicate.error" defaultMessage="We were not able to duplicate the ad" />
);

const duplicateCreative =
    (
        {
            campaignId: originalCreativeCampaignId,
            accountId: originalCreativeAccountId,
            selectedAccount: { accountId: targetAccountId },
        },
        creativeFormData,
        matchingCreativeIds = []
    ) =>
    async (dispatch, getState) => {
        const { selectedCampaigns = [], ...initialCreative } = creativeFormData;

        const { error: focalPointsSaveError } = await dispatch(
            saveFocalPoints(initialCreative, originalCreativeAccountId, matchingCreativeIds)
        );
        if (focalPointsSaveError) {
            dispatch(saveCreativeError(initialCreative.id, focalPointsSaveError));
            return;
        }

        const { thumbnailUrl, error: uploadError } = await getThumbnailUrl(
            dispatch,
            originalCreativeAccountId,
            initialCreative
        );
        if (uploadError) {
            dispatch(saveCreativeError(initialCreative.id, uploadError));
            return;
        }
        const creative = updateCreativeFields(initialCreative, thumbnailUrl);

        try {
            const selectedCampaignIds = selectedCampaigns.map(({ value }) => value);

            await creativesApi.createCombinedCreatives(targetAccountId, true, {
                campaignIds: selectedCampaignIds,
                staticImagePojo: [creative],
            });
            dispatch(clearHasUnsavedChanges());
            dispatch(addIndication(successIndication));

            return true;
        } catch (error) {
            dispatch(saveCreativeError(error));
            dispatch(addIndication(generateErrorIndication(error, errorMessage)));
        }
    };

export default duplicateCreative;
