import React, { FC, SVGProps } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import styled from 'styled-components';
import { ErrorOutlinedIcon, CheckCircleOutlinedIcon, CircleIcon } from 'tuui/lib/atoms/Icon';
import { StepProgress } from 'tuui/lib/molecules/ProgressStepper/Step/StepProgress';
import { zIndex } from 'tuui/lib/theme/primitives';
import { scale, theme } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';
import { StepType } from './stepTypes';

interface BaseStepProps {
    hasTail?: boolean;
    title: string;
    stepType: StepType;
    progress: number;
    progressTitle?: string;
    isLast?: boolean;
}

const stepTypeToProgress = (progress: number): Record<StepType, number> => ({
    active: progress,
    warn: progress,
    error: progress,
    done: 100,
    inactive: 0,
});

const stepTypeToIcon: Record<StepType, FC<SVGProps<SVGSVGElement>> | SvgIconComponent> = {
    done: CheckCircleOutlinedIcon,
    warn: ErrorOutlinedIcon,
    error: ErrorOutlinedIcon,
    active: CircleIcon,
    inactive: CircleIcon,
};

export const BaseStep: UIC<BaseStepProps> = ({ hasTail = false, title, stepType, progress, progressTitle, isLast }) => {
    const isStepActive = stepType === 'active' || stepType === 'warn' || stepType === 'error';
    const StepIcon = stepTypeToIcon[stepType];

    return (
        <>
            {hasTail ? <StepProgress progressType="tail" stepType={stepType} progress={100} /> : null}
            <StepContainer>
                <StepCircle $stepType={stepType}>
                    <StepIcon />
                </StepCircle>
                <StepTitle>{title}</StepTitle>
            </StepContainer>
            {!isLast && (
                <StepProgress
                    progressType={isStepActive ? 'expanded' : 'regular'}
                    stepType={stepType}
                    progress={stepTypeToProgress(progress)[stepType]}
                    progressTitle={isStepActive ? progressTitle : undefined}
                />
            )}
        </>
    );
};

const StepContainer = styled.div`
    z-index: ${zIndex.secondLayer};
`;

const StepCircle = styled.div<{ $stepType: StepType }>`
    display: flex;
    color: white;
    border-radius: 50%;
    height: ${scale('xxl')};
    width: ${scale('xxl')};
    display: flex;
    margin: -${scale('xs')};
    justify-content: center;
    align-items: center;
    background: ${({ $stepType }) => `var(--${$stepType}-step-background)`};
    color: ${({ $stepType }) => `var(--${$stepType}-step-color)`};
`;

const StepTitle = styled.span`
    position: absolute;
    top: calc(${scale('xl')} + ${scale('xxs')});
    transform: translateX(-50%) translateX(${scale('sm')});
    align-items: center;
    white-space: nowrap;
    color: var(--text-color);
    font-size: ${theme('typography.sizes.md')};
    font-weight: ${theme('typography.weight.bold')};
`;
