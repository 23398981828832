import React, { useMemo } from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedRelative } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { isNetworkAccountType } from '../../../account-management';
import { TRACKING_STATUS_CONFIG, TRACKING_TYPE } from '../../config/constants';
import styles from '../TrackingStateRibbon/trackingStateRibbon.module.scss';

const PER_MINUTE = 60;

export const TrackingStatus = ({ trackingType }) => {
    const { trackingStateData, isLoadingTrackingState, accountType } = useTrackingState();
    const isNetworkAccount = isNetworkAccountType(accountType);
    const { pixelTrackingData, s2sTrackingData } = trackingStateData;
    const {
        status: pixelStatus,
        lastEvent: lastPixelEvent,
        lastEventFromAccountNetwork: isLastPixelEventFromAccountNetwork,
    } = pixelTrackingData || {};
    const {
        status: s2sStatus,
        lastEvent: lastS2sEvent,
        lastEventFromAccountNetwork: isLastS2sEventFromAccountNetwork,
    } = s2sTrackingData || {};
    const status = trackingType === TRACKING_TYPE.PIXEL ? pixelStatus : s2sStatus;
    const isLastEventFromAccountNetwork =
        trackingType === TRACKING_TYPE.PIXEL ? isLastPixelEventFromAccountNetwork : isLastS2sEventFromAccountNetwork;
    const eventAccountType = isNetworkAccount ? 'basic' : isLastEventFromAccountNetwork ? 'network' : 'account';
    const { icon: Icon, label, descriptionCode, defaultMessage, className } = TRACKING_STATUS_CONFIG[status] ?? {};

    const lastEvent = useMemo(() => {
        if (trackingType === TRACKING_TYPE.PIXEL) {
            return moment().diff(lastPixelEvent, 'second');
        }
        return moment().diff(lastS2sEvent, 'second');
    }, [trackingType, lastPixelEvent, lastS2sEvent]);

    if (isLoadingTrackingState || !TRACKING_STATUS_CONFIG[status]) {
        return null;
    }

    return (
        <div className={styles['pixel-status']}>
            <div className={styles['icon-status']}>
                <Icon className={styles[className]} aria-label="tracking-status-icon" />
            </div>
            <div>
                <span className={styles['status-header']}>
                    <FormattedMessage
                        id={`tracking.status.header.${trackingType}.${eventAccountType}`}
                        defaultMessage={label}
                        values={{ status: label }}
                    />
                </span>
                <div className={styles['body']}>
                    <FormattedMessage
                        id={`${descriptionCode}.${trackingType}`}
                        defaultMessage={defaultMessage}
                        values={{
                            lastEvent: (
                                <FormattedRelative
                                    value={-lastEvent}
                                    numeric="auto"
                                    updateIntervalInSeconds={PER_MINUTE}
                                />
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
