import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignGroupExtensionEnabled } from '../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { CampaignGroup } from './Components/CampaignGroup/CampaignGroup';
import styles from './CampaignGroupField.module.scss';

export const CampaignGroupField = props => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    if (!campaignGroupExtensionEnabled) {
        return null;
    }

    return (
        <FormField
            label={<FormattedMessage id="campaign.group" defaultMessage="Campaign Group" />}
            helpText={
                <FormattedMessage
                    id="campaign.name.help"
                    defaultMessage="This is an internal name only - it does not display in your ads."
                />
            }
            containerClass={styles['form-field']}
        >
            <CampaignGroup {...props} />
        </FormField>
    );
};
