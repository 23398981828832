"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgTracking(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M21 6C21 7.65685 19.6569 9 18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6ZM15.6631 18C16.8329 18 18 16.9452 18 15.7798V12.4444C18 11.8615 20 11.8615 20 12.4444V15.7798C20 18.1117 18.1093 20 15.7742 20H8.22576C5.89123 20 4 18.1098 4 15.7742V8.22576C4 5.89276 5.89339 4 8.22502 4H12C12.583 4 12.583 6 12 6H8.22502C7.05917 6 6 7.05885 6 8.22576V15.7742C6 16.9436 6.94581 18 8.11465 18H15.6631Z"
            })
        )
    );
}
exports.default = SvgTracking;