import { isGrowthAdvertiserAccount } from 'modules/account-management/accountType';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';

export const MSG_ID_PREFIX = 'campaign.editor.thirdPartyTags';

export const VALUE_TYPES = {
    HTML: 'HTML',
    URL: 'URL',
};

export const TAG_TYPES = {
    THIRD_PARTY_PIXEL: 'THIRD_PARTY_PIXEL',
    MOAT: 'MOAT',
    IAS: 'IAS',
    GOOGLE_DCM: 'GOOGLE_DCM',
    DOUBLE_VERIFY: 'DOUBLE_VERIFY',
    ADLOOX: 'ADLOOX',
};

export const JS_TAG_TYPES = [
    TAG_TYPES.MOAT,
    TAG_TYPES.IAS,
    TAG_TYPES.GOOGLE_DCM,
    TAG_TYPES.DOUBLE_VERIFY,
    TAG_TYPES.ADLOOX,
];

export const EVENT_TYPES = {
    IMPRESSION: 'IMPRESSION',
    VIEWABLE_IMPRESSION: 'VIEWABLE_IMPRESSION',
    CLICK: 'CLICK',
};

const shouldDisplayJs = ({ accountConfig, advertiserGroupTier }) =>
    accountConfig?.[FEATURE_FLAGS.SHOULD_DISPLAY_JS_FOR_ALL] === 'true' ||
    !isGrowthAdvertiserAccount(advertiserGroupTier);

export const TAG_TYPE_OPTIONS = [
    {
        value: TAG_TYPES.THIRD_PARTY_PIXEL,
        label: 'Third Party Pixel',
        valueType: VALUE_TYPES.URL,
        eventTypes: [EVENT_TYPES.IMPRESSION, EVENT_TYPES.CLICK, EVENT_TYPES.VIEWABLE_IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_IMAGE_PIXEL_ENABLED]: 'true' },
    },
    {
        value: TAG_TYPES.IAS,
        label: 'JS - IAS',
        valueType: VALUE_TYPES.HTML,
        eventTypes: [EVENT_TYPES.IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_IAS_ENABLED]: 'true' },
        visibilityCondition: shouldDisplayJs,
    },
    {
        value: TAG_TYPES.DOUBLE_VERIFY,
        label: 'JS - DoubleVerify',
        valueType: VALUE_TYPES.HTML,
        eventTypes: [EVENT_TYPES.IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_DV_ENABLED]: 'true' },
        visibilityCondition: shouldDisplayJs,
    },
    {
        value: TAG_TYPES.GOOGLE_DCM,
        label: 'JS - DCM',
        valueType: VALUE_TYPES.HTML,
        eventTypes: [EVENT_TYPES.IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_GOOGLE_DCM_ENABLED]: 'true' },
        visibilityCondition: shouldDisplayJs,
    },
    {
        value: TAG_TYPES.MOAT,
        label: 'JS - Moat',
        valueType: VALUE_TYPES.HTML,
        eventTypes: [EVENT_TYPES.IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_MOAT_ENABLED]: 'true' },
        visibilityCondition: shouldDisplayJs,
    },
    {
        value: TAG_TYPES.ADLOOX,
        label: 'JS - Adloox',
        valueType: VALUE_TYPES.HTML,
        eventTypes: [EVENT_TYPES.IMPRESSION],
        accountConfigurations: { [FEATURE_FLAGS.TAG_TYPE_ADLOOX_ENABLED]: 'true' },
        visibilityCondition: shouldDisplayJs,
    },
];

export const EVENT_TYPE_OPTIONS_MAP = {
    [EVENT_TYPES.CLICK]: {
        value: EVENT_TYPES.CLICK,
        label: 'Clicks',
    },
    [EVENT_TYPES.VIEWABLE_IMPRESSION]: {
        value: EVENT_TYPES.VIEWABLE_IMPRESSION,
        label: 'Viewable Impressions',
    },
    [EVENT_TYPES.IMPRESSION]: {
        value: EVENT_TYPES.IMPRESSION,
        label: 'Impressions',
    },
};

export const EVENT_TYPE_OPTIONS = [
    EVENT_TYPE_OPTIONS_MAP[EVENT_TYPES.CLICK],
    EVENT_TYPE_OPTIONS_MAP[EVENT_TYPES.VIEWABLE_IMPRESSION],
    EVENT_TYPE_OPTIONS_MAP[EVENT_TYPES.IMPRESSION],
];

export const TAG_TYPE_EVENT_OPTIONS = TAG_TYPE_OPTIONS.reduce((map, obj) => {
    map[obj.value] = obj.eventTypes;
    return map;
}, {});

export const TAG_TYPE_VALUE_TYPE = TAG_TYPE_OPTIONS.reduce((map, obj) => {
    map[obj.value] = obj.valueType;
    return map;
}, {});

export const TAG_TYPE_TOOLTIP_POSITION = 'top';

const hasTagWithError = tags => {
    if (!tags) {
        return true;
    }

    tags.forEach(tag => {
        if (
            tag.type === TAG_TYPES.THIRD_PARTY_PIXEL &&
            tag.error &&
            tag.error.id === 'campaign.editor.thirdPartyTags.validations.error.tracking.pixels.error.illegalCharacters'
        ) {
            const invalidChar = tag.error?.defaultMessage?.split(' , line')[0]?.split('character: ')[1];
            throw new CustomValidationError({
                messageCode: 'validations.error.campaign.thirdPartyTags.invalid.url',
                message: `3rd party tag URL contains invalid characters: \\"${invalidChar}\\"`,
                templateParameters: { invalidChar },
            });
        }
    });

    return !tags.some(({ error } = {}) => !!error);
};

export const THIRD_PARTY_VALIDATIONS = [
    {
        // check that no tag has an error msg
        validationFn: hasTagWithError,
        messageId: 'validations.error.campaign.thirdPartyTags.has.error',
        defaultMessage: 'Some of your third party tags are invalid',
    },
];
