import { useCallback } from 'react';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { useThirdPartyTagsApi } from 'services/api/thirdPartyTagsApi';

export const useVendorMacroReplacement = () => {
    const { accountId } = useAccount();

    const thirdPartyTagsApiFactory = useThirdPartyTagsApi();

    const getReplacements = useCallback(
        async newTag => {
            try {
                const res = await thirdPartyTagsApiFactory.replaceMacros(accountId, newTag?.tagValue?.value);
                const data = await res.json();
                const { replacedTag, macroReplacements } = data || {};
                const replacedTagValue = replacedTag?.replace(/^"(.*)"$/, '$1');
                return { replacedTagValue, macroReplacements };
            } catch (error) {
                console.error('Error while calling replace macros', error);
                return error;
            }
        },
        [accountId, thirdPartyTagsApiFactory]
    );

    return {
        getReplacements,
    };
};
