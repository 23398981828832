import React from 'react';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { Spinner } from 'tuui';
import { useSelectedAccount } from 'hooks';
import { selectedRowsSelector } from 'modules/campaigns/selectors';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../components/Form/FormLayout/FormLayout';
import { useCreativeBulkEditorState } from '../creative-bulk-editor/hooks/useCreativeBulkEditorState';
import { SetupSection } from '../creative-editor/components/SetupSection';
import styles from './creativeBulkDuplicator.module.scss';

// TODO creative editor missed button

const generateDataMetricsAtttrs = numCreativesSelected => ({
    'data-metrics-component': 'bulkAction',
    'data-metrics-column-name': 'duplicate',
    'data-metrics-value': numCreativesSelected,
});

const CreativeBulkDuplicator = ({ sourcePath, onCancel }) => {
    const { submitStatus } = useFormDataContext();
    const [selectedAccount] = useSelectedAccount();
    const selectedRows = useSelector(selectedRowsSelector);
    const { submit: submitDuplicate } = useCreativeBulkEditorState({ sourcePath });

    return (
        <FormInitBase>
            <FormLayout
                className={styles['editor']}
                headerContainerClassName={styles['editor-header']}
                contentContainerClassName={styles['content-container']}
                bodyContainerClassName={styles['body-container']}
                footerContainerClassName={styles['footer']}
                header={
                    <div className={styles['subheader']}>
                        <FormattedMessage
                            id="creative.editor.duplicate.bulk.subheader"
                            tagName="h1"
                            values={{ count: size(selectedRows) }}
                        />
                    </div>
                }
                onCancel={onCancel}
                onSubmit={submitDuplicate}
                submitButtonText={
                    <FormattedMessage
                        id="creative.editor.duplicate.bulk.submit.button"
                        defaultMessage="DUPLICATE ADS"
                    />
                }
                submitDataMetricAttrs={generateDataMetricsAtttrs(size(selectedRows))}
            >
                <SetupSection selectedAccount={selectedAccount} />
                {submitStatus === COMPONENT_STATUS.LOADING && <Spinner className={styles['overlay']} />}
            </FormLayout>
        </FormInitBase>
    );
};

const CreativeBulkDuplicatorWithFormDataProvider = withFormDataProvider(CreativeBulkDuplicator);

export { CreativeBulkDuplicatorWithFormDataProvider as CreativeBulkDuplicator };
