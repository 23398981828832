'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var SIDE_DRAWER_ANCHOR_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right'
};

exports.default = SIDE_DRAWER_ANCHOR_DIRECTION;