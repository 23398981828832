import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import {
    DATE,
    CVR,
    AVERAGE_CPC,
    CONVERSIONS,
    CONVERSIONS_CLICKS,
    CONVERSIONS_VIEWS,
    CVR_CLICKS,
    CVR_VIEWS,
} from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { graph } from './graph';
import { defaultColumns, customColumns } from './reportPresets';

export const campaignHistoryReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CAMPAIGN_HISTORY],
    endpoint: 'campaign-history',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    sortMap: {
        [DATE.field]: 'date',
        [CVR.field]: 'cpaConversionRate',
        [AVERAGE_CPC.field]: 'cpc',
        [CONVERSIONS.field]: 'cpaActionsNum',
        [CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    searchPlaceholderMsgId: 'app.reports.byCampaignHistory.search.placeholder',
    searchField: 'performer',
    footerColumnDef: [],
    isEmptyFooter: true,

    filters: [
        {
            id: FILTER_TYPE.HISTORY_LOG_ACTIVITY_CODE_TYPE,
            permissions: ['ADS_CONSOLE_CAMPAIGN_HISTORY_FILTERS'],
            multiSelect: true,
        },
    ],
};
