import { generatePath } from 'react-router';
import { ENTITY_PARAM_NAME, FORM_MODE_TO_PATH_MAP, MODE_PARAM_NAME, MODULE_PARAM_NAME } from 'config/routes';
import {
    ACCOUNT_ID_PARAM_NAME,
    AUDIENCE_ID_PARAM_NAME,
    ENTITY_TO_PATH_MAP,
    MODULE_NAME,
} from 'modules/audiences/config/routes';

export const generateFormPath = (pathTemplate, { module = MODULE_NAME, accountId, audienceId, entity, mode }) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    const path = generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
        [AUDIENCE_ID_PARAM_NAME]: audienceId,
    });

    return path;
};
