import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { MotivationSection } from './MotivationSection';
import styles from './bodySection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const BodySection = ({ descriptionId, motivationId, isCollapsed }) => {
    return (
        <div className={classNameBuilder('container', { hidden: isCollapsed })}>
            <div className={styles['description']}>
                <FormattedMessage
                    id={`onboarding.assistant.${descriptionId}.description`}
                    defaultMessage="Description"
                />
            </div>
            {motivationId && <MotivationSection motivationId={motivationId} />}
        </div>
    );
};
