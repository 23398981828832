import { DEFAULT_ZOOM, ORIGIN_POINT } from '../constants/croppingConsts';

const cropContentInitialCrop = {
    crop: ORIGIN_POINT,
    rotation: 0,
    zoom: DEFAULT_ZOOM,
    croppedAreaPixels: null,
};

export const defaultCropData = [
    {
        ...cropContentInitialCrop,
        ratio: 16 / 9,
        ratioName: '16 : 9',
        aspect: {
            width: 16,
            height: 9,
        },
    },
    {
        ...cropContentInitialCrop,
        ratio: 6 / 5,
        ratioName: '6 : 5',
        aspect: {
            width: 6,
            height: 5,
        },
    },
    {
        ...cropContentInitialCrop,
        ratio: 2,
        ratioName: '2 : 1',
        aspect: {
            width: 2,
            height: 1,
        },
    },
    {
        ...cropContentInitialCrop,
        ratio: 1,
        ratioName: '1 : 1',
        aspect: {
            width: 1,
            height: 1,
        },
    },

    {
        ...cropContentInitialCrop,
        ratio: 1.91,
        ratioName: '1.91 : 1',
        aspect: {
            width: 191,
            height: 100,
        },
    },
];
