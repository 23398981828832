import React, { useState, useMemo } from 'react';
import uuid from 'uuid/v1';
import { FileTypes } from 'taboola-ultimate-ui';
import { THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from './creativeStudioEventsPrefix';
import { CREATIVE_STUDIO_MODES, CREATIVE_STUDIO_MODE_CONFIG, isValidCreativeStudioMode } from './creativeStudioModes';
import { CreativeStudioContextProvider } from './providers/CreativeStudioContext';
import styles from './creativeStudioContent.module.scss';

const NULL_COMPONENT = () => null;
const CONTAINER_CLASS_BY_MODE = {
    [CREATIVE_STUDIO_MODES.EDIT_FALLBACK_IMAGE]: styles['fallback-image-container'],
};

export const CreativeStudioContent = ({
    originalItem,
    urlTransformer,
    src,
    mode: initialMode,
    accountId,
    campaignId,
    addImageHandler,
    closeCreativeStudioModal,
    formAccount,
    shouldSkipCrop = false,
}) => {
    const [mode, setMode] = useState(initialMode);

    const {
        Header,
        Content,
        galleryItemDropdownOptions,
        initialSelected,
        applyButtonText = <FormattedMessage id="creative.studio.submit.button" defaultMessage="Select Image" />,
    } = useMemo(
        () =>
            isValidCreativeStudioMode(mode)
                ? CREATIVE_STUDIO_MODE_CONFIG[mode]
                : {
                      Header: NULL_COMPONENT,
                      Content: NULL_COMPONENT,
                      galleryItemDropdownOptions: [],
                      initialSelected: true,
                  },
        [mode]
    );

    const [selectedImageUrl, setSelectedImageUrl] = useState(initialSelected ? src : null);

    const addImageHandlerShouldEnableCropSubmitAlways = props => {
        addImageHandler({ ...props, options: { shouldEnableCropSubmitAlways: true } });

        if (shouldSkipCrop) {
            closeCreativeStudioModal();
        }
    };

    const editFallbackImageSubmitHandler = async imageUrl => {
        await addImageHandler({
            image: {
                ...originalItem,
                fallbackImageUrl: imageUrl,
            },
        });
        closeCreativeStudioModal();
    };

    const defaultSubmitHandler = async imageUrl => {
        const image = {
            url: imageUrl,
            id: uuid(),
            cdnUrl: imageUrl,
            thumbnailUrl: imageUrl,
            fileType: FileTypes.IMAGE,
            source: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION,
        };
        await addImageHandlerShouldEnableCropSubmitAlways({ image });
    };

    const onSubmitClick = async imageUrl => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${eventsPrefix}: Next Clicked`,
            taboolaCampaignId: campaignId,
        });
        if (imageUrl && mode === CREATIVE_STUDIO_MODES.EDIT_FALLBACK_IMAGE) {
            editFallbackImageSubmitHandler(imageUrl);
        } else if (imageUrl) {
            defaultSubmitHandler(imageUrl);
        }
    };

    const onCancelClick = () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${eventsPrefix}: Cancelled`,
            taboolaCampaignId: campaignId,
        });
        closeCreativeStudioModal();
    };

    const eventsPrefix = `${CREATIVE_STUDIO_EVENT_PREFIX}_${CREATIVE_STUDIO_MODE_EVENTS_MAP[mode]}`;

    const containerClassName = CONTAINER_CLASS_BY_MODE[mode] || styles['container'];

    return (
        <CreativeStudioContextProvider
            src={src}
            mode={mode}
            setMode={setMode}
            accountId={accountId}
            campaignId={campaignId}
            galleryItemDropdownOptions={galleryItemDropdownOptions}
            selectedImageUrl={selectedImageUrl}
            setSelectedImageUrl={setSelectedImageUrl}
            addImageHandler={addImageHandlerShouldEnableCropSubmitAlways}
            originalItem={originalItem}
            urlTransformer={urlTransformer}
            formAccount={formAccount}
        >
            <ModalFormLayout
                onCancel={() => onCancelClick()}
                onSubmit={() => onSubmitClick(selectedImageUrl)}
                cancelButtonText={<FormattedMessage id="creative.studio.cancel.button" defaultMessage="Cancel" />}
                submitButtonText={applyButtonText}
                header={<Header />}
                headerContainerClassName={styles['header-container']}
                disableSubmit={!selectedImageUrl}
                bodyContainerClassName={styles['body-container']}
            >
                <div className={containerClassName}>
                    <Content />
                </div>
            </ModalFormLayout>
        </CreativeStudioContextProvider>
    );
};
