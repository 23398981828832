import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelectedAccountId } from 'hooks/queryParams/useSelectedAccountId';
import { useSelectedCampaignId } from 'modules/campaigns/hooks/useSelectedCampaignId';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { useSearchApi, FILTERS } from 'services/api';

const useOmniboxQueryParam = queryParamHookFactory(FILTERS.QUERY);
export const useOmniboxQuery = () => {
    const { omniboxSearch } = useSearchApi();
    const [query, setQuery] = useOmniboxQueryParam();
    const [, setCampaignId] = useSelectedCampaignId();
    const [, setAccountId] = useSelectedAccountId();
    const { isLoading, data } = useQuery(['omnibox', query], () => omniboxSearch(query), {
        enabled: !!query,
        retry: false,
    });

    useEffect(() => {
        const hasQueryAndResponse = query && data;
        if (!hasQueryAndResponse) {
            return;
        }
        setQuery();
        if (data.accountId) {
            setAccountId(data.accountId);
        }
        if (data.campaignId) {
            setCampaignId(data.campaignId);
        }
    }, [data, query, setAccountId, setCampaignId, setQuery]);

    return isLoading;
};
