import { useCallback, useMemo } from 'react';
import { differenceBy, isEqual } from 'lodash';
import {
    REPORT_SETTINGS_PREFIX,
    PRESET_SETTINGS_PREFIX,
} from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { useDBStorage } from 'modules/taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import {
    getColumnDefsForConfigPreset,
    getMatchedColumnState,
    getMergedColumnStateWithDef,
    injectSortModelToColumnState,
    mergeFullStateWithSubState,
} from '../services';
import { isCustomReportPreset } from '../utils';

export const getBasePresetsDBKey = reportId => `${REPORT_SETTINGS_PREFIX}.${reportId}.${PRESET_SETTINGS_PREFIX}`;
export const getPresetDBKey = (reportId, presetName) => `${getBasePresetsDBKey(reportId)}.${presetName}`;
const getColumnStateDBKey = (reportId, presetName) => `${getPresetDBKey(reportId, presetName)}.gridColumnState`;

export const useColumnState = (reportId, presetName, columnDefs) => {
    const { getter, setter } = useDBStorage();
    const getValue = useCallback(
        (reportId, passedPresetName) => getter(getColumnStateDBKey(reportId, passedPresetName)),
        [getter]
    );
    const setValue = useCallback(
        (value, passedReportId, passedPresetName, autoUpdate) =>
            setter(getColumnStateDBKey(passedReportId, passedPresetName), value, { autoUpdate }),
        [setter]
    );
    const columnState = getValue(reportId, presetName) || null;

    const saveColumnStateByPresetAndColumnDefs = useCallback(
        ({ reportConfig, reportId, reportPresetName, selectedColumns, reportSorting, columnDefs, autoUpdate }) => {
            const columnState = getValue(reportId, reportPresetName);
            const allConfigColumnDefs = reportConfig.columnsDef;
            const isCustomPreset = isCustomReportPreset(reportPresetName);

            const resolvedColumnDefs = isCustomPreset
                ? [...differenceBy(allConfigColumnDefs, columnDefs, ({ colId }) => colId), ...columnDefs]
                : getColumnDefsForConfigPreset(allConfigColumnDefs, reportConfig, reportPresetName);

            const newColumnState = getMergedColumnStateWithDef({
                columnState,
                columnDefs: resolvedColumnDefs,
                reportConfig,
                selectedColumns,
                reportPresetName,
            });

            const columnStateAlignedSorting = injectSortModelToColumnState({
                columnState: newColumnState,
                reportSorting,
                columnDefs,
            });

            if (isEqual(columnState, columnStateAlignedSorting)) {
                return;
            }

            setValue(columnStateAlignedSorting, reportId, reportPresetName, autoUpdate);
        },
        [getValue, setValue]
    );

    const saveColumnStateOnGridChange = useCallback(
        ({ columnState, resetPrevSorting, reportId, reportPresetName }) => {
            const prevColumnState = getValue(reportId, reportPresetName);
            setValue(
                mergeFullStateWithSubState(prevColumnState, columnState, resetPrevSorting),
                reportId,
                reportPresetName
            );
        },
        [getValue, setValue]
    );

    const matchedColumnState = useMemo(() => {
        const matchedState = columnDefs ? getMatchedColumnState(columnState, columnDefs) : columnState;

        return matchedState;
    }, [columnState, columnDefs]);

    return { columnState: matchedColumnState, saveColumnStateByPresetAndColumnDefs, saveColumnStateOnGridChange };
};
