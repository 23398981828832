export const SET_CURRENT_ROOT_ACCOUNT = 'SET_CURRENT_ROOT_ACCOUNT';
export const REQUEST_FETCH_ACCOUNT = 'REQUEST_FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';
export const REQUEST_FETCH_ACCOUNT_ADDITIONAL_DATA = 'REQUEST_FETCH_ACCOUNT_ADDITIONAL_DATA';
export const FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS = 'FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS';
export const FETCH_ACCOUNT_ADDITIONAL_DATA_ERROR = 'FETCH_ACCOUNT_ADDITIONAL_DATA_ERROR';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
export const RETRY_FETCH_ACCOUNTS = 'RETRY_FETCH_ACCOUNTS';
export const REQUEST_FETCH_ACCOUNTS = 'REQUEST_FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';
export const NAVIGATE = 'NAVIGATE';
export const REPLACE_NAVIGATION_STATE = 'REPLACE_NAVIGATION_STATE';
export const WINDOW_NAVIGATE = 'WINDOW_NAVIGATE';
export const SAVE_STATE_TO_STORAGE = 'SAVE_STATE_TO_STORAGE';
export const INITIATE_STATE_FROM_STORAGE = 'INITIATE_STATE_FROM_STORAGE';
export const RESET_STATE_TO_INITIAL = 'RESET_STATE_TO_INITIAL';
export const SET_FORM_SECTION_STATE_BY_SECTION = 'SET_FORM_SECTION_STATE_BY_SECTION';
export const SET_HAS_UNSAVED_CHANGES = 'SET_HAS_UNSAVED_CHANGES';
export const SET_PAGE_NAME = 'SET_PAGE_NAME';
export const FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS = 'FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS';
export const RESET_ACCOUNTS_UNDER_NETWORK = 'RESET_ACCOUNTS_UNDER_NETWORK';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const SET_DIMENSION = 'SET_DIMENSION';
export const SET_LOCATION_SEARCH = 'SET_LOCATION_SEARCH';
