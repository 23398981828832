import React from 'react';
import classnames from 'classnames/bind';
import { AlertIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import styles from './delayMessage.module.scss';

const classNameBuilder = classnames.bind(styles);

export const DelayMessage = ({ delayMsgId, className }) => (
    <div className={classNameBuilder('delay-message', className)}>
        <AlertIcon className={styles['icon']} />
        <FormattedMessage
            id={delayMsgId}
            defaultMessage="In some cases it might take some time for a task to appear complete"
        />
    </div>
);
