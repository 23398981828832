import React, { Component } from 'react';
import momentPropTypes from 'react-moment-proptypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames/bind';
import { get, isEmpty, isNil, mapValues } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DashboardRangePicker, ScrollCorrector } from 'taboola-ultimate-ui';
import { selectedSegmentSelector } from 'modules/audience-insights/selectors';
import { resetFlashReportGridRows } from 'modules/campaigns/actions';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { getDateFormat } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { setNavigationState } from '../../../../actions';
import { dimensionSelector, selectedAccountSelector } from '../../../../selectors';
import {
    reportDataRawSelector,
    reportDataSelector,
    selectedReportSelector,
    selectedRowsSelector,
} from '../../selectors';
import { BulkActionToolbar, CollapsibleGraph, Filters, GridToolbar, ReportDataGrid, TimeFrame } from './components';
import ReportCampaignIndicator from './components/CampaignReportBreadcrumbs/CampaignReportBreadcrumbs';
import RssLinks from './components/RssLinks/RssLinks';
import { editGridCell, getGraphData, getGraphDataSuccess, getReportAndGraph, saveGraphOpenState } from './flows';
import { DATE_PRESET, END_DATE, START_DATE } from './hooks';
import {
    calculateSelectedColumnIds,
    csvOptionsBuilder,
    getEdges,
    getSortModelByColumnState,
    getWeekendAreas,
} from './services';
import { getDefaultEndDate, getDefaultStartDate, PAGE_SIZE, renderWeekHeaderElement } from './utils';
import styles from './campaignsReports.module.scss';

const classNameBuilder = classnames.bind(styles);
const defaultStartDate = getDefaultStartDate();
const defaultEndDate = getDefaultEndDate();

class CampaignsReports extends Component {
    state = {};
    getWeekendAreas = memoizeOne(getWeekendAreas);
    saveColumnStateOnGridChange = memoizeOne(columnState => {
        const { saveColumnStateOnGridChange, selectedReport, reportPresetName } = this.props;

        saveColumnStateOnGridChange({ columnState, reportId: selectedReport, reportPresetName });
    });

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { skipFetchReport, history, setNavigationStateAction } = this.props;

        if (skipFetchReport) {
            setNavigationStateAction(history, { skipFetchReport: false });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            history,
            setNavigationStateAction,
            skipFetchReport,
            forceFetchFlag,
            isReportBasisReady,
            graphConfig,
            selectedReport,
        } = this.props;
        const {
            forceFetchFlag: forceFetchFlagPrev,
            graphConfig: graphConfigPrev,
            selectedReport: selectedReportPrev,
        } = prevProps;

        if (skipFetchReport) {
            setNavigationStateAction(history, { skipFetchReport: false });
            return;
        }

        if (isReportBasisReady && forceFetchFlag !== forceFetchFlagPrev) {
            this.fetchReportAndGraph({ initial: true });
            return;
        }

        if (isReportBasisReady && graphConfig !== graphConfigPrev && selectedReport === selectedReportPrev) {
            this.updateGraph({ initial: true });
        }
    }

    calculateRowHeight = reportConfig => {
        const { isComfortableViewEnabled } = this.props;

        return isComfortableViewEnabled ? reportConfig?.comfortableRowHeight : reportConfig?.rowHeight;
    };

    handlePaginationChange = page => {
        const { setReportPage } = this.props;

        setReportPage(page + 1);
    };

    handleGridSort = columnState => {
        const { setReportSorting, saveColumnStateOnGridChange, selectedReport, reportPresetName } = this.props;
        const reportSort = getSortModelByColumnState(columnState);

        saveColumnStateOnGridChange({
            columnState,
            resetPrevSorting: true,
            reportId: selectedReport,
            reportPresetName,
        });
        setReportSorting(reportSort);
    };

    fetchReportAndGraph = (args = {}) => {
        const {
            getReportAndGraphFlow,
            selectedAccountId,
            selectedCampaignId,
            selectedReport,
            startDate: start = defaultStartDate,
            endDate: end = defaultEndDate,
            reportPage,
            parentContainerRef,
            reportQueryParams,
            reportConfig,
            allowedSortingColumnDefs,
            pageSize,
            searchTerm,
            reportSort,
            reportFilters,
            columnState,
            saveColumnStateByPresetAndColumnDefs,
            locale,
            selectedAccount,
            graphEdgesState,
            setGraphEdgesState,
            setReportSorting,
            reportPresetName,
            reportPresetNameGetter,
            graphConfig,
            requestHashGetter,
        } = this.props;
        const {
            // <paramFromArgs>: <paramToCreateInThisContext> = <defaultValueOfCreatedParam>
            flow = getReportAndGraphFlow,
            accountId = selectedAccountId,
            campaignId = selectedCampaignId,
            reportId = selectedReport,
            startDate = start,
            endDate = end,
            initial,
        } = args;

        if (parentContainerRef?.current) {
            parentContainerRef.current.scrollTop = 0;
        }

        flow({
            accountId,
            reportId,
            startDate,
            endDate,
            campaignId,
            initial,
            reportPage,
            reportPageSize: pageSize,
            reportQueryParams,
            reportConfig,
            allowedSortingColumnDefs,
            searchTerm,
            reportSort,
            reportFilters,
            columnState,
            saveColumnStateByPresetAndColumnDefs,
            locale,
            currency: selectedAccount.currency,
            graphEdgesState,
            setGraphEdgesState,
            setReportSorting,
            reportPresetName,
            reportPresetNameGetter,
            graphConfig,
            requestHashGetter,
        });
    };

    updateGraph = (args = {}) => {
        const {
            getGraphDataFlow,
            getGraphDataSuccessFlow,
            selectedAccountId: accountId,
            selectedCampaignId: campaignId,
            selectedReport: reportId,
            startDate = defaultStartDate,
            endDate = defaultEndDate,
            reportQueryParams,
            reportSort,
            reportFilters,
            columnState,
            locale,
            graphEdgesState,
            setGraphEdgesState,
            graphConfig,
            selectedAccount,
            currency,
            reportDataRaw,
            requestHashGetter,
        } = this.props;

        const { shouldUseGridDataSrc, maxXAxisValues, displayTotalsInLegendRow } = graphConfig || {};

        if (!shouldUseGridDataSrc) {
            getGraphDataFlow({
                accountId,
                reportId,
                startDate,
                endDate,
                campaignId,
                reportQueryParams,
                reportSort,
                reportFilters,
                columnState,
                locale,
                graphEdgesState,
                setGraphEdgesState,
                graphConfig,
                currency: selectedAccount.currency,
                requestHashGetter,
                ...args,
            });
            return;
        }

        if (!reportDataRaw) {
            // report has been fetched yet
            return;
        }

        getGraphDataSuccessFlow({
            graphConfig,
            graphResponse: reportDataRaw,
            graphEdgesState,
            setGraphEdgesState,
            locale,
            currency,
            options: { maxXAxisValues, displayTotalsInLegendRow },
        });
    };

    csvOptionsProvider = () => {
        const {
            selectedAccountId: accountId,
            selectedCampaignId: campaignId,
            dimension,
            selectedReport,
            searchTerm,
            reportSort,
            startDate,
            endDate,
            selectedAccountName,
            selectedCampaignName,
            buildReportNameForExport,
            columnDefs,
            columnState,
            currency,
            timeZoneName,
            reportFilters,
            reportQueryParams,
            reportConfig,
            allowedSortingColumnDefs,
            intl,
        } = this.props;

        return csvOptionsBuilder({
            accountId,
            campaignId,
            dimension,
            selectedReport,
            searchTerm,
            reportSort,
            startDate,
            endDate,
            selectedAccountName,
            buildReportNameForExport,
            selectedCampaignName,
            columnDefs,
            selectedColumns: calculateSelectedColumnIds(columnState),
            currency,
            intl,
            timeZoneName,
            reportFilters,
            reportQueryParams,
            reportConfig,
            allowedSortingColumnDefs,
        });
    };

    rowDeltaFunction = data => data.gridRowId;

    trackGraphMetricToggle = (toggledMetric, selected) => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: toggledMetric,
            columnName: selected ? 'Show' : 'Hide',
            component: 'Graph',
            pageName: getPageName(),
        });
    };

    onGraphMetricToggle = (newEdges, { edgeName: toggledMetric, selected } = {}) => {
        const { graphEdgesState, setGraphEdgesState } = this.props;
        const cleanedGraphEdgesState = mapValues(graphEdgesState, ({ selected, selectionOrder, ...rest }) => rest);

        setGraphEdgesState({ edgeState: { ...cleanedGraphEdgesState, ...newEdges } });
        this.trackGraphMetricToggle(toggledMetric, selected);
    };

    onRangeConfirmed = (startDate, endDate, preset) => {
        const { setDateRange } = this.props;

        setDateRange({ [START_DATE]: startDate, [END_DATE]: endDate, [DATE_PRESET]: preset });
    };

    renderGraph() {
        const {
            startDate,
            endDate,
            locale,
            currency,
            graphDataStatus,
            graphData,
            graphTotals,
            reportTotals,
            reportConfig,
            graphMetrics,
            reportDataStatus,
            edges,
            selectedReport,
            saveGraphOpenStateFlow,
            graphOverlay,
            graphConfig,
        } = this.props;
        const shouldUseGridDataSrc = graphConfig?.shouldUseGridDataSrc;
        const totals = shouldUseGridDataSrc ? reportTotals : graphTotals;
        const dataStatus = shouldUseGridDataSrc ? reportDataStatus : graphDataStatus;
        const legendValues = (dataStatus === COMPONENT_STATUS.ACTIVE && totals?.[0]) || {};
        const weekendAreas = get(reportConfig, 'graph.skipWeekendAreas')
            ? null
            : this.getWeekendAreas(graphData, styles.areaColor);

        return (
            <div className={styles['section']}>
                <CollapsibleGraph
                    reportStartDate={startDate}
                    reportEndDate={endDate}
                    key={selectedReport}
                    className={styles['graph-wrapper']}
                    onToggle={this.onGraphMetricToggle}
                    dataStatus={graphDataStatus}
                    values={graphData}
                    legendValues={legendValues}
                    config={graphConfig}
                    metrics={graphMetrics}
                    edges={edges}
                    areas={weekendAreas}
                    locale={locale}
                    currency={currency}
                    onCollapseToggle={saveGraphOpenStateFlow}
                    overlay={graphOverlay}
                />
            </div>
        );
    }

    renderDataGrid() {
        const {
            selectedReport,
            columnDefs,
            reportTotals,
            footerColumnDefs,
            editGridCellFlow,
            columnState,
            reportPage,
            reportTotalRowsCount,
            reportDataStatus,
            selectedRows,
            isReportBasisReady,
            reportData,
            isFilterActive,
            reportConfig,
            pageSize,
            gridRowToFlash,
            resetFlashReportGridRows,
            noRowsOverlayComponentFramework,
            gridContext,
        } = this.props;
        const { shouldDisableSelectedRows } = this.state;
        const isLoading =
            reportDataStatus !== COMPONENT_STATUS.ERROR &&
            (!isReportBasisReady || reportDataStatus !== COMPONENT_STATUS.ACTIVE);

        return (
            <div className={classNameBuilder('grid-wrapper', { offset: !isEmpty(selectedRows) })}>
                <ReportDataGrid
                    context={gridContext}
                    gridRowToFlash={gridRowToFlash}
                    resetFlashReportGridRows={resetFlashReportGridRows}
                    reportId={selectedReport}
                    reportData={reportData}
                    footerReportData={reportTotals}
                    isEmptyFooter={reportConfig?.isEmptyFooter}
                    autoFitColumnsToScreen={reportConfig?.autoFitColumnsToScreen}
                    columnDefinition={columnDefs}
                    footerColumnDefs={footerColumnDefs}
                    onCellValueChange={editGridCellFlow}
                    columnState={columnState}
                    onColumnStateChanged={this.saveColumnStateOnGridChange}
                    noRowsOverlayComponentFramework={noRowsOverlayComponentFramework}
                    rowHeight={this.calculateRowHeight(reportConfig)}
                    headerLoading={isLoading}
                    rowsLoading={isLoading}
                    onGridSort={this.handleGridSort}
                    onPaginationChange={this.handlePaginationChange}
                    currentPage={reportPage - 1}
                    totalRows={reportTotalRowsCount}
                    rowsPerPage={pageSize}
                    getRowNodeId={this.rowDeltaFunction}
                    onError={gtmTracker.trackError}
                    reportRef={this.containerRef}
                    selectedRows={selectedRows}
                    shouldDisableSelectedRows={shouldDisableSelectedRows}
                    animateRows={false}
                    isFilterActive={isFilterActive}
                    getSortModelByColumnState={getSortModelByColumnState}
                />
            </div>
        );
    }

    renderScrollCorrector() {
        const { parentContainerRef, selectedRows } = this.props;
        return <ScrollCorrector scrollRef={parentContainerRef} correctionTrigger={isEmpty(selectedRows)} />;
    }

    disableRowSelection = () => this.setState({ shouldDisableSelectedRows: true });
    enableRowSelection = () => this.setState({ shouldDisableSelectedRows: false });

    render() {
        const {
            startDate,
            endDate,
            datePreset,
            timeZoneName,
            selectedReport,
            selectedCampaignId,
            reportPresetName,
            reportDuration,
            reportConfig,
            reportConfigMap,
            selectedAccountId,
            header,
            toolbar,
            rangePickerPresetConfigs,
            forceFetchFlag,
            reportBanner,
            graphConfig,
            intl,
        } = this.props;
        const { formatMessage } = intl;
        const isGraphSupported = graphConfig && graphConfig.endpoint;
        const searchPlaceholderMsgId = reportConfig.searchPlaceholderMsgId;
        const customPresetText = formatMessage({
            id: 'app.campaigns.campaign.date.range.CUSTOM',
            defaultMessage: 'Custom',
        });
        const getDataMetricAttrs = period => ({
            'data-metrics-component': 'Date Picker',
            'data-metrics-column-name': 'Date Preset',
            'data-metrics-value': period || customPresetText,
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-table-date-preset': period || customPresetText,
        });

        const getApplyBtnMetricsAttrs = ({ type, values }) => ({
            'data-metrics-component': `Filter: ${type}`,
            'data-metrics-value': values
                .filter(v => v !== undefined)
                .map(({ value }) => value)
                .join(', '),
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
        });

        return (
            <div className={styles['container']} ref={this.containerRef}>
                <div className={classNameBuilder('section', 'header')}>
                    {header || (
                        <>
                            <ReportCampaignIndicator />
                            <DashboardRangePicker
                                defaultPreset={null}
                                preset={datePreset}
                                className={styles['dashboard-range-picker']}
                                startDate={startDate}
                                endDate={endDate}
                                onRangeConfirmed={this.onRangeConfirmed}
                                confirmButtonText={formatMessage({
                                    id: 'app.actionButtons.apply',
                                    defaultMessage: 'APPLY',
                                })}
                                customPresetText={customPresetText}
                                customTimeFramePicker={<TimeFrame />}
                                displayFormat={getDateFormat(timeZoneName)}
                                extendPresetProps={getDataMetricAttrs}
                                extendConfirmProps={getDataMetricAttrs}
                                presetConfigs={rangePickerPresetConfigs}
                                renderWeekHeaderElement={renderWeekHeaderElement}
                            />
                        </>
                    )}
                </div>

                {toolbar || (
                    <GridToolbar
                        className={styles['section']}
                        selectedReport={selectedReport}
                        selectedColumns={reportPresetName}
                        isSearchHidden={isNil(searchPlaceholderMsgId)}
                        searchPlaceholderMsgId={searchPlaceholderMsgId}
                        reportDuration={reportDuration}
                        csvOptionsProvider={this.csvOptionsProvider}
                        campaignId={selectedCampaignId}
                        accountId={selectedAccountId}
                        reportConfig={reportConfig}
                        reportConfigMap={reportConfigMap}
                        forceFetchFlag={forceFetchFlag}
                    />
                )}

                {reportBanner}

                <Filters reportConfig={reportConfig} getApplyBtnMetricsAttrs={getApplyBtnMetricsAttrs} />
                <RssLinks />
                <BulkActionToolbar
                    reportConfig={reportConfig}
                    disableRowSelection={this.disableRowSelection}
                    enableRowSelection={this.enableRowSelection}
                />
                {this.renderScrollCorrector()}
                {isGraphSupported ? this.renderGraph() : null}
                {this.renderDataGrid()}
            </div>
        );
    }
}

CampaignsReports.propTypes = {
    selectedCampaign: PropTypes.object,
    selectedAccount: PropTypes.object.isRequired,
    reportDataStatus: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    selectedCampaignName: PropTypes.string,
    selectedCampaignId: PropTypes.number,
    selectedReport: PropTypes.string.isRequired,
    reportMetadata: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    datePreset: PropTypes.string,
    searchTerm: PropTypes.string,
    skipFetchReport: PropTypes.bool,
    columnDefs: PropTypes.array.isRequired,
    columnState: PropTypes.array,
    footerColumnDefs: PropTypes.array,
    reportData: PropTypes.array,
    reportTotals: PropTypes.array,
    reportConfig: PropTypes.object,
    graphDataStatus: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    graphData: PropTypes.array,
    graphTotals: PropTypes.array,
    getReportAndGraphFlow: PropTypes.func,
    editGridCellFlow: PropTypes.func,
    setNavigationStateAction: PropTypes.func,
    saveGraphOpenStateFlow: PropTypes.func,
    addFilterStateFlow: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    reportQueryParams: PropTypes.object,
    reportDuration: PropTypes.number,
    currency: PropTypes.string,
    timeZoneName: PropTypes.string,
    reportPage: PropTypes.number,
    reportSort: PropTypes.array,
    reportFilters: PropTypes.array,
    reportTotalRowsCount: PropTypes.number,
    onSelectReport: PropTypes.func,
    reportGraphState: PropTypes.object,
    edges: PropTypes.object,
    selectedAccountName: PropTypes.string,
    onEditCampaign: PropTypes.func,
    intl: PropTypes.object,
    isFilterActive: PropTypes.bool,
    selectedRows: PropTypes.object,
    selectedAccountConfig: PropTypes.object,
    parentContainerRef: PropTypes.object,
    forceFetchFlag: PropTypes.number,
    isReportBasisReady: PropTypes.bool,
    isComfortableViewEnabled: PropTypes.bool,
    rangePickerPresetConfigs: PropTypes.objectOf(
        PropTypes.shape({
            label: PropTypes.string,
            startDate: momentPropTypes.momentObj,
            endDate: momentPropTypes.momentObj,
        })
    ).isRequired,
    graphOverlay: PropTypes.node,
    pageSize: PropTypes.number,
};

CampaignsReports.defaultProps = {
    reportFilters: [],
    pageSize: PAGE_SIZE,
};

const mapStateToProps = (state, props) => {
    const { campaignsReducer } = state;
    const {
        campaignId: selectedCampaignId,
        reportConfig,
        graphEdgesState,
        columnDefs,
        footerColumnDefs,
        isComfortableViewEnabled,
        graphMetrics,
        columnState,
        selectedCampaign,
        gridContext,
    } = props;
    const {
        reportTotals,
        graphDataStatus,
        graphData,
        graphTotals,
        reportDataStatus,
        reportDuration,
        reportTotalRowsCount,
        reportMetadata,
        gridRowToFlash,
    } = campaignsReducer;
    const { name: selectedCampaignName } = selectedCampaign;
    const selectedSegment = selectedSegmentSelector(state);
    const { id: selectedSegmentId } = selectedSegment;
    const selectedReport = selectedReportSelector(state);
    const dimension = dimensionSelector(state);
    const reportData = reportDataSelector(state);
    const reportDataRaw = reportDataRawSelector(state);
    const selectedAccount = selectedAccountSelector(state);
    const { currency, timeZoneName, name: selectedAccountName, type: selectedAccountType } = selectedAccount;
    const { intl } = gridContext;
    const edges = getEdges({
        metrics: graphMetrics,
        edges: graphEdgesState,
        selectedCampaign,
        intl,
        selectedAccount,
    });
    const selectedRows = selectedRowsSelector(state);

    return {
        selectedCampaign,
        selectedCampaignId,
        selectedSegmentId,
        selectedAccountId: props.selectedAccount?.accountId,
        selectedReport,
        reportMetadata,
        columnDefs,
        footerColumnDefs,
        reportData,
        reportTotals,
        graphDataStatus,
        graphData,
        graphTotals,
        selectedCampaignName,
        reportDataStatus,
        reportDuration,
        currency,
        timeZoneName,
        columnState,
        reportTotalRowsCount,
        edges,
        selectedAccountName,
        reportConfig,
        selectedRows,
        selectedAccountType,
        dimension,
        isComfortableViewEnabled,
        graphMetrics,
        gridRowToFlash,
        reportDataRaw,
        intl,
    };
};

const mapDispatchToProps = dispatch => {
    const boundActions = bindActionCreators(
        {
            getReportAndGraphFlow: getReportAndGraph,
            getGraphDataFlow: getGraphData,
            editGridCellFlow: editGridCell,
            setNavigationStateAction: setNavigationState,
            saveGraphOpenStateFlow: saveGraphOpenState,
            resetFlashReportGridRows,
            getGraphDataSuccessFlow: getGraphDataSuccess,
        },
        dispatch
    );

    return { dispatch, ...boundActions };
};

export { CampaignsReports };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignsReports));
