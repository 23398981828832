export const NOTIFICATION_SUB_TYPES = {
    NO_BASIC_TRACKING: 'NO_BASIC_TRACKING',
    BUDGET_REACHED_DEPLETION_RECOMMENDATION: 'BUDGET_REACHED_DEPLETION_RECOMMENDATION',
    INACCESSIBLE_IMAGES: 'INACCESSIBLE_IMAGES',
    RETARGET_AUDIENCE_RECOMMENDATION: 'RETARGET_AUDIENCE_RECOMMENDATION',
    ATTENTIVE_AUDIENCE_RECOMMENDATION: 'ATTENTIVE_AUDIENCE_RECOMMENDATION',
    ITEM_WITHOUT_CTA_RECOMMENDATION: 'ITEM_WITHOUT_CTA_RECOMMENDATION',
    UNBLOCK_SITES_RECOMMENDATION: 'UNBLOCK_SITES_RECOMMENDATION',
    CVR_LEARNING_STATUS_RECOMMENDATION: 'CVR_LEARNING_STATUS_RECOMMENDATION',
    UNREALISTIC_TCPA_RECOMMENDATION: 'UNREALISTIC_TCPA_RECOMMENDATION',
    CAMPAIGN_SEGMENT_EXPLORATION_ENDED: 'CAMPAIGN_SEGMENT_EXPLORATION_ENDED',
    PAID_PREPAYMENTS: 'PAID_PREPAYMENTS',
};
