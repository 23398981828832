import { keyBy, omit } from 'lodash';
import { immutableMergeWith } from 'modules/taboola-common-frontend-modules/utils/customMergers';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { COMPONENT_STATUS } from 'services/constants';
import { REPORT_DATA_MAP_KEYS } from '../../../config/reportsBaseConfig';
import {
    SET_COLUMN_DEF,
    GET_REPORT_SUCCESS,
    SET_REPORT_DATA,
    GET_REPORT_ERROR,
    SET_REPORT_DURATION,
    SET_REPORT_GRID_STATE,
    SET_REPORT_DATA_STATUS,
    REQUEST_GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_DATA_ERROR,
    CLEAR_GRAPH_DATA,
    SET_REPORT_TOTALS,
    RESET_REPORT_ROW_COUNT,
    SET_REPORT_GRAPH_STATE,
    REMOVE_CREATIVE,
    SET_SELECTED_ROWS,
    RESET_SELECTED_ROWS,
    REMOVE_RSS_ITEMS_ROWS,
    CLEAR_RSS_METADATA,
    REQUEST_GET_REPORT,
    SET_GRAPH_METRICS,
} from '../actions/actionTypes';

const initialState = {
    columnDefs: [],
    graphMetrics: [],
    reportData: [],
    graphData: [],
    reportTotals: [],
    graphTotals: [],
    reportDataStatus: COMPONENT_STATUS.INITIAL,
    graphDataStatus: COMPONENT_STATUS.LOADING,
    searchTerm: '',
    reportGridState: {},
    reportGraphState: {},
    reportTotalRowsCount: 0,
    selectedRows: {},
    reportRssMetadata: null,
};

const propsToPersist = ['reportGridState', 'reportGraphState'];

const removeCreativeRow = (state, action) => {
    const updatedReportData = state.reportData.filter(item => item.id !== action.payload);
    return updateObject(state, {
        reportData: updatedReportData,
    });
};

const removeRssItemsRows = (state, action) => {
    const updatedReportData = state.reportData.filter(item => item?.rssParentId !== action.payload);
    const updatedReportRssMetadata = state.reportRssMetadata.filter(rssMetadata => rssMetadata.id !== action.payload);

    return updateObject(state, {
        reportData: updatedReportData,
        reportRssMetadata: updatedReportRssMetadata,
    });
};

const updateReportDataMap = ({ reportId, state, updates, reportData, reportConfig }) => {
    const stateFieldName = REPORT_DATA_MAP_KEYS[reportId];

    if (stateFieldName) {
        return {
            ...updates,
            [stateFieldName]: immutableMergeWith(
                state[stateFieldName],
                keyBy(reportData, reportConfig.rowIdField || 'id')
            ),
        };
    }

    return updates;
};

const handleReportStart = state =>
    state.reportDataStatus === COMPONENT_STATUS.ACTIVE
        ? updateObject(state, { reportDataStatus: COMPONENT_STATUS.LOADING })
        : state;

const handleReportSuccess = (state, { payload }) => {
    const {
        reportId,
        rows: reportData,
        totalRow: reportTotals,
        metadata: reportMetadata,
        rssMetadata: reportRssMetadata,
        reportConfig,
        reportDataRaw,
    } = payload;

    const { total: reportTotalRowsCount } = reportMetadata;
    let updates = {
        reportData,
        reportTotals,
        reportMetadata,
        reportTotalRowsCount,
        reportDataRaw,
    };

    updates = updateReportDataMap({ reportId, state, updates, reportData, reportConfig });

    return updateObject(state, { ...updates, reportRssMetadata });
};

const handleGraphDataSuccess = (state, { payload: { rows, totalRow } = {} }) =>
    updateObject(state, {
        graphData: rows,
        graphTotals: totalRow,
        graphDataStatus: COMPONENT_STATUS.ACTIVE,
    });

const setSelectedRows = (state, { rows, isSelected }) => {
    const { selectedRows } = state;
    let updatedSelectedRows;
    if (isSelected) {
        const newRows = rows.reduce((newRows, row) => {
            newRows[row.id] = row;
            return newRows;
        }, {});
        updatedSelectedRows = updateObject(selectedRows, newRows);
    } else {
        const ids = rows.map(({ id }) => id);
        updatedSelectedRows = omit(selectedRows, ids);
    }
    return updateObject(state, { selectedRows: updatedSelectedRows });
};

const reducer = {
    [SET_COLUMN_DEF]: (state, { payload: columnDefs }) => updateObject(state, { columnDefs }),
    [REQUEST_GET_REPORT]: handleReportStart,
    [GET_REPORT_SUCCESS]: handleReportSuccess,
    [GET_REPORT_ERROR]: state => updateObject(state, { reportData: [], reportTotals: [] }),
    [SET_REPORT_DATA]: (state, action) => updateObject(state, { reportData: action.payload }),
    [SET_REPORT_TOTALS]: (state, action) => updateObject(state, { reportTotals: action.payload }),
    [REQUEST_GET_GRAPH_DATA]: state => updateObject(state, { graphDataStatus: COMPONENT_STATUS.LOADING }),
    [GET_GRAPH_DATA_SUCCESS]: handleGraphDataSuccess,
    [GET_GRAPH_DATA_ERROR]: state =>
        updateObject(state, { graphData: [], graphTotals: [], graphDataStatus: COMPONENT_STATUS.ERROR }),
    [CLEAR_GRAPH_DATA]: state =>
        updateObject(state, { graphData: [], graphTotals: [], graphDataStatus: COMPONENT_STATUS.LOADING }),
    [SET_GRAPH_METRICS]: (state, { payload }) => updateObject(state, { graphMetrics: payload }),
    [SET_REPORT_DURATION]: (state, { payload: { timing } }) => updateObject(state, { reportDuration: timing }),
    [SET_REPORT_GRID_STATE]: (state, action) => updateObject(state, { reportGridState: action.payload }),
    [SET_REPORT_DATA_STATUS]: (state, action) => updateObject(state, { reportDataStatus: action.payload }),
    [RESET_REPORT_ROW_COUNT]: state => updateObject(state, { reportTotalRowsCount: 0 }),
    [SET_REPORT_GRAPH_STATE]: (state, action) => updateObject(state, { reportGraphState: action.payload }),
    [REMOVE_CREATIVE]: removeCreativeRow,
    [REMOVE_RSS_ITEMS_ROWS]: removeRssItemsRows,
    [SET_SELECTED_ROWS]: setSelectedRows,
    [RESET_SELECTED_ROWS]: state => updateObject(state, { selectedRows: {} }),
    [CLEAR_RSS_METADATA]: state => updateObject(state, { reportRssMetadata: null }),
};

export { initialState, propsToPersist };
export default reducer;
