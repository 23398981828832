import { uniq } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { MARKETING_OBJECTIVE_KEYS } from '../../../modules/common-campaign-form/config/marketingObjectives';
import { OS_FAMILY_KEYS } from '../../../modules/creative-creator/components/AdSetupSection/useAdSetupUrlInputGroupConfig';

export const checkAppInstallOSFamily = (value, marketingObjective) => {
    if (marketingObjective !== MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL) {
        return true;
    }

    const osFamilies = uniq(value.map(el => el.osFamily));
    if (osFamilies?.length !== 1) {
        return false;
    }

    const [osFamily] = osFamilies;
    return osFamily === OS_FAMILY_KEYS.ANDROID || osFamily === OS_FAMILY_KEYS.IOS;
};

export const appInstallOSTargetingValidations = [
    {
        validationFn: (value, options, { marketingObjective }) => checkAppInstallOSFamily(value, marketingObjective),
        messageId: 'validations.error.campaign.targeting.os.appInstall',
        defaultMessage:
            'Please select one Operating System per campaign. To target an additional OS family, please create a separate campaign',
        severity: INDICATION_TYPES.WARNING,
    },
];
