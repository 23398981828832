import { useMemo } from 'react';
import { isEmpty, keyBy } from 'lodash';

export const useSelectedRuleDropdownValue = ({ options, conversionRules }) => {
    const optionsMap = useMemo(() => keyBy(options, 'id'), [options]);

    const selectedRule = conversionRules?.[0];
    if (!selectedRule) {
        return {
            value: null,
        };
    }

    const { id, displayName } = selectedRule;

    if (!isEmpty(options) && optionsMap[id]) {
        return {
            value: id.toString(),
        };
    }

    return {
        selectedValueObject: {
            value: id.toString(),
            label: displayName,
        },
    };
};
