import { REPLACE_NAVIGATION_STATE } from './actionTypes';

/**
 * @deprecated - use link or useNavigate hook instead
 * @param history
 * @param routerLocationState
 * @returns {{payload: {destination, history}, type: string}}
 */
const setNavigationState = (history, routerLocationState) => ({
    type: REPLACE_NAVIGATION_STATE,
    payload: { history, destination: `${history.location.pathname}${history.location.search}`, routerLocationState },
});

export default setNavigationState;
