import React from 'react';
import { useTargetCpaAdditionalValidations } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategyV2/MaxConversionsCategory/useTargetCpaAdditionalValidations';
import { useTargetCpaValidations } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategyV2/MaxConversionsCategory/useTargetCpaValidations';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import SimpleCurrencyCellEditor from './SimpleCurrencyCellEditor';

const CpaGoalCellEditor = props => {
    const { targetCpaValidationDependencies, targetCpaValidations } = useTargetCpaAdditionalValidations();
    const validations = useTargetCpaValidations({
        additionalDependencies: targetCpaValidationDependencies,
        additionalValidations: targetCpaValidations,
    });

    return <SimpleCurrencyCellEditor {...props} {...validations} />;
};

export const CpaGoalCellEditorWithFormData = withGridCellFormDataProvider(CpaGoalCellEditor);
