'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useMergedStylesheets = useMergedStylesheets;
exports.useMergedClasses = useMergedClasses;

var _react = require('react');

var _lodash = require('lodash.mergewith');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.mapvalues');

var _lodash4 = _interopRequireDefault(_lodash3);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var EMPTY_OBJECT = {};

function mergeStylesheets(base, extensions) {
    if (!Object.keys(extensions).length) {
        return base;
    }
    var mergedClasses = (0, _lodash2.default)({}, base, extensions, function (objectValue, srcValue) {
        return [objectValue, srcValue];
    });
    var commonClasses = (0, _lodash4.default)(mergedClasses, function (cls) {
        return (0, _classnames2.default)(cls);
    });

    return commonClasses;
}

function useMergedStylesheets(base) {
    var extensions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EMPTY_OBJECT;

    return (0, _react.useMemo)(function () {
        return mergeStylesheets(base, extensions);
    }, [base, extensions]);
}

function useMergedClasses(classNames, base, extensions) {
    var merged = useMergedStylesheets(base, extensions);
    var classes = (0, _react.useMemo)(function () {
        return (0, _lodash4.default)(classNames || {}, function (value) {
            return Array.isArray(value) ? (0, _classnames2.default)(value.map(function (cls) {
                return merged[cls];
            })) : merged[value];
        });
    }, [merged]);
    return classes;
}