import { useCallback } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGeoTargetingApi } from 'services/api/geoTargetingApi';

const useZipCodesApi = () => {
    const { getZipCodes } = useGeoTargetingApi();
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });

    const fetcher = useCallback(
        async ids => {
            let results = [];

            try {
                const response = await getZipCodes(countryCodes, ids);
                results = response;
            } catch (e) {
                // no need to handle
            }

            return results;
        },
        [countryCodes, getZipCodes]
    );

    return fetcher;
};

export default useZipCodesApi;
