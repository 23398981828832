import React, { Component } from 'react';
import ActionableTextCellRenderer from './ActionableTextCellRenderer';
import EllipsisCellRenderer from './EllipsisCellRenderer';

class ActionableTextWithEllipsisCellRenderer extends Component {
    render() {
        return (
            <ActionableTextCellRenderer {...this.props}>
                <EllipsisCellRenderer {...this.props} />
            </ActionableTextCellRenderer>
        );
    }
}

ActionableTextWithEllipsisCellRenderer.propTypes = {
    ...ActionableTextCellRenderer.propTypes,
    ...EllipsisCellRenderer.propTypes,
};

export default ActionableTextWithEllipsisCellRenderer;
