import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCurrentValueGetter, useModuleName, useNavigate } from 'hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { isIframeMode } from 'modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { userSelector } from '../../../taboola-common-frontend-modules/authentication';
import { useChatAgentContext } from '../../../taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { MODULE_NAME, ONBOARDING_ROUTE } from '../../config/module';
import { ONBOARDING_CHAT_DONE_KEY } from '../../config/onboardingSkipKey';

export const useOnboardingFlow = () => {
    const module = useModuleName();
    const moduleGetter = useCurrentValueGetter(module);
    const { setChatReady } = useChatAgentContext();
    const isEnabled = useConfigMatch({ [FEATURE_FLAGS.CHAT_AGENT_ONBOARDING_ENABLED]: 'true' });
    const [localStorageGetter] = useLocalStorage();
    const shouldSkip = localStorageGetter(ONBOARDING_CHAT_DONE_KEY);
    const iFrameMode = isIframeMode();
    const { username, hasCampaigns = true } = useSelector(userSelector);
    const isAuthorized = !!username;
    const shouldRun = isEnabled && !iFrameMode && !shouldSkip && !hasCampaigns && isAuthorized;
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthorized || moduleGetter() === MODULE_NAME) {
            return;
        }
        if (shouldRun) {
            navigate(ONBOARDING_ROUTE);
            return;
        }
        setChatReady(true);
    }, [navigate, shouldRun, setChatReady, isAuthorized, moduleGetter]);
};
