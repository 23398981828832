import { reduce } from 'lodash';
import { isCampaignTargetingField } from 'modules/campaigns/modules/common-campaign-form/utils/targetingRestrictions/isCampaignTargetingField';
import { CustomValidationError } from 'modules/errors/CustomValidationError';

export const isTargetingRestrictionEnforcedForSupply = (targeting, currentCampaignTargeting) => {
    return reduce(
        targeting,
        (acc, restriction) => {
            const { isFieldValid, targetingField } = isCampaignTargetingField(restriction, currentCampaignTargeting);
            return {
                isFieldValid: acc.isFieldValid && isFieldValid,
                targetingField: isFieldValid ? acc.targetingField : [...acc.targetingField, targetingField],
            };
        },
        { isFieldValid: true, targetingField: [] }
    );
};

export const isSupplyTargetingRestrictionEnforced = ({
    supplyTargeting,
    supplyTargetingResource,
    currentCampaignTargetingByType,
}) => {
    if (!supplyTargetingResource) {
        return true;
    }

    const currentSupplyTargeting = supplyTargetingResource?.[supplyTargeting];

    if (!currentSupplyTargeting?.targetingRestrictions || !currentCampaignTargetingByType) {
        return true;
    }

    const { isFieldValid, targetingField } = isTargetingRestrictionEnforcedForSupply(
        currentSupplyTargeting.targetingRestrictions,
        currentCampaignTargetingByType
    );

    if (isFieldValid) {
        return isFieldValid;
    }

    const supplyTargetingLabel = currentSupplyTargeting.name;
    const targetingFieldDescription = targetingField.join(', ');
    const verb = targetingField.length > 1 ? 'are' : 'is';

    throw new CustomValidationError({
        messageCode: 'campaign.editor.targeting.supply.unsupported.targeting',
        message: `Please note: ${targetingFieldDescription} targeting ${verb} not supported by ${supplyTargetingLabel}`,
        templateParameters: { supplyTargetingLabel, targetingFieldDescription, verb },
    });
};
