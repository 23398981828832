import { get, pick } from 'lodash';

export const getInvalidFieldsWithMessages = (invalidFields, data) => {
    const result = { invalidFields: {} };

    invalidFields.reduce((acc, item) => {
        const { fieldId, failedValidationData } = item;
        const message =
            typeof failedValidationData?.message === 'string'
                ? failedValidationData?.message
                : pick(failedValidationData?.message?.props, ['id', 'defaultMessage', 'values']);

        acc[fieldId] = {
            value: get(data, fieldId),
            indicationType: failedValidationData?.indicationType,
            message,
        };

        return acc;
    }, result.invalidFields);

    return result;
};
