import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';

export const TooltipWrapper = ({ wrap = true, children, tooltipContent, tooltipOptions }) =>
    wrap ? (
        <span>
            {children}
            <Tooltip {...tooltipOptions}>{tooltipContent}</Tooltip>
        </span>
    ) : (
        children
    );
