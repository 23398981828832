import React from 'react';
import styles from './achBankDetails.module.scss';

export const AchBankDetails = ({ accountFourLastDigits, bankName }) => {
    return (
        <>
            <span>{`${accountFourLastDigits}`}</span>
            <span className={styles['exp']}>{bankName}</span>
        </>
    );
};
