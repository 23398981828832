import { createSelector } from 'reselect';
import { accountsMapSelector, appStateSelector, transformAccountObject } from './baseSelectors';

export const accountsDataSelector = createSelector(appStateSelector, appState => appState.accounts);

const accountsSelector = createSelector(accountsDataSelector, accountsMapSelector, (accountsData, accountsMap) =>
    accountsData.map(account => transformAccountObject(accountsMap[account.accountId]))
);

export default accountsSelector;
