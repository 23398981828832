import React from 'react';
import { FormattedAnchor } from '../FormattedAnchor';

// These are the map of keys we support for parsing. For instance, when a user enters {newline}
// We will parse it to <br />
export const buildFormattedMessageMap = (values, id) => ({
    ...values,
    newline: <br />,
    br: <br />,
    b: (...chuncks) => <strong>{chuncks}</strong>,
    i: (...chuncks) => <em>{chuncks}</em>,
    strong: (...chuncks) => <strong>{chuncks}</strong>,
    em: (...chuncks) => <em>{chuncks}</em>,
    a: text => (
        <FormattedAnchor values={values} id={id}>
            {text}
        </FormattedAnchor>
    ),
    li: (...chuncks) => <li>{chuncks}</li>,
    ul: (...chuncks) => <ul>{chuncks}</ul>,
    u: (...chuncks) => <u>{chuncks}</u>,
});
