export const transformPaymentMethodData = (userData, card) => {
    const {
        cardHolderName,
        billingAddress: { zipCode, country, region, street, city },
    } = userData;

    return {
        payment_method: {
            card,
            billing_details: {
                name: cardHolderName,
                address: {
                    city,
                    country,
                    state: region,
                    line1: street,
                    postal_code: zipCode,
                },
            },
        },
    };
};

export const transformConfirmSetupResponse = ({ setupIntent, error }) => {
    if (error) {
        return { error };
    }

    const { payment_method } = setupIntent;
    return { paymentMethodToken: payment_method };
};
