import { isEmpty } from 'lodash';
import { CREATIVE_STATUS } from '../../../../../../../config';
import { PHASES, GW_PHASES } from '../../../../../services/campaignStatusPhaseService';
import { MINIMUM_ADS_RUNNING, SUB_STEP_DATA } from '../../../consts';

const { SCALING, LEARNING_LIMITED, LEARNING } = PHASES;
const { COMPLETED_LEARNING, GETTING_CONVERSIONS } = GW_PHASES;
const { RUNNING, PENDING_APPROVAL, REJECTED } = CREATIVE_STATUS;
const { HAS_MINIMUM_ADS_PER_CAMPAIGN_RUNNING, HAS_LEARNING_OR_SCALING_CAMPAIGNS } = SUB_STEP_DATA;
const LearningOrScalingPhase = new Set([SCALING, LEARNING_LIMITED, LEARNING, COMPLETED_LEARNING, GETTING_CONVERSIONS]);

const getHasLearningOrScalingCampaigns = desktopOrMobileCampaigns => {
    return desktopOrMobileCampaigns.some(campaign => {
        const { trackerPhase } = campaign;
        return LearningOrScalingPhase.has(trackerPhase);
    });
};

const getHasSomeStatusCreative = (desktopOrMobileCampaigns, status) => {
    return desktopOrMobileCampaigns.some(campaign => {
        const { creatives } = campaign;
        return creatives.some(creative => creative.status === status);
    });
};

const getHasMinimumAdsPerCampaignRunning = desktopOrMobileCampaigns => {
    const getHasMinimumCreativesRunning = campaign => {
        const { creatives } = campaign;
        const runningCreatives = creatives.filter(creative => creative.status === RUNNING);
        return runningCreatives.length >= MINIMUM_ADS_RUNNING;
    };

    return isEmpty(desktopOrMobileCampaigns)
        ? false
        : desktopOrMobileCampaigns.every(campaign => getHasMinimumCreativesRunning(campaign));
};

const getLowestCreativesStatus = desktopOrMobileCampaigns => {
    const hasSomeRejectedCreative = getHasSomeStatusCreative(desktopOrMobileCampaigns, REJECTED);
    const hasSomePendingCreative = getHasSomeStatusCreative(desktopOrMobileCampaigns, PENDING_APPROVAL);
    const hasMinimumAdsPerCampaignRunning = getHasMinimumAdsPerCampaignRunning(desktopOrMobileCampaigns);

    if (hasMinimumAdsPerCampaignRunning) {
        return RUNNING;
    } else if (hasSomeRejectedCreative) {
        return REJECTED;
    } else if (hasSomePendingCreative) {
        return PENDING_APPROVAL;
    }

    return undefined;
};

export const getLaunchCampaignData = desktopOrMobileCampaigns => {
    const lowestCreativesStatus = getLowestCreativesStatus(desktopOrMobileCampaigns);
    const hasMinimumCreativesPerCampaignRunning = lowestCreativesStatus === RUNNING;
    const hasLearningOrScalingCampaigns = getHasLearningOrScalingCampaigns(desktopOrMobileCampaigns);

    return {
        [HAS_MINIMUM_ADS_PER_CAMPAIGN_RUNNING]: hasMinimumCreativesPerCampaignRunning,
        [HAS_LEARNING_OR_SCALING_CAMPAIGNS]: hasLearningOrScalingCampaigns,
        lowestCreativesStatus,
    };
};
