import { REPORT_TYPE } from 'modules/campaigns/config';
import {
    csvDateHeaderValueGetter,
    formattedUTCDateValueGetter,
    gridNumberFormatter,
    gridUTCDateFormatter,
    InvoicesActionsCellRenderer,
} from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    hiddenColumnType,
    numericColumn,
    rightPinnedColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { FormattedMessageHeaderRenderer } from '../../../components/HeaderRenderers';
import * as FIELDS from '../../fields';

const reportColumnDefinition = [
    {
        headerName: FIELDS.BILLING_DATE.label,
        headerValueGetter: FIELDS.BILLING_DATE.headerValueGetter,
        headerComponentParams: FIELDS.BILLING_DATE.headerComponentParams,
        field: FIELDS.BILLING_DATE.field,
        valueFormatter: gridUTCDateFormatter,
        csvValueGetter: formattedUTCDateValueGetter,
        csvHeaderValueGetter: csvDateHeaderValueGetter,
        width: 120,
        minWidth: 80,
    },
    {
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerName: FIELDS.INVOICE_NAME.label,
        headerComponentParams: FIELDS.INVOICE_NAME.headerComponentParams,
        field: FIELDS.INVOICE_NAME.field,
        width: 140,
        minWidth: 90,
    },
    {
        headerName: FIELDS.INVOICE_AMOUNT.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.INVOICE_AMOUNT.headerComponentParams,
        field: FIELDS.INVOICE_AMOUNT.field,
        cellRendererParams: FIELDS.INVOICE_AMOUNT.renderParams,
        valueFormatter: gridNumberFormatter,
        type: [numericColumn],
        width: 140,
        minWidth: 90,
    },
    {
        headerName: FIELDS.DUE_DATE.label,
        headerValueGetter: FIELDS.DUE_DATE.headerValueGetter,
        headerComponentParams: FIELDS.DUE_DATE.headerComponentParams,
        field: FIELDS.DUE_DATE.field,
        valueFormatter: FIELDS.DUE_DATE.valueFormatter,
        csvValueGetter: FIELDS.DUE_DATE.csvValueGetter,
        csvHeaderValueGetter: csvDateHeaderValueGetter,
        width: 120,
        minWidth: 80,
    },
    {
        headerName: FIELDS.INVOICE_STATUS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.INVOICE_STATUS.headerComponentParams,
        field: FIELDS.INVOICE_STATUS.field,
        cellRendererParams: FIELDS.INVOICE_STATUS.renderParams,
        cellRendererFramework: FIELDS.INVOICE_STATUS.cellRendererFramework,
        csvValueGetter: FIELDS.INVOICE_STATUS.csvValueGetter,
        width: 140,
        minWidth: 90,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [actionsColumnType, rightPinnedColumn],
        cellRendererFramework: InvoicesActionsCellRenderer,
        minWidth: 40,
        maxWidth: 40,
        width: 40,
    },
    {
        field: FIELDS.INVOICE_ID.field,
        type: [hiddenColumnType, numericColumn],
    },
    {
        field: FIELDS.INVOICE_TYPE.field,
        type: [hiddenColumnType],
    },
];
export const columnDefs = normalizeColumnDefs(reportColumnDefinition, REPORT_TYPE.INVOICES);
