import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './rateVsAvgCellRenderer.module.scss';

const classNameBuilder = classNames.bind(styles);

const MAX_BAR_VALUE = 200;

const RateVsAvgCellRenderer = ({ value: rate }) => {
    if (rate == null || rate <= 0) {
        return <span />;
    }

    const rateAsPercent = Math.round(rate * 100);
    const formattedCvrUplift = `${rateAsPercent}%`;
    const barValue = Math.min(rateAsPercent, MAX_BAR_VALUE);
    const isHighTrust = rate > 1;
    const progressBarColor = isHighTrust ? 'high-trust-progress' : 'low-trust-progress';
    const progressBarClassName = classNameBuilder('progress-bar', progressBarColor);

    return (
        <div className={styles['container']}>
            <div className={styles['bar-and-value-container']}>
                <div className={styles['uplift-value']}>{formattedCvrUplift}</div>
                <div className={styles['progress-bar-container']}>
                    <progress className={progressBarClassName} value={barValue} max={MAX_BAR_VALUE} />
                    <div className={styles['middle-bar']} />
                </div>
            </div>
        </div>
    );
};

RateVsAvgCellRenderer.propTypes = {
    value: PropTypes.number,
};

export default RateVsAvgCellRenderer;
