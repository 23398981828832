import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { SegmentDescriptionField } from '../AudienceDescriptionField/AudienceDescriptionField';
import { TopicsLanguageField } from '../SegmentTopics/LanguageField/TopicsLanguageField';
import TopicsListField from '../SegmentTopics/TopicsListField';
import styles from './customContextualTopicsSection.module.scss';

export const CustomContextualTopicsSection = () => {
    return (
        <CommonCollapsibleCard
            id="CUSTOM_CONTEXTUAL_AUDIENCE_TOPICS"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="custom.contextual.editor.topics.section.title"
                            defaultMessage="Topics Setup"
                        />
                    }
                />
            }
        >
            <div className={styles['field-container']}>
                <TopicsLanguageField />
                <TopicsListField />
                <SegmentDescriptionField />
            </div>
        </CommonCollapsibleCard>
    );
};

export default CustomContextualTopicsSection;
