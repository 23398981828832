import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import usePerformanceRecommendationExecution from '../../hooks/usePerformanceRecommendationExecution';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

const params = {
    titleCode: 'performance-recommendations.day-parting.title',
    descriptionCode: 'performance-recommendations.day-parting.description',
    actionCode: 'performance-recommendations.day-parting.action',
    tooltipCode: 'performance-recommendations.day-parting.action.tooltip',
    dismissCode: 'performance-recommendations.general-recommendation.dismiss',
    successCode: 'performance-recommendations.general-recommendation.success-message',
    errorCode: 'performance-recommendations.general-recommendation.error-message',
    iconImg: LightningRoundIcon,
};

export const DayPartingPerformanceRecommendation = ({ recommendation, messageParams = {} }) => {
    const { formatMessage } = useIntl();
    const { campaignId, id: recommendationId, actions, accountName: accountId } = recommendation;
    const [action] = actions;

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId: action.actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: params.errorCode,
    });

    const actionDetails = {
        actionId: action.actionId,
    };

    const transformedMessageParams = useMemo(
        () => ({
            ...messageParams,
            day_type: formatMessage({
                id: `${params.descriptionCode}.${messageParams.day_type}`,
                defaultMessage: `${messageParams.day_type}`,
            }),
        }),
        [formatMessage, messageParams]
    );

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={transformedMessageParams}
            isApplied={apply.isSuccess}
            {...params}
        >
            <ButtonsSection>
                <DismissSection
                    dismissInvoker={dismiss.invoke}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissCode={params.dismissCode}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode={params.actionCode}
                    actionParams={actionDetails}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode={params.tooltipCode}
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

DayPartingPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default DayPartingPerformanceRecommendation;
