import React from 'react';
import classnames from 'classnames/bind';
import { isEmpty, noop, get } from 'lodash';
import PropTypes from 'prop-types';
import { Button, Spinner, LeftArrowIcon } from 'tuui';
import { RefreshIcon } from 'taboola-ultimate-ui';
import ErrorMsg from 'modules/errors/components/ErrorMsg/ErrorMsg';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useUserPermissionOrFeatureFlagEnabled } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { useCreativeCreatorConfigPermissions } from '../../modules/creative-creator/hooks/useCreativeCreatorConfigPermissions';
import { ContentAiSuggestionsResultsSection } from './ContentAiSuggestionsResultsSection';
import ContentTitleAISuggestionsBaseCard from './ContentTitleAISuggestionsBaseCard';
import styles from './contentTitleAISuggestionsResults.module.scss';

const classNameBuilder = classnames.bind(styles);

const ContentTitleAISuggestionsResults = ({
    status,
    campaignId,
    suggestionsHistory,
    selectedSuggestionsPage,
    showNextSuggestionsPage,
    showPreviousSuggestionsPage,
    clearSuggestions,
    onChangeCallback,
    errorMessage,
    triggerSuccessIndication,
    ...rest
}) => {
    const { collapsed = true } = rest;
    const { titles: titleSuggestions, descriptions: descriptionSuggestions } = get(
        suggestionsHistory,
        selectedSuggestionsPage,
        {}
    );
    const { isContentDescriptionEnabled } = useCreativeCreatorConfigPermissions();
    const isAIDescriptionSuggestionsFlagEnabled = useUserPermissionOrFeatureFlagEnabled(
        'AI_DESCRIPTION_SUGGESTIONS',
        FEATURE_FLAGS.AI_DESCRIPTION_SUGGESTIONS_ENABLED
    );

    const shouldDisplayDescriptionSuggestions =
        isContentDescriptionEnabled && isAIDescriptionSuggestionsFlagEnabled && !isEmpty(descriptionSuggestions);
    const isLoading = status === COMPONENT_STATUS.LOADING;
    const isPreviousSuggestionsDisabled = selectedSuggestionsPage === 0 || isLoading;

    const header = (
        <div className={styles['header-content']}>
            <Button
                size={Button.size.sm}
                variant={Button.variant.text}
                onClick={clearSuggestions}
                className={styles['back-button']}
                data-testid="suggestions-back-button"
            >
                <LeftArrowIcon className={styles['icon']} />
            </Button>
            <FormattedMessage id="creative.creator.aiTitleSuggestions.header.results" defaultMessage="Suggested text" />
        </div>
    );

    const { onChange: onChangeTitle } = useFormFieldValue({ field: 'title' });
    const { onChange: onChangeDescription } = useFormFieldValue({ field: 'description' });
    const { onChange: onChangeDescriptionEnabled } = useFormFieldValue({ field: 'descriptionEnabled' });

    // show the "Regenerate All" message only if we're at the end of the history and the history is at a multiple of 3
    let buttonMessage = (
        <FormattedMessage id="creative.creator.aiTitleSuggestions.more.button" defaultMessage="Show More" />
    );
    if (selectedSuggestionsPage === suggestionsHistory.length - 1 && suggestionsHistory.length % 3 === 0) {
        buttonMessage = (
            <>
                <RefreshIcon size={Button.size.sm} />
                <FormattedMessage
                    id="creative.creator.aiTitleSuggestions.regenerate.button"
                    defaultMessage="Regenerate All"
                />
            </>
        );
    }

    const onSuggestedTitleClick = title => {
        onChangeTitle(title);
        if (onChangeCallback) {
            onChangeCallback(title);
        }
        triggerSuccessIndication();
    };

    const onSuggestedDescriptionClick = description => {
        onChangeDescription(description);
        onChangeDescriptionEnabled(true);
    };

    return (
        <ContentTitleAISuggestionsBaseCard
            header={header}
            description={
                <FormattedMessage
                    id="creative.creator.aiTitleSuggestions.description.results"
                    defaultMessage="Select one title and one description. You can regenerate couple of times for more results, and edit these text options after adding them. "
                />
            }
            containerClassName={classNameBuilder({ 'opened-card-container': !collapsed })}
            {...rest}
        >
            <div className={styles['results-container']}>
                <ContentAiSuggestionsResultsSection
                    header={
                        <FormattedMessage
                            id="creative.creator.aiTitleSuggestions.title.section.header"
                            defaultMessage="Titles"
                        />
                    }
                    gaWord="Title"
                    campaignId={campaignId}
                    results={titleSuggestions}
                    onItemClick={onSuggestedTitleClick}
                />
                {shouldDisplayDescriptionSuggestions && (
                    <ContentAiSuggestionsResultsSection
                        header={
                            <FormattedMessage
                                id="creative.creator.aiTitleSuggestions.description.section.header"
                                defaultMessage="Descriptions"
                            />
                        }
                        gaWord="Description"
                        campaignId={campaignId}
                        results={descriptionSuggestions}
                        onItemClick={onSuggestedDescriptionClick}
                    />
                )}
                {errorMessage && <ErrorMsg errorMessage={errorMessage} />}
                <div className={styles['action-buttons']}>
                    <Button
                        onClick={showPreviousSuggestionsPage}
                        className={classNameBuilder({ 'previous-suggestions-button': !isPreviousSuggestionsDisabled })}
                        variant={Button.variant.text}
                        disabled={isPreviousSuggestionsDisabled}
                    >
                        <FormattedMessage
                            id="creative.creator.aiTitleSuggestions.previousSuggestions.button"
                            defaultMessage="Previous Suggestions"
                        />
                    </Button>
                    <Button
                        onClick={showNextSuggestionsPage}
                        className={styles['regenerate-button']}
                        variant={Button.variant.ghost}
                        disabled={isLoading}
                    >
                        {status === COMPONENT_STATUS.LOADING ? (
                            <Spinner size={14} className={styles['spinner']} color="primary" />
                        ) : (
                            <div className={styles['button-content']}>{buttonMessage}</div>
                        )}
                    </Button>
                </div>
            </div>
        </ContentTitleAISuggestionsBaseCard>
    );
};

ContentTitleAISuggestionsResults.propTypes = {
    suggestionsHistory: PropTypes.array,
    selectedSuggestionsPage: PropTypes.number,
    showNextSuggestionsPage: PropTypes.func,
    showPreviousSuggestionsPage: PropTypes.func,
    clearSuggestions: PropTypes.func,
    campaignId: PropTypes.number,
    errorMessage: PropTypes.string,
    status: PropTypes.string,
    onRegenerateClick: PropTypes.func,
    onChangeCallback: PropTypes.func,
};

ContentTitleAISuggestionsResults.defaultProps = {
    selectedSuggestionsPage: 0,
    showNextSuggestionsPage: noop,
    showPreviousSuggestionsPage: noop,
    clearSuggestions: noop,
    suggestionsHistory: [],
    campaignId: -1,
    status: COMPONENT_STATUS.ACTIVE,
    onRegenerateClick: noop,
    onChangeCallback: noop,
};

export default ContentTitleAISuggestionsResults;
