import {
    compact,
    entries,
    flatten,
    get,
    groupBy,
    isEmpty,
    isNil,
    isObject,
    keyBy,
    map,
    omit,
    set,
    split,
    toNumber,
    trim,
} from 'lodash';
import moment from 'moment-timezone';
import { isNetworkAccountType } from 'modules/account-management';
import { getInitialDeliveryMethod } from '../../common-campaign-form/components/Budget/const/emptyBudgetObject';
import { DAY_TIME_BID_OPTION } from '../../common-campaign-form/components/DayTimeBidBoost/consts/dayTimeBidOptions';
import { PLATFORM_BID_OPTION } from '../../common-campaign-form/components/PlatformBidBoost/consts/platformBidOptions';
import { RUNTIME_TYPES } from '../../common-campaign-form/config';
import BRAND_SAFETY_TYPES from '../../common-campaign-form/config/BrandSafeTypes';
import CONVERSION_RULE_MODES from '../../common-campaign-form/config/ConversionRuleModes';
import CREATIVE_TRAFFIC_METHOD from '../../common-campaign-form/config/creativeTrafficAllocationMethodTypes';
import { checkObjectListForMissingFields, getBrandSafetySegmentExcelLabel } from '../utils/excelBulkWorkbookUtils';
import { ExcelBulkError } from './ExcelBulkError';

export const EXCEL_DEFAULT_VALUE_TERM = 'DEFAULT';
const LIST_ITEM_DELIMITER = ';';
const OBJECT_ENTRY_DELIMITER = ',';
const OBJECT_KEY_VALUE_DELIMITER = ':';
const ALL_OS_ID = 'ALL';

const PUBLISHER_BID_MODIFICATION_MODES = {
    NONE: 'NONE',
    CUSTOM: 'CUSTOM',
};

const BID_MODIFICATION_KEYS = {
    PUBLISHER: 'publisher',
    BID_MODIFICATION: 'bidModification',
};

const setBidModifierMode = (entityData, value, path, bidModificationModes) => {
    const mode = isEmpty(value) ? bidModificationModes?.NONE : bidModificationModes?.CUSTOM;
    set(entityData, path, mode);
};

export const transformHyperLinkObjectToString = cellData =>
    isObject(cellData) && cellData.text ? cellData.text : cellData;
export const transformListToString = list => list.join(LIST_ITEM_DELIMITER);
export const transformStringToList = string =>
    split(string, LIST_ITEM_DELIMITER)
        .filter(string => !isEmpty(string))
        .map(trim);

export const transformStringToNumberList = string => {
    const list = transformStringToList(string);
    return map(list, toNumber);
};
export const transformObjectToString = object =>
    entries(object)
        .map(([key, value]) => `${key}${OBJECT_KEY_VALUE_DELIMITER}${value}`)
        .join(OBJECT_ENTRY_DELIMITER);

export const transformStringToObject = string =>
    string
        .split(OBJECT_ENTRY_DELIMITER)
        .filter(string => !isEmpty(string))
        .reduce((acc, keyValueString) => {
            const [key, value] = keyValueString.split(OBJECT_KEY_VALUE_DELIMITER);
            return { ...acc, [trim(key)]: trim(value) };
        }, {});

export const transformObjectListToDelimitedString = (objectList = [], { keysToOmit = [] } = {}) => {
    const listOfStrings = objectList.map(object => omit(object, keysToOmit)).map(transformObjectToString);
    const excelValue = transformListToString(listOfStrings);

    return excelValue;
};

export const transformStringToBidModificationValues = cellData => {
    const value = transformStringToList(cellData);

    const bidModificationValues = value.reduce((acc, keyValuePair) => {
        const [key, value] = keyValuePair.split(':');
        acc.push({
            [BID_MODIFICATION_KEYS.PUBLISHER]: trim(key),
            [BID_MODIFICATION_KEYS.BID_MODIFICATION]: trim(value),
        });
        return acc;
    }, []);

    return bidModificationValues;
};

export const transformObjectListFromExcel = cellData => transformStringToList(cellData).map(transformStringToObject);

const excelDateFormat = 'YYYY-MM-DD';
export const transformDateToExcel = timestamp => moment(timestamp).format(excelDateFormat);

export const transformDateFromExcel = excelDateString => moment(excelDateString, excelDateFormat).valueOf();

export const transformPublisherTargetingToExcel = (list = []) => transformListToString(map(list, 'accountName'));

export const transformBlockSitesFromExcel = cellData => {
    const accountNamesList = transformStringToList(cellData);

    return accountNamesList.map(accountName => ({ accountName }));
};

export const transformOSToExcel = (value = [], { osList }) => {
    const flatList = flatten(
        value.map(({ osFamily, subCategories }) =>
            isEmpty(subCategories)
                ? { osFamily, subCategoryId: ALL_OS_ID }
                : subCategories.map(subCategory => ({ osFamily, subCategoryId: subCategory }))
        )
    );
    const labelList = flatList.map(({ osFamily, subCategoryId }) => {
        const os = osList.find(
            ({ osFamily: itemOsFamily, subCategory }) => itemOsFamily === osFamily && subCategory.id === subCategoryId
        );

        return os?.subCategory?.label;
    });
    const excelValue = transformListToString(labelList);

    return excelValue;
};

export const transformOSListFromExcel = (cellData, options) => {
    const { osLabelMap, cellHeaderName, formatMessage } = options;
    const osLabelList = transformStringToList(cellData);
    const osList = osLabelList.map(osLabel => {
        if (!osLabelMap[osLabel]?.osFamily) {
            throw new ExcelBulkError(
                formatMessage({ id: 'excel.bulk.upload.transform.error.value' }, { cellHeaderName, value: osLabel })
            );
        }

        return {
            osFamily: osLabelMap[osLabel].osFamily,
            subCategories: osLabelMap[osLabel].subCategory.id === ALL_OS_ID ? [] : [osLabelMap[osLabel].subCategory.id],
        };
    });
    const groupedFamilies = groupBy(osList, 'osFamily');
    const groupedList = entries(groupedFamilies).map(([osFamily, value]) => ({
        osFamily,
        subCategories: flatten(value.map(({ subCategories }) => subCategories)),
    }));

    return groupedList;
};

export const transformConversionRulesToExcel = (list = []) => transformListToString(map(list, 'displayName'));

export const transformConversionRulesFromExcel = (cellData, options) => {
    const { conversionRulesMap, entityData, cellHeaderName, formatMessage } = options;
    const conversionRulesDisplayNameList = transformStringToList(cellData);
    const conversionRulesList = map(conversionRulesDisplayNameList, displayName => {
        const rule = get(conversionRulesMap, displayName);

        if (!rule) {
            throw new ExcelBulkError(
                formatMessage({ id: 'excel.bulk.upload.transform.error.value' }, { cellHeaderName, value: displayName })
            );
        }

        return rule;
    });
    const value = compact(conversionRulesList);

    if (!isEmpty(value)) {
        set(entityData, 'conversionRulesMode', CONVERSION_RULE_MODES.CUSTOM);
    }

    return value;
};

export const transformDayTimeBidModifierToExcel = (list = []) =>
    transformListToString(list.map(item => omit(item, ['id'])).map(transformObjectToString));

export const transformDayTimeBidModifierFromExcel = (cellData, { entityData }) => {
    const value = transformObjectListFromExcel(cellData);

    setBidModifierMode(entityData, value, 'dayTimeBidModifier.mode', DAY_TIME_BID_OPTION);

    return value;
};

export const transformPlatformBidModifierToExcel = (list = []) =>
    transformListToString(list.map(transformObjectToString));

export const transformPlatformBidModifierFromExcel = (cellData, { entityData }) => {
    const value = transformObjectListFromExcel(cellData);

    setBidModifierMode(entityData, value, 'platformBidModifier.mode', PLATFORM_BID_OPTION);
    return value;
};

export const transformPublisherBidModificationToExcel = object => {
    return object.map(({ publisher, bidModification }) => `${publisher}:${bidModification}`).join(LIST_ITEM_DELIMITER);
};

export const transformPublisherBidModificationFromExcel = (cellData, { entityData }) => {
    const value = transformStringToBidModificationValues(cellData);

    setBidModifierMode(entityData, value, 'adsConsoleBidModifications.mode', PUBLISHER_BID_MODIFICATION_MODES);

    return value;
};

export const transformCityTargetingToExcel = (list = []) => transformListToString(map(list, 'id'));

export const transformCityTargetingFromExcel = cellData => {
    const cityTargetingIds = transformStringToList(cellData);
    return map(cityTargetingIds, id => ({ id }));
};

export const transformMediaFileNameFromExcel = (cellData, options) => {
    const { mediaMap, cellHeaderName, formatMessage, entityData, column } = options;
    const { field, dynamicFieldPathGetter } = column;
    const transformedConfig = mediaMap[cellData];

    if (!transformedConfig || transformedConfig?.error) {
        throw new ExcelBulkError(
            formatMessage(
                { id: 'excel.bulk.upload.transform.error.value.detailed' },
                {
                    cellHeaderName,
                    value: cellData,
                    detail: formatMessage(
                        {
                            id: transformedConfig?.error?.message || 'excel.bulk.upload.transform.image.missing',
                        },
                        {
                            value: cellData,
                        }
                    ),
                }
            )
        );
    }

    set(
        entityData,
        dynamicFieldPathGetter
            ? dynamicFieldPathGetter(column, { originalValue: cellData, newValue: transformedConfig.value })
            : field,
        transformedConfig.value
    );
};

export const viewThroughWindowDisabledFromExcel = (value, options) => {
    const { rowData, entityData } = options;
    const isDisabledOptionText = translateOptionFromExcel(value, options);
    const vtaLookBackWindowValueKey = 'conversionConfiguration.vtaLookBackWindow.value';
    const windowValue = get(rowData, vtaLookBackWindowValueKey);

    if (isNil(windowValue) || isDisabledOptionText === 'true') {
        set(entityData, vtaLookBackWindowValueKey, 0);
    }

    return isDisabledOptionText;
};

export const translateOptionToExcel = (value, { column: { optionMessagePrefix }, formatMessage }) =>
    formatMessage({ id: `${optionMessagePrefix}.${value}` });

export const translateOptionFromExcel = (
    cellData,
    { column: { options, optionMessagePrefix }, cellHeaderName, formatMessage }
) => {
    const labelMap = keyBy(
        options.map(value => ({
            value,
            label: formatMessage({ id: `${optionMessagePrefix}.${value}` }),
        })),
        'label'
    );
    const value = labelMap[cellData]?.value;

    if (isNil(value)) {
        const error = new ExcelBulkError(
            formatMessage({ id: 'excel.bulk.option.translation.error' }, { cellHeaderName, value: cellData })
        );

        throw error;
    }

    return value;
};

export const transformABTestEndDateFromExcel = (cellData, { entityData }) => {
    const value = transformDateFromExcel(cellData);
    if (!value) {
        return;
    }
    set(entityData, 'creativeTrafficAllocationMethod', CREATIVE_TRAFFIC_METHOD.EVEN);
    return value;
};

const requiredDayPartingFields = ['type', 'day', 'fromHour', 'untilHour'];
export const transformDayPartingRulesFromExcel = (cellData, { entityData, formatMessage, cellHeaderName }) => {
    const value = transformObjectListFromExcel(cellData);

    checkObjectListForMissingFields(value, requiredDayPartingFields, formatMessage, cellHeaderName);

    if (!isEmpty(value)) {
        set(entityData, 'campaignSchedule.mode', RUNTIME_TYPES.CUSTOM);
    }

    return value;
};

export const campaignTargetingAudienceDataPathGetter = ({ field }) => {
    const [campaignTargetingKey, audienceTargetingKey] = field.split('.');

    const key = [campaignTargetingKey, audienceTargetingKey, 'collection'];

    return key;
};

export const transformAudiencesToExcel = (list = [], { column, targetingType, entityData, index = 0 }) => {
    const { dynamicFieldPathGetter } = column;
    const key = dynamicFieldPathGetter(column);
    const collection = get(entityData, key, []);

    const collectionByType = groupBy(collection, 'type');
    const values = get(collectionByType, [targetingType, index, 'values'], []);

    if (isEmpty(values)) {
        return;
    }

    const excelValue = transformListToString(values);

    return excelValue;
};

export const transformAudiencesFromExcel = (cellData, { column, entityData, targetingType, formatMessage }) => {
    const { dynamicFieldPathGetter } = column;
    const key = dynamicFieldPathGetter(column);
    const collection = get(entityData, key, []);

    const audienceIds = transformStringToNumberList(cellData);

    if (audienceIds.some(id => isNaN(id) || isNil(id))) {
        throw new ExcelBulkError(formatMessage({ id: 'excel.bulk.upload.transform.error.value.audiences' }));
    }

    collection.push({ type: targetingType, values: audienceIds });

    set(entityData, key, collection);
};

export const transformPlatformListToString = (value, { platformIdMap }) => {
    const excelValue = transformListToString(value.map(id => platformIdMap[id]));

    return excelValue;
};
export const transformPlatformStringToList = (cellData, { platformLabelMap, cellHeaderName, formatMessage }) => {
    const list = transformStringToList(cellData);

    const value = list.map(label => {
        const platform = platformLabelMap[label];
        if (!platform) {
            throw new ExcelBulkError(
                formatMessage({ id: 'excel.bulk.upload.transform.error.value' }, { cellHeaderName, value: label })
            );
        }
        return platform;
    });

    return value;
};

export const transformTargetingTypeToExcel = (value, options = {}) => {
    const { valuesFieldName, entityData } = options;
    const values = get(entityData, valuesFieldName);

    if (!values || isEmpty(values)) {
        return;
    }

    return translateOptionToExcel(value, options);
};

export const transformTargetingTypeFromExcel = (value, options) => {
    const { valuesFieldName, rowData } = options;
    const values = get(rowData, valuesFieldName);

    if (!values) {
        return;
    }

    return translateOptionFromExcel(value, options);
};

export const transformCampaignGroupNameFromExcel = (cellData, { campaignGroupsMapByName, formatMessage }) => {
    const trimmedCellData = trim(cellData);
    const campaignGroupID = campaignGroupsMapByName[trimmedCellData]?.id;

    if (!campaignGroupID) {
        throw new ExcelBulkError(
            formatMessage({ id: 'excel.bulk.upload.transform.error.value.campaign.group' }, { value: cellData })
        );
    }

    return campaignGroupID;
};

export const transformCampaignGroupIDToExcel = (value, { campaignGroupsMapByID }) => {
    const cellValue = campaignGroupsMapByID[value]?.name;

    return cellValue;
};

export const transformCTATypeFromExcel = (cellData, { ctaLabelToTypeMap, cellHeaderName, formatMessage }) => {
    const ctaType = ctaLabelToTypeMap?.[cellData];

    if (!ctaType) {
        throw new ExcelBulkError(
            formatMessage({ id: 'excel.bulk.upload.transform.error.value' }, { cellHeaderName, value: cellData })
        );
    }

    return ctaType;
};

export const transformCTATypeToExcel = (value, { ctaTypeToLabelMap }) => {
    const cellValue = ctaTypeToLabelMap?.[value];

    return cellValue;
};

export const transformTextToExcel = value => value.replace(/[\n\r]/gm, '');

export const transformBudgetTypeFromExcel = (cellData, { entityData }) => {
    const deliveryMethod = getInitialDeliveryMethod(cellData);
    set(entityData, 'budget.deliveryMethod', deliveryMethod);

    return cellData;
};

export const translateThirdPartyBrandSafetyOptionToExcel = (value, options) => {
    if (value === BRAND_SAFETY_TYPES.NONE) {
        return '';
    }

    return translateOptionToExcel(value, options);
};

export const transformThirdPartyBrandSafetyListToExcel = (
    list = [],
    { entityData, thirdPartyBrandSafetyValueToDataMapDV, thirdPartyBrandSafetyValueToDataMapIAS }
) => {
    const brandSafetyTypeToMap = {
        [BRAND_SAFETY_TYPES.DV]: thirdPartyBrandSafetyValueToDataMapDV,
        [BRAND_SAFETY_TYPES.IAS]: thirdPartyBrandSafetyValueToDataMapIAS,
    };
    const brandSafetyType = get(entityData, 'thirdPartyBrandSafetyTargeting.type');

    if (brandSafetyType === BRAND_SAFETY_TYPES.NONE) {
        return '';
    }

    const thirdPartyBrandSafetyValueToDataMap = brandSafetyTypeToMap[brandSafetyType];

    const labelledRiskLevelList = list.map(item =>
        thirdPartyBrandSafetyValueToDataMap[item.categoryName]?.hasRiskLevel
            ? getBrandSafetySegmentExcelLabel(item.categoryName, item.riskLevel)
            : item.categoryName
    );

    return transformListToString(labelledRiskLevelList);
};

export const transformThirdPartyBrandSafetyListFromExcel = (
    cellData,
    {
        thirdPartyBrandSafetyLabelToItemMapDV,
        thirdPartyBrandSafetyLabelToItemMapIAS,
        formatMessage,
        cellHeaderName,
        entityData,
    }
) => {
    const brandSafetyTypeToMap = {
        [BRAND_SAFETY_TYPES.DV]: thirdPartyBrandSafetyLabelToItemMapDV,
        [BRAND_SAFETY_TYPES.IAS]: thirdPartyBrandSafetyLabelToItemMapIAS,
    };
    const brandSafetyType = get(entityData, 'thirdPartyBrandSafetyTargeting.type');
    const brandSafetyStringsMap = brandSafetyTypeToMap[brandSafetyType];
    const brandSafetyStringsList = transformStringToList(cellData);

    return brandSafetyStringsList.map(brandSafetyString => {
        if (!brandSafetyStringsMap[brandSafetyString]) {
            throw new ExcelBulkError(
                formatMessage(
                    { id: 'excel.bulk.upload.transform.error.value' },
                    { cellHeaderName, value: brandSafetyString }
                )
            );
        }
        return brandSafetyStringsMap[brandSafetyString];
    });
};

export const transformAccountNameFromExcel = (cellData, { selectedAccount }) =>
    isNetworkAccountType(selectedAccount.type) ? cellData : selectedAccount.accountName;
