import React from 'react';
import { ExternalLink } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const MarketplaceAudiencesFieldTooltip = ({ isAndLogicEnabled }) => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.marketplace.audiences.tooltip"
            defaultMessage="Select from readily-available audience segments built from Taboola or trusted data providers' data.{newline}When selecting multiple Marketplace Audiences, by default, these audiences are connected using OR logic.{newline}We recommend including multiple audiences to increase the campaign reach."
        />
        {isAndLogicEnabled && (
            <FormattedMessage
                id="campaign.editor.marketplace.audiences.tooltip.and.logic"
                defaultMessage={'Use "+AND" to refine your audience and target the intersection of multiple audiences.'}
            />
        )}
        <ExternalLink href="https://help.taboola.com/hc/en-us/articles/115001936293-Targeting-Audience-Segments">
            <FormattedMessage id="campaign.editor.marketplace.audiences.help.learn.more" defaultMessage="Learn more" />
        </ExternalLink>
    </TooltipSection>
);

export default MarketplaceAudiencesFieldTooltip;
