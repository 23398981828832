import React from 'react';
import { ToggleSwitch } from 'tuui';
import styles from './toggleSwitchWithLabel.module.scss';

export const ToggleSwitchWithLabel = ({ checked, text, onChange, label, disabled }) => {
    return (
        <>
            <ToggleSwitch text={text} checked={checked} onChange={onChange} disabled={disabled} height={13} />
            <label className={styles['label']}>{label}</label>
        </>
    );
};
