import React from 'react';
import { INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useGtmIndicator } from 'modules/tracking/hooks/useGtmIndicator';
import { GtmIndication } from './GtmIndication';

export const GtmConnectIndicator = () => {
    const { isTaboolaPixelInstalled, isLimitedAccess, hasActiveAccount, hasActiveContainer, hasActiveWorkspace } =
        useGtmIndicator();

    return (
        <>
            {isTaboolaPixelInstalled ? (
                <GtmIndication
                    type={INDICATION_TYPES.SUCCESS}
                    labelMessageId="tracking.setup.gtm.configuration.gtm.tag.already.installed.label"
                    labelDefault="Taboola Pixel Installed"
                    descriptionMessageId="tracking.setup.gtm.configuration.gtm.tag.already.installed.description"
                    descriptionDefault="We've noticed the Taboola Pixel is already installed on this container, so there's no need to install again"
                />
            ) : isLimitedAccess ? (
                <GtmIndication
                    labelMessageId="tracking.setup.gtm.configuration.gtm.insufficient.permissions.label"
                    labelDefault="Limited Access"
                    descriptionMessageId="tracking.setup.gtm.configuration.gtm.insufficient.permissions.description"
                    descriptionDefault="We aren't able to create the tag for you without necessary permissions, please re-connect your google account"
                />
            ) : hasActiveAccount ? (
                <GtmIndication />
            ) : hasActiveContainer ? (
                <GtmIndication
                    labelMessageId="tracking.setup.gtm.configuration.gtm.no.container.label"
                    labelDefault="No Active Container"
                    descriptionMessageId="tracking.setup.gtm.configuration.gtm.no.container.description"
                    descriptionDefault="Please make sure to activate and set up your GTM container. <a>Learn more</a>"
                />
            ) : (
                hasActiveWorkspace && (
                    <GtmIndication
                        labelMessageId="tracking.setup.gtm.configuration.gtm.no.workspace.label"
                        labelDefault="No Active Workspace"
                        descriptionMessageId="tracking.setup.gtm.configuration.gtm.no.workspace.description"
                        descriptionDefault="Please make sure to activate and set up your GTM workspace. <a>Learn more</a>"
                    />
                )
            )}
        </>
    );
};
