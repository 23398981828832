import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import { modifyExternalUrl } from '../../../../services/utils';
import { CreativePreviewMultiItem } from '../CreativePreviewMultiItem/CreativePreviewMultiItem';
import styles from '../CreativePreviewMultiItem/creativePreviewMultiItem.module.scss';

const CreativePreviewMotion = ({ brandingText, language, onCancel, onEdit, editPermissionsProps }) => {
    const {
        data: { title, description, cta, thumbnailUrl, url, videoUrl, id, logo, rating },
    } = useFormDataContext();
    const modifiedThumbnailUrl = modifyExternalUrl(thumbnailUrl);
    const items = useMemo(
        () => [
            {
                cta,
                deleted: false,
                description,
                displayUrl: modifiedThumbnailUrl,
                fallbackImageUrl: modifiedThumbnailUrl,
                fileType: '',
                gifUrl: '',
                id,
                thumbnailUrl: modifiedThumbnailUrl,
                title,
                url,
                videoUrl: modifyExternalUrl(videoUrl),
                brandingText,
                language,
                logo,
                rating,
            },
        ],
        [cta, description, modifiedThumbnailUrl, id, title, url, videoUrl, brandingText, language, logo, rating]
    );

    return (
        <CreativePreviewMultiItem
            formLayoutProps={{
                className: styles['overflow'],
                headerContainerClassName: styles['preview-header'],
            }}
            items={items}
            id="creative-creator-native-preview"
            isItemRepeatEnabled={true}
            formClassName={styles['video-form']}
            onCancel={onCancel}
            onEdit={onEdit}
            editPermissionsProps={editPermissionsProps}
            multiItem={false}
        />
    );
};

CreativePreviewMotion.propTypes = {
    creative: PropTypes.object,
    onEdit: PropTypes.func,
    editPermissionsProps: permissionsShape,
};

export default CreativePreviewMotion;
