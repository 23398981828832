"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgRss(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 14,
            height: 14,
            viewBox: "0 0 14 14",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M1.75 0C0.783502 0 0 0.783502 0 1.75V12.25C0 13.2165 0.783502 14 1.75 14H12.25C13.2165 14 14 13.2165 14 12.25V1.75C14 0.783502 13.2165 0 12.25 0H1.75ZM1.75 6.125C5.6875 6.125 7.875 8.3125 7.875 12.25L6.125 12.2499C6.125 10.0625 4.8125 7.875 1.75 7.875V6.125ZM9.625 12.2499L11.375 12.25C11.375 6.125 7.875 2.625 1.75 2.625V4.375C6.5625 4.375 9.625 7.4375 9.625 12.2499ZM3.0625 12.25C3.78737 12.25 4.375 11.6624 4.375 10.9375C4.375 10.2126 3.78737 9.625 3.0625 9.625C2.33763 9.625 1.75 10.2126 1.75 10.9375C1.75 11.6624 2.33763 12.25 3.0625 12.25Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgRss;