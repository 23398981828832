import React from 'react';
import { Button, TrackChangesOutlinedIcon } from 'tuui';
import { isNetworkAccountType } from 'modules/account-management';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TRACKING_STATUS } from 'modules/tracking/config';
import { useTrackingSetupMethodTypeModal } from 'modules/tracking/hooks/useTrackingSetupMethodTypeModal';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';

export function TrackingSetupButton() {
    const {
        trackingStateData: { pixelTrackingData },
        accountType,
    } = useTrackingState();

    const isNetworkAccount = isNetworkAccountType(accountType);

    const { status: pixelStatus } = pixelTrackingData || {};

    const variant = pixelStatus === TRACKING_STATUS.NOT_INSTALLED ? Button.variant.primary : Button.variant.outline;

    const { openTrackingSetupModal } = useTrackingSetupMethodTypeModal();

    return (
        <Button
            className="tracking-setup"
            size={Button.size.md}
            variant={variant}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="TrackingSetupButton"
            onClick={openTrackingSetupModal}
        >
            <TrackChangesOutlinedIcon />
            {isNetworkAccount ? (
                <FormattedMessage
                    id="tracking.conversions.report.tracking.setup.button.basic"
                    defaultMessage="Tracking Setup"
                />
            ) : (
                <FormattedMessage
                    id="tracking.conversions.report.tracking.setup.button"
                    defaultMessage="Account Tracking Setup"
                />
            )}
        </Button>
    );
}
