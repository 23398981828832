'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DropAreaIndicator = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _DropSVGIcon = require('../DropSVGIcon');

var _DropSVGIcon2 = _interopRequireDefault(_DropSVGIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dropAreaIndicator-module__taboola-svg-icon___23nzv',
    'drop-active-container': 'dropAreaIndicator-module__drop-active-container___WKgTz',
    'text': 'dropAreaIndicator-module__text___1gP2c'
};


var classNameBuilder = _bind2.default.bind(styles);

var DropAreaIndicator = exports.DropAreaIndicator = function DropAreaIndicator(_ref) {
    var dropIcon = _ref.dropIcon,
        text = _ref.text,
        dropActiveContainerClassName = _ref.dropActiveContainerClassName;
    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('drop-active-container', dropActiveContainerClassName) },
        _react2.default.createElement(
            'div',
            null,
            dropIcon
        ),
        text && _react2.default.createElement(
            'div',
            { className: styles['text'] },
            text
        )
    );
};

DropAreaIndicator.propTypes = {
    text: _propTypes2.default.node,
    dropActiveContainerClassName: _propTypes2.default.string,
    dropIcon: _propTypes2.default.node
};

DropAreaIndicator.defaultProps = {
    dropIcon: _react2.default.createElement(_DropSVGIcon2.default, null),
    text: ''
};