import { useCallback } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { useAudiencesApi, MARKETPLACE_SEGMENTS_API_CACHE_KEY } from 'services/api';
import { FILTERS } from 'services/api/apiConstants';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { useIsMarketplaceAudiencesAndLogicEnabled } from '../../MarketplaceAudiences/hooks';
import { generatePath } from '../../Tree';

const fetchSegmentsApi = ({ selectedAccountId, queryParams, options, getMarketplaceSegmentsBundles }) => {
    const accountId = selectedAccountId || {};

    return getMarketplaceSegmentsBundles(accountId, queryParams, options);
};

const transformSegments = (segments = []) =>
    segments.map(segment => {
        const { id, taxonomy } = segment;
        return { path: generatePath(id, taxonomy), ...segment };
    });

const transformTotals = (totalsPerTaxonomy = []) =>
    totalsPerTaxonomy.reduce((obj, item) => ({ ...obj, [item.taxonomy]: item.total }), {});

export const useSegmentsWithBundlingFetcher = ({
    search,
    taxonomies,
    providers,
    selectedAccountId,
    chargeType,
    searchMode,
}) => {
    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();
    const { value: countryTargetingType = TARGETING_TYPES.ALL } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });
    const { getMarketplaceSegmentsBundles } = useAudiencesApi();
    const fetchSegments = useCallback(async () => {
        const queryParams = {
            [FILTERS.SEARCH]: search,
            taxonomies,
            providers,
            countryTargetingType,
            countryCodes,
            chargeType,
            searchMode,
        };

        try {
            const { results, metadata } = await fetchSegmentsApi({
                selectedAccountId,
                queryParams,
                getMarketplaceSegmentsBundles,
            });

            return { results: transformSegments(results), transformedTotals: transformTotals(metadata) };
        } catch (e) {
            // empty
        }
        return { results: [], transformedTotals: {} };
    }, [
        search,
        providers,
        taxonomies,
        countryTargetingType,
        countryCodes,
        chargeType,
        selectedAccountId,
        getMarketplaceSegmentsBundles,
        searchMode,
    ]);

    const fetchSegmentsByIds = useCallback(
        async ids => {
            const queryParams = {
                countryTargetingType,
                countryCodes,
                ids,
            };

            try {
                const { results } = await fetchSegmentsApi({
                    selectedAccountId,
                    queryParams,
                    options: {
                        singleRequestCacheKey: MARKETPLACE_SEGMENTS_API_CACHE_KEY,
                        allowMultiRequestCache: isAndLogicEnabled,
                    },
                    getMarketplaceSegmentsBundles,
                });

                return transformSegments(results);
            } catch (e) {
                // TODO: add error indication
            }

            return [];
        },
        [countryTargetingType, countryCodes, selectedAccountId, isAndLogicEnabled, getMarketplaceSegmentsBundles]
    );

    return { fetchSegments, fetchSegmentsByIds };
};
