import React from 'react';
import { useElementSize } from 'taboola-ultimate-ui';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { ImpressionsTitleTooltip } from './ImpressionsTitleTooltip';
import SizeEstimationTrendGraph from './SizeEstimationTrendGraph';
import styles from './sizeEstimationGraphField.module.scss';

export const SizeEstimationGraphField = props => {
    const { width, ref } = useElementSize();
    const { formatMessage } = useIntl();

    return (
        <div>
            <div className={styles['above-graph']}>
                <FormField
                    label={formatMessage({
                        id: 'custom.contextual.size.estimation.graph.right.label',
                        defaultMessage: 'Impressions',
                    })}
                    helpText={<ImpressionsTitleTooltip />}
                />
                <FormattedMessage
                    id="custom.contextual.size.estimation.graph.left.label"
                    defaultMessage="Last 30 days"
                />
            </div>
            <div className={styles['container']} role="figure" ref={ref}>
                <SizeEstimationTrendGraph width={width} {...props} />
            </div>
        </div>
    );
};

export default SizeEstimationGraphField;
