export const SET_CREATIVE_ROLLBACK_DATA = 'SET_CREATIVE_ROLLBACK_DATA';
export const SET_CREATIVE_BEING_EDITED = 'SET_CREATIVE_BEING_EDITED';
export const INIT_CREATIVE_THUMBNAIL_EDITOR_ERROR = 'INIT_CREATIVE_THUMBNAIL_EDITOR_ERROR';
export const CLEAR_CREATIVE_DRAWER_RESOURCES = 'CLEAR_CREATIVE_DRAWER_RESOURCES';

export const REQUEST_FETCH_CREATIVE = 'REQUEST_FETCH_CREATIVE';
export const FETCH_CREATIVE_SUCCESS = 'FETCH_CREATIVE_SUCCESS';
export const FETCH_CREATIVE_ERROR = 'FETCH_CREATIVE_ERROR';

export const REQUEST_UPLOAD_CREATIVE_THUMBNAIL = 'REQUEST_UPLOAD_CREATIVE_THUMBNAIL';
export const UPLOAD_CREATIVE_THUMBNAIL_SUCCESS = 'UPLOAD_CREATIVE_THUMBNAIL_SUCCESS';
export const UPLOAD_CREATIVE_THUMBNAIL_ERROR = 'UPLOAD_CREATIVE_THUMBNAIL_ERROR';

export const REQUEST_FETCH_RECOMMENDED_MEDIA = 'REQUEST_FETCH_RECOMMENDED_MEDIA';
export const FETCH_RECOMMENDED_MEDIA_SUCCESS = 'FETCH_RECOMMENDED_MEDIA_SUCCESS';
export const FETCH_RECOMMENDED_MEDIA_ERROR = 'FETCH_RECOMMENDED_MEDIA_ERROR';
export const RESET_RECOMMENDED_MEDIA = 'RESET_RECOMMENDED_MEDIA';

export const REQUEST_FETCH_MEDIA_LIBRARY_ITEMS = 'REQUEST_FETCH_MEDIA_LIBRARY_ITEMS';
export const FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS = 'FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS';
export const FETCH_MEDIA_LIBRARY_ITEMS_ERROR = 'FETCH_MEDIA_LIBRARY_ITEMS_ERROR';
export const RESET_MEDIA_LIBRARY_ITEMS = 'RESET_MEDIA_LIBRARY_ITEMS';

export const MERGE_AI_TAB_IMAGE_PROMPT_MAP = 'MERGE_AI_TAB_IMAGE_PROMPT_MAP';
export const SET_AI_TAB_REFERENCE_IMAGE = 'SET_AI_TAB_REFERENCE_IMAGE';
export const SET_AI_TAB_TO_INITIAL_STATE = 'SET_AI_TAB_TO_INITIAL_STATE';
