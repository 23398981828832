import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { InfoOutlinedIcon } from 'tuui';
import { creativeTitleValidations } from 'modules/campaigns/config/validations/creative';
import { useConfigMatch, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, FormattedNumber, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { UIAssistant } from '../../UIAssistant/UIAssistant';
import { CHECKBOX_IDS } from '../config';
import ActionButtons from './ContentItemActionButtons';
import Checkboxes from './ContentItemCheckboxes';
import { ContentTitleItem, ContentDescriptionItem, CtaField } from './fields';
import styles from './contentItem.module.scss';

const classNameBuilder = classnames.bind(styles);
const backgroundUIAssistant = {
    PRIMARY: 'primary',
};

const noValidations = [];

export const ContentItem = ({
    index,
    shouldRenderDescription,
    shouldRenderCta,
    autoFocus,
    duplicateItem,
    deleteItem,
    numExistingItems,
    maxItems,
    onTitlePaste,
}) => {
    const [isDescriptionInFocus, setIsDescriptionInFocus] = useState(false);
    const { formatMessage } = useIntl();
    const { value: contentId } = useFormFieldValue({ field: 'id' });
    const { value: status, onChange: onChangeStatus } = useFormFieldValue({ field: 'status' });
    const { value: descriptionEnabled, onChange: onChangeDescriptionEnabled } = useFormFieldValue({
        field: 'descriptionEnabled',
    });
    const { value: ctaEnabled, onChange: onChangeCtaEnabled } = useFormFieldValue({ field: 'ctaEnabled' });
    let automationId;

    const disabled = status === COMPONENT_STATUS.INITIAL;

    const isPlaceholder = status === COMPONENT_STATUS.EMPTY || status === COMPONENT_STATUS.INITIAL;
    const isInteractable = status === COMPONENT_STATUS.ACTIVE || status === COMPONENT_STATUS.ERROR;
    const isFirstItem = index === 0;

    const ignoreValidation = (isPlaceholder && (disabled || numExistingItems > 0)) || !isInteractable;

    const canDuplicate =
        !isPlaceholder && numExistingItems < maxItems && isInteractable && status !== COMPONENT_STATUS.ERROR;
    const canDelete = (!isPlaceholder && numExistingItems > 0 && isInteractable) || status === COMPONENT_STATUS.ERROR;

    const isCtaRecommendedEnabled = useConfigMatch({ [FEATURE_FLAGS.DEFAULT_CTA_ENABLED]: 'true' });
    const shouldRenderCheckboxes = isCtaRecommendedEnabled ? false : shouldRenderDescription || shouldRenderCta;
    const shouldRenderCtaField = isCtaRecommendedEnabled ? shouldRenderCta : ctaEnabled && shouldRenderCta;
    const shouldRenderDescriptionField = isCtaRecommendedEnabled
        ? shouldRenderDescription
        : descriptionEnabled && shouldRenderDescription;

    const generateCtaCheckbox = useMemo(() => {
        return {
            id: CHECKBOX_IDS.CTA,
            value: ctaEnabled,
            defaultMessage: 'CTA Button',
            onChange: onChangeCtaEnabled,
            isPermitted: shouldRenderCta,
        };
    }, [ctaEnabled, onChangeCtaEnabled, shouldRenderCta]);

    const checkboxesConfig = useMemo(
        () => [
            {
                id: CHECKBOX_IDS.DESCRIPTION,
                value: descriptionEnabled,
                defaultMessage: 'Description',
                onChange: onChangeDescriptionEnabled,
                isPermitted: shouldRenderDescription,
            },
            generateCtaCheckbox,
        ],
        [descriptionEnabled, onChangeDescriptionEnabled, shouldRenderDescription, generateCtaCheckbox]
    );

    if (isPlaceholder && !disabled) {
        automationId = 'placeholder';
    }

    const onTitleUpdate = useCallback(
        value => {
            if (value && status !== COMPONENT_STATUS.ACTIVE) {
                onChangeStatus(COMPONENT_STATUS.ACTIVE);
            }
        },
        [status, onChangeStatus]
    );

    return (
        <div
            className={classNameBuilder('container', { disabled })}
            data-automation={automationId}
            data-testid={`content-item-container-${index}`}
        >
            <div className={styles['headline']}>
                <FormattedMessage
                    id="creative.creator.content.headline.label"
                    defaultMessage="Headline {count}"
                    values={{ count: index + 1 }}
                />
            </div>
            <ActionButtons
                canDuplicate={canDuplicate}
                canDelete={canDelete}
                id={contentId}
                duplicateItem={duplicateItem}
                deleteItem={deleteItem}
            />
            <ContentTitleItem
                id={contentId}
                label={<FormattedMessage id="creative.creator.content.title.label" defaultMessage="Title" />}
                placeholder={formatMessage({
                    id: 'creative.creator.content.title.placeholder',
                    defaultMessage: 'Type to add title...',
                })}
                index={index}
                autoFocus={autoFocus}
                disabled={disabled}
                onChangeCallback={onTitleUpdate}
                isLoading={status === COMPONENT_STATUS.LOADING}
                isLoadingError={status === COMPONENT_STATUS.ERROR}
                validations={ignoreValidation ? noValidations : creativeTitleValidations}
                onPaste={onTitlePaste}
            />
            {shouldRenderCheckboxes && (
                <Checkboxes
                    config={checkboxesConfig}
                    shouldRenderDescription={shouldRenderDescription}
                    shouldRenderCta={shouldRenderCta}
                />
            )}
            {shouldRenderDescriptionField && (
                <div className={styles['description']}>
                    <ContentDescriptionItem
                        id={contentId}
                        label={
                            <FormattedMessage
                                id="creative.creator.content.description.label"
                                defaultMessage="Description"
                            />
                        }
                        placeholder={formatMessage({
                            id: 'creative.creator.content.description.placeholder',
                            defaultMessage: 'Type to add description...',
                        })}
                        index={index}
                        showHelpTooltip
                        onFocus={() => setIsDescriptionInFocus(true)}
                        onBlur={() => setIsDescriptionInFocus(false)}
                    />
                    {isCtaRecommendedEnabled && isFirstItem && (
                        <UIAssistant
                            label={
                                <FormattedMessage
                                    id="creative.creator.content.description.ui.assistant"
                                    defaultMessage="Description has improved performance up to {param} for similar advertisers"
                                    values={{
                                        param: (
                                            <div className={classNameBuilder('ui-assistant-param')}>
                                                <FormattedNumber value={8 / 100} variant="percent" />
                                            </div>
                                        ),
                                    }}
                                />
                            }
                            isCollapsed={!isDescriptionInFocus}
                            headerIcon={<InfoOutlinedIcon />}
                            headerIconClassName={classNameBuilder('header-icon')}
                            backgroundUIAssistant={backgroundUIAssistant.PRIMARY}
                        />
                    )}
                </div>
            )}
            {shouldRenderCtaField && (
                <div>
                    <CtaField className={styles['cta-container']} />
                </div>
            )}
        </div>
    );
};

ContentItem.propTypes = {
    index: PropTypes.number,
    duplicateItem: PropTypes.func,
    deleteItem: PropTypes.func,
    onTitlePaste: PropTypes.func,

    autoFocus: PropTypes.bool,
    shouldRenderDescription: PropTypes.bool,
    shouldRenderCta: PropTypes.bool,

    numExistingItems: PropTypes.number,
    maxItems: PropTypes.number,
};

ContentItem.defaultProps = {
    item: {},
    autoFocus: false,
    shouldRenderCta: false,
    shouldRenderDescription: false,
};
