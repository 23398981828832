import { some, isNil, keys } from 'lodash';
import { config as logRocketConfig } from 'modules/taboola-common-frontend-modules/log-rocket';
import { authTokenManager } from '../../modules/taboola-common-frontend-modules/authentication/services/AuthTokenManager';
import { gwSignatureManager } from '../../modules/taboola-common-frontend-modules/authentication/services/GWSignatureManager';
import { callAjax } from '../callAjax/callAjax';
import abortControllerFactory from './abortControllerFactory';
import { PAGINATION, FILTERS, VALIDATIONS, PAGE_METADATA, SORTING } from './apiConstants';

const DEFAULT_HEADERS = { 'content-type': 'application/json' };
const getHeaders = headers => ({
    ...headers,
    ...authTokenManager.getHeaders(),
    ...logRocketConfig.getHeaders(),
    ...gwSignatureManager.getHeaders(),
});

const getOptionsWithBody = (data, options = {}) => {
    const headers = getHeaders(options.headers || DEFAULT_HEADERS);

    return {
        body: JSON.stringify(data),
        ...options,
        headers,
    };
};

// '/' after api comes from the url param
const addApiPrefix = url => `/api${url}`;

const getPatchOperationWithData = (data, patchOperation) => ({ patchOperation, ...data });

// TODO: DEV-42237 Ads Console - Frontend - apiCaller - improve query params addition logic
const addQueryParams = (url, { paginationEnabled, page, pageSize, id, sort, ...rest }) => {
    const restParamsMap = {
        name: FILTERS.NAME,
        searchText: FILTERS.SEARCH,
        keywords: FILTERS.KEYWORDS,
        thumbnailUrl: FILTERS.THUMBNAIL_URL,
        dimension: FILTERS.DIMENSION,
        urls: VALIDATIONS.URLS,
        urlPageMetadata: PAGE_METADATA.URL,
        status: FILTERS.STATUS,
        statuses: FILTERS.STATUSES,
        query: FILTERS.QUERY,
        contentType: FILTERS.CONTENT_TYPE,
        affiliatesId: FILTERS.AFFILIATES_ID,
        recommendationTypes: FILTERS.RECOMMENDATION_TYPES,
        productDescription: FILTERS.PRODUCT_DESCRIPTION,
        targetAudience: FILTERS.TARGET_AUDIENCE,
        uniqueSellingProposition: FILTERS.UNIQUE_SELLING_PROPOSITION,
        landingPageTitle: FILTERS.LANDING_PAGE_TITLE,
        landingPageDescription: FILTERS.LANDING_PAGE_DESCRIPTION,
        landingPageContent: FILTERS.LANDING_PAGE_CONTENT,
        language: FILTERS.LANGUAGE,
        originalText: FILTERS.ORIGINAL_TEXT,
        imageUrl: FILTERS.IMAGE_URL,
        textPrompt: FILTERS.TEXT_PROMPT,
        supplyGroup: FILTERS.SUPPLY_GROUP,
        isImageVariations: FILTERS.IS_IMAGE_VARIATIONS,
        campaignId: FILTERS.CAMPAIGN_ID,
        campaignIds: FILTERS.CAMPAIGN_IDS,
        ruleIds: FILTERS.RULE_IDS,
    };
    const requestUrl = new URL(url, window.location);
    const hasParamInUse = some([id, paginationEnabled, sort, ...keys(restParamsMap)], param => !isNil(param));

    if (!hasParamInUse) {
        return requestUrl.toString();
    }

    keys(restParamsMap).forEach(key => {
        if (rest[key]) {
            requestUrl.searchParams.append(restParamsMap[key], rest[key]);
        }
    });

    if (!isNil(id)) {
        requestUrl.searchParams.append(FILTERS.ID, id);
    }
    if (paginationEnabled && page && pageSize) {
        requestUrl.searchParams.append(PAGINATION.PAGE, page);
        requestUrl.searchParams.append(PAGINATION.PAGE_SIZE, pageSize);
    }
    if (sort) {
        requestUrl.searchParams.append(SORTING.SORT, JSON.stringify(sort));
    }
    return requestUrl.toString();
};

const callGetApi = (url, options, skipJsonParse = false) => {
    const urlWithApiPrefix = addApiPrefix(url);
    const requestUrl = options ? addQueryParams(urlWithApiPrefix, options) : urlWithApiPrefix;
    const headers = getHeaders(options?.headers);
    const signal = options?.signal;

    if (options && options.singleRequestCacheKey) {
        const signal = abortControllerFactory.createCancelableController(
            options.singleRequestCacheKey,
            options.allowMultiRequestCache
        ).signal;
        return callAjax(requestUrl, { headers, signal }, skipJsonParse);
    }
    return callAjax(requestUrl, { headers, signal }, skipJsonParse);
};

const callPostApi = (url, data, options, skipJsonParse = false) =>
    callAjax(addApiPrefix(url), getOptionsWithBody(data, { method: 'POST', ...options }), skipJsonParse);

const callPutApi = (url, data, skipJsonParse = false) =>
    callAjax(addApiPrefix(url), getOptionsWithBody(data, { method: 'PUT' }), skipJsonParse);

const callPatchApi = (url, data, patchOperation) =>
    callAjax(
        addApiPrefix(url),
        getOptionsWithBody(getPatchOperationWithData(data, patchOperation), { method: 'PATCH' })
    );

const callDeleteApi = (url, data) => callAjax(addApiPrefix(url), getOptionsWithBody(data, { method: 'DELETE' }));

export { callGetApi, callPostApi, callPutApi, callPatchApi, callDeleteApi };
