import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import classnames from 'classnames/bind';
import { get, map, noop } from 'lodash';
import uuid from 'uuid/v1';
import { ImageIcon, INDICATION_TYPES } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useCurrentValueGetter } from 'hooks';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FORM_MODES } from 'modules/campaigns/config';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import {
    IMAGES,
    MAX_THUMBNAILS_COUNT,
    MIN_THUMBNAILS_COUNT,
    MOTIONS,
} from 'modules/campaigns/modules/creative-creator/config';
import styles from 'modules/campaigns/modules/creative-creator/creativeCreator.module.scss';
import { getUpdatedPreviews } from 'modules/campaigns/modules/creative-creator/reducer';
import { isFileTypeImage } from 'modules/campaigns/modules/creative-creator/services/utils';
import { updateThumbnailById } from 'modules/campaigns/modules/creative-creator/utils/updateThumbnailById';
import { useCropImageModal } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/CroppingToolsForm/hooks/useCropImageModal';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { defaultCropData } from '../../../../creative-editor/components/MediaTabs/CroppingToolsForm/config/defaultCropData';
import { useFormatTypeFormFieldValueCreateMode } from '../../../hooks/useFormatTypeFormFieldValueCreateMode';
import { LogoField } from '../../LogoField/LogoField';
import ThumbnailsField from '../../ThumbnailsField/ThumbnailsField';

const missedAccountImageMessage = (
    <FormattedMessage
        id="creative.editor.recommended.empty.account.image.error"
        defaultMessage="Please select an account before adding new images."
    />
);

const missedAccountIndication = {
    type: INDICATION_TYPES.WARNING,
    highlight: <FormattedMessage id="note.highlight" defaultMessage="Info!" />,
    message: missedAccountImageMessage,
};

const errorIndicationTemplate = {
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const logoExcludedFormatTypes = [CREATIVE_FORMAT_TYPE.CAROUSEL, CREATIVE_FORMAT_TYPE.APP_INSTALL];

const useFallbackImage = (setThumbnails, motionWithoutFallback) => {
    const [motionIdFallbackSelecting, setMotionIdFallbackSelecting] = useState();
    const startSelectFallbackImage = useCallback(id => setMotionIdFallbackSelecting(id), []);
    const cancelSelectFallbackImage = useCallback(() => setMotionIdFallbackSelecting(), []);
    const motionIdFallbackSelectingGetter = useCurrentValueGetter(
        motionWithoutFallback?.id || motionIdFallbackSelecting
    );
    const isFallbackImageSelecting = useCallback(
        () => motionIdFallbackSelectingGetter(),
        [motionIdFallbackSelectingGetter]
    );

    const onSelectFallbackImage = useCallback(
        async (urlResolver, metadata) => {
            const motionIdFallbackSelecting = motionIdFallbackSelectingGetter();
            const id = motionIdFallbackSelecting;

            setThumbnails(prev =>
                updateThumbnailById(prev, motionIdFallbackSelecting, {
                    fallbackImageStatus: COMPONENT_STATUS.LOADING,
                })
            );
            // If user selected file via double click we should reset motion id
            if (!metadata.dropped) {
                cancelSelectFallbackImage();
            }

            try {
                const { url } = await urlResolver();
                setThumbnails(prev =>
                    updateThumbnailById(prev, id, {
                        fallbackImageUrl: url,
                        fallbackImageStatus: COMPONENT_STATUS.ACTIVE,
                    })
                );
            } catch (error) {
                setThumbnails(prev =>
                    updateThumbnailById(prev, id, { fallbackImageStatus: COMPONENT_STATUS.ERROR, error })
                );
            }
        },
        [cancelSelectFallbackImage, setThumbnails, motionIdFallbackSelectingGetter]
    );

    return {
        startSelectFallbackImage,
        cancelSelectFallbackImage,
        isFallbackImageSelecting,
        onSelectFallbackImage,
        motionIdFallbackSelectingGetter,
    };
};

const classNameBuilder = classnames.bind(styles);

export const VariationsMediaSection = ({ className }) => {
    const { value: thumbnailList, onChange: setThumbnails } = useFormFieldValue({ field: 'thumbnails' });
    const { onChange: setCreativePreviews } = useFormFieldValue({ field: 'creativePreviews' });
    const { value: creativeCreatorSelectedCampaigns } = useCampaignsFormFieldValue();
    const campaignId = get(creativeCreatorSelectedCampaigns, [0, 'id'], undefined);
    const { value: content } = useFormFieldValue({ field: 'content' });
    const dispatch = useDispatch();
    const isCroppingImageEnabled = useConfigMatch({ [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true' });
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();
    const isAppInstallFormatSelected = formatType === CREATIVE_FORMAT_TYPE.APP_INSTALL;
    const isLogoFieldEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' });
    const isLogoExcludedFormatType = logoExcludedFormatTypes.includes(formatType);
    const isLogoFieldVisible = isLogoFieldEnabled && !isLogoExcludedFormatType;
    const isMounted = useMountedState();

    const motionWithoutFallback = useMemo(
        () =>
            thumbnailList.find(({ mediaCategory, fallbackImageUrl }) => mediaCategory === MOTIONS && !fallbackImageUrl),
        [thumbnailList]
    );

    const { formAccount, mode } = useFormDataContext();

    const isMissedAccount = useCallback(() => !formAccount?.accountId, [formAccount?.accountId]);

    const titleList = useMemo(
        () =>
            map(
                content.filter(({ status }) => status === COMPONENT_STATUS.ACTIVE),
                'title'
            ),
        [content]
    );

    const showMissedAccountIndication = useCallback(() => {
        dispatch(addIndication(missedAccountIndication));
    }, [dispatch]);

    const removeThumbnail = useCallback(
        ({ id }) => setThumbnails(prevList => prevList.filter(el => el.id !== id)),
        [setThumbnails]
    );

    const setCreativeFocus = useCallback(
        ({ thumbnailUrl, creativeFocus }) => {
            setThumbnails(prev => {
                const id = prev.find(el => el.displayUrl === thumbnailUrl).id;
                return updateThumbnailById(prev, id, { creativeFocus });
            });
            setCreativePreviews(prevPreviews => {
                const newPreviews = getUpdatedPreviews(prevPreviews, thumbnailUrl, { creativeFocus });
                return newPreviews;
            });
        },
        [setCreativePreviews, setThumbnails]
    );

    const showIndication = useCallback(
        (id, defaultMessage, maxItems) => {
            dispatch(
                addIndication({
                    ...errorIndicationTemplate,
                    message: <FormattedMessage id={id} defaultMessage={defaultMessage} values={{ maxItems }} />,
                })
            );
        },
        [dispatch]
    );

    const showThumbnailsSelectError = useCallback(
        maxItems => {
            showIndication(
                'creative.creator.thumbnails.limit.picker.prevent.error',
                'You can add up to {maxItems} media files',
                maxItems
            );
        },
        [showIndication]
    );

    const {
        startSelectFallbackImage,
        cancelSelectFallbackImage,
        isFallbackImageSelecting,
        onSelectFallbackImage,
        motionIdFallbackSelectingGetter,
    } = useFallbackImage(setThumbnails, motionWithoutFallback);

    const onSelectMediaBase = useCallback(
        async (urlResolver, metadata, onSuccess = noop) => {
            if (isMissedAccount()) {
                showMissedAccountIndication();
                return;
            }
            if (metadata?.selected) {
                setThumbnails(prev => prev.filter(el => el.id !== metadata.id));
                return;
            }
            if (isFallbackImageSelecting()) {
                onSelectFallbackImage(urlResolver, metadata);
                return;
            }

            const { id = uuid(), fileType } = metadata;

            const isImage = isFileTypeImage(fileType);
            const mediaCategory = isFileTypeImage(fileType) ? IMAGES : MOTIONS;

            setThumbnails(prev => {
                if (prev.length >= MAX_THUMBNAILS_COUNT) {
                    showThumbnailsSelectError(MAX_THUMBNAILS_COUNT);
                    return prev;
                }
                return [...prev, { ...metadata, id, status: COMPONENT_STATUS.LOADING, mediaCategory }];
            });

            try {
                const { url, ...dependentUrls } = await urlResolver();
                if (!isMounted()) {
                    return;
                }

                setThumbnails(prev =>
                    updateThumbnailById(prev, id, {
                        value: url,
                        status: COMPONENT_STATUS.ACTIVE,
                        displayUrl: modifyExternalUrl(url),
                        cropData: defaultCropData,
                        ...dependentUrls,
                    })
                );

                if (mode !== FORM_MODES.CREATE || !isCroppingImageEnabled || !isImage || isAppInstallFormatSelected) {
                    return;
                }

                onSuccess({ src: url, metadata });
            } catch (error) {
                if (!isMounted()) {
                    return;
                }

                setThumbnails(prev => updateThumbnailById(prev, id, { status: COMPONENT_STATUS.ERROR, error }));
            }
        },
        [
            isMissedAccount,
            isFallbackImageSelecting,
            setThumbnails,
            showMissedAccountIndication,
            onSelectFallbackImage,
            showThumbnailsSelectError,
            mode,
            isCroppingImageEnabled,
            isAppInstallFormatSelected,
            isMounted,
        ]
    );

    const { openModal: openCropModal } = useCropImageModal({ campaignId, onSelectMedia: onSelectMediaBase, titleList });

    const onSelectMedia = useCallback(
        (urlResolver, metadata) => {
            const onSuccess = ({ src: url, metadata }) => {
                openCropModal({ src: url, metadata });
            };

            onSelectMediaBase(urlResolver, metadata, onSuccess);
        },
        [onSelectMediaBase, openCropModal]
    );

    return (
        <div>
            <CommonCollapsibleCard
                id="CREATIVE_THUMBNAIL"
                header={
                    <SectionHeader
                        headerIcon={<ImageIcon />}
                        headerText={<FormattedMessage id="creative.creator.media.label" defaultMessage="Media" />}
                    />
                }
                containerClassName={classNameBuilder('collapsible-card', className)}
            >
                <ThumbnailsField
                    thumbnailList={thumbnailList}
                    deleteItem={removeThumbnail}
                    minItems={formatType === CREATIVE_FORMAT_TYPE.CAROUSEL ? MIN_THUMBNAILS_COUNT : null}
                    maxItems={MAX_THUMBNAILS_COUNT}
                    setCreativeFocus={setCreativeFocus}
                    onSelectMedia={onSelectMedia}
                    motionIdFallbackSelecting={motionIdFallbackSelectingGetter()}
                    startSelectFallbackImage={startSelectFallbackImage}
                    cancelSelectFallbackImage={() => cancelSelectFallbackImage()}
                    campaignId={campaignId}
                    titleList={titleList}
                    openCropModal={openCropModal}
                />
                {isLogoFieldVisible && <LogoField />}
            </CommonCollapsibleCard>
        </div>
    );
};
