import React from 'react';
import { isEmpty, noop } from 'lodash';
import { AllCampaignsObject, REPORT_TYPE, resolveReportIdByCampaignDimension } from 'modules/campaigns/config';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { dimensionSelector } from 'selectors';
import { creativesApi } from 'services/api';
import { generateErrorIndication } from 'services/utils/errorMessagesUtils';
import { GTM_EVENTS, gtmTracker } from '../../../../taboola-common-frontend-modules/gtmTracker';
import { sendGTMEventsForAIImageGeneration } from '../../../services/utils';

const successIndication = {
    message: <FormattedMessage id="creative.creator.save.success" defaultMessage="Ads successfully saved" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const errorMessage = (
    <FormattedMessage id="creative.creator.save.error" defaultMessage="We were not able to save the new ads" />
);

const sendGTMEventDefaultCta = (eventName, creativeItems, ctaData) => {
    try {
        const { defaultCta, marketingObjective, iabCategory } = ctaData;
        creativeItems.forEach(creativeItem => {
            const { cta, id } = creativeItem;
            const { ctaType } = cta || {};

            gtmTracker.trackEvent('cta data on submit', {
                selectedCta: ctaType,
                defaultCta,
                marketingObjective,
                iabCategory,
                instructionId: id,
            });
        });
    } catch (e) {}
};
const sendGTMEventForCtaCheckbox = (eventName, encodedActivePreviews) => {
    try {
        encodedActivePreviews.forEach(creativePreview => {
            const { cta } = creativePreview;
            const { ctaType } = cta || {};
            const value = `selectedCta:${ctaType}`;

            gtmTracker.trackEvent(eventName, {
                component: 'cta from checkbox on submit',
                value,
            });
        });
    } catch (e) {}
};

const extractDataToSendGAEvents = (ctaData, creativesResults, encodedActivePreviews, aiImagesPromptData) => {
    const creativeItems = creativesResults.map(({ sponsoredItems, scVideoItems }) => {
        const fetchResults = [];
        if (sponsoredItems) {
            fetchResults.push(sponsoredItems);
        }
        if (scVideoItems) {
            fetchResults.push(scVideoItems);
        }
        return fetchResults;
    });
    const creativeItemsArray = creativeItems.flat(Infinity);
    if (creativeItems?.length > 0) {
        if (!isEmpty(ctaData)) {
            sendGTMEventDefaultCta(GTM_EVENTS.USABILITY, creativeItemsArray, ctaData);
        } else {
            sendGTMEventForCtaCheckbox(GTM_EVENTS.USABILITY, encodedActivePreviews);
        }
        sendGTMEventsForAIImageGeneration({ creativeItems: creativeItemsArray, aiImagesPromptData });
    }
};

const bulkCreateCreativesBase =
    (
        {
            accountId,
            selectedCampaigns,
            creatives = [],
            videoCreatives = [],
            ctaData,
            encodedActivePreviews,
            aiImagesPromptData = {},
            recommendationId,
            onSuccess = noop,
        },
        options = {}
    ) =>
    async (dispatch, getState) => {
        const state = getState();
        const dimension = dimensionSelector(state);
        const { indicationsOverride, isDuplicated } = options;

        const selectedCampaignIds = selectedCampaigns.map(({ id }) => id);

        try {
            let creativesResults;
            if (recommendationId) {
                creativesResults = [
                    await creativesApi.createCombinedCreativesFromRecommendation({
                        accountId,
                        isDuplicated,
                        recommendationId,
                        body: {
                            campaignIds: selectedCampaignIds,
                            staticImagePojo: creatives,
                            motionAdPojo: videoCreatives,
                        },
                    }),
                ];
            } else {
                creativesResults = [
                    await creativesApi.createCombinedCreatives(accountId, isDuplicated, {
                        campaignIds: selectedCampaignIds,
                        staticImagePojo: creatives,
                        motionAdPojo: videoCreatives,
                    }),
                ];
            }
            await extractDataToSendGAEvents(ctaData, creativesResults, encodedActivePreviews, aiImagesPromptData);
            onSuccess(creativesResults);

            const finalSelectedCampaign = selectedCampaigns.length > 1 ? AllCampaignsObject : selectedCampaigns[0];

            dispatch(addIndication(indicationsOverride?.success || successIndication));

            return {
                campaignId: finalSelectedCampaign.id,
                reportId: resolveReportIdByCampaignDimension(REPORT_TYPE.CREATIVE, dimension),
                creativesResults,
            };
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error, errorMessage)));
            throw error;
        }
    };

export default bulkCreateCreativesBase;
