import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH, CAMPAIGNS_GROUP_ROUTE } from 'modules/campaigns/config';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CampaignsGroupCreator } from '../modules/campaigns-group-form/forms/campaigns-group-creator';
import { replacePathParams } from '../services/utils';

const path = CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH;

export const CampaignsGroupCreatorPage = ({ onCancel }) => {
    const campaignsGroupPath = replacePathParams(path);

    useUnsavedChangesController(CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                strict
                path={campaignsGroupPath}
                render={props => <CampaignsGroupCreator {...props} onCancel={onCancel} />}
            />
            <Redirect to={CAMPAIGNS_GROUP_ROUTE} />
        </Switch>
    );
};

CampaignsGroupCreatorPage.propTypes = {
    onCancel: PropTypes.func,
};

export default CampaignsGroupCreatorPage;
