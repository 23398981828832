import { isAdminNetworkAccountType, isNetworkAccountType } from '../../../account-management';
import { FEATURE_FLAGS } from '../../../taboola-common-frontend-modules/account-configurations';

export const isEditDisabled = ({ data, context }) => {
    const { accountName: reportAccount } = data;
    const { selectedAccountConfig, userPermissions, accountType, accountName: selectedAccountName } = context;
    const isAccountNetwork = isNetworkAccountType(accountType);
    const isFlagEnabledOnNetworkLevel =
        selectedAccountConfig[FEATURE_FLAGS.SCHEDULED_REPORTS_EDIT_VIEW_ON_NETWORK] === 'true';
    const isAccountOwnerOfReport = selectedAccountName === reportAccount;
    const isAccountAllowedToEdit = !isAccountNetwork || isFlagEnabledOnNetworkLevel;
    const isEnabled =
        userPermissions['ADS_CONSOLE_SCHEDULED_REPORTS_EDIT'] ||
        selectedAccountConfig[FEATURE_FLAGS.SCHEDULED_REPORTS_EDIT] === 'true';
    const isDisabled =
        !isEnabled ||
        isAdminNetworkAccountType(accountType) ||
        (isNetworkAccountType(accountType) && !isAccountOwnerOfReport) ||
        !isAccountAllowedToEdit;

    return isDisabled;
};
