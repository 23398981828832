import React from 'react';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'tuui';
import { FormField, Input } from 'taboola-ultimate-ui';
import { LoadingBar } from 'modules/campaigns/components';
import { AI_VARIATIONS_FIELD_NAMES } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import ErrorMsg from 'modules/errors/components/ErrorMsg/ErrorMsg';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import ContentTitleAISuggestionsBaseCard from './ContentTitleAISuggestionsBaseCard';
import { useViewedSuggestionsGA } from './hooks/useViewedSuggestionsGA';
import styles from './contentTitleAISuggestionsForm.module.scss';

const ContentTitleAISuggestionsForm = ({ campaignId, status, onGenerateClick, errorMessage, ...rest }) => {
    const { value: productDescription = '', onChange: onProductDescriptionChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_DESCRIPTION,
        isAbsolute: true,
    });
    const { value: targetAudience = '', onChange: onTargetAudienceChange } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_TARGET_AUDIENCE,
        isAbsolute: true,
    });
    const { value: uniqueSellingProposition = '', onChange: onUniqueSellingProposition } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_USP,
        isAbsolute: true,
    });

    const { collapsed } = rest;
    const { formatMessage } = useIntl();
    const loadingMessage = formatMessage({
        id: 'creative.creator.aiTitleSuggestions.loadingMessage',
        defaultMessage: "We're working on generating your titles and descriptions. It will only take a few seconds.",
    });
    const isGenerateDisabled =
        isEmpty(productDescription) ||
        isEmpty(targetAudience) ||
        isEmpty(uniqueSellingProposition) ||
        status === COMPONENT_STATUS.LOADING;

    useViewedSuggestionsGA({ campaignId, collapsed });

    return (
        <ContentTitleAISuggestionsBaseCard
            header={
                <FormattedMessage
                    id="creative.creator.aiTitleSuggestions.header.search"
                    defaultMessage="Looking for AI Suggestions?"
                />
            }
            description={
                <FormattedMessage
                    id="creative.creator.aiTitleSuggestions.description.search"
                    defaultMessage="Please share some details with us"
                />
            }
            {...rest}
        >
            <div className={styles['search-container']}>
                <FormField
                    inputId="aiSuggestionsProductDescription"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.productDescription.label',
                        defaultMessage: 'What services do you offer?',
                    })}
                    labelClass={styles['label']}
                    containerClass={styles['form-field']}
                >
                    <Input
                        id="aiSuggestionsProductDescription"
                        value={productDescription}
                        onChange={e => onProductDescriptionChange(e.target.value)}
                        placeholder={formatMessage({
                            id: 'creative.creator.aiTitleSuggestions.productDescription.placeholder',
                            defaultMessage: 'e.g. Socks',
                        })}
                        inputWrapperClass={styles['input']}
                        inputClass={styles['input']}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIContentTitleSuggestions - Product Description Field Focused"
                        data-metrics-taboola-campaign-id={campaignId}
                    />
                </FormField>
                <FormField
                    inputId="aiSuggestionsTargetAudience"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.targetAudience.label',
                        defaultMessage: 'What is your target audience?',
                    })}
                    labelClass={styles['label']}
                    containerClass={styles['form-field']}
                >
                    <Input
                        id="aiSuggestionsTargetAudience"
                        value={targetAudience}
                        onChange={e => onTargetAudienceChange(e.target.value)}
                        placeholder={formatMessage({
                            id: 'creative.creator.aiTitleSuggestions.targetAudience.placeholder',
                            defaultMessage: 'e.g. 20 -30 year old who love fashion and comfort',
                        })}
                        inputWrapperClass={styles['input']}
                        inputClass={styles['input']}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIContentTitleSuggestions - Target Audience Field Focused"
                        data-metrics-taboola-campaign-id={campaignId}
                    />
                </FormField>
                <FormField
                    inputId="aiSuggestionsUniqueSellingProposition"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.uniqueSellingProposition.label',
                        defaultMessage: 'What is your unique selling proposition (USP)?',
                    })}
                    labelClass={styles['label']}
                    containerClass={styles['form-field']}
                >
                    <Input
                        id="aiSuggestionsUniqueSellingProposition"
                        value={uniqueSellingProposition}
                        onChange={e => onUniqueSellingProposition(e.target.value)}
                        placeholder={formatMessage({
                            id: 'creative.creator.aiTitleSuggestions.uniqueSellingProposition.placeholder',
                            defaultMessage: 'e.g. comfortable and fashionable high quality socks',
                        })}
                        inputWrapperClass={styles['input']}
                        inputClass={styles['input']}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIContentTitleSuggestions - Unique Selling Proposition Field Focused"
                        data-metrics-taboola-campaign-id={campaignId}
                    />
                </FormField>
                {errorMessage && <ErrorMsg errorMessage={errorMessage} errorMessageClass={styles['error']} />}
                <Button
                    onClick={onGenerateClick}
                    disabled={isGenerateDisabled}
                    variant={Button.variant.text}
                    className={styles['button']}
                >
                    {status === COMPONENT_STATUS.LOADING ? (
                        <Spinner size={14} className={styles['spinner']} color="primary" />
                    ) : (
                        <FormattedMessage
                            id="creative.creator.aiTitleSuggestions.generate.button"
                            defaultMessage="Generate"
                        />
                    )}
                </Button>
                {status === COMPONENT_STATUS.LOADING && (
                    <LoadingBar durationInSeconds={40} loadingMessage={loadingMessage} />
                )}
            </div>
        </ContentTitleAISuggestionsBaseCard>
    );
};

ContentTitleAISuggestionsForm.propTypes = {
    campaignId: PropTypes.number,
    status: PropTypes.string,
    onGenerateClick: PropTypes.func,
    errorMessage: PropTypes.string,
};

ContentTitleAISuggestionsForm.defaultProps = {
    campaignId: -1,
    status: COMPONENT_STATUS.ACTIVE,
    onGenerateClick: noop,
};

export default ContentTitleAISuggestionsForm;
