import React from 'react';
import { TopicArticlesHeader } from './TopicArticlesHeader';
import styles from './TopicArticlesDrawerContent.module.scss';

export const TopicArticlesDrawerContent = ({ topicName, totalArticles, children }) => {
    const sampleArticlesNumber = isNaN(children.length) ? 0 : children.length;
    const totalArticlesNumber = isNaN(totalArticles) ? 0 : totalArticles;

    return (
        <div className={styles['wrapper']}>
            <TopicArticlesHeader
                sampleArticles={sampleArticlesNumber}
                totalArticles={totalArticlesNumber}
                topicName={topicName}
            ></TopicArticlesHeader>
            <div className={styles['body-container']}>{children}</div>
        </div>
    );
};
