import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import FormattedMessageCellRenderer from './FormattedMessageCellRenderer';

class DmaCellRenderer extends Component {
    render() {
        const { msgIdPrefix, emptyValue, data: { dmaCode, dmaName } = {}, isSearchable } = this.props;
        const msgId = `${msgIdPrefix}.${dmaCode || emptyValue}`;

        return (
            <FormattedMessageCellRenderer
                value={msgId}
                defaultValue={dmaName || emptyValue}
                isSearchable={isSearchable}
            />
        );
    }
}

DmaCellRenderer.propTypes = {
    /** Cell string data */
    data: PropTypes.object,
    /** Cell msgId Prefix */
    msgIdPrefix: PropTypes.string,
    /** Cell empty value */
    emptyValue: PropTypes.string,
};

DmaCellRenderer.defaultProps = {
    emptyValue: EMPTY_FIELD_VALUE,
};

export default DmaCellRenderer;
