import React, { useRef } from 'react';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from '../../../taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { IFrameRenderer } from './IFrameRenderer';
import styles from './stripeBankTransferDetailsModal.module.scss';

export const StripeBankTransferDetailsModal = ({ pageUrl }) => {
    const iFrameRef = useRef();

    const onErrorMessage = (
        <div className={styles['stripe-bank-transfer-details-error-message']}>
            <FormattedMessage
                id="billingAndPayments.modal.bankTransferDetails.error"
                defaultMessage="Service temporarily unavailable"
            />
        </div>
    );

    return (
        <ModalFormLayout
            hideFooter
            className={styles['stripe-bank-transfer-details-modal']}
            contentContainerClassName={styles['stripe-bank-transfer-details']}
        >
            <IFrameRenderer
                src={pageUrl}
                ref={iFrameRef}
                onErrorMessage={onErrorMessage}
                className={styles['stripe-bank-transfer-details-iframe']}
            />
        </ModalFormLayout>
    );
};
