import moment from 'moment/moment';
import { Divider } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './PerformanceRuleDetails.module.scss';

export const PerformanceRuleDetails = ({
    details: {
        siteDescription,
        id: adId,
        performanceRuleName,
        performanceRuleActionType,
        performanceRuleActionTimeInUtc,
    },
}) => {
    const contentToRender = performanceRuleActionType === 'PAUSE_AD' ? adId : siteDescription;
    return (
        <span>
            <span className={styles['title']}>
                <FormattedMessage
                    id={`app.campaigns.reports.performance.rules.details.title.${performanceRuleActionType}`}
                    defaultMessage="Paused by Custom Rules"
                />
            </span>
            <Divider className={styles['divider']} />
            <div className={styles['group']}>
                <span className={styles['secondary-title']}>
                    <FormattedMessage
                        id={`app.campaigns.reports.performance.rules.details.entity.${performanceRuleActionType}`}
                        defaultMessage="Ad ID: "
                    />
                </span>
                <span className={styles['details']}>{contentToRender}</span>
            </div>
            <div className={styles['group']}>
                <span className={styles['secondary-title']}>
                    <FormattedMessage
                        id={`app.campaigns.reports.performance.rules.details.date.${performanceRuleActionType}`}
                        defaultMessage="Date of Pause: "
                    />
                </span>
                <span className={styles['details']}>
                    {moment(performanceRuleActionTimeInUtc).format('MMMM Do, YYYY')}
                </span>
            </div>
            <div className={styles['group']}>
                <span className={styles['secondary-title']}>
                    <FormattedMessage
                        id={`app.campaigns.reports.performance.rules.details.name.${performanceRuleActionType}`}
                        defaultMessage="Paused by Rule: "
                    />
                </span>
                <span className={styles['details']}>{performanceRuleName}</span>
            </div>
        </span>
    );
};
