import React, { useCallback, useEffect, useState } from 'react';
import { CollapsibleList, FormField, useCollapsibleListContext, LinkButton } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../../../taboola-common-frontend-modules/formData';
import ListWithMultilineInputListItem from '../../../ListWithMultilineInput/ListWithMultilineInputListItem/ListWithMultilineInputListItem';
import useListWithMultilineInputSelectorState from '../../../ListWithMultilineInput/hooks/useListWithMultilineInputSelectorState';
import { TargetingMultilineInputBox } from '../../../TargetingBox';
import { useZipCodesApi } from './hooks';
import styles from './zipCodeTargeting.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
const MessageWithRemoveIndication = ({
    message,
    isLoading,
    indicationType,
    invalidSelectedValuesSet,
    handleClearInvalidItems,
}) => {
    if (!message || isLoading) {
        return null;
    }

    return (
        <div className={indicationType && styles['error-container']}>
            {message}
            {invalidSelectedValuesSet.size > 0 && (
                <LinkButton onClick={handleClearInvalidItems} className={styles['clear-invalid']}>
                    <FormattedMessage
                        id="campaign.editor.geo.targeting.zipCodes.clearUnavailable"
                        defaultMessage="Click here to remove {count} unavailable postal code{count, plural, one {} other {s} }."
                        values={{ count: invalidSelectedValuesSet.size }}
                    />
                </LinkButton>
            )}
        </div>
    );
};

const ListHeader = () => {
    const { items } = useCollapsibleListContext();
    return (
        <FormattedMessage
            id="campaign.editor.geo.targeting.zipCodes"
            defaultMessage="Selected Postal/Zip Codes"
            values={{ count: items.length }}
        />
    );
};

const topContentPlaceholder = '10002\n20003\n30004';

const footerErrorMessage = (
    <FormattedMessage
        id="campaign.editor.geo.targeting.zipCodes.error.noResultsFound"
        defaultMessage="These Postal/Zip Codes are invalid or have limited reach."
    />
);

const footerButtonMessage = (
    <FormattedMessage id="campaign.editor.geo.targeting.zipCodes.addLocations" defaultMessage="Add Locations" />
);

const itemValidations = [
    {
        validationFn: (value, options, { resourcesById = {}, loading }) => loading || resourcesById[value],
        messageId: 'validations.error.campaign.targeting.zipCodes.invalidItem',
        defaultMessage: 'Unavailable postal code.',
    },
];
const validations = [
    {
        validationFn: (values, options, { resourcesById = {}, loadingIdsSet }) =>
            values.length > 0 || loadingIdsSet.size > 0,
        messageId: 'validations.error.campaign.targeting.zipCodes.empty',
        defaultMessage: 'Please select at least one Postal Code',
    },
    {
        validationFn: (values, options, { resourcesById = {}, loadingIdsSet }) =>
            values.every(value => resourcesById[value] || loadingIdsSet.has(value)),
        messageId: 'validations.error.campaign.targeting.zipCodes.invalid',
        defaultMessage: 'Some Postal Codes are unavailable anymore.',
    },
];

const submitBtnMetricsAttributes = {
    'data-metrics-event-name': GTM_EVENTS.USABILITY,
    'data-metrics-component': 'Postal Codes',
    'data-metrics-value': 'Add Locations',
};

const typeField = 'campaignTargeting.geoTargeting.postalCodeTargeting.type';
const valueField = 'campaignTargeting.geoTargeting.postalCodeTargeting.values';

const ZipCodeTargeting = () => {
    const [submittedIdsCount, setSubmittedIdsCount] = useState(0);

    const fetchZipCodesApi = useZipCodesApi();
    const { isEqualToInitial: isCountryTargetingLevelInitial } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargetingLevel',
    });
    const {
        selectedValues,
        invalidSelectedValuesSet,
        invalidInputValues,
        inputValues,
        text,
        initResources,
        resourcesById,
        failedValidationData: { message, indicationType, ...failedValidationData },
        isLoading,
        handleInputChange,
        handleDeleteItem,
        handleClearItems,
        handleClearInvalidItems,
        handleAddItems,
    } = useListWithMultilineInputSelectorState({
        valueField,
        validateInput: fetchZipCodesApi,
        validations,
        hasInitialData: isCountryTargetingLevelInitial,
    });
    const hasInvalidValues = invalidInputValues.length > 0;
    const { value: type, onChange: onChangeType } = useFormFieldValue({ field: `${typeField}` });
    const handleTypeChange = useCallback(
        ({ value }) => {
            onChangeType(value);
        },
        [onChangeType]
    );
    const submitMultiline = () => {
        setSubmittedIdsCount(inputValues.length);
        handleAddItems();
    };

    useEffect(() => {
        initResources();
    }, [initResources]);

    return (
        <FormField inputId="zip-code-area-box">
            <TargetingMultilineInputBox
                itemType={type}
                onChangeTargetingType={handleTypeChange}
                id="zip-code-multiline-input"
                textAreaPlaceholder={topContentPlaceholder}
                submitMultiline={submitMultiline}
                submitBtnMetricsAttributes={submitBtnMetricsAttributes}
                footerErrorMessage={footerErrorMessage}
                footerButtonMessage={footerButtonMessage}
                hasError={hasInvalidValues}
                submittedValuesCount={submittedIdsCount}
                text={text}
                inputValues={inputValues}
                valuesCount={inputValues.length}
                handleTextAreaChange={handleInputChange}
            />
            <CollapsibleListWithIndication
                id={valueField}
                items={selectedValues}
                itemHeight={45}
                ItemComponent={ListWithMultilineInputListItem}
                deleteItem={handleDeleteItem}
                clearItems={handleClearItems}
                listHeaderTitle={<ListHeader />}
                containerClassName={styles['list-container']}
                metadata={{ valueField, typeField, resourcesById, itemValidations }}
                message={
                    <MessageWithRemoveIndication
                        message={message}
                        isLoading={isLoading}
                        indicationType={indicationType}
                        invalidSelectedValuesSet={invalidSelectedValuesSet}
                        handleClearInvalidItems={handleClearInvalidItems}
                    />
                }
                indicationType={indicationType}
                {...failedValidationData}
            />
        </FormField>
    );
};

export default ZipCodeTargeting;
