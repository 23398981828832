import { useState, useCallback } from 'react';
import { UNCHECKED, CHECKED } from '../modules/campaigns/config/checkboxStates';

const useCheckboxState = (onSelectCallback, onUnselectCallback, initialState = UNCHECKED) => {
    const [selected, setSelected] = useState(Number(initialState));

    const handleClick = useCallback(
        (selectedValue, onClick) => {
            setSelected(selectedValue);
            onClick();
        },
        [setSelected]
    );
    const onSelectCheckbox = useCallback(() => handleClick(CHECKED, onSelectCallback), [handleClick, onSelectCallback]);
    const onUnselectCheckbox = useCallback(
        () => handleClick(UNCHECKED, onUnselectCallback),
        [handleClick, onUnselectCallback]
    );

    const selectedValues = [CHECKED];

    return { onSelectCheckbox, onUnselectCheckbox, selected, setSelected, selectedValues };
};

export default useCheckboxState;
