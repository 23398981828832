import React, { useCallback } from 'react';
import { useAvailableItems, useDropdownOptions, useDropdownValue } from 'hooks';
import { DropdownField } from 'modules/campaigns/components/Form';
import { supplyTargetingOptions } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/const';
import { useAPISupplyTargetingRestrictions } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useAPISupplyTargetingRestrictions';
import { useSupplyTargetingFormField } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useSupplyTargetingFormField';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const SupplyTargetingField = () => {
    const { value, onChange } = useSupplyTargetingFormField();
    const msgIdPrefix = 'campaign.editor.targeting.supply';
    const { supplyTargetingResource, isSupplyPermitted } = useAPISupplyTargetingRestrictions();
    const { value: campaignSupplyTargeting } = useSupplyTargetingFormField();
    const availableOptions = useAvailableItems(supplyTargetingOptions, {
        supplyTargetingResource,
        campaignSupplyTargeting,
    });
    const options = useDropdownOptions(availableOptions, msgIdPrefix);
    const selectedValue = useDropdownValue({ value }, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: supplyTargeting }) => {
            onChange(supplyTargeting);
        },
        [onChange]
    );

    if (!isSupplyPermitted) {
        return null;
    }

    return (
        <DropdownField
            id="supply-targeting"
            label={<FormattedMessage id="campaign.editor.targeting.supply.title" defaultMessage="Supply Targeting" />}
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.supply.description"
                    defaultMessage="Choose which Marketplace your line's ads will run in."
                />
            }
            placeholder={
                <FormattedMessage
                    id="campaign.editor.targeting.supply.placeholder"
                    defaultMessage="Select supply targeting..."
                />
            }
            selectedValueObject={selectedValue}
            options={options}
            onChange={changeHandler}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'SupplyTargeting',
            }}
        />
    );
};
