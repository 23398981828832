import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    EllipsisCellRenderer,
    withCampaignEditIndication,
} from 'modules/campaigns/modules/campaigns-reports/components';
import { CampaignsUsingCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/CampaignsUsingCellRenderer';
import { FunnelDefaultCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/FunnelDefaultCellRenderer';
import { FunnelActionsCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/FunnelsActionsCellRenderer';
import {
    actionsColumnType,
    hiddenColumnType,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { updateDefaultFunnel } from 'modules/tracking/flows/updateDefaultFunnel';

const unipFunnelsReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.IS_DEFAULT.label,
            field: FIELDS.IS_DEFAULT.field,
            type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
            cellRendererParams: {
                ...FIELDS.IS_DEFAULT.renderParams,
                reportId: REPORT_TYPE.FUNNELS,
            },
            cellRendererFramework: FunnelDefaultCellRenderer,
            csv: false,
            minWidth: 72,
            maxWidth: 72,
            accountConfigurations: { [FEATURE_FLAGS.CONVERSION_DEFAULT_FUNNEL_ENABLED]: 'true' },
            valueChangeHandler: ({ isDefault }, { id, isActive, accountName, name }) =>
                updateDefaultFunnel({
                    accountId: accountName,
                    funnel: {
                        isDefault,
                        id,
                        isActive,
                        name,
                    },
                }),
        },
        {
            headerName: FIELDS.FUNNEL_ID.label,
            field: FIELDS.FUNNEL_ID.field,
            type: [hiddenColumnType, numericColumn],
        },
        {
            headerName: FIELDS.FUNNEL_NAME.label,
            field: FIELDS.FUNNEL_NAME.field,
            cellRendererFramework: FIELDS.FUNNEL_NAME.cellRenderer,
            headerComponentParams: FIELDS.FUNNEL_NAME.headerComponentParams,
        },
        {
            headerName: FIELDS.CAMPAIGNS_USING.label,
            field: FIELDS.CAMPAIGNS_USING.field,
            cellRendererParams: {
                reportId: REPORT_TYPE.FUNNELS,
            },
            cellRendererFramework: CampaignsUsingCellRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_USING.headerComponentParams,
            width: 100,
        },
        {
            headerName: FIELDS.ACCOUNT_NAME.label,
            field: FIELDS.ACCOUNT_NAME.field,
            cellRendererFramework: EllipsisCellRenderer,
            headerComponentParams: FIELDS.ACCOUNT_NAME.headerComponentParams,
        },

        {
            headerName: FIELDS.RULE_DISPLAY_NAMES.label,
            field: FIELDS.RULE_DISPLAY_NAMES.field,
            cellRendererFramework: FIELDS.RULE_DISPLAY_NAMES.cellRenderer,
            headerComponentParams: FIELDS.RULE_DISPLAY_NAMES.headerComponentParams,
            minWidth: 350,
        },
        {
            headerName: FIELDS.LAST_MODIFY_ON.label,
            field: FIELDS.LAST_MODIFY_ON.field,
            valueFormatter: FIELDS.LAST_MODIFY_ON.valueFormatter,
            csvValueGetter: FIELDS.LAST_MODIFY_ON.csvValueGetter,
            headerComponentParams: FIELDS.LAST_MODIFY_ON.headerComponentParams,
            maxWidth: 200,
            minWidth: 200,
        },
        {
            headerName: FIELDS.ACTIONS_COLUMN.label,
            field: FIELDS.ACTIONS_COLUMN.field,
            type: [rightPinnedColumn, actionsColumnType, staticColumn],
            cellRendererFramework: FunnelActionsCellRenderer,
            csv: false,
            maxWidth: 100,
            minWidth: 100,
        },
    ],
];

export default normalizeColumnDefs(
    unipFunnelsReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.FUNNELS].id,
    withCampaignEditIndication
);
