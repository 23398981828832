import { useMemo } from 'react';
import classnames from 'classnames/bind';
import { some } from 'lodash';
import { MyLocationOutlinedIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { useConditions } from 'modules/performance-rules/hooks/useConditions';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PerformanceRuleEmailNotifications } from './EmailNotifications/PerformanceRuleEmailNotifications';
import { PerformanceRuleExcludeDays } from './PerformanceRuleExcludeDays';
import { PerformanceRuleFrequency } from './PerformanceRuleFrequency';
import { useDaysToExcludeValidatedField } from './useDaysToExcludeValidatedField';
import styles from './advancedSettingsSection.module.scss';

const classNameBuilder = classnames.bind(styles);

const resolveFrequency = (conditions, daysToExclude) => {
    const isAnyConditionWithTodayLookBackWindow = some(conditions, condition => condition.lookbackWindowInDays === 0);
    if (daysToExclude === 0 || isAnyConditionWithTodayLookBackWindow) {
        return 1;
    }
    const anyConditionWithLowFrequencyLookBackWindow = some(
        conditions,
        condition => condition.lookbackWindowInDays > 30
    );
    return anyConditionWithLowFrequencyLookBackWindow ? 12 : 5;
};

export const AdvancedSettingsSection = () => {
    const { conditions } = useConditions();
    const { daysToExclude, daysToExcludeChange, failedValidationData } = useDaysToExcludeValidatedField();
    const hours = useMemo(() => resolveFrequency(conditions, daysToExclude), [conditions, daysToExclude]);
    return (
        <CommonCollapsibleCard
            isInitiallyCollapsed={true}
            id="PERFORMANC_ERULES_CONDITION_AND_ACTION_SECTION"
            header={
                <SectionHeader
                    headerIcon={<MyLocationOutlinedIcon />}
                    headerText={
                        <FormattedMessage
                            id="app.create.performance.rules.advanced.settings.title"
                            defaultMessage="Advanced Settings"
                        />
                    }
                />
            }
            containerClassName={classNameBuilder('card-container')}
        >
            <PerformanceRuleFrequency hours={hours} />
            <PerformanceRuleExcludeDays
                daysToExclude={daysToExclude}
                daysToExcludeChange={daysToExcludeChange}
                failedValidationData={failedValidationData}
            />
            <PerformanceRuleEmailNotifications />
        </CommonCollapsibleCard>
    );
};
