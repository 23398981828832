import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { INDICATION_TYPES, RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { aiTabReferenceImageSelector } from '../../../selectors';
import { useGenerativeAiDisclaimerModal } from './GenerativeAiDisclaimerModal/useGenerativeAiDisclaimerModal';
import { useHasAcceptedGenerativeAiDisclaimer } from './GenerativeAiDisclaimerModal/useHasAcceptedGenerativeAiDisclaimer';
import { ImageToImage } from './icons/ImageToImage';
import { TextToImage } from './icons/TextToImage';
import FromImageSubTabWithDragAndDrop from './subTabs/FromImageSubTabWithDragAndDrop';
import FromPromptSubTab from './subTabs/FromPromptSubTab';
import styles from './aiGeneratedImagesTab.module.scss';

const classNameBuilder = classnames.bind(styles);

export const FROM_PROMPT = 'fromPrompt';
export const FROM_IMAGE = 'fromImage';
export const subTabs = [
    {
        id: FROM_PROMPT,
        caption: {
            id: 'creative.editor.tab.aiGenerated.fromPrompt.title',
            defaultMessage: 'Text-to-Image',
        },
        tooltip: {
            id: 'creative.editor.tab.aiGenerated.fromPrompt.description',
            defaultMessage: 'Bring your words to life with AI-generated visuals.',
        },
        Component: FromPromptSubTab,
        metricsAttributes: {
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'FROM_PROMPT',
        },
        icon: <TextToImage />,
    },
    {
        id: FROM_IMAGE,
        caption: {
            id: 'creative.editor.tab.aiGenerated.fromImage.title',
            defaultMessage: 'Image-to-Image',
        },
        tooltip: {
            id: 'creative.editor.tab.aiGenerated.fromImage.description',
            defaultMessage: 'Explore creative variations of your uploaded images with the power of AI.',
        },
        Component: FromImageSubTabWithDragAndDrop,
        metricsAttributes: {
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'FROM_IMAGE',
        },
        icon: <ImageToImage />,
    },
];

const BRANDS = [
    'ford',
    'toyota',
    'honda',
    'chevrolet',
    'bmw',
    'mercedes-benz',
    'volkswagen',
    'audi',
    'porsche',
    'nissan',
    'hyundai',
    'kia',
    'peugeot',
    'fiat',
    'subaru',
    'mazda',
    'renault',
    'mitsubishi',
    'lexus',
    'land rover',
    'cadillac',
    'dodge',
    'jeep',
    'tesla',
    'volvo',
    'infiniti',
    'acura',
    'gmc',
    'chrysler',
    'lincoln',
    'buick',
    'ram',
    'alfa romeo',
    'bentley',
    'maserati',
    'rolls-royce',
    'aston martin',
    'lamborghini',
    'ferrari',
    'mclaren',
    'bugatti',
    'genesis',
    'samsung',
    'google',
    'microsoft',
    'sony',
    'intel',
    'dell',
    'hp',
    'lenovo',
    'lg',
];
const BRANDS_SET = new Set(BRANDS);

const promptValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: 400 },
        messageId: 'creative.editor.tab.aiGenerated.fromPrompt.validations.length',
        defaultMessage: 'We suggest keeping the prompt length under 400 characters',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, _, { selectedTab }) => {
            if (selectedTab !== FROM_PROMPT) {
                return true;
            }

            const lowerCaseValueWords = (value || '').toLowerCase().split(/\s/);
            // return true if no word in the prompt is a brand
            return !lowerCaseValueWords.some(word => BRANDS_SET.has(word));
        },
        messageId: 'creative.editor.tab.aiGenerated.fromPrompt.brand.error',
        defaultMessage: 'Remove mentions of brands from your description to generate images',
    },
];

const AIGeneratedImagesTab = ({ onSelectMedia }) => {
    const { getHasAcceptedGenerativeAiDisclaimer } = useHasAcceptedGenerativeAiDisclaimer();
    const { openDisclaimerModal } = useGenerativeAiDisclaimerModal(onSelectMedia);
    const [{ accountId: selectedAccountId }] = useSelectedAccount();
    const [selectedTab, setSelectedTab] = useState(FROM_PROMPT);
    const {
        value: prompt,
        onChange: setPrompt,
        indicationData: promptFailedValidationData,
    } = useFormValidatedValue({
        field: 'aiImagesPrompt',
        validations: promptValidations,
        validationDependencies: { selectedTab },
    });
    const initialReferenceImage = useSelector(aiTabReferenceImageSelector);
    const [referenceImage, setReferenceImage] = useState(initialReferenceImage);
    const resolvedOnSelectMedia = useCallback(
        (...args) => (getHasAcceptedGenerativeAiDisclaimer() ? onSelectMedia(...args) : openDisclaimerModal(...args)),
        [getHasAcceptedGenerativeAiDisclaimer, onSelectMedia, openDisclaimerModal]
    );

    const tabHeaders = subTabs.map(({ caption, icon, id, metricsAttributes }) => (
        <RadioIcon
            key={id}
            title={<FormattedMessage id={caption.id} defaultMessage={caption.defaultMessage} />}
            iconComponent={icon}
            value={id}
            hideRadioPointer={true}
            titleClassName={styles['title']}
            iconContainerClassName={classNameBuilder('icon-container', { selected: id === selectedTab })}
            labelClassName={styles['label']}
            checkedRadioColor={styles['checkedRadioColor']}
            {...metricsAttributes}
        />
    ));

    const description = subTabs.map(
        ({ tooltip, id }) =>
            id === selectedTab && <FormattedMessage key={id} id={tooltip.id} defaultMessage={tooltip.defaultMessage} />
    );

    const tabContent = subTabs.map(
        ({ Component, id }) =>
            id === selectedTab && (
                <Component
                    key={id}
                    onSelectMedia={resolvedOnSelectMedia}
                    selectedAccountId={selectedAccountId}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    referenceImage={referenceImage}
                    setReferenceImage={setReferenceImage}
                    promptFailedValidationData={promptFailedValidationData}
                />
            )
    );

    return (
        <div className={styles['container']}>
            <RadioGroup className={styles['group']} selectedValue={selectedTab} onChange={setSelectedTab}>
                {tabHeaders}
            </RadioGroup>
            <div className={styles['description']}>{description}</div>
            {tabContent}
        </div>
    );
};

AIGeneratedImagesTab.propTypes = {
    onSelectMedia: PropTypes.func,
};

AIGeneratedImagesTab.defaultProps = {
    onSelectMedia: noop,
};

export default AIGeneratedImagesTab;
