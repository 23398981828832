import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { StyledButton, STYLED_BUTTON_TYPE, STYLED_BUTTON_SIZE } from 'taboola-ultimate-ui';
import styles from './daypartingButton.module.scss';

const classNameBuilder = classnames.bind(styles);

const DaypartingButton = ({ onClick, className, children, ...rest }) => (
    <StyledButton
        className={classNameBuilder(styles['button'], className)}
        type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
        size={STYLED_BUTTON_SIZE.MEDIUM}
        onClick={onClick}
        {...rest}
    >
        {children}
    </StyledButton>
);

DaypartingButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default DaypartingButton;
