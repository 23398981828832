import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AllCampaignsObject } from '../../../../../config';
import { useFetchedCampaign } from '../../../../../hooks/useFetchedCampaign';
import { useLastCreatedCampaignId } from '../../../../../hooks/useLastCreatedCampaignId';
import { useSelectedCampaign } from '../../../../../hooks/useSelectedCampaign';
import { campaignsMapSelector } from '../../../../../selectors';

export const useInitialCampaign = () => {
    const [lastCreatedCampaignId] = useLastCreatedCampaignId();
    const campaignMap = useSelector(campaignsMapSelector);
    const lastCreatedCampaign = campaignMap[lastCreatedCampaignId];
    const { campaign: selectedCampaign } = useSelectedCampaign();
    useFetchedCampaign(lastCreatedCampaignId);
    const campaign = useMemo(() => {
        if (lastCreatedCampaign) {
            return lastCreatedCampaign;
        }
        if (!lastCreatedCampaignId && selectedCampaign && selectedCampaign?.id !== AllCampaignsObject.id) {
            return selectedCampaign;
        }
    }, [selectedCampaign, lastCreatedCampaignId, lastCreatedCampaign]);

    return campaign;
};
