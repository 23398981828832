import getChangesObject from '../../../../taboola-common-frontend-modules/utils/objectDiff';
import { FORM_MODES } from '../../../config/';
import { saveCampaign as campaignsSaveCampaign, duplicateCampaign as campaignsDuplicateCampaign } from '../../../flows';

export const saveCampaign =
    ({ accountName, originalCampaign, editedCampaign, entityType, mode }) =>
    dispatch => {
        const campaignChanges = getChangesObject(editedCampaign, originalCampaign);

        if (campaignChanges.campaignTargeting && originalCampaign.campaignTargeting) {
            campaignChanges.campaignTargeting = getChangesObject(
                editedCampaign.campaignTargeting,
                originalCampaign.campaignTargeting
            );
            // Need to have diff on third level
            if (
                editedCampaign.campaignTargeting.publisherTargeting &&
                originalCampaign.campaignTargeting.publisherTargeting
            ) {
                campaignChanges.campaignTargeting.publisherTargeting = getChangesObject(
                    editedCampaign.campaignTargeting.publisherTargeting,
                    originalCampaign.campaignTargeting.publisherTargeting
                );
            }
        }

        // un-editable campaign fields
        const { accountName: sourceAccountName, id: campaignId } = editedCampaign;
        if (mode === FORM_MODES.DUPLICATE) {
            return dispatch(
                campaignsDuplicateCampaign({
                    accountId: sourceAccountName,
                    campaignId,
                    changes: campaignChanges,
                    passedDestinationAccountName: accountName,
                    entityType,
                })
            );
        } else if (Object.keys(campaignChanges).length) {
            return dispatch(
                campaignsSaveCampaign({
                    accountId: sourceAccountName,
                    campaignId,
                    changes: campaignChanges,
                    entityType,
                })
            );
        }
    };
