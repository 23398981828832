import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import {
    UpIcon,
    DownIcon,
    Collapsible,
    BUTTON_MODE,
    STYLED_WITH_MODE_BUTTON_SIZE,
    STYLED_WITH_MODE_BUTTON_TYPE,
    StyledWithModeButton,
    useIsMobile,
} from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { isGraphOpenSelector } from '../../../../selectors';
import BarGraph from '../BarGraph/BarGraph';
import Graph from '../Graph';
import { SingleMetricGraph } from '../SingleMetricGraph/SingleMetricGraph';
import styles from './collapsibleGraph.module.scss';

const classNameBuilder = classnames.bind(styles);

const GRAPH_TYPE_TO_GRAPH_COMPONENT_MAP = {
    [GRAPHS.SINGLE_METRIC_LINE]: SingleMetricGraph,
    [GRAPHS.BAR]: BarGraph,
    [GRAPHS.LINE]: Graph,
};

const CollapsibleGraph = ({ onCollapseToggle, config, ...rest }) => {
    const isMobile = useIsMobile();
    const isGraphOpen = useSelector(isGraphOpenSelector);
    const [isOpen, setIsOpen] = useState(!isMobile && (isNil(isGraphOpen) || isGraphOpen));
    const toggleIsOpen = () => {
        onCollapseToggle(!isOpen);
        setIsOpen(prev => !prev);
    };
    const { type } = config;
    const GraphComponent = GRAPH_TYPE_TO_GRAPH_COMPONENT_MAP[type] || Graph;

    return (
        <React.Fragment>
            <Collapsible collapsed={!isOpen}>
                <GraphComponent config={config} {...rest} />
            </Collapsible>
            <div className={classNameBuilder('control', { collapsed: !isOpen })}>
                <StyledWithModeButton
                    onClick={toggleIsOpen}
                    size={STYLED_WITH_MODE_BUTTON_SIZE.SMALL}
                    type={STYLED_WITH_MODE_BUTTON_TYPE.SYSTEM}
                    mode={BUTTON_MODE.NONE}
                    data-metrics-component="Graph show/hide"
                    data-metrics-value={isOpen ? 'Hide' : 'Show'}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                >
                    {isOpen ? <UpIcon /> : <DownIcon />}
                </StyledWithModeButton>
            </div>
        </React.Fragment>
    );
};

CollapsibleGraph.propTypes = {
    initialIsOpen: PropTypes.bool,
    onCollapseToggle: PropTypes.func,
};

CollapsibleGraph.defaultProps = {
    initialIsOpen: false,
    onCollapseToggle: () => {},
    config: {},
};

export default CollapsibleGraph;
