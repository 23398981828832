import React, { useState } from 'react';
import classnames from 'classnames/bind';
import ExternalLink from '../../../../../../../components/ExternalLink/ExternalLink';
import { useTopicsPreviewTracker } from '../../../hooks/useTopicsPreviewTracker';
import styles from './article.module.scss';

const classNameBuilder = classnames.bind(styles);

const Article = ({ articleDetails }) => {
    const { title, url, thumbnail_url: thumbnailUrl, logo_url: logoUrl, term } = articleDetails;
    const [isValidThumbnail, setIsValidThumbnail] = useState(true);
    const { reportTopicsPreviewEvents } = useTopicsPreviewTracker();
    const handleClick = () => {
        reportTopicsPreviewEvents(`Article Clicked, Topic Name: ${term}, URL: ${url}`, 'Topics Preview Whether Card');
    };

    const handleImageError = () => {
        reportTopicsPreviewEvents(
            `Invalid Image, Topic Name: ${term}, Image URL: ${thumbnailUrl}, Article URL: ${url}`,
            'Topics Preview Whether Card'
        );
        setIsValidThumbnail(false);
    };

    return (
        <ExternalLink
            href={url}
            onClick={handleClick}
            onAuxClick={handleClick}
            className={classNameBuilder('container')}
        >
            <div className={classNameBuilder('logo-and-keyword')}>
                <img src={logoUrl} alt="Logo" className={classNameBuilder('logo')} />
                <span className={classNameBuilder('delimiter-container')}>
                    <span className={classNameBuilder('delimiter')}></span>
                </span>
                <span className={classNameBuilder('keyword')}>{term}</span>
            </div>
            <div className={classNameBuilder('title')}>{title}</div>
            {thumbnailUrl && isValidThumbnail && (
                <img
                    src={thumbnailUrl}
                    alt="Thumbnail"
                    onError={handleImageError}
                    className={classNameBuilder('thumbnail')}
                />
            )}
        </ExternalLink>
    );
};

export default Article;
