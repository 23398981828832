import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

const emptyColumns = [
    FIELDS.IS_ACTIVE,
    FIELDS.ID,
    FIELDS.NAME,
    FIELDS.VIDEO_BUDGET,
    FIELDS.STATUS,
    FIELDS.VIDEO_MODEL_TYPE,
    FIELDS.START_DATE,
    FIELDS.END_DATE,
    FIELDS.ACTIONS_COLUMN,
].map(createFooterMapper(REPORT_TYPE.CAMPAIGN_VIDEO));

const campaignReportFooterColumnDefinition = [...emptyColumns];

export default campaignReportFooterColumnDefinition;
