import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import useFormFieldValue from '../../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { campaignInitialValues, DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../../config';
import { useThirdPartyTagsFormFieldValue } from '../../ThirdPartyTags/useThirdPartyTagsFormFieldValue';
import ThirdPartyTrackingTooltip from './ThirdPartyTrackingTooltip';
import styles from './thirdPartyTrackingSetting.module.scss';

const ThirdPartyTrackingSetting = ({ checked }) => {
    const { value: trackingCode, onChange: onChangeTrackingCode } = useFormFieldValue({
        field: 'trackingCode',
    });
    const { value: thirdPartyTags, onChange: onChangeThirdPartyTags } = useThirdPartyTagsFormFieldValue();

    const originalCampaignTrackingCode = useRef(trackingCode);
    const originalCampaignThirdPartyTags = useRef(thirdPartyTags);

    useEffect(() => {
        if (checked) {
            onChangeTrackingCode(originalCampaignTrackingCode.current);
            onChangeThirdPartyTags(originalCampaignThirdPartyTags.current);
            return;
        }

        onChangeThirdPartyTags();
        onChangeTrackingCode(campaignInitialValues.trackingCode);
    }, [checked, onChangeThirdPartyTags, onChangeTrackingCode]);

    return (
        <TextCheckbox
            helpText={<ThirdPartyTrackingTooltip />}
            value={DUPLICATE_CAMPAIGN_SETTINGS_TYPE.TRACKING_CODE_THIRD_PARTY_PIXEL}
            title={
                <FormattedMessage
                    id="campaign.editor.duplicateCampaignSettings.TrackingCodeAndThirdPartyTags"
                    defaultMessage="Tracking Code and 3rd Party Tags"
                />
            }
            labelClassName={styles['label']}
        />
    );
};

ThirdPartyTrackingSetting.propTypes = {
    checked: PropTypes.bool,
};

export default ThirdPartyTrackingSetting;
