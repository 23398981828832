import React from 'react';
import { isNil } from 'lodash';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from 'recharts';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { FormattedMessage, useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './sizeEstimationGraphField.module.scss';

const color = styles.lineStroke;

const calcVerticalPoints = width => {
    const quarterSize = width / 4;
    return [quarterSize, quarterSize * 2, quarterSize * 3];
};

const Loading = ({ formatMessage }) => (
    <div className={styles['loading']}>
        <LoadingAnimation
            label={formatMessage({
                id: 'custom.contextual.size.estimation.loading',
                defaultMessage: 'Calculating...',
            })}
        />
    </div>
);

const Banner = () => (
    <div className={styles['banner']}>
        <FormattedMessage id="custom.contextual.size.estimation.no.results" defaultMessage="No results" />
    </div>
);

const dataKey = 'p';

const Graph = ({ verticalPoints, graphData }) => {
    const gradiantId = 'colorPv';

    return (
        <div className={styles['graph-container']} role="figure">
            <ResponsiveContainer>
                <AreaChart data={graphData} margin={{ top: 0, right: 0, left: 0, bottom: 1 }}>
                    <defs>
                        <linearGradient id={gradiantId} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={color} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid horizontal={false} verticalPoints={verticalPoints} />
                    <Area
                        strokeWidth={2}
                        type="monotone"
                        dataKey={dataKey}
                        stroke={color}
                        fill={`url(#${gradiantId})`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export const SizeEstimationTrendGraph = ({ isLoading, graphPoints, width }) => {
    const hasData = !isNil(graphPoints) && graphPoints.length > 1;
    const verticalPoints = calcVerticalPoints(width);
    const { formatMessage } = useIntl();

    if (isLoading) {
        return <Loading formatMessage={formatMessage} />;
    }

    if (hasData) {
        // We don't use useMemo as the graphPoints are bounded at 30
        const graphData = hasData && graphPoints.map(n => ({ [dataKey]: n }));
        return <Graph verticalPoints={verticalPoints} graphData={graphData} />;
    }

    return <Banner />;
};

export default SizeEstimationTrendGraph;
