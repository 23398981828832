import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useBreadcrumbsAccountStep, { MAX_CHARACTER_COUNT } from '../../../hooks/useBreadcrumbsAccountStep';
import { allCampaignsNameIdSelector } from '../../../selectors';
import { isVideoCampaign } from '../../../services/campaignDimension';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';
import { AllCampaignsId, AllCampaignsObject, CAMPAIGNS_ROUTE, REPORT_TYPE, reportsBaseConfig } from '../config';
import { useCampaignGroupExtensionEnabled } from '../modules/campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { getCampaignGroupFilter } from '../modules/campaigns-reports/config/reports/campaigns-groups/columnDefinitions';
import { REPORT_ID, useSelectedReportId } from '../modules/campaigns-reports/hooks';
import { REPORT_FILTERS } from '../modules/campaigns-reports/hooks/usePermanentReportFilters';
import { REPORT_FILTER_STATUS } from '../modules/campaigns-reports/hooks/useReportFiltersStatus';
import { useFetchedCampaignGroup } from '../modules/common-campaign-form/components/CampaignGroupField/hooks/useFetchedCampaignGroup';
import { useSelectedCampaign } from './useSelectedCampaign';
import { CAMPAIGN_ID } from './useSelectedCampaignId';

const useCampaignBreadcrumbs = ({ selectedReport: coerceReport, title, onEditCampaign, isTitleAsLastStep = true }) => {
    const { formatMessage } = useIntl();
    const [selectedReportIdParam] = useSelectedReportId();
    const selectedReport = coerceReport || selectedReportIdParam;
    const { campaign: selectedCampaign } = useSelectedCampaign();
    const { campaignGroupId } = selectedCampaign || {};
    const campaignGroup = useFetchedCampaignGroup(campaignGroupId);
    const campaignGroupName = campaignGroup?.campaignsGroupName;
    const allCampaignsNameId = useSelector(allCampaignsNameIdSelector);
    const accountSteps = useBreadcrumbsAccountStep(CAMPAIGNS_ROUTE);
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    const handleEdit = useCallback(() => {
        onEditCampaign(selectedCampaign);
    }, [onEditCampaign, selectedCampaign]);

    const shouldRenderEdit =
        !!onEditCampaign &&
        selectedCampaign.id !== AllCampaignsObject.id &&
        !isVideoCampaign(selectedCampaign.dimension);

    const steps = useMemo(() => {
        const allCampaignsText = formatMessage({ id: allCampaignsNameId, defaultMessage: 'All Campaigns' });
        const selectedReportLabel = formatMessage({
            id: `app.campaigns.reports.picker.report.type.${reportsBaseConfig[selectedReport]?.id}`,
            defaultMessage: reportsBaseConfig[selectedReport]?.label,
        });

        const campaignGroupStep = {
            label: campaignGroupName,
            path: isTitleAsLastStep ? CAMPAIGNS_ROUTE : null,
            params: {
                [CAMPAIGN_ID]: AllCampaignsId,
                [REPORT_ID]: REPORT_TYPE.CAMPAIGN,
                [REPORT_FILTER_STATUS]: 'true',
                [REPORT_FILTERS]: getCampaignGroupFilter(campaignGroupId, campaignGroupName),
            },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };

        const allCampaignStep = {
            label: `${selectedReportLabel} (${allCampaignsText})`,
            path: isTitleAsLastStep ? CAMPAIGNS_ROUTE : null,
            params: { [CAMPAIGN_ID]: AllCampaignsId, [REPORT_ID]: selectedReport },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };

        const steps = [...accountSteps, allCampaignStep];

        if (campaignGroupId && campaignGroupExtensionEnabled) {
            steps.push(campaignGroupStep);
        }

        if (isTitleAsLastStep) {
            steps.push({ label: title, maxCharacterCount: MAX_CHARACTER_COUNT });
        }
        return steps;
    }, [
        formatMessage,
        allCampaignsNameId,
        selectedReport,
        campaignGroupName,
        isTitleAsLastStep,
        campaignGroupId,
        accountSteps,
        title,
        campaignGroupExtensionEnabled,
    ]);

    return {
        steps,
        shouldRenderEdit,
        handleEdit,
    };
};

export default useCampaignBreadcrumbs;
