import React from 'react';
import { noop, stubTrue } from 'lodash';
import { SuggestionContentTitle } from 'modules/campaigns/components/SuggestionContentTitle/SuggestionContentTitle';
import {
    creativeDescriptionValidations,
    creativeTitleValidations,
    maxCreativeTitleLength,
    REPORT_TYPE,
    reportsBaseConfig,
    TITLE_WARNING_THRESHOLD,
    urlValidations,
} from 'modules/campaigns/config';
import { deleteCreative } from 'modules/campaigns/flows';
import { setSelectedRows } from 'modules/campaigns/modules/campaigns-reports/actions';
import {
    CheckboxCellRenderer,
    CheckboxHeaderRenderer,
    ContentCellRenderer,
    CreativeActionsCellRenderer,
    CreativeIdCellRenderer,
    CreativeStatusRenderer,
    DummyBidModificationCellRenderer,
    EllipsisCellRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    formattedUTCDateValueGetter,
    gridNumberFormatter,
    gridUTCDateFormatter,
    IMAGE_CELL_RENDERER_MODES,
    ImageCellRenderer,
    LinkCellRenderer,
    RssBulkDisabledTooltip,
    StateCellRenderer,
    StoppedCreativeBulkDisabledTooltip,
    withCreativeEditIndication,
} from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    bypassMetadataFilterType,
    checkBoxSelectionColumn,
    editableColumn,
    keyColumn,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { nonDefaultHeaderComponentParams } from 'modules/campaigns/modules/campaigns-reports/config/fields';
import {
    isCreativeRejected,
    isCreativeStatusEditable,
    isCreativeStopped,
    isRssChild,
    onlyNetworkColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import {
    duplicateCreative,
    editCreative,
    previewCreative,
    saveCreative,
    saveCreativeUrlCellEditor,
} from 'modules/campaigns/modules/campaigns-reports/flows';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { isCarouselCreative } from 'modules/campaigns/modules/creative-creator/components/CarouselCardList/utils/isCarouselCreative';
import {
    DESCRIPTION_WARNING_THRESHOLD,
    MAX_DESCRIPTION_LENGTH,
} from 'modules/campaigns/modules/creative-creator/components/ContentField/config';
import { BYPASS_URL_RESPONSE_VALIDATION_FIELD } from 'modules/campaigns/modules/creative-creator/components/UrlList/UrlValidatedInput';
import { PERFORMANCE_RECOMMENDATIONS } from 'modules/campaigns/modules/performance-recommendations/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PERMISSIONS_OPERATORS } from 'modules/taboola-common-frontend-modules/authentication';
import { PerformanceRuleCellEditor } from '../../../components/CellEditors/PerformanceRuleCellEditor';

const isDisabled = data => {
    return isRssChild(data) || isCreativeStopped(data);
};
const tooltipContent = data =>
    (isRssChild(data) && <RssBulkDisabledTooltip />) ||
    (isCreativeStopped(data) && <StoppedCreativeBulkDisabledTooltip />) ||
    null;

const creativeReportColumnDefinition = [
    {
        headerComponentFramework: CheckboxHeaderRenderer,
        headerName: FIELDS.IS_SELECTED.label,
        field: FIELDS.IS_SELECTED.field,
        type: [leftPinnedColumn, checkBoxSelectionColumn, staticColumn],
        cellRendererParams: {
            onClick: setSelectedRows,
            isDisabled,
            tooltipContent,
        },
        headerComponentParams: {
            onClick: setSelectedRows,
            isDisabled: data => isDisabled(data) || isCarouselCreative(data),
            tooltipContent,
        },
        valueGetter: FIELDS.IS_SELECTED.valueGetter,
        cellRendererFramework: CheckboxCellRenderer,
        csv: false,
        permissions: {
            permissions: ['CAMPAIGNS_ITEMS_BULK_UPDATE'],
        },
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVES_BULK_ACTIONS_ENABLED]: 'true',
        },
    },
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [lockedColumn, toggleColumn, leftPinnedColumn, staticColumn],
        cellRendererParams: {
            ...FIELDS.IS_ACTIVE.renderParams,
            isDisabled: data => isCreativeStopped(data) || isCreativeRejected(data) || isCarouselCreative(data),
            reportId: REPORT_TYPE.CREATIVE,
        },
        valueChangeHandler: saveCreative,
        cellRendererFramework: StateCellRenderer,
        csv: false,
    },
    {
        headerName: FIELDS.CUSTOM_CREATIVE_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CUSTOM_CREATIVE_NAME.headerComponentParams,
        field: FIELDS.CUSTOM_CREATIVE_NAME.field,
        type: [leftPinnedColumn, editableColumn],
        editable: stubTrue,
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        cellEditorFramework: FIELDS.CUSTOM_CREATIVE_NAME.cellEditorFramework,
        valueChangeHandler: saveCreative,
        valueFormatter: FIELDS.CUSTOM_CREATIVE_NAME.valueFormatter,
        accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
    },
    {
        headerName: FIELDS.CUSTOM_ID.label,
        field: FIELDS.CUSTOM_ID.field,
        type: [leftPinnedColumn, editableColumn],
        editable: stubTrue,
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        cellEditorFramework: FIELDS.CUSTOM_ID.cellEditorFramework,
        valueChangeHandler: saveCreative,
        valueFormatter: FIELDS.CUSTOM_CREATIVE_NAME.valueFormatter,
        accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
    },
    {
        headerName: FIELDS.CAMPAIGNS_GROUP_ID.label,
        field: FIELDS.CAMPAIGNS_GROUP_ID.field,
        type: [numericColumn],
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CAMPAIGNS_GROUP_ID.headerComponentParams,
        accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.CAMPAIGNS_GROUP_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CAMPAIGNS_GROUP_NAME.headerComponentParams,
        field: FIELDS.CAMPAIGNS_GROUP_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGNS_GROUP_NAME.cellRendererFramework,
        cellRendererParams: FIELDS.CAMPAIGNS_GROUP_NAME.cellRendererFramework,
        accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.BID_MODIFICATION.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.BID_MODIFICATION.headerComponentParams,
        field: FIELDS.BID_MODIFICATION.field,
        type: [bypassMetadataFilterType],
        cellRendererFramework: DummyBidModificationCellRenderer,
        width: 150,
        csvValueGetter: noop,
        permissions: {
            permissions: ['ADS_REPORT_DUMMY_BID_ADJUSTMENT_VIEW'],
            operator: PERMISSIONS_OPERATORS.AND,
        },
    },
    {
        headerName: FIELDS.ID.label,
        field: FIELDS.ID.field,
        type: [numericColumn],
        cellRendererFramework: CreativeIdCellRenderer,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.CREATIVE),
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.THUMBNAIL_URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL.field,
        type: [leftPinnedColumn, editableColumn],
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.DEFAULT,
        },
        onCellClicked: editCreative,
        singleClickEdit: false,
        width: 120,
        csv: FIELDS.THUMBNAIL_URL.csv,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.THUMBNAIL_URL_COMFORTABLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
        type: [leftPinnedColumn, editableColumn, bypassMetadataFilterType],
        valueGetter: ({ data }) => {
            const { thumbnailUrl } = data;
            return thumbnailUrl;
        },
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.COMFORTABLE,
        },
        onCellClicked: editCreative,
        singleClickEdit: false,
        width: 186,
        csv: FIELDS.THUMBNAIL_URL_COMFORTABLE.csv,
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.CONTENT.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CONTENT.headerComponentParams,
        type: [editableColumn, bypassMetadataFilterType],
        valueGetter: ({ data }) => {
            const { title, description, cta } = data;
            return {
                title,
                description,
                cta,
            };
        },
        field: FIELDS.CONTENT.field,
        cellRendererFramework: ContentCellRenderer,
        cellRendererParams: {
            lines: 2,
            ctaMsgIdPrefix: 'creative.creator.content.cta.option',
            isSearchable: true,
        },
        onCellClicked: editCreative,
        singleClickEdit: false,
        width: 328,
        csv: [
            {
                headerName: 'Title',
                field: 'title',
            },
            {
                headerName: 'Description',
                field: 'description',
            },
            ...FIELDS.CTA.csv,
        ],
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.TITLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.TITLE.field,
        type: [keyColumn, editableColumn],
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: {
            lines: 2,
            isSearchable: true,
        },
        cellEditorFramework: FIELDS.TITLE.cellEditorFramework,
        cellEditorParams: {
            validations: creativeTitleValidations,
            maxLength: maxCreativeTitleLength,
            warningThreshold: TITLE_WARNING_THRESHOLD,
            EditableTextComponent: SuggestionContentTitle,
            editableTextClassName: 'overflow-visible',
        },
        valueChangeHandler: saveCreative,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.URL.headerComponentParams,
        field: FIELDS.URL.field,
        type: [editableColumn],
        cellRendererFramework: LinkCellRenderer,
        width: 270,
        cellEditorFramework: FIELDS.URL.cellEditorFramework,
        cellEditorParams: {
            validations: urlValidations,
        },
        valueChangeHandler: saveCreativeUrlCellEditor,
        cellRendererParams: { isSearchable: true },
        valueSetter: params => {
            params.data[FIELDS.URL.field] = params.newValue.url;
            params.data[BYPASS_URL_RESPONSE_VALIDATION_FIELD] = params.newValue[BYPASS_URL_RESPONSE_VALIDATION_FIELD];
            return true;
        },
    },
    {
        headerName: FIELDS.DESCRIPTION.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.DESCRIPTION.headerComponentParams,
        field: FIELDS.DESCRIPTION.field,
        type: [editableColumn],
        cellRendererFramework: EllipsisCellRenderer,
        width: 270,
        cellEditorFramework: FIELDS.DESCRIPTION.cellEditorFramework,
        cellEditorParams: {
            validations: creativeDescriptionValidations,
            maxLength: MAX_DESCRIPTION_LENGTH,
            warningThreshold: DESCRIPTION_WARNING_THRESHOLD,
        },
        valueChangeHandler: saveCreative,
        cellRendererParams: { isSearchable: true },
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.STATUS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.STATUS.field,
        type: [leftPinnedColumn, editableColumn],
        cellRendererFramework: CreativeStatusRenderer,
        flex: 1,
        minWidth: 200,
        editable: isCreativeStatusEditable,
        cellEditorFramework: PerformanceRuleCellEditor,
        cellRendererParams: {
            ...FIELDS.STATUS.renderParams,
            recommendationTypes: [
                PERFORMANCE_RECOMMENDATIONS.LOW_PERFORMING_ITEM,
                PERFORMANCE_RECOMMENDATIONS.LOW_RESOLUTION_IC,
                PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_DESCRIPTION,
                PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_CTA,
                PERFORMANCE_RECOMMENDATIONS.FATIGUED_ITEM,
            ],
        },
        csvValueGetter: FIELDS.STATUS.csvValueGetter,
    },
    {
        headerName: FIELDS.ACCOUNT_ID.label,
        field: FIELDS.ACCOUNT_ID.field,
        visibilityCondition: onlyNetworkColumn,
    },
    {
        headerName: FIELDS.ACCOUNT_DESCRIPTION.label,
        field: FIELDS.ACCOUNT_DESCRIPTION.field,
        visibilityCondition: onlyNetworkColumn,
    },
    {
        headerName: FIELDS.CTA.label,
        field: FIELDS.CTA.field,
        valueGetter: FIELDS.CTA.valueGetter,
        type: [editableColumn],
        cellRendererFramework: FIELDS.CTA.cellRenderer,
        cellRendererParams: FIELDS.CTA.renderParams,
        cellEditorFramework: FIELDS.CTA.cellEditorFramework,
        valueChangeHandler: saveCreative,
        csv: FIELDS.CTA.csv,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.CAMPAIGN_ID.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CAMPAIGN_ID.headerComponentParams,
        field: FIELDS.CAMPAIGN_ID.field,
        type: [numericColumn],
        cellRendererFramework: EllipsisCellRenderer,
    },
    {
        headerName: FIELDS.CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGN_NAME.cellRendererFramework,
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.START_DATE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.START_DATE.headerComponentParams,
        field: FIELDS.START_DATE.field,
        valueFormatter: gridUTCDateFormatter,
        csvValueGetter: formattedUTCDateValueGetter,
        accountConfigurations: { [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.END_DATE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.END_DATE.headerComponentParams,
        field: FIELDS.END_DATE.field,
        valueFormatter: gridUTCDateFormatter,
        csvValueGetter: formattedUTCDateValueGetter,
        accountConfigurations: { [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.CREATE_TIME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.CREATE_TIME.field,
        valueFormatter: FIELDS.CREATE_TIME.valueFormatter,
        csvValueGetter: FIELDS.CREATE_TIME.csvValueGetter,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        cellRendererParams: {
            onDelete: deleteCreative,
            onPreview: previewCreative,
            onEdit: editCreative,
            onDuplicate: duplicateCreative,
        },
        cellRendererFramework: CreativeActionsCellRenderer,
        csv: false,
    },
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.VCTR,
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    creativeReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CREATIVE].id,
    withCreativeEditIndication
);
