import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import deleteCampaignFlow from '../flows/deleteCampaign';

const useDeleteCampaign = () => {
    const dispatch = useDispatch();
    const { open: openModal } = useModal();
    const deleteCampaign = useCallback(
        async campaign => {
            const { accountName, id, demandType, name } = campaign;

            const result = await openModal({
                title: (
                    <FormattedMessage
                        id="app.campaigns.delete.campaign.confirmation.title"
                        defaultMessage="Delete Campaign?"
                        tagName="h2"
                    />
                ),
                content: (
                    <FormattedMessage
                        id="app.campaigns.delete.campaign.confirmation.message"
                        defaultMessage="You are about to delete: {name}. Once a campaign is deleted, it can never run again. Instead of deleting, you can pause campaigns. Do you wish to proceed?"
                        values={{ name: <b>{name}</b> }}
                    />
                ),
                formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" /> },
            });

            if (result) {
                dispatch(deleteCampaignFlow(accountName, id, demandType));
            }
        },
        [openModal, dispatch]
    );

    return deleteCampaign;
};

export default useDeleteCampaign;
