import React from 'react';
import classnames from 'classnames/bind';
import { map } from 'lodash';
import { ErrorOutlineOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './bulkUploadResultMessages.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ErrorResultsMessages = ({ errors }) => (
    <div className={classNameBuilder('section-container', 'unsuccessful')}>
        <div className={styles['header']}>
            <FormattedMessage id="excel.upload.unsuccessful.header" defaultMessage="Requires Attention" />
        </div>
        <div className={styles['unsuccessful-subheader']}>
            <FormattedMessage
                id="excel.upload.unsuccessful.subheader"
                defaultMessage={`Download "Summary Report" to see the exact failure reasons.`}
            />
        </div>
        <div className={styles['messages-container']}>
            {map(errors, ({ entity, errorCount }) => (
                <div key={entity} className={[styles['message-container']]}>
                    <ErrorOutlineOutlinedIcon fontSize="small" className={styles['error-icon']} />
                    <FormattedMessage
                        id={`excel.upload.${entity}.creator.error.message`}
                        values={{
                            errorCount,
                        }}
                    />
                </div>
            ))}
        </div>
    </div>
);
