import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { chain } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { FORM_MODES } from 'config/formModes';
import { useSelectedAccount } from 'hooks';
import FormLayout from 'modules/campaigns/components/Form/FormLayout/FormLayout';
import { BulkEditTrackingSection } from 'modules/campaigns/modules/creative-bulk-editor/components/BulkEditTrackingSection/BulkEditTrackingSection';
import { ContentSection } from 'modules/campaigns/modules/creative-bulk-editor/components/ContentSection/ContentSection';
import { MediaSection } from 'modules/campaigns/modules/creative-bulk-editor/components/MediaSection/MediaSection';
import { useCreativeBulkEditorState } from 'modules/campaigns/modules/creative-bulk-editor/hooks/useCreativeBulkEditorState';
import useCreativesIdsBeingEdited from 'modules/campaigns/modules/creative-bulk-editor/hooks/useCreativesIdsBeingEdited';
import { FIELDS } from 'modules/campaigns/modules/creative-bulk-editor/utils';
import { BYPASS_URL_RESPONSE_VALIDATION_FIELD } from 'modules/campaigns/modules/creative-creator/components/UrlList/UrlValidatedInput';
import { FieldEditorHeader } from 'modules/campaigns/modules/creative-editor/components/EditorForm/Header';
import { selectedRowsSelector } from 'modules/campaigns/selectors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import FormInitBase from 'modules/taboola-common-frontend-modules/forms/components/FormInitBase/FormInitBase';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './creativeBulkEditor.module.scss';

const initialSelectedFieldsMatrix = {
    [FIELDS.TITLE]: false,
    [FIELDS.URL]: false,
    [FIELDS.DESCRIPTION]: false,
    [FIELDS.CTA]: false,
    [FIELDS.THUMBNAIL_URL]: false,
    [FIELDS.VIDEO_URL]: false,
    [FIELDS.THIRD_PARTY_TAGS]: false,
    [BYPASS_URL_RESPONSE_VALIDATION_FIELD]: true,
};

const useSelectedRowsValues = () => {
    const creativeValues = useSelector(selectedRowsSelector);

    const selectedRowsValues = useMemo(() => {
        const test = Object.values(creativeValues).reduce(
            (prev, curr) => {
                const { title, description, url, cta, thumbnailUrl, videoUrl, thirdPartyTags } = curr;
                prev.titles.push(title);
                prev.descriptions.push(description);
                prev.urls.push(url);
                prev.ctas.push(cta);
                prev.thumbnailUrls.push(thumbnailUrl);
                prev.videoUrls.push(videoUrl);
                prev.thirdPartyTags.push(thirdPartyTags);
                return prev;
            },
            {
                titles: [],
                descriptions: [],
                urls: [],
                ctas: [],
                thumbnailUrls: [],
                videoUrls: [],
                thirdPartyTags: [],
            }
        );
        return test;
    }, [creativeValues]);

    return selectedRowsValues;
};

const headerLabel = <FormattedMessage id="creative.editor.edit.bulk.header" tagName="h1" />;

const CreativeBulkEditor = ({ sourcePath, onCancel }) => {
    const [{ accountId }] = useSelectedAccount();
    const { submitStatus, isSubmitDisabled, isFormTouched, mode } = useFormDataContext();
    const [isThumbnailLoading, setIsThumbnailLoading] = useState();
    const [selectedFieldsMatrix, setSelectedFieldsMatrix] = useState(initialSelectedFieldsMatrix);
    const [thumbnailStatus, setThumbnailStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [videoStatus, setVideoStatus] = useState(COMPONENT_STATUS.INITIAL);
    const isTrackingSectionEnabledByConfig = useConfigMatch({ [FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING]: 'true' });
    const isTrackingSectionEnabledByPermission = usePermissions('CREATIVE_EDIT_TRACKING');
    const isTrackingSectionEnabled = isTrackingSectionEnabledByConfig || isTrackingSectionEnabledByPermission;
    const creativesIdsBeingEdited = useCreativesIdsBeingEdited();
    const selectedRowsValues = useSelectedRowsValues();
    const { submit } = useCreativeBulkEditorState({ sourcePath });
    const isDisableSubmit = useMemo(() => {
        return (
            chain(selectedFieldsMatrix)
                .omit([BYPASS_URL_RESPONSE_VALIDATION_FIELD])
                .every(value => value === false)
                .value() ||
            isSubmitDisabled ||
            (!isFormTouched && mode !== FORM_MODES.BULK_DUPLICATE)
        );
    }, [selectedFieldsMatrix, isSubmitDisabled, isFormTouched, mode]);

    return (
        <FormInitBase className={styles['wrapper']}>
            <FormLayout
                className={styles['editor']}
                headerContainerClassName={styles['editor-header']}
                contentContainerClassName={styles['content-container']}
                bodyContainerClassName={styles['body-container']}
                footerContainerClassName={styles['footer']}
                header={
                    <FieldEditorHeader headerLabel={headerLabel} hideStatusSection={true}>
                        <div className={styles['subheader']}>
                            <FormattedMessage
                                id="creative.editor.edit.bulk.subheader"
                                values={{ count: creativesIdsBeingEdited.length }}
                            />
                        </div>
                    </FieldEditorHeader>
                }
                onCancel={onCancel}
                onSubmit={() => submit(selectedFieldsMatrix)}
                disableSubmit={isDisableSubmit}
            >
                <MediaSection
                    selectedFieldsMatrix={selectedFieldsMatrix}
                    setSelectedFieldsMatrix={setSelectedFieldsMatrix}
                    accountId={accountId}
                    isThumbnailLoading={isThumbnailLoading}
                    setIsThumbnailLoading={setIsThumbnailLoading}
                    selectedRowsValues={selectedRowsValues}
                    thumbnailStatus={thumbnailStatus}
                    videoStatus={videoStatus}
                    setThumbnailStatus={setThumbnailStatus}
                    setVideoStatus={setVideoStatus}
                    formLayoutProps={{
                        headerContainerClassName: styles['editor-header'],
                        hideFooter: true,
                    }}
                />
                <ContentSection
                    selectedRowsValues={selectedRowsValues}
                    selectedFieldsMatrix={selectedFieldsMatrix}
                    setSelectedFieldsMatrix={setSelectedFieldsMatrix}
                />
                {isTrackingSectionEnabled && (
                    <BulkEditTrackingSection
                        selectedFieldsMatrix={selectedFieldsMatrix}
                        setSelectedFieldsMatrix={setSelectedFieldsMatrix}
                    />
                )}
                {submitStatus === COMPONENT_STATUS.LOADING && <Spinner className={styles['overlay']} />}
            </FormLayout>
        </FormInitBase>
    );
};
CreativeBulkEditor.propTypes = { onCancel: PropTypes.func };

const CreativeBulkEditorWithFormDataProvider = withFormDataProvider(CreativeBulkEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { CreativeBulkEditorWithFormDataProvider as CreativeBulkEditor };
