"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgUrl(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", { d: "M10 16h12v1H10v-1z", fill: "#212832" }),
        React.createElement("path", {
            d: "M18 11h3.5a5.5 5.5 0 110 11H18v1h3.5a6.5 6.5 0 100-13H18v1zm-4 0v-1h-3.5a6.5 6.5 0 100 13H14v-1h-3.5a5.5 5.5 0 110-11H14z",
            fill: "#212832"
        })
    );
}
exports.default = SvgUrl;