import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { DropdownMenu } from '../../../../../../../components';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { PRICING_MODEL } from '../../../../../config';
import { BidTooltip } from '../../Bid/BidTooltip';
import { CPCBidType } from '../BidType/CPCBidType';
import { CPMBidType } from '../BidType/CPMBidType';
import { VCPMBidType } from '../BidType/VCPMBidType';
import { useHighImpactPackageIndication } from '../hooks/useHighImpactPackageIndication';
import { usePricingModelOptions } from './usePricingModelOptions';
import { usePricingModelState } from './usePricingModelState';
import styles from './bidControlCategory.module.scss';

const pricingModelToBidTypeComponent = {
    [PRICING_MODEL.CPC]: CPCBidType,
    [PRICING_MODEL.CPM]: CPMBidType,
    [PRICING_MODEL.VCPM]: VCPMBidType,
};

export const BidControlCategory = () => {
    const { pricingModel, onPricingModelChange } = usePricingModelState();
    const hasHighImpactPackage = useHighImpactPackageIndication();
    const { pricingModelOptions, selectedValue, isOptionDisabled } = usePricingModelOptions({
        pricingModel,
        hasHighImpactPackage,
    });

    const BidTypeComponent = pricingModelToBidTypeComponent[pricingModel];

    const descriptionMessageId =
        pricingModelOptions.length > 1
            ? 'campaign.editor.bid.description'
            : 'campaign.editor.bid.description.singleItem';

    return (
        <FormField
            inputId="bid"
            label={<FormattedMessage id="campaign.editor.bid.type.title" defaultMessage="Bid Type" />}
            description={
                <>
                    <FormattedMessage
                        id={descriptionMessageId}
                        defaultMessage="Select delivery method and bid amount for your campaign."
                    />
                    {hasHighImpactPackage ? (
                        <>
                            <br />
                            <FormattedMessage
                                id="campaign.editor.bid.highImpactException"
                                defaultMessage="When choosing a High Impact Package the pricing model must be vCPM."
                            />
                        </>
                    ) : null}
                </>
            }
            helpText={<BidTooltip hasHighImpactPackage={hasHighImpactPackage} />}
        >
            {pricingModelOptions.length > 1 && (
                <DropdownMenu
                    dropdownWrapperClassName={styles['dropdown-wrapper']}
                    selectedValueObject={selectedValue}
                    options={pricingModelOptions}
                    onChange={onPricingModelChange}
                    isOptionDisabled={isOptionDisabled}
                />
            )}
            <BidTypeComponent />
        </FormField>
    );
};
