import { useMemo } from 'react';
import { LanguageOutlinedIcon } from 'tuui';
import { isNetworkAccountType } from '../../../modules/account-management';
import { isDSPAccount } from '../../../modules/account-management/accountType';
import { YahooIconWrapper } from './YahooIconWrapper';

const iconList = [
    {
        condition: ({ type }) => isNetworkAccountType(type),
        component: () => <LanguageOutlinedIcon fontSize="small" name="publisher" />,
    },
    {
        condition: isDSPAccount,
        component: YahooIconWrapper,
    },
];

export const AccountItemIcon = ({ className, hasOuterLink, ...data }) => {
    const icon = useMemo(() => {
        const item = iconList.find(({ condition }) => condition(data));

        if (!item) {
            return;
        }

        const Component = item.component;

        return (
            <div className={className}>
                <Component {...data} hasOuterLink={hasOuterLink} />
            </div>
        );
    }, [data, className, hasOuterLink]);

    return icon || null;
};
