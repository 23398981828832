import { FORM_MODES } from 'config/formModes';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';

export const useConversionRulesValidationDependencies = ({
    selectedRules,
    mode,
    isCampaignConversionRulesEnabled,
    marketingObjective,
    conversionRulesMode,
}) => {
    const { [COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_NUMBER_OF_CONVERSIONS_THRESHOLD]: numberOfConversionThreshold } =
        useCommonConfig([COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_NUMBER_OF_CONVERSIONS_THRESHOLD]);
    const isEditMode = mode === FORM_MODES.EDIT;
    const isPixelWarningPermitted = usePermissions('TA_PIXEL_IMPLEMENTING_WARNINGS');
    const { trackingStateData } = useTrackingState(isPixelWarningPermitted);
    const disabledThresholdWarning = useConfigMatch({
        [FEATURE_FLAGS.DISABLED_CLC_THRESHOLD_WARNING]: 'true',
    });
    return {
        selectedRules,
        isEditMode,
        numberOfConversionThreshold: parseFloat(numberOfConversionThreshold),
        trackingStateData,
        isPixelWarningPermitted,
        isCampaignConversionRulesEnabled,
        marketingObjective,
        mode,
        conversionRulesMode,
        disabledThresholdWarning,
    };
};
