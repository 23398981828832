import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from '../../../../../../services/campaignDimension';
import { useCustomContextualPermitted } from '../../../../../audiences/hooks/useCustomContextualPermitted';
import { FormButtons } from '../../../../components/Form';
import { REPORT_TYPE } from '../../../../config';
import useCampaignBreadcrumbs from '../../../../hooks/useCampaignBreadcrumbs';
import {
    AudienceTargetingSection,
    AudienceTargetingSectionLegacy,
    CampaignCreatorFormButtons,
    ContextualTargetingSection,
    FormBreadcrumbs,
} from '../../../common-campaign-form/components';
import { videoCampaignInitialValues } from '../../../common-campaign-form/config';
import { useCampaignCreatorState } from '../../../common-campaign-form/hooks';
import { SetupSection, InventorySection, TargetingSection, BudgetSection, ScheduleSection } from '../../components';
import styleConsts from '../../../../../../globalStyle/styleConsts.module.scss';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';
import styles from './videoCampaignCreator.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const VideoCampaignCreator = ({ onCancel }) => {
    // TODO: take campaign type from url params
    const dimension = CAMPAIGN_DIMENSION.VIDEO;
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'video.campaign.creator.new.campaign', defaultMessage: 'New Video Campaign' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN_VIDEO,
        title,
    });

    const isCustomContextualPermitted = useCustomContextualPermitted();
    const audiencesTargetingSectionWithWizard = isCustomContextualPermitted ? (
        <>
            <WizardStep
                label={
                    <FormattedMessage id="campaign.editor.contextual.targeting" defaultMessage="Contextual Targeting" />
                }
                id="contextual-targeting"
            >
                <ContextualTargetingSection />
            </WizardStep>
            <WizardStep
                label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
                id="audiences-targeting"
            >
                <AudienceTargetingSection />
            </WizardStep>
        </>
    ) : (
        <WizardStep
            label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
            id="audiences-targeting"
        >
            <AudienceTargetingSectionLegacy />
        </WizardStep>
    );

    const { step, nextStep, prevStep, handleSetStep, submit, handleSubmitCampaignAndNavigateToCreative } =
        useCampaignCreatorState({
            dimension,
        });

    const { formAccount } = useFormDataContext();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="campaign.creator.campaign.settings.step.label"
                            defaultMessage="Campaign Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                            id="setup"
                        >
                            <SetupSection isAccountSelectionEnabled />
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.schedule" defaultMessage="Schedule" />}
                            id="schedule"
                        >
                            <ScheduleSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage id="campaign.editor.targeting" defaultMessage="Campaign Targeting" />
                            }
                            id="targeting"
                        >
                            <TargetingSection />
                        </WizardStep>
                        {audiencesTargetingSectionWithWizard}
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="video.campaign.editor.inventory"
                                    defaultMessage="Campaign Inventory"
                                />
                            }
                            id="inventory"
                        >
                            <InventorySection account={formAccount} />
                        </WizardStep>
                        <FormButtons
                            onSubmit={nextStep}
                            onCancel={onCancel}
                            submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                            submitIcon={<ChevronRightOutlinedIcon />}
                            submitDataMetricsComponent="NextButton"
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>

                <WizardStep
                    label={
                        <FormattedMessage id="campaign.creator.budget.bidding" defaultMessage="Budget and Bidding" />
                    }
                    id="budgeting"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.budget" defaultMessage="Budget" />}
                            id="budget"
                        >
                            <BudgetSection account={formAccount} />
                        </WizardStep>
                        <CampaignCreatorFormButtons
                            onSubmit={submit}
                            onSubmitAndAddCreatives={handleSubmitCampaignAndNavigateToCreative}
                            onBack={prevStep}
                            onCancel={onCancel}
                            disableSubmit={false}
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

VideoCampaignCreator.propTypes = { onCancel: PropTypes.func };

const VideoCampaignCreatorWithFormDataProvider = withFormDataProvider(VideoCampaignCreator, {
    defaultValuesConfig: videoCampaignInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { VideoCampaignCreatorWithFormDataProvider as VideoCampaignCreator };
