'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'aspect-ratio-wrapper': 'aspectRatioKeeper__aspect-ratio-wrapper___N8EtL',
    'cover': 'aspectRatioKeeper__cover___3VD6e'
};


var classNameBuilder = _bind2.default.bind(styles);

var AspectRatioKeeper = function AspectRatioKeeper(_ref) {
    var children = _ref.children,
        wrapperClass = _ref.wrapperClass,
        coverClass = _ref.coverClass,
        containerRef = _ref.containerRef;

    var wrapperClassName = classNameBuilder('aspect-ratio-wrapper', wrapperClass);
    var coverClassName = classNameBuilder('cover', coverClass);

    return _react2.default.createElement(
        'div',
        { className: wrapperClassName, ref: containerRef },
        _react2.default.createElement(
            'div',
            { className: coverClassName },
            children
        )
    );
};

AspectRatioKeeper.propTypes = {
    /** Linked component */
    children: _propTypes2.default.node,
    /** Class added to style/change element aspect ratio */
    wrapperClass: _propTypes2.default.string,
    /** Class added to style cover element */
    coverClass: _propTypes2.default.string,
    /** Class added to reference element */
    containerRef: _propTypes2.default.func
};

AspectRatioKeeper.defaultProps = {
    containerRef: function containerRef() {}
};

exports.default = AspectRatioKeeper;