import React from 'react';
import { useBidValidations } from 'modules/campaigns/modules/common-campaign-form/components/Bid/useBidValidations';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import PRICING_MODEL from '../../../../config/pricingModel';
import SimpleCurrencyCellEditor from './SimpleCurrencyCellEditor';

export const BidCellEditor = props => {
    const { data } = props;
    const { pricingModel = PRICING_MODEL.CPC } = data;
    const validations = useBidValidations(pricingModel, data);
    return <SimpleCurrencyCellEditor {...props} {...validations} />;
};

export default withGridCellFormDataProvider(BidCellEditor);
