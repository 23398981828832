import { isEmpty } from 'lodash';
import TARGETING_TYPES from '../../config/TargetingTypes';
import { generateValidations } from './audiencesValidationsGenerator';

const excludeErrorListValidations = [
    {
        validationFn: (values, options, { leavesByIdMap = {} }) => {
            if (isEmpty(leavesByIdMap)) {
                return true;
            }

            return values.every(id => leavesByIdMap[id]?.isActive ?? true);
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.inactiveItemInList',
        defaultMessage: 'Some of the targeted audiences are not available for targeting. Please remove them to proceed',
    },
    {
        validationFn: (values, options, { countriesMap = {}, countryTargetingType, leavesByIdMap = {} }) => {
            const isInclude = countryTargetingType === TARGETING_TYPES.INCLUDE;
            if (!isInclude || isEmpty(countriesMap) || isEmpty(leavesByIdMap)) {
                return true;
            }

            return values.every(id => {
                if (leavesByIdMap[id]) {
                    const { allowedCountries } = leavesByIdMap[id];
                    return allowedCountries && allowedCountries.some(code => countriesMap[code]);
                }
                return true;
            });
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.noIncludedCountriesInList',
        defaultMessage:
            'Some of the targeted audiences are not available in the targeted countries. Please remove them to proceed',
    },
    {
        validationFn: (values, options, { countriesMap = [], countryTargetingType, leavesByIdMap = {} }) => {
            const isExclude = countryTargetingType === TARGETING_TYPES.EXCLUDE;
            if (!isExclude || isEmpty(countriesMap) || isEmpty(leavesByIdMap)) {
                return true;
            }

            return values.every(id => {
                if (leavesByIdMap[id]) {
                    const { allowedCountries } = leavesByIdMap[id];
                    return allowedCountries && !allowedCountries.every(code => countriesMap[code]);
                }
                return true;
            });
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.allExcludedCountriesInList',
        defaultMessage:
            'Some of the targeted audiences are not available in the targeted countries. Please remove them to proceed',
    },
];

const excludeWarningListValidations = [];

const includeErrorListValidations = [...excludeErrorListValidations];

const includeWarningListValidations = [
    ...generateValidations('contextualSegmentsValuesLength', [
        'myAudienceIncludeValuesLength',
        'marketPlaceAudienceValuesLength',
    ]),
];

const itemValidations = [
    {
        validationFn: ([, , isActive]) => {
            return isActive;
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.inactiveItem',
        defaultMessage: 'This audience is not available for targeting',
    },
    {
        validationFn: ([allowedCountries = []], options, { countriesMap, countryTargetingType }) => {
            const isInclude = countryTargetingType === TARGETING_TYPES.INCLUDE;
            if (!isInclude || isEmpty(countriesMap)) {
                return true;
            }

            return allowedCountries.some(code => countriesMap[code]);
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.noIncludedCountriesInItem',
        defaultMessage: 'This audience is not available in the targeted countries',
    },
    {
        validationFn: ([allowedCountries = []], options, { countriesMap, countryTargetingType }) => {
            const isInclude = countryTargetingType === TARGETING_TYPES.INCLUDE;
            if (!isInclude || isEmpty(countriesMap)) {
                return true;
            }

            return allowedCountries.some(code => countriesMap[code]);
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.noIncludedCountriesInItem',
        defaultMessage: 'This audience is not available in the targeted countries',
    },
    {
        validationFn: ([allowedCountries = []], options, { countriesMap, countryTargetingType }) => {
            const isExclude = countryTargetingType === TARGETING_TYPES.EXCLUDE;
            if (!isExclude || isEmpty(countriesMap)) {
                return true;
            }

            return !allowedCountries.every(code => countriesMap[code]);
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.allExcludedCountriesInItem',
        defaultMessage: 'This audience is not available in the targeted countries',
    },
];

export {
    includeErrorListValidations,
    includeWarningListValidations,
    excludeErrorListValidations,
    excludeWarningListValidations,
    itemValidations,
};
