const RECOMMENDATION_TYPE = {
    BUDGET: 'BUDGET',
    BUDGET_V2: 'BUDGET_V2',
    AUDIENCE_RETARGETING: 'AUDIENCE_RETARGETING',
    AUDIENCE: 'AUDIENCE',
    LOW_RESOLUTION: 'LOW_RESOLUTION',
    ATTENTIVE_AUDIENCE: 'ATTENTIVE_AUDIENCE',
    ON_DEMAND: 'ON_DEMAND',
};

export default RECOMMENDATION_TYPE;
