import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { AlertOIcon, BlockIcon, OkOIcon, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import TargetingTypes from '../../../config/TargetingTypes';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import {
    CONTEXTUAL_MIN_SIZE,
    CONTEXTUAL_POSTFIX,
    MARKETPLACE_MIN_SIZE,
    getSizeContentRenderer,
} from '../../MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import styles from '../../commonAudienceListItemContent.module.scss';
import commonStyles from '../../commonAudienceListItemContent.module.scss';

const ContextualSegmentListItemContent = ({ item, deleteItem, indicationType, message }) => {
    const { targetingType, label = '', size = 0, articles, id, loading } = item;

    const handleOnClick = () => {
        deleteItem({ id, targetingType });
    };

    if (loading) {
        return (
            <ContentLoader width={580} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="12" rx="8" ry="8" width="250" height="12" />
                <rect x="510" y="12" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    return (
        <div className={commonStyles['container']}>
            {targetingType === TargetingTypes.INCLUDE ? (
                <OkOIcon className={commonStyles['include']} />
            ) : (
                <BlockIcon className={commonStyles['exclude']} />
            )}
            <AudienceDetails
                name={label}
                size={size}
                minSize={MARKETPLACE_MIN_SIZE}
                articles={articles}
                minArticles={CONTEXTUAL_MIN_SIZE}
                renderSizeContent={getSizeContentRenderer}
                showRemoveButton
                handleOnClick={handleOnClick}
                sizeIdPostfix={CONTEXTUAL_POSTFIX}
            />
            {!loading && indicationType && (
                <span className={commonStyles['error-icon-wrapper']} aria-label={`${label} ${indicationType} icon`}>
                    <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                    <Tooltip delay={800}>{message}</Tooltip>
                </span>
            )}
        </div>
    );
};

ContextualSegmentListItemContent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        targetingType: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
};

export default ContextualSegmentListItemContent;
