import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { EXPLORATION_STATUS, SITE_EDITOR_TYPE } from '../../../../config/siteConsts';
import BaseCellEditor from './BaseCellEditor';

export const SiteExplorationCellEditor = props => {
    const { onCancel, onSave } = props;

    const onSubmitHandle = value => {
        onSave({ type: SITE_EDITOR_TYPE.EXPLORATION_STATUS, value: EXPLORATION_STATUS.STOPPED });
    };

    return (
        <BaseCellEditor
            {...props}
            onCancel={onCancel}
            onSubmit={onSubmitHandle}
            fieldPath="explorationStatus"
            label={
                <FormattedMessage
                    id="app.campaigns.sites.site.exploration.label"
                    defaultMessage="End exploration for this site?"
                />
            }
            submitLabel={
                <FormattedMessage
                    id="app.campaigns.sites.site.exploration.button.label"
                    defaultMessage="End Exploration"
                />
            }
        >
            <div>
                <FormattedMessage
                    id="app.campaigns.sites.site.exploration.description"
                    defaultMessage="This site will no longer be prioritized to explore performance."
                />
            </div>
        </BaseCellEditor>
    );
};
