import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { OfflineBoltOutlinedIcon, ErrorOutlinedIcon } from 'tuui';
import { useSelectedAccount } from 'hooks';
import { RECOMMENDATIONS_STATE } from 'modules/recommendations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useSelectedCampaign } from '../../../../hooks/useSelectedCampaign';
import { PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE } from '../../config/performanceRecommendationIndicationType';
import { BaseRibbon } from './BaseRibbon/BaseRibbon';
import styles from './performanceRecommendationRibbon.module.scss';

const classNameBuilder = classnames.bind(styles);

const recommendationIndicationTypeToIcon = {
    [PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE.ACTION]: OfflineBoltOutlinedIcon,
    [PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE.WARNING]: ErrorOutlinedIcon,
};

export const PerformanceRecommendationRibbon = ({
    recommendation,
    recommendationIndicationType,
    isApplied,
    titleCode,
    descriptionCode,
    messageParams,
    children,
}) => {
    const [{ id: accountId }] = useSelectedAccount();
    const { campaign } = useSelectedCampaign();
    const { formatMessage } = useIntl();
    const { id } = recommendation;

    const CardIcon = recommendationIndicationTypeToIcon[recommendationIndicationType];

    return (
        <BaseRibbon recommendation={recommendation} recommendationIndicationType={recommendationIndicationType}>
            <div
                aria-label={formatMessage({ id: titleCode })}
                className={classNameBuilder('content')}
                data-metrics-visibility={GTM_EVENTS.VISIBLE}
                data-metrics-account-id={accountId}
                data-metrics-taboola-campaign-id={campaign.id}
                data-metrics-value={id}
                data-metrics-component="RecommendationRibbon"
                data-metrics-state={isApplied ? RECOMMENDATIONS_STATE.APPLIED : RECOMMENDATIONS_STATE.ACTIVE}
            >
                <div className={styles['details']}>
                    <CardIcon className={classNameBuilder('title-icon', recommendationIndicationType)} />
                    <div className={styles['header']}>
                        <FormattedMessage id={titleCode} defaultMessage="Should have a ribbon message header" />
                    </div>
                    <span>-</span>
                    <div className={styles['description']}>
                        <FormattedMessage
                            id={descriptionCode}
                            defaultMessage="Should have a ribbon message description"
                            values={messageParams}
                        />
                    </div>
                </div>
                <div className={styles['actions']}>{children}</div>
            </div>
        </BaseRibbon>
    );
};

PerformanceRecommendationRibbon.propTypes = {
    titleCode: PropTypes.string,
    descriptionCode: PropTypes.string,
    messageParams: PropTypes.object,
};
