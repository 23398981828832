import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import {
    DragAndDropFileContext,
    Gallery,
    AspectRatioKeeper,
    GalleryItem,
    TaboolaVideo,
    FallbackImage,
} from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { FormLayout } from 'modules/campaigns/components';
import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';
import { DropContainer } from 'modules/campaigns/modules/creative-creator/components/DropContainer/DropContainer';
import { ThumbnailsFieldTooltip } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField';
import ThumbnailError from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField/ThumbnailGallery/ThumbnailError/ThumbnailError';
import ThumbnailLoading from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import ExternalError from 'modules/campaigns/modules/creative-creator/components/ThumbnailsPicker/SelectedThumbnails/ExternalError';
import { VideoExternalError } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsPicker/SelectedThumbnails/VideoExternalError';
import { ONLY_MOTION_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { isFileTypeImage } from 'modules/campaigns/modules/creative-creator/services/utils';
import { WochitProvider } from 'modules/campaigns/modules/creative-creator/services/wochit';
import { MediaDragAndDrop } from 'modules/campaigns/modules/creative-editor/components/MediaDragAndDrop/MediaDragAndDrop';
import { MediaPicker } from 'modules/campaigns/modules/creative-editor/components/MediaPicker/MediaPicker';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import ReplaceImageTooltip from './ReplaceImageTooltip';
import styles from './thumbnailMotionEditorBody.module.scss';

const classNameBuilder = classnames.bind(styles);
const FallbackImageError = ({ error }) => (
    <div className={styles['fallback-image']}>
        {error ? (
            <ExternalError error={error} />
        ) : (
            <ThumbnailError
                messageId="creative.creator.thumbnails.fallbackImage.empty"
                defaultMessage="Choose default image"
            />
        )}
    </div>
);

const FallbackImageLoading = () => (
    <div className={styles['fallback-image']}>
        <ThumbnailLoading />
    </div>
);

const ThumbnailMotionEditorBody = ({
    campaignId,
    thumbnailStatus,
    setThumbnailStatus,
    videoStatus,
    setVideoStatus,
    formLayoutProps,
    headerLabel,
    disabled: isBulkEditMotionModeDisabled = false,
}) => {
    const [thumbnailError, setThumbnailError] = useState(null);
    const [videoError, setVideoError] = useState(null);
    const [editFallbackImageMode, setEditFallbackImageMode] = useState(false);
    const onFileDialogCancel = useCallback(() => setEditFallbackImageMode(false), [setEditFallbackImageMode]);

    const { fetchStatus, setSubmitStatus } = useFormDataContext();
    const { value: thumbnailUrl, onChange: onChangeThumbnailUrl } = useFormFieldValue({ field: 'thumbnailUrl' });
    const { value: videoUrl, onChange: onChangeVideoUrl } = useFormFieldValue({ field: 'videoUrl' });
    const { onChange: onChangeGifUrl } = useFormFieldValue({ field: 'gifUrl' });
    const { onChange: onChangeMediaUploadSource } = useFormFieldValue({ field: 'mediaUploadSource' });
    const { onChange: onChangeMotionAdsStudio } = useFormFieldValue({ field: 'motionAdsStudio' });

    const [lastSelectedId, setLastSelectedId] = useState();
    const selectedMedia = useMemo(() => [{ id: lastSelectedId }], [lastSelectedId]);
    const onSelectMedia = useCallback(
        async (urlResolver, metadata) => {
            const setMotion = ({ url, mediaUploadSource, motionAdsStudio, gifUrl }) => {
                onChangeVideoUrl(url);
                onChangeMediaUploadSource(mediaUploadSource);
                onChangeMotionAdsStudio(motionAdsStudio);
                onChangeGifUrl(gifUrl);
            };
            const setFallback = ({ cdnUrl, url }) => onChangeThumbnailUrl(cdnUrl || url);
            const [setStatus, setValue, setError] = isFileTypeImage(metadata.fileType)
                ? [setThumbnailStatus, setFallback, setThumbnailError]
                : [setVideoStatus, setMotion, setVideoError];

            setStatus(COMPONENT_STATUS.LOADING);
            try {
                const { url, ...dependentUrls } = await urlResolver();

                setLastSelectedId(metadata?.id);
                setValue({ ...metadata, ...dependentUrls, url, displayUrl: url });
                setStatus(COMPONENT_STATUS.ACTIVE);
                setError(null);
            } catch (error) {
                setStatus(COMPONENT_STATUS.ERROR);
                setError(error);
            }
            if (!metadata.dropped) {
                setEditFallbackImageMode(false);
            }
        },
        [
            setThumbnailStatus,
            setVideoStatus,
            onChangeVideoUrl,
            onChangeMediaUploadSource,
            onChangeMotionAdsStudio,
            onChangeThumbnailUrl,
            onChangeGifUrl,
            setEditFallbackImageMode,
        ]
    );

    const handleEditFallbackImageClick = openFileDialog => () => {
        if (isBulkEditMotionModeDisabled) {
            return;
        }
        if (thumbnailStatus === COMPONENT_STATUS.LOADING) {
            return;
        }
        setEditFallbackImageMode(true);
        openFileDialog();
    };

    const handleBrowseFilesClick = openFileDialog => () => {
        if (videoStatus === COMPONENT_STATUS.LOADING) {
            return;
        }
        setEditFallbackImageMode(false);
        openFileDialog();
    };

    useEffect(() => {
        const statuses = new Set([videoStatus, thumbnailStatus]);

        if (statuses.has(COMPONENT_STATUS.ACTIVE)) {
            setSubmitStatus(COMPONENT_STATUS.ACTIVE);
        } else if (statuses.has(COMPONENT_STATUS.LOADING) || statuses.has(COMPONENT_STATUS.ERROR)) {
            setSubmitStatus(COMPONENT_STATUS.LOADING);
        }
    }, [thumbnailStatus, videoStatus, setSubmitStatus]);

    return (
        <FormLayout
            className={styles['thumbnail-form']}
            header={headerLabel}
            {...formLayoutProps}
            contentContainerClassName={styles['thumbnail-video']}
        >
            {fetchStatus !== COMPONENT_STATUS.ACTIVE && <Spinner className={styles['overlay']} />}
            <WochitProvider>
                <MediaPicker
                    fileTypes={ONLY_MOTION_FILES}
                    value={modifyExternalUrl(videoUrl)}
                    onSelectMedia={onSelectMedia}
                    onCancel={onFileDialogCancel}
                    tabsContext={{ thumbnails: selectedMedia, campaignId }}
                    tabsConditionData={{ creativeType: CREATIVE_TYPE.MOTION }}
                    showFileBrowser
                    onBrowseFile={handleBrowseFilesClick}
                    clickZoneDescription={
                        <FormattedMessage
                            id="creative.drag.and.drop.clickable.text.upload.from.computer"
                            defaultMessage="upload from computer"
                        />
                    }
                    clickZoneTooltip={<ThumbnailsFieldTooltip />}
                    disabled={editFallbackImageMode || isBulkEditMotionModeDisabled}
                    tabsDisabled={editFallbackImageMode || isBulkEditMotionModeDisabled}
                >
                    <div className={classNameBuilder('creative-editor', { disabled: isBulkEditMotionModeDisabled })}>
                        {videoUrl && (
                            <>
                                <div className={styles['gallery-container']}>
                                    <DropContainer>
                                        <Gallery gap={styleConsts.galleryGridGap} minRowHeight="155px" columns={2}>
                                            <GalleryItem>
                                                <AspectRatioKeeper>
                                                    <TaboolaVideo
                                                        className={styles['video']}
                                                        src={modifyExternalUrl(videoUrl)}
                                                        status={videoStatus}
                                                        errorComponent={<VideoExternalError error={videoError} />}
                                                        loadingComponent={<ThumbnailLoading />}
                                                    />
                                                </AspectRatioKeeper>
                                            </GalleryItem>
                                            <GalleryItem>
                                                <AspectRatioKeeper>
                                                    <MediaDragAndDrop
                                                        onDrop={onSelectMedia}
                                                        onCancel={onFileDialogCancel}
                                                        inputId="motion-editor-file-input"
                                                    >
                                                        <DragAndDropFileContext.Consumer>
                                                            {({ openFileDialog }) => (
                                                                <FallbackImage
                                                                    imgSrc={modifyExternalUrl(thumbnailUrl)}
                                                                    loadingComponent={<FallbackImageLoading />}
                                                                    errorComponent={
                                                                        <FallbackImageError error={thumbnailError} />
                                                                    }
                                                                    status={thumbnailStatus}
                                                                    onEditFallbackImageClick={handleEditFallbackImageClick(
                                                                        openFileDialog
                                                                    )}
                                                                    editText={<ReplaceImageTooltip />}
                                                                />
                                                            )}
                                                        </DragAndDropFileContext.Consumer>
                                                    </MediaDragAndDrop>
                                                </AspectRatioKeeper>
                                            </GalleryItem>
                                        </Gallery>
                                    </DropContainer>
                                </div>
                            </>
                        )}
                    </div>
                </MediaPicker>
            </WochitProvider>
        </FormLayout>
    );
};

ThumbnailMotionEditorBody.propTypes = {
    url: PropTypes.string,
    setUrl: PropTypes.func,
    thumbnailLoading: PropTypes.bool,
    setThumbnailLoading: PropTypes.func,
    videoLoading: PropTypes.bool,
    setVideoLoading: PropTypes.func,
    headerLabel: PropTypes.node,
};

export default ThumbnailMotionEditorBody;
