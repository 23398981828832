import { useCallback } from 'react';
import { useCommonConfig } from '../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';

const parseCountries = input => {
    const countriesArray = input.split(',');
    return countriesArray
        .map(country => {
            const [value, label] = country.split(':');
            if (!value || !label) {
                return undefined;
            }
            return { label, value };
        })
        .filter(c => c !== undefined);
};

export const useSupportedCountries = configName => {
    const { [configName]: supportedCountriesFromConfig } = useCommonConfig([configName]);

    let supportedCountriesList = parseCountries(supportedCountriesFromConfig);

    const { value: country, onChange } = useFormFieldValue({
        field: 'country',
    });

    const onCountryChange = useCallback(
        selectedOption => {
            onChange(selectedOption.value);
        },
        [onChange]
    );

    const selectedCountry = supportedCountriesList.find(option => option.value === country) || null;

    return {
        selectedCountry,
        onCountryChange,
        supportedCountriesList,
    };
};
