import { generateBulkCreativeEditorPath } from 'modules/campaigns/services/utils';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { navigate } from '../../../../../actions';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP } from '../../../config';
import { cleanCreativesEditingResources } from '../../creative-editor/flows';
import { INITIAL_CREATIVE_TYPE } from '../../creative-editor/hooks/useInitialCreativeType';
import { BULK_EDIT_CREATIVES } from '../components/BulkActionToolbar/hooks/useBulkEditCreativeIds';
import { BULK_EDIT_CREATIVES_SHARED_VALUES } from '../components/BulkActionToolbar/hooks/useBulkEditCreativesSharedValues';

const getCreativeType = selectedRowsObj => {
    const firstItem = selectedRowsObj[Object.keys(selectedRowsObj)[0]];
    return PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP[firstItem.performanceStatus];
};

const bulkEditCreative =
    ({ history, accountId, selectedRowsIds, bulkEditCreativesSharedValues, selectedRows }) =>
    dispatch => {
        const bulkEditCreativePath = generateBulkCreativeEditorPath({
            accountId,
            dimension: CAMPAIGN_DIMENSION.SPONSORED,
        });
        const pathWithQuery = `${bulkEditCreativePath}${mergeQueryParams('', {
            [BULK_EDIT_CREATIVES]: selectedRowsIds,
            [BULK_EDIT_CREATIVES_SHARED_VALUES]: bulkEditCreativesSharedValues,
            [INITIAL_CREATIVE_TYPE]: getCreativeType(selectedRows),
        })}`;

        dispatch(cleanCreativesEditingResources());
        dispatch(navigate(history, pathWithQuery));
    };

export default bulkEditCreative;
