import React from 'react';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const UserFrequencyCappingFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.UserFrequencyCapping.tooltip"
            defaultMessage="Frequency cap for the number of times a user can see an ad from this campaign in daily, weekly and monthly time frames."
        />
    </TooltipSection>
);
