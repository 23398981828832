"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RADIUS = exports.RADIUS = 175;
var AXIS_OFFSET = exports.AXIS_OFFSET = -25;
var diameter = Math.round(Math.PI * RADIUS * 2);
var getOffset = exports.getOffset = function getOffset() {
  var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return Math.round((100 - Math.min(val, 100)) / 100 * diameter);
};