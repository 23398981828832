"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgArchive(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 16,
            height: 17,
            viewBox: "0 0 16 17",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M3 0C2.44772 0 2 0.447715 2 1C2 1.55228 2.44772 2 3 2H13C13.5523 2 14 1.55228 14 1C14 0.447715 13.5523 0 13 0H3Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9 10.9194L9.87531 10.2192C10.3066 9.87416 10.9359 9.94408 11.2809 10.3753C11.6259 10.8066 11.556 11.4359 11.1247 11.7809L8 14.2807L4.87531 11.7809C4.44404 11.4359 4.37412 10.8066 4.71913 10.3753C5.06414 9.94408 5.69343 9.87416 6.1247 10.2192L7 10.9194V8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8V10.9194Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M2 4H14C15.1046 4 16 4.89543 16 6V15C16 16.1046 15.1046 17 14 17H2C0.89543 17 0 16.1046 0 15V6C0 4.89543 0.89543 4 2 4ZM2 6V15H14V6H2Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgArchive;