import React, { useMemo } from 'react';
import { useNavigate, useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useDeleteFunnel } from 'modules/tracking/hooks/useDeleteFunnel';
import { generateConversionFunnelEditorPath } from 'modules/tracking/utils/generateTrackingPath';
import ActionsCellRenderer from './ActionsCellRenderer';

export const FunnelActionsCellRenderer = ({ data, context, ...rest }) => {
    const deleteFunnel = useDeleteFunnel();
    const navigate = useNavigate();
    const [{ accountName }] = useSelectedAccount();
    const { accountName: currFunnelAccountName } = data;
    const isDisabled = useMemo(() => accountName !== currFunnelAccountName, [accountName, currFunnelAccountName]);
    const buttonConfigs = [
        {
            onClick: () => {
                navigate(generateConversionFunnelEditorPath(currFunnelAccountName, data.id));
            },
            isDisabled,
            dataMetricComponent: 'editFunnelActionButton',
            iconLabel: 'edit-o',
            tooltipContent: <FormattedMessage id="tracking.funnels.actionButtons.edit" defaultMessage="Edit Funnel" />,
        },
        {
            onClick: () => deleteFunnel(data),
            isDisabled,
            dataMetricComponent: 'deleteFunnelActionButton',
            iconLabel: 'delete',
            tooltipContent: (
                <FormattedMessage id="tracking.funnels.actionButtons.delete" defaultMessage="Delete Funnel" />
            ),
        },
    ];
    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};
