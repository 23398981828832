import React, { useCallback, useMemo } from 'react';
import moment from 'moment/moment';
import { CompassIcon } from 'tuui';
import {
    DeletableItem,
    INDICATION_TYPES,
    TooltipV2 as Tooltip,
    useCollapsibleListContext,
    AlertOIcon,
} from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { isValidItem } from '../../SiteTargeting/utils/isValidItem';
import { EXPLORATION_STATUS } from '../siteExplorationConstants';
import commonStyles from '../../commonAudienceListItemContent.module.scss';
import styles from './SiteExploartionListItem.module.scss';

export const SiteExplorationListItem = ({ item }) => {
    const { formatMessage } = useIntl();
    const {
        deleteItem,
        metadata: { isAllowedSitesReady, readonly, allowedSites = {} },
    } = useCollapsibleListContext();
    const { label = '', loading } = item;

    const { accountId, accountDescription, explorationStatus, endDateUtc } = item;
    const leftDays = moment(endDateUtc).diff(moment(), 'day') + 1;
    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);
    const { indicationType, message } = useMemo(() => {
        if (!isAllowedSitesReady || isValidItem({ item, allowedSites, readonly })) {
            return {};
        }
        return {
            indicationType: INDICATION_TYPES.WARNING,
            message: (
                <FormattedMessage id="campaign.editor.targeting.site.invalidItem" defaultMessage="Unavailable site" />
            ),
        };
    }, [isAllowedSitesReady, allowedSites, item, readonly]);

    const buttonLabel = formatMessage(
        {
            id: `campaign.editor.site.exploration.item.delete`,
            defaultMessage: `Delete site: {label}`,
        },
        { label: accountId }
    );
    const explorationStatusLabel = explorationStatus
        ? formatMessage({
              id: `campaign.editor.site.exploration.details.exploration.status.${explorationStatus}`,
              defaultMessage: '',
          })
        : '';

    const pendingLabel = formatMessage({
        id: 'campaign.editor.site.exploration.pending.label',
        defaultMessage: 'Pending',
    });

    const isSiteInExploring = explorationStatus === EXPLORATION_STATUS.IN_PROGRESS || !explorationStatus;

    return (
        <DeletableItem
            onDelete={onDelete}
            isItemDeletable={isSiteInExploring && !loading}
            buttonProps={{ 'aria-label': buttonLabel, 'data-testid': buttonLabel }}
        >
            <div className={styles['container']}>
                <div className={styles['icon-container']}>
                    <CompassIcon />
                </div>
                <div className={styles['content']}>
                    <div className={styles['label']}>{accountDescription}</div>
                </div>
                <div className={styles['status']}>
                    {!explorationStatus ? (
                        pendingLabel
                    ) : (
                        <>
                            {explorationStatusLabel}
                            {isSiteInExploring && (
                                <FormattedMessage
                                    id="campaign.editor.site.exploration.details.left.days"
                                    defaultMessage=" | {leftDays}D left "
                                    values={{ leftDays }}
                                />
                            )}
                        </>
                    )}
                </div>
                {!loading && indicationType && (
                    <div className={styles['detail']}>
                        <span
                            className={commonStyles['error-icon-wrapper']}
                            aria-label={`${label} ${indicationType} icon`}
                        >
                            <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                            <Tooltip delay={800}>{message}</Tooltip>
                        </span>
                    </div>
                )}
            </div>
        </DeletableItem>
    );
};
