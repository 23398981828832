import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { useAvailableItemsService, useShallowObject, useAccountAdditionalData } from 'hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { excelConfig } from '../config/excelConfig';
import { useExcelBulkUploadConfigs } from '../utils/useExcelBulkUploadConfigs';

export const useExcelConfig = ({ config = excelConfig, conditionData: conditionDataProp } = {}) => {
    const selectedAccount = useAccount();
    const conditionDataPropMemo = useShallowObject(conditionDataProp);
    const { accountAdditionalData: { advertiserGroupTier } = {} } = useAccountAdditionalData();
    const conditionData = useMemo(
        () => ({ selectedAccount, advertiserGroupTier, ...conditionDataPropMemo }),
        [selectedAccount, conditionDataPropMemo, advertiserGroupTier]
    );
    const { isFullConfigAvailable } = useExcelBulkUploadConfigs();
    const availableItemsHandler = useAvailableItemsService(conditionData);
    const availableConfig = useMemo(() => {
        const newConfig = cloneDeep(config);

        if (isFullConfigAvailable) {
            newConfig.entityList.forEach(entity => {
                entity.columns.forEach(column => {
                    if (column.options) {
                        column.options = column.options.map(({ id }) => id);
                    }
                });
            });

            return newConfig;
        }

        newConfig.entityList = availableItemsHandler(newConfig.entityList);
        newConfig.entityList = newConfig.entityList.map(entity => {
            const availableColumns = availableItemsHandler(entity.columns);
            availableColumns.forEach(column => {
                if (column.options) {
                    column.options = availableItemsHandler(column.options).map(({ id }) => id);
                }
            });

            return { ...entity, columns: availableColumns };
        });

        return newConfig;
    }, [config, availableItemsHandler, isFullConfigAvailable]);

    return availableConfig;
};
