import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormLayout } from 'modules/campaigns/components';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { isProgrammaticCampaign } from 'services/campaignDimension';
import { useSelectedAccount } from '../../../../hooks';
import { CAMPAIGNS_ROUTE, CREATIVE_TYPE, FORM_MODES, REPORT_TYPE } from '../../config';
import { campaignsMapSelector } from '../../selectors';
import { UnknownCreativeType } from '../creative-creator/components/UnknownCreativeType/UnknownCreativeType';
import { CreativeEditorForm, CreativeEditorLoader, CreativeMotionEditorForm } from './components';
import { useRecentlyUsedMediaSubTabTooltipShowing } from './components/MediaTabs/MediaLibraryTab/hooks/useRecentlyUsedMediaSubTabTooltipShowing';
import { UnifiedCreativeEditorForm } from './components/UnifiedCreativeEditor/Form/UnifiedCreativeEditorForm';
import { FORM_MODE_CONFIG } from './config';
import { creativeInitialValues } from './config/creativeInitialValues';
import { useCreativeEditorState } from './hooks/useCreativeEditorState';
import { useUnifiedCreativeEditorFormConfig } from './hooks/useUnifiedCreativeEditorFormConfig';
import styles from './creativeEditor.module.scss';

const classNameBuilder = classnames.bind(styles);

const creativeEditorComponentMap = {
    [CREATIVE_TYPE.MOTION]: CreativeMotionEditorForm,
    [CREATIVE_TYPE.IMAGE]: CreativeEditorForm,
};

const CreativeEditor = ({
    onCancel,
    mode,
    match,
    sourcePath,
    getSubmitDataMetricAttrs,
    addIdToMatchingCreativeIds,
    hideStatusSection,
    showSetupSection,
    reportConfigMap,
}) => {
    const modeConfig = FORM_MODE_CONFIG[mode];
    const [selectedAccount] = useSelectedAccount();
    const { accountId: creativeAccountId, campaignId, creativeId } = match.params;
    const campaignMap = useSelector(campaignsMapSelector);
    const campaign = campaignMap[campaignId];
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    const { submit } = useCreativeEditorState({
        creativeAccountId,
        campaignId,
        creativeId,
        sourcePath,
    });
    const { data: creative, isSubmitDisabled, isFormTouched } = useFormDataContext();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const [showingGenAiTooltip] = useRecentlyUsedMediaSubTabTooltipShowing();

    const reportConfig = reportConfigMap[REPORT_TYPE.CREATIVE];

    // TODO: Add RSS child check here once it's implemented
    if (isProgrammaticCampaign(campaign?.type)) {
        return <Redirect to={CAMPAIGNS_ROUTE} />;
    }

    const commonProps = {
        accountId: creativeAccountId,
        creativeId,
        campaign,
        onCancel,
        match,
        hideStatusSection,
        showSetupSection,
        selectedAccount,
        reportConfig,
        addIdToMatchingCreativeIds,
        campaignId,
    };

    const Component = isUnifiedCreativesFormDuplicateMode
        ? UnifiedCreativeEditorForm
        : creativeEditorComponentMap[creativeType] || UnknownCreativeType;

    return (
        <FormInitBase
            loadingComponent={() => (
                <CreativeEditorLoader onCancel={onCancel} submitButtonLabel={modeConfig.submitButtonLabel} />
            )}
        >
            <FormLayout
                contentContainerClassName={styles['content-container']}
                bodyContainerClassName={styles['content-body']}
                footerContainerClassName={classNameBuilder('footer', { 'cover-tooltip': showingGenAiTooltip })}
                onCancel={onCancel}
                onSubmit={submit}
                disableSubmit={isSubmitDisabled || (!isFormTouched && mode !== FORM_MODES.DUPLICATE)}
                submitButtonText={modeConfig.submitButtonLabel}
                submitDataMetricAttrs={getSubmitDataMetricAttrs({ creative })}
            >
                <Component {...commonProps} {...modeConfig} />
            </FormLayout>
        </FormInitBase>
    );
};

CreativeEditor.propTypes = {
    onCancel: PropTypes.func,
    mode: PropTypes.string,
    saveVideoCreativeFlow: PropTypes.func,
    saveCreativeFlow: PropTypes.func,
    match: PropTypes.object,
    sourcePath: PropTypes.string.isRequired,
    getSubmitDataMetricAttrs: PropTypes.func,
    headerLabel: PropTypes.node,
    addIdToMatchingCreativeIds: PropTypes.bool,
    hideStatusSection: PropTypes.bool,
    showSetupSection: PropTypes.bool,
};

CreativeEditor.defaultProps = {
    mode: PropTypes.oneOf(Object.values(FORM_MODES)),
    reportConfigMap: {},
    getSubmitDataMetricAttrs: noop,
};

const creativeEditorWithFormDataProvider = withFormDataProvider(CreativeEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    defaultValuesConfig: creativeInitialValues,
});

export { creativeEditorWithFormDataProvider as CreativeEditor };
