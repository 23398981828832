import React, { useCallback } from 'react';
import { FORM_MODES } from 'config/formModes';
import { useAppInstallFormat } from 'modules/campaigns/modules/creative-creator/hooks/useAppInstallFormat';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { AccountProvider } from 'modules/taboola-common-frontend-modules/account-configurations/AccountProvider';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { CreativeStudioContent } from './CreativeStudioContent';
import styles from './useCreativeStudioModal.module.scss';

export const useCreativeStudioModal = () => {
    const { open: openModal, close: closeModal } = useModal();
    const { formAccount, mode: formMode } = useFormDataContext();
    const isAppInstallInCreateMode = useAppInstallFormat();
    const { isAppInstall: isAppInstallInEditMode } = useIsAppInstallCreativeInEditMode();
    const shouldSkipCrop = isAppInstallInCreateMode || isAppInstallInEditMode || formMode === FORM_MODES.BULK_EDIT;

    const openCreativeStudioModal = useCallback(
        async ({
            src,
            mode,
            accountId,
            campaignId,
            addImageHandler,
            closeCreativeStudioModal,
            originalItem,
            urlTransformer,
        }) => {
            return await openModal({
                title: null,
                contentRenderer: props => (
                    <AccountProvider accountId={formAccount.id}>
                        <CreativeStudioContent
                            src={src}
                            mode={mode}
                            campaignId={campaignId}
                            accountId={accountId}
                            addImageHandler={addImageHandler}
                            closeCreativeStudioModal={closeCreativeStudioModal}
                            originalItem={originalItem}
                            urlTransformer={urlTransformer}
                            formAccount={formAccount}
                            shouldSkipCrop={shouldSkipCrop}
                            {...props}
                        />
                    </AccountProvider>
                ),
                className: styles['modal'],
                contentClassName: styles['modal-content'],
                headerClassName: styles['modal-header'],
            });
        },
        [formAccount, openModal, shouldSkipCrop]
    );

    return { openModal: openCreativeStudioModal, closeModal };
};
