import { validationFunctions } from '../../../../../../taboola-common-frontend-modules/validations';

const nameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: `video.creative.creator.validations.name.empty`,
        defaultMessage: 'Enter a Video Creative name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 100 },
        messageId: `video.creative.creator.validations.error.name.length`,
        defaultMessage: 'Video Creative name can not exceed 100 characters',
    },
];

export default nameValidations;
