import React, { useState, useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CollapsibleList, useDebouncedValue, useElementSize } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { useSendSegmentSearchEvent } from '../../../utils/useSendSegmentSearchEvent';
import {
    excludeErrorListValidations,
    excludeWarningListValidations,
    includeErrorListValidations,
    includeWarningListValidations,
} from '../../MarketplaceAudiences/MarketplaceAudienceValidations';
import { MarketplaceAudiencesProvider } from '../../MarketplaceAudiences/MarketplaceAudiencesContext';
import { useMarketplaceAudiencesTargetingFieldNames } from '../../MarketplaceAudiences/MarketplaceAudiencesTargeting/hooks/useMarketplaceAudienceTargetingFieldNames';
import ProvidersDropdown from '../../MarketplaceAudiences/ProvidersDropdown/ProvidersDropDown';
import { useProvidersApi, useProvidersState, useSelectedSegments } from '../../MarketplaceAudiences/hooks';
import { TargetingTypeBareDropdown } from '../../TargetingTypeDropdown';
import { LoadingMode, MAX_SEGMENTS_TO_SELECT } from '../../Tree';
import { useBundleData } from '../../Tree/hooks/useBundleData';
import SegmentListItemContentWithBundling from '../SegmentListContentWithBundling/SegmentListItemContentWithBundling';
import { SegmentsDropdownWithBundling } from '../SegmentsDropdownWithBundling/SegmentsDropdownWithBundling';
import { useBundleNodeHandlersWithMetrics } from '../hooks/useBundleNodeHandlersWithMetrics';
import { useMarketplaceAudiencesBundleTreeState } from '../hooks/useMarketplaceAudiencesBundleTreeState';
import { useMarketplaceAudiencesWithBundlingEffects } from '../hooks/useMarketplaceAudiencesWithBundlingEffects';
import { useSegmentsWithBundlingApi } from '../hooks/useSegmentsWithBundlingApi';
import commonStyles from '../../commonEditor.module.scss';
import styles from './marketplaceAudiencesTargetingWithBundling.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
export const MarketplaceAudiencesTargetingWithBundling = ({ selectedAccountId, isAdvancedSearch }) => {
    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const [targetingType, setTargetingType] = useState(TARGETING_TYPES.INCLUDE);
    const [searchText, setSearchText] = useState('');
    const { unbundledNodes, unbundleNode, getIsNodeBundled } = useBundleData();
    const search = useDebouncedValue(searchText.length > 2 ? searchText : '');

    const { handleAddMissingTargetingGroup, includeIdsFormFieldName, excludeIdsFormFieldName } =
        useMarketplaceAudiencesTargetingFieldNames();

    const { providers, reloadProviders, isProvidersReady } = useProvidersApi({ selectedAccountId });
    const {
        selectedProviders,
        isProviderEnabled,
        getPartnerByProvider,
        enabledPartnerGroup,
        handleChangeSelectedProviders,
        handleChangeEnabledProviders,
        setDefaultEnabledProviders,
        enabledProvidersMap,
    } = useProvidersState({ providers });

    const { segments, fullSegments, ...treeStateParams } = useSegmentsWithBundlingApi({
        search,
        unbundledNodes,
        providers: selectedProviders,
        selectedAccountId,
        isAdvancedSearch,
    });

    const {
        selectedNodes,
        handleRemoveItem,
        handleReload,
        setSelectedNodesByIds,
        handleSelectNode,
        handleSelectAll,
        expandAll,
        setIsReady,
        ...treeProps
    } = useMarketplaceAudiencesBundleTreeState({
        search,
        nodes: segments,
        fullNodes: fullSegments,
        ...treeStateParams,
    });

    const {
        includeLeaves,
        excludeLeaves,
        removeIncludeLeaves,
        removeExcludeLeaves,
        includeFailedValidationData,
        excludeFailedValidationData,
        scrollRef,
    } = useSelectedSegments({
        targetingType,
        selectedLeaves: selectedNodes,
        setSelectedLeavesByIds: setSelectedNodesByIds,
        includeIdsFormFieldName,
        excludeIdsFormFieldName,
        onAddIds: handleAddMissingTargetingGroup,
        enabledProvidersMap,
        includeErrorListValidations,
        includeWarningListValidations,
        excludeErrorListValidations,
        excludeWarningListValidations,
        setIsReady,
        isReadyToValidate: treeProps.isReady && isProvidersReady,
    });

    useMarketplaceAudiencesWithBundlingEffects({
        handleReload,
        reloadProviders,
        handleChangeEnabledProviders,
        selectedNodes,
    });

    useSendSegmentSearchEvent({
        component: 'Marketplace Audiences Segments Search',
        searchText: search,
        resultsCount: segments.length,
        providers: selectedProviders,
        isLoading: treeProps.loadingMode !== LoadingMode.NONE,
    });

    const { handleSelectNodeWrapper, handleSelectAllWrapper } = useBundleNodeHandlersWithMetrics({
        handleSelectNode,
        handleSelectAll,
        search,
        selectedNodes,
        setDefaultEnabledProviders,
    });

    const { width, ref } = useElementSize();
    const menuStyles = useMemo(() => ({ width }), [width]);

    return (
        <div className={styles['container']} data-testid="marketplace-audiences-targeting">
            <div className={styles['dropdown-container']} ref={ref}>
                <MarketplaceAudiencesProvider
                    limit={MAX_SEGMENTS_TO_SELECT}
                    handleSelectNode={handleSelectNodeWrapper}
                    handleSelectAll={handleSelectAllWrapper}
                    isProviderEnabled={isProviderEnabled}
                    getPartnerByProvider={getPartnerByProvider}
                    enabledPartnerGroup={enabledPartnerGroup}
                    campaignId={campaignId}
                    getIsNodeBundled={getIsNodeBundled}
                    unbundleNode={unbundleNode}
                    nodes={segments}
                    {...treeProps}
                >
                    <TargetingTypeBareDropdown
                        onChange={({ value }) => {
                            setTargetingType(value);
                        }}
                        value={targetingType}
                    />
                    <div className={styles['delimiter']} />
                    <SegmentsDropdownWithBundling
                        search={searchText}
                        onSearchChange={setSearchText}
                        menuStyles={menuStyles}
                    />
                    <ProvidersDropdown
                        selectedValues={selectedProviders}
                        options={providers}
                        onChange={providerIds => handleChangeSelectedProviders(providerIds)}
                    />
                </MarketplaceAudiencesProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting.include"
                data-testid="marketplace-audiences-targeting-include-list"
                items={includeLeaves}
                ItemComponent={SegmentListItemContentWithBundling}
                itemHeight={70}
                metadata={{
                    targetingType: TARGETING_TYPES.INCLUDE,
                    field: includeIdsFormFieldName,
                    enabledProvidersMap,
                    isProvidersReady,
                }}
                deleteItem={handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.marketplace.include.audiences.title"
                        defaultMessage="Included Marketplace Audiences"
                    />
                }
                clearItems={removeIncludeLeaves}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...includeFailedValidationData}
                indicationContainerClass={commonStyles['warning-indication']}
                ref={scrollRef}
            />
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting.exclude"
                data-testid="marketplace-audiences-targeting-exclude-list"
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.marketplace.exclude.audiences.title"
                        defaultMessage="Excluded Marketplace Audiences"
                    />
                }
                items={excludeLeaves}
                ItemComponent={SegmentListItemContentWithBundling}
                itemHeight={70}
                metadata={{
                    targetingType: TARGETING_TYPES.EXCLUDE,
                    field: excludeIdsFormFieldName,
                    enabledProvidersMap,
                    isProvidersReady,
                }}
                addItem={noop}
                deleteItem={handleRemoveItem}
                clearItems={removeExcludeLeaves}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...excludeFailedValidationData}
            />
        </div>
    );
};

MarketplaceAudiencesTargetingWithBundling.propTypes = {
    selectedAccountId: PropTypes.string,
};
