import { isEqual, union } from 'lodash';

export default (newObject, baseObject) => {
    const keys = union(Object.keys(newObject), Object.keys(baseObject));

    return keys.reduce(
        (res, key) => (isEqual(newObject[key], baseObject[key]) ? res : { ...res, [key]: newObject[key] }),
        {}
    );
};
