import React, { useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Textarea } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { oneByOneURLValidations } from 'modules/campaigns/config/validations/creative';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { withInputValidations } from 'modules/taboola-common-frontend-modules/validations';
import { splitOnNewLine } from 'services/utils/stringUtils';
import BulkUrlsFieldErrorMessage from './BulkUrlsFieldErrorMessage';
import styles from './bulkUrlsField.module.scss';

const classNameBuilder = classnames.bind(styles);

// error and errorMsg need to be removed in order to avoid them being passed to Textarea and causing an error
const sanitizedWithValidationsTextarea = ({ error, errorMsg, indicationType, ...rest }) => <Textarea {...rest} />;

const TextareaWithValidations = withInputValidations(sanitizedWithValidationsTextarea, oneByOneURLValidations, {
    onChange: { validateEventValue: true, eventValueGetter: e => e.target.value },
    onBlur: { validateEventValue: true, eventValueGetter: e => e.target.value },
});

const BulkUrlsField = ({ urls = '', onUrlsChange, updateValidatedUrls, contentMethod, HelpTooltipComponent }) => {
    const [error, setError] = useState();

    const handleInputErrorStateChange = incomingError => {
        setError(incomingError);
    };

    const allUrlsList = splitOnNewLine(urls, { removeWhitespace: true });

    const validUrlsList = allUrlsList.filter(url => validationFunctions.isURL(url));

    const hasInvalidUrls = validUrlsList.length < allUrlsList.length;

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.creator.creation.mode.ONE_BY_ONE.urls.placeholder',
        defaultMessage: 'www.example1.com{br}www.example2.com{br}www.example3.com',
    });

    return (
        <FormField
            label={<FormattedMessage id={`creative.creator.${contentMethod}.urls.title`} />}
            containerClass={styles['form-container']}
            helpText={<HelpTooltipComponent />}
            description={<FormattedMessage id={`creative.creator.creation.mode.${contentMethod}.urls.description`} />}
        >
            <TextareaWithValidations
                id="bulkUrls"
                onChange={e => onUrlsChange(e.target.value)}
                onBlur={() => updateValidatedUrls(validUrlsList)}
                value={urls}
                textareaClassName={classNameBuilder('textarea', {
                    'textarea-error': error,
                    'textarea-warning': !error && hasInvalidUrls,
                })}
                placeholder={placeholder}
                onErrorStateChange={handleInputErrorStateChange}
            />
            <BulkUrlsFieldErrorMessage error={error} hasInvalidUrls={hasInvalidUrls} />
        </FormField>
    );
};

BulkUrlsField.propTypes = {
    urls: PropTypes.string,
    onUrlsChange: PropTypes.func,
    updateValidatedUrls: PropTypes.func,
};

export { TextareaWithValidations };
export default BulkUrlsField;
