'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _DragAndDropFile = require('../../DragAndDropFile');

var _StyledButton = require('../StyledButton');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'file-input': 'fileDialogButton__file-input___2jsl7'
};

var FileDialogButton = function (_Component) {
    _inherits(FileDialogButton, _Component);

    function FileDialogButton(props) {
        _classCallCheck(this, FileDialogButton);

        var _this = _possibleConstructorReturn(this, (FileDialogButton.__proto__ || Object.getPrototypeOf(FileDialogButton)).call(this, props));

        _this.handleOpenDialog = function (e) {
            e.preventDefault();
            _this.input.current.click();
        };

        _this.handleFileSelect = function (event) {
            var onAddFiles = _this.props.onAddFiles;


            if (onAddFiles) {
                onAddFiles(event.target.files);
            }

            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
        };

        _this.input = _react2.default.createRef();
        return _this;
    }

    _createClass(FileDialogButton, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                buttonText = _props.buttonText,
                multiple = _props.multiple,
                fileType = _props.fileType,
                className = _props.className,
                onAddFiles = _props.onAddFiles,
                rest = _objectWithoutProperties(_props, ['buttonText', 'multiple', 'fileType', 'className', 'onAddFiles']);

            return _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                    _StyledButton.StyledButton,
                    Object.assign({}, rest, {
                        className: className,
                        type: _StyledButton.STYLED_BUTTON_TYPE.GHOST,
                        size: _StyledButton.STYLED_BUTTON_SIZE.SMALL,
                        onClick: this.handleOpenDialog
                    }),
                    buttonText
                ),
                _react2.default.createElement('input', {
                    className: styles['file-input'],
                    type: 'file',
                    ref: this.input,
                    multiple: multiple,
                    accept: fileType,
                    onChange: this.handleFileSelect
                })
            );
        }
    }]);

    return FileDialogButton;
}(_react.Component);

FileDialogButton.propTypes = {
    /** Button label */
    buttonText: _propTypes2.default.node,
    /** Accept multiple files */
    multiple: _propTypes2.default.bool,
    /** File types supported */
    fileType: _propTypes2.default.string,
    /** Fires when files are added */
    onAddFiles: _propTypes2.default.func.isRequired,
    /** Apply class to style the button */
    className: _propTypes2.default.string
};

FileDialogButton.defaultProps = {
    className: '',
    multiple: true,
    fileType: _DragAndDropFile.FileTypes.IMAGE
};

exports.default = FileDialogButton;