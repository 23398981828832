import React, { createRef, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
    CloseIcon,
    pxToRem,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    DropdownMenu,
    DelimiterWrapper,
    AsyncDropdown,
    InputTypes,
} from 'taboola-ultimate-ui';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { RANGE_FILTERS_OPERATORS } from '../../../../common-campaign-form/components/Profile/utils/constants';
import { STATIC_FILTER_TYPE_MAP } from '../../../config';
import { InputEditField } from '../../CellEditors/InputEditField';
import { generateStaticFilteredOptions } from '../filterUtils';
import { comparableFiltersPlaceholdersResolver } from './comparableFiltersPlaceholdersResolver';
import styles from './baseOpenComparableFilter.module.scss';

const classNameBuilder = classnames.bind(styles);

const dropdownPlaceholder = (
    <FormattedMessage id="app.campaigns.reports.filters.placeholder" defaultMessage="Select from..." />
);

const textStyle = {
    color: styles['dropdown-color'],
    fontSize: styles['dropdown-fontSize'],
};
const dropdownStyles = {
    menu: provided => ({
        ...provided,
        minWidth: pxToRem(200),
    }),
    container: provided => ({
        ...provided,
        width: 'max-content',
        minWidth: pxToRem(175),
    }),
    control: provided => ({
        ...provided,
        border: 'none',
        borderRadius: 0,
        minHeight: 0,
        padding: styles['dropdown-padding'],
        backgroundColor: 'unset',
        boxShadow: 'none',
        '&:hover': {
            border: 0,
            boxShadow: 'none',
        },
        flexGrow: 1,
    }),
    placeholder: () => ({ ...textStyle, position: 'absolute' }),
    singleValue: () => ({
        ...textStyle,
        width: 'max-content',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    input: () => ({ margin: 0 }),
};

const asyncDropdownStyles = {
    ...dropdownStyles,
    singleValue: () => ({
        ...dropdownStyles.singleValue(),
        position: 'absolute',
    }),
};

export const BaseOpenComparableFilter = ({
    initialOpen,
    type,
    typeOptions,
    onTypeChange,
    onValuesChange,
    onCancel,
    onApply,
    applyBtnMetricsAttrs,
    operator,
    onOperatorChange,
    values,
}) => {
    const operatorRef = createRef();
    const { formatMessage } = useIntl();
    const [valuesOpen, setValuesOpen] = useState(false);
    const [inputValue, setInputValue] = useState(values[0] ?? '');
    const [inputSecondValue, setInputSecondValue] = useState(values[1] ?? '');
    const [inputOperator, setInputOperator] = useState('');
    const openValuesDropdown = () => setValuesOpen(true);
    const closeValuesDropdown = () => setValuesOpen(false);
    const showFirstInput = !isEmpty(operator);
    const showSecondInput = operator?.value === RANGE_FILTERS_OPERATORS.BETWEEN && showFirstInput;
    const isFilterIncomplete =
        isEmpty(operator) ||
        (operator && showFirstInput && isEmpty(inputValue)) ||
        (showSecondInput && isEmpty(inputSecondValue)) ||
        (showSecondInput &&
            !isEmpty(inputValue) &&
            !isEmpty(inputSecondValue) &&
            Number(inputValue) > Number(inputSecondValue)) ||
        (showFirstInput && Number(inputValue) < 0) ||
        (showSecondInput && Number(inputSecondValue) < 0) ||
        valuesOpen;
    const applyButtonClassName = classNameBuilder('apply-button', { disabled: !isFilterIncomplete });
    const handleInputOperatorChange = newInputOperator => {
        onOperatorChange(newInputOperator);
        setInputOperator('');
    };

    const handleTypeChange = newTypeObject => {
        onTypeChange(newTypeObject);
        onOperatorChange(undefined);
        clearValues();
        openValuesDropdown();
    };

    const clearValues = () => {
        onValuesChange([]);
        setInputValue('');
        setInputSecondValue('');
    };

    const handleOperatorChange = newInputOperator => {
        setInputOperator(newInputOperator);
    };

    const handleValueChange = newValueObject => {
        const newValues = [newValueObject, inputSecondValue];
        if (showSecondInput) {
            onValuesChange(newValues);
        } else {
            onValuesChange(newValueObject);
        }
        setInputValue(newValueObject);
    };

    const handleSecondValueChange = newValueObject => {
        const newValues = [inputValue, newValueObject];
        onValuesChange(newValues);
        setInputSecondValue(newValueObject);
    };

    const handleGetOptions = () => {
        return getOperatorOptions(type);
    };

    const getOperatorOptions = type => {
        const generateOptions = (messageIdPrefix, operator) =>
            generateStaticFilteredOptions({ formatMessage, messageIdPrefix, values: operator });
        const staticTypeMap = STATIC_FILTER_TYPE_MAP[type];

        if (staticTypeMap) {
            const { messageIdPrefix, operator } = staticTypeMap;
            return generateOptions(messageIdPrefix, operator);
        }
    };

    const { currency } = useAccount();
    const isFilterWithCurrency = STATIC_FILTER_TYPE_MAP[type]?.isCurrency;
    const isFilterWithPercent = STATIC_FILTER_TYPE_MAP[type]?.isPercent;
    const { firstPlaceholder, secondPlaceholder } = comparableFiltersPlaceholdersResolver({
        isFilterWithCurrency,
        isFilterWithPercent,
        showSecondInput,
        currency,
        formatMessage,
    });

    const operatorProps = useMemo(
        () => ({
            selectedValueObject: operator,
            blurInputOnSelect: true,
        }),
        [operator]
    );

    useEffect(() => {
        if (type && isEmpty(operator)) {
            if (operatorRef.current) {
                operatorRef.current.select.focus();
            }
            openValuesDropdown();
        }
    }, [type, operatorRef, operator]);

    return (
        <div className={styles['container']}>
            <div className={styles['dropdowns-container']}>
                <DelimiterWrapper>
                    <DropdownMenu
                        styles={dropdownStyles}
                        placeholder={dropdownPlaceholder}
                        autoFocus={initialOpen}
                        openMenuOnFocus
                        selectedValueObject={{ value: type }}
                        options={typeOptions}
                        onChange={handleTypeChange}
                        aria-label="Select filter type"
                    />
                    <AsyncDropdown
                        aria-label="Select filter value"
                        {...operatorProps}
                        key={type}
                        className={styles['values-dropdown']}
                        selectRef={operatorRef}
                        styles={asyncDropdownStyles}
                        disabled={!type}
                        onFocus={openValuesDropdown}
                        onBlur={closeValuesDropdown}
                        placeholder={dropdownPlaceholder}
                        inputValue={inputOperator}
                        onInputChange={handleOperatorChange}
                        getOptions={handleGetOptions}
                        onChange={handleInputOperatorChange}
                        menuIsOpen={valuesOpen}
                        backspaceRemovesValue={false}
                        manualMenuControl
                    />
                    {showFirstInput && (
                        <InputEditField
                            onChange={handleValueChange}
                            value={inputValue}
                            inputClass={styles['input']}
                            type={InputTypes.NUMBER}
                            autoFocus={false}
                            placeholder={firstPlaceholder}
                        />
                    )}
                    {showSecondInput && (
                        <InputEditField
                            onChange={handleSecondValueChange}
                            value={inputSecondValue}
                            inputClass={styles['input']}
                            type={InputTypes.NUMBER}
                            autoFocus={false}
                            placeholder={secondPlaceholder}
                        />
                    )}
                    <StyledButton
                        aria-label={`Cancel ${type} filter editing`}
                        className={styles['remove-button']}
                        size={STYLED_BUTTON_SIZE.SMALL}
                        type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                        iconBefore={<CloseIcon height={16} width={16} />}
                        onClick={onCancel}
                    />
                </DelimiterWrapper>
            </div>
            <StyledButton
                aria-label="Apply"
                className={applyButtonClassName}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.STRONG}
                onClick={onApply}
                disabled={isFilterIncomplete}
                {...applyBtnMetricsAttrs}
            >
                <FormattedMessage id="app.actionButtons.apply" defaultMessage="Apply" />
            </StyledButton>
        </div>
    );
};

BaseOpenComparableFilter.propTypes = {
    initialOpen: PropTypes.bool,
    type: PropTypes.string,
    typeOptions: PropTypes.array,
    onTypeChange: PropTypes.func,
    value: PropTypes.object,
    getValueOptions: PropTypes.func,
    onValueChange: PropTypes.func,
    valuesSearchable: PropTypes.bool,
    onCancel: PropTypes.func,
    onApply: PropTypes.func,
    applyBtnMetricsAttrs: PropTypes.object,
};
