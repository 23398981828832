import React from 'react';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import { DesktopIcon, MobileSimpleIcon, TabletIcon, TargetIcon } from 'taboola-ultimate-ui';
import { CollapsibleFormSection } from 'components';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { isProgrammaticAccount } from 'modules/account-management/accountType';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { ENTITY_MESSAGE_PREFIX } from 'modules/campaigns/config/routes';
import {
    alertKeys,
    basePlatformValidations,
    campaignPlatformValidations,
    checkIsCompatiblePlatforms,
} from 'modules/campaigns/config/validations/campaign/platformValidations';
import { MARKETING_OBJECTIVE_KEYS, PLATFORMS } from 'modules/campaigns/modules/common-campaign-form/config';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { HIGH_IMPACT_PACKAGE_PERMISSION } from '../../config/highImpactPackageOptions';
import BrandSafety from '../BrandSafety';
import BrowserTargeting from '../BrowserTargeting';
import GeoTargeting from '../GeoTargeting/GeoTargeting';
import { HighImpactTargeting } from '../HighImpactTargeting/HighImpactTargeting';
import MissingTargetingFeatures from '../MissingTargetingFeatures';
import OsTargetingField from '../OsTargeting';
import PlatformTargeting from '../PlatformTargeting/PlatformTargeting';
import PremiumSite from '../PremiumSite';
import { SiteTargetingField } from '../SiteTargeting/SiteTargetingField';
import { SupplyTargeting } from '../SupplyTargeting/SupplyTargeting';
import ThirdPartyBrandSafety from '../ThirdPartyBrandSafety';
import WifiTargeting from '../WifiTargeting/WifiTargeting';
import { useAdvancedOptionsSection } from './hooks/useAdvancedOptionsSection';
import styles from './targetingSection.module.scss';

const getCheckboxColor = ({ id, platformTargeting }) => {
    if (checkIsCompatiblePlatforms(platformTargeting) || !alertKeys.includes(id)) return;
    return styles.alertColor;
};

const shouldEnableItem = conditionData => {
    return conditionData?.marketingObjective !== MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL;
};
const platformsWithCondition = [
    {
        id: PLATFORMS.DESKTOP,
        icon: DesktopIcon,
        enableCondition: shouldEnableItem,
    },
    {
        id: PLATFORMS.MOBILE,
        icon: MobileSimpleIcon,
    },
    {
        id: PLATFORMS.TABLET,
        icon: TabletIcon,
        enableCondition: shouldEnableItem,
    },
];

const TargetingSection = ({
    isIncludeHighImpactTargeting,
    isIncludeSupplyTargeting = true,
    messageIdPrefix = ENTITY_MESSAGE_PREFIX.CAMPAIGN,
}) => {
    const { isInitiallyCollapsed, advancedOptionsSubtitle } = useAdvancedOptionsSection(isIncludeHighImpactTargeting);
    const { mode, data, formAccount } = useFormDataContext();
    const campaignTargeting = data?.campaignTargeting;
    const isEditOrDuplicateForm = mode === FORM_MODES.EDIT || mode === FORM_MODES.DUPLICATE;
    const hasHighImpactPackagePermission = usePermissions(HIGH_IMPACT_PACKAGE_PERMISSION);
    const platformTargetingValidations = isEmpty(campaignTargeting?.myAudienceTargeting?.include)
        ? isProgrammaticAccount(formAccount)
            ? basePlatformValidations
            : campaignPlatformValidations(hasHighImpactPackagePermission)
        : basePlatformValidations;
    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_TARGETING"
            header={
                <SectionHeader
                    headerIcon={<TargetIcon />}
                    headerText={<FormattedMessage id={`${messageIdPrefix}.editor.targeting`} />}
                />
            }
        >
            {isEditOrDuplicateForm && campaignTargeting && (
                <MissingTargetingFeatures className={styles['missing-features']} />
            )}
            <GeoTargeting />
            <PlatformTargeting
                platforms={platformsWithCondition}
                validations={platformTargetingValidations}
                getCheckboxColor={isProgrammaticAccount(formAccount) ? noop : getCheckboxColor}
            />
            <WifiTargeting />
            <OsTargetingField />
            <BrowserTargeting />
            <CollapsibleFormSection
                title={<FormattedMessage id="campaign.advancedOptions" defaultMessage="Advanced Options" />}
                subtitle={advancedOptionsSubtitle}
                isInitiallyCollapsed={isInitiallyCollapsed}
                sectionId="CAMPAIGN_SUBSECTION_ADVANCED_TARGETING"
                testId="targeting-advanced-options"
            >
                {isIncludeSupplyTargeting ? <SupplyTargeting /> : null}
                <SiteTargetingField />
                <ThirdPartyBrandSafety />
                {isIncludeHighImpactTargeting ? <HighImpactTargeting /> : null}
                <PremiumSite />
                <BrandSafety />
            </CollapsibleFormSection>
        </CommonCollapsibleCard>
    );
};

TargetingSection.propTypes = { messageIdPrefix: PropTypes.string };

TargetingSection.defaultProps = {
    messageIdPrefix: ENTITY_MESSAGE_PREFIX.CAMPAIGN,
};

export default TargetingSection;
