import React from 'react';
import { parse } from 'papaparse';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

export const parseCSV = async file =>
    new Promise((resolve, reject) => {
        parse(file, {
            skipEmptyLines: 'greedy',
            header: false,
            complete: results => resolve(results.data),
            error: err => reject(err),
        });
    });

export const getColumnsNumber = parsedCSV => {
    if (!parsedCSV || !parsedCSV.length) {
        return 0;
    }
    return Object.keys(parsedCSV[0]).length;
};

export const getLabels = parsedCSV => {
    return parsedCSV.map(row => Object.values(row)[0].trim());
};

const indicationUnsupportedFilenameFormat = {
    message: (
        <FormattedMessage
            id="audience.editor.validations.error.crm.upload.unsupported.format"
            defaultMessage="Unsupported file name, the file name must be in English characters without spaces"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const csvFileNameRegex = /^([a-z0-9_\\.\-():])+(\.csv)$/;

export const validateFiles = ({ acceptedFiles, dispatch }) => {
    if (
        acceptedFiles &&
        acceptedFiles.length === 1 &&
        !csvFileNameRegex.test(acceptedFiles[0].name.toLocaleLowerCase())
    ) {
        dispatch(addIndication(indicationUnsupportedFilenameFormat));
        return false;
    }
    return true;
};
