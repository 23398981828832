'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.escaperegexp');

var _lodash2 = _interopRequireDefault(_lodash);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'highlighter__taboola-svg-icon___CbGlT',
    'container': 'highlighter__container___3ogsc',
    'highlighted-text': 'highlighter__highlighted-text___1Q9v9'
};


var classNameBuilder = _bind2.default.bind(styles);

var Highlighter = function (_Component) {
    _inherits(Highlighter, _Component);

    function Highlighter() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Highlighter);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Highlighter.__proto__ || Object.getPrototypeOf(Highlighter)).call.apply(_ref, [this].concat(args))), _this), _this.getHighlightedText = function () {
            var _this$props = _this.props,
                text = _this$props.text,
                highlightedText = _this$props.highlightedText,
                caseSensitive = _this$props.caseSensitive,
                highlightedTextClassName = _this$props.highlightedTextClassName;


            if (!highlightedText || !highlightedText.length) {
                return text;
            }

            var textClassName = classNameBuilder('highlighted-text', _defineProperty({}, highlightedTextClassName, highlightedTextClassName));
            var caseStr = caseSensitive ? 'g' : 'gi';
            var validText = (0, _lodash2.default)(highlightedText);
            var regEx = new RegExp(validText, caseStr);
            var result = [];
            var lastIndex = 0;

            var appearance = void 0;
            try {
                appearance = regEx.exec(text);
            } catch (error) {
                return text;
            }

            while (appearance && text) {
                result.push(text.slice(lastIndex, appearance.index));
                result.push(_react2.default.createElement(
                    'span',
                    { className: textClassName, key: appearance[0] + lastIndex },
                    appearance[0]
                ));

                lastIndex = appearance.index + highlightedText.length;
                appearance = regEx.exec(text);
            }

            if (text && lastIndex < text.length) {
                result.push(text.slice(lastIndex));
            }

            return result;
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Highlighter, [{
        key: 'render',
        value: function render() {
            var className = this.props.className;

            var containerClassName = classNameBuilder('container', _defineProperty({}, className, className));
            return _react2.default.createElement(
                'div',
                { className: containerClassName, dir: 'auto' },
                this.getHighlightedText()
            );
        }
    }]);

    return Highlighter;
}(_react.Component);

Highlighter.propTypes = {
    /** the element's content */
    text: _propTypes2.default.string.isRequired,
    /** the text we wish to search and highlight */
    highlightedText: _propTypes2.default.string,
    /** determines wethear the search will be case sensitive  */
    caseSensitive: _propTypes2.default.bool,
    /** additional class name added to container */
    className: _propTypes2.default.string,
    /** additional class name added to highlighted text */
    highlightedTextClassName: _propTypes2.default.string
};

Highlighter.defaultProps = {
    caseSensitive: false
};

exports.default = Highlighter;