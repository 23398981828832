import React from 'react';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../../../config/PaymentType';
import styles from '../../addFundsModal.module.scss';

export const InstructionsAchBankDirectDebit = () => {
    return (
        <div className={styles['ach-bank-direct-debit-instructions']}>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.processingTime`}
                    defaultMessage="It will take <b>4 business days</b> to complete the transfer and see the funds added to your account balance."
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.processingTime.tooltip`}
                    />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.1`}
                    defaultMessage="You will be redirected to sign into your bank account to provide account credentials."
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.1.tooltip`}
                    />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.3`}
                    defaultMessage="A receipt will be sent upon payment. A tax invoice will be issued at the end of the month."
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.3.tooltip`}
                    />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.4`}
                    defaultMessage="Bank transfers cannot be used to settle monthly invoices."
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT}.instruction.4.tooltip`}
                    />
                </HelpTooltip>
            </li>
        </div>
    );
};
