export const getZoom = () => {
    const element = document.body; // Replace with the relevant element

    // Get the computed style of the element
    const style = getComputedStyle(element);

    // Access the value of the --zoom variable
    const rawZoom = style.getPropertyValue('--zoom').trim();

    const zoomValue = parseFloat(rawZoom);

    return zoomValue;
};
