import { useCallback } from 'react';
import { itemTransformer } from 'modules/campaigns/services/utils/dropdownUtils';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';

const useDropdownValue = (item, msgIdPrefix) => {
    const { formatMessage } = useIntl();
    const noPrefixFormatMessage = useCallback(label => label.defaultMessage, []);

    return itemTransformer(item, msgIdPrefix, msgIdPrefix ? formatMessage : noPrefixFormatMessage);
};

export default useDropdownValue;
