import React, { useCallback } from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { TARGETING_TYPES } from '../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { TopicsListItem } from '../TopicsListItem';
import styles from '../topicsListField.module.scss';

const SMALL_ITEM = 40;

export const IncludedTopicsList = ({ terms, setIncludedTopics }) => {
    const onDeleteIncludedItem = useCallback(
        item => {
            setIncludedTopics(terms.map(topicList => topicList.filter(topic => topic !== item)));
        },
        [terms, setIncludedTopics]
    );
    const onClearIncludedTopics = useCallback(() => {
        // TODO: change it to clear only the selected list when we support multiple included topics with AND
        setIncludedTopics([]);
    }, [setIncludedTopics]);

    return terms.map((topicsList, i) => (
        <CollapsibleList
            key={i}
            items={topicsList}
            ItemComponent={TopicsListItem}
            metadata={{ targetingType: TARGETING_TYPES.INCLUDE }}
            deleteItem={onDeleteIncludedItem}
            itemHeight={SMALL_ITEM}
            clearItems={onClearIncludedTopics}
            listHeaderTitle={
                <FormattedMessage
                    id="custom.contextual.editor.topics.list.included.label"
                    defaultMessage="Must contain at least one of the following"
                />
            }
            aria-label="include-list"
            showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
            showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
            containerClassName={styles['list']}
        />
    ));
};
