import React from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import CurrencyIcon from 'modules/campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

const InputWithIndications = withIndication(Input);

export const LookBackWindowInput = ({ field, validations, currency, id, inputPadding, isDisabled }) => {
    const {
        value: lookBackWindow = '',
        onChange: onLookBackWindowChange,
        indicationData,
        scrollRef: inputScrollRef,
    } = useFormValidatedValue({
        field,
        validations,
        validationDependencies: { min: 1, max: 30, isDisabled },
    });
    const changeHandler = useEventValueHandler(onLookBackWindowChange);

    return (
        <FormattedNumber value={0} minimumIntegerDigits={2}>
            {placeholder => (
                <InputWithIndications
                    id={id}
                    type={InputTypes.NUMBER}
                    placeholder={placeholder}
                    value={lookBackWindow}
                    onChange={changeHandler}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component={field}
                    ref={inputScrollRef}
                    disabled={isDisabled}
                    {...indicationData}
                />
            )}
        </FormattedNumber>
    );
};
