import moment from 'moment-timezone';
import { DURATION_MS, PROGRESS_VALUE, REMAINING_TIME_MESSAGE_ID } from './config';

export const getTimeLeftProps = remainingTimeInMs => {
    if (remainingTimeInMs > DURATION_MS.ONE_DAY) {
        return {
            id: REMAINING_TIME_MESSAGE_ID.DAYS_SHORT,
            value: Math.round(moment.duration(remainingTimeInMs).asDays()),
        };
    }

    return {
        id: REMAINING_TIME_MESSAGE_ID.HOURS_LEFT,
        value: Math.round(moment.duration(remainingTimeInMs).asHours()),
    };
};

export const calculateProgress = (remainingTimeInMs, totalTimeInMs) => {
    if (!totalTimeInMs) {
        return;
    }

    return Math.min(
        PROGRESS_VALUE.MAX_PROGRESS,
        Math.round(((totalTimeInMs - remainingTimeInMs) / totalTimeInMs) * 100)
    );
};
