import React from 'react';
import PropTypes from 'prop-types';
import { EmptyStateOverlay as EmptyStateOverlayInner } from 'taboola-ultimate-ui';
import { useEmptyState } from '../../hooks/useEmptyState';

export const EmptyStateOverlay = ({ className, includeContent }) => {
    const { header, content } = useEmptyState({ includeContent });
    return <EmptyStateOverlayInner header={header} content={content} className={className} />;
};

EmptyStateOverlay.propTypes = {
    /** the Header of the overlay */
    header: PropTypes.node,
    /** The content of the overlay */
    content: PropTypes.node,
    /** optional class name to be added to the main container */
    className: PropTypes.string,
};

EmptyStateOverlay.defaultProps = {
    header: '',
    content: '',
    className: '',
};
