import React, { useState } from 'react';
import { toNumber } from 'lodash';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { immutableAddItemByIndex } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { validationFunctions, withContainerValidations } from 'modules/taboola-common-frontend-modules/validations';
import useValidationContext from 'modules/taboola-common-frontend-modules/validations/hooks/useValidationContext';
import { HOURS_OF_DAY, TARGETING_TYPES } from '../../config';
import DaypartingRow from './DaypartingRow';
import styles from './daypartingTable.module.scss';

export const DAY_PART_RULE_FIELD = {
    START: 'fromHour',
    END: 'untilHour',
    TYPE: 'type',
};

const DaypartingRowWithValidations = withContainerValidations(withIndication(DaypartingRow), [
    {
        validationFn: validationFunctions.range,
        options: { gt: 'fromHour' },
        messageId: 'campaign.editor.schedule.dayparting.hour.dropdown.error',
        defaultMessage: 'Start time must be earlier than end time',
    },
    {
        validationFn: (items, options, { data: { isChanged, untilHour, minUntilHour, minDayPartingWindowHrs } }) => {
            if (!isChanged || !minDayPartingWindowHrs) {
                return true;
            }

            return minUntilHour <= untilHour;
        },
        messageId: 'campaign.editor.schedule.dayparting.selection.minDayPartingHrs',
        defaultMessage: 'A minimum of {minDayPartingWindowHrs} hours per day is required',
    },
]);

const DaypartingRowWrapper = ({ item, index, onRulesChange, daypartingConfig, startTimeOptions, endTimeOptions }) => {
    const {
        [FEATURE_FLAGS.IS_MIN_DAY_PARTING_WINDOW]: isMinDayPartingWindowStr,
        [FEATURE_FLAGS.MIN_DAY_PARTING_WINDOW_HRS]: minDayPartingWindowHrsStr,
    } = useAccountConfig([FEATURE_FLAGS.IS_MIN_DAY_PARTING_WINDOW, FEATURE_FLAGS.MIN_DAY_PARTING_WINDOW_HRS]);

    const minDayPartingWindowHrs = toNumber(minDayPartingWindowHrsStr);

    const { day, fromHour, untilHour, type } = item;

    const handleStartHourChange = ({ value }) => onRulesChange(getUpdatedRules(DAY_PART_RULE_FIELD.START, value));

    const handleEndHourChange = ({ value }) => onRulesChange(getUpdatedRules(DAY_PART_RULE_FIELD.END, value));

    const [isChanged, setIsChanged] = useState(false);
    const startHourChangeWrapper = value => {
        setIsChanged(true);
        handleStartHourChange(value);
    };

    const endHourChangeWrapper = value => {
        setIsChanged(true);
        handleEndHourChange(value);
    };

    const isMinDayPartingWindow = JSON.parse(isMinDayPartingWindowStr);

    const validationContext = useValidationContext({
        fromHour,
        minUntilHour: fromHour + minDayPartingWindowHrs,
        untilHour,
        isChanged,
        minDayPartingWindowHrs: isMinDayPartingWindow && minDayPartingWindowHrs,
    });

    const getUpdatedRules = (key, value) =>
        immutableAddItemByIndex(daypartingConfig, index, updateObject(item, { [key]: value }));

    const handleToggleDayOff = () => {
        const updatedItem = updateObject(item, {
            [DAY_PART_RULE_FIELD.START]: HOURS_OF_DAY.ZERO,
            [DAY_PART_RULE_FIELD.END]: HOURS_OF_DAY.TWENTY_FOUR,
            [DAY_PART_RULE_FIELD.TYPE]: TARGETING_TYPES.EXCLUDE,
        });
        onRulesChange(immutableAddItemByIndex(daypartingConfig, index, updatedItem));
    };

    const handleToggleDayOn = () => onRulesChange(getUpdatedRules(DAY_PART_RULE_FIELD.TYPE, TARGETING_TYPES.INCLUDE));

    const handleCopy = () =>
        onRulesChange(
            daypartingConfig.map(row => ({
                ...row,
                fromHour,
                untilHour,
                type,
            }))
        );

    return (
        <DaypartingRowWithValidations
            day={day}
            startTime={fromHour}
            endTime={untilHour}
            type={type}
            onStartHourChange={startHourChangeWrapper}
            onEndHourChange={endHourChangeWrapper}
            onToggleDayOff={handleToggleDayOff}
            onToggleDayOn={handleToggleDayOn}
            onCopy={handleCopy}
            valueToValidate={untilHour}
            id={`dayparting-row-${day}`}
            validationContext={validationContext}
            errorMessageClass={styles['error-messsage']}
            startTimeOptions={startTimeOptions}
            endTimeOptions={endTimeOptions}
        />
    );
};

export default DaypartingRowWrapper;
