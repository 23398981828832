import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CAMPAIGN_HISTORY].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.CHANGE_TIME.field,
        FIELDS.CAMPAIGN_NAME.field,
        FIELDS.ACTIVITY.field,
        FIELDS.ACTIVITY_DETAILS.field,
        FIELDS.OLD_VALUE.field,
        FIELDS.NEW_VALUE.field,
        FIELDS.PERFORMER.field,
    ].map(field => generateColumnId(reportId, field)),
};
const defaultColumns = [
    FIELDS.CHANGE_TIME.field,
    FIELDS.CAMPAIGN_NAME.field,
    FIELDS.ACTIVITY.field,
    FIELDS.ACTIVITY_DETAILS.field,
    FIELDS.OLD_VALUE.field,
    FIELDS.NEW_VALUE.field,
    FIELDS.PERFORMER.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
