const trackingAPIPrefix = accountId => `/${accountId}/tracking`;

const time = 3 * 60 * 1000;

export const trackingApiFactory = ({ callGetApi, callPostApi, queryClient, callPatchApi }) => ({
    getTrackingStatus: accountId => {
        return queryClient.fetchQuery(['status', accountId], () =>
            callGetApi(`${trackingAPIPrefix(accountId)}/status`)
        );
    },
    getTrackingSettings: accountName => {
        return queryClient.fetchQuery(['settings', accountName], () =>
            callGetApi(`${trackingAPIPrefix(accountName)}/settings`)
        );
    },
    updateTrackingSettings: (accountId, queryParams) => {
        return callPatchApi(`${trackingAPIPrefix(accountId)}/settings`, queryParams);
    },
    updateConversion: (accountId, conversionId, queryParams) => {
        return callPostApi(`${trackingAPIPrefix(accountId)}/conversion_rules/${conversionId}`, queryParams);
    },
    createConversion: (accountId, queryParams) => {
        return callPostApi(`${trackingAPIPrefix(accountId)}/conversion_rules`, queryParams);
    },
    getConversion: (accountId, conversionId) => {
        return callGetApi(`${trackingAPIPrefix(accountId)}/conversion_rules/${conversionId}`);
    },
    getConversionsList: (accountId, ruleIds) => {
        return callGetApi(`${trackingAPIPrefix(accountId)}/conversion_rules`, { ruleIds });
    },
    sendPixelCode: ({ accountId, ...queryParams }) => {
        return callPostApi(`${trackingAPIPrefix(accountId)}/pixel-code`, queryParams);
    },
    generateTestLink: (accountId, campaignId) => {
        return queryClient.fetchQuery(
            ['s2s', campaignId],
            () => callGetApi(`${trackingAPIPrefix(accountId)}/s2s/test-link?campaignId=${campaignId}`),
            { staleTime: time }
        );
    },

    getGTMAccounts: (accessTokenString, accountId) => {
        return callGetApi(`${trackingAPIPrefix(accountId)}/google-tag-manager/accounts`, {
            headers: {
                'Authorization-Token': `Bearer ${accessTokenString}`,
            },
        });
    },

    getContainers: (accessTokenString, accountId, gtmAccountId) => {
        return callGetApi(`${trackingAPIPrefix(accountId)}/google-tag-manager/accounts/${gtmAccountId}/containers`, {
            headers: {
                'Authorization-Token': `Bearer ${accessTokenString}`,
            },
        });
    },

    getWorkspaces: (accessTokenString, accountId, gtmAccountId, containerId) => {
        return callGetApi(
            `${trackingAPIPrefix(
                accountId
            )}/google-tag-manager/accounts/${gtmAccountId}/containers/${containerId}/workspaces`,
            {
                headers: {
                    'Authorization-Token': `Bearer ${accessTokenString}`,
                },
            }
        );
    },

    createTag: (accessTokenString, accountId, gtmAccountId, containerId, workspaceId) => {
        return callPostApi(
            `${trackingAPIPrefix(
                accountId
            )}/google-tag-manager/accounts/${gtmAccountId}/containers/${containerId}/workspaces/${workspaceId}`,
            {},
            {
                headers: {
                    'Authorization-Token': `Bearer ${accessTokenString}`,
                },
            }
        );
    },

    getLiveTags: (accessTokenString, accountId, gtmAccountId, containerId, taboolaOnly) => {
        return callGetApi(
            `${trackingAPIPrefix(
                accountId
            )}/google-tag-manager/accounts/${gtmAccountId}/containers/${containerId}/live-tags?taboolaOnly=${taboolaOnly}`,
            {
                headers: {
                    'Authorization-Token': `Bearer ${accessTokenString}`,
                },
            }
        );
    },

    createShopifyRules: accountId => {
        return callPostApi(`${trackingAPIPrefix(accountId)}/shopify_connect/rules`);
    },
});
