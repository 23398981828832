import {
    AdsClickOutlinedIcon,
    PeopleOutlineIcon,
    FeedOutlinedIcon,
    EmailOutlinedIcon,
    SearchOutlinedIcon,
    JoinInnerIcon,
} from 'tuui';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { isVideoAccount } from '../../account-management';
import { isOpenExchangeAccount, isPmpAccount } from '../../account-management/accountType';
import { REPORT_TYPE } from '../../campaigns/config';

const isFeatureFlagEnabled = (accountConfig, featureFlagName) => accountConfig?.[featureFlagName] === 'true';
const isUserPermissionOrFeatureFlagEnabled = ({ permissionsMap, accountConfig, requiredPermission, featureFlagName }) =>
    permissionsMap[requiredPermission] || isFeatureFlagEnabled(accountConfig, featureFlagName);
const isSupportedAccountTypeForCombined = selectedAccount => {
    const { partnerTypes } = selectedAccount;

    return !isVideoAccount(partnerTypes) && !isPmpAccount(selectedAccount) && !isOpenExchangeAccount(selectedAccount);
};

const yahooAudiencesVisibilityCondition = ({ permissionsMap, accountConfig }) =>
    isUserPermissionOrFeatureFlagEnabled({
        permissionsMap,
        accountConfig,
        requiredPermission: 'YAHOO_SRT_MRT_AUDIENCE',
        featureFlagName: FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_ALLOWED,
    });

export const yourDataAudienceConfigs = [
    {
        id: REPORT_TYPE.PIXEL_BASED,
        label: 'Pixel',
        subtext: 'What they did on your site',
        IconComponent: AdsClickOutlinedIcon,
        tooltipText:
            'Convert visitor interactions on your website into audience segments. This option allows for highly personalized retargeting based on user actions throughout the funnel, from specific page visits to purchases, alongside suppressing users who have already converted.',
    },
    {
        id: REPORT_TYPE.FIRST_PARTY,
        label: 'Customer List',
        subtext: 'Data from your CRM',
        IconComponent: PeopleOutlineIcon,
        tooltipText:
            'Segment audiences by customer behaviour or demographic based on your own CRM data. This is ideal for re-targeting specific groups within your existing customer base, such as loyal or churned customers, as well as for suppression purposes.',
        visibilityCondition: ({ permissionsMap, accountConfig }) =>
            isUserPermissionOrFeatureFlagEnabled({
                permissionsMap,
                accountConfig,
                requiredPermission: 'CREATE_CUSTOMER_FILE_ALLOWED',
                featureFlagName: FEATURE_FLAGS.CREATE_CUSTOMER_FILE_ALLOWED,
            }),
    },
];

export const taboolaDataAudienceConfigs = [
    {
        id: REPORT_TYPE.CUSTOM_CONTEXTUAL,
        label: 'Topics',
        subtext: 'What they currently reading',
        IconComponent: FeedOutlinedIcon,
        tooltipText:
            'Engage with users in real-time as they explore content related to specific topics. This dynamic targeting approach will allow you to place your message directly in the context of their current interests, enhancing relevance and engagement.',
        visibilityCondition: ({ permissionsMap, accountConfig }) =>
            isUserPermissionOrFeatureFlagEnabled({
                permissionsMap,
                accountConfig,
                requiredPermission: 'ADS_CONSOLE_CUSTOM_CONTEXTUAL',
                featureFlagName: FEATURE_FLAGS.ADS_CONSOLE_CUSTOM_CONTEXTUAL_ALLOWED,
            }),
    },
    {
        id: REPORT_TYPE.MAIL_DOMAINS,
        label: 'Email Domains',
        subtext: 'Who their emails are from',
        IconComponent: EmailOutlinedIcon,
        tooltipText:
            'Target users based on the domains of the emails they have received. This unique approach allows for precise targeting, especially useful for reaching audiences engaged with specific industries or competitors.',
        visibilityCondition: yahooAudiencesVisibilityCondition,
    },
    {
        id: REPORT_TYPE.SEARCH_KEYWORDS,
        label: 'Search Keywords',
        subtext: 'What they have searched',
        IconComponent: SearchOutlinedIcon,
        tooltipText:
            'Create segments based on the search terms users have entered. This option taps into user intent, enabling you to retarget people actively seeking information or products related to your business.',
        visibilityCondition: yahooAudiencesVisibilityCondition,
    },
];

export const moreAudienceConfigs = [
    {
        id: REPORT_TYPE.PREDICTIVE,
        label: 'Lookalike Audience',
        subtext: 'Similar users to existing audiences',
        IconComponent: PeopleOutlineIcon,
        tooltipText:
            'Expand your reach by targeting new users who share characteristics with your existing customers. This method leverages advanced algorithms to find new prospects likely to be interested in your offerings.',
        visibilityCondition: ({ permissionsMap, accountConfig }) => {
            const createLookalikeAllowed = isUserPermissionOrFeatureFlagEnabled({
                permissionsMap,
                accountConfig,
                requiredPermission: 'CREATE_LOOKALIKE_ALLOWED',
                featureFlagName: FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED,
            });
            const createLookalikeAllowedNew = isUserPermissionOrFeatureFlagEnabled({
                permissionsMap,
                accountConfig,
                requiredPermission: 'CREATE_LOOKALIKE_ALLOWED_NEW',
                featureFlagName: FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED_NEW,
            });
            return createLookalikeAllowed && createLookalikeAllowedNew;
        },
    },
    {
        id: REPORT_TYPE.COMBINED_AUDIENCES,
        label: 'Combined Audiences',
        subtext: 'Built from existing audiences',
        IconComponent: JoinInnerIcon,
        tooltipText:
            'Enhance your targeting precision by combining multiple relevant audience segments together. This helps with greater organisation of audiences from different data sources and easy re-use for future campaigns.',
        visibilityCondition: ({ permissionsMap, accountConfig, selectedAccount }) => {
            const isSupportedAccountType = isSupportedAccountTypeForCombined(selectedAccount);
            const isAllowed = isUserPermissionOrFeatureFlagEnabled({
                permissionsMap,
                accountConfig,
                requiredPermission: 'ADS_CONSOLE_COMBINED_AUDIENCES',
                featureFlagName: FEATURE_FLAGS.ADS_CONSOLE_COMBINED_AUDIENCES_ALLOWED,
            });
            return isAllowed && isSupportedAccountType;
        },
    },
];
