import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGNS_GROUP_ROUTE, CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH } from 'modules/campaigns/config';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CampaignsGroupBulkEditor } from '../modules/campaigns-group-form/forms/campaigns-group-bulk-editor';

export const CampaignsGroupBulkEditorPage = ({ onCancel }) => {
    useUnsavedChangesController(CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                exact
                path={CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH}
                render={props => <CampaignsGroupBulkEditor {...props} onCancel={onCancel} />}
            />
            <Redirect to={CAMPAIGNS_GROUP_ROUTE} />
        </Switch>
    );
};

CampaignsGroupBulkEditorPage.propTypes = {
    onCancel: PropTypes.func,
};
