import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

const nameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: ({ prefix }) => `audience.editor.validations.error.${prefix}.name.empty`,
        defaultMessage: 'Enter an {prefix} name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 'maxLen' },
        messageId: ({ prefix }) => `audience.editor.validations.error.${prefix}.name.length`,
        defaultMessage: '{prefix} name can not exceed {maxLen} characters',
    },
];

export default nameValidations;
