import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import encodeurl from 'encodeurl';
import { chain } from 'lodash';
import useServerCtaData from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/hooks/useServerCtaData';
import { CONTENT_METHODS } from 'modules/campaigns/modules/creative-creator/config';
import { bulkCreateCreatives } from 'modules/campaigns/modules/creative-creator/flows';
import { aiTabImagePromptMapSelector } from 'modules/campaigns/modules/creative-editor';
import { reduceAndSortObjectKeys } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { ITEM_CREATIVE_TYPE } from '../constants/itemCreativeType';
import { sendVariationsMetrics } from '../services/sendVariationsMetrics';
import { isFileTypeVideo } from '../services/utils';
import { generateCreativeName } from '../services/utils/generateCreativeName';

export const useUnifiedCreativeSaveVariationsCarouselHandler = (accountId, data, dispatch, onSuccess) => {
    const {
        thumbnails: thumbnailList,
        creativePreviews: variationsCreativePreviews,
        aiVariationsCreativePreviews,
        recommendationId,
    } = data;

    const ctaData = useServerCtaData();
    const aiImagesPromptData = useSelector(aiTabImagePromptMapSelector);

    const getActivePreviews = creativePreviewList =>
        chain(creativePreviewList)
            .values()
            .map(previews => previews.filter(preview => !preview.deleted))
            .flatten()
            .value();

    const createParentItemData = useCallback(({ title, url, thumbnailUrl, fileType, fallbackImageUrl }) => {
        const thumbUrl = isFileTypeVideo(fileType) ? fallbackImageUrl : thumbnailUrl;
        return { title, url, thumbnailUrl: thumbUrl };
    }, []);

    return ({ contentMethod }) => {
        const { campaigns, content: variationsContent, aiVariationsContent } = data;
        const creativePreviews =
            contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsCreativePreviews : variationsCreativePreviews;
        const creativePreviewList = reduceAndSortObjectKeys(creativePreviews);
        const content = contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsContent : variationsContent;
        const activePreviews = getActivePreviews(creativePreviewList);
        const encodedActivePreviews = activePreviews.map(({ url, ...rest }) => ({
            url: encodeurl(url),
            ...rest,
        }));
        const { title, url, thumbnailUrl } = createParentItemData(encodedActivePreviews[0]);

        const activeCreatives = {
            title,
            url,
            thumbnailUrl,
            itemCreativeType: ITEM_CREATIVE_TYPE.STATIC_IMAGE,
            type: 'ITEM_WITH_HIERARCHY',
            hierarchyData: {
                hierarchyChildren: encodedActivePreviews.map(
                    (
                        {
                            title,
                            thumbnailUrl,
                            description,
                            cta,
                            url,
                            appInstall,
                            rating,
                            logo,
                            fileType,
                            fallbackImageUrl,
                            gifUrl,
                            mediaUploadSource,
                            recommendedFBImage,
                            motionAdsStudio,
                            creativeFocus,
                            creativeCrop,
                            thirdPartyTags,
                            creativeName,
                            customId,
                        },
                        index
                    ) => {
                        const isFileOfTypeVideo = isFileTypeVideo(fileType);
                        const performanceVideoData = isFileOfTypeVideo
                            ? {
                                  fallbackUrl: fallbackImageUrl,
                                  videoUrl: thumbnailUrl,
                                  gifUrl,
                                  mediaUploadSource,
                                  recommendedFallbackImage: recommendedFBImage,
                                  motionAdsStudio,
                              }
                            : null;
                        const itemCreativeType = isFileOfTypeVideo
                            ? ITEM_CREATIVE_TYPE.PERFORMANCE_VIDEO
                            : ITEM_CREATIVE_TYPE.STATIC_IMAGE;
                        const thumbUrl = isFileOfTypeVideo ? fallbackImageUrl : thumbnailUrl;

                        return {
                            title,
                            thumbnailUrl: thumbUrl,
                            description,
                            cta,
                            url,
                            creativeFocus,
                            appInstall,
                            rating,
                            logo,
                            thirdPartyTags,
                            creativeCrop,
                            itemCreativeType,
                            performanceVideoData,
                            creativeName: generateCreativeName(activePreviews, creativeName, index),
                            customId,
                        };
                    }
                ),
            },
        };

        sendVariationsMetrics({
            content,
            thumbnailList,
            campaigns,
        });

        return dispatch(
            bulkCreateCreatives({
                accountId,
                selectedCampaigns: campaigns,
                creatives: [activeCreatives],
                ctaData,
                encodedActivePreviews,
                aiImagesPromptData,
                recommendationId,
                onSuccess,
            })
        );
    };
};
