import { useMemo } from 'react';
import FuzzySearch from 'fuzzy-search';

const useFuzzySearch = (items, prop, text) => {
    const searcher = useMemo(() => new FuzzySearch(items, [prop]), [items, prop]);
    const filteredItems = useMemo(() => searcher.search(text), [text, searcher]);
    return filteredItems;
};

export default useFuzzySearch;
