import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../../../../selectors';
import { AUDIENCE_STATUS } from '../../../../../audiences/config/audiencesConsts';
import { useOpenAudienceEditor } from '../../../../../audiences/hooks';
import {
    isAudienceChangingStateDisabled,
    isAudienceDefaultExcludeDisabled,
    isAudienceEditFormDisabled,
    isPredictiveAudience,
} from '../../config/predicates/audiencePredicates';
import { COLOR_OPTIONS as BUTTON_COLOR_OPTIONS } from '../ActionButton/ActionButton';
import ActionsCellRenderer from './ActionsCellRenderer';

const AudienceEditTooltipContent = ({ isArchived, isAudienceDisabled, isAnotherPublisherAudience }) =>
    isArchived || isAudienceDisabled ? (
        <FormattedMessage
            id="app.audiences.actions.edit.archived"
            defaultMessage="Archived audiences are not editable"
        />
    ) : isAnotherPublisherAudience ? (
        <FormattedMessage
            id="app.audiences.actions.edit.otherPublisher"
            defaultMessage="Audiences of other accounts are not editable"
        />
    ) : (
        <FormattedMessage id="app.audiences.actions.edit" defaultMessage="Edit Audience" />
    );

const ArchiveAudienceTooltipContent = ({
    isAudienceArchived,
    isAnotherPublisherAudience,
    isAudienceBeingUsed,
    isPredictiveAudience,
}) =>
    isAnotherPublisherAudience ? (
        <FormattedMessage
            id="app.audiences.actions.archive.disable"
            defaultMessage="Audiences of other publishers are not editable"
        />
    ) : isAudienceArchived ? (
        !isPredictiveAudience ? (
            <FormattedMessage id="app.audiences.actions.unarchive" defaultMessage="Unarchive Audience" />
        ) : (
            <FormattedMessage
                id="app.audiences.actions.unarchive_predictive"
                defaultMessage="Cannot unarchive predictive Audiences - please create a new audience"
            />
        )
    ) : isAudienceBeingUsed ? (
        <FormattedMessage id="app.audiences.actions.archive.used" defaultMessage="Cannot archive used audience" />
    ) : (
        <FormattedMessage id="app.audiences.actions.archive" defaultMessage="Archive Audience" />
    );

const AudienceActionsCellRenderer = ({ data, onDefaultExclude, onAudienceArchive, reportType, context, ...rest }) => {
    const dispatch = useDispatch();
    const account = useSelector(selectedAccountSelector);
    const { status, accountPublisherId, excludeFromCampaigns, audienceType, externalAudienceId } = data;
    const [isDefaultExclude, setIsDefaultExclude] = useState(excludeFromCampaigns);
    const [isAudienceArchived, setIsAudienceArchived] = useState(status === AUDIENCE_STATUS.ARCHIVED);
    const openAudienceEditor = useOpenAudienceEditor(reportType, audienceType);

    const buttonConfigs = [
        {
            onClick: () => openAudienceEditor(data),
            isDisabled: isAudienceEditFormDisabled(data, account, isAudienceArchived),
            dataMetricComponent: 'EditAudienceActionButton',
            iconLabel: 'edit-o',
            tooltipContent: (
                <AudienceEditTooltipContent
                    isArchived={isAudienceArchived}
                    isAudienceDisabled={status === AUDIENCE_STATUS.DISABLED}
                    isAnotherPublisherAudience={account.id !== accountPublisherId}
                />
            ),
        },
        {
            onClick: () => {
                setIsDefaultExclude(prevState => !prevState);
                const onError = () => {
                    setIsDefaultExclude(prevState => !prevState);
                };
                const audience = { id: data.id, excludeFromCampaigns: !isDefaultExclude };
                dispatch(
                    onDefaultExclude({
                        accountName: account.accountId,
                        audience,
                        reportType,
                        onError,
                    })
                );
            },
            isDisabled:
                isAudienceDefaultExcludeDisabled(data, account, isAudienceArchived) || onDefaultExclude === undefined,
            dataMetricComponent: 'DefaultExcludeActionButton',
            iconLabel: 'exclude-audience',
            buttonColor: isDefaultExclude ? BUTTON_COLOR_OPTIONS.RED : BUTTON_COLOR_OPTIONS.GRAY,
            tooltipContent: (
                <FormattedMessage id="app.audiences.actions.defaultExclude" defaultMessage="Default Exclude Audience" />
            ),
        },
        {
            onClick: () => {
                setIsAudienceArchived(prevState => !prevState);
                const onError = () => {
                    setIsAudienceArchived(prevState => !prevState);
                };
                const audience = {
                    id: data.id,
                    status: !isAudienceArchived ? AUDIENCE_STATUS.ARCHIVED : AUDIENCE_STATUS.NO_ACTIVITY_YET,
                    segmentType: audienceType,
                    externalId: externalAudienceId,
                };
                dispatch(
                    onAudienceArchive({
                        accountName: account.accountId,
                        audience,
                        reportType,
                        onError,
                    })
                );
            },
            isDisabled: isAudienceChangingStateDisabled(data, account, isAudienceArchived),
            dataMetricComponent: 'archiveAudienceActionButton',
            iconLabel: isAudienceArchived ? 'unarchive' : 'archive',
            tooltipContent: (
                <ArchiveAudienceTooltipContent
                    isAudienceArchived={isAudienceArchived}
                    isAnotherPublisherAudience={account.id !== accountPublisherId}
                    isAudienceBeingUsed={data.campaignUsing !== '0'}
                    isPredictiveAudience={isPredictiveAudience(data.type)}
                />
            ),
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

AudienceActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    onEdit: PropTypes.func,
    onDefaultExclude: PropTypes.func,
    onTogglePolicyState: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default AudienceActionsCellRenderer;
