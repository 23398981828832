import React, { useEffect, useMemo, useRef } from 'react';
import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cloneDeep, get, isEqual, isNil, set, unset } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n/components/FormattedMessage';
import { ERROR_CODES } from 'services/api';
import { useApi } from 'services/api/hooks';
import { useUserSettingsApi } from 'services/api/userSettingsApi/useUserSettingsApi';
import { USER_SETTINGS_QUERY_KEY } from 'services/api/userSettingsApi/userSettingsApiFactory';
import { REPORT_TYPE } from '../../../campaigns/config/reportsBaseConfig';
import addIndication from '../../Indications/flows/addIndication';
import { GTM_EVENTS, gtmTracker } from '../../gtmTracker';
import { getQueryParam } from '../../query-params/queryParamUtils';
import { immutableMergeWith } from '../../utils/customMergers';
import { unsetLocalStorage, useLocalStorage } from '../useLocalStorage';
import { useMemoryStorage } from '../useMemoryStorage';
import {
    catchHiddenRequiredColumns,
    getBatchDBSettingCaller,
    isEmptyAccountId,
    omitAccountDependentData,
} from './DBStorageUtils';
import { useRemoteDeleteCallback } from './useRemoteDeleteCallback';
import { transformUserSettingsToGW } from './userSettingsTransformers';

const failureIndication = {
    message: <FormattedMessage id="app.user.settings.save.failure" defaultMessage="Unable to save user settings." />,
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const EMPTY_DATA_PLACEHOLDER = { reports: { [REPORT_TYPE.CAMPAIGN]: { filterActive: false } } };

const getNumericAccountId = rawValue => {
    const value = rawValue ? +rawValue : rawValue;

    if (isNaN(value)) {
        return;
    }

    return value;
};

const invalidateOthersPredicate = ({ queryKey, accountId }) => {
    // number literals are binded with key structure
    if (queryKey[0] === USER_SETTINGS_QUERY_KEY && queryKey.length === 3 && queryKey[2] !== accountId) {
        return true;
    }

    return false;
};

const ACCOUNT_ID = 'accountId';
const retryCount = 5;
export const useDBStorageQueryMutation = username => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const [getLocalStorageValue] = useLocalStorage();
    const [getMemoryStorageValue] = useMemoryStorage();
    // We do manual search parsing instead of hooks because of circular dependencies
    const accountId =
        getNumericAccountId(getQueryParam(search, ACCOUNT_ID)) ??
        getNumericAccountId(getMemoryStorageValue(ACCOUNT_ID)) ??
        getNumericAccountId(getLocalStorageValue(ACCOUNT_ID));
    const accountIdGetter = useCurrentValueGetter(accountId);
    const { queryClient } = useApi();
    const userSettingsApi = useUserSettingsApi();
    const batchedPatch = useMemo(
        () => getBatchDBSettingCaller({ apiCaller: userSettingsApi.patchSettings }),
        [userSettingsApi.patchSettings]
    );
    const refetchQuery = useCallback(() => {
        // TODO DEV-112101 try to upgrade react-query to use refetch callback
        queryClient.invalidateQueries([USER_SETTINGS_QUERY_KEY, username, accountIdGetter()]);
    }, [username, queryClient, accountIdGetter]);
    const lastDataRef = useRef();
    const resolveCache = useCallback(
        async fetchedData => {
            const currentData = lastDataRef.current;
            const mergedData =
                currentData?.version !== fetchedData.version
                    ? fetchedData
                    : immutableMergeWith(fetchedData, omitAccountDependentData(lastDataRef.current));
            const resolvedData = {
                accountId: accountIdGetter(),
                ...mergedData,
            };

            if (!resolvedData.version) {
                try {
                    const response = await userSettingsApi.postSettings(
                        accountIdGetter(),
                        transformUserSettingsToGW(EMPTY_DATA_PLACEHOLDER)
                    );
                    resolvedData.version = response.version;
                } catch (error) {
                    if (error.status === ERROR_CODES.CONFLICT) {
                        // We need timeout wrapping here because of refetch can be triggered only after async initialization
                        setTimeout(() => refetchQuery());
                        return {};
                    }
                }
            }

            return resolvedData;
        },
        [userSettingsApi, refetchQuery, accountIdGetter]
    );

    const { error, data } = useQuery(
        [USER_SETTINGS_QUERY_KEY, username, accountId],
        async () => {
            const newData = await userSettingsApi.getSettings({
                username,
                resolveCache,
                accountId,
            });
            queryClient.removeQueries({
                predicate: query => invalidateOthersPredicate({ ...query, accountId }),
            });

            return newData;
        },
        {
            enabled: !isNil(username) && !isEmptyAccountId(accountId),
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            keepPreviousData: true,
            retry: retryCount,
        }
    );
    const isReady = !isNil(accountId) && accountId === data?.accountId;

    if (isReady) {
        lastDataRef.current = data;
    }

    const mutationFn = useCallback(
        async ({ key, value = null, context: { skipRemoteMutation, version } }) => {
            if (skipRemoteMutation) {
                return;
            }

            try {
                const patchData = set({ version }, key, value);
                await batchedPatch(accountIdGetter(), patchData);
            } catch (error) {
                if (error.status === ERROR_CODES.CONFLICT) {
                    refetchQuery();
                    return;
                }
                dispatch(addIndication(failureIndication));
            }
        },
        [refetchQuery, dispatch, batchedPatch, accountIdGetter]
    );
    const { mutate } = useMutation(mutationFn, { mutationKey: [USER_SETTINGS_QUERY_KEY, username, accountId] });
    const customSyncMutate = useCallback(
        ({ key, value, context }) => {
            const prevData = queryClient.getQueryData([USER_SETTINGS_QUERY_KEY, username, accountIdGetter()]);
            const prevValue = get(prevData, key);
            const { autoUpdate } = context;

            if (!prevData?.version) {
                if (autoUpdate) {
                    // This is the case with race condition when auto update happened in interval between user selected
                    // an account and user settings was not fetched yet
                    gtmTracker.trackEvent(GTM_EVENTS.NOTIFICATION, { component: 'UserSettingsAutoUpdateEmptyVersion' });
                    return;
                }
                gtmTracker.trackEvent(GTM_EVENTS.NOTIFICATION, { component: `UserSettingsUpdateEmptyVersion${key}` });
            }

            catchHiddenRequiredColumns(prevData, key, value);

            if (isEqual(value, prevValue)) {
                return;
            }

            context.version = prevData?.version;
            const cacheData = cloneDeep(prevData || {});
            set(cacheData, key, value);
            queryClient.setQueryData([USER_SETTINGS_QUERY_KEY, username, accountIdGetter()], cacheData);

            mutate({ key, value, context });
        },
        [queryClient, mutate, username, accountIdGetter]
    );

    const getRemoteDeleteCallback = useRemoteDeleteCallback();

    const customRemove = useCallback(
        async key => {
            const prevData = queryClient.getQueryData([USER_SETTINGS_QUERY_KEY, username, accountIdGetter()]);

            const remoteDeleteCallback = getRemoteDeleteCallback(key, prevData, accountIdGetter());
            const response = await remoteDeleteCallback();
            if (!response) {
                return;
            }
            const cacheData = cloneDeep(prevData || {});
            cacheData.version = response.version;
            unset(cacheData, key);

            queryClient.setQueryData([USER_SETTINGS_QUERY_KEY, username, accountIdGetter()], cacheData);
        },
        [queryClient, username, getRemoteDeleteCallback, accountIdGetter]
    );

    // TODO DEV-126903 clean this effect
    // This is a rollout feature to be able to go back to the old app version
    useEffect(() => {
        if (!username) {
            return;
        }
        unsetLocalStorage(username, USER_SETTINGS_QUERY_KEY);
    }, [username]);

    return { isReady, error, data, mutate: customSyncMutate, remove: customRemove };
};
