import PropTypes from 'prop-types';
import { COMPONENT_STATUS } from '../../../../services/constants';
import HeaderLoading from '../../../campaigns/modules/common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import { NameEdit } from '../../../campaigns/modules/common-campaign-form/components/Name/NameEdit/NameEdit';
import { scheduledReportNameValidations } from '../../validations/scheduledReportNameValidations';
import styles from './scheduledReportsEditHeader.module.scss';

export const ScheduledReportsEditHeader = ({ status }) => {
    return status === COMPONENT_STATUS.LOADING ? (
        <HeaderLoading />
    ) : (
        <div className={styles['section-container']}>
            <NameEdit validations={scheduledReportNameValidations} field="reportName" />
        </div>
    );
};

ScheduledReportsEditHeader.propTypes = {
    /** Status of the Component */
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
    collapsed: PropTypes.bool,
};
