import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import CustomContextualTopicsSection from '../components/CustomContextualTopicsSection/CustomContextualTopicsSection';
import CustomContextualSizeEstimationWidget from '../components/SegmentTopics/CustomContextualSizeEstimationWidget/CustomContextualSizeEstimationWidget';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const CustomContextualEditorPage = ({ onCancel, setMediumDrawer, resetDrawerSize }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = useAudienceEditorState({
        mode,
        reportType: REPORT_TYPE.CUSTOM_CONTEXTUAL,
    });
    const { formatMessage } = useIntl();

    // Set drawer mode to medium when mounted and revert it to small when close the drawer
    useEffect(() => {
        setMediumDrawer();
        return () => resetDrawerSize();
    }, [setMediumDrawer, resetDrawerSize]);

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={formatMessage({ id: `app.actionButtons.${mode}.save`, defaultMessage: 'Save' })}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="SegmentEditor"
            >
                <div className={classNameBuilder('audience-editor', 'widget')}>
                    <CustomContextualTopicsSection />
                    <CustomContextualSizeEstimationWidget />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const CustomContextualEditorPageWithFormDataProvider = withFormDataProvider(CustomContextualEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { CustomContextualEditorPageWithFormDataProvider as CustomContextualEditorPage };
