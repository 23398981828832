import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import FormattedMessageCellRenderer from './FormattedMessageCellRenderer';

class EnumValueCellRenderer extends Component {
    render() {
        const { msgIdPrefix, value, emptyValue, ...rest } = this.props;
        const msgId = `${msgIdPrefix}.${value}`;

        return <FormattedMessageCellRenderer value={msgId} defaultValue={value || emptyValue} {...rest} />;
    }
}

EnumValueCellRenderer.propTypes = {
    /** Cell suffix msg */
    value: PropTypes.string,
    /** Value to render when value is missing */
    emptyValue: PropTypes.string,
    /** Cell msgId Prefix */
    msgIdPrefix: PropTypes.string,
    ...FormattedMessageCellRenderer.propTypes,
};

EnumValueCellRenderer.defaultProps = {
    emptyValue: EMPTY_FIELD_VALUE,
};

export default EnumValueCellRenderer;
