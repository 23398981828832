import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms/hooks/useFormState';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { errorMessagesUtils } from '../../../services/utils';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { BILLING_AND_PAYMENTS_ROUTE } from '../config';
import { PAYMENT_TYPE } from '../config/PaymentType';
import { usePaymentContext } from '../providers/payment-providers/PaymentProvider/PaymentContext';
import { useBillingActions, useBillingData } from './useBillingData';
import { usePaymentProviderAccount } from './usePaymentProviderAccount';

const ORIGINAL_MESSAGES_LIST = [
    'ccb.add_payment.error.Generic',
    'ccb.add_payment.card.already.exists',
    'ccb.add_payment.legal.entities.MISMATCH',
    'ccb.add_payment.INTERNAL_ERROR',
    'ccb.add_payment.INVALID_CCV',
    'ccb.add_payment.INVALID_EXPIRATION_DATE',
    'ccb.add_payment.INVALID_ZIPCODE',
    'ccb.add_payment.INVALID_NUMBER',
    'ccb.add_payment.EXPIRED',
    'ccb.add_payment.DECLINED',
    'ccb.add_payment.MORE_THAN_ONE',
    'ccb.add_payment.AUTHENTICATION_ERROR',
    'ccb.add_payment.AT_RISK',
];

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="paymentMethod.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const CombinedFormattedMessage = ({ messageCode }) => (
    <>
        <FormattedMessage id={messageCode} />
        <FormattedMessage id="paymentMethod.save.error.message.refresh" />
    </>
);

const refreshErrorIndication = e => ({
    message: ORIGINAL_MESSAGES_LIST.includes(e.messageCode) ? (
        <CombinedFormattedMessage messageCode={e.messageCode} />
    ) : (
        <FormattedMessage id="paymentMethod.save.error.message" />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="paymentMethod.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const successIndication = {
    message: (
        <FormattedMessage
            id="paymentMethod.creator.success"
            defaultMessage="We are working on updating your payment method and funds, this may take up to 15 minutes to show."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};
const paymentMethodType = PAYMENT_TYPE.CARD;

export const useAddPaymentMethod = () => {
    const dispatch = useDispatch();
    const { accountName } = useAccount();
    const { generatePaymentMethodToken } = usePaymentContext();
    const { createPaymentMethod } = useBillingActions();

    return useCallback(
        async formData => {
            try {
                const { paymentMethodToken, error } = await generatePaymentMethodToken({ userData: formData });
                if (error) {
                    dispatch(addIndication(generateErrorIndication(error)));
                    throw error;
                }

                await createPaymentMethod({
                    accountId: accountName,
                    paymentMethod: {
                        ...formData,
                        additionalData: { paymentMethodToken },
                    },
                });
                dispatch(addIndication(successIndication));

                return true;
            } catch (e) {
                dispatch(addIndication(refreshErrorIndication(e)));
                throw e;
            }
        },
        [accountName, generatePaymentMethodToken, createPaymentMethod, dispatch]
    );
};

export const useCardPaymentMethodCreatorState = () => {
    const { isPaymentMethodValid } = usePaymentContext();
    const { isError: isErrorBillingData, isSuccess: isSuccessBillingData, billingData } = useBillingData();
    const { isError: isErrorPaymentProviderAccount, isSuccess: isSuccessPaymentProviderAccount } =
        usePaymentProviderAccount({ paymentMethodType });
    const fetchedFormData = useMemo(() => {
        const { vatId } = billingData;
        return {
            isSuccess: isSuccessBillingData && isSuccessPaymentProviderAccount,
            data: { vatId },
            isError: isErrorBillingData || isErrorPaymentProviderAccount,
        };
    }, [
        billingData,
        isErrorBillingData,
        isSuccessBillingData,
        isErrorPaymentProviderAccount,
        isSuccessPaymentProviderAccount,
    ]);

    const { submit } = useFormState({ fetchedFormData });
    const addPaymentMethod = useAddPaymentMethod();
    const handleSubmit = useCallback(() => {
        if (!isPaymentMethodValid()) {
            throw new Error('Invalid payment method');
        }

        return submit(addPaymentMethod, BILLING_AND_PAYMENTS_ROUTE);
    }, [isPaymentMethodValid, submit, addPaymentMethod]);

    return {
        submit: handleSubmit,
    };
};
