import React, { useCallback, useMemo } from 'react';
import { isEmpty, keyBy, noop } from 'lodash';
import { every } from 'lodash/collection';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { isErrorValidation } from 'modules/taboola-common-frontend-modules/validations/utils';
import { MessageWithRemoveIndication } from '../MessageWithRemoveIndication/MessageWithRemoveIndication';
import { siteResourceFieldKeyName } from '../const';
import { isSiteTargetingRestrictionEnforced } from '../utils/isSiteTargetingRestrictionEnforced';
import { isValidItem } from '../utils/isValidItem';
import { useAllowedSites } from './useAllowedSites';
import { useConvertSitesToDropdownFormat } from './useConvertSitesToDropdownFormat';
import { useInvalidSites } from './useInvalidSites';

const listValidations = [
    {
        validationFn: list => !(list || []).some(({ loading }) => loading),
        messageId: 'campaign.editor.targeting.site.error.loading',
        defaultMessage: 'Some sites are still being loaded.',
        events: 'only_submit',
    },
    {
        validationFn: (list, _, dependencies) => (list || []).every(item => isValidItem({ item, ...dependencies })),
        messageId: 'campaign.editor.targeting.site.unavailable.sites',
        defaultMessage: 'Unavailable Sites',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (list, _, dependencies) =>
            every(list, item => isSiteTargetingRestrictionEnforced({ item, ...dependencies })),
        messageId: 'campaign.editor.targeting.site.unsupported.site.targeting',
        defaultMessage:
            'Please note: This campaign will not serve on the selected site because one of the following is applied: DMA targeting, Region targeting, City targeting, Zip Code targeting, Connection Type targeting, Topic Segments targeting, Audience or 3rd Party Trackers.',
        severity: INDICATION_TYPES.WARNING,
    },
];
const EMPTY_LIST = [];
const EMPTY_OBJECT = {};

export const useSiteTargeting = ({
    siteListKey,
    field,
    readonly,
    onChangeHandler = noop,
    validations: externalValidations = EMPTY_LIST,
    validationDependencies: externalDependencies = EMPTY_OBJECT,
    isCurrentCampaignTargeting,
}) => {
    const { data: allowedSites, isReady: isAllowedSitesReady } = useAllowedSites(siteListKey, field);
    const { [COMMON_FLAGS.USE_PUBLISHER_DESCRIPTION_PREFIX_FOR_PUBLISHER]: affiliateIdsToUseOnlyPrefix } =
        useCommonConfig(COMMON_FLAGS.USE_PUBLISHER_DESCRIPTION_PREFIX_FOR_PUBLISHER);
    const validations = useMemo(() => [...listValidations, ...externalValidations], [externalValidations]);
    const validationDependencies = useMemo(
        () => ({
            allowedSites,
            readonly,
            currentCampaignTargeting: isCurrentCampaignTargeting,
            field,
            affiliateIdsToUseOnlyPrefix,
            ...externalDependencies,
        }),
        [allowedSites, readonly, isCurrentCampaignTargeting, field, affiliateIdsToUseOnlyPrefix, externalDependencies]
    );

    const {
        value = EMPTY_LIST,
        onChange: onChangeOrigin,
        isDirty,
        failedValidationData: failedValidationDataRaw,
        scrollRef,
    } = useFormValidatedValue({
        field,
        isReady: isAllowedSitesReady && !readonly,
        validations,
        validationDependencies,
    });
    const onChange = useCallback(
        value => {
            onChangeOrigin(value);
            onChangeHandler();
        },
        [onChangeOrigin, onChangeHandler]
    );
    const siteItems = useConvertSitesToDropdownFormat(value);
    const invalidSites = useInvalidSites(siteItems, allowedSites, isAllowedSitesReady && !readonly);
    const addOne = useCallback(value => onChange((prev = []) => [{ ...value, isTrusted: true }, ...prev]), [onChange]);
    const addMany = useCallback(list => onChange((prev = []) => [...list, ...prev]), [onChange]);
    const deleteOne = useCallback(
        ({ value }) =>
            onChange((prev = []) =>
                prev.filter(({ [siteResourceFieldKeyName]: currentValue }) => currentValue !== value)
            ),
        [onChange]
    );
    const deleteMany = useCallback(
        sites => {
            const deleteMap = keyBy(sites, siteResourceFieldKeyName);
            onChange((prev = []) =>
                prev.filter(({ [siteResourceFieldKeyName]: currentValue }) => !deleteMap[currentValue])
            );
        },
        [onChange]
    );
    const clearAll = useCallback(() => onChange([]), [onChange]);
    const failedValidationData = useMemo(() => {
        if (isEmpty(failedValidationDataRaw?.indicationType) || readonly) {
            return {};
        }
        if (
            isErrorValidation(failedValidationDataRaw, 'indicationType') ||
            isCustomValidationMessage(failedValidationDataRaw)
        ) {
            return failedValidationDataRaw;
        }

        return {
            ...failedValidationDataRaw,
            message: <MessageWithRemoveIndication handleClearInvalidItems={deleteMany} invalidItems={invalidSites} />,
        };
    }, [failedValidationDataRaw, readonly, deleteMany, invalidSites]);

    return {
        siteItems,
        isDirty,
        onChange,
        invalidSites,
        allowedSites,
        isAllowedSitesReady,
        addOne,
        addMany,
        deleteOne,
        deleteMany,
        clearAll,
        failedValidationData,
        scrollRef,
    };
};

const isCustomValidationMessage = failedValidationDataRaw =>
    failedValidationDataRaw?.message?.props?.id === 'campaign.editor.targeting.site.unsupported.site.targeting';
