import { matchPath } from 'react-router';
import { MODULE_TEMPLATE } from 'config/routes/module';
import { MODULE_NAME } from 'modules/campaigns/config/routes/module';

export const getModuleName = (pathname = window.location.pathname) => {
    const match = matchPath(pathname, { path: MODULE_TEMPLATE }) || { params: {} };
    const {
        params: { module = MODULE_NAME },
    } = match;

    return module;
};
