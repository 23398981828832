export const ENTITIES_TYPES = {
    CAMPAIGN: 'CAMPAIGN',
    ADVERTISER: 'ADVERTISER',
};

export const entityTypeOptions = [
    {
        id: ENTITIES_TYPES.ADVERTISER,
    },
    {
        id: ENTITIES_TYPES.CAMPAIGN,
    },
];
