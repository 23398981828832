import { recommendationsApi } from '../../../services/api';
import {
    requestFetchRecommendationsIndication,
    fetchRecommendationsIndicationSuccess,
    fetchRecommendationsIndicationError,
} from '../actions';

export const fetchRecommendationsIndication = accountName => async dispatch => {
    dispatch(requestFetchRecommendationsIndication());

    try {
        const response = await recommendationsApi.getRecommendationsIndication(accountName);
        dispatch(fetchRecommendationsIndicationSuccess(response.count));
    } catch (error) {
        dispatch(fetchRecommendationsIndicationError(error));
    }
};

export default fetchRecommendationsIndication;
