import { useCallback } from 'react';
import { isFunction } from 'lodash';
import { customMergeWith, deepMerger } from 'modules/taboola-common-frontend-modules/utils/customMergers';
import { useShallowObject } from './useShallowObject';

export const useMergeWithValueCallback = (dependencies = {}) => {
    const shallowDependencies = useShallowObject(dependencies);
    const mergeCustomizerWithValueCallback = useCallback(
        function mergeCustomizer(oldVal, newVal) {
            if (isFunction(newVal)) {
                return newVal(oldVal, shallowDependencies);
            }
            return deepMerger(oldVal, newVal, mergeCustomizer);
        },
        [shallowDependencies]
    );
    const customMergeWithValueCallback = useCallback(
        (object, patch) => customMergeWith(object, patch, mergeCustomizerWithValueCallback),
        [mergeCustomizerWithValueCallback]
    );

    return customMergeWithValueCallback;
};
