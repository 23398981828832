import React, { useCallback, useEffect } from 'react';
import { Button, CloseIcon } from 'tuui';
import uuid from 'uuid/v1';
import { UrlValidatedInput } from 'modules/campaigns/modules/creative-creator/components/UrlList/UrlValidatedInput';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './urlItem.module.scss';

const validations = [];

export const UrlItem = ({ existingItems, deleteItem, pixelEventType }) => {
    const { value: id, onChange: setId } = useFormFieldValue({ field: 'id' });
    const { value, isDirty: isUrlDirty } = useFormFieldValue({
        field: 'value',
    });
    const { onChange: setStatus } = useFormFieldValue({
        field: 'status',
    });

    const deleteUrlItem = () => deleteItem(id);

    useEffect(() => {
        if (!isUrlDirty || !value) {
            return;
        }
        setStatus(COMPONENT_STATUS.ACTIVE);
    }, [setStatus, isUrlDirty, value]);

    const afterOnBlur = useCallback(() => {
        setId(id || uuid());
    }, [setId, id]);

    const { formatMessage } = useIntl();
    const pixelType = formatMessage({ id: `video.creative.creator.pixel.${pixelEventType.pixelType}.label` });

    return (
        <div className={styles['item-container']}>
            <UrlValidatedInput
                field="value"
                placeholder={formatMessage({
                    id: `video.creative.creator.pixel.${pixelType}.placeholder`,
                    defaultMessage: `Add ${pixelType} Tag Here...`,
                })}
                validations={validations}
                validationDependencies={{ existingItems }}
                className={styles['input']}
                errorMessageClass={styles['error-msg']}
                afterOnBlur={afterOnBlur}
            >
                <Button
                    aria-label="Remove url item"
                    className={styles['remove-button']}
                    onClick={deleteUrlItem}
                    variant={Button.variant.text}
                    size={Button.size.sm}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="RemoveUrlButton"
                >
                    <CloseIcon />
                </Button>
            </UrlValidatedInput>
        </div>
    );
};
