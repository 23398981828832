import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const PerformanceRuleExecutedActionCampaignNameCellRenderer = ({ data: { campaignName, campaignId } }) => (
    <FormattedMessage
        id="performance.rule.executed.actions.report.campaign.name"
        defaultMessage="{campaignName} (ID: {campaignId})"
        values={{ campaignName, campaignId }}
    />
);
