import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './campaignPickerListItemTooltip.module.scss';

const classNameBuilder = classnames.bind(styles);

const CampaignPickerListItemTooltip = ({ id, name, status, bidStrategy }) => {
    const containerClassName = classNameBuilder('item');
    const idLabelClassName = classNameBuilder('id-label');
    const statusLabelClassName = classNameBuilder('status-label');
    const nameLabelClassName = classNameBuilder('name-label');

    return (
        <Tooltip className={styles['tooltip-content']} arrow position={TOOLTIP_POSITION.TOP_START}>
            <div className={containerClassName}>
                <div className={nameLabelClassName}>{name}</div>
                <div className={idLabelClassName}>
                    <FormattedMessage id="app.campaigns.campaign.displayName" values={{ id }} />
                </div>
                {bidStrategy ? (
                    <div className={styles['bid-strategy-label']}>
                        <FormattedMessage
                            id={`app.campaigns.campaign.bidStrategy.${bidStrategy}`}
                            defaultMessage={`Bid Strategy: ${bidStrategy}`}
                        />
                    </div>
                ) : null}
                <div className={statusLabelClassName}>
                    <FormattedMessage
                        id="app.campaign.picker.drawer.status.tooltip"
                        defaultMessage="Status: {status}"
                        values={{
                            status: (
                                <FormattedMessage
                                    id={`app.campaigns.campaign.status.${status}`}
                                    defaultMessage={status}
                                />
                            ),
                        }}
                    />
                </div>
            </div>
        </Tooltip>
    );
};

CampaignPickerListItemTooltip.propTypes = {
    id: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
};

export default CampaignPickerListItemTooltip;
