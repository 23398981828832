import createCSVValueGetter from './createCSVValueGetter';

const conversionConditionsValueFormatter = ({ value, i18n }) => {
    const conditions = JSON.parse(value);

    return conditions
        .map(condition => {
            return i18n.formatMessage(
                {
                    id: `app.campaigns.reports.grid.conversion.conditions.prefix.${condition.conditionType.toLowerCase()}`,
                    defaultMessage: `${condition.conditionType}: {url}`,
                },
                { url: condition.url }
            );
        })
        .join(
            i18n.formatMessage({
                id: `app.campaigns.reports.grid.conversion.conditions.or`,
                defaultMessage: ' OR ',
            })
        );
};

export const csvConversionConditionsValueFormatter = createCSVValueGetter(conversionConditionsValueFormatter);
