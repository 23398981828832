import React, { useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { debounce } from 'lodash';
import { useSelectedAccountId } from 'hooks';
import { abtEventEmitter, TEST_CREATED } from './ABTEventEmitter';
import { ABT_DATA_NOT_READY, ABT_DATA_READY } from './middlewares';

export const ABTestContext = React.createContext({ lastExperimentId: null, eventEmitter: abtEventEmitter });

export const ABTestProvider = ({ eventEmitter, children }) => {
    const [readyForAccountId, setReadyForAccountId] = useState(null);
    const [selectedAccountId] = useSelectedAccountId();
    const isMounted = useMountedState();

    const onAddLastTests = useMemo(
        () =>
            debounce(() => {
                if (!isMounted()) {
                    return;
                }
                setReadyForAccountId(selectedAccountId);
            }, 15),
        [selectedAccountId, isMounted]
    );

    useEffect(() => {
        window?.dataLayer?.push({ event: 'optimize.activate' });
    }, []);

    useEffect(() => {
        return eventEmitter.on(TEST_CREATED, onAddLastTests);
    }, [eventEmitter, onAddLastTests]);

    useEffect(() => {
        return eventEmitter.on(ABT_DATA_READY, () => {
            window?.dataLayer?.push({ event: 'optimize.user.activate' });
            setTimeout(onAddLastTests, 50);
        });
    }, [eventEmitter, onAddLastTests]);

    useEffect(() => {
        return eventEmitter.on(ABT_DATA_NOT_READY, () => {
            eventEmitter.clear();
        });
    }, [eventEmitter]);

    const value = useMemo(
        () => ({
            eventEmitter,
            isReady: selectedAccountId === readyForAccountId,
        }),
        [eventEmitter, readyForAccountId, selectedAccountId]
    );
    return <ABTestContext.Provider value={value}>{children}</ABTestContext.Provider>;
};
