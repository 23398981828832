import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';

const SegmentListItemSizeTooltip = ({ sizeMsgIdPostfix }) => {
    return (
        <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow duration={0} delay={800}>
            <FormattedMessage
                id={`campaign.editor.targeting.my.audiences.size.tooltip.${sizeMsgIdPostfix}`}
                defaultMessage="The number of users in this audience, who had visible activity in the selected countries in the last 30 days. Does not take into account other campaign restrictions such as platform, blocked publishers etc."
            />
        </Tooltip>
    );
};

SegmentListItemSizeTooltip.propTypes = {
    sizeMsgIdPostfix: PropTypes.string,
};

export default SegmentListItemSizeTooltip;
