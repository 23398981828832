'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _dataGridUtils = require('../../dataGridUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DATA_MOCK_SIZE = 8;
var COLUMN_DEFS_MOCK_SIZE = 10;
var COLUMN_DEFS_MOCK = new Array(COLUMN_DEFS_MOCK_SIZE).fill({});

var useGridPropsCache = function useGridPropsCache(props) {
    var rowsLoading = props.rowsLoading,
        headerLoading = props.headerLoading,
        gridContainerClass = props.gridContainerClass;

    var propsRef = (0, _react.useRef)(props);
    var reportMockData = (0, _react.useMemo)(function () {
        if (!rowsLoading) {
            return null;
        }
        return (0, _dataGridUtils.createGridLoadingData)((0, _lodash2.default)(propsRef, 'current.reportData.length') || DATA_MOCK_SIZE);
    }, [rowsLoading]);

    var columnDefsLength = (0, _lodash2.default)(props, 'columnDefs.length');
    var loadingColumnDefs = (0, _react.useMemo)(function () {
        if (!rowsLoading) {
            return null;
        }
        if (!columnDefsLength) {
            return COLUMN_DEFS_MOCK;
        }
        return props.columnDefs;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsLoading, !!columnDefsLength]);

    if (rowsLoading) {
        var columnDefs = propsRef.current.columnDefs;
        return Object.assign({}, propsRef.current, {
            rowsLoading: rowsLoading,
            headerLoading: headerLoading,
            gridContainerClass: gridContainerClass,
            reportData: reportMockData,
            columnDefs: (0, _lodash2.default)(columnDefs, 'length') ? columnDefs : loadingColumnDefs
        });
    }

    propsRef.current = props;
    return props;
};

exports.default = useGridPropsCache;