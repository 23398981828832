import { callDeleteApi, callGetApi, callPostApi, callPutApi } from '../apiCallerProxy';
import { scheduledReportsApiFactory } from './scheduledReportsApiFactory';

export * from './useScheduledReportsApi';

export const { updateScheduledReport, deleteScheduledReport } = scheduledReportsApiFactory({
    callPutApi,
    callPostApi,
    callDeleteApi,
    callGetApi,
});
