import { CAMPAIGN_STATUS, REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { TOP_RESULTS_TYPE } from 'modules/campaigns/constants';
import { FILTER_TYPE } from 'modules/campaigns/modules/campaigns-reports/config/filters';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import * as FIELDS from '../../fields';
import columnDefinitions from './columnDefinitions';
import { hierarchyCampaignReportFooterColumnDefinition } from './footerColumnDefinitions';
import { graph } from './graph';
import { customColumns, defaultColumns, performanceColumns, setupColumns } from './reportPresets';

export const hierarchyCampaignReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.HIERARCHY_CAMPAIGN],
    endpoint: 'hierarchy-campaign-report-by-campaign-daily',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: hierarchyCampaignReportFooterColumnDefinition,
    searchPlaceholderMsgId: 'app.reports.byCampaign.search.placeholder',
    searchField: FIELDS.CAMPAIGN_NAME.field,
    sortMap: {
        [FIELDS.NAME.field]: FIELDS.CAMPAIGN_NAME.field,
        [FIELDS.ID.field]: 'campaignId',
    },
    initialFilters: [
        {
            id: FILTER_TYPE.CAMPAIGN_STATUS,
            type: FILTER_TYPE.CAMPAIGN_STATUS,
            values: [
                { value: CAMPAIGN_STATUS.RECENT, label: 'Recent', messageId: 'app.campaigns.campaign.status.RECENT' },
            ],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP100, label: 'Top 100', messageId: 'report.topResults.100' }],
            accountConfigurations: {
                [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'true',
                [FEATURE_FLAGS.CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
            },
        },
    ],
    filters: [
        { id: FILTER_TYPE.CAMPAIGN_STATUS, multiSelect: true },
        {
            id: FILTER_TYPE.TOP_RESULTS,
            accountConfigurations: {
                [FEATURE_FLAGS.CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
            },
        },
    ],
};

export default hierarchyCampaignReportConfig;
