import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import AdUnitType from './AdUnitType';
import AdUnitTypeFieldTooltip from './AdUnitTypeFieldTooltip';
import styles from './AdUnitType.module.scss';

export const AdUnitTypeField = () => {
    const isPermitted = usePermissions('ADS_CONSOLE_VIDEO_AD_UNIT_TYPE');

    return isPermitted ? (
        <FormField
            inputId="adUnitType"
            label={
                <FormattedMessage
                    id="video.campaign.editor.adUnitTypeFieldTooltip.title"
                    defaultMessage="Ad Unit Type"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.adUnitTypeFieldTooltip.description"
                    defaultMessage="Select Video Ad Unit types."
                />
            }
            helpText={<AdUnitTypeFieldTooltip />}
            containerClass={styles['input']}
        >
            <AdUnitType />
        </FormField>
    ) : null;
};

export default AdUnitTypeField;
