import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { validationFunctions } from '../../services';
import { withDropdownValidations } from './index';
import styles from '../../../../campaigns/components/Form/FormFields/formFields.module.scss';

const requiredFieldValidation = [
    {
        validationFn: validationFunctions.isIn,
        messageId: 'validations.error.field.mandatory',
        defaultMessage: 'This field is mandatory',
    },
];

const withDropdownField = (InnerDropdown, validateOnChange = false) => {
    class DropdownField extends Component {
        constructor(props) {
            super(props);

            const { validations, required } = this.props;

            this.DropdownComponent = this.getDropdownComponent([
                ...(required ? requiredFieldValidation : []),
                ...validations,
            ]);
        }

        getDropdownComponent = validations =>
            validations && validations.length
                ? withDropdownValidations(
                      this.props.disableMessageIndication ? InnerDropdown : withIndication(InnerDropdown),
                      validations,
                      validateOnChange
                  )
                : InnerDropdown;

        render() {
            const { DropdownComponent } = this;
            const { id, containerClass, ...rest } = this.props;

            return (
                <FormField {...rest} containerClass={`${styles['input']} ${containerClass}`} inputId={id}>
                    <DropdownComponent id={id} {...rest} />
                </FormField>
            );
        }
    }
    DropdownField.propTypes = {
        ...FormField.propTypes,
        ...InnerDropdown.propTypes,
        containerClass: PropTypes.string,
        validations: PropTypes.array,
        // if true, removes wrapping error indication
        disableMessageIndication: PropTypes.bool,
    };

    DropdownField.defaultProps = {
        containerClass: '',
        validations: [],
    };

    return DropdownField;
};

export default withDropdownField;
