import { useMemo } from 'react';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { queryParamHookFactory } from '../../../modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from '../../../modules/taboola-common-frontend-modules/storage';

const mockChatAgentUrlQueryParam = 'mockChatAgent';

const useMockChatAgentParam = queryParamHookFactory(mockChatAgentUrlQueryParam, { persist: PERSISTENCE_TYPE.MEMORY });

export const useChatAgentMockUrl = () => {
    const isPermitted = usePermissions('SHOW_GRID_LOAD_TIME');
    const [value] = useMockChatAgentParam();
    const url = useMemo(
        () => (value && isPermitted ? `${window.origin}/resources/html/chat-agent.html` : ''),
        [value, isPermitted]
    );

    return url;
};
