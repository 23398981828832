const countryPrefix = countryCode => `/authenticated-resources/countries/${countryCode}`;

export const createGeoTargetingApi = ({ callGetApi, callPostApi }) => {
    return {
        getCountryRegions: countryCode => callGetApi(`${countryPrefix(countryCode)}/regions`),
        getCountryDMAs: countryCode => callGetApi(`${countryPrefix(countryCode)}/dma`),
        getCities: (countryCode, searchText, page, pageSize) =>
            callGetApi(`${countryPrefix(countryCode)}/cities`, {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
            }),

        getZipCodes: (countryCode, ids) => callPostApi(`${countryPrefix(countryCode)}/zip-codes`, { ids }),
    };
};
