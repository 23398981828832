import { generatePath } from 'react-router';
import { ENTITY_PARAM_NAME, FORM_MODE_TO_PATH_MAP, MODE_PARAM_NAME, MODULE_PARAM_NAME } from 'config/routes';
import {
    ACCOUNT_ID_PARAM_NAME,
    CONVERSION_ID_PARAM_NAME,
    ENTITY_TO_PATH_MAP,
    MODULE_NAME,
} from 'modules/tracking/config';
import { FUNNEL_ID_PARAM_NAME } from '../config/routes/funnel';

export const generateConversionFormPath = (
    pathTemplate,
    { module = MODULE_NAME, entity, mode, accountId, conversionId }
) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    const path = generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
        [CONVERSION_ID_PARAM_NAME]: conversionId,
    });
    return path;
};

export const generateFunnelFormPath = (pathTemplate, { module = MODULE_NAME, entity, mode, accountId, funnelId }) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    const path = generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
        [FUNNEL_ID_PARAM_NAME]: funnelId,
    });
    return path;
};
