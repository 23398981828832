import React from 'react';
import PropTypes from 'prop-types';
import withValidations from './withValidations';

const defaultInputEvents = { onBlur: { validateEventValue: false }, onKeyUp: { validateEventValue: false } };

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withInputValidations = (
    InputComponent,
    validations = [],
    events = defaultInputEvents,
    validateOnValueChange = false
) => {
    const ValidationsWrapper = withValidations(InputComponent, {
        validations,
        validationTriggerEvents: events,
        validateOnValueChange,
    });
    const InputWithValidations = ({ id, validationId, value, ...rest }) => (
        <ValidationsWrapper valueToValidate={value} validationId={validationId || id} value={value} id={id} {...rest} />
    );

    InputWithValidations.propTypes = {
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onValidationChange: PropTypes.func,
        triggerValidation: PropTypes.bool,
        id: PropTypes.string.isRequired,
        validationId: PropTypes.string,
    };

    return InputWithValidations;
};

export default withInputValidations;
