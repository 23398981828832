import React, { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import { DeleteOutlineIcon, KeyboardArrowDownIcon } from 'tuui';
import { SuggestionContentTitle } from 'modules/campaigns/components/SuggestionContentTitle/SuggestionContentTitle';
import { ContentDescriptionItem } from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/fields';
import { ContentCtaDropdown } from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/fields/ContentCtaDropdown/ContentCtaDropdown';
import { UrlValidatedInput } from 'modules/campaigns/modules/creative-creator/components/UrlList/UrlValidatedInput';
import { AI_CREATE_CAROUSEL_EVENTS_PREFIX } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { useCreateFormSelectedCampaignId } from 'modules/campaigns/modules/creative-creator/hooks/useCreateFormSelectedCampaignId';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { MediaPreview } from '../MediaPreview/MediaPreview';
import commonStyles from '../CarouserlCard/CarouselCard.module.scss';
import styles from './CarouselCardEdit.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CarouselCardEdit = ({ dragContent, collapsed, toggleCollapsed, deleteCard, thumbnail, readOnly }) => {
    const { formatMessage } = useIntl();
    const campaignId = useCreateFormSelectedCampaignId();
    const [size, setSize] = useState('');
    const onLoad = useCallback((width, height) => setSize(`${width}*${height}`), []);

    return (
        <div className={classNameBuilder('wrapper', { collapsed })}>
            <div className={classNameBuilder('header', 'box')}>
                {!readOnly && dragContent}
                <div className={styles['card-details']} onClick={toggleCollapsed}>
                    <FormattedMessage id="creative.creator.carousel.cards.details" defaultMessage="Card Details" />
                </div>
                {!readOnly && (
                    <DeleteOutlineIcon className={commonStyles['icon']} onClick={deleteCard} aria-label="Delete Card" />
                )}
                {!readOnly && (
                    <KeyboardArrowDownIcon
                        className={commonStyles['icon']}
                        onClick={toggleCollapsed}
                        aria-label="Toggle Card"
                    />
                )}
            </div>
            <div className={classNameBuilder('media-preview-wrapper', 'box')}>
                <MediaPreview thumbnail={thumbnail} className={styles['media']} onLoad={onLoad} />
                <div>
                    <div className={styles['media-title']}>
                        <FormattedMessage id="creative.creator.carousel.cards.size" defaultMessage="Size" />
                    </div>
                    <div className={styles['media-size']}>{size}</div>
                </div>
            </div>
            <div className={classNameBuilder('url-wrapper', 'box')}>
                <span className={styles['input-label']}>
                    <FormattedMessage id="creative.creator.carousel.cards.url" defaultMessage="URL" />
                </span>
                <UrlValidatedInput className={styles['input']} />
            </div>
            <div className={styles['box']}>
                <span className={styles['input-label']}>
                    <FormattedMessage id="creative.creator.carousel.cards.title" defaultMessage="Headline" />
                </span>
                <div className={styles['input']}>
                    <SuggestionContentTitle
                        placeholder={formatMessage({
                            id: 'creative.creator.carousel.cards.title.placeholder',
                            defaultMessage: 'Headline',
                        })}
                        openModal={null}
                        gaEventsPrefix={AI_CREATE_CAROUSEL_EVENTS_PREFIX}
                        campaignId={campaignId}
                    />
                </div>
            </div>
            <div className={styles['box']}>
                <span className={styles['input-label']}>
                    <FormattedMessage id="creative.creator.carousel.cards.description" defaultMessage="Description" />
                </span>
                <div className={styles['input']}>
                    <ContentDescriptionItem
                        placeholder={formatMessage({
                            id: 'creative.creator.carousel.cards.description.placeholder',
                            defaultMessage: 'Description',
                        })}
                        showHelpTooltip={false}
                        isShowLengthIndicatorAlways
                    />
                </div>
            </div>
            <div className={styles['box']}>
                <span className={styles['input-label']}>
                    <FormattedMessage id="creative.creator.carousel.cards.cta" defaultMessage="CTA" />
                </span>
                <div className={styles['cta-input']}>
                    <ContentCtaDropdown className={styles['cta-container']} />
                </div>
            </div>
        </div>
    );
};
