import { batch } from 'react-redux';
import { difference } from 'lodash';
import { useColumnDefs, useSelectedReportId } from '../../../hooks';
import { useColumnState } from '../../../hooks/useColumnState';
import { useCustomPresetAccountId } from '../../../hooks/useCustomPresetAccountId';
import { useCustomPresetDisplayName } from '../../../hooks/useCustomPresetDisplayName';
import { useReportPresetName } from '../../../hooks/useReportPresetName';
import { useReportSorting } from '../../../hooks/useReportSorting';
import { calculateSelectedColumnIds, getCustomPresetInitialState } from '../../../services';
import { getCustomPresetWithDynamicColumns } from '../../../services/dynamicColumnsService';

export const useCustomColumnPickerData = ({ reportConfig, reportPresetName }) => {
    const [selectedReport] = useSelectedReportId();
    const { columnDefs } = useColumnDefs(reportConfig, reportPresetName);
    const { columnState, saveColumnStateByPresetAndColumnDefs } = useColumnState(
        selectedReport,
        reportPresetName,
        columnDefs
    );
    const [, setPresetDisplayName] = useCustomPresetDisplayName(selectedReport, reportPresetName);
    const [, setPresetAccountId] = useCustomPresetAccountId(selectedReport, reportPresetName);
    const selectedColumns = calculateSelectedColumnIds(columnState);
    const customPresetColumns = getCustomPresetWithDynamicColumns(reportConfig, columnDefs);
    const defaultColumns = getCustomPresetInitialState(reportConfig);
    const [reportSorting, setReportSorting] = useReportSorting();
    const [, setReportPresetName] = useReportPresetName();

    const alignSorting = selectedColumns => {
        const sortedColumns = reportSorting.map(({ colId }) => colId);
        const sortedColumnsNotPresent = difference(sortedColumns, selectedColumns);
        if (sortedColumnsNotPresent.length > 0) {
            const filteredReportSort = reportSorting.filter(({ colId }) => !sortedColumnsNotPresent.includes(colId));
            setReportSorting(filteredReportSort);
        }
    };

    const applySelectedColumns = ({ displayName, accountId, columns: selectedColumns }) => {
        batch(() => {
            setReportPresetName(reportPresetName);
            setPresetDisplayName(displayName, selectedReport, reportPresetName);
            setPresetAccountId(accountId);
            saveColumnStateByPresetAndColumnDefs({
                reportConfig,
                reportId: selectedReport,
                reportPresetName,
                selectedColumns,
                reportSorting,
                columnDefs,
            });
            alignSorting(selectedColumns);
        });
        return true;
    };

    return {
        columnDefs,
        selectedColumns,
        customPresetColumns,
        defaultColumns,
        applySelectedColumns,
    };
};
