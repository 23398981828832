import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { AI_GENERATED_IMAGE_SOURCES } from '../../constants';

export const sendGTMEventsForAIImageGeneration = ({ creativeItems = [], aiImagesPromptData = {} }) => {
    creativeItems.forEach(creative => {
        const { id: instructionId, campaignId, thumbnailUrl } = creative;
        if (aiImagesPromptData[thumbnailUrl]) {
            const { source, prompt } = aiImagesPromptData[thumbnailUrl];
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: 'AIGeneratedImagesTab - Selected as thumbnail',
                value: `Ad ID: ${instructionId}${prompt ? `, Prompt: ${prompt}` : ''}, Image: ${thumbnailUrl}`,
                taboolaCampaignId: campaignId,
            });
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: 'AIGeneratedImagesTab - Generate With AI',
                value: `Ad ID: ${instructionId}`,
                taboolaCampaignId: campaignId,
            });
            if (source === AI_GENERATED_IMAGE_SOURCES.PROMPT_TO_IMAGE) {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: 'AIGeneratedImagesTab - Prompt to Image',
                    value: `Ad ID: ${instructionId}, Prompt: ${prompt}, Image: ${thumbnailUrl}`,
                    taboolaCampaignId: campaignId,
                });
            } else if (source === AI_GENERATED_IMAGE_SOURCES.IMAGE_TO_IMAGE) {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: 'AIGeneratedImagesTab - Image to Image',
                    value: `Ad ID: ${instructionId}, Image: ${thumbnailUrl}`,
                    taboolaCampaignId: campaignId,
                });
            }
        }
    });
};
