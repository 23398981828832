import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './counter.module.scss';

const classNameBuilder = classnames.bind(styles);

const DEFAULT_WARNING_THRESHOLD = 3 / 4;
const DEFAULT_ERROR_THRESHOLD = 1;

export const Counter = ({ value, max, className, warningThreshold, errorThreshold }) => {
    const { formatNumber } = useIntl();
    const ratio = value / max;
    const colorClassName = {
        warning: ratio >= warningThreshold && ratio <= errorThreshold,
        error: ratio > errorThreshold,
    };

    return (
        <FormattedMessage
            className={classNameBuilder('container', colorClassName, className)}
            id="generic.counter"
            defaultMessage="{current}/{max}"
            values={{
                current: formatNumber(value),
                max: formatNumber(max),
            }}
        />
    );
};

Counter.propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    warningThreshold: PropTypes.number,
    errorThreshold: PropTypes.number,
    className: PropTypes.string,
};

Counter.defaultProps = {
    value: 0,
    max: 0,
    warningThreshold: DEFAULT_WARNING_THRESHOLD,
    errorThreshold: DEFAULT_ERROR_THRESHOLD,
};

export default Counter;
