import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './toolbarToggle.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ToolbarToggle = ({
    onLeftChildClick,
    leftChild,
    onRightChildClick,
    rightChild,
    isLeftToggleSelected,
    dataAutomationKey,
    rightToggleButtonClassName,
    leftToggleButtonClassName,
}) => (
    <div className={styles['container']} data-automation={dataAutomationKey}>
        <div
            className={classNameBuilder(
                'toggle-button',
                'left',
                {
                    selected: isLeftToggleSelected,
                },
                leftToggleButtonClassName
            )}
            onClick={onLeftChildClick}
        >
            {leftChild}
        </div>
        <div
            className={classNameBuilder(
                'toggle-button',
                'right',
                {
                    selected: !isLeftToggleSelected,
                },
                rightToggleButtonClassName
            )}
            onClick={onRightChildClick}
        >
            {rightChild}
        </div>
    </div>
);

ToolbarToggle.propTypes = {
    onLeftChildClick: PropTypes.func,
    leftChild: PropTypes.node,
    onRightChildClick: PropTypes.func,
    rightChild: PropTypes.node,
    isLeftToggleSelected: PropTypes.bool,
    dataAutomationKey: PropTypes.string,
};
