import React, { createRef, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
    CloseIcon,
    pxToRem,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    DropdownMenu,
    DelimiterWrapper,
    AsyncDropdown,
    CheckboxGroup,
    TextCheckbox,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './baseOpenFilter.module.scss';

const classNameBuilder = classnames.bind(styles);

const dropdownPlaceholder = (
    <FormattedMessage id="app.campaigns.reports.filters.placeholder" defaultMessage="Select from..." />
);

const textStyle = {
    color: styles['dropdown-color'],
    fontSize: styles['dropdown-fontSize'],
};
const dropdownStyles = {
    menu: provided => ({
        ...provided,
        minWidth: pxToRem(200),
    }),
    container: provided => ({
        ...provided,
        width: 'max-content',
        minWidth: pxToRem(130),
    }),
    control: provided => ({
        ...provided,
        border: 'none',
        borderRadius: 0,
        minHeight: 0,
        padding: styles['dropdown-padding'],
        backgroundColor: 'unset',
        boxShadow: 'none',
        '&:hover': {
            border: 0,
            boxShadow: 'none',
        },
        flexGrow: 1,
    }),
    placeholder: () => ({ ...textStyle, position: 'absolute' }),
    singleValue: () => ({
        ...textStyle,
        width: 'max-content',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    input: () => ({ margin: 0 }),
};

const asyncDropdownStyles = {
    ...dropdownStyles,
    singleValue: () => ({
        ...dropdownStyles.singleValue(),
        position: 'absolute',
    }),
};

const OptionRenderer = ({ label, value, isSelected, data, selectOption }) => {
    return (
        <div onClick={() => selectOption(data)} className={styles['multi-option']}>
            <CheckboxGroup selectedValues={isSelected ? [value] : []}>
                <TextCheckbox title={label} value={value} labelClassName={styles['option-color']} />
            </CheckboxGroup>
        </div>
    );
};

// We render null to allow values render in value container
const MultiValueRenderer = () => null;

const ValueContainerRenderer = ({ getValue, children }) => {
    const values = getValue();

    return (
        <div className={styles['value-container-wrapper']}>
            <div className={styles['value-container']}>{values.map(({ label }) => label).join(', ')}</div>

            {children}
        </div>
    );
};

const MULTI_SELECT_MAX_HEIGHT = 600;

export const BaseOpenFilter = ({
    initialOpen,
    type,
    typeOptions,
    onTypeChange,
    values,
    getValueOptions,
    onValuesChange,
    valuesSearchable,
    onCancel,
    onApply,
    isMultiSelectFilter,
    applyBtnMetricsAttrs,
}) => {
    const valueRef = createRef();
    const [valuesOpen, setValuesOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [valuePage, setValuePage] = useState(0);

    const isFilterIncomplete = !type || isEmpty(values);
    const applyButtonClassName = classNameBuilder('apply-button', { disabled: isFilterIncomplete });

    const openValuesDropdown = () => setValuesOpen(true);
    const closeValuesDropdown = () => setValuesOpen(false);

    useEffect(() => {
        if (type && isEmpty(values)) {
            if (valueRef.current) {
                valueRef.current.select.focus();
            }
            openValuesDropdown();
        }
    }, [type, valueRef, values]);

    const handleTypeChange = newTypeObject => {
        onTypeChange(newTypeObject);
        setValuePage(0);
    };

    const handleInputChange = newInputValue => {
        setInputValue(newInputValue);
        setValuePage(0);
    };

    const handleValueChange = newValueObject => {
        onValuesChange(newValueObject);
        setInputValue('');
    };

    const handleGetOptions = searchInput => {
        const newPage = valuePage + 1;
        setValuePage(newPage);
        return getValueOptions(type, searchInput, newPage);
    };

    const valueProps = useMemo(
        () =>
            isMultiSelectFilter(type)
                ? {
                      isMulti: true,
                      closeMenuOnSelect: false,
                      hideSelectedOptions: false,
                      optionRenderer: OptionRenderer,
                      multiValueRenderer: MultiValueRenderer,
                      valueContainerRenderer: ValueContainerRenderer,
                      clearable: false,
                      defaultValue: values,
                      blurInputOnSelect: false,
                      searchable: false,
                      maxMenuHeight: MULTI_SELECT_MAX_HEIGHT,
                  }
                : {
                      selectedValueObject: values[0],
                      blurInputOnSelect: true,
                      searchable: valuesSearchable,
                  },
        [isMultiSelectFilter, values, type, valuesSearchable]
    );

    return (
        <div className={styles['container']}>
            <div className={styles['dropdowns-container']}>
                <DelimiterWrapper>
                    <DropdownMenu
                        styles={dropdownStyles}
                        placeholder={dropdownPlaceholder}
                        autoFocus={initialOpen}
                        openMenuOnFocus
                        selectedValueObject={{ value: type }}
                        options={typeOptions}
                        onChange={handleTypeChange}
                        aria-label="Select filter type"
                    />
                    <AsyncDropdown
                        aria-label="Select filter value"
                        {...valueProps}
                        key={type}
                        className={styles['values-dropdown']}
                        selectRef={ref => {
                            valueRef.current = ref;
                        }}
                        styles={asyncDropdownStyles}
                        disabled={!type}
                        onFocus={openValuesDropdown}
                        onBlur={closeValuesDropdown}
                        placeholder={dropdownPlaceholder}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        getOptions={handleGetOptions}
                        onChange={handleValueChange}
                        menuIsOpen={valuesOpen}
                        backspaceRemovesValue={false}
                        manualMenuControl
                    />
                    <StyledButton
                        aria-label={`Cancel ${type} filter editing`}
                        className={styles['remove-button']}
                        size={STYLED_BUTTON_SIZE.SMALL}
                        type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                        iconBefore={<CloseIcon height={16} width={16} />}
                        onClick={onCancel}
                    />
                </DelimiterWrapper>
            </div>
            <StyledButton
                aria-label="Apply"
                className={applyButtonClassName}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.STRONG}
                onClick={onApply}
                disabled={isFilterIncomplete}
                {...applyBtnMetricsAttrs}
            >
                <FormattedMessage id="app.actionButtons.apply" defaultMessage="Apply" />
            </StyledButton>
        </div>
    );
};

BaseOpenFilter.propTypes = {
    initialOpen: PropTypes.bool,
    type: PropTypes.string,
    typeOptions: PropTypes.array,
    onTypeChange: PropTypes.func,
    value: PropTypes.object,
    getValueOptions: PropTypes.func,
    onValueChange: PropTypes.func,
    valuesSearchable: PropTypes.bool,
    onCancel: PropTypes.func,
    onApply: PropTypes.func,
    applyBtnMetricsAttrs: PropTypes.object,
};
