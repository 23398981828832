import React from 'react';
import { BookmarkIcon, BackgroundIcon, RecycleIcon, MotionAdIcon } from 'tuui';
import { EditOIcon as EditIcon } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GalleryItemDropdownOption } from './GalleryItemDropdownOption';

export const GALLERY_ITEM_DROPDOWN_OPTIONS = {
    IMAGE_VARIATIONS: 'IMAGE_VARIATIONS',
    CREATE_MOTION_ADS: 'CREATE_MOTION_ADS',
    REPLACE_BACKGROUND: 'REPLACE_BACKGROUND',
    SAVE_TO_MEDIA_LIBRARY: 'SAVE_TO_MEDIA_LIBRARY',
    EDIT_FALLBACK_IMAGE: 'EDIT_FALLBACK_IMAGE',
};

export const GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS = {
    [GALLERY_ITEM_DROPDOWN_OPTIONS.SAVE_TO_MEDIA_LIBRARY]: {
        label: (
            <GalleryItemDropdownOption
                icon={BookmarkIcon}
                defaultMessage="Save to Media Library"
                messageId="creative.studio.dropdown.mediaLibrary"
            />
        ),
        visibilityCondition: ({ isImage }) => isImage,
        value: GALLERY_ITEM_DROPDOWN_OPTIONS.SAVE_TO_MEDIA_LIBRARY,
        accountConfigurations: { [FEATURE_FLAGS.FAVORITED_MEDIA_ENABLED]: 'true' },
    },
    [GALLERY_ITEM_DROPDOWN_OPTIONS.REPLACE_BACKGROUND]: {
        label: (
            <GalleryItemDropdownOption
                icon={BackgroundIcon}
                defaultMessage="Replace Background"
                messageId="creative.studio.dropdown.replaceBackground"
            />
        ),
        value: GALLERY_ITEM_DROPDOWN_OPTIONS.REPLACE_BACKGROUND,
        visibilityCondition: ({ isImage }) => isImage === true,
        accountConfigurations: {
            [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND]: 'true',
            [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true',
        },
    },
    [GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS]: {
        label: (
            <GalleryItemDropdownOption
                icon={RecycleIcon}
                defaultMessage="Generate Variations"
                messageId="creative.studio.dropdown.generate.variations"
            />
        ),
        value: GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS,
        visibilityCondition: ({ isImage }) => isImage === true,
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVE_STUDIO_GENERATE_VARIATIONS]: 'true',
            [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true',
        },
    },
    [GALLERY_ITEM_DROPDOWN_OPTIONS.CREATE_MOTION_ADS]: {
        label: (
            <GalleryItemDropdownOption
                icon={MotionAdIcon}
                defaultMessage="Create Motion Ads"
                messageId="creative.studio.dropdown.create.motion.ads"
            />
        ),
        value: GALLERY_ITEM_DROPDOWN_OPTIONS.CREATE_MOTION_ADS,
        accountConfigurations: {
            [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'true',
        },
        visibilityCondition: ({ isMotionStudioEnabled, isImage }) => isImage === true && isMotionStudioEnabled,
        enableCondition: ({ isWochitAccessTokenInvalid }) => !isWochitAccessTokenInvalid,
    },
    [GALLERY_ITEM_DROPDOWN_OPTIONS.EDIT_FALLBACK_IMAGE]: {
        label: (
            <GalleryItemDropdownOption
                icon={EditIcon}
                defaultMessage="Edit Fallback Image"
                messageId="creative.studio.dropdown.editFallbackImage"
            />
        ),
        value: GALLERY_ITEM_DROPDOWN_OPTIONS.EDIT_FALLBACK_IMAGE,
        visibilityCondition: ({ isImage }) => isImage === false,
        accountConfigurations: {
            [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'true',
            [FEATURE_FLAGS.DROPDOWN_EDIT_FALLBACK_IMAGE_OPTION_ENABLED]: 'true',
        },
    },
};
