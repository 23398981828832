import React, { useCallback, useEffect, useState } from 'react';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './representativeDropdown.module.scss';

const RepresentativeDropdown = ({
    field,
    labelId,
    resourceOptions,
    component: DropdownField,
    disabledOnceSaved,
    accountId,
    isFormAccountTouched,
    ...rest
}) => {
    const { value, onChange, mode } = useFormFieldValue({ field: `representatives.${field}` });
    const handleOnChange = useCallback(({ value }) => onChange(value), [onChange]);
    const disabled = disabledOnceSaved && !!value && mode === FORM_MODES.EDIT;
    const { loadPage } = rest;
    const [initialRepresentativeView, setInitialRepresentativeView] = useState({});

    //Once account interactively changed we should reset representative
    useEffect(() => {
        if (!isFormAccountTouched) {
            return;
        }
        onChange('');
    }, [accountId, isFormAccountTouched, onChange]);

    const fetchSavedRepresentative = useCallback(async () => {
        try {
            const [list] = await loadPage({ page: 1, pageSize: 1, searchText: value });
            const representative = list.find(el => +el.value === value);
            if (!representative) {
                return;
            }
            setInitialRepresentativeView({ [value]: representative.label });
        } catch (error) {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isNull(value)) {
            return;
        }
        fetchSavedRepresentative();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles['container']} data-testid={`representativeDropdown-${field}`}>
            <DropdownField
                {...rest}
                searchable
                id={field}
                label={<FormattedMessage id={labelId} />}
                options={resourceOptions}
                selectedValueObject={value ? { value: `${value}`, label: initialRepresentativeView[value] } : null}
                onChange={handleOnChange}
                placeholder={
                    <FormattedMessage id="campaign.editor.representatives.placeholder" defaultMessage="Select..." />
                }
                containerClass={styles['field']}
                disabled={disabled}
            />
        </div>
    );
};

RepresentativeDropdown.propTypes = {
    field: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
    resourceOptions: PropTypes.array.isRequired,
    component: PropTypes.func.isRequired,
    disabledOnceSaved: PropTypes.bool,
    accountId: PropTypes.string.isRequired,
};

export default RepresentativeDropdown;
