import React from 'react';
import classnames from 'classnames/bind';
import { ExternalLink } from '../../../../../../components';
import { FORM_MODES } from '../../../../../campaigns/config';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { ViewableImpressionsField } from './ViewableImpressionsField/ViewableImpressionsField';
import { useViewableImpressions } from './hooks/useViewableImpressions';
import styles from './reachEstimatorWidget.module.scss';

const classNameBuilder = classnames.bind(styles);

const WidgetDescription = ({ noFiltersChosen }) => {
    const descriptionFormattedMessage = (
        <FormattedMessage
            id="campaign.reach.estimator.widget.description.text"
            defaultMessage="Estimated monthly viewable impressions based on the targeting you’ve set, using data from the past 30 days. "
        />
    );

    return (
        !noFiltersChosen && (
            <div className={styles['description']}>
                {descriptionFormattedMessage}
                <ExternalLink className={styles['feedback']} href="https://forms.gle/FjeyArrsbQEVCdpb9">
                    <FormattedMessage
                        id="campaign.reach.estimator.widget.description.feedback"
                        defaultMessage="Feedback"
                    />
                </ExternalLink>
            </div>
        )
    );
};

export const ReachEstimatorWidget = entityType => {
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.DUPLICATE || mode === FORM_MODES.EDIT || mode === FORM_MODES.PREVIEW;
    const viewableImpressionsData = useViewableImpressions(entityType);

    return (
        <div
            data-testid="reach-estimator-widget"
            className={classNameBuilder('container', {
                'edit-mode': isEditMode,
            })}
        >
            <div className={styles['header']}>
                <FormattedMessage id="campaign.reach.estimator.widget.header.text" defaultMessage="Reach Estimator" />
            </div>
            <ViewableImpressionsField entityType={entityType} />
            <WidgetDescription {...viewableImpressionsData} />
        </div>
    );
};
