import React from 'react';
import classnames from 'classnames/bind';
import moment from 'moment';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import StatusType from '../../config/StatusType';
import CardActions from './CardActions';
import CardStatus from './CardStatus';
import styles from './baseCard.module.scss';

const classNameBuilder = classnames.bind(styles);
const dateFormat = 'MMMM DD, YYYY';

export const BaseCard = ({
    className,
    title,
    icon,
    detailsFirstLine,
    detailsSecondLine,
    date,
    statusType,
    dateTitle,
    showCardActions,
    onDelete,
    editPath,
    isBlocked,
}) => {
    const isExpired = statusType === StatusType.EXPIRED;
    const lastBillingDate = date ? moment(date).format(dateFormat) : null;
    return (
        <div
            className={classNameBuilder('container', className, {
                'is-expired': isExpired,
                'show-card-actions': showCardActions,
            })}
        >
            <div className={styles['inner-card']}>
                <div className={styles['header-container']}>
                    <div className={styles['title']}>{title}</div>
                    {icon}
                </div>
                <div className={styles['details-container']}>
                    <div className={styles['details-first-line']}>{detailsFirstLine}</div>
                    <div className={styles['details-second-line']}>{detailsSecondLine}</div>
                </div>
                <div className={styles['bottom-container']}>
                    {statusType ? (
                        <div className={styles['bottom-status']}>
                            <CardStatus statusType={statusType} />
                        </div>
                    ) : null}
                    {lastBillingDate ? (
                        <div>
                            <div className={styles['bottom-first-line']}>{dateTitle}</div>
                            <div>{lastBillingDate}</div>
                        </div>
                    ) : null}
                    {isBlocked ? (
                        <div className={styles['bottom-message']}>
                            <FormattedMessage
                                id="billingAndPayments.creditCard.blocked.message"
                                defaultMessage="Your credit card was declined. Try removing it and adding a new card, or contact support."
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {showCardActions ? (
                <CardActions
                    className={styles['card-actions']}
                    onDelete={onDelete}
                    editPath={isExpired ? null : editPath}
                />
            ) : null}
        </div>
    );
};
