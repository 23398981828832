import React from 'react';

const AiVariationsSvgIcon = props => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M40.4219 40.7366H12.5781C12.0973 40.7366 11.5878 40.4439 11.1768 39.8654C10.7695 39.2921 10.5 38.4867 10.5 37.6045V13.6322C10.5 12.7499 10.7695 11.9445 11.1768 11.3713C11.5878 10.7928 12.0973 10.5 12.5781 10.5H40.4219C40.9027 10.5 41.4122 10.7928 41.8232 11.3713C42.2305 11.9445 42.5 12.7499 42.5 13.6322V37.6045C42.5 38.4867 42.2305 39.2921 41.8232 39.8654C41.4122 40.4439 40.9027 40.7366 40.4219 40.7366Z"
            stroke="#667686"
        />
        <rect x="14" y="33.1755" width="23" height="1.00763" fill="#B8C1CA" />
        <rect x="14" y="36.1985" width="18" height="1.00763" fill="#B8C1CA" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3574 14.3335C12.3574 13.2289 13.2529 12.3335 14.3574 12.3335H38.6431C39.7477 12.3335 40.6431 13.2289 40.6431 14.3335V27.8345C40.6431 28.9391 39.7477 29.8345 38.6431 29.8345H14.3574C13.2529 29.8345 12.3574 28.9391 12.3574 27.8345V14.3335Z"
            fill="#EEF0F2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.7319 17.0005C24.7319 18.2889 23.6766 19.3339 22.3747 19.3339C21.0728 19.3339 20.0176 18.2889 20.0176 17.0005C20.0176 15.712 21.0728 14.667 22.3747 14.667C23.6766 14.667 24.7319 15.712 24.7319 17.0005Z"
            fill="#B8C1CA"
        />
        <mask id="mask0_3604_168979" maskUnits="userSpaceOnUse" x="23" y="17" width="18" height="13">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.643 28.6678C40.643 29.2132 40.1009 29.7595 39.4644 29.8346H23.5537V17.0005H40.643V28.6678Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask0_3604_168979)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5537 24.001L29.4466 29.8347H44.1787L32.393 17.584C31.6506 16.8418 30.6483 16.8418 30.0359 17.584L23.5537 24.001Z"
                fill="#B8C1CA"
            />
        </g>
        <mask id="mask1_3604_168979" maskUnits="userSpaceOnUse" x="12" y="22" width="16" height="8">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.536 29.8345C12.9628 29.7612 12.3574 29.2331 12.3574 28.6678V22.2507H27.0896V29.8345H13.536Z"
                fill="white"
            />
        </mask>
        <g mask="url(#mask1_3604_168979)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5889 29.8345H17.071H27.6782L21.2328 23.5211C20.0664 22.3786 18.2006 22.3786 17.0342 23.5211L10.5889 29.8345Z"
                fill="#B8C1CA"
            />
        </g>
        <rect x="32" y="30" width="18" height="18" fill="white" />
        <rect x="34.5" y="32.5" width="13" height="13" rx="1.25" fill="white" />
        <path
            d="M43.8636 37.7727L44.375 36.6477L45.5 36.1364L44.375 35.625L43.8636 34.5L43.3523 35.625L42.2273 36.1364L43.3523 36.6477L43.8636 37.7727Z"
            fill="#667686"
        />
        <path
            d="M43.8636 40.2273L43.3523 41.3523L42.2273 41.8636L43.3523 42.375L43.8636 43.5L44.375 42.375L45.5 41.8636L44.375 41.3523L43.8636 40.2273Z"
            fill="#667686"
        />
        <path
            d="M40.7955 37.9773L39.7727 35.7273L38.75 37.9773L36.5 39L38.75 40.0227L39.7727 42.2727L40.7955 40.0227L43.0455 39L40.7955 37.9773ZM40.1777 39.405L39.7727 40.2968L39.3677 39.405L38.4759 39L39.3677 38.595L39.7727 37.7032L40.1777 38.595L41.0695 39L40.1777 39.405Z"
            fill="#667686"
        />
        <rect x="34.5" y="32.5" width="13" height="13" rx="1.25" stroke="#667686" />
    </svg>
);

export default AiVariationsSvgIcon;
