import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, isNil } from 'lodash';
import { useCurrentValueGetter, useNavigate } from 'hooks';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { hasUnsavedChangesSelector } from '../../../../selectors';
import setRecommendations from '../../../recommendations/actions/setRecommendations';
import { RECOMMENDATION_SOURCE } from '../../../recommendations/recommendationSource';
import { APP_EVENT_TYPE, useAppEventContext } from '../../app-events-aggregator';
import { intercomService } from '../../intercom';
import { CHAT_AGENT_ACTION_STATUS } from '../config/chatAgentActionStatus';
import {
    CHAT_AGENT_ACTION_TYPE,
    CHAT_AGENT_MESSAGE_TYPE,
    MANUAL_GA_EVENTS,
    OFF_CYCLE_ACTIONS,
} from '../config/chatAgentActionType';
import { CHAT_AGENT_COMPONENT } from '../config/chatAgentComponentGA';
import { CHAT_AGENT_VERSION_V2 } from '../config/chatAgentVersionV2';
import { transformV2ToV1 } from '../transformers/v1v2Transformers';
import { getChatAgentGAValue } from '../utils/getChatAgentGAValue';
import { isChatAgentEvent } from '../utils/isChatAgentEvent';
import { updateEventByAction } from '../utils/updateEventByAction';
import { useCanEditCell } from './useCanEditCell';
import { useChatAgentContextService } from './useChatAgentContextService';

export const useChatAgentMessageHandler = ({
    userAction,
    setCurrentEvent,
    setCellToEdit,
    setWizardStep,
    getCurrentEvent,
    sendResponseToChatAgent,
    registerAgent,
    unregisterAgent,
    setCurrentAction,
    openChat,
    client,
    setSessionId,
    notifyChatTextMessageListeners,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);
    const hasUnsavedChangesGetter = useCurrentValueGetter(hasUnsavedChanges);
    const { getUrl } = useChatAgentContextService();
    const canEditCell = useCanEditCell();
    const { push: pushAppEvent } = useAppEventContext();
    const handlers = useMemo(
        () => ({
            [CHAT_AGENT_ACTION_TYPE.OPEN_CHAT]: (action, event) => {
                openChat();
                const updatedEvent = updateEventByAction(event.data, action, {
                    status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                    actionType: action.actionType,
                });
                sendResponseToChatAgent(updatedEvent, event.source);
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.NAVIGATE]: (action, event) => {
                if (hasUnsavedChangesGetter()) {
                    userAction(
                        {
                            status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                            message: 'Currently another form is being edited, navigation is blocked.',
                            actionType: action.actionType,
                        },
                        event.source
                    );
                    return;
                }
                const url = getUrl(event.data.context);
                if (!url) {
                    userAction(
                        {
                            status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                            message: "Can't generate url based on context.",
                            actionType: action.actionType,
                        },
                        event.source
                    );
                    return;
                }

                userAction({ status: CHAT_AGENT_ACTION_STATUS.SUCCESS, actionType: action.actionType }, event.source);
                // Promise wrapper applied due to react bug when it batches useEffect callbacks synchronously after navigation
                Promise.resolve().then(() => navigate(url));
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.SET_RECOMMENDED_DATA]: (action, event) => {
                dispatch(
                    setRecommendations({
                        recommendationData: get(action, 'actionData'),
                        recommendationSource: RECOMMENDATION_SOURCE.CHAT_AGENT,
                    })
                );
                userAction(
                    {
                        status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                        actionType: action.actionType,
                        actionData: action.actionData,
                    },
                    event.source
                );
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.EDIT_GRID_CELL]: (action, event) => {
                if (!canEditCell(get(action, 'actionMetadata'))) {
                    userAction(
                        {
                            status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                            message: 'Wait until needed report loaded with proper data.',
                            actionType: action.actionType,
                        },
                        event.source
                    );
                    return;
                }
                setCellToEdit({
                    colId: get(action, 'actionMetadata.colId'),
                    rowId: get(action, 'actionMetadata.rowId'),
                });
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.FORM_WIZARD_STEP]: action => {
                setWizardStep(get(action, 'actionData.rawData'));
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.ABORT]: (action, event) => {
                userAction({ status: CHAT_AGENT_ACTION_STATUS.SUCCESS, actionType: action.actionType }, event.source);
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.CONNECT]: (action, event) => {
                if (event.source) {
                    registerAgent(event.source);
                }

                const updatedEvent = updateEventByAction(event.data, action, {
                    status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                    actionData: {
                        url: `${window.location.pathname}${window.location.search}`,
                    },
                    actionType: action.actionType,
                });
                sendResponseToChatAgent(updatedEvent, event.source);
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.DISCONNECT]: (action, event) => {
                const updatedEvent = updateEventByAction(event.data, action, {
                    actionType: action.actionType,
                    status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                });
                sendResponseToChatAgent(updatedEvent, event.source);
                unregisterAgent(event.source);
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.FORM_ACTION]: () => {
                intercomService.hide();
                return true;
            },
            [CHAT_AGENT_ACTION_TYPE.MESSAGE]: action => {
                notifyChatTextMessageListeners(action);
                if (action?.actionMetadata?.type === CHAT_AGENT_MESSAGE_TYPE.OUTBOUND) {
                    return true;
                }

                openChat();
                return true;
            },
        }),
        [
            navigate,
            dispatch,
            setCellToEdit,
            setWizardStep,
            userAction,
            hasUnsavedChangesGetter,
            sendResponseToChatAgent,
            registerAgent,
            unregisterAgent,
            openChat,
            getUrl,
            canEditCell,
            notifyChatTextMessageListeners,
        ]
    );
    const messageHandler = useCallback(
        rawEvent => {
            if (!isChatAgentEvent(rawEvent, client)) {
                return;
            }

            const event = client === CHAT_AGENT_VERSION_V2 ? transformV2ToV1(rawEvent) : rawEvent;
            const eventData = event.data;
            const sessionId = event.data.sessionId;
            const actions = eventData?.actions || [];

            if (!isNil(sessionId)) {
                setSessionId(sessionId);
            }

            if (isEmpty(actions)) {
                if (client === CHAT_AGENT_VERSION_V2) {
                    pushAppEvent({
                        type: APP_EVENT_TYPE.CHAT_TRIGGERED_ACTION,
                        status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                        displayText: 'Actions are empty.',
                    });
                    return;
                }
                sendResponseToChatAgent({
                    ...eventData,
                    actions: [{ status: CHAT_AGENT_ACTION_STATUS.FAILURE, message: 'Actions are empty.' }],
                });
                return;
            }

            const isOffCycleAction = !!OFF_CYCLE_ACTIONS[get(eventData, 'actions[0].actionType')];

            const firstAction = get(actions, '[0]', {});

            if (
                !isEmpty(getCurrentEvent()) &&
                !isOffCycleAction &&
                firstAction.actionType !== CHAT_AGENT_ACTION_TYPE.ABORT
            ) {
                sendResponseToChatAgent(
                    updateEventByAction(eventData, firstAction, {
                        ...firstAction,
                        status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                        message: `Current event is in progress.`,
                        actionData: { currentEvent: getCurrentEvent() },
                    })
                );
                return;
            }

            if (!isOffCycleAction && client !== CHAT_AGENT_VERSION_V2) {
                setCurrentEvent(eventData);
            }

            for (let action of actions) {
                if (!MANUAL_GA_EVENTS[action.actionType]) {
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: CHAT_AGENT_COMPONENT,
                        value: getChatAgentGAValue(action),
                    });
                }

                if (!isOffCycleAction && client !== CHAT_AGENT_VERSION_V2) {
                    setCurrentAction(action);
                }

                const handler = handlers[action.actionType];
                if (handler) {
                    const result = handler(action, event);

                    if (client === CHAT_AGENT_VERSION_V2) {
                        pushAppEvent({
                            type: `${APP_EVENT_TYPE.CHAT_TRIGGERED_ACTION}_${action.actionType}`,
                            status: result ? CHAT_AGENT_ACTION_STATUS.SUCCESS : CHAT_AGENT_ACTION_STATUS.FAILURE,
                            data: action.actionData,
                        });
                    }
                    if (!result) {
                        break;
                    }
                }
            }
        },
        [
            handlers,
            setCurrentEvent,
            setCurrentAction,
            getCurrentEvent,
            sendResponseToChatAgent,
            client,
            pushAppEvent,
            setSessionId,
        ]
    );

    return messageHandler;
};
