import React from 'react';

const DailySVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
        <g fill="#000" fillRule="evenodd">
            <path fillRule="nonzero" d="M5 18.876v-.752h41v.752z" />
            <path d="M37 41l1-1h-5v1H8c-1.879 0-3-1.121-3-3V13c0-1.879 1.121-3 3-3h36c1.88 0 3 1.121 3 3v25c0 1.879-1.12 3-3 3h-7zm9-28c0-1.227-.776-2-2-2H8c-1.224 0-2 .773-2 2v25c0 1.227.776 2 2 2h36c1.224 0 2-.773 2-2V13zm0 13v5l1-1v-4h-1z" />
            <path
                fillRule="nonzero"
                d="M25.875 33.124H28.5v.751h-6v-.75h2.625v-7.53c-.62.798-1.488 1.28-2.625 1.28v-.75c.934 0 1.616-.405 2.107-1.093a3.567 3.567 0 0 0 .527-1.115c.099-.436.741-.365.741.083v9.124zM35 28.5a.5.5 0 0 1 .5-.5h8a2.5 2.5 0 0 0 2.5-2.5h1v.666a6.5 6.5 0 0 1-1.708 4.392l-7.64 8.334A6.5 6.5 0 0 1 32.861 41H32.5v-1h1a1.5 1.5 0 0 0 1.5-1.5v-10zm1 .5v9.5c0 .192-.022.38-.063.559.355-.24.684-.521.978-.843l7.64-8.333c.421-.46.758-.985 1-1.55A3.484 3.484 0 0 1 43.5 29H36zm10-2.834V25.5c0-.667 1-.667 1 0 0 1.165-.57 2.197-1.445 2.834A5.5 5.5 0 0 0 46 26.166zM33.5 41h-1c-.667 0-.667-1 0-1h.36a5.5 5.5 0 0 0 3.077-.941A2.501 2.501 0 0 1 33.5 41z"
            />
        </g>
    </svg>
);

export default DailySVGIcon;
