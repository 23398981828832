import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from '../../../hooks';
import audienceLongCreationTimeIndication from '../../campaigns/config/indications/staticIndications/audienceLongCreationTimeIndication';
import { addStaticIndication, removeStaticIndication } from '../../taboola-common-frontend-modules/Indications';
import { COMMON_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';

export const useAudienceLongCreationTimeIndication = onCancel => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const isIndicationEnabled = useConfigMatch(
        {},
        {
            [COMMON_FLAGS.AUDIENCES_LONG_CREATION_TIME_INDICATION_ENABLED]: 'true',
        }
    );

    const addIndication = useCallback(() => {
        if (isIndicationEnabled) {
            dispatch(addStaticIndication(audienceLongCreationTimeIndication()));
        }
    }, [dispatch, isIndicationEnabled]);

    const removeIndication = useCallback(() => {
        if (isIndicationEnabled) {
            dispatch(removeStaticIndication(audienceLongCreationTimeIndication()));
        }
    }, [dispatch, isIndicationEnabled]);

    useEffect(() => {
        const handlePopState = event => {
            removeIndication();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, removeIndication]);

    const onCancelWrapper = useCallback(() => {
        removeIndication();
        onCancel();
    }, [onCancel, removeIndication]);

    return {
        addIndication,
        removeIndication,
        onCancelWrapper,
    };
};
