import { InfoIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ExternalLink } from '../../../../components';
import styles from './audienceCreationAdvancedOptions.module.scss';

export const AudienceCreationAdvancedOptions = () => (
    <div className={styles['container']}>
        <InfoIcon className={styles['info-icon']} fontSize="small" />
        <div className={styles['options-container']}>
            <FormField
                label={
                    <FormattedMessage id="audience.creation.type.dmp.label" defaultMessage="DMP or MMP" tagName="h4" />
                }
                description={
                    <FormattedMessage
                        id="audience.creation.type.dmp.description"
                        defaultMessage="Use your own audiences from existing integrations with a DMP or MMP - {link}"
                        values={{
                            link: (
                                <ExternalLink
                                    href="https://help.taboola.com/hc/en-us/articles/1500011417841-Send-your-1st-Party-Audiences-via-DMP"
                                    className={styles['link']}
                                >
                                    <FormattedMessage
                                        id="audience.creation.type.dmp.help.link"
                                        defaultMessage="Help Center"
                                    />
                                </ExternalLink>
                            ),
                        }}
                    />
                }
                labelClass={styles['label']}
                descriptionClass={styles['description']}
            />
            <FormField
                label={
                    <FormattedMessage id="audience.creation.type.api.label" defaultMessage="Taboola API" tagName="h4" />
                }
                description={
                    <FormattedMessage
                        id="audience.creation.type.api.description"
                        defaultMessage="Onboard your own data directly using Taboola API - {link}"
                        values={{
                            link: (
                                <ExternalLink
                                    href="https://developers.taboola.com/backstage-api/reference#onboarding-overview"
                                    className={styles['link']}
                                >
                                    <FormattedMessage
                                        id="audience.creation.type.api.help.link"
                                        defaultMessage="Dev Center"
                                    />
                                </ExternalLink>
                            ),
                        }}
                    />
                }
                labelClass={styles['label']}
                descriptionClass={styles['description']}
            />
        </div>
    </div>
);
