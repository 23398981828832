import URI from 'urijs';
import { callGetApi } from './apiCallerProxy';

// queryParams is an object. each key is a field and the value is the requested value.
// example: {start_date: '2016-12-17', end_date: '2016-12-27'}
const reportsApiCreator = apiCacheKey => (reportEndPoint, accountId, queryParams) => {
    const url = URI(`/${accountId}/reports/${reportEndPoint}`);
    if (queryParams) {
        url.addSearch(queryParams);
    }

    return callGetApi(url.toString(), { singleRequestCacheKey: apiCacheKey });
};

export default reportsApiCreator;
