import React, { createContext, useContext, useMemo } from 'react';

const defaultConfig = {
    msgIdPrefix: 'campaign.editor.targeting.3rd.party.brand.safety',
};

const ThirdPartyBrandSafetyContext = createContext(defaultConfig);

export function useThirdPartyBrandSafetyConfig() {
    return useContext(ThirdPartyBrandSafetyContext);
}

export const ThirdPartyBrandSafetyConfigOverrides = ({ overrides, children }) => {
    const config = useMemo(() => (overrides ? { ...defaultConfig, ...overrides } : defaultConfig), [overrides]);

    return <ThirdPartyBrandSafetyContext.Provider value={config}>{children}</ThirdPartyBrandSafetyContext.Provider>;
};
