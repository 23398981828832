import URI from 'urijs';

const CampaignReachEstimatorAPIPrefix = accountId => `/${accountId}/campaign/reach_estimator`;

export const reachEstimatorApiFactory = ({ callPostApi }) => ({
    getReachEstimation: ({ accountId, campaign }) => {
        const url = URI(`${CampaignReachEstimatorAPIPrefix(accountId)}`);
        return callPostApi(url.toString(), campaign);
    },
});
