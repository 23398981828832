import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGeoTargetingApi } from '../../../../services/api/geoTargetingApi';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue, validationFunctions } from '../../../taboola-common-frontend-modules/validations';

const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.paymentMethod.region.empty',
        defaultMessage: 'Enter State',
    },
];

export const usePaymentMethodRegion = () => {
    const { [COMMON_FLAGS.SHOW_REGION_FOR_COUNTRIES]: showRegionForCountries } = useCommonConfig([
        COMMON_FLAGS.SHOW_REGION_FOR_COUNTRIES,
    ]);
    const [regions, setRegions] = useState([]);
    const { value: countryCode, isDirty } = useFormFieldValue({ field: 'billingAddress.country' });
    const showRegionField = useMemo(
        () => showRegionForCountries.includes(countryCode),
        [showRegionForCountries, countryCode]
    );
    const {
        value: selectedRegion,
        onChange: onSelectRegion,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'billingAddress.region',
        validations: showRegionField ? validations : [],
    });
    const { getCountryRegions } = useGeoTargetingApi();
    const loadRegionsPage = useCallback(async () => {
        if (!getCountryRegions || !countryCode) {
            return;
        }

        const countryRegions = await getCountryRegions(countryCode);

        const list = countryRegions.map(region => ({
            value: region.name,
            label: region.value,
            code: region.name,
        }));

        return [list, countryRegions.length];
    }, [getCountryRegions, countryCode]);
    const onRegionChange = useCallback(({ value }) => onSelectRegion(value), [onSelectRegion]);

    useEffect(() => {
        if (isDirty) {
            onRegionChange({ value: null });
        }
    }, [isDirty, countryCode, onRegionChange]);

    return {
        regions,
        setRegions,
        loadRegionsPage,
        onRegionChange,
        showRegionField,
        selectedRegion,
        indicationData,
        scrollRef,
        mode,
    };
};
