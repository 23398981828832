import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityType } from 'hooks';
import { fetchCampaignSuccess } from 'modules/campaigns/actions';
import {
    CAMPAIGNS_ROUTE,
    CREATIVE_TYPE,
    ENTITY,
    FORM_MODES,
    PATH_TO_ENTITY_MAP,
    REPORT_TYPE,
} from 'modules/campaigns/config';
import { fromGWtransformers } from 'modules/campaigns/flows/flowsUtils';
import { useRealtime } from 'modules/campaigns/hooks';
import {
    DEFAULT_REPORT_PAGE,
    REPORT_ID,
    REPORT_PAGE,
    SEARCH_TERM,
} from 'modules/campaigns/modules/campaigns-reports/hooks';
import { REPORT_FILTERS } from 'modules/campaigns/modules/campaigns-reports/hooks/usePermanentReportFilters';
import { REPORT_FILTER_STATUS } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportFiltersStatus';
import { REPORT_FORCE_FETCH_FLAG } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportForceFetchFlag';
import { REPORT_SORTING } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportSorting';
import { BYPASS_URL_RESPONSE_VALIDATION_FIELD } from 'modules/campaigns/modules/creative-creator/components/UrlList/UrlValidatedInput';
import { aiTabImagePromptMapSelector } from 'modules/campaigns/modules/creative-editor';
import { fetchCreativeError, fetchCreativeSuccess } from 'modules/campaigns/modules/creative-editor/actions';
import {
    postDuplicationFilter,
    postDuplicationReportSort,
} from 'modules/campaigns/modules/creative-editor/flows/duplicateCreativeCommon';
import { useCleanCreativeEditingResources } from 'modules/campaigns/modules/creative-editor/hooks';
import { useCreativeEditorSubmitHandlerWrapper } from 'modules/campaigns/modules/creative-editor/hooks/useCreativeEditorSubmitHandlerWrapper';
import { creativesMapSelector } from 'modules/campaigns/selectors';
import {
    campaignToDropdownObjectTransformer,
    sendGTMEventsForAIImageGeneration,
} from 'modules/campaigns/services/utils';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { useCampaignsApi, useCreativesApi } from 'services/api';
import { useInitialCreativeType } from './useInitialCreativeType';

const SUCCESSFUL_PATH = `${CAMPAIGNS_ROUTE}?${REPORT_ID}=${REPORT_TYPE.CREATIVE}`;
const REALTIME_SUCCESSFUL_PATH = `${CAMPAIGNS_ROUTE}?${REPORT_ID}=${REPORT_TYPE.CREATIVE_REALTIME}`;

export const useCreativeEditorState = ({ creativeAccountId, campaignId, creativeId, sourcePath }) => {
    const {
        formAccount: { accountName },
        data,
        mode,
    } = useFormDataContext();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const dispatch = useDispatch();
    const selectedAccount = useAccount();
    const [creativeBeingEdited, setCreativeBeingEdited] = useState({});
    const creativeMap = useSelector(creativesMapSelector);
    const creativesApi = useCreativesApi();
    const aiImagesPromptData = useSelector(aiTabImagePromptMapSelector);
    const campaignsApi = useCampaignsApi();
    const { value: matchingCreativeIds } = useFormFieldValue({ field: 'matchingCreativeIds' });
    useCleanCreativeEditingResources();
    const [creativeType = CREATIVE_TYPE.IMAGE] = useInitialCreativeType();
    const { thumbnailUrl: formThumbnailUrl } = data;
    const formDataFetcher = useCallback(async () => {
        const getCampaignApi =
            creativeType === CREATIVE_TYPE.CREATIVE_TEMPLATE ? () => Promise.resolve({}) : campaignsApi.getCampaign;
        const fetchCampaign = getCampaignApi({
            accountId: creativeAccountId,
            campaignId,
            entityType,
        });

        const getCreativesApi =
            creativeType === CREATIVE_TYPE.CREATIVE_TEMPLATE
                ? () => creativeMap[creativeId] || {}
                : creativesApi.getCreative;
        const fetchCreative = getCreativesApi(creativeAccountId, campaignId, creativeId);

        try {
            const [fetchedCampaign, fetchedCreative] = await Promise.all([fetchCampaign, fetchCreative]);
            const { performanceVideoData, ...creativeCommonFields } = fetchedCreative;
            const performanceVideoFields = performanceVideoData
                ? {
                      thumbnailUrl: performanceVideoData.fallbackUrl,
                      gifUrl: performanceVideoData.gifUrl,
                      videoUrl: performanceVideoData.videoUrl,
                      recommendedFBImage: performanceVideoData.recommendedFallbackImage,
                      mediaUploadSource: performanceVideoData.mediaUploadSource,
                      motionAdsStudio: performanceVideoData.motionAdsStudio,
                  }
                : {};

            const creative = {
                ...creativeCommonFields,
                ...performanceVideoFields,
            };

            const campaign = fromGWtransformers[ENTITY.CAMPAIGN](fetchedCampaign);
            dispatch(fetchCampaignSuccess(campaign));
            dispatch(fetchCreativeSuccess(creative));
            setCreativeBeingEdited(creative);
            return {
                ...creative,
                creativeType,
                [BYPASS_URL_RESPONSE_VALIDATION_FIELD]: false,
                selectedCampaigns: [campaignToDropdownObjectTransformer(campaign)],
            };
        } catch (error) {
            dispatch(fetchCreativeError(error));
        }
    }, [
        creativeAccountId,
        campaignId,
        creativeId,
        entityType,
        creativeType,
        dispatch,
        creativesApi,
        campaignsApi,
        creativeMap,
    ]);
    const submitHandlerWrapper = useCreativeEditorSubmitHandlerWrapper(mode, creativeType);
    const { submit } = useFormState({ formDataFetcher });
    const { isRealtimeActive } = useRealtime();
    const successfulURL = useMemo(() => {
        if (mode === FORM_MODES.EDIT) {
            return isRealtimeActive ? REALTIME_SUCCESSFUL_PATH : SUCCESSFUL_PATH;
        }
        return mergeQueryParamsWithLocation(
            { pathname: sourcePath },
            {
                [SEARCH_TERM]: '',
                [REPORT_PAGE]: DEFAULT_REPORT_PAGE,
                [REPORT_SORTING]: postDuplicationReportSort,
                [REPORT_FILTERS]: postDuplicationFilter,
                [REPORT_FILTER_STATUS]: true,
                [REPORT_FORCE_FETCH_FLAG]: Date.now(),
            }
        );
    }, [sourcePath, mode, isRealtimeActive]);

    const submitHandler = useCallback(
        async props => {
            const submitHandler = submitHandlerWrapper(accountName, data, dispatch);
            const submitProps = { ...props, sourcePath, selectedAccount, creativeBeingEdited, matchingCreativeIds };
            await submit(() => submitHandler(submitProps), successfulURL);
            sendGTMEventsForAIImageGeneration({
                creativeItems: [{ id: creativeId, campaignId, thumbnailUrl: formThumbnailUrl }],
                aiImagesPromptData,
            });
        },
        [
            accountName,
            data,
            dispatch,
            sourcePath,
            selectedAccount,
            creativeBeingEdited,
            matchingCreativeIds,
            submit,
            successfulURL,
            aiImagesPromptData,
            creativeId,
            campaignId,
            formThumbnailUrl,
            submitHandlerWrapper,
        ]
    );

    return {
        submit: submitHandler,
    };
};
