import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { stripeErrorMap } from '../consts';
import { transformPaymentMethodData, transformConfirmSetupResponse } from '../transformers';

export const useStripeCardSetup = ({ clientSecret, updateElementState, elementsState }) => {
    const stripeObject = useStripe();
    const stripeElements = useElements();

    const markInvalidField = useCallback(
        error => {
            const { code = 'other' } = error;
            const errorField = stripeErrorMap[code];

            if (errorField) {
                const currentElementState = elementsState[errorField];
                updateElementState(errorField, { ...currentElementState, error });
            }
        },
        [elementsState, updateElementState]
    );

    const setupCard = useCallback(
        async ({ userData }) => {
            const card = stripeElements.getElement(CardNumberElement);
            const response = await stripeObject.confirmCardSetup(
                clientSecret,
                transformPaymentMethodData(userData, card)
            );

            const { error } = response;
            if (error) {
                markInvalidField(error);
            }

            return transformConfirmSetupResponse(response);
        },
        [markInvalidField, stripeElements, stripeObject, clientSecret]
    );

    return { setupCard };
};
