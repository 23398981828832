import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormField } from 'taboola-ultimate-ui';
import { LoadingBar } from 'modules/campaigns/components';
import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { AI_GENERATED_IMAGE_SOURCES } from 'modules/campaigns/constants';
import { IMAGE, THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { mergeAITabImagePromptMap } from 'modules/campaigns/modules/creative-editor/actions';
import { fetchAIGeneratedImagesFromImage } from 'modules/campaigns/modules/creative-editor/flows';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { ImageGallery } from '../../ImageGallery';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import { getValidationErrorMessage } from '../../services';
import { AIGeneratedTabGalleryItemDropdown } from '../AIGeneratedTabGalleryItemDropdown/AIGeneratedTabGalleryItemDropdown';
import buildImagePromptMap from '../utils/buildImagePromptMap';
import FromImageSubTabDragAndDropField from './FromImageSubTabDragAndDropField';
import FromImageSubTabUploadFields from './FromImageSubTabUploadFields';
import styles from './fromImageSubTab.module.scss';

const mediaType = { IMAGE: 'image' };

const FromImageSubTab = ({
    onSelectMedia,
    selectedAccountId,
    referenceImage,
    setReferenceImage,
    referenceImageStatus,
    setReferenceImageStatus,
    imageError,
    setErrorMessage,
}) => {
    const { value: images = [], onChange: setImages } = useFormFieldValue({
        field: 'fromImageGeneratedImages',
    });
    const [urlError, setUrlError] = useState();
    const [prompt, setPrompt] = useState();
    const [galleryStatus, setGalleryStatus] = useState(COMPONENT_STATUS.EMPTY);
    const isGalleryLoading = galleryStatus === COMPONENT_STATUS.LOADING;
    const { mode } = useFormDataContext();
    const dispatch = useDispatch();
    const { campaignId } = useMediaTabsContext();
    const { formatMessage } = useIntl();
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();
    const loadingMessage = formatMessage({
        id: 'creative.studio.generate.long.loading.message',
        defaultMessage: "Wait with us please while we're working on generating your images",
    });

    const selectMediaHandler = (data, selected) => {
        onSelectMedia(() => ({ url: data.url }), {
            id: data.id,
            mediaUploadSource: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION,
            fileType: IMAGE,
            selected,
        });
    };

    const onAddClick = async (url, metadata) => {
        setReferenceImageStatus(COMPONENT_STATUS.LOADING);
        const errorMessage = metadata
            ? null
            : await getValidationErrorMessage({
                  url,
                  mode,
                  creativeType: CREATIVE_TYPE.IMAGE,
                  mediaType,
                  isUnifiedCreativesFormDuplicateMode,
              });

        if (errorMessage) {
            setReferenceImageStatus(COMPONENT_STATUS.EMPTY);
            setUrlError(errorMessage);
            return;
        }

        setReferenceImage(url);
        setReferenceImageStatus(COMPONENT_STATUS.ACTIVE);
        setUrlError(null);
        setErrorMessage(null);
    };

    const onGenerateClick = async () => {
        setUrlError(null);
        const start = Date.now();
        const { results, generatedTextPrompt, error } = await fetchAIGeneratedImagesFromImage({
            accountId: selectedAccountId,
            campaignId,
            referenceImage,
            setStatus: setGalleryStatus,
        });

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'AIGeneratedImagesTab - Selected as thumbnail - Duration',
            value: Math.floor((Date.now() - start) / 1000),
        });

        if (error) {
            setErrorMessage(
                formatMessage({
                    id: 'creative.studio.error.upload',
                    defaultMessage: 'We were unable to process the uploaded image.',
                })
            );
            return;
        }

        setPrompt(generatedTextPrompt);
        const imageUrlToPromptMap = buildImagePromptMap({ results, source: AI_GENERATED_IMAGE_SOURCES.IMAGE_TO_IMAGE });
        setImages(results);
        dispatch(mergeAITabImagePromptMap(imageUrlToPromptMap));
    };

    return (
        <div>
            <FromImageSubTabDragAndDropField
                referenceImage={referenceImage}
                referenceImageStatus={imageError ? COMPONENT_STATUS.EMPTY : referenceImageStatus}
                onGenerateClick={onGenerateClick}
            />
            {prompt && !isGalleryLoading && (
                <FormField label="Image Prompt" containerClass={styles['form-field']}>
                    <div className={styles['prompt']}>{prompt}</div>
                </FormField>
            )}
            <FromImageSubTabUploadFields imageError={imageError} urlError={urlError} onAddClick={onAddClick} />
            {isGalleryLoading ? (
                <LoadingBar durationInSeconds={15} loadingMessage={loadingMessage} />
            ) : (
                <ImageGallery
                    recommendedImages={images}
                    addImageHandler={selectMediaHandler}
                    status={galleryStatus}
                    columns={3}
                    hideEmptyGallery
                    getItemMetricsAttributes={url => ({
                        'data-metrics-event-name': GTM_EVENTS.USABILITY,
                        'data-metrics-component': 'AIGeneratedImagesTab - Selected as thumbnail',
                        'data-metrics-taboola-campaign-id': campaignId,
                        'data-metrics-value': url ? `Image: ${url}` : '',
                    })}
                    GalleryItemDropdown={AIGeneratedTabGalleryItemDropdown}
                    shouldHideOnError
                    gap="0px 12px"
                />
            )}
        </div>
    );
};

export default FromImageSubTab;
