import { fromPairs, cloneDeep, intersection, get, groupBy, first, entries, isEmpty, keyBy } from 'lodash';
import { REPORT_FIELD_TYPE } from '../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../components/HeaderRenderers';
import { formattedNumberValueGetter, gridNumberFormatter } from '../components/ValueFormatters';
import { actionsColumnType, bypassMetadataFilterType, checkBoxSelectionColumn, numericColumn } from '../config/columns';
import { defaultCurrencyRenderParams, defaultPercentRenderParams } from '../config/fields';
import { numberFormatter } from '../config/fields/transformers';
import generateColumnId from '../utils/generateColumnId';
import { generateGraphMetricDef } from '../utils/generateGraphMetricDef';
import commonStyles from '../../../../../globalStyle/styleConsts.module.scss';

export const GRAPH_PALETTE = commonStyles.dynamicGraphColors.split(' ');
export const DEFAULT_GRAPH_PALETTE = commonStyles.defaultGraphColors.split(' ');

const nonDigitRegexp = /[^\d]/g;
export const reportUnderscoreRegexp = /^[\d\w-]+_/g;
const formatOptionsMap = {
    [REPORT_FIELD_TYPE.NUMERIC]: {},
    [REPORT_FIELD_TYPE.PERCENT]: defaultPercentRenderParams,
    [REPORT_FIELD_TYPE.MONEY]: defaultCurrencyRenderParams,
};

const NUMBER_INTL_STYLES = {
    PERCENT: 'percent',
    CURRENCY: 'currency',
};

const DEFAULT_METRIC_NOTATION = 'compact';
const DEFAULT_METRIC_CURRENCY = 'usd';
const DEFAULT_FRACTION_DIGITS = 2;

const DATA_TYPE_TO_NUMER_STYLE = {
    PERCENT: NUMBER_INTL_STYLES.PERCENT,
    MONEY: NUMBER_INTL_STYLES.CURRENCY,
};

const getMetricOptions = dataType => {
    const options = {
        maximumFractionDigits: DEFAULT_FRACTION_DIGITS,
        notation: DEFAULT_METRIC_NOTATION,
    };

    if (DATA_TYPE_TO_NUMER_STYLE[dataType]) {
        options.style = DATA_TYPE_TO_NUMER_STYLE[dataType];
    }
    if (options.style === NUMBER_INTL_STYLES.CURRENCY) {
        options.currency = DEFAULT_METRIC_CURRENCY;
    }

    return options;
};

const generateMetricColor = (id, allDynamicFields) => {
    const index = allDynamicFields.findIndex(field => field.id === id) % GRAPH_PALETTE.length;

    return GRAPH_PALETTE[index];
};

const generateMetricByDynamicField = ({ id, caption, dataType }, allDynamicFields) => {
    const metricOptions = getMetricOptions(dataType);

    return {
        field: id,
        label: caption,
        formatter: numberFormatter,
        yAxisOptions: metricOptions,
        legendOptions: metricOptions,
        labelOptions: metricOptions,
        color: generateMetricColor(id, allDynamicFields),
    };
};

const getMergedMetrics = (metrics, dynamicFields) => {
    const mergedMetrics = [
        ...metrics,
        ...dynamicFields.map(dynamicField => generateMetricByDynamicField(dynamicField, dynamicFields)),
    ];

    return mergedMetrics;
};

const flatDynamicFields = dynamicFields => {
    const mappedDynamicFields = (dynamicFields || []).map(({ id, value }) => [id, value]);

    return fromPairs(mappedDynamicFields);
};

const getMaximumFractionDigits = format => +((format || '').replace(nonDigitRegexp, '') || 0);

const getDynamicColFormatOptions = (dataType, format) => {
    const formatOptions = cloneDeep(formatOptionsMap[dataType] || formatOptionsMap[REPORT_FIELD_TYPE.NUMERIC]);

    formatOptions.maximumFractionDigits = getMaximumFractionDigits(format);

    return formatOptions;
};

const createDynamicColumnDef = (reportConfig, { caption, id, dataType, format, referenceColumnId }) => ({
    headerName: caption,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    headerComponentParams: {
        tooltipContent: () => caption,
        useOnlyValue: true,
    },
    field: id,
    colId: generateColumnId(reportConfig.id, id),
    isDynamicField: true,
    ...(referenceColumnId ? { referenceColId: generateColumnId(reportConfig.id, referenceColumnId) } : {}),
    type: [numericColumn],
    cellRendererParams: getDynamicColFormatOptions(dataType, format),
    valueFormatter: gridNumberFormatter,
    csvValueGetter: formattedNumberValueGetter,
});

const columnTypesToIgnore = [actionsColumnType, checkBoxSelectionColumn, bypassMetadataFilterType];

const isInIgnoreList = columnDef => intersection(columnTypesToIgnore, columnDef.type).length > 0;

const getColumnsDefWithDynamicFields = (reportConfig, columnDefs = [], dynamicMetadataColumns = []) => {
    const dynamicColDefs = dynamicMetadataColumns.map(column => createDynamicColumnDef(reportConfig, column));
    const jointColumns = [...columnDefs, ...dynamicColDefs];

    return jointColumns;
};

const getColumnDefsByMetadataColumns = (columnDefs = [], metadataColumns) =>
    columnDefs.filter(
        columnDef => metadataColumns[columnDef.dataField || columnDef.field] || isInIgnoreList(columnDef)
    );

export const getColumnDefsWithDynamicColumns = (reportId, reportConfig, reportMetadata) => {
    const columnDefs = reportConfig.columnsDef;
    const staticFields = reportMetadata.staticFields || [];
    const dynamicFields = reportMetadata.dynamicFields || [];

    if (isEmpty(staticFields) && isEmpty(dynamicFields)) {
        return [];
    }

    const metadataColumns = [...staticFields, ...dynamicFields].reduce(
        (result, field) => ({ ...result, [field.id]: true }),
        {}
    );

    const columnsDefToFilter = dynamicFields.length
        ? getColumnsDefWithDynamicFields({ id: reportId }, columnDefs, dynamicFields)
        : columnDefs;

    const resolvedColumnDefs = getColumnDefsByMetadataColumns(columnsDefToFilter, metadataColumns);

    return resolvedColumnDefs;
};

export const getCustomPresetWithDynamicColumns = (reportConfig, columnDefs) => {
    const customColumns = cloneDeep(get(reportConfig, ['customPreset', 'columns'], {}));
    const conversionColumns = columnDefs.filter(
        ({ isDynamicField, referenceColId }) => isDynamicField && !referenceColId
    );
    const groupedColumns = groupBy(conversionColumns, column => first(column.headerName.split(':')));
    entries(groupedColumns).forEach(([groupTitle, columns]) => {
        if (!isEmpty(columns)) {
            const columnIds = columns.map(({ colId }) => colId);
            customColumns[groupTitle] = columnIds;
        }
    });

    return customColumns;
};

export const getGraphMetricsWithDynamicFields = ({ graphConfig, metadata = {}, locale, currency }) => {
    const { metrics } = graphConfig;
    const metadataStaticFields = metadata.staticFields || [];
    const metadataDynamicFields = metadata.dynamicFields || [];

    if (isEmpty(metadataStaticFields) && isEmpty(metadataDynamicFields)) {
        return [];
    }

    const allowedColumns = keyBy([...metadataStaticFields, ...metadataDynamicFields], 'id');
    const mergedMetrics = getMergedMetrics(metrics, metadataDynamicFields);
    const filteredMetrics = mergedMetrics.filter(metric => allowedColumns[metric.field]);
    const resolvedMetrics = filteredMetrics.map(metric => generateGraphMetricDef(metric, locale, currency));

    return resolvedMetrics;
};

export { flatDynamicFields, getMaximumFractionDigits, createDynamicColumnDef, getMergedMetrics };
