export const campaignGroupApiPrefix = (accountId, campaignsGroupId) =>
    `/${accountId}/campaign-groups${campaignsGroupId ? `/${campaignsGroupId}` : ''}`;

export const campaignGroupApiFactory = ({ callGetApi, callPostApi, callPutApi, callDeleteApi }) => ({
    createCampaignGroup: (accountName, campaignGroup) =>
        callPostApi(campaignGroupApiPrefix(accountName), campaignGroup),
    getCampaignGroup: (accountName, campaignGroupId) =>
        callGetApi(campaignGroupApiPrefix(accountName, campaignGroupId)),
    getCampaignGroupList: (accountName, { page, pageSize, searchText, statuses }) =>
        callGetApi(campaignGroupApiPrefix(accountName), {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
            statuses,
        }),
    updateCampaignGroup: (accountName, campaignGroupId, campaignGroup) =>
        callPutApi(campaignGroupApiPrefix(accountName, campaignGroupId), campaignGroup),
    deleteCampaignGroup: (accountName, campaignGroupId) =>
        callDeleteApi(campaignGroupApiPrefix(accountName, campaignGroupId)),
});
