import React, { useCallback } from 'react';
import { identity, noop } from 'lodash';
import uuid from 'uuid/v1';
import { useMotionStudioModalBase } from 'modules/campaigns/modules/creative-creator/components/VariationsCreation/hooks/useMotionStudioModalBase';
import { IMAGE, THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useCreativeStudioModal } from '../../CreativeStudio/useCreativeStudioModal';
import { useMotionStudioStatus } from '../../GalleryItemDropdown/useMotionStudioStatus';
import { PREVIEW_DRAWER_EVENT_PREFIX } from '../../PreviewsDrawer/previewsDrawerConstants';
import { PreviewsDrawer } from '../PreviewsDrawer';
import styles from './usePreviewsDrawerModal.module.scss';

export const usePreviewsDrawerModal = ({ campaignId, titleList, accountId, onSelectMedia = noop }) => {
    const { open: openModal, close: closeModal } = useModal();
    const { openModal: openMotionStudioModal } = useMotionStudioModalBase({ onSelectMedia, campaignId, titleList });
    const { isMotionStudioEnabled, isWochitAccessTokenInvalid } = useMotionStudioStatus();

    const shouldDisableMotionAdsButton = !(isMotionStudioEnabled && !isWochitAccessTokenInvalid);

    const { openModal: openCreativeStudioModal, closeModal: closeCreativeStudioModal } = useCreativeStudioModal({
        onSelectMedia,
        campaignId,
        titleList,
    });

    // TODO: sync this somehow with the FromImage and FromText selectMediaHandler -- LA-54494
    const addImageHandler = useCallback(
        (data, openCropImageModal) => {
            onSelectMedia(
                () => ({ url: data.image.url }),
                {
                    id: data.image.id,
                    mediaUploadSource: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION,
                    fileType: IMAGE,
                    selected: false,
                },
                () =>
                    openCropImageModal({
                        src: data.image.url,
                        metadata: { shouldEnableCropSubmitAlways: true },
                        shouldSkipPreview: true,
                    }) //The third argument of the onSelectMedia function is the OnSuccess function that will be run when the creative studio modal is closed properly
            );
        },
        [onSelectMedia]
    );

    const openPreviewsDrawerModal = useCallback(
        async ({ src, openCropImageModal = noop }) => {
            return await openModal({
                title: null,
                // to be used in the close function of Modal.js.
                // passedData === false means that the Modal is closed using the X or clicking outside or that it was canceled, closing without submitting any data
                onClose: passedData => {
                    if (passedData === false) {
                        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                            component: `${PREVIEW_DRAWER_EVENT_PREFIX}: Exited without trying features`,
                            taboolaCampaignId: campaignId,
                        });
                    }
                },
                contentRenderer: props => (
                    <PreviewsDrawer
                        {...props}
                        closeModal={closeModal}
                        openMotionStudioModal={openMotionStudioModal}
                        openCreativeStudioModal={openCreativeStudioModal}
                        src={src}
                        originalItem={{
                            id: uuid(),
                            url: src,
                        }}
                        accountId={accountId}
                        campaignId={campaignId}
                        addImageHandler={data => {
                            addImageHandler(data, openCropImageModal);
                        }}
                        closeCreativeStudioModal={closeCreativeStudioModal}
                        urlTransformer={identity}
                        shouldDisableMotionAdsButton={shouldDisableMotionAdsButton}
                    />
                ),
                className: styles['modal'],
                contentClassName: styles['modal-content'],
                headerClassName: styles['modal-header'],
            });
        },
        [
            openModal,
            closeModal,
            openMotionStudioModal,
            openCreativeStudioModal,
            accountId,
            campaignId,
            closeCreativeStudioModal,
            shouldDisableMotionAdsButton,
            addImageHandler,
        ]
    );

    return { openModal: openPreviewsDrawerModal };
};
