'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ListFooter = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'listFooter-module__taboola-svg-icon___1ANZN',
    'container': 'listFooter-module__container___3C54O',
    'title': 'listFooter-module__title___QlAZU'
};
var ListFooter = exports.ListFooter = function ListFooter(_ref) {
    var listFooterTitle = _ref.listFooterTitle,
        listFooterClass = _ref.listFooterClass;

    if (!listFooterTitle) {
        return null;
    }
    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(listFooterClass, styles['container']) },
        listFooterTitle && _react2.default.createElement(
            'div',
            { className: styles['title'] },
            listFooterTitle
        )
    );
};
ListFooter.propTypes = {
    /** List header title */
    listFooterTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** List header class */
    listFooterClass: _propTypes2.default.string
};