import { useCallback } from 'react';
import { DropdownMenu, FormField } from 'taboola-ultimate-ui';
import { useDropdownOptions } from '../../../../hooks';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { buildDeliveryTimeOptions } from './buildDeliveryTimeOptions';
import styles from './scheduledReportDeliveryTimeField.module.scss';

const optionsMsgPrefix = 'campaign.editor.schedule.dayparting.hour';
export const helpTextDefaultMessage =
    'Select when you would like to receive the report. The report will not include today’s data, only historic data according to your date range selection.';

export const ScheduledReportDeliveryTimeField = () => {
    const { value: deliveryTime, onChange: setDeliveryTime } = useFormFieldValue({ field: 'deliveryTime' });
    const dropdownOptions = useDropdownOptions(buildDeliveryTimeOptions(), optionsMsgPrefix);
    const changeHandler = useCallback(
        ({ value: deliveryTime }) => {
            setDeliveryTime(deliveryTime);
        },
        [setDeliveryTime]
    );
    return (
        <FormField
            inputId="delivery-time-selector"
            containerClass={styles['delivery-time-container']}
            label={
                <FormattedMessage id="app.scheduled.reports.create.delivery.time.select.title" defaultMessage="Time" />
            }
            labelClass={styles['label']}
            helpText={
                <FormattedMessage
                    id="app.scheduled.reports.create.delivery.time.select.help"
                    defaultMessage={helpTextDefaultMessage}
                />
            }
        >
            <DropdownMenu
                id="delivery-time-selector"
                selectedValueObject={{
                    value: deliveryTime,
                }}
                options={dropdownOptions}
                onChange={changeHandler}
                searchable
                aria-label="delivery-time-dropdown"
                className={styles['delivery-time']}
            />
        </FormField>
    );
};
