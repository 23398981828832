import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useCurrentCampaignTargetingByType } from 'modules/campaigns/modules/common-campaign-form/components/SiteTargeting/utils/useCurrentCampaignTargetingByType';
import { isSupplyTargetingRestrictionEnforced } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/utils/isSupplyTargetingRestrictionEnforced';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useAPISupplyTargetingRestrictions } from './useAPISupplyTargetingRestrictions';

const validations = [
    {
        validationFn: (supplyTargeting, _, dependencies) =>
            isSupplyTargetingRestrictionEnforced({ supplyTargeting, ...dependencies }),
        messageId: 'campaign.editor.targeting.supply.unsupported.targeting.default.message',
        defaultMessage:
            'Please note: This campaign will not serve on the selected environment because one or more of the following are' +
            ' applied: DMA targeting, Region targeting, City targeting, Zip Code targeting, Connection Type targeting,' +
            ' Topic Segments targeting, Audience or 3rd Party Tags.',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const useValidateSupplyTargetingFormField = () => {
    const currentCampaignTargetingByType = useCurrentCampaignTargetingByType();
    const { supplyTargetingResource, isSupplyPermitted, isTaboolaDefaultValue, isPermitted } =
        useAPISupplyTargetingRestrictions();

    const formFieldProps = useFormValidatedValue({
        field: 'campaignTargeting.supplyTargeting',
        validations,
        validationDependencies: {
            currentCampaignTargetingByType,
            supplyTargetingResource,
        },
        formFieldDependencies: {
            isSupplyPermitted,
            isTaboolaDefaultValue,
            isPermitted,
        },
    });

    return formFieldProps;
};
