import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Radio, RadioGroup, FormField, Indication } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useSelectedAccount } from 'hooks';
import { DAY_AND_GROUP_OPTIONS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/consts/dayTimeDaysOptions';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DayTimeParting } from '../DayTimeParting/DayTimeParting';
import { TIME_ZONE_METHODS } from '../DayTimeParting/consts/timeZoneOptions';
import TimezoneDropdown from '../TimezoneDropdown/TimezoneDropdown';
import { SchedulerDayTimeSummary } from './SchedulerDayTimeSummary';
import { createNewDefaultDayParting } from './consts/campaignSchedulerDayTimeDefaultValue';
import styles from './schedulerDayTime.module.scss';

export const SchedulerDayTime = ({ isDayPartingPermitted, timeZoneEnabled, timezones, onChange, timeZone }) => {
    const { value: campaignScheduleRules } = useFormFieldValue(
        { field: 'campaignSchedule.rules' },
        { isDayPartingPermitted }
    );
    const { onChange: setIsMultipleSlicesPerDay, mode } = useFormFieldValue({
        field: 'campaignSchedule.isMultipleSlicesPerDay',
    });
    const { fetchedData: originalCampaign } = useFormDataContext();
    const userTimeZoneEnabled = useConfigMatch({ [FEATURE_FLAGS.USER_TIME_ZONE_ENABLED]: 'true' });
    const [{ timeZoneName }] = useSelectedAccount();

    const [selectedTimeZoneMethod, setSelectedTimeZoneMethod] = useState(TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE);
    const [selectedTimezone, setSelectedTimezone] = useState(timeZoneName);

    const setTimezoneMethod = useCallback(
        timeZoneMethod => {
            setSelectedTimeZoneMethod(timeZoneMethod);
            if (timeZoneMethod === TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE) {
                onChange({ value: selectedTimezone });
                return;
            }

            onChange({ value: timeZoneMethod });
        },
        [onChange, selectedTimezone, setSelectedTimeZoneMethod]
    );

    useEffect(() => {
        if (mode === FORM_MODES.CREATE) {
            setIsMultipleSlicesPerDay(true);
            return;
        }
        const {
            campaignSchedule: { rules: InitialCampaignSchedule },
        } = originalCampaign;
        if (!isEqual(InitialCampaignSchedule, campaignScheduleRules)) {
            setIsMultipleSlicesPerDay(true);
        }
    }, [setIsMultipleSlicesPerDay, mode, campaignScheduleRules, originalCampaign]);

    useEffect(() => {
        if (timeZone === TIME_ZONE_METHODS.USER_TIME_ZONE) {
            setSelectedTimeZoneMethod(timeZone);
            return;
        }
        setSelectedTimezone(timeZone);
    }, [timeZone, setSelectedTimeZoneMethod, setSelectedTimezone]);

    return (
        <div className={styles['day-time']}>
            <DayTimeParting
                field="campaignSchedule.rules"
                showBoost={false}
                newRowData={createNewDefaultDayParting()}
                formFieldDependencies={{ formFieldDependencies: { isDayPartingPermitted } }}
                dayOptions={DAY_AND_GROUP_OPTIONS}
            />
            <Indication
                type="info"
                message={
                    <FormField
                        description={
                            <FormattedMessage
                                id="campaign.day.time.summary.indication"
                                defaultMessage="<i>Based on the selection here, this campaign will run in the following days and hours</i>"
                            />
                        }
                    />
                }
                displayDismissComponent={false}
            />
            <SchedulerDayTimeSummary isDayPartingPermitted={isDayPartingPermitted} />
            {timeZoneEnabled &&
                (userTimeZoneEnabled ? (
                    <RadioGroup
                        id="scheduler_day_time_radio_group"
                        name="scheduler-day-time-radio-group"
                        selectedValue={selectedTimeZoneMethod}
                        onChange={setTimezoneMethod}
                    >
                        <Radio
                            value={TIME_ZONE_METHODS.USER_TIME_ZONE}
                            title={
                                <FormattedMessage
                                    id="campaign.time.zone.as.user.time.zone"
                                    defaultMessage="User Time Zone"
                                />
                            }
                        />
                        <Radio
                            value={TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE}
                            title={
                                <FormattedMessage
                                    id="campaign.time.zone.as.specific.time.zone"
                                    defaultMessage="Specific Time Zone"
                                />
                            }
                        />
                        {selectedTimeZoneMethod !== TIME_ZONE_METHODS.USER_TIME_ZONE && (
                            <div className={styles['time-zone-dropdown']}>
                                <TimezoneDropdown
                                    selectedTimezone={selectedTimezone}
                                    timezones={timezones}
                                    onChange={({ value }) => {
                                        onChange({ value });
                                        setSelectedTimezone(value);
                                    }}
                                />
                            </div>
                        )}
                    </RadioGroup>
                ) : (
                    <TimezoneDropdown
                        selectedTimezone={selectedTimezone}
                        timezones={timezones}
                        onChange={({ value }) => {
                            onChange({ value });
                            setSelectedTimezone(value);
                        }}
                    />
                ))}
        </div>
    );
};
