import { useSelectedAccountId } from 'hooks/queryParams/useSelectedAccountId';
import { isAdminNetworkAccountType, isNetworkAccountType } from '../../../account-management/accountType';
import { useAccountCache } from './useAccountCache';

export const useIsAppAccountNetwork = () => {
    const [queryAccountId] = useSelectedAccountId();
    const getAccount = useAccountCache();
    const accountType = getAccount(queryAccountId)?.type;
    const isNetwork = isNetworkAccountType(accountType) || isAdminNetworkAccountType(accountType);

    return isNetwork;
};
