import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { MyLocationOutlinedIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { isNetworkAccountType } from 'modules/account-management';
import { SectionHeader } from 'modules/campaigns/components';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { AccountDropdown } from 'modules/campaigns/modules/common-campaign-form';
import { CampaignsField } from 'modules/campaigns/modules/creative-creator/components';
import { CONTENT_METHODS } from 'modules/campaigns/modules/creative-creator/config';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import PerformanceRuleNameField from '../PerformanceRulesNameField/PerformanceRulesNameField';
import EntitiesRule from './EntitiesRule';
import { NoCampaignsSelectedIndication } from './NoCampaignsSelectedIndication';
import { PerformanceRulesExcludeNewCampaigns } from './PerformanceRulesExcludeNewCampaigns';
import { ENTITIES_TYPES } from './entitiesRuleTypes';
import styles from './performanceRulesSetupSection.module.scss';

const EMPTY_ARRAY = [];
export const PerformanceRulesSetupSection = () => {
    const { formAccount, setFormAccount, mode } = useFormDataContext();
    const { accountName } = formAccount;
    const [{ type }] = useSelectedAccount();
    const isNetworkAccount = isNetworkAccountType(type);
    const { onChange: onAdvertiserChange } = useFormFieldValue({ field: 'advertiser' }, { accountName });
    const { value: entityType, onChange: entityTypeChange } = useFormFieldValue({ field: 'entityType' });
    const { value: selectedCampaigns, onChange: onCampaignChange } = useCampaignsFormFieldValue();
    const shouldUseValidations = useMemo(() => {
        return entityType === ENTITIES_TYPES.CAMPAIGN && isNetworkAccount;
    }, [entityType, isNetworkAccount]);
    const isAccountSelectionPermitted = usePermissions('NETWORK_LEVEL_DUPLICATE_CAMPAIGN_DROPDOWN');
    const [{ type: selectedAccountType }] = useSelectedAccount();
    const shouldRenderAccountDropdown = isAccountSelectionPermitted && isNetworkAccountType(selectedAccountType);
    const sendGTMEvent = () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'PerformanceRulesSetupSection',
            value: 'change entity rule to specific campaigns',
            pageName: getPageName(),
        });
    };
    const onEntityRuleChange = useCallback(
        value => {
            entityTypeChange(value);
            if (value === ENTITIES_TYPES.CAMPAIGN) {
                onCampaignChange(EMPTY_ARRAY);
                sendGTMEvent();
            } else {
                onAdvertiserChange(formAccount.accountId);
            }
        },
        [entityTypeChange, onCampaignChange, onAdvertiserChange, formAccount.accountId]
    );

    const onAccountChange = useCallback(
        value => {
            setFormAccount(value);
            const { accountName } = value;
            onAdvertiserChange(accountName);
        },
        [onAdvertiserChange, setFormAccount]
    );
    return (
        <CommonCollapsibleCard
            id="PERFORMANCE_RULES_SETUP"
            header={
                <SectionHeader
                    headerIcon={<MyLocationOutlinedIcon />}
                    headerText={
                        <FormattedMessage
                            id="app.create.performance.rules.setup.step.title"
                            defaultMessage="Custom Rules Setup"
                        />
                    }
                />
            }
            containerClassName={styles['card-container']}
        >
            <div className={styles['field-container']}>
                <PerformanceRuleNameField />
                {shouldRenderAccountDropdown && (
                    <FormField containerClass={styles['account-field']}>
                        <AccountDropdown onAccountChange={onAccountChange} accountForCampaign={formAccount} />
                    </FormField>
                )}
                <>
                    <EntitiesRule selection={entityType} onChange={onEntityRuleChange} mode={mode} />
                    {entityType === ENTITIES_TYPES.CAMPAIGN && (
                        <CampaignsField
                            className={styles['campaigns-field']}
                            selectedCampaigns={selectedCampaigns}
                            accountForCampaign={formAccount}
                            contentMethod={CONTENT_METHODS.VARIATIONS}
                            helpText=""
                            shouldUseValidations={shouldUseValidations}
                        />
                    )}
                    {isEmpty(selectedCampaigns) && entityType === ENTITIES_TYPES.CAMPAIGN && (
                        <NoCampaignsSelectedIndication />
                    )}
                </>
            </div>
            <PerformanceRulesExcludeNewCampaigns />
        </CommonCollapsibleCard>
    );
};
