import PERFORMANCE_RECOMMENDATIONS_SUB_TYPES from '../../config/performanceRecommendationsSubTypes';

export const performanceRecommendationsSubTypeCards = {
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_REACHED_DEPLETION]: {
        descriptionCode: 'performance-recommendations.budget.reached-depletion.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_CLOSE_DEPLETION]: {
        descriptionCode: 'performance-recommendations.budget.close-depletion.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_OVER_DELIVERY]: {
        descriptionCode: 'performance-recommendations.budget.over-delivery.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CONVERSIONS]: {
        descriptionCode: 'performance-recommendations.budget.daily-cap-partial-activity-conversions.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CLICKS]: {
        descriptionCode: 'performance-recommendations.budget.daily-cap-partial-activity-clicks.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CONVERSIONS]: {
        descriptionCode:
            'performance-recommendations.budget.daily-cap-partial-activity-day-parting-conversions.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CLICKS]: {
        descriptionCode: 'performance-recommendations.budget.daily-cap-partial-activity-day-parting-clicks.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_HIGH_PACING_CONVERSIONS]: {
        descriptionCode: 'performance-recommendations.budget.daily-cap-high-pacing-conversions.description',
    },
    [PERFORMANCE_RECOMMENDATIONS_SUB_TYPES.BUDGET_DAILY_CAP_HIGH_PACING_CLICKS]: {
        descriptionCode: 'performance-recommendations.budget.daily-cap-high-pacing-clicks.description',
    },
};
