import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './applyButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ApplyButton = ({
    onClick,
    isApplied,
    isApplyLoading,
    textCode,
    actionParams = {},
    tooltipCode,
    dataMetricsComponent = 'RecommendationCardActionButton',
    isItemRec = false,
    size = Button.size.sm,
    disabled = false,
    recommendation,
}) => {
    const { campaignId, id: recommendationId, numericAccountId } = recommendation;
    const isDisabled = disabled || isApplied;
    return (
        <div>
            <Button
                variant={Button.variant.primary}
                size={size}
                onClick={onClick}
                disabled={isDisabled}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-account-id={numericAccountId}
                data-metrics-taboola-campaign-id={campaignId}
                data-metrics-value={recommendationId}
                data-metrics-component={dataMetricsComponent}
                className={classNameBuilder('apply-button', { item: isItemRec })}
            >
                {isApplyLoading ? (
                    <div className={classNameBuilder('spinner', { item: isItemRec, enabled: !isDisabled })} />
                ) : null}
                <FormattedMessage id={textCode} defaultMessage="Apply" values={actionParams} />
            </Button>
            {!disabled ? (
                <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow>
                    <FormattedMessage
                        id={tooltipCode}
                        values={actionParams}
                        defaultMessage="By clicking here, campaign's setup will be updated"
                    />
                </Tooltip>
            ) : null}
        </div>
    );
};
