import { useCallback, useEffect } from 'react';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { GAPI_SRC_URL } from '../constants';
import { useLoadScript } from './useLoadScript';

export const useLoadGapiClient = () => {
    const { isSuccess } = useLoadScript(GAPI_SRC_URL);

    const loadGapiClient = useCallback(async () => {
        await new Promise(resolve => {
            window.gapi.load('client', resolve);
        });

        try {
            await window.gapi.client.init({});
        } catch (e) {
            gtmTracker.trackError(e);
            console.error(e);
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            loadGapiClient();
        }
    }, [isSuccess, loadGapiClient]);

    return { isSuccess };
};
