import { FORM_MODES } from 'config/formModes';
import { BASE_FORM_ROUTE_PATH } from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { ACCOUNT_ID_PARAM_NAME, ACCOUNT_PARAM_TEMPLATE } from './account';
import { CAMPAIGN_ID_PARAM_NAME, CAMPAIGN_ID_PARAM_TEMPLATE } from './campaign';
import { DIMENSION_PARAM_TEMPLATE } from './campaignDimension';
import { CAMPAIGNS_GROUP_ID_PARAM_NAME, CAMPAIGNS_GROUP_ID_PARAM_TEMPLATE } from './campaignsGroup';
import { CREATIVE_ID_PARAM_NAME, CREATIVE_ID_PARAM_TEMPLATE } from './creative';
import { HIERARCHY_REP_ITEM_ID_PARAM_NAME } from './creativeCarousel';
import { ENTITY } from './entity';

export const EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${CAMPAIGN_ID_PARAM_TEMPLATE}${CREATIVE_ID_PARAM_TEMPLATE}`;
export const BULK_EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${DIMENSION_PARAM_TEMPLATE}`;
export const BULK_DUPLICATE_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${DIMENSION_PARAM_TEMPLATE}`;
export const CAMPAIGN_GROUP_EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${CAMPAIGNS_GROUP_ID_PARAM_TEMPLATE}`;
export const RECOMMENDATIONS_DRAWER_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${CAMPAIGN_ID_PARAM_TEMPLATE}`;

const emptyParams = { accountId: [], campaignId: [], campaignsGroupId: [], creativeId: [], creativeType: [] };
export const CAMPAIGN_CREATOR_ROUTE_PATH = replacePathParams(EDITOR_ROUTE_PATH, {
    entity: [
        ENTITY.CAMPAIGN,
        ENTITY.RTB_OPEN_EXCHANGE,
        ENTITY.PMP_DEAL,
        ENTITY.CAMPAIGN_VIDEO,
        ENTITY.VIDEO_PMP_DEAL,
        ENTITY.EXCEL_BULK,
    ],
    mode: [FORM_MODES.CREATE, FORM_MODES.BULK_CREATE],
    ...emptyParams,
});
export const CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH = replacePathParams(EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CAMPAIGNS_GROUP],
    mode: [FORM_MODES.CREATE],
    ...emptyParams,
});
export const CAMPAIGN_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [
            ENTITY.CAMPAIGN,
            ENTITY.RTB_OPEN_EXCHANGE,
            ENTITY.PMP_DEAL,
            ENTITY.CAMPAIGN_VIDEO,
            ENTITY.VIDEO_PMP_DEAL,
        ],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [ACCOUNT_ID_PARAM_NAME, CAMPAIGN_ID_PARAM_NAME]
);
export const CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH = replacePathParams(
    CAMPAIGN_GROUP_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CAMPAIGNS_GROUP],
        mode: [FORM_MODES.EDIT, FORM_MODES.PREVIEW],
    },
    [ACCOUNT_ID_PARAM_NAME, CAMPAIGNS_GROUP_ID_PARAM_NAME]
);
export const CAMPAIGNS_GROUP_BULK_EDITOR_ROUTE_PATH = replacePathParams(
    BULK_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CAMPAIGNS_GROUP],
        mode: [FORM_MODES.BULK_EDIT],
    },
    [ACCOUNT_ID_PARAM_NAME]
);
export const CREATIVE_CREATOR_ROUTE_PATH = replacePathParams(EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CREATIVE, ENTITY.CREATIVE_VIDEO],
    mode: [FORM_MODES.CREATE],
    ...emptyParams,
});
export const CREATIVE_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CREATIVE, ENTITY.CREATIVE_VIDEO],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [ACCOUNT_ID_PARAM_NAME, CAMPAIGN_ID_PARAM_NAME, CREATIVE_ID_PARAM_NAME]
);
export const CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CREATIVE_CAROUSEL],
        mode: [FORM_MODES.EDIT],
    },
    [ACCOUNT_ID_PARAM_NAME, CAMPAIGN_ID_PARAM_NAME, HIERARCHY_REP_ITEM_ID_PARAM_NAME]
).replace(CREATIVE_ID_PARAM_NAME, HIERARCHY_REP_ITEM_ID_PARAM_NAME);

export const CREATIVE_BULK_EDITOR_ROUTE_PATH = replacePathParams(
    BULK_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CREATIVE],
        mode: [FORM_MODES.BULK_EDIT],
    },
    [ACCOUNT_ID_PARAM_NAME]
);
export const CREATIVE_BULK_DUPLICATE_ROUTE_PATH = replacePathParams(
    BULK_DUPLICATE_ROUTE_PATH,
    {
        entity: [ENTITY.CREATIVE],
        mode: [FORM_MODES.BULK_DUPLICATE],
    },
    [ACCOUNT_ID_PARAM_NAME]
);
export const PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH = replacePathParams(
    RECOMMENDATIONS_DRAWER_ROUTE_PATH,
    {
        entity: [ENTITY.PERFORMANCE_RECOMMENDATION],
        mode: [FORM_MODES.EDIT],
    },
    [ACCOUNT_ID_PARAM_NAME, CAMPAIGN_ID_PARAM_NAME]
);

export const creativeDuplicatePath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, { mode: [FORM_MODES.DUPLICATE] });
export const creativeEditorPath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, {
    mode: [FORM_MODES.EDIT],
    entity: [ENTITY.CREATIVE],
});
export const sponsoredPath = replacePathParams(CAMPAIGN_EDITOR_ROUTE_PATH, { entity: [ENTITY.CAMPAIGN] });
export const videoCreativeEditorPath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CREATIVE_VIDEO],
});

const MAX_DEPTH_OF_URL = EDITOR_ROUTE_PATH.split('/').length;
export const WILDCARD_PATH = '/*'.repeat(MAX_DEPTH_OF_URL);
