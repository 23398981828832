import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEventListener } from 'taboola-ultimate-ui';
import { isOnline } from 'services/callAjax/isOnline';
import { INDICATION_TYPES, addIndication, removeIndication } from '../Indications';
import { FormattedMessage } from '../i18n';

const networkIssueIndication = {
    message: (
        <FormattedMessage
            id="app.internet.connection.is.unavailable.details"
            defaultMessage="Your network seems to be disconnected. Please check your internet connection."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.internet.connection.is.unavailable.high.light"
            defaultMessage="Internet Connection Is Not Stable"
        />
    ),
    timeoutActive: false,
};

export const useNetworkIndication = () => {
    const [online, setOnline] = useState(() => isOnline());
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOnline()) {
            return;
        }
        dispatch(addIndication(networkIssueIndication));
        setOnline(false);
        return () => dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
    }, [dispatch]);

    useEventListener('online', () => {
        setOnline(true);
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
    });

    useEventListener('offline', () => {
        setOnline(false);
        dispatch(addIndication(networkIssueIndication));
    });

    return online;
};
