const COMMON_FLAGS = {
    BID_MODIFICATION_BOOST_MIN_DEFAULT: 'backstage:min-cpc-boost-default',
    BID_MODIFICATION_BOOST_MAX_DEFAULT: 'backstage:max-cpc-boost-default',
    BID_MODIFICATION_BOOST_MIN_INTERNAL: 'backstage:min-cpc-boost-internal',
    BID_MODIFICATION_BOOST_MAX_INTERNAL: 'backstage:max-cpc-boost-internal',
    UNIP_BASE_CODE_SNIPPET: 'backstage:unip:base-pixel-default-code-snippet',
    UNIP_EVENT_CODE_SNIPPET: 'backstage:unip:event-default-code-snippet',
    UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITH_GTM: 'backstage:unip:conversion-image-pixel-default-code-snippet-with-gmt',
    UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITHOUT_GTM:
        'backstage:unip:conversion-image-pixel-default-code-snippet-without-gmt',
    UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITH_GTM:
        'backstage:unip:base-image-pixel-default-code-snippet-with-gmt',
    UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITHOUT_GTM:
        'backstage:unip:base-image-pixel-default-code-snippet-without-gmt',
    TARGET_CPA_MAX_CAMPAIGN_LEARNING_TIME_DAYS: 'sp-campaign-cvr-learning-status:max-campaign-learning-time-days',
    TARGET_CPA_MAX_CAMPAIGN_LEARNING_LIMITED_TIME_DAYS:
        'sp-campaign-cvr-learning-status:max-campaign-learning-limited-time-days',
    CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS: 'sp-campaign-cvr-learning-status:max-time-in-review-in-hours',
    CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION:
        'sp-campaign-cvr-learning-status:number-of-conversions-in-timeframe-for-completion',
    LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS: 'sp-campaign-cvr-learning-status:lookback-window-for-conversions-days',
    CVR_LEARNING_STATUS_MAX_TIME_TO_SHOW_PROGRESS_AFTER_COMPLETION_IN_HOURS:
        'sp-campaign-cvr-learning-status:max-time-to-show-progress-after-completion-in-hours',
    CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING:
        'sp-campaign-cvr-learning-status:min-learning-days-before-warning',
    CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING:
        'sp-campaign-cvr-learning-status:min-learning-progress-percentage-before-warning',
    DCO_CREATIVE_TEMPLATE_REPORT_ENABLED: 'taboola-ads:campaign-report:dco-creative-template-report-enabled',
    DCO_ITEM_PERFORMANCE_REPORT_ENABLED: 'taboola-ads:campaign-report:dco-item-performance-report-enabled',
    STRIPE_PUBLIC_API_KEY: 'ccb:stripe-public-api-key',
    SITE_BLOCKING_MAX_VALUE: 'targeting-limit:syndicator:exclude',
    MOTION_ADS_WOCHIT_CHANNEL_ID: 'backstage:motion-ads:wochit-channel-id',
    RESUBMIT_CREATIVES_LANDING_PAGE_REJECTION_REASONS: 'resubmit-creatives:landing-page-reject-reasons',
    TARGET_CPA_ELIGIBILITY_LABELS: 'ads-console:target-cpa-eligibility-labels',
    PERFORMANCE_RECOMMENDATION_TCPA_SHOULD_USE_EDITABLE_CARD:
        'taboola-ads:performance-recommendation:tcpa:should-use-editable-card',
    CUSTOM_CONTEXTUAL_REACH_THRESHOLD: 'ads-console:custom-contextual:reach-threshold',
    CAMPAIGN_REACH_ESTIMATOR_THRESHOLD: 'ads-console:campaign-reach-estimator:threshold',
    CAMPAIGN_REACH_ESTIMATOR_LIMIT: 'ads-console:campaign-reach-estimator:limit',
    CAMPAIGN_REACH_ESTIMATOR_DEBOUNCE_TIME: 'ads-console:campaign-reach-estimator:debounce-time',
    COMBINED_SEGMENTS_REACH_THRESHOLD: 'ads-console:combined-segments-reach-estimator:threshold',
    COMBINED_SEGMENTS_REACH_LIMIT: 'ads-console:combined-segments-reach-estimator:limit',
    SRT_AUDIENCES_SUPPORTED_COUNTRIES_LIST: 'ads-console:srt-audiences:supported-countries:list',
    MRT_AUDIENCES_SUPPORTED_COUNTRIES_LIST: 'ads-console:mrt-audiences:supported-countries:list',
    AUDIENCES_LONG_CREATION_TIME_INDICATION_ENABLED: 'ads-console:audiences-long-creation-time-indication:enabled',
    SCHEDULE_TRACKING_MEETING_URL: 'taboola-ads:tracking:schedule-meeting-url',
    PERMITTED_TIMEZONES_FOR_SCHEDULE_MEETING: 'taboola-ads:tracking:permitted-timezones-for-schedule-meeting',
    CAMPAIGN_LEVEL_CONVERSIONS_NUMBER_OF_CONVERSIONS_THRESHOLD:
        'ads-console:campaign-level-conversions:num-of-conversions-for-warning-threshold',
    CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW:
        'campaign-level-conversions:lookback-window-for-account-conversions-data',
    IS_AUTO_BLOCKED_SITES_FORM_EDITABLE: 'backstage-api:auto-publisher-targeting:is-allowed-to-update',
    TARGET_CPA_SIGNIFICANT_CHANGE_PERCENT: 'target-cpa:lifecycle-task:target-cpa-significant-change-percent',
    NOTIFICATIONS_CENTER_REFECTH_TIME: 'ads-console:notification-refetch-time',
    LEGAL_ENTITIES_REQUIRE_MANDATE_CONFIG: 'stripe:legal-entities-require-mandate',
    LEGAL_ENTITIES_PARTIAL_CC_BRANDS_SUPPORT: 'stripe:legal-entities-partial-cc-brands-support',
    COUNTRY_TO_LEGAL_ENTITIES_WITH_TAX_REGISTRATION: 'taboola-ads:country-to-legal-entities-with-tax-registration',
    SUPPORTED_COUNTRY_FOR_TAX_REGISTRATION: 'taboola-ads:supported-country-for-tax-registration',
    OUTCROPPING_IMAGE_NUM_IMAGES: 'taboola-ads:outcropping-image-num-images',
    OUTCROPPING_IMAGE_MIN_THRESHOLD: 'taboola-ads:outcropping-image-min-threshold',
    OUTCROPPING_IMAGE_MAX_THRESHOLD: 'taboola-ads:outcropping-image-max-threshold',
    UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET: 'unrealistic-tcpa-recommendation:should-recommend-target',
    BID_MODIFICATION_DAY_TIME_BOOST_MAX_DEFAULT: 'day-time-bid-manager:max-multiplier-val',
    BID_MODIFICATION_DAY_TIME_BOOST_MIN_DEFAULT: 'day-time-bid-manager:min-multiplier-val',
    PLATFORM_BOOST_MIN_VALUE: 'backstage:min-cpc-boost-platform',
    PLATFORM_BOOST_MAX_VALUE: 'backstage:max-cpc-boost-platform',
    CANCEL_PAYMENT_ALLOWED_PAYMENT_METHODS: 'billing:cancel-payment:allowed-payment-methods',
    CROPPING_SIGNIFICANT_CHANGE_THRESHOLD_KEY: 'backstage:creative:crop:significant-change-threshold',
    LOW_TIER_DAILY_LIMIT_VALIDATION_ENABLED: 'backstage:low-tier:enable-daily-limit-validation',
    GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES: 'taboola-ads:creative-studio:edit-duplicate-tooltip-max-closes',
    TA_PIXEL_WITH_TIER_WARNINGS: 'taboola-ads:pixel-with-tier-warnings',
    WIDE_PERFORMANCE_TABLE_DATA_RETENTION_IN_MONTHS: 'backstage:wide-performance-table-data-retention-in-month',
    CHAT_AGENT_ENABLED: 'taboola-ads:chat-agent:enabled',
    USE_PUBLISHER_DESCRIPTION_PREFIX_FOR_PUBLISHER: 'taboola-ads:use-publisher-description-prefix',
    BULK_UPLOAD_NUM_CAMPAIGNS_PER_BATCH: 'taboola-ads:bulk-upload:campaigns-per-batch',
    BULK_UPLOAD_NUM_CREATIVES_PER_BATCH: 'taboola-ads:bulk-upload:creatives-per-batch',
    LOADING_BAR_TIME_FOR_REPLACE_BACKGROUND: 'taboola-ads:loading-bar-time:replace-background',
    BULK_UPLOAD_MAX_CAMPAIGN_ROWS: 'backstage:max-bulk-upload-campaigns-size',
    BULK_UPLOAD_MAX_CREATIVES_ROWS: 'backstage:max-bulk-upload-campaign-items-size',
    MAX_ALLOWED_TERMS_NUMBER: 'taboola-ads:custom-contextual-segments:max-allowed-terms-number',
    MAX_CONVERSIONS_CPC_CAP_ENABLED: 'taboola-ads:cpc-cap:is-enabled',
    MIN_PBP_ELIGIBLE_SEED_EVENT_THRESHOLD: 'pbp-eligible-seed-received-event-count:threshold',
    SHOW_REGION_FOR_COUNTRIES: 'taboola-ads:billing:show-state-for-countries',
    ALLOW_CARTES_BANCAIRES: 'taboola-ads:billing:cartes-bancaires:is-enabled',
    MRT_AUDIENCES_PREFIXES_TO_REMOVE_FROM_INPUT: 'taboola-ads:mrt-audiences:prefixes-to-remove-from-input',
    ADVANCED_PREVIEW_MAX_VARIATIONS: 'taboola-ads:advanced-preview-max-variations',
    ADVANCED_PREVIEW_URL_WITH_HASH: 'taboola-ads:advanced-preview-url-with-hash',
};

const defaultCommonValues = {
    [COMMON_FLAGS.BID_MODIFICATION_BOOST_MIN_DEFAULT]: -0.5,
    [COMMON_FLAGS.BID_MODIFICATION_BOOST_MAX_DEFAULT]: 0.5,
    [COMMON_FLAGS.BID_MODIFICATION_BOOST_MIN_INTERNAL]: -0.5,
    [COMMON_FLAGS.BID_MODIFICATION_BOOST_MAX_INTERNAL]: 1.5,
    [COMMON_FLAGS.UNIP_BASE_CODE_SNIPPET]:
        '<!-- Taboola Pixel Code -->\n' +
        "<script type='text/javascript'>\n" +
        '  window._tfa = window._tfa || [];\n' +
        "  window._tfa.push({notify: 'event', name: 'page_view', id: <NUMERIC_ADVERTISER_ID>});\n" +
        '  !function (t, f, a, x) {\n' +
        '         if (!document.getElementById(x)) {\n' +
        '            t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);\n' +
        '         }\n' +
        "  }(document.createElement('script'),\n" +
        "  document.getElementsByTagName('script')[0],\n" +
        "  '//cdn.taboola.com/libtrc/unip/<NUMERIC_ADVERTISER_ID>/tfa.js',\n" +
        "  'tb_tfa_script');\n" +
        '</script>\n' +
        '<!-- End of Taboola Pixel Code -->',
    [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET]:
        '<!-- Taboola Pixel Code -->\n' +
        '<script>\n' +
        "    _tfa.push({notify: 'event', name: 'event_name', id: <NUMERIC_ADVERTISER_ID>});\n" +
        '</script>\n' +
        '<!-- End of Taboola Pixel Code -->',
    [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITH_GTM]:
        "<img src='https://trc.taboola.com/<NUMERIC_ADVERTISER_ID>/log/3/unip?item-url={{Page URL}}&ref={{Referrer}}&en=event_name'\n" +
        "\twidth='0' height='0' style='display:none'/>",
    [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET_IMAGE_PIXEL_WITHOUT_GTM]:
        "<img src='https://trc.taboola.com/<NUMERIC_ADVERTISER_ID>/log/3/unip?en=event_name'\n" +
        "\twidth='0' height='0' style='display:none' referrerpolicy='no-referrer-when-downgrade'/>",
    [COMMON_FLAGS.UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITH_GTM]:
        "<img src='https://trc.taboola.com/<NUMERIC_ADVERTISER_ID>/log/3/unip?en=page_view&item-url={{Page URL}}&ref={{Referrer}}'\n" +
        "\twidth='0' height='0' style='display:none'/>",
    [COMMON_FLAGS.UNIP_BASE_IMAGE_PIXEL_DEFAULT_CODE_SNIPPET_WITHOUT_GTM]:
        "<img src='https://trc.taboola.com/<NUMERIC_ADVERTISER_ID>/log/3/unip?en=page_view'\n" +
        "\twidth='0' height='0' style='display:none' referrerpolicy='no-referrer-when-downgrade'/>",
    [COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_TIME_DAYS]: 7,
    [COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_LIMITED_TIME_DAYS]: 10,
    [COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS]: 24,
    [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]: 50,
    [COMMON_FLAGS.LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS]: 7,
    [COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_TO_SHOW_PROGRESS_AFTER_COMPLETION_IN_HOURS]: 24,
    [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING]: 5,
    [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING]: 0.5,
    [COMMON_FLAGS.DCO_CREATIVE_TEMPLATE_REPORT_ENABLED]: 'false',
    [COMMON_FLAGS.DCO_ITEM_PERFORMANCE_REPORT_ENABLED]: 'false',
    [COMMON_FLAGS.STRIPE_PUBLIC_API_KEY]: 'pk_test_AOFxJkKcEyhD77xd3QSnglkL',
    [COMMON_FLAGS.SITE_BLOCKING_MAX_VALUE]: '1500',
    [COMMON_FLAGS.MOTION_ADS_WOCHIT_CHANNEL_ID]: 393216,
    [COMMON_FLAGS.RESUBMIT_CREATIVES_LANDING_PAGE_REJECTION_REASONS]: '',
    [COMMON_FLAGS.TARGET_CPA_ELIGIBILITY_LABELS]: 'false',
    [COMMON_FLAGS.PERFORMANCE_RECOMMENDATION_TCPA_SHOULD_USE_EDITABLE_CARD]: 'true',
    [COMMON_FLAGS.CUSTOM_CONTEXTUAL_REACH_THRESHOLD]: 10000,
    [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_THRESHOLD]: 1000,
    [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_LIMIT]: 1000000000,
    [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_DEBOUNCE_TIME]: 500,
    [COMMON_FLAGS.COMBINED_SEGMENTS_REACH_THRESHOLD]: 10000,
    [COMMON_FLAGS.COMBINED_SEGMENTS_REACH_LIMIT]: 1000000000,
    [COMMON_FLAGS.SRT_AUDIENCES_SUPPORTED_COUNTRIES_LIST]: 'US:United States,GB:United Kingdom',
    [COMMON_FLAGS.MRT_AUDIENCES_SUPPORTED_COUNTRIES_LIST]: 'US:United States,GB:United Kingdom',
    [COMMON_FLAGS.AUDIENCES_LONG_CREATION_TIME_INDICATION_ENABLED]: 'false',
    [COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_NUMBER_OF_CONVERSIONS_THRESHOLD]: -1,
    [COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL]:
        'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0DxkncvhuDhKGDPXX0azrsFZWnuRvyjPuCqsu5qMvd_jMZ69qoxwmpV0sC4uC-SpCK5g74LUcd',
    [COMMON_FLAGS.PERMITTED_TIMEZONES_FOR_SCHEDULE_MEETING]: 'US/Eastern',
    [COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW]: 45,
    [COMMON_FLAGS.IS_AUTO_BLOCKED_SITES_FORM_EDITABLE]: 'false',
    [COMMON_FLAGS.TARGET_CPA_SIGNIFICANT_CHANGE_PERCENT]: 0.5,
    [COMMON_FLAGS.NOTIFICATIONS_CENTER_REFECTH_TIME]: 60000,
    [COMMON_FLAGS.LEGAL_ENTITIES_REQUIRE_MANDATE_CONFIG]: '',
    [COMMON_FLAGS.LEGAL_ENTITIES_PARTIAL_CC_BRANDS_SUPPORT]: '',
    [COMMON_FLAGS.COUNTRY_TO_LEGAL_ENTITIES_WITH_TAX_REGISTRATION]: '',
    [COMMON_FLAGS.SUPPORTED_COUNTRY_FOR_TAX_REGISTRATION]: '',
    [COMMON_FLAGS.OUTCROPPING_IMAGE_NUM_IMAGES]: 4,
    [COMMON_FLAGS.OUTCROPPING_IMAGE_MIN_THRESHOLD]: 1.48,
    [COMMON_FLAGS.OUTCROPPING_IMAGE_MAX_THRESHOLD]: null,
    [COMMON_FLAGS.UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET]: 'false',
    [COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MAX_DEFAULT]: '10.0',
    [COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MIN_DEFAULT]: '0.1',
    [COMMON_FLAGS.PLATFORM_BOOST_MAX_VALUE]: 9,
    [COMMON_FLAGS.PLATFORM_BOOST_MIN_VALUE]: 0.6,
    [COMMON_FLAGS.CROPPING_SIGNIFICANT_CHANGE_THRESHOLD_KEY]: 0.9,
    [COMMON_FLAGS.LOW_TIER_DAILY_LIMIT_VALIDATION_ENABLED]: 'false',
    [COMMON_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES]: 5,
    [COMMON_FLAGS.BULK_UPLOAD_NUM_CAMPAIGNS_PER_BATCH]: 100,
    [COMMON_FLAGS.BULK_UPLOAD_NUM_CREATIVES_PER_BATCH]: 100,
    [COMMON_FLAGS.LOADING_BAR_TIME_FOR_REPLACE_BACKGROUND]: 25,
    [COMMON_FLAGS.TA_PIXEL_WITH_TIER_WARNINGS]: 'Low Touch',
    [COMMON_FLAGS.WIDE_PERFORMANCE_TABLE_DATA_RETENTION_IN_MONTHS]: 18,
    [COMMON_FLAGS.CHAT_AGENT_ENABLED]: 'true',
    [COMMON_FLAGS.USE_PUBLISHER_DESCRIPTION_PREFIX_FOR_PUBLISHER]: [
        1629109, 1629113, 1629149, 1629124, 1629600, 1629601, 1629819, 1629820, 1632963, 1632965, 1632966, 1632968,
        1632974, 1632975, 1632976, 1632979, 1632980, 1632981, 1632983, 1632984,
    ],
    [COMMON_FLAGS.BULK_UPLOAD_MAX_CREATIVES_ROWS]: 200,
    [COMMON_FLAGS.BULK_UPLOAD_MAX_CAMPAIGN_ROWS]: 200,
    [COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER]: 300,
    [COMMON_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED]: 'false',
    [COMMON_FLAGS.MIN_PBP_ELIGIBLE_SEED_EVENT_THRESHOLD]: 300,
    [COMMON_FLAGS.SHOW_REGION_FOR_COUNTRIES]: ['US', 'CA', 'IN', 'AU'],
    [COMMON_FLAGS.ALLOW_CARTES_BANCAIRES]: 'false',
    [COMMON_FLAGS.MRT_AUDIENCES_PREFIXES_TO_REMOVE_FROM_INPUT]: '@, www., http://, https://',
    [COMMON_FLAGS.ADVANCED_PREVIEW_MAX_VARIATIONS]: 3,
    [COMMON_FLAGS.ADVANCED_PREVIEW_URL_WITH_HASH]: 'false',
};

export { COMMON_FLAGS, defaultCommonValues };
