'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'overlay__taboola-svg-icon___24mhd',
    'container': 'overlay__container___1QC9D',
    'opacity-container': 'overlay__opacity-container___2a37d',
    'clear-opacity': 'overlay__clear-opacity___2ow6i',
    'on-top-overlay': 'overlay__on-top-overlay___NKW8e'
};

var Overlay = function (_Component) {
    _inherits(Overlay, _Component);

    function Overlay() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Overlay);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Overlay.__proto__ || Object.getPrototypeOf(Overlay)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            isHovering: false
        }, _this.hoverStateOn = function (event) {
            var _this$props = _this.props,
                isOverlayAlwaysOn = _this$props.isOverlayAlwaysOn,
                onHover = _this$props.onHover;

            if (!isOverlayAlwaysOn) {
                _this.setState({ isHovering: true });
            }
            onHover(event);
        }, _this.hoverStateOff = function () {
            var isOverlayAlwaysOn = _this.props.isOverlayAlwaysOn;


            if (!isOverlayAlwaysOn) {
                _this.setState({ isHovering: false });
            }
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Overlay, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                children = _props.children,
                className = _props.className,
                isOpacityActive = _props.isOpacityActive,
                isOverlayAlwaysOn = _props.isOverlayAlwaysOn,
                childrenContainerClassName = _props.childrenContainerClassName,
                ariaLabel = _props.ariaLabel;
            var isHovering = this.state.isHovering;

            var overlayClassName = styles['opacity-container'] + ' ' + (!isOpacityActive ? styles['clear-opacity'] : '') + ' ' + className;
            return _react2.default.createElement(
                'div',
                {
                    className: styles['container'],
                    onFocus: this.hoverStateOn,
                    onMouseOver: this.hoverStateOn,
                    onMouseLeave: this.hoverStateOff,
                    'aria-label': ariaLabel
                },
                (isOverlayAlwaysOn || isHovering) && _react2.default.createElement(
                    _react.Fragment,
                    null,
                    _react2.default.createElement('div', { className: overlayClassName + ' ' + className }),
                    _react2.default.createElement(
                        'div',
                        { className: styles['on-top-overlay'] + ' ' + childrenContainerClassName },
                        children
                    )
                )
            );
        }
    }]);

    return Overlay;
}(_react.Component);

Overlay.propTypes = {
    /** Class added to the opacity element */
    className: _propTypes2.default.string,
    /** Opacity div on top */
    isOpacityActive: _propTypes2.default.bool,
    /** Elements inside Overlay */
    children: _propTypes2.default.node,
    /** Determine if Overlay is always on */
    isOverlayAlwaysOn: _propTypes2.default.bool,
    /** Class Name for the children prop container */
    childrenContainerClassName: _propTypes2.default.string,
    /** Function to be called on hover */
    onHover: _propTypes2.default.func,
    /** aria-label for outer div */
    ariaLabel: _propTypes2.default.string
};

Overlay.defaultProps = {
    className: '',
    childrenContainerClassName: '',
    isOpacityActive: true,
    isOverlayAlwaysOn: false,
    onHover: _lodash2.default
};

exports.default = Overlay;