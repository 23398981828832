import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../reportsBaseConfig';

export const CONVERSION_DATA_EXISTS_FROM_DATE = Date.parse('2022-11-01');
export const USE_WIDE_PERFORMANCE_TABLE_REPORTS = [REPORT_TYPE.DMA];

export const reportHistoricalConversionsUnavailableIndication = {
    staticIndicationType: STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA,
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.historicalConversionsUnavailableWarning.highlight"
            defaultMessage="Historical conversion data is not available."
        />
    ),
    message: (
        <FormattedMessage
            id="app.campaign.indication.historicalConversionsUnavailableWarning.message"
            defaultMessage="- Please note that for longer periods before November 2022, conversion data will not be available."
        />
    ),
};
