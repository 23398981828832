import React from 'react';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';

const CreativeEditorFormId = () => {
    const { value: creativeId } = useFormFieldValue({ field: 'id' });

    return (
        <FormattedMessage id="creative.editor.status.ID" defaultMessage="ID: {creativeId}" values={{ creativeId }} />
    );
};

export default CreativeEditorFormId;
