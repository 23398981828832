import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { MAX_LISTENERS_BEFORE_WARN } from './constants';

export class SimpleEventEmitter {
    constructor() {
        this.subscribers = new Map();
        this.advancedSubscribers = new Map();
    }

    trigger = async (event, ...args) => {
        const tasks = new Map();
        tasks.set(event, []);

        for (let sub of this.subscribers.get(event) ?? []) {
            tasks.get(event).push(sub);
        }
        for (let [pattern, sub] of this.advancedSubscribers.entries()) {
            if (pattern.test(event)) {
                tasks.set(pattern, tasks.get(pattern) ?? []);
                tasks.get(pattern).push(sub);
            }
        }

        const results = [];
        for (let [origin, originTasks] of tasks.entries()) {
            for (let task of originTasks) {
                if (this.subscribers.has(origin) || this.advancedSubscribers.has(origin)) {
                    results.push(await task(...args));
                }
            }
        }

        return results;
    };

    _onRegExp = (pattern, callback) => {
        this.advancedSubscribers.set(pattern, callback);

        return () => {
            this.advancedSubscribers.delete(pattern);
        };
    };

    on = (event, callback, warnOnMaxListeners = MAX_LISTENERS_BEFORE_WARN) => {
        if (typeof event === 'object') {
            return this._onRegExp(event, callback);
        }
        if (!this.subscribers.has(event)) {
            this.subscribers.set(event, new Set());
        }

        this.subscribers.get(event).add(callback);
        if (this.subscribers.get(event).size > warnOnMaxListeners) {
            const maxListenerWarning = `There are over ${warnOnMaxListeners} listeners to the "${event}" event. Is this a leak?`;
            console.warn(maxListenerWarning);
            throwCriticalError(new Error(maxListenerWarning));
        }

        return () => {
            const eventListeners = this.subscribers.get(event);
            if (!eventListeners) {
                return;
            }
            eventListeners.delete(callback);
            if (eventListeners.size === 0) {
                this.subscribers.delete(event);
            }
        };
    };

    off = event => {
        const eventName = typeof event === 'string' ? new RegExp(event) : event;
        Array.from(this.subscribers.keys())
            .concat(Array.from(this.advancedSubscribers.keys()).map(regex => regex.source))
            .filter(event => eventName.test(event))
            .forEach(event => {
                this.subscribers.delete(event);
            });
    };
}
