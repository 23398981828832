import { FORM_MODES } from 'config/formModes';
import { EDITOR_ROUTE_PATH, ENTITY } from '../config';
import { generateFormPath } from './generateFormPath';

export const generatePixelBasedEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.PIXEL_BASED,
        mode,
    });

export const generatePredictiveEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.PREDICTIVE,
        mode,
    });

export const generateFirstPartyEditorPath = ({
    accountId,
    audienceId,
    mode = FORM_MODES.EDIT,
    entity = ENTITY.FIRST_PARTY,
}) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity,
        mode,
    });

export const generateCustomContextualEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.CUSTOM_CONTEXTUAL,
        mode,
    });

export const generateSearchKeywordsEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.SEARCH_KEYWORDS,
        mode,
    });

export const generateMailDomainsEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.MAIL_DOMAINS,
        mode,
    });

export const generateCombinedAudiencesEditorPath = ({ accountId, audienceId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        audienceId,
        entity: ENTITY.COMBINED_AUDIENCES,
        mode,
    });
