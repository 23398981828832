import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { flatMapDeep, identity } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { sortArrayWithoutMutation } from '../customContextualTopicsUtils';
import { useArticlesPreviewEnabled } from './useArticlesPreviewEnabled';
import { useCountriesField } from './useCountriesField';

export const useArticlesPreview = maxAllowedTopics => {
    const isArticlesPreviewEnabled = useArticlesPreviewEnabled();
    const { getCustomContextualSegmentArticlesPreview } = useAudiencesApi();
    const [articlesPreview, setArticlesPreview] = useState(null);
    const { value: terms = { include: [], exclude: [] } } = useFormFieldValue({
        field: 'terms',
    });
    const { value: platforms } = useFormFieldValue({
        field: 'platforms',
    });
    const { value: countries } = useCountriesField();

    const { value: languages } = useFormFieldValue({
        field: 'languages',
    });

    const { include, exclude } = terms;
    const termsString = flatMapDeep([include, exclude], identity);

    const queryKey = useMemo(() => {
        const sortedTermsString = termsString.sort();
        const sortedPlatforms = sortArrayWithoutMutation(platforms);
        const sortedCountries = sortArrayWithoutMutation(countries);
        const sortedLanguages = sortArrayWithoutMutation(languages);

        return [
            'getCustomContextualSegmentArticlesPreview',
            sortedTermsString,
            sortedPlatforms,
            sortedCountries,
            sortedLanguages,
        ];
    }, [countries, languages, platforms, termsString]);

    const isTermsStringValid = termsString && !!termsString.length;
    const isPlatformsValid = platforms && !!platforms.length;
    const isEnabled = isTermsStringValid && isPlatformsValid && isArticlesPreviewEnabled;

    const queryParams = useMemo(
        () => ({
            languages,
            platforms,
            countries,
            terms,
        }),
        [languages, platforms, countries, terms]
    );

    const isTermsLengthValid = include?.[0]?.length <= maxAllowedTopics;
    const { data, isLoading: isLoadingArticles } = useQuery(
        queryKey,
        () => getCustomContextualSegmentArticlesPreview(queryParams),
        {
            staleTime: Infinity,
            enabled: isEnabled && isTermsLengthValid,
            select: data => data?.results,
        }
    );

    useEffect(() => {
        if (data) {
            setArticlesPreview(data);
        }
    }, [data]);

    return {
        articlesPreview,
        isLoadingArticles,
    };
};
