import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const ConversionOptimizationTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="conversion.optimization.tooltip.overview"
                defaultMessage="Conversion Goal enables you to choose which conversion event SmartBid’s algorithm will optimize your campaign towards."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="conversion.optimization.tooltip.new"
                defaultMessage="For new campaigns or in cases where the conversion goal was changed on an existing campaign, we recommend giving your campaign at least a week before evaluating performance."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="conversion.optimization.tooltip.change"
                defaultMessage="The conversion goal can be modified while the campaign is active and running (in that case there can be a delay of up to 24 hours in the reportings for the new conversion goal)"
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="conversion.optimization.tooltip.note"
                defaultMessage="Note: you can choose a conversion event even if it is not Included in Total on the account level"
            />
        </TooltipSection>
    </div>
);

export default ConversionOptimizationTooltip;
