'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var loadingSubKey = 'loadingMock';

var isRowMockData = function isRowMockData(data) {
    return data && ('' + data.gridRowId).includes(loadingSubKey);
};

var createGridLoadingData = function createGridLoadingData(dataLength) {
    var array = [];

    for (var i = 0; i < dataLength; i += 1) {
        array.push({ gridRowId: '' + loadingSubKey + i });
    }

    return array;
};

exports.isRowMockData = isRowMockData;
exports.createGridLoadingData = createGridLoadingData;