import { isEmpty, set, map } from 'lodash';

export const METADATA_SHEET_VISIBILITY = 'hidden';

export const removeEmptyRows = worksheet => {
    const rows = worksheet.getRows(1, worksheet.rowCount);
    const rowValuesWithData = map(rows, 'values').filter(values => !isEmpty(values));

    rows.forEach((row, index) => {
        const values = row.values;
        const valuesToSet = rowValuesWithData[index] || [];

        // If there are values in the row or values to set in the row, overwrite the row.
        const shouldOverwriteValues = !isEmpty(values) || !isEmpty(valuesToSet);
        if (shouldOverwriteValues) {
            row.values = valuesToSet;
        }
    });
};

export const resetRowHeights = worksheet => {
    const rows = worksheet.getRows(1, worksheet.rowCount);
    rows.forEach(row => {
        row.height = undefined;
    });
};

export const removeAllNotes = worksheet => {
    worksheet.eachRow({ includeEmpty: true }, row => {
        row.eachCell({ includeEmpty: true }, cell => {
            cell._comment = undefined;
        });
    });
};

export const addCellStyle = ({ worksheet, rowNumber, columnNumber, style }) => {
    const cell = worksheet.getRow(rowNumber).getCell(columnNumber);
    cell.style = { ...cell.style, ...style };
};

export const addCellNote = ({ worksheet, rowNumber, columnNumber, note }) => {
    const cell = worksheet.getRow(rowNumber).getCell(columnNumber);
    cell.note = note;
};

export const addRowStyle = ({ worksheet, rowNumber, style }) => {
    const row = worksheet.getRow(rowNumber);

    row.eachCell({ includeEmpty: true }, cell => {
        cell.style = { ...cell.style, ...style };
    });
};

export const addColumnStyle = ({ worksheet, columnNumber, style }) => {
    const column = worksheet.getColumn(columnNumber);

    column.eachCell({ includeEmpty: true }, cell => {
        cell.style = { ...cell.style, ...style };
    });
};

export const insertNewRow = ({ worksheet, data, style, rowHeight, rowNumber = 1 }) => {
    worksheet.spliceRows(rowNumber, 0, data);
    addRowStyle({ worksheet, style, rowNumber });

    if (rowHeight) {
        worksheet.getRow(rowNumber).height = rowHeight;
    }
};

export const readKeyValueSheet = sheet => {
    const data = sheet
        .getRows(1, sheet.rowCount)
        .filter(row => !isEmpty(row.values))
        .reduce((acc, row) => {
            acc[row.getCell(1).value] = row.getCell(2).value;
            return acc;
        }, {});

    return data;
};

export const setRangeProp = ({ sheet, rowStart = 1, rowEnd = 1, columnStart = 1, columnEnd = 1, propName, value }) => {
    for (let rowIndex = rowStart; rowIndex <= rowEnd; rowIndex++) {
        const row = sheet.getRow(rowIndex);
        for (let columnIndex = columnStart; columnIndex <= columnEnd; columnIndex++) {
            set(row.getCell(columnIndex), propName, value);
        }
    }
};
