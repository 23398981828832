import React from 'react';

export const Emotion = props => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.1 11.4C14.5971 11.4 15 10.9971 15 10.5C15 10.0029 14.5971 9.6 14.1 9.6C13.6029 9.6 13.2 10.0029 13.2 10.5C13.2 10.9971 13.6029 11.4 14.1 11.4Z"
            fill="#667686"
        />
        <path
            d="M9.9 11.4C10.3971 11.4 10.8 10.9971 10.8 10.5C10.8 10.0029 10.3971 9.6 9.9 9.6C9.40294 9.6 9 10.0029 9 10.5C9 10.9971 9.40294 11.4 9.9 11.4Z"
            fill="#667686"
        />
        <path d="M12 15.6C13.368 15.6 14.532 14.604 15 13.2H9C9.468 14.604 10.632 15.6 12 15.6Z" fill="#667686" />
        <path
            d="M11.994 6C8.682 6 6 8.688 6 12C6 15.312 8.682 18 11.994 18C15.312 18 18 15.312 18 12C18 8.688 15.312 6 11.994 6ZM12 16.8C9.348 16.8 7.2 14.652 7.2 12C7.2 9.348 9.348 7.2 12 7.2C14.652 7.2 16.8 9.348 16.8 12C16.8 14.652 14.652 16.8 12 16.8Z"
            fill="#667686"
        />
    </svg>
);
