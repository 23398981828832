import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const ContextualSegmentsFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.contextual.segments.tooltip"
            defaultMessage="Select from readily-available contextual categories."
        />
    </TooltipSection>
);

export default ContextualSegmentsFieldTooltip;
