import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import moment from 'moment-timezone';
import useCurrentDate from 'hooks/useCurrentDate';
import { isAdminNetworkAccountType } from 'modules/account-management/accountType';
import AllCampaignsObject from 'modules/campaigns/config/allCampaignsObject';
import { selectedCampaignSelector } from 'modules/campaigns/selectors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../../../selectors';
import { DYNAMIC_PRESETS, DYNAMIC_PRESET_LABELS } from '../config/dynamicPresets';
import { getPresetLabel, createPreset } from '../utils/dashboardRangePickerUtils';

const useLifetimePreset = ({ timeZoneName }) => {
    const { formatMessage } = useIntl();
    const label = getPresetLabel(formatMessage, DYNAMIC_PRESETS.LIFETIME, DYNAMIC_PRESET_LABELS.LIFETIME);

    const currentDate = useCurrentDate({ timeZoneName });

    const {
        id: selectedCampaignId,
        startDate: selectedCampaignStartDate,
        endDate: selectedCampaignEndDate,
    } = useSelector(selectedCampaignSelector);

    const {
        id: selectedAccountId,
        firstCampaignStartDate,
        type: selectedAccountType,
    } = useSelector(selectedAccountSelector);

    const isLifetimePresetEnabled =
        useConfigMatch({
            [FEATURE_FLAGS.LIFETIME_RANGE_PICKER_PRESET_ENABLED]: 'true',
        }) && !isAdminNetworkAccountType(selectedAccountType);

    const isSpecificCampaignSelected = selectedCampaignId !== AllCampaignsObject.id;

    const presetForAccount = useMemo(
        () =>
            createPreset({
                preset: DYNAMIC_PRESETS.LIFETIME,
                label,
                startDate: moment(firstCampaignStartDate).startOf('day'),
                endDate: currentDate,
            }),
        [label, firstCampaignStartDate, currentDate]
    );

    const presetForCampaign = useMemo(
        () =>
            createPreset({
                preset: DYNAMIC_PRESETS.LIFETIME,
                label,
                startDate: moment(selectedCampaignStartDate).startOf('day'),
                endDate: selectedCampaignEndDate ? moment(selectedCampaignEndDate).startOf('day') : currentDate,
            }),
        [currentDate, label, selectedCampaignEndDate, selectedCampaignStartDate]
    );

    const placeholderPreset = useMemo(
        () =>
            createPreset({
                preset: DYNAMIC_PRESETS.LIFETIME,
                label,
                startDate: currentDate,
                endDate: currentDate,
            }),
        [currentDate, label]
    );

    const lifetimePreset = useMemo(() => {
        if (
            !isLifetimePresetEnabled ||
            (!isSpecificCampaignSelected && firstCampaignStartDate === null) || //user on account level but no data for start date of first campaign
            (isSpecificCampaignSelected && selectedCampaignStartDate === null) || //user on campaign level but no data for campaign start date
            (isSpecificCampaignSelected && moment(selectedCampaignStartDate).startOf('day').isAfter(currentDate)) //campaign start date in the future
        ) {
            return {};
        }
        if (!isSpecificCampaignSelected && !isNull(firstCampaignStartDate)) {
            return presetForAccount;
        }
        if (isSpecificCampaignSelected && !isNull(selectedCampaignStartDate)) {
            return presetForCampaign;
        }
        //We must provide placeholder preset so app doesn't
        //use default time range when the network call that populates the
        //selectedCampaignStartDate or firstCampaignStartDate fields in
        //redux hasn't responded yet
        return placeholderPreset;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isLifetimePresetEnabled,
        selectedCampaignId,
        firstCampaignStartDate,
        isSpecificCampaignSelected,
        selectedCampaignStartDate,
        placeholderPreset,
        presetForAccount,
        presetForCampaign,
        selectedAccountId,
    ]);

    return lifetimePreset;
};

export default useLifetimePreset;
