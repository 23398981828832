import { cloneDeep } from 'lodash';

export const updateEventByAction = (event, currentAction, updateData) => {
    const updatedEvent = cloneDeep(event);
    const actionIndex = updatedEvent.actions.findIndex(
        action => action.actionId === currentAction.actionId || action.actionType === currentAction.actionType
    );
    if (actionIndex === -1) {
        return event;
    }
    updatedEvent.actions[actionIndex] = {
        ...updatedEvent.actions[actionIndex],
        ...updateData,
    };
    return updatedEvent;
};
