import React from 'react';
import { ContentIcon, FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SUCCESS_INDICATION_TYPES } from 'modules/campaigns/components/ContentTitleAISuggestions';
import ContentTitleAISuggestions from 'modules/campaigns/components/ContentTitleAISuggestions/ContentTitleAISuggestions';
import { useContentTitleSuggestionsState } from 'modules/campaigns/components/ContentTitleAISuggestions/hooks/useContentTitleSuggestionsState';
import { CreativeDescriptionTooltip } from 'modules/campaigns/components/Tooltips';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { SuggestionContentTitle } from '../../../../components/SuggestionContentTitle/SuggestionContentTitle';
import { ContentDescription } from '../../../creative-creator/components/ContentField/ContentItem/fields';
import ExternalLinkWithIcon from '../ExternalLinkWithIcon/ExternalLinkWithIcon';
import { CTADropdown, CreativeURL } from '../fields';
import styles from './contentSection.module.scss';

const contentSectionTitle = <FormattedMessage id="creative.editor.content" defaultMessage="Content" />;
const titleLabel = <FormattedMessage id="creative.creator.content.title.label" defaultMessage="Title" />;
const renderUrlLabel = url => (
    <div className={styles['field-label']}>
        <FormattedMessage id="creative.creator.content.url.label" defaultMessage="Landing Page URL" />
        <ExternalLinkWithIcon url={url} />
    </div>
);
const descriptionLabel = (
    <FormattedMessage
        id="creative.creator.content.description.optional.label"
        defaultMessage="Description (Optional)"
    />
);

export const ContentSection = ({ language }) => {
    const isDescriptionEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_DESCRIPTION_ENABLED]: 'true' });
    const isCTAEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_FORM_CTA_ENABLED]: 'true',
        [FEATURE_FLAGS.BACKSTAGE_ADVERTISER_ENABLE_CTA]: 'true',
    });
    const isGenAiEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });

    const isDescriptionPermitted = usePermissions('CAMPAIGN_ITEM_DESCRIPTION');
    const isCTAEditPermitted = usePermissions('CREATIVE_CTA_EDIT');
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();

    const { data } = useFormDataContext();
    const campaignId = data?.campaignId;
    const { isAISuggestionsVisible, aiSuggestionsCollapsed, setAiSuggestionsCollapsed, openAiSuggestions } =
        useContentTitleSuggestionsState(true);

    return (
        <CommonCollapsibleCard
            id="CREATIVE_CONTENT"
            header={<SectionHeader headerIcon={<ContentIcon />} headerText={contentSectionTitle} />}
        >
            <FormField containerClass={styles['container']} label={titleLabel}>
                <SuggestionContentTitle
                    campaignId={campaignId}
                    label={titleLabel}
                    onFocus={openAiSuggestions}
                    gaEventsPrefix="CREATIVE_EDITOR"
                />
                {isAISuggestionsVisible && isGenAiEnabled && (
                    <ContentTitleAISuggestions
                        campaignId={campaignId}
                        collapsed={aiSuggestionsCollapsed}
                        setCollapsed={setAiSuggestionsCollapsed}
                        showSuccessIndication
                        successMessageType={SUCCESS_INDICATION_TYPES.SINGLE}
                    />
                )}
            </FormField>
            <CreativeURL renderLabel={renderUrlLabel} />
            {isDescriptionEnabled && isDescriptionPermitted ? (
                <FormField
                    containerClass={styles['container']}
                    helpText={<CreativeDescriptionTooltip />}
                    label={descriptionLabel}
                >
                    <ContentDescription />
                </FormField>
            ) : null}
            {isCTAEnabled && isCTAEditPermitted && (
                <CTADropdown language={language} shouldIncludeNoneOption={!isAppInstall} searchable />
            )}
        </CommonCollapsibleCard>
    );
};
