import { useCallback } from 'react';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { isPricingModelCpmOrVcpm } from '../../../../../config/pricingModel';
import BID_STRATEGIES from '../../../config/bidStrategies';
import { getBidCacheKey, getBidStrategyCacheKey, useBidStrategyCacheContext } from '../BidStrategyCacheProvider';
import { strategyToCategoryMap } from '../hooks/useBidStrategiesOptions';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';

export const usePricingModelState = () => {
    const { value: pricingModel, onChange: onBasePricingModelChange } = useFormFieldValue({
        field: 'pricingModel',
    });
    const { value: bidStrategy, onChange: onBidStrategyChange } = useBidStrategyFormField();
    const { onChange: onChangeBid, resetFieldToInitial: resetBid } = useFormFieldValue({ field: 'bid' });
    const { bidStrategyCache, bidCache } = useBidStrategyCacheContext();

    const onPricingModelChange = useCallback(
        ({ value: pricingModel }) => {
            onBasePricingModelChange(pricingModel);

            const bidStrategyCategory = strategyToCategoryMap[bidStrategy];
            const defaultBidStrategy = isPricingModelCpmOrVcpm(pricingModel)
                ? BID_STRATEGIES.FIXED
                : BID_STRATEGIES.SMART;
            const cachedBidStrategy = bidStrategyCache[getBidStrategyCacheKey({ bidStrategyCategory, pricingModel })];
            const calculatedBidStrategy = cachedBidStrategy ? cachedBidStrategy : defaultBidStrategy;
            onBidStrategyChange(calculatedBidStrategy);

            const cachedBid =
                bidCache[
                    getBidCacheKey({
                        bidStrategyCategory,
                        pricingModel,
                        bidStrategy: calculatedBidStrategy,
                    })
                ];
            if (cachedBid) {
                onChangeBid(cachedBid);
            } else {
                resetBid();
            }
        },
        [onBasePricingModelChange, bidStrategy, bidStrategyCache, onBidStrategyChange, bidCache, onChangeBid, resetBid]
    );

    return {
        pricingModel,
        onPricingModelChange,
    };
};
