import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CREATIVE_REALTIME].id;

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.SPENT.field,
        FIELDS.BID_MODIFICATION.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.VCTR.field,
        FIELDS.CTR.field,
        FIELDS.CLICKS.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.CVR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.CPA.field,
        FIELDS.VCPM.field,
        FIELDS.CPM.field,
        FIELDS.CONVERSIONS_VALUE.field,
        FIELDS.ROAS.field,
    ].map(field => generateColumnId(reportId, field)),
    [REPORT_PRESET.SETUP]: [
        FIELDS.URL.field,
        FIELDS.ID.field,
        FIELDS.CUSTOM_CREATIVE_NAME.field,
        FIELDS.CUSTOM_ID.field,
        FIELDS.STATUS.field,
        FIELDS.ACCOUNT_ID.field,
        FIELDS.ACCOUNT_DESCRIPTION.field,
        FIELDS.CAMPAIGN_ID.field,
        FIELDS.CAMPAIGN_NAME.field,
        FIELDS.CREATE_TIME.field,
        FIELDS.DESCRIPTION.field,
        FIELDS.CTA.field,
        FIELDS.START_DATE.field,
        FIELDS.END_DATE.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.STATUS.field,
    FIELDS.CUSTOM_CREATIVE_NAME.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.TITLE.field,
    FIELDS.CONTENT.field,
    FIELDS.URL.field,
    FIELDS.ID.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.CAMPAIGN_NAME.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.VCTR.field,
    FIELDS.CLICKS.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
    FIELDS.CREATE_TIME.field,
    FIELDS.DESCRIPTION.field,
    FIELDS.CTA.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.ID.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.TITLE.field,
    FIELDS.CONTENT.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.VCTR.field,
    FIELDS.CLICKS.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
    FIELDS.ACTIONS_COLUMN.field,
    FIELDS.CONVERSIONS_VALUE.field,
    FIELDS.ROAS.field,
].map(field => generateColumnId(reportId, field));

const setupColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.CUSTOM_CREATIVE_NAME.field,
    FIELDS.CUSTOM_ID.field,
    FIELDS.THUMBNAIL_URL.field,
    FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
    FIELDS.TITLE.field,
    FIELDS.CONTENT.field,
    FIELDS.URL.field,
    FIELDS.ID.field,
    FIELDS.STATUS.field,
    FIELDS.ACCOUNT_ID.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.CAMPAIGN_ID.field,
    FIELDS.CAMPAIGN_NAME.field,
    FIELDS.CREATE_TIME.field,
    FIELDS.DESCRIPTION.field,
    FIELDS.CTA.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns, performanceColumns, setupColumns };
