import { useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import { convertToAppEventContext } from '../../app-events-aggregator/utils/convertToAppEventContext';
import config from '../../authentication/config';
import { gtmTracker } from '../../gtmTracker';
import { CHAT_AGENT_VERSION_V2 } from '../config/chatAgentVersionV2';
import { transformV1ToV2 } from '../transformers/v1v2Transformers';
import { cleanEventBeforeSending } from '../utils/cleanEventBeforeSending';
import { useChatAgentContextService } from './useChatAgentContextService';

export const useChatAgentMessageSender = ({ client }) => {
    const agentWindowRef = useRef([]);
    const registerAgent = useCallback(srcWindow => {
        agentWindowRef.current.push(srcWindow);
    }, []);
    const unregisterAgent = useCallback(srcWindow => {
        agentWindowRef.current = agentWindowRef.current.filter(src => src !== srcWindow);
    }, []);
    const clearWindowList = useCallback(() => {
        agentWindowRef.current = [];
    }, []);
    const gridApiRef = useRef(null);
    const wizardApiRef = useRef(null);
    const messageActionListenersRef = useRef([]);
    const registerGrid = useCallback(gridApi => {
        gridApiRef.current = gridApi;
        return () => (gridApiRef.current = null);
    }, []);
    const setCellToEdit = useCallback(cell => {
        if (gridApiRef.current) {
            gridApiRef.current.setCellToEdit(cell);
        }
    }, []);
    const registerWizard = useCallback(wizardApi => {
        wizardApiRef.current = wizardApi;
        return () => (wizardApiRef.current = null);
    }, []);
    const setWizardStep = useCallback(step => {
        if (wizardApiRef.current) {
            wizardApiRef.current.setWizardStep(step);
        }
    }, []);
    const registerChatTextMessageListener = useCallback(callback => {
        messageActionListenersRef.current.push(callback);

        return () => {
            messageActionListenersRef.current = messageActionListenersRef.current.filter(el => el !== callback);
        };
    }, []);
    const notifyChatTextMessageListeners = useCallback(messageAction => {
        messageActionListenersRef.current.forEach(callback => callback(messageAction));
    }, []);
    const { getContext } = useChatAgentContextService();

    const sendResponseToChatAgent = useCallback(
        async (message, source) => {
            if (isEmpty(agentWindowRef.current)) {
                gtmTracker.trackError(new Error('Chat Agent is not connected. Ignoring message:', message));
                return;
            }

            const rawMessageObject = {
                client,
                ...cleanEventBeforeSending(client === CHAT_AGENT_VERSION_V2 ? transformV1ToV2(message) : message),
            };
            rawMessageObject.context = await getContext();
            const messageObject =
                client === CHAT_AGENT_VERSION_V2 ? convertToAppEventContext(rawMessageObject) : rawMessageObject;

            if (source) {
                source.postMessage(messageObject, config.getChatAgentDomain());
                return;
            }

            agentWindowRef.current.forEach(srcWindow => {
                srcWindow.postMessage(messageObject, config.getChatAgentDomain());
            });
        },
        [getContext, client]
    );

    return {
        registerAgent,
        unregisterAgent,
        clearWindowList,
        sendResponseToChatAgent,
        registerGrid,
        setCellToEdit,
        registerWizard,
        setWizardStep,
        registerChatTextMessageListener,
        notifyChatTextMessageListeners,
    };
};
