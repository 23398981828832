import React, { useCallback } from 'react';
import { isPmpActiveAccount } from 'modules/account-management/accountType';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { ENTITY_MESSAGE_PREFIX, FORM_MODES } from '../../../config';
import {
    AttributionGroup,
    BudgetSectionPmpDeal,
    DealId,
    EvergreenField,
    DuplicateCampaignSettingsSection,
    MarketingObjective,
    Representatives,
    SetupSection,
    TargetingSection,
    TimeFrameSection,
    TrackingSection,
} from '../../common-campaign-form/components';
import { AudienceTargetingSectionCampaignEditor } from '../../common-campaign-form/components/AudienceTargetingSection/AudienceTargetingSectionCampaignEditor';

const PmpDealEditorContent = ({ shouldRenderAccountDropdown }) => {
    const { mode } = useFormDataContext();
    const accountFilter = useCallback(account => isPmpActiveAccount(account), []);

    return (
        <>
            {mode === FORM_MODES.DUPLICATE && <DuplicateCampaignSettingsSection />}
            <SetupSection
                isAccountSelectionEnabled={shouldRenderAccountDropdown}
                accountFilter={accountFilter}
                messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL}
                noAccountsMessageId="pmpDeal.editor.setup.no.pmp.deal.accounts"
            >
                <DealId />
                <EvergreenField />
                <MarketingObjective pixelAndConversionWarningEnabled />
                <Representatives />
                <AttributionGroup />
            </SetupSection>
            <TimeFrameSection />
            <TargetingSection messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL} isIncludeHighImpactTargeting={true} />
            <AudienceTargetingSectionCampaignEditor />
            <BudgetSectionPmpDeal />
            <TrackingSection />
        </>
    );
};

export default PmpDealEditorContent;
