import { getIsAndLogicAllowedOnAccount } from 'modules/campaigns/services/utils/audiences';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';

export const useIsMarketplaceAudiencesAndLogicEnabled = () => {
    const isAndLogicEnabled = useConfigMatch({ [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED]: 'true' });
    const accountToCheck = useAccount();

    return isAndLogicEnabled && getIsAndLogicAllowedOnAccount(accountToCheck);
};
