import React, { useCallback } from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { TARGETING_TYPES } from '../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { TopicsListItem } from '../TopicsListItem';
import styles from '../topicsListField.module.scss';

const SMALL_ITEM = 40;

export const ExcludedTopicsList = ({ terms, setExcludedTopics }) => {
    const onDeleteExcludedItem = useCallback(
        item => {
            setExcludedTopics(terms.filter(topic => topic !== item));
        },
        [terms, setExcludedTopics]
    );
    const onClearExcludedTopics = useCallback(() => {
        setExcludedTopics([]);
    }, [setExcludedTopics]);
    return (
        <CollapsibleList
            items={terms}
            ItemComponent={TopicsListItem}
            metadata={{ targetingType: TARGETING_TYPES.EXCLUDE }}
            deleteItem={onDeleteExcludedItem}
            itemHeight={SMALL_ITEM}
            clearItems={onClearExcludedTopics}
            listHeaderTitle={
                <FormattedMessage
                    id="custom.contextual.editor.topics.list.excluded.label"
                    defaultMessage="Excluded Topics"
                />
            }
            aria-label="exclude-list"
            showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
            showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
            containerClassName={styles['list']}
        />
    );
};
