import { useMemo, useRef } from 'react';
import { FORM_MODES } from 'config/formModes';
import {
    cpcValidationsForCreate,
    cpcValidationsForEdit,
    cpmValidationsForCreate,
    cpmValidationsForEdit,
    PRICING_MODEL,
    vcpmValidationsForCreate,
    vcpmValidationsForEdit,
} from 'modules/campaigns/config';
import { restrictNumberWithDigits, ROUNDING } from 'modules/campaigns/modules/campaigns-reports/utils/numberOperations';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { SELF_FACTOR_FIELD_NAME } from '../../../../config/validations/campaign/bidValidations';
import { useBidStrategyFormField } from '../BidStrategyV2/hooks/useBidStrategyFormField';
import { useBidValidationRatio } from '../Budget/hooks/useBidValidationRatio';
import { useBudgetFormFieldValue } from '../Budget/hooks/useBudgetFormFieldValue';
import { useMarketplaceAudienceTargetingValues } from '../MarketplaceAudiences/hooks/useMarketplaceAudienceTargetingValues';
import { useCpcAccountConfig } from './useCpcAccountConfig';
import { useCpmAccountConfig } from './useCpmAccountConfig';
import { useVcpmAccountConfig } from './useVcpmAccountConfig';

const pricingModelToValidations = {
    [PRICING_MODEL.CPC]: {
        editValidations: cpcValidationsForEdit,
        createValidations: cpcValidationsForCreate,
    },
    [PRICING_MODEL.CPM]: {
        editValidations: cpmValidationsForEdit,
        createValidations: cpmValidationsForCreate,
    },
    [PRICING_MODEL.VCPM]: {
        editValidations: vcpmValidationsForEdit,
        createValidations: vcpmValidationsForCreate,
    },
};

export const useBidValidations = (pricingModel, data) => {
    const { currency, tier } = useAccount();
    const { isEqualToInitial: isBidStrategyEqualToInitial } = useBidStrategyFormField();
    const { value: bid = data?.bid, mode: formMode } = useFormFieldValue({ field: 'bid' });
    const { value: budget = data?.budget } = useBudgetFormFieldValue();
    // Note: I'm adding a default value here because some legacy tests don't provide a form context.

    const {
        excludeLength: marketPlaceAudienceExcludeValuesLength,
        includeLength: marketPlaceAudienceIncludeValuesLength,
    } = useMarketplaceAudienceTargetingValues();

    const cpcAccountConfig = useCpcAccountConfig(tier);
    const cpmAccountConfig = useCpmAccountConfig();
    const vcpmAccountConfig = useVcpmAccountConfig();

    const originValueRef = useRef(bid);
    const originalBidValue = originValueRef.current;
    const budgetValue = budget?.value;

    const bidValidationRatio = useBidValidationRatio(data);

    const { editValidations, createValidations } = pricingModelToValidations[pricingModel] || {};
    const budgetMaxThreshold =
        bidValidationRatio !== 0 && budgetValue && bidValidationRatio
            ? restrictNumberWithDigits(budgetValue / bidValidationRatio, 3, ROUNDING.ROUND_DOWN)
            : undefined;

    const validationDependencies = useMemo(
        () => ({
            pricingModel,
            tier,
            currency,
            marketPlaceAudienceIncludeValuesLength,
            marketPlaceAudienceExcludeValuesLength,
            ...cpcAccountConfig,
            ...cpmAccountConfig,
            ...vcpmAccountConfig,
            ...data,
            budgetValue,
            budgetMaxThreshold,
            [SELF_FACTOR_FIELD_NAME]: originalBidValue,
        }),
        [
            pricingModel,
            tier,
            currency,
            marketPlaceAudienceIncludeValuesLength,
            marketPlaceAudienceExcludeValuesLength,
            cpcAccountConfig,
            cpmAccountConfig,
            vcpmAccountConfig,
            data,
            budgetValue,
            originalBidValue,
            budgetMaxThreshold,
        ]
    );

    const validations = formMode === FORM_MODES.CREATE ? createValidations : editValidations;

    return {
        field: 'bid',
        validationDependencies: { data: validationDependencies },
        validations,
        hasInitialData: isBidStrategyEqualToInitial,
    };
};
