import { MODULE_PARAM_NAME } from 'config/routes/module';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { DEFAULT_SEGMENT_ID, MODULE_NAME as AUDIENCE_INSIGHT_MODULE_NAME } from '../config';

export const SEGMENT = 'segment';

export const useSelectedSegment = queryParamHookFactory(SEGMENT, {
    persist: PERSISTENCE_TYPE.MEMORY,
    defaultValue: ({ [MODULE_PARAM_NAME]: moduleName }) => {
        if (moduleName !== AUDIENCE_INSIGHT_MODULE_NAME) {
            return;
        }

        return DEFAULT_SEGMENT_ID;
    },
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
    },
});
