import { createSelector } from 'reselect';

export const campaignsStateSelector = state => state.campaignsReducer;

export const aiTabImagePromptMapSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.aiTabImagePromptMap
);

export const aiTabReferenceImageSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.aiTabReferenceImage
);
