import React, { useCallback, useRef, useState } from 'react';
import config from './GoogleApiConfig';
import { GoogleApiContextProvider } from './GoogleApiContext';
import { SCOPES } from './constants';
import { useLoadGIS, useLoadGapiClient } from './hooks';

const GoogleApiProvider = ({ children, clientId = config.getClientId(), scopes = SCOPES }) => {
    const [googleSheetsLoaded, setGoogleSheetsLoaded] = useState(false);
    const tokenExpirationTime = useRef(null);
    const setTokenExpirationTime = useCallback(
        time => {
            tokenExpirationTime.current = time;
        },
        [tokenExpirationTime]
    );
    const {
        getToken,
        revokeToken,
        isSuccess: isGisLoaded,
        getIsPermitted,
        requestToken,
    } = useLoadGIS({
        clientId,
        scopes,
    });
    const { isSuccess: isGapiLoaded } = useLoadGapiClient();

    return (
        <GoogleApiContextProvider
            value={{
                googleSheetsLoaded,
                setGoogleSheetsLoaded,
                getToken,
                revokeToken,
                isGisLoaded,
                isGapiLoaded,
                getIsPermitted,
                requestToken,
                tokenExpirationTime,
                setTokenExpirationTime,
            }}
        >
            {children}
        </GoogleApiContextProvider>
    );
};

export default GoogleApiProvider;
