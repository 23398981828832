'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _BaseRadio = require('../BaseRadio');

var _BaseRadio2 = _interopRequireDefault(_BaseRadio);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'iconRadioButton__taboola-svg-icon___1MQwq',
    'label': 'iconRadioButton__label___29vxU',
    'title': 'iconRadioButton__title___2XoBQ',
    'description': 'iconRadioButton__description___JTqBc',
    'checked-label': 'iconRadioButton__checked-label___1IRef',
    'error': 'iconRadioButton__error___u9mAW',
    'icon-container': 'iconRadioButton__icon-container___39s_y',
    'input': 'iconRadioButton__input___1hewI',
    'seperator': 'iconRadioButton__seperator___1zwPb'
};


var classNameBuilder = _bind2.default.bind(styles);

// eslint-disable-next-line react/prefer-stateless-function

var IconRadioButton = function (_Component) {
    _inherits(IconRadioButton, _Component);

    function IconRadioButton() {
        _classCallCheck(this, IconRadioButton);

        return _possibleConstructorReturn(this, (IconRadioButton.__proto__ || Object.getPrototypeOf(IconRadioButton)).apply(this, arguments));
    }

    _createClass(IconRadioButton, [{
        key: 'render',
        value: function render() {
            var selectedValue = this.context.radioGroup.selectedValue;

            var _props = this.props,
                value = _props.value,
                title = _props.title,
                description = _props.description,
                iconComponent = _props.iconComponent,
                iconContainerClassName = _props.iconContainerClassName,
                labelClassName = _props.labelClassName,
                checkedLabelClassName = _props.checkedLabelClassName,
                children = _props.children,
                titleClassName = _props.titleClassName,
                descriptionClassName = _props.descriptionClassName,
                onMouseEnter = _props.onMouseEnter,
                onMouseLeave = _props.onMouseLeave,
                error = _props.error,
                rest = _objectWithoutProperties(_props, ['value', 'title', 'description', 'iconComponent', 'iconContainerClassName', 'labelClassName', 'checkedLabelClassName', 'children', 'titleClassName', 'descriptionClassName', 'onMouseEnter', 'onMouseLeave', 'error']);

            var checked = selectedValue === value;

            var titleMergedClassName = classNameBuilder('title', titleClassName);
            var descriptionMergedClassName = classNameBuilder('description', descriptionClassName);

            return _react2.default.createElement(
                _BaseRadio2.default,
                Object.assign({}, rest, {
                    value: value,
                    size: _BaseRadio.RADIO_SIZE.SMALL,
                    labelClassName: classNameBuilder('label', labelClassName, {
                        error: error
                    }),
                    inputClassName: styles['input'],
                    error: error,
                    checkedLabelClassName: classNameBuilder('checked-label', checkedLabelClassName),
                    onMouseEnter: onMouseEnter,
                    onMouseLeave: onMouseLeave
                }),
                _react2.default.createElement(
                    'div',
                    {
                        className: classNameBuilder('icon-container', iconContainerClassName)
                    },
                    iconComponent,
                    _react2.default.createElement(
                        'span',
                        { className: titleMergedClassName },
                        title
                    ),
                    description && _react2.default.createElement(
                        'span',
                        { className: descriptionMergedClassName },
                        description
                    )
                ),
                children && checked && _react2.default.createElement(
                    _react.Fragment,
                    null,
                    _react2.default.createElement('span', { className: styles['seperator'] }),
                    children
                )
            );
        }
    }]);

    return IconRadioButton;
}(_react.Component);

IconRadioButton.propTypes = {
    /** Radio title */
    title: _propTypes2.default.node.isRequired,
    /** Radio description */
    description: _propTypes2.default.node,
    /** Icon component */
    iconComponent: _propTypes2.default.node.isRequired,
    /** Components to the right of the icon */
    children: _propTypes2.default.node,
    /** Apply class to icon container */
    iconContainerClassName: _propTypes2.default.string,
    /** Apply class to checked label */
    checkedLabelClassName: _propTypes2.default.string,
    /** Apply class to style the radio label */
    labelClassName: _propTypes2.default.string,
    /** Apply class to style the title span */
    titleClassName: _propTypes2.default.string,
    /** Apply class to style the description span */
    descriptionClassName: _propTypes2.default.string,
    /** Value of the radio */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.bool]).isRequired,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    error: _propTypes2.default.bool,
    /** Radio input color */
    radioColor: _propTypes2.default.string,
    /** On mouse enter action */
    onMouseEnter: _propTypes2.default.func,
    /** On mouse leave action */
    onMouseLeave: _propTypes2.default.func,
    /** Hide radio pointer */
    hideRadioPointer: _propTypes2.default.bool
};

IconRadioButton.defaultProps = {
    iconContainerClassName: '',
    checkedLabelClassName: '',
    labelClassName: '',
    titleClassName: ''
};

IconRadioButton.contextTypes = {
    radioGroup: _propTypes2.default.object
};

exports.default = IconRadioButton;