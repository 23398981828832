import { useCallback } from 'react';
import { head, size } from 'lodash';
import TARGETING_TYPES from '../../../../config/TargetingTypes';
import { useMarketplaceAudienceTargetingCollection } from '../../hooks';
import { getTargetingValuesFieldName } from '../../utils/andLogicUtils';

// This hook is only rendered when And targeting is disabled and valid (checked in useMarketplaceAudienceTargetingValues).
// Therefore, we can assume there's at most one Include and at most one Exclude group.
const getIncludeExcludeIndices = marketplaceAudienceTargetingCollection => {
    const firstGroupType = head(marketplaceAudienceTargetingCollection)?.type;

    if (!firstGroupType || firstGroupType === TARGETING_TYPES.INCLUDE) {
        return { includeIndex: 0, excludeIndex: 1 };
    } else {
        return { includeIndex: 1, excludeIndex: 0 };
    }
};

export const useMarketplaceAudiencesTargetingFieldNames = () => {
    const { marketplaceAudienceTargetingCollection, onAddTargeting } = useMarketplaceAudienceTargetingCollection();
    const { includeIndex, excludeIndex } = getIncludeExcludeIndices(marketplaceAudienceTargetingCollection);

    const handleAddMissingTargetingGroup = useCallback(
        targetingType => {
            const collectionSize = size(marketplaceAudienceTargetingCollection);
            const targetingIndex = targetingType === TARGETING_TYPES.INCLUDE ? includeIndex : excludeIndex;

            // If the index for this targeting type group is missing, add the empty group
            if (targetingIndex >= collectionSize) {
                onAddTargeting(targetingType);
            }
        },
        [excludeIndex, includeIndex, marketplaceAudienceTargetingCollection, onAddTargeting]
    );

    return {
        handleAddMissingTargetingGroup,
        includeIdsFormFieldName: getTargetingValuesFieldName(includeIndex),
        excludeIdsFormFieldName: getTargetingValuesFieldName(excludeIndex),
    };
};
