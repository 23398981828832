import { createSelector } from 'reselect';

export const authenticationStateSelector = state => state.authenticationReducer;

export const userSelector = createSelector(
    authenticationStateSelector,
    authenticationState => authenticationState.user
);

export const authentificationSelector = createSelector(
    authenticationStateSelector,
    authenticationState => !!authenticationState.user?.isFetched
);
