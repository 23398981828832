import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import styles from './smartListLoadingItem.module.scss';

const SmartListLoadingItem = ({ className, children, loading, contentContainerClassName }) => (
    <div className={`${styles['item-wrapper']} ${className}`}>
        {loading && <Spinner className={styles['spinner']} loading size={14} />}
        <div className={`${styles['content']} ${contentContainerClassName}`}>{children}</div>
    </div>
);

SmartListLoadingItem.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    contentContainerClassName: PropTypes.string,
    className: PropTypes.string,
};

SmartListLoadingItem.defaultProps = {
    children: null,
    loading: true,
    contentContainerClassName: '',
    className: '',
};

export default SmartListLoadingItem;
