import React, { useCallback } from 'react';
import { useSelectedAccount } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { GLOBAL_PRESET_ACCOUNT_ID } from '../../../../ReportPresetPicker/constants';
import { PresetToggle } from '../PresetToggle/PresetToggle';

const label = (
    <FormattedMessage
        id="app.campaigns.reports.columnPicker.account.level.label"
        defaultMessage="Show this preset across all my accounts"
    />
);

const helpText = (
    <FormattedMessage
        id="app.campaigns.reports.columnPicker.account.level.help.tooltip"
        defaultMessage={
            'To save this column preset for future use across all the accounts you can access, turn on the "Show this preset across all my accounts” toggle button. By default, this option is not enabled and the new preset will appear for this report in this account only.'
        }
    />
);

export const AccountLevelToggle = () => {
    const { value: currentAccountId, onChange } = useFormFieldValue({ field: 'accountId' });
    const [{ id: selectedAccountId }] = useSelectedAccount();

    const isGlobalPreset = currentAccountId === GLOBAL_PRESET_ACCOUNT_ID;
    const handleTogglePresetAccountLevel = useCallback(
        shouldUseGlobalPreset => {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                pageName: getPageName(),
                component: 'global preset',
                value: shouldUseGlobalPreset ? 'on' : 'off',
            });
            const updatedAccountId = shouldUseGlobalPreset ? GLOBAL_PRESET_ACCOUNT_ID : selectedAccountId;
            onChange(updatedAccountId);
        },
        [selectedAccountId, onChange]
    );

    return (
        <PresetToggle
            isChecked={isGlobalPreset}
            onChange={handleTogglePresetAccountLevel}
            label={label}
            helpText={helpText}
        />
    );
};
