"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLink(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M18.002 7.402L18 10a1 1 0 0 0 2 0V5.993A1.995 1.995 0 0 0 18.003 4H14a1 1 0 0 0 0 2h2.563l-5.266 5.27a.988.988 0 0 0 0 1.41c.395.39 1.034.39 1.43 0l5.275-5.278zM18 18.007L6.002 18C6 18 6 5.993 6 5.993 6 6.001 9.99 6 9.99 6a1 1 0 1 0 0-2H5.996A1.999 1.999 0 0 0 4 5.993v12.014C4 19.109 4.899 20 6.002 20h11.996A1.992 1.992 0 0 0 20 18.007V14a1 1 0 0 0-2 0v4.007z"
            })
        )
    );
}
exports.default = SvgLink;