/**
 * Created by oreuveni
 * Date: 28/06/2018
 * Time: 13:09
 */
import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import styles from './loadingCellRenderer.module.scss';

class LoadingCellRenderer extends Component {
    render() {
        return (
            <span className={styles['loader']}>
                <ContentLoader className={styles['content']} speed={1}>
                    <rect x="20" y="45" rx="20" ry="20" width="330" height="30" />
                </ContentLoader>
            </span>
        );
    }
}

export default LoadingCellRenderer;
