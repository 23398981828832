import React from 'react';
import { filter } from 'lodash';
import { DeletableItem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addCombinedAudience.module.scss';

export const DeleteCombinedAudience = ({ audience, setAudienceSetup }) => (
    <DeletableItem onDelete={() => setAudienceSetup(prev => filter(prev, ({ id }) => id !== audience.id))}>
        <div className={styles['must-include']}>
            <FormattedMessage id="audience.creator.combined.audiences.targeting.must-include" defaultMessage="AND" />
        </div>
    </DeletableItem>
);
