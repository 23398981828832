import React from 'react';
import PropTypes from 'prop-types';
import {
    StyledWithModeButton,
    STYLED_WITH_MODE_BUTTON_TYPE,
    SORTING_ORDER_TYPES,
    STYLED_WITH_MODE_BUTTON_SIZE,
    BUTTON_MODE,
    SortUpIcon,
    SortDownIcon,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MSG_ID_PREFIX } from '../constants';
import styles from './collapsiblePickerSortTypeToggle.module.scss';

const CollapsiblePickerSortTypeToggle = ({ sortType, onSelectSortType }) => {
    const ascMode = sortType === SORTING_ORDER_TYPES.ASCENDANT ? BUTTON_MODE.ACTIVE_CLOSED : BUTTON_MODE.NONE;
    const descMode = sortType === SORTING_ORDER_TYPES.DESCENDANT ? BUTTON_MODE.ACTIVE_CLOSED : BUTTON_MODE.NONE;
    const handleSelectSortType = type => () => {
        if (sortType !== type) {
            onSelectSortType(type);
        }
    };

    return (
        <div className={styles['container']}>
            <StyledWithModeButton
                className={styles['desc-button']}
                type={STYLED_WITH_MODE_BUTTON_TYPE.SYSTEM}
                size={STYLED_WITH_MODE_BUTTON_SIZE.REGULAR}
                mode={descMode}
                iconBefore={<SortDownIcon />}
                onClick={handleSelectSortType(SORTING_ORDER_TYPES.DESCENDANT)}
            >
                <FormattedMessage id={`${MSG_ID_PREFIX}.sorting.desc`} defaultMessage="Descending" />
            </StyledWithModeButton>
            <StyledWithModeButton
                className={styles['asc-button']}
                type={STYLED_WITH_MODE_BUTTON_TYPE.SYSTEM}
                size={STYLED_WITH_MODE_BUTTON_SIZE.REGULAR}
                mode={ascMode}
                iconBefore={<SortUpIcon />}
                onClick={handleSelectSortType(SORTING_ORDER_TYPES.ASCENDANT)}
            >
                <FormattedMessage id={`${MSG_ID_PREFIX}.sorting.asc`} defaultMessage="Ascending" />
            </StyledWithModeButton>
        </div>
    );
};

CollapsiblePickerSortTypeToggle.propType = {
    sortType: PropTypes.oneOf(Object.values(SORTING_ORDER_TYPES)),
    onSelectSortType: PropTypes.func,
};

export default CollapsiblePickerSortTypeToggle;
