import { ENTITY, ENTITY_TO_PATH_MAP } from 'modules/campaigns/config/routes';
import { callGetApi, callPutApi, callPostApi, callDeleteApi } from './apiCallerProxy';

export const videoCreativesAPIPrefix = ({ accountId, campaignId, creativeId }) => {
    return `/${accountId}/${ENTITY_TO_PATH_MAP[ENTITY.CAMPAIGN_VIDEO]}/${campaignId}/${
        ENTITY_TO_PATH_MAP[ENTITY.CREATIVE_VIDEO]
    }${creativeId ? `/${creativeId}` : ''}`;
};

const videoCreativesApi = {
    getCreative: (accountId, campaignId, creativeId) =>
        callGetApi(videoCreativesAPIPrefix({ accountId, campaignId, creativeId })),
    updateVideoCreative: (accountId, campaignId, creativeId, creative) =>
        callPutApi(videoCreativesAPIPrefix({ accountId, campaignId, creativeId }), creative),
    createCreative: (accountId, campaignId, creative) =>
        callPostApi(videoCreativesAPIPrefix({ accountId, campaignId }), creative),
    deleteCreative: (accountId, campaignId, creativeId) =>
        callDeleteApi(videoCreativesAPIPrefix({ accountId, campaignId, creativeId })),
};

export default videoCreativesApi;
