const updatePointsWithPredicate = (predicateFn, value) => points =>
    points.map(point => {
        if (predicateFn(point)) {
            return { ...point, ...value };
        }
        return point;
    });

export const updatePointsById = (id, value) => updatePointsWithPredicate(point => point?.id === id, value);

export const getClicks = points => points.filter(({ hovered }) => !hovered);

export const appendToClicks = point => points => [...getClicks(points), point];

export const destructurePoints = points => {
    const pointCoords = [];
    const pointLabels = [];
    for (const { x, y, clickType, xImageOffset, yImageOffset } of points) {
        pointCoords.push([x + xImageOffset, y + yImageOffset]);
        pointLabels.push(clickType);
    }
    return { pointCoords, pointLabels };
};
