import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useSelectedAccount } from 'hooks';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useCampaignGroupApi } from 'services/api';
import { errorMessagesUtils } from '../../../services/utils';
import { deleteCampaignGroupError, deleteCampaignGroupSuccess } from '../actions/deleteCampaignGroup';

export const successIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.delete.campaign.group.success.message"
            defaultMessage="Campaign group successfully deleted."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.campaigns.delete.campaign.group.error.message"
            defaultMessage="Unable to delete the Campaign Group."
        />
    ),
});

export const useDeleteCampaignGroup = () => {
    const dispatch = useDispatch();
    const { open: openModal } = useModal();
    const [{ accountName }] = useSelectedAccount();
    const campaignGroupApi = useCampaignGroupApi();
    const deleteCampaignGroup = useCallback(
        async campaignGroupData => {
            const { campaignsGroupId, campaignsGroupName } = campaignGroupData;

            const result = await openModal({
                title: (
                    <FormattedMessage
                        id="app.campaigns.delete.campaign.group.confirmation.title"
                        defaultMessage="Delete Campaign Group?"
                        tagName="h2"
                    />
                ),
                content: (
                    <FormattedMessage
                        id="app.campaigns.delete.campaign.group.confirmation.message"
                        defaultMessage="You are about to delete: {name}\n Once a campaign group is deleted it can never run again, and all the campaign within it will be deleted as well.\n Instead of deleting, you can pause the campaign group and it will also pause the campaigns within it.\n Do you wish to proceed?"
                        values={{ name: <b>{campaignsGroupName}</b> }}
                    />
                ),
                formProps: {
                    submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" />,
                },
            });

            if (!result) {
                return;
            }
            try {
                const response = await campaignGroupApi.deleteCampaignGroup(accountName, campaignsGroupId);
                batch(() => {
                    dispatch(deleteCampaignGroupSuccess(response));
                    dispatch(addIndication(successIndication));
                });
            } catch (error) {
                batch(() => {
                    dispatch(deleteCampaignGroupError(error));
                    dispatch(addIndication(generateErrorIndication(error)));
                });
            }
        },
        [openModal, accountName, campaignGroupApi, dispatch]
    );

    return deleteCampaignGroup;
};
