import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { PRICING_MODEL } from '../../../../../config';
import { useFormBidValidatedValue } from '../../Bid/useFormBidValidatedValue';
import { BidFormField } from './BidFormField';
import styles from './bidFormField.module.scss';

export const CPMBidType = () => {
    const {
        value: bid = '',
        onChange,
        failedValidationData,
        scrollRef,
    } = useFormBidValidatedValue({
        pricingModel: PRICING_MODEL.CPM,
    });

    return (
        <BidFormField
            label={<FormattedMessage id="campaign.editor.bid.type.title.CPM" defaultMessage="CPM" />}
            labelClass={styles['label']}
            descriptionClass={styles['description']}
            description={
                <FormattedMessage
                    id="campaign.editor.bid.type.description.CPM"
                    defaultMessage="Cost per 1000 impressions"
                />
            }
            ref={scrollRef}
            bid={bid}
            onBidChange={onChange}
            failedValidationData={failedValidationData}
        />
    );
};
