import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityType } from 'hooks/index';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useMultiLayerPathParams } from '../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { CAMPAIGNS_ROUTE, PATH_TO_ENTITY_MAP } from '../../../config';
import { invalidateSubCountryData } from '../actions';
import { useReportReachToGTM } from '../components/ReachEstimator/hooks/useReportReachToGTM';
import { fetchCampaignForEdit, saveCampaign } from '../flows';

const useCampaignEditorState = () => {
    const {
        mode,
        formAccount: { accountName },
        fetchedData: originalCampaign,
    } = useFormDataContext();
    const dispatch = useDispatch();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const { campaignId } = useMultiLayerPathParams();
    const formDataFetcher = useCallback(
        () => dispatch(fetchCampaignForEdit(accountName, campaignId, entityType)),
        [accountName, campaignId, dispatch, entityType]
    );
    const { submit } = useFormState({ formDataFetcher });
    const { reportReachToGTM } = useReportReachToGTM();

    const handleCampaignSubmitWrapper = useCallback(() => {
        reportReachToGTM();
        const saveCampaignWrapper = campaign =>
            dispatch(
                saveCampaign({
                    accountName,
                    originalCampaign,
                    editedCampaign: campaign,
                    entityType,
                    mode,
                })
            );

        submit(saveCampaignWrapper, CAMPAIGNS_ROUTE);
    }, [reportReachToGTM, submit, dispatch, accountName, originalCampaign, entityType, mode]);

    //clearing resources
    useEffect(
        () => () => {
            dispatch(invalidateSubCountryData());
        },
        [dispatch]
    );

    return {
        submit: handleCampaignSubmitWrapper,
    };
};

export default useCampaignEditorState;
