import { useMemo } from 'react';
import { last } from 'lodash';
import { useDrawerStack } from '../../../../hooks/queryParams/useDrawerStack';

export const useIsLastLayer = path => {
    const { stack } = useDrawerStack();
    const isLastLayer = useMemo(() => last(stack)?.path === path, [stack, path]);

    return isLastLayer;
};
