import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { replacePathParams } from '../../campaigns/services/utils';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { CONVERSION_FUNNEL_CREATOR_ROUTE_PATH, ENTITY, ENTITY_TO_PATH_MAP } from '../config';
import { UnipFunnelCreatorPage } from './UnipFunnelCreatorPage';

const CONVERSION_FUNNEL_ROUTE = `/${ENTITY_TO_PATH_MAP[ENTITY.FUNNEL]}`;
const path = CONVERSION_FUNNEL_CREATOR_ROUTE_PATH;

export const UnipFunnelCreator = ({ onCancel }) => {
    const createConversionFunnelPath = replacePathParams(path);

    useUnsavedChangesController(CONVERSION_FUNNEL_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                strict
                path={createConversionFunnelPath}
                render={props => <UnipFunnelCreatorPage {...props} onCancel={onCancel} />}
            />
            <Redirect to={CONVERSION_FUNNEL_ROUTE} />
        </Switch>
    );
};

UnipFunnelCreator.propTypes = {
    onCancel: PropTypes.func,
};
