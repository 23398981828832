import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { ContentDescription } from 'modules/campaigns/components/ContentEditableText';
import { CreativeDescriptionTooltip } from 'modules/campaigns/components/Tooltips';
import commonStyles from '../fields.module.scss';
import styles from './contentDescriptionItem.module.scss';

const commonClassNameBuilder = classnames.bind(commonStyles);

export const ContentDescriptionItem = ({ id, label, labelClass, placeholder, autoFocus, showHelpTooltip, ...rest }) => {
    return (
        <FormField
            containerClass={styles['container']}
            labelClass={commonClassNameBuilder('header', labelClass)}
            helpText={showHelpTooltip && <CreativeDescriptionTooltip />}
            helpIconLabel="help-tooltip"
            label={label}
        >
            <ContentDescription
                id={id}
                className={styles['input']}
                label={label}
                placeholder={placeholder}
                autoFocus={autoFocus}
                {...rest}
            />
        </FormField>
    );
};

ContentDescriptionItem.propTypes = {
    id: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.node,
    autoFocus: PropTypes.bool,
    showHelpTooltip: PropTypes.bool,
};

ContentDescriptionItem.defaultProps = {
    showHelpTooltip: true,
};
