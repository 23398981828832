import React, { useState, useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    ImagePlaceholder,
    DropAreaIndicator,
    useDragAndDropFileContext,
    INDICATION_TYPES,
    TooltipV2 as Tooltip,
    WarningCircleIcon,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { defaultDropFileText } from '../../../../creative-creator/components/DropContainer/DropContainer';
import { FOCUS_TYPES } from '../../../config';
import { FocalPointEditor } from '../FocalPoint';
import CloudinaryImageWithEditBanner from './CloudinaryImageWithEditBanner';
import ThumbnailEditorImageOverlay from './ThumbnailEditorImageOverlay';
import styles from './thumbnailEditorImage.module.scss';

const classNameBuilder = classnames.bind(styles);

const ThumbnailEditorImage = ({
    imageSrc,
    creativeFocus,
    onChangeCreativeFocus,
    isThumbnailLoading,
    initialIsEditing,
    imageError,
    applyFocusToMatches,
    setApplyFocusToMatches,
    numMatchingCreatives,
    isFocalPointEditorEnabled,
    failedValidationData,
    scrollRef,
    openCropModal,
    disabled,
}) => {
    const { isDragActive } = useDragAndDropFileContext();
    const [isEditing, setIsEditing] = useState(initialIsEditing);

    const isErrorValidation = failedValidationData?.indicationType === INDICATION_TYPES.ERROR;
    const isWarningValidation = failedValidationData?.indicationType === INDICATION_TYPES.WARNING;

    const handleCancel = useCallback(
        initialCreativeFocus => {
            setIsEditing(false);
            onChangeCreativeFocus(initialCreativeFocus);
        },
        [setIsEditing, onChangeCreativeFocus]
    );

    const startEditing = useCallback(() => setIsEditing(true), [setIsEditing]);
    const stopEditing = useCallback(() => setIsEditing(false), [setIsEditing]);

    const encodedImageSrc = encodeURIComponent(imageSrc);

    const onChangeCoordinates = coordinates => {
        const newCreativeFocus = {
            type: FOCUS_TYPES.COORDINATES,
            coordinates,
        };
        onChangeCreativeFocus(newCreativeFocus);
    };

    const onResetFocalPoint = () => {
        const defaultCreativeFocus = {
            type: FOCUS_TYPES.AUTOMATIC,
            coordinates: null,
        };
        onChangeCreativeFocus(defaultCreativeFocus);
    };

    if (imageError) {
        return (
            <div className={styles['container']}>
                <ImagePlaceholder />
                <ThumbnailEditorImageOverlay isThumbnailLoading={isThumbnailLoading} imageError={imageError} />
            </div>
        );
    }

    return (
        <div
            className={classNameBuilder('container', {
                disabled,
                error: isErrorValidation,
                warning: isWarningValidation,
            })}
            ref={scrollRef}
            data-automation-value={imageSrc}
        >
            {isDragActive && <DropAreaIndicator text={defaultDropFileText} />}
            {isEditing && (
                <FocalPointEditor
                    imageSrc={encodedImageSrc}
                    creativeFocus={creativeFocus}
                    imageContainerClassName={styles['thumbnail-container']}
                    imageClassName={styles['thumbnail-image']}
                    applyFocusToMatches={applyFocusToMatches}
                    setApplyFocusToMatches={setApplyFocusToMatches}
                    numMatchingCreatives={numMatchingCreatives}
                    onCancel={handleCancel}
                    onDone={stopEditing}
                    onChangeCoordinates={onChangeCoordinates}
                    onResetFocalPoint={onResetFocalPoint}
                />
            )}
            <CloudinaryImageWithEditBanner
                imageSrc={encodedImageSrc}
                width={styles.thumbnailWidth}
                height={styles.thumbnailHeight}
                isHidden={isEditing}
                onClickEdit={startEditing}
                creativeFocus={creativeFocus}
                editPermissions="CREATIVE_PREVIEW_VIEW"
                isFocalPointEditorEnabled={isFocalPointEditorEnabled}
                openCropModal={openCropModal}
            />
            <ThumbnailEditorImageOverlay isThumbnailLoading={isThumbnailLoading} />

            {(isErrorValidation || isWarningValidation) && (
                <div className={styles['badge-container']} aria-label="thumbnail-editor-image-validation-error">
                    <WarningCircleIcon
                        className={classNameBuilder('badge', {
                            error: isErrorValidation,
                            warning: isWarningValidation,
                        })}
                    />
                    <Tooltip position={TOOLTIP_POSITION.RIGHT}>{failedValidationData.message}</Tooltip>
                </div>
            )}
        </div>
    );
};

ThumbnailEditorImage.propTypes = {
    imageSrc: PropTypes.string,
    creativeFocus: PropTypes.object,
    onChangeCreativeFocus: PropTypes.func,
    isThumbnailLoading: PropTypes.bool,
    initialIsEditing: PropTypes.bool,
    imageError: PropTypes.shape({
        status: PropTypes.number,
    }),
    applyFocusToMatches: PropTypes.bool,
    setApplyFocusToMatches: PropTypes.func,
    numMatchingCreatives: PropTypes.number,
    disabled: PropTypes.bool,
    failedValidationData: PropTypes.object,
    scrollRef: PropTypes.object,
};

ThumbnailEditorImage.defaultProps = {
    initialIsEditing: false,
};

export default ThumbnailEditorImage;
