import React from 'react';
import { ViewCarouselOutlinedIcon } from 'tuui';
import { COMMON_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CarouselEditorAdvancedPreview } from '../CarouselEditorAdvancedPreview/CarouselEditorAdvancedPreview';
import styles from './CarouselEditorHeader.module.scss';

export const CarouselEditorHeader = ({ hierarchyRepItemId }) => {
    const isAdvancedPreviewUrlWithHash = useConfigMatch(
        {},
        {
            [COMMON_FLAGS.ADVANCED_PREVIEW_URL_WITH_HASH]: 'true',
        }
    );

    return (
        <div className={styles['wrapper']}>
            <div className={styles['header']}>
                <ViewCarouselOutlinedIcon className={styles['icon']} aria-label="Carousel indication" />
                <FormattedMessage id="creative.edit.carousel.edit.form.header" defaultMessage="Edit Carousel" />
            </div>
            <div className={styles['container']}>
                <div className={styles['id']}>ID: {hierarchyRepItemId}</div>
                {isAdvancedPreviewUrlWithHash && <CarouselEditorAdvancedPreview />}
            </div>
        </div>
    );
};
