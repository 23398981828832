import React, { createContext, useContext, useMemo, useState } from 'react';

const ContextMenuContext = createContext({});

export const useContextMenu = () => useContext(ContextMenuContext);

export const ContextMenuProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [menuPosition, setMenuPosition] = useState(null);

    const value = useMemo(
        () => ({
            data,
            setData,
            menuPosition,
            setMenuPosition,
        }),
        [data, menuPosition]
    );

    return <ContextMenuContext.Provider value={value}>{children}</ContextMenuContext.Provider>;
};
