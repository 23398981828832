'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _memoizeOne = require('memoize-one');

var _memoizeOne2 = _interopRequireDefault(_memoizeOne);

var _BackgroundOverlay = require('../../BackgroundOverlay');

var _BackgroundOverlay2 = _interopRequireDefault(_BackgroundOverlay);

var _Icons = require('../../Icons/Icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'externalApps__taboola-svg-icon___1A9hb',
    'container': 'externalApps__container___2LhhQ',
    'body': 'externalApps__body___3H2AO',
    'app': 'externalApps__app___3dbpe',
    'initial': 'externalApps__initial___1Fgx4',
    'label': 'externalApps__label___2aYUT'
};


var ExternalApps = function ExternalApps(_ref) {
    var AppsIconComponent = _ref.AppsIconComponent,
        apps = _ref.apps,
        initialOpen = _ref.initialOpen,
        generateLinkProps = _ref.generateLinkProps;

    var _useState = (0, _react.useState)(initialOpen),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var ref = (0, _react.useRef)(null);
    var toggle = function toggle() {
        return setIsOpen(function (prev) {
            return !prev;
        });
    };
    var memoizedClose = (0, _memoizeOne2.default)(function () {
        return setIsOpen(false);
    });
    return _react2.default.createElement(
        'div',
        {
            className: styles['container'],
            onClick: toggle,
            role: 'button',
            tabIndex: 0,
            ref: ref
        },
        _react2.default.createElement(AppsIconComponent, null),
        isOpen && _react2.default.createElement(
            'div',
            { className: styles['body'] },
            apps.map(function (_ref2) {
                var initial = _ref2.initial,
                    label = _ref2.label,
                    href = _ref2.href,
                    color = _ref2.color;
                return _react2.default.createElement(
                    'a',
                    Object.assign({
                        key: href,
                        className: styles['app'],
                        href: href,
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    }, generateLinkProps(label)),
                    _react2.default.createElement(
                        'div',
                        {
                            className: styles['initial'],
                            style: { background: color }
                        },
                        initial || label[0]
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: styles['label'] },
                        label
                    )
                );
            }),
            _react2.default.createElement(_BackgroundOverlay2.default, {
                onOutsideClick: memoizedClose,
                foregroundElementRef: ref
            })
        )
    );
};

ExternalApps.propTypes = {
    apps: _propTypes2.default.array,
    AppsIconComponent: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.elementType]),
    initialOpen: _propTypes2.default.bool,
    generateLinkProps: _propTypes2.default.func
};

ExternalApps.defaultProps = {
    initialOpen: false,
    AppsIconComponent: _Icons.AppsIcon,
    generateLinkProps: function generateLinkProps() {
        return {};
    }
};

exports.default = ExternalApps;