import React from 'react';
import { DropdownField } from '../../../campaigns/components/Form';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { CRM_FILE_TYPE } from '../../config';
import CrmFileFirstPartyCountriesDropdownTooltip from './CrmFileFirstPartyCountriesDropdownTooltip';

const audienceCountryList = {
    [CRM_FILE_TYPE.EMAIL]: [
        { label: 'United States', value: 'US' },
        { label: 'Canada', value: 'CA' },
        { label: 'United Kingdom', value: 'UK' },
        { label: 'India', value: 'IN' },
        { label: 'Brazil', value: 'BR' },
        { label: 'France', value: 'FR' },
        { label: 'Australia', value: 'AU' },
        { label: 'Israel', value: 'IL' },
        { label: 'Greece', value: 'GR' },
        { label: 'New Zealand', value: 'NZ' },
        { label: 'Germany', value: 'DE' },
        { label: 'Japan', value: 'JP' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Spain', value: 'ES' },
        { label: 'Italy', value: 'IT' },
        { label: 'Turkey', value: 'TR' },
        { label: 'Argentina', value: 'AR' },
        { label: 'Colombia', value: 'CO' },
        { label: 'South Africa', value: 'ZA' },
        { label: 'Portugal', value: 'PT' },
        { label: 'Switzerland', value: 'CH' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Uruguay', value: 'UY' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Panama', value: 'PA' },
        { label: 'Azerbaijan', value: 'AZ' },
    ],
    [CRM_FILE_TYPE.DEVICE_ID]: [
        { label: 'United States', value: 'US' },
        { label: 'Canada', value: 'CA' },
        { label: 'United Kingdom', value: 'UK' },
        { label: 'India', value: 'IN' },
        { label: 'Brazil', value: 'BR' },
        { label: 'France', value: 'FR' },
        { label: 'Australia', value: 'AU' },
        { label: 'Israel', value: 'IL' },
        { label: 'Greece', value: 'GR' },
        { label: 'Germany', value: 'DE' },
        { label: 'Japan', value: 'JP' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Spain', value: 'ES' },
        { label: 'Italy', value: 'IT' },
        { label: 'Turkey', value: 'TR' },
        { label: 'Argentina', value: 'AR' },
        { label: 'Colombia', value: 'CO' },
        { label: 'South Korea', value: 'KO' },
        { label: 'Poland', value: 'PL' },
        { label: 'Thailand', value: 'TH' },
        { label: 'Indonesia', value: 'ID' },
        { label: 'Vietnam', value: 'VN' },
        { label: 'Egypt', value: 'EG' },
    ],
};

export const CrmFileFirstPartyCountriesDropdown = ({ countryCode, setCountryCode }) => {
    const { value: crmFileType } = useFormFieldValue({ field: 'crmFileType' });

    return (
        <>
            <DropdownField
                label={<FormattedMessage id="audience.editor.select.audience.country.title" defaultMessage="Country" />}
                helpText={<CrmFileFirstPartyCountriesDropdownTooltip />}
                required={true}
                id="CrmFileFirstPartyCountries"
                placeholder={
                    <FormattedMessage
                        id="audience.editor.select.audience.placeholder"
                        defaultMessage="Click to Select"
                    />
                }
                selectedValueObject={countryCode}
                options={audienceCountryList[crmFileType]}
                onChange={setCountryCode}
                searchable={true}
            />
        </>
    );
};

export default CrmFileFirstPartyCountriesDropdown;
