import React, { Fragment, useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import { flatten, groupBy, throttle } from 'lodash';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { RBOX_LOGGING_LEVELS } from 'modules/taboola-common-frontend-modules/rbox-loader';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import config from '../../config';
import { hasInvalidItems } from '../../services';
import { LoadingIndicator } from '../LoadingIndicator';
import { CreativePreviewNote } from '../Note';
import { CreativePreviewPlacements } from '../Placements';
import { useCreativePreviewProcessedItems } from './hooks/useCreativePreviewProcessedItems';
import { useCreativePreviewQueryParams } from './hooks/useCreativePreviewQueryParams';
import { useRboxLoggingLevel } from './useRboxLoggingLevel';
import styles from './creativePreviewBody.module.scss';

const classNameBuilder = classnames.bind(styles);

const pushBlanks = (array, url, num) => {
    for (let i = 0; i < num; i++) {
        array.push({
            title: 'blank title',
            itemType: 'is-syndicated',
            'additional-properties': {
                hideMe: true,
                placementUrl: url,
            },
        });
    }
};

const addBlankItems = (processedItems, urls, placements) => {
    const [placement] = placements;
    const { width } = placement;

    const itemsByUrl = groupBy(processedItems, item => item['additional-properties'].placementUrl);
    urls.forEach(url => {
        const urlItems = itemsByUrl[url];
        if (urlItems) {
            const numItems = urlItems.length;
            const numBlanksToAdd = numItems % width === 0 ? 0 : width - (numItems % width);

            pushBlanks(urlItems, url, numBlanksToAdd);
        }
    });

    return flatten(Object.values(itemsByUrl));
};

const CreativePreviewBody = ({
    className,
    items,
    urls,
    loadingIndicator,
    placements,
    isItemRepeatEnabled,
    shouldRenderNote = true,
}) => {
    const [, setRboxLoggingLevelParam] = useRboxLoggingLevel();
    const { creativePreviewParams, setCreativePreviewParams } = useCreativePreviewQueryParams();
    const isCreativeCreator = urls && urls.length > 0;
    const isSupportAppInstallMultiSlots = useConfigMatch({
        [FEATURE_FLAGS.SUPPORT_APP_INSTALL_MULTI_SLOTS_WIDGETS]: 'true',
    });
    const { data } = useFormDataContext();
    const { campaigns, selectedCampaigns } = data;
    const selectedCampaign = useMemo(() => {
        const campaignsList = campaigns || selectedCampaigns || [];
        return [campaignsList[0]];
    }, [campaigns, selectedCampaigns]);
    const filteredItems = items.filter(item => !item.deleted);
    const processedItems = useCreativePreviewProcessedItems(filteredItems, selectedCampaign);
    const finalItems =
        isCreativeCreator && processedItems.length ? addBlankItems(processedItems, urls, placements) : processedItems;
    const isLoading = hasInvalidItems(items) || !creativePreviewParams.length || items.length > config.numMaxItems;
    const placementKey = JSON.stringify(creativePreviewParams);
    const shouldRepeatPreviewItems = !isItemRepeatEnabled && !isCreativeCreator;
    const isAppInstallItem = finalItems.find(item => item['additional-properties']['is-app-install']);
    const shouldPropertiesOverride = !!isAppInstallItem && !isSupportAppInstallMultiSlots;
    const throttledSetCreativePreviewParams = useMemo(
        () => throttle(setCreativePreviewParams, 500, { trailling: false, leading: false }),
        [setCreativePreviewParams]
    );

    // sets rbox debug level to hide logging in console
    useEffect(() => {
        setRboxLoggingLevelParam(RBOX_LOGGING_LEVELS.HIDE);
    }, [setRboxLoggingLevelParam]);

    // set query params when the final items change
    useEffect(() => {
        throttledSetCreativePreviewParams.cancel();
        throttledSetCreativePreviewParams({
            creativePreviewParams: finalItems,
            shouldRepeatPreviewItems,
            shouldPropertiesOverride,
        });
    }, [
        shouldRepeatPreviewItems,
        finalItems,
        throttledSetCreativePreviewParams,
        setCreativePreviewParams,
        shouldPropertiesOverride,
    ]);

    return (
        <div className={classNameBuilder('preview-feed-container', className)}>
            {isLoading ? (
                loadingIndicator
            ) : (
                <Fragment>
                    <CreativePreviewPlacements placements={placements} placementKey={placementKey} />
                    {shouldRenderNote && (
                        <CreativePreviewNote isCreativeCreator={isCreativeCreator} items={finalItems} />
                    )}
                </Fragment>
            )}
        </div>
    );
};

CreativePreviewBody.propTypes = {
    className: PropTypes.string,
    intl: PropTypes.object,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            cta: PropTypes.shape({
                ctaType: PropTypes.string,
            }),
            thumbnail: PropTypes.string,
            creativeId: PropTypes.string,
            brandingText: PropTypes.string,
            creativeFocus: PropTypes.shape({
                type: PropTypes.string,
                coordinates: PropTypes.shape({
                    x: PropTypes.number,
                    y: PropTypes.number,
                }),
            }),
            language: PropTypes.string,
        })
    ),
    urls: PropTypes.arrayOf(PropTypes.string),
    loadingIndicator: PropTypes.node,
    placements: PropTypes.array,
};

CreativePreviewBody.defaultProps = {
    loadingIndicator: <LoadingIndicator />,
    placements: config.feedPlacements,
};

export { CreativePreviewBody };
export default CreativePreviewBody;
