import { useQuery } from 'react-query';
import { useResourcesApi } from 'services/api/resourcesApi';

export const useFetchTimeZones = () => {
    const resourcesApi = useResourcesApi();
    const { data, isSuccess } = useQuery('time-zones-list', () => resourcesApi.getTimeZones(), {
        cacheTime: 14400000,
        refetchOnWindowFocus: false,
    });

    return { data, isSuccess };
};
