'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.IconCheckbox = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _BaseCheckbox = require('../BaseCheckbox');

var _BaseCheckbox2 = _interopRequireDefault(_BaseCheckbox);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'iconCheckbox__taboola-svg-icon___och2M',
    'label': 'iconCheckbox__label___ufHv9',
    'title': 'iconCheckbox__title___2bGBI',
    'checked-label': 'iconCheckbox__checked-label___8P1vH',
    'error': 'iconCheckbox__error___1R0CD',
    'input': 'iconCheckbox__input___3yHT9',
    'icon-container': 'iconCheckbox__icon-container___F4Ou5'
};


var classNameBuilder = _bind2.default.bind(styles);

var IconCheckbox = function IconCheckbox(_ref) {
    var title = _ref.title,
        labelClassName = _ref.labelClassName,
        checkedLabelClassName = _ref.checkedLabelClassName,
        iconComponent = _ref.iconComponent,
        rest = _objectWithoutProperties(_ref, ['title', 'labelClassName', 'checkedLabelClassName', 'iconComponent']);

    var labelMergedClassName = classNameBuilder('label', labelClassName);
    var checkedLabelMergedClassName = classNameBuilder('checked-label', checkedLabelClassName);
    return _react2.default.createElement(
        _BaseCheckbox2.default,
        Object.assign({
            labelClassName: labelMergedClassName,
            inputClassName: styles['input'],
            checkedLabelClassName: checkedLabelMergedClassName
        }, rest),
        _react2.default.createElement(
            'div',
            { className: styles['icon-container'] },
            iconComponent,
            _react2.default.createElement(
                'span',
                { className: styles['title'] },
                title
            )
        )
    );
};

exports.IconCheckbox = IconCheckbox;
IconCheckbox.propTypes = Object.assign({
    /** Radio title */
    title: _propTypes2.default.node.isRequired,
    /** Icon component */
    iconComponent: _propTypes2.default.node.isRequired,
    /** Apply class to style the checkbox label */
    labelClassName: _propTypes2.default.string,
    /** Apply class to style the checkbox label when checked */
    checkedLabelClassName: _propTypes2.default.string
}, _BaseCheckbox2.default.propTypes);

exports.default = IconCheckbox;