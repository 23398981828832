import { FORM_MODES } from 'config/formModes';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { ACCOUNT_PARAM_TEMPLATE } from './account';
import { AUDIENCE_ID_PARAM_TEMPLATE } from './audience';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${AUDIENCE_ID_PARAM_TEMPLATE}`;

const emptyParams = { accountId: [] };

export const PIXEL_BASED_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.PIXEL_BASED],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const PIXEL_BASED_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.PIXEL_BASED],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const FIRST_PARTY_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.FIRST_PARTY],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);
export const FIRST_PARTY_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.FIRST_PARTY],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const PREDICTIVE_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.PREDICTIVE],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);
export const PREDICTIVE_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.PREDICTIVE],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const CUSTOM_CONTEXTUAL_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CUSTOM_CONTEXTUAL],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);
export const CUSTOM_CONTEXTUAL_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CUSTOM_CONTEXTUAL],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const COMBINED_AUDIENCE_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.COMBINED_AUDIENCES],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const COMBINED_AUDIENCE_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.COMBINED_AUDIENCES],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const MAIL_DOMAIN_AUDIENCES_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.MAIL_DOMAINS],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const MAIL_DOMAIN_AUDIENCE_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.MAIL_DOMAINS],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const SEARCH_KEYWORDS_AUDIENCES_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.SEARCH_KEYWORDS],
        mode: [FORM_MODES.CREATE],
        ...emptyParams,
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const SEARCH_KEYWORDS_AUDIENCE_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.SEARCH_KEYWORDS],
        mode: [FORM_MODES.EDIT, FORM_MODES.DUPLICATE, FORM_MODES.PREVIEW],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

const MAX_DEPTH_OF_URL = EDITOR_ROUTE_PATH.split('/').length;
export const WILDCARD_PATH = '/*'.repeat(MAX_DEPTH_OF_URL);
