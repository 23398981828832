import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Collapsible } from 'taboola-ultimate-ui';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { getCampaignsGroupStatusMessages } from '../../../../services/utils';
import { Id, Status } from '../../../common-campaign-form';
import HeaderLoading from '../../../common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import CampaignsGroupNameEdit from '../Name/CampaignsGroupNameEdit/CampaignsGroupNameEdit';
import styles from './campaignsGroupEditorHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const CampaignsGroupEditorHeader = ({ status, headerClassName, collapsed }) => {
    const { value: isActive = false, onChange } = useFormFieldValue({ field: 'isActive' });
    const { value: displayStatus } = useFormFieldValue({ field: 'displayStatus' });
    const { value: id = EMPTY_FIELD_VALUE } = useFormFieldValue({ field: 'campaignsGroupId' });
    const { formattedStatusMsg } = getCampaignsGroupStatusMessages(displayStatus);

    return status === COMPONENT_STATUS.LOADING ? (
        <HeaderLoading />
    ) : (
        <div className={classNameBuilder('section-container', headerClassName)}>
            <CampaignsGroupNameEdit />
            <Collapsible collapsed={collapsed}>
                <div className={classNameBuilder('campaigns-group-status-container')}>
                    <Id id={id} className={styles['campaigns-group-id']} />
                    <Status formattedStatusMsg={formattedStatusMsg} isActive={isActive} onChange={onChange} />
                </div>
            </Collapsible>
        </div>
    );
};

CampaignsGroupEditorHeader.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
    collapsed: PropTypes.bool,
};

CampaignsGroupEditorHeader.defaultProps = {
    collapsed: false,
};

export default CampaignsGroupEditorHeader;
