/* eslint-disable no-template-curly-in-string */
import FALLBACK_POLICY from '../fallbackPolicy';
import generateDefaultFlagFromTemplate from '../utils/generateDefaultFlagFromTemplate';

const FEATURE_FLAGS = {
    IS_CAMPAIGN_COMMENT_SECTION_ENABLED: 'is-campaign-comment-section-enabled',
    ONBOARDING_ASSISTANT_ENABLED: 'taboola-ads:onboarding-assistant-enabled',
    SHOULD_REPORT_LOAD_TIME: 'use-i2i-links',
    HAS_OLD_PIXEL: 'has-old-pixel',
    HAS_SWITCHED_TO_TABOOLA_PIXEL: 'has-switched-to-taboola-pixel',
    HAS_CAMPAIGNS: 'has-campaigns',
    HAS_NATIVE_CAMPAIGNS: 'has-native-campaigns',
    HAS_CAMPAIGNS_VIDEO: 'has-campaigns-video',
    CAMPAIGNS_BULK_UPDATE_API_ENABLED_KEY: 'campaigns-bulk-update-api-enabled',
    ONLY_PERFORMANCE_REPORTS: 'backstage:reports:only-performance-reports',
    GOOGLE_DRIVE_EXPORT_ENABLED: 'taboola-ads:reports:google-drive-export-enabled',
    SHOW_CATEGORY_REPORT: 'taboola-ads:reports:categories-reports:is-visible',
    SHOW_CATEGORY_REPORT_MISSING_DATA_INDICATION:
        'taboola-ads:reports:categories-reports:missing-data-indication-is-visible',
    CAN_EDIT_CAMPAIGN_USER_SEGMENTS: 'can-edit-campaign-user-segments',
    ON_SITE_FEED_PREVIEW: 'taboola-ads:creative-preview:on-site-feed-preview',
    CREATIVE_CREATOR_BULK_CREATE_VARIATIONS_ENABLED: 'taboola-ads:creative-creator:bulk-create-variations-enabled',
    MEDIA_LIBRARY_VIDEO_DRAWERS: 'taboola-ads:media-library:video:drawers',
    MEDIA_LIBRARY_IMAGE_DRAWERS: 'taboola-ads:media-library:image:drawers',
    AI_IMAGE_GENERATION_TAB_ENABLED: 'taboola-ads:ai-image-generation-tab-enabled',
    AI_TITLE_SUGGESTIONS_ENABLED: 'taboola-ads:ai-title-suggestions-enabled',
    AI_DESCRIPTION_SUGGESTIONS_ENABLED: 'taboola-ads:ai-description-suggestions-enabled',
    AI_PROMPT_HELPER_ENABLED: 'taboola-ads:ai-prompt-helper-enabled',
    CREATIVES_BULK_ACTIONS_ENABLED: 'taboola-ads:campaigns-reports:creative:is-bulk-actions-enabled',
    CREATIVE_FOCUS_APPLY_TO_ALL: 'taboola-ads:creative-editor:creative-focus-apply-to-all',
    CREATIVE_CREATOR_FOCAL_POINT_ENABLED: 'taboola-ads:creative-creator:creative-focal-point',
    CREATIVE_EDIT_VIDEO_DUPLICATE_ENABLED: 'taboola-ads:creative-editor:creative-edit-video-duplicate-enabled',
    CREATIVE_DUPLICATE_ENABLED: 'taboola-ads:campaigns-reports:creative:is-duplicate-enabled',
    REPORT_AUTO_FILTERS: 'taboola-ads:campaigns-reports:auto-filters',
    CREATIVE_FORM_DESCRIPTION_ENABLED: 'taboola-ads:creative-form:description-enabled',
    CREATIVE_FORM_CTA_ENABLED: 'taboola-ads:creative-form:cta-enabled',
    BACKSTAGE_ADVERTISER_ENABLE_CTA: 'backstage:advertiser-enable-cta',
    ADS_REPORT_VIDEO_ON_HOVER_ENABLED: 'taboola-ads:ads-reports-video-on-hover-enabled',
    CREATIVE_ONE_BY_ONE_CREATION_ENABLED: 'taboola-ads:creative-creator:one-by-one-creation-enabled',
    ACTION_CONVERSION_VIEWS_LOOKBACK_WINDOW: 'vta:action-conversion-report:include-views-lookback-window',
    ACTION_CONVERSION_CLICK_LOOKBACK_WINDOW: 'action-conversion-report:include-last-click-lookback-window',
    ACTION_CONVERSION_VIEWS_START_DATE: 'vta:action-conversion-report:include-views-start-date',
    ACTION_CONVERSION_LAST_CLICK_START_DATE: 'unip-action-conversion-report:include-last-click-start-date',
    IS_DUPLICATE_CAMPAIGN_SETTINGS_ENABLED: 'taboola-ads:campaigns:is-campaign-duplicate-settings-enabled',
    FEEDBACK_FORM_IS_VISIBLE: 'contact-form-is-enabled',
    CONTACT_SUPPORT_CAN_BE_VISIBLE: 'contact-support-form-can-be-visible',
    AUDIENCE_BULK_ACTION_ENABLED: 'taboola-ads:campaigns-reports:audience:is-bulk-actions-duplicate-enabled',
    USE_COUNTRIES_QUERY: 'taboola-ads:countries-query-enabled',
    IS_BUDGET_LIMIT_RATIO_CONFIG: 'backstage:campaign:enable-spending-limit-multiple-daily',
    BUDGET_LIMIT_RATIO_CONFIG: 'backstage:campaign:enable-spending-limit-multiple-daily-value',
    IS_CREATIVES_AB_TEST_ENABLED: 'backstage:creatives-ab-test:is-enabled',
    CREATIVES_AB_TEST_MIN_END_DATE_DAYS: 'backstage:creatives-ab-test:end-date:min-days-in-test',
    CREATIVES_AB_TEST_MAX_END_DATE_DAYS: 'backstage:creatives-ab-test:end-date:max-days-in-test',
    CREATIVES_AB_TEST_DEFAULT_END_DATE_DAYS: 'backstage:creatives-ab-test:end-date:default-days-in-test',
    LT_MAX_CPC: 'backstage:low-touch-publisher-max-cpc',
    IS_SPECIFIC_LT_MAX_CPC: 'backstage:allow-specific-validation-for-low-touch-publisher-max-cpc',
    IS_MIN_DAY_PARTING_WINDOW: 'backstage:day-parting:minimum-running-window-enable',
    MIN_DAY_PARTING_WINDOW_HRS: 'backstage:day-parting:minimum-running-window-value',
    HEAVY_ACCOUNT: 'backstage:is-heavy-account',
    HEAVY_CREATIVES_REPORT: 'backstage:is-heavy-ads',
    HIDE_HEAVY_ACCOUNT_CREATIVES_REPORT: 'taboola-ads:hide-heavy-account-creatives-reports',
    HIDE_HEAVY_ACCOUNT_CAMPAIGNS_REPORT: 'taboola-ads:hide-heavy-account-campaigns-reports',
    CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE: 'taboola-ads:campaign-report-top-result-filter-active',
    SITE_REPORT_TOP_RESULTS_FILTER_ACTIVE: 'taboola-ads:site-report-top-result-filter-active',
    CREATIVE_REPORT_TOP_RESULTS_FILTER_ACTIVE: 'taboola-ads:ads-report-top-result-filter-active',
    ENABLE_EXTERNAL_APPS_PERMISSIONS: 'taboola-ads:external-apps-permissions:enable',
    CPC_MIN_VALUE: 'currency:${accountCurrency}:cpc:min-value',
    CPC_MAX_VALUE: 'currency:${accountCurrency}:cpc:max-value',
    VIDEO_RATE_MAX_VALUE: 'currency:${accountCurrency}:video-rate:max-value',
    VIDEO_RATE_MIN_VALUE_CPCV: 'currency:${accountCurrency}:video-rate:min-value-cpcv',
    VIDEO_RATE_MIN_VALUE_CPM: 'currency:${accountCurrency}:video-rate:min-value-cpm',
    VIDEO_RATE_MIN_VALUE_VCPM: 'currency:${accountCurrency}:video-rate:min-value-vcpm',
    CPC_MIN_WARNING: 'currency:${accountCurrency}:cpc:min-warn',
    CPC_MAX_WARNING: 'currency:${accountCurrency}:cpc:max-warn',
    ACCOUNT_CPM_MIN_VALUE: 'currency:${accountCurrency}:cpm:min-value',
    ACCOUNT_CPM_MAX_VALUE: 'currency:${accountCurrency}:cpm:max-value',
    ACCOUNT_CPM_MIN_WARN: 'currency:${accountCurrency}:cpm:min-warn',
    ACCOUNT_CPM_MAX_WARN: 'currency:${accountCurrency}:cpm:max-warn',
    ACCOUNT_VCPM_MIN_VALUE: 'currency:${accountCurrency}:vcpm:min-value',
    ACCOUNT_VCPM_MAX_VALUE: 'currency:${accountCurrency}:vcpm:max-value',
    ACCOUNT_VCPM_MIN_WARN: 'currency:${accountCurrency}:vcpm:min-warn',
    ACCOUNT_VCPM_MAX_WARN: 'currency:${accountCurrency}:vcpm:max-warn',
    ACCOUNT_CPC_DATA_MIN_VALUE: 'currency:${accountCurrency}:cpc:data-min-value',
    ACCOUNT_CPC_DATA_MAX_VALUE: 'currency:${accountCurrency}:cpc:data-max-value',
    ACCOUNT_CPM_DATA_MIN_VALUE: 'currency:${accountCurrency}:cpm:data-min-value',
    ACCOUNT_CPM_DATA_MAX_VALUE: 'currency:${accountCurrency}:cpm:data-max-value',
    ACCOUNT_VCPM_DATA_MIN_VALUE: 'currency:${accountCurrency}:vcpm:data-min-value',
    ACCOUNT_VCPM_DATA_MAX_VALUE: 'currency:${accountCurrency}:vcpm:data-max-value',
    ACCOUNT_BUDGET_LIMITATION_THRESHOLD: 'currency:${accountCurrency}:budgetLimitations:budget-limitation-threshold',
    LIFETIME_RANGE_PICKER_PRESET_ENABLED: 'taboola-ads:dashboard-range-picker:dynamic-presets:lifetime-enabled',
    PIXEL_PREDICTIVE_ENABLED: 'taboola-ads:pixel-based-predictive:enabled',
    ENABLE_GROUP_LEADS_GENERATION: 'campaigns-group:leads-generation:enable',
    ENABLE_GROUP_ONLINE_PURCHASES: 'campaigns-group:online-purchases:enable',
    ENABLE_GROUP_DRIVE_WEBSITE_TRAFFIC: 'campaigns-group:drive-website-traffic:enable',
    ENABLE_GROUP_BRAND_AWARENESS: 'campaigns-group:brand-awareness:enable',
    ENABLE_GROUP_MOBILE_APP_INSTALL: 'campaigns-group:mobile-app-install:enable',
    TAG_TYPE_IAS_ENABLED: 'taboola-ads:campaign-3rd-party-tags-ias:enabled',
    TAG_TYPE_MOAT_ENABLED: 'taboola-ads:campaign-3rd-party-tags-moat:enabled',
    TAG_TYPE_ADLOOX_ENABLED: 'taboola-ads:campaign-3rd-party-tags-adloox:enabled',
    TAG_TYPE_DV_ENABLED: 'taboola-ads:campaign-3rd-party-tags-dv:enabled',
    TAG_TYPE_GOOGLE_DCM_ENABLED: 'taboola-ads:campaign-3rd-party-tags-google-dcm:enabled',
    TAG_TYPE_IMAGE_PIXEL_ENABLED: 'taboola-ads:campaign-3rd-party-tags-image-pixel:enabled',
    PROGRAMMATIC_BUDGET_ENABLED: 'taboola-ads:campaign-form:programmatic-budget-enabled',
    SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER: 'taboola-ads:campaign-report:should-display-legacy-category-filter',
    SHOULD_DISPLAY_IAB_CATEGORY_FILTER: 'taboola-ads:campaign-report:should-display-iab-category-filter',
    ENABLE_DEAL_ID: 'backstage:enable-deal-id-field',
    IMPRESSION_TYPE_FIELD: 'backstage:impression-type-field',
    CREATE_LOOKALIKE_ALLOWED: 'taboola-ads:create-lookalike-allowed',
    CREATE_LOOKALIKE_ALLOWED_NEW: 'taboola-ads:create-lookalike-allowed-new',
    ADS_CONSOLE_CUSTOM_CONTEXTUAL_ALLOWED: 'taboola-ads:custom-contextual-allowed',
    ADS_CONSOLE_COMBINED_AUDIENCES_ALLOWED: 'taboola-ads:combined-audiences-allowed',
    ADS_CONSOLE_YAHOO_AUDIENCES_ALLOWED: 'taboola-ads:yahoo-audiences-allowed',
    ADS_CONSOLE_YAHOO_AUDIENCES_KEYWORD_SUGGESTIONS_ALLOWED: 'taboola-ads:yahoo-audiences-keyword-suggestions-allowed',
    ADS_CONSOLE_YAHOO_AUDIENCES_DOMAIN_SUGGESTIONS_ALLOWED: 'taboola-ads:yahoo-audiences-domain-suggestions-allowed',
    ADS_CONSOLE_REACH_ESTIMATOR_ALLOWED: 'taboola-ads:reach-estimator-allowed',
    ADS_CONSOLE_REACH_ESTIMATOR_BID_ALLOWED: 'reach-estimator:is-bid-allowed-for-publisher',
    ADS_CONSOLE_CUSTOM_CONTEXTUAL_ARTICLES_PREVIEW_ALLOWED: 'taboola-ads:custom-contextual-articles-preview-allowed',
    CREATE_CUSTOMER_FILE_ALLOWED: 'taboola-ads:create-customer-file-allowed',
    TARGET_CPA_ENABLED: 'backstage:target-cpa:is-enabled',
    TARGET_CPA_NO_CPC_ENABLED: 'backstage:target-cpa-no-cpc:is-enabled',
    TARGET_CPA_DUPLICATE_CAMPAIGN_ENABLED: 'backstage:target-cpa-duplicate-campaign:is-enabled',
    TARGET_ROAS_ENABLED: 'taboola-ads:target-roas:is-enabled',
    MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER: 'max-conversions:should-use-max-cpc',
    TARGET_CPA_ELIGIBLE_ACCOUNTS: 'target-cpa:target-cpa-eligible-accounts',
    SUPPLY_EXPLORATION_ENABLED: 'supply-exploration:is-enabled',
    SAFETY_NETS_ENABLED_CONFIG_ATTRIBUTE: 'safety-nets:enabled',
    FREQUENCY_CAPPING_ENABLED_CONFIG_ATTRIBUTE: 'ads-console:frequency-capping:enabled',
    CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_TARGET_CPA:
        'campaign-level-conversions:bid-strategy:allow-target-cpa',
    CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_MAX_CONVERSIONS:
        'campaign-level-conversions:bid-strategy:allow-max-conversions',
    ENABLE_CREATIVE_TRACKING: 'taboola-ads:creative-form:tracking',
    HYBRID_MODULE_QUERY_PARAMS_ENABLED: 'taboola-ads:hybrid-module-query-params-enabled',
    HYBRID_MODULE_HEADER_PARAMS_ENABLED: 'taboola-ads:hybrid-module-header-params-enabled',
    IS_DCO_ACCOUNT: 'has-dco-ecomm-columns',
    BILLING_AND_PAYMENTS_MODULE_ENABLED: 'taboola-ads:billing-and-payments-module-enabled',
    SHOULD_HIDE_CURRENT_BALANCE: 'taboola-ads:should-hide-current-balance',
    CREATIVE_RESUBMISSION_ENABLED: 'resubmit-creatives:is-enabled',
    BLOCK_INSIGNICANT_CREATIVE_CHANGES_ENABLED: 'block-insignificant-creative-changes-enabled',
    MIN_CONVERSIONS_GOAL_ENABLED: 'backstage:target-cpa:min-conversion-goal:is-enabled',
    NOTIFICATIONS_CENTER_ENABLED: 'taboola-ads:notifications-center-enabled',
    NOTIFICATIONS_CENTER_ONLY_FOR_LOW_TOUCH: 'taboola-ads:notifications-center:enabled-for-low-touch-only',
    SHOW_OUTDATED_API_NOTICE: 'taboola-ads:show-outdated-api-notice',
    MY_AUDIENCE_DROPDOWN_IS_DISABLEABLE: 'taboola-ads:my-audience-dropdown:is-disableable',
    WOCHIT_CREATE_VIDEO_ENABLED: 'taboola-ads:wochit-create-video-enabled',
    DEFAULT_CTA_ENABLED: 'taboola-ads:default_cta_enabled',
    SHOW_CAMPAIGN_HISTORY_REPORT: 'taboola-ads:show-campaign-history-report',
    SHOW_CAMPAIGN_HISTORY_REPORT_GRAPH: 'taboola-ads:show-campaign-history-report-graph',
    SHOW_COMPARE_CAMPAIGNS_GRAPH: 'taboola-ads:show-compare-campaigns-graph',
    SHOW_COMPARE_CREATIVES_GRAPH: 'taboola-ads:show-compare-creatives-graph',
    ENABLE_RIBBON_RECOMMENDATION: 'performance:recommendation:enable-ribbon-recommendation',
    SUBMISSION_DATA_ALLOWED_IN_ADS_REPORT: 'submission-data:allowed-in-creatives-report',
    AUDIENCE_TARGETING_FIRST_CAMPAIGN_MESSAGE_ENABLED: 'taboola-ads:audience-targeting-first-campaign-message-enabled',
    IS_WITH_TAX_REGISTRATION: 'taboola-ads:is-with-tax-registration',
    IS_WITH_VAT_ID: 'taboola-ads:is-with-vat-id',
    CAMPAIGN_LEVEL_CONVERSIONS: 'campaign-level-conversions:is-enabled-ta',
    TA_REALTIME: 'taboola-ads:is-realtime-enabled',
    AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED: 'taboola-ads:auto-reviewer-content-validation-is-enabled',
    AUTO_REVIEWER_CROPPED_IMAGE_SAFETY_VALIDATIONS_ENABLED:
        'taboola-ads:auto-reviewer-cropped-image-safety-validation-is-enabled',
    IS_SCHEDULE_TRACKING_EXPERT_ENABLED_TA: 'taboola-ads:tracking:is-schedule-tracking-expert-enabled',
    IS_PIXEL_CODE_ENABLED: 'backstage:send-pixel-code-is-enabled',
    TRACKING_SCREENS: 'taboola-ads:tracking-screens',
    GTM_AUTOMATED_SETUP_ENABLED: 'taboola-ads:tracking:gtm-automated-setup-enabled',
    CONVERSION_AGGREGATED_TYPE_ENABLED: 'taboola-ads:tracking:aggregated-type-enabled',
    CONVERSION_FUNNEL_ENABLED: 'taboola-ads:tracking:conversion-funnel-enabled',
    CONVERSION_DEFAULT_FUNNEL_ENABLED: 'taboola-ads:tracking:conversion-default-funnel-enabled',
    CAMPAIGN_PERFORMANCE_RULES_ENABLED: 'taboola-ads:campaign-performance-rules-enabled',
    PERFORMANCE_RULES_ENABLED: 'taboola-ads:performance-rules-enabled',
    PERFORMANCE_RULES_EXECUTED_ACTIONS_ENABLED: 'taboola-ads:performance-rules-executed-actions-enabled',
    EDIT_PERFORMANCE_RULES_ENABLED: 'taboola-ads:edit-performance-rules-enabled',
    DUPLICATE_PERFORMANCE_RULES_ENABLED: 'taboola-ads:duplicate-performance-rules-enabled',
    PERFORMANCE_RULES_PREVIEW_ENABLED: 'taboola-ads:performance-rules-preview-enabled',
    PERFORMANCE_RULES_REPORTS_ENABLED: 'performance-rules-reports-enabled',
    PERFORMANCE_RULES_LONG_LOOK_BACK_WINDOW_ENABLED: 'taboola-ads:performance-rules-long-look-back-window-enabled',
    PERFORMANCE_RULES_UNDO_ACTIONS_ENABLED: 'taboola-ads:performance-rules-undo-actions-enabled',
    PERFORMANCE_RULES_ENABLE_EMAIL_NOTIFICATIONS: 'taboola-ads:performance-rules-enable-email-notifications',
    CVR_LEARNING_STATUS_ENABLED: 'taboola-ads:cvr-learning-status:enabled',
    DCO_ACCOUNT_HAS_IN_TRIAL_CAMPAIGNS: 'taboola-ads:dco-publisher-has-in-trial-campaigns',
    CROPPING_IMAGE_ENABLED: 'taboola-ads:cropping-image-enable',
    OUTCROPPING_IMAGE_ENABLED: 'taboola-ads:outcropping-image-enabled',
    RANGE_OF_RATIO_OUTCROPPING_ENABLED: 'taboola-ads:range-of-ratio-outcropping-enabled',
    CREATIVE_REPORT_USE_WIDE_CONFIG_MSV2: 'taboola-ads:use-wide-performance-table-in-creatives-report',
    CREATIVE_VIDEO_NEW_FORM_ACCOUNT: 'backstage:video:new-creative-form',
    MARKETPLACE_AUDIENCES_BUNDLING_ENABLED: 'taboola-ads:marketplace-audiences-bundling-enable',
    MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED: 'backstage:marketplace-audiences-multiple-targetings:enabled',
    MARKETPLACE_AUDIENCES_AGE_GROUP_IDS_LIST: 'backstage:marketplace-audiences:age-segments-ids:list',
    MARKETPLACE_AUDIENCES_GENDER_GROUP_IDS_LIST: 'backstage:marketplace-audiences:gender-segments-ids:list',
    MARKETPLACE_AUDIENCES_AGE_GROUP_TAXONOMY_PARENTS_LIST:
        'backstage:marketplace-audiences:age-segments-parents-ids:list',
    MARKETPLACE_AUDIENCES_GENDER_GROUP_TAXONOMY_PARENTS_LIST:
        'backstage:marketplace-audiences:gender-segments-parents-ids:list',
    MARKETPLACE_AUDIENCES_AGE_SEGMENT_LIMIT: 'backstage:marketplace-audiences:age-segment:limit',
    MARKETPLACE_AUDIENCES_GENDER_SEGMENT_LIMIT: 'backstage:marketplace-audiences:gender-segment:limit',
    MARKETPLACE_AUDIENCES_MIXED_SEGMENT_LIMIT: 'backstage:marketplace-audiences:mixed-segment:limit',
    MARKETPLACE_AUDIENCES_ADVANCED_SEARCH_ENABLED: 'taboola-ads:marketplace-audiences-advanced-search-enabled',
    BID_STRATEGY_V2_ENABLED: 'taboola-ads:bid-strategy-v2:enabled',
    MAX_CONVERSIONS_ENABLED: 'backstage:max-conversions:is-enabled',
    CREATIVE_CREATION_AI_VARIATIONS_ENABLED: 'taboola-ads:creative-creation-ai-variations-enabled',
    CAMPAIGN_MULTIPLE_CONVERSIONS_ENABLED: 'campaign-level-conversions:multiple-unip-rules-enabled',
    HIDE_CONVERSIONS_GOAL_ACCOUNT_DEFAULT: 'taboola-ads:hide-conversion-goal-account-default',
    CAMPAIGN_GROUP_EXTENSION_ENABLED: 'backstage:campaign-group:is-skip-override-fields-for-campaign-group',
    SHARED_BUDGET_UA_ENABLED: 'taboola-ads:shared-budget:ui-enabled',
    DELETE_CAMPAIGN_GROUP_ENABLED: 'taboola-ads:is-delete-campaign-group-enabled',
    BID_DAY_TIME_ENABLED: 'taboola-ads:day-time-bid-enabled',
    TIME_ZONE_ENABLED: 'taboola-ads:time-zone-enabled',
    USER_TIME_ZONE_ENABLED: 'taboola-ads:user-time-zone-enabled',
    SCHEDULE_DAY_TIME_ENABLED: 'day-time-schedule-enabled',
    BID_PLATFORM_ENABLED: 'taboola-ads:bid-mods-platform-enabled',
    CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED: 'taboola-ads:campaign:campaign-conversion-configuration-enabled',
    MAX_EXCLUDE_COUNTRIES: 'taboola-ads:campaign-targeting:max-exclude-countries-allowed',
    AI_WIZARD_ONBOARDING: 'taboola-ads:ai-wizard-onboarding',
    CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2: 'taboola-ads:use-wide-performance-table-in-campaign-report',
    SITE_REPORT_USE_WIDE_CONFIG_MSV2: 'taboola-ads:use-wide-performance-table-in-site-report',
    SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2: 'taboola-ads:dont-use-wide-performance-table-in-site-report',
    FAVORITED_MEDIA_ENABLED: 'taboola-ads:favorited-media-enabled',
    SUPPLY_TARGETING_ENABLED: 'taboola-ads:supply-targeting-enabled',
    SUPPLY_TARGETING_USE_TABOOLA_VALUE_AS_DEFAULT: 'taboola-ads:supply-targeting-use-taboola-value-as-default',
    SUPPLY_TARGETING_PMP_ENABLED: 'taboola-ads:supply-targeting-pmp-enabled',
    LOW_TIER_DAILY_LIMIT_MAX_VALUE: 'backstage:low-tier:campaign:daily-limit',
    TA_PIXEL_WARNINGS_TESTING: 'taboola-ads:pixel-warnings-testing',
    PROFIT_MARGIN_ENABLED: 'taboola-ads:profit-margin-enable',
    SHOULD_DISPLAY_JS_FOR_ALL: 'taboola-ads:should-display-js-for-all',
    DISABLED_CLC_THRESHOLD_WARNING: 'taboola-ads:disabled-clc-threshold-warning',
    REPLACE_IMAGE_BACKGROUND: 'taboola-ads:replace-image-background-enabled',
    REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING:
        'taboola-ads:replace-image-background-segment-anything-foreground-detection-enabled',
    REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING_POPUP:
        'taboola-ads:replace-image-background-segment-anything-foreground-detection-popup-enabled',
    CREATIVE_STUDIO_GENERATE_VARIATIONS: 'taboola-ads:creative-studio:generate-variations-enabled',
    GEN_AI_EDIT_DUPLICATE_TOOLTIP_ENABLED: 'taboola-ads:creative-studio:edit-duplicate-tooltip-enabled',
    SUPPORT_APP_INSTALL_MULTI_SLOTS_WIDGETS: 'taboola-ads:support-app-install-multi-slots-widgets',
    SUPPORT_ADVANCED_PREVIEW: 'taboola-ads:support-advanced-preview',
    UPLOAD_IN_MEDIA_LIBRARY_ENABLED: 'taboola-ads:upload-in-media-library-enabled',
    AI_VARIATIONS_REPLACEMENT_ENABLED: 'taboola-ads:ai-variations-replacement-enabled',
    ACCOUNT_REPORT_ENABLED: 'taboola-ads:account-report-enabled',
    ONLINE_PURCHASES_MARKETING_OBJECTIVE_ENABLED: 'taboola-ads:online-purchases-marketing-objective-enabled',
    SHOW_CAMPAIGN_GROUP_PICKER_IN_IFRAME: 'taboola-ads:show-campaign-group-picker-in-iframe',
    CAMPAIGN_BULK_UPLOAD: 'taboola-ads:campaign-bulk-upload-enabled',
    BULK_OPERATIONS_METADATA_CHECK_DISABLED: 'taboola-ads:bulk-operations-metadata-check-disabled',
    ALLOW_SEARCH_ADS_REPORTS: 'allow-search-ads-reports-ui-test',
    CREATIVE_CAROUSEL_ENABLED: 'taboola-ads:creative-carousel-enabled',
    UNIFIED_CREATIVE_ENDPOINTS_ENABLED: 'taboola-ads:unified-creative-endpoints-enabled',
    UNIFIED_CREATIVE_FORM_DUPLICATE_ENABLED: 'taboola-ads:unified-creative-form:duplicate-enabled',
    GEN_AI_AB_TEST_WITH_AI: 'taboola-ads:gen-ai-ab-test-with-ai',
    GEN_AI_OPTIMIZATION_PREVIEWS_DRAWER_ENABLED: 'taboola-ads:gen-ai-optimization-previews-drawer-enabled',
    LLM_DKI_DETECTION_ENABLED: 'taboola-ads:creatives:llm-dki-detection-enabled',
    UNLIMITED_BUDGET_ENABLED: 'taboola-ads:unlimited-budget-enabled',
    SHOW_LEGACY_CATEGORY_FILTER: 'show-legacy-category-filter',
    IS_BILLING_RECONCILIATION_V2_ENABLED: 'billing:reconciliation-v2:is-enabled',
    SHOW_INTERNAL_LINK_CUSTOM_CONTEXT_MENU: 'taboola-ads:show-internal-link-custom-context-menu',
    DROPDOWN_EDIT_FALLBACK_IMAGE_OPTION_ENABLED: 'taboola-ads:dropdown-edit-fallback-image-option-enabled',
    SHOW_CUSTOM_DATA_IN_CREATIVE_FORM: 'taboola-ads:show-custom-data-in-creative-form',
    ACCOUNT_MANAGEMENT_ENABLED: 'taboola-ads:account-management-enabled',
    BULK_URL_VALIDATION_ENABLED: 'taboola-ads:bulk-url-validation-is-enabled',
    HIDE_ADMIN_ACCOUNT_SITE_REPORT: 'taboola-ads:hide-admin-account-site-reports',
    SHOW_PO_NUMBER: 'taboola-ads:show-po-number',
    CREATIVE_FORM_LOGO_FIELD_ENABLED: 'taboola-ads:creative-form-logo-field-enabled',
    EXTERNAL_DATA_DELETION_DISABLED: 'backstage:external-data:deletion-disabled',
    EXTERNAL_DATA_READONLY_FIELDS: 'backstage:external-data:account:read-only-fields',
    SCHEDULED_REPORTS_VIEW: 'taboola-ads:scheduled-reports-view:enable',
    SCHEDULED_REPORTS_EDIT: 'taboola-ads:scheduled-reports-edit:enable',
    SCHEDULED_REPORTS_EDIT_VIEW_ON_NETWORK: 'taboola-ads:scheduled-reports-on-network:enabled',
    SCHEDULED_REPORTS_ACCOUNT_SELECT_ON_NETWORK_LEVEL:
        'taboola-ads:scheduled-reports-select-account-enabled-for-networks:enable',
    SCHEDULED_REPORTS_CAMPAIGN_GROUP_REPORT_ENABLED:
        'taboola-ads:scheduled-reports-campaign-group-report:enabled-for-dsp',
    SCHEDULED_REPORTS_CUSTOM_RULE_REPORT_ENABLED: 'taboola-ads:scheduled-reports-custom-rule-report:enabled',
    PROFIT_MARGIN_IN_REPORT_ENABLED: 'taboola-ads:profit-margin-in-report-enabled',
    ALIGN_INITIAL_CROP_WITH_CLOUDINARY_ENABLED: 'taboola-ads:align-initial-crop-with-cloudinary-enabled',
    VTA_LOOKBACK_WINDOW_INITIAL_VALUE_IN_HOURS: 'vta:lookback-window-initial-value-in-hours',
    VTA_LOOKBACK_WINDOW_MAX_VALUE_IN_HOURS: 'vta:lookback-window-max-value-in-hours',
    VTA_LOOKBACK_WINDOW_DEFAULT_VALUE_IN_DAYS: 'vta:lookback-window-max-value-in-days',
    TABOOLA_SELECT_ENABLED: 'taboola-ads:taboola-select-enabled',
    TABOOLA_SELECT_ENABLED_FOR_VIDEO_ACCOUNT: 'taboola-ads:taboola-select-enabled-for-video-account',
    APPLE_NEWS_IN_TABOOLA_SELECT_ENABLED: 'taboola-ads:taboola-select:apple-news-enabled',
    PREMIUM_SUPPLY_IN_TABOOLA_SELECT_ENABLED: 'taboola-ads:taboola-select:premium-supply-enabled',
    YAHOO_MAIL_IN_TABOOLA_SELECT_ENABLED: 'taboola-ads:taboola-select:yahoo-mail-enabled',
    TABOOLA_CURATED_IN_TABOOLA_SELECT_ENABLED: 'taboola-ads:taboola-select:taboola-curated-enabled',
    SUPPORT_BP_ASPECT_RATIOS: 'taboola-ads:support-bp-aspect-ratios',
    EXCEL_BULK_ALLOW_FULL_CONFIG: 'taboola-ads:excel-bulk-allow-full-config',
    CUSTOM_EVENT_AGGREAGATOR_ENABLED: 'taboola-ads:custom-event-aggregator-enabled',
    AD_SCHEDULER_ENABLED: 'taboola-ads:ad-scheduler-enabled',
    AD_SCHEDULER_ENABLED_FOR_MOTION_ADS: 'taboola-ads:ad-scheduler-enabled-for-motion-ads',
    VENDOR_MACRO_REPLACEMENT_ENABLED: 'taboola-ads:vendor-macro-replacement-enabled',
    SHOW_ESTIMATED_DAILY_CAP_COLUMN_CAMPAIGN_REPORT: 'show-estimated-daily-cap-column-campaign-report',
    ENABLE_YAHOO_APP_HEADER_STYLE: 'taboola-ads:enable-yahoo-app-header-style',
    VIDEO_CREATIVE_UPLOADER_IS_VERTICAL_VIDEO_ALLOWED: 'taboola-ads:video-creative-uploader-is-vertical-video-allowed',
    CHAT_AGENT_ONBOARDING_ENABLED: 'taboola-ads:chat-agent-onboarding-enabled',
    MULTI_LAYER_DRAWER_ENABLED: 'taboola-ads:multi-layer-drawer-enabled',
    RENDER_REPORT_CONSTANTLY_ENABLED: 'taboola-ads:render-report-constantly-enabled',
    IS_TCPA_PIXEL_VALIDATION_ENABLED: 'backstage:target-cpa:is-pixel-validation-enabled',
    PERFORMANCE_RULES_ENABLE_EXCLUDE_NEW_CAMPAIGNS: 'taboola-ads:performance-rules:enable-exclude-new-campaigns',
    SHOW_SHOPIFY_SETUP: 'taboola-ads:show-shopify-setup',
    SENSITIVE_TOPICS_BRAND_SAFETY_ENABLED: 'taboola-ads:brand-safety:sensitive-topics-enabled',
    CAMPAIGN_GROUPS_LEVEL_REPORTING_ENABLED: 'taboola-ads:campaign-groups-level-reporting-enabled',
    CAMPAIGNS_GROUP_BULK_ACTIONS_ENABLED: 'taboola-ads:campaigns-reports:campaigns-group:is-bulk-actions-enabled',
    DISABLE_CREATE_BUTTON: 'taboola-ads:disable-create-button',
};

const fallbackPolicies = {
    ONLY_PERFORMANCE_REPORTS: FALLBACK_POLICY.PUBLISHER_ONLY,
    BACKSTAGE_ADVERTISER_ENABLE_CTA: FALLBACK_POLICY.FULL,
    HEAVY_ACCOUNT: FALLBACK_POLICY.PUBLISHER_ONLY,
    HEAVY_CREATIVES_REPORT: FALLBACK_POLICY.PUBLISHER_ONLY,
    HIDE_HEAVY_ACCOUNT_CREATIVES_REPORT: FALLBACK_POLICY.PUBLISHER_ONLY,
    HIDE_HEAVY_ACCOUNT_CAMPAIGNS_REPORT: FALLBACK_POLICY.PUBLISHER_ONLY,
    SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2: FALLBACK_POLICY.PUBLISHER_AND_GLOBAL,
    DISABLE_CREATE_BUTTON: FALLBACK_POLICY.PUBLISHER_ONLY,
};

const configKeyData = {
    [FEATURE_FLAGS.CPC_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.01,
        },
    ],
    [FEATURE_FLAGS.CPC_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: Number.POSITIVE_INFINITY,
        },
    ],
    [FEATURE_FLAGS.VIDEO_RATE_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 70,
        },
    ],
    [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPCV]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.005,
        },
    ],
    [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPM]: [
        {
            accountCurrency: 'USD',
            defaultValue: 1,
        },
    ],
    [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_VCPM]: [
        {
            accountCurrency: 'USD',
            defaultValue: 1.5,
        },
    ],
    [FEATURE_FLAGS.CPC_MAX_WARNING]: [
        {
            accountCurrency: 'USD',
            defaultValue: Number.POSITIVE_INFINITY,
        },
    ],
    [FEATURE_FLAGS.CPC_MIN_WARNING]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.01,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 30,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_MIN_WARN]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.05,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_MAX_WARN]: [
        {
            accountCurrency: 'USD',
            defaultValue: 15,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.01,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 30,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_MIN_WARN]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.05,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_MAX_WARN]: [
        {
            accountCurrency: 'USD',
            defaultValue: 15,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPC_DATA_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.05,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPC_DATA_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: Number.POSITIVE_INFINITY,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_DATA_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.1,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_CPM_DATA_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 30,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_DATA_MIN_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0.1,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_VCPM_DATA_MAX_VALUE]: [
        {
            accountCurrency: 'USD',
            defaultValue: 30,
        },
    ],
    [FEATURE_FLAGS.ACCOUNT_BUDGET_LIMITATION_THRESHOLD]: [
        {
            accountCurrency: 'USD',
            defaultValue: 0,
        },
    ],
};

const templateDefaultValues = generateDefaultFlagFromTemplate(configKeyData);

const defaultValues = {
    [FEATURE_FLAGS.IS_CAMPAIGN_COMMENT_SECTION_ENABLED]: 'true',
    [FEATURE_FLAGS.ONBOARDING_ASSISTANT_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOULD_REPORT_LOAD_TIME]: 'true',
    [FEATURE_FLAGS.HAS_OLD_PIXEL]: 'false',
    [FEATURE_FLAGS.HAS_SWITCHED_TO_TABOOLA_PIXEL]: 'false',
    [FEATURE_FLAGS.HAS_CAMPAIGNS]: 'false',
    [FEATURE_FLAGS.HAS_NATIVE_CAMPAIGNS]: 'false',
    [FEATURE_FLAGS.HAS_CAMPAIGNS_VIDEO]: 'false',
    [FEATURE_FLAGS.CAMPAIGNS_BULK_UPDATE_API_ENABLED_KEY]: 'true',
    [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
    [FEATURE_FLAGS.GOOGLE_DRIVE_EXPORT_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOW_CATEGORY_REPORT]: 'true',
    [FEATURE_FLAGS.SHOW_CATEGORY_REPORT_MISSING_DATA_INDICATION]: 'false',
    [FEATURE_FLAGS.CAN_EDIT_CAMPAIGN_USER_SEGMENTS]: 'true',
    [FEATURE_FLAGS.CREATIVE_CREATOR_BULK_CREATE_VARIATIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVES_BULK_ACTIONS_ENABLED]: 'true',
    [FEATURE_FLAGS.CREATIVE_FOCUS_APPLY_TO_ALL]: 'false',
    [FEATURE_FLAGS.CREATIVE_CREATOR_FOCAL_POINT_ENABLED]: 'false',
    [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true',
    [FEATURE_FLAGS.CREATIVE_DUPLICATE_ENABLED]: 'true',
    [FEATURE_FLAGS.CREATIVE_FORM_DESCRIPTION_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_ONE_BY_ONE_CREATION_ENABLED]: 'false',
    [FEATURE_FLAGS.BACKSTAGE_ADVERTISER_ENABLE_CTA]: 'false',
    [FEATURE_FLAGS.ACTION_CONVERSION_VIEWS_LOOKBACK_WINDOW]: 30,
    [FEATURE_FLAGS.ACTION_CONVERSION_CLICK_LOOKBACK_WINDOW]: 30,
    [FEATURE_FLAGS.ACTION_CONVERSION_VIEWS_START_DATE]: '9999-12-31 23:59',
    [FEATURE_FLAGS.ACTION_CONVERSION_LAST_CLICK_START_DATE]: '9999-12-31 23:59',
    [FEATURE_FLAGS.ON_SITE_FEED_PREVIEW]: 'true',
    [FEATURE_FLAGS.FEEDBACK_FORM_IS_VISIBLE]: 'false',
    [FEATURE_FLAGS.CONTACT_SUPPORT_CAN_BE_VISIBLE]: 'false',
    [FEATURE_FLAGS.IS_DUPLICATE_CAMPAIGN_SETTINGS_ENABLED]: 'false',
    [FEATURE_FLAGS.AUDIENCE_BULK_ACTION_ENABLED]: 'false',
    [FEATURE_FLAGS.USE_COUNTRIES_QUERY]: 'false',
    [FEATURE_FLAGS.IS_BUDGET_LIMIT_RATIO_CONFIG]: 'false',
    [FEATURE_FLAGS.BUDGET_LIMIT_RATIO_CONFIG]: '2',
    [FEATURE_FLAGS.IS_CREATIVES_AB_TEST_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVES_AB_TEST_MIN_END_DATE_DAYS]: 3,
    [FEATURE_FLAGS.CREATIVES_AB_TEST_MAX_END_DATE_DAYS]: 30,
    [FEATURE_FLAGS.CREATIVES_AB_TEST_DEFAULT_END_DATE_DAYS]: 14,
    [FEATURE_FLAGS.LT_MAX_CPC]: '10',
    [FEATURE_FLAGS.IS_SPECIFIC_LT_MAX_CPC]: 'false',
    [FEATURE_FLAGS.IS_MIN_DAY_PARTING_WINDOW]: 'false',
    [FEATURE_FLAGS.MIN_DAY_PARTING_WINDOW_HRS]: '4',
    [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'false',
    [FEATURE_FLAGS.HEAVY_CREATIVES_REPORT]: 'false',
    [FEATURE_FLAGS.HIDE_HEAVY_ACCOUNT_CREATIVES_REPORT]: 'false',
    [FEATURE_FLAGS.HIDE_HEAVY_ACCOUNT_CAMPAIGNS_REPORT]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'false',
    [FEATURE_FLAGS.SITE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'false',
    [FEATURE_FLAGS.CREATIVE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'false',
    [FEATURE_FLAGS.LIFETIME_RANGE_PICKER_PRESET_ENABLED]: 'true',
    [FEATURE_FLAGS.PIXEL_PREDICTIVE_ENABLED]: 'false',
    [FEATURE_FLAGS.ENABLE_GROUP_LEADS_GENERATION]: 'false',
    [FEATURE_FLAGS.ENABLE_GROUP_ONLINE_PURCHASES]: 'false',
    [FEATURE_FLAGS.ENABLE_GROUP_DRIVE_WEBSITE_TRAFFIC]: 'false',
    [FEATURE_FLAGS.ENABLE_GROUP_BRAND_AWARENESS]: 'false',
    [FEATURE_FLAGS.ENABLE_GROUP_MOBILE_APP_INSTALL]: 'false',
    [FEATURE_FLAGS.ENABLE_EXTERNAL_APPS_PERMISSIONS]: 'false',
    [FEATURE_FLAGS.MEDIA_LIBRARY_VIDEO_DRAWERS]: 'false',
    [FEATURE_FLAGS.MEDIA_LIBRARY_IMAGE_DRAWERS]: 'false',
    [FEATURE_FLAGS.AI_IMAGE_GENERATION_TAB_ENABLED]: 'false',
    [FEATURE_FLAGS.AI_TITLE_SUGGESTIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.AI_DESCRIPTION_SUGGESTIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.AI_PROMPT_HELPER_ENABLED]: 'false',
    [FEATURE_FLAGS.TAG_TYPE_IAS_ENABLED]: 'true',
    [FEATURE_FLAGS.TAG_TYPE_MOAT_ENABLED]: 'true',
    [FEATURE_FLAGS.TAG_TYPE_ADLOOX_ENABLED]: 'true',
    [FEATURE_FLAGS.TAG_TYPE_DV_ENABLED]: 'true',
    [FEATURE_FLAGS.TAG_TYPE_GOOGLE_DCM_ENABLED]: 'true',
    [FEATURE_FLAGS.TAG_TYPE_IMAGE_PIXEL_ENABLED]: 'true',
    [FEATURE_FLAGS.PROGRAMMATIC_BUDGET_ENABLED]: 'false',
    [FEATURE_FLAGS.ENABLE_DEAL_ID]: 'false',
    [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true',
    [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'false',
    [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'false',
    [FEATURE_FLAGS.TARGET_CPA_NO_CPC_ENABLED]: 'false',
    [FEATURE_FLAGS.TARGET_CPA_DUPLICATE_CAMPAIGN_ENABLED]: 'false',
    [FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING]: 'false',
    [FEATURE_FLAGS.HYBRID_MODULE_QUERY_PARAMS_ENABLED]: 'true',
    [FEATURE_FLAGS.HYBRID_MODULE_HEADER_PARAMS_ENABLED]: 'false',
    [FEATURE_FLAGS.TARGET_ROAS_ENABLED]: 'false',
    [FEATURE_FLAGS.SUPPLY_EXPLORATION_ENABLED]: 'false',
    [FEATURE_FLAGS.SAFETY_NETS_ENABLED_CONFIG_ATTRIBUTE]: 'true',
    [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'false',
    [FEATURE_FLAGS.BILLING_AND_PAYMENTS_MODULE_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOULD_HIDE_CURRENT_BALANCE]: 'false',
    [FEATURE_FLAGS.CREATIVE_RESUBMISSION_ENABLED]: 'false',
    [FEATURE_FLAGS.MIN_CONVERSIONS_GOAL_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_EDIT_VIDEO_DUPLICATE_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_FORM_CTA_ENABLED]: 'false',
    [FEATURE_FLAGS.ADS_REPORT_VIDEO_ON_HOVER_ENABLED]: 'false',
    [FEATURE_FLAGS.IMPRESSION_TYPE_FIELD]: null,
    [FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED]: 'false',
    [FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED_NEW]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_CUSTOM_CONTEXTUAL_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_COMBINED_AUDIENCES_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_KEYWORD_SUGGESTIONS_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_YAHOO_AUDIENCES_DOMAIN_SUGGESTIONS_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_REACH_ESTIMATOR_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_REACH_ESTIMATOR_BID_ALLOWED]: 'false',
    [FEATURE_FLAGS.ADS_CONSOLE_CUSTOM_CONTEXTUAL_ARTICLES_PREVIEW_ALLOWED]: 'false',
    [FEATURE_FLAGS.CREATE_CUSTOMER_FILE_ALLOWED]: 'false',
    [FEATURE_FLAGS.TARGET_CPA_ELIGIBLE_ACCOUNTS]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_TARGET_CPA]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_MAX_CONVERSIONS]: 'false',
    [FEATURE_FLAGS.BLOCK_INSIGNICANT_CREATIVE_CHANGES_ENABLED]: 'true',
    [FEATURE_FLAGS.SHOW_OUTDATED_API_NOTICE]: 'false',
    [FEATURE_FLAGS.NOTIFICATIONS_CENTER_ENABLED]: 'false',
    [FEATURE_FLAGS.NOTIFICATIONS_CENTER_ONLY_FOR_LOW_TOUCH]: 'true',
    [FEATURE_FLAGS.MY_AUDIENCE_DROPDOWN_IS_DISABLEABLE]: 'false',
    [FEATURE_FLAGS.WOCHIT_CREATE_VIDEO_ENABLED]: 'true',
    [FEATURE_FLAGS.DEFAULT_CTA_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOW_CAMPAIGN_HISTORY_REPORT]: 'true',
    [FEATURE_FLAGS.SHOW_CAMPAIGN_HISTORY_REPORT_GRAPH]: 'true',
    [FEATURE_FLAGS.ENABLE_RIBBON_RECOMMENDATION]: 'false',
    [FEATURE_FLAGS.SUBMISSION_DATA_ALLOWED_IN_ADS_REPORT]: 'false',
    [FEATURE_FLAGS.AUDIENCE_TARGETING_FIRST_CAMPAIGN_MESSAGE_ENABLED]: 'false',
    [FEATURE_FLAGS.IS_WITH_TAX_REGISTRATION]: 'false',
    [FEATURE_FLAGS.IS_WITH_VAT_ID]: 'false',
    [FEATURE_FLAGS.SHOW_COMPARE_CAMPAIGNS_GRAPH]: 'false',
    [FEATURE_FLAGS.SHOW_COMPARE_CREATIVES_GRAPH]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS]: 'false',
    [FEATURE_FLAGS.TA_REALTIME]: 'false',
    [FEATURE_FLAGS.IS_SCHEDULE_TRACKING_EXPERT_ENABLED_TA]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_PERFORMANCE_RULES_ENABLED]: 'false',
    [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.IS_PIXEL_CODE_ENABLED]: 'false',
    [FEATURE_FLAGS.TRACKING_SCREENS]: 'true',
    [FEATURE_FLAGS.GTM_AUTOMATED_SETUP_ENABLED]: 'false',
    [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'false',
    [FEATURE_FLAGS.CONVERSION_FUNNEL_ENABLED]: 'false',
    [FEATURE_FLAGS.CONVERSION_DEFAULT_FUNNEL_ENABLED]: 'false',
    [FEATURE_FLAGS.CVR_LEARNING_STATUS_ENABLED]: 'true',
    [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_EXECUTED_ACTIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.EDIT_PERFORMANCE_RULES_ENABLED]: 'false',
    [FEATURE_FLAGS.DUPLICATE_PERFORMANCE_RULES_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_PREVIEW_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_REPORTS_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_LONG_LOOK_BACK_WINDOW_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_UNDO_ACTIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLE_EMAIL_NOTIFICATIONS]: 'false',
    [FEATURE_FLAGS.DCO_ACCOUNT_HAS_IN_TRIAL_CAMPAIGNS]: 'false',
    [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'false',
    [FEATURE_FLAGS.OUTCROPPING_IMAGE_ENABLED]: 'false',
    [FEATURE_FLAGS.RANGE_OF_RATIO_OUTCROPPING_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_REPORT_USE_WIDE_CONFIG_MSV2]: 'false',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_BUNDLING_ENABLED]: 'false',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED]: 'false',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_IDS_LIST]: '',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_IDS_LIST]: '',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_TAXONOMY_PARENTS_LIST]: '',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_TAXONOMY_PARENTS_LIST]: '',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_SEGMENT_LIMIT]: '1',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_SEGMENT_LIMIT]: '1',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_MIXED_SEGMENT_LIMIT]: '2',
    [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_ADVANCED_SEARCH_ENABLED]: 'false',
    [FEATURE_FLAGS.BID_STRATEGY_V2_ENABLED]: 'false',
    [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_CREATION_AI_VARIATIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'false',
    [FEATURE_FLAGS.DELETE_CAMPAIGN_GROUP_ENABLED]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_MULTIPLE_CONVERSIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.HIDE_CONVERSIONS_GOAL_ACCOUNT_DEFAULT]: 'false',
    [FEATURE_FLAGS.BID_DAY_TIME_ENABLED]: 'false',
    [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true',
    [FEATURE_FLAGS.USER_TIME_ZONE_ENABLED]: 'false',
    [FEATURE_FLAGS.SCHEDULE_DAY_TIME_ENABLED]: 'false',
    [FEATURE_FLAGS.BID_PLATFORM_ENABLED]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'false',
    [FEATURE_FLAGS.MAX_EXCLUDE_COUNTRIES]: 200,
    [FEATURE_FLAGS.AI_WIZARD_ONBOARDING]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'false',
    [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'false',
    [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
    [FEATURE_FLAGS.FAVORITED_MEDIA_ENABLED]: 'false',
    [FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED]: 'false',
    [FEATURE_FLAGS.SUPPLY_TARGETING_USE_TABOOLA_VALUE_AS_DEFAULT]: 'false',
    [FEATURE_FLAGS.LOW_TIER_DAILY_LIMIT_MAX_VALUE]: null,
    [FEATURE_FLAGS.TA_PIXEL_WARNINGS_TESTING]: 'false',
    [FEATURE_FLAGS.PROFIT_MARGIN_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOULD_DISPLAY_JS_FOR_ALL]: 'true',
    [FEATURE_FLAGS.DISABLED_CLC_THRESHOLD_WARNING]: 'false',
    [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND]: 'false',
    [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING]: 'false',
    [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING_POPUP]: 'true',
    [FEATURE_FLAGS.CREATIVE_STUDIO_GENERATE_VARIATIONS]: 'false',
    [FEATURE_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_ENABLED]: 'false',
    [FEATURE_FLAGS.SUPPORT_APP_INSTALL_MULTI_SLOTS_WIDGETS]: 'false',
    [FEATURE_FLAGS.SUPPORT_ADVANCED_PREVIEW]: 'false',
    [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'false',
    [FEATURE_FLAGS.AI_VARIATIONS_REPLACEMENT_ENABLED]: 'true',
    [FEATURE_FLAGS.SHOW_CAMPAIGN_GROUP_PICKER_IN_IFRAME]: 'true',
    [FEATURE_FLAGS.ACCOUNT_REPORT_ENABLED]: 'true',
    [FEATURE_FLAGS.ONLINE_PURCHASES_MARKETING_OBJECTIVE_ENABLED]: 'true',
    [FEATURE_FLAGS.CAMPAIGN_BULK_UPLOAD]: 'false',
    [FEATURE_FLAGS.BULK_OPERATIONS_METADATA_CHECK_DISABLED]: 'false',
    [FEATURE_FLAGS.ALLOW_SEARCH_ADS_REPORTS]: 'false',
    [FEATURE_FLAGS.SHARED_BUDGET_UA_ENABLED]: 'false',
    [FEATURE_FLAGS.CREATIVE_CAROUSEL_ENABLED]: 'false',
    [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    [FEATURE_FLAGS.GEN_AI_OPTIMIZATION_PREVIEWS_DRAWER_ENABLED]: 'false',
    [FEATURE_FLAGS.LLM_DKI_DETECTION_ENABLED]: 'false',
    [FEATURE_FLAGS.UNLIMITED_BUDGET_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOW_LEGACY_CATEGORY_FILTER]: 'false',
    [FEATURE_FLAGS.IS_BILLING_RECONCILIATION_V2_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOW_INTERNAL_LINK_CUSTOM_CONTEXT_MENU]: 'false',
    [FEATURE_FLAGS.DROPDOWN_EDIT_FALLBACK_IMAGE_OPTION_ENABLED]: 'false',
    [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'false',
    [FEATURE_FLAGS.ACCOUNT_MANAGEMENT_ENABLED]: 'false',
    [FEATURE_FLAGS.BULK_URL_VALIDATION_ENABLED]: 'true',
    [FEATURE_FLAGS.HIDE_ADMIN_ACCOUNT_SITE_REPORT]: 'false',
    [FEATURE_FLAGS.SHOW_PO_NUMBER]: 'false',
    [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'false',
    [FEATURE_FLAGS.EXTERNAL_DATA_DELETION_DISABLED]: 'false',
    [FEATURE_FLAGS.EXTERNAL_DATA_READONLY_FIELDS]: '',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_VIEW]: 'false',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_EDIT]: 'false',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_EDIT_VIEW_ON_NETWORK]: 'false',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_ACCOUNT_SELECT_ON_NETWORK_LEVEL]: 'false',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_CAMPAIGN_GROUP_REPORT_ENABLED]: 'false',
    [FEATURE_FLAGS.SCHEDULED_REPORTS_CUSTOM_RULE_REPORT_ENABLED]: 'false',
    [FEATURE_FLAGS.PROFIT_MARGIN_IN_REPORT_ENABLED]: 'false',
    [FEATURE_FLAGS.ALIGN_INITIAL_CROP_WITH_CLOUDINARY_ENABLED]: 'false',
    [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_INITIAL_VALUE_IN_HOURS]: 24,
    [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_MAX_VALUE_IN_HOURS]: 168,
    [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_DEFAULT_VALUE_IN_DAYS]: 'false',
    [FEATURE_FLAGS.TABOOLA_SELECT_ENABLED]: 'false',
    [FEATURE_FLAGS.APPLE_NEWS_IN_TABOOLA_SELECT_ENABLED]: 'false',
    [FEATURE_FLAGS.PREMIUM_SUPPLY_IN_TABOOLA_SELECT_ENABLED]: 'false',
    [FEATURE_FLAGS.YAHOO_MAIL_IN_TABOOLA_SELECT_ENABLED]: 'false',
    [FEATURE_FLAGS.TABOOLA_CURATED_IN_TABOOLA_SELECT_ENABLED]: 'false',
    [FEATURE_FLAGS.SUPPORT_BP_ASPECT_RATIOS]: 'false',
    [FEATURE_FLAGS.SUPPLY_TARGETING_PMP_ENABLED]: 'false',
    [FEATURE_FLAGS.EXCEL_BULK_ALLOW_FULL_CONFIG]: 'false',
    [FEATURE_FLAGS.CUSTOM_EVENT_AGGREAGATOR_ENABLED]: 'false',
    [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'false',
    [FEATURE_FLAGS.UNIFIED_CREATIVE_ENDPOINTS_ENABLED]: 'false',
    [FEATURE_FLAGS.UNIFIED_CREATIVE_FORM_DUPLICATE_ENABLED]: 'false',
    [FEATURE_FLAGS.VENDOR_MACRO_REPLACEMENT_ENABLED]: 'false',
    [FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER]: 'false',
    [FEATURE_FLAGS.ENABLE_YAHOO_APP_HEADER_STYLE]: 'false',
    [FEATURE_FLAGS.SHOW_ESTIMATED_DAILY_CAP_COLUMN_CAMPAIGN_REPORT]: 'false',
    [FEATURE_FLAGS.CHAT_AGENT_ONBOARDING_ENABLED]: 'false',
    [FEATURE_FLAGS.RENDER_REPORT_CONSTANTLY_ENABLED]: 'false',
    [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'false',
    [FEATURE_FLAGS.IS_TCPA_PIXEL_VALIDATION_ENABLED]: 'false',
    [FEATURE_FLAGS.AD_SCHEDULER_ENABLED_FOR_MOTION_ADS]: 'false',
    [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLE_EXCLUDE_NEW_CAMPAIGNS]: 'false',
    [FEATURE_FLAGS.SHOW_SHOPIFY_SETUP]: 'false',
    [FEATURE_FLAGS.SENSITIVE_TOPICS_BRAND_SAFETY_ENABLED]: 'false',
    [FEATURE_FLAGS.CAMPAIGN_GROUPS_LEVEL_REPORTING_ENABLED]: 'false',
    [FEATURE_FLAGS.CAMPAIGNS_GROUP_BULK_ACTIONS_ENABLED]: 'false',
    [FEATURE_FLAGS.DISABLE_CREATE_BUTTON]: 'false',
    ...templateDefaultValues,
};

export { FEATURE_FLAGS, defaultValues, fallbackPolicies };
