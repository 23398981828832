import { usePermissions } from '../../../../../taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';

export const useThirdPartyTagsFormFieldValue = () => {
    const isPermitted = usePermissions('CAMPAIGN_TRACKING_PIXELS_EDIT');
    const props = useFormFieldValue({
        field: 'thirdPartyTags',
        isPermitted,
    });

    return { ...props, isPermitted };
};
