import { validationsApi } from '../../../../../services/api';
import { stringUtils } from '../../../../../services/utils';

const MSG_ID_PREFIX = 'campaign.editor.thirdPartyTags.validations.error';

export const suffixesToRemove = ['.', ' in row 1', ': line 1'];
export const UNKNOWN_ERROR = {
    defaultMessage: 'Failed to validate tag.',
    id: `${MSG_ID_PREFIX}.generic`,
};

export const sanitizeErrorMsg = msg => {
    // reverse strings to play nicely with str.indexOf and str.substring
    const reversedMsg = stringUtils.reverseStr(msg);
    const reversedSuffixes = suffixesToRemove.map(stringUtils.reverseStr);

    return stringUtils.reverseStr(stringUtils.removePrefixes(reversedMsg, reversedSuffixes));
};

export const validateThirdPartyTags = (accountId, tag) => () => {
    return validationsApi
        .validateThirdPartyTag(accountId, tag)
        .then(() => true)
        .catch((error = {}) => {
            const { message, messageCode } = error;

            // if the error sent has missing fields, return unknown error
            if (!message || !messageCode) {
                return UNKNOWN_ERROR;
            }

            const sanitizedMessage = sanitizeErrorMsg(message);

            return {
                defaultMessage: sanitizedMessage,
                id: `${MSG_ID_PREFIX}.${messageCode}`,
            };
        });
};

export default validateThirdPartyTags;
