import React from 'react';
import { isVideoCampaign } from '../../../../../../services/campaignDimension';
import { getCampaignStatusMessages } from '../../../../services/utils';
import { BID_STRATEGIES, CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES } from '../../../common-campaign-form/config';
import { PHASES, STATUS } from '../../services/campaignStatusPhaseService';
import CreativesABTestStatusRenderer from './CreativesABTestStatusRenderer';
import { CvrLearningStatusRenderer } from './CvrLearningStatusRenderer/CvrLearningStatusRenderer';
import { useCvrLearningStatusDisplayValidator } from './CvrLearningStatusRenderer/useCvrLearningStatusDisplayValidator';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';
import StatusTrackerRenderer from './StatusTrackerRenderer';
import TargetCpaStatusRenderer from './TargetCpaStatusRenderer';
import VideoCampaignStatusRenderer from './VideoCampaignStatusRenderer';

const CampaignStatusRenderer = props => {
    const {
        value: { status },
    } = props;
    const campaignStatusMessages = getCampaignStatusMessages(status);
    const shouldDisplayCvrLearningStatus = useCvrLearningStatusDisplayValidator(props);

    if (shouldDisplayCreativesABTestStatus(props)) {
        return <CreativesABTestStatusRenderer campaignStatusMessages={campaignStatusMessages} {...props} />;
    }

    if (shouldDisplayCvrLearningStatus) {
        return <CvrLearningStatusRenderer campaignStatusMessages={campaignStatusMessages} {...props} />;
    }

    if (shouldDisplayTargetCpaStatus(props)) {
        return <TargetCpaStatusRenderer campaignStatusMessages={campaignStatusMessages} {...props} />;
    }

    if (shouldDisplayVideoCampaignStatus(props)) {
        return <VideoCampaignStatusRenderer campaignStatusMessages={campaignStatusMessages} {...props} />;
    }

    if (shouldDisplayStatusOnly(props)) {
        return <StatusCellRenderer {...props} {...campaignStatusMessages} value={status} />;
    }

    return <StatusTrackerRenderer campaignStatusMessages={campaignStatusMessages} {...props} />;
};

const shouldDisplayCreativesABTestStatus = props => {
    const {
        data: { creativeTrafficAllocationMethod, creativeTrafficAllocationABTestEndDate },
        value: { status, isActive },
    } = props;

    return (
        creativeTrafficAllocationMethod === CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN &&
        creativeTrafficAllocationABTestEndDate &&
        isActive &&
        isStatusValid(status)
    );
};

const shouldDisplayTargetCpaStatus = props => {
    const {
        data: { cvrLearningStatus, bidStrategy, cvrLearningStatusUpdateTimeInUtc },
    } = props;

    return (
        bidStrategy === BID_STRATEGIES.TARGET_CPA &&
        cvrLearningStatusUpdateTimeInUtc &&
        (cvrLearningStatus === PHASES.CVR_LEARNING || cvrLearningStatus === PHASES.CVR_LEARNING_LIMITED)
    );
};

const shouldDisplayVideoCampaignStatus = props => {
    const {
        data: { dimension },
    } = props;

    return isVideoCampaign(dimension);
};

const isStatusValid = status => status === STATUS.PENDING_APPROVAL || status === STATUS.RUNNING;

const shouldDisplayStatusOnly = ({ data: { bidStrategy } }) => {
    return bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS;
};

export default CampaignStatusRenderer;
