import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withFormDataProvider, useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../../../../selectors';
import { FormInitBase } from '../../../../../taboola-common-frontend-modules/forms';
import { FormLayout, useVerticalScrollDirection } from '../../../../components/Form';
import CampaignsGroupEditorContent from '../../components/CampaignsGroupEditorContent/CampaignsGroupEditorContent';
import CampaignsGroupEditorHeader from '../../components/CampaignsGroupEditorHeader/CampaignsGroupEditorHeader';
import { useCampaignsGroupEditorState } from '../../hooks';
import styles from './campaignsGroupEditor.module.scss';

const CampaignsGroupEditor = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus } = useFormDataContext();
    const { submit } = useCampaignsGroupEditorState();
    const { currency } = useSelector(selectedAccountSelector);
    const { scrollElementRef: scrollRef, down: collapsedHeader } = useVerticalScrollDirection();

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={
                    <CampaignsGroupEditorHeader
                        status={fetchStatus}
                        headerClassName={styles['header']}
                        collapsed={collapsedHeader}
                    />
                }
                submitButtonText={<FormattedMessage id="campaigns.group.editor.submit.button" defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['editor']}
                bodyContainerClassName={styles['editor-body']}
                footerContainerClassName={styles['editor-footer']}
                data-automation="CampaignsGroupEditor"
                bodyRef={scrollRef}
            >
                <CampaignsGroupEditorContent currency={currency} />
            </FormLayout>
        </FormInitBase>
    );
};

CampaignsGroupEditor.propTypes = { onCancel: PropTypes.func };

const CampaignsGroupEditorWithFormDataProvider = withFormDataProvider(CampaignsGroupEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export {
    CampaignsGroupEditorWithFormDataProvider as CampaignsGroupEditor,
    CampaignsGroupEditor as CampaignsGroupEditorUnwrapped,
};
