import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker/gtmTracker';

export const saveItem = (key, item) => {
    try {
        localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
        gtmTracker.trackError(error);
    }
};

export const getItem = (key, defaultValue = {}) => {
    try {
        return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (error) {
        gtmTracker.trackError(error);
        return defaultValue;
    }
};

export const removeItem = key => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        gtmTracker.trackError(error);
    }
};
