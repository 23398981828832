import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { partition, isEmpty } from 'lodash';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import ThumbnailOverlay from 'modules/campaigns/modules/creative-creator/components/ThumbnailOverlay/ThumbnailOverlay';
import { MOTIONS, IMAGES } from 'modules/campaigns/modules/creative-creator/config';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { isFileTypeFallbackImage, isFileTypeVideo } from 'modules/campaigns/modules/creative-creator/services/utils';
import { ThumbnailEditBanner } from 'modules/campaigns/modules/creative-editor/components/ThumbnailEditor/Banner';
import { useConfigMatch, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PermissionsGuard } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n/index';
import { COMPONENT_STATUS } from 'services/constants';
import GalleryTitle from './GalleryTitle';
import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';
import styles from './selectedThumbnailsGallery.module.scss';

const classNameBuilder = classnames.bind(styles);
const videoGridConfig = { HEIGHT: '155px', COUNT: '0.4fr 1fr 1fr', LEFT: 2 };
const defaultCreativeFocus = { type: 'AUTOMATIC', coordinates: null };
const imageGridConfigs = {
    1: { HEIGHT: '155px', COUNT: 1, LEFT: 2, GRID_TEMPLATE_COLUMNS: '1fr 2.15fr 1fr' },
    2: { HEIGHT: '155px', COUNT: 2, LEFT: 0, GRID_TEMPLATE_COLUMNS: `1fr 1fr` },
    3: { HEIGHT: '105px', COUNT: 3, LEFT: 0 },
};
const CREATIVE_PREVIEW_VIEW = 'CREATIVE_PREVIEW_VIEW';

const getImageGridConfig = (thumbnailsCount, columnCount) => {
    const count = thumbnailsCount > columnCount ? columnCount : thumbnailsCount;
    return imageGridConfigs[count];
};

export const SelectedThumbnailsGallery = ({
    className,
    checkFallbackImageDisabled,
    onEditFallbackImageClick,
    onRemoveThumbnailById,
    onSelectMedia,
    cancelSelectFallbackImage,
    motionIdFallbackSelecting,
    columnCount,
    thumbnailList,
    startEditing,
    setSelectedImgSrc,
    setCreativeFocus,
    openCropModal,
    formatType,
}) => {
    const [motions, images] = useMemo(
        () =>
            partition(
                // before partition, add a key marking the original index of the item
                // for FormNamespaceProvider
                thumbnailList?.map((thumbnail, i) => ({ ...thumbnail, originalIndex: i })),
                thumbnail => thumbnail.mediaCategory === MOTIONS
            ),
        [thumbnailList]
    );

    const isFocalPointEditBannerEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_CREATOR_FOCAL_POINT_ENABLED]: 'true',
    });
    const isCroppingImageEnabled = useConfigMatch({ [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true' });
    const { isAppInstall: isAppInstallInEditMode } = useIsAppInstallCreativeInEditMode();
    const isAppInstallFormatInCreateMode = formatType === CREATIVE_FORMAT_TYPE.APP_INSTALL;
    const isAppInstallFormatSelected = isAppInstallFormatInCreateMode || isAppInstallInEditMode;

    const shouldRenderFocalPointBanner =
        isCroppingImageEnabled && !isAppInstallFormatSelected ? false : isFocalPointEditBannerEnabled;

    const renderEditBanner = (thumbnail, isEnabled, onClick, text, dataMetrics, BannerComponent) => {
        if (!isEnabled) {
            return null;
        }

        const onBannerClick = () => {
            onClick(thumbnail);
        };

        return (
            <PermissionsGuard permissions={CREATIVE_PREVIEW_VIEW}>
                <BannerComponent
                    className={styles['banner']}
                    onClick={onBannerClick}
                    text={text}
                    dataMetrics={dataMetrics}
                />
            </PermissionsGuard>
        );
    };

    const renderOverlay = (thumbnail, banner) => {
        const { fileType, status } = thumbnail;
        if ((isFileTypeVideo(fileType) && status !== COMPONENT_STATUS.ERROR) || isFileTypeFallbackImage(fileType)) {
            return null;
        }
        return () => <ThumbnailOverlay onButtonClick={() => onRemoveThumbnailById(thumbnail)} banner={banner} />;
    };

    const renderOverlayComponent = thumbnail => {
        const focalPointBanner = renderEditBanner(
            thumbnail,
            shouldRenderFocalPointBanner,
            () => {
                setSelectedImgSrc(thumbnail);
                setCreativeFocus(thumbnail.creativeFocus || defaultCreativeFocus);
                startEditing();
            },
            <FormattedMessage id="focal.point.label" defaultMessage="Focal Point" />,
            'Focal Point',
            ThumbnailEditBanner
        );

        const croppingBanner = renderEditBanner(
            thumbnail,
            isCroppingImageEnabled,
            () => {
                openCropModal({ src: thumbnail.value });
            },
            <FormattedMessage id="crop.image.label" defaultMessage="Crop Image" />,
            'Cropping',
            ThumbnailEditBanner
        );

        const banner = shouldRenderFocalPointBanner ? focalPointBanner : croppingBanner;

        return renderOverlay(thumbnail, banner);
    };

    const showGalleryTitles = !isEmpty(images) && !isEmpty(motions);
    const imageGridConfig = getImageGridConfig(images.length || 1, columnCount);

    return (
        <>
            {showGalleryTitles && <GalleryTitle name={MOTIONS} />}
            {!isEmpty(motions) && (
                <VideoGallery
                    gap={styleConsts.galleryGridGap}
                    minRowHeight={videoGridConfig.HEIGHT}
                    columns={videoGridConfig.COUNT}
                    className={classNameBuilder({ 'video-gallery': showGalleryTitles }, className)}
                    videosToRender={motions}
                    left={videoGridConfig.LEFT}
                    checkFallbackImageDisabled={checkFallbackImageDisabled}
                    onEditFallbackImageClick={onEditFallbackImageClick}
                    onCloseVideoClick={onRemoveThumbnailById}
                    renderOverlayComponent={renderOverlay}
                    onSelectMedia={onSelectMedia}
                    cancelSelectFallbackImage={cancelSelectFallbackImage}
                    motionIdFallbackSelecting={motionIdFallbackSelecting}
                />
            )}
            {showGalleryTitles && <GalleryTitle name={IMAGES} />}
            {images.length > 0 && images && (
                <ImageGallery
                    gap={styleConsts.galleryGridGap}
                    minRowHeight={imageGridConfig.HEIGHT}
                    columns={imageGridConfig.GRID_TEMPLATE_COLUMNS || imageGridConfig.COUNT}
                    className={className}
                    images={images}
                    left={imageGridConfig.LEFT}
                    renderOverlayComponent={renderOverlayComponent}
                    onEditFallbackImageClick={onEditFallbackImageClick}
                    onRemoveThumbnailById={onRemoveThumbnailById}
                />
            )}
        </>
    );
};
