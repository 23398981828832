'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.isnil');

var _lodash4 = _interopRequireDefault(_lodash3);

var _lodash5 = require('lodash.noop');

var _lodash6 = _interopRequireDefault(_lodash5);

var _ToggleSwitch = require('../../ToggleSwitch');

var _ToggleSwitch2 = _interopRequireDefault(_ToggleSwitch);

var _TooltipV = require('../../Tooltips/TooltipV2/TooltipV2');

var _TooltipV2 = _interopRequireDefault(_TooltipV);

var _TooltipPosition = require('../../Tooltips/Tooltip/TooltipPosition');

var _TooltipPosition2 = _interopRequireDefault(_TooltipPosition);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'legendRow__taboola-svg-icon___2Ahs1',
    'row': 'legendRow__row___38Dml',
    'disabled': 'legendRow__disabled___R7nOs',
    'value': 'legendRow__value___NYKEB',
    'label': 'legendRow__label___2W77E',
    'active': 'legendRow__active___3UgmL',
    'tooltip': 'legendRow__tooltip___3SqJj',
    'color-dot': 'legendRow__color-dot___2VJTl'
};


var classNameBuilder = _bind2.default.bind(styles);

var LegendRow = function LegendRow(_ref) {
    var onTogglePreview = _ref.onTogglePreview,
        toggled = _ref.toggled,
        onToggle = _ref.onToggle,
        name = _ref.name,
        label = _ref.label,
        color = _ref.color,
        formatter = _ref.formatter,
        active = _ref.active,
        payload = _ref.payload,
        value = _ref.value,
        disabled = _ref.disabled,
        isFirst = _ref.isFirst,
        renderTooltipContent = _ref.renderTooltipContent,
        disablePreviewForSelected = _ref.disablePreviewForSelected,
        Toggle = _ref.toggleComponent,
        disableTooltip = _ref.disableTooltip;

    var tooltipContent = renderTooltipContent && renderTooltipContent(disabled, payload);
    var onMouseEnter = disablePreviewForSelected && toggled ? _lodash6.default : function () {
        return onTogglePreview(name, true);
    };
    var tooltipClass = (0, _lodash2.default)(payload, 'tooltipClass');
    var customTooltipProps = (0, _lodash2.default)(payload, 'tooltipProps', {});

    var tooltipProps = Object.assign({
        position: isFirst ? _TooltipPosition2.default.BOTTOM : _TooltipPosition2.default.TOP,
        arrow: true
    }, customTooltipProps);

    return _react2.default.createElement(
        'label',
        {
            className: classNameBuilder({ row: true, active: active, disabled: disabled }),
            onMouseEnter: onMouseEnter,
            onMouseLeave: function onMouseLeave() {
                return onTogglePreview('', false);
            }
        },
        _react2.default.createElement(Toggle, { checked: toggled, onChange: onToggle, color: color, disabled: disabled }),
        _react2.default.createElement(
            'span',
            { className: styles['label'] },
            label
        ),
        !(0, _lodash4.default)(value) ? _react2.default.createElement(
            'span',
            { className: styles['value'], style: { color: active ? color : null } },
            formatter(value)
        ) : null,
        tooltipContent && !disableTooltip && _react2.default.createElement(
            _TooltipV2.default,
            Object.assign({ className: tooltipClass || styles['tooltip'] }, tooltipProps),
            tooltipContent
        )
    );
};

LegendRow.propTypes = {
    onTogglePreview: _propTypes2.default.func,
    toggled: _propTypes2.default.bool,
    onToggle: _propTypes2.default.func,
    name: _propTypes2.default.string,
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]).isRequired,
    color: _propTypes2.default.string,
    formatter: _propTypes2.default.func,
    active: _propTypes2.default.bool,
    payload: _propTypes2.default.object,
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    shouldDisableUnselected: _propTypes2.default.bool,
    isFirst: _propTypes2.default.bool,
    renderTooltipContent: _propTypes2.default.func,
    disabled: _propTypes2.default.bool,
    disablePreviewForSelected: _propTypes2.default.bool,
    toggleComponent: _propTypes2.default.any,
    disableTooltip: _propTypes2.default.bool
};

LegendRow.defaultProps = {
    onTogglePreview: function onTogglePreview() {},
    onToggle: function onToggle() {},
    formatter: function formatter(value) {
        return value;
    },
    toggleComponent: _ToggleSwitch2.default
};

exports.default = LegendRow;