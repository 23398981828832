import { saveStateToStorage } from '../../../../../actions';
import { selectedReportSelector, reportGraphStateSelector } from '../../../selectors';
import { setReportGraphState } from '../actions';
import { updateOpenState } from '../services';

const saveGraphOpenState = isOpen => async (dispatch, getState) => {
    const state = getState();
    const reportGraphState = reportGraphStateSelector(state);
    const selectedReport = selectedReportSelector(state);
    const updatedReportGraphState = updateOpenState(reportGraphState, selectedReport, isOpen);

    dispatch(setReportGraphState(updatedReportGraphState));
    dispatch(saveStateToStorage());
};

export default saveGraphOpenState;
