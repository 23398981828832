import { useQuery } from 'react-query';
import { useResourcesApi } from '../../../../../../../services/api';

const useViewabilityRatesApi = () => {
    const { getViewabilityRates } = useResourcesApi();
    const { data: viewabilityRates, isLoading: isResourcesLoading } = useQuery(
        ['viewability-rates'],
        () => getViewabilityRates(),
        {
            refetchOnWindowFocus: false,
            select: data =>
                data?.map(({ name, value, ...rest }) => ({
                    id: name,
                    label: value,
                    value,
                    ...rest,
                })),
        }
    );

    return {
        viewabilityRates,
        isResourcesLoading,
    };
};

export default useViewabilityRatesApi;
