import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './conversionSetupUrlConditionPredicateOption.module.scss';

export const ConversionSetupUrlConditionPredicateOption = ({ data: { value } }) => {
    return (
        <div className={styles['container']}>
            <span className={styles['label']}>
                <FormattedMessage id={`tracking.conversion.setup.conditions.predicate.${value}`} />
            </span>
        </div>
    );
};

ConversionSetupUrlConditionPredicateOption.propTypes = {
    data: PropTypes.object,
};
