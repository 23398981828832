"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var cssVars = function cssVars() {
    var vars = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var varNames = Object.keys(vars);
    var varMap = {};
    varNames.forEach(function (varName) {
        varMap["--" + varName] = vars[varName];
    });
    return varMap;
};

exports.default = cssVars;