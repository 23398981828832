"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgZipcode(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M19 13C19 14.6572 17.6567 16 16 16C14.3433 16 13 14.6572 13 13C13 11.3428 14.3433 10 16 10C17.6567 10 19 11.3428 19 13ZM16 15C17.1046 15 18 14.1046 18 13C18 11.8954 17.1046 11 16 11C14.8954 11 14 11.8954 14 13C14 14.1046 14.8954 15 16 15Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M16 25C16.8887 25 24 19.0908 24 13.1816C24 8.66309 20.4185 5 16 5C11.5815 5 8 8.66309 8 13.1816C8 19.0908 15.1113 25 16 25ZM19.5723 20.6582C21.416 18.4951 23 15.7725 23 13.1816C23 9.19434 19.8447 6 16 6C12.1553 6 9 9.19434 9 13.1816C9 15.7725 10.584 18.4951 12.4277 20.6582C13.333 21.7197 14.2622 22.6016 15.0073 23.21C15.3813 23.5146 15.6958 23.7412 15.9277 23.8848L16 23.9277L16.0723 23.8848C16.165 23.8271 16.2715 23.7559 16.3896 23.6719C16.5659 23.5469 16.7686 23.3926 16.9927 23.21C17.7378 22.6016 18.667 21.7197 19.5723 20.6582Z",
            fill: "#212832"
        }),
        React.createElement("path", { d: "M24 26V27H8V26H24Z", fill: "#212832" })
    );
}
exports.default = SvgZipcode;