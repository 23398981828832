import React from 'react';
import classnames from 'classnames/bind';
import { OkIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { OnboardingCTA } from './BodySection/OnboardingCTA';
import styles from './onboardingCompletedDescription.module.scss';

const classNameBuilder = classnames.bind(styles);

export const OnboardingCompletedDescription = ({ containerClassName, onClickCTA, isCollapsed }) => {
    return (
        <div className={classNameBuilder('container', containerClassName)}>
            <div className={classNameBuilder('header', { collapsed: isCollapsed })}>
                <OkIcon className={styles['ok-icon']} />
                <div className={styles['description']}>
                    <FormattedMessage
                        id="onboarding.assistant.complete.description"
                        defaultMessage="Well Done! Your account is now ready 🎉"
                    />
                </div>
            </div>
            <div className={classNameBuilder('body', { collapsed: isCollapsed })}>
                <OkIcon className={styles['ok-icon']} />
                <div className={styles['description']}>
                    <FormattedMessage
                        id="onboarding.assistant.complete.description"
                        defaultMessage="Well Done! Your account is now ready 🎉"
                    />
                </div>
            </div>
            <div className={styles['action-section']}>
                <OnboardingCTA stepId="complete" onClick={onClickCTA} className={styles['cta']} />
            </div>
        </div>
    );
};
