import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const TimeFrameTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.time.frame.help.title"
            defaultMessage="Choose when your campaign will start and end. You have 2 options:"
        />
        <FormattedMessage
            id="campaign.time.frame.help.option.a"
            defaultMessage="a) Start running as soon as approved, and continue until budget is depleted."
        />
        <FormattedMessage
            id="campaign.time.frame.help.option.b"
            defaultMessage="b) Specify a start and end date. (If you leave the End Date blank, the campaign will run until its budget is depleted.)"
        />
    </TooltipSection>
);

export default TimeFrameTooltip;
