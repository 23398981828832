import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

export const sendTitleOnGenerateGA = ({
    generateType,
    productDescription,
    targetAudience,
    uniqueSellingProposition,
    campaignId,
}) => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: `AIContentTitleSuggestions - ${generateType} clicked`,
        value: `Product Description: ${productDescription}, Target Audience: ${targetAudience}, Unique Selling Proposition: ${uniqueSellingProposition}`,
        taboolaCampaignId: campaignId,
    });
};

export const sendDescriptionOnGenerateGA = ({
    generateType,
    productDescription,
    targetAudience,
    uniqueSellingProposition,
    campaignId,
}) => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: `AIContentDescriptionSuggestions - ${generateType} clicked`,
        value: `Product Description: ${productDescription}, Target Audience: ${targetAudience}, Unique Selling Proposition: ${uniqueSellingProposition}`,
        taboolaCampaignId: campaignId,
    });
};
