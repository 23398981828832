import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';
import { useThirdPartyTagsConfig } from '../config';
import styles from './helpTooltip.module.scss';

const HelpTooltip = () => {
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();

    return (
        <div className={styles['container']}>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.help.overview`}
                    defaultMessage="Common use cases for 3rd party tags include:"
                />
            </TooltipSection>
            <TooltipSection>
                <ul className={styles['bullet-container']}>
                    <li>
                        <FormattedMessage
                            id={`${MSG_ID_PREFIX}.help.bullet.retargeting`}
                            defaultMessage="Retargeting users engaging with your Taboola ad in other platforms."
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={`${MSG_ID_PREFIX}.help.bullet.verification`}
                            defaultMessage="Verification of Taboola reporting."
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id={`${MSG_ID_PREFIX}.help.bullet.brand`}
                            defaultMessage="Brand safety and viewability measurement."
                        />
                    </li>
                </ul>
            </TooltipSection>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.help.bullet.note`}
                    defaultMessage="<strong>Note:</strong> This is not the place to insert your landing page URL or Taboola pixel."
                />
            </TooltipSection>
        </div>
    );
};

export default HelpTooltip;
