import React from 'react';
import PropTypes from 'prop-types';
import { useSpendingLimitValidations } from 'modules/campaigns/modules/common-campaign-form/components/Budget/SpendingLimit/useSpendingLimitValidations';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import SimpleCurrencyCellEditor from './SimpleCurrencyCellEditor';

const SpendingLimitCellEditor = props => {
    const { data } = props;
    const spendingLimitType = `${data?.budget?.spendingLimit?.type}`.toLowerCase();
    const validations = useSpendingLimitValidations(spendingLimitType, data);
    return <SimpleCurrencyCellEditor {...props} {...validations} />;
};

SpendingLimitCellEditor.propTypes = {
    data: PropTypes.object,
};

export default withGridCellFormDataProvider(SpendingLimitCellEditor);
