import { createSelector } from 'reselect';
import { useSelectedAccountId } from 'hooks/queryParams/useSelectedAccountId';
import { accountsMapSelector, transformAccountObject, createDeepEqualSelector } from './baseSelectors';

/**
 * @deprecated - use useSelectedAccountId
 */
export const selectedAccountIdSelector = useSelectedAccountId.createSelector();

export const selectedAccountSelectorRaw = createSelector(
    selectedAccountIdSelector,
    accountsMapSelector,
    // fallback accountId for the first init of the app, when selectedAccount is derived from the url query string.
    (selectedAccountId, accountsMap) => accountsMap[selectedAccountId] || { accountId: selectedAccountId }
);

export const selectedAccountPartnerTypesSelector = createSelector(
    selectedAccountSelectorRaw,
    selectedAccountObj => selectedAccountObj.partnerTypes
);

/**
 * @deprecated - use useSelectedAccount
 */
export const selectedAccountSelector = createDeepEqualSelector(selectedAccountSelectorRaw, selectedAccountObj =>
    transformAccountObject(selectedAccountObj)
);

export const selectedAccountTypeSelector = createSelector(
    selectedAccountSelector,
    selectedAccountObj => selectedAccountObj.type
);

export default selectedAccountSelector;
