import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useCreativesApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { defaultCropData } from '../config/defaultCropData';

export const useCropDataInit = ({ src, ratios, setInitialCropData, setOutcroppingStatus }) => {
    const { onChange: setCropData } = useFormFieldValue({
        field: 'cropData',
    });

    const { setFetchStatus } = useFormDataContext();

    const { getCropAreaSize } = useCreativesApi();

    const { data: cloudinaryData = [], isLoading } = useQuery(
        ['cropAreaSize', src],
        () => Promise.all(getCropAreaSize(src, ratios)),
        {
            enabled: !!src,
        }
    );

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const processedCropData = cloudinaryData.map(({ resize }, index) => ({
            ...defaultCropData[index],
            croppedAreaPixels: resize?.[0],
        }));

        setCropData(processedCropData);
        setInitialCropData(processedCropData);

        setOutcroppingStatus(COMPONENT_STATUS.ACTIVE);

        setFetchStatus(COMPONENT_STATUS.ACTIVE);
    }, [cloudinaryData, setCropData, setInitialCropData, setOutcroppingStatus, src, isLoading, setFetchStatus]);
};
