import { useMemo } from 'react';
import useBreadcrumbsAccountStep, { MAX_CHARACTER_COUNT } from 'hooks/useBreadcrumbsAccountStep';
import { REPORT_ID, useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { MODULE_NAME, PERFORMANCE_RULES_ROUTE } from 'modules/performance-rules/config/routes';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';

export const usePerformanceRulesBreadcrumbs = ({ selectedReport: coerceReport, title, isTitleAsLastStep = true }) => {
    const [reportId] = useSelectedReportId();
    const selectedReport = coerceReport || reportId;
    const accountSteps = useBreadcrumbsAccountStep(PERFORMANCE_RULES_ROUTE);

    const { formatMessage } = useIntl();
    const performanceRulesStepLabel = formatMessage({
        id: 'navigation.caption.performance.rules',
        defaultMessage: 'Rules',
    });
    const steps = useMemo(() => {
        const allPerformanceRulesStep = {
            label: performanceRulesStepLabel,
            path: isTitleAsLastStep ? MODULE_NAME : null,
            params: { [REPORT_ID]: selectedReport },
            maxCharacterCount: MAX_CHARACTER_COUNT,
        };
        const steps = [...accountSteps, allPerformanceRulesStep];

        if (isTitleAsLastStep) {
            steps.push({ label: title, maxCharacterCount: MAX_CHARACTER_COUNT });
        }
        return steps;
    }, [accountSteps, isTitleAsLastStep, selectedReport, title, performanceRulesStepLabel]);

    return {
        steps,
    };
};
