import { createSelector } from 'reselect';
import { permissionsSelector } from '../modules/taboola-common-frontend-modules/authentication/selectors';
import { isSponsoredCampaign } from '../services/campaignDimension';
import dimensionSelector from './dimensionSelector';
import { selectedAccountPartnerTypesSelector } from './selectedAccount';

const allCampaignsNameIdSelector = createSelector(
    dimensionSelector,
    selectedAccountPartnerTypesSelector,
    permissionsSelector,
    (dimension, partnerTypes, permissions) => getAllCampaignsNameId(dimension, partnerTypes, permissions)
);

const ALL_CAMPAIGNS_PROPERTIES = { nameId: 'app.campaigns.allAccounts', name: 'All Campaigns' };
const NATIVE_CAMPAIGNS_PROPERTIES = { nameId: 'app.campaigns.allNativeAccounts', name: 'Native Campaigns' };
const VIDEO_CAMPAIGNS_PROPERTIES = { nameId: 'app.campaigns.allVideoAccounts', name: 'Video Campaigns' };

const getAllCampaignsProperties = (dimension, partnerTypes, permissions = {}) => {
    if (!permissions?.['ADS_CONSOLE_VIDEO_CAMPAIGNS'] || partnerTypes?.length <= 1) {
        return ALL_CAMPAIGNS_PROPERTIES;
    }

    return isSponsoredCampaign(dimension) ? NATIVE_CAMPAIGNS_PROPERTIES : VIDEO_CAMPAIGNS_PROPERTIES;
};

export const getAllCampaignsNameId = (dimension, partnerTypes, permissions) =>
    getAllCampaignsProperties(dimension, partnerTypes, permissions).nameId;
export const getAllCampaignsName = (dimension, partnerTypes, permissions) =>
    getAllCampaignsProperties(dimension, partnerTypes, permissions).name;

export default allCampaignsNameIdSelector;
