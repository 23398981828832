'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactVirtual = require('react-virtual');

var _lodash = require('lodash.isempty');

var _lodash2 = _interopRequireDefault(_lodash);

var _MoreAndLessButton = require('./MoreAndLessButton/MoreAndLessButton');

var _MoreAndLessButton2 = _interopRequireDefault(_MoreAndLessButton);

var _Icons = require('../Icons/Icons');

var _CollapsibleListContext = require('./CollapsibleListContext');

var _ListHeader = require('./ListHeader/ListHeader');

var _ListFooter = require('./ListFooter/ListFooter');

var _Indications = require('../Indications');

var _useShouldMemoizeValue = require('../../hooks/useShouldMemoizeValue');

var _useShouldMemoizeValue2 = _interopRequireDefault(_useShouldMemoizeValue);

var _useSearchableList2 = require('./useSearchableList');

var _DragAndDrop = require('../DragAndDrop');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'collapsibleList-module__taboola-svg-icon___e1gj-',
    'container': 'collapsibleList-module__container___3et6D',
    'error': 'collapsibleList-module__error___19YZq',
    'warning': 'collapsibleList-module__warning___lpNcC',
    'list-wrapper': 'collapsibleList-module__list-wrapper___2yhJK',
    'list': 'collapsibleList-module__list___UeE0o',
    'collapsed': 'collapsibleList-module__collapsed___3aMu5',
    'virtual-item': 'collapsibleList-module__virtual-item___1poFI',
    'with-button': 'collapsibleList-module__with-button___3aqLF',
    'absolute-item': 'collapsibleList-module__absolute-item___3vnd3',
    'no-results-message': 'collapsibleList-module__no-results-message___2DefX'
};


var classNameBuilder = _bind2.default.bind(styles);

var borderWidth = 1;

var labelFieldExtractor = function labelFieldExtractor(item) {
    return item && item.label;
};

var CollapsibleList = function CollapsibleList(_ref) {
    var items = _ref.items,
        onReorder = _ref.onReorder,
        passedTotalItems = _ref.totalItems,
        itemHeight = _ref.itemHeight,
        ItemComponent = _ref.ItemComponent,
        clearItems = _ref.clearItems,
        addItem = _ref.addItem,
        updateItem = _ref.updateItem,
        deleteItem = _ref.deleteItem,
        metadata = _ref.metadata,
        isCollapsible = _ref.isCollapsible,
        collapsedItemsLimit = _ref.collapsedItemsLimit,
        expandedItemsLimit = _ref.expandedItemsLimit,
        listHeaderClass = _ref.listHeaderClass,
        containerClassName = _ref.containerClassName,
        showAllLabel = _ref.showAllLabel,
        showLessLabel = _ref.showLessLabel,
        clearAllLabel = _ref.clearAllLabel,
        clearAllLabelTooltip = _ref.clearAllLabelTooltip,
        listHeaderTitle = _ref.listHeaderTitle,
        indicationType = _ref.indicationType,
        copyToClipboard = _ref.copyToClipboard,
        copyToClipboardLabel = _ref.copyToClipboardLabel,
        copyToClipboardLabelTooltip = _ref.copyToClipboardLabelTooltip,
        exportToFile = _ref.exportToFile,
        exportToFileTooltip = _ref.exportToFileTooltip,
        _ref$isSearchable = _ref.isSearchable,
        isSearchable = _ref$isSearchable === undefined ? false : _ref$isSearchable,
        _ref$searchFieldExtra = _ref.searchFieldExtractor,
        searchFieldExtractor = _ref$searchFieldExtra === undefined ? labelFieldExtractor : _ref$searchFieldExtra,
        _ref$noResultsFoundMs = _ref.noResultsFoundMsg,
        noResultsFoundMsg = _ref$noResultsFoundMs === undefined ? 'No results found' : _ref$noResultsFoundMs,
        _ref$isDraggable = _ref.isDraggable,
        isDraggable = _ref$isDraggable === undefined ? false : _ref$isDraggable,
        listFooterTitle = _ref.listFooterTitle,
        listFooterClass = _ref.listFooterClass,
        showTotal = _ref.showTotal,
        rest = _objectWithoutProperties(_ref, ['items', 'onReorder', 'totalItems', 'itemHeight', 'ItemComponent', 'clearItems', 'addItem', 'updateItem', 'deleteItem', 'metadata', 'isCollapsible', 'collapsedItemsLimit', 'expandedItemsLimit', 'listHeaderClass', 'containerClassName', 'showAllLabel', 'showLessLabel', 'clearAllLabel', 'clearAllLabelTooltip', 'listHeaderTitle', 'indicationType', 'copyToClipboard', 'copyToClipboardLabel', 'copyToClipboardLabelTooltip', 'exportToFile', 'exportToFileTooltip', 'isSearchable', 'searchFieldExtractor', 'noResultsFoundMsg', 'isDraggable', 'listFooterTitle', 'listFooterClass', 'showTotal']);

    (0, _useShouldMemoizeValue2.default)({
        value: itemHeight,
        message: 'You should use memoized itemHeight within CollapsibleList to avoid performance issues'
    });
    var virtualParentRef = (0, _react.useRef)();

    var _useState = (0, _react.useState)(true),
        _useState2 = _slicedToArray(_useState, 2),
        collapsed = _useState2[0],
        setCollapsed = _useState2[1];

    var getItemHeight = (0, _react.useCallback)(function (item) {
        return typeof itemHeight === 'function' ? itemHeight(item) : itemHeight;
    }, [itemHeight]);
    var unfilteredItemsCount = items.length;
    var noItems = unfilteredItemsCount === 0;

    var _useSearchableList = (0, _useSearchableList2.useSearchableList)({
        items: items,
        searchFieldExtractor: searchFieldExtractor
    }),
        filteredItems = _useSearchableList.filteredItems,
        searchText = _useSearchableList.searchText,
        setSearchText = _useSearchableList.setSearchText,
        isAllItemsFiltered = _useSearchableList.isAllItemsFiltered;

    var totalItems = passedTotalItems || filteredItems.length;
    var showToggleButton = isCollapsible && totalItems > collapsedItemsLimit;
    var listViewPortHeight = (0, _react.useMemo)(function () {
        if (!isCollapsible) {
            return 'max-content';
        }
        var visibleItemsLimit = Math.min(collapsed ? collapsedItemsLimit : expandedItemsLimit, totalItems);
        var fixedListHeight = filteredItems.slice(0, visibleItemsLimit).reduce(function (sum, item) {
            return sum + getItemHeight(item);
        }, 0) + borderWidth;
        return fixedListHeight;
    }, [collapsed, filteredItems, collapsedItemsLimit, expandedItemsLimit, getItemHeight, isCollapsible, totalItems]);

    var itemVirtualizer = (0, _reactVirtual.useVirtual)({
        parentRef: virtualParentRef,
        size: totalItems,
        estimateSize: (0, _react.useCallback)(function (index) {
            return getItemHeight(filteredItems[index]);
        }, [getItemHeight, filteredItems])
    });

    var toggleCollapsed = (0, _react.useCallback)(function () {
        setCollapsed(!collapsed);
        if (!collapsed && virtualParentRef.current) {
            virtualParentRef.current.scrollTop = 0;
        }
    }, [collapsed]);
    // On delete last expanded item we should collapse list
    (0, _react.useEffect)(function () {
        if (unfilteredItemsCount <= collapsedItemsLimit && !collapsed) {
            setCollapsed(true);
        }
    }, [collapsedItemsLimit, unfilteredItemsCount, collapsed]);

    var listWrapperStyle = (0, _react.useMemo)(function () {
        return { height: listViewPortHeight };
    }, [listViewPortHeight]);
    var listStyle = (0, _react.useMemo)(function () {
        return { height: itemVirtualizer.totalSize };
    }, [itemVirtualizer.totalSize]);

    if (noItems) {
        return null;
    }

    var onDragEnd = function onDragEnd(result) {
        if ((0, _DragAndDrop.shouldSkipDragEvent)(result)) {
            return;
        }
        var orderItems = (0, _DragAndDrop.getDragEndData)(filteredItems, result);
        onReorder(orderItems);
    };

    var renderDraggableItem = function renderDraggableItem(_ref2) {
        var index = _ref2.index;
        return _react2.default.createElement(
            _DragAndDrop.Draggable,
            {
                draggableId: String(filteredItems[index].id),
                index: index,
                key: filteredItems[index].id
            },
            function (provided) {
                return _react2.default.createElement(
                    'li',
                    Object.assign({
                        className: classNameBuilder('virtual-item'),
                        ref: provided.innerRef
                    }, provided.draggableProps, {
                        style: Object.assign({}, provided.draggableProps.style, itemVirtualizer.virtualItems[index].style, {
                            height: itemVirtualizer.virtualItems[index].size
                        })
                    }),
                    _react2.default.createElement(
                        'span',
                        provided.dragHandleProps,
                        _react2.default.createElement(_Icons.DragIndicatorIcon, null)
                    ),
                    _react2.default.createElement(ItemComponent, Object.assign({
                        index: index,
                        item: filteredItems[index],
                        deleteItem: deleteItem
                    }, metadata))
                );
            }
        );
    };

    var noResultsFound = _react2.default.createElement(
        'div',
        { className: styles['no-results-message'] },
        noResultsFoundMsg
    );

    var renderItem = function renderItem(_ref3) {
        var index = _ref3.index,
            size = _ref3.size,
            start = _ref3.start;
        return _react2.default.createElement(
            'li',
            {
                key: filteredItems[index].id,
                className: classNameBuilder('virtual-item', 'absolute-item', {
                    'with-button': showToggleButton
                }),
                style: {
                    height: size,
                    top: start
                }
            },
            isAllItemsFiltered ? noResultsFound : _react2.default.createElement(ItemComponent, Object.assign({
                index: index,
                item: filteredItems[index],
                searchText: searchText,
                deleteItem: deleteItem
            }, metadata))
        );
    };

    return _react2.default.createElement(
        _CollapsibleListContext.CollapsibleListProvider,
        {
            addItem: addItem,
            updateItem: updateItem,
            deleteItem: deleteItem,
            items: filteredItems,
            onReorder: onReorder,
            metadata: metadata
        },
        _react2.default.createElement(
            'div',
            Object.assign({}, rest, {
                className: classNameBuilder('container', containerClassName, _defineProperty({}, indicationType, !!indicationType))
            }),
            _react2.default.createElement(_ListHeader.ListHeader, {
                listHeaderTitle: listHeaderTitle,
                showTotal: showTotal,
                listHeaderClass: listHeaderClass,
                clearItems: clearItems,
                clearAllLabel: clearAllLabel,
                clearAllLabelTooltip: clearAllLabelTooltip,
                totalItems: isAllItemsFiltered ? 0 : totalItems,
                copyToClipboard: copyToClipboard,
                copyToClipboardLabel: copyToClipboardLabel,
                copyToClipboardLabelTooltip: copyToClipboardLabelTooltip,
                isSearchable: isSearchable,
                searchText: searchText,
                setSearchText: setSearchText,
                exportToFile: exportToFile,
                exportToFileTooltip: exportToFileTooltip
            }),
            _react2.default.createElement(
                'div',
                {
                    ref: virtualParentRef,
                    className: classNameBuilder('list-wrapper', { collapsed: collapsed }),
                    style: listWrapperStyle
                },
                _react2.default.createElement(
                    _DragAndDrop.DragDropContext,
                    { onDragEnd: onDragEnd },
                    _react2.default.createElement(
                        _DragAndDrop.Droppable,
                        { droppableId: 'list' },
                        function (provided) {
                            return _react2.default.createElement(
                                'ul',
                                Object.assign({}, provided.droppableProps, {
                                    ref: provided.innerRef,
                                    className: classNameBuilder(styles['list']),
                                    style: { listStyle: listStyle, overflow: 'auto' }
                                }),
                                itemVirtualizer.virtualItems.map(function (_ref4) {
                                    var index = _ref4.index,
                                        size = _ref4.size,
                                        start = _ref4.start;
                                    return isDraggable && (0, _lodash2.default)(searchText.trim()) ? renderDraggableItem({ index: index }) : renderItem({
                                        index: index,
                                        size: size,
                                        start: start
                                    });
                                }),
                                provided.placeholder
                            );
                        }
                    )
                )
            ),
            showToggleButton && _react2.default.createElement(_MoreAndLessButton2.default, {
                showAll: !collapsed,
                onClick: toggleCollapsed,
                showAllLabel: showAllLabel,
                showLessLabel: showLessLabel,
                isCollapsible: true,
                totalItems: totalItems
            }),
            _react2.default.createElement(_ListFooter.ListFooter, {
                listFooterClass: listFooterClass,
                listFooterTitle: listFooterTitle
            })
        )
    );
};

CollapsibleList.propTypes = {
    /** Initial list of items */
    items: _propTypes2.default.array,
    /** Total items count if not provided */
    totalItems: _propTypes2.default.number,
    /** Item renderer */
    ItemComponent: _propTypes2.default.func,
    /** Callback on copyToClipboardLabel */
    copyToClipboard: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    clearItems: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    addItem: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    updateItem: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    deleteItem: _propTypes2.default.func,
    /** Fires when there is a change in the list order when draggable item */
    onReorder: _propTypes2.default.func,
    /** Additional metadata associated with the list */
    metadata: _propTypes2.default.object,
    /** If true, items over collapsedItemsLimit are hidden until button is clicked */
    isCollapsible: _propTypes2.default.bool,
    /** Item height in px to calculate container height when collapsed or expanded */
    itemHeight: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.func]),
    /** number of items to show by default, adds button at bottom of list to show all the items */
    collapsedItemsLimit: _propTypes2.default.number,
    /** number of items to show when expanded */
    expandedItemsLimit: _propTypes2.default.number,
    /** List header class */
    listHeaderClass: _propTypes2.default.string,
    /** Container class name */
    containerClassName: _propTypes2.default.string,
    /** styling of 'show' button */
    showButtonClass: _propTypes2.default.string,
    /** List header title */
    listHeaderTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** showAll label */
    showAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** showLess label */
    showLessLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Clear all text */
    clearAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Clear all tooltip text */
    clearAllLabelTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Copy label tolltip text */
    copyToClipboardLabelTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Copy text */
    copyToClipboardLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Indication type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES)),
    /** Item props */
    itemProps: _propTypes2.default.object,
    /** If true, the list is searchable */
    isSearchable: _propTypes2.default.bool,
    /** Function to extract the search field from an item */
    searchFieldExtractor: _propTypes2.default.func,
    /** Message to display when no search results are found */
    noResultsFoundMsg: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** If true, items can be dragged and reordered */
    isDraggable: _propTypes2.default.bool,
    /** List footer title */
    listFooterTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** List footer class */
    listFooterClass: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Function that exports the items content to a file */
    exportToFile: _propTypes2.default.func,
    /** Tooltip text or node for the export button */
    exportToFileTooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Decide if you want to show the total selected number */
    showTotal: _propTypes2.default.bool
};

CollapsibleList.defaultProps = {
    items: [],
    isCollapsible: true,
    itemHeight: 48,
    collapsedItemsLimit: 10,
    expandedItemsLimit: 15,
    listHeaderTitle: 'Selected options',
    clearAllLabel: 'Clear All',
    showAllLabel: 'Show All',
    showLessLabel: 'Show Less',
    metadata: {}
};

exports.default = CollapsibleList;