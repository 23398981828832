import { updatePerformanceRule } from 'modules/performance-rules/flows/updatePerformanceRule';
import { selectedAccountSelector } from '../../../../../selectors';

export const prepareRollBackData = (rowIndex, oldValues, reportData) => [
    ...reportData.slice(0, rowIndex),
    { ...reportData[rowIndex], ...oldValues },
    ...reportData.slice(rowIndex + 1),
];

export const savePerformanceRule =
    (ruleChanges, rowData, { onError, onSuccess } = {}) =>
    (dispatch, getState) => {
        const { accountName, id } = rowData;
        const { accountId } = selectedAccountSelector(getState());

        return dispatch(
            updatePerformanceRule({
                accountName: accountName || accountId,
                changes: ruleChanges,
                ruleId: id,
                onError,
                onSuccess,
            })
        );
    };
