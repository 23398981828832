import { useCallback, useMemo, useState } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignLevelConversionsApi } from 'services/api/campaignLevelConversionsApi';

export const useAccountConversions = () => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { getAccountLevelConversionRulesData } = useCampaignLevelConversionsApi();
    const [rawOptions, setOptions] = useState([]);
    const { formatMessage } = useIntl();

    const options = useMemo(
        () =>
            rawOptions.map(rule => {
                if (!rule) {
                    return {};
                }

                const { ruleCategory, apiUnipRuleConfiguration } = rule;
                const { id, displayName: conversionName } = apiUnipRuleConfiguration;
                const formattedRuleCategory = formatMessage({
                    id: `tracking.conversions.report.category.${ruleCategory}`,
                    defaultMessage: ruleCategory,
                });
                const label = `${conversionName} (${formattedRuleCategory})`;
                return {
                    id,
                    displayName: conversionName,
                    ruleCategory,
                    value: id,
                    label,
                };
            }),
        [rawOptions, formatMessage]
    );
    const loadConversionRulesData = useCallback(
        (accountId, page, pageSize, searchText) => {
            return getAccountLevelConversionRulesData(accountId, page, pageSize, searchText);
        },
        [getAccountLevelConversionRulesData]
    );
    const loadConversionRules = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await loadConversionRulesData(accountId, page, pageSize, searchText);
            return [results, total, results.length];
        },
        [loadConversionRulesData, accountId]
    );

    return { options, loadConversionRules, setOptions };
};
