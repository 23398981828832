import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './displayNotificationDetails.module.scss';

const classNameBuilder = classnames.bind(styles);

export const DisplayNotificationDetails = ({ id, values, isInactive }) => (
    <div className={classNameBuilder('display-notification-details-container', { 'is-inactive': isInactive })}>
        <FormattedMessage id={id} values={values} />
    </div>
);
