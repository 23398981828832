import {
    REQUEST_FETCH_AUDIENCE_FOR_EDIT,
    FETCH_AUDIENCE_FOR_EDIT_ERROR,
    FETCH_AUDIENCE_FOR_EDIT_SUCCESS,
} from './actionTypes';

export const requestFetchAudienceForEdit = () => ({ type: REQUEST_FETCH_AUDIENCE_FOR_EDIT });

export const fetchAudienceForEditSuccess = audience => ({ type: FETCH_AUDIENCE_FOR_EDIT_SUCCESS, payload: audience });

export const fetchAudienceForEditError = error => ({ type: FETCH_AUDIENCE_FOR_EDIT_ERROR, payload: error });
