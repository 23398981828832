import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button, AutoAwesomeOutlinedIcon, EditOutlinedIcon } from 'tuui';
import { pxToRem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { getHasPageProductDetails, useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { useHasAiWizardOnboarding } from '../../../AiVariationsContextProvider/hooks/useHasAiWizardOnboarding';
import styles from './helpWriteButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const HelpWriteButton = ({ className, size, variant, onClick }) => {
    const hasAiWizardOnboarding = useHasAiWizardOnboarding();
    const data = useAiVariationsContext();
    const hasPageProductDetails = getHasPageProductDetails(data);
    if (!hasAiWizardOnboarding) {
        return null;
    }
    return hasPageProductDetails ? (
        <div className={classNameBuilder('help-text', 'no-button')} onClick={onClick}>
            <HelpWriteText />
        </div>
    ) : (
        <Button size={size} variant={variant} className={classNameBuilder('button', className)} onClick={onClick}>
            <div className={styles['help-text']}>
                <HelpWriteText />
            </div>
        </Button>
    );
};

export const HelpWriteText = () => {
    const data = useAiVariationsContext();
    const hasPageProductDetails = getHasPageProductDetails(data);
    return (
        <div className={styles['text-container']}>
            {hasPageProductDetails ? (
                <>
                    <EditOutlinedIcon sx={{ fontSize: pxToRem('13px') }} className={classNameBuilder('icon', 'edit')} />
                    <FormattedMessage id="creative.creator.aiTitleSuggestions.edit" defaultMessage="Edit AI Wizard" />
                </>
            ) : (
                <>
                    <AutoAwesomeOutlinedIcon
                        sx={{ fontSize: pxToRem('13px') }}
                        className={classNameBuilder('icon', 'stars')}
                    />
                    <FormattedMessage id="creative.creator.aiTitleSuggestions.help" defaultMessage="Help Me Write" />
                </>
            )}
        </div>
    );
};

HelpWriteButton.propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
};

HelpWriteButton.defaultProps = {
    size: Button.size.md,
    variant: Button.variant.outline,
};
