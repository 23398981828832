import React from 'react';
import classnames from 'classnames/bind';
import { ErrorOutlinedIcon, ProgressStepper, Step } from 'tuui';
import { Divider } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useCvrLearningProgressState } from './useCvrLearningProgressState';
import styles from './cvrLearningProgressTooltip.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CvrLearningProgressTooltip = ({
    phase,
    phaseUpdateTime,
    totalConversions,
    phaseMsgIdPrefix,
    isCvrRelearning,
}) => {
    const { allSteps, activeStepIndex, progress, titleMessageId, titleMessageValue, activeStepType } =
        useCvrLearningProgressState({
            phase,
            phaseUpdateTime,
            totalConversions,
            isCvrRelearning,
        });
    const { formatMessage } = useIntl();

    return (
        <div className={styles['tooltip-container']}>
            <div className={styles['tooltip-header']}>
                <FormattedMessage id={`${phaseMsgIdPrefix}.cvrLearningTooltip.title`} />
            </div>
            <ProgressStepper
                activeStepIndex={activeStepIndex}
                progress={progress}
                progressTitle={
                    titleMessageId &&
                    formatMessage(
                        {
                            id: titleMessageId,
                        },
                        titleMessageValue
                    )
                }
                activeStepType={activeStepType}
                hasTail={isCvrRelearning}
            >
                {allSteps.map((currPhase, index) => (
                    <Step
                        key={index}
                        title={formatMessage({ id: `${phaseMsgIdPrefix}.cvrLearningTooltip.step.${currPhase}` })}
                    />
                ))}
            </ProgressStepper>
            <Divider className={styles['divider']} />
            {activeStepType !== ProgressStepper.activeStepType.regular ? (
                <div className={styles['tooltip-alert']} role="alert">
                    <div>
                        <ErrorOutlinedIcon className={classNameBuilder('alert-icon', activeStepType)} />
                    </div>
                    <div className={styles['alert-content']}>
                        <FormattedMessage id={`${phaseMsgIdPrefix}.cvrLearningTooltip.alert.${phase}`} />
                    </div>
                </div>
            ) : null}
            <div className={styles['tooltip-description-title']}>
                <FormattedMessage id={`${phaseMsgIdPrefix}.cvrLearningTooltip.step.${phase}`} />
            </div>
            <div className={styles['tooltip-description']}>
                <FormattedMessage id={`${phaseMsgIdPrefix}.cvrLearningTooltip.description.${phase}`} />
            </div>
        </div>
    );
};
