import React, { useCallback } from 'react';
import { useAvailableItems } from 'hooks';
import PlatformTargetingDescription from 'modules/campaigns/modules/common-campaign-form/components/PlatformTargeting/PlatformTargetingDescription';
import { MARKETING_OBJECTIVE_KEYS, PLATFORMS } from 'modules/campaigns/modules/common-campaign-form/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

const usePlatformTargeting = (platforms, validations) => {
    const {
        value: platformTargeting,
        onChange: setPlatformTargeting,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'platformTargeting',
        validations,
    });

    const { value: marketingObjective } = useFormFieldValue({
        field: 'marketingObjective',
    });

    const handleSelectValue = useCallback(
        value => {
            const updatedPlatformTargeting = [...platformTargeting, value].sort();
            setPlatformTargeting(updatedPlatformTargeting);
        },
        [platformTargeting, setPlatformTargeting]
    );

    const handleUnselectValue = useCallback(
        value => setPlatformTargeting(platformTargeting.filter(val => val !== value)),
        [platformTargeting, setPlatformTargeting]
    );

    const platformInfoList = useAvailableItems(platforms, { marketingObjective });

    const setPlatformByMarketingObjective = useCallback(() => {
        if (marketingObjective === MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL) {
            setPlatformTargeting([PLATFORMS.MOBILE]);
        }
    }, [marketingObjective, setPlatformTargeting]);

    const getPlatformTargetingDescription = () => (
        <PlatformTargetingDescription selectedMarketingObjective={marketingObjective} />
    );

    return {
        formValidatedValueData: { platformTargeting, indicationData, scrollRef },
        platformInfoList,
        handleSelectValue,
        handleUnselectValue,
        setPlatformByMarketingObjective,
        getPlatformTargetingDescription,
    };
};

export default usePlatformTargeting;
