import { useEffect, useState } from 'react';

const FETCH_STATUS = {
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
};

export const useLoadScript = src => {
    const [status, setStatus] = useState(FETCH_STATUS.LOADING);

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.src = src;
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.onload = () => {
            setStatus(FETCH_STATUS.SUCCESS);
        };
        scriptTag.onerror = () => {
            setStatus(FETCH_STATUS.ERROR);
        };

        document.body.appendChild(scriptTag);
        setStatus(FETCH_STATUS.LOADING);

        return () => {
            document.body.removeChild(scriptTag);
        };
    }, [src]);

    return {
        isSuccess: status === FETCH_STATUS.SUCCESS,
        isLoading: status === FETCH_STATUS.LOADING,
        isError: status === FETCH_STATUS.ERROR,
    };
};
