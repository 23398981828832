import { useCallback } from 'react';
import { identity } from 'lodash';
import { INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

export const useSingleFieldCellEditorData = ({
    fieldPath,
    colDef,
    validations,
    validationDependencies,
    parseInputValue = identity,
    onSave,
    // Next props is going to be deprecated use validationDependencies instead
    validationContext,
    hasInitialData,
    formFieldDependencies,
}) => {
    const { field } = colDef;
    const {
        value,
        onChange,
        triggerValidationEvent,
        failedValidationData: { message, indicationType },
        isValidationsRunning,
        isDirty,
    } = useFormValidatedValue({
        field: fieldPath || field,
        validations,
        validationDependencies: validationDependencies || validationContext,
        hasInitialData,
        formFieldDependencies,
    });
    const hasError = indicationType === INDICATION_TYPES.ERROR;
    const changeHandler = useCallback(
        newValue => onChange(prevValue => parseInputValue(newValue, prevValue)),
        [onChange, parseInputValue]
    );
    const submitHandler = useCallback(() => onSave(value), [value, onSave]);

    return {
        id: 'cell-editor-input',
        value,
        onChange: changeHandler,
        message,
        indicationType,
        hasError,
        submitHandler,
        triggerValidationEvent,
        isValidationsRunning,
        isDirty,
        failedValidationData: { message, indicationType },
    };
};
