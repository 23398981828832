import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { MARKETPLACE_SEGMENTS_API_CACHE_KEY } from 'services/api';
import { FILTERS, PAGINATION } from 'services/api/apiConstants';
import { useAudiencesApi } from '../../../../../../../services/api/audiencesApi';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { DEFAULT_PAGE_SIZE } from '../../Tree';
import { useIsMarketplaceAudiencesAndLogicEnabled } from './useIsMarketplaceAudiencesAndLogicEnabled';

const fetchSegmentsApi = ({ selectedAccountId, queryParams, options, getMarketplaceSegments }) => {
    const accountId = selectedAccountId || -1;

    return getMarketplaceSegments(accountId, queryParams, options);
};

const getNextPage = (currentCountPerTaxonomy, loadingPaths, taxonomy, pageSize) => {
    // case of initial load
    if (isEmpty(currentCountPerTaxonomy) || pageSize === 0) {
        return 1;
    }

    const currentTotal = currentCountPerTaxonomy[taxonomy] || 0;
    const loadingTotal = loadingPaths[taxonomy] || 0;
    const page = Math.ceil((currentTotal + loadingTotal * pageSize) / pageSize);
    return page + 1;
};

function hasMoreSegments(
    currentCountPerTaxonomy,
    loadingPaths,
    totalsPerTaxonomy,
    taxonomy,
    pageSize = DEFAULT_PAGE_SIZE
) {
    const total = totalsPerTaxonomy[taxonomy] || 0;
    const loadingTotal = loadingPaths[taxonomy] || 0;
    const currentTotal = currentCountPerTaxonomy[taxonomy] || 0;
    return total > currentTotal + loadingTotal * pageSize;
}

const transformTotals = (totalsPerTaxonomy = []) =>
    totalsPerTaxonomy.reduce((obj, item) => ({ ...obj, [item.taxonomy]: item.total }), {});

const useSegmentsFetcher = ({ search, providers, selectedAccountId, chargeType }) => {
    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();
    const { value: countryTargetingType = TARGETING_TYPES.ALL } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });
    const { getMarketplaceSegments } = useAudiencesApi();
    const fetchSegments = useCallback(
        async ({
            currentCountPerTaxonomy = {},
            loadingPaths = {},
            totalsPerTaxonomy = {},
            taxonomies = [],
            pageSize = DEFAULT_PAGE_SIZE,
            ids,
        }) => {
            const taxonomiesToFetch = taxonomies.filter(taxonomy =>
                hasMoreSegments(currentCountPerTaxonomy, loadingPaths, totalsPerTaxonomy, taxonomy, pageSize)
            );
            // flow of initial load
            if (!isEmpty(taxonomies) && isEmpty(taxonomiesToFetch)) {
                return { results: [], transformedTotals: totalsPerTaxonomy };
            }

            const nextPage = getNextPage(currentCountPerTaxonomy, loadingPaths, taxonomiesToFetch, pageSize);

            const queryParams = {
                [FILTERS.SEARCH]: search,
                providers,
                taxonomies: taxonomiesToFetch,
                countryTargetingType,
                countryCodes,
                chargeType,
            };

            if (pageSize > 0) {
                queryParams[PAGINATION.PAGE_SIZE] = pageSize;
                queryParams[PAGINATION.PAGE] = nextPage;
            }

            const queryParamsWithIds = {
                countryTargetingType,
                countryCodes,
                ids,
            };

            const fetchSegmentsQueryParams = ids ? queryParamsWithIds : queryParams;
            const fetchSegmentsOptions = {
                singleRequestCacheKey: ids ? MARKETPLACE_SEGMENTS_API_CACHE_KEY : null,
                allowMultiRequestCache: isAndLogicEnabled,
            };

            let results = [];
            let metadata = [];

            try {
                const response = await fetchSegmentsApi({
                    selectedAccountId,
                    queryParams: fetchSegmentsQueryParams,
                    options: fetchSegmentsOptions,
                    getMarketplaceSegments,
                });
                results = response.results;
                metadata = response.metadata;
            } catch (e) {
                // empty
            }
            return { results, transformedTotals: transformTotals(metadata) };
        },
        [
            search,
            providers,
            selectedAccountId,
            countryTargetingType,
            countryCodes,
            chargeType,
            isAndLogicEnabled,
            getMarketplaceSegments,
        ]
    );

    const fetchSegmentsByIds = useCallback(
        async ids => {
            let results = [];
            try {
                const queryParams = {
                    countryTargetingType,
                    countryCodes,
                    ids,
                };
                const options = {
                    allowMultiRequestCache: isAndLogicEnabled,
                    singleRequestCacheKey: MARKETPLACE_SEGMENTS_API_CACHE_KEY,
                };

                const response = await fetchSegmentsApi({
                    selectedAccountId,
                    queryParams,
                    options,
                    getMarketplaceSegments,
                });
                results = response.results;
            } catch (e) {
                // TODO: add error indication
            }

            return results;
        },
        [countryTargetingType, countryCodes, selectedAccountId, isAndLogicEnabled, getMarketplaceSegments]
    );

    return { fetchSegments, fetchSegmentsByIds };
};

export default useSegmentsFetcher;
