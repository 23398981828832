import {
    DUPLICATE_PERFORMANCE_RULE_ERROR,
    DUPLICATE_PERFORMANCE_RULE_SUCCESS,
    REQUEST_DUPLICATE_PERFORMANCE_RULE,
} from './actionTypes';

export const requestDuplicatePerformanceRule = () => ({ type: REQUEST_DUPLICATE_PERFORMANCE_RULE });

export const duplicatePerformanceRuleSuccess = rule => ({
    type: DUPLICATE_PERFORMANCE_RULE_SUCCESS,
    payload: rule,
});

export const duplicatePerformanceRuleError = error => ({ type: DUPLICATE_PERFORMANCE_RULE_ERROR, payload: error });
