import { useQuery } from 'react-query';
import { isNil, isEmpty } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { useTrackingApi } from 'services/api';
import { useContainerDropdown } from './useContainerDropdown';
import { useGtmAccountDropdown } from './useGtmAccountDropdown';
import { useWorkspaceDropdown } from './useWorkspaceDropdown';

const defaultResponse = [];

export const useGtmIndicator = () => {
    const { isTokenValid, getIsPermitted, getToken, exponentialBackoff } = useGoogleApi();
    const [{ accountName }] = useSelectedAccount();
    const { getLiveTags } = useTrackingApi();
    const { value: gtmAccount, options: gtmAccounts, isLoading: isLoadingAccount } = useGtmAccountDropdown();
    const { value: gtmContainer, options: gtmContainers, isLoading: isLoadingContainer } = useContainerDropdown();
    const { options: gtmWorkspaces, isLoading: isLoadingWorkspace } = useWorkspaceDropdown();

    const { data = defaultResponse } = useQuery(
        ['getLiveTags', accountName, gtmAccount, getToken()?.access_token, gtmContainer],
        () => getLiveTags(getToken()?.access_token, accountName, gtmAccount?.value, gtmContainer?.value, true),
        {
            enabled: isTokenValid && !isNil(gtmAccount) && !isNil(gtmContainer),
            retryDelay: exponentialBackoff,
        }
    );

    return {
        isTaboolaPixelInstalled: !isEmpty(data),
        isLimitedAccess: getToken() && !getIsPermitted(),
        hasActiveAccount: isTokenValid && !isLoadingAccount && !gtmAccounts?.length,
        hasActiveContainer: isTokenValid && gtmAccount && !isLoadingContainer && !gtmContainers?.length,
        hasActiveWorkspace: isTokenValid && gtmContainer && !isLoadingWorkspace && !gtmWorkspaces?.length,
    };
};
