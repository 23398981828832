import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const performanceRulesReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULES],
    endpoint: 'performance-rules',
    rowIdField: FIELDS.PERFORMANCE_RULE_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    searchPlaceholderMsgId: 'performance.rules.report.search.placeholder',
    searchField: FIELDS.PERFORMANCE_RULE_NAME.field,
    filters: [],
    initialFilters: [],
};
