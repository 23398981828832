import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AlertIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import recommendationsApi from 'services/api/recommendationsApi';
import { isNilOrEmptyString } from 'services/utils/stringUtils';
import { CTADropdown } from '../../../creative-editor/components/fields';
import { RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES } from '../../config/PerformanceRecommendationsActionParamTypes';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';
import styles from './itemWithoutCTASingleItemRecommendation.module.scss';

export const ItemWithoutCTASingleItemRecommendation = ({
    recommendation,
    removeRecommendationIndication,
    applyRecommendationIndication,
}) => {
    const [ctaType, setCtaType] = useState(null);
    const { actions, id: recommendationId, itemId, accountName: accountId, campaignId, messageParams } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const dismissItemRecommendation = recommendationsApi.dismissItemPerformanceRecommendation;
    const applyItemRecommendation = recommendationsApi.executeItemRecommendationAction;
    const applyParams = useMemo(
        () => ({
            accountId,
            campaignId,
            recommendationId,
            actionId,
            itemId,
            actionParams: {
                [RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES.CREATIVE]: JSON.stringify({
                    cta: { ctaType },
                }),
            },
        }),
        [accountId, actionId, campaignId, ctaType, itemId, recommendationId]
    );
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        markAsApplied: false,
        applyParams,
        dismissParams: {
            accountId,
            campaignId,
            recommendationId,
            itemId,
        },
        onApply: applyItemRecommendation,
        onApplySuccess: applyRecommendationIndication,
        onDismiss: dismissItemRecommendation,
        onDismissSuccess: removeRecommendationIndication,
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageParams}
            titleCode="performance-recommendations.item-without-cta-single-item.title"
            descriptionCode="performance-recommendations.item-without-cta-single-item.description"
            isApplied={apply.isSuccess}
            isRenderedInReportCell={true}
            iconImg={AlertIcon}
        >
            <CTADropdown
                className={styles['cta-input']}
                dropdownWrapperClassName={styles['cta-form-field-container']}
                labelClass={styles['cta-form-field-label']}
                onChange={setCtaType}
                shouldIncludeNoneOption={false}
                label={<FormattedMessage id="creative.creator.content.cta.label" />}
                searchable
            />
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode="performance-recommendations.item-without-cta-single-item.action"
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode="performance-recommendations.item-without-cta-single-item.tooltip"
                    onClick={apply.invoke}
                    isItemRec={true}
                    disabled={isNilOrEmptyString(ctaType)}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

ItemWithoutCTASingleItemRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default ItemWithoutCTASingleItemRecommendation;
