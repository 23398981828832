import { ENTITY } from 'modules/campaigns/config/routes';
import addRecommendationsQueryParams from '../../../modules/recommendations/utils/queryParamUtils';
import patchOperation from '../patchOperation';

export const ENTITY_TO_API_PATH = {
    [ENTITY.CAMPAIGN]: 'campaigns',
    [ENTITY.PMP_DEAL]: 'pmp-deals',
    [ENTITY.CAMPAIGN_VIDEO]: 'campaign-video',
    [ENTITY.VIDEO_PMP_DEAL]: 'video-pmp-deal',
};

export const campaignsAPIPrefix = ({ accountId, campaignId, entityType = ENTITY.CAMPAIGN, suffix = '' }) => {
    const apiPath = ENTITY_TO_API_PATH[entityType] || ENTITY_TO_API_PATH[ENTITY.CAMPAIGN];

    return `/${accountId}/${apiPath}${suffix}${campaignId ? `/${campaignId}` : ''}`;
};

export const campaignsCreateAPIPrefix = ({ entityType, ...rest }) => {
    if (entityType === ENTITY.RTB_OPEN_EXCHANGE) {
        return campaignsAPIPrefix({ entityType, ...rest, suffix: '/open-exchange' });
    }
    return campaignsAPIPrefix({ entityType, ...rest });
};

export const campaignsApiFactory = ({ callGetApi, callPostApi, callPutApi, callPatchApi, callDeleteApi }) => ({
    getCampaigns: ({
        accountId,
        page,
        pageSize,
        searchText,
        sort,
        dimension,
        status,
        apiCacheKey,
        allowMultiRequestCache,
        campaignIds,
    }) =>
        callGetApi(campaignsAPIPrefix({ accountId }), {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
            sort,
            dimension,
            status,
            singleRequestCacheKey: apiCacheKey,
            allowMultiRequestCache,
            campaignIds,
        }),
    getCampaign: ({ accountId, campaignId, entityType, query }) =>
        callGetApi(campaignsAPIPrefix({ accountId, campaignId, entityType }), query),
    createCampaign: ({ accountId, campaign, entityType, recommendationType, recommendationId }) =>
        callPostApi(
            addRecommendationsQueryParams(campaignsCreateAPIPrefix({ accountId, entityType }), {
                recommendationType,
                recommendationId,
            }),
            campaign
        ),
    updateCampaign: ({ accountId, campaignId, changes, recommendationType, recommendationId, entityType }) =>
        callPutApi(
            addRecommendationsQueryParams(campaignsAPIPrefix({ accountId, campaignId, entityType }), {
                recommendationType,
                recommendationId,
            }),
            changes
        ),
    updateCampaigns: (accountId, changes) => callPutApi(`${campaignsAPIPrefix({ accountId })}/bulk-update`, changes),
    duplicateCampaign: ({
        accountId,
        campaignId,
        changes,
        destinationAccount,
        recommendationType,
        recommendationId,
        entityType,
    }) =>
        callPostApi(
            addRecommendationsQueryParams(campaignsAPIPrefix({ accountId, campaignId, entityType }), {
                recommendationType,
                recommendationId,
                destinationAccount,
            }),
            changes
        ),
    deleteCampaign: ({ accountId, campaignId, entityType }) =>
        callDeleteApi(campaignsAPIPrefix({ accountId, campaignId, entityType })),
    addToCampaignProperty: ({ accountId, campaignId, changes, entityType }) =>
        callPatchApi(campaignsAPIPrefix({ accountId, campaignId, entityType }), changes, patchOperation.ADD),
    removeFromCampaignProperty: ({ accountId, campaignId, changes, entityType }) =>
        callPatchApi(campaignsAPIPrefix({ accountId, campaignId, entityType }), changes, patchOperation.REMOVE),
    replaceCampaignProperty: ({ accountId, campaignId, changes, entityType }) =>
        callPatchApi(campaignsAPIPrefix({ accountId, campaignId, entityType }), changes, patchOperation.REPLACE),
    getDefaultRecommendedCta: (accountId, campaignId) =>
        callGetApi(`${campaignsAPIPrefix({ accountId, campaignId })}/cta/default`),
});
