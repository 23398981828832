import React from 'react';
import { FormButtons } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './paymentCreatorFormButtons.module.scss';

export const PaymentCreatorFormButtons = ({ onCancel, onSubmit }) => (
    <div className={styles['buttons-wrapper']}>
        <FormButtons
            className={styles['form-buttons']}
            onSubmit={onSubmit}
            disableSubmit={false}
            submitLabel={<FormattedMessage id="app.actionButtons.add" defaultMessage="Add" />}
            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
            onCancel={onCancel}
            submitButtonType="submit"
        />
    </div>
);
