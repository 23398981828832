import React from 'react';
import PropTypes from 'prop-types';
import styles from './newNotificationsIndication.module.scss';

export const NewNotificationsIndication = ({ notificationsCount }) =>
    !!notificationsCount && (
        <div aria-label="New Notifications Indication" className={styles['new-notifications-indication']}>
            {notificationsCount}
        </div>
    );

NewNotificationsIndication.propTypes = { notificationsCount: PropTypes.number };
