import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { generateCampaignEditorPath } from 'modules/campaigns/services/utils';
import { setRecommendations } from 'modules/recommendations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const UnblockSitesPerformanceRecommendation = ({ recommendation }) => {
    const {
        messageParams,
        campaignId,
        actions,
        type: recommendationType,
        id: recommendationId,
        accountName: accountId,
    } = recommendation;
    const [action] = actions;
    const { publishers } = messageParams;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToEditCampaign = useCallback(() => {
        dispatch(
            setRecommendations({
                recommendationType,
                recommendationId,
                recommendationData: {
                    campaignTargeting: { publisherTargeting: { exclude: identity } },
                },
            })
        );
        navigate(generateCampaignEditorPath({ accountId, campaignId }));
    }, [accountId, campaignId, dispatch, navigate, recommendationId, recommendationType]);

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId: action.actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    const messageDetails = useMemo(
        () => ({
            ...messageParams,
            publishers: publishers
                .split(',')
                .map(str => (
                    <FormattedMessage
                        id="performance-recommendations-unblock-sites.description.publisher"
                        values={{ publisher: str }}
                    />
                )),
        }),
        [messageParams, publishers]
    );

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            titleCode="performance-recommendations.unblock-sites.title"
            messageParams={messageDetails}
            descriptionCode="performance-recommendations-unblock-sites.description"
            iconImg={LightningRoundIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToEditCampaign}
                    recommendation={recommendation}
                    textCode="performance-recommendations-unblock-sites.edit-campaign.button"
                    dataMetricsComponent="RecommendationCardEditCampaignButton"
                />
                <ApplyButton
                    textCode="performance-recommendations.unblock-sites.action"
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode="performance-recommendations.unblock-sites.action.tooltip"
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

UnblockSitesPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
