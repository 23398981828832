import React from 'react';
import PropTypes from 'prop-types';
import { FormLayout } from '../../../../components/Form';
import { LoadingIndicator as PreviewLoadingIndicator } from '../../../creative-preview/components';
import ThumbnailEditorBaseLoader from './ThumbnailEditorBaseLoader';
import styles from './creativeEditorLoader.module.scss';

const CreativeEditorLoader = ({ onCancel, submitButtonLabel }) => (
    <FormLayout
        bodyContainerClassName={styles['body-container']}
        onCancel={onCancel}
        submitButtonText={submitButtonLabel}
        disableSubmit
    >
        <ThumbnailEditorBaseLoader />
        <PreviewLoadingIndicator className={styles['preview']} />
    </FormLayout>
);

CreativeEditorLoader.propTypes = {
    onCancel: PropTypes.func,
    submitButtonLabel: PropTypes.node,
};

export default CreativeEditorLoader;
