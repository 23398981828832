import React from 'react';
import classnames from 'classnames/bind';
import { InfoOutlinedIcon } from 'tuui';
import { CommonCollapsibleCard } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './contentTitleAISuggestionsBaseCard.module.scss';

const classNameBuilder = classnames.bind(styles);

const collapsedHeader = (
    <div className={styles['collapsed-header-content']}>
        <InfoOutlinedIcon className={styles['info-icon']} />
        <FormattedMessage
            id="creative.creator.aiTitleSuggestions.header.collapsed"
            defaultMessage="Title generator based on AI suggestions"
        />
    </div>
);

const ContentTitleAISuggestionsBaseCard = ({
    header: expandedHeader,
    description,
    collapsed,
    setCollapsed,
    children,
    containerClassName,
}) => {
    const header = collapsed ? collapsedHeader : expandedHeader;

    return (
        <CommonCollapsibleCard
            id="AI_TITLE_SUGGESTIONS"
            header={header}
            description={description}
            containerClassName={classNameBuilder('card-container', containerClassName)}
            headerClassName={classNameBuilder('card-header', { 'collapsed-card-header': collapsed })}
            descriptionClassName={styles['card-description']}
            bodyClassName={styles['card-body']}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
        >
            {children}
        </CommonCollapsibleCard>
    );
};

export default ContentTitleAISuggestionsBaseCard;
