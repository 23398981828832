import { get, isEmpty } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { SupportedVideoTypes } from 'modules/campaigns/config/VideoTypes';
import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';
import { validateImage, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { getFailedValidation } from 'modules/taboola-common-frontend-modules/validations/utils';
import ValidationError from '../../../../../../../errors/ValidationError';
import SupportedImageType from '../../../../../../config/ImageTypes';
import trackImageErrorEvent from '../../../../../../services/utils/trackImageErrorEvent';
import {
    THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
    VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
    VIDEO_URL_UNSUPPORTED_FORMAT_ERROR_MESSAGE_ID,
} from '../../../../../creative-creator/config/thumbnailValidationConsts';

const validations = [{ validationFn: validationFunctions.isURL, messageId: 'validations.error.field.url.invalid' }];
const validationErrorMessagesPrefix = 'validations.error.field.url.';

export const getValidationErrorMessage = async ({
    url,
    mode,
    creativeType,
    mediaType,
    isUnifiedCreativesFormDuplicateMode,
}) => {
    const failedValidation = await getFailedValidation(validations, { value: url });

    if (failedValidation) {
        return failedValidation.messageId;
    }

    try {
        await validateMedia({
            url,
            mode,
            creativeType,
            mediaType,
            isUnifiedCreativesFormDuplicateMode,
        });
    } catch (error) {
        trackImageErrorEvent(error);
        return validationErrorMessagesPrefix + error.status;
    }
    return null;
};

const validateMedia = async ({ url, mode, creativeType, mediaType, isUnifiedCreativesFormDuplicateMode }) => {
    const isUrlTypeOfImage = Object.fromEntries(
        Object.entries(SupportedImageType).filter(([, imageType]) => url.endsWith(imageType))
    );
    if (!isEmpty(isUrlTypeOfImage)) {
        shouldBlockEditOrDuplicateByCreativeType({
            urlType: mediaType.IMAGE,
            mode,
            creativeType,
            mediaType,
            isUnifiedCreativesFormDuplicateMode,
        });
        await validateImage(url);
        return;
    }

    const urlWithoutQuery = get(`${url}`.split('?'), '[0]', '').toLowerCase();
    const isUrlTypeOfVideo = SupportedVideoTypes.filter(videoType => urlWithoutQuery.endsWith(videoType));
    if (isUrlTypeOfVideo.length) {
        try {
            shouldBlockEditOrDuplicateByCreativeType({
                urlType: mediaType.VIDEO,
                mode,
                creativeType,
                mediaType,
                isUnifiedCreativesFormDuplicateMode,
            });
        } catch (error) {
            if (error instanceof ValidationError) {
                throw error;
            }
            throw new ValidationError('Video file loading has failed', VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID);
        }
    } else {
        throw new ValidationError('format type unsupported', VIDEO_URL_UNSUPPORTED_FORMAT_ERROR_MESSAGE_ID);
    }
};

const shouldBlockEditOrDuplicateByCreativeType = ({
    urlType,
    mode,
    creativeType,
    mediaType,
    isUnifiedCreativesFormDuplicateMode,
}) => {
    if (isUnifiedCreativesFormDuplicateMode) {
        return;
    }
    const isModeEditOrDuplicate =
        mode === FORM_MODES.EDIT || mode === FORM_MODES.BULK_EDIT || mode === FORM_MODES.DUPLICATE;
    const shouldBlockVideo = isModeEditOrDuplicate && creativeType === CREATIVE_TYPE.IMAGE;
    const shouldBlockImage = isModeEditOrDuplicate && creativeType === CREATIVE_TYPE.MOTION;
    if (shouldBlockVideo && urlType === mediaType.VIDEO) {
        throw new ValidationError('Image file loading has failed', THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID);
    }
    if (shouldBlockImage && urlType === mediaType.IMAGE) {
        throw new ValidationError('Video file loading has failed', VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID);
    }
};
