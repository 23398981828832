import { hybridModuleFactory } from '../backstage-content';
import {
    BACKSTAGE_CONTENT_PERMISSION,
    PERMISSIONS_OPERATORS,
} from '../taboola-common-frontend-modules/authentication/';
import { setRecommendations, clearRecommendations } from './actions';
import NavigationItemContent from './components/NavigationItemContent';
import * as RECOMMENDATIONS_STATE from './config/recommendationsState';
import fetchRecommendationsIndication from './flows/fetchRecommendationsIndication';
import externalMessageHandler from './recommendationsHandler';
import recommendationsReducer from './reducer';

const MODULE_NAME = 'recommendations';
const externalPath = 'reports/campaigns/recommendations';
const ModuleComponent = hybridModuleFactory(externalPath, { externalMessageHandler });

const permissions = {
    permissions: [
        'TABOOLA_ADMIN',
        {
            permissions: ['ADS_CONSOLE_RECOMMENDATIONS', 'RECOMMENDATIONS_VIEW', BACKSTAGE_CONTENT_PERMISSION],
            operator: PERMISSIONS_OPERATORS.AND,
        },
    ],
    operator: PERMISSIONS_OPERATORS.OR,
};

export {
    MODULE_NAME,
    ModuleComponent,
    externalPath,
    permissions,
    recommendationsReducer,
    setRecommendations,
    clearRecommendations,
    fetchRecommendationsIndication,
    NavigationItemContent,
    RECOMMENDATIONS_STATE,
};
