import { isEmpty } from 'lodash';
import { CustomValidationError } from '../../../../../errors/CustomValidationError';
import { validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import { OS_FAMILY_KEYS } from './useAdSetupUrlInputGroupConfig';

export const isValidAppStoreURL = (appUrl, osFamily) => {
    if (isEmpty(appUrl)) {
        return false;
    }

    if (osFamily === OS_FAMILY_KEYS.IOS) {
        return appleAppStoreRegex.test(appUrl);
    }
    if (osFamily === OS_FAMILY_KEYS.ANDROID) {
        return googlePlayStoreRegex.test(appUrl);
    }
    return false;
};

export const validations = [
    {
        messageId: 'validations.error.creative.app.url',
        defaultMessage: 'Please enter the Google Play Store (Android) or App Store (iOS) URL directly.',
        validationFn: async (value, options, { regExp, getAppData }) => {
            if (validationFunctions.isEmpty(value) || !regExp.test(value)) {
                return false;
            }
            try {
                await getAppData(value);
                return true;
            } catch (err) {
                throw new CustomValidationError({
                    messageCode: 'creative.creator.app.install.error.invalid.app.url',
                    message:
                        'Retrieving your app assets from the provided URL was unsuccessful. Please check your app URL or contact support',
                });
            }
        },
    },
];

export const appleAppStoreRegex = /apps\.apple\.com.+id(\d+)/i;
export const googlePlayStoreRegex = /play\.google\.com.+?id=(\w+\.\w+)/i;
