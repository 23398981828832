import React, { useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { FormField } from 'taboola-ultimate-ui';
import { useAvailableItems, useNavigate } from 'hooks';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CommonCollapsibleCard, ExternalLink } from '../../../components';
import { FormButtons } from '../../campaigns/components';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { useModal } from '../../taboola-common-frontend-modules/modals';
import { AudienceCreationAdvancedOptions } from '../components/AudienceCreationAdvancedOptions/AudienceCreationAdvancedOptions';
import { AudienceCreationRadioIconGroup } from '../components/AudienceCreationRadioIconGroup/AudienceCreationRadioIconGroup';
import {
    moreAudienceConfigs,
    taboolaDataAudienceConfigs,
    yourDataAudienceConfigs,
} from '../config/audienceCreatorConfig';
import {
    generateFirstPartyAudienceCreatorPath,
    generatePredictiveAudienceCreatorPath,
    generatePixelBasedAudienceCreatorPath,
    generateCustomContextualCreatorPath,
    generateCombinedAudienceCreatorPath,
    generateMailDomainAudiencesCreatorPath,
    generateSearchKeywordsAudiencesCreatorPath,
} from '../utils/generateCreatorPath';
import styles from './audiencesCreatorSelectionPage.module.scss';

const paths = {
    [REPORT_TYPE.PIXEL_BASED]: generatePixelBasedAudienceCreatorPath(),
    [REPORT_TYPE.PREDICTIVE]: generatePredictiveAudienceCreatorPath(),
    [REPORT_TYPE.FIRST_PARTY]: generateFirstPartyAudienceCreatorPath(),
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: generateCustomContextualCreatorPath(),
    [REPORT_TYPE.MAIL_DOMAINS]: generateMailDomainAudiencesCreatorPath(),
    [REPORT_TYPE.SEARCH_KEYWORDS]: generateSearchKeywordsAudiencesCreatorPath(),
    [REPORT_TYPE.COMBINED_AUDIENCES]: generateCombinedAudienceCreatorPath(),
};

const yourDataLabel = <FormattedMessage id="audience.creator.selection.your.data" defaultMessage="Your data" />;
const taboolaDataLabel = (
    <FormattedMessage id="audience.creator.selection.taboola.data" defaultMessage="Taboola data" />
);
const moreOptionsLabel = (
    <FormattedMessage id="audience.creator.selection.more.options" defaultMessage="More options" />
);

const dataUsePolicyLink = (
    <ExternalLink href="https://www.taboola.com/policies/advertiser-data-use-policy" className={styles['link']}>
        <FormattedMessage
            id="audience.creator.selection.legal.disclaimer.link.text"
            defaultMessage="Taboola Advertiser Data Use Policy"
        />
    </ExternalLink>
);

export const AudiencesCreatorSelectionPage = () => {
    const [type, setType] = useState('pixel-audiences');
    const navigate = useNavigate();
    const { close: closeModal } = useModal();
    const handleNavigate = useCallback(async () => {
        await navigate(paths[type]);
        closeModal();
    }, [closeModal, navigate, type]);

    const selectedAccount = useAccount();
    const conditionData = useMemo(() => ({ selectedAccount }), [selectedAccount]);

    const availableYourDataAudienceConfigs = useAvailableItems(yourDataAudienceConfigs);
    const availableTaboolaDataAudienceConfigs = useAvailableItems(taboolaDataAudienceConfigs);
    const availableMoreAudienceConfigs = useAvailableItems(moreAudienceConfigs, conditionData);

    return (
        <div className={styles['container']}>
            <div className={styles['content-container']}>
                <div className={styles['form-container']}>
                    <FormattedMessage
                        id="audience.creator.selection.title"
                        tagName="h3"
                        defaultMessage="What do you want your audience to be based on?"
                    />
                    {!isEmpty(availableYourDataAudienceConfigs) && (
                        <FormField label={yourDataLabel}>
                            <AudienceCreationRadioIconGroup
                                type={type}
                                onTypeChange={setType}
                                audienceConfigs={availableYourDataAudienceConfigs}
                            />
                        </FormField>
                    )}
                    {!isEmpty(availableTaboolaDataAudienceConfigs) && (
                        <FormField label={taboolaDataLabel}>
                            <AudienceCreationRadioIconGroup
                                type={type}
                                onTypeChange={setType}
                                audienceConfigs={availableTaboolaDataAudienceConfigs}
                            />
                        </FormField>
                    )}
                    <CommonCollapsibleCard
                        id="CREATE_AUDIENCE_MORE_OPTIONS"
                        containerClassName={styles['more-options-container']}
                        headerClassName={styles['more-options-header']}
                        bodyClassName={styles['more-options-body']}
                        header={moreOptionsLabel}
                        isInitiallyCollapsed={true}
                    >
                        {!isEmpty(availableMoreAudienceConfigs) && (
                            <AudienceCreationRadioIconGroup
                                type={type}
                                onTypeChange={setType}
                                audienceConfigs={availableMoreAudienceConfigs}
                            />
                        )}
                        <AudienceCreationAdvancedOptions />
                    </CommonCollapsibleCard>
                </div>
            </div>
            <div className={styles['buttons-container']}>
                <FormattedMessage
                    className={styles['disclaimer']}
                    id="audience.creator.selection.legal.disclaimer"
                    defaultMessage="{helpLink} for more information regarding Moat Billing"
                    values={{ link: dataUsePolicyLink }}
                />
                <FormButtons
                    className={styles['buttons']}
                    onCancel={closeModal}
                    disableSubmit={!type}
                    submitLabel={<FormattedMessage id="app.actionButtons.continue" defaultMessage="Continue" />}
                    onSubmit={handleNavigate}
                />
            </div>
        </div>
    );
};
