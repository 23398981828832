"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgWeek(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M17.993 20H6.007A2.009 2.009 0 0 1 4 18V8a2.002 2.002 0 0 1 2.008-2H8V5a1 1 0 0 1 2 0v1h4V5a1 1 0 0 1 2 0v1h1.993c1.105 0 2.002.895 2.007 2v10a2.002 2.002 0 0 1-2.008 2h.001zM18 8h-2v1a1 1 0 0 1-2 0V8h-4v1a1 1 0 0 1-2 0V8H6v10h12V8zM8 12c0-.552.444-1 1-1 .552 0 1 .444 1 1v3c0 .552-.444 1-1 1-.552 0-1-.444-1-1v-3zm3 0c0-.552.444-1 1-1 .552 0 1 .444 1 1v3c0 .552-.444 1-1 1-.552 0-1-.444-1-1v-3zm3 0c0-.552.444-1 1-1 .552 0 1 .444 1 1v3c0 .552-.444 1-1 1-.552 0-1-.444-1-1v-3z"
            })
        )
    );
}
exports.default = SvgWeek;