import { useMemo } from 'react';
import { uniqBy, values } from 'lodash';
import { useDropdownOptions, useDropdownValue } from '../../../../../../../hooks';
import { BID_STRATEGIES } from '../../../config';

export const bidStrategiesCategory = {
    MAX_CONVERSIONS: 'MAX_CONVERSIONS',
    MAX_REVENUE: 'MAX_REVENUE',
    BID_CONTROL: 'BID_CONTROL',
};

export const categoryToStrategyMap = {
    [bidStrategiesCategory.BID_CONTROL]: BID_STRATEGIES.SMART,
    [bidStrategiesCategory.MAX_CONVERSIONS]: BID_STRATEGIES.MAX_CONVERSIONS,
    [bidStrategiesCategory.MAX_REVENUE]: BID_STRATEGIES.TARGET_ROAS,
};

export const strategyToCategoryMap = {
    [BID_STRATEGIES.SMART]: bidStrategiesCategory.BID_CONTROL,
    [BID_STRATEGIES.FIXED]: bidStrategiesCategory.BID_CONTROL,
    [BID_STRATEGIES.TARGET_CPA]: bidStrategiesCategory.MAX_CONVERSIONS,
    [BID_STRATEGIES.MAX_CONVERSIONS]: bidStrategiesCategory.MAX_CONVERSIONS,
    [BID_STRATEGIES.TARGET_ROAS]: bidStrategiesCategory.MAX_REVENUE,
};

export const useBidStrategiesOptions = ({ bidStrategyCategory, availableBidStrategies }) => {
    const availableCategories = useMemo(
        () =>
            uniqBy(
                values(availableBidStrategies).map(({ id, ...rest }) => {
                    const strategyCategory = strategyToCategoryMap[id];
                    return {
                        ...rest,
                        value: strategyCategory,
                        label: bidStrategiesCategory[strategyCategory],
                    };
                }),
                'value'
            ),
        [availableBidStrategies]
    );

    const bidStrategyOptions = useDropdownOptions(availableCategories, 'campaign.editor.bid.strategy.category');
    const selectedValue = useDropdownValue({ value: bidStrategyCategory }, 'campaign.editor.bid.strategy.category');

    return {
        bidStrategyOptions,
        selectedValue,
    };
};
