import { REQUEST_FETCH_ACCOUNTS, FETCH_ACCOUNTS_SUCCESS, FETCH_ACCOUNTS_ERROR } from './actionTypes';

export const requestFetchAccounts = () => ({ type: REQUEST_FETCH_ACCOUNTS });

export const fetchAccountsSuccess = (accounts, accountsTotal) => ({
    type: FETCH_ACCOUNTS_SUCCESS,
    payload: { accounts, accountsTotal },
});

export const fetchAccountsError = error => ({ type: FETCH_ACCOUNTS_ERROR, payload: error });
