import React from 'react';
import { Spinner } from 'tuui';
import { FileIcon, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './filesSection.module.scss';

export const FileSection = ({ onRemoveImage, fileName, isLoading, message, icon: Icon }) => {
    return (
        <>
            <Icon />
            {isLoading || fileName ? (
                <div className={styles['filename-container']}>
                    <span className={styles['file-icon']}>
                        {isLoading ? <Spinner size={20} className={styles['spinner']} /> : <FileIcon />}
                    </span>
                    <span className={styles['filename']}>{fileName}</span>
                    {fileName && (
                        <StyledButton
                            data-testid="close"
                            iconBefore="close"
                            size={STYLED_BUTTON_SIZE.SMALL}
                            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                            onClick={onRemoveImage}
                        />
                    )}
                </div>
            ) : (
                <FormattedMessage id={message.id} defaultMessage={message.defaultMessage} />
            )}
        </>
    );
};
