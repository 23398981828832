import React from 'react';
import { GetHelpFromExpertSection } from '../GetHelpFromExpert/GetHelpFromExpertSection';

export const S2SIntroduction = () => (
    <GetHelpFromExpertSection
        headerMessageId="tracking.setup.s2s.introduction.label"
        headerDefaultMessage="Server to Server"
        descriptionMessageId="tracking.setup.s2s.introduction.description"
        descriptionDefaultMessage="Server to Server (S2S) conversion tracking allows you to track multiple conversions, such as mobile app install and post-install events without installing the Taboola Pixel."
    />
);
