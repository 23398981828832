import { FORM_MODES } from 'config/formModes';
import { FORM_MODE_TO_PATH_MAP } from '../../../config/routes';
import { AUDIENCES_ROUTE, EDITOR_ROUTE_PATH, ENTITY } from '../config';
import { generateFormPath } from './generateFormPath';

export const generatePixelBasedAudienceCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.PIXEL_BASED,
        mode: FORM_MODES.CREATE,
    });

export const generatePredictiveAudienceCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.PREDICTIVE,
        mode: FORM_MODES.CREATE,
    });

export const generateFirstPartyAudienceCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.FIRST_PARTY,
        mode: FORM_MODES.CREATE,
    });

export const generateCustomContextualCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.CUSTOM_CONTEXTUAL,
        mode: FORM_MODES.CREATE,
    });

export const generateCombinedAudienceCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.COMBINED_AUDIENCES,
        mode: FORM_MODES.CREATE,
    });

export const generateSearchKeywordsAudiencesCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.SEARCH_KEYWORDS,
        mode: FORM_MODES.CREATE,
    });

export const generateMailDomainAudiencesCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.MAIL_DOMAINS,
        mode: FORM_MODES.CREATE,
    });

export const audienceCreatorSelectionPath = `${AUDIENCES_ROUTE}/${FORM_MODE_TO_PATH_MAP[FORM_MODES.CREATE]}`;
