import { useMemo } from 'react';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { VTA_LOOKBACK_WINDOW_INPUT_TYPE } from './VtaLookbackWindowInputType';

export const useVtaLookBackWindowValue = (isInitialValue = false) => {
    const {
        [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_MAX_VALUE_IN_HOURS]: vtaMaxValue,
        [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_INITIAL_VALUE_IN_HOURS]: vtaInitialValue,
    } = useAccountConfig([
        FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_MAX_VALUE_IN_HOURS,
        FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_INITIAL_VALUE_IN_HOURS,
    ]);

    const hours = isInitialValue ? vtaInitialValue : vtaMaxValue;
    const days = hours / 24;
    return useMemo(
        () => ({
            [VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS]: hours,
            [VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS]: days,
        }),
        [hours, days]
    );
};
