import React, { useCallback } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { DropdownMenu } from 'components';
import { withIndication } from 'modules/errors/components';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './timeFrame.module.scss';

const FormFieldWithIndication = withIndication(FormField, styles['time-frame-field']);
const getTimeRangeValue = value => {
    if (value === 0) {
        return (
            <FormattedMessage id={`app.create.performance.rules.setup.time.frame.${value}`} defaultMessage="Today" />
        );
    }
    if (value === 1) {
        return (
            <FormattedMessage
                id={`app.create.performance.rules.setup.time.frame.${value}`}
                defaultMessage="Yesterday"
            />
        );
    }

    return (
        <FormattedMessage
            id="app.create.performance.rules.setup.time.frame"
            defaultMessage="Last {value} days"
            values={{ value }}
        />
    );
};
const daysOptions = [0, 1, 3, 5, 7, 14, 30].map(value => ({
    value,
    label: getTimeRangeValue(value),
}));

const daysOptionsLongLookBackWindow = [0, 1, 3, 5, 7, 14, 30, 60, 90].map(value => ({
    value,
    label: getTimeRangeValue(value),
}));

const timeRangeDropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.performance.rules.time.range.no.selection',
        defaultMessage: 'Please select a Time Range',
    },
];

const formFieldId = 'timeFrame-selector';
export const TimeFrame = () => {
    const {
        value: timeFrameValue,
        onChange: timeFrameOnChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'lookbackWindowInDays',
        hasInitialData: false,
        validations: timeRangeDropdownValidations,
    });
    const onTimeFrameChange = useCallback(
        ({ value }) => {
            timeFrameOnChange(value);
        },
        [timeFrameOnChange]
    );
    const isCustomRuleLongLookBackWindowEnabled = useConfigMatch({
        [FEATURE_FLAGS.PERFORMANCE_RULES_LONG_LOOK_BACK_WINDOW_ENABLED]: 'true',
    });

    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['time-frame-container']}
            label={
                <FormattedMessage
                    id="app.create.performance.rules.setup.time.range.label"
                    defaultMessage="Time Range"
                />
            }
            labelClass={styles['label']}
            helpText={
                <FormattedMessage
                    id="app.create.performance.rules.time.frame.help"
                    defaultMessage="Last X days time frames does not include “Today” data"
                />
            }
            {...indicationData}
        >
            <DropdownMenu
                id={formFieldId}
                selectedValueObject={{ value: timeFrameValue }}
                options={isCustomRuleLongLookBackWindowEnabled ? daysOptionsLongLookBackWindow : daysOptions}
                onChange={onTimeFrameChange}
                dropdownWrapperClassName={styles['time-select']}
                aria-label="timeFrame-dropdown"
            />
        </FormFieldWithIndication>
    );
};
