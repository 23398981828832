import React, { useEffect, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks';
import { withIndication } from 'modules/errors/components/withIndication';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import CurrencyIcon from '../../../common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);

const InputWithIndication = withIndication(Input);
const baseValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'video.campaign.editor.rate.validations.error.not.empty',
        defaultMessage: 'Enter rate',
    },
    {
        validationFn: value => value > 0,
        messageId: 'video.campaign.editor.rate.validations.error.positive.number',
        defaultMessage: 'Rate must be a positive number',
    },
    {
        validationFn: (value, options, { originalRate, businessModel, startDate }) =>
            !(
                originalRate < value &&
                businessModel === VIDEO_PRICING_MODEL.CPCV &&
                moment(startDate).isBefore(moment().startOf('day'))
            ),
        messageId: 'video.campaign.editor.rate.validations.error.cpcv.increase',
        defaultMessage:
            'Increasing CPCV rate is not allowed. Pause video campaign and duplicate with remaining budget to avoid delivery issues',
    },
    {
        validationFn: validationFunctions.range,
        options: { lt: 'budget' },
        messageId: 'video.campaign.editor.rate.validations.error.smaller.than.budget',
        defaultMessage: 'Rate must be smaller than budget',
    },
];

const extraValidations = [
    {
        validationFn: validationFunctions.range,
        options: { min: 'minRate' },
        messageId: 'video.campaign.editor.rate.validations.error.specificAccountMinValue',
        defaultMessage: 'Rate amount cannot be smaller than {minRate} {currency}',
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'maxRate' },
        messageId: 'video.campaign.editor.rate.validations.error.specificAccountMaxValue',
        defaultMessage: 'Rate amount cannot be higher than {maxRate} {currency}',
    },
];

const internalValidations = [...baseValidations, ...extraValidations];

export const Rate = ({ currency }) => {
    const { value: startDate } = useFormFieldValue({ field: 'startDate' });
    const { value: businessModel } = useFormFieldValue({ field: 'videoBudget.businessModel' });
    const { value: budget } = useFormFieldValue({ field: 'videoBudget.budget' });
    const { value: originalRate } = useFormFieldValue({ field: 'videoBudget.rate' });

    const {
        [FEATURE_FLAGS.VIDEO_RATE_MAX_VALUE]: maxRate,
        [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPCV]: minRateCpcv,
        [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPM]: minRateCpm,
        [FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_VCPM]: minRateVcpm,
    } = useAccountConfig([
        FEATURE_FLAGS.VIDEO_RATE_MAX_VALUE,
        FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPCV,
        FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_CPM,
        FEATURE_FLAGS.VIDEO_RATE_MIN_VALUE_VCPM,
    ]);

    const pricingModelToMinRateValue = {
        [VIDEO_PRICING_MODEL.CPCV]: minRateCpcv,
        [VIDEO_PRICING_MODEL.VCPM]: minRateVcpm,
        [VIDEO_PRICING_MODEL.CPM]: minRateCpm,
    };
    const minRate = pricingModelToMinRateValue[businessModel];
    const originalRateRef = useRef(originalRate);
    const isInternalValidations = usePermissions('VIDEO_MINIMUM_RATE_INTERNAL_VALIDATION');
    const validationDependencies = useMemo(
        () => ({
            data: {
                budget,
                maxRate,
                currency,
                minRate,
            },
            startDate,
            businessModel,
            originalRate: originalRateRef.current,
        }),
        [budget, businessModel, currency, maxRate, minRate, startDate]
    );
    const {
        value: rate,
        onChange: onRateChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'videoBudget.rate',
        validationDependencies,
        validations: isInternalValidations ? internalValidations : baseValidations,
    });

    const rateGetter = useCurrentValueGetter(rate);
    useEffect(() => {
        originalRateRef.current = rateGetter();
    }, [rateGetter]);

    return (
        <FormattedNumber value={0} minimumIntegerDigits={1}>
            {placeholder => (
                <InputWithIndication
                    id="videoBudget.rate"
                    onChange={e => onRateChange(e.target.value)}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    placeholder={placeholder}
                    value={rate}
                    type={InputTypes.NUMBER}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Rate"
                    {...indicationData}
                />
            )}
        </FormattedNumber>
    );
};

Rate.propTypes = {
    currency: PropTypes.string,
};

export default Rate;
