import TARGETING_TYPES from '../../modules/common-campaign-form/config/TargetingTypes';

export const getDefaultTargeting = (targeting = {}) => {
    const { type, values } = targeting || {};
    return {
        type: type !== TARGETING_TYPES.EXCLUDE ? TARGETING_TYPES.INCLUDE : type,
        values: values || [],
    };
};

export default getDefaultTargeting;
