/* eslint-disable no-undef */
export const createSpreadsheet = async ({ filename }) => {
    const metadata = {
        properties: {
            title: filename,
        },
    };

    return gapi.client.sheets.spreadsheets.create(metadata);
};

export const pushCSVDataToSpreadsheet = ({ spreadsheetId, csvData, delimiter = ',' }) =>
    gapi.client.sheets.spreadsheets.batchUpdate(
        {
            spreadsheetId,
        },
        {
            requests: [
                {
                    pasteData: {
                        coordinate: {
                            sheetId: 0,
                            rowIndex: 0,
                            columnIndex: 0,
                        },
                        data: csvData,
                        type: 'PASTE_NORMAL',
                        delimiter,
                    },
                },
            ],
        }
    );
