import { useCallback, useMemo, useState } from 'react';
import { find, some } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { isNetworkAccountType } from 'modules/account-management';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignLevelConversionsApi } from 'services/api/campaignLevelConversionsApi';

export const useCampaignLevelConversions = () => {
    const [{ type }] = useSelectedAccount();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const [currentOptions, setCurrentOptions] = useState([]);
    const { getAccountLevelConversionRulesData, getCampaignLevelConversionRulesData } =
        useCampaignLevelConversionsApi();
    const { formatMessage } = useIntl();
    const { value: conversionRules } = useFormFieldValue({
        field: 'conversionRules.rules',
    });
    const isCampaignConversionRulesEnabled = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_MULTIPLE_CONVERSIONS_ENABLED]: 'true',
    });

    const loadConversionRulesData = useCallback(
        (accountId, campaignId, page, pageSize, searchText) => {
            return campaignId && !isNetworkAccountType(type)
                ? getCampaignLevelConversionRulesData(accountId, campaignId, page, pageSize, searchText)
                : getAccountLevelConversionRulesData(accountId, page, pageSize, searchText);
        },
        [type, getCampaignLevelConversionRulesData, getAccountLevelConversionRulesData]
    );

    const loadConversionRules = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await loadConversionRulesData(accountId, campaignId, page, pageSize, searchText);
            const filteredResults = !isCampaignConversionRulesEnabled
                ? results
                : results.filter(item => !some(conversionRules, rule => item.apiUnipRuleConfiguration?.id === rule.id));
            return [filteredResults, total, results.length - filteredResults.length];
        },
        [loadConversionRulesData, accountId, campaignId, conversionRules, isCampaignConversionRulesEnabled]
    );

    const { [COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW]: numDays } = useCommonConfig(
        COMMON_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_DATA_LOOKBACK_WINDOW
    );

    const options = useMemo(
        () =>
            currentOptions.map(rule => {
                if (!rule) {
                    return {};
                }

                const { avgCvr, clicks, conversions, ruleCategory, apiUnipRuleConfiguration } = rule;
                const { id, displayName: conversionName, status } = apiUnipRuleConfiguration;
                const formattedRuleCategory = formatMessage({
                    id: `tracking.conversions.report.category.${ruleCategory}`,
                    defaultMessage: ruleCategory,
                });
                const displayName = `${conversionName} (${formattedRuleCategory})`;
                return {
                    id,
                    displayName,
                    status,
                    avgCvr,
                    clicks,
                    conversions,
                    ruleCategory,
                    value: id.toString(),
                    label: displayName,
                    numDays,
                };
            }),
        [currentOptions, numDays, formatMessage]
    );
    const defaultRule = useMemo(() => find(options, { isDisabled: true }), [options]);
    const accountDefaultRule = defaultRule?.displayName;
    return {
        options,
        loadConversionRules,
        setCurrentOptions,
        accountDefaultRule,
    };
};
