'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useDragAndDropFileContext = exports.DragAndDropFileContext = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDropzone = require('react-dropzone');

var _DropSVGIcon = require('../DropSVGIcon');

var _DropSVGIcon2 = _interopRequireDefault(_DropSVGIcon);

var _FileTypes = require('../FileTypes');

var _FileTypes2 = _interopRequireDefault(_FileTypes);

var _DropAreaIndicator = require('../DropAreaIndicator/DropAreaIndicator');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dragAndDropFile__taboola-svg-icon___1fIF5',
    'drop-zone': 'dragAndDropFile__drop-zone___11-cz'
};


var classNameBuilder = _bind2.default.bind(styles);
var DragAndDropFileContext = exports.DragAndDropFileContext = (0, _react.createContext)({ isDragActive: false, openFileDialog: function openFileDialog() {} });
var useDragAndDropFileContext = exports.useDragAndDropFileContext = function useDragAndDropFileContext() {
    return (0, _react.useContext)(DragAndDropFileContext);
};

var DragAndDropFile = function DragAndDropFile(_ref) {
    var onDrop = _ref.onDrop,
        onOpen = _ref.onOpen,
        onCancel = _ref.onCancel,
        text = _ref.text,
        fileTypes = _ref.fileTypes,
        multiple = _ref.multiple,
        dropIcon = _ref.dropIcon,
        dropActiveContainerClassName = _ref.dropActiveContainerClassName,
        children = _ref.children,
        className = _ref.className,
        disabled = _ref.disabled,
        suppressDropContainer = _ref.suppressDropContainer,
        inputId = _ref.inputId;

    var _useDropzone = (0, _reactDropzone.useDropzone)({
        accept: fileTypes,
        noClick: true,
        onDrop: onDrop,
        onFileDialogOpen: onOpen,
        onFileDialogCancel: onCancel,
        multiple: multiple,
        disabled: disabled
    }),
        getRootProps = _useDropzone.getRootProps,
        getInputProps = _useDropzone.getInputProps,
        isDragActive = _useDropzone.isDragActive,
        open = _useDropzone.open;

    var contextValue = (0, _react.useMemo)(function () {
        return { isDragActive: isDragActive, openFileDialog: open };
    }, [isDragActive, open]);

    return _react2.default.createElement(
        DragAndDropFileContext.Provider,
        { value: contextValue },
        _react2.default.createElement(
            'div',
            Object.assign({}, getRootProps(), { className: classNameBuilder('drop-zone', className) }),
            children,
            _react2.default.createElement('input', Object.assign({}, getInputProps(), { id: inputId })),
            isDragActive && !suppressDropContainer && _react2.default.createElement(_DropAreaIndicator.DropAreaIndicator, { dropIcon: dropIcon, dropActiveContainerClassName: dropActiveContainerClassName, text: text })
        )
    );
};

DragAndDropFile.propTypes = {
    /** Function to be called when files are dropped inside drop zone */
    onDrop: _propTypes2.default.func.isRequired,
    /** Function to be called when file drop cancelled */
    onCancel: _propTypes2.default.func,
    /** Function to be called when file dialog opened */
    onOpen: _propTypes2.default.func,
    /** Accepted file types */
    fileTypes: _propTypes2.default.object,
    /** Allow drag and drop (or selection from the file dialog) of multiple files */
    multiple: _propTypes2.default.bool,
    /**  children content */
    children: _propTypes2.default.node,
    /** Drop Zone Heading Message */
    text: _propTypes2.default.node,
    /** Drop container className in active dropping state */
    dropActiveContainerClassName: _propTypes2.default.string,
    /** Icon to showed on the IsDragActive Layout */
    dropIcon: _propTypes2.default.node,
    className: _propTypes2.default.string,
    disabled: _propTypes2.default.bool,
    suppressDropContainer: _propTypes2.default.bool,
    inputId: _propTypes2.default.string
};

DragAndDropFile.defaultProps = {
    fileTypes: _defineProperty({}, _FileTypes2.default.IMAGE, []),
    multiple: true,
    dropIcon: _react2.default.createElement(_DropSVGIcon2.default, null),
    dropActiveContainerClassName: '',
    className: '',
    suppressDropContainer: false
};

exports.default = DragAndDropFile;