import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const attributionGroupValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: 255 },
        messageId: 'validations.error.attribution.group.name.length',
        defaultMessage: 'Attribution Group name should not exceed 255 characters.',
        severity: INDICATION_TYPES.ERROR,
    },
];

export default attributionGroupValidations;
