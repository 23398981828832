import { get, isNil } from 'lodash';
import { CAMPAIGN_STATUS, REPORT_TYPE } from 'modules/campaigns/config';
import PRICING_MODEL from 'modules/campaigns/config/pricingModel';
import { BID_STRATEGIES, SPENDING_LIMITS } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CAMPAIGN_DIMENSION, isProgrammaticCampaign, isVideoCampaign } from 'services/campaignDimension';
import generateColumnId from '../../utils/generateColumnId';

const SPENDING_LIMIT_FIELD = 'spendingLimit';

const isCampaignEditFormDisabled = ({ status = '' }, userPermissions = {}, dimension = CAMPAIGN_DIMENSION.SPONSORED) =>
    (status === CAMPAIGN_STATUS.TERMINATED && !userPermissions['ADS_CONSOLE_VIEW_TERMINATED_CAMPAIGNS']) ||
    (isVideoCampaign(dimension) && !userPermissions['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN']) ||
    (!userPermissions['CAMPAIGN_EDIT'] &&
        !userPermissions['EXCHANGE_CAMPAIGN_VIEW_AND_EDIT'] &&
        !userPermissions['NATIVE_CAMPAIGN_VIEW_AND_EDIT']);

const isCampaignDuplicationDisabled = (data, userPermissions = {}, dimension = CAMPAIGN_DIMENSION.SPONSORED) =>
    isCampaignEditFormDisabled(data, userPermissions, dimension);

const isCampaignEditCellDisabled = (
    { status = '', isPartOfSharedBudgetCampaignsGroup, budget },
    userPermissions = {},
    colId
) => status === CAMPAIGN_STATUS.TERMINATED || hasNoSpendingLimitType(budget, colId);

const hasNoSpendingLimitType = (budget, colId) =>
    !!budget &&
    colId === generateColumnId(REPORT_TYPE.CAMPAIGN, SPENDING_LIMIT_FIELD) &&
    get(budget, 'spendingLimit.type', null) === null;

const isCampaignRejected = ({ status = '' }) => status === CAMPAIGN_STATUS.REJECTED;

const isCampaignDeleted = campaign => campaign?.status === CAMPAIGN_STATUS.TERMINATED;

const isCampaignEditable = ({ data = {} }) => !isCampaignEditCellDisabled(data) && !isCampaignRejected(data);

const isCampaignGroupEditable = ({ data = {}, context = {} }) => {
    const { userPermissions = {}, selectedAccountConfig = {} } = context;
    const { displayStatus = '' } = data;
    return (
        displayStatus !== CAMPAIGN_STATUS.TERMINATED &&
        displayStatus !== CAMPAIGN_STATUS.REJECTED &&
        userPermissions['SHARED_BUDGET_EDIT'] &&
        selectedAccountConfig?.[FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED] === 'true'
    );
};

const isSpendingLimitEditable = ({ data = {} }) => {
    const spendingLimitType = get(data, 'budget.spendingLimit.type', null);
    return isCampaignEditable({ data }) && SPENDING_LIMITS.NONE !== spendingLimitType;
};

const isVideoCampaignEditable = ({ data = {}, context }) => {
    const { userPermissions } = context;
    return isCampaignEditable({ data }) && userPermissions['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'];
};

const isBidEditable = ({ data = {}, context: { userPermissions } }) => {
    const { bid, pricingModel, bidStrategy } = data;

    if (isNil(bid)) {
        return false;
    }

    const isCpcPermitted = pricingModel === PRICING_MODEL.CPC;
    const isCpmPermitted = pricingModel === PRICING_MODEL.CPM && userPermissions['DIRECT_CAMPAIGN_CPM_EDIT'];
    const isVcpmPermitted = pricingModel === PRICING_MODEL.VCPM && userPermissions['DIRECT_CAMPAIGN_VCPM_EDIT'];
    const isTargetCpa = bidStrategy === BID_STRATEGIES.TARGET_CPA;

    return isCampaignEditable({ data }) && !isTargetCpa && (isCpcPermitted || isCpmPermitted || isVcpmPermitted);
};

const isBudgetEditable = data => {
    const {
        data: { demandType },
    } = data;

    if (isProgrammaticCampaign(demandType)) {
        return false;
    }

    return isCampaignEditable(data);
};

const isCpaGoalEditable = ({ data = {} }) => {
    const { bidStrategy } = data;
    return isCampaignEditable({ data }) && bidStrategy === BID_STRATEGIES.TARGET_CPA;
};

const isCpcCapEditable = ({ data = {}, context = {} }) => {
    const { selectedAccountConfig = {} } = context;
    const { bidStrategy } = data;

    return (
        isCampaignEditable({ data }) &&
        bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS &&
        selectedAccountConfig?.[FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER] === 'true'
    );
};

const isVideoBudgetEditable = ({ data = {}, context }) => {
    const { userPermissions } = context;
    return isBudgetEditable({ data }) && userPermissions['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'];
};

const activeRecommendationsCount = ({ recommendationCounts = '' }) => recommendationCounts?.activeRecommendations;

const appliedRecommendationsCount = ({ recommendationCounts = '' }) => recommendationCounts?.appliedRecommendations;

const isCampaignRecommendationsDisabled = ({ recommendationCounts = '' }, userPermissions = {}) =>
    (!activeRecommendationsCount({ recommendationCounts }) && !appliedRecommendationsCount({ recommendationCounts })) ||
    !userPermissions['CAMPAIGN_PERFORMANCE_RECOMMENDATIONS'];

export {
    isCampaignEditFormDisabled,
    isCampaignDuplicationDisabled,
    isCampaignEditCellDisabled,
    isCampaignRejected,
    isCampaignEditable,
    isCampaignGroupEditable,
    isCampaignDeleted,
    isBidEditable,
    isCpaGoalEditable,
    isBudgetEditable,
    isCampaignRecommendationsDisabled,
    activeRecommendationsCount,
    appliedRecommendationsCount,
    isVideoCampaignEditable,
    isVideoBudgetEditable,
    isSpendingLimitEditable,
    isCpcCapEditable,
};
