import { useCallback, useMemo } from 'react';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { CREATIVE_STUDIO_MODES } from '../../CreativeStudio/creativeStudioModes';
import { PREVIEW_DRAWER_EVENT_PREFIX, PREVIEW_DRAWER_MODE_PREFIXES } from '../previewsDrawerConstants';
import { PREVIEWS_DRAWER_OPTIONS_CONFIGS } from '../previewsDrawerOptions';

export const usePreviewDrawerOptions = ({
    closeModal,
    openMotionStudioModal,
    openCreativeStudioModal,

    src,
    originalItem,
    accountId,
    campaignId,
    addImageHandler,
    closeCreativeStudioModal,
    urlTransformer,
    shouldDisableMotionAdsButton,
}) => {
    const openCreativeStudioModalWithMode = useCallback(
        mode => {
            openCreativeStudioModal({
                mode,
                src,
                originalItem,
                accountId,
                campaignId,
                addImageHandler,
                closeCreativeStudioModal,
                urlTransformer,
            });
        },
        [
            accountId,
            addImageHandler,
            campaignId,
            closeCreativeStudioModal,
            openCreativeStudioModal,
            originalItem,
            src,
            urlTransformer,
        ]
    );
    const motionAdsOption = useMemo(
        () => ({
            ...PREVIEWS_DRAWER_OPTIONS_CONFIGS.MOTION_ADS,
            onClick: () => {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${PREVIEW_DRAWER_EVENT_PREFIX}_${PREVIEW_DRAWER_MODE_PREFIXES.MOTION_ADS}: Try It Now Clicked`,
                    taboolaCampaignId: campaignId,
                });
                closeModal(true);
                openMotionStudioModal(src);
            },
            shouldDisableButton: shouldDisableMotionAdsButton,
        }),
        [shouldDisableMotionAdsButton, campaignId, closeModal, openMotionStudioModal, src]
    );
    const imageVariationsOption = useMemo(
        () => ({
            ...PREVIEWS_DRAWER_OPTIONS_CONFIGS.IMAGE_VARIATIONS,
            onClick: () => {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${PREVIEW_DRAWER_EVENT_PREFIX}_${PREVIEW_DRAWER_MODE_PREFIXES.IMAGE_VARIATIONS}: Try It Now Clicked`,
                    taboolaCampaignId: campaignId,
                });
                openCreativeStudioModalWithMode(CREATIVE_STUDIO_MODES.IMAGE_VARIATIONS);
            },
        }),
        [campaignId, openCreativeStudioModalWithMode]
    );
    const replaceBackgroundOption = useMemo(
        () => ({
            ...PREVIEWS_DRAWER_OPTIONS_CONFIGS.REPLACE_BACKGROUND,
            onClick: () => {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${PREVIEW_DRAWER_EVENT_PREFIX}_${PREVIEW_DRAWER_MODE_PREFIXES.REPLACE_BACKGROUND}: Try It Now Clicked`,
                    taboolaCampaignId: campaignId,
                });
                openCreativeStudioModalWithMode(CREATIVE_STUDIO_MODES.REPLACE_BACKGROUND);
            },
        }),
        [campaignId, openCreativeStudioModalWithMode]
    );

    return [motionAdsOption, imageVariationsOption, replaceBackgroundOption];
};
