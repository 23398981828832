import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { BareDropdown, useElementSize } from 'taboola-ultimate-ui';
import { TargetingTypeBareDropdown } from '../TargetingTypeDropdown';
import styles from './targetingDropdownBox.module.scss';

const classNameBuilder = classnames.bind(styles);

const TargetingDropdownBox = ({
    itemType,
    allItemTypes,
    itemTypeOptionRenderer,
    onSelectItemType,
    isItemTypeDisabled,
    allItems,
    MainDropDownComponent,
    onAddItem,
    shouldRenderItemValue,
    getItemOptionLabel,
    isItemsLoading,
    itemPlaceholder,
    itemOptionRenderer,
    itemSelectionDisabled,
    mainDropDownComponentProps,
}) => {
    const { width, ref } = useElementSize();
    const itemStyles = useMemo(
        () => ({
            menu: provided => ({
                ...provided,
                width: width || '100%',
                right: 0,
            }),
            placeholder: () => ({
                color: isItemTypeDisabled ? styles.gray7 : styles.gray6,
            }),
        }),
        [isItemTypeDisabled, width]
    );

    return (
        <div
            className={classNameBuilder('container', {
                disabled: isItemTypeDisabled,
            })}
            ref={ref}
        >
            <TargetingTypeBareDropdown
                value={itemType}
                options={allItemTypes}
                optionItemRenderer={itemTypeOptionRenderer}
                onChange={onSelectItemType}
                disabled={isItemTypeDisabled}
            />
            <div className={styles['delimiter']} />
            <MainDropDownComponent
                options={isItemsLoading ? [{}] : allItems}
                optionItemRenderer={isItemsLoading ? () => <Spinner size={21} /> : itemOptionRenderer}
                onChange={isItemsLoading ? () => {} : onAddItem}
                className={isItemsLoading ? classNameBuilder('item-wrapper', 'prioritized', 'more-prioritized') : ''}
                disabled={itemSelectionDisabled}
                controlShouldRenderValue={shouldRenderItemValue}
                searchable
                placeholder={itemPlaceholder}
                skipLeftBorderRadius
                getOptionLabel={getItemOptionLabel}
                styles={itemStyles}
                {...mainDropDownComponentProps}
            />
        </div>
    );
};

TargetingDropdownBox.propTypes = {
    itemType: PropTypes.string,
    shouldRenderItemTypeValue: PropTypes.bool,
    isItemTypeDisabled: PropTypes.bool,
    onSelectItemType: PropTypes.func.isRequired,
    shouldRenderItemValue: PropTypes.bool,
    itemSelectionDisabled: PropTypes.bool,
    allItems: PropTypes.array,
    onAddItem: PropTypes.func.isRequired,
    isItemsLoading: PropTypes.bool,
    itemPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    itemOptionRenderer: PropTypes.func,
    getItemOptionLabel: PropTypes.func,
    MainDropDownComponent: PropTypes.func,
    mainDropDownComponentProps: PropTypes.object,
};

TargetingDropdownBox.defaultProps = {
    allItems: [],
    shouldRenderItemValue: false,
    isItemTypeDisabled: false,
    itemSelectionDisabled: false,
    isItemsLoading: false,
    MainDropDownComponent: BareDropdown,
    mainDropDownComponentProps: {},
    getItemOptionLabel: option => option.label,
};

export default TargetingDropdownBox;
