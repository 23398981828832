import moment from 'moment-timezone';
import uuid from 'uuid/v1';
import { PRICING_MODEL } from '../../../../config';
import DAYPARTING_DEFAULT from '../../components/Dayparting/config/daypartingDefault';
import TARGETING_TYPES from '../TargetingTypes';
import COUNTRY_TARGETING_LEVELS from '../countryTargetingLevels';
import { NO_HIP_TARGETING_ID } from '../highImpactPackageOptions';
import { PLATFORMS_KEYS } from '../platforms';
import RUNTIME_TYPES from '../runtimeTypes';

export const pmpDealCampaignInitialValues = {
    startDate: () => moment(),
    premiumSiteTargeting: TARGETING_TYPES.ALL,
    brandSafetyTargeting: TARGETING_TYPES.ALL,
    campaignSchedule: {
        mode: RUNTIME_TYPES.ALWAYS,
        rules: [...DAYPARTING_DEFAULT],
        timeZone: dependencies => dependencies?.account?.timeZoneName,
    },
    campaignTargeting: {
        connectionTypeTargeting: { type: TARGETING_TYPES.ALL, values: [] },
        osTargeting: { type: TARGETING_TYPES.INCLUDE, values: [] },
        geoTargeting: {
            countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
            countryTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            postalCodeTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
            sectionTargeting: {
                type: TARGETING_TYPES.INCLUDE,
                values: [],
            },
        },
        marketplaceAudienceTargeting: {
            collection: [{ type: TARGETING_TYPES.INCLUDE, values: [], id: uuid() }],
            validTargeting: true,
        },
        contextualSegmentsTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        myAudienceTargeting: {
            include: [],
            exclude: ({ defaultExcludedListOfMyAudience }) => defaultExcludedListOfMyAudience,
            validTargeting: true,
        },
        customContextualTargeting: {
            include: [],
            exclude: [],
            validTargeting: true,
        },
        highImpactPackageTargetingId: NO_HIP_TARGETING_ID,
        publisherGroupsTargeting: {
            type: TARGETING_TYPES.ALL,
            values: [],
        },
        browserTargeting: {
            type: TARGETING_TYPES.INCLUDE,
            values: [],
        },
    },
    thirdPartyTags: [],
    pricingModel: PRICING_MODEL.VCPM,
    thirdPartyBrandSafetyTargeting: {
        type: ({ defaultThirdPartyBrandSafetyType }) => defaultThirdPartyBrandSafetyType,
        values: [],
    },
    platformTargeting: PLATFORMS_KEYS,
};
