import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from 'taboola-ultimate-ui';
import styles from './loadMoreNode.module.scss';

const LoadMoreNode = ({ onClick, title }) => (
    <StyledButton
        onClick={e => {
            e.stopPropagation();
            onClick();
        }}
        className={styles['load-more']}
    >
        {title}
    </StyledButton>
);

LoadMoreNode.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default LoadMoreNode;
