import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import PixelBasedAudienceUrlConditionsField from '../PixelBasedAudienceUrlConditionsField/PixelBasedAudienceUrlConditionsField';

export const PixelBasedAudienceUrlConditionsSection = () => (
    <CommonCollapsibleCard
        id="PIXEL_AUDIENCE_CONDITIONS"
        header={
            <SectionHeader
                headerText={
                    <FormattedMessage
                        id="audience.editor.pixel.based.conditions.section.title"
                        defaultMessage="Audience Conditions"
                    />
                }
            />
        }
    >
        <PixelBasedAudienceUrlConditionsField />
    </CommonCollapsibleCard>
);

export default PixelBasedAudienceUrlConditionsSection;
