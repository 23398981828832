import { isObject, isEmpty } from 'lodash';
import { AUDIENCE_STATUS, AUDIENCE_TYPE } from '../../../../../audiences/config';

const isAllWebsiteVisits = audienceName => audienceName === 'All Website Visits';

const isAudienceEditFormDisabled = (data, account, isAudienceArchived) => {
    const { accountPublisherId, category, conditions, audienceName, status, isEditable = true } = data;
    const hasComplexCondition =
        !isEmpty(conditions) &&
        JSON.parse(conditions).filter(condition => {
            try {
                const parsed = JSON.parse(condition.url);
                return isObject(parsed);
            } catch (e) {
                return false;
            }
        }).length > 0;

    return (
        account.id !== accountPublisherId ||
        isAudienceArchived ||
        status === AUDIENCE_STATUS.DISABLED ||
        (category && category.includes('ENGAGEMENT')) ||
        isAllWebsiteVisits(audienceName) ||
        hasComplexCondition ||
        !isEditable
    );
};

const isAudienceDefaultExcludeDisabled = (data, account, isAudienceArchived) => {
    const { accountPublisherId, category } = data;
    return account.id !== accountPublisherId || (category && category.includes('ENGAGEMENT')) || isAudienceArchived;
};

const isAudienceChangingStateDisabled = (data, account, isAudienceArchived) => {
    const { accountPublisherId, campaignUsing, type, category, audienceName } = data;
    return (
        account.id !== accountPublisherId ||
        (category && category.includes('ENGAGEMENT')) ||
        isAllWebsiteVisits(audienceName) ||
        (!isAudienceArchived && campaignUsing !== '0') ||
        (isAudienceArchived && isPredictiveAudience(type))
    );
};

const isPredictiveAudience = audienceType =>
    audienceType === AUDIENCE_TYPE.LOOKALIKE ||
    audienceType === AUDIENCE_TYPE.PIXEL_LOOKALIKE ||
    audienceType === AUDIENCE_TYPE.PBP_LOOKALIKE;

export {
    isAudienceEditFormDisabled,
    isAudienceDefaultExcludeDisabled,
    isAudienceChangingStateDisabled,
    isPredictiveAudience,
};
