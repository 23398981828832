import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.PREDICTIVE].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.AUDIENCE_NAME.field,
        FIELDS.ACCOUNT_DESCRIPTION.field,
        FIELDS.AUDIENCE_TYPE.field,
        FIELDS.STATUS.field,
        FIELDS.AUDIENCE_DETAILS.field,
        FIELDS.AUDIENCE_SIZE.field,
        FIELDS.AUDIENCE_CAMPAIGN_USING.field,
        FIELDS.LAST_MODIFY_BY.field,
        FIELDS.LAST_MODIFY_ON.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.AUDIENCE_NAME.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.AUDIENCE_TYPE.field,
    FIELDS.STATUS.field,
    FIELDS.AUDIENCE_DETAILS.field,
    FIELDS.AUDIENCE_SIZE.field,
    FIELDS.AUDIENCE_CAMPAIGN_USING.field,
    FIELDS.LAST_MODIFY_BY.field,
    FIELDS.LAST_MODIFY_ON.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
