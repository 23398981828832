import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import CurrencyIcon from 'modules/campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { withIndication } from 'modules/errors';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { FixedValueTooltip } from './FixedValueTooltip/FixedValueTooltip';
import styles from '../commonStyle.module.scss';

const inputPadding = parseInt(styles.currencyInputLeftPadding);

const InputWithIndications = withIndication(Input);

export const FixedValueField = () => {
    const {
        formAccount: { currency },
    } = useFormDataContext();
    const {
        value: fixedValue,
        onChange: onChangeFixValue,
        indicationData,
        scrollRef: inputScrollRef,
    } = useFormValidatedValue({
        field: 'fixedValue',
    });

    const changeHandler = useEventValueHandler(onChangeFixValue);

    return (
        <FormField
            inputId="fixed-value"
            label={
                <FormattedMessage
                    id="tracking.conversion.creator.properties.fixed.value.label"
                    defaultMessage="Fixed Value (optional)"
                />
            }
            description={
                <FormattedMessage
                    id="tracking.conversion.creator.properties.fixed.value.description"
                    defaultMessage="A value will help you measure the return on ad spend for this conversion."
                />
            }
            subtext={
                <FormattedMessage
                    id="tracking.conversion.creator.properties.fixed.value.subtext"
                    defaultMessage="To use dynamic values mark the checkbox below, under the event code."
                />
            }
            helpText={<FixedValueTooltip />}
        >
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <InputWithIndications
                        onChange={changeHandler}
                        icon={<CurrencyIcon currency={currency} />}
                        type={InputTypes.NUMBER}
                        inputPadding={inputPadding}
                        placeholder={placeholder}
                        id="fixed-value"
                        value={fixedValue ?? ''}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="ConversionFixValue"
                        ref={inputScrollRef}
                        {...indicationData}
                    />
                )}
            </FormattedNumber>
        </FormField>
    );
};
