import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppHeader as ExternalAppHeader } from 'taboola-ultimate-ui';
import { ACCOUNT_ID } from 'hooks';
import { useNonAuthAccess } from 'hooks/useNonAuthAccess';
import { currentRootAccountSelector } from 'selectors/index';
import { navigate } from '../../actions';
import { FEATURE_FLAGS, useConfigMatch } from '../../modules/taboola-common-frontend-modules/account-configurations';
import { logout } from '../../modules/taboola-common-frontend-modules/authentication';
import { isIframeMode } from '../../modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import ButtonsPanel from '../ButtonsPanel';
import { NavigationItems } from '../NavigationItems';
import { HOME_PATH } from '../RouteList/RouteList';
import useAvailableRoutes from '../RouteList/hooks/useAvailableRoutes';
import Logo from './components/Logo';
import { useShowHeader } from './hooks/useShowHeader';
import styles from './appHeader.module.scss';

export const LOGOUT_PATH = '/logout';

const AppHeader = ({ config, height, showButtonsPanel, ...rest }) => {
    const { legal, routingMap, externalApps } = config;
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    const currentRootAccount = useSelector(currentRootAccountSelector);
    const {
        appHeaderContainerClass,
        appHeaderBackgroundClass,
        navItemsContainerClass,
        navitemContainerClass,
        appHeaderActiveItemClassName,
        appHeaderIconsClass,
        appHeaderBrandingClass,
    } = rest;

    const availableRoutes = useAvailableRoutes(routingMap);
    const navigateAction = useCallback((...args) => dispatch(navigate(...args)), [dispatch]);

    const handleLogoutButtonClick = useCallback(() => navigateAction(history, LOGOUT_PATH), [history, navigateAction]);

    const showHeaderForModule = useShowHeader();
    const iFrameMode = isIframeMode();
    const isAppHeaderStyleEnabled = useConfigMatch({ [FEATURE_FLAGS.ENABLE_YAHOO_APP_HEADER_STYLE]: 'true' });
    const showHeader = showHeaderForModule && (!iFrameMode || isAppHeaderStyleEnabled);
    const nonAuthAccess = useNonAuthAccess();

    // Once we are on logout path we do log out
    useEffect(() => {
        if (pathname !== LOGOUT_PATH) {
            return;
        }

        dispatch(logout());
    }, [pathname, dispatch]);

    if (!showHeader) {
        return null;
    }

    return (
        <ExternalAppHeader
            brandingWidth={0}
            logoComponent={
                <Logo
                    path={HOME_PATH}
                    params={{ [ACCOUNT_ID]: currentRootAccount?.accountId }}
                    paramsToKeep={[]}
                    className={styles['taboola-logo']}
                    color={styles.defaultLogoColor}
                    aria-label="Taboola Ads Logo"
                />
            }
            contentClassName={styles['content-container']}
            height={Number(height)}
            zIndex={2}
            backgroundColor={appHeaderBackgroundClass ? styles.headerBackgroundColor : undefined}
            brandingClass={appHeaderBrandingClass}
            className={appHeaderContainerClass}
        >
            {!nonAuthAccess && (
                <NavigationItems
                    navItems={availableRoutes}
                    className={navItemsContainerClass}
                    itemsClassName={navitemContainerClass}
                    activeClassName={appHeaderActiveItemClassName}
                    iconClass={appHeaderIconsClass}
                />
            )}
            {!nonAuthAccess && showButtonsPanel && (
                <ButtonsPanel
                    availableRoutes={availableRoutes}
                    externalApps={externalApps}
                    onLogoutButtonClick={handleLogoutButtonClick}
                    legal={legal}
                />
            )}
        </ExternalAppHeader>
    );
};

AppHeader.propTypes = {
    /** General configurations */
    config: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showButtonsPanel: PropTypes.bool,
};

export default AppHeader;
