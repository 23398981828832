import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const spendingLimitValidations = [
    {
        validationFn: validationFunctions.range,
        options: { gt: 'monthlyBudgetValue' },
        messageId: ({ type }) => `validations.error.campaign.spendingLimit.${type}.largerThanBudget31Times`,
        defaultMessage: 'Make sure your {type} Spending Limit is sufficient considering your Daily Budget.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.spendingLimit.empty',
        defaultMessage: 'Enter a {type}',
    },
    {
        validationFn: validationFunctions.isFloat,
        options: { min: 1 },
        messageId: 'validations.error.campaign.spendingLimit.minValue',
        defaultMessage: '{type} must be a positive number above 1.',
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 'minSpendingLimit' },
        messageId: 'validations.error.campaign.spendingLimit.largerThanBudgetLimitRatio',
        defaultMessage: '{type} must be at least {minSpendingLimit} {currency} with this budget',
    },
    {
        validationFn: validationFunctions.range,
        options: { gt: 'budgetValue' },
        messageId: 'validations.error.campaign.spendingLimit.largerThanBudget',
        defaultMessage: '{type} must be larger than daily budget',
    },
];

export default spendingLimitValidations;
