import { useCallback } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import TargetingTypes from '../../../config/TargetingTypes';
import { useCurrentCampaignTargetingByType } from '../utils/useCurrentCampaignTargetingByType';
import { useSiteTargeting } from './useSiteTargeting';

const siteListKey = 'included-sites';

export const useIncludedSites = (onChangeTargetingType, hasPermission) => {
    const { isDirty } = useFormFieldValue({ field: 'campaignTargeting.publisherTargeting.exclude' });
    const onChangeHandler = useCallback(() => onChangeTargetingType(TargetingTypes.INCLUDE), [onChangeTargetingType]);
    const isCurrentCampaignTargeting = useCurrentCampaignTargetingByType();

    const props = useSiteTargeting({
        field: 'campaignTargeting.publisherTargeting.include',
        readonly: isDirty || !hasPermission,
        siteListKey,
        onChangeHandler,
        isCurrentCampaignTargeting,
    });

    return props;
};
