import React, { useState } from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { AdvancedSettingsSection } from '../components/PerformanceRuleSetupSection/AdvancedSettingsSection/AdvancedSettingsSection';
import { PerformanceRulesConditionAndActionSection } from '../components/PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/PerformanceRulesConditionAndActionSection';
import { PerformanceRulesSetupSection } from '../components/PerformanceRuleSetupSection/PerformanceRulesSetupSection';
import { PerformanceRulesFormBreadcrumbs } from '../components/PerformanceRulesFormBreadcrumbs/PerformanceRulesFormBreadcrumbs';
import { RuleSimulator } from '../components/RuleSimulator/RuleSimulator';
import { RuleSummarySection } from '../components/RuleSimulator/RuleSummarySection';
import { performanceRulesInitialValues } from '../config/initialValues/performanceRulesInitialValues';
import { usePerformanceRulesCreatorState } from '../hooks/usePerformanceRulesCreatorState';
import styles from './PerformanceRulesCreatorPage.module.scss';

export const PerformanceRulesCreatorPage = ({ onCancel }) => {
    const { submit, step, setStep, prevStep, nextStep } = usePerformanceRulesCreatorState();
    const shouldShowPerformanceRulesPreview = useConfigMatch({
        [FEATURE_FLAGS.PERFORMANCE_RULES_PREVIEW_ENABLED]: 'true',
    });
    const [isSimulatorLoading, setIsSimulatorLoading] = useState(true);
    const { isSubmitDisabled } = useFormDataContext();
    const shouldDisableSubmit = isSubmitDisabled || isSimulatorLoading;

    return (
        <FormInitBase>
            <Wizard wizardContentClassName={styles['wizard-content']} step={step} onStepChange={setStep}>
                <WizardStep
                    id="creator"
                    label={
                        <FormattedMessage
                            id="app.create.performance.rules.setup"
                            defaultMessage="Custom Rules Creator"
                        />
                    }
                >
                    <StaticIndications />
                    <PerformanceRulesFormBreadcrumbs
                        msgId="app.create.performance.rules.setup"
                        defaultMessage="Custom Rules Creator"
                    />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            id="setup"
                            label={
                                <FormattedMessage
                                    id="app.create.performance.rules.setup.step.title"
                                    defaultMessage="Custom Rules Setup"
                                />
                            }
                        >
                            <PerformanceRulesSetupSection />
                        </WizardStep>
                        <WizardStep
                            id="conditions-and-actions"
                            label={
                                <FormattedMessage
                                    id="app.create.performance.rules.conditions.and.actions.title"
                                    defaultMessage="Conditions And Actions"
                                />
                            }
                        >
                            <PerformanceRulesConditionAndActionSection />
                        </WizardStep>
                        <WizardStep
                            id="advanced-settings"
                            label={
                                <FormattedMessage
                                    id="app.create.performance.rules.advanced.settings.title"
                                    defaultMessage="Advanced Settings"
                                />
                            }
                        >
                            <AdvancedSettingsSection />
                        </WizardStep>
                        <div className={styles['buttons-wrapper']}>
                            <FormButtons
                                className={styles['form-buttons']}
                                onSubmit={shouldShowPerformanceRulesPreview ? nextStep : submit}
                                onCancel={onCancel}
                                submitDataMetricsComponent="NextButton"
                                submitLabel={
                                    shouldShowPerformanceRulesPreview ? (
                                        <FormattedMessage id="app.actionButtons.next" defaultMessage="Next" />
                                    ) : (
                                        <FormattedMessage
                                            id="app.performance.rules.actionButtons.save.rules"
                                            defaultMessage="Save Rule"
                                        />
                                    )
                                }
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                            />
                        </div>
                    </div>
                </WizardStep>
                {shouldShowPerformanceRulesPreview && (
                    <WizardStep
                        id="Previewe-section"
                        label={
                            <FormattedMessage
                                id="app.create.performance.rules.Preview"
                                defaultMessage="Custom Rules Preview"
                            />
                        }
                    >
                        <StaticIndications />
                        <PerformanceRulesFormBreadcrumbs
                            msgId="app.create.performance.rules.Preview"
                            defaultMessage="Custom Rules Preview"
                        />
                        <div className={styles['form-wrapper']}>
                            <WizardStep
                                id="summary"
                                label={
                                    <FormattedMessage
                                        id="performance.rule.summary.header.text"
                                        defaultMessage="Rule Summary"
                                    />
                                }
                            >
                                <RuleSummarySection />
                            </WizardStep>
                            <WizardStep
                                id="preview"
                                label={
                                    <FormattedMessage
                                        id="performance.rule.preview.header.text"
                                        defaultMessage="Rule Preview"
                                    />
                                }
                            >
                                <RuleSimulator setIsLoading={setIsSimulatorLoading} />
                            </WizardStep>
                            <div className={styles['buttons-wrapper']}>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={submit}
                                    onCancel={onCancel}
                                    onBack={prevStep}
                                    disableSubmit={shouldDisableSubmit}
                                    backLabel={<FormattedMessage id="app.actionButtons.back" />}
                                    submitDataMetricsComponent="NextButton"
                                    submitLabel={
                                        <FormattedMessage
                                            id="app.performance.rules.actionButtons.save.rules"
                                            defaultMessage="Save Rule"
                                        />
                                    }
                                    cancelLabel={
                                        <FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />
                                    }
                                />
                            </div>
                        </div>
                    </WizardStep>
                )}
            </Wizard>
        </FormInitBase>
    );
};

const PerformanceCreatorPageWithFormDataProvider = withFormDataProvider(PerformanceRulesCreatorPage, {
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    defaultValuesConfig: performanceRulesInitialValues,
});

export { PerformanceCreatorPageWithFormDataProvider as PerformanceCreatorPage };
