export const SHEET_NAME = {
    CAMPAIGNS: 'CAMPAIGNS',
    CREATIVES: 'CREATIVES',
    METADATA: 'METADATA',
    COUNTRY_CODES: 'COUNTRY_CODES',
    REGION_CODES: 'REGION_CODES',
    DMA_CODES: 'DMA_CODES',
    CITY_CODES: 'CITY_CODES',
    CONTEXTUAL_SEGMENTS: 'CONTEXTUAL_SEGMENTS',
    MARKETPLACE_AUDIENCES: 'MARKETPLACE_AUDIENCES',
    MY_AUDIENCES: 'MY_AUDIENCES',
    OS: 'OS',
    BROWSERS: 'BROWSERS',
    BRAND_SAFETY_DV: 'BRAND_SAFETY_DV',
    BRAND_SAFETY_IAS: 'BRAND_SAFETY_IAS',
    CONVERSION_EVENTS: 'CONVERSION_EVENTS',
    TIME_ZONES: 'TIME_ZONES',
    CAMPAIGN_GROUPS: 'CAMPAIGN_GROUPS',
    CTA_TYPE: 'CTA_TYPE',
    ACCOUNTS: 'ACCOUNTS',
};
