import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

export const TotalImpressionCapFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.TotalImpressionCap.tooltip"
            defaultMessage="Total Impressions Cap is a lifetime limitation of Video Impressions. Relevant for PMPs only."
        />
    </TooltipSection>
);
