import { ExtendedComponent } from 'tuui/lib/utils/typing';
import { Tag as Base, TagProps } from './Tag';

export const Tag: ExtendedComponent<typeof Base, Pick<TagProps, 'variant' | 'size' | 'color'>> = Object.assign(Base, {
    variant: {
        filled: 'filled',
        outlined: 'outlined',
    },
    size: {
        sm: 'sm',
        md: 'md',
    },
    color: {
        primary: 'primary',
        secondary: 'secondary',
        error: 'error',
        warning: 'warning',
    },
    displayName: 'Tag',
} as const);

export type { TagProps } from './Tag';
