import React, { forwardRef } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { InternalLink } from 'components/InternalLink';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './internalLinkCellRenderer.module.scss';

const InternalLinkCellRenderer = forwardRef(({ to, paramsToKeep, paramsFactory = noop, ...props }, ref) => {
    const { data } = props;
    return (
        <InternalLink path={to} params={paramsFactory(data)} paramsToKeep={paramsToKeep} className={styles.link}>
            <EllipsisCellRenderer ref={ref} {...props} />
        </InternalLink>
    );
});

InternalLinkCellRenderer.propTypes = {
    ...EllipsisCellRenderer.propTypes,
    to: PropTypes.string.isRequired,
    // A function which should return the required query params based on row's data
    paramsFactory: PropTypes.func,
    paramsToKeep: PropTypes.array,
    value: PropTypes.node,
    children: PropTypes.node,
};

export default InternalLinkCellRenderer;
