import { get } from 'lodash';
import { routingConfigStore } from '../config/routingConfigStore';
import { useModuleName } from './useModuleName';

export const useNonAuthAccess = () => {
    const moduleName = useModuleName();
    const routingConfig = routingConfigStore.getValue();
    const nonAuthAccess = get(routingConfig, `routingMap.${moduleName}.nonAuthAccess`, false);

    return nonAuthAccess;
};
