import moment from 'moment';
import { PRICING_MODEL } from 'modules/campaigns/config';
import { BUDGET_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import {
    FEATURE_FLAGS,
    useAccountConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useStartDateFormFieldValue } from '../../TimeFrame/hooks/useStartDateFormFieldValue';
import { useBudgetFormFieldValue } from './useBudgetFormFieldValue';

export const NUMBER_OF_DAYS_IN_MONTH = 31;

export const getBidRatio = ({
    pricingModel,
    budget: { type: budgetType } = {},
    budgetLimitationThreshold,
    startDate,
    endDate,
}) => {
    if (pricingModel === PRICING_MODEL.CPC && budgetType) {
        if (budgetType === BUDGET_TYPES.DAILY) {
            return budgetLimitationThreshold;
        }
        if (budgetType === BUDGET_TYPES.LIFETIME && endDate) {
            return budgetLimitationThreshold * (moment(endDate).diff(moment(startDate), 'days') + 1);
        }
        return budgetLimitationThreshold * NUMBER_OF_DAYS_IN_MONTH;
    }
    return null;
};

export const useBidValidationRatio = data => {
    const { value: budget } = useBudgetFormFieldValue();
    const { value: pricingModel } = useFormFieldValue({ field: 'pricingModel' });
    const { value: startDate } = useStartDateFormFieldValue();
    const { value: endDate } = useFormFieldValue({ field: 'endDate' });
    const { [FEATURE_FLAGS.ACCOUNT_BUDGET_LIMITATION_THRESHOLD]: budgetLimitationThreshold = 0 } = useAccountConfig([
        FEATURE_FLAGS.ACCOUNT_BUDGET_LIMITATION_THRESHOLD,
    ]);
    return getBidRatio({
        budgetLimitationThreshold: JSON.parse(budgetLimitationThreshold),
        budget,
        pricingModel,
        startDate,
        endDate,
        ...data,
    });
};
