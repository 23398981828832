import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useThumbnailsPickerData } from 'modules/campaigns/modules/creative-creator/hooks/useThumbnailsPickerData';
import { MediaPicker } from 'modules/campaigns/modules/creative-editor/components/MediaPicker/MediaPicker';
import SelectedThumbnailsContainer from './SelectedThumbnails/SelectedThumbnailsContainer';
import styles from './thumbnailsPicker.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ThumbnailsPicker = ({
    thumbnailList,
    setCreativeFocus,
    columnCount,
    onSelectMedia,
    motionIdFallbackSelecting,
    startSelectFallbackImage,
    cancelSelectFallbackImage,
    deleteItem,
    campaignId,
    titleList,
    openCropModal,
}) => {
    const { fileTypes, isEmptyThumbnailList } = useThumbnailsPickerData(thumbnailList);

    return (
        <MediaPicker
            fileTypes={fileTypes}
            onSelectMedia={onSelectMedia}
            onCancel={cancelSelectFallbackImage}
            tabsContext={{ thumbnails: thumbnailList, campaignId, titleList }}
            tabsDisabled={!!motionIdFallbackSelecting}
            disabled={!!motionIdFallbackSelecting}
            openCropModal={openCropModal}
        >
            <div className={classNameBuilder('container', { 'container-active': !isEmptyThumbnailList })}>
                <div className={styles['gallery-section']}>
                    <SelectedThumbnailsContainer
                        thumbnailList={thumbnailList}
                        onRemoveThumbnailById={deleteItem}
                        className={styles['gallery-thumbnails']}
                        setCreativeFocusInReducer={setCreativeFocus}
                        isEmpty={isEmptyThumbnailList}
                        columnCount={columnCount}
                        onEditFallbackImageClick={startSelectFallbackImage}
                        onSelectMedia={onSelectMedia}
                        cancelSelectFallbackImage={cancelSelectFallbackImage}
                        motionIdFallbackSelecting={motionIdFallbackSelecting}
                        openCropModal={openCropModal}
                    />
                </div>
            </div>
        </MediaPicker>
    );
};

ThumbnailsPicker.propTypes = {
    thumbnailList: PropTypes.array,
    deleteItem: PropTypes.func,
    setCreativeFocus: PropTypes.func,
    columnCount: PropTypes.number,
};

ThumbnailsPicker.defaultProps = {
    thumbnailList: [],
};
