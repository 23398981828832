import React, { useCallback, useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { isUndefined, isNil, omit, isFunction, isEqual } from 'lodash';
import { useImmer } from 'use-immer';
import { BASE_FORM_ROUTE_PATH_OPTIONALS } from 'config/routes/routeTypes';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { useStorage } from '../storage/useStorage';
import { HISTORY_METHOD } from './QueryParamBatcher';
import { useQueryParamBatcher } from './QueryParamBatcherContext';
import { useSingleValueStorageCleaner } from './hooks/useSingleValueStorageCleaner';
import { getQueryParam } from './queryParamUtils';
import {
    useSyncParamNamesMap,
    getParamDefaultValue,
    defaultStorageKeyGetter,
    getParamOptions,
    getParamValue,
    getExtendedMetadataWithUnregisteredParamName,
} from './queryParamsProviderUtils';

export const QueryParamsContext = React.createContext({});

let counter = 0;

export const QueryParamsProvider = ({ children }) => {
    const [paramNamesMap, setParamNamesMap] = useImmer({});
    useSyncParamNamesMap(paramNamesMap);
    const paramNamesMapGetter = useCurrentValueGetter(paramNamesMap);

    const [getStorage, setStorage, removeStorage, isStorageReady] = useStorage();
    const batcher = useQueryParamBatcher();
    const { search, pathname } = useLocation();
    const getCurrentSearch = useCurrentValueGetter(search);
    const { params: pathParams } = useMemo(() => matchPath(pathname, BASE_FORM_ROUTE_PATH_OPTIONALS) ?? {}, [pathname]);

    const metadata = useMemo(
        () => ({
            pathParams,
            paramNamesMap,
            getFromStorage: getStorage,
            getLocationSearch: getCurrentSearch,
        }),
        [getCurrentSearch, getStorage, paramNamesMap, pathParams]
    );

    const getDefaultValue = useCallback(paramName => getParamDefaultValue(paramName, metadata), [metadata]);

    const getStorageKey = useCallback(
        paramName => {
            const { storageKeyGetter = defaultStorageKeyGetter } = metadata.paramNamesMap[paramName] || {};

            return storageKeyGetter(paramName, metadata);
        },
        [metadata]
    );

    const getCurrent = useCallback(
        ({ search, ...rest }) => {
            const extendedMetadata = getExtendedMetadataWithUnregisteredParamName({ ...rest }, metadata);

            return getParamValue({ search: search ?? getCurrentSearch(), ...rest }, extendedMetadata);
        },
        [getCurrentSearch, metadata]
    );

    const getParam = useCallback(params => getCurrent({ search, ...params }), [getCurrent, search]);

    const setParamInitialValue = useCallback(
        ({ value, paramName, serializer }) => {
            batcher.commitChange(paramName, value, {
                serializer,
                method: HISTORY_METHOD.REPLACE,
                paramNamesMap: paramNamesMapGetter(),
            });
        },
        [batcher, paramNamesMapGetter]
    );

    const setParam = useCallback(
        ({ value, paramName, serializer, method, ...rest }) => {
            const extendedMetadata = getExtendedMetadataWithUnregisteredParamName(
                { paramName, serializer, ...rest },
                metadata
            );

            let valueToSet = value;
            if (isFunction(value)) {
                const params = getParamOptions(paramName, extendedMetadata);
                const currentValue = getCurrent({ paramName, ...params });
                valueToSet = value(currentValue);
            }

            setParamNamesMap(paramNamesMap => {
                if (paramNamesMap[paramName]) {
                    paramNamesMap[paramName].deleted = false;
                }
            });

            return batcher.enqueueChange(paramName, valueToSet, {
                serializer,
                method,
                paramNamesMap: paramNamesMapGetter(),
            });
        },
        [batcher, getCurrent, metadata, setParamNamesMap, paramNamesMapGetter]
    );

    const unsetParam = useCallback(
        async (paramName, applyToStorage = true) => {
            const queryValue = getQueryParam(getCurrentSearch(), paramName);
            const { persist } = getParamOptions(paramName, metadata);
            if (persist && applyToStorage) {
                const storageKey = getStorageKey(paramName);
                removeStorage(persist, storageKey);
            }

            if (isUndefined(queryValue)) {
                return;
            }

            setParamNamesMap(paramNamesMap => {
                if (paramNamesMap[paramName]) {
                    paramNamesMap[paramName].deleted = true;
                }
            });

            batcher.enqueueChange(paramName);
        },
        [batcher, getCurrentSearch, getStorageKey, metadata, removeStorage, setParamNamesMap]
    );

    // store object with count and configs of param
    const registerParam = useCallback(
        ({ paramName, order, ...rest }) => {
            setParamNamesMap(paramNamesMap => {
                if (!paramNamesMap[paramName]) {
                    paramNamesMap[paramName] = {
                        paramName,
                        deleted: false,
                        refCount: 0,
                        order: order ?? ++counter,
                        ...rest,
                    };
                }

                paramNamesMap[paramName].refCount += 1;
            });

            return () =>
                setParamNamesMap(paramNamesMap => {
                    paramNamesMap[paramName].refCount -= 1;
                });
        },
        [setParamNamesMap]
    );

    const value = useMemo(
        () => ({ set: setParam, get: getParam, registerParam, remove: unsetParam }),
        [getParam, registerParam, setParam, unsetParam]
    );

    useSingleValueStorageCleaner({ getStorageKey, paramNamesMap, removeStorage });

    useEffect(
        function syncStorageOrSetDefaultQueryParamsInToLocation() {
            Object.entries(paramNamesMap)
                .filter(([paramName, { refCount, deleted, deserializer, persist }]) => {
                    const queryValue = getQueryParam(search, paramName, deserializer);

                    if (refCount <= 0 || deleted || !isUndefined(queryValue)) {
                        return false;
                    }

                    if (!persist && isNil(getDefaultValue(paramName))) {
                        return false;
                    }

                    return true;
                })
                .forEach(([paramName, { refCount, persist, ...rest }]) => {
                    if (!persist) {
                        setParamInitialValue({
                            value: getDefaultValue(paramName),
                            ...rest,
                        });
                        return;
                    }

                    if (!isStorageReady) {
                        return;
                    }

                    const storageKey = getStorageKey(paramName);
                    const value = getStorage(persist, storageKey) ?? getDefaultValue(paramName);
                    if (isNil(value)) {
                        return;
                    }

                    setParamInitialValue({ value, ...rest });
                });
        },
        [search, isStorageReady, getStorage, paramNamesMap, getDefaultValue, getStorageKey, setParamInitialValue]
    );

    useEffect(
        function syncQueryParamsInToStorage() {
            if (!isStorageReady) {
                return;
            }

            Object.entries(paramNamesMap)
                .filter(([paramName, { refCount, deserializer, persist }]) => {
                    if (!persist || refCount < 1) {
                        return false;
                    }

                    const queryValue = getQueryParam(getCurrentSearch(), paramName, deserializer);
                    if (isUndefined(queryValue)) {
                        return false;
                    }

                    const storageKey = getStorageKey(paramName);
                    if (isNil(storageKey)) {
                        return false;
                    }

                    const storageValue = getStorage(persist, storageKey);

                    return !isEqual(queryValue, storageValue);
                })
                .forEach(([paramName, params]) => {
                    const value = getParam(params);
                    const storageKey = getStorageKey(paramName);
                    setStorage(params.persist, storageKey, value);
                });
        },
        [getParam, getCurrentSearch, isStorageReady, getStorage, setStorage, paramNamesMap, getStorageKey]
    );

    useEffect(
        function removeUnnecessaryQueryParams() {
            setParamNamesMap(paramNamesMap => {
                const entriesToRemove = Object.entries(paramNamesMap).filter(
                    ([paramName, { refCount, deserializer }]) => {
                        if (refCount > 0) {
                            return false;
                        }

                        const queryValue = getQueryParam(getCurrentSearch(), paramName, deserializer);
                        return !isUndefined(queryValue);
                    }
                );

                if (entriesToRemove.length === 0) {
                    return paramNamesMap;
                }

                entriesToRemove.forEach(([paramName]) => {
                    unsetParam(paramName, false);
                });

                const paramsToRemove = entriesToRemove.map(([paramName]) => paramName);
                return omit(paramNamesMap, paramsToRemove);
            });
        },
        [getCurrentSearch, setParamNamesMap, unsetParam]
    );

    return <QueryParamsContext.Provider value={value}>{children}</QueryParamsContext.Provider>;
};
