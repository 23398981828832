import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'actions';
import {
    PERFORMANCE_RULES_CREATOR_ROUTE_PATH,
    PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH,
    PERFORMANCE_RULES_EDIT_ROUTE_PATH,
    PERFORMANCE_RULES_ROUTE,
} from 'modules/performance-rules/config';
import { INDICATION_TYPES, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { CustomizedSwitchWrapper } from '../../../components/CustomizedSwitchWrapper/CustomizedSwitchWrapper';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ModuleRoute } from '../../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { PerformanceRuleCreator } from './PerformanceRuleCreator';
import { PerformanceRulesDuplicatePage } from './PerformanceRulesDuplicatePage';
import { PerformanceRulesEditPage } from './PerformanceRulesEditPage';
import { PerformanceRulesPage } from './PerformanceRulesPage';

const path = PERFORMANCE_RULES_ROUTE;

const sendGTMEvent = () => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: 'Cancel create PerformanceRules',
        pageName: getPageName(),
    });
};
export const PerformanceRules = ({ history }) => {
    const dispatch = useDispatch();
    const handleNavigation = useCallback(() => {
        sendGTMEvent();
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        dispatch(
            navigate(history, path, {
                skipFetchReport: true,
            })
        );
    }, [dispatch, history]);

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    return (
        <CustomizedSwitchWrapper>
            <LayerRoute
                strict
                exact
                path={PERFORMANCE_RULES_CREATOR_ROUTE_PATH}
                render={props => <PerformanceRuleCreator {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            {isMultiLayerDrawerEnabled && (
                <>
                    <LayerRoute
                        exact
                        path={PERFORMANCE_RULES_EDIT_ROUTE_PATH}
                        render={props => <PerformanceRulesEditPage {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH}
                        render={() => <PerformanceRulesDuplicatePage />}
                    />
                </>
            )}
            <ModuleRoute path={path} render={() => <PerformanceRulesPage />} />
        </CustomizedSwitchWrapper>
    );
};

PerformanceRules.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
};
