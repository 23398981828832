import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CONVERSIONS].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.CONVERSION_STATUS.field,
        FIELDS.CONVERSION_NAME.field,
        FIELDS.CONVERSION_TYPE.field,
        FIELDS.ACCOUNT_NAME.field,
        FIELDS.CATEGORY.field,
        FIELDS.CONVERSION_CONDITIONS.field,
        FIELDS.LAST_RECEIVED.field,
        FIELDS.EVENT_RECEIVED.field,
        FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.field,
        FIELDS.INCLUDE_IN_TOTAL_VALUE.field,
        FIELDS.LAST_MODIFY_ON.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.CONVERSION_STATUS.field,
    FIELDS.CONVERSION_NAME.field,
    FIELDS.CONVERSION_TYPE.field,
    FIELDS.ACCOUNT_NAME.field,
    FIELDS.CATEGORY.field,
    FIELDS.CONVERSION_CONDITIONS.field,
    FIELDS.LAST_RECEIVED.field,
    FIELDS.EVENT_RECEIVED.field,
    FIELDS.INCLUDED_IN_TOTAL_CONVERSIONS.field,
    FIELDS.INCLUDE_IN_TOTAL_VALUE.field,
    FIELDS.LAST_MODIFY_ON.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
