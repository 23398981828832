import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import { BID_STRATEGIES, CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES, MARKETING_OBJECTIVE_KEYS } from '../../../config';
import { useBudgetFormFieldValue } from '../../Budget/hooks/useBudgetFormFieldValue';

const targetCpaValidations = [
    {
        validationFn: (value, options, { bidStrategy }) => bidStrategy,
        messageId: 'validations.error.campaign.bid.strategy.empty',
        defaultMessage:
            'Target CPA is not supported on duplicated campaigns, change the Bid Strategy in order to complete the duplication flow.',
    },
    {
        validationFn: (value, options, { marketingObjective, bidStrategy }) =>
            bidStrategy !== BID_STRATEGIES.TARGET_CPA ||
            (marketingObjective !== MARKETING_OBJECTIVE_KEYS.DRIVE_WEBSITE_TRAFFIC &&
                marketingObjective !== MARKETING_OBJECTIVE_KEYS.BRAND_AWARENESS),
        messageId: 'validations.error.campaign.target.cpa.marketingObjective',
        defaultMessage: 'Target CPA campaigns do not support {marketingObjectiveLabel} as a Marketing Objective.',
    },
    {
        validationFn: (value, options, { creativeTrafficAllocationMethod, bidStrategy }) =>
            bidStrategy !== BID_STRATEGIES.TARGET_CPA ||
            creativeTrafficAllocationMethod === CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED,
        messageId: 'validations.error.campaign.target.cpa.creativeTrafficAllocationMethod',
        defaultMessage:
            'Target CPA campaigns do not support A/B Testing for Ad Optimization. Please choose Optimized instead.',
    },
];

export const useTargetCpaAdditionalValidations = () => {
    const { value: marketingObjective } = useFormFieldValue({ field: 'marketingObjective' });
    const { value: creativeTrafficAllocationMethod } = useFormFieldValue({ field: 'creativeTrafficAllocationMethod' });
    const { value: budget } = useBudgetFormFieldValue();
    const { formatMessage } = useIntl();

    const marketingObjectiveLabel = marketingObjective
        ? formatMessage({
              id: `campaign.marketing.objective.${marketingObjective}.title`,
          })
        : '';

    const targetCpaValidationDependencies = {
        marketingObjective,
        creativeTrafficAllocationMethod,
        marketingObjectiveLabel,
        budget,
    };

    return {
        targetCpaValidationDependencies,
        targetCpaValidations,
    };
};
