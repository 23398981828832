import { useEntityType } from 'hooks';
import { ENTITY, PATH_TO_ENTITY_MAP } from '../../../../../config';
import { useCampaignGroupExtensionEnabled } from '../../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { useSpendingLimitOptions } from './useSpendingLimitOptions';

export const useNoneSpendingLimitEnabled = () => {
    const isConfigured = useSpendingLimitOptions();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const isEnabled = isConfigured && (entityType !== ENTITY.CAMPAIGNS_GROUP || campaignGroupExtensionEnabled);

    return isEnabled;
};
