import React from 'react';
import { FormField, Input } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import { withInputValidations } from '../../../../../../taboola-common-frontend-modules/validations';
import groupNameValidations from '../../../../../config/validations/campaigns-group/groupNameValidations';
import styles from '../../../../common-campaign-form/components/commonEditor.module.scss';

const InputWithValidations = withInputValidations(withIndication(Input), groupNameValidations);

const CampaignsGroupNameCreate = () => {
    const { value: name, onChange } = useFormFieldValue({ field: 'campaignsGroupName', throttleValue: true });
    const changeHandler = e => onChange(e.target.value);

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'campaigns.group.name.placeholder',
        defaultMessage: 'Enter Group Name',
    });

    return (
        <FormField
            inputId="campaignsGroupName"
            containerClass={styles['input']}
            label={<FormattedMessage id="campaigns.group.name" defaultMessage="Group Name" />}
        >
            <InputWithValidations
                id="campaignsGroupName"
                className={styles['editable-text']}
                value={name}
                onChange={changeHandler}
                placeholder={placeholder}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="CampaignsGroupNameCreate"
            />
        </FormField>
    );
};

export default CampaignsGroupNameCreate;
