import { useMemo } from 'react';
import { compact, concat, filter } from 'lodash';
import { TAG_TYPES } from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags';
import { COUNTRY_TARGETING_LEVELS } from 'modules/campaigns/modules/common-campaign-form/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { TARGETING_TYPES } from '../../../config';

const emptyArray = [];
export const useCurrentCampaignTargetingByType = () => {
    const { value: countryTargetingLevel } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargetingLevel',
    });

    const { value: DMATargetingField } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.DMATargeting.values',
    });

    const { value: regionTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.regionTargeting.values',
    });
    const { value: cityTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.cityTargeting.values',
    });
    const { value: postalCodeTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.postalCodeTargeting.values',
    });

    const { value: connectionTypeTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.connectionTypeTargeting.values',
    });
    const { value: contextualSegmentsTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting',
    });
    const { value: customContextualTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting',
    });
    const { value: marketplaceTargetingField } = useFormFieldValue({
        field: 'campaignTargeting.marketplaceAudienceTargeting',
    });
    const { value: myAudienceFieldInclude } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
    });
    const { value: thirdPartyTagsField } = useFormFieldValue({
        field: 'thirdPartyTags',
    });

    const {
        DMATargeting,
        regionTargeting,
        cityTargeting,
        postalCodeTargeting,
        connectionTypeTargeting,
        contextualCategoriesTargeting,
        topicSegmentsTargeting,
    } = useMemo(() => {
        return {
            DMATargeting:
                countryTargetingLevel === COUNTRY_TARGETING_LEVELS.DMAS && DMATargetingField
                    ? DMATargetingField
                    : emptyArray,
            regionTargeting:
                countryTargetingLevel === COUNTRY_TARGETING_LEVELS.REGIONS && regionTargetingField
                    ? regionTargetingField
                    : emptyArray,
            cityTargeting:
                countryTargetingLevel === COUNTRY_TARGETING_LEVELS.CITIES && cityTargetingField
                    ? cityTargetingField
                    : emptyArray,
            postalCodeTargeting:
                countryTargetingLevel === COUNTRY_TARGETING_LEVELS.ZIPCODES && postalCodeTargetingField
                    ? postalCodeTargetingField
                    : emptyArray,
            connectionTypeTargeting: connectionTypeTargetingField ?? emptyArray,
            contextualCategoriesTargeting: [
                ...contextualSegmentsTargetingField.exclude,
                ...contextualSegmentsTargetingField.include,
            ],
            topicSegmentsTargeting: [
                ...customContextualTargetingField.exclude,
                ...customContextualTargetingField.include,
            ],
        };
    }, [
        countryTargetingLevel,
        DMATargetingField,
        regionTargetingField,
        cityTargetingField,
        postalCodeTargetingField,
        connectionTypeTargetingField,
        contextualSegmentsTargetingField.exclude,
        contextualSegmentsTargetingField.include,
        customContextualTargetingField.exclude,
        customContextualTargetingField.include,
    ]);

    const audienceIncludeTargeting = useMemo(() => {
        const marketplaceAudiencesInclude = filter(
            marketplaceTargetingField.collection,
            group => group?.type === TARGETING_TYPES.INCLUDE
        ).flatMap(group => group?.values);

        return compact(concat(myAudienceFieldInclude, marketplaceAudiencesInclude));
    }, [myAudienceFieldInclude, marketplaceTargetingField]);

    const viewabilityTagTargeting = useMemo(() => {
        return thirdPartyTagsField?.filter(tag => tag.type !== TAG_TYPES.THIRD_PARTY_PIXEL);
    }, [thirdPartyTagsField]);

    return useMemo(
        () => ({
            DMATargeting,
            regionTargeting,
            cityTargeting,
            postalCodeTargeting,
            connectionTypeTargeting,
            contextualCategoriesTargeting,
            topicSegmentsTargeting,
            audienceIncludeTargeting,
            viewabilityTagTargeting,
        }),
        [
            DMATargeting,
            audienceIncludeTargeting,
            cityTargeting,
            connectionTypeTargeting,
            contextualCategoriesTargeting,
            postalCodeTargeting,
            regionTargeting,
            viewabilityTagTargeting,
            topicSegmentsTargeting,
        ]
    );
};
