import React from 'react';
import { get } from 'lodash';
import { Button } from 'tuui';
import { FileTypes, MediaGalleryItem } from 'taboola-ultimate-ui';
import VideoTypes from 'modules/campaigns/config/VideoTypes';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { EmptyGallery, emptyGalleryTypes } from '../../ImageGallery';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import styles from './fromImageSubTabDragAndDropField.module.scss';

const FromImageSubTabDragAndDropField = ({ referenceImage, referenceImageStatus, onGenerateClick }) => {
    const { formatMessage } = useIntl();
    const buttonText = formatMessage({
        id: 'creative.editor.tab.aiGenerated.button.generate',
        defaultMessage: 'Generate Images',
    });
    const { campaignId } = useMediaTabsContext();

    const urlWithoutQuery = get(`${referenceImage}`.split('?'), '[0]', '').toLowerCase();
    const isVideo = urlWithoutQuery.endsWith(VideoTypes.MP4) || urlWithoutQuery.endsWith(VideoTypes.MOV);

    return (
        <div className={styles['container']}>
            {referenceImageStatus !== COMPONENT_STATUS.EMPTY ? (
                <div>
                    <MediaGalleryItem
                        imgSrc={referenceImage}
                        thumbnail={{ fileType: isVideo ? FileTypes.VIDEO : FileTypes.IMAGE }}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIGeneratedImageFromImage"
                        className={styles['reference-image']}
                        status={referenceImageStatus}
                        shouldAddOverlay={false}
                    />
                    <Button
                        onClick={onGenerateClick}
                        disabled={referenceImageStatus !== COMPONENT_STATUS.ACTIVE}
                        size="sm"
                        className={styles['button']}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIGeneratedImagesTab - Image to Image"
                        data-metrics-taboola-campaign-id={campaignId}
                    >
                        {buttonText}
                    </Button>
                </div>
            ) : (
                <EmptyGallery
                    className={styles['empty-gallery']}
                    type={emptyGalleryTypes.AI_GENERATED_IMAGES_FROM_IMAGE_INITIAL}
                />
            )}
        </div>
    );
};
export default FromImageSubTabDragAndDropField;
