import React from 'react';
import { useBillingData } from '../../hooks/useBillingData';
import { BalanceAndPaymentsPanel } from '../BalanceAndPaymentsPanel/BalanceAndPaymentsPanel';
import { InvalidManageLevel } from '../InvalidManageLevel/InvalidManageLevel';
import { InvoicesReportPreview } from '../PreviewReports/InvoicesReportPreview';
import { PaymentsReportPreview } from '../PreviewReports/PaymentsReportPreview';
import { useAdditionalFundsModal } from './useAdditionalFundsModal';
import styles from './billingManagementContent.module.scss';

export const BillingManagementContent = () => {
    const { isFetching, isManagingBilling } = useBillingData();

    useAdditionalFundsModal();

    if (!isFetching && !isManagingBilling) {
        return <InvalidManageLevel />;
    }
    return (
        <div className={styles['container']}>
            <BalanceAndPaymentsPanel />
            <InvoicesReportPreview />
            <PaymentsReportPreview />
        </div>
    );
};
