import {
    REQUEST_FETCH_RECOMMENDED_MEDIA,
    FETCH_RECOMMENDED_MEDIA_SUCCESS,
    FETCH_RECOMMENDED_MEDIA_ERROR,
    RESET_RECOMMENDED_MEDIA,
} from './actionTypes';

export const requestFetchRecommendedMedia = () => ({
    type: REQUEST_FETCH_RECOMMENDED_MEDIA,
});

export const fetchRecommendedMediaSuccess = ({
    recommendedMedia,
    shouldFetchRecommendedImages,
    shouldFetchRecommendedVideos,
}) => ({
    type: FETCH_RECOMMENDED_MEDIA_SUCCESS,
    payload: { recommendedMedia, shouldFetchRecommendedImages, shouldFetchRecommendedVideos },
});

export const fetchRecommendedMediaError = error => ({
    type: FETCH_RECOMMENDED_MEDIA_ERROR,
    payload: error,
});

export const resetRecommendedMedia = () => ({
    type: RESET_RECOMMENDED_MEDIA,
});
