import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { LoadingOverlay } from 'modules/taboola-common-frontend-modules/forms';
import FormInitBase from 'modules/taboola-common-frontend-modules/forms/components/FormInitBase/FormInitBase';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { FormButtons } from '../../components';
import { useLastCreatedCampaignId } from '../../hooks/useLastCreatedCampaignId';
import BodyLoading from '../common-campaign-form/components/BodyLoading/BodyLoading';
import { AiVariationsContextProvider } from './components/AiVariationsContextProvider';
import { AiVariationsPreviewSection } from './components/AiVariationsCreation/components/AiVariationsPreviewSection';
import { AiVariationsContentSection } from './components/AiVariationsCreation/components/ContentSection/AiVariationsContentSection';
import { CarouselCardList } from './components/CarouselCardList/CarouselCardList';
import CreativeBreadcrumbs from './components/CreativeBreadcrumbs';
import { CreativeSetUp } from './components/CreativeSetUp/CreativeSetUp';
import { OneByOneLoading } from './components/OneByOneCreation/OneByOneLoading';
import { VariationsContentSection } from './components/VariationsCreation/components/VariationsContentSection';
import { VariationsMediaSection } from './components/VariationsCreation/components/VariationsMediaSection';
import { VariationsPreviewSection } from './components/VariationsCreation/components/VariationsPreviewSection';
import { CampaignSetupSection } from './components/campaignSetupSection/CampaignSetupSection';
import { CONTENT_METHODS } from './config';
import { creativeInitialValues } from './config/creativeInitialValues';
import { setContentMethod } from './flows';
import { useCreativeCampaignData } from './hooks/useCreativeCampaignData';
import { useCreativeCreatorConfigPermissions } from './hooks/useCreativeCreatorConfigPermissions';
import { useCreativeCreatorState } from './hooks/useCreativeCreatorState';
import { contentMethodSelector } from './selectors/selectors';
import { WochitProvider } from './services/wochit';
import styles from './creativeCreator.module.scss';

const classNameBuilder = classnames.bind(styles);

const formContainerId = 'CreativesCreator';
const resetSelectedCampaignsContentMethods = {
    [CONTENT_METHODS.ONE_BY_ONE]: true,
    [CONTENT_METHODS.RSS]: true,
};
const contentMethodLoadingOverlayMap = {
    [CONTENT_METHODS.ONE_BY_ONE]: OneByOneLoading,
};
const contentMethodMediaSectionMap = {
    [CONTENT_METHODS.VARIATIONS]: VariationsMediaSection,
    [CONTENT_METHODS.AI_VARIATIONS]: VariationsMediaSection,
    [CONTENT_METHODS.CAROUSEL]: VariationsMediaSection,
};
const contentMethodContentSectionMap = {
    [CONTENT_METHODS.VARIATIONS]: VariationsContentSection,
    [CONTENT_METHODS.AI_VARIATIONS]: AiVariationsContentSection,
    [CONTENT_METHODS.CAROUSEL]: CarouselCardList,
};
const contentMethodPreviewSectionMap = {
    [CONTENT_METHODS.VARIATIONS]: VariationsPreviewSection,
    [CONTENT_METHODS.AI_VARIATIONS]: AiVariationsPreviewSection,
    [CONTENT_METHODS.CAROUSEL]: VariationsPreviewSection,
};

const CreativeCreator = props => {
    // This is a intended call to keep lastCreatedCampaignId in url until form is live
    useLastCreatedCampaignId();
    const dispatch = useDispatch();
    const { submit: submitProp, step, handleSetStep, nextStep } = useCreativeCreatorState();
    const { onCancel } = props;
    const initialContentMethod = useSelector(contentMethodSelector);
    const { submitStatus } = useFormDataContext();
    const configPermission = useCreativeCreatorConfigPermissions();
    const { availableContentMethods, isAiVariationsReplacementEnabled } = configPermission;
    const contentMethod = availableContentMethods.includes(initialContentMethod)
        ? initialContentMethod
        : availableContentMethods[0];
    const { value: creativeCreatorSelectedCampaigns, onChange: setCampaigns } = useCampaignsFormFieldValue();
    const [selectedCampaignsBeforeReset, setSelectedCampaignsBeforeReset] = useState(creativeCreatorSelectedCampaigns);

    const { fetchedCampaign } = useCreativeCampaignData();
    const isCampaignReady = fetchedCampaign.name !== '';

    const onContentMethodChange = useCallback(
        (key, changeProps = {}) => {
            const { isInitialRender = false } = changeProps;

            dispatch(setContentMethod(key));
            if (resetSelectedCampaignsContentMethods[key]) {
                const { id: value, name: label, campaignItemType } = creativeCreatorSelectedCampaigns[0] || {};
                if (label) {
                    setCampaigns([
                        {
                            value,
                            label,
                            id: value,
                            campaignItemType,
                        },
                    ]);
                } else {
                    setCampaigns([]);
                }
            } else {
                if (!isEmpty(selectedCampaignsBeforeReset) && !isInitialRender) {
                    setCampaigns(selectedCampaignsBeforeReset);
                }
            }
        },
        [creativeCreatorSelectedCampaigns, dispatch, selectedCampaignsBeforeReset, setCampaigns]
    );

    useEffect(() => {
        onContentMethodChange(availableContentMethods[0], { isInitialRender: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // saving selected campaigns for the non-resetting modes to avoid api errors related to no campaign
    useEffect(() => {
        if (!resetSelectedCampaignsContentMethods[contentMethod] && !isEmpty(creativeCreatorSelectedCampaigns)) {
            setSelectedCampaignsBeforeReset(creativeCreatorSelectedCampaigns);
        }
    }, [contentMethod, creativeCreatorSelectedCampaigns]);

    const submit = async () => {
        await submitProp(contentMethod);
    };

    const LoadingOverlayComponent = contentMethodLoadingOverlayMap[contentMethod] || LoadingOverlay;
    const ContentSectionComponent = contentMethodContentSectionMap[contentMethod] || (() => null);
    const MediaSectionComponent = contentMethodMediaSectionMap[contentMethod] || (() => null);
    const PreviewSectionComponent = contentMethodPreviewSectionMap[contentMethod] || (() => null);

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns="100% 0"
                className={styles['static-indications']}
                wizardNavigationClassName={styles['wizard-navigation']}
            >
                <WizardStep
                    id="campaignSetup"
                    label={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                    wrapperClassName={styles['wizard-step']}
                    className={styles['wizard-step']}
                >
                    <div
                        className={classNameBuilder('container', 'vertical-flex', 'restricted-width', {
                            overflow: contentMethod !== CONTENT_METHODS.VARIATIONS,
                        })}
                        {...(contentMethod !== CONTENT_METHODS.VARIATIONS ? { id: formContainerId } : {})}
                    >
                        <StaticIndications containerClassName={styles['static-indications']} />
                        <div className={classNameBuilder('section-container', 'flex-rest-space', 'vertical-flex')}>
                            <CreativeBreadcrumbs
                                className={classNameBuilder('breadcrumbs-container')}
                                containerClass={classNameBuilder('breadcrumbs-content-container')}
                            />
                            <div
                                className={classNameBuilder('form-container', 'flex-rest-space', 'overflow')}
                                {...(contentMethod === CONTENT_METHODS.VARIATIONS ? { id: formContainerId } : {})}
                            >
                                <CampaignSetupSection disabled={false} contentMethod={contentMethod} />
                            </div>
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                                submitIcon={<ChevronRightOutlinedIcon />}
                                submitDataMetricAttrs={{
                                    'data-metrics-component': 'ClickNext',
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                submitButtonType="submit"
                                className={classNameBuilder('form-content', 'buttons')}
                            />
                        </div>
                    </div>
                </WizardStep>
                {isCampaignReady && (
                    <WizardStep
                        id="adSetup"
                        label={
                            <FormattedMessage id="campaign.creator.creatives.step.label" defaultMessage="Ad Setup" />
                        }
                        wrapperClassName={styles['wizard-step']}
                        className={styles['wizard-step']}
                    >
                        <div
                            className={classNameBuilder('container', 'vertical-flex', {
                                overflow: contentMethod !== CONTENT_METHODS.VARIATIONS,
                            })}
                            {...(contentMethod !== CONTENT_METHODS.VARIATIONS ? { id: formContainerId } : {})}
                        >
                            <StaticIndications containerClassName={styles['static-indications']} />
                            <div className={classNameBuilder('flex-rest-space', 'flex', 'fixer')}>
                                <div
                                    className={classNameBuilder(
                                        'section-container',
                                        'vertical-flex',
                                        'restricted-width'
                                    )}
                                >
                                    <CreativeBreadcrumbs
                                        className={styles['breadcrumbs-container']}
                                        containerClass={classNameBuilder('breadcrumbs-content-container')}
                                    />
                                    <div
                                        className={classNameBuilder('form-container', 'overflow', 'flex-rest-space')}
                                        {...(contentMethod === CONTENT_METHODS.VARIATIONS
                                            ? { id: formContainerId }
                                            : {})}
                                    >
                                        <AiVariationsContextProvider>
                                            <CampaignSetupSection disabled contentMethod={contentMethod} />
                                            <CreativeSetUp
                                                onChange={onContentMethodChange}
                                                selected={contentMethod}
                                                options={availableContentMethods}
                                                isAiVariationsReplacementEnabled={isAiVariationsReplacementEnabled}
                                            />
                                            <WochitProvider>
                                                <MediaSectionComponent />
                                            </WochitProvider>
                                            <ContentSectionComponent />
                                        </AiVariationsContextProvider>
                                    </div>
                                </div>
                                <PreviewSectionComponent />
                            </div>
                            <FormButtons
                                className={classNameBuilder('form-content', 'buttons')}
                                onSubmit={submit}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.submit" defaultMessage="SUBMIT" />}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="CANCEL" />}
                                submitDataMetricAttrs={{
                                    'data-metrics-value': contentMethod,
                                    'data-metrics-component': 'SubmitButton',
                                }}
                                submitButtonType="submit"
                            />
                            {submitStatus === COMPONENT_STATUS.LOADING && <LoadingOverlayComponent />}
                        </div>
                    </WizardStep>
                )}
                {!isCampaignReady && <BodyLoading className={styles['body-loading']} />}
            </Wizard>
        </FormInitBase>
    );
};

CreativeCreator.propTypes = {
    onCancel: PropTypes.func,
};

CreativeCreator.defaultProps = {
    onCancel: () => {},
};

const CreativeCreatorWithFormDataProvider = withFormDataProvider(CreativeCreator, {
    skipInitFormAccount: true,
    defaultValuesConfig: creativeInitialValues,
});

export { CreativeCreatorWithFormDataProvider as CreativeCreator };
