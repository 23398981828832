import CAMPAIGN_TYPE from '../campaigns/constants/campaignType';

const ACCOUNT_TYPE = {
    ADMIN_NETWORK: 'ADMIN_NETWORK',
    NETWORK: 'NETWORK',
    GROUP: 'GROUP',
    PARTNER: 'PARTNER',
};

export const PARTNER_TYPE = {
    VIDEO_ADVERTISER: 'VIDEO_ADVERTISER',
    ADVERTISER: 'ADVERTISER',
};

const NETWORK_ACCOUNT_TYPE_MAP = {
    [ACCOUNT_TYPE.ADMIN_NETWORK]: true,
    [ACCOUNT_TYPE.GROUP]: true,
    [ACCOUNT_TYPE.NETWORK]: true,
};

export const ACCOUNT_TIER = {
    LOW_TOUCH: 'Low Touch',
    LOW_TOUCH_PLUS: 'Low Touch Plus',
    HIGH_TOUCH: 'High Touch',
    NONE: 'None',
};

export const ADVERTISER_GROUP_TIER = {
    GROWTH: 'GROWTH',
    ENTERPRISE: 'ENTERPRISE',
    GLOBAL_SAL: 'GLOBAL_SAL',
    NONE: 'NONE',
};

export const isDSPAccount = account => (account?.isDSPAccount ? account.isDSPAccount : false);
export const isNetworkAccountType = type => !!NETWORK_ACCOUNT_TYPE_MAP[type];
export const isAdminNetworkAccountType = type => type === ACCOUNT_TYPE.ADMIN_NETWORK;
export const isPartnerAccountType = type => type === ACCOUNT_TYPE.PARTNER;
export const isGroupAccountType = type => type === ACCOUNT_TYPE.GROUP;
export const isVideoAccount = partnerTypes => (partnerTypes || []).includes(PARTNER_TYPE.VIDEO_ADVERTISER);
export const isSponsoredAccount = partnerTypes => (partnerTypes || []).includes(PARTNER_TYPE.ADVERTISER);
export const isLowTouchAccount = tier => tier === ACCOUNT_TIER.LOW_TOUCH;
export const isOpenExchangeAccount = selectedAccount =>
    selectedAccount?.campaignTypes?.includes(CAMPAIGN_TYPE.RTB_OPEN_EXCHANGE);
export const isPmpAccount = selectedAccount => selectedAccount?.campaignTypes?.includes(CAMPAIGN_TYPE.PMP_DEAL);
export const isProgrammaticAccount = selectedAccount =>
    isOpenExchangeAccount(selectedAccount) || isPmpAccount(selectedAccount);
export const isActiveAccount = selectedAccount => selectedAccount?.isActive === true;
export const nonProgrammaticAccount = selectedAccount => !isProgrammaticAccount(selectedAccount);
export const nonProgrammaticActiveAccount = selectedAccount =>
    nonProgrammaticAccount(selectedAccount) && isActiveAccount(selectedAccount);
export const isOpenExchangeActiveAccount = selectedAccount =>
    isOpenExchangeAccount(selectedAccount) && isActiveAccount(selectedAccount);
export const isPmpActiveAccount = selectedAccount => isPmpAccount(selectedAccount) && isActiveAccount(selectedAccount);
export const isGrowthAdvertiserAccount = advertiserGroupTier => advertiserGroupTier === ADVERTISER_GROUP_TIER.GROWTH;

export default ACCOUNT_TYPE;
