import uuid from 'uuid/v1';
import {
    GROUPS_OF_DAYS_ORDER,
    HOUR_OPTIONS,
} from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/consts/dayTimeDaysOptions';
import TARGETING_TYPES from 'modules/campaigns/modules/common-campaign-form/config/TargetingTypes';
import DAYS_OF_WEEK from 'modules/campaigns/modules/common-campaign-form/config/daysOfWeek';

export const createNewDefaultDayParting = () => ({
    id: uuid(),
    type: TARGETING_TYPES.INCLUDE,
    day: GROUPS_OF_DAYS_ORDER[0],
    fromHour: HOUR_OPTIONS[0].value,
    untilHour: HOUR_OPTIONS[24].value,
});

export const getScheduleDayPartingDefault = () => {
    return [
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.MONDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.TUESDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.WEDNESDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.THURSDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.FRIDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.SATURDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
        {
            id: uuid(),
            type: TARGETING_TYPES.INCLUDE,
            day: DAYS_OF_WEEK.SUNDAY,
            fromHour: HOUR_OPTIONS[0].value,
            untilHour: HOUR_OPTIONS[24].value,
        },
    ];
};
