import React from 'react';
import { HelpTooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../../common-campaign-form';
import styles from './thumbnailMotionEditorBody.module.scss';

const ReplaceImageTooltip = () => (
    <div className={styles['header']}>
        <FormattedMessage id="creative.creator.media.fallbackImage.link.replaceImage" defaultMessage="Replace Image" />
        <HelpTooltip iconClassName={styles['icon']}>
            <TooltipSection>
                <FormattedMessage
                    id="creative.editor.tab.upload.device.help.text.image.body"
                    defaultMessage="Recommended Image size: 1200 x 674{newline}Recommended aspect Ratio: 16:9{newline}Min. required: 400 x 350{newline}Max file size: 5 MB"
                />
            </TooltipSection>
        </HelpTooltip>
    </div>
);

export default ReplaceImageTooltip;
