'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _constants = require('./constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'progressColor': 'var(--blue-4)',
    'backgroundColor': 'var(--gray-3)',
    'taboola-svg-icon': 'circleProgress__taboola-svg-icon___3kuQk',
    'animated': 'circleProgress__animated___2xhJH',
    'wrapper': 'circleProgress__wrapper___tGXYE',
    'container': 'circleProgress__container___aCup-'
};


var classNameBuilder = _bind2.default.bind(styles);

/**
 * @deprecated - use CircleProgressIndicator from tuui
 */
function CircleProgress(props) {
    var progress = props.progress,
        backgroundColor = props.backgroundColor,
        progressColor = props.progressColor,
        lineWidth = props.lineWidth,
        animate = props.animate,
        children = props.children,
        animationClassName = props.animationClassName;

    var strokeDashoffset = (0, _constants.getOffset)(progress);
    var animatedClassName = animate && animationClassName;
    var circleContentWrapperClassName = classNameBuilder('wrapper');
    var circleContentContainerClassName = classNameBuilder('container');

    return _react2.default.createElement(
        'svg',
        { viewBox: '-25 -25 400 400' },
        _react2.default.createElement('circle', {
            stroke: backgroundColor,
            cx: _constants.RADIUS,
            cy: _constants.RADIUS,
            r: _constants.RADIUS,
            strokeWidth: lineWidth,
            fill: 'none'
        }),
        _react2.default.createElement('circle', {
            stroke: progressColor,
            transform: 'rotate(-90 ' + _constants.RADIUS + ' ' + _constants.RADIUS + ')',
            cx: _constants.RADIUS,
            cy: _constants.RADIUS,
            r: _constants.RADIUS,
            strokeDasharray: 1100,
            strokeWidth: lineWidth,
            strokeDashoffset: strokeDashoffset,
            strokeLinecap: 'butt',
            fill: 'none',
            className: animatedClassName
        }),
        children ? _react2.default.createElement(
            'foreignObject',
            { x: _constants.AXIS_OFFSET, y: _constants.AXIS_OFFSET, className: circleContentWrapperClassName },
            _react2.default.createElement(
                'div',
                { className: circleContentContainerClassName },
                children
            )
        ) : null
    );
}

CircleProgress.defaultProps = {
    progress: 0,
    animate: true,
    progressColor: styles.progressColor,
    backgroundColor: styles.backgroundColor,
    lineWidth: 35,
    animationClassName: styles['animated']
};

CircleProgress.propTypes = {
    animate: _propTypes2.default.bool,
    backgroundColor: _propTypes2.default.string,
    lineWidth: _propTypes2.default.number,
    progress: _propTypes2.default.number,
    progressColor: _propTypes2.default.string,
    children: _propTypes2.default.node,
    animationClassName: _propTypes2.default.string
};

exports.default = CircleProgress;