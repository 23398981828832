import { map } from 'lodash';
import DAYS_OF_WEEK from 'modules/campaigns/modules/common-campaign-form/config/daysOfWeek';
import { GROUPS_OF_DAYS } from 'modules/campaigns/modules/common-campaign-form/config/groupsOfDays';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const DAYS_OF_WEEK_ORDER = [
    DAYS_OF_WEEK.MONDAY,
    DAYS_OF_WEEK.TUESDAY,
    DAYS_OF_WEEK.WEDNESDAY,
    DAYS_OF_WEEK.THURSDAY,
    DAYS_OF_WEEK.FRIDAY,
    DAYS_OF_WEEK.SATURDAY,
    DAYS_OF_WEEK.SUNDAY,
];

export const GROUPS_OF_DAYS_ORDER = [
    GROUPS_OF_DAYS.ALL_DAYS,
    GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS,
    GROUPS_OF_DAYS.SATURDAYS_TO_SUNDAYS,
];

export const WEEKDAYS_AND_GROUPS = [...GROUPS_OF_DAYS_ORDER, ...DAYS_OF_WEEK_ORDER];

export const GROUPS_TO_DAYS_MAP = {
    MONDAYS_TO_FRIDAYS: [
        DAYS_OF_WEEK.MONDAY,
        DAYS_OF_WEEK.TUESDAY,
        DAYS_OF_WEEK.WEDNESDAY,
        DAYS_OF_WEEK.THURSDAY,
        DAYS_OF_WEEK.FRIDAY,
    ],
    SATURDAYS_TO_SUNDAYS: [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY],
    ALL_DAYS: DAYS_OF_WEEK_ORDER,
};

export const DAYS_TO_GROUPS_MAP = {
    MONDAY: [GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    TUESDAY: [GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    WEDNESDAY: [GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    THURSDAY: [GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    FRIDAY: [GROUPS_OF_DAYS.MONDAYS_TO_FRIDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    SATURDAY: [GROUPS_OF_DAYS.SATURDAYS_TO_SUNDAYS, GROUPS_OF_DAYS.ALL_DAYS],
    SUNDAY: [GROUPS_OF_DAYS.SATURDAYS_TO_SUNDAYS, GROUPS_OF_DAYS.ALL_DAYS],
};

const calcDayOptions = days =>
    map(days).map(key => ({
        value: key,
        label: <FormattedMessage id={`campaign.editor.schedule.dayparting.day.${key}`} defaultMessage={key} />,
    }));

export const DAY_OPTIONS = calcDayOptions(DAYS_OF_WEEK_ORDER);

export const DAY_AND_GROUP_OPTIONS = calcDayOptions(WEEKDAYS_AND_GROUPS);

export const formatHour = hour => {
    if (hour === 24) {
        return '00:00';
    }
    return `${hour < 10 ? '0' : ''}${hour}:00`;
};

export const HOUR_OPTIONS = Array.from({ length: 25 }, (_, index) => ({
    value: index,
    label: <FormattedMessage id={`campaign.editor.day.parting.hour.${index}`} defaultMessage={formatHour(index)} />,
}));
