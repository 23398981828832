export const CAMPAIGN_CONTENT_TYPE = {
    VIDEO: 'VIDEO',
    TEXT: 'TEXT',
    PHOTO: 'PHOTO',
    OTHER: 'OTHER',
};

export const CAMPAIGN_ADVERTISEMENT_TYPE = {
    PUBLISHER: 'PUBLISHER',
    BRANDED: 'BRANDED',
    AD_HEAVY: 'AD_HEAVY',
    MOBILE_APP: 'MOBILE_APP',
    CTA: 'CTA',
    AGGRESSIVE_CTA: 'AGGRESSIVE_CTA',
    AGGRESSIVE_AFFILIATE: 'AGGRESSIVE_AFFILIATE',
    DIRECT_AD: 'DIRECT_AD',
};

export const CAMPAIGN_CONTENT_SAFETY = {
    PREMIUM: 'PREMIUM',
    SAFE_POSITIVE: 'SAFE_POSITIVE',
    SAFE_SENSITIVE: 'SAFE_SENSITIVE',
    LOW_BROW: 'LOW_BROW',
};

export const FILTERS_CONTENT_SAFETY = {
    RACY: 'RACY',
    SAFE_POSITIVE: 'SAFE_POSITIVE',
    SAFE_SENSITIVE: 'SAFE_SENSITIVE',
    LOW_BROW: 'LOW_BROW',
    PREMIUM: 'PREMIUM',
    SHOCKING: 'SHOCKING',
};

export const POLICY_STATE = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
};

export const RANGE_FILTERS_OPERATORS = {
    GREATER: '>',
    LESS: '<',
    BETWEEN: '[]',
};
