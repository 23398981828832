import selectedAccountSelector from '../../../../../selectors/selectedAccount';
import { AllCampaignsObject, getCampaignEntity, PUBLISHER_BLOCKING_LEVEL } from '../../../config';
import { addToCampaignProperty, removeFromCampaignProperty, blockSite, unblockSite } from '../../../flows';
import { selectedCampaignSelector } from '../../../selectors';
import setReportData from '../actions/setReportData';
import { prepareRollBackData } from './saveCampaign';

// TODO DEV-66093 oldValue, rowIndex - have to be removed along with site data consistency issue solved
const toggleSiteBlocking =
    ({ isPublisherBlocked }, { accountName, siteName }, oldValue, rowIndex) =>
    (dispatch, getState) => {
        const state = getState();
        const { campaignsReducer } = state;
        const { reportData } = campaignsReducer;
        const { accountId } = selectedAccountSelector(state);
        const { id: campaignId, demandType } = selectedCampaignSelector(state);
        const resolvedAccountName = accountName || accountId;
        const rollBackData = prepareRollBackData(rowIndex, oldValue, reportData);
        const rollBack = () => dispatch(setReportData(rollBackData));
        const publisherBlockingLevel = reportData[rowIndex]?.publisherBlockingLevel;
        const publisherData = getPublisherData(siteName, isPublisherBlocked, publisherBlockingLevel);

        if (campaignId === AllCampaignsObject.id) {
            const toggleSiteBlockingAction = isPublisherBlocked ? blockSite : unblockSite;
            dispatch(toggleSiteBlockingAction(resolvedAccountName, siteName, { handler: rollBack }));
        } else {
            const campaignUpdateAction = isPublisherBlocked ? addToCampaignProperty : removeFromCampaignProperty;
            dispatch(
                campaignUpdateAction({
                    accountId: resolvedAccountName,
                    campaignId,
                    changes: publisherData,
                    onError: rollBack,
                    entityType: getCampaignEntity({ demandType }),
                })
            );
        }
    };

const getPublisherData = (siteName, isPublisherBlocked, publisherBlockingLevel) => {
    const autoBlocked =
        publisherBlockingLevel === PUBLISHER_BLOCKING_LEVEL.AUTO ||
        publisherBlockingLevel === PUBLISHER_BLOCKING_LEVEL.EMULATION ||
        publisherBlockingLevel === PUBLISHER_BLOCKING_LEVEL.CUSTOM_RULE;
    if (!isPublisherBlocked && autoBlocked) {
        return { autoPublisherSiteName: siteName };
    }

    return { publisherSiteName: siteName };
};

export default toggleSiteBlocking;
