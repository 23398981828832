import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../../../campaigns/components';
import styles from './OnboardingFormButtons.module.scss';

export const OnboardingFormButtons = props => {
    const { submitLabel } = props;

    return (
        <div className={styles['wrapper']}>
            <FormButtons
                {...props}
                className={styles['container']}
                hasCancel={false}
                submitLabel={
                    submitLabel ?? <FormattedMessage id="onboarding.assistant.next.step" defaultMessage="Next Step" />
                }
                showFooterValidations={false}
            />
        </div>
    );
};
