import React from 'react';
import { DesktopIcon, InAppIcon, MobileSimpleIcon, TabletIcon, TargetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import { SectionHeader } from '../../../../components/Form';
import BrowserTargeting from '../../../common-campaign-form/components/BrowserTargeting';
import GeoTargeting from '../../../common-campaign-form/components/GeoTargeting/GeoTargeting';
import OsTargetingField from '../../../common-campaign-form/components/OsTargeting';
import PlatformTargeting from '../../../common-campaign-form/components/PlatformTargeting/PlatformTargeting';
import { UserFrequencyCappingField } from '../../../common-campaign-form/components/UserFrequencyCapping';
import { VIDEO_PLATFORMS } from '../../../common-campaign-form/config';
import { LanguageField } from '../Language/LanguageField';

export const VIDEO_PLATFORMS_ICONS = [
    {
        id: VIDEO_PLATFORMS.DESKTOP,
        icon: DesktopIcon,
    },
    {
        id: VIDEO_PLATFORMS.MOBILE,
        icon: MobileSimpleIcon,
    },
    {
        id: VIDEO_PLATFORMS.TABLET,
        icon: TabletIcon,
    },
    {
        id: VIDEO_PLATFORMS.IN_APP,
        icon: InAppIcon,
    },
];

const osValuesToFilter = [
    'Windows_XP',
    'Windows_Vista',
    'Windows_8.1',
    'iOS_8.1',
    'iOS_8.2',
    'iOS_8.3',
    'iOS_8.4',
    'Android_1',
];
const countryValidations = [
    {
        validationFn: (value, options) => validationFunctions.isNotEmptyString(value, options),
        messageId: 'video.campaign.creator.CountryTargeting.validations.error.not.empty',
        defaultMessage: 'Enter Countries',
    },
];

export const TargetingSection = () => {
    return (
        <CommonCollapsibleCard
            id="VIDEO_CAMPAIGNS_TARGETING"
            header={
                <SectionHeader
                    headerIcon={<TargetIcon />}
                    headerText={<FormattedMessage id="campaign.editor.targeting" defaultMessage="Campaign Targeting" />}
                />
            }
        >
            <GeoTargeting countryValidations={countryValidations} />
            <PlatformTargeting platforms={VIDEO_PLATFORMS_ICONS} />
            <LanguageField />
            <OsTargetingField valuesToFilter={osValuesToFilter} />
            <BrowserTargeting />
            <UserFrequencyCappingField />
        </CommonCollapsibleCard>
    );
};

export default TargetingSection;
