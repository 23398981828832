import React from 'react';
import classnames from 'classnames/bind';
import { stubTrue } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { ContentDescription } from 'modules/campaigns/components/ContentEditableText';
import { CreativeDescriptionTooltip } from 'modules/campaigns/components/Tooltips';
import { creativeDescriptionValidations } from 'modules/campaigns/config/validations/creative';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './contentField.module.scss';

const classNameBuilder = classnames.bind(styles);

const trueValidations = [{ validationFn: stubTrue }];

export const ContentDescriptionField = ({
    selected,
    onSelect,
    label,
    checkboxLabel,
    disabledPlaceholderId,
    validationDependencies,
    containerClassName,
    ...rest
}) => {
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.editor.content.description.placeholder',
        defaultMessage: 'Enter Description',
    });

    return (
        <FormField
            label={label}
            checkboxLabel={checkboxLabel}
            containerClass={classNameBuilder('container', containerClassName)}
            helpText={<CreativeDescriptionTooltip />}
            selected={selected}
            onSelect={onSelect}
        >
            <ContentDescription
                validations={selected === false ? trueValidations : creativeDescriptionValidations}
                validationDependencies={validationDependencies}
                placeholder={placeholder}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'Description',
                }}
                disabled={selected === false}
                disabledPlaceholderId={disabledPlaceholderId}
                contentEditable
                {...rest}
            />
        </FormField>
    );
};

ContentDescriptionField.protoTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    label: PropTypes.node,
    checkboxLabel: PropTypes.node,
    disabledPlaceholderId: PropTypes.string,
};
