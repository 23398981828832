import { columnIdDelimiter } from './generateColumnId';

const extractFieldFromColumnId = columnId => {
    const delimiterIndex = columnId.indexOf(columnIdDelimiter);

    if (delimiterIndex === -1) {
        return columnId;
    }

    return columnId.substr(delimiterIndex + 1);
};

export default extractFieldFromColumnId;
