import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { PermissionsGuard } from 'modules/taboola-common-frontend-modules/authentication';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import { InternalLink } from '../InternalLink';
import styles from './adminDropdown.module.scss';

export const OptionWrapper = ({ permissions, operator, href, children }) => {
    return (
        <PermissionsGuard key={href} permissions={permissions || []} operator={operator}>
            <InternalLink className={styles['link']} path={href}>
                {children}
            </InternalLink>
        </PermissionsGuard>
    );
};

OptionWrapper.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node,
    history: PropTypes.object,
    operator: PropTypes.string,
    permissions: permissionsShape,
    componentProps: PropTypes.object,
};

export default withRouter(OptionWrapper);
