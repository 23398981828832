import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './daypartingHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const DaypartingHeader = () => (
    <div className={styles['row']}>
        <div className={classNameBuilder('day-column', 'column-header')}>
            <FormattedMessage id="campaign.editor.schedule.dayparting.header.day" defaultMessage="Day" />
        </div>
        <div className={classNameBuilder('hour-column', 'column-header')}>
            <FormattedMessage
                id="campaign.editor.schedule.dayparting.header.start.time"
                defaultMessage="Starting Time"
            />
        </div>
        <div className={styles['dropdown-column-divider']} />
        <div className={classNameBuilder('hour-column', 'column-header')}>
            <FormattedMessage id="campaign.editor.schedule.dayparting.header.end.time" defaultMessage="Ending Time" />
        </div>
    </div>
);

export default DaypartingHeader;
