import encodeurl from 'encodeurl';
import { saveVideoCreative } from 'modules/campaigns/flows';
import getChangesObject from 'modules/taboola-common-frontend-modules/utils/objectDiff';

export const editMotionAdCreativeHandler =
    (accountId, data, dispatch) =>
    async ({ creativeBeingEdited }) => {
        const savedCreative = { ...data, url: encodeurl(data.url) };
        const getCreativeChanges = () => {
            const changes = getChangesObject(data, creativeBeingEdited);
            const { selectedCampaigns, url, ...restChanges } = changes;
            const urlObj = url ? { url: encodeurl(url) } : {};
            return { ...restChanges, ...urlObj };
        };

        const result = await dispatch(saveVideoCreative(accountId, savedCreative, getCreativeChanges()));
        if (!result?.error) {
            return result;
        }
    };
