import React from 'react';
import { VariationsIcon } from 'tuui';
import { CreativeStudioBaseHeader } from '../CreativeStudioBaseHeader';

export const ImageVariationsContentHeader = () => (
    <CreativeStudioBaseHeader
        icon={<VariationsIcon />}
        titleMessageId="creative.studio.image.variations.header.title"
        subTitleMessageId="creative.studio.image.variations.header.sub.title"
    />
);
