import { useCallback } from 'react';
import uuid from 'uuid/v1';

export const newCondition = (ruleMetric = '', settingValue) => ({
    ruleMetric,
    subMetric: {
        property: settingValue?.conversionEvent ? 'CONVERSION_RULE' : undefined,
    },
    id: uuid(),
});
export const useConditionCollection = (conditions, setConditions) => {
    const onAddCondition = useCallback(() => {
        setConditions([...conditions, newCondition()]);
    }, [conditions, setConditions]);

    const onDeleteCondition = useCallback(
        indexToDelete => {
            setConditions(conditions.filter((t, index) => indexToDelete !== index));
        },
        [conditions, setConditions]
    );
    return {
        onAddCondition,
        onDeleteCondition,
    };
};
