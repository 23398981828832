import React from 'react';
import { RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './audienceCreationRadioIconGroup.module.scss';

export const AudienceCreationRadioIconGroup = ({ type, onTypeChange, audienceConfigs }) => (
    <RadioGroup selectedValue={type} onChange={onTypeChange} className={styles['group']}>
        {audienceConfigs.map(({ id, label, subtext, IconComponent }) => (
            <RadioIcon
                key={id}
                value={id}
                iconComponent={<IconComponent />}
                title={<FormattedMessage id={`audience.creator.selection.${id}.label`} defaultMessage={label} />}
                labelClassName={styles['radio']}
                titleClassName={styles['title']}
                descriptionClassName={styles['description']}
                description={
                    <FormattedMessage id={`audience.creator.selection.${id}.description`} defaultMessage={subtext} />
                }
            />
        ))}
    </RadioGroup>
);
