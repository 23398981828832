import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import useUnsavedChangesController from 'modules/campaigns/hooks/useUnsavedChangesController';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { PERFORMANCE_RULES_CREATOR_ROUTE_PATH, RULE_ROUTE } from '../config';
import { PerformanceCreatorPage } from './PerformanceRulesCreatorPage';

const path = PERFORMANCE_RULES_CREATOR_ROUTE_PATH;
export const PerformanceRuleCreator = ({ onCancel }) => {
    const createPerformancePath = replacePathParams(path);

    useUnsavedChangesController(PERFORMANCE_RULES_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                strict
                path={createPerformancePath}
                render={props => <PerformanceCreatorPage {...props} onCancel={onCancel} />}
            />
            <Redirect to={RULE_ROUTE} />
        </Switch>
    );
};

PerformanceRuleCreator.propTypes = {
    onCancel: PropTypes.func,
};
