import { useCallback } from 'react';
import { AUDIENCES_ROUTE } from 'modules/audiences/config';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';

const useAudienceCreatorState = ({ submitAudience }) => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { submit, step, setStep } = useFormState();

    const submitWrapper = useCallback(() => {
        const submitAudienceWrapper = audience => submitAudience(audience, accountId);

        submit(submitAudienceWrapper, AUDIENCES_ROUTE);
    }, [submit, accountId, submitAudience]);

    return {
        step,
        setStep,
        submit: submitWrapper,
    };
};

export default useAudienceCreatorState;
