import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { DropdownMenu } from 'components';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { languageOptions } from '../../../AiVariationsContextProvider/languageOptions';
import styles from './titleGenerationLanguageDropdown.module.scss';

export const TitleGenerationLanguageDropdown = ({ language, onLanguageChange, disabled }) => {
    const { formatMessage } = useIntl();
    return (
        <FormField
            inputId="title-generation-form-language"
            label={formatMessage({
                id: 'creative.creator.aiTitleSuggestions.targetAudience.languages.label',
                defaultMessage: 'What is your target audience language?',
            })}
            labelClass={styles['label']}
        >
            <DropdownMenu
                aria-label="languages-dropdown"
                selectedValueObject={language}
                onChange={onLanguageChange}
                options={languageOptions}
                searchable={true}
                disabled={disabled}
                className={styles['dropdown']}
                dropdownWrapperClassName={styles['wrapper']}
            />
        </FormField>
    );
};
