import React from 'react';
import { CodeIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DynamicParametersFormField } from '../DynamicParametersField/DynamicParametersField';
import { EventCodeSnippetField } from '../EventCodeSnippetField/EventCodeSnippetField';
import { AdvancedOptions } from '../ManualSetupAdvancedOptions/AdvancedOptions';
import styles from './eventCodeSection.module.scss';

export const EventCodeSection = ({ eventName }) => {
    return (
        <CommonCollapsibleCard
            id="EVENT_CODE"
            header={
                <SectionHeader
                    headerIcon={<CodeIcon />}
                    headerText={
                        <FormattedMessage
                            id="tracking.conversion.creator.setup.event.code.title"
                            defaultMessage="Event Code"
                        />
                    }
                />
            }
        >
            <div className={styles['col-list']}>
                <DynamicParametersFormField />
                <EventCodeSnippetField />
                <AdvancedOptions isEventImagePixel eventName={eventName} />
            </div>
        </CommonCollapsibleCard>
    );
};
