import React, { useCallback } from 'react';
import { CheckboxGroup, FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { parametersConfig } from '../../config/eventCodeConfig';
import { DynamicParametersCheckBoxTitle } from './DynamicParametersCheckBoxTitle';
import { DynamicParametersTooltip } from './dynamicParametersTooltip';
import styles from './dynamicParametersFormField.module.scss';

export const DynamicParametersFormField = () => {
    const { value: parameters = [], onChange: onParametersChange } = useFormFieldValue({
        field: 'dynamicParameters',
    });
    const onSelectParam = useCallback(
        selectedParam => onParametersChange(parameters ? [...parameters, selectedParam] : [selectedParam]),
        [parameters, onParametersChange]
    );

    const onUnSelectParam = useCallback(
        unSelectedParam => {
            onParametersChange(parameters.filter(value => value !== unSelectedParam));
        },
        [parameters, onParametersChange]
    );
    return (
        <FormField
            inputId="dynamic-parameter"
            label={
                <FormattedMessage id="tracking.dynamic.field.label" defaultMessage="Dynamic Parameters (advanced)" />
            }
            description={
                <FormattedMessage
                    id="tracking.dynamic.field.description"
                    defaultMessage="This will allow returning a dynamic value with each conversion such as purchase price. After website implementation, update the code with a corresponding function."
                />
            }
            helpText={<DynamicParametersTooltip />}
        >
            <CheckboxGroup
                id="dynamicParametersCheckboxGroup"
                onSelectCheckbox={onSelectParam}
                onUnselectCheckbox={onUnSelectParam}
                selectedValues={parameters}
                className={styles['dynamic-parameters-checkbox-group']}
            >
                {parametersConfig.map(({ value, defaultMessage }) => (
                    <TextCheckbox
                        value={value}
                        key={value}
                        label={defaultMessage}
                        title={
                            <DynamicParametersCheckBoxTitle
                                value={value}
                                id={`tracking.dynamic.field.parameter.${value}`}
                                defaultMessage={defaultMessage}
                                checkedParameters={parameters}
                            />
                        }
                        labelClassName={styles['checkbox-label']}
                    />
                ))}
            </CheckboxGroup>
        </FormField>
    );
};
