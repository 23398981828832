import React from 'react';
import { batch } from 'react-redux';
import { removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { videoCreativesApi } from 'services/api';
import { errorMessagesUtils } from 'services/utils';
import { REPORT_TYPE } from '../../../config';
import { createCreativeError, createCreativeSuccess, requestCreateCreative } from '../actions/createCreative';
import { transformVideoCreativeToGW } from '../hooks/videoCreativeTransformers';

const successIndication = {
    message: <FormattedMessage id="video.creative.create.success" defaultMessage="Creative successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="creative.create.error.highlight" defaultMessage="Unable to save changes." />,
});

export const postCreativeCreationSortState = [{ colId: `${REPORT_TYPE.CREATIVE_VIDEO}_startDate`, sort: 'desc' }];

const createCreative =
    ({ accountId, campaignId, creative }) =>
    async dispatch => {
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        dispatch(requestCreateCreative());
        let createdCreative;

        try {
            const response = await videoCreativesApi.createCreative(
                accountId,
                campaignId,
                transformVideoCreativeToGW(creative)
            );

            createdCreative = { id: response.id, name: response.name, advertiserName: response.accountName };
            batch(() => {
                dispatch(createCreativeSuccess(response));
                dispatch(addIndication(successIndication));
            });
        } catch (error) {
            batch(() => {
                dispatch(addIndication(generateErrorIndication(error)));
                dispatch(createCreativeError(error));
            });
            throw error;
        }

        return createdCreative;
    };

export default createCreative;
