import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { recommendationsDataSelector } from '../../../recommendations/selectors';

const LOADING_TIMEOUT = 3000;

export const useStepsLoader = ({ step, submitStatus }) => {
    const [loading, setLoading] = useState(false);
    const timeoutId = useRef();
    const recommendationData = useSelector(recommendationsDataSelector);
    const emulateLoading = useCallback(() => {
        setLoading(true);
        clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout(() => {
            setLoading(false);
        }, LOADING_TIMEOUT);
    }, []);

    useLayoutEffect(() => {
        if (step === 0 && recommendationData?.onboarding?.campaigns) {
            emulateLoading();
        }
    }, [step, recommendationData, emulateLoading]);

    useLayoutEffect(() => {
        if (submitStatus === COMPONENT_STATUS.LOADING) {
            emulateLoading();
        }
    }, [submitStatus, emulateLoading]);

    return loading;
};
