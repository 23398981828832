"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgProfile(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M10 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V6C19 4.82266 18.1866 4 17 4H14V6H17V18H7V6H10V4Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M8 4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V6C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6V4ZM10 4V6H14V4H10Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M9 11C9 10.4477 9.44772 10 10 10H14C14.5523 10 15 10.4477 15 11C15 11.5523 14.5523 12 14 12H10C9.44772 12 9 11.5523 9 11Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M9 15C9 14.4477 9.44772 14 10 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H10C9.44772 16 9 15.5523 9 15Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgProfile;