"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgArrange(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M14 10.5a1.1 1.1 0 0 1-1.501 0c-.415-.396-.499-.307-.499-1.062L14 7.5c.302-.286 1.006-1.5 2.003-1.5C17 6 17.7 7.214 18 7.5l1.989 1.938c0 .721-.07.666-.486 1.062a1.1 1.1 0 0 1-1.502 0L17 8.768 16.998 18c0 .552-.412 1-.998 1a.98.98 0 0 1-1.002-1L15 8.768 14 10.5zm-8 3l1 1.755V6c0-.552.416-1 1.002-1C8.59 5 9 5.448 9 6v9.255l1-1.755a1.1 1.1 0 0 1 1.503 0c.415.396.486.341.486 1.062L10 16.5c-.3.286-1 1.5-1.997 1.5-.997 0-1.701-1.214-2.003-1.5l-2-1.938c0-.755.084-.666.499-1.062A1.1 1.1 0 0 1 6 13.5z"
            })
        )
    );
}
exports.default = SvgArrange;