import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { CREATIVE_NAME, ID } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graph } from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const creativeVideoReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CREATIVE_VIDEO],
    endpoint: 'campaign-report-by-creative',
    graph,
    columnsDef: columnDefinitions,
    rowIdField: ID.field,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    rowHeight: 62,
    searchPlaceholderMsgId: 'app.reports.byCreativeVideo.search.placeholder',
    searchField: 'creative_name',
    sortMap: {
        [CREATIVE_NAME.field]: 'creative_name',
    },
    filters: [
        { id: FILTER_TYPE.COUNTRY },
        { id: FILTER_TYPE.PLATFORM },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default creativeVideoReportConfig;
