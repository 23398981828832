import React from 'react';
import PropTypes from 'prop-types';
import { SystemBreadcrumbs } from '../SystemBreadcrumbs/SystemBreadcrumbs';
import ContentLoader from './IndicatorContentLoader';
import styles from './indicatorContentLoader.module.scss';

export const ReportBreadcrumbs = ({ title, steps, shouldRenderEdit, handleEdit }) => {
    return (
        <div className={styles['container']}>
            {title ? (
                <SystemBreadcrumbs
                    title={title}
                    steps={steps}
                    shouldRenderEdit={shouldRenderEdit}
                    handleEdit={handleEdit}
                />
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};

ReportBreadcrumbs.propTypes = {
    title: PropTypes.string.isRequired,
    steps: PropTypes.array,
    shouldRenderEdit: PropTypes.bool,
    handleEdit: PropTypes.func,
};

export default ReportBreadcrumbs;
