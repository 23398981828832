import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { COMPONENT_STATUS } from 'services/constants';
import { useCreateFormSelectedCampaignId } from '../../../hooks/useCreateFormSelectedCampaignId';
import {
    getHasPageProductDetails,
    useAiTextSuggestions,
    useAiVariationsContext,
} from '../../AiVariationsContextProvider';
import { useAiVariationsEventsPrefix } from '../../AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import { HelpWriteModalWithProviders } from '../components/HelpWrite/HelpWriteModal';
import styles from '../components/HelpWrite/helpWriteModal.module.scss';

export const useHelpWriteModal = ({ eventType }) => {
    const data = useAiVariationsContext();
    const {
        onGenerationSuccess,
        onGenerationError,
        setPageProductDetails,
        setCrawlLpStatus,
        crawlLpStatus,
        landingPageData,
        setLandingPageData,
        language,
        onLanguageChange,
    } = data;
    const { open: openModal } = useModal();
    const campaignId = useCreateFormSelectedCampaignId();
    const { setNewSuggestedTextBatch } = useAiTextSuggestions({ campaignId });
    const hasPageProductDetails = getHasPageProductDetails(data);
    const eventsPrefix = useAiVariationsEventsPrefix();
    const { value: urls } = useFormFieldValue({
        field: 'urls',
    });
    const url = urls[0]?.value || '';
    const pageName = getPageName();
    const getGtmParams = value => ({
        component: `${eventsPrefix}_${hasPageProductDetails ? 'Edit' : 'Help'}_${eventType}`,
        taboolaCampaignId: campaignId,
        value,
        pageName,
    });
    const open = async () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, getGtmParams('Open'));
        const result = await openModal({
            initialData: { campaignId, url, pageProductDetails: data, crawlLpStatus, landingPageData, language },
            className: styles['modal'],
            contentClassName: styles['modal-content'],
            contentRenderer: HelpWriteModalWithProviders,
        });
        if (result) {
            const { suggestedText, pageProductDetails, crawlLpStatus, landingPageData, language, error } = result;
            if (crawlLpStatus === COMPONENT_STATUS.ACTIVE) {
                setCrawlLpStatus(crawlLpStatus);
            }
            if (pageProductDetails) {
                setPageProductDetails(pageProductDetails);
            }
            if (landingPageData) {
                setLandingPageData(landingPageData);
            }
            if (language) {
                onLanguageChange(language);
            }
            if (error) {
                onGenerationError(error);
            } else if (suggestedText) {
                setNewSuggestedTextBatch(suggestedText);
                onGenerationSuccess(suggestedText);
            }
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, getGtmParams('Complete'));
        } else {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, getGtmParams('Cancel'));
        }
    };
    return open;
};
