import React, { createContext, useContext, useMemo } from 'react';
import { isFunction } from 'lodash';
import * as defaultConfig from './defaultConfig';

const ThirdPartyTagsContext = createContext(defaultConfig);

export function useThirdPartyTagsConfig() {
    return useContext(ThirdPartyTagsContext);
}

export const ThirdPartyConfigOverrides = ({ overrides, children }) => {
    const config = useMemo(() => {
        if (overrides) {
            if (isFunction(overrides)) {
                return { ...defaultConfig, ...overrides(defaultConfig) };
            }
            return { ...defaultConfig, ...overrides };
        } else {
            return defaultConfig;
        }
    }, [overrides]);

    return <ThirdPartyTagsContext.Provider value={config}>{children}</ThirdPartyTagsContext.Provider>;
};
