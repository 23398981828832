import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedValue } from 'taboola-ultimate-ui';
import CollapsiblePicker from 'components/CollapsiblePicker';
import useComponentStatus from 'hooks/useComponentStatus';
import CampaignPickerError from 'modules/campaigns/components/CampaignPicker/CampaignPickerError/CampaignPickerError';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { selectedAccountSelector } from '../../../../selectors';
import { selectSegment, setSegmentPickerSorting } from '../../actions';
import { sortFieldsOptions } from '../../config';
import { audienceInsightsModuleStatusSelector, selectedSegmentSelector } from '../../selectors';
import SegmentPickerListItem from './SegmentPickerListItem';
import useSegmentPickerService from './services/useSegmentPickerService';

const SEGMENT_PICKER_ITEM_HEIGHT = 40;

const SegmentPicker = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { id: selectedAccountId } = useSelector(selectedAccountSelector);
    const audienceInsightsModuleStatus = useSelector(audienceInsightsModuleStatusSelector);
    const { uddId: selectedSegmentId } = useSelector(selectedSegmentSelector);
    const { status, setStatus, isModuleReady } = useComponentStatus();
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebouncedValue(searchText);
    const { segments, reload, reset, loadNextPage, isItemLoaded, paginationStatus, sortType, sortField } =
        useSegmentPickerService(debouncedSearchText, status);
    const handleOnSelectSortField = ({ value: segmentPickerSortingField }) =>
        dispatch(setSegmentPickerSorting({ segmentPickerSortingField }));
    const handleOnSelectSortType = segmentPickerSortingType =>
        dispatch(setSegmentPickerSorting({ segmentPickerSortingType }));

    const sortTooltip = useMemo(() => {
        const sortFieldMessage = formatMessage({ id: `segment.picker.sorting.${sortField}` });
        const sortTypeMessage = formatMessage({ id: `segment.picker.sorting.${sortType}` });
        return formatMessage(
            { id: 'segment.picker.sorting.tooltip' },
            { field: sortFieldMessage, type: sortTypeMessage }
        );
    }, [sortField, sortType, formatMessage]);

    const handleSelectSegment = useCallback(
        segment => {
            dispatch(selectSegment(segment));
        },
        [dispatch]
    );

    useEffect(() => {
        if (!isModuleReady) {
            return;
        }

        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText, sortType, sortField, isModuleReady]);

    // clear state on selectedAccountId change
    useEffect(() => {
        reset();
        setSearchText('');
        setStatus(COMPONENT_STATUS.INITIAL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccountId]);

    // Sync audienceInsightsModuleStatus and component status
    useEffect(() => {
        setStatus(audienceInsightsModuleStatus);
    }, [audienceInsightsModuleStatus, setStatus]);

    return (
        <>
            {status === COMPONENT_STATUS.ERROR && segments.length === 0 ? (
                <CampaignPickerError reload={reload} />
            ) : (
                <CollapsiblePicker
                    sortType={sortType}
                    sortField={sortField}
                    sortOptions={sortFieldsOptions}
                    onSelectSortField={handleOnSelectSortField}
                    onSelectSortType={handleOnSelectSortType}
                    listItems={segments}
                    selectedItem={selectedSegmentId}
                    onSelectListItem={handleSelectSegment}
                    totalItems={segments.length}
                    loadNextPage={loadNextPage}
                    isItemLoaded={isItemLoaded}
                    isSelectedItem={(item, val) => item?.uddId === val}
                    paginationStatus={paginationStatus}
                    getItemSize={() => SEGMENT_PICKER_ITEM_HEIGHT}
                    getItemKey={item => item.uddId}
                    listItemComponent={SegmentPickerListItem}
                    onSearch={setSearchText}
                    searchText={searchText}
                    sortTooltip={sortTooltip}
                />
            )}
        </>
    );
};

export default SegmentPicker;
