'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getDragEndData = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _shouldSkipDragEvent = require('./shouldSkipDragEvent');

var getDragEndData = exports.getDragEndData = function getDragEndData(data, dragEvent) {
    if ((0, _shouldSkipDragEvent.shouldSkipDragEvent)(dragEvent)) {
        return data;
    }
    var oldIndex = dragEvent.source.index;
    var newIndex = dragEvent.destination.index;
    var orderItems = Array.from(data);

    var _orderItems$splice = orderItems.splice(oldIndex, 1),
        _orderItems$splice2 = _slicedToArray(_orderItems$splice, 1),
        removed = _orderItems$splice2[0];

    orderItems.splice(newIndex, 0, removed);

    return orderItems;
};