import {
    FETCH_CREATIVE_FOR_EDIT_ERROR,
    FETCH_CREATIVE_FOR_EDIT_SUCCESS,
    REQUEST_FETCH_CREATIVE_FOR_EDIT,
} from './actionTypes';

export const requestFetchCreativeForEdit = () => ({ type: REQUEST_FETCH_CREATIVE_FOR_EDIT });

export const fetchCreativeForEditSuccess = creative => ({ type: FETCH_CREATIVE_FOR_EDIT_SUCCESS, payload: creative });

export const fetchCreativeForEditError = error => ({ type: FETCH_CREATIVE_FOR_EDIT_ERROR, payload: error });
