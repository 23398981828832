import React from 'react';
import { ScheduleSectionFrame } from '../ScheduleSectionFrame';
import TimeFrame from '../TimeFrame/TimeFrame';

const TimeFrameSection = () => (
    <ScheduleSectionFrame>
        <TimeFrame />
    </ScheduleSectionFrame>
);

export default TimeFrameSection;
