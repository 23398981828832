import React from 'react';
import { MissingFeature } from 'components/MissingFeature';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './unsupportedConfiguration.module.scss';

const UnsupportedConfiguration = () => (
    <MissingFeature
        title={
            <FormattedMessage
                id="campaign.editor.unsupported.configuration.title"
                defaultMessage="Unsupported Configuration"
            />
        }
        description={
            <>
                <FormattedMessage
                    id="campaign.editor.unsupported.configuration.description"
                    defaultMessage="The applied configuration is not editable in the platform. For any changes please contact your account manager or "
                />
                <a href="mailto:support@taboola.com" className={styles['link']}>
                    <FormattedMessage id="navigation.caption.contact-support" defaultMessage="contact support" />
                </a>
            </>
        }
    />
);

export default UnsupportedConfiguration;
