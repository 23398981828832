import { useCallback, useMemo, useState } from 'react';
import { size } from 'lodash';
import { LoadingMode } from '../utils';

export const useBundleLoadingWrapper = ({ nodes, loadingPaths }) => {
    const [loadingCounter, setLoadingCounter] = useState(0);

    const loadingMode = useMemo(() => {
        if (loadingCounter === 0) {
            return LoadingMode.NONE;
        } else if (size(loadingPaths) < size(nodes)) {
            return LoadingMode.PARTIAL;
        } else {
            return LoadingMode.FULL;
        }
    }, [loadingCounter, nodes, loadingPaths]);

    const wrapWithLoading = useCallback(
        async (paths, cb) => {
            setLoadingCounter(prev => prev + 1);
            const returnValue = await cb();
            setLoadingCounter(prev => prev - 1);

            return returnValue;
        },
        [setLoadingCounter]
    );

    return { wrapWithLoading, loadingMode };
};
