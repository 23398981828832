import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormField, TimeFramePicker } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { timeFrameValidations } from 'modules/campaigns/config';
import styles from 'modules/campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { withValidations } from 'modules/taboola-common-frontend-modules/validations';
import { useStartDateFormFieldValue } from '../../../common-campaign-form/components/TimeFrame/hooks/useStartDateFormFieldValue';
import CampaignsGroupTimeFrameTooltip from './CampaignsGroupTimeFrameTooltip';

const TimeFramePickerWithValidations = withValidations(withIndication(TimeFramePicker), {
    validations: timeFrameValidations,
});

const CampaignsGroupTimeFrame = ({ formFieldParams, isDisabled }) => {
    const { formatMessage } = useIntl();
    const { value: startDate, onChange: onChangeStartDate, mode } = useStartDateFormFieldValue();
    const { value: endDate, onChange: onChangeEndDate } = useFormFieldValue({ field: 'endDate' });
    const { onChange: onChangeHasEndDate } = useFormFieldValue({ field: 'hasEndDate' });

    const handleEndDate = useCallback(
        date => {
            onChangeHasEndDate(date !== null);
            onChangeEndDate(date);
        },
        [onChangeEndDate, onChangeHasEndDate]
    );

    const isEditMode = mode === FORM_MODES.EDIT;
    const isStartDateDisabled = isEditMode;
    const validationValueWithContext = {
        isEditMode,
        startDate: startDate || '',
        endDate: endDate || '',
    };

    return (
        <FormField
            containerClass={styles['input']}
            inputId="time-frame"
            label={<FormattedMessage id="campaign.time.frame.title" defaultMessage="Time Frame" />}
            helpText={<CampaignsGroupTimeFrameTooltip />}
            {...formFieldParams}
        >
            <TimeFramePickerWithValidations
                startDate={startDate && moment(startDate)}
                endDate={endDate && moment(endDate)}
                onStartDateChange={onChangeStartDate}
                onEndDateChange={handleEndDate}
                disableStartDate={isStartDateDisabled || isDisabled}
                disableEndDate={isDisabled}
                validationContext={validationValueWithContext}
                valueToValidate={validationValueWithContext}
                validationId="timeFrame"
                endDatePlaceholderText={formatMessage({
                    id: 'campaign.time.frame.no.end.date',
                    defaultMessage: 'No End Date',
                })}
                startDataInputIcon={<FormattedMessage id="campaign.time.frame.start" defaultMessage="START" />}
                endDataInputIcon={<FormattedMessage id="campaign.time.frame.end" defaultMessage="END" />}
            />
        </FormField>
    );
};

CampaignsGroupTimeFrame.propTypes = {
    formFieldParams: PropTypes.object,
    isDisabled: PropTypes.bool,
};

CampaignsGroupTimeFrame.defaultProps = {
    formFieldParams: {},
};

export default CampaignsGroupTimeFrame;
