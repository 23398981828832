import {
    FREQUENCY_CONDITIONS,
    MONTHLY_CONDITIONS,
    WEEKLY_CONDITIONS,
} from '../components/ScheduledReportsFrequencyField/scheduledReportFrequencyFieldOptions';

export const resolveSubFrequencyInitValue = frequency => {
    return frequency === FREQUENCY_CONDITIONS.DAILY
        ? null
        : frequency === FREQUENCY_CONDITIONS.WEEKLY
        ? WEEKLY_CONDITIONS.MONDAY
        : MONTHLY_CONDITIONS.FIRST_DAY_OF_MONTH;
};
