import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    SortIcon,
    STYLED_BUTTON_SIZE,
    StyledButton,
    TOOLTIP_POSITION,
    TooltipV2 as Tooltip,
    HeadlessDropdown,
    SORTING_ORDER_TYPES,
} from 'taboola-ultimate-ui';
import CollapsiblePickerSortDropdownMenu from './CollapsiblePickerSortDropdownMenu';
import styles from './collapsiblePickerSortDropdown.module.scss';

const defaultMenuStyles = {
    left: 0,
    marginTop: styles.dropdownMarginTop,
    color: styles.dropdownColor,
    boxShadow: styles.dropdownShadow,
    border: styles.dropdownBorder,
    borderRadius: styles.dropdownBorderRadius,
};

const defaultOptionStyles = {
    option: (dropdownStyles, state) => ({
        ...dropdownStyles,
        backgroundColor: state.isFocused ? styles.dropdownHoverBackground : null,
        color: state.isSelected ? styles.dropdownSelectedColor : dropdownStyles.color,
        fontWeight: state.isSelected ? styles.dropdownSelectedFontWeight : dropdownStyles.fontWeight,
    }),
};

const CollapsiblePickerSortDropdown = ({
    options,
    menuStyles: propMenuStyles,
    styles: propStyles,
    sortType,
    sortField,
    disabled,
    onSelectSortField,
    sortTooltip,
    sortFooter,
    onSelectSortType,
}) => {
    const [open, setOpen] = useState(false);
    const menuStyles = useMemo(() => ({ ...defaultMenuStyles, ...propMenuStyles }), [propMenuStyles]);
    const dropdownStyles = useMemo(() => ({ ...defaultOptionStyles, ...propStyles }), [propStyles]);
    const handleToggleDropdown = isOpen => setOpen(isOpen);

    return (
        <div>
            <HeadlessDropdown
                options={options}
                onChange={onSelectSortField}
                menuListRenderer={CollapsiblePickerSortDropdownMenu}
                menuStyles={{ menuStyles }}
                styles={dropdownStyles}
                selectedValueObject={{ value: sortField }}
                menuPortalTarget={document.body}
                sortType={sortType}
                onSelectSortType={onSelectSortType}
                open={open}
                onToggleDropdown={handleToggleDropdown}
                disabled={disabled}
                sortFooter={sortFooter}
            >
                <StyledButton
                    className={styles['button']}
                    size={STYLED_BUTTON_SIZE.MEDIUM}
                    iconBefore={<SortIcon className={styles['icon']} />}
                    disabled={disabled}
                    aria-label="Campaign picker sort dropdown"
                >
                    {sortTooltip && (
                        <Tooltip arrow position={TOOLTIP_POSITION.RIGHT_END}>
                            {sortTooltip}
                        </Tooltip>
                    )}
                </StyledButton>
            </HeadlessDropdown>
        </div>
    );
};

CollapsiblePickerSortDropdown.propTypes = {
    /* sort direction (Ascending vs Descending vs Non Sorted) */
    sortType: PropTypes.oneOf(Object.values(SORTING_ORDER_TYPES)),
    /* selected sort value */
    sortField: PropTypes.any,
    /* list of sort field options */
    options: PropTypes.array,
    /* override styles for sort menu */
    menuStyles: PropTypes.object,
    /* override styles for headless dropdown */
    styles: PropTypes.object,
    /* func called when a sort field is selected */
    onSelectSortField: PropTypes.func,
    /* func called when sort type is selected */
    onSelectSortType: PropTypes.func,
    /* tooltip to render when dropdown is closed */
    sortTooltip: PropTypes.node,
    /* footer placed below sort menu */
    sortFooter: PropTypes.node,
};

export default CollapsiblePickerSortDropdown;
