import React from 'react';
import { useDropdownOptions, useDropdownValue } from 'hooks';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownField } from '../../../../../components/Form';
import { resourceTransformer } from '../../../../../services/utils/dropdownUtils';
import EMPTY_FIELD_VALUE from '../../../../campaigns-reports/config/defaultValues';
import useFetcher from '../hooks/useFetcher';

const defaultInitialValue = [];

const ProfileDropdown = ({
    field,
    staticOptions,
    labelId,
    id,
    fetcher,
    errorIndication,
    addIndicationFlow,
    messageId,
}) => {
    const { value = EMPTY_FIELD_VALUE, onChange } = useFormFieldValue({ field: `campaignProfile.${field}` });
    const handleOnChange = ({ value }) => onChange(value);
    const resourceValues = useFetcher({
        fetcher,
        errorIndication,
        addIndicationFlow,
        emptyValue: defaultInitialValue,
    });
    const resourceOptions = useDropdownOptions(resourceValues, messageId, resourceTransformer);
    const selectedValue = useDropdownValue({ value }, messageId);
    const options = staticOptions || resourceOptions;

    return (
        <DropdownField
            searchable
            id={id}
            label={<FormattedMessage id={labelId} defaultMessage="" />}
            options={options}
            selectedValueObject={selectedValue}
            onChange={handleOnChange}
            placeholder={
                <FormattedMessage
                    id={`campaign.editor.profile.${field}.placeholder`}
                    defaultMessage={`Select ${field}`}
                />
            }
        />
    );
};

export default ProfileDropdown;
