import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';

export const PERFORMANCE_STATUS = {
    MOTION: 'PERFORMANCE_VIDEO',
    IMAGE: 'STATIC_IMAGE',
    CALL_TO_ACTION: 'CALL_TO_ACTION',
    GIF_T: 'GIF_T',
    VIDEO: 'VIDEO',
    TEMPLATE: 'CREATIVE_TEMPLATE',
    HTML_CARD: 'HTML_CARD',
    HTML_CARD_EXP: 'HTML_CARD_EXP',
};

export const PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP = {
    [PERFORMANCE_STATUS.MOTION]: CREATIVE_TYPE.MOTION,
    [PERFORMANCE_STATUS.IMAGE]: CREATIVE_TYPE.IMAGE,
    [PERFORMANCE_STATUS.CALL_TO_ACTION]: CREATIVE_TYPE.IMAGE, //Is not a creative type
    [PERFORMANCE_STATUS.GIF_T]: CREATIVE_TYPE.IMAGE, //New items should not use it
    [PERFORMANCE_STATUS.VIDEO]: CREATIVE_TYPE.VIDEO,
    [PERFORMANCE_STATUS.TEMPLATE]: CREATIVE_TYPE.CREATIVE_TEMPLATE,
};

export default PERFORMANCE_STATUS;
