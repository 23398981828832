import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { CREATIVE_STATUS } from 'modules/campaigns/config';
import { bulkResubmitCreatives, resubmitCreative } from 'modules/campaigns/flows';
import { creativesMapSelector } from 'modules/campaigns/selectors';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { isCreativeResubmittable, isRssChild, isRssFeed } from '../config/predicates';

const contentPolicyUrl =
    'https://help.taboola.com/hc/en-us/articles/115007287467-Advertising-Content-Policies-Overview';

export const resubmitModalPrompt = {
    title: <FormattedMessage id="creative.resubmit.modal.title" tagName="h2" defaultMessage="Resubmit to Review" />,
    content: <FormattedMessage id="creative.resubmit.modal.content" values={{ href: contentPolicyUrl }} />,
    formProps: { submitButtonText: <FormattedMessage id="app.actionButtons.submit" defaultMessage="Submit" /> },
};

export const sendGtmResubmitEvent = numSelectedForResubmission =>
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        pageName: getPageName(),
        component: 'Resubmit',
        columnName: 'Resubmit',
        value: numSelectedForResubmission,
    });

export const useIsCreativeResubmitEnabled = () => {
    const configured = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_RESUBMISSION_ENABLED]: 'true',
        [FEATURE_FLAGS.SUBMISSION_DATA_ALLOWED_IN_ADS_REPORT]: 'true',
    });

    return configured;
};

export const getIsEligibleForResubmission = (creatives, creativeIds, eligibleRejectReasons) => {
    if (isEmpty(creatives)) {
        return false;
    }

    return creativeIds.every(creativeId => {
        const creative = creatives[creativeId] || {};
        const { rejectReason, status } = creative;
        const isRssItem = isRssChild(creative) || isRssFeed(creative);

        return (
            status === CREATIVE_STATUS.REJECTED &&
            eligibleRejectReasons.includes(rejectReason) &&
            !isRssItem &&
            isCreativeResubmittable(creative)
        );
    });
};

export const useCreativeResubmitButton = ({ isForBulkAction, creativeIds = [] }) => {
    const { open: openModal } = useModal();
    const creatives = useSelector(creativesMapSelector);
    const { [COMMON_FLAGS.RESUBMIT_CREATIVES_LANDING_PAGE_REJECTION_REASONS]: rejectReasonsRaw } = useCommonConfig([
        COMMON_FLAGS.RESUBMIT_CREATIVES_LANDING_PAGE_REJECTION_REASONS,
    ]);
    const eligibleRejectReasons = useMemo(() => rejectReasonsRaw.split(','), [rejectReasonsRaw]);

    const [{ accountId }] = useSelectedAccount();
    const dispatch = useDispatch();

    const isEligibleForResubmission = useMemo(
        () => getIsEligibleForResubmission(creatives, creativeIds, eligibleRejectReasons),
        [creatives, creativeIds, eligibleRejectReasons]
    );

    const onClick = useCallback(async () => {
        const modalResult = await openModal(resubmitModalPrompt);
        if (modalResult && isForBulkAction) {
            sendGtmResubmitEvent(creativeIds.length);
            dispatch(bulkResubmitCreatives({ creativeIds, accountId }));
        } else if (modalResult) {
            sendGtmResubmitEvent(creativeIds.length);
            dispatch(resubmitCreative({ creativeIds, accountId }));
        }
    }, [accountId, creativeIds, dispatch, isForBulkAction, openModal]);

    return { isEligibleForResubmission, onClick };
};
