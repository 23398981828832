import React, { useCallback } from 'react';
import { isEmpty, get } from 'lodash';
import { AddIcon, Spinner } from 'tuui';
import { DragAndDropFileContext } from 'taboola-ultimate-ui';
import { DragAndDropFile } from 'taboola-ultimate-ui';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { operationsApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import styles from './logoUploadBox.module.scss';

const IMAGE_FILES = { 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] };
const defaultUploadErrorMessage = 'Failed to load logo';

export const LogoUploadBox = ({ setUploadedLogo, setLogoFileName, uploadStatus, setUploadStatus, setError }) => {
    const isUploadLoading = uploadStatus === COMPONENT_STATUS.LOADING;
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const onDropFileHandler = useCallback(
        async (fileList, fileRejections) => {
            setUploadStatus(COMPONENT_STATUS.LOADING);
            setError(null);

            if (!isEmpty(fileRejections)) {
                setUploadStatus(COMPONENT_STATUS.ERROR);
                setError({
                    defaultMessage: 'Unsupported logo file format',
                    id: 'creative.creator.logo.error.invalidFileFormat',
                });
                return;
            }

            const logoFile = get(fileList, '[0]');
            operationsApi
                .uploadLogo(accountId, logoFile)
                .then(({ value }) => {
                    setUploadedLogo({ logo: value });
                    setLogoFileName(logoFile.path);
                    setUploadStatus(COMPONENT_STATUS.ACTIVE);
                })
                .catch(({ messageCode = defaultUploadErrorMessage }) => {
                    setError({ defaultMessage: defaultUploadErrorMessage, id: messageCode });
                    setUploadStatus(COMPONENT_STATUS.ERROR);
                });
        },
        [accountId, setError, setUploadStatus, setUploadedLogo, setLogoFileName]
    );

    return (
        <DragAndDropFile
            fileTypes={IMAGE_FILES}
            multiple={false}
            onDrop={onDropFileHandler}
            disabled={isUploadLoading}
            text={
                <FormattedMessage
                    id="creative.creator.thumbnails.logo.add.logo.drag.drop"
                    defaultMessage="Drop your logo file here"
                />
            }
        >
            <DragAndDropFileContext.Consumer>
                {({ openFileDialog }) => (
                    <div onClick={openFileDialog} className={styles['container']}>
                        {isUploadLoading ? (
                            <>
                                <Spinner size={14} className={styles['spinner']} />
                                <FormattedMessage
                                    id="creative.creator.thumbnails.logo.uploading"
                                    defaultMessage="Uploading logo..."
                                />
                            </>
                        ) : (
                            <>
                                <AddIcon className={styles['add-icon']} />
                                <FormattedMessage
                                    id="creative.creator.thumbnails.logo.add.logo"
                                    defaultMessage="Add logo"
                                />
                            </>
                        )}
                    </div>
                )}
            </DragAndDropFileContext.Consumer>
        </DragAndDropFile>
    );
};
