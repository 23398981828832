import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { resetMediaLibraryItems } from '../../../actions';
import { emptyGalleryTypes } from '../ImageGallery';
import { PaginatedGallery } from '../PaginatedGallery';
import { MediaLibraryGalleryItemDropdown } from './MediaLibraryGalleryItemDropdown';
import { useFavoritedMediaFetchService } from './services/useFavoritedMediaFetchService';
import styles from './recentlyUsedMediaSubTab.module.scss';

const emptyGalleryMessagesPrefix = 'creative.editor.favoritedLibrary';

export const FavoritedMediaSubTab = ({
    contentType,
    columnCount,
    onSelectMedia,
    selectMediaHandler: selectMediaHandlerProp,
}) => {
    const selectMediaHandler = useCallback(
        (data, selected, options = {}) => {
            selectMediaHandlerProp(data, selected, options, THUMBNAIL_SOURCE_TYPES.FAVORITED_IMAGES);
        },
        [selectMediaHandlerProp]
    );
    const dispatch = useDispatch();
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const {
        loadedMedia: loadedMediaLibraryItems,
        reload,
        loadNextPage,
        isItemLoaded,
        isModuleReady,
        paginationStatus: status,
        totalValues = 0,
    } = useFavoritedMediaFetchService({ accountId, loadParams: contentType });

    useEffect(() => {
        if (!isModuleReady || !accountId) {
            return;
        }
        dispatch(resetMediaLibraryItems());
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModuleReady, accountId]);

    return (
        <div className={styles['image-gallery-container']}>
            <PaginatedGallery
                accountId={accountId}
                items={loadedMediaLibraryItems}
                itemOnClick={selectMediaHandler}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadNextPage}
                totalItemCount={Math.min(totalValues, 180)}
                scrollbarWidth={11}
                status={status}
                urlTransformer={modifyExternalUrl}
                emptyGalleryType={emptyGalleryTypes.FAVORITED_LIBRARY_EMPTY}
                emptyGalleryMessagesPrefix={emptyGalleryMessagesPrefix}
                columnCount={columnCount}
                onSelectMedia={onSelectMedia}
                GalleryItemDropdown={MediaLibraryGalleryItemDropdown}
            />
        </div>
    );
};
