import React from 'react';
import { filter, flatten, get, values } from 'lodash';
import PropTypes from 'prop-types';
import { Gallery } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import {
    validationFunctions,
    withContainerValidations,
} from '../../../../../../taboola-common-frontend-modules/validations';
import CreativePreviewGalleryItem from '../CreativePreviewGalleryItem/CreativePreviewGalleryItem';
import EmptyPreviewWidgetGallery from '../EmptyPreviewWidgetGallery/EmptyPreviewWidgetGallery';
import styles from './previewWidgetsGallery.module.scss';

const mediaComparator = (a, b) => (a.thumbnailUrl < b.thumbnailUrl ? -1 : 1);
const titleComparator = (a, b) => (a.title < b.title ? -1 : 1);
const widgetComparator = (a, b) => (a.thumbnailUrl === b.thumbnailUrl ? titleComparator(a, b) : mediaComparator(a, b));

class PreviewWidgetsGallery extends React.Component {
    getWidgetValues = (titleUrl, previewWidgets) => values(previewWidgets[titleUrl]).sort(widgetComparator);

    render() {
        const { previewWidgets, gap, minRowHeight, className, columns, onToggleStatus, selectedCampaigns } = this.props;
        const urlTitles = Object.keys(previewWidgets);
        const brandingText = get(selectedCampaigns, '0.brandingText', '');

        return urlTitles.length ? (
            urlTitles.map(titleUrl => {
                const widgets = this.getWidgetValues(titleUrl, previewWidgets);
                if (!widgets.length) {
                    return null;
                }

                return (
                    <div key={`previewGallery-${titleUrl}`}>
                        <div className={styles['url-title']}>{titleUrl}</div>
                        <Gallery gap={gap} minRowHeight={minRowHeight} className={className} columns={columns}>
                            {widgets.map(previewWidget => (
                                <CreativePreviewGalleryItem
                                    key={`previewThumbnail-${previewWidget.id}`}
                                    previewWidget={previewWidget}
                                    onToggleStatus={onToggleStatus}
                                    brandingText={brandingText}
                                />
                            ))}
                        </Gallery>
                    </div>
                );
            })
        ) : (
            <EmptyPreviewWidgetGallery />
        );
    }
}

PreviewWidgetsGallery.propTypes = {
    previewWidgets: PropTypes.object,
    gap: PropTypes.string,
    minRowHeight: PropTypes.string,
    className: PropTypes.string,
    onToggleStatus: PropTypes.func,
    columns: PropTypes.number,
    id: PropTypes.string,
    selectedCampaigns: PropTypes.array,
};

PreviewWidgetsGallery.defaultProps = {
    previewWidgets: {},
    onToggleStatus: () => {},
    className: '',
    columns: 3,
    selectedCampaigns: [{ brandingText: '' }],
};

const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.preview.empty',
        defaultMessage: 'Select at least one variation',
    },
];

export { PreviewWidgetsGallery as PreviewWidgetsGalleryUnwrapped };

export default withContainerValidations(withIndication(PreviewWidgetsGallery), validations, 'previewWidgets', items =>
    filter(flatten(values(items)), item => !item.deleted)
);
