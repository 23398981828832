import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import ThumbnailGalleryEmptySVGIcon from './ThumbnailGalleryEmptySVGIcon';
import styles from './EmptyThumbnailGallery.module.scss';

const EmptyThumbnailGallery = ({ icon, description }) => (
    <div className={styles['container']}>
        {icon}
        <div className={styles['description']}>{description}</div>
    </div>
);

EmptyThumbnailGallery.propTypes = {
    icon: PropTypes.node,
    description: PropTypes.node,
};

EmptyThumbnailGallery.defaultProps = {
    icon: <ThumbnailGalleryEmptySVGIcon />,
    description: (
        <FormattedMessage
            id="creative.thumbnail.description.empty"
            defaultMessage="Upload your media, or drag files here"
        />
    ),
};

export default EmptyThumbnailGallery;
