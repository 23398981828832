import React from 'react';
import { TourOutlinedIcon } from 'tuui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './primaryConversion.module.scss';

export const PrimaryConversion = () => (
    <div className={styles['primary-text']}>
        <FormattedMessage id="tracking.funnel.conversion.primary.conversion" defaultMessage="PRIMARY CONVERSION" />
        <TourOutlinedIcon />
    </div>
);
