import React from 'react';
import classnames from 'classnames/bind';
import { ExternalLink } from 'components';
import styles from './urlHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const UrlHeader = ({ url, firstHeader }) => {
    return (
        <ExternalLink className={classNameBuilder('url-header', { 'first-url-header': firstHeader })} href={url}>
            {url}
        </ExternalLink>
    );
};

export default UrlHeader;
