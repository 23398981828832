import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveAs as saveAsFile } from 'file-saver';
import PropTypes from 'prop-types';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useSelectedAccount } from '../../../../../../hooks';
import { useBillingApi } from '../../../../../../services/api';
import { errorMessagesUtils } from '../../../../../../services/utils';
import ActionsCellRenderer from './ActionsCellRenderer';

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="billingAndPayments.invoiceReport.actions.downloadInvoice.error.highlight"
            defaultMessage="Unable to download invoice."
        />
    ),
});

export const InvoicesActionsCellRenderer = ({ data, context, ...rest }) => {
    const dispatch = useDispatch();
    const [{ accountName: selectedAccountName }] = useSelectedAccount();
    const { getInvoice } = useBillingApi();
    const { invoiceType, invoiceName, invoiceId } = data;
    const [isLoading, setIsLoading] = useState(false);
    const buttonConfigs = [
        {
            onClick: async () => {
                try {
                    setIsLoading(true);
                    const response = await getInvoice({
                        accountId: selectedAccountName,
                        invoiceType,
                        invoiceId,
                    });
                    const blob = await response.blob();
                    saveAsFile(blob, `${invoiceName}.pdf`);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    dispatch(addIndication(generateErrorIndication(error)));
                }
            },
            dataMetricComponent: 'DownloadInvoiceActionButton',
            iconLabel: 'download',
            tooltipContent: (
                <FormattedMessage
                    id="billingAndPayments.invoiceReport.actions.downloadInvoice.tooltip"
                    defaultMessage="Download invoice"
                />
            ),
            isLoading,
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

InvoicesActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    ...ActionsCellRenderer.propTypes,
};
