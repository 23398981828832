import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './adSetupUrlConditionPredicateOption.module.scss';

export const AdSetupUrlConditionPredicateOption = ({ data: { value } }) => {
    const { formatMessage } = useIntl();
    const label = formatMessage({ id: `creative.creator.ad.setup.app.url.dropdown.${value}`, defaultMessage: '' });

    return (
        <div className={styles['container']}>
            <span className={styles['label']}>{label}</span>
        </div>
    );
};

AdSetupUrlConditionPredicateOption.propTypes = {
    data: PropTypes.object,
};
