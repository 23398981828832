import React, { useMemo, useRef } from 'react';
import classnames from 'classnames/bind';
import { omit } from 'lodash';
import { TaboolaDataGrid } from 'taboola-ultimate-ui';
import { REPORT_ID } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { InternalLink } from '../../../../components';
import { BLACK_LIST } from '../../../campaigns/modules/campaigns-reports/components/ReportDataGrid/ReportDataGrid';
import { columnsTypesMap, hiddenColumnType } from '../../../campaigns/modules/campaigns-reports/config';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import styles from './basePreviewRecent.module.scss';

const classNameBuilder = classnames.bind(styles);
const emptyStateData = {
    header: <FormattedMessage id="billingAndPayments.basePreviewRecent.datagrid.overlay.empty.header" />,
    content: <FormattedMessage id="billingAndPayments.basePreviewRecent.datagrid.overlay.empty.content" />,
    className: styles['empty-state-data'],
};

export const BasePreviewRecent = ({
    className,
    titleMsgId,
    linkMsgId,
    linkPath,
    reportId,
    reportData,
    isLoadingReport,
    columnDefinitions,
}) => {
    const containerRef = useRef();
    const intl = useIntl();
    const { currency } = useAccount();
    const context = useMemo(() => {
        return { intl, currency };
    }, [intl, currency]);

    const columnDefinitionsWithoutHidden = useMemo(() => {
        return columnDefinitions
            .filter(columnDef => !(columnDef.type ?? []).includes(hiddenColumnType))
            .map(columnDef => omit(columnDef, BLACK_LIST));
    }, [columnDefinitions]);
    const slicedData = useMemo(() => reportData.slice(0, 4), [reportData]);

    return (
        <div className={classNameBuilder('container', className)}>
            <div className={styles['title']}>
                <FormattedMessage id={titleMsgId} />
            </div>
            <div className={styles['report-container']} ref={containerRef}>
                <TaboolaDataGrid
                    context={context}
                    reportData={slicedData}
                    columnDefs={columnDefinitionsWithoutHidden}
                    stickyHeader
                    withPagination={false}
                    rowsLoading={isLoadingReport}
                    emptyStateData={emptyStateData}
                    columnTypes={columnsTypesMap}
                />
            </div>
            <InternalLink path={linkPath} params={{ [REPORT_ID]: reportId }} className={styles['link-msg']}>
                <FormattedMessage id={linkMsgId} />
            </InternalLink>
        </div>
    );
};
