import { useCallback, useMemo } from 'react';
import uuid from 'uuid/v1';
import {
    FEATURE_FLAGS,
    useAccountConfig,
    useConfigMatch,
} from '../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../taboola-common-frontend-modules/formData';

export const useExternalMetadata = () => {
    const { value: externalMetadata = [], onChange } = useFormFieldValue({
        field: 'externalMetadata.data',
    });

    const onRemove = useCallback(
        itemToRemove => {
            onChange(externalMetadata.filter(item => item.key !== itemToRemove.key));
        },
        [externalMetadata, onChange]
    );

    const isRemovable = useConfigMatch({ [FEATURE_FLAGS.EXTERNAL_DATA_DELETION_DISABLED]: 'false' });

    const { [FEATURE_FLAGS.EXTERNAL_DATA_READONLY_FIELDS]: externalDataReadonlyFields } = useAccountConfig(
        FEATURE_FLAGS.EXTERNAL_DATA_READONLY_FIELDS
    );

    const externalMetadataWithIds = useMemo(
        () =>
            externalMetadata.map(item => ({
                ...item,
                id: item.id ?? item.key,
                isRemovable: item.isNew || isRemovable,
                isEditable: item.isNew || !externalDataReadonlyFields.includes(item.key),
            })),
        [externalDataReadonlyFields, externalMetadata, isRemovable]
    );

    const onAdd = useCallback(
        async item => {
            onChange([{ ...item, id: uuid(), isNew: true }, ...externalMetadata]);
        },
        [externalMetadata, onChange]
    );
    return { externalMetadata: externalMetadataWithIds, onAdd, onRemove, onChange };
};
