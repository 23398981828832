import React from 'react';
import { keyBy } from 'lodash';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../reportsBaseConfig';

const AVAILABLE_BROWSER_OS_REPORT_START_DATE = Date.parse('2021-01-01');
const reportsWithPartialData = keyBy([REPORT_TYPE.BROWSER, REPORT_TYPE.OS_FAMILY, REPORT_TYPE.OS_VERSION]);

const getPartialReportDataIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA,
    type: INDICATION_TYPES.ERROR,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.partialReportData.highlight"
            defaultMessage="Data is partially available"
        />
    ),
    message: (
        <FormattedMessage
            defaultMessage="- The data for the chosen date is displayed partially - please choose a start date after 01-01-2021 to see a full report."
            id="app.campaign.indication.partialReportData.message"
        />
    ),
});

export { getPartialReportDataIndication, AVAILABLE_BROWSER_OS_REPORT_START_DATE, reportsWithPartialData };
