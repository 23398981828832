import React, { useCallback, useState } from 'react';
import { isEmpty, noop } from 'lodash';
import { Modal as ExternalModal, useEnterHandler, useEscapeHandler } from 'taboola-ultimate-ui';
import { useModal } from '../../hooks/useModal';
import { ModalFormLayout } from '../ModalFormLayout/ModalFormLayout';

const closeButtonProps = { 'aria-label': 'Close modal button' };

export const Modal = () => {
    const { data, close: closeModal } = useModal();
    const isOpen = !isEmpty(data);
    const {
        title = '',
        content = '',
        className,
        headerClassName,
        hasCloseButton = true,
        formProps,
        contentRenderer: ContentRenderer,
        initialData,
        shouldOverlayClose,
        contentClassName,
        onClose = noop,
    } = data;
    const [isLocked, setIsLocked] = useState(false);

    const close = useCallback(
        passedData => {
            onClose(passedData);

            if (isLocked) {
                return;
            }
            closeModal(passedData);
        },
        [closeModal, isLocked, onClose]
    );
    const closeCanceled = useCallback(() => close(false), [close]);
    const closeConfirmed = useCallback(() => close(true), [close]);

    const shouldBindHotKeys = isOpen && !ContentRenderer;
    useEscapeHandler(shouldBindHotKeys ? closeCanceled : null);
    useEnterHandler(shouldBindHotKeys ? closeConfirmed : null);

    if (!isOpen) {
        return null;
    }

    const modalContent = ContentRenderer ? (
        <ContentRenderer onClose={close} isLocked={isLocked} setIsLocked={setIsLocked} initialData={initialData} />
    ) : (
        <ModalFormLayout onCancel={closeCanceled} onSubmit={closeConfirmed} {...formProps}>
            {content}
        </ModalFormLayout>
    );

    return (
        <ExternalModal
            isOpen
            title={title}
            onClose={closeCanceled}
            className={className}
            headerClassName={headerClassName}
            hideCloseButton={!hasCloseButton || isLocked}
            closeButtonProps={closeButtonProps}
            shouldOverlayClose={shouldOverlayClose}
            contentClassName={contentClassName}
        >
            {modalContent}
        </ExternalModal>
    );
};
