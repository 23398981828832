import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { updateAudience } from '../../../../../../audiences/flows';
import {
    AudienceActionsCellRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    withCampaignEditIndication,
} from '../../../components';
import {
    staticColumn,
    leftPinnedColumn,
    actionsColumnType,
    rightPinnedColumn,
    numericColumn,
    hiddenColumnType,
} from '../../columns';
import * as FIELDS from '../../fields';

const campaignReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.ID.label,
            field: FIELDS.ID.field,
            type: [hiddenColumnType, numericColumn],
        },
        {
            headerName: FIELDS.AUDIENCE_NAME.label,
            field: FIELDS.AUDIENCE_NAME.field,
            type: [leftPinnedColumn],
            cellRendererFramework: FIELDS.AUDIENCE_NAME.cellRenderer,
        },
        {
            headerName: FIELDS.ACCOUNT_ID.label,
            field: FIELDS.ACCOUNT_ID.field,
        },
        {
            headerName: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.field,
            field: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.field,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.headerComponentParams,
            cellRendererFramework: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.cellRendererFramework,
            cellRendererParams: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.cellRendererParams,
        },
        {
            headerName: FIELDS.AUDIENCE_TYPE.label,
            field: FIELDS.AUDIENCE_TYPE.field,
            headerComponentParams: FIELDS.AUDIENCE_TYPE.headerComponentParams,
        },
        {
            headerName: FIELDS.AUDIENCE_SOURCE.label,
            field: FIELDS.AUDIENCE_SOURCE.field,
            headerComponentParams: FIELDS.AUDIENCE_SOURCE.headerComponentParams,
        },
        {
            headerName: FIELDS.AUDIENCE_STATUS.label,
            field: FIELDS.AUDIENCE_STATUS.field,
            cellRendererParams: FIELDS.AUDIENCE_STATUS.cellRendererParams,
            cellRendererFramework: FIELDS.AUDIENCE_STATUS.cellRendererFramework,
            csvValueGetter: FIELDS.AUDIENCE_STATUS.csvValueGetter,
            headerComponentParams: FIELDS.AUDIENCE_STATUS.headerComponentParams,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        },
        {
            headerName: FIELDS.AUDIENCE_CAMPAIGN_USING.label,
            field: FIELDS.AUDIENCE_CAMPAIGN_USING.field,
            type: [numericColumn],
            cellRendererFramework: FIELDS.AUDIENCE_CAMPAIGN_USING.cellRenderer,
            headerComponentParams: FIELDS.AUDIENCE_CAMPAIGN_USING.headerComponentParams,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        },
        {
            headerName: FIELDS.LAST_MODIFY_BY.label,
            field: FIELDS.LAST_MODIFY_BY.field,
            headerComponentParams: FIELDS.LAST_MODIFY_BY.headerComponentParams,
        },
        {
            headerName: FIELDS.LAST_MODIFY_ON.label,
            field: FIELDS.LAST_MODIFY_ON.field,
            valueFormatter: FIELDS.LAST_MODIFY_ON.valueFormatter,
            csvValueGetter: FIELDS.LAST_MODIFY_ON.csvValueGetter,
            headerComponentParams: FIELDS.LAST_MODIFY_ON.headerComponentParams,
        },
        {
            headerName: FIELDS.EXCLUDE_FROM_CAMPAIGNS.label,
            field: FIELDS.EXCLUDE_FROM_CAMPAIGNS.field,
        },
    ],
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererParams: {
            onDefaultExclude: updateAudience,
            onAudienceArchive: updateAudience,
            reportType: REPORT_TYPE.COMBINED_AUDIENCES,
        },
        cellRendererFramework: AudienceActionsCellRenderer,
        csv: false,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
    },
];

export default normalizeColumnDefs(
    campaignReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.COMBINED_AUDIENCES].id,
    withCampaignEditIndication
);
