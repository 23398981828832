'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _AsyncDropdown = require('../../DropdownMenus/AsyncDropdown/AsyncDropdown');

var _AsyncDropdown2 = _interopRequireDefault(_AsyncDropdown);

var _BareDropdown = require('../../DropdownMenus/BareDropdown');

var _useElementSize2 = require('../../../hooks/useElementSize');

var _useElementSize3 = _interopRequireDefault(_useElementSize2);

var _DropdownGroupSmartList = require('../DropdownGroupSmartList');

var _DropdownGroupSmartList2 = _interopRequireDefault(_DropdownGroupSmartList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'placeholderColor': 'var(--gray-6)',
    'taboola-svg-icon': 'asyncDropDownPairSmartList__taboola-svg-icon___3HZIc',
    'container': 'asyncDropDownPairSmartList__container___1tVM2',
    'disabled': 'asyncDropDownPairSmartList__disabled___3tP6t',
    'delimiter': 'asyncDropDownPairSmartList__delimiter___65L57',
    'item-wrapper': 'asyncDropDownPairSmartList__item-wrapper___1WaIr',
    'prioritized': 'asyncDropDownPairSmartList__prioritized___2wu1K',
    'more-prioritized': 'asyncDropDownPairSmartList__more-prioritized___3IztX'
};


var getMenuStyle = function getMenuStyle(width) {
    return {
        menu: function menu(provided) {
            return Object.assign({}, provided, {
                width: width,
                right: 0
            });
        }
    };
};

var classNameBuilder = _bind2.default.bind(styles);

var AsyncDropDownPairSmartList = function AsyncDropDownPairSmartList(_ref) {
    var itemType = _ref.itemType,
        shouldRenderItemTypeValue = _ref.shouldRenderItemTypeValue,
        isItemTypeDisabled = _ref.isItemTypeDisabled,
        allItemTypes = _ref.allItemTypes,
        onSelectItemType = _ref.onSelectItemType,
        itemTypeRenderer = _ref.itemTypeRenderer,
        searchable = _ref.searchable,
        onInputChange = _ref.onInputChange,
        selectedItems = _ref.selectedItems,
        onAddItem = _ref.onAddItem,
        onRemoveItem = _ref.onRemoveItem,
        optionLabelRenderer = _ref.optionLabelRenderer,
        listHeaderTitle = _ref.listHeaderTitle,
        listHeaderClassName = _ref.listHeaderClassName,
        itemPlaceholder = _ref.itemPlaceholder,
        isItemsLoading = _ref.isItemsLoading,
        renderItem = _ref.renderItem,
        loadMoreOptions = _ref.loadMoreOptions,
        hideArrow = _ref.hideArrow,
        blacklist = _ref.blacklist,
        whitelist = _ref.whitelist,
        shouldCollapseItems = _ref.shouldCollapseItems,
        collapsedItemsLimit = _ref.collapsedItemsLimit,
        error = _ref.error,
        loadingMessageRenderer = _ref.loadingMessageRenderer,
        onMenuClose = _ref.onMenuClose,
        hasMore = _ref.hasMore,
        shouldStretchItemMenu = _ref.shouldStretchItemMenu,
        rest = _objectWithoutProperties(_ref, ['itemType', 'shouldRenderItemTypeValue', 'isItemTypeDisabled', 'allItemTypes', 'onSelectItemType', 'itemTypeRenderer', 'searchable', 'onInputChange', 'selectedItems', 'onAddItem', 'onRemoveItem', 'optionLabelRenderer', 'listHeaderTitle', 'listHeaderClassName', 'itemPlaceholder', 'isItemsLoading', 'renderItem', 'loadMoreOptions', 'hideArrow', 'blacklist', 'whitelist', 'shouldCollapseItems', 'collapsedItemsLimit', 'error', 'loadingMessageRenderer', 'onMenuClose', 'hasMore', 'shouldStretchItemMenu']);

    var _useState = (0, _react.useState)(''),
        _useState2 = _slicedToArray(_useState, 2),
        input = _useState2[0],
        setInput = _useState2[1];

    var _useState3 = (0, _react.useState)(1),
        _useState4 = _slicedToArray(_useState3, 2),
        page = _useState4[0],
        setPage = _useState4[1];

    var _useElementSize = (0, _useElementSize3.default)(),
        width = _useElementSize.width,
        ref = _useElementSize.ref;

    var getOptions = function getOptions(i) {
        var moreOptions = loadMoreOptions(i, page);
        setPage(page + 1);
        return moreOptions;
    };
    var handleInputChange = function handleInputChange(i) {
        setPage(1);
        setInput(i);
        onInputChange(i);
    };

    var asyncDropdownStyle = Object.assign({}, (0, _BareDropdown.bareDropdownStyles)(), {
        placeholder: function placeholder(defaultStyles) {
            return Object.assign({}, defaultStyles, {
                color: styles.placeholderColor
            });
        }
    }, shouldStretchItemMenu ? getMenuStyle(width) : {});

    var resetInput = function resetInput() {
        return handleInputChange('');
    };

    var addItemAndResetInput = function addItemAndResetInput(value) {
        onAddItem(value);
        resetInput();
    };

    return _react2.default.createElement(
        _DropdownGroupSmartList2.default,
        Object.assign({
            items: selectedItems,
            renderItem: renderItem,
            addItem: addItemAndResetInput,
            deleteItem: onRemoveItem,
            shouldCollpaseItems: shouldCollapseItems,
            collapsedItemsLimit: collapsedItemsLimit,
            listHeaderTitle: listHeaderTitle,
            listHeaderClassName: listHeaderClassName
        }, rest),
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('container', { disabled: isItemTypeDisabled }), ref: ref },
            _react2.default.createElement(_BareDropdown.BareDropdown, {
                value: itemType,
                controlShouldRenderValue: shouldRenderItemTypeValue,
                options: allItemTypes,
                optionItemRenderer: itemTypeRenderer,
                onChange: onSelectItemType,
                disabled: isItemTypeDisabled,
                arrowRenderer: hideArrow ? function () {
                    return null;
                } : undefined,
                skipRightBorderRadius: true
            }),
            _react2.default.createElement('div', { className: styles['delimiter'] }),
            _react2.default.createElement(_AsyncDropdown2.default, {
                styles: asyncDropdownStyle,
                getOptions: getOptions,
                onChange: isItemsLoading ? null : addItemAndResetInput,
                placeholder: itemPlaceholder,
                selectedValueObject: {},
                hasMore: hasMore,
                inputValue: input,
                onInputChange: handleInputChange,
                loadingMessageRenderer: loadingMessageRenderer,
                optionLabelRenderer: optionLabelRenderer,
                error: error,
                backspaceRemovesValue: false,
                controlShouldRenderValue: false,
                hideSelectedOptions: false,
                blacklist: blacklist,
                whitelist: whitelist,
                onBlur: function onBlur() {
                    return resetInput();
                },
                tabSelectsValue: false,
                searchable: searchable,
                onMenuClose: onMenuClose
            })
        )
    );
};

AsyncDropDownPairSmartList.propTypes = {
    /** Selected item type */
    itemType: _propTypes2.default.string,
    /** Boolean flag to tell if item type value should be rendered */
    shouldRenderItemTypeValue: _propTypes2.default.bool,
    /** Boolean flag to tell if item type selection disabled */
    isItemTypeDisabled: _propTypes2.default.bool,
    /** Handler to select item type */
    onSelectItemType: _propTypes2.default.func.isRequired,
    /** All available item types */
    allItemTypes: _propTypes2.default.array,
    /** Item type select option renderer */
    itemTypeRenderer: _propTypes2.default.func.isRequired,
    /** whether to enable searching feature or not */
    searchable: _propTypes2.default.bool,
    /** Selected items */
    selectedItems: _propTypes2.default.array,
    /** Handler to add new item */
    onAddItem: _propTypes2.default.func.isRequired,
    /** Handler to remove new item */
    onRemoveItem: _propTypes2.default.func.isRequired,
    /** Boolean flag to tell is available list of items it being loaded */
    isItemsLoading: _propTypes2.default.bool,
    /** react-select option label renderer */
    optionLabelRenderer: _propTypes2.default.func,
    /** Title for selected items list */
    listHeaderTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Title class name for selected items list */
    listHeaderClassName: _propTypes2.default.string,
    /** Item select placeholder */
    itemPlaceholder: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** Item list line renderer */
    renderItem: _propTypes2.default.node,
    /** Callback that returns a promise resolving with the next set of data */
    loadMoreOptions: _propTypes2.default.func,
    /** Error object */
    error: _propTypes2.default.bool,
    /** bool to hide dropdown arrow of 1st dropdown */
    hideArrow: _propTypes2.default.bool,
    /** list of value that should never show up */
    blacklist: _propTypes2.default.array,
    /** list of value that should only show up */
    whitelist: _propTypes2.default.array,
    /** Whether to hide items or not */
    shouldCollapseItems: _propTypes2.default.bool,
    /** Number of items to display before hiding */
    collapsedItemsLimit: _propTypes2.default.number,
    /** onInputChange handler: function(inputValue) {} */
    onInputChange: _propTypes2.default.func,
    /** function which returns a custom way to render the loading component */
    loadingMessageRenderer: _propTypes2.default.func,
    /** Callback invoked when menu is closed */
    onMenuClose: _propTypes2.default.func,
    /** is there more data */
    hasMore: _propTypes2.default.bool,
    /** Should stretch item menu */
    shouldStretchItemMenu: _propTypes2.default.bool
};

AsyncDropDownPairSmartList.defaultProps = {
    searchable: false,
    shouldRenderItemTypeValue: true,
    isItemTypeDisabled: false,
    isItemsLoading: false,
    allItemTypes: [],
    selectedItems: [],
    error: false,
    onInputChange: function onInputChange() {},
    onMenuClose: function onMenuClose() {},
    shouldStretchItemMenu: true
};

exports.default = AsyncDropDownPairSmartList;