import React from 'react';
import { AudienceSectionIcon } from 'taboola-ultimate-ui';
import { AudienceTargetingFirstCampaignIndication } from '../AudienceTargetingSectionLegacy/AudienceTargetingFirstCampaignIndication';
import MarketplaceAudiencesField from '../MarketplaceAudiences/MarketplaceAudiencesField';
import { MyAudiencesField } from '../MyAudiencesField/MyAudiencesField';
import { AudienceTargetingTooltip } from './AudienceTargetingTooltip';
import { SegmentsTargetingSection } from './SegmentsTargetingSection';

export const AudienceTargetingSection = () => {
    return (
        <SegmentsTargetingSection
            sectionId="CAMPAIGN_AUDIENCE_TARGETING"
            titleMessageId="campaign.editor.audience.targeting"
            descriptionMessageId="campaign.editor.audience.targeting.description"
            isShowTooltip
            tooltip={<AudienceTargetingTooltip />}
            headerIcon={<AudienceSectionIcon />}
            indication={<AudienceTargetingFirstCampaignIndication />}
        >
            <MarketplaceAudiencesField />
            <MyAudiencesField />
        </SegmentsTargetingSection>
    );
};
