import { isEmpty, isNil, uniqBy } from 'lodash';
import { SORTING_ORDER_TYPES, TaboolaGraph } from 'taboola-ultimate-ui';
import { BarGraphIcon, LineGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import CampaignGraphTooltip from '../../../components/Tooltips/CampainGraphTooltip';
import { DEFAULT_GRAPH_PALETTE } from '../../../services/dynamicColumnsService';
import { getDataWithFullPeriodScaleSingleMetric } from '../../../transformers/getDataWithFullPeriodScaleSingleMetric';
import * as FIELDS from '../../fields';
import { DATE } from '../../fields';
import { dayWeekMonthXAxisMinTickGap, hourXAxisFormatterUTC, tooltipHourFormatterUTC } from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-realtime-report-by-platform',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.CTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.VCPM,
        metrics.CPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKeyWithIntl:
            intl =>
            ({ [FIELDS.PLATFORM_CODE.field]: platformCode }) =>
                intl.formatMessage({
                    id: `${FIELDS.PLATFORM_CODE.renderParams.msgIdPrefix}.${platformCode}`,
                    defaultMessage: platformCode,
                }),
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

const singleMetricGraph = {
    type: GRAPHS.SINGLE_METRIC_LINE,
    graphIdField: DATE.field,
    endpoint: 'campaign-realtime-report-by-platform-time-bucket',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 10,
    collatingKey: 'platformCode',
    skipSyncWithGrid: true,
    emptyMetricsMsgIdOverride: 'metrics.empty.comparePlatforms',
    numLegendValues: 10,
    getGridDataDependentFilters: reportData => {
        if (!isEmpty(reportData)) {
            return {
                platforms: reportData.slice(0, 10).map(({ platformCode }) => platformCode),
            };
        }
    },
    defaultSort: [{ field: 'date', order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: FIELDS.DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: hourXAxisFormatterUTC,
        toolTipLabelFormatter: tooltipHourFormatterUTC,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
        isSingleYAxisDomain: true,
    },
    getMetrics: ({ graphData: { results } }) => {
        const sanitizedResults = uniqBy(results, 'platformCode').filter(({ platformCode }) => !isNil(platformCode));
        const colors = DEFAULT_GRAPH_PALETTE;
        return sanitizedResults.map((dataPoint, index) => ({
            name: String(dataPoint.platformCode),
            label: dataPoint.platformName,
            color: colors[index],
            active: index < 2,
        }));
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: LineGraphIcon,
    dataViewTransformer: getDataWithFullPeriodScaleSingleMetric,
};

export const graphConfigs = [singleMetricGraph, graph];
