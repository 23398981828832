import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import styles from './svgButton.module.scss';

const classNameBuilder = classnames.bind(styles);

const SvgButton = ({ children, getIsActive, name, isDisabled, onClick, getDataMetrics, ...rest }) => {
    const clickHandler = useCallback(() => !isDisabled && onClick(name), [onClick, isDisabled, name]);
    const isActive = useMemo(() => getIsActive(name), [getIsActive, name]);

    return (
        <StyledButton
            className={classNameBuilder('button', { disabled: isDisabled })}
            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
            name={name}
            aria-label={`${isActive ? 'Active' : ''} ${name}`}
            disabled={isDisabled}
            onClick={clickHandler}
            {...getDataMetrics(name)}
            {...rest}
        >
            <svg
                data-id={name}
                className={classNameBuilder({ active: isActive })}
                width="36"
                height="34"
                viewBox="0 0 36 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="0.5" y="0.5" width="35" height="33" rx="4.5" fill="white" />
                <path
                    className={styles['color-override']}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.23067 5C5.61533 5 4 6.5 4 8L4.0009 17C4.0009 18.5 5.61623 20 7.23156 20H28.7693C30.3838 20 32 18.5 32 17V8C32 6.5 30.3838 5 28.7684 5L7.23067 5Z"
                    fill="#9CA8B5"
                />
                <path
                    className={styles['color-override']}
                    d="M5.07779 23C5.07779 22.4477 5.55993 22 6.15468 22H28.7693C29.3641 22 29.8462 22.4477 29.8462 23C29.8462 23.5523 29.3641 24 28.7693 24H6.15468C5.55993 24 5.07779 23.5523 5.07779 23Z"
                    fill="#9CA8B5"
                />
                <path
                    className={styles['color-override']}
                    d="M5.07779 27C5.07779 26.4477 5.55993 26 6.15468 26H23.3849C23.9796 26 24.4618 26.4477 24.4618 27C24.4618 27.5523 23.9796 28 23.3849 28H6.15468C5.55993 28 5.07779 27.5523 5.07779 27Z"
                    fill="#9CA8B5"
                />
                {children}
                <rect x="0.5" y="0.5" width="35" height="33" rx="4.5" stroke="#B8C1CA" />
            </svg>
        </StyledButton>
    );
};

SvgButton.propTypes = {
    getIsActive: PropTypes.func,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    getDataMetrics: PropTypes.func,
    ...StyledButton.propTypes,
};

SvgButton.defaultProps = {
    getIsActive: noop,
    getDataMetrics: noop,
};

export default SvgButton;
