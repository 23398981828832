import { GALLERY_ITEM_DROPDOWN_OPTIONS } from '../GalleryItemDropdown/GalleryItemDropdownOptions';

export const CREATIVE_STUDIO_EVENT_PREFIX = 'Creative_Studio';

export const MEDIA_LIBRARY_EVENT_PREFIX = 'Media_Library';
export const GENERATIVE_AI_TAB_PREFIX = 'Generative_AI_Tab';

export const CREATIVE_STUDIO_MODE_EVENTS_MAP = {
    [GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS]: 'Image_Variations',
    [GALLERY_ITEM_DROPDOWN_OPTIONS.REPLACE_BACKGROUND]: 'Replace_Background',
    [GALLERY_ITEM_DROPDOWN_OPTIONS.EDIT_FALLBACK_IMAGE]: 'Edit_Fallback_Image',
};

export const SEGMENT_ANYTHING_EVENT_PREFIX = 'Segment_Anything';
