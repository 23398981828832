import { NameEdit } from '../components/NameEdit/NameEdit';
import { creativeNameValidations, customIDValidations } from '../components/NameSetup/validations';
import { useCustomDataSection } from './useCustomDataSection';
import styles from './customDataSection.module.scss';

export const CustomDataSection = ({ contentMethod }) => {
    const isCustomDataSectionSupported = useCustomDataSection(contentMethod);

    if (!isCustomDataSectionSupported) {
        return null;
    }

    return (
        <>
            <div className={styles['name-edit-container']} name="ad-name">
                <NameEdit
                    field="creativeName"
                    messageIdPrefix="creative.creator.creation.creative"
                    validations={creativeNameValidations}
                    defaultLabel="Ad Name (Optional)"
                    inputId="ad-name"
                />
            </div>
            <div className={styles['name-edit-container']} name="custom-id">
                <NameEdit
                    field="customId"
                    messageIdPrefix="creative.creator.creation.custom"
                    validations={customIDValidations}
                    defaultLabel="Custom ID (Optional)"
                    inputId="custom-id"
                />
            </div>
        </>
    );
};
