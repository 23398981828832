import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { chain, map } from 'lodash';
import { useOnClickOutside } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { NewNotificationsIndicator } from './NewNotificationsIndicator/NewNotificationsIndicator';
import { NotificationCardsBySubType } from './config/NotificationCardsBySubType';
import { NOTIFICATION_STATE } from './config/NotificationState';
import { useNotificationsActions } from './hooks/useNotificationsActions';
import { useNotificationsService } from './hooks/useNotificationsService';
import styles from './notificationsCenter.module.scss';

export const NotificationsCenter = () => {
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const { notifications = [], refetch } = useNotificationsService();

    const closeNotificationsCenter = useCallback(() => {
        if (!open) {
            return;
        }
        setOpen(false);
        refetch();
    }, [open, refetch]);

    useOnClickOutside(ref, closeNotificationsCenter);

    const [{ accountName }] = useSelectedAccount();

    const newNotificationsCount = useMemo(
        () => chain(notifications).filter({ state: NOTIFICATION_STATE.NONE }).size().value(),
        [notifications]
    );

    const { onView } = useNotificationsActions({
        viewParams: {
            errorCode: 'app.notificationsCenter.view.error-message',
            notificationIds: map(notifications, 'id'),
            accountName,
        },
    });

    const hasNotifications = notifications.length > 0;

    const toggleNotificationsCenter = useCallback(() => {
        setOpen(open => !open);
    }, [setOpen]);

    useEffect(() => {
        if (open && hasNotifications) {
            onView();
        }
    }, [open, onView, hasNotifications]);

    return (
        <div ref={ref} aria-label="Notifications Center" className={styles['notifications-center']}>
            <NewNotificationsIndicator
                newNotificationsCount={newNotificationsCount}
                open={open}
                toggleNotificationsCenter={toggleNotificationsCenter}
            />
            {open && (
                <>
                    <div className={styles['notification-center-header']}>
                        <FormattedMessage id="app.notificationsCenter.header" defaultMessage="Notifications" />
                    </div>
                    <div className={styles['notifications-center-dropdown']}>
                        {!hasNotifications && (
                            <div className={styles['empty-notifications-state']}>
                                <FormattedMessage
                                    id="app.notificationsCenter.emptyNotifications"
                                    defaultMessage="No Notifications yet"
                                />
                            </div>
                        )}
                        {notifications.map(notification => {
                            const NotificationCard = NotificationCardsBySubType[notification.subType];

                            const timeDiffInMinutes = (Date.now() - notification.createTimeInUtc) / 1000 / 60;

                            return (
                                <NotificationCard
                                    key={notification.id}
                                    notification={notification}
                                    timeDiffInMinutes={timeDiffInMinutes}
                                    closeNotificationCenter={closeNotificationsCenter}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

NotificationsCenter.propTypes = {};
