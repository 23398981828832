export const isEmailNotificationActiveValidations = [
    {
        validationFn: (value, options, { scheduledReportIds, createNewScheduledReport }) => {
            const isEmailNotificationActive = value === 'true';

            if (!isEmailNotificationActive) {
                return true;
            }
            return scheduledReportIds !== null || createNewScheduledReport;
        },
        messageId: 'app.performance.rules.setup.email.notifications.one.option.selected.validation',
        defaultMessage: 'Please select at least one option',
    },
];
