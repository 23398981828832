"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgMobileSimple(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 33,
            viewBox: "0 0 32 33",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M17 23.5C17 24.0523 16.5523 24.5 16 24.5C15.4477 24.5 15 24.0523 15 23.5C15 22.9477 15.4477 22.5 16 22.5C16.5523 22.5 17 22.9477 17 23.5Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M14 8C13.7239 8 13.5 8.22386 13.5 8.5C13.5 8.77614 13.7239 9 14 9H18C18.2761 9 18.5 8.77614 18.5 8.5C18.5 8.22386 18.2761 8 18 8H14Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M12 5.5C10.3431 5.5 9 6.84315 9 8.5V24.5C9 26.1569 10.3431 27.5 12 27.5H20C21.6569 27.5 23 26.1569 23 24.5V8.5C23 6.84315 21.6569 5.5 20 5.5H12ZM20 6.5H12C10.8954 6.5 10 7.39543 10 8.5V24.5C10 25.6046 10.8954 26.5 12 26.5H20C21.1046 26.5 22 25.6046 22 24.5V8.5C22 7.39543 21.1046 6.5 20 6.5Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgMobileSimple;