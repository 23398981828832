import { useMemo } from 'react';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { generateCreativeCarouselEditorPath } from '../../../../../services/utils';
import { useIsCarouselCreative } from '../../../hooks/useIsCarouselCreative';

export const useCreativeEditorFormHeader = () => {
    const isCarousel = useIsCarouselCreative();
    const { value: hierarchyRepItemId } = useFormFieldValue({ field: 'hierarchyRepItemId' });
    const { value: campaignId } = useFormFieldValue({ field: 'campaignId' });
    const {
        formAccount: { accountName },
        mode,
    } = useFormDataContext();
    const renderEditCarousel = isCarousel && mode === FORM_MODES.EDIT;
    const carouselLink = useMemo(
        () =>
            renderEditCarousel
                ? generateCreativeCarouselEditorPath({
                      accountId: accountName,
                      campaignId,
                      hierarchyRepItemId,
                  })
                : '',
        [accountName, campaignId, hierarchyRepItemId, renderEditCarousel]
    );

    return { carouselLink, renderEditCarousel, isCarousel };
};
