import { Tab as LibTab } from 'react-tabs';
import styled from 'styled-components';
import { scale } from 'tuui/lib/utils/styles';

export const Tab = styled(LibTab)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${scale('md')};
    border-bottom: ${scale('xs')} solid transparent;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.sizes.lg};
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    color: ${({ theme }) => theme.palette.secondary.text};

    &.react-tabs__tab--selected {
        color: ${({ theme }) => theme.palette.secondary.fill};
        border-color: ${({ theme }) => theme.palette.secondary.fill};
    }
`;

Tab.tabsRole = 'Tab';