import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { SPENT } from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import graph from './graph';
import { customColumns, performanceColumns, setupColumns, defaultColumns } from './reportPresets';

export const dcoByItemReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.DCO_ITEM_PERFORMANCE],
    endpoint: 'dco-by-item-performance',
    graph,
    rowIdField: FIELDS.ITEM_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    isEmptyFooter: true,
    sortMap: {
        [SPENT.field]: SPENT.field,
    },
    searchPlaceholderMsgId: 'app.reports.dcoByItem.search.placeholder',
    searchField: FIELDS.ITEM_ID.field,
    filters: [],
    initialFilters: [],
    rowHeight: 62,
    comfortableRowHeight: 112,
};

export default dcoByItemReportConfig;
