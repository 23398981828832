import { ExtendedComponent } from 'tuui/lib/utils/typing';
import { ProgressStepper as Base, ProgressStepperProps } from './ProgressStepper';

export * from './Step/Step';
export type { ActiveStepType } from './Step/stepTypes';
export type { ProgressStepperProps } from './ProgressStepper';

export const ProgressStepper: ExtendedComponent<
    typeof Base,
    Pick<ProgressStepperProps, 'activeStepType' | 'variant'>
> = Object.assign(Base, {
    activeStepType: { regular: 'regular', warn: 'warn', error: 'error' },
    variant: { dark: 'dark', light: 'light' },
} as const);
