import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';

const reportId = reportsBaseConfig[REPORT_TYPE.CAMPAIGNS_GROUPS].id;

const defaultColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.CAMPAIGNS_GROUP_STATUS.field,
    FIELDS.CAMPAIGNS_GROUP_NAME.field,
    FIELDS.CAMPAIGNS_GROUP_ID.field,
    FIELDS.CAMPAIGNS_GROUP_LINKED_CAMPAIGNS.field,
    FIELDS.CAMPAIGNS_GROUP_BUDGET.field,
    FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.field,
    FIELDS.CAMPAIGN_GROUP_SPENT.field,
    FIELDS.BID_STRATEGY.field,
    FIELDS.BID.field,
    FIELDS.CPA_GOAL.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
    FIELDS.SAFETY_LEVEL.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.CAMPAIGNS_GROUP_NAME.field,
    FIELDS.ID.field,
    FIELDS.CAMPAIGN_GROUP_SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
    FIELDS.CONVERSIONS_VALUE.field,
    FIELDS.ROAS.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const setupColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.CAMPAIGNS_GROUP_ID.field,
    FIELDS.ACCOUNT_ID.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.CAMPAIGNS_GROUP_NAME.field,
    FIELDS.CAMPAIGNS_GROUP_STATUS.field,
    FIELDS.CAMPAIGNS_GROUP_BUDGET.field,
    FIELDS.CAMPAIGN_GROUP_SPENT.field,
    FIELDS.CPA_GOAL.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { defaultColumns, performanceColumns, setupColumns };
