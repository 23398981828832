import { useHotkeys } from 'react-hotkeys-hook';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import copyToClipboard from '../modules/campaigns/services/utils/clipboard';
import { config as logRocketConfig } from '../modules/taboola-common-frontend-modules/log-rocket/';
import { useCurrentValueGetter } from './index';

export const useLogRocketHotKey = () => {
    const isPermitted = usePermissions('LOG_ROCKET_SESSION_URL_PERMISSION');
    const isPermittedGetter = useCurrentValueGetter(isPermitted);
    const { open } = useModal();

    useHotkeys('ctrl+l+r', () => {
        if (!isPermittedGetter()) {
            open({
                title: 'You are not allow to copy log rocket url.',
                formProps: { submitButtonText: 'Ok', hasCancel: false },
            });
            return;
        }
        const sessionUrl = logRocketConfig.sessionUrl;
        open({
            title: sessionUrl
                ? `Log rocket session url was copied to you clipboard - ${sessionUrl}`
                : 'Log rocket session url is empty.',
            formProps: { submitButtonText: 'Ok', hasCancel: false },
        });
        copyToClipboard(sessionUrl);
    });
};
