class ApiError extends Error {
    constructor({
        message,
        status,
        offendingField,
        messageCode,
        messageCodeEnglishTemplate,
        templateParameters,
        rrand,
    }) {
        super(message);
        this.status = status;
        this.offendingField = offendingField;
        this.messageCode = messageCode;
        this.messageCodeEnglishTemplate = messageCodeEnglishTemplate;
        this.templateParameters = templateParameters;
        this.name = 'ApiError';
        this.rrand = rrand;
    }
}

export default ApiError;
