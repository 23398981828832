import { useMemo } from 'react';
import { ProgressStepper } from 'tuui';
import {
    COMMON_FLAGS,
    useCommonConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import {
    PHASES,
    PHASE_TO_TOOLTIP_PROGRESS_MESSAGE,
    calculatePendingReviewProgress,
    calculateConversionsProgress,
    stepsConfigurationToSteps,
    STEPS_CONFIGURATION_TYPE,
    isLearningWithWarning,
} from '../../../services/campaignStatusPhaseService';

const getStepsConfigurationType = (phase, isCvrRelearning) => {
    if (isCvrRelearning) {
        return STEPS_CONFIGURATION_TYPE.RELEARNING;
    }

    return phase === PHASES.CVR_LEARNING_LIMITED ? STEPS_CONFIGURATION_TYPE.LIMITED : STEPS_CONFIGURATION_TYPE.DEFAULT;
};

export const useCvrLearningProgressState = ({ phase, phaseUpdateTime, totalConversions, isCvrRelearning }) => {
    const {
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS]: maxTimeInReviewInHours,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]:
            numberOfConversionsInTimeframeForCompletion,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING]: minLearningDaysBeforeWarning,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING]:
            minLearningProgressPercentageBeforeWarning,
    } = useCommonConfig([
        COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS,
        COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION,
        COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING,
        COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING,
    ]);

    const { allSteps, activeStepIndex } = useMemo(() => {
        const stepsConfigurationType = getStepsConfigurationType(phase, isCvrRelearning);
        const allSteps = stepsConfigurationToSteps[stepsConfigurationType];

        return {
            allSteps,
            activeStepIndex: allSteps.indexOf(phase),
        };
    }, [phase, isCvrRelearning]);

    const { progress, titleMessageValue } = useMemo(() => {
        if (phase === PHASES.PENDING_REVIEW) {
            const { progress } = calculatePendingReviewProgress({
                maxTimeInReviewInHours,
                phaseUpdateTime,
            });

            return {
                progress,
                titleMessageValue: {
                    value: maxTimeInReviewInHours,
                },
            };
        }

        const { currentConversions, progress } = calculateConversionsProgress({
            totalConversions,
            numberOfConversionsInTimeframeForCompletion,
        });
        return {
            progress,
            titleMessageValue: {
                current: currentConversions,
                total: numberOfConversionsInTimeframeForCompletion,
            },
        };
    }, [maxTimeInReviewInHours, numberOfConversionsInTimeframeForCompletion, phase, phaseUpdateTime, totalConversions]);

    const activeStepType = useMemo(() => {
        if (phase === PHASES.CVR_LEARNING_LIMITED) {
            return ProgressStepper.activeStepType.error;
        }

        if (phase !== PHASES.CVR_LEARNING) {
            return ProgressStepper.activeStepType.regular;
        }

        return isLearningWithWarning({
            progress,
            phaseUpdateTime,
            minLearningDaysBeforeWarning,
            minLearningProgressPercentageBeforeWarning,
        })
            ? ProgressStepper.activeStepType.warn
            : ProgressStepper.activeStepType.regular;
    }, [minLearningDaysBeforeWarning, minLearningProgressPercentageBeforeWarning, phase, phaseUpdateTime, progress]);

    const messageId = PHASE_TO_TOOLTIP_PROGRESS_MESSAGE[phase];
    const titleMessageId = messageId && `app.campaigns.campaign.status.time.left.${messageId}`;

    return {
        allSteps,
        activeStepIndex,
        progress,
        titleMessageId,
        titleMessageValue,
        activeStepType,
    };
};
