import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';

const LOCAL_STORAGE_KEY = 'acceptedGenerativeAiDisclaimer';

export const useHasAcceptedGenerativeAiDisclaimer = () => {
    const [getLocalStorage, setLocalStorage, isLocalStorageReady] = useLocalStorage();

    const getHasAcceptedGenerativeAiDisclaimer = () => isLocalStorageReady && getLocalStorage(LOCAL_STORAGE_KEY);

    const setHasAcceptedGenerativeAiDisclaimer = value => setLocalStorage(LOCAL_STORAGE_KEY, value);

    return {
        getHasAcceptedGenerativeAiDisclaimer,
        setHasAcceptedGenerativeAiDisclaimer,
    };
};
