'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Input = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _hooks = require('../../../hooks');

var _hoc = require('../../hoc');

var _inputHelpers = require('../inputHelpers');

var _inputHelpers2 = _interopRequireDefault(_inputHelpers);

var _inputTypes = require('../inputTypes');

var _inputTypes2 = _interopRequireDefault(_inputTypes);

var _Indications = require('../../Indications');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'input__taboola-svg-icon___h3Dt9',
    'input': 'input__input___3hc9r',
    'taboola-input': 'input__taboola-input___16vaF',
    'hide-arrow-buttons': 'input__hide-arrow-buttons___289-v',
    'input-wrapper': 'input__input-wrapper___1T2Sv',
    'label': 'input__label___1-kb7',
    'description': 'input__description___3GjGP',
    'error': 'input__error___1UiAa',
    'warning': 'input__warning___1O44r',
    'suggestion': 'input__suggestion___35Ahd',
    'full-width': 'input__full-width___1AgJR',
    'with-icon': 'input__with-icon___3JG-e',
    'input-icon': 'input__input-icon___3FSnt'
};


var SIZE = { LARGE: 'large' };

var Input = (0, _react.forwardRef)(function (_ref, ref) {
    var _classNames;

    var value = _ref.value,
        error = _ref.error,
        indicationType = _ref.indicationType,
        errorMessageClass = _ref.errorMessageClass,
        errorMsg = _ref.errorMsg,
        size = _ref.size,
        icon = _ref.icon,
        inputPadding = _ref.inputPadding,
        inputWrapperClass = _ref.inputWrapperClass,
        inputClass = _ref.inputClass,
        mainClass = _ref.mainClass,
        classes = _ref.classes,
        children = _ref.children,
        type = _ref.type,
        onChange = _ref.onChange,
        onWheelCapture = _ref.onWheelCapture,
        shouldHideArrowButtons = _ref.shouldHideArrowButtons,
        rest = _objectWithoutProperties(_ref, ['value', 'error', 'indicationType', 'errorMessageClass', 'errorMsg', 'size', 'icon', 'inputPadding', 'inputWrapperClass', 'inputClass', 'mainClass', 'classes', 'children', 'type', 'onChange', 'onWheelCapture', 'shouldHideArrowButtons']);

    var className = (0, _classnames2.default)(classes.main, mainClass, (_classNames = {}, _defineProperty(_classNames, classes.error, error || indicationType === _Indications.TYPES.ERROR), _defineProperty(_classNames, classes.warning, indicationType === _Indications.TYPES.WARNING), _defineProperty(_classNames, classes.suggestion, indicationType === _Indications.TYPES.SUGGESTION), _defineProperty(_classNames, classes.large, size === SIZE.LARGE), _defineProperty(_classNames, classes.withIcon, icon), _defineProperty(_classNames, classes.hideArrows, shouldHideArrowButtons), _classNames));
    var inputCls = (0, _classnames2.default)(classes.input, inputClass);
    var inputWrapperCls = (0, _classnames2.default)(classes.inputWrapper, inputWrapperClass);
    var inputStyle = {};
    var onChangeWithMask = (0, _hooks.useNumberMask)(onChange);
    var onWheelCaptureHandler = (0, _react.useCallback)(function (e) {
        e.target.blur();
        if (onWheelCapture) {
            onWheelCapture(e);
        }
    }, [onWheelCapture]);
    if (inputPadding) inputStyle.paddingLeft = inputPadding;
    if (parseInt(size, 10) > 0) inputStyle.width = size;
    var isNumberType = type === 'number';

    return _react2.default.createElement(
        'div',
        { className: className },
        _react2.default.createElement(
            'div',
            { className: inputWrapperCls },
            _react2.default.createElement('input', Object.assign({
                ref: ref
            }, rest, {
                value: (0, _inputHelpers2.default)(value),
                className: inputCls,
                style: inputStyle,
                type: type,
                onChange: isNumberType ? onChangeWithMask : onChange,
                onWheelCapture: isNumberType ? onWheelCaptureHandler : onWheelCapture
            })),
            icon && _react2.default.createElement(
                'div',
                { className: classes.icon },
                icon
            ),
            children
        )
    );
});

exports.Input = Input;
Input.classNames = {
    main: 'taboola-input',
    error: 'error',
    warning: 'warning',
    suggestion: 'suggestion',
    large: 'full-width',
    withIcon: 'with-icon',
    inputWrapper: 'input-wrapper',
    input: 'input',
    icon: 'input-icon',
    hideArrows: 'hide-arrow-buttons'
};

Input.propTypes = {
    /** Id of the input component */
    id: _propTypes2.default.string,
    /** Type of input */
    type: _propTypes2.default.oneOf(Object.values(_inputTypes2.default)),
    /** Name of input */
    name: _propTypes2.default.string,
    /** Value of input */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /** Placeholder / Hint */
    placeholder: _propTypes2.default.string,
    /** Disable the input */
    disabled: _propTypes2.default.bool,
    /** Set the size of the input in pixels, accepts the string 'large' to set full width */
    size: _propTypes2.default.oneOfType([_propTypes2.default.oneOf(Object.values(SIZE)), _propTypes2.default.number]),
    /** Error control */
    error: _propTypes2.default.bool,
    /** Error message */
    errorMsg: _propTypes2.default.node,
    /** Optional Class name added to div element */
    errorMessageClass: _propTypes2.default.string,
    /** Auto focus input */
    autoFocus: _propTypes2.default.bool,
    /** Onchange event */
    onChange: _propTypes2.default.func,
    /** OnKeyPress event */
    onKeyPress: _propTypes2.default.func,
    /** onWheelCapture event */
    onWheelCapture: _propTypes2.default.func,
    /** Optional icon element */
    icon: _propTypes2.default.node,
    /** Left padding of text - to match icon element width */
    inputPadding: _propTypes2.default.number,
    /** Class added to input wrapper div element */
    inputWrapperClass: _propTypes2.default.string,
    /** Class added to input element */
    inputClass: _propTypes2.default.string,
    /** Class added to outmost container element */
    mainClass: _propTypes2.default.string,
    /** Stylesheet for customization */
    stylesheet: _propTypes2.default.object,
    /** Flag for hiding arrow buttons */
    shouldHideArrowButtons: _propTypes2.default.bool,
    classes: _propTypes2.default.object,
    children: _propTypes2.default.node,
    /** Additional input indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES))
};

Input.defaultProps = {
    type: _inputTypes2.default.TEXT,
    onKeyPress: function onKeyPress() {},
    classes: Input.classNames,
    shouldHideArrowButtons: true
};

var InputWithStyles = (0, _hoc.withMergedStyles)(Input, styles, 'Input');

InputWithStyles.SIZE = SIZE;

exports.default = InputWithStyles;