import { useSelector } from 'react-redux';
import { CreateOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { RECOMMENDATION_SOURCE } from '../../../../recommendations/recommendationSource';
import { recommendationsSourceSelector } from '../../../../recommendations/selectors';
import { useChatAgentContext } from '../../hooks/useChatAgentContext';
import styles from './ChatAgentIndication.module.scss';

export const ChatAgentIndication = ({ children, isRecommended }) => {
    const { openChat } = useChatAgentContext();
    const recommendationSource = useSelector(recommendationsSourceSelector);

    if (!isRecommended || recommendationSource !== RECOMMENDATION_SOURCE.CHAT_AGENT) {
        return children;
    }

    return (
        <div className={styles['wrapper']}>
            <div className={styles['tag']}>
                <div className={styles['icon']}>
                    <CreateOutlinedIcon onClick={openChat} fontSize="small" />
                </div>
                <span className={styles['label']}>
                    <FormattedMessage id="app.chat.agent" defaultMessage="Abby" />
                </span>
            </div>
            <div className={styles['arrow']}>
                <div className={styles['arrow-inner']}></div>
            </div>
            {children}
        </div>
    );
};
