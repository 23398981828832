import React, { useMemo } from 'react';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { isAsianLanguage } from 'modules/taboola-common-frontend-modules/i18n';
import Graph from '../Graph';
import LongTextTick from './LongTextTick';

export const X_AXIS_HEIGHT_FOR_ASIAN_CHARACTERS = 36;

const BarGraph = ({ config, locale, ...rest }) => {
    const newConfig = useMemo(() => {
        const { graphProps, enableLongTextTickFormatting } = config || {};
        const { xAxisTick, xAxisFormatter, xAxisHeight } = graphProps;
        const newGraphProps = {};

        if (enableLongTextTickFormatting) {
            // recharts hack to ensure that all tick labels are rendered
            // in the center of their respective tick marks
            newGraphProps.xAxisFormatter = xAxisFormatter ?? (() => '');

            newGraphProps.xAxisTick = xAxisTick ?? <LongTextTick />;

            if (isAsianLanguage(locale) && isNil(xAxisHeight)) {
                newGraphProps.xAxisHeight = X_AXIS_HEIGHT_FOR_ASIAN_CHARACTERS;
            }
        }

        return { ...config, graphProps: { ...graphProps, ...newGraphProps } };
    }, [config, locale]);

    return (
        <Graph
            drawTooltipCursor
            type={GRAPHS.BAR}
            disablePreviewForSelected
            locale={locale}
            config={newConfig}
            {...rest}
        />
    );
};

BarGraph.propTypes = {
    config: PropTypes.shape({
        graphProps: PropTypes.shape({
            xAxisTick: PropTypes.func,
            xAxisFormatter: PropTypes.func,
            xAxisHeight: PropTypes.number,
        }),
        enableLongTextTickFormatting: PropTypes.bool,
    }),
    locale: PropTypes.string,
};

export default BarGraph;
