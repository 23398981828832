'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserMenu = exports.SimpleUserMenu = undefined;

var _SimpleUserMenu = require('./SimpleUserMenu/SimpleUserMenu');

var _SimpleUserMenu2 = _interopRequireDefault(_SimpleUserMenu);

var _UserMenu = require('./UserMenu/UserMenu');

var _UserMenu2 = _interopRequireDefault(_UserMenu);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.SimpleUserMenu = _SimpleUserMenu2.default;
exports.UserMenu = _UserMenu2.default;