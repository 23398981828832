import moment from 'moment-timezone';

const addLastUpdateTime = ({ rowData, lastUpdateTimeField, lastUpdateTime, timeZoneName }) => {
    if (!lastUpdateTimeField || !lastUpdateTime || !rowData[lastUpdateTimeField]) {
        return rowData;
    }

    const currRowDate = moment.utc(rowData[lastUpdateTimeField]);
    const lastUpdateDate = moment.tz(lastUpdateTime, timeZoneName).utc();

    if (!currRowDate.isSame(lastUpdateDate, 'date')) {
        return rowData;
    }

    return { ...rowData, lastUpdateTime };
};

export default addLastUpdateTime;
