import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ToggleSwitch } from 'tuui';
import { HelpTooltip } from 'taboola-ultimate-ui';
import styles from './presetToggle.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PresetToggle = ({ isChecked, onChange, isDisabled, label, helpText, children }) => (
    <div className={styles['container']}>
        <label className={classNameBuilder('clickable', { 'disabled-label': isDisabled })}>
            <ToggleSwitch checked={isChecked} onChange={onChange} className={styles['toggle']} disabled={isDisabled} />
            {label}
            {children}
        </label>
        {helpText && <HelpTooltip iconClassName={styles['toggle-help']}>{helpText}</HelpTooltip>}
    </div>
);

PresetToggle.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    label: PropTypes.node,
    helpText: PropTypes.node,
    children: PropTypes.node,
};
