import { useHistory } from 'react-router';
import { useItemsConditionConfigData } from 'hooks/useItemsConditionConfigData';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useSelectedCampaign } from '../../../../../hooks/useSelectedCampaign';
import { useReportPage, useSelectedReportId } from '../../../hooks';
import { useReportForceFetchFlag } from '../../../hooks/useReportForceFetchFlag';

export const useBulkActionData = ({ selectedRows }) => {
    const [selectedReport] = useSelectedReportId();
    const selectedAccount = useAccount();
    const { accountName } = selectedAccount;
    const history = useHistory();
    const [, forceFetchReport] = useReportForceFetchFlag();
    const [reportPage, setReportPage] = useReportPage();
    const { campaign: selectedCampaign } = useSelectedCampaign();
    const configAndPermissions = useItemsConditionConfigData();

    return {
        selectedRows,
        selectedAccount,
        accountName,
        history,
        reportPage,
        setReportPage,
        forceFetchReport,
        selectedReport,
        selectedCampaign,
        ...configAndPermissions,
    };
};
