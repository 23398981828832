import { useCallback } from 'react';
import uuid from 'uuid/v1';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { TARGETING_TYPES } from '../../CombinedTargetingTypes';

const DEFAULT_COLLECTION = [];

export const useCombinedAudienceIngredientsTargetingCollection = () => {
    const { value: combinedAudienceIngredientsTargetingCollection = DEFAULT_COLLECTION, onChange: onChangeCollection } =
        useFormFieldValue({
            field: 'audienceSetup',
        });

    const onAddTargeting = useCallback(() => {
        onChangeCollection([
            ...combinedAudienceIngredientsTargetingCollection,
            {
                relation: TARGETING_TYPES.OR,
                audiences: [],
                id: uuid(),
            },
        ]);
    }, [combinedAudienceIngredientsTargetingCollection, onChangeCollection]);

    const onDeleteTargeting = useCallback(
        indexToDelete => {
            onChangeCollection(
                combinedAudienceIngredientsTargetingCollection.filter((t, index) => indexToDelete !== index)
            );
        },
        [combinedAudienceIngredientsTargetingCollection, onChangeCollection]
    );

    const onChangeTargetingType = useCallback(
        (newType, indexToFlip) => {
            const newValue = combinedAudienceIngredientsTargetingCollection.map((targeting, index) => {
                if (indexToFlip !== index) {
                    return targeting;
                }
                return {
                    ...targeting,
                    relation: newType,
                };
            });
            onChangeCollection(newValue);
        },
        [combinedAudienceIngredientsTargetingCollection, onChangeCollection]
    );

    return {
        combinedAudienceIngredientsTargetingCollection,
        onAddTargeting,
        onDeleteTargeting,
        onChangeTargetingType,
    };
};
