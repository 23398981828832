import React from 'react';
import { INDICATION_TYPES, addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { fetchCampaign } from '../../../flows/fetchCampaign';

const indication = {
    message: (
        <FormattedMessage
            id="campaign.drawer.error"
            defaultMessage="Something went wrong when we tried to fetch the requested campaign."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

export const fetchCampaignForEdit =
    (accountId, campaignId, entityType, { hideError } = {}) =>
    async dispatch => {
        const campaign = await dispatch(fetchCampaign(accountId, campaignId, entityType, { hideError }));

        if (campaign) {
            return campaign;
        }

        if (!hideError) {
            dispatch(addIndication(indication));
        }
    };
