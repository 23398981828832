'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TIMEOUTS_BY_TYPE = exports.ERROR_TIMEOUT = exports.DEFAULT_TIMEOUT = undefined;

var _TIMEOUTS_BY_TYPE;

var _indicationTypes = require('./IndicationBase/indicationTypes');

var _indicationTypes2 = _interopRequireDefault(_indicationTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DEFAULT_TIMEOUT = exports.DEFAULT_TIMEOUT = 5000;

var ERROR_TIMEOUT = exports.ERROR_TIMEOUT = 10000;

var TIMEOUTS_BY_TYPE = exports.TIMEOUTS_BY_TYPE = (_TIMEOUTS_BY_TYPE = {}, _defineProperty(_TIMEOUTS_BY_TYPE, _indicationTypes2.default.SUCCESS, DEFAULT_TIMEOUT), _defineProperty(_TIMEOUTS_BY_TYPE, _indicationTypes2.default.ERROR, ERROR_TIMEOUT), _defineProperty(_TIMEOUTS_BY_TYPE, _indicationTypes2.default.WARNING, DEFAULT_TIMEOUT), _defineProperty(_TIMEOUTS_BY_TYPE, _indicationTypes2.default.INFO, DEFAULT_TIMEOUT), _defineProperty(_TIMEOUTS_BY_TYPE, _indicationTypes2.default.CUSTOM, DEFAULT_TIMEOUT), _TIMEOUTS_BY_TYPE);