import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedTime } from '../../../../../taboola-common-frontend-modules/i18n/index';

class FormattedTimeCellRenderer extends Component {
    render() {
        const { value } = this.props;

        return <FormattedTime value={value} />;
    }
}

FormattedTimeCellRenderer.propTypes = {
    /** value which is injected by the grid */
    value: PropTypes.instanceOf(Date),
};

export default FormattedTimeCellRenderer;
