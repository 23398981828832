import React from 'react';
import { useQuery } from 'react-query';
import classnames from 'classnames/bind';
import copy from 'copy-to-clipboard';
import { ContentCopyOutlinedIcon, CheckCircleOutlinedIcon, Button } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import CampaignDropdown from 'modules/campaigns/modules/creative-creator/components/CampaignsField/CreativeCreatorCampaignDropdown';
import { useInitialCampaign } from 'modules/campaigns/modules/creative-creator/components/CampaignsField/hooks/useInitialCampaign';
import { withIndication } from 'modules/errors';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingApi } from 'services/api';
import styles from './S2SGenerateTestLink.module.scss';

const ButtonWithIndication = withIndication(Button);
const classNameBuilder = classnames.bind(styles);

export const S22GenerateTestLink = () => {
    const {
        formAccount,
        formAccount: { accountId },
    } = useFormDataContext();
    const initialCampaign = useInitialCampaign();
    const { onChange: onChangeCampaign, value: selectedCampaign } = useFormFieldValue(
        { field: 'campaign' },
        { initialCampaign, formAccount }
    );

    const { generateTestLink } = useTrackingApi();

    const { refetch, data, isPreviousData } = useQuery(
        ['s2sGenerateTestLink', accountId, selectedCampaign?.value],
        () => generateTestLink(accountId, selectedCampaign?.value),
        {
            onSuccess: response => {
                if (response.url) {
                    copy(response.url);
                }
                return response;
            },
            cacheTime: 0,
            refetchOnWindowFocus: false,
            enabled: false,
        }
    );
    const onClickHandle = () => {
        refetch();
    };

    const hasDataAndSuccess = data && data.url && !data?.errorMessage && !isPreviousData;

    return (
        <>
            <FormField
                label={
                    <FormattedMessage
                        id="creative.duplicate.section.campaigns.title"
                        defaultMessage="Select Campaign"
                    />
                }
                inputId="creative-creator-campaign-selector"
            >
                <CampaignDropdown
                    className={styles['dropdown']}
                    accountForCampaign={formAccount}
                    onSelectCampaign={campaign => {
                        onChangeCampaign(campaign);
                    }}
                    selectedCampaigns={[selectedCampaign]}
                    placeholder={
                        <FormattedMessage
                            id="app.creative.creator.campaign.targeting.multiselect.placeholder"
                            defaultMessage="Select"
                        />
                    }
                    validations={[]}
                />
            </FormField>
            <ButtonWithIndication
                disabled={!selectedCampaign}
                className={classNameBuilder('container', { isSuccess: hasDataAndSuccess })}
                onClick={onClickHandle}
                size={Button.size.md}
                variant={Button.variant.ghost}
                indicationType="error"
                message={data?.errorMessage && <FormattedMessage id={data.errorMessage} defaultMessage="error" />}
            >
                {hasDataAndSuccess ? (
                    <CheckCircleOutlinedIcon aria-label="successful copy" />
                ) : (
                    <ContentCopyOutlinedIcon />
                )}
                <FormattedMessage
                    id="tracking.setup.s2s.generate.test.link.copy.button"
                    defaultMessage="Copy Test Link"
                />
            </ButtonWithIndication>
        </>
    );
};
