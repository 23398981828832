'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _hooks = require('../../../hooks');

var _ButtonsGroupButton = require('./ButtonsGroupButton');

var _ButtonsGroupButton2 = _interopRequireDefault(_ButtonsGroupButton);

var _ButtonsGroupDropdown = require('./ButtonsGroupDropdown');

var _ButtonsGroupDropdown2 = _interopRequireDefault(_ButtonsGroupDropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'buttonsGroupOption-module__taboola-svg-icon___kw12E',
    'container': 'buttonsGroupOption-module__container___1L7jA',
    'button-container': 'buttonsGroupOption-module__button-container___19ZiO',
    'selected': 'buttonsGroupOption-module__selected___1gmhp',
    'left-button': 'buttonsGroupOption-module__left-button___2Ly-X',
    'right-button': 'buttonsGroupOption-module__right-button___1IePd',
    'divider': 'buttonsGroupOption-module__divider___LfKQ0',
    'new-badge': 'buttonsGroupOption-module__new-badge___11Zsz'
};


var classNameBuilder = _bind2.default.bind(styles);

var optionComparator = function optionComparator(_ref, _ref2) {
    var valueA = _ref.value;
    var valueB = _ref2.value;
    return valueA === valueB;
};

var ButtonsGroupOption = function ButtonsGroupOption(_ref3) {
    var option = _ref3.option,
        _ref3$selectedValueOb = _ref3.selectedValueObject,
        selectedValueObject = _ref3$selectedValueOb === undefined ? {} : _ref3$selectedValueOb,
        divider = _ref3.divider,
        onChange = _ref3.onChange,
        isFirstButton = _ref3.isFirstButton,
        isLastButton = _ref3.isLastButton,
        setWidth = _ref3.setWidth,
        getHref = _ref3.getHref,
        isScrollable = _ref3.isScrollable;

    var isDropdown = option.options;
    var selected = Boolean(optionComparator(option, selectedValueObject) || isDropdown && option.options.find(function (subButton) {
        return optionComparator(subButton, selectedValueObject);
    }));
    var buttonClassName = classNameBuilder('button-container', {
        selected: selected,
        'left-button': isFirstButton,
        'right-button': isLastButton
    });

    var _useElementSize = (0, _hooks.useElementSize)(),
        ref = _useElementSize.ref,
        width = _useElementSize.width;

    (0, _react.useLayoutEffect)(function () {
        if (width && setWidth) {
            setWidth(width);
        }
    }, [width, setWidth]);

    var dropdownLabel = option.label;
    if (isDropdown && option.options.some(function (_ref4) {
        var DropdownLabelBadge = _ref4.DropdownLabelBadge;
        return DropdownLabelBadge;
    })) {
        var tagList = option.options.reduce(function (acc, curr) {
            if (curr.DropdownLabelBadge) {
                acc.push(curr.DropdownLabelBadge);
            }
            return acc;
        }, []);
        dropdownLabel = _react2.default.createElement(
            _react.Fragment,
            null,
            option.label,
            tagList
        );
    }
    return _react2.default.createElement(
        _react.Fragment,
        { key: option.value },
        _react2.default.createElement(
            'div',
            { className: buttonClassName, ref: setWidth ? ref : null },
            isDropdown ? _react2.default.createElement(_ButtonsGroupDropdown2.default, Object.assign({}, option, {
                label: dropdownLabel,
                className: styles['arrow'],
                onChange: onChange,
                selected: selected,
                selectedValueObject: selectedValueObject,
                getHref: getHref,
                isScrollable: isScrollable
            })) : _react2.default.createElement(_ButtonsGroupButton2.default, Object.assign({}, option, { onChange: onChange, getHref: getHref }))
        ),
        isLastButton ? null : _react2.default.createElement(
            'span',
            { className: styles['divider'] },
            divider
        )
    );
};

var itemPropType = {
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
    options: _propTypes2.default.array
};

ButtonsGroupOption.propTypes = {
    option: _propTypes2.default.shape(itemPropType),
    divider: _propTypes2.default.node,
    selectedValueObject: _propTypes2.default.shape(itemPropType),
    onChange: _propTypes2.default.func,
    isFirstButton: _propTypes2.default.bool,
    isLastButton: _propTypes2.default.bool,
    isScrollable: _propTypes2.default.bool,
    setWidth: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.bool]),
    buttonGroupDropdownContainerClassName: _propTypes2.default.string,
    getHref: _propTypes2.default.func
};

exports.default = ButtonsGroupOption;