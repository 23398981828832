import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

export const CustomContextualSegmentsFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.targeting.custom.contextual.segments.tooltip"
            defaultMessage="Select from your Topics segments. If you would like to create a new segment, click on the Create button from the dropdown menu."
        />
    </TooltipSection>
);
