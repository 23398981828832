import React from 'react';
import { LaunchOutlinedIcon } from 'tuui';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { AddConversion } from 'modules/campaigns/modules/common-campaign-form/components/AddConversionButton/AddConversionButton';
import { AddTrackingButton } from 'modules/campaigns/modules/common-campaign-form/components/AddTrackingButton/AddTrackingButton';
import { MARKETING_OBJECTIVE_KEYS } from 'modules/campaigns/modules/common-campaign-form/config';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

export const marketingObjectiveValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.marketing.objective.empty',
        defaultMessage: 'Select a Marketing Objective',
    },
    {
        validationFn: (value, options, { isPixelOrS2sInstalled, isPixelWarningPermitted, isPixelWarningsTesting }) => {
            if (!isPixelWarningPermitted || !isPixelWarningsTesting) {
                return true;
            }
            if (
                value === MARKETING_OBJECTIVE_KEYS.LEADS_GENERATION ||
                value === MARKETING_OBJECTIVE_KEYS.ONLINE_PURCHASES ||
                value === MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL
            ) {
                return isPixelOrS2sInstalled;
            }
            return true;
        },
        messageId: 'campaign.marketing.objective.validations.noPixelImplementation',
        messageValues: {
            tracking: <AddTrackingButton />,
            conversion: <AddConversion id="campaign.editor.conversion.goal.conversion" defaultMessage="Conversion" />,
            linkIcon: <LaunchOutlinedIcon />,
        },
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, options, { isPixelOrS2sInstalled, isPixelWarningPermitted, isPixelWarningsTesting }) => {
            if (!isPixelWarningPermitted || !isPixelWarningsTesting) {
                return true;
            }

            if (
                value === MARKETING_OBJECTIVE_KEYS.BRAND_AWARENESS ||
                value === MARKETING_OBJECTIVE_KEYS.DRIVE_WEBSITE_TRAFFIC
            ) {
                return isPixelOrS2sInstalled;
            }
            return true;
        },
        messageId: 'campaign.marketing.objective.validations.worksBestWithPixel',
        messageValues: {
            tracking: <AddTrackingButton />,
            conversion: <AddConversion id="campaign.editor.conversion.goal.conversion" defaultMessage="Conversion" />,
            linkIcon: <LaunchOutlinedIcon />,
        },
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (
            value,
            options,
            { hasAtLeastOneActiveConversion, isPixelWarningPermitted, isPixelWarningsTesting }
        ) => {
            if (!isPixelWarningPermitted || !isPixelWarningsTesting) {
                return true;
            }
            if (
                value === MARKETING_OBJECTIVE_KEYS.LEADS_GENERATION ||
                value === MARKETING_OBJECTIVE_KEYS.ONLINE_PURCHASES ||
                value === MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL
            ) {
                return hasAtLeastOneActiveConversion;
            }
            return true;
        },
        messageId: 'campaign.marketing.objective.validations.noActivity',
        messageValues: {
            conversion: <AddConversion id="campaign.editor.conversion.goal.conversion" defaultMessage="Conversion" />,
            linkIcon: <LaunchOutlinedIcon />,
        },
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (
            value,
            options,
            { hasAtLeastOneActiveConversion, isPixelWarningPermitted, isPixelWarningsTesting }
        ) => {
            if (!isPixelWarningPermitted || !isPixelWarningsTesting) {
                return true;
            }
            if (
                value === MARKETING_OBJECTIVE_KEYS.BRAND_AWARENESS ||
                value === MARKETING_OBJECTIVE_KEYS.DRIVE_WEBSITE_TRAFFIC
            ) {
                return hasAtLeastOneActiveConversion;
            }
            return true;
        },
        messageId: 'campaign.marketing.objective.validations.worksBestWithActiveConversion',
        messageValues: {
            conversion: <AddConversion id="campaign.editor.conversion.goal.conversion" defaultMessage="Conversion" />,
            linkIcon: <LaunchOutlinedIcon />,
        },
        severity: INDICATION_TYPES.WARNING,
    },
];
