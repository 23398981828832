import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import UnsupportedConfiguration from 'components/UnsupportedConfiguration/UnsupportedConfiguration';
import { FORM_MODES } from 'modules/campaigns/config';
import transformTimeZonesFromGW from 'modules/campaigns/services/transformers/timeZonesTransformer';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useResourcesApi } from 'services/api/resourcesApi';
import { RUNTIME_TYPES, VIDEO_PRICING_MODEL } from '../../config';
import { TIME_ZONE_METHODS } from '../DayTimeParting/consts/timeZoneOptions';
import Dayparting from '../Dayparting/Dayparting';
import DAYPARTING_DEFAULT from '../Dayparting/config/daypartingDefault';
import RuntimePicker from '../RuntimePicker/RuntimePicker';
import RuntimePickerTooltip from '../RuntimePicker/RuntimePickerTooltip';
import TimezoneDropdown from '../TimezoneDropdown/TimezoneDropdown';
import { SchedulerDayTime } from './SchedulerDayTime';
import SchedulerDayTimeToolTip from './SchedulerDayTimeToolTip';
import { getScheduleDayPartingDefault } from './consts/campaignSchedulerDayTimeDefaultValue';
import { useCampaignSchedulerSupportCheck } from './useCampaignSchedulerSupportCheck';
import styles from './campaignScheduler.module.scss';

const CACHE_TIME = 14400000;

const CampaignScheduler = ({ showTimezones = true, runtimeCustomDescription }) => {
    const isDayPartingPermitted = useConfigMatch({ [FEATURE_FLAGS.SCHEDULE_DAY_TIME_ENABLED]: 'true' });
    const timeZoneEnabled = useConfigMatch({ [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true' });
    const { formAccount: account, isFormAccountTouched } = useFormDataContext();
    const resourcesApi = useResourcesApi();
    const { isSuccess, data } = useQuery('time-zones-list', () => resourcesApi.getTimeZones(), {
        cacheTime: CACHE_TIME,
        refetchOnWindowFocus: false,
    });
    const listOfTimeZones = useMemo(() => {
        if (isSuccess) {
            return transformTimeZonesFromGW(data);
        }
        return [];
    }, [isSuccess, data]);

    const { value: runtimeMode, onChange: onRuntimeModeChange } = useFormFieldValue({ field: 'campaignSchedule.mode' });
    const { value: timeZone, onChange: onTimeZoneChange } = useFormFieldValue(
        { field: 'campaignSchedule.timeZone' },
        { isPermitted: isDayPartingPermitted, timeZoneEnabled, account }
    );
    const { value: campaignScheduleRules, onChange: setCampaignScheduleRules } = useFormFieldValue(
        { field: 'campaignSchedule.rules' },
        { isDayPartingPermitted }
    );

    const {
        onChange: onBusinessModelChange,
        mode,
        value: businessModel,
    } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });
    const [previousRules, setPreviousRules] = useState();
    const dayPartingDefault = useMemo(() => {
        return isDayPartingPermitted ? getScheduleDayPartingDefault() : [...DAYPARTING_DEFAULT];
    }, [isDayPartingPermitted]);

    const isSupported = useCampaignSchedulerSupportCheck({ isDayPartingPermitted });

    useEffect(() => {
        const timeZoneName = account.timeZoneName;

        if (!account.timeZoneName || !isFormAccountTouched()) {
            return;
        }
        onTimeZoneChange(timeZoneName);
    }, [isFormAccountTouched, account, onTimeZoneChange]);

    const handleRunTimeModeChange = useCallback(
        value => {
            let newRules;
            if (value === RUNTIME_TYPES.ALWAYS) {
                setPreviousRules(campaignScheduleRules);
                newRules = [];
            } else {
                newRules = previousRules ? previousRules : dayPartingDefault;
            }
            setCampaignScheduleRules(newRules);

            if (mode === FORM_MODES.EDIT && !timeZoneEnabled && isDayPartingPermitted) {
                onRuntimeModeChange(value);
                onTimeZoneChange(TIME_ZONE_METHODS.USER_TIME_ZONE);
            } else {
                onRuntimeModeChange(value);
            }
            if (mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE) {
                const toChange =
                    value === RUNTIME_TYPES.CUSTOM && businessModel === VIDEO_PRICING_MODEL.CPCV
                        ? VIDEO_PRICING_MODEL.VCPM
                        : businessModel;
                onBusinessModelChange(toChange);
            }
        },
        [
            setCampaignScheduleRules,
            mode,
            timeZoneEnabled,
            isDayPartingPermitted,
            campaignScheduleRules,
            previousRules,
            dayPartingDefault,
            onRuntimeModeChange,
            onTimeZoneChange,
            businessModel,
            onBusinessModelChange,
        ]
    );

    if (isNil(runtimeMode)) {
        return null;
    }
    if (!isSupported) {
        return (
            <FormField
                label={<FormattedMessage id="campaign.editor.schedule.runtime.title" defaultMessage="Days and Hours" />}
                helpText={isDayPartingPermitted ? <SchedulerDayTimeToolTip /> : <RuntimePickerTooltip />}
            >
                <UnsupportedConfiguration />
            </FormField>
        );
    }
    const handleTimeZoneChange = ({ value }) => onTimeZoneChange(value);

    return (
        <Fragment>
            <RuntimePicker
                selection={runtimeMode}
                onChange={handleRunTimeModeChange}
                runtimeCustomDescription={runtimeCustomDescription}
                isDayPartingPermitted={isDayPartingPermitted}
            />
            {runtimeMode === RUNTIME_TYPES.CUSTOM && (
                <FormField inputId="custom-campaign-schedule" containerClass={styles['custom-campaign-schedule']}>
                    {isDayPartingPermitted ? (
                        <SchedulerDayTime
                            isDayPartingPermitted={isDayPartingPermitted}
                            timeZoneEnabled={timeZoneEnabled}
                            timezones={listOfTimeZones}
                            onChange={handleTimeZoneChange}
                            timeZone={timeZone}
                        />
                    ) : (
                        <Dayparting />
                    )}
                    {showTimezones && !isDayPartingPermitted && (
                        <TimezoneDropdown
                            selectedTimezone={timeZone}
                            timezones={listOfTimeZones}
                            onChange={handleTimeZoneChange}
                        />
                    )}
                </FormField>
            )}
        </Fragment>
    );
};

CampaignScheduler.propTypes = { isTimezoneRemoved: PropTypes.bool };

export default CampaignScheduler;
