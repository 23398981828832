'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDotdotdot = require('react-dotdotdot');

var _reactDotdotdot2 = _interopRequireDefault(_reactDotdotdot);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Dotdotdot = function Dotdotdot(props) {
    var children = props.children,
        lines = props.lines,
        rest = _objectWithoutProperties(props, ['children', 'lines']);

    return _react2.default.createElement(
        _reactDotdotdot2.default,
        Object.assign({ clamp: lines }, rest),
        children
    );
};

Dotdotdot.propTypes = {
    /** className to apply on the root element */
    className: _propTypes2.default.string,
    /** The element to which the ellipsis logic is applied */
    children: _propTypes2.default.oneOfType([_propTypes2.default.element, _propTypes2.default.string]).isRequired,
    /** Number of visible text lines */
    lines: _propTypes2.default.number,
    /** Use -webkit-line-clamp available in Webkit (Chrome, Safari) only. */
    useNativeClamp: _propTypes2.default.bool,
    /** Split on sentences (periods), hypens, en-dashes, em-dashes, and words (spaces) */
    splitOnChars: _propTypes2.default.array,
    /** animated clamp */
    animate: _propTypes2.default.bool,
    /** The character to insert at the end of the HTML element after truncation is performed. This defaults to an ellipsis (…).
        useNativeClamp overrides it to default */
    truncationChar: _propTypes2.default.string,
    /** String of HTML to use instead of truncationChar */
    truncationHTML: _propTypes2.default.node,
    /** The type of HTML tag which will wrap the component's content */
    tagName: _propTypes2.default.string
};

Dotdotdot.defaultProps = {
    className: '',
    lines: 2,
    useNativeClamp: true,
    splitOnChars: ['.', '-', '–', '—', '_', ' '],
    animate: false,
    truncationChar: '...',
    tagName: 'div'
};

exports.default = Dotdotdot;