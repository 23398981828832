import React, { ButtonHTMLAttributes, forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { Scale } from 'tuui/lib/theme';
import { getScaledSize, scale, theme } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';
import { ButtonVariant, buttonVariants, variantStateVariable } from './buttonVariants';

export interface ButtonSizing {
    paddingY: Scale;
    paddingX: Scale;
}

export type ButtonSize = 'sm' | 'md' | 'lg';

export const buttonSizingMap: Record<ButtonSize, ButtonSizing> = {
    sm: {
        paddingX: 'sm',
        paddingY: 'xxs',
    },
    md: {
        paddingX: 'lg',
        paddingY: 'xs',
    },
    lg: {
        paddingX: 'lg',
        paddingY: 'sm',
    },
};

export interface ButtonProps {
    variant?: ButtonVariant;
    size?: ButtonSize;
}

export const Button = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps>(
    ({ variant = 'primary', size = 'md', type = 'button', children, ...props }, ref) => {
        const [innerRef, setInnerRef] = useState<HTMLButtonElement | null>(null);
        useImperativeHandle(ref, () => innerRef!);
        const onlyChild = innerRef?.childNodes?.length === 1;
        return (
            <ButtonBase
                ref={setInnerRef}
                {...props}
                type={type}
                $variant={variant}
                $sizing={buttonSizingMap[size]}
                $onlyChild={onlyChild}
            >
                {children}
            </ButtonBase>
        );
    }
) as UIC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps>;

const ButtonBase = styled.button<{ $variant: ButtonVariant; $sizing: ButtonSizing; $onlyChild: boolean }>`
    --text: ${({ theme, $variant }) => buttonVariants[$variant].text(theme)};
    --fill: ${({ theme, $variant }) => buttonVariants[$variant].fill(theme)};
    --stroke: ${({ theme, $variant }) => buttonVariants[$variant].stroke(theme)};

    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    border: none;
    box-sizing: content-box;
    cursor: pointer;

    color: var(--text);
    background-color: var(--fill);
    box-shadow: inset 0 0 0 1px var(--stroke);
    white-space: nowrap;

    gap: ${scale('sm')};
    border-radius: ${theme('curves.md')};
    font-size: ${theme('typography.sizes.md')};
    font-family: ${theme('typography.font')};
    line-height: ${scale('xxl')};
    letter-spacing: 0.5px;
    padding-inline: ${({ theme, $sizing }) => getScaledSize(theme, $sizing.paddingX)};
    padding-block: ${({ theme, $sizing }) => getScaledSize(theme, $sizing.paddingY)};

    &:hover {
        ${variantStateVariable('hover', 'text')};
        ${variantStateVariable('hover', 'fill')};
        ${variantStateVariable('hover', 'stroke')};
    }

    &:active {
        ${variantStateVariable('active', 'text')};
        ${variantStateVariable('active', 'fill')};
        ${variantStateVariable('active', 'stroke')};
    }

    &:disabled {
        --text: ${({ theme }) => theme.palette.disabled.text};
        --fill: ${({ theme }) => theme.palette.disabled.fill};
        --stroke: ${({ theme }) => theme.palette.disabled.stroke};
        ${variantStateVariable('disabled', 'text')};
        ${variantStateVariable('disabled', 'fill')};
        ${variantStateVariable('disabled', 'stroke')};
    }

    > svg:not(:root) {
        height: 1em;
        width: 1em;
        transform: scale(2);
        overflow: visible;
        font-size: 1.2rem;

        ${({ $onlyChild, $sizing }) =>
            $onlyChild &&
            `
            padding-block: 0.5em;
            margin-inline: ${$sizing === buttonSizingMap.lg ? '-2px' : $sizing === buttonSizingMap.md ? '-12px' : '0'};
        `}
    }
`;
