import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CREATIVE_VIDEO].id;

const defaultColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.CREATIVE_NAME.field,
    FIELDS.CAMPAIGN_NAME.field,
    FIELDS.SPENT.field,
    FIELDS.COMPLETED_VIEWS.field,
    FIELDS.FIRST_QUARTILE.field,
    FIELDS.MID_POINT.field,
    FIELDS.THIRD_QUARTILE.field,
    FIELDS.CPCV.field,
    FIELDS.CLICKS.field,
    FIELDS.CTR.field,
    FIELDS.CPA.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.SPENT.field,
        FIELDS.COMPLETED_VIEWS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.FIRST_QUARTILE.field,
        FIELDS.MID_POINT.field,
        FIELDS.THIRD_QUARTILE.field,
        FIELDS.COMPLETED_VIDEO.field,
        FIELDS.THIRTY_SECONDS_MARK.field,
        FIELDS.CPM.field,
        FIELDS.VCPM.field,
        FIELDS.CPCV.field,
        FIELDS.VIEWABILITY_RATE.field,
        FIELDS.HUNDRED_PCT_VIEWABILITY_RATE.field,
        FIELDS.COMPLETION_RATE.field,
        FIELDS.CLICKS.field,
        FIELDS.CTR.field,
        FIELDS.VCTR.field,
        FIELDS.CPA.field,
        FIELDS.VIDEO_CVR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.VIDEO_DURATION.field,
    ].map(field => generateColumnId(reportId, field)),
};

export { customColumns, defaultColumns };
