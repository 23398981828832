import { convertFileToWorkbook } from '../utils/convertFileToWorkbook';
import { convertWorkbookToBlob } from '../utils/convertWorkbookToBlob';
import { generateResponseSummary } from '../utils/generateResponseSummary';
import { addReportToWorkbook } from './addReportToWorkbook';

export const buildParsingErrorReport = async ({ excelConfig, file, formatMessage, entitiesData }) => {
    const workbook = await convertFileToWorkbook(file);
    excelConfig.entityList.forEach(entityConfig => {
        const entity = entityConfig.entity;
        addReportToWorkbook({
            workbook,
            formatMessage,
            responseData: entitiesData[entity],
            entityConfig,
        });
    });

    const reportFileBlob = await convertWorkbookToBlob(workbook);
    const responseSummary = generateResponseSummary(excelConfig, entitiesData);

    return { reportFileBlob, responseSummary };
};
