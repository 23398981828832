import React from 'react';
import CommonCollapsibleCard from '../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from '../../../campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { StripePaymentElement } from '../StripeElements/StripePaymentElement/StripePaymentElement';
import styles from './paymentMethodACHSection.module.scss';

export const PaymentMethodACHSection = () => (
    <CommonCollapsibleCard
        id="ACH_BANK_DIRECT_DEBIT"
        header={
            <SectionHeader
                headerText={<FormattedMessage id="paymentMethod.creator.ACH_BANK_DIRECT_DEBIT" defaultMessage="ACH" />}
            />
        }
    >
        <div className={styles['collapsible-body']} data-private="true">
            <StripePaymentElement />
        </div>
    </CommonCollapsibleCard>
);
