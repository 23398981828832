import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { errorMessagesUtils } from 'services/utils';
import { sitesApi } from '../../../services/api';
import { addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { requestToggleSiteBlocking, toggleSiteBlockingSuccess, toggleSiteBlockingError } from '../actions';

const indications = {
    success: {
        message: (
            <FormattedMessage
                id="app.campaigns.reports.block.site.success"
                defaultMessage="Site status successfully updated."
            />
        ),
        type: INDICATION_TYPES.SUCCESS,
        highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    },
    error: (errorObject = {}) => ({
        message: errorMessagesUtils.getErrorMessage(errorObject),
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
    }),
};

const baseToggleSiteBlocking = blockSiteApiCall => (accountId, site, onError, onSuccess) => dispatch => {
    dispatch(requestToggleSiteBlocking());
    blockSiteApiCall(accountId, site)
        .then(() => {
            dispatch(addIndication(indications.success));
            dispatch(toggleSiteBlockingSuccess());
            if (onSuccess) {
                onSuccess();
            }
        })
        .catch(error => {
            dispatch(toggleSiteBlockingError(error));
            dispatch(addIndication(indications.error(error)));
            if (onError.handler) {
                onError.handler(error);
            }
        });
};

const blockSite = baseToggleSiteBlocking(sitesApi.blockSite);
const unblockSite = baseToggleSiteBlocking(sitesApi.unblockSite);
const unblockSites = baseToggleSiteBlocking(sitesApi.unblockSites);

export { blockSite, unblockSite, unblockSites };
