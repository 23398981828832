import { values } from 'lodash';

const generatePlacementConfig = id => ({
    containerId: id,
    placementName: `Ads Console - Creative Preview - ${id}`,
    mode: `thumbnails-${id}`,
    width: placementWidths[id],
});

const PLACEMENTS = {
    TWO_BY_ONE: '2x1',
    SIXTEEN_BY_NINE: '16x9',
    FOUR_BY_THREE: '4x3',
    SIX_BY_FIVE: '6x5',
    ONE_BY_ONE: '1x1',
    ONE_DOT_NINETY_ONE_BY_ONE: '19x10',
};

const placementWidths = {
    [PLACEMENTS.TWO_BY_ONE]: 1,
    [PLACEMENTS.SIXTEEN_BY_NINE]: 2,
    [PLACEMENTS.FOUR_BY_THREE]: 2,
    [PLACEMENTS.SIX_BY_FIVE]: 3,
    [PLACEMENTS.ONE_BY_ONE]: 3,
    [PLACEMENTS.ONE_DOT_NINETY_ONE_BY_ONE]: 1,
};

const PLACEMENTS_CONFIG = {
    [PLACEMENTS.TWO_BY_ONE]: generatePlacementConfig(PLACEMENTS.TWO_BY_ONE),
    [PLACEMENTS.SIXTEEN_BY_NINE]: generatePlacementConfig(PLACEMENTS.SIXTEEN_BY_NINE),
    [PLACEMENTS.FOUR_BY_THREE]: generatePlacementConfig(PLACEMENTS.FOUR_BY_THREE),
    [PLACEMENTS.SIX_BY_FIVE]: generatePlacementConfig(PLACEMENTS.SIX_BY_FIVE),
    [PLACEMENTS.ONE_BY_ONE]: generatePlacementConfig(PLACEMENTS.ONE_BY_ONE),
    [PLACEMENTS.ONE_DOT_NINETY_ONE_BY_ONE]: generatePlacementConfig(PLACEMENTS.ONE_DOT_NINETY_ONE_BY_ONE),
};

const PLACEMENTS_CONFIG_ARR = values(PLACEMENTS_CONFIG);

export { PLACEMENTS, PLACEMENTS_CONFIG, PLACEMENTS_CONFIG_ARR };
