'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useCheckboxContext = exports.CheckboxProvider = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CheckboxContext = (0, _react.createContext)({});

var CheckboxProvider = exports.CheckboxProvider = function CheckboxProvider(_ref) {
    var selectedValues = _ref.selectedValues,
        onSelectCheckbox = _ref.onSelectCheckbox,
        onUnselectCheckbox = _ref.onUnselectCheckbox,
        onIntermediateSelect = _ref.onIntermediateSelect,
        children = _ref.children;
    return _react2.default.createElement(
        CheckboxContext.Provider,
        { value: {
                selectedValues: selectedValues,
                onSelectCheckbox: onSelectCheckbox,
                onUnselectCheckbox: onUnselectCheckbox,
                onIntermediateSelect: onIntermediateSelect
            }
        },
        children
    );
};

CheckboxProvider.propTypes = {
    /** Checkboxes inside the group */
    children: _propTypes2.default.node.isRequired,
    /** Selected checkbox values */
    selectedValues: _propTypes2.default.array,
    /** On select checkbox action */
    onSelectCheckbox: _propTypes2.default.func,
    /** On intermediate select checkbox action */
    onIntermediateSelect: _propTypes2.default.func,
    /** On unselect checkbox action */
    onUnselectCheckbox: _propTypes2.default.func
};

CheckboxProvider.defaultProps = {
    onSelectCheckbox: function onSelectCheckbox() {},
    onUnselectCheckbox: function onUnselectCheckbox() {},
    onIntermediateSelect: function onIntermediateSelect() {},
    selectedValues: []
};

var useCheckboxContext = exports.useCheckboxContext = function useCheckboxContext() {
    var ctx = (0, _react.useContext)(CheckboxContext);

    return ctx;
};