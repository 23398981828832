import React, { useCallback, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { DRAWER_MODE, SideDrawer } from 'components';
import { EDITOR_ROUTE_PATH, FORM_MODES } from '../../../campaigns/config';
import { LayerRoute } from '../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import {
    COMBINED_AUDIENCE_EDITOR_ROUTE_PATH,
    CUSTOM_CONTEXTUAL_EDITOR_ROUTE_PATH,
    FIRST_PARTY_EDITOR_ROUTE_PATH,
    MAIL_DOMAIN_AUDIENCE_EDITOR_ROUTE_PATH,
    PATH_TO_ENTITY_MAP,
    PIXEL_BASED_EDITOR_ROUTE_PATH,
    PREDICTIVE_EDITOR_ROUTE_PATH,
    SEARCH_KEYWORDS_AUDIENCE_EDITOR_ROUTE_PATH,
} from '../../config';
import {
    CustomContextualEditorPage,
    FirstPartyAudiencesEditorPage,
    MailDomainsAudiencesEditorPage,
    PixelBasedAudiencesEditorPage,
    PredictiveAudiencesEditorPage,
    SearchKeywordsAudiencesEditorPage,
} from '../../pages';
import { CombinedAudiencesEditorPage } from '../../pages/CombinedAudiencesEditorPage';

const EditDrawer = ({ onCancel }) => {
    const { params: { mode, entity: entityParam } = {} } = useRouteMatch(EDITOR_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const [drawerMode, setDrawerMode] = useState(DRAWER_MODE.SMALL);
    const setMediumDrawer = useCallback(() => {
        setDrawerMode(DRAWER_MODE.MEDIUM);
    }, [setDrawerMode]);
    const resetDrawerSize = useCallback(() => {
        setDrawerMode(DRAWER_MODE.SMALL);
    }, [setDrawerMode]);

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel} drawerMode={drawerMode}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={PIXEL_BASED_EDITOR_ROUTE_PATH}
                        render={props => (
                            <PixelBasedAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} onCancel={close} />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={FIRST_PARTY_EDITOR_ROUTE_PATH}
                        render={props => (
                            <FirstPartyAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} onCancel={close} />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={PREDICTIVE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <PredictiveAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} onCancel={close} />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={CUSTOM_CONTEXTUAL_EDITOR_ROUTE_PATH}
                        render={props => (
                            <CustomContextualEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                onCancel={close}
                                setMediumDrawer={setMediumDrawer}
                                resetDrawerSize={resetDrawerSize}
                            />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={SEARCH_KEYWORDS_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <SearchKeywordsAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} onCancel={close} />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={MAIL_DOMAIN_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <MailDomainsAudiencesEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                onCancel={close}
                                setMediumDrawer={setMediumDrawer}
                                resetDrawerSize={resetDrawerSize}
                            />
                        )}
                    />
                    <LayerRoute
                        exact
                        path={COMBINED_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <CombinedAudiencesEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                onCancel={close}
                                setMediumDrawer={setMediumDrawer}
                                resetDrawerSize={resetDrawerSize}
                            />
                        )}
                    />
                </Switch>
            )}
        </SideDrawer>
    );
};

EditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
};

export default EditDrawer;
