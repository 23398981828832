import { get } from 'lodash';
import { intercomApi } from 'services/api';
import { setTotalCampaignsCount } from '../../../campaigns/actions/setTotalCampaignsCount';
import { intercomService } from '../services/IntercomService';

export default ({ accountName }, chatAgentPermitted) =>
    async dispatch => {
        try {
            const { bootData = {}, updateData } = await intercomApi.getIntercomParameters(accountName);
            const totalCampaignsCount = get(bootData, 'company.total_campaigns_count', null);
            bootData.chat_agent_permitted = chatAgentPermitted;

            intercomService.initIntercomService(bootData, updateData);
            dispatch(setTotalCampaignsCount(totalCampaignsCount));
        } catch (error) {
            intercomService.shutdown();
        }
    };
