export const funnelTransformerToGW = funnel => {
    const rules = [...funnel.rules].map(({ id, label }, index) => ({
        id,
        name: label,
        stageNumber: funnel.rules.length - index,
    }));
    const { id } = funnel;
    return {
        id,
        name: funnel.name,
        rules,
    };
};

export const funnelTransformerFromGW = ({ id, name, rules }) => ({
    id,
    name,
    rules: rules.map(({ id, name: label, stageNumber }) => ({ id, label, stageNumber })),
});
