import React from 'react';
import classnames from 'classnames/bind';
import FormLayout from '../../../../campaigns/components/Form/FormLayout/FormLayout';
import styles from './modalFormLayout.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ModalFormLayout = ({ children, bodyClassName, ...rest }) => (
    <FormLayout
        contentContainerClassName={styles['container']}
        footerContainerClassName={styles['footer']}
        bodyContainerClassName={classNameBuilder('body', bodyClassName)}
        showFooterValidations={false}
        {...rest}
    >
        {children}
    </FormLayout>
);
