import React from 'react';
import { startCase } from 'lodash';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useFetchConversionRules } from '../PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/ConversionEvent/useFetchConversionRules';
import styles from './ruleSummarySection.module.scss';

const getTimeRangeValue = (timeRangeValue, formatMessage) => {
    if (timeRangeValue === null || timeRangeValue === undefined) {
        return '';
    }
    const defaultMessage = `in last ${timeRangeValue} days`;

    if (timeRangeValue === 0 || timeRangeValue === 1) {
        return formatMessage({
            id: `performance.rule.summary.time.range.${timeRangeValue}`,
            defaultMessage,
        });
    }
    return formatMessage(
        {
            id: 'performance.rule.summary.time.range.last.days',
            defaultMessage,
        },
        { timeRangeValue }
    );
};

const getIs = (predicateType, conditionValue, formatMessage) =>
    formatMessage(
        {
            id: `performance.rules.summary.predicate.type.${predicateType}`,
            defaultMessage: `is ${startCase(predicateType.toLowerCase())} ${conditionValue} `,
        },
        { conditionValue }
    );

const getConversionName = (conversionId, conversions) => {
    const conversion = conversions?.find(conversion => conversion.id === parseInt(conversionId));
    return conversion?.conversionName || '';
};

const getFromConversion = (conversions, subMetric, formatMessage) => {
    if (!subMetric.property) {
        return ' ';
    }
    const fromConversion = formatMessage({
        id: 'performance.rule.summary.conversion.from',
        defaultMessage: ' from ',
    });

    return fromConversion + '"' + getConversionName(subMetric.propertyValue, conversions) + '" ';
};

const getCurrencyOrPercent = (conditionValue, ruleMetric, currency) => {
    const convertToNum = parseFloat(conditionValue);
    const isCurrencyNeeded =
        !Number.isNaN(convertToNum) && (ruleMetric === 'CPA' || ruleMetric === 'SPEND' || ruleMetric === 'VALUE');
    if (isCurrencyNeeded) {
        return ` ${currency} `;
    }

    const isPercentNeeded =
        !Number.isNaN(convertToNum) &&
        (ruleMetric === 'VCTR' || ruleMetric === 'ROAS' || ruleMetric === 'CVR' || ruleMetric === 'CTR');
    if (isPercentNeeded) {
        return '% ';
    }
    return ' ';
};

const getBody = (conversions, currency, condition, formatMessage) => {
    const { ruleMetric, subMetric, lookbackWindowInDays, predicateType, conditionValue } = condition;

    const conditionText = formatMessage({
        id: `performance.rule.create.settings.metric.name.${ruleMetric}`,
        defaultMessage: ruleMetric,
    });

    const fromConversion = getFromConversion(conversions, subMetric, formatMessage);
    const isValue = getIs(predicateType, conditionValue, formatMessage);
    const currencyText = getCurrencyOrPercent(conditionValue, ruleMetric, currency, formatMessage);
    const inTimeRange = getTimeRangeValue(lookbackWindowInDays, formatMessage);

    return conditionText + fromConversion + isValue + currencyText + inTimeRange;
};

const getIfAndSection = (rule, currency, formatMessage, conversions) =>
    rule.conditions.map((condition, index) => (
        <div key={condition.ruleMetric + index} className={styles['row-content']}>
            {index === 0 ? (
                <FormattedMessage id="performance.rule.summary.if" defaultMessage="If " className={styles['ifAnd']} />
            ) : (
                <FormattedMessage id="performance.rule.summary.and" defaultMessage="And " className={styles['ifAnd']} />
            )}
            {getBody(conversions, currency, condition, formatMessage)}
        </div>
    ));

export const RuleSummarySection = () => {
    const { data: rule = {} } = useFormDataContext();
    const { currency, accountId } = useAccount();
    const { formatMessage } = useIntl();

    const conversions = useFetchConversionRules(rule.conditions, accountId);

    return (
        <CommonCollapsibleCard
            id="CONVERSION_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="performance.rule.summary.header.text" defaultMessage="Rule Summary" />
                    }
                />
            }
            containerClassName={styles['card-container']}
        >
            <div className={styles['rows']}>
                {getIfAndSection(rule, currency, formatMessage, conversions)}
                <div>
                    <FormattedMessage
                        id="performance.rule.summary.then"
                        defaultMessage="Then "
                        className={styles['ifAnd']}
                    />
                    <FormattedMessage
                        id={`performance.rule.summary.then.${rule.dimension}`}
                        defaultMessage="Block Site"
                        className={styles['row-content']}
                    />
                </div>
            </div>
        </CommonCollapsibleCard>
    );
};
