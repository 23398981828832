import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { withIndication } from 'modules/errors/components/withIndication';

const RadioGroupEditField = ({ radioOptions, value, ...restProps }) => (
    <RadioGroup selectedValue={value} {...restProps}>
        {radioOptions.map(({ radioValue, title, disableMessage, isDisabled }) => (
            <TooltipWrapper
                key={radioValue}
                wrap={!!disableMessage}
                tooltipContent={disableMessage}
                tooltipOptions={{ arrow: true, position: TOOLTIP_POSITION.TOP_LEFT }}
            >
                <Radio key={radioValue} value={radioValue} title={title} disabled={isDisabled} />
            </TooltipWrapper>
        ))}
    </RadioGroup>
);

RadioGroupEditField.propTypes = {
    radioOptions: PropTypes.array,
    value: PropTypes.string,
};

export { RadioGroupEditField };
export default withIndication(RadioGroupEditField);
