import encodeurl from 'encodeurl';
import { createRssCreatives } from '../flows';

export const useRssHandler = (accountId, data, dispatch) => () => {
    const {
        rssData: { validatedUrls },
        campaigns: [selectedCampaign],
    } = data;
    const validatedAndEncodedUrls = validatedUrls.map(url => encodeurl(url));

    return dispatch(
        createRssCreatives({
            accountId,
            selectedCampaign,
            rssUrls: validatedAndEncodedUrls,
        })
    );
};
