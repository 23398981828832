import React, { useState, useCallback } from 'react';
import { WeekIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../../../components/Form/SectionHeader/SectionHeader';
import CampaignsGroupTimeFrame from '../../../../components/CampaignsGroupTimeFrame/CampaignsGroupTimeFrame';

export const ScheduleSection = () => {
    const [selected, setSelected] = useState(false);

    const onSelect = useCallback(() => {
        setSelected(!selected);
    }, [selected]);

    return (
        <CommonCollapsibleCard
            id="CAMPAIGNS_GROUP_SCHEDULE"
            header={
                <SectionHeader
                    headerIcon={<WeekIcon />}
                    headerText={
                        <FormattedMessage id="campaigns.group.editor.schedule" defaultMessage="Group Schedule" />
                    }
                />
            }
        >
            <CampaignsGroupTimeFrame formFieldParams={{ selected, onSelect }} isDisabled={!selected} />
        </CommonCollapsibleCard>
    );
};
