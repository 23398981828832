import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormField, EditableText } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import styles from './editableHeaderFormField.module.scss';

const classNameBuilder = classnames.bind(styles);

const EditableTextWithIndication = withIndication(EditableText);

export const EditableHeaderFormField = ({ inputId, containerClassName, name, ...rest }) => (
    <FormField inputId={inputId} containerClass={classNameBuilder('form-field-container', containerClassName)}>
        <div name={name}>
            <EditableTextWithIndication
                className={styles['edit-container']}
                inputClassName={styles['edit-value']}
                iconName="edit-o"
                {...rest}
            />
        </div>
    </FormField>
);

EditableHeaderFormField.propTypes = {
    inputId: PropTypes.string,
    containerClassName: PropTypes.string,
    ...EditableTextWithIndication.propTypes,
};
