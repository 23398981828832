export const CONSENT_API_PREFIX = '/consent';

export const consentApiFactory = ({ callGetApi }) => ({
    exclude: () => {
        return callGetApi(`${CONSENT_API_PREFIX}/exclude`, {}, true);
    },
    include: () => {
        return callGetApi(`${CONSENT_API_PREFIX}/include`, {}, true);
    },
});
