import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { DatePicker } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { FormattedMessage, getDateFormat, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { renderWeekHeaderElement } from '../../utils/dashboardRangePickerUtils';
import { getDateWithTimeZone } from '../ValueFormatters/dateFormatter';
import BaseCellEditor from './BaseCellEditor';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';
import styles from './dateCellEditor.module.scss';

const getValueView = value => (value ? moment(value) : null);
const parseInputValue = newValue => newValue || 0;

const DateCellEditor = props => {
    const {
        value: oldValue,
        placeholderMsgId,
        defaultPlaceholderMsg,
        inputIconMsgId,
        defaultInputIconMsg,
        data,
        onCancel,
        colDef,
    } = props;
    const { accountTimeZoneName } = data;
    const [{ timeZoneName: selectedAccountTimeZoneName }] = useSelectedAccount();
    const [focused, setFocused] = useState(false);
    const customInputIcon = <FormattedMessage id={inputIconMsgId} defaultMessage={defaultInputIconMsg} />;
    const { hasError, submitHandler, id, value, onChange } = useSingleFieldCellEditorData({
        ...props,
        parseInputValue,
    });
    const onFocusChange = focused => {
        setFocused(focused);
    };

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: placeholderMsgId,
        defaultMessage: defaultPlaceholderMsg,
    });

    //On start editing we need to apply time zone shift
    useEffect(() => {
        if (!oldValue) {
            return;
        }
        onChange(getDateWithTimeZone(oldValue, accountTimeZoneName));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!value) {
            setFocused(false);
        }
    }, [value]);

    return (
        <BaseCellEditor onCancel={onCancel} onSubmit={submitHandler} disableSubmit={hasError} colDef={colDef}>
            <div className={styles['wrapper']}>
                <DatePicker
                    id={id}
                    date={getValueView(value)}
                    focused={focused}
                    onDateChange={onChange}
                    onFocusChange={onFocusChange}
                    placeholder={placeholder}
                    customInputIcon={customInputIcon}
                    displayFormat={getDateFormat(accountTimeZoneName || selectedAccountTimeZoneName)}
                    showClearDate
                    renderWeekHeaderElement={renderWeekHeaderElement}
                />
            </div>
        </BaseCellEditor>
    );
};

DateCellEditor.propTypes = {
    value: PropTypes.number,
    placeholderMsgId: PropTypes.string,
    defaultPlaceholderMsg: PropTypes.string,
    inputIconMsgId: PropTypes.string,
    defaultInputIconMsg: PropTypes.string,
    onCancel: PropTypes.func,
    colDef: PropTypes.object,
};

export default withGridCellFormDataProvider(DateCellEditor);
