import { useEffect } from 'react';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

export const useViewedSuggestionsGA = ({ campaignId, collapsed }) => {
    useEffect(() => {
        if (collapsed) {
            return;
        }

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'AIContentTitleSuggestions - Viewed',
            taboolaCampaignId: campaignId,
        });
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'AIContentDescriptionSuggestions - Viewed',
            taboolaCampaignId: campaignId,
        });
    }, [campaignId, collapsed]);
};
