import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { lookBackWindowValidations } from 'modules/tracking/validations/lookBackWindowValidations';
import { LookBackWindowInput } from './LookBackWindowInput/LookBackWindowInput';
import styles from './LookBackWindowInput/lookBackWindowInput.module.scss';

const inputPadding = parseInt(styles.currencyInputLeftPadding);

export const LookBackWindowField = () => {
    return (
        <FormField
            inputId="conversion-window"
            label={
                <FormattedMessage
                    id="tracking.conversion.properties.window.title"
                    defaultMessage="Click Through Conversion Window"
                />
            }
            description={
                <FormattedMessage
                    id="tracking.conversion.properties.window.description"
                    defaultMessage="Set the number of days after a click during which a conversion be recorder"
                />
            }
            subtext={
                <FormattedMessage id="tracking.conversion.properties.window.subtext" defaultMessage="Max: 30 days" />
            }
        >
            <LookBackWindowInput
                field="lookBackWindow"
                id="conversion-window"
                validations={lookBackWindowValidations}
                currency="DAYS"
                inputPadding={inputPadding}
            />
        </FormField>
    );
};
