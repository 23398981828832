import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { ExternalLink } from '../../../../../../components';
import { useAccount } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage, useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../../../config/PaymentType';
import { useAllowedPrepaymentMethods } from '../../../../hooks/useAllowedPrepaymentMethods';
import { useBankTransferMessages } from '../../../../hooks/useBankTransferMessages';
import styles from '../../addFundsModal.module.scss';

export const InstructionsBankTransfer = () => {
    const { formatMessage } = useIntl();
    const { currency } = useAccount();
    const { vatPercentage, absoluteCommissionForBankTransfer } = useAllowedPrepaymentMethods();
    const { instruction2Id, instruction2tooltipId } = useBankTransferMessages();
    const upperCaseCurrency = currency?.toUpperCase();
    const href = formatMessage({
        id: `billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.help.center.href`,
        defaultMessage: `https://help.taboola.com/hc/en-us/articles/16499296445207-Bank-Transfer`,
    });

    return (
        <div className={styles['bank-transfer-instructions']}>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.instruction.1`}
                    values={{ currency: upperCaseCurrency }}
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.instruction.1.tooltip`}
                        values={{ currency: upperCaseCurrency }}
                    />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage id={instruction2Id} />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage id={instruction2tooltipId} />
                </HelpTooltip>
            </li>
            {absoluteCommissionForBankTransfer > 0 && (
                <li className={styles['instruction-text']}>
                    <FormattedMessage
                        id="billingAndPayments.modal.addFunds.absoluteCommissionForBankTransfer.instruction"
                        values={{
                            currency_symbol: getSymbolFromCurrency(upperCaseCurrency),
                            amount: absoluteCommissionForBankTransfer,
                        }}
                    />
                    <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                        <FormattedMessage
                            id="billingAndPayments.modal.addFunds.absoluteCommissionForBankTransfer.instruction.tooltip"
                            values={{
                                currency_code: upperCaseCurrency,
                                amount: absoluteCommissionForBankTransfer,
                                currency_symbol: getSymbolFromCurrency(upperCaseCurrency),
                            }}
                        />
                    </HelpTooltip>
                </li>
            )}
            {vatPercentage > 0 && (
                <li className={styles['instruction-text']}>
                    <FormattedMessage id="billingAndPayments.modal.addFunds.vat.instruction" />
                    <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                        <FormattedMessage id="billingAndPayments.modal.addFunds.vat.instruction.tooltip" />
                    </HelpTooltip>
                </li>
            )}
            <li className={styles['instruction-text']}>
                <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.instructions" />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.tooltip" />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.instruction.3`}
                />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.instruction.3.tooltip`}
                    />
                </HelpTooltip>
            </li>
            <ExternalLink href={href} className={styles['link']}>
                <FormattedMessage
                    id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.help.center`}
                    defaultMessage="Help Center"
                />
            </ExternalLink>
        </div>
    );
};
