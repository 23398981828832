import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { BackgroundOverlay, useEscapeHandler, useEnterHandler } from 'taboola-ultimate-ui';
import { APP_EVENT_TYPE, useAppEventContext } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { CHAT_AGENT_ACTION_STATUS } from 'modules/taboola-common-frontend-modules/chat-agent/config/chatAgentActionStatus';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../../../components/Form';
import ColDefLabel from './ColDefLabel';
import styles from './baseCellEditor.module.scss';

const BaseCellEditor = ({ label, field, children, onSubmit, onCancel: onCancelProp, colDef, ...restProps }) => {
    const { push: pushAppEvent } = useAppEventContext();
    const onCancel = useCallback(
        (...args) => {
            onCancelProp(...args);
            pushAppEvent({ status: CHAT_AGENT_ACTION_STATUS.CANCEL, type: APP_EVENT_TYPE.EDIT_GRID_CELL });
        },
        [pushAppEvent, onCancelProp]
    );
    useEscapeHandler(onCancel);
    useEnterHandler(onSubmit);
    const containerRef = useRef(null);

    return (
        <div ref={containerRef} className={styles['container']}>
            <div className={styles['label']}>{label ? label : <ColDefLabel {...colDef} />}</div>
            {children}
            <FormButtons
                className={styles['buttons-container']}
                onSubmit={onSubmit}
                onCancel={onCancel}
                showFooterValidations={false}
                {...restProps}
            />
            <BackgroundOverlay foregroundElementRef={containerRef} onOutsideClick={onCancel} />
        </div>
    );
};

BaseCellEditor.propTypes = {
    /* Label Component to render for the header */
    label: PropTypes.node,
    /* Field component to be rendered inside the FieldSection */
    field: PropTypes.node,
    /* Any children to be rendered below the input and above the buttons */
    children: PropTypes.node,
    /* Props to customize the form buttons */
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitLabel: PropTypes.node,
    cancelLabel: PropTypes.node,
    ...FormButtons.propTypes,
};

BaseCellEditor.defaultProps = {
    submitLabel: <FormattedMessage id="app.actionButtons.save" defaultMessage="Save" />,
    cancelLabel: <FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />,
};

export default BaseCellEditor;
