import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import {
    DEFAULT_CAMPAIGN_ID,
    DEFAULT_CREATIVE_ID,
    FIELD_TYPES,
} from 'modules/campaigns/components/ContentEditableText/services/validateContent';
import { singleRatioCropDataTransformer } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/CroppingToolsForm/utils/cropDataUtils';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { getAutoReviewValidationResults, getRejectReasonFromResults } from './autoReviewValidationUtils';

const getContentThumbnailValidationError = results => {
    const reason = getRejectReasonFromResults(results);

    if (!reason) {
        return true;
    }

    throw new CustomValidationError({
        messageCode: `app.campaigns.campaign.status.reject.reason.${reason}`,
        message: 'This thumbnail is not compliant with our policy, please replace it',
    });
};

export const thumbnailImageSafetyValidation = {
    validationFn: async (val, options, dependencies = {}) => {
        const { accountId, campaignId = DEFAULT_CAMPAIGN_ID } = dependencies;

        return getAutoReviewValidationResults({
            accountId,
            campaignId,
            fieldToValidate: FIELD_TYPES.THUMBNAIL_URL,
            item: {
                thumbnailUrl: val,
                id: DEFAULT_CREATIVE_ID,
            },
            scope: 'WARNINGS_ONLY',
        });
    },
    severity: INDICATION_TYPES.WARNING,
    messageId: 'validations.thumbnail.image.safety.error',
    defaultMessage: 'This thumbnail may not be compliant with our policy, consider replacing it',
};

export const CROPPING_SIGNIFICANT_CHANGE_EVENT = 'CROPPING_SIGNIFICANT_CHANGE_EVENT';

export const thumbnailCroppedImageSafetyValidations = {
    validationFn: async (val, options, dependencies = {}) => {
        const {
            accountId,
            campaignId = DEFAULT_CAMPAIGN_ID,
            src,
            isCroppedImageSafetyValidationEnabled,
        } = dependencies;

        if (!isCroppedImageSafetyValidationEnabled) {
            return true;
        }

        return getAutoReviewValidationResults({
            accountId,
            campaignId,
            fieldToValidate: FIELD_TYPES.THUMBNAIL_URL,
            item: {
                thumbnailUrl: src,
                id: DEFAULT_CREATIVE_ID,
                creativeCrop: { cropData: [singleRatioCropDataTransformer(val)] },
            },
            scope: 'WARNINGS_ONLY',
        });
    },
    severity: INDICATION_TYPES.WARNING,
    messageId: 'validations.thumbnail.image.safety.error',
    defaultMessage: 'This thumbnail may not be compliant with our policy, consider replacing it',
    events: [CROPPING_SIGNIFICANT_CHANGE_EVENT],
};

export const thumbnailAutoReviewValidation = {
    validationFn: async (val, options, dependencies = {}) => {
        const { accountId, campaignId = DEFAULT_CAMPAIGN_ID } = dependencies;

        return getAutoReviewValidationResults(
            {
                accountId,
                campaignId,
                fieldToValidate: FIELD_TYPES.THUMBNAIL_URL,
                item: {
                    thumbnailUrl: val,
                    id: DEFAULT_CREATIVE_ID,
                },
            },
            getContentThumbnailValidationError
        );
    },
    messageId: 'validations.thumbnail.auto.review.error',
    defaultMessage: 'Thumbnail Rejected - this thumbnail is not compliant with our policy, please change it',
};

export const thumbnailAutoReviewValidations = [thumbnailAutoReviewValidation, thumbnailImageSafetyValidation];
