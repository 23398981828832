import { useCallback } from 'react';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingSetupMethodTypeModal } from 'modules/tracking/hooks/useTrackingSetupMethodTypeModal';
import { TRACKING_ROUTE } from '../../../../../tracking/config';
import styles from './addTracking.module.css';

export const AddTrackingButton = ({
    id = 'campaign.marketing.objective.validations.tracking',
    defaultMessage = 'Tracking',
    openModal = true,
}) => {
    const { openTrackingSetupModal } = useTrackingSetupMethodTypeModal(true);
    const onClick = useCallback(() => {
        if (openModal) {
            openTrackingSetupModal();
            return;
        }

        window.open(TRACKING_ROUTE, '_blank');
    }, [openModal, openTrackingSetupModal]);
    return (
        <span
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value="add pixel"
            data-metrics-component="Pixel Warning"
            onClick={onClick}
            className={styles['add-tracking']}
        >
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </span>
    );
};
