import React, { useMemo } from 'react';
import { map, size } from 'lodash';
import { Button, Spinner } from 'tuui';
import { ExportIcon } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignGroupExtensionEnabled } from '../../../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { BULK_UPLOAD_GTM_PREFIX } from '../../../../consts/gtmConsts';
import { useEnabledExportableEntityConfigs } from '../../../../hooks/useEnabledExportableEntities';
import { CampaignGroupsSection } from '../CampaignGroupsSection/CampaignGroupsSection';
import { ExportableEntityCheckbox } from './ExportableEntityCheckbox';
import styles from './exportSelection.module.scss';

export const ExportSelection = ({ entityList, downloadTemplate, isDisabled, isLoading }) => {
    const enabledExportableEntityConfigs = useEnabledExportableEntityConfigs();
    const isCampaignGroupsFilterEnabled = useCampaignGroupExtensionEnabled();

    const joinedEntityNames = useMemo(
        () => map(enabledExportableEntityConfigs, 'entity').sort().join(','),
        [enabledExportableEntityConfigs]
    );

    return (
        <div className={styles['container']}>
            {entityList.map(({ sheetName, entity, disabled }) => (
                <ExportableEntityCheckbox
                    key={entity}
                    entity={entity}
                    sheetName={sheetName}
                    isDisabled={isDisabled || disabled}
                />
            ))}
            {isCampaignGroupsFilterEnabled && <CampaignGroupsSection />}
            <Button
                onClick={() => downloadTemplate(FORM_MODES.EDIT)}
                size={Button.size.md}
                disabled={isDisabled || size(enabledExportableEntityConfigs) === 0}
                variant={Button.variant.outline}
                data-metrics-component={`${BULK_UPLOAD_GTM_PREFIX}: Download Edit Template`}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={joinedEntityNames}
            >
                <ExportIcon fontSize="small" />
                <FormattedMessage id="excel.bulk.export.existing.data" defaultMessage="Export" />
                {isLoading && <Spinner size={14} />}
            </Button>
        </div>
    );
};
