import TARGETING_TYPES from '../../../config/TargetingTypes';
import DAYS_OF_WEEK from '../../../config/daysOfWeek';
import HOURS_OF_DAY from '../../../config/hoursOfDay';

const DAYPARTING_DEFAULT = [
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.MONDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.TUESDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.WEDNESDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.THURSDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.FRIDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.SATURDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
    {
        type: TARGETING_TYPES.INCLUDE,
        day: DAYS_OF_WEEK.SUNDAY,
        fromHour: HOURS_OF_DAY.ZERO,
        untilHour: HOURS_OF_DAY.TWENTY_FOUR,
    },
];

export default DAYPARTING_DEFAULT;
