"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgTablet(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 33,
            viewBox: "0 0 32 33",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M17 24.5C17 25.0523 16.5523 25.5 16 25.5C15.4477 25.5 15 25.0523 15 24.5C15 23.9477 15.4477 23.5 16 23.5C16.5523 23.5 17 23.9477 17 24.5Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M16 25.5C16.5523 25.5 17 25.0523 17 24.5C17 23.9477 16.5523 23.5 16 23.5C15.4477 23.5 15 23.9477 15 24.5C15 25.0523 15.4477 25.5 16 25.5Z",
            fill: "#212832"
        }),
        React.createElement("path", { d: "M8 21.5H24V22.5H8V21.5Z", fill: "#212832" }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M22 6.5H10C8.89543 6.5 8 7.39543 8 8.5V24.5C8 25.6046 8.89543 26.5 10 26.5H22C23.1046 26.5 24 25.6046 24 24.5V8.5C24 7.39543 23.1046 6.5 22 6.5ZM10 5.5C8.34315 5.5 7 6.84315 7 8.5V24.5C7 26.1569 8.34315 27.5 10 27.5H22C23.6569 27.5 25 26.1569 25 24.5V8.5C25 6.84315 23.6569 5.5 22 5.5H10Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgTablet;