import React from 'react';
import { CtaField } from '../../../../ContentField/ContentItem/fields';
import { AiContentBaseCard } from './AiContentBaseCard';
import styles from './aiContentCardCommon.module.scss';

export const AiContentCTACard = () => {
    return (
        <AiContentBaseCard>
            <CtaField className={styles['container']} labelClassName={styles['label']} />
        </AiContentBaseCard>
    );
};
