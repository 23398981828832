import { keyBy, uniq } from 'lodash';
import { getGraphTimeScale } from './getGraphTimeScale';

export const getDataWithFullPeriodScale = (data, keyName, options = {}) => {
    const { getScale = getGraphTimeScale } = options;
    const scaleKeys = getScale(options);
    const keys = uniq([...scaleKeys, ...data.map(({ [keyName]: key }) => key)]).sort();
    const dataMap = keyBy(data, keyName);
    const resolvedData = keys.reduce((acc, key) => {
        acc.push({ [keyName]: key, ...dataMap[key] });
        return acc;
    }, []);

    return resolvedData;
};
