'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keycodeJs = require('keycode-js');

var _useKeyHandler = require('./useKeyHandler');

var _useKeyHandler2 = _interopRequireDefault(_useKeyHandler);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var keyList = [_keycodeJs.KEY_ESCAPE];

var useEscapeHandler = function useEscapeHandler(onEscape, options) {
  return (0, _useKeyHandler2.default)(onEscape, keyList, options);
};

exports.default = useEscapeHandler;