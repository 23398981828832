import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { FunnelEditorHeader } from '../components/FunnelEditorHeader/FunnelEditorHeader';
import { FunnelSetup } from '../components/FunnelSetup/FunnelSetup';
import { FUNNEL_EDITOR_ROUTE_PATH } from '../config';
import { useUnipFunnelEditorState } from '../hooks/useFunnelEditorState';
import styles from './unipFunnelEditorPage.module.scss';

const FunnelEditorPage = ({ onCancel }) => {
    useUnsavedChangesController(FUNNEL_EDITOR_ROUTE_PATH);
    const { submit } = useUnipFunnelEditorState();

    const { isSubmitDisabled, submitInProgress, mode, fetchStatus } = useFormDataContext();

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<FunnelEditorHeader status={fetchStatus} headerClassName={styles['funnel-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['funnel-editor']}
                bodyContainerClassName={styles['funnel-editor-body']}
                footerContainerClassName={styles['funnel-editor-footer']}
            >
                <FunnelSetup />
            </FormLayout>
        </FormInitBase>
    );
};

const FunnelEditorWithFormDataProvider = withFormDataProvider(FunnelEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { FunnelEditorWithFormDataProvider as UnipFunnelEditorPage };
