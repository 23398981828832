import { useSelectedAccount } from '../../../../../hooks';
import { useSelectedCampaign } from '../../../hooks/useSelectedCampaign';

export const useRecommendationsQueryKey = () => {
    const {
        campaign: { id: campaignId },
    } = useSelectedCampaign();
    const [{ accountId }] = useSelectedAccount();
    return [accountId, campaignId, 'recommendations'];
};
