import { GALLERY_ITEM_DROPDOWN_OPTIONS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';

export const TRY_GEN_AI_TOOLTIP_PAGES = {
    REPLACE_BACKGROUND: 1,
    GENERATE_VARIATIONS: 2,
};
export const TRY_GEN_AI_TOOLTIP_PAGE_CONFIG = {
    [TRY_GEN_AI_TOOLTIP_PAGES.REPLACE_BACKGROUND]: {
        messageId: 'replace.background',
        videoPrefix: 'replaceBackground',
        gtmSuffix: 'REPLACE_BACKGROUND',
        tryItValue: GALLERY_ITEM_DROPDOWN_OPTIONS.REPLACE_BACKGROUND,
    },
    [TRY_GEN_AI_TOOLTIP_PAGES.GENERATE_VARIATIONS]: {
        messageId: 'image.variations',
        videoPrefix: 'imageVariations',
        gtmSuffix: 'IMAGE_VARIATIONS',
        tryItValue: GALLERY_ITEM_DROPDOWN_OPTIONS.IMAGE_VARIATIONS,
    },
};
export const GEN_AI_TOOLTIP_CLOSES_KEY = 'TRY_GEN_AI_TOOLTIP_TIMES';
