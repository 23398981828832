import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { flatMap, noop } from 'lodash';
import PropTypes from 'prop-types';
import { ButtonsGroup, useElementSize } from 'taboola-ultimate-ui';
import { ACCOUNT_ID, DIMENSION, useCurrentValueGetter } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, LOCALE, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { mergeQueryParamsWithLocation } from 'modules/taboola-common-frontend-modules/query-params';
import { BetaTag, InternalLink } from '../../../../../../components';
import { COMPONENT_STATUS } from '../../../../../../services/constants';
import { CAMPAIGN_ID } from '../../../../hooks';
import { setReportDataStatus } from '../../actions';
import { DATE_PRESET, END_DATE, REPORT_ID, START_DATE, useSelectedReportId } from '../../hooks';
import useAvailableReports from '../../hooks/useAvailableReports';
import styles from './reportsPicker.module.scss';

// offset to the right of the ButtonsGroup for responsiveness
const DEFAULT_OFFSET = 40;
const LOAD_TIME_OFFSET = 120;

const PARAMS_TO_KEEP = [START_DATE, END_DATE, DATE_PRESET, ACCOUNT_ID, DIMENSION, LOCALE, CAMPAIGN_ID];

const useMoreLabel = () => {
    const { formatMessage } = useIntl();
    const label = formatMessage({ id: 'app.campaigns.reports.picker.more', defaultMessage: 'More' });

    return label;
};

const CustomLinkRenderer = ({ className, children, value, label, onClick, selectedReportGetter, isDropdown }) => {
    const selectedReport = selectedReportGetter();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    if (value === ButtonsGroup.MORE_BUTTON_VALUE) {
        return (
            <div
                className={className}
                onClick={onClick}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="ReportPickerMore"
            >
                {label}
            </div>
        );
    }

    if (!value) {
        return null;
    }

    return (
        <InternalLink
            path={pathname}
            params={{ [REPORT_ID]: value }}
            className={className}
            paramsToKeep={PARAMS_TO_KEEP}
            onClick={event => {
                if (value === selectedReport) {
                    event.preventDefault();
                    if (isDropdown) {
                        onClick({ preventDefault: noop });
                    }
                    return;
                }
                dispatch(setReportDataStatus(COMPONENT_STATUS.INITIAL));
            }}
            aria-label={value === selectedReport ? `Selected report - ${value}` : ''}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="ReportPicker"
            data-metrics-value={value}
        >
            {children ? children : label}
        </InternalLink>
    );
};

// generate props that get destructured into the buttons for the ButtonsGroup
const buildButton = ({
    id: value,
    label,
    hasBetaLabel,
    options,
    selectedReportGetter,
    labelComponent: LabelComponent,
}) => ({
    value,
    customLinkRenderer: CustomLinkRenderer,
    selectedReportGetter,
    label: LabelComponent ? (
        <LabelComponent id={value} label={label} />
    ) : (
        <div className={styles['label']}>
            <FormattedMessage id={`app.campaigns.reports.picker.report.type.${value}`} defaultMessage={label} />
            {hasBetaLabel ? <BetaTag /> : null}
        </div>
    ),
    DropdownLabelBadge: hasBetaLabel && <BetaTag tagMessage="New" tooltipMessage={null} key="newTag" />,
    options: options && options.map(option => buildButton({ ...option, selectedReportGetter })),
    ...(options ? { buttonGroupDropdownContainerClassName: value } : {}),
});

const useResponsiveWidth = hasGridLoadTimePermissions => {
    const { width, ref } = useElementSize();
    const maxWidthOffset = hasGridLoadTimePermissions ? LOAD_TIME_OFFSET : DEFAULT_OFFSET;
    return {
        maxWidth: width - maxWidthOffset,
        containerRef: ref,
    };
};

export const ReportPicker = ({ selectedValue, reportDuration, reportConfigMap }) => {
    const moreLabel = useMoreLabel();
    const location = useLocation();
    const { availableReports } = useAvailableReports();
    const [selectedReport] = useSelectedReportId();
    const selectedReportGetter = useCurrentValueGetter(selectedReport);
    const buttonsList = useMemo(
        () =>
            availableReports.map(option =>
                buildButton({ ...option, selectedReportGetter, ...(reportConfigMap?.[option?.id] ?? []) })
            ),
        [availableReports, selectedReportGetter, reportConfigMap]
    );
    const selectedValueObject = useMemo(
        () =>
            flatMap(buttonsList, button => [button, ...(button.options || [])]).find(
                ({ value }) => value === selectedValue
            ),
        [buttonsList, selectedValue]
    );
    const hasGridLoadTimePermissions = usePermissions('SHOW_GRID_LOAD_TIME');
    const { maxWidth, containerRef } = useResponsiveWidth(hasGridLoadTimePermissions);
    const getHref = useCallback(
        value =>
            mergeQueryParamsWithLocation(location, {
                [REPORT_ID]: value,
            }),
        [location]
    );

    return (
        <div className={styles['container']} ref={containerRef}>
            <ButtonsGroup
                selectedValueObject={selectedValueObject}
                options={buttonsList}
                maxWidth={maxWidth}
                moreLabel={moreLabel}
                getHref={getHref}
                isScrollable
            />
            {hasGridLoadTimePermissions ? (
                <div className={styles['duration-container']}>
                    <FormattedMessage
                        id="app.campaigns.reports.report.duration.message"
                        defaultMessage="<strong>{reportDuration}s</strong> to load"
                        values={{ reportDuration }}
                    />
                </div>
            ) : null}
        </div>
    );
};

ReportPicker.propTypes = {
    selectedValue: PropTypes.string,
    reportDuration: PropTypes.number,
};
