import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, Overlay } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import ReloadButton from '../ReloadButton/ReloadButton';
import styles from './thumbnailOverlay.module.scss';

const ThumbnailOverlay = ({ onButtonClick, isReloadButton, onReloadClick, banner, ...rest }) => (
    <Overlay
        className={styles['container']}
        childrenContainerClassName={styles['button-container']}
        ariaLabel="thumbnail overlay"
    >
        <div className={styles['top-inner-container']} {...rest}>
            <StyledButton
                iconBefore="close"
                size={STYLED_BUTTON_SIZE.SMALL}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                onClick={onButtonClick}
                className={styles['remove-button']}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
            />
        </div>
        {banner}
        {isReloadButton && <ReloadButton className={styles['reload-button']} onButtonClick={onReloadClick} disabled />}
    </Overlay>
);

ThumbnailOverlay.propTypes = {
    onButtonClick: PropTypes.func,
    onReloadClick: PropTypes.func,
    children: PropTypes.node,
    isReloadButton: PropTypes.bool,
    banner: PropTypes.node,
};

ThumbnailOverlay.defaultProps = {
    onButtonClick: () => {},
};

export default ThumbnailOverlay;
