"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgVideoCampaign(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M15 10.1604L17.5248 8.79675C18.1911 8.4369 19 8.91939 19 9.67662V14.282C19 15.0487 18.1728 15.5302 17.5061 15.1516L15 13.7282V10.1604Z"
            }),
            React.createElement("path", {
                fill: "currentColor",
                d: "M5 9C5 8.44772 5.44772 8 6 8H13C13.5523 8 14 8.44772 14 9V15C14 15.5523 13.5523 16 13 16H6C5.44772 16 5 15.5523 5 15V9Z"
            })
        )
    );
}
exports.default = SvgVideoCampaign;