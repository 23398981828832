import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../../../taboola-common-frontend-modules/validations';
import CurrencyIcon from '../../../common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { DELIVERY_METHODS } from '../../../common-campaign-form/config/deliveryMethods';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: value => value >= 0,
        messageId: 'video.campaign.editor.dailyRevenueCap.error.whole.positive.number',
        defaultMessage: 'Daily Revenue Cap must be a positive number',
    },
]);

export const DailyRevenueCap = ({ currency }) => {
    const { value: DailyRevenueCap, onChange: onDailyRevenueCapChange } = useFormFieldValue({
        field: 'videoBudget.dailyRevenueCap',
    });

    const { value: deliveryMethod } = useFormFieldValue({
        field: 'videoBudget.deliveryMethod',
    });

    useEffect(() => {
        if (deliveryMethod === DELIVERY_METHODS.BALANCED) {
            onDailyRevenueCapChange('');
        }
    }, [deliveryMethod, onDailyRevenueCapChange]);

    return (
        <FormattedNumber value={0} minimumIntegerDigits={1}>
            {placeholder => (
                <InputWithValidations
                    id="videoBudget.dailyRevenueCap"
                    onChange={e => onDailyRevenueCapChange(e.target.value)}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    placeholder={placeholder}
                    value={DailyRevenueCap}
                    type={InputTypes.NUMBER}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="DailyRevenueCap"
                    disabled={deliveryMethod === DELIVERY_METHODS.BALANCED}
                />
            )}
        </FormattedNumber>
    );
};

DailyRevenueCap.propTypes = {
    currency: PropTypes.string,
};

export default DailyRevenueCap;
