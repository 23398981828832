import { map, keyBy } from 'lodash';

const getDefaultExportConfig = ({ entity }) => ({ entity, isEnabled: true, shouldFilterActive: true });

export const getExportExcelRequestMap = ({ enabledExportableEntityConfigs, apiCallerMap }) => {
    const requestMap = {};

    enabledExportableEntityConfigs.forEach(({ entity, shouldFilterActive }) => {
        const caller = apiCallerMap[entity];
        requestMap[entity] = caller({ shouldFilterActive });
    });

    return requestMap;
};

export const getDefaultExportableEntitiesState = entityList => {
    const exportableEntityConfigArray = map(entityList, getDefaultExportConfig);

    return keyBy(exportableEntityConfigArray, 'entity');
};
