import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const ImpressionTypeFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.pmpDeal.creator.impressionType.tooltip"
            defaultMessage="Video - allows an advertiser to leverage their preferred video asset (MP4 or MOV file, VPAID or VAST tag), applied in the buyer's DSP, to run across Taboola's high quality video placements.
                            Native video - allows an advertiser to leverage relevant copy- advertiser name, headline or long headline, and CTA- to run alongside their video asset (MP4 or MOV file, VAST tag),
                            applied in the buyer's DSP through a native integration, that will run across Taboola's high quality video placements."
        />
    </TooltipSection>
);

export default ImpressionTypeFieldTooltip;
