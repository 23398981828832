import { PAYMENT_ACTION } from './PaymentAction';
import { PAYMENT_TYPE } from './PaymentType';

const ADD_FUNDS_CONTEXT = {
    ADD_FUNDS_VIA_BANK_TRANSFER: 'ADD_FUNDS_VIA_BANK_TRANSFER',
    ADD_FUNDS_VIA_ADDITIONAL_FUNDS: 'ADD_FUNDS_VIA_ADDITIONAL_FUNDS',
};

const AddFundsContextToPaymentContext = {
    [ADD_FUNDS_CONTEXT.ADD_FUNDS_VIA_BANK_TRANSFER]: PAYMENT_TYPE.BANK_TRANSFER,
    [ADD_FUNDS_CONTEXT.ADD_FUNDS_VIA_ADDITIONAL_FUNDS]: PAYMENT_ACTION.CASH_BALANCE_REFUND,
};

export { ADD_FUNDS_CONTEXT, AddFundsContextToPaymentContext };
