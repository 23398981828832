import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FocalPointEditorWrapper as FocalPointEditor } from './FocalPoint';
import SelectedThumbnails from './SelectedThumbnails';

const defaultCreativeFocus = { type: 'AUTOMATIC', coordinates: null };

export const SelectedThumbnailsContainer = ({
    videoThumbnails,
    thumbnailList,
    onRemoveThumbnailById,
    className,
    onEditFallbackImageClick,
    isVideoSelected,
    setCreativeFocusInReducer,
    isEmpty,
    fallbackImageParentId,
    columnCount,
    isDragActive,
    onSelectMedia,
    cancelSelectFallbackImage,
    motionIdFallbackSelecting,
    openCropModal,
}) => {
    const [isEditingFocalPoint, setIsEditingFocalPoint] = useState(false);
    const [selectedImgSrc, setSelectedImgSrc] = useState({});
    const [creativeFocus, setCreativeFocus] = useState(defaultCreativeFocus);

    const startEditing = useCallback(() => setIsEditingFocalPoint(true), [setIsEditingFocalPoint]);
    const stopEditing = useCallback(() => setIsEditingFocalPoint(false), [setIsEditingFocalPoint]);

    return (
        <div>
            {isEditingFocalPoint ? (
                <FocalPointEditor
                    stopEditing={stopEditing}
                    selectedImgSrc={selectedImgSrc}
                    creativeFocus={creativeFocus}
                    setSelectedImgSrc={setSelectedImgSrc}
                    setCreativeFocus={setCreativeFocus}
                    setCreativeFocusInReducer={setCreativeFocusInReducer}
                />
            ) : (
                <SelectedThumbnails
                    id="selected-media"
                    videoThumbnails={videoThumbnails}
                    isVideoSelected={isVideoSelected}
                    onRemoveThumbnailById={onRemoveThumbnailById}
                    className={className}
                    onEditFallbackImageClick={onEditFallbackImageClick}
                    thumbnailList={thumbnailList}
                    isEmpty={isEmpty}
                    fallbackImageParentId={fallbackImageParentId}
                    startEditing={startEditing}
                    setSelectedImgSrc={setSelectedImgSrc}
                    setCreativeFocus={setCreativeFocus}
                    columnCount={columnCount}
                    isDragActive={isDragActive}
                    onSelectMedia={onSelectMedia}
                    cancelSelectFallbackImage={cancelSelectFallbackImage}
                    motionIdFallbackSelecting={motionIdFallbackSelecting}
                    openCropModal={openCropModal}
                />
            )}
        </div>
    );
};

SelectedThumbnails.propTypes = {
    thumbnails: PropTypes.array,
    isVideoSelected: PropTypes.bool,
    onEditFallbackImageClick: PropTypes.func,
    thumbnailList: PropTypes.array,
    onRemoveThumbnailById: PropTypes.func,
    fallbackImageParentId: PropTypes.string,
    className: PropTypes.string,
    setCreativeFocusInReducer: PropTypes.func,
    columnCount: PropTypes.number,
};

SelectedThumbnails.defaultProps = {
    videoThumbnails: [],
    onRemoveThumbnailById: () => {},
};

export default SelectedThumbnailsContainer;
