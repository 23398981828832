import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../../config';
import styles from './campaignItemTooltip.module.scss';

const resolveTooltipPosition = (showArrow, disabled, mode) => {
    if (showArrow && disabled) {
        return mode === FORM_MODES.CREATE ? TOOLTIP_POSITION.RIGHT : TOOLTIP_POSITION.TOP_END;
    }

    if (showArrow) {
        return TOOLTIP_POSITION.RIGHT;
    }

    return TOOLTIP_POSITION.TOP_START;
};

export const CampaignItemTooltip = ({ status, disabled, isPartOfSharedBudgetCampaignsGroup, arrow }) => {
    const { mode } = useFormDataContext();
    const tooltipOffset = mode === FORM_MODES.EDIT ? { offset: -10 } : {};
    const showArrow = arrow ?? (mode === FORM_MODES.CREATE || (mode === FORM_MODES.EDIT && disabled));
    const tooltipPosition = resolveTooltipPosition(showArrow, disabled, mode);

    return (
        <Tooltip position={tooltipPosition} arrow={showArrow} {...tooltipOffset} delay={0}>
            <div className={styles['status-label']}>
                <FormattedMessage
                    id="app.campaign.picker.drawer.status.tooltip"
                    defaultMessage="Status: {status}"
                    values={{
                        status: (
                            <FormattedMessage id={`app.campaigns.campaign.status.${status}`} defaultMessage={status} />
                        ),
                    }}
                />
            </div>
            {disabled ? (
                <div className={styles['disabled-label']}>
                    {isPartOfSharedBudgetCampaignsGroup ? (
                        <FormattedMessage
                            id="campaigns.group.editor.sharedBudgetCampaigns.alreadyInCampaignsGroup.disabled.tooltip"
                            defaultMessage="Campaign is already part of existing campaigns group."
                        />
                    ) : (
                        <FormattedMessage
                            id="campaigns.group.editor.sharedBudgetCampaigns.differentMarketingObjective.disabled.tooltip"
                            defaultMessage="Only campaigns with the same Marketing Objective can be selected. Depleted campaigns are also ineligible."
                        />
                    )}
                </div>
            ) : null}
        </Tooltip>
    );
};
