import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import addStaticIndication from '../../Indications/actions/addStaticIndication';
import removeStaticIndication from '../../Indications/actions/removeStaticIndication';
import { GTM_EVENTS, gtmTracker } from '../../gtmTracker';
import { FormattedMessage } from '../components/FormattedMessage';

const errorIndication = {
    message: (
        <FormattedMessage
            id="app.language.error.message"
            defaultMessage="It looks like you are using an extension or translation application that may cause Taboola Ads technical issues. We recommend disabling this extension for technical reasons."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="app.language.error.highlight" defaultMessage="Translation Application Detected" />,
};

const showLanguageErrorIndication = dispatch => {
    const targetLanguage = document.documentElement.lang;

    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        value: targetLanguage,
        component: 'useTranslateDetector',
    });
    dispatch(removeStaticIndication(errorIndication));
    dispatch(addStaticIndication(errorIndication));
};

export const useTextChangeTranslateDetector = (elementToWatch, expectedText) => {
    const dispatch = useDispatch();
    const observeLanguageChanges = useCallback(() => {
        if (!elementToWatch) {
            return;
        }

        const observer = new MutationObserver(() => {
            const innerText = elementToWatch.innerText;
            if (innerText !== expectedText) {
                showLanguageErrorIndication(dispatch);
            }
        });

        observer.observe(elementToWatch, { childList: true, characterData: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, [elementToWatch, expectedText, dispatch]);

    useEffect(observeLanguageChanges, [observeLanguageChanges]);
};
