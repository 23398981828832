import React from 'react';
import { useAvailableItems } from 'hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import { pricingModelConfig } from '../BidInputList/BidInputList';

const BidTooltip = ({ hasHighImpactPackage }) => {
    const { value: pricingModel, mode } = useFormFieldValue({
        field: 'pricingModel',
    });
    const { value: bidStrategy } = useBidStrategyFormField();
    const { formAccount: selectedAccount } = useFormDataContext();
    const availableTooltips = useAvailableItems(pricingModelConfig, {
        selectedAccount,
        hasHighImpactPackage,
        currentPricingModel: pricingModel,
        bidStrategy,
        formMode: mode,
    });

    return availableTooltips.map(({ id, Tooltip }) => <Tooltip key={id} />);
};

export default BidTooltip;
