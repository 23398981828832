import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual, isNil } from 'lodash';
import { CollapsibleList, FormField } from 'taboola-ultimate-ui';
import { SUPPLY_TARGETING_TYPES } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/const';
import { useSupplyTargetingFormField } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/hooks/useSupplyTargetingFormField';
import { CollapsibleListInput } from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/CollapsibleListInput';
import HelpTooltip from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/HelpTooltip';
import ListItem from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/ListItem';
import {
    TAG_TYPES,
    useThirdPartyTagsConfig,
} from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/config';
import { validateThirdPartyTag as validateThirdPartyTagFlow } from 'modules/campaigns/modules/common-campaign-form/flows';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import { VendorMacroReplacement } from './VendorMacroReplacement/VendorMacroReplacement';
import { useVendorMacroReplacement } from './VendorMacroReplacement/useVendorMacroReplacement';
import commonStyles from '../commonEditor.module.scss';

const CollapsibleListWithErrorIndication = withIndication(CollapsibleList);

const getUniqueTagFields = ({ type, tagValue, eventType }) => [type, tagValue, eventType];

const isEqualTag = (tag1, tag2) => isEqual(getUniqueTagFields(tag1), getUniqueTagFields(tag2));

export const isTagAdded = (newTag, list) => list.some(tag => isEqualTag(newTag, tag));

const removeTagFromList = (tagToRemove, list) => list.filter(tag => !isEqualTag(tag, tagToRemove));

const DEFAULT_TAGS = [];

const use3rdPartyTagsService = () => {
    const dispatch = useDispatch();
    const { getReplacements } = useVendorMacroReplacement();
    const {
        formAccount: { accountId: selectedAccountId },
    } = useFormDataContext();
    const validateThirdPartyTag = useCallback((...args) => dispatch(validateThirdPartyTagFlow(...args)), [dispatch]);
    return { selectedAccountId, validateThirdPartyTag, getVendorMacroReplacement: getReplacements };
};

const TAGS_CONFIRMATION_MODAL_MESSAGES = {
    title: (
        <FormattedMessage id="app.modal.thirdPartyTags.title" tagName="h2" defaultMessage="Submit 3rd Party Tracking" />
    ),
    formProps: {
        submitButtonText: (
            <FormattedMessage id="app.modal.thirdPartyTags.button.positive" defaultMessage="Yes, leave" />
        ),
        cancelButtonText: <FormattedMessage id="app.modal.thirdPartyTags.button.negative" defaultMessage="Stay here" />,
    },
    content: (
        <FormattedMessage
            id="app.modal.thirdPartyTags.content"
            defaultMessage="You have added 3rd party tracking tags but did not submit them.{newline}Click SUBMIT now to add the tags and start tracking."
        />
    ),
};

const supplyTargetingValidationOnThirdPartyTags = [
    {
        validationFn: (value, _, { supplyTargeting }) => {
            if (isEmpty(value)) return true;
            const viewabilityTags = value?.filter(tag => tag?.type !== TAG_TYPES.THIRD_PARTY_PIXEL);
            return supplyTargeting === SUPPLY_TARGETING_TYPES.APPLE_NEWS ? isEmpty(viewabilityTags) : true;
        },
        messageId: 'campaign.editor.targeting.supply.unsupported.viewability.tag.targeting',
        defaultMessage:
            'Please note: This campaign will not serve on Apple News as it does not support third-party JavaScript tags.',
    },
];

export const ThirdPartyTags = ({ label, helpText, listClassName = commonStyles['list'] }) => {
    const { MSG_ID_PREFIX, THIRD_PARTY_VALIDATIONS } = useThirdPartyTagsConfig();
    const { selectedAccountId, validateThirdPartyTag, getVendorMacroReplacement } = use3rdPartyTagsService();
    const isVendorMacroReplacementEnabled = useConfigMatch({
        [FEATURE_FLAGS.VENDOR_MACRO_REPLACEMENT_ENABLED]: 'true',
    });

    const [macroReplacements, setMacroReplacements] = useState([]);
    const [macroReplacedTagValue, setMacroReplacedTagValue] = useState('');
    const [originalTag, setOriginalTag] = useState({});
    const [macroReplacedTag, setMacroReplacedTag] = useState({});
    const [isMacrosFixed, setIsMacrosFixed] = useState(false);

    const resetState = () => {
        setMacroReplacements([]);
        setMacroReplacedTagValue('');
        setOriginalTag({});
        setMacroReplacedTag({});
        setIsMacrosFixed(false);
    };

    const {
        formAccount: { accountId: formAccountId },
    } = useFormDataContext();
    const { value: campaignAccountId } = useFormFieldValue({ field: 'accountName', isAbsolute: true });
    const isPermitted = usePermissions('CAMPAIGN_TRACKING_PIXELS_EDIT');

    const validations = [...THIRD_PARTY_VALIDATIONS, ...supplyTargetingValidationOnThirdPartyTags];
    const { value: supplyTargeting } = useSupplyTargetingFormField();
    const {
        value: tags,
        onChange: onChangeTags,
        scrollRef,
        indicationData,
    } = useFormValidatedValue({
        field: 'thirdPartyTags',
        validations,
        validationDependencies: { supplyTargeting },
        isPermitted,
    });
    const [isInputFilled, setIsInputFilled] = useState(false);

    const { setOptionalValidationNote } = useValidationContext();

    const { submitInProgress } = useFormDataContext;
    useEffect(() => {
        if (!tags?.length && isInputFilled) {
            setOptionalValidationNote('thirdPartyTags', TAGS_CONFIRMATION_MODAL_MESSAGES, true);
        } else {
            setOptionalValidationNote('thirdPartyTags');
        }
    }, [setOptionalValidationNote, tags, isInputFilled, submitInProgress]);

    const [tagsToValidate, setTagsToValidate] = useState([]);
    const sanitizedTags = tags || DEFAULT_TAGS;

    const allTags = useMemo(() => {
        const loadingTags = tagsToValidate.map(item => ({ loading: true, ...item }));

        return [...loadingTags, ...sanitizedTags].map((item, index) => ({ ...item, id: index }));
    }, [tagsToValidate, sanitizedTags]);

    const addTagToValidate = useCallback(
        async newTag => {
            resetState();
            if (isTagAdded(newTag, allTags)) {
                return;
            }

            setTagsToValidate(prevTags => [newTag, ...prevTags]);

            // Catch block isn't needed as validateThirdPartyTag did catch and converting the error
            // TODO Using async code within component causes memory leaks should be wrapped with fetching hook as soon as it added
            const error = await validateThirdPartyTag(campaignAccountId || formAccountId || selectedAccountId, newTag);
            const { id: errId, defaultMessage: errMsg } = error || {};
            const tagToAdd = errId || errMsg ? { ...newTag, error } : newTag;

            if (isVendorMacroReplacementEnabled) {
                const { replacedTagValue, macroReplacements } = await getVendorMacroReplacement(tagToAdd);
                if (!isEmpty(macroReplacements)) {
                    setMacroReplacements(macroReplacements);
                    setMacroReplacedTagValue(replacedTagValue);
                    setOriginalTag(tagToAdd);
                }
            }

            // there will be no validation for macro replaced tags
            setTagsToValidate(prevTags => removeTagFromList(newTag, prevTags));
            // but it will be added to the list
            onChangeTags([tagToAdd, ...sanitizedTags]);
        },
        [
            allTags,
            validateThirdPartyTag,
            campaignAccountId,
            formAccountId,
            selectedAccountId,
            isVendorMacroReplacementEnabled,
            onChangeTags,
            sanitizedTags,
            getVendorMacroReplacement,
        ]
    );

    const fixMacros = useCallback(() => {
        const tagToAdd = {
            ...originalTag,
            tagValue: { ...originalTag.tagValue, value: macroReplacedTagValue },
            macroReplacements,
        };
        setMacroReplacedTag(tagToAdd);
        onChangeTags([tagToAdd, ...removeTagFromList(originalTag, sanitizedTags)]);
        setIsMacrosFixed(true);
    }, [macroReplacedTagValue, macroReplacements, onChangeTags, originalTag, sanitizedTags]);

    const removeTag = useCallback(
        tagToRemove => {
            onChangeTags(removeTagFromList(tagToRemove, tags));
            if (isEqualTag(tagToRemove, isMacrosFixed ? macroReplacedTag : originalTag)) {
                resetState();
            }
        },
        [isMacrosFixed, macroReplacedTag, onChangeTags, originalTag, tags]
    );

    if (!isPermitted) {
        return null;
    }

    const formFieldLabel = label ? (
        label
    ) : (
        <FormattedMessage id={`${MSG_ID_PREFIX}.title`} defaultMessage="3rd Party Tags" />
    );
    const formFieldHelpText = isNil(helpText) ? null : <HelpTooltip />;
    return (
        <FormField
            ref={scrollRef}
            inputId="thirdPartyTags"
            containerClass={commonStyles['input']}
            label={formFieldLabel}
            description={
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.description`}
                    defaultMessage="Add 3rd party pixels or JavaScript tags to capture your campaign data and deliver it to other platforms."
                />
            }
            helpText={formFieldHelpText}
        >
            <div name="thirdPartyTags">
                <CollapsibleListInput validateAddedItem={addTagToValidate} setIsInputFilled={setIsInputFilled} />
                <CollapsibleListWithErrorIndication
                    items={allTags}
                    deleteItem={removeTag}
                    listHeaderTitle={
                        <FormattedMessage id={`${MSG_ID_PREFIX}.list.title`} defaultMessage="Added 3rd Party Tags" />
                    }
                    metadata={{ msgIdPrefix: MSG_ID_PREFIX }}
                    ItemComponent={ListItem}
                    containerClassName={listClassName}
                    {...indicationData}
                />
                {!indicationData.indicationType && isVendorMacroReplacementEnabled && (
                    <VendorMacroReplacement
                        macroReplacements={macroReplacements}
                        onClick={fixMacros}
                        isFixed={isMacrosFixed}
                    />
                )}
            </div>
        </FormField>
    );
};

export default ThirdPartyTags;
