import { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import { isEqual } from 'lodash';
import { useWizardContext } from 'taboola-ultimate-ui';
import { useValidationContext } from '../ValidationContext';
import { ValidationState } from '../services/validationService';

export function useValidationState() {
    const [, setRefId] = useState(0);
    const mountRef = useRef(true);
    const { validationService } = useValidationContext();
    const { setStep, step } = useWizardContext();

    const goto = useCallback(
        (metadata, fieldId) => {
            if (step === metadata.step) {
                return validationService.manuallyTriggerValidationFocus([fieldId]);
            }
            setStep(metadata.step);
            const unlisten = validationService.onRegistered(fieldId, () => {
                requestAnimationFrame(() => {
                    validationService.manuallyTriggerValidationFocus([fieldId]);
                });
                unlisten();
            });
        },
        [setStep, step, validationService]
    );

    const getCondensedValidationState = useCallback(() => {
        const condensedState = {};
        if (!validationService) {
            return condensedState;
        }
        for (let fieldId of validationService) {
            const {
                state = ValidationState.Unknown,
                result = null,
                metadata = {},
            } = validationService.getCurrentValidationState(fieldId) ?? {};
            condensedState[fieldId] = {
                state,
                fieldId,
                result,
                metadata,
                goto,
            };
        }
        return condensedState;
    }, [goto, validationService]);
    const validationStateRef = useRef({});

    useLayoutEffect(() => () => (mountRef.current = false), []);

    useEffect(() => {
        if (!validationService) {
            return;
        }
        validationStateRef.current = getCondensedValidationState();
        return validationService.onValidationStateUpdated(() => {
            if (!mountRef.current) {
                return;
            }
            const newState = getCondensedValidationState();
            const shouldUpdateState = !isEqual(validationStateRef.current, newState);

            validationStateRef.current = newState;

            if (shouldUpdateState) {
                setRefId(current => current + 1);
            }
        });
    }, [validationService, getCondensedValidationState]);

    return validationStateRef.current;
}
