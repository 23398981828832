import React, { useEffect, useMemo } from 'react';
import classnames from 'classnames/bind';
import { CollapsibleList, LinkButton } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ListWithMultilineInputBoxTopContent } from './ListWithMultilineBoxTopContent/ListWithMultilineInputBoxTopContent';
import { ListWithMultilineInputBoxFooter } from './ListWithMultilineInputBoxFooter/ListWithMultilineInputBoxFooter';
import { ListWithMultilineInputListItem } from './ListWithMultilineInputListItem/ListWithMultilineInputListItem';
import useListWithMultilineInputSelectorState from './hooks/useListWithMultilineInputSelectorState';
import styles from './listWithMultilineInput.module.scss';

const classNameBuilder = classnames.bind(styles);

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const EMPTY_VALIDATIONS = [];

export const ListWithMultilineInput = ({
    typeField,
    valueField,
    fetchValidItems,
    validateInput,
    listHeader,
    topContentPlaceholder,
    footerErrorMessage,
    footerButtonMessage,
    validations = EMPTY_VALIDATIONS,
    itemValidations = EMPTY_VALIDATIONS,
    hasInitialData,
    dataTestId,
    submitBtnMetricsAttributes,
}) => {
    const {
        selectedValues,
        invalidSelectedValuesSet,
        invalidInputValues,
        inputValues,
        text,
        initResources,
        resourcesById,
        failedValidationData: { message, indicationType, ...failedValidationData },
        scrollRef,
        isLoading,
        handleInputChange,
        handleDeleteItem,
        handleClearItems,
        handleClearInvalidItems,
        handleAddItems,
    } = useListWithMultilineInputSelectorState({
        valueField,
        fetchValidItems,
        validateInput,
        validations,
        hasInitialData,
    });

    const hasInvalidValues = invalidInputValues.length > 0;

    const messageWithRemoveIndication = useMemo(() => {
        if (!message || isLoading) {
            return null;
        }

        return (
            <div className={classNameBuilder('error-container', indicationType)}>
                {message}
                {invalidSelectedValuesSet.size > 0 && (
                    <LinkButton onClick={handleClearInvalidItems} className={styles['clear-invalid']}>
                        <FormattedMessage
                            id="campaign.editor.geo.targeting.zipCodes.clearUnavailable"
                            defaultMessage="Click here to remove {count} unavailable postal code{count, plural, one {} other {s} }."
                            values={{ count: invalidSelectedValuesSet.size }}
                        />
                    </LinkButton>
                )}
            </div>
        );
    }, [indicationType, invalidSelectedValuesSet.size, isLoading, message, handleClearInvalidItems]);

    useEffect(() => {
        initResources();
    }, [initResources]);

    return (
        <>
            <div className={styles['container']} ref={scrollRef} data-testid={dataTestId}>
                <ListWithMultilineInputBoxTopContent
                    typeField={typeField}
                    requestedIdsText={text}
                    onRequestedIdsChange={handleInputChange}
                    error={hasInvalidValues}
                    placeholder={topContentPlaceholder}
                />
                <div className={styles['horizontal-delimiter']} />
                <ListWithMultilineInputBoxFooter
                    error={hasInvalidValues}
                    selectedIdsCount={inputValues.length}
                    onSubmit={handleAddItems}
                    footerErrorMessage={footerErrorMessage}
                    footerButtonMessage={footerButtonMessage}
                    submitBtnMetricsAttributes={submitBtnMetricsAttributes}
                />
            </div>
            <CollapsibleListWithIndication
                id={valueField}
                items={selectedValues}
                itemHeight={45}
                ItemComponent={ListWithMultilineInputListItem}
                deleteItem={handleDeleteItem}
                clearItems={handleClearItems}
                listHeaderTitle={listHeader}
                containerClassName={styles['list-container']}
                metadata={{ valueField, typeField, resourcesById, itemValidations }}
                message={messageWithRemoveIndication}
                indicationType={indicationType}
                {...failedValidationData}
            />
        </>
    );
};

export default ListWithMultilineInput;
