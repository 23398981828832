const PULL_PAYMENT_TYPES = {
    CARD: 'CARD',
    ACH_BANK_DIRECT_DEBIT: 'ACH_BANK_DIRECT_DEBIT',
};

const PUSH_PAYMENT_TYPES = {
    HOSTED_INVOICE: 'HOSTED_INVOICE',
    BANK_TRANSFER: 'BANK_TRANSFER',
};

const PAYMENT_TYPE = { ...PULL_PAYMENT_TYPES, ...PUSH_PAYMENT_TYPES };

const isCreditCard = paymentType => PAYMENT_TYPE.CARD === paymentType;

const isHostedInvoice = paymentType => PAYMENT_TYPE.HOSTED_INVOICE === paymentType;

const isBankTransfer = paymentType => PAYMENT_TYPE.BANK_TRANSFER === paymentType;

const isAchBankDirectDebit = paymentType => PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT === paymentType;

const isPushPaymentType = paymentType => Object.keys(PUSH_PAYMENT_TYPES).includes(paymentType);

const isPullPaymentType = paymentType => Object.keys(PULL_PAYMENT_TYPES).includes(paymentType);

export {
    PULL_PAYMENT_TYPES,
    PUSH_PAYMENT_TYPES,
    PAYMENT_TYPE,
    isCreditCard,
    isHostedInvoice,
    isBankTransfer,
    isAchBankDirectDebit,
    isPullPaymentType,
    isPushPaymentType,
};
