import { getPageName } from '../../gtmTracker/urlPageNameUtil';

export const getNotificationMessage = message => {
    if (typeof message === 'string') {
        return message;
    }
    const values = JSON.stringify(message.props?.values) ?? '';
    return `${message.props?.defaultMessage}(${message.props?.id})${values}`;
};

const createEventViaIndication = (notificationType, indication) => {
    const { type: notificationStatus, message } = indication;
    const notificationMessage = getNotificationMessage(message);

    return {
        pageName: getPageName(),
        notificationType,
        notificationStatus,
        notificationMessage,
    };
};

export default createEventViaIndication;
