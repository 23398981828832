import React from 'react';

const InvalidGalleryItemSVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="56" viewBox="0 0 80 56">
        <g fill="none" fillRule="evenodd">
            <path fill="#EEF0F2" d="M11 7h57a4 4 0 0 1 4 4v33a4 4 0 0 1-4 4H11a4 4 0 0 1-4-4V11a4 4 0 0 1 4-4z" />
            <path
                fill="#B8C1CA"
                fillRule="nonzero"
                d="M 6 0 L 74 0 C 77.314 0 80 2.686 80 6 L 80 49.993 C 80 53.307 77.314 55.993 74 55.993 L 6 55.993 C 2.686 55.993 0 53.307 0 49.993 L 0 6 C 0 2.686 2.686 0 6 0 Z M 6 2 C 3.791 2 2 3.791 2 6 L 2 49.993 C 2 52.202 3.791 53.993 6 53.993 L 74 53.993 C 76.209 53.993 78 52.202 78 49.993 L 78 6 C 78 3.791 76.209 2 74 2 L 6 2 Z M 30 18 C 30 22.619 25 25.506 21 23.196 C 19.144 22.124 18 20.144 18 18 C 18 13.381 23 10.494 27 12.804 C 28.856 13.876 30 15.856 30 18 Z M 33.949 34.38 L 50.142 18.155 C 51.68 16.622 54.169 16.622 55.707 18.155 L 72.016 34.495 C 72.023 36.239 72.022 39.403 72.011 43.988 C 72.005 46.193 70.216 47.978 68.011 47.978 L 47.979 47.978 L 33.949 34.38 Z M 7.077 43.108 L 20.665 30.07 C 22.081 28.712 24.393 28.717 25.802 30.083 L 31.196 35.313 L 44.264 47.977 L 11.001 47.977 C 8.792 47.942 7.029 46.123 7.064 43.914 L 7.077 43.107 L 7.077 43.108 Z"
            />
        </g>
    </svg>
);

export default InvalidGalleryItemSVGIcon;
