'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Icons = require('../Icons/Icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by oreuveni
 * Date: 22/08/2018
 * Time: 14:20
 */
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'section__taboola-svg-icon___33l17',
    'section': 'section__section___AMqYO',
    'open': 'section__open___2_G4u',
    'section-content': 'section__section-content___xieqD',
    'arrow-icon': 'section__arrow-icon___3xeIJ',
    'section-header': 'section__section-header___QvkcM',
    'with-description': 'section__with-description___1sS5L',
    'collapsible': 'section__collapsible___3pcbY',
    'section-title': 'section__section-title___OXHJT',
    'description': 'section__description___3b8be'
};


var classNameBuilder = _bind2.default.bind(styles);

/**
 * @deprecated - use tuui Spinner
 */
var Section = (0, _react.forwardRef)(function (props, ref) {
    var children = props.children,
        title = props.title,
        isOpen = props.isOpen,
        name = props.name,
        onCollapseToggled = props.onCollapseToggled,
        maxHeight = props.maxHeight,
        className = props.className,
        sectionHeaderClassName = props.sectionHeaderClassName,
        collapsible = props.collapsible,
        description = props.description;

    var collapsibleMaxHeight = isOpen ? { maxHeight: maxHeight } : null;
    return _react2.default.createElement(
        'div',
        {
            className: classNameBuilder('section', className, { open: isOpen }),
            ref: ref
        },
        _react2.default.createElement(
            'div',
            {
                role: 'button',
                tabIndex: collapsible ? 0 : null,
                className: classNameBuilder('section-header', sectionHeaderClassName, { collapsible: collapsible, 'with-description': !!description }),
                onClick: collapsible ? function () {
                    return onCollapseToggled(name);
                } : null
            },
            _react2.default.createElement(
                'div',
                { className: classNameBuilder('section-title') },
                typeof title === 'string' ? _react2.default.createElement(
                    'h4',
                    null,
                    title
                ) : title
            ),
            collapsible && _react2.default.createElement(_Icons.LeftIcon, { className: classNameBuilder('arrow-icon') })
        ),
        description && _react2.default.createElement(
            'div',
            { className: classNameBuilder('description') },
            description
        ),
        _react2.default.createElement(
            'div',
            {
                className: classNameBuilder('section-content'),
                style: collapsible ? collapsibleMaxHeight : { maxHeight: maxHeight }
            },
            children
        )
    );
});

Section.propTypes = {
    /** Content for the section */
    children: _propTypes2.default.node,
    /** Title of the section */
    title: _propTypes2.default.node,
    /** Title of the section */
    description: _propTypes2.default.node,
    /** Fires when section is opened or close - receives a boolean for close/open */
    onCollapseToggled: _propTypes2.default.func,
    /** For controlled component */
    isOpen: _propTypes2.default.bool,
    /** For section identification */
    name: _propTypes2.default.string,
    /** Section max height if needs to be more than 1000px defaults to none */
    maxHeight: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    /** ClassName prop */
    className: _propTypes2.default.string,
    /** Section header ClassName prop */
    sectionHeaderClassName: _propTypes2.default.string,
    /** Activates collapse functionality */
    collapsible: _propTypes2.default.bool
};

Section.defaultProps = {
    collapsible: false,
    isOpen: true,
    maxHeight: 'none',
    onCollapseToggled: function onCollapseToggled() {}
};

exports.default = Section;