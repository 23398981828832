import { useMemo } from 'react';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext, useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { useMarketplaceAudienceTargetingValues } from '../../MarketplaceAudiences/hooks/useMarketplaceAudienceTargetingValues';
import { MAX_SEGMENTS_TO_SELECT } from '../../Tree';
import {
    excludeErrorListValidations,
    excludeWarningListValidations,
    includeErrorListValidations,
    includeWarningListValidations,
} from '../customContextualSegmentsValidations';

export const useCustomContextualSegmentsValidation = ({
    allSegmentsByIdMap,
    isOptionsLoadedSuccessfully,
    isSegmentsByIdsLoadedSuccessfully,
}) => {
    const { mode } = useFormDataContext();

    const { includeLength: marketPlaceAudienceValuesLength } = useMarketplaceAudienceTargetingValues();
    const {
        value: { length: myAudienceIncludeValuesLength },
    } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
    });

    const {
        value: { length: contextualSegmentsValuesLength },
    } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting.include',
    });

    const {
        value: { length: customContextualSegmentsValuesLength },
    } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.include',
    });

    const warningValidationDependencies = useMemo(() => {
        return {
            data: {
                myAudienceIncludeValuesLength,
                marketPlaceAudienceValuesLength,
                contextualSegmentsValuesLength,
                customContextualSegmentsValuesLength,
                fieldLength: customContextualSegmentsValuesLength,
            },
        };
    }, [
        myAudienceIncludeValuesLength,
        marketPlaceAudienceValuesLength,
        contextualSegmentsValuesLength,
        customContextualSegmentsValuesLength,
    ]);

    const errorValidationDependencies = useMemo(() => {
        return {
            limit: MAX_SEGMENTS_TO_SELECT,
            allSegmentsIdMap: allSegmentsByIdMap,
        };
    }, [allSegmentsByIdMap]);

    const validationDependencies = useMemo(
        () => ({ ...errorValidationDependencies, ...warningValidationDependencies }),
        [errorValidationDependencies, warningValidationDependencies]
    );

    const includeValidations = useMemo(() => [...includeErrorListValidations, ...includeWarningListValidations], []);

    const isReady = useMemo(
        () =>
            mode === FORM_MODES.CREATE
                ? isOptionsLoadedSuccessfully
                : isOptionsLoadedSuccessfully && isSegmentsByIdsLoadedSuccessfully,
        [isOptionsLoadedSuccessfully, isSegmentsByIdsLoadedSuccessfully, mode]
    );

    const { failedValidationData: includeFailedValidationData } = useFormValidatedValue({
        field: 'campaignTargeting.customContextualTargeting.include',
        validations: includeValidations,
        validationDependencies,
        isReady,
    });

    const excludeValidations = useMemo(() => [...excludeErrorListValidations, ...excludeWarningListValidations], []);
    const { failedValidationData: excludeFailedValidationData } = useFormValidatedValue({
        field: 'campaignTargeting.customContextualTargeting.exclude',
        validations: excludeValidations,
        validationDependencies,
        isReady,
    });

    return {
        includeFailedValidationData,
        excludeFailedValidationData,
    };
};
