'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styleUtils = require('../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'borderColor': 'var(--gray-4)',
    'taboola-svg-icon': 'divider__taboola-svg-icon___2AjAM',
    'divider': 'divider__divider___1rp0u'
};


var Divider = function Divider(_ref) {
    var className = _ref.className,
        color = _ref.color,
        thickness = _ref.thickness;
    return _react2.default.createElement('div', { className: styles['divider'] + ' ' + (className || ''), style: { borderColor: color, borderWidth: (0, _styleUtils2.default)(thickness) } });
};

Divider.propTypes = {
    className: _propTypes2.default.string,
    /** Divider's color */
    color: _propTypes2.default.string,
    /** Divider's thickness */
    thickness: _propTypes2.default.number
};

Divider.defaultProps = {
    color: styles.borderColor,
    thickness: 1
};

exports.default = Divider;