import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { dynamicKeywordInsertionRegEx, whitelistedEmojiRegex } from 'services/utils/regExUtils';
import { isEmojiInString } from 'services/utils/stringUtils';

const maxCreativeTitleLength = 180;

const videoCreativeDescriptionValidations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: maxCreativeTitleLength },
        messageId: 'video.validations.error.creative.description.length',
    },
    {
        validationFn: description => !description.match(dynamicKeywordInsertionRegEx),
        messageId: 'validations.error.field.description.dynamicKeywordInjection',
        defaultMessage: 'Dynamic Keyword Insertion is not supported for ad descriptions.',
    },
    {
        validationFn: description => !isEmojiInString(description, whitelistedEmojiRegex),
        messageId: 'validations.error.field.description.emoji',
        defaultMessage: 'Emojis are not supported for ad description.',
    },
];

export { videoCreativeDescriptionValidations };
