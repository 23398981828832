import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { useWochit } from 'modules/campaigns/modules/creative-creator/services/wochit';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useMotionStudioStatus = () => {
    // default object used to prevent crashes if this hook is used somewhere wochit is not activated
    const { wochitSDK, wochitAccessToken } = useWochit() || {};
    const isWochitAccessTokenInvalid = wochitAccessToken == null || wochitAccessToken.length === 0 || !wochitSDK;
    const isCreateVideoViaWochitEnable = useConfigMatch({
        [FEATURE_FLAGS.WOCHIT_CREATE_VIDEO_ENABLED]: 'true',
    });
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const isModeLockedForMotionAdsStudio =
        creativeType && creativeType !== CREATIVE_TYPE.MOTION && creativeType !== CREATIVE_TYPE.VIDEO;

    const isMotionStudioEnabled =
        isCreateVideoViaWochitEnable && (isUnifiedCreativesFormDuplicateMode || !isModeLockedForMotionAdsStudio);

    return {
        isMotionStudioEnabled,
        isWochitAccessTokenInvalid,
    };
};
