import React from 'react';
import PropTypes from 'prop-types';
import { PreviewGalleryItem, FileTypes } from 'taboola-ultimate-ui';
import { isFileTypeImage } from '../../../services/utils';
import ThumbnailOverlay from '../../ThumbnailOverlay/ThumbnailOverlay';

const renderOverlay = ({ url, id, fileType = FileTypes.IMAGE }, onToggleStatus) => {
    if (!isFileTypeImage(fileType)) {
        return null;
    }
    return () => (
        <ThumbnailOverlay
            onButtonClick={() => onToggleStatus(url, id)}
            data-metrics-component="RemovePreviewItemButton"
        />
    );
};

const CreativePreviewGalleryItem = ({ previewWidget, onToggleStatus, brandingText }) => {
    const { id, url, deleted } = previewWidget;
    if (deleted) {
        return null;
    }

    return (
        <PreviewGalleryItem
            previewWidget={previewWidget}
            branding={brandingText}
            renderOverlayComponent={renderOverlay(previewWidget, onToggleStatus)}
            onRemovePreviewWidget={() => onToggleStatus(url, id)}
        />
    );
};

CreativePreviewGalleryItem.propTypes = {
    previewWidget: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        deleted: PropTypes.bool,
        fileType: PropTypes.string,
    }),
    onToggleStatus: PropTypes.func,
    brandingText: PropTypes.string,
};

export default CreativePreviewGalleryItem;
