import React from 'react';
import PropTypes from 'prop-types';
import { CampaignEditorContent } from 'modules/campaigns/modules/common-campaign-form';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import FormLayout from '../../components/Form/FormLayout/FormLayout';
import CampaignEditorFormBase from '../common-campaign-form/CampaignEditorFormBase';
import { campaignDuplicateValues } from '../common-campaign-form/config';

const SponsoredCampaignEditor = ({ onCancel }) => {
    return <CampaignEditorFormBase onCancel={onCancel} ContentComponent={CampaignEditorContent} />;
};

SponsoredCampaignEditor.propTypes = { onCancel: PropTypes.func };

const SponsoredCampaignEditorWithFormDataProvider = withFormDataProvider(SponsoredCampaignEditor, {
    defaultValuesConfig: campaignDuplicateValues,
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { SponsoredCampaignEditorWithFormDataProvider as SponsoredCampaignEditor };
