import { stubFalse } from 'lodash';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { AVERAGE_CPC, CONVERSIONS, CVR, SITE_ID } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import { siteReportColumnDefinition } from './columnDefinitions';
import { siteReportFooterColumnDefinition } from './footerColumnDefinitions';
import { graphConfigs } from './graph';
import { performanceColumns, customColumns, defaultColumns } from './reportPresets';

export const siteRealtimeReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.SITE_REALTIME],
    endpoint: 'campaign-realtime-report-by-site',
    graph: graphConfigs,
    rowIdField: SITE_ID.field,
    columnsDef: siteReportColumnDefinition,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: siteReportFooterColumnDefinition,
    inlineEditPermissions: ['CAMPAIGN_EDIT'],
    rowHeight: 42,
    sortMap: {
        [CVR.field]: 'cpaConversionRate',
        [AVERAGE_CPC.field]: 'cpc',
        [CONVERSIONS.field]: 'cpaActionsNum',
    },
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.PLATFORM,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.COUNTRY,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.SITE_ID,
            multiSelect: true,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
            // This is made to hide temporary this filter
            visibilityCondition: stubFalse,
        },
    ],
};
