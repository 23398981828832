import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './section.module.scss';

export const Section = ({
    className,
    id,
    defaultMessage,
    tooltip,
    notification,
    loading,
    loaderWidth,
    testId,
    ariaLabel,
}) => {
    const content = loading ? (
        <ContentLoader speed={2} height={10} width={loaderWidth} className={styles['placeholder']} />
    ) : (
        <>
            {id ? <FormattedMessage id={id} defaultMessage={defaultMessage} /> : <span>{defaultMessage}</span>}
            {tooltip}
        </>
    );

    return (
        <>
            <div data-testid={testId} className={classnames(styles['label'], className)} aria-label={ariaLabel}>
                {content}
            </div>
            {notification}
        </>
    );
};

Section.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    tooltip: PropTypes.node,
    loading: PropTypes.bool,
    loaderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    notification: PropTypes.node,
};

export default Section;
