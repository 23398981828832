import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormDataContext } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './s2SSreverSide.module.scss';

export const S2SSreverSide = () => {
    const {
        formAccount: { id },
    } = useFormDataContext();
    return (
        <FormField
            label={<FormattedMessage id="tracking.setup.s2s.server.side.header" defaultMessage="Server Side" />}
            description={
                <FormattedMessage
                    id="tracking.setup.s2s.server.side.description"
                    defaultMessage="Make sure that the Taboola module is correctly integrated with the platform that you are using. For a detailed step-by-step guide, please see our <a>help center</a>.{newline}<b>Account ID: {id}</b>"
                    values={{ id }}
                />
            }
            descriptionClass={styles['text']}
        />
    );
};
