import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const categoryReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CATEGORY],
    endpoint: 'campaign-report-by-category',
    rowIdField: FIELDS.CATEGORY.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    searchPlaceholderMsgId: 'app.reports.byCategory.search.placeholder',
    searchField: FIELDS.CATEGORY.field,
};

export default categoryReportConfig;
