import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useCurrentValueGetter } from 'hooks';
import { accountsMapSelector } from '../../../../selectors/baseSelectors';
import { useSelectedCampaignId } from '../../../campaigns/hooks';
import { userSelector } from '../../authentication';
import { getUrl, getContext } from '../utils/chatAgentContextService';
import { useContextAccountsData } from './useContextAccountsData';

export const useChatAgentContextService = () => {
    const { user_id } = useSelector(userSelector);
    const accountMap = useSelector(accountsMapSelector);
    const accountMapGetter = useCurrentValueGetter(accountMap);
    const location = useLocation();
    const locationGetter = useCurrentValueGetter(location);
    const urlGetter = useCallback(context => getUrl(context, accountMapGetter()), [accountMapGetter]);
    const userIdGetter = useCurrentValueGetter(user_id);
    const getAccountsData = useContextAccountsData();
    const [campaignId] = useSelectedCampaignId();
    const campaignsIdGetter = useCurrentValueGetter(campaignId);

    const contextGetter = useCallback(
        async event => {
            const { accountName, globalAccountName } = await getAccountsData();
            const { pathname, search } = locationGetter();
            const resolvedUrl = `${pathname}${search}`;

            return getContext({
                url: resolvedUrl,
                userId: userIdGetter(),
                accountMap: accountMapGetter(),
                accountName: event?.event?.contextAccountName ?? accountName,
                globalAccountName,
                campaignId: campaignsIdGetter(),
            });
        },
        [locationGetter, userIdGetter, accountMapGetter, getAccountsData, campaignsIdGetter]
    );

    return { getContext: contextGetter, getUrl: urlGetter };
};
