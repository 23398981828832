'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TextCheckbox = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _BaseCheckbox = require('../BaseCheckbox');

var _BaseCheckbox2 = _interopRequireDefault(_BaseCheckbox);

var _Tooltips = require('../../Tooltips');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'help-tooltip': 'textCheckbox__help-tooltip___1_yqD',
    'label': 'textCheckbox__label___25K1f'
};


var classNameBuilder = _bind2.default.bind(styles);

var TextCheckbox = function TextCheckbox(_ref) {
    var title = _ref.title,
        labelClassName = _ref.labelClassName,
        helpText = _ref.helpText,
        rest = _objectWithoutProperties(_ref, ['title', 'labelClassName', 'helpText']);

    var labelMergedClassName = classNameBuilder('label', labelClassName);

    return _react2.default.createElement(
        _BaseCheckbox2.default,
        Object.assign({}, rest, { labelClassName: labelMergedClassName }),
        title,
        helpText && _react2.default.createElement(
            _Tooltips.HelpTooltip,
            { iconClassName: styles['help-tooltip'] },
            helpText
        )
    );
};

exports.TextCheckbox = TextCheckbox;
TextCheckbox.propTypes = Object.assign({
    /** Radio title */
    title: _propTypes2.default.node,
    /** Apply class to style the checkbox label */
    labelClassName: _propTypes2.default.string,
    /** help tooltip text */
    helpText: _propTypes2.default.node
}, _BaseCheckbox2.default.propTypes);

TextCheckbox.defaultProps = {
    title: '',
    labelClassName: '',
    helpText: ''
};

exports.default = TextCheckbox;