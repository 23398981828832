import { AlertOIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { useRejectedAudiencesIndication } from '../../hooks/useRejectedAudiencesIndication';
import styles from '../NavigationItemContent/audiencesNavigationItemContent.module.scss';

export const CustomAudiencesReportLabel = ({ id, label }) => {
    let { hasRejectedAudiences } = useRejectedAudiencesIndication();
    return (
        <div className={styles['label']}>
            <FormattedMessage id={`app.campaigns.reports.picker.report.type.${id}`} defaultMessage={label} />
            {hasRejectedAudiences && <AlertOIcon data-testid="alert-icon" className={styles[`warning-icon`]} />}
        </div>
    );
};
