import React from 'react';
import { TrackingSectionIcon, INDICATION_TYPES } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import ThirdPartyTags, {
    ThirdPartyConfigOverrides,
    VALUE_TYPES,
} from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './trackingSection.module.scss';

const thirdPartyOverrides = ({ THIRD_PARTY_VALIDATIONS }) => ({
    TAG_TYPE_TOOLTIP_POSITION: 'top-start',
    MSG_ID_PREFIX: 'creative.editor.thirdPartyTags',
    THIRD_PARTY_VALIDATIONS: [
        ...THIRD_PARTY_VALIDATIONS,
        {
            validationFn: tags => {
                if (!tags) {
                    return true;
                }
                return !tags.some(({ tagValue }) => tagValue.type === VALUE_TYPES.HTML);
            },
            severity: INDICATION_TYPES.WARNING,
            messageId: 'creative.editor.thirdPartyTags.warning',
            defaultMessage:
                "Note: There are industry limitations for acceptance of Javascript tags within native placements. When applying 3rd party javascript tags, your campaign's reach will be impacted.",
        },
    ],
});

export const TrackingSection = () => (
    <>
        <CommonCollapsibleCard
            id="CREATIVE_TRACKING"
            header={
                <SectionHeader
                    headerIcon={<TrackingSectionIcon />}
                    headerText={<FormattedMessage id="creative.editor.tracking" defaultMessage="Tracking" />}
                />
            }
        >
            <ThirdPartyConfigOverrides overrides={thirdPartyOverrides}>
                <ThirdPartyTags />
            </ThirdPartyConfigOverrides>
        </CommonCollapsibleCard>
        <div className={styles['placeholder-whitespace']} />
    </>
);
