import { createContext, useCallback, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { useAccount } from '../../account-configurations/hooks/useAccount';

export const AppEventsContext = createContext({ register: noop, push: noop });

export const useAppEventContext = () => {
    const contextValue = useContext(AppEventsContext);
    const account = useAccount();
    const accountGetter = useCurrentValueGetter(account);
    const push = useCallback(
        event =>
            contextValue.push({
                ...event,
                contextAccountName: accountGetter()?.accountName,
            }),
        [contextValue, accountGetter]
    );
    const wrappedValue = useMemo(() => ({ ...contextValue, push }), [contextValue, push]);

    return wrappedValue;
};
