import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import { useUserPermissionOrFeatureFlagEnabled } from '../../../taboola-common-frontend-modules/./account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';
import { FEATURE_FLAGS } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { CRM_TARGETING_TYPE } from '../../config';
import AudienceDescriptionField from '../AudienceDescriptionField/AudienceDescriptionField';
import BusinessOfExtendingCreditField from '../BusinessOfExtendingCreditField/BusinessOfExtendingCreditField';
import CreateAdditionalAudienceFromFileField from '../CreateAdditionalAudienceFromFileField/CreateAdditionalAudienceFromFileField';
import ExcludeAudienceField from '../ExcludeAudienceField/ExcludeAudienceField';

export const FirstPartyAudiencePropertiesSection = () => {
    const { mode, value: crmTargetingType } = useFormFieldValue({ field: 'crmTargetingType' });
    const createLookalikeAllowed = useUserPermissionOrFeatureFlagEnabled(
        'CREATE_LOOKALIKE_ALLOWED',
        FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED
    );
    const createLookalikeAllowedNew = useUserPermissionOrFeatureFlagEnabled(
        'CREATE_LOOKALIKE_ALLOWED_NEW',
        FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED_NEW
    );

    return (
        <CommonCollapsibleCard
            id="FIRST_PARTY_AUDIENCE_PROPERTIES"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.properties.section.title"
                            defaultMessage="Audience Properties"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && createLookalikeAllowed && createLookalikeAllowedNew && (
                <CreateAdditionalAudienceFromFileField
                    msgId="audience.editor.first.party.audience.setup.checkbox.label"
                    defaultMessage="Create an additional predictive audience from file"
                    checkedValue={CRM_TARGETING_TYPE.ALL}
                    uncheckedValue={CRM_TARGETING_TYPE.TARGETING_SUPPRESSION}
                    headerMsgId="audience.editor.first.party.audience.setup.checkbox.header"
                    headerDefaultMessage="Additional Predictive Audience"
                />
            )}
            <ExcludeAudienceField />
            <br />
            {mode === FORM_MODES.CREATE && crmTargetingType === CRM_TARGETING_TYPE.ALL && (
                <>
                    <BusinessOfExtendingCreditField />
                    <br />
                </>
            )}
            <AudienceDescriptionField />
        </CommonCollapsibleCard>
    );
};

export default FirstPartyAudiencePropertiesSection;
