import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { BareDropdown, CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import validationFunctions from 'modules/taboola-common-frontend-modules/validations/services/validationFunctions';
import { campaignsGroupMapSelector } from '../../../../../campaigns-group/selectors';
import { isCampaignGroupDeleted } from '../../../../../campaigns-reports/config/predicates/campaignGroupPredicates';
import { useCampaignGroupDropdownData } from '../../hooks/useCampaignGroupDropdownData';
import { CampaignGroupListItem } from '../CampaignGroupListItem/CampaignGroupListItem';
import styles from './CampaignGroup.module.scss';

const PAGE_SIZE = 10;
const defaultValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'campaign.group.placeholder',
        defaultMessage: 'Select a Campaign Group',
    },
];
const DropdownWithIndication = withIndication(PaginatedDropdown);
const invalidValueStyle = {
    control: props => ({ ...props, border: `1px solid ${styles.invalidValueColor}` }),
};
export const CampaignGroupMultiSelect = ({ validations = defaultValidations }) => {
    const {
        value: campaignGroups,
        onChange,
        scrollRef,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'campaignGroupList',
        validations,
        hasInitialData: false,
    });
    const cache = useSelector(campaignsGroupMapSelector);
    const { options, setOptions, loadPage } = useCampaignGroupDropdownData(campaignGroups);
    const selectedCampaignGroups = campaignGroups?.map(campaignGroup => cache[campaignGroup.id]);
    const onSelectGroup = group => {
        const groupObj = { id: group };
        onChange(prevList => [groupObj, ...prevList]);
    };
    const onRemoveGroup = group => {
        onChange(prevList => prevList.filter(id => id.id !== group.id));
    };
    const disabledByOptions = !loadPage || !options.length;

    return (
        <>
            <DropdownWithIndication
                id="campaign-group-dropdown"
                ref={scrollRef}
                options={options}
                loadPage={loadPage}
                onOptionsLoaded={setOptions}
                searchable
                enabledWhileSearching
                pageSize={PAGE_SIZE}
                onChange={({ value }) => onSelectGroup(value)}
                placeholder={
                    <FormattedMessage id="campaign.groups.placeholder" defaultMessage="Select Campaign Groups..." />
                }
                styles={isEmpty(failedValidationData) ? undefined : invalidValueStyle}
                DropdownMenuComponent={BareDropdown}
                containerClassName={styles['dropdown-container']}
                className={styles['item-container']}
                isOptionDisabled={option => isCampaignGroupDeleted(option)}
                disabled={disabledByOptions}
                controlShouldRenderValue={false}
                {...failedValidationData}
            />
            <CollapsibleList
                items={selectedCampaignGroups.filter(item => !!item)}
                id="campaign-group-list"
                ItemComponent={props => <CampaignGroupListItem {...props} />}
                deleteItem={onRemoveGroup}
                listHeaderTitle={
                    <FormattedMessage
                        id="app.create.scheduled.reports.campaign.groups.select.title"
                        defaultMessage="Campaign Groups"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
            />
        </>
    );
};
