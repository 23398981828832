import { DASHBOARD_RANGE_PICKER_PRESET_LABELS, DASHBOARD_RANGE_PICKER_PRESETS } from 'taboola-ultimate-ui';

export const dateRangeOptions = [
    { value: DASHBOARD_RANGE_PICKER_PRESETS.YESTERDAY, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.YESTERDAY },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.SEVEN_DAYS, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.SEVEN_DAYS },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.FOURTEEN_DAYS, label: DASHBOARD_RANGE_PICKER_PRESETS.FOURTEEN_DAYS },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.THIRTY_DAYS, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.THIRTY_DAYS },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.THIS_MONTH, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.THIS_MONTH },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.LAST_MONTH, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.LAST_MONTH },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.THIS_QUARTER, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.THIS_QUARTER },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.LAST_QUARTER, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.LAST_QUARTER },
    { value: DASHBOARD_RANGE_PICKER_PRESETS.THIS_YEAR, label: DASHBOARD_RANGE_PICKER_PRESET_LABELS.THIS_YEAR },
];
