'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _BaseRadio = require('../BaseRadio');

var _BaseRadio2 = _interopRequireDefault(_BaseRadio);

var _TextRadioLabel = require('./TextRadioLabel/TextRadioLabel');

var _TextRadioLabel2 = _interopRequireDefault(_TextRadioLabel);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'textRadioButton__taboola-svg-icon___QXGtm',
    'label': 'textRadioButton__label___2oKQz',
    'title': 'textRadioButton__title___3Hlzc',
    'checked-label': 'textRadioButton__checked-label___3JJ_K',
    'description': 'textRadioButton__description___2K7vS',
    'children-container': 'textRadioButton__children-container____2rFt'
};


var classNameBuilder = _bind2.default.bind(styles);
var TextRadioButton = function TextRadioButton(_ref) {
    var title = _ref.title,
        description = _ref.description,
        childrenContainerClassName = _ref.childrenContainerClassName,
        checkedLabelClassName = _ref.checkedLabelClassName,
        labelClassName = _ref.labelClassName,
        children = _ref.children,
        descriptionClassName = _ref.descriptionClassName,
        rest = _objectWithoutProperties(_ref, ['title', 'description', 'childrenContainerClassName', 'checkedLabelClassName', 'labelClassName', 'children', 'descriptionClassName']);

    return _react2.default.createElement(
        _BaseRadio2.default,
        Object.assign({}, rest, {
            labelClassName: classNameBuilder('label', labelClassName),
            checkedLabelClassName: classNameBuilder('checked-label', checkedLabelClassName)
        }),
        _react2.default.createElement(_TextRadioLabel2.default, {
            title: title,
            titleClassName: styles['title'],
            description: description,
            descriptionClassName: classNameBuilder('description', descriptionClassName)
        }),
        children && _react2.default.createElement(
            'div',
            { className: classNameBuilder('children-container', childrenContainerClassName) },
            children
        )
    );
};

TextRadioButton.propTypes = {
    /** Radio title */
    title: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]).isRequired,
    /** Radio description */
    description: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    /** Class name of the children container container */
    childrenContainerClassName: _propTypes2.default.string,
    /** Value of the radio */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.bool]).isRequired,
    /** Label displayed next to the radio input */
    label: _propTypes2.default.node,
    /** Size of the radio */
    size: _propTypes2.default.oneOf(Object.values(_BaseRadio.RADIO_SIZE)),
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Apply class to style the radio label */
    labelClassName: _propTypes2.default.string,
    /** Apply class to style the radio input */
    inputClassName: _propTypes2.default.string,
    /** Apply class to checked label */
    checkedLabelClassName: _propTypes2.default.string,
    /** Apply class to description */
    descriptionClassName: _propTypes2.default.string,
    /** Radio input color */
    radioColor: _propTypes2.default.string,
    /** Linked component */
    children: _propTypes2.default.node
};

TextRadioButton.defaultProps = {
    checkedLabelClassName: '',
    childrenContainerClassName: '',
    labelClassName: ''
};

exports.default = TextRadioButton;