import React, { useMemo } from 'react';
import { findLastIndex } from 'lodash';
import { SideDrawer as ExternalSideDrawer, useIsMobile } from 'taboola-ultimate-ui';
import { DRAWER_MODE } from '../../components/SideDrawer/SideDrawer';
import { FORM_MODES } from '../../config/formModes';
import { FORM_MODE_TO_PATH_MAP } from '../../config/routes';
import { useDrawerStack } from '../../hooks/queryParams/useDrawerStack';
import BodyLoading from '../campaigns/modules/common-campaign-form/components/BodyLoading/BodyLoading';
import { useMultiLayerDrawerContext } from './components/MultiLayerDrawerProvider/MultiLayerDrawerProvider';
import { getParams } from './utils/getParams';

const BOOKMARK_WIDTH = 30;

const DRAWER_WIDTH = {
    [DRAWER_MODE.SMALL]: 39,
    [DRAWER_MODE.MEDIUM]: 70,
    [DRAWER_MODE.LARGE]: 100,
};

const SideDrawerWrapper = ({ path, mode, pop, index, openDrawer, lastCreatedFormIndex, drawerMode, ...restProps }) => {
    const isMobile = useIsMobile();

    const drawerWidth = useMemo(() => {
        if (!openDrawer) {
            return 0;
        }

        const mode = index <= lastCreatedFormIndex ? DRAWER_MODE.LARGE : drawerMode;

        return `calc( ${DRAWER_WIDTH[mode]}vw - ${BOOKMARK_WIDTH * index}px )`;
    }, [index, lastCreatedFormIndex, openDrawer, drawerMode]);

    return (
        <ExternalSideDrawer
            anchorDirection="right"
            openDrawer={openDrawer}
            fitToScreen={isMobile}
            style={{ width: drawerWidth }}
            {...restProps}
        />
    );
};

export const MultiLayerDrawer = () => {
    const { stack, pop } = useDrawerStack();
    const { forms } = useMultiLayerDrawerContext();

    const drawerStack = useMemo(() => [...stack, {}], [stack]);

    const lastCreatedFormIndex = findLastIndex(stack, ({ path, pattern }) => {
        const { mode } = getParams(path, pattern);
        return mode === FORM_MODE_TO_PATH_MAP[FORM_MODES.CREATE];
    });

    return (
        <>
            {drawerStack.map(({ path, pattern, mode }, index) => {
                const { component: Component, props, drawerMode = DRAWER_MODE.SMALL } = forms[pattern] || {};

                return (
                    <SideDrawerWrapper
                        key={index}
                        mode={mode}
                        onClose={() => pop(null)}
                        openDrawer={!!path}
                        index={index}
                        lastCreatedFormIndex={lastCreatedFormIndex}
                        drawerMode={drawerMode}
                        path={path}
                    >
                        {Component ? <Component {...props} path={path} pattern={pattern} /> : <BodyLoading />}
                    </SideDrawerWrapper>
                );
            })}
        </>
    );
};
