import { isFunction, keyBy } from 'lodash';

const hrefFactory = (baseHref, values) => {
    if (!isFunction(baseHref)) {
        return baseHref;
    }
    return baseHref(values);
};
const localizeLinks = (config, formatMessage, values = {}) => {
    const list = Object.entries(config).map(([key, { href, hrefId, ...rest }]) => ({
        ...rest,
        key,
        href: (hrefId && formatMessage({ id: hrefId, defaultMessage: href }, values)) || hrefFactory(href, values),
        label: rest.id ? formatMessage(rest) : '',
    }));
    return keyBy(list, 'key');
};

export { localizeLinks };
