"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgColumns(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 13,
            height: 12,
            viewBox: "0 0 13 12",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M1.501 0C0.671 0 0 0.672 0 1.5V10.5C0 11.328 0.671 12 1.501 12C2.328 12 3 11.328 3 10.5V1.5C3 0.672 2.328 0 1.501 0ZM6.501 0C5.672 0 5 0.672 5 1.5V10.5C5 11.328 5.672 12 6.501 12C7.328 12 8 11.328 8 10.5V1.5C8 0.672 7.328 0 6.501 0ZM11.5 0C10.672 0 10.001 0.672 10.001 1.5V10.5C10.001 11.328 10.672 12 11.5 12C12.328 12 13 11.328 13 10.5V1.5C13 0.672 12.328 0 11.5 0Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgColumns;