import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupBy, isNil, map } from 'lodash';
import { graphDataSelector } from 'modules/campaigns/selectors';

export const useSingleMetricGraph = graphConfig => {
    const legendDropdownMetrics = useMemo(
        () => graphConfig.metrics.map(metric => ({ value: metric.label, label: metric.label, field: metric.field })),
        [graphConfig.metrics]
    );
    const [selectedLegendDropdownMetric, setSelectedLegendDropdownMetric] = useState(legendDropdownMetrics[0]);
    const graphDataRaw = useSelector(graphDataSelector);

    const graphData = useMemo(() => {
        const {
            graphProps: { xAxisDataKey },
            collatingKey,
        } = graphConfig;
        const { field: selectedMetric } = selectedLegendDropdownMetric;
        const valuesGroupedByXAxisDataKey = groupBy(graphDataRaw, xAxisDataKey);

        const transposedGraphData = map(valuesGroupedByXAxisDataKey, (values, xAxisDataKeyValue) => {
            const dataPoint = {
                [xAxisDataKey]: parseInt(xAxisDataKeyValue) || xAxisDataKeyValue,
            };
            values.forEach(valueObj => {
                if (isNil(valueObj[collatingKey])) {
                    return;
                }
                const collatingKeyValue = valueObj[collatingKey];
                dataPoint[collatingKeyValue] = valueObj[selectedMetric];
            });

            return dataPoint;
        });

        return transposedGraphData;
    }, [graphConfig, graphDataRaw, selectedLegendDropdownMetric]);

    return {
        selectedLegendDropdownMetric,
        legendDropdownMetrics,
        onMetricDropdownChange: setSelectedLegendDropdownMetric,
        graphData,
    };
};
