import { UPDATE_CREATIVES_SUCCESS, UPDATE_CREATIVES_ERROR, REQUEST_UPDATE_CREATIVES } from './actionTypes';

export const requestUpdateCreatives = () => ({
    type: REQUEST_UPDATE_CREATIVES,
});

export const updateCreativesSuccess = creatives => ({
    type: UPDATE_CREATIVES_SUCCESS,
    payload: creatives,
});

export const updateCreativesError = (ids, error) => ({
    type: UPDATE_CREATIVES_ERROR,
    payload: { ids, error },
});
