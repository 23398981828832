const FALLBACK_POLICY = {
    PUBLISHER_ONLY: 'PUBLISHER_ONLY',
    PUBLISHER_AND_GLOBAL: 'PUBLISHER_AND_GLOBAL',
    PUBLISHER_AND_NETWORK: 'PUBLISHER_AND_NETWORK',
    FULL: 'FULL',
    ADMIN_NETWORK_ONLY: 'ADMIN_NETWORK_ONLY',
    PUBLISHER_AND_NETWORK_AND_ADMIN_NETWORK: 'PUBLISHER_AND_NETWORK_AND_ADMIN_NETWORK',
};

export default FALLBACK_POLICY;
