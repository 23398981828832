import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const BrowserTargetingTooltip = () => (
    <TooltipSection>
        <FormattedMessage id="campaign.editor.targeting.browser.help.overview" />
    </TooltipSection>
);

export default BrowserTargetingTooltip;
