import React from 'react';
import { ValidationContextConsumer } from '../../ValidationContext';

const withValidationContextConsumer = InnerComponent => props => {
    const { scrollAreaRef: _, ...propWithoutAreaScroll } = props;
    return (
        <ValidationContextConsumer>
            {state => <InnerComponent {...propWithoutAreaScroll} {...state} />}
        </ValidationContextConsumer>
    );
};

export default withValidationContextConsumer;
