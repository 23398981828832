import React from 'react';

const LifetimeSVGIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
        <g fill="#000" fillRule="evenodd">
            <path fillRule="nonzero" d="M5.5 18v-1h41v1z" />
            <path d="M41 10h4c1.153 0 2 .839 2 2v27c0 1.161-.847 2-2 2H7c-1.153 0-2-.839-2-2V12c0-1.161.847-2 2-2h4v1h7v-1h16v1h7v-1zm5 2c0-.505-.502-1-1-1H7c-.498 0-1 .495-1 1v27c0 .505.502 1 1 1h38c.498 0 1-.495 1-1V12z" />
            <path fillRule="nonzero" d="M46 34v-3.5h1v4a.5.5 0 0 1-.5.5h-41a.5.5 0 0 1-.5-.5v-3.79h1V34h40z" />
            <path
                fillRule="nonzero"
                d="M10.5 37a.5.5 0 1 1 0 1h-3A2.5 2.5 0 0 1 5 35.5v-2.463a.5.5 0 1 1 1 0V35.5A1.5 1.5 0 0 0 7.5 37h3zM41.5 38a.5.5 0 1 1 0-1h3a1.5 1.5 0 0 0 1.5-1.5v-1.521a.5.5 0 1 1 1 0V35.5a2.5 2.5 0 0 1-2.5 2.5h-3zM12.5 15a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4zm.5-1h3V8h-3v6zM35.5 15a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4zm.5-1h3V8h-3v6zM8.5 24a.5.5 0 1 1 0-1h35a.5.5 0 1 1 0 1h-35zM8.5 29a.5.5 0 1 1 0-1h35a.5.5 0 1 1 0 1h-35z"
            />
            <path
                fillRule="nonzero"
                d="M13 20.5a.5.5 0 1 1 1 0v11a.5.5 0 1 1-1 0v-11zM21 20.5a.5.5 0 1 1 1 0v11a.5.5 0 1 1-1 0v-11zM30 20.5a.5.5 0 1 1 1 0v11a.5.5 0 1 1-1 0v-11zM38 20.5a.5.5 0 1 1 1 0v11a.5.5 0 1 1-1 0v-11z"
            />
        </g>
    </svg>
);

export default LifetimeSVGIcon;
