import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const AppsTargetingFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.apps.targeting.tooltip"
            defaultMessage="Include or exclude publisher applications."
        />
    </TooltipSection>
);
