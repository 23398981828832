import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './measuredBy.module.scss';

const classNameBuilder = classnames.bind(styles);

export const MeasuredByImgTag = ({ img, imageClassName }) => (
    <div>
        <FormattedMessage
            id="app.campaigns.reports.grid.column.header.tooltip.content.measuredBy"
            defaultMessage="Measured by"
        />
        <img src={img} alt="moat" className={classNameBuilder('img', imageClassName)} />
    </div>
);
