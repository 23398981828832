"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgTag(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M10.1596 0.308526L14.7184 0.00194318C15.0634 -0.0175964 15.4004 0.110937 15.6447 0.355283C15.8891 0.599629 16.0176 0.93659 15.9981 1.28159L15.6781 5.84035C15.6576 6.13091 15.5348 6.40479 15.3316 6.61347L6.30736 15.6443C6.08315 15.8719 5.77705 16 5.4576 16C5.13815 16 4.83204 15.8719 4.60783 15.6443L0.355654 11.3922C0.128114 11.168 0 10.8619 0 10.5424C0 10.223 0.128114 9.91685 0.355654 9.69264L9.38653 0.668428C9.59322 0.460323 9.86734 0.332718 10.1596 0.308526ZM11.8729 3.87734C12.2752 4.28057 12.9279 4.28216 13.3321 3.88089C13.7354 3.47533 13.7354 2.82018 13.3321 2.41462C12.9279 2.01335 12.2752 2.01493 11.8729 2.41816C11.4707 2.82139 11.4707 3.47411 11.8729 3.87734Z",
            fill: "#667686"
        })
    );
}
exports.default = SvgTag;