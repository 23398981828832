import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AlertIcon, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { PERFORMANCE_RECOMMENDATIONS_ITEM_CARD_TYPES } from '../../config/performanceRecommendationsItemCardTypes';
import styles from './recommendationInCellIndication.module.scss';

export const RecommendationInCellIndication = ({ recommendation, onApplyCallback, onDismissCallback, data }) => {
    const { id, type, numericAccountId } = recommendation;
    const { campaignId } = data;
    const RecommendationCard = PERFORMANCE_RECOMMENDATIONS_ITEM_CARD_TYPES[type];
    const [isDismissed, setIsDismissed] = useState(false);
    const transformedRecommendation = useMemo(() => {
        return {
            ...recommendation,
            campaignId,
        };
    }, [campaignId, recommendation]);
    const dismissInCellRecommendationIndication = () => {
        setIsDismissed(true);
        onDismissCallback?.({}, type);
    };

    const applyRecommendationIndication = (newData, appliedRecommendationType) => {
        setIsDismissed(true);
        onApplyCallback?.(newData, appliedRecommendationType);
    };

    if (isDismissed) {
        return null;
    }

    return (
        <div
            className={styles['recommendation-in-cell-indication-container']}
            data-metrics-visibility={GTM_EVENTS.VISIBLE}
            data-metrics-account-id={numericAccountId}
            data-metrics-taboola-campaign-id={campaignId}
            data-metrics-value={id}
            data-metrics-component="RecommendationInCellIndication"
        >
            <AlertIcon className={styles['recommendation-in-cell-indication-icon']} />
            <Tooltip
                className={styles['recommendation-in-cell-indication-tooltip']}
                position={TOOLTIP_POSITION.RIGHT}
                arrow
                interactive
            >
                {RecommendationCard ? (
                    <RecommendationCard
                        recommendation={transformedRecommendation}
                        removeRecommendationIndication={dismissInCellRecommendationIndication}
                        applyRecommendationIndication={applyRecommendationIndication}
                        data={data}
                        {...transformedRecommendation}
                    />
                ) : null}
            </Tooltip>
        </div>
    );
};

RecommendationInCellIndication.propTypes = {
    recommendation: PropTypes.object,
    campaignId: PropTypes.number,
};

export default RecommendationInCellIndication;
