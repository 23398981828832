import React from 'react';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import { BaseGalleryItemDropdown } from './BaseGalleryItemDropdown';
import { useGalleryItemDropdown } from './useGalleryItemDropdown';
import { useMotionStudioStatus } from './useMotionStudioStatus';

export const GalleryItemDropdown = ({ onSelectMedia, options, ...rest }) => {
    const { eventsLocationPrefix } = rest;
    const { campaignId } = useMediaTabsContext();
    const { onClick } = useGalleryItemDropdown({ campaignId, eventsLocationPrefix, onSelectMedia });
    const { isMotionStudioEnabled, isWochitAccessTokenInvalid } = useMotionStudioStatus();
    const optionsConditionData = { isMotionStudioEnabled, isWochitAccessTokenInvalid };

    return (
        <BaseGalleryItemDropdown
            onClick={onClick}
            campaignId={campaignId}
            options={options}
            optionsConditionData={optionsConditionData}
            {...rest}
        />
    );
};
