import { CREATIVE_TYPE, FORM_MODES } from 'modules/campaigns/config';
import { useIsCarouselCreative } from 'modules/campaigns/modules/creative-editor/hooks/useIsCarouselCreative';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useAdScheduleConfig = () => {
    const isAdSchedulerEnabled = useConfigMatch({ [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'true' });
    const isAdSchedulerEnabledForMotionAds = useConfigMatch({
        [FEATURE_FLAGS.AD_SCHEDULER_ENABLED_FOR_MOTION_ADS]: 'true',
    });
    const { mode } = useFormDataContext();
    const isCarouselCreative = useIsCarouselCreative();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const isMotionAdCreative = creativeType === CREATIVE_TYPE.MOTION;
    const isAdScheduleAllowed = !isMotionAdCreative || isAdSchedulerEnabledForMotionAds;
    return mode === FORM_MODES.EDIT && isAdSchedulerEnabled && !isCarouselCreative && isAdScheduleAllowed;
};
