import { isIframeMode } from 'modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { redirectToLoginPage } from './pageRedirect';

const userSessionKey = 'session';

export const MISSED_TOKEN_ERROR = new Error('Abort request due to missed token');
export const EXPIRED_TOKEN_ERROR = new Error('Expired token');

class AuthTokenManager {
    constructor() {
        this.token = null;
        this.sessionExp = null;
        this.mirrorToLocalStorage = !isIframeMode();
        this.pendingRedirect = false;
    }

    setToken(token, sessionExp, skipStorageUpdate) {
        if (this.mirrorToLocalStorage && !skipStorageUpdate) {
            localStorage.setItem(userSessionKey, JSON.stringify({ token, sessionExp }));
        }
        this.token = token;
        this.sessionExp = sessionExp;
    }

    isRelevantStorageEvent(event) {
        return event.key === userSessionKey;
    }

    clearOnStorageEvent(event) {
        if (!event.newValue) {
            this.clear();
            return true;
        }
    }

    updateTokenOnStorageChange(event) {
        try {
            const userSession = JSON.parse(event.newValue) || {};
            this.setToken(userSession.token, userSession.sessionExp, true);
        } catch (e) {
            this.clear();
        }
    }

    getToken() {
        if (!this.mirrorToLocalStorage) {
            return this.token;
        }

        if (!this.token) {
            try {
                const userSession = JSON.parse(localStorage.getItem(userSessionKey)) || {};
                this.token = userSession.token;
                this.sessionExp = userSession.sessionExp;
            } catch (e) {
                this.clear();
            }
        }

        return this.token;
    }

    redirectOnWrongToken(error) {
        this.clear();
        redirectToLoginPage();
        if (error) {
            throw error;
        }
    }

    getHeaders() {
        if (this.isExpired()) {
            console.error(EXPIRED_TOKEN_ERROR.message);
            this.pendingRedirect = true;
            this.redirectOnWrongToken();
            return;
        }

        if (!this.getToken()) {
            console.error(MISSED_TOKEN_ERROR.message);
            this.pendingRedirect = true;
            this.redirectOnWrongToken();
            return;
        }

        return {
            Authorization: `Bearer ${this.getToken()}`,
        };
    }

    isExpired() {
        return Date.now() > parseInt(this.sessionExp, 10);
    }

    clear() {
        this.token = null;
        this.sessionExp = null;
        this.clearSessionCookies();
        if (this.mirrorToLocalStorage) {
            localStorage.removeItem(userSessionKey);
        }
    }

    isPendingRedirect() {
        return this.pendingRedirect;
    }

    clearSessionCookies() {
        document.cookie = `JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
}

export const authTokenManager = new AuthTokenManager();
