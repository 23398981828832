import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { OutlineEditableText } from 'modules/campaigns/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import styles from './nameEdit.module.scss';

const classNameBuilder = classnames.bind(styles);

export const NameEdit = ({
    messageIdPrefix,
    field,
    validations = [],
    defaultLabel,
    innerClassName,
    inputId = 'ad-name',
}) => {
    const {
        value: name,
        onChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field,
        validations,
        throttleValue: true,
    });
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `${messageIdPrefix}.name.placeholder`,
    });

    return (
        <FormField
            ref={scrollRef}
            containerClass={styles['input']}
            label={<FormattedMessage id={`${messageIdPrefix}.name`} defaultMessage={defaultLabel} />}
            inputId={inputId}
        >
            <OutlineEditableText
                id={inputId}
                className={classNameBuilder('editable-text', innerClassName)}
                value={name}
                onChange={onChange}
                placeholder={placeholder}
                {...indicationData}
            />
        </FormField>
    );
};
