import { useCallback } from 'react';
import { isIframeMode } from '../../modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { useContextMenu } from './ContextMenuContext';

export const useCustomContextMenu = () => {
    const { data, setData, menuPosition, setMenuPosition } = useContextMenu();

    const open = useCallback(
        (e, data) => {
            if (!isIframeMode()) {
                return;
            }

            e.preventDefault();
            setMenuPosition({
                x: e.clientX,
                y: e.clientY,
            });
            setData(data);
        },
        [setMenuPosition, setData]
    );

    const close = useCallback(() => {
        setData(null);
        setMenuPosition(null);
    }, [setData, setMenuPosition]);

    return {
        open,
        close,
        data,
        menuPosition,
        setMenuPosition,
    };
};
