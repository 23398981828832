import React from 'react';
import { PerformanceRulesIcon } from 'tuui';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { EmptyStateOverlay } from 'modules/campaigns/modules/campaigns-reports/components/EmptyStateOverlay/EmptyStateOverlay';
import { useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { CreatePerformanceRulesButton } from 'modules/performance-rules/components/CreatePerformanceRulesButton/CreatePerformanceRulesButton';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './performanceRulesEmptyState.module.scss';

const selectedReportMessage = selectedReport => {
    switch (selectedReport) {
        case REPORT_TYPE.PERFORMANCE_RULES:
            return <PerformanceRulesReportEmptyState />;
        case REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS:
            return <EmptyStateOverlay includeContent={false} />;
        default:
            return <PerformanceRulesReportEmptyState />;
    }
};

const PerformanceRulesReportEmptyState = () => (
    <div className={styles['container-empty']}>
        <PerformanceRulesIcon className={styles['performance-rules-icon']} />
        <div className={styles['header']}>
            <FormattedMessage
                className={styles['header']}
                id="performance.rules.report.empty"
                defaultMessage="No rules were created yet"
            />
        </div>
        <div className={styles['content-empty']}>
            <FormattedMessage
                id="performance.rules.report.empty.content"
                defaultMessage="Create your first rule to manage your campaigns"
            />
            <div className={styles['create-button']}>
                <CreatePerformanceRulesButton />
            </div>
        </div>
    </div>
);

export const PerformanceRulesEmptyState = () => {
    const [selectedReport] = useSelectedReportId();

    return <div>{selectedReportMessage(selectedReport)}</div>;
};
