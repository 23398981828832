import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { generatePerformanceRecommendationsReviewerPath } from '../services/utils';

const useOpenPerformanceRecommendationsReviewer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { accountId: selectedAccountId } = useSelector(selectedAccountSelector);

    return useCallback(
        campaign => {
            const { id, accountName, advertiserName } = campaign;
            // TODO: remove advertiserName after DEV-65984
            const resolvedAdvertiserName = accountName || advertiserName || selectedAccountId;
            const editCampaignsGroupPath = generatePerformanceRecommendationsReviewerPath({
                accountId: resolvedAdvertiserName,
                campaignId: id,
            });
            dispatch(navigate(history, editCampaignsGroupPath));
        },
        [selectedAccountId, dispatch, history]
    );
};

export default useOpenPerformanceRecommendationsReviewer;
