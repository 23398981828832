import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useQuery } from 'react-query';
import { PAYMENT_TYPE } from '../../../billing-and-payments/config/PaymentType';
import { usePaymentProviderAccount } from '../../../billing-and-payments/hooks/usePaymentProviderAccount';
import { StripeACHPaymentProvider } from '../../../billing-and-payments/providers/payment-providers/stripe/StripeACHPaymentProvider';
import { StripeCardPaymentProvider } from '../../../billing-and-payments/providers/payment-providers/stripe/StripeCardPaymentProvider';
import { isDefined } from '../../formData/utils/isDefined';
import { useLocale } from '../../i18n';
import { StripeACHContextProvider } from '../StripeACHContext';
import { StripeCardContextProvider } from '../StripeCardContext';
import { STRIPE_PAYMENT_ELEMENT_APPEARANCE as appearance } from '../consts';

const paymentMethodTypesMetadata = {
    [PAYMENT_TYPE.CARD]: {
        paymentMethodContextProvider: StripeCardContextProvider,
        paymentMethodProvider: StripeCardPaymentProvider,
        options: () => {},
    },
    [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT]: {
        paymentMethodContextProvider: StripeACHContextProvider,
        paymentMethodProvider: StripeACHPaymentProvider,
        options: options => ({ ...options }),
    },
};

export const StripeProvider = ({ paymentMethodType, children }) => {
    const [locale] = useLocale();
    const { stripeAccountId, clientSecret, stripeApiKey } = usePaymentProviderAccount({
        paymentMethodType,
    });
    const isStripeReady = isDefined(stripeAccountId) && isDefined(clientSecret) && isDefined(locale);
    const { data: stripe } = useQuery(
        ['stripe-api-account', stripeAccountId],
        () => loadStripe(stripeApiKey, { stripeAccount: stripeAccountId, locale }),
        {
            refetchOnWindowFocus: false,
            enabled: isStripeReady,
        }
    );

    const paymentMethodTypeMetadata = paymentMethodTypesMetadata[paymentMethodType];
    const PaymentMethodContextProvider = paymentMethodTypeMetadata?.paymentMethodContextProvider;
    const PaymentMethodProvider = paymentMethodTypeMetadata?.paymentMethodProvider;
    const options = paymentMethodTypeMetadata?.options({ clientSecret, appearance, locale });

    return paymentMethodType ? (
        <Elements stripe={stripe ?? null} options={options}>
            <PaymentMethodContextProvider clientSecret={clientSecret}>
                <PaymentMethodProvider>{children}</PaymentMethodProvider>
            </PaymentMethodContextProvider>
        </Elements>
    ) : null;
};
