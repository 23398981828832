import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { get, keyBy, map, noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFetchedCampaignList } from '../../../../../performance-rules/hooks/useFetchedCampaignList';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import { CONTENT_METHODS } from '../../config';
import CampaignDropdown from './CreativeCreatorCampaignDropdown';
import CampaignsSmartList from './CreativeCreatorCampaignsSmartList';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';

const getMessageAndHandlers = (isMultiCampaignSelectionEnabled, onChangeCampaign, isCarouselEnabled) =>
    isMultiCampaignSelectionEnabled
        ? {
              onSelectCampaign: campaign => onChangeCampaign(prevList => [campaign, ...prevList]),
              onRemoveCampaign: campaign =>
                  onChangeCampaign(prevList => prevList.filter(({ id }) => id !== campaign.id)),
              Content: CampaignsSmartList,
              description: isCarouselEnabled ? (
                  <FormattedMessage
                      id="app.creative.creator.campaign.selector.description.carousel"
                      defaultMessage="Select one or more non-carousel campaigns."
                  />
              ) : (
                  <FormattedMessage
                      id="app.creative.creator.campaign.selector.description"
                      defaultMessage="Select one or more campaigns."
                  />
              ),
              placeholder: (
                  <FormattedMessage
                      id="app.creative.creator.campaign.targeting.multiselect.placeholder"
                      defaultMessage="Select Campaigns..."
                  />
              ),
          }
        : {
              onSelectCampaign: campaign => onChangeCampaign([campaign]),
              onRemoveCampaign: noop,
              Content: CampaignDropdown,
              description: null,
              placeholder: null,
          };

const allowedMultiCampaignContentMethods = keyBy([
    CONTENT_METHODS.VARIATIONS,
    CONTENT_METHODS.AI_VARIATIONS,
    CONTENT_METHODS.CAROUSEL,
]);
const defaultHelpText = isMultiCampaignSelectionEnabled => {
    if (isMultiCampaignSelectionEnabled) {
        return (
            <FormattedMessage
                id="app.creative.creator.campaign.targeting.multiselect.helpText"
                defaultMessage="The new ads will be added to the selected campaigns."
            />
        );
    }
    return (
        <FormattedMessage
            id="app.creative.creator.campaign.targeting.helpText"
            defaultMessage="The new ads will be added to the selected campaign."
        />
    );
};

const CampaignsField = ({ className, contentMethod, helpText, shouldUseValidations = true, ...rest }) => {
    const isBulkEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_CREATOR_BULK_CREATE_VARIATIONS_ENABLED]: 'true' });
    const isCarouselEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_CAROUSEL_ENABLED]: 'true' });
    const { formAccount, fetchedData } = useFormDataContext();
    const campaignIds = useMemo(() => map(get(fetchedData, 'campaigns', []), 'id'), [fetchedData]);

    const initialCampaigns = useFetchedCampaignList(campaignIds, formAccount.accountId);
    const isMultiCampaignSelectionEnabled = !!allowedMultiCampaignContentMethods[contentMethod] && isBulkEnabled;
    const { onChange: onChangeCampaign } = useCampaignsFormFieldValue();

    const { onSelectCampaign, onRemoveCampaign, Content, description, placeholder } = getMessageAndHandlers(
        isMultiCampaignSelectionEnabled,
        onChangeCampaign,
        isCarouselEnabled
    );
    const helpTextSelected = helpText ?? defaultHelpText(isMultiCampaignSelectionEnabled);

    return (
        <FormField
            label={<FormattedMessage id="creative.duplicate.section.campaigns.title" defaultMessage="Campaigns" />}
            inputId="creative-creator-campaign-selector"
            containerClass={classnames(commonStyles['input'], className)}
            description={description}
            helpText={helpTextSelected}
        >
            <Content
                onSelectCampaign={onSelectCampaign}
                onRemoveCampaign={onRemoveCampaign}
                placeholder={placeholder}
                initialCampaigns={initialCampaigns}
                shouldUseValidations={shouldUseValidations}
                {...rest}
            />
        </FormField>
    );
};

CampaignsField.propTypes = {
    className: PropTypes.string,
    contentMethod: PropTypes.oneOf(Object.values(CONTENT_METHODS)),
    accountForCampaign: PropTypes.object,
};

CampaignsField.defaultProps = {};

export default CampaignsField;
