import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CreateFunnelButton } from '../../CreateFunnelButton/CreateFunnelButton';
import styles from '../trackingEmptyState.module.scss';

export const UnipFunnelEmptyStateMessages = () => {
    return (
        <>
            <div className={styles['header']}>
                <FormattedMessage
                    className={styles['header']}
                    id="tracking.funnels.report.not.created.title"
                    defaultMessage="No Available Funnels"
                />
            </div>
            <div className={styles['content']}>
                <FormattedMessage
                    id="tracking.funnels.report.not.created.content"
                    defaultMessage="Start defining your first funnel"
                />
            </div>
            <CreateFunnelButton />
        </>
    );
};
