import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const groupNameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.campaigns.group.name.empty',
        defaultMessage: 'Enter a Group Name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 255 },
        messageId: 'validations.error.campaigns.group.name.length',
        defaultMessage: 'Group name can not exceed 255 characters',
    },
];

export default groupNameValidations;
