import React from 'react';
import { InputGroupWithButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useMetadataInput } from '../../hooks/useMetadataInput';
import styles from './metadataInput.module.scss';

export const MetadataInput = ({ onAdd }) => {
    const { config, isDisabled, onClick } = useMetadataInput(onAdd);
    return (
        <InputGroupWithButton
            className={styles['group']}
            config={config}
            delimiterClassName={styles['delimiter']}
            buttonProps={{
                onClick,
                disabled: isDisabled,
                className: styles['submit-button'],
                children: (
                    <FormattedMessage id="account.editor.input.group.button.submit.text" defaultMessage="Submit" />
                ),
            }}
        />
    );
};
