import { useCallback } from 'react';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { generateConversionCreatorPath } from 'modules/tracking/utils/generateTrackingPath';
import styles from './addConversion.module.css';

export const AddConversion = ({
    id = 'campaign.editor.conversion.goal.conversion.addConversion',
    defaultMessage = 'Add Conversion',
}) => {
    const onClick = useCallback(() => {
        const win = window.open(generateConversionCreatorPath(), '_blank');
        win.focus();
    }, []);

    return (
        <span
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value="add conversion"
            data-metrics-component="Pixel Warning"
            onClick={onClick}
            className={styles['add-conversion']}
        >
            <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </span>
    );
};
