import { batch } from 'react-redux';
import moment from 'moment-timezone';
import { resetCampaigns, resetCreatives } from 'modules/campaigns/actions';
import { resetSelectedRows } from 'modules/campaigns/modules/campaigns-reports/actions';
import { fetchRecommendationsIndication } from 'modules/recommendations/flows/fetchRecommendationsIndication';
import { resetIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { fetchAndUpdateIntercomParameters } from 'modules/taboola-common-frontend-modules/intercom';
import { fetchSyndicatorRecommendationCounts } from '../modules/campaigns/flows/fetchSyndicatorRecommendationCounts';
import { isIframeMode } from '../modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { appStatusSelector } from '../selectors';
import { COMPONENT_STATUS } from '../services/constants';

export const fetchAppAccountDependencies = (selectedAccount, chatAgentPermitted) => async (dispatch, getState) => {
    const { accountName, timeZoneName } = selectedAccount;
    const state = getState();
    const status = appStatusSelector(state);

    moment.tz.setDefault(timeZoneName);

    if (status !== COMPONENT_STATUS.INITIAL) {
        batch(() => {
            dispatch(resetCampaigns());
            dispatch(resetCreatives());
            dispatch(resetIndications());
            dispatch(resetSelectedRows());
        });
    }

    if (!isIframeMode()) {
        dispatch(fetchAndUpdateIntercomParameters(selectedAccount, chatAgentPermitted));
    }
    dispatch(fetchRecommendationsIndication(accountName));
    dispatch(fetchSyndicatorRecommendationCounts(accountName));
};
