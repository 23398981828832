import { modifyExternalUrl } from 'modules/campaigns/services/utils';

const isImage = item => item.thumbnailUrl && !item.videoUrl && !item.gifUrl;

export const transformVideoItem = (item, source) => ({
    url: item.videoUrl,
    id: item.videoUrl,
    displayUrl: modifyExternalUrl(item.videoUrl),
    fileType: 'video',
    gifUrl: item.gifUrl,
    source,
    fallbackImageUrl: item.thumbnailUrl,
});

export const transformImageItem = (item, source) => ({
    url: modifyExternalUrl(item.thumbnailUrl),
    id: item.thumbnailUrl,
    cdnUrl: modifyExternalUrl(item.thumbnailUrl),
    thumbnailUrl: modifyExternalUrl(item.thumbnailUrl),
    fileType: 'image',
    source,
});

export const transformMediaResponseToMediaItems = (mediaLibraryItemsResponse, source) =>
    mediaLibraryItemsResponse.map(item =>
        isImage(item) ? transformImageItem(item, source) : transformVideoItem(item, source)
    );
