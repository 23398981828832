import React, { useMemo } from 'react';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { formatItemIds, useNavigateToAdsReport } from '../../hooks/useNavigateToAdsReport';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const ItemWithoutDescriptionPerformanceRecommendation = ({ recommendation }) => {
    const { accountName: accountId, campaignId, messageParams, actions, id: recommendationId } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const { numOfItems, itemIds } = messageParams;
    const singleMessageIdAddition = numOfItems === 1 ? 'single.' : '';
    const formattedItemIds = useMemo(() => formatItemIds(itemIds), [itemIds]);
    const { navigateToAdsReport } = useNavigateToAdsReport({ formattedItemIds });

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={{
                ...messageParams,
                formattedItemIds,
            }}
            titleCode={`performance-recommendations.item-without-description.${singleMessageIdAddition}title`}
            descriptionCode={`performance-recommendations.item-without-description.${singleMessageIdAddition}description`}
            iconImg={LightningRoundIcon}
            isApplied={false}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToAdsReport}
                    recommendation={recommendation}
                    textCode={`performance-recommendations.low-performing-item.${singleMessageIdAddition}view`}
                    dataMetricsComponent="RecommendationCardViewItemsButton"
                    defaultMessage="View Ads"
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};
