'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _HeadlessDropdown = require('../../../DropdownMenus/HeadlessDropdown/HeadlessDropdown');

var _HeadlessDropdown2 = _interopRequireDefault(_HeadlessDropdown);

var _ArrowIcon = require('../../../DropdownMenus/ArrowIcon/ArrowIcon');

var _ArrowIcon2 = _interopRequireDefault(_ArrowIcon);

var _constants = require('../constants');

var _ButtonsGroupButton = require('../ButtonsGroupButton');

var _ButtonsGroupButton2 = _interopRequireDefault(_ButtonsGroupButton);

var _ButtonsGroupDropdownOption = require('./ButtonsGroupDropdownOption');

var _ButtonsGroupDropdownOption2 = _interopRequireDefault(_ButtonsGroupDropdownOption);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'buttonsGroupDropdown-module__taboola-svg-icon___26Jgl',
    'container': 'buttonsGroupDropdown-module__container___26kpD',
    'arrow': 'buttonsGroupDropdown-module__arrow___2oB9r',
    'arrow-container': 'buttonsGroupDropdown-module__arrow-container___2HRMW',
    'button-arrow-container': 'buttonsGroupDropdown-module__button-arrow-container___3EV-w',
    'button': 'buttonsGroupDropdown-module__button___5xEta',
    'dropdown': 'buttonsGroupDropdown-module__dropdown___1JdJS'
};


var classNameBuilder = _bind2.default.bind(styles);

var ButtonsGroupDropdown = function ButtonsGroupDropdown(_ref) {
    var onChange = _ref.onChange,
        selected = _ref.selected,
        selectedValueObject = _ref.selectedValueObject,
        buttonGroupDropdownContainerClassName = _ref.buttonGroupDropdownContainerClassName,
        options = _ref.options,
        showLabel = _ref.showLabel,
        value = _ref.value,
        label = _ref.label,
        getHref = _ref.getHref,
        isScrollable = _ref.isScrollable;

    var _useState = (0, _react.useState)(function () {
        return selected ? selectedValueObject : options[0];
    }),
        _useState2 = _slicedToArray(_useState, 2),
        button = _useState2[0],
        setButton = _useState2[1];

    var _useState3 = (0, _react.useState)(false),
        _useState4 = _slicedToArray(_useState3, 2),
        open = _useState4[0],
        setOpen = _useState4[1];

    var handleToggleDropdown = function handleToggleDropdown(isOpen) {
        return setOpen(isOpen);
    };
    var dropdownOnChange = function dropdownOnChange(newButton) {
        return setButton(newButton) || onChange(newButton);
    };
    var containerClass = classNameBuilder(styles['container'], buttonGroupDropdownContainerClassName);
    return _react2.default.createElement(
        'div',
        { className: containerClass },
        _react2.default.createElement(
            _HeadlessDropdown2.default,
            {
                options: options,
                onChange: dropdownOnChange,
                selectedValueObject: selectedValueObject,
                className: styles['dropdown'],
                width: 140,
                optionRenderer: _ButtonsGroupDropdownOption2.default,
                open: open,
                onToggleDropdown: handleToggleDropdown,
                getHref: getHref,
                isScrollable: isScrollable
            },
            function () {
                return _react2.default.createElement(
                    'div',
                    { className: styles['button-arrow-container'] },
                    _react2.default.createElement(_ButtonsGroupButton2.default, Object.assign({}, button, showLabel ? { label: label } : {}, {
                        className: styles['button'],
                        onChange: selected || showLabel ? function () {
                            return setOpen(true);
                        } : function (v) {
                            return setOpen(false) || onChange(v);
                        },
                        value: value !== _constants.MORE_BUTTON_VALUE ? button.value : value,
                        getHref: value !== _constants.MORE_BUTTON_VALUE ? getHref : _lodash2.default,
                        isDropdown: true
                    })),
                    _react2.default.createElement(
                        'div',
                        {
                            className: styles['arrow-container'],
                            role: 'button',
                            tabIndex: 0,
                            onClick: function onClick() {
                                return setOpen(true);
                            }
                        },
                        _react2.default.createElement(_ArrowIcon2.default, { className: styles['arrow'] })
                    )
                );
            }
        )
    );
};

var itemPropType = {
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node])
};

ButtonsGroupDropdown.propTypes = Object.assign({
    index: _propTypes2.default.number,
    onChange: _propTypes2.default.func,
    selected: _propTypes2.default.bool,
    selectedValueObject: _propTypes2.default.shape(itemPropType),
    buttonGroupDropdownContainerClassName: _propTypes2.default.string,
    // show the label provided in props instead of the most recently selected suboption. if this is enabled,
    // clicking the body of the button will toggle the dropdown instead of selecting an element (since the label
    // is no longer indicative of what element would have been selected)
    showLabel: _propTypes2.default.bool,
    getHref: _propTypes2.default.func
}, itemPropType);

exports.default = ButtonsGroupDropdown;