import React, { useMemo } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { PATH_TO_FORM_MODE_MAP } from 'config/routes';
import useAvailableItems from 'hooks/useAvailableItems';
import { CAMPAIGNS_ROUTE, CREATIVE_EDITOR_ROUTE_PATH, ENTITY, FORM_MODES } from 'modules/campaigns/config';
import { replacePathParams } from 'modules/campaigns/services/utils';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CreativeEditor } from '../modules/creative-editor/CreativeEditor';
import { CreativePreview } from '../modules/creative-preview';
import { VideoCreativeEditor } from '../modules/video-creative-editor';

const modesConfigs = [
    {
        id: FORM_MODES.EDIT,
    },
    {
        id: FORM_MODES.DUPLICATE,
        accountConfigurations: { [FEATURE_FLAGS.CREATIVE_DUPLICATE_ENABLED]: 'true' },
    },
];

const sponsoredPath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CREATIVE],
});
const videoPath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CREATIVE_VIDEO],
});
const sponsoredPreviewPath = replacePathParams(sponsoredPath, { mode: [FORM_MODES.PREVIEW] });

export const CreativeEditorPage = ({ onCancel, drawerMode, ...rest }) => {
    const availableModes = useAvailableItems(modesConfigs);
    const { params: { mode: modeParam } = {} } = useRouteMatch(CREATIVE_EDITOR_ROUTE_PATH);
    const mode = PATH_TO_FORM_MODE_MAP[modeParam] || FORM_MODES.EDIT;

    const sponsoredPathWithPermissions = useMemo(
        () => replacePathParams(sponsoredPath, { mode: availableModes.map(({ id }) => id) }),
        [availableModes]
    );
    useUnsavedChangesController(CREATIVE_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                path={sponsoredPathWithPermissions}
                render={props => (
                    <CreativeEditor mode={mode} onCancel={onCancel} sourcePath={CAMPAIGNS_ROUTE} {...props} {...rest} />
                )}
                drawerMode={drawerMode}
            />
            <LayerRoute
                path={sponsoredPreviewPath}
                render={props => <CreativePreview onCancel={onCancel} sourcePath={CAMPAIGNS_ROUTE} {...props} />}
                drawerMode={drawerMode}
            />
            <LayerRoute
                path={videoPath}
                render={props => (
                    <VideoCreativeEditor
                        mode={mode}
                        onCancel={onCancel}
                        sourcePath={CAMPAIGNS_ROUTE}
                        {...props}
                        {...rest}
                    />
                )}
                drawerMode={drawerMode}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CreativeEditorPage.propTypes = {
    onCancel: PropTypes.func,
};

export default CreativeEditorPage;
