import { FORCE_CAMPAIGN_PICKER_RELOAD } from './actionTypes';

const forceCampaignPickerReload = () => ({
    type: FORCE_CAMPAIGN_PICKER_RELOAD,
    payload: {
        forceCampaignPickerReloadFlag: Date.now(),
    },
});

export default forceCampaignPickerReload;
