const DEMAND_TYPE = {
    DIRECT: 'DIRECT',
    PMP_DEAL: 'PMP_DEAL',
    VIDEO_PMP_DEAL: 'VIDEO_PMP_DEAL',
    RTB_OPEN_EXCHANGE: 'RTB_OPEN_EXCHANGE',
};

export const PROGRAMMATIC_DEMAND_TYPES = new Set([
    DEMAND_TYPE.RTB_OPEN_EXCHANGE,
    DEMAND_TYPE.PMP_DEAL,
    DEMAND_TYPE.VIDEO_PMP_DEAL,
]);

export default DEMAND_TYPE;
