import React from 'react';
import { useFormDataContext, useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { MAX_URLS_COUNT } from '../../../config';
import { UrlsField } from '../../index';
import styles from '../../../creativeCreator.module.scss';

const missedAccountUrlMessage = (
    <FormattedMessage
        id="creative.editor.recommended.empty.account.url.error"
        defaultMessage="Please select an account before adding new urls."
    />
);

export const VariationsSetupSection = ({ showDescription = false }) => {
    const { value: urls } = useFormFieldValue({ field: 'urls' });
    const { formAccount } = useFormDataContext();
    const isMissedAccount = () => !formAccount?.accountId;

    return (
        <UrlsField
            className={styles['field']}
            urls={urls}
            maxItems={MAX_URLS_COUNT}
            missedAccountMessage={missedAccountUrlMessage}
            isMissedAccount={isMissedAccount()}
            showDescription={showDescription}
        />
    );
};
