import React from 'react';
import { SeparatorDropdown } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './legendMetricDropdown.module.scss';

export const LegendMetricDropdown = ({ options, value, onChange }) => {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: 'app.campaigns.graph.metricDropdown.label',
        defaultMessage: 'METRIC',
    });

    return (
        <SeparatorDropdown
            className={styles['container']}
            leftSide={label}
            options={options}
            value={value}
            onChange={onChange}
        />
    );
};
