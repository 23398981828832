import { useCallback, useMemo, useState } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useSupplyTargetingFormField } from '../../SupplyTargeting/hooks/useSupplyTargetingFormField';
import { convertSitesToDropdownFormat } from './useConvertSitesToDropdownFormat';
import { useFetchSites } from './useFetchSites';
import { useSelectedSites } from './useSelectedSites';

const defaultOptionsFilter = ({ site, isCurrentlyEditingIncluded, includedSitesSet, excludedSitesSet }) => {
    if (isCurrentlyEditingIncluded) {
        if (includedSitesSet.has(site.value)) {
            return false;
        }
    } else {
        if (includedSitesSet.size && !includedSitesSet.has(site.value)) {
            return false;
        }
        if (excludedSitesSet.has(site.value)) {
            return false;
        }
    }
    return true;
};

export const useSiteDropdownData = (isCurrentlyEditingIncluded = true, optionsFilter = defaultOptionsFilter) => {
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const { fetchAllSites } = useFetchSites();
    const { value: supplyGroup } = useSupplyTargetingFormField();
    const [rawOptions, setOptions] = useState([]);
    const options = useMemo(() => rawOptions.map(option => option || {}), [rawOptions]);
    const { allIncludedSites, allExcludedSites } = useSelectedSites();
    const includedSitesSet = useMemo(() => new Set(allIncludedSites), [allIncludedSites]);
    const excludedSitesSet = useMemo(() => new Set(allExcludedSites), [allExcludedSites]);

    const loadPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await fetchAllSites(accountName, searchText, page, pageSize, supplyGroup);
            const list = convertSitesToDropdownFormat(results).filter(site =>
                optionsFilter({ site, isCurrentlyEditingIncluded, includedSitesSet, excludedSitesSet })
            );
            return [list, total, results.length - list.length];
        },
        [
            fetchAllSites,
            accountName,
            includedSitesSet,
            excludedSitesSet,
            isCurrentlyEditingIncluded,
            optionsFilter,
            supplyGroup,
        ]
    );

    return {
        options,
        setOptions,
        loadPage,
    };
};
