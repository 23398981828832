export const GW_SIGNATURE_HEADER_NAME = 'X-GW-Signature';

class GWSignatureManager {
    constructor() {
        this.timePoint = 0;
    }

    encodeExpectedServerTimeNow() {
        return (this.timePoint + Date.now())
            .toString()
            .split('')
            .map((element, index) => {
                return 9 - element;
            })
            .join('');
    }

    updateSignature(response) {
        try {
            const serverTimeFromResponse = Number(response?.headers?.get(GW_SIGNATURE_HEADER_NAME));
            if (serverTimeFromResponse && !isNaN(serverTimeFromResponse)) {
                this.timePoint = serverTimeFromResponse - Date.now();
            }
        } catch (error) {}
    }

    getHeaders() {
        return { [GW_SIGNATURE_HEADER_NAME]: this.encodeExpectedServerTimeNow() };
    }
}
export const gwSignatureManager = new GWSignatureManager();
