import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { setSelectedRows } from '../../../actions';
import {
    CheckboxHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    CheckboxCellRenderer,
} from '../../../components';
import { gridNumberFormatter, formattedNumberValueGetter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import { checkBoxSelectionColumn, leftPinnedColumn, numericColumn, staticColumn } from '../../columns';
import * as FIELDS from '../../fields';
import { onlySelectedCampaignColumn } from '../../predicates';
import columnPermissions from './columnPermissions';

const audienceReportColumnDefinition = [
    {
        headerComponentFramework: CheckboxHeaderRenderer,
        headerName: FIELDS.IS_SELECTED.label,
        field: FIELDS.IS_SELECTED.field,
        type: [leftPinnedColumn, checkBoxSelectionColumn, staticColumn],
        cellRendererParams: {
            onClick: setSelectedRows,
        },
        headerComponentParams: {
            onClick: setSelectedRows,
        },
        cellRendererFramework: CheckboxCellRenderer,
        csv: false,
        ...columnPermissions[FIELDS.IS_SELECTED.field],
    },
    {
        headerName: FIELDS.PARTNER_NAME.label,
        field: FIELDS.PARTNER_NAME.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.PARTNER_NAME.cellRendererFramework,
    },
    {
        headerName: FIELDS.AUDIENCE_NAME.label,
        field: FIELDS.AUDIENCE_NAME.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.AUDIENCE_NAME.cellRenderer,
        cellRendererParams: FIELDS.AUDIENCE_NAME.cellRendererParams,
    },
    {
        headerName: FIELDS.DATA_PARTNER_AUDIENCE_ID.label,
        field: FIELDS.DATA_PARTNER_AUDIENCE_ID.field,
        type: [numericColumn],
    },
    {
        headerName: FIELDS.CVR_VS_AVG.label,
        field: FIELDS.CVR_VS_AVG.field,
        cellRendererFramework: FIELDS.CVR_VS_AVG.cellRenderer,
        headerComponentParams: FIELDS.CVR_VS_AVG.headerComponentParams,
        headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        visibilityCondition: onlySelectedCampaignColumn,
    },
    ...[
        FIELDS.IMPRESSIONS,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.IMPRESSIONS_PCT,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CPM,
        FIELDS.VCPM,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.SPENT,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(audienceReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.AUDIENCE].id);
