import React from 'react';
import classnames from 'classnames/bind';
import { OkIcon } from 'taboola-ultimate-ui';
import { useIntl } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { IconLearnMore } from './IconLearnMore';
import styles from './headerSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const HeaderSection = ({ titleId, learnMoreLinkId, isComplete, isCollapsed }) => {
    const { formatMessage } = useIntl();
    const learnMoreVisible = learnMoreLinkId && !isCollapsed;

    const learnMoreLink = formatMessage({
        id: `onboarding.assistant.${learnMoreLinkId}.learn.more.link`,
        defaultMessage: 'https://help.taboola.com',
    });

    return (
        <div className={styles['container']}>
            <OkIcon className={classNameBuilder('ok-icon', isComplete ? 'complete' : 'incomplete')} />
            <h1 className={styles['title']}>
                {formatMessage({ id: `onboarding.assistant.${titleId}.title`, defaultMessage: 'Title' })}
            </h1>
            {learnMoreVisible && <IconLearnMore learnMoreLink={learnMoreLink} />}
        </div>
    );
};
