'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.omit');

var _lodash2 = _interopRequireDefault(_lodash);

var _checkboxContext = require('../checkboxContext');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'checkboxDefaultColor': 'var(--green-5)',
    'intermediateDefaultColor': 'var(--gray-6)',
    'taboola-svg-icon': 'baseCheckbox__taboola-svg-icon___11EbP',
    'custom-checkbox': 'baseCheckbox__custom-checkbox___3UJc9',
    'custom-checkbox-check': 'baseCheckbox__custom-checkbox-check___1yCru',
    'custom-checkbox-intermediate': 'baseCheckbox__custom-checkbox-intermediate___2nb6A',
    'label': 'baseCheckbox__label___2qia2',
    'checked': 'baseCheckbox__checked___3R3rF'
};


var classNameBuilder = _bind2.default.bind(styles);

var useStyles = function useStyles(_ref) {
    var checked = _ref.checked,
        disabled = _ref.disabled,
        isIntermediate = _ref.isIntermediate,
        intermediateColor = _ref.intermediateColor,
        checkboxColor = _ref.checkboxColor;
    return (0, _react.useMemo)(function () {
        if (disabled) {
            return {
                labelStyle: { borderColor: {} },
                checkboxBoxStyle: {},
                checkMarkStyle: {}
            };
        }

        if (isIntermediate) {
            return {
                labelStyle: { borderColor: intermediateColor },
                checkboxBoxStyle: { borderColor: intermediateColor, backgroundColor: intermediateColor },
                checkMarkStyle: { borderColor: intermediateColor }
            };
        }

        if (checked) {
            return {
                labelStyle: { borderColor: checkboxColor },
                checkboxBoxStyle: { borderColor: checkboxColor },
                checkMarkStyle: { borderColor: checkboxColor }
            };
        }

        return {
            labelStyle: {},
            checkboxBoxStyle: {},
            checkMarkStyle: { borderColor: checkboxColor }
        };
    }, [checked, disabled, isIntermediate, checkboxColor, intermediateColor]);
};

var BaseCheckbox = function BaseCheckbox(_ref2) {
    var value = _ref2.value,
        disabled = _ref2.disabled,
        checkboxColor = _ref2.checkboxColor,
        intermediateColor = _ref2.intermediateColor,
        children = _ref2.children,
        labelClassName = _ref2.labelClassName,
        checkedLabelClassName = _ref2.checkedLabelClassName,
        inputClassName = _ref2.inputClassName,
        isIntermediate = _ref2.isIntermediate,
        independent = _ref2.independent,
        label = _ref2.label,
        rest = _objectWithoutProperties(_ref2, ['value', 'disabled', 'checkboxColor', 'intermediateColor', 'children', 'labelClassName', 'checkedLabelClassName', 'inputClassName', 'isIntermediate', 'independent', 'label']);

    var checkboxCtx = (0, _checkboxContext.useCheckboxContext)();

    var _ref3 = independent ? rest : checkboxCtx,
        selectedValues = _ref3.selectedValues,
        onSelectCheckbox = _ref3.onSelectCheckbox,
        onUnselectCheckbox = _ref3.onUnselectCheckbox,
        onIntermediateSelect = _ref3.onIntermediateSelect;

    var checked = (0, _react.useMemo)(function () {
        return selectedValues.includes(value);
    }, [selectedValues, value]);

    var _useStyles = useStyles({
        disabled: disabled,
        isIntermediate: isIntermediate,
        intermediateColor: intermediateColor,
        checkboxColor: checkboxColor,
        checked: checked
    }),
        labelStyle = _useStyles.labelStyle,
        checkboxBoxStyle = _useStyles.checkboxBoxStyle,
        checkMarkStyle = _useStyles.checkMarkStyle;

    var handleClick = function handleClick(e) {
        if (disabled) {
            return;
        }

        if (isIntermediate) {
            onIntermediateSelect(value, e);
        } else if (checked) {
            onUnselectCheckbox(value, e);
        } else {
            onSelectCheckbox(value, e);
        }
    };

    var checkedLabelMergedClassName = classNameBuilder('checked', checkedLabelClassName);
    var labelMergedClassName = classNameBuilder('label', labelClassName, _defineProperty({}, checkedLabelMergedClassName, checked && !isIntermediate));
    var checkBoxClassName = classNameBuilder('custom-checkbox', inputClassName);
    var checkMarkIcon = isIntermediate ? 'custom-checkbox-intermediate' : 'custom-checkbox-check';
    var checkMarkClassName = classNameBuilder(checkMarkIcon);

    return _react2.default.createElement(
        'label',
        Object.assign({}, (0, _lodash2.default)(rest, Object.keys(_checkboxContext.CheckboxProvider.propTypes)), {
            className: labelMergedClassName,
            disabled: disabled,
            onClick: handleClick,
            style: labelStyle
        }),
        _react2.default.createElement('span', {
            role: 'checkbox',
            'aria-checked': checked,
            'aria-label': label,
            disabled: disabled
        }),
        _react2.default.createElement(
            'span',
            { className: checkBoxClassName, style: checkboxBoxStyle },
            _react2.default.createElement('span', { className: checkMarkClassName, style: checkMarkStyle })
        ),
        children
    );
};

BaseCheckbox.propTypes = Object.assign({
    /** Component to displayed next to the checkbox */
    children: _propTypes2.default.node,
    /** Value of the radio */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.bool]).isRequired,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Checkbox input color */
    checkboxColor: _propTypes2.default.string,
    /** Checkbox input color for isIntermediate state */
    intermediateColor: _propTypes2.default.string,
    /** Apply class to style the checkbox label */
    labelClassName: _propTypes2.default.string,
    /** Apply class to checked label */
    checkedLabelClassName: _propTypes2.default.string,
    /** Apply class to style the radio input */
    inputClassName: _propTypes2.default.string,
    /** Disable / Enable flag for third checkbox state */
    isIntermediate: _propTypes2.default.bool,
    /** Disregard checkbox group context */
    independent: _propTypes2.default.bool
}, (0, _lodash2.default)(_checkboxContext.CheckboxProvider.propTypes, ['children']));

BaseCheckbox.defaultProps = {
    disabled: false,
    checkboxColor: styles.checkboxDefaultColor,
    intermediateColor: styles.intermediateDefaultColor,
    isIntermediate: false
};

exports.default = BaseCheckbox;