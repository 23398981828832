import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import MarketplaceAudiencesFieldTooltip from '../MarketplaceAudiences/MarketplaceAudiencesFieldTooltip';
import { MarketplaceAudiencesWithBundling } from '../MarketplaceAudiencesWithBundling/MarketplaceAudiencesWithBundling';
import { MarketplaceAudiences } from './MarketplaceAudiences';
import { useIsMarketplaceAudiencesAndLogicEnabled, useIsMarketplaceAudiencesBundlingEnabled } from './hooks';
import styles from '../commonEditor.module.scss';

const MarketplaceAudiencesField = () => {
    const { formAccount } = useFormDataContext();
    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();
    const isBundlingEnabled = useIsMarketplaceAudiencesBundlingEnabled();

    return (
        <FormField
            inputId="marketplace-audiences"
            label={
                <FormattedMessage
                    id="campaign.editor.targeting.marketplace.audiences.title"
                    defaultMessage="Marketplace Audiences"
                />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.marketplace.audiences.description"
                    defaultMessage="Target people who match at least one of the following audiences. The audience size estimates the number of monthly users in your selected locations."
                />
            }
            helpText={<MarketplaceAudiencesFieldTooltip isAndLogicEnabled={isAndLogicEnabled} />}
            containerClass={styles['input']}
        >
            {isBundlingEnabled ? (
                <MarketplaceAudiencesWithBundling selectedAccountId={formAccount.accountName} />
            ) : (
                <MarketplaceAudiences selectedAccountId={formAccount.accountName} />
            )}
        </FormField>
    );
};

export default MarketplaceAudiencesField;
