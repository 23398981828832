import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { getCountryRegions } from '../../../../../services/api/geoTargetingApi';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import transfromSubCountryFromGW from '../../../services/transformers/subCountryTransformer';
import { requestFetchRegions, fetchRegionsSuccess, fetchRegionsError } from '../actions';

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.geo.targeting.regions.fetch.error"
            defaultMessage="We were not able to fetch list of regions"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const fetchRegions = countryCode => dispatch => {
    dispatch(requestFetchRegions());
    getCountryRegions(countryCode)
        .then(rawList => transfromSubCountryFromGW(rawList))
        .then(listOfRegions => {
            dispatch(fetchRegionsSuccess(listOfRegions));
        })
        .catch(error => {
            dispatch(addIndication(errorIndication));
            dispatch(fetchRegionsError(error));
        });
};

export default fetchRegions;
