import React, { useContext, useEffect, useRef } from 'react';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { BID_STRATEGIES } from '../../config';
import { useCpaGoalFormFieldValue } from '../CpaGoal/useCpaGoalFormFieldValue';
import { useIsApplyCapActiveField } from './ApplyCapSection/useIsApplyCapActiveField';
import { bidStrategiesCategory, strategyToCategoryMap } from './hooks/useBidStrategiesOptions';
import { useBidStrategyFormField } from './hooks/useBidStrategyFormField';

const BidStrategyContext = React.createContext({});
export const useBidStrategyCacheContext = () => useContext(BidStrategyContext);
export const { Provider: BidStrategyCacheProvider } = BidStrategyContext;

export const getBidStrategyCacheKey = ({ bidStrategyCategory, pricingModel, isApplyCapActive }) =>
    `${bidStrategyCategory}_${pricingModel}_${isApplyCapActive}`;
export const getBidCacheKey = ({ bidStrategyCategory, pricingModel, bidStrategy }) =>
    `${bidStrategyCategory}_${pricingModel}_${bidStrategy}`;

export const withBidStrategyCacheProvider = Component => props => {
    const { value: bidStrategy } = useBidStrategyFormField();
    const { value: pricingModel } = useFormFieldValue({
        field: 'pricingModel',
    });
    const { value: bid, isEqualToInitial: isBidEqualToInitial } = useFormFieldValue({ field: 'bid' });
    const { value: targetCpa, isEqualToInitial: isTargetCpaEqualToInitial } = useCpaGoalFormFieldValue();
    const { value: cpcCap, isEqualToInitial: isCpcCapEqualToInitial } = useFormFieldValue({
        field: 'cpcCap',
    });
    const { value: isApplyCapActive, isEqualToInitial: isApplyCapEqualToInitial } = useIsApplyCapActiveField();

    const bidStrategyCache = useRef({});
    const pricingModelCache = useRef({});
    const bidCache = useRef({});
    const targetCpaCache = useRef({});
    const cpcCapCache = useRef({});
    const isApplyCapActiveCache = useRef({});

    useEffect(() => {
        const bidStrategyCategory = strategyToCategoryMap[bidStrategy];
        const calculatedIsApplyCapActive =
            bidStrategyCategory === bidStrategiesCategory.MAX_CONVERSIONS ? isApplyCapActive : undefined;

        bidStrategyCache.current = {
            ...bidStrategyCache.current,
            [getBidStrategyCacheKey({
                bidStrategyCategory,
                pricingModel,
                isApplyCapActive: calculatedIsApplyCapActive,
            })]: bidStrategy,
        };
    }, [bidStrategy, pricingModel, isApplyCapActive]);

    useEffect(() => {
        const bidStrategyCategory = strategyToCategoryMap[bidStrategy];

        pricingModelCache.current = { ...pricingModelCache.current, [bidStrategyCategory]: pricingModel };
    }, [bidStrategy, pricingModel]);

    useEffect(() => {
        const bidStrategyCategory = strategyToCategoryMap[bidStrategy];
        const bidValue = isBidEqualToInitial ? undefined : bid;

        bidCache.current = {
            ...bidCache.current,
            [getBidCacheKey({ bidStrategyCategory, pricingModel, bidStrategy })]: bidValue,
        };
    }, [bid, bidStrategy, isBidEqualToInitial, pricingModel]);

    useEffect(() => {
        if (bidStrategy === BID_STRATEGIES.TARGET_CPA) {
            const targetCpaValue = isTargetCpaEqualToInitial ? undefined : targetCpa;
            targetCpaCache.current = { targetCpa: targetCpaValue };
        }
    }, [bidStrategy, isTargetCpaEqualToInitial, targetCpa]);

    useEffect(() => {
        if (bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS && isApplyCapActive) {
            const cpcCapValue = isCpcCapEqualToInitial ? undefined : cpcCap;
            cpcCapCache.current = { cpcCap: cpcCapValue };
        }
    }, [bidStrategy, cpcCap, isApplyCapActive, isCpcCapEqualToInitial]);

    useEffect(() => {
        if (bidStrategy === BID_STRATEGIES.TARGET_CPA || bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS) {
            const isApplyCapActiveValue = isApplyCapEqualToInitial ? undefined : isApplyCapActive;
            isApplyCapActiveCache.current = { isApplyCapActive: isApplyCapActiveValue };
        }
    }, [bidStrategy, isApplyCapActive, isApplyCapEqualToInitial]);

    return (
        <BidStrategyCacheProvider
            value={{
                bidStrategyCache: bidStrategyCache.current,
                pricingModelCache: pricingModelCache.current,
                bidCache: bidCache.current,
                targetCpaCache: targetCpaCache.current,
                cpcCapCache: cpcCapCache.current,
                isApplyCapActiveCache: isApplyCapActiveCache.current,
            }}
        >
            <Component {...props} />
        </BidStrategyCacheProvider>
    );
};
