import classnames from 'classnames/bind';
import { LoadingBar } from '../LoadingBar';
import { SuggestionOptions } from './SuggestionOptions';
import styles from './suggestionsWrapper.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SuggestionsWrapper = ({
    menuStyles,
    dropdownStyles,
    options,
    onDropdownChange,
    shouldHideOptions,
    optionsLoading,
    className,
    children,
    loadingMessage,
    loadingMessageDuration,
    ...rest
}) => {
    return (
        <div className={classNameBuilder('container', className)} {...rest}>
            <SuggestionOptions
                menuStyles={menuStyles}
                dropdownStyles={dropdownStyles}
                options={options}
                open={!optionsLoading && !shouldHideOptions}
                onDropdownChange={onDropdownChange}
            >
                {children}
            </SuggestionOptions>
            {optionsLoading && (
                <div className={styles['loading-bar-container']}>
                    <LoadingBar durationInSeconds={loadingMessageDuration} loadingMessage={loadingMessage} />
                </div>
            )}
        </div>
    );
};
