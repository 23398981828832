import React from 'react';
import { FormField, INDICATION_TYPES, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormValidatedValue, validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import { FunnelRules } from './FunnelRules/FunnelRules';
import { FunnelsDropDown } from './FunnelsDropDown/FunnelsDropDown';
import { FUNNEL_MODE } from './consts/FunnelModes';
import styles from './funnel.module.scss';

const FormFieldWithIndication = withIndication(FormField);

const validations = [
    {
        validationFn: (value, _, { mode, isDefaultFunnelEnabled }) => {
            return !(isDefaultFunnelEnabled && mode === FUNNEL_MODE.CUSTOM && validationFunctions.isEmpty(value));
        },
        messageId: 'campaign.editor.unip.funnel.errorMessage',
        defaultMessage: 'Please select a Funnel',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const Funnel = () => {
    const isPermitted = usePermissions(['TA_CONVERSION_FUNNEL']);
    const isConversionFunnelEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_FUNNEL_ENABLED]: 'true',
    });

    const isDefaultFunnelEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_DEFAULT_FUNNEL_ENABLED]: 'true',
    });

    const { value: mode, onChange: onModeChange } = useFormFieldValue({ field: 'funnel.mode' });
    const {
        value: funnelId,
        onChange: onFunnelChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'funnel.funnelId',
        validations,
        validationDependencies: {
            mode,
            isDefaultFunnelEnabled,
        },
    });

    const onModeChangeHandler = value => {
        onFunnelChange('');
        onModeChange(value);
    };

    if (!isPermitted || !isConversionFunnelEnabled) {
        return null;
    }

    return (
        <div className={styles['list']}>
            <FormFieldWithIndication
                label={<FormattedMessage id="campaign.editor.unip.funnel" defaultMessage="Funnel (internal only)" />}
                description={
                    <FormattedMessage
                        id="campaign.editor.unip.funnel.description"
                        defaultMessage="Please select the relevant conversion funnel for this campaign. This will NOT influence the campaign's performance, and is used for internal data collection and model training."
                    />
                }
                {...indicationData}
            >
                {isDefaultFunnelEnabled && (
                    <RadioGroup name="funnelRadioSelected" selectedValue={mode} onChange={onModeChangeHandler}>
                        <Radio
                            value={FUNNEL_MODE.DEFAULT}
                            title={
                                <FormattedMessage
                                    id="campaign.editor.unip.funnel.description.radio.default"
                                    defaultMessage="Account Default"
                                />
                            }
                        />
                        <Radio
                            value={FUNNEL_MODE.CUSTOM}
                            title={
                                <FormattedMessage
                                    id="campaign.editor.unip.funnel.description.radio.custom"
                                    defaultMessage="Funnel selection"
                                />
                            }
                        />
                    </RadioGroup>
                )}{' '}
                <FunnelsDropDown />
                {funnelId && (mode !== FUNNEL_MODE.DEFAULT || !isDefaultFunnelEnabled) && (
                    <FunnelRules funnelId={funnelId} />
                )}
            </FormFieldWithIndication>
        </div>
    );
};
