import React from 'react';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { AUDIENCE_TYPE } from '../../config';

export const PixelBasedAudienceSetupDetailsLabel = () => {
    const { value: type } = useFormFieldValue({
        field: 'type',
    });

    return type === AUDIENCE_TYPE.BASIC ? (
        <FormattedMessage
            id="audience.editor.pixel.based.audience.conditions.step.label"
            defaultMessage="Audience Conditions"
        />
    ) : (
        <FormattedMessage id="audience.editor.pixel.based.audience.events.step.label" defaultMessage="Events" />
    );
};

export default PixelBasedAudienceSetupDetailsLabel;
