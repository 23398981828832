import React from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import { MinConversionsGoal } from './MinConversionsGoal/MinConversionsGoal';
import { TargetCpaInput } from './TargetCpaInput';
import { useClearFieldsOnUnselect } from './useClearFieldsOnUnselect';
import styles from './TargetCpaInput.module.scss';

export const TargetCpaRadio = ({
    targetCpaValidations,
    targetCpaValidationDependencies,
    shouldUseTooltipMessageIndication,
    ...props
}) => {
    const shouldRenderMinConversionGoal = useConfigMatch({ [FEATURE_FLAGS.MIN_CONVERSIONS_GOAL_ENABLED]: 'true' });
    const { value: bidStrategy } = useBidStrategyFormField();
    // reset targetCpa and minConversionsGoal when targetCpa is not selected
    useClearFieldsOnUnselect();
    return (
        <Radio {...props}>
            {bidStrategy === BID_STRATEGIES.TARGET_CPA && (
                <div className={styles['target-cpa-input']}>
                    <TargetCpaInput
                        additionalValidations={targetCpaValidations}
                        additionalDependencies={targetCpaValidationDependencies}
                        showLabel={false}
                        shouldUseTooltipMessageIndication={shouldUseTooltipMessageIndication}
                    />
                    {shouldRenderMinConversionGoal ? <MinConversionsGoal /> : null}
                </div>
            )}
        </Radio>
    );
};

export default TargetCpaRadio;
