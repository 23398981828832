import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import { SITE_DESCRIPTION, SITE_ID, SITE_NAME, PUBLISHER_BLOCKING, PUBLISHER_BLOCKING_LEVEL } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { customColumns, performanceColumns, setupColumns, defaultColumns } from './reportPresets';

export const siteReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.SITE],
    endpoint: 'campaign-report-by-site',
    graph,
    rowIdField: SITE_ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.bySite.search.placeholder',
    inlineEditPermissions: ['CAMPAIGN_EDIT'],
    rowHeight: 42,
    searchField: SITE_DESCRIPTION.field,

    // TODO: DEV-58584 need to convert filter names in the GW. remove sortMap
    sortMap: {
        [SITE_NAME.field]: SITE_DESCRIPTION.field,
        [PUBLISHER_BLOCKING_LEVEL.field]: PUBLISHER_BLOCKING.field,
    },
    initialFilters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP200, label: 'Top 200', messageId: 'report.topResults.200' }],
            accountConfigurations: { [FEATURE_FLAGS.SITE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true' },
        },
    ],

    filters: [
        { id: FILTER_TYPE.SITE_BLOCKED_STATUS },
        {
            id: FILTER_TYPE.TOP_RESULTS,
            accountConfigurations: { [FEATURE_FLAGS.SITE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true' },
        },
        {
            id: FILTER_TYPE.PLATFORM,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.COUNTRY,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.OS_FAMILY,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.OS_VERSION,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.BROWSER,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.TARGET_PAGE_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.IAB_CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.LANGUAGE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.CLICKS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.SPENT,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.VISIBLE_IMPRESSIONS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.IMPRESSIONS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.VCTR,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CTR,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.AVG_CPC,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CVR,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.ACTIONS_CONVERSIONS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CPA,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.VCPM,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CPM,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CONVERSIONS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.ROAS,
            accountConfigurations: {
                [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
                [FEATURE_FLAGS.SITE_REPORT_DONT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
    ],
};

export default siteReportConfig;
