import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { isEmpty, map, size } from 'lodash';
import { DoneIcon } from 'tuui';
import { SetupIcon, TargetIcon, Wizard, WizardStep, Dotdotdot, HelpTooltip } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormLoadingOverlay } from 'modules/taboola-common-frontend-modules/forms/components/FormLoadingOverlay/FormLoadingOverlay';
import { FormattedNumber, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { clearHasUnsavedChanges } from '../../../actions';
import { CommonCollapsibleCard, InternalLink } from '../../../components';
import Logo from '../../../components/AppHeader/components/Logo';
import { CHAT_RENDER_MODE } from '../../../components/ChatAgent/chatRenderMode';
import { useChatAgentPermitted } from '../../../components/ChatAgent/hooks/useChatAgentPermitted';
import { TooltipWrapper } from '../../../components/TooltipWrapper/TooltipWrapper';
import { COMPONENT_STATUS } from '../../../services/constants';
import { PaymentMethodAddressSection } from '../../billing-and-payments/components/PaymentMethodAddressSection/PaymentMethodAddressSection';
import { PaymentMethodCreditCardSection } from '../../billing-and-payments/components/PaymentMethodCreditCardSection/PaymentMethodCreditCardSection';
import { CREDIT_LIMIT_MODEL } from '../../billing-and-payments/config/CreditLimitModel';
import { PAYMENT_TYPE } from '../../billing-and-payments/config/PaymentType';
import { withPaymentProvider } from '../../billing-and-payments/providers/payment-providers/PaymentProvider/PaymentProvider';
import SectionHeader from '../../campaigns/components/Form/SectionHeader/SectionHeader';
import { CAMPAIGNS_ROUTE } from '../../campaigns/config';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import {
    Branding,
    BudgetSectionSponsored,
    MarketingObjective,
    NameCreate,
    SetupSection,
} from '../../campaigns/modules/common-campaign-form';
import { CampaignGroupField } from '../../campaigns/modules/common-campaign-form/components/CampaignGroupField/CampaignGroupField';
import GeoTargeting from '../../campaigns/modules/common-campaign-form/components/GeoTargeting/GeoTargeting';
import TimeFrame from '../../campaigns/modules/common-campaign-form/components/TimeFrame/TimeFrame';
import { campaignInitialValues } from '../../campaigns/modules/common-campaign-form/config';
import { AiVariationsContextProvider } from '../../campaigns/modules/creative-creator/components/AiVariationsContextProvider';
import { AiVariationsPreviewSection } from '../../campaigns/modules/creative-creator/components/AiVariationsCreation/components/AiVariationsPreviewSection';
import { AiVariationsContentSection } from '../../campaigns/modules/creative-creator/components/AiVariationsCreation/components/ContentSection/AiVariationsContentSection';
import { VariationsMediaSection } from '../../campaigns/modules/creative-creator/components/VariationsCreation/components/VariationsMediaSection';
import { VariationsSetupSection } from '../../campaigns/modules/creative-creator/components/VariationsCreation/components/VariationsSetupSection';
import { creativeInitialValues } from '../../campaigns/modules/creative-creator/config/creativeInitialValues';
import { AI_VARIATIONS_FIELD_NAMES } from '../../campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { CHAT_AGENT_VERSION_V2 } from '../../taboola-common-frontend-modules/chat-agent/config/chatAgentVersionV2';
import { useChatAgentContext } from '../../taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { useLocalStorage } from '../../taboola-common-frontend-modules/storage';
import { ONBOARDING_CHAT_DONE_KEY } from '../config/onboardingSkipKey';
import { ONBOARDING_FORM_PATH } from '../config/routeTypes';
import { InitWidget } from './components/InitWidget/InitWidget';
import { getPlatformMessageId, MediaPlanBudget } from './components/MediaPlanBudget/MediaPlanBudget';
import { MediaPlanCountryTargeting } from './components/MediaPlanCountryTargeting/MediaPlanCountryTargeting';
import { MediaPlanItem } from './components/MediaPlanItem/MediaPlanItem';
import { OnboardingFormButtons } from './components/OnboardingFormButtons/OnboardingFormButtons';
import { OnboardingLoader } from './components/OnboardingLoader/OnboardingLoader';
import { useOnboardingCreatorState } from './hooks/useOnboardingCreatorState';
import { useStepsLoader } from './hooks/useStepsLoader';
import styles from './OnboardingChatForm.module.scss';

const MAX_CAMPAIGNS_COUNT = 2;
const classNameBuilder = classnames.bind(styles);

const getCampaignInfo = (campaign, formatMessage) =>
    `(${formatMessage({ id: `campaign.editor.bid.platformParting.${getPlatformMessageId(campaign)}` })})`;

const Onboarding = () => {
    const isChatPermitted = useChatAgentPermitted();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { submitStatus } = useFormDataContext();
    const { isChatConnected } = useChatAgentContext();
    const chatContainerRef = useRef();
    const { step, isSubmitDisable, submitCampaign, submitCreative, submitPayment, submittingEntityName } =
        useOnboardingCreatorState();
    const loading = useStepsLoader({ step, submitStatus });
    const { value: mediaPlan } = useFormFieldValue({ field: 'mediaPlan' });
    const isMediaPlanEmpty = isEmpty(mediaPlan);
    const { value: campaignMap } = useFormFieldValue({ field: 'campaignMap' });
    const navigate = useNavigate();
    const [getLocalStorage, setLocalStorage] = useLocalStorage();
    const isOnboardingPassed = getLocalStorage(ONBOARDING_CHAT_DONE_KEY);
    const { open: openModal } = useModal();
    const skipHandler = useCallback(
        async event => {
            event.preventDefault();
            const confirmed = await openModal({
                title: (
                    <FormattedMessage
                        id="onboarding.form.exit.confirm.title"
                        defaultMessage="Skip onboarding form confirmation"
                    />
                ),
                content: (
                    <FormattedMessage
                        id="onboarding.form.exit.confirm.details"
                        defaultMessage="All unsaved data will be lost, would you like to exit?"
                    />
                ),
                formProps: {
                    submitButtonText: (
                        <FormattedMessage id="onboarding.form.exit.confirm.button" defaultMessage="Exit" />
                    ),
                },
            });

            if (!confirmed) {
                return;
            }

            dispatch(clearHasUnsavedChanges());
            setLocalStorage(ONBOARDING_CHAT_DONE_KEY, 'true');
            navigate(CAMPAIGNS_ROUTE);
        },
        [dispatch, navigate, setLocalStorage, openModal]
    );
    const { openChat, closeChat, setChatReady } = useChatAgentContext();
    const containerRendered = !!chatContainerRef.current;
    const { currency, creditData } = useAccount();
    const showBillingData = creditData?.creditLimitModel === CREDIT_LIMIT_MODEL.CCB;

    useEffect(() => {
        if (chatContainerRef.current) {
            openChat({
                container: chatContainerRef.current,
                client: CHAT_AGENT_VERSION_V2,
                chatRenderMode: CHAT_RENDER_MODE.INPUT_ONLY,
            });
            setChatReady(true);
        }
    }, [containerRendered, openChat, setChatReady]);

    useEffect(() => {
        return closeChat;
    }, [closeChat]);

    useEffect(() => {
        if (!isChatPermitted || isOnboardingPassed) {
            navigate(CAMPAIGNS_ROUTE);
        }
    }, [isChatPermitted, isOnboardingPassed, navigate]);

    useUnsavedChangesController(ONBOARDING_FORM_PATH);

    if (isOnboardingPassed) {
        return null;
    }

    return (
        <div className={classNameBuilder('container')}>
            <div className={classNameBuilder('header', { 'with-media-plan': !isMediaPlanEmpty })}>
                <Logo className={styles['logo']} disabled />
                {!isMediaPlanEmpty && (
                    <InternalLink to={CAMPAIGNS_ROUTE} onClick={skipHandler}>
                        <FormattedMessage id="onboarding.form.exit.explore" defaultMessage="Exit and explore Taboola" />
                    </InternalLink>
                )}
            </div>
            <div className={classNameBuilder('chat-and-form-container')}>
                <div
                    className={classNameBuilder('chat-wrapper', {
                        narrow: step === 1 + size(campaignMap),
                        connected: isChatConnected(),
                        aside: !isMediaPlanEmpty,
                    })}
                >
                    <InitWidget />
                    <div className={classNameBuilder('chat-container')} ref={chatContainerRef}></div>
                </div>
                <div
                    className={classNameBuilder('wizard-wrapper', {
                        hidden: isMediaPlanEmpty,
                        wide: step > 0,
                        wider: step === 1 + size(campaignMap),
                    })}
                >
                    {(submitStatus !== COMPONENT_STATUS.INITIAL || loading) && (
                        <FormLoadingOverlay
                            loader={() => (
                                <OnboardingLoader
                                    title={
                                        <FormattedMessage
                                            id="onboarding.form.generating.your.data"
                                            defaultMessage="Generating Your Data"
                                            values={{ data: submittingEntityName }}
                                        />
                                    }
                                    content={
                                        <FormattedMessage
                                            id="onboarding.form.this.take.time"
                                            defaultMessage="This may take a couple of minutes..."
                                        />
                                    }
                                />
                            )}
                            className={styles['overlay-loading']}
                        />
                    )}
                    <Wizard
                        step={step}
                        wizardTemplateColumns="none"
                        wizardContentClassName={styles['wizard-content']}
                        wizardNavigationClassName={classNameBuilder('wizard-navigation', { 'with-button': step > 0 })}
                        className={styles['wizard-step']}
                        activeClassName={styles['wizard-step-active']}
                        completeClassName={styles['wizard-step-complete']}
                        stepClassName={styles['wizard-step-inner']}
                        buttonNumberClassName={styles['wizard-step-button']}
                        substepClassName={styles['wizard-sub-step-button']}
                        substepActiveClassName={styles['wizard-sub-step-active']}
                        substepCompleteClassName={styles['wizard-sub-step-complete']}
                        completeIcon={<DoneIcon className={styles['complete-icon']} />}
                    >
                        <WizardStep
                            id="media-plan"
                            label={<FormattedMessage id="onboarding.form.media.plan" defaultMessage="Media Plan" />}
                        >
                            <div className={classNameBuilder('media-plan-wrapper', 'button-shifter')}>
                                <div className={styles['media-plan-inner']}>
                                    <div className={styles['media-plan-header']}>
                                        <FormattedMessage
                                            id="onboarding.form.media.plan.title"
                                            defaultMessage="My Media Plan"
                                        />
                                        <span className={styles['preview-tag-wrapper']}>
                                            <FormattedMessage
                                                id="onboarding.form.media.plan.preview.tag"
                                                defaultMessage="Preview"
                                                className={styles['preview-tag']}
                                            />
                                            <HelpTooltip>
                                                <FormattedMessage
                                                    id="onboarding.form.media.plan.preview.help"
                                                    defaultMessage="This is a preview of your media plan and cannot be edited here. To make changes, please use Abby."
                                                />
                                            </HelpTooltip>
                                        </span>
                                    </div>
                                    <div className={styles['media-plan-section']}>
                                        {mediaPlan?.accountBrandName && (
                                            <WizardStep id="accountBrandName" label="">
                                                <MediaPlanItem
                                                    propName="accountBrandName"
                                                    key={mediaPlan?.accountBrandName}
                                                >
                                                    {mediaPlan?.accountBrandName}
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                        {mediaPlan?.landingPageUrl && (
                                            <WizardStep id="landingPageUrl" label="">
                                                <MediaPlanItem
                                                    propName="landingPageUrl"
                                                    key={mediaPlan?.landingPageUrl}
                                                >
                                                    <TooltipWrapper tooltipContent={mediaPlan?.landingPageUrl}>
                                                        <Dotdotdot lines={2}>{mediaPlan?.landingPageUrl}</Dotdotdot>
                                                    </TooltipWrapper>
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                    </div>
                                    <div className={styles['media-plan-section']}>
                                        {mediaPlan?.marketingObjective && (
                                            <WizardStep id="marketingObjective" label="">
                                                <MediaPlanItem
                                                    propName="marketingObjective"
                                                    key={mediaPlan?.marketingObjective}
                                                >
                                                    <FormattedMessage
                                                        id={`campaign.marketing.objective.${mediaPlan?.marketingObjective}.title`}
                                                    />
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                        {mediaPlan?.countryTargeting && (
                                            <WizardStep id="countryTargeting" label="">
                                                <MediaPlanItem
                                                    propName="countryTargeting"
                                                    key={mediaPlan?.countryTargeting}
                                                >
                                                    <MediaPlanCountryTargeting />
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                    </div>
                                    <div className={styles['media-plan-section']}>
                                        {mediaPlan?.cpaGoal && (
                                            <WizardStep id="cpaGoal" label="">
                                                <MediaPlanItem propName="cpaGoal" key={mediaPlan?.cpaGoal}>
                                                    <FormattedNumber
                                                        value={mediaPlan?.cpaGoal}
                                                        currency={currency}
                                                        variant="currency"
                                                    />
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                    </div>
                                    <div className={styles['media-plan-section']}>
                                        {mediaPlan?.budget && (
                                            <WizardStep id="budget" label="">
                                                <MediaPlanItem propName="budget" key={mediaPlan?.budget}>
                                                    <MediaPlanBudget />
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                        {mediaPlan?.biddingStrategy && (
                                            <WizardStep id="biddingStrategy" label="">
                                                <MediaPlanItem
                                                    propName="biddingStrategy"
                                                    key={mediaPlan?.biddingStrategy}
                                                >
                                                    <FormattedMessage
                                                        id={`app.campaigns.campaign.bidStrategy.${mediaPlan?.biddingStrategy}`}
                                                    />
                                                </MediaPlanItem>
                                            </WizardStep>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <OnboardingFormButtons hasSubmit={false} />
                        </WizardStep>
                        {!campaignMap?.campaign_1?.generated && (
                            <WizardStep
                                id="campaign-step-placeholder"
                                label={<FormattedMessage id="onboarding.form.campaigns" defaultMessage="Campaigns" />}
                            ></WizardStep>
                        )}
                        {campaignMap?.campaign_1?.generated &&
                            map(campaignMap, (campaign, campaignKey) => (
                                <WizardStep
                                    key={campaignKey}
                                    id={campaignKey}
                                    label={
                                        <>
                                            <FormattedMessage
                                                id="onboarding.form.campaigns"
                                                defaultMessage="Campaigns"
                                            />
                                            <br />
                                            {getCampaignInfo(campaign, formatMessage)}
                                        </>
                                    }
                                >
                                    <FormNamespaceProvider field={`campaignMap.${campaignKey}`} itemKey={campaignKey}>
                                        <div className={classNameBuilder('button-shifter')}>
                                            <WizardStep label="" id="setup" className={styles['content-sub-step']}>
                                                <SetupSection>
                                                    <CampaignGroupField />
                                                    <NameCreate />
                                                    <Branding />
                                                    <MarketingObjective pixelAndConversionWarningEnabled />
                                                    <TimeFrame />
                                                </SetupSection>
                                            </WizardStep>
                                            <WizardStep label="" id="targeting" className={styles['content-sub-step']}>
                                                <CommonCollapsibleCard
                                                    id="CAMPAIGN_TARGETING"
                                                    header={
                                                        <SectionHeader
                                                            headerIcon={<TargetIcon />}
                                                            headerText={
                                                                <FormattedMessage id="campaign.editor.targeting" />
                                                            }
                                                        />
                                                    }
                                                >
                                                    <GeoTargeting showCities={false} showPostalCode={false} />
                                                </CommonCollapsibleCard>
                                            </WizardStep>
                                            <WizardStep id="budget" label="" className={styles['content-sub-step']}>
                                                <BudgetSectionSponsored onlyBudget />
                                            </WizardStep>
                                        </div>
                                        <OnboardingFormButtons
                                            isSubmitDisable={isSubmitDisable}
                                            onSubmit={() => submitCampaign(`campaignMap.${campaignKey}`)}
                                        />
                                    </FormNamespaceProvider>
                                </WizardStep>
                            ))}
                        <WizardStep
                            id="creative"
                            label={<FormattedMessage id="onboarding.form.creative" defaultMessage="Ads" />}
                            className="onboarding-chat-agent-form-creative"
                        >
                            <FormNamespaceProvider field="creative">
                                <div className={classNameBuilder('button-shifter')}>
                                    <WizardStep
                                        id="setup"
                                        label=""
                                        className={classNameBuilder('content-sub-step', 'creative-sub-step')}
                                    >
                                        <CommonCollapsibleCard
                                            id="setup"
                                            header={<SectionHeader headerIcon={<SetupIcon />} headerText="Setup" />}
                                        >
                                            <VariationsSetupSection />
                                        </CommonCollapsibleCard>
                                    </WizardStep>
                                    <WizardStep
                                        id="media"
                                        label=""
                                        className={classNameBuilder('content-sub-step', 'creative-sub-step')}
                                    >
                                        <VariationsMediaSection className={styles['creative-media-card']} />
                                    </WizardStep>
                                    <WizardStep
                                        id="content"
                                        label=""
                                        className={classNameBuilder('content-sub-step', 'creative-sub-step')}
                                    >
                                        <AiVariationsContextProvider>
                                            <AiVariationsContentSection className={styles['creative-content-card']} />
                                        </AiVariationsContextProvider>
                                    </WizardStep>
                                    <div className={styles['preview-wrapper']}>
                                        <AiVariationsPreviewSection />
                                    </div>
                                </div>
                                <OnboardingFormButtons isSubmitDisable={isSubmitDisable} onSubmit={submitCreative} />
                            </FormNamespaceProvider>
                        </WizardStep>
                        <WizardStep
                            id="payment"
                            label={
                                <FormattedMessage
                                    id="onboarding.form.payment.details"
                                    defaultMessage="Payment Details"
                                />
                            }
                        >
                            <FormNamespaceProvider field="payment">
                                <div className={classNameBuilder('button-shifter')}>
                                    <WizardStep
                                        label={
                                            <FormattedMessage
                                                id="paymentMethod.creator.CARD"
                                                defaultMessage="Credit Card"
                                            />
                                        }
                                        id="creditCard"
                                        className={classNameBuilder('content-sub-step')}
                                    >
                                        {showBillingData ? (
                                            <PaymentMethodCreditCardSection />
                                        ) : (
                                            <CommonCollapsibleCard
                                                id="CREDIT_CARD"
                                                header={
                                                    <SectionHeader
                                                        headerText={
                                                            <FormattedMessage
                                                                id="paymentMethod.creator.CARD"
                                                                defaultMessage="Credit Card"
                                                            />
                                                        }
                                                    />
                                                }
                                            >
                                                <FormattedMessage
                                                    id="onboarding.form.payment.provider.not.configured"
                                                    message="Payment provider is not configured for current account."
                                                />
                                            </CommonCollapsibleCard>
                                        )}
                                    </WizardStep>
                                    {showBillingData && (
                                        <WizardStep
                                            label={
                                                <FormattedMessage
                                                    id="paymentMethod.creator.CARD.billingAddress"
                                                    defaultMessage="Billing Address"
                                                />
                                            }
                                            id="billingAddress"
                                            className={classNameBuilder('content-sub-step')}
                                        >
                                            <PaymentMethodAddressSection />
                                        </WizardStep>
                                    )}
                                </div>
                                <OnboardingFormButtons
                                    onSubmit={submitPayment}
                                    disableSubmit={!showBillingData}
                                    submitLabel={
                                        <FormattedMessage id="onboarding.form.submit" defaultMessage="Submit" />
                                    }
                                />
                            </FormNamespaceProvider>
                        </WizardStep>
                    </Wizard>
                </div>
            </div>
        </div>
    );
};

const singleCampaignDefaultValue = {
    startDate: campaignInitialValues.startDate,
    campaignTargeting: {
        geoTargeting: campaignInitialValues.campaignTargeting.geoTargeting,
    },
    platformTargeting: campaignInitialValues.platformTargeting,
    budget: campaignInitialValues.budget,
    bidStrategy: campaignInitialValues.bidStrategy,
    pricingModel: campaignInitialValues.pricingModel,
    creativeTrafficAllocationMethod: campaignInitialValues.creativeTrafficAllocationMethod,
};

const allCampaignDefaultValue = new Array(MAX_CAMPAIGNS_COUNT)
    .fill({})
    .reduce((acc, _, index) => ({ ...acc, [`campaign_${index + 1}`]: singleCampaignDefaultValue }), {});

export const OnboardingChatForm = withPaymentProvider(
    withFormDataProvider(Onboarding, {
        defaultValuesConfig: {
            mediaPlan: {},
            campaignMap: allCampaignDefaultValue,
            creative: {
                urls: creativeInitialValues.urls,
                content: creativeInitialValues.content,
                [AI_VARIATIONS_FIELD_NAMES.CONTENT]: creativeInitialValues[AI_VARIATIONS_FIELD_NAMES.CONTENT],
                [AI_VARIATIONS_FIELD_NAMES.CREATIVE_PREVIEWS]:
                    creativeInitialValues[AI_VARIATIONS_FIELD_NAMES.CREATIVE_PREVIEWS],
                [AI_VARIATIONS_FIELD_NAMES.TITLES]: creativeInitialValues[AI_VARIATIONS_FIELD_NAMES.TITLES],
                thumbnails: creativeInitialValues.thumbnails,
            },
        },
    }),
    { paymentMethodType: PAYMENT_TYPE.CARD }
);
