/**
 * Created by itamar.c
 * Date: 2020-07-01
 */
import React from 'react';
import PropTypes from 'prop-types';
import { BlockIcon, OkOIcon, DeletableItem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingTypes from '../../../config/TargetingTypes';
import styles from './osTargetingListItem.module.scss';

function OsTargetingListItem({ targetingType, item, deleteItem }) {
    const handleOnClick = () => {
        deleteItem(item);
    };

    const label = item.label || item?.subCategory?.label;
    const messageId = `app.os.${item.taxonomy || item.osFamily}.${item.id || item.subCategory.id}`;

    return (
        <DeletableItem onDelete={handleOnClick} isItemDeletable={!!deleteItem}>
            <div className={styles['container']}>
                <span>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={styles['include']} />
                    ) : (
                        <BlockIcon className={styles['exclude']} />
                    )}
                </span>
                <div className={styles['label']}>
                    <FormattedMessage id={messageId} defaultMessage={label} />
                </div>
            </div>
        </DeletableItem>
    );
}

OsTargetingListItem.propTypes = {
    item: PropTypes.shape({
        osFamily: PropTypes.string,
        subCategory: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string,
            }),
        ]),
    }),
    deleteItem: PropTypes.func,
    targetingType: PropTypes.string,
};

export default OsTargetingListItem;
