import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { brandSafetyOptions } from '../../BrandSafety/BrandSafety';
import { useHighImpactTargetingLogic } from '../../HighImpactTargeting/useHighImpactTargetingLogic';
import { premiumSiteOptions } from '../../PremiumSite/PremiumSite';
import { useSelectedSites } from '../../SiteTargeting/hooks/useSelectedSites';
import { useIsSupplyTargetingSelectPermitted } from '../../SupplyTargeting/hooks/useIsSupplyTargetingSelectPermitted';
import { useSupplyTargetingFormField } from '../../SupplyTargeting/hooks/useSupplyTargetingFormField';

export const useAdvancedOptionsSection = isIncludeHighImpactTargeting => {
    const { formAccount } = useFormDataContext();
    const { formatMessage } = useIntl();

    const isSiteTargetingPermitted = usePermissions('API_BLOCK_SITES_VIEW');
    const { allSelectedSites } = useSelectedSites();

    const isSupplyTargetingPermitted = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED]: 'true' });
    const { value: supplyTargetingValue } = useSupplyTargetingFormField();

    const isThirdPartyBrandSafetyPermitted = usePermissions('EXTERNAL_BRAND_SAFETY');
    const { value: thirdPartyBrandSafetyItems } = useFormFieldValue({
        field: 'thirdPartyBrandSafetyTargeting.values',
    });

    const { isPermitted: isHighImpactTargetingPermitted, selectedValue: highImpactTargetingValue } =
        useHighImpactTargetingLogic(formAccount.accountId);
    const isPremiumSiteTargetingPermitted = usePermissions(['QUALITY_TARGETING_EDIT_TA']);
    const { value: premiumSiteTargetingValue } = useFormFieldValue({
        field: 'premiumSiteTargeting',
        isPermitted: isPremiumSiteTargetingPermitted,
    });
    const isBrandSafetyTargetingPermitted = usePermissions(['SAFETY_TARGETING_EDIT_TA']);
    const { value: brandSafetyTargetingValue } = useFormFieldValue({
        field: 'brandSafetyTargeting',
        isPermitted: isBrandSafetyTargetingPermitted,
    });
    const isTaboolaSelectPermitted = useIsSupplyTargetingSelectPermitted();

    const isInitiallyCollapsed =
        (isEmpty(allSelectedSites) || !isSiteTargetingPermitted) &&
        (!supplyTargetingValue || !isSupplyTargetingPermitted) &&
        (isEmpty(thirdPartyBrandSafetyItems) || !isThirdPartyBrandSafetyPermitted) &&
        (!highImpactTargetingValue || !isHighImpactTargetingPermitted || !isIncludeHighImpactTargeting) &&
        (premiumSiteTargetingValue === premiumSiteOptions[0].value || !isPremiumSiteTargetingPermitted) &&
        (brandSafetyTargetingValue === brandSafetyOptions[0].value || !isBrandSafetyTargetingPermitted);

    const getAdvancedOptionsSubtitle = useCallback(() => {
        const availableFields = [];
        if (isSiteTargetingPermitted) {
            availableFields.push(
                formatMessage({ id: 'campaign.editor.targeting.site.title', defaultMessage: 'Block Sites' })
            );
        }
        if (isSupplyTargetingPermitted) {
            availableFields.push(
                formatMessage({ id: 'campaign.editor.targeting.supply.title', defaultMessage: 'Supply Targeting' })
            );
        }
        if (isThirdPartyBrandSafetyPermitted) {
            availableFields.push(
                formatMessage({
                    id: 'campaign.editor.targeting.3rd.party.brand.safety.title',
                    defaultMessage: 'Brand Safety and Suitability Pre-Bid',
                })
            );
        }
        if (isHighImpactTargetingPermitted && isIncludeHighImpactTargeting && !isTaboolaSelectPermitted) {
            availableFields.push(
                formatMessage({
                    id: 'campaign.editor.highImpactTargeting.title',
                    defaultMessage: 'High Impact Package',
                })
            );
        }
        if (isPremiumSiteTargetingPermitted) {
            availableFields.push(
                formatMessage({ id: 'campaign.editor.premium.site.title', defaultMessage: 'Premium Site' })
            );
        }
        if (isBrandSafetyTargetingPermitted) {
            availableFields.push(
                formatMessage({ id: 'campaign.editor.brand.safety.title', defaultMessage: 'Brand Safety' })
            );
        }

        return availableFields.join(', ');
    }, [
        formatMessage,
        isBrandSafetyTargetingPermitted,
        isHighImpactTargetingPermitted,
        isIncludeHighImpactTargeting,
        isPremiumSiteTargetingPermitted,
        isSiteTargetingPermitted,
        isThirdPartyBrandSafetyPermitted,
        isSupplyTargetingPermitted,
        isTaboolaSelectPermitted,
    ]);

    return {
        isInitiallyCollapsed,
        advancedOptionsSubtitle: getAdvancedOptionsSubtitle(),
    };
};
