'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _reactTransitionGroup = require('react-transition-group');

require('react-dates/initialize');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _reactDates = require('react-dates');

var _useOnclickoutside = require('use-onclickoutside');

var _useOnclickoutside2 = _interopRequireDefault(_useOnclickoutside);

var _hooks = require('../../../../hooks');

var _DatePickerNavigationIcons = require('../../DatePicker/DatePickerNavigationIcons');

var _CalendarFooter = require('../CalendarFooter');

var _CalendarFooter2 = _interopRequireDefault(_CalendarFooter);

var _CalendarPresets = require('../CalendarPresets');

var _CalendarPresets2 = _interopRequireDefault(_CalendarPresets);

var _defaultPhrases = require('../defaultPhrases');

var _constants = require('../constants');

var _utils = require('../utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dashboardRangePickerContent__taboola-svg-icon___Dr74D',
    'container': 'dashboardRangePickerContent__container___3iYLo',
    'day-picker-wrapper': 'dashboardRangePickerContent__day-picker-wrapper___1_u-T'
};


var renderCalendarInfo = function renderCalendarInfo(props) {
    return function () {
        return _react2.default.createElement(_CalendarFooter2.default, props);
    };
};

var DashboardRangePickerContent = function DashboardRangePickerContent(_ref) {
    var startDate = _ref.startDate,
        endDate = _ref.endDate,
        preset = _ref.preset,
        pickerOpen = _ref.pickerOpen,
        presetConfigs = _ref.presetConfigs,
        confirmButtonText = _ref.confirmButtonText,
        cancelButtonText = _ref.cancelButtonText,
        extendConfirmProps = _ref.extendConfirmProps,
        focusedStartDate = _ref.focusedStartDate,
        focusedEndDate = _ref.focusedEndDate,
        displayFormat = _ref.displayFormat,
        extendPresetProps = _ref.extendPresetProps,
        timeFramePicker = _ref.customTimeFramePicker,
        onPresetChange = _ref.onPresetChange,
        onDatesConfirmed = _ref.onDatesConfirmed,
        onClose = _ref.onClose,
        renderWeekHeaderElement = _ref.renderWeekHeaderElement,
        rest = _objectWithoutProperties(_ref, ['startDate', 'endDate', 'preset', 'pickerOpen', 'presetConfigs', 'confirmButtonText', 'cancelButtonText', 'extendConfirmProps', 'focusedStartDate', 'focusedEndDate', 'displayFormat', 'extendPresetProps', 'customTimeFramePicker', 'onPresetChange', 'onDatesConfirmed', 'onClose', 'renderWeekHeaderElement']);

    var container = (0, _react.useRef)(null);

    var _useState = (0, _react.useState)(startDate),
        _useState2 = _slicedToArray(_useState, 2),
        tempStartDate = _useState2[0],
        setTempStartDate = _useState2[1];

    var _useState3 = (0, _react.useState)(endDate),
        _useState4 = _slicedToArray(_useState3, 2),
        tempEndDate = _useState4[0],
        setTempEndDate = _useState4[1];

    var _useState5 = (0, _react.useState)(null),
        _useState6 = _slicedToArray(_useState5, 2),
        error = _useState6[0],
        setError = _useState6[1];

    var _useState7 = (0, _react.useState)(true),
        _useState8 = _slicedToArray(_useState7, 2),
        rangeSelected = _useState8[0],
        setRangeSelected = _useState8[1];

    var _useState9 = (0, _react.useState)(_constants.START_DATE),
        _useState10 = _slicedToArray(_useState9, 2),
        focusedInput = _useState10[0],
        setFocusedInput = _useState10[1];

    var closePicker = (0, _react.useCallback)(function () {
        setTempStartDate(startDate);
        setTempEndDate(endDate);

        onClose();
    }, [onClose, setTempStartDate, setTempEndDate]);

    (0, _useOnclickoutside2.default)(container, closePicker);
    (0, _hooks.useEscapeHandler)(closePicker);

    var handlePresetChange = function handlePresetChange(newPreset) {
        var config = presetConfigs[newPreset];

        if (config) {
            var newStartDate = config.startDate,
                newEndDate = config.endDate;

            setTempStartDate(newStartDate);
            setTempEndDate(newEndDate);
        }
        onPresetChange(newPreset);
    };

    var onDatesChange = function onDatesChange(_ref2) {
        var newStartDate = _ref2.startDate,
            newEndDate = _ref2.endDate;

        var finalEndDate = focusedInput === _constants.START_DATE ? null : newEndDate;

        setRangeSelected(newStartDate !== null && finalEndDate !== null);
        setFocusedInput(newStartDate !== tempStartDate ? _constants.END_DATE : _constants.START_DATE);

        setTempStartDate(newStartDate);
        setTempEndDate(finalEndDate);
    };

    var onConfirm = function onConfirm() {
        if (error) {
            return;
        }

        setFocusedInput(_constants.START_DATE);
        onDatesConfirmed(tempStartDate, tempEndDate || tempStartDate, null);
    };

    var onStartDateChange = (0, _react.useCallback)(function (date) {
        var newRangeSelected = tempEndDate !== null;

        if (date === null) {
            newRangeSelected = false;

            setTempEndDate(null);
            setFocusedInput(_constants.START_DATE);
        }

        setRangeSelected(newRangeSelected);
        setTempStartDate(date);
    }, [setTempStartDate, setTempEndDate, setFocusedInput, setRangeSelected]);

    var onEndDateChange = (0, _react.useCallback)(function (date) {
        var newRangeSelected = true;

        if (date === null) {
            newRangeSelected = false;

            setFocusedInput(_constants.END_DATE);
        }

        setTempEndDate(date);
        setRangeSelected(newRangeSelected);
    }, [setTempEndDate, setRangeSelected, setFocusedInput]);

    var calendarInfoProps = {
        startDate: tempStartDate,
        endDate: tempEndDate,
        timeFramePicker: timeFramePicker,
        onErrorStateChange: function onErrorStateChange(err) {
            return setError(err);
        },
        onStartDateChange: onStartDateChange,
        onEndDateChange: onEndDateChange,
        onConfirm: onConfirm,
        onCancel: closePicker,

        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        extendConfirmProps: extendConfirmProps,
        focusedStartDate: focusedStartDate,
        focusedEndDate: focusedEndDate,
        displayFormat: displayFormat
    };

    var isValidTempEndDate = tempEndDate && tempEndDate.isAfter(tempStartDate);

    (0, _react.useEffect)(function () {
        if (!pickerOpen) {
            return;
        }

        setTempStartDate(startDate);
        setTempEndDate(endDate);
        setError(null);
        setRangeSelected(true);
        setFocusedInput(_constants.START_DATE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickerOpen]);

    (0, _react.useEffect)(function () {
        if (preset && !(0, _utils.isSamePreset)({ startDate: startDate, endDate: endDate }, presetConfigs[preset])) {
            handlePresetChange(preset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preset, startDate, endDate, presetConfigs]);

    (0, _react.useEffect)(function () {
        if (!(0, _utils.isEqualDate)(startDate, tempStartDate)) {
            setTempStartDate(startDate);
        }

        if (!(0, _utils.isEqualDate)(endDate, tempEndDate)) {
            setTempEndDate(endDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return _react2.default.createElement(
        _reactTransitionGroup.CSSTransitionGroup,
        {
            transitionName: 'dashboard-range-picker-animation',
            transitionEnterTimeout: 500,
            transitionLeaveTimeout: 300
        },
        pickerOpen && _react2.default.createElement(
            'div',
            { className: (0, _bind2.default)('dashboard-range-picker-picker', { 'range-not-selected': !rangeSelected || !isValidTempEndDate }) },
            _react2.default.createElement(
                'div',
                { className: styles['container'], ref: container },
                _react2.default.createElement(
                    'div',
                    { className: styles['day-picker-wrapper'], ref: container },
                    _react2.default.createElement(_reactDates.DayPickerRangeController, Object.assign({}, rest, {
                        startDate: tempStartDate,
                        endDate: tempEndDate,
                        focusedInput: focusedInput,

                        onDatesChange: onDatesChange,
                        onFocusChange: function onFocusChange(newInput) {
                            return setFocusedInput(newInput);
                        },

                        phrases: _defaultPhrases.DateRangePickerPhrases,
                        navPrev: _DatePickerNavigationIcons.NavLeftIconSmall,
                        navNext: _DatePickerNavigationIcons.NavRightIconSmall,
                        weekDayFormat: _constants.SHORT_WEEKDAY_FORMAT,
                        minimumNights: 0,
                        keepOpenOnDateSelect: true,
                        noBorder: true,
                        renderCalendarInfo: renderCalendarInfo(calendarInfoProps),
                        renderWeekHeaderElement: renderWeekHeaderElement
                    }))
                ),
                _react2.default.createElement(_CalendarPresets2.default, {
                    onPeriodChange: handlePresetChange,
                    preset: preset,
                    periods: presetConfigs,
                    extendPresetProps: extendPresetProps
                })
            )
        )
    );
};

DashboardRangePickerContent.propTypes = {
    startDate: _reactMomentProptypes2.default.momentObj,
    endDate: _reactMomentProptypes2.default.momentObj,
    preset: _propTypes2.default.string,
    pickerOpen: _propTypes2.default.bool,
    presetConfigs: _propTypes2.default.object,

    focusedStartDate: _propTypes2.default.bool,
    focusedEndDate: _propTypes2.default.bool,
    displayFormat: _propTypes2.default.string,
    isOutsideRange: _propTypes2.default.func,

    extendPresetProps: _propTypes2.default.func,
    customTimeFramePicker: _propTypes2.default.element,
    cancelButtonText: _propTypes2.default.string,
    confirmButtonText: _propTypes2.default.string,
    extendConfirmProps: _propTypes2.default.func,

    onDatesConfirmed: _propTypes2.default.func,
    onPresetChange: _propTypes2.default.func,
    onClose: _propTypes2.default.func,

    renderWeekHeaderElement: _propTypes2.default.func
};

DashboardRangePickerContent.defaultProps = {
    renderWeekHeaderElement: function renderWeekHeaderElement(day) {
        return day.toUpperCase();
    }
};

exports.default = DashboardRangePickerContent;