import React from 'react';
import ReportBreadcrumbs from '../../campaigns/components/ReportBreadcrumbs/ReportBreadcrumbs';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';
import { useTrackingBreadcrumbs } from '../hooks/useTrackingBreadcrumbs';

export const TrackingReportBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'app.campaigns.reports.datagrid.overlay.conversions.header',
        defaultMessage: 'Conversions',
    });

    const { steps } = useTrackingBreadcrumbs({
        title,
        isTitleAsLastStep: false,
    });

    return <ReportBreadcrumbs title={title} steps={steps} />;
};
