import React from 'react';
import { FormattedNumber } from '../../../../../../taboola-common-frontend-modules/i18n';
import { RANGE_FILTERS_OPERATORS } from '../../../../common-campaign-form/components/Profile/utils/constants';
import { STATIC_FILTER_TYPE_MAP } from '../../../config';

const resolveValue = ({ isCurrency, isPercent, currency, value }) => {
    if (isCurrency) {
        return <FormattedNumber value={value} variant="currency" currency={currency} />;
    } else if (isPercent) {
        return <FormattedNumber value={value / 100} maximumFractionDigits={2} variant="percent" />;
    }
    return value;
};

export const comparableFilterValuesResolver = ({ type, values, operator, currency }) => {
    const showSecondInput = operator?.value === RANGE_FILTERS_OPERATORS.BETWEEN;
    const isCurrency = STATIC_FILTER_TYPE_MAP[type]?.isCurrency;
    const isPercent = STATIC_FILTER_TYPE_MAP[type]?.isPercent;
    const comparableFilterValuesSeparator = '-';
    const comparableFilterFirstValue = resolveValue({ isCurrency, isPercent, currency, value: values[0] });
    const comparableFilterSecondValue = resolveValue({ isCurrency, isPercent, currency, value: values[1] });

    return !showSecondInput
        ? comparableFilterFirstValue
        : [comparableFilterFirstValue, comparableFilterValuesSeparator, comparableFilterSecondValue];
};
