'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _keycodeJs = require('keycode-js');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Buttons = require('../../Buttons');

var _Indications = require('../../Indications');

var _Input = require('../Input/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'inputWithButton__taboola-svg-icon___1G4O-',
    'input-with-button': 'inputWithButton__input-with-button___ErD29',
    'input': 'inputWithButton__input___3pm_u',
    'taboola-input': 'inputWithButton__taboola-input___1PRas',
    'hide-arrow-buttons': 'inputWithButton__hide-arrow-buttons___1fmN-',
    'input-wrapper': 'inputWithButton__input-wrapper___1G_TV',
    'label': 'inputWithButton__label___2j4Qx',
    'description': 'inputWithButton__description___1epav',
    'error': 'inputWithButton__error___19jl7',
    'warning': 'inputWithButton__warning___12K2H',
    'suggestion': 'inputWithButton__suggestion___2rwZe',
    'full-width': 'inputWithButton__full-width___3sZ0k',
    'with-icon': 'inputWithButton__with-icon___2paLX',
    'input-icon': 'inputWithButton__input-icon___33zl4',
    'button': 'inputWithButton__button___1M9AS',
    'disabled': 'inputWithButton__disabled___3LyqH',
    'empty': 'inputWithButton__empty___1H1qW'
};


var classNameBuilder = _bind2.default.bind(styles);

var InputWithButton = function InputWithButton(_ref) {
    var value = _ref.value,
        inputWithButtonClass = _ref.inputWithButtonClass,
        buttonClass = _ref.buttonClass,
        buttonLabel = _ref.buttonLabel,
        disabled = _ref.disabled,
        inputClass = _ref.inputClass,
        onButtonClick = _ref.onButtonClick,
        onChange = _ref.onChange,
        error = _ref.error,
        indicationType = _ref.indicationType,
        buttonProps = _ref.buttonProps,
        rest = _objectWithoutProperties(_ref, ['value', 'inputWithButtonClass', 'buttonClass', 'buttonLabel', 'disabled', 'inputClass', 'onButtonClick', 'onChange', 'error', 'indicationType', 'buttonProps']);

    var handleButtonClick = function handleButtonClick() {
        if (onButtonClick) {
            onButtonClick(value);
        }

        onChange('');
    };

    var handleInputKeyPress = function handleInputKeyPress(e) {
        if (e.charCode === _keycodeJs.KEY_RETURN) {
            handleButtonClick();
        }
    };

    var handleOnChange = function handleOnChange(e) {
        if (onChange) {
            onChange(e.target.value);
        }
    };

    var wrapperClasses = classNameBuilder('input-with-button', inputWithButtonClass, {
        disabled: disabled,
        error: error || indicationType === _Indications.TYPES.ERROR,
        empty: !value
    });

    var inputClasses = classNameBuilder('input', inputClass);

    var buttonClasses = classNameBuilder('button', buttonClass);

    return _react2.default.createElement(
        'div',
        { className: wrapperClasses },
        _react2.default.createElement(_Input2.default, Object.assign({}, rest, {
            value: value,
            disabled: disabled,
            onKeyPress: handleInputKeyPress,
            onChange: handleOnChange,
            inputClass: inputClasses
        })),
        _react2.default.createElement(
            _Buttons.StyledButton,
            Object.assign({
                className: buttonClasses,
                onClick: handleButtonClick,
                disabled: disabled || !value,
                type: _Buttons.STYLED_BUTTON_TYPE.STRONG
            }, buttonProps),
            buttonLabel
        )
    );
};

InputWithButton.propTypes = {
    /** Input Button Label */
    buttonLabel: _propTypes2.default.node,
    /** Optional Input Button Class */
    buttonClass: _propTypes2.default.string,
    /** Optional Input with Button Class */
    inputWithButtonClass: _propTypes2.default.string,
    /** Optional Input Class */
    inputClass: _propTypes2.default.string,
    /** Input Button Click callback */
    onButtonClick: _propTypes2.default.func,
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Input Onchange event */
    onChange: _propTypes2.default.func,
    /** Initial input value prop */
    value: _propTypes2.default.string,
    /** Additional props for button */
    buttonProps: _propTypes2.default.object,
    /** Error control */
    error: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES))
};

InputWithButton.defaultProps = {
    value: '',
    onChange: function onChange() {},
    onButtonClick: function onButtonClick() {}
};

exports.default = InputWithButton;