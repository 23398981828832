import React from 'react';
import PropTypes from 'prop-types';
import { useFormDataContext } from '../../taboola-common-frontend-modules/formData';
import PerformanceRecommendationsReviewer from '../modules/performance-recommendations/components/PerformanceRecommendationsReviewer/PerformanceRecommendationsReviewer';

export const PerformanceRecommendationsPage = ({ onCancel }) => {
    const { mode } = useFormDataContext();

    return <PerformanceRecommendationsReviewer mode={mode} onCancel={onCancel} />;
};

PerformanceRecommendationsPage.propTypes = {
    onCancel: PropTypes.func,
};

export default PerformanceRecommendationsPage;
