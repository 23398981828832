import React from 'react';
import { useSelectedAccount } from 'hooks';
import { useSelectedCampaign } from 'modules/campaigns/hooks/useSelectedCampaign';
import { PERFORMANCE_RECOMMENDATIONS_RIBBON_TYPES } from '../../../performance-recommendations/config/performanceRecommendationsRibbonTypes';
import { useRibbonRecommendation } from '../../../performance-recommendations/hooks/useRibbonRecommendation';

export const RecommendationRibbon = () => {
    const { campaign, isError, isSuccess } = useSelectedCampaign();
    const [{ accountId }] = useSelectedAccount();
    const { ribbonRecommendations = [] } = useRibbonRecommendation(accountId, campaign.id);

    const [recommendation = {}] = ribbonRecommendations;
    const { type } = recommendation;
    const RibbonContent = PERFORMANCE_RECOMMENDATIONS_RIBBON_TYPES[type];

    const shouldRender = ribbonRecommendations.length > 0 && !!RibbonContent;

    if (!shouldRender || !isSuccess || isError) {
        return null;
    }

    return <RibbonContent recommendation={recommendation} />;
};
