'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _hooks = require('../../hooks');

var useCollapseSyncCallback = function useCollapseSyncCallback(collapsed, callback) {
    var _useState = (0, _react.useState)(collapsed),
        _useState2 = _slicedToArray(_useState, 2),
        innerCollapsed = _useState2[0],
        setInnerCollapsed = _useState2[1];

    var onCollapsed = (0, _react.useCallback)(function () {
        return setInnerCollapsed(true);
    }, []);

    var _useElementSize = (0, _hooks.useElementSize)(),
        width = _useElementSize.width,
        height = _useElementSize.height,
        collapseRef = _useElementSize.ref;

    // Sync cached flag with external


    (0, _react.useEffect)(function () {
        return setInnerCollapsed(collapsed);
    }, [collapsed]);

    // Once close animation is finished we trigger passed close callback
    (0, _react.useEffect)(function () {
        var zeroSize = !width || !height;

        if (zeroSize && !collapsed && innerCollapsed) {
            callback();
        }
    }, [width, height, innerCollapsed, callback]);

    return {
        collapseRef: collapseRef,
        collapsed: innerCollapsed,
        onCollapsed: onCollapsed
    };
};

exports.default = useCollapseSyncCallback;