import React, { useCallback } from 'react';
import { reject } from 'lodash';
import { CollapsibleList, PaginatedDropdown, BareDropdown, Dotdotdot } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CampaignGroupsSmartListItem } from './CampaignGroupsSmartListItem';
import { useCampaignGroupsList } from './hooks/useCampaignGroupsList';
import styles from './campaignGroupsSmartList.module.scss';

const PAGE_SIZE = 10;
const emptyGroups = [];

export const CampaignGroupsSmartList = () => {
    const { value: selectedCampaignGroups = emptyGroups, onChange: setSelectedCampaignGroups } = useFormFieldValue({
        field: 'selectedCampaignGroups',
    });

    const handleAddCampaignGroup = useCallback(
        option => setSelectedCampaignGroups(currentGroups => (currentGroups ? [...currentGroups, option] : [option])),
        [setSelectedCampaignGroups]
    );

    const handleDeleteCampaignGroup = useCallback(
        option => setSelectedCampaignGroups(currentGroups => reject(currentGroups, { value: option.value })),
        [setSelectedCampaignGroups]
    );

    const handleClearCampaignGroups = useCallback(() => setSelectedCampaignGroups([]), [setSelectedCampaignGroups]);

    const { groups, setGroups, loadPage } = useCampaignGroupsList(selectedCampaignGroups);

    return (
        <div className={styles['container']}>
            <PaginatedDropdown
                id="campaign-groups-dropdown"
                searchable
                options={groups}
                loadPage={loadPage}
                onOptionsLoaded={setGroups}
                pageSize={PAGE_SIZE}
                onChange={handleAddCampaignGroup}
                placeholder={
                    <FormattedMessage id="campaign.group.placeholder" defaultMessage="Select a Campaign Group" />
                }
                value={null}
                optionItemRenderer={({ data: { label } }) => <Dotdotdot>{label}</Dotdotdot>}
                DropdownMenuComponent={BareDropdown}
                containerClassName={styles['dropdown']}
                aria-label="campaign-groups-dropdown"
            />
            <CollapsibleList
                items={selectedCampaignGroups}
                ItemComponent={CampaignGroupsSmartListItem}
                itemHeight={40}
                deleteItem={handleDeleteCampaignGroup}
                clearItems={handleClearCampaignGroups}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.groups.select.list.header"
                        defaultMessage="Selected Campaign Groups"
                    />
                }
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                containerClassName={styles['list']}
            />
        </div>
    );
};
