import { useQuery } from 'react-query';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useAudiencesApi } from 'services/api/audiencesApi';

export const usePredictiveSeedAudiencesApi = selectedAccountId => {
    const { getPredictiveSeedAudiences } = useAudiencesApi();
    const { mode } = useFormFieldValue({});

    const { data: audienceList, isLoading: isLoadingAudienceList } = useQuery(
        ['getPredictiveSeedAudiences', selectedAccountId, mode],
        () => getPredictiveSeedAudiences(selectedAccountId),
        {
            refetchOnWindowFocus: false,
            select: data =>
                data.results.map(({ name: label, ruleId, receivedEventCount }) => ({
                    value: ruleId,
                    label,
                    receivedEventCount,
                })),
        }
    );
    return { audienceList, isLoadingAudienceList };
};
