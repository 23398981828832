'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react3 = require('@tippy.js/react');

var _react4 = _interopRequireDefault(_react3);

var _TooltipTrigger = require('./TooltipTrigger');

var _TooltipTrigger2 = _interopRequireDefault(_TooltipTrigger);

var _TooltipPosition = require('./TooltipPosition');

var _TooltipPosition2 = _interopRequireDefault(_TooltipPosition);

var _TooltipBoundary = require('./TooltipBoundary');

var _TooltipBoundary2 = _interopRequireDefault(_TooltipBoundary);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'tooltip__taboola-svg-icon___1gaZQ',
    'tooltip-content': 'tooltip__tooltip-content___399jp'
};


var Tooltip = function Tooltip(props) {
    var content = props.content,
        className = props.className,
        children = props.children,
        tippyProps = _objectWithoutProperties(props, ['content', 'className', 'children']);

    var htmlContent = _react2.default.createElement(
        'div',
        { className: styles['tooltip-content'] + ' ' + className },
        content
    );

    return _react2.default.createElement(
        _react4.default,
        Object.assign({
            content: htmlContent
        }, tippyProps),
        children
    );
};

Tooltip.propTypes = Object.assign({
    /** Apply class to style the tooltip */
    className: _propTypes2.default.string,
    /** Tooltip content. Can be either text, html or jsx */
    content: _propTypes2.default.node.isRequired,
    /** Elements inside tooltip tag */
    children: _propTypes2.default.element.isRequired,
    /** Adds an arrow pointing to the tooltipped element */
    arrow: _propTypes2.default.bool,
    /** Makes a tooltip interactive, i.e. will not close when the user hovers over or clicks on the tooltip */
    interactive: _propTypes2.default.bool,
    /** Specifies which type of events will trigger a tooltip to show */
    trigger: _propTypes2.default.oneOf(Object.values(_TooltipTrigger2.default)),
    /** Specifies which direction to position the tooltip on the element */
    placement: _propTypes2.default.oneOf(Object.values(_TooltipPosition2.default)),
    /** Specifies which boundary to be used when positioning the tooltip on the element */
    boundary: _propTypes2.default.oneOf(Object.values(_TooltipBoundary2.default))
}, _react4.default.propTypes);

Tooltip.defaultProps = {
    className: '',
    arrow: false,
    interactive: true,
    trigger: _TooltipTrigger2.default.HOVER,
    placement: _TooltipPosition2.default.TOP,
    boundary: _TooltipBoundary2.default.HTML_ELEMENT
};

exports.default = Tooltip;