import { FORM_MODES } from 'config/formModes';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { ACCOUNT_NAME_PARAM_TEMPLATE, PERFORMANCE_RULES_ID_PARAM_TEMPLATE } from './account';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const PERFORMANCE_RULES_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_NAME_PARAM_TEMPLATE}${PERFORMANCE_RULES_ID_PARAM_TEMPLATE}`;

export const RULE_ROUTE = `/${ENTITY_TO_PATH_MAP[ENTITY.RULE]}`;

export const PERFORMANCE_RULES_CREATOR_ROUTE_PATH = replacePathParams(
    PERFORMANCE_RULES_ROUTE_PATH,
    {
        entity: [ENTITY.RULE],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);
export const PERFORMANCE_RULES_PREVIEW_ROUTE_PATH = replacePathParams(
    PERFORMANCE_RULES_ROUTE_PATH,
    {
        entity: [ENTITY.RULE],
        mode: [FORM_MODES.PREVIEW],
    },
    ['ruleId'],
    KEY_TO_PATH_MAP_MAP
);
export const PERFORMANCE_RULES_EDIT_ROUTE_PATH = replacePathParams(
    PERFORMANCE_RULES_ROUTE_PATH,
    {
        entity: [ENTITY.RULE],
        mode: [FORM_MODES.EDIT],
    },
    ['ruleId'],
    KEY_TO_PATH_MAP_MAP
);

export const PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH = replacePathParams(
    PERFORMANCE_RULES_ROUTE_PATH,
    {
        entity: [ENTITY.RULE],
        mode: [FORM_MODES.DUPLICATE],
    },
    ['ruleId'],
    KEY_TO_PATH_MAP_MAP
);
const MAX_DEPTH_OF_URL = PERFORMANCE_RULES_ROUTE_PATH.split('/').length;
export const WILDCARD_PATH = '/*'.repeat(MAX_DEPTH_OF_URL);
