import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { setShouldCrawlOneByOneUrls } from '../../flows';
import { shouldCrawlOneByOneUrlsSelector } from '../../selectors/selectors';
import { OneByOneFields } from '../index';

export const OneByOneSetupSection = () => {
    const dispatch = useDispatch();
    const { value: oneByOneData, onChange: setOneByOneData } = useFormFieldValue({ field: 'oneByOneData' });
    const { urls } = oneByOneData;
    const shouldCrawlOneByOneUrls = useSelector(shouldCrawlOneByOneUrlsSelector);
    const updateShouldCrawlUrls = shouldCrawlOneByOneUrls =>
        dispatch(setShouldCrawlOneByOneUrls(shouldCrawlOneByOneUrls));

    const updateOneByOneUrls = urls => {
        updateOneByOneData({ urls });
    };

    const updateOneByOneValidatedUrls = validatedUrls => {
        updateOneByOneData({
            validatedUrls,
            numUrls: validatedUrls.length,
        });
    };

    const updateOneByOneData = newData =>
        setOneByOneData(oneByOneData => {
            return { ...oneByOneData, ...newData };
        });

    return (
        <OneByOneFields
            urls={urls}
            shouldCrawlUrls={shouldCrawlOneByOneUrls}
            updateUrls={updateOneByOneUrls}
            updateValidatedUrls={updateOneByOneValidatedUrls}
            updateShouldCrawlUrls={updateShouldCrawlUrls}
        />
    );
};
