import { matchPath } from 'react-router';
import moment from 'moment';

export const stripUrl = url => {
    if (!url || !url.length) {
        return '';
    }

    const trimmed = url.replace(/(^\w+:|^)\/\//, '');
    return trimmed.replace(/\/.*/, '');
};

const URL_PROTOCOL_REGEX = /^(?:f|ht)tps?:\/\//;
export const addProtocol = (url, protocol = 'https') => {
    if (!url || !url.length) {
        return '';
    }
    return `${!URL_PROTOCOL_REGEX.test(url) ? `${protocol}://` : ''}${url}`;
};

export const cleanProtocol = url => `${url}`.replace(URL_PROTOCOL_REGEX, '');

export const isPathActive = (sourcePath, destinationPath, { exact = false, strict = false } = {}) => {
    const escapedPath = destinationPath && destinationPath.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

    return matchPath(sourcePath, { path: escapedPath, exact, strict });
};

export const urlDateParser = dateText => dateText && moment(dateText);

export const urlDateSerialize = date => date && date.format('YYYY-MM-DD');

export const isEqualUrlDate = (a, b) => a && b && a.format('YYYY-MM-DD') === b.format('YYYY-MM-DD');

export const getUrlFileExtension = url => url.split('.').pop();
