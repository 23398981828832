import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntityType, useModuleName } from 'hooks';
import { setFormSectionStateBySection } from '../../flows';
import { formSectionStateBySectionSelector } from '../../selectors';

export const useCollapsedState = ({ sectionId, isInitiallyCollapsed, collapsed, setCollapsed, isPersisted }) => {
    const dispatch = useDispatch();
    const entity = useEntityType();
    const module = useModuleName();

    const [internalCollapsed, setInternalCollapsed] = useState(isInitiallyCollapsed);
    const { collapsed: reduxCollapsed } = useSelector(formSectionStateBySectionSelector(module, entity, sectionId));

    const setReduxCollapsed = useCallback(
        stateChangeFunc => {
            dispatch(
                setFormSectionStateBySection(
                    { collapsed: stateChangeFunc(reduxCollapsed) },
                    { module, entity, section: sectionId }
                )
            );
        },
        [dispatch, reduxCollapsed, module, entity, sectionId]
    );

    useEffect(() => {
        if (reduxCollapsed === undefined) {
            setReduxCollapsed(() => isInitiallyCollapsed);
        }
    }, [isInitiallyCollapsed, reduxCollapsed, setReduxCollapsed]);

    useEffect(() => {
        if (!isPersisted) {
            setInternalCollapsed(isInitiallyCollapsed);
        }
    }, [isInitiallyCollapsed, isPersisted, setInternalCollapsed]);

    if (setCollapsed && collapsed !== undefined) {
        return [collapsed, setCollapsed];
    }
    if (isPersisted) {
        return [reduxCollapsed, setReduxCollapsed];
    }
    return [internalCollapsed, setInternalCollapsed];
};
