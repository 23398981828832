import { FORM_MODES } from 'config/formModes';
import { useFetchedCampaign } from 'modules/campaigns/hooks/useFetchedCampaign';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

export const useCreativeCampaignData = () => {
    const { data, mode } = useFormDataContext();
    const { campaigns } = data;
    const isSingleCampaignSelected = campaigns?.length === 1;
    const selectedCampaignId = mode === FORM_MODES.CREATE ? campaigns?.[0]?.id : data?.campaignId;
    const { campaign: fetchedCampaign } = useFetchedCampaign(selectedCampaignId);
    return { fetchedCampaign, isSingleCampaignSelected };
};
