import { useState, useMemo } from 'react';
import { noop } from 'lodash';

const useHover = (init, { onMouseOver = noop, onMouseOut = noop } = {}) => {
    const [hovered, setHovered] = useState(init);

    const hoverHandlers = useMemo(
        () => ({
            onMouseOver: e => setHovered(true) || onMouseOver(e),
            onMouseOut: e => setHovered(false) || onMouseOut(e),
        }),
        [setHovered, onMouseOver, onMouseOut]
    );

    return [hovered, hoverHandlers, setHovered];
};

export default useHover;
