import React from 'react';
import { NewsIcon } from 'taboola-ultimate-ui';
import { ContextualSegmentsField } from '../ContextualSegments/ContextualSegmentsField';
import { CustomContextualSegmentsField } from '../CustomContextualSegments/CustomContextualSegmentsField';
import { SegmentsTargetingSection } from './SegmentsTargetingSection';

export const ContextualTargetingSection = () => {
    return (
        <SegmentsTargetingSection
            sectionId="CAMPAIGN_CONTEXTUAL_TARGETING"
            titleMessageId="campaign.editor.contextual.targeting"
            descriptionMessageId="campaign.editor.contextual.targeting.description"
            isShowTooltip={false}
            headerIcon={<NewsIcon />}
        >
            <ContextualSegmentsField messagePrefix="new." />
            <CustomContextualSegmentsField />
        </SegmentsTargetingSection>
    );
};
