'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Buttons = require('../../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'calendarFooter-module__taboola-svg-icon___8AuJ3',
    'container': 'calendarFooter-module__container___3S8FN',
    'border-container': 'calendarFooter-module__border-container___7NvZA',
    'buttons-container': 'calendarFooter-module__buttons-container___27Vpu',
    'calendar-cancel-button': 'calendarFooter-module__calendar-cancel-button___oWZos',
    'time-frame-container': 'calendarFooter-module__time-frame-container___1hilj'
};


var CalendarFooter = function CalendarFooter(_ref) {
    var confirmButtonText = _ref.confirmButtonText,
        onConfirm = _ref.onConfirm,
        cancelButtonText = _ref.cancelButtonText,
        onCancel = _ref.onCancel,
        timeFramePicker = _ref.timeFramePicker,
        extendConfirmProps = _ref.extendConfirmProps,
        rest = _objectWithoutProperties(_ref, ['confirmButtonText', 'onConfirm', 'cancelButtonText', 'onCancel', 'timeFramePicker', 'extendConfirmProps']);

    return _react2.default.createElement(
        'div',
        { className: styles['container'] },
        _react2.default.createElement(
            'div',
            { className: styles['border-container'] },
            timeFramePicker ? (0, _react.cloneElement)(timeFramePicker, rest) : null,
            _react2.default.createElement(
                'div',
                { className: styles['buttons-container'] },
                _react2.default.createElement(
                    _Buttons.StyledButton,
                    {
                        className: styles['calendar-cancel-button'],
                        size: _Buttons.STYLED_BUTTON_SIZE.MEDIUM,
                        type: _Buttons.STYLED_BUTTON_TYPE.GHOST,
                        onClick: onCancel
                    },
                    cancelButtonText
                ),
                _react2.default.createElement(
                    _Buttons.StyledButton,
                    Object.assign({
                        className: styles['calendar-confirm-button'],
                        size: _Buttons.STYLED_BUTTON_SIZE.MEDIUM,
                        type: _Buttons.STYLED_BUTTON_TYPE.STRONG,
                        onClick: onConfirm
                        // sending null bc the Confirm button always results
                        // in the Custom preset now
                    }, extendConfirmProps(null)),
                    confirmButtonText
                )
            )
        )
    );
};

CalendarFooter.propTypes = {
    /** timeFramePicker component rendered in the footer (for things like validations). Important props being passed in are startDate, endDate, onStartDateChange, and onEndDateChange */
    timeFramePicker: _propTypes2.default.element,
    /** Determines the text on the confirm button */
    confirmButtonText: _propTypes2.default.string,
    /** Callback for the confirm button */
    onConfirm: _propTypes2.default.func,
    /** Determines the text on the cancel button */
    cancelButtonText: _propTypes2.default.string,
    /** Callback for the cancel button */
    onCancel: _propTypes2.default.func,
    /** Add props to confirm button */
    extendConfirmProps: _propTypes2.default.func,
    /** react-date props */
    focusedStartDate: _propTypes2.default.bool,
    focusedEndDate: _propTypes2.default.bool,
    displayFormat: _propTypes2.default.string,
    isOutsideRange: _propTypes2.default.func
};

CalendarFooter.defaultProps = {
    focusedStartDate: false,
    focusedEndDate: false,
    displayFormat: 'DD/MM/YYYY',
    isOutsideRange: function isOutsideRange() {
        return false;
    },
    extendConfirmProps: function extendConfirmProps() {
        return {};
    }
};

exports.default = CalendarFooter;