import React from 'react';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { BidAdjustmentsSectionFrame } from '../BidAdjustmentsSectionFrame/BidAdjustmentsSectionFrame';
import { DayTimeBidBoost } from '../DayTimeBidBoost/DayTimeBidBoost';
import { PlatformBidBoost } from '../PlatformBidBoost/PlatformBidBoost';

export const BidAdjustmentsSection = () => {
    const isDayTimePermitted = useConfigMatch({ [FEATURE_FLAGS.BID_DAY_TIME_ENABLED]: 'true' });
    const isPlatformPermitted = useConfigMatch({ [FEATURE_FLAGS.BID_PLATFORM_ENABLED]: 'true' });

    if (!isDayTimePermitted && !isPlatformPermitted) {
        return null;
    }

    return (
        <BidAdjustmentsSectionFrame>
            <DayTimeBidBoost />
            <PlatformBidBoost />
        </BidAdjustmentsSectionFrame>
    );
};
