import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentValueGetter } from '../../../../../../../../hooks';
import { useFormDataContext } from '../../../../../../../taboola-common-frontend-modules/formData';
import { resetMediaLibraryItems } from '../../../../actions';
import useMediaLibraryItemsFetchService from './useMediaLibraryItemsFetchService';

export const useMediaLibrary = () => {
    const contentType = 'PHOTO';
    const dispatch = useDispatch();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const {
        loadedMedia: loadedMediaLibraryItems,
        reload,
        isModuleReady,
    } = useMediaLibraryItemsFetchService(accountId, { contentType });
    const getReload = useCurrentValueGetter(reload);
    useEffect(() => {
        if (!isModuleReady || !accountId) {
            return;
        }
        dispatch(resetMediaLibraryItems());
        const reload = getReload();
        reload();
    }, [isModuleReady, accountId, getReload, dispatch]);

    return { loadedMediaLibraryItems };
};

export default useMediaLibrary;
