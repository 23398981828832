import { useEffect } from 'react';
import { useModal } from '../../../taboola-common-frontend-modules/modals';
import { useBillingData } from '../../hooks/useBillingData';
import { AdditionalFundsModal } from '../AdditionalFundsModal/AdditionalFundsModal';
import styles from './billingManagementContent.module.scss';

export const useAdditionalFundsModal = () => {
    const { isManagingBilling } = useBillingData();
    const { open: openModal } = useModal();
    const {
        billingData: { cashBalance },
    } = useBillingData();

    useEffect(() => {
        const shouldOpenAdditionalFundsModal = cashBalance?.amount > 0 && isManagingBilling;

        const openAdditionalFundsModel = async () => {
            await openModal({
                contentRenderer: AdditionalFundsModal,
                className: styles['add-funds-modal'],
            });
        };

        if (shouldOpenAdditionalFundsModal) {
            openAdditionalFundsModel();
        }
    }, [cashBalance, openModal, isManagingBilling]);

    return {};
};
