import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../../../taboola-common-frontend-modules/i18n/index';
import ThumbnailError from '../../../../ThumbnailsField/ThumbnailGallery/ThumbnailError/ThumbnailError';
import { VideoExternalError } from '../../VideoExternalError';
import styles from './fallbackImageError.module.scss';

const FallbackImageError = ({ error }) => (
    <div className={styles['fallback-image']}>
        {error ? (
            <VideoExternalError error={error} />
        ) : (
            <ThumbnailError
                messageId="creative.creator.thumbnails.fallbackImage.empty"
                defaultMessage="A default image is required"
                helpText={
                    <FormattedMessage
                        id="creative.creator.thumbnails.fallbackImage.requiredImage.tooltip"
                        defaultMessage="Please upload the highest quality image possible to ensure strong results (Up to 5 Mb.)."
                    />
                }
            />
        )}
    </div>
);

FallbackImageError.propTypes = {
    error: PropTypes.object,
};

export default FallbackImageError;
