import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import { RECOMMENDATIONS_STATE } from 'modules/recommendations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useMultiLayerPathParams } from '../../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import styles from './performanceRecommendationCard.module.scss';

export const PerformanceRecommendationCard = ({
    recommendationId,
    isApplied,
    titleCode,
    descriptionCode,
    iconImg,
    messageParams,
    children,
    isRenderedInReportCell = false,
}) => {
    const classNameBuilder = classnames.bind(styles);
    const CardIcon = iconImg;
    const { value: accountNumericId } = useSelectedAccount();
    const { formatMessage } = useIntl();
    const { campaignId } = useMultiLayerPathParams();
    return (
        <div
            aria-label={formatMessage({ id: titleCode })}
            className={classNameBuilder('card', { hasMargin: !isRenderedInReportCell })}
            data-metrics-visibility={GTM_EVENTS.VISIBLE}
            data-metrics-account-id={accountNumericId}
            data-metrics-taboola-campaign-id={campaignId}
            data-metrics-value={recommendationId}
            data-metrics-component="RecommendationCard"
            data-metrics-state={isApplied ? RECOMMENDATIONS_STATE.APPLIED : RECOMMENDATIONS_STATE.ACTIVE}
        >
            <div className={classNameBuilder('header', { disabled: isApplied })}>
                <CardIcon
                    className={classNameBuilder('title-icon', {
                        disabled: isApplied,
                        warning: isRenderedInReportCell,
                    })}
                />
                <FormattedMessage id={titleCode} defaultMessage="Optimization Opportunity" />
            </div>
            <div className={styles['description']}>
                <FormattedMessage
                    id={descriptionCode}
                    defaultMessage="Based on campaign performance, we've identified an opportunity to improve CVR by changing platform targeting."
                    values={messageParams}
                />
            </div>
            {children}
        </div>
    );
};

PerformanceRecommendationCard.propTypes = {
    titleCode: PropTypes.string,
    iconImg: PropTypes.func,
    descriptionCode: PropTypes.string,
    messageParams: PropTypes.object,
};

export default PerformanceRecommendationCard;
