import { useMemo } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { isNetworkAccountType } from '../../../account-management';
import {
    FEATURE_FLAGS,
    useAccount,
    useAccountConfig,
} from '../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { ScheduledReportsAccountWithCampaignsSelectField } from '../ScheduledReportsAccountWithCampaignsSelectField/ScheduledReportsAccountWithCampaignsSelectField';
import styles from './ScheduledReportsAccountSelectField.module.scss';

export const ScheduledReportsAccountSelectField = () => {
    const { [FEATURE_FLAGS.SCHEDULED_REPORTS_ACCOUNT_SELECT_ON_NETWORK_LEVEL]: accountSelectEnabledOnNetwork } =
        useAccountConfig([FEATURE_FLAGS.SCHEDULED_REPORTS_ACCOUNT_SELECT_ON_NETWORK_LEVEL]);
    const accountSelectEnabled = accountSelectEnabledOnNetwork === 'true';
    const { type } = useAccount();
    const isAccountNetwork = useMemo(() => isNetworkAccountType(type), [type]);
    const showAccountSelectOnNetworkLevel = accountSelectEnabled && isAccountNetwork;
    const showAccountSelect = showAccountSelectOnNetworkLevel || !isAccountNetwork;

    return showAccountSelect ? (
        <ScheduledReportsAccountWithCampaignsSelectField />
    ) : (
        <FormField
            containerClass={styles['account-field']}
            label={
                <FormattedMessage
                    id="app.create.scheduled.reports.network.native.accounts.title"
                    defaultMessage="Network Level Report - (All Native Accounts)"
                />
            }
        />
    );
};
