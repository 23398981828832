import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import {
    removeIndication,
    removeStaticIndication,
    ADD_STATIC_INDICATION,
    ADD_INDICATION,
    REMOVE_INDICATION,
    RESET_INDICATIONS,
    resetIndications,
} from './actions';
import { StaticIndications, Indications as ConnectedIndications } from './components/Indications';
import { addIndication, addStaticIndication } from './flows';
import indicationsReducer from './reducer/indicationsReducer';
import STATIC_INDICATION_TYPES from './staticIndicationTypes';

export default ConnectedIndications;
export {
    INDICATION_TYPES,
    indicationsReducer,
    StaticIndications,
    STATIC_INDICATION_TYPES,
    ADD_STATIC_INDICATION,
    addIndication,
    removeIndication,
    ADD_INDICATION,
    REMOVE_INDICATION,
    RESET_INDICATIONS,
    addStaticIndication,
    removeStaticIndication,
    resetIndications,
};
