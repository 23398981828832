import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityType } from 'hooks';
import { INITIAL_FORM_ACCOUNT_NAME } from 'hooks/queryParams/useInitialFormAccountName';
import {
    CAMPAIGNS_ROUTE,
    DIMENSION_TO_CREATIVE_ENTITY_MAP,
    PATH_TO_ENTITY_MAP,
    REPORT_TYPE,
    resolveReportIdByCampaignDimension,
} from 'modules/campaigns/config';
import { useAddPaymentModal } from 'modules/campaigns/hooks';
import { generateCreativeCreatorPath } from 'modules/campaigns/services/utils';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import {
    mergeQueryParams,
    mergeQueryParamsWithLocation,
} from 'modules/taboola-common-frontend-modules/query-params/queryParamUtils';
import { createCampaign } from '../../../flows';
import { postCampaignCreationSortState } from '../../../flows/createCampaign';
import { LAST_CREATED_CAMPAIGN_ID } from '../../../hooks/useLastCreatedCampaignId';
import { REPORT_SORTING } from '../../campaigns-reports/hooks/useReportSorting';
import { REPORT_ID } from '../../campaigns-reports/hooks/useSelectedReportId';
import { invalidateSubCountryData } from '../actions';
import { useReportReachToGTM } from '../components/ReachEstimator/hooks/useReportReachToGTM';
import styleConsts from '../../../../../globalStyle/styleConsts.module.scss';

const useCampaignCreatorState = ({ dimension }) => {
    const dispatch = useDispatch();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const { formAccount } = useFormDataContext();
    const { openModalAndGetPath: openAddPaymentModalAndGetPath } = useAddPaymentModal();
    const { submit, step, setStep, prevStep, nextStep } = useFormState();
    const { reportReachToGTM } = useReportReachToGTM();

    const createCampaignWrapper = useCallback(
        async successUrl => {
            const saveCampaign = campaign => dispatch(createCampaign(formAccount, campaign, entityType));
            await submit(saveCampaign, successUrl);
        },
        [submit, dispatch, formAccount, entityType]
    );

    const submitWrapper = useCallback(async () => {
        reportReachToGTM();
        const reportId = resolveReportIdByCampaignDimension(REPORT_TYPE.CAMPAIGN, dimension);
        const campaignReportPath = mergeQueryParamsWithLocation(
            { pathname: CAMPAIGNS_ROUTE },
            {
                [REPORT_ID]: reportId,
                [REPORT_SORTING]: postCampaignCreationSortState,
            }
        );

        const getSuccessUrl = async () => (await openAddPaymentModalAndGetPath()) || campaignReportPath;
        createCampaignWrapper(getSuccessUrl);
    }, [reportReachToGTM, createCampaignWrapper, dimension, openAddPaymentModalAndGetPath]);

    const handleSubmitCampaignAndNavigateToCreative = useCallback(() => {
        const creativeCreatorPath = generateCreativeCreatorPath({
            entity: DIMENSION_TO_CREATIVE_ENTITY_MAP[dimension],
        });
        const pathWithCampaignId = ({ id }) =>
            `${creativeCreatorPath}${mergeQueryParams('', {
                [LAST_CREATED_CAMPAIGN_ID]: id,
                [INITIAL_FORM_ACCOUNT_NAME]: formAccount.accountName,
            })}`;

        createCampaignWrapper(pathWithCampaignId);
    }, [formAccount, createCampaignWrapper, dimension]);

    //clearing resources
    useEffect(() => () => dispatch(invalidateSubCountryData()), [dispatch]);

    return {
        step,
        handleSetStep: setStep,
        prevStep,
        nextStep,
        submit: submitWrapper,
        handleSubmitCampaignAndNavigateToCreative,
        wizardTemplateColumns: `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`,
    };
};

export default useCampaignCreatorState;
