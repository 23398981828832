'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'defaultDelimiter-module__taboola-svg-icon___28LdO',
    'delimiter': 'defaultDelimiter-module__delimiter___3Iuw8'
};


var DefaultDelimiter = function DefaultDelimiter(_ref) {
    var className = _ref.className;
    return _react2.default.createElement('div', { className: (0, _classnames2.default)(styles['delimiter'], className) });
};

DefaultDelimiter.propTypes = {
    className: _propTypes2.default.string
};

exports.default = DefaultDelimiter;