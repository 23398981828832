import React from 'react';
import { INDICATION_TYPES, STATIC_INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FILTER_TYPE } from '../../../modules/campaigns-reports/config/filters/filterType';

export const AVAILABLE_MSV2_CREATIVE_REPORT_FILTERS_START_DATE = Date.parse('2022-11-01');
export const UNAVAILABLE_MSV2_CREATIVE_REPORT_FILTERS = [
    FILTER_TYPE.OS_FAMILY,
    FILTER_TYPE.BROWSER,
    FILTER_TYPE.COUNTRY,
    FILTER_TYPE.SITE,
    FILTER_TYPE.PLATFORM,
];

const getReportFiltersDataUnavailableIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA,
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.reportFiltersUnavailableWarning.highlight"
            defaultMessage="Filters are unavailable for this date range"
        />
    ),
    message: (
        <FormattedMessage
            defaultMessage="- Please choose a date range that starts after November 01, 2022 or remove the filters."
            id="app.campaign.indication.reportFiltersUnavailableWarning.message"
        />
    ),
});
const getRetentionReasonFiltersUnavailableIndication = retentionMonths => ({
    staticIndicationType: STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA,
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.reportFiltersUnavailableWarning.highlight"
            defaultMessage="Filters are unavailable for this date range"
        />
    ),
    message: (
        <FormattedMessage
            defaultMessage="- Please choose a date range that is less than {monthsNum} months or remove the filters."
            id="app.campaign.indication.retentionReasonFiltersUnavailableIndication.message"
            values={{ monthsNum: retentionMonths }}
        />
    ),
});

export const getReportDataUnavailableIndication = (showRetentionMessage = false, retentionMonths) => {
    return showRetentionMessage
        ? getRetentionReasonFiltersUnavailableIndication(retentionMonths)
        : getReportFiltersDataUnavailableIndication();
};
