import { useCallback, useMemo } from 'react';
import { flatMapDeep, identity, isNil, keyBy, merge } from 'lodash';
import { FORM_MODES } from '../../../../campaigns/config';
import { TARGETING_TYPES } from '../../../../campaigns/modules/common-campaign-form';
import { COMMON_FLAGS, useCommonConfig } from '../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { useArticlesPreview } from './useArticlesPreview';
import { useFullTermsData } from './useFullTermsData';

const extractId = ({ name }) => name;

const isIncludedHasAnyItems = included => {
    const flat = flatMapDeep(included, identity);

    return flat.length !== 0;
};

const fullTermDataHasTerm = (fullTermData, term) => !!fullTermData?.filter(o => o.term === term).length;

const createEmptyTermData = term => {
    return { term, size: 0, articles: 0, label: term, name: term };
};

const sort = (terms, newSelection) => {
    if (!newSelection || !terms) {
        return [];
    }

    return terms.sort((a, b) => {
        if (newSelection.includes(a.term) && newSelection.includes(b.term)) {
            return newSelection.indexOf(a) - newSelection.indexOf(b);
        }
        if (newSelection.includes(a.term)) {
            return -1;
        }
        if (newSelection.includes(b.term)) {
            return 1;
        }
        return b.articles - a.articles;
    });
};

export const useTopicsListField = newSelection => {
    const {
        value: terms = { include: [], exclude: [] },
        onChange: setTerms,
        mode,
    } = useFormFieldValue({
        field: 'terms',
    });
    const isNotCreate = mode !== FORM_MODES.CREATE;
    const { include, exclude } = terms;
    const { [COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER]: maxAllowedTopics } = useCommonConfig([
        COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER,
    ]);

    const fullTermData = useFullTermsData(maxAllowedTopics);
    const { articlesPreview, isLoadingArticles } = useArticlesPreview(maxAllowedTopics);

    const addTrafficlessTerms = useCallback(() => {
        if (include.length && include[0].length && !isNil(fullTermData)) {
            include[0].forEach(term =>
                !fullTermDataHasTerm(fullTermData, term) ? fullTermData.push(createEmptyTermData(term)) : null
            );
        }
        if (exclude.length && !isNil(fullTermData)) {
            exclude.forEach(term =>
                !fullTermDataHasTerm(fullTermData, term) ? fullTermData.push(createEmptyTermData(term)) : null
            );
        }
    }, [exclude, fullTermData, include]);

    const termsLookup = useMemo(() => {
        addTrafficlessTerms();
        if (!fullTermData) {
            return;
        }
        const keyByTermsData = keyBy(fullTermData, ({ name }) => name);
        const keyByArticlesPreview = keyBy(articlesPreview, ({ term }) => term);

        return merge(keyByTermsData, keyByArticlesPreview);
    }, [addTrafficlessTerms, articlesPreview, fullTermData]);

    const { normalizedInclude, normalizedExclude } = useMemo(() => {
        if (!termsLookup) {
            return { normalizedInclude: [], normalizedExclude: [] };
        }

        const normalizedInclude = include.map(termsList =>
            termsList
                .filter(term => termsLookup[term])
                .map(term => ({
                    ...termsLookup[term],
                    isLoadingArticles,
                    targetingType: TARGETING_TYPES.INCLUDE,
                }))
        );
        const normalizedExclude = exclude
            .filter(term => termsLookup[term])
            .map(term => ({ ...termsLookup[term], isLoadingArticles, targetingType: TARGETING_TYPES.EXCLUDE }));

        return { normalizedInclude, normalizedExclude };
    }, [termsLookup, include, exclude, isLoadingArticles]);

    const setIncludedTopics = useCallback(
        includedTerms => {
            setTerms({
                ...terms,
                include: isIncludedHasAnyItems(includedTerms) ? includedTerms.map(list => list.map(extractId)) : [],
            });
        },
        [setTerms, terms]
    );
    const setExcludedTopics = useCallback(
        excludedTerms => {
            setTerms({ ...terms, exclude: excludedTerms.map(extractId) });
        },
        [setTerms, terms]
    );

    const sortedInclude = useMemo(() => {
        if (normalizedInclude && normalizedInclude[0] && !!normalizedInclude[0].length) {
            return [sort(normalizedInclude[0], newSelection)];
        }
        return normalizedInclude;
    }, [newSelection, normalizedInclude]);

    const sortedExclude = useMemo(() => {
        if (normalizedExclude && !!normalizedExclude.length) {
            return sort(normalizedExclude, newSelection);
        }
        return normalizedExclude;
    }, [newSelection, normalizedExclude]);

    return {
        setExcludedTopics,
        setIncludedTopics,
        include: sortedInclude,
        exclude: sortedExclude,
        isLoading: isNotCreate && isNil(fullTermData),
        articlesPreview,
        isLoadingArticles,
    };
};
