import { flow, values } from 'lodash';
import * as combinedAudiencesFromGWIndex from './combinedAudiencesFromGWIndex';
import * as combinedAudiencesToGWIndex from './combinedAudiencesToGWIndex';
import * as customContextualSegmentToGWindex from './customContextualSegmentToGWindex';
import * as externalAudiencesFromGWIndex from './externalAudiencesFromGWIndex';
import * as externalAudiencesToGWIndex from './externalAudiencesToGWIndex';
import * as lookalikeAudienceToGWindex from './lookalikeAudienceToGWindex';
import * as pixelBasedAudienceFromGWindex from './pixelBasedAudienceFromGWindex';
import * as pixelBasedAudienceToGWindex from './pixelBasedAudienceToGWindex';
import * as pixelBasedPredictiveAudienceToGWIndex from './pixelBasedPredictiveAudienceToGWIndex';

export const transformPixelBasedAudienceFromGW = flow(values(pixelBasedAudienceFromGWindex));
export const transformPixelBasedAudienceToGW = flow(values(pixelBasedAudienceToGWindex));
export const transformLookalikeAudienceToGW = flow(values(lookalikeAudienceToGWindex));
export const transformCustomContextualSegmentToGW = flow(values(customContextualSegmentToGWindex));
export const transformExternalAudiencesSegmentToGW = flow(values(externalAudiencesToGWIndex));
export const transformExternalAudiencesSegmentFromGW = flow(values(externalAudiencesFromGWIndex));
export const transformCombinedAudiencesSegmentToGW = flow(values(combinedAudiencesToGWIndex));
export const transformCombinedAudiencesSegmentFromGW = flow(values(combinedAudiencesFromGWIndex));
export const transformPixelBasedPredictiveAudienceToGW = flow(values(pixelBasedPredictiveAudienceToGWIndex));
