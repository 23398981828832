import React from 'react';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../../../config/PaymentType';
import styles from '../../addFundsModal.module.scss';

export const InstructionsCreditCard = () => {
    return (
        <div className={styles['credit-card-prepayment-instructions']}>
            <li className={styles['instruction-text']}>
                <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.instructions" />
                <HelpTooltip iconClassName={styles['help-icon']} position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage id="billingAndPayments.modal.addFunds.receipt.tooltip" />
                </HelpTooltip>
            </li>
            <li className={styles['instruction-text']}>
                <FormattedMessage id={`billingAndPayments.modal.addFunds.${PAYMENT_TYPE.CARD}.instruction`} />
            </li>
        </div>
    );
};
