import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isProgrammaticCampaign } from 'services/campaignDimension';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import LabelLayoutCellRenderer from './LabelLayoutCellRenderer';

class BudgetCellRenderer extends Component {
    render() {
        const { value, data, ...rest } = this.props;

        const msgIdPrefix = 'app.campaigns.campaign.budget.type';
        const budgetType = data?.budget?.type || EMPTY_FIELD_VALUE;
        const msgId = `${msgIdPrefix}.${budgetType}`;
        const { demandType } = data;

        if (isProgrammaticCampaign(demandType)) {
            return EMPTY_FIELD_VALUE;
        }

        return (
            <LabelLayoutCellRenderer className="budget-value" labelClassName="budget-type" msgId={msgId}>
                <div>{value ? <FormattedNumberCellRenderer value={value} {...rest} /> : EMPTY_FIELD_VALUE}</div>
            </LabelLayoutCellRenderer>
        );
    }
}

BudgetCellRenderer.propTypes = {
    /** budget value */
    value: PropTypes.number,
    /** data which is injected by the grid - The row data object */
    data: PropTypes.object,
};

export default BudgetCellRenderer;
