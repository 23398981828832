import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import AudienceDetails from '../../AudienceDetails/AudienceDetails';
import {
    isCrmLookalikeAudience,
    defaultSimilarityLevelForPixelLookalike,
    defaultSimilarityLevelForCrmLookalike,
    isPixelLookalikeAudience,
} from '../utils/MyAudienceUtils';

const tailStyle = { zIndex: 1 };
const disabledTooltip = (
    <Tooltip position={TOOLTIP_POSITION.BOTTOM} arrow duration={0} delay={800} tailStyle={tailStyle}>
        <FormattedMessage
            id="campaign.editor.targeting.my.audiences.exclude.lookalike.disabled.tooltip"
            defaultMessage="Currently, it is not possible to exclude predictive audiences."
        />
    </Tooltip>
);

const sizeTooltip = (
    <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow duration={0} delay={0}>
        <FormattedMessage
            id="campaign.editor.targeting.my.audiences.combined.audience.size.tooltip"
            defaultMessage="Size will be populated in 24 hours"
        />
    </Tooltip>
);

const MyAudienceDropDownOption = ({
    data: { label, audienceType, audienceSize, similarityLevelToSize, isDisabled },
    selectProps: { inputValue },
}) => {
    const similarityLevel = isCrmLookalikeAudience(audienceType)
        ? defaultSimilarityLevelForCrmLookalike
        : defaultSimilarityLevelForPixelLookalike;
    const isLookalikeAudience = isCrmLookalikeAudience(audienceType) || isPixelLookalikeAudience(audienceType);
    const size = isLookalikeAudience && similarityLevelToSize ? similarityLevelToSize[similarityLevel] : audienceSize;

    return (
        <AudienceDetails
            name={label}
            highLightText={inputValue}
            detail={audienceType}
            size={size}
            minSize={100}
            sizeTooltip={audienceType === 'COMBINED_AUDIENCE' && sizeTooltip}
            disabled={isDisabled}
            disabledTooltip={disabledTooltip}
        />
    );
};

export default MyAudienceDropDownOption;
