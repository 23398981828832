import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { MAX_SEGMENTS_TO_SELECT } from '../../Tree';
import { useMarketplaceAudienceTargetingValues } from './useMarketplaceAudienceTargetingValues';

export const useSelectedSegmentsValidationDependencies = ({ enabledProvidersMap, leavesByIdMap, field }) => {
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });

    const countriesMap = useMemo(() => keyBy(countryCodes), [countryCodes]);

    const { includeLength: marketPlaceAudienceValuesLength } = useMarketplaceAudienceTargetingValues();

    const { value: { length: myAudienceIncludeValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.include',
    });

    const { value: { length: contextualSegmentsValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting.include',
    });

    const { value: { length: customContextualSegmentsValuesLength } = [] } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.include',
    });

    const { value: countryTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });

    const errorValidationDependencies = useMemo(() => {
        return {
            countriesMap,
            countryTargetingType,
            enabledProvidersMap,
            leavesByIdMap,
            limit: MAX_SEGMENTS_TO_SELECT,
        };
    }, [countriesMap, countryTargetingType, enabledProvidersMap, leavesByIdMap]);

    const { value: { length: fieldLength } = [] } = useFormFieldValue({ field });

    const warningValidationDependencies = useMemo(() => {
        return {
            data: {
                myAudienceIncludeValuesLength,
                marketPlaceAudienceValuesLength,
                contextualSegmentsValuesLength,
                customContextualSegmentsValuesLength,
                fieldLength,
            },
        };
    }, [
        myAudienceIncludeValuesLength,
        marketPlaceAudienceValuesLength,
        contextualSegmentsValuesLength,
        customContextualSegmentsValuesLength,
        fieldLength,
    ]);

    const validationDependencies = useMemo(
        () => ({ ...errorValidationDependencies, ...warningValidationDependencies }),
        [errorValidationDependencies, warningValidationDependencies]
    );

    return validationDependencies;
};
