const config = {
    feedPlacements: [
        {
            containerId: 'below-article-thumbnails',
            placementName: 'Ads Console - Creative Preview - Feed',
            mode: 'thumbnails-a',
        },
    ],
    feedBPPlacements: [
        {
            containerId: 'below-article-thumbnails',
            placementName: 'Ads Console - Creative Preview - Feed BP',
            mode: 'thumbnails-a',
        },
    ],
    appInstallPlacements: [
        {
            containerId: 'below-article-thumbnails',
            placementName: 'Ads Console - Creative Preview - App Install ',
            mode: 'thumbnails-a-app',
        },
    ],
    applePlacements: [
        {
            containerId: 'below-article-thumbnails',
            placementName: 'Ads Console - Creative Preview - Apple',
            mode: 'thumbnails-a',
        },
    ],
    onBoardingJourneyPlacements: [
        {
            containerId: '2x1',
            placementName: 'Ads Console - Creative Preview - 2x1',
            mode: 'thumbnails-2x1-3-columns',
        },
    ],
    numMaxItems: 100,
};

export default config;
