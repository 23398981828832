import React from 'react';
import { stubTrue } from 'lodash';
import {
    reportsBaseConfig,
    REPORT_TYPE,
    creativeTitleValidations,
    maxCreativeTitleLength,
    TITLE_WARNING_THRESHOLD,
    creativeDescriptionValidations,
    urlValidations,
} from 'modules/campaigns/config';
import { deleteCreative } from 'modules/campaigns/flows';
import { setSelectedRows } from 'modules/campaigns/modules/campaigns-reports/actions';
import {
    MAX_DESCRIPTION_LENGTH,
    DESCRIPTION_WARNING_THRESHOLD,
} from 'modules/campaigns/modules/creative-creator/components/ContentField/config';
import { PERFORMANCE_RECOMMENDATIONS } from 'modules/campaigns/modules/performance-recommendations/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import {
    FormattedMessageWithTooltipHeaderRenderer,
    FormattedMessageHeaderRenderer,
    ImageCellRenderer,
    IMAGE_CELL_RENDERER_MODES,
    EllipsisCellRenderer,
    StateCellRenderer,
    withCreativeEditIndication,
    CreativeStatusRenderer,
    LinkCellRenderer,
    CreativeActionsCellRenderer,
    ContentCellRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    CheckboxHeaderRenderer,
    CheckboxCellRenderer,
    CreativeIdCellRenderer,
    RssBulkDisabledTooltip,
    StoppedCreativeBulkDisabledTooltip,
} from '../../../components';
import { editCreative, saveCreative, previewCreative, duplicateCreative } from '../../../flows';
import { normalizeColumnDefs } from '../../../utils';
import {
    editableColumn,
    staticColumn,
    keyColumn,
    leftPinnedColumn,
    toggleColumn,
    numericColumn,
    rightPinnedColumn,
    actionsColumnType,
    checkBoxSelectionColumn,
    lockedColumn,
    bypassMetadataFilterType,
} from '../../columns';
import { nonDefaultHeaderComponentParams } from '../../fields';
import * as FIELDS from '../../fields';
import { isCreativeRejected, isCreativeStopped, isRssChild } from '../../predicates';

const isDisabled = data => {
    return isRssChild(data) || isCreativeStopped(data);
};

const tooltipContent = data =>
    (isRssChild(data) && <RssBulkDisabledTooltip />) ||
    (isCreativeStopped(data) && <StoppedCreativeBulkDisabledTooltip />) ||
    null;

const creativeReportColumnDefinition = [
    {
        headerComponentFramework: CheckboxHeaderRenderer,
        headerName: FIELDS.IS_SELECTED.label,
        field: FIELDS.IS_SELECTED.field,
        type: [leftPinnedColumn, checkBoxSelectionColumn, staticColumn],
        cellRendererParams: {
            onClick: setSelectedRows,
            isDisabled,
            tooltipContent,
        },
        headerComponentParams: {
            onClick: setSelectedRows,
            isDisabled,
            tooltipContent,
        },
        valueGetter: FIELDS.IS_SELECTED.valueGetter,
        cellRendererFramework: CheckboxCellRenderer,
        csv: false,
        permissions: {
            permissions: ['CAMPAIGNS_ITEMS_BULK_UPDATE'],
        },
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVES_BULK_ACTIONS_ENABLED]: 'true',
        },
    },
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [lockedColumn, toggleColumn, leftPinnedColumn, staticColumn],
        cellRendererParams: {
            ...FIELDS.IS_ACTIVE.renderParams,
            isDisabled: data => isCreativeStopped(data) || isCreativeRejected(data),
            reportId: REPORT_TYPE.CREATIVE,
        },
        valueChangeHandler: saveCreative,
        cellRendererFramework: StateCellRenderer,
        csv: false,
    },
    {
        headerName: FIELDS.CUSTOM_CREATIVE_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CUSTOM_CREATIVE_NAME.headerComponentParams,
        field: FIELDS.CUSTOM_CREATIVE_NAME.field,
        type: [leftPinnedColumn, editableColumn],
        editable: stubTrue,
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        cellEditorFramework: FIELDS.CUSTOM_CREATIVE_NAME.cellEditorFramework,
        valueChangeHandler: saveCreative,
        valueFormatter: FIELDS.CUSTOM_CREATIVE_NAME.valueFormatter,
        accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
    },
    {
        headerName: FIELDS.CUSTOM_ID.label,
        field: FIELDS.CUSTOM_ID.field,
        type: [leftPinnedColumn, editableColumn],
        editable: stubTrue,
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        cellEditorFramework: FIELDS.CUSTOM_ID.cellEditorFramework,
        valueChangeHandler: saveCreative,
        valueFormatter: FIELDS.CUSTOM_CREATIVE_NAME.valueFormatter,
        accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
    },
    {
        headerName: FIELDS.ID.label,
        field: FIELDS.ID.field,
        type: [numericColumn],
        cellRendererFramework: CreativeIdCellRenderer,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.CREATIVE_REALTIME),
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.THUMBNAIL_URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL.field,
        type: [leftPinnedColumn, editableColumn],
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.DEFAULT,
        },
        singleClickEdit: false,
        onCellClicked: editCreative,
        width: 120,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
        sortable: false,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.THUMBNAIL_URL_COMFORTABLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
        type: [leftPinnedColumn, editableColumn, bypassMetadataFilterType],
        valueGetter: ({ data }) => {
            const { thumbnailUrl } = data;
            return thumbnailUrl;
        },
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.COMFORTABLE,
        },
        onCellClicked: editCreative,
        singleClickEdit: false,
        width: 186,
        csv: [
            {
                headerName: 'Media',
                field: 'thumbnailUrl',
            },
        ],
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
        sortable: false,
    },
    {
        headerName: FIELDS.CONTENT.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CONTENT.headerComponentParams,
        type: [editableColumn, bypassMetadataFilterType, leftPinnedColumn],
        valueGetter: ({ data }) => {
            const { title, description, cta } = data;
            return { title, description, cta };
        },
        field: FIELDS.CONTENT.field,
        cellRendererFramework: ContentCellRenderer,
        cellRendererParams: {
            lines: 2,
            ctaMsgIdPrefix: 'creative.creator.content.cta.option',
            isSearchable: true,
        },
        singleClickEdit: false,
        width: 200,
        csv: [
            {
                headerName: 'Title',
                field: 'title',
            },
            {
                headerName: 'Description',
                field: 'description',
            },
            ...FIELDS.CTA.csv,
        ],
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
        sortable: false,
        onCellClicked: editCreative,
    },
    {
        headerName: FIELDS.TITLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.TITLE.field,
        type: [keyColumn, editableColumn, leftPinnedColumn],
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2, isSearchable: true },
        cellEditorFramework: FIELDS.TITLE.cellEditorFramework,
        cellEditorParams: {
            validations: creativeTitleValidations,
            maxLength: maxCreativeTitleLength,
            warningThreshold: TITLE_WARNING_THRESHOLD,
        },
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
        width: 120,
        sortable: false,
    },
    {
        headerName: FIELDS.URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.URL.headerComponentParams,
        field: FIELDS.URL.field,
        type: [editableColumn],
        cellRendererFramework: LinkCellRenderer,
        width: 150,
        cellEditorFramework: FIELDS.URL.cellEditorFramework,
        cellEditorParams: {
            validations: urlValidations,
        },
        valueChangeHandler: saveCreative,
        cellRendererParams: { isSearchable: true },
    },
    {
        headerName: FIELDS.DESCRIPTION.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.DESCRIPTION.headerComponentParams,
        field: FIELDS.DESCRIPTION.field,
        type: [editableColumn],
        cellRendererFramework: EllipsisCellRenderer,
        width: 250,
        cellEditorFramework: FIELDS.DESCRIPTION.cellEditorFramework,
        cellEditorParams: {
            validations: creativeDescriptionValidations,
            maxLength: MAX_DESCRIPTION_LENGTH,
            warningThreshold: DESCRIPTION_WARNING_THRESHOLD,
        },
        valueChangeHandler: saveCreative,
        cellRendererParams: { isSearchable: true },
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.STATUS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.STATUS.field,
        type: [leftPinnedColumn],
        cellRendererFramework: CreativeStatusRenderer,
        flex: 1,
        minWidth: 200,
        cellRendererParams: {
            ...FIELDS.STATUS.renderParams,
            recommendationTypes: [
                PERFORMANCE_RECOMMENDATIONS.LOW_PERFORMING_ITEM,
                PERFORMANCE_RECOMMENDATIONS.LOW_RESOLUTION_IC,
                PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_DESCRIPTION,
                PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_CTA,
            ],
        },
        csvValueGetter: FIELDS.STATUS.csvValueGetter,
    },
    {
        headerName: FIELDS.CTA.label,
        field: FIELDS.CTA.field,
        valueGetter: FIELDS.CTA.valueGetter,
        type: [editableColumn],
        cellRendererFramework: FIELDS.CTA.cellRenderer,
        cellRendererParams: FIELDS.CTA.renderParams,
        cellEditorFramework: FIELDS.CTA.cellEditorFramework,
        valueChangeHandler: saveCreative,
        csv: FIELDS.CTA.csv,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.START_DATE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.START_DATE.headerComponentParams,
        field: FIELDS.START_DATE.field,
        valueFormatter: FIELDS.START_DATE.valueFormatter,
        csvValueGetter: FIELDS.START_DATE.csvValueGetter,
        accountConfigurations: { [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.END_DATE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.END_DATE.headerComponentParams,
        field: FIELDS.END_DATE.field,
        valueFormatter: FIELDS.END_DATE.valueFormatter,
        csvValueGetter: FIELDS.END_DATE.csvValueGetter,
        accountConfigurations: { [FEATURE_FLAGS.AD_SCHEDULER_ENABLED]: 'true' },
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        cellRendererParams: {
            onDelete: deleteCreative,
            onPreview: previewCreative,
            onEdit: editCreative,
            onDuplicate: duplicateCreative,
        },
        cellRendererFramework: CreativeActionsCellRenderer,
        csv: false,
    },
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.VCTR,
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    creativeReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CREATIVE_REALTIME].id,
    withCreativeEditIndication
);
