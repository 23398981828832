import { useCallback } from 'react';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { PRICING_MODEL } from '../../../../../config';
import { BID_STRATEGIES } from '../../../config';
import { useCpaGoalFormFieldValue } from '../../CpaGoal/useCpaGoalFormFieldValue';
import { getBidStrategyCacheKey, useBidStrategyCacheContext } from '../BidStrategyCacheProvider';
import { bidStrategiesCategory } from '../hooks/useBidStrategiesOptions';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';
import { useIsApplyCapActiveField } from './useIsApplyCapActiveField';

export const useApplyCapState = () => {
    const { onChange: onChangeBidStrategy } = useBidStrategyFormField();
    const { resetFieldToInitial: resetTargetCpa, onChange: onChangeTargetCpa } = useCpaGoalFormFieldValue();
    const { resetFieldToInitial: resetCpcCap, onChange: onChangeCpcCap } = useFormFieldValue({
        field: 'cpcCap',
    });
    const { value: isApplyCapActive, onChange: setIsApplyCapActive } = useIsApplyCapActiveField();
    const { bidStrategyCache, targetCpaCache, cpcCapCache } = useBidStrategyCacheContext();

    const handleCheckboxSelect = useCallback(() => {
        setIsApplyCapActive(true);

        const calculatedBidStrategy =
            bidStrategyCache[
                getBidStrategyCacheKey({
                    bidStrategyCategory: bidStrategiesCategory.MAX_CONVERSIONS,
                    pricingModel: PRICING_MODEL.CPC,
                    isApplyCapActive: true,
                })
            ] ?? BID_STRATEGIES.MAX_CONVERSIONS;

        onChangeBidStrategy(calculatedBidStrategy);

        const cachedTargetCpa =
            calculatedBidStrategy === BID_STRATEGIES.TARGET_CPA ? targetCpaCache.targetCpa : undefined;
        if (cachedTargetCpa) {
            onChangeTargetCpa(cachedTargetCpa);
        } else {
            resetTargetCpa();
        }

        const cachedCpcCap = calculatedBidStrategy === BID_STRATEGIES.MAX_CONVERSIONS ? cpcCapCache.cpcCap : undefined;
        if (cachedCpcCap) {
            onChangeCpcCap(cachedCpcCap);
        } else {
            resetCpcCap();
        }
    }, [
        bidStrategyCache,
        cpcCapCache.cpcCap,
        onChangeBidStrategy,
        onChangeCpcCap,
        onChangeTargetCpa,
        resetCpcCap,
        resetTargetCpa,
        setIsApplyCapActive,
        targetCpaCache.targetCpa,
    ]);

    const handleCheckboxUnselect = useCallback(() => {
        setIsApplyCapActive(false);
        onChangeBidStrategy(BID_STRATEGIES.MAX_CONVERSIONS);
        resetCpcCap();
        resetTargetCpa();
    }, [onChangeBidStrategy, resetCpcCap, resetTargetCpa, setIsApplyCapActive]);

    return {
        isApplyCapActive,
        handleCheckboxSelect,
        handleCheckboxUnselect,
    };
};
