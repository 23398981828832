const EVENT_NAME_PARAM = 'event_name';

const ADVERTISER_ID_PARAM = '<NUMERIC_ADVERTISER_ID>';

export const buildCodeSnippetWithParams = (codeSnippet, accountId, eventName) => {
    let codeSnippetWithParams = accountId ? codeSnippet.replaceAll(ADVERTISER_ID_PARAM, accountId) : codeSnippet;
    codeSnippetWithParams = eventName
        ? codeSnippetWithParams.replaceAll(EVENT_NAME_PARAM, eventName)
        : codeSnippetWithParams;
    return codeSnippetWithParams;
};
