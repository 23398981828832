import React from 'react';
import { ListIcon, ComfortableListIcon, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { ToolbarToggle } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useReportComfortableViewParam } from '../../../hooks/useReportComfortableViewParam';

export const ComfortableViewToggle = () => {
    const [isComfortable, setComfortable] = useReportComfortableViewParam();

    return (
        <ToolbarToggle
            onLeftChildClick={() => setComfortable(true)}
            leftChild={
                <>
                    <Tooltip arrow position={TOOLTIP_POSITION.BOTTOM_START}>
                        <FormattedMessage
                            id="app.gridToolbar.comfortableViewToggle.comfortable.tooltip"
                            defaultMessage="Comfortable View"
                        />
                    </Tooltip>
                    <ComfortableListIcon aria-label="Enable comfortable view" />
                </>
            }
            onRightChildClick={() => setComfortable(false)}
            rightChild={
                <>
                    <Tooltip arrow position={TOOLTIP_POSITION.BOTTOM_START}>
                        <FormattedMessage
                            id="app.gridToolbar.comfortableViewToggle.table.tooltip"
                            defaultMessage="Table View"
                        />
                    </Tooltip>
                    <ListIcon aria-label="Disable comfortable view" />
                </>
            }
            isLeftToggleSelected={isComfortable}
            dataAutomationKey="ComfortableViewToggle"
        />
    );
};
