import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import LearnMore from '../../../common-campaign-form/components/LearnMore';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

const ThumbnailsFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="creative.editor.tab.upload.device.help.text.image.title"
            defaultMessage="<strong>Image Ads:</strong>"
        />
        <FormattedMessage
            id="creative.editor.tab.upload.device.help.text.image.body"
            defaultMessage="Recommended Image size: 1200 x 674{newline}Recommended aspect Ratio: 16:9{newline}Min. required: 400 x 350{newline}Max file size: 5 MB"
        />
        <FormattedMessage
            id="creative.editor.tab.upload.device.help.text.video.title"
            defaultMessage="{newline}<strong>Motion Ads:</strong>"
        />
        <FormattedMessage
            id="creative.editor.tab.upload.device.help.text.video.body"
            defaultMessage="Supported video file types: MP4, MOV, GIF.{newline}Minimum video size: 600 x 400{newline}Recommended aspect Ratio: 16:9{newline}Video Length: 15s{newline}Max Video file size : 50Mb{newline}Max GIF file size GIF: 5Mb"
        />
        <LearnMore href="https://help.taboola.com/hc/en-us/articles/360045103514-About-Video-Sponsored-Content" />
    </TooltipSection>
);

export default ThumbnailsFieldTooltip;
