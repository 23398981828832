import { ColorDot } from '../../components';
import * as FIELDS from '../fields';
import { numberFormatter } from '../fields/transformers';
import staticGraphColors from '../../../../../../globalStyle/styleConsts.module.scss';

export const XAxisFormat = 'MMM DD';
export const XAxisFormatWithYear = 'MMM YYYY';

const REFERENCE_LINES = {
    TARGET_CPA: {
        field: FIELDS.TARGET_CPA.field,
        name: FIELDS.TARGET_CPA.field,
        label: FIELDS.TARGET_CPA.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        strokeDasharray: '8 6',
        color: staticGraphColors[FIELDS.TARGET_CPA.field],
    },
};

const metrics = {
    SPENT: {
        field: FIELDS.SPENT.field,
        label: FIELDS.SPENT.label,
        active: true,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.SPENT.field],
    },
    PROFIT_MARGIN: {
        field: FIELDS.PROFIT_MARGIN.field,
        label: FIELDS.PROFIT_MARGIN.label,
        active: true,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.PROFIT_MARGIN.field],
    },
    VISIBLE_IMPRESSIONS: {
        field: FIELDS.VISIBLE_IMPRESSIONS.field,
        label: FIELDS.VISIBLE_IMPRESSIONS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.VISIBLE_IMPRESSIONS.field],
    },
    IMPRESSIONS: {
        field: FIELDS.IMPRESSIONS.field,
        label: FIELDS.IMPRESSIONS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.IMPRESSIONS.field],
    },
    CLICKS: {
        field: FIELDS.CLICKS.field,
        label: FIELDS.CLICKS.label,
        active: true,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CLICKS.field],
    },
    ACTIONS_CONVERSIONS: {
        field: FIELDS.CONVERSIONS.field,
        label: FIELDS.CONVERSIONS.label,
        formatter: numberFormatter,
        color: staticGraphColors[FIELDS.CONVERSIONS.field],
    },
    ACTIONS_CONVERSIONS_CLICKS: {
        field: FIELDS.CONVERSIONS_CLICKS.field,
        label: FIELDS.CONVERSIONS_CLICKS.label,
        formatter: numberFormatter,
        color: staticGraphColors[FIELDS.CONVERSIONS_CLICKS.field],
    },
    ACTIONS_CONVERSIONS_VIEWS: {
        field: FIELDS.CONVERSIONS_VIEWS.field,
        label: FIELDS.CONVERSIONS_VIEWS.label,
        formatter: numberFormatter,
        color: staticGraphColors[FIELDS.CONVERSIONS_VIEWS.field],
    },
    VCTR: {
        field: FIELDS.VCTR.field,
        label: FIELDS.VCTR.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.VCTR.field],
    },
    CTR: {
        field: FIELDS.CTR.field,
        label: FIELDS.CTR.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CTR.field],
    },
    AVERAGE_CPC: {
        field: FIELDS.AVERAGE_CPC.field,
        label: FIELDS.AVERAGE_CPC.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.AVERAGE_CPC.field],
    },
    CVR: {
        field: FIELDS.CVR.field,
        label: FIELDS.CVR.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CVR.field],
    },
    CVR_CLICKS: {
        field: FIELDS.CVR_CLICKS.field,
        label: FIELDS.CVR_CLICKS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CVR_CLICKS.field],
    },
    CVR_VIEWS: {
        field: FIELDS.CVR_VIEWS.field,
        label: FIELDS.CVR_VIEWS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CVR_VIEWS.field],
    },
    CONVERSIONS: {
        field: FIELDS.CONVERSIONS.field,
        label: FIELDS.CONVERSIONS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CONVERSIONS.field],
    },
    CONVERSIONS_CLICKS: {
        field: FIELDS.CONVERSIONS_CLICKS.field,
        label: FIELDS.CONVERSIONS_CLICKS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CONVERSIONS_CLICKS.field],
    },
    CONVERSIONS_VIEWS: {
        field: FIELDS.CONVERSIONS_VIEWS.field,
        label: FIELDS.CONVERSIONS_VIEWS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CONVERSIONS_VIEWS.field],
    },
    CPA: {
        field: FIELDS.CPA.field,
        label: FIELDS.CPA.label,
        referenceLines: [REFERENCE_LINES.TARGET_CPA],
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.CPA.field],
    },
    CPA_CLICKS: {
        field: FIELDS.CPA_CLICKS.field,
        label: FIELDS.CPA_CLICKS.label,
        lineParams: FIELDS.CPA_CLICKS.lineParams,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.CPA_CLICKS.field],
    },
    CPA_VIEWS: {
        field: FIELDS.CPA_VIEWS.field,
        label: FIELDS.CPA_VIEWS.label,
        lineParams: FIELDS.CPA_VIEWS.lineParams,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.CPA_VIEWS.field],
    },
    VCPM: {
        field: FIELDS.VCPM.field,
        label: FIELDS.VCPM.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.VCPM.field],
    },
    CPM: {
        field: FIELDS.CPM.field,
        label: FIELDS.CPM.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.CPM.field],
    },
    CONVERSIONS_VALUE: {
        field: FIELDS.CONVERSIONS_VALUE.field,
        label: FIELDS.CONVERSIONS_VALUE.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        legendOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 2,
            notation: 'compact',
        },
        labelOptions: {
            style: 'currency',
            currency: 'usd',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.CONVERSIONS_VALUE.field],
    },
    ROAS: {
        field: FIELDS.ROAS.field,
        label: FIELDS.ROAS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.ROAS.field],
    },
    ROAS_CLICKS: {
        field: FIELDS.ROAS_CLICKS.field,
        label: FIELDS.ROAS_CLICKS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.ROAS_CLICKS.field],
    },
    ROAS_VIEWS: {
        field: FIELDS.ROAS_VIEWS.field,
        label: FIELDS.ROAS_VIEWS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.ROAS_VIEWS.field],
    },
    CPCV: {
        field: FIELDS.CPCV.field,
        label: FIELDS.CPCV.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 3,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 3,
        },
        color: staticGraphColors[FIELDS.AVERAGE_CPC.field],
    },
    VIEWABILITY_RATE: {
        field: FIELDS.VIEWABILITY_RATE.field,
        label: FIELDS.VIEWABILITY_RATE.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.VIEWABILITY_RATE.field],
    },
    COMPLETION_RATE: {
        field: FIELDS.COMPLETION_RATE.field,
        label: FIELDS.COMPLETION_RATE.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CVR.field],
    },
    HUNDRED_PCT_VIEWABILITY_RATE: {
        field: FIELDS.HUNDRED_PCT_VIEWABILITY_RATE.field,
        label: FIELDS.HUNDRED_PCT_VIEWABILITY_RATE.label,
        formatter: numberFormatter,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.HUNDRED_PCT_VIEWABILITY_RATE.field],
    },
    COMPLETED_VIEWS: {
        field: FIELDS.COMPLETED_VIEWS.field,
        label: FIELDS.COMPLETED_VIEWS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.CONVERSIONS.field],
    },
    UNIQUE_IMPRESSIONS: {
        field: FIELDS.UNIQUE_IMPRESSIONS.field,
        label: FIELDS.UNIQUE_IMPRESSIONS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.UNIQUE_IMPRESSIONS.field],
    },
    UNIQUE_VIEWABLE_IMPRESSIONS: {
        field: FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS.field,
        label: FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS.field],
    },
    UNIQUE_CLICKS: {
        field: FIELDS.UNIQUE_CLICKS.field,
        label: FIELDS.UNIQUE_CLICKS.label,
        formatter: numberFormatter,
        yAxisOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            notation: 'compact',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.UNIQUE_CLICKS.field],
    },
    AUDIENCE_INDEX: {
        field: FIELDS.AUDIENCE_INDEX.field,
        label: FIELDS.AUDIENCE_INDEX.label,
        formatter: numberFormatter,
        active: true,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.AUDIENCE_INDEX.field],
        toggleComponent: ColorDot,
        disableLegendRow: true,
        disableLegendTooltip: true,
    },
    BASELINE_INDEX: {
        field: FIELDS.BASELINE_INDEX.field,
        label: FIELDS.BASELINE_INDEX.label,
        formatter: numberFormatter,
        active: true,
        yAxisOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        legendOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        labelOptions: {
            style: 'percent',
            maximumFractionDigits: 2,
        },
        color: staticGraphColors[FIELDS.BASELINE_INDEX.field],
        toggleComponent: ColorDot,
        disableLegendRow: true,
        disableLegendTooltip: true,
    },
};

export default metrics;
