import { CheckCircleOutlinedIcon, RemoveCircleOutlinedIcon } from 'tuui';
import { STATUS_TYPE } from '../../campaigns/config';
import { getStatusType } from '../../campaigns/config/statusConsts';

export const RECENT_ACTIVITIES_PERIOD = 48;

export const PIXEL_STATE = {
    NOT_INSTALLED: 'NOT_INSTALLED',
    NO_ACTIVITY: 'NO_ACTIVITY',
};

export const TRACKING_STATUS = {
    NOT_INSTALLED: 'NOT_INSTALLED',
    NO_ACTIVITY: 'NO_ACTIVITY',
    NO_RECENT_ACTIVITY: 'NO_RECENT_ACTIVITY',
    ACTIVE: 'ACTIVE',
};

export const TRACKING_TYPE = {
    PIXEL: 'pixel',
    S2S: 's2s',
};

export const TRACKING_STATUS_CONFIG = {
    [TRACKING_STATUS.NOT_INSTALLED]: {
        id: TRACKING_STATUS.NOT_INSTALLED,
        label: 'Not Installed',
        icon: RemoveCircleOutlinedIcon,
        className: 'reject',
        descriptionCode: 'tracking.status.subtext.not.install',
        defaultMessage: 'Install Pixel to start tracking',
    },
    [TRACKING_STATUS.NO_RECENT_ACTIVITY]: {
        id: TRACKING_STATUS.NO_RECENT_ACTIVITY,
        label: 'No Recent Activity',
        icon: RemoveCircleOutlinedIcon,
        className: 'no-active',
        descriptionCode: '"tracking.status.subtext.last.active',
        defaultMessage: 'Last received {lastEvent}',
    },
    [TRACKING_STATUS.NO_ACTIVITY]: {
        id: TRACKING_STATUS.NO_ACTIVITY,
        label: 'No Activity',
        icon: RemoveCircleOutlinedIcon,
        className: 'reject',
        descriptionCode: 'tracking.status.subtext.no.active',
        defaultMessage: 'Waiting for Pixel to fire',
    },
    [TRACKING_STATUS.ACTIVE]: {
        id: TRACKING_STATUS.ACTIVE,
        label: 'Active',
        icon: CheckCircleOutlinedIcon,
        className: 'success',
        descriptionCode: 'tracking.status.subtext.last.active',
        defaultMessage: 'Last received {lastEvent}',
    },
};

export const CONVERSION_TYPE = {
    BASIC: 'BASIC',
    EVENT_BASED: 'EVENT_BASED',
};

export const CONVERSION_STATUS = {
    ACTIVE: 'ACTIVE',
    NO_ACTIVITY_YET: 'NO_ACTIVITY_YET',
    NO_RECENT_ACTIVITY: 'NO_RECENT_ACTIVITY',
    DISABLED: 'DISABLED',
    ARCHIVED: 'ARCHIVED',
    ALL_BUT_ARCHIVED: 'ALL_BUT_ARCHIVED',
};

export const CONVERSION_STATUS_KEYS = Object.keys(CONVERSION_STATUS);

const nonInfoConversionStatusConfig = {
    [CONVERSION_STATUS.ACTIVE]: { statusType: STATUS_TYPE.SUCCESS },
    [CONVERSION_STATUS.NO_ACTIVITY_YET]: { statusType: STATUS_TYPE.DANGER },
};

export const getConversionStatusType = conversionStatus =>
    getStatusType(nonInfoConversionStatusConfig, conversionStatus);

export const INCLUDE_IN_TOTAL_FILTER_VALUES = ['TRUE', 'FALSE'];
export const INCLUDE_IN_TOTAL_VALUE_FILTER_VALUES = ['TRUE', 'FALSE'];
export const DEFINED_CONVERSIONS_FILTER_VALUES = ['TRUE', 'FALSE'];
export const DEFINED_CONVERSIONS_VALUE_FILTER_VALUES = ['TRUE', 'FALSE'];
