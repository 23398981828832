import { UPDATE_CAMPAIGNS_SUCCESS, UPDATE_CAMPAIGNS_ERROR, REQUEST_UPDATE_CAMPAIGNS } from './actionTypes';

export const requestUpdateCampaigns = () => ({
    type: REQUEST_UPDATE_CAMPAIGNS,
});

export const updateCampaignsSuccess = campaigns => ({
    type: UPDATE_CAMPAIGNS_SUCCESS,
    payload: campaigns,
});

export const updateCampaignsError = (ids, error) => ({
    type: UPDATE_CAMPAIGNS_ERROR,
    payload: { ids, error },
});
