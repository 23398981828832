import { get } from 'lodash';

// check string and find value between '${}'
// e.g in string "currency:${accountCurrency}:cpc:min-value" find value '${accountCurrency}'
//  ** RegExp explanation **
// \$ - Literal $
// { - Literal {
//     ([^}]+) First (and only) capture group:
//     [^}]+ - One or more characters which are not }
// } - Literal }
const regExp = /\${([^}]+)}/g;

const configKeyTransformer = (template, configKeyData) =>
    template.replace(regExp, (match, prop) => get(configKeyData, prop, match));

export default configKeyTransformer;
