import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import encodeurl from 'encodeurl';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import getChangesObject from 'modules/taboola-common-frontend-modules/utils/objectDiff';
import { useMultiLayerPathParams } from '../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { CAMPAIGNS_ROUTE } from '../../../config/routes';
import { REPORT_ID } from '../../campaigns-reports/hooks';
import { transformVideoCreativeToGW } from '../../video-creative-creator/hooks/videoCreativeTransformers';
import { fetchCreativeForEdit, updateCreative } from '../flows';

export const useVideoCreativeEditorState = ({ reportType }) => {
    const dispatch = useDispatch();
    const { creativeId, campaignId } = useMultiLayerPathParams();
    const {
        formAccount: { accountId },
        fetchedData: originalCreative,
    } = useFormDataContext();

    const formDataFetcher = useCallback(() => {
        return dispatch(fetchCreativeForEdit(accountId, campaignId, creativeId));
    }, [accountId, campaignId, creativeId, dispatch]);

    const { submit } = useFormState({ formDataFetcher });

    const handleCreativeSubmitWrapperNew = useCallback(() => {
        const saveCreativeWrapper = creative => {
            const getCreativeChanges = () => {
                const creativeWithNormalizedPixelChanges = {
                    ...transformVideoCreativeToGW(creative),
                };
                const changes = getChangesObject(creativeWithNormalizedPixelChanges, originalCreative);
                const { url, ...restChanges } = changes;
                const urlObj = url ? { url: encodeurl(url) } : {};

                return { ...restChanges, ...urlObj };
            };

            return dispatch(
                updateCreative({
                    accountName: accountId,
                    creativeId,
                    campaignId,
                    creativeChanges: getCreativeChanges(),
                })
            );
        };
        return submit(saveCreativeWrapper, `${CAMPAIGNS_ROUTE}?${REPORT_ID}=${reportType}`);
    }, [submit, reportType, dispatch, accountId, creativeId, campaignId, originalCreative]);

    return {
        submit: handleCreativeSubmitWrapperNew,
    };
};
