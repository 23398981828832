import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { InventoryIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks/usePermissions';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../components/Form';
import { FORM_MODES } from '../../../../config';
import BrandSafety from '../../../common-campaign-form/components/BrandSafety';
import PremiumSite from '../../../common-campaign-form/components/PremiumSite';
import PublishersDomains from '../../../common-campaign-form/components/PublishersDomains/PublishersDomains';
import { SupplyTargetingSelectField } from '../../../common-campaign-form/components/SupplyTargeting/components/SupplyTargetingSelectField/SupplyTargetingSelectField';
import ThirdPartyBrandSafety from '../../../common-campaign-form/components/ThirdPartyBrandSafety';
import UrlKeywords from '../../../common-campaign-form/components/UrlKeywords/UrlKeywords';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import { AdUnitTypeField } from '../AdUnitType/AdUnitTypeField';
import { AppsTargetingField } from '../AppsTargeting/AppsTargetingField';
import { PreferredViewabilityRate } from '../PreferredViewabilityRate/PreferredViewabilityRate';
import CustomPresets from '../Preset/CustomPresets';
import styles from './inventorySection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const InventorySection = ({ account }) => {
    const { mode } = useFormDataContext();
    const isUserPermittedView = usePermissions('VIDEO_CAMPAIGN_VIEW_PERMISSION');
    const isUserPermittedEdit = usePermissions('VIDEO_CAMPAIGN_EDIT_PERMISSION');
    const isEditOrDuplicateForm = mode === FORM_MODES.EDIT || mode === FORM_MODES.DUPLICATE;
    const containerClassName = classNameBuilder('container', { 'create-form': !isEditOrDuplicateForm });
    const { value: selectedBusinessModel } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });
    const isTaboolaSelectEnabled = useConfigMatch({ [FEATURE_FLAGS.TABOOLA_SELECT_ENABLED_FOR_VIDEO_ACCOUNT]: 'true' });
    const cpcvOrVcpmEditMode =
        mode === FORM_MODES.EDIT &&
        (selectedBusinessModel === VIDEO_PRICING_MODEL.CPCV || selectedBusinessModel === VIDEO_PRICING_MODEL.VCPM);

    return (
        <div className={containerClassName}>
            <CommonCollapsibleCard
                id="VIDEO_CAMPAIGNS_INVENTORY"
                header={
                    <SectionHeader
                        headerIcon={<InventoryIcon />}
                        headerText={
                            <FormattedMessage
                                id="video.campaign.inventory.section"
                                defaultMessage="Campaign Inventory"
                            />
                        }
                    />
                }
            >
                {isTaboolaSelectEnabled && <SupplyTargetingSelectField />}
                <ThirdPartyBrandSafety />
                {isUserPermittedView && isUserPermittedEdit ? (
                    <>
                        <PremiumSite permissions="ADS_CONSOLE_VIDEO_PREMIUM_SITE" />
                        <BrandSafety />
                    </>
                ) : (
                    <></>
                )}
                <PublishersDomains />
                <AppsTargetingField />
                <UrlKeywords />
                {!cpcvOrVcpmEditMode && <PreferredViewabilityRate account={account} />}
                {isUserPermittedView && isUserPermittedEdit ? (
                    <>
                        <CustomPresets />
                    </>
                ) : (
                    <></>
                )}
                <AdUnitTypeField />
            </CommonCollapsibleCard>
        </div>
    );
};

InventorySection.propTypes = {
    account: PropTypes.object,
};

export default InventorySection;
