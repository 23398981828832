import { isNil } from 'lodash';

const isDropdownObject = object => !(isNil(object.label) || isNil(object.value));

const namedIdedObjectToDropdownObjectTransformer = campaign =>
    !campaign || isDropdownObject(campaign)
        ? campaign
        : {
              label: campaign.name,
              value: campaign.id,
              status: campaign.status,
              id: campaign.id,
              name: campaign.name,
              campaignItemType: campaign.campaignItemType,
          };

export const resourceTransformer = list => list.map(({ name: value, value: label }) => ({ value, label }));

export const enumTransformer = enumObj => Object.keys(enumObj).map(value => ({ value, label: enumObj[value] }));

export const itemTransformer = (item, msgIdPrefix, formatMessage) => ({
    ...item,
    label: formatMessage({
        id: !isNil(item.value) ? `${msgIdPrefix}.${item.value}` : 'empty.message',
        defaultMessage: item.label,
        values: item.values,
    }),
});

export const nameToLabel = ({ name: label, value }) => ({ value, label });

export const nameToLabelById = ({ id, name: label }) => ({ id, label });

export default namedIdedObjectToDropdownObjectTransformer;
