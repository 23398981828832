'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _styleUtils = require('../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'defaultBackgroundColor': 'var(--blue-7)',
    'defaultTextColor': 'var(--white)',
    'taboola-svg-icon': 'appHeader__taboola-svg-icon___OJdpn',
    'header-container': 'appHeader__header-container___yIVuN',
    'sticky': 'appHeader__sticky___1NHQH',
    'branding-container': 'appHeader__branding-container___3saNC',
    'content': 'appHeader__content___3SBOS',
    'branding-item': 'appHeader__branding-item___1OGjq'
};


var FULL_WIDTH_PERCENTAGE = 100;

var classNameBuilder = _bind2.default.bind(styles);

var AppHeader = function AppHeader(props) {
    var children = props.children,
        logoComponent = props.logoComponent,
        appNameComponent = props.appNameComponent,
        contentClassName = props.contentClassName,
        className = props.className,
        height = props.height,
        backgroundColor = props.backgroundColor,
        textColor = props.textColor,
        brandingWidth = props.brandingWidth,
        sticky = props.sticky,
        zIndex = props.zIndex,
        brandingClass = props.brandingClass;

    var editableStyles = {
        container: {
            height: (0, _styleUtils2.default)(height),
            backgroundColor: backgroundColor,
            color: textColor,
            zIndex: zIndex
        },
        branding: {
            flexGrow: brandingWidth
        },
        content: {
            flexGrow: FULL_WIDTH_PERCENTAGE - brandingWidth
        }
    };
    return _react2.default.createElement(
        'div',
        {
            className: classNameBuilder(styles['header-container'], sticky ? styles['sticky'] : '', className),
            style: editableStyles.container
        },
        _react2.default.createElement(
            'div',
            { className: classNameBuilder(styles['branding-container'], brandingClass), style: editableStyles.branding },
            _react2.default.createElement(
                'div',
                { className: styles['branding-item'] },
                logoComponent
            ),
            _react2.default.createElement(
                'div',
                { className: styles['branding-item'] },
                appNameComponent
            )
        ),
        _react2.default.createElement(
            'div',
            { className: styles['content'] + ' ' + contentClassName, style: editableStyles.content },
            children
        )
    );
};

AppHeader.propTypes = {
    /** The content of the header */
    children: _propTypes2.default.node,
    /** The component to use for the App Logo section */
    logoComponent: _propTypes2.default.node,
    /** The component to use for the App Name section */
    appNameComponent: _propTypes2.default.node,
    /** The class name of the content section */
    contentClassName: _propTypes2.default.string,
    className: _propTypes2.default.string,
    /** The height of the header */
    height: _propTypes2.default.number,
    /** The background color of the header */
    backgroundColor: _propTypes2.default.string,
    /** The color of text in the header */
    textColor: _propTypes2.default.string,
    /** The width of the Branding section (percentage). i.e width=10 for 10% of screen */
    brandingWidth: _propTypes2.default.number,
    /** Determines if to activate sticky behavior of the header - NO IE SUPPORT */
    sticky: _propTypes2.default.bool,
    /** The z-index of the header */
    zIndex: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    brandingClass: _propTypes2.default.string
};

AppHeader.defaultProps = {
    // TODO: replace with icon
    logoComponent: _react2.default.createElement(
        'span',
        null,
        'LOGO'
    ),
    height: 65,
    backgroundColor: styles.defaultBackgroundColor,
    textColor: styles.defaultTextColor,
    contentClassName: '',
    className: '',
    brandingWidth: 20,
    sticky: false,
    zIndex: 'auto',
    brandingClass: ''
};

exports.default = AppHeader;