'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'simpleUserMenu__taboola-svg-icon___3YmR1',
    'separator': 'simpleUserMenu__separator___1JZKX'
};


var SimpleUserMenu = function SimpleUserMenu(props) {
    var userName = props.userName,
        onLogoutClick = props.onLogoutClick;

    return _react2.default.createElement(
        'div',
        { className: styles['user-menu-container'] },
        _react2.default.createElement(
            'span',
            null,
            'Hello',
            userName
        ),
        _react2.default.createElement(
            'span',
            { className: styles['separator'] },
            '|'
        ),
        _react2.default.createElement(
            'span',
            { className: styles['logout'], onClick: onLogoutClick, role: 'button', tabIndex: 0 },
            'Sign Out'
        )
    );
};

SimpleUserMenu.propTypes = {
    userName: _propTypes2.default.string,
    onLogoutClick: _propTypes2.default.func
};

SimpleUserMenu.defaultProps = {
    onLogoutClick: function onLogoutClick() {}
};

exports.default = SimpleUserMenu;