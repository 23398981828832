import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

export const ReachFiltersTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.size.estimation.reach.filters.tooltip.title"
                defaultMessage="Choose your target location and platform to see an estimate of your potential reach."
            />
        </TooltipSection>
    </>
);
