import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const BidModifierTooltip = ({ wrap, children }) => (
    <TooltipWrapper
        wrap={wrap}
        tooltipContent={
            <FormattedMessage
                id="campaign.editor.bid.adjust.disabled"
                defaultMessage="Bid adjustments are not available when selecting Maximize Conversions Bidding Strategy or vCPM Bid Type"
            />
        }
        tooltipOptions={{ arrow: true, position: TOOLTIP_POSITION.BOTTOM_START }}
    >
        {children}
    </TooltipWrapper>
);
