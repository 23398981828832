import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import useFormFieldValue from '../../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../../config';
import { campaignInitialValues } from '../../../config';
import AD_TYPES from '../../../config/adTypes';
import CATEGORIES from '../../../config/categories';
import CONTENT_SAFTIES from '../../../config/contentSafties';
import CONTENT_TYPES from '../../../config/contentTypes';
import AdsTooltip from './AdsTooltip';
import styles from './adsSetting.module.scss';

const AdsSetting = ({ checked }) => {
    const { value: contentType, onChange: onChangeContentType } = useFormFieldValue({
        field: 'campaignProfile.contentType',
    });
    const { value: adType, onChange: onChangeAdType } = useFormFieldValue({
        field: 'campaignProfile.adType',
    });
    const { value: contentSafety, onChange: onChangeContentSafety } = useFormFieldValue({
        field: 'campaignProfile.contentSafety',
    });
    const { value: language, onChange: onChangeLanguage } = useFormFieldValue({
        field: 'campaignProfile.language',
    });
    const { value: category, onChange: onChangeCategory } = useFormFieldValue({
        field: 'campaignProfile.category',
    });

    const originalContentType = useRef(contentType);
    const originalAdType = useRef(adType);
    const originalContentSafety = useRef(contentSafety);
    const originalLanguage = useRef(language);
    const originalCategory = useRef(category);

    useEffect(() => {
        if (checked) {
            onChangeAdType(originalAdType.current);
            onChangeContentType(originalContentType.current);
            onChangeCategory(originalCategory.current);
            onChangeContentSafety(originalContentSafety.current);
            onChangeLanguage(originalLanguage.current);
            return;
        }
        onChangeAdType(AD_TYPES.PUBLISHER);
        onChangeContentType(CONTENT_TYPES.VIDEO);
        onChangeCategory(CATEGORIES.NONE);
        onChangeContentSafety(CONTENT_SAFTIES.LOW_BROW);
        onChangeLanguage(campaignInitialValues.campaignProfile.language);
    }, [onChangeAdType, onChangeCategory, onChangeLanguage, onChangeContentSafety, onChangeContentType, checked]);

    return (
        <TextCheckbox
            helpText={<AdsTooltip />}
            value={DUPLICATE_CAMPAIGN_SETTINGS_TYPE.ADS}
            title={<FormattedMessage id="campaign.editor.duplicateCampaignSettings.ads" defaultMessage="Ads" />}
            labelClassName={styles['label']}
        />
    );
};

AdsSetting.propTypes = {
    checked: PropTypes.bool,
};

export default AdsSetting;
