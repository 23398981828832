'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Buttons = require('../../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Created by oreuveni
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Date: 09/08/2018
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Time: 15:24
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */


var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'paginationBar__taboola-svg-icon___aozjP',
    'grid-pagination': 'paginationBar__grid-pagination___28NlP',
    'grid-pagination-buttons': 'paginationBar__grid-pagination-buttons___IAsxo',
    'grid-pagination-row-numbers': 'paginationBar__grid-pagination-row-numbers___3jx7W',
    'pagination-number': 'paginationBar__pagination-number___2od7e',
    'of': 'paginationBar__of___38sjh'
};

var PaginationBar = function (_Component) {
    _inherits(PaginationBar, _Component);

    function PaginationBar() {
        _classCallCheck(this, PaginationBar);

        return _possibleConstructorReturn(this, (PaginationBar.__proto__ || Object.getPrototypeOf(PaginationBar)).apply(this, arguments));
    }

    _createClass(PaginationBar, [{
        key: 'getCurrentRowsStart',
        value: function getCurrentRowsStart(currentPage, rowsPerPage) {
            return currentPage * rowsPerPage - (rowsPerPage - 1);
        }
    }, {
        key: 'getCurrentRowsEnd',
        value: function getCurrentRowsEnd(currentPage, rowsPerPage, totalRows) {
            var currentRowsEnd = currentPage * rowsPerPage;
            return currentRowsEnd > totalRows ? totalRows : currentRowsEnd;
        }
    }, {
        key: 'render',
        value: function render() {
            var _props = this.props,
                children = _props.children,
                className = _props.className,
                currentPage = _props.currentPage,
                totalRows = _props.totalRows,
                rowsPerPage = _props.rowsPerPage,
                totalPages = _props.totalPages,
                goToPreviousPage = _props.goToPreviousPage,
                goToNextPage = _props.goToNextPage,
                goToFirstPage = _props.goToFirstPage,
                goToLastPage = _props.goToLastPage,
                ofText = _props.ofText;

            var actualCurrentPage = currentPage + 1;
            var nextDisabled = actualCurrentPage === totalPages;
            var previousDisabled = currentPage === 0;
            return _react2.default.createElement(
                'div',
                { className: className + ' ' + styles['grid-pagination'] },
                children,
                _react2.default.createElement(
                    'div',
                    { className: styles['grid-pagination-row-numbers'] },
                    _react2.default.createElement(
                        'span',
                        { className: styles['pagination-number'] },
                        this.getCurrentRowsStart(actualCurrentPage, rowsPerPage),
                        ' ',
                        '-',
                        ' ',
                        this.getCurrentRowsEnd(actualCurrentPage, rowsPerPage, totalRows)
                    ),
                    _react2.default.createElement(
                        'span',
                        { className: styles['pagination-number'] + ' ' + styles['of'] + ' ' },
                        ofText
                    ),
                    _react2.default.createElement(
                        'span',
                        { className: styles['pagination-number'] },
                        totalRows
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: styles['grid-pagination-buttons'] },
                    _react2.default.createElement(_Buttons.StyledButton, {
                        disabled: previousDisabled,
                        className: styles['arrow-button'],
                        onClick: goToFirstPage,
                        iconBefore: 'contract',
                        size: 'small',
                        type: 'borderless-icon'
                    }),
                    _react2.default.createElement(_Buttons.StyledButton, {
                        disabled: previousDisabled,
                        className: styles['arrow-button'],
                        onClick: goToPreviousPage,
                        iconBefore: 'left',
                        size: 'small',
                        type: 'borderless-icon'
                    }),
                    '|',
                    _react2.default.createElement(_Buttons.StyledButton, {
                        disabled: nextDisabled,
                        className: styles['arrow-button'],
                        onClick: goToNextPage,
                        iconBefore: 'right',
                        size: 'small',
                        type: 'borderless-icon'
                    }),
                    _react2.default.createElement(_Buttons.StyledButton, {
                        disabled: nextDisabled,
                        className: styles['arrow-button'],
                        onClick: goToLastPage,
                        iconBefore: 'expand',
                        size: 'small',
                        type: 'borderless-icon'
                    })
                )
            );
        }
    }]);

    return PaginationBar;
}(_react.Component);

PaginationBar.propTypes = {
    /** Additional components to potentially render */
    children: _propTypes2.default.node,
    /** Class name to be added to the component main element */
    className: _propTypes2.default.string,
    /** Replace the "of" text - i18n */
    ofText: _propTypes2.default.node,
    /** Value of current page */
    currentPage: _propTypes2.default.number,
    /** Total no. of pages */
    totalPages: _propTypes2.default.number,
    /** Total no. of rows */
    totalRows: _propTypes2.default.number,
    /** No. of rows per page */
    rowsPerPage: _propTypes2.default.number,
    /** Fires when nextPage button is clicked - should navigate to next page */
    goToPreviousPage: _propTypes2.default.func,
    /** Fires when previousPage button is clicked - should navigate to previous page */
    goToNextPage: _propTypes2.default.func,
    /** Fires when firstPage button is clicked - should navigate to the first page */
    goToFirstPage: _propTypes2.default.func,
    /** Fires when lastPage button is clicked - should navigate to the last page */
    goToLastPage: _propTypes2.default.func
};

PaginationBar.defaultProps = {
    className: '',
    ofText: 'of'
};

exports.default = PaginationBar;