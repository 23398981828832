import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useScheduledReportsApi } from '../../../services/api/scheduledReportApi';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useFormState } from '../../taboola-common-frontend-modules/forms';
import { REPORT_SCHEDULE_ROUTE } from '../config';
import { fetchScheduledReport } from '../flows/fetchScheduledReport';
import { updateScheduledReports } from '../flows/updateScheduledReports';
import { transformScheduledReportToGw } from '../transformers/scheduledReportsToGWTransformer';

export const useScheduledReportsEditorState = () => {
    const { scheduledReportId, accountId } = useMultiLayerPathParams();
    const { getScheduledReportData } = useScheduledReportsApi();
    const dispatch = useDispatch();

    const formDataFetcher = useCallback(
        () => dispatch(fetchScheduledReport(accountId, scheduledReportId, getScheduledReportData)),
        [accountId, dispatch, scheduledReportId, getScheduledReportData]
    );
    const { submit } = useFormState({ formDataFetcher });

    const submitAndExecuteWrapper = useCallback(() => {
        const idWrapper = { id: scheduledReportId };
        const saveScheduledReportWrapper = report => {
            const transformedReport = transformScheduledReportToGw(report);
            return dispatch(
                updateScheduledReports(transformedReport, idWrapper, '', '', '', { accountName: accountId }, true)
            );
        };
        submit(saveScheduledReportWrapper, REPORT_SCHEDULE_ROUTE);
    }, [scheduledReportId, accountId, submit, dispatch]);

    const handleScheduledReportsSubmitWrapper = useCallback(() => {
        const idWrapper = { id: scheduledReportId };
        const saveScheduledReportWrapper = report => {
            const transformedReport = transformScheduledReportToGw(report);
            return dispatch(
                updateScheduledReports(transformedReport, idWrapper, '', '', '', { accountName: accountId })
            );
        };
        submit(saveScheduledReportWrapper, REPORT_SCHEDULE_ROUTE);
    }, [scheduledReportId, accountId, submit, dispatch]);

    return { submit: handleScheduledReportsSubmitWrapper, submitAndExecuteWrapper };
};
