import { DASHBOARD_RANGE_PICKER_PRESETS } from 'taboola-ultimate-ui';
import HOURS_OF_DAY from '../../../campaigns/modules/common-campaign-form/config/hoursOfDay';
import { ALL_CAMPAIGN_GROUPS } from '../../components/ScheduledReportsCampaignGroupsListTypeFiled/scheduledReportsCampaignGroupsListTypes';
import { ALL_CAMPAIGNS } from '../../components/ScheduledReportsCampaignsListFiled/scheduledReportsCampaignListTypes';
import { CONDITION_DIMENSIONS } from '../../components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';
import { FILE_TYPE_CONDITION } from '../../components/ScheduledReportsFileTypeField/scheduledReportFileTypeFieldOptions';
import { FREQUENCY_CONDITIONS } from '../../components/ScheduledReportsFrequencyField/scheduledReportFrequencyFieldOptions';

export const scheduledReportsInitialValues = {
    isActive: true,
    campaigns: [],
    campaignGroupList: [],
    performanceRuleIds: [],
    accountId: ({ id }) => id,
    campaignSelectType: ALL_CAMPAIGNS,
    campaignGroupsSelectType: ALL_CAMPAIGN_GROUPS,
    dimension: CONDITION_DIMENSIONS.CAMPAIGN_BY_DAY,
    dateRange: DASHBOARD_RANGE_PICKER_PRESETS.YESTERDAY,
    frequency: FREQUENCY_CONDITIONS.DAILY,
    fileType: FILE_TYPE_CONDITION.CSV,
    sentTo: [],
    reportName: ({ reportDefaultName }) => `${reportDefaultName}`,
    deliveryTime: HOURS_OF_DAY.SEVEN,
    includeConversionsRules: false,
};
