import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { AUDIENCE_STATUS } from '../../../../../../audiences/config/audiencesConsts';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const pixelBasedReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.PIXEL_BASED],
    endpoint: 'pixel-based-audience-report',
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    isEmptyFooter: true,
    searchPlaceholderMsgId: 'app.reports.pixelBased.search.placeholder',

    // TODO: DEV-58584 need to convert filter names in the GW. replace sortField + remove sortMap
    // searchField: NAME.field
    searchField: FIELDS.AUDIENCE_NAME.field,
    sortMap: {
        [FIELDS.NAME.field]: FIELDS.AUDIENCE_NAME.field,
    },
    initialFilters: [
        {
            id: FILTER_TYPE.AUDIENCE_STATUS,
            type: FILTER_TYPE.AUDIENCE_STATUS,
            values: [
                {
                    value: AUDIENCE_STATUS.ALL_BUT_ARCHIVED,
                    label: 'All but archived',
                    messageId: 'report.audienceStatus.ALL_BUT_ARCHIVED',
                },
            ],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
    ],
    filters: [{ id: FILTER_TYPE.AUDIENCE_STATUS }],
};

export default pixelBasedReportConfig;
