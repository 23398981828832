const copyToClipboardLegacy = content => {
    // browser does not have clipboard access
    const input = document.createElement('textarea');
    input.value = content;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    input.remove();
};

export const copyToClipboard = async content => {
    try {
        await navigator.clipboard.writeText(content);
    } catch (err) {
        // browser does not have clipboard access
        copyToClipboardLegacy(content);
    }
};

export const readFromClipboard = async () => {
    try {
        const clipboard = await navigator.clipboard.readText();
        return clipboard;
    } catch (err) {
        return '';
    }
};

export default copyToClipboard;
