import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { BareDropdown, PaginatedDropdown } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import CONVERSION_RULE_MODES from '../../config/ConversionRuleModes';
import { ConversionItemDropdownOption } from './ConversionItemDropdownOption/ConversionItemDropdownOption';
import { useCampaignLevelConversions } from './hook/useCampaignLevelConversions';
import { useSelectedRuleDropdownValue } from './hook/useSelectedRuleDropdownValue';
import styles from './conversionOptimization.module.scss';

export const ConversionOptimizationDropDown = ({
    conversionRules,
    onChangeConversionRules,
    setHasOptions,
    showAccountDefault,
}) => {
    const { formatMessage } = useIntl();

    const { value: conversionRulesMode } = useFormFieldValue({ field: 'conversionRulesMode' });
    const { options, loadConversionRules, setCurrentOptions } = useCampaignLevelConversions();

    const handleSelectConversionRule = useCallback(
        selectedConversionRule => {
            const { id, displayName, status, conversions } = selectedConversionRule;
            onChangeConversionRules([
                {
                    id,
                    displayName,
                    status,
                    conversions,
                },
            ]);
        },
        [onChangeConversionRules]
    );

    const dropdownValue = useSelectedRuleDropdownValue({ options, conversionRules });

    useEffect(() => {
        setHasOptions(prev => prev || !isEmpty(options));
    }, [options, setHasOptions]);

    return (
        <div className={styles['dropdown-container']}>
            <PaginatedDropdown
                id="rule-selector"
                onChange={handleSelectConversionRule}
                searchable
                {...dropdownValue}
                disabled={conversionRulesMode !== CONVERSION_RULE_MODES.CUSTOM && showAccountDefault}
                enabledWhileSearching
                options={options}
                loadPage={loadConversionRules}
                onOptionsLoaded={setCurrentOptions}
                placeholder={formatMessage({
                    id: 'campaign.editor.conversion.goal.placeholder',
                    defaultMessage: 'Select Conversion...',
                })}
                DropdownMenuComponent={BareDropdown}
                optionItemRenderer={ConversionItemDropdownOption}
                valueRenderer={undefined}
            />
        </div>
    );
};
