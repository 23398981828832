import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Collapsible } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { CarouselCardEdit } from '../CarouselCardEdit/CarouselCardEdit';
import { CarouselCardPreview } from '../CarouserlPreview/CarouselCardPreview';
import styles from './CarouselCard.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CarouselCard = props => {
    const [isHovered, setIsHovered] = useState(false);
    const { mode } = useFormDataContext();
    const [isCollapsed, setIsCollapsed] = useState(mode !== FORM_MODES.CREATE);
    const toggleCollapsed = () => setIsCollapsed(prev => !prev);

    return (
        <Collapsible collapsed={isCollapsed} collapsedSize={92} className={classNameBuilder('panel-vertical-visible')}>
            <div
                className={styles['inner']}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {isCollapsed && (
                    <CarouselCardPreview toggleCollapsed={toggleCollapsed} isHovered={isHovered} {...props} />
                )}
                <CarouselCardEdit toggleCollapsed={toggleCollapsed} collapsed={isCollapsed} {...props} />
            </div>
        </Collapsible>
    );
};
