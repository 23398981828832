import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { useAvailableItems } from 'hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccountFullConfigData } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountFullConfigData';
import PermissionsContext from '../../../../taboola-common-frontend-modules/authentication/PermissionsContext';
import { useSelectedCampaign } from '../../../hooks/useSelectedCampaign';
import { columnDefsStateSelector } from '../../../selectors';
import { getColumnDefsForConfigPreset, getCustomPresetColumns, getRequiredCustomColumns } from '../services';
import { isCustomReportPreset } from '../utils';
import { useReportComfortableViewParam } from './useReportComfortableViewParam';

const mapWithId = ({ field, ...rest }) => ({ id: field, field, ...rest });
const emptyColumnsDef = [];

export const useColumnDefs = (reportConfig, reportPresetName) => {
    const columnDefsToFilter = useSelector(columnDefsStateSelector);
    const columnDefsByView = useMemo(() => {
        if (!isCustomReportPreset(reportPresetName)) {
            return getColumnDefsForConfigPreset(columnDefsToFilter, reportConfig, reportPresetName);
        }

        const configCustomColumnsSet = new Set(getCustomPresetColumns(reportConfig));
        const requiredSet = new Set(getRequiredCustomColumns(reportConfig));
        return columnDefsToFilter.filter(
            ({ colId, referenceColId, isDynamicField }) =>
                requiredSet.has(colId) || configCustomColumnsSet.has(colId) || isDynamicField || referenceColId
        );
    }, [columnDefsToFilter, reportPresetName, reportConfig]);

    const userPermissions = useContext(PermissionsContext);
    const selectedAccountConfig = useAccountFullConfigData()?.selectedAccountConfig;
    const { campaign: selectedCampaign } = useSelectedCampaign();
    const selectedAccount = useAccount();
    const [isComfortableViewEnabled] = useReportComfortableViewParam();
    const columnDefsConditionData = {
        selectedAccount,
        selectedAccountConfig,
        selectedCampaign,
        userPermissions,
        isComfortableViewEnabled,
    };
    const allConfigColumnDefs = reportConfig.columnsDef || emptyColumnsDef;
    const allConfigColumnDefsWithIds = useMemo(() => allConfigColumnDefs.map(mapWithId), [allConfigColumnDefs]);
    const columnDefsWithIds = useMemo(() => columnDefsByView.map(mapWithId), [columnDefsByView]);
    const allowedSortingColumnDefs = useAvailableItems(allConfigColumnDefsWithIds, columnDefsConditionData);
    const columnDefs = useAvailableItems(columnDefsWithIds, columnDefsConditionData);

    const filterMap = keyBy(columnDefs, 'colId');
    const footerColumnDefs = reportConfig.footerColumnDef
        ? reportConfig.footerColumnDef.filter(({ colId }) => filterMap[colId])
        : undefined;

    return { columnDefs, footerColumnDefs, allowedSortingColumnDefs, allPresetsColumnDefs: columnDefsToFilter };
};
