"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCampaign(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M7.651 16.001l.008.032c.193.73-.231 1.48-.942 1.676-.713.197-1.444-.237-1.635-.967l-1.036-3.968c-.191-.731.232-1.482.943-1.678a1.32 1.32 0 0 1 1.459.567l9.24-6.48c.74-.4 1.537-.123 1.736.644l2.535 9.707c.2.765-.354 1.415-1.19 1.45l-2.279-.357c.133 1.316-.671 2.572-1.956 2.925l-1.288.354c-1.424.391-2.888-.476-3.27-1.937l-.48-1.837-1.845-.13zm6.54 2.228c.71-.196 1.132-.947.942-1.678l-.006-.02-4.222-.3.36 1.384c.191.73.923 1.164 1.635.969l1.29-.355z"
            })
        )
    );
}
exports.default = SvgCampaign;