import { uniq, uniqBy, transform, omitBy, isEmpty, set } from 'lodash';
import uuid from 'uuid/v1';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { ENTITY } from '../../../campaigns/config';
import { fromGWtransformers } from '../../../campaigns/flows/flowsUtils';
import { IMAGE, IMAGES } from '../../../campaigns/modules/creative-creator/config';
import { creativeInitialValues } from '../../../campaigns/modules/creative-creator/config/creativeInitialValues';
import { AI_VARIATIONS_FIELD_NAMES } from '../../../campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { GALLERY_ITEM_SOURCES } from '../../../campaigns/modules/creative-editor/components/MediaTabs';
import { ENTITY_EVENT_DATA_PATH_MAP } from '../../config/entityEventDataPathMap';
import { ONBOARDING_ENTITY } from '../../config/onboardingEntity';

const campaignTransformer = fromGWtransformers[ENTITY.CAMPAIGN];

const cleanNulls = obj => omitBy(obj, value => value === null);
const creativeTransformer = creatives => {
    const result = transform(
        creatives,
        (acc, { url, title, thumbnailUrl, description, cta }) => {
            acc.urls.push({ id: uuid(), value: url, status: COMPONENT_STATUS.ACTIVE });
            acc[AI_VARIATIONS_FIELD_NAMES.CONTENT].push({ title, status: COMPONENT_STATUS.ACTIVE });
            acc[AI_VARIATIONS_FIELD_NAMES.TITLES].push(title);
            acc[AI_VARIATIONS_FIELD_NAMES.DESCRIPTION] = description;
            acc[AI_VARIATIONS_FIELD_NAMES.CTA] = cta?.ctaType;
            acc.thumbnails.push({
                displayUrl: thumbnailUrl,
                fileType: IMAGE,
                id: thumbnailUrl,
                mediaCategory: IMAGES,
                mediaUploadSource: GALLERY_ITEM_SOURCES.MEDIA_LIBRARY,
                selected: false,
                status: COMPONENT_STATUS.ACTIVE,
                value: thumbnailUrl,
            });
        },
        {
            urls: [],
            [AI_VARIATIONS_FIELD_NAMES.CONTENT]: [],
            [AI_VARIATIONS_FIELD_NAMES.TITLES]: [],
            thumbnails: [],
        }
    );

    result.urls = uniqBy(result.urls, 'value') ?? creativeInitialValues.urls;
    result[AI_VARIATIONS_FIELD_NAMES.CONTENT] = uniqBy(result[AI_VARIATIONS_FIELD_NAMES.CONTENT], 'title');
    result[AI_VARIATIONS_FIELD_NAMES.TITLES] = uniq(result[AI_VARIATIONS_FIELD_NAMES.TITLES]);
    result.thumbnails = uniqBy(result.thumbnails, 'id');

    if (isEmpty(result.urls)) {
        result.urls = creativeInitialValues.urls;
        result[AI_VARIATIONS_FIELD_NAMES.CONTENT] = creativeInitialValues[AI_VARIATIONS_FIELD_NAMES.CONTENT];
        result[AI_VARIATIONS_FIELD_NAMES.TITLES] = creativeInitialValues[AI_VARIATIONS_FIELD_NAMES.TITLES];
    } else {
        result[AI_VARIATIONS_FIELD_NAMES.CONTENT].push({ title: '', status: COMPONENT_STATUS.EMPTY });
        result[AI_VARIATIONS_FIELD_NAMES.TITLES].push('');
    }

    return result;
};
export const transformRecommendedDataToForm = data => {
    const {
        [ENTITY_EVENT_DATA_PATH_MAP[ONBOARDING_ENTITY.ONBOARDING_CHAT]]: {
            mediaPlan: rawMediaPlan,
            campaigns,
            creatives: rawCreatives,
        } = {},
    } = data;
    const campaignList = (campaigns || []).map(campaign => {
        const transformedCampaign = cleanNulls({ generated: true, ...campaignTransformer(campaign) });
        set(transformedCampaign, 'campaignTargeting.publisherTargeting', null);
        set(transformedCampaign, 'campaignTargeting.cityCountryTargeting', null);

        return transformedCampaign;
    });
    const campaignMap = campaignList.reduce(
        (acc, campaign, currentIndex) => ({ ...acc, [`campaign_${currentIndex + 1}`]: campaign }),
        {}
    );
    const creative = cleanNulls(creativeTransformer(rawCreatives || []));
    const mediaPlan = cleanNulls(rawMediaPlan);

    if (mediaPlan?.budget) {
        mediaPlan.budget = mediaPlan.budget.map(cleanNulls);
    }

    return { mediaPlan, campaignMap, creative };
};
