import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';

const reportId = reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_DATE.field,
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.field,
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.field,
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME.field,
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY.field,
        FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_DATE.field,
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME.field,
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME.field,
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME.field,
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY.field,
    FIELDS.PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
