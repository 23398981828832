import { useCallback } from 'react';
import { get as lodashGet } from 'lodash';
import { useOnboardingAccountDataContext } from '../contextProvider/onboardingAccountDataContext';

export const useOnboardingLocalStorage = ({ key, defaultValue }) => {
    const { onboardingAccountData, setAccountLocalStorage } = useOnboardingAccountDataContext();
    const onboardingAccountDataValue = lodashGet(onboardingAccountData, key, defaultValue);

    const set = useCallback(
        value => {
            setAccountLocalStorage({ ...onboardingAccountData, [key]: value });
        },
        [key, onboardingAccountData, setAccountLocalStorage]
    );

    const remove = useCallback(() => {
        if (!onboardingAccountDataValue) {
            return;
        }

        const { [key]: removedValue, ...restAccountData } = onboardingAccountData;
        setAccountLocalStorage(restAccountData);
    }, [onboardingAccountDataValue, onboardingAccountData, key, setAccountLocalStorage]);

    return [onboardingAccountDataValue, set, remove];
};
