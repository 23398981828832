'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var DOM_LAYOUTS = {
    AUTO_HEIGHT: 'autoHeight',
    NORMAL: 'normal'
};

exports.default = DOM_LAYOUTS;