import _ from 'lodash';
import { CONDITION_DIMENSIONS } from 'modules/performance-rules/components/PerformanceRuleSetupSection/PerformanceRulesConditionAndActionSection/DimensionDropdown/DimensionDropdown';
import { ENTITIES_TYPES } from 'modules/performance-rules/components/PerformanceRuleSetupSection/entitiesRuleTypes';
import { getInitialConditionsByTemplate } from 'modules/performance-rules/config/initialValues/getInitialConditionsByTemplate';

export const performanceRulesInitialValues = {
    ruleName: '',
    daysToExclude: conditions =>
        _.some(
            conditions,
            condition => _.isNumber(condition.lookbackWindowInDays) && condition.lookbackWindowInDays === 0
        )
            ? 0
            : 1,
    entityType: ENTITIES_TYPES.ADVERTISER,
    advertiser: ({ accountName }) => accountName,
    campaigns: [],
    dimension: CONDITION_DIMENSIONS.SITE_PER_CAMPAIGN,
    templates: ({ defaultTemplate }) => defaultTemplate,
    excludeNewCampaigns: false,
    conditions: ({ template }) => getInitialConditionsByTemplate(template),
    actions: [
        {
            actionType: ({ action }) => action,
        },
    ],
    isEmailNotificationActive: false,
    scheduledReportIds: null,
    createNewScheduledReport: false,
};
