import { createReducer } from '../../taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from '../../taboola-common-frontend-modules/utils/updateObject';
import {
    CLEAR_RECOMMENDATIONS,
    SET_RECOMMENDATIONS,
    FETCH_RECOMMENDATIONS_INDICATION_SUCCESS,
    REQUEST_FETCH_RECOMMENDATIONS_INDICATION,
    CLEAR_RECOMMENDATIONS_INDICATION,
} from '../actions';

const initialState = {
    recommendationType: '',
    recommendationData: null,
    recommendationMetadata: null,
    recommendationId: '',
    recommendationIndicationCount: null,
};

const recommendationsReducer = createReducer(initialState, {
    [SET_RECOMMENDATIONS]: (
        state,
        {
            payload: {
                recommendationData,
                recommendationMetadata,
                recommendationId,
                recommendationType,
                recommendationSource,
            },
        }
    ) =>
        updateObject(state, {
            recommendationType,
            recommendationSource,
            recommendationData,
            recommendationMetadata,
            recommendationId,
        }),
    [CLEAR_RECOMMENDATIONS]: state =>
        updateObject(state, {
            recommendationType: '',
            recommendationData: null,
            recommendationMetadata: null,
            recommendationId: '',
            recommendationSource: null,
        }),
    [CLEAR_RECOMMENDATIONS_INDICATION]: state =>
        updateObject(state, {
            recommendationIndicationCount: null,
        }),
    [REQUEST_FETCH_RECOMMENDATIONS_INDICATION]: state =>
        updateObject(state, {
            recommendationIndicationCount: null,
        }),
    [FETCH_RECOMMENDATIONS_INDICATION_SUCCESS]: (state, { payload }) =>
        updateObject(state, {
            recommendationIndicationCount: payload,
        }),
});

export default recommendationsReducer;
