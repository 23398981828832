import { useEffect } from 'react';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { BID_STRATEGIES } from '../../../config';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import { useCpaGoalFormFieldValue } from '../../CpaGoal/useCpaGoalFormFieldValue';

export const useClearFieldsOnUnselect = () => {
    const { value: bidStrategy } = useBidStrategyFormField();
    const { resetFieldToInitial: resetTargetCpa, isEqualToInitial: isTargetCpaInitial } = useCpaGoalFormFieldValue();
    const { resetFieldToInitial: resetMinConversionGoal, isEqualToInitial: isMinConversionsGoalInitial } =
        useFormFieldValue({
            field: 'minExpectedConversionsForCpaGoal',
        });

    useEffect(() => {
        if (bidStrategy === BID_STRATEGIES.TARGET_CPA) {
            return;
        }

        if (!isTargetCpaInitial) {
            resetTargetCpa();
        }

        if (!isMinConversionsGoalInitial) {
            resetMinConversionGoal();
        }
    }, [bidStrategy, isMinConversionsGoalInitial, isTargetCpaInitial, resetMinConversionGoal, resetTargetCpa]);
};
