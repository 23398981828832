import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router';
import { isEmpty } from 'lodash';
import { CAMPAIGNS_ROUTE, CREATIVE_BULK_DUPLICATE_ROUTE_PATH, FORM_MODES } from 'modules/campaigns/config';
import { replacePathParams } from 'modules/campaigns/services/utils';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CreativeBulkDuplicator } from '../modules/creative-bulk-duplicator/CreativeBulkDuplicator';
import { selectedRowsSelector } from '../selectors';

export const CreativeBulkDuplicatePage = ({ onCancel }) => {
    const selectedRows = useSelector(selectedRowsSelector);

    const sponsoredBulkDuplicatePath = replacePathParams(CREATIVE_BULK_DUPLICATE_ROUTE_PATH, {
        dimension: [CAMPAIGN_DIMENSION.SPONSORED],
        mode: [FORM_MODES.BULK_DUPLICATE],
    });

    useUnsavedChangesController(CREATIVE_BULK_DUPLICATE_ROUTE_PATH);
    //Redirect in the event someone copy pastes the URL into the browser
    const isCreativeDuplicatorAccessible = !isEmpty(selectedRows);

    return (
        <Switch>
            {isCreativeDuplicatorAccessible ? (
                <LayerRoute
                    path={sponsoredBulkDuplicatePath}
                    render={props => (
                        <CreativeBulkDuplicator onCancel={onCancel} sourcePath={CAMPAIGNS_ROUTE} {...props} />
                    )}
                />
            ) : null}
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

export default CreativeBulkDuplicatePage;
