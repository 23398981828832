import { useCallback, useMemo, useState } from 'react';
import JSZip from 'jszip';
import { reject, toPairs, forEach, filter, size, head, every } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';
import { zipObjValidations } from '../uploadFileValidations';

const removeMacOSFiles = zip => {
    forEach(zip.files, (file, fileName) => {
        if (fileName.startsWith('__MACOSX/')) {
            zip.remove(fileName);
        }
    });
};

const flattenSingleFolderZip = async (zip, folderName) => {
    const folderNameLength = folderName.length;
    const filePairs = toPairs(zip.files);
    const nonFolderFilePairs = reject(filePairs, ([fileName]) => fileName === folderName);

    // Remove each file and re-add them without the folder name
    for (let [fileName, file] of nonFolderFilePairs) {
        const content = await file.async('uint8array');
        zip.remove(fileName);

        const filenameWithoutFolder = fileName.substring(folderNameLength);
        zip.file(filenameWithoutFolder, content);
    }

    // Remove the folder
    zip.remove(folderName);
};

const convertZipToFile = async (zip, fileName, lastModified) => {
    const flattenedBlob = await zip.generateAsync({ type: 'blob' });
    return new File([flattenedBlob], fileName, { lastModified, type: 'application/zip' });
};

export const useParsedZipFile = ({ formatMessage, excelWorkbook, allFileNames }) => {
    const [isParsingZip, setIsParsingZip] = useState(false);
    const [zipParsingError, setZipParsingError] = useState(null);

    const { value: zipFile, onChange: setZipFile } = useFormFieldValue({ field: 'mediaFile' });

    const {
        value: zipObj,
        onChange: setZipObj,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'mediaZip',
        validations: zipObjValidations,
        validationDependencies: { excelWorkbook, allFileNames },
    });

    const onSelectZipFile = useCallback(
        async acceptedFiles => {
            const originalZipFile = acceptedFiles[0];
            let selectedFile = originalZipFile;
            setZipParsingError(null);
            setIsParsingZip(true);

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${BULK_UPLOAD_GTM_PREFIX}_Zip: Zip uploaded`,
            });

            try {
                const zip = await JSZip.loadAsync(selectedFile);
                removeMacOSFiles(zip);
                const folders = filter(zip.files, { dir: true });
                const folderCount = size(folders);

                // If there is one folder and all files are in this folder, flatten the zip
                if (folderCount === 1) {
                    const folderName = head(folders).name;
                    const areAllFilesInFolder = every(zip.files, (file, fileName) => fileName.startsWith(folderName));

                    if (areAllFilesInFolder) {
                        await flattenSingleFolderZip(zip, folderName);
                        selectedFile = await convertZipToFile(zip, originalZipFile.name, originalZipFile.lastModified);
                    } else {
                        // Else if there are files outside the folder, then throw error
                        throw new Error(formatMessage({ id: 'excel.bulk.zip.error.files.outside.folder' }));
                    }
                } else if (folderCount > 1) {
                    // Else if more than one folder, throw error
                    throw new Error(formatMessage({ id: 'excel.bulk.zip.error.multiple.folders' }));
                }

                setZipObj(zip);
                setZipFile(selectedFile);
            } catch (err) {
                setZipParsingError(err);
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${BULK_UPLOAD_GTM_PREFIX}_Zip: Zip upload parsing error`,
                    value: err,
                });
            }

            setIsParsingZip(false);
        },
        [formatMessage, setZipFile, setZipObj]
    );

    const onRemoveZipFile = useCallback(() => {
        setZipParsingError(null);
        setZipFile(null);
        setZipObj(null);
    }, [setZipFile, setZipObj]);

    const finalFailedZipValidationData = useMemo(() => {
        if (zipParsingError) {
            return {
                message: zipParsingError.message,
                indicationType: INDICATION_TYPES.ERROR,
            };
        }

        return failedValidationData;
    }, [failedValidationData, zipParsingError]);

    return {
        zipObj,
        zipFile,
        onSelectZipFile,
        onRemoveZipFile,
        failedZipValidationData: finalFailedZipValidationData,
        zipParsingError,
        isParsingZip,
    };
};
