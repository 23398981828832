const performanceRulesAPIPrefix = accountName => `/${accountName}/performance-rules`;
const RESOURCES_CACHE_TIME = 60 * 60 * 1000;

export const performanceRulesApiFactory = ({ callGetApi, callPutApi, callPostApi, callDeleteApi, queryClient }) => ({
    getPerformanceRulesSettings: (accountName, dimension) => {
        return queryClient.fetchQuery(
            ['performanceRulesSettings', accountName, dimension],
            () => callGetApi(`${performanceRulesAPIPrefix(accountName)}/settings?dimension=${dimension}`),
            {
                staleTime: RESOURCES_CACHE_TIME,
                cacheTime: RESOURCES_CACHE_TIME,
            }
        );
    },
    getPerformanceRulesTemplates: (accountName, dimension) => {
        return queryClient.fetchQuery(
            ['performanceRulesTemplates', accountName, dimension],
            () => callGetApi(`${performanceRulesAPIPrefix(accountName)}/templates?dimension=${dimension}`),
            {
                staleTime: RESOURCES_CACHE_TIME,
                cacheTime: RESOURCES_CACHE_TIME,
            }
        );
    },
    getPerformanceRulesData: (accountName, ruleId) => {
        return queryClient.fetchQuery(['performanceRulesData', accountName, ruleId], () =>
            callGetApi(`${performanceRulesAPIPrefix(accountName)}/rules/${ruleId}`)
        );
    },
    createPerformanceRules: (accountName, queryParams) => {
        return callPostApi(`${performanceRulesAPIPrefix(accountName)}/rules`, queryParams);
    },
    updatePerformanceRuleApi: ({ accountName, ruleId, changes }) => {
        return callPutApi(`${performanceRulesAPIPrefix(accountName)}/rules/${ruleId}`, changes);
    },
    deletePerformanceRule: (accountName, ruleId, shouldUndoActions) => {
        return callDeleteApi(
            `${performanceRulesAPIPrefix(accountName)}/rules/${ruleId}?undoActions=${shouldUndoActions}`
        );
    },
    getAccountRules: accountName => {
        return callGetApi(`${performanceRulesAPIPrefix(accountName)}/rules/account-rules`);
    },
    simulateRule: (accountName, queryParams) => {
        return callPostApi(`${performanceRulesAPIPrefix(accountName)}/rules/simulation`, queryParams);
    },
    duplicatePerformanceRules: (accountName, queryParams) => {
        return callPostApi(`${performanceRulesAPIPrefix(accountName)}/rules/duplicate`, queryParams);
    },
});
