class AbortControllerFactory {
    constructor() {
        this.cache = {};
    }

    createCancelableController(name, allowMultiple = false) {
        const newController = new AbortController();
        const curValue = this.cache[name] || [];

        if (!allowMultiple) {
            this.abort(name);
        }

        this.cache[name] = allowMultiple ? [...curValue, newController] : [newController];

        return newController;
    }

    abort(name) {
        let signal = this.cache[name];
        if (signal && signal.length > 0) {
            // abort all signals
            signal.forEach(sig => sig.abort());

            // cleanup the cache
            this.cache[name] = [];
        }
    }
}

const abortControllerFactory = new AbortControllerFactory();
export default abortControllerFactory;
