import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useDebouncedValue } from 'taboola-ultimate-ui';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { useReachEstimatorApi } from '../../../../../../../services/api/campaignReachEstimatorApi';
import { isPmpAccount } from '../../../../../../account-management/accountType';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useAccount } from '../../../../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { toGWtransformers } from '../../../../../flows/flowsUtils';

const STALE_TIME = 10 * 60 * 1000;
const DEBOUNCE_TIME = 1000;

export const useViewableImpressions = ({ entityType }) => {
    const { [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_THRESHOLD]: reachThreshold } = useCommonConfig([
        COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_THRESHOLD,
    ]);
    const { [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_LIMIT]: reachLimit } = useCommonConfig([
        COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_LIMIT,
    ]);
    const { [COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_DEBOUNCE_TIME]: reachEstimatorDebounceTime } = useCommonConfig([
        COMMON_FLAGS.CAMPAIGN_REACH_ESTIMATOR_DEBOUNCE_TIME,
    ]);

    const isBidAllowed = useConfigMatch({ [FEATURE_FLAGS.ADS_CONSOLE_REACH_ESTIMATOR_BID_ALLOWED]: 'true' });

    const { data: campaignData = {}, formAccount } = useFormDataContext();
    const debouncedCampaignData = useDebouncedValue(campaignData, reachEstimatorDebounceTime);
    const transformedCampaign = toGWtransformers[entityType](debouncedCampaignData);

    const {
        bid,
        pricingModel,
        budget: { value: budgetValue = '' } = { value: '' },
        platformTargeting: platforms = [],
        thirdPartyBrandSafetyTargeting: thirdPartyBrandSafety,
        campaignTargeting: {
            countryTargeting: countries = { values: [] },
            regionCountryTargeting: regions,
            cityCountryTargeting: cities,
            dmaCountryTargeting: dma,
            connectionTypeTargeting: connectionType,
            osTargeting: os,
            browserTargeting: browsers,
            customAudienceTargeting: customAudiences,
            lookalikeAudienceTargeting: lookalikeAudiences,
            marketplaceAudienceTargeting: marketplaceAudiences,
            contextualSegmentsTargeting: contextualSegments,
            customContextualTargeting: customContextualSegments,
            highImpactPackageTargetingId: highImpactPackageTargeting,
            publisherTargeting,
            supplyTargeting,
        } = {},
    } = transformedCampaign;

    let queryKey = [
        'getReachEstimation',
        formAccount.accountId,
        platforms,
        countries,
        regions,
        cities,
        dma,
        connectionType,
        os,
        browsers,
        customAudiences,
        lookalikeAudiences,
        marketplaceAudiences,
        contextualSegments,
        customContextualSegments,
        highImpactPackageTargeting,
        pricingModel,
        publisherTargeting,
        thirdPartyBrandSafety,
        supplyTargeting,
    ];

    const debouncedBid = useDebouncedValue(bid, DEBOUNCE_TIME);
    const debouncedBudgetValue = useDebouncedValue(budgetValue, DEBOUNCE_TIME);

    if (isBidAllowed) {
        queryKey = [...queryKey, debouncedBid, debouncedBudgetValue];
    }

    const account = useAccount();
    const shouldSendQuery = !isEmpty(platforms) && !isEmpty(countries?.values) && !isPmpAccount(account);
    const { getReachEstimation } = useReachEstimatorApi();
    const { data, isLoading, isSuccess } = useQuery(
        queryKey,
        () => {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                pageName: getPageName(),
                component: 'ViewableImpressionsField',
            });
            return getReachEstimation({ accountId: formAccount.accountId, campaign: transformedCampaign });
        },
        { staleTime: STALE_TIME, enabled: shouldSendQuery }
    );

    const isSmallReach = data && data.lowerBound < reachThreshold;
    const isReachOverLimit = data && data.upperBound > reachLimit;
    const isError =
        !isLoading && (!isSuccess || !data || data.lowerBound === undefined || data.upperBound === undefined);

    return {
        ...data,
        noFiltersChosen: !shouldSendQuery,
        isLoading,
        isSmallReach,
        isReachOverLimit,
        reachLimit,
        isError,
        reachThreshold,
    };
};
