import { SET_APPLICATION_STATUS } from 'actions';
import { abtEventEmitter } from 'modules/taboola-common-frontend-modules/ab-test/ABTEventEmitter';
import { permissionsSelector, userSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { selectedAccountSelectorRaw } from 'selectors/selectedAccount';
import { COMPONENT_STATUS } from 'services/constants';

export const ABT_DATA_READY = 'ABT_DATA_READY';
export const ABT_DATA_NOT_READY = 'ABT_DATA_NOT_READY';

export const createABTMiddleware = eventEmitter => store => next => action => {
    next(action);
    const { type, payload } = action;

    if (type !== SET_APPLICATION_STATUS) {
        return;
    }

    const { status, accountConfig } = payload;

    if (status !== COMPONENT_STATUS.ACTIVE) {
        eventEmitter.trigger(ABT_DATA_NOT_READY);
        return;
    }

    const state = store.getState();
    const user = userSelector(state);
    const permissions = permissionsSelector(state);
    const selectedAccountData = selectedAccountSelectorRaw(state);

    window.ABT_DATA = {
        user,
        selectedAccountData,
        selectedAccountConfig: accountConfig,
        permissions,
    };

    eventEmitter.trigger(ABT_DATA_READY);
};

export const abtMiddleware = createABTMiddleware(abtEventEmitter);
