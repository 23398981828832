'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _reactDates = require('react-dates');

require('react-dates/initialize');

var _Icons = require('../../Icons/Icons');

var _DatePickerNavigationIcons = require('../DatePicker/DatePickerNavigationIcons');

var _defaultPhrases = require('./defaultPhrases');

var _DateRangePickerDay = require('./DateRangePickerDay');

var _DateRangePickerDay2 = _interopRequireDefault(_DateRangePickerDay);

var _CalendarPresets = require('./CalendarPresets');

var _CalendarPresets2 = _interopRequireDefault(_CalendarPresets);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DateRangePicker = function (_Component) {
    _inherits(DateRangePicker, _Component);

    function DateRangePicker(props) {
        _classCallCheck(this, DateRangePicker);

        var _this = _possibleConstructorReturn(this, (DateRangePicker.__proto__ || Object.getPrototypeOf(DateRangePicker)).call(this, props));

        _initialiseProps.call(_this);

        var _this$props = _this.props,
            startDate = _this$props.startDate,
            endDate = _this$props.endDate;


        _this.state = {
            startDate: startDate,
            endDate: endDate,
            focusedInput: null,
            confirmEnabled: Boolean(startDate && endDate)
        };
        return _this;
    }

    _createClass(DateRangePicker, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                onRangeConfirmed = _props.onRangeConfirmed,
                confirmButtonText = _props.confirmButtonText,
                redundantStartDate = _props.startDate,
                redundantEndDate = _props.endDate,
                customRenderCalendarInfo = _props.renderCalendarInfo,
                ArrowIcon = _props.ArrowIcon,
                newProps = _objectWithoutProperties(_props, ['onRangeConfirmed', 'confirmButtonText', 'startDate', 'endDate', 'renderCalendarInfo', 'ArrowIcon']);

            var _state = this.state,
                startDate = _state.startDate,
                endDate = _state.endDate,
                focusedInput = _state.focusedInput,
                confirmEnabled = _state.confirmEnabled;


            return _react2.default.createElement(
                'div',
                { className: confirmEnabled ? '' : 'range-not-selected' },
                _react2.default.createElement(_reactDates.DateRangePicker, Object.assign({}, newProps, {
                    startDate: startDate,
                    endDate: endDate,
                    startDateId: 'start-date',
                    endDateId: 'end-date',
                    onDatesChange: this.onDatesChange,
                    onClose: this.onClose,
                    focusedInput: focusedInput,
                    onFocusChange: this.onFocusChange,
                    navPrev: _DatePickerNavigationIcons.NavLeftIcon,
                    navNext: _DatePickerNavigationIcons.NavRightIcon,
                    calendarInfoPosition: 'bottom',
                    customArrowIcon: ArrowIcon,
                    renderCalendarInfo: customRenderCalendarInfo || this.renderCalendarInfo,
                    minimumNights: 0,
                    phrases: _defaultPhrases.DateRangePickerPhrases
                }))
            );
        }
    }]);

    return DateRangePicker;
}(_react.Component);

var _initialiseProps = function _initialiseProps() {
    var _this2 = this;

    this.onDatesChange = function (_ref) {
        var startDate = _ref.startDate,
            endDate = _ref.endDate;
        var onDatesChange = _this2.props.onDatesChange;
        var _state2 = _this2.state,
            stateStartDate = _state2.startDate,
            stateFocusedInput = _state2.focusedInput;

        var focusedInput = startDate !== stateStartDate ? 'endDate' : 'startDate';
        var theEndDate = stateFocusedInput === 'startDate' ? null : endDate;
        var confirmEnabled = startDate !== null && theEndDate !== null;

        _this2.setState({
            startDate: startDate,
            endDate: theEndDate,
            confirmEnabled: confirmEnabled,
            focusedInput: focusedInput
        }, function () {
            return onDatesChange({ startDate: startDate, endDate: endDate });
        });
    };

    this.onFocusChange = function (focusedInput) {
        var onFocusChange = _this2.props.onFocusChange;


        _this2.setState({ focusedInput: focusedInput }, function () {
            return onFocusChange(focusedInput);
        });
    };

    this.onClose = function () {
        var _props2 = _this2.props,
            onRangeConfirmed = _props2.onRangeConfirmed,
            onClose = _props2.onClose;
        var _state3 = _this2.state,
            startDate = _state3.startDate,
            endDate = _state3.endDate;


        onRangeConfirmed(startDate, endDate);
        onClose();
    };

    this.setDates = function (startDate, endDate) {
        _this2.setState({
            startDate: startDate,
            endDate: endDate,
            confirmEnabled: true
        });
    };

    this.confirm = function () {
        var onRangeConfirmed = _this2.props.onRangeConfirmed;
        var _state4 = _this2.state,
            startDate = _state4.startDate,
            endDate = _state4.endDate;


        _this2.setState({ focusedInput: null }, function () {
            return onRangeConfirmed(startDate, endDate);
        });
    };

    this.renderCalendarInfo = function () {
        var confirmEnabled = _this2.state.confirmEnabled;
        var confirmButtonText = _this2.props.confirmButtonText;


        return _react2.default.createElement(_CalendarPresets2.default, {
            confirmEnabled: confirmEnabled,
            confirmButtonText: confirmButtonText,
            setDates: _this2.setDates,
            confirm: _this2.confirm
        });
    };
};

DateRangePicker.propTypes = {
    /** Fires when date range was confirmed */
    onRangeConfirmed: _propTypes2.default.func,
    /** Controlled input value for start date */
    startDate: _reactMomentProptypes2.default.momentObj,
    /** Controlled input value for end date */
    endDate: _reactMomentProptypes2.default.momentObj,
    /** The current focused input */
    focusedInput: _propTypes2.default.oneOf(['startDate', 'endDate']),
    /** Input id */
    startDateId: _propTypes2.default.string,
    /** Input id */
    endDateId: _propTypes2.default.string,
    /** Fires when focus changes */
    onFocusChange: _propTypes2.default.func,
    /** Fires when dates changes */
    onDatesChange: _propTypes2.default.func,
    // input related props
    /** Placeholder for start date input */
    startDatePlaceholderText: _propTypes2.default.string,
    /** Placeholder for end date input */
    endDatePlaceholderText: _propTypes2.default.string,
    disabled: _propTypes2.default.bool,
    required: _propTypes2.default.bool,
    readOnly: _propTypes2.default.bool,
    /** Message for screen readers */
    screenReaderInputMessage: _propTypes2.default.string,
    /** Adds a clear-dates button */
    showClearDates: _propTypes2.default.bool,
    /** Displays the default input icon */
    showDefaultInputIcon: _propTypes2.default.bool,
    /** Displays a custom input icon */
    customInputIcon: _propTypes2.default.node,
    /** Displays a custom arrow icon */
    customArrowIcon: _propTypes2.default.node,
    /** Displays a custom close icon */
    customCloseIcon: _propTypes2.default.node,
    /** Determines the text on the confirm button */
    confirmButtonText: _propTypes2.default.string,
    /** Custom arrow icon */
    ArrowIcon: _propTypes2.default.node,

    // calendar presentation and interaction related props
    /** Custom rendering of the month */
    renderMonth: _propTypes2.default.func,
    /** Controls the horizontal margin */
    horizontalMargin: _propTypes2.default.number,
    /** Changes the day size */
    daySize: _propTypes2.default.number,
    /** Changes the text direction */
    isRTL: _propTypes2.default.bool,
    /** Sets the initial visible month */
    initialVisibleMonth: _propTypes2.default.func,
    /** Changes the day the week starts with */
    firstDayOfWeek: _propTypes2.default.oneOf([0, 1, 2, 3, 4, 5, 6]),
    /** Number of months to present */
    numberOfMonths: _propTypes2.default.number,
    /** Opens the calendar when inputs are cleared */
    reopenPickerOnClearDates: _propTypes2.default.bool,
    /** Determines the vertical spacing from the top of the calendar */
    verticalSpacing: _propTypes2.default.number,
    /** Show keyboard shortcut panel */
    hideKeyboardShortcutsPanel: _propTypes2.default.bool,
    /** Close picker on range selected */
    keepOpenOnDateSelect: _propTypes2.default.bool,
    /** Custom calendar info renderer */
    renderCalendarInfo: _propTypes2.default.func,

    // navigation related props
    /** Go to previous month */
    navPrev: _propTypes2.default.node,
    /** Go to next month */
    navNext: _propTypes2.default.node,
    /** Fires when the previous month button is clicked */
    onPrevMonthClick: _propTypes2.default.func,
    /** Fires when the next month button is clicked */
    onNextMonthClick: _propTypes2.default.func,
    /** Fires when the calendar closes */
    onClose: _propTypes2.default.func,
    /** Determines the delay before the calendar opens */
    transitionDuration: _propTypes2.default.number, // milliseconds

    // day presentation and interaction related props
    /** Enables the selection of out-of-range dates */
    enableOutsideDays: _propTypes2.default.bool,
    /** Determines which dates cannot be selected */
    isDayBlocked: _propTypes2.default.func,
    /** Determines which dates are out of the range of selection */
    isOutsideRange: _propTypes2.default.func,
    /** Determines which dates highlighted */
    isDayHighlighted: _propTypes2.default.func,
    /** Change the visibility of a day */
    renderDayContents: _propTypes2.default.func,

    // internationalization props
    /** Intl - formats the display of the selected date */
    displayFormat: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /** Intl - format of month (MomentJS formats) */
    monthFormat: _propTypes2.default.string
};

DateRangePicker.defaultProps = {
    displayFormat: 'DD/MM/YY',
    daySize: 38,
    hideKeyboardShortcutsPanel: true,
    verticalSpacing: 10,
    startDate: null,
    endDate: null,
    focusedInput: 'startDate',
    ArrowIcon: _react2.default.createElement(_Icons.RightIcon, null),
    renderDayContents: function renderDayContents(day) {
        return (0, _DateRangePickerDay2.default)({ day: day });
    },
    keepOpenOnDateSelect: true,
    confirmButtonText: 'APPLY',
    isOutsideRange: function isOutsideRange() {
        return false;
    },
    onDatesChange: function onDatesChange() {},
    onFocusChange: function onFocusChange() {},
    onRangeConfirmed: function onRangeConfirmed() {},
    onClose: function onClose() {}
};

exports.default = DateRangePicker;