import React from 'react';
import classnames from 'classnames/bind';
import { RadioGroup, Radio, IndicationContent, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { PRICING_MODEL } from '../../../../../config';
import { BID_STRATEGIES } from '../../../config';
import BidInput from '../../Bid/BidInput/BidInput';
import { useFormBidValidatedValue } from '../../Bid/useFormBidValidatedValue';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';
import commonStyles from '../../commonEditor.module.scss';
import styles from './cpcBidType.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CPCBidType = () => {
    const {
        value: bid = '',
        onChange: onBidChange,
        failedValidationData,
        scrollRef,
    } = useFormBidValidatedValue({
        pricingModel: PRICING_MODEL.CPC,
    });
    const bidInputProps = { bid, onBidChange, failedValidationData, ref: scrollRef };
    const { value: bidStrategy, onChange: onChangeStrategy } = useBidStrategyFormField();

    return (
        <RadioGroup name="cpc-strategy" onChange={onChangeStrategy} selectedValue={bidStrategy}>
            <Radio
                childrenContainerClassName={styles['radio-child']}
                title={
                    <FormattedMessage
                        id="campaign.editor.bid.type.title.smart.CPC"
                        defaultMessage="Enhanced CPC (Recommended)"
                    />
                }
                description={
                    <FormattedMessage
                        id="campaign.editor.bid.type.description.smart.CPC"
                        defaultMessage="Automatically optimize baseline bid to maximize the performance (Smartbid)"
                    />
                }
                value={BID_STRATEGIES.SMART}
            >
                <BidInput isHidden={bidStrategy !== BID_STRATEGIES.SMART} {...bidInputProps} />
            </Radio>
            <Radio
                childrenContainerClassName={styles['radio-child']}
                value={BID_STRATEGIES.FIXED}
                title={<FormattedMessage id="campaign.editor.bid.type.title.fixed.CPC" defaultMessage="Fixed CPC" />}
                description={
                    <FormattedMessage
                        id="campaign.editor.bid.type.description.fixed.CPC"
                        defaultMessage="Manually control your bid, baseline will be fixed and not optimized"
                    />
                }
            >
                <BidInput isHidden={bidStrategy !== BID_STRATEGIES.FIXED} {...bidInputProps} />
                {bidStrategy === BID_STRATEGIES.FIXED && (
                    <IndicationContent
                        containerClassName={classNameBuilder('fixed-bid-warning', commonStyles['warning-indication'])}
                        type={INDICATION_TYPES.WARNING}
                    >
                        <FormattedMessage
                            id="validations.error.campaign.cpc.fixed.warning"
                            defaultMessage="Manually controlled bids are not recommended for optimal performance and require constant monitoring"
                        />
                    </IndicationContent>
                )}
            </Radio>
        </RadioGroup>
    );
};
