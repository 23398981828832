import React, { Component } from 'react';
import ActionableTextCellRenderer from './ActionableTextCellRenderer';
import InternalLinkCellRenderer from './InternalLinkCellRenderer';

class ActionableTextWithInternalLinkCellRenderer extends Component {
    render() {
        return (
            <ActionableTextCellRenderer {...this.props}>
                <InternalLinkCellRenderer {...this.props} />
            </ActionableTextCellRenderer>
        );
    }
}

ActionableTextWithInternalLinkCellRenderer.propTypes = {
    ...ActionableTextCellRenderer.propTypes,
    ...InternalLinkCellRenderer.propTypes,
};

export default ActionableTextWithInternalLinkCellRenderer;
