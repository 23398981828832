import { isNil, keys, size, isEmpty, get } from 'lodash';
import {
    addMetadataToWorkbook,
    DEFAULT_WIDTH,
    DEFAULT_WORKBOOK_VIEW,
    getEntityMetadataWorksheetName,
    getExampleText,
    getExcelFieldValue,
    getHeaderName,
    getMetadataFromConfig,
    getSheetName,
    MAX_CELL_LENGTH,
    setColumnStyle,
} from '../utils/excelBulkWorkbookUtils';
import { METADATA_SHEET_VISIBILITY } from '../utils/excelUtils';
import { verifyEntityConfigConsistency } from '../utils/pojoConsistencyUtils';

export const buildExcelBulkTemplate = ({
    workbook,
    excelConfig,
    entityDataMap,
    metadata: { locale, parentAppName, accountName, formatMessage },
    dictionaryData,
    isFullConfigAvailable,
    entityToRowLimitMap,
}) => {
    addMetadataToWorkbook(workbook, getMetadataFromConfig(excelConfig, { locale, parentAppName, accountName }));
    workbook.views = [{ ...DEFAULT_WORKBOOK_VIEW, activeTab: 1 }];

    excelConfig.entityList.forEach(entityConfig => {
        const { entity, headerRowIndex, sheetName, exampleRowIndex, columns } = entityConfig;
        if (!isEmpty(entityDataMap[entity]) && isFullConfigAvailable) {
            verifyEntityConfigConsistency(entityConfig, get(entityDataMap, [entity, 0, 'value']));
        }
        const maxRows = entityToRowLimitMap[entity];
        const sheet = workbook.addWorksheet(getSheetName(sheetName, formatMessage), {
            views: [{ state: 'frozen', ySplit: headerRowIndex }],
        });
        const metadataWorksheetName = getEntityMetadataWorksheetName(entityConfig);
        const metadataSheet = workbook.addWorksheet(metadataWorksheetName);
        metadataSheet.state = METADATA_SHEET_VISIBILITY;
        const totalRowCount = entityDataMap
            ? Math.max(maxRows, size(keys(entityDataMap[entity])) + headerRowIndex)
            : maxRows + headerRowIndex;
        sheet.addRows(new Array(totalRowCount).fill([]));
        sheet.getRows(1, totalRowCount).forEach(row => {
            row.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        });

        const dependentTargetFields = new Set([]);
        columns
            .filter(({ ignore }) => !ignore)
            .forEach((column, index) => {
                const columnIndex = index + 1;
                sheet.getColumn(columnIndex).width = column.width || DEFAULT_WIDTH;
                const headerName = getHeaderName({
                    entity,
                    column,
                    formatMessage,
                });
                const exampleText = getExampleText({
                    entity,
                    column,
                    formatMessage,
                    dictionaryData,
                });

                // metadata
                const metadataRow = metadataSheet.addRow([]);
                metadataRow.getCell(1).value = column.field;
                metadataRow.getCell(2).value = headerName;

                // header
                const exampleRow = sheet.getRow(exampleRowIndex);
                const headerRow = sheet.getRow(headerRowIndex);
                headerRow.font = { bold: true, size: 12 };
                exampleRow.getCell(columnIndex).value = exampleText;
                headerRow.getCell(columnIndex).value = headerName;

                //data
                if (!isEmpty(entityDataMap[entity])) {
                    entityDataMap[entity].forEach(({ value: entityData }, index) => {
                        const currentRowIndex = headerRowIndex + 1 + index;
                        const row = sheet.getRow(currentRowIndex);
                        const value = getExcelFieldValue({
                            entityData,
                            column,
                            formatMessage,
                            dictionaryData,
                        });
                        const cell = row.getCell(columnIndex);
                        if (!isNil(value)) {
                            if (value?.length > MAX_CELL_LENGTH) {
                                // Special handling for fields hitting character limit that require dependent cells to be set to Null
                                if (!isEmpty(column?.characterLimitDependencies)) {
                                    column.characterLimitDependencies.forEach(field => {
                                        const targetFieldName = formatMessage({
                                            id: `excel.bulk.column.CAMPAIGN.${field}`,
                                            defaultMessage: field,
                                        });
                                        dependentTargetFields.add({
                                            rowIndex: currentRowIndex,
                                            targetFieldName,
                                            dependentFieldName: headerName,
                                        });
                                    });
                                }
                                cell.value = undefined;
                                cell.note = formatMessage({
                                    id: 'excel.bulk.export.data.note.too.long',
                                    defaultMessage:
                                        'Value is too long to be displayed. To update this field, please edit on Taboola Ads',
                                });
                            } else {
                                cell.value = value;
                            }
                        }
                    });
                }

                setColumnStyle({ sheet, column, entityConfig, columnIndex, totalRowCount, formatMessage });
            });
        if (!isEmpty(dependentTargetFields)) {
            dependentTargetFields.forEach(({ rowIndex, targetFieldName, dependentFieldName }) => {
                // using the dependentFieldName this block checks the header rows to see what column the targetFieldName is
                // takes that column index with row index to find the cell and set the value to undefined
                sheet.getRow(headerRowIndex).eachCell((cell, index) => {
                    if (cell.value === targetFieldName) {
                        const targetCell = sheet.getRow(rowIndex).getCell(index);
                        targetCell.value = undefined;
                        targetCell.note = formatMessage(
                            {
                                id: 'excel.bulk.export.data.note.too.long.dependentField',
                                defaultMessage: `${targetFieldName} is unavailable via Bulk Sheet due to the character limit of ${dependentFieldName}'s cell, please edit on Taboola Ads`,
                            },
                            {
                                fieldName: targetFieldName,
                                dependentFieldName,
                            }
                        );
                    }
                });
            });
        }
    });

    return workbook;
};
