import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { findIndex, get, isEqual, map, omit } from 'lodash';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { aiGenerationApi, ERROR_CODES } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { defaultCropData } from '../config/defaultCropData';
import { CROPPING_MODAL_COMPONENT_NAME, OUTCROPPING_COMPONENT_NAME } from '../constants/croppingModalGtmConstants';
import { createImage } from '../utils/getCropImage';
import { useCropDataInit } from './useCropDataInit';

export const sendCropGTMEvent = value => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: CROPPING_MODAL_COMPONENT_NAME,
        value,
    });
};

const ratios = map(defaultCropData, 'ratio');

export const useCroppingToolsFormState = ({ onClose, campaignId, originalSrc }) => {
    const { value: cropData = [] } = useFormFieldValue({
        field: 'cropData',
    });
    const [srcHistory, setSrcHistory] = useState([]);
    const [shouldShowOutcropBanner, setShouldShowOutcropBanner] = useState(false);
    const [activeRatio, setActiveRatio] = useState(defaultCropData[0].ratio);
    const [outcroppingStatus, setOutcroppingStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [outcroppingError, setOutcroppingError] = useState(null);
    const [src, setSrc] = useState(originalSrc);
    const curSrcIndex = srcHistory.indexOf(src);
    const [initialCropData, setInitialCropData] = useState([]);
    const isMounted = useMountedState();

    useCropDataInit({ src, ratios, setInitialCropData, setOutcroppingStatus });

    const isOutcroppingEnabled = useConfigMatch({ [FEATURE_FLAGS.OUTCROPPING_IMAGE_ENABLED]: 'true' });

    const shouldShowOutcropWarning = shouldShowOutcropBanner && outcroppingStatus !== COMPONENT_STATUS.LOADING;

    const shouldRenderOutcropBanner =
        isOutcroppingEnabled && shouldShowOutcropBanner && outcroppingStatus !== COMPONENT_STATUS.LOADING;

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const {
        [COMMON_FLAGS.OUTCROPPING_IMAGE_NUM_IMAGES]: outcropNumImages,
        [COMMON_FLAGS.OUTCROPPING_IMAGE_MIN_THRESHOLD]: outcropRatioMinThreshold,
        [COMMON_FLAGS.OUTCROPPING_IMAGE_MAX_THRESHOLD]: outcropRatioMaxThreshold,
    } = useCommonConfig([
        COMMON_FLAGS.OUTCROPPING_IMAGE_NUM_IMAGES,
        COMMON_FLAGS.OUTCROPPING_IMAGE_MIN_THRESHOLD,
        COMMON_FLAGS.OUTCROPPING_IMAGE_MAX_THRESHOLD,
    ]);

    const onApply = useCallback(() => {
        if (curSrcIndex >= 0) {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                taboolaCampaignId: campaignId,
                component: `${OUTCROPPING_COMPONENT_NAME}: Save Image Clicked`,
                value: src,
            });
        }

        onClose({
            cropData,
            src,
        });
        sendCropGTMEvent('apply crop');
        return cropData;
    }, [campaignId, curSrcIndex, onClose, src, cropData]);

    const onSkip = useCallback(() => {
        onClose({ cropData: null, src });
        sendCropGTMEvent('skip modal');
    }, [onClose, src]);

    const activeIndex = useMemo(() => findIndex(cropData, { ratio: activeRatio }), [activeRatio, cropData]);

    const isEqualToInitialData = useMemo(
        () =>
            isEqual(
                map(cropData, data => omit(data, 'crop')),
                map(initialCropData, data => omit(data, 'crop'))
            ),
        [cropData, initialCropData]
    );

    const updateSrc = useCallback(
        async newSrc => {
            if (src !== newSrc) {
                setOutcroppingStatus(COMPONENT_STATUS.LOADING);
            }
            setSrc(newSrc);
        },
        [src, setOutcroppingStatus]
    );

    const onOutcropImage = async (apiBody = {}) => {
        setOutcroppingStatus(COMPONENT_STATUS.LOADING);
        setOutcroppingError(null);

        let results;

        try {
            const response = await aiGenerationApi.getAIGeneratedOutcropImage({
                accountId,
                campaignId,
                imageUrl: src,
                numImages: outcropNumImages,
                ...apiBody,
            });
            results = get(response, 'results', []).map(({ url }) => url);
        } catch (err) {
            if (!isMounted()) {
                return;
            }
            setOutcroppingStatus(COMPONENT_STATUS.ERROR);

            if (err?.status === ERROR_CODES.UNPROCESSABLE_ENTITY) {
                setOutcroppingError('app.genai.againstpolicy.error');
            } else {
                setOutcroppingError('creative.studio.error.generic');
            }

            return;
        }
        if (!isMounted()) {
            return;
        }

        // if the API returned no results, show an error
        if (!results?.length) {
            setOutcroppingStatus(COMPONENT_STATUS.ERROR);
            setOutcroppingError('creative.studio.error.generic');

            return;
        }

        updateSrc(results[0]);
        setSrcHistory(results);
        setOutcroppingStatus(COMPONENT_STATUS.ACTIVE);
        setShouldShowOutcropBanner(false);
    };

    const checkSrcRatio = useCallback(async () => {
        try {
            const { width, height } = await createImage(src);

            if (!isMounted()) {
                return;
            }

            const srcRatio = parseFloat(width / height);

            let isBeyondThreshold;

            if (outcropRatioMinThreshold) {
                isBeyondThreshold = srcRatio < outcropRatioMinThreshold;
            }

            if (outcropRatioMaxThreshold) {
                isBeyondThreshold = isBeyondThreshold || srcRatio > outcropRatioMaxThreshold;
            }

            setShouldShowOutcropBanner(isBeyondThreshold);

            if (isBeyondThreshold) {
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${OUTCROPPING_COMPONENT_NAME}: Warning Visible`,
                    value: src,
                });
            }
        } catch (err) {
            if (!isMounted()) {
                return;
            }

            setShouldShowOutcropBanner(false);
        }
    }, [outcropRatioMaxThreshold, outcropRatioMinThreshold, src, isMounted]);

    useEffect(() => {
        // if outcropping is disabled OR if the src is not the original src OR if the src is a stable outcropping image
        //       don't check the ratio
        const shouldSkipRatioCheck =
            !isOutcroppingEnabled || originalSrc !== src || /.*STABLE_DIFFUSION_OUTCROP.*/.test(originalSrc);

        if (shouldSkipRatioCheck) {
            return;
        }

        checkSrcRatio();
    }, [checkSrcRatio, isOutcroppingEnabled, originalSrc, src]);

    return {
        handleSubmit: onApply,
        handleSkip: onSkip,
        initialCropData,
        setInitialCropData,
        setActiveRatio,
        activeRatio,
        activeIndex,
        isEqualToInitialData,
        ratios,
        setSrcHistory,
        curSrcIndex,
        srcHistory,
        shouldShowOutcropBanner,
        outcroppingStatus,
        outcroppingError,
        onOutcropImage,
        isOutcroppingEnabled,
        updateSrc,
        src,
        shouldShowOutcropWarning,
        shouldRenderOutcropBanner,
        setShouldShowOutcropBanner,
        setOutcroppingStatus,
    };
};
