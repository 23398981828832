import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { AlertIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import recommendationsApi from 'services/api/recommendationsApi';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { useRecommendationService } from '../../hooks/useRecommendationService';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const LowResolutionSingleItemRecommendation = ({ recommendation, removeRecommendationIndication, data }) => {
    const {
        messageParams,
        id: recommendationId,
        itemId,
        campaignId,
        accountName: accountId,
        subType: recommendationSubType,
    } = recommendation;
    const { item_improvement_rate: itemImprovementRate } = messageParams;
    const recommendationWithoutImprovementRate =
        recommendationSubType === 'LOW_RESOLUTION_WITHOUT_IMPROVEMENT_RATE' ? 'no-improvement-rate.' : '';
    const descriptionCode = `performance-recommendations.low-resolution-single-item.${recommendationWithoutImprovementRate}description`;

    const { editCreativeWithRecommendation } = useRecommendationService();

    const navigateToEditCreativeWithRecommendation = useCallback(
        () => editCreativeWithRecommendation({ recommendationId, accountId, campaignId, itemId, data }),
        [editCreativeWithRecommendation, recommendationId, accountId, campaignId, itemId, data]
    );

    const dismissItemRecommendation = recommendationsApi.dismissItemPerformanceRecommendation;
    const { dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        markAsApplied: false,
        errorCode: 'performance-recommendations.general-recommendation.error-message',
        dismissParams: {
            accountId,
            campaignId,
            itemId,
            recommendationId,
        },
        onDismiss: dismissItemRecommendation,
        onDismissSuccess: removeRecommendationIndication,
    });

    const messageDetails = {
        ...messageParams,
        itemImprovementRate: <FormattedNumber value={itemImprovementRate} maximumFractionDigits={2} />,
    };

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageDetails}
            descriptionCode={descriptionCode}
            isRenderedInReportCell
            titleCode="performance-recommendations.low-resolution-single-item.title"
            iconImg={AlertIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToEditCreativeWithRecommendation}
                    recommendation={recommendation}
                    textCode="performance-recommendations.low-resolution-single-item.action"
                    dataMetricsComponent="RecommendationIndicationEditCreativeButton"
                    defaultMessage="Edit Media"
                    variant={Button.variant.primary}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

LowResolutionSingleItemRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
