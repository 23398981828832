"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgTarget(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "evenodd",
            d: "M10.75 18.142a6.474 6.474 0 0 1-5.392-5.392H3v-2h2.358a6.474 6.474 0 0 1 5.392-5.392V3h2v2.358a6.474 6.474 0 0 1 5.392 5.392H20.5v2h-2.358a6.474 6.474 0 0 1-5.392 5.392V20.5h-2v-2.358zm1-1.413a4.979 4.979 0 1 0 0-9.958 4.979 4.979 0 0 0 0 9.958zm0-1.986a2.993 2.993 0 1 1 0-5.986 2.993 2.993 0 0 1 0 5.986zm0-1.806a1.187 1.187 0 1 0 0-2.374 1.187 1.187 0 0 0 0 2.374z"
        })
    );
}
exports.default = SvgTarget;