import moment from 'moment-timezone';
import { HOUR_IN_MS, PHASES } from './config';
import { calculateProgress } from './serviceUtils';

export const MIN_PROGRESS = 4;

const PROGRESS_MESSAGE_ID = {
    HOURS_SHORT: 'hours.short',
    HOURS: 'hours',
    CONVERSIONS_SHORT: 'conversions.short',
    CONVERSIONS_LEFT: 'conversions.left',
};

export const PHASE_TO_CELL_PROGRESS_MESSAGE = {
    [PHASES.PENDING_REVIEW]: PROGRESS_MESSAGE_ID.HOURS_SHORT,
    [PHASES.CVR_RELEARNING]: PROGRESS_MESSAGE_ID.CONVERSIONS_SHORT,
    [PHASES.CVR_LEARNING]: PROGRESS_MESSAGE_ID.CONVERSIONS_SHORT,
    [PHASES.CVR_LEARNING_LIMITED]: PROGRESS_MESSAGE_ID.CONVERSIONS_SHORT,
};

export const PHASE_TO_TOOLTIP_PROGRESS_MESSAGE = {
    [PHASES.PENDING_REVIEW]: PROGRESS_MESSAGE_ID.HOURS,
    [PHASES.CVR_RELEARNING]: PROGRESS_MESSAGE_ID.CONVERSIONS_LEFT,
    [PHASES.CVR_LEARNING]: PROGRESS_MESSAGE_ID.CONVERSIONS_LEFT,
    [PHASES.CVR_LEARNING_LIMITED]: PROGRESS_MESSAGE_ID.CONVERSIONS_LEFT,
};

export const STEPS_CONFIGURATION_TYPE = {
    DEFAULT: 'DEFAULT',
    LIMITED: 'LIMITED',
    RELEARNING: 'RELEARNING',
};

export const stepsConfigurationToSteps = {
    [STEPS_CONFIGURATION_TYPE.DEFAULT]: [PHASES.PENDING_REVIEW, PHASES.CVR_LEARNING, PHASES.CVR_LEARNING_COMPLETE],
    [STEPS_CONFIGURATION_TYPE.LIMITED]: [
        PHASES.PENDING_REVIEW,
        PHASES.CVR_LEARNING_LIMITED,
        PHASES.CVR_LEARNING_COMPLETE,
    ],
    [STEPS_CONFIGURATION_TYPE.RELEARNING]: [PHASES.CVR_RELEARNING, PHASES.CVR_LEARNING_COMPLETE],
};

export const calculateRemainingTimeInMs = (updateTimeUtcInMs, maxTimeInHours) => {
    const maxTimeInMs = maxTimeInHours * HOUR_IN_MS;
    const endTime = maxTimeInMs + updateTimeUtcInMs;
    return moment(endTime).diff(moment().utc());
};

export const calculatePendingReviewProgress = ({ maxTimeInReviewInHours, phaseUpdateTime }) => {
    const maxTimeInMs = maxTimeInReviewInHours * HOUR_IN_MS;
    const remainingTimeInMs = Math.max(0, calculateRemainingTimeInMs(phaseUpdateTime, maxTimeInReviewInHours));
    const progress = calculateProgress(remainingTimeInMs, maxTimeInMs);

    return {
        progress,
        remainingTimeInMs,
    };
};

export const calculateConversionsProgress = ({ totalConversions, numberOfConversionsInTimeframeForCompletion }) => {
    const currentConversions = Math.min(totalConversions, numberOfConversionsInTimeframeForCompletion);
    const progress = Math.max(
        Math.round((currentConversions / numberOfConversionsInTimeframeForCompletion) * 100),
        MIN_PROGRESS
    );

    return {
        currentConversions,
        progress,
    };
};

export const isLearningWithWarning = ({
    progress,
    phaseUpdateTime,
    minLearningDaysBeforeWarning,
    minLearningProgressPercentageBeforeWarning,
}) => {
    const timePassedInLearningInDays = moment(moment().utc()).diff(phaseUpdateTime, 'days');
    return (
        timePassedInLearningInDays >= minLearningDaysBeforeWarning &&
        progress < minLearningProgressPercentageBeforeWarning * 100
    );
};
