import { useMemo, useState, useCallback, useEffect } from 'react';
import { batch } from 'react-redux';
import { keys, uniqBy, noop } from 'lodash';
import { getAllNodePathsMap, useLoadingState } from '../../Tree';
import { searchModes } from '../consts/searchModes';
import { useEmptyTaxonomiesGetter } from './useEmptyTaxonomiesGetter';
import { useSegmentsWithBundlingFetcher } from './useSegmentsWithBundlingFetcher';

const getLoadingPathsMap = segments => getAllNodePathsMap(segments, () => 1);
const chargeType = 'CPC';
const getSearchMode = (search, isAdvancedSearch) => {
    if (!search) {
        return undefined;
    }

    return isAdvancedSearch ? searchModes.FULL : searchModes.BUNDLED;
};

export const useSegmentsWithBundlingApi = ({
    search,
    unbundledNodes,
    providers,
    selectedAccountId,
    isAdvancedSearch,
}) => {
    const taxonomyIds = useMemo(() => keys(unbundledNodes), [unbundledNodes]);
    const [segments, setSegments] = useState([]);
    const [fullSegments, setFullSegments] = useState([]);
    const [totalsPerTaxonomy, setTotals] = useState({});
    const { getEmptyTaxonomies } = useEmptyTaxonomiesGetter(segments, unbundledNodes);

    const { loadingPaths, startLoading, stopLoading } = useLoadingState(segments, getLoadingPathsMap);
    const { fetchSegments, fetchSegmentsByIds } = useSegmentsWithBundlingFetcher({
        search,
        taxonomies: taxonomyIds,
        providers,
        selectedAccountId,
        chargeType,
        searchMode: getSearchMode(search, isAdvancedSearch),
    });

    useEffect(() => {
        setFullSegments([]);
    }, [providers]);

    const fetchSegmentsWithLoading = useCallback(
        async ({ replaceSegments, onDataLoad = noop }) => {
            const emptyTaxonomies = getEmptyTaxonomies();
            const isBundleLoad = emptyTaxonomies.length > 0;

            startLoading(emptyTaxonomies);
            const { results, transformedTotals } = await fetchSegments();

            batch(() => {
                setFullSegments(prevResults => uniqBy([...prevResults, ...results], 'path'));
                setSegments(prevResults => (replaceSegments ? results : uniqBy([...prevResults, ...results], 'path')));
                setTotals(prevTotals =>
                    replaceSegments ? transformedTotals : { ...prevTotals, ...transformedTotals }
                );
                onDataLoad(results, isBundleLoad);
                stopLoading(emptyTaxonomies);
            });

            return results;
        },
        [fetchSegments, startLoading, stopLoading, getEmptyTaxonomies]
    );

    const loadMore = useCallback(
        params =>
            fetchSegmentsWithLoading({
                loadingPaths,
                totalsPerTaxonomy,
                ...params,
            }),
        [fetchSegmentsWithLoading, totalsPerTaxonomy, loadingPaths]
    );

    const loadAll = useCallback(taxonomies => loadMore({ taxonomies, pageSize: 0 }), [loadMore]);

    const reload = useCallback(
        onDataLoad => fetchSegmentsWithLoading({ replaceSegments: true, onDataLoad }),
        [fetchSegmentsWithLoading]
    );

    return {
        segments,
        fullSegments,
        totals: totalsPerTaxonomy,
        loadMore,
        loadAll,
        reload,
        loadByIds: fetchSegmentsByIds,
        loadingPaths,
    };
};
