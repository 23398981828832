import React from 'react';
import YES_NO_TYPES from '../../../campaigns/config/yesNoConsts';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const ExcludeAudienceFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.exclude.audience.tooltip.overview.yes"
                defaultMessage="Select {yes} if you don’t want any campaign, new or existing, to target anyone that is part of this audience."
                values={{
                    yes: (
                        <strong>
                            <FormattedMessage id="app.form.field.type.yes" defaultMessage={YES_NO_TYPES.YES} />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.exclude.audience.tooltip.overview.no"
                defaultMessage="Select {no} if you want campaigns to be able to target this audience."
                values={{
                    no: (
                        <strong>
                            <FormattedMessage id="app.form.field.type.no" defaultMessage={YES_NO_TYPES.NO} />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.exclude.audience.tooltip.note"
                defaultMessage="Note that selecting {no} does not affect any campaign currently excluding this audience already."
                values={{
                    no: (
                        <strong>
                            <FormattedMessage id="app.form.field.type.no" defaultMessage={YES_NO_TYPES.NO} />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.pixel.based.audience.exclude.audience.tooltip.tip"
                defaultMessage="You can view and modify any campaign's targeting/suppression settings under 'My Audiences' in the 'Edit Campaign' page."
            />
        </TooltipSection>
    </>
);

export default ExcludeAudienceFieldTooltip;
