import React, { useCallback, useContext, useMemo, useState } from 'react';
import { STRIPE_ELEMENT } from './consts';
import { useStripeCardSetup } from './hooks/useStripeCardSetup';

const StripeCardContext = React.createContext({});
export const useStripeCardContext = () => useContext(StripeCardContext);
const { Provider } = StripeCardContext;

const initialElementsData = {
    [STRIPE_ELEMENT.CARD_NUMBER]: { empty: true, error: null, dirty: false },
    [STRIPE_ELEMENT.CARD_EXPIRY]: { empty: true, error: null, dirty: false },
    [STRIPE_ELEMENT.CARD_CVC]: { empty: true, error: null, dirty: false },
};

export const StripeCardContextProvider = ({ clientSecret, children }) => {
    const [elementsState, setElementsState] = useState(initialElementsData);

    const updateElementState = useCallback((elementId, elementState) => {
        setElementsState(elementsState => ({ ...elementsState, [elementId]: elementState }));
    }, []);

    const isStripeValid = useCallback(
        () => Object.values(elementsState).every(stripeElementState => stripeElementState.complete),
        [elementsState]
    );

    const { setupCard } = useStripeCardSetup({ clientSecret, updateElementState, elementsState });

    const stripeCardContext = useMemo(
        () => ({
            elementsState,
            updateElementState,
            isStripeValid,
            setupCard,
        }),
        [elementsState, isStripeValid, setupCard, updateElementState]
    );

    return <Provider value={stripeCardContext}>{children}</Provider>;
};
