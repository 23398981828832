"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLightningRound(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 13V18L16 11H13V6L8 13H11Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgLightningRound;