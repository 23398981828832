import React from 'react';
import {
    CampaignOutlinedIcon,
    CheckCircleOutlinedIcon,
    MediationOutlinedIcon,
    TrackChangesOutlinedIcon,
    PersonOutlineOutlinedIcon,
    EmailOutlinedIcon,
} from 'tuui';
import { CookieConsent } from 'components/CookieConsent/CookieConsent';
import {
    externalPath as accountManagementExternalPath,
    isAdminNetworkAccountType,
    isGroupAccountType,
    isLowTouchAccount,
    isNetworkAccountType,
    isVideoAccount,
    MODULE_NAME as ACCOUNT_MGMT_MODULE,
    ModuleComponent as AccountManagement,
    permissions as ACCOUNT_MGMT_PERMISSIONS,
} from 'modules/account-management';
import { Accounts, MODULE_NAME as ACCOUNTS_MODULE } from 'modules/accounts';
import AudienceInsights, { MODULE_NAME as AUDIENCE_INSIGHTS_MODULE } from 'modules/audience-insights';
import { Audiences, MODULE_NAME as AUDIENCES_MODULE } from 'modules/audiences';
import {
    BillingAndPaymentsModulePage,
    MODULE_NAME as BILLING_AND_PAYMENTS_MODULE,
    visibilityCondition as billingAndPaymentVisibilityCondition,
} from 'modules/billing-and-payments';
import {
    externalPath as campaignReviewExternalPath,
    MODULE_NAME as CAMPAIGN_REVIEW_MODULE,
    ModuleComponent as CampaignReviewManagement,
    permissions as CAMPAIGN_REVIEW_PERMISSIONS,
} from 'modules/campaign-review';
import Campaigns, { config as campaignsConfig } from 'modules/campaigns';
import { MODULE_NAME as CAMPAIGNS_MODULE } from 'modules/campaigns/config';
import {
    externalPath as contactSupportExternalPath,
    MODULE_NAME as CONTACT_SUPPORT_MODULE,
    ModuleComponent as ContactSupport,
    permissions as CONTACT_SUPPORT_PERMISSIONS,
} from 'modules/contact-support';
import {
    externalPath as lightAccountManagementExternalPath,
    MODULE_NAME as LIGHT_ACCOUNT_MGMT_MODULE,
    ModuleComponent as LightAccountManagement,
    permissions as LIGHT_ACCOUNT_MGMT_PERMISSIONS,
} from 'modules/light-account-management';
import { MODULE_NAME as ONBOARDING_MODULE, Onboarding } from 'modules/onboarding';
import { MODULE_NAME as PERFORMANCE_RULES_MODULE } from 'modules/performance-rules/config/routes';
import { PerformanceRules } from 'modules/performance-rules/pages';
import {
    externalPath as recommendationsExternalPath,
    MODULE_NAME as RECOMMENDATIONS_MODULE,
    ModuleComponent as RecommendationsManagement,
    NavigationItemContent as RecommendationsNavigationItemContent,
    permissions as RECOMMENDATIONS_MGMT_PERMISSIONS,
} from 'modules/recommendations';
import { MODULE_NAME as SCHEDULED_REPORTS_MODULE } from 'modules/scheduled-reports/config/routes';
import { ScheduledReports } from 'modules/scheduled-reports/pages';
import {
    MODULE_NAME as SEARCH_ADD_KEYWORD_MODULE,
    ModuleComponent as SearchAdsKeywordComponent,
    permissions as SEARCH_ADD_KEYWORD_PERMISSIONS,
} from 'modules/search-ads-keyword';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import {
    BACKSTAGE_CONTENT_PERMISSION,
    FETCH_USER_SUCCESS,
    isInternalUser,
    PERMISSIONS_OPERATORS,
} from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MODULE_NAME as TRACKING_MODULE, Tracking } from 'modules/tracking';
import {
    externalPath as userManagementExternalPath,
    MODULE_NAME as USER_MGMT_MODULE,
    ModuleComponent as UserManagement,
    permissions as USER_MGMT_PERMISSIONS,
} from 'modules/user-management';
import { USER_TYPE } from 'services/constants';
import { generateAccountFormPath } from '../modules/accounts/config';
import { AudiencesNavigationItemContent } from '../modules/audiences/components/NavigationItemContent/AudiencesNavigationItemContent';
import { TrackingNavigationItemContent } from '../modules/tracking/components/NavigationItemContent/TrackingNavigationItemContent';

const config = {
    routingMap: {
        [CAMPAIGNS_MODULE]: {
            id: CAMPAIGNS_MODULE,
            groupId: CAMPAIGNS_MODULE,
            icon: CampaignOutlinedIcon,
            component: Campaigns,
            isDefault: true,
            permissions: {
                permissions: ['CAMPAIGN_EDIT', 'CAMPAIGN_VIEW'],
                operator: PERMISSIONS_OPERATORS.OR,
            },
            caption: {
                id: 'navigation.caption.campaigns',
                default: 'Campaigns',
            },
            componentProps: {
                id: CAMPAIGNS_MODULE,
            },
            visibilityCondition: ({ selectedAccount: { type, partnerTypes }, permissions }) =>
                permissions['ADS_CONSOLE_VIDEO_CAMPAIGNS'] ||
                isNetworkAccountType(type) ||
                !isVideoAccount(partnerTypes),
        },
        [ACCOUNTS_MODULE]: {
            id: ACCOUNTS_MODULE,
            groupId: ACCOUNTS_MODULE,
            component: Accounts,
            isDefault: true,
            hideNavItem: true,
            href: ({ selectedAccount: { accountName } }) => generateAccountFormPath(accountName),
            permissions: {
                permissions: ['ACCOUNT_EDIT'],
                operator: PERMISSIONS_OPERATORS.OR,
            },
            caption: {
                id: 'navigation.caption.accounts',
                default: 'Account Management',
            },
            componentProps: {
                key: ACCOUNTS_MODULE,
                id: ACCOUNTS_MODULE,
            },
            accountConfigurations: { [FEATURE_FLAGS.ACCOUNT_MANAGEMENT_ENABLED]: 'true' },
        },
        [RECOMMENDATIONS_MODULE]: {
            id: RECOMMENDATIONS_MODULE,
            groupId: RECOMMENDATIONS_MODULE,
            icon: CheckCircleOutlinedIcon,
            component: RecommendationsManagement,
            navigationItemContent: RecommendationsNavigationItemContent,
            isDefault: false,
            visibilityCondition: () => false,
            permissions: RECOMMENDATIONS_MGMT_PERMISSIONS,
            caption: {
                id: 'navigation.caption.recommendations',
                default: 'Recommendations',
            },
            componentProps: {
                key: RECOMMENDATIONS_MODULE,
                id: RECOMMENDATIONS_MODULE,
                externalPath: recommendationsExternalPath,
            },
        },
        [TRACKING_MODULE]: {
            id: TRACKING_MODULE,
            groupId: TRACKING_MODULE,
            icon: TrackChangesOutlinedIcon,
            component: Tracking,
            isDefault: false,
            navigationItemContent: TrackingNavigationItemContent,
            visibilityCondition: ({ selectedAccount: { type } }) =>
                !(isAdminNetworkAccountType(type) || isGroupAccountType(type)),
            permissions: ['TABOOLA_PIXEL_MODULE_VIEWER'],
            caption: {
                id: 'navigation.caption.tracking',
                default: 'Tracking',
            },
            componentProps: {
                key: TRACKING_MODULE,
                id: TRACKING_MODULE,
            },
        },
        [AUDIENCES_MODULE]: {
            id: AUDIENCES_MODULE,
            groupId: AUDIENCES_MODULE,
            icon: PersonOutlineOutlinedIcon,
            component: Audiences,
            isDefault: false,
            navigationItemContent: AudiencesNavigationItemContent,
            visibilityCondition: ({ selectedAccount: { type } }) =>
                !(isAdminNetworkAccountType(type) || isGroupAccountType(type)),
            permissions: ['AUDIENCE_EDIT', BACKSTAGE_CONTENT_PERMISSION],
            operator: PERMISSIONS_OPERATORS.AND,
            caption: {
                id: 'navigation.caption.audiences',
                default: 'Audiences',
            },
            componentProps: {
                key: AUDIENCES_MODULE,
                id: AUDIENCES_MODULE,
            },
        },
        [AUDIENCE_INSIGHTS_MODULE]: {
            id: AUDIENCE_INSIGHTS_MODULE,
            groupId: AUDIENCES_MODULE,
            icon: PersonOutlineOutlinedIcon,
            component: AudienceInsights,
            isDefault: false,
            permissions: ['ADS_CONSOLE_VIEW_AUDIENCE_INSIGHTS'],
            caption: {
                id: 'navigation.caption.audience-insights',
                default: 'Audience Insights',
            },
            componentProps: {
                id: AUDIENCE_INSIGHTS_MODULE,
            },
            visibilityCondition: ({ selectedAccount: { type } }) =>
                !(isAdminNetworkAccountType(type) || isGroupAccountType(type)),
        },
        [PERFORMANCE_RULES_MODULE]: {
            id: PERFORMANCE_RULES_MODULE,
            groupId: PERFORMANCE_RULES_MODULE,
            icon: props => <MediationOutlinedIcon fontSize="small" {...props} />,
            component: PerformanceRules,
            isDefault: false,
            accountConfigurations: { [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLED]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) =>
                !(isAdminNetworkAccountType(type) || isGroupAccountType(type)),
            caption: {
                id: 'navigation.caption.performance.rules',
                default: 'Rules',
            },
            componentProps: {
                key: PERFORMANCE_RULES_MODULE,
                id: PERFORMANCE_RULES_MODULE,
            },
        },
        [SCHEDULED_REPORTS_MODULE]: {
            id: SCHEDULED_REPORTS_MODULE,
            groupId: SCHEDULED_REPORTS_MODULE,
            icon: EmailOutlinedIcon,
            component: ScheduledReports,
            isDefault: false,
            caption: {
                id: 'navigation.caption.scheduledReports',
                default: 'Reports',
            },
            componentProps: {
                key: SCHEDULED_REPORTS_MODULE,
                id: SCHEDULED_REPORTS_MODULE,
            },
            visibilityCondition: ({ accountConfig, permissionsMap, selectedAccount: { type, partnerTypes } }) =>
                (accountConfig[FEATURE_FLAGS.SCHEDULED_REPORTS_VIEW] === 'true' ||
                    permissionsMap['ADS_CONSOLE_SCHEDULED_REPORTS_VIEW']) &&
                !(
                    isAdminNetworkAccountType(type) ||
                    isGroupAccountType(type) ||
                    (!isNetworkAccountType(type) && isVideoAccount(partnerTypes))
                ),
        },
        [LIGHT_ACCOUNT_MGMT_MODULE]: {
            id: LIGHT_ACCOUNT_MGMT_MODULE,
            groupId: LIGHT_ACCOUNT_MGMT_MODULE,
            hideNavItem: true,
            component: LightAccountManagement,
            isDefault: false,
            permissions: LIGHT_ACCOUNT_MGMT_PERMISSIONS,
            componentProps: {
                key: LIGHT_ACCOUNT_MGMT_MODULE,
                id: LIGHT_ACCOUNT_MGMT_MODULE,
                externalPath: lightAccountManagementExternalPath,
            },
        },
        [ACCOUNT_MGMT_MODULE]: {
            id: ACCOUNT_MGMT_MODULE,
            groupId: ACCOUNT_MGMT_MODULE,
            hideNavItem: true,
            component: AccountManagement,
            isDefault: false,
            permissions: ACCOUNT_MGMT_PERMISSIONS,
            componentProps: {
                key: ACCOUNT_MGMT_MODULE,
                id: ACCOUNT_MGMT_MODULE,
                externalPath: accountManagementExternalPath,
            },
        },
        [USER_MGMT_MODULE]: {
            id: USER_MGMT_MODULE,
            groupId: USER_MGMT_MODULE,
            hideNavItem: true,
            component: UserManagement,
            isDefault: false,
            permissions: USER_MGMT_PERMISSIONS,
            componentProps: {
                key: USER_MGMT_MODULE,
                id: USER_MGMT_MODULE,
                externalPath: userManagementExternalPath,
            },
        },
        [BILLING_AND_PAYMENTS_MODULE]: {
            id: BILLING_AND_PAYMENTS_MODULE,
            groupId: BILLING_AND_PAYMENTS_MODULE,
            hideNavItem: true,
            component: BillingAndPaymentsModulePage,
            isDefault: false,
            visibilityCondition: billingAndPaymentVisibilityCondition,
            caption: {
                id: 'navigation.caption.billing-and-payments',
                default: 'Billing & Payments',
            },
            componentProps: {
                id: BILLING_AND_PAYMENTS_MODULE,
            },
        },
        [CAMPAIGN_REVIEW_MODULE]: {
            id: CAMPAIGN_REVIEW_MODULE,
            groupId: CAMPAIGN_REVIEW_MODULE,
            hideNavItem: true,
            component: CampaignReviewManagement,
            isDefault: false,
            permissions: CAMPAIGN_REVIEW_PERMISSIONS,
            componentProps: {
                key: CAMPAIGN_REVIEW_MODULE,
                id: CAMPAIGN_REVIEW_MODULE,
                externalPath: campaignReviewExternalPath,
            },
        },
        [CONTACT_SUPPORT_MODULE]: {
            id: CONTACT_SUPPORT_MODULE,
            groupId: CONTACT_SUPPORT_MODULE,
            hideNavItem: true,
            component: ContactSupport,
            isDefault: false,
            permissions: CONTACT_SUPPORT_PERMISSIONS,
            visibilityCondition: ({ selectedAccount: { tier }, accountConfig }) =>
                isLowTouchAccount(tier) || accountConfig?.[FEATURE_FLAGS.FEEDBACK_FORM_IS_VISIBLE] === 'true',
            accountConfigurations: { [FEATURE_FLAGS.CONTACT_SUPPORT_CAN_BE_VISIBLE]: 'true' },
            componentProps: {
                key: CONTACT_SUPPORT_MODULE,
                id: CONTACT_SUPPORT_MODULE,
                externalPath: contactSupportExternalPath,
            },
        },
        [SEARCH_ADD_KEYWORD_MODULE]: {
            id: SEARCH_ADD_KEYWORD_MODULE,
            component: SearchAdsKeywordComponent,
            permissions: SEARCH_ADD_KEYWORD_PERMISSIONS,
            accountConfigurations: { [FEATURE_FLAGS.ALLOW_SEARCH_ADS_REPORTS]: 'true' },
            componentProps: { id: SEARCH_ADD_KEYWORD_MODULE },
            caption: {
                id: 'navigation.caption.search-ads-keyword',
                default: 'Search Ads Keyword',
            },
        },
        [ONBOARDING_MODULE]: {
            id: ONBOARDING_MODULE,
            component: Onboarding,
            hidden: true,
            hideHeader: true,
            permissions: ['CAMPAIGN_VIEW'],
        },
    },
    externalApps: {
        helpCenter: {
            id: 'app.externalApps.helpCenter',
            hrefId: 'app.externalApps.helpCenter.href',
            color: '#00CB84',
        },
        trends: {
            id: 'app.externalApps.trends',
            href: 'https://trends.taboola.com/',
            color: '#2A3F4F',
        },
        marketingPartners: {
            id: 'app.externalApps.marketingPartners',
            href: 'https://marketing-partners.taboola.com/',
            color: '#84BBF9',
        },
        backstage: {
            id: 'app.externalApps.backstage',
            hrefId: 'app.externalApps.backstage.href',
            color: '#2180C0',
            visibilityCondition: ({ accountConfig, permissionsMap }) => {
                const shouldCheckForPermissions =
                    accountConfig?.[FEATURE_FLAGS.ENABLE_EXTERNAL_APPS_PERMISSIONS] === 'true';
                if (!shouldCheckForPermissions) {
                    return true;
                }
                return permissionsMap['ENABLE_BACKSTAGE_FROM_ADS_CONSOLE'];
            },
        },
        offstage: {
            id: 'app.externalApps.offstage',
            href: 'https://offstage.taboola.com',
            color: '#CF7299',
            permissions: CAMPAIGN_REVIEW_PERMISSIONS,
        },
        salesforce: {
            id: 'app.externalApps.salesforce',
            href: 'https://taboola.lightning.force.com/lightning/page/home',
            color: '#45CED6',
            permissions: CAMPAIGN_REVIEW_PERMISSIONS,
        },
        searchKeywordTool: {
            id: 'app.externalApps.searchKeywordTool',
            href: 'https://search-ads-keyword.taboolasyndication.com',
            color: '#706AA6',
            permissions: CAMPAIGN_REVIEW_PERMISSIONS,
        },
        featureRequests: {
            id: 'app.externalApps.featureRequests',
            href: 'https://tbla.atlassian.net/jira/polaris/projects/TAP/ideas/view/2892811',
            color: '#4D86EC',
            permissions: CAMPAIGN_REVIEW_PERMISSIONS,
        },
        customerHub: {
            id: 'app.externalApps.customerHub',
            href: ({ selectedAccount: { successPlanUrl } }) => successPlanUrl,
            color: '#706AA6',
            visibilityCondition: ({ selectedAccount: { successPlanUrl } }) => {
                return successPlanUrl;
            },
        },
    },
    legal: {
        privacy: {
            title: <FormattedMessage id="app.legal.privacy" />,
            hrefId: 'app.legal.privacy.href',
        },
        termOfUse: {
            title: <FormattedMessage id="app.legal.termOfUse" />,
            hrefId: 'app.legal.termOfUse.href',
        },
        cookiesConsent: {
            Component: CookieConsent,
            title: 'app.legal.cookieConsent',
            hrefId: 'app.legal.termOfUse.href',
        },
    },
    metrics: {
        options: {
            autoTrackPageView: false,
            useTrackBinding: false,
        },
        config: {
            vendors: [
                {
                    name: 'GTM Tracker',
                    api: {
                        track: gtmTracker.trackEvent,
                    },
                },
            ],
        },
    },
    analyticsEventCreators: {
        [FETCH_USER_SUCCESS]: action => ({
            event: GTM_EVENTS.USER,
            value: isInternalUser(action.payload) ? USER_TYPE.INTERNAL : USER_TYPE.EXTERNAL,
            userId: action.payload.user_id,
            accountId: action.payload.account_id,
        }),
        ...campaignsConfig.analyticsEventCreators,
    },
};

export default config;
