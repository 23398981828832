export const convertToAppEventContext = event => {
    const { context, ...restEvent } = event;
    const appContext = {
        module: context.module,
        accountId: context.globalAccountName,
        campaignId: context.campaignId,
        userId: context.userId,
        metadata: context.metadata,
    };
    const formContext = context.mode
        ? {
              accountId: context.accountId,
              mode: context.mode,
              entity: context.entity,
              entityId: context.entityId,
          }
        : null;
    const newEvent = {
        ...restEvent,
        appContext,
        formContext,
    };

    return newEvent;
};
