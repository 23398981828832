import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { map, parseInt } from 'lodash';
import { DropdownMenu, Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { VTA_LOOKBACK_WINDOW_INPUT_TYPE } from 'modules/tracking/components/VtaLookBackWindowField/VtaLookbackWindowInputType';
import styles from 'modules/tracking/components/VtaLookBackWindowField/VtaLookbackWindowInputWithDropdown/VtaLookbackWindowInputWithDropdown.module.scss';
import { useVtaLookBackWindowValue } from '../UseVtaLookBackWindowValue';
import { useVtaLookBackWindowField } from '../useVtaLookBackWindowField';

const WithMessage = withIndication(({ children, className }) => <div className={className}>{children}</div>);
const classNameBuilder = classnames.bind(styles);

export const VtaLookbackWindowInputWithDropdown = ({ validations, isDisabled }) => {
    const {
        lookBackFormFieldType: { value: vtaLookBackWindowType, onChange: onVtaLookBackWindowTypeChange },
    } = useVtaLookBackWindowField();

    const lookBackInitialValue = useVtaLookBackWindowValue(true);
    const lookBackMaxValue = useVtaLookBackWindowValue();

    const maxThreshold = useMemo(
        () => lookBackMaxValue[vtaLookBackWindowType],
        [lookBackMaxValue, vtaLookBackWindowType]
    );

    const initialValue = useMemo(
        () => lookBackInitialValue[vtaLookBackWindowType],
        [lookBackInitialValue, vtaLookBackWindowType]
    );

    const validationDependencies = useMemo(() => {
        return {
            isDisabled,
            maxThreshold,
            vtaLookBackWindowType: (
                <FormattedMessage
                    id={`tracking.conversion.properties.vta.window.${vtaLookBackWindowType}`}
                    defaultMessage="hours"
                />
            ),
        };
    }, [maxThreshold, vtaLookBackWindowType, isDisabled]);

    const {
        value: lookBackWindow,
        onChange: onLookBackWindowChange,
        indicationData,
        scrollRef: inputScrollRef,
    } = useFormValidatedValue({
        field: 'vtaLookBackWindow.value',
        validations,
        formFieldDependencies: { value: initialValue },
        validationDependencies,
    });
    const valueChangeHandler = useEventValueHandler(onLookBackWindowChange);

    const lookBackWindowMapping = {
        [VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS]: () => Math.max(1, parseInt(lookBackWindow / 24)),
        [VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS]: () =>
            Math.min(lookBackWindow * 24, lookBackInitialValue[VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS]),
    };

    const dropdownChangeHandler = ({ value: type }) => {
        onVtaLookBackWindowTypeChange(type);
        onLookBackWindowChange(lookBackWindowMapping[type] || lookBackInitialValue[type]);
    };
    const VtaLookbackWindowTypeOptions = map(
        Object.values(VTA_LOOKBACK_WINDOW_INPUT_TYPE).map(key => ({
            value: key,
            label: (
                <FormattedMessage
                    id={`tracking.conversion.properties.vta.window.dropdown.${key}`}
                    defaultMessage={key}
                />
            ),
        }))
    );

    const dropDownStyles = {
        control: provided => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            height: '100%',
        }),
    };

    return (
        <WithMessage
            className={classNameBuilder('container', { error: indicationData.indicationType === 'error' })}
            {...indicationData}
        >
            <DropdownMenu
                id="dropdown-vta-lookback-window"
                dropdownWrapperClassName={styles['dropdown-wrapper']}
                selectedValueObject={{ value: vtaLookBackWindowType }}
                styles={dropDownStyles}
                className={styles['dropdown']}
                options={VtaLookbackWindowTypeOptions}
                onChange={dropdownChangeHandler}
                blurInputOnSelect={false}
                openMenuOnFocus
                disabled={isDisabled}
            />
            <div className={styles['divider']} />
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <Input
                        id="vta-look-back-window-with-dropdown-input"
                        type={InputTypes.NUMBER}
                        placeholder={placeholder}
                        value={lookBackWindow}
                        onChange={valueChangeHandler}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="vtaLookBackWindow.value"
                        ref={inputScrollRef}
                        inputClass={styles['input']}
                        disabled={isDisabled}
                    />
                )}
            </FormattedNumber>
        </WithMessage>
    );
};
