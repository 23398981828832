import React from 'react';
import { FormField, EditableText } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { withInputValidations } from 'modules/taboola-common-frontend-modules/validations';
import groupNameValidations from '../../../../../config/validations/campaigns-group/groupNameValidations';
import styles from './campaignsGroupNameEdit.module.scss';

const EditableTextWithValidations = withInputValidations(withIndication(EditableText), groupNameValidations);

const CampaignsGroupNameEdit = () => {
    const { value, onChange } = useFormFieldValue({ field: 'campaignsGroupName', throttleValue: true });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'campaigns.group.name.placeholder',
        defaultMessage: 'Type group name',
    });

    return (
        <FormField inputId="campaign-group-name" containerClass={styles['form-field-container']}>
            <EditableTextWithValidations
                id="campaignsGroupName"
                className={styles['edit-campaigns-group-container']}
                inputClassName={styles['edit-campaigns-group-value']}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                iconName="edit-o"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="CampaignsGroupNameEdit"
            />
        </FormField>
    );
};

export default CampaignsGroupNameEdit;
