import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useExcelBulkCommonMetadata } from './useExcelBulkCommonMetadata';
import { useParsedExcelFile } from './useParsedExcelFile';
import { useParsedZipFile } from './useParsedZipFile';

export const useUploadableFiles = () => {
    const { formatMessage } = useIntl();
    const commonMetadata = useExcelBulkCommonMetadata();

    const {
        file,
        excelWorkbook,
        allFileNames,
        onSelectFile,
        onRemoveFile,
        failedValidationData,
        isParsingWorkbook,
        isValidationsRunning: isExcelValidationsRunning,
    } = useParsedExcelFile({ formatMessage, commonMetadata });

    const { zipFile, onSelectZipFile, onRemoveZipFile, failedZipValidationData, isParsingZip } = useParsedZipFile({
        formatMessage,
        excelWorkbook,
        allFileNames,
    });

    return {
        file,
        onSelectFile,
        onRemoveFile,
        failedValidationData,
        isParsingWorkbook,

        zipFile,
        onSelectZipFile,
        onRemoveZipFile,
        failedZipValidationData,
        isParsingZip,
        isExcelValidationsRunning,
    };
};
