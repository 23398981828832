import { useCallback, useEffect } from 'react';
import { uniqueId, isEmpty } from 'lodash';
import { useFormValidatedValue } from '../../../../taboola-common-frontend-modules/validations';
import pixelBasedUrlConditionValidations from '../validations/pixelBasedUrlConditionValidations';

const generateUniqueId = () => {
    return uniqueId('condition');
};

const usePixelBasedUrlConditions = (predicate, url, setUrl) => {
    const {
        value: conditions,
        onChange: onConditionsChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'conditions',
        validations: pixelBasedUrlConditionValidations,
    });

    useEffect(
        () => onConditionsChange(conditions.map(condition => ({ id: generateUniqueId(), ...condition }))),
        [conditions, onConditionsChange]
    );

    const handleAddCondition = useCallback(() => {
        if (!isEmpty(url)) {
            const conditionToAdd = {
                id: generateUniqueId(),
                predicate,
                url: url?.trim(),
            };

            onConditionsChange([...conditions, conditionToAdd]);
            setUrl('');
        }
    }, [predicate, url, setUrl, onConditionsChange, conditions]);

    const handleRemoveCondition = useCallback(
        ({ id: idToRemove }) => {
            onConditionsChange(conditions => conditions.filter(({ id }) => id !== idToRemove));
        },
        [onConditionsChange]
    );

    const handleRemoveAllConditions = useCallback(() => onConditionsChange([]), [onConditionsChange]);

    return {
        conditions,
        handleAddCondition,
        handleRemoveCondition,
        handleRemoveAllConditions,
        indicationData,
        scrollRef,
    };
};

export default usePixelBasedUrlConditions;
