import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import {
    DATE,
    CVR,
    AVERAGE_CPC,
    CONVERSIONS,
    CONVERSIONS_CLICKS,
    CONVERSIONS_VIEWS,
    CVR_CLICKS,
    CVR_VIEWS,
} from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { defaultColumns, customColumns } from './reportPresets';

export const todayRealtimeReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.TODAY],
    endpoint: 'realtime-campaign-report-by-hour-performance',
    graph,
    rowIdField: DATE.field,
    lastUpdateTimeField: DATE.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    sortMap: {
        [DATE.field]: 'date',
        [CVR.field]: 'cpaConversionRate',
        [AVERAGE_CPC.field]: 'cpc',
        [CONVERSIONS.field]: 'cpaActionsNum',
        [CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.PLATFORM,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.COUNTRY,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.SITE,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
    ],
};

export default todayRealtimeReportConfig;
