import React, { useCallback } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { FORM_MODES } from '../../../../../config';
import { useSiteTargeting } from './useSiteTargeting';

const deleteModalMessages = {
    title: (
        <FormattedMessage id="app.modal.deleteAccountBlockedSite.title" tagName="h2" defaultMessage="Are You Sure?" />
    ),
    formProps: {
        submitButtonText: (
            <FormattedMessage id="app.modal.deleteAccountBlockedSite.button.positive" defaultMessage="Yes, I'm Sure" />
        ),
        cancelButtonText: (
            <FormattedMessage id="app.modal.deleteAccountBlockedSite.button.negative" defaultMessage="Cancel" />
        ),
    },
    content: (
        <FormattedMessage
            id="app.modal.deleteAccountBlockedSite.content"
            defaultMessage="Removing this site from the account level block list will enable it to run on all of your campaigns."
        />
    ),
};

export const siteListKey = 'account-blocked-sites';

export const useAccountBlockedSites = () => {
    const { mode } = useFormDataContext();
    const readonly = mode !== FORM_MODES.EDIT;
    const { open: openModal } = useModal();
    const { deleteOne: deleteOneRaw, ...rest } = useSiteTargeting({
        field: 'blockedSitesOnAccountLevel',
        readonly,
        siteListKey,
    });

    const deleteOne = useCallback(
        async ({ value }) => {
            const result = await openModal(deleteModalMessages);

            if (!result) {
                return;
            }

            deleteOneRaw({ value });
        },
        [openModal, deleteOneRaw]
    );

    return {
        deleteOne,
        ...rest,
    };
};
