import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const AggregatedConversionTooltip = () => {
    return (
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.properties.aggregation.type.tooltip"
                defaultMessage="Last Value aggregation type allows reporting of only the most recent conversion value. In most cases, it is recommended to keep the default selection and to use Aggregated aggregation type."
            />
        </TooltipSection>
    );
};
