import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import { generateBulkActionsOnDuplicateHandler } from '../audience/generateBulkActionsOnDuplicateHandler';
import columnDefinitions from './columnDefinitions';
import graph from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const contextualReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CONTEXTUAL],
    endpoint: 'campaign-report-by-context',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    isEmptyFooter: true,
    searchPlaceholderMsgId: 'app.reports.byContext.search.placeholder',
    searchField: FIELDS.AUDIENCE_NAME.field,
    sortMap: {
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    bulkActions: [
        {
            key: BULK_ACTION.DUPLICATE,
            handler: generateBulkActionsOnDuplicateHandler,
            buttonContent: (
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.duplicate.contextual"
                    defaultMessage="Duplicate Campaign Using Contextual Targeting"
                />
            ),
        },
    ],
    filters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP100, label: 'Top 100', messageId: 'report.topResults.100' }],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
    ],
};

export default contextualReportConfig;
