import { invert, identity } from 'lodash';
import { REPORT_TYPE, getDefaultReportId, resolveReportIdByCampaignDimension } from '../../../../config';

const INVERTED_REPORT_TYPE = invert(REPORT_TYPE);

export const getMatchedReportId = ({ reportId = '', dimension, isReportVisible = identity } = {}) => {
    const reportTypeTwin = resolveReportIdByCampaignDimension(reportId, dimension);
    const newReportId = reportId !== reportTypeTwin && INVERTED_REPORT_TYPE[reportTypeTwin] && reportTypeTwin;
    if (isReportVisible(newReportId)) {
        return newReportId;
    }

    const defaultReportId = getDefaultReportId(dimension);
    if (defaultReportId === REPORT_TYPE.CUSTOM_CONTEXTUAL && !isReportVisible(REPORT_TYPE.CUSTOM_CONTEXTUAL)) {
        return REPORT_TYPE.PIXEL_BASED;
    }
    return defaultReportId;
};
