import React from 'react';
import { FormattedMessage, FormattedNumber } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './spentProgressBar.module.scss';

export const SpentProgressBar = ({ spentAmount, credit, currency, progressBarTextMessageId }) => {
    return (
        <div className={styles['progress-bar-container']}>
            <progress className={styles['progress-bar']} value={spentAmount} max={credit} />
            <div className={styles['progress-bar-text']}>
                <FormattedMessage
                    id={progressBarTextMessageId}
                    values={{
                        spentAmount: (
                            <FormattedNumber
                                value={spentAmount}
                                variant="currency"
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                            />
                        ),
                        credit: (
                            <FormattedNumber
                                value={credit}
                                variant="currency"
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                            />
                        ),
                    }}
                />
            </div>
        </div>
    );
};
