import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import BusinessModel from './BusinessModel';
import BusinessModelFieldTooltip from './BusinessModelFieldTooltip';
import styles from '../radioBtnComponents.module.scss';

export const BusinessModelField = () => {
    return (
        <FormField
            inputId="businessModel"
            label={<FormattedMessage id="video.campaign.editor.BusinessModel.title" defaultMessage="Business Model" />}
            description={
                <FormattedMessage
                    id="video.campaign.editor.BusinessModel.description"
                    defaultMessage="Select the desired pricing model. It can be either vCPM, CPM or CPCV."
                />
            }
            helpText={<BusinessModelFieldTooltip />}
            containerClass={styles['input']}
        >
            <BusinessModel />
        </FormField>
    );
};

BusinessModelField.propTypes = {
    account: PropTypes.object,
};

export default BusinessModelField;
