import React from 'react';
import { NoticeSection } from 'modules/campaigns/components/NoticeSection/NoticeSection';
import { ExcelUpload } from 'modules/campaigns/components/Uploaders/ExcelUpload';
import { ZipUpload } from 'modules/campaigns/components/Uploaders/ZipUpload';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useUploadableFiles } from '../../../hooks/useUploadableFiles';
import styles from './bulkUpload.module.scss';

export const BulkUpload = () => {
    const {
        file,
        onSelectFile,
        onRemoveFile,
        failedValidationData,
        isParsingWorkbook,

        zipFile,
        onSelectZipFile,
        onRemoveZipFile,
        failedZipValidationData,
        isParsingZip,
    } = useUploadableFiles();

    return (
        <div>
            <FormattedMessage
                className={styles['description']}
                id="excel.bulk.bulk.upload.description"
                defaultMessage="Upload an excel file to create/update multiple entities at once."
            />
            <ExcelUpload
                isLoading={isParsingWorkbook}
                onSelectFile={onSelectFile}
                onRemoveFile={onRemoveFile}
                file={file}
                failedValidationData={failedValidationData}
            />
            <div className={styles['separation-line']} />
            <FormattedMessage
                className={styles['description']}
                id="excel.bulk.zip.upload.description"
                defaultMessage="Upload a zip file with media items. File names must match the file names in the excel above."
            />
            <ZipUpload
                isLoading={isParsingZip}
                onSelectFile={onSelectZipFile}
                onRemoveFile={onRemoveZipFile}
                file={zipFile}
                failedValidationData={failedZipValidationData}
            />
            <NoticeSection
                containerClassName={styles['guidelines']}
                title={
                    <FormattedMessage
                        id="campaign.creator.bulk.upload.excel.guidelines.title"
                        defaultMessage="Excel Guidelines"
                    />
                }
                description={
                    <FormattedMessage
                        id="campaign.creator.bulk.upload.excel.guidelines.message"
                        defaultMessage="Download the template file and follow instructions."
                    />
                }
            />
        </div>
    );
};
