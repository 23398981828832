'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _IndicationContent = require('../IndicationContent/IndicationContent');

var _IndicationContent2 = _interopRequireDefault(_IndicationContent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'indicationContentWithDescription-module__taboola-svg-icon___164xx',
    'description': 'indicationContentWithDescription-module__description___2d3pk'
};


var classNameBuilder = _bind2.default.bind(styles);

var IndicationContentWithDescription = function IndicationContentWithDescription(_ref) {
    var children = _ref.children,
        contentClassName = _ref.contentClassName,
        containerClassName = _ref.containerClassName,
        displayIcon = _ref.displayIcon,
        type = _ref.type,
        iconTypeOverride = _ref.iconTypeOverride,
        description = _ref.description,
        descriptionClassName = _ref.descriptionClassName;
    return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
            _IndicationContent2.default,
            {
                contentClassName: contentClassName,
                containerClassName: containerClassName,
                displayIcon: displayIcon,
                type: type,
                iconTypeOverride: iconTypeOverride
            },
            children
        ),
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('description', descriptionClassName) },
            description
        )
    );
};

IndicationContentWithDescription.propTypes = Object.assign({}, _IndicationContent2.default.propTypes, {
    /** For adding text under content rendered w/ icon ie. description or footer */
    description: _propTypes2.default.node,
    descriptionClassName: _propTypes2.default.string
});

exports.default = IndicationContentWithDescription;