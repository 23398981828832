import { updateCreatives } from 'modules/campaigns/flows';
import { editedCreativeSelector } from 'modules/campaigns/selectors';
import getChangesObject from 'modules/taboola-common-frontend-modules/utils/objectDiff';

const saveFocalPoints =
    (creative, accountId, matchingCreativeIds, suppressSuccessIndication = true) =>
    async (dispatch, getState) => {
        const creativeBeingDuplicated = editedCreativeSelector(getState());
        const creativeChanges = getChangesObject(creative, creativeBeingDuplicated);
        const { creativeFocus } = creativeChanges;

        if (matchingCreativeIds.length > 0 && creativeFocus) {
            return await dispatch(
                updateCreatives(accountId, matchingCreativeIds, { creativeFocus }, { suppressSuccessIndication })
            );
        }
        return {};
    };

export default saveFocalPoints;
