import { REPORT_TYPE } from 'modules/campaigns/config';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';

const defaultColumns = [
    FIELDS.BILLING_DATE.field,
    FIELDS.INVOICE_NAME.field,
    FIELDS.INVOICE_AMOUNT.field,
    FIELDS.DUE_DATE.field,
    FIELDS.PAYMENT_STATUS.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(REPORT_TYPE.INVOICES, field));

export { defaultColumns };
