import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import styles from './asyncDropdownLoader.module.scss';

const classNameBuilder = classnames.bind(styles);

const AsyncDropdownLoader = ({ options }) => {
    const containerClassNames = classNameBuilder('container', {
        'top-border': options && options.length,
    });
    return <Spinner className={containerClassNames} size={21} />;
};

AsyncDropdownLoader.propTypes = {
    options: PropTypes.array,
};

export default AsyncDropdownLoader;
