import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './creativePreviewNote.module.scss';

const CreativePreviewNote = ({ isCreativeCreator, items }) => {
    if (isCreativeCreator || !items[0].description) {
        return null;
    }

    return (
        <div className={styles['description-note']}>
            <FormattedMessage
                id="creative.preview.description.note"
                defaultMessage="* Note: Description is currently not supported on all placement types."
            />
        </div>
    );
};

export default CreativePreviewNote;
