import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useCampaignGroupApi } from 'services/api';
import { fetchCampaignGroupListSuccess } from '../../../../campaigns-group/actions/fetchCampaignGroupList';

export const DEFAULT_SELECTED_CAMPAIGN_GROUPS = [];

export const useCampaignGroupDropdownData = (campaignGroups = DEFAULT_SELECTED_CAMPAIGN_GROUPS) => {
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const dispatch = useDispatch();
    const { getCampaignGroupList } = useCampaignGroupApi();
    const [rawOptions, setOptions] = useState([]);
    const options = useMemo(() => {
        const campaignGroupsSet = new Set(map(campaignGroups, 'id'));
        return rawOptions.map(option => option || {}).filter(option => !campaignGroupsSet.has(option?.value));
    }, [rawOptions, campaignGroups]);
    const loadPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getCampaignGroupList(accountName, { page, pageSize, searchText });
            const list = results.map(({ id: value, name: label, status }) => ({ value, label, status }));
            dispatch(fetchCampaignGroupListSuccess(results));

            return [list, total, results.length - list.length];
        },
        [accountName, getCampaignGroupList, dispatch]
    );

    return { options, setOptions, loadPage };
};
