import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { STEP_DATA, SUB_STEP_DATA, LOCAL_STORAGE_ACCOUNT_KEYS } from '../../consts';
import { useOnboardingLocalStorage } from '../useOnboardingLocalStorage';

const { COMPLETION_DATA } = LOCAL_STORAGE_ACCOUNT_KEYS;
const { REVIEW_CAMPAIGN_DATA } = STEP_DATA;
const { HAS_VISIT_SITE_REPORT } = SUB_STEP_DATA;

export const useReviewCampaignData = ({ accountId }) => {
    const [localCompletionData] = useOnboardingLocalStorage({
        key: COMPLETION_DATA,
    });
    const storageHasVisitSiteReport = get(localCompletionData, `${REVIEW_CAMPAIGN_DATA}.${HAS_VISIT_SITE_REPORT}`);

    const [hasVisitSiteReport, setHasVisitSiteReport] = useState(false);
    const [loadedDataAccountId, setLoadedDataAccountId] = useState(-1);

    useEffect(() => {
        setHasVisitSiteReport(storageHasVisitSiteReport);
        setLoadedDataAccountId(accountId);
    }, [storageHasVisitSiteReport, accountId]);

    const isLoaded = accountId === loadedDataAccountId;

    const completeStep = useCallback(() => {
        setHasVisitSiteReport(true);
    }, []);

    return {
        [REVIEW_CAMPAIGN_DATA]: { [HAS_VISIT_SITE_REPORT]: isLoaded ? hasVisitSiteReport : false },
        completeStep,
    };
};
