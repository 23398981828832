import { TabList as LibTabList } from 'react-tabs';
import styled from 'styled-components';

export const TabList = styled(LibTabList)`
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
`;

TabList.tabsRole = 'TabList';
