'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.identity');

var _lodash2 = _interopRequireDefault(_lodash);

var _LegendRow = require('./LegendRow');

var _LegendRow2 = _interopRequireDefault(_LegendRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'fontFamily': 'var(--regular-font-stack)',
    'fontSize': '1.2rem',
    'yAxisColor': 'var(--gray-7)',
    'xAxisColor': 'var(--gray-8)',
    'activeDotFill': 'var(--white)',
    'cartesianGridColor': 'var(--gray-4)',
    'yAxisFontWeight': '500',
    'xAxisFontWeight': '500',
    'singleYAxisColor': 'var(--gray-4)',
    'taboola-svg-icon': 'legend__taboola-svg-icon___UV52m',
    'graph-container': 'legend__graph-container___2-e3y',
    'responsive-graph-container': 'legend__responsive-graph-container___3XK88',
    'overlay-container': 'legend__overlay-container___JE8Z_',
    'label': 'legend__label___3WI4i',
    'bar-preview': 'legend__bar-preview___2IIAY',
    'bar-preview-animation': 'legend__bar-preview-animation___2a7Xl',
    'invisible': 'legend__invisible___2vNE0',
    'container': 'legend__container___TdB85',
    'metrics-container': 'legend__metrics-container___IKJ0e',
    'metrics': 'legend__metrics___WWArv',
    'footer': 'legend__footer___2FiJH'
};


var Legend = function Legend(_ref) {
    var config = _ref.config,
        edges = _ref.edges,
        previewedEdge = _ref.previewedEdge,
        onTogglePreview = _ref.onTogglePreview,
        _onToggle = _ref.onToggle,
        values = _ref.values,
        maxAllowedSelectedEdges = _ref.maxAllowedSelectedEdges,
        renderTooltipContent = _ref.renderTooltipContent,
        disablePreviewForSelected = _ref.disablePreviewForSelected,
        footerText = _ref.footerText,
        header = _ref.header;

    var shouldDisableUnselected = (0, _react.useMemo)(function () {
        return Object.values(edges).filter(_lodash2.default).length >= maxAllowedSelectedEdges;
    }, [edges, maxAllowedSelectedEdges]);

    // This effect is needed when we are close to limit and on toggle doesn't have mouse event
    (0, _react.useEffect)(function () {
        if (shouldDisableUnselected) {
            onTogglePreview('', false);
        }
    }, [shouldDisableUnselected]);

    var togglePreviewWrapper = (0, _react.useCallback)(function (name, isPreviewAvailable) {
        if (shouldDisableUnselected) {
            return;
        }
        onTogglePreview(name, isPreviewAvailable);
    }, [onTogglePreview, shouldDisableUnselected]);

    return _react2.default.createElement(
        'div',
        { className: styles['container'] },
        header,
        _react2.default.createElement(
            'div',
            { className: styles['metrics-container'] },
            _react2.default.createElement(
                'div',
                { className: styles['metrics'] },
                config.map(function (_ref2, index) {
                    var name = _ref2.name,
                        label = _ref2.label,
                        payload = _ref2.payload,
                        color = _ref2.color,
                        legendFormatter = _ref2.legendFormatter,
                        toggleComponent = _ref2.toggleComponent,
                        disableLegendRow = _ref2.disableLegendRow,
                        disableLegendTooltip = _ref2.disableLegendTooltip;

                    var toggled = edges[name];
                    var active = toggled || name === previewedEdge;
                    return _react2.default.createElement(_LegendRow2.default, {
                        key: name + '-switch',
                        onTogglePreview: togglePreviewWrapper,
                        toggled: toggled,
                        onToggle: function onToggle() {
                            return _onToggle(name);
                        },
                        name: name,
                        label: label,
                        color: color,
                        formatter: legendFormatter,
                        active: active,
                        payload: payload,
                        value: values[name],
                        isFirst: !index,
                        renderTooltipContent: renderTooltipContent,
                        disabled: disableLegendRow || !active && shouldDisableUnselected,
                        disablePreviewForSelected: disablePreviewForSelected,
                        toggleComponent: toggleComponent,
                        disableTooltip: disableLegendTooltip
                    });
                })
            ),
            _react2.default.createElement(
                'div',
                { className: styles['footer'] },
                footerText
            )
        )
    );
};

Legend.propTypes = {
    config: _propTypes2.default.arrayOf(_propTypes2.default.shape({
        name: _propTypes2.default.string,
        label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
        color: _propTypes2.default.string,
        active: _propTypes2.default.bool,
        payload: _propTypes2.default.object,
        formatter: _propTypes2.default.func,
        toggleComponent: _propTypes2.default.any,
        disableLegendRow: _propTypes2.default.bool,
        disableLegendTooltip: _propTypes2.default.bool
    })),
    edges: _propTypes2.default.object,
    previewedEdge: _propTypes2.default.string,
    onTogglePreview: _propTypes2.default.func,
    onToggle: _propTypes2.default.func,
    values: _propTypes2.default.object,
    maxAllowedSelectedEdges: _propTypes2.default.number,
    renderTooltipContent: _propTypes2.default.func,
    disablePreviewForSelected: _propTypes2.default.bool,
    footerText: _propTypes2.default.node,
    header: _propTypes2.default.node
};

exports.default = Legend;