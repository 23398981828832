import {
    REQUEST_UPDATE_PERFORMANCE_RULE,
    UPDATE_PERFORMANCE_RULE_ERROR,
    UPDATE_PERFORMANCE_RULE_SUCCESS,
} from './actionTypes';

export const requestUpdatePerformanceRule = () => ({ type: REQUEST_UPDATE_PERFORMANCE_RULE });

export const updatePerformanceRuleSuccess = rule => ({
    type: UPDATE_PERFORMANCE_RULE_SUCCESS,
    payload: rule,
});

export const updatePerformanceRuleError = error => ({ type: UPDATE_PERFORMANCE_RULE_ERROR, payload: error });
