import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { config, logout } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage, useLocale } from 'modules/taboola-common-frontend-modules/i18n';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n/hooks';
import { useModuleName } from '../../hooks';
import { FEATURE_FLAGS, useAccount, useConfigMatch } from '../taboola-common-frontend-modules/account-configurations';
import { HISTORY_METHOD } from '../taboola-common-frontend-modules/query-params';
import BackstageIframe from './HybridModuleIframe';
import { useHybridUrlSrc } from './hooks/useHybridUrlSrc';
import { generateBackstageUrl } from './utils';

export const hybridModuleFactory = (
    componentPath,
    { externalMessageHandlerProp, urlGenerator = generateBackstageUrl, iframeProps } = {}
) => {
    const BackstageModule = ({ id }) => {
        const { formatMessage } = useIntl();
        const dispatch = useDispatch();
        const isQueryParamsEnabled = useConfigMatch({
            [FEATURE_FLAGS.HYBRID_MODULE_QUERY_PARAMS_ENABLED]: 'true',
        });
        const [locale] = useLocale();
        const { id: accountId } = useAccount();
        const [url, setUrl] = useHybridUrlSrc();
        const moduleName = useModuleName();
        const mountedModule = useRef();
        const [iframeSrc, setIframeSrc] = useState();

        const changeHandler = useCallback(data => setUrl(data.url), [setUrl]);
        const logoutHandler = useCallback(() => dispatch(logout()), [dispatch]);
        const errorHandler = useCallback(() => {
            const error = {
                message: <FormattedMessage id="navigation.hybrid.failure" values={{ href: iframeSrc }} />,
                type: INDICATION_TYPES.WARNING,
            };

            dispatch(addIndication(error));
        }, [dispatch, iframeSrc]);
        const externalMessageHandler = useCallback(
            props => {
                if (externalMessageHandlerProp) {
                    dispatch(externalMessageHandlerProp(props));
                }
            },
            [dispatch]
        );
        const generatedUrl = useMemo(
            () =>
                urlGenerator({
                    config,
                    accountId,
                    componentPath,
                    locale,
                    isQueryParamsEnabled,
                }),
            [accountId, locale, isQueryParamsEnabled]
        );

        useEffect(
            function initUrlIfNotExists() {
                if (url) {
                    if (!iframeSrc) {
                        setIframeSrc(url);
                    }
                    return;
                }

                setUrl(generatedUrl, HISTORY_METHOD.REPLACE);
                setIframeSrc(generatedUrl);
            },
            [url, iframeSrc, generatedUrl, setUrl]
        );

        useEffect(
            function updateUrlWithAccountAndLocale() {
                if (mountedModule.current !== moduleName) {
                    mountedModule.current = moduleName;
                    return;
                }

                setUrl(generatedUrl);
                setIframeSrc(generatedUrl);
            },
            [generatedUrl, moduleName, setUrl]
        );

        return iframeSrc ? (
            <BackstageIframe
                url={iframeSrc}
                title={formatMessage({ id: `navigation.caption.${id}` })}
                onUrlChange={changeHandler}
                onLogout={logoutHandler}
                onError={errorHandler}
                externalMessageHandler={externalMessageHandler}
                iframeProps={iframeProps}
            />
        ) : null;
    };

    BackstageModule.propTypes = { id: PropTypes.string };

    return BackstageModule;
};
