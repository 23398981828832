import { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { APP_EVENT_TYPE } from '../appEventType';

const TIMEOUT = 30_000;

export const useIdleAppEvents = (push, timeout = TIMEOUT) => {
    const onIdle = useCallback(() => push({ type: APP_EVENT_TYPE.USER_GET_INACTIVE }), [push]);
    const onActive = useCallback(() => push({ type: APP_EVENT_TYPE.USER_GET_ACTIVE }), [push]);

    useIdleTimer({ onIdle, onActive, timeout });
};
