import React, { forwardRef, SVGAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { UIC } from 'tuui/lib/utils/typing';

type CircleProgressIndicatorVariant = 'primary' | 'secondary';

export interface CircleProgressIndicatorProps {
    children?: React.ReactNode;
    progress?: number;
    variant?: CircleProgressIndicatorVariant;
    strokeWidth?: number;
    radius?: number;
    axisOffset?: number;
}

export const CircleProgressIndicator = forwardRef<
    SVGSVGElement,
    SVGAttributes<SVGElement> & CircleProgressIndicatorProps
>(
    (
        { children, progress = 0, variant = 'secondary', strokeWidth = 35, radius = 175, axisOffset = -25, ...rest },
        ref
    ) => {
        const diameter = Math.round(Math.PI * radius * 2);
        const getOffset = (val = 0) => Math.round(((100 - Math.min(val, 100)) / 100) * diameter);
        const strokeDashoffset = getOffset(progress);
        const theme = useTheme();
        const progressStrokeColor = theme.palette[variant].stroke;
        const backgroundStrokeColor = theme.palette.grayscale[2];

        return (
            <svg
                {...rest}
                viewBox="-25 -25 400 400"
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={progress}
                ref={ref}
            >
                <circle
                    stroke={backgroundStrokeColor}
                    cx={radius}
                    cy={radius}
                    r={radius}
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                <AnimatedCircle
                    stroke={progressStrokeColor}
                    transform={`rotate(-90 ${radius} ${radius})`}
                    cx={radius}
                    cy={radius}
                    r={radius}
                    strokeDasharray={1100}
                    strokeWidth={strokeWidth}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="butt"
                    fill="none"
                />
                {children ? (
                    <StyledForeignObject x={axisOffset} y={axisOffset}>
                        <div>{children}</div>
                    </StyledForeignObject>
                ) : null}
            </svg>
        );
    }
) as UIC<CircleProgressIndicatorProps>;

const StyledForeignObject = styled.foreignObject`
    width: 100%;
    height: 100%;

    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const AnimatedCircle = styled.circle`
    transition-property: stroke-dashoffset;
    transition-duration: var(
        --circle-progress-transition-duration,
        ${({ theme }) => theme.transitions.duration.complex}s
    );
    transition-timing-function: var(
        --circle-progress-transition-timing-function,
        ${({ theme }) => theme.transitions.easing.easeOut}
    );
    transition-delay: var(--circle-progress-transition-delay, ${({ theme }) => theme.transitions.delays.instant}s);
`;
