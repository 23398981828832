import React, { useState } from 'react';
import { DeleteOutlinedIcon, ContentCopyOutlinedIcon } from 'tuui';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { usePermissions } from '../../../../../../taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import { TARGETING_TYPES } from '../../../config';
import { TargetingBox } from '../../TargetingBox';
import SiteTargetingListItem from '../SiteTargetingListItem/SiteTargetingListItem';
import { ALLOW_INCLUDE_SITES_PERMISSION, API_BLOCK_SITES_PERMISSION } from '../const';
import { useExcludedSites } from '../hooks/useExcludedSites';
import { useIncludedSites } from '../hooks/useIncludedSites';
import { useSiteClipboardOperations } from '../hooks/useSiteClipboardOperations';
import { useSiteDropdownData } from '../hooks/useSiteDropdownData';
import styles from './includedExcludedSites.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
const ITEM_HEIGHT = 40;
const itemPlaceholder = (
    <FormattedMessage id="campaign.editor.targeting.site.select.placeholder" defaultMessage="Select Sites..." />
);
const footerButtonMessage = (
    <FormattedMessage id="campaign.editor.targeting.site.multiline.footer.button" defaultMessage="Add Sites" />
);
const footerErrorMessage = (
    <FormattedMessage
        id="campaign.editor.targeting.site.multiline.footer.error.noResultsFound"
        defaultMessage="The following values are invalid."
    />
);
const submitBtnMetricsAttributes = {
    'data-metrics-event-name': GTM_EVENTS.USABILITY,
    'data-metrics-component': 'Block Sites',
    'data-metrics-value': 'Add Sites',
};

export const IncludedExcludedSites = () => {
    const hasEditSitePermission = usePermissions(API_BLOCK_SITES_PERMISSION);
    const hasEditIncludeSitePermission = usePermissions([API_BLOCK_SITES_PERMISSION, ALLOW_INCLUDE_SITES_PERMISSION]);
    const [targetingType, onChangeTargetingType] = useState(TARGETING_TYPES.EXCLUDE);
    const { options, setOptions, loadPage } = useSiteDropdownData(targetingType === TARGETING_TYPES.INCLUDE);
    const {
        isDirty: isDirtyIncluded,
        siteItems: includedSiteItems,
        addOne: addOneIncluded,
        addMany: addManyIncluded,
        deleteOne: deleteOneIncluded,
        deleteMany: deleteManyIncluded,
        clearAll: clearAllIncluded,
        allowedSites: allowedSitesIncluded,
        isAllowedSitesReady: isAllowedSitesReadyIncluded,
        failedValidationData: includeFailedValidationData,
    } = useIncludedSites(onChangeTargetingType, hasEditIncludeSitePermission);
    const {
        isDirty: isDirtyExcluded,
        siteItems: excludedSiteItems,
        addOne: addOneExcluded,
        addMany: addManyExcluded,
        deleteOne: deleteOneExcluded,
        deleteMany: deleteManyExcluded,
        clearAll: clearAllExcluded,
        allowedSites: allowedSitesExcluded,
        isAllowedSitesReady: isAllowedSitesReadyExcluded,
        failedValidationData: excludeFailedValidationData,
        scrollRef: excludeScrollRef,
    } = useExcludedSites(onChangeTargetingType);
    const { copySitesToClipboard, submitMultiline, hasError, setHasError, submittedValuesCount } =
        useSiteClipboardOperations(
            targetingType === TARGETING_TYPES.INCLUDE
                ? { list: includedSiteItems, addList: addManyIncluded, deleteList: deleteManyIncluded }
                : {
                      list: excludedSiteItems,
                      addList: addManyExcluded,
                      deleteList: deleteManyExcluded,
                  }
        );

    const canChangeTargetingType = !(isDirtyExcluded || isDirtyIncluded) && hasEditIncludeSitePermission;
    const canEditIncludedList = !isDirtyExcluded && hasEditIncludeSitePermission;
    const canEditExcludedList = !isDirtyIncluded && hasEditSitePermission;

    return (
        <>
            <TargetingBox
                msgIdPrefix="campaign.editor.targeting.site.dropdown.option"
                id="sites-targeting-select"
                itemType={targetingType}
                onSelectItemType={({ value }) => onChangeTargetingType(value)}
                onChangeTargetingType={({ value }) => onChangeTargetingType(value)}
                isItemTypeDisabled={!canChangeTargetingType}
                itemSelectionDisabled={!hasEditSitePermission}
                dropdownPlaceholder={itemPlaceholder}
                allItems={options}
                onOptionsLoaded={setOptions}
                loadPage={loadPage}
                onAddItem={value =>
                    targetingType === TARGETING_TYPES.INCLUDE ? addOneIncluded(value) : addOneExcluded(value)
                }
                footerErrorMessage={footerErrorMessage}
                footerButtonMessage={footerButtonMessage}
                submitBtnMetricsAttributes={submitBtnMetricsAttributes}
                submitMultiline={submitMultiline}
                hasError={hasError}
                setHasError={setHasError}
                submittedValuesCount={submittedValuesCount}
                isClipboardEnabled
            />
            <CollapsibleListWithIndication
                clearAllLabel={<DeleteOutlinedIcon aria-label="clear-all-included" />}
                data-testid="site-included-list"
                items={includedSiteItems}
                deleteItem={canEditIncludedList ? deleteOneIncluded : null}
                clearItems={canEditIncludedList ? clearAllIncluded : null}
                ItemComponent={SiteTargetingListItem}
                itemHeight={ITEM_HEIGHT}
                listHeaderClass={styles['list-header']}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.site.included.header"
                        defaultMessage="Targeted for this Campaign"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                copyToClipboard={() => copySitesToClipboard(includedSiteItems)}
                copyToClipboardLabel={<ContentCopyOutlinedIcon aria-label="copy-all-included" />}
                metadata={{
                    isIncludedItem: true,
                    isAllowedSitesReady: isAllowedSitesReadyIncluded,
                    allowedSites: allowedSitesIncluded,
                    readonly: !canEditIncludedList,
                }}
                {...includeFailedValidationData}
            />
            <CollapsibleListWithIndication
                ref={excludeScrollRef}
                clearAllLabel={<DeleteOutlinedIcon aria-label="clear-all-excluded" />}
                data-testid="site-excluded-list"
                items={excludedSiteItems}
                ItemComponent={SiteTargetingListItem}
                deleteItem={canEditExcludedList ? deleteOneExcluded : null}
                itemHeight={ITEM_HEIGHT}
                clearItems={canEditExcludedList ? clearAllExcluded : null}
                listHeaderClass={styles['list-header']}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.site.campaign.header"
                        defaultMessage="Blocked for this Campaign"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                copyToClipboard={() => copySitesToClipboard(excludedSiteItems)}
                copyToClipboardLabel={<ContentCopyOutlinedIcon aria-label="copy-all-excluded" />}
                metadata={{
                    isAllowedSitesReady: isAllowedSitesReadyExcluded,
                    allowedSites: allowedSitesExcluded,
                    readonly: !canEditExcludedList,
                }}
                {...excludeFailedValidationData}
            />
        </>
    );
};
