import { RESET_STATE_TO_INITIAL } from '../../../actions';
import { createReducer } from '../../taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from '../../taboola-common-frontend-modules/utils/updateObject';
import SET_FATAL_ERROR from '../actions/actionTypes';

const initialState = {
    error: false,
    detail: {},
};

const errorReducer = createReducer(initialState, {
    [SET_FATAL_ERROR]: (state, action) => updateObject(state, { error: true, detail: action.payload }),
    [RESET_STATE_TO_INITIAL]: () => initialState,
});

export default errorReducer;
