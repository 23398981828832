import React from 'react';

export const CartesBancaires = () => {
    return (
        <svg width="32" height="21" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient x1="84.571%" y1="-2.163%" x2="20.485%" y2="100%" id="b">
                    <stop stopColor="#002253" offset="0%" />
                    <stop stopColor="#0082B1" offset="24.255%" />
                    <stop stopColor="#0E9641" offset="100%" />
                </linearGradient>
                <clipPath id="a" clipPathUnits="userSpaceOnUse">
                    <rect fill="#00f" height="21" rx="2" width="32" />
                </clipPath>
            </defs>
            <g fill="none" fillRule="evenodd" clipPath="url(#a)">
                <path fill="url(#b)" d="M0 0h42v32H0z" />
                <path
                    d="M17.06 5.506v4.206h6.714a2.103 2.103 0 0 0 0-4.206H17.06zm-.572 4.206c-.039-.827-.245-1.57-.593-2.2a4.174 4.174 0 0 0-1.65-1.65c-.701-.387-1.541-.599-2.483-.599h-.89c-.941 0-1.78.212-2.482.6a4.174 4.174 0 0 0-1.65 1.65c-.387.7-.6 1.54-.6 2.482 0 .942.213 1.781.6 2.482a4.17 4.17 0 0 0 1.65 1.65c.701.388 1.54.6 2.483.6h.89c.94 0 1.78-.212 2.482-.6a4.174 4.174 0 0 0 1.65-1.65c.348-.63.554-1.371.593-2.199h-5.009v-.566h5.009zm.572.566v4.206h6.714a2.103 2.103 0 0 0 0-4.206H17.06z"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
};
