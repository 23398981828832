import { useSelectedAccount } from 'hooks';
import { ACCOUNT_TIER } from '../../account-management/accountType';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from '../../taboola-common-frontend-modules/account-configurations';
import { usePermissions } from '../../taboola-common-frontend-modules/authentication';

export const useIsScheduleMeetingPermitted = () => {
    const isSchedulePermittedPermission = usePermissions('SCHEDULE_TRACKING_MEETING');
    const isSchedulePermittedFF = useConfigMatch({ [FEATURE_FLAGS.IS_SCHEDULE_TRACKING_EXPERT_ENABLED_TA]: 'true' });

    const { [COMMON_FLAGS.PERMITTED_TIMEZONES_FOR_SCHEDULE_MEETING]: permittedTimezones } = useCommonConfig([
        COMMON_FLAGS.PERMITTED_TIMEZONES_FOR_SCHEDULE_MEETING,
    ]);

    const [{ timeZoneName, tier }] = useSelectedAccount();
    const isAllowedTier = tier !== ACCOUNT_TIER.LOW_TOUCH && tier !== ACCOUNT_TIER.LOW_TOUCH_PLUS;
    const timezonePermitted = permittedTimezones.includes(timeZoneName);
    return isSchedulePermittedPermission && (timezonePermitted || isSchedulePermittedFF) && isAllowedTier;
};
