import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';

const KeywordsTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.external-audience.keywords.tooltip"
                defaultMessage="To build a list of relevant keywords, add ideas below. Keywords can trigger your ad to show when people search for related terms."
            />
        </TooltipSection>
    </div>
);

export default KeywordsTooltip;
