'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PaginatedDropdownProvider = PaginatedDropdownProvider;
exports.usePaginatedDropdownContext = usePaginatedDropdownContext;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _scrollbars = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'scrollbarSize': '11px'
};
var _variables = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px'
};

var _styleUtils = require('../../../style-utils');

var _textSize = require('./textSize');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PaginatedDropdownContext = (0, _react.createContext)();

function PaginatedDropdownProvider(_ref) {
    var width = _ref.width,
        _ref$scrollBarWidth = _ref.scrollBarWidth,
        scrollBarWidth = _ref$scrollBarWidth === undefined ? parseInt(_scrollbars.scrollbarSize, 10) : _ref$scrollBarWidth,
        _ref$fontSize = _ref.fontSize,
        fontSize = _ref$fontSize === undefined ? parseInt((0, _styleUtils.remToPx)(_variables.fontSizeMedium), 10) : _ref$fontSize,
        totalItemCount = _ref.totalItemCount,
        loadNextPage = _ref.loadNextPage,
        isItemLoaded = _ref.isItemLoaded,
        _ref$paddingX = _ref.paddingX,
        paddingX = _ref$paddingX === undefined ? 12 : _ref$paddingX,
        _ref$paddingY = _ref.paddingY,
        paddingY = _ref$paddingY === undefined ? 14 : _ref$paddingY,
        itemHeight = _ref.itemHeight,
        children = _ref.children;

    var calculateTextHeight = (0, _react.useCallback)(function (text) {
        var includeScrollbar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        return (0, _textSize.getTextHeight)(text, {
            textBlockWidth: width - (includeScrollbar ? scrollBarWidth : 0) - 2 * paddingX,
            fontSize: fontSize
        }) + paddingY;
    }, [width, paddingX, paddingY, fontSize, scrollBarWidth]);
    var estimatedHeight = (0, _react.useMemo)(function () {
        return calculateTextHeight('text');
    }, [calculateTextHeight]);
    var ctx = (0, _react.useMemo)(function () {
        return {
            width: width,
            fontSize: fontSize,
            paddingX: paddingX,
            paddingY: paddingY,
            estimatedHeight: itemHeight || estimatedHeight,
            itemHeight: itemHeight,
            totalItemCount: totalItemCount,
            loadNextPage: loadNextPage,
            isItemLoaded: isItemLoaded,
            calculateTextHeight: calculateTextHeight
        };
    }, [width, fontSize, paddingX, paddingY, itemHeight, estimatedHeight, totalItemCount, loadNextPage, isItemLoaded, calculateTextHeight]);

    return _react2.default.createElement(
        PaginatedDropdownContext.Provider,
        { value: ctx },
        children
    );
}

PaginatedDropdownProvider.propTypes = {
    width: _propTypes2.default.number.isRequired,
    scrollBarWidth: _propTypes2.default.number,
    fontSize: _propTypes2.default.number,
    totalItemCount: _propTypes2.default.number.isRequired,
    paddingX: _propTypes2.default.number,
    paddingY: _propTypes2.default.number,
    itemHeight: _propTypes2.default.number,
    loadNextPage: _propTypes2.default.func,
    isItemLoaded: _propTypes2.default.func,
    children: _propTypes2.default.node
};

function usePaginatedDropdownContext() {
    return (0, _react.useContext)(PaginatedDropdownContext);
}