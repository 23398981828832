import { cloneDeep } from 'lodash';
import { VIDEO_CREATIVE_CREATION_METHOD } from '../config/CreationMethod';

export const transformVideoCreativeToGW = creative => {
    if (creative.mediaType !== VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO) {
        return creative;
    }
    const result = cloneDeep(creative);

    result.videoUploadAssets.videoFileName = result.videoItem.file.name;
    delete result.videoItem;
    if (result.logoItem?.file) {
        result.videoUploadAssets.logo = { ...result.videoUploadAssets.logo, fileName: result.logoItem.file.name };
        delete result.logoItem;
    }

    return result;
};

export const transformVideoCreativeFromGW = creative => {
    if (creative.mediaType !== VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO) {
        return creative;
    }
    const result = cloneDeep(creative);

    result.videoItem = {
        file: { name: result.videoUploadAssets.videoFileName, trusted: true },
        uploadedData: result.videoUploadItems,
    };
    if (result.videoUploadAssets.logo) {
        result.logoItem = {
            file: { name: result.videoUploadAssets.logo.fileName, trusted: true },
            uploadedData: result.videoUploadItems,
        };
    }

    return result;
};
