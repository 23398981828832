import React from 'react';
import { useHasAiWizardOnboarding } from '../../../AiVariationsContextProvider/hooks/useHasAiWizardOnboarding';
import { AiVariationsUrlField } from './AiVariationsUrlField';
import { TitlesGenerationDetailsForm } from './TitlesGenerationDetailsForm';
import styles from '../../../../creativeCreator.module.scss';

export const AiVariationsSetupSection = () => {
    const hasAiWizardOnboarding = useHasAiWizardOnboarding();
    return (
        <>
            <AiVariationsUrlField className={styles['field']} />
            {hasAiWizardOnboarding ? null : <TitlesGenerationDetailsForm />}
        </>
    );
};
