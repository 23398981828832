import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { isNaN } from 'lodash';
import { StatusIndicator } from 'tuui';
import {
    DeletableItem,
    DelimiterWrapper,
    Dotdotdot,
    TooltipV2 as Tooltip,
    useCollapsibleListContext,
    StyledButton,
    AlertOIcon,
} from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl, LocalCurrencyInput } from 'modules/taboola-common-frontend-modules/i18n';
import useValidations from 'modules/taboola-common-frontend-modules/validations/hooks/useValidations';
import FORM_MODES, { getCampaignStatusType, getStatusIndicatorType } from '../../../../../config';
import { CampaignItemTooltip } from '../CampaignItemTooltip/CampaignItemTooltip';
import { itemValidations } from '../hooks/useSharedBudgetCampaignsValidations';
import commonStyles from '../../../../common-campaign-form/components/commonAudienceListItemContent.module.scss';
import styles from './campaignBudgetListItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const DeleteButton = ({ className, ...rest }) => {
    const buttonClassName = classNameBuilder('delete-button', className);

    return (
        <span>
            <Tooltip arrow>
                <FormattedMessage
                    id="campaigns.group.editor.sharedBudgetCampaigns.delete.tooltip"
                    defaultMessage="Pause and remove campaign from this group."
                />
            </Tooltip>
            <StyledButton {...rest} className={buttonClassName} />
        </span>
    );
};

export const CampaignBudgetListItem = ({ item }) => {
    const { id, name, budgetLimit: campaignBudgetLimit, status } = item;
    const {
        updateItem,
        deleteItem,
        metadata: { marketingObjective, groupBudget, budgetType },
    } = useCollapsibleListContext();
    const validationDependencies = useMemo(
        () => ({
            marketingObjective,
            groupBudget,
        }),
        [groupBudget, marketingObjective]
    );
    const valueToValidate = useMemo(() => [item], [item]);
    const { isDirty, mode } = useFormFieldValue({ field: 'campaigns' });
    const {
        failedValidationData: { message: failedMessage },
    } = useValidations({
        validations: itemValidations,
        validationId: `campaigns.${id}`,
        valueToValidate,
        validationDependencies,
        isDirty,
        hasInitialData: mode !== FORM_MODES.CREATE,
    });
    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);
    const handleBudgetLimitChange = useCallback(
        budgetLimit => {
            const parsedBudgetLimit = parseFloat(budgetLimit);
            updateItem({
                ...item,
                budgetLimit: isNaN(parsedBudgetLimit) ? null : parsedBudgetLimit,
            });
        },
        [item, updateItem]
    );
    const handleBudgetLimitChangeEvent = useEventValueHandler(handleBudgetLimitChange);
    const { formatMessage } = useIntl();
    const placeholder = formatMessage(
        {
            id: 'campaigns.group.editor.sharedBudgetCampaigns.budgetLimit',
        },
        {
            type: formatMessage({
                id: `app.campaigns.campaign.budget.type.${budgetType}`,
            }),
        }
    );
    const statusType = getCampaignStatusType(status);
    const statusIndicatorType = getStatusIndicatorType(statusType);

    return (
        <DeletableItem onDelete={onDelete} DeleteButton={DeleteButton}>
            <div className={styles['container']}>
                <DelimiterWrapper>
                    <div className={styles['label-container']}>
                        <div className={styles['label']}>
                            <div className={styles['indicator']}>
                                {statusIndicatorType && <StatusIndicator type={statusIndicatorType} />}
                            </div>
                            <CampaignItemTooltip status={status} arrow={false} />
                            <Dotdotdot lines={2}>
                                <span>{`${name} (${id})`}</span>
                            </Dotdotdot>
                        </div>
                        {failedMessage && (
                            <span className={commonStyles['error-icon-wrapper']}>
                                <AlertOIcon className={commonStyles['error-icon']} />
                                <Tooltip delay={800}>{failedMessage}</Tooltip>
                            </span>
                        )}
                    </div>
                    <LocalCurrencyInput
                        placeholder={placeholder}
                        value={campaignBudgetLimit}
                        onChange={handleBudgetLimitChangeEvent}
                        mainClass={styles['input-container']}
                        inputClass={styles['input']}
                        inputWrapperClass={styles['input-wrapper']}
                    />
                </DelimiterWrapper>
            </div>
        </DeletableItem>
    );
};
