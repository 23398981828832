import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import { REPORT_TYPE } from '../../../campaigns/config';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import AudienceNameField from '../AudienceNameField/AudienceNameField';
import { CrmFileDataTypeField } from '../CrmFileDataTypeField/CrmFileDataTypeField';

export const FirstPartyAudienceSetupSection = () => {
    const { mode, value: crmFileType } = useFormFieldValue({ field: 'crmFileType' });

    if (crmFileType === undefined || crmFileType === null) {
        return null;
    }

    return (
        <CommonCollapsibleCard
            id="FIRST_PARTY_AUDIENCE_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.setup.section.title"
                            defaultMessage="Audience Setup"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && <AudienceNameField />}
            <br />
            <CrmFileDataTypeField reportType={REPORT_TYPE.FIRST_PARTY} />
            <br />
        </CommonCollapsibleCard>
    );
};

export default FirstPartyAudienceSetupSection;
