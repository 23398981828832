import { useMemo } from 'react';
import { useAvailableItems } from 'hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { dictionaryConfig } from '../config/dictionaryConfig';

export const useAvailableDictionarySheets = () => {
    const selectedAccount = useAccount();
    const conditionData = useMemo(() => ({ selectedAccount }), [selectedAccount]);

    return useAvailableItems(dictionaryConfig.sheets, conditionData);
};
