'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useOnClickOutside = undefined;

var _react = require('react');

var useOnClickOutside = exports.useOnClickOutside = function useOnClickOutside(ref, handler) {
    (0, _react.useEffect)(function () {
        var listener = function listener(event) {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};