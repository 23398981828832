import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SiteTargetingList } from './SiteTargetingList/SiteTargetingList';
import SiteTargetingTooltip from './SiteTargetingTooltip/SiteTargetingTooltip';
import styles from '../commonEditor.module.scss';

export const SiteTargetingField = () => {
    const isUserViewPermitted = usePermissions('API_BLOCK_SITES_VIEW');

    if (!isUserViewPermitted) {
        return null;
    }

    return (
        <FormField
            inputId="site-targeting"
            label={
                <FormattedMessage id="campaign.editor.targeting.site.title" defaultMessage="Block Sites (optional)" />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.site.description"
                    defaultMessage="Block specific sites based on performance or brand safety."
                />
            }
            helpText={<SiteTargetingTooltip />}
            containerClass={styles['input']}
        >
            <SiteTargetingList />
        </FormField>
    );
};
