import { noop } from 'lodash';
import { authTokenManager } from 'modules/taboola-common-frontend-modules/authentication/services/AuthTokenManager';
import { config as VersionsConfig } from 'modules/taboola-common-frontend-modules/version';
import ApiError from '../../modules/errors/ApiError';
import { gwSignatureManager } from '../../modules/taboola-common-frontend-modules/authentication/services/GWSignatureManager';
import { ERROR_CODES } from '../api/apiConstants';
import { customFetch } from './customFetch';
import { isOnline } from './isOnline';
import { getDataWithResponseMetadata, getRrandFormResponse } from './responseParserUtils';
import { waitNetworkConnection } from './waitNetworkConnection';

const parseJSONResponse = async response => {
    try {
        const parsedData = await response.json();
        const data = getDataWithResponseMetadata(parsedData, response);

        return data;
    } catch (e) {
        throw new ApiError({
            message: 'Something went wrong. Please try again later or <a>contact support</a>',
            status: ERROR_CODES.BAD_REQUEST,
            offendingField: null,
            messageCode: 'app.common.failure',
            rrand: getRrandFormResponse(response),
        });
    }
};

export const callAjax = async (url, options, skipJsonParse = false) => {
    let response;

    if (authTokenManager.isPendingRedirect()) {
        // we should put on hold unauthenticated calls until app forwarded to the auth page
        return new Promise(noop);
    }

    if (!isOnline()) {
        await waitNetworkConnection(url);
    }

    try {
        response = await customFetch(url, options);
    } catch (e) {
        if (e.name === 'AbortError') {
            throw new ApiError({ message: e.message, status: ERROR_CODES.ABORT });
        }

        throw e;
    }

    VersionsConfig.updateVersion(response);
    VersionsConfig.setGWVersion(response);
    gwSignatureManager.updateSignature(response);

    if (response.status === 200) {
        if (skipJsonParse) {
            return response;
        }
        return parseJSONResponse(response);
    }

    if (response.status === ERROR_CODES.UNAUTHORIZED || response.status === ERROR_CODES.METHOD_NOT_ALLOWED) {
        authTokenManager.redirectOnWrongToken(new Error('Unauthorized'));
    }

    if (response.status >= ERROR_CODES.BAD_REQUEST && response.status < ERROR_CODES.INTERNAL_ERROR) {
        const responseData = await parseJSONResponse(response);
        throw new ApiError({
            message: responseData['message'],
            status: responseData['http_status'],
            offendingField: responseData['offending_field'],
            messageCode: responseData['message_code'],
            messageCodeEnglishTemplate: responseData['message_code_english_template'],
            templateParameters: responseData['template_parameters'],
            rrand: getRrandFormResponse(response),
        });
    }

    throw new Error(response.statusText);
};
