import {
    FETCH_RECOMMENDATIONS_INDICATION_ERROR,
    FETCH_RECOMMENDATIONS_INDICATION_SUCCESS,
    REQUEST_FETCH_RECOMMENDATIONS_INDICATION,
} from './actionTypes';

export const requestFetchRecommendationsIndication = () => ({ type: REQUEST_FETCH_RECOMMENDATIONS_INDICATION });

export const fetchRecommendationsIndicationSuccess = count => ({
    type: FETCH_RECOMMENDATIONS_INDICATION_SUCCESS,
    payload: count,
});

export const fetchRecommendationsIndicationError = error => ({
    type: FETCH_RECOMMENDATIONS_INDICATION_ERROR,
    payload: error,
});
