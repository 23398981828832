'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _windowSize = require('@react-hook/window-size');

var _hooks = require('../../../hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'stickyContent-module__taboola-svg-icon___2CELE',
    'sticker': 'stickyContent-module__sticker___1D6Hc',
    'sticker-content': 'stickyContent-module__sticker-content___1f6Hm'
};


var StickyContent = function StickyContent(_ref) {
    var reportRef = _ref.reportRef,
        children = _ref.children,
        stickerHeight = _ref.stickerHeight,
        eventPublisher = _ref.eventPublisher,
        dataChangeEventName = _ref.dataChangeEventName;

    var windowWidth = (0, _windowSize.useWindowWidth)();

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        setDataTrigger = _useState2[1];

    var stickerRef = (0, _react.useRef)();
    var contentRef = (0, _react.useRef)();
    var reportElement = reportRef && reportRef.current;
    var stickerElement = stickerRef.current;
    var contentElement = contentRef.current;
    var unmountedRef = (0, _react.useRef)();

    // We track unmount state
    (0, _react.useLayoutEffect)(function () {
        return function () {
            unmountedRef.current = true;
        };
    }, []);

    // We subscribe for rowData update processing done event and trigger rerender
    (0, _react.useEffect)(function () {
        if (!eventPublisher || !dataChangeEventName) {
            return;
        }

        var callback = function callback(_ref2) {
            var rowData = _ref2.rowData;

            if (!rowData || unmountedRef.current) {
                return;
            }
            setDataTrigger(function (prevValue) {
                return !prevValue;
            });
        };

        eventPublisher.addEventListener(dataChangeEventName, callback);
        // eslint-disable-next-line
        return function () {
            return eventPublisher.removeEventListener(dataChangeEventName, callback);
        };
    }, [eventPublisher, dataChangeEventName]);

    // Here we are listening report element size

    var _useElementSize = (0, _hooks.useElementSize)(),
        reportWidth = _useElementSize.width,
        setReportElement = _useElementSize.ref;

    (0, _react.useEffect)(function () {
        return setReportElement(reportElement);
    }, [setReportElement, reportElement]);

    // We should align sticky content width by parent depending on window and report widths
    (0, _react.useLayoutEffect)(function () {
        if (!contentElement) {
            return;
        }
        contentElement.style.width = stickerElement.clientWidth + 'px';
    }, [reportWidth, windowWidth, stickerElement, contentElement]);

    return _react2.default.createElement(
        'div',
        { className: styles['sticker'], ref: stickerRef, style: { height: stickerHeight } },
        _react2.default.createElement(
            'div',
            { className: styles['sticker-content'], ref: contentRef, style: { height: stickerHeight } },
            children
        )
    );
};

StickyContent.propTypes = {
    reportRef: _propTypes2.default.object,
    children: _propTypes2.default.node,
    stickerHeight: _propTypes2.default.number,
    rowsCount: _propTypes2.default.number,
    reportData: _propTypes2.default.array,
    eventPublisher: _propTypes2.default.object,
    dataChangeEventName: _propTypes2.default.string
};

exports.default = StickyContent;