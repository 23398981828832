'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'emptyStateOverlay-module__taboola-svg-icon___2Izna',
    'container': 'emptyStateOverlay-module__container___D6-Pc',
    'header': 'emptyStateOverlay-module__header___1AFNC',
    'content': 'emptyStateOverlay-module__content___1zbsK'
};


var EmptyStateOverlay = function EmptyStateOverlay(_ref) {
    var header = _ref.header,
        content = _ref.content,
        className = _ref.className;
    return _react2.default.createElement(
        'div',
        { className: styles['container'] + ' ' + className },
        _react2.default.createElement(
            'div',
            { className: styles['header'] },
            header
        ),
        _react2.default.createElement(
            'div',
            { className: styles['content'] },
            content
        )
    );
};

EmptyStateOverlay.propTypes = {
    /** the Header of the overlay */
    header: _propTypes2.default.node, /** The content of the overlay */
    content: _propTypes2.default.node, /** optional class name to be added to the main container */
    className: _propTypes2.default.string
};

EmptyStateOverlay.defaultProps = {
    header: '', content: '', className: ''
};

exports.default = EmptyStateOverlay;