import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Highlighter } from 'taboola-ultimate-ui';
import styles from './withHighlighter.module.scss';

const withHighlighter = WrappedComponent => {
    const Hoc = ({ value, searchTerm, ...rest }) => (
        <WrappedComponent
            value={<Highlighter className={styles['highlight']} text={value} highlightedText={searchTerm} />}
            {...rest}
        />
    );

    Hoc.propTypes = {
        value: PropTypes.string,
        searchTerm: PropTypes.string,
    };

    const mapStateToProps = state => ({ searchTerm: state.campaignsReducer.searchTerm });

    return connect(mapStateToProps, {})(Hoc);
};

export default withHighlighter;
