import { keyBy } from 'lodash';
import getChangesObject from 'modules/taboola-common-frontend-modules/utils/objectDiff';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';

export const transformCarouselCardToGW = (newCardsData = [], initialCardsData = []) => {
    const initialCardsMap = keyBy(initialCardsData, 'id');

    const submitChanges = newCardsData.map(newCardData => {
        const oldCardData = initialCardsMap[newCardData.id];
        const changesObject = getChangesObject(newCardData, oldCardData);
        return updateObject(changesObject, { id: newCardData.id });
    });
    return {
        hierarchyData: {
            hierarchyChildren: submitChanges,
        },
    };
};
