import React, { useState } from 'react';
import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import { AI_VARIATIONS_FIELD_NAMES } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { fetchAiGeneratedTitleSuggestions } from '../../services/utils';
import ContentTitleAISuggestionsForm from './ContentTitleAISuggestionsForm';
import ContentTitleAISuggestionsResults from './ContentTitleAISuggestionsResults';
import { SuccessIndication } from './SuccessIndication';
import { sendDescriptionOnGenerateGA, sendTitleOnGenerateGA } from './helpers/gaEventHelpers';
import { useSuccessIndicationState } from './hooks/useSuccessIndicationState';

const ContentTitleAISuggestions = ({
    campaignId,
    onChangeCallback,
    collapsed,
    setCollapsed,
    showSuccessIndication,
    successMessageType,
}) => {
    const { value: suggestionsHistory = [], onChange: setSuggestionsHistory } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.SUGGESTIONS_HISTORY,
        isAbsolute: true,
    });
    const { value: selectedSuggestionsPage = 0, onChange: setSelectedSuggestionsPage } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.SUGGESTIONS_PAGE,
        isAbsolute: true,
    });
    const [status, setStatus] = useState(COMPONENT_STATUS.ACTIVE);
    const { isSuccessIndicationVisible, triggerSuccessIndication, dismissSuccessIndication } =
        useSuccessIndicationState(showSuccessIndication);

    const [{ accountId }] = useSelectedAccount();
    const [errorMessage, setErrorMessage] = useState();

    const { value: productDescription = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_DESCRIPTION,
        isAbsolute: true,
    });
    const { value: targetAudience = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_TARGET_AUDIENCE,
        isAbsolute: true,
    });
    const { value: uniqueSellingProposition = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_USP,
        isAbsolute: true,
    });

    const { formatMessage } = useIntl();
    const noResultsMessage = formatMessage({
        id: 'creative.creator.aiTitleSuggestions.noResults',
        defaultMessage:
            'Was unable to generate any titles with these details. Please try again with different details.',
    });

    const onGenerateClick = async (generateType = 'Generate') => {
        sendTitleOnGenerateGA({
            generateType,
            productDescription,
            targetAudience,
            uniqueSellingProposition,
            campaignId,
        });
        sendDescriptionOnGenerateGA({
            generateType,
            productDescription,
            targetAudience,
            uniqueSellingProposition,
            campaignId,
        });

        const { titles, descriptions, error } = await fetchAiGeneratedTitleSuggestions({
            accountId,
            campaignId,
            productDescription,
            targetAudience,
            uniqueSellingProposition,
            setStatus,
        });

        if (error) {
            setErrorMessage(error.toString());
        } else if (isEmpty(titles)) {
            setErrorMessage(noResultsMessage);
        } else {
            const newHistory = [...suggestionsHistory, { titles, descriptions }];
            setSuggestionsHistory(newHistory);
            setSelectedSuggestionsPage(newHistory.length - 1);
            setErrorMessage(null);
        }
    };

    const clearSuggestions = e => {
        setSuggestionsHistory([]);
        setSelectedSuggestionsPage(0);
        e.stopPropagation();
    };

    const showNextSuggestionsPage = () => {
        if (selectedSuggestionsPage >= suggestionsHistory.length - 1) {
            return onGenerateClick('Regenerate');
        }
        setSelectedSuggestionsPage(selectedSuggestionsPage + 1);
    };

    const showPreviousSuggestionsPage = () => {
        if (selectedSuggestionsPage > 0) {
            setSelectedSuggestionsPage(selectedSuggestionsPage - 1);
        }
    };

    return (
        <div>
            {isSuccessIndicationVisible && (
                <SuccessIndication onCloseClick={dismissSuccessIndication} successMessageType={successMessageType} />
            )}
            {!isEmpty(suggestionsHistory) ? (
                <ContentTitleAISuggestionsResults
                    campaignId={campaignId}
                    suggestionsHistory={suggestionsHistory}
                    selectedSuggestionsPage={selectedSuggestionsPage}
                    showNextSuggestionsPage={showNextSuggestionsPage}
                    showPreviousSuggestionsPage={showPreviousSuggestionsPage}
                    clearSuggestions={clearSuggestions}
                    status={status}
                    errorMessage={errorMessage}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    onChangeCallback={onChangeCallback}
                    triggerSuccessIndication={triggerSuccessIndication}
                />
            ) : (
                <ContentTitleAISuggestionsForm
                    campaignId={campaignId}
                    status={status}
                    onGenerateClick={() => onGenerateClick('Generate')}
                    errorMessage={errorMessage}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            )}
        </div>
    );
};

ContentTitleAISuggestions.propTypes = {
    campaignId: PropTypes.number,
    onChangeCallback: PropTypes.func,
    setCollapsed: PropTypes.func,
    showSuccessIndication: PropTypes.bool,
    successMessageType: PropTypes.string,
};

ContentTitleAISuggestions.defaultProps = {
    campaignId: -1,
    onChangeCallback: noop,
    setCollapsed: noop,
    showSuccessIndication: false,
};

export default ContentTitleAISuggestions;
