import React from 'react';
import classnames from 'classnames/bind';
import { SetupIcon } from 'taboola-ultimate-ui';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import CommonCollapsibleCard from '../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { AccountDropdown } from '../../../common-campaign-form';
import { CampaignsField } from '../../components';
import { differentSupplyTargettingValidation } from '../Validations/differentSupplyTargettingValidation';
import styles from './campaignSetupSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CampaignSetupSection = ({ contentMethod, disabled }) => {
    const { formAccount, setFormAccount } = useFormDataContext();

    const { value: creativeCreatorSelectedCampaigns } = useCampaignsFormFieldValue();

    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SetupIcon />}
                    headerText={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                />
            }
            containerClassName={classNameBuilder('collapsible-card')}
        >
            <AccountDropdown
                onAccountChange={setFormAccount}
                accountForCampaign={formAccount}
                helpText={
                    <FormattedMessage
                        id="creative.creator.account.help"
                        defaultMessage="The new ad will be created in the selected account."
                    />
                }
                className={classNameBuilder('accounts-field')}
                disabled={disabled}
            />
            <CampaignsField
                className={classNameBuilder('campaigns-field')}
                selectedCampaigns={creativeCreatorSelectedCampaigns}
                accountForCampaign={formAccount}
                contentMethod={contentMethod}
                disabled={disabled}
                shouldUseValidations
                validations={differentSupplyTargettingValidation}
            />
        </CommonCollapsibleCard>
    );
};
