import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { CheckboxGroup, TextCheckbox, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks';
import { useCreateFormSelectedCampaignId } from 'modules/campaigns/modules/creative-creator/hooks/useCreateFormSelectedCampaignId';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { useAiVariationsEventsPrefix } from '../../../AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import styles from './lpLoadFromUrlCheckbox.module.scss';

const classNameBuilder = classNames.bind(styles);

export const LpLoadFromUrlCheckbox = ({ url, initialValue, className }) => {
    const { fetchPageProductDetails, cancelFetchPageProductDetails } = useAiVariationsContext();
    const campaignId = useCreateFormSelectedCampaignId();
    const [shouldLoadProductDetails, setShouldLoadProductDetails] = useState(initialValue);
    const eventsPrefix = useAiVariationsEventsPrefix();

    const shouldLoadProductDetailsGetter = useCurrentValueGetter(shouldLoadProductDetails);

    const handleLoadFromUrl = newValue => {
        const checked = Boolean(newValue);
        const prev = shouldLoadProductDetailsGetter();

        setShouldLoadProductDetails(checked);
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${eventsPrefix}_Load_Product_Info_From_URL`,
            taboolaCampaignId: campaignId,
            pageName: getPageName(),
            value: checked,
        });
        if (!prev && checked) {
            fetchPageProductDetails(url);
        } else if (prev && !checked) {
            cancelFetchPageProductDetails();
        }
    };
    const disabled = isEmpty(url);

    return (
        <CheckboxGroup
            className={classNameBuilder('checkbox-container', className)}
            onSelectCheckbox={handleLoadFromUrl}
            onUnselectCheckbox={() => handleLoadFromUrl()}
            selectedValues={[shouldLoadProductDetails]}
        >
            <TextCheckbox
                value
                title={
                    <FormattedMessage
                        id="creative.creator.load.page.productDetails"
                        defaultMessage="Load product details from the landing page URL"
                    />
                }
                disabled={disabled}
            />
            {disabled && (
                <Tooltip arrow>
                    <FormattedMessage
                        id="creative.creator.load.page.productDetails.disabled.tooltip"
                        defaultMessage="Please provide a landing page URL to use this feature"
                    />
                </Tooltip>
            )}
        </CheckboxGroup>
    );
};
