import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { DRAWER_MODE, SideDrawer } from 'components';
import { LayerRoute } from '../../../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { COLUMN_PICKER_CREATOR_ROUTE_PATH, COLUMN_PICKER_EDITOR_ROUTE_PATH } from '../../routes';
import { isCustomReportPreset } from '../../utils';
import { ColumnPresetCreator } from './components/ColumnPresetCreator';
import { ColumnPresetEditor } from './components/ColumnPresetEditor';

export const CustomColumnPickerDrawer = ({ closeSideDrawer, reportConfig }) => {
    const creatorMatch = useRouteMatch(COLUMN_PICKER_CREATOR_ROUTE_PATH);
    const editorMatch = useRouteMatch(COLUMN_PICKER_EDITOR_ROUTE_PATH);

    // TODO: Rename the view query param string as part of https://jira.taboola.com/browse/LA-47351
    const reportPresetName = editorMatch?.params?.reportViewName;

    const isOpen = Boolean(creatorMatch || (editorMatch && isCustomReportPreset(reportPresetName)));

    return (
        <SideDrawer openDrawer={isOpen} onClose={closeSideDrawer} drawerMode={DRAWER_MODE.SMALL}>
            {close => (
                <Switch>
                    <LayerRoute
                        path={COLUMN_PICKER_CREATOR_ROUTE_PATH}
                        render={() => <ColumnPresetCreator onCancel={close} reportConfig={reportConfig} />}
                    />
                    <LayerRoute
                        path={COLUMN_PICKER_EDITOR_ROUTE_PATH}
                        render={() => <ColumnPresetEditor onCancel={close} reportConfig={reportConfig} />}
                    />
                </Switch>
            )}
        </SideDrawer>
    );
};

CustomColumnPickerDrawer.propTypes = {
    closeSideDrawer: PropTypes.func,
    reportConfig: PropTypes.object,
};
