import React from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { TargetCpaInput } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategy/TargetCpaRadio/TargetCpaInput';
import { useCpaRecommendation } from 'modules/campaigns/modules/performance-recommendations/hooks/useCpaRecommendation';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { COMMON_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { withRecommendationFormValidationDataProvider } from 'modules/taboola-common-frontend-modules/formData/withRecommendationFormValidationDataProvider';
import { useCpaGoalFormFieldValue } from '../../../common-campaign-form/components/CpaGoal/useCpaGoalFormFieldValue';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';
import styles from './CPAPerformanceRecommendation.module.scss';

const CPAPerformanceRecommendation = ({ recommendation }) => {
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const {
        campaignId,
        accountId,
        applyParams,
        recommendationId,
        additionalValidations,
        messageParams,
        navigateToEditCampaign,
    } = useCpaRecommendation({
        recommendation,
    });
    const { min_range: minRange, max_range: maxRange } = messageParams;
    const shouldUseEditableCard = useConfigMatch(
        {},
        {
            [COMMON_FLAGS.PERFORMANCE_RECOMMENDATION_TCPA_SHOULD_USE_EDITABLE_CARD]: 'true',
        }
    );
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams,
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.set-cpa.title"
            descriptionCode="performance-recommendations.set-cpa.description"
            iconImg={LightningRoundIcon}
        >
            {shouldUseEditableCard && (
                <div className={styles['form-field-container']}>
                    <TargetCpaInput
                        additionalValidations={additionalValidations}
                        minRange={minRange}
                        maxRange={maxRange}
                        disabled={apply.isSuccess}
                    />
                </div>
            )}
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.set-cpa.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToEditCampaign}
                    recommendation={recommendation}
                    textCode="performance-recommendations.set-cpa.edit-campaign.button"
                    dataMetricsComponent="RecommendationRibbonEditCampaignButton"
                />
                <ApplyButton
                    textCode="performance-recommendations.set-cpa.action"
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode="performance-recommendations.set-cpa.action.tooltip"
                    onClick={apply.invoke}
                    disabled={!targetCpa}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

CPAPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

const CPAPerformanceRecommendationWithForm = withRecommendationFormValidationDataProvider(CPAPerformanceRecommendation);
export { CPAPerformanceRecommendationWithForm as CPAPerformanceRecommendation };
