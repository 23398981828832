import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { isDefined } from '../../taboola-common-frontend-modules/formData/utils/isDefined';
import { useBillingData } from './useBillingData';

export const useReport = (reportEndpoint, reportEndpointName) => {
    const { accountId, accountName } = useAccount();
    const { isFetching } = useBillingData();

    const { data, isError, isSuccess, isLoading, dataUpdatedAt } = useQuery(
        [reportEndpointName, accountId],
        () => reportEndpoint({ accountId: accountName }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: isDefined(accountName),
        }
    );

    const report = useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        return data?.results ?? [];
    }, [data, isSuccess]);

    return {
        isError,
        isLoading: isFetching || isLoading,
        isSuccess,
        dataUpdatedAt,
        report,
    };
};
