'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

exports.useElementSize = useElementSize;

var _react = require('react');

var _resizeObserverPolyfill = require('resize-observer-polyfill');

var _resizeObserverPolyfill2 = _interopRequireDefault(_resizeObserverPolyfill);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initialSize = { width: 0, height: 0 };
var parseElementSize = function parseElementSize(_ref) {
    var clientWidth = _ref.clientWidth,
        clientHeight = _ref.clientHeight;
    return {
        width: clientWidth,
        height: clientHeight
    };
};

function useElementSize() {
    var requestId = (0, _react.useRef)();

    var _useState = (0, _react.useState)(null),
        _useState2 = _slicedToArray(_useState, 2),
        element = _useState2[0],
        setElement = _useState2[1];

    var _useState3 = (0, _react.useState)(initialSize),
        _useState4 = _slicedToArray(_useState3, 2),
        _useState4$ = _useState4[0],
        width = _useState4$.width,
        height = _useState4$.height,
        setSize = _useState4[1];

    var resizeCallBack = (0, _react.useCallback)(function (entries) {
        var entry = entries && entries[0];
        if (entry) {
            cancelAnimationFrame(requestId.current);
            requestId.current = requestAnimationFrame(function () {
                setSize(parseElementSize(entry.target));
            });
        }
    }, []);

    (0, _react.useLayoutEffect)(function () {
        if (!element) {
            return;
        }
        setSize(parseElementSize(element));
        var resizeObserver = new _resizeObserverPolyfill2.default(resizeCallBack);
        resizeObserver.observe(element);

        // eslint-disable-next-line consistent-return
        return function () {
            resizeObserver.disconnect();
            cancelAnimationFrame(requestId.current);
        };
    }, [element, resizeCallBack]);

    return {
        height: height,
        width: width,
        element: element,
        ref: setElement
    };
}

exports.default = useElementSize;