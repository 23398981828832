import { REQUEST_TOGGLE_SITE_BLOCKING, TOGGLE_SITE_BLOCKING_SUCCESS, TOGGLE_SITE_BLOCKING_ERROR } from './actionTypes';

export const requestToggleSiteBlocking = () => ({
    type: REQUEST_TOGGLE_SITE_BLOCKING,
});

export const toggleSiteBlockingSuccess = () => ({
    type: TOGGLE_SITE_BLOCKING_SUCCESS,
});

export const toggleSiteBlockingError = error => ({
    type: TOGGLE_SITE_BLOCKING_ERROR,
    payload: error,
});
