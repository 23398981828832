import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { REPORT_TYPE } from '../../campaigns/config';
import { AUDIENCE_TYPE } from '../config';
import {
    generateCombinedAudiencesEditorPath,
    generateCustomContextualEditorPath,
    generateFirstPartyEditorPath,
    generatePredictiveEditorPath,
    generateMailDomainsEditorPath,
    generatePixelBasedEditorPath,
    generateSearchKeywordsEditorPath,
} from '../utils/generateEditorPath';

const paths = {
    [REPORT_TYPE.PIXEL_BASED]: generatePixelBasedEditorPath,
    [REPORT_TYPE.FIRST_PARTY]: generateFirstPartyEditorPath,
    [REPORT_TYPE.PREDICTIVE]: generatePredictiveEditorPath,
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: generateCustomContextualEditorPath,
    [REPORT_TYPE.COMBINED_AUDIENCES]: generateCombinedAudiencesEditorPath,
    [AUDIENCE_TYPE.SEARCH_KEYWORDS]: generateSearchKeywordsEditorPath,
    [AUDIENCE_TYPE.MAIL_DOMAINS]: generateMailDomainsEditorPath,
};

const useOpenAudienceEditor = (reportType, audienceType) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { accountId: selectedAccountId } = useSelector(selectedAccountSelector);

    const openAudienceEditor = useCallback(
        audience => {
            const { id, accountId } = audience;
            const resolvedAdvertiserName = accountId || selectedAccountId;
            const path =
                audienceType === AUDIENCE_TYPE.SEARCH_KEYWORDS || audienceType === AUDIENCE_TYPE.MAIL_DOMAINS
                    ? paths[audienceType]
                    : paths[reportType];
            const editAudiencePath = path({ accountId: resolvedAdvertiserName, audienceId: id });

            dispatch(navigate(history, editAudiencePath));
        },
        [selectedAccountId, reportType, audienceType, dispatch, history]
    );

    return openAudienceEditor;
};

export default useOpenAudienceEditor;
