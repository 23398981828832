import BRAND_SAFETY_TYPES from 'modules/campaigns/modules/common-campaign-form/config/BrandSafeTypes';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useThirdPartyBrandSafetyTargetingType = () => {
    const hasDvPermission = usePermissions('ADS_CONSOLE_THIRD_PARTY_BRAND_SAFETY_DV');
    const defaultThirdPartyBrandSafetyType = hasDvPermission ? BRAND_SAFETY_TYPES.DV : BRAND_SAFETY_TYPES.IAS;
    const { value, onChange } = useFormFieldValue(
        {
            field: 'thirdPartyBrandSafetyTargeting.type',
        },
        { defaultThirdPartyBrandSafetyType }
    );
    return { value, onChange };
};
