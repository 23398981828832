import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

export const PremiumSiteTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.premium.site.tooltip"
            defaultMessage="This allows you to target only Publishers that we have identified as premium. Please note that this will exclude potentially valuable publishers from your reach and will impact scale. This feature is not customer-facing."
        />
    </TooltipSection>
);
