import React from 'react';
import { Button } from 'tuui';
import { useSelectedAccount } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const DismissButton = ({ onClick, recommendationId, campaignId, dismissCode }) => {
    const { value: accountNumericId } = useSelectedAccount();

    return (
        <Button
            size={Button.size.sm}
            variant={Button.variant.text}
            onClick={onClick}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-account-id={accountNumericId}
            data-metrics-taboola-campaign-id={campaignId}
            data-metrics-value={recommendationId}
            data-metrics-component="RecommendationCardDismissButton"
        >
            <FormattedMessage id={dismissCode} defaultMessage="Dismiss" />
        </Button>
    );
};

export default DismissButton;
