import React, { useState } from 'react';
import { find } from 'lodash';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { CTA_OPTIONS } from '../../../../config';
import { DropdownCellEditor } from './DropdownCellEditor';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';

const serializeDropdownOption = option => ({ ctaType: option.value });

const deserializeCellData = cellData => find(CTA_OPTIONS, { value: cellData.ctaType });

const CTADropdownCellEditor = props => {
    const { submitHandler, onChange, value } = useSingleFieldCellEditorData(props);
    const [dropdownValue, setDropdownValue] = useState(deserializeCellData(value));

    const changeHandler = selectedValue => {
        onChange(serializeDropdownOption(selectedValue));
        setDropdownValue(selectedValue);
    };

    return (
        <DropdownCellEditor
            options={CTA_OPTIONS}
            dropdownValue={dropdownValue}
            onChange={changeHandler}
            onSubmit={submitHandler}
            {...props}
        />
    );
};

const CTADropdownCellEditorWithFormData = withGridCellFormDataProvider(CTADropdownCellEditor);

export { CTADropdownCellEditor };
export default CTADropdownCellEditorWithFormData;
