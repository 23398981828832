export const MESSAGE_TYPE = {
    ACCOUNT_CHANGE: 'ACCOUNT_CHANGE',
    URL_CHANGE: 'URL_CHANGE',
    NEW_TAB_NAVIGATION: 'NEW_TAB_NAVIGATION',
    URL_COPY: 'URL_COPY',
    FATAL_ERROR: 'FATAL_ERROR',
    GET_AUTH_TOKEN: 'GET_AUTH_TOKEN',
    AUTH_TOKEN_CHANGE: 'AUTH_TOKEN_CHANGE',
    SHOW_LOADING: 'SHOW_LOADING',
    HIDE_LOADING: 'HIDE_LOADING',
    IFRAME_READY: 'IFRAME_READY',
};
