import { useMemo } from 'react';
import { isLowTouchAccount } from '../../../../../account-management';
import { FEATURE_FLAGS, useAccountConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';

export const useCpcAccountConfig = tier => {
    const {
        [FEATURE_FLAGS.LT_MAX_CPC]: ltMaxCpc,
        [FEATURE_FLAGS.IS_SPECIFIC_LT_MAX_CPC]: isSpecificMaxCpcLT,
        [FEATURE_FLAGS.CPC_MAX_VALUE]: cpcMaxValue,
        [FEATURE_FLAGS.CPC_MIN_VALUE]: minAccountCpc,
        [FEATURE_FLAGS.CPC_MAX_WARNING]: cpcMaxWarning,
        [FEATURE_FLAGS.CPC_MIN_WARNING]: cpcMinWarning,
        [FEATURE_FLAGS.ACCOUNT_CPC_DATA_MIN_VALUE]: cpcDataMinValue,
        [FEATURE_FLAGS.ACCOUNT_CPC_DATA_MAX_VALUE]: cpcDataMaxValue,
    } = useAccountConfig([
        FEATURE_FLAGS.IS_SPECIFIC_LT_MAX_CPC,
        FEATURE_FLAGS.LT_MAX_CPC,
        FEATURE_FLAGS.CPC_MAX_VALUE,
        FEATURE_FLAGS.CPC_MIN_VALUE,
        FEATURE_FLAGS.CPC_MAX_WARNING,
        FEATURE_FLAGS.CPC_MIN_WARNING,
        FEATURE_FLAGS.ACCOUNT_CPC_DATA_MIN_VALUE,
        FEATURE_FLAGS.ACCOUNT_CPC_DATA_MAX_VALUE,
    ]);
    const isSpecificLtMaxCpc = JSON.parse(isSpecificMaxCpcLT) && isLowTouchAccount(tier);
    const maxAccountCpc = isSpecificLtMaxCpc ? ltMaxCpc : cpcMaxValue;
    return useMemo(
        () => ({
            maxAccountCpc,
            minAccountCpc,
            cpcMaxWarning,
            cpcMinWarning,
            cpcDataMinValue,
            cpcDataMaxValue,
        }),
        [maxAccountCpc, minAccountCpc, cpcMaxWarning, cpcMinWarning, cpcDataMinValue, cpcDataMaxValue]
    );
};
