import { isNil, isObject, partition } from 'lodash';

const getValueForValidation = ({ value }) => {
    if (isNil(value) || Number.isNaN(value)) {
        return '';
    }

    return Array.isArray(value) || isObject(value) ? value : `${value}`;
};

const emptyPartitionedValidations = [[], []];

const validateValue = async (validations, context) => {
    if (!(validations && Array.isArray(validations))) {
        return emptyPartitionedValidations;
    }

    const validationValues = await Promise.all(
        validations.map(async validation => {
            const { validationFn, options = {} } = validation;
            try {
                const result = await validationFn(getValueForValidation(context), options, context);

                return { validation, isInvalid: !result };
            } catch (error) {
                return { validation, isInvalid: true, error };
            }
        })
    );

    return partition(validationValues, 'isInvalid');
};

export default validateValue;
