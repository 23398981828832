import React from 'react';
import classnames from 'classnames/bind';
import { useSelectedAccount } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ButtonsSection } from '../../../ButtonsSection/ButtonsSection';
import { useNotificationsService } from '../../../hooks/useNotificationsService';
import { DisplayNotificationDetails } from '../DisplayNotificationDetails/DisplayNotificationDetails';
import styles from './notificationCardContent.module.scss';

const classNameBuilder = classnames.bind(styles);
export const NotificationCardContent = ({
    notification,
    children,
    dataMetricsComponent = 'NotificationCard',
    formattedMessageId,
    formattedMessageValues,
    descriptionCodeSuffix,
}) => {
    const { id, subType, attributes } = notification;
    const descriptionCode = `app.notificationsCenter.${subType}.description${
        descriptionCodeSuffix ? `.${descriptionCodeSuffix}` : ''
    }`;
    const descriptionParams = attributes ? attributes.descriptionParams : {};
    const [{ id: accountNumericId }] = useSelectedAccount();
    const { getNotificationStatus } = useNotificationsService();

    const { isInactive } = getNotificationStatus(notification);
    return (
        <div
            aria-label="NotificationCard"
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-visibility={GTM_EVENTS.VISIBLE}
            data-metrics-account-id={accountNumericId}
            data-metrics-value={id}
            data-metrics-component={dataMetricsComponent}
            className={styles['content-container']}
        >
            <div className={classNameBuilder('content', { 'is-inactive': isInactive })}>
                <FormattedMessage
                    id={descriptionCode}
                    values={descriptionParams}
                    defaultMessage="Something interesting happened, but we can't tell you what..."
                />
                {formattedMessageId && (
                    <DisplayNotificationDetails
                        isInactive={isInactive}
                        id={formattedMessageId}
                        values={formattedMessageValues}
                    />
                )}
                {children && <ButtonsSection isInactive={isInactive}>{children}</ButtonsSection>}
            </div>
        </div>
    );
};
