import React from 'react';
import PropTypes from 'prop-types';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PresetToggle } from '../PresetToggle/PresetToggle';

const label = (
    <FormattedMessage
        id="app.campaigns.reports.columnPicker.compare.caption"
        defaultMessage="Compare Clicks and View-through conversions"
    />
);

const helpText = (
    <FormattedMessage
        id="app.campaigns.reports.columnPicker.compare.hint"
        defaultMessage="When checked, this setting will add additional columns: Clicks and Views to each conversion metric"
    />
);

export const ConversionToggle = ({ shouldShowCompareColumns, toggleShouldShowCompareColumns, isVTAClosed }) => (
    <PresetToggle
        isChecked={shouldShowCompareColumns}
        onChange={toggleShouldShowCompareColumns}
        isDisabled={isVTAClosed}
        label={label}
        helpText={helpText}
    >
        {isVTAClosed && (
            <Tooltip arrow>
                <FormattedMessage
                    id="app.campaigns.reports.columnPicker.compare.hint.disabled"
                    defaultMessage="Attribution settings are set to clicks only. To compare clicks and view-through turn this option on under Tracking: Pixel Setting."
                />
            </Tooltip>
        )}
    </PresetToggle>
);

ConversionToggle.propTypes = {
    shouldShowCompareColumns: PropTypes.bool,
    toggleShouldShowCompareColumns: PropTypes.func,
    isVTAClosed: PropTypes.bool,
};
