import React from 'react';
import PropTypes from 'prop-types';
import DaypartingHeader from './DaypartingHeader';
import DaypartingRowWrapper from './DaypartingRowWrapper';

const DaypartingTable = ({ daypartingConfig, onRulesChange, startTimeOptions, endTimeOptions }) => {
    return (
        <div>
            <DaypartingHeader />
            {daypartingConfig.map((item, index) => {
                let { day } = item;
                return (
                    <DaypartingRowWrapper
                        key={day}
                        item={item}
                        index={index}
                        daypartingConfig={daypartingConfig}
                        onRulesChange={onRulesChange}
                        startTimeOptions={startTimeOptions}
                        endTimeOptions={endTimeOptions}
                    />
                );
            })}
        </div>
    );
};

DaypartingTable.propTypes = {
    daypartingConfig: PropTypes.array,
    onRulesChange: PropTypes.func,
    startTimeOptions: PropTypes.array,
    endTimeOptions: PropTypes.array,
};

export default DaypartingTable;
