import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinkIcon, withStylesheet } from 'taboola-ultimate-ui';
import { addProtocol } from '../../../../../taboola-common-frontend-modules/utils/urlUtils';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './linkCellRenderer.module.scss';

const StyledEllipsisCellRenderer = withStylesheet(EllipsisCellRenderer, styles, {
    [EllipsisCellRenderer.classNames.value]: 'label-text',
});

class LinkCellRenderer extends Component {
    renderLabel = ({ text, isSearchable }) => (
        <StyledEllipsisCellRenderer value={text} lines={2} isSearchable={isSearchable} />
    );

    renderLink = url => {
        const href = addProtocol(url);

        return (
            <a className={styles['link']} href={href} title={href} target="_blank" rel="noopener noreferrer">
                <LinkIcon className={styles['icon']} />
            </a>
        );
    };

    render() {
        const { value, text, textColumnName, data, isSearchable } = this.props;
        const labelText = text || (data && textColumnName && data[textColumnName]) || value;

        return (
            <div className={styles['container']}>
                {labelText && this.renderLabel({ text: labelText, isSearchable })}
                {value && this.renderLink(value)}
            </div>
        );
    }
}

LinkCellRenderer.propTypes = {
    /** Link value */
    value: PropTypes.string,
    /** Display text value */
    text: PropTypes.string,
    /** textColumnName which is injected by the grid - The column in which the text value is */
    textColumnName: PropTypes.string,
    /** value which is injected by the grid - Data of current grid row */
    data: PropTypes.object,
};

export default LinkCellRenderer;
