import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxGroup, INDICATION_TYPES, TextCheckbox } from 'taboola-ultimate-ui';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { withIndication } from 'modules/errors/components/withIndication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { TotalConversionTooltip } from './TotalConversionTooltip/TotalConversionTooltip';

const CheckboxGroupWithIndication = withIndication(CheckboxGroup);

const validations = [
    {
        validationFn: (checkBoxValue, options, { formMode, initialValue }) => {
            const boolValue = checkBoxValue === 'true';
            return (
                (formMode === FORM_MODES.EDIT || !boolValue) &&
                (formMode !== FORM_MODES.EDIT || boolValue === initialValue)
            );
        },
        messageId: 'tracking.conversion.validations.warning.totalConversions.significantChange',
        defaultMessage:
            'Please note that any change in the Total Conversions field might cause existing campaigns to re-enter the learning phase until sufficient data is collected.',
        severity: INDICATION_TYPES.WARNING,
    },
];

export const TotalConversionCheckBox = () => {
    const [isValidationTriggered, triggerValidation] = useState(false);
    const {
        value: checkBoxValue,
        onChange,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'includeInTotalConversions',
        validations,
        validationDependencies: {
            isValidationTriggered,
        },
    });
    const onSelect = useCallback(() => onChange(true), [onChange]);
    const onUnSelect = useCallback(() => onChange(false), [onChange]);

    // This effect is run on startup and triggers validation by causing change in validationDependencies
    // This is needed in order to show warning on init of create form
    useEffect(() => {
        if (mode === FORM_MODES.CREATE) {
            triggerValidation(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormField
            inputId="total-Conversions"
            label={
                <FormattedMessage
                    id="tracking.conversion.properties.total.conversion.checkbox.label"
                    defaultMessage="Total Conversions"
                />
            }
            description={
                <FormattedMessage
                    id="tracking.conversion.properties.total.conversion.checkbox.description"
                    defaultMessage="Total conversions are the primary conversions to be used for SmartBid optimizations and overall performance reporting."
                />
            }
            helpText={<TotalConversionTooltip />}
            ref={scrollRef}
        >
            <CheckboxGroupWithIndication
                onSelectCheckbox={onSelect}
                onUnselectCheckbox={onUnSelect}
                selectedValues={[checkBoxValue]}
                {...indicationData}
            >
                <TextCheckbox
                    value
                    title={
                        <FormattedMessage
                            id="tracking.conversion.properties.total.conversion.checkbox.title"
                            defaultMessage="Include in total conversions"
                        />
                    }
                    data-metrics-component="Total conversions checkbox"
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-value={!checkBoxValue}
                />
            </CheckboxGroupWithIndication>
        </FormField>
    );
};
