import { isEqual, pick, set } from 'lodash';
import { ACCOUNT_ID, DIMENSION } from 'hooks';
import { AllCampaignsId, CAMPAIGNS_ROUTE, REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import {
    campaignsGroupBudgetValidations,
    campaignsGroupNameValidations,
} from 'modules/campaigns/config/validations/campaigns-group';
import { CAMPAIGN_ID } from 'modules/campaigns/hooks';
import {
    ActionableTextWithInternalLinkCellRenderer,
    CampaignsGroupActionsCellRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    StateCellRenderer,
    CheckboxHeaderRenderer,
    CheckboxCellRenderer,
} from 'modules/campaigns/modules/campaigns-reports/components';
import saveCampaignsGroup from 'modules/campaigns/modules/campaigns-reports/flows/saveCampaignsGroup';
import { REPORT_FILTERS } from 'modules/campaigns/modules/campaigns-reports/hooks/usePermanentReportFilters';
import { REPORT_FILTER_STATUS } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportFiltersStatus';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { setSelectedRows } from '../../../actions';
import createCSVValueGetter from '../../../components/ValueFormatters/createCSVValueGetter';
import { remainingBudgetValueFormatter } from '../../../components/ValueFormatters/remainingBudgetValueFormatter';
import { DATE_PRESET, END_DATE, REPORT_ID, START_DATE } from '../../../hooks';
import {
    actionsColumnType,
    editableColumn,
    keyColumn,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
    checkBoxSelectionColumn,
} from '../../columns';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { isCampaignGroupEditable } from '../../predicates';

const isDisabled = (data, context) => {
    return !isCampaignGroupEditable({ data, context });
};

export const getCampaignGroupFilter = (campaignsGroupId, campaignsGroupName) => [
    {
        id: FILTER_TYPE.CAMPAIGNS_GROUP,
        type: FILTER_TYPE.CAMPAIGNS_GROUP,
        values: [{ value: campaignsGroupId, label: campaignsGroupName }],
    },
];

const campaignsGroupReportColumnDefinition = [
    ...[
        {
            headerComponentFramework: CheckboxHeaderRenderer,
            headerName: FIELDS.IS_SELECTED.label,
            field: FIELDS.IS_SELECTED.field,
            type: [leftPinnedColumn, checkBoxSelectionColumn, staticColumn],
            cellRendererParams: {
                onClick: setSelectedRows,
            },
            headerComponentParams: {
                onClick: setSelectedRows,
            },
            cellRendererFramework: CheckboxCellRenderer,
            csv: false,
            accountConfigurations: {
                [FEATURE_FLAGS.CAMPAIGNS_GROUP_BULK_ACTIONS_ENABLED]: 'true',
            },
        },
        {
            headerName: FIELDS.IS_ACTIVE.label,
            field: FIELDS.IS_ACTIVE.field,
            type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
            cellRendererParams: {
                ...FIELDS.IS_ACTIVE.renderParams,
                isDisabled,
                reportId: REPORT_TYPE.CAMPAIGNS_GROUPS,
            },
            cellRendererFramework: StateCellRenderer,
            valueChangeHandler: saveCampaignsGroup,
            csv: false,
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_NAME.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_NAME.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_NAME.field,
            cellRendererFramework: ActionableTextWithInternalLinkCellRenderer,
            cellRendererParams: {
                isSearchable: true,
                to: CAMPAIGNS_ROUTE,
                paramsToKeep: [ACCOUNT_ID, DIMENSION, START_DATE, END_DATE, DATE_PRESET],
                paramsFactory: ({ campaignsGroupId, campaignsGroupName }) => {
                    return {
                        [CAMPAIGN_ID]: AllCampaignsId,
                        [REPORT_ID]: REPORT_TYPE.CAMPAIGN,
                        [REPORT_FILTER_STATUS]: 'true',
                        [REPORT_FILTERS]: getCampaignGroupFilter(campaignsGroupId, campaignsGroupName),
                    };
                },
            },
            type: [leftPinnedColumn, keyColumn, editableColumn],
            cellEditorFramework: FIELDS.CAMPAIGNS_GROUP_NAME.cellEditorFramework,
            editable: isCampaignGroupEditable,
            valueChangeHandler: saveCampaignsGroup,
            cellEditorParams: {
                validations: campaignsGroupNameValidations,
                editableTextClassName: 'with-formatted-spaces',
            },
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_STATUS.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_STATUS.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_STATUS.field,
            type: [leftPinnedColumn],
            cellRendererFramework: FIELDS.CAMPAIGNS_GROUP_STATUS.cellRenderer,
            cellRendererParams: FIELDS.CAMPAIGNS_GROUP_STATUS.renderParams,
            csvValueGetter: FIELDS.CAMPAIGNS_GROUP_STATUS.csvValueGetter,
            flex: 1,
            valueGetter: ({ data }) => pick(data, ['isActive', 'status', 'displayStatus']),
            equals: isEqual,
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_ID.label,
            field: FIELDS.CAMPAIGNS_GROUP_ID.field,
            type: [numericColumn],
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_ID.headerComponentParams,
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_LINKED_CAMPAIGNS.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_LINKED_CAMPAIGNS.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_LINKED_CAMPAIGNS.field,
            type: [numericColumn],
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_BUDGET.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_BUDGET.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_BUDGET.field,
            type: [numericColumn, editableColumn],
            cellRendererFramework: FIELDS.CAMPAIGNS_GROUP_BUDGET.cellRenderer,
            cellRendererParams: FIELDS.CAMPAIGNS_GROUP_BUDGET.renderParams,
            csv: FIELDS.CAMPAIGNS_GROUP_BUDGET.csv,
            cellEditorFramework: FIELDS.CAMPAIGNS_GROUP_BUDGET.cellEditorFramework,
            valueChangeHandler: saveCampaignsGroup,
            cellEditorParams: {
                validations: campaignsGroupBudgetValidations,
                fieldPath: 'budget.value',
            },
            editable: isCampaignGroupEditable,
            valueGetter: 'data.budget.value',
            valueSetter: params => set(params, 'data.budget.value', +params.newValue),
        },
        {
            headerName: FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.headerComponentParams,
            field: FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.field,
            type: [numericColumn],
            cellRendererFramework: FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.cellRenderer,
            cellRendererParams: FIELDS.CAMPAIGNS_GROUP_REMAINING_BUDGET.renderParams,
            csvValueGetter: createCSVValueGetter(remainingBudgetValueFormatter),
        },
        {
            headerName: FIELDS.START_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.START_DATE.headerComponentParams,
            field: FIELDS.START_DATE.field,
            valueFormatter: FIELDS.START_DATE.valueFormatter,
            csvValueGetter: FIELDS.START_DATE.csvValueGetter,
        },
        {
            headerName: FIELDS.END_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.END_DATE.headerComponentParams,
            field: FIELDS.END_DATE.field,
            valueFormatter: FIELDS.END_DATE.valueFormatter,
            csvValueGetter: FIELDS.END_DATE.csvValueGetter,
        },
    ].map(colDef => ({ valueChangeHandler: saveCampaignsGroup, ...colDef })),
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.AVERAGE_CPC,
        FIELDS.VCTR,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.UNIQUE_IMPRESSIONS,
        FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS,
        FIELDS.UNIQUE_CLICKS,
        FIELDS.CAMPAIGN_GROUP_SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        accountConfigurations: fieldConfig.accountConfigurations,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererFramework: CampaignsGroupActionsCellRenderer,
        csv: false,
        minWidth: 50,
        maxWidth: 72,
        width: 72,
    },
];

export default normalizeColumnDefs(
    campaignsGroupReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CAMPAIGNS_GROUPS].id
);
