import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params/';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { debugFlagStore, IS_DEBUG_MODE_QUERY_PARAM } from './isDebugMode';

export const useDebugQueryParamInner = queryParamHookFactory(IS_DEBUG_MODE_QUERY_PARAM, {
    persist: PERSISTENCE_TYPE.MEMORY,
});

export const useDebugQueryParam = () => {
    const [value, ...rest] = useDebugQueryParamInner();
    debugFlagStore.setValue(value);

    return [value, ...rest];
};
