import { noop } from 'lodash';
import { isMetaKeyEvent } from '../../../../hooks/useMetaKeySkipHandler';
import { FormattedMessage } from '../../i18n';
import { MESSAGE_TYPE } from '../messageType';
import { getHashedUrl } from './getHashedUrl';
import { isIframeMode } from './isIframeMode';
import { sendMessage } from './sendMessage';

export const getOnClickHandler = (handler = noop) => {
    if (!isIframeMode()) {
        return handler;
    }
    const wrappedHandler = event => {
        if (isMetaKeyEvent(event)) {
            event.preventDefault();
            sendMessage(MESSAGE_TYPE.NEW_TAB_NAVIGATION, getHashedUrl(event?.currentTarget?.href));
            return;
        }
        handler(event);
    };

    return wrappedHandler;
};

export const getLinkAuxHandler = (handler = noop) => {
    if (!isIframeMode()) {
        return handler;
    }
    const wrappedHandler = event => {
        if (event.button === 1) {
            event.preventDefault();
            sendMessage(MESSAGE_TYPE.NEW_TAB_NAVIGATION, getHashedUrl(event?.currentTarget?.href));
            handler(event);
        }
    };
    return wrappedHandler;
};

export const getLinkContextMenuOptions = innerUrl => [
    {
        label: <FormattedMessage id="app.common.open.link.new.tab" defautlmessage="Open link in new tab" />,
        onClick: () => {
            sendMessage(MESSAGE_TYPE.NEW_TAB_NAVIGATION, getHashedUrl(`${window.location.origin}${innerUrl}`));
        },
    },
    {
        label: <FormattedMessage id="app.common.cope.link" defautlmessage="Copy link address" />,
        onClick: () => {
            sendMessage(MESSAGE_TYPE.URL_COPY, getHashedUrl(`${window.location.origin}${innerUrl}`));
            navigator.clipboard.writeText(`${window.location.origin}${innerUrl}`);
        },
    },
];
