import {
    FEATURE_FLAGS,
    useAccountConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';

export const useSpendingLimitValidationRatio = () => {
    const {
        [FEATURE_FLAGS.BUDGET_LIMIT_RATIO_CONFIG]: budgetLimitRatioString,
        [FEATURE_FLAGS.IS_BUDGET_LIMIT_RATIO_CONFIG]: isBudgetLimitRatioString,
    } = useAccountConfig([FEATURE_FLAGS.BUDGET_LIMIT_RATIO_CONFIG, FEATURE_FLAGS.IS_BUDGET_LIMIT_RATIO_CONFIG]);
    const budgetLimitRatio = JSON.parse(budgetLimitRatioString);
    const isBudgetLimitRatio = JSON.parse(isBudgetLimitRatioString);
    return isBudgetLimitRatio && budgetLimitRatio;
};
