import { useCallback, useContext, createContext } from 'react';
import { isUndefined, unset } from 'lodash';
import { IS_DEBUG_MODE } from 'services/isDebugMode';

/**
 * @deprecated - will be removed after migration of all selectors
 */
let IN_MEMORY_QUERY_PARAMS_DATA = {};

if (IS_DEBUG_MODE()) {
    window.IN_MEMORY_QUERY_PARAMS_DATA = IN_MEMORY_QUERY_PARAMS_DATA;
}

export const MemoryStorageContext = createContext(IN_MEMORY_QUERY_PARAMS_DATA);

/**
 * @deprecated - will be removed after migration of all selectors
 */
export const getQueryParamFromMemory = key => IN_MEMORY_QUERY_PARAMS_DATA[key];

export const resetQueryParamsInMemory = () => {
    IN_MEMORY_QUERY_PARAMS_DATA = {};
};

export const useMemoryStorage = () => {
    const data = useContext(MemoryStorageContext);
    const getter = useCallback(key => data[key], [data]);

    const setter = useCallback(
        (key, value) => {
            if (isUndefined(value)) {
                unset(data, key);
                return;
            }
            data[key] = value;
        },
        [data]
    );

    return [getter, setter];
};
