import React, { forwardRef } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import PRICING_MODEL from '../../../../config/pricingModel';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import LabelLayoutCellRenderer from './LabelLayoutCellRenderer';

export const BidCellRenderer = props => {
    const {
        value,
        data: { pricingModel = PRICING_MODEL.CPC },
    } = props;

    return value ? (
        <LabelLayoutCellRenderer
            className="bid-value"
            labelClassName="pricingModel"
            msgId={`app.campaigns.campaign.pricingModel.${pricingModel}`}
        >
            <FormattedNumberCellRenderer {...props} />
        </LabelLayoutCellRenderer>
    ) : (
        <FormattedMessage id="app.campaigns.campaignsReports.bid.empty" defaultMessage="Auto" />
    );
};

export default forwardRef((props, ref) => <BidCellRenderer {...props} />);
