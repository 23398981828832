import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../taboola-common-frontend-modules/modals';
import { deleteScheduledReportFlow } from '../flows/deteleScheduledReports';
import styles from './useDeleteScheduledReport.module.scss';

export const useDeleteScheduledReport = () => {
    const dispatch = useDispatch();
    const { open: openModal } = useModal();
    const deleteScheduledReport = useCallback(
        async params => {
            const { scheduledReportId, scheduledReportName, accountName } = params;
            const result = await openModal({
                title: (
                    <FormattedMessage
                        id="app.create.scheduled.reports.delete.confirmation.title"
                        defaultMessage="Delete Scheduled Report?"
                        tagName="h2"
                    />
                ),
                content: (
                    <FormattedMessage
                        id="app.create.scheduled.reports.delete.confirmation.message"
                        defaultMessage="You are about to delete {name}."
                        values={{ name: scheduledReportName }}
                    />
                ),
                className: styles['content'],
                headerClassName: styles['title'],
                formProps: {
                    submitButtonText: <FormattedMessage id="app.actionButtons.DELETE" defaultMessage="DELETE" />,
                },
            });

            if (result) {
                dispatch(deleteScheduledReportFlow(accountName, scheduledReportId));
            }
        },
        [openModal, dispatch]
    );
    return deleteScheduledReport;
};
