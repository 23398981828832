import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { isNil } from 'lodash';
import { FormField } from 'taboola-ultimate-ui';
import formatNumberCompact from '../../../../campaigns/services/utils/formatNumberCompact';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './sizeEstimationField.module.scss';

const classNameBuilder = classnames.bind(styles);

const getSizeEstimationToDisplay = count => {
    if (!count) {
        return '-';
    }
    return count < 100 ? '< 100' : formatNumberCompact(count, 2);
};

const loadingNode = <ContentLoader height={12} width={112} speed={2} className={styles['loading']} />;
const countNode = sizeEstimationToDisplay => <span className={styles['count']}>{sizeEstimationToDisplay}</span>;

export const SizeEstimationField = ({ field, isLoading, count, tooltip }) => {
    const { formatMessage } = useIntl();
    const sizeEstimationToDisplay = useMemo(() => getSizeEstimationToDisplay(count), [count]);
    const hasData = !isNil(count);
    return (
        <FormField
            inputId={`size-estimation-${field}`}
            labelClass={classNameBuilder({ 'gray-label': isLoading || !hasData })}
            label={formatMessage({
                id: `external-audience.size.estimation.${field}.label`,
                defaultMessage: 'Viewable Impressions',
            })}
            helpText={tooltip}
        >
            {isLoading ? loadingNode : countNode(sizeEstimationToDisplay)}
        </FormField>
    );
};
