import React, { useEffect } from 'react';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useWorkspaceDropdown } from 'modules/tracking/hooks/useWorkspaceDropdown';
import { DropdownPlaceholder } from '../GtmConnectForm/DropdownPlaceholder';
import { WorkspaceDropdownTooltip } from './WorkspaceDropdownTooltip';
import styles from './workspaceDropdown.module.scss';

export const WorkspaceDropdown = () => {
    const { value, options, onChange, isLoading } = useWorkspaceDropdown();
    const { isTokenValid } = useGoogleApi();

    const { value: gtmContainer } = useFormFieldValue({ field: 'gtmContainer' });

    useEffect(() => {
        if (options?.length === 1) {
            onChange(options[0]);
        }
    }, [options, onChange]);

    return (
        <FormField
            inputId="gtm-workspace-dropdown"
            label={
                <div className={styles['selector-label']}>
                    <FormattedMessage id="tracking.setup.gtm.workspace.label" defaultMessage="Select Workspace" />
                    <WorkspaceDropdownTooltip />
                </div>
            }
        >
            <div className={styles['dropdown-container']}>
                <BareDropdown
                    id="gtm-workspace-selector"
                    selectedValueObject={value}
                    disabled={!isTokenValid || !gtmContainer}
                    options={options}
                    placeholder={<DropdownPlaceholder isLoading={isLoading} />}
                    onChange={onChange}
                />
            </div>
        </FormField>
    );
};
