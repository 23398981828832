import { useEffect, useMemo } from 'react';
import { toLower, isEmpty, toString, debounce } from 'lodash';
import { useCurrentValueGetter } from 'hooks';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

export const useSendSegmentSearchEvent = ({ component, searchText, resultsCount, providers = [], isLoading }) => {
    const searchTextValueGetter = useCurrentValueGetter(searchText);
    const resultsCountGetter = useCurrentValueGetter(resultsCount);
    const providersGetter = useCurrentValueGetter(providers);

    const sendEvent = useMemo(() => {
        const _sendEvent = (component, value) => gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { component, value });

        return debounce(_sendEvent, 2000);
    }, []);

    useEffect(() => {
        const currentSearchText = searchTextValueGetter();
        const cleanedSearchText = toLower(currentSearchText);

        if (!isLoading && cleanedSearchText) {
            const currentResultsCount = resultsCountGetter();
            const currentProviders = providersGetter();
            const providersText = isEmpty(currentProviders) ? '' : `;${toString(currentProviders)}`;

            sendEvent(component, `${cleanedSearchText};${currentResultsCount}${providersText}`);
        }
    }, [component, searchTextValueGetter, resultsCountGetter, providersGetter, isLoading, sendEvent]);
};
