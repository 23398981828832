import React from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_STATUS } from 'services/constants';
import CampaignsGroupBudgetSection from '../CampaignsGroupBudgetSection/CampaignsGroupBudgetSection';
import CampaignsGroupScheduleSection from '../CampaignsGroupScheduleSection/CampaignsGroupScheduleSection';
import CampaignsGroupSetupSection from '../CampaignsGroupSetupSection/CampaignsGroupSetupSection';
import { SharedBudgetCampaignsSection } from '../SharedBudgetCampaignsSection/SharedBudgetCampaignsSection';
import styles from './campaignsGroupEditorContent.module.scss';

const CampaignsGroupEditorContent = () => (
    <div className={styles['container']}>
        <CampaignsGroupSetupSection />
        <CampaignsGroupScheduleSection />
        <CampaignsGroupBudgetSection />
        <SharedBudgetCampaignsSection />
    </div>
);

CampaignsGroupEditorContent.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    currency: PropTypes.string,
};

export default CampaignsGroupEditorContent;
