import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGNS_ROUTE, CREATIVE_CREATOR_ROUTE_PATH, ENTITY, REPORT_TYPE } from 'modules/campaigns/config';
import { replacePathParams } from 'modules/campaigns/services/utils/index';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { REPORT_ID } from '../modules/campaigns-reports/hooks';
import { CreativeCreator } from '../modules/creative-creator/CreativeCreator';
import { VideoCreativeCreator } from '../modules/video-creative-creator/VideoCreativeCreator.new';

const path = CREATIVE_CREATOR_ROUTE_PATH;

export const CreativeCreatorPage = ({ onCancel }) => {
    const sponsoredPath = replacePathParams(path, { entity: [ENTITY.CREATIVE] });
    const videoPath = replacePathParams(path, { entity: [ENTITY.CREATIVE_VIDEO] });
    useUnsavedChangesController(CREATIVE_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                strict
                path={sponsoredPath}
                render={props => (
                    <CreativeCreator
                        {...props}
                        onCancel={onCancel}
                        backPath={`${CAMPAIGNS_ROUTE}?${REPORT_ID}=${REPORT_TYPE.CREATIVE}`}
                    />
                )}
            />
            <LayerRoute
                strict
                path={videoPath}
                render={props => (
                    <VideoCreativeCreator
                        {...props}
                        onCancel={onCancel}
                        backPath={`${CAMPAIGNS_ROUTE}?${REPORT_ID}=${REPORT_TYPE.CREATIVE_VIDEO}`}
                    />
                )}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CreativeCreatorPage.propTypes = {
    onCancel: PropTypes.func,
    onValidationError: PropTypes.func,
};

export default CreativeCreatorPage;
