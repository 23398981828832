import React from 'react';
import { CardIcon, Input, InputTypes, FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import commonStyles from '../../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import styles from './readOnlyCardNumber.module.scss';

export const ReadOnlyCardNumber = () => {
    const { value: lastDigits = '****' } = useFormValidatedValue({ field: 'lastDigits' });
    const cardNumber = `**** **** **** ${lastDigits}`;

    return (
        <FormField
            inputId="cardNumber"
            containerClass={commonStyles['input']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.cardNumber" defaultMessage="Card Number" />}
        >
            <div className={styles['container']}>
                <CardIcon className={styles['card-icon']} />
                <div className={styles['divider']} />
                <Input
                    inputClass={styles['input']}
                    disabled
                    value={cardNumber}
                    type={InputTypes.TEXT}
                    size={Input.SIZE.LARGE}
                />
            </div>
        </FormField>
    );
};
