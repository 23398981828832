"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSettingsSort(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M5.604 17a3.001 3.001 0 0 1 5.659 0h8.644a1 1 0 0 1 0 2h-8.644a3.001 3.001 0 0 1-5.659 0H4a1 1 0 0 1 0-2h1.604zm7-6a3.001 3.001 0 0 1 5.659 0h1.644a1 1 0 0 1 0 2h-1.644a3.001 3.001 0 0 1-5.659 0H4a1 1 0 0 1 0-2h8.604zm-7-6a3.001 3.001 0 0 1 5.659 0h8.644a1 1 0 0 1 0 2h-8.644a3.001 3.001 0 0 1-5.659 0H4a1 1 0 1 1 0-2h1.604zm2.83 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-7 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            })
        )
    );
}
exports.default = SvgSettingsSort;