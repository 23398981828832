import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TipText from '../../TipText';
import TooltipSection from '../../TooltipSection';

const OsTargetingTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.os.help.overview"
                defaultMessage="Choose the operating systems on which your ads will appear."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.os.help.example"
                defaultMessage="For example, if you're offering an Android app, it would make sense to target Android devices only."
            />
        </TooltipSection>
        <TooltipSection>
            <TipText>
                <FormattedMessage
                    id="campaign.editor.targeting.os.help.tip"
                    defaultMessage="consider setting up a separate campaign for each operating system. This allows you to compare results and optimize performance."
                />
            </TipText>
        </TooltipSection>
    </div>
);

export default OsTargetingTooltip;
