import { useCallback } from 'react';
import { useDropdownOptions } from 'hooks';
import { DropdownField as DropdownFieldRaw } from 'modules/campaigns/components';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './excludeDayField.module.scss';

const DropdownField = withIndication(DropdownFieldRaw);

const excludeDaysOptions = [
    { value: 1, label: 'Today' },
    { value: 2, label: 'Last 2 days' },
    { value: 3, label: 'Last 3 days' },
    { value: 4, label: 'Last 4 days' },
    { value: 5, label: 'Last 5 days' },
    { value: 6, label: 'Last 6 days' },
    { value: 7, label: 'Last 7 days' },
    { value: 0, label: "Don't exclude" },
];

export const ExcludeDaysField = ({ daysToExclude, daysToExcludeChange, failedValidationData }) => {
    const options = useDropdownOptions(excludeDaysOptions, 'performance.rules.advanced.settings.exclude.days.options');

    const onDaysToExcludeChange = useCallback(
        daysToExclude => {
            daysToExcludeChange(daysToExclude.value);
        },
        [daysToExcludeChange]
    );

    return (
        <DropdownField
            label={
                <FormattedMessage
                    id="app.create.performance.rules.advanced.settings.exclude.days.label"
                    defaultMessage="Days to exclude"
                    className={styles['label']}
                />
            }
            id="daysToExclude"
            placeholder={
                <FormattedMessage
                    id="app.create.performance.rules.advanced.settings.exclude.days.place.holder"
                    defaultMessage="Number of days"
                />
            }
            selectedValueObject={{ value: daysToExclude }}
            options={options}
            onChange={onDaysToExcludeChange}
            searchable={true}
            dropdownWrapperClassName={styles['container']}
            {...failedValidationData}
        />
    );
};
