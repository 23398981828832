import React from 'react';
import { CloseFullscreenOutlinedIcon, Button, Textarea } from 'tuui';
import { Dotdotdot, BareDropdown } from 'taboola-ultimate-ui';
import { INCLUDE_EXCLUDE_OPTIONS } from '../../config';
import { TargetingTypeDropdownOption } from '../TargetingTypeDropdown';
import styles from './TargetingMultilineInputBox.module.scss';

export const TargetingMultilineInputBox = ({
    itemType,
    isItemTypeDisabled,
    onChangeTargetingType,
    id,
    textAreaPlaceholder,
    scrollRef,
    submitMultiline,
    submitBtnMetricsAttributes,
    footerErrorMessage,
    footerButtonMessage,
    hasError,
    submittedValuesCount,
    setIsMultiline,
    text,
    setText,
    inputValues,
    valuesCount,
    handleTextAreaChange,
}) => {
    const onSubmitMultiline = async () => {
        try {
            const newText = await submitMultiline(inputValues);
            setText(newText);
        } catch (e) {}
    };

    const TextAreaAppendix = setIsMultiline && (
        <div
            aria-label="minimize-icon"
            onClick={() => setIsMultiline(false)}
            className={styles['textarea-minimize-btn']}
        >
            <CloseFullscreenOutlinedIcon />
        </div>
    );

    return (
        <div className={styles['multiline-container']} ref={scrollRef} data-testid={id}>
            <div className={styles['dropdown-container']}>
                <div className={styles['dropdown-wrapper']}>
                    <BareDropdown
                        value={itemType}
                        options={INCLUDE_EXCLUDE_OPTIONS}
                        disabled={isItemTypeDisabled}
                        optionItemRenderer={TargetingTypeDropdownOption}
                        onChange={onChangeTargetingType}
                        skipRightBorderRadius
                    />
                </div>

                <div className={styles['vertical-delimiter']} />

                <div className={styles['input-container']}>
                    {TextAreaAppendix}
                    <Textarea
                        value={text}
                        placeholder={textAreaPlaceholder}
                        onChange={handleTextAreaChange}
                        className={hasError ? styles['error-message'] : ''}
                        textareaClassName={styles['textarea']}
                        rows={4}
                    />
                </div>
            </div>
            <div className={styles['horizontal-delimiter']} />
            <div className={styles['footer-container']}>
                {hasError && (
                    <Dotdotdot lines={2} className={styles['error-message']}>
                        {footerErrorMessage}
                    </Dotdotdot>
                )}

                {hasError && (
                    <div className={styles['counter']}>
                        {valuesCount}/{submittedValuesCount}
                    </div>
                )}
                <Button
                    onClick={onSubmitMultiline}
                    size="sm"
                    variant="primary"
                    disabled={!valuesCount}
                    {...submitBtnMetricsAttributes}
                >
                    {footerButtonMessage}
                </Button>
            </div>
        </div>
    );
};
