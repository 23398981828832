import React from 'react';
import { Radio, RadioGroup } from 'taboola-ultimate-ui';
import { FormField } from 'taboola-ultimate-ui';
import { RUNTIME_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AdSchedulerTooltip } from './AdSchedulerTooltip';

export const RunTimeAdScheduler = ({ mode, onModeChange }) => (
    <FormField
        inputId="ad-scheduler"
        label={
            <FormattedMessage id="creative.editor.edit.ad.schedule.header" defaultMessage="Beta: Ad Flight Schedule" />
        }
        helpText={<AdSchedulerTooltip />}
    >
        <RadioGroup
            name="runTimeAdScheduler"
            onChange={onModeChange}
            selectedValue={mode}
            data-metrics-component="runTimeAdScheduler"
        >
            <Radio
                value={RUNTIME_TYPES.ALWAYS}
                title={
                    <FormattedMessage
                        id="creative.editor.edit.ad.schedule.running.as.approved"
                        defaultMessage="Start running as soon as approved."
                    />
                }
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={RUNTIME_TYPES.ALWAYS}
            />
            <Radio
                value={RUNTIME_TYPES.CUSTOM}
                title={
                    <FormattedMessage
                        id="campaign.time.frame.set.start.and.end"
                        defaultMessage="Specify a start and end date."
                    />
                }
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={RUNTIME_TYPES.CUSTOM}
            />
        </RadioGroup>
    </FormField>
);
