import { FatiguedItemSingleItemRecommendation } from '../components/FatiguedItemSingleItemRecommendation/FatiguedItemSingleItemRecommendation';
import { ItemWithoutCTASingleItemRecommendation } from '../components/ItemWithoutCTASingleItemRecommendation/ItemWithoutCTASingleItemRecommendation';
import { ItemWithoutDescriptionSingleItemRecommendation } from '../components/ItemWithoutDescriptionSingleItemRecommendation/ItemWithoutDescriptionSingleItemRecommendation';
import { LowPerformingSingleItemRecommendation } from '../components/LowPerformingSingleItemRecommendation/LowPerformingSingleItemRecommendation';
import { LowResolutionSingleItemRecommendation } from '../components/LowResolutionSingleItemPerformanceRecommendation/LowResolutionSingleItemRecommendation';
import PERFORMANCE_RECOMMENDATIONS from './performanceRecommendations';

export const PERFORMANCE_RECOMMENDATIONS_ITEM_CARD_TYPES = {
    [PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_CTA]: ItemWithoutCTASingleItemRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_DESCRIPTION]: ItemWithoutDescriptionSingleItemRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.LOW_PERFORMING_ITEM]: LowPerformingSingleItemRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.LOW_RESOLUTION_IC]: LowResolutionSingleItemRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.FATIGUED_ITEM]: FatiguedItemSingleItemRecommendation,
};
