import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const TotalValueTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="tracking.conversion.properties.total.value.field.tooltip"
            defaultMessage="Choose whether or not to include a conversion value data in your total conversions 'Value’ reporting column. Changing this setting will affect the reports for the past 30 days."
        />
    </TooltipSection>
);
