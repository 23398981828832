import React, { useLayoutEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'tuui';
import { Highlighter, Dotdotdot, useElementSize } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { getCampaignStatusType, getStatusIndicatorType } from '../../config';
import styles from './campaignPickerListItemContent.module.scss';

const classNameBuilder = classnames.bind(styles);

const SEARCH_TEXT_LINES = 15;
const MAX_LIST_ITEM_LINES = 3;

const CampaignPickerListItemContent = ({
    id,
    name,
    status,
    isSelected,
    searchText,
    onHeightResolved,
    windowRef,
    index,
}) => {
    const statusType = getCampaignStatusType(status);
    const statusIndicatorType = getStatusIndicatorType(statusType);
    const containerClassName = classNameBuilder('item', { selected: isSelected });
    const idLabelClassName = classNameBuilder('id-label');
    const nameLabelClassName = classNameBuilder('name-label');
    const highlightedTextClassName = classNameBuilder('highlighter-container', { selected: isSelected });

    const { height, ref } = useElementSize();
    const onHeightResolvedRef = useRef(onHeightResolved);
    onHeightResolvedRef.current = onHeightResolved;

    useLayoutEffect(() => {
        const onHeightResolved = onHeightResolvedRef.current;
        if (onHeightResolved && height) {
            onHeightResolved({ index, windowRef, height });
        }
    }, [index, windowRef, height]);

    return (
        <>
            <div className={styles['indicator']}>
                {statusIndicatorType && <StatusIndicator type={statusIndicatorType} />}
            </div>
            <div className={containerClassName} ref={ref}>
                <div className={nameLabelClassName}>
                    <Dotdotdot lines={searchText ? SEARCH_TEXT_LINES : MAX_LIST_ITEM_LINES}>
                        <Highlighter text={name} highlightedText={searchText} className={highlightedTextClassName} />
                    </Dotdotdot>
                </div>
                {isSelected && (
                    <div aria-label="Campaign ID Label" className={idLabelClassName}>
                        <FormattedMessage id="app.campaigns.campaign.displayName" values={{ id }} />
                    </div>
                )}
            </div>
        </>
    );
};

CampaignPickerListItemContent.propTypes = {
    id: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    status: PropTypes.string,
    searchText: PropTypes.string,
    onHeightResolved: PropTypes.func,
};

CampaignPickerListItemContent.defaultProps = {
    isSelected: false,
    status: '',
    onHeightResolved: noop,
};

export default CampaignPickerListItemContent;
