import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { size, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { useAvailableItems, useSelectedAccountId } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { TooltipWrapper } from '../../../../../../components/TooltipWrapper/TooltipWrapper';
import { useSelectedCampaignId } from '../../../../hooks';
import { selectedRowsSelector } from '../../../../selectors';
import { resetSelectedRows } from '../../actions';
import { useSelectedReportId } from '../../hooks';
import { ClearButton } from '../Toolbar';
import { bulkActionConfig } from './bulkAction';
import { useBulkActionData } from './hooks/useBulkActionData';
import styles from './bulkActionToolbar.module.scss';

export const BulkActionToolbar = ({ reportConfig, enableRowSelection, disableRowSelection }) => {
    const dispatch = useDispatch();
    const selectedRows = useSelector(selectedRowsSelector);
    const [accountId] = useSelectedAccountId();
    const [selectedReport] = useSelectedReportId();
    const [selectedCampaignId] = useSelectedCampaignId();
    const { open: openModal } = useModal();
    const [selectedAction, setSelectedAction] = useState(null);
    const { bulkActions: reportBulkActions } = reportConfig;
    const bulkActionData = useBulkActionData({ selectedRows });
    const availableBulkActions = useAvailableItems(reportBulkActions, bulkActionData);
    const bulkActions = useMemo(
        () =>
            availableBulkActions.map(({ getTooltip = noop, ...rest }) => ({
                ...rest,
                tooltip: getTooltip({ ...bulkActionData, ...rest }),
            })),
        [availableBulkActions, bulkActionData]
    );
    const { isLoading } = useQuery(
        ['bulkAction', selectedAction],
        async () => {
            if (!selectedAction) {
                return;
            }
            const { handler, getPrompt } = reportBulkActions.find(({ key }) => key === selectedAction);
            const currentAction = selectedAction;

            if (!handler) {
                return;
            }

            const resetAction = () => {
                setSelectedAction(prev => (prev === currentAction ? null : prev));
                enableRowSelection();
            };
            if (getPrompt) {
                const prompt = getPrompt({ selectedRows });
                const confirmed = await openModal(prompt);
                if (!confirmed) {
                    resetAction();
                    return;
                }
            }

            try {
                const result = await dispatch(handler(bulkActionData));
                resetAction();
                return result;
            } catch (error) {
                resetAction();
                throw error;
            }
        },
        { cacheTime: 0, staleTime: 0 }
    );

    const onClear = useCallback(() => dispatch(resetSelectedRows()), [dispatch]);

    useEffect(
        () => () => {
            setSelectedAction(null);
            enableRowSelection();
            onClear();
        },
        [selectedReport, accountId, selectedCampaignId, onClear, enableRowSelection]
    );

    const selectedCount = size(selectedRows);
    if (!selectedCount || isEmpty(bulkActions)) {
        return null;
    }

    return (
        <div
            className={styles['container']}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={selectedCount}
            data-metrics-component="BulkActions"
            role="toolbar"
        >
            <div className={styles['label']}>
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.selectedCount"
                    defaultMessage="{selectedCount} SELECTED"
                    values={{ selectedCount }}
                />
            </div>
            <div className={styles['divider']} />
            {bulkActions.map(
                ({ key, ButtonComponent = Button, icon, buttonContent, disabled, tooltip, tooltipOptions }) => (
                    <div key={key}>
                        <TooltipWrapper wrap={!!tooltip} tooltipContent={tooltip} tooltipOptions={tooltipOptions}>
                            <ButtonComponent
                                data-metrics-column-name={key}
                                onClick={() => {
                                    disableRowSelection();
                                    setSelectedAction(key);
                                }}
                                disabled={disabled || !isEmpty(selectedAction)}
                                size={Button.size.md}
                                variant={Button.variant.ghost}
                            >
                                <div className={styles['button-content']}>
                                    {key === selectedAction && isLoading && <LoadingAnimation animaSizePx={15} />}
                                    <span className={styles['icon']}>{icon ?? bulkActionConfig[key].icon}</span>
                                    {buttonContent ?? (
                                        <FormattedMessage
                                            id={`app.campaigns.reports.bulkAction.toolbar.${key.toLowerCase()}`}
                                            defaultMessage={key}
                                        />
                                    )}
                                </div>
                            </ButtonComponent>
                        </TooltipWrapper>
                    </div>
                )
            )}
            <ClearButton onClick={onClear}>
                <FormattedMessage
                    id="app.campaigns.reports.bulkAction.toolbar.clearSelection"
                    defaultMessage="Clear Selection"
                />
            </ClearButton>
        </div>
    );
};

BulkActionToolbar.propTypes = {
    reportConfig: PropTypes.object.isRequired,
};
