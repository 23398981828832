const createCSVValueGetter =
    valueFormatter =>
    (value, rowData, formatOptions, { i18n }) =>
        valueFormatter({
            value,
            rowData,
            formatOptions,
            i18n,
        });

export default createCSVValueGetter;
