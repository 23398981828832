import { callGetApi, callPostApi, callPutApi } from '../apiCallerProxy';
import { audiencesApiFactory } from './audiencesApiFactory';

export * from './hooks';

export const {
    getCustomContextualSegment,
    getMyAudience,
    getPredictiveAudience,
    getFirstPartyAudience,
    getMarketplaceSegments,
    getMarketplaceProviders,
    getMarketplaceTaxonomies,
    getContextualSegments,
    createPixelBasedAudience,
    createPixelBasedPredictiveAudience,
    createCrmFileAudience,
    sendCrmFileContentSampleForDebug,
    updatePixelBasedAudience,
    updatePredictiveAudience,
    updateFirstPartyAudience,
    createCustomContextualSegment,
    updateCustomContextualSegment,
    createSRTAudience,
    createMRTAudience,
    getSRTAudience,
    getMRTAudience,
    updateMRTAudience,
    updateSRTAudience,
    getSRTRecommendations,
    getMRTRecommendations,
    createCombinedAudience,
    getCombinedAudience,
    getCombinedAudienceIngredients,
    updateCombinedAudience,
    validateExternalAudienceAddedValues,
} = audiencesApiFactory({
    callGetApi,
    callPostApi,
    callPutApi,
});
