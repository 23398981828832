import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { useCampaignGroupExtensionEnabled } from '../../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { useCampaignBudgetRestrictions } from '../../BudgetSectionSponsored/hooks/useCampaignBudgetRestrictions';
import { useSpendingLimitOptions } from './useSpendingLimitOptions';

export const useBudgetFormFieldValue = () => {
    const isNoneSpendingLimitDisplayed = useSpendingLimitOptions();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const { campaignGroup } = useCampaignBudgetRestrictions();
    const fieldProps = useFormFieldValue(
        { field: 'budget' },
        { isNoneSpendingLimitDisplayed, campaignGroupExtensionEnabled, campaignGroup }
    );
    return fieldProps;
};
