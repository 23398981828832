import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { isFileTypeVideo } from '../../services/utils';
import CountView from '../CountView/CountView';
import PreviewWidgetsGallery from './PreviewWidgetsGallery/PreviewWidgetsGallery';
import styleConsts from '../../../../../../globalStyle/styleConsts.module.scss';
import styles from './previewWidgetField.module.scss';

const getWidgetCount = (widgets = {}) =>
    Object.values(widgets).reduce((count, widgetList) => count + widgetList.length, 0);
const anyFileTypeVideo = thumbnailsArray => thumbnailsArray.some(widget => isFileTypeVideo(widget.fileType));

const Description = ({ isVideoSelected }) => {
    if (isVideoSelected) {
        return (
            <FormattedMessage
                id="creative.creator.preview.versions.video.description"
                defaultMessage="Video files will play on mouse over, on publisher sites video will auto play. {br} We recommend 4-12 ads per campaign."
            />
        );
    }

    return (
        <FormattedMessage
            id="creative.creator.preview.versions.description"
            defaultMessage="We recommend 4-12 ads per campaign. (To remove a variation, mouse over it and click on the 'x'.)"
        />
    );
};

const PreviewWidgetField = ({ className, previewWidgets, onTogglePreviewItemStatus, selectedCampaigns }) => {
    const isVideoSelected = useMemo(() => Object.values(previewWidgets).some(anyFileTypeVideo), [previewWidgets]);

    return (
        <FormField
            label={
                <FormattedMessage
                    id="creative.creator.preview.title"
                    defaultMessage="Preview{countView}"
                    values={{ countView: <CountView data={previewWidgets} countingRule={getWidgetCount} /> }}
                />
            }
            description={<Description isVideoSelected={isVideoSelected} />}
            inputId="creative-creator-preview"
            containerClass={className}
            descriptionClass={styles['preview-gallery-description']}
        >
            <PreviewWidgetsGallery
                onToggleStatus={onTogglePreviewItemStatus}
                previewWidgets={previewWidgets}
                gap={styleConsts.galleryGridGap}
                minRowHeight={styles.galleryMinRowHeight}
                id="creative-creator-preview"
                selectedCampaigns={selectedCampaigns}
                className={styles['preview-gallery']}
            />
        </FormField>
    );
};

PreviewWidgetField.propTypes = {
    className: PropTypes.string,
    previewWidgets: PropTypes.object,
    onTogglePreviewItemStatus: PropTypes.func,
    selectedCampaigns: PropTypes.array,
};

PreviewWidgetField.defaultProps = {
    className: '',
    previewWidgets: {},
};

export default PreviewWidgetField;
