import React from 'react';
import PropTypes from 'prop-types';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import FormLayout from '../../components/Form/FormLayout';
import CampaignEditorFormBase from '../common-campaign-form/CampaignEditorFormBase';
import { openExchangeCampaignDuplicateValues } from '../common-campaign-form/config/campaignDuplicateValues';
import OpenExchangeCampaignEditorContent from './components/OpenExchangeCampaignEditorContent';

const OpenExchangeCampaignEditor = ({ onCancel }) => (
    <CampaignEditorFormBase onCancel={onCancel} ContentComponent={OpenExchangeCampaignEditorContent} />
);

OpenExchangeCampaignEditor.propTypes = { onCancel: PropTypes.func };

const OpenExchangeCampaignEditorWithFormDataProvider = withFormDataProvider(OpenExchangeCampaignEditor, {
    defaultValuesConfig: openExchangeCampaignDuplicateValues,
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export {
    OpenExchangeCampaignEditorWithFormDataProvider as OpenExchangeCampaignEditor,
    OpenExchangeCampaignEditor as OpenExchangeCampaignEditorUnwrapped,
};
