import { useEffect } from 'react';
import { FormField, Radio } from 'taboola-ultimate-ui';
import { RadioGroupField } from '../../../campaigns/components';
import { CampaignGroupMultiSelectField } from '../../../campaigns/modules/common-campaign-form/components/CampaignGroupField/CampaignGroupMultiSelectField';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { scheduledReportsCampaignGroupsValidations } from '../../validations/scheduledReportsCampaignGroupsValidations';
import {
    ALL_CAMPAIGN_GROUPS,
    CAMPAIGN_GROUPS_LIST_TYPES,
    SPECIFIC_CAMPAIGN_GROUPS,
} from './scheduledReportsCampaignGroupsListTypes';
import styles from './scheduledReportsCampaignGroupsListTypeFiled.module.scss';

export const ScheduledReportsCampaignGroupsListTypeFiled = () => {
    const {
        value: campaignGroupsSelectType,
        onChange: campaignGroupsSelectTypeTypeChange,
        resetFieldToInitial,
    } = useFormFieldValue({
        field: 'campaignGroupsSelectType',
    });
    const { resetFieldToInitial: resetGroups, isFieldEqualToInitial: isCampaignGroupsInitValue } = useFormFieldValue({
        field: 'campaignGroupList',
    });
    useEffect(() => resetFieldToInitial, [resetFieldToInitial]);
    useEffect(() => {
        if (campaignGroupsSelectType === ALL_CAMPAIGN_GROUPS && !isCampaignGroupsInitValue) {
            resetGroups();
        }
    }, [campaignGroupsSelectType, isCampaignGroupsInitValue, resetGroups]);

    return (
        <>
            <FormField
                inputId="campaign-groups-select-type"
                label={
                    <FormattedMessage
                        id="app.create.scheduled.reports.campaign.groups.select.title"
                        defaultMessage="Campaign Groups"
                    />
                }
            >
                <RadioGroupField
                    id="campaignGroupsSelectType"
                    name="select-type-"
                    radioGroupName="groupsListType"
                    className={styles['group']}
                    onChange={campaignGroupsSelectTypeTypeChange}
                    selectedValue={campaignGroupsSelectType}
                >
                    {CAMPAIGN_GROUPS_LIST_TYPES.map(campaignGroupsSelectType => (
                        <Radio
                            key={campaignGroupsSelectType}
                            value={campaignGroupsSelectType}
                            title={
                                <FormattedMessage
                                    id={`app.create.scheduled.reports.campaign.groups.select.type.${campaignGroupsSelectType}`}
                                />
                            }
                            labelClassName={styles['radio']}
                        />
                    ))}
                </RadioGroupField>
            </FormField>
            {campaignGroupsSelectType === SPECIFIC_CAMPAIGN_GROUPS && (
                <CampaignGroupMultiSelectField validations={scheduledReportsCampaignGroupsValidations} />
            )}
        </>
    );
};
