import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { useSelectedAccount } from '../../../../../../hooks';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { formatItemIds, useNavigateToAdsReport } from '../../hooks/useNavigateToAdsReport';
import usePerformanceRecommendationExecution from '../../hooks/usePerformanceRecommendationExecution';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const ItemWithoutCTAPerformanceRecommendation = ({ recommendation }) => {
    const [{ currency }] = useSelectedAccount();
    const { accountName: accountId, campaignId, messageParams, actions, id: recommendationId } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const { numOfItems, totalItemsSpend, itemIds } = messageParams;
    const singleMessageIdAddition = numOfItems === 1 ? 'single.' : '';
    const params = {
        titleCode: `performance-recommendations.item-without-cta.title`,
        descriptionCode: `performance-recommendations.item-without-cta.${singleMessageIdAddition}description`,
        viewCode: `performance-recommendations.item-without-cta.${singleMessageIdAddition}view`,
        dismissCode: `performance-recommendations.general-recommendation.dismiss`,
        successCode: `performance-recommendations.general-recommendation.success-message`,
        errorCode: `performance-recommendations.general-recommendation.error-message`,
        iconImg: LightningRoundIcon,
    };

    const formattedItemIds = useMemo(() => formatItemIds(itemIds), [itemIds]);

    const messageDetails = {
        ...messageParams,
        totalItemsSpend: <FormattedNumber value={totalItemsSpend} variant="currency" currency={currency} />,
        formattedItemIds,
    };

    const { navigateToAdsReport } = useNavigateToAdsReport({ formattedItemIds });

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: params.errorCode,
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageDetails}
            isApplied={apply.isSuccess}
            {...params}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode={params.dismissCode}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToAdsReport}
                    recommendation={recommendation}
                    textCode={params.viewCode}
                    dataMetricsComponent="RecommendationCardViewItemsButton"
                    defaultMessage="View Ads"
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

ItemWithoutCTAPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default ItemWithoutCTAPerformanceRecommendation;
