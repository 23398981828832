'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var limitTolerance = 10;
var defaultTimeout = 5;
var MS_PER_SEC = 1000;

var useShouldMemoizeValue = function useShouldMemoizeValue(_ref) {
    var value = _ref.value,
        message = _ref.message,
        _ref$limit = _ref.limit,
        limit = _ref$limit === undefined ? limitTolerance : _ref$limit,
        _ref$timeout = _ref.timeout,
        timeout = _ref$timeout === undefined ? defaultTimeout : _ref$timeout;

    var valueCounter = (0, _react.useRef)(0);
    var timeoutId = (0, _react.useRef)();
    var thrownFlag = (0, _react.useRef)();

    // On any new value if no counting interval we start new interval
    (0, _react.useEffect)(function () {
        if (thrownFlag.current) {
            return;
        }
        if (valueCounter.current >= limit) {
            thrownFlag.current = true;
            console.error(message + ' there are more then ' + limit + ' different values received.'); //eslint-disable-line
            return;
        }
        if (timeoutId.current) {
            return;
        }
        // we start counting value during timeout sec
        valueCounter.current = 0;
        timeoutId.current = setTimeout(function () {
            timeoutId.current = null;
        }, MS_PER_SEC * timeout);
    }, [value, message, limit, timeout]);

    // We just count the value instances
    (0, _react.useEffect)(function () {
        if (valueCounter.current >= limit) {
            return;
        }
        valueCounter.current += 1;
    }, [value]);
};

exports.default = useShouldMemoizeValue;