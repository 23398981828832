import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params/';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { valueStoreFactory } from 'services/utils/valueStoreFactory';
import { isConfiguredParentAppDomain } from './isConfiguredParentAppDomain';

export const trustedParentHost = valueStoreFactory();

export const PARENT_HOST_QUERY_PARAM = 'parentHost';
export const useParentHostParamInner = queryParamHookFactory(PARENT_HOST_QUERY_PARAM, {
    persist: PERSISTENCE_TYPE.MEMORY,
});

export const useParentHostParam = () => {
    const [value, ...rest] = useParentHostParamInner();
    if (value && isConfiguredParentAppDomain(value)) {
        trustedParentHost.setValue(value);
    }

    return [trustedParentHost.getValue(), ...rest];
};
