import React from 'react';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CREATIVE_TYPE } from '../../../config';
import { MediaLibraryTab, RecommendedMediaTab, UrlTab, AIGeneratedImagesTab } from '../components/MediaTabs';
import { CREATIVE_EDITOR_TABS } from './index';

export const thumbnailTabItems = [
    {
        id: CREATIVE_EDITOR_TABS.MEDIA_LIBRARY,
        caption: {
            id: 'creative.editor.tab.mediaLibrary.caption',
            default: 'Media Library',
        },
        component: MediaLibraryTab,
        metricsAttributes: () => ({
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'MediaLibraryTab',
        }),
        visibilityCondition: ({ accountConfig }) =>
            accountConfig[FEATURE_FLAGS.MEDIA_LIBRARY_IMAGE_DRAWERS] === 'true' ||
            accountConfig[FEATURE_FLAGS.MEDIA_LIBRARY_VIDEO_DRAWERS] === 'true',
    },
    {
        id: CREATIVE_EDITOR_TABS.URL_BASED,
        caption: {
            default: 'Upload & Create',
        },
        component: UrlTab,
        metricsAttributes: () => ({
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'UrlTab',
        }),
        visibilityCondition: ({ accountConfig }) =>
            accountConfig[FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED] !== 'true',
    },
    {
        id: CREATIVE_EDITOR_TABS.RECOMMENDED_MEDIA,
        caption: {
            default: 'Stock Images',
        },
        component: RecommendedMediaTab,
        metricsAttributes: () => ({
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'RecommendedMediaTab',
        }),
    },
    {
        id: CREATIVE_EDITOR_TABS.AI_GENERATED,
        caption: {
            id: 'creative.editor.tab.aiGenerated.caption',
            default: 'AI Generated',
        },
        component: AIGeneratedImagesTab,
        metricsAttributes: ({ campaignId }) => ({
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'AIGeneratedImagesTab - Generate With AI',
            'data-metrics-taboola-campaign-id': campaignId,
        }),
        visibilityCondition: ({ creativeType, accountConfig, permissionsMap }) =>
            accountConfig[FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI] === 'true' &&
            (accountConfig[FEATURE_FLAGS.AI_IMAGE_GENERATION_TAB_ENABLED] === 'true' ||
                permissionsMap['AI_IMAGE_GENERATION']) &&
            creativeType !== CREATIVE_TYPE.MOTION,
        isBeta: true,
        tagMessage: <FormattedMessage id="app.new" defaultMessage="New" />,
        tooltip: {
            id: 'creative.editor.tab.aiGenerated.tab.tooltip',
            defaultMessage:
                'Discover a world of limitless possibilities with AI where you can create unique images for your ads. The generated images are free to use and do not carry copyright restrictions within Taboola Ads, ensuring you have the freedom to bring your creative ideas to life',
        },
    },
];
