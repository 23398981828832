import React, { useMemo } from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './safetyNetsStatusActionDetails.module.scss';

const RoundDownNum = ({ num }) => {
    const roundedNum = Math.floor(num * 2) / 2;
    return roundedNum !== 0 ? roundedNum : num;
};

const ActionReasonContainsDetails = ({ spendGuardsActionReason, spendGuardsActionReasonParams, currency }) => {
    const formattedSpendGuardsActionReasonParams = useMemo(() => {
        if (spendGuardsActionReasonParams) {
            const formattedResult = { ...spendGuardsActionReasonParams };

            for (const key in spendGuardsActionReasonParams) {
                if (spendGuardsActionReasonParams.hasOwnProperty(key)) {
                    if (typeof spendGuardsActionReasonParams[key] === 'number') {
                        spendGuardsActionReasonParams[key] = RoundDownNum({ num: spendGuardsActionReasonParams[key] });
                        const fractionDigits = spendGuardsActionReasonParams[key] % 1 ? 1 : 0;

                        formattedResult[`${key}Currency`] = (
                            <FormattedNumber
                                value={spendGuardsActionReasonParams[key]}
                                variant="currency"
                                currency={currency}
                                minimumFractionDigits={fractionDigits}
                                maximumFractionDigits={fractionDigits}
                            />
                        );

                        formattedResult[key] = (
                            <FormattedNumber
                                value={spendGuardsActionReasonParams[key]}
                                minimumFractionDigits={fractionDigits}
                                maximumFractionDigits={fractionDigits}
                            />
                        );
                    }
                }
            }
            return formattedResult;
        }
        return null;
    }, [spendGuardsActionReasonParams, currency]);

    if (spendGuardsActionReasonParams) {
        return (
            <span>
                <FormattedMessage
                    id={`app.campaigns.sites.safety.nets.status.action.details.blocking.reason.${spendGuardsActionReason}`}
                    values={{
                        ...formattedSpendGuardsActionReasonParams,
                    }}
                />
            </span>
        );
    }
    return (
        <span>
            <FormattedMessage id="app.campaigns.sites.safety.nets.status.action.details.blocking.reason.DEFAULT" />
        </span>
    );
};

const ActionDetails = ({ className, id, defaultMessage, children }) => {
    return (
        <div className={className}>
            <span className={styles['action-details-fields-title']}>
                <FormattedMessage id={id} defaultMessage={defaultMessage} />
            </span>
            {children}
        </div>
    );
};

export const SafetyNetsStatusActionDetails = ({
    siteDescription,
    spendGuardsActionReason,
    spendGuardsActionReasonParams,
    spendGuardsActionTimeInUtc,
    currency,
}) => {
    return (
        <div className={styles['container']}>
            <ActionDetails
                id="app.campaigns.sites.safety.nets.status.action.details.site.name"
                defaultMessage="Site name: "
                values={siteDescription}
            >
                <span>{siteDescription}</span>
            </ActionDetails>

            {spendGuardsActionTimeInUtc && (
                <ActionDetails
                    id="app.campaigns.sites.safety.nets.status.action.details.date.of.block"
                    defaultMessage="Date of block: "
                >
                    <span>{moment(spendGuardsActionTimeInUtc).format('MMMM Do, YYYY')}</span>
                </ActionDetails>
            )}

            {spendGuardsActionReason &&
                spendGuardsActionReasonParams &&
                spendGuardsActionReasonParams.startTimestamp &&
                spendGuardsActionReasonParams.endTimestamp && (
                    <ActionDetails
                        id="app.campaigns.sites.safety.nets.status.action.details.time.range.checked"
                        defaultMessage="Time range checked: "
                        className={styles['time-range-container']}
                    >
                        <div
                            className={
                                styles['app.campaigns.sites.safety.nets.status.action.details.time.range.checked.value']
                            }
                        >
                            <FormattedMessage
                                id="timeRange"
                                defaultMessage="{startTimestamp} - {endTimestamp}"
                                values={{
                                    startTimestamp: moment(spendGuardsActionReasonParams.startTimestamp).format(
                                        'MMMM Do, YYYY'
                                    ),
                                    endTimestamp: moment(spendGuardsActionReasonParams.endTimestamp).format(
                                        'MMMM Do, YYYY'
                                    ),
                                }}
                            />
                        </div>
                    </ActionDetails>
                )}
            <ActionDetails
                id="app.campaigns.sites.safety.nets.status.action.details.blocking.reason"
                defaultMessage="Blocking reason: "
            >
                <ActionReasonContainsDetails
                    spendGuardsActionReason={spendGuardsActionReason}
                    spendGuardsActionReasonParams={spendGuardsActionReasonParams}
                    currency={currency}
                />
            </ActionDetails>
        </div>
    );
};
