export const REQUEST_CREATE_CAMPAIGNS_GROUP = 'REQUEST_CREATE_CAMPAIGNS_GROUP';
export const CREATE_CAMPAIGNS_GROUP_SUCCESS = 'CREATE_CAMPAIGNS_GROUP_SUCCESS';
export const CREATE_CAMPAIGNS_GROUP_ERROR = 'CREATE_CAMPAIGNS_GROUP_ERROR';
export const REQUEST_SAVE_CAMPAIGNS_GROUP = 'REQUEST_SAVE_CAMPAIGNS_GROUP';
export const SAVE_CAMPAIGNS_GROUP_SUCCESS = 'SAVE_CAMPAIGNS_GROUP_SUCCESS';
export const SAVE_CAMPAIGNS_GROUP_ERROR = 'SAVE_CAMPAIGNS_GROUP_ERROR';

export const REQUEST_FETCH_CAMPAIGNS_GROUP_FOR_EDIT = 'REQUEST_FETCH_CAMPAIGNS_GROUP_FOR_EDIT';
export const FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS = 'FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS';
export const FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR = 'FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR';

export const FETCH_CAMPAIGN_GROUP_LIST_SUCCESS = 'FETCH_CAMPAIGN_GROUP_LIST_SUCCESS';
