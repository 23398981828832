import updateObject from '../../../../taboola-common-frontend-modules/utils/updateObject';
import {
    FETCH_OS_SUCCESS,
    FETCH_OS_ERROR,
    REQUEST_FETCH_BROWSERS,
    FETCH_BROWSERS_SUCCESS,
    FETCH_BROWSERS_ERROR,
    REQUEST_FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_ERROR,
    INVALIDATE_SUBCOUNTRY_DATA,
    REQUEST_FETCH_BRAND_SAFETY_CATEGORIES,
    FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS,
    FETCH_BRAND_SAFETY_CATEGORIES_ERROR,
    REQUEST_FETCH_DMAS,
    FETCH_DMAS_SUCCESS,
    FETCH_DMAS_ERROR,
    REQUEST_FETCH_CITIES,
    FETCH_CITIES_SUCCESS,
    FETCH_CITIES_ERROR,
    FETCH_TIME_ZONES_SUCCESS,
    FETCH_TIME_ZONES_ERROR,
} from '../actions';

const initialState = {
    listOfRegions: [],
    isListOfRegionsLoading: false,
    listOfDMAs: [],
    isListOfDMAsLoading: false,
    listOfCities: [],
    isListOfCitiesLoading: false,
    listOfOS: [],
    listOfBrowsers: [],
    listOfTimeZones: [],
    brandSafetyCategories: {},
};

const reducer = {
    [INVALIDATE_SUBCOUNTRY_DATA]: state => updateObject(state, { listOfRegions: [], listOfDMAs: [] }),
    [REQUEST_FETCH_REGIONS]: state => updateObject(state, { listOfRegions: [], isListOfRegionsLoading: true }),
    [FETCH_REGIONS_SUCCESS]: (state, { payload }) =>
        updateObject(state, { listOfRegions: payload, isListOfRegionsLoading: false }),
    [FETCH_REGIONS_ERROR]: state => updateObject(state, { isListOfRegionsLoading: false }),
    [REQUEST_FETCH_BROWSERS]: state => updateObject(state, { listOfBrowsers: [] }),
    [FETCH_BROWSERS_SUCCESS]: (state, { payload }) => updateObject(state, { listOfBrowsers: payload }),
    [FETCH_BROWSERS_ERROR]: state => updateObject(state, { listOfBrowsers: [] }),
    [REQUEST_FETCH_BRAND_SAFETY_CATEGORIES]: (state, { meta }) =>
        updateObject(state, {
            brandSafetyCategories: {
                ...state.brandSafetyCategories,
                [meta.provider]: [],
            },
        }),
    [FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS]: (state, { payload, meta }) =>
        updateObject(state, {
            brandSafetyCategories: {
                ...state.brandSafetyCategories,
                [meta.provider]: payload,
            },
        }),
    [FETCH_BRAND_SAFETY_CATEGORIES_ERROR]: (state, { meta }) =>
        updateObject(state, {
            brandSafetyCategories: {
                ...state.brandSafetyCategories,
                [meta.provider]: [],
            },
        }),
    [REQUEST_FETCH_DMAS]: state => updateObject(state, { listOfDMAs: [], isListOfDMAsLoading: true }),
    [FETCH_DMAS_SUCCESS]: (state, { payload }) =>
        updateObject(state, { listOfDMAs: payload, isListOfDMAsLoading: false }),
    [FETCH_DMAS_ERROR]: state => updateObject(state, { isListOfDMAsLoading: false }),
    [REQUEST_FETCH_CITIES]: state => updateObject(state, { listOfCities: [], isListOfCitiesLoading: true }),
    [FETCH_CITIES_SUCCESS]: (state, { payload }) =>
        updateObject(state, { listOfCities: payload, isListOfCitiesLoading: false }),
    [FETCH_CITIES_ERROR]: state => updateObject(state, { isListOfCitiesLoading: false }),
    [FETCH_OS_SUCCESS]: (state, action) => updateObject(state, { listOfOS: action.payload }),
    [FETCH_OS_ERROR]: state => updateObject(state, { listOfOS: [] }),
    [FETCH_TIME_ZONES_SUCCESS]: (state, action) => updateObject(state, { listOfTimeZones: action.payload }),
    [FETCH_TIME_ZONES_ERROR]: state => updateObject(state, { listOfTimeZones: initialState.listOfTimeZones }),
};

export { initialState };
export default reducer;
