import React from 'react';
import { BareDropdown } from 'taboola-ultimate-ui';
import { INCLUDE_EXCLUDE_OPTIONS } from '../../../config';
import TargetingTypeDropdownOption from '../TargetingTypeDropdownOption';

const TargetingTypeBareDropdown = ({
    value,
    options = INCLUDE_EXCLUDE_OPTIONS,
    optionItemRenderer = TargetingTypeDropdownOption,
    onChange,
    disabled,
    ...rest
}) => (
    <BareDropdown
        value={value}
        disabled={disabled}
        options={options}
        optionItemRenderer={optionItemRenderer}
        onChange={onChange}
        skipRightBorderRadius
        {...rest}
    />
);

TargetingTypeBareDropdown.propTypes = {};

export default TargetingTypeBareDropdown;
