import React, { useCallback, useState } from 'react';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { DropdownOption } from '../../../../components/Dropdown';
import DropdownWrapper from '../../../../components/DropdownWrapper/PaginatedDropdownWrapper';
import { FORM_MODES } from '../../../../config/formModes';
import { useResourcesApi } from '../../../../services/api';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { validationFunctions } from '../../../taboola-common-frontend-modules/validations';
import { useFormValidatedValue } from '../../../taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import commonStyles from '../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import styles from './paymentMethodCountry.module.scss';

const COUNTRY_MSG_ID_PREFIX = 'app.country.code';
const PaginatedDropdownWithIndication = withIndication(DropdownWrapper);
const validations = [
    {
        validationFn: (value, options) => validationFunctions.isNotEmptyString(value, options),
        messageId: 'validations.error.paymentMethod.country.empty',
        defaultMessage: 'Enter Country',
    },
];

export const PaymentMethodCountry = () => {
    const { formatMessage } = useIntl();
    const {
        value: selectedCountry,
        onChange: onSelectCountry,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'billingAddress.country',
        validations,
    });
    const [countries, setCountries] = useState([]);
    const { getCountries } = useResourcesApi();
    const loadCountriesPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getCountries(searchText, page, pageSize);
            const list = results.map(country => ({
                value: country.name,
                label: country.value,
                code: country.name,
                group: country.group,
            }));
            return [list, total];
        },
        [getCountries]
    );
    const onCountryChange = useCallback(({ value }) => onSelectCountry(value), [onSelectCountry]);

    return (
        <FormField
            inputId="country"
            ref={scrollRef}
            containerClass={commonStyles['input']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.country" defaultMessage="Country" />}
        >
            <PaginatedDropdownWithIndication
                id="country-selector"
                onChange={onCountryChange}
                searchable
                value={selectedCountry}
                enabledWhileSearching
                options={countries}
                dropdownWrapperClassName={styles['dropdown-container']}
                disabled={!countries.length || mode !== FORM_MODES.CREATE}
                loadPage={loadCountriesPage}
                optionItemRenderer={args => DropdownOption({ ...args, msgIdPrefix: COUNTRY_MSG_ID_PREFIX })}
                DropdownMenuComponent={BareDropdown}
                placeholder={formatMessage({
                    id: 'paymentMethod.creator.CARD.country.placeholder',
                })}
                valueRenderer={undefined}
                onOptionsLoaded={setCountries}
                {...indicationData}
            />
        </FormField>
    );
};
