import {
    FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR,
    FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS,
    REQUEST_FETCH_CAMPAIGNS_GROUP_FOR_EDIT,
} from '../../campaigns-group/actions';

export const requestFetchCampaignsGroupForEdit = () => ({ type: REQUEST_FETCH_CAMPAIGNS_GROUP_FOR_EDIT });

export const fetchCampaignsGroupForEditSuccess = campaignsGroup => ({
    type: FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS,
    payload: campaignsGroup,
});

export const fetchCampaignsGroupForEditError = error => ({
    type: FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR,
    payload: error,
});
