import { useMemo } from 'react';
import { ToggleSwitch } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { HelpTooltip } from 'taboola-ultimate-ui';
import { CONDITION_DIMENSIONS } from 'modules/scheduled-reports/components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';
import { isNetworkAccountType } from '../../../account-management';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './scheduledReportsRulesSettingField.module.scss';

export const ScheduledReportsRulesSettingField = () => {
    const { value: dimension } = useFormFieldValue({
        field: 'dimension',
    });
    const { value: includeConversionsRules, onChange: setIncludeConversionsRules } = useFormFieldValue({
        field: 'includeConversionsRules',
    });
    const { type } = useAccount();
    const isAccountNetwork = useMemo(() => isNetworkAccountType(type), [type]);

    if (CONDITION_DIMENSIONS.CUSTOM_RULES_BY_DAY === dimension || isAccountNetwork) {
        return null;
    }

    return (
        <FormField
            inputId="scheduled-report-reles-setting"
            containerClass={styles['container']}
            label={
                <FormattedMessage
                    id="app.scheduled.reports.create.rules.setting.title"
                    defaultMessage="Conversion Rule Settings"
                />
            }
        >
            <ToggleSwitch
                checked={includeConversionsRules}
                onChange={setIncludeConversionsRules}
                className={styles['toggle']}
            />
            <FormattedMessage
                id="app.scheduled.reports.create.rules.setting.toggle.text"
                defaultMessage="Include a breakdown of conversion rules as additional columns."
                className="description"
            />
            <HelpTooltip iconClassName={styles['toggle-help']}>
                <FormattedMessage
                    id="app.scheduled.reports.create.rules.setting.toggle.help.text"
                    defaultMessage="Select this option if you are tracking multiple conversion events and would like to see the rules broken out by columns."
                />
            </HelpTooltip>
        </FormField>
    );
};
