import { matchPath, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { MODULE_TEMPLATE } from 'config/routes';
import { FEATURE_FLAGS, useConfigMatch } from '../modules/taboola-common-frontend-modules/account-configurations';
import { useDrawerStack } from './queryParams/useDrawerStack';

export const useModuleName = () => {
    const routeMatch = useRouteMatch(MODULE_TEMPLATE);
    const isRenderReportConstantlyEnabled = useConfigMatch({
        [FEATURE_FLAGS.RENDER_REPORT_CONSTANTLY_ENABLED]: 'true',
    });
    const { stack } = useDrawerStack();

    if (!stack.length || !isRenderReportConstantlyEnabled) {
        return get(routeMatch, 'params.module');
    }

    const match = matchPath(stack[0].path, { path: stack[0].pattern });

    if (!match) {
        return get(routeMatch, 'params.module');
    }

    return get(match, 'params.module');
};
