import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ChromeExtensionButton } from './ChromeExtensionButton';
import styles from './testPixel.module.scss';

export const TestPixel = () => (
    <CommonCollapsibleCard
        id="TestPixel"
        header={
            <SectionHeader
                headerText={<FormattedMessage id="tracking.setup.test.pixel.title" defaultMessage="Test Pixel" />}
            />
        }
    >
        <div className={styles['container']}>
            <FormField
                description={
                    <FormattedMessage
                        id="tracking.setup.test.pixel.description"
                        defaultMessage="To test the installation, add the Taboola Pixel extension to your browser.
                            This extension can also be used for troubleshooting tracking issues and test conversion events.
                            "
                    />
                }
            />
            <div>
                <FormField
                    label={
                        <FormattedMessage
                            id="tracking.setup.add.chrome.extension"
                            defaultMessage="Add Chrome extension"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="tracking.setup.add.chrome.extension.description"
                            defaultMessage="After adding the Taboola Pixel helper, navigate to the website and open the extension"
                        />
                    }
                />
                <ChromeExtensionButton />
            </div>
            <Indication
                type="info"
                className={styles['notice-container']}
                message={
                    <FormattedMessage
                        id="tracking.setup.test.pixel.notice"
                        defaultMessage="It may take up to 20 minutes for your pixel status to change in the platform after installation."
                    />
                }
                contentClassName={styles['notice']}
                displayDismissComponent={false}
            />
        </div>
    </CommonCollapsibleCard>
);
