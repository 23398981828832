import { useCallback, useMemo } from 'react';
import { isEmpty, stubTrue } from 'lodash';
import { AutoAwesomeOutlinedIcon } from 'tuui';
import { HelpWriteText } from 'modules/campaigns/modules/creative-creator/components/AiVariationsCreation/components/HelpWrite/HelpWriteButton';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { SuggestionTextButton } from '../../SuggestionsWrapper';
import { SUGGESTION_TYPES, useTitleSuggestions } from './useTitleSuggestions';
import styles from '../suggestionContentTitle.module.scss';

const SUGGESTION_TYPE_TO_CONTENT = {
    [SUGGESTION_TYPES.REPHRASE]: {
        gaEventComponent: 'Rephrase',
        buttonContent: (
            <FormattedMessage id="creative.creator.aiTitleSuggestions.rephrase.button" defaultMessage="Rephrase" />
        ),
        dropdownChangeSuffix: '_Title_Rephrase_Selection',
        getShouldShowIndicator: ({ isGenAiEnabled, isHovered }) => isGenAiEnabled && isHovered,
    },
    [SUGGESTION_TYPES.FIX_DKI]: {
        gaEventComponent: 'Fix_DKI',
        buttonContent: (
            <FormattedMessage id="creative.creator.aiTitleSuggestions.fixDKI.button" defaultMessage="Fix DKI" />
        ),
        dropdownChangeSuffix: '_Title_Fix_DKI_Selection',
        getShouldShowIndicator: stubTrue,
    },
};

export const useSuggestionCustomIndicator = ({
    campaignId,
    title,
    validationDependencies: validationDependenciesProp,
    isHovered,
    setIsHovered,
    isDropdownOpen,
    setIsDropdownOpen,
    gaEventsPrefix,
    openModal,
    pageName,
    setTitle,
    type = SUGGESTION_TYPES.REPHRASE,
}) => {
    const isGenAiEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });
    const isDKIValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.LLM_DKI_DETECTION_ENABLED]: 'true',
    });

    const shouldShowCustomIndicator = SUGGESTION_TYPE_TO_CONTENT[type].getShouldShowIndicator({
        isGenAiEnabled,
        isHovered,
    });

    const {
        status,
        suggestions,
        isFetchingError: isSuggestionFetchingError,
        previousInvalidTitle,
        reset: resetSuggestions,
        resetError,
        getSuggestions,
    } = useTitleSuggestions({ type, campaignId });

    const validationDependencies = useMemo(
        () => ({
            isDKIValidationEnabled,
            isSuggestionFetchingError,
            previousInvalidTitle,
            ...validationDependenciesProp,
        }),
        [isDKIValidationEnabled, isSuggestionFetchingError, previousInvalidTitle, validationDependenciesProp]
    );

    const onSuggestionButtonClick = () => {
        resetSuggestions();
        setIsDropdownOpen(true);
        getSuggestions(title);
    };

    const HelpButton = ({ className }) => {
        return (
            <SuggestionTextButton
                className={className}
                dataTestId="help-me-write-button"
                onClick={() => {
                    resetSuggestions();
                    openModal();
                }}
            >
                <HelpWriteText />
            </SuggestionTextButton>
        );
    };

    const SuggestionButton = ({ className }) => {
        const { gaEventComponent, buttonContent } = SUGGESTION_TYPE_TO_CONTENT[type];

        return (
            <SuggestionTextButton
                className={className}
                onClick={onSuggestionButtonClick}
                disabled={isEmpty(title) || status === COMPONENT_STATUS.LOADING}
                dataTestId="suggestion-button"
                metricProps={{
                    'data-metrics-component': gaEventComponent,
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-taboola-campaign-id': campaignId,
                    'data-metrics-value': title,
                    'data-metrics-page-name': pageName,
                }}
                icon={AutoAwesomeOutlinedIcon}
                iconClassName={styles['rephrase-icon']}
            >
                {buttonContent}
            </SuggestionTextButton>
        );
    };

    const onDropdownChange = useCallback(
        valObj => {
            const { value } = valObj;
            setTitle(value);
            setIsDropdownOpen(false);
            setIsHovered(false);
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${gaEventsPrefix}${SUGGESTION_TYPE_TO_CONTENT[type].dropdownChangeSuffix}`,
                taboolaCampaignId: campaignId,
                value,
                pageName,
            });

            if (type === SUGGESTION_TYPES.FIX_DKI) {
                resetSuggestions();
            }
        },
        [campaignId, gaEventsPrefix, pageName, resetSuggestions, setIsDropdownOpen, setIsHovered, setTitle, type]
    );

    const shouldHideOptions = !isDropdownOpen || status !== COMPONENT_STATUS.ACTIVE || suggestions.length === 0;

    const shouldRenderLoader = status === COMPONENT_STATUS.LOADING;

    let CustomIndicator = SuggestionButton;

    if (type === SUGGESTION_TYPES.REPHRASE && !title && openModal) {
        CustomIndicator = HelpButton;
    }

    return {
        shouldShowCustomIndicator,
        CustomIndicator,
        shouldHideOptions,
        shouldRenderLoader,
        suggestions,
        resetCustomIndicatorError: resetError,
        validationDependencies,
        onDropdownChange,
    };
};
