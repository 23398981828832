import { processingStatus } from '../config/excelColumns';
import { GREEN_FILL_STYLE, RED_FILL_STYLE, HEADER_ROW_STYLE, GENERAL_CELL_STYLE } from '../consts/cellStylesConst';
import { getSheetName, getHeaderName, getBaseExampleText, DEFAULT_WIDTH } from '../utils/excelBulkWorkbookUtils';
import {
    addRowStyle,
    addCellStyle,
    addColumnStyle,
    removeEmptyRows,
    removeAllNotes,
    resetRowHeights,
} from '../utils/excelUtils';

const STATUS_COLUMN_NUMBER = 1;

const getRowNumber = (headerRowIndex, dataIndex) => headerRowIndex + 1 + dataIndex;

const getStatusMessages = ({ entity, formatMessage }) => {
    const headerName = getHeaderName({ entity, column: processingStatus, formatMessage });
    const exampleText = getBaseExampleText({ entity, column: processingStatus, formatMessage });
    const creationFailMessage = formatMessage({
        id: 'excel.report.creation.failed.status',
        defaultMessage: 'Operation Failed',
    });
    const creationSuccessMessage = formatMessage({
        id: 'excel.report.creation.success.status',
        defaultMessage: 'Operation Successful',
    });
    const creationSkippedMessage = formatMessage({
        id: 'excel.report.creation.skipped.status',
        defaultMessage: 'Skipped Operation',
    });

    return { headerName, exampleText, creationFailMessage, creationSuccessMessage, creationSkippedMessage };
};

const shiftValidations = (worksheet, addedColumnNumber) => {
    const lastColumnNumber = worksheet.columnCount + 1;
    worksheet.eachRow({ includeEmpty: true }, row => {
        // Iterates from last column to new column, shifting validations to the right by one
        for (let columnNumber = lastColumnNumber; columnNumber > addedColumnNumber; columnNumber--) {
            const currentCell = row.getCell(columnNumber);
            const previousCell = row.getCell(columnNumber - 1);

            // Optimization: only shift the validation if it's different from the previous one.
            if (currentCell.dataValidation !== previousCell.dataValidation) {
                currentCell.dataValidation = previousCell.dataValidation;
            }
        }

        row.getCell(addedColumnNumber).dataValidation = undefined;
    });
};

const addReportColumn = ({
    worksheet,
    entityConfig,
    exampleText,
    headerName,
    data,
    shouldReplaceColumn,
    columnNumber,
    columnWidth = DEFAULT_WIDTH,
    style = GENERAL_CELL_STYLE,
}) => {
    const { exampleRowIndex, headerRowIndex } = entityConfig;

    //spliceColumns is called twice to enable notes removal, which is otherwise disabled due to an excelJs bug
    if (shouldReplaceColumn) {
        worksheet.spliceColumns(columnNumber, 1);
        worksheet.spliceColumns(columnNumber, 0, []);
    } else {
        worksheet.spliceColumns(columnNumber, 0, []);
        shiftValidations(worksheet, columnNumber);
    }

    addColumnStyle({ worksheet, columnNumber, style });
    worksheet.getRow(exampleRowIndex).getCell(columnNumber).value = exampleText;
    worksheet.getRow(headerRowIndex).getCell(columnNumber).value = headerName;

    data.forEach((value, index) => {
        const rowNumber = getRowNumber(headerRowIndex, index);
        const cell = worksheet.getRow(rowNumber).getCell(columnNumber);
        cell.value = value;
    });

    if (columnWidth) {
        worksheet.getColumn(columnNumber).width = columnWidth;
    }

    addCellStyle({ worksheet, rowNumber: headerRowIndex, columnNumber, style: HEADER_ROW_STYLE });
};

const addStatusColumn = ({ worksheet, responseData, entityConfig, isStatusColumnInSheet, formatMessage }) => {
    const { entity } = entityConfig;
    const { headerName, exampleText, creationFailMessage, creationSuccessMessage, creationSkippedMessage } =
        getStatusMessages({
            formatMessage,
            entity,
        });

    const statusData = responseData.map(({ error }) => {
        if (!error) {
            return creationSuccessMessage;
        } else if (error.skippedUpload) {
            return creationSkippedMessage;
        } else {
            const { offendingField, message, skippedUpload } = error;
            const errorMessage = getErrorMessage({
                offendingField,
                errorMessage: message,
                skippedUpload,
                formatMessage,
            });
            return `${creationFailMessage}\n\n${errorMessage}`;
        }
    });

    addReportColumn({
        worksheet,
        headerName,
        exampleText,
        entityConfig,
        shouldReplaceColumn: isStatusColumnInSheet,
        columnNumber: STATUS_COLUMN_NUMBER,
        data: statusData,
    });
};

const getErrorMessage = ({ offendingField, errorMessage, skippedUpload, formatMessage }) => {
    if (skippedUpload) {
        return formatMessage({
            id: 'excel.report.skipped.note',
            defaultMessage:
                'Operation has been skipped due to error in another row, please fix all rows with "Failed to Create" status before continuing',
        });
    } else if (offendingField) {
        return formatMessage(
            { id: 'excel.report.failed.field.note', defaultMessage: 'Error:\n{offendingField}: {errorMessage}' },
            { offendingField, errorMessage }
        );
    } else {
        return formatMessage(
            { id: 'excel.report.failed.fields.note', defaultMessage: 'Errors:\n{errorMessage}' },
            { errorMessage }
        );
    }
};

const setRowStatusFill = ({ worksheet, rowNumber, error }) => {
    const statusStyle = error ? RED_FILL_STYLE : GREEN_FILL_STYLE;

    addRowStyle({ worksheet, rowNumber, style: statusStyle });
};

const getIsStatusColumnInSheet = ({ worksheet, entityConfig, formatMessage }) => {
    const { entity, headerRowIndex } = entityConfig;
    const statusHeaderCell = worksheet.getRow(headerRowIndex).getCell(STATUS_COLUMN_NUMBER);
    const { headerName: statusHeaderName } = getStatusMessages({
        formatMessage,
        entity,
    });

    return statusHeaderCell.value === statusHeaderName;
};

export const addReportToWorkbook = ({ workbook, responseData, formatMessage, entityConfig }) => {
    const { headerRowIndex, sheetName } = entityConfig;

    const formattedSheetName = getSheetName(sheetName, formatMessage);
    const worksheet = workbook.getWorksheet(formattedSheetName);
    const isStatusColumnInSheet = getIsStatusColumnInSheet({ worksheet, entityConfig, formatMessage });

    // Filter out empty rows, add status column, and remove unnecessary notes
    removeEmptyRows(worksheet);
    resetRowHeights(worksheet);
    if (isStatusColumnInSheet) {
        removeAllNotes(worksheet);
    }
    addStatusColumn({ worksheet, responseData, formatMessage, entityConfig, isStatusColumnInSheet });

    // Add row color
    responseData.forEach((responseValue, index) => {
        const rowNumber = getRowNumber(headerRowIndex, index);
        const { error } = responseValue;

        setRowStatusFill({ worksheet, rowNumber, error });
    });
};
