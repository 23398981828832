import { reportsBaseConfig, REPORT_TYPE, urlValidations } from '../../../../../config';
import {
    ContentCellRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    ImageCellRenderer,
    IMAGE_CELL_RENDERER_MODES,
    LinkCellRenderer,
} from '../../../components';
import { normalizeColumnDefs } from '../../../utils';
import { bypassMetadataFilterType, leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const dcoItemPerformanceReportColumnDefinition = [
    {
        headerName: FIELDS.CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGN_NAME.cellRenderer,
        minWidth: 140,
    },
    {
        headerName: FIELDS.ITEM_ID.label,
        type: [bypassMetadataFilterType, leftPinnedColumn],
        field: FIELDS.ITEM_ID.field,
        cellRendererParams: {
            lines: 1,
        },
        width: 120,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.THUMBNAIL_URL_COMFORTABLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
        type: [leftPinnedColumn, bypassMetadataFilterType],
        valueGetter: ({ data }) => {
            const { thumbnailUrl } = data;
            return thumbnailUrl;
        },
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.COMFORTABLE,
        },
        width: 186,
        csv: [
            {
                headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
                field: FIELDS.THUMBNAIL_URL.field,
            },
        ],
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL.field,
        type: [leftPinnedColumn],
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.DEFAULT,
        },
        minWidth: 120,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.CONTENT.label,
        type: [bypassMetadataFilterType],
        field: FIELDS.TITLE.field,
        cellRendererFramework: ContentCellRenderer,
        cellRendererParams: {
            lines: 2,
        },
        width: 270,
    },
    {
        headerName: FIELDS.URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.URL.headerComponentParams,
        field: FIELDS.URL.field,
        type: [],
        cellRendererFramework: LinkCellRenderer,
        width: 270,
        cellEditorParams: {
            validations: urlValidations,
        },
    },
    ...[
        FIELDS.SPENT,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CPA,
        FIELDS.VCPM,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    dcoItemPerformanceReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.DCO_ITEM_PERFORMANCE].id
);
