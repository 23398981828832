import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ScheduledReportsAccountSelectField } from '../ScheduledReportsAccountSelectField/ScheduledReportsAccountSelectField';
import { ScheduledReportDateRangeField } from '../ScheduledReportsDateRangeField/ScheduledReportDateRangeField';
import { ScheduledReportDimensionField } from '../ScheduledReportsDimensionField/ScheduledReportDimensionField';
import { ScheduledReportNameField } from '../ScheduledReportsNameFiled/ScheduledReportNameField';
import { ScheduledReportsRulesSettingField } from '../ScheduledReportsRulesSettingField/ScheduledReportsRulesSettingField';
import styles from './scheduledReportSetupSection.module.scss';

export const ScheduledReportSetupSection = ({ showNameField = true }) => {
    return (
        <CommonCollapsibleCard
            id="SCHEDULED_REPORT_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="app.create.scheduled.reports.setup.step.title"
                            defaultMessage="Report Setup"
                        />
                    }
                />
            }
        >
            <div className={styles['field-container']}>
                <ScheduledReportDimensionField />
                <ScheduledReportsAccountSelectField />
                <ScheduledReportDateRangeField />
                {showNameField && <ScheduledReportNameField />}
                <ScheduledReportsRulesSettingField />
            </div>
        </CommonCollapsibleCard>
    );
};
