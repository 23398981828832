export const MINIMUM_ADS = 4;
export const MINIMUM_ADS_RUNNING = 4;
export const ONBOARDING_DURATION_DAYS = 60;

export const LOCAL_STORAGE_ACCOUNT_KEYS = {
    IS_CLOSED: 'is_closed',
    IS_COLLAPSED: 'is_collapsed',
    COMPLETION_DATA: 'completion_data',
    CTA_DELAY_DATA: 'cta_delay_data',
};

export const LOCAL_STORAGE_KEY = 'onboarding_assistant';

export const PROGRESS_STATUS = {
    40: 'low',
    60: 'average',
    80: 'good',
    99: 'very.good',
    100: 'excellent',
};

const SUB_STEP_NAMES = {
    ADD_PAYMENT_METHOD: 'Add Payment Method',
    INSTALL_PIXEL: 'Install Pixel',
    CREATE_CONVERSIONS: 'Create Conversions',
    CREATE_CAMPAIGN: 'Create Campaign',
    ADD_NEW_CREATIVES: 'Add New Creatives',
    REVIEW_ADS_STATUS: 'Review Ads Status',
    REVIEW_CAMPAIGN_LEARNING: 'Review Campaign Learning',
    OPTIMIZE_REVIEW_SITES: 'Optimize Review Sites',
};

export const STEP_IDS = {
    ADD_PAYMENT_METHOD: 'add.payment.method',
    COMPLETE_ACCOUNT_SETUP: 'complete.account.setup',
    CREATE_CAMPAIGN: 'create.campaign',
    LAUNCH_YOUR_CAMPAIGN: 'launch.your.campaign',
    REVIEW_CAMPAIGN_PERFORMANCE: 'review.campaign.performance',
};

export const SUB_STEP_IDS = {
    COMPLETE_ACCOUNT_SETUP_1: 'complete.account.setup.step.1',
    COMPLETE_ACCOUNT_SETUP_2: 'complete.account.setup.step.2',
    CREATE_CAMPAIGN_1: 'create.campaign.step.1',
    CREATE_CAMPAIGN_2: 'create.campaign.step.2',
    LAUNCH_YOUR_CAMPAIGN_1: 'launch.your.campaign.step.1',
    LAUNCH_YOUR_CAMPAIGN_2: 'launch.your.campaign.step.2',
};

export const CTA_IDS = {
    SCHEDULE_HELP: 'schedule.help',
    INSTALL_PIXEL: 'install.pixel',
};

export const STEP_DATA = {
    ADD_PAYMENT_DATA: 'addPaymentData',
    COMPLETE_ACCOUNT_DATA: 'completeAccountData',
    CREATE_CAMPAIGN_DATA: 'createCampaignData',
    lAUNCH_CAMPAIGN_DATA: 'launchCampaignData',
    REVIEW_CAMPAIGN_DATA: 'reviewCampaignData',
};

export const SUB_STEP_DATA = {
    HAS_NON_FROZEN_ACCOUNT: 'hasNonFrozenAccount',
    HAS_ACTIVE_CONVERSION: 'hasActiveConversion',
    HAS_INSTALLED_PIXEL: 'hasInstalledPixel',
    HAS_DESKTOP_OR_MOBILE_CAMPAIGNS: 'hasDesktopOrMobileCampaigns',
    HAS_MINIMUM_ADS_PER_CAMPAIGN: 'hasMinimumAdsPerCampaign',
    HAS_MINIMUM_ADS_PER_CAMPAIGN_RUNNING: 'hasMinimumAdsPerCampaignRunning',
    HAS_LEARNING_OR_SCALING_CAMPAIGNS: 'hasLearningOrScalingCampaigns',
    HAS_VISIT_SITE_REPORT: 'hasVisitSiteReport',
};

export const STEP_NUMBER = {
    [STEP_DATA.ADD_PAYMENT_DATA]: 0,
    [STEP_DATA.COMPLETE_ACCOUNT_DATA]: 1,
    [STEP_DATA.CREATE_CAMPAIGN_DATA]: 2,
    [STEP_DATA.lAUNCH_CAMPAIGN_DATA]: 3,
    [STEP_DATA.REVIEW_CAMPAIGN_DATA]: 4,
};

export const COMPLETION_SCORES = {
    [STEP_DATA.ADD_PAYMENT_DATA]: { [SUB_STEP_DATA.HAS_NON_FROZEN_ACCOUNT]: 20 },
    [STEP_DATA.COMPLETE_ACCOUNT_DATA]: {
        [SUB_STEP_DATA.HAS_ACTIVE_CONVERSION]: 20,
        [SUB_STEP_DATA.HAS_INSTALLED_PIXEL]: 10,
    },
    [STEP_DATA.CREATE_CAMPAIGN_DATA]: {
        [SUB_STEP_DATA.HAS_DESKTOP_OR_MOBILE_CAMPAIGNS]: 10,
        [SUB_STEP_DATA.HAS_MINIMUM_ADS_PER_CAMPAIGN]: 10,
    },
    [STEP_DATA.lAUNCH_CAMPAIGN_DATA]: {
        [SUB_STEP_DATA.HAS_MINIMUM_ADS_PER_CAMPAIGN_RUNNING]: 10,
        [SUB_STEP_DATA.HAS_LEARNING_OR_SCALING_CAMPAIGNS]: 10,
    },
    [STEP_DATA.REVIEW_CAMPAIGN_DATA]: { hasVisitSiteReport: 10 },
};

export const ANALYTICS_SUB_STEP_DATA_LABEL = {
    [SUB_STEP_DATA.HAS_NON_FROZEN_ACCOUNT]: SUB_STEP_NAMES.ADD_PAYMENT_METHOD,
    [SUB_STEP_DATA.HAS_INSTALLED_PIXEL]: SUB_STEP_NAMES.INSTALL_PIXEL,
    [SUB_STEP_DATA.HAS_ACTIVE_CONVERSION]: SUB_STEP_NAMES.CREATE_CONVERSIONS,
    [SUB_STEP_DATA.HAS_DESKTOP_OR_MOBILE_CAMPAIGNS]: SUB_STEP_NAMES.CREATE_CAMPAIGN,
    [SUB_STEP_DATA.HAS_MINIMUM_ADS_PER_CAMPAIGN]: SUB_STEP_NAMES.ADD_NEW_CREATIVES,
    [SUB_STEP_DATA.HAS_MINIMUM_ADS_PER_CAMPAIGN_RUNNING]: SUB_STEP_NAMES.REVIEW_ADS_STATUS,
    [SUB_STEP_DATA.HAS_LEARNING_OR_SCALING_CAMPAIGNS]: SUB_STEP_NAMES.REVIEW_CAMPAIGN_LEARNING,
    [SUB_STEP_DATA.HAS_VISIT_SITE_REPORT]: SUB_STEP_NAMES.OPTIMIZE_REVIEW_SITES,
};

export const ANALYTICS_STEP_ID_LABEL = {
    [STEP_IDS.ADD_PAYMENT_METHOD]: SUB_STEP_NAMES.ADD_PAYMENT_METHOD,
    [SUB_STEP_IDS.COMPLETE_ACCOUNT_SETUP_1]: SUB_STEP_NAMES.INSTALL_PIXEL,
    [SUB_STEP_IDS.COMPLETE_ACCOUNT_SETUP_2]: SUB_STEP_NAMES.CREATE_CONVERSIONS,
    [SUB_STEP_IDS.CREATE_CAMPAIGN_1]: SUB_STEP_NAMES.CREATE_CAMPAIGN,
    [SUB_STEP_IDS.CREATE_CAMPAIGN_2]: SUB_STEP_NAMES.ADD_NEW_CREATIVES,
    [SUB_STEP_IDS.LAUNCH_YOUR_CAMPAIGN_1]: SUB_STEP_NAMES.REVIEW_ADS_STATUS,
    [SUB_STEP_IDS.LAUNCH_YOUR_CAMPAIGN_2]: SUB_STEP_NAMES.REVIEW_CAMPAIGN_LEARNING,
    [STEP_IDS.REVIEW_CAMPAIGN_PERFORMANCE]: SUB_STEP_NAMES.OPTIMIZE_REVIEW_SITES,
};

export const ANALYTICS_CTA_LABEL = {
    [STEP_IDS.ADD_PAYMENT_METHOD]: 'Add Payment',
    [SUB_STEP_IDS.COMPLETE_ACCOUNT_SETUP_2]: 'Create Conversion Rules',
    [SUB_STEP_IDS.CREATE_CAMPAIGN_1]: 'Create Campaign',
    [SUB_STEP_IDS.CREATE_CAMPAIGN_2]: 'Add Creative',
    [SUB_STEP_IDS.LAUNCH_YOUR_CAMPAIGN_1]: 'Review Ads',
    [SUB_STEP_IDS.LAUNCH_YOUR_CAMPAIGN_2]: 'Review Campaigns',
    [STEP_IDS.REVIEW_CAMPAIGN_PERFORMANCE]: 'Review Sites',
    [CTA_IDS.INSTALL_PIXEL]: 'Install Pixel',
    [CTA_IDS.SCHEDULE_HELP]: 'Schedule Help',
};
