function throwInitializationException(property) {
    throw new Error(`${property} has no value, init must be called`);
}

const DEFAULT_CHAT_AGENT_CLIENT = 'chat-agent';
const DEFAULT_CHAT_AGENT_DOMAIN = window.origin;

export const APPLICATION_NAME = 'TABOOLA_ADS';

class Config {
    init(config) {
        this.authenticationUrl = config.authenticationEndpointUrl;
        this.backstageEndpointUrl = config.backstageEndpointUrl;
        this.clientId = config.applicationId;
        this.applicationName = config.applicationName || APPLICATION_NAME;
        this.backstageExternalUrl = config.backstageExternalUrl;
        this.adsConsolePermissionType = 'ADS_CONSOLE_ACCESS';
        this.crashClientOnFailure = config.crashClientOnFailure;

        this.parentAppDomain = config.parentAppDomain;
        this.iframeDomain = config.iframeDomain;
        this.authenticationType = config.parentAppAuthenticationType;

        this.chatAgentClient = config.chatAgentClient || DEFAULT_CHAT_AGENT_CLIENT;
        this.chatAgentDomain = config.chatAgentDomain || DEFAULT_CHAT_AGENT_DOMAIN;
        this.chatAgentChatUrl = config.chatAgentChatUrl;
        this.chatAgentEnabled = config.chatAgentEnabled;
    }

    getAuthenticationUrl() {
        if (!this.authenticationUrl) {
            throwInitializationException('authenticationUrl');
        }
        return `${this.authenticationUrl}oauth/authorize`;
    }

    generateLogoutUrl(domain, redirectUrl) {
        return `${domain}j_spring_security_logout?originalUrl=${encodeURIComponent(redirectUrl)}`;
    }

    getLogoutUrl(redirectUrl) {
        if (!this.authenticationUrl || !this.backstageExternalUrl) {
            throwInitializationException('authenticationUrl');
        }

        const authLogoutUrl = this.generateLogoutUrl(this.authenticationUrl, redirectUrl);
        const backstageLogoutUrl = this.generateLogoutUrl(this.backstageExternalUrl, authLogoutUrl);

        return backstageLogoutUrl;
    }

    getClientId() {
        if (!this.clientId) {
            throwInitializationException('clientId');
        }
        return this.clientId;
    }

    getBackstageEndpointUrl() {
        if (!this.backstageEndpointUrl) {
            throwInitializationException('backstageEndpointUrl');
        }
        return this.backstageEndpointUrl;
    }

    getBackstageExternalUrl() {
        if (!this.backstageExternalUrl) {
            throwInitializationException('backstageExternalUrl');
        }
        return this.backstageExternalUrl;
    }

    getAdsConsolePermissionType() {
        return this.adsConsolePermissionType;
    }

    getCrashClientOnFailure() {
        return this.crashClientOnFailure;
    }

    getParentAppDomain() {
        return this.parentAppDomain;
    }

    getIframeDomain() {
        return this.iframeDomain;
    }

    getChatAgentClient() {
        return this.chatAgentClient;
    }

    getChatAgentDomain() {
        return this.chatAgentDomain;
    }

    getChatAgentChatUrl() {
        return this.chatAgentChatUrl;
    }

    getChatAgentEnabled() {
        return this.chatAgentEnabled;
    }

    getAuthenticationType() {
        return this.authenticationType;
    }

    getApplicationName() {
        return this.applicationName;
    }
}

export default new Config();
