import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Gallery, MediaGalleryItem } from 'taboola-ultimate-ui';
import { MediaDragAndDrop } from '../../../../../creative-editor/components/MediaDragAndDrop/MediaDragAndDrop';
import ThumbnailLoading from '../../../../components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import FallbackImageEditor from '../FallbackImageEditor';
import { VideoExternalError } from '../VideoExternalError';

const VideoGallery = ({
    gap,
    minRowHeight,
    columns,
    className,
    videosToRender,
    left,
    checkFallbackImageDisabled,
    onCloseVideoClick,
    onEditFallbackImageClick,
    renderOverlayComponent,
    onSelectMedia,
    cancelSelectFallbackImage,
    motionIdFallbackSelecting,
}) => (
    <Gallery gap={gap} minRowHeight={minRowHeight} columns={columns} className={className} aria-label="gallery">
        {videosToRender.map((thumbnail, index) => {
            const {
                fallbackImageUrl,
                recommendedFBImage,
                fallbackImageStatus,
                id,
                fallbackImageError,
                status,
                src,
                displayUrl,
                error,
            } = thumbnail;
            const handleEditFallbackImageClick = () => onEditFallbackImageClick(id);
            const isFallbackImageRecommended = recommendedFBImage && recommendedFBImage.length > 0;
            const handleCloseVideoClick = () => onCloseVideoClick(thumbnail);
            return (
                <Fragment key={`thumbnail-${id}`}>
                    <MediaGalleryItem
                        loadingComponent={<ThumbnailLoading />}
                        status={status}
                        left={left}
                        imgSrc={src || displayUrl}
                        thumbnail={thumbnail}
                        renderOverlayComponent={renderOverlayComponent(thumbnail)}
                        onCloseButtonClick={handleCloseVideoClick}
                        errorComponent={<VideoExternalError error={error} />}
                        role="cell"
                    />
                    <MediaDragAndDrop
                        onDrop={onSelectMedia}
                        onCancel={cancelSelectFallbackImage}
                        disabled={motionIdFallbackSelecting && motionIdFallbackSelecting !== id}
                        inputId={`fallback-image-file-input-${index}`}
                    >
                        <FallbackImageEditor
                            error={fallbackImageError || error}
                            status={fallbackImageStatus || status}
                            imgSrc={fallbackImageUrl}
                            isDisabled={
                                checkFallbackImageDisabled(fallbackImageStatus, status, id) ||
                                (motionIdFallbackSelecting && motionIdFallbackSelecting !== id)
                            }
                            onEditFallbackImageClick={handleEditFallbackImageClick}
                            isFallbackImageRecommended={isFallbackImageRecommended}
                        />
                    </MediaDragAndDrop>
                </Fragment>
            );
        })}
    </Gallery>
);

VideoGallery.propTypes = {
    gap: PropTypes.string,
    minRowHeight: PropTypes.string,
    columns: PropTypes.string,
    className: PropTypes.string,
    videosToRender: PropTypes.array,
    left: PropTypes.number,
    checkFallbackImageDisabled: PropTypes.func,
    onCloseVideoClick: PropTypes.func,
    onEditFallbackImageClick: PropTypes.func,
    renderOverlayComponent: PropTypes.func,
};

export default VideoGallery;
