export const FORM_MODES = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    DUPLICATE: 'DUPLICATE',
    PREVIEW: 'PREVIEW',
    REVIEW: 'REVIEW',
    BULK_EDIT: 'BULK_EDIT',
    BULK_DUPLICATE: 'BULK_DUPLICATE',
    BULK_CREATE: 'BULK_CREATE',
};

export const isCreateFormMode = mode => mode === FORM_MODES.CREATE || mode === FORM_MODES.BULK_CREATE;
