"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgExcludeAudience(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 19,
            height: 19,
            viewBox: "0 0 19 19",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M0.677002 2.03018L2.09122 0.615967L18.1739 16.6987L16.7597 18.1129L0.677002 2.03018Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M5.03732 4.4515C5.01276 4.63082 5.00006 4.81393 5.00006 5.00003C5.00006 7.21003 6.79006 9.00002 9.00006 9.00002C9.18616 9.00002 9.36927 8.98733 9.54859 8.96277L5.03732 4.4515Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M10.7266 10.1407C10.0928 10.047 9.49984 10 9.00006 10C6.33006 10 1.00007 11.34 1.00007 14V17H17.0001V16.4142L15.1001 14.5142V15.1H2.90007V14C2.90007 13.36 6.03006 11.9 9.00006 11.9C10.5813 11.9 12.2078 12.3139 13.3833 12.7974L10.7266 10.1407Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M11.7996 7.85767L10.4557 6.51379C10.8529 6.13174 11.1001 5.5948 11.1001 5.00003C11.1001 3.84003 10.1601 2.90003 9.00006 2.90003C8.40529 2.90003 7.86835 3.14715 7.4863 3.54435L6.14242 2.20047C6.86833 1.45958 7.88028 1.00003 9.00006 1.00003C11.2101 1.00003 13.0001 2.79003 13.0001 5.00003C13.0001 6.11981 12.5405 7.13176 11.7996 7.85767Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgExcludeAudience;