import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../TooltipSection';
import styles from './bundleDetailTooltip.module.scss';

export const BundleDetailTooltip = ({ item, formattedSize, position }) => {
    const { label: title, description } = item;
    return (
        <Tooltip position={position} className={styles['tooltip-content']} delay={800} arrow>
            <TooltipSection>
                <FormattedMessage
                    id="segment.detail.bundled.parent"
                    defaultMessage="The <b>{title}</b> bundle estimated reach is <b>{formattedSize} users per month</b>."
                    values={{ title, formattedSize }}
                />
            </TooltipSection>
            {description && (
                <TooltipSection bodyClass={styles['description']}>
                    <FormattedMessage
                        id={`campaign.editor.marketplace.audiences.taxonomies.${description}.title`}
                        defaultMessage={description}
                    />
                </TooltipSection>
            )}
        </Tooltip>
    );
};
