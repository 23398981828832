import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { usePageName } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ANALYTICS_STEP_ID_LABEL, ANALYTICS_CTA_LABEL } from '../../consts';
import { useIsCTADisabled } from '../../hooks/useIsCTADisabled';
import styles from './onboardingCTA.module.scss';

const classNameBuilder = classnames.bind(styles);

export const OnboardingCTA = ({
    stepId,
    CTAId,
    variant,
    className,
    iconBefore,
    isComplete,
    onClick,
    delayStateDurationMinutes,
    setIsCTADisabled,
    tooltip,
}) => {
    const pageName = usePageName();
    const { isDisabled, handleClick } = useIsCTADisabled({
        CTAId,
        delayStateDurationMinutes,
        stepId,
        isComplete,
        onClick,
        setIsCTADisabled,
    });

    return (
        <div className={classNameBuilder('container', className)}>
            <Button
                className={styles['CTA-button']}
                variant={variant}
                onClick={handleClick}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-page-name={pageName}
                data-metrics-component="Onboarding Assistant"
                data-metrics-value={ANALYTICS_CTA_LABEL[CTAId ? CTAId : stepId]}
                data-metrics-column-name={ANALYTICS_STEP_ID_LABEL[stepId]}
                disabled={isDisabled}
            >
                {iconBefore}
                <FormattedMessage
                    id={`onboarding.assistant.${stepId}${CTAId ? '.' + CTAId : ''}.CTA`}
                    defaultMessage="Action"
                />
                {tooltip}
            </Button>
        </div>
    );
};
