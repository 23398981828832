import React, { useMemo } from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import CurrencyIcon from 'modules/campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { withIndication } from 'modules/errors/components';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './valueType.module.scss';

const InputWithIndication = withIndication(Input, styles['value-field']);
const metricNameDropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.performance.rules.value.no.selection',
        defaultMessage: 'Enter a specific Value',
    },
    {
        validationFn: (value, options, { type }) => {
            if (type === 'text') {
                return true;
            }

            return validationFunctions.isFloatOrEmpty(value, options);
        },
        options: { min: 0 },
        messageId: 'validations.error.performance.rules.value.minValue',
        defaultMessage: 'Value must be a positive number',
    },
];
const padding = parseInt(styles.currencyInputLeftPadding);
export const VALUE_TYPE = { CURRENCY: 'CURRENCY', PERCENT: 'PERCENT', TEXT: 'TEXT' };

export const ValueType = ({ valueType }) => {
    const type = useMemo(() => {
        return valueType === VALUE_TYPE.TEXT ? InputTypes.TEXT : InputTypes.NUMBER;
    }, [valueType]);

    const {
        value: inputValue,
        onChange: valueOnChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'conditionValue',
        validations: metricNameDropdownValidations,
        hasInitialData: false,
        validationDependencies: {
            type,
        },
    });

    const {
        formAccount: { currency },
    } = useFormDataContext();

    const getIcon = useMemo(() => {
        if (valueType === VALUE_TYPE.CURRENCY) {
            return <CurrencyIcon currency={currency} />;
        }
        if (valueType === VALUE_TYPE.PERCENT) {
            return <span className={styles['percent-icon']}> % </span>;
        }
        return null;
    }, [currency, valueType]);

    const inputPadding = useMemo(() => {
        if (valueType === VALUE_TYPE.CURRENCY || valueType === VALUE_TYPE.PERCENT) {
            return padding;
        } else {
            return 0;
        }
    }, [valueType]);

    const changeHandler = useEventValueHandler(valueOnChange);
    return (
        <div className={styles['input']}>
            <InputWithIndication
                onChange={changeHandler}
                icon={getIcon}
                inputPadding={inputPadding}
                id="value-type"
                value={inputValue ?? null}
                type={type}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="input-value"
                inputClass={styles['input-container']}
                aria-label="input-dropdown"
                {...indicationData}
            />
        </div>
    );
};
