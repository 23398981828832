import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ENTITY } from 'modules/campaigns/config';
import { fromGWtransformers } from 'modules/campaigns/flows/flowsUtils';
import { campaignToDropdownObjectTransformer } from 'modules/campaigns/services/utils';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignsApi, useCreativesApi } from 'services/api';
import { generateErrorIndication } from 'services/utils';
import { STEPS } from '../../common-campaign-form/config';
import {
    creativeToContentFormObjectTransformer,
    creativeToUrlFormObjectTransformer,
} from '../../creative-creator/utils/creativeCreatorTransformerUtils';
import { useRecommendationService } from './useRecommendationService';

export const useFatiguedItemRecommendation = ({ accountId, itemId, campaignId, recommendation }) => {
    const { createCreativeWithRecommendation } = useRecommendationService();
    const dispatch = useDispatch();
    const creativesApi = useCreativesApi();
    const campaignsApi = useCampaignsApi();
    const campaignTransformer = fromGWtransformers[ENTITY.CAMPAIGN];

    const navigateToCreativeCreator = useCallback(async () => {
        try {
            const fetchCampaign = campaignsApi.getCampaign({
                accountId,
                campaignId,
                entityType: ENTITY.CREATIVE,
            });
            const fetchCreative = creativesApi.getCreative(accountId, campaignId, itemId);
            const [fetchedCampaign, creative] = await Promise.all([fetchCampaign, fetchCreative]);
            const campaign = campaignTransformer(fetchedCampaign);

            createCreativeWithRecommendation({
                recommendation,
                recommendationData: {
                    recommendationId: recommendation.id,
                    campaigns: [campaignToDropdownObjectTransformer(campaign)],
                    content: [creativeToContentFormObjectTransformer(creative)],
                    urls: [creativeToUrlFormObjectTransformer(creative)],
                    'selected-media': null,
                    initialStep: STEPS.SECOND_STEP,
                },
            });
        } catch (error) {
            const errorMessage = (
                <FormattedMessage id="performance-recommendations.general-recommendation.error-message" />
            );
            dispatch(addIndication(generateErrorIndication(errorMessage, errorMessage)));
        }
    }, [
        accountId,
        campaignId,
        itemId,
        recommendation,
        dispatch,
        creativesApi,
        campaignsApi,
        createCreativeWithRecommendation,
        campaignTransformer,
    ]);
    return { navigateToCreativeCreator };
};
