import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import {
    formattedCampaignHistoryChangeTimeGetterNoTzShift,
    FormattedMessageHeaderRenderer,
    gridCampaignHistoryChangeTimeGetterNoTzShift,
} from '../../../components';
import { normalizeColumnDefs } from '../../../utils';
import * as FIELDS from '../../fields';

const hugeColumnMinWidth = 140;
const smallColumnDefaultWidth = 70;
const campaignHistoryReportColumnDefinition = [
    {
        headerName: FIELDS.CHANGE_TIME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CHANGE_TIME.headerComponentParams,
        field: FIELDS.CHANGE_TIME.field,
        cellRendererFramework: FIELDS.CHANGE_TIME.cellRendererFramework,
        valueGetter: gridCampaignHistoryChangeTimeGetterNoTzShift,
        csvValueGetter: formattedCampaignHistoryChangeTimeGetterNoTzShift,
    },
    {
        headerName: FIELDS.CAMPAIGN_HISTORY_CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.CAMPAIGN_HISTORY_CAMPAIGN_NAME.headerComponentParams,
        field: FIELDS.CAMPAIGN_HISTORY_CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGN_HISTORY_CAMPAIGN_NAME.cellRendererFramework,
        csvValueGetter: FIELDS.CAMPAIGN_HISTORY_CAMPAIGN_NAME.csvValueGetter,
        minWidth: hugeColumnMinWidth,
    },
    {
        headerName: FIELDS.ACTIVITY.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.ACTIVITY.headerComponentParams,
        field: FIELDS.ACTIVITY.field,
        cellRendererFramework: FIELDS.ACTIVITY.cellRendererFramework,
        valueGetter: FIELDS.ACTIVITY.valueGetter,
        csvValueGetter: FIELDS.ACTIVITY.csvValueGetter,
    },
    {
        headerName: FIELDS.ACTIVITY_DETAILS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.ACTIVITY_DETAILS.headerComponentParams,
        field: FIELDS.ACTIVITY_DETAILS.field,
        cellRendererFramework: FIELDS.ACTIVITY_DETAILS.cellRendererFramework,
        valueGetter: FIELDS.ACTIVITY_DETAILS.valueGetter,
        csvValueGetter: FIELDS.ACTIVITY_DETAILS.csvValueGetter,
        minWidth: hugeColumnMinWidth,
    },
    {
        headerName: FIELDS.OLD_VALUE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.OLD_VALUE.headerComponentParams,
        field: FIELDS.OLD_VALUE.field,
        cellRendererFramework: FIELDS.OLD_VALUE.cellRendererFramework,
        valueGetter: FIELDS.OLD_VALUE.valueGetter,
        csvValueGetter: FIELDS.OLD_VALUE.csvValueGetter,
        width: smallColumnDefaultWidth,
    },
    {
        headerName: FIELDS.NEW_VALUE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.NEW_VALUE.headerComponentParams,
        field: FIELDS.NEW_VALUE.field,
        cellRendererFramework: FIELDS.NEW_VALUE.cellRendererFramework,
        cellRendererParams: {
            isBoldCell: true,
            customFromDetails: true,
        },
        valueGetter: FIELDS.NEW_VALUE.valueGetter,
        csvValueGetter: FIELDS.NEW_VALUE.csvValueGetter,
        width: smallColumnDefaultWidth,
    },
    {
        headerName: FIELDS.PERFORMER.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.PERFORMER.headerComponentParams,
        field: FIELDS.PERFORMER.field,
        cellRendererFramework: FIELDS.PERFORMER.cellRendererFramework,
        csvValueGetter: FIELDS.PERFORMER.csvValueGetter,
    },
];

export default normalizeColumnDefs(
    campaignHistoryReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CAMPAIGN_HISTORY].id
);
