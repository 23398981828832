import { setSearchParams } from './queryParamsUtils';

const generateBackstageUrl = ({ config, accountId, componentPath, locale, isQueryParamsEnabled }) => {
    const url = `${config.getBackstageExternalUrl()}${accountId}/${componentPath}${
        isQueryParamsEnabled ? '?preventForceRedirect=true' : ''
    }`;
    if (locale) {
        return setSearchParams(url, { locale });
    }
    return url;
};

export default generateBackstageUrl;
