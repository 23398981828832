import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useComponentStatus } from '../../hooks';
import { useAccountsApi } from '../../services/api/accountsApi';
import { COMPONENT_STATUS } from '../../services/constants';
import styles from './defaultPlatform.module.scss';

const DefaultPlatform = ({ onError }) => {
    const [checkBoxValue, setCheckboxValue] = useState(false);
    const { status, setStatus } = useComponentStatus();
    const { isDefaultPlatformTaboolaAds, updateDefaultPlatform } = useAccountsApi();

    const fetchAndSetDefaultPlatform = useCallback(async () => {
        try {
            const isDefaultPlatformTaboolaAdsValue = await isDefaultPlatformTaboolaAds();
            setCheckboxValue(isDefaultPlatformTaboolaAdsValue);
            setStatus(COMPONENT_STATUS.ACTIVE);
        } catch (e) {
            setStatus(COMPONENT_STATUS.ERROR);
            onError();
        }
    }, [setStatus, onError, isDefaultPlatformTaboolaAds]);

    useEffect(() => {
        fetchAndSetDefaultPlatform();
    }, [fetchAndSetDefaultPlatform]);

    const handleDefaultPlatformChange = isDefaultPlatformTaboolaAds => async () => {
        try {
            setCheckboxValue(isDefaultPlatformTaboolaAds);
            await updateDefaultPlatform(isDefaultPlatformTaboolaAds);
        } catch (e) {
            setCheckboxValue(!isDefaultPlatformTaboolaAds);
        }
    };

    if (status === COMPONENT_STATUS.ERROR) {
        return null;
    }

    return (
        <CheckboxGroup
            onSelectCheckbox={handleDefaultPlatformChange(true)}
            onUnselectCheckbox={handleDefaultPlatformChange(false)}
            selectedValues={[checkBoxValue]}
        >
            <TextCheckbox
                labelClassName={styles['checkbox-label']}
                disabled={status === COMPONENT_STATUS.INITIAL}
                value
                title={
                    <FormattedMessage
                        id="app.defaultPlatform.checkbox.label"
                        defaultMessage="Default platform is Taboola Ads"
                    />
                }
                data-metrics-component="Default platform checkbox"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={!checkBoxValue}
            />
        </CheckboxGroup>
    );
};

export default DefaultPlatform;
