import React from 'react';
import { Switch } from 'react-router';
import PropTypes from 'prop-types';
import useUnsavedChangesController from 'modules/campaigns/hooks/useUnsavedChangesController';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { SCHEDULED_REPORTS_CREATOR_ROUTE_PATH } from '../config';
import { ScheduledReportCreatorForm } from './ScheduledReportCreatorForm';

const path = SCHEDULED_REPORTS_CREATOR_ROUTE_PATH;

export const ScheduledReportCreatorPage = ({ onCancel }) => {
    useUnsavedChangesController(SCHEDULED_REPORTS_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute strict path={path} render={() => <ScheduledReportCreatorForm onCancel={onCancel} />} />
        </Switch>
    );
};

ScheduledReportCreatorPage.propTypes = {
    onCancel: PropTypes.func,
};
