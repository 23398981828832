"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgOkPartial(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 25,
            viewBox: "0 0 24 25",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M10.7098 15.2564C10.8515 15.384 11.0353 15.4548 11.2259 15.4551H11.2698C11.4759 15.4441 11.6693 15.3513 11.8066 15.197L15.6775 10.81L14.5163 9.77783L11.1614 13.5791L9.16155 11.7727L8.12939 12.9237L10.7098 15.2564Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M5.54858 12.8743C5.54858 13.3018 5.20189 13.6486 4.77435 13.6486C4.34683 13.6486 4.00012 13.3019 4.00012 12.8743C4.00012 12.4468 4.34682 12.1001 4.77435 12.1001C5.20187 12.1001 5.54858 12.4468 5.54858 12.8743Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M6.58081 16.4874C6.58081 16.9151 6.23429 17.2617 5.80676 17.2617C5.37906 17.2617 5.03253 16.9151 5.03253 16.4874C5.03253 16.0599 5.37905 15.7134 5.80676 15.7134C6.23428 15.7134 6.58081 16.0599 6.58081 16.4874Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M6.58081 9.26154C6.58081 9.68906 6.23429 10.0356 5.80676 10.0356C5.37906 10.0356 5.03253 9.68907 5.03253 9.26154C5.03253 8.83384 5.37905 8.4873 5.80676 8.4873C6.23428 8.4873 6.58081 8.83382 6.58081 9.26154Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9.16144 6.68097C9.16144 7.10849 8.81492 7.45502 8.38739 7.45502C7.95969 7.45502 7.61316 7.1085 7.61316 6.68097C7.61316 6.25327 7.95968 5.90674 8.38739 5.90674C8.81491 5.90674 9.16144 6.25326 9.16144 6.68097Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M12.0001 4.87451V6.42297C14.305 6.42297 16.4348 7.65264 17.5872 9.64874C18.7398 11.6448 18.7398 14.1042 17.5872 16.1003C16.4347 18.0964 14.3048 19.3261 12.0001 19.3261V20.8745C14.1219 20.8745 16.1567 20.0315 17.6567 18.5313C19.157 17.0311 19.9999 14.9963 19.9999 12.8747C19.9999 10.7531 19.157 8.71811 17.6567 7.21808C16.1565 5.71787 14.1217 4.87488 12.0001 4.87488V4.87451Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M9.16144 19.068C9.16144 19.4957 8.81492 19.8422 8.38739 19.8422C7.95969 19.8422 7.61316 19.4957 7.61316 19.068C7.61316 18.6405 7.95968 18.2939 8.38739 18.2939C8.81491 18.2939 9.16144 18.6405 9.16144 19.068Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgOkPartial;