"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEditSmall(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 12,
            height: 12,
            fill: "currentColor",
            viewBox: "0 0 12 12"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            d: "M8.716 5.678L10 4.478 7.57 2 6.277 3.208l2.439 2.47zM4.988 9.447L7.843 6.56 5.402 4.092 2.547 6.98A2.49 2.49 0 002 7.957V10h2.021c.36-.108.69-.297.967-.553z"
        })
    );
}
exports.default = SvgEditSmall;