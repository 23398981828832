import { callAjax } from '../callAjax/callAjax';

class Configuration {
    constructor() {
        this.data = window.__APP_CONFIG__;
        this.url = '/resources/env-config';
    }

    get configObj() {
        return { ...this.data };
    }

    init() {
        if (this.data) {
            return Promise.resolve(this.data);
        }
        return callAjax(this.url).then(data => {
            this.data = data;
            return Promise.resolve(data);
        });
    }
}

const Config = new Configuration();
export default Config;
