export class CrmFileError extends Error {
    constructor(msgId, defaultMessage) {
        super(defaultMessage);
        this.msgId = msgId;
        this.defaultMessage = defaultMessage;
    }
}

export const crmFileTransformerToGW = async audience => {
    const { processCrmFile } = await import('./crmFileWorkerSingleton');
    try {
        const {
            message: crmFileContents,
            isFileHashedByTaboola,
            fileRecordsForDebug,
        } = await processCrmFile(audience.file);
        return {
            ...audience,
            fileBlob: new Blob([crmFileContents], { type: 'text/csv' }),
            isFileHashedByTaboola,
            fileRecordsForDebug,
        };
    } catch (crmFileError) {
        if (crmFileError.msgId) {
            throw new CrmFileError(crmFileError.msgId, crmFileError.message);
        } else {
            throw crmFileError;
        }
    }
};
