import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { ToolbarToggle } from 'components';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { MATCH_TYPES } from '../../../../config';
import MatchTypeTooltip from './MatchTypeTooltip';
import styles from './matchTypeField.module.scss';

export const MatchTypeField = () => {
    const { value: matchType, onChange: onMatchTypeChange } = useFormFieldValue({
        field: 'matchType',
    });

    return (
        <FormField
            inputId="match-type"
            label={
                <FormattedMessage id="audience.editor.external-audience.match-type.label" defaultMessage="Match Type" />
            }
            helpText={<MatchTypeTooltip />}
            helpIconLabel="match-type-tooltip"
        >
            <div className={styles['field-container']}>
                <ToolbarToggle
                    leftChild={<div>Broad Match</div>}
                    rightChild={<div>Exact Match</div>}
                    onLeftChildClick={() => onMatchTypeChange(MATCH_TYPES.BROAD)}
                    onRightChildClick={() => onMatchTypeChange(MATCH_TYPES.EXACT)}
                    leftToggleButtonClassName={styles['left-option']}
                    rightToggleButtonClassName={styles['right-option']}
                    isLeftToggleSelected={matchType === MATCH_TYPES.BROAD}
                />
            </div>
        </FormField>
    );
};
