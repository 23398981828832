import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';
import styles from './SegmentListItemTooltip.module.scss';

const tailStyle = { zIndex: 1 };

const SegmentListItemTooltip = ({ item }) => {
    const { provider, label, description } = item;

    return (
        <Tooltip
            position={TOOLTIP_POSITION.TOP}
            arrow
            className={styles['tooltip-content']}
            tailStyle={tailStyle}
            delay={800}
        >
            <TooltipSection header={label}>
                <FormattedMessage
                    id={`campaign.editor.marketplace.audiences.taxonomies.${provider}.title`}
                    defaultMessage="Partner: {provider}"
                    values={{ provider }}
                />
            </TooltipSection>
            {description && (
                <TooltipSection bodyClass={styles['description']}>
                    <FormattedMessage
                        id={`campaign.editor.marketplace.audiences.taxonomies.${description}.title`}
                        defaultMessage={description}
                    />
                </TooltipSection>
            )}
        </Tooltip>
    );
};

SegmentListItemTooltip.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
};

export default SegmentListItemTooltip;
