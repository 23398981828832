import React, { useMemo } from 'react';
import { Button, CloseIcon } from 'tuui';
import { DelimiterWrapper } from 'taboola-ultimate-ui';
import { getFormattedSizeAndArticles } from '../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './topicsDetails.module.scss';

export const TopicsDetails = ({ size = 0, articles = 0, showRemoveButton, handleOnClick, name = '' }) => {
    const { formattedSize, formattedArticles } = useMemo(
        () => getFormattedSizeAndArticles({ size, articles }),
        [articles, size]
    );
    return (
        <div className={styles['details']} aria-label="topics-details">
            <DelimiterWrapper delimiter={PipeDelimiter}>
                <div className={styles['detail']}>
                    <FormattedMessage
                        id="custom.contextual.editor.topics.details.size"
                        defaultMessage="Size: {size}"
                        values={{ size: formattedSize }}
                    />
                </div>
                <div>
                    <FormattedMessage
                        id="custom.contextual.editor.topics.details.articles"
                        defaultMessage="Articles: {articles}"
                        values={{ articles: formattedArticles }}
                    />
                </div>
            </DelimiterWrapper>
            {showRemoveButton && (
                <Button
                    onClick={handleOnClick}
                    size="sm"
                    variant="text"
                    className={styles['remove-button']}
                    aria-label={`Remove ${name} item`}
                >
                    <CloseIcon />
                </Button>
            )}
        </div>
    );
};
const PipeDelimiter = () => <span>|&nbsp;</span>;
