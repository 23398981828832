import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import Budget from './Budget';
import BudgetFieldTooltip from './BudgetFieldTooltip';
import styles from './Budget.module.scss';

export const BudgetField = ({ currency }) => {
    return (
        <FormField
            inputId="budgetField"
            label={<FormattedMessage id="video.campaign.editor.budget.title" defaultMessage="Budget" />}
            description={
                <FormattedMessage
                    id="video.campaign.editor.budget.description"
                    defaultMessage="Specify a budget for the entire campaign 'lifetime'."
                />
            }
            helpText={<BudgetFieldTooltip />}
            containerClass={styles['input']}
        >
            <Budget currency={currency} />
        </FormField>
    );
};

BudgetField.propTypes = {
    currency: PropTypes.string,
};

export default BudgetField;
