import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { nonProgrammaticActiveAccount } from 'modules/account-management/accountType';
import { useCustomContextualPermitted } from 'modules/audiences/hooks/useCustomContextualPermitted';
import { ConversionConfiguration } from 'modules/campaigns/components/ConversionConfiguration/ConversionConfiguration';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { FormButtons } from '../../components/Form';
import { ENTITY, REPORT_TYPE } from '../../config';
import useCampaignBreadcrumbs from '../../hooks/useCampaignBreadcrumbs';
import { useLastCreatedCampaignGroupId } from '../../hooks/useLastCreatedCampaignGroupId';
import {
    AudienceTargetingSection,
    AudienceTargetingSectionLegacy,
    Branding,
    BudgetSectionSponsored,
    CampaignCreatorFormButtons,
    CommentsSection,
    ContextualTargetingSection,
    ConversionOptimization,
    FormBreadcrumbs,
    MarketingObjective,
    NameCreate,
    ProfileSection,
    ReachEstimatorWidget,
    ScheduleSection,
    SetupSection,
    TargetingSection,
    TrackingCode,
    TrackingSection,
} from '../common-campaign-form/components';
import { useShouldRenderBidStrategyV2 } from '../common-campaign-form/components/BidStrategyV2/hooks/useShouldRenderBidStrategyV2';
import { BiddingConversionGoalSection } from '../common-campaign-form/components/BiddingConversionGoalSection';
import { CampaignGroupField } from '../common-campaign-form/components/CampaignGroupField/CampaignGroupField';
import { CampaignPerformanceRules } from '../common-campaign-form/components/CampaignPerformanceRules/CampaignPerformanceRules';
import { Funnel } from '../common-campaign-form/components/Funnel/Funnel';
import { useReachEstimatorEnabled } from '../common-campaign-form/components/ReachEstimator/hooks/useReachEstimatorEnabled';
import { campaignInitialValues } from '../common-campaign-form/config';
import { useCampaignCreatorState } from '../common-campaign-form/hooks';
import commonStyles from '../common-campaign-form/components/commonEditor.module.scss';
import styles from './sponsoredCampaignCreator.module.scss';

const SponsoredCampaignCreator = ({ onCancel }) => {
    const isCustomContextualPermitted = useCustomContextualPermitted();
    const isReachEstimatorEnabled = useReachEstimatorEnabled();
    // This is a intended call to keep lastCreatedCampaignGroupId in url until form is live
    useLastCreatedCampaignGroupId();

    const audiencesTargetingSectionWithWizard = isCustomContextualPermitted ? (
        <>
            <WizardStep
                label={
                    <FormattedMessage id="campaign.editor.contextual.targeting" defaultMessage="Contextual Targeting" />
                }
                id="contextual-targeting"
            >
                <ContextualTargetingSection />
            </WizardStep>
            <WizardStep
                label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
                id="audiences-targeting"
            >
                <AudienceTargetingSection />
            </WizardStep>
        </>
    ) : (
        <WizardStep
            label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
            id="audiences-targeting"
        >
            <AudienceTargetingSectionLegacy />
        </WizardStep>
    );

    const dimension = CAMPAIGN_DIMENSION.SPONSORED;
    const {
        step,
        handleSetStep,
        nextStep,
        prevStep,
        submit,
        handleSubmitCampaignAndNavigateToCreative,
        wizardTemplateColumns,
    } = useCampaignCreatorState({
        dimension,
    });

    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'campaign.creator.new.campaign', defaultMessage: 'New Campaign' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN,
        title,
    });
    const shouldRenderBidStrategyV2 = useShouldRenderBidStrategyV2();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="campaign.creator.campaign.settings.step.label"
                            defaultMessage="Campaign Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <WizardStep
                                label={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                                id="setup"
                            >
                                <SetupSection isAccountSelectionEnabled accountFilter={nonProgrammaticActiveAccount}>
                                    <CampaignGroupField />
                                    <NameCreate />
                                    <Branding />
                                    <MarketingObjective pixelAndConversionWarningEnabled />
                                    {shouldRenderBidStrategyV2 && (
                                        <>
                                            <ConversionOptimization />
                                            <ConversionConfiguration />
                                            <Funnel />
                                        </>
                                    )}
                                </SetupSection>
                            </WizardStep>

                            {!shouldRenderBidStrategyV2 && (
                                <WizardStep
                                    label={
                                        <FormattedMessage
                                            id="bidding.conversion.goal"
                                            defaultMessage="Bidding And Conversion Goal"
                                        />
                                    }
                                    id="bidding"
                                >
                                    <BiddingConversionGoalSection />
                                </WizardStep>
                            )}

                            <WizardStep
                                label={<FormattedMessage id="campaign.editor.schedule" defaultMessage="Schedule" />}
                                id="schedule"
                            >
                                <ScheduleSection />
                            </WizardStep>
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="campaign.editor.targeting"
                                        defaultMessage="Campaign Targeting"
                                    />
                                }
                                id="targeting"
                            >
                                <TargetingSection isIncludeHighImpactTargeting={true} />
                            </WizardStep>
                            {audiencesTargetingSectionWithWizard}
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                                submitIcon={<ChevronRightOutlinedIcon />}
                                submitDataMetricAttrs={{
                                    'data-metrics-component': 'ClickNext',
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                submitButtonType="submit"
                                className={commonStyles['form-buttons']}
                            />
                        </div>
                        {isReachEstimatorEnabled && <ReachEstimatorWidget entityType={ENTITY.CAMPAIGN} />}
                    </div>
                </WizardStep>

                <WizardStep
                    label={
                        <FormattedMessage id="campaign.creator.budget.bidding" defaultMessage="Budget and Bidding" />
                    }
                    id="budgeting"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <WizardStep
                                label={<FormattedMessage id="campaign.editor.budget" defaultMessage="Budget" />}
                                id="budget"
                            >
                                <BudgetSectionSponsored />
                                <CampaignPerformanceRules />
                            </WizardStep>
                            <WizardStep
                                label={<FormattedMessage id="campaign.editor.tracking" defaultMessage="Tracking" />}
                                id="tracking"
                            >
                                <TrackingSection>
                                    <TrackingCode />
                                </TrackingSection>
                            </WizardStep>
                            <WizardStep
                                label={<FormattedMessage id="campaign.editor.profile" defaultMessage="Profile" />}
                                id="profile"
                            >
                                <ProfileSection />
                                <CommentsSection />
                                <CampaignCreatorFormButtons
                                    onSubmit={submit}
                                    onSubmitAndAddCreatives={handleSubmitCampaignAndNavigateToCreative}
                                    onBack={prevStep}
                                    onCancel={onCancel}
                                    disableSubmit={false}
                                />
                            </WizardStep>
                        </div>
                        {isReachEstimatorEnabled && <ReachEstimatorWidget entityType={ENTITY.CAMPAIGN} />}
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

SponsoredCampaignCreator.propTypes = { onCancel: PropTypes.func };

const SponsoredCampaignCreatorWithFormDataProvider = withFormDataProvider(SponsoredCampaignCreator, {
    defaultValuesConfig: campaignInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { SponsoredCampaignCreatorWithFormDataProvider as SponsoredCampaignCreator };
