import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { LoadingBar } from 'modules/campaigns/components';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ERROR_CODES } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { fetchAIGeneratedReplacedBackgrounds } from '../../../../flows/fetchAIGeneratedReplacedBackgrounds';
import { GalleryItem } from '../../ImageGallery/GalleryItem';
import { CONTENT_BANNER_TYPES, CreativeStudioContentBanner } from '../CreativeStudioContentBanner';
import { CreativesStudioGallery } from '../CreativesStudioGallery/CreativesStudioGallery';
import { CreativesStudioTextInput } from '../CreativesStudioTextInput/CreativesStudioTextInput';
import { StudioGalleryItemDropdown } from '../StudioGalleryItemDropdown/StudioGalleryItemDropdown';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { ReplaceBackgroundSegmentAnything } from './ReplaceBackgroundSegmentAnything';
import { ReplaceBackgroundSegmentAnythingToggle } from './ReplaceBackgroundSegmentAnythingToggle';
import styles from './replaceBackgroundContent.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ReplaceBackgroundContent = props => {
    const [backgroundPrompt, setBackgroundPrompt] = useState('');
    const [imageResults, setImageResults] = useState([]);
    const [imageResultsStatus, setImageResultsStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [isPolicyViolated, setIsPolicyViolated] = useState(false);
    const [isSegmentSelectionActive, setIsSegmentSelectionActive] = useState(false);
    const [segmentMask, setSegmentMask] = useState(null);
    const isGalleryLoading = imageResultsStatus === COMPONENT_STATUS.LOADING;
    const hasError = imageResultsStatus === COMPONENT_STATUS.ERROR;
    const isGenerateDisabled = isEmpty(backgroundPrompt);
    const isSegmentAnythingEnabled = useConfigMatch({
        [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING]: 'true',
    });
    const { accountId, campaignId, mode, originalImage, selectedImageUrl, setSelectedImageUrl } =
        useCreativeStudioContext();
    const isSegmentAnythingVisible =
        isSegmentAnythingEnabled && !isSegmentSelectionActive && originalImage?.url === selectedImageUrl;
    const EVENTS_PREFIX = `${CREATIVE_STUDIO_EVENT_PREFIX}_${CREATIVE_STUDIO_MODE_EVENTS_MAP[mode]}`;

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.studio.replace.background.placeholder',
        defaultMessage: 'on a white surface surrounded by flowers and soft shadows',
    });
    const loadingMessage = formatMessage({
        id: 'creative.studio.generate.loading.message',
        defaultMessage: "We're working on generating your images. It will only take a few seconds.",
    });
    const { [COMMON_FLAGS.LOADING_BAR_TIME_FOR_REPLACE_BACKGROUND]: loadingTimeSetting } = useCommonConfig(
        COMMON_FLAGS.LOADING_BAR_TIME_FOR_REPLACE_BACKGROUND
    );
    const loadingTime = parseInt(loadingTimeSetting);

    const onBeforeLoad = () => {
        setImageResultsStatus(COMPONENT_STATUS.LOADING);
        setIsPolicyViolated(false);
    };
    const onLoad = () => setImageResultsStatus(COMPONENT_STATUS.ACTIVE);
    const onError = error => {
        if (error.status === ERROR_CODES.UNPROCESSABLE_ENTITY) {
            setIsPolicyViolated(true);
        }
        setImageResultsStatus(COMPONENT_STATUS.ERROR);
    };

    const generateNewBackgrounds = async () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${EVENTS_PREFIX}: Generate Background Clicked`,
            taboolaCampaignId: campaignId,
        });
        setSelectedImageUrl(originalImage.url);
        const { results } = await fetchAIGeneratedReplacedBackgrounds({
            accountId,
            campaignId,
            imageUrl: originalImage.url,
            textPrompt: backgroundPrompt,
            foregroundMask: segmentMask,
            onBeforeLoad,
            onLoad,
            onError,
        });
        setImageResults(results);
    };

    const sendResultClickEvent = imageUrl => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${EVENTS_PREFIX}: Result Clicked`,
            value: `Item URL: ${imageUrl}`,
            taboolaCampaignId: campaignId,
        });
    };

    return (
        <div className={styles['container']}>
            {hasError && (
                <CreativeStudioContentBanner
                    type={CONTENT_BANNER_TYPES.ERROR}
                    id={isPolicyViolated ? 'app.genai.againstpolicy.error' : 'creative.studio.error.generic'}
                    defaultMessage={
                        isPolicyViolated
                            ? 'The AI image generation is currently not possible as the thumbnail may not adhere to our policy standards.'
                            : 'We were unable to process the request. Please try again.'
                    }
                />
            )}
            <CreativesStudioGallery
                imageResults={imageResults}
                GalleryItemDropdown={StudioGalleryItemDropdown}
                MainGalleryComponent={!isSegmentSelectionActive ? GalleryItem : ReplaceBackgroundSegmentAnything}
                setSegmentMask={setSegmentMask}
                imageResultsStatus={imageResultsStatus}
                sendResultClickEvent={sendResultClickEvent}
                shouldHideDropdown={originalImage?.url === selectedImageUrl}
                {...props}
            >
                {isSegmentAnythingVisible && (
                    <ReplaceBackgroundSegmentAnythingToggle setIsSegmentSelectionActive={setIsSegmentSelectionActive} />
                )}

                <div
                    className={classNameBuilder('replace-bg-input-prompt', {
                        'segment-anything-disabled': !isSegmentAnythingEnabled,
                    })}
                >
                    <FormattedMessage
                        id="creative.studio.replace.background.generate.help"
                        defaultMessage="Describe your desired background"
                    />
                </div>
                <CreativesStudioTextInput
                    input={backgroundPrompt}
                    setInput={setBackgroundPrompt}
                    onGenerateClick={generateNewBackgrounds}
                    placeholder={placeholder}
                    isAllInputDisabled={isGalleryLoading}
                    isGenerateDisabled={isGenerateDisabled}
                    buttonMessage={
                        <FormattedMessage
                            id="creative.studio.replace.background.generate.button"
                            defaultMessage="Generate Background"
                        />
                    }
                />
            </CreativesStudioGallery>
            {isGalleryLoading && (
                <LoadingBar
                    durationInSeconds={loadingTime}
                    loadingMessage={loadingMessage}
                    containerClassName={styles['loading-bar']}
                />
            )}
        </div>
    );
};
