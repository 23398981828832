import React from 'react';
import { EmailOutlinedIcon } from 'tuui';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { CreateScheduledReportsButton } from '../CreateButton/CreateScheduledReportsButton';
import styles from './scheduledReportsEmptyState.module.scss';

export const ScheduledReportsEmptyState = () => (
    <div className={styles['container-empty']}>
        <EmailOutlinedIcon className={styles['performance-rules-icon']} />
        <div className={styles['header']}>
            <FormattedMessage
                className={styles['header']}
                id="app.campaigns.reports.scheduledReports.report.empty"
                defaultMessage="There are no Scheduled Reports"
            />
        </div>
        <div className={styles['create-button']}>
            <CreateScheduledReportsButton />
        </div>
    </div>
);
