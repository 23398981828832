import React from 'react';
import PropTypes from 'prop-types';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import EmptyThumbnailSVG from '../EmptyThumbnailSVG/EmptyThumbnailSVG';
import styles from './thumbnailLoading.module.scss';

const ThumbnailLoading = () => (
    <div className={styles['container']}>
        <EmptyThumbnailSVG />
        <LoadingAnimation
            className={styles['loading']}
            label={<FormattedMessage id="creative.creator.thumbnails.uploading" defaultMessage="Uploading..." />}
        />
    </div>
);

ThumbnailLoading.propTypes = {
    buttonLabel: PropTypes.node,
};

export default ThumbnailLoading;
