import React from 'react';
import classnames from 'classnames/bind';
import { CheckboxGroup, TextCheckbox, Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import styles from './mailDomainsDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const MailDomainsDropdownOption = ({ data: { label }, selectProps: { inputValue }, includedDomains }) => {
    return (
        <div className={classNameBuilder('label')}>
            <CheckboxGroup selectedValues={includedDomains}>
                <TextCheckbox
                    label={`${label}-checkbox`}
                    value={label}
                    title={
                        <Dotdotdot lines={3}>
                            <Highlighter text={label} highlightedText={inputValue} className={styles['highlighter']} />
                        </Dotdotdot>
                    }
                />
            </CheckboxGroup>
        </div>
    );
};
