import React from 'react';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { SimilarityRangeSliderBase } from './SimilarityRangeSliderBase';

export const CRMLookalikeSimilarityRangeSlider = props => {
    const { formatMessage } = useIntl();
    const percentageValueLabelFormat = value =>
        formatMessage(
            {
                id: `campaign.editor.targeting.my.audiences.similarity.slider.value`,
                defaultMessage: 'Top {value}%',
            },
            { value }
        );

    return (
        <SimilarityRangeSliderBase min={5} max={25} step={5} valueLabelFormat={percentageValueLabelFormat} {...props} />
    );
};
