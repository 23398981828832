export const PLATFORM_TARGETING_MAP = {
    DESKTOP: 'DESK',
    SMARTPHONE: 'PHON',
    TABLET: 'TBLT',
    IN_APP: 'APP',
};

const transformPlatformTargetingFromGW = campaign => {
    const { platformTargeting } = campaign;
    return {
        ...campaign,
        platformTargeting: platformTargeting?.map(platform => PLATFORM_TARGETING_MAP[platform]),
    };
};
const transformPlatformTargetingToGW = campaign => {
    const { platformTargeting } = campaign;
    return {
        ...campaign,
        platformTargeting: platformTargeting?.map(platform =>
            Object.keys(PLATFORM_TARGETING_MAP).find(key => PLATFORM_TARGETING_MAP[key] === platform)
        ),
    };
};

export { transformPlatformTargetingFromGW, transformPlatformTargetingToGW };
