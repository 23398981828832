import addRecommendationsQueryParams from '../../../modules/recommendations/utils/queryParamUtils';
import { stringifyQueryParams } from '../../../modules/taboola-common-frontend-modules/query-params/queryParamUtils';

const recommendationsPrefix = accountId => `/${accountId}/recommendations`;
const dataAdditionalCpcBasePath = accountId => `${recommendationsPrefix(accountId)}/data-additional-cpc`;
const indicationBasePath = accountId => `${recommendationsPrefix(accountId)}/indications`;
const retrieveCampaignRecommendationsPath = (accountId, campaignId) => {
    return `${recommendationsPrefix(accountId)}/campaign/${campaignId}`;
};
const syndicatorRecommendationCountsPrefix = accountName => `${recommendationsPrefix(accountName)}/campaigns/count`;
const executeCampaignRecommendationActionPath = (accountId, campaignId, recommendationId, actionId, actionParams) => {
    const urlRequest = `${recommendationsPrefix(
        accountId
    )}/campaign/${campaignId}/recommendation/${recommendationId}/execute-action/${actionId}`;
    if (actionParams) {
        return `${urlRequest}${stringifyQueryParams(actionParams)}`;
    }
    return urlRequest;
};
const executeItemRecommendationActionPath = (accountId, campaignId, itemId, recommendationId, actionId) =>
    `${recommendationsPrefix(
        accountId
    )}/campaign/${campaignId}/item/${itemId}/recommendation/${recommendationId}/execute-action/${actionId}`;
const dismissCampaignsPerformanceRecommendationPath = (accountId, campaignId, recommendationId) =>
    `${recommendationsPrefix(accountId)}/campaign/${campaignId}/${recommendationId}`;
const dismissItemPerformanceRecommendationPath = (accountId, campaignId, itemId, recommendationId) =>
    `${recommendationsPrefix(accountId)}/campaign/${campaignId}/item/${itemId}/recommendation/${recommendationId}`;

export const recommendationApiFactory = ({ callGetApi, callPostApi, callDeleteApi }) => ({
    getDataAdditionalCpc: (accountId, { campaignId, uddIdsString }) => {
        const requestUrl = dataAdditionalCpcBasePath(accountId);
        const requestUrlWithParams = addRecommendationsQueryParams(requestUrl, { campaignId, uddIdsString });
        return callGetApi(requestUrlWithParams);
    },
    getRecommendationsIndication: accountId => callGetApi(indicationBasePath(accountId)),
    getSyndicatorRecommendationCounts: accountName => callGetApi(syndicatorRecommendationCountsPrefix(accountName)),
    getCampaignRecommendations: (accountId, campaignId, recommendationTypes) =>
        callGetApi(retrieveCampaignRecommendationsPath(accountId, campaignId), {
            recommendationTypes,
        }),
    executeCampaignRecommendationAction: ({ accountId, campaignId, recommendationId, actionId, actionParams }) =>
        callPostApi(
            executeCampaignRecommendationActionPath(accountId, campaignId, recommendationId, actionId),
            actionParams
        ),
    dismissCampaignsPerformanceRecommendation: ({ accountId, campaignId, recommendationId }) =>
        callDeleteApi(dismissCampaignsPerformanceRecommendationPath(accountId, campaignId, recommendationId)),
    executeItemRecommendationAction: ({ accountId, campaignId, itemId, recommendationId, actionId, actionParams }) =>
        callPostApi(
            executeItemRecommendationActionPath(accountId, campaignId, itemId, recommendationId, actionId),
            actionParams
        ),
    dismissItemPerformanceRecommendation: ({ accountId, campaignId, itemId, recommendationId }) =>
        callDeleteApi(dismissItemPerformanceRecommendationPath(accountId, campaignId, itemId, recommendationId)),
});
