import { useMemo } from 'react';
import { DAYS_OF_WEEK } from 'modules/campaigns/modules/common-campaign-form/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { formatHour } from '../DayTimeBidBoost/consts/dayTimeDaysOptions';
import { getDayTimeRulesSummary } from './getDayTimeRulesSummary';
import styles from './schedulerDayTimeSummary.module.scss';

const Hours = ({ intervals }) => {
    return (
        <span>
            {intervals
                .map(interval => {
                    const fromHour = formatHour(interval.fromHour);
                    const untilHour = formatHour(interval.untilHour);
                    return `${fromHour}-${untilHour}`;
                })
                .join(', ')}
        </span>
    );
};

const Days = ({ startDay, endDay }) => {
    if (startDay === endDay) {
        return (
            <FormattedMessage
                id={`campaign.editor.schedule.dayparting.day.summary.${startDay}`}
                defaultMessage={`<b>${startDay}</b>`}
            />
        );
    }

    if (startDay === DAYS_OF_WEEK.MONDAY && endDay === DAYS_OF_WEEK.SUNDAY) {
        return (
            <FormattedMessage id="campaign.editor.schedule.dayparting.day.allDays" defaultMessage="<b>All Days</b>" />
        );
    }

    return (
        <FormattedMessage
            id="campaign.editor.schedule.dayparting.day.summary.interval"
            defaultMessage="<b>{startDay} - {endDay}</b>"
            values={{
                startDay: (
                    <FormattedMessage
                        id={`campaign.editor.schedule.dayparting.day.summary.${startDay}`}
                        defaultMessage={startDay}
                    />
                ),
                endDay: (
                    <FormattedMessage
                        id={`campaign.editor.schedule.dayparting.day.summary.${endDay}`}
                        defaultMessage={endDay}
                    />
                ),
            }}
        />
    );
};

export const SchedulerDayTimeSummary = ({ isDayPartingPermitted }) => {
    const { value: campaignScheduleRules } = useFormFieldValue(
        { field: 'campaignSchedule.rules' },
        { isDayPartingPermitted }
    );

    const rulesSummary = useMemo(() => getDayTimeRulesSummary({ campaignScheduleRules }), [campaignScheduleRules]);

    if (rulesSummary.length === 0) {
        return null;
    }

    return (
        <div className={styles['scheduler-day-time-summary']}>
            <div className={styles['horizontal-delimiter']} />
            <div className={styles['scheduler-day-time-summary-title']}>
                {rulesSummary.map(ruleSummary => (
                    <div className={styles['day-summary']} key={ruleSummary.startDay}>
                        <Days startDay={ruleSummary.startDay} endDay={ruleSummary.endDay} />
                        <Hours intervals={ruleSummary.intervals} />
                    </div>
                ))}
            </div>
            <div className={styles['horizontal-delimiter']} />
        </div>
    );
};
