import React from 'react';
import { Divider } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './noResultComponent.module.scss';

const NoResultComponent = ({
    relatedTopics,
    searchTopics,
    isFetching,
    isInputValid,
    isExcludeTargetingType,
    children,
}) => {
    if (isFetching || searchTopics) {
        return <>{children}</>;
    }

    return (
        <div aria-label="no-results-component">
            {isInputValid && (
                <div className={styles['no-results-container']}>
                    <FormattedMessage
                        className={styles['title']}
                        id="custom.contextual.editor.topics.dropdown.no.results.found.title"
                        defaultMessage="No results found"
                    />
                </div>
            )}
            {relatedTopics && !isExcludeTargetingType && (
                <>
                    {isInputValid && <Divider />}
                    <div className={styles['related-topics-header-container']}>
                        <FormattedMessage
                            className={styles['related-topics-header']}
                            id="custom.contextual.editor.topics.dropdown.related.topics.header"
                            defaultMessage="Related Topics (based on selections)"
                        />
                    </div>
                </>
            )}
            {children}
        </div>
    );
};

export default NoResultComponent;
