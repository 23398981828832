import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from '../../../../../../selectors';
import normalizeHeaderRenderer from './normalizeHeaderRenderer';

const DateHeaderRenderer = ({ headerName }) => {
    const { timeZoneName } = useSelector(selectedAccountSelector);

    return (
        <FormattedMessage
            id="app.campaigns.reports.grid.column.smartHeader"
            defaultMessage={headerName}
            values={{ value: headerName, details: timeZoneName }}
        />
    );
};

DateHeaderRenderer.propTypes = {
    headerName: PropTypes.string,
};

export default normalizeHeaderRenderer(DateHeaderRenderer);
