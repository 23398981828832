import React, { useState } from 'react';
import { useCreateFormSelectedCampaignId } from 'modules/campaigns/modules/creative-creator/hooks/useCreateFormSelectedCampaignId';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { getHasPageProductDetails, useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { GenerateDetailsButton } from './GenerateDetailsButton';
import { LpLoadFromUrlCheckbox } from './LpLoadFromUrlCheckbox';
import { TitlesGenerationDetailsFormBase } from './TitlesGenerationDetailsFormBase';
import styles from './titlesGenerationDetailsForm.module.scss';

export const TitlesGenerationDetailsForm = () => {
    const campaignId = useCreateFormSelectedCampaignId();
    const [generationStatus, setGenerationStatus] = useState(COMPONENT_STATUS.INITIAL);
    const data = useAiVariationsContext();
    const {
        crawlLpStatus,
        productDescription,
        targetAudience,
        uniqueSellingProposition,
        onProductDescriptionChange,
        onTargetAudienceChange,
        onUniqueSellingPropositionChange,
        onGenerationSuccess,
        onGenerationError,
    } = data;
    const { value: urls } = useFormFieldValue({
        field: 'urls',
    });
    const url = urls[0]?.value || '';

    const isLoadingLp = crawlLpStatus === COMPONENT_STATUS.LOADING;

    const isGenerating = generationStatus === COMPONENT_STATUS.LOADING;

    const isGenerateDisabled = !getHasPageProductDetails(data) || isLoadingLp;

    return (
        <div className={styles['search-container']}>
            <div className={styles['header']}>
                <FormattedMessage
                    id="creative.creator.aiVariationsSetup.generate.header"
                    defaultMessage="Share your product details to unlock AI Generated titles & descriptions"
                />
            </div>
            <LpLoadFromUrlCheckbox url={url} />
            <TitlesGenerationDetailsFormBase
                isLoadingLp={isLoadingLp}
                productDescription={productDescription}
                targetAudience={targetAudience}
                uniqueSellingProposition={uniqueSellingProposition}
                onProductDescriptionChange={onProductDescriptionChange}
                onTargetAudienceChange={onTargetAudienceChange}
                onUniqueSellingPropositionChange={onUniqueSellingPropositionChange}
                isGenerating={isGenerating}
            />
            <div className={styles['button-container']}>
                <GenerateDetailsButton
                    isGenerating={isGenerating}
                    setGenerationStatus={setGenerationStatus}
                    disabled={isGenerateDisabled}
                    campaignId={campaignId}
                    onSuccess={onGenerationSuccess}
                    onError={onGenerationError}
                />
            </div>
        </div>
    );
};
