import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

export const HighImpactTargetingTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.highImpactTargeting.tooltip"
            defaultMessage="Package of High Quality, non-disruptive placements that capture user-attention to deliver your brand message with adjacency control that ensures the placement is surrounded by safe publisher content. <strong>This offering comes at a premium rate.</strong>"
        />
    </TooltipSection>
);

export default HighImpactTargetingTooltip;
