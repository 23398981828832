import React from 'react';
import PropTypes from 'prop-types';
import { OkOIcon, DeletableItem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './customPresetsListItem.module.scss';

const CustomPresetListItem = ({ item, deleteItem }) => {
    const { label = '' } = item;

    const handleOnClick = () => {
        deleteItem(item);
    };

    return (
        <DeletableItem onDelete={handleOnClick}>
            <div className={styles['container']}>
                <span>
                    <OkOIcon className={styles['include']} role="ok-icon" />
                </span>
                <div className={styles['label']}>
                    <FormattedMessage
                        id={`video.campaign.creator.apps.targeting.options.${label}`}
                        defaultMessage={label}
                    />
                </div>
            </div>
        </DeletableItem>
    );
};

CustomPresetListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
};

export default CustomPresetListItem;
