import React, { useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { AutoFixNormalOutlinedIcon, Button, Spinner } from 'tuui';
import { INDICATION_TYPES, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import { COMPONENT_STATUS } from 'services/constants';
import { DEFAULT_ZOOM, ORIGIN_POINT } from '../../constants/croppingConsts';
import { OUTCROPPING_COMPONENT_NAME } from '../../constants/croppingModalGtmConstants';
import { useCropAreaSize } from '../../hooks/useCropAreaSize';
import { useCropMaxZoom } from '../../hooks/useCropMaxZoom';
import { getSanitizedCrop, isImageCropped, isNewCropDataSimilarToPrevious } from '../../utils/cropDataUtils';
import { CroppingModalWarningBadge } from '../CroppingModalWarningBadge/CroppingModalWarningBadge';
import { OutcroppingGalleryItemDropdown } from '../OutcroppingGalleryItemDropdown/OutcroppingGalleryItemDropdown';
import { CropImageControls } from './CropImageControls';
import styles from './cropImage.module.scss';

const classNameBuilder = classnames.bind(styles);

export function CropImage({
    src,
    updateSrc,
    originalSrc,
    ratio = 16 / 9,
    onOutcropImage,
    srcHistory,
    setSrcHistory,
    outcroppingStatus,
    isOutcroppingEnabled,
    campaignId,
    shouldShowOutcropWarning,
    initialCropData,
    setInitialCropData,
    index,
}) {
    const { value: crop, onChange: setCrop } = useFormFieldValue({ field: 'crop' });
    const curCrop = useRef(crop || ORIGIN_POINT);
    const { value: zoom, onChange: setZoom } = useFormFieldValue({ field: 'zoom' });
    const { value: rotation, onChange: setRotation } = useFormFieldValue({ field: 'rotation' });
    const { onChange: setCroppedAreaPixels } = useFormFieldValue({
        field: 'croppedAreaPixels',
    });
    const curZoom = useRef(zoom || DEFAULT_ZOOM);

    const isRangeOfRatioOutcroppingEnabled = useConfigMatch({
        [FEATURE_FLAGS.RANGE_OF_RATIO_OUTCROPPING_ENABLED]: 'true',
    });
    const shouldSupportBpRatios = useConfigMatch({
        [FEATURE_FLAGS.SUPPORT_BP_ASPECT_RATIOS]: 'true',
    });

    const { validationService } = useValidationContext();

    const failedValidationData = validationService
        .getInvalidFields()
        ?.find(({ fieldId }) => fieldId === `cropData.${index}`)?.failedValidationData;

    const onChangeCrop = newCrop => {
        if (!isDraggable.current) {
            return;
        }

        const sanitizedCrop = getSanitizedCrop(newCrop);
        setCrop(sanitizedCrop);
        curCrop.current = sanitizedCrop;
    };
    const onChangeZoom = newZoom => {
        setZoom(newZoom);
        curZoom.current = newZoom;
    };

    const { message: errorMessage, indicationType } = failedValidationData || {};

    const maxZoom = useCropMaxZoom(src);

    const { setMediaSize, isDraggable } = useCropAreaSize(setInitialCropData, index);
    const onCropComplete = useCallback(
        (_, croppedAreaPixels) => {
            if (!isDraggable.current || !isImageCropped({ crop: curCrop.current, zoom: curZoom.current })) {
                return;
            }

            setCroppedAreaPixels(prev => {
                if (isNewCropDataSimilarToPrevious(prev, croppedAreaPixels)) {
                    return prev;
                }
                return croppedAreaPixels;
            });
        },
        [setCroppedAreaPixels, isDraggable]
    );

    const onExtendImageClick = useCallback(() => {
        const onOutcropImageProps = shouldShowOutcropWarning
            ? {}
            : {
                  method: 'RANGE_OF_RATIO',
                  maskRangeOfRatio: shouldSupportBpRatios ? { min: 1, max: 2 } : undefined,
              };

        onOutcropImage(onOutcropImageProps);

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: shouldShowOutcropWarning
                ? `${OUTCROPPING_COMPONENT_NAME}: Extend Image Button Clicked`
                : `${OUTCROPPING_COMPONENT_NAME}: Range of Ratio Button Clicked`,
            taboolaCampaignId: campaignId,
            value: src,
        });
    }, [campaignId, onOutcropImage, shouldShowOutcropWarning, shouldSupportBpRatios, src]);

    if (!crop || !zoom || rotation) {
        return null;
    }

    const showWarningIndication = indicationType === INDICATION_TYPES.WARNING;
    const shouldShowOutcroppingControls = isOutcroppingEnabled && srcHistory?.length > 0;
    // if outcropping is enabled and (the outcropping warning is shown or the range of ratio outcropping is enabled)
    //    OR if the outcropping is currently in progress, show the outcropping button */
    const shouldShowOutcropButton =
        (isOutcroppingEnabled && (shouldShowOutcropWarning || isRangeOfRatioOutcroppingEnabled)) ||
        outcroppingStatus === COMPONENT_STATUS.LOADING;

    return (
        <div className={classNameBuilder('container', { compact: shouldShowOutcroppingControls })}>
            <div className={styles['cropper-wrapper']}>
                <Cropper
                    image={src}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={ratio}
                    onCropChange={onChangeCrop}
                    objectFit="contain"
                    restrictPosition
                    onMediaLoaded={setMediaSize}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={onChangeZoom}
                    classes={{
                        containerClassName: classNameBuilder('crop-container', {
                            warning: showWarningIndication,
                        }),
                    }}
                    maxZoom={maxZoom}
                />
                {(showWarningIndication || shouldShowOutcropWarning) && (
                    <CroppingModalWarningBadge
                        shouldShowOutcropWarning={shouldShowOutcropWarning}
                        errorMessage={errorMessage}
                    />
                )}
                {shouldShowOutcropButton && (
                    <Button
                        onClick={onExtendImageClick}
                        className={styles['outcrop-button']}
                        disabled={outcroppingStatus === COMPONENT_STATUS.LOADING}
                        data-testid="cropping-content-outcrop-button"
                    >
                        <Tooltip position={TOOLTIP_POSITION.TOP}>
                            <FormattedMessage
                                id="cropping.content.outcrop.button.tooltip"
                                defaultMessage="We will automatically extend your ad's image using Generative AI without losing vital information. This can help you ensure your image isn't being cropped in any placement's aspect ratio"
                            />
                        </Tooltip>
                        {outcroppingStatus === COMPONENT_STATUS.LOADING ? (
                            <Spinner size={20} className={styles['spinner']} color="info" />
                        ) : (
                            <>
                                <div className={styles['outcrop-icon']}>
                                    <AutoFixNormalOutlinedIcon sx={{ transform: 'none' }} fontSize="small" />
                                </div>
                                <FormattedMessage id="cropping.content.outcrop.button" defaultMessage="Extend Image" />
                            </>
                        )}
                    </Button>
                )}
            </div>
            {shouldShowOutcroppingControls && (
                <div className={styles['dropdown-container']} data-testid="outcropping-dropdown">
                    <OutcroppingGalleryItemDropdown itemUrl={src} />
                </div>
            )}
            <CropImageControls
                src={src}
                updateSrc={updateSrc}
                originalSrc={originalSrc}
                srcHistory={srcHistory}
                setSrcHistory={setSrcHistory}
                isOutcroppingEnabled={isOutcroppingEnabled}
                shouldShowOutcroppingControls={shouldShowOutcroppingControls}
                maxZoom={maxZoom}
                setCroppedAreaPixels={setCroppedAreaPixels}
                initialCropData={initialCropData[index]}
            />
        </div>
    );
}

CropImage.propTypes = {
    src: PropTypes.string,
};
