import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from '../../../../../config/formModes';
import { ViewableImpressionsContent } from '../../../../campaigns/modules/common-campaign-form/components/ReachEstimator/ViewableImpressionsField/ViewableImpressionsContent';
import { ViewableImpressionsFieldTooltip } from '../../../../campaigns/modules/common-campaign-form/components/ReachEstimator/ViewableImpressionsField/ViewableImpressionsFieldTooltip';
import formatNumberCompact from '../../../../campaigns/services/utils/formatNumberCompact';
import { useFormDataContext, useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import { SizeEstimationCountryField } from '../../SegmentTopics/CustomContextualSizeEstimationWidget/SizeEstimationCountryField/SizeEstimationCountryField';
import { SizeEstimationPlatformField } from '../../SegmentTopics/CustomContextualSizeEstimationWidget/SizeEstimationPlatformField/SizeEstimationPlatformField';
import { useCountriesField } from '../../SegmentTopics/hooks/useCountriesField';
import { useCombinedSegmentsSizeEstimateApi } from '../hooks/useCombinedSegmentsSizeEstimateApi';
import { Banner } from './Banner';
import styles from './CombinedSegmentsSizeEstimationWidget.module.scss';

const classNameBuilder = classnames.bind(styles);

export const checkInputValid = ({ platforms, countries, audiences, isAudiencesValid }) => {
    const isPlatformSelected = platforms && platforms.length > 0;
    const isCountriesSelected = countries && !!countries.length;
    const isAudiencesSelected = audiences && isAudiencesValid({ audiences });
    const isInputValid = isAudiencesSelected && isPlatformSelected && isCountriesSelected;
    return { isPlatformSelected, isAudiencesSelected, isCountriesSelected, isInputValid };
};

const checkAudiencesValid = ({ audiences }) => {
    return audiences && audiences.filter(item => item?.audiences?.length > 0).length > 0;
};

const getMsgId = (isAudiencesSelected, isPlatformSelected, isCountriesSelected) => {
    if (!isAudiencesSelected) {
        return 'audience.creator.combined.audiences.size.estimation.widget.no-audiences-selected';
    }

    if (!isPlatformSelected) {
        return 'audience.creator.combined.audiences.size.estimation.widget.no-platform-selected';
    }

    if (!isCountriesSelected) {
        return 'audience.creator.combined.audiences.size.estimation.widget.no-country-selected';
    }

    return 'audience.creator.combined.audiences.size.estimation.widget.error';
};

export const ViewableImpressionsField = ({ isInputValid }) => {
    const {
        lowerBound,
        noFiltersChosen,
        isLoading,
        isSmallReach,
        isReachOverLimit,
        reachLimit,
        isError,
        reachThreshold,
    } = useCombinedSegmentsSizeEstimateApi(isInputValid);
    const { formatMessage } = useIntl();

    const formattedMin = formatNumberCompact(lowerBound, 1);
    const formattedReachLimit = formatNumberCompact(reachLimit, 1);

    return (
        <div data-testid="viewable-impressions-field">
            <FormField
                inputId="reach-min-max"
                label={formatMessage({
                    id: 'audience.creator.combined.audiences.size.estimation.widget.title',
                    defaultMessage: 'Viewable Impressions',
                })}
                helpText={<ViewableImpressionsFieldTooltip />}
                className={styles['viewable-impressions']}
            >
                <div data-testid="reach-range" className={styles['reach-range']}>
                    <ViewableImpressionsContent
                        noFiltersChosen={noFiltersChosen}
                        isLoading={isLoading}
                        isSmallReach={isSmallReach}
                        isReachOverLimit={isReachOverLimit}
                        isError={isError}
                        formattedMin={formattedMin}
                        isOnlyLowerBound={true}
                        formattedReachLimit={formattedReachLimit}
                        reachThreshold={reachThreshold}
                    />
                </div>
            </FormField>
        </div>
    );
};

export const CombinedSegmentsSizeEstimationWidget = () => {
    const { value: audiences = [] } = useFormFieldValue({
        field: 'audienceSetup',
    });
    const { value: countries = [] } = useCountriesField();

    const { value: platforms = [] } = useFormFieldValue({
        field: 'platforms',
    });
    const { isPlatformSelected, isAudiencesSelected, isCountriesSelected, isInputValid } = checkInputValid({
        platforms,
        countries,
        audiences,
        isAudiencesValid: checkAudiencesValid,
    });

    const { isSmallReach, isError } = useCombinedSegmentsSizeEstimateApi(isInputValid);

    const msgId = getMsgId(isAudiencesSelected, isPlatformSelected, isCountriesSelected);
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.EDIT;

    return (
        <div
            className={classNameBuilder('container', {
                'edit-mode': isEditMode,
                'small-reach': isSmallReach,
                error: isInputValid && isError,
            })}
        >
            <div className={styles['header']}>
                <FormattedMessage id="custom.contextual.size.estimation.widget" defaultMessage="Segment Size" />
            </div>
            <div className={styles['content']}>
                <SizeEstimationCountryField />
                <SizeEstimationPlatformField />
                {isInputValid ? (
                    <ViewableImpressionsField isInputValid={isInputValid} />
                ) : (
                    <Banner msgId={msgId} className={styles['banner']} />
                )}
            </div>
        </div>
    );
};

export default CombinedSegmentsSizeEstimationWidget;
