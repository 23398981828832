import React from 'react';
import Draggable from 'react-draggable';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { PlusIcon } from 'taboola-ultimate-ui';
import { FOCUS_TYPES, FOCUS_TYPES_ARRAY } from '../../../config';
import { shiftCenteredCoordinatesToOffsets, shiftOffsetsToCenteredCoordinates } from '../../../services';
import styles from './focalPointIndicator.module.scss';

const calculateDraggablePosition = (focusType, width, height, offsets) => {
    if (focusType === FOCUS_TYPES.AUTOMATIC || !offsets) {
        return { x: 0, y: 0 };
    }

    const { left, top } = offsets;
    return shiftOffsetsToCenteredCoordinates(width, height, left, top);
};

const FocalPointIndicator = ({
    focusType,
    imageTarget: { clientWidth, clientHeight },
    bounds,
    offsets,
    onChangePosition,
}) => {
    const draggablePosition = calculateDraggablePosition(focusType, clientWidth, clientHeight, offsets);

    const handleDragStop = (event, { x, y }) => {
        const newOffsets = shiftCenteredCoordinatesToOffsets(clientWidth, clientHeight, x, y);
        onChangePosition(newOffsets);
    };

    return (
        <div className={styles['centered']}>
            <Draggable bounds={bounds} onStop={handleDragStop} position={draggablePosition}>
                <div className={styles['indicator']}>
                    <PlusIcon />
                </div>
            </Draggable>
        </div>
    );
};

FocalPointIndicator.propTypes = {
    focusType: PropTypes.oneOf(FOCUS_TYPES_ARRAY),
    imageTarget: PropTypes.shape({
        clientWidth: PropTypes.number,
        clientHeight: PropTypes.number,
    }).isRequired,
    bounds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    offsets: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
    }),
    onChangePosition: PropTypes.func,
};

FocalPointIndicator.defaultProps = {
    focusType: FOCUS_TYPES.AUTOMATIC,
    onChangePosition: noop,
    bounds: 'parent',
};

export default FocalPointIndicator;
