'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'wizardStepNavigationButton__taboola-svg-icon___3SVV7',
    'wizard-navigation-section': 'wizardStepNavigationButton__wizard-navigation-section___2fV2b',
    'active': 'wizardStepNavigationButton__active___3hG5O',
    'wizard-navigation-button-number': 'wizardStepNavigationButton__wizard-navigation-button-number___1rmTX',
    'wizard-navigation-button-label': 'wizardStepNavigationButton__wizard-navigation-button-label___2cQRm',
    'wizard-label': 'wizardStepNavigationButton__wizard-label___21g4k',
    'wizard-navigation-substeps': 'wizardStepNavigationButton__wizard-navigation-substeps___nSOef'
}; /**
    * Created by oreuveni
    * Date: 2018-11-28
    * Time: 12:38
    */

var classNameBuilder = _bind2.default.bind(styles);

var WizardStepNavigationButton = function WizardStepNavigationButton(props) {
    var active = props.active,
        displayNumber = props.displayNumber,
        label = props.label,
        onClick = props.onClick,
        className = props.className,
        activeClassName = props.activeClassName,
        buttonNumberClassName = props.buttonNumberClassName,
        completeIcon = props.completeIcon,
        isComplete = props.isComplete,
        registerStep = props.registerStep;

    var buttonClassName = styles['wizard-navigation-button'] + ' ' + (className || '') + ' ' + (active ? styles['active'] + ' ' + (activeClassName || '') : '');

    (0, _react.useEffect)(function () {
        if (registerStep) {
            return registerStep('' + (displayNumber - 1));
        }
        return function () {};
    }, [registerStep, displayNumber]);

    return _react2.default.createElement(
        'div',
        {
            className: buttonClassName,
            role: 'button',
            tabIndex: 0,
            onClick: onClick
        },
        _react2.default.createElement(
            'span',
            {
                className: classNameBuilder('wizard-navigation-button-number', buttonNumberClassName)
            },
            isComplete ? completeIcon || displayNumber : displayNumber
        ),
        _react2.default.createElement(
            'span',
            { className: styles['wizard-navigation-button-label'] },
            label
        )
    );
};

WizardStepNavigationButton.propTypes = {
    active: _propTypes2.default.bool,
    displayNumber: _propTypes2.default.number,
    label: _propTypes2.default.node,
    onClick: _propTypes2.default.func,
    className: _propTypes2.default.string,
    activeClassName: _propTypes2.default.string,
    buttonNumberClassName: _propTypes2.default.string,
    isComplete: _propTypes2.default.bool,
    completeIcon: _propTypes2.default.node,
    registerStep: _propTypes2.default.func
};

exports.default = WizardStepNavigationButton;