'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.GRAPH_TYPES = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.pick');

var _lodash4 = _interopRequireDefault(_lodash3);

var _rechartsScale = require('recharts-scale');

var _recharts = require('recharts');

var _d3Scale = require('d3-scale');

var _GraphTooltip = require('./GraphTooltip');

var _GraphTooltip2 = _interopRequireDefault(_GraphTooltip);

var _CartesianGrid = require('./CartesianGrid');

var _CartesianGrid2 = _interopRequireDefault(_CartesianGrid);

var _Legend = require('./Legend');

var _Legend2 = _interopRequireDefault(_Legend);

var _DefaultBar = require('./DefaultBar/DefaultBar');

var _DefaultBar2 = _interopRequireDefault(_DefaultBar);

var _CustomLineDot = require('./CustomLineDot/CustomLineDot');

var _graphUtils = require('./graphUtils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'fontFamily': 'var(--regular-font-stack)',
    'fontSize': '1.2rem',
    'yAxisColor': 'var(--gray-7)',
    'xAxisColor': 'var(--gray-8)',
    'activeDotFill': 'var(--white)',
    'cartesianGridColor': 'var(--gray-4)',
    'yAxisFontWeight': '500',
    'xAxisFontWeight': '500',
    'singleYAxisColor': 'var(--gray-4)',
    'taboola-svg-icon': 'taboolaGraph__taboola-svg-icon___36gUA',
    'graph-container': 'taboolaGraph__graph-container___1lmW8',
    'responsive-graph-container': 'taboolaGraph__responsive-graph-container___68A_j',
    'overlay-container': 'taboolaGraph__overlay-container___dmZF2',
    'label': 'taboolaGraph__label___1GQS2',
    'bar-preview': 'taboolaGraph__bar-preview___1T1Hh',
    'bar-preview-animation': 'taboolaGraph__bar-preview-animation___2CWuW',
    'invisible': 'taboolaGraph__invisible___1q9Y_'
};


var classNameBuilder = _bind2.default.bind(styles);
var defaultFormatter = function defaultFormatter(value) {
    return value;
};

var GRAPH_TYPE = { BAR: 'BAR', LINE: 'LINE' };
var AXIS_DOMAIN = { DATA_MIN: 'dataMin', DATA_MAX: 'dataMax' };
var AXIS_TYPE = { NUMBER: 'number' };
var AXIS_SCALE = { LINEAR: 'linear' };

// Let's leave it for a while for backward compatibility
var GRAPH_TYPES = exports.GRAPH_TYPES = GRAPH_TYPE;

var parseReferenceLines = function parseReferenceLines(activeEdges) {
    return activeEdges.reduce(function (acc, currEdge) {
        acc.push(currEdge);
        if (Array.isArray(currEdge.referenceLines)) {
            currEdge.referenceLines.forEach(function (referenceEdge) {
                acc.push(Object.assign({}, referenceEdge, { referenceTo: currEdge.name }));
            });
        }
        return acc;
    }, []);
};

var TaboolaGraph = function (_Component) {
    _inherits(TaboolaGraph, _Component);

    function TaboolaGraph(props) {
        _classCallCheck(this, TaboolaGraph);

        var _this = _possibleConstructorReturn(this, (TaboolaGraph.__proto__ || Object.getPrototypeOf(TaboolaGraph)).call(this, props));

        _this.getOverlayComponent = function (values, noEdgesSelected) {
            var _this$props = _this.props,
                NoDataOverlayComponent = _this$props.NoDataOverlayComponent,
                NoEdgesOverlayComponent = _this$props.NoEdgesOverlayComponent;

            var overlay = null;
            if (values.length === 0) {
                overlay = _react2.default.createElement(NoDataOverlayComponent, null);
            } else if (noEdgesSelected) {
                overlay = _react2.default.createElement(NoEdgesOverlayComponent, null);
            }
            return overlay;
        };

        _this.getSelectedEdgesSortedList = function (edges) {
            return Object.keys(edges).filter(function (key) {
                return edges[key].selected;
            }).map(function (edgeName) {
                return Object.assign({ edgeName: edgeName }, edges[edgeName]);
            }).sort(function (a, b) {
                return a.selectionOrder <= b.selectionOrder ? -1 : 1;
            });
        };

        _this.getShownYAxisOrder = function (edges) {
            return _this.getSelectedEdgesSortedList(edges).map(function (edge) {
                return edge.edgeName;
            });
        };

        _this.getTicks = function (values) {
            var max = Math.max.apply(Math, _toConsumableArray(values)) * 1.1;
            var domain = (0, _d3Scale.scaleLinear)().domain([0, max]).nice().domain();
            return (0, _rechartsScale.getNiceTickValues)(domain, 5);
        };

        _this.alignSelectionOrder = function (edges) {
            var newEdges = Object.assign({}, edges);
            var indexMap = _this.getSelectedEdgesSortedList(edges);

            indexMap.forEach(function (el, index) {
                newEdges[el.edgeName].selectionOrder = index;
            });

            return newEdges;
        };

        _this.toggleEdge = function (edgeName) {
            var _this$props2 = _this.props,
                onToggle = _this$props2.onToggle,
                edges = _this$props2.edges;

            var newEdges = edges[edgeName].selected ? Object.assign({}, edges, _defineProperty({}, edgeName, { selected: false })) : Object.assign({}, edges, _defineProperty({}, edgeName, { selected: true, selectionOrder: Object.keys(edges).length }));

            onToggle(_this.alignSelectionOrder(newEdges), Object.assign({ edgeName: edgeName }, newEdges[edgeName]));
        };

        _this.togglePreviewedEdge = function (previewedEdge, isPreviewAvailable) {
            _this.setState({ previewedEdge: previewedEdge, isPreviewAvailable: isPreviewAvailable });
        };

        _this.prepareEdgesToLegend = function (edges) {
            var legendEdges = Object.assign({}, edges);

            Object.keys(legendEdges).forEach(function (key) {
                legendEdges[key] = !!legendEdges[key].selected;
            });

            return legendEdges;
        };

        _this.buildEdges = function () {
            var _this$state = _this.state,
                previewedEdge = _this$state.previewedEdge,
                isPreviewAvailable = _this$state.isPreviewAvailable;
            var _this$props3 = _this.props,
                edges = _this$props3.edges,
                config = _this$props3.config,
                type = _this$props3.type;

            var previewedEdgeConfig = config.find(function (edge) {
                return edge.name === previewedEdge;
            });
            var focused = previewedEdgeConfig && edges[previewedEdge].selected;
            var previewedEdgeArray = [];
            var activeEdges = [];
            var baseLineValue = 0;

            activeEdges = config.filter(function (edge) {
                return edges[edge.name] && edges[edge.name].selected;
            });

            if (type === GRAPH_TYPE.LINE) {
                previewedEdgeArray = previewedEdgeConfig ? [previewedEdgeConfig] : [];
                if (focused) {
                    activeEdges = [previewedEdgeConfig];
                    previewedEdgeArray = config.filter(function (edge) {
                        return edges[edge.name].selected && edge.name !== previewedEdge;
                    });
                    baseLineValue = edges[previewedEdge] && edges[previewedEdge].line && edges[previewedEdge].line.value;
                }

                var parsedPreviewedEdgeArray = parseReferenceLines(previewedEdgeArray);
                var parsedActiveEdges = parseReferenceLines(activeEdges);

                return {
                    previewedEdgeArray: parsedPreviewedEdgeArray,
                    activeEdges: parsedActiveEdges,
                    baseLineValue: baseLineValue
                };
            }

            if (type === GRAPH_TYPE.BAR) {
                activeEdges.sort(function (a, b) {
                    return edges[a.name].selectionOrder <= edges[b.name].selectionOrder ? -1 : 1;
                });
                if (previewedEdgeConfig) {
                    activeEdges.push(Object.assign({ isPreview: true }, previewedEdgeConfig));
                } else if (isPreviewAvailable && activeEdges.length) {
                    // Here we do a trick if we over the active edge, we need placeholder to keep a place and avoid jumping
                    // to do so we use last selected edge and it will be invisible
                    var invisibleEdge = Object.assign({}, activeEdges[activeEdges.length - 1], { isInvisible: true });
                    activeEdges.push(invisibleEdge);
                }
            }

            return {
                previewedEdgeArray: previewedEdgeArray,
                activeEdges: activeEdges,
                baseLineValue: baseLineValue
            };
        };

        _this.calculateTooltipCursor = function () {
            var _this$props4 = _this.props,
                type = _this$props4.type,
                drawTooltipCursor = _this$props4.drawTooltipCursor,
                maxBarSize = _this$props4.maxBarSize,
                barGap = _this$props4.barGap,
                maxAllowedSelectedEdges = _this$props4.maxAllowedSelectedEdges,
                values = _this$props4.data.values;


            return drawTooltipCursor && type === GRAPH_TYPES.BAR && values.length > 0 && {
                stroke: '#DAE7FB',
                strokeWidth: maxAllowedSelectedEdges * maxBarSize + maxAllowedSelectedEdges * barGap,
                strokeOpacity: '60%'
            };
        };

        _this.renderReferenceLineLabel = function (_ref, filter, opacity) {
            var label = _ref.label,
                offset = _ref.offset;
            return label && _react2.default.createElement(_recharts.Label, {
                className: styles['label'],
                value: label,
                position: 'left',
                offset: offset,
                filter: filter,
                opacity: opacity
            });
        };

        _this.renderAreaLine = function (edge) {
            var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.2;
            var name = edge.name,
                color = edge.color;
            var _this$props5 = _this.props,
                isLineAnimationActive = _this$props5.isLineAnimationActive,
                edges = _this$props5.edges,
                _this$props5$data$val = _this$props5.data.values,
                values = _this$props5$data$val === undefined ? [] : _this$props5$data$val;
            var previewedEdge = _this.state.previewedEdge;
            var _edges$name = edges[name],
                _edges$name$line = _edges$name.line,
                value = _edges$name$line.value,
                area = _edges$name$line.area,
                selected = _edges$name.selected;

            var focused = previewedEdge === name && selected;
            if (area && focused) {
                var valueList = values.map(function (val) {
                    return val[name];
                });
                var maxValue = Math.max.apply(Math, _toConsumableArray(valueList));
                var minValue = Math.min.apply(Math, _toConsumableArray(valueList));
                var offsetDenominator = maxValue - minValue;
                var offsetFallback = maxValue > value ? 0 : 1;
                var offset = offsetDenominator !== 0 ? (value - minValue) / offsetDenominator : offsetFallback;
                var gradientId = name + '-area-defs';
                return [_react2.default.createElement(
                    'defs',
                    { key: gradientId },
                    _react2.default.createElement(
                        'linearGradient',
                        { id: gradientId, x1: '0', y1: '1', x2: '0', y2: '0' },
                        _react2.default.createElement('stop', { offset: offset, stopColor: color, stopOpacity: area > 0 ? 0 : 1 }),
                        _react2.default.createElement('stop', { offset: offset, stopColor: color, stopOpacity: area > 0 ? 1 : 0 })
                    )
                ), _react2.default.createElement(_recharts.Area, {
                    key: name + '-area',
                    dataKey: name,
                    yAxisId: name,
                    stroke: 'none',
                    isAnimationActive: isLineAnimationActive,
                    fillOpacity: opacity,
                    fill: 'url(#' + gradientId + ')'
                })];
            }
            return [];
        };

        _this.renderReferenceLine = function (edge, opacity) {
            var name = edge.name,
                color = edge.color;
            var edges = _this.props.edges;
            var previewedEdge = _this.state.previewedEdge;
            var _edges$name2 = edges[name],
                _edges$name2$line = _edges$name2.line,
                line = _edges$name2$line === undefined ? {} : _edges$name2$line,
                selected = _edges$name2.selected;
            var value = line.value;

            var focused = previewedEdge === name && selected;
            var lines = [];
            if (value) {
                lines.push.apply(lines, _toConsumableArray(_this.renderAreaLine(edge)));
                // we need to utilize an svg filter to render the background color of the label because rechart's Label API
                // is extremely restrictive and calls .toString() on any non-node value passed in. The only alternative is
                // to render our own custom Label which calculates the x and y positioning which is equally messy
                // https://stackoverflow.com/questions/56172331/svg-text-with-background-color-and-rounded-borders
                var filterId = name + '-reference-line-defs';
                lines.push(_react2.default.createElement(
                    'defs',
                    { key: filterId },
                    _react2.default.createElement(
                        'filter',
                        { id: filterId, x: '-10%', width: '120%', y: '-15%', height: '125%' },
                        _react2.default.createElement('feFlood', { floodColor: color }),
                        _react2.default.createElement('feGaussianBlur', { stdDeviation: '2' }),
                        _react2.default.createElement(
                            'feComponentTransfer',
                            null,
                            _react2.default.createElement('feFuncA', { type: 'table', tableValues: '0 0 0 1' })
                        ),
                        _react2.default.createElement(
                            'feComponentTransfer',
                            null,
                            _react2.default.createElement('feFuncA', { type: 'table', tableValues: '0 1 1 1 1 1 1 1' })
                        ),
                        _react2.default.createElement('feComposite', { operator: 'over', 'in': 'SourceGraphic' })
                    )
                ), _react2.default.createElement(_recharts.ReferenceLine, {
                    key: name + '-reference-line',
                    dataKey: name,
                    yAxisId: name,
                    y: value,
                    label: focused && _this.renderReferenceLineLabel(line, 'url(#' + filterId + ')', opacity),
                    stroke: color,
                    strokeDasharray: '8 6',
                    strokeWidth: '2',
                    strokeLinecap: 'round',
                    strokeOpacity: opacity
                }));
            }
            return lines;
        };

        _this.renderLine = function (_ref2, opacity, isPreview) {
            var name = _ref2.name,
                _ref2$labelFormatter = _ref2.labelFormatter,
                labelFormatter = _ref2$labelFormatter === undefined ? defaultFormatter : _ref2$labelFormatter,
                label = _ref2.label,
                color = _ref2.color,
                strokeDasharray = _ref2.strokeDasharray,
                referenceTo = _ref2.referenceTo;
            var _this$props6 = _this.props,
                hoveredDotStyle = _this$props6.hoveredDotStyle,
                lineStrokeWidth = _this$props6.lineStrokeWidth,
                isLineAnimationActive = _this$props6.isLineAnimationActive,
                values = _this$props6.data.values;


            return _react2.default.createElement(_recharts.Line, {
                key: name,
                dataKey: name,
                yAxisId: referenceTo || name,
                formatter: labelFormatter,
                name: label,
                stroke: color,
                isAnimationActive: isLineAnimationActive,
                strokeOpacity: opacity,
                strokeWidth: lineStrokeWidth,
                dot: _react2.default.createElement(_CustomLineDot.CustomLineDot, { data: values, label: label, isReference: Boolean(referenceTo), isPreview: isPreview }),
                activeDot: hoveredDotStyle,
                strokeDasharray: strokeDasharray
            });
        };

        _this.renderBar = function (_ref3, className) {
            var name = _ref3.name,
                label = _ref3.label,
                color = _ref3.color,
                _ref3$labelFormatter = _ref3.labelFormatter,
                labelFormatter = _ref3$labelFormatter === undefined ? defaultFormatter : _ref3$labelFormatter;
            var _this$props7 = _this.props,
                isBarAnimationActive = _this$props7.isBarAnimationActive,
                barSize = _this$props7.barSize,
                maxBarSize = _this$props7.maxBarSize,
                barShape = _this$props7.barShape,
                singleYAxisId = _this$props7.singleYAxisId;


            return _react2.default.createElement(_recharts.Bar, {
                key: name,
                dataKey: name,
                yAxisId: singleYAxisId || name,
                barSize: barSize,
                maxBarSize: maxBarSize,
                name: label,
                fill: color,
                isAnimationActive: isBarAnimationActive,
                shape: barShape,
                formatter: labelFormatter,
                className: className
            });
        };

        _this.renderDataLayers = function (edges) {
            var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
            var isPreview = arguments[2];
            return edges.reduce(function (dataLayers, edge) {
                var type = _this.props.type;

                var newDataLayers = [];

                if (type === GRAPH_TYPE.LINE) {
                    newDataLayers.push(_this.renderLine(edge, opacity, isPreview));
                }

                if (type === GRAPH_TYPE.BAR) {
                    newDataLayers.push(_this.renderBar(edge, classNameBuilder({
                        'bar-preview': edge.isPreview,
                        invisible: edge.isInvisible
                    })));
                }

                return [].concat(_toConsumableArray(dataLayers), newDataLayers);
            }, []);
        };

        _this.renderSingleYAxis = function () {
            var _this$props8 = _this.props,
                yAxisTick = _this$props8.yAxisTick,
                config = _this$props8.config,
                singleYAxisId = _this$props8.singleYAxisId,
                singleYAxisColor = _this$props8.singleYAxisColor,
                singleYAxisMetricForFormatter = _this$props8.singleYAxisMetricForFormatter,
                values = _this$props8.data.values;

            var _ref4 = config.find(function (metricConfig) {
                return metricConfig.name === singleYAxisMetricForFormatter;
            }) || {},
                _ref4$yAxisFormatter = _ref4.yAxisFormatter,
                yAxisFormatter = _ref4$yAxisFormatter === undefined ? defaultFormatter : _ref4$yAxisFormatter;

            var displayedValues = values.reduce(function (vals, currentValue) {
                var currentValueMetrics = config.map(function (_ref5) {
                    var name = _ref5.name;
                    return currentValue[name];
                });
                return [].concat(_toConsumableArray(currentValueMetrics), _toConsumableArray(vals));
            }, []);
            var ticks = void 0;
            if (values.length > 0) {
                ticks = _this.getTicks(displayedValues);
            }

            return _react2.default.createElement(_recharts.YAxis, {
                yAxisId: singleYAxisId,
                tickFormatter: yAxisFormatter,
                hide: false,
                orientation: 'left',
                stroke: singleYAxisColor,
                ticks: ticks,
                tickLine: false,
                tick: yAxisTick,
                strokeWidth: 2
            });
        };

        _this.renderYAxis = function () {
            var _this$props9 = _this.props,
                yAxisTick = _this$props9.yAxisTick,
                config = _this$props9.config,
                edges = _this$props9.edges,
                singleYAxisId = _this$props9.singleYAxisId,
                values = _this$props9.data.values,
                type = _this$props9.type,
                isSingleYAxisDomain = _this$props9.isSingleYAxisDomain,
                singleDomainYAxisColor = _this$props9.singleDomainYAxisColor;


            var singleDomain = isSingleYAxisDomain ? (0, _graphUtils.getSingleDomain)(edges, _this.state.previewedEdge, values) : null;

            if (singleYAxisId) {
                return _this.renderSingleYAxis();
            }

            var visibleEdges = (0, _lodash4.default)(edges, config.map(function (el) {
                return el.name;
            }));
            var shownYAxisOrder = _this.getShownYAxisOrder(visibleEdges);

            var yAxis = config.map(function (_ref6) {
                var name = _ref6.name,
                    _ref6$yAxisFormatter = _ref6.yAxisFormatter,
                    yAxisFormatter = _ref6$yAxisFormatter === undefined ? defaultFormatter : _ref6$yAxisFormatter,
                    color = _ref6.color,
                    referenceLines = _ref6.referenceLines;

                var index = shownYAxisOrder.indexOf(name);
                var hideAxis = index < 0 || index > 1;
                var orientation = index === 1 ? 'right' : 'left';
                var lineValue = (0, _lodash2.default)(edges, [name, 'line', 'value']);
                var ticks = void 0;

                if (lineValue && values.length > 0) {
                    ticks = _this.getTicks([lineValue].concat(_toConsumableArray(values.map(function (value) {
                        return value[name];
                    }))));
                }

                var domain = [0, 'auto'];

                if (!singleDomain && referenceLines && type === GRAPH_TYPE.LINE) {
                    // We should manually calculate max YAxis value
                    var referenceLinesNames = referenceLines.map(function (line) {
                        return line.name;
                    }).flat();
                    var targetedProps = [name].concat(_toConsumableArray(referenceLinesNames));
                    var allValues = (0, _graphUtils.getAllPickedValues)(values, targetedProps);
                    domain = (0, _graphUtils.calcDomainByValues)(allValues);
                }

                return _react2.default.createElement(_recharts.YAxis, {
                    key: name + 'YAxis',
                    dataKey: name,
                    yAxisId: name,
                    tickFormatter: yAxisFormatter,
                    hide: hideAxis,
                    orientation: orientation,
                    stroke: isSingleYAxisDomain ? singleDomainYAxisColor : color,
                    ticks: ticks,
                    tickLine: false,
                    tick: yAxisTick,
                    strokeWidth: 2,
                    domain: singleDomain || domain
                });
            });

            return yAxis;
        };

        _this.renderXAxis = function (data) {
            var _this$props10 = _this.props,
                xAxisDataKey = _this$props10.xAxisDataKey,
                xAxisFormatter = _this$props10.xAxisFormatter,
                xAxisTick = _this$props10.xAxisTick,
                xAxisTickMargin = _this$props10.xAxisTickMargin,
                xAxisTickPadding = _this$props10.xAxisTickPadding,
                xAxisScale = _this$props10.xAxisScale,
                xAxisType = _this$props10.xAxisType,
                xAxisHeight = _this$props10.xAxisHeight,
                xAxisMinTickGap = _this$props10.xAxisMinTickGap,
                xAxisDomain = _this$props10.xAxisDomain,
                xAxisComponentRenderer = _this$props10.xAxisComponentRenderer;


            var xAxisProps = {
                dataKey: xAxisDataKey,
                tickFormatter: xAxisFormatter,
                tick: xAxisTick,
                tickMargin: xAxisTickMargin,
                padding: xAxisTickPadding,
                scale: xAxisScale,
                type: xAxisType,
                height: xAxisHeight,
                minTickGap: xAxisMinTickGap,
                domain: xAxisDomain,
                axisLine: false,
                tickLine: false
            };

            return xAxisComponentRenderer ? xAxisComponentRenderer(Object.assign({}, xAxisProps, { data: data })) : _react2.default.createElement(_recharts.XAxis, xAxisProps);
        };

        _this.renderReferenceAreas = function () {
            var areas = _this.props.areas;


            if (areas && areas.length > 0) {
                return [_react2.default.createElement(_recharts.YAxis, { key: 'area-y-axis', hide: true })].concat(_toConsumableArray(areas.map(function (areaProps) {
                    var x1 = areaProps.x1,
                        x2 = areaProps.x2;

                    var key = x1 + '-' + x2;

                    return _react2.default.createElement(_recharts.ReferenceArea, Object.assign({ key: key }, areaProps));
                })));
            }

            return null;
        };

        _this.state = {
            previewedEdge: null
        };
        return _this;
    }

    /** renders YAxis for all fields in order to render the lines according to their own ration. displays only the first 2. */


    _createClass(TaboolaGraph, [{
        key: 'render',
        value: function render() {
            var previewedEdge = this.state.previewedEdge;
            var _props = this.props,
                edges = _props.edges,
                data = _props.data,
                config = _props.config,
                height = _props.height,
                width = _props.width,
                toolTipLabelFormatter = _props.toolTipLabelFormatter,
                isTooltipAnimationActive = _props.isTooltipAnimationActive,
                GraphTooltip = _props.graphTooltip,
                CartesianGrid = _props.cartesianGrid,
                containerClassNameProp = _props.containerClassName,
                barGap = _props.barGap,
                barCategoryGap = _props.barCategoryGap,
                maxAllowedSelectedEdges = _props.maxAllowedSelectedEdges,
                renderTooltipContent = _props.renderTooltipContent,
                disablePreviewForSelected = _props.disablePreviewForSelected,
                legendFooter = _props.legendFooter,
                legendHeader = _props.legendHeader;
            var _data$legendValues = data.legendValues,
                legendValues = _data$legendValues === undefined ? {} : _data$legendValues,
                _data$values = data.values,
                values = _data$values === undefined ? [] : _data$values;

            var _buildEdges = this.buildEdges(),
                previewedEdgeArray = _buildEdges.previewedEdgeArray,
                activeEdges = _buildEdges.activeEdges,
                baseLineValue = _buildEdges.baseLineValue;

            var containerClassName = classNameBuilder('graph-container', containerClassNameProp);
            var legendEdges = this.prepareEdgesToLegend(edges);
            var noEdgesSelected = Object.keys(legendEdges).reduce(function (noneSelected, key) {
                return noneSelected && !legendEdges[key];
            }, true);
            var chartData = noEdgesSelected ? [] : values;
            var overlay = this.getOverlayComponent(values, noEdgesSelected);

            return _react2.default.createElement(
                'div',
                { className: containerClassName },
                _react2.default.createElement(
                    'div',
                    { className: styles['responsive-graph-container'] },
                    _react2.default.createElement(
                        _recharts.ResponsiveContainer,
                        { height: height, width: width },
                        _react2.default.createElement(
                            _recharts.ComposedChart,
                            { data: chartData, baseValue: baseLineValue, barGap: barGap, barCategoryGap: barCategoryGap },
                            this.renderReferenceAreas(),
                            _react2.default.createElement(CartesianGrid, null),
                            _react2.default.createElement(_recharts.Tooltip, {
                                cursor: this.calculateTooltipCursor(),
                                content: _react2.default.createElement(GraphTooltip, null),
                                labelFormatter: toolTipLabelFormatter,
                                isAnimationActive: isTooltipAnimationActive
                            }),
                            this.renderDataLayers(activeEdges, 1),
                            this.renderDataLayers(previewedEdgeArray, 0.5, true),
                            this.renderYAxis(),
                            this.renderXAxis(chartData)
                        )
                    ),
                    overlay && _react2.default.createElement(
                        'div',
                        { className: styles['overlay-container'] },
                        overlay
                    )
                ),
                _react2.default.createElement(_Legend2.default, {
                    containerClassName: styles['legend-container'],
                    config: config,
                    edges: legendEdges,
                    activeEdges: activeEdges,
                    previewedEdge: previewedEdge,
                    onToggle: this.toggleEdge,
                    onTogglePreview: this.togglePreviewedEdge,
                    values: legendValues,
                    maxAllowedSelectedEdges: maxAllowedSelectedEdges,
                    renderTooltipContent: renderTooltipContent,
                    disablePreviewForSelected: disablePreviewForSelected,
                    footerText: legendFooter,
                    header: legendHeader
                })
            );
        }
    }]);

    return TaboolaGraph;
}(_react.Component);

TaboolaGraph.propTypes = {
    /** Graph data */
    data: _propTypes2.default.shape({
        values: _propTypes2.default.array,
        legendValues: _propTypes2.default.object
    }),
    /** Configuration array for each field in data: describes field's color, label, format function, active (display line) */
    config: _propTypes2.default.arrayOf(_propTypes2.default.shape({
        name: _propTypes2.default.string,
        label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
        color: _propTypes2.default.string,
        active: _propTypes2.default.bool,
        yAxisFormatter: _propTypes2.default.func,
        legendFormatter: _propTypes2.default.func,
        labelFormatter: _propTypes2.default.func
    })),
    /** Edges selection state object */
    edges: _propTypes2.default.objectOf(_propTypes2.default.shape({
        selected: _propTypes2.default.bool,
        selectionOrder: _propTypes2.default.number,
        line: _propTypes2.default.shape({
            value: _propTypes2.default.number,
            label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
            area: _propTypes2.default.oneOf([1, -1]),
            offset: _propTypes2.default.number
        })
    })),
    /** Graph type: Lines, Bars */
    type: _propTypes2.default.oneOf(Object.values(GRAPH_TYPE)),
    /** Graph Lines animation disabler */
    isLineAnimationActive: _propTypes2.default.bool,
    /** Graph container height */
    height: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    /** Graph container width */
    width: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    /** X axis data key */
    xAxisDataKey: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]).isRequired,
    /** X axis values formatter */
    xAxisFormatter: _propTypes2.default.func,
    /** X axis tick height */
    xAxisHeight: _propTypes2.default.number,
    /** Flag to enable tooltip cursor */
    drawTooltipCursor: _propTypes2.default.bool,
    /** Graph tooltip component */
    graphTooltip: _propTypes2.default.func,
    /** Graph tooltip labels formatter */
    toolTipLabelFormatter: _propTypes2.default.func,
    /** Graph tooltip animation disabler */
    isTooltipAnimationActive: _propTypes2.default.bool,
    /** Callback being called when a field is toggled in legend */
    onToggle: _propTypes2.default.func,
    /** Graph cartesian grid in background */
    cartesianGrid: _propTypes2.default.func,
    /** If set false, no ticks will be drawn. If set a object, the option is the configuration of ticks. If set a React element, the option is the custom react element of drawing ticks. */
    yAxisTick: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.object]),
    /** If set false, no ticks will be drawn. If set a object, the option is the configuration of ticks. If set a React element, the option is the custom react element of drawing ticks. */
    xAxisTick: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.object]),
    xAxisTickPadding: _propTypes2.default.object,
    xAxisTickMargin: _propTypes2.default.number,
    xAxisMinTickGap: _propTypes2.default.number,
    hoveredDotStyle: _propTypes2.default.object,
    containerClassName: _propTypes2.default.string,
    lineStrokeWidth: _propTypes2.default.number,
    xAxisScale: _propTypes2.default.string,
    xAxisType: _propTypes2.default.string,
    xAxisDomain: _propTypes2.default.arrayOf(_propTypes2.default.string),
    /** Overlay component to display when no data is received */
    NoDataOverlayComponent: _propTypes2.default.func,
    /** Overlay component to display when no edges selected */
    NoEdgesOverlayComponent: _propTypes2.default.func,
    /** Array of area props as an object */
    areas: _propTypes2.default.arrayOf(_propTypes2.default.object),
    /** The maximum allowed metrics to be selected simultaneously */
    maxAllowedSelectedEdges: _propTypes2.default.number,
    /** tooltip when maximum allowed metrics are met */
    renderTooltipContent: _propTypes2.default.func,
    /** barSize */
    barSize: _propTypes2.default.number,
    /** maxBarSize */
    maxBarSize: _propTypes2.default.number,
    /** maxBarSize */
    barGap: _propTypes2.default.number,
    /** maxBarSize */
    barCategoryGap: _propTypes2.default.number,
    /** Graph Lines animation disabler */
    isBarAnimationActive: _propTypes2.default.bool,
    /** Custom bar shape drawer */
    barShape: _propTypes2.default.func,
    /** Disable preview for selected metrics */
    disablePreviewForSelected: _propTypes2.default.bool,
    /** Footer of legend */
    legendFooter: _propTypes2.default.node,
    /** Axis ID for single y axis case */
    singleYAxisId: _propTypes2.default.string,
    /** Axis color for single y axis case */
    singleYAxisColor: _propTypes2.default.string,
    /** Metric whose formatter should be used in single y axis case */
    singleYAxisMetricForFormatter: _propTypes2.default.string,
    /** Header of legend */
    legendHeader: _propTypes2.default.node,
    /** A function that should return an X Axis component (or multiple, in an array). Overrides default */
    xAxisComponentRenderer: _propTypes2.default.func,
    /** Once set will calculate domain based on selected values */
    isSingleYAxisDomain: _propTypes2.default.bool,
    /** YAxis single domain color */
    singleDomainYAxisColor: _propTypes2.default.string
};

TaboolaGraph.defaultProps = {
    config: [],
    edges: {},
    data: {},
    height: '100%',
    width: '100%',
    type: GRAPH_TYPE.LINE,
    drawTooltipCursor: false,
    graphTooltip: _GraphTooltip2.default,
    onToggle: function onToggle() {},
    cartesianGrid: _CartesianGrid2.default,
    yAxisTick: {
        fontSize: styles.fontSize,
        fill: styles.yAxisColor,
        fontFamily: styles.fontFamily,
        fontWeight: parseInt(styles.yAxisFontWeight, 10)
    },
    xAxisTick: {
        fontSize: styles.fontSize,
        fill: styles.xAxisColor,
        fontFamily: styles.fontFamily,
        fontWeight: parseInt(styles.xAxisFontWeight, 10)
    },
    xAxisTickPadding: { left: 20, right: 20 },
    xAxisTickMargin: 10,
    xAxisFormatter: defaultFormatter,
    toolTipLabelFormatter: defaultFormatter,
    hoveredDotStyle: {
        r: 5,
        strokeWidth: 2
    },
    lineStrokeWidth: 3,
    NoDataOverlayComponent: function NoDataOverlayComponent() {
        return null;
    },
    NoEdgesOverlayComponent: function NoEdgesOverlayComponent() {
        return null;
    },
    isLineAnimationActive: true,
    isBarAnimationActive: true,
    isTooltipAnimationActive: true,
    barSize: 12,
    maxBarSize: 12,
    barGap: 2,
    barCategoryGap: 2,
    barShape: _DefaultBar2.default,
    isSingleYAxisDomain: false,
    singleDomainYAxisColor: styles.singleYAxisColor
};

TaboolaGraph.GRAPH_TYPE = GRAPH_TYPE;
TaboolaGraph.AXIS_DOMAIN = AXIS_DOMAIN;
TaboolaGraph.AXIS_TYPE = AXIS_TYPE;
TaboolaGraph.AXIS_SCALE = AXIS_SCALE;

exports.default = TaboolaGraph;