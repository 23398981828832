import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const FixedValueTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.properties.fix.value.tooltip.title"
                defaultMessage="Here you can add a value to each of your conversions. Adding a value will halp you measure the return on ad spend for this conversion."
            />
        </TooltipSection>
    </>
);
