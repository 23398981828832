import { useCallback } from 'react';
import { AI_VARIATIONS_FIELD_NAMES } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { aiGenerationApi } from 'services/api';

export const useRephraseApiCall = () => {
    const { value: productDescription } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_DESCRIPTION });
    const { value: targetAudience } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_TARGET_AUDIENCE });
    const { value: uniqueSellingProposition } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_USP });

    return useCallback(
        ({ accountId, campaignId, originalText }) =>
            aiGenerationApi.getAiRephrasedTitles({
                accountId,
                campaignId,
                productDescription,
                targetAudience,
                uniqueSellingProposition,
                originalText,
            }),
        [productDescription, targetAudience, uniqueSellingProposition]
    );
};
