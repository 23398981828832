import { useCallback, useContext, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { DASHBOARD_RANGE_PICKER_PRESETS as PRESETS } from 'taboola-ultimate-ui';
import { MODULE_PARAM_NAME } from 'config/routes/module';
import { MODULE_NAME as AUDIENCE_INSIGHT_MODULE_NAME } from 'modules/audience-insights/config/routes';
import { DatePresetContext } from 'modules/campaigns/components/DatePresetContext/DatePresetContext';
import { getQueryParamFromLocation, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import {
    urlDateSerialize,
    urlDateParser,
    isEqualUrlDate,
} from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { MODULE_NAME as BILLING_AND_PAYMENTS_MODULE_NAME } from '../../../../billing-and-payments/config/routes';

const dateOptions = {
    serializer: urlDateSerialize,
    deserializer: urlDateParser,
    comparator: isEqualUrlDate,
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
    },
};

const isValidRange = (range = {}) => {
    return range[DATE_PRESET] || (range[START_DATE] && range[END_DATE]);
};

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const DATE_PRESET = 'datePreset';

const useStartDateParam = queryParamHookFactory(START_DATE, {
    ...dateOptions,
    defaultValue: ({ [MODULE_PARAM_NAME]: module }) => {
        if (module === AUDIENCE_INSIGHT_MODULE_NAME) {
            const datePreset = getQueryParamFromLocation(DATE_PRESET);

            if (!datePreset) {
                return moment().subtract(3, 'days');
            }
        }
    },
});
const useEndDateParam = queryParamHookFactory(END_DATE, {
    ...dateOptions,
    defaultValue: ({ [MODULE_PARAM_NAME]: module }) => {
        if (module === AUDIENCE_INSIGHT_MODULE_NAME) {
            const datePreset = getQueryParamFromLocation(DATE_PRESET);

            if (!datePreset) {
                return moment().subtract(2, 'days');
            }
        }
    },
});
const usePresetDateParam = queryParamHookFactory(DATE_PRESET, {
    persist: PERSISTENCE_TYPE.MEMORY,
    defaultValue: ({ [MODULE_PARAM_NAME]: module }) => {
        if (module === AUDIENCE_INSIGHT_MODULE_NAME || module === BILLING_AND_PAYMENTS_MODULE_NAME) {
            return;
        }

        const startDate = getQueryParamFromLocation(START_DATE);
        const endDate = getQueryParamFromLocation(END_DATE);
        if (!startDate || !endDate) {
            return PRESETS.SEVEN_DAYS;
        }

        return PRESETS.CUSTOM;
    },
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
    },
});

const DEFAULT_ADDITIONAL_PRESET = {};

export const useDateRange = (additionalPresets = DEFAULT_ADDITIONAL_PRESET) => {
    const [startDateQueryParam, setStartDateQueryParam, unsetStartDateQueryParam] = useStartDateParam();
    const [endDateQueryParam, setEndDateQueryParam, unsetEndDateQueryParam] = useEndDateParam();
    const [datePresetQueryParam, setDatePresetQueryParam] = usePresetDateParam();

    const presetConfigs = useContext(DatePresetContext);

    const extendedPresetConfigs = useMemo(
        () => ({ ...additionalPresets, ...presetConfigs }),
        [presetConfigs, additionalPresets]
    );
    const { startDate, endDate } = useMemo(
        () => extendedPresetConfigs?.[datePresetQueryParam] || {},
        [datePresetQueryParam, extendedPresetConfigs]
    );

    const dateRange = useMemo(() => {
        return {
            [START_DATE]: startDate || startDateQueryParam,
            [END_DATE]: endDate || endDateQueryParam,
            [DATE_PRESET]: datePresetQueryParam,
        };
    }, [startDateQueryParam, endDateQueryParam, datePresetQueryParam, startDate, endDate]);

    const setDateRange = useCallback(
        newRange => {
            if (!isValidRange(newRange)) {
                console.error('called with invalid range. ignoring update', newRange);
                return;
            }
            const { [START_DATE]: newStartDate, [END_DATE]: newEndDate, [DATE_PRESET]: newDatePreset } = newRange;

            if (newDatePreset) {
                unsetStartDateQueryParam();
                unsetEndDateQueryParam();
                setDatePresetQueryParam(newDatePreset);

                return;
            }

            // if no date preset, then both dates are provided
            setStartDateQueryParam(newStartDate);
            setEndDateQueryParam(newEndDate);
            setDatePresetQueryParam(PRESETS.CUSTOM);
        },
        [
            setStartDateQueryParam,
            setEndDateQueryParam,
            unsetStartDateQueryParam,
            unsetEndDateQueryParam,
            setDatePresetQueryParam,
        ]
    );

    // TODO DEV-122459 merge realtime date preset and dynamic preset approach
    useEffect(() => {
        if (additionalPresets === DEFAULT_ADDITIONAL_PRESET) {
            return;
        }

        if (!extendedPresetConfigs[datePresetQueryParam] && datePresetQueryParam !== PRESETS.CUSTOM) {
            setDatePresetQueryParam(PRESETS.CUSTOM);
        }
    }, [datePresetQueryParam, setDatePresetQueryParam, extendedPresetConfigs, additionalPresets]);

    return [dateRange, setDateRange, extendedPresetConfigs];
};
