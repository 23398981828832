import { useMemo } from 'react';
import { Button } from 'tuui';
import { AutoDismissedIndication, FormField, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './vendorMacroReplacement.module.scss';

const descriptionMessageId = 'creative.editor.thirdPartyTags.vendorMacroReplacement.warning.description';
const descriptionDefault = `Enhance results by adjusting macros (tracking parameters) to match Taboola's syntax`;
const fixedDescriptionMessageId = 'creative.editor.thirdPartyTags.vendorMacroReplacement.warning.fixed.description';
const fixedDescriptionDefault = `We've successfully replaced the following tracking parameters:{newline}`;

const INDICATION_TIMEOUT = 70000;

export const VendorMacroReplacement = ({ macroReplacements, onClick, isFixed }) => {
    const indicationType = isFixed ? INDICATION_TYPES.SUCCESS : INDICATION_TYPES.WARNING;

    const macroReplacementsMessage = useMemo(
        () =>
            macroReplacements.map(({ vendorMacroInput, replacementOutput }) => {
                return (
                    <div>
                        {vendorMacroInput} {'->'} {replacementOutput}
                    </div>
                );
            }),
        [macroReplacements]
    );

    return (
        !!macroReplacements.length && (
            <AutoDismissedIndication
                className={styles['container']}
                type={indicationType}
                timeoutDuration={INDICATION_TIMEOUT}
                message={
                    isFixed ? (
                        <FormField
                            description={
                                <FormattedMessage
                                    id={fixedDescriptionMessageId}
                                    defaultMessage={fixedDescriptionDefault.concat(macroReplacementsMessage)}
                                    values={{
                                        macroReplacementsMessage,
                                    }}
                                />
                            }
                        />
                    ) : (
                        <div>
                            <FormField
                                description={
                                    <FormattedMessage id={descriptionMessageId} defaultMessage={descriptionDefault} />
                                }
                            />
                            <Button variant={Button.variant.outline} size={Button.size.md} onClick={onClick}>
                                <FormattedMessage
                                    id="creative.editor.thirdPartyTags.vendorMacroReplacement.warning.fix.button"
                                    defaultMessage="Fix It For Me"
                                />
                            </Button>
                        </div>
                    )
                }
            />
        )
    );
};
