import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import { identity, get } from 'lodash';
import PropTypes from 'prop-types';
import { MediaGalleryItem, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from '../../../../../../../services/constants';
import { GTM_EVENTS } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import { GTM_ACTION_TYPES, sourceToTabNameMap } from '../consts';
import InvalidGalleryItemSvg from './InvalidGalleryItemSVGIcon';
import styles from './paginatedGalleryItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const emptyComponent = <div />;

const errorComponent = (
    <div className={styles['errorComponent']}>
        <InvalidGalleryItemSvg />
    </div>
);

const calculateStatus = (item, imgSrc, paginationStatus) => {
    if (paginationStatus === COMPONENT_STATUS.ACTIVE && !item) {
        return COMPONENT_STATUS.EMPTY;
    }
    return imgSrc ? COMPONENT_STATUS.ACTIVE : COMPONENT_STATUS.LOADING;
};

const PaginatedGalleryItem = ({
    itemIndex,
    onClick,
    urlTransformer,
    GalleryItemDropdown,
    itemDropdownMenuStyle,
    onSelectMedia,
    galleryItemTooltip,
    galleryItemTooltipProps,
    gridRef,
}) => {
    const { status: paginationStatus, thumbnailsMap, items } = useMediaTabsContext();
    const [failedToLoad, setFailedToLoad] = useState(false);
    const item = items[itemIndex];
    const isImage = get(item, 'fileType') === 'image';
    const imgSrc = urlTransformer(item?.url);
    const status = calculateStatus(item, imgSrc, paginationStatus);
    const disabled = !item || failedToLoad;
    const selected = !!thumbnailsMap[item?.id];

    // when scrolling through grid quickly, the component might be unmounted before the image is loaded
    // causing the setState to be called on an unmounted component
    const isUnmounted = useRef(false);

    useEffect(() => {
        return () => {
            isUnmounted.current = true;
        };
    }, []);

    useEffect(() => {
        if (!isUnmounted.current) {
            setFailedToLoad(false);
        }
    }, [item]);

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component={sourceToTabNameMap[item?.source]}
            data-metrics-value={imgSrc}
            data-metrics-column-name={GTM_ACTION_TYPES.IMAGE_CLICK}
            className={styles['container']}
        >
            {failedToLoad && (
                <Tooltip arrow={true} position={TOOLTIP_POSITION.TOP} offset={-10}>
                    <FormattedMessage
                        id="creative.creator.thumbnails.gallery.invalid.item"
                        defaultMessage="Image is unavailable."
                    />
                </Tooltip>
            )}
            <MediaGalleryItem
                imgSrc={imgSrc}
                selected={selected}
                clickHandler={() => onClick(item, selected)}
                status={status}
                thumbnail={item}
                showLargePreview={!disabled}
                emptyComponent={emptyComponent}
                errorComponent={errorComponent}
                className={classNameBuilder({ disabled })}
                videoContainerClass={styles['videoContainer']}
                onError={() => {
                    if (!isUnmounted.current) {
                        setFailedToLoad(true);
                    }
                }}
            />
            {!failedToLoad && item && GalleryItemDropdown && (
                <GalleryItemDropdown
                    urlTransformer={urlTransformer}
                    dropdownMenuStyle={itemDropdownMenuStyle}
                    addImageHandler={({ image, options }) => onClick(image, false, options)}
                    itemUrl={imgSrc}
                    originalItem={item}
                    onSelectMedia={onSelectMedia}
                    isImage={isImage}
                    tooltipContent={galleryItemTooltip}
                    tooltipProps={galleryItemTooltipProps}
                    gridRef={gridRef}
                />
            )}
        </div>
    );
};

PaginatedGalleryItem.defaultProps = {
    urlTransformer: identity,
    GalleryItemDropdown: () => null,
};

PaginatedGalleryItem.propTypes = {
    /*function to sanitize the url, if needed*/
    urlTransformer: PropTypes.func,
};

export default PaginatedGalleryItem;
