import React from 'react';
import { noop } from 'lodash';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import DealIdTooltip from './DealIdTooltip';
import styles from '../../../../components/Form/FormFields/formFields.module.scss';

const DealId = () => {
    const { value: dealId } = useFormFieldValue({ field: 'id' });

    return (
        <FormField
            inputId="deal-id"
            containerClass={styles['input']}
            label={<FormattedMessage id="pmpDeal.id.label" defaultMessage="Deal ID" />}
            description={
                <FormattedMessage id="pmpDeal.id.description" defaultMessage="The unique identifier for the deal." />
            }
            helpText={<DealIdTooltip />}
        >
            <Input
                id="deal-id"
                onChange={noop}
                value={dealId}
                type={InputTypes.TEXT}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="DealId"
                disabled
                role="input"
            />
        </FormField>
    );
};

export default DealId;
