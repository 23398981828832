"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCompletionCircle66(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("circle", {
            cx: 12,
            cy: 12,
            r: 7.5,
            fill: "white",
            stroke: "currentColor"
        }),
        React.createElement(
            "mask",
            { id: "path-2-inside-1_503_912", fill: "white" },
            React.createElement("path", { d: "M12 6C13.0426 6 14.0671 6.27166 14.9728 6.78821C15.8784 7.30476 16.6337 8.04837 17.1645 8.94575C17.6952 9.84313 17.9829 10.8633 17.9993 11.9058C18.0156 12.9482 17.7601 13.9769 17.2578 14.8905C16.7556 15.8041 16.0239 16.5711 15.135 17.1158C14.2461 17.6606 13.2305 17.9643 12.1885 17.997C11.1464 18.0298 10.1138 17.7904 9.19242 17.3026C8.27104 16.8147 7.49267 16.0952 6.93403 15.215L12 12V6Z" })
        ),
        React.createElement("path", {
            d: "M12 6C13.0426 6 14.0671 6.27166 14.9728 6.78821C15.8784 7.30476 16.6337 8.04837 17.1645 8.94575C17.6952 9.84313 17.9829 10.8633 17.9993 11.9058C18.0156 12.9482 17.7601 13.9769 17.2578 14.8905C16.7556 15.8041 16.0239 16.5711 15.135 17.1158C14.2461 17.6606 13.2305 17.9643 12.1885 17.997C11.1464 18.0298 10.1138 17.7904 9.19242 17.3026C8.27104 16.8147 7.49267 16.0952 6.93403 15.215L12 12V6Z",
            fill: "currentColor",
            stroke: "currentColor",
            strokeWidth: 2,
            mask: "url(#path-2-inside-1_503_912)"
        })
    );
}
exports.default = SvgCompletionCircle66;