import { COMMON_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useSiteTargeting } from './useSiteTargeting';

export const siteListKey = 'smart-bid-blocked-sites';

export const useCampaignLevelSmartBidBlockedSites = () => {
    const readonly = useConfigMatch({}, { [COMMON_FLAGS.IS_AUTO_BLOCKED_SITES_FORM_EDITABLE]: 'false' });
    const props = useSiteTargeting({
        field: 'campaignTargeting.autoPublisherTargeting.values',
        readonly,
        siteListKey,
    });

    return props;
};
