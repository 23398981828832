import { get, isEmpty } from 'lodash';
import { getIsMarketplaceAudiencesTargetingEmpty } from 'modules/campaigns/services/utils/audiences';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import AllCampaignsObject from '../../../../../config/allCampaignsObject';
import * as FIELDS from '../../fields';

const columnPermissions = {
    [FIELDS.IS_SELECTED.field]: {
        permissions: {
            permissions: ['TABOOLA_CAMPAIGN_BY_SEGMENT_BULK_ACTION'],
        },
        accountConfigurations: {
            [FEATURE_FLAGS.AUDIENCE_BULK_ACTION_ENABLED]: 'true',
        },
        visibilityCondition: ({ selectedCampaign = {} }) => {
            const { id } = selectedCampaign;
            if (id === AllCampaignsObject.id) {
                return false;
            }

            const isMarketplaceAudienceTargetingEmpty = getIsMarketplaceAudiencesTargetingEmpty(selectedCampaign);
            const includeMyAudiences = get(selectedCampaign, 'campaignTargeting.myAudienceTargeting.include', []);
            const includeContextualSegments = get(
                selectedCampaign,
                'campaignTargeting.contextualSegmentsTargeting.include',
                []
            );
            return (
                isMarketplaceAudienceTargetingEmpty && isEmpty(includeMyAudiences) && isEmpty(includeContextualSegments)
            );
        },
    },
};

export default columnPermissions;
