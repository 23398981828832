'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'buttonsGroupButton-module__taboola-svg-icon___2tFHE',
    'button': 'buttonsGroupButton-module__button___3aWck'
};


var classNameBuilder = _bind2.default.bind(styles);

var ButtonsGroupButton = function ButtonsGroupButton(_ref) {
    var onChange = _ref.onChange,
        className = _ref.className,
        getHref = _ref.getHref,
        isDropdown = _ref.isDropdown,
        button = _objectWithoutProperties(_ref, ['onChange', 'className', 'getHref', 'isDropdown']);

    var value = button.value,
        label = button.label,
        labelSuffix = button.labelSuffix,
        options = button.options,
        CustomLinkRenderer = button.customLinkRenderer,
        rest = _objectWithoutProperties(button, ['value', 'label', 'labelSuffix', 'options', 'customLinkRenderer']);

    var onClick = function onClick(e) {
        // see ButtonsGroupDropdownOption for reasoning why preventDefault is done here
        // instead of passing e up through onChange
        if (getHref) {
            e.preventDefault();
        }
        onChange(button);
    };

    if (CustomLinkRenderer) {
        return _react2.default.createElement(CustomLinkRenderer, Object.assign({
            className: classNameBuilder(styles['button'], className),
            value: value,
            label: label,
            onClick: onClick,
            labelSuffix: labelSuffix,
            isDropdown: isDropdown
        }, rest));
    }

    return _react2.default.createElement(
        'a',
        Object.assign({
            role: 'button',
            tabIndex: 0,
            onClick: onClick,
            href: getHref && getHref(value),
            className: classNameBuilder(styles['button'], className)
        }, rest),
        label,
        !isDropdown && labelSuffix
    );
};

ButtonsGroupButton.propTypes = {
    onChange: _propTypes2.default.func,
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
    labelSuffix: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
    className: _propTypes2.default.string,
    getHref: _propTypes2.default.func,
    isDropdown: _propTypes2.default.bool
};

exports.default = ButtonsGroupButton;