'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DefaultBar = function DefaultBar(_ref) {
    var x = _ref.x,
        y = _ref.y,
        w = _ref.width,
        h = _ref.height,
        fill = _ref.fill;

    // border radius
    var br = 2;
    var d = void 0;
    // rectangle with top rounded corners
    if (h) {
        d = 'M' + x + ',' + (y + h) + ' L' + x + ',' + (y + br) + ' L' + (x + br) + ',' + y + ' L' + (x + w - br) + ',' + y + ' L' + (x + w) + ',' + (y + br) + ' L' + (x + w) + ',' + (y + h) + ' Z';
    } else {
        d = 'M' + x + ',' + y + ' L' + (x + br) + ',' + (y - br) + ' L' + (x + w - br) + ',' + (y - br) + ' L' + (x + w) + ',' + y + ' Z';
    }

    return _react2.default.createElement('path', { d: d, fill: fill });
};

DefaultBar.propTypes = {
    x: _propTypes2.default.number,
    y: _propTypes2.default.number,
    width: _propTypes2.default.number,
    height: _propTypes2.default.number,
    fill: _propTypes2.default.string
};

exports.default = DefaultBar;