'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.EMPTY_PLACEHOLDER_VALUE = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _keycodeJs = require('keycode-js');

var _Buttons = require('../Buttons');

var _Indications = require('../Indications');

var _utils = require('./utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'editableText__taboola-svg-icon___2bCgv',
    'container': 'editableText__container___xvpII',
    'focus': 'editableText__focus___1-omM',
    'disabled': 'editableText__disabled___2_BIe',
    'error': 'editableText__error___9_eu6',
    'suggestion': 'editableText__suggestion___2sX5B',
    'warning': 'editableText__warning___3x1y_',
    'value': 'editableText__value___1zFQ3',
    'edit-button': 'editableText__edit-button___3wXSX',
    'hidden': 'editableText__hidden___3D9gG'
};


var classNameBuilder = _bind2.default.bind(styles);
// No-width space props up the editable div when no placeholder is supplied
var EMPTY_PLACEHOLDER_VALUE = '\u200B';
var newLineRegExp = /\n/g;

var EditableText = function (_Component) {
    _inherits(EditableText, _Component);

    function EditableText(props) {
        _classCallCheck(this, EditableText);

        var _this = _possibleConstructorReturn(this, (EditableText.__proto__ || Object.getPrototypeOf(EditableText)).call(this, props));

        _this.onKeyUp = function (e) {
            var onChange = _this.props.onChange;

            var newValue = e.target.textContent;

            _this.contextChanged = true;

            onChange(newValue || '');
        };

        _this.onKeyDown = function (e) {
            var keyCode = e.keyCode;
            var blurOnEnter = _this.props.blurOnEnter;


            if (blurOnEnter && (keyCode === _keycodeJs.KEY_ENTER || keyCode === _keycodeJs.KEY_RETURN)) {
                e.preventDefault();
                _this.onBlur(e);
            }
        };

        _this.onBlur = function (e) {
            var propsOnBlur = _this.props.onBlur;

            var newValue = e.target.textContent || '';

            if (_this.contextChanged) {
                _this.input.current.dispatchEvent(new Event('change', { bubbles: true }));
            }
            _this.contextChanged = false;

            _this.setText(newValue);
            _this.setState({ editing: false });
            propsOnBlur();
        };

        _this.onPaste = function (e) {
            var propsOnPaste = _this.props.onPaste;

            if (propsOnPaste) {
                var shouldSkipDefaultPasteHandler = propsOnPaste(e);
                if (shouldSkipDefaultPasteHandler) {
                    return;
                }
            }
            e.preventDefault();
            var pastedValue = e.clipboardData.getData('text/plain').replace(newLineRegExp, '');

            var _getSelection = (0, _utils.getSelection)(),
                startIndex = _getSelection.startIndex,
                endIndex = _getSelection.endIndex;

            var innerText = _this.getInnerText();
            var textBefore = innerText.slice(0, startIndex);
            var textAfter = innerText.slice(endIndex);
            var newValue = '' + textBefore + pastedValue + textAfter;

            _this.setText(newValue);
            (0, _utils.setCursorPosition)(_this.input.current, startIndex + pastedValue.length);
        };

        _this.setInnerText = function (text) {
            if (_this.input.current) {
                _this.input.current.innerText = text;
            }
        };

        _this.getInnerText = function () {
            return (0, _lodash2.default)(_this.input.current, 'innerText', '');
        };

        _this.setText = function (text) {
            var onChange = _this.props.onChange;


            onChange(text);
            _this.setInnerText(text);
        };

        _this.startEditing = function () {
            var _this$props = _this.props,
                onClick = _this$props.onClick,
                disabled = _this$props.disabled;


            onClick();
            if (disabled) {
                return;
            }
            _this.setState({ editing: true });
            _this.input.current.focus();
        };

        _this.focusInput = function () {
            _this.startEditing();
            (0, _utils.moveCaretToEndOfInput)(_this.input.current);
        };

        _this.state = {
            editing: false
        };
        _this.input = _react2.default.createRef();
        _this.contextChanged = false;
        return _this;
    }

    _createClass(EditableText, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props = this.props,
                value = _props.value,
                autoFocus = _props.autoFocus;


            this.setInnerText(value);

            if (autoFocus) {
                this.focusInput();
            }
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var _props2 = this.props,
                value = _props2.value,
                autoFocus = _props2.autoFocus;

            var safeValue = value || '';
            var safeInput = this.input.current.textContent || '';

            if (safeValue !== safeInput) {
                this.setInnerText(value);

                if (document.activeElement === this.input.current) {
                    (0, _utils.moveCaretToEndOfInput)(this.input.current);
                }
            }

            if (autoFocus && autoFocus !== prevProps.autoFocus) {
                this.focusInput();
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classNameBuilder;

            var _props3 = this.props,
                containerClassName = _props3.className,
                inputClassName = _props3.inputClassName,
                useInternalContainer = _props3.useInternalContainer,
                iconName = _props3.iconName,
                error = _props3.error,
                autoFocus = _props3.autoFocus,
                indicationType = _props3.indicationType,
                disabled = _props3.disabled,
                contentEditable = _props3.contentEditable,
                children = _props3.children,
                blurOnEnter = _props3.blurOnEnter,
                rest = _objectWithoutProperties(_props3, ['className', 'inputClassName', 'useInternalContainer', 'iconName', 'error', 'autoFocus', 'indicationType', 'disabled', 'contentEditable', 'children', 'blurOnEnter']);

            var editing = this.state.editing;

            var wrapperClassName = classNameBuilder(containerClassName, (_classNameBuilder = {
                EditableText: true,
                container: useInternalContainer,
                error: error
            }, _defineProperty(_classNameBuilder, indicationType, indicationType), _defineProperty(_classNameBuilder, 'focus', editing), _defineProperty(_classNameBuilder, 'disabled', disabled), _classNameBuilder));
            var divClassName = classNameBuilder(inputClassName);
            var buttonClassName = classNameBuilder('edit-button', {
                hidden: editing
            });

            return _react2.default.createElement(
                'div',
                {
                    role: 'heading',
                    'aria-level': '1',
                    className: wrapperClassName
                },
                _react2.default.createElement('div', Object.assign({}, rest, {
                    ref: this.input,
                    className: divClassName,
                    role: 'textbox',
                    tabIndex: '0',
                    contentEditable: contentEditable || !disabled,
                    onClick: this.startEditing,
                    onBlur: this.onBlur,
                    suppressContentEditableWarning: true,
                    onInput: this.onKeyUp,
                    onPaste: this.onPaste,
                    onKeyDown: this.onKeyDown,
                    'data-placeholder': this.props.placeholder
                })),
                children,
                iconName && _react2.default.createElement(_Buttons.StyledButton, {
                    className: buttonClassName,
                    iconAfter: iconName,
                    size: _Buttons.STYLED_BUTTON_SIZE.PRIMARY,
                    type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON
                })
            );
        }
    }]);

    return EditableText;
}(_react.Component);

EditableText.propTypes = {
    /** EditableText's class name */
    className: _propTypes2.default.string,
    /** EditableText's input class name */
    inputClassName: _propTypes2.default.string,
    /** use EditableText's internal container */
    useInternalContainer: _propTypes2.default.bool,
    /** EditableText's value */
    value: _propTypes2.default.node,
    /** EditableText's onChange event handler */
    onChange: _propTypes2.default.func,
    /** Optional icon name */
    iconName: _propTypes2.default.string,
    /** Error control */
    error: _propTypes2.default.bool,
    /** Add a placeholder to the text field */
    placeholder: _propTypes2.default.string,
    /** Focus the input when component mounts */
    autoFocus: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES)),
    /** Disabled state of input */
    disabled: _propTypes2.default.bool,
    /** EditableText's onClick event handler */
    onClick: _propTypes2.default.func,
    /** EditableText's onBlur event handler */
    onBlur: _propTypes2.default.func,
    /** EditableText's onPaste event handler */
    onPaste: _propTypes2.default.func,
    /** contenteditable attribute for browser modification of input */
    contentEditable: _propTypes2.default.bool,
    children: _propTypes2.default.node,
    blurOnEnter: _propTypes2.default.bool
};

EditableText.defaultProps = {
    className: 'container',
    inputClassName: 'value',
    useInternalContainer: false,
    onChange: function onChange() {},
    iconName: null,
    value: '',
    placeholder: EMPTY_PLACEHOLDER_VALUE,
    onClick: function onClick() {},
    onBlur: function onBlur() {}
};

exports.EMPTY_PLACEHOLDER_VALUE = EMPTY_PLACEHOLDER_VALUE;
exports.default = EditableText;