import React from 'react';
import { omit } from 'lodash';
import { PLACEMENTS } from '../../../config/placements';
import { SvgButton } from './SvgButton';

export const OneByOne = props => (
    <SvgButton {...props} name={PLACEMENTS.ONE_BY_ONE}>
        <path
            d="M15.2627 8.87598V16H13.8564V10.4971L12.167 11.0342V9.92578L15.1113 8.87598H15.2627ZM17.6895 15.3213C17.6895 15.113 17.7611 14.9388 17.9043 14.7988C18.0508 14.6589 18.2445 14.5889 18.4854 14.5889C18.7262 14.5889 18.9183 14.6589 19.0615 14.7988C19.208 14.9388 19.2812 15.113 19.2812 15.3213C19.2812 15.5296 19.208 15.7038 19.0615 15.8438C18.9183 15.9837 18.7262 16.0537 18.4854 16.0537C18.2445 16.0537 18.0508 15.9837 17.9043 15.8438C17.7611 15.7038 17.6895 15.5296 17.6895 15.3213ZM17.6895 11.293C17.6895 11.0846 17.7611 10.9105 17.9043 10.7705C18.0508 10.6305 18.2445 10.5605 18.4854 10.5605C18.7262 10.5605 18.9183 10.6305 19.0615 10.7705C19.208 10.9105 19.2812 11.0846 19.2812 11.293C19.2812 11.5013 19.208 11.6755 19.0615 11.8154C18.9183 11.9554 18.7262 12.0254 18.4854 12.0254C18.2445 12.0254 18.0508 11.9554 17.9043 11.8154C17.7611 11.6755 17.6895 11.5013 17.6895 11.293ZM23.8271 8.87598V16H22.4209V10.4971L20.7314 11.0342V9.92578L23.6758 8.87598H23.8271Z"
            fill="white"
        />
    </SvgButton>
);

OneByOne.propTypes = omit(SvgButton.propTypes, 'name');
