import React, { useCallback } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { ModalFormLayout } from '../../../taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { ADD_FUNDS_CONTEXT } from '../../config/AddFundsContext';
import { PAYMENT_ACTION } from '../../config/PaymentAction';
import { PAYMENT_PROVIDER_NAME } from '../../config/PaymentProviderName';
import { PAYMENT_TYPE } from '../../config/PaymentType';
import { useAddFunds } from '../../hooks/useAddFunds';
import { useAllowedPrepaymentMethods } from '../../hooks/useAllowedPrepaymentMethods';
import { useBillingActions, useBillingData } from '../../hooks/useBillingData';
import { usePaymentMethods } from '../AddFundsModal/PaymentMethods/usePaymentMethods';
import styles from './additionalFundsModal.module.scss';

export const AdditionalFundsModal = ({ onClose }) => {
    const { billingData } = useBillingData();
    const { cashRefund } = useBillingActions();
    const { financialRepEmail } = useAllowedPrepaymentMethods();
    const { cashBalance } = billingData;
    const { accountName, currency } = useAccount();
    const availableFunds = cashBalance?.amount;

    const { addFunds } = useAddFunds({ funds: availableFunds, paymentType: PAYMENT_TYPE.BANK_TRANSFER });
    const { paymentMethodHandlersMap } = usePaymentMethods({ financialRepEmail });

    const onAddAdditionalFundsClicked = useCallback(async () => {
        const addFundsResponse = await addFunds(ADD_FUNDS_CONTEXT.ADD_FUNDS_VIA_ADDITIONAL_FUNDS);
        onClose(addFundsResponse);
    }, [onClose, addFunds]);

    const onRefundClicked = useCallback(async () => {
        const paymentMethodHandler = paymentMethodHandlersMap.get(PAYMENT_ACTION.CASH_BALANCE_REFUND);

        try {
            await cashRefund({
                accountId: accountName,
                apiRefund: {
                    amount: availableFunds,
                    vendor: PAYMENT_PROVIDER_NAME.STRIPE,
                },
            });
            paymentMethodHandler.onRefundSuccess();
        } catch (e) {
            paymentMethodHandler.onRefundFailure(e);
        } finally {
            onClose();
        }
    }, [onClose, availableFunds, cashRefund, accountName, paymentMethodHandlersMap]);

    return (
        <ModalFormLayout
            onCancel={onRefundClicked}
            onSubmit={onAddAdditionalFundsClicked}
            submitButtonText={
                <FormattedMessage
                    id="billingAndPayments.billingManagement.additionalFunds.addFunds"
                    defaultMessage="Add Funds to My Account"
                />
            }
            cancelButtonText={
                <FormattedMessage
                    id="billingAndPayments.billingManagement.additionalFunds.refund"
                    defaultMessage="Refunds Additional Funds"
                />
            }
            bodyContainerClassName={styles['modal-body-container']}
        >
            <div className={styles['modal-header']}>
                <FormattedMessage
                    id="billingAndPayments.billingManagement.additionalFunds.title"
                    defaultMessage="Additional Funds Detected"
                />
            </div>
            <div className={styles['sub-header']}>
                <FormattedMessage
                    id="billingAndPayments.billingManagement.additionalFunds.info"
                    values={{ availableFunds, currency }}
                    defaultMessage={`We've received an additional transfer of ${availableFunds} ${currency}.`}
                />
            </div>
            <div className={styles['sub-header']}>
                <FormattedMessage
                    id="billingAndPayments.billingManagement.additionalFunds.action"
                    defaultMessage="Would you like to add these funds to your Taboola account balance?"
                />
            </div>
        </ModalFormLayout>
    );
};
