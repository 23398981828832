import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const BidAdjustmentsTooltip = () => (
    <TooltipSection
        tip={
            <FormattedMessage
                id="campaign.editor.duplicateCampaignSettings.bid.tooltip.tip"
                defaultMessage="By applying this checkbox, Taboola will copy all your site's bid adjustments from the original campaign. By unchecking all sites, bids will be equal to the campaign level CPC bidding."
            />
        }
    >
        <FormattedMessage
            id="campaign.editor.duplicateCampaignSettings.bid.tooltip.main"
            defaultMessage="Duplicate the original campaign’s site bid adjustments."
        />
    </TooltipSection>
);

export default BidAdjustmentsTooltip;
