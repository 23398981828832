import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export const MultiLayerDrawerContext = createContext({});

export const useMultiLayerDrawerContext = () => useContext(MultiLayerDrawerContext);

export const MultiLayerDrawerProvider = ({ children }) => {
    const [forms, setForms] = useState({});
    const [modulePages, setModulePages] = useState({});
    const [entityData, setEntityData] = useState({});

    const registerModulePage = useCallback(
        modulePage => {
            setModulePages(modulePages => ({ ...modulePages, [modulePage.path]: { component: modulePage.component } }));
        },
        [setModulePages]
    );

    const registerForm = useCallback(
        form => {
            setForms(forms => ({
                ...forms,
                [form.path]: {
                    ...form,
                },
            }));
        },
        [setForms]
    );

    const value = useMemo(
        () => ({
            forms,
            setForms,
            registerForm,
            modulePages,
            registerModulePage,
            entityData,
            setEntityData,
        }),
        [forms, setForms, registerForm, modulePages, registerModulePage, entityData, setEntityData]
    );

    return <MultiLayerDrawerContext.Provider value={value}>{children}</MultiLayerDrawerContext.Provider>;
};
