import React, { useCallback, useMemo } from 'react';
import { Divider, DropdownMenu } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './localesList.module.scss';

const LocalesList = ({ children, locale, setLocale, availableLocales }) => {
    const localeChangeHandler = useCallback(({ value }) => setLocale(value), [setLocale]);
    const options = useMemo(() => {
        const languageOptions = availableLocales.map(key => ({
            value: key,
            label: (
                <>
                    <FormattedMessage id={`app.language.${key}`} defaultMessage={key} />
                    <FormattedMessage id={`app.language.${key}.en`} defaultMessage={key} />
                </>
            ),
        }));

        return languageOptions;
    }, [availableLocales]);

    return (
        <>
            {children}
            {options.length > 1 && (
                <>
                    <Divider className={styles['divider']} />
                    <DropdownMenu
                        options={options}
                        selectedValueObject={{ value: locale }}
                        onChange={localeChangeHandler}
                        className={styles['lang-menu']}
                    />
                </>
            )}
        </>
    );
};

export default LocalesList;
