import { get } from 'lodash';
import { getPixelStatus } from 'modules/tracking/utils/getPixelStatus';
import { STEP_DATA, SUB_STEP_DATA } from '../../consts';

const { ADD_PAYMENT_DATA, COMPLETE_ACCOUNT_DATA } = STEP_DATA;
const { HAS_ACTIVE_CONVERSION, HAS_INSTALLED_PIXEL, HAS_NON_FROZEN_ACCOUNT } = SUB_STEP_DATA;

const getAddPaymentData = ({ accountData }) => {
    const { isFrozen } = accountData;

    if (isFrozen === false) {
        return { [HAS_NON_FROZEN_ACCOUNT]: true };
    }
    return { [HAS_NON_FROZEN_ACCOUNT]: false };
};

const getCompleteAccountData = ({ accountData }) => {
    const pixelStatus = getPixelStatus(accountData);
    const hasInstalledPixel = pixelStatus === null;
    const hasActiveConversion = get(accountData, 'pixelData.hasAtLeastOneActiveConversion', false);

    return { [HAS_INSTALLED_PIXEL]: hasInstalledPixel, [HAS_ACTIVE_CONVERSION]: hasActiveConversion };
};

export const getAccountCompletionData = ({ accountData }) => {
    const addPaymentData = getAddPaymentData({ accountData });
    const completeAccountData = getCompleteAccountData({ accountData });

    return { [ADD_PAYMENT_DATA]: addPaymentData, [COMPLETE_ACCOUNT_DATA]: completeAccountData };
};
