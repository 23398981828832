import React from 'react';
import classnames from 'classnames/bind';
import { map } from 'lodash';
import { CheckCircleOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './bulkUploadResultMessages.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SuccessResultsMessages = ({ successes }) => (
    <div className={classNameBuilder('section-container', 'successful')}>
        <div className={styles['header']}>
            <FormattedMessage id="excel.upload.success.header" defaultMessage="Success" />
        </div>
        <div className={styles['messages-container']}>
            {map(successes, ({ entity, successCount, totalCount }) => (
                <div key={entity} className={[styles['message-container']]}>
                    <CheckCircleOutlinedIcon fontSize="small" className={styles['check-icon']} />
                    <FormattedMessage
                        id={`excel.upload.${entity}.creator.success.message`}
                        values={{
                            successCount,
                            totalCount,
                        }}
                    />
                </div>
            ))}
        </div>
    </div>
);
