import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { ENTITY } from '../../../config';
import LookBackWindowInput from '../../TtlTypeField/LookBackWindowInput';
import TtlTypeTooltip from '../../TtlTypeField/TtlTypeTooltip';

const TTLPropsMap = {
    [ENTITY.SEARCH_KEYWORDS]: {
        id: 'audience.editor.search-keywords-audience.include.people.from.look-back-window',
        defaultMessage: 'Include People from (1 to 30 Days)',
        minDays: 1,
        maxDays: 30,
    },
    [ENTITY.MAIL_DOMAINS]: {
        id: 'audience.editor.mail-domains-audience.include.people.from.look-back-window',
        defaultMessage: 'Include People from (3 to 90 Days)',
        minDays: 3,
        maxDays: 90,
    },
};

export const ExternalAudiencesTTLField = ({ AudienceType }) => {
    return (
        <FormField
            inputId={`${AudienceType}-ttl`}
            label={
                <FormattedMessage
                    id={TTLPropsMap[AudienceType].id}
                    defaultMessage={TTLPropsMap[AudienceType].defaultMessage}
                />
            }
            helpText={<TtlTypeTooltip />}
            helpIconLabel="ttl-type-tooltip"
        >
            <LookBackWindowInput
                minDays={TTLPropsMap[AudienceType].minDays}
                maxDays={TTLPropsMap[AudienceType].maxDays}
            />
        </FormField>
    );
};
