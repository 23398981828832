import uuid from 'uuid/v1';
import { TARGETING_TYPES } from '../components/CombinedAudiences/CombinedTargetingTypes';
import { AUDIENCE_TYPE } from './audiencesConsts';

export const combinedAudiencesInitialValues = {
    name: '',
    type: AUDIENCE_TYPE.COMBINED_AUDIENCES,
    audienceSetup: [
        {
            relation: TARGETING_TYPES.OR,
            audiences: [],
            id: uuid(),
        },
    ],
    platforms: ['PHON'],
    countries: ({ defaultCountry }) => {
        return defaultCountry ? [defaultCountry] : ['US'];
    },
};

export default combinedAudiencesInitialValues;
