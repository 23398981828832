import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const RuntimePickerTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.runtime.tooltip.overview"
                defaultMessage="You can schedule your campaign to run on specific hours and days of the week."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.runtime.tooltip.example"
                defaultMessage="Select {tip} to set the days and hours that your campaign will run on. You can only set one specific time period per day. To add an additional time period, duplicate this campaign and create another scheduling rule."
                values={{
                    tip: (
                        <strong>
                            <FormattedMessage
                                id="campaign.editor.schedule.runtime.radio.specific"
                                defaultMessage="Set Specific Days or Hours"
                            />
                        </strong>
                    ),
                }}
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.schedule.runtime.tooltip.tip"
                defaultMessage="Selecting {time} will set the campaign to run twenty-four hours a day, seven days a week."
                values={{
                    time: <strong>24/7</strong>,
                }}
            />
        </TooltipSection>
    </div>
);

export default RuntimePickerTooltip;
