import { OVER_AVERAGE_TYPING_THROTTLE_TIME } from 'hooks/useDelayedState';
import {
    googlePlayStoreRegex,
    appleAppStoreRegex,
    validations,
} from 'modules/campaigns/modules/creative-creator/components/AdSetupSection/adSetUpSectionValidation';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { useAppDataApi } from 'services/api/appDataApi';

export const useAppUrl = (isAndroidOsFamily, field) => {
    const { getAppData } = useAppDataApi();

    const {
        value: appUrl,
        onChange: onChangeAppUrl,
        indicationData,
    } = useFormValidatedValue({
        field,
        validations,
        validationDependencies: {
            regExp: isAndroidOsFamily ? googlePlayStoreRegex : appleAppStoreRegex,
            getAppData,
        },
        throttleValue: true,
        throttleTiming: OVER_AVERAGE_TYPING_THROTTLE_TIME,
    });

    return { appUrl, onChangeAppUrl, indicationData };
};
