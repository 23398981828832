import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { InformationIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './statusTrackerPhaseTooltipAlert.module.scss';

const classNameBuilder = classnames.bind(styles);

const StatusTrackerPhaseTooltipAlert = ({ type, phaseMsgIdPrefix, isAdditionalInfo }) => (
    <div className={styles['alert-container']}>
        <div className={styles['alert-icon-container']}>
            <InformationIcon className={classNameBuilder('alert-icon', { info: isAdditionalInfo })} />
        </div>
        <div className={styles['alert-text-container']}>
            <FormattedMessage id={`${phaseMsgIdPrefix}.tooltip.alert.${type}`} defaultMessage="" />
        </div>
    </div>
);

StatusTrackerPhaseTooltipAlert.propTypes = {
    type: PropTypes.string,
    phaseMsgIdPrefix: PropTypes.string,
    isAdditionalInfo: PropTypes.bool,
};

StatusTrackerPhaseTooltipAlert.defaultProps = {
    isAdditionalInfo: false,
};

export default StatusTrackerPhaseTooltipAlert;
