import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import { InformationIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { ENTITY_TYPE } from 'modules/performance-rules/utils/performanceRulesConsts';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BoldEllipsisCellRenderer } from './BoldEllipsisCellRenderer';
import styles from './performanceRulesAppliesToCampaignsCellRenderer.module.scss';

const MAX_LINES_NUMBER = 10;
const classNameBuilder = classnames.bind(styles);

const NoCampaignsTooltipMessage = () => (
    <FormattedMessage
        id="performance.rules.report.applies.to.no.campaigns.tooltip.message"
        defaultMessage="The rule doesn't apply to any campaigns. you can change the rule's setting via the edit button."
    />
);
const AppliesToCampaignsTooltipMessage = ({ campaigns }) => {
    const campaignIds = campaigns.join(', ');
    return (
        <FormattedMessage
            id="performance.rules.report.applies.to.only.id.tooltip.message"
            defaultMessage="Campaign Ids: {campaignIds}"
            values={{ campaignIds }}
        />
    );
};
export const PerformanceRulesAppliesToCampaignsCellRenderer = ({ data }) => {
    const { campaigns, entityType } = data;
    const campaignsCount = size(campaigns);
    const isCampaignEntity = entityType !== ENTITY_TYPE.ADVERTISER;
    const columnCellMessageSuffix = useMemo(() => {
        if (!isCampaignEntity) {
            return 'all';
        }
        if (campaignsCount === 1) {
            return 'one';
        }
        return 'multiple';
    }, [campaignsCount, isCampaignEntity]);

    const memoizedTooltipContent = useMemo(() => {
        return (
            <>
                {campaignsCount > 0 ? (
                    <AppliesToCampaignsTooltipMessage campaigns={campaigns} />
                ) : (
                    <NoCampaignsTooltipMessage />
                )}
            </>
        );
    }, [campaignsCount, campaigns]);

    return (
        <div className={styles['alert-container']}>
            <FormattedMessage
                id={`performance.rules.report.applies.to.message.${columnCellMessageSuffix}`}
                defaultMessage="{campaignsCount} Campaigns"
                values={{ campaignsCount }}
            />
            {isCampaignEntity && (
                <Tooltip position={TOOLTIP_POSITION.LEFT} arrow interactive>
                    <BoldEllipsisCellRenderer value={memoizedTooltipContent} lines={MAX_LINES_NUMBER} />
                </Tooltip>
            )}
            {isCampaignEntity && campaignsCount === 0 && (
                <div className={styles['alert-text']}>
                    <div className={styles['alert-icon-container']}>
                        <InformationIcon className={classNameBuilder('alert-icon')} />
                    </div>
                </div>
            )}
        </div>
    );
};

PerformanceRulesAppliesToCampaignsCellRenderer.propTypes = {
    data: PropTypes.object,
    value: PropTypes.array,
};
