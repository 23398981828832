import React from 'react';
import classnames from 'classnames/bind';
import { Textarea } from 'tuui';
import { CollapsibleList, FormField } from 'taboola-ultimate-ui';
import CSVUploadModule from '../../../../../campaigns/components/CSVUploadModule/CSVUploadModule';
import { ListWithMultilineInputBoxFooter } from '../../../../../campaigns/modules/common-campaign-form/components/ListWithMultilineInput/ListWithMultilineInputBoxFooter/ListWithMultilineInputBoxFooter';
import { showAllLabel, showLessLabel } from '../../../../../campaigns/services/utils/commonLabels';
import { withIndication } from '../../../../../errors';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { validateFiles } from '../../../../config';
import { useYahooKeywordSuggestionsEnabled } from '../../../../hooks/useYahooExternalAudiencesFF';
import { ItemSuggestions } from '../../../ItemSuggestions/ItemSuggestions';
import { RejectedKeywordsIndication } from '../../RejectedKeywords/RejectedKeywordsIndication';
import { exportToFile } from '../../utils';
import KeywordsTooltip from './KeywordsTooltip';
import { SearchKeywordsListItem } from './SearchKeywordsListItem';
import { useSearchKeywordsField } from './useSearchKeywordsField';
import { useValidateSearchKeywordsOnAdd } from './useValidateSearchKeywordsOnAdd';
import styles from './searchKeywordsField.module.scss';

const PLACEHOLDER = 'Enter or paste keywords. You can separate each keyword by a new line, commas or semicolons.';
const classNameBuilder = classnames.bind(styles);

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const SearchKeywordsField = () => {
    const {
        handleInputChange,
        onSubmit,
        text,
        file,
        keywords,
        onDropFileHandler,
        handleClearItems,
        handleDeleteItem,
        audienceName,
        suggestions,
        isLoadingSuggestions,
        handleAddAllSuggestions,
        handleSuggestionClick,
        removeInvalidKeywords,
        indicationData,
        rejectedKeywords,
        setRejectedKeywords,
        rejectedKeywordsTopics,
        setRejectedKeywordsTopics,
    } = useSearchKeywordsField();

    useValidateSearchKeywordsOnAdd({
        rejectedKeywords,
        setRejectedKeywords,
        rejectedKeywordsTopics,
        setRejectedKeywordsTopics,
    });

    return (
        <FormField
            inputId="keywords"
            label={
                <FormattedMessage id="audience.editor.external-audience.keywords.title" defaultMessage="Add Keywords" />
            }
            description={
                <FormattedMessage
                    id="audience.editor.external-audience.keywords.description"
                    defaultMessage="To build a list of relevant keywords, add ideas below. Keywords can trigger your ad to show when people search for related items."
                />
            }
            helpText={<KeywordsTooltip />}
            helpIconLabel="keywords-tooltip"
            containerClass={styles['input']}
        >
            <div className={styles['list-container']}>
                <div className={styles['grid-container']}>
                    <div className={styles['textarea-container']}>
                        <Textarea
                            value={text}
                            placeholder={PLACEHOLDER}
                            onChange={handleInputChange}
                            textareaClassName={styles['textarea']}
                            rows={19}
                        />

                        <div className={styles['horizontal-delimiter']} />

                        <ListWithMultilineInputBoxFooter
                            error={false}
                            selectedIdsCount={text.length}
                            onSubmit={onSubmit}
                            footerButtonMessage={
                                <FormattedMessage
                                    id="audience.editor.external-audience.keywords.add.keywords"
                                    defaultMessage="Add Keywords"
                                />
                            }
                        />
                    </div>
                    <CSVUploadModule
                        guidelinesMessage={
                            <FormattedMessage
                                id="audience.editor.external-audience.upload.csv.file.guidelines"
                                defaultMessage="One column only{newline}Up to 1,000,000 records"
                            />
                        }
                        onRemoveFileHandler={() => {}}
                        file={file}
                        onDropFileHandler={onDropFileHandler}
                        additionalValidateFiles={validateFiles}
                        inZoneButton={true}
                        containerClassName={classNameBuilder('csv-container')}
                        isMandatory={false}
                    />
                </div>
                {rejectedKeywords && rejectedKeywords.length > 0 && (
                    <RejectedKeywordsIndication
                        successMessageType="error"
                        rejectedKeywords={rejectedKeywords}
                        keywordsMap={rejectedKeywordsTopics}
                        handleInvalidKeywords={removeInvalidKeywords}
                        showRemoveInvalidKeywordsButton
                    />
                )}
                <div className={styles['list-container']}>
                    <CollapsibleListWithIndication
                        id="keywords-list"
                        items={keywords}
                        ItemComponent={SearchKeywordsListItem}
                        itemHeight={40}
                        isSearchable
                        exportToFile={() => exportToFile({ items: keywords, prefix: audienceName, suffix: 'keywords' })}
                        deleteItem={handleDeleteItem}
                        clearItems={handleClearItems}
                        listHeaderTitle={
                            <FormattedMessage
                                id="audience.editor.external-audience.keywords.list.included.keywords"
                                defaultMessage="Included Keywords"
                            />
                        }
                        showLessLabel={showLessLabel}
                        showAllLabel={showAllLabel}
                        containerClassName={styles['list']}
                        metadata={{ rejectedKeywords }}
                        {...indicationData}
                    />
                    {useYahooKeywordSuggestionsEnabled() && (
                        <ItemSuggestions
                            suggestions={suggestions}
                            isLoading={isLoadingSuggestions}
                            onAddAllSuggestions={handleAddAllSuggestions}
                            onSuggestionClick={handleSuggestionClick}
                            noSuggestionsMessage={
                                <FormattedMessage
                                    id="audience.editor.external-audience.search-keywords.banner"
                                    defaultMessage="We only show keyword ideas that are relevant to your business. To get ideas, enter some keywords in the field above."
                                />
                            }
                            title={
                                <FormattedMessage
                                    id="audience.editor.external-audience.search-keywords.title"
                                    defaultMessage="Keyword suggestions (by relevance)"
                                />
                            }
                        />
                    )}
                </div>
            </div>
        </FormField>
    );
};
