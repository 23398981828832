import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const budgetValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.budget.empty',
        defaultMessage: 'Enter a Budget',
    },
    {
        validationFn: validationFunctions.isInt,
        options: { min: 1 },
        messageId: 'validations.error.campaign.budget.minValue',
        defaultMessage: 'Budget must be a positive number above 1.',
    },
];

export default budgetValidations;
