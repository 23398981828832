import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useOperationsApi } from 'services/api';

export const WochitContext = createContext({});
export const useWochit = () => useContext(WochitContext);
export const WochitProvider = ({ children }) => {
    const operationsApi = useOperationsApi();
    const [wochitSDK, setWochitSDK] = useState(null);
    const { [COMMON_FLAGS.MOTION_ADS_WOCHIT_CHANNEL_ID]: channelId } = useCommonConfig(
        COMMON_FLAGS.MOTION_ADS_WOCHIT_CHANNEL_ID
    );
    useEffect(() => {
        import('./sdk')
            .then(module => {
                return module.SDK(channelId);
            })
            .then(SDK => {
                setWochitSDK(SDK);
            });
    }, [channelId]);
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    let { data: { accessToken: wochitAccessToken } = {}, isError } = useQuery(
        [accountId, 'wochitAuthentication'],
        () => operationsApi.getWochitAuthentication(accountId),
        {
            staleTime: Infinity,
            enabled: !!accountId,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            retry: 1,
        }
    );

    const value = useMemo(() => ({ wochitSDK, wochitAccessToken }), [wochitSDK, wochitAccessToken]);

    if (isError) {
        wochitAccessToken = null;
    }

    return <WochitContext.Provider value={value}>{children}</WochitContext.Provider>;
};
