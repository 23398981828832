import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ACCOUNT_ID } from 'hooks/queryParams';
import { useMetaKeySkipHandler } from 'hooks/useMetaKeySkipHandler';
import { DATE_PRESET, END_DATE, START_DATE } from 'modules/campaigns/modules/campaigns-reports/hooks/useDateRange';
import { mergeQueryParams, pickQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import {
    getLinkAuxHandler,
    getOnClickHandler,
} from '../../modules/taboola-common-frontend-modules/iframe/utils/linkHandlers';
import { useInternalLinkMenu } from './useInternalLinkMenu';
import styles from './InternalLink.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PARAMS_TO_KEEP = [START_DATE, END_DATE, DATE_PRESET, ACCOUNT_ID];
export const InternalLink = ({ path, params, paramsToKeep = PARAMS_TO_KEEP, className, disabled, ...rest }) => {
    const locationWithRequiredParams = pickQueryParams(paramsToKeep);
    const search = mergeQueryParams(locationWithRequiredParams, params);
    const location = useLocation();
    const to = (path ?? location.pathname) + search;
    const onClick = useMetaKeySkipHandler(rest?.onClick);

    const openContextMenu = useInternalLinkMenu(to);

    return (
        <Link
            to={to}
            {...rest}
            onClick={getOnClickHandler(onClick)}
            onAuxClick={getLinkAuxHandler(rest?.onAuxClick)}
            className={classNameBuilder(className, { disabled })}
            onContextMenu={openContextMenu}
        />
    );
};

InternalLink.propTypes = {
    children: PropTypes.node,
    path: PropTypes.string,
    params: PropTypes.object,
    paramsToKeep: PropTypes.array,
    disabled: PropTypes.bool,
};
