import { abtEventEmitter } from './ABTEventEmitter';

export const createABTProxy = eventEmitter => {
    return new Proxy(
        {},
        {
            set(target, experimentId, values) {
                eventEmitter.createTest(experimentId, values);
                return true;
            },
        }
    );
};

export const ABTProxy = createABTProxy(abtEventEmitter);

window.ABT_VARIANT_VALUES = ABTProxy;
