import React from 'react';
import { PERMISSIONS_OPERATORS, usePermissions } from '../../../../taboola-common-frontend-modules/authentication';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import { useAddFundsModal } from '../../BalanceAndPaymentsPanel/useAddFundsModal';
import { BalanceBase } from '../BalanceBase/BalanceBase';
import { LastAddedFund } from '../LastAddedFund/LastAddedFund';
import styles from './creditLimitBalance.module.scss';

const addFundsPermissions = {
    permissions: ['BUDGET_MANAGEMENT_PAYMENT_METHODS_EDIT', 'ADD_FUNDS_EDIT'],
    operator: PERMISSIONS_OPERATORS.AND,
};

export const CreditLimitBalance = ({
    className,
    currency,
    creditAmount,
    remainingAmount,
    dateLastFundAdded,
    lastAddedAmount,
    progressBarTextMessageId,
    hasPendingPrepayment,
    creditLimitModel,
}) => {
    const { formatMessage } = useIntl();
    const { handleOnClickAddFunds } = useAddFundsModal();
    const isAddFundsPermitted = usePermissions(addFundsPermissions);

    return (
        <BalanceBase
            className={className}
            fundsAmount={remainingAmount}
            currency={currency}
            showAddFundsButton
            creditAmount={creditAmount}
            onAddFundsClick={isAddFundsPermitted ? handleOnClickAddFunds : null}
            progressBarTextMessageId={progressBarTextMessageId}
            title={formatMessage({
                id: 'billingAndPayments.balancePanel.overall.title',
                defaultMessage: 'Current Balance',
            })}
            addFundsDisabled={hasPendingPrepayment}
            addFundsTooltipMessage={formatMessage({
                id: `billingAndPayments.balancePanel.addFunds.${creditLimitModel}.tooltip`,
            })}
        >
            <div className={styles['first-line']}>
                {formatMessage({
                    id: 'billingAndPayments.balancePanel.limitBalance.bottom.title',
                    defaultMessage: 'Last time funds were added:',
                })}
            </div>
            <div className={styles['second-line']}>
                <LastAddedFund
                    lastAddedAmount={lastAddedAmount}
                    dateLastFundAdded={dateLastFundAdded}
                    currency={currency}
                />
            </div>
        </BalanceBase>
    );
};
