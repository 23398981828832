import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useDrawerStack } from '../../../hooks/queryParams/useDrawerStack';
import { hasUnsavedChangesSelector } from '../../../selectors';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import usePostNavigationCallback from './usePostNavigationCallback';

export const UNSAVED_CHANGES_MODAL_MESSAGES = {
    title: <FormattedMessage id="app.modal.unsavedChanges.title" tagName="h2" defaultMessage="Leave Page?" />,
    formProps: {
        submitButtonText: (
            <FormattedMessage id="app.modal.unsavedChanges.button.positive" defaultMessage="Yes, leave" />
        ),
        cancelButtonText: <FormattedMessage id="app.modal.unsavedChanges.button.negative" defaultMessage="Stay here" />,
    },
    content: (
        <FormattedMessage
            id="app.modal.unsavedChanges.content"
            defaultMessage="You have unsaved changes that will be lost if you leave this page."
        />
    ),
};

const useUnsavedChangesController = (formPath = '', hasUnsavedChangesProp = false, isLastLayer = false) => {
    const history = useHistory();
    const { open: openModal } = useModal();
    const hasUnsavedChangesRedux = useSelector(hasUnsavedChangesSelector);
    const { removeCallback: removeNavigationCallback } = usePostNavigationCallback();
    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    const { stack } = useDrawerStack();

    const hasUnsavedChanges = isMultiLayerDrawerEnabled ? hasUnsavedChangesProp : hasUnsavedChangesRedux;
    const skipBlockNavigationCondition = useCallback(
        (pathname, search) => {
            if (isMultiLayerDrawerEnabled) {
                try {
                    const params = new URLSearchParams(search);
                    const newStack = JSON.parse(params.get('drawerStack')) || [];

                    if (newStack.length > stack.length) {
                        return isEqual(stack, newStack.slice(0, newStack.length - 1));
                    }

                    return false;
                } catch (e) {
                    return false;
                }
            }

            return matchPath(pathname, { path: formPath });
        },
        [formPath, isMultiLayerDrawerEnabled, stack]
    );

    const openConfirmDialog = useCallback(
        async (destinationTo, state, unblock) => {
            const result = await openModal(UNSAVED_CHANGES_MODAL_MESSAGES);

            if (!result) {
                removeNavigationCallback();
                return;
            }

            unblock();
            history.push(destinationTo, state);
        },
        [history, removeNavigationCallback, openModal]
    );

    const blockNavigation = useCallback(() => {
        const customPrompt = destination => {
            const { pathname, search, state } = destination;
            const destinationTo = { pathname, search };

            // This block is needed when we update url inside the same form like is creative
            if (skipBlockNavigationCondition(pathname, search)) {
                return true;
            }

            openConfirmDialog(destinationTo, state, unblock);

            return false;
        };

        const unblock = history.block(customPrompt);

        return unblock;
    }, [history, openConfirmDialog, skipBlockNavigationCondition]);

    // In this effect we activate unsaved changes tracker to prevent user leaving the page with the confirm modal
    useLayoutEffect(() => {
        if (
            !hasUnsavedChanges ||
            (isMultiLayerDrawerEnabled && !isLastLayer) ||
            (isEmpty(formPath) && !isMultiLayerDrawerEnabled)
        ) {
            return;
        }

        const unblockNavigation = blockNavigation();

        return unblockNavigation;
    }, [hasUnsavedChanges, blockNavigation, isLastLayer, isMultiLayerDrawerEnabled, formPath]);
};
export default useUnsavedChangesController;
