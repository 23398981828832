import React, { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { useCampaignGroupExtensionEnabled } from 'modules/campaigns/modules/campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { BUDGET_TYPES } from '../../config/budgetTypes';
import { Budget } from './Budget';
import { getEmptyBudgetObject } from './const/emptyBudgetObject';
import { useBudgetFormFieldValue } from './hooks/useBudgetFormFieldValue';
import { useNoneSpendingLimitEnabled } from './hooks/useNoneSpendingLimitEnabled';

export const CampaignBudget = ({ budgetRestrictions }) => {
    const { isCurrentlyDirty, mode } = useFormDataContext();
    const { onChange: onBudgetChange, isDirty: isBudgetDirty } = useBudgetFormFieldValue();
    const { value: isPartOfSharedBudgetCampaignsGroup } = useFormFieldValue({
        field: 'isPartOfSharedBudgetCampaignsGroup',
    });
    const { value: spent } = useFormFieldValue({ field: 'totalSpent' });
    const isCampaignGroupChanged = isCurrentlyDirty('campaignGroupId');
    const campaignGroupBudget = budgetRestrictions?.campaignGroupBudget;
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const notPermitted = !usePermissions('CAMPAIGN_EDIT');
    const disabled =
        notPermitted ||
        (mode === FORM_MODES.EDIT && isPartOfSharedBudgetCampaignsGroup && !campaignGroupExtensionEnabled);
    const isNoneSpendingLimitEnabled = useNoneSpendingLimitEnabled();

    useEffect(() => {
        if (!isCampaignGroupChanged) {
            return;
        }
        if (campaignGroupBudget?.type === BUDGET_TYPES.UNLIMITED) {
            if (isBudgetDirty) {
                onBudgetChange(prev =>
                    getEmptyBudgetObject({ prev, type: BUDGET_TYPES.DAILY, isNoneSpendingLimitEnabled })
                );
            }
            return;
        }

        const newBudget = cloneDeep(campaignGroupBudget);
        onBudgetChange(newBudget);
    }, [isCampaignGroupChanged, campaignGroupBudget, onBudgetChange, isNoneSpendingLimitEnabled, isBudgetDirty]);

    return (
        <Budget
            spent={spent}
            entityTypeMessagePrefix="campaign"
            disabled={disabled}
            helpTextId={isPartOfSharedBudgetCampaignsGroup ? 'campaign.editor.budget.help.disabledInGroup' : ''}
        />
    );
};

CampaignBudget.propTypes = {
    currency: PropTypes.string,
};
