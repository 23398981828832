import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { useCurrentValueGetter } from 'hooks';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../../config';
import BlockedPublishersTooltip from './BlockedPublishersTooltip';
import styles from './blockedPublishersSetting.module.scss';

const BlockedPublishersSetting = ({ checked }) => {
    const {
        value: blockedPublishers,
        onChange: onChangeBlockedPublishers,
        isEqualToInitial: isBlockedPublishersInitial,
    } = useFormFieldValue({
        field: 'campaignTargeting.publisherTargeting.exclude',
    });
    const originalCampaignBlockedPublishers = useRef(blockedPublishers);
    const blockedPublishersGetter = useCurrentValueGetter(blockedPublishers);
    const isBlockedPublishersInitialGetter = useCurrentValueGetter(isBlockedPublishersInitial);
    useEffect(() => {
        if (checked) {
            onChangeBlockedPublishers(originalCampaignBlockedPublishers.current);
            return;
        }
        onChangeBlockedPublishers(isBlockedPublishersInitialGetter() ? [] : blockedPublishersGetter());
    }, [onChangeBlockedPublishers, checked, blockedPublishersGetter, isBlockedPublishersInitialGetter]);

    return (
        <TextCheckbox
            helpText={<BlockedPublishersTooltip />}
            value={DUPLICATE_CAMPAIGN_SETTINGS_TYPE.SITE_BLOCKS}
            title={
                <FormattedMessage
                    id="campaign.editor.duplicateCampaignSettings.siteBlocks"
                    defaultMessage="Site Blocks"
                />
            }
            labelClassName={styles['label']}
        />
    );
};

BlockedPublishersSetting.propTypes = {
    checked: PropTypes.bool,
};

export default BlockedPublishersSetting;
