import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { CREATIVE_TYPE } from '../../config';
import { isEditCreativeAllowed } from '../campaigns-reports/config/predicates';
import baseEditCreative from '../campaigns-reports/flows/baseEditCreative';
import { UnknownCreativeType } from '../creative-creator/components/UnknownCreativeType/UnknownCreativeType';
import { useCreativeCampaignData } from '../creative-creator/hooks/useCreativeCampaignData';
import { useCreativeEditorState } from '../creative-editor/hooks/useCreativeEditorState';
import { CreativePreviewBase } from './components';
import CreativePreviewMotion from './components/CreativePreviewMotion/CreativePreviewMotion';

const BASE_PERMISSIONS = ['ADS_CONSOLE_EDIT_PREVIEW', 'CAMPAIGN_EDIT'];

const CREATIVE_TYPE_MAP = {
    [CREATIVE_TYPE.MOTION]: { Component: CreativePreviewMotion, editPermissionsProps: 'ADS_CONSOLE_EDIT_PREVIEW' },
    [CREATIVE_TYPE.IMAGE]: {
        Component: CreativePreviewBase,
        editPermissionsProps: BASE_PERMISSIONS,
    },
    [CREATIVE_TYPE.CREATIVE_TEMPLATE]: {
        Component: CreativePreviewBase,
        editPermissionsProps: BASE_PERMISSIONS,
    },
};

const CreativePreview = ({ onCancel, history, match }) => {
    const dispatch = useDispatch();
    const {
        params: { accountId, campaignId, creativeId },
    } = match;
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    useCreativeEditorState({ creativeAccountId: accountId, campaignId, creativeId, creativeType });
    const { data: creative } = useFormDataContext();

    const { fetchedCampaign: campaign } = useCreativeCampaignData();
    const { brandingText, campaignProfile } = campaign || {};
    const language = get(campaignProfile, 'language', 'EN');
    const isEditable = creative =>
        isEditCreativeAllowed(creative) && !(creativeType === CREATIVE_TYPE.CREATIVE_TEMPLATE);
    const handleEdit = useCallback(
        () => dispatch(baseEditCreative(accountId, campaignId, creativeId, history, creativeType)),
        [accountId, campaignId, creativeId, history, creativeType, dispatch]
    );
    const commonProps = { language, brandingText, onCancel, onEdit: isEditable(creative) ? handleEdit : null };
    const { Component, editPermissionsProps } = CREATIVE_TYPE_MAP[creativeType] || {
        Component: UnknownCreativeType,
    };

    return (
        <FormInitBase>
            <Component {...commonProps} editPermissionsProps={editPermissionsProps} />
        </FormInitBase>
    );
};

CreativePreview.propTypes = { onCancel: PropTypes.func };

const CreativePreviewWithFormDataProvider = withFormDataProvider(CreativePreview);
export { CreativePreviewWithFormDataProvider as CreativePreview };
