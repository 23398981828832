import React, { useEffect } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownMenu } from '../../../../../components';
import { GTM_EVENTS } from '../../../../taboola-common-frontend-modules/gtmTracker';
import { TopicsLanguageFieldTooltip } from './TopicsLanguageFieldTooltip';
import { useCustomContextualLanguagesApi } from './useCustomContextualLanguagesApi';
import styles from './topicsLanguageField.module.scss';

const getSelectedValueObject = (valuesArray, allValueObjects, defaultValueObject) => {
    if (valuesArray?.length) {
        const valueObjects = allValueObjects ? allValueObjects : [defaultValueObject];
        const firstValue = valuesArray[0];
        return valueObjects.filter(o => o.value === firstValue)[0];
    }
    return {};
};

export const TopicsLanguageField = () => {
    const {
        value: selectedLanguages,
        onChange: setSelectedLanguages,
        scrollRef,
        mode,
    } = useFormFieldValue({
        field: 'languages',
    });

    const { formatMessage } = useIntl();

    const { allLanguagesValueObjects, defaultLanguageValueObject, isLanguagesLoading } =
        useCustomContextualLanguagesApi();

    useEffect(() => {
        if (mode === FORM_MODES.CREATE && defaultLanguageValueObject) {
            setSelectedLanguages([defaultLanguageValueObject.value]);
        }
    }, [defaultLanguageValueObject, mode, setSelectedLanguages]);

    const selectedLanguageValueObject = getSelectedValueObject(
        selectedLanguages,
        allLanguagesValueObjects,
        defaultLanguageValueObject
    );

    const options = allLanguagesValueObjects?.filter(o => !selectedLanguages?.includes(o.value));

    return (
        <FormField
            dataTestId="topics-language-field"
            inputId="language"
            ref={scrollRef}
            label={formatMessage({
                id: 'custom.contextual.setup.languages.label',
                defaultMessage: 'Topics Language Filter',
            })}
            description={formatMessage({
                id: 'custom.contextual.setup.languages.description',
                defaultMessage: 'Select the language you search topics for',
            })}
            containerClass={styles['languages-dropdown']}
            helpText={<TopicsLanguageFieldTooltip />}
        >
            <DropdownMenu
                aria-label="languages-dropdown"
                selectedValueObject={selectedLanguageValueObject}
                onChange={selectedValueObject => setSelectedLanguages([selectedValueObject.value])}
                id="language-dropdown"
                placeholder={formatMessage({
                    id: 'custom.contextual.setup.languages.placeholder',
                    defaultMessage: 'Select a language...',
                })}
                options={options}
                searchable={true}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'TopicsLanguageField',
                }}
                isLoading={isLanguagesLoading}
            />
        </FormField>
    );
};
