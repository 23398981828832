import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const hourOfDayReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.HOUR_OF_DAY],
    endpoint: 'campaign-report-by-hour-of-day-performance',
    graph,
    rowIdField: FIELDS.HOUR_OF_DAY.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    sortMap: {
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    filters: [
        { id: FILTER_TYPE.PLATFORM },
        { id: FILTER_TYPE.COUNTRY },
        { id: FILTER_TYPE.SITE },
        {
            id: FILTER_TYPE.TARGET_PAGE_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.IAB_CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.LANGUAGE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default hourOfDayReportConfig;
