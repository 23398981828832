import classnames from 'classnames/bind';
import { Badge, ErrorOutlinedIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useSelectedAccount } from 'hooks';
import { isNetworkAccountType } from 'modules/account-management';
import { usePixelWarningPermitted } from 'modules/campaigns/modules/common-campaign-form/components/MarketingObjective/usePixelWarningPermitted';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { pixelOrS2sInstalled } from '../../hooks/pixelOrS2sInstalled';
import styles from './trackingNavigationItemContent.module.scss';

const classNameBuilder = classnames.bind(styles);

const alertTypes = ({ isPixelOrS2sInstalled, hasAtLeastOneActiveConversion }) => {
    if (!isPixelOrS2sInstalled) {
        return 'alert';
    } else if (!hasAtLeastOneActiveConversion) {
        return 'warning';
    }
};

const alertTypesText = ({ isPixelOrS2sInstalled, hasAtLeastOneActiveConversion }) => {
    if (!isPixelOrS2sInstalled) {
        if (!hasAtLeastOneActiveConversion) {
            return {
                title: 'Pixel not installed and there are no conversions',
                text: 'For best performance and optimized results, please implement the pixel.',
            };
        }
        return {
            title: 'Pixel not installed but has conversions',
            text: 'For best performance and optimized results, please implement the pixel and enable at least one conversion rule',
        };
    }
    if (!hasAtLeastOneActiveConversion) {
        return {
            title: 'No active conversions',
            text: 'Your account has a live pixel, but no active conversions. Please define and enable your conversion(s)',
        };
    }
};

const AlertWrapper = ({ type }) => (
    <div className={styles['alert-wrapper']}>
        <ErrorOutlinedIcon className={classNameBuilder(styles['alert-icon'], styles[type])} />
    </div>
);

export const TrackingNavigationItemContent = ({ caption, icon: IconComponent, className }) => {
    const isDcoAccount = useConfigMatch({ [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'true' });
    const [{ type }] = useSelectedAccount();
    const isPixelWarningPermitted = usePixelWarningPermitted();
    const isPixelWarningsTesting = useConfigMatch({ [FEATURE_FLAGS.TA_PIXEL_WARNINGS_TESTING]: 'true' });

    const {
        trackingStateData: { pixelTrackingData, s2sTrackingData, hasAtLeastOneActiveConversion },
    } = useTrackingState(isPixelWarningPermitted);
    const { status: pixelStatus } = pixelTrackingData || {};

    const isPixelOrS2sInstalled = pixelOrS2sInstalled(pixelTrackingData, s2sTrackingData);

    const showBadgeAndTooltip =
        isPixelWarningPermitted &&
        isPixelWarningsTesting &&
        !isNetworkAccountType(type) &&
        !isDcoAccount &&
        (!isPixelOrS2sInstalled || !hasAtLeastOneActiveConversion);

    return (
        <Badge
            badgeContent={<AlertWrapper type={alertTypes({ isPixelOrS2sInstalled, hasAtLeastOneActiveConversion })} />}
            hideBadge={!showBadgeAndTooltip}
            type={Badge.TYPE.ERROR}
            contentClassName={classNameBuilder('prioritized', 'badge-content')}
        >
            <IconComponent className={classNameBuilder(styles['icon'], className)} />
            <FormattedMessage id={caption.id} defaultMessage={caption.default} />
            {showBadgeAndTooltip && (
                <Tooltip
                    position={TOOLTIP_POSITION.RIGHT}
                    arrow
                    interactive
                    className={styles['tooltip']}
                    maskClassName={styles['tooltip-mask']}
                    tailClassName={styles['tooltip-mask']}
                >
                    <div className={styles['container']}>
                        <ErrorOutlinedIcon
                            className={classNameBuilder(
                                styles['alert-icon'],
                                styles[alertTypes({ isPixelOrS2sInstalled, hasAtLeastOneActiveConversion })]
                            )}
                        />
                        <div className={styles['tooltip-text-container']}>
                            <span className={styles['tooltip-text-title']}>
                                <FormattedMessage
                                    id={`tooltip.tracking.status.${pixelStatus}.title`}
                                    defaultMessage={
                                        alertTypesText({
                                            isPixelOrS2sInstalled,
                                            hasAtLeastOneActiveConversion,
                                        }).title
                                    }
                                />
                            </span>
                            <FormattedMessage
                                id={`tooltip.tracking.status.${pixelStatus}`}
                                defaultMessage={
                                    alertTypesText({
                                        isPixelOrS2sInstalled,
                                        hasAtLeastOneActiveConversion,
                                    }).text
                                }
                            />
                        </div>
                    </div>
                </Tooltip>
            )}
        </Badge>
    );
};
