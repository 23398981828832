import {
    REQUEST_FETCH_MEDIA_LIBRARY_ITEMS,
    FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS,
    FETCH_MEDIA_LIBRARY_ITEMS_ERROR,
    RESET_MEDIA_LIBRARY_ITEMS,
} from './actionTypes';

export const requestFetchMediaLibraryItems = () => ({
    type: REQUEST_FETCH_MEDIA_LIBRARY_ITEMS,
});

export const fetchMediaLibraryItemsSuccess = mediaLibraryItems => ({
    type: FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS,
    payload: mediaLibraryItems,
});

export const fetchMediaLibraryItemsError = error => ({
    type: FETCH_MEDIA_LIBRARY_ITEMS_ERROR,
    payload: error,
});

export const resetMediaLibraryItems = () => ({
    type: RESET_MEDIA_LIBRARY_ITEMS,
});
