import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { PLATFORMS } from '../../../modules/common-campaign-form/config/platforms';

export const alertKeys = [PLATFORMS.DESKTOP, PLATFORMS.MOBILE];

export const checkIsCompatiblePlatforms = arr => {
    const isInArr = val => arr.includes(val);
    return !alertKeys.every(isInArr);
};

export const messageToUse = hasHighImpactPackagePermission => {
    return hasHighImpactPackagePermission
        ? 'campaign.targeting.platform.mobile.desktop.select.alert.hip.user'
        : 'campaign.targeting.platform.mobile.desktop.select.alert';
};

export const basePlatformValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.campaign.targeting.platform.empty',
        defaultMessage: 'Select at least 1 platform',
    },
];

export const campaignPlatformValidations = hasHighImpactPackagePermission => [
    ...basePlatformValidations,
    {
        validationFn: checkIsCompatiblePlatforms,
        messageId: messageToUse(hasHighImpactPackagePermission),
        defaultMessage:
            'Desktop and Mobile usually require different bid amounts, we recommend to run a separate campaign for each platform.',
        severity: INDICATION_TYPES.WARNING,
    },
];
