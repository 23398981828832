import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { AllCampaignsObject, PUBLISHER_BLOCKING_LEVEL } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isVideoCampaign } from '../../../../../../services/campaignDimension';
import { selectedCampaignSelector } from '../../../../selectors';
import { isSiteBlockingDisabled, isVideoAccountReport } from '../../config/predicates';
import { GridContext } from '../GridContextProvider/GridContextProvider';
import ToggleSwitchCellRenderer from './ToggleSwitchCellRenderer';

const reverseBool = val => !val;

const TooltipMessage = ({
    data: { publisherBlockingLevel, isPublisherBlocked, isBlockPublisherAllowed, dimension } = {},
}) => {
    return !isBlockPublisherAllowed && isVideoCampaign(dimension) ? (
        <FormattedMessage
            id="app.campaigns.site.video.isBlockPublisherAllowed"
            defaultMessage="Utilize toggle to block or target site domains. Targeting a domain will also include all subdomains beneath within it. ( i.e. Domain: Espn.com Subdomain: fantasy.espn.com). There is no functionality to block a subdomain, as a subdomain does not exist in the site list of the campaign. If you do not want to target a subdomain we recommend blocking the whole domain."
        />
    ) : publisherBlockingLevel && PUBLISHER_BLOCKING_LEVEL[publisherBlockingLevel] && !isVideoCampaign(dimension) ? (
        <FormattedMessage id={`app.campaigns.site.status.${publisherBlockingLevel}`} defaultMessage="Blocked" />
    ) : (
        <FormattedMessage
            id={`app.campaigns.site.state.${Boolean(isPublisherBlocked)}`}
            defaultMessage="Site is Blocked"
        />
    );
};

TooltipMessage.propTypes = {
    data: PropTypes.shape({
        publisherBlockingLevel: PropTypes.string,
    }),
};

const PublisherBlockingCellRenderer = ({ data = {}, ...rest }) => {
    const { isGridEditDisabled } = useContext(GridContext);
    const selectedCampaign = useSelector(selectedCampaignSelector);
    const isCampaignSelected = selectedCampaign && selectedCampaign.id !== AllCampaignsObject.id;
    return (
        <TooltipWrapper
            tooltipContent={<TooltipMessage data={data} />}
            tooltipOptions={{
                interactive: false,
                arrow: true,
                placement: TOOLTIP_POSITION.RIGHT,
            }}
        >
            {isCampaignSelected || !isVideoAccountReport(data) ? (
                <ToggleSwitchCellRenderer
                    {...rest}
                    disabled={isSiteBlockingDisabled(data) || isGridEditDisabled}
                    data={data}
                    valueToToggled={reverseBool}
                    toggledToValue={reverseBool}
                />
            ) : null}
        </TooltipWrapper>
    );
};

PublisherBlockingCellRenderer.propTypes = {
    ...ToggleSwitchCellRenderer.propTypes,
};

export default PublisherBlockingCellRenderer;
