import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './ConversionItemTooltip.module.scss';

const resolveTooltipPosition = (showArrow, disabled, mode) => {
    if (!showArrow) {
        return TOOLTIP_POSITION.TOP_START;
    }

    if (disabled) {
        return mode === FORM_MODES.CREATE ? TOOLTIP_POSITION.RIGHT : TOOLTIP_POSITION.TOP_END;
    }

    return TOOLTIP_POSITION.RIGHT;
};

const tooltipOffset = (mode, arrow, disabled) => {
    return arrow ?? (mode === FORM_MODES.CREATE || (mode === FORM_MODES.EDIT && disabled));
};

export const ConversionItemTooltip = ({ status, avgCvr, disabled, arrow, conversions, numDays }) => {
    const { mode } = useFormDataContext();
    const showArrow = arrow ?? (mode === FORM_MODES.CREATE || (mode === FORM_MODES.EDIT && disabled));
    const tooltipPosition = resolveTooltipPosition(showArrow, disabled, mode);

    return (
        <Tooltip position={tooltipPosition} arrow={showArrow} {...tooltipOffset(mode, arrow, disabled)} delay={0}>
            <div className={styles['label']}>
                <FormattedMessage
                    id="conversion.rule.picker.drawer.status.tooltip"
                    defaultMessage="Status: {status}"
                    values={{
                        status: <FormattedMessage id={`conversion.rule.status.${status}`} defaultMessage={status} />,
                    }}
                />
            </div>
            <div className={styles['label']}>
                <FormattedMessage
                    id="conversion.rule.picker.drawer.avgCvr.tooltip"
                    defaultMessage="Account Avg CVR (30 days): {avgCvr}"
                    values={{
                        avgCvr,
                    }}
                />
            </div>
            <div className={styles['label']}>
                <FormattedMessage
                    id="conversion.rule.picker.drawer.conversions.tooltip"
                    defaultMessage="Conversions ({numDays}} days): {conversions}"
                    values={{ conversions, numDays }}
                />
            </div>
        </Tooltip>
    );
};
