import encodeurl from 'encodeurl';
import { saveCreative } from 'modules/campaigns/modules/creative-editor/flows';

export const editCreativeHandler =
    (accountId, data, dispatch) =>
    async ({ matchingCreativeIds }) => {
        const savedCreative = { ...data, url: encodeurl(data.url) };
        const result = await dispatch(saveCreative({ accountId }, savedCreative, matchingCreativeIds));
        if (!result?.error) {
            return result;
        }
    };
