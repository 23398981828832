import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

export const PlatformFiltersTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.size.estimation.platform.filters.tooltip.title"
                defaultMessage="Select your target platform."
            />
        </TooltipSection>
    </>
);
