import { matchPath } from 'react-router';
import { get, startCase } from 'lodash';
import { PATH_TO_FORM_MODE_MAP } from 'config/routes';
import { getLocationSearch, getQueryParam } from 'modules/taboola-common-frontend-modules/query-params/queryParamUtils';
import { getModuleName } from 'services/url/urlParams';
import reportsBaseConfig from '../../campaigns/config/reportsBaseConfig';
import { EDITOR_ROUTE_PATH, MODULE_NAME } from '../../campaigns/config/routes';

const REPORT_ID_QUERY_NAME = 'reportId';
const DATE_PRESET_QUERY_NAME = 'datePreset';

const getLocationData = () => {
    const { search = '', pathname = '' } = window.location;

    return {
        pathname,
        search,
    };
};

const getReportIdFromQuery = search => {
    const reportId = getQueryParam(search ?? getLocationSearch(), REPORT_ID_QUERY_NAME);
    return reportId ?? '';
};

const getReportPageName = (module, reportId) => {
    const reportLabel = get(reportsBaseConfig[reportId], 'label');

    return reportLabel ? `${module}: ${reportLabel} Report` : '';
};

const getFormPageNameByParams = pathname => {
    const match = matchPath(pathname, { path: EDITOR_ROUTE_PATH });
    if (!match) {
        return;
    }

    const {
        params: { module, entity: entityPath, mode: modePath },
    } = match;
    const mode = PATH_TO_FORM_MODE_MAP[modePath];

    return `${startCase(module)}: ${startCase(mode.toLowerCase())} ${startCase(entityPath.toLowerCase())}`;
};

const DEFAULT_MODULE_NAME = startCase(MODULE_NAME);

const getPageName = location => {
    const { search = '', pathname = '' } = location || getLocationData();

    const formPageName = getFormPageNameByParams(pathname);
    if (formPageName) {
        return formPageName;
    }

    const module = startCase(getModuleName(pathname));
    const reportId = getReportIdFromQuery(search);
    const reportPageName = getReportPageName(module, reportId);

    return reportPageName || module || pathname;
};

const getPageUrl = () => {
    const { pathname, search } = getLocationData();

    return `${pathname}${search}`;
};

const isInterimPageName = pageName => pageName === '/' || pageName === DEFAULT_MODULE_NAME;

const getDatePresetFromQuery = search => {
    const datePreset = getQueryParam(search ?? getLocationSearch(), DATE_PRESET_QUERY_NAME);
    return datePreset ?? '';
};

export { getLocationData, getReportIdFromQuery, getPageName, getPageUrl, isInterimPageName, getDatePresetFromQuery };
