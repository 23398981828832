import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import LearnMore from '../LearnMore';
import TooltipSection from '../TooltipSection';

const RoasGoalTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.editor.roas.goal.help.overview"
            defaultMessage="When a user takes a desired action on your landing page, it is called a conversion."
        />
        <FormattedMessage
            id="campaign.editor.roas.goal.help.example"
            defaultMessage="This conversion could be a product purchase, a pageview, an email signup or any action that is valuable to your business."
        />
        <FormattedMessage
            id="campaign.editor.roas.goal.help.definition"
            defaultMessage="Your Return on Ad Spend or ROAS, is how much revenue you generate from each advertising dollar you spend."
        />
        <LearnMore href="https://help.taboola.com/hc/en-us/articles/360009026873-Measuring-ROAS-Return-on-Ad-Spend-" />
    </TooltipSection>
);

export default RoasGoalTooltip;
