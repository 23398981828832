import { createSelector } from 'reselect';

const recommendationsStateSelector = state => state.recommendationsReducer;

export const recommendationsTypeSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationType
);

export const recommendationsSourceSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationSource
);

export const recommendationsIdSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationId
);

export const recommendationsDataSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationData
);

export const recommendationsMetadataSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationMetadata
);

export const recommendationsIndicationSelector = createSelector(
    recommendationsStateSelector,
    recommendationsState => recommendationsState.recommendationIndicationCount
);
