import { gtmTracker } from '../../taboola-common-frontend-modules/gtmTracker';
import { setFatalError } from '../actions';

const handledErrors = new WeakSet();

const errorMiddleware = store => {
    const handleError = error => {
        if (error?.error?.fatal) {
            store.dispatch(setFatalError(error));
        }

        if (handledErrors.has(error)) {
            return;
        }

        gtmTracker.trackError(error);
        handledErrors.add(error);
        console.error(error);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    const callback = next => action => {
        if (action.error) {
            const { error } = action;
            handleError(error);
            return store.dispatch(setFatalError(error));
        }

        try {
            let nextAction = next(action);
            if (nextAction instanceof Promise) {
                nextAction = nextAction.catch(e => {
                    handleError(e);
                    throw e;
                });
            }

            return nextAction;
        } catch (e) {
            handleError(e);
            throw e;
        }
    };
    return callback;
};

export default errorMiddleware;
