'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _indicationTypes = require('./indicationTypes');

var _indicationTypes2 = _interopRequireDefault(_indicationTypes);

var _StyledButton = require('../../Buttons/StyledButton/StyledButton');

var _StyledButton2 = _interopRequireDefault(_StyledButton);

var _StyledButtonType = require('../../Buttons/StyledButton/StyledButtonType');

var _StyledButtonType2 = _interopRequireDefault(_StyledButtonType);

var _StyledButtonSize = require('../../Buttons/StyledButton/StyledButtonSize');

var _StyledButtonSize2 = _interopRequireDefault(_StyledButtonSize);

var _IndicationContent = require('../IndicationContent/IndicationContent');

var _IndicationContent2 = _interopRequireDefault(_IndicationContent);

var _IndicationContentWithDescription = require('../IndicationContentWithDescription/IndicationContentWithDescription');

var _IndicationContentWithDescription2 = _interopRequireDefault(_IndicationContentWithDescription);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'indicationBase__taboola-svg-icon___2bXxS',
    'indication': 'indicationBase__indication___2Vycy',
    'dismiss': 'indicationBase__dismiss___16w7s',
    'content-container': 'indicationBase__content-container___2c9Oe'
};


var classNameBuilder = _bind2.default.bind(styles);

var IndicationBase = function (_Component) {
    _inherits(IndicationBase, _Component);

    function IndicationBase() {
        _classCallCheck(this, IndicationBase);

        return _possibleConstructorReturn(this, (IndicationBase.__proto__ || Object.getPrototypeOf(IndicationBase)).apply(this, arguments));
    }

    _createClass(IndicationBase, [{
        key: 'renderDefaultDismissComponent',
        value: function renderDefaultDismissComponent() {
            var onDismiss = this.props.onDismiss;

            return _react2.default.createElement(_StyledButton2.default, { className: styles['dismiss'] + ' ' + styles['default'], iconBefore: 'close', onClick: onDismiss, size: _StyledButtonSize2.default.SMALL, type: _StyledButtonType2.default.BORDERLESS_ICON });
        }
    }, {
        key: 'renderDismissComponent',
        value: function renderDismissComponent() {
            var _props = this.props,
                displayDismissComponent = _props.displayDismissComponent,
                dismissComponent = _props.dismissComponent;

            return displayDismissComponent && (dismissComponent || this.renderDefaultDismissComponent());
        }
    }, {
        key: 'renderIcon',
        value: function renderIcon() {
            var _props2 = this.props,
                displayIcon = _props2.displayIcon,
                type = _props2.type,
                iconTypeOverride = _props2.iconTypeOverride;

            var icon = iconTypeOverride || type;
            var IconGraphic = _indicationTypes.ICONS[icon];
            return displayIcon && IconGraphic && _react2.default.createElement(IconGraphic, { className: styles['icon'] });
        }
    }, {
        key: 'render',
        value: function render() {
            var _props3 = this.props,
                children = _props3.children,
                type = _props3.type,
                className = _props3.className,
                contentClassName = _props3.contentClassName,
                containerClassName = _props3.containerClassName,
                displayIcon = _props3.displayIcon,
                iconTypeOverride = _props3.iconTypeOverride,
                description = _props3.description,
                descriptionClassName = _props3.descriptionClassName;

            var IndicationContentComponent = description ? _IndicationContentWithDescription2.default : _IndicationContent2.default;
            return _react2.default.createElement(
                'div',
                { className: classNameBuilder('indication', className), role: type },
                _react2.default.createElement(
                    IndicationContentComponent,
                    {
                        containerClassName: classNameBuilder('content-container', containerClassName),
                        contentClassName: contentClassName,
                        displayIcon: displayIcon,
                        type: type,
                        iconTypeOverride: iconTypeOverride,
                        description: description,
                        descriptionClassName: descriptionClassName
                    },
                    children
                ),
                this.renderDismissComponent()
            );
        }
    }]);

    return IndicationBase;
}(_react.Component);

IndicationBase.propTypes = Object.assign({}, _IndicationContentWithDescription2.default.propTypes, {
    /** The type of the Indication. can be: notification | success | alert | error | custom  */
    type: _propTypes2.default.oneOf(Object.values(_indicationTypes2.default)),
    /** The array of handlers which will be triggered when the indication is dismissed */
    onDismiss: _propTypes2.default.func,
    /** Determines if the manual dismiss component is visible or not */
    displayDismissComponent: _propTypes2.default.bool,
    /** The external dismiss component for the Indication to use */
    dismissComponent: _propTypes2.default.element,
    /** External class */
    className: _propTypes2.default.string,
    children: _propTypes2.default.node
});

IndicationBase.defaultProps = {
    type: _indicationTypes2.default.CUSTOM,
    displayDismissComponent: true,
    onDismiss: function onDismiss() {}
};

exports.default = IndicationBase;