import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { StepperButton, withStylesheet } from 'taboola-ultimate-ui';
import styles from 'modules/campaigns/modules/common-campaign-form/components/DayTimeParting/DayTimeInfo/dayTimeInfo.module.scss';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

const boostStepperValue = 10;

const classNameBuilder = classnames.bind(styles);
const Border = () => <div className={styles['border']} />;
const StyledStepperButton = withStylesheet(StepperButton, styles, {
    [StepperButton.classNames.main]: 'stepper-container',
    [StepperButton.classNames.downButton]: 'stepper-button',
    [StepperButton.classNames.upButton]: ['stepper-button', 'stepper-up'],
});

export const DayTimeBoostColumn = ({ failedValidationData }) => {
    const { [COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MAX_DEFAULT]: maxBoost } = useCommonConfig([
        COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MAX_DEFAULT,
    ]);
    const { [COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MIN_DEFAULT]: minBoost } = useCommonConfig([
        COMMON_FLAGS.BID_MODIFICATION_DAY_TIME_BOOST_MIN_DEFAULT,
    ]);
    const maxBoostValue = maxBoost * 100 - 100;
    const minBoostValue = minBoost * 100 - 100;
    const { value: boost, onChange: setBoost } = useFormFieldValue({ field: 'cpcModification' });
    const onMinusClick = useCallback(() => {
        setBoost(boost => boost - boostStepperValue);
    }, [setBoost]);

    const onPlusClick = useCallback(() => {
        setBoost(boost => boost + boostStepperValue);
    }, [setBoost]);

    return (
        <>
            <span className={styles['boost-text']}>{`${boost}%`}</span>
            <StyledStepperButton
                onStepUp={onPlusClick}
                onStepDown={onMinusClick}
                upDisabled={boost >= maxBoostValue}
                downDisabled={boost <= minBoostValue}
                className={classNameBuilder('stepperButton', { invalid: !isEmpty(failedValidationData) })}
            />
            <Border />
        </>
    );
};
