"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDesktop(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 33,
            viewBox: "0 0 32 33",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", { d: "M14 24.5H18V25.5H14V24.5Z", fill: "#212832" }),
        React.createElement("path", { d: "M11 26.5H21V27.5H11V26.5Z", fill: "#212832" }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M25 9.5H7C5.89543 9.5 5 10.3954 5 11.5V20.5C5 21.6046 5.89543 22.5 7 22.5H25C26.1046 22.5 27 21.6046 27 20.5V11.5C27 10.3954 26.1046 9.5 25 9.5ZM7 8.5C5.34315 8.5 4 9.84315 4 11.5V20.5C4 22.1569 5.34315 23.5 7 23.5H25C26.6569 23.5 28 22.1569 28 20.5V11.5C28 9.84315 26.6569 8.5 25 8.5H7Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgDesktop;