import React from 'react';
import { partition } from 'lodash';
import { isPerformanceVideoCreative } from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import { bulkCreateCreativesBase } from 'modules/campaigns/modules/creative-creator/flows';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { creativesApi } from 'services/api';

const indications = {
    error: {
        message: (
            <FormattedMessage
                id="creative.editor.duplicate.bulk.save.error"
                defaultMessage="We were not able to duplicate ads"
            />
        ),
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
    },
    success: {
        message: (
            <FormattedMessage
                id="creative.editor.duplicate.bulk.save.success"
                defaultMessage="Ads successfully duplicated"
            />
        ),
        type: INDICATION_TYPES.SUCCESS,
        highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    },
};

const sanitizeCreatives = creatives => {
    // todo unify into one "creative" const DEV-157770
    const [videoCreativeRows, imageCreativeRows] = partition(creatives, creative =>
        isPerformanceVideoCreative(creative)
    );

    const sanitizedVideoCreatives = videoCreativeRows.map(creative => {
        const { fallbackUrl: thumbnailUrl, videoUrl } = creative.performanceVideoData;
        const { title, url, description, cta, thirdPartyTags, logo } = creative;

        return { title, url, description, cta, thumbnailUrl, videoUrl, thirdPartyTags, logo };
    });

    const sanitizedImageCreatives = imageCreativeRows.map(creative => {
        const { title, url, description, cta, thumbnailUrl, thirdPartyTags, logo } = creative;

        return { title, url, description, cta, thumbnailUrl, thirdPartyTags, logo };
    });

    return [sanitizedVideoCreatives, sanitizedImageCreatives];
};

const fetchCreatives = async selectedCreativeRows => {
    const creativePromises = Object.values(selectedCreativeRows).map(({ accountName, campaignId, id }) =>
        creativesApi.getCreative(accountName, campaignId, id)
    );
    return await Promise.all(creativePromises);
};

const getCreatives = async selectedCreativeRows => {
    const creatives = await fetchCreatives(selectedCreativeRows);
    return sanitizeCreatives(creatives);
};

export const bulkDuplicateCreatives =
    ({ accountId, selectedCampaigns, selectedCreativeRows }) =>
    async dispatch => {
        return await getCreatives(selectedCreativeRows)
            .then(([videoCreatives, imageCreatives]) =>
                dispatch(
                    bulkCreateCreativesBase(
                        {
                            accountId,
                            selectedCampaigns,
                            creatives: imageCreatives,
                            videoCreatives,
                        },
                        {
                            indicationsOverride: indications,
                            isDuplicated: true,
                        }
                    )
                )
            )
            .catch(error => {
                dispatch(addIndication(indications.error));
                throw error;
            });
    };
