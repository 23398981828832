import { batch } from 'react-redux';
import { selectReport, selectCampaign } from '../actions';
import { resetSelectedRows } from '../modules/campaigns-reports/actions';

const selectCampaignAndReport = (selectedCampaign, reportId) => dispatch => {
    batch(() => {
        dispatch(selectCampaign(selectedCampaign));
        dispatch(selectReport(reportId));
        dispatch(resetSelectedRows());
    });
};

export default selectCampaignAndReport;
