import React, { useMemo } from 'react';
import { isEmpty, keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { CampaignsSmartList as CommonCampaignsSmartList } from 'modules/campaigns/modules/common-campaign-form/components';
import CampaignsSmartListItem from 'modules/campaigns/modules/common-campaign-form/components/CampaignsSmartList/CampaignsSmartListItem';
import campaignToDropdownObjectTransformer from 'modules/campaigns/services/utils/dropdownUtils';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './creativeCreatorCampaignsSmartList.module.scss';

const dropdownValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.duplicate.campaign.empty',
        defaultMessage: 'Select at least one campaign',
    },
];

export const Description = ({ status }) =>
    status ? (
        <FormattedMessage
            className={styles['description']}
            id={`app.campaigns.campaign.status.${status}`}
            defaultMessage={status}
        />
    ) : null;

const CreativeCreatorCampaignSmartList = ({
    selectedCampaigns,
    initialCampaigns,
    accountForCampaign,
    onRemoveCampaign,
    onSelectCampaign,
    placeholder,
    disabled = false,
    validations = dropdownValidations,
    shouldUseValidations = true,
}) => {
    const initialCampaignsMap = useMemo(() => keyBy(initialCampaigns, 'id'), [initialCampaigns]);

    const mappedValues = useMemo(
        () =>
            selectedCampaigns.map(selectedCampaign => {
                const campaign = initialCampaignsMap[selectedCampaign.id] || selectedCampaign;

                return {
                    ...campaignToDropdownObjectTransformer(campaign),
                    description: <Description status={campaign?.status} />,
                };
            }),
        [initialCampaignsMap, selectedCampaigns]
    );
    const isItemDeletable = !(selectedCampaigns.length === 1 && disabled);

    return (
        <CommonCampaignsSmartList
            validations={shouldUseValidations ? validations : []}
            selectedCampaigns={mappedValues}
            selectedAccount={accountForCampaign}
            onRemove={onRemoveCampaign}
            onAdd={onSelectCampaign}
            isDisabled={isEmpty(accountForCampaign) || disabled}
            validationId="creative-creator-campaign-smart-list"
            metricsAttributes={{
                'data-metrics-component': 'CampaignsSmartList (Creative Creator)',
            }}
            placeholder={placeholder}
            listItemComponent={props => <CampaignsSmartListItem {...props} isItemDeletable={isItemDeletable} />}
        />
    );
};

CreativeCreatorCampaignSmartList.propTypes = {
    selectedCampaigns: PropTypes.array,
    accountForCampaign: PropTypes.object,
    onRemoveCampaign: PropTypes.func,
    onSelectCampaign: PropTypes.func,
};

CreativeCreatorCampaignSmartList.defaultProps = {
    selectedCampaigns: [],
};

export default CreativeCreatorCampaignSmartList;
