import { UPDATE_AUDIENCE_ERROR, UPDATE_AUDIENCE_SUCCESS, REQUEST_UPDATE_AUDIENCE } from './actionTypes';

export const requestUpdateAudience = () => ({ type: REQUEST_UPDATE_AUDIENCE });

export const updateAudienceSuccess = audience => ({
    type: UPDATE_AUDIENCE_SUCCESS,
    payload: audience,
});

export const updateAudienceError = error => ({ type: UPDATE_AUDIENCE_ERROR, payload: error });
