import React from 'react';
import { InternalLink } from 'components/InternalLink/InternalLink';
import { BILLING_AND_PAYMENTS_ROUTE } from 'modules/billing-and-payments/config/routes/module';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

const getFrozenAccountIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.FROZEN_ACCOUNT,
    type: INDICATION_TYPES.ERROR,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: <FormattedMessage id="app.campaign.indication.frozen.highlight" />,
    message: (
        <FormattedMessage
            defaultMessage="- Please visit {billing} to update your payment method or add funds. To learn more read this article in our {helpCenter}. If you run into any problems, please contact {support}"
            id="app.campaign.indication.frozen.message"
            values={{
                billing: <InternalLink path={BILLING_AND_PAYMENTS_ROUTE}>Billing & Payments</InternalLink>,
                helpCenter: (
                    <FormattedMessage
                        id="app.campaign.indication.frozen.help-center"
                        defaultMessage="<a>Help Center</a>"
                    />
                ),
                support: <a href="mailto:support@taboola.com">support@taboola.com.</a>,
            }}
        />
    ),
});

export default getFrozenAccountIndication;
