import { useCallback } from 'react';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { useDBStorage } from 'modules/taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { useCurrentValueGetter } from '../../../../../hooks';

const getPresetDisplayNameDBKey = (reportId, presetName) =>
    `${REPORT_SETTINGS_PREFIX}.${reportId}.presets.${presetName}.presetDisplayName`;

export const useCustomPresetDisplayName = (reportId, presetName) => {
    const key = getPresetDisplayNameDBKey(reportId, presetName);
    const { getter, setter } = useDBStorage();
    const presetDisplayName = getter(key);
    const reportIdGetter = useCurrentValueGetter(reportId);
    const presetNameGetter = useCurrentValueGetter(presetName);

    const setPresetDisplayName = useCallback(
        (value, passedReportId, passedPresetName) => {
            const resolvedReportId = passedReportId || reportIdGetter();
            const resolvedPresetName = passedPresetName || presetNameGetter();

            const key = getPresetDisplayNameDBKey(resolvedReportId, resolvedPresetName);
            setter(key, value);
        },
        [setter, reportIdGetter, presetNameGetter]
    );

    return [presetDisplayName, setPresetDisplayName];
};
