import React, { createContext, useContext, useMemo } from 'react';
import { isUndefined } from 'lodash';
import { getFullFieldName, isArrayElementField } from './utils/formContextUtils';
import { throwCriticalError } from './utils/throwCriticalError';

const FormNamespaceContext = createContext({ field: '', getDataPathPrefix: () => '', getHashPrefix: () => '' });

export const useFormNamespaceContext = () => useContext(FormNamespaceContext);

export const FormNamespaceProvider = ({ field, itemKey, children }) => {
    const { getDataPathPrefix, getHashPrefix } = useFormNamespaceContext();

    const value = useMemo(() => {
        const fullName = getFullFieldName(field, getDataPathPrefix);
        if (isArrayElementField(fullName) && isUndefined(itemKey)) {
            throwCriticalError(`FormNamespaceProvider: itemKey is required for array element field ${fullName}`);
        }

        return {
            field,
            getDataPathPrefix: () => getFullFieldName(field, getDataPathPrefix),
            getHashPrefix: () => getFullFieldName(itemKey || field, getHashPrefix),
        };
    }, [field, itemKey, getDataPathPrefix, getHashPrefix]);

    return <FormNamespaceContext.Provider value={value}>{children}</FormNamespaceContext.Provider>;
};
