import React, { useEffect } from 'react';
import { useEventValueHandler } from 'hooks';
import { withIndication } from 'modules/errors';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { ContentDescriptionTextarea } from './ContentDescriptionTextarea';
import { videoCreativeDescriptionValidations } from './validations';

const MAX_CHARECTERS = 180;

const ContentDescriptionTextareaWithIndication = withIndication(ContentDescriptionTextarea);

export const ValidatedVideoContentDescriptionTextarea = () => {
    const {
        value: description,
        onChange: setDescription,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'description',
        validations: videoCreativeDescriptionValidations,
    });

    const onChangeHandler = useEventValueHandler(setDescription);

    const { formatMessage } = useIntl();

    useEffect(() => {
        return () => setDescription('');
    }, [setDescription]);

    return (
        <ContentDescriptionTextareaWithIndication
            value={description}
            onChange={onChangeHandler}
            placeholder={formatMessage({
                id: 'video.creative.creator.content.description.placeholder',
                defaultMessage: 'Type to add description...',
            })}
            rows={3}
            max={MAX_CHARECTERS}
            ref={scrollRef}
            {...indicationData}
        />
    );
};
