import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { GalleryItem, AspectRatioKeeper, FallbackImage, DragAndDropFileContext } from 'taboola-ultimate-ui';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { GTM_EVENTS, gtmTracker } from '../../../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { modifyExternalUrl } from '../../../../../../services/utils';
import ThumbnailLoading from '../../../../components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import FallbackImageError from './FallbackImageError';
import styles from './fallbackImageEditor.module.scss';

const trackFallbackImageClick = (campaigns = []) => {
    const selectedCampaignIds = campaigns.map(campaign => campaign.id);
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: 'Replace_Fallback_Image_Preview_Gallery',
        pageName: getPageName(),
        taboolaCampaignId: selectedCampaignIds.toString(),
    });
};

const FallbackImageEditor = ({ error, status, imgSrc, onEditFallbackImageClick, isDisabled }) => {
    const { openFileDialog } = useContext(DragAndDropFileContext);
    const { value: campaigns } = useCampaignsFormFieldValue();

    const handleEditFallbackImageClick = () => {
        if (isDisabled) {
            return;
        }
        trackFallbackImageClick(campaigns);
        onEditFallbackImageClick();
        openFileDialog();
    };
    return (
        <GalleryItem>
            <AspectRatioKeeper>
                <FallbackImage
                    loadingComponent={
                        <div className={styles['fallback-image']}>
                            <ThumbnailLoading />
                        </div>
                    }
                    errorComponent={<FallbackImageError error={error} />}
                    status={status}
                    imgSrc={modifyExternalUrl(imgSrc)}
                    onEditFallbackImageClick={handleEditFallbackImageClick}
                    header={
                        <FormattedMessage
                            id="creative.creator.media.fallbackImage.header"
                            defaultMessage="Fallback Image"
                        />
                    }
                    editText={
                        <FormattedMessage
                            id="creative.creator.media.fallbackImage.link.replaceImage"
                            defaultMessage="Replace Image"
                        />
                    }
                    selectText={
                        <FormattedMessage
                            id="creative.creator.media.fallbackImage.link.chooseImage"
                            defaultMessage="Choose Image"
                        />
                    }
                    selectBtnMetricsAttributes={{
                        'data-metrics-event-name': GTM_EVENTS.USABILITY,
                        'data-metrics-component': 'Choose Default Image',
                    }}
                    tooltipText={
                        <FormattedMessage
                            id="creative.creator.media.fallbackImage.tooltip"
                            defaultMessage="A fallback image will serve when Motion Ads isn't supported so that you do not lose scale. This image, automatically generated and represents the mid-frame, can be replaced with your own by clicking the 'replace image'"
                        />
                    }
                />
            </AspectRatioKeeper>
        </GalleryItem>
    );
};

FallbackImageEditor.propTypes = {
    error: PropTypes.object,
    status: PropTypes.string,
    imgSrc: PropTypes.string,
    onEditFallbackImageClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default FallbackImageEditor;
