import isNil from 'lodash/isNil';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';

export const DEFAULT_REPORT_PAGE = 1;
export const REPORT_PAGE = 'page';

export const useReportPage = queryParamHookFactory(REPORT_PAGE, {
    deserializer: val => {
        if (isNil(val)) {
            return val;
        }

        const parsedVal = parseInt(val);
        if (Number.isNaN(parsedVal) || parsedVal < DEFAULT_REPORT_PAGE) {
            return;
        }

        return parsedVal;
    },
    defaultValue: DEFAULT_REPORT_PAGE,
    persist: PERSISTENCE_TYPE.MEMORY,
});
