"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgContent(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M8 18h8a1 1 0 001-1v-6.172L12.172 6H8a1 1 0 00-1 1v10a1 1 0 001 1zm11-8v7a3 3 0 01-3 3H8a3 3 0 01-3-3V7a3 3 0 013-3h5l6 6z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M9 10a1 1 0 011-1h3a1 1 0 110 2h-3a1 1 0 01-1-1zm0 4a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z",
            fill: "#212832"
        })
    );
}
exports.default = SvgContent;