import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { isRssChild, isMotionAd } from '../../config/predicates';
import ActionsCellRenderer from './ActionsCellRenderer';

const ACTIONS = {
    EDIT: 'edit',
    DUPLICATE: 'duplicate',
    PREVIEW: 'preview',
    DELETE: 'delete',
};

const getTooltipMessage = (data, action, formatMessage) => {
    const videoMessageId = isMotionAd(data) ? 'video' : null;
    const rssMessageId = isRssChild(data) ? 'rss' : null;
    const domain = videoMessageId || rssMessageId || 'general';
    const messageId = `app.campaigns.creativeReports.actions.${action}.creative.${domain}`;

    const message = formatMessage({ id: messageId });

    return message === messageId ? null : message;
};

const pass = () => {};

export const DcoCreativeActionsCellRenderer = ({ data, onPreview, context, ...rest }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const buttonConfigs = [
        {
            onClick: pass,
            isDisabled: true,
            dataMetricComponent: 'EditActionButton',
            iconLabel: 'edit-o',
            tooltipContent: getTooltipMessage(data, ACTIONS.EDIT, formatMessage),
        },
        {
            onClick: pass,
            isDisabled: true,
            dataMetricComponent: 'DuplicateActionButton',
            iconLabel: 'duplicate',
            tooltipContent: getTooltipMessage(data, ACTIONS.DUPLICATE, formatMessage),
        },
        {
            onClick: () => dispatch(onPreview(data, history)),
            isDisabled: false,
            dataMetricComponent: 'PreviewActionButton',
            iconLabel: 'eye',
            tooltipContent: getTooltipMessage(data, ACTIONS.PREVIEW, formatMessage),
        },
        {
            onClick: pass,
            isDisabled: true,
            dataMetricComponent: 'DeleteActionButton',
            iconLabel: 'delete',
            tooltipContent: getTooltipMessage(data, ACTIONS.DELETE, formatMessage),
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

DcoCreativeActionsCellRenderer.propTypes = {
    data: PropTypes.object,
    onPreview: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default DcoCreativeActionsCellRenderer;
