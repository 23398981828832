import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import useUnsavedChangesController from 'modules/campaigns/hooks/useUnsavedChangesController';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { Attribution } from '../components/Attribution/Attribution';
import { PixelCode } from '../components/PixelCode';
import { TrackingSettingsHeader } from '../components/TrackingSettingsHeader/TrackingSettingsHeader';
import { TRACKING_SETTINGS_ROUTE } from '../config';
import { useTrackingSettingsEditorState } from '../hooks/useTrackingSettingsEditorState';
import styles from './trackingSettings.module.scss';

const TrackingSettingsEditorPage = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, mode } = useFormDataContext();
    const { submit } = useTrackingSettingsEditorState();
    useUnsavedChangesController(TRACKING_SETTINGS_ROUTE);

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<TrackingSettingsHeader />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['tracking-settings-editor']}
                bodyContainerClassName={styles['tracking-settings-body']}
                footerContainerClassName={styles['tracking-settings-editor-footer']}
                data-automation="TrackingSettings"
            >
                <div className={styles['content']}>
                    <PixelCode />
                    <Attribution />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const TrackingSettingsProvider = withFormDataProvider(TrackingSettingsEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { TrackingSettingsProvider as TrackingSettingsEditorPage };
