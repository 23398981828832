'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _ArrowIcon = require('../ArrowIcon/ArrowIcon');

var _ArrowIcon2 = _interopRequireDefault(_ArrowIcon);

var _CustomDropdownInput = require('../CustomDropdownInput/CustomDropdownInput');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'defaultColor': 'var(--blue-5)',
    'gray6': 'var(--gray-6)',
    'taboola-svg-icon': 'dropdownMenu__taboola-svg-icon___3-mU_',
    'arrow': 'dropdownMenu__arrow___2vn20',
    'container': 'dropdownMenu__container___-pESf',
    'Select': 'dropdownMenu__Select___39PHv',
    'stretched-menu': 'dropdownMenu__stretched-menu___3Cx3u',
    'left': 'dropdownMenu__left___1DZVY',
    'right': 'dropdownMenu__right___1u78k'
};


var MENU_POSITION = {
    LEFT: 'left',
    RIGHT: 'right'
};

var classNameBuilder = _bind2.default.bind(styles);

var placeholderMargin = 2;
var valueContainerPadding = 8;

var dropDownStyles = function dropDownStyles() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var _control = _ref.control,
        _placeholder = _ref.placeholder,
        parentStyles = _objectWithoutProperties(_ref, ['control', 'placeholder']);

    return Object.assign({
        control: function control(originStyle) {
            for (var _len = arguments.length, rest = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                rest[_key - 1] = arguments[_key];
            }

            return Object.assign({}, _control ? _control.apply(undefined, [originStyle].concat(rest)) : originStyle, {
                width: '100%',
                flexWrap: 'nowrap'
            });
        },
        valueContainer: function valueContainer() {
            return {
                padding: '0 0 0 ' + valueContainerPadding + 'px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                position: 'relative'
            };
        },
        placeholder: function placeholder(props) {
            return Object.assign({
                color: styles.gray6,
                position: 'absolute',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: placeholderMargin + 'px',
                width: 'calc(100% - ' + (placeholderMargin + valueContainerPadding) + 'px)'
            }, _placeholder ? _placeholder(props) : {});
        }
    }, parentStyles);
};

var DropDownMenu = (0, _react.forwardRef)(function (props, ref) {
    var _classNameBuilder;

    var selectedValueObject = props.selectedValueObject,
        disabled = props.disabled,
        searchable = props.searchable,
        clearable = props.clearable,
        backspaceRemoves = props.backspaceRemoves,
        className = props.className,
        arrowRenderer = props.arrowRenderer,
        valueRenderer = props.valueRenderer,
        multiValueRenderer = props.multiValueRenderer,
        multiValueRemoveRenderer = props.multiValueRemoveRenderer,
        optionRenderer = props.optionRenderer,
        inputRenderer = props.inputRenderer,
        OptionWrapper = props.optionWrapper,
        ControlRenderer = props.controlRenderer,
        tooltip = props.tooltip,
        menuListRenderer = props.menuListRenderer,
        valueContainerRenderer = props.valueContainerRenderer,
        value = props.value,
        filterOption = props.filterOption,
        shouldStretchedByContent = props.shouldStretchedByContent,
        strechedMenuPosition = props.strechedMenuPosition,
        parentStyles = props.styles,
        selectClassPrefixOverride = props.selectClassPrefixOverride,
        rest = _objectWithoutProperties(props, ['selectedValueObject', 'disabled', 'searchable', 'clearable', 'backspaceRemoves', 'className', 'arrowRenderer', 'valueRenderer', 'multiValueRenderer', 'multiValueRemoveRenderer', 'optionRenderer', 'inputRenderer', 'optionWrapper', 'controlRenderer', 'tooltip', 'menuListRenderer', 'valueContainerRenderer', 'value', 'filterOption', 'shouldStretchedByContent', 'strechedMenuPosition', 'styles', 'selectClassPrefixOverride']);

    var valueObject = (0, _react.useMemo)(function () {
        if (value) {
            return value;
        }

        if (selectedValueObject === null) {
            return null;
        }

        var label = selectedValueObject.label;


        return label ? selectedValueObject : rest.options.find(function (option) {
            return option && option.value === selectedValueObject.value;
        });
    }, [rest.options, selectedValueObject, value]);

    var controlRenderer = (0, _react.useMemo)(function () {
        if (!tooltip) {
            return ControlRenderer;
        }

        return function (controlProps) {
            return _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(ControlRenderer, controlProps),
                tooltip
            );
        };
    }, [ControlRenderer, tooltip]);

    var Option = (0, _react.useMemo)(function () {
        if (OptionWrapper) {
            return function (optionProps) {
                return _react2.default.createElement(
                    OptionWrapper,
                    optionProps.data,
                    _react2.default.createElement(_reactSelect.components.Option, optionProps)
                );
            };
        }
        return optionRenderer;
    }, [OptionWrapper, optionRenderer]);

    var containerClassName = classNameBuilder('Select', (_classNameBuilder = {}, _defineProperty(_classNameBuilder, className, className), _defineProperty(_classNameBuilder, 'stretched-menu', shouldStretchedByContent), _defineProperty(_classNameBuilder, strechedMenuPosition, shouldStretchedByContent), _classNameBuilder));

    return _react2.default.createElement(_reactSelect2.default, Object.assign({}, rest, {
        styles: dropDownStyles(parentStyles),
        classNamePrefix: selectClassPrefixOverride || 'Select',
        value: valueObject,
        isSearchable: searchable,
        isDisabled: disabled,
        isClearable: clearable,
        backspaceRemovesValue: backspaceRemoves,
        filterOption: filterOption,
        className: containerClassName,
        components: {
            IndicatorSeparator: null,
            DropdownIndicator: arrowRenderer,
            SingleValue: valueRenderer,
            MultiValue: multiValueRenderer,
            MenuList: menuListRenderer,
            Option: Option,
            Control: controlRenderer,
            ValueContainer: valueContainerRenderer,
            MultiValueRemove: multiValueRemoveRenderer,
            Input: inputRenderer
        },
        ref: ref
    }));
});

var valueShape = _propTypes2.default.shape({
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
    label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node])
});

DropDownMenu.propTypes = {
    /** html id to set on the input element for accessibility or tests */
    id: _propTypes2.default.string,
    /** autofocus the component on mount */
    autoFocus: _propTypes2.default.bool,
    /** array of options */
    options: _propTypes2.default.array,
    /** should it be possible to reset value */
    clearable: _propTypes2.default.bool,
    /** field name, for hidden <input /> tag */
    name: _propTypes2.default.string,
    /** whether the component is disabled or not */
    disabled: _propTypes2.default.bool,
    /** lose focus on backspace pressed */
    backspaceRemoves: _propTypes2.default.bool,
    /** onChange handler: function(newOption) {} */
    onChange: _propTypes2.default.func.isRequired,
    /** whether to enable searching feature or not */
    searchable: _propTypes2.default.bool,
    /** className for the outer element */
    className: _propTypes2.default.string,
    /** field placeholder, displayed when there's no value */
    placeholder: _propTypes2.default.node,
    /** selected field value */
    selectedValueObject: valueShape,
    /** selected field value */
    value: _propTypes2.default.oneOfType([valueShape, _propTypes2.default.arrayOf(valueShape)]),
    /** function which returns a custom way to render the value selected function (option) {} */
    valueRenderer: _propTypes2.default.func,
    /** function which returns a custom way to render the multi value selected function (option) {} */
    multiValueRenderer: _propTypes2.default.func,
    /** function which returns a custom way to render the multi value remove function (option) {} */
    multiValueRemoveRenderer: _propTypes2.default.func,
    /** function which returns a custom way to render the arrow */
    arrowRenderer: _propTypes2.default.func,
    /** function which returns a custom way to render the option */
    optionRenderer: _propTypes2.default.func,
    /** function which returns a custom way to render the input */
    inputRenderer: _propTypes2.default.func,
    /** (alternative to optionRenderer) must be component that renders children prop */
    optionWrapper: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.node]),
    /** function which returns a custom way to render the control */
    controlRenderer: _propTypes2.default.func,
    /** tooltip attached to control renderer */
    tooltip: _propTypes2.default.node,
    /** function which returns a custom way to render the menu list */
    menuListRenderer: _propTypes2.default.func,
    styles: _propTypes2.default.object,
    filterOption: _propTypes2.default.func,
    valueContainerRenderer: _propTypes2.default.func,
    /** Flag to stretch dropdown menu by content */
    shouldStretchedByContent: _propTypes2.default.bool,
    /** Left/right align position */
    strechedMenuPosition: _propTypes2.default.string,
    selectClassPrefixOverride: _propTypes2.default.string
};

DropDownMenu.defaultProps = {
    autoFocus: false,
    options: [],
    clearable: false,
    disabled: false,
    searchable: false,
    placeholder: 'Select...',
    backspaceRemoves: false,
    valueRenderer: _reactSelect.components.SingleValue,
    multiValueRenderer: _reactSelect.components.MultiValue,
    multiValueRemoveRenderer: _reactSelect.components.MultiValueRemove,
    arrowRenderer: function arrowRenderer() {
        return _react2.default.createElement(_ArrowIcon2.default, null);
    },
    optionRenderer: _reactSelect.components.Option,
    controlRenderer: _reactSelect.components.Control,
    menuListRenderer: _reactSelect.components.MenuList,
    inputRenderer: _CustomDropdownInput.CustomDropdownInput,
    selectedValueObject: {},
    filterOption: (0, _reactSelect.createFilter)({ ignoreAccents: false }),
    valueContainerRenderer: _reactSelect.components.ValueContainer,
    strechedMenuPosition: MENU_POSITION.LEFT
};

DropDownMenu.MENU_POSITION = MENU_POSITION;

exports.default = DropDownMenu;