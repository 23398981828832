'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var STYLED_BUTTON_SIZE = {
    PRIMARY: 'primary',
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small'
};

exports.default = STYLED_BUTTON_SIZE;