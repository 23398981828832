import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './pixelBasedUrlConditionPredicateOption.module.scss';

const PixelBasedUrlConditionPredicateOption = ({ data: { value } }) => {
    const { formatMessage } = useIntl();
    const label = formatMessage({ id: `audience.editor.pixel.based.audience.conditions.predicate.${value}` });

    return (
        <div className={styles['container']}>
            <span className={styles['label']}>{label}</span>
        </div>
    );
};

PixelBasedUrlConditionPredicateOption.propTypes = {
    data: PropTypes.object,
};

export default PixelBasedUrlConditionPredicateOption;
