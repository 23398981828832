import { REQUEST_GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_ERROR, GET_REPORT_ABORT } from './actionTypes';

export const requestGetReport = () => ({ type: REQUEST_GET_REPORT });
export const getReportError = error => ({ type: GET_REPORT_ERROR, payload: error });
export const getReportSuccess = ({ reportId, results, total, metadata, rssMetadata, reportConfig, reportDataRaw }) => ({
    type: GET_REPORT_SUCCESS,
    payload: {
        reportId,
        rows: results,
        totalRow: total ? [total] : null,
        metadata,
        rssMetadata,
        reportConfig,
        reportDataRaw,
    },
});
export const getReportAbort = () => ({ type: GET_REPORT_ABORT });
