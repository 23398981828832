import LANGUAGE from '../language';
import { EMPTY_MESSAGE_ID, ONLY_VALUE_MESSAGE_ID } from './messages.common';
import defaultMessages from './messages.en.json';

const { en, ja, ko, pt, "zh-Hans": zh_hans } = LANGUAGE;
const allLocales = [
    { id: en },
    { id: ja },
    {
        id: ko,
        permissions: 'ADS_CONSOLE_KO_LANGUAGE',
    },
    { id: pt },
    { id: zh_hans }
];

export { LANGUAGE, allLocales, en as defaultLocale, EMPTY_MESSAGE_ID, ONLY_VALUE_MESSAGE_ID, defaultMessages };
