'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Icons = require('../../Icons/Icons');

var _Tooltips = require('../../Tooltips');

var _Buttons = require('../../Buttons');

var _GalleryItem = require('../GalleryItem');

var _GalleryItem2 = _interopRequireDefault(_GalleryItem);

var _AspectRatioKeeper = require('../AspectRatioKeeper/AspectRatioKeeper');

var _AspectRatioKeeper2 = _interopRequireDefault(_AspectRatioKeeper);

var _UploadFileGalleryButtonSVG = require('./UploadFileGalleryButtonSVG');

var _UploadFileGalleryButtonSVG2 = _interopRequireDefault(_UploadFileGalleryButtonSVG);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'UploadFileGalleryItem__taboola-svg-icon___3NoUi',
    'gallery-upload-item-container': 'UploadFileGalleryItem__gallery-upload-item-container___3UMXZ',
    'gallery-graphic': 'UploadFileGalleryItem__gallery-graphic___2fICL',
    'text-container': 'UploadFileGalleryItem__text-container___3zq8O',
    'heading-container': 'UploadFileGalleryItem__heading-container___20mwL',
    'disabled-caption': 'UploadFileGalleryItem__disabled-caption___33Svx',
    'title': 'UploadFileGalleryItem__title___3DucU',
    'buttons-container': 'UploadFileGalleryItem__buttons-container___1o4YX',
    'inner-button': 'UploadFileGalleryItem__inner-button___1VFfM',
    'button': 'UploadFileGalleryItem__button___2moNu'
};


var classNameBuilder = _bind2.default.bind(styles);

var UploadFileGalleryItem = function UploadFileGalleryItem(_ref) {
    var titleText = _ref.titleText,
        uploadButtonText = _ref.uploadButtonText,
        selectButtonText = _ref.selectButtonText,
        onSelectClick = _ref.onSelectClick,
        fileType = _ref.fileType,
        onAddFiles = _ref.onAddFiles,
        wrapperClass = _ref.wrapperClass,
        coverClass = _ref.coverClass,
        disabled = _ref.disabled,
        tooltip = _ref.tooltip,
        rest = _objectWithoutProperties(_ref, ['titleText', 'uploadButtonText', 'selectButtonText', 'onSelectClick', 'fileType', 'onAddFiles', 'wrapperClass', 'coverClass', 'disabled', 'tooltip']);

    var titleClassName = classNameBuilder('title', { 'disabled-caption': disabled });
    var iconClassName = classNameBuilder({ 'disabled-caption': disabled });

    return _react2.default.createElement(
        _GalleryItem2.default,
        rest,
        _react2.default.createElement(
            _AspectRatioKeeper2.default,
            { wrapperClass: wrapperClass, coverClass: coverClass },
            _react2.default.createElement(
                'div',
                { className: styles['gallery-upload-item-container'] },
                _react2.default.createElement(_UploadFileGalleryButtonSVG2.default, { className: styles['gallery-graphic'] }),
                _react2.default.createElement(
                    'div',
                    { className: styles['text-container'] },
                    _react2.default.createElement(
                        'div',
                        { className: styles['heading-container'] },
                        _react2.default.createElement(_Icons.PlusIcon, { className: iconClassName }),
                        _react2.default.createElement(
                            'span',
                            { className: titleClassName },
                            titleText
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: styles['buttons-container'] },
                        _react2.default.createElement(
                            'div',
                            { className: styles['inner-button'] },
                            _react2.default.createElement(_Buttons.FileDialogButton, {
                                buttonText: uploadButtonText,
                                disabled: disabled,
                                fileType: fileType,
                                onAddFiles: onAddFiles,
                                className: styles['button']
                            })
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: styles['inner-button'] },
                            _react2.default.createElement(
                                _Buttons.StyledButton,
                                {
                                    onClick: onSelectClick,
                                    disabled: disabled,
                                    size: _Buttons.STYLED_BUTTON_SIZE.SMALL,
                                    type: _Buttons.STYLED_BUTTON_TYPE.GHOST,
                                    className: styles['button']
                                },
                                selectButtonText
                            )
                        )
                    )
                ),
                tooltip && _react2.default.createElement(
                    _Tooltips.TooltipV2,
                    {
                        arrow: true,
                        interactive: true,
                        position: _Tooltips.TOOLTIP_POSITION.RIGHT
                    },
                    tooltip
                )
            )
        )
    );
};

UploadFileGalleryItem.propTypes = {
    /** Title's text */
    titleText: _propTypes2.default.node,
    /** Upload button's text */
    uploadButtonText: _propTypes2.default.node,
    /** Select button's text */
    selectButtonText: _propTypes2.default.node,
    /** Fires when files are selected */
    onSelectClick: _propTypes2.default.func,
    /** File types supported */
    fileType: _propTypes2.default.string,
    /** Fires when files are added */
    onAddFiles: _propTypes2.default.func,
    /** Class added to content aspect ratio wrapper element */
    wrapperClass: _propTypes2.default.string,
    /** Class added to content cover element */
    coverClass: _propTypes2.default.string,
    /** Property to disable the item */
    disabled: _propTypes2.default.bool,
    /** Item tooltip */
    tooltip: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node])
};

UploadFileGalleryItem.defaultProps = {
    titleText: 'ADD FILES',
    uploadButtonText: 'Image and Video Upload',
    selectButtonText: 'Select',
    disabled: false,
    tooltip: '',
    onSelectClick: function onSelectClick() {},
    onAddFiles: function onAddFiles() {}
};

exports.default = UploadFileGalleryItem;