import React from 'react';
import { AddIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addMarketplaceAudiencesTargetingButton.module.scss';

export const AddMarketplaceAudiencesTargetingButton = ({ onClick }) => {
    return (
        <div
            role="button"
            className={styles['container']}
            onClick={onClick}
            data-testid="marketplace-audience-targeting-and-button"
        >
            <AddIcon className={styles['plus-icon']} fontSize="small" />
            <FormattedMessage
                defaultMessage="AND"
                id="app.forms.fields.campaigns._.campaignTargeting.marketplaceAudienceTargeting.add.button"
            />
        </div>
    );
};
