'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMint = require('react-mint');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _TooltipTrigger = require('../Tooltip/TooltipTrigger');

var _TooltipTrigger2 = _interopRequireDefault(_TooltipTrigger);

var _TooltipRoot = require('./TooltipRoot');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'tooltipV2__taboola-svg-icon___3CTWD',
    'tooltip-content': 'tooltipV2__tooltip-content___381I3',
    'tooltip-mask': 'tooltipV2__tooltip-mask___1aKSC',
    'tooltip-tail': 'tooltipV2__tooltip-tail___34qo3'
};


var classNameBuilder = _bind2.default.bind(styles);

var Tooltip = function Tooltip(props) {
    var defaults = (0, _TooltipRoot.useTooltipDefaults)();

    var _defaults$props = Object.assign({}, defaults, props),
        className = _defaults$props.className,
        arrow = _defaults$props.arrow,
        placement = _defaults$props.placement,
        position = _defaults$props.position,
        trigger = _defaults$props.trigger,
        hoverable = _defaults$props.hoverable,
        clickable = _defaults$props.clickable,
        children = _defaults$props.children,
        maskClassName = _defaults$props.maskClassName,
        tailClassName = _defaults$props.tailClassName,
        rest = _objectWithoutProperties(_defaults$props, ['className', 'arrow', 'placement', 'position', 'trigger', 'hoverable', 'clickable', 'children', 'maskClassName', 'tailClassName']);

    return _react2.default.createElement(
        _reactMint.Tooltip,
        Object.assign({
            boxClassName: classNameBuilder(styles['tooltip-content'], className),
            maskClassName: classNameBuilder(styles['tooltip-mask'], maskClassName),
            tailClassName: classNameBuilder(styles['tooltip-tail'], tailClassName),
            tailHeight: arrow ? 6 : 0,
            position: placement || position,
            hoverable: trigger === _TooltipTrigger2.default.HOVER || hoverable,
            clickable: trigger === _TooltipTrigger2.default.CLICK || clickable,
            style: null,
            boxStyle: null,
            tailStyle: null
        }, rest),
        children
    );
};

Tooltip.propTypes = {
    /** contents of tooltip */
    children: _propTypes2.default.node.isRequired,
    /** class passed to box */
    className: _propTypes2.default.string,
    /** Direction for the tooltip to render */
    position: _propTypes2.default.string,
    /** Same as position for backwards compatibility */
    placement: _propTypes2.default.string,
    /** renders the tooltip when the parent is hovered over */
    hoverable: _propTypes2.default.bool,
    /** renders the tooltip when the parent is clicked on */
    clickable: _propTypes2.default.bool,
    /** Specifies which type of events will trigger a tooltip to show */
    trigger: _propTypes2.default.oneOf(Object.values(_TooltipTrigger2.default)),
    /** If this is not undefined, it programatically controls whether or not the tooltip is rendering */
    showing: _propTypes2.default.bool,
    /** Duration of animation in ms */
    duration: _propTypes2.default.number,
    /** Delay before tooltip appears */
    delay: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.array]),
    /** Should the tooltip stay open when moused over? Important for tooltips with links in them */
    interactive: _propTypes2.default.bool,
    /** Should the tooltip render a tail */
    arrow: _propTypes2.default.bool,
    /** Vertical offset between the bottom of the tail and the rendered component */
    offset: _propTypes2.default.number,
    /** Horizontal offset between tail and body. Normally the tail is centered but use this prop if you want an uncentered tail */
    offsetBody: _propTypes2.default.number,
    /**
     * Should the component be pure? This should almost always be true. If you need the component to dynamically rerender,
     *  instead of setting pure to false, try passing in the additional prop through rest
     */
    pure: _propTypes2.default.bool,
    /**
     *  Should the component render beside the element instead of with a portal? Keep this true unless you're doing something
     * super weird like a tooltip that is sandwiched between 2 other component's z-index
     */
    inline: _propTypes2.default.bool,
    /**
     *  Sets the tooltip to automatically flip position if its render would bleed out of the nearest portal
     */
    autoFlip: _propTypes2.default.bool
};

exports.default = Tooltip;