import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const AudienceDescriptionFieldTooltip = ({ fieldName = 'audience' }) => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id={`audience.editor.${fieldName}.description.tooltip.title`}
                defaultMessage="The description will help you recognize your audience when you select an audience later for targeting purposes under Audience Targeting section on the Campaign Setup page."
            />
        </TooltipSection>
    </div>
);

export default AudienceDescriptionFieldTooltip;
