export const ALL_VALIDATION_EVENTS = '*';

export const ValidationState = {
    Failed: Symbol('Failed'),
    Warning: Symbol('Warning'),
    Passed: Symbol('Passed'),
    Pending: Symbol('Pending'),
    Unknown: Symbol('Unknown'),
};

export const MAX_LISTENERS_BEFORE_WARN = 5;

export const ERROR_MSG_PARAMS_SYM = Symbol('errorMsgParams');
