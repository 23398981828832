import React from 'react';
import classnames from 'classnames/bind';
import { HelpTooltip } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import UnsupportedConfiguration from '../../../../../../components/UnsupportedConfiguration/UnsupportedConfiguration';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { useMarketplaceAudienceTargetingValues } from '../MarketplaceAudiences/hooks/useMarketplaceAudienceTargetingValues';
import styles from './segmentsTargetingSection.module.scss';

const classNameBuilder = classnames.bind(styles);
export const SegmentsTargetingSection = ({
    sectionId,
    titleMessageId,
    descriptionMessageId,
    tooltip,
    isShowTooltip,
    indication,
    children,
    headerIcon,
}) => {
    const { value: validTargetingMyAudiences, mode } = useFormFieldValue({
        field: 'campaignTargeting.myAudienceTargeting.validTargeting',
    });

    const { isValidTargeting: validTargetingMarketplace } = useMarketplaceAudienceTargetingValues();

    const { value: validTargetingContextual } = useFormFieldValue({
        field: 'campaignTargeting.contextualSegmentsTargeting.validTargeting',
    });
    const { value: validTargetingCustomContextual } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.validTargeting',
    });

    const isValidTargeting =
        validTargetingMyAudiences &&
        validTargetingMarketplace &&
        validTargetingContextual &&
        validTargetingCustomContextual;
    const containerClassName = classNameBuilder('container', { 'new-campaign': mode === FORM_MODES.CREATE });

    return (
        <div className={containerClassName}>
            <CommonCollapsibleCard
                id={sectionId}
                header={<SectionHeader headerIcon={headerIcon} headerText={<FormattedMessage id={titleMessageId} />} />}
                description={
                    <>
                        <FormattedMessage id={descriptionMessageId} />
                        {isShowTooltip && <HelpTooltip iconClassName={styles['help-tooltip']}>{tooltip}</HelpTooltip>}
                        {indication}
                    </>
                }
            >
                {isValidTargeting ? children : <UnsupportedConfiguration />}
            </CommonCollapsibleCard>
        </div>
    );
};
