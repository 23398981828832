import React from 'react';
import PropTypes from 'prop-types';
import withValidations from './withValidations';

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withCheckboxGroupValidations = (CheckboxGroupComponent, validations = [], events = {}) => {
    const ValidationsWrapper = withValidations(CheckboxGroupComponent, {
        validations,
        validationTriggerEvents: events,
        validateOnValueChange: true,
    });
    const CheckboxGroupWithValidations = ({ id, selectedValues, ...rest }) => (
        <ValidationsWrapper
            valueToValidate={selectedValues}
            validationId={id}
            selectedValues={selectedValues}
            id={id}
            {...rest}
        />
    );

    CheckboxGroupWithValidations.propTypes = {
        selectedValues: PropTypes.array,
        onValidationChange: PropTypes.func,
        triggerValidation: PropTypes.bool,
        id: PropTypes.string.isRequired,
    };

    return CheckboxGroupWithValidations;
};

export default withCheckboxGroupValidations;
