"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEditO(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M19.015 9.356l-8.658 8.656a4.412 4.412 0 0 1-1.732.98l-2.641.66-1.184.3a.57.57 0 0 1-.752-.752l.299-1.183.66-2.641c.19-.645.525-1.238.98-1.733l8.658-8.656a3.09 3.09 0 0 1 5.259 1.34 3.093 3.093 0 0 1-.89 3.029zM7.39 15.047c-.2.24-.355.513-.458.808L6.53 17.47l1.613-.404c.294-.103.568-.258.808-.457l6.823-6.822-.035-.035-1.492-1.492-.036-.036-6.82 6.824zM17.61 6.391a1.107 1.107 0 0 0-1.562 0l-.43.43.035.036 1.492 1.492.035.035.43-.43a1.108 1.108 0 0 0 0-1.565v.002z"
            })
        )
    );
}
exports.default = SvgEditO;