import React, { useEffect, useMemo } from 'react';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { FORM_MODES } from '../../../../../config';
import { itemValidations } from '../MyAudiencesSmartList/myAudiencesValidations';
import { MyAudiencesListItem } from './MyAudiencesListItem';

export const MyAudiencesListItemContentWithValidations = ({
    item,
    deleteItem,
    index,
    isReady,
    allSegmentsByIdMap,
    rejectedAudiencesList,
    isDirty,
    ...rest
}) => {
    const validationDependencies = useMemo(() => {
        return { allSegmentsByIdMap, rejectedAudiencesList };
    }, [allSegmentsByIdMap, rejectedAudiencesList]);

    const { value, onChange, failedValidationData } = useFormValidatedValue({
        field: 'id',
        validations: itemValidations,
        validationDependencies,
        isReady,
        validateOnParentChange: true,
    });

    const isRejectedAudience = rejectedAudiencesList.includes(item.id);

    const { mode } = useFormDataContext();
    //this useEffect will allow us to validate the elements of the array after adding.
    //the elements themselves doesn't change therefore, in useValidations -> isDirty=false.
    //TODO: this will change after an infra improvement DEV-120563
    useEffect(() => {
        if (mode === FORM_MODES.CREATE) {
            onChange(value);
        }
    }, [onChange, value, mode]);

    const { message, indicationType } = failedValidationData;
    return (
        <MyAudiencesListItem
            id="marketplace-audiences-item"
            item={item}
            message={message}
            indicationType={indicationType}
            deleteItem={deleteItem}
            isRejectedAudience={isRejectedAudience}
            {...rest}
        />
    );
};
