import React, { useCallback, useState } from 'react';
import {
    FormField,
    HelpTooltip,
    INDICATION_TYPES,
    Radio,
    RadioGroup,
    TOOLTIP_POSITION,
    TooltipV2 as Tooltip,
} from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import useFormValidatedValue from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { BID_STRATEGIES } from '../../config';
import CONVERSION_RULE_MODES from '../../config/ConversionRuleModes';
import { useBidStrategyFormField } from '../BidStrategyV2/hooks/useBidStrategyFormField';
import { CampaignConversionRules } from './CampaignConversionRules/CampaignConversionRules';
import { ConversionGoalTitle } from './ConversionGoalTitle';
import { ConversionOptimizationDropDown } from './ConversionOptimizationDropDown';
import ConversionOptimizationTooltip from './ConversionOptimizationTooltip';
import { conversionOptimizationValidations } from './ConversionOptimizationValidations';
import { useClcInitialData } from './hook/useClcInitialData';
import { useConversionRulesMode } from './hook/useConversionRulesMode';
import { useConversionRulesValidationDependencies } from './hook/useConversionRulesValidationDependencies';
import { useIsConversionRuleDisable } from './hook/useIsConversionRuleDisable';
import commonStyles from '../commonEditor.module.scss';
import styles from './conversionOptimization.module.scss';

const FormFieldWithIndication = withIndication(FormField);

export const ConversionOptimization = () => {
    const [hasOptions, setHasOptions] = useState(false);
    const isCampaignConversionRulesEnabled = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_MULTIPLE_CONVERSIONS_ENABLED]: 'true',
    });
    const showAccountDefault = useConfigMatch({ [FEATURE_FLAGS.HIDE_CONVERSIONS_GOAL_ACCOUNT_DEFAULT]: 'false' });

    const {
        value: conversionRulesMode,
        onChange: onConversionRulesModeChange,
        mode,
        failedValidationData: conversionRuleModeFiledValidations,
    } = useConversionRulesMode();
    const { value: bidStrategy } = useBidStrategyFormField();

    const { initialData: selectedRules, accountDefaultRule } = useClcInitialData();

    const validationDependencies = useConversionRulesValidationDependencies({
        selectedRules,
        mode,
        isCampaignConversionRulesEnabled,
        conversionRulesMode,
    });

    const {
        value: conversionRules,
        onChange: onChangeConversionRules,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'conversionRules.rules',
        validations: conversionOptimizationValidations,
        validationDependencies,
    });

    const { indicationType } = indicationData;
    const { indicationType: conversionRuleModeIndicationType } = conversionRuleModeFiledValidations;

    const onChangeConversionRuleMode = useCallback(
        mode => {
            if (mode === CONVERSION_RULE_MODES.DEFAULT) {
                onChangeConversionRules([]);
            }
            onConversionRulesModeChange(mode);
        },
        [onChangeConversionRules, onConversionRulesModeChange]
    );

    const isAllowedMaxConversionsBidStrategy = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_MAX_CONVERSIONS]: 'true',
    });

    const isMaxConversions = BID_STRATEGIES.MAX_CONVERSIONS === bidStrategy && !isAllowedMaxConversionsBidStrategy;
    const disabled = useIsConversionRuleDisable();

    return (
        <>
            <FormFieldWithIndication
                inputId="conversionRules"
                ref={scrollRef}
                label={
                    <div className={styles['label-container']}>
                        <FormattedMessage
                            id="campaign.editor.conversion.goal.title"
                            defaultMessage="Conversion Goal'"
                        />
                        <HelpTooltip content={<ConversionOptimizationTooltip />} />
                    </div>
                }
                description={
                    <FormattedMessage
                        id="campaign.editor.conversion.goal.description"
                        defaultMessage="Select “Conversion events” if you want SmartBid’s algorithm to optimize your campaign towards a different conversion than the account default."
                    />
                }
                containerClass={styles['list']}
                indicationContainerClass={
                    indicationType === INDICATION_TYPES.WARNING ||
                    conversionRuleModeIndicationType === INDICATION_TYPES.WARNING
                        ? commonStyles['warning-indication']
                        : null
                }
                {...indicationData}
                {...conversionRuleModeFiledValidations}
            >
                {showAccountDefault && (
                    <RadioGroup
                        name="conversionRuleRadioSelected"
                        selectedValue={conversionRulesMode}
                        onChange={onChangeConversionRuleMode}
                    >
                        <Radio
                            value={CONVERSION_RULE_MODES.DEFAULT}
                            title={
                                <div>
                                    <FormattedMessage
                                        id="campaign.editor.conversion.goal.account.default"
                                        defaultMessage="Account Default"
                                    />
                                    {accountDefaultRule && <span>{`: ${accountDefaultRule}`}</span>}
                                    <HelpTooltip
                                        iconClassName={styles['help-icon']}
                                        position={TOOLTIP_POSITION.RIGHT}
                                        content="Choose “Account default” to keep the campaign optimizing towards the conversion events that are  already Included in Total under the Tracking tab."
                                    />
                                </div>
                            }
                        />
                        <Radio
                            value={CONVERSION_RULE_MODES.CUSTOM}
                            title={<ConversionGoalTitle hasOptions={hasOptions} />}
                            disabled={disabled || !hasOptions}
                        />
                    </RadioGroup>
                )}
                <div className={styles['container']}>
                    {!isCampaignConversionRulesEnabled ? (
                        <ConversionOptimizationDropDown
                            conversionRules={conversionRules}
                            onChangeConversionRules={onChangeConversionRules}
                            setHasOptions={setHasOptions}
                            showAccountDefault={showAccountDefault}
                        />
                    ) : (
                        <CampaignConversionRules
                            conversionRules={conversionRules}
                            onChangeConversionRules={onChangeConversionRules}
                            setHasOptions={setHasOptions}
                            disabled={disabled}
                        />
                    )}
                    {isMaxConversions && (
                        <Tooltip arrow interactive delay={800}>
                            <FormattedMessage
                                id="campaign.editor.conversion.goal.disabled.maxConversions.tooltip"
                                defaultMessage="Conversion events are not supported for Max Conversions Campaigns. To enable it you can select a
                        different bidding strategy in step 2."
                            />
                        </Tooltip>
                    )}
                </div>
            </FormFieldWithIndication>
        </>
    );
};
