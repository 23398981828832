import React, { useCallback } from 'react';
import { DropdownField } from 'modules/campaigns/components/Form';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { CONVERSION_TYPE } from '../../config';
import { useConversionCategory } from '../../hooks/useConversionCategory';
import styles from './conversionCategoryField.module.scss';

const dropdownValidations = [
    {
        validationFn: validationFunctions.isIn,
        messageId: 'tracking.conversion.form.category.validations.error',
        defaultMessage: 'Select a Category',
    },
];

export const ConversionCategoryField = () => {
    const conversionCategories = useConversionCategory();
    const { value: categoryVal, onChange, mode } = useFormFieldValue({ field: 'category' });
    const { onChange: onChangeEventName } = useFormFieldValue({ field: 'eventName' });
    const { value: type } = useFormFieldValue({ field: 'type' });

    const category = conversionCategories?.find(categoryObj => categoryObj.value === categoryVal);

    const onCategoryChange = useCallback(
        category => {
            onChange(category.value);
            if (type === CONVERSION_TYPE.EVENT_BASED) {
                onChangeEventName(category.value.toLowerCase());
            }
        },
        [type, onChangeEventName, onChange]
    );

    return (
        <div className={styles['container']}>
            <DropdownField
                label={<FormattedMessage id="tracking.conversion.form.category.label" defaultMessage="Category" />}
                description={
                    mode === FORM_MODES.CREATE && (
                        <FormattedMessage
                            id="tracking.conversion.form.category.description"
                            defaultMessage="Choose the category that best describes the conversion you're defining."
                        />
                    )
                }
                id="conversionCategory"
                placeholder={
                    <FormattedMessage
                        id="tracking.conversion.form.category.placeholder"
                        defaultMessage="Click to Select..."
                    />
                }
                selectedValueObject={category}
                options={conversionCategories}
                onChange={onCategoryChange}
                validations={dropdownValidations}
                searchable={true}
                disabled={mode === FORM_MODES.EDIT}
            />
        </div>
    );
};
