import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Dotdotdot, Highlighter, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { getFormattedSizeAndArticles } from '../MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import styles from './audienceDetails.module.scss';

const AudienceDetails = props => {
    const {
        name,
        highLightText,
        detail,
        size = 0,
        articles = 0,
        showRemoveButton,
        handleOnClick,
        tooltip,
        minSize,
        minArticles,
        renderSizeContent,
        sizeTooltip,
        disabled,
        disabledTooltip,
        sizeIdPostfix,
    } = props;

    const classNameBuilder = classnames.bind(styles);
    const { formattedSize, formattedArticles } = useMemo(
        () => getFormattedSizeAndArticles({ size, articles, minSize, minArticles }),
        [articles, minArticles, minSize, size]
    );

    return (
        <div className={styles['container']}>
            <div className={classNameBuilder('label', { disabled })}>
                {tooltip}
                <Dotdotdot lines={3}>
                    <Highlighter text={name} highlightedText={highLightText} className={styles['highlighter']} />
                </Dotdotdot>
            </div>
            {disabled && disabledTooltip}
            <div className={styles['details']}>
                {detail && (
                    <>
                        <div className={styles['detail']}>
                            <FormattedMessage
                                id={`campaign.editor.targeting.audience.details.${detail}`}
                                defaultMessage="{detail}"
                                values={{ detail }}
                            />
                        </div>
                        <div className={styles['detail']}>
                            <FormattedMessage
                                id="campaign.editor.targeting.audience.details.delimiter"
                                defaultMessage=" | "
                            />
                        </div>
                    </>
                )}
                <div>
                    {(renderSizeContent &&
                        renderSizeContent({ size, articles, formattedSize, formattedArticles, sizeIdPostfix })) || (
                        <FormattedMessage
                            id="campaign.editor.targeting.audience.details.template.new.size"
                            defaultMessage="Size: {size}"
                            values={{ size: formattedSize }}
                        />
                    )}
                    {sizeTooltip}
                </div>
            </div>
            {showRemoveButton && (
                <StyledButton
                    aria-label="Remove"
                    className={styles['remove-button']}
                    size={STYLED_BUTTON_SIZE.MEDIUM}
                    type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                    iconBefore="close"
                    onClick={handleOnClick}
                />
            )}
        </div>
    );
};

AudienceDetails.defaultProps = {
    minSize: 100000,
};

export default AudienceDetails;
