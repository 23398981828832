import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Radio, RadioGroup } from 'taboola-ultimate-ui';
import UnsupportedConfiguration from 'components/UnsupportedConfiguration/UnsupportedConfiguration';
import { useAvailableItems } from 'hooks';
import { isProgrammaticAccount } from 'modules/account-management/accountType';
import { FORM_MODES, PRICING_MODEL } from 'modules/campaigns/config';
import BID_STRATEGIES from 'modules/campaigns/modules/common-campaign-form/config/bidStrategies';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isPricingModelCpmOrVcpm } from '../../../../../config/pricingModel';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import LearnMore from '../../LearnMore';
import TooltipSection from '../../TooltipSection';
import { BidInput } from '../BidInput';
import { CpmBidInput } from '../CpmBidInput';

const CPCTooltip = () => (
    <TooltipSection>
        <strong>
            <FormattedMessage
                id="campaign.editor.cpc.tooltip"
                defaultMesage="CPM (Cost Per One Thousand Impressions)"
            />
        </strong>
        <FormattedMessage
            id="campaign.editor.cpc.tooltip.details"
            defaultMesage="The amount you pay per one thousand impressions of your campaign items. Example: If your CPM is $0.25, it means you have paid $0.25 for every 1,000 impressions of your campaign ads."
        />
        <LearnMore href="https://help.taboola.com/hc/en-us/articles/115005944548-Setting-Your-Bid-Amount" />
    </TooltipSection>
);

const CPMTooltip = () => (
    <TooltipSection>
        <strong>
            <FormattedMessage
                id="campaign.editor.cpm.tooltip"
                defaultMesage="CPM (Cost Per One Thousand Impressions)"
            />
        </strong>
        <FormattedMessage
            id="campaign.editor.cpm.tooltip.details"
            defaultMesage="The amount you pay per one thousand available impressions of your campaign items. Example: If your CPM is $0.25, it means you have paid $0.25 for every 1,000 available impressions of your campaign ads."
        />
    </TooltipSection>
);

const VCPMTooltip = () => (
    <TooltipSection>
        <strong>
            <FormattedMessage
                id="campaign.editor.vcpm.tooltip"
                defaultMesage="CPM (Cost Per One Thousand Impressions)"
            />
        </strong>
        <FormattedMessage
            id="campaign.editor.vcpm.tooltip.details"
            defaultMesage="The amount you pay per one thousand impressions of your campaign items. For example, if your CPM is $0.25, it means you have paid $0.25 for every 1,000 impressions of your campaign ads."
        />
    </TooltipSection>
);

const isPricingModelDisabled = ({ id }, { formMode, currentPricingModel, bidStrategy, selectedAccount }) => {
    if (isProgrammaticAccount(selectedAccount)) {
        return false;
    }

    return formMode === FORM_MODES.EDIT && (currentPricingModel !== id || bidStrategy === BID_STRATEGIES.TARGET_CPA);
};

export const pricingModelConfig = [
    {
        id: PRICING_MODEL.CPC,
        messageId: 'campaign.editor.bidGroup.title.cpc',
        defaultMessage: 'Per Click (CPC)',
        Component: BidInput,
        visibilityCondition: ({ selectedAccount }) => !isProgrammaticAccount(selectedAccount),
        enableCondition: options =>
            !isPricingModelDisabled({ id: PRICING_MODEL.CPC }, options) && !options.hasHighImpactPackage,
        Tooltip: CPCTooltip,
    },
    {
        id: PRICING_MODEL.VCPM,
        messageId: 'campaign.editor.bidGroup.title.vcpm',
        defaultMessage: 'Per 1000 Impressions (CPM)',
        permissions: 'DIRECT_CAMPAIGN_VCPM_EDIT',
        Component: BidInput,
        enableCondition: options => !isPricingModelDisabled({ id: PRICING_MODEL.VCPM }, options),
        Tooltip: VCPMTooltip,
    },
    {
        id: PRICING_MODEL.CPM,
        messageId: 'campaign.editor.bidGroup.title.cpm',
        defaultMessage: 'Per 1000 Impressions (CPM - Available)',
        permissions: 'DIRECT_CAMPAIGN_CPM_EDIT',
        Component: CpmBidInput,
        enableCondition: options =>
            !isPricingModelDisabled({ id: PRICING_MODEL.CPM }, options) && !options.hasHighImpactPackage,
        Tooltip: CPMTooltip,
    },
];

export const BidInputList = ({
    hasHighImpactPackage = false,
    pricingModel,
    onPricingModelChange,
    mode,
    isManaged = true,
    ...rest
}) => {
    const { value: bidStrategy, onChange: onBidStrategyChange } = useBidStrategyFormField();
    const { formAccount: selectedAccount } = useFormDataContext();
    const availableItems = useAvailableItems(pricingModelConfig, {
        selectedAccount,
        hasHighImpactPackage,
        currentPricingModel: pricingModel,
        bidStrategy,
        formMode: mode,
    });

    useEffect(() => {
        if (isManaged) {
            const toChange = isPricingModelCpmOrVcpm(pricingModel) ? BID_STRATEGIES.FIXED : bidStrategy;
            if (toChange !== bidStrategy) {
                onBidStrategyChange(toChange);
            }
        }
    }, [isManaged, bidStrategy, onBidStrategyChange, pricingModel]);

    if (isEmpty(availableItems)) {
        return <UnsupportedConfiguration />;
    }

    if (availableItems.length === 1) {
        const [item] = availableItems;
        const { Component, props, disabled } = item;
        return <Component {...props} {...rest} pricingModel={pricingModel} disabled={disabled} />;
    }

    return (
        <RadioGroup id="bid-group" name="bid-group" onChange={onPricingModelChange} selectedValue={pricingModel}>
            {availableItems.map(({ id, messageId, defaultMessage, Component, disabled, props }) => (
                <div key={id}>
                    <Radio
                        value={id}
                        title={<FormattedMessage id={messageId} defaultMessage={defaultMessage} />}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={id}
                        disabled={disabled}
                    />
                    <Component
                        {...props}
                        {...rest}
                        pricingModel={pricingModel}
                        disabled={disabled}
                        isHidden={pricingModel !== id}
                    />
                </div>
            ))}
        </RadioGroup>
    );
};
