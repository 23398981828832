"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgAudienceType(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M16 14a4 4 0 100-8 4 4 0 000 8zm0 1a5 5 0 100-10 5 5 0 000 10zM8 26a1 1 0 01-1-1v-3.5a1 1 0 01.122-.478C7.952 19.496 10.803 17 16 17s8.048 2.495 8.878 4.022A1 1 0 0125 21.5V25a1 1 0 01-1 1H8zm16-1H8v-3.5c.635-1.167 3.124-3.5 8-3.5 4.876 0 7.365 2.333 8 3.5V25z",
            fill: "#212832"
        })
    );
}
exports.default = SvgAudienceType;