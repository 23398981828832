import React from 'react';
import { keyBy } from 'lodash';
import { FormField, EventIcon, UrlIcon } from 'taboola-ultimate-ui';
import TypeRadioIconGroup from 'components/TypeRadioIconGroup/TypeRadioIconGroup';
import { useTypeSubtext } from 'hooks';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { AUDIENCE_TYPE } from '../../config';
import styles from './pixelBasedAudienceTypeField.module.scss';

const configs = [
    {
        id: AUDIENCE_TYPE.BASIC,
        icon: UrlIcon,
        label: 'URL',
        subtext:
            'Build a targetable audience of people who visited your website, a section of your website, or specific web pages on your website.',
    },
    {
        id: AUDIENCE_TYPE.EVENT_BASED,
        icon: EventIcon,
        label: 'EVENT',
        subtext:
            'Build a targetable audience of people who have completed a specific action, such as subscribed to a newsletter or purchased an item.',
    },
];

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'audience.editor.validations.error.audience.type.empty',
        defaultMessage: 'Select an Audience Type',
    },
];

const configByKeys = keyBy(configs, 'id');

export const PixelBasedAudienceTypeField = () => {
    const { mode, value: type, onChange: onTypeChange } = useFormFieldValue({ field: 'type' });
    const msgPrefix = 'audience.editor.pixel.based.audience.type';
    const { subtext, setHovered } = useTypeSubtext(type, configByKeys, msgPrefix);

    return (
        <FormField
            inputId="audience-type"
            label={<FormattedMessage id="audience.editor.pixel.based.audience.type.title" defaultMessage="Type" />}
            subtext={subtext}
        >
            <TypeRadioIconGroup
                name="audience-type"
                type={type}
                onTypeChange={onTypeChange}
                configs={configs}
                onHover={key => setHovered(key)}
                msgPrefix={msgPrefix}
                titleClassName={styles['title']}
                disabled={mode === FORM_MODES.EDIT}
                validations={validations}
            />
        </FormField>
    );
};

export default PixelBasedAudienceTypeField;
