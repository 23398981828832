import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const CrmFileFirstPartyCountriesDropdownTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.first.party.audience.select.country.tooltip.title"
                defaultMessage="Select the country of the users in the file."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.first.party.audience.select.country.tooltip.text.line.a"
                defaultMessage="If the users originate from more than one country, please select the most common one."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.first.party.audience.select.country.tooltip.text.line.b"
                defaultMessage="If a country does not appear in the list, then it\'s unlikely we\'ll be able identify the users in the list and the audience will remain very small."
            />
        </TooltipSection>
    </>
);

export default CrmFileFirstPartyCountriesDropdownTooltip;
