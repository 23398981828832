import history from 'services/history';
import { authTokenManager } from '../services/AuthTokenManager';
import { getAndClearRedirectUrlFromLocalStorage, redirectToLoginPage } from '../services/pageRedirect';

export const accessTokenServerParam = 'access_token';
export const sessionExpireParam = 'expires_in';

const MAX_AUTH_ATTEMPTS = 3;
const SHORT_TIME_AUTH = 10 * 1000;
const AUTH_COUNTER_CONTROL_KEY = 'AUTH_COUNTER';
const EMPTY_DATA = { counter: 0, time: 0 };

const getAuthCounterData = () => {
    try {
        const rawData = sessionStorage.getItem(AUTH_COUNTER_CONTROL_KEY);
        const data = JSON.parse(rawData);

        if (Date.now() - data.time > SHORT_TIME_AUTH) {
            return EMPTY_DATA;
        }

        return data;
    } catch (err) {
        return EMPTY_DATA;
    }
};
const oneMoreAuthAttempt = () => {
    const currentData = getAuthCounterData();
    currentData.counter++;
    currentData.time = Date.now();
    sessionStorage.setItem(AUTH_COUNTER_CONTROL_KEY, JSON.stringify(currentData));
};
const reachedMaxAuthAttempts = () => {
    const currentData = getAuthCounterData();
    return currentData.counter >= MAX_AUTH_ATTEMPTS;
};

export const cleanAuthCounterData = () => sessionStorage.removeItem(AUTH_COUNTER_CONTROL_KEY);

const hashParams = hashPath => {
    const serverParams = {};

    hashPath
        .substring(1)
        .split('&')
        .forEach(param => {
            const query = param.split('=');

            serverParams[query[0]] = query[1];
        });
    return serverParams;
};

const gotAuthParamsFromServer = location => location.hash?.length;

const oneSecondMs = 1000;
const safeShiftSec = 100;

export const parseAndProcessToken = userId => {
    if (!gotAuthParamsFromServer(window.location)) {
        if (reachedMaxAuthAttempts()) {
            throw new Error(
                'The maximum number of login attempts has been reached. Please wait and try again later, or get in touch with our support team for assistance.'
            );
        }

        oneMoreAuthAttempt();
        redirectToLoginPage({ userId });

        return;
    }

    const { pathname, search } = getAndClearRedirectUrlFromLocalStorage();
    const serverParams = hashParams(window.location.hash);
    const token = serverParams[accessTokenServerParam];
    const expiresAt = Date.now() + (+serverParams[sessionExpireParam] - safeShiftSec) * oneSecondMs;
    authTokenManager.setToken(token, expiresAt);
    history.push(`${pathname}${search}`);
};
