import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { deletePerformanceRuleFlow } from '../flows/deletePerformanceRuleFlow';
import styles from '../../audiences/components/CombinedAudiences/combinedAudiences.module.scss';

const DeleteModalContent = ({ initialData: { ruleName }, onClose }) => {
    const isUndoEnabled = useConfigMatch({
        [FEATURE_FLAGS.PERFORMANCE_RULES_UNDO_ACTIONS_ENABLED]: 'true',
    });
    const [shouldUndoActions, setShouldUndoActions] = useState(isUndoEnabled);

    return (
        <ModalFormLayout
            onCancel={onClose}
            onSubmit={() => {
                onClose({ accepted: true, shouldUndoActions });
            }}
            hasCancel={true}
            submitButtonText={<FormattedMessage id="app.actionButtons.DELETE" />}
        >
            <FormattedMessage
                id="performance.rule.delete.confirmation.message"
                defaultMessage="You are about to delete {name}. Once a rule is deleted, it will be removed from the report and it can’t be restored."
                values={{ name: <b>{ruleName}</b> }}
            />
            {isUndoEnabled && (
                <TextCheckbox
                    labelClassName={styles['checkbox']}
                    title={
                        <FormattedMessage
                            id="performance.rule.delete.confirmation.undo.actions"
                            defaultMessage="Undo all actions performed by this rule"
                        />
                    }
                    selectedValues={[true]}
                    value={shouldUndoActions}
                    onSelectCheckbox={() => setShouldUndoActions(true)}
                    onUnselectCheckbox={() => setShouldUndoActions(false)}
                    independent
                />
            )}
        </ModalFormLayout>
    );
};
export const useDeletePerformanceRule = () => {
    const dispatch = useDispatch();
    const { open: openModal } = useModal();

    const deletePerformanceRule = useCallback(
        async rowData => {
            const { id: ruleId, accountName, ruleName } = rowData;

            const { accepted, shouldUndoActions } = await openModal({
                title: (
                    <FormattedMessage
                        id="performance.rule.delete.confirmation.title"
                        defaultMessage="Delete Rule?"
                        tagName="h2"
                    />
                ),
                contentRenderer: DeleteModalContent,
                initialData: { ruleName },
            });

            if (accepted) {
                dispatch(
                    deletePerformanceRuleFlow({
                        accountName,
                        ruleId,
                        shouldUndoActions,
                    })
                );
            }
        },
        [openModal, dispatch]
    );

    return deletePerformanceRule;
};
