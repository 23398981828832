import React from 'react';
import { Button, SettingsOutlinedIcon } from 'tuui';
import { useNavigate } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { TRACKING_TYPE } from '../../config';
import { generateSettingsEditorPath } from '../../utils/generateTrackingPath';
import { GetHelpFromExpertButton } from '../GetHelpFromExpert/GetHelpFromExpertButton';
import { TrackingSetupButton } from '../TrackingButton/TrackingSetupButton';
import { TrackingStatus } from '../TrackingStatus/TrackingStatus';
import styles from './trackingStateRibbon.module.scss';

export const TrackingStateRibbon = () => {
    const {
        selectedAccountId: accountId,
        trackingStateData: { s2sTrackingData },
        isLoadingTrackingState,
    } = useTrackingState();
    const navigate = useNavigate();
    const pixelSettingsPermission = usePermissions('UNIP_VIEW_PERMISSION');

    if (isLoadingTrackingState) {
        return null;
    }
    const { lastEvent: lastS2sEvent } = s2sTrackingData || {};
    const onClickHandle = () => {
        navigate(generateSettingsEditorPath());
    };

    return (
        <div className={styles['container']}>
            <div className={styles['section']}>
                <div>
                    <span className={styles['title']}>Tracking</span>
                    <div className={styles['body']}>
                        <FormattedMessage
                            id="tracking.status.account.id"
                            defaultMessage="Account ID: {accountId}"
                            values={{ accountId }}
                        />
                    </div>
                </div>
                <span className={styles['divider']} />
                <TrackingStatus trackingType={TRACKING_TYPE.PIXEL} />
                {lastS2sEvent && <TrackingStatus trackingType={TRACKING_TYPE.S2S} />}
            </div>
            <div className={styles['buttons-section']}>
                {pixelSettingsPermission && (
                    <Button size={Button.size.lg} variant={Button.variant.text} onClick={onClickHandle}>
                        <SettingsOutlinedIcon className={styles['setting-button']} />
                        <FormattedMessage id="tracking.status.settings" defaultMessage="Settings" />
                    </Button>
                )}
                <GetHelpFromExpertButton />
                <TrackingSetupButton />
            </div>
        </div>
    );
};
