import { isMatch } from 'lodash';
import { BrandIcon, LeadIcon, MobileBigIcon, OnlineIcon, WebsiteIcon } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';

const LEADS_GENERATION = 'LEADS_GENERATION';
const ONLINE_PURCHASES = 'ONLINE_PURCHASES';
const DRIVE_WEBSITE_TRAFFIC = 'DRIVE_WEBSITE_TRAFFIC';
const BRAND_AWARENESS = 'BRAND_AWARENESS';
const MOBILE_APP_INSTALL = 'MOBILE_APP_INSTALL';

const enableCondition = conditionData => {
    const { enableData } = conditionData;
    const { accountConfig, permission } = enableData || {};
    const { accountConfig: accountConfigMap, permissionsMap } = conditionData;

    return permissionsMap[permission] && isMatch(accountConfigMap, accountConfig);
};

export const GROUP_MARKETING_OBJECTIVES = [
    {
        id: LEADS_GENERATION,
        icon: LeadIcon,
        enableCondition,
        enableData: {
            permission: 'GROUP_LEADS_GENERATION',
            accountConfig: { [FEATURE_FLAGS.ENABLE_GROUP_LEADS_GENERATION]: 'true' },
        },
    },
    {
        id: ONLINE_PURCHASES,
        icon: OnlineIcon,
        enableCondition,
        enableData: {
            permission: 'GROUP_ONLINE_PURCHASES',
            accountConfig: { [FEATURE_FLAGS.ENABLE_GROUP_ONLINE_PURCHASES]: 'true' },
        },
    },
    {
        id: DRIVE_WEBSITE_TRAFFIC,
        icon: WebsiteIcon,
        enableCondition,
        enableData: {
            permission: 'GROUP_DRIVE_WEBSITE_TRAFFIC',
            accountConfig: { [FEATURE_FLAGS.ENABLE_GROUP_DRIVE_WEBSITE_TRAFFIC]: 'true' },
        },
    },
    {
        id: BRAND_AWARENESS,
        icon: BrandIcon,
        enableCondition,
        enableData: {
            permission: 'GROUP_BRAND_AWARENESS',
            accountConfig: { [FEATURE_FLAGS.ENABLE_GROUP_BRAND_AWARENESS]: 'true' },
        },
    },
    {
        id: MOBILE_APP_INSTALL,
        icon: MobileBigIcon,
        enableCondition,
        enableData: {
            permission: 'GROUP_MOBILE_APP_INSTALL',
            accountConfig: { [FEATURE_FLAGS.ENABLE_GROUP_MOBILE_APP_INSTALL]: 'true' },
        },
    },
];
