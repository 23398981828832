import React from 'react';
import { FORM_MODES } from 'config/formModes';
import CommonCollapsibleCard from '../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from '../../../campaigns/components/Form/SectionHeader/SectionHeader';
import { useFormDataContext } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_PROVIDER_NAME } from '../../config/PaymentProviderName';
import { useBillingData } from '../../hooks/useBillingData';
import { PaymentMethodNameOnCard } from '../PaymentMethodNameOnCard/PaymentMethodNameOnCard';
import { ReadOnlyCreditCardInfo } from '../ReadOnlyCreditCardInfo/ReadOnlyCreditCardInfo';
import { StripeCreditCardInfo } from '../StripeElements/StripeCreditCardInfo/StripeCreditCardInfo';
import { CardBrands } from './CardBrands';
import styles from './paymentMethodCreditCardSection.module.scss';

const providerToCreditCardInfo = {
    [PAYMENT_PROVIDER_NAME.STRIPE]: StripeCreditCardInfo,
};

export const PaymentMethodCreditCardSection = () => {
    const {
        billingData: { paymentProviderName },
    } = useBillingData();
    const { mode } = useFormDataContext();
    const CreditCardInfo = providerToCreditCardInfo[paymentProviderName];

    return (
        <CommonCollapsibleCard
            id="CREDIT_CARD"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="paymentMethod.creator.CARD" defaultMessage="Credit Card" />}
                />
            }
        >
            <div className={styles['collapsible-body']} data-private="true">
                <CardBrands />
                <PaymentMethodNameOnCard />
                {mode !== FORM_MODES.CREATE ? <ReadOnlyCreditCardInfo /> : <CreditCardInfo />}
            </div>
        </CommonCollapsibleCard>
    );
};
