'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.usePageMechanism = undefined;

var _react = require('react');

var _lodash = require('lodash.range');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.sum');

var _lodash4 = _interopRequireDefault(_lodash3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getPageByIndex = function getPageByIndex(pageSize, index) {
    return Math.ceil((index + 1) / pageSize);
};

var usePageMechanism = exports.usePageMechanism = function usePageMechanism(pageSize) {
    var filteredOutCountByPageRef = (0, _react.useRef)({});
    var pagesLoaded = (0, _react.useRef)(new Set());

    var resetPageState = (0, _react.useCallback)(function () {
        filteredOutCountByPageRef.current = {};
        pagesLoaded.current.clear();
    }, []);

    var markPageAsLoaded = (0, _react.useCallback)(function (page) {
        var filteredItemsCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

        pagesLoaded.current.add(page);
        filteredOutCountByPageRef.current[page] = filteredItemsCount;
    }, []);

    var isPageLoaded = (0, _react.useCallback)(function (page) {
        return pagesLoaded.current.has(page);
    }, []);

    var getFilteredCountTotal = (0, _react.useCallback)(function () {
        return (0, _lodash4.default)(Object.values(filteredOutCountByPageRef.current));
    }, []);

    var getHiddenItemsCountUpToVisibleIndex = (0, _react.useCallback)(function (index) {
        var hiddenItems = 0;
        for (var page = 1; page <= getPageByIndex(pageSize, index + hiddenItems); page += 1) {
            hiddenItems += filteredOutCountByPageRef.current[page] || 0;
        }

        return hiddenItems;
    }, [pageSize]);

    var getPageRange = (0, _react.useCallback)(function (startIndex, endIndex) {
        var hiddenItems = getHiddenItemsCountUpToVisibleIndex(startIndex);
        var pageStart = getPageByIndex(pageSize, startIndex + hiddenItems);
        var pageEnd = getPageByIndex(pageSize, endIndex + hiddenItems);
        var pages = (0, _lodash2.default)(pageStart, pageEnd + 1);

        return pages;
    }, [pageSize, getHiddenItemsCountUpToVisibleIndex]);

    (0, _react.useEffect)(function () {
        resetPageState();
    }, [pageSize]);

    return {
        resetPageState: resetPageState,
        markPageAsLoaded: markPageAsLoaded,
        isPageLoaded: isPageLoaded,
        getFilteredCountTotal: getFilteredCountTotal,
        getPageRange: getPageRange
    };
};

exports.default = usePageMechanism;