import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { PERFORMANCE_RULES_ROUTE } from 'modules/performance-rules/config';
import { duplicatePerformanceRule } from 'modules/performance-rules/flows/duplicatePerformanceRule';
import { fetchPerformanceRule } from 'modules/performance-rules/flows/fetchPerformanceRule';
import { performanceRulesTransformerToGW } from 'modules/performance-rules/transformers/performanceRulesTransformerToGW';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';

export const usePerformanceRulesDuplicateState = () => {
    const { ruleId, accountId } = useParams();
    const dispatch = useDispatch();
    const formDataFetcher = useCallback(
        () => dispatch(fetchPerformanceRule(accountId, ruleId)),
        [accountId, dispatch, ruleId]
    );

    const { submit } = useFormState({ formDataFetcher });

    const handlePerformanceRulesSubmitWrapper = useCallback(() => {
        const savePerformanceRulesWrapper = async rule => {
            const transformedRule = performanceRulesTransformerToGW(rule);
            const duplicate = await dispatch(
                duplicatePerformanceRule({
                    accountName: accountId,
                    queryParams: transformedRule,
                })
            );
            return duplicate;
        };
        submit(savePerformanceRulesWrapper, PERFORMANCE_RULES_ROUTE);
    }, [accountId, submit, dispatch]);

    return { submit: handlePerformanceRulesSubmitWrapper };
};
