import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import CreateAdditionalAudienceFromFileFieldTooltip from './CreateAdditionalAudienceFromFileFieldTooltip';

export const CreateAdditionalAudienceFromFileField = ({
    msgId,
    defaultMessage,
    checkedValue,
    uncheckedValue,
    headerMsgId,
    headerDefaultMessage,
}) => {
    const { value: crmTargetingType, onChange: onCrmTargetingTypeChange } = useFormFieldValue({
        field: 'crmTargetingType',
    });

    return (
        <FormField
            inputId="additional-audience-input"
            label={<FormattedMessage id={headerMsgId} defaultMessage={headerDefaultMessage} />}
            helpText={<CreateAdditionalAudienceFromFileFieldTooltip />}
        >
            <CheckboxGroup
                onSelectCheckbox={() => onCrmTargetingTypeChange(checkedValue)}
                onUnselectCheckbox={() => onCrmTargetingTypeChange(uncheckedValue)}
                selectedValues={[crmTargetingType === checkedValue]}
            >
                <TextCheckbox value title={<FormattedMessage id={msgId} defaultMessage={defaultMessage} />} />
            </CheckboxGroup>
            <br />
        </FormField>
    );
};

CreateAdditionalAudienceFromFileField.propTypes = {
    msgId: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    checkedValue: PropTypes.string.isRequired,
    uncheckedValue: PropTypes.string.isRequired,
};

export default CreateAdditionalAudienceFromFileField;
