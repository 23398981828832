import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MyAudiences } from '../MyAudiences/MyAudiencesSmartList/MyAudiences';
import MyAudiencesFieldTooltip from './MyAudiencesFieldTooltip';
import styles from '../commonEditor.module.scss';

export const MyAudiencesField = () => {
    const { formAccount } = useFormDataContext();
    // This is a temporary patch
    useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.include',
    });

    return (
        <FormField
            inputId="my-audiences-field"
            label={<FormattedMessage id="campaign.editor.targeting.my.audiences.title" defaultMessage="My Audiences" />}
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.my.audiences.description"
                    defaultMessage="Target custom or predictive audiences. You can include or exclude (suppress) each audience."
                />
            }
            helpText={<MyAudiencesFieldTooltip />}
            containerClass={styles['input']}
        >
            <MyAudiences selectedAccountId={formAccount.accountName} />
        </FormField>
    );
};
