'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var leftDotRegExp = /^\./;

var useNumberMask = function useNumberMask(onChange) {
    return (0, _react.useCallback)(function (e) {
        var tempValue = e.target.value || '';

        if (leftDotRegExp.test(tempValue)) {
            e.target.value = '0' + tempValue;
        }
        if (onChange) {
            onChange(e);
        }
    }, [onChange]);
};

exports.default = useNumberMask;