"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgRight(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M17.057 13.062c.285-.284.443-.66.443-1.061 0-.401-.158-.778-.443-1.06l-6.833-6.649a1.018 1.018 0 0 0-1.428 0 .993.993 0 0 0 0 1.415L15.272 12l-6.476 6.293a.993.993 0 0 0 0 1.415 1.017 1.017 0 0 0 1.428 0l6.833-6.647z"
            })
        )
    );
}
exports.default = SvgRight;