import { isEmpty, isNil, uniqBy } from 'lodash';
import { SORTING_ORDER_TYPES, TaboolaGraph } from 'taboola-ultimate-ui';
import { BarGraphIcon, LineGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import CampaignGraphTooltip from '../../../components/Tooltips/CampainGraphTooltip';
import { CreativeLegendTooltip, CreativeLegendTooltipClass } from '../../../components/Tooltips/CreativeGraphTooltip';
import CreativeGraphTooltip from '../../../components/Tooltips/CreativeGraphTooltip';
import { DEFAULT_GRAPH_PALETTE } from '../../../services/dynamicColumnsService';
import { getDataWithFullPeriodScaleSingleMetric } from '../../../transformers/getDataWithFullPeriodScaleSingleMetric';
import * as FIELDS from '../../fields';
import { DATE } from '../../fields';
import { dayWeekMonthXAxisMinTickGap, hourXAxisFormatterUTC, tooltipHourFormatterUTC } from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'top-campaign-realtime-report-by-creative',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.TITLE.field,
    },
    graphToggleIcon: BarGraphIcon,
    graphTooltip: CreativeGraphTooltip,
};

const singleMetricGraph = {
    type: GRAPHS.SINGLE_METRIC_LINE,
    graphIdField: DATE.field,
    endpoint: 'top-campaign-realtime-report-by-creative-time-bucket',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 10,
    collatingKey: 'id',
    skipSyncWithGrid: true,
    emptyMetricsMsgIdOverride: 'metrics.empty.compareCreatives',
    numLegendValues: 10,
    campaignIdFilterName: 'campaign',
    getGridDataDependentFilters: reportData => {
        if (!isEmpty(reportData)) {
            return {
                items: reportData.slice(0, 10).map(({ id }) => id),
            };
        }
    },
    defaultSort: [{ field: 'date', order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: FIELDS.DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: hourXAxisFormatterUTC,
        toolTipLabelFormatter: tooltipHourFormatterUTC,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
        isSingleYAxisDomain: true,
    },
    getMetrics: ({ graphData: { results } }) => {
        const sanitizedResults = uniqBy(results, 'id').filter(({ id }) => !isNil(id));
        const colors = DEFAULT_GRAPH_PALETTE;
        return sanitizedResults.map((dataPoint, index) => ({
            name: String(dataPoint.id),
            label: dataPoint.title,
            color: colors[index],
            active: index < 2,
            payload: {
                label: dataPoint.title,
                thumbnailUrl: dataPoint.thumbnailUrl,
                description: dataPoint.description,
                cta: dataPoint.cta,
                tooltipClass: CreativeLegendTooltipClass,
                tooltipProps: {
                    position: 'bottom',
                    arrow: false,
                },
            },
        }));
    },
    graphTooltip: CampaignGraphTooltip,
    graphLegendTooltip: CreativeLegendTooltip,
    graphToggleIcon: LineGraphIcon,
    dataViewTransformer: getDataWithFullPeriodScaleSingleMetric,
};

export const graphConfigs = [singleMetricGraph, graph];
