import React from 'react';
import PropTypes from 'prop-types';
import { StyledWithModeButton, STYLED_WITH_MODE_BUTTON_TYPE, STYLED_WITH_MODE_BUTTON_SIZE } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './campaignPickerError.module.scss';

const CampaignPickerError = props => {
    const { reload } = props;

    return (
        <div className={styles['container']}>
            <div className={styles['primary-message']}>
                <FormattedMessage id="campaign.picker.error.message" />
            </div>
            <div className={styles['sub-message']}>
                <FormattedMessage id="campaign.picker.error.message.sub" />
            </div>
            <StyledWithModeButton
                className={styles['reload-button']}
                onClick={reload}
                size={STYLED_WITH_MODE_BUTTON_SIZE.SMALL}
                type={STYLED_WITH_MODE_BUTTON_TYPE.SYSTEM}
            >
                <FormattedMessage id="campaign.picker.error.button.name" />
            </StyledWithModeButton>
        </div>
    );
};

CampaignPickerError.propTypes = { reload: PropTypes.func };

CampaignPickerError.defaultProps = { reload: () => {} };

export default CampaignPickerError;
