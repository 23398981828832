/**
 * Created by oreuveni
 * Date: 2018-12-13
 * Time: 16:13
 */
import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormFooterValidations } from '../FormFooterValidations';
import styles from './formButtons.module.scss';

const classNameBuilder = classnames.bind(styles);

const FormButtons = props => {
    const {
        onSubmit,
        onBack,
        onCancel,
        submitLabel,
        backLabel,
        hasCancel,
        hasSubmit,
        cancelLabel,
        submitIcon,
        backIcon,
        className,
        disableSubmit,
        disableCancel,
        loadingSubmit,
        cancelDataMetricAttrs,
        submitDataMetricAttrs,
        submitButtonType,
        outlineSubmitButton,
        showFooterValidations,
        children,
    } = props;

    return (
        <div className={`${styles['container']} ${className}`}>
            <div>
                {!!backLabel && (
                    <Button
                        className={classNameBuilder('button', 'back')}
                        onClick={onBack}
                        size={Button.size.md}
                        variant={Button.variant.ghost}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="BackButton"
                    >
                        {backIcon}
                        {backLabel}
                    </Button>
                )}
            </div>
            <div className={styles['action-buttons']}>
                {showFooterValidations && <FormFooterValidations />}
                {hasCancel && (
                    <Button
                        className={classNameBuilder('button', 'cancel')}
                        onClick={onCancel}
                        size={Button.size.md}
                        variant={Button.variant.ghost}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="CancelButton"
                        {...cancelDataMetricAttrs}
                        data-automation="FormCancelButton"
                        disabled={disableCancel}
                    >
                        {cancelLabel}
                    </Button>
                )}
                {hasSubmit && (
                    <Button
                        className={classNameBuilder('button', 'submit', { loading: loadingSubmit })}
                        onClick={onSubmit}
                        size={Button.size.md}
                        variant={outlineSubmitButton ? Button.variant.outline : Button.variant.primary}
                        type={submitButtonType}
                        disabled={disableSubmit}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        {...submitDataMetricAttrs}
                        data-automation="FormSubmitButton"
                    >
                        {submitLabel}
                        {submitIcon}
                    </Button>
                )}
                {children}
            </div>
        </div>
    );
};

FormButtons.propTypes = {
    onSubmit: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    submitLabel: PropTypes.node,
    backLabel: PropTypes.node,
    hasCancel: PropTypes.bool,
    cancelLabel: PropTypes.node,
    submitIcon: PropTypes.node,
    backIcon: PropTypes.node,
    className: PropTypes.string,
    disableSubmit: PropTypes.bool,
    loadingSubmit: PropTypes.bool,
    submitDataMetricAttrs: PropTypes.object,
    submitButtonType: PropTypes.string,
    outlineSubmitButton: PropTypes.bool,
    showFooterValidations: PropTypes.bool,
};

FormButtons.defaultProps = {
    className: '',
    submitDataMetricAttrs: {
        'data-metrics-component': 'SubmitButton',
    },
    submitLabel: <FormattedMessage id="app.actionButtons.save" defaultMessage="Save" />,
    hasCancel: true,
    hasSubmit: true,
    outlineSubmitButton: false,
    cancelLabel: <FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />,
    showFooterValidations: true,
};

export default FormButtons;
