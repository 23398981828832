import { get } from 'lodash';
import config from '../../../config';
import { useModuleName } from '../../../hooks';

export const useShowHeader = () => {
    const module = useModuleName();
    const showHeader = !get(config.routingMap, `${module}.hideHeader`, false);

    return showHeader;
};
