import { useEffect, useMemo, useRef, useState } from 'react';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { FormField, RadioGroup } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useAvailableItems, useEntityType } from 'hooks';
import { PATH_TO_ENTITY_MAP } from 'modules/campaigns/config';
import { withIndication } from 'modules/errors';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { BUDGET_TYPES } from '../../config';
import BudgetRadioChoice from './BudgetRadioChoice/BudgetRadioChoice';
import SpendingLimit from './SpendingLimit/SpendingLimit';
import { BUDGET_OPTIONS } from './budgetOptions';
import { getEmptyBudgetObject } from './const/emptyBudgetObject';
import { useBudgetFormFieldValue } from './hooks/useBudgetFormFieldValue';
import { useFormBudgetValidatedValue } from './hooks/useFormBudgetValidatedValue';
import { useNoneSpendingLimitEnabled } from './hooks/useNoneSpendingLimitEnabled';
import styles from './budget.module.scss';

export const RadioGroupWithErrorMessage = withIndication(RadioGroup, styles['group-container']);
const isDailyBudget = type => type === BUDGET_TYPES.DAILY;

export const Budget = ({
    spent,
    disabled,
    entityTypeMessagePrefix,
    disableBudgetTypeChange,
    helpTextId: helpTextIdProp,
    formFieldParams,
}) => {
    const {
        mode,
        formAccount: { currency },
    } = useFormDataContext();

    const { value: budget, onChange: onBudgetChange } = useBudgetFormFieldValue();

    const selectedBudgetType = budget?.type;
    const budgetCache = useRef({});
    const isNoneSpendingLimitEnabled = useNoneSpendingLimitEnabled();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const options = useAvailableItems(BUDGET_OPTIONS, {
        entityType,
    });

    const validationData = useMemo(
        () => ({ skipValidation: keyBy(options, 'id')[selectedBudgetType]?.skipBudgetData }),
        [options, selectedBudgetType]
    );

    const { failedValidationData: failedBudgetValueValidationData, scrollRef: budgetValueScrollRef } =
        useFormBudgetValidatedValue(validationData);

    const onRadioChange = type =>
        onBudgetChange(prev => getEmptyBudgetObject({ prev, type, isNoneSpendingLimitEnabled, budgetCache }));

    const onValueChange = value => onBudgetChange(prev => ({ ...prev, value }));
    const [hoverKey, setHoverKey] = useState();
    const getDescriptionText = () => {
        const keyForDescription = hoverKey || selectedBudgetType;

        return (
            <FormattedMessage
                id={`${entityTypeMessagePrefix}.editor.budget.${BUDGET_TYPES[keyForDescription]}.description`}
            />
        );
    };
    const handleMouseLeave = () => setHoverKey(null);
    const { value: selectedBudgetValue } = budget || {};
    const helpTextId = helpTextIdProp || `${entityTypeMessagePrefix}.editor.budget.help`;
    const showSpent = mode === FORM_MODES.EDIT && (spent || spent === 0);
    const showSpentUnderBudget =
        showSpent && (selectedBudgetType === BUDGET_TYPES.MONTHLY || selectedBudgetType === BUDGET_TYPES.LIFETIME);

    useEffect(() => {
        if (selectedBudgetType) {
            budgetCache.current[selectedBudgetType] = budget;
        }
    }, [selectedBudgetType, budget]);

    return (
        <FormField
            inputId="budget"
            label={<FormattedMessage id="campaign.editor.budget" defaultMessage="Budget" />}
            description={
                <FormattedMessage
                    id={`${entityTypeMessagePrefix}.editor.budget.description`}
                    defaultMessage="You can specify a budget that is daily, monthly, or for the duration of the campaign 'lifetime'."
                />
            }
            dataTestId="budget-test-id"
            helpText={
                <FormattedMessage
                    id={`${helpTextId}`}
                    defaultMessage="Decide how much you would like to spend on your campaign - and how you would like to pace your budget."
                />
            }
            ref={budgetValueScrollRef}
            {...formFieldParams}
        >
            <RadioGroupWithErrorMessage
                className={styles['group']}
                selectedValue={selectedBudgetType}
                onChange={onRadioChange}
                aria-label="Budget Radio"
                data-metrics-component="Budget"
                disabled={disabled}
                {...failedBudgetValueValidationData}
            >
                {options.map(({ id, type, icon, skipBudgetData }) => (
                    <BudgetRadioChoice
                        id={id}
                        key={type}
                        className={styles['choice']}
                        title={<FormattedMessage id={`campaign.editor.${id}`} />}
                        label={<FormattedMessage id={`campaign.editor.budget.${id}`} defaultMessage="Daily Budget" />}
                        skipBudgetData={skipBudgetData}
                        iconComponent={icon}
                        type={type}
                        disabled={disableBudgetTypeChange && selectedBudgetType !== type}
                        currency={currency}
                        value={selectedBudgetValue}
                        onBudgetChange={onValueChange}
                        onMouseEnter={setHoverKey}
                        onMouseLeave={handleMouseLeave}
                        failedValidationData={failedBudgetValueValidationData}
                    />
                ))}
            </RadioGroupWithErrorMessage>
            {showSpentUnderBudget && (
                <div className={styles['spent']}>
                    <FormattedMessage
                        id="campaign.editor.budget.spent"
                        values={{
                            value: <FormattedNumber value={spent} variant="currency" currency={currency} />,
                        }}
                    />
                </div>
            )}
            <div className={styles['description']}>{getDescriptionText()}</div>
            <div className={styles['section']}>
                {isDailyBudget(selectedBudgetType) && (
                    <SpendingLimit
                        id={`${selectedBudgetType}-SpendingLimit`}
                        inputId="spending-limit"
                        currency={currency}
                        disabled={disabled}
                        budget={budget}
                        spent={showSpent ? spent : null}
                        entityTypeMessagePrefix={entityTypeMessagePrefix}
                        disableTypeChange={disableBudgetTypeChange}
                    />
                )}
            </div>
        </FormField>
    );
};

Budget.propTypes = {
    spent: PropTypes.number,
    disabled: PropTypes.bool,
    entityTypeMessagePrefix: PropTypes.string,
    disableBudgetTypeChange: PropTypes.bool,
    helpTextId: PropTypes.string,
    formFieldParams: PropTypes.object,
};
Budget.defaultProps = {
    formFieldParams: {},
};
