import React from 'react';
import PropTypes from 'prop-types';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { RadioGroupField } from '../../../../../components/Form';
import { SPENDING_LIMIT_KEYS, SPENDING_LIMITS } from '../../../config/spendingLimits';
import SpendingLimitRadioChoice from '../SpendingLimitRadioChoice/SpendingLimitRadioChoice';
import { useNoneSpendingLimitEnabled } from '../hooks/useNoneSpendingLimitEnabled';
import SpendingLimitTooltip from './SpendingLimitTooltip';
import { useFormSpendingLimitValidatedValue } from './useFormSpendingLimitValidatedValue';
import styles from '../../commonEditor.module.scss';

const SpendingLimit = ({
    id,
    inputId,
    budget,
    disabled,
    spent,
    entityTypeMessagePrefix,
    disableTypeChange,
    currency,
}) => {
    const { value: type = budget?.spendingLimit?.type, onChange: onSpendingLimitTypeChange } = useFormFieldValue({
        field: 'budget.spendingLimit.type',
    });
    const {
        value = '',
        onChange: onSpendingLimitValueChange,
        resetFieldToInitial: resetSpendingLimitValue,
        failedValidationData,
        scrollRef,
    } = useFormSpendingLimitValidatedValue(type);
    const onRadioOptionChange = type => {
        onSpendingLimitTypeChange(type);
        onSpendingLimitValueChange('');
    };
    const isNoneSpendingLimitEnabled = useNoneSpendingLimitEnabled();

    const onOptionChange = type => {
        onRadioOptionChange(type);
        if (SPENDING_LIMITS.NONE !== type) {
            resetSpendingLimitValue();
        }
    };

    const onValueChange = e => {
        const parsedValue = parseFloat(e.target.value);
        const value = isNaN(parsedValue) ? '' : parsedValue;
        onSpendingLimitValueChange(value);
    };
    const shouldRenderSpendingLimitRadioChoice = key => key !== SPENDING_LIMITS.NONE || isNoneSpendingLimitEnabled;

    return (
        <RadioGroupField
            inputId={inputId}
            groupTitle={<FormattedMessage id="campaign.editor.spending.limit.title" defaultMessage="Spending Limit" />}
            className={styles['input']}
            radioGroupName={id}
            onChange={onOptionChange}
            selectedValue={type}
            data-metrics-component="SpendingLimit"
            aria-label="SpendingLimit"
            groupHelpText={<SpendingLimitTooltip entityTypeMessagePrefix={entityTypeMessagePrefix} />}
            disabled={disabled}
            ref={scrollRef}
        >
            {SPENDING_LIMIT_KEYS.map(
                key =>
                    shouldRenderSpendingLimitRadioChoice(key) && (
                        <SpendingLimitRadioChoice
                            key={`${id}-${key}`}
                            id={`${id}-${key}`}
                            title={<FormattedMessage id={`campaign.editor.spending.limit.${key}`} />}
                            radioOption={{
                                type: key,
                                value,
                            }}
                            currency={currency}
                            spent={spent}
                            budget={budget}
                            renderInput={type === key && key !== SPENDING_LIMITS.NONE}
                            onChange={onValueChange}
                            failedValidationData={failedValidationData}
                            ariaLabel={key}
                            disabled={disableTypeChange && type !== key}
                        />
                    )
            )}
        </RadioGroupField>
    );
};
SpendingLimit.propTypes = {
    id: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired,
    currency: PropTypes.string,
    disabled: PropTypes.bool,
    spent: PropTypes.number,
    entityTypeMessagePrefix: PropTypes.string,
};

SpendingLimit.defaultProps = {
    entityTypeMessagePrefix: 'campaign',
};

export default SpendingLimit;
