import { isEmpty } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import CONVERSION_RULE_MODES from '../../config/ConversionRuleModes';

export const conversionOptimizationValidations = [
    {
        validationFn: (
            value,
            options,
            {
                selectedRules,
                isEditMode,
                numberOfConversionThreshold,
                isCampaignConversionRulesEnabled,
                disabledThresholdWarning,
            }
        ) => {
            if (disabledThresholdWarning || (!isCampaignConversionRulesEnabled && !selectedRules)) {
                return true;
            }
            const numConversions = value?.[0]?.conversions ?? selectedRules?.[0]?.conversions;

            return !isEditMode || !value.length || (isEditMode && numConversions >= numberOfConversionThreshold);
        },
        messageId: 'validations.warning.campaign.conversion.optimization.low.conversions',
        defaultMessage:
            'Note that you have chosen a conversion event with low conversion volume, we recommend choosing one with at least 50 conversions (In the past 30 days) so that SmartBid’s algorithm will optimize your campaign more accurately and effectively.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (
            value,
            options,
            {
                selectedRules,
                isEditMode,
                numberOfConversionThreshold,
                isCampaignConversionRulesEnabled,
                disabledThresholdWarning,
            }
        ) => {
            if (disabledThresholdWarning || (isCampaignConversionRulesEnabled && !selectedRules)) {
                return true;
            }

            const numConversions = value?.[0]?.conversions
                ? value.reduce((total, obj) => total + obj.conversions, 0)
                : selectedRules.reduce((total, obj) => total + obj.conversions, 0);

            return !isEditMode || !value.length || (isEditMode && numConversions >= numberOfConversionThreshold);
        },
        messageId: 'validations.warning.campaign.conversion.optimization.low.conversions',
        defaultMessage:
            'Note that you have chosen a conversion event with low conversion volume, we recommend choosing one with at least 50 conversions (In the past 30 days) so that SmartBid’s algorithm will optimize your campaign more accurately and effectively.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: (value, options, { conversionRulesMode }) =>
            conversionRulesMode !== CONVERSION_RULE_MODES.CUSTOM || !isEmpty(value),
        messageId: 'validations.error.campaign.conversion.optimization.no.selection',
        defaultMessage: 'Please select a Conversion Event',
        severity: INDICATION_TYPES.ERROR,
    },
];
