import { useCallback } from 'react';
import { RUNTIME_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useAdScheduler = () => {
    const { value: mode, onChange: onModeChange } = useFormFieldValue({ field: 'adScheduleMode' });
    const { onChange: onChangeStartDate } = useFormFieldValue({
        field: 'startDate',
    });
    const { onChange: onChangeEndDate } = useFormFieldValue({
        field: 'endDate',
    });
    const handleRunTimeModeChange = useCallback(
        value => {
            onModeChange(value);
            if (value === RUNTIME_TYPES.ALWAYS) {
                onChangeStartDate(null);
                onChangeEndDate(null);
            }
        },
        [onChangeEndDate, onChangeStartDate, onModeChange]
    );
    return { handleRunTimeModeChange, mode };
};
