import {
    MIN_VIDEO_WIDTH,
    MIN_VIDEO_HEIGHT,
} from '../../../campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import ValidationError from '../../../errors/ValidationError';

export const validateVideo = src =>
    new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
            if (video.videoWidth >= MIN_VIDEO_WIDTH && video.videoHeight >= MIN_VIDEO_HEIGHT) {
                resolve(src);
            } else {
                reject(new ValidationError('Minimum video size validation failed'));
            }
        };
        video.onerror = () => reject(new ValidationError('Video failed to load'));
        video.src = src;
    });
