import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../../campaigns/components';
import { COMMON_FLAGS } from '../../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { ENTITY } from '../../../config';
import { ExternalAudiencesTTLField } from '../SearchKeywordsPropertiesSection/ExternalAudiencesTTLField';
import { SupportedCountriesDropdown } from '../SupportedCountriesDropdown/SupportedCountriesDropdown';
import { MailDomainsListField } from './MailDomainsListField/MailDomainsListField';
import styles from './mailDomainsPropertiesSection.module.scss';

export const MailDomainsPropertiesSection = () => {
    return (
        <CommonCollapsibleCard
            id="MAIL_DOMAINS_PROPERTIES"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.properties.section.title"
                            defaultMessage="Audience Properties"
                        />
                    }
                />
            }
        >
            <div className={styles['field-container']}>
                <SupportedCountriesDropdown configName={COMMON_FLAGS.MRT_AUDIENCES_SUPPORTED_COUNTRIES_LIST} />
                <MailDomainsListField />
                <ExternalAudiencesTTLField AudienceType={ENTITY.MAIL_DOMAINS} />
            </div>
        </CommonCollapsibleCard>
    );
};
