import { useMemo } from 'react';
import { ENTITY } from 'modules/campaigns/config';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';

export const useExcelBulkUploadConfigs = () => {
    const {
        [COMMON_FLAGS.BULK_UPLOAD_MAX_CAMPAIGN_ROWS]: maxCampaignRows,
        [COMMON_FLAGS.BULK_UPLOAD_MAX_CREATIVES_ROWS]: maxCreativesRows,
    } = useCommonConfig([COMMON_FLAGS.BULK_UPLOAD_MAX_CAMPAIGN_ROWS, COMMON_FLAGS.BULK_UPLOAD_MAX_CREATIVES_ROWS]);

    const isFullConfigAvailable = useConfigMatch({ [FEATURE_FLAGS.EXCEL_BULK_ALLOW_FULL_CONFIG]: 'true' });
    const isMetadataCheckDisabled = useConfigMatch({ [FEATURE_FLAGS.BULK_OPERATIONS_METADATA_CHECK_DISABLED]: 'true' });

    const parsedMaxCampaignRows = parseInt(maxCampaignRows, 10);
    const parsedMaxCreativeRows = parseInt(maxCreativesRows, 10);

    const entityToRowLimitMap = useMemo(
        () => ({
            [ENTITY.CAMPAIGN]: parsedMaxCampaignRows,
            [ENTITY.CREATIVE]: parsedMaxCreativeRows,
        }),
        [parsedMaxCampaignRows, parsedMaxCreativeRows]
    );

    return {
        maxCampaignRows: parsedMaxCampaignRows,
        maxCreativesRows: parsedMaxCreativeRows,
        isFullConfigAvailable,
        isMetadataCheckDisabled,
        entityToRowLimitMap,
    };
};
