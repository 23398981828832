import { differenceBy, get, flatten, isEmpty, fromPairs, keyBy, isNil, groupBy, partition, values } from 'lodash';
import memoizeOne from 'memoize-one';
import { actionsColumnType, columnsTypesMap, defaultColumnDefinition } from '../config/columns';
import { isCustomReportPreset } from '../utils';
import { getCleanColId } from './conversionColumnsService';

const COL_ID_FIELD = 'colId';

const updateReportGridState = (gridState, key, state) => {
    const currentReportPresetState = gridState[key];
    const updatedStateWithColumnState = { ...currentReportPresetState, ...state };

    return { ...gridState, [key]: updatedStateWithColumnState };
};

export const updateColumnState = (gridState, reportPresetKey, columnState) =>
    updateReportGridState(gridState, reportPresetKey, { columnState });

export const calculateSelectedColumnIds = (customColumnState = []) => {
    if (!customColumnState) {
        return null;
    }

    const {
        left = [],
        none = [],
        right = [],
    } = groupBy(
        customColumnState.filter(col => !col.hide),
        col => col.pinned ?? 'none'
    );

    return left
        .concat(none)
        .concat(right)
        .map(col => col.colId);
};

export const updateSortState = (gridState, selectedReport, sortState) =>
    updateReportGridState(gridState, selectedReport, { sortState });

export const generateReportSort = (report, field, sort) => [{ colId: `${report}_${field}`, sort }];

const emptySortState = [];
export const getSortStateByKey = (reportGridState, selectedReport, defaultGridSort = emptySortState) =>
    get(reportGridState, `${selectedReport}.sortState`, defaultGridSort);

export const updateFilterAndActiveState = (gridState, selectedReport, filterState, isFilterActive) =>
    updateReportGridState(gridState, selectedReport, {
        ...(filterState === null ? {} : { filterState }),
        ...(isFilterActive === null ? {} : { isFilterActive }),
    });

export const filterReportSortByColumnDefs = (reportSort = [], columnDefs = [], availableColumnDefs = []) => {
    const dynamicColumnDefs = columnDefs.filter(({ isDynamicField }) => isDynamicField);
    const columnDefsByColId = keyBy([...dynamicColumnDefs, ...availableColumnDefs], (colDef = {}) => colDef.colId);
    return reportSort.filter(({ colId }) => columnDefsByColId[colId]);
};

const getDefsFromTypes = (types = []) =>
    types.reduce(
        (totalDefs, currentType) => ({
            ...totalDefs,
            ...columnsTypesMap[currentType],
        }),
        { ...defaultColumnDefinition }
    );

const getColDefPropValue = (colDef, propName) => {
    if (colDef[propName]) {
        return colDef[propName];
    }
    const colDefTypeValues = (colDef.type || []).map(type => (columnsTypesMap[type] || {})[propName]);
    const allValues = [defaultColumnDefinition[propName], ...colDefTypeValues];
    const finalValue = allValues.reduce((prevValue, currentValue) => (currentValue ? currentValue : prevValue));

    return finalValue;
};

const getValidWidth = (width, colDef) => {
    const minWidth = getColDefPropValue(colDef, 'minWidth');
    const maxWidth = getColDefPropValue(colDef, 'maxWidth');

    if (colDef.type?.includes(actionsColumnType)) {
        // We always ignore column state width to avoid migration and allow it consume width from column definition
        return colDef.width || null;
    }

    return Math.min(Math.max(width, minWidth), maxWidth);
};

const mapColDefToColState = colDef => {
    const defsFromTypes = getDefsFromTypes(colDef.type);
    const flatColDef = { ...defsFromTypes, ...colDef };

    return {
        aggFunc: flatColDef.aggFunc || null,
        colId: flatColDef.colId || null,
        hide: false,
        pinned: flatColDef.pinned || null,
        pivot: false,
        pivotIndex: flatColDef.pivotIndex || null,
        rowGroup: false,
        rowGroupIndex: flatColDef.rowGroupIndex || null,
        width: getValidWidth(flatColDef.width, colDef),
        sort: null,
        sortIndex: null,
        flex: null,
    };
};

// This function is needed to avoid passing columnState to ag-grid with columns missed in columnDefs (no warning in console)
// such case is possible when we switched selectedReport have new columnState but don't have response with metadata yet
export const getMatchedColumnState = (columnState, columnDefs) => {
    let matchedColumnState = columnState;

    if (columnState && columnDefs) {
        const colDefIds = columnDefs.map(col => col.colId);
        matchedColumnState = columnState.filter(col => colDefIds.includes(col.colId));
    }

    return matchedColumnState;
};

const mergeByIndex = (consumer, source, freeIndexes) => {
    const result = [...consumer];

    source.forEach((el, index) => {
        result[freeIndexes[index]] = el;
    });

    return result;
};

// This is a reverse operation opposite to getMatchedColumnState we need filtered subState put in previous places
// Grid state should be merged with full state in the same places which it was filtered from
export const mergeFullStateWithSubState = (prevFullStateParam, newSubState, resetPrevSorting) => {
    if (!prevFullStateParam) {
        return newSubState;
    }
    const prevFullState = resetPrevSorting
        ? prevFullStateParam.map(el => ({ ...el, sort: null, sortIndex: null }))
        : prevFullStateParam;
    const newSubStateSet = new Set(newSubState.map(({ colId }) => colId));
    const freeIndexes = prevFullState.reduce((acc, { colId }, index) => {
        if (newSubStateSet.has(colId)) {
            acc.push(index);
        }
        return acc;
    }, []);
    const newFullState = mergeByIndex(prevFullState, newSubState, freeIndexes);

    return newFullState;
};

const getVisibleSelectedColumns = (columns, selectedColumns, untouchableColumnSet) => {
    const selectedColumnsSet = new Set(selectedColumns);
    const result = columns.map(el => {
        if (untouchableColumnSet.has(el.colId)) {
            return el;
        }

        return {
            ...el,
            hide: !selectedColumnsSet.has(el.colId),
        };
    });

    return result;
};

export const getSortModelByColumnState = memoizeOne(columnState =>
    (columnState || [])
        .filter(({ sortIndex }) => !isNil(sortIndex))
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map(({ colId, sort }) => ({ colId, sort }))
);

export const injectSortModelToColumnState = ({ columnState, reportSorting = [], columnDefs }) => {
    let sortIndex = 0;
    const sortedColumnState = columnState.map(el => ({ ...el, sort: null, sortIndex: null }));
    const columnStateMap = keyBy(sortedColumnState, 'colId');
    const columnDefsSet = new Set(columnDefs.map(({ colId }) => colId));
    const visibleSorting = reportSorting.filter(
        ({ colId }) => columnStateMap[colId]?.hide === false && columnDefsSet.has(colId)
    );

    visibleSorting.forEach(({ colId, sort }) => {
        const colToBeSorted = columnStateMap[colId];

        colToBeSorted.sort = sort;
        colToBeSorted.sortIndex = sortIndex;
        sortIndex += 1;
    });

    return sortedColumnState;
};

export const getCustomPresetInitialState = reportConfig => get(reportConfig, 'customPreset.initialState', []);

export const getCustomPresetColumns = reportConfig => {
    const configColumns = get(reportConfig, 'customPreset.columns', []);
    const customPresetColumns = flatten(values(configColumns));

    return customPresetColumns;
};

export const getColumnDefsForConfigPreset = (columnDefs, reportConfig, reportPresetName) => {
    const configColumns = get(reportConfig, `presets.${reportPresetName}.columns`, []);
    const columnSet = new Set(configColumns);
    return columnDefs.filter(({ colId }) => columnSet.has(colId));
};

// get the list of all columns present in the default preset, but are not
// customizable (the list of columns REQUIRED to appear in the custom preset)

export const getRequiredCustomColumns = reportConfig => {
    const { columns: customizableColumns, initialState: defaultColumns } = get(reportConfig, 'customPreset', {});

    if (!customizableColumns || !defaultColumns) {
        return [];
    }

    const customizableColumnsSet = new Set(flatten(Object.values(customizableColumns)));

    return defaultColumns.filter(col => !customizableColumnsSet.has(col));
};

// TODO DEV-80934 Restructure imports to allow import conversions service and fields into grid service
const dynColIdRegExp = /_[\d]+$/;
const CLICKS_VIEWS = {
    cpaClicks: true,
    cpaViews: true,
    cvrClicks: true,
    cvrViews: true,
    actionsConversionsClicks: true,
    actionsConversionsViews: true,
    roasClicks: true,
    roasViews: true,
};

export const isDynamicColId = colId => dynColIdRegExp.test(colId);

export const isVisibleDynamicColumnState = ({ colId, hide }) => isDynamicColId(colId) && !hide;

export const isVisibleChild = ({ colId, hide }) => CLICKS_VIEWS[getCleanColId(colId)] && !hide;

export const pickColumnsToKeepColumnState = ({
    columnState,
    currentColumns,
    allConfigColumnDefs = [],
    reportPresetName,
}) => {
    const isCustomPreset = isCustomReportPreset(reportPresetName);
    const currentColIds = keyBy(currentColumns, COL_ID_FIELD);
    const temporaryHiddenColsById = keyBy(
        allConfigColumnDefs.filter(({ colId }) => !currentColIds[colId]),
        COL_ID_FIELD
    );
    const dynamicColumnsShouldBeKept = (columnState || []).filter(
        col =>
            !currentColIds[col.colId] &&
            (((isVisibleDynamicColumnState(col) || isVisibleChild(col)) && isCustomPreset) ||
                (!col.hide && temporaryHiddenColsById[col.colId]))
    );

    return dynamicColumnsShouldBeKept;
};

const getReportPresetOrderedList = (reportConfig, presetName) => {
    if (!isCustomReportPreset(presetName)) {
        const columns = reportConfig?.presets[presetName].columns || [];
        return columns;
    }
    const sortedList = [...getRequiredCustomColumns(reportConfig), ...getCustomPresetColumns(reportConfig)];
    return sortedList;
};

const getPresetOrderMap = (columnDefs, reportPresetList, presetName) => {
    const presetOrderMap = fromPairs(reportPresetList.map((colId, i) => [colId, i]));
    if (!isCustomReportPreset(presetName)) {
        return presetOrderMap;
    }

    //we need to add click/views to be sorted right after main column
    const childWeight = 0.1;
    columnDefs.forEach(({ colId, referenceColId }) => {
        if (!referenceColId) {
            return;
        }

        const parentIndex = presetOrderMap[referenceColId];
        if (isNil(parentIndex)) {
            return;
        }
        presetOrderMap[colId] = parentIndex + childWeight;
    });

    return presetOrderMap;
};

export const getColumnStateWithNewAddedColumns = ({
    columnState,
    columnDefs,
    reportPresetList = [],
    reportPresetName,
    newColumnVisibilityMapper,
}) => {
    const presetOrderMap = getPresetOrderMap(columnDefs, reportPresetList, reportPresetName);
    const [presentInPreset, absentInPreset] = partition(columnDefs, ({ colId }) => !isNil(presetOrderMap[colId]));
    const sorter = ({ colId: a }, { colId: b }) => presetOrderMap[a] - presetOrderMap[b];
    const sortedColumnDefs = [...presentInPreset.sort(sorter), ...absentInPreset];

    if (isEmpty(columnState)) {
        return sortedColumnDefs.map(mapColDefToColState).map(newColumnVisibilityMapper);
    }

    const newAddedColumnDefs = differenceBy(columnDefs, columnState, COL_ID_FIELD);
    if (isEmpty(newAddedColumnDefs)) {
        return columnState;
    }

    // We calculate correct new columns order
    const newAddedColumnSet = new Set(newAddedColumnDefs.map(({ colId }) => colId));
    const newAddedColumnDefPairs = sortedColumnDefs
        .map((colDef, i) => ({
            colDef,
            predecessorColId: sortedColumnDefs[i - 1]?.colId,
        }))
        .filter(({ colDef: { colId } }) => newAddedColumnSet.has(colId));

    // We insert new column state into right order
    const result = newAddedColumnDefPairs.reduce(
        (acc, { colDef, predecessorColId }) => {
            const singleColumnState = newColumnVisibilityMapper(mapColDefToColState(colDef));

            if (isNil(predecessorColId)) {
                return [singleColumnState, ...acc];
            }

            const predecessorIndex = acc.findIndex(({ colId }) => colId === predecessorColId);
            // We inject new single column state right after column which precedes it in preset config
            return [...acc.slice(0, predecessorIndex + 1), singleColumnState, ...acc.slice(predecessorIndex + 1)];
        },
        [...columnState]
    );

    return result;
};

export const getMergedColumnStateWithDef = ({
    columnState,
    columnDefs,
    reportConfig,
    selectedColumns,
    reportPresetName,
}) => {
    const isCustomPreset = isCustomReportPreset(reportPresetName);
    let newColumnState;
    const defaultCustomColumns = getCustomPresetInitialState(reportConfig);
    const requiredCustomColumns = getRequiredCustomColumns(reportConfig);
    const defaultCustomColumnsSet = new Set(defaultCustomColumns);
    const requiredCustomColumnsSet = new Set(requiredCustomColumns);
    const newColumnVisibilityMapper = ({ colId, hide, ...rest }) => ({
        ...rest,
        colId,
        hide: isCustomPreset ? !(requiredCustomColumnsSet.has(colId) || defaultCustomColumnsSet.has(colId)) : false,
    });

    // All new columns from column defs which are missed in column state, should be placed
    // in column state right after it predecessor in column view config
    const reportPresetList = getReportPresetOrderedList(reportConfig, reportPresetName);
    newColumnState = getColumnStateWithNewAddedColumns({
        columnState,
        columnDefs,
        reportPresetList,
        reportPresetName,
        newColumnVisibilityMapper,
    });
    // We need to keep all configured and dynamic columns state, and remove obsolete columns (which are not in the column defs)
    const columnsToKeep = pickColumnsToKeepColumnState({
        columnState,
        currentColumns: columnDefs,
        allConfigColumnDefs: reportConfig.columnsDef,
        reportPresetName,
    });
    const columnsToKeepSet = new Set(columnsToKeep.map(({ colId }) => colId));
    const removedColumnsSet = new Set(differenceBy(columnState, columnDefs, COL_ID_FIELD).map(({ colId }) => colId));
    newColumnState = newColumnState.filter(({ colId }) => !removedColumnsSet.has(colId) || columnsToKeepSet.has(colId));

    const untouchableColumnSet = new Set([...requiredCustomColumnsSet.values(), ...columnsToKeepSet.values()]);

    const finalColumnState = selectedColumns
        ? getVisibleSelectedColumns(newColumnState, selectedColumns, untouchableColumnSet)
        : newColumnState;

    return finalColumnState;
};
