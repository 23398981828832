class Config {
    init(config) {
        this.gtmContainerId = config.gtmContainerId || 'GTM-MFNMFXK';
        this.sendClientSideErrorsWithStacktrace = config.sendClientSideErrorsWithStacktrace;
    }

    getSendClientSideErrorsWithStacktrace() {
        return this.sendClientSideErrorsWithStacktrace;
    }
}

export default new Config();
