"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEvent(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M18.826 14.172l-.841-.865a3 3 0 10-4.98 1.933v1.228a4 4 0 115.82-2.296z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M21.073 16.483a7 7 0 10-8.064 3.23l.001 1.038a8 8 0 118.784-3.525l-.721-.742z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M15.002 13.109l.014 13.217 2.757-3.671a2 2 0 011.907-.775l4.536.705-9.214-9.476zm-.986 13.218l-.014-13.217c0-.898 1.091-1.342 1.717-.698l9.214 9.476c.67.688.078 1.832-.87 1.685l-4.537-.705a1 1 0 00-.953.387l-2.757 3.671c-.577.768-1.799.361-1.8-.6z",
            fill: "#212832"
        })
    );
}
exports.default = SvgEvent;