'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var calendarLabel = 'Calendar';
var closeDatePicker = 'Close';
var focusStartDate = 'Interact with the calendar and add the start date.';
var clearDate = 'Clear Date';
var clearDates = 'Clear Dates';
var jumpToPrevMonth = 'Move backward to switch to the previous month.';
var jumpToNextMonth = 'Move forward to switch to the next month.';
var keyboardShortcuts = 'Keyboard Shortcuts';
var showKeyboardShortcutsPanel = 'Open the keyboard shortcuts panel.';
var hideKeyboardShortcutsPanel = 'Close the shortcuts panel.';
var openThisPanel = 'Open this panel.';
var enterKey = 'Enter key';
var leftArrowRightArrow = 'Right and left arrow keys';
var upArrowDownArrow = 'up and down arrow keys';
var pageUpPageDown = 'page up and page down keys';
var homeEnd = 'Home and end keys';
var escape = 'Escape key';
var questionMark = 'Question mark';
var selectFocusedDate = 'Select the date in focus.';
var moveFocusByOneDay = 'Move backward (left) and forward (right) by one day.';
var moveFocusByOneWeek = 'Move backward (up) and forward (down) by one week.';
var moveFocusByOneMonth = 'Switch months.';
var moveFocustoStartAndEndOfWeek = 'Go to the first or last day of a week.';
var returnFocusToInput = 'Return to the date input field.';
var keyboardNavigationInstructions = 'Press the down arrow key to interact with the calendar and\n  select a date. Press the question mark key to get the keyboard shortcuts for changing dates.';

var chooseAvailableStartDate = function chooseAvailableStartDate(_ref) {
    var date = _ref.date;
    return date;
};
var chooseAvailableEndDate = function chooseAvailableEndDate(_ref2) {
    var date = _ref2.date;
    return date;
};
var chooseAvailableDate = function chooseAvailableDate(_ref3) {
    var date = _ref3.date;
    return date;
};
var dateIsUnavailable = function dateIsUnavailable(_ref4) {
    var date = _ref4.date;
    return 'Not available. ' + date;
};
var dateIsSelected = function dateIsSelected(_ref5) {
    var date = _ref5.date;
    return 'Selected. ' + date;
};

exports.default = {
    calendarLabel: calendarLabel,
    closeDatePicker: closeDatePicker,
    focusStartDate: focusStartDate,
    clearDate: clearDate,
    clearDates: clearDates,
    jumpToPrevMonth: jumpToPrevMonth,
    jumpToNextMonth: jumpToNextMonth,
    keyboardShortcuts: keyboardShortcuts,
    showKeyboardShortcutsPanel: showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel: hideKeyboardShortcutsPanel,
    openThisPanel: openThisPanel,
    enterKey: enterKey,
    leftArrowRightArrow: leftArrowRightArrow,
    upArrowDownArrow: upArrowDownArrow,
    pageUpPageDown: pageUpPageDown,
    homeEnd: homeEnd,
    escape: escape,
    questionMark: questionMark,
    selectFocusedDate: selectFocusedDate,
    moveFocusByOneDay: moveFocusByOneDay,
    moveFocusByOneWeek: moveFocusByOneWeek,
    moveFocusByOneMonth: moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek: moveFocustoStartAndEndOfWeek,
    returnFocusToInput: returnFocusToInput,
    keyboardNavigationInstructions: keyboardNavigationInstructions,

    chooseAvailableStartDate: chooseAvailableStartDate,
    chooseAvailableEndDate: chooseAvailableEndDate,
    dateIsUnavailable: dateIsUnavailable,
    dateIsSelected: dateIsSelected
};
var DateRangePickerPhrases = exports.DateRangePickerPhrases = {
    calendarLabel: calendarLabel,
    closeDatePicker: closeDatePicker,
    clearDates: clearDates,
    focusStartDate: focusStartDate,
    jumpToPrevMonth: jumpToPrevMonth,
    jumpToNextMonth: jumpToNextMonth,
    keyboardShortcuts: keyboardShortcuts,
    showKeyboardShortcutsPanel: showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel: hideKeyboardShortcutsPanel,
    openThisPanel: openThisPanel,
    enterKey: enterKey,
    leftArrowRightArrow: leftArrowRightArrow,
    upArrowDownArrow: upArrowDownArrow,
    pageUpPageDown: pageUpPageDown,
    homeEnd: homeEnd,
    escape: escape,
    questionMark: questionMark,
    selectFocusedDate: selectFocusedDate,
    moveFocusByOneDay: moveFocusByOneDay,
    moveFocusByOneWeek: moveFocusByOneWeek,
    moveFocusByOneMonth: moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek: moveFocustoStartAndEndOfWeek,
    returnFocusToInput: returnFocusToInput,
    keyboardNavigationInstructions: keyboardNavigationInstructions,
    chooseAvailableStartDate: chooseAvailableStartDate,
    chooseAvailableEndDate: chooseAvailableEndDate,
    dateIsUnavailable: dateIsUnavailable,
    dateIsSelected: dateIsSelected
};

var DateRangePickerInputPhrases = exports.DateRangePickerInputPhrases = {
    focusStartDate: focusStartDate,
    clearDates: clearDates,
    keyboardNavigationInstructions: keyboardNavigationInstructions
};

var SingleDatePickerPhrases = exports.SingleDatePickerPhrases = {
    calendarLabel: calendarLabel,
    closeDatePicker: closeDatePicker,
    clearDate: clearDate,
    jumpToPrevMonth: jumpToPrevMonth,
    jumpToNextMonth: jumpToNextMonth,
    keyboardShortcuts: keyboardShortcuts,
    showKeyboardShortcutsPanel: showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel: hideKeyboardShortcutsPanel,
    openThisPanel: openThisPanel,
    enterKey: enterKey,
    leftArrowRightArrow: leftArrowRightArrow,
    upArrowDownArrow: upArrowDownArrow,
    pageUpPageDown: pageUpPageDown,
    homeEnd: homeEnd,
    escape: escape,
    questionMark: questionMark,
    selectFocusedDate: selectFocusedDate,
    moveFocusByOneDay: moveFocusByOneDay,
    moveFocusByOneWeek: moveFocusByOneWeek,
    moveFocusByOneMonth: moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek: moveFocustoStartAndEndOfWeek,
    returnFocusToInput: returnFocusToInput,
    keyboardNavigationInstructions: keyboardNavigationInstructions,
    chooseAvailableDate: chooseAvailableDate,
    dateIsUnavailable: dateIsUnavailable,
    dateIsSelected: dateIsSelected
};

var SingleDatePickerInputPhrases = exports.SingleDatePickerInputPhrases = {
    clearDate: clearDate,
    keyboardNavigationInstructions: keyboardNavigationInstructions
};

var DayPickerPhrases = exports.DayPickerPhrases = {
    calendarLabel: calendarLabel,
    jumpToPrevMonth: jumpToPrevMonth,
    jumpToNextMonth: jumpToNextMonth,
    keyboardShortcuts: keyboardShortcuts,
    showKeyboardShortcutsPanel: showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel: hideKeyboardShortcutsPanel,
    openThisPanel: openThisPanel,
    enterKey: enterKey,
    leftArrowRightArrow: leftArrowRightArrow,
    upArrowDownArrow: upArrowDownArrow,
    pageUpPageDown: pageUpPageDown,
    homeEnd: homeEnd,
    escape: escape,
    questionMark: questionMark,
    selectFocusedDate: selectFocusedDate,
    moveFocusByOneDay: moveFocusByOneDay,
    moveFocusByOneWeek: moveFocusByOneWeek,
    moveFocusByOneMonth: moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek: moveFocustoStartAndEndOfWeek,
    returnFocusToInput: returnFocusToInput,
    chooseAvailableStartDate: chooseAvailableStartDate,
    chooseAvailableEndDate: chooseAvailableEndDate,
    chooseAvailableDate: chooseAvailableDate,
    dateIsUnavailable: dateIsUnavailable,
    dateIsSelected: dateIsSelected
};

var DayPickerKeyboardShortcutsPhrases = exports.DayPickerKeyboardShortcutsPhrases = {
    keyboardShortcuts: keyboardShortcuts,
    showKeyboardShortcutsPanel: showKeyboardShortcutsPanel,
    hideKeyboardShortcutsPanel: hideKeyboardShortcutsPanel,
    openThisPanel: openThisPanel,
    enterKey: enterKey,
    leftArrowRightArrow: leftArrowRightArrow,
    upArrowDownArrow: upArrowDownArrow,
    pageUpPageDown: pageUpPageDown,
    homeEnd: homeEnd,
    escape: escape,
    questionMark: questionMark,
    selectFocusedDate: selectFocusedDate,
    moveFocusByOneDay: moveFocusByOneDay,
    moveFocusByOneWeek: moveFocusByOneWeek,
    moveFocusByOneMonth: moveFocusByOneMonth,
    moveFocustoStartAndEndOfWeek: moveFocustoStartAndEndOfWeek,
    returnFocusToInput: returnFocusToInput
};

var DayPickerNavigationPhrases = exports.DayPickerNavigationPhrases = {
    jumpToPrevMonth: jumpToPrevMonth,
    jumpToNextMonth: jumpToNextMonth
};

var CalendarDayPhrases = exports.CalendarDayPhrases = {
    chooseAvailableDate: chooseAvailableDate,
    dateIsUnavailable: dateIsUnavailable,
    dateIsSelected: dateIsSelected
};