import { useQuery } from 'react-query';
import { useSelectedAccount } from 'hooks';
import { useTrackingApi } from 'services/api/trackingApi';
import { useFormDataContext } from '../../taboola-common-frontend-modules/formData';

const emptyObj = {};
export const useTrackingState = (isPixelWarningPermitted = true) => {
    const { getTrackingStatus } = useTrackingApi();
    const [{ accountName, id: selectedAccountId, type: accountType }] = useSelectedAccount();
    const dataContext = useFormDataContext();
    const formAccountName = dataContext?.formAccount?.accountId;

    const { data: trackingStateData = emptyObj, isLoading: isLoadingTrackingState } = useQuery(
        ['getTrackingStatus', accountName],
        () => getTrackingStatus(accountName),
        {
            enabled: !!accountName && isPixelWarningPermitted,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5, // 5 minutes
        }
    );

    const { data: formAccountTrackingStateData = emptyObj, isLoading: isLoadingFormAccountTrackingState } = useQuery(
        ['getTrackingStatus', formAccountName],
        () => getTrackingStatus(formAccountName),
        {
            enabled: !!formAccountName && isPixelWarningPermitted,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        }
    );

    return {
        trackingStateData,
        isLoadingTrackingState,
        selectedAccountId,
        formAccountTrackingStateData,
        isLoadingFormAccountTrackingState,
        accountType,
    };
};
