import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDetectAdBlock } from 'adblock-detect-react';
import { getAdBlockerDetectedIndication } from 'indications';
import { addStaticIndication, removeStaticIndication } from 'modules/taboola-common-frontend-modules/Indications';

export const useAdBlockDetector = () => {
    const hasAdBlock = useDetectAdBlock();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasAdBlock) {
            return;
        }

        dispatch(addStaticIndication(getAdBlockerDetectedIndication()));
        return () => {
            dispatch(removeStaticIndication(getAdBlockerDetectedIndication()));
        };
    }, [hasAdBlock, dispatch]);
};

export default useAdBlockDetector;
