import { useQuery } from 'react-query';
import { get } from 'lodash';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import { useCampaignsApi } from '../../../../../../../../services/api';
import { FEATURE_FLAGS } from '../../../../../../../taboola-common-frontend-modules/account-configurations';
import { useConfigMatch } from '../../../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from '../../../../../../../taboola-common-frontend-modules/formData';
import { useFormatTypeFormFieldValueCreateMode } from '../../../../hooks/useFormatTypeFormFieldValueCreateMode';

const useServerCtaData = () => {
    const isRecommendedCtaEnabled = useConfigMatch({
        [FEATURE_FLAGS.DEFAULT_CTA_ENABLED]: 'true',
    });
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();
    const isAppInstallFormatSelected = formatType === CREATIVE_FORMAT_TYPE.APP_INSTALL;
    const { formAccount } = useFormDataContext();
    const { value: campaign } = useCampaignsFormFieldValue();
    const { accountId } = formAccount;
    const operationsApi = useCampaignsApi();
    const campaignId = get(campaign, [0, 'id']);
    const { data = {} } = useQuery(
        ['serverCTAData', campaignId],
        () => operationsApi.getDefaultRecommendedCta(accountId, campaignId),
        {
            enabled: !!campaignId && isRecommendedCtaEnabled && isAppInstallFormatSelected,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            retry: 1,
        }
    );

    return data;
};

export default useServerCtaData;
