import React from 'react';
import { useCustomContextualPermitted } from '../../../../../audiences/hooks/useCustomContextualPermitted';
import AudienceTargetingSectionLegacy from '../AudienceTargetingSectionLegacy/AudienceTargetingSectionLegacy';
import { AudienceTargetingSection } from './AudienceTargetingSection';
import { ContextualTargetingSection } from './ContextualTargetingSection';

export const AudienceTargetingSectionCampaignEditor = () => {
    const isCustomContextualPermitted = useCustomContextualPermitted();

    if (isCustomContextualPermitted) {
        return (
            <>
                <ContextualTargetingSection />
                <AudienceTargetingSection />
            </>
        );
    }
    return <AudienceTargetingSectionLegacy />;
};
