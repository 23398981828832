import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Highlighter } from 'taboola-ultimate-ui';
import styles from './nodeLabel.module.scss';

const classNameBuilder = classnames.bind(styles);

const NodeLabel = ({ node, searchText, title, depth }) => {
    const { loading } = node;
    if (loading) {
        return (
            <ContentLoader width={400} height={30} speed={2} className={styles['loader']}>
                <rect x="15" y="8" rx="8" ry="8" width="250" height="12" />
            </ContentLoader>
        );
    }

    return (
        <div className={classNameBuilder('label', { parent: !isEmpty(node.nodes) || depth === 0 })}>
            <Highlighter text={title} highlightedText={searchText} />
        </div>
    );
};

NodeLabel.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    node: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    searchText: PropTypes.string,
    depth: PropTypes.number,
};

export default NodeLabel;
