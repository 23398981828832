import React from 'react';
import { ToggleSwitch } from 'tuui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getCreativeStatusMessages } from '../../../../../services/utils';
import styles from './creativeEditorFormStatus.module.scss';

const CreativeEditorFormStatus = ({ disabled }) => {
    const { value: isActive = false, onChange } = useFormFieldValue({ field: 'isActive' });
    const { value: status } = useFormFieldValue({ field: 'status' });
    const { formattedStatusMsg } = getCreativeStatusMessages(status);

    return (
        <div className={styles['container']}>
            <span data-metrics-event-name={GTM_EVENTS.USABILITY} data-metrics-component="Status">
                <ToggleSwitch checked={isActive} onChange={onChange} disabled={disabled} />
            </span>
            <span className={styles['status']}>{formattedStatusMsg}</span>
        </div>
    );
};

export default CreativeEditorFormStatus;
