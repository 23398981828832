import React from 'react';
import classnames from 'classnames/bind';
import { CollapsibleCard, DropdownLargeIcon } from 'taboola-ultimate-ui';
import { useCollapsedState } from 'components/CommonCollapsibleCard/useCollapsedState';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from '../../modules/taboola-common-frontend-modules/formData';
import styles from './collapsibleFormSection.module.scss';

const classNameBuilder = classnames.bind(styles);

const DropdownIcon = props => (
    <div className={styles['iconContainer']}>
        <DropdownLargeIcon width={11} height={11} {...props} />
    </div>
);

export const CollapsibleFormSection = ({ title, subtitle, sectionId, children, isInitiallyCollapsed, testId }) => {
    const { mode } = useFormDataContext();
    const isPersisted = mode === FORM_MODES.CREATE;
    const [isCollapsed, setIsCollapsed] = useCollapsedState({
        isPersisted,
        sectionId,
        isInitiallyCollapsed,
    });

    return (
        <div data-testid={testId}>
            <CollapsibleCard
                collapsed={isCollapsed}
                withAnimation={true}
                setCollapsed={setIsCollapsed}
                header={
                    <div>
                        <div className={styles['headerTitle']}>{title}</div>
                        <div className={styles['headerSubtitle']}>{subtitle}</div>
                    </div>
                }
                containerClassName={styles['container']}
                headerClassName={classNameBuilder('header', { collapsed: isCollapsed })}
                bodyClassName={styles['body']}
                iconClassName={styles['icon']}
                IconComponent={DropdownIcon}
            >
                {children}
            </CollapsibleCard>
        </div>
    );
};

export default CollapsibleFormSection;
