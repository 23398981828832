'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _windowSize = require('@react-hook/window-size');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _styleUtils = require('../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

var _hooks = require('../../hooks');

var _useCollapseAnimation3 = require('./useCollapseAnimation');

var _useCollapseAnimation4 = _interopRequireDefault(_useCollapseAnimation3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'panel': 'collapsible__panel___2Kk3c',
    'vertical': 'collapsible__vertical___1kVqE',
    'size-transition': 'collapsible__size-transition___XH-C3',
    'collapsed': 'collapsible__collapsed___1Zn23',
    'animation-open': 'collapsible__animation-open___3v2Vy',
    'children-wrapper': 'collapsible__children-wrapper___2C6ea',
    'horizontal': 'collapsible__horizontal___1-XuB'
};


var ROOT_CLASS = 'panel';

var classNameBuilder = _bind2.default.bind(styles);

var Directions = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
};

var Collapsible = (0, _react.forwardRef)(function (_ref, ref) {
    var collapsed = _ref.collapsed,
        direction = _ref.direction,
        children = _ref.children,
        className = _ref.className,
        collapsedSize = _ref.collapsedSize,
        transitionDuration = _ref.transitionDuration,
        fitToScreen = _ref.fitToScreen,
        withAnimation = _ref.withAnimation,
        style = _ref.style;

    var _useElementSize = (0, _hooks.useElementSize)(),
        childrenRef = _useElementSize.ref,
        elementSize = _objectWithoutProperties(_useElementSize, ['ref']);

    var _useWindowSize = (0, _windowSize.useWindowSize)(),
        _useWindowSize2 = _slicedToArray(_useWindowSize, 2),
        windowWidth = _useWindowSize2[0],
        windowHeight = _useWindowSize2[1];

    var _useCollapseAnimation = (0, _useCollapseAnimation4.default)(collapsed, ref),
        _useCollapseAnimation2 = _slicedToArray(_useCollapseAnimation, 2),
        collapsibleRef = _useCollapseAnimation2[0],
        animationOpen = _useCollapseAnimation2[1];

    var _ref2 = fitToScreen ? { height: windowHeight, width: windowWidth } : elementSize,
        height = _ref2.height,
        width = _ref2.width;

    var expandedSize = direction === Directions.VERTICAL ? height : width;
    var styleVars = (0, _react.useMemo)(function () {
        return (0, _styleUtils.cssVars)({
            collapsedSize: (0, _styleUtils2.default)(collapsedSize),
            expandedSize: (0, _styleUtils2.default)(expandedSize || collapsedSize),
            duration: transitionDuration + 'ms'
        });
    }, [collapsedSize, expandedSize, transitionDuration]);
    var sectionClassName = classNameBuilder(ROOT_CLASS, direction, className, {
        collapsed: collapsed,
        'animation-open': animationOpen || !withAnimation && !collapsed,
        'size-transition': withAnimation
    });

    return _react2.default.createElement(
        'section',
        {
            ref: collapsibleRef,
            style: Object.assign({}, styleVars, style),
            className: sectionClassName
        },
        _react2.default.createElement(
            'div',
            {
                ref: childrenRef,
                className: classNameBuilder('children-wrapper'),
                style: style
            },
            children
        )
    );
});

Collapsible.DIRECTION = Directions;

Collapsible.propTypes = {
    collapsedSize: _propTypes2.default.number,
    transitionDuration: _propTypes2.default.number,
    collapsed: _propTypes2.default.bool,
    className: _propTypes2.default.string,
    direction: _propTypes2.default.oneOf(Object.values(Directions)),
    children: _propTypes2.default.node,
    fitToScreen: _propTypes2.default.bool,
    withAnimation: _propTypes2.default.bool,
    style: _propTypes2.default.object
};

Collapsible.defaultProps = {
    collapsedSize: 0,
    transitionDuration: 250,
    direction: Directions.VERTICAL,
    withAnimation: true
};

exports.default = Collapsible;