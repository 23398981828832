export const TARGETING_TYPES = {
    AND: 'AND',
    OR: 'OR',
    EXCLUDE: 'EXCLUDE',
};

export const TARGETING_OPTIONS = [
    { value: TARGETING_TYPES.OR },
    { value: TARGETING_TYPES.AND },
    { value: TARGETING_TYPES.EXCLUDE },
];
