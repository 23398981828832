import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { isPmpAccount } from '../../../../../account-management/accountType';
import { ENTITY_MESSAGE_PREFIX } from '../../../../config';
import { Representatives } from '../../../common-campaign-form';
import { AudienceTargetingSectionCampaignEditor } from '../../../common-campaign-form/components/AudienceTargetingSection/AudienceTargetingSectionCampaignEditor';
import { InventorySection } from '../InventorySection/InventorySection';
import BudgetSectionVideoPmpDeal from '../PMPBudgetSection/BudgetSectionVideoPmpDeal';
import { ScheduleSection } from '../ScheduleSection/ScheduleSection';
import { SetupSection } from '../SetupSection/SetupSection';
import { TargetingSection } from '../TargetingSection/TargetingSection';
import DealId from '../VideoDealId/DealId';

export const VideoPMPDealEditorContent = ({ shouldRenderAccountDropdown }) => {
    const { formAccount } = useFormDataContext();
    const accountFilter = useCallback(account => isPmpAccount(account), []);

    return (
        <>
            <SetupSection
                isAccountSelectionEnabled={shouldRenderAccountDropdown}
                accountFilter={accountFilter}
                messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL}
                noAccountsMessageId="pmpDeal.editor.setup.no.pmp.deal.accounts"
            >
                <DealId />
                <Representatives />
            </SetupSection>
            <ScheduleSection />
            <TargetingSection />
            <AudienceTargetingSectionCampaignEditor />
            <InventorySection account={formAccount} />
            <BudgetSectionVideoPmpDeal account={formAccount} />
        </>
    );
};

VideoPMPDealEditorContent.propTypes = {
    account: PropTypes.object,
    onAccountChange: PropTypes.func,
};

export default VideoPMPDealEditorContent;
