import { useLayoutEffect } from 'react';
import JSConfetti from 'js-confetti';
import { Button, CheckCircleOutlineIcon } from 'tuui';
import { useNavigate } from '../../../../../hooks';
import { CAMPAIGNS_ROUTE } from '../../../../campaigns/config';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../../../taboola-common-frontend-modules/modals';
import { useLocalStorage } from '../../../../taboola-common-frontend-modules/storage';
import { ONBOARDING_CHAT_DONE_KEY } from '../../../config/onboardingSkipKey';
import styles from './SetupSuccessModal.module.scss';

const jsConfetti = new JSConfetti();

export const SetupSuccessModal = () => {
    const navigate = useNavigate();
    const { close } = useModal();
    const [, setLocalStorage] = useLocalStorage();

    useLayoutEffect(() => {
        jsConfetti.addConfetti();
    }, []);

    return (
        <div className={styles['wrapper']}>
            <span className={styles['icon-wrapper']}>
                <CheckCircleOutlineIcon />
            </span>
            <FormattedMessage
                id="onboarding.form.setup.complete.title"
                defaultMessage="Congratulations! You're All Set!"
                className={styles['title']}
            />
            <FormattedMessage
                id="onboarding.form.setup.complete.content"
                defaultMessage="Explore the platform while your ads are being reviewed."
                className={styles['content']}
            />
            <Button
                onClick={() => {
                    setLocalStorage(ONBOARDING_CHAT_DONE_KEY, 'true');
                    navigate(CAMPAIGNS_ROUTE);
                    close();
                }}
                className={styles['download-button']}
            >
                <FormattedMessage id="app.actionButtons.continue" defaultMessage="Continue" />
            </Button>
        </div>
    );
};
