'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPortal = require('react-portal');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'overlay': 'backgroundOverlay__overlay___OkaBM'
};


var classNameBuilder = _bind2.default.bind(styles);

var BackgroundOverlay = function BackgroundOverlay(_ref) {
    var className = _ref.className,
        onOutsideClick = _ref.onOutsideClick,
        foregroundElementRef = _ref.foregroundElementRef,
        onClick = _ref.onClick;

    var handleClick = function handleClick(event) {
        var current = foregroundElementRef.current;


        if (!current || !current.contains(event.target)) {
            onOutsideClick();
        }
    };

    (0, _react.useEffect)(function () {
        document.addEventListener('mousedown', handleClick, true);
        return function () {
            document.removeEventListener('mousedown', handleClick, true);
        };
    }, []);

    return _react2.default.createElement(
        _reactPortal.Portal,
        null,
        _react2.default.createElement('div', {
            role: 'presentation',
            className: classNameBuilder('overlay', className),
            onClick: onClick
        })
    );
};

BackgroundOverlay.propTypes = {
    /** Click callback for the overlay */
    onClick: _propTypes2.default.func,
    /** Additional styling for the overlay */
    className: _propTypes2.default.string,
    /** Function that runs if there is a click outside the foreground */
    onOutsideClick: _propTypes2.default.func,
    /** The foreground element, used to define outside clicks */
    foregroundElementRef: _propTypes2.default.object
};

BackgroundOverlay.defaultProps = {
    onClick: function onClick() {},
    onOutsideClick: function onOutsideClick() {}
};

exports.default = BackgroundOverlay;