import { useCallback } from 'react';
import { filter } from 'lodash';
import { useAudiencesApi } from '../../../../../services/api';
import { generatePath } from '../../../../campaigns/modules/common-campaign-form/components/Tree';

const fetchSegmentsApi = async ({ selectedAccountId, queryParams, options, getCombinedAudienceIngredients }) => {
    const accountId = selectedAccountId || {};

    return getCombinedAudienceIngredients(accountId, queryParams, options);
};

const transformSegments = (segments = []) =>
    segments.map(segment => {
        const { id, taxonomy } = segment;
        return { path: generatePath(id, taxonomy), ...segment };
    });

const transformTotals = (totalsPerTaxonomy = []) =>
    totalsPerTaxonomy.reduce(
        (obj, item) => ({
            ...obj,
            [item.taxonomy]: item.total,
        }),
        {}
    );

export const useCombinedSegmentsWithBundlingFetcher = ({
    search,
    selectedAccountId,
    taxonomies,
    chargeType,
    searchMode,
}) => {
    const { getCombinedAudienceIngredients } = useAudiencesApi();

    const fetchSegments = useCallback(async () => {
        const queryParams = {
            searchText: search,
            chargeType,
            searchMode,
            taxonomies,
            bundlesMode: true,
        };

        try {
            const { results, metadata } = await fetchSegmentsApi({
                selectedAccountId,
                queryParams,
                getCombinedAudienceIngredients,
            });

            return {
                results: transformSegments(results),
                transformedTotals: transformTotals(metadata),
            };
        } catch (e) {
            // empty
        }
        return {
            results: [],
            transformedTotals: {},
        };
    }, [search, chargeType, searchMode, taxonomies, selectedAccountId, getCombinedAudienceIngredients]);

    const fetchSegmentsByIds = useCallback(
        async ids => {
            const queryParams = {
                ids,
            };

            try {
                const { results } = await fetchSegmentsApi({
                    selectedAccountId,
                    queryParams,
                    getCombinedAudienceIngredients,
                });

                return transformSegments(filter(results, ({ id: segmentId }) => ids.includes(segmentId)));
            } catch (e) {
                // TODO: add error indication
            }

            return [];
        },
        [selectedAccountId, getCombinedAudienceIngredients]
    );

    return {
        fetchSegments,
        fetchSegmentsByIds,
    };
};
