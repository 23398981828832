import { FORM_MODES } from 'config/formModes';
import { CONVERSIONS_EDITOR_ROUTE_PATH, ENTITY, FUNNELS_EDITOR_ROUTE_PATH } from 'modules/tracking/config';
import { generateConversionFormPath, generateFunnelFormPath } from './generateTrackingFormPath';

export const generateSettingsEditorPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.TRACKING_SETTINGS,
        mode: FORM_MODES.EDIT,
    });

export const generateConversionCreatorPath = () =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.CONVERSION,
        mode: FORM_MODES.CREATE,
    });

export const generateConversionFunnelCreatorPath = () =>
    generateFunnelFormPath(FUNNELS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.FUNNEL,
        mode: FORM_MODES.CREATE,
    });

export const generateConversionEditorPath = (accountId, conversionId) =>
    generateConversionFormPath(CONVERSIONS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.CONVERSION,
        mode: FORM_MODES.EDIT,
        accountId,
        conversionId,
    });

export const generateConversionFunnelEditorPath = (accountId, funnelId) =>
    generateFunnelFormPath(FUNNELS_EDITOR_ROUTE_PATH, {
        entity: ENTITY.FUNNEL,
        mode: FORM_MODES.EDIT,
        accountId,
        funnelId,
    });
