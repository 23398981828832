"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCode(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M13.987 6.202a1 1 0 00-1.973-.331l-2 11.927a1 1 0 101.973.33l2-11.926zm1.873 11.066a1 1 0 01-.128-1.409L19 12l-3.268-3.86a1 1 0 111.536-1.28l3.35 4.02a1.75 1.75 0 010 2.24l-3.35 4.02a1 1 0 01-1.408.128zM8.14 6.732a1 1 0 01.13 1.408L5 12l3.269 3.86a1 1 0 11-1.537 1.28l-3.35-4.02a1.75 1.75 0 010-2.24l3.35-4.02a1 1 0 011.409-.128z",
            fill: "#667686"
        })
    );
}
exports.default = SvgCode;