import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import {
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
} from '../../../components/HeaderRenderers';
import { formattedNumberValueGetter, gridNumberFormatter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import { alwaysVisibleColumnType, leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const osVersionReportColumnDefinition = [
    {
        headerName: FIELDS.PLATFORM_CODE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.PLATFORM_CODE.headerComponentParams,
        field: FIELDS.PLATFORM_CODE.field,
        type: [leftPinnedColumn, alwaysVisibleColumnType],
        cellRendererFramework: FIELDS.PLATFORM_CODE.cellRendererFramework,
        cellRendererParams: FIELDS.PLATFORM_CODE.renderParams,
        csvValueGetter: FIELDS.PLATFORM_CODE.csvValueGetter,
    },
    {
        headerName: FIELDS.OS_VERSION.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.OS_VERSION.headerComponentParams,
        field: FIELDS.OS_VERSION.field,
        type: [leftPinnedColumn, alwaysVisibleColumnType],
        csvValueGetter: FIELDS.OS_VERSION.csvValueGetter,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CPA,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(osVersionReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.OS_VERSION_VIDEO].id);
