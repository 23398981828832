import React from 'react';
import { Button, SelectAllIcon } from 'tuui';
import { NewUserPopupTooltip } from 'modules/campaigns/components/NewUserPopupTooltip/NewUserPopupTooltip';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    CREATIVE_STUDIO_EVENT_PREFIX,
    CREATIVE_STUDIO_MODE_EVENTS_MAP,
    SEGMENT_ANYTHING_EVENT_PREFIX,
} from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import styles from './replaceBackgroundSegmentAnythingToggle.module.scss';

export const ReplaceBackgroundSegmentAnythingToggle = ({ setIsSegmentSelectionActive }) => {
    const { campaignId, mode } = useCreativeStudioContext();
    const isPopupEnabled = useConfigMatch({
        [FEATURE_FLAGS.REPLACE_IMAGE_BACKGROUND_SEGMENT_ANYTHING_POPUP]: 'true',
    });

    const SEGMENT_EVENTS_PREFIX = `${CREATIVE_STUDIO_EVENT_PREFIX}_${CREATIVE_STUDIO_MODE_EVENTS_MAP[mode]}_${SEGMENT_ANYTHING_EVENT_PREFIX}`;

    const sendSelectSubjectClickEvent = () => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${SEGMENT_EVENTS_PREFIX}: Select Subject Clicked`,
            taboolaCampaignId: campaignId,
        });
    };

    return (
        <div className={styles['segment-toggle-container']}>
            {isPopupEnabled && (
                <NewUserPopupTooltip
                    popupOrigin="segmentAnything"
                    tooltipBodyText={
                        <FormattedMessage
                            id="creative.studio.replace.background.manual.foreground.toggle.tooltip"
                            defaultMessage="Now you can select the main object you want to keep by clicking on the object when generating a new background"
                        />
                    }
                />
            )}

            <Button
                onClick={() => {
                    setIsSegmentSelectionActive(v => !v);
                    sendSelectSubjectClickEvent();
                }}
                className={styles['segment-toggle']}
            >
                <SelectAllIcon />
                <FormattedMessage
                    id="creative.studio.replace.background.manual.foreground.toggle"
                    defaultMessage="Select Subject"
                />
            </Button>
        </div>
    );
};
