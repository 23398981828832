import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import classnames from 'classnames/bind';
import { isEmpty, noop } from 'lodash';
import { Spinner } from 'tuui';
import { OkOIcon } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { addProtocol } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import { OutlineEditableText } from '../../../../components/OutlineEditableText/OutlineEditableText';
import { SubmitAnyway } from '../../../../components/SubmitAnyway/SubmitAnyway';
import { urlValidations } from '../../../../config';
import styles from './urlValidatedInput.module.scss';

const classNameBuilder = classnames.bind(styles);

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);

export const BYPASS_URL_RESPONSE_VALIDATION_FIELD = 'bypassUrlResponseValidation';

export const UrlValidatedInput = ({
    field = 'url',
    validations: validationsProp = urlValidations,
    validationDependencies: validationDependenciesProp,
    children,
    className,
    disabled,
    disabledPlaceholder,
    isResetFieldToInitialOnUnmount = false,
    onSubmitAnyway: onSubmitAnywayProp = noop,
    afterOnBlur,
    ...rest
}) => {
    const { validationService } = useValidationContext();
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({ id: 'creative.editor.content.url.placeholder', defaultMessage: 'Enter URL' });
    const {
        formAccount: { accountId: formAccountId },
    } = useFormDataContext();
    const { value: bypassUrlResponseValidation, onChange: setBypassUrlResponseValidation } = useFormFieldValue({
        field: BYPASS_URL_RESPONSE_VALIDATION_FIELD,
    });
    const submitAnyway = useMemo(
        () => (
            <SubmitAnyway
                onClick={() => {
                    setBypassUrlResponseValidation(true);
                    onSubmitAnywayProp();
                }}
            />
        ),
        [onSubmitAnywayProp, setBypassUrlResponseValidation]
    );

    const accessibleUrlCache = useRef();
    const validations = useMemo(() => validationsProp, [validationsProp]);
    const validationDependencies = useMemo(
        () => ({
            ...validationDependenciesProp,
            formAccountId,
            bypassUrlResponseValidation,
            accessibleUrlCache,
            submitAnyway,
        }),
        [validationDependenciesProp, formAccountId, bypassUrlResponseValidation, submitAnyway]
    );
    const {
        value: url,
        onChange: onChangeUrl,
        indicationData,
        isValidationsRunning,
        validationId,
        resetFieldToInitial,
    } = useFormValidatedValue({
        field,
        validations,
        validationDependencies,
    });

    useEffect(() => {
        return () => isResetFieldToInitialOnUnmount && resetFieldToInitial();
    }, [isResetFieldToInitialOnUnmount, resetFieldToInitial]);

    const [cacheValue, setCacheValue] = useState(url);
    const [showSuccessIcon, setShowSuccessIcon] = useState(false);
    const onChange = useCallback(value => {
        setShowSuccessIcon(false);
        setCacheValue(value);
    }, []);
    const onBlur = useCallback(() => {
        onChangeUrl(addProtocol(cacheValue));

        if (!afterOnBlur) {
            return;
        }

        afterOnBlur();
    }, [afterOnBlur, cacheValue, onChangeUrl]);

    useEffect(() => {
        const unlisten = validationService.onValid(validationId, () => {
            if (isEmpty(cacheValue)) {
                return;
            }
            setShowSuccessIcon(true);
        });

        return unlisten;
    }, [validationService, validationId, cacheValue, setShowSuccessIcon]);

    return (
        <OutlineEditableTextWithIndication
            id={validationId}
            value={disabled && disabledPlaceholder ? disabledPlaceholder : cacheValue}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            className={classNameBuilder('input', className)}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="URL"
            disabled={disabled}
            blurOnEnter
            {...indicationData}
            {...rest}
        >
            {!disabled && isValidationsRunning ? <Spinner size={16} className={styles['spinner']} /> : null}
            {!disabled && showSuccessIcon ? (
                <OkOIcon data-testid="okIcon" className={styles['success-icon']} aria-label="Validated url icon" />
            ) : null}
            {children}
        </OutlineEditableTextWithIndication>
    );
};
