import { ACCOUNT_ID } from 'hooks/queryParams/useSelectedAccountId';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { CAMPAIGN_ID } from '../../../hooks/useSelectedCampaignId';
import { REPORT_ID } from './useSelectedReportId';

export const TEMP_REPORT_FILTERS = 'temp_filters';

export const useTempReportFilters = queryParamHookFactory(TEMP_REPORT_FILTERS, {
    serializer: JSON.stringify,
    deserializer: value => {
        if (!value) {
            return;
        }

        if (typeof value !== 'string') {
            return value;
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {}
    },
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        queryParams: [ACCOUNT_ID, REPORT_ID, CAMPAIGN_ID],
    },
    keepSingleValue: true,
});
