import React, { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { OnboardingStepInformation } from './OnboardingStepInformation/OnboardingStepInformation';
import { OnboardingSummary } from './OnboardingSummary/OnboardingSummary';
import { ANALYTICS_SUB_STEP_DATA_LABEL } from './consts';
import { LOCAL_STORAGE_ACCOUNT_KEYS } from './consts';
import { useStepsCompleteData, useOnboardingLocalStorage } from './hooks';
import styles from './onboardingContent.module.scss';

const { COMPLETION_DATA } = LOCAL_STORAGE_ACCOUNT_KEYS;

const getStepCompletionData = completionData => {
    const LocalStorageCompletionData = Object.fromEntries(
        Object.entries(completionData).map(([stepName, subStepData]) => {
            const { progressPercent, additionalData, ...subStepCompletionData } = subStepData;
            return [stepName, subStepCompletionData];
        })
    );

    return LocalStorageCompletionData;
};

export const OnboardingContent = ({ isCollapsed, closeOnboardingAssistant }) => {
    const stepsCompleteData = useStepsCompleteData();
    const { additionalData, ...stepsProgressData } = stepsCompleteData;
    const { totalProgressPercent } = additionalData;
    const [stepsCompleteDataLocalStorage, saveStepsCompleteDataLocalStorage] = useOnboardingLocalStorage({
        key: COMPLETION_DATA,
    });

    const trackSubStepCompletion = useCallback(
        subStepName => {
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                pageName: getPageName(),
                component: 'Onboarding Assistant',
                columnId: ANALYTICS_SUB_STEP_DATA_LABEL[subStepName],
                columnName: 'Step Completion',
                additionalEventInfo: totalProgressPercent,
            });
        },
        [totalProgressPercent]
    );

    const handleSubStepCompletion = useCallback(
        ({ stepName, stepCompletionData, subStepCompletionData }) => {
            const subStepDataLocalStorage = get(stepsCompleteDataLocalStorage, stepName, {});
            for (const [subStepName, isSubStepComplete] of Object.entries(subStepCompletionData)) {
                const isSubStepCompleteLocalStorage = subStepDataLocalStorage[subStepName];
                if (isSubStepComplete && !isSubStepCompleteLocalStorage) {
                    saveStepsCompleteDataLocalStorage(stepCompletionData);
                    trackSubStepCompletion(subStepName);
                }
            }
        },
        [stepsCompleteDataLocalStorage, saveStepsCompleteDataLocalStorage, trackSubStepCompletion]
    );

    useEffect(() => {
        const stepCompletionData = getStepCompletionData(stepsProgressData);
        Object.entries(stepCompletionData).forEach(([stepName, subStepCompletionData]) => {
            handleSubStepCompletion({ stepName, stepCompletionData, subStepCompletionData });
        });
    }, [stepsProgressData, handleSubStepCompletion]);

    return (
        <div className={styles['container']}>
            <OnboardingSummary isCollapsed={isCollapsed} progressPercent={totalProgressPercent} />
            <OnboardingStepInformation
                isCollapsed={isCollapsed}
                stepsCompleteData={stepsCompleteData}
                closeOnboardingAssistant={closeOnboardingAssistant}
            />
        </div>
    );
};
