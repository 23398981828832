import React, { useCallback } from 'react';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import { PromptHelperModalContent } from './PromptHelperModalContent';
import styles from './promptHelper.module.scss';

export const usePromptHelperOptionsModal = () => {
    const { open: openModal } = useModal();
    const { campaignId } = useMediaTabsContext();

    const openPromptHelperModal = useCallback(
        async (category, options, optionValue, optionOnChange) => {
            const { accepted, stagedOption } = await openModal({
                className: styles['modal-container'],
                headerClassName: styles['modal-header'],
                title: (
                    <FormattedMessage
                        id={`creative.editor.tab.aiGenerated.fromPrompt.helper.categories.${category}`}
                        tagName="h3"
                        defaultMessage={category}
                    />
                ),
                hasCloseButton: true,
                contentRenderer: props => (
                    <PromptHelperModalContent options={options} optionValue={optionValue} {...props} />
                ),
            });
            if (accepted) {
                optionOnChange(stagedOption);
            }
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `PromptHelperModal - Category: ${category} ${accepted ? 'Accepted' : 'Cancelled'}`,
                value: stagedOption,
                taboolaCampaignId: campaignId,
            });
        },
        [campaignId, openModal]
    );

    return { openPromptHelperModal };
};
