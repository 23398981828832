import { useMemo, useState, useCallback } from 'react';
import { batch } from 'react-redux';
import { groupBy, mapValues, uniqBy } from 'lodash';
import { useLoadingState } from '../../Tree';
import useSegmentsFetcher from './useSegmentsFetcher';

const getCurrentCount = segments => mapValues(groupBy(segments, 'taxonomy'), 'length');
const chargeType = 'CPC';

const useSegmentsApi = ({ search, providers, selectedAccountId }) => {
    const [segments, setSegments] = useState([]);
    const [totalsPerTaxonomy, setTotals] = useState({});
    const currentCountPerTaxonomy = useMemo(() => getCurrentCount(segments), [segments]);
    const { loadingPaths, startLoading, stopLoading } = useLoadingState(segments);

    const { fetchSegments, fetchSegmentsByIds } = useSegmentsFetcher({
        search,
        providers,
        selectedAccountId,
        chargeType,
    });

    const fetchSegmentsWithLoading = useCallback(
        async ({ taxonomies, replaceSegments, ...rest }) => {
            startLoading(taxonomies);
            const { results, transformedTotals } = await fetchSegments({ taxonomies, ...rest });

            batch(() => {
                setSegments(prevResults => (replaceSegments ? results : uniqBy([...prevResults, ...results], 'id')));
                setTotals(prevTotals =>
                    replaceSegments ? transformedTotals : { ...prevTotals, ...transformedTotals }
                );
                stopLoading(taxonomies);
            });

            return results;
        },
        [fetchSegments, startLoading, stopLoading]
    );

    const loadMore = useCallback(
        params =>
            fetchSegmentsWithLoading({
                currentCountPerTaxonomy,
                loadingPaths,
                totalsPerTaxonomy,
                ...params,
            }),
        [fetchSegmentsWithLoading, totalsPerTaxonomy, currentCountPerTaxonomy, loadingPaths]
    );

    const loadAll = useCallback(taxonomies => loadMore({ taxonomies, pageSize: 0 }), [loadMore]);

    const reload = useCallback(() => fetchSegmentsWithLoading({ replaceSegments: true }), [fetchSegmentsWithLoading]);

    return {
        segments,
        totals: totalsPerTaxonomy,
        loadMore,
        loadAll,
        reload,
        loadByIds: fetchSegmentsByIds,
        loadingPaths,
    };
};

export default useSegmentsApi;
