import { useCallback, useMemo, useState } from 'react';
import { differenceBy, uniq, intersection, union } from 'lodash';
import { useDebouncedValue } from 'taboola-ultimate-ui';
import TargetingTypes from '../../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { COMMON_FLAGS, useCommonConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue, validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import { getLabels, parseCSV } from '../../../../config';
import { addAllItemsToArrayIfAbsent, addSingleItemToArrayIfAbsent } from '../../../ItemSuggestions/ItemSuggestions';
import { useRejectedKeywords } from '../../RejectedKeywords/useRejectedKeywords';
import { useExternalAudienceValidations } from '../../useExternalAudienceValidations';
import { useSuggestionsApi } from '../../useSuggestionsApi';
import { useMailDomainsAutocomplete } from './useMailDomainsAutocomplete';

const truncateMailDomain = (mailDomain, prefixes) => {
    for (let prefix of prefixes) {
        if (mailDomain.toLocaleLowerCase().startsWith(prefix)) {
            return mailDomain.slice(prefix.length);
        }
    }
    return mailDomain;
};

const validations = [
    {
        validationFn: ({ include = [], exclude = [] }) => {
            return include.length !== 0 || exclude.length !== 0;
        },
        messageId: 'audience.editor.external-audience.mail-domains.validations.empty.error',
        defaultMessage: 'Choose at least one Domain.',
    },
    {
        validationFn: ({ include = [], exclude = [] }) => {
            return !intersection(include, exclude).length;
        },
        messageId: 'audience.editor.external-audience.mail-domains.validations.include.exclude.intersection.error',
        defaultMessage: 'Choosing the same domain for include and exclude is not allowed.',
    },
    {
        validationFn: ({ include, exclude }) => {
            const combined = union(include, exclude);
            return combined.every(domain => validationFunctions.isDomain(domain));
        },
        messageId: 'audience.editor.external-audience.mail-domains.validations.error',
        defaultMessage:
            'Please remove any unsupported values, such as email addresses, hashed emails, domains without a dot, etc.',
    },
];

const truncateMailDomains = (mailDomains, prefixes) =>
    mailDomains.map(mailDomain => truncateMailDomain(mailDomain, prefixes));

export const useMailDomainsListField = () => {
    const { value: includeList, onChange: setIncludeList } = useFormFieldValue({
        field: 'domains.include',
    });
    const { value: excludeList, onChange: setExcludeList } = useFormFieldValue({
        field: 'domains.exclude',
    });

    const { failedValidationData } = useFormValidatedValue({
        field: 'domains',
        validations,
        validationDependencies: {
            includeList,
            excludeList,
        },
    });

    const { [COMMON_FLAGS.MRT_AUDIENCES_PREFIXES_TO_REMOVE_FROM_INPUT]: prefixesToRemoveString } = useCommonConfig([
        COMMON_FLAGS.MRT_AUDIENCES_PREFIXES_TO_REMOVE_FROM_INPUT,
    ]);
    const prefixesToRemove = useMemo(() => {
        return prefixesToRemoveString ? prefixesToRemoveString.split(',').map(prefix => prefix.trim()) : [];
    }, [prefixesToRemoveString]);
    const [targetingType, setTargetingType] = useState(TargetingTypes.INCLUDE);

    const [searchInputValue, setSearchInputValue] = useState('');
    const debouncedSearchInputValue = useDebouncedValue(searchInputValue);

    const clearIncludedItems = () => setIncludeList([]);
    const clearExcludedItems = () => setExcludeList([]);

    const removeIncludeItem = valueToRemove => {
        setIncludeList(includeList.filter(item => item !== valueToRemove));
    };
    const removeExcludeItem = valueToRemove => {
        setExcludeList(excludeList.filter(item => item !== valueToRemove));
    };

    const removeInvalidKeywords = useCallback(
        domainsToRemove => {
            const updatedIncludedItems = includeList.filter(domain => {
                const lowercaseDomain = domain.toLowerCase();
                return !domainsToRemove.includes(lowercaseDomain);
            });
            setIncludeList(updatedIncludedItems);

            const updatedExcludedItems = excludeList.filter(domain => {
                const lowercaseDomain = domain.toLowerCase();
                return !domainsToRemove.includes(lowercaseDomain);
            });
            setExcludeList(updatedExcludedItems);
        },
        [includeList, setIncludeList, excludeList, setExcludeList]
    );

    const domainsToDisplay = useMemo(() => {
        const domainsToDisplay = {};
        domainsToDisplay[TargetingTypes.INCLUDE] = includeList.map(item => ({
            label: item,
            value: item,
            id: item,
            targetingType: TargetingTypes.INCLUDE,
        }));
        domainsToDisplay[TargetingTypes.EXCLUDE] = excludeList.map(item => ({
            label: item,
            value: item,
            id: item,
            targetingType: TargetingTypes.EXCLUDE,
        }));
        return domainsToDisplay;
    }, [includeList, excludeList]);

    const setTargetingTypeValue = ({ value }) => {
        setTargetingType(value);
    };

    const [tempSelection, setTempSelection] = useState([]);

    const addToTempSelection = useCallback(
        domain => {
            setTempSelection(
                tempSelection.includes(domain.label)
                    ? tempSelection.filter(selection => selection !== domain.label)
                    : [...tempSelection, domain.label]
            );
        },
        [tempSelection]
    );

    const { isLoadingAutoComplete, autocompletedDomains } = useMailDomainsAutocomplete(debouncedSearchInputValue);
    const { suggestionsFromApi, isLoadingSuggestions } = useSuggestionsApi({
        selectedValues: includeList,
        apiFunctionName: 'getMRTRecommendations',
    });
    const suggestions = differenceBy(suggestionsFromApi, includeList, 'label');

    const { value: audienceName } = useFormFieldValue({ field: 'name' });

    const handleAddAllSuggestions = () =>
        setIncludeList(
            addAllItemsToArrayIfAbsent(
                includeList,
                suggestions.map(suggestion => ({ ...suggestion, targetingType: TargetingTypes.INCLUDE }))
            )
        );

    const handleSuggestionClick = clickedSuggestion =>
        setIncludeList(
            addSingleItemToArrayIfAbsent(includeList, {
                ...clickedSuggestion,
                targetingType: TargetingTypes.INCLUDE,
            })
        );

    const addDomains = useCallback(
        domains => {
            if (targetingType === TargetingTypes.EXCLUDE) {
                setExcludeList(uniq([...domains, ...excludeList]));
            } else {
                setIncludeList(uniq([...domains, ...includeList]));
            }
        },
        [includeList, setIncludeList, excludeList, setExcludeList, targetingType]
    );

    const onMenuClose = useCallback(() => {
        setSearchInputValue('');
        addDomains(tempSelection);
        setTempSelection([]);
    }, [addDomains, tempSelection]);

    const { validateFileFormat } = useExternalAudienceValidations();

    const onDropFileHandler = useCallback(
        async acceptedFiles => {
            const parsedCSV = await parseCSV(acceptedFiles[0]);
            const valid = validateFileFormat(parsedCSV);
            if (valid) {
                const extractedKeywords = getLabels(parsedCSV);
                const truncatedKeywords = truncateMailDomains(extractedKeywords, prefixesToRemove);
                addDomains(truncatedKeywords);
            }
        },
        [addDomains, validateFileFormat, prefixesToRemove]
    );

    const { rejectedKeywords, rejectedKeywordsTopics } = useRejectedKeywords();

    return {
        includeList: domainsToDisplay[TargetingTypes.INCLUDE],
        excludeList: domainsToDisplay[TargetingTypes.EXCLUDE],
        clearExcludedItems,
        clearIncludedItems,
        removeIncludeItem,
        removeExcludeItem,
        setTargetingTypeValue,
        addToTempSelection,
        isDisabled: false,
        targetingType,
        suggestionsFromApi,
        isLoadingSuggestions,
        audienceName,
        suggestions,
        handleAddAllSuggestions,
        handleSuggestionClick,
        tempSelection,
        onMenuClose,
        searchInputValue,
        setSearchInputValue,
        debouncedSearchInputValue,
        autocompletedDomains,
        isLoadingAutoComplete,
        onDropFileHandler,
        removeInvalidKeywords,
        prefixesToRemoveString,
        prefixesToRemove,
        targetingListsFailedValidationData: failedValidationData,
        rejectedKeywords,
        rejectedKeywordsTopics,
    };
};
