import memoize from 'memoize-one';
import uuid from 'uuid/v1';
import { reduceAndSort } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { COMPONENT_STATUS } from 'services/constants';
import { getActiveItemsList } from './statusHelpers';

export const findContentItemById = (content, idToFind) => {
    const index = content.findIndex(({ id }) => id === idToFind);

    return {
        item: index !== -1 ? content[index] : null,
        index,
    };
};

export const generateEmptyContentItem = options => ({
    id: uuid(),
    status: COMPONENT_STATUS.EMPTY,
    descriptionEnabled: false,
    ctaEnabled: false,
    ...options,
});

export const generateDisabledContentItem = options => ({
    id: uuid(),
    status: COMPONENT_STATUS.INITIAL,
    descriptionEnabled: false,
    ctaEnabled: false,
    ...options,
});

export const getCleanContent = (content, isRecommendedCtaEnabled) =>
    content.map(({ descriptionEnabled, description, ctaEnabled, cta, ...rest }) => {
        const isCtaEnable = ctaEnabled ? cta : '';
        const shouldRenderCta = isRecommendedCtaEnabled ? cta : isCtaEnable;
        const isDescriptionEnabled = descriptionEnabled ? description : '';
        const shouldRenderDescription = isRecommendedCtaEnabled ? description : isDescriptionEnabled;
        return { ...rest, description: shouldRenderDescription, cta: shouldRenderCta };
    });

export const getContentList = memoize(
    (titleMap = {}, descriptionMap = {}, ctaMap = {}, allowNonActiveItems = false) => {
        const titlesList = allowNonActiveItems ? reduceAndSort(titleMap) : getActiveItemsList(titleMap);

        return titlesList.map(entry => {
            const { id, value: title, status } = entry;
            const content = { id, title, status };

            const { value: curDescription, enabled: descriptionEnabled } = descriptionMap[id] || {};

            content.descriptionEnabled = descriptionEnabled;
            if (descriptionEnabled) {
                content.description = curDescription;
            }

            const { value: curCta, enabled: ctaEnabled } = ctaMap[id] || {};

            content.ctaEnabled = ctaEnabled;
            if (ctaEnabled) {
                content.cta = curCta;
            }

            return content;
        });
    }
);
