import React from 'react';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import { isDSPAccount } from 'modules/account-management/accountType';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import Alert from './StatusTrackerPhaseTooltipAlert';
import styles from './statusTrackerPhaseTooltip.module.scss';

// Alert Type Enums
const MISSING_PIXEL = 'MISSING_PIXEL';
const NO_CONVERSIONS = 'NO_CONVERSIONS';
const ADDITIONAL_INFO = 'ADDITIONAL_INFO';

export const StatusTrackerPhaseTooltip = ({
    phase,
    phaseMsgIdPrefix,
    hasActivePixel,
    hasConversions,
    hasAdditionalInfo,
}) => {
    const [selectedAccount] = useSelectedAccount();
    const isDSP = isDSPAccount(selectedAccount);
    const showNoActivePixel = !isDSP && !hasActivePixel;

    return (
        <>
            <div className={styles['tooltip-section-container']}>
                <div className={styles['tooltip-sub-header']}>
                    <FormattedMessage id={`${phaseMsgIdPrefix}.tooltip.tracker.${phase}`} defaultMessage={phase} />
                </div>
            </div>
            <div className={styles['tooltip-line-break']} />
            <div className={styles['tooltip-section-container']}>
                {hasAdditionalInfo && (
                    <Alert type={`${ADDITIONAL_INFO}.${phase}`} phaseMsgIdPrefix={phaseMsgIdPrefix} isAdditionalInfo />
                )}
                {showNoActivePixel && <Alert type={MISSING_PIXEL} phaseMsgIdPrefix={phaseMsgIdPrefix} />}
                {hasActivePixel && !hasConversions && (
                    <Alert type={NO_CONVERSIONS} phaseMsgIdPrefix={phaseMsgIdPrefix} />
                )}
            </div>
            <div className={styles['tooltip-section-container']}>
                <div className={styles['tooltip-header']}>
                    <FormattedMessage id={`${phaseMsgIdPrefix}.${phase}`} defaultMessage={phase} />
                </div>
                <FormattedMessage id={`${phaseMsgIdPrefix}.tooltip.main.${phase}`} defaultMessage={phase} />
            </div>
            <div className={styles['tooltip-section-container']}>
                <div className={styles['tooltip-sub-header']}>
                    <FormattedMessage
                        id={`${phaseMsgIdPrefix}.tooltip.recommendations.header`}
                        defaultMessage="We recommend"
                    />
                </div>
                <FormattedMessage id={`${phaseMsgIdPrefix}.tooltip.recommendations.${phase}`} defaultMessage="" />
            </div>
        </>
    );
};

StatusTrackerPhaseTooltip.propTypes = {
    phase: PropTypes.string,
    phaseMsgIdPrefix: PropTypes.string,
    hasActivePixel: PropTypes.bool,
    hasConversions: PropTypes.bool,
    hasAdditionalInfo: PropTypes.bool,
};

export default StatusTrackerPhaseTooltip;
