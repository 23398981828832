import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { Wizard } from 'taboola-ultimate-ui';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import { MailDomainsPropertiesSection } from '../components/ExternalAudiencePropertiesSection/MailDomainsPropertiesSection/MailDomainsPropertiesSection';
import { MailDomainsSizeEstimationWidget } from '../components/ExternalAudienceSetupSection/MailDomainsSizeEstimation/MailDomainsSizeEstimationWidget';
import { ENTITY } from '../config';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const MailDomainsAudiencesEditorPage = ({ onCancel, setMediumDrawer, resetDrawerSize }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();

    const { submit } = useAudienceEditorState({
        reportType: REPORT_TYPE.MAIL_DOMAINS,
        entity: ENTITY.MAIL_DOMAINS,
    });

    useEffect(() => {
        setMediumDrawer();
        return () => resetDrawerSize();
    }, [setMediumDrawer, resetDrawerSize]);

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="AudienceEditor"
            >
                <div className={classNameBuilder('audience-editor', 'widget')}>
                    <MailDomainsPropertiesSection />
                    <MailDomainsSizeEstimationWidget />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const MailDomainsAudiencesEditorPageWithFormDataProvider = withFormDataProvider(MailDomainsAudiencesEditorPage, {
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { MailDomainsAudiencesEditorPageWithFormDataProvider as MailDomainsAudiencesEditorPage };
