import React from 'react';
import { batch } from 'react-redux';
import { requestUpdateConversion, updateConversionError, updateConversionSuccess } from 'modules/tracking/actions';
import trackingApi from 'services/api/trackingApi';
import { errorMessagesUtils } from 'services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

export const successIndication = {
    message: <FormattedMessage id="conversion.save.success" defaultMessage="Conversion successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="conversion.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const updateConversion =
    ({ accountId, conversion, onError }) =>
    async dispatch => {
        const { id: conversionId } = conversion;
        dispatch(requestUpdateConversion());

        try {
            const updateConversionResponse = await trackingApi.updateConversion(accountId, conversionId, conversion);

            batch(() => {
                dispatch(updateConversionSuccess(updateConversionResponse));
                dispatch(addIndication(successIndication));
            });
            return updateConversionResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateConversionError(error));
            if (onError) {
                onError();
            }
            throw error;
        }
    };
