import { CAMPAIGN_STATUS } from './campaignsConsts';
import PERFORMANCE_STATUS from './performanceStatus';
import { STATUS_TYPE, getStatusType } from './statusConsts';

export const CREATIVE_STATUS = {
    RECENT: 'RECENT',
    RUNNING: 'RUNNING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
    REJECTED: 'REJECTED',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    NEED_TO_EDIT: 'NEED_TO_EDIT',
    CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
    CAMPAIGN_DEPLETED: 'CAMPAIGN_DEPLETED',
    CAMPAIGN_DEPLETED_CURRENT_MONTH: 'CAMPAIGN_DEPLETED_CURRENT_MONTH',
    CAMPAIGN_TERMINATED: 'CAMPAIGN_TERMINATED',
    CAMPAIGN_EXPIRED: 'CAMPAIGN_EXPIRED',
    CAMPAIGN_DISABLED: 'CAMPAIGN_DISABLED',
    FROZEN: 'FROZEN',
    PENDING_START_DATE: 'PENDING_START_DATE',
    EXPIRED: 'EXPIRED',
};

export const CREATIVE_STATUS_FILTERS = {
    RECENT: CREATIVE_STATUS.RECENT,
    RUNNING: CREATIVE_STATUS.RUNNING,
    PAUSED: CREATIVE_STATUS.PAUSED,
    STOPPED: CREATIVE_STATUS.STOPPED,
    REJECTED: CREATIVE_STATUS.REJECTED,
    PENDING_APPROVAL: CREATIVE_STATUS.PENDING_APPROVAL,
    NEED_TO_EDIT: CREATIVE_STATUS.NEED_TO_EDIT,
};

export const CREATIVE_STATUS_REASONS = {
    DUPLICATE: 'DUPLICATE',
    NO_TITLE: 'NO_TITLE',
    NO_THUMBNAIL: 'NO_THUMBNAIL',
    NO_URL: 'NO_URL',
    TIMEOUT: 'TIMEOUT',
    IMAGE_WITHOUT_LICENSE: 'IMAGE_WITHOUT_LICENSE',
    CUSTOM_RULE: 'CUSTOM_RULE',
};

export const CREATIVE_STATUS_KEYS = Object.values(CREATIVE_STATUS);
export const CREATIVE_STATUS_FILTER_KEYS = Object.values(CREATIVE_STATUS_FILTERS);

const creativeStatusConfig = {
    [CREATIVE_STATUS.RUNNING]: { statusType: STATUS_TYPE.SUCCESS },
    [CREATIVE_STATUS.NEED_TO_EDIT]: { statusType: STATUS_TYPE.DANGER },
    [CREATIVE_STATUS.PAUSED]: { statusType: STATUS_TYPE.INFO },
    [CREATIVE_STATUS.PENDING_APPROVAL]: { statusType: STATUS_TYPE.INFO },
    [CREATIVE_STATUS.REJECTED]: { statusType: STATUS_TYPE.DANGER },
    [CREATIVE_STATUS.PENDING_START_DATE]: { statusType: STATUS_TYPE.INFO },
    [CREATIVE_STATUS.EXPIRED]: { statusType: STATUS_TYPE.DANGER },
};

const campaignStatusMap = {
    [CAMPAIGN_STATUS.PAUSED]: CREATIVE_STATUS.CAMPAIGN_PAUSED,
    [CAMPAIGN_STATUS.DEPLETED]: CREATIVE_STATUS.CAMPAIGN_DEPLETED,
    [CAMPAIGN_STATUS.DEPLETED_MONTHLY]: CREATIVE_STATUS.CAMPAIGN_DEPLETED_CURRENT_MONTH,
    [CAMPAIGN_STATUS.TERMINATED]: CREATIVE_STATUS.CAMPAIGN_TERMINATED,
    [CAMPAIGN_STATUS.EXPIRED]: CREATIVE_STATUS.CAMPAIGN_EXPIRED,
    [CAMPAIGN_STATUS.FROZEN]: CREATIVE_STATUS.FROZEN,
    [CAMPAIGN_STATUS.DISABLED]: CREATIVE_STATUS.CAMPAIGN_DISABLED,
};

export const PERFORMANCE_STATUS_FILTERS = {
    MOTION: PERFORMANCE_STATUS.MOTION,
    IMAGE: PERFORMANCE_STATUS.IMAGE,
};
export const PERFORMANCE_STATUS_FILTER_VALUES = Object.values(PERFORMANCE_STATUS_FILTERS);

export const getCreativeStatusType = creativeStatus => getStatusType(creativeStatusConfig, creativeStatus);

export const isCreativeStatusRejected = ({ status, rejectReason }) =>
    status === CREATIVE_STATUS.REJECTED && rejectReason;

export const isCreativeStatusNeedToEditWithReason = ({ status, statusReason }) =>
    status === CREATIVE_STATUS.NEED_TO_EDIT && statusReason && CREATIVE_STATUS_REASONS[statusReason];

export const resolveCreativeStatusWithParentCampaignStatus = (itemStatus, data) => {
    const { campaignStatus, statusReason } = data;
    if (itemStatus === CREATIVE_STATUS.PAUSED && statusReason === CREATIVE_STATUS_REASONS.CUSTOM_RULE) {
        return CREATIVE_STATUS.PAUSED;
    }
    return itemStatus === CREATIVE_STATUS.RUNNING && campaignStatusMap[campaignStatus]
        ? campaignStatusMap[campaignStatus]
        : itemStatus;
};
