import React from 'react';
import AdsConsoleLogo from '../../AdsConsoleLogo';

const Logo = ({ className, ...rest }) => (
    <div className={className}>
        <AdsConsoleLogo {...rest} />
    </div>
);

export default Logo;
