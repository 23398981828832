"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLeft(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M6.943 13.062A1.485 1.485 0 0 1 6.5 12c0-.401.158-.778.443-1.06l6.833-6.649a1.018 1.018 0 0 1 1.428 0 .993.993 0 0 1 0 1.415L8.728 12l6.476 6.293a.993.993 0 0 1 0 1.415 1.017 1.017 0 0 1-1.428 0l-6.833-6.647z"
            })
        )
    );
}
exports.default = SvgLeft;