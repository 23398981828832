import React from 'react';
import { PlusIcon, EditIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../../components';
import { useBulkUploadPreview } from '../../../hooks/useBulkUploadPreview';
import styles from './bulkUploadPreview.module.scss';

export const BulkUploadPreview = () => {
    const { entityToNumOperationsMap } = useBulkUploadPreview();

    return (
        <>
            {Object.entries(entityToNumOperationsMap).map(([entity, operationsMap]) => {
                const { numUpdates, numCreations } = operationsMap;

                return (
                    <CommonCollapsibleCard
                        id={`bulk-operations-preview-section-${entity}`}
                        header={
                            <SectionHeader headerText={<FormattedMessage id={`excel.upload.preview.${entity}`} />} />
                        }
                        key={entity}
                    >
                        <div key={entity} className={styles['messages-container']}>
                            <div className={styles['operation-summary-container']}>
                                <EditIcon className={styles['icon']} />
                                <FormattedMessage
                                    id={`excel.upload.preview.update.${entity}`}
                                    values={{ num: numUpdates }}
                                />
                            </div>
                            <div className={styles['separation-line']} />
                            <div className={styles['operation-summary-container']}>
                                <PlusIcon className={styles['icon']} />
                                <FormattedMessage
                                    id={`excel.upload.preview.create.${entity}`}
                                    values={{ num: numCreations }}
                                />
                            </div>
                        </div>
                    </CommonCollapsibleCard>
                );
            })}
        </>
    );
};
