import React from 'react';
import PropTypes from 'prop-types';
import { BudgetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from '../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../components/Form';
import { DailyImpressionCapField } from '../DailyImpressionCap/DailyImpressionCapField';
import ImpressionTypeField from '../ImpressionType/ImpressionTypeField';
import RateField from '../Rate/RateField';
import { TotalImpressionCapField } from '../TotalImpressionCap/TotalImpressionCapField';

export const BudgetSectionVideoPmpDeal = ({ account }) => {
    return (
        <CommonCollapsibleCard
            id="VIDEO_PMP_CAMPAIGNS_BUDGET"
            header={
                <SectionHeader
                    headerIcon={<BudgetIcon />}
                    headerText={
                        <FormattedMessage id="campaign.editor.budget.and.bidding" defaultMessage="Budget and Bidding" />
                    }
                />
            }
        >
            <ImpressionTypeField />
            <RateField
                currency={account.currency}
                label={<FormattedMessage id="video.pmpDeal.creator.cpmRate.title" defaultMessage="CPM Rate" />}
            ></RateField>
            <DailyImpressionCapField />
            <TotalImpressionCapField />
        </CommonCollapsibleCard>
    );
};

BudgetSectionVideoPmpDeal.propTypes = {
    account: PropTypes.object,
};

export default BudgetSectionVideoPmpDeal;
