export const BACKSTAGE_CONTENT_PERMISSION = 'BACKSTAGE_HYBRID_IFRAME_VIEW';

export const PERMISSIONS_OPERATORS = {
    OR: 'some',
    AND: 'every',
};

export default {
    PERMISSIONS_OPERATORS,
};
