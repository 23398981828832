import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './naMessage.module.scss';

export const NAMessage = () => {
    return (
        <div>
            <span className={styles['na-message']}>
                <FormattedMessage
                    id="campaign.reach.estimator.widget.reach.na.info.message"
                    defaultMessage="Please choose a platform and country to get your audience size estimation."
                />
            </span>
        </div>
    );
};
