import React from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormNamespaceProvider } from '../../../taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { useExternalMetadata } from '../../hooks/useExternalMetadata';
import { ListItem } from '../ListItem/ListItem';
import { MetadataInput } from '../MetadataInput/MetadataInput';
import styles from './externalMetadataSection.module.scss';

export const ExternalMetadataSection = () => {
    const { externalMetadata, onAdd, onRemove } = useExternalMetadata();
    return (
        <CommonCollapsibleCard
            id="ACCOUNT_EXTERNAL_METADATA"
            header={<SectionHeader headerText={<FormattedMessage id="account.editor.externalMetadata" />} />}
        >
            <MetadataInput onAdd={onAdd} />
            <FormNamespaceProvider field="externalMetadata.data">
                <CollapsibleList
                    containerClassName={styles['list']}
                    items={externalMetadata}
                    deleteItem={onRemove}
                    listHeaderTitle={
                        <FormattedMessage
                            id="account.editor.externalMetadata.list.header"
                            defaultMessage="External Metadata"
                        />
                    }
                    ItemComponent={ListItem}
                />
            </FormNamespaceProvider>
        </CommonCollapsibleCard>
    );
};

export default ExternalMetadataSection;
