import { saveCampaignExplorationStatusModification } from './saveCampaignExplorationStatusModification';
import { saveCampaignSafetyNetsStatusModification } from './saveCampaignSafetyNetsStatusModification';

export const saveCampaignSiteStatus = (_, rowData, { publisherBlockingLevel: oldValue }, rowIndex, newValue) => {
    const { safetyNetsStatus: newSafetyNetsStatus } = newValue;
    const { safetyNetsStatus: oldSafetyNetsStatus } = oldValue;
    if (newSafetyNetsStatus !== oldSafetyNetsStatus) {
        return saveCampaignSafetyNetsStatusModification(newSafetyNetsStatus, rowData, oldSafetyNetsStatus, rowIndex);
    }
    return saveCampaignExplorationStatusModification(rowData, oldValue, rowIndex);
};
