import TargetingTypes from '../../modules/common-campaign-form/config/TargetingTypes';
import { getAudiencesByTargetingType } from './audienceTransformerUtils';

const transformContextualSegmentTargetingFromGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { collection: targetedContextualSegments = [], validTargeting = true } =
        campaignTargeting?.contextualSegmentsTargeting || {};

    const includeSegments = getAudiencesByTargetingType(targetedContextualSegments, TargetingTypes.INCLUDE);
    const excludeSegments = getAudiencesByTargetingType(targetedContextualSegments, TargetingTypes.EXCLUDE);

    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            contextualSegmentsTargeting: { include: includeSegments, exclude: excludeSegments, validTargeting },
        },
    };
};

const transformContextualSegmentTargetingToGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { contextualSegmentsTargeting = {} } = campaignTargeting;
    const { include, exclude } = contextualSegmentsTargeting;
    if (!include && !exclude) {
        return campaign;
    }

    const collection = [];

    if (Array.isArray(include) && include.length > 0) {
        collection.push({ type: TargetingTypes.INCLUDE, values: include });
    }

    if (Array.isArray(exclude) && exclude.length > 0) {
        collection.push({ type: TargetingTypes.EXCLUDE, values: exclude });
    }

    return { ...campaign, campaignTargeting: { ...campaignTargeting, contextualSegmentsTargeting: { collection } } };
};

export { transformContextualSegmentTargetingFromGW, transformContextualSegmentTargetingToGW };
