"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgBlock(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm6-8a5.959 5.959 0 0 0-1.118-3.468l-8.35 8.35A5.985 5.985 0 0 0 18 12zM6 12a5.959 5.959 0 0 0 1.118 3.468l8.35-8.35A5.985 5.985 0 0 0 6 12z"
            })
        )
    );
}
exports.default = SvgBlock;