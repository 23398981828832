import React from 'react';
import { MyLocationOutlinedIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { SectionHeader } from 'modules/campaigns/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CONVERSION_TYPE } from '../../config';
import ConversionNameField from '../ConversionNameField/ConversionNameField';
import { ConversionSetupTypeField } from '../ConversionSetupTypeField/ConversionSetupTypeField';
import { ConversionSetupUrlConditionsField } from '../ConversionSetupUrlConditionsField/ConversionSetupUrlConditionsField';
import styles from './conversionSetupSection.module.scss';

export const ConversionSetupSection = () => {
    const { mode, value: type } = useFormFieldValue({ field: 'type' });

    return (
        <CommonCollapsibleCard
            id="CONVERSION_SETUP"
            header={
                <SectionHeader
                    headerIcon={<MyLocationOutlinedIcon />}
                    headerText={
                        <FormattedMessage
                            id="tracking.conversion.creator.setup.step.title"
                            defaultMessage="Conversion Setup"
                        />
                    }
                />
            }
        >
            <div className={styles['col-list']}>
                {mode === FORM_MODES.CREATE && <ConversionNameField />}
                <ConversionSetupTypeField />
                {type === CONVERSION_TYPE.BASIC && <ConversionSetupUrlConditionsField />}
            </div>
        </CommonCollapsibleCard>
    );
};
