import { useCallback, useMemo } from 'react';
import { batch, useSelector } from 'react-redux';
import { invert, get } from 'lodash';
import { usePagination, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import useComponentStatus from '../../../../../hooks/useComponentStatus';
import { COMPONENT_STATUS } from '../../../../../services/constants';
import { CAMPAIGN_SORTABLE_FIELDS, defaultSortType, defaultSortField } from '../../../config';
import { campaignPickerForceReloadFlagSelector, campaignPickerSortOptionsSelector } from '../../../selectors';
import useCampaignTotals from './useCampaignTotals';
import useCampaignsFetchService from './useCampaignsFetchService';

const CAMPAIGNS_PAGE_SIZE = 30;
const SORTABLE_FIELD_VALUE_TO_KEY_MAP = invert(CAMPAIGN_SORTABLE_FIELDS);
const SORTING_ORDER_TYPE_TO_KEY_MAP = invert(SORTING_ORDER_TYPES);
export const GET_CAMPAIGNS_API_CACHE_KEY = 'pickerGetCampaignsApiCache';

const getValidValue = (value, validValues, defaultValue) => (validValues[value] ? value : defaultValue);

const useCampaignPickerService = (searchText, status) => {
    const { status: paginationStatus, setStatus: setPaginationStatus, isModuleReady } = useComponentStatus(status);
    const { getFullCampaign, loadCampaignsPage, loadCampaignsTotalsByDimension } = useCampaignsFetchService();
    const forceCampaignPickerReloadFlag = useSelector(campaignPickerForceReloadFlagSelector);
    const { type, field } = useSelector(campaignPickerSortOptionsSelector);
    const sortField = getValidValue(field, SORTABLE_FIELD_VALUE_TO_KEY_MAP, defaultSortField);
    const sortType = getValidValue(type, SORTING_ORDER_TYPE_TO_KEY_MAP, defaultSortType);
    const sort = [
        {
            field: sortField,
            order: sortType,
        },
    ];

    const loadCampaignsPageWrapper = useCallback(
        params => loadCampaignsPage({ ...params, apiCacheKey: GET_CAMPAIGNS_API_CACHE_KEY }),
        [loadCampaignsPage]
    );

    const {
        reload: innerReload,
        isItemLoaded,
        loadCurrentFrame,
        values: loadedCampaigns,
        reset,
    } = usePagination({
        loadPage: loadCampaignsPageWrapper,
        pageSize: CAMPAIGNS_PAGE_SIZE,
        loadParams: { searchText, sort },
    });

    const {
        loadSecondaryCampaignsTotals,
        totalCampaignsByDimension,
        reset: resetTotals,
        setSelectedCampaignTotals,
    } = useCampaignTotals({ loadCampaignsTotalsByDimension, defaultPageSize: CAMPAIGNS_PAGE_SIZE });
    const campaigns = useMemo(() => loadedCampaigns.map(getFullCampaign), [loadedCampaigns, getFullCampaign]);

    const loadNextPage = useCallback(
        (...params) => {
            if (!isModuleReady) {
                return;
            }

            return loadCurrentFrame(...params);
        },
        [loadCurrentFrame, isModuleReady]
    );

    const reload = useCallback(async () => {
        setPaginationStatus(COMPONENT_STATUS.LOADING);
        const pageTuples = await innerReload();
        const selectedCampaignTotals = get(pageTuples, ['0', '2']);
        batch(() => {
            setSelectedCampaignTotals(selectedCampaignTotals);
            setPaginationStatus(COMPONENT_STATUS.ACTIVE);
        });
    }, [innerReload, setPaginationStatus, setSelectedCampaignTotals]);

    const resetWrapper = useCallback(() => {
        resetTotals();
        reset();
        loadSecondaryCampaignsTotals();
    }, [reset, resetTotals, loadSecondaryCampaignsTotals]);

    return {
        campaigns,
        reload,
        reset: resetWrapper,
        loadNextPage,
        isItemLoaded,
        paginationStatus,
        sortType,
        sortField,
        totalCampaignsByDimension,
        loadSecondaryCampaignsTotals,
        forceCampaignPickerReloadFlag,
    };
};

export default useCampaignPickerService;
