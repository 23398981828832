import { Workbook } from 'exceljs';

export const convertFileToWorkbook = async file => {
    const fileArrayBuffer = await file.arrayBuffer();
    const buffer = Buffer.from(fileArrayBuffer);
    const workbook = new Workbook();

    await workbook.xlsx.load(buffer);

    return workbook;
};
