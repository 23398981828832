import React from 'react';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { generateErrorIndication } from 'services/utils/errorMessagesUtils';
import { dimensionSelector } from '../../../../../selectors';
import { creativesApi } from '../../../../../services/api';
import { REPORT_TYPE, resolveReportIdByCampaignDimension } from '../../../config';

const SUCCESS_TIMEOUT_DURATION = 30_000;

export const successIndication = {
    message: (
        <FormattedMessage
            id="creative.creator.save.rss.success"
            defaultMessage="Rss Feed has been successfully added. It may take up to 10 minutes for the ads to appear, please visit the Ads report again later to see your new ads."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    timeoutDuration: SUCCESS_TIMEOUT_DURATION,
};

export const errorMessage = (
    <FormattedMessage
        id="creative.creator.save.rss.error"
        defaultMessage="We were not able to add the RSS Feed to your campaign."
    />
);

export const convertToUrlCollection = listedUrl => ({ url: listedUrl });

const createRssCreatives =
    ({ accountId, selectedCampaign, rssUrls }) =>
    async (dispatch, getState) => {
        const dimension = dimensionSelector(getState());
        const rssCreatives = rssUrls.map(convertToUrlCollection);

        try {
            await creativesApi.createRssCreatives(accountId, selectedCampaign.id, rssCreatives);

            dispatch(addIndication(successIndication));

            return {
                campaignId: selectedCampaign.id,
                reportId: resolveReportIdByCampaignDimension(REPORT_TYPE.CREATIVE, dimension),
            };
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error, errorMessage)));
            throw error;
        }
    };

export default createRssCreatives;
