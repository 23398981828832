import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, identity, keyBy } from 'lodash';
import { graphMetricsSelector } from '../../../selectors';
import { generateColumnId } from '../utils';

export const useGraphMetrics = ({ columnDefs, columnState, selectedReport: reportId, graphConfig = {}, entity }) => {
    const { metricsViewTransformer = identity } = graphConfig;
    const rawGraphMetrics = useSelector(graphMetricsSelector);
    const { graphMetrics } = metricsViewTransformer({ entity, graphMetrics: rawGraphMetrics });
    const skipSyncWithGrid = get(graphConfig, 'skipSyncWithGrid', false);
    const metrics = useMemo(() => {
        if (skipSyncWithGrid) return graphMetrics;
        const columnDefsMap = keyBy(columnDefs, 'field');
        const columnStateMap = keyBy(columnState, 'colId');

        const filteredMetrics = graphMetrics.filter(({ name }) => {
            const colId = generateColumnId(reportId, name);

            return columnDefsMap[name] && columnStateMap[colId] && !columnStateMap[colId].hide;
        });
        return filteredMetrics;
    }, [columnDefs, columnState, graphMetrics, reportId, skipSyncWithGrid]);

    return metrics;
};
