import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import OutlineEditableText from '../../../campaigns/components/OutlineEditableText';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import AudienceDescriptionFieldTooltip from './AudienceDescriptionFieldTooltip';
import styles from './audienceDescriptionField.module.scss';

export const AudienceDescriptionField = ({ fieldName = 'audience', metricName = 'AudienceDescription' }) => {
    const { value: description, onChange: onDescriptionChange } = useFormFieldValue({ field: 'description' });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `audience.editor.${fieldName}.description.placeholder`,
        defaultMessage: 'Add description here...',
    });

    return (
        <FormField
            dataTestId="audience-description-field"
            inputId="audience-description"
            label={
                <FormattedMessage
                    id={`audience.editor.${fieldName}.description.title`}
                    defaultMessage="Audience Description (optional)"
                />
            }
            helpText={<AudienceDescriptionFieldTooltip fieldName={fieldName} />}
        >
            <OutlineEditableText
                id={`${fieldName}-description`}
                className={styles['editable-text']}
                value={description}
                onChange={onDescriptionChange}
                placeholder={placeholder}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component={metricName}
            />
        </FormField>
    );
};

export default AudienceDescriptionField;
export const SegmentDescriptionField = () => (
    <AudienceDescriptionField fieldName="segment" metricName="SegmentDescription" />
);
