import React from 'react';
import { Button, DownloadOutlinedIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BULK_UPLOAD_GTM_PREFIX } from '../../../../consts/gtmConsts';
import styles from './templateSection.module.scss';

export const TemplateSection = ({ downloadTemplate, isDisabled }) => {
    return (
        <FormField
            label={
                <FormattedMessage
                    id="template.download.section.description"
                    defaultMessage="Option 1: Download a blank template"
                />
            }
        >
            <Button
                onClick={() => downloadTemplate(FORM_MODES.CREATE)}
                size={Button.size.md}
                disabled={isDisabled}
                variant={Button.variant.outline}
                className={styles['button']}
                data-metrics-component={`${BULK_UPLOAD_GTM_PREFIX}: Download Empty Template`}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
            >
                <DownloadOutlinedIcon fontSize="small" />
                <FormattedMessage id="excel.bulk.download.template" defaultMessage="Download Template" />
            </Button>
        </FormField>
    );
};
