import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { LoadingMode } from '../Tree';

const ContextualSegmentsTreeContext = createContext({});

export const ContextualSegmentsTreeProvider = ({ children, ...rest }) => (
    <ContextualSegmentsTreeContext.Provider value={rest}>{children}</ContextualSegmentsTreeContext.Provider>
);

ContextualSegmentsTreeProvider.propTypes = {
    children: PropTypes.node,
    search: PropTypes.string,
    isSearchMode: PropTypes.bool,
    loadingMode: PropTypes.oneOf(Object.values(LoadingMode)),
    nodesTree: PropTypes.array,
    handleSelectNode: PropTypes.func,
    handleSelectAll: PropTypes.func,
    selectedPathsMap: PropTypes.object,
    collapsedPathMap: PropTypes.object,
    disabledPathsMap: PropTypes.object,
    totalCount: PropTypes.number,
    handleCollapseChange: PropTypes.func,
};

export function useContextualSegmentsTreeContext() {
    return useContext(ContextualSegmentsTreeContext);
}
