import { getParamDependentValues, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { REPORT_ID } from './useSelectedReportId';

export const REPORT_FILTER_STATUS = 'filters_active';

export const getReportFilterActiveKey = reportId => `${REPORT_SETTINGS_PREFIX}.${reportId}.filterActive`;

export const useReportFiltersStatus = queryParamHookFactory(REPORT_FILTER_STATUS, {
    deserializer: value => {
        if (!value) {
            return;
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {
            return false;
        }
    },
    persist: PERSISTENCE_TYPE.DB_STORAGE,
    dependencies: { queryParams: [REPORT_ID] },
    storageKeyGetter: (paramName, metadata) => {
        const dependentValues = getParamDependentValues(paramName, metadata);

        return getReportFilterActiveKey(dependentValues[REPORT_ID]);
    },
});
