import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { useNavigateToAdsReport } from '../../hooks/useNavigateToAdsReport';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const LowResolutionPerformanceRecommendation = ({ recommendation }) => {
    const {
        messageParams,
        id: recommendationId,
        accountName: accountId,
        campaignId,
        subType: recommendationSubType,
    } = recommendation;
    const { numOfItems, itemIds, campaign_improvement_rate: campaignImprovementRate } = messageParams;
    const singleMessageIdAddition = numOfItems === 1 ? 'single.' : '';
    const recommendationWithoutImprovementRate =
        recommendationSubType === 'LOW_RESOLUTION_WITHOUT_IMPROVEMENT_RATE' ? 'no-improvement-rate.' : '';
    const params = {
        descriptionCode: `performance-recommendations.low-resolution.${singleMessageIdAddition}${recommendationWithoutImprovementRate}description`,
        viewCode: `performance-recommendations.low-resolution.${singleMessageIdAddition}view`,
    };

    const formattedItemIds = useMemo(() => itemIds.join(', '), [itemIds]);

    const messageDetails = useMemo(() => {
        return {
            ...messageParams,
            campaignImprovementRate: <FormattedNumber value={campaignImprovementRate} maximumFractionDigits={2} />,
            formattedItemIds,
        };
    }, [messageParams, campaignImprovementRate, formattedItemIds]);

    const { navigateToAdsReport } = useNavigateToAdsReport({ formattedItemIds });

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: `performance-recommendations.general-recommendation.error-message`,
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageDetails}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.low-resolution.title"
            descriptionCode={params.descriptionCode}
            iconImg={LightningRoundIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToAdsReport}
                    recommendation={recommendation}
                    textCode={params.viewCode}
                    dataMetricsComponent="RecommendationCardViewItemsButton"
                    defaultMessage="View Ads"
                    variant={Button.variant.primary}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

LowResolutionPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
