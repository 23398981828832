'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'userNameLetterIcon__taboola-svg-icon___2ly6u',
    'round-container': 'userNameLetterIcon__round-container___2Knk3'
};


var UserNameLetterIcon = function UserNameLetterIcon(props) {
    var letter = props.letter,
        letterColor = props.letterColor,
        bgColor = props.bgColor,
        fontSize = props.fontSize,
        className = props.className;


    var style = !className ? {
        color: letterColor || '',
        backgroundColor: bgColor || '',
        fontSize: fontSize ? fontSize + 'px' : ''
    } : null;

    return _react2.default.createElement(
        'div',
        { className: styles['round-container'] + ' ' + (className || ''), style: style },
        letter.toUpperCase()
    );
};

UserNameLetterIcon.propTypes = {
    /** User Name Initial letter */
    letter: _propTypes2.default.string.isRequired,
    /** Define component letter foreground color */
    letterColor: _propTypes2.default.string,
    /** Define component background color */
    bgColor: _propTypes2.default.string,
    /** Define component letter font size */
    fontSize: _propTypes2.default.string,
    /** Define component optional styled class name */
    className: _propTypes2.default.string
};

exports.default = UserNameLetterIcon;