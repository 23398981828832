import React from 'react';
import PropTypes from 'prop-types';
import SegmentListItemContent from './SegmentListItemContent';

const SegmentListItemContentWithValidationsWrapper = ({
    item,
    field,
    enabledProvidersMap,
    isProvidersReady,
    ...rest
}) => {
    return <SegmentListItemContent id="marketplace-audiences-item" item={item} {...rest} />;
};

SegmentListItemContentWithValidationsWrapper.propTypes = {
    campaign: PropTypes.object,
    isProviderEnabled: PropTypes.func,
    ...SegmentListItemContent.propTypes,
};

export default SegmentListItemContentWithValidationsWrapper;
