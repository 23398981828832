/**
 * Created by oreuveni
 * Date: 2019-02-27
 * Time: 10:11
 */
import React, { useMemo, useState } from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import useCampaignsFetchService from '../../../../components/CampaignPicker/services/useCampaignsFetchService';
import { PaginatedDropdownField } from '../../../../components/Form';
import { AllCampaignsObject } from '../../../../config';
import campaignToDropdownObjectTransformer from '../../../../services/utils/dropdownUtils';
import { hasCampaignCarousel } from '../CarouselCardList/utils/hasCampaignCarousel';

const dropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.creative.campaign.empty',
        defaultMessage: 'Select a campaign',
    },
];

const getSelectedCampaign = selectedCampaigns => {
    const selectedCampaignId = get(selectedCampaigns, '0.id', AllCampaignsObject.id);
    if (selectedCampaignId === AllCampaignsObject.id) {
        return null;
    }
    return selectedCampaigns[0];
};

const CreativeCreatorCampaignDropdown = ({
    className,
    onSelectCampaign,
    selectedCampaigns,
    accountForCampaign,
    validations = dropdownValidations,
}) => {
    const [currentOptions, setCurrentOptions] = useState([]);
    const overrideAccountObject = isEmpty(accountForCampaign) ? null : accountForCampaign;
    const { getFullCampaign, loadCampaignsPage } = useCampaignsFetchService(overrideAccountObject);
    const selectedCampaign = getSelectedCampaign(selectedCampaigns);

    const options = useMemo(
        () =>
            currentOptions
                .filter(campaign => !hasCampaignCarousel(campaign))
                .map(campaign => {
                    if (!campaign) {
                        return {};
                    }
                    return campaignToDropdownObjectTransformer(getFullCampaign(campaign));
                }),
        [currentOptions, getFullCampaign]
    );

    const noLoadedOptions = !options.length || isEmpty(accountForCampaign);
    return (
        <PaginatedDropdownField
            id="creative-creator-campaign-selector"
            containerClass={className}
            onChange={onSelectCampaign}
            searchable
            selectedValueObject={campaignToDropdownObjectTransformer(selectedCampaign)}
            disabled={noLoadedOptions}
            enabledWhileSearching
            validations={validations}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'CampaignSelector (Creative Creator)',
            }}
            options={options}
            loadPage={loadCampaignsPage}
            onOptionsLoaded={setCurrentOptions}
        />
    );
};

CreativeCreatorCampaignDropdown.propTypes = {
    className: PropTypes.string,
    onSelectCampaign: PropTypes.func,
    selectedCampaigns: PropTypes.array,
    accountForCampaign: PropTypes.object,
};

CreativeCreatorCampaignDropdown.defaultProps = {
    className: '',
    onSelectCampaign: () => {},
    selectedCampaigns: [],
};

export default CreativeCreatorCampaignDropdown;
