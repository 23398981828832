import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormField, HelpTooltip, RadioGroup, RadioIcon, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { BetaTag } from 'components';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CONTENT_METHODS } from '../../config';
import { CONTENT_METHOD_CONFIGS } from './config/index';
import styles from './contentMethodField.module.scss';

const classNameBuilder = classnames.bind(styles);

const modeHasTooltip = {
    [CONTENT_METHODS.ONE_BY_ONE]: true,
};

export const ContentMethodField = ({ onChange, selected, options, isAiVariationsReplacementEnabled, ...rest }) => {
    const [hoveredValue, setHoveredValue] = useState();

    const modeKey = hoveredValue || selected;

    const renderSubtext = useMemo(() => {
        const { getSubtext } = CONTENT_METHOD_CONFIGS[modeKey];

        const finalSubtext = getSubtext ? (
            getSubtext({ isAiVariationsReplacementEnabled })
        ) : (
            <FormattedMessage
                id={`creative.creator.creation.mode.${modeKey}.subtext`}
                defaultMessage={CONTENT_METHOD_CONFIGS[modeKey].subtext}
            />
        );

        return modeKey ? (
            <>
                {finalSubtext}
                {modeHasTooltip[modeKey] && (
                    <HelpTooltip iconClassName={styles['help-icon']}>
                        <FormattedMessage id={`creative.creator.creation.mode.${modeKey}.help.text`} />
                    </HelpTooltip>
                )}
            </>
        ) : null;
    }, [isAiVariationsReplacementEnabled, modeKey]);

    return (
        <FormField
            label={<FormattedMessage id="creative.creator.creation.mode.header" defaultMessage="Creation Mode" />}
            subtext={renderSubtext}
            subtextClass={styles['subtext']}
            containerClass={styles['container']}
            labelContainerClass={styles['label-container']}
        >
            <RadioGroup className={styles['group']} selectedValue={selected} onChange={onChange}>
                {options.map(key => {
                    const { icon, tagMessage, getTagMessage, title, getTitle } = CONTENT_METHOD_CONFIGS[key];
                    const finalTitle = getTitle ? (
                        getTitle({ isAiVariationsReplacementEnabled })
                    ) : (
                        <FormattedMessage id={`creative.creator.creation.mode.${key}`} defaultMessage={title} />
                    );
                    const finalTagMessage = getTagMessage
                        ? getTagMessage({ isAiVariationsReplacementEnabled })
                        : tagMessage;

                    return (
                        <div key={key} className={styles['radio-container']}>
                            <RadioIcon
                                title={finalTitle}
                                iconComponent={icon}
                                value={key}
                                hideRadioPointer={true}
                                labelClassName={classNameBuilder('radio', {
                                    'with-beta-tag': CONTENT_METHOD_CONFIGS[key].isInBeta,
                                })}
                                titleClassName={styles['title']}
                                iconContainerClassName={styles['icon-container']}
                                onMouseEnter={() => setHoveredValue(key)}
                                onMouseLeave={() => setHoveredValue(null)}
                                data-metrics-event-name={GTM_EVENTS.USABILITY}
                                data-metrics-value={CONTENT_METHOD_CONFIGS[key].title}
                                data-metrics-component="Creation Mode"
                            />
                            {CONTENT_METHOD_CONFIGS[key].isInBeta && (
                                <BetaTag
                                    tooltipPosition={TOOLTIP_POSITION.TOP}
                                    tooltipOffset={1}
                                    containerClassName={styles['beta-tag-container']}
                                    tagClassName={styles['beta-tag']}
                                    tagMessage={finalTagMessage}
                                    tagMessageClassName={styles['beta-tag-message']}
                                    tooltipMessage=""
                                />
                            )}
                        </div>
                    );
                })}
            </RadioGroup>
        </FormField>
    );
};

ContentMethodField.propTypes = {
    selected: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    isAiVariationsReplacementEnabled: PropTypes.bool,
};
