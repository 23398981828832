'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useTooltipDefaults = undefined;

var _lodash = require('lodash.omit');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.pick');

var _lodash4 = _interopRequireDefault(_lodash3);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMint = require('react-mint');

var _TooltipPosition = require('../Tooltip/TooltipPosition');

var _TooltipPosition2 = _interopRequireDefault(_TooltipPosition);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

// This component is the anchor point where all child tooltips will render in.
// This is important for bypassing all z-index problems

var DEFAULTS = {
    position: _TooltipPosition2.default.TOP,
    duration: 200,
    delay: [150, 75],
    interactive: false,
    arrow: false,
    offset: 6,
    offsetBody: 0,
    pure: true,
    inline: false,
    hoverable: true,
    clickable: false
};

var tooltipDefaultPropsKeys = Object.keys(DEFAULTS);

var TooltipDefaultsContext = (0, _react.createContext)(DEFAULTS);

var useTooltipDefaults = exports.useTooltipDefaults = function useTooltipDefaults() {
    return (0, _react.useContext)(TooltipDefaultsContext);
};

var TooltipRoot = (0, _react.forwardRef)(function (_ref, ref) {
    var children = _ref.children,
        props = _objectWithoutProperties(_ref, ['children']);

    var topDefaults = useTooltipDefaults();
    var portalProps = (0, _lodash2.default)(props, tooltipDefaultPropsKeys);
    var defaultProps = (0, _lodash4.default)(props, tooltipDefaultPropsKeys);
    var ctx = Object.assign({}, topDefaults, defaultProps);

    return _react2.default.createElement(
        _reactMint.TooltipPortal,
        Object.assign({ ref: ref }, portalProps),
        _react2.default.createElement(
            TooltipDefaultsContext.Provider,
            { value: ctx },
            children
        )
    );
});

TooltipRoot.propTypes = _reactMint.TooltipPortal.propTypes;

exports.default = TooltipRoot;