import React from 'react';

export const BoletoSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 6H1V12H0V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2 6H3V12H2V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4 6H5V12H4V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7 6H8V12H7V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9 6H10V12H9V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 6H12V12H11V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 6H6V12H5V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12 6H13V12H12V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15 6H16V12H15V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 6H17V12H16V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19 6H20V12H19V6Z" fill="#231F20" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23 6H24V12H23V6Z" fill="#231F20" />
            <path
                d="M1.8392 15.9419H0.632428L0.625975 15.2772H1.63915C1.81769 15.2772 1.96397 15.2547 2.07798 15.2095C2.19199 15.1622 2.27696 15.0944 2.33289 15.0062C2.39097 14.9159 2.42001 14.8061 2.42001 14.6771C2.42001 14.5308 2.39204 14.4125 2.33611 14.3221C2.28233 14.2318 2.19736 14.1662 2.0812 14.1253C1.9672 14.0844 1.81984 14.064 1.63915 14.064H0.968002V18.0038H0V13.3057H1.63915C1.91234 13.3057 2.15649 13.3316 2.37161 13.3832C2.58887 13.4348 2.77279 13.5133 2.92337 13.6187C3.07394 13.7241 3.18903 13.8575 3.26862 14.0188C3.34821 14.178 3.38801 14.3673 3.38801 14.5867C3.38801 14.7803 3.34391 14.9589 3.25571 15.1224C3.16967 15.2858 3.03307 15.4192 2.84593 15.5225C2.66093 15.6257 2.41893 15.6827 2.11992 15.6935L1.8392 15.9419ZM1.79726 18.0038H0.367841L0.745362 17.2487H1.79726C1.9672 17.2487 2.10594 17.2219 2.2135 17.1681C2.32105 17.1121 2.40065 17.0369 2.45227 16.9422C2.5039 16.8476 2.52971 16.7389 2.52971 16.6163C2.52971 16.4786 2.50605 16.3593 2.45873 16.2582C2.41355 16.157 2.34041 16.0796 2.23931 16.0258C2.13821 15.9699 2.00484 15.9419 1.8392 15.9419H0.906695L0.913149 15.2772H2.07475L2.29739 15.5386C2.58349 15.5343 2.81366 15.5849 2.9879 15.6903C3.16429 15.7935 3.29228 15.928 3.37187 16.0936C3.45362 16.2592 3.49449 16.4367 3.49449 16.626C3.49449 16.9271 3.42888 17.181 3.29766 17.3875C3.16644 17.5918 2.97392 17.7456 2.72009 17.8489C2.46841 17.9522 2.1608 18.0038 1.79726 18.0038Z"
                fill="black"
            />
            <path
                d="M8.07959 15.5451V15.7677C8.07959 16.1248 8.03119 16.4453 7.93439 16.7292C7.83759 17.0132 7.70099 17.2552 7.5246 17.4552C7.34821 17.6531 7.1374 17.8048 6.89218 17.9102C6.6491 18.0156 6.37913 18.0683 6.08228 18.0683C5.78758 18.0683 5.51761 18.0156 5.27239 17.9102C5.02931 17.8048 4.8185 17.6531 4.63996 17.4552C4.46141 17.2552 4.32267 17.0132 4.22372 16.7292C4.12692 16.4453 4.07852 16.1248 4.07852 15.7677V15.5451C4.07852 15.1858 4.12692 14.8653 4.22372 14.5835C4.32052 14.2996 4.45711 14.0576 4.6335 13.8575C4.81205 13.6575 5.02286 13.5047 5.26593 13.3993C5.51116 13.2939 5.78112 13.2412 6.07583 13.2412C6.37268 13.2412 6.64265 13.2939 6.88572 13.3993C7.13095 13.5047 7.34176 13.6575 7.51815 13.8575C7.69669 14.0576 7.83436 14.2996 7.93116 14.5835C8.03012 14.8653 8.07959 15.1858 8.07959 15.5451ZM7.10191 15.7677V15.5386C7.10191 15.2891 7.07932 15.0697 7.03415 14.8804C6.98898 14.6911 6.92229 14.5319 6.8341 14.4028C6.7459 14.2737 6.63834 14.1769 6.51143 14.1124C6.38451 14.0457 6.23931 14.0124 6.07583 14.0124C5.91234 14.0124 5.76714 14.0457 5.64023 14.1124C5.51546 14.1769 5.40898 14.2737 5.32079 14.4028C5.23474 14.5319 5.16913 14.6911 5.12396 14.8804C5.07878 15.0697 5.0562 15.2891 5.0562 15.5386V15.7677C5.0562 16.0151 5.07878 16.2345 5.12396 16.4259C5.16913 16.6152 5.23582 16.7755 5.32401 16.9067C5.41221 17.0358 5.51976 17.1337 5.64668 17.2003C5.77359 17.267 5.9188 17.3004 6.08228 17.3004C6.24576 17.3004 6.39096 17.267 6.51788 17.2003C6.6448 17.1337 6.75128 17.0358 6.83732 16.9067C6.92337 16.7755 6.98898 16.6152 7.03415 16.4259C7.07932 16.2345 7.10191 16.0151 7.10191 15.7677Z"
                fill="black"
            />
            <path
                d="M11.7967 17.2487V18.0038H9.43157V17.2487H11.7967ZM9.74455 13.3057V18.0038H8.77655V13.3057H9.74455Z"
                fill="black"
            />
            <path
                d="M15.5042 17.2487V18.0038H13.0035V17.2487H15.5042ZM13.3197 13.3057V18.0038H12.3517V13.3057H13.3197ZM15.1783 15.2192V15.9548H13.0035V15.2192H15.1783ZM15.5009 13.3057V14.064H13.0035V13.3057H15.5009Z"
                fill="black"
            />
            <path
                d="M18.2339 13.3057V18.0038H17.2692V13.3057H18.2339ZM19.6795 13.3057V14.064H15.8462V13.3057H19.6795Z"
                fill="black"
            />
            <path
                d="M24 15.5451V15.7677C24 16.1248 23.9516 16.4453 23.8548 16.7292C23.758 17.0132 23.6214 17.2552 23.445 17.4552C23.2686 17.6531 23.0578 17.8048 22.8126 17.9102C22.5695 18.0156 22.2995 18.0683 22.0027 18.0683C21.708 18.0683 21.438 18.0156 21.1928 17.9102C20.9497 17.8048 20.7389 17.6531 20.5604 17.4552C20.3818 17.2552 20.2431 17.0132 20.1441 16.7292C20.0473 16.4453 19.9989 16.1248 19.9989 15.7677V15.5451C19.9989 15.1858 20.0473 14.8653 20.1441 14.5835C20.2409 14.2996 20.3775 14.0576 20.5539 13.8575C20.7325 13.6575 20.9433 13.5047 21.1863 13.3993C21.4316 13.2939 21.7015 13.2412 21.9962 13.2412C22.2931 13.2412 22.5631 13.2939 22.8061 13.3993C23.0514 13.5047 23.2622 13.6575 23.4386 13.8575C23.6171 14.0576 23.7548 14.2996 23.8516 14.5835C23.9505 14.8653 24 15.1858 24 15.5451ZM23.0223 15.7677V15.5386C23.0223 15.2891 22.9997 15.0697 22.9546 14.8804C22.9094 14.6911 22.8427 14.5319 22.7545 14.4028C22.6663 14.2737 22.5588 14.1769 22.4318 14.1124C22.3049 14.0457 22.1597 14.0124 21.9962 14.0124C21.8328 14.0124 21.6875 14.0457 21.5606 14.1124C21.4359 14.1769 21.3294 14.2737 21.2412 14.4028C21.1551 14.5319 21.0895 14.6911 21.0444 14.8804C20.9992 15.0697 20.9766 15.2891 20.9766 15.5386V15.7677C20.9766 16.0151 20.9992 16.2345 21.0444 16.4259C21.0895 16.6152 21.1562 16.7755 21.2444 16.9067C21.3326 17.0358 21.4402 17.1337 21.5671 17.2003C21.694 17.267 21.8392 17.3004 22.0027 17.3004C22.1662 17.3004 22.3114 17.267 22.4383 17.2003C22.5652 17.1337 22.6717 17.0358 22.7577 16.9067C22.8438 16.7755 22.9094 16.6152 22.9546 16.4259C22.9997 16.2345 23.0223 16.0151 23.0223 15.7677Z"
                fill="black"
            />
        </svg>
    );
};
