import React from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { StaticIndications } from '../../taboola-common-frontend-modules/Indications';
import { ScheduledReportDeliverySection } from '../components/ScheduledReportsDeliverySection/ScheduledReportDeliverySection';
import { ScheduledReportsFormBreadcrumbs } from '../components/ScheduledReportsFormBreadcrumbs/ScheduledReportsFormBreadcrumbs';
import { ScheduledReportsFormButtons } from '../components/ScheduledReportsFormButtons/ScheduledReportsFormButtons';
import { ScheduledReportSetupSection } from '../components/ScheduledReportsSetupSection/ScheduledReportSetupSection';
import { scheduledReportsInitialValues } from '../config/initialValues/scheduledReportInitialValues';
import { useScheduledReportsCreatorState } from '../hooks/useScheduledReportsCreatorState';
import styles from './ScheduledReportCreatorForm.module.scss';

const ScheduledReportCreatorForm = ({ onCancel }) => {
    const { submit, step, setStep, submitAndExecuteWrapper } = useScheduledReportsCreatorState();
    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns="100% 0"
                className={styles['static-indications']}
                wizardNavigationClassName={styles['wizard-navigation']}
            >
                <WizardStep
                    id="setup"
                    label={
                        <FormattedMessage id="app.create.scheduled.reports.new" defaultMessage="New Scheduled Report" />
                    }
                >
                    <StaticIndications />
                    <ScheduledReportsFormBreadcrumbs />
                    <div className={styles['form-wrapper']}>
                        <ScheduledReportSetupSection />
                        <ScheduledReportDeliverySection />
                        <div className={styles['buttons-wrapper']}>
                            <ScheduledReportsFormButtons
                                onCancel={onCancel}
                                onSubmit={submit}
                                submitAndExecuteWrapper={submitAndExecuteWrapper}
                            />
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ScheduledReportsCreatorPageWithFormDataProvider = withFormDataProvider(ScheduledReportCreatorForm, {
    defaultValuesConfig: scheduledReportsInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { ScheduledReportsCreatorPageWithFormDataProvider as ScheduledReportCreatorForm };
