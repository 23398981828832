import React from 'react';
import Graph from '../Graph';
import { LegendMetricDropdown } from './LegendMetricDropdown';
import { useFormattedLegendMetrics } from './hooks/useFormattedLegendMetrics';
import { useSingleMetricGraph } from './hooks/useSingleMetricGraph';

export const SingleMetricGraph = ({ config, onToggle, metrics, locale, currency, ...rest }) => {
    const { selectedLegendDropdownMetric, legendDropdownMetrics, onMetricDropdownChange, graphData } =
        useSingleMetricGraph(config);

    const formattedMetrics = useFormattedLegendMetrics({
        config,
        metrics,
        locale,
        currency,
        selectedLegendDropdownMetric,
    });

    const onGraphMetricToggle = (newEdges, { edgeName: toggledMetric, selected } = {}) => {
        onToggle(newEdges, { edgeName: `lineGraph ${toggledMetric}`, selected });
    };

    return (
        <Graph
            {...rest}
            onToggle={onGraphMetricToggle}
            config={config}
            locale={locale}
            metrics={formattedMetrics}
            legendHeader={
                <LegendMetricDropdown
                    value={selectedLegendDropdownMetric}
                    options={legendDropdownMetrics}
                    onChange={onMetricDropdownChange}
                />
            }
            values={graphData}
        />
    );
};
