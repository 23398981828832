import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

export const VastTagTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.video-creative.tooltip"
            defaultMessage="Click “Generate Video” to upload MP4 file OR to paste a 3rd Party Vast/Vpaid tag in order to generate Taboola Vast Tag and create video ad OR Paste existing Taboola Vast tag from previous video campaign.{br}{br}Once Taboola vast is generated it will redirect you back to this page and you can click “Create”.{br}{br}To test enter VAST tag into https://vast-tester.taboola.com/?impto=100000 and click Test Ad. Video should play and click to the desired landing page"
        />
    </TooltipSection>
);
