import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { isEmpty, stubTrue, noop } from 'lodash';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { useIsAppAccountNetwork } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import { retryFetchAccounts, resetAccountsUnderNetwork } from '../../../../../../actions';
import AccountPickerRetryMenuItem from '../../../../../../components/AccountPicker/AccountPickerRetryMenuItem';
import fetchAccountsUnderNetwork from '../../../../../../flows/fetchAccountsUnderNetwork';
import { accountsUnderNetworkSelector } from '../../../../../../selectors';
import { COMPONENT_STATUS } from '../../../../../../services/constants';
import { validationFunctions } from '../../../../../taboola-common-frontend-modules/validations/services';
import AsyncDropdownField from '../../../../components/Form/FormFields/AsyncDropdownField/AsyncDropdownField';
import AccountDropdownLoader from './AccountDropdownLoader';
import styles from './accountDropdown.module.scss';

const PAGE_SIZE = 10;

const classNameBuilder = classnames.bind(styles);

const accountDropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.account.empty',
        defaultMessage: 'Select an account',
    },
];

const switchAccountMessages = {
    title: (
        <h2>
            <FormattedMessage id="app.modal.title.sure" defaultMessage="Are You Sure?" />
        </h2>
    ),
    formProps: {
        submitButtonText: <FormattedMessage id="app.actionButtons.yes.proceed" defaultMessage="Yes, Proceed" />,
    },
    content: (
        <FormattedMessage
            id="form.account.reset.form"
            defaultMessage="Switching to another account will clear the form. Are you sure you want to proceed?"
        />
    ),
};

const AccountDropdown = ({
    onAccountChange,
    accountForCampaign,
    helpText,
    accounts,
    accountsTotal,
    fetchMoreAccountsFlow,
    resetAccountsAction,
    retryFetchAccountsAction,
    status,
    pageSize,
    dropdownStyles,
    accountFilter,
    noAccountsMessageId,
    skipFormResetOnAccountChange,
    className,
    disabled = false,
}) => {
    const [search, setSearch] = useState('');
    const [isRetry, setIsRetry] = useState(false);
    const { resetFormToInitial, touchedFormFields, mode } = useFormDataContext();
    const { reset: resetValidationContext } = useValidationContext();
    const { open: openModal } = useModal();

    useEffect(
        () => () => {
            resetAccountsAction();
        },
        [resetAccountsAction]
    );

    const getOptions = useCallback(
        inputValue =>
            new Promise((resolve, reject) => {
                fetchMoreAccountsFlow(
                    inputValue,
                    pageSize,
                    isRetry,
                    (options, hasMore) => resolve({ options: options.filter(accountFilter), hasMore }),
                    error => reject(error)
                );

                setIsRetry(false);
            }),
        [pageSize, isRetry, fetchMoreAccountsFlow, accountFilter]
    );

    const applyNewAccount = useCallback(
        account => {
            onAccountChange(account);
            setSearch('');
        },
        [onAccountChange]
    );

    const handleOnChange = useCallback(
        async account => {
            const unsupportedForm = !resetValidationContext || !resetFormToInitial || skipFormResetOnAccountChange;

            if (isEmpty(touchedFormFields) || mode === FORM_MODES.DUPLICATE || unsupportedForm) {
                applyNewAccount(account);
                return;
            }

            const result = await openModal(switchAccountMessages);

            if (result) {
                resetFormToInitial();
                resetValidationContext();
                applyNewAccount(account);
            }
        },
        [
            applyNewAccount,
            resetFormToInitial,
            resetValidationContext,
            openModal,
            touchedFormFields,
            skipFormResetOnAccountChange,
            mode,
        ]
    );

    const handleRetry = () => {
        setIsRetry(true);
        retryFetchAccountsAction();
    };

    const handleInputChange = value => {
        resetAccountsAction();
        setSearch(value);
    };

    const isAppAccountNetwork = useIsAppAccountNetwork();
    if (!isAppAccountNetwork) {
        return null;
    }

    const isDisabled = mode === FORM_MODES.EDIT || mode === FORM_MODES.PREVIEW || disabled;

    return (
        <AsyncDropdownField
            id="account-dropdown"
            className={classNameBuilder('container', className)}
            label={<FormattedMessage id="app.account.title" defaultMessage="Account" />}
            placeholder={<FormattedMessage id="app.account.placeholder" defaultMessage="Select Account..." />}
            noOptionsMessage={() => <FormattedMessage id={noAccountsMessageId} defaultMessage="No results found..." />}
            helpText={helpText}
            loadingMessageRenderer={AccountDropdownLoader}
            retryComponentRenderer={AccountPickerRetryMenuItem}
            getOptions={getOptions}
            options={[]}
            selectedValueObject={isEmpty(accountForCampaign) ? null : accountForCampaign}
            inputValue={search}
            onChange={handleOnChange}
            onInputChange={handleInputChange}
            onRetry={handleRetry}
            disabled={isDisabled}
            hasMore={accountsTotal > accounts.length}
            escapeClearsValue
            error={status === COMPONENT_STATUS.ERROR}
            validations={accountDropdownValidations}
            styles={dropdownStyles}
        />
    );
};

AccountDropdown.propTypes = {
    onAccountChange: PropTypes.func.isRequired,
    accountForCampaign: PropTypes.object,
    helpText: PropTypes.node,
    accounts: PropTypes.array,
    accountsTotal: PropTypes.number,
    fetchMoreAccountsFlow: PropTypes.func.isRequired,
    resetAccountsAction: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    retryFetchAccountsAction: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    dropdownStyles: PropTypes.object,
    accountFilter: PropTypes.func,
    noAccountsMessageId: PropTypes.string,
    className: PropTypes.string,
};

AccountDropdown.defaultProps = {
    accounts: [],
    accountForCampaign: {},
    helpText: null,
    pageSize: PAGE_SIZE,
    accountFilter: stubTrue,
    noAccountsMessageId: 'app.account.noResults',
    onAccountChange: noop,
};

const mapStateToProps = state => {
    const { accountsUnderNetworkTotal: accountsTotal, fetchAccountsStatus: status } = state.appReducer;
    const accounts = accountsUnderNetworkSelector(state);

    return {
        accounts,
        accountsTotal,
        status,
    };
};

const mapDispatchToProps = {
    fetchMoreAccountsFlow: fetchAccountsUnderNetwork,
    resetAccountsAction: resetAccountsUnderNetwork,
    retryFetchAccountsAction: retryFetchAccounts,
};

export { AccountDropdown };
export default connect(mapStateToProps, mapDispatchToProps)(AccountDropdown);
