import { get, size } from 'lodash';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';

export const validateSingleSelectedFile = ({
    acceptedFiles,
    rejectedFiles,
    dispatch,
    fileTypes,
    multipleFilesIndication,
    wrongFormatIndication,
}) => {
    const rejectedCount = size(rejectedFiles);
    const acceptedCount = size(acceptedFiles);

    if (acceptedCount > 1 || rejectedCount > 1) {
        dispatch(addIndication(multipleFilesIndication));
        return false;
    }

    if (rejectedCount === 1) {
        const fileName = get(rejectedFiles, '0.file.name', '').toLocaleLowerCase();
        const availableTypes = Object.values(fileTypes).flat();
        const isFileExtensionIncorrect = !availableTypes.some(type => fileName.endsWith(type));

        if (isFileExtensionIncorrect) {
            dispatch(addIndication(wrongFormatIndication));
            return false;
        }
    }

    return true;
};
