import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useCurrentValueGetter } from '../../../../../hooks';
import { usePermanentReportFilters } from './usePermanentReportFilters';
import { useTempReportFilters } from './useTempReportFilters';

export const emptyReportFilters = Object.freeze([]);

export const useReportFilters = () => {
    const [permReportFilters = emptyReportFilters, setPermReportFilters] = usePermanentReportFilters();
    const [tempReportFilters = emptyReportFilters, setTempReportFilters] = useTempReportFilters();
    const reportFilters = useMemo(() => {
        const combinedFilters = [...permReportFilters, ...tempReportFilters];
        return isEmpty(combinedFilters) ? emptyReportFilters : combinedFilters;
    }, [permReportFilters, tempReportFilters]);
    const reportFiltersGetter = useCurrentValueGetter(reportFilters);
    const tempReportFiltersGetter = useCurrentValueGetter(tempReportFilters);

    const addReportFilter = useCallback(
        filter =>
            setPermReportFilters((prevFilters = emptyReportFilters) => {
                const newFilters = [...prevFilters, filter];

                return newFilters;
            }),
        [setPermReportFilters]
    );
    const updateReportFilter = useCallback(
        (index, filter) => {
            const reportFilters = reportFiltersGetter();
            const { type: oldType } = reportFilters[index];
            const tempReportFilters = tempReportFiltersGetter();
            const isTempFilter = tempReportFilters.find(({ type }) => type === oldType);
            const getFiltersWithoutOld = (prevFilters = emptyReportFilters) =>
                prevFilters.filter(({ type }) => type !== oldType);
            if (isTempFilter) {
                setTempReportFilters(getFiltersWithoutOld);
                setPermReportFilters(prevFilters => [...prevFilters, filter]);
                return;
            }

            setPermReportFilters(prevFilters => {
                const result = [...prevFilters];
                result[index] = filter;
                return result;
            });
        },
        [setPermReportFilters, setTempReportFilters, reportFiltersGetter, tempReportFiltersGetter]
    );
    const removeReportFilter = useCallback(
        ({ type: typeToDelete }) => {
            const removeCallBack = (prevFilters = emptyReportFilters) =>
                prevFilters.filter(({ type }) => type !== typeToDelete);

            setPermReportFilters(removeCallBack);
            setTempReportFilters(removeCallBack);
        },
        [setPermReportFilters, setTempReportFilters]
    );
    const clearReportFilters = useCallback(() => {
        setPermReportFilters(emptyReportFilters);
        setTempReportFilters(emptyReportFilters);
    }, [setPermReportFilters, setTempReportFilters]);

    return {
        reportFilters,
        setReportFilters: setPermReportFilters,
        addReportFilter,
        updateReportFilter,
        removeReportFilter,
        clearReportFilters,
    };
};
