import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { useValidationSectionState } from 'modules/taboola-common-frontend-modules/validations/components/ValidationSection';
import { ValidationGroup } from '../ValidationGroup';
import styles from './sectionHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const SectionHeader = ({ headerIcon, headerText, iconClassName }) => {
    const validationsState = useValidationSectionState();
    return (
        <div className={styles['header-title']}>
            {headerIcon && <div className={classNameBuilder('header-icon', iconClassName)}>{headerIcon}</div>}
            <span className={styles['title-text']}>{headerText}</span>
            <ValidationGroup validationsState={validationsState} placement={TOOLTIP_POSITION.LEFT} />
        </div>
    );
};

SectionHeader.propTypes = {
    headerIcon: PropTypes.node,
    headerText: PropTypes.node,
    iconStyle: PropTypes.string,
};

export default SectionHeader;
