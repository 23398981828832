const checkIfAnalyticsLoaded = callback => {
    if (window.ga && window.gaData) {
        callback();
    } else {
        setTimeout(checkIfAnalyticsLoaded, 30, callback);
    }
};

export const waitForAnalyticsLoaded = () => {
    return new Promise(resolve => {
        checkIfAnalyticsLoaded(resolve);
    });
};
