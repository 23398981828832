import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Highlighter } from 'taboola-ultimate-ui';
import { useIntl, LOCALE } from 'modules/taboola-common-frontend-modules/i18n';
import { ACCOUNT_ID, DIMENSION } from '../../../hooks';
import { isNetworkAccountType } from '../../../modules/account-management';
import { AllCampaignsObject } from '../../../modules/campaigns/config';
import { CAMPAIGN_ID } from '../../../modules/campaigns/hooks';
import {
    DATE_PRESET,
    END_DATE,
    START_DATE,
} from '../../../modules/campaigns/modules/campaigns-reports/hooks/useDateRange';
import { REPORT_ID } from '../../../modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { InternalLink } from '../../InternalLink';
import { AccountItemIcon } from '../AccountItemIcon/AccountItemIcon';
import styles from './accountPickerMenuItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const PARAMS_TO_KEEP = [START_DATE, END_DATE, DATE_PRESET, DIMENSION, LOCALE, REPORT_ID];
const emptyInnerProps = {};

export const AccountPickerMenuItem = ({
    data,
    innerRef,
    innerProps = emptyInnerProps,
    selectProps: { inputValue },
    isSelected,
    isFocused,
}) => {
    const { name, type, id, isActive } = data;
    const messageId = `app.accounts.${isNetworkAccountType(type) ? 'network' : 'account'}.displayName`;
    const containerClassName = classNameBuilder('menu-item', {
        'is-selected': isSelected,
        'is-focused': isFocused,
        'is-inactive': !isActive,
    });
    const { onClick, ...restInnerProps } = innerProps;

    const { formatMessage } = useIntl();
    const idDescription = formatMessage(
        {
            id: messageId,
            defaultMessage: 'Account ID: {id}',
        },
        { id }
    );

    return (
        <InternalLink
            params={{ [ACCOUNT_ID]: id, [CAMPAIGN_ID]: AllCampaignsObject.id }}
            paramsToKeep={PARAMS_TO_KEEP}
            className={styles['account-link']}
            onClick={onClick}
        >
            <div className={containerClassName} tabIndex="-1" ref={innerRef} {...restInnerProps}>
                <AccountItemIcon {...data} className={styles['icon']} hasOuterLink />
                <div className={styles['label']}>
                    <Highlighter
                        text={name}
                        highlightedText={inputValue}
                        highlightedTextClassName={styles['highlight']}
                    />
                </div>
                <div className={styles['description']}>
                    <Highlighter
                        text={idDescription}
                        highlightedText={inputValue}
                        highlightedTextClassName={styles['highlight']}
                    />
                </div>
            </div>
        </InternalLink>
    );
};

AccountPickerMenuItem.propTypes = {
    data: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number,
    }),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
    selectProps: PropTypes.shape({ inputValue: PropTypes.string }),
};

AccountPickerMenuItem.defaultProps = {
    selectProps: {},
};
