import { useCallback, useMemo } from 'react';
import { itemTransformer } from 'modules/campaigns/services/utils/dropdownUtils';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useItemsConditionConfigData } from './useItemsConditionConfigData';

const emptyTransformer = input => input;

const useDropdownOptions = (collection, msgIdPrefix, transformer = emptyTransformer) => {
    const { formatMessage } = useIntl();
    const selectedAccount = useAccount();
    const itemsConditionConfigData = useItemsConditionConfigData({ selectedAccount });
    const list = useMemo(
        () => transformer(collection, { ...itemsConditionConfigData }),
        [collection, itemsConditionConfigData, transformer]
    );
    const noPrefixFormatMessage = useCallback(label => label.defaultMessage, []);
    const localizedOptions = useMemo(
        () => list.map(item => itemTransformer(item, msgIdPrefix, msgIdPrefix ? formatMessage : noPrefixFormatMessage)),
        [list, msgIdPrefix, formatMessage, noPrefixFormatMessage]
    );

    return localizedOptions;
};

export default useDropdownOptions;
