import React from 'react';
import { CollapsibleList, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownOption } from '../../../../../../components/Dropdown';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from '../../../../../taboola-common-frontend-modules/validations';
import TargetingDropdownBox from '../../../common-campaign-form/components/TargetingDropdownBox/TargetingDropdownBox';
import useSelectedItems from '../../../common-campaign-form/hooks/useSelectedItems';
import AdUnitTypeListItem from './AdUnitTypeListItem';
import { useAdUnitTypeApi } from './hooks';
import styles from './AdUnitType.module.scss';

const ITEM_HEIGHT = 40;
const CollapsibleListWithIndication = withIndication(CollapsibleList);
const validations = [
    {
        validationFn: value => value.length === 0,
        severity: INDICATION_TYPES.WARNING,
        messageId: 'video.campaign.editor.adUnitTypeFieldTooltip.validations.warn',
        defaultMessage: 'Targeting Ad Unit is not recommended and will narrow your reach',
    },
];

export const AdUnitType = () => {
    const { value: selectedValues, onChange: onChangeSelectedValues } = useFormFieldValue({
        field: 'campaignTargeting.adUnitTypeTargeting.values',
    });

    const { value: targetingType, onChange: onChangeTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.adUnitTypeTargeting.type',
    });
    const { adUnitTypes, isResourcesLoading } = useAdUnitTypeApi();

    const { indicationData } = useFormValidatedValue({
        field: 'campaignTargeting.adUnitTypeTargeting.values',
        validations,
    });

    const { handleAddItem, handleRemoveItem, availableItems, selectedItems, handleRemoveAllItems } = useSelectedItems({
        items: adUnitTypes,
        isResourcesLoading,
        selectedValues,
        onChangeSelectedValues,
        targetingType,
    });

    if (isResourcesLoading) {
        return null;
    }

    return (
        <>
            <TargetingDropdownBox
                itemType={targetingType}
                onSelectItemType={({ value }) => {
                    onChangeTargetingType(value);
                }}
                selectedItems={selectedItems}
                itemPlaceholder={
                    <FormattedMessage
                        id="video.campaign.editor.adUnitTypeFieldTooltip.placeholder"
                        defaultMessage="Select Video Ad Unit types..."
                    />
                }
                onAddItem={handleAddItem}
                allItems={availableItems}
                itemOptionRenderer={DropdownOption}
            />
            <CollapsibleListWithIndication
                data-testid="ad-unit-type-list"
                items={selectedItems}
                ItemComponent={AdUnitTypeListItem}
                itemHeight={ITEM_HEIGHT}
                deleteItem={handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="video.campaign.editor.adUnitTypeFieldTooltip.header"
                        defaultMessage="Selected Ad Unit Types"
                    />
                }
                clearItems={handleRemoveAllItems}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </>
    );
};

export default AdUnitType;
