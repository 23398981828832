import React from 'react';
import * as ReactIs from 'react-is';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../modules/taboola-common-frontend-modules/i18n';
import styles from './dropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

const DropdownOption = ({ data, msgIdPrefix, className, selectProps }) => {
    const { value, label } = data;
    const containerClass = classNameBuilder(className, { disabled: selectProps.isOptionDisabled(data) });

    if (!value && !label) {
        return null;
    }

    return (
        <span className={containerClass}>
            {ReactIs.isElement(label) ? (
                label
            ) : (
                <FormattedMessage id={`${msgIdPrefix}.${value}`} defaultMessage={label} />
            )}
        </span>
    );
};

DropdownOption.propTypes = {
    data: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
    className: PropTypes.string,
    msgIdPrefix: PropTypes.string,
    selectProps: PropTypes.shape({
        isOptionDisabled: PropTypes.func,
    }),
};

DropdownOption.defaultProps = {
    msgIdPrefix: '',
    data: {},
    selectProps: {
        isOptionDisabled: () => false,
    },
};

export default DropdownOption;
