import React from 'react';
import { StripeCardCVVField } from './StripeCardCVV/StripeCardCVVField';
import { StripeCardNumberField } from './StripeCardNumber/StripeCardNumberField';
import { StripeExpirationDateField } from './StripeExpirationDate/StripeExpirationDateField';
import styles from './stripeCardInfo.module.scss';

export const StripeCreditCardInfo = () => {
    return (
        <div className={styles['container']}>
            <StripeCardNumberField />
            <div className={styles['bottom-container']}>
                <StripeExpirationDateField />
                <StripeCardCVVField />
            </div>
        </div>
    );
};
