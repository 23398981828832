import React from 'react';
import { batch } from 'react-redux';
import {
    duplicatePerformanceRuleError,
    duplicatePerformanceRuleSuccess,
    requestDuplicatePerformanceRule,
} from 'modules/performance-rules/actions/duplicatePerformanceRule';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { performanceRulesApi } from 'services/api';
import { errorMessagesUtils } from 'services/utils';

export const successIndication = {
    message: <FormattedMessage id="performance.rules.save.success" defaultMessage="Rule successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Unable to save changes." />,
});

export const duplicatePerformanceRule =
    ({ accountName, queryParams, onError, onSuccess }) =>
    async dispatch => {
        dispatch(requestDuplicatePerformanceRule());
        try {
            const duplicateRuleResponse = await performanceRulesApi.duplicatePerformanceRules(accountName, queryParams);

            batch(() => {
                dispatch(duplicatePerformanceRuleSuccess(duplicateRuleResponse));
                dispatch(addIndication(successIndication));
            });
            if (onSuccess) {
                onSuccess();
            }
            return duplicateRuleResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(duplicatePerformanceRuleError(error));
            if (onError) {
                onError(error);
            }
            throw error;
        }
    };
