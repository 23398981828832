import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { optionToImageMap } from './config/options';
import styles from './promptHelperModalContent.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PromptHelperModalContent = ({ onClose, options, optionValue }) => {
    const [selectedOption, setSelectedOption] = useState(optionValue);

    return (
        <ModalFormLayout
            onCancel={onClose}
            onSubmit={() => {
                onClose({ accepted: true, stagedOption: selectedOption });
            }}
            hasCancel={true}
            submitButtonText={
                <FormattedMessage
                    id="creative.editor.tab.aiGenerated.fromPrompt.helper.modal.continue"
                    defaultMessage="Continue"
                />
            }
        >
            <div className={styles['content']}>
                {options.map(option => (
                    <div className={classNameBuilder('option', { selected: selectedOption === option })} key={option}>
                        <Button
                            className={classNameBuilder('button', { selected: selectedOption === option })}
                            size={Button.size.md}
                            variant={Button.variant.text}
                            onClick={() => {
                                if (selectedOption === option) {
                                    setSelectedOption(null);
                                } else {
                                    setSelectedOption(option);
                                }
                            }}
                        >
                            {optionToImageMap[option].image && (
                                <img className={styles['image']} src={optionToImageMap[option].image} alt={option} />
                            )}
                            <FormattedMessage
                                id={`creative.editor.tab.aiGenerated.fromPrompt.helper.options.${option}`}
                                defaultMessage={option}
                            />
                        </Button>
                    </div>
                ))}
            </div>
        </ModalFormLayout>
    );
};
