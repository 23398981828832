import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';

export const ENTITY_TYPES = {
    CAMPAIGN: 'CAMPAIGN',
    CAMPAIGNS_GROUP_ID: 'CAMPAIGNS_GROUP_ID',
    CUSTOM_RULE: 'CUSTOM_RULE',
};
export const CONDITION_DIMENSIONS = {
    CAMPAIGN_BY_DAY: 'CAMPAIGN_BY_DAY',
    ADS_BY_DAY: 'ADS_BY_DAY',
    CAMPAIGNS_GROUP_BY_DAY: 'CAMPAIGNS_GROUP_BY_DAY',
    CUSTOM_RULES_BY_DAY: 'CUSTOM_RULES_BY_DAY',
};

export const dimensionOptions = [
    { value: CONDITION_DIMENSIONS.CAMPAIGN_BY_DAY, label: 'Campaign By Day' },
    { value: CONDITION_DIMENSIONS.ADS_BY_DAY, label: 'Ads By Day' },
    {
        value: CONDITION_DIMENSIONS.CUSTOM_RULES_BY_DAY,
        label: 'Custom Rules By Day',
        visibilityCondition: ({ accountConfig }) =>
            accountConfig?.[FEATURE_FLAGS.SCHEDULED_REPORTS_CUSTOM_RULE_REPORT_ENABLED] === 'true',
    },
];

export const dspDimensionOptions = [
    ...dimensionOptions,
    { value: CONDITION_DIMENSIONS.CAMPAIGNS_GROUP_BY_DAY, label: 'Campaign Group By Day' },
];

export const dimensionEntityTypeMap = {
    CAMPAIGN_BY_DAY: ENTITY_TYPES.CAMPAIGN,
    ADS_BY_DAY: ENTITY_TYPES.CAMPAIGN,
    CAMPAIGNS_GROUP_BY_DAY: ENTITY_TYPES.CAMPAIGNS_GROUP_ID,
    CUSTOM_RULES_BY_DAY: ENTITY_TYPES.CUSTOM_RULE,
};
