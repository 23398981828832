import { FormField } from 'taboola-ultimate-ui';
import { useCampaignGroupExtensionEnabled } from '../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { CampaignGroupMultiSelect } from './Components/CampaignGroup/CampaignGroupMultiSelect';

export const CampaignGroupMultiSelectField = props => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    if (!campaignGroupExtensionEnabled) {
        return null;
    }

    return (
        <FormField>
            <CampaignGroupMultiSelect {...props} />
        </FormField>
    );
};
