import { VIDEO_CREATIVE_CREATION_METHOD } from '../../video-creative-creator/config/CreationMethod';
import { defaultLogoPosition } from './videoCreativeLogoPositionOptions';
import { defaultSubtitlesType } from './videoCreativeSubtitlesOptions';

export const creativeInitialValues = {
    title: '',
    description: '',
    brandName: '',
    mediaType: VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO,
    pixels: {},
    videoUploadAssets: {
        logo: {
            position: defaultLogoPosition,
        },
        subtitles: {
            type: defaultSubtitlesType,
        },
    },
};
