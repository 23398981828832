import { useCallback, useMemo, useState, useRef } from 'react';
import { differenceBy, isEmpty, keyBy, keys, negate, sum, uniqBy, values, partition } from 'lodash';
import { LoadingMode, NodeSelectionMode, generatePath, generateSubPathsMap, generateTree } from '../utils';
import useCollapseState from './useCollapseState';
import useLoadingWrapper from './useLoadingWrapper';

const generateSelectedPathsMap = (selectedLeaves, getSelectionModeByPath) => {
    const pathsMap = selectedLeaves.reduce((result, leaf) => {
        const subPathsMap = generateSubPathsMap(leaf, getSelectionModeByPath);
        return { ...result, ...subPathsMap };
    }, {});

    return pathsMap;
};

const defaultIsDisabledLeaf = () => false;
const defaultLoadAll = () => [];
const DEFAULT_MAX_AMOUNT_TO_SELECT = 1000000;
const useTreeState = ({
    search,
    leaves,
    loadingPaths,
    totals,
    loadMore,
    loadAll = defaultLoadAll,
    reload,
    initialSelectedIds = [],
    loadByIds,
    leafTransformer,
    getTaxonomyValue,
    formatMessage,
    isDisabledLeaf = defaultIsDisabledLeaf,
    defaultMaxAmountToSelect = DEFAULT_MAX_AMOUNT_TO_SELECT,
    parentTransformer,
    sortSegments,
}) => {
    const totalCount = useMemo(() => sum(values(totals)), [totals]);
    const selectedIds = useRef(initialSelectedIds);
    // TODO DEV-74625 Add sync market audience initialisation feature
    const [selectedLeaves, setSelectedLeaves] = useState([]);
    const { wrapWithLoading, loadingMode } = useLoadingWrapper({ leaves, totals });
    const isSearchMode = loadingMode === LoadingMode.NONE && !isEmpty(search);

    const { expandAll, collapseAll, ...restCollapseState } = useCollapseState();

    const getSelectionModeByPath = useCallback(
        (path, leaf) => {
            if (generatePath(leaf.id, leaf.taxonomy) === path) {
                return NodeSelectionMode.SELECTED;
            }

            const total = totals[path] || Number.MAX_SAFE_INTEGER;
            const selectedLeavesUnderPath = selectedLeaves.filter(({ taxonomy }) => taxonomy === path);
            if (total > selectedLeavesUnderPath.length) {
                return NodeSelectionMode.INDETERMINATE;
            }

            const leavesUnderPath = leaves.filter(({ taxonomy }) => taxonomy === path);
            const unselectedLeaves = differenceBy(leavesUnderPath, selectedLeaves, 'id');

            if (leavesUnderPath.length === unselectedLeaves.length) {
                return NodeSelectionMode.UNSELECTED;
            }

            if (unselectedLeaves.length === 0) {
                return NodeSelectionMode.SELECTED;
            }

            return NodeSelectionMode.INDETERMINATE;
        },
        [leaves, totals, selectedLeaves]
    );

    const selectedPathsMap = useMemo(
        () => generateSelectedPathsMap(selectedLeaves, getSelectionModeByPath),
        [selectedLeaves, getSelectionModeByPath]
    );

    const disabledPathsMap = useMemo(
        () =>
            leaves.reduce((result, leaf) => {
                const { id, taxonomy } = leaf;
                const idPath = generatePath(id, taxonomy);
                return { ...result, [idPath]: isDisabledLeaf(leaf) };
            }, {}),
        [leaves, isDisabledLeaf]
    );

    const handleReload = useCallback(
        () =>
            wrapWithLoading([], async () => {
                const loadedData = await reload();
                if (!isEmpty(search)) {
                    expandAll();
                } else {
                    collapseAll(loadedData);
                }

                return loadedData;
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [collapseAll, expandAll, reload, search]
    );

    const handleRemoveItem = useCallback(
        ({ id }) => setSelectedLeaves(prevState => prevState.filter(item => item.id !== id)),
        [setSelectedLeaves]
    );

    const handleRemoveAllItems = useCallback(() => setSelectedLeaves([]), [setSelectedLeaves]);

    const setSelectedLeavesByPath = useCallback(
        (leaves, paths, checked, maxAmountToSelect = defaultMaxAmountToSelect) => {
            const isSelectedLeaf = ({ id, taxonomy }) =>
                paths.some(path => taxonomy.startsWith(path) || path === generatePath(id, taxonomy));

            const filteredLeavesUnderPath = leaves
                .filter(isSelectedLeaf)
                .filter(negate(isDisabledLeaf))
                .slice(0, maxAmountToSelect);
            if (!checked) {
                const selectedLeavesMap = keyBy(filteredLeavesUnderPath, 'id');
                setSelectedLeaves(prevStat => prevStat.filter(({ id }) => !selectedLeavesMap[id]));
                return;
            }

            setSelectedLeaves(prevState => uniqBy([...filteredLeavesUnderPath, ...prevState], 'id'));
        },
        [isDisabledLeaf, defaultMaxAmountToSelect, setSelectedLeaves]
    );

    const handleSelectByPaths = useCallback(
        async (paths, checked, maxAmountToSelect = defaultMaxAmountToSelect) => {
            setSelectedLeavesByPath(leaves, paths, checked, maxAmountToSelect);

            const restLeavesUnderPath = await wrapWithLoading(paths, () => loadAll(paths));

            setSelectedLeavesByPath([...restLeavesUnderPath, ...leaves], paths, checked, maxAmountToSelect);
        },
        [setSelectedLeavesByPath, wrapWithLoading, leaves, loadAll, defaultMaxAmountToSelect]
    );

    const handleSelectNode = useCallback(
        async (path, { id: itemId }, checked, maxAmountToSelect = defaultMaxAmountToSelect) => {
            const selectedLeaf = leaves.find(({ id }) => id === itemId);
            if (!selectedLeaf) {
                await handleSelectByPaths([path], checked, maxAmountToSelect);
                return;
            }

            if (!checked) {
                handleRemoveItem(selectedLeaf);
                return;
            }

            setSelectedLeaves(prevState => [selectedLeaf, ...prevState]);
        },
        [leaves, handleRemoveItem, handleSelectByPaths, defaultMaxAmountToSelect, setSelectedLeaves]
    );

    const handleLoadMore = useCallback(
        (taxonomy, pageSize) => wrapWithLoading([taxonomy], () => loadMore({ taxonomies: [taxonomy], pageSize })),
        [loadMore, wrapWithLoading]
    );

    const handleSelectAll = useCallback(
        (checked, maxAmountToSelect = defaultMaxAmountToSelect) =>
            handleSelectByPaths(keys(totals), checked, maxAmountToSelect),
        [totals, handleSelectByPaths, defaultMaxAmountToSelect]
    );

    // Optimization: no need to add all dependencies in order to generate the tree once loadingMode flag is changed
    const nodesTree = useMemo(
        () =>
            generateTree({
                leaves,
                totals,
                leafTransformer,
                loadingPaths,
                loadingMode,
                getTaxonomyValue,
                formatMessage,
                parentTransformer,
                sortSegments,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingMode, getTaxonomyValue]
    );

    const [isReady, setIsReady] = useState(false);

    const setSelectedLeavesByIds = useCallback(
        async (ids = selectedIds.current) => {
            const leavesMap = keyBy(leaves, 'id');
            const [offlineSelectedIds, remoteSelectedIds] = partition(ids, id => leavesMap[id]);
            const offlineSelectedLeaves = offlineSelectedIds.map(id => leavesMap[id]);
            let remoteSelectedLeaves = [];
            if (!isEmpty(remoteSelectedIds)) {
                remoteSelectedLeaves = await loadByIds(remoteSelectedIds);
            }

            setSelectedLeaves([...offlineSelectedLeaves, ...remoteSelectedLeaves]);
            setIsReady(true);
        },
        [loadByIds, leaves]
    );

    return {
        totalCount,
        selectedLeaves,
        selectedPathsMap,
        handleRemoveItem,
        handleRemoveAllItems,
        handleSelectNode,
        handleSelectAll,
        handleLoadMore,
        handleReload,
        nodesTree,
        isSearchMode,
        setSelectedLeavesByIds,
        disabledPathsMap,
        search,
        loadingMode,
        expandAll,
        collapseAll,
        isReady,
        setIsReady,
        ...restCollapseState,
    };
};

export default useTreeState;
