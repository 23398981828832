import React from 'react';
import { FORM_MODES } from 'config/formModes';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const FORM_MODE_CONFIG = {
    [FORM_MODES.EDIT]: {
        headerLabel: <FormattedMessage id="creative.editor.edit.header" defaultMessage="Edit Ad" tagName="h3" />,
    },
    [FORM_MODES.DUPLICATE]: {
        headerLabel: (
            <FormattedMessage id="creative.editor.duplicate.header" defaultMessage="Duplicate Ad" tagName="h3" />
        ),
        hideStatusSection: true,
        submitButtonLabel: (
            <FormattedMessage id="creative.editor.duplicate.submit.button" defaultMessage="DUPLICATE AD" />
        ),
        addIdToMatchingCreativeIds: true,
        getSubmitDataMetricAttrs: ({ creative: { selectedCampaigns } = {} }) => ({
            'data-metrics-component': 'Duplicate Ad Window to Multiple Campaigns',
            'data-metrics-column-name': '',
            'data-metrics-value': (selectedCampaigns || []).length,
        }),
        showSetupSection: true,
    },
};

export default FORM_MODE_CONFIG;
