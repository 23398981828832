import React from 'react';
import { keyBy } from 'lodash';
import { FormField, AudienceTypeIcon, DocumentIcon } from 'taboola-ultimate-ui';
import TypeRadioIconGroup from 'components/TypeRadioIconGroup/TypeRadioIconGroup';
import { FORM_MODES } from 'config/formModes';
import { useTypeSubtext } from 'hooks';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useUserPermissionOrFeatureFlagEnabled } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { AUDIENCE_TYPE } from '../../config';
import { useAudienceTypeFormField } from '../../hooks/useAudienceTypeFormField';

const configs = [
    {
        id: AUDIENCE_TYPE.PBP_LOOKALIKE,
        icon: AudienceTypeIcon,
        label: 'PIXEL AUDIENCE',
        subtext: 'Pixel Audience',
    },
    {
        id: AUDIENCE_TYPE.LOOKALIKE,
        icon: DocumentIcon,
        label: 'CUSTOMER FILE',
        subtext: 'Available only for <strong>US</strong> based emails',
    },
];

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'audience.editor.validations.error.audience.type.empty',
        defaultMessage: 'Select an Audience Type',
    },
];

const configByKeys = keyBy(configs, 'id');

export const AudienceTypeField = () => {
    const { mode, value: type, onChange: onTypeChange } = useAudienceTypeFormField();
    const msgPrefix = 'audience.editor.predictive.audience.type';
    const { subtext, setHovered } = useTypeSubtext(type, configByKeys, msgPrefix);
    const pixelLookalikePermitted = useUserPermissionOrFeatureFlagEnabled(
        'CREATE_PIXEL_PREDICTIVE',
        FEATURE_FLAGS.PIXEL_PREDICTIVE_ENABLED
    );
    return (
        <FormField
            inputId="audience-type"
            label={
                pixelLookalikePermitted && (
                    <FormattedMessage
                        id="audience.editor.predictive.audience.type.selection.title"
                        defaultMessage="Seed Selection"
                    />
                )
            }
            subtext={pixelLookalikePermitted && subtext}
        >
            {pixelLookalikePermitted && (
                <TypeRadioIconGroup
                    name="audience-type"
                    type={type}
                    onTypeChange={onTypeChange}
                    configs={configs}
                    onHover={key => setHovered(key)}
                    msgPrefix={msgPrefix}
                    disabled={mode === FORM_MODES.EDIT}
                    validations={validations}
                    formFieldDependencies={{ pixelLookalikePermitted }}
                />
            )}
        </FormField>
    );
};

export default AudienceTypeField;
