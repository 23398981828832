import {
    PERMISSIONS_OPERATORS,
    BACKSTAGE_CONTENT_PERMISSION,
} from '../../modules/taboola-common-frontend-modules/authentication';
import { hybridModuleFactory } from '../backstage-content';

const MODULE_NAME = 'user-management';
const externalPath = 'reports/admin/user-management';
const ModuleComponent = hybridModuleFactory(externalPath);
const modulePermissions = {
    permissions: ['LIGHT_USER_EDIT', 'USER_EDIT'],
    operator: PERMISSIONS_OPERATORS.OR,
};
const permissions = {
    permissions: [BACKSTAGE_CONTENT_PERMISSION, modulePermissions],
    operator: PERMISSIONS_OPERATORS.AND,
};

export { MODULE_NAME, ModuleComponent, permissions, externalPath };
