import React from 'react';
import { generatePath } from 'react-router';
import { PlusIcon } from 'taboola-ultimate-ui';
import { DropdownMenuLinkOption } from 'components/Dropdown/DropdownMenuLinkOption/DropdownMenuLinkOption';
import { FORM_MODES } from '../../../../../../../config/formModes';
import {
    ENTITY_PARAM_NAME,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_NAME,
    MODULE_PARAM_NAME,
} from '../../../../../../../config/routes';
import { EDITOR_ROUTE_PATH, ENTITY, ENTITY_TO_PATH_MAP, MODULE_NAME } from '../../../../../../audiences/config';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './dropdownCreateSegmentOption.module.scss';

const customContextualCreatorPagePath = generatePath(EDITOR_ROUTE_PATH, {
    [MODULE_PARAM_NAME]: MODULE_NAME,
    [ENTITY_PARAM_NAME]: ENTITY_TO_PATH_MAP[ENTITY.CUSTOM_CONTEXTUAL],
    [MODE_PARAM_NAME]: FORM_MODE_TO_PATH_MAP[FORM_MODES.CREATE],
});

export const DropdownCreateSegmentOption = () => (
    <DropdownMenuLinkOption to={customContextualCreatorPagePath} target="_blank" rel="noopener noreferrer">
        <PlusIcon className={styles['icon']} />
        <FormattedMessage
            id="campaign.editor.targeting.custom.contextual.dropdown.create.segment"
            defaultMessage="Create Topics Segment"
        />
    </DropdownMenuLinkOption>
);
