import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormBreadcrumbs } from '../../../campaigns/modules/common-campaign-form';
import useAudienceBreadcrumbs from '../../hooks/useAudienceBreadcrumbs';
import styles from '../../pages/audiencesCreatorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const AudienceFormBreadcrumbs = ({ className, reportId, title, addAllAudiencesStep }) => {
    const { steps } = useAudienceBreadcrumbs({
        selectedReport: reportId,
        addAllAudiencesStep,
        title,
    });

    return (
        <FormBreadcrumbs title={title} steps={steps} className={classNameBuilder('breadcrumbs-container', className)} />
    );
};

AudienceFormBreadcrumbs.propTypes = {
    reportId: PropTypes.string,
    titleId: PropTypes.string,
    titleDefaultMessage: PropTypes.array,
};

export default AudienceFormBreadcrumbs;
