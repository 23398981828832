import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CampaignMissingFeature } from 'components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useValidationsApi } from 'services/api/useValidationsApi';

const campaignClickersMessageId = 'campaign.editor.targeting.campaignClickers';

const MissingTargetingFeatures = ({ className }) => {
    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const [missingCampaignClickersFeature, setMissingCampaignClickersFeature] = useState(null);

    const { validateCampaignClickers } = useValidationsApi();

    const fetchAndSetMissingCampaignClickersFeature = useCallback(async () => {
        try {
            const hasCampaignClickers = await validateCampaignClickers(campaignId);
            if (hasCampaignClickers) {
                setMissingCampaignClickersFeature({
                    messageId: campaignClickersMessageId,
                    key: `Targeting_${campaignClickersMessageId}`,
                });
            }
        } catch (e) {
            // silent
        }
    }, [campaignId, validateCampaignClickers]);

    useEffect(() => {
        fetchAndSetMissingCampaignClickersFeature();
    }, [fetchAndSetMissingCampaignClickersFeature]);

    const featuresData = [missingCampaignClickersFeature].filter(Boolean);

    return <CampaignMissingFeature featuresData={featuresData} className={className} />;
};

MissingTargetingFeatures.propTypes = {
    className: PropTypes.string,
};

export default MissingTargetingFeatures;
