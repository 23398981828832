export const REQUEST_UPDATE_CONVERSION = 'REQUEST_UPDATE_CONVERSION';
export const UPDATE_CONVERSION_SUCCESS = 'UPDATE_CONVERSION_SUCCESS';
export const UPDATE_CONVERSION_ERROR = 'UPDATE_CONVERSION_ERROR';
export const REQUEST_DELETE_FUNNEL = 'REQUEST_DELETE_FUNNEL';
export const DELETE_FUNNEL_SUCCESS = 'DELETE_FUNNEL_SUCCESS';
export const DELETE_FUNNEL_ERROR = 'DELETE_FUNNEL_ERROR';
export const REQUEST_UPDATE_FUNNEL = 'REQUEST_UPDATE_FUNNEL';
export const UPDATE_FUNNEL_SUCCESS = 'UPDATE_FUNNEL_SUCCESS';
export const UPDATE_FUNNEL_LIST_SUCCESS = 'UPDATE_FUNNEL_LIST_SUCCESS';
export const UPDATE_FUNNEL_ERROR = 'UPDATE_FUNNEL_ERROR';
