import { useState, useMemo, useCallback } from 'react';
import { forEach, map } from 'lodash';
import { set as fpSet } from 'lodash/fp';
import { EMPTY_SEGMENT_GROUP_COUNT, SEGMENT_GROUP_TYPE } from '../utils/andLogicUtils';

const getTypeCountMap = (segmentTypesArray, index) => {
    const typeCountMap = { ...EMPTY_SEGMENT_GROUP_COUNT };

    forEach(segmentTypesArray, (segmentType, otherIndex) => {
        if (index !== otherIndex) {
            typeCountMap[segmentType]++;
        }
    });

    return typeCountMap;
};

export const useCombinedAudienceIngredientsTypeCounts = combinedAudienceIngredientsTargetingCollection => {
    const [combinedAudienceIngredientsType, setCombinedAudienceIngredientsType] = useState([]);

    const setCombinedAudienceIngredientsTypeByIndex = useCallback((index, type) => {
        // only update if the type is different
        setCombinedAudienceIngredientsType(prev => (prev[index] === type ? prev : fpSet(index, type, prev)));
    }, []);

    // map index to an object with a count representing the other targetings segment type
    //  (using combinedAudienceIngredientsTargetingCollection so that we don't have to clean up combinedAudienceIngredientsType)
    // e.g. [{ AGE: 1, GENDER: 0, OTHER: 1, EMPTY: 1 }, { AGE: 0, GENDER: 1, OTHER: 1, EMPTY: 1 }]
    const combinedAudienceIngredientsTypeCounts = useMemo(() => {
        const segmentTypesArray = map(
            combinedAudienceIngredientsTargetingCollection,
            (_, index) => combinedAudienceIngredientsType[index] || SEGMENT_GROUP_TYPE.EMPTY
        );

        return map(combinedAudienceIngredientsTargetingCollection, (_, index) =>
            getTypeCountMap(segmentTypesArray, index)
        );
    }, [combinedAudienceIngredientsTargetingCollection, combinedAudienceIngredientsType]);

    return { setCombinedAudienceIngredientsTypeByIndex, combinedAudienceIngredientsTypeCounts };
};
