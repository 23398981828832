import { useMemo, useState } from 'react';
import { Button, CloseIcon } from 'tuui';
import { LoadingAnimation } from 'components/LoadingAnimation';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { COMPONENT_STATUS } from 'services/constants';
import {
    TRY_GEN_AI_TOOLTIP_PAGES,
    TRY_GEN_AI_TOOLTIP_PAGE_CONFIG,
    GEN_AI_TOOLTIP_CLOSES_KEY,
} from './consts/tryGenAiTooltipConsts';
import { useTryGenAiTooltip } from './hooks/useTryGenAiTooltip';
import { useTryGenAiTooltipDismissedForSession } from './hooks/useTryGenAiTooltipDismissedForSession';
import styles from './tryGenAiTooltip.module.scss';

const gaEventsPrefix = 'Try_Gen_AI_Tooltip';

export const TryGenAiTooltip = ({ onClose, onClick, value, formMode }) => {
    const imageSetNumber = useMemo(() => Math.floor(Math.random() * 4), []);
    const [page, setPage] = useState(TRY_GEN_AI_TOOLTIP_PAGES.REPLACE_BACKGROUND);
    const [, setLocalStorage] = useLocalStorage();
    const [videoStatus, setVideoStatus] = useState(COMPONENT_STATUS);
    const { setDismissed } = useTryGenAiTooltipDismissedForSession();

    const { getClosedCount } = useTryGenAiTooltip();

    const { [COMMON_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES]: maxGenAiTooltipCloseCount } = useCommonConfig([
        COMMON_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES,
    ]);
    let maxGenAiTooltipCloseCountInt = parseInt(maxGenAiTooltipCloseCount);

    if (isNaN(maxGenAiTooltipCloseCount)) {
        maxGenAiTooltipCloseCountInt = 0;
    }

    const onChangePage = () => {
        setVideoStatus(COMPONENT_STATUS.LOADING);
        setPage(
            page === TRY_GEN_AI_TOOLTIP_PAGES.REPLACE_BACKGROUND
                ? TRY_GEN_AI_TOOLTIP_PAGES.GENERATE_VARIATIONS
                : TRY_GEN_AI_TOOLTIP_PAGES.REPLACE_BACKGROUND
        );
    };

    const isReplaceBackgroundPage = page === TRY_GEN_AI_TOOLTIP_PAGES.REPLACE_BACKGROUND;

    return (
        <div
            className={styles['container']}
            onClick={e => {
                // prevents click from trickling down to dropdown
                e.stopPropagation();
            }}
            data-testid="try-gen-ai-tooltip"
        >
            <div className={styles['video-container']}>
                {videoStatus === COMPONENT_STATUS.LOADING && <LoadingAnimation className={styles['loader']} />}
                <video
                    src={`${process.env.PUBLIC_URL}/previewsDrawerAssets/${TRY_GEN_AI_TOOLTIP_PAGE_CONFIG[page].videoPrefix}-animation-${imageSetNumber}.mov`}
                    alt="preview-replace-background"
                    className={styles['video']}
                    onLoadedData={e => {
                        setVideoStatus(COMPONENT_STATUS.ACTIVE);
                        e.target.play();
                    }}
                />
            </div>
            <button
                className={styles['close-button']}
                data-testid="try-gen-ai-tooltip-x-button"
                onClick={e => {
                    const oldValue = getClosedCount();

                    setLocalStorage(GEN_AI_TOOLTIP_CLOSES_KEY, oldValue + 1);
                    setDismissed(true);

                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${gaEventsPrefix}_Close_Button_Clicked`,
                        value,
                        pageName: formMode,
                    });

                    if (oldValue + 1 >= maxGenAiTooltipCloseCountInt) {
                        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                            component: `${gaEventsPrefix}_MAX_CLOSES_REACHED`,
                            value,
                            pageName: formMode,
                        });
                    }
                    onClose(e);
                }}
            >
                <div className={styles['background']} />
                <CloseIcon className={styles['icon']} />
            </button>
            <div className={styles['content']}>
                <FormattedMessage
                    className={styles['title']}
                    id={`previews.drawer.card.title.${TRY_GEN_AI_TOOLTIP_PAGE_CONFIG[page].messageId}`}
                    defaultMessage="Create Seasonal Background"
                />
                <FormattedMessage
                    className={styles['subtitle']}
                    id={`previews.drawer.card.body.${TRY_GEN_AI_TOOLTIP_PAGE_CONFIG[page].messageId}`}
                    defaultMessage="Add a refreshing touch to your visuals with new background."
                />
                <Button
                    className={styles['try-it-button']}
                    size={Button.size.sm}
                    onClick={() => {
                        onClick(TRY_GEN_AI_TOOLTIP_PAGE_CONFIG[page].tryItValue);
                        onClose();

                        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                            component: `${gaEventsPrefix}_Try_It_Button_Clicked_${TRY_GEN_AI_TOOLTIP_PAGE_CONFIG[page].gtmSuffix}`,
                            value,
                            pageName: formMode,
                        });
                    }}
                >
                    <FormattedMessage
                        className={styles['text']}
                        id="previews.drawer.card.submit.button"
                        defaultMessage="Try It Now"
                    />
                </Button>
                <div className={styles['footer']}>
                    <div className={styles['page']}>{page}/2</div>
                    <Button
                        className={styles['next']}
                        variant={Button.variant.text}
                        onClick={() => {
                            onChangePage();

                            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                                component: `${gaEventsPrefix}_CHANGE_PAGE_BUTTON_CLICKED_${
                                    isReplaceBackgroundPage ? 'NEXT' : 'BACK'
                                }`,
                                value,
                                pageName: formMode,
                            });
                        }}
                    >
                        <FormattedMessage
                            id={isReplaceBackgroundPage ? 'app.actionButtons.next' : 'app.actionButtons.back'}
                            defaultMessage={isReplaceBackgroundPage ? 'Next' : 'Back'}
                        />
                    </Button>
                </div>
            </div>
        </div>
    );
};
