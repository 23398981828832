import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useCurrentValueGetter } from 'hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { usePreviewsDrawerModal } from '../../PreviewsDrawer/hooks/usePreviewsDrawerModal';
import { CroppingToolsForm } from '../CroppingToolsForm';
import { useCropImageModalFormFields } from './useCropImageModalFormFields';
import styles from './useCropImageModal.module.scss';

export const useCropImageModal = ({ campaignId, onSelectMedia, titleList }) => {
    const { open: openModal } = useModal();
    const {
        formAccount: { accountId },
        mode: formMode,
    } = useFormDataContext();

    const [src, setSrc] = useState(null);

    const { setCropData, setThumbnailSrc } = useCropImageModalFormFields(src, setSrc);

    const isPreviewsDrawerFFEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_OPTIMIZATION_PREVIEWS_DRAWER_ENABLED]: 'true',
    });

    // previews drawer only enabled in Create Form
    const isPreviewsDrawerEnabled = isPreviewsDrawerFFEnabled && formMode === FORM_MODES.CREATE;

    const { openModal: openPreviewsDrawerModal } = usePreviewsDrawerModal({
        campaignId,
        titleList,
        accountId,
        onSelectMedia,
    });
    const getOpenPreviewsDrawerModal = useCurrentValueGetter(openPreviewsDrawerModal);

    const openCropImageModal = useCallback(
        async ({ src, metadata: { shouldEnableCropSubmitAlways } = {}, shouldSkipPreview }) => {
            setSrc(src);
            const { cropData, src: newSrc } = await openModal({
                title: null,
                contentRenderer: ({ onClose }) => (
                    <CroppingToolsForm
                        src={src}
                        campaignId={campaignId}
                        shouldEnableCropSubmitAlways={shouldEnableCropSubmitAlways}
                        onClose={onClose}
                    />
                ),
                className: styles['modal'],
                contentClassName: styles['modal-content'],
                headerClassName: styles['modal-header'],
            });

            // The cropData is null if the user skips
            if (!isEmpty(cropData)) {
                setCropData(cropData);
            }

            // if the image was outcropped, update the selected thumbnail's src
            if (newSrc && src !== newSrc) {
                setThumbnailSrc(newSrc);
            }

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: 'Cropping Modal',
                value: 'open modal',
            });

            if (isPreviewsDrawerEnabled && !shouldSkipPreview) {
                const openPreviewsDrawerModal = getOpenPreviewsDrawerModal();

                // Recursion here because the crop modal needs to open again in certain cases after the PreviewsDrawerModal closes
                // This won't result in an infinite loop because when openPreviewsDrawerModal calls openCropImageModal,
                // it will have shouldSkipPreview as true so openPreviewersDrawerModal won't run again
                openPreviewsDrawerModal({ src: newSrc ? newSrc : src, openCropImageModal });
            }
        },
        [campaignId, getOpenPreviewsDrawerModal, isPreviewsDrawerEnabled, openModal, setCropData, setThumbnailSrc]
    );

    return { openModal: openCropImageModal };
};
