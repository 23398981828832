import React from 'react';
import { MediaUploadIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { VideoFileUploadDragAndDrop } from './VideoFileUploadDragAndDrop';

export const VideoMediaFileUploadSection = () => {
    return (
        <CommonCollapsibleCard
            id="VIDEO_MEDIA_UPLOAD"
            header={
                <SectionHeader
                    headerIcon={<MediaUploadIcon />}
                    headerText={
                        <FormattedMessage id="video.creative.creator.media.section.title" defaultMessage="Media" />
                    }
                />
            }
        >
            <FormField
                label={<FormattedMessage id="video.creative.creator.media.form.field.label" defaultMessage="Media" />}
                helpText={
                    <FormattedMessage
                        id="video.creative.creator.media.form.field.tooltip"
                        defaultMessage="Media Type: MP4, MOV{br}3rd Party Tag Specifications: VAST 2.0, VAST 2.0 with VPAID 2.0 JS, VAST 3.0{br}Aspect Ratios: 16:9 (preferred), 4:5, 1:1{br}Video Size Limit: 100 MB{br}Bitrate: >=2500 kbps{br}Video length: :06-:15 sec (preferred), 3min maximum"
                    />
                }
                description={
                    <FormattedMessage
                        id="video.creative.creator.media.form.field.description"
                        defaultMessage="To upload a video file, drag it to the area below or click on the upload file button below.{br}<a>Best Practices</a>"
                    />
                }
            >
                <VideoFileUploadDragAndDrop />
            </FormField>
        </CommonCollapsibleCard>
    );
};
