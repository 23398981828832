import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { ValidationState } from 'modules/taboola-common-frontend-modules/validations/services/validationService';

export function useGroupedValidations(validationsState) {
    const validationIdsByState = useMemo(() => {
        return groupBy(Object.keys(validationsState), fieldId => {
            const { state } = validationsState[fieldId];
            return state;
        });
    }, [validationsState]);

    const errors = validationIdsByState[ValidationState.Failed] ?? [];
    const warnings = validationIdsByState[ValidationState.Warning] ?? [];

    return { errors, warnings };
}
