import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const ThirdPartyBrandSafetyTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.3rd.party.brand.safety.help"
                defaultMessage="Taboola has partnered with DoubleVerify (DV) and Integral Ad Science (IAS) to offer marketers brand safety and brand suitability pre-bid targeting and avoidance capabilities."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.targeting.3rd.party.brand.safety.help.explain"
                defaultMessage="These third parties scan Taboola's network of publisher sites and apps, assigning brand safety risk tiers and brand suitability avoidance capabilities across a variety of topics. By applying brand safety and brand suitability pre-bid targeting/avoidance, Taboola will prevent your ad from serving on all sites and apps which fail to meet the brand safety and suitability criteria you selected."
            />
        </TooltipSection>
    </div>
);

export default ThirdPartyBrandSafetyTooltip;
