import { isNil } from 'lodash';
import { EXPLORATION_STATUS } from 'modules/campaigns/config/siteConsts';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { isVideoCampaign } from 'services/campaignDimension';
import { isPerformanceRulesActiveOnAccountLevel, onlySelectedCampaignColumn } from './commonPredicates';

const isSiteBlockingDisabled = data => !data.isBlockPublisherAllowed;

const isSiteStatusEditable = ({ data = {}, context }) => {
    const { userPermissions, selectedAccountConfig } = context;
    return (
        (data.explorationStatus &&
            data.explorationStatus === EXPLORATION_STATUS.IN_PROGRESS &&
            userPermissions['EXPLORATION_BAR_AND_STATUS']) ||
        (data.publisherBlockingLevel === 'NONE' &&
            data.safetyNetsStatus &&
            userPermissions['SAFETY_NETS_VIEW'] &&
            isSpendGuardActiveOnAccountLevel(selectedAccountConfig)) ||
        (userPermissions['SPEND_GUARD_ACTION_DETAILS_VIEW'] && data.isPublisherBlocked && data.spendGuardsActionType) ||
        (isPerformanceRulesActiveOnAccountLevel(selectedAccountConfig) && !!data.performanceRuleId)
    );
};

const isTooltipDisabled = data => isSiteBlockingDisabled(data) && !isVideoCampaign(data.dimension);
const isBidStrategyPerAffiliateEditable = params =>
    params.data.bidStrategy !== BID_STRATEGIES.FIXED && params.data.bidStrategy !== BID_STRATEGIES.TARGET_CPA;

const isVideoAccountReport = data => isVideoCampaign(data.dimension);

const isSpendGuardActiveOnAccountLevel = selectedAccountConfig =>
    selectedAccountConfig?.[FEATURE_FLAGS.SAFETY_NETS_ENABLED_CONFIG_ATTRIBUTE] === 'true';

export const isCpcBasedBidStrategy = ({ selectedCampaign }) =>
    !isNil(selectedCampaign?.bidStrategy) &&
    selectedCampaign?.bidStrategy !== BID_STRATEGIES.MAX_CONVERSIONS &&
    selectedCampaign?.bidStrategy !== BID_STRATEGIES.TARGET_CPA;

export const hasSelectedCampaignAndBidModificationsAllowed = props =>
    onlySelectedCampaignColumn(props) && isCpcBasedBidStrategy(props);

export {
    isSiteBlockingDisabled,
    isBidStrategyPerAffiliateEditable,
    isVideoAccountReport,
    isTooltipDisabled,
    isSiteStatusEditable,
};
