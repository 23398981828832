import React from 'react';
import moment from 'moment';
import { FormattedMessage, FormattedNumber, useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import { BalanceBase } from '../BalanceBase/BalanceBase';
import styles from './creditCardBalance.module.scss';

const dateFormat = 'MMMM D, YYYY';
const formattedDate = moment().add(1, 'months').startOf('month').format(dateFormat);
export const CreditCardBalance = ({ className, currency, billingCycle, spentSinceLastBillingCycle }) => {
    const { formatMessage } = useIntl();
    return (
        <BalanceBase
            className={className}
            fundsAmount={spentSinceLastBillingCycle}
            currency={currency}
            title={formatMessage({
                id: 'billingAndPayments.balancePanel.ccb.title',
                defaultMessage: 'Current Spent',
            })}
        >
            <div className={styles['first-line']}>
                {formatMessage(
                    {
                        id: 'billingAndPayments.balancePanel.ccb.bottom.title',
                        defaultMessage: 'Next Charge Date: {date}',
                    },
                    {
                        date: formattedDate,
                    }
                )}
            </div>
            <div className={styles['second-line']}>
                <FormattedMessage
                    id="billingAndPayments.balancePanel.ccb.bottom.text"
                    defaultMessage="Or everytime you spend {billingCycle}"
                    values={{
                        billingCycle: (
                            <FormattedNumber
                                value={billingCycle}
                                variant="currency"
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />
                        ),
                    }}
                />
            </div>
        </BalanceBase>
    );
};
