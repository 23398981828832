import { generateBulkDuplicateEditorPath } from 'modules/campaigns/services/utils';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { navigate } from '../../../../../actions';

const bulkDuplicateCreative =
    ({ history, accountId }) =>
    dispatch => {
        const bulkDuplicateCreativePath = generateBulkDuplicateEditorPath({
            accountId,
            dimension: CAMPAIGN_DIMENSION.SPONSORED,
        });

        dispatch(navigate(history, bulkDuplicateCreativePath));
    };

export default bulkDuplicateCreative;
