import React from 'react';
import { useNavigate } from 'hooks';
import { useSelectedAccount } from 'hooks';
import { BILLING_AND_PAYMENTS_ROUTE } from 'modules/billing-and-payments/config/routes/module';
import { OneStepDescription } from '../../OnboardingStepDescription/OneStepDescription';
import { STEP_IDS } from '../../consts';

const { ADD_PAYMENT_METHOD } = STEP_IDS;
const countriesWithoutCTA = new Set(['CN', 'KP', 'KR', 'TW', 'HK', 'NZ', 'RU', 'TR', 'TH']);

export const AddPaymentStep = ({ isCollapsed, stepCompleteData }) => {
    const [accountData] = useSelectedAccount();
    const { hasNonFrozenAccount } = stepCompleteData;
    const { country } = accountData;
    const isCTADisabled = countriesWithoutCTA.has(country);
    const status = hasNonFrozenAccount ? 'complete' : 'incomplete';
    const navigate = useNavigate();
    const navigateToBilling = () => navigate(BILLING_AND_PAYMENTS_ROUTE);
    return (
        <OneStepDescription
            titleId={ADD_PAYMENT_METHOD}
            descriptionId={`${ADD_PAYMENT_METHOD}.${status}`}
            learnMoreLinkId={ADD_PAYMENT_METHOD}
            motivationId={!hasNonFrozenAccount && ADD_PAYMENT_METHOD}
            isComplete={hasNonFrozenAccount}
            isCollapsed={isCollapsed}
            onClickCTA={navigateToBilling}
            delayMsgId="onboarding.assistant.CTA.delay"
            delayStateDurationMinutes={10}
            isCTADisabled={isCTADisabled}
        />
    );
};
