'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _FormField = require('./FormField');

var _FormField2 = _interopRequireDefault(_FormField);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _FormField2.default; /**
                                        * Created by oreuveni
                                        * Date: 17/10/2018
                                        * Time: 12:35
                                        */