import React from 'react';
import { ReadOnlyCardNumber } from './ReadOnlyCardNumber/ReadOnlyCardNumber';
import { ReadOnlyExpirationDate } from './ReadOnlyExpirationDate/ReadOnlyExpirationDate';
import styles from './readOnlyCreditCardInfo.module.scss';

export const ReadOnlyCreditCardInfo = () => {
    return (
        <div className={styles['container']}>
            <ReadOnlyCardNumber />
            <ReadOnlyExpirationDate />
        </div>
    );
};
