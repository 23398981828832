import React from 'react';
import { DRAWER_MODE, SideDrawer } from '../../../../../components';
import { TopicArticlesDrawerContent } from './TopicArticlesDrawerContent';

export const TopicArticlesDrawer = ({ isOpen, onClose, topicName, totalArticles, children }) => (
    <SideDrawer openDrawer={isOpen} hasCloseButton={true} onClose={onClose} drawerMode={DRAWER_MODE.SMALL}>
        <TopicArticlesDrawerContent topicName={topicName} totalArticles={totalArticles}>
            {children}
        </TopicArticlesDrawerContent>
    </SideDrawer>
);
