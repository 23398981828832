import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction, noop } from 'lodash';
import PropTypes from 'prop-types';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { navigate } from 'actions';
import AudiencesReportsPage from 'modules/audiences/pages/AudiencesReportsPage';
import { DRAWER_MODE } from '../../components';
import { CustomizedSwitchWrapper } from '../../components/CustomizedSwitchWrapper/CustomizedSwitchWrapper';
import { FORM_MODES } from '../../config/formModes';
import { LayerRoute } from '../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ModuleRoute } from '../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { removeIndication } from '../taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from '../taboola-common-frontend-modules/account-configurations';
import {
    AUDIENCES_ROUTE,
    COMBINED_AUDIENCE_CREATOR_ROUTE_PATH,
    COMBINED_AUDIENCE_EDITOR_ROUTE_PATH,
    CUSTOM_CONTEXTUAL_CREATOR_ROUTE_PATH,
    CUSTOM_CONTEXTUAL_EDITOR_ROUTE_PATH,
    FIRST_PARTY_CREATOR_ROUTE_PATH,
    FIRST_PARTY_EDITOR_ROUTE_PATH,
    MAIL_DOMAIN_AUDIENCE_EDITOR_ROUTE_PATH,
    MAIL_DOMAIN_AUDIENCES_CREATOR_ROUTE_PATH,
    PIXEL_BASED_CREATOR_ROUTE_PATH,
    PIXEL_BASED_EDITOR_ROUTE_PATH,
    PREDICTIVE_CREATOR_ROUTE_PATH,
    PREDICTIVE_EDITOR_ROUTE_PATH,
    SEARCH_KEYWORDS_AUDIENCE_EDITOR_ROUTE_PATH,
    SEARCH_KEYWORDS_AUDIENCES_CREATOR_ROUTE_PATH,
} from './config';
import {
    AudiencesCreatorSelectionPage,
    CustomContextualEditorPage,
    CustomContextualTargetingCreatorPage,
    FirstPartyAudiencesCreatorPage,
    FirstPartyAudiencesEditorPage,
    MailDomainAudiencesCreatorPage,
    MailDomainsAudiencesEditorPage,
    PixelBasedAudiencesCreatorPage,
    PixelBasedAudiencesEditorPage,
    PredictiveAudiencesCreatorPage,
    PredictiveAudiencesEditorPage,
    SearchKeywordsAudiencesCreatorPage,
    SearchKeywordsAudiencesEditorPage,
} from './pages';
import { CombinedAudiencesAudiencesCreatorPage } from './pages/CombinedAudiencesCreatorPage';
import { CombinedAudiencesEditorPage } from './pages/CombinedAudiencesEditorPage';
import { audienceCreatorSelectionPath } from './utils/generateCreatorPath';

const path = AUDIENCES_ROUTE;

const Audiences = ({ history }) => {
    const dispatch = useDispatch();

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history]
    );

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    return (
        <CustomizedSwitchWrapper>
            <LayerRoute
                strict
                exact
                path={CUSTOM_CONTEXTUAL_CREATOR_ROUTE_PATH}
                render={props => <CustomContextualTargetingCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={PIXEL_BASED_CREATOR_ROUTE_PATH}
                render={props => <PixelBasedAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={FIRST_PARTY_CREATOR_ROUTE_PATH}
                render={props => <FirstPartyAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={PREDICTIVE_CREATOR_ROUTE_PATH}
                render={props => <PredictiveAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={COMBINED_AUDIENCE_CREATOR_ROUTE_PATH}
                render={props => <CombinedAudiencesAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={SEARCH_KEYWORDS_AUDIENCES_CREATOR_ROUTE_PATH}
                render={props => <SearchKeywordsAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={MAIL_DOMAIN_AUDIENCES_CREATOR_ROUTE_PATH}
                render={props => <MailDomainAudiencesCreatorPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={audienceCreatorSelectionPath}
                render={props => <AudiencesCreatorSelectionPage {...props} onCancel={handleNavigation} />}
            />
            {isMultiLayerDrawerEnabled && (
                <>
                    <LayerRoute
                        exact
                        path={PIXEL_BASED_EDITOR_ROUTE_PATH}
                        render={props => <PixelBasedAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={FIRST_PARTY_EDITOR_ROUTE_PATH}
                        render={props => <FirstPartyAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={PREDICTIVE_EDITOR_ROUTE_PATH}
                        render={props => <PredictiveAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={CUSTOM_CONTEXTUAL_EDITOR_ROUTE_PATH}
                        render={props => (
                            <CustomContextualEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                setMediumDrawer={noop}
                                resetDrawerSize={noop}
                            />
                        )}
                        drawerMode={DRAWER_MODE.MEDIUM}
                    />
                    <LayerRoute
                        exact
                        path={SEARCH_KEYWORDS_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => <SearchKeywordsAudiencesEditorPage mode={FORM_MODES.EDIT} {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={MAIL_DOMAIN_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <MailDomainsAudiencesEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                setMediumDrawer={noop}
                                resetDrawerSize={noop}
                            />
                        )}
                        drawerMode={DRAWER_MODE.MEDIUM}
                    />
                    <LayerRoute
                        exact
                        path={COMBINED_AUDIENCE_EDITOR_ROUTE_PATH}
                        render={props => (
                            <CombinedAudiencesEditorPage
                                mode={FORM_MODES.EDIT}
                                {...props}
                                setMediumDrawer={noop}
                                resetDrawerSize={noop}
                            />
                        )}
                        drawerMode={DRAWER_MODE.MEDIUM}
                    />
                </>
            )}
            <ModuleRoute path={path} render={() => <AudiencesReportsPage />} />
        </CustomizedSwitchWrapper>
    );
};

Audiences.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

export { Audiences };
export default Audiences;
