import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const AdSchedulerTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="creative.editor.edit.ad.schedule.help"
                defaultMessage="Ad schedules are useful if you want to efficiently execute creative swaps - precisely-timed replacement of one creative for another within the same campaign."
            />
        </TooltipSection>
    </>
);
