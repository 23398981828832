import { useQuery } from 'react-query';
import { useSelectedAccount } from '../../../hooks';
import { useUnipFunnelApi } from '../../../services/api/unipFunnelAPI';
import { nameToLabelById } from '../../campaigns/services/utils/dropdownUtils';

const emptyArr = [];
export const useFunnelState = funnelId => {
    const { getFunnel } = useUnipFunnelApi();

    const [{ accountName, id: selectedAccountId }] = useSelectedAccount();

    const {
        data: rules = emptyArr,
        isLoading,
        isError,
    } = useQuery(['getFunnel', selectedAccountId, funnelId], () => getFunnel(accountName, funnelId), {
        enabled: !!accountName,
        select: data => data?.rules.map(nameToLabelById),
        refetchOnWindowFocus: false,
    });

    return { rules, isLoading, isError };
};
