import React, { Fragment } from 'react';
import { ExternalLink } from 'components';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const CreativeDescriptionTooltip = () => (
    <Fragment>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.description.help.intro"
                defaultMessage="The description offers advertisers storytelling opportunities to highlight details about their product or service."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.description.help.detail"
                defaultMessage="Must-see details about your ad should be written in the title."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.description.help.optimization"
                defaultMessage="Based on the specific environment and publishers, Taboola will auto-optimize between your ad with and without description to show the best ad for achieving strong performance."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.content.description.help.length"
                defaultMessage="The visible length of the description depends on the placement it appears on. If shortened, the description will end with ellipses. Maximum length of the text is 250 characters."
            />
        </TooltipSection>
        <FormattedMessage
            id="creative.creator.content.description.help.linkIntro"
            defaultMessage="See our {link}."
            values={{
                link: (
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/360055676554">
                        <FormattedMessage
                            id="creative.creator.content.description.help.link"
                            defaultMessage="best practices"
                        />
                    </ExternalLink>
                ),
            }}
        />
    </Fragment>
);

export default CreativeDescriptionTooltip;
