import { identity, pick, isEmpty, mapValues } from 'lodash';
import qs from 'qs';

export const stringifyQueryParams = (params, options = {}) => {
    const resolvedParams = mapValues(params, value => (value instanceof Array ? JSON.stringify(value) : value));

    return qs.stringify(resolvedParams, { addQueryPrefix: true, ...options });
};
export const getLocationSearch = () => window?.location?.search || '';

export const getQueryParamFromLocation = (param, deserializer) =>
    getQueryParam(getLocationSearch(), param, deserializer);

export const getQueryParam = (searchString, param, deserializer = identity) => {
    const params = qs.parse(searchString, { ignoreQueryPrefix: true });
    const value = params[param];

    return deserializer(value);
};

export const setQueryParam = (searchString, param, value, serializer = identity) => {
    const params = qs.parse(searchString, { ignoreQueryPrefix: true });
    params[param] = serializer(value);

    return stringifyQueryParams(params);
};

export const pickQueryParams = paramNameList => {
    const parsedParams = qs.parse(getLocationSearch(), { ignoreQueryPrefix: true });
    const pickedParams = pick(parsedParams, paramNameList);

    return stringifyQueryParams(pickedParams, { addQueryPrefix: !isEmpty(pickedParams) });
};

export const parseQueryParams = searchString => {
    return qs.parse(searchString, { ignoreQueryPrefix: true });
};

const getSortOrder = item => item?.order ?? Number.MAX_VALUE;
export const mergeQueryParams = (searchString, queryParamsObj, orderMap) => {
    const params = {
        ...qs.parse(searchString, { ignoreQueryPrefix: true }),
        ...queryParamsObj,
    };
    const sort = orderMap ? (a, b) => getSortOrder(orderMap[a]) - getSortOrder(orderMap[b]) : -1;

    return stringifyQueryParams(params, { sort });
};

export const mergeQueryParamsWithLocation = ({ pathname, search }, queryParamsObj) =>
    pathname + mergeQueryParams(search, queryParamsObj);
