import React from 'react';
import { Button } from 'tuui';
import { ExternalLink } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ReactComponent as GoogleChromeIcon } from './chrome-logo.svg';
import styles from './chromeExtensionButton.module.scss';

const pixelHelperURL =
    'https://chrome.google.com/webstore/detail/taboola-pixel-helper/aefiepimkogajhddmhcekceihikjcabd';

export const ChromeExtensionButton = () => (
    <ExternalLink className={styles['chrome-extension']} href={pixelHelperURL}>
        <Button variant="ghost">
            <GoogleChromeIcon />
            <FormattedMessage id="tracking.setup.chrome.extension.button" defaultMessage="Chrome Extension" />
        </Button>
    </ExternalLink>
);
