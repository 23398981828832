import React, { Component } from 'react';
import { isEqual, isNull, omit } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { PAGINATION_TYPE, TaboolaDataGrid } from 'taboola-ultimate-ui';
import { actionsColumnType, columnsTypesMap, defaultColumnDefinition } from '../../config';
import withFilterIcon from '../CellRenderers/withFilterIcon';
import CustomHeader from './CustomHeader';
import CustomPaginationBar from './CustomPaginationBar';

export const BLACK_LIST = [
    'dataField',
    'valueChangeHandler',
    'csvValueGetter',
    'csv',
    'isDynamicField',
    'referenceColId',
    'permissions',
    'accountConfigurations',
    'csvValueGetterParams',
    'csvHeaderValueGetter',
    'visibilityCondition',
    'id',
    'disabled',
];

// unit: ms
export const FLASH_DELAY = 50;
export const FADE_DELAY = 9950;
const FLASH_DURATION = 2000;

class ReportDataGrid extends Component {
    gridApi = null;

    handleCellValueChange = e => {
        const { onCellValueChange, context } = this.props;
        const { colDef: sanitizedColDef, data: rowData, newValue, oldValue, rowIndex, api, node } = e;
        const colDef = this.props.columnDefinition.find(col => col.colId === sanitizedColDef.colId);
        const { valueChangeHandler, field, dataField } = colDef;

        if (this.isValueChanged(newValue, oldValue) && this.isChangeValid(e)) {
            api && api.flashCells({ rowNodes: [node], flashDelay: FLASH_DELAY, fadeDelay: FADE_DELAY });
            onCellValueChange &&
                onCellValueChange({
                    valueChangeHandler,
                    field: dataField || field,
                    rowData,
                    newValue,
                    oldValue,
                    rowIndex,
                    context,
                });
        }
    };

    sanitizeColDefs = colDefs => colDefs.map(colDef => omit(colDef, BLACK_LIST));

    sanitizeColDefsMemo = memoizeOne(this.sanitizeColDefs);

    sanitizeFooterColDefsMemo = memoizeOne(this.sanitizeColDefs);

    isValueChanged = (newValue, oldValue) => !isEqual(newValue, oldValue);

    isChangeValid = ({ api, column, node, newValue, data }) => {
        const cellRenderers = api.getCellRendererInstances({ columns: [column.colId], rowNodes: [node] });

        if (!cellRenderers || !cellRenderers.length) {
            return true;
        }

        const [{ componentInstance: cellRenderer }] = cellRenderers;
        const validate =
            cellRenderer &&
            cellRenderer.childRef &&
            cellRenderer.childRef.current &&
            cellRenderer.childRef.current.validate;
        if (!validate) {
            return true;
        }
        return validate({ value: newValue, data });
    };

    normalizeWithFilterIcon = memoizeOne(columnDefs =>
        columnDefs.map(({ cellRendererFramework, ...rest }) => ({
            ...rest,
            cellRendererFramework:
                cellRendererFramework !== null ? withFilterIcon(cellRendererFramework) : cellRendererFramework,
        }))
    );

    flashGridCurrentRows() {
        const { resetFlashReportGridRows, gridRowToFlash } = this.props;

        if (!this.gridApi || !gridRowToFlash) {
            return;
        }

        const rowNodeToFlash = this.gridApi.getRenderedNodes().find(({ data }) => gridRowToFlash === data.id);

        if (rowNodeToFlash) {
            resetFlashReportGridRows();
            this.gridApi.flashCells({
                rowNodes: [rowNodeToFlash],
                flashDelay: FLASH_DURATION,
                fadeDelay: FLASH_DURATION,
            });
        }
    }

    onGridReady = params => {
        this.props.onGridReady?.(params);
        this.gridApi = params.api;
    };

    componentDidUpdate() {
        this.flashGridCurrentRows();
    }

    render() {
        const {
            reportId,
            columnDefinition,
            footerColumnDefs,
            footerReportData,
            isEmptyFooter,
            searchTerm,
            reportData,
            autoWidthColumns,
            ...rest
        } = this.props;

        return (
            <TaboolaDataGrid
                {...rest}
                id={reportId}
                columnDefs={this.sanitizeColDefsMemo(columnDefinition)}
                footerColumnDefs={this.sanitizeFooterColDefsMemo(footerColumnDefs)}
                defaultColDef={defaultColumnDefinition}
                columnTypes={columnsTypesMap}
                reportData={reportData}
                onCellValueChanged={this.handleCellValueChange}
                footerData={footerReportData}
                quickFilterText={searchTerm}
                isEmptyFooter={isEmptyFooter}
                withFooter={!searchTerm && reportData?.length !== 0 && (!isNull(footerReportData) || isEmptyFooter)}
                stickyHeader
                stickyFooter
                onGridReady={this.onGridReady}
                paginationType={PAGINATION_TYPE.SERVER}
                rowSelection="multiple"
                suppressRowClickSelection
                customHeaderComponent={CustomHeader}
                normalizeFooterColDefs={this.normalizeWithFilterIcon}
                PaginationBar={CustomPaginationBar}
                autoWidthColumns={autoWidthColumns}
            />
        );
    }
}

ReportDataGrid.propTypes = {
    /** The report id for the data presented in the grid */
    reportId: PropTypes.string.isRequired,
    /** The column definition for the grid's cells */
    columnDefinition: PropTypes.array.isRequired,
    /** The data presented in the grid */
    reportData: PropTypes.array,
    /** The data presented in the grid footer */
    footerReportData: PropTypes.array,
    /** The column definition for the grid's footer cells */
    footerColumnDefs: PropTypes.array,
    /** Should render an empty footer grid to allow horizontal scrolling */
    isEmptyFooter: PropTypes.bool,
    /** Cell inline edit event handler */
    onCellValueChange: PropTypes.func,
    /** Text to search in the grid */
    searchTerm: PropTypes.string,
    /** Empty/Error state overlay props */
    emptyStateData: PropTypes.shape({ header: PropTypes.node, content: PropTypes.node, className: PropTypes.string }),
    /** context to be held by the grid */
    context: PropTypes.object,
    /** Column name masks which should be auto sized disregards of passed column width */
    autoWidthColumns: PropTypes.array,
};

ReportDataGrid.defaultProps = {
    footerColumnDefs: [],
    isEmptyFooter: false,
    autoWidthColumns: [actionsColumnType],
};

export default ReportDataGrid;
