import React from 'react';
import { RemoveCircleIcon } from 'tuui';
import { DismissIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import '../../notificationCard.module.scss';
import styles from './notificationCardStateSection.module.scss';

export const NotificationCardStateSection = ({ isNew, isInactive, dismissNotificationCallback }) => (
    <div className={styles['notification-state-section']}>
        <div className={styles['dismiss-button-container']}>
            <DismissIcon className={styles['dismiss-button-icon']} onClick={dismissNotificationCallback} />
        </div>
        {isNew && !isInactive && (
            <div className={styles['status-indicator-container']}>
                <div className={styles['status-indicator']} />
            </div>
        )}
        {isInactive && (
            <div className={styles['expired-indicator']}>
                <RemoveCircleIcon className={styles['icon']} />
                <FormattedMessage id="app.notificationsCenter.notificationCard.expired" defaultMessage="Expired" />
            </div>
        )}
    </div>
);
