import { FormattedMessage } from '../../../i18n';
import styles from './CustomerSupportPrompt.module.scss';

export const CustomerSupportPrompt = () => (
    <div className={styles['wrapper']}>
        <FormattedMessage id="support.prompt.heading" />
        <div className={styles['message']}>
            <FormattedMessage id="support.prompt.message" />
        </div>
    </div>
);
