export const FREQUENCY_CONDITIONS = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
};

export const frequencyOptions = [
    { value: FREQUENCY_CONDITIONS.DAILY, label: 'Daily' },
    { value: FREQUENCY_CONDITIONS.WEEKLY, label: 'Weekly' },
    { value: FREQUENCY_CONDITIONS.MONTHLY, label: 'Monthly' },
];

export const WEEKLY_CONDITIONS = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
};

export const frequencyWeeklyOptions = [
    { value: WEEKLY_CONDITIONS.MONDAY, label: 'Monday' },
    { value: WEEKLY_CONDITIONS.TUESDAY, label: 'Tuesday' },
    { value: WEEKLY_CONDITIONS.WEDNESDAY, label: 'Wednesday' },
    { value: WEEKLY_CONDITIONS.THURSDAY, label: 'Thursday' },
    { value: WEEKLY_CONDITIONS.FRIDAY, label: 'Friday' },
    { value: WEEKLY_CONDITIONS.SATURDAY, label: 'Saturday' },
    { value: WEEKLY_CONDITIONS.SUNDAY, label: 'Sunday' },
];

export const MONTHLY_CONDITIONS = {
    FIRST_DAY_OF_MONTH: 'FIRST_DAY_OF_MONTH',
    LAST_DAY_OF_MONTH: 'LAST_DAY_OF_MONTH',
};

export const frequencyMonthlyOptions = [
    { value: MONTHLY_CONDITIONS.FIRST_DAY_OF_MONTH, label: 'First Day of the Month' },
    { value: MONTHLY_CONDITIONS.LAST_DAY_OF_MONTH, label: 'Last Day of the Month' },
];
