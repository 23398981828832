import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TrackingSetupButton } from '../../TrackingButton/TrackingSetupButton';
import styles from '../trackingEmptyState.module.scss';

export const PixelEmptyStateMessages = () => {
    return (
        <>
            <div className={styles['header']}>
                <FormattedMessage id="tracking.conversions.report.not.installed.title" defaultMessage="Taboola Pixel" />
            </div>
            <div className={styles['content']}>
                <FormattedMessage
                    id="tracking.conversions.report.not.installed.content"
                    defaultMessage="Install the Taboola pixel and measure the impact of your Campaigns"
                />
            </div>
            <TrackingSetupButton />
        </>
    );
};
