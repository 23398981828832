import { useState, useCallback, useMemo } from 'react';
import { CONDITION_DIMENSIONS } from 'modules/scheduled-reports/components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useScheduledReportsApi } from 'services/api/scheduledReportApi';

export const useScheduledReportsList = ({ scheduledReportIds }) => {
    const { getScheduledReportsList } = useScheduledReportsApi();
    const [scheduledReports, setScheduledReports] = useState([]);
    const { accountName } = useAccount();

    const options = useMemo(
        () => scheduledReports.filter(item => !scheduledReportIds?.includes(item?.id)),
        [scheduledReports, scheduledReportIds]
    );
    const selectedReports = useMemo(
        () => scheduledReports.filter(item => scheduledReportIds?.includes(item?.id)),
        [scheduledReports, scheduledReportIds]
    );

    const loadScheduledReportsList = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getScheduledReportsList(
                accountName,
                [CONDITION_DIMENSIONS.CUSTOM_RULES_BY_DAY],
                page,
                pageSize,
                searchText
            );
            return [results, total, results?.length];
        },
        [getScheduledReportsList, accountName]
    );

    return {
        options,
        scheduledReports,
        selectedReports,
        loadScheduledReportsList,
        setScheduledReports,
    };
};
