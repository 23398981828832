import { useState, useMemo } from 'react';
import { useSelectedAccount } from 'hooks';
import { aiGenerationApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { NUM_DESCRIPTION_SUGGESTIONS, NUM_TITLE_SUGGESTIONS } from '../../../constants/aiVariationsConstants';
import { useAiVariationsContext } from '../AiVariationsContextProvider';

export const useAiTextSuggestions = ({ campaignId }) => {
    const [{ accountId }] = useSelectedAccount();
    const [titleStatus, setTitleStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [descriptionStatus, setDescriptionStatus] = useState(COMPONENT_STATUS.INITIAL);
    const {
        productDescription,
        targetAudience,
        uniqueSellingProposition,
        language,
        landingPageData,
        aiTitleSuggestions,
        setAiTitleSuggestions,
        aiDescriptionSuggestions,
        setAiDescriptionSuggestions,
        numOfAiTitleSuggestions,
        setNumOfAiTitleSuggestions,
        numOfAiDescriptionSuggestions,
        setNumOfAiDescriptionSuggestions,
    } = useAiVariationsContext();

    const suggestedTitles = useMemo(
        () => aiTitleSuggestions.slice(0, numOfAiTitleSuggestions),
        [aiTitleSuggestions, numOfAiTitleSuggestions]
    );
    const suggestedDescriptions = useMemo(
        () => aiDescriptionSuggestions.slice(0, numOfAiDescriptionSuggestions),
        [aiDescriptionSuggestions, numOfAiDescriptionSuggestions]
    );

    const fetchNewSuggestedTextBatch = async () => {
        try {
            const results = await aiGenerationApi.getAIGeneratedTitles({
                accountId,
                campaignId,
                productDescription,
                targetAudience,
                uniqueSellingProposition,
                language: language?.value,
                ...landingPageData,
            });

            setNewSuggestedTextBatch(results);

            return { results };
        } catch (error) {
            return { error };
        }
    };

    const setNewSuggestedTextBatch = results => {
        setAiTitleSuggestions(results['titles']);
        setAiDescriptionSuggestions(results['descriptions']);

        setNumOfAiTitleSuggestions(NUM_TITLE_SUGGESTIONS);
        setNumOfAiDescriptionSuggestions(NUM_DESCRIPTION_SUGGESTIONS);
    };

    const fetchMoreSuggestedText = async () => {
        try {
            const results = await aiGenerationApi.getAIGeneratedTitles({
                accountId,
                campaignId,
                productDescription,
                targetAudience,
                uniqueSellingProposition,
                language: language?.value,
                ...landingPageData,
            });

            setAiTitleSuggestions([...aiTitleSuggestions, ...results['titles']]);
            setAiDescriptionSuggestions([...aiDescriptionSuggestions, ...results['descriptions']]);

            return {};
        } catch (error) {
            return { error };
        }
    };

    const loadMoreTitles = async () => {
        if (aiTitleSuggestions.length <= numOfAiTitleSuggestions) {
            setTitleStatus(COMPONENT_STATUS.LOADING);
            const { error } = await fetchMoreSuggestedText();

            if (error) {
                setTitleStatus(COMPONENT_STATUS.ERROR);
                return;
            }

            setTitleStatus(COMPONENT_STATUS.ACTIVE);
        }

        setNumOfAiTitleSuggestions(prev => prev + NUM_TITLE_SUGGESTIONS);
    };

    const loadMoreDescriptions = async () => {
        if (aiDescriptionSuggestions.length <= numOfAiDescriptionSuggestions) {
            setDescriptionStatus(COMPONENT_STATUS.LOADING);
            const { error } = await fetchMoreSuggestedText();
            setDescriptionStatus(COMPONENT_STATUS.ACTIVE);

            if (error) {
                setDescriptionStatus(COMPONENT_STATUS.ERROR);
                return;
            }
        }

        setNumOfAiDescriptionSuggestions(numOfAiDescriptionSuggestions + NUM_DESCRIPTION_SUGGESTIONS);
    };

    return {
        fetchNewSuggestedTextBatch,
        setNewSuggestedTextBatch,
        suggestedTitles,
        suggestedDescriptions,
        loadMoreTitles,
        loadMoreDescriptions,
        titleStatus,
        descriptionStatus,
    };
};
