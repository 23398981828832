import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const RssBulkDisabledTooltip = () => (
    <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow>
        <FormattedMessage
            id="rss.bulkAction.checkbox.tooltip"
            defaultMessage="Bulk actions are not supported for RSS ads yet"
        />
    </Tooltip>
);

export default RssBulkDisabledTooltip;
