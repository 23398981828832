import React from 'react';
import { Link } from 'react-router-dom';
import { AddIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import { getPaymentMethodDefaultAddMessage } from '../../config/PaymentTypeMetadata';
import { generatePaymentMethodFormPath } from '../../config/routes/generatePaymentMethodFormPath';
import paymentMethodStyles from './addPaymentMethod.module.scss';

export const AddPaymentMethod = ({ paymentMethodType }) => {
    const defaultMessage = getPaymentMethodDefaultAddMessage(paymentMethodType);
    const createPath = generatePaymentMethodFormPath({ mode: FORM_MODES.CREATE, paymentMethodType });

    return (
        <Link to={createPath} className={paymentMethodStyles['payment-method-container']}>
            <AddIcon className={paymentMethodStyles['icon']} />
            <div className={paymentMethodStyles['add-payment-text']}>
                <FormattedMessage
                    id={`billingAndPayments.addPaymentMethod.${paymentMethodType}`}
                    defaultMessage={defaultMessage}
                />
            </div>
        </Link>
    );
};
