import React from 'react';
//  eslint-disable-next-line no-restricted-syntax
import { FormattedNumber as ReactIntlFormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';

const FormattedNumber = props => {
    const { value, forceSign, variant } = props;

    const formattedNumber = <ReactIntlFormattedNumber {...props} style={variant} />;
    const formattedNumberWithPlusSign = (
        <div>
            <span>+</span>
            {formattedNumber}
        </div>
    );

    return value > 0 && forceSign ? formattedNumberWithPlusSign : formattedNumber;
};

FormattedNumber.propTypes = {
    ...ReactIntlFormattedNumber.propTypes,
    forceSign: PropTypes.bool,
    variant: PropTypes.oneOf(['decimal', 'percent', 'currency', 'unit']),
};

FormattedNumber.defaultProps = {
    forceSign: false,
};

export default FormattedNumber;
