import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { difference, identity, keyBy, map, size } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import uuid from 'uuid/v1';
import { CollapsibleList, TextCheckbox, useDebouncedValue, useElementSize } from 'taboola-ultimate-ui';
import commonStyles from 'modules/campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import { useSendSegmentSearchEvent } from 'modules/campaigns/modules/common-campaign-form/utils/useSendSegmentSearchEvent';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SegmentsDropdownWithBundling } from '../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiencesWithBundling/SegmentsDropdownWithBundling/SegmentsDropdownWithBundling';
import { TargetingTypeBareDropdown } from '../../../campaigns/modules/common-campaign-form/components/TargetingTypeDropdown';
import {
    LoadingMode,
    MAX_SEGMENTS_TO_SELECT,
    useBundleTreeState,
} from '../../../campaigns/modules/common-campaign-form/components/Tree';
import { useBundleData } from '../../../campaigns/modules/common-campaign-form/components/Tree/hooks/useBundleData';
import { TargetingTypeDropdownOption } from '../CombinedAudiencesTargetingTypeDropdown/CombinedAudiencesTargetingTypeDropdown';
import { CombinedAudiencesProvider } from './CombinedAudiencesContext';
import { CombinedSegmentListItemContentWithBundling } from './CombinedSegmentListItemContentWithBundling';
import { CombinedSegmentsTreeWithBundling } from './CombinedSegmentsTreeWithBundling';
import { TARGETING_OPTIONS, TARGETING_TYPES } from './CombinedTargetingTypes';
import { DeleteCombinedAudience } from './DeleteCombinedAudience';
import { useCombinedSegmentsWithBundlingApi } from './hooks/useCombinedSegmentsWithBundlingApi';
import { useSelectedCombinedSegments } from './hooks/useSelectedCombinedSegments';
import styles from './combinedAudiences.module.scss';

const nodeTransformer = ({ provider, size, articles, description, allowedCountries }) => ({
    provider,
    size,
    articles,
    description,
    allowedCountries,
});
const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const CombinedAudiencesTargetingList = ({ selectedAccountId }) => {
    const { value: audienceSetup, onChange: setAudienceSetup } = useFormFieldValue({ field: 'audienceSetup' });
    const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

    const narrowSegment = useCallback(() => {
        setAudienceSetup(prev => [
            ...prev,
            {
                relation: TARGETING_TYPES.OR,
                audiences: [],
                id: uuid(),
            },
        ]);
    }, [setAudienceSetup]);

    return (
        <div className={styles['wrapper']}>
            <TextCheckbox
                labelClassName={styles['checkbox']}
                title={
                    <FormattedMessage
                        id="campaign.editor.marketplace.audiences.advanced.search"
                        defaultMessage="Advanced Search"
                    />
                }
                selectedValues={[true]}
                value={isAdvancedSearch}
                onSelectCheckbox={() => setIsAdvancedSearch(true)}
                onUnselectCheckbox={() => setIsAdvancedSearch(false)}
                independent
                helpText={
                    <FormattedMessage
                        id="advanced.search.tooltip"
                        defaultMessage="Advanced search will return specific audience segments as well as audience bundles."
                    />
                }
            />
            <FormNamespaceProvider field="audienceSetup">
                {map(audienceSetup, (audience, index) => (
                    <FormNamespaceProvider field={index} itemKey={audience.id} key={audience.id}>
                        {!!index && index < audienceSetup.length && (
                            <DeleteCombinedAudience audience={audience} setAudienceSetup={setAudienceSetup} />
                        )}
                        <CombinedAudiencesTargeting
                            selectedAccountId={selectedAccountId}
                            isAdvancedSearch={isAdvancedSearch}
                        />
                    </FormNamespaceProvider>
                ))}
            </FormNamespaceProvider>
            {!!size(audienceSetup?.[0].audiences) && (
                <div className={styles['narrow-button']}>
                    <Button className={styles['button']} variant={Button.variant.outline} onClick={narrowSegment}>
                        <FormattedMessage
                            id="audience.creator.combined.audiences.targeting.button"
                            defaultMessage="Narrow Your Segment (And)"
                        />
                    </Button>
                </div>
            )}
        </div>
    );
};

const CombinedAudiencesTargeting = ({ selectedAccountId, isAdvancedSearch }) => {
    const [searchText, setSearchText] = useState('');
    const { unbundledNodes, unbundleNode, getIsNodeBundled } = useBundleData();
    const search = useDebouncedValue(searchText.length > 2 ? searchText : '');

    const { value: targetingType, onChange: setTargetingType } = useFormFieldValue({ field: 'relation' });

    const { segments, fullSegments, ...treeStateParams } = useCombinedSegmentsWithBundlingApi({
        search,
        unbundledNodes,
        selectedAccountId,
        isAdvancedSearch,
    });

    const { value: audiences, onChange: onChangeAudiences } = useFormFieldValue({
        field: 'audiences',
    });

    const onRemoveAudience = useCallback(
        selectedLeaves => {
            const treeSelectedLeafIds = selectedLeaves.map(item => item.id);

            const treeSelectedLeafIdsMap = keyBy(treeSelectedLeafIds, identity);
            const removedAudeincesIds = difference(audiences, treeSelectedLeafIds);

            if (removedAudeincesIds.length) {
                onChangeAudiences(prev => prev.filter(id => treeSelectedLeafIdsMap[id]));
            }
        },
        [audiences, onChangeAudiences]
    );

    const {
        selectedNodes,
        handleRemoveItem,
        handleReload,
        setSelectedNodesByIds,
        handleSelectNode,
        handleSelectAll,
        handleRemoveAllItems,
        expandAll,
        setIsReady,
        ...treeProps
    } = useBundleTreeState({
        search,
        nodes: segments,
        fullNodes: fullSegments,
        nodeTransformer,
        onRemoveAudience,
        ...treeStateParams,
    });

    const { nodes, removeNodes, indicationData, scrollRef } = useSelectedCombinedSegments({
        targetingType,
        selectedNodes,
        setSelectedNodesByIds,
        setIsReady,
        handleRemoveAllItems,
        isReady: treeProps.isReady,
        setSelectedNodes: treeProps.setSelectedNodes,
    });
    // flow of initial load, pagination, search and providers change
    useLayoutEffect(() => {
        handleReload();
    }, [handleReload]);

    useSendSegmentSearchEvent({
        component: 'Combined Audiences Segments Search',
        searchText: search,
        resultsCount: segments.length,
        isLoading: treeProps.loadingMode !== LoadingMode.NONE,
    });

    const handleSelectNodeWrapper = useCallback(
        (path, node, checked) => {
            const { label, taxonomy } = node;
            const component = search ? 'Combined Search' : 'Combined Browse';

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component,
                value: label,
                columnName: taxonomy,
            });

            handleSelectNode(node, checked);
        },
        [handleSelectNode, search]
    );

    const handleSelectAllWrapper = useCallback(
        check => {
            handleSelectAll(check, MAX_SEGMENTS_TO_SELECT);
        },
        [handleSelectAll]
    );

    const { width, ref } = useElementSize();
    const menuStyles = useMemo(
        () => ({
            width,
            right: styles.segmentDropdownWithBundlingWidth,
        }),
        [width]
    );

    return (
        <div className={styles['container']}>
            <div className={styles['dropdown-container']} ref={ref}>
                <CombinedAudiencesProvider
                    limit={MAX_SEGMENTS_TO_SELECT}
                    handleSelectNode={handleSelectNodeWrapper}
                    handleSelectAll={handleSelectAllWrapper}
                    getIsNodeBundled={getIsNodeBundled}
                    unbundleNode={unbundleNode}
                    {...treeProps}
                >
                    <TargetingTypeBareDropdown
                        onChange={({ value }) => setTargetingType(value)}
                        value={targetingType}
                        options={TARGETING_OPTIONS}
                        optionItemRenderer={TargetingTypeDropdownOption}
                    />
                    <div className={styles['delimiter']} />
                    <SegmentsDropdownWithBundling
                        search={searchText}
                        onSearchChange={setSearchText}
                        menuStyles={menuStyles}
                        menuListRenderer={CombinedSegmentsTreeWithBundling}
                    />
                    <div />
                </CombinedAudiencesProvider>
            </div>
            <CollapsibleListWithIndication
                id="campaignTargeting.marketplaceAudienceTargeting.include"
                items={nodes}
                ItemComponent={CombinedSegmentListItemContentWithBundling}
                metadata={{
                    targetingType,
                }}
                deleteItem={handleRemoveItem}
                showTotal={false}
                listHeaderTitle={`${size(nodes)} selected`}
                clearItems={removeNodes}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...indicationData}
                indicationContainerClass={commonStyles['warning-indication']}
                ref={scrollRef}
            />
        </div>
    );
};
CombinedAudiencesTargeting.propTypes = {
    selectedAccountId: PropTypes.string,
};

export { CombinedAudiencesTargetingList as CombinedAudiences };
