import React, { useMemo } from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import CodeSnippet from 'modules/audiences/components/CodeSnippet/CodeSnippet';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { buildCodeSnippetWithParams } from '../../config/eventCodeConfig';
import styles from './eventCodeSnippetField.module.scss';

const useEventCodeSnippet = () => {
    const { [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET]: codeSnippet } = useCommonConfig([
        COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET,
    ]);

    const { value: parameters = [] } = useFormFieldValue({
        field: 'dynamicParameters',
    });

    const { value: eventName } = useFormFieldValue({
        field: 'eventName',
    });

    const {
        formAccount: { id: accountId },
    } = useFormDataContext();

    return useMemo(
        () => buildCodeSnippetWithParams(codeSnippet, accountId, parameters, eventName),
        [codeSnippet, accountId, parameters, eventName]
    );
};

export const EventCodeSnippetField = () => {
    const codeSnippet = useEventCodeSnippet();
    return (
        <div className={styles['container']}>
            <FormField
                inputId="event-code-snippet"
                label={<FormattedMessage id="tracking.event.cone.snippet.label" defaultMessage="Code Snippet" />}
                description={
                    <FormattedMessage
                        id="tracking.event.code.snippet.description"
                        defaultMessage="Paste the code snippet below on your web page. To track inline actions, add the snippet to the relevant HTML element. To track page load, add the code snippet to the <head> HTML element."
                    />
                }
                subtext={
                    <FormattedMessage
                        id="tracking.event.code.snippet.subtext"
                        defaultMessage="It may take up to 20 minutes for your pixel status to change after installing it. You can always go to the Help Center to learn more about pixel management."
                    />
                }
            >
                <CodeSnippet codeSnippetText={codeSnippet} />
            </FormField>
            <Indication
                type="info"
                highlight="Test Event"
                message={
                    <FormattedMessage
                        id="tracking.conversion.form.event.code.test.event.notice"
                        defaultMessage="To ensure your Event has been added properly, use our Chrome Extension"
                    />
                }
                displayDismissComponent={false}
            />
        </div>
    );
};
