import { bulkEditDrawerUpdateCreativesSuccess } from 'modules/campaigns/actions/index';
import { updateCreativesBase } from 'modules/campaigns/flows';
import { setSelectedRows } from 'modules/campaigns/modules/campaigns-reports/actions';
import { isMediaLibraryItem } from '../../creative-editor/components/MediaTabs/services/utils';
import uploadCreativeRecommendedImage from '../../creative-editor/flows/uploadCreativeRecommendedImage';

const getThumbnailUrl = async (dispatch, accountId, initialCreativeChanges) => {
    const { thumbnailUrl, thumbnailRecommendedImage } = initialCreativeChanges;

    if (thumbnailRecommendedImage) {
        return isMediaLibraryItem(thumbnailRecommendedImage)
            ? thumbnailRecommendedImage
            : await dispatch(uploadCreativeRecommendedImage(accountId, thumbnailRecommendedImage));
    }

    return { thumbnailUrl };
};

export const updateThumbnailCreatives =
    ({ accountId, creativeIds, changes: initialChanges }) =>
    async dispatch => {
        const { thumbnailUrl, error: uploadError } = await getThumbnailUrl(dispatch, accountId, initialChanges);
        if (uploadError) {
            throw uploadError;
        }

        //remove thumbnailRecommendedImage and add new thumbnailUrl
        const { thumbnailRecommendedImage, ...creativeChanges } = initialChanges;
        const changes = { ...creativeChanges, thumbnailUrl };

        const { results } = await dispatch(updateCreativesBase(accountId, creativeIds, changes));
        if (results) {
            dispatch(bulkEditDrawerUpdateCreativesSuccess(results));
            dispatch(setSelectedRows(true, results));
            return true;
        }
    };
