import { AUDIENCE_TYPE, TTL_TYPE } from './audiencesConsts';

export const pixelBasedAudienceInitialValues = {
    type: AUDIENCE_TYPE.BASIC,
    conditions: [],
    ttlType: TTL_TYPE.SPECIFIC_TIMEFRAME,
    lookBackWindow: 30,
    excludeFromCampaigns: false,
};

export default pixelBasedAudienceInitialValues;
