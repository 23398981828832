import { bulkResubmitCreativeSuccess, bulkResubmitCreativeError, requestResubmitCreative } from '../actions';
import { resubmitCreativesBase } from './resubmitCreativesBase';

export const bulkResubmitCreatives =
    ({ creativeIds, accountId }) =>
    dispatch => {
        dispatch(
            resubmitCreativesBase({
                creativeIds,
                accountId,
                onInit: () => dispatch(requestResubmitCreative()),
                onError: () => dispatch(bulkResubmitCreativeError()),
                onSuccess: creatives => {
                    dispatch(bulkResubmitCreativeSuccess(creatives));
                },
            })
        );
    };
