'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CheckboxGroup = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Indications = require('../../Indications');

var _checkboxContext = require('../checkboxContext');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var CheckboxGroup = function CheckboxGroup(_ref) {
    var selectedValues = _ref.selectedValues,
        onSelectCheckbox = _ref.onSelectCheckbox,
        onUnselectCheckbox = _ref.onUnselectCheckbox,
        onIntermediateSelect = _ref.onIntermediateSelect,
        children = _ref.children,
        error = _ref.error,
        indicationType = _ref.indicationType,
        rest = _objectWithoutProperties(_ref, ['selectedValues', 'onSelectCheckbox', 'onUnselectCheckbox', 'onIntermediateSelect', 'children', 'error', 'indicationType']);

    return _react2.default.createElement(
        'div',
        Object.assign({ role: 'group' }, rest),
        _react2.default.createElement(
            _checkboxContext.CheckboxProvider,
            {
                selectedValues: selectedValues,
                onSelectCheckbox: onSelectCheckbox,
                onUnselectCheckbox: onUnselectCheckbox,
                onIntermediateSelect: onIntermediateSelect
            },
            children
        )
    );
};

exports.CheckboxGroup = CheckboxGroup;
CheckboxGroup.propTypes = Object.assign({}, _checkboxContext.CheckboxProvider.propTypes, {
    /** Error control */
    error: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES))
});

exports.default = CheckboxGroup;