import encodeurl from 'encodeurl';
import duplicateMotionCreative from 'modules/campaigns/modules/creative-editor/flows/duplicateMotionCreative';

export const duplicateMotionAdCreativeHandler =
    (accountId, data, dispatch) =>
    async ({ selectedAccount }) => {
        const savedCreative = { ...data, url: encodeurl(data.url) };
        const result = await dispatch(duplicateMotionCreative({ accountId, selectedAccount }, savedCreative));
        if (!result?.error) {
            return result;
        }
    };
