import { useMemo } from 'react';
import { FEATURE_FLAGS, useAccountConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';

export const useVcpmAccountConfig = () => {
    const { vcpmMinValue, vcpmMaxValue, vcpmMinWarn, vcpmMaxWarn, vcpmDataMinValue, vcpmDataMaxValue } =
        useAccountConfig([
            FEATURE_FLAGS.ACCOUNT_VCPM_MIN_VALUE,
            FEATURE_FLAGS.ACCOUNT_VCPM_MAX_VALUE,
            FEATURE_FLAGS.ACCOUNT_VCPM_MIN_WARN,
            FEATURE_FLAGS.ACCOUNT_VCPM_MAX_WARN,
            FEATURE_FLAGS.ACCOUNT_VCPM_DATA_MIN_VALUE,
            FEATURE_FLAGS.ACCOUNT_VCPM_DATA_MAX_VALUE,
        ]);
    return useMemo(
        () => ({
            vcpmMinValue,
            vcpmMaxValue,
            vcpmMinWarn,
            vcpmMaxWarn,
            vcpmDataMinValue,
            vcpmDataMaxValue,
        }),
        [vcpmMinValue, vcpmMaxValue, vcpmMinWarn, vcpmMaxWarn, vcpmDataMinValue, vcpmDataMaxValue]
    );
};
