import encodeurl from 'encodeurl';
import { cloneDeep, isNil } from 'lodash';
import validator from 'validator';

const buildOptionsObject = (data, options = {}) => {
    if (!data) {
        return options;
    }

    const optionValueObject = Object.keys(options).reduce((res, key) => {
        const optionValue = options[key];

        if (!data.hasOwnProperty(optionValue)) {
            return {
                ...res,
                [key]: optionValue,
            };
        }

        const dataValue = data[optionValue];
        if (!isNil(dataValue)) {
            return {
                ...res,
                [key]: String(dataValue),
            };
        }
        return res;
    }, {});
    return optionValueObject;
};

const validationFunctions = {
    ...validator,
    ...{
        isLength: (value, options, { data }) => validator.isLength(value, buildOptionsObject(data, options)),
        range: (value, options, { data }) => validator.isFloat(value, buildOptionsObject(data, options)),
        isIn: (value, { list: listFromOptions }, { list }) => validator.isIn(value, listFromOptions || list),
        notIn: (value, { list: listFromOptions }, { list }) => !validator.isIn(value, listFromOptions || list),
        isNotEmptyString: (value, options) => !validator.isEmpty(`${value}`.trim(), options),
        isNotEmpty: (value, options) => !validator.isEmpty(value, options),
        isEmpty: (value, options) => validator.isEmpty(value, options),
        isFloatOrEmpty: (value, options) => validator.isFloat(value, options) || validator.isEmpty(value, options),
        arrayLengthRange: (value, options) => validator.isFloat(`${value.length}`, options),
        isURL: (value, options) => validator.isURL(encodeurl(value), cloneDeep(options)),
        isEmail: (value, options) => validator.isEmail(value, options),
        isDomain: (value, options) => validator.isFQDN(value, options),
    },
};

export { buildOptionsObject };

export default validationFunctions;
