import React from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownOption } from '../../../../../../components/Dropdown';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import TargetingDropdownBox from '../../../common-campaign-form/components/TargetingDropdownBox/TargetingDropdownBox';
import useSelectedItems from '../../../common-campaign-form/hooks/useSelectedItems';
import LanguageListItem from './LanguageListItem';
import { useLanguagesApi } from './hooks';
import styles from './language.module.scss';

const ITEM_HEIGHT = 40;

export const Language = () => {
    const { value: selectedValues, onChange: onChangeSelectedValues } = useFormFieldValue({
        field: 'campaignTargeting.languageTargeting.values',
    });

    const { value: targetingType, onChange: onChangeTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.languageTargeting.type',
    });
    const { languages, isResourcesLoading } = useLanguagesApi();

    const { handleAddItem, handleRemoveItem, availableItems, selectedItems, handleRemoveAllItems } = useSelectedItems({
        items: languages,
        isResourcesLoading,
        selectedValues,
        onChangeSelectedValues,
        targetingType,
    });

    if (isResourcesLoading) {
        return null;
    }

    return (
        <>
            <TargetingDropdownBox
                itemType={targetingType}
                onSelectItemType={({ value }) => {
                    onChangeTargetingType(value);
                }}
                selectedItems={selectedItems}
                itemPlaceholder={
                    <FormattedMessage
                        id="video.campaign.language.targeting.placeholder"
                        defaultMessage="Select Languages..."
                    />
                }
                onAddItem={handleAddItem}
                allItems={availableItems}
                itemOptionRenderer={DropdownOption}
            />
            <CollapsibleList
                data-testid="languages-list"
                items={selectedItems}
                ItemComponent={LanguageListItem}
                itemHeight={ITEM_HEIGHT}
                deleteItem={handleRemoveItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="video.campaign.language.targeting.header"
                        defaultMessage="Selected Languages"
                    />
                }
                clearItems={handleRemoveAllItems}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                containerClassName={styles['list']}
            />
        </>
    );
};

export default Language;
