import React from 'react';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useInsignificantChangesValidation } from '../../../../config';
import { EditableTextCellEditor } from './EditableTextCellEditor';

const EditableTextCellEditorWithInsigfinicantChangesValidationsBase = ({
    validations: baseValidations,
    ...restProps
}) => {
    const validations = useInsignificantChangesValidation({ baseValidations });
    return <EditableTextCellEditor validations={validations} {...restProps} />;
};

export const EditableTextCellEditorWithInsigfinicantChangesValidations = withGridCellFormDataProvider(
    EditableTextCellEditorWithInsigfinicantChangesValidationsBase
);
