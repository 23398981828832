import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIsTotalAccountConversionsValidForFunnel } from '../../hooks/useIsTotalAccountConversionsValidForFunnel';
import { generateConversionFunnelCreatorPath } from '../../utils/generateTrackingPath';
import styles from './createFunnelButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CreateFunnelButton = () => {
    const navigate = useNavigate();

    const shouldRenderCreateFunnelButtonInDropDown = useIsTotalAccountConversionsValidForFunnel();
    const isDisabled = !shouldRenderCreateFunnelButtonInDropDown;

    const onClickHandle = () => {
        if (isDisabled) {
            return;
        }
        navigate(generateConversionFunnelCreatorPath());
    };

    return (
        <Button onClick={onClickHandle} className={classNameBuilder('button', { isDisabled })}>
            <FormattedMessage id="tracking.unip.funnel.setup.create.funnel.empty.state" defaultMessage="+ New Funnel" />
            {isDisabled ? (
                <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow>
                    <FormattedMessage
                        id="tracking.unip.funnel.setup.create.funnel.tooltip"
                        defaultMessage="You must set up at least 2 conversion events before creating a funnel"
                    />
                </Tooltip>
            ) : null}
        </Button>
    );
};
