'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.dropDownStyles = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.isnil');

var _lodash2 = _interopRequireDefault(_lodash);

var _styleUtils = require('../../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

var _DropdownMenu = require('../Dropdown/DropdownMenu');

var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

var _withDropdownItemContainer = require('./withDropdownItemContainer');

var _withDropdownItemContainer2 = _interopRequireDefault(_withDropdownItemContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /**
                                                                                                                                                                                                                              * Created by itamar.c
                                                                                                                                                                                                                              * Date: 2019-27-12
                                                                                                                                                                                                                              */

var stripLastTwoCharacters = function stripLastTwoCharacters(input) {
    var str = String(input);
    return str.substring(0, str.length - 2);
};

var dropDownStyles = exports.dropDownStyles = function dropDownStyles() {
    var width = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
    var additionalStyles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Object.assign({
        container: function container(provided) {
            return Object.assign({}, provided, {
                width: String(width).indexOf('px') === -1 ? width + '%' : (0, _styleUtils2.default)(stripLastTwoCharacters(width))
            });
        },
        control: function control(provided, _ref) {
            var selectProps = _ref.selectProps;
            return Object.assign({}, provided, {
                border: 0,
                '--border': 'none',
                boxShadow: 'none',
                '&:hover': {
                    border: 0,
                    boxShadow: 'none'
                },
                borderWidth: 0,
                height: (0, _styleUtils2.default)(_withDropdownItemContainer.itemHeight)
            }, selectProps.skipLeftBorderRadius ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {}, selectProps.skipRightBorderRadius ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {});
        }
    }, additionalStyles);
};

function BareDropdown(props) {
    var value = props.value,
        width = props.width,
        optionItemRenderer = props.optionItemRenderer,
        className = props.className,
        styles = props.styles,
        containerClassName = props.containerClassName,
        rest = _objectWithoutProperties(props, ['value', 'width', 'optionItemRenderer', 'className', 'styles', 'containerClassName']);

    var Item = (0, _react.useMemo)(function () {
        return (0, _withDropdownItemContainer2.default)(optionItemRenderer, className);
    }, [optionItemRenderer, className]);

    var renderers = optionItemRenderer ? {
        valueRenderer: Item,
        optionRenderer: Item
    } : {};

    return _react2.default.createElement(_DropdownMenu2.default, Object.assign({
        styles: dropDownStyles(width, styles),
        selectedValueObject: (0, _lodash2.default)(value) ? value : { value: value },
        className: containerClassName
    }, renderers, rest));
}

BareDropdown.propTypes = Object.assign({}, _DropdownMenu2.default.propTypes, {
    /** string that represent the selected option */
    value: _propTypes2.default.string,
    /** width of the Dropdown, if number, treated as %, if string with 'px' suffix,
     * treated as px width */
    width: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    /** function which returns a custom way to render the option */
    optionItemRenderer: _propTypes2.default.func,
    /** className for the item container element */
    className: _propTypes2.default.string,
    /** className for the container element */
    containerClassName: _propTypes2.default.string,
    /** Prop to skip left border radius */
    skipLeftBorderRadius: _propTypes2.default.bool,
    /** Prop to skip right border radius */
    skipRightBorderRadius: _propTypes2.default.bool
});

BareDropdown.defaultProps = {
    // DropdownMenu Default Props
    // eslint-disable-next-line react/default-props-match-prop-types
    controlShouldRenderValue: true,
    // eslint-disable-next-line react/default-props-match-prop-types
    searchable: false
};

exports.default = BareDropdown;