import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'taboola-ultimate-ui';
import { FormLayout, LoadingBar } from 'modules/campaigns/components';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormFieldValue, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { COMPONENT_STATUS } from 'services/constants';
import { CONTENT_BANNER_TYPES, CreativeStudioContentBanner } from '../CreativeStudio/CreativeStudioContentBanner';
import { CroppingContentHeader, CroppingLayout, PreviewCroppedImage } from './components';
import { OUTCROPPING_COMPONENT_NAME } from './constants/croppingModalGtmConstants';
import { sendCropGTMEvent, useCroppingToolsFormState } from './hooks/useCroppingToolsFormState';
import styles from './croppingToolsForm.module.scss';

const classNameBuilder = classnames.bind(styles);

const CroppingToolsForm = ({ src: originalSrc, onClose, campaignId, shouldEnableCropSubmitAlways = false }) => {
    const { value: cropData = [] } = useFormFieldValue({
        field: 'cropData',
    });

    const {
        handleSubmit,
        handleSkip,
        setActiveRatio,
        activeRatio,
        activeIndex,
        isEqualToInitialData,
        ratios,
        setSrcHistory,
        curSrcIndex,
        srcHistory,
        initialCropData,
        outcroppingStatus,
        outcroppingError,
        onOutcropImage,
        isOutcroppingEnabled,
        updateSrc,
        src,
        shouldShowOutcropWarning,
        shouldRenderOutcropBanner,
        setInitialCropData,
    } = useCroppingToolsFormState({
        onClose,
        campaignId,
        originalSrc,
    });

    const onClickRatioPreview = useCallback(
        ratio => {
            setActiveRatio(ratio);
            sendCropGTMEvent('ratio preview: ' + ratio.toFixed(2));
        },
        [setActiveRatio]
    );

    const isOriginalSrc = src === originalSrc;

    const submitButtonText =
        isOutcroppingEnabled && curSrcIndex >= 0 ? (
            <FormattedMessage id="cropping.content.outcrop.submit.button" defaultMessage="Save Image" />
        ) : (
            <FormattedMessage id="cropping.content.submit.button" defaultMessage="Apply Cropping" />
        );

    const cancelButtonText = isOriginalSrc ? (
        <FormattedMessage id="cropping.content.cancel.button" defaultMessage="Skip" />
    ) : (
        <FormattedMessage id="cropping.content.outcrop.cancel.button" defaultMessage="Cancel" />
    );

    return (
        <FormInitBase>
            <ModalFormLayout
                onCancel={handleSkip}
                onSubmit={handleSubmit}
                cancelButtonText={cancelButtonText}
                submitButtonText={submitButtonText}
                header={<CroppingContentHeader />}
                headerContainerClassName={styles['header-container']}
                disableSubmit={isEqualToInitialData && !shouldEnableCropSubmitAlways && isOriginalSrc}
                bodyClassName={styles['body']}
                data-testid="cropping-content"
            >
                {shouldRenderOutcropBanner && !outcroppingError && (
                    <CreativeStudioContentBanner
                        className={styles['banner']}
                        type={CONTENT_BANNER_TYPES.WARNING}
                        id="cropping.content.banner.outcrop.warning"
                        defaultMessage="The aspect ratio of this image does not align with Taboola's best practices. Use the AI Image Extender, unless you're targeting Yahoo mobile with the correct image ratio uploaded"
                        suffix={
                            <Button
                                onClick={() => {
                                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                                        taboolaCampaignId: campaignId,
                                        component: `${OUTCROPPING_COMPONENT_NAME}: Extend Image warning Clicked`,
                                        value: src,
                                    });
                                    onOutcropImage();
                                }}
                                data-testid="cropping-content-outcrop-banner-button"
                                className={styles['banner-button']}
                                disabled={outcroppingStatus === COMPONENT_STATUS.LOADING}
                            >
                                <FormattedMessage
                                    id="cropping.content.banner.outcrop.warning.button"
                                    defaultMessage="Extend Image"
                                />
                            </Button>
                        }
                    />
                )}
                {outcroppingError && (
                    <CreativeStudioContentBanner
                        className={classNameBuilder('banner')}
                        type={CONTENT_BANNER_TYPES.ERROR}
                        id={outcroppingError}
                        defaultMessage="We were unable to process the request. Please try again."
                    />
                )}
                {cropData[activeIndex] && (
                    <FormNamespaceProvider field="cropData">
                        <div className={styles['container']}>
                            <FormNamespaceProvider field={activeIndex} itemKey={activeIndex}>
                                <CroppingLayout
                                    src={src}
                                    setActiveRatio={setActiveRatio}
                                    campaignId={campaignId}
                                    ratios={ratios}
                                    index={activeIndex}
                                    ratio={activeRatio}
                                    onOutcropImage={onOutcropImage}
                                    originalSrc={originalSrc}
                                    srcHistory={srcHistory}
                                    updateSrc={updateSrc}
                                    outcroppingStatus={outcroppingStatus}
                                    setSrcHistory={setSrcHistory}
                                    isOutcroppingEnabled={isOutcroppingEnabled}
                                    shouldShowOutcropWarning={shouldShowOutcropWarning}
                                    initialCropData={initialCropData}
                                    setInitialCropData={setInitialCropData}
                                />
                            </FormNamespaceProvider>
                            {outcroppingStatus !== COMPONENT_STATUS.LOADING ? (
                                <div className={styles['previews-container']}>
                                    {map(cropData, ({ ratio, ratioName }, index) => (
                                        <FormNamespaceProvider field={index} key={index + src} itemKey={index}>
                                            <PreviewCroppedImage
                                                src={src}
                                                ratio={ratio}
                                                isActive={ratio === activeRatio}
                                                onClick={onClickRatioPreview}
                                                ratioName={ratioName}
                                                isOutcroppingEnabled={isOutcroppingEnabled}
                                                shouldShowOutcropWarning={shouldShowOutcropWarning}
                                                index={index}
                                            />
                                        </FormNamespaceProvider>
                                    ))}
                                </div>
                            ) : (
                                <LoadingBar
                                    durationInSeconds={15}
                                    loadingMessage={
                                        <FormattedMessage
                                            id="creative.studio.generate.loading.message"
                                            defaultMessage="We're working on generating your images. It will only take a few seconds."
                                        />
                                    }
                                    containerClassName={styles['loading-bar']}
                                />
                            )}
                        </div>
                    </FormNamespaceProvider>
                )}
            </ModalFormLayout>
        </FormInitBase>
    );
};

CroppingToolsForm.protoTypes = {
    src: PropTypes.string,
};

const CroppingToolsFormProvider = withFormDataProvider(CroppingToolsForm, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
    mode: FORM_MODES.CREATE,
});

export { CroppingToolsFormProvider as CroppingToolsForm };
