import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { filter, flatten, values } from 'lodash';
import PropTypes from 'prop-types';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { withContainerValidations } from 'modules/taboola-common-frontend-modules/validations/components/hoc';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations/services';
import { FormLayout } from '../../../../components/Form';
import { FORM_MODES } from '../../../../config';
import { EmptyPreviewWidgetGallery } from '../../../creative-creator/components/PreviewWidgetField';
import { useCreativeCampaignData } from '../../../creative-creator/hooks/useCreativeCampaignData';
import { useFormatTypeFormFieldValueCreateMode } from '../../../creative-creator/hooks/useFormatTypeFormFieldValueCreateMode';
import { useIsAppInstallCreativeInEditMode } from '../../../creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { isAppleTargeting } from '../../../creative-creator/utils/isAppleTargeting';
import config from '../../config';
import { PLACEMENTS, PLACEMENTS_CONFIG } from '../../config/placements';
import { useCreativePreviewRBoxRenderListenerEffect } from '../../hooks';
import { hasInvalidItems } from '../../services';
import { CreativePreviewBody, CreativePreviewHeader, PlacementToolbar } from '../index';
import styles from './creativePreviewMultiItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const CreativePreviewMultiItem = ({
    onCancel,
    onEdit,
    editPermissionsProps,
    placements,
    formLayoutProps,
    items,
    onExcludeButtonClick,
    indicationType,
    message,
    isItemRepeatEnabled,
    formClassName,
    multiItem,
    ...rest
}) => {
    const { mode } = useFormDataContext();
    const { value: formatTypeValueCreate } = useFormatTypeFormFieldValueCreateMode();
    const { isAppInstall: isAppInstallInEdit } = useIsAppInstallCreativeInEditMode();
    const isAppInstall =
        mode === FORM_MODES.CREATE ? formatTypeValueCreate === CREATIVE_FORMAT_TYPE.APP_INSTALL : isAppInstallInEdit;
    const { fetchedCampaign } = useCreativeCampaignData();
    const isTargetingApple = isAppleTargeting(fetchedCampaign);
    const { value: selectedPlacement = PLACEMENTS.SIXTEEN_BY_NINE, onChange: setSelectedPlacement } = useFormFieldValue(
        {
            field: 'selectedPlacement',
        },
        { isAppInstall, isTargetingApple }
    );
    const isLoading = useMemo(() => hasInvalidItems(items), [items]);
    const selectedPlacementConfig = useMemo(() => [PLACEMENTS_CONFIG[selectedPlacement]], [selectedPlacement]);
    const className = classNameBuilder(formClassName);
    useCreativePreviewRBoxRenderListenerEffect(onExcludeButtonClick);

    return (
        <FormLayout
            onCancel={onCancel}
            formClassName={className}
            hideFooter
            header={
                <CreativePreviewHeader
                    onEdit={onEdit}
                    editPermissionsProps={editPermissionsProps}
                    items={multiItem && items}
                />
            }
            bodyContainerClassName={styles['form-body']}
            {...formLayoutProps}
        >
            {indicationType === INDICATION_TYPES.ERROR && (
                <div className={styles['error-message-container']}>{message}</div>
            )}
            <CreativePreviewBody
                className={styles['preview-body']}
                placements={selectedPlacementConfig}
                items={items}
                isItemRepeatEnabled={isItemRepeatEnabled}
                {...rest}
            />
            {!isAppInstall && (
                <PlacementToolbar
                    isDisabled={isLoading}
                    selected={selectedPlacement}
                    onSelect={setSelectedPlacement}
                    className={styles['toolbar-inner']}
                />
            )}
        </FormLayout>
    );
};

CreativePreviewMultiItem.propTypes = {
    formLayoutProps: PropTypes.shape(FormLayout.propTypes),
    items: PropTypes.array.isRequired,
    onExcludeButtonClick: PropTypes.func,
    loadingIndicator: PropTypes.node,
    indicationType: PropTypes.oneOf(Object.values(INDICATION_TYPES)),
    message: PropTypes.node,
    onEdit: PropTypes.func,
    editPermissionsProps: permissionsShape,
    multiItem: PropTypes.bool,
};

CreativePreviewMultiItem.defaultProps = {
    formLayoutProps: {},
    loadingIndicator: <EmptyPreviewWidgetGallery className={styles['empty-preview']} />,
    multiItem: true,
};

const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.preview.empty',
        defaultMessage: 'Select at least one variation',
    },
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: config.numMaxItems },
        messageId: 'validations.error.creative.preview.length',
        defaultMessage: 'Select 100 or fewer variations',
    },
];

export { CreativePreviewMultiItem };
export default withContainerValidations(
    CreativePreviewMultiItem,
    validations,
    'items',
    items => filter(flatten(values(items)), item => !item.deleted),
    { removeScrollElement: true }
);
