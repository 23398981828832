import { useContext } from 'react';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import PermissionsContext from 'modules/taboola-common-frontend-modules/authentication/PermissionsContext';
import { visibilityCondition } from '../config';

const allFeatureFlags = Object.values(FEATURE_FLAGS);
export const useIsBillingModulePermitted = () => {
    const allUserPermissions = useContext(PermissionsContext);
    const fullAccountConfig = useAccountConfig(allFeatureFlags);

    return visibilityCondition({ permissionsMap: allUserPermissions, accountConfig: fullAccountConfig });
};
