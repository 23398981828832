import React, { useMemo } from 'react';
import { saveAs as saveAsFile } from 'file-saver';
import { Button } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import CodeSnippet from 'modules/audiences/components/CodeSnippet/CodeSnippet';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { buildCodeSnippetWithParams } from 'modules/taboola-common-frontend-modules/account-configurations/utils/buildPixelCodeSnippet';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useSelectedAccount } from '../../../../hooks';
import styles from './pixelCode.module.scss';

const usePixelCodeSnippet = () => {
    const { [COMMON_FLAGS.UNIP_BASE_CODE_SNIPPET]: codeSnippet } = useCommonConfig([
        COMMON_FLAGS.UNIP_BASE_CODE_SNIPPET,
    ]);

    const [{ id }] = useSelectedAccount();

    return useMemo(() => {
        return buildCodeSnippetWithParams(codeSnippet, id);
    }, [codeSnippet, id]);
};

const DownloadButton = ({ pixelCodeSnippet }) => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const fileName = 'pixel_code_snippet_' + accountId;

    const exportToLocalTextFile = (text, filename) => {
        const blob = new Blob([text], { type: 'text/plain;charset=utf-8;' });

        saveAsFile(blob, filename);
    };

    return (
        <Button
            onClick={() => exportToLocalTextFile(pixelCodeSnippet, fileName)}
            variant={Button.variant.ghost}
            className={styles['download-button']}
        >
            <FormattedMessage id="tracking.settings.pixel.code.download.button" defaultMessage="DOWNLOAD CODE" />
        </Button>
    );
};

export const PixelCode = () => {
    const pixelCodeSnippet = usePixelCodeSnippet();

    return (
        <CommonCollapsibleCard
            id="PIXEL CODE"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="tracking.settings.pixel.code" defaultMessage="Pixel Code" />}
                />
            }
        >
            <FormField
                inputId="pixel-code-description"
                label={<FormattedMessage id="tracking.settings.pixel.code.label" defaultMessage="Pixel Code" />}
                description={
                    <div className={styles['download-button-container']}>
                        <FormattedMessage
                            id="tracking.settings.pixel.code.description"
                            defaultMessage="Copy the code to clipboard or download by clicking the button on the right"
                        />
                        <DownloadButton pixelCodeSnippet={pixelCodeSnippet} />
                    </div>
                }
                descriptionClass={styles['code-event-description']}
            >
                <CodeSnippet codeSnippetText={pixelCodeSnippet} />
            </FormField>
        </CommonCollapsibleCard>
    );
};
