import { useCallback, useContext } from 'react';
import { isEmpty, isMatch } from 'lodash';
import PermissionsContext from '../modules/taboola-common-frontend-modules/authentication/PermissionsContext';
import parseRecursivePermissions from '../modules/taboola-common-frontend-modules/authentication/services/parseRecursivePermissions';
import { useItemsConditionConfigData } from './useItemsConditionConfigData';
import { useNonAuthAccess } from './useNonAuthAccess';

const getFormattedPermission = (permissions = [], operator) => {
    if (Array.isArray(permissions)) {
        return { permissions, operator };
    }

    return typeof permissions === 'string' ? { permissions: [permissions], operator } : permissions;
};

const emptyList = [];

const isItemVisible = ({ visibilityCondition }, conditionData) =>
    !visibilityCondition || visibilityCondition(conditionData);

const isItemPermitted = ({ permissions, operator }, allUserPermissions, nonAuthAccess) => {
    const formattedPermission = getFormattedPermission(permissions, operator);

    const isPermitted =
        isEmpty(allUserPermissions) && (!nonAuthAccess || !isEmpty(permissions))
            ? false
            : parseRecursivePermissions(formattedPermission, allUserPermissions);

    return isPermitted;
};

const isItemConfigured = ({ accountConfigurations, commonConfigurations }, fullAccountConfig, fullCommonConfig) =>
    (!accountConfigurations || isMatch(fullAccountConfig, accountConfigurations)) &&
    (!commonConfigurations || isMatch(fullCommonConfig, commonConfigurations));

const getItemWithDisableFlag = (item, conditionData) => {
    const { enableCondition } = item;
    const enabled = !enableCondition || enableCondition({ ...item, ...conditionData });

    return {
        ...item,
        disabled: !enabled,
    };
};

export const useAvailableItemsService = conditionData => {
    const conditionDataWithConfig = useItemsConditionConfigData(conditionData);
    const allUserPermissions = useContext(PermissionsContext);
    const nonAuthAccess = useNonAuthAccess();

    const availableItemsHandler = useCallback(
        (list = emptyList) => {
            const visibleList = list.filter(el => isItemVisible(el, conditionDataWithConfig));
            const permittedList = visibleList.filter(item => isItemPermitted(item, allUserPermissions, nonAuthAccess));
            const configuredList = permittedList.filter(item =>
                isItemConfigured(item, conditionDataWithConfig.accountConfig, conditionDataWithConfig.commonConfig)
            );
            const availableList = configuredList.map(item => getItemWithDisableFlag(item, conditionDataWithConfig));

            return availableList;
        },
        [conditionDataWithConfig, allUserPermissions, nonAuthAccess]
    );

    return availableItemsHandler;
};
