import { invert } from 'lodash';

export const ENTITY = {
    TRACKING_SETTINGS: 'TRACKING-SETTINGS',
    CONVERSION: 'CONVERSION',
    GTM_SETUP: 'GTM_SETUP',
    MANUALLY_SETUP: 'MANUALLY_SETUP',
    S2S_SETUP: 'S2S_SETUP',
    SHOPIFY_SETUP: 'SHOPIFY_SETUP',
    ACTIVE_EVENTS: 'ACTIVE_EVENTS',
    FUNNEL: 'FUNNEL',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.TRACKING_SETTINGS]: 'tracking-settings',
    [ENTITY.CONVERSION]: 'conversion',
    [ENTITY.FUNNEL]: 'funnel',
    [ENTITY.GTM_SETUP]: 'gtm-setup',
    [ENTITY.MANUALLY_SETUP]: 'manually-setup',
    [ENTITY.S2S_SETUP]: 'server-to-server-setup',
    [ENTITY.SHOPIFY_SETUP]: 'shopify-setup',
    [ENTITY.ACTIVE_EVENTS]: 'active-events',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
