import React, { useCallback, useEffect, useMemo } from 'react';
import { identity, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Button, AddIcon } from 'tuui';
import uuid from 'uuid/v1';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { immutableMergeWith } from 'modules/taboola-common-frontend-modules/utils/customMergers';
import { COMPONENT_STATUS } from 'services/constants';
import { useFormDataContext, useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { UrlItem } from './UrlItem';
import { useFetchPageMetadata } from './useFetchPageMetadata';
import styles from './urlList.module.scss';

export const UrlList = ({ maxItems, disabled }) => {
    const {
        value: urls,
        onChange: setUrls,
        isDirty,
    } = useFormFieldValue({
        field: 'urls',
    });
    const { value: content, onChange: setContent } = useFormFieldValue({ field: 'content' });
    const { initialData } = useFormDataContext();
    const hasEmptyItem = useMemo(() => (urls || []).some(item => !item.value), [urls]);

    const addNewItem = () => setUrls(prevList => [...prevList, { id: uuid(), value: '' }]);
    const removeItem = idToDelete =>
        setUrls(prevList =>
            prevList.length === 1 ? [{ id: uuid(), value: '' }] : prevList.filter(({ id }) => id !== idToDelete)
        );

    const addContentItem = useCallback(
        item => {
            const insertIndex = content.filter(({ status }) => status === COMPONENT_STATUS.ACTIVE).length;
            const placeHolderForInsert = content[insertIndex];
            const id = uuid();
            setContent([
                ...content.slice(0, insertIndex),
                { ...placeHolderForInsert, id, status: COMPONENT_STATUS.ACTIVE, ...item },
                ...content.slice(insertIndex + 1),
            ]);
            return id;
        },
        [content, setContent]
    );

    const updateContent = useCallback(
        ({ id, ...rest }) =>
            setContent(prevContent => {
                const index = prevContent.findIndex(({ id: elId }) => elId === id);
                if (index < 0) {
                    return prevContent;
                }
                return [
                    ...prevContent.slice(0, index),
                    immutableMergeWith(prevContent[index], { ...rest, id: uuid() }),
                    ...prevContent.slice(index + 1),
                ];
            }),
        [setContent]
    );
    const fetchPageMetadata = useFetchPageMetadata(addContentItem, updateContent);
    const onSetLoadMetadata = (id, url) => fetchPageMetadata(id, url);

    // TODO: should be removed - after DEV-130554
    useEffect(() => {
        if (!isDirty && !isEqual(initialData.urls, urls)) {
            setUrls(identity);
        }
    }, [urls, isDirty, initialData.urls, setUrls]);

    return (
        <>
            <div className={styles['container']}>
                <FormNamespaceProvider field="urls">
                    {urls.map((item, index) => (
                        <div key={item.id} className={styles['url-item']}>
                            <FormNamespaceProvider field={index} itemKey={item.id}>
                                <UrlItem
                                    item={item}
                                    existingItems={urls}
                                    disabled={disabled}
                                    deleteItem={removeItem}
                                    onSetLoadMetadata={onSetLoadMetadata}
                                />
                            </FormNamespaceProvider>
                        </div>
                    ))}
                </FormNamespaceProvider>
            </div>
            {!disabled && (
                <Button
                    onClick={addNewItem}
                    variant={Button.variant.text}
                    size={Button.size.md}
                    disabled={urls?.length >= maxItems || hasEmptyItem}
                    aria-label="Add another URL"
                >
                    <AddIcon />
                    <FormattedMessage id="creative.creator.urls.button.label" defaultMessage="Add another URL" />
                </Button>
            )}
        </>
    );
};

UrlList.propTypes = {
    urls: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            status: PropTypes.string,
        })
    ),
    maxItems: PropTypes.number,
    id: PropTypes.string,
};

UrlList.defaultProps = {
    maxItems: 10,
    urlsForValidation: {},
};
