import React from 'react';

const ThumbnailGalleryEmptySVGIcon = () => (
    <svg width="212" height="48" viewBox="0 0 212 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 1h56c2.7614 0 5 2.23858 5 5v36c0 2.7614-2.2386 5-5 5H6c-2.76142 0-5-2.2386-5-5V6c0-2.76142 2.23858-5 5-5zM150 1h56c2.761 0 5 2.23858 5 5v36c0 2.7614-2.239 5-5 5h-56c-2.761 0-5-2.2386-5-5V6c0-2.76142 2.239-5 5-5z"
            stroke="#9CA8B5"
            strokeWidth="2"
        />
        <rect x="4" y="4" width="60" height="40" rx="2" fill="#EAECEF" />
        <rect x="148" y="4" width="60" height="40" rx="2" fill="#EAECEF" />
        <path
            d="M36.6666 18l-5 6.6667 3.8 5.0666-2.1333 1.6c-2.2533-3-6-8-6-8l-8 10.6667h29.3333l-12-16zM30 16.5c0 1.3807-1.1193 2.5-2.5 2.5-1.3808 0-2.5-1.1193-2.5-2.5s1.1192-2.5 2.5-2.5c1.3807 0 2.5 1.1193 2.5 2.5zM170.971 24.362h4.389v5.2347c-.652.4719-1.349.8186-2.089 1.04-.734.2155-1.513.3233-2.335.3233-1.052 0-2.004-.1602-2.856-.4806-.852-.3263-1.58-.7778-2.185-1.3546s-1.072-1.2613-1.401-2.0537c-.329-.7923-.494-1.6575-.494-2.5955 0-.9496.156-1.8206.467-2.6129.317-.7924.767-1.474 1.348-2.045.582-.5709 1.284-1.0166 2.107-1.3371.828-.3204 1.754-.4806 2.776-.4806.529 0 1.022.0437 1.48.1311.459.0816.882.1981 1.269.3495.388.1457.744.3205 1.067.5244.323.2039.617.4282.881.6729l-.767 1.171c-.117.1865-.27.3001-.458.3409-.188.0407-.394-.0059-.617-.1399-.211-.1281-.42-.2418-.626-.3408-.199-.1049-.414-.1952-.643-.2709-.229-.0757-.479-.134-.749-.1748s-.582-.0612-.934-.0612c-.594 0-1.131.102-1.613.3059-.482.2039-.893.4923-1.234.8652-.335.3728-.593.8214-.775 1.3458-.182.5243-.273 1.1098-.273 1.7565 0 .6992.099 1.3255.299 1.8789.2.5477.482 1.0108.846 1.3895.365.3787.799.6671 1.305.8652.511.1981 1.078.2971 1.7.2971.423 0 .799-.0408 1.128-.1223.335-.0874.661-.2039.979-.3496v-2.1323h-1.446c-.17 0-.305-.0437-.405-.1311-.094-.0932-.141-.2097-.141-.3496V24.362zM180.182 30.8202h-2.661V18.1398h2.661v12.6804zM185.518 20.1935v3.5044h4.601v2.0624h-4.601v5.0599h-2.661V18.1398H191v2.0537h-5.482z"
            fill="#9CA8B5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M82.8001 7.2l-2.4-7.2H85l2.4 7.2h4.8L89.8 0H94l2.4 7.2h4.8L98.8 0h4.2l2.4 7.2h4.8L107.8 0h4.2l2.4 7.2h4.8L116.8 0h4.2l2.4 7.2h4.8L125.8 0h4.2l2.4 7.2h4.8L134.8 0h3.2c1.105 0 2 .895431 2 2v42c0 2.2091-1.791 4-4 4H76c-2.2091 0-4-1.7909-4-4V6c0-2.5163 1.549-4.67082 3.7455-5.562028C76.0082 1.52347 78.0001 7.2 78.0001 7.2h4.8zM114.4 23.9215c1.6.9238 1.6 3.2332 0 4.157l-10.8 6.2353c-1.6.9238-3.6001-.2309-3.6001-2.0784V19.7646c0-1.8475 2.0001-3.0022 3.6001-2.0784l10.8 6.2353z"
            fill="#9CA8B5"
        />
    </svg>
);

export default ThumbnailGalleryEmptySVGIcon;
