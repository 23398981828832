import React, { useCallback } from 'react';
import { ToggleSwitch } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { withIndication } from 'modules/errors';
import { ChooseFromExistingReportsCheckBox } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/ChooseFromExistingReportsCheckBox';
import { CreateNewReportCheckBox } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/CreateNewReportCheckBox';
import { isEmailNotificationActiveValidations } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/isEmailNotificationActiveValidations';
import styles from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/performanceRuleEmailNotifications.module.scss';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

const WithMessage = withIndication(({ children }) => <div>{children}</div>);

export const PerformanceRuleEmailNotifications = () => {
    const shouldEnableEmailNotifications = useConfigMatch({
        [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLE_EMAIL_NOTIFICATIONS]: 'true',
    });

    const { mode } = useFormDataContext();

    const { value: scheduledReportIds, onChange: onScheduleReportIdsChange } = useFormFieldValue({
        field: 'scheduledReportIds',
    });
    const { value: createNewScheduledReport, onChange: onCreateNewScheduledReportChange } = useFormFieldValue({
        field: 'createNewScheduledReport',
    });
    const {
        value: isEmailNotificationActive,
        onChange: setIsEmailNotificationActive,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'isEmailNotificationActive',
        validations: isEmailNotificationActiveValidations,
        validationDependencies: { scheduledReportIds, createNewScheduledReport },
    });

    const handleToggle = useCallback(() => {
        const toggleEmailNotificationActive = () => {
            if (isEmailNotificationActive) {
                setIsEmailNotificationActive(false);
                onScheduleReportIdsChange(null);
            } else {
                setIsEmailNotificationActive(true);
                onScheduleReportIdsChange([]);
            }
            onCreateNewScheduledReportChange(false);
        };

        toggleEmailNotificationActive();
    }, [
        onScheduleReportIdsChange,
        onCreateNewScheduledReportChange,
        isEmailNotificationActive,
        setIsEmailNotificationActive,
    ]);

    if (!shouldEnableEmailNotifications) {
        return null;
    }

    if (mode === FORM_MODES.EDIT) {
        return null;
    }

    return (
        <WithMessage {...failedValidationData}>
            <FormField
                inputId="performance-rules-min-campaign-age-input"
                label={
                    <FormattedMessage
                        id="app.performance.rules.setup.email.notifications.title"
                        defaultMessage="Notify"
                    />
                }
                description={
                    <FormattedMessage
                        id="app.performance.rules.setup.email.notifications.description"
                        defaultMessage="Get a scheduled report to your email with a summary of all rule's actions"
                    />
                }
                containerClass={styles['field-container']}
            >
                <div className={styles['toggle-container']}>
                    <div className={styles['toggle-label']}>
                        <ToggleSwitch checked={isEmailNotificationActive} onChange={handleToggle} height={13} />
                        <label className={styles['label']}>
                            <FormattedMessage
                                id="app.performance.rules.setup.email.notifications.toggle.title"
                                defaultMessage="Include this rule in a scheduled report"
                            />
                        </label>
                    </div>
                    {isEmailNotificationActive && (
                        <>
                            <ChooseFromExistingReportsCheckBox />
                            <CreateNewReportCheckBox />
                        </>
                    )}
                </div>
            </FormField>
        </WithMessage>
    );
};
