import { useState, useCallback, useRef } from 'react';
import { isEmpty, reduce } from 'lodash';
import { generateSubPathsMap } from '../utils';

const getAllLoadingPathsMap = data =>
    data.reduce((result, leaf) => {
        const subPathsMap = generateSubPathsMap(leaf, () => 1);
        return { ...result, ...subPathsMap };
    }, {});

const useLoadingState = (leaves, getLoadingPathsMap = getAllLoadingPathsMap) => {
    const [loadingPaths, setLoadingPaths] = useState({});
    const leavesRef = useRef(leaves);
    leavesRef.current = leaves;

    const startLoading = useCallback(
        (paths = []) => {
            if (isEmpty(paths)) {
                const allLoadingPaths = getLoadingPathsMap(leavesRef.current);
                setLoadingPaths(allLoadingPaths);
                return;
            }

            setLoadingPaths(current =>
                reduce(
                    paths,
                    (result, path) => {
                        const newCount = path in current ? current[path] + 1 : 1;
                        return { ...result, [path]: newCount };
                    },
                    {}
                )
            );
        },
        [setLoadingPaths, getLoadingPathsMap]
    );

    const stopLoading = useCallback(
        (paths = []) => {
            if (isEmpty(paths)) {
                setLoadingPaths({});
                return;
            }

            setLoadingPaths(current =>
                reduce(
                    paths,
                    (result, path) => {
                        const newCount = current[path] ? current[path] - 1 : 0;
                        if (newCount === 0) {
                            const { [path]: pathCount, ...resultWithoutPath } = result;
                            return resultWithoutPath;
                        }

                        return { ...result, [path]: newCount };
                    },
                    {}
                )
            );
        },
        [setLoadingPaths]
    );

    return {
        loadingPaths,
        startLoading,
        stopLoading,
    };
};

export default useLoadingState;
