import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AddTrackingToCampaign } from '../TrackingS2SAddToCampaign/AddTrackingToCampaign';
import { S2SSreverSide } from '../TrackingS2SServerSide/S2SSreverSide';
import styles from './s2SCampaignSetup.module.scss';

export const S2SCampaignSetup = () => {
    return (
        <CommonCollapsibleCard
            id="S2S Campaign Setup"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="tracking.setup.s2s.campaign.label" defaultMessage="Setup" />}
                />
            }
        >
            <S2SSreverSide />
            <AddTrackingToCampaign />
            <Indication
                type="info"
                message={
                    <FormField
                        className={styles['tag']}
                        label={<FormattedMessage id="tracking.setup.s2s.campaign.detail.label" />}
                        description={
                            <FormattedMessage
                                id="tracking.setup.s2s.campaign.detail.description.helpcenter"
                                defaultMessage="For a detailed step-by-step guide and advanced S2S features, please see our <a>help center</a>"
                            />
                        }
                    />
                }
                displayDismissComponent={false}
            />
        </CommonCollapsibleCard>
    );
};
