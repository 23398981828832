import React from 'react';
import { EXPLORATION_STATUS } from 'modules/campaigns/config/siteConsts';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { PerformanceRuleCellEditor } from './PerformanceRuleCellEditor';
import { SafetyNetsStatusCellEditor } from './SafetyNetsStatusCellEditor';
import { SiteExplorationCellEditor } from './SiteExplorationCellEditor';

export const SiteStatusCellEditor = props => {
    const { value } = props;
    const { isPublisherBlocked, explorationStatus, performanceRuleId } = value;
    const isExplorationBar =
        usePermissions('EXPLORATION_BAR_AND_STATUS') &&
        !isPublisherBlocked &&
        explorationStatus === EXPLORATION_STATUS.IN_PROGRESS;
    const isPerformanceRule = !!performanceRuleId;

    if (isExplorationBar) {
        return <SiteExplorationCellEditor {...props} />;
    }
    if (isPerformanceRule) {
        return <PerformanceRuleCellEditor {...props} />;
    }
    return <SafetyNetsStatusCellEditor {...props} />;
};
