import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { addMeasuredByMoatToMetric } from '../../../utils/addMeasuredByMoat';
import { HOUR_OF_DAY } from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-hour-of-day-performance',
    metrics: [
        metrics.SPENT,
        metrics.COMPLETED_VIEWS,
        addMeasuredByMoatToMetric(metrics.IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.CPM),
        addMeasuredByMoatToMetric(metrics.VCPM),
        metrics.CPCV,
        addMeasuredByMoatToMetric(metrics.VIEWABILITY_RATE),
        addMeasuredByMoatToMetric(metrics.HUNDRED_PCT_VIEWABILITY_RATE),
        metrics.COMPLETION_RATE,
        metrics.CLICKS,
        metrics.CTR,
        metrics.VCTR,
        metrics.CPA,
        metrics.CVR,
        metrics.CONVERSIONS,
    ],
    defaultSort: [{ field: HOUR_OF_DAY.field, order: SORTING_ORDER_TYPES.ASCENDANT }],
    maxAllowedSelectedMetrics: 3,
    graphProps: {
        xAxisDataKey: HOUR_OF_DAY.field,
    },
};

export default graph;
