import { isEmpty } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { generateValidations } from '../../ContextualSegments/audiencesValidationsGenerator';
import { attentiveAudienceType } from '../utils/MyAudienceUtils';

const listValidations = [
    ...generateValidations('myAudienceIncludeValuesLength', [
        'marketPlaceAudienceValuesLength',
        'contextualSegmentsValuesLength',
        'customContextualSegmentsValuesLength',
    ]),
    {
        validationFn: (values, options, { includeAudiences = {} }) => {
            const isIncludeAttentiveAudience = includeAudiences.some(item =>
                item.label.startsWith(attentiveAudienceType)
            );
            const isNotIncludeAttentiveAudience = includeAudiences.some(
                item => !item.label.startsWith(attentiveAudienceType)
            );
            return !(isIncludeAttentiveAudience && isNotIncludeAttentiveAudience);
        },
        severity: INDICATION_TYPES.WARNING,
        messageId: 'campaign.targeting.audiences.attentive.audiences.warning',
        defaultMessage:
            'Attentive Audience performs better on its own. We recommend removing all other audiences for best results.',
    },
    {
        validationFn: (values, options, { allSegmentsIdMap = {} }) => {
            if (isEmpty(allSegmentsIdMap)) {
                return true;
            }
            return values.every(({ id }) => allSegmentsIdMap[id] !== undefined);
        },
        messageId: 'validations.error.campaign.targeting.my.audiences.noScaleForChosenCountry',
        defaultMessage:
            'Some of the targeted audiences does not have scale in the targeted countries. Please remove them to proceed',
    },
];

const itemValidations = [
    {
        validationFn: (value, _, { allSegmentsByIdMap = {} }) => {
            return !!allSegmentsByIdMap[value];
        },
        messageId: 'validations.error.campaign.targeting.my.audiences.item.noScaleForChosenCountry',
        defaultMessage: 'This audience is not available in the targeted countries',
    },
    {
        validationFn: (value, _, { rejectedAudiencesList = [] }) => {
            return !rejectedAudiencesList.includes(parseInt(value));
        },
        messageId: 'validations.error.campaign.targeting.my.audiences.item.rejected',
        defaultMessage: 'This audience was rejected',
    },
];

export { listValidations, itemValidations };
