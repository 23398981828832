/**
 * Corresponds to backstage creative focus types in backstage api.
 * AUTOMATIC lets backstage choose the creative thumbnail focal point
 * COORDINATES lets the user choose
 */
export const FOCUS_TYPES = {
    AUTOMATIC: 'AUTOMATIC',
    COORDINATES: 'COORDINATES',
    PADDING: 'PADDING',
};

export const FOCUS_TYPES_ARRAY = Object.values(FOCUS_TYPES);
