import { useContext, useMemo } from 'react';
import { ABTestContext } from './ABTestProvider';

export const useTestValue = ({ key, fallback }) => {
    const { eventEmitter, isReady } = useContext(ABTestContext);
    const { value, experimentId } = eventEmitter.values[key] ?? { value: fallback, experimentId: null };

    const analyticProps = useMemo(() => {
        const props = {};
        if (experimentId) {
            props['data-metrics-column-name'] = `${key},${experimentId}`;
        }

        return props;
    }, [experimentId, key]);

    return {
        isReady,
        value,
        analyticProps,
    };
};
