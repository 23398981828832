import { useEffect } from 'react';
import { Route } from 'react-router';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';

export const CustomizedRoute = props => {
    const { path, render, hasNestedRoutes, pages, registerPage, drawerMode } = props;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    useEffect(() => {
        if (pages[path] || !isMultiLayerDrawerEnabled || hasNestedRoutes) {
            return;
        }

        registerPage({ path, component: render, pattern: path, drawerMode });
    }, [registerPage, pages, isMultiLayerDrawerEnabled, render, path, hasNestedRoutes, drawerMode]);

    if (!isMultiLayerDrawerEnabled || hasNestedRoutes) {
        return <Route {...props} render={({ staticContext, exact, ...renderProps }) => render({ ...renderProps })} />;
    }

    return null;
};
