import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { sum } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { COMMON_FLAGS, useCommonConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { sortArrayWithoutMutation, sortTerms } from '../../customContextualTopicsUtils';
import { useCountriesField } from '../../hooks/useCountriesField';
import { useFullTermsData } from '../../hooks/useFullTermsData';

const calculateQueryKey = ({ terms, platforms, countries }) => {
    const sortedPlatforms = sortArrayWithoutMutation(platforms);
    const sortedCountries = sortArrayWithoutMutation(countries);
    const sortedTerms = sortTerms(terms);
    return ['getCustomContextualSizeEstimate', sortedTerms, sortedPlatforms, sortedCountries];
};

const EMPTY_ARRAY = [];
export const useCustomContextualSizeEstimateApi = isInputValid => {
    const { [COMMON_FLAGS.CUSTOM_CONTEXTUAL_REACH_THRESHOLD]: impressionsThreshold } = useCommonConfig([
        COMMON_FLAGS.CUSTOM_CONTEXTUAL_REACH_THRESHOLD,
    ]);
    const { value: terms = { include: EMPTY_ARRAY, exclude: EMPTY_ARRAY } } = useFormFieldValue({
        field: 'terms',
    });
    const { value: platforms } = useFormFieldValue({
        field: 'platforms',
    });
    const { value: countries } = useCountriesField();

    const fullTermData = useFullTermsData();

    const {
        formAccount: { accountId: selectedAccountId },
    } = useFormDataContext();
    const queryKey = useMemo(() => calculateQueryKey({ terms, platforms, countries }), [platforms, countries, terms]);
    const { getCustomContextualSizeEstimate } = useAudiencesApi();
    const reverseGraphPoints = data => {
        if (data && data.size && !data.reversed) {
            data.size = data.size.reverse();
            data.reversed = true;
        }
        return data;
    };

    const {
        data,
        isLoading: isSizeEstimateLoading,
        refetch,
    } = useQuery(queryKey, () => getCustomContextualSizeEstimate(selectedAccountId, { terms, platforms, countries }), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        select: reverseGraphPoints,
        enabled: !!isInputValid,
    });

    const { impressions, articles } = useMemo(() => {
        if (terms.include.length === 1 && fullTermData?.length === 1) {
            return { impressions: fullTermData[0].size, articles: fullTermData[0].articles };
        }
        return { impressions: data && sum(data?.size), articles: data?.articles };
    }, [data, fullTermData, terms.include]);

    return {
        graphPoints: data?.size,
        impressions,
        articles,
        refetch,
        isSizeEstimateLoading,
        isSmallReach: impressions < impressionsThreshold,
        isServerError: data?.size?.length === 1 && data.size[0] === 0,
    };
};
