import React, { useCallback, useMemo } from 'react';
import { FormField, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { DAY_AND_GROUP_OPTIONS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/consts/dayTimeDaysOptions';
import transformTimeZonesFromGW from 'modules/campaigns/services/transformers/timeZonesTransformer';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFetchTimeZones } from '../../../creative-editor/components/AdScheduler/useFetchTimeZones';
import { DayTimeParting } from '../DayTimeParting/DayTimeParting';
import TimezoneDropdown from '../TimezoneDropdown/TimezoneDropdown';
import { BidModifierTooltip } from './components/BidModifierTooltip/BidModifierTooltip';
import { getDefaultBidDayParting } from './consts/bidModificationDayTimeDefaultValue';
import { DAY_TIME_BID_OPTION } from './consts/dayTimeBidOptions';
import { useBidModifierLock } from './useBidModifierLock';
import styles from './dayTimeBidBoost.module.scss';

export const DayTimeBidBoost = () => {
    const { value: dayTimeBidMode, onChange: setDayTimeBidMode } = useFormFieldValue({
        field: 'dayTimeBidModifier.mode',
    });
    const { onChange: setDayTimeBidModifierValues } = useFormFieldValue({ field: 'dayTimeBidModifier.values' });
    const isDayTimeBidTimePermitted = useConfigMatch({ [FEATURE_FLAGS.BID_DAY_TIME_ENABLED]: 'true' });
    const timeZoneEnabled = useConfigMatch({ [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true' });
    const { timeZoneName } = useAccount();
    const { formAccount: account } = useFormDataContext();
    const { value: timeZone, onChange: onTimeZoneChange } = useFormFieldValue(
        { field: 'dayTimeBidModifier.timeZone' },
        { timeZoneEnabled, account }
    );

    const changeDayTimeBidMode = useCallback(
        value => {
            setDayTimeBidMode(value);
            setDayTimeBidModifierValues(value === DAY_TIME_BID_OPTION.NONE ? [] : [getDefaultBidDayParting()]);
            onTimeZoneChange(timeZoneEnabled ? timeZoneName : null);
        },
        [onTimeZoneChange, setDayTimeBidMode, setDayTimeBidModifierValues, timeZoneEnabled, timeZoneName]
    );
    const resetHandler = useCallback(() => {
        setDayTimeBidMode(DAY_TIME_BID_OPTION.NONE);
        setDayTimeBidModifierValues([getDefaultBidDayParting()]);
    }, [setDayTimeBidMode, setDayTimeBidModifierValues]);
    const disabled = useBidModifierLock({ resetHandler });
    const { data, isSuccess } = useFetchTimeZones();

    const listOfTimeZones = useMemo(() => {
        if (isSuccess) {
            return transformTimeZonesFromGW(data);
        }

        return [];
    }, [isSuccess, data]);

    if (!isDayTimeBidTimePermitted) {
        return null;
    }

    return (
        <FormField
            inputId="dayTimeBid"
            label={
                <FormattedMessage id="campaign.editor.bid.dayTimeBid.title" defaultMessage="Bid per Days and Hours" />
            }
            description={
                <div className={styles['description-container']}>
                    <FormattedMessage
                        id="campaign.editor.conversion.goal.description.title"
                        defaultMessage="Increase or decrease your CPC Bid per Days and Hours."
                    />
                    <br />
                    <div className={styles['description']}>
                        <FormattedMessage
                            id="campaign.editor.conversion.goal.description.text"
                            defaultMessage="Note, this will not change the campaign’s day parting targeting."
                        />
                    </div>
                </div>
            }
        >
            <RadioGroup selectedValue={dayTimeBidMode} onChange={changeDayTimeBidMode}>
                <BidModifierTooltip wrap={disabled}>
                    <Radio
                        value={DAY_TIME_BID_OPTION.NONE}
                        title={<FormattedMessage id="campaign.editor.bid.dayTimeBid.none" defaultMessage="None" />}
                        name="day-time-bid-none-option"
                        disabled={disabled}
                    />
                    <Radio
                        value={DAY_TIME_BID_OPTION.CUSTOM}
                        title={
                            <FormattedMessage
                                id="campaign.editor.bid.dayTimeBid.adjust"
                                defaultMessage="Adjust bids for specific days or hours"
                            />
                        }
                        disabled={disabled}
                    />
                </BidModifierTooltip>
            </RadioGroup>
            <div className={styles['container']}>
                {dayTimeBidMode === DAY_TIME_BID_OPTION.CUSTOM && (
                    <div className={styles['day-time']}>
                        <DayTimeParting
                            field="dayTimeBidModifier.values"
                            newRowData={getDefaultBidDayParting()}
                            showType={false}
                            dayOptions={DAY_AND_GROUP_OPTIONS}
                        />
                        {timeZoneEnabled && (
                            <div className={styles['time-zone-dropdown']}>
                                <TimezoneDropdown
                                    selectedTimezone={timeZone ?? timeZoneName}
                                    timezones={listOfTimeZones}
                                    onChange={({ value }) => {
                                        onTimeZoneChange(value);
                                    }}
                                    helpText=""
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </FormField>
    );
};
