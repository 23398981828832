import { useState, useCallback } from 'react';
import { has } from 'lodash';

export const useBundleData = () => {
    const [unbundledNodes, setUnbundledNodes] = useState({});

    const getIsNodeBundled = useCallback(
        (node, hasChildren) => {
            const { id, isBundle: isBundleType } = node;

            const isCurrentlyBundled = !has(unbundledNodes, id);

            return isBundleType && isCurrentlyBundled && !hasChildren;
        },
        [unbundledNodes]
    );

    const unbundleNode = useCallback(
        node => {
            const { id, isBundle: isBundleType } = node;

            if (isBundleType) {
                setUnbundledNodes({ ...unbundledNodes, [id]: node });
            }
        },
        [setUnbundledNodes, unbundledNodes]
    );

    return { unbundledNodes, unbundleNode, getIsNodeBundled };
};
