import { useMemo } from 'react';
import { intersection, xor } from 'lodash';
import { usePermissions } from '../../taboola-common-frontend-modules/authentication';
import { PULL_PAYMENT_TYPES } from '../config/PaymentType';
import { useBillingData } from './useBillingData';

export const useAddPaymentMethods = () => {
    const {
        billingData: { allowedPrepaymentMethodsV2, paymentMethodsV2, isFetching },
    } = useBillingData();

    //the logic of verifying legal-entity and stripe-account exists only in the
    // AllowedPrepaymentMethods API call so currently this condition is a must
    const isAllowedToAddPaymentMethods = allowedPrepaymentMethodsV2?.length > 0;
    const isEditPermitted = usePermissions('BUDGET_MANAGEMENT_PAYMENT_METHODS_EDIT');

    const paymentMethodTypesToAdd = useMemo(() => {
        if (!isAllowedToAddPaymentMethods || !isEditPermitted || isFetching) {
            return [];
        }

        //Find all non-existent (symmetric-diff => xor) and allowed (intersect) payment methods
        return intersection(
            allowedPrepaymentMethodsV2,
            xor(
                paymentMethodsV2.map(({ paymentMethodType }) => paymentMethodType),
                Object.keys(PULL_PAYMENT_TYPES)
            )
        );
    }, [allowedPrepaymentMethodsV2, paymentMethodsV2, isAllowedToAddPaymentMethods, isEditPermitted, isFetching]);

    return { paymentMethodTypesToAdd };
};
