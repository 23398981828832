import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

export const ViewableImpressionsFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="campaign.reach.estimator.widget.tooltip.message.text"
            defaultMessage="An estimate of the number of viewable impressions your campaign can potentially reach, based on the settings and targeting you’ve selected. Please note the estimates do not take into account the following settings:{newline}{newline}<ul><li>Campaign scheduling</li><li>Targeting by postal code</li><li>Ad formats and placements</li><li>Budget & Bid</li></ul>{newline}{newline}Please note the estimates should not be interpreted as guaranteed results."
        />
    </TooltipSection>
);
