import React, { useCallback } from 'react';
import { SpeakerIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormDataContext, useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { NameCreate } from '../../../common-campaign-form';
import { VideoCreationTypeField } from '../../../common-campaign-form/components';
import { CampaignsField } from '../../../creative-creator/components';
import { creativeNameValidations } from '../../../video-campaigns-form/components/VideoCreativeSetupSection/validations';
import {
    VIDEO_CREATIVE_CREATION_METHOD_KEYS_ARRAY,
    VIDEO_CREATIVE_CREATION_METHOD,
} from '../../../video-creative-creator/config/CreationMethod';
import { VideoCreativeCreatorLandingPageField } from './LandingPageField/VideoCreativeCreatorLandingPageField';
import { VASTTagField } from './VASTTagField/VASTTagField';
import styles from '../../../creative-creator/creativeCreator.module.scss';

const contentMethodAditionalDataMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoCreativeCreatorLandingPageField,
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VAST]: VASTTagField,
};
export const VideoCreativeSetupSection = () => {
    const { formAccount: account } = useFormDataContext();
    const { value: creativeCreatorSelectedCampaigns } = useCampaignsFormFieldValue();
    const { value: videoCreationType, onChange: setVideoCreationType } = useFormFieldValue({
        field: 'mediaType',
    });
    const { onChange: setVideoUploadItems } = useFormFieldValue({
        field: 'videoUploadItems',
    });
    const onVideoCreationTypeChange = useCallback(
        creationType => {
            setVideoCreationType(creationType);
            setVideoUploadItems(null);
        },
        [setVideoCreationType, setVideoUploadItems]
    );

    const AdditionalDataComponent = contentMethodAditionalDataMap[videoCreationType];

    return (
        <CommonCollapsibleCard
            id="VIDEO_CREATIVE_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SpeakerIcon />}
                    headerText={<FormattedMessage id="video.creative.creator.setup" defaultMessage="Setup" />}
                />
            }
        >
            <CampaignsField
                className={styles['field']}
                accountForCampaign={account}
                selectedCampaigns={creativeCreatorSelectedCampaigns}
            />
            <NameCreate
                messageIdPrefix="video.creative.creator.setup"
                showTip={true}
                validations={creativeNameValidations}
            />

            <VideoCreationTypeField
                onChange={onVideoCreationTypeChange}
                selected={videoCreationType}
                options={VIDEO_CREATIVE_CREATION_METHOD_KEYS_ARRAY}
            />
            <AdditionalDataComponent />
        </CommonCollapsibleCard>
    );
};

export default VideoCreativeSetupSection;
