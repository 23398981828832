import { batch } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { unipFunnelApi } from '../../../services/api';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { deleteFunnelError, deleteFunnelSuccess, requestDeleteFunnel } from '../actions/deleteFunnel';

export const successIndication = {
    message: (
        <FormattedMessage id="tracking.unip.funnel.delete.success" defaultMessage="Funnel successfully deleted." />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="racking.unip.funnel.delete.error.highlight"
            defaultMessage="Unable to delete the funnel."
        />
    ),
});

export const deleteFunnelFlow = (accountName, id) => async dispatch => {
    dispatch(requestDeleteFunnel());
    try {
        const response = await unipFunnelApi.deleteUnipFunnel(accountName, id);
        batch(() => {
            dispatch(deleteFunnelSuccess(response));
            dispatch(addIndication(successIndication));
        });
    } catch (error) {
        batch(() => {
            dispatch(deleteFunnelError(error));
            dispatch(addIndication(generateErrorIndication(error)));
        });
    }
};
