import { useMemo } from 'react';
import { useAvailableItems } from 'hooks';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import contentMethodItems from '../config/contentMethodItems';
import { useFormatTypeFormFieldValueCreateMode } from './useFormatTypeFormFieldValueCreateMode';

export const useCreativeCreatorConfigPermissions = () => {
    const isContentDescriptionEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_FORM_DESCRIPTION_ENABLED]: 'true',
    });
    const isContentCtaEnabled = useConfigMatch({
        [FEATURE_FLAGS.CREATIVE_FORM_CTA_ENABLED]: 'true',
        [FEATURE_FLAGS.BACKSTAGE_ADVERTISER_ENABLE_CTA]: 'true',
    });
    const isAiVariationsReplacementEnabled = useConfigMatch({
        [FEATURE_FLAGS.AI_VARIATIONS_REPLACEMENT_ENABLED]: 'true',
    });
    const isContentDescriptionPermitted = usePermissions('CAMPAIGN_ITEM_DESCRIPTION');
    const isContentCTAPermitted = usePermissions('CREATIVE_CTA_EDIT');
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();
    const availableContentMethods = useAvailableItems(contentMethodItems, { formatType });
    const availableContentMethodIDs = useMemo(
        () => availableContentMethods.map(contentItem => contentItem.id),
        [availableContentMethods]
    );

    return {
        isAiVariationsReplacementEnabled,
        isContentDescriptionEnabled: isContentDescriptionEnabled && isContentDescriptionPermitted,
        isContentCtaEnabled: isContentCtaEnabled && isContentCTAPermitted,
        availableContentMethods: availableContentMethodIDs,
    };
};
