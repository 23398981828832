import { invert } from 'lodash';
import { DEMAND_TYPE } from '../../constants';

export const ENTITY = {
    CAMPAIGN: 'CAMPAIGN',
    CAMPAIGN_VIDEO: 'CAMPAIGN_VIDEO',
    CAMPAIGNS_GROUP: 'CAMPAIGNS_GROUP',
    CREATIVE: 'CREATIVE',
    CREATIVE_VIDEO: 'CREATIVE_VIDEO',
    CREATIVE_CAROUSEL: 'CREATIVE_CAROUSEL',
    THUMBNAIL: 'THUMBNAIL',
    RTB_OPEN_EXCHANGE: DEMAND_TYPE.RTB_OPEN_EXCHANGE,
    PMP_DEAL: DEMAND_TYPE.PMP_DEAL,
    VIDEO_PMP_DEAL: DEMAND_TYPE.VIDEO_PMP_DEAL,
    PERFORMANCE_RECOMMENDATION: 'PERFORMANCE_RECOMMENDATION',
    CONVERSION: 'CONVERSION',
    EXCEL_BULK: 'EXCEL_BULK',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.CAMPAIGN]: 'campaign',
    [ENTITY.CAMPAIGN_VIDEO]: 'campaign-video',
    [ENTITY.CAMPAIGNS_GROUP]: 'campaigns-group',
    [ENTITY.CREATIVE]: 'creative',
    [ENTITY.CREATIVE_VIDEO]: 'creative-video',
    [ENTITY.CREATIVE_CAROUSEL]: 'creative-carousel',
    [ENTITY.THUMBNAIL]: 'thumbnail',
    [ENTITY.RTB_OPEN_EXCHANGE]: 'open-exchange-campaign',
    [ENTITY.PMP_DEAL]: 'pmp-deal',
    [ENTITY.VIDEO_PMP_DEAL]: 'video-pmp-deal',
    [ENTITY.PERFORMANCE_RECOMMENDATION]: 'performance-recommendation',
    [ENTITY.CONVERSION]: 'conversion',
    [ENTITY.EXCEL_BULK]: 'excel-bulk',
};

export const ENTITY_MESSAGE_PREFIX = {
    [ENTITY.CAMPAIGN]: 'campaign',
    [ENTITY.PMP_DEAL]: 'pmpDeal',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);

export const getCampaignEntity = ({ demandType } = {}) => ENTITY[demandType] || ENTITY.CAMPAIGN;
