import React from 'react';
import PropTypes from 'prop-types';
import { Button, CloseIcon } from 'tuui';
import styles from './deleteButton.module.scss';

export const DeleteButton = ({ onClick, addDelimiter, ...rest }) => {
    return (
        <>
            {addDelimiter ? <div className={styles['button-left-border']} /> : null}
            <Button
                className={styles['button']}
                size={Button.size.sm}
                variant={Button.variant.text}
                iconBefore="close"
                onClick={onClick}
                tabIndex="-1"
                {...rest}
            >
                <CloseIcon height={12} width={12} />
            </Button>
        </>
    );
};

DeleteButton.propTypes = {
    onClick: PropTypes.func,
    addDelimiter: PropTypes.bool,
};
