import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { isEmpty, keyBy } from 'lodash';
import { useAudiencesApi } from '../../../../../../../../services/api';
import { AUDIENCE_TYPE } from '../../../../../../../audiences/config';
import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../../taboola-common-frontend-modules/account-configurations';
import TargetingTypes from '../../../../config/TargetingTypes';
import { isPredictiveAudience } from '../../utils/MyAudienceUtils';
import { filterActiveAudiences, filterExcludedAudiences, filterNonEmptyAudiences } from '../utils';

const EMPTY_ARR = [];

const transformDefaultExcludedAudiences = rawList =>
    isEmpty(rawList)
        ? []
        : rawList
              .filter(filterActiveAudiences)
              .filter(filterExcludedAudiences)
              .map(({ id }) => ({ id }));

export const useMyAudiencesApi = (accountId, countryTargetingType, countryTargetingValues, targetingType) => {
    const transformMyAudiences = useCallback(
        (audiences = [], ignoreStatus = false) =>
            audiences
                .filter(audience => ignoreStatus || filterActiveAudiences(audience))
                .map(audience => ({
                    ...audience,
                    value: audience.id,
                    label: audience.name,
                    audienceType: audience.type,
                    isDisabled: targetingType === TargetingTypes.EXCLUDE && isPredictiveAudience(audience.type),
                })),
        [targetingType]
    );
    const { getMyAudiences } = useAudiencesApi();
    const {
        data: listOfMyAudiencesApiResult = EMPTY_ARR,
        isSuccess: isSuccessMyAudiences,
        isLoading: isLoadingMyAudiences,
    } = useQuery(
        ['listOfMyAudiences', accountId, countryTargetingType, countryTargetingValues],
        () => getMyAudiences(accountId, countryTargetingType, countryTargetingValues),
        {
            select: data => data.results,
            staleTime: Infinity,
        }
    );
    const { data: listOfAllMyAudiencesApiResult, isSuccess: isSuccessAllMyAudiences } = useQuery(
        ['listOfAllMyAudiences', accountId],
        () => getMyAudiences(accountId, '', ''),
        {
            select: data => data.results,
            staleTime: Infinity,
        }
    );

    const listOfMyAudiences = useMemo(() => {
        if (isSuccessMyAudiences) {
            const listOfMyAudiencesApiResultFiltered = listOfMyAudiencesApiResult.filter(audience => {
                const audienceHasSize = !!audience?.audienceSize || !!audience?.similarityLevelToSize;
                const shouldIgnoreSize =
                    audience.type === AUDIENCE_TYPE.COMBINED_AUDIENCES ||
                    audience.type === AUDIENCE_TYPE.SEARCH_KEYWORDS ||
                    audience.type === AUDIENCE_TYPE.MAIL_DOMAINS;
                return audienceHasSize || shouldIgnoreSize;
            });
            return transformMyAudiences(listOfMyAudiencesApiResultFiltered);
        }
    }, [isSuccessMyAudiences, listOfMyAudiencesApiResult, transformMyAudiences]);

    const listOfAllMyAudiences = useMemo(() => {
        if (isSuccessAllMyAudiences) {
            return transformMyAudiences(listOfAllMyAudiencesApiResult, true);
        }
    }, [isSuccessAllMyAudiences, listOfAllMyAudiencesApiResult, transformMyAudiences]);

    const isDisableable = useConfigMatch({
        [FEATURE_FLAGS.MY_AUDIENCE_DROPDOWN_IS_DISABLEABLE]: 'true',
    });

    const defaultExcludedListOfMyAudience = useMemo(
        () => transformDefaultExcludedAudiences(listOfMyAudiences),
        [listOfMyAudiences]
    );
    const isDisabled = useMemo(
        () => isDisableable && isEmpty(listOfMyAudiences.filter(filterNonEmptyAudiences)),
        [isDisableable, listOfMyAudiences]
    );

    const mySegmentsByIdMap = useMemo(() => keyBy(listOfMyAudiences, 'id'), [listOfMyAudiences]);
    const allMySegmentsByIdMap = useMemo(() => keyBy(listOfAllMyAudiences, 'id'), [listOfAllMyAudiences]);

    return {
        listOfMyAudiences,
        isSuccessMyAudiences,
        listOfAllMyAudiences,
        isSuccessAllMyAudiences,
        mySegmentsByIdMap,
        defaultExcludedListOfMyAudience,
        isDisabled,
        isLoadingMyAudiences,
        mapOfAllMyAudiences: allMySegmentsByIdMap,
    };
};
