import { CREATE_AUDIENCE_ERROR, CREATE_AUDIENCE_SUCCESS, REQUEST_CREATE_AUDIENCE } from './actionTypes';

export const requestCreateAudience = () => ({ type: REQUEST_CREATE_AUDIENCE });

export const createAudienceSuccess = audience => ({
    type: CREATE_AUDIENCE_SUCCESS,
    payload: audience,
});

export const createAudienceError = error => ({ type: CREATE_AUDIENCE_ERROR, payload: error });
