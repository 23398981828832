import { useCallback, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { filter, keys, noop, uniqBy } from 'lodash';
import { searchModes } from '../../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiencesWithBundling/consts/searchModes';
import { useEmptyTaxonomiesGetter } from '../../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiencesWithBundling/hooks/useEmptyTaxonomiesGetter';
import {
    getAllNodePathsMap,
    useLoadingState,
} from '../../../../campaigns/modules/common-campaign-form/components/Tree';
import { useCombinedSegmentsWithBundlingFetcher } from './useCombinedSegmentsWithBundlingFetcher';

const getLoadingPathsMap = segments => getAllNodePathsMap(segments, () => 1);
const chargeType = 'CPC';
const getSearchMode = (search, isAdvancedSearch) => {
    if (!search) {
        return undefined;
    }

    return isAdvancedSearch ? searchModes.FULL : searchModes.BUNDLED;
};

export const useCombinedSegmentsWithBundlingApi = ({ search, unbundledNodes, selectedAccountId, isAdvancedSearch }) => {
    const taxonomyIds = useMemo(() => keys(unbundledNodes), [unbundledNodes]);
    const [segments, setSegments] = useState([]);
    const [fullSegments, setFullSegments] = useState([]);
    const [totalsPerTaxonomy, setTotals] = useState({});

    const { getEmptyTaxonomies } = useEmptyTaxonomiesGetter(segments, unbundledNodes);

    const { loadingPaths, startLoading, stopLoading } = useLoadingState(segments, getLoadingPathsMap);
    const { fetchSegments, fetchSegmentsByIds } = useCombinedSegmentsWithBundlingFetcher({
        search,
        taxonomies: taxonomyIds,
        selectedAccountId,
        chargeType,
        searchMode: getSearchMode(search, isAdvancedSearch),
    });

    const fetchSegmentsWithLoading = useCallback(
        async ({ replaceSegments, onDataLoad = noop }) => {
            const emptyTaxonomies = getEmptyTaxonomies();
            const isBundleLoad = emptyTaxonomies.length > 0;

            startLoading(emptyTaxonomies);
            const { results, transformedTotals } = await fetchSegments();

            const resultsWithSize = filter(
                results,
                segment => (!!segment.size && segment.isTargetable) || !segment.isTargetable
            );

            batch(() => {
                setFullSegments(prevResults => uniqBy([...prevResults, ...resultsWithSize], 'id'));
                setSegments(prevResults =>
                    replaceSegments ? resultsWithSize : uniqBy([...prevResults, ...resultsWithSize], 'id')
                );
                setTotals(prevTotals =>
                    replaceSegments ? transformedTotals : { ...prevTotals, ...transformedTotals }
                );
                onDataLoad(resultsWithSize, isBundleLoad);
                stopLoading(emptyTaxonomies);
            });

            return resultsWithSize;
        },
        [fetchSegments, startLoading, stopLoading, getEmptyTaxonomies]
    );

    const loadMore = useCallback(
        params =>
            fetchSegmentsWithLoading({
                loadingPaths,
                totalsPerTaxonomy,
                ...params,
            }),
        [fetchSegmentsWithLoading, totalsPerTaxonomy, loadingPaths]
    );

    const loadAll = useCallback(
        taxonomies =>
            loadMore({
                taxonomies,
                pageSize: 0,
            }),
        [loadMore]
    );

    const reload = useCallback(
        onDataLoad =>
            fetchSegmentsWithLoading({
                replaceSegments: true,
                onDataLoad,
            }),
        [fetchSegmentsWithLoading]
    );

    return {
        segments,
        fullSegments,
        totals: totalsPerTaxonomy,
        loadMore,
        loadAll,
        reload,
        loadByIds: fetchSegmentsByIds,
        loadingPaths,
    };
};
