import { useMemo } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { siteResourceFieldKeyName } from '../const';

const EMPTY_LIST = [];
const keyMapper = ({ [siteResourceFieldKeyName]: key }) => key;

export const useSelectedSites = () => {
    const { value: campaignIncludedSites = EMPTY_LIST } = useFormFieldValue({
        field: 'campaignTargeting.publisherTargeting.include',
    });
    const { value: campaignExcludedSites = EMPTY_LIST } = useFormFieldValue({
        field: 'campaignTargeting.publisherTargeting.exclude',
    });
    const { value: smartBidBlockedSites = EMPTY_LIST } = useFormFieldValue({
        field: 'campaignTargeting.autoPublisherTargeting.values',
    });
    const { value: accountBlockedSites = EMPTY_LIST } = useFormFieldValue({
        field: 'blockedSitesOnAccountLevel',
    });

    const allIncludedSites = useMemo(() => campaignIncludedSites.map(keyMapper), [campaignIncludedSites]);
    const allExcludedSites = useMemo(
        () => [...accountBlockedSites, ...smartBidBlockedSites, ...campaignExcludedSites].map(keyMapper),
        [accountBlockedSites, smartBidBlockedSites, campaignExcludedSites]
    );

    const allSelectedSites = useMemo(
        () => [...allIncludedSites, ...allExcludedSites],
        [allIncludedSites, allExcludedSites]
    );

    return {
        allIncludedSites,
        allExcludedSites,
        allSelectedSites,
    };
};
