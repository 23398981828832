import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useTrackingApi } from 'services/api';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { TRACKING_ROUTE } from '../config';
import { updateConversion } from '../flows/updateConversion';
import {
    conversionConditionsTransformerFromGW,
    conversionConditionsTransformerToGW,
} from '../transformers/conversionConditionsTransformer';

export const useConversionEditorState = () => {
    const { getConversion } = useTrackingApi();
    const { conversionId } = useMultiLayerPathParams();
    const dispatch = useDispatch();

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const formDataFetcher = useCallback(async () => {
        const conversion = await getConversion(accountId, conversionId);
        return conversionConditionsTransformerFromGW(conversion);
    }, [accountId, getConversion, conversionId]);

    const { submit } = useFormState({ formDataFetcher });

    const handleEditConversionSubmitWrapper = useCallback(() => {
        const updateConversionWrapper = conversion => {
            const { accountName } = conversion;
            const transformConversion = conversionConditionsTransformerToGW(conversion);
            return dispatch(updateConversion({ accountId: accountName, conversion: transformConversion }));
        };
        return submit(updateConversionWrapper, TRACKING_ROUTE);
    }, [submit, dispatch]);

    return { submit: handleEditConversionSubmitWrapper };
};
