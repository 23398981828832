import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../../../config';
import { reportRssMetadataSelector, selectedReportSelector } from '../../../../selectors';
import RssLink from './RssLink';
import styles from './rssLinks.module.scss';

const RssLinks = () => {
    const selectedReport = useSelector(selectedReportSelector);
    const reportRssMetaData = useSelector(reportRssMetadataSelector);

    if (selectedReport !== REPORT_TYPE.CREATIVE || !reportRssMetaData?.length) {
        return null;
    }

    return (
        <div className={styles['toolbar']} data-automation="RssLinks">
            <div className={styles['rss-links-container']}>
                <div className={styles['filters-label']}>
                    <FormattedMessage id="app.campaigns.reports.rss.toolbar.title" defaultMessage="Rss Links" />
                </div>
                {reportRssMetaData.map(rssData => (
                    <RssLink key={rssData.id} rssData={rssData} />
                ))}
            </div>
        </div>
    );
};

export default RssLinks;
