import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import emptyMediaLibraryImage from './emptyMediaLibrary.svg';
import initialAIGeneratedImage from './initialAIGeneratedImages.svg';
import initialRecommendationsImage from './initialRecommendations.svg';
import unknownRecommendationsImage from './unknownRecommendations.svg';
import styles from './emptyGallery.module.scss';

const classNameBuilder = classnames.bind(styles);

const defaultEmptyGalleryMessagesPrefix = 'creative.editor.recommended';

const MEDIA_LIBRARY_EMPTY = 'mediaLibraryEmpty';
const RECOMMENDED_IMAGES_INITIAL = 'recommendedInitial';
const RECOMMENDED_IMAGES_UNKNOWN = 'recommendedUnknown';
const AI_GENERATED_IMAGES_FROM_IMAGE_INITIAL = 'aiGeneratedFromImageInitial';
const FAVORITED_LIBRARY_EMPTY = 'favoritedLibraryEmpty';

export const emptyGalleryTypes = {
    RECOMMENDED_IMAGES_INITIAL,
    RECOMMENDED_IMAGES_UNKNOWN,
    MEDIA_LIBRARY_EMPTY,
    AI_GENERATED_IMAGES_FROM_IMAGE_INITIAL,
    FAVORITED_LIBRARY_EMPTY,
};

const emptyGalleryTypeDefs = {
    [RECOMMENDED_IMAGES_INITIAL]: {
        id: RECOMMENDED_IMAGES_INITIAL,
        image: initialRecommendationsImage,
        headerMsg: 'Looking for a great ad?',
        bodyMsg: "Search the world's best photos and images",
    },
    [RECOMMENDED_IMAGES_UNKNOWN]: {
        id: RECOMMENDED_IMAGES_UNKNOWN,
        image: unknownRecommendationsImage,
        headerMsg: 'Oops, we could not find results {br} for the above keywords',
        bodyMsg: 'Try using fewer keywords or checking for any spelling mistakes',
    },
    [MEDIA_LIBRARY_EMPTY]: {
        id: MEDIA_LIBRARY_EMPTY,
        image: emptyMediaLibraryImage,
        headerMsg: 'No Images to Show',
        bodyMsg: 'Upload a new image or select from our stock images',
    },
    [AI_GENERATED_IMAGES_FROM_IMAGE_INITIAL]: {
        id: AI_GENERATED_IMAGES_FROM_IMAGE_INITIAL,
        image: initialAIGeneratedImage,
        headerMsg: 'Looking for a unique image?',
        bodyMsg: 'Upload a file or add a web URL to generate a unique image',
    },
    [FAVORITED_LIBRARY_EMPTY]: {
        id: FAVORITED_LIBRARY_EMPTY,
        image: emptyMediaLibraryImage,
        headerMsg: 'No favorited images',
        bodyMsg: 'Images favorited from the Generate with AI tab will be displayed here',
    },
};

const EmptyGallery = ({ messagesPrefix, type, className }) => {
    const { id, image, headerMsg, bodyMsg } = emptyGalleryTypeDefs[type];

    const header = <FormattedMessage id={`${messagesPrefix}.empty.${id}.header`} defaultMessage={headerMsg} />;
    const body = <FormattedMessage id={`${messagesPrefix}.empty.${id}.body`} defaultMessage={bodyMsg} />;

    return (
        <div className={classNameBuilder('container', className)}>
            <img src={image} alt="no results" />
            <div className={styles['header']}>{header}</div>
            <div className={styles['body']}>{body}</div>
        </div>
    );
};

EmptyGallery.propTypes = {
    messagesPrefix: PropTypes.string,
    type: PropTypes.oneOf(Object.values(emptyGalleryTypes)),
    className: PropTypes.string,
};

EmptyGallery.defaultProps = {
    type: RECOMMENDED_IMAGES_INITIAL,
    messagesPrefix: defaultEmptyGalleryMessagesPrefix,
};

export default EmptyGallery;
