import { stubFalse } from 'lodash';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { TITLE, ID, CVR, AVERAGE_CPC, CONVERSIONS } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graphConfigs } from './graph';
import { performanceColumns, customColumns, defaultColumns, setupColumns } from './reportPresets';

export const creativeRealtimeReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CREATIVE_REALTIME],
    endpoint: 'top-campaign-realtime-report-by-creative',
    graph: graphConfigs,
    columnsDef: columnDefinitions,
    rowIdField: ID.field,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    rowHeight: 62,
    comfortableRowHeight: 112,
    searchPlaceholderMsgId: 'app.reports.byCreative.search.placeholder',
    searchField: TITLE.field,
    inlineEditPermissions: ['CAMPAIGN_EDIT'],
    sortMap: {
        [CVR.field]: 'cpaConversionRate',
        [AVERAGE_CPC.field]: 'cpc',
        [CONVERSIONS.field]: 'cpaActionsNum',
        [ID.field]: 'item_id',
    },
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.PLATFORM,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.COUNTRY,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.SITE,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.ITEMS,
            multiSelect: true,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
            // This is made to hide temporary this filter
            visibilityCondition: stubFalse,
        },
    ],
};

export default creativeRealtimeReportConfig;
