import { useState, useMemo, useCallback } from 'react';
import { map, reject } from 'lodash';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useCampaignGroupApi } from 'services/api';
import { ACCEPTED_CAMPAIGN_GROUPS_STATUSES } from '../../../../../hooks/useDictionaryData';

export const useCampaignGroupsList = selectedCampaignGroups => {
    const [groups, setGroups] = useState([]);
    const { accountName } = useAccount();

    const { getCampaignGroupList } = useCampaignGroupApi();

    const selectedGroupIdsSet = useMemo(() => {
        const selectedGroupIdsArray = map(selectedCampaignGroups, 'id');
        return new Set(selectedGroupIdsArray);
    }, [selectedCampaignGroups]);

    const loadPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            const {
                results,
                metadata: { total },
            } = await getCampaignGroupList(accountName, {
                page,
                pageSize,
                searchText,
                statuses: ACCEPTED_CAMPAIGN_GROUPS_STATUSES,
            });
            const filteredResults = reject(results, ({ id }) => selectedGroupIdsSet.has(id));
            const list = map(filteredResults, ({ id, name, status }) => ({ id, value: id, label: name, status }));

            return [list, total, results.length - filteredResults.length];
        },
        [accountName, getCampaignGroupList, selectedGroupIdsSet]
    );

    const handleSetGroups = useCallback(rawGroups => setGroups(rawGroups.map(option => option || {})), [setGroups]);

    return { groups, setGroups: handleSetGroups, loadPage };
};
