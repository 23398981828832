import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import { ConversionEditorHeader } from '../components/ConversionEditorHeader/ConversionEditorHeader';
import { ConversionPropertiesSection } from '../components/ConversionPropertiesSection/ConversionPropertiesSection';
import { ConversionSetupSection } from '../components/ConversionSetupSection/ConversionSetupSection';
import { EventCodeSection } from '../components/EventCodeSection/EventCodeSection';
import { CONVERSION_EDITOR_ROUTE_PATH, CONVERSION_TYPE } from '../config';
import { useConversionEditorState } from '../hooks/useConversionEditorState';
import styles from './conversionEditorPage.module.scss';

const ConversionEditorPage = ({ onCancel }) => {
    useUnsavedChangesController(CONVERSION_EDITOR_ROUTE_PATH);
    const { submit } = useConversionEditorState();
    const { isSubmitDisabled, submitInProgress, mode, fetchStatus } = useFormDataContext();
    const { value: type } = useFormFieldValue({
        field: 'type',
    });
    const { value: eventName } = useFormFieldValue({
        field: 'eventName',
    });
    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<ConversionEditorHeader status={fetchStatus} headerClassName={styles['conversion-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['conversion-editor']}
                bodyContainerClassName={styles['conversion-editor-body']}
                footerContainerClassName={styles['conversion-editor-footer']}
                data-automation="TrackingSettings"
            >
                <div className={styles['content']}>
                    <ConversionSetupSection />
                    <ConversionPropertiesSection type={type} />
                    {type === CONVERSION_TYPE.EVENT_BASED && <EventCodeSection eventName={eventName} />}
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const ConversionEditorWithFormDataProvider = withFormDataProvider(ConversionEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { ConversionEditorWithFormDataProvider as ConversionEditorPage };
