import React, { useCallback, useMemo } from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { FormButtons } from 'modules/campaigns/components';
import { REPORT_TYPE } from 'modules/campaigns/config';
import useCampaignBreadcrumbs from 'modules/campaigns/hooks/useCampaignBreadcrumbs';
import { FormBreadcrumbs } from 'modules/campaigns/modules/common-campaign-form';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { useAPISupplyTargetingRestrictions } from '../common-campaign-form/components/SupplyTargeting/hooks/useAPISupplyTargetingRestrictions';
import { BulkUploadResultSection } from './components/BulkUploadResultSection/BulkUploadResultSection';
import { DownloadSection } from './components/DownloadSection/DownloadSection';
import { ExcelBulkLoadingOverlay } from './components/ExcelBulkLoadingOverlay/ExcelBulkLoadingOverlay';
import { BulkUploadPreview } from './components/UploadSection/BulkUploadPreview/BulkUploadPreview';
import { UploadSection } from './components/UploadSection/UploadSection';
import { BULK_UPLOAD_GTM_PREFIX } from './consts/gtmConsts';
import { useExcelBulkFormState } from './hooks/useExcelBulkFormState';
import styles from './excelBulkForm.module.scss';

const UPLOAD_STEP_ID = 1;
const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

export const ExcelBulkFormBase = ({ onCancel }) => {
    const { supplyTargetingResource } = useAPISupplyTargetingRestrictions();
    const conditionData = useMemo(() => ({ supplyTargetingResource }), [supplyTargetingResource]);
    const {
        formAccount: { country: accountCountry },
        resetFormToInitial,
        setSubmitStatus,
    } = useFormDataContext();
    const {
        uploadExcelBulkFile,
        isSubmitDisabled,
        downloadReport,
        responseSummary,
        loadingState,
        step,
        setStep,
        nextStep,
        prevStep,
    } = useExcelBulkFormState({ accountCountry, conditionData });

    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'excel.bulk.new.entities', defaultMessage: 'Bulk Operations' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN,
        title,
    });

    const onUploadSubmit = () => {
        uploadExcelBulkFile().then(nextStep);
    };

    const onUploadMore = useCallback(() => {
        resetFormToInitial();
        setSubmitStatus(COMPONENT_STATUS.INITIAL);
        setStep(UPLOAD_STEP_ID);
    }, [resetFormToInitial, setStep, setSubmitStatus]);

    return (
        <FormInitBase loadingOverlay={ExcelBulkLoadingOverlay} loadingOverlayProps={{ ...loadingState }}>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                wizardTemplateColumns={wizardTemplateColumns}
                wizardNavigationClassName={styles['wizard-navigation']}
            >
                <WizardStep
                    id="download-section"
                    label={<FormattedMessage id="excel.bulk.wizard.step.download" defaultMessage="Download" />}
                    wrapperClassName={styles['wizard-step']}
                    className={styles['wizard-step']}
                >
                    <div className={styles['section-container']}>
                        <StaticIndications />
                        <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                        <div className={styles['form-container']}>
                            <DownloadSection conditionData={conditionData} />
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" defaultMessage="Next" />}
                                submitDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Download Step - Next Clicked`,
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                                cancelDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Download Step - Cancel Clicked`,
                                }}
                                submitButtonType="submit"
                                disableSubmit={isSubmitDisabled}
                            />
                        </div>
                    </div>
                </WizardStep>
                <WizardStep
                    id="upload-section"
                    label={<FormattedMessage id="excel.bulk.wizard.step.upload" defaultMessage="Upload" />}
                    wrapperClassName={styles['wizard-step']}
                    className={styles['wizard-step']}
                >
                    <div className={styles['section-container']}>
                        <StaticIndications />
                        <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                        <div className={styles['form-container']}>
                            <UploadSection />
                            <FormButtons
                                onSubmit={nextStep}
                                onBack={prevStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" defaultMessage="Next" />}
                                submitDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Upload Step - Next Clicked`,
                                }}
                                backLabel={<FormattedMessage id="app.actionButtons.back" defaultMessage="Back" />}
                                backDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Upload Step - Back Clicked`,
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                                cancelDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Upload Step - Cancel Clicked`,
                                }}
                                submitButtonType="submit"
                                disableSubmit={isSubmitDisabled}
                            />
                        </div>
                    </div>
                </WizardStep>
                <WizardStep
                    id="preview-section"
                    label={<FormattedMessage id="excel.bulk.wizard.step.preview" defaultMessage="Preview" />}
                    wrapperClassName={styles['wizard-step']}
                    className={styles['wizard-step']}
                >
                    <div className={styles['section-container']}>
                        <StaticIndications />
                        <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                        <div className={styles['form-container']}>
                            <BulkUploadPreview />
                            <FormButtons
                                onSubmit={onUploadSubmit}
                                onBack={prevStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.submit" defaultMessage="Submit" />}
                                submitDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Submit Clicked`,
                                }}
                                backLabel={<FormattedMessage id="app.actionButtons.back" defaultMessage="Back" />}
                                backDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Preview Step - Back Clicked`,
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                                cancelDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Preview Step - Cancel Clicked`,
                                }}
                                submitButtonType="submit"
                                disableSubmit={isSubmitDisabled}
                            />
                        </div>
                    </div>
                </WizardStep>
                <WizardStep
                    id="status-section"
                    label={<FormattedMessage id="excel.bulk.wizard.step.status" defaultMessage="Status" />}
                    wrapperClassName={styles['wizard-step']}
                    className={styles['wizard-step']}
                >
                    <div className={styles['section-container']}>
                        <StaticIndications />
                        <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                        <div className={styles['form-container']}>
                            <BulkUploadResultSection
                                responseSummary={responseSummary}
                                onReportDownloadClick={downloadReport}
                            />
                            <FormButtons
                                onSubmit={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.close" defaultMessage="Close" />}
                                submitDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Close Clicked`,
                                }}
                                onCancel={onUploadMore}
                                cancelLabel={
                                    <FormattedMessage id="excel.bulk.report.prev.step" defaultMessage="Upload More" />
                                }
                                cancelDataMetricAttrs={{
                                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                                    'data-metrics-component': `${BULK_UPLOAD_GTM_PREFIX}: Upload More Clicked`,
                                }}
                            />
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ExcelBulkFormWithFormDataProvider = withFormDataProvider(ExcelBulkFormBase, {
    isNetworkOwnerAllowed: true,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { ExcelBulkFormWithFormDataProvider as ExcelBulkForm };
