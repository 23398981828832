import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TRACKING_ROUTE } from 'modules/tracking/config';
import { useUnipFunnelApi } from 'services/api/unipFunnelAPI';
import { errorMessagesUtils } from 'services/utils';
import { REPORT_TYPE } from '../../campaigns/config';
import { REPORT_ID } from '../../campaigns/modules/campaigns-reports/hooks';
import { funnelTransformerToGW } from '../transformers/funnelsGWTransformer';

const successIndication = {
    message: <FormattedMessage id="unip.funnel.create.success" defaultMessage="Funnel successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="unip.funnel.create.error.highlight" defaultMessage="Unable to create funnel." />,
});

export const useUnipFunnelFormState = () => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const dispatch = useDispatch();

    const { submit, step, setStep } = useFormState();

    const { createUnipFunnel } = useUnipFunnelApi();

    const submitUnipFunnel = useCallback(
        async funnel => {
            try {
                const transformedFunnel = funnelTransformerToGW(funnel);
                await createUnipFunnel(accountId, transformedFunnel);
                dispatch(addIndication(successIndication));
                return true;
            } catch (error) {
                dispatch(addIndication(generateErrorIndication(error)));
                throw error;
            }
        },
        [createUnipFunnel, accountId, dispatch]
    );

    const submitWrapper = useCallback(() => {
        const submitUnipFunnelWrapper = funnel => {
            return submitUnipFunnel(funnel);
        };
        submit(submitUnipFunnelWrapper, `${TRACKING_ROUTE}?${REPORT_ID}=${REPORT_TYPE.FUNNELS}`);
    }, [submit, submitUnipFunnel]);

    return { submit: submitWrapper, step, setStep };
};
