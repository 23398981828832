import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { gridNumberFormatter } from '../../../components';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const categoryReportColumnDefinition = [
    {
        headerName: FIELDS.CATEGORY.label,
        field: FIELDS.CATEGORY.field,
        type: [leftPinnedColumn],
        headerValueGetter: FIELDS.CATEGORY.headerValueGetter,
        headerComponentParams: FIELDS.CATEGORY.headerComponentParams,
        cellRendererFramework: FIELDS.CATEGORY.cellRendererFramework,
        cellRendererParams: FIELDS.CATEGORY.renderParams,
        csvValueGetter: FIELDS.CATEGORY.csvValueGetter,
        minWidth: 200,
        width: 600,
        suppressSizeToFit: true,
    },
    {
        headerName: FIELDS.AUDIENCE_INDEX.label,
        field: FIELDS.AUDIENCE_INDEX.field,
        type: [numericColumn],
        headerValueGetter: FIELDS.AUDIENCE_INDEX.headerValueGetter,
        headerComponentParams: FIELDS.AUDIENCE_INDEX.headerComponentParams,
        valueFormatter: gridNumberFormatter,
        cellRendererParams: FIELDS.AUDIENCE_INDEX.renderParams,
    },
    {
        headerName: FIELDS.BASELINE_INDEX.label,
        field: FIELDS.BASELINE_INDEX.field,
        type: [numericColumn],
        headerValueGetter: FIELDS.BASELINE_INDEX.headerValueGetter,
        headerComponentParams: FIELDS.BASELINE_INDEX.headerComponentParams,
        valueFormatter: gridNumberFormatter,
        cellRendererParams: FIELDS.BASELINE_INDEX.renderParams,
    },
    {
        headerName: FIELDS.COMPARE.label,
        field: FIELDS.COMPARE.field,
        type: [numericColumn],
        headerValueGetter: FIELDS.COMPARE.headerValueGetter,
        headerComponentParams: FIELDS.COMPARE.headerComponentParams,
        cellRendererFramework: FIELDS.COMPARE.cellRendererFramework,
        cellRendererParams: FIELDS.COMPARE.renderParams,
    },
];

export default normalizeColumnDefs(categoryReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.CATEGORY].id);
