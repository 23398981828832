import React, { useState, useCallback } from 'react';
import { BudgetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { getEmptyBudgetObject } from 'modules/campaigns/modules/common-campaign-form/components/Budget/const/emptyBudgetObject';
import { useBudgetFormFieldValue } from 'modules/campaigns/modules/common-campaign-form/components/Budget/hooks/useBudgetFormFieldValue';
import { useNoneSpendingLimitEnabled } from 'modules/campaigns/modules/common-campaign-form/components/Budget/hooks/useNoneSpendingLimitEnabled';
import { BUDGET_TYPES } from 'modules/campaigns/modules/common-campaign-form/config/budgetTypes';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../../../components/Form/SectionHeader/SectionHeader';
import { Budget } from '../../../../../common-campaign-form/components/Budget/Budget';

export const BudgetSection = () => {
    const [selected, setSelected] = useState(false);
    const { value: budget, onChange: onBudgetChange } = useBudgetFormFieldValue();
    const isNoneSpendingLimitEnabled = useNoneSpendingLimitEnabled();

    const onSelect = useCallback(() => {
        const isFirstTimeSelected = !budget && !selected;
        if (isFirstTimeSelected) {
            onBudgetChange(prev =>
                getEmptyBudgetObject({ prev, type: BUDGET_TYPES.DAILY, isNoneSpendingLimitEnabled })
            );
        }
        setSelected(prev => !prev);
    }, [budget, isNoneSpendingLimitEnabled, onBudgetChange, selected]);

    return (
        <CommonCollapsibleCard
            id="CAMPAIGNS_GROUP_BUDGET"
            header={
                <SectionHeader
                    headerIcon={<BudgetIcon />}
                    headerText={
                        <FormattedMessage id="campaign.editor.budget.and.bidding" defaultMessage="Budget and Bidding" />
                    }
                />
            }
        >
            <Budget
                entityTypeMessagePrefix="campaigns.group"
                formFieldParams={{ selected, onSelect }}
                disabled={!selected}
            />
        </CommonCollapsibleCard>
    );
};
