import { useCallback } from 'react';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { fetchPageMetadata as fetchPageMetadataFlow } from '../../flows';

export const useFetchPageMetadata = (addContentItem, updateContent) => {
    const { formAccount } = useFormDataContext();
    const { onChange: setThumbnails } = useFormFieldValue({ field: 'thumbnails' });

    const fetchPageMetadata = useCallback(
        async (urlFormId, url) => {
            const contentId = addContentItem({ title: '', status: COMPONENT_STATUS.LOADING });

            const response = await fetchPageMetadataFlow({
                accountId: formAccount.accountName,
                url,
                setThumbnails,
                contentId,
            });

            if (response) {
                updateContent({
                    id: contentId,
                    title: response.title,
                    status: COMPONENT_STATUS.ACTIVE,
                });
                return;
            }
            updateContent({ id: contentId, status: COMPONENT_STATUS.ERROR });
        },
        [addContentItem, updateContent, setThumbnails, formAccount.accountName]
    );

    return fetchPageMetadata;
};
