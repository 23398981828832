import React from 'react';
import { useEntityType } from 'hooks';
import { PATH_TO_ENTITY_MAP, ENTITY } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BidInput } from '../BidInput';
import styles from './cpmBidInput.module.scss';

export const CpmBidInput = props => {
    const entity = useEntityType(PATH_TO_ENTITY_MAP);
    const isPmpEntity = entity === ENTITY.PMP_DEAL;

    return (
        <>
            <BidInput {...props} />
            {isPmpEntity && props.disabled && (
                <div className={styles['footer']}>
                    <FormattedMessage
                        id="campaign.editor.bid.highImpactException"
                        defaultMessage="When choosing a High Impact Package the pricing model must be vCPM."
                    />
                </div>
            )}
        </>
    );
};

export default CpmBidInput;
