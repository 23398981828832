import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import {
    gridNumberFormatter,
    formattedNumberValueGetter,
    csvDateHeaderValueGetter,
} from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, keyColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const hourOfDayReportColumnDefinition = [
    {
        headerName: FIELDS.HOUR_OF_DAY.label,
        headerValueGetter: FIELDS.HOUR_OF_DAY.headerValueGetter,
        headerComponentParams: FIELDS.HOUR_OF_DAY.headerComponentParams,
        field: FIELDS.HOUR_OF_DAY.field,
        type: [leftPinnedColumn, keyColumn],
        cellRendererFramework: FIELDS.HOUR_OF_DAY.cellRenderer,
        maxWidth: 180,
        csvValueGetter: FIELDS.HOUR_OF_DAY.csvValueGetter,
        csvHeaderValueGetter: csvDateHeaderValueGetter,
    },
    ...[
        FIELDS.IMPRESSIONS,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.VCPM,
        FIELDS.CPM,
        FIELDS.VCTR,
        FIELDS.CTR,
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(hourOfDayReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.HOUR_OF_DAY].id);
