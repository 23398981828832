import { stubFalse } from 'lodash';
import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { deleteCreative } from '../../../../../flows';
import {
    CreativeActionsCellRenderer,
    EllipsisCellRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    StateCellRenderer,
    withCreativeEditIndication,
} from '../../../components';
import { editCreative, saveCreative } from '../../../flows';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import {
    actionsColumnType,
    editableColumn,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from '../../columns';
import * as FIELDS from '../../fields';
import { isVideoCreativeEditable } from '../../predicates';

const creativeReportColumnDefinition = [
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
        cellRendererParams: {
            ...FIELDS.IS_ACTIVE.renderParams,
            isDisabled: stubFalse,
            reportId: REPORT_TYPE.CREATIVE_VIDEO,
        },
        valueChangeHandler: saveCreative,
        cellRendererFramework: StateCellRenderer,
        csv: false,
        permissions: {
            permissions: ['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'],
        },
    },
    {
        headerName: FIELDS.CREATIVE_NAME.label,
        field: FIELDS.CREATIVE_NAME.field,
        type: [leftPinnedColumn, editableColumn],
        editable: isVideoCreativeEditable,
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        cellEditorFramework: FIELDS.CREATIVE_NAME.cellEditorFramework,
        valueChangeHandler: saveCreative,
    },
    {
        headerName: FIELDS.CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGN_NAME.cellRenderer,
    },
    {
        headerName: FIELDS.VIDEO_DURATION.label,
        headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: FIELDS.VIDEO_DURATION.headerComponentParams,
        field: FIELDS.VIDEO_DURATION.field,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        cellRendererParams: {
            onEdit: editCreative,
            onDelete: deleteCreative,
        },
        cellRendererFramework: CreativeActionsCellRenderer,
        csv: false,
        permissions: {
            permissions: ['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'],
        },
    },
    ...[
        FIELDS.SPENT,
        FIELDS.COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        FIELDS.FIRST_QUARTILE,
        FIELDS.MID_POINT,
        FIELDS.THIRD_QUARTILE,
        FIELDS.COMPLETED_VIDEO,
        FIELDS.THIRTY_SECONDS_MARK,
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CPA,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    creativeReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CREATIVE_VIDEO].id,
    withCreativeEditIndication
);
