import React from 'react';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { FormButtons } from 'modules/campaigns/components';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import DoneAndCreateConversionButton from 'modules/tracking/components/DoneAndCreateConversionButton/DoneAndCreateConversionButton';
import { PixelCode } from 'modules/tracking/components/PixelCode';
import { useTrackingSetupFormState } from 'modules/tracking/hooks/useTrackingSetupFormState';
import { GetHelpFromExpertSection } from '../../components/GetHelpFromExpert/GetHelpFromExpertSection';
import { PasteCodeToWebsite } from '../../components/PasteCodeToWebsite/PasteCodeToWebsite';
import { TestPixel } from '../../components/TestPixel/TestPixel';
import { TrackingFormBreadcrumbs } from '../../components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import styles from './manuallySetupPage.module.scss';

const ManuallySetupPage = ({ onCancel }) => {
    const { step, handleSetStep, nextStep, prevStep, submit, wizardTemplateColumns } = useTrackingSetupFormState();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage id="tracking.setup.manually.first.step.label" defaultMessage="Add the code" />
                    }
                    id="add-the-code"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.setup.manually.install"
                        defaultMessage="Manually Install"
                    />
                    <div className={styles['form-with-widget-wrapper']}>
                        <div className={styles['form-wrapper']}>
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="tracking.setup.manually.copy.the.code"
                                        defaultMessage="Copy the code"
                                    />
                                }
                                id="copy-the-code"
                            >
                                <GetHelpFromExpertSection
                                    headerMessageId="tracking.setup.install.pixel"
                                    headerDefaultMessage="Install Pixel"
                                    descriptionMessageId="tracking.setup.install.pixel.description"
                                    descriptionDefaultMessage="The pixel code is a snippet that when added to a webpage, allows tracking activity and analyzing performance.
                        Follow the quick guide below to complete the installation."
                                />
                                <PixelCode />
                            </WizardStep>
                            <WizardStep
                                label={
                                    <FormattedMessage
                                        id="tracking.setup.manually.paste.to.website"
                                        defaultMessage="Paste to website"
                                    />
                                }
                                id="paste-to-website"
                            >
                                <PasteCodeToWebsite />
                            </WizardStep>
                            <FormButtons
                                onSubmit={nextStep}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                                submitIcon={<ChevronRightOutlinedIcon />}
                                submitDataMetricAttrs={{
                                    'data-metrics-component': 'ClickNext',
                                }}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                submitButtonType="submit"
                                className={styles['form-buttons']}
                            />
                        </div>
                    </div>
                </WizardStep>
                <WizardStep
                    label={<FormattedMessage id="tracking.setup.manually.second.step.label" defaultMessage="Test" />}
                    id="test"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.setup.manually.install"
                        defaultMessage="Manually Install"
                    />
                    <div className={styles['form-wrapper']}>
                        <TestPixel />
                        <DoneAndCreateConversionButton
                            onSubmit={onCancel}
                            onSubmitAndCreateConversion={submit}
                            onBack={prevStep}
                            disableSubmit={false}
                            hasBack
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ManuallySetupPageWithFormDataProvider = withFormDataProvider(ManuallySetupPage, {
    defaultValuesConfig: {},
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});
export { ManuallySetupPageWithFormDataProvider as ManuallySetupPage };
