import { FormField } from 'taboola-ultimate-ui';
import { ExcludeDaysField } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/ExcludeDaysField';
import { ExcludeDaysToolTip } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/ExcludeDaysToolTip';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const PerformanceRuleExcludeDays = ({ daysToExclude, daysToExcludeChange, failedValidationData }) => {
    return (
        <>
            <FormField
                inputId="frequency"
                label={
                    <FormattedMessage
                        id="app.create.performance.rules.advanced.settings.exclude.days.from.time.range"
                        defaultMessage="Exclude days from time range"
                    />
                }
                helpText={<ExcludeDaysToolTip />}
                description={
                    <FormattedMessage
                        id="app.create.performance.rules.advanced.settings.exclude.days.from.time.range.description"
                        defaultMessage="Exclude days from the time range you selected for each condition"
                    />
                }
            />
            <ExcludeDaysField
                daysToExclude={daysToExclude}
                daysToExcludeChange={daysToExcludeChange}
                failedValidationData={failedValidationData}
            />
        </>
    );
};
