import { AdvancedPreview } from '../../../creative-preview/components/OnSitePreviewModal/components/AdvancedPreview/AdvancedPreview';
import { useAdvancedPreviewEditCarouselParameters } from '../../hooks/useAdvancedPreviewEditCarouselParameters';
import styles from './CarouselEditorAdvancedPreview.module.scss';

export const CarouselEditorAdvancedPreview = () => {
    useAdvancedPreviewEditCarouselParameters();

    return (
        <div className={styles['advancedPreview']}>
            <AdvancedPreview />
        </div>
    );
};
