import { set, get, cloneDeep } from 'lodash';
import { getLocationSearch } from 'modules/taboola-common-frontend-modules/query-params';
import { createReducer } from 'modules/taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { COMPONENT_STATUS } from 'services/constants';
import { addPersistHandler } from 'services/persister';
import {
    FETCH_ACCOUNTS_SUCCESS,
    RESET_ACCOUNTS,
    FETCH_ACCOUNTS_ERROR,
    RETRY_FETCH_ACCOUNTS,
    SET_HAS_UNSAVED_CHANGES,
    SET_PAGE_NAME,
    FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS,
    RESET_ACCOUNTS_UNDER_NETWORK,
    NAVIGATE,
    REPLACE_NAVIGATION_STATE,
    WINDOW_NAVIGATE,
    SET_APPLICATION_STATUS,
    SET_CURRENT_ROOT_ACCOUNT,
    SET_FORM_SECTION_STATE_BY_SECTION,
    SET_LOCATION_SEARCH,
} from '../actions';

const initialState = {
    accounts: [],
    accountsTotal: -1,
    accountsUnderNetwork: [],
    accountsUnderNetworkTotal: -1,
    currentRootAccountId: undefined,
    fetchAccountsStatus: COMPONENT_STATUS.INITIAL,
    status: COMPONENT_STATUS.INITIAL,
    formSectionState: {},
    hasUnsavedChanges: false,
    pageName: null,
    locationSearch: getLocationSearch(),
};

const propsToPersist = ['formSectionState'];

const REDUCER_NAME = 'appReducer';

const handleFetchAccounts = (state, { payload: { accounts, accountsTotal } }) =>
    updateObject(state, {
        // TODO: remove account object after DEV-67250
        accounts: [...state.accounts, ...accounts.map(account => ({ accountId: account.accountId }))],
        accountsTotal,
        fetchAccountsStatus: COMPONENT_STATUS.ACTIVE,
    });

const handleResetAccounts = state =>
    updateObject(state, {
        accounts: [],
        accountsTotal: -1,
        fetchAccountsStatus: COMPONENT_STATUS.LOADING,
    });

const handleFetchAccountsUnderNetwork = (state, { payload: { accountsUnderNetwork, accountsUnderNetworkTotal } }) =>
    updateObject(state, {
        accountsUnderNetwork: [...state.accountsUnderNetwork, ...accountsUnderNetwork],
        accountsUnderNetworkTotal,
        fetchAccountsStatus: COMPONENT_STATUS.ACTIVE,
    });

const handleResetAccountsUnderNetwork = state =>
    updateObject(state, {
        accountsUnderNetwork: [],
        accountsUnderNetworkTotal: -1,
        fetchAccountsStatus: COMPONENT_STATUS.LOADING,
    });

const setFormSectionStateBySection = (state, { payload: { state: newState, section, module, entity } }) => {
    const deepCopy = cloneDeep(state.formSectionState);

    // merge current state and new state
    const curSectionState = get(deepCopy, [module, entity, section], {});
    const finalSectionState = updateObject(curSectionState, newState);

    // insert new combined state into deep copy
    set(deepCopy, [module, entity, section], finalSectionState);

    return updateObject(state, { formSectionState: deepCopy });
};

// The main idea to have navigationTimeStamp in store to be able to know point when navigation ends to track it
// and set pageName dynamically base on URL string
const updateNavigationTimeStamp = state => updateObject(state, { navigationTimeStamp: Date.now() });

const handlers = {
    [SET_CURRENT_ROOT_ACCOUNT]: (state, { payload: currentRootAccountId }) =>
        updateObject(state, { currentRootAccountId }),
    [FETCH_ACCOUNTS_SUCCESS]: handleFetchAccounts,
    [FETCH_ACCOUNTS_ERROR]: state => updateObject(state, { fetchAccountsStatus: COMPONENT_STATUS.ERROR }),
    [RESET_ACCOUNTS]: handleResetAccounts,
    [RETRY_FETCH_ACCOUNTS]: state => updateObject(state, { fetchAccountsStatus: COMPONENT_STATUS.LOADING }),
    [SET_HAS_UNSAVED_CHANGES]: (state, { payload }) => updateObject(state, { hasUnsavedChanges: payload }),
    [SET_FORM_SECTION_STATE_BY_SECTION]: setFormSectionStateBySection,
    [SET_PAGE_NAME]: (state, { payload }) => updateObject(state, { pageName: payload }),
    [FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS]: handleFetchAccountsUnderNetwork,
    [RESET_ACCOUNTS_UNDER_NETWORK]: handleResetAccountsUnderNetwork,
    [NAVIGATE]: updateNavigationTimeStamp,
    [REPLACE_NAVIGATION_STATE]: updateNavigationTimeStamp,
    [WINDOW_NAVIGATE]: updateNavigationTimeStamp,
    [SET_APPLICATION_STATUS]: (state, { payload: { status } }) => updateObject(state, { status }),
    [SET_LOCATION_SEARCH]: (state, { payload: locationSearch }) => updateObject(state, { locationSearch }),
};

const AppReducer = createReducer(initialState, addPersistHandler(handlers, REDUCER_NAME));

export default AppReducer;

export { propsToPersist, REDUCER_NAME };
