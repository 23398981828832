import React, { Component } from 'react';
import { noop, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import styles from './actionableTextCellRenderer.module.scss';

class ActionableTextCellRenderer extends Component {
    onClick = () => {
        const {
            clickAction,
            data,
            context: { dispatch = noop },
        } = this.props;

        if (isFunction(clickAction)) {
            dispatch(clickAction(data));
        }
    };

    render() {
        const { value, children, ...rest } = this.props;
        const { column } = rest || {};
        const { colDef } = column || {};
        const { field, headerName } = colDef || {};

        return (
            <div
                className={`${styles['clickable-area']}`}
                role="cell"
                onClick={this.onClick}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="ClickableCell"
                data-metrics-column-id={field}
                data-metrics-column-name={headerName}
            >
                {children || value}
            </div>
        );
    }
}

ActionableTextCellRenderer.propTypes = {
    value: PropTypes.node,
    /** row data which is injected by the grid */
    data: PropTypes.object,
    /** context of AgGrid */
    context: PropTypes.shape({
        /** component of DataGrid */
        dispatch: PropTypes.func.isRequired,
    }),
    clickAction: PropTypes.func,
    children: PropTypes.node,
};

export default ActionableTextCellRenderer;
