import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { withCampaignEditIndication } from 'modules/campaigns/modules/campaigns-reports/components';
import { ActiveEventsActionsCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components';
import { FormattedMessageWithTooltipHeaderRenderer } from 'modules/campaigns/modules/campaigns-reports/components';
import {
    actionsColumnType,
    hiddenColumnType,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
} from 'modules/campaigns/modules/campaigns-reports/config/columns';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';

const activeEventsReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.ID.label,
            field: FIELDS.ID.field,
            type: [hiddenColumnType, numericColumn],
        },
        {
            headerName: FIELDS.EVENT_NAME.label,
            field: FIELDS.EVENT_NAME.field,
            cellRendererFramework: FIELDS.EVENT_NAME.cellRenderer,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.EVENT_NAME.headerComponentParams,
        },
        {
            headerName: FIELDS.LAST_DATE_RECEIVED.label,
            field: FIELDS.LAST_DATE_RECEIVED.field,
            cellRendererFramework: FIELDS.LAST_DATE_RECEIVED.cellRendererFramework,
        },
        {
            headerName: FIELDS.TOTAL_RECEIVED.label,
            field: FIELDS.TOTAL_RECEIVED.field,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            cellRendererFramework: FIELDS.TOTAL_RECEIVED.cellRendererFramework,
            csvValueGetter: FIELDS.TOTAL_RECEIVED.csvValueGetter,
            headerComponentParams: FIELDS.TOTAL_RECEIVED.headerComponentParams,
        },
        {
            headerName: FIELDS.DEFINED_CONVERSIONS.label,
            field: FIELDS.DEFINED_CONVERSIONS.field,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            cellRendererParams: FIELDS.DEFINED_CONVERSIONS.renderParams,
            cellRendererFramework: FIELDS.DEFINED_CONVERSIONS.cellRendererFramework,
            csvValueGetter: FIELDS.DEFINED_CONVERSIONS.csvValueGetter,
            headerComponentParams: FIELDS.DEFINED_CONVERSIONS.headerComponentParams,
        },
        {
            headerName: FIELDS.ACTIONS_COLUMN.label,
            field: FIELDS.ACTIONS_COLUMN.field,
            type: [rightPinnedColumn, actionsColumnType, staticColumn],
            cellRendererFramework: ActiveEventsActionsCellRenderer,
            csv: false,
            maxWidth: 100,
            minWidth: 100,
        },
    ],
];

export default normalizeColumnDefs(
    activeEventsReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.ACTIVE_EVENTS].id,
    withCampaignEditIndication
);
