'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash.isnil');

var _lodash2 = _interopRequireDefault(_lodash);

var _reactCurrencyInputField = require('react-currency-input-field');

var _reactCurrencyInputField2 = _interopRequireDefault(_reactCurrencyInputField);

var _Input = require('../Input/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var CurrencyInput = (0, _react.forwardRef)(function (_ref, ref) {
    var onChange = _ref.onChange,
        type = _ref.type,
        value = _ref.value,
        rest = _objectWithoutProperties(_ref, ['onChange', 'type', 'value']);

    var onValueChangeWrapper = (0, _react.useCallback)(function (val) {
        onChange({
            target: {
                value: val
            }
        });
    }, [onChange]);

    return _react2.default.createElement(_reactCurrencyInputField2.default, Object.assign({
        customInput: _Input2.default,
        ref: ref,
        value: (0, _lodash2.default)(value) ? undefined : value
    }, rest, {
        onValueChange: onValueChangeWrapper
    }));
});

CurrencyInput.propTypes = Object.assign({}, _Input2.default.propTypes, _reactCurrencyInputField2.default.propTypes);

exports.default = CurrencyInput;