import staticGraphColors from 'globalStyle/styleConsts.module.scss';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'audience-insights-report-by-category',
    metrics: [metrics.AUDIENCE_INDEX, metrics.BASELINE_INDEX],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    singleYAxisId: 'segmentCategoryReportYAxisId',
    singleYAxisColor: staticGraphColors[FIELDS.AUDIENCE_INDEX.field],
    singleYAxisMetricForFormatter: metrics.AUDIENCE_INDEX.field,
    graphProps: {
        xAxisDataKey: FIELDS.CATEGORY.field,
    },
};

export default graph;
