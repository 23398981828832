import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from '../../../campaigns/components';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import PixelBasedAudienceBasedOnEvent from '../PixelBasedAudienceBasedOnEvent/PixelBasedAudienceBasedOnEvent';

export const PixelBasedAudienceEventsSection = () => {
    return (
        <CommonCollapsibleCard
            id="PIXEL_AUDIENCE_EVENTS"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.pixel.based.audience.events.section.title"
                            defaultMessage="Events"
                        />
                    }
                />
            }
        >
            <PixelBasedAudienceBasedOnEvent />
        </CommonCollapsibleCard>
    );
};

export default PixelBasedAudienceEventsSection;
