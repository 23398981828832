import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './dynamicParametersCheckBoxTitle.module.scss';

export const DynamicParametersCheckBoxTitle = ({ id, defaultMessage }) => (
    <div className={styles['container']}>
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </div>
);

DynamicParametersCheckBoxTitle.prototype = {
    checkedParameters: PropTypes.array.isRequired,
    shouldShowDescription: PropTypes.func.isRequired,
};
