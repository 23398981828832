import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import { Divider, useDebouncedValue } from 'taboola-ultimate-ui';
import CollapsiblePicker from 'components/CollapsiblePicker';
import {
    DEFAULT_LIST_ITEM_HEIGHT,
    SELECTED_ITEM_PADDING,
    ITEM_PADDING,
    DEFAULT_SELECTED_ITEM_HEIGHT,
} from 'components/CollapsiblePicker/constants';
import { useDimension } from 'hooks/queryParams/useDimension';
import useComponentStatus from 'hooks/useComponentStatus';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { CAMPAIGN_SORTABLE_FIELDS } from 'modules/campaigns/config';
import { useSelectedCampaignId } from 'modules/campaigns/hooks/useSelectedCampaignId';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { COMPONENT_STATUS } from 'services/constants';
import { isSponsoredAccount, isVideoAccount } from '../../../account-management';
import { usePermissions } from '../../../taboola-common-frontend-modules/authentication';
import { setCampaignPickerSorting } from '../../actions';
import useOpenCampaignEditor from '../../hooks/useOpenCampaignEditor';
import { campaignsMapSelector, campaignsModuleStatusSelector } from '../../selectors';
import CampaignPickerHeader from '../CampaignPickerHeader/CampaignPickerHeader';
import CampaignPickerListItem from '../CampaignPickerListItem/CampaignPickerListItem';
import { CampaignContentLoader } from './CampaignLoader';
import CampaignPickerError from './CampaignPickerError/CampaignPickerError';
import useCampaignPickerService from './services/useCampaignPickerService';
import styles from './campaignPicker.module.scss';

const sortFieldsOptions = [
    {
        value: CAMPAIGN_SORTABLE_FIELDS.TOTAL_SPENT,
        label: <FormattedMessage id="campaign.picker.sorting.totalSpent" defaultMessage="Spent (Overall)" />,
    },
    {
        value: CAMPAIGN_SORTABLE_FIELDS.CREATE_DATE,
        label: <FormattedMessage id="campaign.picker.sorting.creationDate" defaultMessage="Creation Date" />,
    },
    {
        value: CAMPAIGN_SORTABLE_FIELDS.NAME,
        label: <FormattedMessage id="campaign.picker.sorting.name" defaultMessage="Name" />,
    },
];

const CampaignPicker = ({ dataMetricsAttrs }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const campaignsModuleStatus = useSelector(campaignsModuleStatusSelector);
    const campaignsFromStore = useSelector(campaignsMapSelector);
    const [{ id: selectedAccountId, partnerTypes }] = useSelectedAccount();
    const [selectedCampaignId] = useSelectedCampaignId();
    const [selectedCampaignDimension] = useDimension();
    const { status, setStatus, isModuleReady } = useComponentStatus();
    const [searchText, setSearchText] = useState('');
    const debouncedSearchText = useDebouncedValue(searchText).trim();
    const itemHeightsCache = useRef({});
    const {
        campaigns,
        reload,
        loadNextPage,
        totalCampaignsByDimension,
        isItemLoaded,
        paginationStatus,
        reset,
        sortType,
        sortField,
        forceCampaignPickerReloadFlag,
    } = useCampaignPickerService(debouncedSearchText, status);
    const handleOnSelectSortField = ({ value: campaignPickerSortingField }) =>
        dispatch(setCampaignPickerSorting({ campaignPickerSortingField }));
    const handleOnSelectSortType = campaignPickerSortingType =>
        dispatch(setCampaignPickerSorting({ campaignPickerSortingType }));
    const disabled = useConfigMatch({ [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'true' });
    const pageName = getPageName();
    const basicEditSearchFieldEvent = useMemo(
        () => ({ component: 'CampaignPickerEditSearchField', pageName }),
        [pageName]
    );
    const basicSortEvent = useMemo(() => ({ component: 'CampaignPickerSort', pageName }), [pageName]);
    const isVideoCampaignsSupported = usePermissions('ADS_CONSOLE_VIDEO_CAMPAIGNS');
    const sortTooltip = useMemo(() => {
        const sortFieldMessage = formatMessage({ id: `campaign.picker.sorting.${sortField}` });
        const sortTypeMessage = formatMessage({ id: `campaign.picker.sorting.${sortType}` });
        return formatMessage(
            { id: 'campaign.picker.sorting.tooltip' },
            { field: sortFieldMessage, type: sortTypeMessage }
        );
    }, [sortField, sortType, formatMessage]);

    const handleEditCampaign = useOpenCampaignEditor();

    const getItemSize = useCallback(
        ({ isSelected, index }) => {
            if (isSelected) {
                return (itemHeightsCache.current[index] || DEFAULT_SELECTED_ITEM_HEIGHT) + SELECTED_ITEM_PADDING;
            }

            return (itemHeightsCache.current[index] || DEFAULT_LIST_ITEM_HEIGHT) + ITEM_PADDING;
        },
        [itemHeightsCache]
    );

    const onHeightResolved = useCallback(
        ({ height, index, windowRef }) => {
            const curHeight = itemHeightsCache.current[index];
            itemHeightsCache.current[index] = height;

            if (windowRef.current && (isNil(curHeight) || curHeight !== height)) {
                windowRef.current.resetAfterIndex(index);
            }
        },
        [itemHeightsCache]
    );

    const reloadWrapper = useCallback(async () => {
        setStatus(COMPONENT_STATUS.LOADING);
        await reload();
        setStatus(COMPONENT_STATUS.ACTIVE);
    }, [reload, setStatus]);

    // clear state on selectedAccountId change
    useEffect(() => {
        reset();
        setSearchText('');
        setStatus(COMPONENT_STATUS.INITIAL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccountId, setStatus]);

    useEffect(() => {
        if (!isModuleReady || disabled) {
            return;
        }

        reloadWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText, sortType, sortField, isModuleReady, disabled, selectedCampaignDimension]);

    useEffect(() => {
        if (!forceCampaignPickerReloadFlag) {
            return;
        }

        reset();
        reloadWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceCampaignPickerReloadFlag]);

    useEffect(() => {
        if (isEmpty(campaigns) || !isEmpty(campaignsFromStore)) {
            return;
        }

        reset();
        reloadWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignsFromStore, campaigns]);

    // Sync campaignStatus and component status
    useEffect(() => {
        setStatus(campaignsModuleStatus);
    }, [campaignsModuleStatus, setStatus]);

    // Here we manually are throwing event because can't use DOM due debouncing
    const mountRef = useRef();
    useEffect(() => {
        if (!mountRef.current) {
            return;
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            ...basicEditSearchFieldEvent,
            value: `Edit ${debouncedSearchText}`,
        });
        // eslint-disable-next-line
    }, [debouncedSearchText]);

    // Here we manually are throwing event because can't use DOM due to portal
    useEffect(() => {
        if (!mountRef.current) {
            mountRef.current = true;
            return;
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { ...basicSortEvent, value: `Sort by ${sortField} ${sortType}` });
        // eslint-disable-next-line
    }, [sortField, sortType]);

    const shouldShowTotals = totals => !disabled && !isNil(totals) && !debouncedSearchText;
    return (
        <div
            className={styles['container']}
            data-metrics-component={dataMetricsAttrs && dataMetricsAttrs.component}
            data-metrics-visibility={dataMetricsAttrs && dataMetricsAttrs.visibility}
        >
            <div className={styles['header-wrapper']}>
                {isSponsoredAccount(partnerTypes) && (
                    <CampaignPickerHeader
                        status={status}
                        disabled={disabled}
                        campaignsCount={totalCampaignsByDimension[CAMPAIGN_DIMENSION.SPONSORED]}
                        shouldShowTotals={shouldShowTotals(totalCampaignsByDimension[CAMPAIGN_DIMENSION.SPONSORED])}
                        dimension={CAMPAIGN_DIMENSION.SPONSORED}
                        selectedCampaignId={selectedCampaignId}
                    />
                )}
                {isVideoAccount(partnerTypes) && isVideoCampaignsSupported && (
                    <CampaignPickerHeader
                        status={status}
                        disabled={disabled}
                        campaignsCount={totalCampaignsByDimension[CAMPAIGN_DIMENSION.VIDEO]}
                        shouldShowTotals={shouldShowTotals(totalCampaignsByDimension[CAMPAIGN_DIMENSION.VIDEO])}
                        dimension={CAMPAIGN_DIMENSION.VIDEO}
                        selectedCampaignId={selectedCampaignId}
                    />
                )}
            </div>
            <Divider className={styles['divider']} color={styles.dividerColor} />
            {status === COMPONENT_STATUS.ERROR ? (
                <CampaignPickerError reload={reloadWrapper} />
            ) : (
                <CollapsiblePicker
                    onSearch={setSearchText}
                    searchText={searchText}
                    searchPlaceholder={formatMessage({ id: 'app.campaigns.search', defaultMessage: 'Search' })}
                    sortType={sortType}
                    sortField={sortField}
                    sortOptions={sortFieldsOptions}
                    onSelectSortField={handleOnSelectSortField}
                    onSelectSortType={handleOnSelectSortType}
                    sortFooter={
                        <div className={styles['footer']}>
                            <FormattedMessage
                                id="campaign.picker.sorting.footer"
                                defaultMessage="Running campaigns always appear first"
                            />
                        </div>
                    }
                    sortTooltip={sortTooltip}
                    disabled={disabled}
                    listItems={campaigns}
                    totalItems={totalCampaignsByDimension[selectedCampaignDimension] ?? 0}
                    loadNextPage={loadNextPage}
                    isItemLoaded={isItemLoaded}
                    virtualizationState={debouncedSearchText}
                    paginationStatus={paginationStatus}
                    selectedItem={selectedCampaignId}
                    isSelectedItem={(item, selected) => item?.id === selected}
                    loadingListItemComponent={CampaignContentLoader}
                    getItemSize={getItemSize}
                    getItemKey={item => item?.id}
                    listItemComponent={props => (
                        <CampaignPickerListItem
                            {...props}
                            onEditCampaign={handleEditCampaign}
                            searchText={searchText}
                            onHeightResolved={onHeightResolved}
                            selectedAccountId={selectedAccountId}
                        />
                    )}
                />
            )}
        </div>
    );
};

export default CampaignPicker;
