import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const UrlKeywordsTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="video.campaign.creator.url.keywords.help.overview"
                defaultMessage="Opt-in OR out specific keywords in the site URL."
            />
        </TooltipSection>
    </div>
);

export default UrlKeywordsTooltip;
