import React from 'react';

export const Ach = () => {
    return (
        <svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.688 12.634v-4.24a.82.82 0 0 1 .244-.604.82.82 0 0 1 .604-.244.82.82 0 0 1 .605.244.82.82 0 0 1 .244.604v4.24a.82.82 0 0 1-.244.605.82.82 0 0 1-.605.244.82.82 0 0 1-.604-.244.82.82 0 0 1-.244-.605Zm5.089 0v-4.24a.82.82 0 0 1 .244-.604.82.82 0 0 1 .604-.244.82.82 0 0 1 .604.244.82.82 0 0 1 .244.604v4.24a.82.82 0 0 1-.244.605.82.82 0 0 1-.604.244.82.82 0 0 1-.604-.244.82.82 0 0 1-.244-.605ZM.992 16.875a.82.82 0 0 1-.604-.244.82.82 0 0 1-.244-.604.82.82 0 0 1 .244-.604.82.82 0 0 1 .604-.244h15.266a.82.82 0 0 1 .604.244.82.82 0 0 1 .244.604.82.82 0 0 1-.244.604.82.82 0 0 1-.604.244H.992Zm11.874-4.24V8.393a.82.82 0 0 1 .243-.604.82.82 0 0 1 .605-.244.82.82 0 0 1 .604.244.82.82 0 0 1 .244.604v4.24a.82.82 0 0 1-.244.605.82.82 0 0 1-.604.244.82.82 0 0 1-.605-.244.82.82 0 0 1-.243-.605Zm3.392-6.785H.908a.736.736 0 0 1-.541-.223.736.736 0 0 1-.223-.54V4.62c0-.156.039-.29.117-.403a.892.892 0 0 1 .307-.276L7.862.295c.24-.113.494-.17.763-.17s.523.057.763.17L16.64 3.92c.155.07.272.177.35.318.077.142.116.29.116.446v.318a.82.82 0 0 1-.244.604.82.82 0 0 1-.604.244ZM3.918 4.153h9.414L8.625 1.821 3.918 4.153Z"
                fill="#96F"
            />
        </svg>
    );
};
