import React from 'react';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../config';
import useCampaignBreadcrumbs from '../../hooks/useCampaignBreadcrumbs';
import { FormBreadcrumbs } from '../common-campaign-form';

const VideoCreativeBreadcrumbs = props => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'video.creative.creator.title', defaultMessage: 'Add New Video' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CREATIVE_VIDEO,
        title,
    });
    return <FormBreadcrumbs title={title} steps={steps} {...props} />;
};

export default VideoCreativeBreadcrumbs;
