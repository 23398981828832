import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { BaseCard } from '../BaseCard/BaseCard';
import { BRAND_TYPE, BrandMark, LOGO_TYPE } from '../BrandMark/BrandMark';
import { AchBankDetails } from './AchBankDetails';
import styles from './achBankDirectDebit.scss';

const classNameBuilder = classnames.bind(styles);

export const AchBankDirectDebit = ({
    className,
    accountNumber,
    accountHolderName,
    bankName,
    lastBillingDate,
    statusType,
    onDelete,
}) => (
    <BaseCard
        className={classNameBuilder('ach-background', className)}
        title={<FormattedMessage id="billingAndPayments.ach.title" defaultMessage="ACH" />}
        icon={<BrandMark brand={BRAND_TYPE.ACH} type={LOGO_TYPE.ICON} />}
        detailsFirstLine={accountHolderName}
        detailsSecondLine={<AchBankDetails bankName={bankName} accountFourLastDigits={accountNumber} />}
        dateTitle={
            <FormattedMessage
                id="billingAndPayments.baseCard.lastBillingDate.label"
                defaultMessage="Last billing Date"
            />
        }
        date={lastBillingDate}
        statusType={statusType}
        onDelete={onDelete}
        showCardActions
    />
);
