import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import { gridNumberFormatter, formattedNumberValueGetter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const cityReportColumnDefinition = [
    {
        headerName: FIELDS.CITY.label,
        field: FIELDS.CITY.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.CITY.cellRendererFramework,
        cellRendererParams: FIELDS.CITY.renderParams,
        csvValueGetter: FIELDS.CITY.csvValueGetter,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.VCTR,
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        FIELDS.CLICKS,
        FIELDS.AVERAGE_CPC,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
        FIELDS.CPA,
        addMeasuredByMoatToField(FIELDS.VCPM),
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        FIELDS.CTR,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,

        FIELDS.COMPLETED_VIEWS,
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(cityReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.CITY_VIDEO].id);
