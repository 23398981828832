import { invert } from 'lodash';

export const ENTITY = {
    PIXEL_BASED: 'TABOOLA_PIXEL_AUDIENCES',
    FIRST_PARTY: 'CUSTOM_AUDIENCES',
    PREDICTIVE: 'PREDICTIVE',
    CUSTOM_CONTEXTUAL: 'CUSTOM_CONTEXTUAL',
    COMBINED_AUDIENCES: 'COMBINED_AUDIENCES',
    SEARCH_KEYWORDS: 'SEARCH_KEYWORDS',
    MAIL_DOMAINS: 'MAIL_DOMAINS',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.PIXEL_BASED]: 'pixel-audiences',
    [ENTITY.FIRST_PARTY]: 'custom-audiences',
    [ENTITY.PREDICTIVE]: 'predictive',
    [ENTITY.CUSTOM_CONTEXTUAL]: 'topics-targeting',
    [ENTITY.COMBINED_AUDIENCES]: 'combined-audiences',
    [ENTITY.SEARCH_KEYWORDS]: 'search-keywords-audiences',
    [ENTITY.MAIL_DOMAINS]: 'mail-domain-audiences',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
