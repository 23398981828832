import { keyBy, flatten, pickBy, get } from 'lodash';
import { getActiveItemsList } from './utils';
import { getActiveAppdata } from './utils/filterByAppData';

export const cartesianProductOf = (...args) =>
    args.reduce((a, b) => flatten(a.map(x => b.map(y => x.concat([y])))), [[]]);

const generateCreativeFromPermutation = (
    creativePreviewsGetter,
    permutation,
    activeAppData,
    creativeName,
    customId,
    creativeLogo
) => {
    const [url, content, thumbnail] = permutation;
    const id = window.btoa(`${url.id}${content.id}${thumbnail.id}`);
    const { description, cta, title, thirdPartyTags } = content;
    const { appInstall, rating, logo: appDataLogo } = activeAppData || {};
    const deleted = get(creativePreviewsGetter(), [url.value, id, 'deleted']);

    return {
        id,
        deleted: deleted || false,
        url: url.value,
        thumbnailUrl: thumbnail.value,
        fileType: thumbnail.fileType,
        fallbackImageUrl: thumbnail.fallbackImageUrl,
        displayUrl: thumbnail.displayUrl,
        gifUrl: thumbnail.gifUrl,
        creativeCrop: thumbnail.creativeCrop?.cropData?.length
            ? { cropData: thumbnail.creativeCrop?.cropData }
            : { cropData: [] },
        title,
        description: description || null,
        cta: cta ? { ctaType: cta } : null,
        thirdPartyTags: thirdPartyTags || null,
        recommendedFBImage: thumbnail.recommendedFBImage || null,
        mediaUploadSource: thumbnail.mediaUploadSource || null,
        motionAdsStudio: thumbnail.motionAdsStudio || null,
        appInstall: appInstall ?? null,
        rating: rating ?? null,
        logo: appDataLogo ?? creativeLogo ?? null,
        creativeName: creativeName ?? null,
        customId: customId ?? null,
    };
};

export const generateCreativesByUrl = (
    permutationsArray,
    creativePreviewsGetter,
    activeAppData,
    creativeName,
    customId,
    creativeLogo
) => {
    const creativesByUrl = {};
    permutationsArray.forEach(permutation => {
        const creative = generateCreativeFromPermutation(
            creativePreviewsGetter,
            permutation,
            activeAppData,
            creativeName,
            customId,
            creativeLogo
        );
        const url = creative.url;

        if (!creativesByUrl[url]) {
            creativesByUrl[url] = [];
        }
        creativesByUrl[url].push(creative);
    });

    return Object.keys(creativesByUrl).reduce((res, url) => {
        res[url] = keyBy(creativesByUrl[url], 'id');
        return res;
    }, {});
};

const creativePreviewGenerator = ({
    urls,
    thumbnails,
    content,
    creativePreviewsGetter,
    appData,
    formatType,
    creativeName,
    customId,
    creativeLogo,
}) => {
    const urlList = getActiveItemsList(urls);
    const activeAppData = getActiveAppdata(appData, formatType);
    const thumbnailList = getActiveItemsList(pickBy(thumbnails, 'value'));

    const permutationsArray = cartesianProductOf(urlList, content, thumbnailList);

    return generateCreativesByUrl(
        permutationsArray,
        creativePreviewsGetter,
        activeAppData,
        creativeName,
        customId,
        creativeLogo
    );
};

export const carouselPreviewGenerator = (carouselCards, thumbnails, customData) => {
    const { creativeName, customId } = customData;
    const permutationsArray = [];
    carouselCards.forEach((card, index) => {
        if (!card.url || !card.title) {
            return;
        }

        permutationsArray.push([
            {
                id: card.id,
                value: card.url,
            },
            {
                id: card.id,
                title: card.title,
                description: card.description,
                cta: card.cta,
            },
            {
                ...thumbnails[index],
            },
        ]);
    });

    return generateCreativesByUrl(permutationsArray, () => ({}), {}, creativeName, customId);
};

export default creativePreviewGenerator;
