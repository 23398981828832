import { PHASES } from './phases';

export const REMAINING_TIME_MESSAGE_ID = {
    BUSINESS_DAY: 'business.day',
    BUSINESS_DAYS: 'business.days',
    HOURS_LEFT: 'hours.left',
    DAYS_SHORT: 'days.short',
    HOURS_SHORT: 'hours.short',
};

export const PHASE_REMAINING_TIME_MESSAGE_ID = {
    [PHASES.PENDING_REVIEW]: REMAINING_TIME_MESSAGE_ID.HOURS_LEFT,
    [PHASES.LEARNING]: REMAINING_TIME_MESSAGE_ID.DAYS_SHORT,
    [PHASES.LEARNING_LIMITED]: REMAINING_TIME_MESSAGE_ID.DAYS_SHORT,
    [PHASES.EXTENDED_LEARNING]: REMAINING_TIME_MESSAGE_ID.DAYS_SHORT,
    [PHASES.SCALING]: REMAINING_TIME_MESSAGE_ID.DAYS_SHORT,
    [PHASES.EXPEDITED_LEARNING]: REMAINING_TIME_MESSAGE_ID.HOURS_SHORT,
};
