"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgResume(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 20,
            height: 19,
            viewBox: "0 0 20 19",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M9.58837 2.09915C9.58836 1.68183 10.0694 1.44814 10.3974 1.7061L19.1434 8.58357C19.3848 8.77339 19.3996 9.13394 19.1746 9.34294L10.4291 17.4665C10.1092 17.7636 9.58878 17.5368 9.58877 17.1002L9.58837 2.09915Z",
            fill: "currentColor"
        }),
        React.createElement("rect", {
            x: 0.558838,
            y: 1.55688,
            width: 5,
            height: 16,
            fill: "currentColor"
        })
    );
}
exports.default = SvgResume;