import { useCallback, useMemo, useState } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';
import { SHEET_NAME } from '../consts/sheetName';
import { getUniqueFileNamesFromWorkbook } from '../services/getFileNamesFromWorkbook';
import { getExcelWorkbookValidations } from '../uploadFileValidations';
import { convertFileToWorkbook } from '../utils/convertFileToWorkbook';
import { getSheetName } from '../utils/excelBulkWorkbookUtils';
import { useExcelBulkUploadConfigs } from '../utils/useExcelBulkUploadConfigs';
import { useExcelConfig } from './useExcelConfig';

export const useParsedExcelFile = ({ formatMessage, commonMetadata }) => {
    const excelConfig = useExcelConfig();

    const [isParsingWorkbook, setIsParsingWorkbook] = useState(false);
    const [excelParsingError, setExcelParsingError] = useState(null);

    const { value: file, onChange: onChangeFile } = useFormFieldValue({ field: 'file' });
    const { maxCampaignRows, maxCreativesRows, isMetadataCheckDisabled } = useExcelBulkUploadConfigs();
    const validations = useMemo(
        () => getExcelWorkbookValidations({ maxCampaignRows, maxCreativesRows }),
        [maxCampaignRows, maxCreativesRows]
    );

    const {
        value: excelWorkbook,
        onChange: onChangeWorkbook,
        failedValidationData,
        isValidationsRunning,
    } = useFormValidatedValue({
        field: 'excelWorkbook',
        validations,
        validationDependencies: {
            campaignsSheetName: getSheetName(SHEET_NAME.CAMPAIGNS, formatMessage),
            creativesSheetName: getSheetName(SHEET_NAME.CREATIVES, formatMessage),
            excelConfig,
            isParsingWorkbook,
            isMetadataCheckDisabled,
            ...commonMetadata,
        },
    });

    const onSelectFile = useCallback(
        async acceptedFiles => {
            const selectedFile = acceptedFiles[0];
            setExcelParsingError(null);
            setIsParsingWorkbook(true);

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${BULK_UPLOAD_GTM_PREFIX}: Template uploaded`,
            });

            try {
                const excelWorkbook = await convertFileToWorkbook(selectedFile);
                onChangeWorkbook(excelWorkbook);
                onChangeFile(selectedFile);
            } catch (error) {
                setExcelParsingError(error);
            }

            setIsParsingWorkbook(false);
        },
        [onChangeWorkbook, onChangeFile]
    );
    const onRemoveFile = useCallback(() => {
        onChangeFile(null);
        onChangeWorkbook(null);
        setIsParsingWorkbook(false);
        setExcelParsingError(null);
    }, [onChangeWorkbook, onChangeFile]);

    const allFileNames = useMemo(() => {
        if (!excelWorkbook) {
            return null;
        }

        return getUniqueFileNamesFromWorkbook({ workbook: excelWorkbook, excelConfig, formatMessage });
    }, [excelConfig, excelWorkbook, formatMessage]);

    return {
        file,
        excelWorkbook,
        allFileNames,
        onSelectFile,
        onRemoveFile,
        failedValidationData,
        isParsingWorkbook,
        excelParsingError,
        isValidationsRunning,
    };
};
