import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from '../../../../config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import AudienceNameField from '../AudienceNameField/AudienceNameField';

export const ExternalAudiencesSetupSection = () => {
    const { mode } = useFormFieldValue({ field: 'type' });

    return (
        <CommonCollapsibleCard
            id="EXTERNAL_AUDIENCE_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.setup.section.title"
                            defaultMessage="Audience Setup"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && <AudienceNameField />}
        </CommonCollapsibleCard>
    );
};
