import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations/services';
import { audienceTargetingBidValidator, bidValidationsBase, selfFactorValidations } from './bidValidations';

const cpmDataValidations = [
    {
        validationFn: audienceTargetingBidValidator,
        options: { min: 'cpmDataMinValue' },
        messageId: 'validations.error.campaign.cpm.data.specificAccountMinValue',
        defaultMessage:
            'CPM Amount cannot be lower than {cpmDataMinValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: audienceTargetingBidValidator,
        options: { max: 'cpmDataMaxValue' },
        messageId: 'validations.error.campaign.cpm.data.specificAccountMaxValue',
        defaultMessage:
            'CPM Amount cannot be higher than {cpmDataMaxValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const cpmValidationsForCreate = [
    ...bidValidationsBase,
    {
        validationFn: validationFunctions.range,
        options: { min: 'cpmMinValue' },
        messageId: 'validations.error.campaign.cpm.specificAccountMinValue',
        defaultMessage: 'Bid has to be higher than {cpmMinValue} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'cpmMaxValue' },
        messageId: 'validations.error.campaign.cpm.specificAccountMaxValue',
        defaultMessage: 'Bid has to be lower than {cpmMaxValue} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 'cpmMinWarn' },
        messageId: 'validations.error.campaign.cpm.network.minValue',
        defaultMessage: 'Please note: Bid is lower than normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'cpmMaxWarn' },
        messageId: 'validations.error.campaign.cpm.network.maxValue',
        defaultMessage: 'Please note: Bid is above normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    ...cpmDataValidations,
];

export const cpmValidationsForEdit = [...bidValidationsBase, ...cpmDataValidations, ...selfFactorValidations];
