import { useCallback, useMemo } from 'react';
import { batch } from 'react-redux';
import { countBy, keyBy, isEmpty } from 'lodash';
import { useComponentStatus, useFuzzySearch } from 'hooks';
import { resourcesApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { useLoadingState } from '../../Tree';
import { getUniqId } from '../utils/index';
import useOsTargetingService from './useOsTargetingService';

const DEFAULT_SELECTED_VALUES = [];
const useOsTreeData = ({ search, selectedValues = DEFAULT_SELECTED_VALUES, valuesToFilter }) => {
    const { setListOfOs, listOfOs, leavesToValues, valuesToLeaves, getTaxonomyValue } =
        useOsTargetingService(valuesToFilter);
    const leaves = useFuzzySearch(listOfOs, 'label', search);
    const totals = useMemo(() => countBy(leaves, ({ taxonomy }) => taxonomy), [leaves]);
    const { startLoading, stopLoading } = useLoadingState(listOfOs);
    const { status, setStatus } = useComponentStatus();

    const fetchWithLoading = useCallback(async () => {
        if (!isEmpty(listOfOs)) {
            return listOfOs;
        }

        startLoading();

        let newListOfOs = [];
        let newStatus = COMPONENT_STATUS.ACTIVE;
        try {
            const response = await resourcesApi.getOsList();
            newListOfOs = response.operatingSystemTargetings;
        } catch (error) {
            newStatus = COMPONENT_STATUS.ERROR;
        }

        batch(() => {
            newListOfOs = setListOfOs(newListOfOs);
            setStatus(newStatus);
            stopLoading();
        });

        return newListOfOs;
    }, [listOfOs, setListOfOs, setStatus, startLoading, stopLoading]);

    const loadByIds = useCallback(
        ids => {
            const itemsMap = keyBy(listOfOs, 'id');
            return ids.map(id => itemsMap[id] || { id });
        },
        [listOfOs]
    );

    const selectedValuesForSmartList = useMemo(() => {
        return selectedValues.map(({ id, osFamily, ...rest }) => ({
            id: getUniqId(id, osFamily),
            osFamily,
            ...rest,
        }));
    }, [selectedValues]);

    return {
        leaves,
        totals,
        reload: fetchWithLoading,
        loadByIds,
        status,
        leavesToValues,
        valuesToLeaves,
        getTaxonomyValue,
        selectedValuesForSmartList,
    };
};

export default useOsTreeData;
