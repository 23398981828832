import InvalidContainerIdError from '../InvalidContainerIdError';

const createScriptContent = containerId =>
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${containerId}');`;

const injectScript = containerId => {
    const snippet = createScriptContent(containerId);
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = snippet;
    document.head.insertBefore(scriptElement, document.head.firstChild);
};

const createDataLayer = initialDataLayer => {
    window.dataLayer = window.dataLayer || [];

    if (!initialDataLayer) {
        return;
    }

    if (typeof initialDataLayer !== 'object') {
        throw Error('initialDataLayer must be an object');
    }

    window.dataLayer.push(JSON.stringify(initialDataLayer));
};

const injectSnippets = (containerId, initialDataLayer) => {
    if (typeof containerId !== 'string' || containerId.length === 0) {
        throw new InvalidContainerIdError(containerId);
    }

    injectScript(containerId);
    createDataLayer(initialDataLayer);
};

export default injectSnippets;
