import React, { useMemo } from 'react';
import { Button, ContentPasteOutlinedIcon } from 'tuui';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { copyToClipboard } from 'modules/campaigns/services/utils/clipboard';
import { useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './vastUrlPreivewField.module.scss';

const vastTagUrlTemplate = adId => `https://impr.taboola.com/CreativeDisplayer?adId=${adId}&excid=22`;

export const VastUrlPreviewField = ({ adId }) => {
    const { formatMessage } = useIntl();

    const finalUrl = useMemo(() => vastTagUrlTemplate(adId), [adId]);

    const handleClick = () => copyToClipboard(finalUrl);

    return (
        <FormField label={formatMessage({ id: 'video.creative.creator.setup.vast.url.preview' })}>
            <div className={styles['container']}>
                <Input disabled value={finalUrl} type={InputTypes.URL} size="large" inputClass={styles['input']} />
                <Button
                    size={Button.size.md}
                    variant={Button.variant.text}
                    title={formatMessage({ id: 'video.creative.creator.setup.vast.url.preview.copy.button.tooltip' })}
                    onClick={handleClick}
                >
                    <ContentPasteOutlinedIcon />
                </Button>
            </div>
        </FormField>
    );
};
