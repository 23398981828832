'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ColumnMenu = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _HeadlessDropdown = require('../../DropdownMenus/HeadlessDropdown/HeadlessDropdown');

var _HeadlessDropdown2 = _interopRequireDefault(_HeadlessDropdown);

var _Dropdown = require('../../Icons/Icons/Dropdown');

var _Dropdown2 = _interopRequireDefault(_Dropdown);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var ColumnMenu = function ColumnMenu(props) {
    var column = props.column,
        open = props.open,
        toggleColumnMenu = props.toggleColumnMenu,
        suppressMovable = props.suppressMovable,
        _props$context = props.context;
    _props$context = _props$context === undefined ? {} : _props$context;

    var onColumnStateChanged = _props$context.onColumnStateChanged,
        _props$context$notPin = _props$context.notPinnedLabel,
        notPinnedLabel = _props$context$notPin === undefined ? 'Unpin' : _props$context$notPin,
        _props$context$leftPi = _props$context.leftPinnedLabel,
        leftPinnedLabel = _props$context$leftPi === undefined ? 'Pin Left' : _props$context$leftPi,
        rest = _objectWithoutProperties(props, ['column', 'open', 'toggleColumnMenu', 'suppressMovable', 'context']);

    var colId = column.colId;
    var isMenuVisible = !(0, _lodash2.default)(column, 'colDef.pinned') && !suppressMovable;

    if (!isMenuVisible) {
        return null;
    }

    var columnState = column.columnApi.getColumnState();
    var currentColumnState = columnState.find(function (_ref) {
        var filterValue = _ref.colId;
        return filterValue === colId;
    });
    var currentPinnedValue = (0, _lodash2.default)(currentColumnState, 'pinned', null);
    var onChange = function onChange(_ref2) {
        var value = _ref2.value;

        currentColumnState.pinned = value;
        column.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true
        });
        if (onColumnStateChanged) {
            onColumnStateChanged(column.columnApi.getColumnState());
        }
    };
    var options = [{ value: null, label: notPinnedLabel }, { value: 'left', label: leftPinnedLabel }];
    var currentOptions = options.filter(function (_ref3) {
        var value = _ref3.value;
        return value !== currentPinnedValue;
    });

    return _react2.default.createElement(
        'span',
        null,
        _react2.default.createElement(
            _HeadlessDropdown2.default,
            Object.assign({
                options: currentOptions,
                onChange: onChange,
                open: open,
                onToggleDropdown: toggleColumnMenu,
                menuStyles: { right: 0 },
                menuPlacement: 'top',
                styles: {
                    menuList: function menuList(provided) {
                        return Object.assign({}, provided, { padding: 0 });
                    },
                    option: function option(provided) {
                        return Object.assign({}, provided, { padding: '5px 9px' });
                    }
                }
            }, rest),
            _react2.default.createElement(_Dropdown2.default, {
                className: 'column-menu-icon',
                'aria-label': 'Column menu icon'
            })
        )
    );
};

exports.ColumnMenu = ColumnMenu;
ColumnMenu.propTypes = {
    column: _propTypes2.default.object,
    context: _propTypes2.default.object,
    open: _propTypes2.default.bool,
    toggleColumnMenu: _propTypes2.default.func,
    leftPinnedLabel: _propTypes2.default.string,
    notPinnedLabel: _propTypes2.default.string,
    suppressMovable: _propTypes2.default.bool
};