import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    BlockIcon,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    useCollapsibleListContext,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import BrandSafetyRiskLevelRangeSlider from './BrandSafetyRiskLevelRangeSlider';
import styles from './brandSafetyItem.module.scss';

const BrandSafetyItem = ({ item, deleteItem, index }) => {
    const {
        metadata: { msgIdPrefix, maxValue, onRiskLevelsChange },
    } = useCollapsibleListContext();
    const { formatMessage } = useIntl();
    const { value, label, shouldUseRiskLevelSlider, riskLevel } = item;
    const handleOnClick = useCallback(() => deleteItem?.(item), [item, deleteItem]);
    const riskChangeHandler = useCallback(value => onRiskLevelsChange(index, value), [index, onRiskLevelsChange]);

    return (
        <div className={styles['container']}>
            <div className={styles['info-container']}>
                <span>
                    <BlockIcon className={styles['exclude']} />
                </span>
                <div className={styles['label']}>
                    <FormattedMessage id={`${msgIdPrefix}.${value}`} defaultMessage={label} />
                </div>
                <StyledButton
                    aria-label={`${formatMessage({ id: 'app.modal.removeVariation.button.remove' })} - ${value}`}
                    className={styles['remove-button']}
                    size={STYLED_BUTTON_SIZE.MEDIUM}
                    type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                    iconBefore="close"
                    onClick={handleOnClick}
                />
            </div>
            {shouldUseRiskLevelSlider && (
                <BrandSafetyRiskLevelRangeSlider
                    riskLevel={riskLevel}
                    onRiskLevelChange={riskChangeHandler}
                    msgIdPrefix={msgIdPrefix}
                    maxValue={maxValue}
                />
            )}
        </div>
    );
};

BrandSafetyItem.propTypes = {
    item: PropTypes.shape({
        value: PropTypes.string,
        targetingType: PropTypes.string,
        label: PropTypes.node,
        audienceType: PropTypes.string,
        audienceSize: PropTypes.number,
    }),
    deleteItem: PropTypes.func,
    msgIdPrefix: PropTypes.string,
    index: PropTypes.number,
};

export default BrandSafetyItem;
