"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgHeaderNotifications(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M19.674 19h-5c0 1.312-1.192 3-2.504 3-1.31 0-2.496-1.688-2.496-3h-5c-1.525 0-1.934-1.206-1.525-1.512 1.2-.895 1.526-2.491 1.525-3.988V8.937C4.674 5.002 8.244 2 12.178 2c3.933 0 7.496 3.002 7.496 6.938V13.5c-.004 1.496.326 3.093 1.525 3.988.408.306 0 1.512-1.525 1.512zm-2-10.096C17.574 6.22 14.811 4 12.176 4 9.542 4 6.774 6.22 6.674 8.904V13.5c0 1.267.265 2.414-.375 3.5h11.757c-.641-1.085-.382-2.233-.382-3.5V8.904z"
            })
        )
    );
}
exports.default = SvgHeaderNotifications;