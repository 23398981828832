import { useCallback } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useTrackingApi } from '../../../services/api/trackingApi';
import { TRACKING_ROUTE } from '../config';

export const useTrackingSettingsEditorState = () => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const { getTrackingSettings, updateTrackingSettings } = useTrackingApi();

    const formDataFetcher = useCallback(() => getTrackingSettings(accountId), [accountId, getTrackingSettings]);

    const { submit } = useFormState({ formDataFetcher });

    const handleTrackingSettingsSubmitWrapper = useCallback(() => {
        const updateTrackingSettingsOnSubmit = data => {
            try {
                return updateTrackingSettings(accountId, data);
            } catch (e) {
                //TODO - handle error case
                throw e;
            }
        };

        return submit(updateTrackingSettingsOnSubmit, TRACKING_ROUTE);
    }, [submit, updateTrackingSettings, accountId]);

    return { submit: handleTrackingSettingsSubmitWrapper };
};
