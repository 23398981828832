import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useChatAgentPermitted } from '../../../../components/ChatAgent/hooks/useChatAgentPermitted';
import clearRecommendations from '../../../recommendations/actions/clearRecommendations';
import { CHAT_AGENT_ACTION_STATUS } from '../config/chatAgentActionStatus';
import { ASYNC_ACTIONS, CHAT_AGENT_ACTION_TYPE } from '../config/chatAgentActionType';
import { CHAT_AGENT_COMPONENT } from '../config/chatAgentComponentGA';
import { CHAT_AGENT_VERSION_V2 } from '../config/chatAgentVersionV2';
import { getChatAgentGAValue } from '../utils/getChatAgentGAValue';
import { isValidUserAction } from '../utils/isValidUserAction';
import { updateEventByAction } from '../utils/updateEventByAction';

export const useUserActionService = ({ setCellToEdit, sendResponseToChatAgent, client }) => {
    const isPermitted = useChatAgentPermitted();
    const dispatch = useDispatch();
    const currentEventRef = useRef(null);
    const currentActionRef = useRef(null);
    const setCurrentAction = useCallback(action => (currentActionRef.current = action), []);
    const getCurrentAction = useCallback(() => currentActionRef.current, []);
    const setCurrentEvent = useCallback(action => (currentEventRef.current = action), []);
    const getCurrentEvent = useCallback(() => currentEventRef.current, []);
    const userAction = useCallback(
        (action, srcWindow) => {
            if (!isPermitted) {
                return;
            }

            const { status, actionData, actionType, message } = action;

            if (client === CHAT_AGENT_VERSION_V2 && actionType !== CHAT_AGENT_ACTION_TYPE.FORM_ACTION) {
                return;
            }

            const currentEvent = getCurrentEvent();
            if (isEmpty(currentEvent)) {
                sendResponseToChatAgent({ actions: [action] }, srcWindow);
                return;
            }
            if (!isValidUserAction(getCurrentAction(), { actionType, status })) {
                console.error('Incompatible action', getCurrentAction(), action);
                return;
            }
            setCurrentEvent(updateEventByAction(currentEvent, getCurrentAction(), { status, actionData, message }));

            const isLastAction =
                currentActionRef.current.actionId === currentEvent.actions[currentEvent.actions.length - 1].actionId;

            if (status === CHAT_AGENT_ACTION_STATUS.FAILURE || ASYNC_ACTIONS[actionType] || isLastAction) {
                sendResponseToChatAgent(getCurrentEvent(), srcWindow);
            }

            if (
                status !== CHAT_AGENT_ACTION_STATUS.FAILURE &&
                (status === CHAT_AGENT_ACTION_STATUS.IN_PROGRESS ||
                    (status === CHAT_AGENT_ACTION_STATUS.SUCCESS && !isLastAction))
            ) {
                return;
            }

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: CHAT_AGENT_COMPONENT,
                value: getChatAgentGAValue(action, currentEvent),
            });

            setCurrentAction(null);
            setCurrentEvent(null);
            setCellToEdit(null);
            dispatch(clearRecommendations());
        },
        [
            getCurrentEvent,
            setCurrentEvent,
            getCurrentAction,
            setCurrentAction,
            sendResponseToChatAgent,
            setCellToEdit,
            dispatch,
            isPermitted,
            client,
        ]
    );

    return {
        setCurrentEvent,
        getCurrentEvent,
        setCurrentAction,
        userAction,
    };
};
