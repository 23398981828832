import { isNetworkAccountType } from 'modules/account-management';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { SHEET_NAME } from '../consts/sheetName';
import {
    transformOSResponse,
    transformResponseNames,
    transformConversionRulesResponse,
    transformResponseResults,
    transformCampaignGroupsResponse,
    transformThirdPartyDVBrandSafetyResponse,
    transformThirdPartyIASBrandSafetyResponse,
    transformAccountsResponse,
} from '../transformers/dictionaryResponseTransformers';
import { COLUMN_WIDTH_LARGE, COLUMN_WIDTH_MEDIUM } from '../utils/excelBulkWorkbookUtils';

export const dictionaryConfig = {
    sheets: [
        {
            dataPath: 'accounts',
            responseTransformer: transformAccountsResponse,
            sheetName: SHEET_NAME.ACCOUNTS,
            columns: [{ dataPath: 'accountName' }, { dataPath: 'accountDescription' }],
            visibilityCondition: ({ selectedAccount }) => isNetworkAccountType(selectedAccount.type),
        },
        {
            dataPath: 'countryList',
            responseTransformer: transformResponseResults,
            sheetName: SHEET_NAME.COUNTRY_CODES,
            columns: [{ dataPath: 'name' }, { dataPath: 'value', width: COLUMN_WIDTH_MEDIUM }],
        },
        {
            dataPath: 'regionList',
            sheetName: SHEET_NAME.REGION_CODES,
            columns: [{ dataPath: 'name' }, { dataPath: 'value' }],
        },
        {
            sheetName: SHEET_NAME.DMA_CODES,
            dataPath: 'dmaList',
            columns: [{ dataPath: 'name' }, { dataPath: 'value', width: COLUMN_WIDTH_MEDIUM }],
        },
        {
            dataPath: 'cityList',
            responseTransformer: transformResponseResults,
            sheetName: SHEET_NAME.CITY_CODES,
            columns: [{ dataPath: 'id' }, { dataPath: 'city' }, { dataPath: 'region' }],
        },
        {
            dataPath: 'osList',
            responseTransformer: transformOSResponse,
            sheetName: SHEET_NAME.OS,
            columns: [{ dataPath: 'subCategory.label' }],
        },
        {
            dataPath: 'browserList',
            responseTransformer: transformResponseNames,
            sheetName: SHEET_NAME.BROWSERS,
            columns: [{}],
        },
        {
            dataPath: 'brandSafetyDV',
            responseTransformer: transformThirdPartyDVBrandSafetyResponse,
            sheetName: SHEET_NAME.BRAND_SAFETY_DV,
            columns: [{}],
            permissions: ['EXTERNAL_BRAND_SAFETY'],
        },
        {
            dataPath: 'brandSafetyIAS',
            responseTransformer: transformThirdPartyIASBrandSafetyResponse,
            sheetName: SHEET_NAME.BRAND_SAFETY_IAS,
            columns: [{}],
            permissions: ['EXTERNAL_BRAND_SAFETY'],
        },
        {
            dataPath: 'conversionRules',
            responseTransformer: transformConversionRulesResponse,
            sheetName: SHEET_NAME.CONVERSION_EVENTS,
            columns: [{ dataPath: 'displayName', width: COLUMN_WIDTH_MEDIUM }],
        },
        {
            dataPath: 'contextualSegments',
            responseTransformer: transformResponseResults,
            sheetName: SHEET_NAME.CONTEXTUAL_SEGMENTS,
            columns: [
                { dataPath: 'id' },
                { dataPath: 'label', width: COLUMN_WIDTH_MEDIUM },
                { dataPath: 'description', width: COLUMN_WIDTH_LARGE },
            ],
        },
        {
            dataPath: 'marketplaceAudiences',
            responseTransformer: transformResponseResults,
            sheetName: SHEET_NAME.MARKETPLACE_AUDIENCES,
            columns: [
                { dataPath: 'id' },
                { dataPath: 'label', width: COLUMN_WIDTH_MEDIUM },
                { dataPath: 'provider' },
                { dataPath: 'description', width: COLUMN_WIDTH_LARGE },
            ],
        },
        {
            dataPath: 'myAudiences',
            responseTransformer: transformResponseResults,
            sheetName: SHEET_NAME.MY_AUDIENCES,
            columns: [{ dataPath: 'id' }, { dataPath: 'name', width: COLUMN_WIDTH_LARGE }],
        },
        {
            dataPath: 'timeZones',
            responseTransformer: transformResponseNames,
            sheetName: SHEET_NAME.TIME_ZONES,
            columns: [{}],
            accountConfigurations: { [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true' },
        },
        {
            dataPath: 'campaignGroups',
            responseTransformer: transformCampaignGroupsResponse,
            sheetName: SHEET_NAME.CAMPAIGN_GROUPS,
            columns: [{ dataPath: 'name', width: COLUMN_WIDTH_LARGE }],
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
        },
        {
            dataPath: 'ctaType',
            sheetName: SHEET_NAME.CTA_TYPE,
            columns: [{}],
            isStaticData: true,
        },
    ],
};
