import { useEffect, useMemo, useState } from 'react';
import { FORM_MODES } from 'config/formModes';
import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useTryGenAiTooltip } from './useTryGenAiTooltip';
import { useTryGenAiTooltipDismissedForSession } from './useTryGenAiTooltipDismissedForSession';

const SUPPORTED_GEN_AI_TOOLTIP_FORMS = [FORM_MODES.EDIT, FORM_MODES.BULK_EDIT, FORM_MODES.DUPLICATE];

export const useRecentlyUsedMediaSubTabTooltipShowing = ({ disabled } = {}) => {
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const { mode: formMode } = useFormDataContext();

    const { dismissedRef } = useTryGenAiTooltipDismissedForSession();
    const {
        getClosedCount,
        tooltipEnabled: genAITooltipEnabled,
        maxCloseCount: maxGenAiTooltipCloseCount,
    } = useTryGenAiTooltip();

    const hasClosedInSession = dismissedRef.current;
    const shouldShowTooltip = useMemo(() => {
        const timesGenAITooltipClosed = getClosedCount();

        return Boolean(
            !disabled &&
                creativeType === CREATIVE_TYPE.IMAGE &&
                SUPPORTED_GEN_AI_TOOLTIP_FORMS.includes(formMode) &&
                genAITooltipEnabled &&
                timesGenAITooltipClosed < maxGenAiTooltipCloseCount &&
                !hasClosedInSession
        );
    }, [
        creativeType,
        disabled,
        formMode,
        genAITooltipEnabled,
        getClosedCount,
        hasClosedInSession,
        maxGenAiTooltipCloseCount,
    ]);

    const [showingGenAiTooltip, setShowingGenAiTooltip] = useState(shouldShowTooltip);

    useEffect(() => {
        setShowingGenAiTooltip(shouldShowTooltip);
    }, [shouldShowTooltip]);

    return [showingGenAiTooltip, setShowingGenAiTooltip];
};
