import classnames from 'classnames/bind';
import { ToggleSwitch } from 'tuui';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './exportableEntityCheckbox.module.scss';

const classNameBuilder = classnames.bind(styles);

const booleanCheckboxSelectedValues = [true];

export const ExportableEntityCheckbox = ({ entity, sheetName, isDisabled }) => {
    const { value: isEntityEnabled = true, onChange: onChangeEntityEnabled } = useFormFieldValue({
        field: `exportableEntities.${entity}.isEnabled`,
    });

    const { value: shouldFilterActive = true, onChange: onChangeShouldFilterActive } = useFormFieldValue({
        field: `exportableEntities.${entity}.shouldFilterActive`,
    });

    const isEntityDisabled = !isEntityEnabled;
    const isStatusFilterDisabled = isDisabled || isEntityDisabled;

    return (
        <div className={styles['container']}>
            <TextCheckbox
                independent
                disabled={isDisabled}
                label={`${sheetName}-checkbox`}
                labelClassName={styles['checkbox-label']}
                title={<FormattedMessage id={`excel.bulk.sheet.${sheetName}`} defaultMessage={sheetName} />}
                selectedValues={booleanCheckboxSelectedValues}
                value={isEntityEnabled}
                onSelectCheckbox={() => onChangeEntityEnabled(true)}
                onUnselectCheckbox={() => onChangeEntityEnabled(false)}
            />
            <label className={classNameBuilder('filter-status-section', { 'disabled-text': isStatusFilterDisabled })}>
                <ToggleSwitch
                    disabled={isStatusFilterDisabled}
                    checked={shouldFilterActive}
                    onChange={() => onChangeShouldFilterActive(prev => !prev)}
                />
                <FormattedMessage
                    id={`excel.bulk.download.${entity}.status.filter`}
                    defaultMessage={`Include active ${sheetName} only`}
                />
            </label>
        </div>
    );
};
