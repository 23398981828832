import React, { useCallback, useEffect, useState } from 'react';
import { fill, noop } from 'lodash';
import { Gallery } from 'taboola-ultimate-ui';
import { LoadingBar } from 'modules/campaigns/components';
import { fetchAIGeneratedImagesFromImage } from 'modules/campaigns/modules/creative-editor/flows';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ERROR_CODES } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { CreativeStudioContentBanner } from '../CreativeStudioContentBanner';
import { OriginalImageIndication } from '../OriginalImageIndication/OriginalImageIndication';
import { CREATIVE_STUDIO_EVENT_PREFIX, CREATIVE_STUDIO_MODE_EVENTS_MAP } from '../creativeStudioEventsPrefix';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { ImageVariationsGalleryItem } from './ImageVariationsGalleryItem';
import { ImageVariationsItemDropdown } from './ImageVariationsItemDropdown';
import styles from './imageVariationsContent.module.scss';

const MAX_RESULTS = 3;
const DEFAULT_IMAGE_RESULTS = fill(new Array(MAX_RESULTS), { id: null, url: null });
const flippedItemDropdownMenuStyle = { left: 'unset', right: '0px' };

const errorBanner = (
    <CreativeStudioContentBanner
        id="creative.studio.image.variations.banner.error"
        defaultMessage="We were unable to process the request. Please try again."
    />
);

const againstPolicyBanner = (
    <CreativeStudioContentBanner
        id="app.genai.againstpolicy.error"
        defaultMessage="The AI image generation is currently not possible as the thumbnail may not adhere to our policy standards."
    />
);

export const ImageVariationsContent = ({ getResultsImageMetricsAttributes = noop }) => {
    const { mode, originalImage, accountId, campaignId, setOriginalImage, selectedImageUrl, setSelectedImageUrl } =
        useCreativeStudioContext();
    const eventsLocationPrefix = CREATIVE_STUDIO_MODE_EVENTS_MAP[mode];
    const [imageResults, setImageResults] = useState(DEFAULT_IMAGE_RESULTS);
    const [imageResultsStatus, setImageResultsStatus] = useState(COMPONENT_STATUS.INITIAL);
    const isLoading =
        imageResultsStatus === COMPONENT_STATUS.LOADING || imageResultsStatus === COMPONENT_STATUS.INITIAL;
    const isError = imageResultsStatus === COMPONENT_STATUS.ERROR;
    const [isAgainstPolicy, setIsAgainstPolicy] = useState(false);
    const [numImageErrors, setNumImageErrors] = useState(0);
    const hasAllErrorImages = numImageErrors >= MAX_RESULTS;

    const onImageError = useCallback(() => setNumImageErrors(prev => prev + 1), []);

    const onGenerateVariations = useCallback(
        async image => {
            setNumImageErrors(0);
            setOriginalImage(image);
            setImageResultsStatus(COMPONENT_STATUS.LOADING);
            setImageResults(DEFAULT_IMAGE_RESULTS);
            setSelectedImageUrl(null);
            setIsAgainstPolicy(false);

            const start = Date.now();

            const { results, error = {} } = await fetchAIGeneratedImagesFromImage({
                accountId,
                referenceImage: image?.url,
                campaignId,
                setStatus: setImageResultsStatus,
                isImageVariations: true,
            });

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${eventsLocationPrefix}: Image Generation Duration`,
                value: Math.floor((Date.now() - start) / 1000),
            });

            setSelectedImageUrl(image.url);
            setImageResults(results.filter((_, i) => i < MAX_RESULTS));
            if (error.status === ERROR_CODES.UNPROCESSABLE_ENTITY) {
                setIsAgainstPolicy(true);
            }
        },
        [accountId, campaignId, eventsLocationPrefix, setOriginalImage, setSelectedImageUrl]
    );

    // on mount, generate variations of the original image
    useEffect(() => {
        onGenerateVariations(originalImage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles['container']}>
            {isError && (isAgainstPolicy ? againstPolicyBanner : errorBanner)}
            {hasAllErrorImages && (
                <CreativeStudioContentBanner
                    id="creative.studio.image.variations.banner.all.error.images"
                    defaultMessage="We were unable to process the request. Please try again later."
                />
            )}
            <div className={styles['grid-container']}>
                <Gallery gap="8px" columns={2} className={styles['results-gallery']}>
                    <div key={originalImage.id || 'original'} className={styles['gallery-item']}>
                        <OriginalImageIndication />
                        <ImageVariationsGalleryItem
                            image={originalImage}
                            onGenerateVariations={onGenerateVariations}
                            status={COMPONENT_STATUS.ACTIVE}
                            showLargePreview={false}
                            selected={selectedImageUrl && selectedImageUrl === originalImage.url}
                            addImageHandler={
                                isLoading
                                    ? noop
                                    : () => {
                                          setSelectedImageUrl(originalImage.url);
                                      }
                            }
                            getItemMetricsAttributes={getResultsImageMetricsAttributes}
                        />
                    </div>
                    {imageResults.map((image, i) => (
                        <div key={image.id || i} className={styles['gallery-item']}>
                            <ImageVariationsGalleryItem
                                onImageError={onImageError}
                                // the first item is the original
                                // thus, the 0th and 2nd item's dropdowns must be flipped
                                itemDropdownMenuStyle={i % 2 === 0 ? flippedItemDropdownMenuStyle : {}}
                                image={image}
                                onGenerateVariations={onGenerateVariations}
                                status={
                                    // if the image is not loading and has an id
                                    //  then its active, otherwise its loading
                                    !isLoading && image?.id ? COMPONENT_STATUS.ACTIVE : COMPONENT_STATUS.LOADING
                                }
                                showLargePreview={false}
                                selected={selectedImageUrl && selectedImageUrl === image.url}
                                addImageHandler={
                                    isLoading
                                        ? noop
                                        : () => {
                                              setSelectedImageUrl(image.url);
                                          }
                                }
                                getItemMetricsAttributes={getResultsImageMetricsAttributes}
                                isLoading={isLoading}
                                GalleryItemDropdown={ImageVariationsItemDropdown}
                                shouldHideOnError
                            />
                        </div>
                    ))}
                </Gallery>
                {isLoading && (
                    <LoadingBar
                        durationInSeconds={20}
                        loadingMessage={
                            <FormattedMessage
                                id="creative.studio.generate.long.loading.message"
                                defaultMessage="Wait with us please while we're working on generating your images"
                            />
                        }
                        containerClassName={styles['loading-bar']}
                    />
                )}
            </div>
        </div>
    );
};
