import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import YesNoRadioGroup from '../../../campaigns/components/Form/FormFields/YesNoRadioGroup/YesNoRadioGroup';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import ExcludeAudienceFieldTooltip from './ExcludeAudienceFieldTooltip';

export const ExcludeAudienceField = () => {
    const { value: excludeFromCampaigns, onChange: onExcludeFromCampaignsChange } = useFormFieldValue({
        field: 'excludeFromCampaigns',
    });

    return (
        <FormField
            inputId="exclude-audience"
            label={
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.exclude.audience.title"
                    defaultMessage="Exclude Audience from all Campaigns:"
                />
            }
            helpText={<ExcludeAudienceFieldTooltip />}
        >
            <YesNoRadioGroup
                selectedValue={excludeFromCampaigns}
                onChange={onExcludeFromCampaignsChange}
                id="exclude-audience"
                name="exclude-audience"
            />
        </FormField>
    );
};

export default ExcludeAudienceField;
