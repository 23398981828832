import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import useFitText from 'use-fit-text';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormattedNumber } from '../../../../taboola-common-frontend-modules/i18n';
import { SpentProgressBar } from '../SpentProgressBar/SpentProgressBar';
import styles from './balanceBase.module.scss';

const classNameBuilder = classnames.bind(styles);

export const BalanceBase = ({
    className,
    title,
    fundsAmount,
    currency,
    onAddFundsClick,
    addFundsTooltipMessage,
    progressBarTextMessageId,
    creditAmount,
    addFundsDisabled,
    children,
}) => {
    const [doneFontResizing, setDoneFontResizing] = useState(false);
    const onFontMeasureFinish = useCallback(() => {
        setDoneFontResizing(true);
    }, []);
    const { ref, fontSize } = useFitText({ logLevel: 'none', onFinish: onFontMeasureFinish });
    const spentAmount = useMemo(() => (creditAmount ? creditAmount - fundsAmount : null), [creditAmount, fundsAmount]);
    return (
        <div className={classNameBuilder('container', className)}>
            <div role="heading" aria-level={2} className={styles['title']}>
                {title}
            </div>
            <div className={styles['funds-section']}>
                <div
                    role="heading"
                    aria-level={3}
                    className={classNameBuilder('funds-amount', { visible: doneFontResizing })}
                    ref={ref}
                    style={{ fontSize }}
                >
                    <FormattedNumber
                        value={fundsAmount || 0}
                        variant="currency"
                        currency={currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                    />
                </div>
                {onAddFundsClick ? (
                    <div data-testid="button-div">
                        <Button
                            variant={Button.variant.primary}
                            size={Button.size.md}
                            onClick={onAddFundsClick}
                            disabled={addFundsDisabled}
                            className={classNameBuilder('bold-text', { visible: doneFontResizing })}
                        >
                            <FormattedMessage
                                id="billingAndPayments.balancePanel.addFunds.button"
                                defaultMessage="Add Funds"
                            />
                        </Button>
                        {addFundsTooltipMessage || addFundsDisabled ? (
                            <Tooltip arrow interactive position={TOOLTIP_POSITION.RIGHT_START}>
                                {addFundsDisabled ? (
                                    <FormattedMessage
                                        id="billingAndPayments.balancePanel.addFunds.disabled.tooltip.ongoing.request"
                                        defaultMessage="The previous request is still being processed. Once it's complete, you'll be able to add funds again."
                                    />
                                ) : (
                                    addFundsTooltipMessage
                                )}
                            </Tooltip>
                        ) : null}
                    </div>
                ) : null}
            </div>
            {spentAmount !== null ? (
                <SpentProgressBar
                    currency={currency}
                    spentAmount={spentAmount}
                    credit={creditAmount}
                    progressBarTextMessageId={progressBarTextMessageId}
                />
            ) : null}
            <div className={styles['bottom-section']}>{children}</div>
        </div>
    );
};
