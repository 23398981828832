'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.DelimiterWrapper = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _uuid = require('uuid');

var _DefaultDelimiter = require('./DefaultDelimiter');

var _DefaultDelimiter2 = _interopRequireDefault(_DefaultDelimiter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DelimiterWrapper = exports.DelimiterWrapper = function DelimiterWrapper(_ref) {
    var children = _ref.children,
        Delimiter = _ref.delimiter,
        delimiterClassName = _ref.delimiterClassName;

    var filteredChildren = children ? children.filter(function (child) {
        return child;
    }) : [];

    if (filteredChildren.length === 0) {
        return null;
    }

    var childrenLength = filteredChildren.length;
    var childrenWithDelimiters = new Array(childrenLength * 2 - 1);

    filteredChildren.forEach(function (child, i) {
        var newI = i * 2;
        var lastChild = i === childrenLength - 1;

        // add the child in between delimiters
        childrenWithDelimiters[newI] = child;
        // add the delimiter after the child as long as its not the last child
        if (!lastChild) {
            childrenWithDelimiters[newI + 1] = _react2.default.createElement(Delimiter, { key: (0, _uuid.v1)(), className: delimiterClassName });
        }
    });

    return childrenWithDelimiters;
};

DelimiterWrapper.propTypes = {
    children: _propTypes2.default.array,
    delimiter: _propTypes2.default.elementType,
    delimiterClassName: _propTypes2.default.string
};

DelimiterWrapper.defaultProps = {
    delimiter: _DefaultDelimiter2.default
};

exports.default = _react2.default.memo(DelimiterWrapper);