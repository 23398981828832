import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { STRIPE_ELEMENT } from 'modules/taboola-common-frontend-modules/stripe';
import { useStripeCardElement } from '../../../../../taboola-common-frontend-modules/stripe/hooks/useStripeCardElement';
import { StripeExpirationDate } from './StripeExpirationDate';
import commonStyles from '../../common.module.scss';

const ExpirationDateWithIndication = withIndication(StripeExpirationDate);

export const StripeExpirationDateField = () => {
    const { scrollRef, ...stripeElementProps } = useStripeCardElement(STRIPE_ELEMENT.CARD_EXPIRY);

    return (
        <FormField
            inputId="expirationDate"
            ref={scrollRef}
            containerClass={commonStyles['inline-field']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.expirationDate" defaultMessage="Exp. Date" />}
        >
            <ExpirationDateWithIndication {...stripeElementProps} />
        </FormField>
    );
};
