import React, { useCallback } from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { DeletableItem, useCollapsibleListContext } from 'taboola-ultimate-ui';
import styles from './campaignsSmartListItem.module.scss';

const CampaignsSmartListItem = ({ item, IconComponent, iconClassName, isItemDeletable = true }) => {
    const { description, label } = item;
    const { deleteItem } = useCollapsibleListContext();

    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);

    return (
        <div className={styles['container']}>
            {IconComponent && (
                <span className={styles['icon']}>
                    <IconComponent className={iconClassName} />
                </span>
            )}
            <DeletableItem
                contentClassName={styles['content']}
                onDelete={onDelete}
                isItemDeletable={isItemDeletable && !isNil(deleteItem)}
            >
                <div data-automation="campaigns-smart-list-item-label">{label}</div>
                <div>{description}</div>
            </DeletableItem>
        </div>
    );
};

CampaignsSmartListItem.propTypes = {
    item: PropTypes.shape({
        predicate: PropTypes.string,
        url: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
    IconComponent: PropTypes.elementType,
    iconClassName: PropTypes.string,
};

export default CampaignsSmartListItem;
