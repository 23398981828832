import React, { useCallback, useState } from 'react';
import { COMPONENT_STATUS } from '../../../../../../../../services/constants';
import { MediaDragAndDrop } from '../../../MediaDragAndDrop/MediaDragAndDrop';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import FromImageSubTab from './FromImageSubTab';

const FromImageSubTabWithDragAndDrop = ({ referenceImage, setReferenceImage, ...rest }) => {
    const [referenceImageStatus, setReferenceImageStatus] = useState(
        referenceImage ? COMPONENT_STATUS.ACTIVE : COMPONENT_STATUS.EMPTY
    );
    const [uploadError, setUploadError] = useState();
    const { campaignId } = useMediaTabsContext();

    const onDrop = useCallback(
        async urlResolver => {
            setReferenceImageStatus(COMPONENT_STATUS.LOADING);
            setUploadError(null);

            try {
                const { url } = await urlResolver();
                setReferenceImage(url);
                setReferenceImageStatus(COMPONENT_STATUS.ACTIVE);
            } catch (error) {
                setUploadError(error.message);
                setReferenceImageStatus(COMPONENT_STATUS.EMPTY);
            }
        },
        [setReferenceImage]
    );

    return (
        <MediaDragAndDrop onDrop={onDrop} campaignId={campaignId}>
            <FromImageSubTab
                referenceImage={referenceImage}
                setReferenceImage={setReferenceImage}
                referenceImageStatus={referenceImageStatus}
                setReferenceImageStatus={setReferenceImageStatus}
                imageError={uploadError}
                setErrorMessage={setUploadError}
                {...rest}
            />
        </MediaDragAndDrop>
    );
};

export default FromImageSubTabWithDragAndDrop;
