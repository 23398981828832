import React from 'react';
import { Spinner } from 'tuui';
import { NAMessage, ReachRange } from '../utils';
import styles from './viewableImpressionsField.module.scss';

export const ViewableImpressionsContent = ({
    noFiltersChosen,
    isError,
    isLoading,
    isReachOverLimit,
    isSmallReach,
    formattedMin,
    formattedMax,
    formattedReachLimit,
    isOnlyLowerBound,
    reachThreshold,
}) => {
    if (noFiltersChosen) {
        return <NAMessage />;
    }

    if (isLoading) {
        return (
            <div className={styles['spinner']}>
                <Spinner size={21} />
            </div>
        );
    }

    return (
        <ReachRange
            isSmallReach={isSmallReach}
            isReachOverLimit={isReachOverLimit}
            formattedReachLimit={formattedReachLimit}
            isError={isError}
            lowerBound={formattedMin}
            upperBound={formattedMax}
            isOnlyLowerBound={isOnlyLowerBound}
            reachThreshold={reachThreshold}
        />
    );
};
