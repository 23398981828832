import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useIsHeavyReport } from 'hooks';
import { isProgrammaticAccount } from 'modules/account-management/accountType';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAppEventContext } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { permissionsSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { useChatAgentContext } from 'modules/taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { useIntl, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../../../config/reportsBaseConfig';
import { useSelectedReportId } from '../../hooks';
import { useReportFiltersStatus } from '../../hooks/useReportFiltersStatus';
import { GridContext } from '../GridContextProvider/GridContextProvider';

const emptyReportConfig = {};
const CampaignGridContextProvider = ({
    children,
    reportConfig = emptyReportConfig,
    saveColumnStateOnGridChange,
    selectedAccountConfig,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userPermissions = useSelector(permissionsSelector);
    const intl = useIntl();
    const { inlineEditPermissions } = reportConfig;
    const hasInlineEditAccess = usePermissions(inlineEditPermissions);
    const isHeavyReport = useIsHeavyReport();
    const [selectedReport] = useSelectedReportId();
    const selectedAccount = useAccount();
    const { isActive: isActiveAccount, currency, timeZoneName, accountName, type: accountType } = selectedAccount;
    const [isFilterActive] = useReportFiltersStatus();
    const isGridEditDisabled =
        !isActiveAccount ||
        !hasInlineEditAccess ||
        isHeavyReport ||
        (isProgrammaticAccount(selectedAccount) && selectedReport === REPORT_TYPE.CREATIVE);
    const [cellToEdit, setCellToEdit] = useState(null);
    const { registerGrid } = useChatAgentContext();
    const { push: pushAppEvent } = useAppEventContext();
    const value = useMemo(
        () => ({
            isGridEditDisabled,
            isFilterActive,
            cellToEdit,
            dispatch,
            history,
            onColumnStateChanged: saveColumnStateOnGridChange,
            userPermissions,
            intl,
            selectedAccountConfig,
            currency,
            timeZoneName,
            accountName,
            accountType,
            notPinnedLabel: (
                <FormattedMessage id="app.campaigns.reports.grid.column.menu.unpin" defaultMessage="Unpin" />
            ),
            leftPinnedLabel: (
                <FormattedMessage id="app.campaigns.reports.grid.column.menu.pin.left" defaultMessage="Pin Left" />
            ),
            pushAppEvent,
        }),
        [
            isGridEditDisabled,
            isFilterActive,
            cellToEdit,
            dispatch,
            history,
            saveColumnStateOnGridChange,
            userPermissions,
            selectedAccountConfig,
            intl,
            currency,
            timeZoneName,
            accountName,
            accountType,
            pushAppEvent,
        ]
    );

    useEffect(() => registerGrid({ setCellToEdit }), [registerGrid]);

    return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
};

CampaignGridContextProvider.propTypes = { reportConfig: PropTypes.object.isRequired };

export default CampaignGridContextProvider;
