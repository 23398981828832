import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useAvailableItems from 'hooks/useAvailableItems';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { permissionsSelector } from 'modules/taboola-common-frontend-modules/authentication';

export const useAvailableRoutes = routes => {
    const selectedAccount = useAccount();
    const permissions = useSelector(permissionsSelector);
    const list = useMemo(() => Object.values(routes), [routes]);
    const conditionData = useMemo(() => ({ permissions, selectedAccount }), [permissions, selectedAccount]);
    const availableRoutes = useAvailableItems(list, conditionData);

    return availableRoutes;
};

export default useAvailableRoutes;
