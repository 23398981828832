import { captureException, trackEvent } from '../log-rocket/logRocketService';
import InvalidContainerIdError from './InvalidContainerIdError';
import { config } from './config';
import { EVENT_KEY } from './config/eventKey';
import injectSnippets from './services/snippetsInjector';
import { getPageName, getPageUrl, getReportIdFromQuery, getDatePresetFromQuery } from './urlPageNameUtil';

export const EMPTY_DATA = Object.fromEntries(Object.keys(EVENT_KEY).map(p => [p, '']));
const DATA_KEY_MAP = Object.keys(EMPTY_DATA).reduce((res, key) => {
    res[key] = true;
    return res;
}, {});

let isInitialized = false;
let dynamicConfig = {};

const validateInitialized = () => {
    if (!isInitialized) {
        throw new Error('gtmManager must be initialized before using it.');
    }
    if (!window.dataLayer) {
        throw new Error('dataLayer was not present. Did you remove it?');
    }
};

const extendWithDefaults = (data = {}) => {
    const wrongKey = Object.keys(data).find(key => !DATA_KEY_MAP[key]);

    if (wrongKey) {
        console.error(
            `Wrong data metrics key (${wrongKey}) with data (${data[wrongKey]}). Consider extending gtmManager EMPTY_DATA structure or fix data-metrics key`
        ); //eslint-disable-line
    }

    return { ...EMPTY_DATA, ...data };
};

const enrichTrackingData = (data = {}) => {
    return {
        pageName: data?.pageName || getPageName(),
        tableDatePreset: data?.tableDatePreset || getDatePresetFromQuery(),
        reportId: data?.reportId || getReportIdFromQuery(),
    };
};

export const gtmTracker = {
    init(containerId, initialDataLayer) {
        if (typeof containerId !== 'string' || containerId.length === 0) {
            throw new InvalidContainerIdError(containerId);
        }
        injectSnippets(containerId, initialDataLayer);

        isInitialized = true;
    },
    trackEvent(eventName, data) {
        validateInitialized();

        const event = { event: eventName, ...extendWithDefaults(data), ...enrichTrackingData(data) };

        window.dataLayer.push(event);
        trackEvent(eventName, event);
    },
    trackError(error) {
        validateInitialized();

        const pagePath = getPageUrl();
        const pageName = getPageName();
        const event = {
            ...EMPTY_DATA,
            event: 'error',
            message: error.message,
            pageName,
            pagePath,
        };

        if (config.getSendClientSideErrorsWithStacktrace()) {
            event.stack = error.stack || error.componentStack;
        }

        window.dataLayer.push(event);
        captureException(error, {
            tags: {
                // additional data to be grouped as "tags"
            },
            extra: {
                // additional arbitrary data associated with the event
                pageName,
            },
        });
    },
    set(event) {
        validateInitialized();

        window.dataLayer.push(event);
        trackEvent(event?.event, event);
    },
    updateConfig(passedConfig) {
        dynamicConfig = { ...dynamicConfig, ...passedConfig };
    },
};
