const BRAND_SAFETY_TYPES = {
    NONE: 'NONE',
    IAS: 'IAS',
    DV: 'DV',
    GENERAL_SENSITIVE_TOPICS: 'GENERAL_SENSITIVE_TOPICS',
};
export const BRAND_SAFETY_TYPES_OPTIONS = [
    { id: BRAND_SAFETY_TYPES.DV, value: BRAND_SAFETY_TYPES.DV, permissions: 'ADS_CONSOLE_THIRD_PARTY_BRAND_SAFETY_DV' },
    { id: BRAND_SAFETY_TYPES.IAS, value: BRAND_SAFETY_TYPES.IAS },
    { id: BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS, value: BRAND_SAFETY_TYPES.GENERAL_SENSITIVE_TOPICS },
];

export default BRAND_SAFETY_TYPES;
