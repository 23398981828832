"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgHide(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M19.703 5.711l-3.262 3.28h1.564a1.003 1.003 0 0 1 0 2.007H15.01A1.998 1.998 0 0 1 13.011 9V5.984a.999.999 0 1 1 1.998 0v1.598l3.27-3.288a1.014 1.014 0 0 1 1.428 0 .999.999 0 0 1-.004 1.417zm-9.688 13.304a.999.999 0 0 1-.998-.999v-1.598L5.73 19.706a1.023 1.023 0 0 1-1.435 0 .993.993 0 0 1 0-1.415l3.277-3.28H6.005a1.003 1.003 0 0 1 0-2.007h3.013c1.099 0 1.991.89 1.996 1.989v3.016a.999.999 0 0 1-.999 1.006z"
            })
        )
    );
}
exports.default = SvgHide;