import React from 'react';
import { FormattedNumberCellRenderer } from './index';

export const AudienceMatchRateCellRenderer = data => {
    return data.value === null ? (
        <div>-</div>
    ) : (
        // eslint-disable-next-line react/style-prop-object
        <FormattedNumberCellRenderer value={data.value} style="percent" maximumFractionDigits={2} />
    );
};
