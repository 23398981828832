import React from 'react';
import { useRecommendationService } from 'modules/campaigns/modules/performance-recommendations/hooks/useRecommendationService';
import { transformMyAudienceTargetingFromDB } from 'modules/campaigns/services/transformers/myAudienceTargetingTransformer';
import { FormattedRelativeTime } from '../../../../../modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';
import { NotificationCardContent } from '../NotificationCardContent/NotificationCardContent';

export const AudienceNotification = ({
    notification,
    closeNotificationCenter,
    timeDiffInMinutes,
    recommendationType,
}) => {
    //TODO should be after fix in backend that reutrns also the recommendation  object
    // const { referredEntityObject: recommendation } = notification;
    // const { recommendationData: actionParamData } = useRecommendationActionParam(recommendation);
    //     const recommendationData = actionParamData ? transformMyAudienceTargetingFromGW(actionParamData) : undefined;
    const {
        attributes: { campaignOverrideValues },
        referredEntityId,
    } = notification;

    const { createCampaignWithRecommendation: onCardClick } = useRecommendationService();

    if (!campaignOverrideValues) {
        return null;
    }

    const recommendationData = transformMyAudienceTargetingFromDB(campaignOverrideValues);

    return (
        <NotificationCard
            notification={notification}
            cardClick={onCardClick}
            cardClickParams={{
                recommendation: { id: referredEntityId, type: recommendationType },
                recommendationData,
            }}
            closeNotificationCenter={closeNotificationCenter}
        >
            <NotificationCardContent
                notification={notification}
                dataMetricsComponent="NotificationActionCard"
                formattedMessageId="app.notificationsCenter.AudienceNotificationDetails"
                formattedMessageValues={{
                    notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
                }}
            />
        </NotificationCard>
    );
};
