import React, { useCallback, useEffect } from 'react';
import { identity, isEqual } from 'lodash';
import uuid from 'uuid/v1';
import { FormField } from 'taboola-ultimate-ui';
import { MAX_TITLES_COUNT } from 'modules/campaigns/modules/creative-creator/config';
import { AI_VARIATIONS_FIELD_NAMES } from 'modules/campaigns/modules/creative-creator/constants/aiVariationsConstants';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormDataContext, useFormFieldValue } from '../../../../../../../taboola-common-frontend-modules/formData';
import { useFetchPageMetadata } from '../../../UrlList/useFetchPageMetadata';
import UrlFieldDescription from '../../../UrlsField/UrlFieldDescription';
import UrlsFieldTooltip from '../../../UrlsField/UrlsFieldTooltip';
import { AiVariationsUrlFieldDescription } from './AiVariationsUrlFieldDescription';
import { AiVariationsUrlItem } from './AiVariationsUrlItem';
import styles from '../../../UrlList/urlList.module.scss';

export const AiVariationsUrlField = ({ className, showSubDescription, disabled }) => {
    const {
        value: urls,
        onChange: setUrls,
        isDirty,
    } = useFormFieldValue({
        field: 'urls',
    });
    const { initialData } = useFormDataContext();
    const { onChange: setTitles } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.TITLES });

    const addContentItem = useCallback(() => uuid(), []);
    const updateContent = useCallback(
        ({ title = '' }) => {
            setTitles(prev => {
                // if there's no titles, append a title (shouldn't happen b/c we always populate one empty string)
                if (prev.length === 0) {
                    return [title];
                }
                const lastItem = prev[prev.length - 1];

                // if we're at the max amount of titles and the last item is not empty, return the existing list
                if (prev.length === MAX_TITLES_COUNT && lastItem) {
                    return prev;
                }

                // if the last item is an empty string, push the last item over and insert the new title if there's space
                if (!lastItem) {
                    const result = [...prev];
                    // if we're at max titles, remove the empty item
                    const numToDelete = prev.length === MAX_TITLES_COUNT ? 1 : 0;

                    result.splice(prev.length - 1, numToDelete, title);

                    return result;
                }

                // otherwise append to end of list (shouldn't happen unless the user is typing in
                // the last title while the metadata finishes loading)
                return [...prev, title];
            });
        },
        [setTitles]
    );

    const fetchPageMetadata = useFetchPageMetadata(addContentItem, updateContent);

    // TODO: should be removed - after DEV-130554
    useEffect(() => {
        if (!isDirty && !isEqual(initialData?.urls, urls)) {
            setUrls(identity);
        }
    }, [urls, isDirty, initialData.urls, setUrls]);
    return (
        <FormField
            label={<FormattedMessage id="creative.creator.urls.title" defaultMessage="Landing Page URL" />}
            helpText={<UrlsFieldTooltip />}
            inputId="creative-creator-landing-page"
            containerClass={className}
            description={<AiVariationsUrlFieldDescription />}
            subDescription={showSubDescription && <UrlFieldDescription />}
        >
            <FormNamespaceProvider field="urls">
                <div data-testid="ai-variation-urls-field">
                    {urls.map((item, index) => (
                        <div key={item.id} className={styles['url-item']}>
                            <FormNamespaceProvider field={index} itemKey={item.id}>
                                <AiVariationsUrlItem
                                    existingItems={urls}
                                    fetchPageMetadata={fetchPageMetadata}
                                    disabled={disabled}
                                />
                            </FormNamespaceProvider>
                        </div>
                    ))}
                </div>
            </FormNamespaceProvider>
        </FormField>
    );
};
