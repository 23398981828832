import React from 'react';
import { CollapsibleCardContextConsumer } from './CollapsibleCardContext';

const withCollapsibleCardContextConsumer = InnerComponent => props => {
    const { scrollAreaRef: _, ...propWithoutAreaScroll } = props;

    return (
        <CollapsibleCardContextConsumer>
            {state => <InnerComponent {...propWithoutAreaScroll} {...state} />}
        </CollapsibleCardContextConsumer>
    );
};

export default withCollapsibleCardContextConsumer;
