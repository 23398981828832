import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useBidStrategyDescriptionMessageId } from './useBidStrategyDescriptionMessageId';
import styles from '../bidStrategyV2.module.scss';

const classNameBuilder = classnames.bind(styles);
export const BidStrategyItem = ({ data: { value, label }, selectProps: { availableBidStrategies, menuIsOpen } }) => {
    const descriptionMessageId = useBidStrategyDescriptionMessageId(value, availableBidStrategies);
    return (
        <div className={styles['bid-item']}>
            <div>{label}</div>
            <div className={classNameBuilder('description', 'description-item')}>
                {menuIsOpen && <FormattedMessage id={descriptionMessageId}></FormattedMessage>}
            </div>
        </div>
    );
};

BidStrategyItem.propTypes = {
    data: PropTypes.object,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
};
