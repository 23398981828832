const checker = (violatingFields, otherFields) =>
    otherFields.every(v => violatingFields.includes(v)) && violatingFields.every(v => otherFields.includes(v));

export const validateAudienceTargeting =
    (fieldName, otherFields) =>
    (value, options, { data }) => {
        if (!data.fieldLength) {
            return true;
        }
        const violatingFields = otherFields.filter(otherField => otherField !== fieldName && data[otherField]);

        return !checker(violatingFields, otherFields);
    };
