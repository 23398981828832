import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { get, isNil } from 'lodash';
import { useDimension } from 'hooks';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import usePermissions from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignGroupApi } from 'services/api';
import { PERMISSIONS_OPERATORS } from '../../../../../../taboola-common-frontend-modules/authentication';
import { ENTITY } from '../../../../../config';
import { isOnlySponsored } from '../../../../../config/reportsBaseConfig';
import { toGWtransformers } from '../../../../../flows/flowsUtils';
import { useCampaignGroupExtensionEnabled } from '../../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { createCampaignsGroupSuccess } from '../../../../campaigns-group/actions';
import { campaignsGroupMapSelector } from '../../../../campaigns-group/selectors';

const errorIndication = {
    message: <FormattedMessage id="campaign.group.fetch.error" defaultMessage="Unable to fetch Campaign Group." />,
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

export const useFetchedCampaignGroup = campaignGroupId => {
    const dispatch = useDispatch();
    const account = useAccount();
    const accountName = account?.accountName;
    const cache = useSelector(campaignsGroupMapSelector);
    const { getCampaignGroup } = useCampaignGroupApi();
    const isPermitted = usePermissions(['VIEW_CAMPAIGN_GROUP', 'EDIT_CAMPAIGN_GROUP'], PERMISSIONS_OPERATORS.OR);
    const [dimension] = useDimension();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const isConfigured = useMemo(
        () => campaignGroupExtensionEnabled && isOnlySponsored({ dimension, selectedAccount: account }),
        [account, campaignGroupExtensionEnabled, dimension]
    );
    const { data } = useQuery(
        ['CAMPAIGN_GROUP', accountName, campaignGroupId],
        async () => {
            const rawCampaignGroup = await getCampaignGroup(accountName, campaignGroupId);
            const campaignGroup = toGWtransformers[ENTITY.CAMPAIGNS_GROUP](rawCampaignGroup);
            dispatch(createCampaignsGroupSuccess(campaignGroup));
            return campaignGroup;
        },
        {
            enabled: !isNil(campaignGroupId) && isPermitted && isConfigured,
            onError: () => dispatch(addIndication(errorIndication)),
        }
    );

    const campaignGroup = data ?? get(cache, campaignGroupId);

    return campaignGroup;
};
