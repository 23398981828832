"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgFilter(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M15.002 12.09v5.41a1 1 0 0 1-.649.936l-4 1.5A1 1 0 0 1 9.002 19v-6.91L4.249 6.659C3.684 6.012 4.143 5 5.002 5h14c.86 0 1.318 1.012.753 1.659l-4.753 5.431zm-2-.376a1 1 0 0 1 .247-.658L16.8 7H7.205l3.549 4.056a1 1 0 0 1 .247.658v5.843l2-.75v-5.093z"
            })
        )
    );
}
exports.default = SvgFilter;