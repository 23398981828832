import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import ContentFieldSubDescription from '../ContentFieldSubDescription/ContentFieldSubDescription';
import ContentFieldTooltip from './ContentFieldTooltip';
import { ContentList } from './ContentList';
import styles from './contentField.module.scss';

const ContentField = ({ className, ...rest }) => {
    return (
        <FormField
            label={
                <FormattedMessage
                    id="creative.creator.content.description"
                    defaultMessage="Include at least 2 titles to increase your chances for a better performance (no more than 10), 35 - 45 characters is recommended."
                />
            }
            labelClass={styles['content-label']}
            subDescription={<ContentFieldSubDescription />}
            helpText={<ContentFieldTooltip />}
            inputId="creative-creator-content"
            containerClass={className}
        >
            <ContentList {...rest} />
        </FormField>
    );
};

ContentField.propTypes = {
    className: PropTypes.string,
    maxItems: PropTypes.number,
    items: PropTypes.array,

    addItem: PropTypes.func,
    duplicateItem: PropTypes.func,
    deleteItem: PropTypes.func,

    updateTitle: PropTypes.func,
    updateDescription: PropTypes.func,
    updateCta: PropTypes.func,

    shouldRenderDescription: PropTypes.bool,
    shouldRenderCta: PropTypes.bool,
};

ContentField.defaultProps = {
    className: '',
    items: [],
};

export default ContentField;
