import { isNil } from 'lodash';
import { createSelector } from 'reselect';
import { useSelectedCampaignId } from 'modules/campaigns/hooks/useSelectedCampaignId';
import { allCampaignsNameIdSelector } from 'selectors';
import { createDeepEqualSelector } from 'selectors/baseSelectors';
import AllCampaignsObject from '../config/allCampaignsObject';
import { campaignsStateSelector, campaignsMapSelector } from './baseSelectors';

/**
 * @deprecated - use useSelectedCampaignId
 */
export const selectedCampaignIdSelector = useSelectedCampaignId.createSelector();

export const selectedCampaignInnerSelector = createSelector(
    selectedCampaignIdSelector,
    campaignsMapSelector,
    allCampaignsNameIdSelector,
    (selectedCampaignId, campaignsMap, allCampaignsNameId) => {
        if (selectedCampaignId === AllCampaignsObject.id || isNil(selectedCampaignId)) {
            return { id: AllCampaignsObject.id, name: allCampaignsNameId };
        }

        return campaignsMap[selectedCampaignId] || { id: selectedCampaignId, name: '' };
    }
);

export const selectedCampaignSelector = createDeepEqualSelector(
    selectedCampaignInnerSelector,
    selectedCampaign => selectedCampaign
);

export const editedCreativeSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.creativeBeingEdited
);

export const campaignsModuleStatusSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.moduleStatus
);

export const campaignPickerSortTypeSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.campaignPickerSortingType
);

export const campaignPickerSortFieldSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.campaignPickerSortingField
);

export const campaignPickerSortOptionsSelector = createSelector(
    campaignPickerSortTypeSelector,
    campaignPickerSortFieldSelector,
    (type, field) => ({ type, field })
);

export const campaignPickerPinnedSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.isCampaignPickerPinned
);

export const campaignPickerForceReloadFlagSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.forceCampaignPickerReloadFlag
);

export const lastCreatedCampaignIdSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.lastCreatedCampaignId
);

export const listOfBrowsersSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.listOfBrowsers
);

export const brandSafetyCategoriesSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.brandSafetyCategories
);
