import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../../../taboola-common-frontend-modules/formData';
import { Check } from './icons/Check';
import { usePromptHelperOptionsModal } from './usePromptHelperOptionsModal';
import styles from './promptHelper.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PromptHelperCategoryModalButton = ({ category, options, icon, setHoveredCategory }) => {
    const { value: optionValue, onChange: optionOnChange } = useFormFieldValue({ field: `promptHelper.${category}` });
    const { openPromptHelperModal } = usePromptHelperOptionsModal();

    return (
        <Button
            className={classNameBuilder('button', { selected: !!optionValue })}
            size={Button.size.md}
            variant={Button.variant.text}
            onClick={() => {
                openPromptHelperModal(category, options, optionValue, optionOnChange);
            }}
            onMouseOver={() => {
                setHoveredCategory(category);
            }}
            onMouseOut={() => {
                setHoveredCategory(null);
            }}
        >
            {!!optionValue ? <Check /> : icon}
            <FormattedMessage
                id={`creative.editor.tab.aiGenerated.fromPrompt.helper.categories.${category}`}
                defaultMessage={category}
            />
        </Button>
    );
};
