import React, { useRef, useEffect, forwardRef, useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import classnames from 'classnames/bind';
import { isNil, noop } from 'lodash';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useElementSize } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import {
    LIST_TOP_PADDING,
    DEFAULT_LIST_ITEM_HEIGHT,
    SELECTED_ITEM_PADDING,
    ITEM_PADDING,
    DEFAULT_SELECTED_ITEM_HEIGHT,
} from '../constants';
import CollapsiblePickerListItem from './CollapsiblePickerListItem';
import styles from './collapsiblePickerList.module.scss';

const classNameBuilder = classnames.bind(styles);

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
    <ul
        ref={ref}
        style={{
            ...style,
            height: `${parseFloat(style.height) + LIST_TOP_PADDING * 2}px`,
        }}
        {...rest}
    />
));

const defaultGetItemSize = ({ isSelected }) => {
    return isSelected ? DEFAULT_SELECTED_ITEM_HEIGHT + SELECTED_ITEM_PADDING : DEFAULT_LIST_ITEM_HEIGHT + ITEM_PADDING;
};

const DefaultEmptyList = () => (
    <div className={styles['no-results-item']}>
        <FormattedMessage id="app.campaigns.noResults" defaultMessage="No results found..." />
    </div>
);
const DefaultLoadingItem = () => (
    <ContentLoader height={60} width={300} speed={2} className={styles['loading-item']}>
        <rect x="15" y="8" rx="8" ry="8" width="250" height="13" />
        <rect x="15" y="30" rx="8" ry="8" width="182" height="13" />
    </ContentLoader>
);

const CollapsiblePickerList = ({
    className,

    total,
    paginationStatus,
    disabled,

    items,
    listItemComponent: ListItemComponent,
    listItemProps,
    selectedItem,
    isSelectedItem,

    emptyListComponent: EmptyListComponent,
    loadingListItemComponent: LoadingListItemComponent,

    getItemSize,
    getItemKey,
    loadNextPage,
    isItemLoaded,

    onSelect,

    virtualizationState,
}) => {
    const windowRef = useRef(null);
    const scrollerRef = useRef(null);
    const { height, ref: containerRef } = useElementSize();
    const hasEmptyTotal = isNil(total) || total === 0;
    const hasEmptyResults = disabled || (paginationStatus === COMPONENT_STATUS.ACTIVE && hasEmptyTotal);

    const finalTotalSize = hasEmptyResults ? 1 : total;

    const getItemSizeCallback = useCallback(
        index => getItemSize({ items, isSelected: isSelectedItem(items[index], selectedItem), index }),
        [items, selectedItem, isSelectedItem, getItemSize]
    );

    useEffect(() => {
        if (windowRef.current) {
            windowRef.current.resetAfterIndex(0);
        }
        if (scrollerRef.current) {
            scrollerRef.current.resetloadMoreItemsCache();
        }
    }, [total, virtualizationState]);

    return (
        <div ref={containerRef} className={classNameBuilder('container', className)}>
            <InfiniteLoader
                ref={scrollerRef}
                isItemLoaded={isItemLoaded}
                itemCount={finalTotalSize}
                loadMoreItems={loadNextPage}
            >
                {({ onItemsRendered, ref: setListRef }) => (
                    <List
                        innerElementType={innerElementType}
                        height={height}
                        width="100%"
                        itemCount={finalTotalSize}
                        onItemsRendered={onItemsRendered}
                        ref={list => {
                            setListRef(list);
                            windowRef.current = list;
                        }}
                        estimatedItemSize={DEFAULT_LIST_ITEM_HEIGHT}
                        itemSize={getItemSizeCallback}
                    >
                        {({ index, style }) => {
                            const styleWithTopPadding = {
                                ...style,
                                top: `${parseFloat(style.top) + LIST_TOP_PADDING}px`,
                            };
                            // if no items are present, then render this
                            if (hasEmptyResults) {
                                return (
                                    <li style={styleWithTopPadding}>
                                        <EmptyListComponent style={styleWithTopPadding} />
                                    </li>
                                );
                            }
                            if (!isItemLoaded(index) || !items[index]) {
                                return (
                                    <li style={styleWithTopPadding}>
                                        <LoadingListItemComponent />
                                    </li>
                                );
                            }

                            return (
                                <li style={styleWithTopPadding}>
                                    <ListItemComponent
                                        key={getItemKey(items[index])}
                                        index={index}
                                        windowRef={windowRef}
                                        getItemKey={getItemKey}
                                        item={items[index]}
                                        isSelected={isSelectedItem(items[index], selectedItem)}
                                        selectedItem={selectedItem}
                                        onSelect={onSelect}
                                        listItemProps={listItemProps}
                                    />
                                </li>
                            );
                        }}
                    </List>
                )}
            </InfiniteLoader>
        </div>
    );
};

CollapsiblePickerList.propTypes = {
    /* list of items to select */
    items: PropTypes.array,
    /* selected item from list */
    selectedItem: PropTypes.any,
    /* func to determine if an item from items is the selected item */
    isSelectedItem: PropTypes.func,
    /* total number of items */
    total: PropTypes.number,
    /* func called when next page should be loaded */
    loadNextPage: PropTypes.func,
    /* given a specific index in the list of items, return true if the index is loaded */
    isItemLoaded: PropTypes.func,
    /* component stays in loading state if not active */
    paginationStatus: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    /* func called when a list item is selected (passes entire item) */
    onSelectListItem: PropTypes.func,
    /* given an index in items, return the size of the item */
    getItemSize: PropTypes.func,
    /* given an item from items, return the key of the item */
    getItemKey: PropTypes.func,
    /* when this prop changes, virtualization state is reset */
    virtualizationState: PropTypes.any,
    /* passed to ListItemComponent through selectProps */
    listItemProps: PropTypes.object,
    /* override for the list item renderer */
    listItemComponent: PropTypes.any,
    /* override for the empty list component */
    emptyListComponent: PropTypes.any,
    /* override for the loading list item component */
    loadingListItemComponent: PropTypes.any,
};

CollapsiblePickerList.defaultProps = {
    onSelectListItem: noop,
    getItemSize: defaultGetItemSize,
    items: [],
    isSelectedItem: isEqual,
    listItemComponent: CollapsiblePickerListItem,
    emptyListComponent: DefaultEmptyList,
    loadingListItemComponent: DefaultLoadingItem,
};

export default CollapsiblePickerList;
