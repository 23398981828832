import React from 'react';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import MarketingObjective from '../../../common-campaign-form/components/MarketingObjective/MarketingObjective';
import { useCampaignGroupExtensionEnabled } from '../../hooks/useCampaignGroupExtensionEnabled';
import { GROUP_MARKETING_OBJECTIVES } from './config';

export const CampaignsGroupMarketingObjective = () => {
    const { formatMessage } = useIntl();
    const { mode } = useFormDataContext();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    if (campaignGroupExtensionEnabled) {
        return null;
    }

    const helpText =
        mode === FORM_MODES.EDIT
            ? formatMessage({
                  id: 'campaigns.group.disabled.marketing.objective.helpText',
                  defaultMessage:
                      'All Campaigns within a group must have the same Marketing Objective. In addition to this, you cannot adjust a Campaign Groups Marketing Objective once it has been saved. Please set up a new Campaign Group if you would like to adjust your Marketing Objective.',
              })
            : formatMessage({
                  id: 'campaigns.group.enabled.marketing.objective.helpText',
                  defaultMessage:
                      'Based on your marketing objective, Taboola will optimize your Campaign Group for the best possible results.',
              });
    const descriptionText = formatMessage({
        id: 'campaigns.group.marketing.objective.description',
        defaultMessage: 'Select a primary goal for your Campaign Group.',
    });

    return (
        <MarketingObjective
            config={GROUP_MARKETING_OBJECTIVES}
            disabled={mode === FORM_MODES.EDIT}
            helpText={helpText}
            descriptionText={descriptionText}
        />
    );
};
