import React from 'react';
import { ErrorOutlinedIcon } from 'tuui';
import { COMMON_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { withRecommendationFormValidationDataProvider } from '../../../../../taboola-common-frontend-modules/formData/withRecommendationFormValidationDataProvider';
import { TargetCpaInput } from '../../../common-campaign-form/components/BidStrategy/TargetCpaRadio/TargetCpaInput';
import { useUnrealisticTCPARecommendation } from '../../hooks/useUnrealisticTCPARecommendation';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import { PerformanceRecommendationCard } from '../PerformanceRecommendationCard';
import styles from './unrealisticTCPAPerformanceRecommendation.module.scss';

const WarningIcon = () => <ErrorOutlinedIcon className={styles['warning-icon']} />;

const UnrealisticTCPAPerformanceRecommendation = ({ recommendation }) => {
    const {
        isApplyDisabled,
        recommendationId,
        recommendationMetadata,
        navigateToEditCampaign,
        apply,
        dismiss,
        additionalDependencies,
    } = useUnrealisticTCPARecommendation({ recommendation });

    const shouldRecommendTarget = useConfigMatch(
        {},
        { [COMMON_FLAGS.UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET]: 'true' }
    );

    const descriptionCode = shouldRecommendTarget
        ? 'performance-recommendations.unrealistic-tcpa-with-target-recommendation.description'
        : 'performance-recommendations.unrealistic-tcpa.description';

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.unrealistic-tcpa.title"
            descriptionCode={descriptionCode}
            messageParams={recommendationMetadata}
            iconImg={WarningIcon}
        >
            {shouldRecommendTarget ? (
                <div className={styles['form-field-container']}>
                    <TargetCpaInput disabled={apply.isSuccess} additionalDependencies={additionalDependencies} />
                </div>
            ) : null}
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToEditCampaign}
                    recommendation={recommendation}
                    textCode="performance-recommendations.set-cpa.edit-campaign.button"
                    dataMetricsComponent="UnrealisticTCPARecommendationEditCampaignButton"
                />
                {shouldRecommendTarget ? (
                    <ApplyButton
                        textCode="performance-recommendations.unrealistic-tcpa.action"
                        isApplied={apply.isSuccess}
                        isApplyLoading={apply.isLoading}
                        tooltipCode="performance-recommendations.unrealistic-tcpa.action.tooltip"
                        onClick={apply.invoke}
                        disabled={isApplyDisabled}
                        recommendation={recommendation}
                    />
                ) : null}
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

const UnrealisticTCPAPerformanceRecommendationWithForm = withRecommendationFormValidationDataProvider(
    UnrealisticTCPAPerformanceRecommendation
);
export { UnrealisticTCPAPerformanceRecommendationWithForm as UnrealisticTCPAPerformanceRecommendation };
