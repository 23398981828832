import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './brandSafetyTypeDropdownOption.module.scss';

const MSG_ID_PREFIX = 'campaign.editor.targeting.3rd.party.brand.safety';

function BrandSafetyTypeDropdownOption({ data: { value } }) {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: `${MSG_ID_PREFIX}.type.${value}`,
    });

    return (
        <div className={styles['container']}>
            <span className={styles['label']}>{label}</span>
        </div>
    );
}

BrandSafetyTypeDropdownOption.propTypes = {
    data: PropTypes.object,
};

export default BrandSafetyTypeDropdownOption;
