'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _StatusIndicatorTypes = require('./StatusIndicatorTypes');

var _StatusIndicatorTypes2 = _interopRequireDefault(_StatusIndicatorTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'statusIndicator__taboola-svg-icon___3kDs4',
    'indicator': 'statusIndicator__indicator___3pvgy'
};

/**
 * @deprecated - use StatusIndicator from tuui
 */

var StatusIndicator = function StatusIndicator(_ref) {
    var className = _ref.className,
        pulse = _ref.pulse,
        type = _ref.type;
    return _react2.default.createElement('status-indicator', Object.assign({
        'class': styles['indicator'] + ' ' + className,
        pulse: pulse
    }, _defineProperty({}, type, type)));
};

StatusIndicator.propTypes = {
    /** Class Name to be added to this component */
    className: _propTypes2.default.string,
    /** status indicator type */
    type: _propTypes2.default.oneOf(Object.values(_StatusIndicatorTypes2.default)),
    /** is active */
    pulse: _propTypes2.default.bool
};

StatusIndicator.defaultProps = {
    className: '',
    type: _StatusIndicatorTypes2.default.POSITIVE
};

exports.default = StatusIndicator;