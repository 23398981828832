'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _lodash = require('lodash.clonedeep');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.keyby');

var _lodash4 = _interopRequireDefault(_lodash3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * This function is merging footer configurations with grid column configuration using `field` as a key for merging.
 * Merge of all props is done with Object.assign mechanism, except `type` property.
 * `type` of footer config is appended to grid column config `type`.
 * `type` is appended because footer config should contain rest of functionality defined by grid column config.
 * The footer `type` will be set last in the list of configurations in order to override all props of previous types.
 */
var mergeDefs = function mergeDefs(footerColumnDefsMap, def) {
    var copyDef = (0, _lodash2.default)(def);
    var footerColumnDef = footerColumnDefsMap[def.field];
    if (!footerColumnDef) {
        return copyDef;
    }

    var copyFooterColumnDef = (0, _lodash2.default)(footerColumnDef);
    var defType = copyDef.type;
    // Copy all props from footer configs
    copyDef = Object.assign({}, copyDef, copyFooterColumnDef);
    if (!defType || !copyFooterColumnDef.type) {
        return copyDef;
    }

    // Append all footer columnTypes to grid columnTypes
    copyDef.type = [].concat(defType, copyFooterColumnDef.type);

    return copyDef;
};

var generateFooterColumnDefs = function generateFooterColumnDefs(columnDefs, footerColumnDefs) {
    if (!Array.isArray(columnDefs)) {
        return [];
    }
    var footerColumnDefsMap = (0, _lodash4.default)(footerColumnDefs, 'field');

    return columnDefs.map(mergeDefs.bind(undefined, footerColumnDefsMap));
};

exports.default = generateFooterColumnDefs;