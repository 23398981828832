import { useCallback, useState } from 'react';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { Resizable } from './Resizable';

const defaultInitialSize = { width: 0, height: 0 };

export const PersistentResizable = ({
    children,
    persistencyKey,
    initialSize: initialSizeProp = defaultInitialSize,
    ...rest
}) => {
    const [getStorageValue, setStorageValue] = useLocalStorage();
    const [initialSize] = useState(() => {
        const storedValue = getStorageValue(persistencyKey);

        return {
            width: storedValue?.width || initialSizeProp?.width || 0,
            height: storedValue?.height || initialSizeProp?.height || 0,
        };
    });
    const onSizeChange = useCallback(size => setStorageValue(persistencyKey, size), [persistencyKey, setStorageValue]);

    return (
        <Resizable size={initialSize} onSizeChange={onSizeChange} {...rest}>
            {children}
        </Resizable>
    );
};
