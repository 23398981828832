import React from 'react';

export const Discover = props => (
    <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
            fill="url(#paint0_linear)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
            fill="#E5E5E5"
            fillOpacity="0.01"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.01632 11.3329C6.80583 11.5243 6.5323 11.6077 6.09938 11.6077H5.91955V9.3219H6.09938C6.5323 9.3219 6.79508 9.39989 7.01632 9.60154C7.24804 9.80906 7.38739 10.1309 7.38739 10.4619C7.38739 10.7938 7.24804 11.1254 7.01632 11.3329ZM6.23356 8.73633H5.25V12.1928H6.22838C6.74863 12.1928 7.12435 12.0694 7.45402 11.7937C7.84593 11.4675 8.07765 10.9757 8.07765 10.467C8.07765 9.4469 7.32024 8.73633 6.23356 8.73633Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.38574 12.1929H9.05583V8.73633H8.38574V12.1929Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6947 10.0622C10.2926 9.91249 10.1746 9.8138 10.1746 9.62698C10.1746 9.40931 10.3849 9.24385 10.6737 9.24385C10.8744 9.24385 11.0395 9.32692 11.214 9.52375L11.5646 9.06184C11.2765 8.80825 10.9318 8.67871 10.5552 8.67871C9.94736 8.67871 9.48378 9.10337 9.48378 9.66905C9.48378 10.1453 9.69958 10.3891 10.3289 10.6169C10.5913 10.7101 10.7248 10.7721 10.7921 10.8138C10.926 10.9018 10.993 11.0264 10.993 11.1715C10.993 11.4515 10.7718 11.6591 10.4728 11.6591C10.1532 11.6591 9.89573 11.4981 9.74151 11.198L9.30859 11.6174C9.61729 12.0734 9.9881 12.2755 10.498 12.2755C11.1942 12.2755 11.6828 11.8096 11.6828 11.1405C11.6828 10.5913 11.4569 10.3428 10.6947 10.0622Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.894 10.467C11.894 11.483 12.6869 12.2707 13.7072 12.2707C13.9956 12.2707 14.2427 12.2137 14.5473 12.0694V11.2757C14.2795 11.5455 14.0422 11.6543 13.7384 11.6543C13.0635 11.6543 12.5846 11.162 12.5846 10.4619C12.5846 9.79837 13.0787 9.27489 13.7072 9.27489C14.0268 9.27489 14.2687 9.3896 14.5473 9.66376V8.87053C14.2532 8.72043 14.0114 8.6582 13.723 8.6582C12.7079 8.6582 11.894 9.46198 11.894 10.467Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.86 11.0581L18.944 8.73633H18.2119L19.6697 12.2816H20.0303L21.5143 8.73633H20.7879L19.86 11.0581Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8174 12.1929H23.7176V11.6077H22.4869V10.6747H23.6724V10.0892H22.4869V9.32204H23.7176V8.73633H21.8174V12.1929Z"
            fill="#212D34"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0262 10.3277H24.8304V9.28091H25.0368C25.4542 9.28091 25.681 9.45678 25.681 9.79317C25.681 10.1406 25.4542 10.3277 25.0262 10.3277ZM26.3705 9.75684C26.3705 9.10971 25.9275 8.73633 25.1548 8.73633H24.1611V12.1929H24.8304V10.8043H24.9179L25.8453 12.1929H26.6693L25.5878 10.7368C26.0925 10.6334 26.3705 10.2861 26.3705 9.75684Z"
            fill="#212D34"
        />
        <g filter="url(#filter0_i)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.421 10.4764C18.421 11.492 17.6028 12.3153 16.5935 12.3153C15.5842 12.3153 14.7661 11.492 14.7661 10.4764C14.7661 9.46084 15.5842 8.6377 16.5935 8.6377C17.6028 8.6377 18.421 9.46084 18.421 10.4764Z"
                fill="url(#paint1_linear)"
            />
        </g>
        <defs>
            <filter
                id="filter0_i"
                x="14.7661"
                y="8.6377"
                width="3.65479"
                height="3.67773"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="1" dy="1" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"
                />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
            </filter>
            <linearGradient
                id="paint0_linear"
                x1="-16"
                y1="10.5"
                x2="3.26553"
                y2="39.857"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EDF1F3" />
                <stop offset="1" stopColor="#D3DADF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="18.8321"
                y1="10.8279"
                x2="16.6636"
                y2="8.26321"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFA700" />
                <stop offset="1" stopColor="#E75A10" />
            </linearGradient>
        </defs>
    </svg>
);
