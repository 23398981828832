import { SET_FORM_SECTION_STATE_BY_SECTION } from './actionTypes';

const setFormSectionStateBySection = (state, { section, module, entity }) => ({
    type: SET_FORM_SECTION_STATE_BY_SECTION,
    payload: {
        section,
        module,
        entity,
        state,
    },
});

export default setFormSectionStateBySection;
