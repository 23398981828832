'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Truncate = require('./Truncate');

Object.defineProperty(exports, 'Truncate', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Truncate).default;
  }
});

var _Dotdotdot = require('./Dotdotdot');

Object.defineProperty(exports, 'Dotdotdot', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_Dotdotdot).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }