import { useSelectedAccount } from 'hooks';
import { getIsBundlingAllowedOnAccount } from 'modules/campaigns/services/utils/audiences';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

export const useIsCombinedWithBundlingEnabled = () => {
    const isBundlingEnabled = useConfigMatch({ [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_BUNDLING_ENABLED]: 'true' });

    const { formAccount } = useFormDataContext();
    const [selectedAccount] = useSelectedAccount();
    const accountToCheck = formAccount || selectedAccount;

    return isBundlingEnabled && getIsBundlingAllowedOnAccount(accountToCheck);
};
