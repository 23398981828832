import React, { Component } from 'react';
import RecommendationInCellIndication from '../../../performance-recommendations/components/RecommendationInCellIndication/RecommendationInCellIndication';
import SimpleCellRenderer from './SimpleCellRenderer';
import styles from './withRecommendationIndication.module.scss';

const withRecommendationIndication = (WrappedComponent = SimpleCellRenderer) => {
    class RecommendationIndicationHoc extends Component {
        updateRowData = (newData, appliedRecommendationType) => {
            const { node, ...rest } = this.props;
            const { data = {} } = rest;
            const { recommendations = [] } = data;
            const filteredRecommendations = recommendations.filter(rec => rec.type !== appliedRecommendationType);
            const newDataWithRecommendation = { ...newData, recommendations: filteredRecommendations };
            return node.setData({ ...data, ...newDataWithRecommendation });
        };

        skipShowingIndication(recommendation) {
            return recommendation?.usedTimeUtc != null;
        }

        render() {
            const { forwardedRef, ...rest } = this.props;
            const { column, data } = rest || {};
            const { colDef } = column || {};
            const { cellRendererParams = {} } = colDef;
            const { recommendationTypes = [] } = cellRendererParams;
            const { recommendations = [] } = data;
            const [recommendation] = recommendations?.filter(rec => recommendationTypes.includes(rec?.type)) || [];

            if (!recommendation || this.skipShowingIndication(recommendation)) {
                return <WrappedComponent ref={forwardedRef} {...rest} />;
            }

            return (
                <div aria-label="Recommendation In Cell Indication" className={styles['container']}>
                    <WrappedComponent ref={forwardedRef} {...rest} />
                    <RecommendationInCellIndication
                        recommendation={recommendation}
                        onApplyCallback={this.updateRowData}
                        onDismissCallback={this.updateRowData}
                        data={data}
                    />
                </div>
            );
        }
    }

    return React.forwardRef((props, ref) => <RecommendationIndicationHoc forwardedRef={ref} {...props} />);
};

export default withRecommendationIndication;
