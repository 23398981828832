import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AppsRoundedIcon } from 'tuui';
import { ExternalApps } from 'taboola-ultimate-ui';
import { useAvailableItems } from 'hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { userSelector } from '../../modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage, useIntl } from '../../modules/taboola-common-frontend-modules/i18n';
import AccountPicker from '../AccountPicker';
import AdminDropdown from '../AdminDropdown';
import Balance from '../Balance';
import { NotificationsCenter } from '../NotificationsCenter/NotificationsCenter';
import { useShowNotificationsCenter } from '../NotificationsCenter/hooks/useShowNotificationsCenter';
import UserMenu from '../UserMenu';
import { localizeLinks } from './utils';
import styles from './buttonsPanel.module.scss';

const ButtonsPanel = ({ onLogoutButtonClick, externalApps, legal, availableRoutes }) => {
    const user = useSelector(userSelector);
    const { formatMessage } = useIntl();
    const gtmAttrs = label => ({
        'data-metrics-event-name': 'usability',
        'data-metrics-component': 'AppsMenu',
        'data-metrics-column-name': label,
    });
    const selectedAccount = useAccount();
    const { id: accountId } = selectedAccount;
    const localExternalApps = useMemo(
        () => Object.values(localizeLinks(externalApps, formatMessage, { accountId, selectedAccount })),
        [externalApps, formatMessage, accountId, selectedAccount]
    );
    const apps = useAvailableItems(localExternalApps, { selectedAccount });
    const externalAppsLabel = formatMessage({ id: 'app.externalApps.label', defaultMessage: 'External Links' });

    const localLegal = useMemo(() => localizeLinks(legal, formatMessage), [legal, formatMessage]);
    const { showNotificationsCenter } = useShowNotificationsCenter();
    return (
        <div className={styles['buttons-panel']}>
            <Balance />
            <AccountPicker />
            <div className={styles['grouping']}>
                {showNotificationsCenter && <NotificationsCenter />}
                <AdminDropdown availableRoutes={availableRoutes} />
                <div aria-label={externalAppsLabel} className={styles['external-apps-wrapper']}>
                    <ExternalApps AppsIconComponent={AppsRoundedIcon} apps={apps} generateLinkProps={gtmAttrs} />
                </div>
            </div>
            <UserMenu
                className={styles['user-name']}
                userName={user['full_name']}
                email={user.username}
                topLinks={[localLegal.privacy]}
                bottomLinks={[localLegal.termOfUse, localLegal.cookiesConsent]}
                buttonText={<FormattedMessage id="app.actionButtons.signOut" />}
                onButtonClick={onLogoutButtonClick}
            />
        </div>
    );
};

ButtonsPanel.propTypes = {
    /** Logout on click handler */
    onLogoutButtonClick: PropTypes.func,
    /** Configurations regarding the external apps */
    externalApps: PropTypes.object,
    /** Configurations regarding legal topics */
    legal: PropTypes.object,
    /** List of available routes */
    availableRoutes: PropTypes.array,
};

export default ButtonsPanel;
