import { compact, get, map, flatMap, toNumber } from 'lodash';
import { set as fpSet } from 'lodash/fp';

const thirdPartyTagsPath = 'value.thirdPartyTags';
const campaignIdsPath = 'value.campaignId';

export const removeNullThirdPartyTags = rows =>
    rows.map(row => {
        const thirdPartyTags = get(row, thirdPartyTagsPath);
        if (thirdPartyTags) {
            return fpSet(thirdPartyTagsPath, compact(thirdPartyTags), row);
        }
        return row;
    });

export const duplicateAdsWithMultipleCampaignIds = rows =>
    flatMap(rows, row => {
        const campaignIds = get(row, campaignIdsPath);

        if (!campaignIds) {
            return row;
        }

        return map(campaignIds, campaignId => fpSet(campaignIdsPath, toNumber(campaignId), row));
    });

export const toGWCreativeTransformers = [removeNullThirdPartyTags, duplicateAdsWithMultipleCampaignIds];
