import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const bidModificationValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.site.bidModification.empty',
    },
];

export default bidModificationValidations;
