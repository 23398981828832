const scaleValue = (value, originalSize, newSize) => value * (newSize / originalSize);

export const scaleOffsetsToCoordinates = (imageTarget, offsets) => {
    const { clientWidth, clientHeight, naturalWidth, naturalHeight } = imageTarget;
    const { left, top } = offsets;

    const rawX = scaleValue(left, clientWidth, naturalWidth);
    const rawY = scaleValue(top, clientHeight, naturalHeight);

    return { x: Math.round(rawX), y: Math.round(rawY) };
};

export const scaleCoordinatesToOffsets = (imageTarget, coordinates) => {
    if (!coordinates) {
        return null;
    }

    const { clientWidth, clientHeight, naturalWidth, naturalHeight } = imageTarget;
    const { x, y } = coordinates;

    const rawLeft = scaleValue(x, naturalWidth, clientWidth);
    const rawTop = scaleValue(y, naturalHeight, clientHeight);

    return { left: Math.round(rawLeft), top: Math.round(rawTop) };
};

export const shiftOffsetsToCenteredCoordinates = (width, height, left, top) => {
    const x = left - width / 2;
    const y = top - height / 2;

    return { x, y };
};

export const shiftCenteredCoordinatesToOffsets = (width, height, x, y) => {
    const left = x + width / 2;
    const top = y + height / 2;

    return { left, top };
};
