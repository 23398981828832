import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_CODES } from '../../../../../../../../services/api/';
import { ThumbnailError } from '../../../ThumbnailsField/ThumbnailGallery/ThumbnailError';

const VideoExternalError = ({ error }) => (
    <ThumbnailError
        messageId={
            error?.status === ERROR_CODES.TOO_LARGE || error?.messageCode == null
                ? `creative.creator.thumbnails.fetching.${error?.status}`
                : error?.messageCode
        }
        defaultMessage="Image can’t be used. Please try a different one."
    />
);

VideoExternalError.propTypes = {
    error: PropTypes.object,
};

export default VideoExternalError;
