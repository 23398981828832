import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BulkUpload } from './BulkUpload/BulkUpload';

export const UploadSection = () => {
    return (
        <CommonCollapsibleCard
            id="bulk-operations-upload-section"
            header={
                <SectionHeader
                    headerText={<FormattedMessage id="excel.bulk.bulk.upload" defaultMessage="Bulk Upload" />}
                />
            }
        >
            <BulkUpload />
        </CommonCollapsibleCard>
    );
};
