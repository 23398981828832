import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames/bind';
import { isEmpty, get, keyBy } from 'lodash';
import { Button, AddIcon, DragIndicatorIcon } from 'tuui';
import {
    ContentIcon,
    DragDropContext,
    Droppable,
    Draggable,
    getDragEndData,
    shouldSkipDragEvent,
} from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components';
import { FORM_MODES } from 'config/formModes';
import { useCurrentValueGetter } from 'hooks';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { isPerformanceVideoCreative } from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import { IMAGES, MOTIONS } from 'modules/campaigns/modules/creative-creator/config';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import { COMPONENT_STATUS } from 'services/constants';
import { carouselPreviewGenerator } from '../../services/creativePreviewGenerator';
import { getEmptyCarouselCard } from '../../services/utils/carouselHelpers';
import { HIERARCHY_CHILDREN, validations } from './carouselCardListValidations';
import { CarouselCard } from './components/CarouserlCard/CarouselCard';
import { usePreventDragModal } from './hooks/usePreventDragModal';
import commonStyles from '../../creativeCreator.module.scss';
import styles from './CarouselCardList.module.scss';

const commonClassNameBuilder = classnames.bind(commonStyles);

const MessageIndicator = withIndication(() => <div />);
const EmptyThumbnails = [];

export const CarouselCardList = ({ className }) => {
    const { mode } = useFormDataContext();
    // All code wrapper by this condition flag to be removed once thumbnail can be edited in multi layer drawer
    const shouldSyncThumbnailsDeprecated = mode === FORM_MODES.CREATE;
    const { value: thumbnails, onChange: onChangeThumbnails } = useFormFieldValue({ field: 'thumbnails' });
    const {
        value: cards,
        onChange: onChangeCards,
        resetFieldToInitial: resetCardsToInitial,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({ field: 'carouselCards', validations });
    const { onChange: setCreativePreviews } = useFormFieldValue({
        field: 'creativePreviews',
    });
    const { value: creativeName } = useFormFieldValue({ field: 'creativeName' });
    const { value: customId } = useFormFieldValue({ field: 'customId' });
    const thumbnailsGetter = useCurrentValueGetter(thumbnails || EmptyThumbnails);
    const openPreventDragModal = usePreventDragModal();
    const { validationService } = useValidationContext();
    const addNewCard = useCallback(
        () => validationService.manuallyTriggerValidationFocus(['selected-media']),
        [validationService]
    );
    const onDragEnd = useCallback(
        dragEvent => {
            if (shouldSkipDragEvent(dragEvent)) {
                return;
            }
            const hasNonActiveThumbnail = thumbnailsGetter().some(({ status }) => status !== COMPONENT_STATUS.ACTIVE);
            if (hasNonActiveThumbnail) {
                openPreventDragModal();
                return;
            }

            onChangeCards(prev => {
                const orderItems = getDragEndData(prev, dragEvent);
                return orderItems;
            });
            if (shouldSyncThumbnailsDeprecated) {
                onChangeThumbnails(prev => {
                    const orderItems = getDragEndData(prev, dragEvent);
                    return orderItems;
                });
            }
        },
        [onChangeCards, onChangeThumbnails, thumbnailsGetter, openPreventDragModal, shouldSyncThumbnailsDeprecated]
    );
    const deleteCard = idToDelete => {
        onChangeCards(prev => prev.filter(({ id }) => id !== idToDelete));
        if (shouldSyncThumbnailsDeprecated) {
            onChangeThumbnails(prev => prev.filter(({ id }) => id !== idToDelete));
        }
    };

    const getThumbnail = (slide, index) => {
        if (isPerformanceVideoCreative(slide)) {
            const { videoUrl, fallbackUrl } = slide.performanceVideoData;
            return {
                displayUrl: modifyExternalUrl(videoUrl) || fallbackUrl,
                mediaCategory: videoUrl ? MOTIONS : IMAGES,
            };
        }

        if (slide?.thumbnailUrl) {
            return {
                displayUrl: slide.thumbnailUrl,
                mediaCategory: IMAGES,
            };
        }

        return get(thumbnails, index);
    };

    // List of cards should be synced with list of thumbnails
    const cardsGetter = useCurrentValueGetter(cards);
    useEffect(() => {
        if (!shouldSyncThumbnailsDeprecated) {
            return;
        }
        const cards = cardsGetter();
        const activeThumbnails = thumbnails.filter(({ status }) => status === COMPONENT_STATUS.ACTIVE);

        if (activeThumbnails.length > cards.length) {
            const tail = activeThumbnails.slice(cards.length).map(({ id }) => getEmptyCarouselCard(id));
            onChangeCards(prev => [...(prev || []), ...tail]);
            return;
        }
        if (activeThumbnails.length < cards.length) {
            const thumbnailsIdMap = keyBy(activeThumbnails, 'id');
            onChangeCards(prev => (prev || []).filter(({ id }) => thumbnailsIdMap[id]));
        }
    }, [thumbnails, onChangeCards, cardsGetter, shouldSyncThumbnailsDeprecated]);

    // We generate preview stuff to save slides as different creatives
    useEffect(() => {
        if (!shouldSyncThumbnailsDeprecated) {
            return;
        }
        if (cards.length !== thumbnails.length) {
            return;
        }
        const previews = carouselPreviewGenerator(cards, thumbnails, { creativeName, customId });
        setCreativePreviews(previews);
    }, [thumbnails, cards, setCreativePreviews, shouldSyncThumbnailsDeprecated, creativeName, customId]);

    useEffect(() => {
        if (shouldSyncThumbnailsDeprecated) {
            return resetCardsToInitial;
        }
    }, [resetCardsToInitial, shouldSyncThumbnailsDeprecated]);

    if (isEmpty(cards)) {
        return null;
    }

    return (
        <CommonCollapsibleCard
            id="carousel-slides"
            header={
                <SectionHeader
                    headerIcon={<ContentIcon />}
                    headerText={<FormattedMessage id="creative.creator.carousel.cards.label" defaultMessage="Cards" />}
                />
            }
            containerClassName={commonClassNameBuilder('collapsible-card', className)}
        >
            <MessageIndicator {...indicationData} ref={scrollRef} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="carousel-drop-area">
                    {provided => (
                        <>
                            <div {...provided.droppableProps} ref={provided.innerRef} aria-label="Drop Card Zone">
                                <FormNamespaceProvider field="carouselCards">
                                    {cards.map((slide, index) => (
                                        <Draggable draggableId={String(slide.id)} index={index} key={slide.id}>
                                            {provided => (
                                                <div
                                                    key={slide.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={styles['card-wrapper']}
                                                >
                                                    <FormNamespaceProvider field={index} itemKey={slide.id}>
                                                        <CarouselCard
                                                            thumbnail={getThumbnail(slide, index)}
                                                            dragContent={
                                                                <span
                                                                    {...provided.dragHandleProps}
                                                                    className={styles['drag-content']}
                                                                    aria-label={`Draggable card ${slide.id}`}
                                                                >
                                                                    <DragIndicatorIcon />
                                                                </span>
                                                            }
                                                            deleteCard={() => deleteCard(slide.id)}
                                                            readOnly={!shouldSyncThumbnailsDeprecated}
                                                        />
                                                    </FormNamespaceProvider>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </FormNamespaceProvider>
                            </div>
                            {provided.placeholder}
                        </>
                    )}
                </Droppable>
            </DragDropContext>
            {!'skipp for mvp' && (
                <Button
                    className={styles['add-card-button']}
                    onClick={addNewCard}
                    variant={Button.variant.text}
                    size={Button.size.md}
                    disabled={cards?.length >= HIERARCHY_CHILDREN.MAX}
                    aria-label="Add Card"
                >
                    <AddIcon />
                    <FormattedMessage id="creative.creator.carousel.cards.add.card" defaultMessage="Add Card" />
                </Button>
            )}
        </CommonCollapsibleCard>
    );
};
