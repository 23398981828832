import { DataGrid } from 'taboola-ultimate-ui';
import {
    actionsColumnDefinition,
    alwaysVisibleColumnDefinition,
    hiddenColumnDefinition,
    checkBoxColumnDefinition,
    leftPinnedColumnDefinition,
    editableColumnDefinition,
    staticColumnDefinition,
    rightPinnedColumnDefinition,
    toggleColumnDefinition,
    keyColumnDefinition,
    lockedPositionColumnDefinition,
    leftPinnedToggleColumnDefinition,
    bypassMetadataFilterTypeDefinition,
} from './columnTypesDefinitions';

export const editableColumn = 'editableColumn';
export const staticColumn = 'staticColumn';
export const checkBoxSelectionColumn = 'checkBoxSelectionColumn';
export const bypassMetadataFilterType = 'bypassMetadataFilterType';
export const toggleColumn = 'toggleColumn';
export const rightPinnedColumn = 'rightPinnedColumn';
export const leftPinnedColumn = 'leftPinnedColumn';
export const alwaysVisibleColumnType = 'alwaysVisibleColumn';
export const hiddenColumnType = 'hiddenColumn';
export const actionsColumnType = 'actionsColumn';
export const keyColumn = 'keyColumn';
export const lockedColumn = 'lockedColumn';
export const leftPinnedToggleColumn = 'leftPinnedToggleColumn';

// Column type supplied by ag-grid
export const numericColumn = DataGrid.COLUMN_TYPE.NUMERIC_COLUMN;
const columnTypesMap = {
    [editableColumn]: editableColumnDefinition,
    [staticColumn]: staticColumnDefinition,
    [checkBoxSelectionColumn]: checkBoxColumnDefinition,
    [toggleColumn]: toggleColumnDefinition,
    [leftPinnedColumn]: leftPinnedColumnDefinition,
    [rightPinnedColumn]: rightPinnedColumnDefinition,
    [alwaysVisibleColumnType]: alwaysVisibleColumnDefinition,
    [hiddenColumnType]: hiddenColumnDefinition,
    [actionsColumnType]: actionsColumnDefinition,
    [keyColumn]: keyColumnDefinition,
    [lockedColumn]: lockedPositionColumnDefinition,
    [leftPinnedToggleColumn]: leftPinnedToggleColumnDefinition,
    [bypassMetadataFilterType]: bypassMetadataFilterTypeDefinition,
};

export default columnTypesMap;
