import React from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { useDropdownOptions } from 'hooks';
import { enumTransformer } from 'modules/campaigns/services/utils/dropdownUtils';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { RadioGroupField } from '../../../../../components/Form';
import { POLICY_STATE } from '../utils/constants';

const ProfileStatuses = () => {
    const policyStatuses = useDropdownOptions(POLICY_STATE, 'app.campaigns.campaign.policy.state', enumTransformer);
    const { value: policyValue, onChange: onPolicyChange } = useFormFieldValue({ field: 'policyState' });

    return (
        <React.Fragment>
            <RadioGroupField
                inputId="policy-state"
                groupTitle={
                    <FormattedMessage id="campaign.editor.profile.policyState" defaultMessage="Policy Status" />
                }
                selectedValue={policyValue}
                onChange={onPolicyChange}
            >
                {policyStatuses.map(el => (
                    <Radio key={el.value} value={el.value} title={el.label} />
                ))}
            </RadioGroupField>
        </React.Fragment>
    );
};

export default ProfileStatuses;
