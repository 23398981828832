import React from 'react';
import TooltipSection from '../../../../campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

export const SupportedCountriesDropdownTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.external-audience.select.country.tooltip.title"
                defaultMessage="Select the country of the users you want to retarget."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.external.audience.select.country.tooltip.text."
                defaultMessage="If the users originate from multiple countries, please select the most prevalent one. If a country is not listed, you can still create and retarget the audience; however, it's unlikely that this audience will be large in scale."
            />
        </TooltipSection>
    </>
);
