const getFormatter = (formatFn, locale, currency, options = {}) => {
    const formatOptions = { ...options, currency };
    return formatFn(formatOptions)(locale);
};

export const generateGraphMetricDef = (metric, locale, currency) => {
    const {
        field,
        label,
        formatter: formatFn,
        yAxisOptions,
        legendOptions,
        labelOptions,
        referenceLines,
        ...rest
    } = metric;

    const parsedReferenceLines = Array.isArray(referenceLines)
        ? referenceLines.map(reference => generateGraphMetricDef(reference, locale, currency))
        : referenceLines;
    const yAxisFormatter = getFormatter(formatFn, locale, currency, yAxisOptions);
    const legendFormatter = getFormatter(formatFn, locale, currency, legendOptions);
    const labelFormatter = getFormatter(formatFn, locale, currency, labelOptions);
    return {
        name: field,
        label,
        yAxisFormatter,
        legendFormatter,
        labelFormatter,
        referenceLines: parsedReferenceLines,
        ...rest,
    };
};
