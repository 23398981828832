import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { DropdownMenu } from '../../../../../../components';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { useCountriesField } from '../../hooks/useCountriesField';
import { useCustomContextualCountriesApi } from '../hooks/useCustomContextualCountriesApi';
import { LocationFilterTooltip } from './LocationFilterTooltip';
import { ReachFiltersTooltip } from './ReachFiltersTooltip';
import styles from './sizeEstimationCountryField.module.scss';

export const SizeEstimationCountryField = () => {
    const { options, isLoadingOptions } = useCustomContextualCountriesApi();

    const { value: country, onChange: setCountry } = useCountriesField();

    const selectedValueObject = country ? options?.filter(o => o.value === country[0])[0] : {};

    const { formatMessage } = useIntl();

    const id = 'validate-size-estimation-location';

    return (
        <div aria-label="size-estimation-country-field">
            <FormField
                inputId="reach-filters-label"
                label={formatMessage({
                    id: 'custom.contextual.size.estimation.reach.filter.label',
                    defaultMessage: 'Reach Filters:',
                })}
                helpText={<ReachFiltersTooltip />}
            />
            <FormField
                inputId={id}
                label={formatMessage({
                    id: 'custom.contextual.size.estimation.country.label',
                    defaultMessage: 'Location (country)',
                })}
                labelClass={styles['label']}
                helpText={<LocationFilterTooltip />}
            >
                <DropdownMenu
                    selectedValueObject={selectedValueObject}
                    onChange={o => setCountry([o.value])}
                    dropdownWrapperClassName={styles['country-select']}
                    id={id}
                    placeholder={formatMessage({
                        id: 'custom.contextual.size.estimation.country.placeholder',
                        defaultMessage: 'Pick a country',
                    })}
                    options={options}
                    searchable={true}
                    metricsAttributes={{
                        'data-metrics-event-name': GTM_EVENTS.USABILITY,
                        'data-metrics-component': 'SizeEstimationCountryField',
                    }}
                    isLoading={isLoadingOptions}
                    aria-label="location-dropdown"
                />
            </FormField>
        </div>
    );
};

export default SizeEstimationCountryField;
