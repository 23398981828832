import { saveStateToStorage } from '../actions';
import { setFormSectionStateBySection as setFormSectionStateBySectionAction } from '../actions';

const setFormSectionState =
    (state, { section, module, entity }) =>
    dispatch => {
        dispatch(setFormSectionStateBySectionAction(state, { section, module, entity }));
        dispatch(saveStateToStorage());
    };

export default setFormSectionState;
