import React from 'react';
import PERFORMANCE_RECOMMENDATIONS from '../../../../../modules/campaigns/modules/performance-recommendations/config/performanceRecommendations';
import { AudienceNotification } from '../AudienceNotification/AudienceNotification';

export const RetargetingAudienceNotification = ({ notification, timeDiffInMinutes, closeNotificationCenter }) => (
    <AudienceNotification
        timeDiffInMinutes={timeDiffInMinutes}
        notification={notification}
        closeNotificationCenter={closeNotificationCenter}
        notificationType={PERFORMANCE_RECOMMENDATIONS.AUDIENCE_RETARGETING_IC}
    />
);
