import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { validationFunctions, withInputValidations } from '../../../../../taboola-common-frontend-modules/validations';
import CurrencyIcon from '../../../common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'video.campaign.editor.budget.validations.error.not.empty',
        defaultMessage: 'Enter a Budget',
    },
    {
        validationFn: value => value > 0,
        messageId: ' video.campaign.editor.budget.validations.error.whole.positive.number',
        defaultMessage: 'Budget must be a positive number',
    },
]);

export const Budget = ({ currency }) => {
    const { value: budget, onChange: onBudgetChange } = useFormFieldValue({
        field: 'videoBudget.budget',
    });

    return (
        <FormattedNumber value={0} minimumIntegerDigits={1}>
            {placeholder => (
                <InputWithValidations
                    id="budget"
                    onChange={e => onBudgetChange(e.target.value)}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    placeholder={placeholder}
                    value={budget}
                    type={InputTypes.NUMBER}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Budget"
                />
            )}
        </FormattedNumber>
    );
};

Budget.propTypes = {
    currency: PropTypes.string,
};

export default Budget;
