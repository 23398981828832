"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLeftSmall(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M13.7497 16.2793C13.4894 16.5396 12.8445 16.5396 12.5842 16.2793L8.81296 12.508C8.55261 12.2477 8.55261 11.8256 8.81296 11.5652L12.5842 7.794C12.8445 7.53365 13.5645 7.60773 13.7508 7.79401C14.0111 8.05436 14 8.5 14 8.68943L10.7002 11.9893L14 15.2891C14 15.5 14.0101 16.0189 13.7497 16.2793Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgLeftSmall;