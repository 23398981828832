import React from 'react';
import { Button, DeleteIcon } from 'tuui';
import { ImageWithInfo } from './ImageWithInfo';
import { LOGO_EMPTY_OBJECT } from './logoConsts';
import styles from './uploadedLogoItem.module.scss';

export const UploadedLogoItem = ({ uploadedLogo, setUploadedLogo, logoFileName, setLogoFileName }) => {
    const onRemove = () => {
        setUploadedLogo(LOGO_EMPTY_OBJECT);
        setLogoFileName(null);
    };

    const uploadedLogoSrc = uploadedLogo.logo;

    return (
        <div className={styles['container']}>
            <ImageWithInfo src={uploadedLogoSrc} fileName={logoFileName ?? uploadedLogoSrc} />
            <Button
                size={Button.size.sm}
                variant={Button.variant.text}
                onClick={onRemove}
                className={styles['remove-button']}
            >
                <DeleteIcon />
            </Button>
        </div>
    );
};
