import React, { useCallback, forwardRef } from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import CurrencyIcon from '../../CurrencyIcon/CurrencyIcon';
import commonStyles from '../../commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);
const InputWithIndication = withIndication(Input);

const BidInput = forwardRef(({ bid, onBidChange, failedValidationData, isHidden, disabled }, ref) => {
    const {
        formAccount: { currency },
    } = useFormDataContext();

    const changeHandler = useCallback(
        e => {
            const value = parseFloat(e.target.value);
            onBidChange(Number.isNaN(value) ? '' : value);
        },
        [onBidChange]
    );

    if (isHidden) {
        return null;
    }

    return (
        <FormattedNumber value={0} minimumFractionDigits={2}>
            {placeholder => (
                <InputWithIndication
                    id="bid"
                    type={InputTypes.NUMBER}
                    placeholder={placeholder}
                    value={bid}
                    onChange={changeHandler}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Bid"
                    step=".01"
                    disabled={disabled}
                    ref={ref}
                    {...failedValidationData}
                />
            )}
        </FormattedNumber>
    );
});

export default BidInput;
