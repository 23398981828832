'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _withStylesheet;

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _hoc = require('../../hoc');

var _Button = require('../Button/Button');

var _Button2 = _interopRequireDefault(_Button);

var _StyledWithModeButtonType = require('./StyledWithModeButtonType');

var _StyledWithModeButtonType2 = _interopRequireDefault(_StyledWithModeButtonType);

var _StyledWithModeButtonSize = require('./StyledWithModeButtonSize');

var _StyledWithModeButtonSize2 = _interopRequireDefault(_StyledWithModeButtonSize);

var _ButtonMode = require('./ButtonMode');

var _ButtonMode2 = _interopRequireDefault(_ButtonMode);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'styledWithModeButton__taboola-svg-icon___p2G3N',
    'tbl-styled-btn': 'styledWithModeButton__tbl-styled-btn___10lxQ',
    'tbl-styled-icon-before': 'styledWithModeButton__tbl-styled-icon-before___1oZ1a',
    'tbl-styled-icon-after': 'styledWithModeButton__tbl-styled-icon-after___1DuGH',
    'system': 'styledWithModeButton__system___wy_UO',
    'regular': 'styledWithModeButton__regular___2rq4s',
    'small': 'styledWithModeButton__small___1Jyg9',
    'active-open': 'styledWithModeButton__active-open___3w8IJ',
    'active-closed': 'styledWithModeButton__active-closed___1CHyi',
    'icon-button': 'styledWithModeButton__icon-button___2mXfM'
};
var _Button$classNames = _Button2.default.classNames,
    main = _Button$classNames.main,
    iconBefore = _Button$classNames.iconBefore,
    iconAfter = _Button$classNames.iconAfter;

var StyledWithModeButton = (0, _hoc.withStylesheet)(_Button2.default, styles, (_withStylesheet = {}, _defineProperty(_withStylesheet, main, function (_ref) {
    var type = _ref.type,
        size = _ref.size,
        mode = _ref.mode;
    return ['tbl-styled-btn', type, size, mode];
}), _defineProperty(_withStylesheet, iconBefore, 'tbl-styled-icon-before'), _defineProperty(_withStylesheet, iconAfter, 'tbl-styled-icon-after'), _withStylesheet), 'StyledWithModeButton');

StyledWithModeButton.classNames = Object.assign({}, _Button2.default.classNames);

StyledWithModeButton.propTypes = Object.assign({}, _Button2.default.propTypes, {
    /** Determines button type */
    type: _propTypes2.default.oneOf(Object.values(_StyledWithModeButtonType2.default)),
    /** Determines button size */
    size: _propTypes2.default.oneOf(Object.values(_StyledWithModeButtonSize2.default)),
    /** Determines button mode */
    mode: _propTypes2.default.oneOf(Object.values(_ButtonMode2.default))
});

StyledWithModeButton.defaultProps = Object.assign({}, _Button2.default.defaultProps, {
    mode: _ButtonMode2.default.NONE
});

exports.default = StyledWithModeButton;