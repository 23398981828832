import moment from 'moment-timezone';

export const timeFrameValidations = [
    {
        validationFn: (value, options, { endDate }) => !endDate || moment(endDate).isValid(),
        messageId: 'validations.error.campaign.endDate.invalid',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) => isEditMode || startDate,
        messageId: 'validations.error.campaign.startDate.required',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) => isEditMode || moment(startDate).isValid(),
        messageId: 'validations.error.campaign.startDate.invalid',
    },
    {
        validationFn: (value, options, { endDate, startDate }) =>
            !endDate || moment(startDate).isBefore(moment(endDate).endOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeStartDate',
    },
    {
        validationFn: (value, options, { endDate }) => !endDate || moment(endDate).isAfter(moment().startOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeNow',
    },
    {
        validationFn: (value, options, { startDate, isEditMode }) =>
            isEditMode || !startDate || moment(startDate).isAfter(moment().endOf('day').subtract(1, 'day')),
        messageId: 'validations.error.campaign.startDate.beforeNow',
    },
];
