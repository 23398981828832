import React, { useCallback, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { OkOIcon } from 'taboola-ultimate-ui';
import { CampaignsSmartList as CommonCampaignsSmartList } from 'modules/campaigns/modules/common-campaign-form/components';
import CampaignsSmartListItem from 'modules/campaigns/modules/common-campaign-form/components/CampaignsSmartList/CampaignsSmartListItem';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { FORM_MODES } from '../../../../../../config/formModes';
import { Description } from '../../../creative-creator/components/CampaignsField/CreativeCreatorCampaignsSmartList';
import { differentSupplyTargettingValidation } from '../../../creative-creator/components/Validations/differentSupplyTargettingValidation';
import { useIsCarouselCreative } from '../../hooks/useIsCarouselCreative';
import styles from './creativeEditorCampaignsSmartList.module.scss';

const dropdownValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.duplicate.campaign.empty',
        defaultMessage: 'Select at least one campaign',
    },
];

const DEFAULT_SELECTED_CAMPAIGNS = [];

const listItemComponent = props => (
    <CampaignsSmartListItem {...props} IconComponent={OkOIcon} iconClassName={styles['include']} />
);

const CampaignsSmartList = () => {
    const { value = DEFAULT_SELECTED_CAMPAIGNS, onChange } = useFormFieldValue({ field: 'selectedCampaigns' });
    const { formAccount, isFormAccountTouched } = useFormDataContext();
    const { mode } = useFormDataContext();
    const isCarouselCreative = useIsCarouselCreative();
    const disabled = mode === FORM_MODES.DUPLICATE && isCarouselCreative;

    //Clear selected campaigns on form account change
    useEffect(() => {
        if (formAccount && isFormAccountTouched()) {
            onChange(DEFAULT_SELECTED_CAMPAIGNS);
        }
    }, [formAccount, isFormAccountTouched, onChange]);

    const onAdd = useCallback(campaign => onChange([campaign, ...value]), [onChange, value]);
    const onRemove = useCallback(
        ({ value: idToRemove }) => onChange(value.filter(({ value }) => value !== idToRemove)),
        [onChange, value]
    );
    const onClearAll = useCallback(() => onChange([]), [onChange]);

    const selectedCampaignsWithStatus = useMemo(
        () =>
            value.map(campaign => ({
                ...campaign,
                description: <Description status={campaign?.status} />,
            })),
        [value]
    );

    const validations = useMemo(() => {
        if (mode === FORM_MODES.DUPLICATE) {
            return [...dropdownValidations, ...differentSupplyTargettingValidation];
        }
        return dropdownValidations;
    }, [mode]);

    return (
        <CommonCampaignsSmartList
            validations={validations}
            selectedCampaigns={selectedCampaignsWithStatus}
            selectedAccount={formAccount}
            onRemove={onRemove}
            onAdd={onAdd}
            onClearAll={onClearAll}
            isDisabled={isEmpty(formAccount) || disabled}
            validationId="creative-duplicate-campaign-smart-list"
            metricsAttributes={{
                'data-metrics-component': 'CampaignsSmartList (Creative Creator)',
            }}
            listHeaderTitle={<FormattedMessage id="creative.editor.setup" defaultMessage="Selected Campaigns" />}
            listItemComponent={listItemComponent}
        />
    );
};

export default CampaignsSmartList;
