import { get, isEqual } from 'lodash';
import { bidModificationValidations, REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { PublisherBlockingCellRenderer } from '../../../components/CellRenderers';
import { SiteStatusCellRenderer } from '../../../components/CellRenderers/SiteStatusCellRender/SiteStatusCellRenderer';
import { siteStatusValueSetter } from '../../../components/CellRenderers/SiteStatusCellRender/siteStatusValueSetter';
import {
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
} from '../../../components/HeaderRenderers';
import { formattedNumberValueGetter, gridNumberFormatter } from '../../../components/ValueFormatters';
import {
    saveCampaignBidModification,
    saveCampaignBidStrategyPerAffiliate,
    saveCampaignSiteStatus,
    toggleSiteBlocking,
} from '../../../flows';
import { normalizeColumnDefs } from '../../../utils';
import {
    bypassMetadataFilterType,
    editableColumn,
    leftPinnedColumn,
    leftPinnedToggleColumn,
    numericColumn,
} from '../../columns';
import * as FIELDS from '../../fields';
import { defaultCurrencyRenderParams, defaultHeaderComponentParams } from '../../fields';
import {
    bidStrategyPerAffiliateValueCSVGetter,
    bidStrategyPerAffiliateValueGetter,
} from '../../fields/customValueGetters';
import {
    hasSelectedCampaignAndBidModificationsAllowed,
    isBidStrategyPerAffiliateEditable,
    isSiteStatusEditable,
    onlySelectedCampaignColumn,
} from '../../predicates';

const siteReportColumnDefinition = [
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        {
            ...FIELDS.CPA,
            renderParams: {
                ...defaultCurrencyRenderParams,
                minimumFractionDigits: 3,
                showEmptyWhenZero: true,
            },
        },
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.VCPM,
        { ...FIELDS.AVG_BOOST, visibilityCondition: onlySelectedCampaignColumn },
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        visibilityCondition: fieldConfig.visibilityCondition,
        permissions: fieldConfig.permissions,
    })),
    {
        headerName: FIELDS.PUBLISHER_BLOCKING.label,
        field: FIELDS.PUBLISHER_BLOCKING.field,
        type: [leftPinnedToggleColumn],
        permissions: ['API_BLOCK_SITES_EDIT'],
        cellRendererParams: FIELDS.PUBLISHER_BLOCKING.renderParams,
        valueChangeHandler: toggleSiteBlocking,
        valueSetter: params => {
            params.data.isPublisherBlocked = params.newValue;
            params.data.publisherBlockingLevel = 'NONE';
            return true;
        },
        cellRendererFramework: PublisherBlockingCellRenderer,
        csvValueGetter: FIELDS.PUBLISHER_BLOCKING.csvValueGetter,
        csvValueGetterParams: FIELDS.PUBLISHER_BLOCKING.csvValueGetterParams,
    },
    {
        headerName: FIELDS.PUBLISHER_BLOCKING_LEVEL.label,
        field: FIELDS.PUBLISHER_BLOCKING_LEVEL.field,
        type: [leftPinnedColumn, bypassMetadataFilterType, editableColumn],
        valueGetter: params => ({
            isPublisherBlocked: get(params, 'data.isPublisherBlocked'),
            publisherBlockingLevel: get(params, 'data.publisherBlockingLevel'),
            explorationStatus: get(params, 'data.explorationStatus'),
            safetyNetsStatus: get(params, 'data.safetyNetsStatus'),
            siteDescription: get(params, 'data.siteDescription'),
            spendGuardsActionType: get(params, 'data.spendGuardsActionType'),
            spendGuardsActionReason: get(params, 'data.spendGuardsActionReason'),
            spendGuardsActionReasonParams: get(params, 'data.spendGuardsActionReasonParams'),
            spendGuardsActionTimeInUtc: get(params, 'data.spendGuardsActionTimeInUtc'),
            performanceRuleId: get(params, 'data.performanceRuleId'),
            performanceRuleName: get(params, 'data.performanceRuleName'),
            performanceRuleActionType: get(params, 'data.performanceRuleActionType'),
            performanceRuleActionTimeInUtc: get(params, 'data.performanceRuleActionTimeInUtc'),
        }),
        editable: isSiteStatusEditable,
        cellRendererParams: FIELDS.PUBLISHER_BLOCKING_LEVEL.cellRendererParams,

        cellRendererFramework: SiteStatusCellRenderer,
        equals: isEqual,
        valueChangeHandler: saveCampaignSiteStatus,
        cellEditorFramework: FIELDS.PUBLISHER_BLOCKING_LEVEL.cellEditorFramework,
        valueSetter: siteStatusValueSetter,
        csv: false,
    },
    {
        headerName: FIELDS.SITE_NAME_ID.label,
        field: FIELDS.SITE_NAME_ID.field,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: defaultHeaderComponentParams,
        width: 200,
    },
    {
        headerName: FIELDS.SITE_ID.label,
        field: FIELDS.SITE_ID.field,
        type: [numericColumn],
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: defaultHeaderComponentParams,
    },
    {
        headerName: FIELDS.SITE_NAME.label,
        field: FIELDS.SITE_NAME.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.SITE_NAME.cellRenderer,
        cellRendererParams: FIELDS.SITE_NAME.renderParams,
        width: 350,
        csv: FIELDS.SITE_NAME.csv,
    },
    {
        headerName: FIELDS.BID_MODIFICATION.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.BID_MODIFICATION.headerComponentParams,
        field: FIELDS.BID_MODIFICATION.field,
        type: [editableColumn],
        cellRendererFramework: FIELDS.BID_MODIFICATION.cellRenderer,
        cellRendererParams: FIELDS.BID_MODIFICATION.renderParams,
        width: 150,
        valueChangeHandler: saveCampaignBidModification,
        csvValueGetter: FIELDS.BID_MODIFICATION.csvValueGetter,
        cellEditorFramework: FIELDS.BID_MODIFICATION.cellEditorFramework,
        cellEditorParams: {
            ...FIELDS.BID_MODIFICATION.renderParams,
            validations: bidModificationValidations,
        },
        visibilityCondition: hasSelectedCampaignAndBidModificationsAllowed,
    },
    // TODO: DEV-58312 this should be a lot simpler. need to always return a value for BID_STRATEGY_PER_AFFILIATE in xml
    {
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.BID_STRATEGY_PER_AFFILIATE.headerComponentParams,
        headerName: FIELDS.BID_STRATEGY_PER_AFFILIATE.label,
        field: FIELDS.BID_STRATEGY_PER_AFFILIATE.field,
        type: [editableColumn],
        valueGetter: bidStrategyPerAffiliateValueGetter,
        csvValueGetter: FIELDS.BID_STRATEGY_PER_AFFILIATE.csvValueGetter,
        csvValueGetterParams: {
            msgIdPrefix: FIELDS.BID_STRATEGY_PER_AFFILIATE.renderParams.msgIdPrefix,
            valueGetter: bidStrategyPerAffiliateValueCSVGetter,
        },
        cellRendererFramework: FIELDS.BID_STRATEGY_PER_AFFILIATE.cellRenderer,
        cellRendererParams: FIELDS.BID_STRATEGY_PER_AFFILIATE.renderParams,
        cellEditorParams: FIELDS.BID_STRATEGY_PER_AFFILIATE.renderParams,
        editable: isBidStrategyPerAffiliateEditable,
        valueChangeHandler: saveCampaignBidStrategyPerAffiliate,
        cellEditorFramework: FIELDS.BID_STRATEGY_PER_AFFILIATE.cellEditorFramework,
        visibilityCondition: hasSelectedCampaignAndBidModificationsAllowed,
    },
];

export default normalizeColumnDefs(siteReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.SITE].id);
