import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { AlertOIcon, BlockIcon, DeletableItem, OkOIcon, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import TargetingTypes from '../../../config/TargetingTypes';
import { AudienceDetailsWithBundling } from '../../AudienceDetailsWithBundling/AudienceDetailsWithBundling';
import useSegmentListItemValidations from '../../MarketplaceAudiences/hooks/useSegmentListItemValidations';
import { getFormattedSizeAndArticles } from '../../MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import { SegmentNodeTooltip } from '../SegmentNodeTooltip/SegmentNodeTooltip';
import styles from '../../MarketplaceAudiences/SegmentListItemContent/segmentListItemContent.module.scss';
import commonStyles from '../../commonAudienceListItemContent.module.scss';

export const SegmentListItemContentWithBundling = ({
    item,
    field,
    enabledProvidersMap,
    isProvidersReady,
    targetingType,
    deleteItem,
    searchText,
}) => {
    const { size, label, loading, provider, isTargetable, isBundle: isBundleType, taxonomyLabel } = item;
    const { formattedSize } = getFormattedSizeAndArticles({ size, decimalCount: 1 });
    const isStandalone = !isBundleType && isTargetable;

    const handleOnClick = () => {
        deleteItem(item);
    };

    const { message, indicationType } = useSegmentListItemValidations({
        item,
        field,
        enabledProvidersMap,
        isProvidersReady,
    });

    if (loading) {
        return (
            <ContentLoader width={580} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="12" rx="8" ry="8" width="250" height="12" />
                <rect x="510" y="12" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    return (
        <DeletableItem
            onDelete={handleOnClick}
            aria-label={`Removable ${label}`}
            buttonProps={{ 'aria-label': `Remove ${label} segment` }}
        >
            <div className={commonStyles['container']}>
                <div>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={commonStyles['include']} />
                    ) : (
                        <BlockIcon className={commonStyles['exclude']} />
                    )}
                </div>
                <AudienceDetailsWithBundling
                    className={styles['audience-details']}
                    label={label}
                    highLightText={searchText}
                    labelTooltip={<SegmentNodeTooltip item={item} formattedSize={formattedSize} />}
                    showSizes
                    formattedSize={formattedSize}
                    provider={isStandalone && provider}
                    taxonomyLabel={taxonomyLabel}
                />
                {!loading && indicationType && (
                    <span className={commonStyles['error-icon-wrapper']} aria-label={`${label} ${indicationType} icon`}>
                        <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                        <Tooltip delay={800}>{message}</Tooltip>
                    </span>
                )}
            </div>
        </DeletableItem>
    );
};

SegmentListItemContentWithBundling.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        provider: PropTypes.string,
        taxonomy: PropTypes.string,
        size: PropTypes.number,
        description: PropTypes.string,
        label: PropTypes.string,
        loading: PropTypes.bool,
    }),
    targetingType: PropTypes.string,
    deleteItem: PropTypes.func,
    showRemoveButton: PropTypes.bool,
    searchText: PropTypes.string,
};

export default SegmentListItemContentWithBundling;
