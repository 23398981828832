import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, set, isEmpty, noop, isNil, identity, isArray } from 'lodash';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useCurrentValueGetter } from '../../../../hooks';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { useAddPaymentMethod } from '../../../billing-and-payments/hooks/useCardPaymentMethodCreatorState';
import { ENTITY } from '../../../campaigns/config';
import { createCampaign } from '../../../campaigns/flows';
import { toGWtransformers } from '../../../campaigns/flows/flowsUtils';
import { CONTENT_METHODS } from '../../../campaigns/modules/creative-creator/config';
import { useSaveVariationsHandler } from '../../../campaigns/modules/creative-creator/hooks/useSaveVariationsHandler';
import { clearRecommendations } from '../../../recommendations';
import { recommendationsDataSelector } from '../../../recommendations/selectors';
import { APP_EVENT_TYPE, useAppEventContext } from '../../../taboola-common-frontend-modules/app-events-aggregator';
import { CHAT_AGENT_ACTION_STATUS } from '../../../taboola-common-frontend-modules/chat-agent/config/chatAgentActionStatus';
import { useChatAgentContext } from '../../../taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { useChatAgentSessionId } from '../../../taboola-common-frontend-modules/chat-agent/hooks/useChatAgentSessionId';
import { useFormDataContext } from '../../../taboola-common-frontend-modules/formData';
import { useModal } from '../../../taboola-common-frontend-modules/modals';
import { ENTITY_EVENT_DATA_PATH_MAP } from '../../config/entityEventDataPathMap';
import { ONBOARDING_ENTITY } from '../../config/onboardingEntity';
import { SetupSuccessModal } from '../components/SetupSuccessModal/SetupSuccessModal';
import { transformCreativesGWBulkToEditList } from '../transformers/transformCreativesGWBulkToList';
import { transformCreativesToGw } from '../transformers/transformCreativesToGw';
import { transformRecommendedDataToForm } from '../transformers/transformRecommendedDataToForm';

const saveError = new Error('Onboarding error');
const ENTITY_TO_EVENT_TRANSFORMERS = {
    [ENTITY.CAMPAIGN]: toGWtransformers[ENTITY.CAMPAIGN],
    [ENTITY.CREATIVE]: transformCreativesToGw,
};

export const useOnboardingCreatorState = () => {
    const { step, submit, nextStep } = useFormState({ skipChatContextRegistration: true });
    const [submittingEntityName, setSubmittingEntityName] = useState('');
    const { formatMessage } = useIntl();
    const stepGetter = useCurrentValueGetter(step);
    const dispatch = useDispatch();
    const account = useAccount();
    const { data, setData, setExternalData, submitStatus, setSubmitStatus } = useFormDataContext();
    const postponnedSubmitter = useRef();
    const isSubmitDisable = submitStatus !== COMPONENT_STATUS.INITIAL;
    const { push: pushAppEvent } = useAppEventContext();

    const postponnedSubmit = useCallback(
        async (dataPath, entity, callback) => {
            const result = await submit(data => {
                const eventEntityPath = ENTITY_EVENT_DATA_PATH_MAP[entity];
                const transformer = ENTITY_TO_EVENT_TRANSFORMERS[entity] || identity;
                const eventEntityData = transformer(get(data, dataPath));
                const eventData = {
                    [ENTITY_EVENT_DATA_PATH_MAP[ONBOARDING_ENTITY.ONBOARDING_CHAT]]: {
                        [eventEntityPath]: isArray(eventEntityData) ? eventEntityData : [eventEntityData],
                    },
                };

                pushAppEvent({
                    type: APP_EVENT_TYPE.FORM_ACTION,
                    data: eventData,
                    status: CHAT_AGENT_ACTION_STATUS.IN_PROGRESS,
                });

                return {};
            }, noop);
            if (result) {
                setSubmitStatus(COMPONENT_STATUS.LOADING);
                postponnedSubmitter.current = async () => {
                    const result = await callback();
                    setSubmitStatus(COMPONENT_STATUS.INITIAL);
                    return result;
                };
            }
        },
        [submit, setSubmitStatus, pushAppEvent]
    );
    const submitCampaign = useCallback(
        dataPath => {
            setSubmittingEntityName(formatMessage({ id: 'onboarding.form.submit.entity.campaign' }));
            postponnedSubmit(dataPath, ENTITY.CAMPAIGN, () =>
                submit(
                    async data => {
                        const result = await dispatch(
                            createCampaign(account, get(data, dataPath), ENTITY.CAMPAIGN, true)
                        );

                        if (result) {
                            setData(prevData => {
                                const campaigns = get(prevData, 'campaigns') || [];
                                const data = set(prevData, 'campaigns', [...campaigns, { id: result.id }]);
                                return set(data, `${dataPath}.id`, result.id);
                            });
                        } else {
                            throw saveError;
                        }

                        return { campaigns: [result] };
                    },
                    noop,
                    true
                )
            );
        },
        [account, submit, setData, dispatch, postponnedSubmit, formatMessage]
    );
    const { sessionId, registerWizard } = useChatAgentContext();
    const [, setUrlSessionId] = useChatAgentSessionId();
    const saveCreativeHandler = useSaveVariationsHandler(account.accountName, data?.creative || {}, dispatch);
    const submitCreative = useCallback(() => {
        setSubmittingEntityName(formatMessage({ id: 'onboarding.form.submit.entity.creative' }));
        postponnedSubmit('creative', ENTITY.CREATIVE, () => {
            return submit(
                async data => {
                    const campaigns = get(data, 'campaigns').map(({ id }) => ({ id }));
                    const result = await saveCreativeHandler({
                        contentMethod: CONTENT_METHODS.AI_VARIATIONS,
                        campaigns,
                    });

                    if (!result) {
                        throw saveError;
                    }

                    const creatives = transformCreativesGWBulkToEditList(result);

                    return { creatives };
                },
                noop,
                true
            );
        });
    }, [saveCreativeHandler, submit, postponnedSubmit, formatMessage]);
    const addPaymentMethod = useAddPaymentMethod();
    const { open: openModal } = useModal();
    const submitPayment = useCallback(async () => {
        setSubmittingEntityName(formatMessage({ id: 'onboarding.form.submit.entity.payment.details' }));
        const result = await submit(async data => {
            const paymentData = data.payment;
            const result = await addPaymentMethod(paymentData);

            if (!result) {
                throw saveError;
            }

            return result;
        }, null);

        setSubmitStatus(COMPONENT_STATUS.INITIAL);

        if (result) {
            openModal({
                contentRenderer: SetupSuccessModal,
                hasCloseButton: false,
            });
        }
    }, [addPaymentMethod, submit, formatMessage, openModal, setSubmitStatus]);

    const recommendationData = useSelector(recommendationsDataSelector);

    // Any chat recommended data we set as form data
    useEffect(() => {
        if (isEmpty(recommendationData) || stepGetter() > 0) {
            return;
        }

        const transformedData = transformRecommendedDataToForm(recommendationData);

        setData(transformedData);
        setExternalData(transformedData);
        dispatch(clearRecommendations());

        if (!isEmpty(transformedData?.creative) && !isEmpty(transformedData?.campaignMap)) {
            const eventEntityPath = ENTITY_EVENT_DATA_PATH_MAP[ONBOARDING_ENTITY.MEDIA_PLAN];
            const eventData = {
                [ENTITY_EVENT_DATA_PATH_MAP[ONBOARDING_ENTITY.ONBOARDING_CHAT]]: {
                    [eventEntityPath]: transformedData.mediaPlan,
                },
            };
            pushAppEvent({
                type: APP_EVENT_TYPE.FORM_ACTION,
                data: eventData,
                status: CHAT_AGENT_ACTION_STATUS.IN_PROGRESS,
            });
        }
    }, [recommendationData, setData, setExternalData, stepGetter, dispatch, pushAppEvent]);

    // We store session id from context to url to persist it on page reload
    useEffect(() => {
        if (isNil(sessionId)) {
            return;
        }
        setUrlSessionId(sessionId);
    }, [sessionId, setUrlSessionId]);

    // on set wizard step we do postponned save and forward to next step
    useEffect(() => {
        return registerWizard({
            setWizardStep: async () => {
                if (postponnedSubmitter.current) {
                    const result = await postponnedSubmitter.current();
                    postponnedSubmitter.current = null;
                    if (!result) {
                        return;
                    }
                }
                await nextStep(null, { skipValidation: true });
            },
        });
    }, [registerWizard, nextStep]);

    return {
        step,
        isSubmitDisable,
        submitCampaign,
        submitCreative,
        submitPayment,
        submittingEntityName,
    };
};
