import { TRACKING_STATUS } from '../config';

export const pixelOrS2sInstalled = (pixelTrackingData, s2sTrackingData) => {
    const { status: pixelStatus } = pixelTrackingData || {};
    const { status: s2sStatus } = s2sTrackingData || {};
    return (
        (pixelStatus || TRACKING_STATUS.NOT_INSTALLED) !== TRACKING_STATUS.NOT_INSTALLED ||
        (s2sStatus || TRACKING_STATUS.NOT_INSTALLED) !== TRACKING_STATUS.NOT_INSTALLED
    );
};
