class Config {
    init(config) {
        this.clientId = config.googleApiClientId;
        this.gtmClientId = config.googleTagManagerClientId;
    }

    getClientId() {
        return this.clientId;
    }

    getGtmClientId() {
        return this.gtmClientId;
    }
}

export default new Config();
