import React, { useMemo } from 'react';
import { FORM_MODES } from 'config/formModes';
import { useComponentStatus } from 'hooks';
import { useRecommendationActionParam } from 'modules/campaigns/modules/performance-recommendations/hooks/useRecommendationActionParam';
import { FormContextProvider } from 'modules/taboola-common-frontend-modules/forms';
import { COMPONENT_STATUS } from '../../../services/constants';
import { useFetchedCampaign } from '../../campaigns/hooks/useFetchedCampaign';
import { PerformanceRecommendationLoading } from '../../campaigns/modules/performance-recommendations/components/PerformanceRecommendationLoading/PerformanceRecommendationLoading';
import { AccountProvider } from '../account-configurations/AccountProvider';
import { ValidationContextProvider } from '../validations';
import { FormDataProvider } from './FormDataContext';
import useFormValidation from './hooks/useFormValidation';

export const withRibbonFormValidationDataProvider = Component =>
    withRecommendationFormValidationDataProvider(Component, null);

export const withRecommendationFormValidationDataProvider =
    (Component, LoadingComponent = PerformanceRecommendationLoading) =>
    ({ recommendation, ...rest }) => {
        const { campaignId, accountName } = recommendation;
        const { status: fetchStatus, setStatus: setFetchStatus } = useComponentStatus();
        const { status: submitStatus, setStatus: setSubmitStatus } = useComponentStatus();
        const { campaign, isSuccess, isError } = useFetchedCampaign(campaignId, accountName);
        const fetchedFormData = useMemo(
            () => ({
                data: campaign,
                isSuccess,
                isError,
            }),
            [campaign, isError, isSuccess]
        );
        const { recommendationData } = useRecommendationActionParam(recommendation);
        const { validationContext } = useFormValidation();

        return (
            <AccountProvider accountName={accountName}>
                <FormDataProvider
                    mode={FORM_MODES.EDIT}
                    fetchStatus={fetchStatus}
                    submitStatus={submitStatus}
                    setFetchStatus={setFetchStatus}
                    setSubmitStatus={setSubmitStatus}
                >
                    <ValidationContextProvider key={validationContext.version} value={validationContext}>
                        <FormContextProvider
                            fetchedFormData={fetchedFormData}
                            recommendationData={recommendationData}
                            autoScroll={false}
                        >
                            {fetchStatus === COMPONENT_STATUS.ACTIVE ? (
                                <Component recommendation={recommendation} {...rest} />
                            ) : LoadingComponent ? (
                                <LoadingComponent />
                            ) : null}
                        </FormContextProvider>
                    </ValidationContextProvider>
                </FormDataProvider>
            </AccountProvider>
        );
    };
