import React from 'react';
import { EditableHeaderFormField } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './columnPresetName.module.scss';

const columnNameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.columnPicker.name.empty',
        defaultMessage: 'Preset name cannot be empty',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 50 },
        messageId: 'validations.error.columnPicker.name.length',
        defaultMessage: 'Preset name cannot exceed 50 characters',
    },
];

export const ColumnPresetName = () => {
    const { value, onChange, indicationData } = useFormValidatedValue({
        field: 'displayName',
        validations: columnNameValidations,
    });
    const { formatMessage } = useIntl();

    return (
        <EditableHeaderFormField
            inputId="column-preset-name"
            containerClassName={styles['container']}
            id="name"
            value={value}
            onChange={onChange}
            placeholder={formatMessage({ id: 'column.preset.name.placeholder', defaultMessage: 'Type preset name' })}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="PresetNameEdit"
            {...indicationData}
        />
    );
};
