import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { flatMapDeep, identity } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { sortArrayWithoutMutation, topicToNameAndLabel } from '../customContextualTopicsUtils';
import { useCountriesField } from './useCountriesField';

/**
 *
 * @param queryParams
 * @returns {{size: number, name: string, value: string, articles: number}| undefined}
 */

const termsDefaultValue = { include: [], exclude: [] };
export const useFullTermsData = maxAllowedTopics => {
    const { getCustomContextualFullTopicsData } = useAudiencesApi();
    const { value: terms = termsDefaultValue } = useFormFieldValue({
        field: 'terms',
    });
    const { value: platforms } = useFormFieldValue({
        field: 'platforms',
    });
    const { value: countries } = useCountriesField();

    const { value: languages } = useFormFieldValue({ field: 'languages' });

    const { include, exclude } = terms;
    const termsString = flatMapDeep([include, exclude], identity);

    const queryKey = useMemo(() => {
        const sortedTermsString = termsString.sort();
        const sortedPlatforms = sortArrayWithoutMutation(platforms);
        const sortedCountries = sortArrayWithoutMutation(countries);
        const sortedLanguages = sortArrayWithoutMutation(languages);

        return [
            'getCustomContextualFullTopicsData',
            sortedTermsString,
            sortedPlatforms,
            sortedCountries,
            sortedLanguages,
        ];
    }, [countries, languages, platforms, termsString]);

    const isTermsStringValid = termsString && !!termsString.length;
    const isPlatformsValid = platforms && !!platforms.length;
    const isCountriesValid = countries && !!countries.length;
    const isTermsLengthValid = include?.[0]?.length <= maxAllowedTopics;

    const queryParams = useMemo(
        () => ({
            languages,
            platforms,
            countries,
            terms,
        }),
        [languages, platforms, terms, countries]
    );
    const { data } = useQuery(queryKey, () => getCustomContextualFullTopicsData(queryParams), {
        staleTime: Infinity,
        keepPreviousData: true,
        enabled: isTermsStringValid && isPlatformsValid && isCountriesValid && isTermsLengthValid,
    });

    return data?.results.map(topicToNameAndLabel);
};
