import React from 'react';
import { AddIcon } from 'tuui';
import { LinkButton } from 'taboola-ultimate-ui';
import { FORM_MODES } from '../../../../../config/formModes';
import { useNavigate } from '../../../../../hooks';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { getPaymentMethodDefaultAddMessage } from '../../../config/PaymentTypeMetadata';
import { generatePaymentMethodFormPath } from '../../../config/routes/generatePaymentMethodFormPath';
import styles from '../../AddFundsModal/addFundsModal.module.scss';

export const AddFundsAddPaymentMethod = ({ paymentMethodType, onClick }) => {
    const defaultMessage = getPaymentMethodDefaultAddMessage(paymentMethodType);
    const navigate = useNavigate();
    const createPath = generatePaymentMethodFormPath({ mode: FORM_MODES.CREATE, paymentMethodType });

    const onLinkClicked = () => {
        onClick();
        navigate(createPath);
    };

    return (
        <LinkButton className={styles['add-payment-item']} onClick={onLinkClicked}>
            <AddIcon className={styles['icon']} />
            <FormattedMessage
                id={`billingAndPayments.addPaymentMethod.${paymentMethodType}`}
                defaultMessage={defaultMessage}
            />
        </LinkButton>
    );
};
