import Loader from './chat-agent-loading.gif';
import StaticLoader from './static-chat-agent-loading.svg';
import styles from './OnboardingLoader.module.scss';

export const OnboardingLoader = ({ title = '', content = '', animate = true }) => (
    <div className={styles['wrapper']}>
        <span className={styles['loader-img-wrapper']}>
            <img src={animate ? Loader : StaticLoader} alt="loader" />
        </span>
        {title && <div className={styles['title']}>{title}</div>}
        {content && <div className={styles['content']}>{content}</div>}
    </div>
);
