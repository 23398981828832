import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { useTargetCpaValidations } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategyV2/MaxConversionsCategory/useTargetCpaValidations';
import { useBidStrategyFormField } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategyV2/hooks/useBidStrategyFormField';
import CurrencyIcon from 'modules/campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { withIndication } from 'modules/errors';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import styles from './TargetCpaInput.module.scss';

const inputPadding = parseInt(styles.currencyInputLeftPadding);

const InputWithIndication = withIndication(Input);

export const TargetCpaInput = ({
    additionalValidations = [],
    additionalDependencies = {},
    minRange,
    maxRange,
    showLabel = true,
    disabled = false,
    containerClass = '',
    shouldUseTooltipMessageIndication = false,
}) => {
    const { validations, validationDependencies } = useTargetCpaValidations({
        additionalValidations,
        additionalDependencies,
    });

    const isPermitted = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'false' });

    const { isEqualToInitial: isBidStrategyEqualToInitial } = useBidStrategyFormField();

    const {
        formAccount: { currency },
    } = useFormDataContext();

    const {
        value: targetCpa,
        onChange: onChangeTargetCpa,
        indicationData: indicationDataFromInput,
        scrollRef: inputScrollRef,
    } = useFormValidatedValue({
        field: 'cpaGoal',
        validations,
        validationDependencies,
        isPermitted,
        hasInitialData: isBidStrategyEqualToInitial,
    });

    const { value: targetCpaData } = useFormFieldValue({ field: 'targetCpaData' });

    const messageRange = useMemo(() => {
        const min = minRange ?? targetCpaData?.minTargetCpa;
        const max = maxRange ?? targetCpaData?.maxTargetCpa;
        if (min != null && max != null) {
            return {
                minRange: <FormattedNumber value={min} currency={currency} variant="currency" />,
                maxRange: <FormattedNumber value={max} currency={currency} variant="currency" />,
            };
        }
    }, [minRange, maxRange, targetCpaData, currency]);

    const changeHandler = useEventValueHandler(onChangeTargetCpa);

    return (
        <FormField
            inputId="target-cpa"
            containerClass={containerClass}
            label={
                showLabel && (
                    <FormattedMessage
                        id="performance-recommendations.set-cpa.text-field.label"
                        defaultMessage="Target CPA"
                    />
                )
            }
        >
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <InputWithIndication
                        onChange={changeHandler}
                        icon={<CurrencyIcon currency={currency} />}
                        inputPadding={inputPadding}
                        placeholder={placeholder}
                        id="target-cpa"
                        value={targetCpa ?? ''}
                        type={InputTypes.NUMBER}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="TargetCpa"
                        ref={inputScrollRef}
                        disabled={disabled}
                        shouldUseTooltipMessageIndication={shouldUseTooltipMessageIndication}
                        {...indicationDataFromInput}
                    />
                )}
            </FormattedNumber>
            {messageRange && (
                <div className={styles['input-range']}>
                    <FormattedMessage
                        id="performance-recommendations.set-cpa.optimal-range"
                        values={messageRange}
                        defaultMessage="0$-20$"
                    />
                </div>
            )}
        </FormField>
    );
};

TargetCpaInput.propTypes = {
    additionalValidations: PropTypes.array,
    minRange: PropTypes.number,
    maxRange: PropTypes.number,
    disabled: PropTypes.bool,
    containerClass: PropTypes.string,
    indicationContainerClass: PropTypes.string,
};
