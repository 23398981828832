import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
    STYLED_BUTTON_SIZE,
    StyledButton,
    RefreshIcon,
    TooltipV2 as Tooltip,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { useRealtime } from 'modules/campaigns/hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedRelative } from 'modules/taboola-common-frontend-modules/i18n';
import { useReportForceFetchFlag } from '../../../hooks/useReportForceFetchFlag';
import styles from './refreshButton.module.scss';

const PER_MINUTE = 60;

const RefreshButton = ({ lastUpdateTime }) => {
    const { isRealtimeActive } = useRealtime();
    const { timeZoneName } = useAccount();
    const formattedLastUpdateTime = moment(lastUpdateTime).format('HH:mm');
    const [, setForceFetchFlag] = useReportForceFetchFlag();

    const onClick = () => {
        setForceFetchFlag(Date.now());
    };

    return (
        <>
            {isRealtimeActive && (
                <div className={styles['updated-time']}>
                    <FormattedMessage id="app.gridToolbar.refresh.time.updated" defaultMessage="Updated" />
                    <FormattedRelative key={lastUpdateTime} numeric="auto" updateIntervalInSeconds={PER_MINUTE} />
                </div>
            )}
            <StyledButton
                className={styles['button']}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                iconBefore={<RefreshIcon className={styles['icon']} />}
                onClick={onClick}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="RefreshButton"
                aria-label="RefreshButton"
            >
                <Tooltip arrow interactive position={TOOLTIP_POSITION.BOTTOM}>
                    <FormattedMessage
                        id="app.gridToolbar.refresh.tooltip"
                        defaultMessage="Last Update: {lastUpdateTime} ({timeZoneName})"
                        values={{ lastUpdateTime: formattedLastUpdateTime, timeZoneName }}
                    />
                </Tooltip>
            </StyledButton>
            <div className={styles['delimiter']} />
        </>
    );
};

RefreshButton.propTypes = {
    lastUpdateTime: PropTypes.number,
};

export default RefreshButton;
