"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgMobile(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "nonzero",
            d: "M9 6v12h6V6H9zm0-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm3 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        })
    );
}
exports.default = SvgMobile;