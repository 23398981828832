import { ENTITY } from '../../../config';
import { SEGMENT_GROUP_TYPE } from '../../common-campaign-form/components/MarketplaceAudiences/utils/andLogicUtils';
import { SHEET_NAME } from '../consts/sheetName';
import { toGWCampaignTransformers } from '../transformers/toGWTransformers/toGWCampaignTransformers';
import { campaignNeverUploadPOJOFields } from './campaignNeverUploadPOJOFields';
import * as excelColumns from './excelColumns';

export const campaignConfig = {
    entity: ENTITY.CAMPAIGN,
    sheetName: SHEET_NAME.CAMPAIGNS,
    exampleRowIndex: 1,
    headerRowIndex: 2,
    columns: [
        excelColumns.accountName,
        excelColumns.id,
        excelColumns.isActive,
        excelColumns.name,
        excelColumns.campaignGroupId,
        excelColumns.brandingText,
        excelColumns.marketingObjective,
        excelColumns.conversionRulesRules,
        excelColumns.lookBackWindow,
        excelColumns.vtaLookBackWindowType,
        excelColumns.vtaLookBackWindowValue,
        excelColumns.isViewThroughWindowDisabled,
        excelColumns.aggregationType,
        excelColumns.startDate,
        excelColumns.endDate,
        excelColumns.dayPartingTimezone,
        excelColumns.dayPartingRules,
        excelColumns.countryTargetingType,
        excelColumns.countryTargetingValues,
        excelColumns.regionCountryTargetingType,
        excelColumns.regionCountryTargetingValues,
        excelColumns.dmaCountryTargetingType,
        excelColumns.dmaCountryTargetingValues,
        excelColumns.cityCountryTargetingType,
        excelColumns.cityCountryTargetingValues,
        excelColumns.postalCodeTargetingType,
        excelColumns.postalCodeTargetingValues,
        excelColumns.platformTargeting,
        excelColumns.osTargetingType,
        excelColumns.osTargetingValues,
        excelColumns.browserTargetingType,
        excelColumns.browserTargetingValues,
        excelColumns.contextualSegmentsInclude,
        excelColumns.contextualSegmentsExclude,
        excelColumns.marketplaceAudienceInclude,
        excelColumns.getMarketplaceAudienceTargetingIncludeAnd(0, SEGMENT_GROUP_TYPE.AGE),
        excelColumns.getMarketplaceAudienceTargetingIncludeAnd(1, SEGMENT_GROUP_TYPE.GENDER),
        excelColumns.getMarketplaceAudienceTargetingIncludeAnd(2, SEGMENT_GROUP_TYPE.OTHER),
        excelColumns.getMarketplaceAudienceTargetingIncludeAnd(3, SEGMENT_GROUP_TYPE.OTHER),
        excelColumns.marketplaceAudienceExclude,
        excelColumns.myAudiencesInclude,
        excelColumns.myAudiencesExclude,
        excelColumns.customContextualInclude,
        excelColumns.customContextualExclude,
        excelColumns.lookalikeAudienceInclude,
        excelColumns.lookalikeAudienceExclude,
        excelColumns.blockSitesInclude,
        excelColumns.blockSitesExclude,
        excelColumns.thirdPartyBrandSafetyType,
        excelColumns.thirdPartyBrandSafetyTargeting,
        excelColumns.supplyTargeting,
        excelColumns.supplyTargetingSelect,
        excelColumns.brandSafety,
        excelColumns.bidStrategy,
        excelColumns.cpaGoal,
        excelColumns.cpcCap,
        excelColumns.profitMargin,
        excelColumns.pricingModel,
        excelColumns.bid,
        excelColumns.budgetType,
        excelColumns.budgetValue,
        excelColumns.budgetSpendingLimitType,
        excelColumns.budgetSpendingLimitValue,
        excelColumns.adOptimization,
        excelColumns.abTestEndDate,
        excelColumns.trackingCode,
        excelColumns.platformBidModifierValues,
        excelColumns.dayTimeBidModifierValues,
        excelColumns.dayTimeBidModifierTimeZone,
        excelColumns.publisherBidModificationValues,
        excelColumns.performanceRuleIds,
    ],
    neverUploadPOJOFields: campaignNeverUploadPOJOFields,
    toGWTransformers: toGWCampaignTransformers,
};
