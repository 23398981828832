import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';

const reportId = reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULES].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.PERFORMANCE_RULE_NAME.field,
        FIELDS.ACCOUNT_NAME.field,
        FIELDS.PERFORMANCE_RULE_TYPE.field,
        FIELDS.LAST_MODIFIED_BY_PERFORMER.field,
        FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.field,
        FIELDS.APPLIES_TO_CAMPAIGNS.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.PERFORMANCE_RULE_NAME.field,
    FIELDS.ACCOUNT_NAME.field,
    FIELDS.PERFORMANCE_RULE_TYPE.field,
    FIELDS.LAST_MODIFIED_BY_PERFORMER.field,
    FIELDS.PERFORMANCE_RULES_LAST_MODIFY_TIME.field,
    FIELDS.APPLIES_TO_CAMPAIGNS.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
