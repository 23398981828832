import { PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP } from '../../../config';
import { isEditCreativeAllowed, isHTMLCardCreative } from '../config/predicates';
import baseEditCreative from './baseEditCreative';

const editCreative = ({ data, context }) => {
    if (!isEditCreativeAllowed(data) || isHTMLCardCreative(data)) {
        return;
    }
    const { accountName: accountId, campaignId, id: creativeId, dimension } = data;
    const { history, dispatch } = context;
    const creativeType = PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP[data.performanceStatus];

    dispatch(baseEditCreative(accountId, campaignId, creativeId, history, creativeType, dimension));
};

export default editCreative;
