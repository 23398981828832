import { useCallback } from 'react';
import { useUserSettingsApi } from 'services/api/userSettingsApi/useUserSettingsApi';
import { REPORT_SETTINGS_PREFIX, PRESET_SETTINGS_PREFIX } from './constant';

export const useRemoteDeleteCallback = () => {
    const userSettingsApi = useUserSettingsApi();

    const getRemoteDeleteCallback = useCallback(
        (key, prevData, accountId) => () => {
            const { version } = prevData;
            const [reportKey, reportName, presetKey, presetName] = key.split('.');
            if (reportKey === REPORT_SETTINGS_PREFIX && presetKey === PRESET_SETTINGS_PREFIX) {
                return userSettingsApi.deleteViewSetting({
                    version,
                    reportName,
                    presetName,
                    accountId,
                });
            }
        },
        [userSettingsApi]
    );
    return getRemoteDeleteCallback;
};
