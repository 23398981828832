import { isPmpAccount, isVideoAccount } from 'modules/account-management/accountType';

export const getIsSupplyTargetingSelectPermitted = ({
    selectedAccount,
    isTaboolaSelectEnabled,
    isTaboolaSelectForPMPEnabled,
    isTaboolaSelectForVideoEnabled,
}) => {
    const isVideo = isVideoAccount(selectedAccount.partnerTypes);

    if (isVideo) {
        return isTaboolaSelectForVideoEnabled;
    }

    const isPMP = isPmpAccount(selectedAccount);

    if (isPMP) {
        return isTaboolaSelectEnabled && isTaboolaSelectForPMPEnabled;
    }

    return isTaboolaSelectEnabled;
};
