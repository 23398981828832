import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const messageConfig = actionType =>
    actionType === 'BLOCK_SITES'
        ? {
              id: 'performance.rule.executed.actions.report.actionType.site.blocked.name',
              defaultMessage: 'Site blocked',
          }
        : {
              id: 'performance.rule.executed.actions.report.actionType.ad.paused.name',
              defaultMessage: 'Ad paused',
          };

export const PerformanceRuleExecutedActionActivityTypeCellRenderer = ({ data: { actionType } }) => (
    <FormattedMessage {...messageConfig(actionType)} />
);
