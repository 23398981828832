import React from 'react';
import PropTypes from 'prop-types';
import { STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker/config';
import styles from './excludeButton.module.scss';

const ExcludeButton = ({ onClick }) => {
    return (
        <StyledButton
            iconBefore="close"
            size={STYLED_BUTTON_SIZE.SMALL}
            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
            className={styles['remove-button']}
            onClick={onClick}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
        />
    );
};

ExcludeButton.propTypes = {
    onClick: PropTypes.func,
};

export default ExcludeButton;
