import { UPDATE_CONVERSION_ERROR, UPDATE_CONVERSION_SUCCESS, REQUEST_UPDATE_CONVERSION } from './actionTypes';

export const requestUpdateConversion = () => ({ type: REQUEST_UPDATE_CONVERSION });

export const updateConversionSuccess = conversion => ({
    type: UPDATE_CONVERSION_SUCCESS,
    payload: conversion,
});

export const updateConversionError = error => ({ type: UPDATE_CONVERSION_ERROR, payload: error });
