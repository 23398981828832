import React, { useMemo } from 'react';
import { isNaN, isNil } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { InputTypes, StyledButton, STYLED_BUTTON_TYPE, STYLED_BUTTON_SIZE } from 'taboola-ultimate-ui';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { FormattedNumber, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useBidModificationConfig } from '../../hooks/useBidModificationConfig';
import {
    computeBidModificationFromPercent,
    computeBidModificationPercentFromValue,
} from '../../utils/bidModificationUtils';
import BaseCellEditor from './BaseCellEditor';
import ColDefLabel from './ColDefLabel';
import InputEditField from './InputEditField';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';
import styles from './bidModificationCellEditor.module.scss';

export const generateMinBidValidation = memoizeOne((minBid, bid) => ({
    validationFn: value => {
        const bidAfterModification = bid * (value ?? 1);

        return bidAfterModification >= minBid;
    },
    messageId: 'validations.error.site.bidModification.minBid',
    defaultMessage: 'A minimum CPC of {minCurrency} is required',
}));

const parseInputValue = value => (value ? +computeBidModificationFromPercent(value) : null);

const BidModificationCellEditor = props => {
    const {
        value,
        data,
        context: { currency },
        currencyRenderParams,
        getBidModificationValues,
        onSave,
        validations: propValidations,
        colDef,
        onCancel,
    } = props;
    const hasModificationPermissions = usePermissions('PUBLISHER_BID_ACTIONS');
    const { bid } = getBidModificationValues(data, value);
    const { minBid } = useBidModificationConfig();
    const validations = useMemo(() => {
        if (isNil(minBid) || isNaN(minBid)) {
            return propValidations;
        }
        return [...propValidations, generateMinBidValidation(minBid, bid)];
    }, [propValidations, bid, minBid]);
    const validationDependencies = useMemo(() => {
        if (isNil(minBid) || isNaN(minBid)) {
            return;
        }

        return {
            minCurrency: (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber {...currencyRenderParams} currency={currency} value={minBid} variant="currency" />
            ),
        };
    }, [currency, minBid, currencyRenderParams]);

    const { field } = colDef;
    const {
        hasError,
        submitHandler,
        triggerValidationEvent,
        isValidationsRunning,
        isDirty,
        failedValidationData,
        ...fieldData
    } = useSingleFieldCellEditorData({
        ...props,
        field,
        validationDependencies,
        parseInputValue,
        validations,
        onSave,
        hasInitialData: !!value,
    });
    const { value: inputValue } = fieldData;

    return (
        <BaseCellEditor
            onCancel={onCancel}
            onSubmit={submitHandler}
            disableSubmit={hasError}
            colDef={colDef}
            label={
                <span>
                    <ColDefLabel {...colDef} /> (
                    <FormattedNumber {...currencyRenderParams} currency={currency} value={bid * (inputValue ?? 1)} />)
                </span>
            }
        >
            <FormattedNumber value={0}>
                {placeholder => (
                    <InputEditField
                        placeholder={placeholder}
                        type={InputTypes.NUMBER}
                        icon={<span className={styles['icon']}>%</span>}
                        inputPadding={45}
                        step={1}
                        {...fieldData}
                        {...failedValidationData}
                        value={computeBidModificationPercentFromValue(inputValue) || ''}
                        disabled={!hasModificationPermissions}
                    />
                )}
            </FormattedNumber>
            <StyledButton
                className={styles['reset-button']}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                size={STYLED_BUTTON_SIZE.SMALL}
                onClick={() => onSave(null)}
                disabled={!hasModificationPermissions}
            >
                <FormattedMessage id="app.campaigns.campaignsReports.bid.modification.reset" />
            </StyledButton>
        </BaseCellEditor>
    );
};

BidModificationCellEditor.propTypes = {
    /* save function defined in normalizeCellEditor */
    onSave: PropTypes.func,
    /* Values passed in by the grid */
    value: PropTypes.any,
    data: PropTypes.object,
    context: PropTypes.object,
    /* static currency params passed into the formatted number renderer */
    currencyRenderParams: PropTypes.object,
    /** Parses the current bid and bid modification values - render params */
    getBidModificationValues: PropTypes.func,
    /* Given modification value, returns value to set in data - from render params */
    getNewBidModification: PropTypes.func,
    /* Column definition */
    colDef: PropTypes.object,
};

export default withGridCellFormDataProvider(BidModificationCellEditor);
