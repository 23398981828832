import React, { useMemo } from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useEventValueHandler } from '../../../../../../../../hooks';
import { GTM_EVENTS } from '../../../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import {
    useFormValidatedValue,
    validationFunctions,
} from '../../../../../../../taboola-common-frontend-modules/validations';
import { BUDGET_TYPES, MIN_CONVERSIONS_GOAL_TYPE, SPENDING_LIMITS } from '../../../../config';
import { useBudgetFormFieldValue } from '../../../Budget/hooks/useBudgetFormFieldValue';
import { useCpaGoalFormFieldValue } from '../../../CpaGoal/useCpaGoalFormFieldValue';
import styles from './minConversionsGoal.module.scss';

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.minConversionGoal.empty',
        defaultMessage: 'Enter minimum conversions',
    },
    {
        validationFn: validationFunctions.isInt,
        options: { min: 1 },
        messageId: 'validations.error.campaign.minConversionGoal.minValue',
        defaultMessage: 'Minimum conversions must be a whole number above 1.',
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'maxMinConversionsGoal' },
        messageId: 'validations.error.campaign.minConversionGoal.maxValue',
        defaultMessage:
            'Minimum conversions cannot be higher than {maxMinConversionsGoal} according to the budget and Target CPA you defined',
    },
];
const DAYS_IN_MONTH = 30;

const InputWithIndications = withIndication(Input);

const calculateMaxMinConversions = ({ type: budgetType, value: budgetValue, spendingLimit, targetCpa }) => {
    const { type: spendingLimitType, value: spendingLimitValue } = spendingLimit ?? {};
    if (!budgetValue || !targetCpa) {
        return;
    }

    const isDailyBudgetWithMonthlySpendingLimit =
        budgetType === BUDGET_TYPES.DAILY && spendingLimitType === SPENDING_LIMITS.MONTHLY;
    if (isDailyBudgetWithMonthlySpendingLimit && !spendingLimitValue) {
        return;
    }

    const normalizedBudget = budgetType === BUDGET_TYPES.DAILY ? budgetValue * DAYS_IN_MONTH : budgetValue;
    const resolvedBudget = isDailyBudgetWithMonthlySpendingLimit
        ? Math.min(normalizedBudget, spendingLimitValue)
        : normalizedBudget;

    return Math.round(resolvedBudget / targetCpa);
};

export const MinConversionsGoal = () => {
    const { value: budget } = useBudgetFormFieldValue();
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const maxMinConversionsGoal = useMemo(
        () => calculateMaxMinConversions({ ...budget, targetCpa }),
        [budget, targetCpa]
    );
    const validationDependencies = useMemo(
        () => ({
            data: {
                maxMinConversionsGoal,
            },
        }),
        [maxMinConversionsGoal]
    );
    const {
        value: minConversionsGoal,
        onChange: onChangeMinConversionGoal,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'minExpectedConversionsForCpaGoal',
        validations,
        validationDependencies,
    });
    const changeHandler = useEventValueHandler(onChangeMinConversionGoal);

    const { type: budgetType } = budget;
    const minConversionGoalType =
        budgetType === BUDGET_TYPES.LIFETIME ? MIN_CONVERSIONS_GOAL_TYPE.LIFETIME : MIN_CONVERSIONS_GOAL_TYPE.MONTHLY;

    return (
        <FormField
            inputId="min-conversions-goal"
            label={
                <FormattedMessage
                    id={`campaign.editor.minConversionGoal.${minConversionGoalType}.title`}
                    defaultMessage="Expected minimum monthly conversions"
                />
            }
            labelClass={styles['label']}
            description={
                <FormattedMessage
                    id={`campaign.editor.minConversionGoal.${minConversionGoalType}.description`}
                    defaultMessage="Set the minimum number of monthly conversions to consider this a successful campaign."
                />
            }
            descriptionClass={styles['description']}
            descriptionContainerClass={styles['description-wrapper']}
            helpText={
                <FormattedMessage
                    id={`campaign.editor.minConversionGoal.${minConversionGoalType}.tooltip`}
                    defaultMessage="Share how many conversions per month would make you consider this campaign a success.{newline}This will help us understand the expected scale and optimize towards it.{newline}Over time, SmartBid’s Target CPA algorithm will factor your scale expectations, making optimizations to reach the number of conversions you expect to generate per month."
                />
            }
            containerClass={styles['container']}
            ref={scrollRef}
        >
            <InputWithIndications
                id="min-conversions-goal"
                type={InputTypes.NUMBER}
                value={minConversionsGoal ?? ''}
                onChange={changeHandler}
                aria-label="min-conversions-goal"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="MinConversionsGoal"
                {...indicationData}
            />
        </FormField>
    );
};
