import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import ToggleSwitchCellRenderer from './ToggleSwitchCellRenderer';

export const FunnelDefaultCellRenderer = ({ data, ...rest }) => {
    const [selectedAccountData] = useSelectedAccount();
    const { accountName } = selectedAccountData;
    const isDisabled = useCallback(
        ({ accountName: currAccountName, isDefault }) => isDefault || currAccountName !== accountName,
        [accountName]
    );

    const { isGridEditDisabled } = useGridContext();
    return (
        <ToggleSwitchCellRenderer
            {...rest}
            data={{
                ...data,
                selectedAccountData,
            }}
            value={data.accountName === accountName ? data.isDefault : false}
            disabled={isGridEditDisabled || isDisabled(data)}
            defaultMessage={
                <FormattedMessage
                    id="app.campaigns.reports.grid.column.header.message.funnel.funnelDefault"
                    defaultMessage="Funnel Default"
                />
            }
        />
    );
};
FunnelDefaultCellRenderer.propTypes = {
    ...ToggleSwitchCellRenderer.propTypes,
    isDisabled: PropTypes.func,
};
