import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { CollapsibleSideBar } from 'taboola-ultimate-ui';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import {
    useComponentStatus,
    useCurrentValueGetter,
    useDimension,
    useIsHeavyReport,
    useIsIncludeSubmissionData,
    useSelectedAccount,
} from 'hooks';
import { CampaignPicker } from 'modules/campaigns/components';
import { DatePresetContextProvider } from 'modules/campaigns/components/DatePresetContext/DatePresetContext';
import { EditDrawer } from 'modules/campaigns/components/EditDrawer/EditDrawer';
import { toggleCampaignPickerPinned } from 'modules/campaigns/flows';
import { useRealtime } from 'modules/campaigns/hooks';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import * as reportConfigIndex from 'modules/campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from 'modules/campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import { campaignPickerPinnedSelector, lastCreatedCampaignIdSelector } from 'modules/campaigns/selectors';
import {
    INDICATION_TYPES,
    removeIndication,
    StaticIndications,
} from 'modules/taboola-common-frontend-modules/Indications';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker/config';
import { getInitialCampaignDimension } from 'services/campaignDimension';
import { useProgrammaticAccountReportSwitch } from '../../../hooks/useProgrammaticAccountReportSwitch';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { useSelectedCampaignId } from '../hooks';
import { OnboardingAssistantWrapper } from '../modules/campaigns-reports/components/OnboardingAssistant/OnboardingAssistant';
import { RecommendationRibbon } from '../modules/campaigns-reports/components/RecommendationRibbon/RecommendationRibbon';
import styles from './campaignsReportsPage.module.scss';

const sideBarStyles = {
    collapsedSize: styleConsts.leftPanelTouchZone,
    expandedContentMaxSize: styleConsts.leftPaneMaxWidth,
    expandedContentMinSize: styleConsts.leftPaneMinWidth,
    expandedContentSize: `${styleConsts.leftPaneWidth}%`,
};

const PickerWrapper = ({ children, ...rest }) => {
    const showCampaignGroupPickerInIframe = useConfigMatch({
        [FEATURE_FLAGS.SHOW_CAMPAIGN_GROUP_PICKER_IN_IFRAME]: 'true',
    });

    if (!showCampaignGroupPickerInIframe) {
        return children;
    }

    return <CollapsibleSideBar {...rest}>{children}</CollapsibleSideBar>;
};

export const CampaignsReportsPage = ({ onCancel, onValidationError }) => {
    const dispatch = useDispatch();
    const { isModuleReady } = useComponentStatus();
    const isCampaignPickerPinned = useSelector(campaignPickerPinnedSelector);
    const [sideBarVisibility, setSideBarVisibility] = useState(isCampaignPickerPinned);
    const lastCreatedCampaignId = useSelector(lastCreatedCampaignIdSelector);
    const [id] = useSelectedCampaignId();
    const [selectedAccount] = useSelectedAccount();
    const { isAccountFetched } = selectedAccount;
    const selectedAccountGetter = useCurrentValueGetter(selectedAccount);
    const isHeavyReport = useIsHeavyReport();
    const isIncludeSubmissionData = useIsIncludeSubmissionData();
    const isProgrammaticAccount = useProgrammaticAccountReportSwitch(selectedAccount);

    const [, setDimension] = useDimension();
    const { realtimeDatePreset } = useRealtime();
    const location = useLocation();
    const containerRef = useRef();

    const createButtonAttributes = useMemo(
        () => ({
            'data-metrics-value': isCampaignPickerPinned ? 'Hide' : 'Show',
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
            'data-metrics-component': 'CampaignPickerPinButton',
        }),
        [isCampaignPickerPinned]
    );

    const onPinClicked = useCallback(
        isPinned => {
            dispatch(toggleCampaignPickerPinned(isPinned));
        },
        [dispatch]
    );
    const { state } = location;
    const skipFetch = state?.skipFetchReport;
    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    useEffect(() => {
        if (!isModuleReady || skipFetch || !isAccountFetched) {
            return;
        }

        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
    }, [dispatch, isModuleReady, id, skipFetch, isAccountFetched, selectedAccountGetter]);
    // set correct dimension when account is changed
    useEffect(() => {
        setDimension(dimension => {
            const newDimension = getInitialCampaignDimension(selectedAccount.partnerTypes, dimension);
            if (newDimension !== dimension) {
                return newDimension;
            }

            return dimension;
        });
    }, [selectedAccount, setDimension]);

    return (
        <Fragment>
            <DatePresetContextProvider>
                <PickerWrapper
                    content={
                        <CampaignPicker
                            dataMetricsAttrs={{
                                component: 'CampaignPicker',
                                visibility: sideBarVisibility ? GTM_EVENTS.VISIBLE : null,
                            }}
                            key={lastCreatedCampaignId}
                        />
                    }
                    isPinned={isCampaignPickerPinned}
                    {...sideBarStyles}
                    onPinClicked={onPinClicked}
                    buttonAttributes={createButtonAttributes}
                    onVisibilityChange={setSideBarVisibility}
                >
                    <div className={styles['container']} ref={containerRef}>
                        <StaticIndications />
                        <OnboardingAssistantWrapper />
                        <CampaignReports
                            skipFetchReport={skipFetch}
                            parentContainerRef={containerRef}
                            reportConfigMap={reportConfigMap}
                            reportQueryParams={{
                                heavy: isHeavyReport,
                                isIncludeSubmissionData,
                                isProgrammatic: isProgrammaticAccount,
                            }}
                            reportBanner={<RecommendationRibbon />}
                            closeSideDrawer={onCancel}
                            datePresets={realtimeDatePreset}
                        />
                    </div>
                </PickerWrapper>
                <EditDrawer
                    onCancel={onCancel}
                    onValidationError={onValidationError}
                    reportConfigMap={reportConfigMap}
                />
            </DatePresetContextProvider>
        </Fragment>
    );
};

CampaignsReportsPage.propTypes = {
    onCancel: PropTypes.func,
    onValidationError: PropTypes.func,
};

export default CampaignsReportsPage;
