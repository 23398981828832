import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { StatusType } from 'tuui/lib/theme/base';
import { scale } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';

export interface StatusIndicatorProps {
    type?: StatusType;
}

export const StatusIndicator = forwardRef<HTMLDivElement, StatusIndicatorProps>(
    ({ type = 'success', ...rest }, ref) => {
        return <BaseStatusIndicator role="presentation" aria-label={type} $type={type} ref={ref} {...rest} />;
    }
) as UIC<StatusIndicatorProps>;

const BaseStatusIndicator = styled.div<{ $type: StatusType }>`
    --fill: ${({ theme, $type }) => theme.palette[$type].fill};

    width: ${scale('sm')};
    height: ${scale('sm')};
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--fill);
`;
