import { useMemo } from 'react';
import { stubTrue } from 'lodash';
import { budgetValidations } from 'modules/campaigns/config/validations/campaign';
import { BUDGET_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';
import { useCpaGoalFormFieldValue } from '../../CpaGoal/useCpaGoalFormFieldValue';
import { NUMBER_OF_DAYS_IN_MONTH, useBidValidationRatio } from './useBidValidationRatio';
import { useBudgetFormFieldValue } from './useBudgetFormFieldValue';
import { useLowTierMaxDailyLimit } from './useLowTierMaxDailyLimit';
import { useSpendingLimitValidationRatio } from './useSpendingLimitValidationRatio';

const trueValidation = [{ validationFn: stubTrue }];
export const useBudgetValidations = data => {
    const {
        [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]:
            numberOfConversionsInTimeframeForCompletion,
        [COMMON_FLAGS.LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS]: lookBackWindowForConversionsDays,
    } = useCommonConfig([
        COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION,
        COMMON_FLAGS.LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS,
    ]);

    const {
        formAccount: { currency },
    } = useFormDataContext();
    const { value: bid = data?.bid } = useFormFieldValue({ field: 'bid' });
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const { value: bidStrategy } = useBidStrategyFormField();
    const { value: budget = data?.budget } = useBudgetFormFieldValue();
    const bidValidationRatio = useBidValidationRatio(data);
    const spendingLimitRatio = useSpendingLimitValidationRatio();
    const lowTierDailyLimit = useLowTierMaxDailyLimit();
    const spendingLimit = budget?.spendingLimit?.value;
    const budgetLimitType = budget?.type;
    const validations = data?.skipValidation ? trueValidation : budgetValidations;

    const spendingLimitMaxThreshold =
        spendingLimitRatio && spendingLimitRatio !== 0 && spendingLimit
            ? Math.floor(spendingLimit / spendingLimitRatio)
            : undefined;

    const validationDependencies = useMemo(
        () => ({
            currency,
            budget,
            bid,
            ...data,
            spendingLimit,
            monthlyBudgetInDay: budget?.spendingLimit?.value / NUMBER_OF_DAYS_IN_MONTH + 1,
            bidMinThreshold: bidValidationRatio && bid && Math.ceil(bid * bidValidationRatio),
            spendingLimitMaxThreshold,
            targetCpa,
            bidStrategy,
            minConversionsForTargetCpa: numberOfConversionsInTimeframeForCompletion / lookBackWindowForConversionsDays,
            maxLowTierDailyLimit: BUDGET_TYPES.DAILY === budgetLimitType ? lowTierDailyLimit : null,
        }),
        [
            currency,
            budget,
            bid,
            data,
            spendingLimit,
            bidValidationRatio,
            spendingLimitMaxThreshold,
            targetCpa,
            bidStrategy,
            numberOfConversionsInTimeframeForCompletion,
            lookBackWindowForConversionsDays,
            budgetLimitType,
            lowTierDailyLimit,
        ]
    );
    return {
        field: 'budget.value',
        validationDependencies: { data: validationDependencies },
        validations,
    };
};
