import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { LinkButton, SearchInput } from 'taboola-ultimate-ui';
import { useAccountAdditionalData } from 'hooks';
import { FormLayout } from 'modules/campaigns/components';
import useUnsavedChangesController from 'modules/campaigns/hooks/useUnsavedChangesController';
import {
    useAccountConfig,
    FEATURE_FLAGS,
    useIsAppAccountNetwork,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useRealtime } from '../../../../../../hooks';
import { COLUMN_PICKER_ROUTE_PATH } from '../../../../routes';
import { hasAnyChild, hasConversions } from '../../../../services';
import { dateFormats } from '../../../ValueFormatters';
import { useCustomColumnPickerData } from '../../hooks/useCustomColumnPickerData';
import useCustomColumnsService from '../../hooks/useCustomColumnsService';
import { AccountLevelToggle } from './AccountLevelToggle/AccountLevelToggle';
import ColumnGroup from './ColumnGroup/ColumnGroup';
import { ColumnPresetName } from './ColumnPresetName/ColumnPresetName';
import { ConversionToggle } from './ConversionToggle/ConversionToggle';
import { NetworkConversionsNote } from './NetworkConversionsNote/NetworkConversionsNote';
import { RealtimeConversionsNote } from './RealtimeConversionsNote/RealtimeConversionsNote';
import styles from './baseColumnPicker.module.scss';

const getIsVTAClosed = (viewsStartDate, viewsLookbackWindow, lastClickStartDate, clickLookbackWindow) => {
    const currentUTCdate = moment.utc();
    const viewsStartDateIsBeforeCurrentDate = moment
        .utc(viewsStartDate, dateFormats.DATE_TIME_MINUTE_PRECISION)
        .subtract(viewsLookbackWindow, 'd')
        .isBefore(currentUTCdate);
    const lastClickStartDateIsBeforeCurrentDate = moment
        .utc(lastClickStartDate, dateFormats.DATE_TIME_MINUTE_PRECISION)
        .subtract(clickLookbackWindow, 'd')
        .isBefore(currentUTCdate);

    return !viewsStartDateIsBeforeCurrentDate || lastClickStartDateIsBeforeCurrentDate;
};

const BaseColumnPicker = ({
    onCancel,
    reportConfig,
    reportPresetName,
    reportPresetDisplayName,
    reportPresetAccountId,
    isInvalidPreset,
}) => {
    useUnsavedChangesController(COLUMN_PICKER_ROUTE_PATH);

    const {
        columnDefs: columnsDef,
        selectedColumns,
        customPresetColumns,
        defaultColumns,
        applySelectedColumns,
    } = useCustomColumnPickerData({
        reportConfig,
        reportPresetName,
    });
    const {
        filter,
        selected,
        shouldShowCompareColumns,
        setFilter,
        groupedColumns,
        toggleColumnHandler,
        toggleShouldShowCompareColumns,
        resetToDefaultsHandler,
        saveReportPreset,
    } = useCustomColumnsService({
        selectedColumns,
        customPresetColumns,
        columnsDef,
        defaultColumns,
        reportPresetDisplayName,
        reportPresetAccountId,
        applySelectedColumns,
        isInvalidPreset,
    });
    const { formatMessage } = useIntl();
    const shouldRenderConversion = useMemo(
        () => hasAnyChild(columnsDef) || hasConversions(groupedColumns),
        [columnsDef, groupedColumns]
    );
    const isAppAccountNetwork = useIsAppAccountNetwork();
    const { accountAdditionalData = {} } = useAccountAdditionalData();
    const { pixelData } = accountAdditionalData;
    const isCustomPresetsPermitted = usePermissions('ADS_CONSOLE_CUSTOM_REPORT_PRESETS');
    const { isRealtimeActive } = useRealtime();
    const shouldRenderNetworkConversionsNote =
        !isRealtimeActive && isAppAccountNetwork && pixelData?.hasAtLeastOneActiveConversion;
    const shouldRenderRealTimeConversionsNote = isRealtimeActive && !isAppAccountNetwork;

    const actionConversionConfigs = Object.values(
        useAccountConfig([
            FEATURE_FLAGS.ACTION_CONVERSION_VIEWS_START_DATE,
            FEATURE_FLAGS.ACTION_CONVERSION_VIEWS_LOOKBACK_WINDOW,
            FEATURE_FLAGS.ACTION_CONVERSION_LAST_CLICK_START_DATE,
            FEATURE_FLAGS.ACTION_CONVERSION_CLICK_LOOKBACK_WINDOW,
        ])
    );
    const isVTAClosed = getIsVTAClosed(...actionConversionConfigs);

    return (
        <FormInitBase>
            <aside className={styles['container']}>
                <header className={styles['top-section']}>
                    <ColumnPresetName />
                    <SearchInput
                        id="search-custom-columns"
                        type="text"
                        placeholder={formatMessage({ id: 'app.campaigns.search', defaultMessage: 'Search' })}
                        expandable={false}
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </header>
                <section className={styles['groups']}>
                    {Object.keys(groupedColumns).map(group => (
                        <ColumnGroup
                            key={group}
                            name={group}
                            columns={groupedColumns[group]}
                            selectedColumns={selected}
                            onToggleColumn={toggleColumnHandler}
                            columnsDef={columnsDef}
                        />
                    ))}
                </section>
                <footer>
                    {isCustomPresetsPermitted && <AccountLevelToggle />}
                    {shouldRenderConversion && (
                        <ConversionToggle
                            shouldShowCompareColumns={shouldShowCompareColumns}
                            toggleShouldShowCompareColumns={toggleShouldShowCompareColumns}
                            isVTAClosed={isVTAClosed}
                        />
                    )}
                    {shouldRenderNetworkConversionsNote && <NetworkConversionsNote />}
                    {shouldRenderRealTimeConversionsNote && <RealtimeConversionsNote />}
                    <div className={styles['controls']}>
                        <LinkButton onClick={resetToDefaultsHandler}>
                            <FormattedMessage
                                id="app.campaigns.reports.columnPicker.reset"
                                defaultMessage="Reset to default"
                            />
                        </LinkButton>
                        <div className={styles['buttons']}>
                            <Button size={Button.size.md} variant={Button.variant.ghost} onClick={onCancel}>
                                <FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button size={Button.size.md} variant={Button.variant.primary} onClick={saveReportPreset}>
                                <FormattedMessage id="app.actionButtons.save" defaultMessage="Save" />
                            </Button>
                        </div>
                    </div>
                </footer>
            </aside>
        </FormInitBase>
    );
};

BaseColumnPicker.propTypes = {
    onCancel: PropTypes.func,
    reportConfig: PropTypes.object,
};

const BaseColumnPickerWithFormDataProvider = withFormDataProvider(BaseColumnPicker, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { BaseColumnPickerWithFormDataProvider as BaseColumnPicker };
