import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ClickZoneArea } from 'taboola-ultimate-ui';
import { DropContainer } from 'modules/campaigns/modules/creative-creator/components/DropContainer/DropContainer';
import { filterByStatus } from 'modules/campaigns/modules/creative-creator/services';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n/index';
import { validationFunctions, withContainerValidations } from 'modules/taboola-common-frontend-modules/validations';
import { COMPONENT_STATUS } from 'services/constants';
import { useFormatTypeFormFieldValueCreateMode } from '../../../hooks/useFormatTypeFormFieldValueCreateMode';
import { SelectedThumbnailsGallery } from './SelectedThumbnailsGallery';
import styles from './selectedThumbnails.module.scss';

const classNameBuilder = classnames.bind(styles);
const COLUMN_COUNT = 3;

const checkFallbackImageDisabled = (fallbackImageStatus, parentStatus) =>
    fallbackImageStatus === COMPONENT_STATUS.LOADING ||
    parentStatus === COMPONENT_STATUS.LOADING ||
    parentStatus === COMPONENT_STATUS.ERROR;

const clickZoneTooltip = (
    <FormattedMessage
        id="creative.drag.and.drop.tooltip"
        defaultMessage="Image dimension must have a minimum width of 400 px. and a minimum length of 350 px. Your image file size must be no more than 5 Mb. Switching Images to Video or animated GIF files is not supported at the moment."
    />
);

const clickDialogText = (
    <FormattedMessage
        id="creative.drag.and.drop.clickable.text.upload.from.computer"
        defaultMessage="upload from computer"
    />
);

export const SelectedThumbnails = ({
    onRemoveThumbnailById,
    className,
    onEditFallbackImageClick,
    thumbnailList,
    isEmpty: isEmptyProp,
    startEditing,
    setSelectedImgSrc,
    setCreativeFocus,
    columnCount,
    onSelectMedia,
    cancelSelectFallbackImage,
    motionIdFallbackSelecting,
    openCropModal,
}) => {
    const isUploadInMediaLibraryEnabled = useConfigMatch({
        [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'true',
    });
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();
    const isDisplayEmptyDropZone = !isUploadInMediaLibraryEnabled && isEmptyProp;
    const ThumbnailGallery = useMemo(
        () => (
            <SelectedThumbnailsGallery
                thumbnailList={thumbnailList}
                startEditing={startEditing}
                setSelectedImgSrc={setSelectedImgSrc}
                setCreativeFocus={setCreativeFocus}
                className={className}
                checkFallbackImageDisabled={checkFallbackImageDisabled}
                onEditFallbackImageClick={onEditFallbackImageClick}
                onRemoveThumbnailById={onRemoveThumbnailById}
                onSelectMedia={onSelectMedia}
                cancelSelectFallbackImage={cancelSelectFallbackImage}
                motionIdFallbackSelecting={motionIdFallbackSelecting}
                columnCount={columnCount}
                openCropModal={openCropModal}
                formatType={formatType}
            />
        ),
        [
            thumbnailList,
            startEditing,
            setSelectedImgSrc,
            setCreativeFocus,
            className,
            openCropModal,
            onEditFallbackImageClick,
            onRemoveThumbnailById,
            onSelectMedia,
            cancelSelectFallbackImage,
            motionIdFallbackSelecting,
            columnCount,
            formatType,
        ]
    );

    if (isDisplayEmptyDropZone) {
        return (
            <DropContainer dropIcon={null}>
                <ClickZoneArea
                    tooltipText={clickZoneTooltip}
                    clickZoneIcon={null}
                    clickDialogText={clickDialogText}
                    className={classNameBuilder(styles['drop-container'])}
                />
            </DropContainer>
        );
    }

    return (
        <FormNamespaceProvider field="thumbnails">
            <div className={styles['gallery-wrapper']}>
                {isUploadInMediaLibraryEnabled ? ThumbnailGallery : <DropContainer>{ThumbnailGallery}</DropContainer>}
            </div>
        </FormNamespaceProvider>
    );
};

SelectedThumbnails.propTypes = {
    thumbnailList: PropTypes.array,
    onRemoveThumbnailById: PropTypes.func,
    className: PropTypes.string,
    startEditing: PropTypes.func,
    setSelectedImgSrc: PropTypes.func,
    setCreativeFocus: PropTypes.func,
    columnCount: PropTypes.number,
};

SelectedThumbnails.defaultProps = {
    videoThumbnails: [],
    onRemoveThumbnailById: () => {},
    columnCount: COLUMN_COUNT,
};

const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.thumbnails.empty',
        defaultMessage: 'Add at least one media file',
    },
];

const SelectedThumbnailsWithError = withContainerValidations(
    withIndication(SelectedThumbnails),
    validations,
    'thumbnailList',
    filterByStatus(COMPONENT_STATUS.ERROR, true)
);

SelectedThumbnailsWithError.propTypes = {
    thumbnailList: PropTypes.array,
};

SelectedThumbnailsWithError.defaultProps = {
    thumbnailList: [],
};

export default SelectedThumbnailsWithError;
