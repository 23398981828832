import React from 'react';
import { Redirect, Switch } from 'react-router';
import { ModuleRoute } from '../../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { StaticIndications } from '../../taboola-common-frontend-modules/Indications/components/Indications';
import { BillingManagement } from '../components/BillingManagement/BillingManagement';
import { BillingSidebarContainer } from '../components/BillingSidebarHeader/BillingSidebarContainer';
import { InvoicesAndPaymentsReportsPage } from '../components/InvoicesAndPayments/InvoicesAndPaymentsReportsPage';
import { BILLING_AND_PAYMENTS_ROUTE, INVOICES_AND_PAYMENTS_ROUTE } from '../config';
import styles from './billingAndPaymentsPage.module.scss';

export const BillingAndPaymentsPage = ({ onCancel }) => {
    return (
        <div className={styles['container']} data-private="true">
            <BillingSidebarContainer />
            <div className={styles['page-container']}>
                <StaticIndications />
                <Switch>
                    <ModuleRoute
                        path={INVOICES_AND_PAYMENTS_ROUTE}
                        exact
                        render={() => <InvoicesAndPaymentsReportsPage />}
                    />
                    <ModuleRoute
                        path={BILLING_AND_PAYMENTS_ROUTE}
                        render={props => <BillingManagement {...props} onCancel={onCancel} />}
                    />
                    <Redirect to={BILLING_AND_PAYMENTS_ROUTE} />
                </Switch>
            </div>
        </div>
    );
};
