import { useEffect, useMemo, useState } from 'react';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';

export const useRejectedKeywords = () => {
    const { value: rejectedValues = [] } = useFormFieldValue({
        field: 'externalContentReview.rejectedValues',
    });

    const [rejectedKeywords, setRejectedKeywords] = useState([]);
    const [rejectedKeywordsTopics, setRejectedKeywordsTopics] = useState({});

    const allKeywords = useMemo(() => {
        return rejectedValues.reduce((all, { keywords }) => {
            all.push(...keywords);
            return all;
        }, []);
    }, [rejectedValues]);

    useEffect(() => {
        if (rejectedValues.length > 0) {
            setRejectedKeywords(allKeywords);
            setRejectedKeywordsTopics(rejectedValues);
        }
    }, [allKeywords, rejectedValues]);

    return {
        rejectedKeywords,
        setRejectedKeywords,
        rejectedKeywordsTopics,
        setRejectedKeywordsTopics,
    };
};
