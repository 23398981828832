export const MIN_THUMBNAIL_WIDTH = 400;
export const MIN_THUMBNAIL_HEIGHT = 350;

export const MIN_VIDEO_WIDTH = 600;
export const MIN_VIDEO_HEIGHT = 400;

export const MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID = 'thumbnailSizeTooSmall';
export const THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID = 'ImageLoadFailed';
export const VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID = 'videoLoadFailed';
export const VIDEO_URL_UNSUPPORTED_FORMAT_ERROR_MESSAGE_ID = 'unsupported';

export const MAX_VIDEO_SIZE = 50_000_000;
export const MAX_VIDEO_SIZE_VALIDATION_ERROR_MESSAGE_ID = 'videoSizeTooLarge';

export const GIF_TYPE = 'image/gif';
export const MAX_GIF_SIZE = 5_000_000;
export const MAX_GIF_SIZE_VALIDATION_ERROR_MESSAGE_ID = 'videoGifSizeTooLarge';
