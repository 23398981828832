import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { StepperButton, withStylesheet } from 'taboola-ultimate-ui';
import { gtmTracker, GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import FormattedMessageCellRenderer from './FormattedMessageCellRenderer';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import styles from './dummyBidModificationCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);
const LOCAL_STORAGE_KEY = 'ad_bid_modification';
const CHANGE_PERCENTAGE = 0.05;
const MAX_BOOST_PERCENTAGE = 0.1;

const StyledStepperButton = withStylesheet(StepperButton, styles, {
    [StepperButton.classNames.main]: 'stepper-container',
    [StepperButton.classNames.downButton]: 'stepper-button',
    [StepperButton.classNames.upButton]: ['stepper-button', 'stepper-up'],
});

const getTooltipProps = value => ({
    children: <FormattedNumber value={value} variant="percent" forceSign />,
    offset: 0,
    className: styles['stepper-tooltip'],
});

const trackBidModification = (adID, campaignId, accountName, oldBid, newBid) =>
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: oldBid < newBid ? 'By Ad View - Boost Item' : 'By Ad View - De-Boost Item',
        value: JSON.stringify({ prevBoostPercentage: oldBid, newBoostPercentage: newBid, adID }),
        taboolaCampaignId: campaignId,
        accountId: accountName,
    });

const ModificationPercent = ({ percent }) =>
    percent ? (
        // eslint-disable-next-line react/style-prop-object
        <FormattedNumberCellRenderer value={percent} style="percent" forceSign />
    ) : (
        <FormattedMessageCellRenderer value="app.campaigns.campaignsReports.bid.modification.default" />
    );

export const DummyBidModificationCellRenderer = ({ data }) => {
    const { id: adID, campaignId, accountName } = data;
    const [getModificationDataFromStorage, setModificationInStorage] = useLocalStorage();
    const bidModificationData = getModificationDataFromStorage(LOCAL_STORAGE_KEY) || {};
    const { [adID]: initialBidModification = 0.0 } = bidModificationData;
    const [bidModification, setBidModification] = useState(initialBidModification);
    const adjustBid = changePercentage => {
        const newBid = bidModification + changePercentage;
        trackBidModification(adID, campaignId, accountName, bidModification, newBid);
        setBidModification(newBid);
        setModificationInStorage(LOCAL_STORAGE_KEY, { ...bidModificationData, [adID]: newBid });
    };

    return (
        <div className={styles['main-container']}>
            <div className={classNameBuilder('inner-container', 'left-container')}>
                <ModificationPercent percent={bidModification} />
            </div>
            <div className={classNameBuilder('inner-container', 'right-container')}>
                <StyledStepperButton
                    onStepUp={() => adjustBid(CHANGE_PERCENTAGE)}
                    onStepDown={() => adjustBid(-CHANGE_PERCENTAGE)}
                    upTooltipProps={getTooltipProps(CHANGE_PERCENTAGE)}
                    downTooltipProps={getTooltipProps(-CHANGE_PERCENTAGE)}
                    upDisabled={bidModification >= MAX_BOOST_PERCENTAGE}
                    downDisabled={bidModification <= -MAX_BOOST_PERCENTAGE}
                />
            </div>
        </div>
    );
};
