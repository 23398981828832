import React from 'react';
import { FormField, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const CampaignState = () => {
    const { mode, value: isActive, onChange } = useFormFieldValue({ field: 'isActive' });
    const showComponent = mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE;
    return showComponent ? (
        <FormField
            label={<FormattedMessage id="campaign.time.frame.set.state.title" defaultMessage="Status" />}
            helpText={
                <FormattedMessage
                    id="campaign.time.frame.set.state.help"
                    defaultMessage="By default, your Campaign is set to Active and delivery will begin on the start date, once it will be approved. In case you do not want the campaign to automatically start running as soon as approved you can set the campaign status to Paused and it will start only when you enable it on the Campaigns report."
                />
            }
        >
            <RadioGroup name="isActive" selectedValue={isActive} onChange={onChange}>
                <Radio
                    value={true}
                    title={<FormattedMessage id="campaign.time.frame.set.state.active" defaultMessage="Active" />}
                />
                <Radio
                    value={false}
                    title={<FormattedMessage id="campaign.time.frame.set.state.paused" defaultMessage="Paused" />}
                />
            </RadioGroup>
        </FormField>
    ) : null;
};
