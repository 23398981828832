import React from 'react';
import { CheckboxGroup, FormField, pxToRem, TextCheckbox } from 'taboola-ultimate-ui';
import { ExternalLink } from 'components';
import DropdownMenu from 'modules/campaigns/components/Form/FormFields/DropdownField/DropdownField';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from '../../VideoCreativePixelManagementSection.module.scss';

const textStyle = {
    color: styles['dropdown-color'],
    fontSize: styles['dropdown-fontSize'],
};

const ValueContainerRenderer = ({ getValue, children }) => {
    const values = getValue();
    const { formatMessage } = useIntl();
    return (
        <div className={styles['value-container-wrapper']}>
            <div className={styles['value-container']}>
                {values
                    .map(({ pixelType }) => formatMessage({ id: `video.creative.creator.pixel.${pixelType}.label` }))
                    .join(', ')}
            </div>
            {children}
        </div>
    );
};

const OptionRenderer = ({ label, value, isSelected, data, selectOption }) => {
    return (
        <div onClick={() => selectOption(data)} className={styles['select-option']}>
            <CheckboxGroup selectedValues={isSelected ? [value] : []}>
                <TextCheckbox
                    data-automation={data.pixelType}
                    data-testid={data.pixelType}
                    title={label}
                    value={value}
                    labelClassName={styles['checkbox-text']}
                />
            </CheckboxGroup>
        </div>
    );
};

const MultiValueRenderer = () => null;

const bestPracticesLink = (
    <ExternalLink href="https://brain.taboola.com/pages/viewpage.action?spaceKey=SC&title=Video+Macro+Support">
        <FormattedMessage
            id="video.creative.creator.pixel.eventDropdown.tooltip.href"
            defaultMessage="Supported Macros"
        />
    </ExternalLink>
);

const dropdownStyles = () => ({
    menu: provided => ({
        ...provided,
        minWidth: pxToRem(220),
    }),
    container: provided => ({
        ...provided,
    }),
    control: provided => ({
        ...provided,
        minHeight: 0,
        padding: styles['dropdown-padding'],
        backgroundColor: 'unset',
        boxShadow: 'none',
    }),
    placeholder: () => textStyle,
    singleValue: () => textStyle,
    input: () => ({ margin: 0 }),
});

export const SelectPixelEventsTypes = ({ value, onChange, options }) => {
    return (
        <FormField
            label={
                <FormattedMessage
                    id="video.creative.creator.pixel.eventDropdown.title"
                    defaultMessage="Add Pixel Event"
                />
            }
            description={
                <FormattedMessage
                    id="video.creative.creator.pixel.eventDropdown.description"
                    defaultMessage="To add additional event types, use the dropdown below."
                />
            }
            helpText={
                <FormattedMessage
                    id="video.creative.creator.pixel.eventDropdown.tooltip"
                    defaultMessage="Insert 3rd party tags supplied by client.{br}{br}Common use cases for 3rd party tags include:{br}-Retargeting users engaging with your Taboola ad in other platforms.{br}-Verification of Taboola reporting.{br}-Brand safety and viewability measurement.{br}{br}Note: This is not the place to insert your landing page URL or Taboola pixel.{br}{bestPracticesLink}"
                    values={bestPracticesLink}
                />
            }
            containerClass={styles['input']}
            inputId="pixel-events-types-drop-down"
        >
            <DropdownMenu
                id="pixel-events-types-drop-down"
                placeholder={
                    <FormattedMessage
                        id="video.creative.creator.pixel.eventDropdown.placeholder"
                        defaultMessage="Select..."
                    />
                }
                options={options}
                defaultValue={value}
                multiValueRenderer={MultiValueRenderer}
                value={value}
                optionRenderer={OptionRenderer}
                valueContainerRenderer={ValueContainerRenderer}
                onChange={onChange}
                isMulti
                clearable={false}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                searchable={false}
                dataTestId="pixel-events-types-drop-down-test-id"
                styles={dropdownStyles()}
                className={styles['values-dropdown']}
            />
        </FormField>
    );
};
