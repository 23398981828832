import React, { useCallback } from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useDropdownOptions } from '../../../../hooks';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { resolveSubFrequencyInitValue } from '../../utils/subFrequencyInitValueResolver';
import {
    formFieldId,
    FormFieldWithIndication,
    helpTextDefaultMessage,
    msgIdPrefix,
    subFormFieldId,
} from './ScheduledReportFrequencyFieldConsts';
import {
    FREQUENCY_CONDITIONS,
    frequencyMonthlyOptions,
    frequencyOptions,
    frequencyWeeklyOptions,
} from './scheduledReportFrequencyFieldOptions';
import styles from './scheduledReportFrequencyField.module.scss';

export const ScheduledReportFrequencyField = () => {
    const { value: frequency, onChange: setFrequency } = useFormFieldValue({ field: 'frequency' });
    const { value: subFrequency, onChange: setSubFrequency } = useFormFieldValue({ field: 'subFrequency' });
    const options = useDropdownOptions(frequencyOptions, msgIdPrefix);
    const subFrequencyOptions =
        frequency === FREQUENCY_CONDITIONS.WEEKLY ? frequencyWeeklyOptions : frequencyMonthlyOptions;
    const subOptions = useDropdownOptions(subFrequencyOptions, msgIdPrefix);
    const onFrequencyChange = useCallback(
        ({ value: frequency }) => {
            setFrequency(frequency);
            setSubFrequency(resolveSubFrequencyInitValue(frequency));
        },
        [setFrequency, setSubFrequency]
    );
    const subChangeHandler = useCallback(
        ({ value: subFrequency }) => {
            setSubFrequency(subFrequency);
        },
        [setSubFrequency]
    );
    const showSubSelect = frequency === FREQUENCY_CONDITIONS.WEEKLY || frequency === FREQUENCY_CONDITIONS.MONTHLY;
    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['frequency-container']}
            label={
                <FormattedMessage id="app.scheduled.reports.create.frequency.select.title" defaultMessage="Frequency" />
            }
            labelClass={styles['label']}
            description={
                <FormattedMessage
                    id="app.scheduled.reports.create.frequency.select.description"
                    defaultMessage="Select how often you would like to receive the report."
                />
            }
            helpText={
                <FormattedMessage
                    id="app.scheduled.reports.create.frequency.select.help"
                    defaultMessage={helpTextDefaultMessage}
                />
            }
        >
            <div className={styles['frequency']}>
                <div>
                    <DropdownMenu
                        id={formFieldId}
                        selectedValueObject={{
                            value: frequency,
                        }}
                        options={options}
                        onChange={onFrequencyChange}
                        searchable
                        aria-label="frequency-dropdown"
                        className={styles['frequency-dropdown-container']}
                    />
                </div>
                {showSubSelect && (
                    <div>
                        <DropdownMenu
                            id={subFormFieldId}
                            selectedValueObject={{
                                value: subFrequency,
                            }}
                            options={subOptions}
                            onChange={subChangeHandler}
                            searchable
                            aria-label="sub-frequency-dropdown"
                            className={styles['sub-frequency-dropdown-container']}
                        />
                    </div>
                )}
            </div>
        </FormFieldWithIndication>
    );
};
