import { useQuery } from 'react-query';
import { useBillingApi } from '../../../services/api';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { isDefined } from '../../taboola-common-frontend-modules/formData/utils/isDefined';

export const useAllowedPrepaymentMethods = () => {
    const { accountName } = useAccount();

    const { getAllowedPrepaymentMethods: getAllowedPrepaymentMethodsApi } = useBillingApi();

    const {
        data = {},
        isError,
        isSuccess,
        isFetching,
    } = useQuery(
        ['getAllowedPrepaymentMethods', accountName],
        () => getAllowedPrepaymentMethodsApi({ accountId: accountName }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: isDefined(accountName),
        }
    );

    return {
        ...data,
        isError,
        isSuccess,
        isFetching,
    };
};
