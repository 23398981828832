const RESPONSE_METADATA_MAP = new WeakMap();

export const getRrandFormResponse = response => response?.headers?.get('X-Rrand');

export const getRrandFromData = data => {
    const headers = RESPONSE_METADATA_MAP.get(data);

    if (!headers) {
        return;
    }

    return getRrandFormResponse({ headers });
};

export const getDataWithResponseMetadata = (data, response) => {
    if (typeof data === 'object' && data !== null) {
        RESPONSE_METADATA_MAP.set(data, response?.headers);
    }

    return data;
};
