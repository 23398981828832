'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDom = require('react-dom');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Buttons = require('../Buttons');

var _Overlay = require('./Overlay');

var _Overlay2 = _interopRequireDefault(_Overlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'modal__taboola-svg-icon___12JDy',
    'close-icon': 'modal__close-icon___2uWS7',
    'modal-header': 'modal__modal-header___jActl',
    'modal-wrapper': 'modal__modal-wrapper___3NW1p',
    'modal-content': 'modal__modal-content___29qRv'
};


var classNameBuilder = _bind2.default.bind(styles);

var Modal = function Modal(_ref) {
    var isOpen = _ref.isOpen,
        children = _ref.children,
        onClose = _ref.onClose,
        title = _ref.title,
        overlayClassName = _ref.overlayClassName,
        className = _ref.className,
        headerClassName = _ref.headerClassName,
        contentClassName = _ref.contentClassName,
        hideCloseButton = _ref.hideCloseButton,
        closeButtonClassName = _ref.closeButtonClassName,
        closeButtonProps = _ref.closeButtonProps,
        shouldOverlayClose = _ref.shouldOverlayClose;

    var wrapperClassNames = classNameBuilder('modal-wrapper', className);
    var headerClassNames = classNameBuilder('modal-header', headerClassName);
    var closeButtonClassNames = classNameBuilder('close-icon', closeButtonClassName);
    var contentClassNames = classNameBuilder('modal-content', contentClassName);
    var closeBtn = hideCloseButton ? null : _react2.default.createElement(_Buttons.StyledButton, Object.assign({}, closeButtonProps, {
        className: closeButtonClassNames,
        iconBefore: 'close',
        onClick: onClose,
        type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON,
        size: _Buttons.STYLED_BUTTON_SIZE.SMALL
    }));

    if (!isOpen) {
        return null;
    }

    var dialog = _react2.default.createElement(
        _Overlay2.default,
        {
            overlayClassName: overlayClassName,
            onClose: shouldOverlayClose && onClose
        },
        _react2.default.createElement(
            'div',
            { className: wrapperClassNames },
            title || closeBtn ? _react2.default.createElement(
                'header',
                { className: headerClassNames },
                title,
                closeBtn
            ) : null,
            _react2.default.createElement(
                'section',
                { className: contentClassNames },
                children
            )
        )
    );

    return (0, _reactDom.createPortal)(dialog, document.body);
};

Modal.propTypes = {
    isOpen: _propTypes2.default.bool,
    children: _propTypes2.default.node,
    onClose: _propTypes2.default.func,
    title: _propTypes2.default.node,
    overlayClassName: _propTypes2.default.string,
    className: _propTypes2.default.string,
    headerClassName: _propTypes2.default.string,
    contentClassName: _propTypes2.default.string,
    closeButtonClassName: _propTypes2.default.string,
    shouldOverlayClose: _propTypes2.default.bool,
    hideCloseButton: _propTypes2.default.bool,
    closeButtonProps: _propTypes2.default.object
};

Modal.defaultProps = {
    shouldOverlayClose: true,
    hideCloseButton: false
};

exports.default = Modal;