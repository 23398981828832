import React from 'react';
import { MoonLoader } from 'react-spinners';
import styled, { useTheme } from 'styled-components';
import { ThemeColor } from 'tuui/lib/theme/base';
import { UIC } from 'tuui/lib/utils/typing';

export interface SpinnerProps {
    className?: string;
    size?: number;
    color?: ThemeColor;
}

export const Spinner: UIC<SpinnerProps> = ({ className, size = 60, color = 'secondary' }: SpinnerProps) => {
    const theme = useTheme();
    const spinnerColor = theme.palette[color].fill;

    return (
        <ContainerBase aria-label="loading" className={className}>
            <MoonLoader loading size={size} color={spinnerColor} />
        </ContainerBase>
    );
};

const ContainerBase = styled.div`
    box-sizing: content-box;
    display: flex;
`;
