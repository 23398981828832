const accountPrefix = accountId => `/${accountId}`;

const imagePrefix = accountId => `${accountPrefix(accountId)}/ai-image-generation/images`;
const textPrefix = (accountId, campaignId) =>
    `${accountPrefix(accountId)}/campaigns/${campaignId}/ai-text-generation/content`;
const pageDataPrefix = accountId => `${accountPrefix(accountId)}/ai-page-data-generation/content`;

export const aiGenerationApiFactory = ({ callGetApi, callPostApi }) => ({
    // text
    getAIGeneratedTitles: ({ accountId, campaignId, ...options }) =>
        callGetApi(`${textPrefix(accountId, campaignId)}/title`, { ...options }),
    getAiRephrasedTitles: ({ accountId, campaignId, ...options }) =>
        callGetApi(`${textPrefix(accountId, campaignId)}/rephrase`, { ...options }),
    getDKIFixSuggestions: ({ accountId, campaignId, ...options }) =>
        callGetApi(`${textPrefix(accountId, campaignId)}/fix-dki`, { ...options }),

    // page data
    getPageProductDetails: ({ accountId, url, ...options }) =>
        callGetApi(`${pageDataPrefix(accountId)}/landing-page`, { urlPageMetadata: url, ...options }),

    // images
    getAIGeneratedImagesFromImage: ({ accountId, ...options }) =>
        callGetApi(`${imagePrefix(accountId)}/from-image`, { ...options }),
    getAIGeneratedImagesFromPrompt: ({ accountId, campaignId, ...options }) =>
        callPostApi(`${imagePrefix(accountId)}/from-prompt?campaignId=${campaignId}`, { ...options }),
    getAIGeneratedReplacedBackgrounds: ({ accountId, campaignId, foregroundMask, ...options }) =>
        callPostApi(`${imagePrefix(accountId, campaignId)}/replace-background?campaignId=${campaignId}`, {
            foregroundMaskInBase64: foregroundMask,
            ...options,
        }),
    getAIGeneratedSegmentAnything: ({ accountId, campaignId, ...options }) =>
        callPostApi(`${imagePrefix(accountId, campaignId)}/segment-anything/mask-generation?campaignId=${campaignId}`, {
            ...options,
        }),
    getAIGeneratedOutcropImage: ({ accountId, campaignId, ...body }) =>
        callPostApi(`${imagePrefix(accountId, campaignId)}/outcrop-image?campaignId=${campaignId}`, { ...body }),
});
