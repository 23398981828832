/**
 * Created by oreuveni
 * Date: 04/10/2018
 * Time: 10:21
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './currencyIcon.module.scss';

const CurrencyIcon = ({ currency }) => <span className={styles['input-icon']}>{currency}</span>;

CurrencyIcon.propTypes = {
    currency: PropTypes.string,
};

CurrencyIcon.defaultProps = {
    currency: 'USD',
};

export default CurrencyIcon;
