import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { CompletionStatus } from './CompletionStatus';
import styles from './onboardingStep.module.scss';

const classNameBuilder = classnames.bind(styles);

export const OnboardingStep = ({ titleId, stepCompleteData, isSelected, isCollapsed }) => {
    const { progressPercent, ...stepProgressData } = stepCompleteData;
    const completionValues = Object.values(stepProgressData);
    const totalSubSteps = completionValues.length;
    const totalSubStepsCompleted = completionValues.filter(Boolean).length;

    return (
        <div className={styles['container']}>
            <CompletionStatus progress={progressPercent} isCollapsed={isCollapsed} isSelected={isSelected} />
            <span className={classNameBuilder('title', { hidden: isCollapsed })}>
                <FormattedMessage id={`onboarding.assistant.${titleId}.title`} defaultMessage="Title" />
            </span>
            {totalSubSteps > 1 && (
                <span
                    className={classNameBuilder('step-progress', { hidden: isCollapsed })}
                >{`${totalSubStepsCompleted}/${totalSubSteps}`}</span>
            )}
        </div>
    );
};
