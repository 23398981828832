import { useMemo } from 'react';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';

const ACCOUNT_CONFIGS = [
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_IDS_LIST,
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_IDS_LIST,
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_TAXONOMY_PARENTS_LIST,
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_TAXONOMY_PARENTS_LIST,
];

export const useSegmentTypeGroupIds = () => {
    const {
        [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_IDS_LIST]: ageGroupIds,
        [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_IDS_LIST]: genderGroupIds,
        [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_GROUP_TAXONOMY_PARENTS_LIST]: ageGroupTaxonomyParentsList,
        [FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_GROUP_TAXONOMY_PARENTS_LIST]: genderGroupTaxonomyParentsList,
    } = useAccountConfig(ACCOUNT_CONFIGS);

    return useMemo(
        () => ({
            ageGroupIdsList: ageGroupIds.split(','),
            genderGroupIdsList: genderGroupIds.split(','),
            ageGroupTaxonomyParentsSet: new Set(ageGroupTaxonomyParentsList.split(',')),
            genderGroupTaxonomyParentsSet: new Set(genderGroupTaxonomyParentsList.split(',')),
        }),
        [ageGroupIds, ageGroupTaxonomyParentsList, genderGroupIds, genderGroupTaxonomyParentsList]
    );
};
