import React from 'react';
import PropTypes from 'prop-types';
import { FormField, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { UrlList } from '../UrlList/UrlList';
import UrlFieldDescription from './UrlFieldDescription';
import UrlsFieldTooltip from './UrlsFieldTooltip';

const UrlsField = ({ className, maxItems, isMissedAccount, missedAccountMessage, showDescription }) => (
    <FormField
        label={<FormattedMessage id="creative.creator.urls.title" defaultMessage="Landing Page URL" />}
        helpText={<UrlsFieldTooltip />}
        inputId="creative-creator-landing-page"
        containerClass={className}
        subDescription={showDescription && <UrlFieldDescription />}
    >
        <UrlList
            id="creative-creator-landing-page"
            maxItems={maxItems}
            disabled={isMissedAccount}
            message={isMissedAccount && missedAccountMessage}
            indicationType={INDICATION_TYPES.WARNING}
        />
    </FormField>
);

UrlsField.propTypes = {
    className: PropTypes.string,
    onSetUrl: PropTypes.func,
    deleteItem: PropTypes.func,
    maxItems: PropTypes.number,
    urls: PropTypes.array,
};

UrlsField.defaultProps = {
    className: '',
};

export default UrlsField;
