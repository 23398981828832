import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { useCurrentValueGetter } from './useCurrentValueGetter';

// Average typing pace 200spm, one symbol ~300ms
export const DEFAULT_TYPING_THROTTLE_TIME = 200;
export const OVER_AVERAGE_TYPING_THROTTLE_TIME = 400;

export const useDelayedState = (
    externalValue,
    externalOnChange,
    { throttleTiming = DEFAULT_TYPING_THROTTLE_TIME } = {}
) => {
    const [value, setValue] = useState(externalValue);
    const valueGetter = useCurrentValueGetter(value);
    const cacheReleasedRef = useRef(true);
    const delayedOnChange = useMemo(
        () =>
            throttle(
                (...args) => {
                    externalOnChange(...args);
                    cacheReleasedRef.current = true;
                },
                throttleTiming,
                { leading: false }
            ),
        [externalOnChange, throttleTiming]
    );

    const onChange = useCallback(
        (value, initial) => {
            setValue(value);
            delayedOnChange.cancel();
            if (!initial) {
                cacheReleasedRef.current = false;
                delayedOnChange(value);
            }
        },
        [delayedOnChange]
    );

    // We need to update cache if released value were changed externally
    useEffect(() => {
        if (cacheReleasedRef.current && valueGetter() !== externalValue) {
            setValue(externalValue);
        }
    }, [externalValue, setValue, valueGetter]);

    useEffect(
        () => () => {
            delayedOnChange.cancel();
        },
        [delayedOnChange]
    );

    return { value, onChange };
};
