import React from 'react';
import { ExternalLink } from '../../../../../../components';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useParentAppName } from '../../../../../taboola-common-frontend-modules/i18n/hooks/useParentAppName';
import TooltipSection from '../TooltipSection';

const MyAudiencesFieldTooltip = () => {
    const parentAppName = useParentAppName();

    return (
        <div>
            <TooltipSection
                header={
                    <FormattedMessage
                        id="campaign.editor.targeting.my.audiences.help.title"
                        defaultMessage="Creating Audiences"
                    />
                }
            >
                <FormattedMessage
                    id="campaign.editor.targeting.my.audiences.help.automatic"
                    defaultMessage="An audience is generated automatically each time you create a Conversion (under the Tracking tab)."
                />
            </TooltipSection>
            <TooltipSection>
                <FormattedMessage
                    id="campaign.editor.targeting.my.audiences.help.manual"
                    defaultMessage="You can also create a Custom Audience manually, by uploading data from your CRM - or by defining the Custom Audience Rule yourself (under the Tracking tab)."
                />
            </TooltipSection>
            {!parentAppName && (
                <TooltipSection
                    header={
                        <FormattedMessage
                            id="campaign.editor.targeting.my.audiences.help.learn.more"
                            defaultMessage="Learn More About Audience Creation:"
                        />
                    }
                >
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/360003484314-Defining-and-Creating-Conversions">
                        <FormattedMessage
                            id="campaign.editor.targeting.my.audiences.help.link.conversion.rules"
                            defaultMessage="Creating Conversion Rules"
                        />
                    </ExternalLink>
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/360003504813-Building-and-Targeting-Advertiser-Website-My-Audiences">
                        <FormattedMessage
                            id="campaign.editor.targeting.my.audiences.help.link.audience.manual"
                            defaultMessage="Creating An Audience Manually"
                        />
                    </ExternalLink>
                    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/360021908874-Uploading-and-Targeting-a-Customer-File">
                        <FormattedMessage
                            id="campaign.editor.targeting.my.audiences.help.link.audience.crm"
                            defaultMessage="Creating An Audience From CRM Data"
                        />
                    </ExternalLink>
                </TooltipSection>
            )}
        </div>
    );
};

export default MyAudiencesFieldTooltip;
