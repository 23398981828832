import React from 'react';
import classnames from 'classnames/bind';
import { Button, ChevronIcon } from 'tuui';
import { CropImage } from '../CropImage/CropImage';
import styles from './croppingLayout.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CroppingLayout = ({
    src,
    updateSrc,
    campaignId,
    ratio,
    setActiveRatio,
    ratios,
    index,
    onOutcropImage,
    originalSrc,
    srcHistory,
    outcroppingStatus,
    isOutcroppingEnabled,
    shouldShowOutcropWarning,
    setSrcHistory,
    initialCropData,
    setInitialCropData,
}) => {
    const disableRight = index >= ratios.length - 1;

    const disableLeft = !index;
    const onClickChevronRight = () => {
        if (disableRight) {
            return;
        }
        setActiveRatio(ratios[index + 1]);
    };

    const onClickChevronLeft = () => {
        if (disableLeft) {
            return;
        }
        setActiveRatio(ratios[index - 1]);
    };

    return (
        <div className={styles['container']}>
            <div className={styles['crop-image-container']}>
                {!isOutcroppingEnabled && (
                    <Button
                        variant={Button.variant.text}
                        disabled={disableLeft}
                        onClick={onClickChevronLeft}
                        aria-label="Previous"
                    >
                        <ChevronIcon className={classNameBuilder('chevron-left', 'button', { disable: disableLeft })} />
                    </Button>
                )}
                <CropImage
                    src={src}
                    ratio={ratio}
                    campaignId={campaignId}
                    onOutcropImage={onOutcropImage}
                    srcHistory={srcHistory}
                    originalSrc={originalSrc}
                    updateSrc={updateSrc}
                    outcroppingStatus={outcroppingStatus}
                    isOutcroppingEnabled={isOutcroppingEnabled}
                    shouldShowOutcropWarning={shouldShowOutcropWarning}
                    setSrcHistory={setSrcHistory}
                    initialCropData={initialCropData}
                    setInitialCropData={setInitialCropData}
                    index={index}
                />
                {!isOutcroppingEnabled && (
                    <Button
                        variant={Button.variant.text}
                        disabled={disableRight}
                        onClick={onClickChevronRight}
                        aria-label="Next"
                    >
                        <ChevronIcon className={classNameBuilder('button', { disable: disableRight })} />
                    </Button>
                )}
            </div>
        </div>
    );
};
