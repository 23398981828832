import moment from 'moment';
import {
    PROGRESS_VALUE,
    PROGRESS_COLOR,
    PROGRESS_PHASE_MAX,
    STATUS,
    DISPLAY_PHASES,
    PHASES,
    GW_PHASES,
    PIXEL_CONVERSIONS,
    DURATION_MS,
    PHASE_DURATION_MS,
    SCALING_ADDITIONAL_TIME_MS,
    DURATION_MINIMUM,
    REMAINING_TIME_MESSAGE_ID,
    PHASE_REMAINING_TIME_MESSAGE_ID,
} from './config';

const PENDING_REVIEW_FIRST_FROM_WIZARD = 'PENDING_REVIEW_FIRST_FROM_WIZARD';

export class CampaignStatusPhaseService {
    constructor(
        { phaseName, phaseStartDate, hasConversions, hasActivePixel, isAccountPending, isActive: isTrackerActive },
        status,
        isCampaignPaused
    ) {
        this.gwPhaseName = phaseName;
        this.phase = this.getPhaseNameDisplay(phaseName, status, isTrackerActive, isCampaignPaused);
        this.progress = PROGRESS_VALUE.NEGATIVE_INFINITY;
        this.progressBarColor = PROGRESS_COLOR.PRIMARY;
        this.timeLeftProps = { id: null, value: null };

        if (phaseStartDate && this.phase) {
            const durationSincePhaseStartMs = moment().diff(moment(phaseStartDate));
            const remainingTime = this.getRemainingTime(this.phase, durationSincePhaseStartMs, isAccountPending);

            const pixelConversionsEnum = this.getPixelConversionsEnum(hasConversions, hasActivePixel);

            this.progress = this.getProgress(
                this.phase,
                remainingTime,
                durationSincePhaseStartMs,
                pixelConversionsEnum,
                isAccountPending
            );

            // Progress of NEGATIVE_INFINITY sets this.phase to EMTPY_PHASE
            // EMPTY_PHASE clears the campaign status tracking UI in StatusCellRenderer
            if (this.progress === PROGRESS_VALUE.NEGATIVE_INFINITY) {
                this.phase = PHASES.EMPTY_PHASE;
            } else {
                this.progressBarColor = this.getProgressBarColor(this.phase, pixelConversionsEnum);
                this.timeLeftProps = this.getTimeLeftDisplay(this.phase, remainingTime, durationSincePhaseStartMs);
            }
        }
    }

    getPixelConversionsEnum(hasConversions, hasActivePixel) {
        if (!hasActivePixel) {
            return PIXEL_CONVERSIONS.NO_PIXEL_NO_CONVERSIONS;
        } else if (!hasConversions) {
            return PIXEL_CONVERSIONS.HAS_PIXEL_NO_CONVERSIONS;
        }

        return PIXEL_CONVERSIONS.HAS_PIXEL_HAS_CONVERSIONS;
    }

    getRemainingTime(phaseName, durationSincePhaseStartMs, isAccountPending) {
        if (isAccountPending && phaseName === PHASES.PENDING_REVIEW) {
            return PHASE_DURATION_MS.PENDING_REVIEW_FIRST_FROM_WIZARD - durationSincePhaseStartMs;
        }

        return PHASE_DURATION_MS[phaseName] - durationSincePhaseStartMs;
    }

    isStatusValid(status) {
        return status === STATUS.PENDING_APPROVAL || status === STATUS.RUNNING;
    }

    getPhaseNameDisplay(phaseName, status, isTrackerActive, isCampaignPaused) {
        if (isTrackerActive && this.isStatusValid(status) && !isCampaignPaused) {
            if (status === STATUS.PENDING_APPROVAL && phaseName !== PHASES.MISSING_ADS) {
                return DISPLAY_PHASES.PENDING_REVIEW;
            } else if (DISPLAY_PHASES[phaseName] !== undefined) {
                return DISPLAY_PHASES[phaseName];
            }
        }

        return DISPLAY_PHASES.EMPTY_PHASE;
    }

    calculateProgress(durationSincePhaseStartMs, phaseName, phaseMaxProgress) {
        return Math.min(phaseMaxProgress, Math.round((durationSincePhaseStartMs / PHASE_DURATION_MS[phaseName]) * 100));
    }

    getProgressPhaseName(isAccountPending, phaseName) {
        if (isAccountPending && phaseName === PHASES.PENDING_REVIEW) {
            return PENDING_REVIEW_FIRST_FROM_WIZARD;
        }

        return phaseName;
    }

    getProgress(phaseName, remainingTime, durationSincePhaseStartMs, pixelConversionsEnum, isAccountPending) {
        const pastDuration = remainingTime < 0;

        const progressPhaseMax = PROGRESS_PHASE_MAX[phaseName];
        const progressPhaseName = this.getProgressPhaseName(isAccountPending, phaseName);

        if (this.gwPhaseName === GW_PHASES.COMPLETED_LEARNING) {
            return PROGRESS_VALUE.MAX_PROGRESS;
        } else if (phaseName === PHASES.SCALING && pixelConversionsEnum === PIXEL_CONVERSIONS.NO_PIXEL_NO_CONVERSIONS) {
            return durationSincePhaseStartMs < SCALING_ADDITIONAL_TIME_MS.NO_PIXEL && !pastDuration
                ? this.calculateProgress(
                      durationSincePhaseStartMs,
                      progressPhaseName,
                      PROGRESS_PHASE_MAX.NO_PIXEL_SCALING_MAX_PROGRESS
                  )
                : PROGRESS_VALUE.NEGATIVE_INFINITY;
        } else if (PHASES[phaseName]) {
            return pastDuration
                ? progressPhaseMax
                : this.calculateProgress(durationSincePhaseStartMs, progressPhaseName, progressPhaseMax);
        }

        return PROGRESS_VALUE.NEGATIVE_INFINITY;
    }

    getProgressBarColor(phaseName, pixelConversionsEnum) {
        switch (phaseName) {
            case PHASES.SCALING:
                return pixelConversionsEnum !== PIXEL_CONVERSIONS.HAS_PIXEL_HAS_CONVERSIONS
                    ? PROGRESS_COLOR.WARNING
                    : PROGRESS_COLOR.PRIMARY;
            case PHASES.LEARNING:
            case PHASES.EXPEDITED_LEARNING:
                return pixelConversionsEnum === PIXEL_CONVERSIONS.NO_PIXEL_NO_CONVERSIONS
                    ? PROGRESS_COLOR.WARNING
                    : PROGRESS_COLOR.PRIMARY;
            case PHASES.LEARNING_LIMITED:
            case PHASES.EXTENDED_LEARNING:
                return PROGRESS_COLOR.WARNING;
            case PHASES.PENDING_REVIEW:
            default:
                return PROGRESS_COLOR.PRIMARY;
        }
    }

    convertMsToDisplayTimeUnit(remainingTime, id) {
        if (id === REMAINING_TIME_MESSAGE_ID.HOURS_SHORT || id === REMAINING_TIME_MESSAGE_ID.HOURS_LEFT) {
            return Math.round(moment.duration(remainingTime).asHours());
        }

        return Math.round(moment.duration(remainingTime).asDays());
    }

    getTimeLeftDisplayValue(phaseName, remainingTime, id) {
        return Math.max(this.convertMsToDisplayTimeUnit(remainingTime, id), DURATION_MINIMUM[phaseName]);
    }

    getTimeLeftDisplay(phaseName, remainingTime) {
        let value = null;
        let id = null;

        if (phaseName === PHASES.PENDING_REVIEW) {
            if (remainingTime > DURATION_MS.ONE_DAY) {
                id = REMAINING_TIME_MESSAGE_ID.BUSINESS_DAYS;
                value = 2;
            } else if (remainingTime > DURATION_MS.HALF_DAY) {
                id = REMAINING_TIME_MESSAGE_ID.BUSINESS_DAY;
            } else {
                id = REMAINING_TIME_MESSAGE_ID.HOURS_LEFT;
                value = this.getTimeLeftDisplayValue(phaseName, remainingTime, id);
            }
        } else if (this.gwPhaseName === GW_PHASES.COMPLETED_LEARNING) {
            return { id, value };
        } else if (PHASE_REMAINING_TIME_MESSAGE_ID[phaseName]) {
            id = PHASE_REMAINING_TIME_MESSAGE_ID[phaseName];
            value = this.getTimeLeftDisplayValue(phaseName, remainingTime, id);
        }

        return { id, value };
    }

    get phaseProps() {
        return {
            phase: this.phase,
            progress: this.progress,
            progressBarColor: this.progressBarColor,
            timeLeftProps: this.timeLeftProps,
        };
    }
}

export default CampaignStatusPhaseService;
