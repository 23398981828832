import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.SITE_REALTIME].id;

const defaultColumns = [
    FIELDS.PUBLISHER_BLOCKING.field,
    FIELDS.PUBLISHER_BLOCKING_LEVEL.field,
    FIELDS.SITE_NAME.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.VCTR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
    FIELDS.SPENT.field,
    FIELDS.BID_MODIFICATION.field,
    FIELDS.BID_STRATEGY_PER_AFFILIATE.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.PUBLISHER_BLOCKING.field,
    FIELDS.PUBLISHER_BLOCKING_LEVEL.field,
    FIELDS.SITE_NAME.field,
    FIELDS.SITE_ID.field,
    FIELDS.BID_MODIFICATION.field,
    FIELDS.BID_STRATEGY_PER_AFFILIATE.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.VCTR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.CVR.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.VCPM.field,
    FIELDS.CONVERSIONS_VALUE.field,
    FIELDS.ROAS.field,
].map(field => generateColumnId(reportId, field));

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.CLICKS.field,
        FIELDS.VCTR.field,
        FIELDS.CTR.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.CVR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.CPA.field,
        FIELDS.VCPM.field,
        FIELDS.CPM.field,
        FIELDS.SPENT.field,
        FIELDS.AVG_BOOST.field,
        FIELDS.CONVERSIONS_VALUE.field,
        FIELDS.ROAS.field,
    ].map(field => generateColumnId(reportId, field)),
    [REPORT_PRESET.SETUP]: [
        FIELDS.SITE_ID.field,
        FIELDS.BID_MODIFICATION.field,
        FIELDS.BID_STRATEGY_PER_AFFILIATE.field,
    ].map(field => generateColumnId(reportId, field)),
};

export { customColumns, defaultColumns, performanceColumns };
