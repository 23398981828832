import React, { useMemo } from 'react';
import { useSelectedAccount } from 'hooks';
import PERFORMANCE_RECOMMENDATIONS from 'modules/campaigns/modules/performance-recommendations/config/performanceRecommendations';
import { useRecommendationService } from 'modules/campaigns/modules/performance-recommendations/hooks/useRecommendationService';
import { FormattedRelativeTime } from '../../../../../modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';
import { truncateTargetName } from '../../utils';

export const BudgetReachedDepletionNotification = ({ notification, timeDiffInMinutes, closeNotificationCenter }) => {
    const {
        attributes: { recommendationTargetName, recommendationTargetId: campaignId },
        referredEntityId,
    } = notification;

    const [{ id: accountId }] = useSelectedAccount();

    const { editCampaignWithRecommendation: onCardClick } = useRecommendationService();

    const finalRecommendationTargetName = useMemo(
        () => truncateTargetName(recommendationTargetName),
        [recommendationTargetName]
    );

    return (
        <NotificationCard
            notification={notification}
            cardClick={onCardClick}
            cardClickParams={{
                recommendation: {
                    type: PERFORMANCE_RECOMMENDATIONS.BUDGET_V2_IC,
                    id: referredEntityId,
                },
                accountId,
                campaignId,
            }}
            closeNotificationCenter={closeNotificationCenter}
            dataMetricsComponent="NotificationActionCard"
            formattedMessageId="app.notificationsCenter.recommendationCampaignTargetDetails"
            formattedMessageValues={{
                recommendationTargetName: finalRecommendationTargetName,
                recommendationTargetId: campaignId,
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
        />
    );
};
