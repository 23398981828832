import React from 'react';
import classnames from 'classnames/bind';
import { RangeSlider } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './similarityRangeSliderBase.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SimilarityRangeSliderBase = ({
    isDisableSlider,
    onSimilarityChange,
    similarityLevel,
    valueLabelFormat,
    disabledSliderTooltip: DisabledSliderTooltip,
    min,
    max,
    step,
}) => {
    const onSimilarityValueChange = (event, newValue) => {
        onSimilarityChange(newValue);
    };

    return (
        <div className={styles['container']}>
            <FormattedMessage
                id="campaign.editor.targeting.my.audiences.similarity.slider.title"
                defaultMessage="Probability & Scale:"
                className={styles['title']}
            />
            <div className={styles['similarity-section']}>
                <div className={classNameBuilder('slider-label-left', { 'disabled-slider': isDisableSlider })}>
                    <FormattedMessage
                        id="campaign.editor.targeting.my.audiences.similarity.slider.left.label"
                        defaultMessage="Higher Conversion Probability"
                    />
                </div>
                <div data-testid="slider-container">
                    <RangeSlider
                        value={similarityLevel}
                        min={min}
                        max={max}
                        step={step}
                        marks
                        onChange={onSimilarityValueChange}
                        disabled={isDisableSlider}
                        valueLabelFormat={valueLabelFormat}
                    />
                    {isDisableSlider && DisabledSliderTooltip && <DisabledSliderTooltip />}
                </div>
                <div className={classNameBuilder('slider-label-right', { 'disabled-slider': isDisableSlider })}>
                    <FormattedMessage
                        id="campaign.editor.targeting.my.audiences.similarity.slider.right.label"
                        defaultMessage="Larger Scale"
                    />
                </div>
            </div>
        </div>
    );
};
