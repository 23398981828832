import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import { useVtaLookBackWindowValue } from './UseVtaLookBackWindowValue';

export const useVtaLookBackWindowField = () => {
    const defaultValueIndays = useConfigMatch({
        [FEATURE_FLAGS.VTA_LOOKBACK_WINDOW_DEFAULT_VALUE_IN_DAYS]: 'true',
    });
    const vtaLookBackValueDictionary = useVtaLookBackWindowValue(true);
    const lookBackFormFieldType = useFormFieldValue(
        { field: 'vtaLookBackWindow.type' },
        { shouldReturnDaysType: defaultValueIndays }
    );

    const lookBackFormFieldValue = useFormFieldValue(
        { field: 'vtaLookBackWindow.value' },
        {
            value: vtaLookBackValueDictionary[lookBackFormFieldType.value],
        }
    );

    return {
        lookBackFormFieldType,
        lookBackFormFieldValue,
    };
};
