import { useEffect, useRef, useState } from 'react';
import { cloneDeep, fromPairs } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { defaultCropData } from '../config/defaultCropData';
import { cloudinaryResponseToExactPositionTransformer } from '../utils/cropDataUtils';

const ratioMapInitialValue = fromPairs(defaultCropData.map(crop => [crop.ratioName, false])); // create an object with ratioName as key and false as value
export const useCropAreaSize = (setInitialCropData, index) => {
    const { value: crop, onChange: onCropChange } = useFormFieldValue({ field: 'crop' });
    const { value: cropAreaPixels, onChange: onChangeCroppedAreaPixels } = useFormFieldValue({
        field: 'croppedAreaPixels',
    });
    const { value: ratio } = useFormFieldValue({ field: 'ratio' });
    const { value: ratioName } = useFormFieldValue({ field: 'ratioName' });

    const [ratioMap, setRatioMap] = useState(ratioMapInitialValue);

    const [mediaSize, setMediaSize] = useState(null);

    const isDraggable = useRef(false);

    useEffect(() => {
        if (!mediaSize || !cropAreaPixels || ratioMap[ratioName]) {
            return;
        }

        setRatioMap(ratioMap => ({
            ...ratioMap,
            [ratioName]: true,
        }));

        const { x, y } = cloudinaryResponseToExactPositionTransformer({
            mediaSize,
            ratio,
            cropAreaPixels,
        });

        onCropChange(data => ({
            ...data,
            y,
            x,
        }));

        setInitialCropData(prevState => {
            const copyOfPrevState = cloneDeep(prevState);
            if (copyOfPrevState[index]) {
                copyOfPrevState[index].crop = { ...copyOfPrevState[index].crop, x, y };
            }
            return [...copyOfPrevState];
        });

        isDraggable.current = true;
    }, [
        mediaSize,
        cropAreaPixels,
        onCropChange,
        onChangeCroppedAreaPixels,
        ratioName,
        ratioMap,
        ratio,
        crop,
        setInitialCropData,
        index,
    ]);

    return {
        setMediaSize,
        crop,
        cropAreaPixels,
        isDraggable,
    };
};
