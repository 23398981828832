"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgBarGraph(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5V19H5V6.5Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M11 14.5C11 13.6716 11.6716 13 12.5 13C13.3284 13 14 13.6716 14 14.5V19H11V14.5Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M17 8.5C17 7.67157 17.6716 7 18.5 7C19.3284 7 20 7.67157 20 8.5V19H17V8.5Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgBarGraph;