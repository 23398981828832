import { useEffect } from 'react';
import { entries, keyBy } from 'lodash';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';

const getStorageKeys = (paramNamesMap, getStorageKey) =>
    entries(paramNamesMap)
        .map(([paramName, { persist, keepSingleValue }]) => ({
            paramName,
            keepSingleValue,
            persist,
            storageKey: getStorageKey(paramName),
        }))
        .filter(({ keepSingleValue }) => keepSingleValue);

// This hook (effect) is employed to reset a stored value when its dependencies change, effectively when the key changes.
// However, if the dependencies remain the same, the value will be preserved. For example, when opening a side drawer, we want to retain the value.
export const useSingleValueStorageCleaner = ({ getStorageKey, paramNamesMap, removeStorage }) => {
    const paramNamesMapGetter = useCurrentValueGetter(paramNamesMap);
    const getStorageKeyGetter = useCurrentValueGetter(getStorageKey);

    useEffect(() => {
        const oldStorageKeys = getStorageKeys(paramNamesMap, getStorageKey);

        return () => {
            const paramNamesMap = paramNamesMapGetter();
            const currenStorageKeys = keyBy(getStorageKeys(paramNamesMap, getStorageKeyGetter()), 'paramName');
            oldStorageKeys.forEach(({ paramName, persist, storageKey }) => {
                // key can be changed only when dependency is changed
                const isKeyChanged = storageKey !== currenStorageKeys[paramName]?.storageKey;
                const isLastInstanceUnmounted = paramNamesMap[paramName]?.refCount === 0;

                if (isKeyChanged || isLastInstanceUnmounted) {
                    removeStorage(persist, storageKey);
                }
            });
        };
    }, [getStorageKey, paramNamesMap, removeStorage, paramNamesMapGetter, getStorageKeyGetter]);
};
