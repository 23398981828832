'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useWizardContext = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactScroll = require('react-scroll');

var _WizardStep = require('./WizardStep');

var _WizardStep2 = _interopRequireDefault(_WizardStep);

var _WizardStepNavigationButton = require('./WizardStepNavigationButton');

var _WizardStepNavigationButton2 = _interopRequireDefault(_WizardStepNavigationButton);

var _WizardSubstepNavigationButton = require('./WizardSubstepNavigationButton');

var _WizardSubstepNavigationButton2 = _interopRequireDefault(_WizardSubstepNavigationButton);

var _constants = require('./constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Created by oreuveni
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Date: 2018-11-22
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Time: 17:16
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */


var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'wizard__taboola-svg-icon___353lX',
    'wizard-container-wrapper': 'wizard__wizard-container-wrapper___3GlrU',
    'wizard-container': 'wizard__wizard-container___2M5R1',
    'wizard-navigation': 'wizard__wizard-navigation___3Z-WY',
    'wizard-content': 'wizard__wizard-content___180GO',
    'fit-height': 'wizard__fit-height___24swl'
};
var wizardStepStyles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'wizardStepNavigationButton__taboola-svg-icon___3SVV7',
    'wizard-navigation-section': 'wizardStepNavigationButton__wizard-navigation-section___2fV2b',
    'active': 'wizardStepNavigationButton__active___3hG5O',
    'wizard-navigation-button-number': 'wizardStepNavigationButton__wizard-navigation-button-number___1rmTX',
    'wizard-navigation-button-label': 'wizardStepNavigationButton__wizard-navigation-button-label___2cQRm',
    'wizard-label': 'wizardStepNavigationButton__wizard-label___21g4k',
    'wizard-navigation-substeps': 'wizardStepNavigationButton__wizard-navigation-substeps___nSOef'
};


var STEP_SPLITTER = '-';

var getStepAsArray = function getStepAsArray(step) {
    return ('' + step).split(STEP_SPLITTER);
};
var classNameBuilder = _bind2.default.bind(styles);
var WizardContext = (0, _react.createContext)({ step: null, setStep: function setStep() {} });

var useWizardContext = exports.useWizardContext = function useWizardContext() {
    return (0, _react.useContext)(WizardContext);
};

var Wizard = function (_Component) {
    _inherits(Wizard, _Component);

    function Wizard() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, Wizard);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Wizard.__proto__ || Object.getPrototypeOf(Wizard)).call.apply(_ref, [this].concat(args))), _this), _this.state = { activeSubStep: '' }, _this.stepChildMap = {}, _this.isDeepStepControl = function () {
            return !!_this.props.registerStep;
        }, _this.setStep = function (step) {
            var onStepChange = _this.props.onStepChange;

            if (onStepChange) {
                onStepChange(step);
            }
        }, _this.setSubStep = function (activeSubStep) {
            var onStepChange = _this.props.onStepChange;


            if (_this.isDeepStepControl()) {
                onStepChange(activeSubStep);
                return;
            }

            _this.setState({ activeSubStep: activeSubStep });
        }, _this.processSubSteps = function (children) {
            var parentStep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
            var detectSubsteps = arguments[2];
            var _this$props = _this.props,
                substepClassName = _this$props.substepClassName,
                substepActiveClassName = _this$props.substepActiveClassName,
                substepCompleteClassName = _this$props.substepCompleteClassName,
                registerStep = _this$props.registerStep,
                step = _this$props.step,
                navigationCondition = _this$props.navigationCondition;
            var activeSubStep = _this.state.activeSubStep;

            var validChildren = _react2.default.Children.toArray(children).filter(function (child) {
                return _react2.default.isValidElement(child);
            });

            return validChildren.map(function (child, index) {
                var _child$props = child.props,
                    id = _child$props.id,
                    label = _child$props.label,
                    innerChildren = _child$props.children;

                var subStep = '' + parentStep + STEP_SPLITTER + index;

                if (child.type === _WizardStep2.default) {
                    if (_this.isDeepStepControl()) {
                        _this.stepChildMap[subStep] = child;
                        _this.stepChildMap[getStepAsArray(parentStep)[0]] = null;
                    }

                    if (detectSubsteps) {
                        if (_react2.default.Children.count(innerChildren) > 0) {
                            _this.processSubSteps(innerChildren, subStep, detectSubsteps);
                        }
                        return null;
                    }

                    return _react2.default.createElement(
                        'div',
                        { key: id },
                        _react2.default.createElement(_WizardSubstepNavigationButton2.default, {
                            className: substepClassName,
                            activeClassName: substepActiveClassName,
                            completeClassName: substepCompleteClassName,
                            label: label,
                            id: id,
                            subStep: subStep,
                            activeSubStep: _this.isDeepStepControl() ? step : activeSubStep,
                            setActiveSubStep: _this.setSubStep,
                            controlled: _this.isDeepStepControl(),
                            registerStep: registerStep,
                            navigationCondition: navigationCondition
                        }),
                        _react2.default.Children.count(innerChildren) > 0 && _this.processSubSteps(innerChildren, subStep)
                    );
                }

                if (detectSubsteps) {
                    if (_react2.default.Children.count(innerChildren) > 0) {
                        _this.processSubSteps(innerChildren, subStep, detectSubsteps);
                    }
                    return null;
                }

                return _react2.default.Children.count(innerChildren) > 0 && _this.processSubSteps(innerChildren, subStep);
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Wizard, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var step = this.props.step;
            var prevStep = prevProps.step;


            if (prevStep !== step) {
                _reactScroll.animateScroll.scrollToTop({
                    containerId: Wizard.WIZARD_CONTAINER_ID,
                    duration: 0
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                children = _props.children,
                wizardNavigationClassName = _props.wizardNavigationClassName,
                className = _props.className,
                activeClassName = _props.activeClassName,
                completeClassName = _props.completeClassName,
                stepClassName = _props.stepClassName,
                stepActiveClassName = _props.stepActiveClassName,
                wizardTemplateColumns = _props.wizardTemplateColumns,
                wizardContentClassName = _props.wizardContentClassName,
                buttonNumberClassName = _props.buttonNumberClassName,
                fitHeight = _props.fitHeight,
                completeIcon = _props.completeIcon,
                navigationCondition = _props.navigationCondition,
                registerStep = _props.registerStep;
            var step = this.props.step;

            var childArray = _react2.default.Children.toArray(children);
            var activeChild = this.isDeepStepControl() ? this.stepChildMap[step] || null : childArray[step];

            return _react2.default.createElement(
                WizardContext.Provider,
                { value: { step: step, setStep: this.setStep } },
                _react2.default.createElement(
                    'div',
                    {
                        className: classNameBuilder('wizard-container-wrapper', {
                            'fit-height': fitHeight
                        })
                    },
                    _react2.default.createElement(
                        'div',
                        {
                            className: classNameBuilder('wizard-container', {
                                'fit-height': fitHeight
                            }),
                            style: { gridTemplateColumns: wizardTemplateColumns }
                        },
                        _react2.default.createElement(
                            'div',
                            {
                                className: classNameBuilder('wizard-navigation', wizardNavigationClassName, { 'fit-height': fitHeight })
                            },
                            childArray.filter(function (child) {
                                return _react2.default.isValidElement(child) && child.type === _WizardStep2.default;
                            }).map(function (child, index) {
                                var _classNameBuilder;

                                var _child$props2 = child.props,
                                    label = _child$props2.label,
                                    id = _child$props2.id,
                                    innerChildren = _child$props2.children;

                                var isActive = getStepAsArray(step)[0] === '' + index;
                                var isComplete = '' + index < step;
                                var sectionClassName = classNameBuilder(wizardStepStyles['wizard-navigation-section'], className, (_classNameBuilder = {}, _defineProperty(_classNameBuilder, wizardStepStyles['active'], isActive), _defineProperty(_classNameBuilder, activeClassName, isActive), _classNameBuilder), _defineProperty({}, completeClassName, isComplete));

                                _this2.stepChildMap['' + index] = child;

                                return _react2.default.createElement(
                                    'div',
                                    {
                                        className: sectionClassName,
                                        key: id
                                    },
                                    _react2.default.createElement(_WizardStepNavigationButton2.default, {
                                        active: isActive,
                                        label: label,
                                        displayNumber: index + 1,
                                        completeIcon: completeIcon,
                                        isComplete: isComplete,
                                        onClick: function onClick() {
                                            return navigationCondition(function () {
                                                return _this2.setStep('' + index);
                                            }, {
                                                isComplete: isComplete,
                                                newStep: '' + index
                                            });
                                        },
                                        className: stepClassName,
                                        activeClassName: stepActiveClassName,
                                        buttonNumberClassName: buttonNumberClassName,
                                        registerStep: registerStep
                                    }),
                                    _react2.default.Children.count(innerChildren) > 0 && (isActive ? _react2.default.createElement(
                                        'div',
                                        null,
                                        _this2.processSubSteps(innerChildren, '' + index, !isActive)
                                    ) : _this2.processSubSteps(innerChildren, '' + index, !isActive))
                                );
                            })
                        ),
                        _react2.default.createElement(
                            'div',
                            {
                                id: '__ultimate-ui-wizard-content',
                                className: classNameBuilder('wizard-content', wizardContentClassName, { 'fit-height': fitHeight })
                            },
                            activeChild
                        )
                    )
                )
            );
        }
    }]);

    return Wizard;
}(_react.Component);

Wizard.propTypes = {
    /** The initial step, first step is 0 */
    step: _propTypes2.default.string,
    /** Contents of the wizard */
    children: _propTypes2.default.node,
    /** Fires when a step changes */
    onStepChange: _propTypes2.default.func,
    /** Should be passed for deep step control */
    registerStep: _propTypes2.default.func,
    /** The Wizard offset from the top of the page */
    offset: _propTypes2.default.number,
    /** Class name for wizard navigation container */
    wizardNavigationClassName: _propTypes2.default.string,
    /** The template columns definition. Accepts all values that 'grid-template-columns' accepts  */
    wizardTemplateColumns: _propTypes2.default.string,
    /** Class name for wizard navigation section */
    className: _propTypes2.default.string,
    /** Class name for wizard content section */
    wizardContentClassName: _propTypes2.default.string,
    /** Class name for wizard navigation section when active */
    activeClassName: _propTypes2.default.string,
    /** Class name for wizard navigation section when complete */
    completeClassName: _propTypes2.default.string,
    /** Class name for step navigation link */
    stepClassName: _propTypes2.default.string,
    /** Class name for step navigation link when active */
    stepActiveClassName: _propTypes2.default.string,
    /** Class name for substep navigation link */
    substepClassName: _propTypes2.default.string,
    /** Class name for substep navigation link when active */
    substepActiveClassName: _propTypes2.default.string,
    /** Class name for substep navigation link when complete */
    substepCompleteClassName: _propTypes2.default.string,
    /** Class name for button number next to each step */
    buttonNumberClassName: _propTypes2.default.string,
    /** When passed will consume 100% of it container */
    fitHeight: _propTypes2.default.bool,
    completeIcon: _propTypes2.default.node,
    navigationCondition: _propTypes2.default.func
};

Wizard.defaultProps = {
    wizardTemplateColumns: '15% 85%',
    fitHeight: true,
    navigationCondition: function navigationCondition(callback) {
        return callback && callback();
    }
};

Wizard.WIZARD_CONTAINER_ID = _constants.WIZARD_CONTAINER_ID;
Wizard.getStepAsArray = getStepAsArray;

exports.default = Wizard;