import React from 'react';
import { EditOIcon as EditIcon } from 'taboola-ultimate-ui';
import { CreativeStudioBaseHeader } from '../CreativeStudioBaseHeader';

export const EditFallbackImageContentHeader = () => (
    <CreativeStudioBaseHeader
        icon={<EditIcon />}
        titleMessageId="creative.studio.editFallbackImage.header.title"
        subTitleMessageId="creative.studio.editFallbackImage.header.sub.title"
        helpTooltipMessageId="creative.studio.editFallbackImage.header.sub.title.helpText"
    />
);
