import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import AUDIENCE_DATA_TYPE_MODE from '../../../../../constants/audienceDataTypeMode';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import { generateBulkActionsOnDuplicateHandler } from '../audience/generateBulkActionsOnDuplicateHandler';
import columnDefinitions from './columnDefinitions';
import graph from './graph';
import { customColumns, defaultColumns } from './reportPresets';

export const audienceBundlesReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.AUDIENCE_BUNDLES],
    endpoint: 'campaign-report-by-audience-bundles',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    isEmptyFooter: true,
    searchPlaceholderMsgId: 'app.reports.byAudience.search.placeholder',
    searchField: FIELDS.AUDIENCE_NAME.field,
    sortMap: {
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    bulkActions: [{ key: BULK_ACTION.DUPLICATE, handler: generateBulkActionsOnDuplicateHandler }],
    filters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.AUDIENCE_DATA_TYPE_MODE,
            permissions: ['ADS_CONSOLE_AUDIENCE_DATA_TYPE_FILTER'],
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP200, label: 'Top 200', messageId: 'report.topResults.200' }],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
        {
            id: FILTER_TYPE.AUDIENCE_DATA_TYPE_MODE,
            type: FILTER_TYPE.AUDIENCE_DATA_TYPE_MODE,
            values: [
                {
                    value: AUDIENCE_DATA_TYPE_MODE.ALL,
                    label: 'All Audiences',
                    messageId: 'report.audienceDataTypeMode.ALL',
                },
            ],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
    ],
};

export default audienceBundlesReportConfig;
