import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isPathActive } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { InternalLink } from '../../InternalLink';
import styles from './navigationItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const NavigationItem = ({ navItem, activeClassName, itemsClassName, iconClass }) => {
    const {
        id,
        icon: IconComponent,
        hideNavItem = false,
        caption,
        basePath = '',
        navigationItemContent: NavigationItemContent,
        metricsAttributes,
    } = navItem;

    const location = useLocation();

    if (hideNavItem) {
        return null;
    }

    const destinationPath = `${basePath}/${id}`;
    const match = isPathActive(location.pathname, destinationPath);
    const className = classNameBuilder('container', itemsClassName, { active: match }, match ? activeClassName : null);

    return (
        <InternalLink className={className} path={destinationPath} {...metricsAttributes}>
            {NavigationItemContent ? (
                <NavigationItemContent icon={IconComponent} caption={caption} className={iconClass} />
            ) : (
                <Fragment>
                    {IconComponent ? <IconComponent className={classNameBuilder(styles['icon'], iconClass)} /> : null}
                    <FormattedMessage id={caption.id} defaultMessage={caption.default} />
                </Fragment>
            )}
        </InternalLink>
    );
};

NavigationItem.propTypes = {
    navItem: PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        caption: PropTypes.object,
        basePath: PropTypes.string,
        hideNavItem: PropTypes.bool,
        accountConfigurations: PropTypes.object,
        componentProps: PropTypes.object,
        metricsAttributes: PropTypes.object,
        iconClass: PropTypes.string,
    }),
    onNavigation: PropTypes.func,
};

NavigationItem.defaultProps = {
    onNavigation: () => {},
};

export default NavigationItem;
