import React, { useState } from 'react';
import { Button, UploadOutlinedIcon } from 'tuui';
import { FormField, InputWithButton, useDragAndDropFileContext, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { getValidationIndicationType } from '../../../../../../../taboola-common-frontend-modules/validations/utils';
import { CREATIVE_TYPE } from '../../../../../../config';
import { ThumbnailsFieldTooltip } from '../../../../../creative-creator/components/ThumbnailsField';
import ThumbnailsTooltipForUrl from '../../../../../creative-creator/components/ThumbnailsPicker/ThumbnailsTooltipForUrl';
import UrlTabUploadDescription from '../../UrlTab/UrlTabUploadDescription';
import styles from './fromImageSubTabUploadFields.module.scss';

const UrlInput = withIndication(InputWithButton);
const ButtonWithIndication = withIndication(Button);

const FromImageSubTabUploadFields = ({ imageError, urlError, onAddClick }) => {
    const [urlInput, setUrlInput] = useState('');
    const { openFileDialog } = useDragAndDropFileContext();
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.editor.tab.aiGenerated.url.placeholder',
        defaultMessage: 'www.example.com/Image.png',
    });

    return (
        <div>
            <FormField
                containerClass={styles['form-field']}
                label={<FormattedMessage id="creative.editor.tab.upload.device.title" defaultMessage="From Device" />}
                description={<UrlTabUploadDescription />}
                helpText={<ThumbnailsFieldTooltip />}
            >
                <ButtonWithIndication
                    onClick={openFileDialog}
                    size={Button.size.lg}
                    variant={Button.variant.ghost}
                    message={imageError}
                >
                    <UploadOutlinedIcon />
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.upload.button"
                        defaultMessage="Upload Image"
                    />
                </ButtonWithIndication>
            </FormField>
            <FormField
                helpText={<ThumbnailsTooltipForUrl creativeType={CREATIVE_TYPE.IMAGE} />}
                containerClass={styles['form-field']}
                label={<FormattedMessage id="creative.editor.tab.upload.url" defaultMessage="From URL" />}
                description={
                    <FormattedMessage id="creative.editor.url.subtitle" defaultMessage="Type ad URL address." />
                }
            >
                <UrlInput
                    id="edit-creative-url-input-ai"
                    value={urlInput}
                    placeholder={placeholder}
                    onButtonClick={onAddClick}
                    onChange={setUrlInput}
                    buttonLabel={<FormattedMessage id="creative.editor.url.button" defaultMessage="ADD" />}
                    indicationType={getValidationIndicationType(urlError)}
                    message={urlError && <FormattedMessage id={urlError} defaultMessage="Error" />}
                    type={InputTypes.URL}
                    size="large"
                />
            </FormField>
        </div>
    );
};

export default FromImageSubTabUploadFields;
