import _ from 'lodash';

export const ExcludeDaysValidations = [
    {
        validationFn: (value, options, { conditions }) => {
            const daysToExclude = parseInt(value);
            const invalidDaysToExcludeValue = _.some(conditions, condition => {
                return _.isNumber(condition.lookbackWindowInDays) && condition.lookbackWindowInDays < daysToExclude;
            });
            return !invalidDaysToExcludeValue;
        },
        messageId: 'validations.error.performance.rules.exclude.days.larger.than.look.back.window',
        defaultMessage: 'The number of days to exclude cannot exceed the rule’s actual time frame.',
    },
];
