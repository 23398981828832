'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.normalizeInputProps = exports.ComponentMap = exports.INPUT_TYPES_LIST = exports.INPUT_TYPES = undefined;

var _ComponentMap;

var _Inputs = require('../../Inputs');

var _DropdownMenus = require('../../DropdownMenus');

var _Buttons = require('../../Buttons');

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DROPDOWN = 'DROPDOWN';
var ASYNC_DROPDOWN = 'ASYNC_DROPDOWN';
var PAGINATED_DROPDOWN = 'PAGINATED_DROPDOWN';
var INPUT = 'INPUT';
var BUTTON = 'BUTTON';
var STYLED_BUTTON = 'STYLED_BUTTON';

var INPUT_TYPES = exports.INPUT_TYPES = {
    DROPDOWN: DROPDOWN,
    ASYNC_DROPDOWN: ASYNC_DROPDOWN,
    PAGINATED_DROPDOWN: PAGINATED_DROPDOWN,
    INPUT: INPUT,
    BUTTON: BUTTON,
    STYLED_BUTTON: STYLED_BUTTON
};

var INPUT_TYPES_LIST = exports.INPUT_TYPES_LIST = Object.values(INPUT_TYPES);

var ComponentMap = exports.ComponentMap = (_ComponentMap = {}, _defineProperty(_ComponentMap, DROPDOWN, _DropdownMenus.BareDropdown), _defineProperty(_ComponentMap, ASYNC_DROPDOWN, _DropdownMenus.AsyncDropdown), _defineProperty(_ComponentMap, PAGINATED_DROPDOWN, _DropdownMenus.PaginatedDropdown), _defineProperty(_ComponentMap, INPUT, _Inputs.Input), _defineProperty(_ComponentMap, BUTTON, _Buttons.Button), _defineProperty(_ComponentMap, STYLED_BUTTON, _Buttons.StyledButton), _ComponentMap);

var getComponentDefaultProps = function getComponentDefaultProps(_ref) {
    var type = _ref.type,
        data = _ref.data,
        dataKey = _ref.dataKey,
        updateData = _ref.updateData;

    switch (type) {
        case DROPDOWN:
        case ASYNC_DROPDOWN:
            return {
                onChange: function onChange(value) {
                    return updateData(dataKey, value);
                }
            };
        case INPUT:
            return {
                onChange: function onChange(e) {
                    return updateData(dataKey, e.target.value);
                }
            };
        case BUTTON:
        case STYLED_BUTTON:
            return {
                onClick: function onClick() {
                    return updateData(dataKey, !data[dataKey]);
                }
            };
        default:
            return {};
    }
};

var normalizeInputProps = exports.normalizeInputProps = function normalizeInputProps(_ref2) {
    var type = _ref2.type,
        data = _ref2.data,
        dataKey = _ref2.dataKey,
        updateData = _ref2.updateData,
        props = _ref2.props;
    return Object.assign({}, getComponentDefaultProps({
        type: type, data: data, dataKey: dataKey, updateData: updateData
    }), props);
};