import { useMemo } from 'react';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TryGenAiTooltip } from '../TryGenAiTooltip';
import { useRecentlyUsedMediaSubTabTooltipShowing } from './useRecentlyUsedMediaSubTabTooltipShowing';
import styles from './useRecentlyUsedMediaSubTabTooltip.module.scss';

export const useRecentlyUsedMediaSubTabTooltip = ({ disabled }) => {
    const [showingGenAiTooltip, setShowingGenAiTooltip] = useRecentlyUsedMediaSubTabTooltipShowing({ disabled });
    const initialGalleryItemTooltip = useMemo(() => {
        return showingGenAiTooltip
            ? props => <TryGenAiTooltip {...props} onClose={() => setShowingGenAiTooltip(false)} />
            : null;
    }, [setShowingGenAiTooltip, showingGenAiTooltip]);
    const initialGalleryItemTooltipProps = useMemo(
        () => ({
            showing: true,
            interactive: true,
            position: TOOLTIP_POSITION.RIGHT_END,
            boxClassName: styles['tooltip-box'],
            tailClassName: styles['tooltip-tail'],
            maskClassName: styles['tooltip-mask'],
            tailHeight: 9,
            offsetBody: 20,
            listenToAllParentScrolls: true,
        }),
        []
    );

    return {
        initialGalleryItemTooltip,
        initialGalleryItemTooltipProps,
    };
};
