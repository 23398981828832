'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRadioGroup = require('react-radio-group');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Indications = require('../../Indications');

var _radioSize = require('./radioSize');

var _radioSize2 = _interopRequireDefault(_radioSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'checkedRadioDefaultColor': 'var(--green-5)',
    'errorRadioDefaultColor': 'var(--red-3)',
    'errorBorderColor': 'var(--red-3)',
    'warningBorderColor': 'var(--yellow-3)',
    'taboola-svg-icon': 'baseRadio__taboola-svg-icon___3yICs',
    'radio-normal': 'baseRadio__radio-normal___2jf4u',
    'custom-radio-point': 'baseRadio__custom-radio-point___wdrOM',
    'radio-small': 'baseRadio__radio-small___1FsTU',
    'custom-radio': 'baseRadio__custom-radio___3rXzJ',
    'label': 'baseRadio__label___2WzJr',
    'radio-input': 'baseRadio__radio-input___1uMgR'
};

// eslint-disable-next-line react/prefer-stateless-function
var BaseRadio = function (_Component) {
    _inherits(BaseRadio, _Component);

    function BaseRadio() {
        _classCallCheck(this, BaseRadio);

        return _possibleConstructorReturn(this, (BaseRadio.__proto__ || Object.getPrototypeOf(BaseRadio)).apply(this, arguments));
    }

    _createClass(BaseRadio, [{
        key: 'render',
        value: function render() {
            var selectedValue = this.context.radioGroup.selectedValue;

            var _props = this.props,
                value = _props.value,
                inputClassName = _props.inputClassName,
                labelClassName = _props.labelClassName,
                checkedLabelClassName = _props.checkedLabelClassName,
                size = _props.size,
                disabled = _props.disabled,
                checkedRadioColor = _props.checkedRadioColor,
                errorRadioColor = _props.errorRadioColor,
                children = _props.children,
                onMouseEnter = _props.onMouseEnter,
                onMouseLeave = _props.onMouseLeave,
                error = _props.error,
                indicationType = _props.indicationType,
                hideRadioPointer = _props.hideRadioPointer,
                rest = _objectWithoutProperties(_props, ['value', 'inputClassName', 'labelClassName', 'checkedLabelClassName', 'size', 'disabled', 'checkedRadioColor', 'errorRadioColor', 'children', 'onMouseEnter', 'onMouseLeave', 'error', 'indicationType', 'hideRadioPointer']);

            var checked = selectedValue === value;
            var borderColor = error && errorRadioColor || styles[indicationType + 'BorderColor'] || checkedRadioColor;
            var radioColorStyle = {
                labelBorder: checked ? { borderColor: borderColor } : {},
                radioBorder: checked ? { border: '1px solid ' + checkedRadioColor } : {},
                radioPointer: { backgroundColor: '' + checkedRadioColor }
            };
            var labelMergedClassName = styles['label'] + ' ' + labelClassName + ' ' + (checked ? checkedLabelClassName : '');
            var inputMergedClassName = styles['custom-radio'] + ' ' + styles['radio-' + size] + ' ' + inputClassName;

            return _react2.default.createElement(
                'label',
                { className: labelMergedClassName, disabled: disabled, style: radioColorStyle.labelBorder, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
                _react2.default.createElement(_reactRadioGroup.Radio, Object.assign({}, rest, { value: value, className: '' + styles['radio-input'], disabled: disabled })),
                hideRadioPointer ? null : _react2.default.createElement(
                    'span',
                    { className: inputMergedClassName, style: radioColorStyle.radioBorder },
                    _react2.default.createElement('span', { className: '' + styles['custom-radio-point'], style: radioColorStyle.radioPointer })
                ),
                children
            );
        }
    }]);

    return BaseRadio;
}(_react.Component);

BaseRadio.contextTypes = {
    radioGroup: _propTypes2.default.object
};

BaseRadio.propTypes = {
    /** Value of the radio */
    value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.bool]).isRequired,
    /** Component to displayed next to the radio input */
    children: _propTypes2.default.node,
    /** Size of the radio */
    size: _propTypes2.default.oneOf(Object.values(_radioSize2.default)),
    /** Disable / Enable flag */
    disabled: _propTypes2.default.bool,
    /** Apply class to style the radio label */
    labelClassName: _propTypes2.default.string,
    /** Apply class to style the radio input */
    inputClassName: _propTypes2.default.string,
    /** Apply class to checked label */
    checkedLabelClassName: _propTypes2.default.string,
    /** On mouse enter action */
    onMouseEnter: _propTypes2.default.func,
    /** On mouse leave action */
    onMouseLeave: _propTypes2.default.func,
    /** Checked Radio input color */
    checkedRadioColor: _propTypes2.default.string,
    /** Error Radio input color */
    errorRadioColor: _propTypes2.default.string,
    /** Error control */
    error: _propTypes2.default.bool,
    /** Additional indication by type */
    indicationType: _propTypes2.default.oneOf(Object.values(_Indications.TYPES)),
    /** Hide radio pointer */
    hideRadioPointer: _propTypes2.default.bool
};

BaseRadio.defaultProps = {
    labelClassName: '',
    inputClassName: '',
    checkedLabelClassName: '',
    size: _radioSize2.default.NORMAL,
    disabled: false,
    error: false,
    checkedRadioColor: styles.checkedRadioDefaultColor,
    errorRadioColor: styles.errorRadioDefaultColor,
    hideRadioPointer: false
};

exports.default = BaseRadio;