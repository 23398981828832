import { FORM_MODES } from 'config/formModes';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { ACCOUNT_PARAM_TEMPLATE } from './account';
import { CONVERSION_ID_PARAM_TEMPLATE } from './conversion';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';
import { FUNNEL_ID_PARAM_TEMPLATE } from './funnel';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const CONVERSIONS_EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${CONVERSION_ID_PARAM_TEMPLATE}`;
export const FUNNELS_EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${FUNNEL_ID_PARAM_TEMPLATE}`;

export const TRACKING_SETTINGS_ROUTE = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.TRACKING_SETTINGS],
        mode: [FORM_MODES.EDIT],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const CONVERSION_FUNNEL_CREATOR_ROUTE_PATH = replacePathParams(
    FUNNELS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.FUNNEL],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const CONVERSION_CREATOR_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CONVERSION],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const CONVERSION_EDITOR_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.CONVERSION],
        mode: [FORM_MODES.EDIT],
    },
    ['conversionId'],
    KEY_TO_PATH_MAP_MAP
);

export const MANUALLY_SETUP_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.MANUALLY_SETUP],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const GTM_SETUP_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.GTM_SETUP],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const S2S_SETUP_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.S2S_SETUP],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const SHOPIFY_SETUP_ROUTE_PATH = replacePathParams(
    CONVERSIONS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.SHOPIFY_SETUP],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const FUNNEL_EDITOR_ROUTE_PATH = replacePathParams(
    FUNNELS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.FUNNEL],
        mode: [FORM_MODES.EDIT],
    },
    ['funnelId'],
    KEY_TO_PATH_MAP_MAP
);

const MAX_DEPTH_OF_URL = CONVERSIONS_EDITOR_ROUTE_PATH.split('/').length;
export const WILDCARD_PATH = '/*'.repeat(MAX_DEPTH_OF_URL);
