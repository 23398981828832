import thunk from 'redux-thunk';
import { errorMiddleware } from 'modules/errors';
import { abtMiddleware } from 'modules/taboola-common-frontend-modules/ab-test';
import { createGtmMiddleware } from 'modules/taboola-common-frontend-modules/gtmTracker';
import config from './config';
import { navigation, statePersister, abortRequest } from './middlewares';

const middlewares = [
    errorMiddleware,
    thunk,
    statePersister,
    abortRequest,
    navigation,
    abtMiddleware,
    createGtmMiddleware(config.analyticsEventCreators),
];

export default middlewares;
