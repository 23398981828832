import { useSelectedAccount } from 'hooks';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useAccountConfig,
    useCommonConfig,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { isLowTouchAccount } from '../../../../../../account-management';

export const useLowTierMaxDailyLimit = () => {
    const [{ tier }] = useSelectedAccount();
    const isLowTouch = isLowTouchAccount(tier);
    const { [COMMON_FLAGS.LOW_TIER_DAILY_LIMIT_VALIDATION_ENABLED]: isLowTierDailyLimitValidationEnable } =
        useCommonConfig([COMMON_FLAGS.LOW_TIER_DAILY_LIMIT_VALIDATION_ENABLED]);
    const { [FEATURE_FLAGS.LOW_TIER_DAILY_LIMIT_MAX_VALUE]: maxLowTierDailyLimitValue } = useAccountConfig(
        FEATURE_FLAGS.LOW_TIER_DAILY_LIMIT_MAX_VALUE
    );

    const numberMaxLowTierDailyLimitValue = Number(maxLowTierDailyLimitValue);
    if (isNaN(numberMaxLowTierDailyLimitValue)) {
        return null;
    }

    const isValidationEnableForAccount = isLowTouch && isLowTierDailyLimitValidationEnable;

    return isValidationEnableForAccount === 'true' ? maxLowTierDailyLimitValue : null;
};
