import React, { useState } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PromptHelperCategoryDropdownButton } from './PromptHelperCategoryDropdownButton';
import { PromptHelperCategoryModalButton } from './PromptHelperCategoryModalButton';
import { CATEGORIES } from './config/categories';
import { OPTIONS } from './config/options';
import { Color } from './icons/Color';
import { Emotion } from './icons/Emotion';
import { Focus } from './icons/Focus';
import { Lighting } from './icons/Lighting';
import { Style } from './icons/Style';
import styles from './promptHelper.module.scss';

export const PromptHelper = () => {
    const [hoveredCategory, setHoveredCategory] = useState(null);

    return (
        <div className={styles['container']}>
            <div className={styles['categories']}>
                <PromptHelperCategoryDropdownButton
                    category={CATEGORIES.FOCUS}
                    setHoveredCategory={setHoveredCategory}
                    options={[OPTIONS.CLOSE_UP, OPTIONS.MID_SHOT, OPTIONS.LONG_SHOT]}
                    icon={<Focus />}
                />
                <PromptHelperCategoryModalButton
                    category={CATEGORIES.STYLE}
                    setHoveredCategory={setHoveredCategory}
                    options={[OPTIONS.PHOTOJOURNALISM, OPTIONS.PROFESSIONAL, OPTIONS.STREET, OPTIONS.FLASH]}
                    icon={<Style />}
                />
                <PromptHelperCategoryDropdownButton
                    category={CATEGORIES.COLOR}
                    setHoveredCategory={setHoveredCategory}
                    options={[OPTIONS.COLOR, OPTIONS.BLACK_AND_WHITE]}
                    icon={<Color />}
                />
                <PromptHelperCategoryModalButton
                    category={CATEGORIES.LIGHTING}
                    setHoveredCategory={setHoveredCategory}
                    options={[OPTIONS.NATURAL, OPTIONS.GOLDEN, OPTIONS.MIDDAY]}
                    icon={<Lighting />}
                />
                <PromptHelperCategoryDropdownButton
                    category={CATEGORIES.EMOTION}
                    setHoveredCategory={setHoveredCategory}
                    options={[
                        OPTIONS.CONFIDENT,
                        OPTIONS.EXCITED,
                        OPTIONS.FRUSTRATED,
                        OPTIONS.MOTIVATED,
                        OPTIONS.NERVOUS,
                        OPTIONS.OPTIMISTIC,
                        OPTIONS.PASSIONATE,
                        OPTIONS.RELAXED,
                    ]}
                    icon={<Emotion />}
                />
            </div>
            <div className={styles['description']}>
                {hoveredCategory && (
                    <FormattedMessage
                        id={`creative.editor.tab.aiGenerated.fromPrompt.helper.description.${hoveredCategory}`}
                        defaultMessage="Add a unique touch to your images with various style options to suit your creative vision."
                    />
                )}
            </div>
        </div>
    );
};
