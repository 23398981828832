import { useMemo } from 'react';
import useLifetimePreset from './useLifetimePreset';

//Hook created to allow for easy addition of
//future dynamic presets, even though only Lifetime
//preset exists at time of creation. Delete this comment
//once a new dynamic preset is added.
const useDynamicRangePickerPresets = ({ timeZoneName }) => {
    const lifetimePreset = useLifetimePreset({ timeZoneName });

    const dynamicPresets = useMemo(
        () => ({
            ...lifetimePreset,
        }),
        [lifetimePreset]
    );

    return dynamicPresets;
};

export default useDynamicRangePickerPresets;
