import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations/flags';
import { isNetworkAccountType, isVideoAccount } from '../../../../account-management/accountType.js';

export const getIsBundlingAllowedOnAccount = selectedAccount => {
    const { type, partnerTypes } = selectedAccount;
    const isNonNetworkVideoAccount = !isNetworkAccountType(type) && isVideoAccount(partnerTypes);

    return !isNonNetworkVideoAccount;
};
export const getIsMarketplaceAudiencesBundlingEnabled = (accountConfig, selectedAccount) => {
    const isBundlingEnabled = accountConfig?.[FEATURE_FLAGS.MARKETPLACE_AUDIENCES_BUNDLING_ENABLED] === 'true';

    return isBundlingEnabled && getIsBundlingAllowedOnAccount(selectedAccount);
};
