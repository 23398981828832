import React, { useState, useCallback } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ERROR_MSG_PARAMS_SYM } from 'modules/taboola-common-frontend-modules/validations/services/validationService';
import { AI_VARIATIONS_EVENTS_PREFIX } from '../../modules/creative-creator/constants/aiVariationsConstants';
import { ContentTitle } from '../ContentEditableText';
import { SuggestionsWrapper } from '../SuggestionsWrapper';
import { useSuggestionCustomIndicator } from './hooks/useSuggestionCustomIndication';
import { SUGGESTION_TYPES } from './hooks/useTitleSuggestions';
import styles from './suggestionContentTitle.module.scss';

const classNameBuilder = classnames.bind(styles);
const SUGGESTION_TIMING = {
    [SUGGESTION_TYPES.REPHRASE]: 40,
    [SUGGESTION_TYPES.FIX_DKI]: 2,
};

export const SuggestionContentTitle = ({
    field = 'title',
    campaignId,
    containerClassName,
    onFocus = noop,
    onBlur = noop,
    gaEventsPrefix = AI_VARIATIONS_EVENTS_PREFIX,
    openModal,
    onChangeCallback = noop,
    validationDependencies: validationDependenciesProp = {},
    ...rest
}) => {
    const pageName = getPageName();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { value: title, onChange: setTitle } = useFormFieldValue({
        field,
    });
    const [suggestionType, setSuggestionType] = useState(SUGGESTION_TYPES.REPHRASE);

    const onIndicationDataChange = useCallback(indicationData => {
        const errMessageId = indicationData?.[ERROR_MSG_PARAMS_SYM]?.id;

        if (errMessageId === 'creative.creator.aiTitleSuggestions.invalid.dki') {
            setSuggestionType(SUGGESTION_TYPES.FIX_DKI);
        } else {
            setSuggestionType(SUGGESTION_TYPES.REPHRASE);
        }
    }, []);

    const {
        shouldShowCustomIndicator,
        CustomIndicator,
        shouldHideOptions,
        suggestions,
        shouldRenderLoader,
        validationDependencies,
        resetCustomIndicatorError,
        onDropdownChange,
    } = useSuggestionCustomIndicator({
        campaignId,
        title,
        validationDependencies: validationDependenciesProp,
        isHovered,
        setIsHovered,
        isDropdownOpen,
        setIsDropdownOpen,
        gaEventsPrefix,
        openModal,
        pageName,
        setTitle,
        type: suggestionType,
    });

    const onTitleChangeCallback = useCallback(
        val => {
            onChangeCallback(val);
            if (val !== title) {
                resetCustomIndicatorError();
            }
        },
        [onChangeCallback, resetCustomIndicatorError, title]
    );

    return (
        <SuggestionsWrapper
            dropdownStyles={{
                menuList: baseStyles => ({
                    ...baseStyles,
                    ...(suggestionType === SUGGESTION_TYPES.FIX_DKI
                        ? {
                              maxHeight: '115px',
                          }
                        : {}),
                }),
            }}
            className={containerClassName}
            onDropdownChange={onDropdownChange}
            options={suggestions}
            optionsLoading={shouldRenderLoader}
            shouldHideOptions={shouldHideOptions}
            loadingMessage={
                <FormattedMessage
                    id="creative.creator.content.title.loading.bar"
                    defaultMessage={"We're working on generating your titles. It will only take a few seconds."}
                />
            }
            loadingMessageDuration={SUGGESTION_TIMING[suggestionType]}
            onMouseOver={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <ContentTitle
                field={field}
                customIndicator={shouldShowCustomIndicator ? CustomIndicator : null}
                isShowLengthIndicator={!shouldShowCustomIndicator}
                isShowLengthIndicatorAlways={true}
                onChangeCallback={onTitleChangeCallback}
                validationDependencies={validationDependencies}
                onFocus={() => {
                    setIsDropdownOpen(true);
                    onFocus();
                }}
                indicationContainerClass={classNameBuilder({ hidden: !shouldHideOptions || shouldRenderLoader })}
                onBlur={() => {
                    if (!isHovered) {
                        setIsDropdownOpen(false);
                    }
                    onBlur();
                }}
                onIndicationDataChange={onIndicationDataChange}
                {...rest}
            />
        </SuggestionsWrapper>
    );
};
