import { useCallback, useState } from 'react';
import { useRef } from 'react';
import { isEmpty, keyBy, uniq } from 'lodash';
import { LINE_DELIMITER } from '../../../../../../../services/utils/stringUtils';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { copyToClipboard } from '../../../../../services/utils/clipboard';
import { useSupplyTargetingFormField } from '../../SupplyTargeting/hooks/useSupplyTargetingFormField';
import { siteResourceFieldKeyName } from '../const';
import { useFetchSites } from './useFetchSites';

const emptyList = [];

export const useSiteClipboardOperations = ({ list = emptyList, addList, deleteList } = {}) => {
    const {
        formAccount: { accountName },
    } = useFormDataContext();
    const submittedValuesCount = useRef(0);
    const [hasError, setHasError] = useState(false);
    const copySitesToClipboard = useCallback(items => {
        copyToClipboard(items.map(item => item[siteResourceFieldKeyName]).join(LINE_DELIMITER));
    }, []);
    const { checkValidSites } = useFetchSites();
    const { value: supplyGroup } = useSupplyTargetingFormField();
    const fetchValidSitesByIds = useCallback(
        async ids => {
            try {
                const { results } = await checkValidSites(accountName, ids, supplyGroup);
                return results;
            } catch (e) {
                // no need to handle
            }
        },
        [checkValidSites, accountName, supplyGroup]
    );
    const submitMultiline = async inputList => {
        const currentValuesMap = keyBy(list, siteResourceFieldKeyName);
        const filteredList = uniq(inputList).filter(value => !currentValuesMap[value]);
        const rawItems = filteredList.map(textLine => ({
            accountId: textLine,
            accountDescription: textLine,
            accountName: textLine,
            loading: true,
        }));

        try {
            addList(rawItems);
            const validItems = isEmpty(filteredList) ? filteredList : await fetchValidSitesByIds(filteredList);

            deleteList(rawItems);
            addList(validItems.map(item => ({ ...item, isTrusted: true })));
            const invalidItems = filteredList
                .filter(
                    textItem =>
                        !validItems.find(
                            validItem =>
                                String(validItem.accountId) === textItem ||
                                validItem.accountName === textItem ||
                                validItem.accountDescription === textItem
                        )
                )
                .join(LINE_DELIMITER);

            if (invalidItems) {
                submittedValuesCount.current = inputList.length;
                setHasError(true);
            }
            return invalidItems;
        } catch (e) {
            deleteList(rawItems);
            return inputList.join(LINE_DELIMITER);
        }
    };

    return {
        copySitesToClipboard,
        submitMultiline,
        hasError,
        setHasError,
        submittedValuesCount: submittedValuesCount.current,
    };
};
