import { useIsMobile } from 'taboola-ultimate-ui';
import { config } from 'modules/taboola-common-frontend-modules/authentication';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';

export const useChatAgentPermitted = () => {
    const isConfigured = config.getChatAgentEnabled();
    const isPermitted = usePermissions('CHAT_AGENT_PERMISSION');
    const isMobile = useIsMobile();

    return isPermitted && isConfigured && !isMobile;
};
