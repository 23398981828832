"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgLocation(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M12 20c-1.99 0-6-6.486-6-10.018C6 6.678 8.686 4 12 4s6 2.678 6 5.982C18 13.514 13.99 20 12 20zm0-13.972a3.983 3.983 0 0 0-4 3.954c.203 1.91.88 3.737 1.972 5.32.46.754.982 1.47 1.564 2.134.236.25.464.456.464.456s.23-.2.465-.456a15.26 15.26 0 0 0 1.563-2.135c1.09-1.58 1.77-3.41 1.972-5.318a3.983 3.983 0 0 0-4-3.954v-.001zm0 5.996a1.982 1.982 0 0 1-1.972-1.99 1.984 1.984 0 0 1 1.99-1.97A1.984 1.984 0 0 1 14 10.044a1.988 1.988 0 0 1-2 1.98z"
            })
        )
    );
}
exports.default = SvgLocation;