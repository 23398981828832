import { find, isEmpty, keyBy, mapValues, merge, omit } from 'lodash';
import {
    FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS,
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS,
    RESET_STATE_TO_INITIAL,
} from 'actions';
import { createReducer } from 'modules/taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { COMPONENT_STATUS } from 'services/constants';
import { addPersistHandler } from 'services/persister';
import {
    DELETE_PERFORMANCE_RULE_SUCCESS,
    DUPLICATE_PERFORMANCE_RULE_SUCCESS,
    UPDATE_PERFORMANCE_RULE_SUCCESS,
} from '../../performance-rules/actions/actionTypes';
import {
    DELETE_SCHEDULED_REPORT_SUCCESS,
    UPDATE_SCHEDULED_REPORT_SUCCESS,
} from '../../scheduled-reports/actions/actionTypes';
import {
    DELETE_FUNNEL_SUCCESS,
    UPDATE_CONVERSION_SUCCESS,
    UPDATE_FUNNEL_LIST_SUCCESS,
    UPDATE_FUNNEL_SUCCESS,
} from '../../tracking/actions/actionTypes';
import {
    APPLY_RECOMMENDATION_SUCCESS,
    BULK_EDIT_DRAWER_UPDATE_CREATIVES_SUCCESS,
    BULK_RESUBMIT_CREATIVE_SUCCESS,
    CREATE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CREATIVES_ERROR,
    DELETE_CREATIVES_SUCCESS,
    DISMISS_RECOMMENDATION_SUCCESS,
    DUPLICATE_CAMPAIGN_SUCCESS,
    FETCH_CAMPAIGN_SUCCESS,
    FETCH_CAMPAIGNS_ERROR,
    FETCH_CAMPAIGNS_SUCCESS,
    FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS,
    FORCE_CAMPAIGN_PICKER_RELOAD,
    REQUEST_FETCH_CAMPAIGNS,
    RESET_CAMPAIGNS,
    RESET_CREATIVES,
    RESET_FLASH_REPORT_GRID_ROWS,
    RESUBMIT_CREATIVE_SUCCESS,
    SAVE_CAMPAIGN_ERROR,
    SAVE_CAMPAIGN_SUCCESS,
    SAVE_CREATIVE_ERROR,
    SAVE_CREATIVE_SUCCESS,
    SELECT_REPORT,
    SET_CAMPAIGN_PICKER_SORTING,
    SET_TOTAL_CAMPAIGNS_COUNT,
    TOGGLE_CAMPAIGN_PICKER_PINNED,
    UPDATE_CAMPAIGNS_ERROR,
    UPDATE_CAMPAIGNS_SUCCESS,
    UPDATE_CREATIVES_CAMPAIGN_NAME,
    UPDATE_CREATIVES_ERROR,
    UPDATE_CREATIVES_SUCCESS,
} from '../actions';
import { defaultSortField, defaultSortType, REPORT_DATA_MAP_VALUES } from '../config';
import { reducer as campaignsGroupReducer } from '../modules/campaigns-group/reducer/index';
import {
    initialState as reportsInitialState,
    propsToPersist as reportsPropsToPersist,
    reducer as reportsReducer,
} from '../modules/campaigns-reports';
import {
    initialState as campaignManagerInitialState,
    reducer as campaignManagerReducer,
} from '../modules/common-campaign-form';
import {
    initialState as creativeCreatorInitialState,
    propsToPersist as creativeCreatorPropsToPersist,
    reducer as creativeCreatorReducers,
} from '../modules/creative-creator';
import { creativeEditorInitialState, creativeEditorReducers } from '../modules/creative-editor';
import { updateReportDimensionMapOnError, updateReportDimensionMapOnSuccess } from './reportDimensionStateUpdater';

const REDUCER_NAME = 'campaignsReducer';

const createInitialState = () => ({
    [REPORT_DATA_MAP_VALUES.CAMPAIGNS]: {},
    lastCreatedCampaignId: null,
    creativeCreatorSelectedCampaigns: [],
    moduleStatus: COMPONENT_STATUS.INITIAL,
    [REPORT_DATA_MAP_VALUES.CREATIVES]: {},
    [REPORT_DATA_MAP_VALUES.ACCOUNTS]: {},
    isCampaignPickerPinned: false,
    campaignPickerSortingType: defaultSortType,
    campaignPickerSortingField: defaultSortField,
    ...reportsInitialState,
    ...campaignManagerInitialState,
    ...creativeEditorInitialState,
    ...creativeCreatorInitialState,
    gridRowToFlash: null,
});

export const initialState = createInitialState();

const campaignPickerPropsToPersist = [
    'isCampaignPickerPinned',
    'campaignPickerSortingType',
    'campaignPickerSortingField',
];

const propsToPersist = [...campaignPickerPropsToPersist, ...reportsPropsToPersist, ...creativeCreatorPropsToPersist];

const appendNewCampaign = (state, action) => {
    const newCampaign = action.payload;
    const { campaigns } = state;

    return {
        lastCreatedCampaignId: newCampaign.id,
        campaigns: {
            ...campaigns,
            [newCampaign.id]: {
                createTime: Date.now(),
                ...newCampaign,
            },
        },
    };
};

const appendNewPerformanceRule = (state, action) => {
    const newRule = action.payload;
    const { performanceRules } = state;
    const { reportData } = state;
    const newCreatedRule = { ...newRule, createTime: Date.now() };

    return {
        performanceRules: {
            [newRule.id]: newCreatedRule,
            ...performanceRules,
        },
        reportData: [newCreatedRule, ...reportData],
    };
};

const addCampaignToCache = (state, action) =>
    updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS);

const duplicateCampaignSuccessHandler = (state, action) => updateObject(state, appendNewCampaign(state, action));

const createCampaignSuccessHandler = (state, action) =>
    updateObject(state, {
        ...appendNewCampaign(state, action),
    });

const deleteCampaignSuccessHandler = (state, action) => {
    const campaign = action.payload;
    const reportData = state.reportData.filter(item => item.id !== campaign.id);
    const stateWithRemovedCampaign = updateObject(state, { reportData });

    return updateReportDimensionMapOnSuccess(stateWithRemovedCampaign, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS);
};

const fetchCampaignsSuccessHandler = (state, action) => {
    const { results } = action.payload;
    const fetchedCampaigns = keyBy(results, 'id');
    const campaigns = merge({}, state.campaigns, fetchedCampaigns);
    const moduleStatus = COMPONENT_STATUS.ACTIVE;
    return updateObject(state, {
        moduleStatus,
        campaigns,
    });
};

const fetchCampaignsRequestHandler = state => {
    if (!isEmpty(state.campaigns)) {
        return state;
    }
    return updateObject(state, { moduleStatus: COMPONENT_STATUS.LOADING });
};

const resetCampaignProps = (state, propsToIgnore = []) => ({
    ...state,
    ...omit(createInitialState(), [...propsToPersist, ...propsToIgnore]),
});

const updateCreativesCampaignName = (state, action) => {
    const { id, name } = action.payload;
    const { creatives } = state;
    const newCreatives = Object.keys(creatives).reduce((acc, key) => {
        let creative = creatives[key];
        if (id === creative.campaignId) {
            creative = { ...creatives[key] };
            creative.campaignName = name;
        }
        acc[key] = creative;
        return acc;
    }, {});

    return updateObject(state, {
        creatives: newCreatives,
        gridRowToFlash: id,
    });
};

const saveCampaignErrorHandler = (state, action) =>
    updateReportDimensionMapOnError(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS);

const saveCreativeSuccessHandler = (state, action) => {
    const stateWithUpdatedCreative = updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.CREATIVES);

    return updateObject(state, {
        ...stateWithUpdatedCreative,
        gridRowToFlash: action.payload.id,
    });
};

const saveConversionsSuccessHandler = (state, action) => {
    const stateWithUpdatedCreative = updateReportDimensionMapOnSuccess(
        state,
        action,
        REPORT_DATA_MAP_VALUES.CONVERSIONS
    );

    return updateObject(state, {
        ...stateWithUpdatedCreative,
        gridRowToFlash: action.payload.id,
    });
};

const deleteFunnelSuccessHandler = (state, action) => {
    const funnel = action.payload;
    const reportData = state.reportData.filter(item => item.id !== funnel.id);
    const stateWithRemovedFunnel = updateObject(state, { reportData });

    return updateReportDimensionMapOnSuccess(stateWithRemovedFunnel, action, REPORT_DATA_MAP_VALUES.FUNNELS);
};

const updateFunnelsSuccessHandler = (state, action) => {
    const {
        payload: { id: funnelId },
    } = action;

    const nextDefaultAccountName = state[REPORT_DATA_MAP_VALUES.FUNNELS][funnelId].accountName;

    const lastDefault = find(state[REPORT_DATA_MAP_VALUES.FUNNELS], {
        isDefault: true,
        accountName: nextDefaultAccountName,
    });

    const updatedFunnels = {
        ...state[REPORT_DATA_MAP_VALUES.FUNNELS],
        [funnelId]: {
            ...state[REPORT_DATA_MAP_VALUES.FUNNELS][funnelId],
            isDefault: true,
        },
        [lastDefault.id]: {
            ...lastDefault,
            isDefault: false,
        },
    };

    const stateWithUpdatedFunnel = updateReportDimensionMapOnSuccess(
        {
            ...state,
            [REPORT_DATA_MAP_VALUES.FUNNELS]: updatedFunnels,
        },
        action,
        REPORT_DATA_MAP_VALUES.FUNNELS
    );

    return updateObject(state, {
        ...stateWithUpdatedFunnel,
        gridRowToFlash: funnelId,
    });
};

const updateFunnelSuccessHandler = (state, action) => {
    const stateWithUpdatedFunnel = updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.FUNNELS);
    return updateObject(state, {
        ...stateWithUpdatedFunnel,
        gridRowToFlash: action.payload.id,
    });
};

const updatePerformanceRuleSuccessHandler = (state, action) => {
    const stateWithUpdatedPerformanceRule = updateReportDimensionMapOnSuccess(
        state,
        action,
        REPORT_DATA_MAP_VALUES.PERFORMANCE_RULES
    );

    return updateObject(stateWithUpdatedPerformanceRule, {
        performanceRuleBeingEdited: null,
    });
};

const deletePerformanceRuleSuccessHandler = (state, action) => {
    const rule = action.payload;
    const reportData = state.reportData.filter(item => item.id !== rule.id);
    const stateWithRemovedRule = updateObject(state, { reportData });
    return updateReportDimensionMapOnSuccess(stateWithRemovedRule, action, REPORT_DATA_MAP_VALUES.PERFORMANCE_RULES);
};

const duplicatePerformanceRuleSuccessHandler = (state, action) =>
    updateObject(state, { ...appendNewPerformanceRule(state, action), gridRowToFlash: action.payload.id });

const saveCreativeErrorHandler = (state, action) => {
    const stateWithUpdatedCreative = updateReportDimensionMapOnError(state, action, REPORT_DATA_MAP_VALUES.CREATIVES);
    return updateObject(state, { ...stateWithUpdatedCreative });
};

const bulkResubmitCreativeSuccessHandler = (state, action) => {
    const stateWithUpdatedCreative = getStateWithUpdatedReportRows(
        state,
        action.payload,
        REPORT_DATA_MAP_VALUES.CREATIVES
    );
    return updateObject(state, {
        ...stateWithUpdatedCreative,
    });
};

const resubmitCreativeSuccessHandler = (state, action) => {
    const stateWithUpdatedCreative = getStateWithUpdatedReportRows(
        state,
        action.payload,
        REPORT_DATA_MAP_VALUES.CREATIVES
    );
    return updateObject(state, stateWithUpdatedCreative);
};

const getStateWithUpdatedReportRows = (state, updatedRows, reportType) =>
    updatedRows.reduce((updatedState, row) => {
        const data = { payload: row };
        return updateReportDimensionMapOnSuccess(updatedState, data, reportType);
    }, state);

const handleBulkEditCreativesDrawerSuccess = (state, action, reportType) => {
    const stateWithUpdatedRows = getStateWithUpdatedReportRows(state, action.payload, reportType);
    return updateObject({ ...stateWithUpdatedRows });
};

const updateReportRowsSuccessHandler = (state, action, reportType) => {
    const stateWithUpdatedRows = getStateWithUpdatedReportRows(state, action.payload, reportType);

    return updateObject({ ...stateWithUpdatedRows });
};

const updateReportRowsErrorHandler = (state, action, reportType) => {
    const stateWithUpdatedRows = action.payload.ids.reduce((updatedState, id) => {
        const data = { payload: { id } };
        return updateReportDimensionMapOnError(updatedState, data, reportType);
    }, state);
    return updateObject({ ...stateWithUpdatedRows });
};

const handleFetchAccountsSuccess = (state, { payload: { accounts, accountsUnderNetwork } }) => {
    const newAccountList = accounts || accountsUnderNetwork;
    const fetchedAccounts = keyBy(newAccountList, 'accountId');
    const mergedAccounts = { accounts: merge({}, state.accounts, fetchedAccounts) };

    return updateObject(state, mergedAccounts);
};

const handleFetchAccountSuccess = (state, { payload: account }) => {
    const fetchedAccount = { [account.accountId]: account };
    const mergedAccounts = { accounts: merge({}, state.accounts, fetchedAccount) };

    return updateObject(state, mergedAccounts);
};

const deleteCreativesSuccessHandler = (state, action) => {
    const deletedCreativesIds = action.payload.map(creative => creative.id);
    const updatedReportData = state.reportData.filter(item => !deletedCreativesIds.includes(item.id));
    return updateObject(state, {
        reportData: updatedReportData,
        selectedRows: {},
    });
};

const fetchSyndicatorRecommendationCountsSuccessHandler = (state, action) => {
    const { campaignRecommendationCounts } = action.payload;
    const transformedRecommendationCounts = mapValues(campaignRecommendationCounts, v => ({
        recommendationCounts: v,
    }));
    const updatedCampaigns = merge({}, state.campaigns, transformedRecommendationCounts);
    return updateObject(state, {
        campaigns: updatedCampaigns,
    });
};

const applyRecommendationSuccessHandler = (state, action) => {
    const recommendationCounts = state.campaigns[action.payload.id].recommendationCounts;

    if (recommendationCounts?.activeRecommendations > 0) {
        action.payload.recommendationCounts = {
            activeRecommendations: recommendationCounts.activeRecommendations - 1,
            appliedRecommendations: recommendationCounts.appliedRecommendations + 1,
        };
    }

    return updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS);
};

const dismissRecommendationSuccessHandler = (state, action) => {
    const campaign = state.campaigns[action.payload.campaignId];
    const recommendationCounts = campaign.recommendationCounts;
    const setter = {
        payload: {
            id: campaign.id,
            recommendationCounts: {
                ...campaign.recommendationCounts,
                activeRecommendations: Math.max(recommendationCounts.activeRecommendations - 1, 0),
            },
        },
    };

    return updateReportDimensionMapOnSuccess(state, setter, REPORT_DATA_MAP_VALUES.CAMPAIGNS);
};

const deleteScheduledReportSuccessHandler = (state, action) => {
    const scheduledReport = action.payload;
    const reportData = state.reportData.filter(item => item.id !== scheduledReport.id);
    const reportMetadata = { ...state.reportMetadata };
    --reportMetadata.total;
    --reportMetadata.count;
    --state.reportTotalRowsCount;
    const stateWithRemovedReport = updateObject(state, { reportData, reportMetadata });

    return updateReportDimensionMapOnSuccess(stateWithRemovedReport, action, REPORT_DATA_MAP_VALUES.SCHEDULED_REPORTS);
};

const updateScheduledReportSuccessHandler = (state, action) => {
    const stateWithUpdatedScheduledReport = updateReportDimensionMapOnSuccess(
        state,
        action,
        REPORT_DATA_MAP_VALUES.SCHEDULED_REPORTS
    );
    return updateObject(state, {
        ...stateWithUpdatedScheduledReport,
        gridRowToFlash: action.payload.id,
    });
};

const handlers = {
    [SELECT_REPORT]: state => updateObject(state, { reportDataStatus: COMPONENT_STATUS.INITIAL }),
    [REQUEST_FETCH_CAMPAIGNS]: fetchCampaignsRequestHandler,
    [RESET_CAMPAIGNS]: state =>
        updateObject(state, {
            campaigns: {},
            lastCreatedCampaignId: null,
            moduleStatus: COMPONENT_STATUS.INITIAL,
            reportDataStatus: COMPONENT_STATUS.INITIAL,
        }),
    [FETCH_CAMPAIGNS_SUCCESS]: fetchCampaignsSuccessHandler,
    [FETCH_CAMPAIGN_SUCCESS]: (state, action) => updateObject(state, addCampaignToCache(state, action)),
    [FETCH_CAMPAIGNS_ERROR]: state => updateObject(state, { moduleStatus: COMPONENT_STATUS.ERROR }),
    [SAVE_CAMPAIGN_SUCCESS]: (state, action) =>
        updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS),
    [SAVE_CAMPAIGN_ERROR]: saveCampaignErrorHandler,
    [UPDATE_CREATIVES_CAMPAIGN_NAME]: updateCreativesCampaignName,
    [UPDATE_CAMPAIGNS_SUCCESS]: (state, action) =>
        updateReportRowsSuccessHandler(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS),
    [UPDATE_CAMPAIGNS_ERROR]: (state, action) =>
        updateReportRowsErrorHandler(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS),
    [CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccessHandler,
    [DELETE_CAMPAIGN_SUCCESS]: deleteCampaignSuccessHandler,
    [DUPLICATE_CAMPAIGN_SUCCESS]: duplicateCampaignSuccessHandler,
    [TOGGLE_CAMPAIGN_PICKER_PINNED]: (state, { payload }) => updateObject(state, { isCampaignPickerPinned: payload }),
    [RESET_STATE_TO_INITIAL]: (state, { payload }) => resetCampaignProps(state, payload),
    [SAVE_CREATIVE_SUCCESS]: saveCreativeSuccessHandler,
    [SAVE_CREATIVE_ERROR]: saveCreativeErrorHandler,
    [RESET_CREATIVES]: state => updateObject(state, { [REPORT_DATA_MAP_VALUES.CREATIVES]: {} }),
    [FETCH_ACCOUNT_SUCCESS]: handleFetchAccountSuccess,
    [FETCH_ACCOUNTS_SUCCESS]: handleFetchAccountsSuccess,
    [FETCH_ACCOUNTS_UNDER_NETWORK_SUCCESS]: handleFetchAccountsSuccess,
    [FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS]: (state, { payload }) =>
        handleFetchAccountSuccess(state, {
            payload: {
                ...payload,
                isAccountFetched: true,
            },
        }),
    [BULK_EDIT_DRAWER_UPDATE_CREATIVES_SUCCESS]: (state, action) =>
        handleBulkEditCreativesDrawerSuccess(state, action, REPORT_DATA_MAP_VALUES.CREATIVES),
    [UPDATE_CREATIVES_SUCCESS]: (state, action) =>
        updateReportRowsSuccessHandler(state, action, REPORT_DATA_MAP_VALUES.CREATIVES),
    [UPDATE_CONVERSION_SUCCESS]: (state, action) => saveConversionsSuccessHandler(state, action),
    [DELETE_FUNNEL_SUCCESS]: deleteFunnelSuccessHandler,
    [UPDATE_FUNNEL_SUCCESS]: updateFunnelSuccessHandler,
    [UPDATE_FUNNEL_LIST_SUCCESS]: updateFunnelsSuccessHandler,
    [UPDATE_PERFORMANCE_RULE_SUCCESS]: updatePerformanceRuleSuccessHandler,
    [DELETE_PERFORMANCE_RULE_SUCCESS]: deletePerformanceRuleSuccessHandler,
    [DUPLICATE_PERFORMANCE_RULE_SUCCESS]: duplicatePerformanceRuleSuccessHandler,
    [UPDATE_CREATIVES_ERROR]: (state, action) =>
        updateReportRowsErrorHandler(state, action, REPORT_DATA_MAP_VALUES.CREATIVES),
    [DELETE_CREATIVES_SUCCESS]: deleteCreativesSuccessHandler,
    [DELETE_CREATIVES_ERROR]: (state, action) =>
        updateReportRowsErrorHandler(state, action, REPORT_DATA_MAP_VALUES.CREATIVES),
    [RESUBMIT_CREATIVE_SUCCESS]: resubmitCreativeSuccessHandler,
    [BULK_RESUBMIT_CREATIVE_SUCCESS]: bulkResubmitCreativeSuccessHandler,
    [SET_CAMPAIGN_PICKER_SORTING]: (state, action) => ({ ...state, ...action.payload }),
    [FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS]: fetchSyndicatorRecommendationCountsSuccessHandler,
    [APPLY_RECOMMENDATION_SUCCESS]: applyRecommendationSuccessHandler,
    [DISMISS_RECOMMENDATION_SUCCESS]: dismissRecommendationSuccessHandler,
    [FORCE_CAMPAIGN_PICKER_RELOAD]: (state, action) => ({ ...state, ...action.payload }),
    [SET_TOTAL_CAMPAIGNS_COUNT]: (state, action) => updateObject(state, { totalCampaignsCount: action.payload }),
    [RESET_FLASH_REPORT_GRID_ROWS]: state => ({
        ...state,
        gridRowToFlash: null,
    }),
    [DELETE_SCHEDULED_REPORT_SUCCESS]: deleteScheduledReportSuccessHandler,
    [UPDATE_SCHEDULED_REPORT_SUCCESS]: updateScheduledReportSuccessHandler,
    ...reportsReducer,
    ...campaignManagerReducer,
    ...creativeEditorReducers,
    ...creativeCreatorReducers,
    ...campaignsGroupReducer,
};

const campaignsReducer = createReducer(initialState, addPersistHandler(handlers, REDUCER_NAME));

export default campaignsReducer;
export { propsToPersist, REDUCER_NAME };
