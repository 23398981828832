import { performanceRulesApi } from 'services/api';
import {
    fetchPerformanceRulesError,
    fetchPerformanceRulesSuccess,
    requestFetchPerformanceRulesForEdit,
} from '../actions/fetchPerformanceRule';
import { fromGWToPerformanceRules } from '../transformers/fromGWToPerformanceRules';

export const fetchPerformanceRule =
    (accountName, ruleId, { hideError } = {}) =>
    async dispatch => {
        dispatch(requestFetchPerformanceRulesForEdit());
        try {
            const response = await performanceRulesApi.getPerformanceRulesData(accountName, ruleId);
            const rule = fromGWToPerformanceRules(response);
            dispatch(fetchPerformanceRulesSuccess(rule));
            return rule;
        } catch (e) {
            if (!hideError) {
                dispatch(fetchPerformanceRulesError(e));
            }
        }
    };
