import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty, isUndefined } from 'lodash';
import { accountsMapSelector } from '../../../../selectors/baseSelectors';

let accountPromiseList = [];
const TIMEOUT = 5000;

export const useAsyncAccountGetter = () => {
    const accountMap = useSelector(accountsMapSelector);
    const callback = useCallback(accountId => {
        if (isUndefined(accountId)) {
            return Promise.resolve();
        }
        const promise = new Promise(resolve => {
            accountPromiseList.push({
                accountId,
                resolve,
                startPoint: Date.now(),
            });
        });

        return promise;
    }, []);

    useEffect(() => {
        if (isEmpty(accountPromiseList)) {
            return;
        }
        accountPromiseList.forEach(item => {
            const account = get(accountMap, item.accountId);
            if (account?.accountName || Date.now() - item.startPoint > TIMEOUT) {
                item.resolve(account);
                item.settled = true;
            }
        });
        accountPromiseList = accountPromiseList.filter(({ settled }) => !settled);
    }, [accountMap]);

    return callback;
};
