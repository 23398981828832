export function getAudiencesByTargetingType(targeting, targetingType) {
    const targetingByType = targeting.find(value => {
        const { type } = value;
        return type === targetingType;
    });

    const { values = [] } = targetingByType || {};

    return values;
}
