import { cloneDeep, isArray } from 'lodash';

export const transformV2ToV1 = event => {
    if (!isArray(event?.data?.actions)) {
        return event;
    }

    event.data.actions.forEach(({ type: actionType, data: actionData, ...rest }, index, ar) => {
        ar[index] = { actionType, actionData, ...rest };
    });
    return event;
};

export const transformV1ToV2 = rawEvent => {
    if (!isArray(rawEvent?.actions)) {
        return rawEvent;
    }

    const event = cloneDeep(rawEvent);
    event.actions.forEach(({ actionType: type, actionData: data, ...rest }, index, ar) => {
        ar[index] = { type, data, ...rest };
    });
    return event;
};
