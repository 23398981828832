import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { NodeTreeIcon } from 'tuui';
import { Dotdotdot, Highlighter, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BundleIconTooltip } from './BundleIconTooltip/BundleIconTooltip';
import { SegmentSizes } from './SegmentSizes/SegmentSizes';
import styles from './audienceDetailsWithBundling.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AudienceDetailsWithBundling = ({
    className,
    isLoading,
    isParent,
    showUnbundleIcon,
    onUnbundle,
    label,
    highlightText,
    labelTooltip,
    showSizes,
    formattedSize,
    provider,
    taxonomyLabel,
}) => {
    if (isLoading) {
        return (
            <ContentLoader width={460} height={32} speed={2} className={styles['loader']}>
                <rect x="10" y="8" rx="8" ry="8" width="250" height="12" />
                {showSizes && <rect x="390" y="8" rx="8" ry="8" width="60" height="12" />}
            </ContentLoader>
        );
    }

    return (
        <div className={classNameBuilder('container', className)}>
            <div className={styles['node-text']}>
                <Dotdotdot lines={2}>
                    <Highlighter
                        text={label}
                        highlightedText={highlightText}
                        className={classNameBuilder('highlighter', { parent: isParent })}
                    />
                </Dotdotdot>
                {taxonomyLabel && (
                    <Dotdotdot lines={2} className={styles['taxonomy-label']}>
                        {taxonomyLabel}
                    </Dotdotdot>
                )}
                {labelTooltip}
            </div>
            <div className={styles['details']}>
                {provider && (
                    <div className={styles['provider']}>
                        <FormattedMessage
                            id={`campaign.editor.targeting.audience.details.${provider}`}
                            defaultMessage={provider}
                        />
                    </div>
                )}

                {showSizes && <SegmentSizes formattedSize={formattedSize} />}
            </div>
            {showUnbundleIcon && (
                <div
                    className={styles['bundle-button-container']}
                    onClick={onUnbundle}
                    aria-label={`unbundle button ${label}`}
                >
                    <NodeTreeIcon />
                    <BundleIconTooltip BundleIcon={<NodeTreeIcon />} position={TOOLTIP_POSITION.TOP_END} />
                </div>
            )}
        </div>
    );
};
