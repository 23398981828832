import React, { useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { thumbnailAutoReviewValidations, useInsignificantChangesValidation } from 'modules/campaigns/config';
import ThumbnailEditorImage from 'modules/campaigns/modules/creative-editor/components/ThumbnailEditor/Image/ThumbnailEditorImage';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import validationFunctions from 'modules/taboola-common-frontend-modules/validations/services/validationFunctions';
import styles from './unifiedCreativeThumbnailEditorBody.module.scss';

export const baseValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.creative.editor.thumbnail.empty',
        defaultMessage: 'Media file cannot be empty',
    },
];

const emptyValidations = [];
const UnifiedCreativeThumbnailEditorBody = ({
    isThumbnailLoading,
    applyFocusToMatches = false,
    setApplyFocusToMatches = noop,
    matchingCreativeIds = [],
    disabled,
    isFocalPointEditorEnabled,
    skipValidations,
    selectedRowsValues,
    error,
    openCropModal,
}) => {
    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });
    const validationsWithFF = useMemo(
        () => (isContentValidationEnabled ? [...baseValidations, ...thumbnailAutoReviewValidations] : baseValidations),
        [isContentValidationEnabled]
    );
    const urlValidations = useInsignificantChangesValidation({ baseValidations: validationsWithFF, isForUrl: true });
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const {
        value: thumbnailUrl,
        failedValidationData,
        scrollRef,
    } = useFormValidatedValue({
        validations: skipValidations ? emptyValidations : urlValidations,
        field: 'thumbnailUrl',
        validationDependencies: { selectedRowsValues: selectedRowsValues?.thumbnailUrls, accountId },
    });
    const { value: creativeFocus, onChange: onChangeCreativeFocus } = useFormFieldValue({ field: 'creativeFocus' });

    const isThumbnailSelected = thumbnailUrl || isThumbnailLoading || error;

    return (
        <div className={styles['creative-editor']}>
            {isThumbnailSelected && (
                <ThumbnailEditorImage
                    imageSrc={thumbnailUrl}
                    creativeFocus={creativeFocus}
                    onChangeCreativeFocus={onChangeCreativeFocus}
                    isThumbnailLoading={isThumbnailLoading}
                    imageError={error}
                    applyFocusToMatches={applyFocusToMatches}
                    setApplyFocusToMatches={setApplyFocusToMatches}
                    numMatchingCreatives={matchingCreativeIds.length}
                    isFocalPointEditorEnabled={isFocalPointEditorEnabled}
                    disabled={disabled}
                    failedValidationData={failedValidationData}
                    scrollRef={scrollRef}
                    openCropModal={openCropModal}
                />
            )}
        </div>
    );
};

UnifiedCreativeThumbnailEditorBody.propTypes = {
    isThumbnailLoading: PropTypes.bool,
    applyFocusToMatches: PropTypes.bool,
    setApplyFocusToMatches: PropTypes.func,
    matchingCreativeIds: PropTypes.array,
    disabled: PropTypes.bool,
};

const UnifiedCreativeThumbnailEditorBaseWithIndication = withIndication(UnifiedCreativeThumbnailEditorBody);

export { UnifiedCreativeThumbnailEditorBaseWithIndication as UnifiedCreativeThumbnailEditorBody };
