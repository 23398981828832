import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, STYLED_BUTTON_TYPE, STYLED_BUTTON_SIZE } from 'taboola-ultimate-ui';
import styles from './selectAll.module.scss';

const SelectAll = ({ onClick, children, disabled }) => (
    <div className={styles['container']}>
        <StyledButton
            size={STYLED_BUTTON_SIZE.MEDIUM}
            type={STYLED_BUTTON_TYPE.LINK}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </StyledButton>
    </div>
);

SelectAll.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default SelectAll;
