import React from 'react';
import { BareDropdown } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FUNNEL_MODE } from '../consts/FunnelModes';
import { useAccountFunnels } from '../hooks/useAccountFunnels';
import styles from './funnelsDropDown.module.scss';

export const FunnelsDropDown = () => {
    const { value: funnelId, onChange: onFunnelChange } = useFormFieldValue({ field: 'funnel.funnelId' });
    const { value: mode } = useFormFieldValue({ field: 'funnel.mode' });
    const isDefaultFunnelEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_DEFAULT_FUNNEL_ENABLED]: 'true',
    });

    const { data: options, isLoading } = useAccountFunnels();
    const handleOnChange = item => {
        if (!item) {
            onFunnelChange('');
            return;
        }
        const { value } = item;
        onFunnelChange(value);
    };

    if (isLoading) {
        return null;
    }

    return (
        <div className={styles['dropdown-container']}>
            <BareDropdown
                id="funnel-selector"
                selectedValueObject={funnelId !== '' ? { value: funnelId } : null}
                options={options}
                clearable={!isDefaultFunnelEnabled}
                onChange={handleOnChange}
                placeholder={
                    <FormattedMessage
                        id="campaign.editor.unip.funnel.select.placeholder"
                        defaultMessage="Select funnel..."
                    />
                }
                disabled={mode === FUNNEL_MODE.DEFAULT && isDefaultFunnelEnabled}
            />
        </div>
    );
};
