import React from 'react';
import { PaperIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations/index';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CONVERSION_TYPE } from '../../config';
import { AggregatedConversionRadio } from '../AggregatedConversionRadio/AggregatedConversionRadio';
import { AudiencePropertiesCheckBox } from '../AudiencePropertiesCheckBox/AudiencePropertiesCheckBox';
import { ConversionCategoryField } from '../ConversionCategoryField/ConversionCategoryField';
import { EventNameField } from '../EventNameField/EventNameField';
import { FixedValueField } from '../FixedValueField/FixedValueField';
import { LookBackWindowField } from '../LookBackWindowField/LookBackWindowField';
import { TotalConversionCheckBox } from '../TotalConversionCheckBox/TotalConversionCheckBox';
import { TotalValueCheckBox } from '../TotalValueCheckBox/TotalValueCheckBox';
import { VtaLookBackWindowField } from '../VtaLookBackWindowField/VtaLookBackWindowField';
import styles from './conversionPropertiesSection.module.scss';

export const ConversionPropertiesSection = ({ type }) => {
    const isAggregatedConversionEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
    });
    return (
        <CommonCollapsibleCard
            id="CONVERSION_PROPERTIES"
            header={
                <SectionHeader
                    headerIcon={<PaperIcon />}
                    headerText={
                        <FormattedMessage
                            id="tracking.conversion.creator.properties.title"
                            defaultMessage="Conversion Properties"
                        />
                    }
                />
            }
        >
            <div className={styles['col-list']}>
                <FixedValueField />
                <ConversionCategoryField />
                {type === CONVERSION_TYPE.EVENT_BASED && <EventNameField />}
                <LookBackWindowField />
                <VtaLookBackWindowField />
                <TotalConversionCheckBox />
                <TotalValueCheckBox />
                <AudiencePropertiesCheckBox />
                {isAggregatedConversionEnabled && <AggregatedConversionRadio />}
            </div>
        </CommonCollapsibleCard>
    );
};
