import React from 'react';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../../config';
import useCampaignBreadcrumbs from '../../../hooks/useCampaignBreadcrumbs';
import { FormBreadcrumbs } from '../../common-campaign-form';

const CreativeBreadcrumbs = props => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'creative.creator.title', defaultMessage: 'Create Ads' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CREATIVE,
        title,
    });
    return <FormBreadcrumbs title={title} steps={steps} {...props} />;
};

export default CreativeBreadcrumbs;
