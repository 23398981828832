import { useAccountAdditionalData } from '../../../hooks';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { CURRENCY } from '../config/Currency';
import { LEGAL_ENTITY } from '../config/LegalEntity';
import { PAYMENT_TYPE } from '../config/PaymentType';

const LEGAL_ENTITY_AND_CURRENCY_SPECIFIC_BEHAVIOR = [
    { legalEntity: LEGAL_ENTITY.TABOOLA_EUROPE, currency: CURRENCY.USD },
];

const isCurrencyAndLegalEntityCombinationExist = ({ legalEntity, currency }) => {
    return LEGAL_ENTITY_AND_CURRENCY_SPECIFIC_BEHAVIOR.some(
        combination => combination.legalEntity === legalEntity && combination.currency === currency
    );
};

export const useBankTransferMessages = () => {
    const { currency } = useAccount();
    const { accountAdditionalData: { legalEntity } = {} } = useAccountAdditionalData();

    const instruction2Id = isCurrencyAndLegalEntityCombinationExist({ currency, legalEntity })
        ? `billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.${legalEntity}.${currency}.instruction.2`
        : `billingAndPayments.modal.addFunds.${PAYMENT_TYPE.BANK_TRANSFER}.instruction.2`;
    const instruction2tooltipId = `${instruction2Id}.tooltip`;

    return { instruction2Id, instruction2tooltipId };
};
