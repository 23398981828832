'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Image = require('../../Image');

var _Video = require('../../Video');

var _Ellipsis = require('../../Ellipsis');

var _GalleryItem = require('../GalleryItem');

var _GalleryItem2 = _interopRequireDefault(_GalleryItem);

var _DragAndDropFile = require('../../DragAndDropFile');

var _AspectRatioKeeper = require('../AspectRatioKeeper/AspectRatioKeeper');

var _AspectRatioKeeper2 = _interopRequireDefault(_AspectRatioKeeper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'PeviewGalleryItem__taboola-svg-icon___246m-',
    'container': 'PeviewGalleryItem__container___2XKP4',
    'content': 'PeviewGalleryItem__content___3rsA6',
    'overlay': 'PeviewGalleryItem__overlay___3tTBY',
    'title': 'PeviewGalleryItem__title___2Ticv',
    'branding': 'PeviewGalleryItem__branding___GlUzC'
};


var classNameBuilder = _bind2.default.bind(styles);

var getCompByFileType = function getCompByStatus(loadingComponent, errorComponent, previewWidget, onRemovePreviewWidget) {
    if ((0, _DragAndDropFile.isFileTypeVideo)(previewWidget.fileType)) {
        return _react2.default.createElement(_Video.Video, {
            className: styles['content'],
            src: previewWidget.displayUrl || previewWidget.thumbnailUrl,
            autoplay: false,
            onCloseButtonClick: onRemovePreviewWidget,
            renderWatermark: false
        });
    }

    return _react2.default.createElement(_Image.Image, {
        className: styles['content'],
        errorComponent: errorComponent,
        loadingComponent: loadingComponent,
        src: previewWidget.displayUrl || previewWidget.thumbnailUrl
    });
};

var PreviewGalleryItem = function PreviewGalleryItem(_ref) {
    var branding = _ref.branding,
        loadingComponent = _ref.loadingComponent,
        errorComponent = _ref.errorComponent,
        renderOverlayComponent = _ref.renderOverlayComponent,
        className = _ref.className,
        wrapperClass = _ref.wrapperClass,
        coverClass = _ref.coverClass,
        previewWidget = _ref.previewWidget,
        onRemovePreviewWidget = _ref.onRemovePreviewWidget,
        rest = _objectWithoutProperties(_ref, ['branding', 'loadingComponent', 'errorComponent', 'renderOverlayComponent', 'className', 'wrapperClass', 'coverClass', 'previewWidget', 'onRemovePreviewWidget']);

    return _react2.default.createElement(
        _GalleryItem2.default,
        Object.assign({ className: classNameBuilder('container', className) }, rest),
        _react2.default.createElement(
            _AspectRatioKeeper2.default,
            { wrapperClass: wrapperClass, coverClass: coverClass },
            getCompByFileType(loadingComponent, errorComponent, previewWidget, onRemovePreviewWidget)
        ),
        previewWidget.title && _react2.default.createElement(
            _Ellipsis.Truncate,
            {
                className: styles['title'],
                lines: 2
            },
            previewWidget.title
        ),
        branding && _react2.default.createElement(
            _Ellipsis.Truncate,
            {
                className: styles['branding'],
                lines: 1
            },
            branding
        ),
        renderOverlayComponent && renderOverlayComponent()
    );
};

PreviewGalleryItem.propTypes = {
    /** Class added to main element */
    className: _propTypes2.default.string,
    /** Class added to content aspect ratio wrapper element */
    wrapperClass: _propTypes2.default.string,
    /** Class added to content cover element */
    coverClass: _propTypes2.default.string,
    /** Component that describe Loading status */
    loadingComponent: _propTypes2.default.node,
    /** Component that describe Error status */
    errorComponent: _propTypes2.default.node,
    /** Overlay Component added on top of the img - hover */
    renderOverlayComponent: _propTypes2.default.func,
    /** Branding */
    branding: _propTypes2.default.string,
    /** Type of file image/video */
    fileType: _propTypes2.default.string,
    /** Preview widget to show */
    previewWidget: _propTypes2.default.shape({
        id: _propTypes2.default.string,
        url: _propTypes2.default.string,
        title: _propTypes2.default.string,
        thumbnailUrl: _propTypes2.default.string,
        deleted: _propTypes2.default.bool,
        fileType: _propTypes2.default.string
    }),
    /** Function to remove preview widget */
    onRemovePreviewWidget: _propTypes2.default.func
};

PreviewGalleryItem.defaultProps = {
    errorComponent: _react2.default.createElement(
        'h1',
        null,
        'Error'
    ),
    loadingComponent: _react2.default.createElement(_Image.ImageContentLoader, null),
    renderOverlayComponent: function renderOverlayComponent() {}
};

exports.default = PreviewGalleryItem;