import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isNilOrEmptyString } from 'services/utils/stringUtils';
import { useValidationContext } from '../../../../../../../../taboola-common-frontend-modules/validations/ValidationContext';
import { MAX_TITLES_COUNT } from '../../../../../config';
import { AI_VARIATIONS_FIELD_NAMES } from '../../../../../constants/aiVariationsConstants';
import { useHasAiWizardOnboarding } from '../../../../AiVariationsContextProvider/hooks/useHasAiWizardOnboarding';
import { useHelpWriteModal } from '../../../hooks/useHelpWriteModal';
import { AiTextSuggestions } from '../AiSuggestions/AiTextSuggestions';
import { AiContentBaseCard } from './AiContentBaseCard';
import { TitleInput } from './TitleInput/TitleInput';
import commonStyles from './aiContentCardCommon.module.scss';
import styles from './aiContentTitlesCard.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AiContentTitlesCard = ({ suggestedTitles, loadMoreTitles, titleStatus }) => {
    const { value: titles, onChange: setTitles } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.TITLES });
    const { validationService } = useValidationContext();
    const hasAiWizardOnboarding = useHasAiWizardOnboarding();
    let openModal = useHelpWriteModal({ eventType: 'Title' });
    if (!hasAiWizardOnboarding) {
        openModal = null;
    }

    const onSuggestionClick = suggestionText => {
        setTitles(prev => {
            const newList = prev.slice(0, prev.length - 1);
            newList.push(suggestionText);
            return newList;
        });
    };

    useEffect(() => {
        const emptyIndex = titles.findIndex(title => isNilOrEmptyString(title));
        if (emptyIndex !== titles.length - 1) {
            setTitles((prev = []) => {
                const newList = [...prev].filter(title => !isNilOrEmptyString(title));
                newList.push('');

                return newList.slice(0, MAX_TITLES_COUNT);
            });
        }
    }, [titles, setTitles, validationService]);

    return (
        <AiContentBaseCard>
            <div className={classNameBuilder('label', commonStyles['label'])}>
                <FormattedMessage id="creative.creator.content.headlines.label" defaultMessage="Headlines" />
            </div>
            {titles.map((title, index) => (
                <TitleInput
                    index={index}
                    key={index}
                    ignoreValidations={index !== MAX_TITLES_COUNT - 1 && index === titles.length - 1}
                    openModal={openModal}
                />
            ))}
            {!isEmpty(suggestedTitles) && (
                <AiTextSuggestions
                    results={suggestedTitles}
                    labelType="title"
                    onShowMoreClick={loadMoreTitles}
                    onSuggestionClick={onSuggestionClick}
                    status={titleStatus}
                    currentTexts={titles}
                />
            )}
        </AiContentBaseCard>
    );
};
