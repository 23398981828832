import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { COMPONENT_STATUS } from '../../../../../services/constants';
import { useSelectedSegment } from '../../../../audience-insights/hooks';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../../config';
import { reportDataStatusSelector } from '../../../selectors';
import { useSelectedReportId } from './useSelectedReportId';

const generateEmptyStateDataWithContent = msgId => ({
    header: <FormattedMessage id={`app.campaigns.reports.datagrid.overlay.${msgId}.header`} />,
    content: <FormattedMessage id={`app.campaigns.reports.datagrid.overlay.${msgId}.content`} />,
});
const generateEmptyStateDataWithoutContent = msgId => ({
    header: <FormattedMessage id={`app.campaigns.reports.datagrid.overlay.${msgId}.header`} />,
});

export const useEmptyState = ({ includeContent = true }) => {
    const reportDataStatus = useSelector(reportDataStatusSelector);
    const [selectedReport] = useSelectedReportId();

    const selectedSegment = useSelectedSegment();
    const { id: selectedSegmentId } = selectedSegment;

    const calculateEmptyStateDataMemo = useMemo(() => {
        let msgId = 'empty';

        if (selectedReport === REPORT_TYPE.CATEGORY) {
            msgId = `${REPORT_TYPE.CATEGORY}.empty`;
        }

        if (reportDataStatus === COMPONENT_STATUS.ERROR) {
            msgId = 'error';
        }

        if (selectedReport === REPORT_TYPE.SEGMENT_CATEGORY && selectedSegmentId === -1) {
            msgId = `${REPORT_TYPE.SEGMENT_CATEGORY}.noSelectedSegment`;
        }

        return includeContent ? generateEmptyStateDataWithContent(msgId) : generateEmptyStateDataWithoutContent(msgId);
    }, [reportDataStatus, selectedReport, selectedSegmentId, includeContent]);

    return calculateEmptyStateDataMemo;
};
