'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isFileTypeVideo = exports.isFileTypeVideo = function isFileTypeVideo(fileType) {
  return Boolean(fileType && fileType.match('video'));
};
var isFileTypeImage = exports.isFileTypeImage = function isFileTypeImage(fileType) {
  return Boolean(fileType && fileType.match('image'));
};
var isFileTypeFallbackImage = exports.isFileTypeFallbackImage = function isFileTypeFallbackImage(fileType) {
  return fileType === 'fallbackImage';
};