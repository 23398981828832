import React, { useCallback } from 'react';
import { keyBy } from 'lodash';
import { CodeIcon, AdsClickIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import TypeRadioIconGroup from 'components/TypeRadioIconGroup/TypeRadioIconGroup';
import { useTypeSubtext } from 'hooks';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { CONVERSION_TYPE } from '../../config';
import { ConversionSetupTypeFieldTooltip } from './ConversionSetupTypeFieldTooltip';
import styles from './conversionSetupTypeField.module.scss';

const configs = [
    {
        id: CONVERSION_TYPE.BASIC,
        icon: CodeIcon,
        iconClassName: styles['icon'],
        label: 'URL',
        subtext:
            'URL : Track every time someone visits a website, a section of a website, or certain web pages as a conversion.',
    },
    {
        id: CONVERSION_TYPE.EVENT_BASED,
        icon: AdsClickIcon,
        iconClassName: styles['icon'],
        label: 'EVENT',
        subtext:
            'Event: Track every time someone completes a specific action as a conversion, such as subscribing to newsletter or purchasing an item.',
    },
];

const configByKeys = keyBy(configs, 'id');

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'tracking.conversion.setup.error.type.empty',
        defaultMessage: 'Select a Conversion Type',
    },
];

export const ConversionSetupTypeField = () => {
    const { mode, value: type, onChange } = useFormFieldValue({ field: 'type' });
    const { value: eventName, onChange: onEventNameChange } = useFormFieldValue({ field: 'eventName' });
    const msgPrefix = 'tracking.conversion.setup.type';
    const { subtext, setHovered } = useTypeSubtext(type, configByKeys, msgPrefix);
    const onTypeChange = useCallback(
        type => {
            if (type === CONVERSION_TYPE.BASIC && eventName) {
                onEventNameChange(null);
            }
            onChange(type);
        },
        [onChange, onEventNameChange, eventName]
    );
    return (
        <FormField
            inputId="conversion-type"
            label={<FormattedMessage id="tracking.conversion.setup.type.title" defaultMessage="Conversion Type" />}
            subtext={subtext}
            helpText={<ConversionSetupTypeFieldTooltip />}
        >
            <TypeRadioIconGroup
                name="conversion-type"
                type={type}
                onTypeChange={onTypeChange}
                configs={configs}
                onHover={key => setHovered(key)}
                msgPrefix={msgPrefix}
                titleClassName={styles['title']}
                disabled={mode === FORM_MODES.EDIT}
                validations={validations}
            />
        </FormField>
    );
};
