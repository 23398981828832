import { useQuery } from 'react-query';
import { performanceRulesTransformerToGW } from 'modules/performance-rules/transformers/performanceRulesTransformerToGW';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { usePerformanceRulesApi } from 'services/api';

const STALE_TIME = 10 * 60 * 1000;

export const usePerformanceRuleSimulation = () => {
    const { data: rule = {} } = useFormDataContext();
    const { accountId } = useAccount();
    const transformedRule = performanceRulesTransformerToGW(rule);
    const { simulateRule } = usePerformanceRulesApi();

    const {
        data,
        isLoading = false,
        isError,
    } = useQuery([transformedRule], () => simulateRule(accountId, transformedRule), {
        staleTime: STALE_TIME,
    });

    return { data, isLoading, rule: transformedRule, isError };
};
