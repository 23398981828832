import React from 'react';
import { MediaGalleryItem } from 'taboola-ultimate-ui';
import { activityCodeToValueTypeMap, VALUE_TYPES } from '../ValueFormatters';
import { BoldEllipsisCellRenderer } from './BoldEllipsisCellRenderer';
import { EllipsisCellRenderer } from './index';
import styles from '../../../creative-editor/components/MediaTabs/PaginatedGallery/paginatedGalleryItem.module.scss';

const isMediaChanges = data => {
    return (
        activityCodeToValueTypeMap[saveGetActivityCode(data)] === VALUE_TYPES.MOTION ||
        activityCodeToValueTypeMap[saveGetActivityCode(data)] === VALUE_TYPES.IMAGE
    );
};

const getFileType = data => {
    if (activityCodeToValueTypeMap[saveGetActivityCode(data)] === VALUE_TYPES.MOTION) {
        return 'video';
    }
    if (activityCodeToValueTypeMap[saveGetActivityCode(data)] === VALUE_TYPES.IMAGE) {
        return 'image';
    }
};

const shouldShowTooltip = data => {
    return activityCodeToValueTypeMap[saveGetActivityCode(data)] === VALUE_TYPES.CELL_WITH_TOOLTIP;
};

const saveGetActivityCode = data => {
    return data?.activityDetails?.activityCode;
};

export const CampaignHistoryValueCellRenderer = props => {
    const { value, data } = props;

    if (isMediaChanges(data) && value) {
        const thumbnail = { value, fileType: getFileType(data) };

        return (
            <div className={styles['historyCellContainer']}>
                <MediaGalleryItem
                    imgSrc={value}
                    thumbnail={thumbnail}
                    showLargePreview
                    shouldAddOverlay={false}
                    imagePreviewRatio={8}
                    playVideoOnHover={false}
                />
            </div>
        );
    }

    return props.isBoldCell ? (
        <BoldEllipsisCellRenderer value={value} hasTooltip={shouldShowTooltip(data)} />
    ) : (
        <EllipsisCellRenderer value={value} hasTooltip={shouldShowTooltip(data)} />
    );
};
