import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { UIAssistantIcon } from 'tuui';
import { FormField, SetupIcon } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import TypeRadioIconGroup from 'components/TypeRadioIconGroup/TypeRadioIconGroup';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { OutlineEditableText } from 'modules/campaigns/components/OutlineEditableText/OutlineEditableText';
import { MARKETING_OBJECTIVE_KEYS } from 'modules/campaigns/modules/common-campaign-form/config';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useAvailableItems } from '../../../../../../hooks';
import { FORM_MODES } from '../../../../config';
import { useCampaignsFormFieldValue } from '../../../../hooks';
import contentMethodItems from '../../config/contentMethodItems';
import { useFormatTypeFormFieldValueCreateMode } from '../../hooks/useFormatTypeFormFieldValueCreateMode';
import { UIAssistant } from '../UIAssistant/UIAssistant';
import { formatTypes, CREATIVE_FORMAT_TYPE, formatTypesMap } from './formatType';
import styles from './adFormatsSection.module.scss';

const classNameBuilder = classnames.bind(styles);

const backgroundUIAssistant = {
    PRIMARY: 'primary',
};

export const AdFormatsSection = ({ onContentMethodChange, renderAppInstallFormat }) => {
    const msgPrefix = 'creative.creator.ad.formats.setup';
    const { mode } = useFormDataContext();
    const { value: formatTypeValueCreate, onChange: setFormatType } = useFormatTypeFormFieldValueCreateMode();
    const { isAppInstall: isAppInstallCreativeInEditMode, editFormatType } = useIsAppInstallCreativeInEditMode();
    const formatTypeValue = mode === FORM_MODES.CREATE ? formatTypeValueCreate : editFormatType;
    const { formatMessage } = useIntl();
    const marketingObjectiveLabel = formatMessage({
        id: `campaign.marketing.objective.${MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL}.title`,
    });
    const isAppInstallFormatSelected = formatTypeValue === CREATIVE_FORMAT_TYPE.APP_INSTALL;
    const { value: campaigns } = useCampaignsFormFieldValue();
    const availableFormatTypes = useAvailableItems(formatTypes, { campaigns, renderAppInstallFormat });
    const availableContentMethods = useAvailableItems(contentMethodItems);

    const onChangeFormatType = useCallback(
        newValue => {
            setFormatType(newValue);
            if (onContentMethodChange) {
                onContentMethodChange(formatTypesMap[newValue]?.contentMethod || availableContentMethods[0].id);
            }
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                value: newValue,
                component: 'AdFormatsSection',
            });
        },
        [setFormatType, availableContentMethods, onContentMethodChange]
    );
    const getFormatTypeSubtext = useCallback(key => formatTypesMap[key]?.subText || '', []);

    return (
        <CommonCollapsibleCard
            id="AD_FORMATS_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SetupIcon />}
                    headerText={<FormattedMessage id="creative.creator.ad.formats.setup" defaultMessage="Ad formats" />}
                />
            }
            containerClassName={classNameBuilder('card-container', {
                'edit-app-install-mode': isAppInstallCreativeInEditMode,
            })}
        >
            {isAppInstallFormatSelected && (
                <FormField
                    label={<FormattedMessage id="campaign.marketing.objective" defaultMessage="Marketing Objective" />}
                    containerClass={classNameBuilder('form-field-container')}
                >
                    <OutlineEditableText
                        data-testid="marketingObjective-value"
                        id="marketing objective value"
                        className={classNameBuilder('editable-text-format')}
                        value={marketingObjectiveLabel}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        disabled={true}
                    />
                </FormField>
            )}
            <FormField
                label={<FormattedMessage id="creative.creator.ad.formats.app.ads" defaultMessage="Ad Format" />}
                containerClass={classNameBuilder('form-field-container')}
                subtext={getFormatTypeSubtext(formatTypeValue)}
            >
                <TypeRadioIconGroup
                    name="format-type"
                    titleClassName={classNameBuilder('title')}
                    type={formatTypeValue}
                    onTypeChange={onChangeFormatType}
                    configs={availableFormatTypes}
                    msgPrefix={msgPrefix}
                    disabled={isAppInstallCreativeInEditMode}
                />
            </FormField>
            {!isAppInstallCreativeInEditMode && (
                <UIAssistant
                    label={
                        <FormattedMessage
                            id="creative.creator.ad.formats.description.ui.assistant"
                            defaultMessage="App install format is recommended for driving app downloads"
                        />
                    }
                    headerIcon={<UIAssistantIcon />}
                    backgroundUIAssistant={backgroundUIAssistant.PRIMARY}
                    isCollapsed={!isAppInstallFormatSelected}
                />
            )}
        </CommonCollapsibleCard>
    );
};
