import React from 'react';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

export const GetHelpButtonTooltip = ({ position }) => {
    return (
        <Tooltip position={position ?? TOOLTIP_POSITION.BOTTOM_END} arrow>
            <FormattedMessage
                id="tracking.get.help.from.expert.button.tooltip"
                defaultMessage="By clicking this button you'll be redirected to a Google Calendar page where you'll be able to schedule a Zoom meeting with our tracking experts to consult about Pixel tracking, S2S tracking and setting up events and conversions."
            />
        </Tooltip>
    );
};
