import { useCallback } from 'react';
import { get, noop, isNil, isEmpty, reduce, partition, head } from 'lodash';
import { useTreeState, LoadingMode, TAXONOMY_SEPARATOR } from '../../../Tree';
import { useHiddenSegmentType } from '../../../Tree/hooks/useHiddenSegmentType';
import { defaultSortSegments, getSubTaxonomy, splitTaxonomy } from '../../../Tree/utils/treeUtils';
import {
    ageGroupsTaxonomyFields,
    genderGroupsTaxonomyFields,
    ageGroupTaxonomyParentsSet,
    genderGroupTaxonomyParentsSet,
    getIsTaboolaTreeSegment,
} from '../../consts/taxonomyFields';
import { EMPTY_HIDDEN_IDS_SET, EMPTY_SEGMENT_GROUP_COUNT } from '../../utils/andLogicUtils';
import { checkNodeIsHidden } from '../../utils/andLogicUtils';

export const getSplitTaxonomyList = node => {
    const { taxonomy } = node;

    return splitTaxonomy(taxonomy);
};

const sortSegments = (tree, depth) => {
    if (depth === 0) {
        const [taboolaSegments, otherSegments] = partition(tree, getIsTaboolaTreeSegment);

        return [...taboolaSegments, ...defaultSortSegments(otherSegments)];
    }

    return defaultSortSegments(tree);
};

const leafTransformer = ({ provider, size, articles, description, taxonomy, allowedCountries }) => ({
    provider,
    size,
    articles,
    description,
    taxonomy: taxonomy.join(TAXONOMY_SEPARATOR),
    allowedCountries,
});

const parentTransformer = ({ itemsInGroup, level }) => ({
    taxonomy: getSubTaxonomy(head(itemsInGroup), level),
});

export const useMarketplaceAudiencesTreeState = ({
    search,
    leaves: segments,
    getTaxonomyValue,
    formatMessage,
    isDisabledLeaf,
    hasFilter,
    index,
    marketplaceAudienceSegmentTypeCount = EMPTY_SEGMENT_GROUP_COUNT,
    setMarketplaceAudienceSegmentTypeByIndex = noop,
    hiddenIdsSet = EMPTY_HIDDEN_IDS_SET,
    ...treeStateParams
}) => {
    const treeProps = useTreeState({
        search,
        leaves: segments,
        leafTransformer,
        parentTransformer,
        sortSegments,
        getTaxonomyValue,
        formatMessage,
        isDisabledLeaf,
        hasFilter,
        ...treeStateParams,
    });

    const { selectedLeaves, loadingMode, totalCount, nodesTree } = treeProps;

    const isFullLoadingMode = loadingMode === LoadingMode.FULL;

    const { shouldHideAge, shouldHideGender, shouldHideOther } = useHiddenSegmentType({
        selectedNodes: selectedLeaves,
        marketplaceAudienceSegmentTypeCount,
        setMarketplaceAudienceSegmentTypeByIndex,
        index,
        isFullLoadingMode,
        getSplitTaxonomyList,
        ageGroupTaxonomyList: ageGroupsTaxonomyFields,
        genderGroupTaxonomyList: genderGroupsTaxonomyFields,
    });

    const filterTreeNode = useCallback(
        (filteredNodes, node, counter) => {
            const isLoading = get(node, 'loading');

            if (isLoading) {
                filteredNodes.push(node);
                return filteredNodes;
            }

            // If there are no nodes below this node, it's a leaf and should be counted.
            const targetableNodeCount = isEmpty(get(node, 'nodes')) ? 1 : 0;

            const isNodeHidden = checkNodeIsHidden({
                node,
                hiddenIdsSet,
                shouldHideAge,
                shouldHideGender,
                shouldHideOther,
                ageGroupTaxonomyList: ageGroupsTaxonomyFields,
                genderGroupTaxonomyList: genderGroupsTaxonomyFields,
                ageGroupTaxonomyParentsSet,
                genderGroupTaxonomyParentsSet,
            });

            // if the node is hidden, prune this node and its children from the tree
            if (!isNodeHidden) {
                const children = get(node, 'nodes');

                // if the node is NOT hidden and it has no children, then it is a visible leaf
                if (isNil(children)) {
                    counter.current += targetableNodeCount;
                    filteredNodes.push(node);
                } else {
                    const filteredChildren = children.reduce((acc, node) => filterTreeNode(acc, node, counter), []);

                    // if there are still children after filtering, keep this node.
                    if (!isEmpty(filteredChildren)) {
                        counter.current += targetableNodeCount;
                        filteredNodes.push({ ...node, nodes: filteredChildren });
                    }
                }
            }

            return filteredNodes;
        },
        [hiddenIdsSet, shouldHideAge, shouldHideGender, shouldHideOther]
    );

    const getNodesTree = useCallback(() => {
        if (hiddenIdsSet.size === 0 && !shouldHideAge && !shouldHideGender && !shouldHideOther) {
            return { tree: nodesTree, count: totalCount };
        }

        const counter = { current: 0 };

        const filteredTree = reduce(nodesTree, (acc, node) => filterTreeNode(acc, node, counter), []);

        return { tree: filteredTree, count: counter.current };
    }, [hiddenIdsSet.size, nodesTree, filterTreeNode, shouldHideAge, shouldHideGender, shouldHideOther, totalCount]);

    return { ...treeProps, getNodesTree };
};
