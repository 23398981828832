import { FORM_MODES } from '../../../config/formModes';
import { ENTITY, SCHEDULED_REPORTS_ROUTE_PATH } from '../config';
import { generateScheduledReportFormPath } from './generateScheduledReportFormPath';

export const generateScheduledReportsCreatorPath = () =>
    generateScheduledReportFormPath(SCHEDULED_REPORTS_ROUTE_PATH, {
        entity: ENTITY.SCHEDULED_REPORT,
        mode: FORM_MODES.CREATE,
    });

export const generateScheduledReportsEditPath = (accountId, rsId) =>
    generateScheduledReportFormPath(SCHEDULED_REPORTS_ROUTE_PATH, {
        entity: ENTITY.SCHEDULED_REPORT,
        mode: FORM_MODES.EDIT,
        accountId,
        rsId,
    });
