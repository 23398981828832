'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.preventDefault = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSelect = require('react-select');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'buttonsGroupDropdownOption-module__taboola-svg-icon___1zOK_',
    'container': 'buttonsGroupDropdownOption-module__container____H8dH'
};

// we need to preventDefault if an href is provided. Normally, to keep this extensible we shouldn't do this and
// leave this functionality to the onChange implemented by the user, but the onChange that gets passed into this
// and is exposed by react-select swallows the event sooooooooooo until they fix that we have to do this

var preventDefault = exports.preventDefault = function preventDefault(e) {
    return e.preventDefault();
};

var ButtonsGroupDropdownOption = function ButtonsGroupDropdownOption(props) {
    var data = props.data,
        selectProps = props.selectProps;
    // extract extra values out of props.data to wrap in the outer div (analytics tags, etc.)

    var value = data.value,
        label = data.label,
        labelSuffix = data.labelSuffix,
        options = data.options,
        CustomLinkRenderer = data.customLinkRenderer,
        rest = _objectWithoutProperties(data, ['value', 'label', 'labelSuffix', 'options', 'customLinkRenderer']);

    if (CustomLinkRenderer) {
        return _react2.default.createElement(
            CustomLinkRenderer,
            Object.assign({ className: styles['container'], value: value, label: label, labelSuffix: labelSuffix }, rest),
            _react2.default.createElement(_reactSelect.components.Option, props)
        );
    }

    var getHref = selectProps.getHref;

    return _react2.default.createElement(
        'a',
        Object.assign({
            className: styles['container'],
            onClick: getHref && preventDefault,
            href: getHref && getHref(value)
        }, rest),
        _react2.default.createElement(_reactSelect.components.Option, props)
    );
};

ButtonsGroupDropdownOption.propTypes = {
    data: _propTypes2.default.shape({
        value: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.object]),
        label: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
        labelSuffix: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number, _propTypes2.default.node]),
        options: _propTypes2.default.array,
        customLinkRenderer: _propTypes2.default.func
    }),
    selectProps: _propTypes2.default.shape({
        getHref: _propTypes2.default.func
    })
};

exports.default = ButtonsGroupDropdownOption;