import React, { useCallback } from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useDropdownOptions } from '../../../../hooks';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { formFieldId, FormFieldWithIndication, msgIdPrefix } from './ScheduledReportFIleTypeFieldConsts';
import { fileTypeOptions } from './scheduledReportFileTypeFieldOptions';
import styles from './scheduledReportFileTypeField.module.scss';

export const ScheduledReportFileTypeField = () => {
    const { value: fileType, onChange: setFileType } = useFormFieldValue({ field: 'fileType' });
    const options = useDropdownOptions(fileTypeOptions, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: fileType }) => {
            setFileType(fileType);
        },
        [setFileType]
    );
    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['file-type-container']}
            label={
                <FormattedMessage id="app.scheduled.reports.create.fileType.select.title" defaultMessage="File Type" />
            }
            labelClass={styles['label']}
        >
            <div className={styles['file-type']}>
                <DropdownMenu
                    id={formFieldId}
                    selectedValueObject={{
                        value: fileType,
                    }}
                    options={options}
                    onChange={changeHandler}
                    searchable
                    aria-label="file-type-dropdown"
                />
            </div>
        </FormFieldWithIndication>
    );
};
