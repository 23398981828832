import { requestFetchAccounts, fetchAccountsSuccess, fetchAccountsError } from '../actions';
import { accountsSelector } from '../selectors';
import { transformAccountObject } from '../selectors/baseSelectors';
import { accountsApi } from '../services/api';
import { ERROR_CODES } from '../services/api/apiConstants';
import { transformAccountFromGW } from '../services/transformers';

export const fetchAccounts = (search, pageSize, isRetry, onSuccess, onError) => (dispatch, getState) => {
    const state = getState();
    const accounts = accountsSelector(state);
    const {
        appReducer: { accountsTotal },
    } = state;
    const hasMoreAccounts = accountsTotal !== accounts.length;

    if (!hasMoreAccounts) {
        onSuccess([], false);
        return;
    }

    const page = Math.ceil(accounts.length / pageSize);
    const nextPage = page + 1;
    dispatch(requestFetchAccounts());
    accountsApi
        .getAccounts(search, nextPage, pageSize)
        .then(({ results, metadata }) => {
            const adjustedAccounts = results.map(transformAccountFromGW);

            dispatch(fetchAccountsSuccess(adjustedAccounts, metadata.total));

            const updatedState = getState();
            const updatedAccounts = accountsSelector(updatedState);
            const {
                appReducer: { accountsTotal: updatedAccountsTotal },
            } = updatedState;
            const hasMore = updatedAccounts.length !== updatedAccountsTotal;
            // TODO: should be removed - after DEV-67257.
            //  we transform account object directly here in case of Not retry,
            //  because the data flows directly from api and not after the selector transformation.
            const transformedAccountsToAppend = adjustedAccounts.map(account => transformAccountObject(account));

            onSuccess(isRetry ? updatedAccounts : transformedAccountsToAppend, hasMore);
        })
        .catch(error => {
            const accountsToSubmit = isRetry ? accounts : [];
            const totalToSubmit = isRetry ? accountsTotal : -1;
            if (error.status === ERROR_CODES.NOT_FOUND) {
                dispatch(fetchAccountsSuccess([], totalToSubmit));
                onSuccess(accountsToSubmit, false);
                return;
            }

            if (error.status === ERROR_CODES.ABORT) {
                dispatch(fetchAccountsSuccess([], totalToSubmit));
                onSuccess(accountsToSubmit, true);
                return;
            }

            dispatch(fetchAccountsError(error));
            onError(error);
        });
};

export default fetchAccounts;
