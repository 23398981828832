import React from 'react';
import ReportBreadcrumbs from 'modules/campaigns/components/ReportBreadcrumbs/ReportBreadcrumbs';
import { usePerformanceRulesBreadcrumbs } from 'modules/performance-rules/hooks/usePerformanceRulesBreadcrumbs';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';

export const PerformanceRulesReportBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'navigation.caption.performance.rules.title',
        defaultMessage: 'Custom Rules',
    });

    const { steps } = usePerformanceRulesBreadcrumbs({
        title,
        isTitleAsLastStep: false,
    });

    return <ReportBreadcrumbs title={title} steps={steps} />;
};
