import PropTypes from 'prop-types';
import { FormField, Radio } from 'taboola-ultimate-ui';
import { RadioGroupField } from 'modules/campaigns/components/Form';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import { entityTypeOptions } from './entitiesRuleTypes';
import styles from './entitiesRule.module.scss';

const EntitiesRule = ({ selection, onChange, mode }) => {
    const disabled = mode === FORM_MODES.EDIT;
    return (
        <FormField
            inputId="entities-rules"
            label={<FormattedMessage id="app.create.performance.rules.entities.title" defaultMessage="Apply rule to" />}
        >
            <RadioGroupField
                id="entitiesRules"
                name="entities-Rules-"
                radioGroupName="entities"
                className={styles['group']}
                onChange={onChange}
                selectedValue={selection}
                data-metrics-component="entitiesRules"
                disabled={disabled}
            >
                {entityTypeOptions.map(obj => {
                    const { id } = obj;
                    return (
                        <Radio
                            key={id}
                            value={id}
                            title={<FormattedMessage id={`app.create.performance.rules.entities.${id}`} />}
                            labelClassName={styles['radio']}
                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                            data-metrics-value={id}
                        />
                    );
                })}
            </RadioGroupField>
        </FormField>
    );
};

EntitiesRule.propTypes = {
    selection: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default EntitiesRule;
