import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const PublishersDomainsTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="video.campaign.creator.publishers.domains.help.overview"
                defaultMessage="Opt-in OR out publisher domains."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="video.campaign.creator.publishers.domains.help.example.first"
                defaultMessage="Domains must be entered as site.com. URLs that include http://www. or additions after .com, .uk, etc. will not be valid."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="video.campaign.creator.publishers.domains.help.example.second"
                defaultMessage="URLs that include http://www. or additions after .com, .uk, etc. will not be valid."
            />
        </TooltipSection>
    </div>
);

export default PublishersDomainsTooltip;
