import { isNetworkAccountType } from '../../../../../../account-management';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { CAMPAIGN_STATUS } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graph } from './graph';
import { customColumns, defaultColumns, setupColumns } from './reportPresets';

const RECENT_CAMPAIGN_COUNT_LIMIT = 25;

export const campaignVideoReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CAMPAIGN_VIDEO],
    endpoint: 'campaign-report-by-campaign-daily',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.byCampaignVideo.search.placeholder',
    searchField: 'campaign_name',
    sortMap: {
        [FIELDS.NAME.field]: 'campaignName',
        [FIELDS.ID.field]: 'campaignId',
        [FIELDS.STATUS.field]: 'campaignStatus',
        [FIELDS.VIDEO_BUDGET.field]: 'budget',
        [FIELDS.VIDEO_MODEL_TYPE.field]: 'modelType',
    },
    initialFilters: [
        {
            type: FILTER_TYPE.CAMPAIGN_STATUS,
            values: [
                { value: CAMPAIGN_STATUS.RECENT, label: 'Recent', messageId: 'app.campaigns.campaign.status.RECENT' },
            ],
            predicate: ({ selectedAccountType, totalCampaignCount }) =>
                totalCampaignCount >= RECENT_CAMPAIGN_COUNT_LIMIT || isNetworkAccountType(selectedAccountType),
        },
    ],
    filters: [
        { id: FILTER_TYPE.CAMPAIGN_STATUS },
        { id: FILTER_TYPE.COUNTRY },
        { id: FILTER_TYPE.PLATFORM },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default campaignVideoReportConfig;
