import React, { Component } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './inputLengthIndicator.module.scss';

const classNameBuilder = classnames.bind(styles);

class InputLengthIndicator extends Component {
    render() {
        const { currentLength, maxLength, className, indicatorClassName, warningThreshold } = this.props;
        const colorIndication = currentLength / maxLength;

        const containerClassName = classNameBuilder(className, 'container');
        const activeItemIndicatorClassName = classNameBuilder(indicatorClassName, 'active-item-indicator', {
            'active-item-indicator-error': colorIndication > 1.0,
            'active-item-indicator-warning': colorIndication > warningThreshold && colorIndication <= 1.0,
        });

        return (
            <div className={containerClassName}>
                <div className={activeItemIndicatorClassName}>
                    {currentLength}/{maxLength}
                </div>
            </div>
        );
    }
}

InputLengthIndicator.propTypes = {
    className: PropTypes.string,
    currentLength: PropTypes.number,
    maxLength: PropTypes.number,
    visible: PropTypes.bool,
};

InputLengthIndicator.defaultProps = {
    maxLength: 1,
};

export default InputLengthIndicator;
