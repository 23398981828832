import { createSelector } from 'reselect';
import { useSelectedSegment } from 'modules/audience-insights/hooks/useSelectedSegment';
import { createDeepEqualSelector } from 'selectors/baseSelectors';
import { audienceInsightsStateSelector, segmentsMapSelector } from './baseSelectors';

export const selectedSegmentIdSelector = useSelectedSegment.createSelector();

export const selectedSegmentInnerSelector = createSelector(
    selectedSegmentIdSelector,
    segmentsMapSelector,
    (selectedSegmentId, segmentsMap) => {
        return segmentsMap[selectedSegmentId] || { id: selectedSegmentId, audienceName: '' };
    }
);

export const selectedSegmentSelector = createDeepEqualSelector(
    selectedSegmentInnerSelector,
    selectedSegment => selectedSegment
);

export const audienceInsightsModuleStatusSelector = createSelector(
    audienceInsightsStateSelector,
    audienceInsightsState => audienceInsightsState.moduleStatus
);

export const segmentPickerSortTypeSelector = createSelector(
    audienceInsightsStateSelector,
    audienceInsightsState => audienceInsightsState.segmentPickerSortingType
);

export const segmentPickerSortFieldSelector = createSelector(
    audienceInsightsStateSelector,
    audienceInsightsState => audienceInsightsState.segmentPickerSortingField
);

export const segmentPickerSortOptionsSelector = createSelector(
    segmentPickerSortTypeSelector,
    segmentPickerSortFieldSelector,
    (type, field) => ({ type, field })
);

export const segmentPickerPinnedSelector = createSelector(
    audienceInsightsStateSelector,
    audienceInsightsState => audienceInsightsState.isSegmentPickerPinned
);
