import React, { useEffect, useCallback } from 'react';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useGtmAccountDropdown } from 'modules/tracking/hooks/useGtmAccountDropdown';
import { DropdownPlaceholder } from '../GtmConnectForm/DropdownPlaceholder';
import { AccountDropdownTooltip } from './AccountDropdownTooltip';
import styles from './accountDropdown.module.scss';

export const AccountDropdown = () => {
    const { value, options, onChange, isLoading } = useGtmAccountDropdown();
    const { isTokenValid } = useGoogleApi();

    const { onChange: onGtmContainerChange } = useFormFieldValue({ field: 'gtmContainer' });
    const { onChange: onGtmWorkspaceChange } = useFormFieldValue({ field: 'gtmWorkspace' });

    useEffect(() => {
        if (options?.length === 1) {
            onChange(options[0]);
        }
    }, [options, onChange]);

    const onChangeDropdown = useCallback(
        updatedValue => {
            onChange(updatedValue);
            onGtmContainerChange(null);
            onGtmWorkspaceChange(null);
        },
        [onChange, onGtmContainerChange, onGtmWorkspaceChange]
    );

    return (
        <FormField
            inputId="gtm-account-dropdown"
            label={
                <div className={styles['selector-label']}>
                    <FormattedMessage id="tracking.setup.gtm.account.label" defaultMessage="Select Account" />
                    <AccountDropdownTooltip />
                </div>
            }
        >
            <div className={styles['dropdown-container']}>
                <BareDropdown
                    id="gtm-account-selector"
                    selectedValueObject={value}
                    disabled={!isTokenValid}
                    options={options}
                    placeholder={<DropdownPlaceholder isLoading={isLoading} />}
                    onChange={onChangeDropdown}
                />
            </div>
        </FormField>
    );
};
