import React from 'react';
import { Button } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../../campaigns/config';
import { useModal } from '../../../taboola-common-frontend-modules/modals';
import { AudiencesCreatorSelectionPage } from '../../pages';
import {
    generateMailDomainAudiencesCreatorPath,
    generatePredictiveAudienceCreatorPath,
    generateSearchKeywordsAudiencesCreatorPath,
    generateFirstPartyAudienceCreatorPath,
    generatePixelBasedAudienceCreatorPath,
    generateCustomContextualCreatorPath,
    generateCombinedAudienceCreatorPath,
} from '../../utils/generateCreatorPath';
import styles from './createAudienceButton.module.scss';

const paths = {
    [REPORT_TYPE.PIXEL_BASED]: generatePixelBasedAudienceCreatorPath(),
    [REPORT_TYPE.PREDICTIVE]: generatePredictiveAudienceCreatorPath(),
    [REPORT_TYPE.FIRST_PARTY]: generateFirstPartyAudienceCreatorPath(),
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: generateCustomContextualCreatorPath(),
    [REPORT_TYPE.MAIL_DOMAINS]: generateMailDomainAudiencesCreatorPath(),
    [REPORT_TYPE.SEARCH_KEYWORDS]: generateSearchKeywordsAudiencesCreatorPath(),
    [REPORT_TYPE.COMBINED_AUDIENCES]: generateCombinedAudienceCreatorPath(),
};
const CreateAudienceButton = () => {
    const navigate = useNavigate();
    const { open: openModal } = useModal();
    const openModalDialog = async () => {
        const audienceCreationType = await openModal({
            title: (
                <FormField
                    label={
                        <FormattedMessage id="app.modal.createAudience.title" defaultMessage="Select Audience Type" />
                    }
                />
            ),
            contentRenderer: AudiencesCreatorSelectionPage,
            className: styles['modal'],
        });
        if (audienceCreationType) {
            navigate(paths[audienceCreationType]);
        }
    };

    return (
        <Button className={styles['button']} onClick={openModalDialog}>
            <FormattedMessage id="app.gridToolbar.create.audience" defaultMessage="+ New Audience" />
        </Button>
    );
};

export default CreateAudienceButton;
