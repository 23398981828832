import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { identity, get } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions, withDropdownValidations } from 'modules/taboola-common-frontend-modules/validations';
import { useResourcesApi } from 'services/api';
import { PaginatedDropdownField } from '../../../../components/Form/FormFields/DropdownField/DropdownField';
import { nameToLabel } from '../../../../services/utils/dropdownUtils';
//next import is so specific to avoid circular dependencies
import RepresentativeDropdown from './components/RepresentativeDropdown/RepresentativeDropdown';
import styles from './representatives.module.scss';

const MediaSalesWithValidation = withDropdownValidations(withIndication(PaginatedDropdownField), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'campaign.editor.representatives.label.media.sales.empty.error',
        defaultMessage: "Enter a 'Original Media Sales Rep'",
    },
]);
const CurrentMediaSalesWithValidation = withDropdownValidations(withIndication(PaginatedDropdownField), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'campaign.editor.representatives.label.current.sale.empty.error',
        defaultMessage: "Enter a 'Current Media Sales Rep'",
    },
]);
const AccountManagerWithValidation = withDropdownValidations(withIndication(PaginatedDropdownField), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'campaign.editor.representatives.label.account.manager.empty.error',
        defaultMessage: "Enter a 'Media Account Manager'",
    },
]);
const AccountDirectorWithValidation = withDropdownValidations(withIndication(PaginatedDropdownField), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'campaign.editor.representatives.label.account.director.empty.error',
        defaultMessage: "Enter a 'Media Account Director'",
    },
]);
const ProgrammaticAmRepWithValidation = withDropdownValidations(withIndication(PaginatedDropdownField), [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'campaign.editor.representatives.label.programmatic.am.rep.empty.error',
        defaultMessage: "Enter a 'Programmatic Am Rep'",
    },
]);

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.representatives.fetch.error"
            defaultMessage="Unable to load Media Sales and Account Manager list."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const Representatives = () => {
    const {
        formAccount: { accountId },
        isFormAccountTouched,
    } = useFormDataContext();
    const dispatch = useDispatch();
    const { getRepresentatives } = useResourcesApi();
    const [representatives, setRepresentatives] = useState([]);
    const loadPage = useCallback(
        async ({ page, pageSize, searchText }) => {
            let response = {};
            try {
                response = await getRepresentatives({ accountId, page, pageSize, searchText, paginationEnabled: true });
            } catch (err) {
                dispatch(addIndication(errorIndication));
            }
            const list = get(response, 'results', []).map(nameToLabel);
            return [list, response.metadata?.total];
        },
        [accountId, getRepresentatives, dispatch]
    );
    const onOptionsLoaded = useCallback(loadedItems => setRepresentatives(loadedItems.filter(identity)), []);

    return (
        <div className={styles['container']}>
            <RepresentativeDropdown
                field="mediaSales"
                labelId="campaign.editor.representatives.label.media.sales"
                resourceOptions={representatives}
                component={MediaSalesWithValidation}
                disabledOnceSaved
                accountId={accountId}
                isFormAccountTouched={isFormAccountTouched()}
                loadPage={loadPage}
                onOptionsLoaded={onOptionsLoaded}
            />
            <RepresentativeDropdown
                field="currentMediaSales"
                labelId="campaign.editor.representatives.label.current.sale"
                resourceOptions={representatives}
                component={CurrentMediaSalesWithValidation}
                accountId={accountId}
                isFormAccountTouched={isFormAccountTouched()}
                loadPage={loadPage}
                onOptionsLoaded={onOptionsLoaded}
            />
            <RepresentativeDropdown
                field="mediaAccountManager"
                labelId="campaign.editor.representatives.label.account.manager"
                resourceOptions={representatives}
                component={AccountManagerWithValidation}
                accountId={accountId}
                isFormAccountTouched={isFormAccountTouched()}
                loadPage={loadPage}
                onOptionsLoaded={onOptionsLoaded}
            />
            <RepresentativeDropdown
                field="mediaAccountDirector"
                labelId="campaign.editor.representatives.label.account.director"
                resourceOptions={representatives}
                component={AccountDirectorWithValidation}
                accountId={accountId}
                isFormAccountTouched={isFormAccountTouched()}
                loadPage={loadPage}
                onOptionsLoaded={onOptionsLoaded}
            />
            <RepresentativeDropdown
                field="programmaticAmRep"
                labelId="campaign.editor.representatives.label.programmatic.am.rep"
                resourceOptions={representatives}
                component={ProgrammaticAmRepWithValidation}
                accountId={accountId}
                isFormAccountTouched={isFormAccountTouched()}
                loadPage={loadPage}
                onOptionsLoaded={onOptionsLoaded}
            />
        </div>
    );
};

export default Representatives;
