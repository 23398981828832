import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const CreateAdditionalAudienceFromFileFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.additional-audience.tooltip.header"
                defaultMessage="If this option is selected, two new audiences will be created:"
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.additional-audience.tooltip.option.1"
                defaultMessage="* An audience of the users in the customer file"
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.additional-audience.tooltip.option.2"
                defaultMessage="* An audience of users that are similar to the users in the customer file (a predictive audience)"
            />
        </TooltipSection>
    </>
);

export default CreateAdditionalAudienceFromFileFieldTooltip;
