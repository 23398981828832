import { useCallback } from 'react';
import { BareDropdown, CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { CustomRuleItem } from 'modules/scheduled-reports/components/ScheduledReportsCustomRuleListFiled/CustomRuleItem';
import styles from 'modules/scheduled-reports/components/ScheduledReportsCustomRuleListFiled/CustomRulesDropdown.module.scss';
import { useCustomRulesList } from 'modules/scheduled-reports/components/ScheduledReportsCustomRuleListFiled/hook/useCustomRulesList';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const customRuleDropDownOption = ({ data: { ruleName } }) => ruleName;

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const scheduledReportsCustomRulesListValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'app.create.scheduled.reports.custom.rules.one.rule.required.validation',
        defaultMessage: 'Select at least one custom rule',
    },
];

export const CustomRulesDropdown = () => {
    const { formatMessage } = useIntl();
    const {
        value: customRulesIds,
        onChange: onRulesChange,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'performanceRuleIds',
        validations: scheduledReportsCustomRulesListValidations,
    });

    const { options, selectedRules, loadRules, setRules } = useCustomRulesList({ customRulesIds });

    const addItem = useCallback(({ id }) => onRulesChange(prev => [...prev, id]), [onRulesChange]);

    const deleteItem = useCallback(id => onRulesChange(prev => prev.filter(ruleId => ruleId !== id)), [onRulesChange]);

    const clearItems = useCallback(() => {
        onRulesChange([]);
    }, [onRulesChange]);

    return (
        <div className={styles['container']}>
            <div className={styles['dropdown-container']}>
                <PaginatedDropdown
                    id="custom-rule-selector"
                    onChange={addItem}
                    searchable={false}
                    containerClassName={styles['dropdown']}
                    enabledWhileSearching
                    options={options}
                    loadPage={loadRules}
                    onOptionsLoaded={setRules}
                    placeholder={formatMessage({
                        id: 'app.create.scheduled.reports.custom.rules.select.placeholder',
                        defaultMessage: 'Select Rules',
                    })}
                    DropdownMenuComponent={BareDropdown}
                    optionItemRenderer={customRuleDropDownOption}
                    selectedValueObject={null}
                    totalItemCount={options ? options.length : null}
                />
            </div>
            <CollapsibleListWithIndication
                items={selectedRules}
                data-automation="custom-rule-collapsible-list"
                ItemComponent={CustomRuleItem}
                listHeaderTitle={
                    <FormattedMessage
                        id="app.create.scheduled.reports.custom.rules.collapsible.list.title"
                        defaultMessage="Selected Rules"
                    />
                }
                deleteItem={deleteItem}
                clearItems={clearItems}
                {...failedValidationData}
            />
        </div>
    );
};
