import React from 'react';
import PropTypes from 'prop-types';
import withValidations from './withValidations';

const defaultEvents = { onChange: { validateEventValue: true, eventValueGetter: e => e } };

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withRadioGroupValidations = (RadioGroupComponent, validations = [], events = defaultEvents) => {
    const ValidationsWrapper = withValidations(RadioGroupComponent, { validations, validationTriggerEvents: events });
    const RadioGroupWithValidations = ({ id, validationId, selectedValue, ...rest }) => (
        <ValidationsWrapper
            valueToValidate={selectedValue}
            validationId={validationId || id}
            id={id}
            selectedValue={selectedValue}
            {...rest}
        />
    );

    RadioGroupWithValidations.propTypes = {
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        onValidationChange: PropTypes.func,
        triggerValidation: PropTypes.bool,
        id: PropTypes.string.isRequired,
        validationId: PropTypes.string,
    };

    return RadioGroupWithValidations;
};

export default withRadioGroupValidations;
