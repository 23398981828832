export const TAB_NAMES = {
    MEDIA_LIBRARY: 'MediaLibrary',
    IMAGE_AND_VIDEO_UPLOAD: 'Image and Video Upload',
    STOCK_IMAGES: 'StockImages',
    UPLOAD_AND_CREATE: 'Upload & Create',
};

export const MEDIA_LIBRARY_SUBTABS = {
    RECENTLY_USED: 'Recently Used Media SubTab',
    FAVORITES: 'Favorited Media SubTab',
};

export const GALLERY_ITEM_SOURCES = {
    GETTY_IMAGES: 'GETTY_IMAGES',
    GETTY_VIDEOS: 'GETTY_VIDEOS',
    MEDIA_LIBRARY: 'MEDIA_LIBRARY',
    FAVORITED_MEDIA: 'FAVORITED_MEDIA',
    AI_GENERATED: 'AI_GENERATED',
};

export const sourceToTabNameMap = {
    [GALLERY_ITEM_SOURCES.GETTY_IMAGES]: TAB_NAMES.STOCK_IMAGES,
    [GALLERY_ITEM_SOURCES.MEDIA_LIBRARY]: MEDIA_LIBRARY_SUBTABS.RECENTLY_USED,
    [GALLERY_ITEM_SOURCES.FAVORITED_MEDIA]: MEDIA_LIBRARY_SUBTABS.FAVORITES,
};

export const GTM_ACTION_TYPES = {
    IMAGE_CLICK: 'Image Click',
    BROWSE_BUTTON: 'BrowseButton',
    WEB_URL: 'WebUrl',
};
