import React from 'react';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import EventNameField from '../EventNameField/EventNameField';
import PixelBasedEventImageTag from '../PixelBasedEventImageTag/PixelBasedEventImageTag';
import PixelBasedEventJSTagField from '../PixelBasedEventJSTag/PixelBasedEventJSTagField';

export const PixelBasedAudienceBasedOnEvent = () => {
    const { value: eventName } = useFormFieldValue({ field: 'eventName' });

    return (
        <>
            <EventNameField />
            <br /> <br />
            <PixelBasedEventJSTagField eventName={eventName} />
            <br /> <br />
            <PixelBasedEventImageTag />
        </>
    );
};

export default PixelBasedAudienceBasedOnEvent;
