import { isEmpty } from 'lodash';

export const parametersConfig = [
    {
        value: 'revenue',
        defaultMessage: 'Value (Revenue)',
        placeholder: 'REVENUE_PARAM',
    },
    {
        value: 'currency',
        defaultMessage: 'Currency',
        placeholder: 'CURRENCY_PARAM',
    },
    {
        value: 'orderid',
        defaultMessage: 'Order ID',
        placeholder: 'ORDER_ID_PARAM',
    },
    {
        value: 'quantity',
        defaultMessage: 'Quantity',
        placeholder: 'QUANTITY_PARAM',
    },
];

export const UNIP_EVENT_CODE_SNIPPET =
    '<!-- Taboola Pixel Code -->\n' +
    '<script>\n' +
    `    _tfa.push({notify: 'event', name: 'event_name', id: <NUMERIC_ADVERTISER_ID>);\n` +
    '</script>\n' +
    '<!-- End of Taboola Pixel Code -->';

const ADVERTISER_ID_PARAM = '<NUMERIC_ADVERTISER_ID>';

export const buildCodeSnippetWithParams = (codeSnippet, accountId, parameters, eventName) => {
    const codeSnippetStr = codeSnippet ?? UNIP_EVENT_CODE_SNIPPET;
    const dynamicParams = parameters
        .reduce(
            (acc, curr) => acc + `${curr}: '${parametersConfig.find(param => param.value === curr).placeholder}', `,
            ''
        )
        .slice(0, -2);
    const injectedParams = accountId + (!isEmpty(dynamicParams) ? `, ${dynamicParams}` : '');
    const withEventName = eventName ? codeSnippetStr.replaceAll('event_name', eventName) : codeSnippetStr;
    return withEventName.replaceAll(ADVERTISER_ID_PARAM, injectedParams);
};
