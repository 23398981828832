import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { ToggleSwitch } from 'tuui';
import { Collapsible } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { FORM_MODE_CONFIG } from 'modules/campaigns/modules/creative-editor/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { Id } from '../../../campaigns/modules/common-campaign-form';
import HeaderLoading from '../../../campaigns/modules/common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './videoCreativeEditorHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const runningMessage = <FormattedMessage id="creative.editor.status.running" defaultMessage="Running" />;
const stoppedMessage = <FormattedMessage id="creative.editor.status.stopped" defaultMessage="Stopped" />;

const VideoCreativeEditorHeader = ({ status, headerClassName, collapsed = false, showId = true }) => {
    const { value: isActive, onChange, mode } = useFormFieldValue({ field: 'isActive' });

    const { value: id = EMPTY_FIELD_VALUE } = useFormFieldValue({ field: 'id' });

    const headerLabel = FORM_MODE_CONFIG[mode].headerLabel;
    return status === COMPONENT_STATUS.LOADING ? (
        <HeaderLoading />
    ) : (
        <div className={classNameBuilder('section-container', headerClassName)}>
            {headerLabel}
            {mode === FORM_MODES.EDIT && showId && (
                <Collapsible collapsed={collapsed}>
                    <div className={classNameBuilder('video-creative-status-container')}>
                        <Id id={id} className={styles['video-creative-id']} />
                        <span>
                            <ToggleSwitch checked={isActive} onChange={onChange} />
                        </span>
                        <span className={styles['status']}>{isActive ? runningMessage : stoppedMessage}</span>
                    </div>
                </Collapsible>
            )}
        </div>
    );
};

VideoCreativeEditorHeader.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
    collapsed: PropTypes.bool,
};

export default VideoCreativeEditorHeader;
