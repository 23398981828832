import { isEmpty } from 'lodash';
import { getParamDependentValues, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { REPORT_ID } from './useSelectedReportId';

export const REPORT_SORTING = 'sorting';

export const emptySorting = [];

export const getReportSortingKey = reportId => `${REPORT_SETTINGS_PREFIX}.${reportId}.sorting`;

export const useReportSortingParam = queryParamHookFactory(REPORT_SORTING, {
    serializer: JSON.stringify,
    deserializer: value => {
        if (!value) {
            return;
        }

        if (typeof value !== 'string') {
            return value;
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {}
    },
    dependencies: { queryParams: [REPORT_ID] },
    persist: PERSISTENCE_TYPE.DB_STORAGE,
    storageKeyGetter: (paramName, metadata) => {
        const dependentValues = getParamDependentValues(paramName, metadata);

        return getReportSortingKey(dependentValues[REPORT_ID]);
    },
});

export const useReportSorting = reportConfig => {
    const [paramValue, ...rest] = useReportSortingParam();
    const value = isEmpty(paramValue) ? reportConfig?.defaultGridSort || emptySorting : paramValue;

    return [value, ...rest];
};
