import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const normalizeCellEditor = WrappedComponent => {
    const Hoc = forwardRef((props, ref) => {
        const { value, stopEditing } = props;

        const valueRef = useRef(value);
        const [guiAttached, setGuiAttached] = useState(false);

        const handleSave = newValue => {
            valueRef.current = newValue;
            stopEditing();
        };

        useImperativeHandle(ref, () => ({
            isPopup: () => true,
            getValue: () => valueRef.current,
            afterGuiAttached: () => setGuiAttached(true),
        }));

        return <WrappedComponent {...props} onSave={handleSave} onCancel={stopEditing} guiAttached={guiAttached} />;
    });

    Hoc.propTypes = {
        /* ag-grid API */
        value: PropTypes.any,
        stopEditing: PropTypes.func,
    };

    return Hoc;
};

export default normalizeCellEditor;
