import React from 'react';
import { CONTENT_METHODS } from '../../config';
import { BulkUrlsField } from '../BulkUrlsField';
import RssFieldsTooltip from './RssFieldsTooltip';

const RssFields = ({ urls, updateUrls, updateValidatedUrls }) => (
    <BulkUrlsField
        urls={urls}
        onUrlsChange={updateUrls}
        updateValidatedUrls={updateValidatedUrls}
        contentMethod={CONTENT_METHODS.RSS}
        HelpTooltipComponent={RssFieldsTooltip}
    />
);

export default RssFields;
