import React from 'react';
import { DateRangeOutlinedIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { ConversionConfiguration } from 'modules/campaigns/components/ConversionConfiguration/ConversionConfiguration';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import BidStrategy from '../BidStrategy/BidStrategy';
import { ConversionOptimization } from '../ConversionOptimization/ConversionOptimization';
import { Funnel } from '../Funnel/Funnel';

export const BiddingConversionGoalSection = () => {
    return (
        <CommonCollapsibleCard
            id="bidding-and-conversion-goal"
            header={
                <SectionHeader
                    headerIcon={<DateRangeOutlinedIcon />}
                    headerText={
                        <FormattedMessage id="bidding.conversion.goal" defaultMessage="Bidding And Conversion Goal" />
                    }
                />
            }
        >
            <BidStrategy />
            <ConversionOptimization />
            <ConversionConfiguration />
            <Funnel />
        </CommonCollapsibleCard>
    );
};
