'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PaginatedDropdown = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.omit');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.isnil');

var _lodash4 = _interopRequireDefault(_lodash3);

var _hooks = require('../../../hooks');

var _DropdownMenu = require('../Dropdown/DropdownMenu');

var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

var _PaginatedDropdownContext = require('./PaginatedDropdownContext');

var _PaginatedDropdownMenuList = require('./PaginatedDropdownMenuList');

var _BareDropdown = require('../BareDropdown');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var offlineSearch = function offlineSearch() {
    return true;
};

var PaginatedDropdown = function PaginatedDropdown(_ref) {
    var searchDelay = _ref.searchDelay,
        loadParams = _ref.loadParams,
        loadPage = _ref.loadPage,
        pageSize = _ref.pageSize,
        totalItemCount = _ref.totalItemCount,
        estimatedTotal = _ref.estimatedTotal,
        onOptionsLoaded = _ref.onOptionsLoaded,
        enabledWhileSearching = _ref.enabledWhileSearching,
        disabled = _ref.disabled,
        DropdownMenuComponent = _ref.DropdownMenuComponent,
        placeholderProps = _ref.placeholderProps,
        maxTotalItemsBeforeThrottling = _ref.maxTotalItemsBeforeThrottling,
        paddingX = _ref.paddingX,
        paddingY = _ref.paddingY,
        itemHeight = _ref.itemHeight,
        optionItemRenderer = _ref.optionItemRenderer,
        props = _objectWithoutProperties(_ref, ['searchDelay', 'loadParams', 'loadPage', 'pageSize', 'totalItemCount', 'estimatedTotal', 'onOptionsLoaded', 'enabledWhileSearching', 'disabled', 'DropdownMenuComponent', 'placeholderProps', 'maxTotalItemsBeforeThrottling', 'paddingX', 'paddingY', 'itemHeight', 'optionItemRenderer']);

    var _useState = (0, _react.useState)(''),
        _useState2 = _slicedToArray(_useState, 2),
        searchText = _useState2[0],
        setSearchText = _useState2[1];

    var debouncedSearchText = (0, _hooks.useDebouncedValue)(searchText, searchDelay);
    var parameters = (0, _react.useMemo)(function () {
        return Object.assign({}, loadParams, {
            searchText: debouncedSearchText
        });
    }, [loadParams, debouncedSearchText]);

    var _usePagination = (0, _hooks.usePagination)({
        loadPage: loadPage,
        estimatedTotal: estimatedTotal,
        pageSize: pageSize,
        maxTotalItemsBeforeThrottling: maxTotalItemsBeforeThrottling,
        loadParams: parameters
    }),
        values = _usePagination.values,
        loadCurrentFrame = _usePagination.loadCurrentFrame,
        reload = _usePagination.reload,
        totalValues = _usePagination.totalValues,
        isItemLoaded = _usePagination.isItemLoaded;

    var menuListRenderer = (0, _react.useCallback)(function (args) {
        return _react2.default.createElement(_PaginatedDropdownMenuList.PaginatedDropdownMenuList, Object.assign({}, args, { placeholderProps: placeholderProps }));
    }, [placeholderProps]);

    var _useElementSize = (0, _hooks.useElementSize)(),
        width = _useElementSize.width,
        ref = _useElementSize.ref;

    (0, _react.useLayoutEffect)(function () {
        /**
         * I'd use a ref to the Dropdown itself, but since it's a class component
         * I'd have to get the underlying DOM node, which is deprecated:
         * https://reactjs.org/docs/react-dom.html#finddomnode
         */
        ref(document.getElementById(props.id));
    });
    // prefetch campaign list
    (0, _react.useEffect)(function () {
        reload();
    }, [reload]);
    (0, _react.useEffect)(function () {
        onOptionsLoaded(values);
    }, [values]);

    var resolvedItemHeight = itemHeight || (optionItemRenderer ? _BareDropdown.itemHeight : null);

    return _react2.default.createElement(
        _PaginatedDropdownContext.PaginatedDropdownProvider,
        {
            width: width,
            totalItemCount: !(0, _lodash4.default)(totalItemCount) ? totalItemCount : totalValues,
            loadNextPage: loadCurrentFrame,
            isItemLoaded: isItemLoaded,
            paddingX: paddingX,
            paddingY: paddingY,
            itemHeight: resolvedItemHeight
        },
        _react2.default.createElement(DropdownMenuComponent, Object.assign({}, props, {
            disabled: disabled && (!searchText || !enabledWhileSearching),
            inputValue: searchText,
            onInputChange: function onInputChange(text) {
                return setSearchText(text);
            },
            optionItemRenderer: optionItemRenderer,
            menuListRenderer: menuListRenderer,
            filterOption: offlineSearch
        }))
    );
};

exports.PaginatedDropdown = PaginatedDropdown;
PaginatedDropdown.propTypes = Object.assign({}, (0, _lodash2.default)(_DropdownMenu2.default.propTypes, ['filterOption', 'menuListRenderer', 'inputValue', 'onInputChange', 'value']), {
    value: _propTypes2.default.oneOfType([_DropdownMenu2.default.propTypes.value, _BareDropdown.BareDropdown.propTypes.value]),
    placeholderProps: _propTypes2.default.shape({
        fontSize: _propTypes2.default.number,
        paddingX: _propTypes2.default.number,
        paddingY: _propTypes2.default.number,
        className: _propTypes2.default.string,
        loaderClassName: _propTypes2.default.string,
        preserveAspectRatio: _propTypes2.default.string
    }),
    searchable: _propTypes2.default.bool,
    searchDelay: _propTypes2.default.number,
    loadPage: _propTypes2.default.func,
    loadParams: _propTypes2.default.object,
    pageSize: _propTypes2.default.number,
    estimatedTotal: _propTypes2.default.number,
    totalItemCount: _propTypes2.default.number,
    // react-select has inherent issues with great amount of
    // options. This allows us to throttle the "future pages"
    // when the total is too large.
    maxTotalItemsBeforeThrottling: _propTypes2.default.number,
    onOptionsLoaded: _propTypes2.default.func.isRequired,
    enabledWhileSearching: _propTypes2.default.bool,
    DropdownMenuComponent: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object])
});

PaginatedDropdown.defaultProps = {
    searchable: true,
    searchDelay: 300,
    loadParams: {},
    pageSize: 30,
    maxTotalItemsBeforeThrottling: 500,
    DropdownMenuComponent: _DropdownMenu2.default
};

exports.default = PaginatedDropdown;