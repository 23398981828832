import React, { useState } from 'react';
import { Button, MovieCreationOutlinedIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { useMotionStudioModal } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/GalleryItemDropdown/useMotionStudioModal';
import { useMotionStudioStatus } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/GalleryItemDropdown/useMotionStudioStatus';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import DisabledCreateVideoViaWochitTooltip from '../../../creative-editor/components/MediaTabs/UrlTab/DisabledCreateVideoViaWochitTooltip';
import styles from './MotionAdsStudio.module.scss';

export const MotionAdsStudio = ({ onSelectMedia, disabled }) => {
    const [uploadErrorMessageId, setUploadErrorMessageId] = useState();
    const { openModal: openMotionStudioModal, failedValidationVideoUrl } = useMotionStudioModal({
        onSelectMedia,
        onUploadErrorMessageChange: setUploadErrorMessageId,
    });

    const { isMotionStudioEnabled, isWochitAccessTokenInvalid } = useMotionStudioStatus();

    return (
        <>
            {isMotionStudioEnabled && (
                <FormField
                    containerClass={styles['form-field']}
                    label={
                        <FormattedMessage
                            id="creative.editor.tab.motion.ads.studio.title"
                            defaultMessage="Motion Ads Studio"
                        />
                    }
                    description={
                        <FormattedMessage
                            id="creative.editor.tab.motion.ads.studio.subtitle"
                            defaultMessage="Create Motion Ads from scratch in minutes using our on-line video creator"
                        />
                    }
                    descriptionClass={styles['create-video-icon']}
                    helpText={isWochitAccessTokenInvalid && <DisabledCreateVideoViaWochitTooltip />}
                >
                    <Button
                        // required b/c otherwise the event will be treated
                        // as a URL
                        onClick={() => openMotionStudioModal()}
                        size={Button.size.lg}
                        variant={Button.variant.ghost}
                        disabled={isWochitAccessTokenInvalid || disabled}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="Motion Ads Studio"
                        data-metrics-value="Button Create Motion Ad"
                    >
                        <MovieCreationOutlinedIcon />
                        <FormattedMessage id="creative.editor.tab.create.motion.ad" defaultMessage="Create Motion Ad" />
                    </Button>
                    {(failedValidationVideoUrl || uploadErrorMessageId) && (
                        <div className={styles['url-validation-error-wochit']}>
                            <FormattedMessage
                                id={uploadErrorMessageId || 'validations.error.field.motion.ads.Studio'}
                                defaultMessage="Oops, something went wrong. Please try again"
                            />
                        </div>
                    )}
                </FormField>
            )}
        </>
    );
};
