import { COMPONENT_STATUS } from '../../../../../services/constants';
import updateObject from '../../../../taboola-common-frontend-modules/utils/updateObject';
import {
    SET_CREATIVE_ROLLBACK_DATA,
    SET_CREATIVE_BEING_EDITED,
    INIT_CREATIVE_THUMBNAIL_EDITOR_ERROR,
    CLEAR_CREATIVE_DRAWER_RESOURCES,
    FETCH_CREATIVE_SUCCESS,
    FETCH_CREATIVE_ERROR,
    REQUEST_UPLOAD_CREATIVE_THUMBNAIL,
    UPLOAD_CREATIVE_THUMBNAIL_SUCCESS,
    UPLOAD_CREATIVE_THUMBNAIL_ERROR,
    FETCH_RECOMMENDED_MEDIA_SUCCESS,
    REQUEST_FETCH_RECOMMENDED_MEDIA,
    FETCH_RECOMMENDED_MEDIA_ERROR,
    RESET_RECOMMENDED_MEDIA,
    FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS,
    REQUEST_FETCH_MEDIA_LIBRARY_ITEMS,
    FETCH_MEDIA_LIBRARY_ITEMS_ERROR,
    RESET_MEDIA_LIBRARY_ITEMS,
    SET_AI_TAB_REFERENCE_IMAGE,
    SET_AI_TAB_TO_INITIAL_STATE,
    MERGE_AI_TAB_IMAGE_PROMPT_MAP,
} from '../actions';

const setCreativeBeingEdited = (state, action) => ({
    creativeBeingEdited: action.payload,
});

const fetchCreativeError = () => ({
    creativeBeingEdited: null,
});

const clearCreativeDrawerResources = () => ({
    creativeBeingEdited: null,
    creativeRollbackData: null,
    recommendedMedia: [],
    keywords: [],
    recommendedMediaStatus: COMPONENT_STATUS.INITIAL,
    thumbnailUploadStatus: COMPONENT_STATUS.INITIAL,
    aiTabImagePromptMap: {},
    aiTabReferenceImage: null,
});

const requestUploadCreativeThumbnail = () => ({
    thumbnailUploadStatus: COMPONENT_STATUS.LOADING,
});

const uploadCreativeThumbnailSuccess = () => ({
    thumbnailUploadStatus: COMPONENT_STATUS.INITIAL,
});

const uploadCreativeThumbnailError = () => ({
    thumbnailUploadStatus: COMPONENT_STATUS.ERROR,
});

const setRecommendedMedia = (state, action) => ({
    recommendedMedia: action.payload,
    recommendedMediaStatus: COMPONENT_STATUS.ACTIVE,
});

const setRecommendedMediaStatusLoading = () => ({
    recommendedMediaStatus: COMPONENT_STATUS.LOADING,
});

const setRecommendedMediaError = () => ({
    recommendedMedia: [],
    recommendedMediaStatus: COMPONENT_STATUS.ERROR,
});

const resetRecommendedMedia = () => ({
    recommendedMedia: [],
    recommendedMediaStatus: COMPONENT_STATUS.INITIAL,
});

const setMediaLibraryItems = (state, action) => ({
    mediaLibraryItems: state.mediaLibraryItems.concat(action.payload),
    mediaLibraryItemsStatus: COMPONENT_STATUS.ACTIVE,
});

const setMediaLibraryItemsStatusLoading = () => ({
    mediaLibraryItemsStatus: COMPONENT_STATUS.LOADING,
});

const setMediaLibraryItemsError = () => ({
    mediaLibraryItems: [],
    mediaLibraryItemsStatus: COMPONENT_STATUS.ERROR,
});

const resetMediaLibraryItems = () => ({
    mediaLibraryItems: [],
    mediaLibraryItemsStatus: COMPONENT_STATUS.INITIAL,
});

const mergeAITabImagePromptMap = (state, action) => ({
    aiTabImagePromptMap: { ...state.aiTabImagePromptMap, ...action.payload },
});

const setAITabReferenceImage = (state, action) => ({
    aiTabReferenceImage: action.payload,
});

const setAITabToInitialState = () => ({
    aiTabImagePromptMap: {},
    aiTabReferenceImage: null,
});

export const creativeEditorInitialState = clearCreativeDrawerResources();

export const creativeEditorReducers = {
    [SET_CREATIVE_ROLLBACK_DATA]: (state, { payload: creativeRollbackData }) =>
        updateObject(state, { creativeRollbackData }),
    [SET_CREATIVE_BEING_EDITED]: (state, action) => updateObject(state, setCreativeBeingEdited(state, action)),
    [INIT_CREATIVE_THUMBNAIL_EDITOR_ERROR]: state => updateObject(state, fetchCreativeError()),
    [FETCH_CREATIVE_SUCCESS]: (state, action) => updateObject(state, setCreativeBeingEdited(state, action)),
    [FETCH_CREATIVE_ERROR]: state => updateObject(state, fetchCreativeError()),
    [CLEAR_CREATIVE_DRAWER_RESOURCES]: state => updateObject(state, clearCreativeDrawerResources()),
    [REQUEST_UPLOAD_CREATIVE_THUMBNAIL]: state => updateObject(state, requestUploadCreativeThumbnail()),
    [UPLOAD_CREATIVE_THUMBNAIL_SUCCESS]: state => updateObject(state, uploadCreativeThumbnailSuccess()),
    [UPLOAD_CREATIVE_THUMBNAIL_ERROR]: state => updateObject(state, uploadCreativeThumbnailError()),
    [FETCH_RECOMMENDED_MEDIA_SUCCESS]: (state, action) => updateObject(state, setRecommendedMedia(state, action)),
    [REQUEST_FETCH_RECOMMENDED_MEDIA]: state => updateObject(state, setRecommendedMediaStatusLoading()),
    [FETCH_RECOMMENDED_MEDIA_ERROR]: state => updateObject(state, setRecommendedMediaError()),
    [RESET_RECOMMENDED_MEDIA]: state => updateObject(state, resetRecommendedMedia()),
    [FETCH_MEDIA_LIBRARY_ITEMS_SUCCESS]: (state, action) => updateObject(state, setMediaLibraryItems(state, action)),
    [REQUEST_FETCH_MEDIA_LIBRARY_ITEMS]: state => updateObject(state, setMediaLibraryItemsStatusLoading()),
    [FETCH_MEDIA_LIBRARY_ITEMS_ERROR]: state => updateObject(state, setMediaLibraryItemsError()),
    [RESET_MEDIA_LIBRARY_ITEMS]: state => updateObject(state, resetMediaLibraryItems()),
    [MERGE_AI_TAB_IMAGE_PROMPT_MAP]: (state, action) => updateObject(state, mergeAITabImagePromptMap(state, action)),
    [SET_AI_TAB_REFERENCE_IMAGE]: (state, action) => updateObject(state, setAITabReferenceImage(state, action)),
    [SET_AI_TAB_TO_INITIAL_STATE]: state => updateObject(state, setAITabToInitialState()),
};
