'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _EditableText = require('./EditableText');

var _EditableText2 = _interopRequireDefault(_EditableText);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _EditableText2.default;