import { useCallback, useState } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useAudiencesApi } from 'services/api/audiencesApi';
import TARGETING_TYPES from '../../../config/TargetingTypes';

const fetchProviders = ({ selectedAccountId, countryCodes, getMarketplaceProviders }) => {
    const accountId = selectedAccountId || {};

    return getMarketplaceProviders(accountId, { countryCodes });
};
const transformProviders = providers => providers.map(({ provider, ...rest }) => ({ id: provider, ...rest }));
const NO_COUNTRIES = [];

const useProvidersApi = ({ selectedAccountId }) => {
    const { getMarketplaceProviders } = useAudiencesApi();
    const [providers, setProviders] = useState([]);
    const [isProvidersReady, setIsProvidersReady] = useState(false);
    const { value: countryTargetingType = TARGETING_TYPES.ALL } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });
    const { value: countries } = useFormFieldValue({ field: 'campaignTargeting.geoTargeting.countryTargeting.values' });
    const countryCodes = countryTargetingType === TARGETING_TYPES.EXCLUDE ? NO_COUNTRIES : countries;

    const providersFetcher = useCallback(async () => {
        const { results } = await fetchProviders({ selectedAccountId, countryCodes, getMarketplaceProviders });
        const transformedProviders = transformProviders(results);
        setProviders(transformedProviders);
        setIsProvidersReady(true);
        return transformedProviders;
    }, [selectedAccountId, countryCodes, getMarketplaceProviders]);

    return { providers, reloadProviders: providersFetcher, isProvidersReady };
};

export default useProvidersApi;
