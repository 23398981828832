"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgMobileBig(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M10 9V8C10 6.896 10.896 6 12 6H20C21.104 6 22 6.896 22 8V9H10ZM10 23H22V10H10V23ZM22 25C22 26.104 21.104 27 20 27H12C10.896 27 10 26.104 10 25V24H22V25ZM20 5H12C10.343 5 9 6.343 9 8V25C9 26.657 10.343 28 12 28H20C21.657 28 23 26.657 23 25V8C23 6.343 21.657 5 20 5ZM14.5 25.5C14.5 25.7761 14.7239 26 15 26H17C17.2761 26 17.5 25.7761 17.5 25.5C17.5 25.2239 17.2761 25 17 25H15C14.7239 25 14.5 25.2239 14.5 25.5ZM14.5 7.5C14.5 7.77614 14.7239 8 15 8H17C17.2761 8 17.5 7.77614 17.5 7.5C17.5 7.22386 17.2761 7 17 7H15C14.7239 7 14.5 7.22386 14.5 7.5Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgMobileBig;