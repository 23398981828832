import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';

const getIndexFromCoords = (columnIndex, rowIndex, columnCount) => columnIndex + rowIndex * columnCount;

const PaginatedGrid = ({
    width,
    height,
    estimatedColumnWidth,
    estimatedRowHeight,

    columnWidth,
    columnCount,
    itemHeight,
    rowHeight,
    gridStyleOverride,
    gap,

    items,
    totalItemCount,
    gridItemComponent: GridItemComponent,
    itemOnClick,

    isItemLoaded,
    loadMoreItems,
    urlTransformer,
    infiniteLoaderThreshold,

    onSelectMedia,
    GalleryItemDropdown,
    initialGalleryItemTooltip,
    initialGalleryItemTooltipProps,
}) => {
    const windowRef = useRef(null);
    const gridRef = useRef(null);
    const rowCount = Math.ceil(totalItemCount / columnCount);

    const calcStyleWithGap = useCallback(
        (style, columnIndex, rowIndex) => {
            const lastRow = rowIndex === rowCount - 1;
            const lastColumn = columnIndex === columnCount - 1;

            return {
                ...style,
                paddingTop: 0,
                paddingBottom: lastRow ? 0 : gap,
                paddingLeft: 0,
                paddingRight: lastColumn ? 0 : gap,
            };
        },
        [columnCount, gap, rowCount]
    );

    const isGenAiEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });

    const calcColumnWidthWithGap = useCallback(
        index => {
            const targetWidth = columnWidth(index);

            return index === columnCount - 1 ? targetWidth : targetWidth + gap;
        },
        [columnCount, columnWidth, gap]
    );

    const calcRowHeightWithGap = useCallback(
        index => {
            const targetHeight = rowHeight(index);

            return index === rowCount - 1 ? targetHeight : targetHeight + gap;
        },
        [gap, rowCount, rowHeight]
    );

    const gridOnItemsRendered = useCallback(
        infiniteOnItemsRendered =>
            ({ visibleColumnStartIndex, visibleColumnStopIndex, visibleRowStartIndex, visibleRowStopIndex }) => {
                const visibleStartIndex = visibleRowStartIndex * columnCount + visibleColumnStartIndex;
                const visibleStopIndex = visibleRowStopIndex * columnCount + visibleColumnStopIndex;

                infiniteOnItemsRendered({ visibleStartIndex, visibleStopIndex });
            },
        [columnCount]
    );

    useEffect(() => {
        if (windowRef.current) {
            windowRef.current.resetAfterIndices({ columnIndex: 0, rowIndex: 0 });
        }
    }, [width]);

    const firstImageIndex = useMemo(() => {
        if (!Array.isArray(items)) {
            return -1;
        }

        return items.findIndex(i => get(i, 'fileType') === 'image');
    }, [items]);

    const renderCell = useCallback(
        ({ columnIndex, rowIndex, style }) => {
            const itemIndex = getIndexFromCoords(columnIndex, rowIndex, columnCount);

            const styleWithGap = !!gap ? calcStyleWithGap(style, columnIndex, rowIndex) : style;

            return (
                <div style={styleWithGap}>
                    <GridItemComponent
                        itemIndex={itemIndex}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        onClick={itemOnClick}
                        urlTransformer={urlTransformer}
                        itemHeight={itemHeight}
                        GalleryItemDropdown={isGenAiEnabled ? GalleryItemDropdown : null}
                        onSelectMedia={onSelectMedia}
                        galleryItemTooltip={itemIndex === firstImageIndex ? initialGalleryItemTooltip : undefined}
                        galleryItemTooltipProps={
                            itemIndex === firstImageIndex ? initialGalleryItemTooltipProps : undefined
                        }
                        gridRef={gridRef}
                    />
                </div>
            );
        },
        [
            columnCount,
            gap,
            calcStyleWithGap,
            itemOnClick,
            urlTransformer,
            itemHeight,
            isGenAiEnabled,
            GalleryItemDropdown,
            onSelectMedia,
            firstImageIndex,
            initialGalleryItemTooltip,
            initialGalleryItemTooltipProps,
        ]
    );

    return (
        <InfiniteLoader
            threshold={infiniteLoaderThreshold}
            isItemLoaded={isItemLoaded}
            itemCount={totalItemCount}
            loadMoreItems={loadMoreItems}
        >
            {({ onItemsRendered, ref: setGridRef }) => (
                <Grid
                    onItemsRendered={gridOnItemsRendered(onItemsRendered)}
                    width={width}
                    height={height}
                    itemCount={totalItemCount}
                    columnCount={columnCount}
                    columnWidth={calcColumnWidthWithGap}
                    rowHeight={calcRowHeightWithGap}
                    rowCount={rowCount}
                    ref={grid => {
                        setGridRef(grid);
                        windowRef.current = grid;
                    }}
                    estimatedColumnWidth={estimatedColumnWidth}
                    estimatedRowHeight={estimatedRowHeight}
                    style={gridStyleOverride}
                    outerRef={gridRef}
                >
                    {renderCell}
                </Grid>
            )}
        </InfiniteLoader>
    );
};

PaginatedGrid.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    estimatedColumnWidth: PropTypes.number,
    estimatedRowHeight: PropTypes.number,

    columnWidth: PropTypes.func,
    columnCount: PropTypes.number,
    rowHeight: PropTypes.func,
    gridStyle: PropTypes.object,
    gap: PropTypes.number,

    totalItemCount: PropTypes.number,
    gridItemComponent: PropTypes.any,
    itemOnClick: PropTypes.func,

    isItemLoaded: PropTypes.func,
    loadMoreItems: PropTypes.func,

    infiniteLoaderThreshold: PropTypes.number,
};

PaginatedGrid.defaultProps = {
    gap: 0,
};

export default PaginatedGrid;
