import { withGridCellFormDataProvider } from '../../../../../taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useCpcCapValidations } from '../../../common-campaign-form/components/BidStrategyV2/ApplyCapSection/CpcCapInput/useCpcCapValidations';
import SimpleCurrencyCellEditor from './SimpleCurrencyCellEditor';

const CpcCapCellEditor = props => {
    const validationsAndDependencies = useCpcCapValidations();

    return <SimpleCurrencyCellEditor {...props} {...validationsAndDependencies} />;
};

export const CpcCapCellEditorWithFormData = withGridCellFormDataProvider(CpcCapCellEditor);
