import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Button } from 'tuui';
import { useModuleName, useNavigate, useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import useAvailableItems from '../../../hooks/useAvailableItems';
import { isEditDisabled } from '../config';
import { generateScheduledReportsCreatorPath } from '../utils/generateScheduledReportsPath';

const optionsConfigurations = [
    {
        id: 'scheduledReports',
        value: 'scheduledReports',
        label: 'Scheduled Reports',
        path: generateScheduledReportsCreatorPath(),
        dataMetricColumnName: 'scheduledReports',
        visibilityCondition: ({ accountConfig, permissionsMap, selectedAccount: { type } }) =>
            !isEditDisabled({
                data: '',
                context: {
                    selectedAccountConfig: accountConfig,
                    userPermissions: permissionsMap,
                    accountType: type,
                },
            }),
    },
];
export const CreateScheduledReportsButton = () => {
    const moduleName = useModuleName();
    const navigate = useNavigate();
    const [selectedAccount] = useSelectedAccount();
    const rawOptions = useAvailableItems(optionsConfigurations, {
        selectedAccount,
        moduleName,
    });
    const handleOnChange = useCallback(
        ({ path }) => {
            navigate(path);
        },
        [navigate]
    );
    return !isEmpty(rawOptions) ? (
        <div>
            <Button onClick={() => handleOnChange(rawOptions[0])}>
                <FormattedMessage id="app.gridToolbar.create.scheduledReports" defaultMessage="NEW REPORT" />
            </Button>
        </div>
    ) : null;
};
