import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { RssIcon, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { isProgrammaticCampaign } from '../../../../../../services/campaignDimension';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import { isRssChild } from '../../config/predicates';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './creativeIdCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);

const CreativeIdCellRenderer = ({ value, data, isSearchable }) => {
    const { rssUrl, demandType } = data;
    const isRSS = isRssChild(data);
    const containerClassName = classNameBuilder('creative-id-container', { 'with-icon': isRSS });

    if (isProgrammaticCampaign(demandType)) {
        return EMPTY_FIELD_VALUE;
    }

    return (
        <div className={containerClassName}>
            {isRSS && <RssIconTooltip rssUrl={rssUrl} />}
            <EllipsisCellRenderer value={value} lines={1} isSearchable={isSearchable} />
        </div>
    );
};

const RssIconTooltip = ({ rssUrl }) => (
    <div>
        <RssIcon className={styles['rss-icon']} />
        <Tooltip position={TOOLTIP_POSITION.LEFT} arrow interactive>
            <span className={styles['rss-url']}>{rssUrl}</span>
        </Tooltip>
    </div>
);

CreativeIdCellRenderer.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.object,
};

export default CreativeIdCellRenderer;
