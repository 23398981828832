import classnames from 'classnames/bind';
import { Button, CloseIcon } from 'tuui';
import { useSelectedAccount } from '../../../../../../../hooks';
import usePerformanceRecommendationExecution from '../../../hooks/usePerformanceRecommendationExecution';
import styles from './baseRibbon.module.scss';

const classNameBuilder = classnames.bind(styles);

export const BaseRibbon = ({ recommendation, recommendationIndicationType, children }) => {
    const { id, campaignId } = recommendation;
    const [{ accountId }] = useSelectedAccount();
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        dismissParams: {
            recommendationId: id,
            accountId,
            campaignId,
        },
    });

    return (
        <div className={classNameBuilder('container', recommendationIndicationType)}>
            {children}
            <div className={styles['separator']} />
            <Button
                variant={Button.variant.text}
                size={Button.size.sm}
                className={styles['dismiss-container']}
                onClick={dismiss.invoke}
                disabled={apply.isSuccess}
                aria-label="dismiss button"
            >
                <CloseIcon role="img" aria-label="dismissIcon" />
            </Button>
        </div>
    );
};
