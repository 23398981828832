import { useCallback } from 'react';
import { isEqual } from 'lodash';
import { ACCOUNT_ID, useCurrentValueGetter } from 'hooks';
import { HISTORY_METHOD, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_ID } from './useSelectedReportId';

const PARAM_NAME = 'autoFiltersTrigger';

const useLastAppliedAutoFiltersParam = queryParamHookFactory(PARAM_NAME, {
    persist: PERSISTENCE_TYPE.MEMORY,
    serializer: value => encodeURIComponent(JSON.stringify(value)),
    deserializer: value => {
        try {
            return JSON.parse(decodeURIComponent(value));
        } catch (err) {}
    },
    dependencies: {
        queryParams: [ACCOUNT_ID, REPORT_ID],
    },
    keepSingleValue: true,
});

export const useLastAutoFiltersTrigger = () => {
    const [value, setValue] = useLastAppliedAutoFiltersParam();
    const setValueHandler = useCallback(
        (reportId, accountName) => {
            setValue({ reportId, accountName }, HISTORY_METHOD.REPLACE);
        },
        [setValue]
    );
    const valueGetter = useCurrentValueGetter(value);
    const shouldSkipAutoFilters = useCallback(
        (reportId, accountName) => isEqual({ reportId, accountName }, valueGetter()),
        [valueGetter]
    );

    return { setValue: setValueHandler, shouldSkipAutoFilters };
};
