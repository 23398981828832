import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Indication } from 'taboola-ultimate-ui';
import styles from './staticIndications.module.scss';

export const StaticIndications = ({ staticIndications, containerClassName }) => (
    <div className={containerClassName}>
        {staticIndications.map(({ staticIndicationType, ...rest }) => (
            <Indication
                key={staticIndicationType}
                contentClassName={styles['content']}
                containerClassName={styles['centered-container']}
                className={styles['indication']}
                displayDismissComponent={false}
                {...rest}
            />
        ))}
    </div>
);

const mapStateToProps = state => {
    const { staticIndications } = state.indicationsReducer;

    return { staticIndications };
};

StaticIndications.propTypes = {
    /** Static Indications */
    staticIndications: PropTypes.array,
    /** ClassName of container surrounding indications */
    containerClassName: PropTypes.string,
};

StaticIndications.defaultProps = {
    staticIndications: [],
};

export default connect(mapStateToProps)(StaticIndications);
