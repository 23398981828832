import EMPTY_FIELD_VALUE from '../../config/defaultValues';

export const statusFormatter = ({
    value,
    rowData: { status, rejectReason },
    formatOptions: { msgIdPrefix, isStatusRejected, emptyValue = EMPTY_FIELD_VALUE },
    i18n,
}) => {
    const formattedStatusMsg = i18n.formatMessage({
        id: `${msgIdPrefix}.${value}`,
        defaultMessage: value || emptyValue,
    });
    if (isStatusRejected({ status, rejectReason })) {
        const formattedReasonMsg = i18n.formatMessage({
            id: `${msgIdPrefix}.${rejectReason}`,
            defaultMessage: rejectReason || emptyValue,
        });
        return `${formattedStatusMsg} (${formattedReasonMsg})`;
    }
    return formattedStatusMsg;
};
