"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCompletionCircle25(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("circle", {
            cx: 12,
            cy: 12,
            r: 7.5,
            fill: "white",
            stroke: "currentColor"
        }),
        React.createElement(
            "mask",
            { id: "path-2-inside-1_503_901", fill: "white" },
            React.createElement("path", { d: "M12 6C12.7879 6 13.5681 6.15519 14.2961 6.45672C15.0241 6.75825 15.6855 7.20021 16.2426 7.75736C16.7998 8.31451 17.2417 8.97595 17.5433 9.7039C17.8448 10.4319 18 11.2121 18 12L12 12V6Z" })
        ),
        React.createElement("path", {
            d: "M12 6C12.7879 6 13.5681 6.15519 14.2961 6.45672C15.0241 6.75825 15.6855 7.20021 16.2426 7.75736C16.7998 8.31451 17.2417 8.97595 17.5433 9.7039C17.8448 10.4319 18 11.2121 18 12L12 12V6Z",
            fill: "currentColor",
            stroke: "currentColor",
            strokeWidth: 2,
            mask: "url(#path-2-inside-1_503_901)"
        })
    );
}
exports.default = SvgCompletionCircle25;