import { useState, useMemo, useCallback } from 'react';
import { findIndex } from 'lodash';
import { remove as fpRemove, set as fpSet } from 'lodash/fp';
import uuid from 'uuid/v1';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { UploadGalleryItem } from './UploadGalleryItem';
import useMediaLibraryItemsFetchService from './services/useMediaLibraryItemsFetchService';

const useUploadedMedia = () => {
    const [uploadedMedia, setUploadedMedia] = useState([]);

    const onInitUpload = useCallback(() => {
        const id = uuid();
        setUploadedMedia(prev => [{ id }, ...prev]);
        return id;
    }, [setUploadedMedia]);

    const onLoadedMedia = useCallback(
        (id, mediaItem) => {
            setUploadedMedia(prevItems => {
                const index = findIndex(prevItems, { id });
                return fpSet(index, mediaItem, prevItems);
            });
        },
        [setUploadedMedia]
    );

    const onRemoveUpload = useCallback(
        id => {
            setUploadedMedia(prevItems => fpRemove({ id }, prevItems));
        },
        [setUploadedMedia]
    );

    return {
        uploadedMedia,
        onInitUpload,
        onLoadedMedia,
        onRemoveUpload,
    };
};

export const useRecentlyUsedMediaSubTab = (accountId, loadParams) => {
    const { uploadedMedia, onInitUpload, onLoadedMedia, onRemoveUpload } = useUploadedMedia();
    const [uploadErrorMessageId, setUploadErrorMessageId] = useState();
    const isUploadEnabled = useConfigMatch({ [FEATURE_FLAGS.UPLOAD_IN_MEDIA_LIBRARY_ENABLED]: 'true' });
    const {
        loadedMedia: loadedMediaLibraryItems,
        reload,
        loadNextPage,
        isItemLoaded,
        isModuleReady,
        paginationStatus: status,
        totalValues: totalFetchedMediaValues = 0,
    } = useMediaLibraryItemsFetchService(accountId, loadParams);

    const totalValuesWithUpload = isUploadEnabled
        ? totalFetchedMediaValues + 1 + uploadedMedia.length
        : totalFetchedMediaValues;

    const items = useMemo(() => {
        const items = [...uploadedMedia, ...loadedMediaLibraryItems];
        if (isUploadEnabled) {
            items.unshift({
                component: props => (
                    <UploadGalleryItem
                        {...props}
                        onInitUpload={onInitUpload}
                        onLoadedMedia={onLoadedMedia}
                        onRemoveUpload={onRemoveUpload}
                        setUploadErrorMessageId={setUploadErrorMessageId}
                    />
                ),
            });
        }
        return items;
    }, [isUploadEnabled, loadedMediaLibraryItems, onInitUpload, onLoadedMedia, onRemoveUpload, uploadedMedia]);

    return {
        items,
        reload,
        loadNextPage,
        isItemLoaded,
        isModuleReady,
        paginationStatus: status,
        totalValues: totalValuesWithUpload,
        uploadErrorMessageId,
        setUploadErrorMessageId,
        isUploadEnabled,
        onInitUpload,
        onLoadedMedia,
        onRemoveUpload,
    };
};
