'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _constants = require('../constants');

var _Indication = require('../Indication/Indication');

var _Indication2 = _interopRequireDefault(_Indication);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AutoDismissedIndication = function (_Component) {
    _inherits(AutoDismissedIndication, _Component);

    function AutoDismissedIndication() {
        _classCallCheck(this, AutoDismissedIndication);

        var _this = _possibleConstructorReturn(this, (AutoDismissedIndication.__proto__ || Object.getPrototypeOf(AutoDismissedIndication)).call(this));

        _this.state = { visible: true };
        _this.dismiss = _this.dismiss.bind(_this);
        return _this;
    }

    _createClass(AutoDismissedIndication, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.initDismissTimeout();
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            this.resetDismissTimeout();
        }
    }, {
        key: 'getTimeoutDuration',
        value: function getTimeoutDuration() {
            var _props = this.props,
                timeoutDuration = _props.timeoutDuration,
                type = _props.type;


            return timeoutDuration || _constants.TIMEOUTS_BY_TYPE[type] || _constants.DEFAULT_TIMEOUT;
        }
    }, {
        key: 'dismiss',
        value: function dismiss() {
            var onDismiss = this.props.onDismiss;

            this.setState({ visible: false });
            if (onDismiss) {
                onDismiss();
            }
            this.resetDismissTimeout();
        }
    }, {
        key: 'initDismissTimeout',
        value: function initDismissTimeout() {
            var _this2 = this;

            var timeoutActive = this.props.timeoutActive;

            var timeoutDuration = this.getTimeoutDuration();

            this.timerHandler = timeoutActive ? setTimeout(function () {
                _this2.dismiss();
                _this2.timerHandler = false;
            }, timeoutDuration) : this.timerHandler;
        }
    }, {
        key: 'resetDismissTimeout',
        value: function resetDismissTimeout() {
            if (this.timerHandler) {
                clearTimeout(this.timerHandler);
                this.timerHandler = false;
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var props = this.props;
            var visible = this.state.visible;

            var newProps = Object.assign({}, props, { onDismiss: this.dismiss });
            return visible ? _react2.default.createElement(_Indication2.default, newProps) : null;
        }
    }]);

    return AutoDismissedIndication;
}(_react.Component);

AutoDismissedIndication.propTypes = Object.assign({
    /** The Time (in millis) for an automatic dismissal of the Indication */
    timeoutDuration: _propTypes2.default.number,
    /** Determines if the Indication should be dismissed on timeout */
    timeoutActive: _propTypes2.default.bool
}, _Indication2.default.propTypes);

AutoDismissedIndication.defaultProps = Object.assign({
    timeoutActive: true
}, _Indication2.default.defaultProps);

exports.default = AutoDismissedIndication;