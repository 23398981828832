import { useMemo } from 'react';
import { isEmpty, find, findIndex, forEach } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { OPERATION_REQUIREMENT_TYPES } from '../consts/columnRequiredTypes';
import { getEntityMetadataFromWorkbook, getSheetName } from '../utils/excelBulkWorkbookUtils';
import { useExcelConfig } from './useExcelConfig';

const getNumUpdatesAndCreations = (sheetConfig, formatMessage, workbook) => {
    let numUpdates = 0;
    let numCreations = 0;

    const sheetName = getSheetName(sheetConfig.sheetName, formatMessage);
    const sheet = workbook.getWorksheet(sheetName);
    const startingReadRowNumber = sheetConfig.headerRowIndex + 1;
    const allRows = sheet.getRows(startingReadRowNumber, sheet.rowCount);
    const headerRowValues = sheet.getRow(sheetConfig.headerRowIndex).values;
    const metadata = getEntityMetadataFromWorkbook(workbook, sheetConfig);

    const mandatoryColumnForUpdate = find(
        sheetConfig.columns,
        column => column.operationRequirementType === OPERATION_REQUIREMENT_TYPES.UPDATE
    );

    const updateColumnIndex = findIndex(
        headerRowValues,
        headerValue => headerValue === metadata[mandatoryColumnForUpdate.field]
    );
    const rows = allRows.filter(row => !isEmpty(row.values));

    rows.forEach(row => {
        row.getCell(updateColumnIndex).value ? numUpdates++ : numCreations++;
    });

    return { numUpdates, numCreations };
};

export const useBulkUploadPreview = () => {
    const excelConfig = useExcelConfig();
    const { formatMessage } = useIntl();
    const { value: workbook } = useFormFieldValue({ field: 'excelWorkbook' });

    const entityToNumOperationsMap = useMemo(() => {
        const entityToNumOperationsMap = {};
        if (!workbook) {
            return entityToNumOperationsMap;
        }

        forEach(excelConfig.entityList, sheetConfig => {
            entityToNumOperationsMap[sheetConfig.entity] = getNumUpdatesAndCreations(
                sheetConfig,
                formatMessage,
                workbook
            );
        });

        return entityToNumOperationsMap;
    }, [workbook, excelConfig.entityList, formatMessage]);

    return { entityToNumOperationsMap };
};
