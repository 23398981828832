export const SELECT_CAMPAIGN = 'SELECT_CAMPAIGN';
export const SELECT_REPORT = 'SELECT_REPORT';
export const RESET_CAMPAIGNS = 'RESET_CAMPAIGNS';

export const REQUEST_FETCH_CAMPAIGNS = 'REQUEST_FETCH_CAMPAIGNS';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_ERROR = 'FETCH_CAMPAIGNS_ERROR';

export const REQUEST_FETCH_CAMPAIGN = 'REQUEST_FETCH_CAMPAIGN';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_ERROR = 'FETCH_CAMPAIGN_ERROR';

export const REQUEST_CREATE_CAMPAIGN = 'REQUEST_CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR';

export const REQUEST_DELETE_CAMPAIGN = 'REQUEST_DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';

export const REQUEST_DELETE_CAMPAIGN_GROUP = 'REQUEST_DELETE_CAMPAIGN_GROUP';
export const DELETE_CAMPAIGN_GROUP_SUCCESS = 'DELETE_CAMPAIGN_GROUP_SUCCESS';
export const DELETE_CAMPAIGN_GROUP_ERROR = 'DELETE_CAMPAIGN_GROUP_ERROR';

export const REQUEST_SAVE_CAMPAIGN = 'REQUEST_SAVE_CAMPAIGN';
export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS';
export const SAVE_CAMPAIGN_ERROR = 'SAVE_CAMPAIGN_ERROR';
export const UPDATE_CREATIVES_CAMPAIGN_NAME = 'UPDATE_CREATIVES_CAMPAIGN_NAME';

export const REQUEST_DUPLICATE_CAMPAIGN = 'REQUEST_DUPLICATE_CAMPAIGN';
export const DUPLICATE_CAMPAIGN_SUCCESS = 'DUPLICATE_CAMPAIGN_SUCCESS';
export const DUPLICATE_CAMPAIGN_ERROR = 'DUPLICATE_CAMPAIGN_ERROR';

export const SAVE_CREATIVE_SUCCESS = 'SAVE_CREATIVE_SUCCESS';
export const SAVE_CREATIVE_ERROR = 'SAVE_CREATIVE_FAILURE';

export const REQUEST_DELETE_CREATIVE = 'REQUEST_DELETE_CREATIVE';
export const DELETE_CREATIVE_SUCCESS = 'DELETE_CREATIVE_SUCCESS';
export const DELETE_CREATIVE_ERROR = 'DELETE_CREATIVE_ERROR';
export const RESET_CREATIVES = 'RESET_CREATIVES';

export const TOGGLE_CAMPAIGN_PICKER_PINNED = 'TOGGLE_CAMPAIGN_PICKER_PINNED';

export const REQUEST_TOGGLE_SITE_BLOCKING = 'REQUEST_TOGGLE_SITE_BLOCKING';
export const TOGGLE_SITE_BLOCKING_SUCCESS = 'TOGGLE_SITE_BLOCKING_SUCCESS';
export const TOGGLE_SITE_BLOCKING_ERROR = 'TOGGLE_SITE_BLOCKING_ERROR';

export const UPDATE_CAMPAIGNS_SUCCESS = 'UPDATE_CAMPAIGNS_SUCCESS';
export const UPDATE_CAMPAIGNS_ERROR = 'UPDATE_CAMPAIGNS_ERROR';
export const REQUEST_UPDATE_CAMPAIGNS = 'REQUEST_UPDATE_CAMPAIGNS';

export const UPDATE_CREATIVES_SUCCESS = 'UPDATE_CREATIVES_SUCCESS';
export const UPDATE_CREATIVES_ERROR = 'UPDATE_CREATIVES_ERROR';
export const REQUEST_UPDATE_CREATIVES = 'REQUEST_UPDATE_CREATIVES';

export const DELETE_CREATIVES_SUCCESS = 'DELETE_CREATIVES_SUCCESS';
export const DELETE_CREATIVES_ERROR = 'DELETE_CREATIVES_ERROR';
export const REQUEST_DELETE_CREATIVES = 'REQUEST_DELETE_CREATIVES';

export const REQUEST_FETCH_SYNDICATOR_RECOMMENDATION_COUNTS = 'REQUEST_FETCH_SYNDICATOR_RECOMMENDATION_COUNTS';
export const FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS = 'FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS';
export const FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_ERROR = 'FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_ERROR';

export const APPLY_RECOMMENDATION_SUCCESS = 'APPLY_RECOMMENDATION_SUCCESS';
export const DISMISS_RECOMMENDATION_SUCCESS = 'DISMISS_RECOMMENDATION_SUCCESS';

export const SET_CAMPAIGN_PICKER_SORTING = 'SET_CAMPAIGN_PICKER_SORTING';

export const BULK_EDIT_DRAWER_UPDATE_CREATIVES_SUCCESS = 'BULK_EDIT_DRAWER_UPDATE_CREATIVES_SUCCESS';

export const REQUEST_CREATE_CREATIVE = 'REQUEST_CREATE_CREATIVE';
export const CREATE_CREATIVE_SUCCESS = 'CREATE_CREATIVE_SUCCESS';
export const CREATE_CREATIVE_ERROR = 'CREATE_CREATIVE_ERROR';

export const RESUBMIT_CREATIVE_SUCCESS = 'RESUBMIT_CREATIVE_SUCCESS';
export const BULK_RESUBMIT_CREATIVE_SUCCESS = 'BULK_RESUBMIT_CREATIVE_SUCCESS';
export const BULK_RESUBMIT_CREATIVE_ERROR = 'BULK_RESUBMIT_CREATIVE_ERROR';
export const REQUEST_RESUBMIT_CREATIVE = 'REQUEST_RESUBMIT_CREATIVE';

export const FORCE_CAMPAIGN_PICKER_RELOAD = 'FORCE_CAMPAIGN_PICKER_RELOAD';

export const SET_TOTAL_CAMPAIGNS_COUNT = 'SET_TOTAL_CAMPAIGNS_COUNT';

export const RESET_FLASH_REPORT_GRID_ROWS = 'RESET_FLASH_REPORT_GRID_ROWS';
