import React from 'react';
import { TooltipSection } from '../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

export const TopicsLanguageFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.setup.languages.tooltip.title"
                defaultMessage="Select the language that you wish to choose your Topics in."
            />
        </TooltipSection>
    </>
);
