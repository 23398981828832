import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { Button, UploadOutlinedIcon } from 'tuui';
import { DragAndDropFile, DragAndDropFileContext, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreativesApi } from 'services/api';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { useFileFieldValue } from '../../../common-campaign-form';
import { FileSection } from '../FileSection/FileSection';
import { MediaFileUploadIcon } from './MediaFileUploadIcon';
import styles from './videoMediaFileUploadSection.module.scss';

const classNameBuilder = classNames.bind(styles);

const VIDEO_FILES = { 'video/*': ['.mov', '.mp4'] };
const ACCEPTED_FILE_TYPES = ['video/quicktime', 'video/mp4'];
const MAX_FILE_SIZE = 50_000_000;

const DivWithIndication = withIndication(({ children, indicationType, className, ...rest }) => (
    <div
        className={classNameBuilder(
            {
                'error-indication': indicationType === INDICATION_TYPES.ERROR,
            },
            className
        )}
        {...rest}
    >
        {children}
    </div>
));

const MULTIPLE_FILES_ERROR_INDICATION = {
    message: (
        <FormattedMessage
            id="video.creative.creator.media.upload.file.number.error"
            defaultMessage="Multiple files selected, please select a single mp4 or mov file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const changeFileButtonProps = {
    id: 'video.creative.creator.media.form.field.drag.and.drop.button.change',
    defaultMessage: 'Change File',
};

const uploadFileButtonProps = {
    id: 'video.creative.creator.media.form.field.drag.and.drop.button.upload',
    defaultMessage: 'Upload File',
};

const validations = [
    {
        validationFn: value => !!value?.file,
        messageId: 'video.creative.creator.empty.video.error',
        defaultMessage: 'Please select video file.',
    },
    {
        validationFn: value => !value?.file || value.file.trusted || ACCEPTED_FILE_TYPES.includes(value.file.type),
        messageId: 'video.creative.creator.media.upload.file.type.error',
        defaultMessage: 'Wrong file format was selected, please add a single mp4 or mov file.',
    },
    {
        validationFn: value => !value?.file || value.file.trusted || value.file.size < MAX_FILE_SIZE,
        messageId: 'video.creative.creator.media.upload.file.size.error',
        defaultMessage: 'File size cannot exceed 50 MB.',
    },
    {
        validationFn: (value, _, { isVerticalVideoAllowed }) => {
            if (!value?.file || isVerticalVideoAllowed) {
                return Promise.resolve(true);
            }

            const video = document.createElement('video');
            video.preload = 'metadata';

            try {
                video.src = URL.createObjectURL(value.file);

                return new Promise(resolve => {
                    video.onloadedmetadata = () => {
                        resolve(video.videoWidth >= video.videoHeight);
                    };
                });
            } catch (e) {
                // In edit mode we dont have the file so URL.createObjectURL will fail
                return Promise.resolve(true);
            }
        },
        messageId: 'video.creative.creator.media.upload.file.orientation.error',
        defaultMessage: 'Wrong video orientation, please upload horizontal video.',
    },
];

export const VideoFileUploadDragAndDrop = () => {
    const { uploadManagedVideo } = useCreativesApi();
    const { onChange: changeLogo } = useFormFieldValue({ field: 'logoItem' });
    const { value: logoItem } = useFormFieldValue({
        field: 'logoItem',
    });
    const { value: videoUploadItems, onChange: setVideoUploadedItems } = useFormFieldValue({
        field: 'videoUploadItems',
    });
    const isLogoSelected = !!logoItem?.file;
    const [logoTrigger, setLogoTrigger] = useState();
    const hashIdsRef = useRef();
    const isVerticalVideoAllowed = useConfigMatch({
        [FEATURE_FLAGS.VIDEO_CREATIVE_UPLOADER_IS_VERTICAL_VIDEO_ALLOWED]: 'true',
    });
    const { onDrop, value, cleanValue, cleanCache, isLoading, scrollRef, failedValidationData } = useFileFieldValue({
        field: 'videoItem',
        validations,
        fileUploader: ({ file }) => uploadManagedVideo(file, hashIdsRef.current),
        dependencies: [logoTrigger],
        validationDependencies: { isVerticalVideoAllowed },
    });
    hashIdsRef.current = useMemo(() => videoUploadItems?.map(a => a?.hashId), [videoUploadItems]);
    const fileName = value?.file?.name;
    const dispatch = useDispatch();
    const onDropHandler = useCallback(
        (files, rejectedFiles) => {
            if (rejectedFiles?.length > 1 || files?.length > 1) {
                dispatch(addIndication(MULTIPLE_FILES_ERROR_INDICATION));
                return;
            }

            onDrop(files);
        },
        [onDrop, dispatch]
    );
    const onRemoveVideo = () => {
        cleanValue();
        cleanCache();
        setVideoUploadedItems(null);
        changeLogo(null);
    };

    useEffect(() => {
        if (isEmpty(value?.uploadedData)) {
            return;
        }
        setVideoUploadedItems(value.uploadedData);
    }, [value?.uploadedData, setVideoUploadedItems]);

    useEffect(() => {
        if (isLogoSelected) {
            return;
        }

        cleanCache();
        setLogoTrigger(prev => !prev);
    }, [isLogoSelected, cleanCache, setLogoTrigger]);

    return (
        <DragAndDropFile fileTypes={VIDEO_FILES} multiple={false} onDrop={onDropHandler}>
            <DragAndDropFileContext.Consumer>
                {({ openFileDialog }) => (
                    <>
                        <DivWithIndication className={styles['dropZone']} {...failedValidationData} ref={scrollRef}>
                            <FileSection
                                icon={MediaFileUploadIcon}
                                fileName={fileName}
                                isLoading={isLoading}
                                onRemoveImage={onRemoveVideo}
                                message={{
                                    id: 'video.creative.creator.media.form.field.drag.and.drop.click.zone',
                                    defaultMessage: 'Drag & Drop video file. MOV, MP4.',
                                }}
                            />
                        </DivWithIndication>
                        <Button
                            onClick={openFileDialog}
                            size={Button.size.md}
                            variant={Button.variant.ghost}
                            data-automation="videoFileUploadButton"
                        >
                            <>
                                <UploadOutlinedIcon />
                                <FormattedMessage {...(fileName ? changeFileButtonProps : uploadFileButtonProps)} />
                            </>
                        </Button>
                    </>
                )}
            </DragAndDropFileContext.Consumer>
        </DragAndDropFile>
    );
};
