import { get, sumBy } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useCombinedAudienceIngredientsTargetingValues = () => {
    const { value: combinedAudienceIngredientsTargetingValue = {}, onChange } = useFormFieldValue({
        field: 'audienceSetup',
    });

    const isValidTargeting = get(combinedAudienceIngredientsTargetingValue, 'validTargeting', true);

    const includeLength = sumBy(combinedAudienceIngredientsTargetingValue, 'audiences.length');

    return { isValidTargeting, includeLength, onChange };
};
