import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { SetupIcon } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components';
import { SectionHeader } from 'modules/campaigns/components';
import { AdFormatsSection } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/AdFormatsSection';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import { ContentMethodField } from 'modules/campaigns/modules/creative-creator/components/ContentMethodField';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { contentMethodSetupSectionMap } from '../../contentMethodSetupSectionMap';
import { CustomDataSection } from '../../creativeCustomData/CustomDataSection';
import { useAppInstallFormat } from '../../hooks/useAppInstallFormat';
import { useFormatTypeFormFieldValueCreateMode } from '../../hooks/useFormatTypeFormFieldValueCreateMode';
import { AdSetupSection } from '../AdSetupSection/AdSetupSection';
import styles from '../../creativeCreator.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CreativeSetUp = ({ onChange, selected, options, isAiVariationsReplacementEnabled }) => {
    const renderAppInstallFormat = useAppInstallFormat();
    const isCarouselEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_CAROUSEL_ENABLED]: 'true' });
    const { value: formatType } = useFormatTypeFormFieldValueCreateMode();
    const isAppInstallFormatSelected = formatType === CREATIVE_FORMAT_TYPE.APP_INSTALL;
    const isCarouselSelected = formatType === CREATIVE_FORMAT_TYPE.CAROUSEL;
    const SetupSectionComponent = contentMethodSetupSectionMap[selected] || (() => null);
    const setUp = (
        <CommonCollapsibleCard
            id="CREATIVE_SETUP"
            header={<SectionHeader headerIcon={<SetupIcon />} headerText="Setup" />}
            containerClassName={classNameBuilder('collapsible-card')}
        >
            {!isCarouselSelected && (
                <ContentMethodField
                    onChange={onChange}
                    selected={selected}
                    options={options}
                    isAiVariationsReplacementEnabled={isAiVariationsReplacementEnabled}
                />
            )}
            <CustomDataSection contentMethod={selected} />
            <SetupSectionComponent />
        </CommonCollapsibleCard>
    );

    return renderAppInstallFormat || isCarouselEnabled ? (
        <>
            <AdFormatsSection renderAppInstallFormat={renderAppInstallFormat} onContentMethodChange={onChange} />
            {isAppInstallFormatSelected && <AdSetupSection />}
            {!isAppInstallFormatSelected && setUp}
        </>
    ) : (
        setUp
    );
};

CreativeSetUp.propTypes = {
    selected: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any,
    isAiVariationsReplacementEnabled: PropTypes.bool,
};
