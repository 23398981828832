import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { CAMPAIGNS_ROUTE } from '../../campaigns/config';
import { fetchAccount } from '../flows/fetchAccount';
import { updateAccount } from '../flows/updateAccount';

export const useAccountEditorState = () => {
    const dispatch = useDispatch();

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const formDataFetcher = useCallback(
        async () => dispatch(fetchAccount({ accountName: accountId })),
        [accountId, dispatch]
    );

    const { submit } = useFormState({ formDataFetcher });

    const handleEditAccountSubmitWrapper = useCallback(() => {
        const updateAccountWrapper = account => dispatch(updateAccount({ account }));
        return submit(updateAccountWrapper, CAMPAIGNS_ROUTE);
    }, [dispatch, submit]);

    return { submit: handleEditAccountSubmitWrapper };
};
