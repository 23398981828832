const normalizeHeaderRendererProps = (props = {}) => {
    // extract the colDef from the props
    const { colDef = {} } = props;

    // extract the headerComponentParams from the colDef
    const { headerComponentParams = {}, ...restOfColDefs } = colDef;

    // flatten out the colDef and the headerComponentParams in the new props
    return {
        ...props,
        ...restOfColDefs,
        ...headerComponentParams,
    };
};

export default normalizeHeaderRendererProps;
