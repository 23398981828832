import TargetingTypes from '../../modules/common-campaign-form/config/TargetingTypes';
import { getAudiencesByTargetingType } from './audienceTransformerUtils';

const transformCustomContextualSegmentTargetingFromGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { collection: targetedCustomContextualSegments = [], validTargeting = true } =
        campaignTargeting?.customContextualTargeting || {};

    const includeSegments = getAudiencesByTargetingType(targetedCustomContextualSegments, TargetingTypes.INCLUDE);
    const excludeSegments = getAudiencesByTargetingType(targetedCustomContextualSegments, TargetingTypes.EXCLUDE);

    return {
        ...campaign,
        campaignTargeting: {
            ...campaignTargeting,
            customContextualTargeting: { include: includeSegments, exclude: excludeSegments, validTargeting },
        },
    };
};

const transformCustomContextualSegmentTargetingToGW = campaign => {
    const { campaignTargeting = {} } = campaign;
    const { customContextualTargeting = {} } = campaignTargeting;
    const { include, exclude } = customContextualTargeting;
    if (!include && !exclude) {
        return campaign;
    }

    const collection = [];

    if (Array.isArray(include) && include.length > 0) {
        collection.push({ type: TargetingTypes.INCLUDE, values: include });
    }

    if (Array.isArray(exclude) && exclude.length > 0) {
        collection.push({ type: TargetingTypes.EXCLUDE, values: exclude });
    }

    return {
        ...campaign,
        campaignTargeting: { ...campaignTargeting, customContextualTargeting: { collection } },
    };
};

export { transformCustomContextualSegmentTargetingFromGW, transformCustomContextualSegmentTargetingToGW };
