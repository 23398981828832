import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import useUnsavedChangesController from '../../campaigns/hooks/useUnsavedChangesController';
import { replacePathParams } from '../../campaigns/services/utils';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { CONVERSION_CREATOR_ROUTE_PATH, CONVERSION_ROUTE } from '../config';
import { ConversionCreatorPage } from './ConversionCreatorPage';

const path = CONVERSION_CREATOR_ROUTE_PATH;

export const ConversionCreator = ({ onCancel }) => {
    const createConversionPath = replacePathParams(path);

    useUnsavedChangesController(CONVERSION_CREATOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                strict
                path={createConversionPath}
                render={props => <ConversionCreatorPage {...props} onCancel={onCancel} />}
            />
            <Redirect to={CONVERSION_ROUTE} />
        </Switch>
    );
};

ConversionCreator.propTypes = {
    onCancel: PropTypes.func,
};
