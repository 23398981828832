import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useReportPresetsWithState } from '../../../hooks/useReportPresetsWithState';
import { isCustomReportPreset } from '../../../utils';
import { GLOBAL_PRESET_ACCOUNT_ID } from '../constants';

export const useReportPresets = reportConfig => {
    const { id: reportId, presets: configPresets = {} } = reportConfig || {};
    const [storedReportPresets = {}] = useReportPresetsWithState(reportId);

    const mergedReportPresets = useMemo(() => {
        const staticPresets = Object.keys(configPresets).map(presetName => ({ presetName }));
        const customPresets = Object.entries(storedReportPresets)
            .filter(([presetName]) => isCustomReportPreset(presetName))
            .map(([presetName, { presetDisplayName, accountId }]) => ({
                presetName,
                presetDisplayName,
                isGlobal: accountId === GLOBAL_PRESET_ACCOUNT_ID,
                isCustom: true,
            }));

        const mergedPresetsList = staticPresets.concat(customPresets);
        const mergedPresetsMap = keyBy(mergedPresetsList, 'presetName');

        return mergedPresetsMap;
    }, [configPresets, storedReportPresets]);

    return mergedReportPresets;
};
