import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { find } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { nameToLabel } from '../../../../campaigns/services/utils/dropdownUtils';
import { useFormDataContext } from '../../../../taboola-common-frontend-modules/formData';

const FALLBACK_LANGUAGE_VALUE_OBJECT = { value: 'EN', label: 'English' };

export const useCustomContextualLanguagesApi = () => {
    const {
        formAccount: { language: accountLanguage },
    } = useFormDataContext();

    const { getCustomContextualLanguages } = useAudiencesApi();
    const { data: allLanguagesValueObjects, isLoading: isLanguagesLoading } = useQuery(
        ['getCustomContextualLanguages'],
        getCustomContextualLanguages,
        {
            refetchOnWindowFocus: false,
            select: data => data?.results.map(nameToLabel),
            staleTime: Infinity,
        }
    );

    const defaultLanguageValueObject = useMemo(() => {
        const tempLanguageValueObject = find(allLanguagesValueObjects, { value: accountLanguage });
        return tempLanguageValueObject === undefined ? FALLBACK_LANGUAGE_VALUE_OBJECT : tempLanguageValueObject;
    }, [allLanguagesValueObjects, accountLanguage]);

    return {
        allLanguagesValueObjects,
        defaultLanguageValueObject,
        isLanguagesLoading,
    };
};
