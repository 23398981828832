import React from 'react';
import { TrackChangesOutlinedIcon } from 'tuui';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { EmptyStateOverlay } from 'modules/campaigns/modules/campaigns-reports/components/EmptyStateOverlay/EmptyStateOverlay';
import { useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { TRACKING_STATUS } from 'modules/tracking/config';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { ActiveEventsEmptyStateMessages } from './ActiveEventsEmptyStateMessages/ActiveEventsEmptyStateMessages';
import { PixelEmptyStateMessages } from './PixelEmptyStateMessages/PixelEmptyStateMessage';
import { UnipFunnelEmptyStateMessages } from './UnipFunnelsEmptyStateMessages/UnipFunnelEmptyStateMessages';
import styles from './trackingEmptyState.module.scss';

const selectedReportMessage = selectedReport => {
    switch (selectedReport) {
        case REPORT_TYPE.CONVERSIONS:
            return <PixelEmptyStateMessages />;
        case REPORT_TYPE.ACTIVE_EVENTS:
            return <ActiveEventsEmptyStateMessages />;
        case REPORT_TYPE.FUNNELS:
            return <UnipFunnelEmptyStateMessages />;
        default:
            return <PixelEmptyStateMessages />;
    }
};

export const TrackingEmptyState = () => {
    const {
        trackingStateData: { pixelTrackingData },
    } = useTrackingState();
    const { status: pixelStatus } = pixelTrackingData || {};
    const [selectedReport] = useSelectedReportId();
    if (pixelStatus !== TRACKING_STATUS.NOT_INSTALLED && selectedReport === REPORT_TYPE.CONVERSIONS) {
        return <EmptyStateOverlay />;
    }

    return (
        <div className={styles['container']}>
            <TrackChangesOutlinedIcon className={styles['pixel-icon']} />
            <div className={styles['message']}>{selectedReportMessage(selectedReport)}</div>
        </div>
    );
};
