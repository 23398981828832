import {
    MIN_THUMBNAIL_WIDTH,
    MIN_THUMBNAIL_HEIGHT,
    MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID,
    THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
} from '../../../campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import { isFileTypeImage } from '../../../campaigns/modules/creative-creator/services/utils';
import ValidationError from '../../../errors/ValidationError';

export const validateFileDimensions = file => {
    if (!isFileTypeImage(file.type)) {
        return;
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const image = new Image();

            image.onload = function () {
                const dimensions = {
                    width: image.width,
                    height: image.height,
                };

                if (dimensions.width >= MIN_THUMBNAIL_WIDTH && dimensions.height >= MIN_THUMBNAIL_HEIGHT) {
                    resolve(file);
                    return;
                }

                reject(
                    new ValidationError(
                        'Minimum thumbnail size validation failed',
                        MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID
                    )
                );
            };

            image.src = e.target.result;
        };

        reader.onerror = () =>
            reject(new ValidationError('Image failed to load', THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID));

        reader.readAsDataURL(file);
    });
};
