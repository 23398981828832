'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.classNamesFromStyles = classNamesFromStyles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _lodash = require('lodash.mapvalues');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.omit');

var _lodash4 = _interopRequireDefault(_lodash3);

var _hooks = require('../../hooks');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function withMergedStyles(BaseComponent, baseStyles, displayName) {
    var MergedStylesComponent = (0, _react.forwardRef)(function (_ref, ref) {
        var stylesheet = _ref.stylesheet,
            props = _objectWithoutProperties(_ref, ['stylesheet']);

        var classes = (0, _hooks.useMergedClasses)(BaseComponent.classNames, baseStyles, stylesheet);
        return _react2.default.createElement(BaseComponent, Object.assign({ ref: ref, classes: classes }, props));
    });

    MergedStylesComponent.propTypes = BaseComponent.propTypes;
    MergedStylesComponent.classNames = BaseComponent.classNames;
    MergedStylesComponent.defaultProps = (0, _lodash4.default)(BaseComponent.defaultProps || {}, 'classes');
    MergedStylesComponent.displayName = displayName || (BaseComponent.name || BaseComponent.displayName) + '(Styled)';

    return MergedStylesComponent;
}

function classNamesFromStyles(styles) {
    return (0, _lodash2.default)(styles, function (_, key) {
        return key;
    });
}

exports.default = withMergedStyles;