import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Badge } from 'tuui';
import {
    useDeleteCampaign,
    useOpenCampaignEditor,
    useOpenPerformanceRecommendationsReviewer,
    useRealtime,
} from 'modules/campaigns/hooks';
import { POLICY_STATE } from 'modules/campaigns/modules/common-campaign-form/components/Profile/utils/constants';
import { RECOMMENDATIONS_STATE } from 'modules/recommendations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from 'selectors';
import { isProgrammaticCampaign, isVideoCampaign } from 'services/campaignDimension';
import {
    activeRecommendationsCount,
    appliedRecommendationsCount,
    isCampaignDeleted,
    isCampaignDuplicationDisabled,
    isCampaignEditFormDisabled,
    isCampaignRecommendationsDisabled,
} from '../../config/predicates';
import { COLOR_OPTIONS as BUTTON_COLOR_OPTIONS } from '../ActionButton/ActionButton';
import ActionsCellRenderer from './ActionsCellRenderer';
import styles from './CampaignActionsCellRenderer.module.scss';

const CampaignEditTooltipContent = ({ isDisabled }) =>
    isDisabled ? (
        <FormattedMessage
            id="app.campaigns.campaignsReports.actions.edit.campaign"
            defaultMessage="Terminated campaigns are not editable"
        />
    ) : (
        <FormattedMessage id="app.campaigns.editCampaign" defaultMessage="Edit Campaign" />
    );

const DisableCampaignTooltipContent = ({ isCampaignDisabled }) =>
    isCampaignDisabled ? (
        <FormattedMessage
            id="app.campaigns.campaignsReports.actions.enable.campaign"
            defaultMessage="Enable Campaign"
        />
    ) : (
        <FormattedMessage
            id="app.campaigns.campaignsReports.actions.disable.campaign"
            defaultMessage="Disable Campaign"
        />
    );

const CampaignDeleteTooltipContent = ({ isDisabled }) =>
    isDisabled ? (
        <FormattedMessage
            id="app.campaigns.edit.terminated.campaign"
            defaultMessage="Deleted campaigns cannot be edited or enabled again."
        />
    ) : (
        <FormattedMessage id="app.campaigns.delete.campaign" defaultMessage="Delete Campaign" />
    );

const classNameBuilder = classnames.bind(styles);

const CampaignDuplicateTooltipContent = ({ isRealtimeActive }) =>
    isRealtimeActive ? (
        <FormattedMessage
            id="app.campaigns.campaignsReports.actions.duplicate.campaign.realtime"
            defaultMessage="Duplicating a campaign is not supported yet in the “real-time” mode."
        />
    ) : (
        <FormattedMessage
            id="app.campaigns.campaignsReports.actions.duplicate.campaign"
            defaultMessage="Duplicate Campaign"
        />
    );

const CampaignActionsCellRenderer = ({ data, onDuplicate, onTogglePolicyState, context, ...rest }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { policyState, demandType, dimension, id: campaignId, accountId } = data;
    const [isCampaignDisabled, setIsCampaignDisabled] = useState(policyState === POLICY_STATE.DISABLED);
    const [blockTogglePolicyStateClick, setBlockTogglePolicyStateClick] = useState(false);
    const openCampaignEditor = useOpenCampaignEditor();
    const openPerformanceRecommendationsReviewer = useOpenPerformanceRecommendationsReviewer();
    const deleteCampaign = useDeleteCampaign();
    const { isActive: isActiveAccount, value: accountNumericId } = useSelector(selectedAccountSelector);
    const isRecommendationsDisabled =
        isCampaignRecommendationsDisabled(data, context?.userPermissions) || !isActiveAccount;
    const hasOnlyAppliedRecs = appliedRecommendationsCount(data) && !activeRecommendationsCount(data);
    const recommendationsState = useMemo(() => {
        return isRecommendationsDisabled
            ? RECOMMENDATIONS_STATE.DISABLED
            : hasOnlyAppliedRecs
            ? RECOMMENDATIONS_STATE.APPLIED_ONLY
            : RECOMMENDATIONS_STATE.ACTIVE;
    }, [hasOnlyAppliedRecs, isRecommendationsDisabled]);

    const hasActiveRecommendations = activeRecommendationsCount(data) > 0;

    const recommendationsDataMetricsAdditionalProps = useMemo(() => {
        return {
            'data-metrics-visibility': isRecommendationsDisabled ? null : GTM_EVENTS.VISIBLE,
            'data-metrics-account-id': accountId || accountNumericId,
            'data-metrics-taboola-campaign-id': campaignId,
            'data-metrics-state': recommendationsState,
        };
    }, [accountId, accountNumericId, campaignId, isRecommendationsDisabled, recommendationsState]);

    const hasEditPermission = usePermissions('CAMPAIGN_EDIT');
    const hasVideoEditPermission = usePermissions('VIDEO_CAMPAIGN_EDIT_PERMISSION');

    const hasDeletePermission = useMemo(() => {
        return isVideoCampaign(dimension) ? hasVideoEditPermission : hasEditPermission;
    }, [dimension, hasEditPermission, hasVideoEditPermission]);
    const { isRealtimeActive } = useRealtime();

    const buttonConfigs = [
        {
            onClick: () => openCampaignEditor(data),
            isDisabled: isCampaignEditFormDisabled(data, context?.userPermissions),
            dataMetricComponent: 'EditActionButton',
            iconLabel: 'edit-o',
            tooltipContent: (
                <CampaignEditTooltipContent isDisabled={isCampaignEditFormDisabled(data, context?.userPermissions)} />
            ),
            className: styles['edit-button'],
        },
        {
            onClick: () => dispatch(onDuplicate(data, history)),
            isDisabled:
                !hasEditPermission ||
                isCampaignDuplicationDisabled(data, context?.userPermissions) ||
                !isActiveAccount ||
                isRealtimeActive,
            dataMetricComponent: 'DuplicateActionButton',
            iconLabel: 'duplicate',
            tooltipContent: <CampaignDuplicateTooltipContent isRealtimeActive={isRealtimeActive} />,
        },
        {
            onClick: () => openPerformanceRecommendationsReviewer(data),
            isDisabled: isRecommendationsDisabled,
            dataMetricComponent: 'RecommendationsActionButton',
            dataMetricsAdditionalProps: recommendationsDataMetricsAdditionalProps,
            iconLabel: 'lightning-outline',
            permissionsGuardProps: {
                permissions: 'CAMPAIGN_PERFORMANCE_RECOMMENDATIONS',
            },
            tooltipContent: (
                <FormattedMessage
                    id="app.campaigns.campaignsReports.actions.recommendations"
                    defaultMessage="Recommendations"
                />
            ),
            badgeData: {
                badgeContent: activeRecommendationsCount(data),
                type: Badge.TYPE.INFO,
                hideBadge: !hasActiveRecommendations,
            },
            className: classNameBuilder(styles['recommendations-badge']),
        },
        {
            onClick: () => {
                setIsCampaignDisabled(prevState => !prevState);
                setBlockTogglePolicyStateClick(true);
                const onSuccess = () => setBlockTogglePolicyStateClick(false);
                const onError = () => {
                    setBlockTogglePolicyStateClick(false);
                    setIsCampaignDisabled(prevState => !prevState);
                };
                const newPolicyState = isCampaignDisabled ? POLICY_STATE.ENABLED : POLICY_STATE.DISABLED;
                dispatch(onTogglePolicyState({ policyState: newPolicyState }, data, { onError, onSuccess }));
            },
            isDisabled:
                !hasEditPermission ||
                blockTogglePolicyStateClick ||
                isCampaignDeleted(data) ||
                isVideoCampaign(dimension) ||
                !isActiveAccount ||
                isRealtimeActive,
            dataMetricComponent: 'disableCampaignActionButton',
            iconLabel: 'block',
            buttonColor: isCampaignDisabled ? BUTTON_COLOR_OPTIONS.RED : BUTTON_COLOR_OPTIONS.GRAY,
            permissionsGuardProps: {
                permissions: 'CAMPAIGN_DISABLED_EDIT',
            },
            tooltipContent: <DisableCampaignTooltipContent isCampaignDisabled={isCampaignDisabled} />,
        },
        {
            onClick: () => deleteCampaign(data),
            isDisabled:
                !hasDeletePermission ||
                isCampaignDeleted(data) ||
                isProgrammaticCampaign(demandType) ||
                !isActiveAccount ||
                isRealtimeActive,
            dataMetricComponent: 'DeleteActionButton',
            iconLabel: 'delete',
            tooltipContent: <CampaignDeleteTooltipContent isDisabled={isCampaignDeleted(data)} />,
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

CampaignActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    onEdit: PropTypes.func,
    onDuplicate: PropTypes.func,
    onTogglePolicyState: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default CampaignActionsCellRenderer;
