import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import FormButtons from 'modules/campaigns/components/Form/FormButtons/FormButtons';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './trackingSetupButtons.module.scss';

export const TrackingSetupButtons = ({ onCancel, onSubmit, onSubmitAndCreateConversion, disableSubmit }) => {
    return (
        <div className={styles['buttons-wrapper']}>
            <FormButtons
                className={styles['form-buttons']}
                onSubmit={onSubmit}
                disableSubmit={disableSubmit}
                submitLabel={<FormattedMessage id="app.actionButtons.save" defaultMessage="Save" />}
                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                onCancel={onCancel}
                submitButtonType="submit"
                outlineSubmitButton
                showFooterValidations
            />
            <Button
                className={styles['create-conversion-button']}
                onClick={onSubmitAndCreateConversion}
                size={Button.variant.md}
                variant={Button.variant.primary}
                disabled={disableSubmit}
                data-mnetrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="SubmitAndCreateConversionButton"
                type="submit"
            >
                <FormattedMessage
                    id="tracking.setup.gtm.configuration.save.and.create.button"
                    defaultMessage="Save & Create Conversion"
                />
            </Button>
        </div>
    );
};

TrackingSetupButtons.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    onSubmitAndCreateConversion: PropTypes.func,
    disableSubmit: PropTypes.bool,
};
