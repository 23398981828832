import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { navigate } from 'actions';
import { INDICATION_TYPES, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import {
    CONVERSION_CREATOR_ROUTE_PATH,
    CONVERSION_EDITOR_ROUTE_PATH,
    CONVERSION_FUNNEL_CREATOR_ROUTE_PATH,
    FUNNEL_EDITOR_ROUTE_PATH,
    GTM_SETUP_ROUTE_PATH,
    MANUALLY_SETUP_ROUTE_PATH,
    S2S_SETUP_ROUTE_PATH,
    SHOPIFY_SETUP_ROUTE_PATH,
    TRACKING_ROUTE,
    TRACKING_SETTINGS_ROUTE,
} from 'modules/tracking/config';
import { GtmSetupPage } from 'modules/tracking/modules/gtmSetupPage/GtmSetupPage';
import { ManuallySetupPage } from 'modules/tracking/modules/manually-setup-page/ManuallySetupPage';
import { CustomizedSwitchWrapper } from '../../../components/CustomizedSwitchWrapper/CustomizedSwitchWrapper';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ModuleRoute } from '../../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { S2SSetupPage } from '../modules/s2sPage/S2SSetupPage';
import { ShopifySetupPage } from '../modules/shopifySetupPage/ShopifySetupPage';
import { ConversionCreator } from './ConversionCreator';
import { ConversionEditorPage } from './ConversionEditorPage';
import { TrackingPage } from './TrackingPage';
import { TrackingSettingsEditorPage } from './TrackingSettingsEditorPage';
import { UnipFunnelCreator } from './UnipFunnelCreator';
import { UnipFunnelEditorPage } from './UnipFunnelEditorPage';

const path = TRACKING_ROUTE;

export const Tracking = ({ history }) => {
    const dispatch = useDispatch();
    const handleNavigation = useCallback(() => {
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        dispatch(
            navigate(history, path, {
                skipFetchReport: true,
            })
        );
    }, [dispatch, history]);

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    return (
        <CustomizedSwitchWrapper>
            <LayerRoute
                strict
                exact
                path={CONVERSION_CREATOR_ROUTE_PATH}
                render={props => <ConversionCreator {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            <LayerRoute
                strict
                exact
                path={CONVERSION_FUNNEL_CREATOR_ROUTE_PATH}
                render={props => <UnipFunnelCreator {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            <LayerRoute
                strict
                exact
                path={MANUALLY_SETUP_ROUTE_PATH}
                render={props => <ManuallySetupPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={GTM_SETUP_ROUTE_PATH}
                render={props => <GtmSetupPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={S2S_SETUP_ROUTE_PATH}
                render={props => <S2SSetupPage {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                strict
                exact
                path={SHOPIFY_SETUP_ROUTE_PATH}
                render={props => <ShopifySetupPage {...props} onCancel={handleNavigation} />}
            />
            {isMultiLayerDrawerEnabled && (
                <>
                    <LayerRoute
                        exact
                        path={TRACKING_SETTINGS_ROUTE}
                        render={props => <TrackingSettingsEditorPage {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={CONVERSION_EDITOR_ROUTE_PATH}
                        render={props => <ConversionEditorPage {...props} />}
                    />
                    <LayerRoute
                        exact
                        path={FUNNEL_EDITOR_ROUTE_PATH}
                        render={props => <UnipFunnelEditorPage {...props} />}
                    />
                    <Redirect to={TRACKING_ROUTE} />
                </>
            )}
            <ModuleRoute path={path} render={() => <TrackingPage />} />
        </CustomizedSwitchWrapper>
    );
};

Tracking.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
};
