import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../TooltipSection';

const SubCountryTargetingTooltip = () => (
    <div>
        <TooltipSection
            header={<FormattedMessage id="campaign.editor.geo.targeting.regions.help.title" defaultMessage="Regions" />}
        >
            <FormattedMessage
                id="campaign.editor.geo.targeting.regions.help.region.limit"
                defaultMessage="You can target regions within a <i>single</i> country per campaign."
            />
            <FormattedMessage
                id="campaign.editor.geo.targeting.regions.help.multiple.country"
                defaultMessage="To target regions across several countries, create a <i>separate</i> campaign for each country."
            />
        </TooltipSection>
        <TooltipSection
            header={<FormattedMessage id="campaign.editor.geo.targeting.cities.help.title" defaultMessage="Cities" />}
        >
            <FormattedMessage
                id="campaign.editor.geo.targeting.cities.help.definition"
                defaultMessage="You can target cities within a <i>single</i> country per campaign."
            />
            <FormattedMessage
                id="campaign.editor.geo.targeting.cities.help.rules"
                defaultMessage="To target cities across multiple countries, create a <i>separate</i> campaign for each country."
            />
        </TooltipSection>
        <TooltipSection
            header={<FormattedMessage id="campaign.editor.geo.targeting.DMAs.help.title" defaultMessage="DMAs" />}
        >
            <FormattedMessage
                id="campaign.editor.geo.targeting.DMAs.help.definition"
                defaultMessage="DMAs are major metropolitan areas in the USA."
            />
            <FormattedMessage
                id="campaign.editor.geo.targeting.DMAs.help.rules"
                defaultMessage="A campaign can target DMAs <i>or</i> Regions in the US, but <i>not</i> both."
            />
        </TooltipSection>
    </div>
);

export default SubCountryTargetingTooltip;
