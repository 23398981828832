import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Collapsible } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { Id, Status } from 'modules/campaigns/modules/common-campaign-form';
import { NameEdit } from 'modules/campaigns/modules/common-campaign-form/components/Name/NameEdit/NameEdit';
import { creativeNameValidations } from 'modules/campaigns/modules/creative-creator/components/NameSetup/validations';
import { FieldEditorHeader } from 'modules/campaigns/modules/creative-editor/components/EditorForm/Header';
import { useIsCarouselCreative } from 'modules/campaigns/modules/creative-editor/hooks/useIsCarouselCreative';
import { getCreativeStatusMessages } from 'modules/campaigns/services/utils';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './creativeEditorHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CreativeEditorHeader = ({
    headerClassName,
    creativeStatusClassName,
    collapsed,
    headerLabel,
    hideStatusSection,
    isCreativeCustomDataEnabled,
}) => {
    const { value: isActive, onChange, mode } = useFormFieldValue({ field: 'isActive' });
    const { value: creativeStatus } = useFormFieldValue({ field: 'status' });
    const { value: id = '' } = useFormFieldValue({ field: 'id' });
    const { formattedStatusMsg } = getCreativeStatusMessages(creativeStatus);
    const isHierarchyChild = useIsCarouselCreative();

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.creator.creation.creative.name.placeholder',
        defaultMessage: 'Enter Ad Name',
    });

    if (!isCreativeCustomDataEnabled) {
        return <FieldEditorHeader headerLabel={headerLabel} hideStatusSection={hideStatusSection} />;
    }

    return (
        <div className={classNameBuilder('section-container', headerClassName)}>
            <div className={styles['header-label']}>{headerLabel}</div>
            <div name="ad-name">
                <NameEdit field="creativeName" validations={creativeNameValidations} placeholder={placeholder} />
            </div>
            {mode === FORM_MODES.EDIT && (
                <Collapsible collapsed={collapsed}>
                    <div className={classNameBuilder('creative-status-container', creativeStatusClassName)}>
                        <Id id={`${id}`} className={styles['creative-id']} />
                        {!isHierarchyChild && (
                            <Status formattedStatusMsg={formattedStatusMsg} isActive={isActive} onChange={onChange} />
                        )}
                    </div>
                    <FieldEditorHeader hideStatusSection />
                </Collapsible>
            )}
        </div>
    );
};

CreativeEditorHeader.propTypes = {
    headerClassName: PropTypes.string,
    creativeStatusClassName: PropTypes.string,
    collapsed: PropTypes.bool,
    headerLabel: PropTypes.node,
    hideStatusSection: PropTypes.bool,
    isCreativeCustomDataEnabled: PropTypes.bool,
};

CreativeEditorHeader.defaultProps = {
    collapsed: false,
};
