import { omitBy, isNil, isEmpty } from 'lodash';
import LogRocket from 'logrocket';
import { EVENT_KEY } from 'modules/taboola-common-frontend-modules/gtmTracker/config/eventKey';
import { VIRTUAL_PAGE_VIEW, USER } from 'modules/taboola-common-frontend-modules/gtmTracker/config/gtmEvents';

export const IGNORED_EVENTS_SET = new Set([VIRTUAL_PAGE_VIEW, USER]);
export const IGNORED_KEYS_SET = new Set([
    'event',
    EVENT_KEY.pageName,
    EVENT_KEY.accountId,
    EVENT_KEY.pagePath,
    'gtm.uniqueEventId',
]);

export const trackEvent = (eventName, eventData) => {
    if (IGNORED_EVENTS_SET.has(eventName)) {
        return;
    }

    const reducedEventData = omitBy(
        eventData,
        (value, key) => isNil(value) || isEmpty(value) || IGNORED_KEYS_SET.has(key)
    );

    LogRocket.track(eventName, reducedEventData);
};

export const captureException = (error, options = {}) => {
    LogRocket.captureException(error, options);
};
