import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from 'taboola-ultimate-ui';
import useComponentStatus from 'hooks/useComponentStatus';
import { selectedAccountSelector } from 'selectors';
import { COMPONENT_STATUS } from 'services/constants';

export const DEFAULT_MEDIA_PAGE_SIZE = 30;

const useMediaFetchService = ({ accountId: formAccountId, fetch, loadParams }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { status: paginationStatus, setStatus: setPaginationStatus, isModuleReady } = useComponentStatus();
    const { accountId: selectedAccountId } = useSelector(selectedAccountSelector) || {};
    const accountId = formAccountId || selectedAccountId;
    const fetchMediaFlow = useCallback(
        params => dispatch(fetch({ ...params, queryClient })),
        [dispatch, fetch, queryClient]
    );

    const loadMediaPage = useCallback(
        async ({ page, pageSize, ...params }) => {
            if (!accountId) {
                return [];
            }

            setPaginationStatus(COMPONENT_STATUS.LOADING);
            const result = await fetchMediaFlow({
                accountId,
                page,
                pageSize,
                ...params,
            });
            setPaginationStatus(COMPONENT_STATUS.ACTIVE);

            return result;
        },
        [fetchMediaFlow, accountId, setPaginationStatus]
    );

    const {
        reload: innerReload,
        isItemLoaded,
        loadCurrentFrame,
        values: loadedMedia,
        reset,
        totalValues,
    } = usePagination({
        loadPage: loadMediaPage,
        pageSize: DEFAULT_MEDIA_PAGE_SIZE,
        loadParams,
    });

    const loadNextPage = useCallback(
        (...params) => {
            if (!isModuleReady) {
                return;
            }

            return loadCurrentFrame(...params);
        },
        [loadCurrentFrame, isModuleReady]
    );

    const reload = useCallback(async () => {
        setPaginationStatus(COMPONENT_STATUS.LOADING);
        await innerReload();
        setPaginationStatus(COMPONENT_STATUS.ACTIVE);
    }, [innerReload, setPaginationStatus]);

    const sanitizedLoadedMedia = useMemo(() => loadedMedia.filter(img => img !== undefined), [loadedMedia]);

    return {
        loadedMedia: sanitizedLoadedMedia,
        reload,
        reset,
        loadNextPage,
        isItemLoaded,
        paginationStatus,
        isModuleReady,
        loadMediaPage,
        totalValues,
    };
};

export default useMediaFetchService;
