import { useCallback, useMemo, useState } from 'react';
import { INPUT_GROUP_TYPES, InputTypes } from 'taboola-ultimate-ui';
import { useDropdownOptions, useDropdownValue } from '../../../../../hooks';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import PIXEL_BASED_URL_CONDITIONS_PREDICATES from '../../../config/pixelBasedUrlConditionPredicates';
import PixelBasedUrlConditionPredicateOption from '../PixelBasedUrlConditionPredicateOption';
import styles from '../pixelBasedAudienceUrlConditionsField.module.scss';

const pixelBasedPredicateOptions = [
    { value: PIXEL_BASED_URL_CONDITIONS_PREDICATES.CONTAINS, label: 'contains' },
    { value: PIXEL_BASED_URL_CONDITIONS_PREDICATES.EQUALS, label: 'equals' },
];

const usePixelBasedUrlInputGroupConfig = () => {
    const [predicate, setPredicate] = useState(PIXEL_BASED_URL_CONDITIONS_PREDICATES.CONTAINS);

    const handlePredicateChange = useCallback(e => {
        setPredicate(e.value);
    }, []);

    const [url, setUrl] = useState('');

    const handleUrlChange = useCallback(e => {
        setUrl(e.target.value);
    }, []);

    const msgIdPrefix = 'audience.editor.pixel.based.audience.conditions.predicate';
    const options = useDropdownOptions(pixelBasedPredicateOptions, msgIdPrefix);
    const selectedValue = useDropdownValue({ value: predicate }, msgIdPrefix);

    const predicateDropdownConfig = useMemo(
        () => ({
            type: INPUT_GROUP_TYPES.DROPDOWN,
            dataKey: 'predicate',
            props: {
                value: predicate,
                options,
                optionItemRenderer: PixelBasedUrlConditionPredicateOption,
                selectedValueObject: selectedValue,
                onChange: handlePredicateChange,
                width: '200px',
            },
        }),
        [predicate, options, selectedValue, handlePredicateChange]
    );

    const { formatMessage } = useIntl();

    const urlInputConfig = useMemo(
        () => ({
            type: INPUT_GROUP_TYPES.INPUT,
            dataKey: 'url',
            props: {
                placeholder: formatMessage({
                    id: 'audience.editor.pixel.based.audience.conditions.text.placeholder',
                    defaultMessage: 'URL Address',
                }),
                value: url,
                type: InputTypes.TEXT,
                onChange: handleUrlChange,
                inputClass: styles['input'],
                inputWrapperClass: styles['input-wrapper'],
            },
        }),
        [url, handleUrlChange, formatMessage]
    );

    const inputGroupConfig = useMemo(
        () => [predicateDropdownConfig, urlInputConfig],
        [predicateDropdownConfig, urlInputConfig]
    );

    return {
        inputGroupConfig,
        predicate,
        url,
        setUrl,
    };
};

export default usePixelBasedUrlInputGroupConfig;
