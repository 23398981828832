import { useElements } from '@stripe/react-stripe-js';
import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from '../../i18n';
import { useValidations } from '../../validations/hooks/useValidations';
import { useStripeCardContext } from '../StripeCardContext';

const stripeValidations = [
    {
        validationFn: (item, options, { empty }) => !empty,
        messageId: 'validations.error.paymentMethod.stripe.empty',
    },
    {
        validationFn: (item, options, { error }) => !error,
        messageId: 'validations.error.paymentMethod.stripe.error',
    },
];

export const useStripeCardElement = stripeElementId => {
    const elements = useElements();
    const { elementsState, updateElementState } = useStripeCardContext();
    const elementState = useMemo(() => elementsState[stripeElementId], [elementsState, stripeElementId]);
    const { formatMessage } = useIntl();

    const { failedValidationData, scrollRef } = useValidations({
        validations: stripeValidations,
        validationId: stripeElementId,
        isDirty: elementState.dirty,
        valueToValidate: stripeElementId,
        validationDependencies: elementState,
    });

    const onReady = useCallback(() => {
        const element = elements?.getElement(stripeElementId);
        return element?.update({ disabled: false });
    }, [elements, stripeElementId]);

    const onChange = useCallback(
        ({ empty, complete, error = null }) => {
            updateElementState(stripeElementId, { empty, complete, error, dirty: true });
        },
        [stripeElementId, updateElementState]
    );

    const error = useMemo(() => {
        const { indicationType } = failedValidationData;
        if (isEmpty(indicationType)) {
            return {};
        }

        const { empty, error } = elementState;
        const message = empty
            ? formatMessage({ id: `validations.error.paymentMethod.${stripeElementId}.empty` })
            : error?.message;
        return {
            ...failedValidationData,
            message,
        };
    }, [failedValidationData, elementState, formatMessage, stripeElementId]);

    return { ...error, onReady, onChange, scrollRef };
};
