import React, { useMemo } from 'react';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useCurrentValueGetter } from '../../../../../../hooks';
import useFormFieldValue from '../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { useValidationContext } from '../../../../../taboola-common-frontend-modules/validations/ValidationContext';
import {
    BYPASS_URL_RESPONSE_VALIDATION_FIELD,
    UrlValidatedInput,
} from '../../../creative-creator/components/UrlList/UrlValidatedInput';
import BaseCellEditor from './BaseCellEditor';
import styles from './urlCellEditor.module.scss';

export const UrlCellEditorBase = ({
    placeholder,
    guiAttached,
    editableTextClassName,
    onCancel,
    onSave,
    validations: baseValidations,
    ...restProps
}) => {
    const { colDef } = restProps;
    const validations = useMemo(
        () => baseValidations.map(item => ({ ...item, events: ['submitOnly'] })),
        [baseValidations]
    );
    const { validate } = useValidationContext();
    const { value } = useFormFieldValue({ field: colDef.field });
    const valueGetter = useCurrentValueGetter(value);
    const { value: bypassUrlResponseValidation } = useFormFieldValue({
        field: BYPASS_URL_RESPONSE_VALIDATION_FIELD,
    });
    const onSubmit = async () => {
        const isValid = await validate();
        if (isValid) {
            onSave({
                url: valueGetter(),
                [BYPASS_URL_RESPONSE_VALIDATION_FIELD]: bypassUrlResponseValidation || false,
            });
        }
    };

    return (
        <BaseCellEditor onCancel={onCancel} onSubmit={onSubmit} colDef={colDef}>
            <UrlValidatedInput
                id="cell-editor-input"
                data-testid="text-cell-editor-input"
                field={colDef.field}
                className={styles['input-container']}
                inputClassName={styles['input']}
                placeholder={placeholder}
                validations={validations}
                onSubmitAnyway={validate}
                autoFocus
            />
        </BaseCellEditor>
    );
};

export const UrlCellEditor = withGridCellFormDataProvider(UrlCellEditorBase);
