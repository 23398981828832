import { has, isEmpty, some } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { HOUR_OPTIONS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeBidBoost/consts/dayTimeDaysOptions';
import { GROUPS_OF_DAYS } from 'modules/campaigns/modules/common-campaign-form/config';
import TARGETING_TYPES from 'modules/campaigns/modules/common-campaign-form/config/TargetingTypes';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { DAYS_TO_GROUPS_MAP, GROUPS_TO_DAYS_MAP } from '../../DayTimeBidBoost/consts/dayTimeDaysOptions';

const isValidDayTime = (value, { rows, id, fromHour, untilHour, type }) => {
    if (rows.length < 2) {
        return true;
    }

    const sameDayIntervals = rows.filter(row => isSameType(row, type) && isSameDayButDifferentRow(row, id, value));
    if (isEmpty(sameDayIntervals)) {
        return true;
    }
    const hasCollisions = sameDayIntervals.some(el => isOverlapping(el, { fromHour, untilHour }));
    return !hasCollisions;
};

const isDaysCollides = (day1, day2) =>
    day1 === day2 ||
    day1 === GROUPS_OF_DAYS.ALL_DAYS ||
    day2 === GROUPS_OF_DAYS.ALL_DAYS ||
    DAYS_TO_GROUPS_MAP[day1]?.includes(day2) ||
    GROUPS_TO_DAYS_MAP[day1]?.includes(day2);

const isValidServingTypes = (day, { rows, id, type }) => {
    const sameDayIntervals = rows.filter(row => row.id !== id && isDaysCollides(day, row.day));
    if (isEmpty(sameDayIntervals)) {
        return true;
    }
    const hasDayWithBothIncludeAndExclude = sameDayIntervals.some(el => el.type !== type);
    return !hasDayWithBothIncludeAndExclude;
};

const isOverlapping = (timePeriod1, timePeriod2) =>
    timePeriod2.fromHour <= timePeriod1.untilHour && timePeriod2.untilHour >= timePeriod1.fromHour;

const isSameDayButDifferentRow = (row, id, day) => {
    if (row.id === id) {
        return false;
    }
    return isDaysCollides(day, row.day);
};

const isSameType = (row, type) => {
    return !has(row, 'type') || row.type === type;
};

const isNotOverlappingRows = value => {
    if (value?.length < 2) {
        return true;
    }

    const hasInvalidRow = some(value, row => !isValidDayTime(row?.day, { rows: value, ...row }));
    return !hasInvalidRow;
};

const isValidDayPartingRows = value => {
    const filterValues = value.filter(val => has(val, 'type'));
    if (filterValues?.length < 2) {
        return true;
    }

    const hasInvalidRows = some(filterValues, row => !isValidServingTypes(row?.day, { rows: filterValues, ...row }));
    return !hasInvalidRows;
};

const isIncludeHours = value =>
    value.some(
        val =>
            val.type !== TARGETING_TYPES.EXCLUDE ||
            val.fromHour !== HOUR_OPTIONS[0].value ||
            val.untilHour !== HOUR_OPTIONS[24].value
    );

export const dayTimeListValidations = [
    {
        validationFn: isNotOverlappingRows,
        messageId: 'validations.error.campaign.date.parting',
        defaultMessage: 'Some time-frames are conflicting',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: isValidDayPartingRows,
        messageId: 'validations.error.campaign.day.parting.exclude.and.include.in.same.day',
        defaultMessage: 'Some days contains both include and exclude',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: isIncludeHours,
        messageId: 'validations.error.campaign.day.parting.only.exclude.hours',
        defaultMessage: 'Excluding all days will result in the campaign not serving at all',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const dayTimeValidations = [
    {
        validationFn: (value, _, { rows, id, fromHour, untilHour, field, type }) => {
            if (!isValidDayTime(value, { rows, id, fromHour, untilHour, type })) {
                throw new CustomValidationError({
                    messageCode: `validations.error.campaign.date.parting.${field}`,
                    message: field,
                });
            }
            return true;
        },

        messageId: 'validations.error.campaign.date.parting',
        defaultMessage: 'Error message',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: (value, _, { rows, id, type, field }) => {
            if (!isValidServingTypes(value, { rows, id, type })) {
                throw new CustomValidationError({
                    messageCode: `validations.error.campaign.date.parting.${field}`,
                    message: field,
                });
            }
            return true;
        },

        messageId: 'validations.error.campaign.date.parting',
        defaultMessage: 'Error message',
        severity: INDICATION_TYPES.ERROR,
    },
];
