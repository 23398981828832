import React from 'react';
import { LaunchOutlinedIcon } from 'tuui';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import STATIC_INDICATION_TYPES from 'modules/taboola-common-frontend-modules/Indications/staticIndicationTypes';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AddTrackingButton } from '../AddTrackingButton/AddTrackingButton';
import styles from './marketingObjectiveMessages.module.scss';

const pixelHighlight = 'No Tracking.';
const pixelMessage =
    'Please verify that your pixel code has been added to your landing page correctly before you proceed. {addTracking}';
export const NO_ACTIVE_PIXEL_INDICATION = {
    staticIndicationType: STATIC_INDICATION_TYPES.NO_ACTIVE_PIXEL,
    message: (
        <span className={styles['message-container']}>
            <FormattedMessage
                id="app.campaign.indication.no.active.tracking.message"
                defaultMessage={pixelMessage}
                values={{
                    addTracking: (
                        <AddTrackingButton
                            id="app.campaign.indication.pixel.setup.link"
                            defaultMessage="<u>Taboola Pixel Setup</u>"
                        />
                    ),
                }}
            />
            <LaunchOutlinedIcon className={styles['iconLink']} />
        </span>
    ),
    type: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage id="app.campaign.indication.no.active.tracking.highlight" defaultMessage={pixelHighlight} />
    ),
};

const conversionsHighlight = 'No Active Conversions. ';
const conversionsMessage =
    'Your account has a live pixel, but no active conversions. Please define and enable your {addTracking}';
export const NO_ACTIVE_CONVERSIONS_INDICATION = {
    staticIndicationType: STATIC_INDICATION_TYPES.NO_ACTIVE_CONVERSIONS,
    message: (
        <span className={styles['message-container']}>
            <FormattedMessage
                id="app.campaign.indication.no.active.conversion.message"
                defaultMessage={conversionsMessage}
                values={{
                    addTracking: (
                        <AddTrackingButton
                            id="campaign.pixel.alive.but.no.active.conversion"
                            defaultMessage="conversion(s)"
                            openModal={false}
                        />
                    ),
                }}
            />
            <LaunchOutlinedIcon />
        </span>
    ),
    type: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.no.active.conversion.highlight"
            defaultMessage={conversionsHighlight}
        />
    ),
};
