import React from 'react';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const TimezoneDropdownTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="creative.editor.edit.ad.timezone.help"
                defaultMessage="We'll schedule your ad to run based on the selected time zone. By default your account time zone is selected."
            />
        </TooltipSection>
    </>
);
