import {
    REQUEST_GET_GRAPH_DATA,
    GET_GRAPH_DATA_SUCCESS,
    GET_GRAPH_DATA_ERROR,
    GET_GRAPH_DATA_ABORT,
    CLEAR_GRAPH_DATA,
} from './actionTypes';

export const requestGetGraphData = () => ({ type: REQUEST_GET_GRAPH_DATA });
export const getGraphDataError = error => ({ type: GET_GRAPH_DATA_ERROR, payload: error });
export const getGraphDataSuccess = ({ results, total, graphMetadata }) => ({
    type: GET_GRAPH_DATA_SUCCESS,
    payload: { rows: results, totalRow: [total], graphMetadata },
});
export const getGraphDataAbort = () => ({ type: GET_GRAPH_DATA_ABORT });
export const clearGraphData = () => ({ type: CLEAR_GRAPH_DATA });
