import { operationsApi } from '../../../../../services/api';
import { COMPONENT_STATUS } from '../../../../../services/constants';
import { initLoadingThumbnails } from '../utils/initLoadingThumbnails';
import { updateThumbnailById } from '../utils/updateThumbnailById';

const fetchPageMetadata = async ({ accountId, url, setThumbnails, contentId }) => {
    initLoadingThumbnails(setThumbnails, [{ id: contentId }]);

    try {
        const response = await operationsApi.getPageMetadata(accountId, url);
        const { thumbnailUrl } = response;
        setThumbnails(prev =>
            updateThumbnailById(prev, contentId, {
                value: thumbnailUrl,
                displayUrl: thumbnailUrl,
                status: COMPONENT_STATUS.ACTIVE,
            })
        );
        return response;
    } catch (error) {
        setThumbnails(prev => updateThumbnailById(prev, contentId, { error, status: COMPONENT_STATUS.ERROR }));
    }
};

export default fetchPageMetadata;
