import { invert } from 'lodash';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { ENTITY } from './entity';

export const DIMENSION_TO_PATH_MAP = {
    [CAMPAIGN_DIMENSION.SPONSORED]: 'sponsored',
    [CAMPAIGN_DIMENSION.VIDEO]: 'video',
};

export const DIMENSION_TO_CREATIVE_ENTITY_MAP = {
    [CAMPAIGN_DIMENSION.SPONSORED]: ENTITY.CREATIVE,
    [CAMPAIGN_DIMENSION.VIDEO]: ENTITY.CREATIVE_VIDEO,
};

const CAMPAIGN_DIMENSION_PATHS = Object.values(DIMENSION_TO_PATH_MAP);

export const DIMENSION_PARAM_NAME = 'dimension';

export const SPONSORED_CAMPAIGN_ROUTE = `/:${DIMENSION_PARAM_NAME}(${
    DIMENSION_TO_PATH_MAP[CAMPAIGN_DIMENSION.SPONSORED]
})`;
export const VIDEO_CAMPAIGN_ROUTE = `/:${DIMENSION_PARAM_NAME}(${DIMENSION_TO_PATH_MAP[CAMPAIGN_DIMENSION.VIDEO]})`;

export const PATH_TO_CAMPAIGN_DIMENSION_MAP = invert(DIMENSION_TO_PATH_MAP);
export const DIMENSION_PARAM_TEMPLATE = `/:${DIMENSION_PARAM_NAME}(${CAMPAIGN_DIMENSION_PATHS.join('|')})?`;
