import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { PRICING_MODEL } from '../../../../../config';
import { useFormBidValidatedValue } from '../../Bid/useFormBidValidatedValue';
import { BidFormField } from '../BidType/BidFormField';
import styles from './cpcInput.module.scss';

export const CPCInput = () => {
    const {
        value: bid = '',
        onChange: onBidChange,
        failedValidationData,
        scrollRef,
    } = useFormBidValidatedValue({
        pricingModel: PRICING_MODEL.CPC,
    });

    return (
        <BidFormField
            containerClass={styles['cpc-form-field']}
            label={<FormattedMessage id="campaign.editor.bid.type.title.CPC" defaultMessage="CPC" />}
            helpText={
                <FormattedMessage
                    id="campaign.editor.bid.type.description.CPC"
                    defaultMessage="Manually control your bid, baseline will be fixed and not optimized"
                />
            }
            bid={bid}
            onBidChange={onBidChange}
            failedValidationData={failedValidationData}
            ref={scrollRef}
        />
    );
};
