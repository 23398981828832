import React from 'react';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import DropdownWrapper from '../../../../components/DropdownWrapper/PaginatedDropdownWrapper';
import { FORM_MODES } from '../../../../config/formModes';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { usePaymentMethodRegion } from './usePaymentMethodRegion';
import commonStyles from '../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';
import styles from './paymentMethodRegion.module.scss';

const DropdownWithIndication = withIndication(DropdownWrapper);

export const PaymentMethodRegion = () => {
    const {
        regions,
        setRegions,
        loadRegionsPage,
        onRegionChange,
        showRegionField,
        selectedRegion,
        indicationData,
        scrollRef,
        mode,
    } = usePaymentMethodRegion();

    return (
        showRegionField && (
            <FormField
                inputId="region"
                ref={scrollRef}
                containerClass={commonStyles['input']}
                label={<FormattedMessage id="paymentMethod.creator.CARD.region" defaultMessage="State" />}
            >
                <DropdownWithIndication
                    id="region-selector"
                    onChange={onRegionChange}
                    searchable
                    value={selectedRegion}
                    enabledWhileSearching
                    options={regions}
                    dropdownWrapperClassName={styles['dropdown-container']}
                    disabled={!regions.length || mode !== FORM_MODES.CREATE}
                    loadPage={loadRegionsPage}
                    DropdownMenuComponent={BareDropdown}
                    placeholder={
                        <FormattedMessage
                            id="paymentMethod.creator.CARD.region.placeholder"
                            defaultMessage="Click to select"
                        />
                    }
                    onOptionsLoaded={setRegions}
                    {...indicationData}
                />
            </FormField>
        )
    );
};
