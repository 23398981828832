import { generatePath } from 'react-router';
import { ENTITY_PARAM_NAME, FORM_MODE_TO_PATH_MAP, MODE_PARAM_NAME, MODULE_PARAM_NAME } from 'config/routes';
import {
    ACCOUNT_ID_PARAM_NAME,
    CAMPAIGN_ID_PARAM_NAME,
    CAMPAIGNS_GROUP_ID_PARAM_NAME,
    DIMENSION_PARAM_NAME,
    DIMENSION_TO_PATH_MAP,
    CREATIVE_ID_PARAM_NAME,
    ENTITY_TO_PATH_MAP,
    MODULE_NAME,
} from 'modules/campaigns/config/routes';
import { HIERARCHY_REP_ITEM_ID_PARAM_NAME } from '../../config/routes/creativeCarousel';

export const generateFormPath = (
    pathTemplate,
    {
        module = MODULE_NAME,
        accountId,
        campaignId,
        campaignsGroupId,
        entity,
        mode,
        dimension,
        creativeId,
        hierarchyRepItemId,
    }
) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    const campaignDimensionPath = DIMENSION_TO_PATH_MAP[dimension];
    const path = generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
        [CAMPAIGN_ID_PARAM_NAME]: campaignId,
        [CAMPAIGNS_GROUP_ID_PARAM_NAME]: campaignsGroupId,
        [DIMENSION_PARAM_NAME]: campaignDimensionPath,
        [CREATIVE_ID_PARAM_NAME]: creativeId,
        [HIERARCHY_REP_ITEM_ID_PARAM_NAME]: hierarchyRepItemId,
    });

    return path;
};
