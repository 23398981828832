import { reduce } from 'lodash';
import { MODULE_NAME as AUDIENCE_INSIGHTS_MODULE } from 'modules/audience-insights/config/routes';
import { MODULE_NAME as AUDIENCES_MODULE } from 'modules/audiences/config/routes';
import { MODULE_NAME as BILLING_MODULE } from 'modules/billing-and-payments/config/routes';
import { MODULE_NAME as CAMPAIGNS_MODULE } from 'modules/campaigns/config/routes';
import { MODULE_NAME as PERFORMANCE_RULES_MODULE } from 'modules/performance-rules/config/routes';
import { MODULE_NAME as SCHEDULED_REPORTS } from 'modules/scheduled-reports/config/routes';
import { COMMON_FLAGS, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations/flags';
import { MODULE_NAME as TRACKING_MODULE } from 'modules/tracking/config/routes';
import { CAMPAIGN_DIMENSION, isSponsoredCampaign, isVideoCampaign } from 'services/campaignDimension';
import { getModuleName } from 'services/url/urlParams';
import {
    isAdminNetworkAccountType,
    isNetworkAccountType,
    isOpenExchangeAccount,
    isPmpAccount,
    isProgrammaticAccount,
    isSponsoredAccount,
    isVideoAccount,
} from '../../account-management/accountType';
import { getIsMarketplaceAudiencesBundlingEnabled } from '../services/utils/audiences';
import { isRealTimeReport } from './isRealTimeReport';

export const REPORT_PERMISSION = {
    PAYMENT_INVOICES_VIEW: 'PAYMENT_INVOICES_VIEW',
    BUDGET_MANAGEMENT_VIEW: 'BUDGET_MANAGEMENT_VIEW',
    ADS_CONSOLE_AUDIENCE_REPORT: 'ADS_CONSOLE_AUDIENCE_REPORT',
    ADS_CONSOLE_CONTEXTUAL_REPORT: 'ADS_CONSOLE_CONTEXTUAL_REPORT',
    ADS_CONSOLE_CONTEXTUAL_VIDEO_REPORT: 'ADS_CONSOLE_CONTEXTUAL_VIDEO_REPORT',
    ADS_CONSOLE_CATEGORY_REPORT: 'ADS_CONSOLE_CATEGORY_REPORT',
    ADS_CONSOLE_PIXEL_CATEGORY_REPORT: 'ADS_CONSOLE_PIXEL_CATEGORY_REPORT',
    REP_BREAKDOWN_VIEW: 'REP_BREAKDOWN_VIEW',
    ADS_CONSOLE_OS_VERSION_REPORT: 'ADS_CONSOLE_OS_VERSION_REPORT',
    ADS_CONSOLE_OS_FAMILY_REPORT: 'ADS_CONSOLE_OS_FAMILY_REPORT',
    ADS_CONSOLE_BROWSER_REPORT: 'ADS_CONSOLE_BROWSER_REPORT',
    ADS_CONSOLE_CITY_REPORT: 'ADS_CONSOLE_CITY_REPORT',
    ADS_CONSOLE_DMA_REPORT: 'ADS_CONSOLE_DMA_REPORT',
    ADS_CONSOLE_QUARTILE_REPORT: 'ADS_CONSOLE_QUARTILE_REPORT',
    ADS_CONSOLE_VIDEO_CAMPAIGNS: 'ADS_CONSOLE_VIDEO_CAMPAIGNS',
    CAMPAIGN_BY_ADVERTISER_DOMAIN_VIEW: 'CAMPAIGN_BY_ADVERTISER_DOMAIN_VIEW',
    CAMPAIGN_BY_ATTRIBUTION_GROUP_VIEW: 'CAMPAIGN_BY_ATTRIBUTION_GROUP_VIEW',
    CAMPAIGN_SUMMARY_BY_SITE: 'CAMPAIGN_SUMMARY_BY_SITE',
    SP_VIEW_SUBCOUNTRY_PERFORMANCE: 'SP_VIEW_SUBCOUNTRY_PERFORMANCE',
    CAMPAIGN_HISTORY_VIEW: 'CAMPAIGN_HISTORY_VIEW',
    ADS_CONSOLE_CUSTOM_CONTEXTUAL: 'ADS_CONSOLE_CUSTOM_CONTEXTUAL',
};

const isVideoAccountAndDimension = ({ selectedAccount: { partnerTypes }, dimension, isRealtime }) =>
    isVideoAccount(partnerTypes) && isVideoCampaign(dimension) && !isRealtime;
const isSponsoredAccountAndDimension = ({ selectedAccount: { partnerTypes }, dimension, isRealtime }) =>
    isSponsoredAccount(partnerTypes) && isSponsoredCampaign(dimension) && !isRealtime;
const supportProgrammaticCampaigns = ({ selectedAccount, isRealtime }) =>
    isProgrammaticAccount(selectedAccount) && !isRealtime;
export const isOnlySponsored = data => isSponsoredAccountAndDimension(data) && !supportProgrammaticCampaigns(data);
const realtimeReportVisibilityCondition = ({ dimension, isRealtime, selectedAccount }) =>
    isSponsoredCampaign(dimension) && isRealtime && !isAdminNetworkAccountType(selectedAccount?.type);

const isFeatureFlagEnabled = (accountConfig, featureFlagEnabled) => accountConfig?.[featureFlagEnabled] === 'true';
const isUserPermissionOrFeatureFlagEnabled = (
    permissions,
    selectedAccountConfig,
    requiredPermissions,
    featureFlagEnabled
) => permissions[requiredPermissions] || isFeatureFlagEnabled(selectedAccountConfig, featureFlagEnabled);

const VIDEO_CAMPAIGN_SUFFIX = '-video';
const REALTIME_CAMPAIGN_SUFFIX = '-realtime';

export const REPORT_TYPE = {
    ACCOUNT: 'account',
    ACCOUNT_VIDEO: `account${VIDEO_CAMPAIGN_SUFFIX}`,
    CAMPAIGN: 'campaign',
    CAMPAIGN_REALTIME: `campaign${REALTIME_CAMPAIGN_SUFFIX}`,
    CAMPAIGN_VIDEO: `campaign${VIDEO_CAMPAIGN_SUFFIX}`,
    HIERARCHY_CAMPAIGN: 'hierarchy-campaign',
    DAY: 'day',
    TODAY: 'today',
    DAY_VIDEO: `day${VIDEO_CAMPAIGN_SUFFIX}`,
    WEEK: 'week',
    WEEK_VIDEO: `week${VIDEO_CAMPAIGN_SUFFIX}`,
    MONTH: 'month',
    MONTH_VIDEO: `month${VIDEO_CAMPAIGN_SUFFIX}`,
    DAY_OF_WEEK: 'day-of-week',
    DAY_OF_WEEK_VIDEO: `day-of-week${VIDEO_CAMPAIGN_SUFFIX}`,
    HOUR_OF_DAY: 'hour-of-day',
    HOUR_OF_DAY_VIDEO: `hour-of-day${VIDEO_CAMPAIGN_SUFFIX}`,
    CREATIVE: 'creative',
    CREATIVE_REALTIME: `creative${REALTIME_CAMPAIGN_SUFFIX}`,
    CREATIVE_VIDEO: `creative${VIDEO_CAMPAIGN_SUFFIX}`,
    QUARTILE_VIDEO: `quartile${VIDEO_CAMPAIGN_SUFFIX}`,
    SITE: 'site',
    SITE_REALTIME: `site${REALTIME_CAMPAIGN_SUFFIX}`,
    SITE_VIDEO: `site${VIDEO_CAMPAIGN_SUFFIX}`,
    PLATFORM: 'platform',
    PLATFORM_REALTIME: `platform${REALTIME_CAMPAIGN_SUFFIX}`,
    PLATFORM_VIDEO: `platform${VIDEO_CAMPAIGN_SUFFIX}`,
    BROWSER: 'browser',
    BROWSER_VIDEO: `browser${VIDEO_CAMPAIGN_SUFFIX}`,
    OS_VERSION: 'os-version',
    OS_VERSION_VIDEO: `os-version${VIDEO_CAMPAIGN_SUFFIX}`,
    OS_FAMILY: 'os-family',
    OS_FAMILY_VIDEO: `os-family${VIDEO_CAMPAIGN_SUFFIX}`,
    COUNTRY: 'country',
    COUNTRY_REALTIME: `country${REALTIME_CAMPAIGN_SUFFIX}`,
    COUNTRY_VIDEO: `country${VIDEO_CAMPAIGN_SUFFIX}`,
    CITY_VIDEO: `city${VIDEO_CAMPAIGN_SUFFIX}`,
    REGION: 'region',
    DMA: 'dma',
    DMA_VIDEO: `dma${VIDEO_CAMPAIGN_SUFFIX}`,
    CATEGORY: 'category',
    SEGMENT_CATEGORY: 'segment-category',
    AUDIENCE: 'audience',
    AUDIENCE_BUNDLES: 'audience-bundles',
    CONTEXTUAL: 'contextual',
    CONTEXTUAL_VIDEO: `contextual${VIDEO_CAMPAIGN_SUFFIX}`,
    ACCOUNT_MANAGER: 'account-manager',
    ACCOUNT_MANAGER_VIDEO: `account-manager${VIDEO_CAMPAIGN_SUFFIX}`,
    SALES_PERSON: 'sales-person',
    SALES_PERSON_VIDEO: `sales-person${VIDEO_CAMPAIGN_SUFFIX}`,
    SALES_GROUP: 'sales-group',
    SALES_GROUP_VIDEO: `sales-group${VIDEO_CAMPAIGN_SUFFIX}`,
    ACCOUNT_MANAGER_GROUP: 'account-manager-group',
    ACCOUNT_MANAGER_GROUP_VIDEO: `account-manager-group${VIDEO_CAMPAIGN_SUFFIX}`,
    CAMPAIGNS_GROUPS: 'campaigns-groups',
    FIRST_PARTY: 'custom-audiences',
    PIXEL_BASED: 'pixel-audiences',
    CONVERSIONS: 'conversions',
    PREDICTIVE: 'predictive',
    CUSTOM_CONTEXTUAL: 'topics-targeting',
    MAIL_DOMAINS: 'mail-domains',
    SEARCH_KEYWORDS: 'search-keywords',
    ADVERTISER_DOMAIN: 'advertiser-domain',
    ATTRIBUTION_GROUP: 'attribution-group',
    DCO_CAMPAIGN_CREATIVE: 'dco-campaign-creative',
    DCO_ITEM_PERFORMANCE: 'dco-item-performance',
    PAYMENTS: 'payments',
    INVOICES: 'invoices',
    CAMPAIGN_HISTORY: 'campaign-history',
    ACTIVE_EVENTS: 'active-events',
    FUNNELS: 'funnels',
    COMBINED_AUDIENCES: 'combined-audiences',
    PERFORMANCE_RULES: 'performance-rules',
    PERFORMANCE_RULE_EXECUTED_ACTIONS: 'performance-rule-executed-actions',
    SCHEDULED_REPORTS: 'scheduled-reports',
};

export const REPORT_DATA_MAP_VALUES = {
    CAMPAIGNS: 'campaigns',
    CAMPAIGNS_GROUPS: 'campaignsGroups',
    CREATIVES: 'creatives',
    ACCOUNTS: 'accounts',
    CONVERSIONS: 'conversions',
    FUNNELS: 'funnels',
    PERFORMANCE_RULES: 'performanceRules',
    SCHEDULED_REPORTS: 'scheduled-reports',
};

export const REPORT_DATA_MAP_KEYS = {
    [REPORT_TYPE.CAMPAIGN]: REPORT_DATA_MAP_VALUES.CAMPAIGNS,
    [REPORT_TYPE.CAMPAIGN_REALTIME]: REPORT_DATA_MAP_VALUES.CAMPAIGNS,
    [REPORT_TYPE.CAMPAIGN_VIDEO]: REPORT_DATA_MAP_VALUES.CAMPAIGNS,
    [REPORT_TYPE.CAMPAIGNS_GROUPS]: REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS,
    [REPORT_TYPE.HIERARCHY_CAMPAIGN]: REPORT_DATA_MAP_VALUES.CAMPAIGNS,
    [REPORT_TYPE.CREATIVE]: REPORT_DATA_MAP_VALUES.CREATIVES,
    [REPORT_TYPE.CREATIVE_REALTIME]: REPORT_DATA_MAP_VALUES.CREATIVES,
    [REPORT_TYPE.DCO_CAMPAIGN_CREATIVE]: REPORT_DATA_MAP_VALUES.CREATIVES,
    [REPORT_TYPE.CREATIVE_VIDEO]: REPORT_DATA_MAP_VALUES.CREATIVES,
    [REPORT_TYPE.ACCOUNT]: REPORT_DATA_MAP_VALUES.ACCOUNTS,
    [REPORT_TYPE.ACCOUNT_VIDEO]: REPORT_DATA_MAP_VALUES.ACCOUNTS,
    [REPORT_TYPE.CONVERSIONS]: REPORT_DATA_MAP_VALUES.CONVERSIONS,
    [REPORT_TYPE.FUNNELS]: REPORT_DATA_MAP_VALUES.FUNNELS,
    [REPORT_TYPE.PERFORMANCE_RULES]: REPORT_DATA_MAP_VALUES.PERFORMANCE_RULES,
    [REPORT_TYPE.SCHEDULED_REPORTS]: REPORT_DATA_MAP_VALUES.SCHEDULED_REPORTS,
};

const campaignsReportsBaseConfig = {
    [REPORT_TYPE.ACCOUNT]: {
        id: REPORT_TYPE.ACCOUNT,
        label: 'Account',
        visibilityCondition: ({ selectedAccount: { type }, dimension, isRealtime }) =>
            isNetworkAccountType(type) && isSponsoredCampaign(dimension) && !isRealtime,
        accountConfigurations: {
            [FEATURE_FLAGS.ACCOUNT_REPORT_ENABLED]: 'true',
        },
    },
    [REPORT_TYPE.ACCOUNT_VIDEO]: {
        id: REPORT_TYPE.ACCOUNT_VIDEO,
        label: 'Account',
        visibilityCondition: ({ selectedAccount: { type }, dimension, isRealtime }) =>
            isNetworkAccountType(type) && isVideoCampaign(dimension) && !isRealtime,
    },
    [REPORT_TYPE.CAMPAIGN]: {
        id: REPORT_TYPE.CAMPAIGN,
        label: 'Campaigns',
        accountConfigurations: {
            [FEATURE_FLAGS.HIDE_HEAVY_ACCOUNT_CAMPAIGNS_REPORT]: 'false',
        },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.CAMPAIGN_VIDEO]: {
        id: REPORT_TYPE.CAMPAIGN_VIDEO,
        label: 'Campaigns',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.DAY]: {
        id: REPORT_TYPE.DAY,
        label: 'Day',
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.TODAY]: {
        id: REPORT_TYPE.TODAY,
        label: 'Today',
        permissions: ['TA_REAL_TIME_REPORT'],
        visibilityCondition: realtimeReportVisibilityCondition,
    },
    [REPORT_TYPE.CAMPAIGN_REALTIME]: {
        id: REPORT_TYPE.CAMPAIGN_REALTIME,
        label: 'Campaigns',
        permissions: ['TA_REALTIME_CAMPAIGN_REPORT'],
        visibilityCondition: realtimeReportVisibilityCondition,
    },
    [REPORT_TYPE.DAY_VIDEO]: {
        id: REPORT_TYPE.DAY_VIDEO,
        label: 'Day',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.WEEK]: {
        id: REPORT_TYPE.WEEK,
        label: 'Week',
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.WEEK_VIDEO]: {
        id: REPORT_TYPE.WEEK_VIDEO,
        label: 'Week',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.MONTH]: {
        id: REPORT_TYPE.MONTH,
        label: 'Month',
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.MONTH_VIDEO]: {
        id: REPORT_TYPE.MONTH_VIDEO,
        label: 'Month',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.DAY_OF_WEEK]: {
        id: REPORT_TYPE.DAY_OF_WEEK,
        label: 'Day of Week',
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.DAY_OF_WEEK_VIDEO]: {
        id: REPORT_TYPE.DAY_OF_WEEK_VIDEO,
        label: 'Day of Week',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.HOUR_OF_DAY]: {
        id: REPORT_TYPE.HOUR_OF_DAY,
        label: 'Hour of Day',
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.HOUR_OF_DAY_VIDEO]: {
        id: REPORT_TYPE.HOUR_OF_DAY_VIDEO,
        label: 'Hour of Day',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.CREATIVE]: {
        id: REPORT_TYPE.CREATIVE,
        label: 'Ads',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.HIDE_HEAVY_ACCOUNT_CREATIVES_REPORT]: 'false',
            [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'false',
        },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.CREATIVE_REALTIME]: {
        id: REPORT_TYPE.CREATIVE_REALTIME,
        label: 'Ads',
        permissions: ['TA_REALTIME_CREATIVE_REPORT'],
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.HIDE_HEAVY_ACCOUNT_CREATIVES_REPORT]: 'false',
            [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'false',
        },
        visibilityCondition: ({ isRealtime, selectedAccount, ...rest }) =>
            realtimeReportVisibilityCondition({ isRealtime, selectedAccount, ...rest }) &&
            !isProgrammaticAccount(selectedAccount),
    },
    [REPORT_TYPE.CREATIVE_VIDEO]: {
        id: REPORT_TYPE.CREATIVE_VIDEO,
        label: 'Ads',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.SITE]: {
        id: REPORT_TYPE.SITE,
        label: 'Sites',
        permissions: REPORT_PERMISSION.CAMPAIGN_SUMMARY_BY_SITE,
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
        },
        visibilityCondition: ({ dimension, accountConfig, selectedAccount, isRealtime }) =>
            isSponsoredAccountAndDimension({ dimension, isRealtime, selectedAccount }) &&
            (!isAdminNetworkAccountType(selectedAccount?.type) ||
                accountConfig[FEATURE_FLAGS.HIDE_ADMIN_ACCOUNT_SITE_REPORT] === 'false'),
    },
    [REPORT_TYPE.SITE_REALTIME]: {
        id: REPORT_TYPE.SITE_REALTIME,
        label: 'Sites',
        permissions: [REPORT_PERMISSION.CAMPAIGN_SUMMARY_BY_SITE, 'TA_REALTIME_SITE_REPORT'],
        visibilityCondition: ({ dimension, isRealtime, selectedAccount }) =>
            isSponsoredCampaign(dimension) &&
            isSponsoredAccount(selectedAccount?.partnerTypes) &&
            isRealtime &&
            !isAdminNetworkAccountType(selectedAccount?.type),
    },
    [REPORT_TYPE.SITE_VIDEO]: {
        id: REPORT_TYPE.SITE_VIDEO,
        label: 'Sites',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.PLATFORM]: {
        id: REPORT_TYPE.PLATFORM,
        label: 'Platforms',
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.PLATFORM_VIDEO]: {
        id: REPORT_TYPE.PLATFORM_VIDEO,
        label: 'Platforms',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.PLATFORM_REALTIME]: {
        id: REPORT_TYPE.PLATFORM_REALTIME,
        label: 'Platforms',
        permissions: ['TA_REAL_TIME_REPORT'],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: realtimeReportVisibilityCondition,
    },
    [REPORT_TYPE.OS_VERSION]: {
        id: REPORT_TYPE.OS_VERSION,
        label: 'OS Versions',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_OS_VERSION_REPORT],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.OS_VERSION_VIDEO]: {
        id: REPORT_TYPE.OS_VERSION_VIDEO,
        label: 'OS Versions',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.OS_FAMILY]: {
        id: REPORT_TYPE.OS_FAMILY,
        label: 'OS Families',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_OS_FAMILY_REPORT],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.OS_FAMILY_VIDEO]: {
        id: REPORT_TYPE.OS_FAMILY_VIDEO,
        label: 'OS Families',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.BROWSER]: {
        id: REPORT_TYPE.BROWSER,
        label: 'Browsers',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_BROWSER_REPORT],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.BROWSER_VIDEO]: {
        id: REPORT_TYPE.BROWSER_VIDEO,
        label: 'Browsers',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.CATEGORY]: {
        id: REPORT_TYPE.CATEGORY,
        label: 'Categories',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_CATEGORY_REPORT],
        accountConfigurations: { [FEATURE_FLAGS.SHOW_CATEGORY_REPORT]: 'true' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.SEGMENT_CATEGORY]: {
        id: REPORT_TYPE.SEGMENT_CATEGORY,
        label: 'Segment Categories',
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_PIXEL_CATEGORY_REPORT],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.COUNTRY]: {
        id: REPORT_TYPE.COUNTRY,
        label: 'Countries',
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.COUNTRY_VIDEO]: {
        id: REPORT_TYPE.COUNTRY_VIDEO,
        label: 'Countries',
        permissions: REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.COUNTRY_REALTIME]: {
        id: REPORT_TYPE.COUNTRY_REALTIME,
        label: 'Countries',
        permissions: ['TA_REALTIME_COUNTRY_REPORT'],
        visibilityCondition: realtimeReportVisibilityCondition,
    },
    [REPORT_TYPE.CITY_VIDEO]: {
        id: REPORT_TYPE.CITY_VIDEO,
        label: 'City',
        permissions: [[REPORT_PERMISSION.ADS_CONSOLE_CITY_REPORT], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.QUARTILE_VIDEO]: {
        id: REPORT_TYPE.QUARTILE_VIDEO,
        label: 'Quartile',
        permissions: [[REPORT_PERMISSION.ADS_CONSOLE_QUARTILE_REPORT], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.REGION]: {
        id: REPORT_TYPE.REGION,
        label: 'Regions',
        permissions: [REPORT_PERMISSION.SP_VIEW_SUBCOUNTRY_PERFORMANCE],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.DMA]: {
        id: REPORT_TYPE.DMA,
        label: 'DMAs',
        permissions: [REPORT_PERMISSION.SP_VIEW_SUBCOUNTRY_PERFORMANCE],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.DMA_VIDEO]: {
        id: REPORT_TYPE.DMA_VIDEO,
        label: 'DMAs',
        permissions: [[REPORT_PERMISSION.ADS_CONSOLE_DMA_REPORT], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        accountConfigurations: { [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false' },
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.AUDIENCE_BUNDLES]: {
        id: REPORT_TYPE.AUDIENCE_BUNDLES,
        label: 'Audiences',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.CAN_EDIT_CAMPAIGN_USER_SEGMENTS]: 'true',
        },
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_AUDIENCE_REPORT],
        visibilityCondition: conf => {
            const {
                selectedAccount,
                configs: { selectedAccountConfig },
            } = conf;
            return (
                isSponsoredAccountAndDimension(conf) &&
                getIsMarketplaceAudiencesBundlingEnabled(selectedAccountConfig, selectedAccount)
            );
        },
    },
    [REPORT_TYPE.AUDIENCE]: {
        id: REPORT_TYPE.AUDIENCE,
        label: 'Audiences',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.CAN_EDIT_CAMPAIGN_USER_SEGMENTS]: 'true',
        },
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_AUDIENCE_REPORT],
        visibilityCondition: conf => {
            const {
                selectedAccount,
                configs: { selectedAccountConfig },
            } = conf;
            return (
                isSponsoredAccountAndDimension(conf) &&
                !getIsMarketplaceAudiencesBundlingEnabled(selectedAccountConfig, selectedAccount)
            );
        },
    },
    [REPORT_TYPE.CONTEXTUAL]: {
        id: REPORT_TYPE.CONTEXTUAL,
        label: 'Contextual',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.CAN_EDIT_CAMPAIGN_USER_SEGMENTS]: 'true',
        },
        permissions: [REPORT_PERMISSION.ADS_CONSOLE_CONTEXTUAL_REPORT],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.CONTEXTUAL_VIDEO]: {
        id: REPORT_TYPE.CONTEXTUAL_VIDEO,
        label: 'Contextual',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
        },
        permissions: [
            [REPORT_PERMISSION.ADS_CONSOLE_CONTEXTUAL_VIDEO_REPORT],
            REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS,
        ],
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.ACCOUNT_MANAGER]: {
        id: REPORT_TYPE.ACCOUNT_MANAGER,
        label: 'AM',
        permissions: [REPORT_PERMISSION.REP_BREAKDOWN_VIEW],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.ACCOUNT_MANAGER_VIDEO]: {
        id: REPORT_TYPE.ACCOUNT_MANAGER_VIDEO,
        label: 'AM',
        permissions: [[REPORT_PERMISSION.REP_BREAKDOWN_VIEW], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.SALES_PERSON]: {
        id: REPORT_TYPE.SALES_PERSON,
        label: 'Sales Person',
        permissions: [REPORT_PERMISSION.REP_BREAKDOWN_VIEW],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.SALES_PERSON_VIDEO]: {
        id: REPORT_TYPE.SALES_PERSON_VIDEO,
        label: 'Sales Person',
        permissions: [[REPORT_PERMISSION.REP_BREAKDOWN_VIEW], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.SALES_GROUP]: {
        id: REPORT_TYPE.SALES_GROUP,
        label: 'Sales Group',
        permissions: [REPORT_PERMISSION.REP_BREAKDOWN_VIEW],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.SALES_GROUP_VIDEO]: {
        id: REPORT_TYPE.SALES_GROUP_VIDEO,
        label: 'Sales Group',
        permissions: [[REPORT_PERMISSION.REP_BREAKDOWN_VIEW], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.ACCOUNT_MANAGER_GROUP]: {
        id: REPORT_TYPE.ACCOUNT_MANAGER_GROUP,
        label: 'AM Group',
        permissions: [REPORT_PERMISSION.REP_BREAKDOWN_VIEW],
        visibilityCondition: isSponsoredAccountAndDimension,
    },
    [REPORT_TYPE.ACCOUNT_MANAGER_GROUP_VIDEO]: {
        id: REPORT_TYPE.ACCOUNT_MANAGER_GROUP_VIDEO,
        label: 'AM Group',
        permissions: [[REPORT_PERMISSION.REP_BREAKDOWN_VIEW], REPORT_PERMISSION.ADS_CONSOLE_VIDEO_CAMPAIGNS],
        visibilityCondition: isVideoAccountAndDimension,
    },
    [REPORT_TYPE.CAMPAIGNS_GROUPS]: {
        id: REPORT_TYPE.CAMPAIGNS_GROUPS,
        label: 'Campaign Groups',
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
        },
        visibilityCondition: props => {
            const { accountConfig } = props;
            const enabled =
                isOnlySponsored(props) &&
                (accountConfig[FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED] === 'true' ||
                    accountConfig[FEATURE_FLAGS.SHARED_BUDGET_UA_ENABLED] === 'true');
            return enabled;
        },
    },
    [REPORT_TYPE.ADVERTISER_DOMAIN]: {
        id: REPORT_TYPE.ADVERTISER_DOMAIN,
        label: 'Advertiser Domain',
        permissions: [REPORT_PERMISSION.CAMPAIGN_BY_ADVERTISER_DOMAIN_VIEW],
        visibilityCondition: supportProgrammaticCampaigns,
    },
    [REPORT_TYPE.ATTRIBUTION_GROUP]: {
        id: REPORT_TYPE.ATTRIBUTION_GROUP,
        label: 'Attribution Group',
        permissions: [REPORT_PERMISSION.CAMPAIGN_BY_ATTRIBUTION_GROUP_VIEW],
        visibilityCondition: supportProgrammaticCampaigns,
    },
    [REPORT_TYPE.DCO_CAMPAIGN_CREATIVE]: {
        id: REPORT_TYPE.DCO_CAMPAIGN_CREATIVE,
        label: 'DCO Campaign Creative',
        accountConfigurations: {
            [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'true',
        },
        commonConfigurations: {
            [COMMON_FLAGS.DCO_CREATIVE_TEMPLATE_REPORT_ENABLED]: 'true',
        },
    },
    [REPORT_TYPE.DCO_ITEM_PERFORMANCE]: {
        id: REPORT_TYPE.DCO_ITEM_PERFORMANCE,
        label: 'DCO Item Performance',
        accountConfigurations: {
            [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'true',
        },
        commonConfigurations: {
            [COMMON_FLAGS.DCO_ITEM_PERFORMANCE_REPORT_ENABLED]: 'true',
        },
    },
    [REPORT_TYPE.CAMPAIGN_HISTORY]: {
        id: REPORT_TYPE.CAMPAIGN_HISTORY,
        label: 'Changes History',
        permissions: [REPORT_PERMISSION.CAMPAIGN_HISTORY_VIEW],
        accountConfigurations: {
            [FEATURE_FLAGS.ONLY_PERFORMANCE_REPORTS]: 'false',
            [FEATURE_FLAGS.SHOW_CAMPAIGN_HISTORY_REPORT]: 'true',
        },
        visibilityCondition: isOnlySponsored,
    },
    [REPORT_TYPE.PERFORMANCE_RULES]: {
        id: REPORT_TYPE.PERFORMANCE_RULES,
        label: 'Performance Rules',
        accountConfigurations: {
            [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLED]: 'true',
        },
    },
    [REPORT_TYPE.HIERARCHY_CAMPAIGN]: {
        id: REPORT_TYPE.HIERARCHY_CAMPAIGN,
        label: 'Carousels',
        accountConfigurations: {
            [FEATURE_FLAGS.CREATIVE_CAROUSEL_ENABLED]: 'true',
        },
        visibilityCondition: isOnlySponsored,
    },
};

const audiencesReportsBaseConfig = {
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: {
        id: REPORT_TYPE.CUSTOM_CONTEXTUAL,
        label: 'Topics Segments',
        visibilityCondition: ({ permissions, configs: { selectedAccountConfig } }) =>
            isUserPermissionOrFeatureFlagEnabled(
                permissions,
                selectedAccountConfig,
                REPORT_PERMISSION.ADS_CONSOLE_CUSTOM_CONTEXTUAL,
                FEATURE_FLAGS.ADS_CONSOLE_CUSTOM_CONTEXTUAL_ALLOWED
            ),
    },
    [REPORT_TYPE.PIXEL_BASED]: {
        id: REPORT_TYPE.PIXEL_BASED,
        label: 'Pixel Audiences',
    },
    [REPORT_TYPE.FIRST_PARTY]: {
        id: REPORT_TYPE.FIRST_PARTY,
        label: 'Custom Audiences',
        visibilityCondition: ({ permissions, configs: { selectedAccountConfig } }) =>
            isUserPermissionOrFeatureFlagEnabled(
                permissions,
                selectedAccountConfig,
                'CREATE_CUSTOMER_FILE_ALLOWED',
                FEATURE_FLAGS.CREATE_CUSTOMER_FILE_ALLOWED
            ),
    },
    [REPORT_TYPE.PREDICTIVE]: {
        id: REPORT_TYPE.PREDICTIVE,
        label: 'Lookalike Audiences',
        visibilityCondition: ({ permissions, configs: { selectedAccountConfig } }) =>
            isUserPermissionOrFeatureFlagEnabled(
                permissions,
                selectedAccountConfig,
                'CREATE_LOOKALIKE_ALLOWED',
                FEATURE_FLAGS.CREATE_LOOKALIKE_ALLOWED
            ),
    },
    [REPORT_TYPE.COMBINED_AUDIENCES]: {
        id: REPORT_TYPE.COMBINED_AUDIENCES,
        label: 'Combined Audiences',
        visibilityCondition: ({ permissions, configs: { selectedAccountConfig }, selectedAccount }) => {
            const { partnerTypes } = selectedAccount;

            const isSupportedAccountType =
                !isVideoAccount(partnerTypes) &&
                !isPmpAccount(selectedAccount) &&
                !isOpenExchangeAccount(selectedAccount);

            const isAllowed = isUserPermissionOrFeatureFlagEnabled(
                permissions,
                selectedAccountConfig,
                'ADS_CONSOLE_COMBINED_AUDIENCES',
                FEATURE_FLAGS.ADS_CONSOLE_COMBINED_AUDIENCES_ALLOWED
            );

            return isAllowed && isSupportedAccountType;
        },
    },
};

const trackingReportsBaseConfig = {
    [REPORT_TYPE.CONVERSIONS]: {
        id: REPORT_TYPE.CONVERSIONS,
        label: 'Taboola Conversions',
    },
    [REPORT_TYPE.ACTIVE_EVENTS]: {
        id: REPORT_TYPE.ACTIVE_EVENTS,
        label: 'Active Events',
        permissions: ['TA_ACTIVE_EVENTS_REPORT'],
    },
    [REPORT_TYPE.FUNNELS]: {
        id: REPORT_TYPE.FUNNELS,
        label: 'Conversion Funnels',
        permissions: ['TA_CONVERSION_FUNNEL'],
        accountConfigurations: {
            [FEATURE_FLAGS.CONVERSION_FUNNEL_ENABLED]: 'true',
        },
    },
};

const billingReportsBaseConfig = {
    [REPORT_TYPE.PAYMENTS]: {
        id: REPORT_TYPE.PAYMENTS,
        label: 'Payments',
        permissions: [REPORT_PERMISSION.BUDGET_MANAGEMENT_VIEW],
    },
    [REPORT_TYPE.INVOICES]: {
        id: REPORT_TYPE.INVOICES,
        label: 'Invoices',
        permissions: [REPORT_PERMISSION.BUDGET_MANAGEMENT_VIEW, REPORT_PERMISSION.PAYMENT_INVOICES_VIEW],
    },
};

const performanceRulesReportsBaseConfig = {
    [REPORT_TYPE.PERFORMANCE_RULES]: {
        id: REPORT_TYPE.PERFORMANCE_RULES,
        label: 'Performance Rules',
    },
    [REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS]: {
        id: REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS,
        label: 'Rule Actions',
        accountConfigurations: {
            [FEATURE_FLAGS.PERFORMANCE_RULES_EXECUTED_ACTIONS_ENABLED]: 'true',
        },
    },
};

const scheduledReportsBaseConfig = {
    [REPORT_TYPE.SCHEDULED_REPORTS]: {
        id: REPORT_TYPE.SCHEDULED_REPORTS,
        label: 'Reports',
    },
};

const reportsBaseConfig = {
    ...createReportConfig(campaignsReportsBaseConfig, CAMPAIGNS_MODULE),
    ...createReportConfig(audiencesReportsBaseConfig, AUDIENCES_MODULE),
    ...createReportConfig(billingReportsBaseConfig, BILLING_MODULE),
    ...createReportConfig(trackingReportsBaseConfig, TRACKING_MODULE),
    ...createReportConfig(performanceRulesReportsBaseConfig, PERFORMANCE_RULES_MODULE),
    ...createReportConfig(scheduledReportsBaseConfig, SCHEDULED_REPORTS),
};

const DEFAULT_REPORT_MAP = {
    [CAMPAIGN_DIMENSION.SPONSORED]: REPORT_TYPE.DAY,
    [CAMPAIGN_DIMENSION.VIDEO]: REPORT_TYPE.DAY_VIDEO,
};

function createReportConfig(reportBaseConfig, moduleName) {
    return reduce(
        reportBaseConfig,
        (result, { visibilityCondition, ...rest }, key) => {
            result[key] = {
                ...rest,
                visibilityCondition: params =>
                    (!visibilityCondition || visibilityCondition(params)) && params.module === moduleName,
            };
            return result;
        },
        {}
    );
}

export const getDefaultReportId = (dimension = CAMPAIGN_DIMENSION.SPONSORED) => {
    const module = getModuleName();

    if (module === TRACKING_MODULE) {
        return REPORT_TYPE.CONVERSIONS;
    }
    if (module === AUDIENCES_MODULE) {
        return REPORT_TYPE.PIXEL_BASED;
    }
    if (module === AUDIENCE_INSIGHTS_MODULE) {
        return REPORT_TYPE.SEGMENT_CATEGORY;
    }
    if (module === BILLING_MODULE) {
        return REPORT_TYPE.PAYMENTS;
    }
    if (module === PERFORMANCE_RULES_MODULE) {
        return REPORT_TYPE.PERFORMANCE_RULES;
    }
    if (module === SCHEDULED_REPORTS) {
        return REPORT_TYPE.SCHEDULED_REPORTS;
    }
    if (isRealTimeReport()) {
        return REPORT_TYPE.TODAY;
    }

    return DEFAULT_REPORT_MAP[dimension] || REPORT_TYPE.DAY;
};

export const resolveReportIdByCampaignDimension = (reportId, dimension) => {
    const cleanName = reportId.replace(VIDEO_CAMPAIGN_SUFFIX, '').replace(REALTIME_CAMPAIGN_SUFFIX, '');
    if (isRealTimeReport()) {
        return `${cleanName}${REALTIME_CAMPAIGN_SUFFIX}`;
    }
    if (isVideoCampaign(dimension)) {
        return `${cleanName}${VIDEO_CAMPAIGN_SUFFIX}`;
    }
    return cleanName;
};

export default reportsBaseConfig;
