import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { useCheckboxState } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { UNCHECKED } from '../../../../config';
import { setShouldShowRemoveVariationModal } from '../../actions';

const checkboxTitle = (
    <FormattedMessage id="app.modal.removeVariation.checkboxTitle" defaultMessage="Don't show this again" />
);

const RemoveVariationModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const {
        onSelectCheckbox,
        onUnselectCheckbox,
        selected: shouldDisableModal,
        setSelected,
        selectedValues,
    } = useCheckboxState(noop, noop);
    const checkboxData = useMemo(() => {
        return [{ title: checkboxTitle, value: shouldDisableModal }];
    }, [shouldDisableModal]);
    const handleSubmit = useCallback(() => {
        dispatch(setShouldShowRemoveVariationModal(!shouldDisableModal));
        onClose(true);
    }, [onClose, shouldDisableModal, dispatch]);
    const handleClose = useCallback(() => {
        setSelected(UNCHECKED);
        onClose();
    }, [onClose, setSelected]);

    return (
        <ModalFormLayout
            onCancel={handleClose}
            onSubmit={handleSubmit}
            submitButtonText={<FormattedMessage id="app.modal.removeVariation.button.remove" defaultMessage="Remove" />}
            onSelectCheckbox={onSelectCheckbox}
            onUnselectCheckbox={onUnselectCheckbox}
            checkboxSelectedValues={selectedValues}
            checkboxData={checkboxData}
        >
            <FormattedMessage
                id="app.modal.removeVariation.content"
                defaultMessage="This variation will be completely removed from the campaign."
            />
        </ModalFormLayout>
    );
};

RemoveVariationModal.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

export default RemoveVariationModal;
