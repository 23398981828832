"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSettingsWhite(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6m6.4-4.6h-.76a5.856 5.856 0 0 0-.519-1.259l.534-.535a1.599 1.599 0 0 0 0-2.264 1.598 1.598 0 0 0-2.26 0l-.537.537a5.794 5.794 0 0 0-1.258-.52V5.6a1.6 1.6 0 0 0-3.2 0v.76c-.444.125-.865.3-1.259.519l-.535-.537a1.6 1.6 0 1 0-2.264 2.264l.537.536c-.22.393-.394.815-.52 1.258H5.6a1.6 1.6 0 1 0 0 3.2h.76c.125.443.3.864.519 1.258l-.537.536a1.601 1.601 0 0 0 2.264 2.263l.535-.536c.394.22.815.393 1.259.518v.761a1.6 1.6 0 1 0 3.2 0v-.76c.443-.125.865-.3 1.258-.519l.536.536a1.598 1.598 0 0 0 2.261 0 1.597 1.597 0 0 0 0-2.263l-.534-.535c.22-.394.394-.816.52-1.259h.759a1.6 1.6 0 0 0 0-3.2"
            })
        )
    );
}
exports.default = SvgSettingsWhite;