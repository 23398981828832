import React from 'react';
import { UploadOutlinedIcon } from 'tuui';
import { InternalLink } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FileDropZone } from '../FileDropZone/FileDropZone';
import styles from './fileClickOrDropZone.module.scss';

const LargeUploadIcon = () => <UploadOutlinedIcon className={styles['upload-icon']} fontSize="large" />;

export const FileClickOrDropZone = ({
    isLoading,
    filename,
    description,
    onRemoveFileHandler,
    openFileDialog,
    containerClassName,
}) => {
    return (
        <FileDropZone
            isLoading={isLoading}
            filename={filename}
            removeFile={onRemoveFileHandler}
            id="ExcelDropZoneValidationId"
            Icon={LargeUploadIcon}
            description={description}
            containerClassName={containerClassName}
        >
            <div className={styles['link-container']}>
                <InternalLink className={styles['upload-link']} onClick={openFileDialog}>
                    <FormattedMessage id="browse.files.to.upload" defaultMessage="browse files to upload" />
                </InternalLink>
            </div>
        </FileDropZone>
    );
};
