'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactTabs = require('react-tabs');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /**
                                                                                                                                                                                                                              * Created by oreuveni
                                                                                                                                                                                                                              * Date: 2019-04-24
                                                                                                                                                                                                                              * Time: 14:34
                                                                                                                                                                                                                              */


/**
 * @deprecated - use Tabs from tuui
 */
function TaboolaTabs(_ref) {
    var children = _ref.children,
        rest = _objectWithoutProperties(_ref, ['children']);

    return _react2.default.createElement(
        _reactTabs.Tabs,
        rest,
        children
    );
}

TaboolaTabs.propTypes = {
    children: _propTypes2.default.node,
    className: _propTypes2.default.string
};

TaboolaTabs.defaultProps = {
    className: ''
};

exports.default = TaboolaTabs;