import React from 'react';
import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const SEGMENT_SORTABLE_FIELDS = {
    NAME: 'name',
    ID: 'uddId',
};
const defaultSortField = SEGMENT_SORTABLE_FIELDS.NAME;
const defaultSortType = SORTING_ORDER_TYPES.DESCENDANT;

const sortFieldsOptions = [
    {
        value: SEGMENT_SORTABLE_FIELDS.NAME,
        label: <FormattedMessage id="segment.picker.sorting.name" defaultMessage="Name" />,
    },
    {
        value: SEGMENT_SORTABLE_FIELDS.ID,
        label: <FormattedMessage id="segment.picker.sorting.uddId" defaultMessage="ID" />,
    },
];

export default SEGMENT_SORTABLE_FIELDS;
export { defaultSortField, defaultSortType, sortFieldsOptions };
