import React from 'react';
import { HelpTooltip } from 'taboola-ultimate-ui';
import { ThumbnailsFieldTooltip } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const UrlTabUploadDescription = () => (
    <>
        <FormattedMessage
            id="creative.editor.tab.upload.device.subtitle"
            defaultMessage="*Max file sizes: Images 5 Mb. GIFs 5 Mb. Videos 50 Mb."
        />
        <HelpTooltip>
            <ThumbnailsFieldTooltip />
        </HelpTooltip>
    </>
);
export default UrlTabUploadDescription;
