import React from 'react';
import classnames from 'classnames/bind';
import { map, size } from 'lodash';
import { DesktopIcon, SmartphoneIcon, TabletIcon } from 'tuui';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFilteredPlatformBoosts } from '../../hooks/useFilteredPlatformBoosts';
import { PlatformInfo } from '../PlatformInfo/PlatformInfo';
import styles from './platformParting.module.scss';

const classNameBuilder = classnames.bind(styles);

const platformToIcon = {
    DESK: <DesktopIcon />,
    PHON: <SmartphoneIcon />,
    TBLT: <TabletIcon />,
};

export const PlatformParting = () => {
    const filteredPlatformBoost = useFilteredPlatformBoosts();

    return (
        <div>
            <table>
                <thead>
                    <tr className={styles['header']}>
                        <th className={classNameBuilder(styles['column-header'], styles['device-header'])}>
                            <FormattedMessage
                                id="campaign.editor.bid.platformParting.header.platform"
                                defaultMessage="Device"
                            />
                        </th>
                        <th className={classNameBuilder(styles['column-header'], styles['boost-amount-header'])}>
                            <FormattedMessage
                                id="campaign.editor.bid.platformParting.header.boost.amount"
                                defaultMessage="Boost Amount"
                            />
                        </th>
                    </tr>
                </thead>

                {!!size(filteredPlatformBoost) && (
                    <tbody className={styles['container']}>
                        {map(filteredPlatformBoost, (row, index) => (
                            <FormNamespaceProvider
                                field={`platformBidModifier.values.${index}`}
                                itemKey={row.platform}
                                key={row.platform}
                            >
                                <PlatformInfo icon={platformToIcon[row.platform]} platform={row.platform} />
                            </FormNamespaceProvider>
                        ))}
                    </tbody>
                )}
            </table>
        </div>
    );
};
