import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { setCachedUserId } from './userIdCache';

export const USER_ID = 'current_user';

const useUserIdParam = queryParamHookFactory(USER_ID, {
    persist: PERSISTENCE_TYPE.MEMORY,
});

export const useUserId = () => {
    const result = useUserIdParam();
    setCachedUserId(result[0]);

    return result;
};
