import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import OsTargeting from './OsTargeting';
import OsTargetingTooltip from './OsTargetingTooltip/OsTargetingTooltip';
import styles from '../commonEditor.module.scss';

const OsTargetingField = ({ valuesToFilter }) => (
    <FormField
        inputId="os-targeting"
        label={<FormattedMessage id="campaign.editor.targeting.os.title" defaultMessage="Operating System" />}
        description={
            <FormattedMessage
                id="campaign.editor.targeting.os.description"
                defaultMessage="Target specific Operating Systems."
            />
        }
        helpText={<OsTargetingTooltip />}
        containerClass={styles['input']}
    >
        <OsTargeting valuesToFilter={valuesToFilter} />
    </FormField>
);

export default OsTargetingField;
