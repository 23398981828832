import { useState } from 'react';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useUserPermissionOrFeatureFlagEnabled } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';

export const useContentTitleSuggestionsState = allowAiSuggestionsVisible => {
    const [aiSuggestionsCollapsed, setAiSuggestionsCollapsed] = useState(true);
    const isAISuggestionsFlagEnabled = useUserPermissionOrFeatureFlagEnabled(
        'AI_TITLE_SUGGESTIONS',
        FEATURE_FLAGS.AI_TITLE_SUGGESTIONS_ENABLED
    );

    const isAISuggestionsVisible = isAISuggestionsFlagEnabled && allowAiSuggestionsVisible;

    const openAiSuggestions = () => {
        isAISuggestionsVisible && setAiSuggestionsCollapsed(false);
    };

    return { isAISuggestionsVisible, aiSuggestionsCollapsed, setAiSuggestionsCollapsed, openAiSuggestions };
};
