import { useAvailableItems, useDropdownOptions, useDropdownValue } from '../../../../../../../hooks';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { PRICING_MODEL } from '../../../../../config';
import { pricingModelConfig } from '../../Bid/BidInputList';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';

const dropdownTransformer = list => list.map(({ id, ...rest }) => ({ ...rest, value: id, label: PRICING_MODEL[id] }));

const isOptionDisabled = ({ disabled }) => disabled;

export const usePricingModelOptions = ({ pricingModel, hasHighImpactPackage }) => {
    const { value: bidStrategy } = useBidStrategyFormField();
    const { formAccount: selectedAccount, mode } = useFormDataContext();
    const availableItems = useAvailableItems(pricingModelConfig, {
        selectedAccount,
        hasHighImpactPackage,
        currentPricingModel: pricingModel,
        bidStrategy,
        formMode: mode,
    });

    const pricingModelOptions = useDropdownOptions(
        availableItems,
        'campaign.editor.bid.type.option',
        dropdownTransformer
    );

    const selectedValue = useDropdownValue({ value: pricingModel }, 'campaign.editor.bid.type.option');

    return {
        pricingModelOptions,
        selectedValue,
        isOptionDisabled,
        hasHighImpactPackage,
    };
};
