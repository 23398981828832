import { useSelector } from 'react-redux';
import useServerCtaData from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/hooks/useServerCtaData';
import { CONTENT_METHODS } from 'modules/campaigns/modules/creative-creator/config';
import { bulkCreateCreatives } from 'modules/campaigns/modules/creative-creator/flows';
import { aiTabImagePromptMapSelector } from 'modules/campaigns/modules/creative-editor';
import { sendVariationsMetrics } from '../services/sendVariationsMetrics';
import { transformCreatePreviews } from '../utils/creativeCreatorTransformerUtils';

export const useSaveVariationsHandler = (accountId, data, dispatch, onSuccess) => {
    const {
        thumbnails: thumbnailList,
        creativePreviews: variationsCreativePreviews,
        aiVariationsCreativePreviews,
        recommendationId,
    } = data;

    const ctaData = useServerCtaData();
    const aiImagesPromptData = useSelector(aiTabImagePromptMapSelector);

    return ({ contentMethod, campaigns: passedCampaigns }) => {
        const { content: variationsContent, aiVariationsContent } = data;
        const campaigns = passedCampaigns || data.campaigns;
        const creativePreviews =
            contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsCreativePreviews : variationsCreativePreviews;

        const content = contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsContent : variationsContent;

        const { activeCreatives, activeVideoCreatives, encodedActivePreviews } =
            transformCreatePreviews(creativePreviews);

        sendVariationsMetrics({
            content,
            thumbnailList,
            campaigns,
        });

        return dispatch(
            bulkCreateCreatives({
                accountId,
                selectedCampaigns: campaigns,
                creatives: activeCreatives,
                videoCreatives: activeVideoCreatives,
                ctaData,
                encodedActivePreviews,
                aiImagesPromptData,
                recommendationId,
                onSuccess,
            })
        );
    };
};
