import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, HelpTooltip, Dotdotdot } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './audienceNameCellRenderer.module.scss';
import helpTooltipStyles from './helpTooltip.module.scss';

const AudienceNameCellRenderer = ({ value, data, fieldName = 'audience', isSearchable }) => {
    const { audienceName, audienceDescription, taxonomyLabel } = data;

    return (
        <div className={styles['audience-name-container']}>
            <div className={styles['audience-name-label']}>
                <EllipsisCellRenderer value={value} lines={1} isSearchable={isSearchable} />
                {taxonomyLabel && (
                    <Dotdotdot lines={1} className={styles['taxonomy-label']}>
                        {taxonomyLabel}
                    </Dotdotdot>
                )}
            </div>
            <HelpTooltip
                iconClassName={helpTooltipStyles['help-icon']}
                position={TOOLTIP_POSITION.RIGHT}
                className={helpTooltipStyles['help-tooltip']}
                iconLabel="audience details"
            >
                <FormattedMessage
                    id={`app.campaigns.reports.grid.${fieldName}.name`}
                    defaultMessage="Audience name: {audienceName}"
                    values={{ audienceName }}
                />
                <br />
                <FormattedMessage
                    id={`app.campaigns.reports.grid.${fieldName}.description`}
                    defaultMessage="Audience description: {audienceDescription}"
                    values={{ audienceDescription: audienceDescription || '-' }}
                />
            </HelpTooltip>
        </div>
    );
};

AudienceNameCellRenderer.propTypes = {
    data: PropTypes.object,
    ...EllipsisCellRenderer.propTypes,
};

export default AudienceNameCellRenderer;
