import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

const validateEmail = email => {
    return !validationFunctions.isNotEmptyString(email) || validationFunctions.isEmail(email);
};

export const scheduledReportEmailValidations = [
    {
        validationFn: (value, options, { sentTo }) => {
            return !validationFunctions.isNotEmptyString(value) || !sentTo.some(v => v.email === `${value}`.trim());
        },
        messageId: 'app.scheduled.reports.sent.to.validations.error.email.duplicated',
        defaultMessage: 'Some of your emails are duplicated',
    },
    {
        validationFn: validateEmail,
        messageId: 'validations.error.field.email.invalid',
    },
];
