import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const Id = ({ id, className }) => (
    <div className={className}>
        <FormattedMessage id="campaign.editor.campaignId.id" defaultMessage="ID: {id}" values={{ id }} />
    </div>
);

Id.propTypes = {
    /** id value */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The class name to use in the component */
    className: PropTypes.string,
};

export default Id;
