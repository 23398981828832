import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

export const ImpressionsReachTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.size.estimation.impressions.reach.tooltip.title"
                defaultMessage="Total impressions for the last 30 days for the selected country, platform and topics."
            />
        </TooltipSection>
    </>
);
