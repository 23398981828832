const VIDEO_PLATFORMS = {
    DESKTOP: 'DESK',
    MOBILE: 'PHON',
    TABLET: 'TBLT',
    IN_APP: 'APP',
};

const VIDEO_PLATFORMS_KEYS = Object.values(VIDEO_PLATFORMS);

export { VIDEO_PLATFORMS_KEYS };
export default VIDEO_PLATFORMS;
