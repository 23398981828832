import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import {
    ExportIcon,
    HeadlessDropdown,
    INDICATION_TYPES,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    TOOLTIP_POSITION,
    TooltipV2 as Tooltip,
} from 'taboola-ultimate-ui';
import { ExternalLink } from 'components';
import { useAvailableItems } from 'hooks';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications/actions';
import {
    exportToGoogleDriveCSVFile,
    exportToLocalCSVFile,
} from 'modules/taboola-common-frontend-modules/csvExporter/csvExporter';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { DROPDOWN_OPTIONS, ERROR_INDICATIONS, EXPORT_TYPES } from './constants';
import styles from './exportDropdown.module.scss';

const ONE_MINUTE = 60 * 1000;

const getGoogleDriveSuccessIndication = url => ({
    message: (
        <FormattedMessage
            id="app.campaigns.reports.csv.googleDrive.success"
            defaultMessage="Report exported to Google Drive successfully. {link}"
            values={{
                link: (
                    <ExternalLink href={url}>
                        <FormattedMessage
                            id="app.campaigns.reports.csv.googleDrive.success.link"
                            defaultMessage="Open on Google Drive"
                        />
                    </ExternalLink>
                ),
            }}
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    timeoutDuration: ONE_MINUTE * 2,
});

const ExportDropdown = ({ csvOptionsProvider }) => {
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { checkAuth, loadGoogleSheets } = useGoogleApi();

    const options = useAvailableItems(DROPDOWN_OPTIONS);

    const isGoogleDriveExportEnabled = useConfigMatch({
        [FEATURE_FLAGS.GOOGLE_DRIVE_EXPORT_ENABLED]: 'true',
    });

    const onToggleDropdownHandler = useCallback(() => setIsOpen(prev => !prev), []);

    const handleLocalCSVExport = useCallback(async () => {
        try {
            const { columnDefs, selectedColumns, filename, dataProvider, injectables } = csvOptionsProvider();

            await exportToLocalCSVFile(dataProvider, columnDefs, selectedColumns, filename, injectables);
            setLoading(false);
        } catch (err) {
            console.error(err);
            dispatch(addIndication(ERROR_INDICATIONS.CSV));
        }
    }, [csvOptionsProvider, dispatch]);

    const handleGoogleDriveExport = useCallback(async () => {
        let spreadsheetUrl = '';

        try {
            // log user into google drive
            await checkAuth();
            // load google sheets API
            await loadGoogleSheets();

            const { columnDefs, selectedColumns, filename, dataProvider, injectables } = await csvOptionsProvider();

            spreadsheetUrl = await exportToGoogleDriveCSVFile(
                dataProvider,
                columnDefs,
                selectedColumns,
                filename,
                injectables
            );

            // create success indication with spreadsheet link
            dispatch(addIndication(getGoogleDriveSuccessIndication(spreadsheetUrl)));
        } catch (err) {
            console.error(err);

            dispatch(addIndication(ERROR_INDICATIONS.GOOGLE_DRIVE));
        }
    }, [checkAuth, loadGoogleSheets, csvOptionsProvider, dispatch]);

    const onChangeHandler = useCallback(
        async ({ value }) => {
            setLoading(true);

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                pageName: getPageName(),
                component: 'ExportToCsvButton',
                value,
            });

            const exportFuncByType = {
                [EXPORT_TYPES.GOOGLE_DRIVE]: handleGoogleDriveExport,
                [EXPORT_TYPES.CSV]: handleLocalCSVExport,
            };

            await exportFuncByType[value]();

            setLoading(false);
        },
        [handleLocalCSVExport, handleGoogleDriveExport]
    );

    return (
        <HeadlessDropdown
            options={options}
            onChange={onChangeHandler}
            open={isOpen}
            onToggleDropdown={isGoogleDriveExportEnabled ? onToggleDropdownHandler : handleLocalCSVExport}
            disabled={loading}
            width={175}
            menuStyles={{ textAlign: 'left' }}
            isRtl
        >
            <Tooltip arrow={true} position={TOOLTIP_POSITION.BOTTOM_START}>
                <FormattedMessage id="app.gridToolbar.export.tooltip" defaultMessage="Export" />
            </Tooltip>
            <StyledButton
                className={styles['export-button']}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.GHOST}
                disabled={loading}
                aria-label="ExportButton"
            >
                {loading ? <Spinner size={14} /> : <ExportIcon />}
            </StyledButton>
        </HeadlessDropdown>
    );
};

ExportDropdown.propTypes = {
    csvOptionsProvider: PropTypes.func,
};

export default ExportDropdown;
