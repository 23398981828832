'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _uuid = require('uuid');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _SmartListItemsContainer = require('./SmartListItemsContainer/SmartListItemsContainer');

var _SmartListItemsContainer2 = _interopRequireDefault(_SmartListItemsContainer);

var _ListHeader = require('./ListHeader/ListHeader');

var _HelpTooltip = require('../Tooltips/HelpTooltip/HelpTooltip');

var _HelpTooltip2 = _interopRequireDefault(_HelpTooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Created by oreuveni
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Date: 2019-01-23
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Time: 16:08
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */


var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'smartList__taboola-svg-icon___34A9c',
    'button-container': 'smartList__button-container___beUPz',
    'show-button': 'smartList__show-button___29kSn',
    'invisible-items-message': 'smartList__invisible-items-message___3JJWv',
    'help-icon': 'smartList__help-icon___3TPNu'
};


var classNameBuilder = _bind2.default.bind(styles);

var SmartList = function (_Component) {
    _inherits(SmartList, _Component);

    function SmartList() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, SmartList);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SmartList.__proto__ || Object.getPrototypeOf(SmartList)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            showAll: false
        }, _this.renderItems = function () {
            var _this$props = _this.props,
                renderItem = _this$props.renderItem,
                updateItem = _this$props.updateItem,
                deleteItem = _this$props.deleteItem,
                items = _this$props.items,
                shouldCollapseItems = _this$props.shouldCollapseItems,
                collapsedItemsLimit = _this$props.collapsedItemsLimit,
                limit = _this$props.limit;
            var showAll = _this.state.showAll;

            var itemsToRender = shouldCollapseItems && !showAll ? items.slice(0, collapsedItemsLimit) : items.slice(0, limit);

            return itemsToRender.map(function (item, index) {
                return _react2.default.createElement(
                    'li',
                    { key: item.id || (0, _uuid.v1)() },
                    _react2.default.cloneElement(renderItem, {
                        item: item,
                        index: index,
                        updateItem: updateItem,
                        deleteItem: deleteItem
                    })
                );
            });
        }, _this.renderShowButton = function (totalItems) {
            var showAll = _this.state.showAll;
            var _this$props2 = _this.props,
                showButtonClass = _this$props2.showButtonClass,
                items = _this$props2.items,
                shouldCollapseItems = _this$props2.shouldCollapseItems,
                collapsedItemsLimit = _this$props2.collapsedItemsLimit,
                showAllLabel = _this$props2.showAllLabel,
                showLessLabel = _this$props2.showLessLabel;

            var needButton = shouldCollapseItems && items.length > collapsedItemsLimit;

            return needButton && _react2.default.createElement(
                'li',
                {
                    key: 'show-more-button',
                    className: styles['button-container']
                },
                _react2.default.createElement(
                    'div',
                    {
                        role: 'button',
                        type: 'button',
                        tabIndex: 0,
                        onClick: function onClick() {
                            return _this.setState(function (prev) {
                                return { showAll: !prev.showAll };
                            });
                        },
                        className: classNameBuilder('show-button', { showButtonClass: showButtonClass })
                    },
                    showAll ? showLessLabel : _react2.default.createElement(
                        'span',
                        null,
                        ' ',
                        showAllLabel,
                        ' ',
                        ' (' + totalItems + ')',
                        ' '
                    )
                )
            );
        }, _this.renderInvisibleMessageWarning = function () {
            var showAll = _this.state.showAll;
            var _this$props3 = _this.props,
                items = _this$props3.items,
                limit = _this$props3.limit;

            var needMessage = items.length > limit && showAll;

            return needMessage && _react2.default.createElement(
                'li',
                {
                    key: 'invisible-items-message',
                    className: styles['invisible-items-message']
                },
                'Showing top ' + limit + ' items. ' + (items.length - limit) + ' additional items are available but not shown.',
                _react2.default.createElement(
                    _HelpTooltip2.default,
                    { iconClassName: styles['help-icon'] },
                    'Currently there is a limitation to the number of selected items we can display on the campaign form. The additional items are valid and will be used for targeting. For further assistance please contact your account manager or support.'
                )
            );
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(SmartList, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                children = _props.children,
                items = _props.items,
                addItem = _props.addItem,
                listHeaderTitle = _props.listHeaderTitle,
                listHeaderClass = _props.listHeaderClass,
                clearItems = _props.clearItems,
                clearAllLabel = _props.clearAllLabel;

            var filteredChildren = children && children.length ? children.filter(function (c) {
                return c;
            }) : children;

            return _react2.default.createElement(
                'div',
                null,
                _react2.default.Children.map(filteredChildren, function (child) {
                    if (child.type === _SmartListItemsContainer2.default) {
                        return _react2.default.createElement(
                            _react2.default.Fragment,
                            null,
                            items.length ? _react2.default.createElement(_ListHeader.ListHeader, {
                                listHeaderTitle: listHeaderTitle,
                                listHeaderClass: listHeaderClass,
                                clearItems: clearItems,
                                clearAllLabel: clearAllLabel,
                                totalItems: items.length
                            }) : null,
                            _react2.default.cloneElement(child, {
                                children: [].concat(_toConsumableArray(_this2.renderItems()), [_this2.renderInvisibleMessageWarning(), _this2.renderShowButton(items.length)])
                            })
                        );
                    }
                    return _react2.default.cloneElement(child, { addItem: addItem });
                })
            );
        }
    }]);

    return SmartList;
}(_react.Component);

SmartList.propTypes = {
    /** The children of the list, should include at least one of type SmartListItemsContainer, and a kind of input that receives via props: addItem(item). */
    children: _propTypes2.default.node,
    /** A React component that renders the list items, Receives via props: index, item, onItemChange(updatedItem), onItemDelete() */
    renderItem: _propTypes2.default.node,
    /** Fires when there is a change to the list of items with the updated items list */
    addItem: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    updateItem: _propTypes2.default.func,
    /** Fires when there is a change to the list of items with the updated items list */
    deleteItem: _propTypes2.default.func,
    /** Inital list of items */
    items: _propTypes2.default.array,
    /** List header title */
    listHeaderTitle: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** List header class */
    listHeaderClass: _propTypes2.default.string,
    /** Fires when there is a change to the list of items with the updated items list */
    clearItems: _propTypes2.default.func,
    /** If true, items over collapsedItemsLimit are hidden until button is clicked */
    shouldCollapseItems: _propTypes2.default.bool,
    /** number of items to show by default, adds button at bottom of list to show all the items */
    collapsedItemsLimit: _propTypes2.default.number,
    /** styling of 'show' button */
    showButtonClass: _propTypes2.default.string,
    /** showAll label */
    showAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    /** showLess label */
    showLessLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node]),
    limit: _propTypes2.default.number,
    /** Clear all text */
    clearAllLabel: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.node])
};

SmartList.defaultProps = {
    items: [],
    listHeaderClass: '',
    limit: 100,
    shouldCollapseItems: true,
    collapsedItemsLimit: 5,
    showAllLabel: 'Show all',
    showLessLabel: 'Show less'
};

exports.default = SmartList;