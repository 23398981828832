import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const useSpendGuard = isToggled => {
    const switchLabel = (
        <FormattedMessage
            id={isToggled ? 'campaign.editor.spend.guard.status.active' : 'campaign.editor.spend.guard.status.disable'}
            defaultMessage={isToggled ? 'SpendGuard is active' : 'SpendGuard is disable'}
        />
    );

    const title = (
        <FormattedMessage
            id={
                isToggled
                    ? 'campaign.editor.spend.guard.modal.title.disable'
                    : 'campaign.editor.spend.guard.modal.title.activate'
            }
            defaultMessage={isToggled ? 'Disable SpendGuard?' : 'Activate SpendGuard?'}
            tagName="h2"
        />
    );

    const submitButtonText = (
        <FormattedMessage
            id={
                isToggled
                    ? 'campaign.editor.spend.guard.modal.submit.button.disable'
                    : 'campaign.editor.spend.guard.modal.submit.button.activate'
            }
            defaultMessage={isToggled ? 'Disable' : 'Activate'}
        />
    );

    const cancelButtonText = <FormattedMessage id="campaign.editor.spend.guard.modal.cancel" defualtMessage="Cancel" />;

    const content = (
        <FormattedMessage
            id={
                isToggled
                    ? 'campaign.editor.spend.guard.modal.content.disable'
                    : 'campaign.editor.spend.guard.modal.content.activate'
            }
            defaultMessage={
                isToggled
                    ? 'Are you sure you want to disable SpendGuard for the campaign?\nThis may cause undesired spend on under performing sites.'
                    : 'Do you want to enable SpendGuard for this campaign?'
            }
        />
    );

    return { switchLabel, title, submitButtonText, cancelButtonText, content };
};
