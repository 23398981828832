import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { targetCpaInputValidations } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategy/TargetCpaRadio/targetCpaInputValidations';
import { useBidStrategyFormField } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategyV2/hooks/useBidStrategyFormField';
import { PERFORMANCE_RECOMMENDATIONS } from 'modules/campaigns/modules/performance-recommendations/config';
import { recommendationsMetadataSelector, recommendationsTypeSelector } from 'modules/recommendations/selectors';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useTargetCpaValidations = ({ additionalValidations, additionalDependencies }) => {
    const {
        [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]:
            numberOfConversionsInTimeframeForCompletion,
        [COMMON_FLAGS.LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS]: lookBackWindowForConversionsDays,
        [COMMON_FLAGS.TARGET_CPA_SIGNIFICANT_CHANGE_PERCENT]: tcpaSignificantChangePercent,
    } = useCommonConfig([
        COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION,
        COMMON_FLAGS.LOOKBACK_WINDOW_FOR_CONVERSIONS_DAYS,
        COMMON_FLAGS.TARGET_CPA_SIGNIFICANT_CHANGE_PERCENT,
    ]);
    const shouldDisplayWarnings = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' });
    const { value: targetCpaData } = useFormFieldValue({ field: 'targetCpaData' });

    const validations = useMemo(
        () => [...targetCpaInputValidations, ...additionalValidations],
        [additionalValidations]
    );
    const { value: bidStrategy } = useBidStrategyFormField();
    const recommendationType = useSelector(recommendationsTypeSelector);
    const recommendationMetadata = useSelector(recommendationsMetadataSelector);

    const recommendationDependencies = useMemo(() => {
        if (recommendationType === PERFORMANCE_RECOMMENDATIONS.UNREALISTIC_TCPA) {
            return { ...recommendationMetadata, ignoreSignificantChangeWarning: true };
        }

        return {};
    }, [recommendationType, recommendationMetadata]);

    const validationDependencies = {
        bidStrategy,
        minTargetCpa: targetCpaData?.minTargetCpa,
        maxTargetCpa: targetCpaData?.maxTargetCpa,
        shouldDisplayWarnings,
        tcpaSignificantChangePercent,
        minConversionsForTargetCpa: numberOfConversionsInTimeframeForCompletion / lookBackWindowForConversionsDays,
        ...recommendationDependencies,
        ...additionalDependencies,
    };

    return { validations, validationDependencies };
};
