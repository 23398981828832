import { isArray, isMatch, uniqueId } from 'lodash';
import { createReducer } from '../../utils/reducerUtils';
import updateObject from '../../utils/updateObject';
import {
    ADD_INDICATION,
    ADD_STATIC_INDICATION,
    REMOVE_INDICATION,
    REMOVE_STATIC_INDICATION,
    RESET_INDICATIONS,
} from '../actions';
import STATIC_INDICATION_TYPES from '../staticIndicationTypes';

const excludedFromReset = {
    [STATIC_INDICATION_TYPES.AD_BLOCKER_DETECTED]: true,
};

export const initialState = {
    indications: [],
    staticIndications: [],
};

const removeIndication = (state, query, indicationsFieldName) => {
    const removeQueries = isArray(query) ? query : [query];
    const updatedIndications = state[indicationsFieldName].filter(indication =>
        removeQueries.every(currQuery => !isMatch(indication, currQuery))
    );
    return updateObject(state, { [indicationsFieldName]: updatedIndications });
};

// TODO: consider moving this logic to somewhere else (middleware?) - DEV-28034
const indicationsReducer = createReducer(initialState, {
    [ADD_INDICATION]: (state, action) => {
        const { indications } = state;
        const indication = { id: uniqueId('indication'), ...action.payload };
        return updateObject(state, {
            indications: [...indications, indication],
        });
    },
    [REMOVE_INDICATION]: (state, { payload: query = {} }) => removeIndication(state, query, 'indications'),
    [REMOVE_STATIC_INDICATION]: (state, { payload: query = {} }) => removeIndication(state, query, 'staticIndications'),
    [ADD_STATIC_INDICATION]: (state, action) => {
        const { staticIndications } = state;

        return updateObject(state, { staticIndications: [...staticIndications, action.payload] });
    },
    [RESET_INDICATIONS]: state => {
        const { staticIndications: currentStaticIndications } = state;
        const staticIndications = currentStaticIndications.filter(
            staticIndication => excludedFromReset[staticIndication.staticIndicationType]
        );

        return updateObject(state, { ...initialState, ...{ staticIndications } });
    },
});

export default indicationsReducer;
