import { useMemo } from 'react';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FOCUS_TYPES } from '../../../../creative-editor/config';
import { THUMBNAIL_TRANSFORMATIONS } from '../../../config/thumbnailTransformations';
import useCtaTranslations from '../../../hooks/useCtaTranslations';

export const useCreativePreviewProcessedItems = (items, campaign) => {
    const intl = useIntl();
    const { accountName } = useAccount();
    const { getCtaText } = useCtaTranslations(accountName, campaign);

    const brandingPlaceholder = intl.formatMessage({
        id: 'creative.preview.branding.placeholder',
        defaultMessage: 'branding placeholder',
    });

    return useMemo(() => {
        return items.map(item => processItem(item, brandingPlaceholder, getCtaText, campaign));
    }, [items, getCtaText, campaign, brandingPlaceholder]);
};

const processItem = (item, brandingPlaceholder, getCtaText, campaign) => {
    const {
        title,
        description,
        cta,
        creativeId,
        brandingText,
        creativeFocus,
        id,
        url = '',
        logo,
        rating,
        creativeCrop,
    } = item;
    const ctaType = cta?.ctaType || cta || undefined;
    const { thumbnail, videoUrl } = getItemUrls(item);
    const { rating: appRating } = rating || {};
    const { logo: logoUrl } = logo || {};
    const { cropData } = creativeCrop || {};
    return {
        title,
        description: description || '',
        thumbnail,
        creativeId,
        itemType: 'is-syndicated',
        uploader: id,
        'branding-text': brandingText || campaign[0]?.brandingText || brandingPlaceholder,
        'additional-properties': {
            hideMe: false,
            placementUrl: url,
            type: 'video',
            'pvideo-url': videoUrl,
            'cta-text': getCtaText(ctaType, campaign[0]?.id),
            ...(logoUrl ? { 'branding-url': logoUrl } : {}),
            ...getThumbnailTransformations(creativeFocus || {}),
            ...isAppInstall(appRating),
            ...(cropData?.length ? { cropping: getCroppingTransformations(cropData) } : {}),
        },
    };
};

const getItemUrls = item => {
    let itemUrls = {
        videoUrl: item.videoUrl,
        thumbnail: item.thumbnailUrl,
    };
    if (item.fileType && item.fileType.includes('video')) {
        itemUrls.videoUrl = item.displayUrl;
        itemUrls.thumbnail = item.fallbackImageUrl;
    }
    if (item.performanceVideoData && item.performanceVideoData.videoUrl) {
        itemUrls.videoUrl = item.performanceVideoData.videoUrl;
        itemUrls.thumbnail = item.performanceVideoData.fallbackUrl;
    }
    return itemUrls;
};

const getThumbnailTransformations = (focus = {}) => {
    const { type, coordinates } = focus;
    const { x, y } = coordinates || {};
    const transformationPrefix = 'f_jpg,q_80,';
    const coordinatesFocus = transformationPrefix + THUMBNAIL_TRANSFORMATIONS.CREATIVE_FOCUS + `,x_${x},y_${y}`;
    const padding = transformationPrefix + THUMBNAIL_TRANSFORMATIONS.PADDING;

    if (type === FOCUS_TYPES.PADDING) {
        return {
            'thumbnail-transformations': padding,
        };
    }
    if (type === FOCUS_TYPES.COORDINATES) {
        return {
            'thumbnail-transformations': coordinatesFocus,
        };
    }

    return {};
};
const isAppInstall = appRating => {
    if (appRating) {
        return {
            'stars-rating': appRating,
            'is-app-install': 'true',
        };
    }
    return {};
};

const getCroppingTransformations = cropData => {
    if (cropData) {
        const transformedData = {
            crop: cropData.map(crop => ({
                ratio: {
                    w: crop.ratio.width,
                    h: crop.ratio.height,
                },
                area: {
                    x: crop.area.topLeftX,
                    y: crop.area.topLeftY,
                    w: crop.area.width,
                    h: crop.area.height,
                },
            })),
        };
        return JSON.stringify(transformedData);
    }
    return {};
};
