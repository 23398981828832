import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useCampaignsDropdownState } from './useCampaignsDropdownState';

export const useSharedBudgetCampaignsState = ({ marketingObjective, budgetType }) => {
    const { value: campaigns, onChange: onChangeCampaigns } = useFormFieldValue({ field: 'campaigns' });
    const { loadCampaigns, options, setCurrentOptions, isCampaignOptionDisabled } = useCampaignsDropdownState({
        campaigns,
        marketingObjective,
    });

    const handleAddCampaign = useCallback(
        selectedCampaignOption => {
            const { id, name, marketingObjective, status } = selectedCampaignOption;
            const campaignToAdd = {
                id,
                name,
                marketingObjective,
                budgetType,
                status,
            };

            onChangeCampaigns(prevCampaigns => [...prevCampaigns, campaignToAdd]);
        },
        [budgetType, onChangeCampaigns]
    );

    const handleRemoveCampaign = useCallback(
        ({ id: idToRemove }) => {
            onChangeCampaigns(prevCampaigns => prevCampaigns.filter(({ id }) => id !== idToRemove));
        },
        [onChangeCampaigns]
    );

    const updateCampaign = useCallback(
        ({ id, ...updatedCampaign }) => {
            onChangeCampaigns(campaigns =>
                campaigns.map(campaign => {
                    const { id: currCampaignId } = campaign;
                    if (currCampaignId !== id) {
                        return campaign;
                    }

                    return {
                        ...campaign,
                        ...updatedCampaign,
                    };
                })
            );
        },
        [onChangeCampaigns]
    );

    const handleRemoveAllCampaigns = useCallback(() => onChangeCampaigns([]), [onChangeCampaigns]);

    // This effect is used to update the budget type of existing campaigns when it changed
    useEffect(() => {
        if (isEmpty(campaigns)) {
            return;
        }

        const currCampaignBudgetType = campaigns[0].budgetType;
        if (currCampaignBudgetType === budgetType) {
            return;
        }

        onChangeCampaigns(
            campaigns.map(({ budgetType: currBudgetType, ...restCampaign }) => ({
                ...restCampaign,
                budgetType,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetType]);

    return {
        campaigns,
        loadCampaigns,
        handleAddCampaign,
        handleRemoveCampaign,
        handleRemoveAllCampaigns,
        updateCampaign,
        options,
        setCurrentOptions,
        budgetType,
        marketingObjective,
        isCampaignOptionDisabled,
    };
};
