import React, { useState, useEffect, useCallback, useRef } from 'react';
import { get, findLastIndex } from 'lodash';
import { aiGenerationApi } from 'services/api';
import { createImage } from '../../CroppingToolsForm/utils/getCropImage';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { Stage } from './Stage';
import { destructurePoints, updatePointsById } from './utils';

export const SegmentAnything = ({ bodyClassName, setSegmentMask }) => {
    const [points, setPoints] = useState([]);
    const [history, setHistory] = useState([]);
    const [maskError, setMaskError] = useState(false);
    const [image, setImage] = useState(null);
    const unmountedRef = useRef(false);
    const { accountId, campaignId, selectedImageUrl } = useCreativeStudioContext();

    const loadImage = useCallback(
        async url => {
            const { width, height } = await createImage(url.href, null);

            setImage({
                width,
                height,
                src: url.href,
            });
        },
        [setImage]
    );

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    useEffect(() => {
        const url = new URL(selectedImageUrl);
        loadImage(url);
    }, [selectedImageUrl, loadImage]);

    useEffect(() => {
        if (!image?.src || !points || points.length === 0) {
            return;
        }
        // for all points that don't have a mask or have errored, generate a mask
        // the mask is attached to the original point array so it is preserved for undos/redos
        // a low res version of the previous mask is also used to generate a mask
        let shouldSetPoints = false;
        const newPoints = points.map((point, index, pointsArray) => {
            const { mask, loading, id, error } = point;
            const lastLowResMask = get(pointsArray, `${index - 1}.lowResMask`, null);

            // If the mask has erred earlier, end early to avoid the API cost - it will be removed before the points are set
            if (loading || mask || maskError || error) {
                return point;
            }
            aiGenerationApi
                .getAIGeneratedSegmentAnything({
                    accountId,
                    campaignId,
                    imageUrl: image.src,
                    prevMask: lastLowResMask,
                    ...destructurePoints(points.slice(0, index + 1)),
                })
                .then(({ mask, lowResMask }) => {
                    if (unmountedRef.current) {
                        return;
                    }
                    const image = new Image();
                    image.src = mask;
                    setPoints(updatePointsById(id, { mask: image, loading: false, lowResMask }));
                })
                .catch(error => {
                    if (unmountedRef.current) {
                        return;
                    }
                    setMaskError(true);
                    setPoints(updatePointsById(id, { error: true, loading: false }));
                });
            shouldSetPoints = true;
            return { ...point, loading: true, error: null };
        });
        if (shouldSetPoints) {
            setPoints(newPoints);
        }
    }, [points, image, accountId, campaignId, maskError, setMaskError]);

    // When maskError is set to true and points is not empty
    // Find the last point that errored and removing everything from that point and after
    // Only do the above if errorIndex is not -1 so it doesn't trigger the useEffect infinitely from creating new empty arrays
    useEffect(() => {
        if (maskError && points) {
            const errorIndex = findLastIndex(points, point => point.error === true);
            if (errorIndex >= 0) {
                setPoints(prevPoints => prevPoints.slice(0, errorIndex));
            }
        }
    }, [maskError, points, setPoints]);

    useEffect(() => {
        if (points && points[points.length - 1]?.mask) {
            setSegmentMask(points[points.length - 1].mask.src);
        } else {
            setSegmentMask(null);
        }
    }, [points, setSegmentMask]);

    return (
        <Stage
            bodyClassName={bodyClassName}
            points={points}
            setPoints={setPoints}
            history={history}
            setHistory={setHistory}
            image={image}
            setImage={setImage}
            unmountedRef={unmountedRef}
            maskError={maskError}
            setMaskError={setMaskError}
        />
    );
};
