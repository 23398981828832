import { useDBStorage } from 'modules/taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { getBasePresetsDBKey } from './useColumnState';

export const useReportPresetsWithState = reportId => {
    const key = getBasePresetsDBKey(reportId);
    const { getter } = useDBStorage();
    const reportPresets = getter(key);

    return [reportPresets];
};
