import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { TuuiTheme } from 'tuui';
import { MobileContextProvider } from 'taboola-ultimate-ui';
import { abtEventEmitter } from 'modules/taboola-common-frontend-modules/ab-test/ABTEventEmitter';
import { ABTestProvider } from 'modules/taboola-common-frontend-modules/ab-test/ABTestProvider';
import { AccountProvider } from 'modules/taboola-common-frontend-modules/account-configurations/AccountProvider';
import { AppEventsProvider } from 'modules/taboola-common-frontend-modules/app-events-aggregator/AppEventsProvider';
import { PermissionsProvider } from 'modules/taboola-common-frontend-modules/authentication';
import { GoogleApiProvider } from 'modules/taboola-common-frontend-modules/googleApi';
import { I18nProvider } from 'modules/taboola-common-frontend-modules/i18n';
import { ModalProvider } from 'modules/taboola-common-frontend-modules/modals';
import { QueryParamBatcherProvider, QueryParamsProvider } from 'modules/taboola-common-frontend-modules/query-params';
import history from 'services/history';
import { QueryProvider } from 'services/query';
import App from './App';
import { CustomRouter } from './CustomRouter';
import config from './config';
import { ErrorBoundary, ErrorPage } from './modules/errors';
import { MultiLayerDrawerProvider } from './modules/multi-layer-drawer/components/MultiLayerDrawerProvider/MultiLayerDrawerProvider';
import { ChatAgentProvider } from './modules/taboola-common-frontend-modules/chat-agent/ChatAgentProvider';
import { useParentAppName } from './modules/taboola-common-frontend-modules/i18n/hooks/useParentAppName';
import Bootstrap from './services/bootstrap/bootstrapper';
import { getStore, initStore } from './store';
import { yahooTheme } from './tuuiYahooTheme';
import './globalStyle/index.scss';

const target = document.querySelector('#root');
const themeMap = {
    yahoo: yahooTheme,
};

const ProvidersWrapper = ({ children }) => {
    const themeName = useParentAppName();
    const tuuiTheme = themeMap[themeName];

    return (
        <QueryProvider>
            <Provider store={getStore()}>
                <CustomRouter history={history}>
                    <QueryParamBatcherProvider>
                        <QueryParamsProvider>
                            <PermissionsProvider>
                                <ABTestProvider eventEmitter={abtEventEmitter}>
                                    <GoogleApiProvider>
                                        <AccountProvider>
                                            <TuuiTheme theme={tuuiTheme}>
                                                <ModalProvider>
                                                    <I18nProvider>
                                                        <AppEventsProvider>
                                                            <ChatAgentProvider>
                                                                <MultiLayerDrawerProvider>
                                                                    <MobileContextProvider>
                                                                        <ErrorBoundary
                                                                            fallback={<ErrorPage config={config} />}
                                                                        >
                                                                            {children}
                                                                        </ErrorBoundary>
                                                                    </MobileContextProvider>
                                                                </MultiLayerDrawerProvider>
                                                            </ChatAgentProvider>
                                                        </AppEventsProvider>
                                                    </I18nProvider>
                                                </ModalProvider>
                                            </TuuiTheme>
                                        </AccountProvider>
                                    </GoogleApiProvider>
                                </ABTestProvider>
                            </PermissionsProvider>
                        </QueryParamsProvider>
                    </QueryParamBatcherProvider>
                </CustomRouter>
            </Provider>
        </QueryProvider>
    );
};

const ErrorFallback = () => (
    <ProvidersWrapper>
        <ErrorPage config={config} />
    </ProvidersWrapper>
);

const AppWrapper = () => (
    <ProvidersWrapper>
        <App />
    </ProvidersWrapper>
);

const runError = error => {
    // eslint-disable-next-line no-console
    console.error(error);
    initStore();
    render(<ErrorFallback />, target);
    window.history.pushState(null, 'error', '/error');
};

const runApp = () => {
    render(<AppWrapper />, target);
};

Bootstrap(initStore, history).then(runApp, runError);
