export const sharedBudgetAPIPrefix = (accountId, campaignsGroupId) =>
    `/${accountId}/shared-budgets${campaignsGroupId ? `/${campaignsGroupId}` : ''}`;

export const sharedBudgetApiFactory = ({ callGetApi, callPostApi, callPutApi }) => ({
    updateCampaignGroup: (accountId, campaignGroupId, changes) =>
        callPutApi(sharedBudgetAPIPrefix(accountId, campaignGroupId), changes),
    createCampaignGroup: (accountId, campaignsGroup) => callPostApi(sharedBudgetAPIPrefix(accountId), campaignsGroup),
    getCampaignsGroups: accountId => callGetApi(sharedBudgetAPIPrefix(accountId)),
    getCampaignGroup: (accountId, campaignsGroupId) => callGetApi(sharedBudgetAPIPrefix(accountId, campaignsGroupId)),
});
