import { isEmpty, keyBy, sortBy } from 'lodash';
import URI from 'urijs';

const RESOURCES_CACHE_TIME = 4 * 60 * 60 * 1000; // 4 hours

const authenticatedResourcesPrefix = resource => `/authenticated-resources/${resource}`;

export const createResourcesApi = ({ callGetApi, queryClient }) => {
    const api = {
        getOsList: () => callGetApi(authenticatedResourcesPrefix('operating-systems')),
        getBrowsers: () => callGetApi(authenticatedResourcesPrefix('browsers')),
        getPredefinedSupplyTargetingRestrictions: () =>
            callGetApi(authenticatedResourcesPrefix('supply-targeting-restrictions')),
        getBrandSafetyCategories: (accountId, brandSafetyType) =>
            callGetApi(
                authenticatedResourcesPrefix(
                    `${accountId}/external_brand_safety/categories?provider_name=${brandSafetyType}`
                )
            ),
        getTimeZones: () => callGetApi(authenticatedResourcesPrefix('timezones')),
        getLanguages: () => callGetApi(authenticatedResourcesPrefix('languages')),
        getCategories: () => callGetApi(authenticatedResourcesPrefix('categories')),
        getIabCategories: () => callGetApi(authenticatedResourcesPrefix('iab-categories')),
        getAdUnitTypes: () => callGetApi(authenticatedResourcesPrefix('list-ad-unit-types')),
        getConversionRuleTypes: () => callGetApi(authenticatedResourcesPrefix('conversion_rules/types')),
        getConversionCategories: () => callGetApi(authenticatedResourcesPrefix('conversion_rules/categories')),
        getConversionStatuses: () => callGetApi(authenticatedResourcesPrefix('conversion_rules/statuses')),
        getRepresentatives: ({ accountId, page, pageSize, searchText }) =>
            queryClient.fetchQuery(
                ['representatives', accountId, page, pageSize, searchText],
                () =>
                    callGetApi(authenticatedResourcesPrefix(`${accountId}/representatives`), {
                        page,
                        pageSize,
                        searchText,
                        paginationEnabled: true,
                    }),
                { staleTime: RESOURCES_CACHE_TIME }
            ),
        getApps: () => callGetApi(authenticatedResourcesPrefix('apps')),
        getPresets: async (searchText = '', page = 1, pageSize = 100) => {
            const presets = await queryClient.fetchQuery(
                ['presets', searchText, page, pageSize],
                async () => {
                    const presets = await callGetApi(authenticatedResourcesPrefix('presets'), {
                        searchText,
                        page,
                        pageSize,
                        paginationEnabled: true,
                    });
                    const { results, metadata } = presets;
                    const sortedPresets = sortBy(results, 'value');
                    return {
                        results: sortedPresets.map(({ value, name, id, ...rest }) => ({
                            label: value,
                            value,
                            id,
                            ...rest,
                        })),
                        metadata: {
                            total: metadata.total,
                        },
                    };
                },
                { staleTime: RESOURCES_CACHE_TIME }
            );

            presets.results.forEach(preset => {
                queryClient.setQueryData(['preset', preset.id], preset);
            });
            return presets;
        },
        getPresetsByIds: ids => {
            const url = URI(authenticatedResourcesPrefix('presets-by-ids'));
            if (ids && !isEmpty(ids)) {
                url.addSearch(ids);
                return callGetApi(url.toString());
            }
        },
        getViewabilityRates: () => callGetApi(authenticatedResourcesPrefix('viewability-rates')),
        getAccountManagers: (searchText, page, pageSize) =>
            callGetApi(authenticatedResourcesPrefix('account-managers'), {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
            }),
        getAccountManagerGroups: (searchText, page, pageSize) =>
            callGetApi(authenticatedResourcesPrefix('account-manager-groups'), {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
            }),
        getSalesPeople: (searchText, page, pageSize) =>
            callGetApi(authenticatedResourcesPrefix('sales-people'), {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
            }),
        getSalesGroups: (searchText, page, pageSize) =>
            callGetApi(authenticatedResourcesPrefix('sales-groups'), {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
            }),
        getTargetPageType: () => callGetApi(authenticatedResourcesPrefix('target-page-types')),
        getHistoryLogActivityCodes: () => callGetApi(authenticatedResourcesPrefix('history-log-activity-codes')),
        getCountries: async (searchText = '', page = 1, pageSize = 300) => {
            const countries = await queryClient.fetchQuery(
                ['countries', page, pageSize],
                async () => {
                    const countries = sortBy(
                        await callGetApi(authenticatedResourcesPrefix('countries'), {
                            searchText: '',
                            page,
                            pageSize,
                            paginationEnabled: true,
                        }),
                        'value'
                    );
                    return {
                        results: countries,
                        metadata: {
                            total: countries.length,
                            count: countries.length,
                        },
                    };
                },
                { staleTime: RESOURCES_CACHE_TIME }
            );
            countries.results.forEach(country => {
                queryClient.setQueryData(['country', country.name], country);
            });
            if (searchText) {
                const filteredResults = countries.results.filter(c =>
                    c.value.toLowerCase().includes(searchText.toLowerCase())
                );
                return {
                    metadata: {
                        total: filteredResults.length,
                        count: filteredResults.length,
                    },
                    results: filteredResults,
                };
            }
            return countries;
        },
    };
    api.getCountriesByCodes = async codes => {
        const { results: countries } = await api.getCountries('', 1, 300);
        const codesSet = new Set(codes);
        const countriesByCode = keyBy(
            countries.filter(country => codesSet.has(country.name)),
            'name'
        );
        return codes.map(code => countriesByCode[code]);
    };

    return api;
};
