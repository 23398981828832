import { clearHasUnsavedChanges } from 'actions';
import { saveCreativeError } from 'modules/campaigns/actions/index';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import creativesApi from 'services/api/creativesApi';
import { generateErrorIndication } from 'services/utils';
import { isMediaLibraryItem } from '../components/MediaTabs';
import { getUnifiedCreativeObject } from '../utils/unifiedCreativeTransformationUtils';
import { errorMessage, successIndication } from './duplicateCreativeCommon';
import saveFocalPoints from './saveFocalPoints';
import uploadCreativeRecommendedImage from './uploadCreativeRecommendedImage';
import uploadCreativeThumbnail from './uploadCreativeThumbnail';

const getThumbnailUrl = async (dispatch, accountId, initialCreative) => {
    const { thumbnailUrl, thumbnailFile, thumbnailRecommendedImage } = initialCreative;

    if (thumbnailFile) {
        return dispatch(uploadCreativeThumbnail(accountId, thumbnailFile));
    }

    if (!thumbnailRecommendedImage) {
        return { thumbnailUrl };
    }

    if (isMediaLibraryItem(thumbnailRecommendedImage)) {
        return thumbnailRecommendedImage;
    }

    return dispatch(uploadCreativeRecommendedImage(accountId, thumbnailRecommendedImage));
};

export const duplicateUnifiedCreative =
    (
        { accountId: originalCreativeAccountId, selectedAccount: { accountId: targetAccountId } },
        creativeFormData,
        matchingCreativeIds = []
    ) =>
    async dispatch => {
        const { selectedCampaigns = [], ...initialCreative } = creativeFormData;
        const { error: focalPointsSaveError } = await dispatch(
            saveFocalPoints(initialCreative, originalCreativeAccountId, matchingCreativeIds)
        );

        if (focalPointsSaveError) {
            dispatch(saveCreativeError(initialCreative.id, focalPointsSaveError));
            return;
        }

        const { thumbnailUrl, error: uploadError } = await getThumbnailUrl(
            dispatch,
            originalCreativeAccountId,
            initialCreative
        );

        if (uploadError) {
            dispatch(saveCreativeError(initialCreative.id, uploadError));
            return;
        }

        const unifiedCreative = getUnifiedCreativeObject(initialCreative, thumbnailUrl);

        try {
            const result = await creativesApi.createCombinedCreatives(targetAccountId, true, {
                campaignIds: selectedCampaigns.map(({ value }) => value),
                staticImagePojo: [unifiedCreative],
            });
            dispatch(clearHasUnsavedChanges());
            dispatch(addIndication(successIndication));

            return result;
        } catch (error) {
            dispatch(saveCreativeError(error));
            dispatch(addIndication(generateErrorIndication(error, errorMessage)));
            throw error;
        }
    };
