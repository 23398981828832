import React from 'react';
import PropTypes from 'prop-types';
import { Input, LinkButton, HelpTooltip } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { AdvancedPreview } from '../AdvancedPreview/AdvancedPreview';
import styles from './onSitePreviewModalContent.module.scss';

const OnSitePreviewModalContent = ({ url, changePreviewUrl, handleCopy }) => {
    const isAdvancedPreviewSupported = useConfigMatch({
        [FEATURE_FLAGS.SUPPORT_ADVANCED_PREVIEW]: 'true',
    });
    return (
        <div>
            {isAdvancedPreviewSupported === true && (
                <>
                    <AdvancedPreview />
                    <div className={styles['preview-divider']}>OR</div>
                </>
            )}
            <FormattedMessage
                id="app.modal.onSitePreview.content"
                defaultMessage="See how your ad will appear on a real Taboola Feed. You can either use our demo page, or enter any URL of a page with Taboola Feed or Widget on it."
            />
            <div className={styles['preview-url-input']}>
                <Input value={url} onChange={e => changePreviewUrl(e.target.value)} size="large" />
            </div>
            <div className={styles['note-text']}>
                <FormattedMessage
                    id="app.modal.onSitePreview.content.note"
                    defaultMessage="Note: Currently site preview is not supported by some of our publishers including MSN."
                />
            </div>
            <div className={styles['copy-parameters-container']}>
                <LinkButton onClick={handleCopy}>
                    <FormattedMessage id="app.modal.onSitePreview.copyParameters" defaultMessage="Copy Parameters" />
                </LinkButton>
                <HelpTooltip
                    content={
                        <FormattedMessage
                            id="app.modal.onSitePreview.copyParameters.tooltip"
                            defaultMessage="Manually paste the Preview Parameters at the end of the site URL in your browser's address bar. The previewed creative will appear on Taboola Feed or Widget on the page."
                        />
                    }
                />
            </div>
        </div>
    );
};

OnSitePreviewModalContent.propTypes = {
    url: PropTypes.string,
    changePreviewUrl: PropTypes.func,
    handleCopy: PropTypes.func,
};

export default OnSitePreviewModalContent;
