import React, { useCallback } from 'react';
import { DropdownMenu, FormField } from 'taboola-ultimate-ui';
import { useDropdownOptions } from 'hooks';
import { withIndication } from 'modules/errors/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './PredicateTypeDropDown.module.scss';

export const FILTER_TYPE = {
    LESS_GREATER_EQUALS: 'LESS_GREATER_EQUALS',
    EQUALS_NOT_EQUALS: 'EQUALS_NOT_EQUALS',
    CONTAINS_NOT_CONTAINS: 'CONTAINS_NOT_CONTAINS',
};

export const PREDICATE_TYPE = {
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    EQUALS: 'EQUALS',
    NOT_EQUALS: 'NOT_EQUALS',
    CONTAINS: 'CONTAINS',
    NOT_CONTAINS: 'NOT_CONTAINS',
};
export const operatorMap = {
    [FILTER_TYPE.LESS_GREATER_EQUALS]: [
        { value: PREDICATE_TYPE.GREATER_THAN },
        { value: PREDICATE_TYPE.LESS_THAN },
        { value: PREDICATE_TYPE.EQUALS },
    ],
    [FILTER_TYPE.EQUALS_NOT_EQUALS]: [{ value: PREDICATE_TYPE.EQUALS }, { value: PREDICATE_TYPE.NOT_EQUALS }],
    [FILTER_TYPE.CONTAINS_NOT_CONTAINS]: [{ value: PREDICATE_TYPE.CONTAINS }, { value: PREDICATE_TYPE.NOT_CONTAINS }],
};

const FormFieldWithIndication = withIndication(FormField, styles['predicate-type-field']);
const predicateTypeDropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        options: { min: 1 },
        messageId: 'validations.error.performance.rules.predicate.Type.no.selection',
        defaultMessage: 'Please select a Predicate Type',
    },
];
const formFieldId = 'predicateType-selector';
export const PredicateTypeDropDown = ({ predicateGroupType }) => {
    const {
        value: predicateType,
        onChange: predicateTypeOnChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'predicateType',
        validations: predicateTypeDropdownValidations,
        hasInitialData: false,
    });

    const handleOperatorDropDownSelection = useCallback(
        ({ value: operator }) => {
            predicateTypeOnChange(operator);
        },
        [predicateTypeOnChange]
    );

    const options = useDropdownOptions(operatorMap[predicateGroupType], 'performance.rules.setup.predicate.type');

    return (
        <FormFieldWithIndication inputId={formFieldId} label=" " {...indicationData}>
            <DropdownMenu
                id={formFieldId}
                required
                placeholder={
                    <FormattedMessage
                        id="performance.rules.setup.metric.placeholder"
                        defaultMessage="Click to Select"
                    />
                }
                selectedValueObject={{ value: predicateType }}
                options={options}
                onChange={handleOperatorDropDownSelection}
                searchable
                dropdownWrapperClassName={styles['operator-select']}
                aria-label="predicate-dropdown"
            />
        </FormFieldWithIndication>
    );
};
