import moment from 'moment-timezone';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import createCSVValueGetter from './createCSVValueGetter';
import createGridValueFormatter from './createGridValueFormatter';

export const dateFormats = {
    DATE_TIME_MINUTE_PRECISION: 'YYYY-MM-DD hh:mm',
};

// We need time zone converting because globally moment default time zone is selected account time zone
// not always the same as for context (grid, form) account
export const getDateWithTimeZone = (date, timeZone, emptyValue) => {
    if (!date) {
        return emptyValue;
    }

    return timeZone ? moment.tz(date, timeZone) : moment(date);
};

const dateFormatter = ({ value, i18n, rowData: { accountTimeZoneName } = {} }) => {
    if (!value) {
        return EMPTY_FIELD_VALUE;
    }
    const m = getDateWithTimeZone(value, accountTimeZoneName);
    const options = {
        timeZone: m.tz(),
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };

    return i18n.formatDate(m, options);
};

//do not use. pass the time on accountTimeZone from the bs (instead of utcTime)
const dateTimeFormatter = ({ value, i18n, rowData: { accountTimeZoneName } = {} }) => {
    if (!value) {
        return EMPTY_FIELD_VALUE;
    }
    const m = getDateWithTimeZone(value, accountTimeZoneName);
    const options = {
        timeZone: m.tz(),
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };

    const timeInAccountTimeZone = moment.utc(value).tz(accountTimeZoneName);
    return i18n.formatDate(timeInAccountTimeZone, options);
};
// TODO DEV-77292 Develop generic approach for time zone graph+grid
// This patch for urgent quick solution while switching day report to campaign summary, need to be found more generic solution
export const utcDateFormatter = ({ value, i18n }) =>
    dateFormatter({
        value: value ? moment.utc(value).format('YYYY-MM-DD') : value,
        i18n,
    });

const gridDateTimeFormatter = createGridValueFormatter(dateTimeFormatter);
const gridDateFormatter = createGridValueFormatter(dateFormatter);
const gridUTCDateFormatter = createGridValueFormatter(utcDateFormatter);
const formattedUTCDateValueGetter = createCSVValueGetter(utcDateFormatter);
const formattedDateValueGetter = createCSVValueGetter(dateFormatter);

export {
    gridDateFormatter,
    gridUTCDateFormatter,
    formattedUTCDateValueGetter,
    formattedDateValueGetter,
    gridDateTimeFormatter,
};
export default dateFormatter;
