'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactScroll = require('react-scroll');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var WizardStep = function WizardStep(_ref) {
    var children = _ref.children,
        id = _ref.id,
        wrapperClassName = _ref.wrapperClassName,
        className = _ref.className;
    return _react2.default.createElement(
        _reactScroll.Element,
        { name: id, className: wrapperClassName },
        _react2.default.createElement(
            'div',
            { className: className },
            children
        )
    );
}; /**
    * Created by oreuveni
    * Date: 2018-11-22
    * Time: 17:16
    */


WizardStep.propTypes = {
    /** The text in the navigation link */
    label: _propTypes2.default.node.isRequired,
    /** Necessary for navigation, must be unique */
    id: _propTypes2.default.string.isRequired,
    /** Content of the Wizard Step */
    children: _propTypes2.default.node,
    wrapperClassName: _propTypes2.default.string,
    className: _propTypes2.default.string
};

exports.default = WizardStep;