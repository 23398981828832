import React, { Fragment } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown, CheckboxGroup } from 'taboola-ultimate-ui';
import ProviderOption from '../ProviderOption/ProviderOption';
import ProvidersSelectIcon from '../ProvidersSelectIcon/ProvidersSelectIcon';

const dropDownStyle = {
    valueContainer: provided => ({
        ...provided,
        width: 0,
    }),
    menu: provided => ({
        ...provided,
        width: '300px',
        right: 0,
    }),
};

const ProvidersDropdown = ({ selectedValues = [], options, onChange }) => {
    const handleSelect = provider => onChange([...selectedValues, provider]);
    const handleDeselect = provider => onChange(selectedValues.filter(prevProvider => prevProvider !== provider));

    return (
        <CheckboxGroup
            id="providers-targeting"
            selectedValues={selectedValues}
            onSelectCheckbox={handleSelect}
            onUnselectCheckbox={handleDeselect}
        >
            <BareDropdown
                id="providers-dropdown"
                options={options}
                onChange={noop}
                placeholder={<Fragment />}
                arrowRenderer={ProvidersSelectIcon}
                controlShouldRenderValue={false}
                optionItemRenderer={ProviderOption}
                closeMenuOnSelect={false}
                searchable={false}
                skipLeftBorderRadius
                styles={dropDownStyle}
            />
        </CheckboxGroup>
    );
};

ProvidersDropdown.propTypes = {
    options: PropTypes.array,
    selectedValues: PropTypes.array,
    selectedAccountId: PropTypes.string,
    campaign: PropTypes.object,
    onChange: PropTypes.func,
};

export default ProvidersDropdown;
