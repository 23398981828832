import React from 'react';
import PropTypes from 'prop-types';
import { BudgetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData/hooks';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { SectionHeader } from '../../../../components/Form';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import BudgetField from '../Budget/BudgetField';
import BusinessModelField from '../BusinessModel/BusinessModelField';
import DailyRevenueCapField from '../DailyRevenueCap/DailyRevenueCapField';
import { DeliveryMethod } from '../DeliveryMethod/DeliveryMethod';
import RateField from '../Rate/RateField';

export const BudgetSection = ({ account }) => {
    const { value: businessModel } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });

    const rateComponent = (
        <RateField
            currency={account.currency}
            label={<FormattedMessage id="video.campaign.editor.rate.title" defaultMessage="Rate" />}
        />
    );

    return (
        <CommonCollapsibleCard
            id="VIDEO_CAMPAIGNS_BUDGET"
            header={
                <SectionHeader
                    headerIcon={<BudgetIcon />}
                    headerText={
                        <FormattedMessage id="campaign.editor.budget.and.bidding" defaultMessage="Budget and Bidding" />
                    }
                />
            }
        >
            <BusinessModelField />
            {businessModel === VIDEO_PRICING_MODEL.CPCV && (
                <div>
                    <BudgetField currency={account.currency} />
                    {rateComponent}
                </div>
            )}
            {businessModel === VIDEO_PRICING_MODEL.VCPM && (
                <div>
                    <BudgetField currency={account.currency} />
                    <DeliveryMethod />
                    <DailyRevenueCapField currency={account.currency} />
                    {rateComponent}
                </div>
            )}
            {businessModel === VIDEO_PRICING_MODEL.CPM && (
                <div>
                    <BudgetField currency={account.currency} />
                    <DeliveryMethod />
                    <DailyRevenueCapField currency={account.currency} />
                    {rateComponent}
                </div>
            )}
        </CommonCollapsibleCard>
    );
};

BudgetSection.propTypes = {
    account: PropTypes.object,
};

export default BudgetSection;
