'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.debounce');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var withDebounce = function withDebounce(WrappedComponent) {
    var ComponentWithDebounce = function (_PureComponent) {
        _inherits(ComponentWithDebounce, _PureComponent);

        _createClass(ComponentWithDebounce, null, [{
            key: 'getDerivedStateFromProps',
            value: function getDerivedStateFromProps(nextProps, state) {
                if (state.value !== state.prevValue) {
                    return {
                        prevValue: state.value
                    };
                }

                return {
                    value: nextProps.value,
                    prevValue: nextProps.value
                };
            }
        }]);

        function ComponentWithDebounce(props) {
            _classCallCheck(this, ComponentWithDebounce);

            var _this = _possibleConstructorReturn(this, (ComponentWithDebounce.__proto__ || Object.getPrototypeOf(ComponentWithDebounce)).call(this, props));

            _this.onChange = function (e) {
                var onChange = _this.props.onChange;


                onChange(e);
            };

            _this.handleChange = function (e) {
                if (e.persist) {
                    e.persist();
                }

                var newValue = e.target.value;
                _this.setState({ value: newValue }, function () {
                    return _this.debounceOnChange(e);
                });
            };

            var value = props.value,
                debounceMillis = props.debounceMillis;


            _this.state = {
                value: value,
                prevValue: value
            };

            _this.debounceOnChange = (0, _lodash2.default)(_this.onChange, debounceMillis);
            return _this;
        }

        _createClass(ComponentWithDebounce, [{
            key: 'render',
            value: function render() {
                var _props = this.props,
                    debounceMillis = _props.debounceMillis,
                    rest = _objectWithoutProperties(_props, ['debounceMillis']);

                var value = this.state.value;


                return _react2.default.createElement(WrappedComponent, Object.assign({}, rest, { value: value, onChange: this.handleChange }));
            }
        }]);

        return ComponentWithDebounce;
    }(_react.PureComponent);

    ComponentWithDebounce.classNames = WrappedComponent.classNames;

    ComponentWithDebounce.propTypes = {
        value: _propTypes2.default.any,
        onChange: _propTypes2.default.func.isRequired,
        debounceMillis: _propTypes2.default.number
    };

    ComponentWithDebounce.defaultProps = {
        value: '',
        debounceMillis: 0
    };

    return ComponentWithDebounce;
};

exports.default = withDebounce;