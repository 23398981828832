import React from 'react';
import { useNavigate } from 'hooks';
import { BILLING_AND_PAYMENTS_ROUTE } from '../../../../../modules/billing-and-payments';
import { FormattedRelativeTime } from '../../../../../modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';

export const PaidPrepaymentsNotification = ({ notification, closeNotificationCenter, timeDiffInMinutes }) => {
    const navigate = useNavigate();

    return (
        <NotificationCard
            notification={notification}
            cardClick={() => navigate(BILLING_AND_PAYMENTS_ROUTE)}
            closeNotificationCenter={closeNotificationCenter}
            formattedMessageId="app.notificationsCenter.timeAgo-message"
            formattedMessageValues={{
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
        />
    );
};
