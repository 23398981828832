'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CustomLineDot = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _isNil = require('lodash/isNil');

var _isNil2 = _interopRequireDefault(_isNil);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var emptyData = [];

var CustomLineDot = exports.CustomLineDot = function CustomLineDot(_ref) {
    var cx = _ref.cx,
        cy = _ref.cy,
        fill = _ref.fill,
        stroke = _ref.stroke,
        value = _ref.value,
        index = _ref.index,
        dataKey = _ref.dataKey,
        isReference = _ref.isReference,
        isPreview = _ref.isPreview,
        label = _ref.label,
        _ref$data = _ref.data,
        data = _ref$data === undefined ? emptyData : _ref$data;

    if ((0, _isNil2.default)(value)) {
        return null;
    }

    if (isReference && isPreview && index === 0) {
        return _react2.default.createElement(
            'svg',
            {
                x: cx - 4,
                y: cy - 10,
                width: '45',
                height: '20',
                viewBox: '0 0 45 20',
                fill: 'black',
                xmlns: 'http://www.w3.org/2000/svg'
            },
            _react2.default.createElement('rect', { width: '45', height: '20', rx: '6', fill: stroke }),
            _react2.default.createElement(
                'text',
                { fontSize: 'smaller', textAnchor: 'middle', x: '22', y: '14', fill: 'white' },
                label
            )
        );
    }

    if ((0, _isNil2.default)((0, _lodash2.default)(data[index - 1], dataKey)) && (0, _isNil2.default)((0, _lodash2.default)(data[index + 1], dataKey))) {
        return _react2.default.createElement(
            'svg',
            {
                x: cx - 4,
                y: cy - 4,
                xmlns: 'http://www.w3.org/2000/svg',
                width: '8',
                height: '8',
                viewBox: '0 0 8 8',
                fill: fill
            },
            _react2.default.createElement('circle', { cx: '4', cy: '4', r: '3', fill: 'white', stroke: stroke, strokeWidth: 2 })
        );
    }

    return null;
};

CustomLineDot.propTypes = {
    /** coordinate X */
    cx: _propTypes2.default.number,
    /** coordinate Y */
    cy: _propTypes2.default.number,
    /** parent fill color */
    fill: _propTypes2.default.string,
    /** parent stroke */
    stroke: _propTypes2.default.string,
    /** index of element */
    index: _propTypes2.default.number,
    /** Graph data */
    data: _propTypes2.default.array,
    /** dataKey */
    dataKey: _propTypes2.default.string,
    /** Value */
    value: _propTypes2.default.number,
    /** label of element */
    label: _propTypes2.default.string,
    /** flag for reference line */
    isReference: _propTypes2.default.bool,
    /** flag for preview mode */
    isPreview: _propTypes2.default.bool
};