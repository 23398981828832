import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { GenerateDetailsButton } from '../SetupSection/GenerateDetailsButton';
import styles from './helpWriteFooter.module.scss';

const classNameBuilder = classnames.bind(styles);

export const HelpWriteFooter = ({
    isGenerating,
    setGenerationStatus,
    isCrawling,
    campaignId,
    onSuccess,
    onError,
    onCancel,
    hasProductDetails,
}) => {
    const isGenerateDisabled = !hasProductDetails || isCrawling;
    return (
        <div className={`${styles['container']}`}>
            <div className={styles['action-buttons']}>
                <Button
                    className={classNameBuilder('button', 'cancel')}
                    onClick={onCancel}
                    size={Button.size.md}
                    variant={Button.variant.ghost}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="CancelButton"
                    data-automation="FormCancelButton"
                >
                    <FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />
                </Button>
                <GenerateDetailsButton
                    isGenerating={isGenerating}
                    setGenerationStatus={setGenerationStatus}
                    disabled={isGenerateDisabled}
                    campaignId={campaignId}
                    onSuccess={onSuccess}
                    onError={onError}
                />
            </div>
        </div>
    );
};
