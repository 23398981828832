import immer from 'immer';
import { isEqual, merge, mergeWith } from 'lodash';

const isObjectVal = value => value && typeof value === 'object' && !(value instanceof Array);

export const deepMerger = (oldVal, newVal, customizer) => {
    if (isObjectVal(oldVal) && isObjectVal(newVal) && !isEqual(newVal, {})) {
        return mergeWith(oldVal, newVal, customizer);
    }
    return newVal;
};

const mergeCustomizer = (oldVal, newVal) => deepMerger(oldVal, newVal, mergeCustomizer);

// Default merge customizer apart of primitives overwrites new arrays and empty objects
export const customMergeWith = (object, patch, customizer = mergeCustomizer) => mergeWith(object, patch, customizer);

export const immutableMerge = (object, patch) => immer(object, draft => merge(draft, patch));

export const immutableMergeWith = (object, patch, customizer = mergeCustomizer) =>
    immer(object, draft => customMergeWith(draft, patch, customizer));
