import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';

const REPORT_GROUP_TYPE = {
    TIME: 'time-group',
    GEO: 'geo-group',
    PLATFORM: 'platform-group',
    REP: 'rep-group',
    AM: 'am-group',
    CAMPAIGN: 'campaign-group',
    AD: 'ads',
};

const reportsGroupConfig = {
    [REPORT_GROUP_TYPE.TIME]: {
        label: 'Day',
        id: REPORT_GROUP_TYPE.TIME,
        options: [
            reportsBaseConfig[REPORT_TYPE.DAY],
            reportsBaseConfig[REPORT_TYPE.DAY_VIDEO],
            reportsBaseConfig[REPORT_TYPE.WEEK],
            reportsBaseConfig[REPORT_TYPE.WEEK_VIDEO],
            reportsBaseConfig[REPORT_TYPE.MONTH],
            reportsBaseConfig[REPORT_TYPE.MONTH_VIDEO],
            reportsBaseConfig[REPORT_TYPE.DAY_OF_WEEK],
            reportsBaseConfig[REPORT_TYPE.DAY_OF_WEEK_VIDEO],
            reportsBaseConfig[REPORT_TYPE.HOUR_OF_DAY],
            reportsBaseConfig[REPORT_TYPE.HOUR_OF_DAY_VIDEO],
        ],
    },
    [REPORT_GROUP_TYPE.GEO]: {
        label: 'Country',
        id: REPORT_GROUP_TYPE.GEO,
        options: [
            reportsBaseConfig[REPORT_TYPE.COUNTRY],
            reportsBaseConfig[REPORT_TYPE.COUNTRY_VIDEO],
            reportsBaseConfig[REPORT_TYPE.COUNTRY_REALTIME],
            reportsBaseConfig[REPORT_TYPE.CITY_VIDEO],
            reportsBaseConfig[REPORT_TYPE.REGION],
            reportsBaseConfig[REPORT_TYPE.DMA],
            reportsBaseConfig[REPORT_TYPE.DMA_VIDEO],
        ],
    },
    [REPORT_GROUP_TYPE.PLATFORM]: {
        label: 'Platforms',
        id: REPORT_GROUP_TYPE.PLATFORM,
        options: [
            reportsBaseConfig[REPORT_TYPE.PLATFORM],
            reportsBaseConfig[REPORT_TYPE.PLATFORM_VIDEO],
            reportsBaseConfig[REPORT_TYPE.PLATFORM_REALTIME],
            reportsBaseConfig[REPORT_TYPE.OS_FAMILY],
            reportsBaseConfig[REPORT_TYPE.OS_FAMILY_VIDEO],
            reportsBaseConfig[REPORT_TYPE.OS_VERSION],
            reportsBaseConfig[REPORT_TYPE.OS_VERSION_VIDEO],
            reportsBaseConfig[REPORT_TYPE.BROWSER],
            reportsBaseConfig[REPORT_TYPE.BROWSER_VIDEO],
        ],
    },
    [REPORT_GROUP_TYPE.AM]: {
        label: 'AM',
        id: REPORT_GROUP_TYPE.AM,
        options: [
            reportsBaseConfig[REPORT_TYPE.ACCOUNT_MANAGER],
            reportsBaseConfig[REPORT_TYPE.ACCOUNT_MANAGER_VIDEO],
            reportsBaseConfig[REPORT_TYPE.SALES_PERSON],
            reportsBaseConfig[REPORT_TYPE.SALES_PERSON_VIDEO],
            reportsBaseConfig[REPORT_TYPE.SALES_GROUP],
            reportsBaseConfig[REPORT_TYPE.SALES_GROUP_VIDEO],
            reportsBaseConfig[REPORT_TYPE.ACCOUNT_MANAGER_GROUP],
            reportsBaseConfig[REPORT_TYPE.ACCOUNT_MANAGER_GROUP_VIDEO],
        ],
    },
    [REPORT_GROUP_TYPE.CAMPAIGN]: {
        label: 'Campaigns',
        id: REPORT_GROUP_TYPE.CAMPAIGN,
        options: [
            reportsBaseConfig[REPORT_TYPE.CAMPAIGN],
            reportsBaseConfig[REPORT_TYPE.CAMPAIGN_REALTIME],
            reportsBaseConfig[REPORT_TYPE.ADVERTISER_DOMAIN],
            reportsBaseConfig[REPORT_TYPE.ATTRIBUTION_GROUP],
        ],
    },
    [REPORT_GROUP_TYPE.AD]: {
        label: 'Ads',
        id: REPORT_GROUP_TYPE.AD,
        options: [
            reportsBaseConfig[REPORT_TYPE.CREATIVE],
            reportsBaseConfig[REPORT_TYPE.DCO_CAMPAIGN_CREATIVE],
            reportsBaseConfig[REPORT_TYPE.DCO_ITEM_PERFORMANCE],
        ],
    },
};

export default [
    reportsBaseConfig[REPORT_TYPE.TODAY],
    reportsBaseConfig[REPORT_TYPE.PIXEL_BASED],
    reportsBaseConfig[REPORT_TYPE.FIRST_PARTY],
    reportsBaseConfig[REPORT_TYPE.CUSTOM_CONTEXTUAL],
    reportsBaseConfig[REPORT_TYPE.PREDICTIVE],
    reportsGroupConfig[REPORT_GROUP_TYPE.TIME],
    reportsBaseConfig[REPORT_TYPE.ACCOUNT],
    reportsBaseConfig[REPORT_TYPE.ACCOUNT_VIDEO],
    reportsBaseConfig[REPORT_TYPE.CAMPAIGNS_GROUPS],
    reportsGroupConfig[REPORT_GROUP_TYPE.CAMPAIGN],
    reportsBaseConfig[REPORT_TYPE.CAMPAIGN_VIDEO],
    reportsGroupConfig[REPORT_GROUP_TYPE.AD],
    reportsBaseConfig[REPORT_TYPE.CREATIVE_VIDEO],
    reportsBaseConfig[REPORT_TYPE.CREATIVE_REALTIME],
    reportsBaseConfig[REPORT_TYPE.QUARTILE_VIDEO],
    reportsBaseConfig[REPORT_TYPE.SITE],
    reportsBaseConfig[REPORT_TYPE.SITE_REALTIME],
    reportsBaseConfig[REPORT_TYPE.SITE_VIDEO],
    reportsGroupConfig[REPORT_GROUP_TYPE.GEO],
    reportsGroupConfig[REPORT_GROUP_TYPE.PLATFORM],
    reportsBaseConfig[REPORT_TYPE.AUDIENCE],
    reportsBaseConfig[REPORT_TYPE.AUDIENCE_BUNDLES],
    reportsBaseConfig[REPORT_TYPE.CONVERSIONS],
    reportsBaseConfig[REPORT_TYPE.CONTEXTUAL],
    reportsBaseConfig[REPORT_TYPE.CONTEXTUAL_VIDEO],
    reportsBaseConfig[REPORT_TYPE.PAYMENTS],
    reportsBaseConfig[REPORT_TYPE.INVOICES],
    reportsBaseConfig[REPORT_TYPE.CAMPAIGN_HISTORY],
    reportsGroupConfig[REPORT_GROUP_TYPE.AM],
    reportsBaseConfig[REPORT_TYPE.FUNNELS],
    reportsBaseConfig[REPORT_TYPE.ACTIVE_EVENTS],
    reportsBaseConfig[REPORT_TYPE.COMBINED_AUDIENCES],
    reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULES],
    reportsBaseConfig[REPORT_TYPE.PERFORMANCE_RULE_EXECUTED_ACTIONS],
    reportsBaseConfig[REPORT_TYPE.SCHEDULED_REPORTS],
    reportsBaseConfig[REPORT_TYPE.HIERARCHY_CAMPAIGN],
];
