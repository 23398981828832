import React from 'react';
import { StripeProvider } from '../../../../taboola-common-frontend-modules/stripe/components/StripeProvider';
import { PAYMENT_PROVIDER_NAME } from '../../../config/PaymentProviderName';
import { useBillingData } from '../../../hooks/useBillingData';

const paymentProviders = {
    [PAYMENT_PROVIDER_NAME.STRIPE]: StripeProvider,
};

export const withPaymentProvider =
    (Component, { paymentMethodType } = {}) =>
    props => {
        const {
            billingData: { paymentProviderName },
        } = useBillingData();
        const PaymentProvider = paymentProviderName ? paymentProviders[paymentProviderName] : null;

        return paymentProviderName ? (
            <PaymentProvider paymentMethodType={paymentMethodType}>
                <Component {...props} />
            </PaymentProvider>
        ) : (
            <Component {...props} />
        );
    };
