import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useTrackingApi } from 'services/api';
import { useGoogleApi } from '../../taboola-common-frontend-modules/googleApi';

const emptyArray = [];
const defaultResponse = { containers: emptyArray };

export const useContainerDropdown = () => {
    const { getContainers } = useTrackingApi();
    const [{ accountId }] = useSelectedAccount();
    const { getToken, isTokenValid, exponentialBackoff, staleTime } = useGoogleApi();

    const { value: gtmAccount } = useFormFieldValue({ field: 'gtmAccount' });
    const { value, onChange } = useFormFieldValue({ field: 'gtmContainer' });

    const { data = defaultResponse, isLoading } = useQuery(
        ['getContainers', accountId, gtmAccount, getToken()?.access_token],
        () => getContainers(getToken()?.access_token, accountId, gtmAccount?.value),
        {
            enabled: isTokenValid && !isNil(gtmAccount),
            retryDelay: exponentialBackoff,
            staleTime,
        }
    );
    const { containers } = data;
    const options = containers?.map(gtmContainer => ({ value: gtmContainer.id, label: gtmContainer.name }));

    return {
        value,
        options,
        isLoading,
        onChange,
    };
};
