import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FORM_MODES } from 'config/formModes';
import { navigate } from '../../../../../actions';
import { selectedAccountSelector } from '../../../../../selectors';
import { usePermissions } from '../../../../taboola-common-frontend-modules/authentication/hooks/usePermissions';
import { generateCampaignsGroupEditorPath } from '../../../services/utils';
import { isCampaignGroupDeleted } from '../../campaigns-reports/config/predicates/campaignGroupPredicates';

const useOpenCampaignsGroupEditor = campaignGroup => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { accountId: selectedAccountId, isActive: isActiveAccount } = useSelector(selectedAccountSelector);
    const hasEditAccess = usePermissions('SHARED_BUDGET_EDIT');

    return useCallback(
        campaignsGroup => {
            const { campaignsGroupId } = campaignsGroup;
            const editCampaignsGroupPath = generateCampaignsGroupEditorPath({
                accountId: selectedAccountId,
                campaignsGroupId,
                mode:
                    isActiveAccount && hasEditAccess && !isCampaignGroupDeleted(campaignGroup)
                        ? FORM_MODES.EDIT
                        : FORM_MODES.PREVIEW,
            });

            dispatch(navigate(history, editCampaignsGroupPath));
        },
        [selectedAccountId, isActiveAccount, dispatch, history, hasEditAccess, campaignGroup]
    );
};

export default useOpenCampaignsGroupEditor;
