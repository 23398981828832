import React from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import FormLayout from 'modules/campaigns/components/Form/FormLayout/FormLayout';
import { withFormDataProvider, useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import FormInitBase from 'modules/taboola-common-frontend-modules/forms/components/FormInitBase/FormInitBase';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BudgetSection } from './components/BudgetSection/BudgetSection';
import { ScheduleSection } from './components/ScheduleSection/ScheduleSection';
import { useBulkEditCampaignsGroupIds } from './hooks/useBulkEditCampaignsGroupIds';
import { useCampaignsGroupBulkEditorState } from './hooks/useCampaignsGroupBulkEditorState';
import styles from './campaignsGroupBulkEditor.module.scss';

const CampaignsGroupBulkEditor = ({ onCancel }) => {
    const [campaignGroupIdBeingEdit] = useBulkEditCampaignsGroupIds();
    const { isSubmitDisabled, submitInProgress } = useFormDataContext();
    //ToDo: Implement onSubmit handler
    const { submit } = useCampaignsGroupBulkEditorState();

    return (
        <FormInitBase>
            <FormLayout
                className={styles['editor']}
                headerContainerClassName={styles['editor-header']}
                contentContainerClassName={styles['content-container']}
                bodyContainerClassName={styles['body-container']}
                footerContainerClassName={styles['footer']}
                header={
                    <div>
                        <div className={styles['header']}>
                            <FormattedMessage id="creative.editor.edit.bulk.header" tagName="h1" />
                        </div>
                        <FormattedMessage
                            id="campaigns.group.bulk.editor.subheader"
                            values={{ count: size(campaignGroupIdBeingEdit) }}
                        />
                    </div>
                }
                onCancel={onCancel}
                onSubmit={submit}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
            >
                <BudgetSection />
                <ScheduleSection />
            </FormLayout>
        </FormInitBase>
    );
};

CampaignsGroupBulkEditor.propTypes = { onCancel: PropTypes.func };

const CampaignsGroupBulkEditorWithFormDataProvider = withFormDataProvider(CampaignsGroupBulkEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export {
    CampaignsGroupBulkEditorWithFormDataProvider as CampaignsGroupBulkEditor,
    CampaignsGroupBulkEditor as CampaignsGroupEditorBase,
};
