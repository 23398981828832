import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { LanguageOutlinedIcon } from 'tuui';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ReportPresetPickerOptionIcon } from './ReportPresetPickerOptionIcon';
import styles from './reportPresetPickerMenuItem.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ReportPresetPickerMenuItem = props => {
    const { data, innerRef, innerProps, isSelected, isFocused } = props;
    const { label, value, iconButtons, isGlobal, isCustom } = data;

    const containerClassName = classNameBuilder('menu-item', {
        'is-selected': isSelected,
        'is-focused': isFocused,
    });

    return (
        <div className={containerClassName} tabIndex="-1" ref={innerRef} {...innerProps}>
            <div className={styles['global-icon']}>
                {isGlobal ? (
                    <div>
                        <LanguageOutlinedIcon
                            fontSize="small"
                            className={styles['publisher-icon']}
                            data-testid="globeIcon"
                        />
                        <Tooltip arrow offset={20} position={TOOLTIP_POSITION.LEFT}>
                            <FormattedMessage
                                id="app.campaigns.reports.columnPicker.dropdown.global.preset"
                                defaultMessage="This preset appears across all accounts"
                            />
                        </Tooltip>
                    </div>
                ) : null}
            </div>
            <div className={styles['label']} aria-label={`${value} view`}>
                {label}
                {isCustom && (
                    <Tooltip arrow offset={20} position={TOOLTIP_POSITION.LEFT}>
                        {label}
                    </Tooltip>
                )}
            </div>
            {iconButtons ? (
                <div className={styles['icons']}>
                    {iconButtons.map((iconProps, index) => (
                        <ReportPresetPickerOptionIcon key={index} {...iconProps} />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

ReportPresetPickerMenuItem.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.string,
        iconButtons: PropTypes.array,
    }),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
};
