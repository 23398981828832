import { keys } from 'lodash';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { hasPerformanceCookies } from 'modules/taboola-common-frontend-modules/consent/consentUtils';
import { waitForAnalyticsLoaded } from 'modules/taboola-common-frontend-modules/gtmTracker/services/waitForAnalyticsLoaded';
import { config as VersionsConfig } from 'modules/taboola-common-frontend-modules/version/config';
import { accessTokenServerParam } from '../authentication/utils/auth-utils';

const authRegexp = new RegExp(`${accessTokenServerParam}=([^&]*)`);
const authHeaderRegexp = new RegExp('.*(auth).*', 'i');
const anonymizedToken = `${accessTokenServerParam}=**redacted**`;

class Config {
    constructor() {
        this.user = {};
        this.config = {};
    }
    init(config) {
        this.config = config;

        if (!this.isActive()) {
            return;
        }

        LogRocket.init(this.config.logRocketAppId, {
            release: VersionsConfig.assetsVersion,
            mergeIframes: true,
            network: {
                requestSanitizer: request => {
                    // if the url contains 'ignore'
                    if (request.url.toLowerCase().indexOf('/billing/') !== -1) {
                        // ignore the request response pair
                        return null;
                    }

                    if (request.url.toLowerCase().indexOf(`${accessTokenServerParam}=`) !== -1) {
                        request.url = request.url.replace(authRegexp, anonymizedToken);
                    }

                    const headersArray = keys(request.headers);
                    headersArray.forEach(key => {
                        if (authHeaderRegexp.test(key)) {
                            request.headers[key] = '';
                        }
                    });

                    return request;
                },
            },
            browser: {
                urlSanitizer: url => {
                    let sanitizedUrl = url;

                    // redact the value of the query parameter secret_key
                    sanitizedUrl = sanitizedUrl.replace(authRegexp, anonymizedToken);

                    // make sure you return the sanitized URL string
                    return sanitizedUrl;
                },
            },
        });

        setupLogRocketReact(LogRocket);
    }

    isActive() {
        if (!this.config.logRocketAppId) {
            return false;
        }

        return hasPerformanceCookies();
    }

    get sessionUrl() {
        return LogRocket.sessionURL;
    }

    checkIfLoadedSessionUrl = callback => {
        if (this.sessionUrl) {
            callback(this.sessionUrl);
            return;
        }

        setTimeout(this.checkIfLoadedSessionUrl, 1000, callback);
    };

    async getSessionUrlAsync() {
        return new Promise(resolve => {
            if (!this.isActive()) {
                return resolve(this.sessionUrl);
            }
            this.checkIfLoadedSessionUrl(resolve);
        });
    }

    getMiddleWare() {
        return LogRocket.reduxMiddleware({});
    }

    getHeaders() {
        if (!this.isActive() || !this.sessionUrl) {
            return;
        }

        return {
            'X-LogRocket-URL': this.sessionUrl,
        };
    }

    getSessionsUrl() {
        return `https://app.logrocket.com/${this.config.logRocketAppId}/sessions?u=${this.user.user_id}`;
    }

    async setUser(user) {
        this.user = user;
        const { user_id, full_name, username } = user;

        if (!this.isActive()) {
            return;
        }

        if (!user_id) {
            console.error('user_id is missing');
        }

        await waitForAnalyticsLoaded();

        LogRocket.identify(user_id, {
            name: full_name,
            email: username,
            ...this.getExperimentsData(),
        });
    }

    getExperimentsData() {
        const experiments = [];
        const variations = [];

        Object.values(window.gaData).forEach(({ experiments: experimentsMap = {} }) => {
            Object.entries(experimentsMap).forEach(([experimentId, variant]) => {
                experiments.push(experimentId);
                variations.push(variant);
            });
        });

        return { experiments: experiments.join(), variations: variations.join() };
    }
}

export const config = new Config();
