import memoize from 'memoize-one';
import config from '../../authentication/config';

const getOriginRegExp = memoize((value = window.origin) => {
    const originWithDots = value.replace(/\./g, '\\.');
    const originWithAsterisk = originWithDots.replace(/\*/g, '.*');

    return new RegExp(`^${originWithAsterisk}$`);
});

export const isChatAgentEvent = (event, client) => {
    const originRegExp = getOriginRegExp(config.getChatAgentDomain());
    const origin = event.origin;
    const eventData = event.data;

    return !!origin.match(originRegExp) && !!eventData?.client && eventData.client === client;
};
