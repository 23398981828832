import React from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { FORM_MODES } from 'modules/campaigns/config';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { EventNameTooltip } from './EventNameTooltip/EventNameTooltip';

const InputWithIndication = withIndication(Input);

const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'tracking.conversion.validations.error.event.name.empty',
        defaultMessage: 'Please enter an Event Name',
    },
    {
        validationFn: value => /^[a-zA-Z0-9_-]+$/.test(value),
        messageId: 'tracking.conversion.validations.error.event.name.empty.invalid',
        defaultMessage: 'Event Name may contain only alphanumeric characters, underscores or dashes, without spaces.',
    },
    {
        validationFn: value => value !== 'page_view',
        messageId: 'unip.server.error.invalid.value.rule.eventNamePageView',
        defaultMessage: 'Event name cannot be page_view. please choose a different event name',
    },
];

export const EventNameField = () => {
    const {
        value: eventName,
        onChange: onChangeEventName,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'eventName',
        validations,
    });

    const changeHandler = useEventValueHandler(onChangeEventName);
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'tracking.conversion.properties.event.name.input.placeholder',
        defaultMessage: 'Enter Name...',
    });

    return (
        <FormField
            ref={scrollRef}
            inputId="event-name"
            label={
                <FormattedMessage
                    id="tracking.conversion.properties.event.name.input.label"
                    defaultMessage="Event Name"
                />
            }
            description={
                mode === FORM_MODES.CREATE && (
                    <FormattedMessage
                        id="tracking.conversion.properties.event.name.input.description"
                        defaultMessage="Use a unique and descriptive name for your event."
                    />
                )
            }
            helpText={<EventNameTooltip />}
        >
            <InputWithIndication
                id="event-name"
                placeholder={placeholder}
                type={InputTypes.TEXT}
                value={eventName ?? ''}
                onChange={changeHandler}
                disabled={mode === FORM_MODES.EDIT}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="event-name"
                {...indicationData}
            />
        </FormField>
    );
};
