import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH, CAMPAIGNS_ROUTE } from 'modules/campaigns/config';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CampaignsGroupEditor } from '../modules/campaigns-group-form/forms/campaigns-group-editor/CampaignsGroupEditor';

export const CampaignsGroupEditorPage = ({ onCancel }) => {
    useUnsavedChangesController(CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                exact
                path={CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH}
                render={props => <CampaignsGroupEditor {...props} onCancel={onCancel} />}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CampaignsGroupEditorPage.propTypes = {
    onCancel: PropTypes.func,
};

export default CampaignsGroupEditorPage;
