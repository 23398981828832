import { isEmpty, intersection, get, mapValues } from 'lodash';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import { splitTaxonomy } from '../../Tree/utils/treeUtils';

export const hasIntersection = (arrayA, arrayB) => !isEmpty(intersection(arrayA, arrayB));

export const SEGMENT_GROUP_TYPE = {
    AGE: 'AGE',
    GENDER: 'GENDER',
    OTHER: 'OTHER',
    EMPTY: 'EMPTY',
};

export const EMPTY_SEGMENT_GROUP_COUNT = mapValues(SEGMENT_GROUP_TYPE, () => 0);

export const EMPTY_HIDDEN_IDS_SET = new Set();

export const marketplaceAudiencesCollectionPath = 'campaignTargeting.marketplaceAudienceTargeting.collection';

export const getTargetingTypeFieldName = index => `${marketplaceAudiencesCollectionPath}.${index}.type`;
export const getTargetingValuesFieldName = index => `${marketplaceAudiencesCollectionPath}.${index}.values`;

export const getTargetingDropdownProps = (shouldDisableExcludeType, targetingType) =>
    shouldDisableExcludeType && targetingType !== TARGETING_TYPES.EXCLUDE
        ? {
              arrowRenderer: () => null,
              styles: {
                  control: (styles, { isDisabled }) => ({
                      ...styles,
                      backgroundColor: isDisabled ? 'transparent' : styles.backgroundColor,
                      border: isDisabled ? '0' : styles.backgroundColor,
                  }),
              },
              disabled: true,
          }
        : {};

export const getSegmentGroupTypesFromNodes = (
    nodes,
    getSplitTaxonomyList,
    { ageGroupTaxonomyList = [], genderGroupTaxonomyList = [] } = {}
) => {
    if (isEmpty(nodes)) {
        return {
            hasOnlyAge: false,
            hasOnlyGender: false,
            hasOther: false,
            hasAge: false,
            hasGender: false,
        };
    }

    let hasOnlyAge = true;
    let hasOnlyGender = true;
    let hasAge = false;
    let hasGender = false;
    let hasOther = false;

    nodes.forEach(node => {
        const splitTaxonomyList = getSplitTaxonomyList(node);

        if (hasIntersection(ageGroupTaxonomyList, splitTaxonomyList)) {
            hasAge = true;
            hasOnlyGender = false;
        } else if (hasIntersection(genderGroupTaxonomyList, splitTaxonomyList)) {
            hasGender = true;
            hasOnlyAge = false;
        } else {
            hasOnlyAge = false;
            hasOnlyGender = false;
            hasOther = true;
        }
    });

    return { hasOnlyAge, hasOnlyGender, hasOther, hasAge, hasGender };
};

export const checkNodeIsHidden = ({
    node,
    hiddenIdsSet,
    shouldHideAge,
    shouldHideGender,
    shouldHideOther,
    ageGroupTaxonomyList = [],
    genderGroupTaxonomyList = [],
    ageGroupTaxonomyParentsSet = new Set(),
    genderGroupTaxonomyParentsSet = new Set(),
}) => {
    const nodeId = String(get(node, 'id', ''));
    const taxonomy = get(node, 'taxonomy', '');
    const pathProperty = get(node, 'pathProperty', '');
    const pathPropertyList = [...splitTaxonomy(taxonomy), pathProperty];

    const checkIsAgeType = () => hasIntersection(ageGroupTaxonomyList, pathPropertyList);
    const checkIsGenderType = () => hasIntersection(genderGroupTaxonomyList, pathPropertyList);
    const checkIsAgeParent = () => ageGroupTaxonomyParentsSet.has(pathProperty);
    const checkIsGenderParent = () => genderGroupTaxonomyParentsSet.has(pathProperty);

    const isNodeTypeHidden = checkNodeTypeIsHidden({
        shouldHideAge,
        shouldHideGender,
        shouldHideOther,
        checkIsAgeType,
        checkIsGenderType,
        checkIsAgeParent,
        checkIsGenderParent,
    });

    if (isNodeTypeHidden) {
        return true;
    }
    // If this id is hidden, it should be filtered
    return hiddenIdsSet.has(nodeId);
};

export const checkNodeTypeIsHidden = ({
    shouldHideAge,
    shouldHideGender,
    shouldHideOther,
    checkIsAgeType,
    checkIsGenderType,
    checkIsAgeParent,
    checkIsGenderParent,
}) => {
    const hasAge = checkIsAgeType();
    if (shouldHideAge && hasAge) {
        return true;
    }

    const hasGender = checkIsGenderType();
    if (shouldHideGender && hasGender) {
        return true;
    }

    const isAgeParent = checkIsAgeParent();
    const isGenderParent = checkIsGenderParent();

    if (shouldHideOther && !hasAge && !hasGender && !isAgeParent && !isGenderParent) {
        return true;
    }
};
