"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgUnblock(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                clipRule: "evenodd",
                d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM11.0004 15C11.4013 15 11.7783 14.8424 12.0603 14.557L15.7075 10.7242C16.0975 10.3291 16.0975 9.69062 15.7075 9.29555C15.3166 8.90148 14.6836 8.90148 14.2937 9.29555L11.0004 12.7723L9.70706 11.2956C9.31711 10.9015 8.68418 10.9015 8.29322 11.2956C7.90226 11.6906 7.90226 12.3291 8.29322 12.7242L9.93949 14.557C10.2235 14.8424 10.5984 15 11.0004 15Z"
            })
        )
    );
}
exports.default = SvgUnblock;