"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DropSVGIcon = function DropSVGIcon() {
    return _react2.default.createElement(
        "svg",
        { xmlns: "http://www.w3.org/2000/svg", width: "123", height: "69", viewBox: "0 0 123 69" },
        _react2.default.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            _react2.default.createElement("path", { fill: "#FFF", stroke: "#667686", strokeWidth: "2", d: "M111.322 64.646l-36.8-9.86c-1.838-.493-2.976-2.525-2.471-4.41L78.234 27.3c.505-1.886 2.507-3.076 4.344-2.584l36.8 9.86c1.838.493 2.976 2.525 2.471 4.41l-6.183 23.077c-.505 1.885-2.507 3.075-4.344 2.583z" }),
            _react2.default.createElement("path", { fill: "#B8C1CA", d: "M87.357 35.497l13.06 3.5a2 2 0 0 1 1.414 2.45l-2.234 8.337a2 2 0 0 1-2.45 1.414l-13.06-3.5a2 2 0 0 1-1.414-2.449l2.235-8.337a2 2 0 0 1 2.45-1.415zm15.866 8.276l9.216-.549-2.766 10.324-7.707-5.083 1.257-4.692z" }),
            _react2.default.createElement("path", { fill: "#FFF", stroke: "#667686", strokeWidth: "2", d: "M48.249 54.731l-36.115 9.677c-1.803.483-3.774-.708-4.277-2.585L1.7 38.846c-.503-1.877.608-3.894 2.411-4.377l36.116-9.678c1.803-.483 3.774.709 4.277 2.586l6.156 22.977c.503 1.877-.608 3.894-2.411 4.377z" }),
            _react2.default.createElement("path", { fill: "#9CA8B5", fillRule: "nonzero", d: "M19.283 46.921l3.83-1.098 1.32 4.607a6.211 6.211 0 0 1-1.557 1.432 7.381 7.381 0 0 1-1.96.874c-.917.263-1.788.36-2.613.288a5.832 5.832 0 0 1-2.248-.641 5.457 5.457 0 0 1-1.74-1.457 6.18 6.18 0 0 1-1.086-2.16c-.24-.836-.323-1.642-.248-2.418a5.359 5.359 0 0 1 .656-2.136 5.571 5.571 0 0 1 1.505-1.704c.64-.489 1.405-.861 2.297-1.117a7.356 7.356 0 0 1 1.324-.259c.421-.04.82-.043 1.196-.01a5.467 5.467 0 0 1 2.002.57l-.373 1.223a.648.648 0 0 1-.314.414c-.154.083-.345.094-.574.032a8.181 8.181 0 0 0-.629-.149 4.1 4.1 0 0 0-1.33-.039 5.693 5.693 0 0 0-.83.18 3.693 3.693 0 0 0-1.33.673c-.37.3-.655.656-.855 1.069-.201.413-.315.872-.341 1.38a4.916 4.916 0 0 0 .204 1.614c.177.615.422 1.14.735 1.574.313.435.676.773 1.09 1.015.413.242.867.387 1.36.434a4.177 4.177 0 0 0 1.555-.163c.37-.106.688-.238.957-.395.268-.157.522-.34.762-.547l-.538-1.877-1.26.362a.51.51 0 0 1-.385-.02.415.415 0 0 1-.214-.266l-.368-1.285zm9.666 3.379l-2.323.666-3.2-11.158 2.323-.666 3.2 11.158zm1.978-10.688l.885 3.084 4.014-1.15.52 1.814-4.014 1.151 1.277 4.453-2.323.666-3.2-11.159 7.106-2.037.519 1.807-4.784 1.371z" }),
            _react2.default.createElement(
                "g",
                { transform: "translate(34.1 1)" },
                _react2.default.createElement("path", { fill: "#FFF", stroke: "#667686", strokeWidth: "2", d: "M51.777 38H4.387C2.029 38 0 35.964 0 33.581V4.42C0 2.036 2.028 0 4.388 0h47.389c2.36 0 4.388 2.036 4.388 4.419V33.58c0 2.383-2.029 4.419-4.388 4.419z" }),
                _react2.default.createElement("rect", { width: "48.141", height: "30", x: "4.012", y: "4", fill: "#EEF0F2", rx: "2" }),
                _react2.default.createElement("path", { fill: "#B8C1CA", d: "M25.074 12c0 2.209-1.796 4-4.012 4a4.006 4.006 0 0 1-4.012-4c0-2.209 1.796-4 4.012-4a4.006 4.006 0 0 1 4.012 4zm-2.006 12L34.1 13c1.042-1.272 2.748-1.272 4.012 0 6.09 6.393 10.676 11.202 13.756 14.427v4.555a2 2 0 0 1-1.997 2L33.097 34l-10.03-10zM5.936 34a2 2 0 0 1-2-2v-.893l9.503-9.376a3 3 0 0 1 4.214 0L30.088 34H5.935z" })
            ),
            _react2.default.createElement(
                "g",
                { transform: "translate(48.141 36)" },
                _react2.default.createElement("ellipse", { cx: "14.543", cy: "14.5", fill: "#01D98E", stroke: "#B5E8D6", strokeWidth: "4", rx: "16.543", ry: "16.5" }),
                _react2.default.createElement("path", { fill: "#FFF", d: "M13.859 9.59v9.853c0 .234.224.423.5.423s.5-.19.5-.423V9.59l4.041 4.762c.203.24.551.26.777.044a.608.608 0 0 0 .04-.824l-4.95-5.833a.53.53 0 0 0-.817 0L9 13.572a.608.608 0 0 0 .04.824.528.528 0 0 0 .778-.044l4.04-4.762z" })
            )
        )
    );
};

exports.default = DropSVGIcon;