import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { NodeLabel, Tree } from '../../../../../campaigns/modules/common-campaign-form/components/Tree';
import SelectAll from '../../../../../campaigns/modules/common-campaign-form/components/Tree/SelectAll/SelectAll';
import { useCombinedAudiencesWithoutBundlingContext } from '../CombinedAudiencesWithoutBundlingContext';
import SegmentLeafContent from '../SegmentLeafContent/SegmentLeafContent';
import styles from './segmentsTree.module.scss';

const needRenderCheckbox = nodeProps => nodeProps?.depth > 2 || nodeProps?.parent?.path === 'MY_AUDIENCES';

const SegmentsTree = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        getNodesTree,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        disabledPathsMap,
        collapsedPathMap,
        handleCollapseChange,
        handleLoadMore,
        limit,
    } = useCombinedAudiencesWithoutBundlingContext();
    const { tree: displayedTree, count: displayedTreeCount } = useMemo(() => getNodesTree(), [getNodesTree]);

    const [isAllChecked, setIsAllChecked] = useState(false);

    const renderNodeText = useCallback(
        (domProps, { hasChildren, node }) => {
            if (hasChildren) {
                const { label } = node;
                return <NodeLabel node={node} searchText={search} title={label} />;
            }

            return <SegmentLeafContent item={node} searchText={search} />;
        },
        [search]
    );

    const handleSelectAllWrapper = useCallback(() => {
        handleSelectAll(!isAllChecked);
        setIsAllChecked(prev => !prev);
    }, [handleSelectAll, isAllChecked]);

    const renderSelectAll = useCallback(
        () =>
            isSearchMode ? (
                <SelectAll onClick={handleSelectAllWrapper} disabled={displayedTreeCount > limit}>
                    <FormattedMessage
                        id={`campaign.editor.marketplace.audiences.${isAllChecked ? 'selectAll' : 'deselectAll'}.title`}
                        defaultMessage="Select all {totalItems} results (limited to {limit})"
                        values={{ totalItems: displayedTreeCount, limit }}
                    />
                </SelectAll>
            ) : null,
        [isSearchMode, handleSelectAllWrapper, displayedTreeCount, limit, isAllChecked]
    );

    return (
        <div className={styles['container']} ref={innerRef} data-testid="segments-tree">
            <Tree
                nodesTree={displayedTree}
                onSelect={handleSelectNode}
                selectedItemsMap={selectedPathsMap}
                totalItems={displayedTreeCount}
                collapsed={collapsedPathMap}
                onCollapsedChange={handleCollapseChange}
                renderNodeText={renderNodeText}
                onLoadMore={handleLoadMore}
                renderSelectAll={renderSelectAll}
                disabledPathsMap={disabledPathsMap}
                noOptionsContent={<FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />}
                loadMoreContent={<FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />}
                loadingMode={loadingMode}
                needRenderCheckbox={needRenderCheckbox}
            />
        </div>
    );
};

SegmentsTree.propTypes = {
    innerRef: PropTypes.func,
};

export default SegmentsTree;
