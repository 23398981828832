import { getGraphDataSuccess as getGraphDataSuccessAction, setGraphMetrics } from '../actions';
import { getMergedEdgesStateWithMetrics } from '../services';
import { getGraphMetricsWithDynamicFields } from '../services/dynamicColumnsService';
import { transformGraphFromGW as transformGraph } from '../transformers';

const getGraphDataSuccess =
    ({
        graphResponse,
        graphEdgesState,
        setGraphEdgesState,
        graphConfig,
        locale,
        currency,
        options: { maxXAxisValues, displayTotalsInLegendRow = true },
        startDate,
    }) =>
    (dispatch, getState) => {
        const { getMetrics = getGraphMetricsWithDynamicFields } = graphConfig;
        const graphData = transformGraph(graphResponse, {
            maxXAxisValues,
            displayTotalsInLegendRow,
            graphConfig,
            startDate,
        });

        dispatch(getGraphDataSuccessAction(graphData));

        const metrics = getMetrics({
            graphData,
            graphConfig,
            metadata: graphResponse.metadata,
            locale,
            currency,
            state: getState(),
        });
        dispatch(setGraphMetrics(metrics));
        setGraphEdgesState({ edgeState: getMergedEdgesStateWithMetrics(metrics, graphEdgesState), autoUpdate: true });
    };

export default getGraphDataSuccess;
