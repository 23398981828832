import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { FormLayout } from 'modules/campaigns/components';
import { ThumbnailsFieldTooltip } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField';
import { ALL_MEDIA_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { MediaPicker } from 'modules/campaigns/modules/creative-editor/components/MediaPicker/MediaPicker';
import { useUnifiedCreativeEditorBodyState } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorBodyState';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { UnifiedCreativeMotionEditorBody } from './UnifiedCreativeMotionEditorBody';
import { UnifiedCreativeThumbnailEditorBody } from './UnifiedCreativeThumbnailEditorBody';
import styles from './unifiedCreativeEditorBody.module.scss';

export const UnifiedCreativeEditorBody = ({
    campaignId,
    thumbnailStatus,
    setThumbnailStatus,
    videoStatus,
    setVideoStatus,
    formLayoutProps,
    headerLabel,
    disabled: isBulkEditMotionModeDisabled = false,
    isThumbnailLoading,
    setIsThumbnailLoading,
    applyFocusToMatches,
    setApplyFocusToMatches,
    matchingCreativeIds,
}) => {
    const {
        thumbnailError,
        videoError,
        editFallbackImageMode,
        setEditFallbackImageMode,
        selectedMedia,
        onFileDialogCancel,
        fetchStatus,
        setSubmitStatus,
        mode,
        thumbnailUrl,
        videoUrl,
        creativeType,
        initialCreativeTypeRef,
        openCropModal,
        onSelectMedia,
        handleBrowseFilesClick,
    } = useUnifiedCreativeEditorBodyState(
        campaignId,
        setThumbnailStatus,
        setVideoStatus,
        setIsThumbnailLoading,
        setApplyFocusToMatches,
        videoStatus,
        thumbnailStatus
    );

    useEffect(() => {
        const statuses = new Set([videoStatus, thumbnailStatus]);
        if (statuses.has(COMPONENT_STATUS.ACTIVE)) {
            setSubmitStatus(COMPONENT_STATUS.ACTIVE);
            return;
        }

        if (statuses.has(COMPONENT_STATUS.LOADING) || statuses.has(COMPONENT_STATUS.ERROR)) {
            setSubmitStatus(COMPONENT_STATUS.LOADING);
        }
    }, [thumbnailStatus, videoStatus, setSubmitStatus]);

    useEffect(() => {
        if (initialCreativeTypeRef.current === creativeType) {
            return;
        }

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: `Creative Type Changed from ${initialCreativeTypeRef.current} to ${creativeType}`,
            component: `UnifiedCreativeEditorBody - ${mode}`,
            pageName: getPageName(),
        });
    }, [initialCreativeTypeRef, creativeType, mode]);

    return (
        <FormLayout
            className={styles['thumbnail-form']}
            header={headerLabel}
            {...formLayoutProps}
            contentContainerClassName={styles['thumbnail-video']}
        >
            {fetchStatus !== COMPONENT_STATUS.ACTIVE && <Spinner className={styles['overlay']} />}
            <MediaPicker
                fileTypes={ALL_MEDIA_FILES}
                value={modifyExternalUrl(videoUrl) || modifyExternalUrl(thumbnailUrl)}
                onSelectMedia={onSelectMedia}
                disabled={editFallbackImageMode || isBulkEditMotionModeDisabled}
                tabsConditionData={creativeType}
                tabsContext={{ thumbnails: selectedMedia, campaignId }}
                showFileBrowser
                openCropModal={openCropModal}
                onCancel={onFileDialogCancel}
                onBrowseFile={handleBrowseFilesClick}
                clickZoneDescription={
                    <FormattedMessage
                        id="creative.drag.and.drop.clickable.text.upload.from.computer"
                        defaultMessage="upload from computer"
                    />
                }
                clickZoneTooltip={<ThumbnailsFieldTooltip />}
                tabsDisabled={editFallbackImageMode || isBulkEditMotionModeDisabled}
            >
                {videoUrl && (
                    <UnifiedCreativeMotionEditorBody
                        thumbnailStatus={thumbnailStatus}
                        thumbnailError={thumbnailError}
                        videoStatus={videoStatus}
                        videoError={videoError}
                        onSelectMedia={onSelectMedia}
                        onFileDialogCancel={onFileDialogCancel}
                        setEditFallbackImageMode={setEditFallbackImageMode}
                    />
                )}
                {!videoUrl && (
                    <UnifiedCreativeThumbnailEditorBody
                        campaignId={campaignId}
                        isThumbnailLoading={isThumbnailLoading}
                        setIsThumbnailLoading={setIsThumbnailLoading}
                        applyFocusToMatches={applyFocusToMatches}
                        setApplyFocusToMatches={setApplyFocusToMatches}
                        matchingCreativeIds={matchingCreativeIds}
                        thumbnailStatus={thumbnailStatus}
                        creativeType={creativeType}
                        error={thumbnailError}
                        openCropModal={openCropModal}
                    />
                )}
            </MediaPicker>
        </FormLayout>
    );
};

UnifiedCreativeEditorBody.propTypes = {
    campaignId: PropTypes.string,
    thumbnailStatus: PropTypes.string,
    setThumbnailStatus: PropTypes.func,
    videoStatus: PropTypes.string,
    setVideoStatus: PropTypes.func,
    formLayoutProps: PropTypes.shape(FormLayout.propTypes),
    headerLabel: PropTypes.node,
    disabled: PropTypes.bool,
    isThumbnailLoading: PropTypes.bool,
    setIsThumbnailLoading: PropTypes.func,
    applyFocusToMatches: PropTypes.bool,
    setApplyFocusToMatches: PropTypes.func,
    matchingCreativeIds: PropTypes.array,
};
