import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from 'tuui/lib/atoms/Button';
import { ButtonSize, ButtonSizing, buttonSizingMap } from 'tuui/lib/atoms/Button/Button';
import { scale } from 'tuui/lib/utils/styles';

const transformIconButtonSize: Record<ButtonSize, ButtonSize> = {
    sm: 'md',
    md: 'lg',
    lg: 'lg',
};

const IconButtonWrapper = ({
    children,
    size = 'lg',
    ...rest
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
    <IconButtonBase
        $onlyChild
        $size={transformIconButtonSize[size]}
        size={size}
        $sizing={buttonSizingMap[size]}
        {...rest}
    >
        {children}
    </IconButtonBase>
);

const IconButtonBase = styled(Button)<{ $onlyChild: boolean; $sizing: ButtonSizing; $size: ButtonSize }>`
    padding: 0;
    padding-inline: 0;
    padding-block: 0;
    border-radius: 50%;
    width: ${scale('lg')};
    height: ${scale('lg')};

    > svg:not(:root) {
        width: ${scale('lg')};
        height: ${scale('lg')};
        transform: scale(1.25);
        overflow: visible;
        padding: 0;
    }
`;

export { IconButtonWrapper as IconButton };
