'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.STATUS = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _ImagePlaceholder = require('../Image/Image/ImagePlaceholder');

var _ImagePlaceholder2 = _interopRequireDefault(_ImagePlaceholder);

var _ImageContentLoader = require('../Image/Image/ImageContentLoader');

var _ImageContentLoader2 = _interopRequireDefault(_ImageContentLoader);

var _HelpTooltip = require('../Tooltips/HelpTooltip/HelpTooltip');

var _HelpTooltip2 = _interopRequireDefault(_HelpTooltip);

var _Image = require('../Image');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'fallbackImage__taboola-svg-icon___14J1s',
    'img': 'fallbackImage__img___2u44f',
    'header': 'fallbackImage__header___3xu7A',
    'edit-image-button': 'fallbackImage__edit-image-button___2TpL2',
    'disabled': 'fallbackImage__disabled___xq3GT',
    'container': 'fallbackImage__container___2EIIj',
    'image-placeholder-auto': 'fallbackImage__image-placeholder-auto___2D4rI',
    'help-icon': 'fallbackImage__help-icon___2x1l_'
};


var classNameBuilder = _bind2.default.bind(styles);

var DEFAULT_HEADER_TEXT = 'Fallback Image';
var EDIT_TEXT = 'Edit image';
var SELECT_TEXT = 'Choose image';
var DEFAULT_TOOLTIP_TEXT = 'A fallback image will serve when Motion Ads isn\'t supported so that you do not lose scale. This image, automatically generated and represents the mid-frame, can be replaced with your own by clicking the \'replace image\'';

var STATUS = exports.STATUS = {
    INITIAL: 'INITIAL',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    ACTIVE: 'ACTIVE'
};

var getComponentByStatus = function getComponentByStatus(status, errorComponent, loadingComponent, imgSrc) {
    if (status === STATUS.ERROR) {
        return errorComponent;
    }
    if (status === STATUS.LOADING) {
        return loadingComponent;
    }

    return _react2.default.createElement(_Image.Image, {
        className: styles['img'],
        src: imgSrc || '',
        errorComponent: errorComponent,
        loadingComponent: loadingComponent
    });
};

var FallbackImage = function FallbackImage(_ref) {
    var loadingComponent = _ref.loadingComponent,
        errorComponent = _ref.errorComponent,
        onEditFallbackImageClick = _ref.onEditFallbackImageClick,
        imgSrc = _ref.imgSrc,
        status = _ref.status,
        header = _ref.header,
        editText = _ref.editText,
        selectText = _ref.selectText,
        selectBtnMetricsAttributes = _ref.selectBtnMetricsAttributes,
        tooltipText = _ref.tooltipText;

    var editButtonClassName = classNameBuilder('edit-image-button', { disabled: status === STATUS.LOADING });
    var isEditMode = !!imgSrc;
    var btnMetricsAttributes = isEditMode ? {} : selectBtnMetricsAttributes;

    return _react2.default.createElement(
        'div',
        { className: styles['container'] },
        _react2.default.createElement(
            'div',
            { className: styles['header'] },
            header,
            _react2.default.createElement(
                _HelpTooltip2.default,
                { iconClassName: styles['help-icon'] },
                tooltipText
            )
        ),
        getComponentByStatus(status, errorComponent, loadingComponent, imgSrc),
        status !== STATUS.LOADING && _react2.default.createElement(
            'div',
            Object.assign({ className: editButtonClassName, onClick: onEditFallbackImageClick, role: 'button', tabIndex: 0 }, btnMetricsAttributes),
            isEditMode ? editText : selectText
        )
    );
};

FallbackImage.propTypes = {
    /** To be shown on error state when image is not loaded */
    errorComponent: _propTypes2.default.node,
    /** Header text to be shown above fallback image */
    header: _propTypes2.default.node,
    /** Link text when there is no fallback image selected */
    selectText: _propTypes2.default.node,
    /** Link text when a fallback image is selected */
    editText: _propTypes2.default.node,
    /** To be shown when Loading the image */
    loadingComponent: _propTypes2.default.node,
    /** imageSrc is the image url of fabllack image */
    imgSrc: _propTypes2.default.string,
    /** status of component - determind what to render */
    status: _propTypes2.default.oneOf(Object.values(STATUS)),
    /** function to invoke on fallback image selection */
    onEditFallbackImageClick: _propTypes2.default.func,
    /** attributes of select button */
    selectBtnMetricsAttributes: _propTypes2.default.object,
    /** Tooltip text to be shown above fallback image */
    tooltipText: _propTypes2.default.node
};

FallbackImage.defaultProps = {
    errorComponent: _react2.default.createElement(_ImagePlaceholder2.default, { className: styles['image-placeholder-auto'] }),
    loadingComponent: _react2.default.createElement(_ImageContentLoader2.default, null),
    header: DEFAULT_HEADER_TEXT,
    editText: EDIT_TEXT,
    selectText: SELECT_TEXT,
    selectBtnMetricsAttributes: {},
    tooltipText: DEFAULT_TOOLTIP_TEXT
};

exports.default = FallbackImage;