import React, { useEffect } from 'react';
import { get } from 'lodash';
import uuid from 'uuid/v1';
import { ContentIcon } from 'taboola-ultimate-ui';
import { useCampaignsFormFieldValue } from 'modules/campaigns/hooks';
import CommonCollapsibleCard from '../../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import { COMPONENT_STATUS } from '../../../../../../../services/constants';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { immutableMergeWith } from '../../../../../../taboola-common-frontend-modules/utils/customMergers';
import SectionHeader from '../../../../../components/Form/SectionHeader/SectionHeader';
import { MAX_TITLES_COUNT } from '../../../config';
import { useCreativeCreatorConfigPermissions } from '../../../hooks/useCreativeCreatorConfigPermissions';
import { generateEmptyContentItem, generateDisabledContentItem } from '../../../services/utils';
import { ContentField } from '../../index';
import styles from '../../../creativeCreator.module.scss';

export const VariationsContentSection = () => {
    const { value: creativeCreatorSelectedCampaigns } = useCampaignsFormFieldValue();
    const { value: content, onChange: setContent } = useFormFieldValue({ field: 'content' });

    const { isContentDescriptionEnabled, isContentCtaEnabled } = useCreativeCreatorConfigPermissions();

    const campaignStatus = get(creativeCreatorSelectedCampaigns, '0.status', 'PENDING_APPROVAL');
    const language = get(creativeCreatorSelectedCampaigns, '0.campaignProfile.language', 'EN');

    const updateContent = (index, newItem) =>
        setContent(prevContent => {
            const newContent = [...prevContent];
            newContent[index] = immutableMergeWith(prevContent[index], { ...newItem, id: uuid() });
            return newContent;
        });

    const addContentItem = item => {
        const id = uuid();
        setContent(prevContent => [...prevContent, { id, status: COMPONENT_STATUS.ACTIVE, ...item }]);
    };

    const duplicateContentItem = idToDuplicate =>
        setContent(prevContent => {
            const index = prevContent.findIndex(({ id }) => id === idToDuplicate);
            const result = [
                ...prevContent.slice(0, index),
                { ...prevContent[index], id: uuid() },
                ...prevContent.slice(index),
            ];
            if (prevContent.length < MAX_TITLES_COUNT) {
                return result;
            }
            return result.slice(0, MAX_TITLES_COUNT);
        });

    const removeContentItem = idToRemote => {
        setContent(prevContent => prevContent.filter(({ id }) => id !== idToRemote));
    };

    useEffect(() => {
        const hasInitial = content.some(({ status }) => status === COMPONENT_STATUS.INITIAL);
        const hasEmpty = content.some(({ status }) => status === COMPONENT_STATUS.EMPTY);
        if (!hasInitial || !hasEmpty) {
            setContent(prev => {
                const newList = [...prev];
                if (!hasInitial) {
                    newList.push(generateDisabledContentItem());
                }
                if (!hasEmpty) {
                    newList[newList.length - 1] = generateEmptyContentItem();
                    newList.push(generateDisabledContentItem());
                }

                return newList.slice(0, MAX_TITLES_COUNT);
            });
        }
    }, [content, setContent]);

    return (
        <CommonCollapsibleCard
            id="CREATIVE_CONTENT"
            header={
                <SectionHeader
                    headerIcon={<ContentIcon />}
                    headerText={<FormattedMessage id="creative.creator.content.label" defaultMessage="Content" />}
                />
            }
            containerClassName={styles['collapsible-card']}
        >
            <ContentField
                className={styles['field']}
                items={content}
                shouldRenderDescription={isContentDescriptionEnabled}
                shouldRenderCta={isContentCtaEnabled}
                updateContentItem={updateContent}
                addContentItem={addContentItem}
                duplicateItem={duplicateContentItem}
                deleteItem={removeContentItem}
                maxItems={MAX_TITLES_COUNT}
                campaignStatus={campaignStatus}
                language={language}
            />
        </CommonCollapsibleCard>
    );
};
