import { useCallback, useMemo, useState, useRef } from 'react';
import { groupBy, isEmpty, keyBy, noop, partition, sum, values } from 'lodash';
import {
    generateTreeWithBundles,
    getAllNodePathsMap,
    getUpdatedSelectedPathsFromAdd,
    getUpdatedSelectedPathsFromRemove,
    setSegmentAncestorsAsIndeterminate,
    setNodeAndDescendantsAsSelected,
    getUpdatedSelectedTopLevelPaths,
    LoadingMode,
} from '../utils';
import { useBundleLoadingWrapper } from './useBundleLoadingWrapper';
import useCollapseState from './useCollapseState';

const generateSelectedPathsMap = (selectedNodes, treeNodePathsMap) => {
    const selectedPathsMap = {};

    selectedNodes.forEach(selectedNode => setSegmentAncestorsAsIndeterminate(selectedNode, selectedPathsMap));
    selectedNodes.forEach(({ path }) => {
        const treeNode = treeNodePathsMap[path];
        if (treeNode) {
            setNodeAndDescendantsAsSelected(treeNode, selectedPathsMap);
        }
    });

    return selectedPathsMap;
};

export const useBundleTreeState = ({
    search,
    nodes,
    fullNodes,
    loadingPaths,
    totals,
    loadMore,
    reload,
    initialSelectedIds = [],
    loadByIds,
    nodeTransformer,
    onRemoveAudience = noop,
}) => {
    const totalCount = useMemo(() => sum(values(totals)), [totals]);
    const taxonomyNodesMap = useMemo(() => groupBy(fullNodes, 'taxonomy'), [fullNodes]);
    const selectedIds = useRef(initialSelectedIds);
    // TODO DEV-74625 Add sync market audience initialisation feature
    const [selectedNodes, setSelectedNodes] = useState([]);

    const { wrapWithLoading, loadingMode } = useBundleLoadingWrapper({
        nodes,
        loadingPaths,
    });
    const isSearchMode = loadingMode === LoadingMode.NONE && !isEmpty(search);

    const { expandAll, collapseAll, ...restCollapseState } = useCollapseState(getAllNodePathsMap);

    const handleReload = useCallback(
        () =>
            wrapWithLoading([], async () => {
                const onDataLoad = (loadedData, isBundleLoad) => {
                    const isRegularReload = !isBundleLoad;

                    if (!isEmpty(search)) {
                        expandAll();
                    } else if (isRegularReload) {
                        collapseAll(loadedData);
                    }
                };

                await reload(onDataLoad);
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [collapseAll, expandAll, reload, search]
    );

    const handleRemoveItem = useCallback(
        node => {
            const { nodePathToRemove, addedSelectedPaths } = getUpdatedSelectedPathsFromRemove(
                node,
                selectedNodes,
                taxonomyNodesMap
            );
            const nodesToAdd = fullNodes.filter(({ path }) => addedSelectedPaths.has(path));
            const updatedSelectedNodes = selectedNodes
                .filter(({ path }) => path !== nodePathToRemove)
                .concat(nodesToAdd);

            setSelectedNodes(updatedSelectedNodes);
            onRemoveAudience(updatedSelectedNodes);
        },
        [selectedNodes, fullNodes, taxonomyNodesMap, onRemoveAudience]
    );

    const handleAddItem = useCallback(
        node => {
            const { nodePathToAdd, filteredSelectedPaths } = getUpdatedSelectedPathsFromAdd(
                node,
                selectedNodes,
                taxonomyNodesMap
            );
            const nodeToAdd = nodes.find(({ path }) => path === nodePathToAdd);
            const updatedSelectedNodes = selectedNodes
                .filter(({ path }) => filteredSelectedPaths.has(path))
                .concat(nodeToAdd);

            setSelectedNodes(updatedSelectedNodes);
        },
        [selectedNodes, nodes, taxonomyNodesMap]
    );

    const handleRemoveAllItems = useCallback(() => setSelectedNodes([]), [setSelectedNodes]);

    const handleSelectNode = useCallback(
        (node, checked) => {
            if (checked) {
                handleAddItem(node);
            } else {
                handleRemoveItem(node);
            }
        },
        [handleAddItem, handleRemoveItem]
    );

    const handleLoadMore = useCallback(
        (taxonomy, pageSize) => {
            wrapWithLoading([taxonomy], () =>
                loadMore({
                    taxonomies: [taxonomy],
                    pageSize,
                })
            );
        },
        [loadMore, wrapWithLoading]
    );

    // Optimization: no need to add all dependencies in order to generate the tree once loadingMode flag is changed
    const [nodesTree, treeNodePathsMap] = useMemo(
        () => generateTreeWithBundles({ nodes, totals, nodeTransformer, loadingPaths, loadingMode }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadingMode]
    );

    const handleSelectAll = useCallback(
        checked => {
            if (checked) {
                const { topLevelNodePathsToAdd, filteredSelectedPaths } = getUpdatedSelectedTopLevelPaths(
                    nodesTree,
                    selectedNodes
                );

                const nodesToAdd = nodes.filter(({ path }) => topLevelNodePathsToAdd.has(path));
                const updatedSelectedNodes = selectedNodes
                    .filter(({ path }) => filteredSelectedPaths.has(path))
                    .concat(nodesToAdd);

                setSelectedNodes(updatedSelectedNodes);
            } else {
                setSelectedNodes(currentSelectedNodes =>
                    currentSelectedNodes.filter(({ path }) => !treeNodePathsMap[path])
                );
            }
        },
        [nodes, nodesTree, treeNodePathsMap, selectedNodes]
    );

    const selectedPathsMap = useMemo(
        () => generateSelectedPathsMap(selectedNodes, treeNodePathsMap),
        [selectedNodes, treeNodePathsMap]
    );

    const [isReady, setIsReady] = useState(false);

    const setSelectedNodesByIds = useCallback(
        async (ids = selectedIds.current) => {
            const nodeIdsMap = keyBy(nodes, 'id');
            const [offlineSelectedIds, remoteSelectedIds] = partition(ids, id => nodeIdsMap[id]);
            const offlineSelectedNodes = offlineSelectedIds.map(id => nodeIdsMap[id]);
            let remoteSelectedNodes = [];
            if (!isEmpty(remoteSelectedIds)) {
                remoteSelectedNodes = await loadByIds(remoteSelectedIds);
            }
            setSelectedNodes([...offlineSelectedNodes, ...remoteSelectedNodes]);
        },
        [loadByIds, nodes]
    );

    return {
        totalCount,
        selectedNodes,
        selectedPathsMap,
        handleRemoveItem,
        handleRemoveAllItems,
        handleSelectNode,
        handleSelectAll,
        handleLoadMore,
        handleReload,
        nodesTree,
        treeNodePathsMap,
        isSearchMode,
        setSelectedNodesByIds,
        search,
        loadingMode,
        expandAll,
        collapseAll,
        isReady,
        setIsReady,
        ...restCollapseState,
    };
};
