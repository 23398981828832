import React from 'react';
import ReportBreadcrumbs from '../../../campaigns/components/ReportBreadcrumbs/ReportBreadcrumbs';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useScheduledReportsBreadcrumbs } from '../../hooks/useScheduledReportsBreadcrumbs';

export const ScheduledReportsReportBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'navigation.caption.scheduledReports.title',
        defaultMessage: 'Scheduled Reports (Beta)',
    });

    const { steps } = useScheduledReportsBreadcrumbs({
        title,
        isTitleAsLastStep: false,
    });

    return <ReportBreadcrumbs title={title} steps={steps} />;
};
