import React, { useCallback, useMemo } from 'react';
import { DropdownMenu, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { newCondition } from '../../../../hooks/useConditionCollection';
import styles from './metric.module.scss';

const FormFieldWithIndication = withIndication(FormField, styles['metric-field']);
const WideFormFieldWithIndication = withIndication(FormField, styles['metric-field-wide']);

const getFormFieldWrapper = hasConversionEvent =>
    hasConversionEvent ? FormFieldWithIndication : WideFormFieldWithIndication;

const metricNameDropdownValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.performance.rules.metric.no.selection',
        defaultMessage: 'Please select a Metric',
    },
];
const formFieldId = 'ruleMetric';
export const Metric = ({ settingValue, setConditions, conditionIndex, hasConversionEvent }) => {
    const { value: metricNameValue, indicationData } = useFormValidatedValue({
        field: formFieldId,
        validations: metricNameDropdownValidations,
        hasInitialData: false,
    });

    const extractedMetricsKeys = useMemo(
        () =>
            Object.keys(settingValue).map(key => ({
                label: (
                    <FormattedMessage id={`performance.rule.create.settings.metric.name.${key}`} defaultMessage={key} />
                ),
                value: key,
            })),
        [settingValue]
    );

    const handleMetricSelection = useCallback(
        ({ value: selectedMetric }) => {
            setConditions(prev => {
                const newList = [...prev];
                newList[conditionIndex] = newCondition(selectedMetric, settingValue[selectedMetric]);
                return newList;
            });
        },
        [setConditions, conditionIndex, settingValue]
    );

    const FormFieldWrapper = getFormFieldWrapper(hasConversionEvent);

    return (
        <FormFieldWrapper
            inputId={formFieldId}
            containerClass={styles['metric-container']}
            label={<FormattedMessage id="app.create.performance.rules.setup.metric.label" defaultMessage="Condition" />}
            labelClass={styles['label']}
            {...indicationData}
        >
            <DropdownMenu
                id={formFieldId}
                placeholder={
                    <FormattedMessage
                        id="performance.rules.setup.metric.placeholder"
                        defaultMessage="Click to Select..."
                    />
                }
                selectedValueObject={metricNameValue ? { value: metricNameValue } : null}
                options={extractedMetricsKeys}
                onChange={handleMetricSelection}
                searchable
                dropdownWrapperClassName={styles['metric-select']}
                aria-label="metric-dropdown"
            />
        </FormFieldWrapper>
    );
};
