import React from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import STEPS from '../../../campaigns/modules/common-campaign-form/config/steps';
import { StaticIndications } from '../../../taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from '../../../taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { PAYMENT_TYPE } from '../../config/PaymentType';
import { paymentMethodInitialValues } from '../../config/paymentMethodInitialValues';
import { useACHPaymentMethodCreatorState } from '../../hooks/useACHPaymentMethodCreatorState';
import { withPaymentProvider } from '../../providers/payment-providers/PaymentProvider/PaymentProvider';
import BillingBreadcrumbs from '../BillingBreadcrumbs/BillingBreadcrumbs';
import { PaymentCreatorFormButtons } from '../PaymentCreatorFormButtons/PaymentCreatorFormButtons';
import { PaymentMethodACHSection } from '../PaymentMethodACHSection/PaymentMethodACHSection';
import styleConsts from '../../../../globalStyle/styleConsts.module.scss';
import styles from './achPaymentMethodCreator.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const ACHPaymentMethodCreator = ({ onCancel }) => {
    const { submit } = useACHPaymentMethodCreatorState();
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'paymentMethod.creator.title',
    });

    return (
        <FormInitBase loadingOverlayProps={{ loadingOverlayText: 'paymentMethod.please-wait' }}>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={STEPS.FIRST_STEP}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="paymentMethod.creator.paymentMethod.step.label"
                            defaultMessage="Payment Method"
                        />
                    }
                    id="paymentMethod"
                >
                    <StaticIndications />
                    <BillingBreadcrumbs className={styles['breadcrumbs-container']} title={title} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="paymentMethod.creator.ACH_BANK_DIRECT_DEBIT"
                                    defaultMessage="Credit Card"
                                />
                            }
                            id="achBankDirectDebit"
                        >
                            <PaymentMethodACHSection />
                        </WizardStep>
                        <PaymentCreatorFormButtons onCancel={onCancel} onSubmit={submit} />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const ACHPaymentMethodCreatorWithFormDataProvider = withPaymentProvider(
    withFormDataProvider(ACHPaymentMethodCreator, {
        defaultValuesConfig: paymentMethodInitialValues,
        formContainerId: Wizard.WIZARD_CONTAINER_ID,
        isNetworkOwnerAllowed: true,
    }),
    { paymentMethodType: PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT }
);

export { ACHPaymentMethodCreatorWithFormDataProvider as ACHPaymentMethodCreator };
