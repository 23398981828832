// eslint-disable-next-line no-restricted-syntax
import { initializePhraseAppEditor } from 'react-intl-phraseapp';
import { isNil } from 'lodash';
import { PHRASE } from 'modules/taboola-common-frontend-modules/i18n/hooks/index';
import { getQueryParamFromLocation } from 'modules/taboola-common-frontend-modules/query-params';

class Init {
    init(config) {
        this.phraseAppEditorInitialized = false;
        this.initPhrase(config);
    }

    initPhrase(config) {
        const { phraseProjectId } = config;
        const phraseBranch = getQueryParamFromLocation(PHRASE);
        initializePhraseAppEditor({
            projectId: phraseProjectId,
            phraseEnabled: !!phraseProjectId && !isNil(phraseBranch),
            fullReparse: true,
            autoLowercase: false,
            branch: phraseBranch,
        });
    }
}

export default new Init();
export * from './messages';
