import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import CodeSnippet from 'modules/audiences/components/CodeSnippet/CodeSnippet';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { buildCodeSnippetWithParams } from 'modules/taboola-common-frontend-modules/account-configurations/utils/buildPixelCodeSnippet';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import ActionsCellRenderer from './ActionsCellRenderer';
import styles from './activeEventsActionsCellRenderer.module.scss';

const ViewCodeTooltipContent = () => (
    <FormattedMessage id="tracking.active.events.actionButtons.view.code" defaultMessage="View Code Snippet" />
);

const useEventCodeSnippet = eventName => {
    const { [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET]: codeSnippet } = useCommonConfig([
        COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET,
    ]);

    const [{ id: accountId }] = useSelectedAccount();
    return useMemo(
        () => buildCodeSnippetWithParams(codeSnippet, accountId, eventName),
        [codeSnippet, accountId, eventName]
    );
};

export const ActiveEventsActionsCellRenderer = ({ data, context, ...rest }) => {
    const { open: openModal } = useModal();
    const { eventName } = data;
    const codeSnippet = useEventCodeSnippet(eventName);

    const handleClickEventCodeSnippet = useCallback(
        () =>
            openModal({
                formProps: {
                    hasCancel: false,
                    submitButtonText: (
                        <FormattedMessage
                            id="tracking.active.events.event.code.snippet.button.close"
                            defaultMessage="Close"
                        />
                    ),
                },
                title: (
                    <FormattedMessage
                        id="tracking.event.cone.snippet.label"
                        defaultMessage="Code Snippet"
                        tagName="h2"
                    />
                ),
                content: (
                    <>
                        <div className={styles['description']}>
                            <FormattedMessage
                                id="tracking.settings.pixel.code.description"
                                defaultMessage="Copy the code to clipboard or download by clicking the button on the right"
                            />
                        </div>
                        <CodeSnippet codeSnippetText={codeSnippet} />
                        <div className={styles['subText']}>
                            <FormattedMessage
                                id="tracking.event.code.snippet.subtext"
                                defaultMessage="It may take up to 20 minutes for your pixel status to change after installing it. You can always go to the Help Center to learn more about pixel management."
                            />
                        </div>
                    </>
                ),
                className: styles['modal'],
            }),
        [openModal, codeSnippet]
    );

    const buttonConfigs = [
        {
            onClick: () => {
                handleClickEventCodeSnippet();
            },
            dataMetricComponent: 'ViewCodeButton',
            iconLabel: 'tracking-section',
            tooltipContent: <ViewCodeTooltipContent />,
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

ActiveEventsActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    ...ActionsCellRenderer.propTypes,
};
