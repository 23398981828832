import { createSelector } from 'reselect';
import { useSelectedReportIdParam } from 'modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { REPORT_DATA_MAP_KEYS } from '../config/reportsBaseConfig';

export const campaignsStateSelector = state => state.campaignsReducer;

export const campaignsMapSelector = createSelector(campaignsStateSelector, campaignsState => campaignsState.campaigns);

export const creativesMapSelector = createSelector(campaignsStateSelector, campaignsState => campaignsState.creatives);

/**
 * @deprecated - use useSelectedReportIdParam
 */
export const selectedReportSelector = useSelectedReportIdParam.createSelector();

export const reportDataMapSelector = createSelector(
    campaignsStateSelector,
    selectedReportSelector,
    (campaignsState, selectedReport) => campaignsState[REPORT_DATA_MAP_KEYS[selectedReport]]
);

export const totalCampaignsCountSelector = createSelector(
    campaignsStateSelector,
    campaignsState => campaignsState.totalCampaignsCount
);
