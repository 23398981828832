import _ from 'lodash';

export const groupColumnsByPresetName = columns => _.groupBy(columns, 'presetName');

const mapColDefsToColId = columnsDef =>
    columnsDef.reduce((result, { colId, headerName, field }) => {
        const mergedObj = {
            [colId]: {
                colId,
                headerName,
                field,
            },
        };

        return _.assign(result, mergedObj);
    }, {});

const mapColDefsToCustomColumnObjects = (columnsDef, customPresetColumns) => {
    const colIdMap = mapColDefsToColId(columnsDef);
    const nestedCustomColumnObjects = _.keys(customPresetColumns)
        // map the keys of the customColumnPreset
        .map(presetName =>
            customPresetColumns[presetName]
                // to arrays of custom column definition objects
                .map(colId => {
                    const col = colIdMap[colId];
                    if (!col) {
                        return null;
                    }

                    return {
                        id: col.colId,
                        label: col.headerName || col.field,
                        presetName,
                    };
                })
        );

    // then flatten all the objects into 1 array, and filter out the unconfigured, null values
    return _.flatten(nestedCustomColumnObjects).filter(field => field !== null);
};

export const getCustomColumnsFromColumnDefsAndPreset = (columnsDef, customPresetColumns) =>
    mapColDefsToCustomColumnObjects(columnsDef, customPresetColumns);
