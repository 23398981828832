import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../hooks';
import { createResourcesApi } from './resourcesApiFactory';

export const useResourcesApi = () => {
    const api = useApi();
    const resourcesApi = useMemo(() => createResourcesApi(api), [api]);

    return resourcesApi;
};

export const useCountryCodeQuery = code => {
    const resourcesApi = useResourcesApi();
    const query = useQuery(
        ['country', code],
        async () => {
            const [country] = await resourcesApi.getCountriesByCodes([code]);
            return country;
        },
        {
            // Disable background fetching, since we can safely use the cached country data
            refetchOnMount: false,
        }
    );

    return query;
};
