import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Textarea, UploadOutlinedIcon } from 'tuui';
import { DragAndDropFile, DragAndDropFileContext } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './srtFileUploadSection.module.scss';

const SRT_FILE = { 'text/*': ['.srt'] };

export const SrtFileUpload = ({ setSrtFile, srtFile }) => {
    const [fileContent, setFileContent] = useState();

    useEffect(() => {
        return () => {
            setFileContent(null);
            setSrtFile(null);
        };
    }, [setFileContent, setSrtFile]);

    const uploadButtonProps = useMemo(
        () =>
            srtFile
                ? {
                      id: 'video.creative.creator.logo.subtitles.file.button.change',
                      defaultMessage: 'Change SRT File',
                  }
                : {
                      id: 'video.creative.creator.logo.subtitles.file.button.upload',
                      defaultMessage: 'Upload SRT File',
                  },
        [srtFile]
    );

    const onDropHandler = useCallback(
        async files => {
            const file = files[0];
            setSrtFile(file);
        },
        [setSrtFile]
    );
    useEffect(() => {
        if (srtFile) {
            srtFile.text().then(text => setFileContent(text));
        }
    }, [srtFile]);

    return (
        <DragAndDropFile fileTypes={SRT_FILE} multiple={false} onDrop={onDropHandler} inputId="srt-file">
            <DragAndDropFileContext.Consumer>
                {({ openFileDialog }) => (
                    <>
                        <Button onClick={openFileDialog} size={Button.size.md} variant={Button.variant.ghost}>
                            <UploadOutlinedIcon />
                            <FormattedMessage {...uploadButtonProps} />
                        </Button>
                        {srtFile && <Textarea className={styles['textArea']} value={fileContent} readOnly />}
                    </>
                )}
            </DragAndDropFileContext.Consumer>
        </DragAndDropFile>
    );
};
