import React from 'react';
import CampaignScheduler from '../CampaignScheduler/CampaignScheduler';
import { CampaignState } from '../CampaignState/CampaignState';
import { ScheduleSectionFrame } from '../ScheduleSectionFrame';
import TimeFrame from '../TimeFrame/TimeFrame';

export const ScheduleSection = () => (
    <ScheduleSectionFrame>
        <TimeFrame />
        <CampaignScheduler />
        <CampaignState />
    </ScheduleSectionFrame>
);

export default ScheduleSection;
