import React, { createContext, useMemo, useContext } from 'react';
import { DASHBOARD_RANGE_PICKER_PRESET_LABELS, DASHBOARD_RANGE_PICKER_HOOKS } from 'taboola-ultimate-ui';
import useDynamicRangePickerPresets from 'modules/campaigns/modules/campaigns-reports/hooks/useDynamicRangePickerPresets';
import { getPresetLabels } from 'modules/campaigns/modules/campaigns-reports/utils/dashboardRangePickerUtils';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';

const { usePresetConfigs: useBasePresetConfigs } = DASHBOARD_RANGE_PICKER_HOOKS;

export const DatePresetContext = createContext({});

export const DatePresetContextProvider = ({ children }) => {
    const { formatMessage } = useIntl();
    const { timeZoneName } = useAccount();

    const basePresetLabels = getPresetLabels(formatMessage, DASHBOARD_RANGE_PICKER_PRESET_LABELS);
    const basePresetConfigs = useBasePresetConfigs(timeZoneName, basePresetLabels);
    const dynamicPresetConfigs = useDynamicRangePickerPresets({ timeZoneName });

    const presetConfigs = useMemo(
        () => ({ ...basePresetConfigs, ...dynamicPresetConfigs }),
        [basePresetConfigs, dynamicPresetConfigs]
    );

    return <DatePresetContext.Provider value={presetConfigs}>{children}</DatePresetContext.Provider>;
};

export const useDatePresetContext = () => useContext(DatePresetContext);
