import React, { useState, useCallback } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { AutoAwesomeOutlinedIcon, ImageMediaIcon } from 'tuui';
import { RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MEDIA_COLUMN_COUNT } from '../../../../creative-creator/config';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import { MEDIA_LIBRARY_SUBTABS } from '../consts';
import { FavoritedMediaSubTab } from './FavoritedMediaSubTab';
import { RecentlyUsedMediaSubTab } from './RecentlyUsedMediaSubTab';
import styles from './MediaLibraryTab.module.scss';

const classNameBuilder = classnames.bind(styles);

const subTabs = [
    {
        id: MEDIA_LIBRARY_SUBTABS.RECENTLY_USED,
        caption: {
            id: 'creative.editor.tab.recentlyUsed.caption',
            defaultMessage: 'Media',
        },
        Component: RecentlyUsedMediaSubTab,
        icon: <ImageMediaIcon width={31} height={31} className={styles['image-media-icon']} />,
    },
    {
        id: MEDIA_LIBRARY_SUBTABS.FAVORITES,
        caption: {
            id: 'creative.editor.tab.favorites.caption',
            defaultMessage: 'AI-Generated Images',
        },
        Component: FavoritedMediaSubTab,
        icon: <AutoAwesomeOutlinedIcon width={21} height={21} />,
    },
];

const MediaLibraryTab = ({ contentType, columnCount, onSelectMedia, ...rest }) => {
    const [selectedTab, setSelectedTab] = useState(MEDIA_LIBRARY_SUBTABS.RECENTLY_USED);
    const isFavoritesViewEnabled = useConfigMatch({
        [FEATURE_FLAGS.FAVORITED_MEDIA_ENABLED]: 'true',
        [FEATURE_FLAGS.GEN_AI_AB_TEST_WITH_AI]: 'true',
    });

    const selectMediaHandler = useCallback(
        (data, selected, options = {}, mediaUploadSource) => {
            const { shouldEnableCropSubmitAlways } = options;
            onSelectMedia(() => ({ url: data.url }), {
                id: data.id,
                mediaUploadSource,
                fileType: data.fileType,
                gifUrl: data.gifUrl,
                selected,
                fallbackImageUrl: data.fallbackImageUrl,
                shouldEnableCropSubmitAlways,
            });
        },
        [onSelectMedia]
    );

    const { campaignId } = useMediaTabsContext();
    const tabHeaders = subTabs.map(({ caption, icon, id, metricsAttributes }) => (
        <RadioIcon
            key={id}
            title={<FormattedMessage id={caption.id} defaultMessage={caption.defaultMessage} />}
            iconComponent={icon}
            value={id}
            hideRadioPointer={true}
            titleClassName={styles['title']}
            iconContainerClassName={classNameBuilder('icon-container', { selected: id === selectedTab })}
            labelClassName={styles['label']}
            checkedRadioColor={styles['checkedRadioColor']}
            {...metricsAttributes}
        />
    ));

    const tabContent = subTabs.map(
        ({ Component, id }) =>
            id === selectedTab && (
                <Component
                    key={id}
                    onSelectMedia={onSelectMedia}
                    selectMediaHandler={selectMediaHandler}
                    contentType={contentType}
                    columnCount={columnCount}
                    {...rest}
                />
            )
    );

    return (
        <div>
            {isFavoritesViewEnabled && (
                <RadioGroup
                    className={styles['group']}
                    selectedValue={selectedTab}
                    onChange={tabName => {
                        setSelectedTab(tabName);
                        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                            component: 'Media Library Subtab',
                            value: tabName,
                            taboolaCampaignId: campaignId,
                        });
                    }}
                >
                    {tabHeaders}
                </RadioGroup>
            )}
            {tabContent}
        </div>
    );
};

MediaLibraryTab.propTypes = {
    onSelectMedia: PropTypes.func,
    contentType: PropTypes.string,
    columnCount: PropTypes.number,
};

MediaLibraryTab.defaultProps = {
    columnCount: MEDIA_COLUMN_COUNT,
    onSelectMedia: noop,
};

export default MediaLibraryTab;
