import { utcDateFormatter } from './dateFormatter';

const lastUpdateTimeFormatter = ({ value, i18n }) => {
    const formattedTime = i18n.formatTime(value, { timeZone: 'UTC' });
    const timeOfDay = (formattedTime.split(' ')[1] || '').toLowerCase();
    const hourOfDay = formattedTime.split(':')[0];
    return `${hourOfDay}${timeOfDay}`;
};

const dateWithLastUpdateTimeFormatter = ({ value, rowData, i18n }) => {
    const { lastUpdateTime } = rowData;
    const formattedDate = utcDateFormatter({ value, i18n });

    if (!lastUpdateTime) {
        return formattedDate;
    }

    const formattedTime = lastUpdateTimeFormatter({ value: lastUpdateTime, i18n });
    return i18n.formatMessage(
        {
            id: 'app.campaigns.reports.grid.dateWithLastUpdateTime',
            defaultMessage: '{date} (Updated {time})',
        },
        {
            date: formattedDate,
            time: formattedTime,
        }
    );
};

export default dateWithLastUpdateTimeFormatter;
