import React from 'react';
import noop from 'lodash/noop';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { generateErrorIndication } from 'services/utils/errorMessagesUtils';
import { dimensionSelector } from '../../../../../selectors';
import { creativesApi } from '../../../../../services/api';
import { REPORT_TYPE, resolveReportIdByCampaignDimension } from '../../../config';

export const successIndication = {
    message: <FormattedMessage id="creative.creator.save.success" defaultMessage="Ads successfully saved" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const errorMessage = (
    <FormattedMessage id="creative.creator.save.error" defaultMessage="We were not able to save the new ads" />
);

const createCreatives =
    (accountId, campaign, creatives, videoCreatives, onError = noop) =>
    async (dispatch, getState) => {
        const dimension = dimensionSelector(getState());

        try {
            const createVideoCreativeCalls = videoCreatives.map(videoCreative =>
                creativesApi.createVideoCreative(accountId, campaign.id, videoCreative)
            );
            const createCreativeCall = creativesApi.createCreatives(accountId, campaign.id, creatives);
            await Promise.all([...createVideoCreativeCalls, createCreativeCall]);

            dispatch(addIndication(successIndication));

            return {
                campaignId: campaign.id,
                reportId: resolveReportIdByCampaignDimension(REPORT_TYPE.CREATIVE, dimension),
            };
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error, errorMessage)));
            onError();
            throw error;
        }
    };

export default createCreatives;
