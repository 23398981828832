import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames/bind';
import { get, isEmpty } from 'lodash';
import { Button, KeyboardArrowLeftOutlinedIcon, DragHandleIcon } from 'tuui';
import { useEventListener } from 'taboola-ultimate-ui';
import { useChatAgentContext } from 'modules/taboola-common-frontend-modules/chat-agent/hooks/useChatAgentContext';
import { useCurrentValueGetter } from '../../hooks';
import { authTokenManager, config } from '../../modules/taboola-common-frontend-modules/authentication';
import { useChatAgentSessionId } from '../../modules/taboola-common-frontend-modules/chat-agent/hooks/useChatAgentSessionId';
import { intercomService } from '../../modules/taboola-common-frontend-modules/intercom';
import { PersistentResizable } from '../PersistentResizable/PersistentResizable';
import { useChatAgentMockUrl } from './hooks/useChatAgentMockUrl';
import { useChatAgentPermitted } from './hooks/useChatAgentPermitted';
import styles from './ChatAgent.module.scss';

const classNameBuilder = classnames.bind(styles);
const intercomAnimationTimeout = 300;

const hideElement = element => {
    if (!element) {
        return;
    }
    element.style.visibility = 'hidden';
    element.style.pointerEvents = 'none';
};
const showElement = element => {
    if (!element) {
        return;
    }
    element.style.visibility = 'visible';
    element.style.pointerEvents = 'auto';
};

export const ChatAgent = () => {
    const [intercomLayout, setIntercomLayout] = useState({});
    const {
        isChatOpened,
        isIntercomOpened,
        closeChat,
        chatContainer,
        clearWindowList,
        isChatReady,
        client,
        chatRenderMode,
    } = useChatAgentContext();
    const [paramSessionId] = useChatAgentSessionId();
    const [sessionId] = useState(paramSessionId);
    const hasContainerRef = useRef(false);
    const isChatVisible = isChatOpened && isIntercomOpened;
    const isPermitted = useChatAgentPermitted();
    const chatAgentMockUrl = useChatAgentMockUrl();
    const chatAgentChatUrl = config.getChatAgentChatUrl();
    const src = chatAgentMockUrl || chatAgentChatUrl || `${config.getChatAgentDomain()}/external-resources/chat-agent`;
    const shouldRender = isChatReady && isPermitted && (config.getChatAgentDomain() !== '*' || !!chatAgentChatUrl);
    const intercomContainerRef = useRef(null);
    const intercomLightContainerRef = useRef(null);
    const intercomNodeRef = useRef(null);
    const isChatOpenedGetter = useCurrentValueGetter(isChatOpened);
    const isChatContainerGetter = useCurrentValueGetter(chatContainer);
    const isIntercomOpenedGetter = useCurrentValueGetter(isIntercomOpened);
    const syncIntercomContainerVisibility = useCallback(() => {
        if (isChatOpenedGetter() && isChatContainerGetter()) {
            hideElement(intercomLightContainerRef.current);
            hideElement(intercomContainerRef.current);
            intercomService.hide();
            return;
        }
        showElement(intercomLightContainerRef.current);
        showElement(intercomContainerRef.current);
    }, [isChatOpenedGetter, isChatContainerGetter]);

    const syncIntercomVisibility = useCallback(() => {
        if (!intercomNodeRef.current) {
            return;
        }

        if (isChatOpenedGetter()) {
            hideElement(intercomNodeRef.current);
            return;
        }

        const callback = () => {
            showElement(intercomNodeRef.current);
        };
        if (isIntercomOpenedGetter()) {
            callback();
            return;
        }
        setTimeout(callback, intercomAnimationTimeout);
    }, [isChatOpenedGetter, isIntercomOpenedGetter]);

    const syncToIntercomLayout = useCallback(() => {
        if (!intercomNodeRef.current) {
            return;
        }
        setIntercomLayout({
            width: intercomNodeRef.current.clientWidth,
            height: intercomNodeRef.current.clientHeight,
            left: intercomNodeRef.current.offsetLeft,
            top: intercomNodeRef.current.offsetTop,
        });
    }, []);

    useLayoutEffect(() => {
        if (!shouldRender) {
            return;
        }
        syncIntercomVisibility();
    }, [isChatVisible, syncIntercomVisibility, shouldRender, chatContainer]);

    useLayoutEffect(() => {
        if (!shouldRender) {
            return;
        }
        syncIntercomContainerVisibility();
        const intervalId = setInterval(() => {
            intercomContainerRef.current =
                intercomContainerRef.current || document.getElementById('intercom-container');
            intercomLightContainerRef.current =
                intercomLightContainerRef.current || document.getElementsByClassName('intercom-lightweight-app')[0];
            syncIntercomContainerVisibility();
            if (intercomContainerRef.current || intercomContainerRef.current) {
                clearInterval(intervalId);
            }
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChatOpened, shouldRender]);

    const isIntercomBooted = intercomService.isServiceBooted();

    //This hack is needed to prevent lazy loading animation issue
    useEffect(() => {
        if (!shouldRender || !isIntercomBooted) {
            return;
        }
        intercomService.show();
        intercomService.hide();
    }, [isIntercomBooted, shouldRender]);

    useLayoutEffect(() => {
        if (!shouldRender) {
            return;
        }

        const intercomObserver = new MutationObserver(syncIntercomVisibility);
        const bodyCallback = async mutationList => {
            const intercomMarker = mutationList
                .map(item => `${get(item, 'addedNodes.0.className')}_${get(item, 'addedNodes.0.id')}`)
                .join('_');

            if (!`${intercomMarker}`.includes('intercom')) {
                return;
            }

            await new Promise(resolve => setTimeout(resolve));

            intercomContainerRef.current = document.getElementById('intercom-container');
            intercomLightContainerRef.current = document.getElementsByClassName('intercom-lightweight-app')[0];

            intercomNodeRef.current = document.getElementsByClassName('intercom-messenger-frame')[0];

            if (intercomContainerRef.current || intercomLightContainerRef.current) {
                syncIntercomContainerVisibility();
            }

            if (!intercomNodeRef.current) {
                return;
            }

            syncToIntercomLayout();
            syncIntercomVisibility();
            intercomObserver.observe(intercomNodeRef.current, { subtree: true, childList: true, attributes: true });
            bodyObserver.disconnect();
        };
        // we need to observe body because intercom container is lazy added to body(once) on first open
        const bodyObserver = new MutationObserver(bodyCallback);
        bodyObserver.observe(document.body, { childList: true });
    }, [
        shouldRender,
        isChatOpenedGetter,
        isIntercomBooted,
        syncIntercomVisibility,
        syncToIntercomLayout,
        syncIntercomContainerVisibility,
    ]);

    useEventListener('resize', syncToIntercomLayout);

    if (!shouldRender || !authTokenManager.getToken()) {
        return null;
    }

    const minSize = { width: intercomLayout.width, height: intercomLayout.height };
    const maxSize = {
        width: isEmpty(intercomLayout) ? undefined : window.innerWidth - 2 * intercomLayout.left,
        height: isEmpty(intercomLayout) ? undefined : intercomLayout.top + intercomLayout.height - intercomLayout.left,
    };
    const sessionParam = sessionId ? `&sessionId=${sessionId}` : '';
    let headerParam = chatContainer ? '&hide-header=true' : '';
    if (chatRenderMode) {
        headerParam = `${headerParam}&render-mode=${chatRenderMode}`;
    }
    const query = `?chat-client=${client}${sessionParam}${headerParam}&token=${authTokenManager.getToken()}`;
    const authSrc = `${src}${query}`;

    const iframeContent = <iframe className={classNameBuilder('chat-iframe')} src={authSrc} title="Chat Agent" />;

    if (hasContainerRef.current !== !!chatContainer) {
        hasContainerRef.current = !!chatContainer;
        clearWindowList();
    }

    if (chatContainer) {
        return createPortal(iframeContent, chatContainer);
    }

    return (
        <div
            className={classNameBuilder('wrapper', { collapsed: !isChatVisible || isEmpty(intercomLayout) })}
            style={{
                left: intercomLayout.left,
                bottom: `calc(100vh - ${intercomLayout.top}px - ${intercomLayout.height}px)`,
            }}
        >
            <PersistentResizable
                className={classNameBuilder('inner')}
                minSize={minSize}
                maxSize={maxSize}
                persistencyKey="chatAgentSize"
            >
                <div className={classNameBuilder('resize-icon')} fontSize="small">
                    <DragHandleIcon />
                </div>
                <div className={classNameBuilder('close-button')}>
                    <Button
                        className={classNameBuilder('back-button')}
                        size={Button.size.lg}
                        variant={Button.variant.ghost}
                        onClick={() => closeChat()}
                        aria-label="Close chat"
                    >
                        <KeyboardArrowLeftOutlinedIcon fontSize="large" className={classNameBuilder('icon')} />
                    </Button>
                </div>
                {iframeContent}
            </PersistentResizable>
        </div>
    );
};
