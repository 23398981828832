import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useMultiLayerPathParams } from '../../../../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useFetchedCampaign } from '../../../../hooks/useFetchedCampaign';
import HeaderLoading from '../../../common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import styles from './performanceRecommendationsHeader.module.scss';

export const PerformanceRecommendationsHeader = () => {
    const { campaignId } = useMultiLayerPathParams();
    const { campaign } = useFetchedCampaign(campaignId);
    const campaignName = campaign?.name;

    return (
        <div className={styles['header']}>
            <h1 className={styles['title']}>
                <FormattedMessage
                    id="app.performance-recommendations.header"
                    defaultMessage="Personalized Recommendations"
                />
            </h1>
            {campaignName ? (
                <div className={styles['campaign-details']}>
                    <h2 className={styles['campaign-name']}>{campaignName}</h2>
                    <div className={styles['details-separator']} />
                    <h2 className={styles['campaign-id']}>{campaignId}</h2>
                </div>
            ) : (
                <span aria-label="Campaign details are loading">
                    <HeaderLoading className={styles['body-loading']} />
                </span>
            )}
        </div>
    );
};

export default PerformanceRecommendationsHeader;
