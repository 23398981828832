import { getLocationSearch, mergeQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import { CAMPAIGN_ID } from '../hooks/useSelectedCampaignId';
import { SELECT_CAMPAIGN } from './actionTypes';

/**
 * @deprecated - use useSelectedCampaignId
 */
export default selectedCampaign => {
    const { id, campaignId } = selectedCampaign;

    return {
        type: SELECT_CAMPAIGN,
        payload: selectedCampaign,
        meta: {
            query: mergeQueryParams(getLocationSearch(), {
                [CAMPAIGN_ID]: id ?? campaignId,
            }),
        },
    };
};
