import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useSitesApi } from 'services/api';

const errorIndicator = {
    message: (
        <FormattedMessage
            id="app.campaign.editor.publisher.targeting.allSites.fetch.error"
            defaultMessage="We were not able to fetch the list of blockable sites"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const useFetchSites = () => {
    const { getAllSites, checkValidSites } = useSitesApi();
    const {
        formAccount: { accountId: defaultAccountId },
    } = useFormDataContext();
    const dispatch = useDispatch();

    const fetchAllSites = useCallback(
        async (accountId, searchText, page, pageSize, supplyGroup) => {
            const validAccountId = accountId || defaultAccountId;
            try {
                if (!validAccountId) {
                    throw new Error('no valid account id');
                }
                const results = await getAllSites(validAccountId, searchText, page, pageSize, supplyGroup);
                return results;
            } catch (err) {
                dispatch(addIndication(errorIndicator));
                return {};
            }
        },
        [getAllSites, defaultAccountId, dispatch]
    );

    return {
        fetchAllSites,
        checkValidSites,
    };
};
