import { CREATIVE_TYPE, FORM_MODES } from 'modules/campaigns/config';
import { duplicateCreativeHandler } from 'modules/campaigns/modules/creative-editor/flows/duplicateCreativeHandler';
import { duplicateMotionAdCreativeHandler } from 'modules/campaigns/modules/creative-editor/flows/duplicateMotionAdCreativeHandler';
import { editCreativeHandler } from 'modules/campaigns/modules/creative-editor/flows/editCreativeHandler';
import { editMotionAdCreativeHandler } from 'modules/campaigns/modules/creative-editor/flows/editMotionAdCreativeHandler';
import { unifiedCreativeDuplicateHandler } from 'modules/campaigns/modules/creative-editor/flows/unifiedCreativeDuplicateHandler';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';

export const useCreativeEditorSubmitHandlerWrapper = (mode, creativeType) => {
    const isUnifiedCreativesFormDuplicateEnabled = useConfigMatch({
        [FEATURE_FLAGS.UNIFIED_CREATIVE_FORM_DUPLICATE_ENABLED]: 'true',
    });
    const submitHandlerDuplicateModeMap = {
        [CREATIVE_TYPE.MOTION]: duplicateMotionAdCreativeHandler,
        [CREATIVE_TYPE.IMAGE]: duplicateCreativeHandler,
    };
    const duplicateModeHandler = isUnifiedCreativesFormDuplicateEnabled
        ? unifiedCreativeDuplicateHandler
        : submitHandlerDuplicateModeMap[creativeType];

    const editModeHandlerMap = {
        [CREATIVE_TYPE.MOTION]: editMotionAdCreativeHandler,
        [CREATIVE_TYPE.IMAGE]: editCreativeHandler,
    };

    return mode === FORM_MODES.EDIT ? editModeHandlerMap[creativeType] : duplicateModeHandler;
};
