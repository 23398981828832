import React from 'react';
import classnames from 'classnames/bind';
import { WarningAmberIcon } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './croppingModalWarningBadge.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CroppingModalWarningBadge = ({ className, shouldShowOutcropWarning, errorMessage }) => {
    return (
        <div className={classNameBuilder('container', className)}>
            <Tooltip position={TOOLTIP_POSITION.RIGHT}>
                {shouldShowOutcropWarning ? (
                    <FormattedMessage
                        id="cropping.content.outcrop.warning.tooltip"
                        defaultMessage="The aspect ratio of this image does not align with Taboola's best practices. Use the AI Image Extender, unless you're targeting Yahoo mobile with the correct image ratio uploaded"
                    />
                ) : (
                    errorMessage
                )}
            </Tooltip>
            <WarningAmberIcon className={styles['icon']} fontSize="small" />
        </div>
    );
};
