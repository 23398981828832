import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { isProgrammaticAccount } from '../../../../../../account-management/accountType';
import { reportsBaseConfig, REPORT_TYPE, CREATIVE_STATUS } from '../../../../../config';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import { TITLE, ID, STATUS, CONTENT, THUMBNAIL_URL, THUMBNAIL_URL_COMFORTABLE } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import { bulkActions } from './bulkActions';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graph } from './graph';
import { customColumns, performanceColumns, setupColumns, defaultColumns } from './reportPresets';

const isMsV2WidePerformanceEnabled = (accountConfig, permissionsMap) =>
    permissionsMap['MULTI_SOURCE_V2'] || accountConfig[FEATURE_FLAGS.CREATIVE_REPORT_USE_WIDE_CONFIG_MSV2] === 'true';

export const creativeReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CREATIVE],
    endpoint: 'campaign-report-by-creative',
    graph,
    columnsDef: columnDefinitions,
    rowIdField: ID.field,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    rowHeight: 62,
    comfortableRowHeight: 112,
    searchPlaceholderMsgId: 'app.reports.byCreative.search.placeholder',
    searchField: TITLE.field,
    inlineEditPermissions: ['CREATIVE_EDIT'],
    sortMap: {
        [STATUS.field]: 'itemStatus',
        [ID.field]: 'itemId',
        [CONTENT.field]: TITLE.field,
        [THUMBNAIL_URL_COMFORTABLE.field]: THUMBNAIL_URL.field,
    },
    bulkActions,
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            accountConfigurations: {
                [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'false',
                [FEATURE_FLAGS.CREATIVE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
                [FEATURE_FLAGS.CREATIVE_REPORT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        { id: FILTER_TYPE.CAMPAIGN_ITEM_STATUS, multiSelect: true },
        {
            id: FILTER_TYPE.OS_FAMILY,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.BROWSER,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.PLATFORM,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.COUNTRY,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.SITE,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CAMPAIGN_ITEM_PERFORMANCE_STATUS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.TARGET_PAGE_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.IAB_CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.LANGUAGE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.CLICKS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.SPENT,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.VISIBLE_IMPRESSIONS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.IMPRESSIONS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.VCTR,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CTR,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.AVG_CPC,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CVR,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.ACTIONS_CONVERSIONS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CPA,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.VCPM,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CPM,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.CONVERSIONS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
        {
            id: FILTER_TYPE.ROAS,
            visibilityCondition: ({ selectedAccount, accountConfig, permissionsMap }) =>
                !isProgrammaticAccount(selectedAccount) && isMsV2WidePerformanceEnabled(accountConfig, permissionsMap),
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP200, label: 'Top 200', messageId: 'report.topResults.200' }],
            accountConfigurations: {
                [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'false',
                [FEATURE_FLAGS.CREATIVE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
                [FEATURE_FLAGS.CREATIVE_REPORT_USE_WIDE_CONFIG_MSV2]: 'false',
            },
        },
        {
            id: FILTER_TYPE.CAMPAIGN_ITEM_STATUS,
            type: FILTER_TYPE.CAMPAIGN_ITEM_STATUS,
            values: [
                { value: CREATIVE_STATUS.RECENT, label: 'Recent', messageId: 'app.campaigns.creative.status.RECENT' },
            ],
            accountConfigurations: {
                [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true',
                [FEATURE_FLAGS.CREATIVE_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'false',
            },
        },
    ],
};

export default creativeReportConfig;
