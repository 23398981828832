import React from 'react';
import { MailIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isLowTouchAccount } from '../../../../../account-management';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import AdditionalRequests from '../AdditionalRequests/AdditionalRequests';

const CommentsSection = () => {
    const {
        formAccount: { tier },
    } = useFormDataContext();

    const isCommentSectionDisabled = useConfigMatch({
        [FEATURE_FLAGS.IS_CAMPAIGN_COMMENT_SECTION_ENABLED]: 'false',
    });

    const hideComments = isCommentSectionDisabled || isLowTouchAccount(tier);

    if (hideComments) {
        return null;
    }

    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_COMMENTS"
            header={
                <SectionHeader
                    headerIcon={<MailIcon />}
                    headerText={<FormattedMessage id="campaign.editor.comments" />}
                />
            }
        >
            <AdditionalRequests />
        </CommonCollapsibleCard>
    );
};

export default CommentsSection;
