import React from 'react';
import { WeekIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';

const ScheduleSectionFrame = ({ children }) => (
    <CommonCollapsibleCard
        id="CAMPAIGN_SCHEDULE"
        header={
            <SectionHeader headerIcon={<WeekIcon />} headerText={<FormattedMessage id="campaign.editor.schedule" />} />
        }
    >
        {children}
    </CommonCollapsibleCard>
);

export { ScheduleSectionFrame };
export default ScheduleSectionFrame;
