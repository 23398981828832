import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { videoCreativesApi } from 'services/api';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { transformVideoCreativeFromGW } from '../../video-creative-creator/hooks/videoCreativeTransformers';
import { fetchCreativeForEditError, fetchCreativeForEditSuccess, requestFetchCreativeForEdit } from '../actions';

const indication = {
    message: (
        <FormattedMessage
            id="creative.drawer.error"
            defaultMessage="Something went wrong while trying to fetch the requested creative."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const fetchCreativeForEdit =
    (accountId, campaignId, creativeId, { hideError } = {}) =>
    async dispatch => {
        dispatch(requestFetchCreativeForEdit());
        try {
            const rawCreative = await videoCreativesApi.getCreative(accountId, campaignId, creativeId);
            const creative = transformVideoCreativeFromGW(rawCreative);
            dispatch(fetchCreativeForEditSuccess(creative));
            return creative;
        } catch (error) {
            if (!hideError) {
                dispatch(addIndication(indication));
                dispatch(fetchCreativeForEditError(error));
            }
        }
    };

export default fetchCreativeForEdit;
