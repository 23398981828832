import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    DelimiterWrapper,
    NotificationIcon,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    TooltipV2 as Tooltip,
} from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { EVENT_TYPES, TAG_TYPES } from '../config';
import Section from './Section';
import delimiterStyle from '../delimiter.module.scss';
import styles from './listItem.module.scss';

const classNameBuilder = classnames.bind(styles);

const ListItem = ({ item, msgIdPrefix, deleteItem }) => {
    const { type, eventType, tagValue: { value } = {}, error = {}, loading } = item;
    const { defaultMessage: errorMessage, id: errorId } = error;
    const validError = !!errorMessage && !!errorId;

    const handleOnClick = useCallback(() => {
        deleteItem(item);
    }, [item, deleteItem]);

    const errorNotif = validError && (
        <div>
            <NotificationIcon width={16} height={16} className={styles['notification']} />
            <Tooltip arrow interactive>
                <FormattedMessage id={errorId} defaultMessage={errorMessage} />
            </Tooltip>
        </div>
    );

    return (
        <div className={styles['container']}>
            <DelimiterWrapper delimiterClassName={delimiterStyle['delimiter']}>
                <Section
                    id={`${msgIdPrefix}.tagType.${type}`}
                    className={styles['tag-type']}
                    loaderWidth={styles.tagTypeWidth}
                    loading={loading}
                    testId="tagType"
                />
                <Section
                    defaultMessage={value}
                    className={styles['value']}
                    tooltip={
                        <Tooltip interactive arrow>
                            {value}
                        </Tooltip>
                    }
                    notification={errorNotif}
                    loaderWidth={styles.valueWidth}
                    loading={loading}
                    testId="tagValue"
                />
                <Section
                    id={`${msgIdPrefix}.eventType.${eventType}`}
                    className={styles['event-type']}
                    loaderWidth={styles.eventTypeWidth}
                    loading={loading}
                    testId="eventType"
                />
            </DelimiterWrapper>
            <StyledButton
                className={classNameBuilder('remove-button', { hidden: loading })}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                iconBefore="close"
                onClick={handleOnClick}
            />
        </div>
    );
};

const ThirdPartyTag = PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TAG_TYPES)),
    eventType: PropTypes.oneOf(Object.values(EVENT_TYPES)),
    tagValue: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
    }),
    loading: PropTypes.bool,
    id: PropTypes.number,
});

ListItem.propTypes = {
    item: ThirdPartyTag,
    deleteItem: PropTypes.func,
    msgIdPrefix: PropTypes.string,
};

export default ListItem;
