import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import TipText from '../TipText';
import styles from './tooltipSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const TooltipSection = ({ containerClass, headerClass, bodyClass, header, tip, children }) => (
    <div className={classNameBuilder('container', containerClass)}>
        {header && <div className={classNameBuilder('header', headerClass)}>{header}</div>}
        <div className={classNameBuilder('body', bodyClass)}>{children}</div>
        {tip && <TipText className={styles['tip']}>{tip}</TipText>}
    </div>
);

TooltipSection.propTypes = {
    /** Class added to the container element */
    containerClass: PropTypes.string,
    /** Class added to the header element */
    headerClass: PropTypes.string,
    /** Class added to the body element */
    bodyClass: PropTypes.string,
    /** Text to render above the body of the section */
    header: PropTypes.node,
    /** Text to render below the body with a Tip: prefixed */
    tip: PropTypes.node,
    /** Body of the tooltip section */
    children: PropTypes.node,
};

export default TooltipSection;
