import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Dotdotdot } from 'taboola-ultimate-ui';
import { CTA } from 'modules/campaigns/config';
import { CtaButton } from '../CtaButton/CtaButton';
import { ReportSearchHighlight } from '../ReportSearchHighlight/ReportSearchHighlight';
import styles from './contentCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);

const ContentCellRenderer = forwardRef((props, ref) => {
    const { data, lines, ctaMsgIdPrefix, isSearchable } = props;
    const { title, description, cta } = data;
    const { ctaType } = cta || {};
    const ctaMsgId = `${ctaMsgIdPrefix}.${ctaType}`;

    const containerClassName = classNameBuilder('container', {
        full: title && description && ctaType && ctaType !== CTA.NONE,
    });

    return (
        <div className={containerClassName} ref={ref}>
            <Dotdotdot className={styles['title']} lines={lines}>
                <span className={styles['value']}>{isSearchable ? <ReportSearchHighlight text={title} /> : title}</span>
            </Dotdotdot>
            {description && (
                <Dotdotdot lines={lines}>
                    <span data-automation="content-cell-renderer-description" className={styles['value']}>
                        {isSearchable ? <ReportSearchHighlight text={description} /> : description}
                    </span>
                </Dotdotdot>
            )}
            {ctaType && ctaType !== CTA.NONE && (
                <div className={styles['cta-btn-container']}>
                    <CtaButton ctaMsgId={ctaMsgId} ctaType={ctaType} />
                </div>
            )}
        </div>
    );
});

ContentCellRenderer.propTypes = {
    data: PropTypes.object,
    lines: PropTypes.number,
    ctaMsgIdPrefix: PropTypes.string,
};

ContentCellRenderer.defaultValues = {
    lines: 2,
};

export default ContentCellRenderer;
