import React, { useCallback } from 'react';
import { BlockOutlinedIcon, CheckCircleOutlinedIcon } from 'tuui';
import { Dotdotdot } from 'taboola-ultimate-ui';
import TargetingTypes from '../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { TopicArticlesPreview } from './CustomContextualSegmentArticlesPreview/TopicArticlesPreview';
import { TopicsDetails } from './TopicsDetails';
import { useArticlesPreviewEnabled } from './hooks/useArticlesPreviewEnabled';
import styles from './commonTopicsListItemContent.module.scss';

export const TopicsListItem = ({ item, deleteItem, index }) => {
    const { targetingType, name, size, articles, articles_preview: articleSamples, isLoadingArticles } = item;
    const handleOnClick = useCallback(() => {
        deleteItem(item);
    }, [deleteItem, item]);

    const isArticlesPreviewEnabled = useArticlesPreviewEnabled();

    return (
        <div className={styles['container']} aria-label="topics-list-item">
            <span>
                {targetingType === TargetingTypes.INCLUDE ? (
                    <CheckCircleOutlinedIcon className={styles['include']} />
                ) : (
                    <BlockOutlinedIcon className={styles['exclude']} />
                )}
            </span>
            <div className={styles['label']}>
                <Dotdotdot lines={3}>
                    <span>{name}</span>
                </Dotdotdot>
            </div>
            <TopicsDetails name={name} size={size} articles={articles} handleOnClick={handleOnClick} showRemoveButton />
            {isArticlesPreviewEnabled && (
                <TopicArticlesPreview
                    articleSamples={articleSamples}
                    isLoading={isLoadingArticles}
                    topicName={name}
                    totalArticles={articles}
                />
            )}
        </div>
    );
};
