import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { errorMessagesUtils } from 'services/utils';
import { useSelectedAccount } from '../../../hooks';
import { usePerformanceRulesApi } from '../../../services/api/performanceRulesApi';
import { addIndication } from '../../taboola-common-frontend-modules/Indications';
import { PERFORMANCE_RULES_ROUTE } from '../config';
import { performanceRulesTransformerToGW } from '../transformers/performanceRulesTransformerToGW';

const successIndication = {
    message: (
        <FormattedMessage
            id="app.create.performance.rules.success"
            defaultMessage="Custom Rules successfully created"
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="app.create.performance.rules.error.highlight"
            defaultMessage="Unable to create custom rules"
        />
    ),
});

const sendGTMEvent = transformRule => {
    try {
        const ruleMetrics = transformRule.data.conditions.map(condition => condition.ruleMetric);
        ruleMetrics.forEach(ruleMetric => {
            if (ruleMetric === 'SITE_NAME') {
                const conditionValues = transformRule.data.conditions
                    .map(condition => condition.conditionValue)
                    .filter(value => value !== undefined);

                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: 'Save Rules - SITE_NAME',
                    value: conditionValues,
                    pageName: getPageName(),
                });
            }
        });
    } catch (e) {}
};

export const usePerformanceRulesCreatorState = () => {
    const [{ accountName }] = useSelectedAccount();
    const dispatch = useDispatch();
    const { submit, step, setStep, prevStep, nextStep } = useFormState();

    const { createPerformanceRules } = usePerformanceRulesApi();

    const submitPerformanceRule = useCallback(
        async performanceRule => {
            try {
                const transformConversion = performanceRulesTransformerToGW(performanceRule);
                await createPerformanceRules(accountName, transformConversion);
                await sendGTMEvent(transformConversion);
                dispatch(addIndication(successIndication));
                return true;
            } catch (error) {
                dispatch(addIndication(generateErrorIndication(error)));
                throw error;
            }
        },
        [accountName, createPerformanceRules, dispatch]
    );

    const submitWrapper = useCallback(() => {
        const submitPerformanceRulesWrapper = performanceRule => {
            return submitPerformanceRule(performanceRule);
        };
        submit(submitPerformanceRulesWrapper, PERFORMANCE_RULES_ROUTE);
    }, [submit, submitPerformanceRule]);

    return { submit: submitWrapper, step, setStep, prevStep, nextStep };
};
