import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CloudinaryImage, ImageContentLoader } from 'taboola-ultimate-ui';
import { FOCUS_TYPES_ARRAY } from '../../../config';
import { scaleCoordinatesToOffsets, scaleOffsetsToCoordinates } from '../../../services';
import FocalPointIndicator from './FocalPointIndicator';
import styles from './focalPointImage.module.scss';

const classNameBuilder = classnames.bind(styles);

const FocalPointImage = ({ className, imageClassName, imageSrc, focusType, focusCoordinates, onChangeCoordinates }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [imageTarget, setImageTarget] = useState(null);
    const [offsets, setOffsets] = useState(null);

    const handlePositionChange = newOffsets => {
        const newCoordinates = scaleOffsetsToCoordinates(imageTarget, newOffsets);

        setOffsets(newOffsets);
        onChangeCoordinates(newCoordinates);
    };

    const handleLoadImage = ({ target: imageTarget }) => {
        const newOffsets = scaleCoordinatesToOffsets(imageTarget, focusCoordinates);

        setOffsets(newOffsets);
        setImageTarget(imageTarget);
        setIsImageLoaded(true);
    };

    return (
        <div className={classNameBuilder('container', className)}>
            <CloudinaryImage
                className={classNameBuilder('image', imageClassName)}
                draggable={false}
                src={imageSrc}
                onLoad={handleLoadImage}
                loadingComponent={<ImageContentLoader width={400} height={250} className={styles['loader']} />}
                width={null}
                height={null}
                quality={null}
            />
            {isImageLoaded && (
                <FocalPointIndicator
                    bounds="parent"
                    focusType={focusType}
                    imageTarget={imageTarget}
                    offsets={offsets}
                    onChangePosition={handlePositionChange}
                />
            )}
        </div>
    );
};

FocalPointImage.defaultProps = {
    onChangeCoordinates: noop,
};

FocalPointImage.propTypes = {
    /* image url */
    imageSrc: PropTypes.string,
    /* focal point coordinates */
    focusCoordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
    /* focal point type */
    focusType: PropTypes.oneOf(FOCUS_TYPES_ARRAY),
    /* called with new { x, y } coordinates */
    onChangeCoordinates: PropTypes.func,
};

export default FocalPointImage;
