import React, { useMemo, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useTopicsListField } from '../../../hooks/useTopicsListField';
import { useTopicsPreviewTracker } from '../../../hooks/useTopicsPreviewTracker';
import Article from './Article';
import styles from './articlesPreview.module.scss';

const classNameBuilder = classnames.bind(styles);
const NUM_OF_ARTICLES_TO_SHOW = 5;
export const orderArrayByUniqueTerms = articlesPreview => {
    const result = [];
    const termsForPreview = articlesPreview.map(i => i.term);
    const maxCount = Math.max(...articlesPreview.map(i => i.articles_preview.length));

    for (let i = 0; i < maxCount; i++) {
        for (let term of termsForPreview) {
            const articles = articlesPreview.find(i => i.term === term).articles_preview;
            if (i < articles.length) {
                result.push({ ...articles[i], term });
            }
        }
    }

    return result;
};

export const ArticlesPreview = () => {
    const { articlesPreview, isLoadingArticles } = useTopicsListField();
    const { reportTermsUniquePublishers } = useTopicsPreviewTracker();
    const { value: excludeTerms } = useFormFieldValue({
        field: 'terms.exclude',
    });

    const articles = useMemo(() => {
        if (articlesPreview && !!articlesPreview.length) {
            const includeArticlePreview = articlesPreview.filter(preview => !excludeTerms.includes(preview.term));
            return orderArrayByUniqueTerms(includeArticlePreview).splice(0, NUM_OF_ARTICLES_TO_SHOW);
        }
        return [];
    }, [articlesPreview, excludeTerms]);

    useEffect(() => {
        if (!isLoadingArticles) {
            reportTermsUniquePublishers(articles, NUM_OF_ARTICLES_TO_SHOW);
        }
    }, [articles, isLoadingArticles, reportTermsUniquePublishers]);

    return isLoadingArticles ? (
        <ContentLoader height={12} width={112} speed={2} className={styles['loading']} />
    ) : (
        <div className={classNameBuilder('articles-container')}>
            {articles.map((article, i) => (
                <Article key={i} articleDetails={article} />
            ))}
        </div>
    );
};
