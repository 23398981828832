import { AUDIENCE_TYPE } from './audiencesConsts';

export const customContextualInitialValues = {
    type: AUDIENCE_TYPE.CUSTOM_CONTEXTUAL,
    platforms: ['PHON'],
    countries: ({ defaultCountry }) => {
        return defaultCountry ? [defaultCountry] : ['US'];
    },
    languages: [],
    terms: {
        include: [],
        exclude: [],
    },
};
