import React from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData/hooks';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../../../taboola-common-frontend-modules/validations/components/hoc';
import styles from '../../../common-campaign-form/components/UserFrequencyCapping/UserFrequencyCapping.module.scss';

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: value => value >= 0 && !(value % 1),
        messageId: 'video.campaign.editor.TotalImpressionCap.validations.error',
        defaultMessage: 'Total Impression Cap must be a whole positive number',
    },
]);

export const TotalImpressionCap = () => {
    const { value: totalImpressionCap, onChange: onTotalImpressionCapChange } = useFormFieldValue({
        field: 'videoBudget.totalImpressionCap',
    });

    return (
        <FormattedNumber value={0}>
            {placeholder => (
                <InputWithValidations
                    id="videoBudget.totalImpressionCap"
                    type={InputTypes.NUMBER}
                    value={totalImpressionCap}
                    placeholder={placeholder}
                    onChange={e => onTotalImpressionCapChange(e.target.value)}
                    inputWrapperClass={styles['inline']}
                    mainClass={styles['inline']}
                />
            )}
        </FormattedNumber>
    );
};
