import { useMemo } from 'react';
import { forEach, invert, values } from 'lodash';
import { CTA_VALUES } from 'modules/campaigns/config';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { PLATFORMS_KEYS } from '../../common-campaign-form';

const generateKeyToLabelMap = (keys, messagePrefix, formatMessage) => {
    const labelsMap = {};
    forEach(keys, key => {
        labelsMap[key] = formatMessage({ id: `${messagePrefix}.${key}` });
    });

    return labelsMap;
};

const PLATFORM_PREFIX = 'campaign.editor.bid.platformParting';
const CTA_TYPE_PREFIX = 'creative.creator.content.cta.option';

export const useStaticDictionaryData = () => {
    const { formatMessage } = useIntl();
    const data = useMemo(() => {
        const platformIdMap = generateKeyToLabelMap(PLATFORMS_KEYS, PLATFORM_PREFIX, formatMessage);
        const platformLabelMap = invert(platformIdMap);
        const ctaTypeToLabelMap = generateKeyToLabelMap(CTA_VALUES, CTA_TYPE_PREFIX, formatMessage);
        const ctaLabelToTypeMap = invert(ctaTypeToLabelMap);
        const ctaType = values(ctaTypeToLabelMap);

        return {
            platformIdMap,
            platformLabelMap,
            ctaTypeToLabelMap,
            ctaLabelToTypeMap,
            ctaType,
        };
    }, [formatMessage]);

    return data;
};
