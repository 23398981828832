'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ICONS = undefined;

var _Icons = require('../../Icons/Icons');

var TYPES = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    CUSTOM: 'custom',
    SUGGESTION: 'suggestion',
    NONE: '',
    // All values bellow are deprecated
    NOTIFICATION: 'notification',
    ALERT: 'alert'
};

var ICONS = {
    info: _Icons.NotificationIcon,
    success: _Icons.OkOIcon,
    warning: _Icons.AlertIcon,
    error: _Icons.AlertIcon,
    custom: _Icons.HelpOIcon,
    // All values bellow are deprecated
    alert: _Icons.AlertOIcon,
    notification: _Icons.NotificationIcon
};

exports.default = TYPES;
exports.ICONS = ICONS;