import React from 'react';
import { CheckCircleOutlinedIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './appliedText.module.scss';

export const AppliedText = successCode => {
    return (
        <div className={styles['success-bar']}>
            <CheckCircleOutlinedIcon className={styles['success-icon']} />
            <FormattedMessage id={successCode.successCode} defaultMessage="Applied!" />
        </div>
    );
};

export default AppliedText;
