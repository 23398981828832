import React from 'react';
import { getPaymentStatusIndicatorType } from '../../../../../billing-and-payments/config/paymentStatusConsts';
import EnumValueCellRenderer from './EnumValueCellRenderer';
import { StatusIndicatorCellRenderer } from './StatusIndicatorCellRenderer';

export const PaymentStatusCellRenderer = ({ value, msgIdPrefix, emptyValue }) => {
    const paymentStatusIndicatorType = getPaymentStatusIndicatorType(value);
    return (
        <StatusIndicatorCellRenderer
            value={value}
            msgIdPrefix={msgIdPrefix}
            emptyValue={emptyValue}
            indicatorType={paymentStatusIndicatorType}
        />
    );
};

PaymentStatusCellRenderer.propTypes = {
    /** Function that maps from value prop to PAYMENT_STATUS_TYPE value */
    ...EnumValueCellRenderer.propTypes,
};

PaymentStatusCellRenderer.defaultProps = {
    ...EnumValueCellRenderer.defaultProps,
};
