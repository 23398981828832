export const COMPONENT_STATUS = {
    ACTIVE: 'ACTIVE',
    LOADING: 'LOADING',
    PROCESSING: 'PROCESSING',
    ERROR: 'ERROR',
    INITIAL: 'INITIAL',
    EMPTY: 'EMPTY',
};

export const USER_TYPE = {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL',
};
