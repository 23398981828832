import { useSelector } from 'react-redux';
import { Indication, TextCheckbox } from 'taboola-ultimate-ui';
import styles from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/performanceRuleEmailNotifications.module.scss';
import { userSelector } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const CreateNewReportCheckBox = () => {
    const { value: createNewScheduledReport, onChange: onCreateNewScheduledReportChange } = useFormFieldValue({
        field: 'createNewScheduledReport',
    });

    const { username } = useSelector(userSelector);

    return (
        <>
            <TextCheckbox
                value={createNewScheduledReport}
                title={
                    <FormattedMessage
                        id="app.performance.rules.setup.email.notifications.create.new.report.checkbox.label"
                        defaultMessage="Create new report"
                    />
                }
                data-metrics-component="performance-rules-emial-notifications-create-new-report-checkbox"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                onSelectCheckbox={() => onCreateNewScheduledReportChange(true)}
                onUnselectCheckbox={() => onCreateNewScheduledReportChange(false)}
                selectedValues={[true]}
                independent
            />
            {createNewScheduledReport && (
                <Indication
                    type=""
                    message={
                        <FormattedMessage
                            id="app.performance.rules.setup.email.notifications.indication"
                            defaultMessage={`You will be notified daily at 10:00 AM to ${username}. Edit these setting under “reports”.`}
                            values={{ username }}
                        />
                    }
                    displayDismissComponent={false}
                    className={styles['indication']}
                />
            )}
        </>
    );
};
