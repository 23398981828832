import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CAMPAIGN_REALTIME].id;

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.SPENT.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.CLICKS.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.CPA.field,
        FIELDS.CVR.field,
        FIELDS.AVERAGE_CPC.field,
        FIELDS.VCTR.field,
        FIELDS.CTR.field,
        FIELDS.VCPM.field,
        FIELDS.CPM.field,
        FIELDS.CONVERSIONS_VALUE.field,
        FIELDS.ROAS.field,
        FIELDS.UNIQUE_IMPRESSIONS.field,
        FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS.field,
        FIELDS.UNIQUE_CLICKS.field,
        FIELDS.CAMPAIGN_CONVERSION_RULES.field,
    ].map(field => generateColumnId(reportId, field)),
    [REPORT_PRESET.SETUP]: [
        FIELDS.STATUS.field,
        FIELDS.ID.field,
        FIELDS.CAMPAIGNS_GROUP_NAME.field,
        FIELDS.DEMAND_TYPE.field,
        FIELDS.ACCOUNT_ID.field,
        FIELDS.ACCOUNT_DESCRIPTION.field,
        FIELDS.BUDGET.field,
        FIELDS.SPENDING_LIMIT.field,
        FIELDS.BID.field,
        FIELDS.CPA_GOAL.field,
        FIELDS.BID_STRATEGY.field,
        FIELDS.SAFETY_LEVEL.field,
        FIELDS.START_DATE.field,
        FIELDS.END_DATE.field,
    ].map(field => generateColumnId(reportId, field)),
};

const defaultColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.NAME.field,
    FIELDS.STATUS.field,
    FIELDS.ID.field,
    FIELDS.DEMAND_TYPE.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.SPENT.field,
    FIELDS.BUDGET.field,
    FIELDS.BID.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
    FIELDS.BID_STRATEGY.field,
    FIELDS.SAFETY_LEVEL.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const performanceColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.NAME.field,
    FIELDS.ID.field,
    FIELDS.SPENT.field,
    FIELDS.VISIBLE_IMPRESSIONS.field,
    FIELDS.CLICKS.field,
    FIELDS.CONVERSIONS.field,
    FIELDS.CPA.field,
    FIELDS.CVR.field,
    FIELDS.AVERAGE_CPC.field,
    FIELDS.VCTR.field,
    FIELDS.VCPM.field,
    FIELDS.CONVERSIONS_VALUE.field,
    FIELDS.ROAS.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const setupColumns = [
    FIELDS.IS_SELECTED.field,
    FIELDS.IS_ACTIVE.field,
    FIELDS.NAME.field,
    FIELDS.ID.field,
    FIELDS.ACCOUNT_ID.field,
    FIELDS.ACCOUNT_DESCRIPTION.field,
    FIELDS.CAMPAIGNS_GROUP_NAME.field,
    FIELDS.STATUS.field,
    FIELDS.BUDGET.field,
    FIELDS.SPENDING_LIMIT.field,
    FIELDS.BID.field,
    FIELDS.CPA_GOAL.field,
    FIELDS.BID_STRATEGY.field,
    FIELDS.SAFETY_LEVEL.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns, performanceColumns, setupColumns };
