import React from 'react';

export const TextToImage = props => (
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x="13.7224"
            y="9.72226"
            width="17.8889"
            height="17.8889"
            rx="2.72222"
            fill="#F6F7F8"
            stroke="currentColor"
            strokeOpacity="0.5"
            strokeWidth="0.777778"
        />
        <path
            d="M28.1114 13.2223V24.1112H17.2225V13.2223H28.1114ZM28.1114 11.6667H17.2225C16.367 11.6667 15.667 12.3667 15.667 13.2223V24.1112C15.667 24.9667 16.367 25.6667 17.2225 25.6667H28.1114C28.967 25.6667 29.667 24.9667 29.667 24.1112V13.2223C29.667 12.3667 28.967 11.6667 28.1114 11.6667ZM24.3314 18.5579L21.9981 21.5679L20.3337 19.5534L18.0003 22.5556H27.3337L24.3314 18.5579Z"
            fill="currentColor"
            fillOpacity="0.5"
        />
        <g filter="url(#filter0_d_320_42737)">
            <rect
                x="4.38889"
                y="0.388889"
                width="17.8889"
                height="17.8889"
                rx="2.72222"
                fill="#F6F7F8"
                stroke="currentColor"
                strokeWidth="0.777778"
            />
            <path d="M7.88867 3.5V5.83333H12.1664V15.1667H14.4998V5.83333H18.7776V3.5H7.88867Z" fill="currentColor" />
        </g>
        <defs>
            <filter
                id="filter0_d_320_42737"
                x="0.888889"
                y="0"
                width="24.8887"
                height="24.8888"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="3.11111" />
                <feGaussianBlur stdDeviation="1.55556" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_320_42737" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_320_42737" result="shape" />
            </filter>
        </defs>
    </svg>
);
