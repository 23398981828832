import React from 'react';
import { Spinner } from 'tuui';
import { CollapsibleList, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import TargetingDropdownBox from '../../../../../campaigns/modules/common-campaign-form/components/TargetingDropdownBox/TargetingDropdownBox';
import { TargetingTypeDropdownOption } from '../../../../../campaigns/modules/common-campaign-form/components/TargetingTypeDropdown';
import { showAllLabel, showLessLabel } from '../../../../../campaigns/services/utils/commonLabels';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useValidations } from '../../../../../taboola-common-frontend-modules/validations';
import { useYahooDomainSuggestionsEnabled } from '../../../../hooks/useYahooExternalAudiencesFF';
import { ItemSuggestions } from '../../../ItemSuggestions/ItemSuggestions';
import { RejectedKeywordsIndication } from '../../RejectedKeywords/RejectedKeywordsIndication';
import { exportToFile } from '../../utils';
import { MailDomainListItem } from './MailDomainListItem';
import { MailDomainsDropdownOption } from './MailDomainsDropdownOption';
import { UploadFile } from './UploadFile';
import { useMailDomainsListField } from './useMailDomainsListField';
import styles from './mailDomainsListField.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

const ItemOptionRenderer = ({ data, selectProps, tempSelection }) => {
    return <MailDomainsDropdownOption data={data} selectProps={selectProps} includedDomains={tempSelection} />;
};

const TargetingDropdownBoxWithValidation = withIndication(TargetingDropdownBox);

const validateMailDomainInput = (input, prefixes) => !prefixes.some(prefix => input.startsWith(prefix));

const noOptionsRenderer = (debouncedSearchInputValue, isLoading, prefixes) => {
    if (!debouncedSearchInputValue) {
        return null;
    }
    if (isLoading) {
        return (
            <div className={styles['spinner']}>
                <Spinner size={21} />
            </div>
        );
    }
    return validateMailDomainInput(debouncedSearchInputValue, prefixes) ? 'No options' : null;
};

export const MailDomainsListField = () => {
    const {
        includeList,
        excludeList,
        clearExcludedItems,
        clearIncludedItems,
        removeIncludeItem,
        removeExcludeItem,
        setTargetingTypeValue,
        addToTempSelection,
        isDisabled,
        targetingType,
        suggestions,
        isLoadingSuggestions,
        audienceName,
        handleAddAllSuggestions,
        handleSuggestionClick,
        tempSelection,
        onMenuClose,
        searchInputValue,
        setSearchInputValue,
        debouncedSearchInputValue,
        autocompletedDomains,
        isLoadingAutoComplete,
        onDropFileHandler,
        removeInvalidKeywords,
        prefixesToRemoveString,
        prefixesToRemove,
        targetingListsFailedValidationData,
        rejectedKeywords,
        rejectedKeywordsTopics,
    } = useMailDomainsListField();

    const validations = [
        {
            validationFn: value => validateMailDomainInput(value, prefixesToRemove),
            options: { prefixes: prefixesToRemoveString },
            messageId: 'audience.editor.external-audience.mail-domains.dropdown.validation.message',
            defaultMessage: 'Please remove prefixes like: {prefixes}.',
        },
    ];
    const { failedValidationData = {} } = useValidations({
        validations,
        valueToValidate: searchInputValue,
        validationDependencies: { searchInputValue },
    });
    const handleInputChange = (query, { action }) => {
        if (action !== 'set-value') {
            setSearchInputValue(query);
        }
        return searchInputValue;
    };
    return (
        <FormField
            inputId="keywords"
            label={
                <FormattedMessage
                    id="audience.editor.external-audience.mail-domains.dropdown.title"
                    defaultMessage="Mail Domains"
                />
            }
            description={
                <FormattedMessage
                    id="audience.editor.external-audience.mail-domains.dropdown.description"
                    defaultMessage="Select your domains. A mail domain audience consists of users that receive a mail from certain domains."
                />
            }
            containerClass={styles['input']}
        >
            <TargetingDropdownBoxWithValidation
                itemType={targetingType}
                onSelectItemType={setTargetingTypeValue}
                itemPlaceholder={
                    <FormattedMessage
                        id="audience.editor.external-audience.mail-domains.dropdown.placeholder"
                        defaultMessage="domain.com"
                    />
                }
                onAddItem={addToTempSelection}
                allItems={autocompletedDomains}
                itemOptionRenderer={args => ItemOptionRenderer({ ...args, tempSelection })}
                itemTypeOptionRenderer={props => <TargetingTypeDropdownOption isDisabled={isDisabled} {...props} />}
                itemSelectionDisabled={isDisabled}
                isItemTypeDisabled={isDisabled}
                isItemsLoading={isLoadingAutoComplete}
                mainDropDownComponentProps={{
                    noOptionsMessage: () =>
                        noOptionsRenderer(debouncedSearchInputValue, isLoadingAutoComplete, prefixesToRemove),
                    inputValue: searchInputValue,
                    onMenuClose,
                    onInputChange: handleInputChange,
                    closeMenuOnSelect: false,
                    blurInputOnSelect: false,
                    styles: {
                        menu: base => ({
                            ...base,
                            maxHeight: '400px',
                            overflow: 'auto',
                            width: '578px',
                            right: '0',
                        }),
                    },
                }}
                {...targetingListsFailedValidationData}
                {...failedValidationData}
            />
            {rejectedKeywords && rejectedKeywords.length > 0 && (
                <RejectedKeywordsIndication
                    successMessageType="error"
                    rejectedKeywords={rejectedKeywords}
                    keywordsMap={rejectedKeywordsTopics}
                    handleInvalidKeywords={removeInvalidKeywords}
                    className={styles['rejected-keywords-indication']}
                    showRemoveInvalidKeywordsButton={false}
                />
            )}
            <>
                <CollapsibleListWithIndication
                    id="domains.include"
                    items={includeList}
                    ItemComponent={MailDomainListItem}
                    metadata={{ targetingType, rejectedKeywords }}
                    isSearchable
                    exportToFile={() =>
                        exportToFile({ items: includeList, prefix: audienceName, suffix: 'include-domains' })
                    }
                    deleteItem={removeIncludeItem}
                    clearItems={clearIncludedItems}
                    listHeaderTitle={
                        <FormattedMessage
                            id="audience.editor.external-audience.mail-domains.included"
                            defaultMessage="Included Domains"
                        />
                    }
                    aria-label="include-list"
                    showLessLabel={showLessLabel}
                    showAllLabel={showAllLabel}
                    containerClassName={styles['list']}
                />
                <CollapsibleList
                    id="domains.exclude"
                    items={excludeList}
                    ItemComponent={MailDomainListItem}
                    isSearchable
                    exportToFile={() =>
                        exportToFile({ items: excludeList, prefix: audienceName, suffix: 'exclude-domains' })
                    }
                    metadata={{ targetingType, rejectedKeywords }}
                    deleteItem={removeExcludeItem}
                    clearItems={clearExcludedItems}
                    listHeaderTitle={
                        <FormattedMessage
                            id="audience.editor.external-audience.mail-domains.excluded"
                            defaultMessage="Excluded Domains"
                        />
                    }
                    aria-label="exclude-list"
                    showLessLabel={showLessLabel}
                    showAllLabel={showAllLabel}
                    containerClassName={styles['list']}
                />
            </>
            <UploadFile onDropFileHandler={onDropFileHandler} />
            {useYahooDomainSuggestionsEnabled() && (
                <div className={styles['suggestions-container']}>
                    <ItemSuggestions
                        suggestions={suggestions}
                        isLoading={isLoadingSuggestions}
                        onAddAllSuggestions={handleAddAllSuggestions}
                        onSuggestionClick={handleSuggestionClick}
                        noSuggestionsMessage={
                            <FormattedMessage
                                id="audience.editor.external-audience.mail-domains.suggestions.banner"
                                defaultMessage="We only show domain ideas that are relevant to your business. To get ideas, enter some domains in the field above."
                            />
                        }
                        title={
                            <FormattedMessage
                                id="audience.editor.external-audience.mail-domains.suggestions.title"
                                defaultMessage="Domain suggestions (by relevance)"
                            />
                        }
                    />
                </div>
            )}
        </FormField>
    );
};
