import { GTM_EVENTS } from './config';
import { gtmTracker } from './gtmTracker';
import { getLocationData, getReportIdFromQuery } from './urlPageNameUtil';

const trackPageName = (pageName, accountId) => {
    const { pathname } = getLocationData();
    const reportId = getReportIdFromQuery();
    const event = {
        pageName,
        pagePath: pathname,
        accountId,
    };

    if (reportId) {
        event.reportId = reportId;
    }

    gtmTracker.trackEvent(GTM_EVENTS.VIRTUAL_PAGE_VIEW, event);
};

export { trackPageName };
