import { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { keyBy } from 'lodash';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import TARGETING_TYPES from '../../../config/TargetingTypes';

export const useCustomContextualSegments = selectedAccountId => {
    const { listCustomContextualSegments, listCustomContextualSegmentsByIds } = useAudiencesApi();

    const {
        data: options,
        isLoading: isLoadingOptions,
        isSuccess: isOptionsLoadedSuccessfully,
    } = useQuery(
        [selectedAccountId, 'listCustomContextualSegments'],
        () => listCustomContextualSegments(selectedAccountId),
        {
            select: data => data.results,
        }
    );

    const [targetingType, setTargetingType] = useState(TARGETING_TYPES.INCLUDE);

    const setTargetingTypeValue = useCallback(({ value }) => {
        setTargetingType(value);
    }, []);

    const { value: includeIds = [], onChange: setIncludeIds } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.include',
    });
    const { value: excludeIds = [], onChange: setExcludeIds } = useFormFieldValue({
        field: 'campaignTargeting.customContextualTargeting.exclude',
    });

    const includeIdsInitialValue = useRef(includeIds);
    const excludeIdsInitialValue = useRef(excludeIds);

    const allIdsInitialValues = useMemo(
        () => [...includeIdsInitialValue.current, ...excludeIdsInitialValue.current],
        []
    );

    const isIdsInitialValuesExist = useMemo(() => allIdsInitialValues.length > 0, [allIdsInitialValues.length]);

    const {
        data: segmentsByIds,
        isLoading: isLoadingSegmentsByIds,
        isSuccess: isSegmentsByIdsLoadedSuccessfully,
    } = useQuery(
        [selectedAccountId, 'listCustomContextualSegmentsByIds', allIdsInitialValues],
        () => listCustomContextualSegmentsByIds(selectedAccountId, { ids: allIdsInitialValues }),
        {
            refetchOnWindowFocus: false,
            select: data => data.results,
            enabled: isIdsInitialValuesExist,
        }
    );

    const allSegmentsByIdMap = useMemo(() => {
        if (options && ((includeIds.length === 0 && excludeIds.length === 0) || segmentsByIds)) {
            const allSegments = [...options, ...(segmentsByIds ? segmentsByIds : [])];
            return keyBy(allSegments, 'id');
        }
        return options ? keyBy(options, 'id') : {};
    }, [segmentsByIds, includeIds.length, excludeIds.length, options]);

    const addItem = item => {
        if (targetingType === TARGETING_TYPES.INCLUDE) {
            setIncludeIds([...includeIds, item.id]);
        } else {
            setExcludeIds([...excludeIds, item.id]);
        }
    };

    const { includeSegments, excludeSegments } = useMemo(() => {
        if (!options || isLoadingOptions || isLoadingSegmentsByIds) {
            return { includeSegments: [], excludeSegments: [] };
        }

        const includeSegments = includeIds.map(segmentId => allSegmentsByIdMap[segmentId]);
        const excludeSegments = excludeIds.map(segmentId => allSegmentsByIdMap[segmentId]);

        return { includeSegments, excludeSegments };
    }, [options, isLoadingOptions, isLoadingSegmentsByIds, includeIds, excludeIds, allSegmentsByIdMap]);

    const deleteItemFromInclude = item => {
        setIncludeIds(includeIds.filter(i => item.id !== i));
    };
    const deleteItemFromExclude = item => {
        setExcludeIds(excludeIds.filter(i => item.id !== i));
    };

    const clearAllInclude = () => {
        setIncludeIds([]);
    };

    const clearAllExclude = () => {
        setExcludeIds([]);
    };

    const filteredOptions = useMemo(
        () => options?.filter(item => !includeIds.includes(item.id) && !excludeIds.includes(item.id)),
        [excludeIds, includeIds, options]
    );

    return {
        options: filteredOptions,
        allSegmentsByIdMap,
        clearAllExclude,
        clearAllInclude,
        deleteItemFromInclude,
        deleteItemFromExclude,
        addItem,
        targetingType,
        setTargetingType: setTargetingTypeValue,
        includeSegments,
        excludeSegments,
        isLoadingOptions,
        isLoadingSegmentsByIds,
        isOptionsLoadedSuccessfully,
        isSegmentsByIdsLoadedSuccessfully: isSegmentsByIdsLoadedSuccessfully || !isIdsInitialValuesExist,
    };
};
