import React from 'react';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { ToolbarToggle } from 'components';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useSelectedGraphConfig } from '../../../hooks/useSelectedGraphConfig';

export const GraphToggle = ({ reportConfig }) => {
    const { selectedConfig: graphConfig, isMultipleGraphs, setSelectedGraph } = useSelectedGraphConfig(reportConfig);

    if (!graphConfig || !isMultipleGraphs) {
        return null;
    }

    const { graph: graphConfigs } = reportConfig;
    const { type: firstGraphOptionType, graphToggleIcon: FirstGraphOptionIcon } = graphConfigs[0];
    const { type: secondGraphOptionType, graphToggleIcon: SecondGraphOptionIcon } = graphConfigs[1];
    const { type: currentGraphType } = graphConfig;

    return (
        <ToolbarToggle
            onLeftChildClick={() => setSelectedGraph(firstGraphOptionType)}
            leftChild={
                <div
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Graph Toggle View"
                    data-metrics-value={firstGraphOptionType}
                >
                    <Tooltip arrow position={TOOLTIP_POSITION.BOTTOM_START}>
                        <FormattedMessage id={`app.campaigns.reports.graph.${firstGraphOptionType}`} />
                    </Tooltip>
                    <FirstGraphOptionIcon />
                </div>
            }
            onRightChildClick={() => setSelectedGraph(secondGraphOptionType)}
            rightChild={
                <div
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Graph Toggle View"
                    data-metrics-value={secondGraphOptionType}
                >
                    <Tooltip arrow position={TOOLTIP_POSITION.BOTTOM_START}>
                        <FormattedMessage id={`app.campaigns.reports.graph.${secondGraphOptionType}`} />
                    </Tooltip>
                    <SecondGraphOptionIcon />
                </div>
            }
            isLeftToggleSelected={currentGraphType === firstGraphOptionType}
        />
    );
};
