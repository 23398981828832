import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { navigate } from 'actions';
import { addIndication, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { DRAWER_MODE } from '../../components';
import { CustomizedSwitchWrapper } from '../../components/CustomizedSwitchWrapper/CustomizedSwitchWrapper';
import { LayerRoute } from '../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ModuleRoute } from '../multi-layer-drawer/components/ModuleRoute/ModuleRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../taboola-common-frontend-modules/account-configurations';
import {
    CAMPAIGN_CREATOR_ROUTE_PATH,
    CAMPAIGN_EDITOR_ROUTE_PATH,
    CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH,
    CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH,
    CAMPAIGNS_ROUTE,
    CREATIVE_BULK_DUPLICATE_ROUTE_PATH,
    CREATIVE_BULK_EDITOR_ROUTE_PATH,
    CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH,
    CREATIVE_CREATOR_ROUTE_PATH,
    CREATIVE_EDITOR_ROUTE_PATH,
    PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH,
} from './config';
import formValidationErrorIndication from './config/indications/validationIndication';
import {
    CampaignCreatorPage,
    CampaignEditorPage,
    CampaignsGroupCreatorPage,
    CampaignsGroupEditorPage,
    CampaignsReportsPage,
    CreativeBulkDuplicatePage,
    CreativeBulkEditorPage,
    CreativeCreatorPage,
    CreativeEditorPage,
} from './pages';
import { CreativeCarouselEditorPage } from './pages/CreativeCarouselEditorPage';
import PerformanceRecommendationsPage from './pages/PerformanceRecommendationsPage';

const path = CAMPAIGNS_ROUTE;

export const Campaigns = ({ history }) => {
    const dispatch = useDispatch();

    const onValidationError = useCallback(() => {
        dispatch(addIndication(formValidationErrorIndication));
    }, [dispatch]);

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history]
    );

    return (
        <CustomizedSwitchWrapper>
            <LayerRoute
                strict
                exact
                path={CAMPAIGN_CREATOR_ROUTE_PATH}
                render={props => <CampaignCreatorPage {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            <LayerRoute
                strict
                exact
                path={CAMPAIGNS_GROUP_CREATOR_ROUTE_PATH}
                render={props => <CampaignsGroupCreatorPage {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
            <LayerRoute
                strict
                exact
                path={CREATIVE_CREATOR_ROUTE_PATH}
                render={props => (
                    <CreativeCreatorPage {...props} onCancel={handleNavigation} onValidationError={onValidationError} />
                )}
                hasNestedRoutes
            />
            {isMultiLayerDrawerEnabled && (
                <>
                    <LayerRoute
                        exact
                        path={CAMPAIGN_EDITOR_ROUTE_PATH}
                        render={props => <CampaignEditorPage {...props} drawerMode={DRAWER_MODE.MEDIUM} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_EDITOR_ROUTE_PATH}
                        render={props => <CreativeEditorPage {...props} drawerMode={DRAWER_MODE.LARGE} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH}
                        render={props => <CreativeCarouselEditorPage {...props} drawerMode={DRAWER_MODE.SMALL} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_BULK_EDITOR_ROUTE_PATH}
                        render={props => <CreativeBulkEditorPage {...props} drawerMode={DRAWER_MODE.SMALL} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CREATIVE_BULK_DUPLICATE_ROUTE_PATH}
                        render={props => <CreativeBulkDuplicatePage {...props} drawerMode={DRAWER_MODE.SMALL} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={CAMPAIGNS_GROUP_EDITOR_ROUTE_PATH}
                        render={props => <CampaignsGroupEditorPage {...props} drawerMode={DRAWER_MODE.MEDIUM} />}
                        hasNestedRoutes
                    />
                    <LayerRoute
                        path={PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH}
                        render={props => <PerformanceRecommendationsPage {...props} drawerMode={DRAWER_MODE.SMALL} />}
                    />
                </>
            )}
            <ModuleRoute
                path={path}
                render={props => (
                    <CampaignsReportsPage
                        {...props}
                        onCancel={handleNavigation}
                        onValidationError={onValidationError}
                    />
                )}
            />
        </CustomizedSwitchWrapper>
    );
};

Campaigns.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

export default Campaigns;
