import React from 'react';
import { FormField, Radio } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { RadioGroupField } from '../../../campaigns/components';
import { AttributionSettingsTooltip } from './AttributionSettingsToolTip';

const AttributionSettings = () => {
    const isDcoAccount = useConfigMatch({
        [FEATURE_FLAGS.IS_DCO_ACCOUNT]: 'true',
    });

    const { value: attributionSettings, onChange } = useFormFieldValue({ field: 'attributionSettings' });

    return (
        <FormField
            label={
                <FormattedMessage id="tracking.settings.attribution.settings" defaultMessage="Attribution Settings" />
            }
            helpText={<AttributionSettingsTooltip />}
            description={
                <FormattedMessage
                    id="tracking.settings.attribution.description"
                    defaultMessage="Our default attribution setting is up to 30 days click and 1 day view-through"
                />
            }
            inputId="attribution-settings"
        >
            <RadioGroupField selectedValue={attributionSettings} onChange={onChange}>
                <Radio
                    value="CLICKS_AND_VIEWS"
                    title={
                        <FormattedMessage
                            id="tracking.settings.attribution.model.clicks.and.view.through.title"
                            defaultMessage="Clicks and view-through"
                        />
                    }
                />
                <Radio
                    value="CLICKS"
                    title={
                        <FormattedMessage
                            id="tracking.settings.attribution.model.clicks.title"
                            defaultMessage="Clicks only"
                        />
                    }
                />
                {isDcoAccount && (
                    <Radio
                        value="LAST_CLICK"
                        title={
                            <FormattedMessage
                                id="tracking.settings.attribution.model.last.click.title"
                                defaultMessage="Last Click"
                            />
                        }
                    />
                )}
            </RadioGroupField>
        </FormField>
    );
};

export default AttributionSettings;
