/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from 'react';
import classnames from 'classnames/bind';
import { isNil, noop, get } from 'lodash';
import PropTypes from 'prop-types';
import { Button, UploadOutlinedIcon } from 'tuui';
import uuid from 'uuid/v1';
import { FormField, InputTypes, InputWithButton, useDragAndDropFileContext } from 'taboola-ultimate-ui';
import { SupportedVideoTypes } from 'modules/campaigns/config/VideoTypes';
import { ThumbnailsFieldTooltip } from 'modules/campaigns/modules/creative-creator/components/ThumbnailsField';
import { ONLY_IMAGE_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { useMediaTabsContext } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/MediaTabsContextProvider/MediaTabsContextProvider';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validateImage } from 'modules/taboola-common-frontend-modules/validations';
import { getValidationIndicationType } from 'modules/taboola-common-frontend-modules/validations/utils';
import { operationsApi } from 'services/api';
import { MotionAdsStudio } from '../../../../creative-creator/components/MotionAdsStudio/MotionAdsStudio';
import ThumbnailsTooltipForUrl from '../../../../creative-creator/components/ThumbnailsPicker/ThumbnailsTooltipForUrl';
import { IMAGE, THUMBNAIL_SOURCE_TYPES, VIDEO } from '../../../../creative-creator/config';
import { MediaDragAndDrop } from '../../MediaDragAndDrop/MediaDragAndDrop';
import { GTM_ACTION_TYPES, TAB_NAMES } from '../consts';
import { getValidationErrorMessage } from '../services';
import UrlTabUploadDescription from './UrlTabUploadDescription';
import styles from './urlTab.module.scss';

const classNameBuilder = classnames.bind(styles);
const messagesPrefix = 'creative.editor.url';
const mediaType = { IMAGE: 'image', VIDEO: 'video' };

const UrlInput = withIndication(InputWithButton);

const UrlTabWrapperWithDragAndDrop = ({ onCancel, disabled, ...rest }) => {
    const { uploadButtonFileTypes, onSelectMedia } = rest;
    const { campaignId, titleList } = useMediaTabsContext();

    return (
        <MediaDragAndDrop
            fileTypes={uploadButtonFileTypes}
            onDrop={onSelectMedia}
            onCancel={onCancel}
            disabled={disabled}
            campaignId={campaignId}
            titleList={titleList}
        >
            <UrlTabWrapper {...rest} />
        </MediaDragAndDrop>
    );
};

const UrlTabWrapper = props => {
    const {
        mode,
        formAccount: { accountId },
    } = useFormDataContext();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const { openFileDialog } = useDragAndDropFileContext();
    const { campaignId, titleList } = useMediaTabsContext();
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    return (
        <UrlTab
            {...props}
            mode={mode}
            creativeType={creativeType}
            handleSelectFiles={openFileDialog}
            accountId={accountId}
            campaignId={campaignId}
            titleList={titleList}
            isUnifiedCreativesFormDuplicateMode={isUnifiedCreativesFormDuplicateMode}
        />
    );
};

class UrlTab extends Component {
    state = {
        error: null,
        value: this.props.value,
        valueFromProps: this.props.value,
    };

    static getDerivedStateFromProps(props, state) {
        const { value } = props;
        const { valueFromProps } = state;

        if (value !== valueFromProps) {
            return {
                value,
                valueFromProps: value,
            };
        }

        return null;
    }

    onAddButtonClick = async (url, metadata) => {
        const {
            onSelectMedia,
            accountId,
            campaignId,
            titleList,
            mode,
            creativeType,
            isUnifiedCreativesFormDuplicateMode,
        } = this.props;
        const errorMessage = metadata
            ? null
            : await getValidationErrorMessage({
                  url,
                  mode,
                  creativeType,
                  mediaType,
                  isUnifiedCreativesFormDuplicateMode,
              });

        this.setState({ error: errorMessage });

        if (errorMessage) {
            return;
        }
        const urlWithoutQuery = get(`${url}`.split('?'), '[0]', '').toLowerCase();
        const isMotion = SupportedVideoTypes.some(videoType => urlWithoutQuery.endsWith(videoType));
        const id = uuid();

        if (isMotion) {
            const motionObject = { id, src: url, fileType: 'video/*', type: THUMBNAIL_SOURCE_TYPES.URL_TA };
            const urlResolver = () =>
                operationsApi
                    .uploadMotionAdsByUrlWithImageRecommendation(accountId, motionObject, campaignId, titleList)
                    .then(({ url, gifUrl, fallbackImageUrl }) => ({
                        url,
                        gifUrl,
                        fallbackImageUrl,
                        recommendedFBImage: fallbackImageUrl,
                    }));
            onSelectMedia(urlResolver, {
                id,
                fileType: VIDEO,
                mediaUploadSource: THUMBNAIL_SOURCE_TYPES.URL_TA,
                ...metadata,
            });
            return;
        }
        const urlResolver = () =>
            validateImage(url)
                .then(() => operationsApi.uploadThumbnailFromUrl(accountId, url))
                .then(({ value }) => ({
                    url: value,
                }));
        onSelectMedia(urlResolver, {
            id,
            fileType: IMAGE,
            mediaUploadSource: THUMBNAIL_SOURCE_TYPES.URL_TA,
            ...metadata,
        });
    };

    getUploadButtonMessage = type => {
        if (type === ONLY_IMAGE_FILES) {
            return (
                <FormattedMessage id="creative.editor.tab.upload.device.button.images" defaultMessage="Upload Images" />
            );
        }

        return <FormattedMessage id="creative.editor.tab.upload.device.button.files" defaultMessage="Upload Files" />;
    };

    handleURLInputChange = newValue => {
        if (this.state.error) {
            this.setState({ error: null });
        }
        this.setState({ value: newValue.trim() });
    };

    render() {
        const {
            containerClass,
            handleSelectFiles,
            uploadButtonFileTypes,
            disabled,
            uploadDescription,
            placeholder,
            helpText,
            uploadHelpText,
            creativeType,
            onSelectMedia,
        } = this.props;
        const { error } = this.state;
        let { value } = this.state;
        if (isNil(value) || value.startsWith('blob:')) {
            value = '';
        }

        return (
            <div className={classNameBuilder('container', containerClass)}>
                <FormField
                    containerClass={styles['form-field']}
                    label={
                        <FormattedMessage id="creative.editor.tab.upload.device.title" defaultMessage="From Device" />
                    }
                    description={uploadDescription}
                    helpText={uploadHelpText}
                    descriptionClass={styles['description']}
                >
                    <Button
                        onClick={handleSelectFiles}
                        size={Button.size.lg}
                        variant={Button.variant.ghost}
                        disabled={disabled}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component={TAB_NAMES.UPLOAD_AND_CREATE}
                        data-metrics-value={GTM_ACTION_TYPES.BROWSE_BUTTON}
                    >
                        <UploadOutlinedIcon />
                        {this.getUploadButtonMessage(uploadButtonFileTypes)}
                    </Button>
                </FormField>
                <FormField
                    helpText={helpText ?? <ThumbnailsTooltipForUrl creativeType={creativeType} />}
                    containerClass={styles['form-field']}
                    labelClass={styles['title']}
                    descriptionClass={styles['sub-title']}
                    label={<FormattedMessage id="creative.editor.tab.upload.url" defaultMessage="From URL" />}
                    description={
                        <Fragment>
                            <FormattedMessage id={`${messagesPrefix}.subtitle`} defaultMessage="Type ad URL address." />
                        </Fragment>
                    }
                >
                    <UrlInput
                        value={value}
                        id="edit-creative-url-input"
                        placeholder={placeholder}
                        role="textbox"
                        onButtonClick={this.onAddButtonClick}
                        onChange={this.handleURLInputChange}
                        buttonLabel={<FormattedMessage id="creative.editor.url.button" defaultMessage="ADD" />}
                        indicationType={getValidationIndicationType(error)}
                        message={error && <FormattedMessage id={error} defaultMessage="Error" />}
                        type={InputTypes.URL}
                        size="large"
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="UrlInput"
                        disabled={disabled}
                        buttonProps={{
                            'data-metrics-event-name': GTM_EVENTS.USABILITY,
                            'data-metrics-component': TAB_NAMES.UPLOAD_AND_CREATE,
                            'data-metrics-value': GTM_ACTION_TYPES.WEB_URL,
                        }}
                    />
                </FormField>
                <MotionAdsStudio onSelectMedia={onSelectMedia} disabled={disabled} />
                <div className={styles['tab-description']}>
                    <FormattedMessage
                        id="creative.creator.media.tab.upload.disclaimer"
                        defaultMessage="*Taboola may modify any images, animated GIFs or video files you upload in order to fit varying thumbnail ratios across the Taboola network and you agree to any sizing modifications for this express purpose."
                    />
                </div>
            </div>
        );
    }
}

UrlTab.propTypes = {
    value: PropTypes.string,
    onSelectMedia: PropTypes.func,
    handleSelectFiles: PropTypes.func,
    containerClass: PropTypes.string,
    disabled: PropTypes.bool,
    uploadDescription: PropTypes.node,
    placeholder: PropTypes.string,
    mode: PropTypes.string,
};

UrlTab.defaultProps = {
    onSelectMedia: noop,
    value: '',
    uploadDescription: <UrlTabUploadDescription />,
    uploadHelpText: <ThumbnailsFieldTooltip />,
    placeholder: 'www.example.com/Image.png',
};

export default UrlTabWrapperWithDragAndDrop;
