import {
    PlayArrowOutlinedIcon,
    PauseOutlinedIcon,
    EditOutlinedIcon,
    DeleteOutlinedIcon,
    ContentCopyOutlinedIcon,
    RefreshOutlinedIcon,
} from 'tuui';

export const BULK_ACTION = {
    PLAY: 'PLAY',
    PAUSE: 'PAUSE',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    DUPLICATE: 'DUPLICATE',
    RESUBMIT: 'RESUBMIT',
};

export const bulkActionConfig = {
    [BULK_ACTION.PLAY]: {
        icon: <PlayArrowOutlinedIcon />,
    },
    [BULK_ACTION.PAUSE]: {
        icon: <PauseOutlinedIcon />,
    },
    [BULK_ACTION.EDIT]: {
        icon: <EditOutlinedIcon />,
    },
    [BULK_ACTION.DELETE]: {
        icon: <DeleteOutlinedIcon />,
    },
    [BULK_ACTION.DUPLICATE]: {
        icon: <ContentCopyOutlinedIcon />,
    },
    [BULK_ACTION.RESUBMIT]: {
        icon: <RefreshOutlinedIcon />,
    },
};
