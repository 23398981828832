import React from 'react';
import { TOOLTIP_POSITION, FilterIcon, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import styles from './withFilterIcon.module.scss';

const withFilterIcon = CellRenderer => props => {
    const { isFilterActive } = useGridContext();
    const originalContent = (
        <span className={styles['bolded-text']}>
            {CellRenderer ? <CellRenderer {...props} /> : props.valueFormatted}
        </span>
    );

    return (
        <div className={styles['filter-cell']}>
            {isFilterActive ? (
                <div className={styles['content']}>
                    <span>
                        <FilterIcon className={styles['filter-icon']} />
                    </span>
                    {originalContent}
                    <Tooltip
                        tooltipOptions={{
                            interactive: false,
                            arrow: true,
                            position: TOOLTIP_POSITION.LEFT,
                        }}
                    >
                        <FormattedMessage
                            id="app.campaigns.reports.grid.column.footer.tooltip.filter"
                            defaultMessage="Note: a filter is applied"
                        />
                    </Tooltip>
                </div>
            ) : (
                originalContent
            )}
        </div>
    );
};

export default withFilterIcon;
