"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgInApp(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 33,
            viewBox: "0 0 32 33",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M9 8.5C9 6.84315 10.3431 5.5 12 5.5H20C21.6569 5.5 23 6.84315 23 8.5V24.5C23 26.1569 21.6569 27.5 20 27.5H12C10.3431 27.5 9 26.1569 9 24.5V21H10V24.5C10 25.6046 10.8954 26.5 12 26.5H20C21.1046 26.5 22 25.6046 22 24.5V8.5C22 7.39543 21.1046 6.5 20 6.5H12C10.8954 6.5 10 7.39543 10 8.5V12H9V8.5Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M15.1716 12.9649L18.3536 16.1469C18.5488 16.3422 18.5488 16.6588 18.3536 16.854L15.1716 20.036C14.9763 20.2313 14.6597 20.2313 14.4645 20.036C14.2692 19.8407 14.2692 19.5242 14.4645 19.3289L16.7929 17.0005H8V16.0005H16.7929L14.4645 13.672C14.2692 13.4768 14.2692 13.1602 14.4645 12.9649C14.6597 12.7697 14.9763 12.7697 15.1716 12.9649Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgInApp;