import pushRBox from './pushRBox';

const pushRBoxListener = (type, handler) => {
    pushRBox({
        listenTo: type,
        handler,
    });
};

export default pushRBoxListener;
