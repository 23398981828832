import CheckBox from '@zippytech/react-toolkit/CheckBox';
import TreeView from '@zippytech/react-toolkit/TreeView';
import '@zippytech/react-toolkit/TreeView/index.css';
import React, { useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { RightSmallIcon } from 'taboola-ultimate-ui';
import LoadMoreNode from './LoadMoreNode/LoadMoreNode';
import { LoadingMode, TAXONOMY_SEPARATOR } from './utils';
import styles from './tree.module.scss';

const classNameBuilder = classnames.bind(styles);

const labelClassName = ({ node: { loading } }) => classNameBuilder('label', { loading });

const nodeClassName = ({ hasChildren }) => classNameBuilder({ 'child-indent': !hasChildren });

const renderCheck = (domProps, nodeProps, needRenderCheckbox) => {
    const { node } = nodeProps;
    const { id, loading, hasMore, label } = node;

    if (!needRenderCheckbox(nodeProps) || hasMore) {
        return null;
    }

    if (loading) {
        return (
            <ContentLoader key={id} width={30} height={30} speed={2} className={styles['checkbox-loader']}>
                <rect x="8" y="8" rx="8" ry="8" width="12" height="12" />
            </ContentLoader>
        );
    }

    domProps['data-automation'] = label;
    domProps.role = 'checkbox';

    return <CheckBox {...domProps} supportIndeterminate />;
};

const ExpandTool = ({ domProps }) => {
    return (
        <div {...domProps}>
            <RightSmallIcon className={styles['right-small-icon']} />
        </div>
    );
};
ExpandTool.propTypes = {
    domProps: PropTypes.object,
};

const defaultNeedRenderCheckbox = () => true;
const defaultGetIsNodeSelectable = () => true;

const Tree = ({
    nodesTree,
    onSelectAll,
    selectedItemsMap,
    onSelect,
    totalItems,
    enableSelectAll,
    collapsed,
    onCollapsedChange,
    renderNodeText,
    onLoadMore,
    renderSelectAll,
    disabledPathsMap,
    noOptionsContent,
    loadMoreContent,
    loadingMode,
    needRenderCheckbox = defaultNeedRenderCheckbox,
    getIsNodeSelectable = defaultGetIsNodeSelectable,
    ...rest
}) => {
    const renderNodeTextWrapper = useCallback(
        (domProps, nodeProps) => {
            const { hasMore, id } = nodeProps.node;
            if (hasMore) {
                const { path } = nodeProps.parent;
                return <LoadMoreNode key={id} onClick={() => onLoadMore(path)} title={loadMoreContent} />;
            }

            const {
                hasChildren,
                disabled,
                onCheckedChange,
                checked,
                onCollapsedChange: onCollapsedChangeInner,
                collapsed: collapsedInner,
            } = nodeProps;
            const isSelectable = getIsNodeSelectable(nodeProps);
            const isClickable = !disabled && (hasChildren || isSelectable);

            return (
                <div
                    role="presentation"
                    className={classNameBuilder('node-text-container', { 'clickable-node': isClickable })}
                    key={id}
                    onClick={e => {
                        e.stopPropagation();
                        if (disabled) {
                            return;
                        }

                        if (hasChildren) {
                            onCollapsedChangeInner({ props: nodeProps, collapsed: !collapsedInner });
                        } else if (isSelectable) {
                            onCheckedChange({ props: nodeProps, checked: !checked });
                        }
                    }}
                >
                    {renderNodeText(domProps, nodeProps)}
                </div>
            );
        },
        [getIsNodeSelectable, loadMoreContent, onLoadMore, renderNodeText]
    );

    const renderCheckWrapper = useCallback(
        (domProps, nodeProps) => renderCheck(domProps, nodeProps, needRenderCheckbox),
        [needRenderCheckbox]
    );

    const handleCheckedChange = useCallback(({ node, checked, path }) => onSelect(path, node, checked), [onSelect]);

    if (totalItems === 0 && loadingMode === LoadingMode.NONE) {
        return <span className={styles['no-options']}>{noOptionsContent}</span>;
    }

    return (
        <div className={styles['container']}>
            {renderSelectAll && renderSelectAll()}
            <TreeView
                className={styles['node']}
                enableChecked
                collapsed={collapsed}
                onCollapsedChange={onCollapsedChange}
                treeLines={false}
                pathSeparator={TAXONOMY_SEPARATOR}
                pathProperty="pathProperty"
                renderNodeText={renderNodeTextWrapper}
                renderCheck={renderCheckWrapper}
                onCheckedChange={handleCheckedChange}
                checked={selectedItemsMap}
                disabled={disabledPathsMap}
                dataSource={nodesTree}
                expandTool={ExpandTool}
                labelClassName={labelClassName}
                nodeClassName={nodeClassName}
                enableHoverStyle={false}
                checkOnClick={false}
                enableKeyboardNavigation
                {...rest}
            />
        </div>
    );
};

Tree.propTypes = {
    nodesTree: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            nodes: PropTypes.array,
        })
    ),
    onSelectAll: PropTypes.func,
    selectedItemsMap: PropTypes.object,
    disabledPathsMap: PropTypes.object,
    onSelect: PropTypes.func,
    totalItems: PropTypes.number,
    enableSelectAll: PropTypes.bool,
    collapsed: PropTypes.object,
    onCollapsedChange: PropTypes.func,
    renderNodeText: PropTypes.func,
    renderSelectAll: PropTypes.func,
    onLoadMore: PropTypes.func,
    noOptionsContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    loadMoreContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    loadingMode: PropTypes.oneOf(Object.values(LoadingMode)),
    needRenderCheckbox: PropTypes.func,
    getIsNodeSelectable: PropTypes.func,
};

export const MemoizedTree = React.memo(Tree);
MemoizedTree.displayName = 'MemoizedTree';

export default Tree;
