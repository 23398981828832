import React from 'react';
import { ContentCopyOutlinedIcon } from 'tuui';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SiteTargetingListItem from '../SiteTargetingListItem/SiteTargetingListItem';
import { useAccountBlockedSites } from '../hooks/useAccountBlockedSites';
import { useSiteClipboardOperations } from '../hooks/useSiteClipboardOperations';
import styles from '../SiteTargetingList/siteTargetingList.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
const ITEM_HEIGHT = 40;

const AccountBlockedSitesInner = () => {
    const { copySitesToClipboard } = useSiteClipboardOperations();
    const { readonly, allowedSites, isAllowedSitesReady, siteItems, deleteOne, failedValidationData } =
        useAccountBlockedSites();

    return (
        <CollapsibleListWithIndication
            data-testid="site-account-level-list"
            items={siteItems}
            ItemComponent={SiteTargetingListItem}
            deleteItem={readonly ? null : deleteOne}
            itemHeight={ITEM_HEIGHT}
            listHeaderClass={styles['list-header']}
            listHeaderTitle={
                <FormattedMessage
                    id="campaign.editor.targeting.site.account.header"
                    defaultMessage="Blocked on Account Level"
                />
            }
            showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
            showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
            containerClassName={styles['list']}
            copyToClipboard={() => copySitesToClipboard(siteItems)}
            copyToClipboardLabel={<ContentCopyOutlinedIcon aria-label="copy-all-account-level" />}
            metadata={{
                isAllowedSitesReady,
                allowedSites,
            }}
            {...failedValidationData}
        />
    );
};

export const AccountBlockedSites = () => {
    const {
        data: { accountName: campaignAccountName },
        formAccount: { accountName },
    } = useFormDataContext();

    if (accountName !== campaignAccountName) {
        return null;
    }

    return <AccountBlockedSitesInner />;
};
