import { StatusIndicator } from 'tuui';

export const PAYMENT_STATUS_TYPE = {
    CANCELED: 'CANCELED',
    PAID: 'PAID',
    PENDING: 'PENDING',
    PENDING_FUNDS: 'PENDING_FUNDS',
    PAID_BY_MAIL: 'PAID_BY_MAIL',
    REFUND: 'REFUND',
    INTERNAL_ERROR: 'INTERNAL_ERROR',
    DECLINED: 'DECLINED',
    EXPIRED: 'EXPIRED',
    WAITING_FOR_MANUAL_PAYMENT: 'WAITING_FOR_MANUAL_PAYMENT',
    MANUAL_PAYMENT_PASSED_DUE: 'MANUAL_PAYMENT_PASSED_DUE',
    FRAUDULENT: 'FRAUDULENT',
    CONNECTION_ERROR: 'CONNECTION_ERROR',
    INVALID_REQUEST: 'INVALID_REQUEST',
    IDEMPOTENT_KEY_ERROR: 'IDEMPOTENT_KEY_ERROR',
    THREE_D_S_AUTHENTICATION_ERROR: 'THREE_D_S_AUTHENTICATION_ERROR',
    BELOW_MINIMUM: 'BELOW_MINIMUM',
    MISSING_VALID_PAYMENT_METHOD: 'MISSING_VALID_PAYMENT_METHOD',
    TOO_MANY_API_REQUESTS: 'TOO_MANY_API_REQUESTS',
    GENERAL_ERROR: 'GENERAL_ERROR',
};

const paymentStatusIndicatorTypeMap = {
    [PAYMENT_STATUS_TYPE.PAID]: StatusIndicator.type.success,
    [PAYMENT_STATUS_TYPE.PENDING]: StatusIndicator.type.warning,
    [PAYMENT_STATUS_TYPE.PENDING_FUNDS]: StatusIndicator.type.warning,
    [PAYMENT_STATUS_TYPE.PAID_BY_MAIL]: StatusIndicator.type.success,
    [PAYMENT_STATUS_TYPE.REFUND]: StatusIndicator.type.success,
    [PAYMENT_STATUS_TYPE.INTERNAL_ERROR]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.DECLINED]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.EXPIRED]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.WAITING_FOR_MANUAL_PAYMENT]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.MANUAL_PAYMENT_PASSED_DUE]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.FRAUDULENT]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.CONNECTION_ERROR]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.INVALID_REQUEST]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.IDEMPOTENT_KEY_ERROR]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.THREE_D_S_AUTHENTICATION_ERROR]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.BELOW_MINIMUM]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.MISSING_VALID_PAYMENT_METHOD]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.TOO_MANY_API_REQUESTS]: StatusIndicator.type.error,
    [PAYMENT_STATUS_TYPE.GENERAL_ERROR]: StatusIndicator.type.error,
};

export const getPaymentStatusIndicatorType = statusType => paymentStatusIndicatorTypeMap[statusType];
