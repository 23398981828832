import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { CloudinaryImage, Image, TaboolaVideo } from 'taboola-ultimate-ui';
import { COMPONENT_STATUS } from '../../../../../../services/constants';
import { modifyExternalUrl } from '../../../../services/utils';
import ThumbnailLoading from '../../../creative-creator/components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import { VideoExternalError } from '../../../creative-creator/components/ThumbnailsPicker/SelectedThumbnails/VideoExternalError';
import styles from './taboolaVideoWrapper.module.scss';

const classNameBuilder = classnames.bind(styles);
const isLocalFile = imageSource => imageSource?.startsWith('blob:');
const isCloudinaryImage = imageSource => !isLocalFile(imageSource);

const TaboolaVideoWrapper = ({ videoUrl, imageSource, modifiedImageSrc, isComfortableView }) => {
    const [canPlay, setCanPlay] = useState(false);
    const canPlayHandler = () => setCanPlay(true);

    return (
        <div className={styles['container']}>
            {isCloudinaryImage(imageSource) ? (
                <CloudinaryImage
                    className={classNameBuilder('media', {
                        comfortable: isComfortableView,
                        normal: !isComfortableView,
                    })}
                    src={modifiedImageSrc}
                    sharpen
                    gravity="faces:auto"
                />
            ) : (
                <Image
                    className={classNameBuilder('media', {
                        comfortable: isComfortableView,
                        normal: !isComfortableView,
                    })}
                    src={imageSource}
                />
            )}

            <div className={styles['video-container']}>
                <TaboolaVideo
                    src={modifyExternalUrl(videoUrl)}
                    className={classNameBuilder('media', {
                        loaded: canPlay,
                        loading: !canPlay,
                        comfortable: isComfortableView,
                        normal: !isComfortableView,
                    })}
                    errorComponent={<VideoExternalError error={null} />}
                    loadingComponent={<ThumbnailLoading />}
                    status={COMPONENT_STATUS.ACTIVE}
                    autoplay
                    renderWatermark={false}
                    canPlayHandler={canPlayHandler}
                    containerClassName={styles['taboola-video-container']}
                />
            </div>
        </div>
    );
};

export default TaboolaVideoWrapper;
