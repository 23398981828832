import { validationsApi } from 'services/api';

export const DEFAULT_CAMPAIGN_ID = -1;
export const DEFAULT_CREATIVE_ID = 0;

export const VALIDATION_SCOPE = {
    ERRORS_ONLY: 'ERRORS_ONLY',
    WARNINGS_ONLY: 'WARNINGS_ONLY',
};

export const FIELD_TYPES = {
    TITLE: 'TITLE',
    DESCRIPTION: 'DESCRIPTION',
    THUMBNAIL_URL: 'THUMBNAIL_URL',
};

const fieldTypeConversionMap = {
    [FIELD_TYPES.TITLE]: 'title',
    [FIELD_TYPES.DESCRIPTION]: 'description',
    [FIELD_TYPES.THUMBNAIL_URL]: 'thumbnailUrl',
};

export async function validateContent({
    accountId,
    campaignId,
    fieldToValidate,
    item,
    scope = VALIDATION_SCOPE.ERRORS_ONLY,
} = {}) {
    try {
        const response = await validationsApi.validateContent({
            accountId,
            campaignId,
            fieldToValidate,
            items: [item],
            scope,
        });
        return response.results;
    } catch (e) {
        return false;
    }
}

export async function bulkValidateContent({
    accountId,
    campaignId,
    fieldToValidate,
    items,
    scope = VALIDATION_SCOPE.ERRORS_ONLY,
} = {}) {
    try {
        const response = await validationsApi.validateContent({
            accountId,
            campaignId,
            fieldToValidate,
            items,
            scope,
        });
        const validationsMap = {};
        response.results.map(({ itemId, reason }) => {
            const field = fieldTypeConversionMap[fieldToValidate];
            const value = items[itemId][field];
            return (validationsMap[value] = { valid: reason === '', reason });
        });
        return validationsMap;
    } catch (e) {
        return false;
    }
}
