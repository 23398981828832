import { getCampaignEntity } from '../../../config';
import { SAFETY_NETS_STATUS } from '../../../config/siteConsts';
import { addToCampaignProperty, removeFromCampaignProperty } from '../../../flows';
import { reportDataSelector, selectedCampaignSelector } from '../../../selectors';
import setReportData from '../actions/setReportData';
import { prepareRollBackData } from './saveCampaign';

export const saveCampaignSafetyNetsStatusModification =
    (newValue, { accountName, siteName }, oldValue, rowIndex) =>
    (dispatch, getState) => {
        const state = getState();
        const reportData = reportDataSelector(state);
        const { id: campaignId, demandType } = selectedCampaignSelector(state);
        const rollBackData = prepareRollBackData(rowIndex, { safetyNetsStatus: oldValue }, reportData);
        const rollBack = () => dispatch(setReportData(rollBackData));
        const publisherData = {
            safetyNetsExcludedAffiliate: {
                affiliate: siteName,
                safetyNetsStatus: newValue,
            },
        };

        const campaignUpdateAction =
            newValue === SAFETY_NETS_STATUS.CAPPING_ENABLED ? removeFromCampaignProperty : addToCampaignProperty;
        dispatch(
            campaignUpdateAction({
                accountId: accountName,
                campaignId,
                changes: publisherData,
                onError: rollBack,
                entityType: getCampaignEntity({ demandType }),
            })
        );
    };
