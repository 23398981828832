import React from 'react';
import PropTypes from 'prop-types';
import { useSelectedAccount, useSelectedAccountId } from '../../../../hooks';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import styles from './accountEditorHeader.module.scss';

const AccountEditorHeader = () => {
    const [{ accountDescription }] = useSelectedAccount();
    const [accountId] = useSelectedAccountId();
    return (
        <div>
            <div className={styles['title']}>{accountDescription}</div>
            <div>
                <FormattedMessage
                    id="account.editor.status.ID"
                    defaultMessage="ID: {accountId}"
                    values={{ accountId }}
                />
            </div>
        </div>
    );
};

AccountEditorHeader.propTypes = {
    headerLabel: PropTypes.node,
    hideStatusSection: PropTypes.bool,
    children: PropTypes.node,
};

AccountEditorHeader.defaultProps = {
    hideStatusSection: false,
};

export default AccountEditorHeader;
