'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Created by oreuveni
 * Date: 04/07/2018
 * Time: 12:38
 */
exports.default = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    URL: 'url',
    NUMBER: 'number'
};