import moment from 'moment';
import { FILTERS, PAGINATION, SORTING } from '../../../../../services/api/apiConstants';
import { AllCampaignsObject } from '../../../config';
import { START_DATE, END_DATE } from '../config/fields';
import { RESTRICTED_TYPES } from '../config/filters';
import extractFieldFromColumnId from './extractFieldFromColumnId';

const getDateRangeQueryParam = (startDate, endDate) => ({
    [START_DATE.field]: moment(startDate).format('YYYY-MM-DD'),
    [END_DATE.field]: moment(endDate).format('YYYY-MM-DD'),
});

const createFilterStateValue = ({ type, value, label, messageId }) => ({
    type,
    values: [{ value, label, messageId }],
});

const createFilterState = filters => filters.map(createFilterStateValue);

const createFilter = (id, values, operator = '=') => {
    const value = Array.isArray(values) ? values.join(',') : values;
    return { id, operator, value };
};

const getReportFiltersParam = ({
    campaignId,
    siteId,
    items,
    countries,
    searchTerm,
    searchField,
    campaignIdFilterName = 'campaign',
    dynamicFilters = [],
} = {}) => {
    const filters = dynamicFilters.map(({ type, values, operator }) => {
        const filterValues = operator?.value ? values : values.map(({ value }) => value);
        return createFilter(type, filterValues, operator?.value);
    });

    if (campaignId && campaignId !== AllCampaignsObject.id) {
        filters.push(createFilter(campaignIdFilterName, campaignId));
    }

    if (siteId) {
        filters.push(createFilter('siteId', siteId));
    }

    if (items) {
        filters.push(createFilter('item', items));
    }

    if (countries) {
        filters.push(createFilter('country', countries));
    }

    if (searchTerm && searchField) {
        filters.push(createFilter(searchField, searchTerm));
    }

    return { [FILTERS.FILTERS]: JSON.stringify(filters) };
};

// TODO: DEV-58584 need to convert filter names in the GW. revert this after.
const convertSortModel =
    (sortMap = {}) =>
    ({ colId, sort }) => {
        const field = extractFieldFromColumnId(colId);
        const transformedField = sortMap[field];

        return {
            field: transformedField || field,
            order: sort,
        };
    };
const isNotEmptySort = ({ colId, sort }) => colId && sort;
const getSortingQueryParams = sortObj => {
    const { reportSort: sort = [], sortMap } = sortObj;
    const queryParams = {};
    const convertedSort = sort.filter(isNotEmptySort).map(convertSortModel(sortMap));

    if (convertedSort.length > 0) {
        queryParams[SORTING.SORT] = JSON.stringify(convertedSort);
    }

    return queryParams;
};
const getReportQueryParams = (page, pageSize, sortObj) => {
    const queryParams = {
        [PAGINATION.PAGE]: page,
        [PAGINATION.PAGE_SIZE]: pageSize,
    };

    return { ...queryParams, ...getSortingQueryParams(sortObj) };
};

const getRestrictedFilterTypes = (filterType, customRestrictedFilters = RESTRICTED_TYPES) =>
    customRestrictedFilters[filterType] || [];

const getDisabledFilterTypes = (selectedTypes, customRestrictedFilters) => {
    const disabledTypesSet = new Set();
    selectedTypes.forEach(selectedType => {
        disabledTypesSet.add(selectedType);
        getRestrictedFilterTypes(selectedType, customRestrictedFilters).forEach(restrictedType =>
            disabledTypesSet.add(restrictedType)
        );
    });
    return disabledTypesSet;
};

export {
    getDateRangeQueryParam,
    getReportFiltersParam,
    getReportQueryParams,
    getSortingQueryParams,
    getRestrictedFilterTypes,
    getDisabledFilterTypes,
    createFilterState,
};
