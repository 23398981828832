import React from 'react';
import { sharedBudgetApi } from 'services/api/sharedBudgetApi/sharedBudgetApi';
import { INDICATION_TYPES, addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { toGWtransformers } from '../../../flows/flowsUtils';
import {
    fetchCampaignsGroupForEditError,
    fetchCampaignsGroupForEditSuccess,
    requestFetchCampaignsGroupForEdit,
} from '../actions/fetchCampaignsGroupForEdit';

const indication = {
    message: (
        <FormattedMessage
            id="campaigns.group.drawer.error"
            defaultMessage="Something went wrong when we tried to fetch the requested group."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const fetchCampaignsGroupForEdit =
    (accountId, campaignsGroupId, entityType, api = sharedBudgetApi) =>
    async dispatch => {
        dispatch(requestFetchCampaignsGroupForEdit());

        try {
            const response = await api.getCampaignGroup(accountId, campaignsGroupId);
            const campaignsGroup = toGWtransformers[entityType](response);

            dispatch(fetchCampaignsGroupForEditSuccess(campaignsGroup));
            return campaignsGroup;
        } catch (error) {
            dispatch(addIndication(indication));
            dispatch(fetchCampaignsGroupForEditError(error));
        }
    };

export default fetchCampaignsGroupForEdit;
