import React, { useMemo } from 'react';
import { FormField, Input } from 'taboola-ultimate-ui';
import useEventValueHandler from 'hooks/useEventValueHandler';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config/formModes';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { useFormValidatedValue } from '../../../taboola-common-frontend-modules/validations';
import commonStyles from '../CardPaymentMethodCreator/cardPaymentMethodCreator.module.scss';

const countryToVatIdValidations = {
    BR: {
        maxLength: 50,
        regExp: /^((\d{2}[.]\d{3}[.]\d{3}[/]\d{4}[-]\d{2}|\d{14})|(\d{3}[.]\d{3}[.]\d{3}[-]\d{2}|\d{11}))$/,
    },
    IL: {
        maxLength: 10,
        regExp: /^\d{9}$/,
    },
    IN: {
        maxLength: 15,
        regExp: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/,
    },
};

const doesCountryHasValidations = country => !!countryToVatIdValidations[country];

const InputWithIndication = withIndication(Input);

export const PaymentMethodVatId = ({ isTaxRegistration = false }) => {
    const { [COMMON_FLAGS.SUPPORTED_COUNTRY_FOR_TAX_REGISTRATION]: supportedCountryForTaxRegistration } =
        useCommonConfig([COMMON_FLAGS.SUPPORTED_COUNTRY_FOR_TAX_REGISTRATION]);

    const { value: selectedCountry } = useFormFieldValue({ field: 'billingAddress.country' });
    const country = isTaxRegistration ? supportedCountryForTaxRegistration : selectedCountry;

    const validations = useMemo(
        () =>
            doesCountryHasValidations(country)
                ? [
                      {
                          validationFn: value => value,
                          messageId: `validations.error.paymentMethod.vatId.${country}.empty`,
                      },
                      {
                          validationFn: (value, options, { maxLength }) => !maxLength || value.length <= maxLength,
                          messageId: `validations.error.paymentMethod.vatId.${country}.moreThen`,
                      },
                      {
                          validationFn: (value, options, { regExp }) => !regExp || regExp.test(value),
                          messageId: `validations.error.paymentMethod.vatId.${country}.invalid`,
                      },
                  ]
                : null,
        [country]
    );

    const {
        value: vatId = '',
        onChange,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'taxData.vatId',
        validations,
        validationDependencies: validations && {
            maxLength: countryToVatIdValidations[country].maxLength,
            regExp: countryToVatIdValidations[country].regExp,
        },
    });
    const changeHandler = useEventValueHandler(onChange);
    const { formatMessage } = useIntl();

    return (
        <FormField
            inputId="vatId"
            containerClass={commonStyles['form-field']}
            ref={scrollRef}
            label={<FormattedMessage id={`paymentMethod.creator.vatId.${country}`} defaultMessage="VAT ID" />}
        >
            <InputWithIndication
                id="vatId"
                value={vatId}
                onChange={changeHandler}
                placeholder={formatMessage({
                    id: `paymentMethod.creator.vatId.${country}.placeholder`,
                    defaultMessage: '1234',
                })}
                size={Input.SIZE.LARGE}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="PaymentMethodVatId"
                disabled={mode !== FORM_MODES.CREATE}
                {...indicationData}
            />
        </FormField>
    );
};
