import React from 'react';
import { isEmpty, isString } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n/components/FormattedMessage';
import { getParentAppName } from 'modules/taboola-common-frontend-modules/i18n/hooks/useParentAppName';
import { translateTermsByParentApp } from 'modules/taboola-common-frontend-modules/i18n/utils/translateTermsByParentApp';
import { ERROR_MESSAGES_SET } from '../api/apiConstants';

const getErrorMessage = (errorObject, flowErrorMessage) => {
    if (isEmpty(errorObject.messageCode) || ERROR_MESSAGES_SET.has(errorObject.messageCode)) {
        if (!isEmpty(flowErrorMessage) && !isString(flowErrorMessage)) {
            return flowErrorMessage;
        }
        const parentApp = getParentAppName();
        const { newError: newDefaultMessage } = translateTermsByParentApp(
            { newError: flowErrorMessage || errorObject.message },
            parentApp
        );
        return newDefaultMessage || errorObject.message || '';
    }
    return (
        <FormattedMessage
            id={errorObject.messageCode}
            defaultMessage={errorObject.message}
            values={errorObject?.templateParameters}
        />
    );
};

export const generateErrorIndication = (errorObject, flowErrorMessage = {}) => ({
    message: getErrorMessage(errorObject, flowErrorMessage),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
});

export default {
    getErrorMessage,
};
