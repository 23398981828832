import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DeletableItem, DelimiterWrapper, TooltipV2 as Tooltip, useCollapsibleListContext } from 'taboola-ultimate-ui';
import Section from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/ListItem/Section';
import styles from './conversionSetupUrlConditionListItem.module.scss';

export const ConversionSetupUrlConditionListItem = ({ item }) => {
    const { deleteItem } = useCollapsibleListContext();

    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);

    return (
        <div className={styles['container']}>
            <DeletableItem onDelete={onDelete}>
                <DelimiterWrapper delimiterClassName={styles['delimiter']}>
                    <Section
                        id="tracking.conversion.setup.conditions.or"
                        defaultMessage="OR"
                        className={styles['or']}
                        loaderWidth={styles.orWidth}
                    />
                    <Section
                        id={`tracking.conversion.setup.conditions.predicate.${item.predicate}`}
                        className={styles['predicate']}
                        loaderWidth={styles.predicateWidth}
                    />
                    <Section
                        id="no.id"
                        defaultMessage={item.url}
                        className={styles['url']}
                        ariaLabel={`${item.predicate?.toLowerCase()}_${item.url}`}
                        tooltip={
                            <Tooltip interactive arrow>
                                {item.url}
                            </Tooltip>
                        }
                        loaderWidth={styles.urlWidth}
                    />
                </DelimiterWrapper>
            </DeletableItem>
        </div>
    );
};

ConversionSetupUrlConditionListItem.propTypes = {
    item: PropTypes.shape({
        predicate: PropTypes.string,
        url: PropTypes.string,
    }),
};
