import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { SideDrawer } from '../../../../components';
import { PATH_TO_FORM_MODE_MAP } from '../../../../config/routes';
import { FORM_MODES } from '../../../campaigns/config';
import { LayerRoute } from '../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { BILLING_AND_PAYMENTS_ROUTE, CARD_PAYMENT_METHOD_EDITOR_ROUTE_PATH, EDITOR_ROUTE_PATH } from '../../config';
import { PaymentMethodEditor } from '../PaymentMethodEditor/PaymentMethodEditor';

export const EditDrawer = ({ onCancel }) => {
    const { params: { mode: modeParam } = {} } = useRouteMatch(EDITOR_ROUTE_PATH) || {};
    const mode = PATH_TO_FORM_MODE_MAP[modeParam];
    const isOpen = mode && mode !== FORM_MODES.CREATE;

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={CARD_PAYMENT_METHOD_EDITOR_ROUTE_PATH}
                        render={props => <PaymentMethodEditor {...props} onCancel={close} />}
                    />
                    <Redirect to={BILLING_AND_PAYMENTS_ROUTE} />
                </Switch>
            )}
        </SideDrawer>
    );
};
