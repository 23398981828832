'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Buttons = require('../../Buttons');

var _Icons = require('../../Icons/Icons');

var _Divider = require('../../Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _UserNameLetterIcon = require('../UserNameLetterIcon/UserNameLetterIcon');

var _UserNameLetterIcon2 = _interopRequireDefault(_UserNameLetterIcon);

var _ArrowPath = require('../ArrowPath/ArrowPath');

var _ArrowPath2 = _interopRequireDefault(_ArrowPath);

var _BackgroundOverlay = require('../../BackgroundOverlay');

var _BackgroundOverlay2 = _interopRequireDefault(_BackgroundOverlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'dividerColor': 'var(--gray-4)',
    'taboola-svg-icon': 'userMenu__taboola-svg-icon___1wVw7',
    'container': 'userMenu__container___1hxw2',
    'letter-button-container': 'userMenu__letter-button-container___1arc9',
    'letter-button': 'userMenu__letter-button___30K9t',
    'dropdown-icon-container': 'userMenu__dropdown-icon-container___3uH2O',
    'letter-image-container': 'userMenu__letter-image-container___3MBRo',
    'letter-image': 'userMenu__letter-image___2_4oG',
    'menu-container': 'userMenu__menu-container___Qce8_',
    'divider': 'userMenu__divider___79iuK',
    'menu': 'userMenu__menu___3hjDk',
    'userName': 'userMenu__userName___ndP7X',
    'email': 'userMenu__email___1yQyJ',
    'link': 'userMenu__link___2zbVd',
    'arrow': 'userMenu__arrow___1ILJD',
    'top-details-container': 'userMenu__top-details-container___1EB87',
    'menu-default-platform': 'userMenu__menu-default-platform___29P6A',
    'menu-bottom-container': 'userMenu__menu-bottom-container___yt3fW',
    'user-details-container': 'userMenu__user-details-container___tSyu3',
    'links-container': 'userMenu__links-container___15dpF',
    'menu-top-container': 'userMenu__menu-top-container___1aUjI'
};

var UserMenu = function (_Component) {
    _inherits(UserMenu, _Component);

    function UserMenu() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, UserMenu);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = UserMenu.__proto__ || Object.getPrototypeOf(UserMenu)).call.apply(_ref, [this].concat(args))), _this), _this.componentRef = _react2.default.createRef(), _this.renderLinks = function (links) {
            return links && Object.values(links).map(function (_ref2) {
                var title = _ref2.title,
                    href = _ref2.href,
                    CustComponent = _ref2.Component;

                if (CustComponent) {
                    return _react2.default.createElement(CustComponent, { key: title });
                }
                return _react2.default.createElement(
                    'a',
                    {
                        key: title,
                        href: href,
                        className: styles['link'],
                        target: '_blank',
                        rel: 'noopener noreferrer'
                    },
                    title
                );
            });
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(UserMenu, [{
        key: 'render',
        value: function render() {
            var _props = this.props,
                className = _props.className,
                popupClassName = _props.popupClassName,
                menuBottomContainerClassName = _props.menuBottomContainerClassName,
                userName = _props.userName,
                email = _props.email,
                topLinks = _props.topLinks,
                bottomLinks = _props.bottomLinks,
                onButtonClick = _props.onButtonClick,
                buttonText = _props.buttonText,
                letterButtonConfig = _props.letterButtonConfig,
                letterImageConfig = _props.letterImageConfig,
                isOpen = _props.isOpen,
                onClick = _props.onClick,
                dropdownIconClassName = _props.dropdownIconClassName,
                onClose = _props.onClose,
                renderDefaultPlatformComponent = _props.renderDefaultPlatformComponent;

            var _ref3 = letterButtonConfig || {},
                letterButtonClassName = _ref3.letterButtonClassName,
                restLetterButtonConfig = _objectWithoutProperties(_ref3, ['letterButtonClassName']);

            var _ref4 = letterImageConfig || {},
                letterImageClassName = _ref4.letterImageClassName,
                restLetterImageConfig = _objectWithoutProperties(_ref4, ['letterImageClassName']);

            var userLetter = userName.substr(0, 1);

            return _react2.default.createElement(
                'div',
                { className: styles['container'] + ' ' + (className || ''), ref: this.componentRef },
                _react2.default.createElement(
                    'div',
                    { className: styles['letter-button-container'], tabIndex: -1, role: 'button', onClick: onClick, 'aria-label': 'User Logo' },
                    _react2.default.createElement(_UserNameLetterIcon2.default, Object.assign({}, restLetterButtonConfig, {
                        className: styles['letter-button'] + ' ' + (letterButtonClassName || ''),
                        letter: userLetter
                    })),
                    _react2.default.createElement(
                        'div',
                        { className: styles['dropdown-icon-container'] },
                        _react2.default.createElement(_Icons.DropdownIcon, { className: dropdownIconClassName || '' })
                    )
                ),
                isOpen && _react2.default.createElement(
                    'div',
                    { className: styles['menu-container'] + ' ' + (popupClassName || ''), 'aria-label': 'User Menu' },
                    _react2.default.createElement(_ArrowPath2.default, { optClassName: styles['arrow'] }),
                    _react2.default.createElement(
                        'div',
                        { className: styles['menu'] },
                        _react2.default.createElement(
                            'div',
                            { className: styles['menu-top-container'] },
                            _react2.default.createElement(
                                'div',
                                { className: styles['letter-image-container'] },
                                _react2.default.createElement(_UserNameLetterIcon2.default, Object.assign({}, restLetterImageConfig, {
                                    className: styles['letter-image'] + ' ' + (letterImageClassName || ''),
                                    letter: userLetter
                                }))
                            ),
                            _react2.default.createElement(
                                'div',
                                { className: styles['top-details-container'] },
                                _react2.default.createElement(
                                    'div',
                                    { className: styles['user-details-container'] },
                                    _react2.default.createElement(
                                        'span',
                                        { className: styles['userName'] },
                                        userName
                                    ),
                                    _react2.default.createElement(
                                        'span',
                                        { className: styles['email'] },
                                        email
                                    )
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: styles['links-container'] },
                                    this.renderLinks(topLinks) || ''
                                )
                            )
                        ),
                        renderDefaultPlatformComponent && _react2.default.createElement(
                            'div',
                            null,
                            _react2.default.createElement(_Divider2.default, { className: styles['divider'], color: styles.dividerColor }),
                            _react2.default.createElement(
                                'div',
                                { className: styles['menu-default-platform'] },
                                renderDefaultPlatformComponent()
                            )
                        ),
                        _react2.default.createElement(_Divider2.default, { className: styles['divider'], color: styles.dividerColor }),
                        _react2.default.createElement(
                            'div',
                            { className: styles['menu-bottom-container'] + ' ' + (menuBottomContainerClassName || '') },
                            this.renderLinks(bottomLinks) || '',
                            _react2.default.createElement(
                                _Buttons.StyledWithModeButton,
                                {
                                    onClick: onButtonClick,
                                    size: _Buttons.STYLED_WITH_MODE_BUTTON_SIZE.SMALL,
                                    type: _Buttons.STYLED_WITH_MODE_BUTTON_TYPE.SYSTEM,
                                    className: styles['bottom-button']
                                },
                                buttonText
                            )
                        )
                    ),
                    _react2.default.createElement(_BackgroundOverlay2.default, {
                        onOutsideClick: onClose,
                        foregroundElementRef: this.componentRef
                    })
                )
            );
        }
    }]);

    return UserMenu;
}(_react.Component);

UserMenu.propTypes = {
    /** optional class name */
    className: _propTypes2.default.string,
    /** optional class name for the popup component */
    popupClassName: _propTypes2.default.string,
    /**  */
    menuBottomContainerClassName: _propTypes2.default.string,
    /** User Name text */
    userName: _propTypes2.default.string,
    /** Email text */
    email: _propTypes2.default.string,
    /** Top view links title and href data (e.g. Privacy link) */
    topLinks: _propTypes2.default.arrayOf(_propTypes2.default.shape({
        title: _propTypes2.default.node.isRequired,
        href: _propTypes2.default.string.isRequired
    })),
    /** Bottom view links title and href data (e.g. Term of use link) */
    bottomLinks: _propTypes2.default.arrayOf(_propTypes2.default.shape({
        title: _propTypes2.default.node.isRequired,
        href: _propTypes2.default.string.isRequired
    })),
    /** Button text (e.g. sign out) */
    buttonText: _propTypes2.default.node.isRequired,
    /** Handle button click callback */
    onButtonClick: _propTypes2.default.func.isRequired,
    /** Letter button config options */
    letterButtonConfig: _propTypes2.default.shape.apply(_propTypes2.default, _toConsumableArray(_UserNameLetterIcon2.default.propTypes)),
    /** Letter Image config options */
    letterImageConfig: _propTypes2.default.shape.apply(_propTypes2.default, _toConsumableArray(_UserNameLetterIcon2.default.propTypes)),
    /** a boolean that determines if the component is in open or closed state */
    isOpen: _propTypes2.default.bool,
    /** on click handler for the main letter icon */
    onClick: _propTypes2.default.func,
    /** optional class name for the dropdown icon component */
    dropdownIconClassName: _propTypes2.default.string,
    /** Function that will close the menu */
    onClose: _propTypes2.default.func,
    /** Default platform Component render function */
    renderDefaultPlatformComponent: _propTypes2.default.func
};

UserMenu.defaultProps = {
    isOpen: false,
    userName: '',
    email: '',
    onClick: function onClick() {}
};

exports.default = UserMenu;