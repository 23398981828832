import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useModuleName } from 'hooks';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import {
    isCompareColumnsShown,
    removeChildColIds,
    isParentColId,
    toggleChildColumns,
    getChildrenColIdsByParent,
} from '../../../services';
import { getCustomColumnsFromColumnDefsAndPreset } from '../utils/columnUtils';
import useColumnGrouper from './useColumnGrouper';

const invalidPresetIndication = {
    type: INDICATION_TYPES.ERROR,
    message: (
        <FormattedMessage
            id="app.campaigns.reports.columnPicker.edit.view.invalid"
            defaultMessage="Custom report preset is invalid"
        />
    ),
    highlight: <FormattedMessage id="error.highlight" />,
};

const useIndicateInvalidPreset = isInvalidPreset => {
    const dispatch = useDispatch();
    const { fetchStatus } = useFormDataContext();
    const isFormInitial = fetchStatus === COMPONENT_STATUS.INITIAL;

    useEffect(() => {
        if (isInvalidPreset && !isFormInitial) {
            dispatch(addIndication(invalidPresetIndication));
        }
    }, [isInvalidPreset, dispatch, isFormInitial]);
};

const useCustomColumnsService = ({
    columnsDef,
    customPresetColumns,
    selectedColumns,
    defaultColumns,
    reportPresetDisplayName,
    reportPresetAccountId,
    applySelectedColumns,
    isInvalidPreset,
}) => {
    useIndicateInvalidPreset(isInvalidPreset);
    const initialSelectedColumns = selectedColumns || defaultColumns;
    const fetchedFormData = useMemo(
        () => ({
            data: {
                filter: '',
                displayName: reportPresetDisplayName,
                accountId: reportPresetAccountId,
                columns: initialSelectedColumns,
                shouldShowCompareColumns: isCompareColumnsShown(columnsDef, initialSelectedColumns),
            },
            isSuccess: !isInvalidPreset,
        }),
        [reportPresetDisplayName, reportPresetAccountId, initialSelectedColumns, columnsDef, isInvalidPreset]
    );
    const { submit } = useFormState({ fetchedFormData });

    const { value: filter = '', onChange: setFilter } = useFormFieldValue({ field: 'filter' });
    const { value: selected = [], onChange: setSelected } = useFormFieldValue({ field: 'columns' });
    const { value: shouldShowCompareColumns = false, onChange: setShouldShowCompareColumns } = useFormFieldValue({
        field: 'shouldShowCompareColumns',
    });

    const customColumns = useMemo(
        () => getCustomColumnsFromColumnDefsAndPreset(columnsDef, customPresetColumns),
        [columnsDef, customPresetColumns]
    );
    const groupedColumns = useColumnGrouper(customColumns, filter);

    const resetToDefaultsHandler = () => {
        setSelected(defaultColumns);
        setShouldShowCompareColumns(false);
    };

    const module = useModuleName();
    const saveReportPreset = () => submit(applySelectedColumns, `/${module}`);

    const toggleShouldShowCompareColumns = () => {
        const newValue = !shouldShowCompareColumns;

        setShouldShowCompareColumns(newValue);
        setSelected(toggleChildColumns(columnsDef, selected, newValue));
    };
    const toggleColumnHandler = colIdsParam => {
        const colIds = typeof colIdsParam === 'string' ? [colIdsParam] : colIdsParam;
        const customColumnsLength = customColumns.length;

        if (customColumnsLength === 0 || colIds.length === 0) {
            return;
        }

        let newSelectedArr = [...selected];

        for (let colId of colIds) {
            if (newSelectedArr.includes(colId)) {
                newSelectedArr = newSelectedArr.filter(col => col !== colId);
                if (shouldShowCompareColumns) {
                    newSelectedArr = removeChildColIds(columnsDef, newSelectedArr, colId);
                }
            } else {
                newSelectedArr = [...newSelectedArr, colId];
                if (shouldShowCompareColumns && isParentColId(columnsDef, colId)) {
                    newSelectedArr = [...newSelectedArr, ...getChildrenColIdsByParent(columnsDef, colId)];
                }
            }
        }

        setSelected(newSelectedArr);
    };

    return {
        filter,
        selected,
        shouldShowCompareColumns,
        setFilter,
        groupedColumns,
        toggleColumnHandler,
        toggleShouldShowCompareColumns,
        resetToDefaultsHandler,
        saveReportPreset,
    };
};

export default useCustomColumnsService;
