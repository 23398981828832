import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.ACTIVE_EVENTS].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.EVENT_NAME.field,
        FIELDS.LAST_DATE_RECEIVED.field,
        FIELDS.TOTAL_RECEIVED.field,
        FIELDS.DEFINED_CONVERSIONS.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.EVENT_NAME.field,
    FIELDS.LAST_DATE_RECEIVED.field,
    FIELDS.TOTAL_RECEIVED.field,
    FIELDS.DEFINED_CONVERSIONS.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
