import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

const campaignsLimit = 100;

export const scheduledReportsCampaignListValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'validations.error.creative.duplicate.campaign.empty',
        defaultMessage: 'Select at least one campaign',
    },
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: campaignsLimit },
        messageId: 'app.scheduled.reports.campaigns.validations.error.limit',
        defaultMessage: 'Campaign limit exceeds maximum allowable limit of {max}',
    },
];
