import recommendationsApi from '../../../services/api/recommendationsApi';
import { permissionsSelector } from '../../taboola-common-frontend-modules/authentication/selectors';
import {
    fetchSyndicatorRecommendationCountsError,
    fetchSyndicatorRecommendationCountsSuccess,
    requestFetchSyndicatorRecommendationCounts,
} from '../actions';

export const fetchSyndicatorRecommendationCounts = accountName => async (dispatch, getState) => {
    const state = getState();
    const userPermissions = permissionsSelector(state);
    if (!userPermissions['CAMPAIGN_PERFORMANCE_RECOMMENDATIONS']) {
        return;
    }

    dispatch(requestFetchSyndicatorRecommendationCounts());
    try {
        const syndicatorRecommendationCounts = await recommendationsApi.getSyndicatorRecommendationCounts(accountName);

        dispatch(fetchSyndicatorRecommendationCountsSuccess(syndicatorRecommendationCounts));
        return syndicatorRecommendationCounts;
    } catch (error) {
        dispatch(fetchSyndicatorRecommendationCountsError(error));
    }
};

export default fetchSyndicatorRecommendationCounts;
