"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSelectUser(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 15,
            height: 14,
            viewBox: "0 0 15 14",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M8.19971 7C10.1328 7 11.6999 5.43296 11.6999 3.49985C11.6999 1.5668 10.1328 0 8.19971 0C6.26678 0 4.69986 1.56692 4.69986 3.49985C4.69986 5.4329 6.2669 7 8.19971 7ZM8.19971 5.59994C9.35965 5.59994 10.2997 4.65973 10.2997 3.49991C10.2997 2.34009 9.35965 1.39988 8.19971 1.39988C7.03989 1.39988 6.09968 2.34009 6.09968 3.49991C6.09968 4.65973 7.03989 5.59994 8.19971 5.59994Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M1.89996 14C1.89996 10.5206 4.72069 7.69995 8.20006 7.69995C11.6795 7.69995 14.5001 10.5206 14.5001 14H1.89996ZM8.20006 9.10007C5.98038 9.10007 4.1053 10.5761 3.50289 12.6002H12.8972C12.2949 10.5761 10.4198 9.10007 8.20006 9.10007Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M2.3099 7.70016L1.40496 8.60521L2.39486 9.59511L4.9899 7.00019L2.39486 4.40515L1.40496 5.39505L2.3099 6.3001H0.499908V7.70016H2.3099Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgSelectUser;