import React from 'react';
import { batch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { errorMessagesUtils } from 'services/utils';
import { performanceRulesApi } from '../../../services/api';
import { requestUpdatePerformanceRule, updatePerformanceRuleError, updatePerformanceRuleSuccess } from '../actions';

export const successIndication = {
    message: <FormattedMessage id="performance.rules.save.success" defaultMessage="Rule successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Unable to save changes." />,
});

export const updatePerformanceRule =
    ({ accountName, changes, ruleId, onError, onSuccess }) =>
    async dispatch => {
        dispatch(requestUpdatePerformanceRule());
        try {
            const updateRuleResponse = await performanceRulesApi.updatePerformanceRuleApi({
                accountName,
                ruleId,
                changes,
            });

            batch(() => {
                dispatch(updatePerformanceRuleSuccess(updateRuleResponse));
                dispatch(addIndication(successIndication));
            });
            if (onSuccess) {
                onSuccess();
            }
            return updateRuleResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updatePerformanceRuleError(error));
            if (onError) {
                onError(error);
            }
            throw error;
        }
    };
