import React from 'react';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import ReportBreadcrumbs from '../../../campaigns/components/ReportBreadcrumbs/ReportBreadcrumbs';
import useAudienceBreadcrumbs from '../../hooks/useAudienceBreadcrumbs';

export const AudienceReportBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'app.campaigns.reports.datagrid.overlay.audiences.header',
        defaultMessage: 'Audiences',
    });
    const { steps } = useAudienceBreadcrumbs({
        title,
        isTitleAsLastStep: false,
    });

    return <ReportBreadcrumbs title={title} steps={steps} />;
};

export default AudienceReportBreadcrumbs;
