import React from 'react';
import { CommonCollapsibleCard } from 'components';
import { SectionHeader } from 'modules/campaigns/components';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations/index';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { EmailInstructions } from 'modules/tracking/components/EmailInstructions/EmailInstructions';
import { AdvancedOptions } from 'modules/tracking/components/ManualSetupAdvancedOptions/AdvancedOptions';
import PasteCode from './PasteCode';

export const PasteCodeToWebsite = () => {
    const isPermitted = useConfigMatch({ [FEATURE_FLAGS.IS_PIXEL_CODE_ENABLED]: 'true' });

    return (
        <CommonCollapsibleCard
            id="Paste Code"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="tracking.setup.paste.code" defaultMessage="2. Paste to website" />
                    }
                />
            }
        >
            <PasteCode />
            {isPermitted && <EmailInstructions />}
            <AdvancedOptions />
        </CommonCollapsibleCard>
    );
};
