import { useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { BASE_FORM_ROUTE_PATH } from 'config/routes';

const EMPTY_OBJECT = {};
export const useEntityType = (entityPathMap = EMPTY_OBJECT) => {
    const routeMatch = useRouteMatch(BASE_FORM_ROUTE_PATH);
    const entityPath = get(routeMatch, 'params.entity');

    return entityPathMap[entityPath] || entityPath;
};
