import { SimpleEventEmitter } from 'modules/taboola-common-frontend-modules/validations/services/validationService/simpleEventEmitter';

export const TEST_CREATED = 'TEST_CREATED';

export class ABTEventEmitter extends SimpleEventEmitter {
    constructor() {
        super();
        this.values = {};
    }
    createTest(experimentId, testValues) {
        if (!experimentId) {
            console.error('experimentId is required');
        }

        Object.keys(testValues).forEach(key => {
            this.values[key] = { value: testValues[key], experimentId };
            this.trigger(key);
        });

        this.trigger(TEST_CREATED, experimentId, testValues);
    }
    clear() {
        this.values = {};
    }
}

export const abtEventEmitter = new ABTEventEmitter();
