import React from 'react';
import classnames from 'classnames/bind';
import { Highlighter, Dotdotdot, DeletableItem, AlertOIcon, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import commonStyles from '../../commonAudienceListItemContent.module.scss';
import styles from './segmentDetails.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SegmentDetails = ({
    name,
    highLightText,
    handleOnClick,
    tooltip,
    disabled,
    disabledTooltip,
    indicationType,
    message,
    loading,
}) => {
    return (
        <DeletableItem
            aria-label="custom-contextual-segment-details"
            onDelete={handleOnClick}
            isItemDeletable
            buttonProps={{ 'aria-label': `Remove ${name} item` }}
            className={styles['deletable-item']}
            contentClassName={styles['deletable-item-content']}
        >
            <div className={classNameBuilder('label', { disabled })}>
                {tooltip}
                <Dotdotdot lines={3}>
                    <Highlighter text={name} highlightedText={highLightText} className={styles['highlighter']} />
                </Dotdotdot>
            </div>
            {disabled && disabledTooltip}
            {!loading && indicationType && (
                <span
                    className={commonStyles['error-icon-wrapper']}
                    aria-label={`${name} ${indicationType} icon`}
                    data-testid="errorIcon"
                >
                    <AlertOIcon className={commonStyles[`${indicationType}-icon`]} />
                    <Tooltip delay={800}>{message}</Tooltip>
                </span>
            )}
        </DeletableItem>
    );
};
