import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const TotalConversionTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="tracking.conversion.properties.total.conversion.field.tooltip"
            defaultMessage="Choose whether or not to include a conversion data in your total 'Conversions' reporting column.
            Include the most important ones since the algorithm will optimize towards these conversions.
            Changing this setting will affect the reports for the past 30 days."
        />
    </TooltipSection>
);
