import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CONVERSION_STATUS } from 'modules/tracking/config/constants';
import { generateConversionEditorPath } from 'modules/tracking/utils/generateTrackingPath';
import ActionsCellRenderer from './ActionsCellRenderer';

const ArchiveConversionTooltipContent = ({ isConversionArchived, isAnotherAccountConversion }) => {
    const messageId = isConversionArchived ? 'unarchive' : 'archive';
    return isAnotherAccountConversion ? (
        <FormattedMessage
            id="tracking.conversion.actionButtons.archive.otherPublisher"
            defaultMessage="Conversion of other accounts cannot be archived"
        />
    ) : (
        <FormattedMessage
            id={`tracking.conversion.actionButtons.${messageId}`}
            defaultMessage="Unarchived Conversion"
        />
    );
};

const EditConversionTooltipContent = ({ isAnotherAccountConversion }) =>
    isAnotherAccountConversion ? (
        <FormattedMessage
            id="tracking.conversion.actionButtons.edit.otherPublisher"
            defaultMessage="Conversion of other accounts cannot be edited"
        />
    ) : (
        <FormattedMessage id="tracking.conversion.actionButtons.edit" defaultMessage="Edit Conversion" />
    );

export const ConversionActionsCellRenderer = ({ data, onConversionArchive, context, ...rest }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [{ accountName }] = useSelectedAccount();
    const { accountName: currConversionAccountName, status } = data;
    const [isConversionArchived, setIsConversionArchived] = useState(status === CONVERSION_STATUS.ARCHIVED);
    const isDisabled = useMemo(
        () => accountName !== currConversionAccountName,
        [accountName, currConversionAccountName]
    );
    const buttonConfigs = [
        {
            onClick: () => {
                navigate(generateConversionEditorPath(currConversionAccountName, data.id));
            },
            isDisabled,
            dataMetricComponent: 'EditActionButton',
            iconLabel: 'edit-o',
            tooltipContent: <EditConversionTooltipContent isAnotherAccountConversion={isDisabled} />,
        },
        {
            onClick: () => {
                const conversion = {
                    id: data.id,
                    status: isConversionArchived ? CONVERSION_STATUS.DISABLED : CONVERSION_STATUS.ARCHIVED,
                };
                setIsConversionArchived(prevState => !prevState);
                dispatch(
                    onConversionArchive({
                        accountId: currConversionAccountName,
                        conversion,
                    })
                );
            },
            isDisabled,
            dataMetricComponent: 'archiveConversionActionButton',
            iconLabel: isConversionArchived ? 'unarchive' : 'archive',
            tooltipContent: (
                <ArchiveConversionTooltipContent
                    isConversionArchived={isConversionArchived}
                    isAnotherAccountConversion={isDisabled}
                />
            ),
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

ConversionActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    onEdit: PropTypes.func,
    onTogglePolicyState: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};
