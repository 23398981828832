import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFetchedCampaignGroup } from '../../CampaignGroupField/hooks/useFetchedCampaignGroup';

export const useCampaignTimeFrameRestrictions = () => {
    const {
        data: { campaignGroupId },
    } = useFormDataContext();
    const campaignGroup = useFetchedCampaignGroup(campaignGroupId);

    return {
        campaignGroupStartDate: campaignGroup?.startDate,
        campaignGroupEndDate: campaignGroup?.endDate,
    };
};
