import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from 'modules/tracking/components/FunnelConversions/primaryConversion.module.scss';

export const RuleType = ({ entityType }) => {
    return (
        <div className={styles['rule-text']}>
            <FormattedMessage
                id={`performance.rules.select.in.campaign.dropdown.rule.type.${entityType}`}
                defaultMessage="rule"
            />
        </div>
    );
};
