import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import {
    PHASES,
    DURATION_MS,
    PROGRESS_COLOR,
    calculateProgress,
    getTimeLeftProps,
} from 'modules/campaigns/modules/campaigns-reports/services/campaignStatusPhaseService';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import PhaseStatusTooltip from '../Tooltips/PhaseStatusTooltip';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

export const TargetCpaStatusRenderer = props => {
    const {
        data: { cvrLearningStatus: phase, cvrLearningStatusUpdateTimeInUtc },
        campaignStatusMessages,
        value: { status },
    } = props;

    const { phaseMsgIdPrefix } = campaignStatusMessages;

    const { [COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_TIME_DAYS]: maxCampaignLearningTimeDays } = useCommonConfig([
        COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_TIME_DAYS,
    ]);

    const { [COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_LIMITED_TIME_DAYS]: maxCampaignLearningLimitedTimeDays } =
        useCommonConfig([COMMON_FLAGS.TARGET_CPA_MAX_CAMPAIGN_LEARNING_LIMITED_TIME_DAYS]);

    const endDate = maxCampaignLearningTimeDays * DURATION_MS.ONE_DAY + cvrLearningStatusUpdateTimeInUtc;
    const remainingTimeInMs = moment(endDate).diff(moment().utc());

    const progress = useMemo(() => {
        if (phase === PHASES.CVR_LEARNING_LIMITED) {
            const hideProgress =
                moment.utc().diff(moment(cvrLearningStatusUpdateTimeInUtc), 'days') >
                maxCampaignLearningLimitedTimeDays;
            return hideProgress ? null : 90;
        }

        const totalTimeInMs = moment(endDate).diff(moment(cvrLearningStatusUpdateTimeInUtc));
        return calculateProgress(remainingTimeInMs, totalTimeInMs);
    }, [cvrLearningStatusUpdateTimeInUtc, endDate, maxCampaignLearningLimitedTimeDays, phase, remainingTimeInMs]);

    const progressBarColor = phase === PHASES.CVR_LEARNING_LIMITED ? PROGRESS_COLOR.WARNING : PROGRESS_COLOR.PRIMARY;
    const timeLeft = getTimeLeftProps(remainingTimeInMs);

    return (
        <StatusCellRenderer
            phase={phase}
            tooltipBody={<PhaseStatusTooltip phase={phase} phaseMsgIdPrefix={phaseMsgIdPrefix} />}
            progress={progress}
            progressBarColor={progressBarColor}
            timeLeft={
                phase === PHASES.CVR_LEARNING_LIMITED ? null : (
                    <FormattedMessage
                        id={`app.campaigns.campaign.status.time.left.${timeLeft.id}`}
                        values={{ value: timeLeft.value }}
                    />
                )
            }
            {...props}
            {...campaignStatusMessages}
            value={status}
        />
    );
};

export default TargetCpaStatusRenderer;
