/**
 * Created by Ishai.m
 * Date: 08-10-2020
 */
import React from 'react';
import { DuplicateIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import DuplicateCampaignSettings from '../DuplicateCampaignSettings/DuplicateCampaignSettings';

const DuplicateCampaignSettingsSection = () => {
    const isDuplicateCampaignSettingsEnabled = useConfigMatch({
        [FEATURE_FLAGS.IS_DUPLICATE_CAMPAIGN_SETTINGS_ENABLED]: 'true',
    });

    return (
        isDuplicateCampaignSettingsEnabled && (
            <CommonCollapsibleCard
                id="CAMPAIGN_DUPLICATE_SETTINGS"
                header={
                    <SectionHeader
                        headerIcon={<DuplicateIcon />}
                        headerText={
                            <FormattedMessage
                                id="campaign.editor.duplicateCampaignSettings"
                                defaultMessage="Duplicate Campaign Settings"
                            />
                        }
                    />
                }
            >
                <DuplicateCampaignSettings />
            </CommonCollapsibleCard>
        )
    );
};

export default DuplicateCampaignSettingsSection;
