import React from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useEventValueHandler } from '../../../../../../hooks';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormValidatedValue, validationFunctions } from '../../../../../taboola-common-frontend-modules/validations';
import DealIdTooltip from './DealIdTooltip';
import styles from '../radioBtnComponents.module.scss';

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: (value, options, { isEnableDealIDMatch }) =>
            !isEnableDealIDMatch || validationFunctions.isNotEmptyString(value, options),
        messageId: 'video.campaign.creator.dealID.validations.error.not.empty',
        defaultMessage: 'Enter Deal ID',
    },
];

export const DealId = () => {
    const isEnableDealIDMatch = useConfigMatch({ [FEATURE_FLAGS.ENABLE_DEAL_ID]: 'true' });
    const {
        value: dealId = '',
        onChange: onDealIdChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'dealId',
        validations,
        validationDependencies: {
            isEnableDealIDMatch,
        },
    });

    const changeHandler = useEventValueHandler(onDealIdChange);

    return (
        <FormField
            inputId="deal-id"
            containerClass={styles['input']}
            label={<FormattedMessage id="pmpDeal.id.label" defaultMessage="Deal ID" />}
            description={
                <FormattedMessage id="pmpDeal.id.description" defaultMessage="The unique identifier for the deal." />
            }
            helpText={<DealIdTooltip />}
            ref={scrollRef}
        >
            <InputWithIndication
                id="deal-id"
                onChange={changeHandler}
                value={dealId}
                type={InputTypes.TEXT}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="DealId"
                disabled={!isEnableDealIDMatch}
                {...indicationData}
            />
        </FormField>
    );
};

export default DealId;
