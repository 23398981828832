import { merge } from 'lodash';
import { defaultTheme } from 'tuui';

export const yahooTheme = (() => {
    const yahooPrimaryColor = '#0F69FF';
    const difference = {
        palette: {
            primary: {
                fill: yahooPrimaryColor,
                stroke: yahooPrimaryColor,
            },
            secondary: {
                fill: yahooPrimaryColor,
                stroke: yahooPrimaryColor,
            },
            info: {
                fill: yahooPrimaryColor,
                stroke: yahooPrimaryColor,
            },
        },
        typography: {
            font: 'Inter, Open Sans, Arial, sans-serif',
        },
    };
    const tempTheme = merge({}, defaultTheme, difference);

    return tempTheme;
})();
