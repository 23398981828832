import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, DeleteOutlinedIcon, ContentCopyOutlinedIcon } from 'tuui';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './contentItemActionButtons.module.scss';

const duplicateTooltip = (
    <Tooltip arrow interactive>
        <FormattedMessage
            id="creative.creator.content.action.button.duplicate.label"
            defaultMessage="Duplicate Content"
        />
    </Tooltip>
);

const deleteTooltip = (
    <Tooltip arrow interactive>
        <FormattedMessage id="creative.creator.content.action.button.delete.label" defaultMessage="Delete Content" />
    </Tooltip>
);

export const ContentItemActionButtons = ({ canDuplicate, canDelete, id, duplicateItem, deleteItem }) => {
    const handleClickDuplicate = useCallback(() => {
        duplicateItem(id);
    }, [duplicateItem, id]);

    const handleClickDelete = useCallback(() => {
        deleteItem(id);
    }, [deleteItem, id]);

    return (
        <div className={styles['container']}>
            <div>
                <Button
                    size={Button.size.md}
                    variant={Button.variant.text}
                    disabled={!canDuplicate}
                    onClick={handleClickDuplicate}
                    data-testid="duplicate-item"
                >
                    <ContentCopyOutlinedIcon />
                </Button>
                {canDuplicate ? duplicateTooltip : null}
            </div>
            <div>
                <Button
                    size={Button.size.md}
                    variant={Button.variant.text}
                    disabled={!canDelete}
                    onClick={handleClickDelete}
                    data-testid="delete-item"
                >
                    <DeleteOutlinedIcon />
                </Button>
                {canDelete ? deleteTooltip : null}
            </div>
        </div>
    );
};

ContentItemActionButtons.propTypes = {
    id: PropTypes.string,
    canDuplicate: PropTypes.bool,
    canDelete: PropTypes.bool,
    duplicateItem: PropTypes.func,
    deleteItem: PropTypes.func,
};

export default ContentItemActionButtons;
