import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CampaignsSectionTooltip from './CampaignsSectionTooltip';
import CampaignsSmartList from './CreativeEditorCampaignsSmartList';
import commonStyles from '../../../common-campaign-form/components/commonEditor.module.scss';
import styles from './campaignsSection.module.scss';

const CampaignsSection = () => (
    <FormField
        label={<FormattedMessage id="creative.duplicate.section.campaigns.title" defaultMessage="Campaigns" />}
        helpText={<CampaignsSectionTooltip />}
        inputId="creative-duplicate-campaign-selector"
        containerClass={classnames(commonStyles['input'], styles['section'])}
        description={
            <FormattedMessage
                id="creative.duplicate.section.campaigns.title"
                defaultMessage="Make sure the selected campaigns has the same targeting and context to keep the ad in approval status."
            />
        }
    >
        <CampaignsSmartList />
    </FormField>
);

export default CampaignsSection;
