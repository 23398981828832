import { useConditions } from 'modules/performance-rules/hooks/useConditions';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { ExcludeDaysValidations } from './ExcludeDaysValidations';

export const useDaysToExcludeValidatedField = () => {
    const { conditions } = useConditions();
    const {
        value: daysToExclude,
        onChange: daysToExcludeChange,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'daysToExclude',
        validations: ExcludeDaysValidations,
        formFieldDependencies: conditions,
        validationDependencies: { conditions },
    });
    return {
        daysToExclude,
        daysToExcludeChange,
        failedValidationData,
    };
};
