import React from 'react';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import BudgetPerformanceRecommendationSubTypeCard from './BudgetPerformanceRecommendationSubTypeCard';

const params = {
    titleCode: 'performance-recommendations.budget.title',
    actionCode: 'performance-recommendations.budget.action',
    tooltipCode: 'performance-recommendations.budget.action.tooltip',
    dismissCode: 'performance-recommendations.general-recommendation.dismiss',
    successCode: 'performance-recommendations.general-recommendation.success-message',
    errorCode: 'performance-recommendations.general-recommendation.error-message',
    descriptionCode: 'performance-recommendations.budget.description',
    iconImg: LightningRoundIcon,
};

export const BudgetPerformanceRecommendation = props => {
    return <BudgetPerformanceRecommendationSubTypeCard params={params} {...props} />;
};

BudgetPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default BudgetPerformanceRecommendation;
