import { keyBy } from 'lodash';

export const CHAT_AGENT_ACTION_TYPE = {
    NAVIGATE: 'NAVIGATE',
    FORM_ACTION: 'FORM_ACTION',
    SET_RECOMMENDED_DATA: 'SET_RECOMMENDED_DATA',
    EDIT_GRID_CELL: 'EDIT_GRID_CELL',
    ABORT: 'ABORT',
    CONNECT: 'CONNECT',
    DISCONNECT: 'DISCONNECT',
    OPEN_CHAT: 'OPEN_CHAT',
    CLOSE_CHAT: 'CLOSE_CHAT',
    MESSAGE: 'MESSAGE',
    FORM_WIZARD_STEP: 'FORM_WIZARD_STEP',
    CHANGE_RENDER_MODE: 'CHANGE_RENDER_MODE',
};

export const CHAT_AGENT_MESSAGE_TYPE = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND',
};

export const OFF_CYCLE_ACTIONS = keyBy([
    CHAT_AGENT_ACTION_TYPE.CONNECT,
    CHAT_AGENT_ACTION_TYPE.DISCONNECT,
    CHAT_AGENT_ACTION_TYPE.OPEN_CHAT,
    CHAT_AGENT_ACTION_TYPE.CLOSE_CHAT,
    CHAT_AGENT_ACTION_TYPE.MESSAGE,
]);

export const MANUAL_GA_EVENTS = keyBy([CHAT_AGENT_ACTION_TYPE.OPEN_CHAT, CHAT_AGENT_ACTION_TYPE.CLOSE_CHAT]);

export const ASYNC_ACTIONS = keyBy([CHAT_AGENT_ACTION_TYPE.FORM_ACTION, CHAT_AGENT_ACTION_TYPE.EDIT_GRID_CELL]);
