import React, { Fragment, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { generatePath } from 'react-router';
import { isEmpty, keys } from 'lodash';
import PropTypes from 'prop-types';
import { SeparatorDropdown, ColumnsIcon, pxToRem, EditOIcon } from 'taboola-ultimate-ui';
import { DeleteIcon } from 'taboola-ultimate-ui';
import { InternalLink } from 'components';
import { createMenuListWithFooter } from 'components/Dropdown';
import { FORM_MODES } from 'config/formModes';
import { MODULE_PARAM_NAME, ENTITY_PARAM_NAME, MODE_PARAM_NAME, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { useModuleName } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useDBStorage } from '../../../../../taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { useColumnDefs, useSelectedReportId } from '../../hooks';
import { useColumnState } from '../../hooks/useColumnState';
import { useReportPresetName } from '../../hooks/useReportPresetName';
import { useReportSorting } from '../../hooks/useReportSorting';
import { COLUMN_PICKER_ROUTE_PATH, ENTITY, ENTITY_TO_PATH_MAP, REPORT_PRESET_PARAM_NAME } from '../../routes';
import { DropdownCreatePresetOption } from './DropdownCreatePresetOption/DropdownCreatePresetOption';
import { ReportPresetPickerMenuItem } from './ReportPresetPickerMenuItem';
import { useDeletePresetCallback } from './hooks/useDeletePresetCallback';
import { useReportPresets } from './hooks/useReportPresets';
import styles from './reportPresetPicker.module.scss';

const customPresetMenuListRenderer = createMenuListWithFooter(DropdownCreatePresetOption);

const controlStyles = {
    control: defaultStyles => ({
        ...defaultStyles,
        minHeight: pxToRem(34),
        borderColor: styles.gray4,
        '&:hover': {
            borderColor: styles.gray6,
        },
    }),
};

const leftSide = (
    <Fragment>
        <ColumnsIcon className={styles['columns']} />{' '}
        <FormattedMessage id="app.campaigns.reports.columnPicker.leftSide.label" defaultMessage="Columns" />
    </Fragment>
);

const ReportPresetPicker = ({ reportConfig }) => {
    const module = useModuleName();
    const { allPresetsColumnDefs: columnDefs } = useColumnDefs(reportConfig);
    const [selectedReport] = useSelectedReportId();
    const [reportPresetName, setReportPresetName] = useReportPresetName();
    const { saveColumnStateByPresetAndColumnDefs } = useColumnState(selectedReport, reportPresetName);
    const reportPresets = useReportPresets(reportConfig);
    const getDeletePresetCallback = useDeletePresetCallback(selectedReport);
    const { isReady: isUserSettingsReady } = useDBStorage();

    const options = useMemo(() => {
        return Object.values(reportPresets).map(({ presetName, presetDisplayName, isGlobal, isCustom }) => {
            if (isCustom) {
                const columnPickerEditPath = generatePath(COLUMN_PICKER_ROUTE_PATH, {
                    [MODULE_PARAM_NAME]: module,
                    [ENTITY_PARAM_NAME]: ENTITY_TO_PATH_MAP[ENTITY.COLUMN_PICKER],
                    [MODE_PARAM_NAME]: FORM_MODE_TO_PATH_MAP[FORM_MODES.EDIT],
                    [REPORT_PRESET_PARAM_NAME]: presetName,
                });
                const label = presetDisplayName || presetName;
                const deletePresetCallback = getDeletePresetCallback(presetName, label);
                return {
                    value: presetName,
                    label,
                    isGlobal,
                    isCustom,
                    iconButtons: [
                        {
                            IconComponent: props => (
                                <InternalLink
                                    path={columnPickerEditPath}
                                    data-metrics-component="ColumnPicker"
                                    data-metrics-value="Edit"
                                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                                >
                                    <EditOIcon {...props} aria-label="Open custom column editor" />
                                </InternalLink>
                            ),
                            tooltip: (
                                <FormattedMessage
                                    id="app.campaigns.reports.columnPicker.dropdown.customizeIcon"
                                    defaultMessage="Customize this preset"
                                />
                            ),
                        },
                        {
                            IconComponent: props => (
                                <DeleteIcon
                                    {...props}
                                    data-metrics-component="ColumnPicker"
                                    data-metrics-value="Delete"
                                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                                    aria-label="Delete custom column preset"
                                />
                            ),
                            handleClick: deletePresetCallback,
                            tooltip: (
                                <FormattedMessage
                                    id="app.campaigns.reports.columnPicker.dropdown.deleteIcon"
                                    defaultMessage="Delete this preset"
                                />
                            ),
                        },
                    ],
                };
            }

            return {
                value: presetName,
                label: (
                    <FormattedMessage
                        id={`app.campaigns.reports.columnPicker.view.${presetName}`}
                        defaultMessage={presetName}
                    />
                ),
            };
        });
    }, [reportPresets, module, getDeletePresetCallback]);

    const [reportSorting] = useReportSorting();
    const onChange = selection => {
        const reportPresetName = selection.value;

        batch(() => {
            setReportPresetName(reportPresetName);
            saveColumnStateByPresetAndColumnDefs({
                reportConfig,
                reportId: selectedReport,
                reportPresetName,
                reportSorting,
                columnDefs,
            });
        });
    };

    useEffect(() => {
        if (isUserSettingsReady && !isEmpty(reportPresets) && !reportPresets[reportPresetName]) {
            const validReportPresetName = keys(reportPresets)[0];
            setReportPresetName(validReportPresetName);
        }
    }, [isUserSettingsReady, reportPresetName, reportPresets, setReportPresetName]);

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="ColumnPicker"
            data-automation="campaign-report-column-picker"
            className={styles['main-box']}
        >
            <SeparatorDropdown
                options={options}
                selectedValue={reportPresetName}
                onChange={onChange}
                leftSide={leftSide}
                styles={controlStyles}
                className={styles['main-box']}
                optionRenderer={ReportPresetPickerMenuItem}
                menuListRenderer={reportConfig.customPreset ? customPresetMenuListRenderer : undefined}
                disabled={!isUserSettingsReady}
            />
        </div>
    );
};

ReportPresetPicker.propTypes = {
    reportConfig: PropTypes.object,
};

export default ReportPresetPicker;
