import React from 'react';
import classnames from 'classnames/bind';
import { Button, AddIcon } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useCreateFormSelectedCampaignId } from '../../../../../hooks/useCreateFormSelectedCampaignId';
import { useAiVariationsEventsPrefix } from '../../../../AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import selected from './selected.svg';
import styles from './aiTextSuggestionsItem.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AiTextSuggestionsItem = ({ suggestionText, onSuggestionClick, labelType, isSelected }) => {
    const campaignId = useCreateFormSelectedCampaignId();
    const eventsPrefix = useAiVariationsEventsPrefix();

    return (
        <Button
            onClick={() => onSuggestionClick(suggestionText)}
            variant={Button.variant.ghost}
            className={classNameBuilder('suggested-item', { selected: isSelected })}
            data-metrics-component={`${eventsPrefix}_${labelType}_Suggestion`}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-taboola-campaign-id={campaignId}
            data-metrics-value={suggestionText}
        >
            {isSelected ? <img src={selected} alt="selected" /> : <AddIcon className={styles['plus-icon']} />}
            <div className={styles['text']}>{suggestionText}</div>
        </Button>
    );
};
