import { throttle } from 'lodash';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { MISSED_TOKEN_TIMEOUT } from './missedTokenTimeout';

export const notifyIfNotReceivedToken = throttle(
    () => {
        gtmTracker.trackError(new Error('Missed parent token'));
    },
    MISSED_TOKEN_TIMEOUT,
    { leading: false }
);
