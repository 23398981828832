import React from 'react';
import { DropdownMenu, FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCountries } from '../../../../../../../../hooks';
import styles from './countrySection.module.scss';

const countryDropdownMessage = (
    <FormattedMessage id="custom.contextual.size.estimation.country.placeholder" defaultMessage="Pick a country" />
);

export const CountrySection = () => {
    const { value: selectedCountry, onChange: setSelectedCountry } = useFormFieldValue({ field: 'selectedCountry' });
    const { countries, isLoading: isLoadingCountries } = useCountries();

    return (
        <FormField
            label={
                <FormattedMessage
                    id="country.select.section.description"
                    defaultMessage="Select country for the following options"
                />
            }
            helpText={
                <FormattedMessage
                    id="country.select.section.tooltip"
                    defaultMessage="The downloaded excel file will have city, region, and DMA information relevant to the selected country. Selecting a country without DMA information will result in an empty DMA tab."
                />
            }
        >
            <DropdownMenu
                selectedValueObject={{ value: selectedCountry }}
                onChange={({ value }) => setSelectedCountry(value)}
                placeholder={countryDropdownMessage}
                className={styles['country-select']}
                options={countries}
                isLoading={isLoadingCountries}
                aria-label="country-dropdown"
                searchable={true}
            />
        </FormField>
    );
};
