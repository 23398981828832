import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

export const useBundleNodeHandlersWithMetrics = ({
    handleSelectNode,
    handleSelectAll,
    search,
    selectedNodes,
    setDefaultEnabledProviders,
}) => {
    // when selected nodes are empty need to set defaultProviders in case of node select
    // it needed for tree to check only enabled nodes
    const handleSelectNodeWrapper = useCallback(
        (path, node, checked) => {
            const { label, taxonomy } = node;
            const component = search ? 'Marketplace Search' : 'Marketplace Browse';

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component,
                value: label,
                columnName: taxonomy,
            });

            if (isEmpty(selectedNodes)) {
                setDefaultEnabledProviders();
            }

            handleSelectNode(node, checked);
        },
        [handleSelectNode, setDefaultEnabledProviders, selectedNodes, search]
    );

    const handleSelectAllWrapper = useCallback(
        check => {
            if (isEmpty(selectedNodes)) {
                setDefaultEnabledProviders();
            }

            handleSelectAll(check);
        },
        [handleSelectAll, setDefaultEnabledProviders, selectedNodes]
    );

    return { handleSelectNodeWrapper, handleSelectAllWrapper };
};
