import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import { CampaignEditorHeader, useCampaignEditorState } from 'modules/campaigns/modules/common-campaign-form';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DRAWER_MODE } from '../../../../components/SideDrawer/SideDrawer';
import { selectedAccountSelector } from '../../../../selectors';
import { isNetworkAccountType } from '../../../account-management';
import { CAMPAIGN_STATUS } from '../../../campaigns/config';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { useEditDrawerMode } from '../../components/EditDrawer/hooks/useEditDrawerMode';
import { FormLayout, useVerticalScrollDirection } from '../../components/Form';
import { PHASES } from '../campaigns-reports/services/campaignStatusPhaseService';
import styles from './campaignEditorFormBase.module.scss';

const WARNING_TITLE = (
    <FormattedMessage
        id="campaign.editor.warning.learning.title"
        defaultMessage="Your Campaign's Learning Phase is in Progress"
    />
);

const WARNING_MESSAGE = (
    <FormattedMessage
        id="campaign.editor.warning.learning.message"
        defaultMessage="Altering your campaign now may affect Taboola's learning algorithm. We suggest waiting until the learning period has ended before changing your campaign settings."
    />
);

const classNameBuilder = classnames.bind(styles);

export const CampaignEditorFormBase = ({ onCancel, ContentComponent }) => {
    const {
        fetchStatus,
        isSubmitDisabled,
        submitInProgress,
        mode,
        formAccount: { currency },
        data: campaign,
    } = useFormDataContext();
    const { type: selectedAccountType } = useSelector(selectedAccountSelector);
    const { submit } = useCampaignEditorState();

    const drawerMode = useEditDrawerMode();
    const withWidget = drawerMode === DRAWER_MODE.MEDIUM;

    const isAccountSelectionPermitted = usePermissions('NETWORK_LEVEL_DUPLICATE_CAMPAIGN_DROPDOWN');
    const isAccountDisplayableMode =
        mode === FORM_MODES.DUPLICATE || mode === FORM_MODES.EDIT || mode === FORM_MODES.PREVIEW;
    const shouldRenderAccountDropdown =
        isAccountDisplayableMode && isAccountSelectionPermitted && isNetworkAccountType(selectedAccountType);

    const { scrollElementRef: scrollRef, down: collapsedHeader } = useVerticalScrollDirection();
    const indications = [
        {
            showIndication:
                mode === FORM_MODES.EDIT &&
                campaign?.campaignStatusTrackerData?.phaseName === PHASES.EXPEDITED_LEARNING &&
                campaign?.status === CAMPAIGN_STATUS.RUNNING,
            title: WARNING_TITLE,
            message: WARNING_MESSAGE,
            type: INDICATION_TYPES.WARNING,
        },
    ];

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={
                    <CampaignEditorHeader
                        status={fetchStatus}
                        headerClassName={styles['campaign-header']}
                        campaignStatusClassName={styles['campaign-status']}
                        collapsed={collapsedHeader}
                    />
                }
                indications={indications}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.campaign`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['campaign-editor']}
                bodyContainerClassName={styles['campaign-editor-body']}
                footerContainerClassName={styles['campaign-editor-footer']}
                data-automation="CampaignEditor"
                bodyRef={scrollRef}
                collapsedHeader={collapsedHeader}
            >
                <div className={classNameBuilder('content', { 'with-widget': withWidget })}>
                    <ContentComponent
                        status={fetchStatus}
                        currency={currency}
                        shouldRenderAccountDropdown={shouldRenderAccountDropdown}
                    />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

CampaignEditorFormBase.propTypes = {
    onCancel: PropTypes.func,
    ContentComponent: PropTypes.func,
};

export default CampaignEditorFormBase;
