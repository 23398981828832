import { getStatusType, STATUS_TYPE } from './statusConsts';

export const CONVERSION_RULES_STATUS = {
    ACTIVE: 'ACTIVE',
    NO_ACTIVITY_YET: 'NO_ACTIVITY_YET',
    NO_RECENT_ACTIVITY: 'NO_RECENT_ACTIVITY',
    DISABLED: 'DISABLED',
    ARCHIVED: 'ARCHIVED',
};

const conversionRulesConfig = {
    [CONVERSION_RULES_STATUS.ACTIVE]: { statusType: STATUS_TYPE.SUCCESS },
    [CONVERSION_RULES_STATUS.NO_ACTIVITY_YET]: { statusType: STATUS_TYPE.DANGER },
    [CONVERSION_RULES_STATUS.NO_RECENT_ACTIVITY]: { statusType: STATUS_TYPE.DANGER },
    [CONVERSION_RULES_STATUS.DISABLED]: { statusType: STATUS_TYPE.DANGER },
    [CONVERSION_RULES_STATUS.ARCHIVED]: { statusType: STATUS_TYPE.DANGER },
};

export const getConversionRulesStatusType = conversionRulesStatus =>
    getStatusType(conversionRulesConfig, conversionRulesStatus);
