import React, { useMemo } from 'react';
import { withIndication } from 'modules/errors/components/withIndication';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData/hooks';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import {
    validationFunctions,
    withCheckboxGroupValidations,
} from '../../../../../taboola-common-frontend-modules/validations';
import { HOURS_OF_DAY, TARGETING_TYPES } from '../../config';
import DaypartingButton from './DaypartingButton';
import DaypartingTable from './DaypartingTable';
import DAYPARTING_DEFAULT from './config/daypartingDefault';
import styles from './dayparting.module.scss';

const DaypartingTableWithValidations = withCheckboxGroupValidations(withIndication(DaypartingTable), [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'campaign.editor.schedule.dayparting.selection.error',
        defaultMessage: 'Select at least one day to run your campaign',
    },
]);

const buildHourOptions = () => {
    const hourOptions = Object.values(HOURS_OF_DAY).map(hour => ({
        label: <FormattedMessage id={`campaign.editor.schedule.dayparting.hour.${hour}`} />,
        value: hour,
    }));

    return { startTimeOptions: hourOptions.slice(0, 24), endTimeOptions: hourOptions.slice(1) };
};

const Dayparting = () => {
    const isDayPartingPermitted = useConfigMatch({ [FEATURE_FLAGS.SCHEDULE_DAY_TIME_ENABLED]: 'true' });
    const { value: daypartingConfig, onChange: onRulesChange } = useFormFieldValue(
        { field: 'campaignSchedule.rules' },
        { isDayPartingPermitted }
    );
    const handleReset = () => onRulesChange([...DAYPARTING_DEFAULT]);

    const hourOptions = useMemo(buildHourOptions, [HOURS_OF_DAY]);
    const { startTimeOptions, endTimeOptions } = hourOptions;

    const includedDays = daypartingConfig.filter(item => item.type === TARGETING_TYPES.INCLUDE);

    return (
        <div className={styles['container']}>
            <DaypartingTableWithValidations
                id="campaignSchedule.rules"
                errorMessageClass={styles['error-messsage']}
                selectedValues={includedDays}
                daypartingConfig={daypartingConfig}
                onRulesChange={onRulesChange}
                startTimeOptions={startTimeOptions}
                endTimeOptions={endTimeOptions}
            />
            <DaypartingButton onClick={handleReset}>
                <FormattedMessage id="campaign.editor.schedule.dayparting.reset" />
            </DaypartingButton>
        </div>
    );
};

export default Dayparting;
