import { isNil } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { buildOptionsObject } from 'modules/taboola-common-frontend-modules/validations/services/validationFunctions';

const SELF_FACTOR_COEF = 5;
export const SELF_FACTOR_FIELD_NAME = 'savedField';

const createFactorValidator =
    coef =>
    (value, options, { data } = {}) => {
        if (isNil(data[SELF_FACTOR_FIELD_NAME])) {
            return true;
        }

        const patchedData = {
            ...data,
            [SELF_FACTOR_FIELD_NAME]: data[SELF_FACTOR_FIELD_NAME] * coef,
        };

        return validationFunctions.isFloat(value, buildOptionsObject(patchedData, options));
    };

export const audienceTargetingBidValidator = (value, options, { data } = {}) => {
    const { marketPlaceAudienceExcludeValuesLength, marketPlaceAudienceIncludeValuesLength } = data;
    return marketPlaceAudienceExcludeValuesLength || marketPlaceAudienceIncludeValuesLength
        ? validationFunctions.isFloat(value, buildOptionsObject(data, options))
        : true;
};

export const bidValidationsBase = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.campaign.bid.empty',
        defaultMessage: 'Enter a Bid Amount',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { lt: 'budgetValue' },
        messageId: 'validations.error.campaign.bid.maxValue',
        defaultMessage: 'Bid must be a smaller than budget',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.isFloat,
        options: { min: 0.0001 },
        messageId: 'validations.error.campaign.bid.minValue',
        defaultMessage: 'Bid must be a positive number',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const selfFactorValidations = [
    {
        validationFn: createFactorValidator(1 / SELF_FACTOR_COEF),
        options: { min: SELF_FACTOR_FIELD_NAME },
        messageId: 'validations.error.campaign.bid.safe.minValue',
        defaultMessage: 'Please note: Bid is lower than your normal range.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: createFactorValidator(SELF_FACTOR_COEF),
        options: { max: SELF_FACTOR_FIELD_NAME },
        messageId: 'validations.error.campaign.bid.safe.maxValue',
        defaultMessage: 'Please note: Bid is above your normal range.',
        severity: INDICATION_TYPES.WARNING,
    },
];
