import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isNetworkAccountType } from 'modules/account-management';
import { useDeleteCampaignGroup } from 'modules/campaigns/hooks/useDeleteCampaignGroup';
import { useCampaignGroupExtensionEnabled } from 'modules/campaigns/modules/campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import useOpenCampaignsGroupEditor from 'modules/campaigns/modules/campaigns-group/hooks/useOpenCampaignsGroupEditor';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from 'selectors';
import { isCampaignGroupDeleted } from '../../config/predicates/campaignGroupPredicates';
import ActionsCellRenderer from './ActionsCellRenderer';

const CampaignsGroupEditTooltipContent = ({ isDisabled }) =>
    isDisabled ? (
        <FormattedMessage
            id="app.campaigns.editCampaignsGroup.disabled"
            defaultMessage="Editing Campaign group from the network level is not supported yet"
        />
    ) : (
        <FormattedMessage id="app.campaigns.editCampaignsGroup" defaultMessage="Edit Group" />
    );

const CampaignsGroupActionsCellRenderer = ({ data, context, ...rest }) => {
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(isCampaignGroupDeleted(data));
    const openCampaignsGroupEditor = useOpenCampaignsGroupEditor(data);
    const { type } = useSelector(selectedAccountSelector);
    const isDisabled = context?.userPermissions && isNetworkAccountType(type);
    const isCampaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const deleteCampaignGroup = useDeleteCampaignGroup();
    const isDeleteEnabled = useConfigMatch({ [FEATURE_FLAGS.DELETE_CAMPAIGN_GROUP_ENABLED]: 'true' });

    const buttonConfigs = [
        {
            onClick: () => openCampaignsGroupEditor(data),
            isDisabled,
            dataMetricComponent: 'EditActionButton',
            iconLabel: 'edit-o',
            tooltipContent: <CampaignsGroupEditTooltipContent isDisabled={isDisabled} />,
        },
    ];

    if (isCampaignGroupExtensionEnabled && isDeleteEnabled) {
        buttonConfigs.push({
            onClick: () => {
                setIsDeleteDisabled(true);
                deleteCampaignGroup(data);
            },
            dataMetricComponent: 'DeleteActionButton',
            iconLabel: 'delete',
            tooltipContent: <FormattedMessage id="app.campaigns.delete.campaign.group" defaultMessage="Delete Group" />,
            isDisabled: isDisabled || isDeleteDisabled,
        });
    }

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};

CampaignsGroupActionsCellRenderer.propTypes = {
    data: PropTypes.object,
    onEdit: PropTypes.func,
    ...ActionsCellRenderer.propTypes,
};

export default CampaignsGroupActionsCellRenderer;
