import { getParamDependentValues, queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { REPORT_SETTINGS_PREFIX } from 'modules/taboola-common-frontend-modules/storage/DBStorage/constant';
import { REPORT_ID } from './useSelectedReportId';

export const REPORT_FILTERS = 'filters';

export const getReportFilterKey = reportId => `${REPORT_SETTINGS_PREFIX}.${reportId}.filters`;

export const usePermanentReportFilters = queryParamHookFactory(REPORT_FILTERS, {
    serializer: JSON.stringify,
    deserializer: value => {
        if (!value) {
            return;
        }

        if (typeof value !== 'string') {
            return value;
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {}
    },
    dependencies: { queryParams: [REPORT_ID] },
    persist: PERSISTENCE_TYPE.DB_STORAGE,
    storageKeyGetter: (paramName, metadata) => {
        const dependentValues = getParamDependentValues(paramName, metadata);

        return getReportFilterKey(dependentValues[REPORT_ID]);
    },
});
