import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { NodeLabel, Tree } from '../../Tree';
import SelectAll from '../../Tree/SelectAll/SelectAll';
import ContextualSegmentsLeafContent from '../ContextualSegmentsLeafContent/ContextualSegmentsLeafContent';
import { useContextualSegmentsTreeContext } from '../ContextualSegmentsTreeContext';
import styles from './contextualSegmentsTree.module.scss';

const needRenderCheckbox = ({ depth }) => depth >= 0;

const ContextualTree = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        nodesTree,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        disabledPathsMap,
        collapsedPathMap,
        totalCount,
        handleCollapseChange,
    } = useContextualSegmentsTreeContext();
    const [isAllChecked, setIsAllChecked] = useState(false);

    const renderNodeText = useCallback(
        (domProps, { hasChildren, node }) => {
            if (hasChildren) {
                const { label } = node;
                return <NodeLabel node={node} searchText={search} title={label} />;
            }

            return <ContextualSegmentsLeafContent item={node} searchText={search} />;
        },
        [search]
    );

    const handleSelectAllWrapper = useCallback(() => {
        handleSelectAll(!isAllChecked);
        setIsAllChecked(prev => !prev);
    }, [handleSelectAll, isAllChecked]);

    const renderSelectAll = useCallback(
        () =>
            isSearchMode ? (
                <SelectAll onClick={handleSelectAllWrapper} disabled={totalCount === 0}>
                    <FormattedMessage
                        id={`campaign.editor.targeting.contextual.${!isAllChecked ? 'selectAll' : 'deselectAll'}.title`}
                        defaultMessage="Select all {totalItems} results"
                        values={{ totalItems: totalCount }}
                    />
                </SelectAll>
            ) : null,
        [isSearchMode, handleSelectAllWrapper, totalCount, isAllChecked]
    );

    return (
        <div className={styles['container']} ref={innerRef}>
            <Tree
                nodesTree={nodesTree}
                onSelect={handleSelectNode}
                selectedItemsMap={selectedPathsMap}
                totalItems={totalCount}
                collapsed={collapsedPathMap}
                onCollapsedChange={handleCollapseChange}
                renderNodeText={renderNodeText}
                renderSelectAll={renderSelectAll}
                disabledPathsMap={disabledPathsMap}
                noOptionsContent={<FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />}
                loadingMode={loadingMode}
                needRenderCheckbox={needRenderCheckbox}
            />
        </div>
    );
};

ContextualTree.propTypes = {
    innerRef: PropTypes.func,
};

export default ContextualTree;
