import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon, ChevronLeftOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from '../../../../services/campaignDimension';
import { isPmpActiveAccount } from '../../../account-management/accountType';
import { useCustomContextualPermitted } from '../../../audiences/hooks/useCustomContextualPermitted';
import { FormButtons } from '../../components';
import { REPORT_TYPE } from '../../config';
import { ENTITY_MESSAGE_PREFIX } from '../../config/routes';
import useCampaignBreadcrumbs from '../../hooks/useCampaignBreadcrumbs';
import {
    NameCreate,
    SetupSection,
    TargetingSection,
    TrackingSection,
    useCampaignCreatorState,
    DealId,
    EvergreenField,
    AttributionGroup,
    BudgetSectionPmpDeal,
    FormBreadcrumbs,
    MarketingObjective,
    ContextualTargetingSection,
    AudienceTargetingSection,
    AudienceTargetingSectionLegacy,
} from '../common-campaign-form';
import { TimeFrameSection, Representatives } from '../common-campaign-form/components';
import { pmpDealCampaignInitialValues } from '../common-campaign-form/config';
import commonStyles from '../common-campaign-form/components/commonEditor.module.scss';
import styles from '../sponsored-campaign-creator/sponsoredCampaignCreator.module.scss';

const dimension = CAMPAIGN_DIMENSION.SPONSORED;

const PmpDealCreator = ({ onCancel }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: 'pmpDeal.creator.new.pmp.deal', defaultMessage: 'New PMP Deal' });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN,
        title,
    });

    const { step, prevStep, nextStep, handleSetStep, submit, wizardTemplateColumns } = useCampaignCreatorState({
        dimension,
    });

    const accountFilter = useCallback(account => isPmpActiveAccount(account), []);
    const isCustomContextualPermitted = useCustomContextualPermitted();
    const audiencesTargetingSectionWithWizard = isCustomContextualPermitted ? (
        <>
            <WizardStep
                label={
                    <FormattedMessage id="campaign.editor.contextual.targeting" defaultMessage="Contextual Targeting" />
                }
                id="contextual-targeting"
            >
                <ContextualTargetingSection />
            </WizardStep>
            <WizardStep
                label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
                id="audiences-targeting"
            >
                <AudienceTargetingSection />
            </WizardStep>
        </>
    ) : (
        <WizardStep
            label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
            id="audiences-targeting"
        >
            <AudienceTargetingSectionLegacy />
        </WizardStep>
    );

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="pmpDeal.creator.deal.settings.step.label"
                            defaultMessage="Deal Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="pmpDeal.editor.setup" defaultMessage="Deal Setup" />}
                            id="setup"
                        >
                            <SetupSection
                                isAccountSelectionEnabled
                                accountFilter={accountFilter}
                                messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL}
                                noAccountsMessageId="pmpDeal.editor.setup.no.pmp.deal.accounts"
                            >
                                <NameCreate messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL} />
                                <DealId />
                                <EvergreenField />
                                <MarketingObjective pixelAndConversionWarningEnabled />
                                <Representatives />
                                <AttributionGroup />
                            </SetupSection>
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.schedule" defaultMessage="Schedule" />}
                            id="schedule"
                        >
                            <TimeFrameSection />
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="pmpDeal.editor.targeting" defaultMessage="Deal Targeting" />}
                            id="targeting"
                        >
                            <TargetingSection
                                isIncludeHighImpactTargeting={true}
                                messageIdPrefix={ENTITY_MESSAGE_PREFIX.PMP_DEAL}
                            />
                        </WizardStep>
                        {audiencesTargetingSectionWithWizard}
                        <FormButtons
                            onSubmit={nextStep}
                            onCancel={onCancel}
                            submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                            submitIcon={<ChevronRightOutlinedIcon />}
                            submitDataMetricsComponent="NextButton"
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            submitButtonType="submit"
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>
                <WizardStep
                    label={
                        <FormattedMessage id="campaign.creator.budget.bidding" defaultMessage="Budget and Bidding" />
                    }
                    id="budgeting"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.budget" defaultMessage="Budget" />}
                            id="budget"
                        >
                            <BudgetSectionPmpDeal />
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.tracking" defaultMessage="Tracking" />}
                            id="tracking"
                        >
                            <TrackingSection />
                        </WizardStep>
                        <FormButtons
                            onSubmit={submit}
                            onCancel={onCancel}
                            onBack={prevStep}
                            submitLabel={
                                <FormattedMessage
                                    id="campaign.creator.submit.button"
                                    defaultMessage="CREATE CAMPAIGN"
                                />
                            }
                            backLabel={<FormattedMessage id="app.actionButtons.back" />}
                            backIcon={<ChevronLeftOutlinedIcon />}
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            submitButtonType="submit"
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

PmpDealCreator.propTypes = { onCancel: PropTypes.func };

const PmpDealCreatorWithFormDataProvider = withFormDataProvider(PmpDealCreator, {
    defaultValuesConfig: pmpDealCampaignInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { PmpDealCreatorWithFormDataProvider as PmpDealCreator };
