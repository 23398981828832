import { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import { FormField, INDICATION_TYPES, IndicationContent } from 'taboola-ultimate-ui';
import { ToggleSwitchWithLabel } from 'components/ToggleSwitchWithLabel/ToggleSwitchWithLabel';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useSpendGuard } from './useSpendGuard';
import styles from '../commonEditor.module.scss';
import commonStyles from '../commonEditor.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SpendGuardField = () => {
    const { value: isSpendGuardActive, onChange: setIsSpendGuardActive } = useFormFieldValue({
        field: 'isSpendGuardActive',
    });
    const [isToggled, setIsToggled] = useState(isSpendGuardActive === 'ACTIVE');
    const { switchLabel, title, submitButtonText, cancelButtonText, content } = useSpendGuard(isToggled);
    const { open: openModal } = useModal();

    const handleToggle = useCallback(() => {
        const toggleSpendGuardActive = () => {
            if (isSpendGuardActive === 'ACTIVE') {
                setIsSpendGuardActive('DISABLE');
            } else if (isSpendGuardActive === 'DISABLE') {
                setIsSpendGuardActive('ACTIVE');
            }
        };
        setIsToggled(prevState => !prevState);
        toggleSpendGuardActive();
    }, [setIsToggled, isSpendGuardActive, setIsSpendGuardActive]);

    const onSelect = useCallback(async () => {
        const result = await openModal({
            title,
            content,
            formProps: {
                cancelButtonText,
                submitButtonText,
            },
        });
        if (result) {
            handleToggle();
        }
    }, [openModal, content, submitButtonText, cancelButtonText, title, handleToggle]);

    return (
        <FormField
            helpText={
                <>
                    <FormattedMessage id="campaign.editor.spend.guard.title" defaultMessage="SpendGuard" tagName="h4" />
                    <FormattedMessage
                        id="campaign.editor.spend.guard.help.text"
                        defaultMessage={
                            'SpendGuard analyzes sites performance (based on CPA and Conversion scale), and compares each site’s performance to the campaign’s average.\n' +
                            'In scenarios where a site is significantly underperforming, SpendGuard will automatically place a spending cap or completely block that site.\n'
                        }
                    />
                </>
            }
            inputId="spend-guard"
            label={<FormattedMessage id="campaign.editor.spend.guard.title" defaultMessage="SpendGuard" />}
            description={
                <FormattedMessage
                    id="campaign.editor.spend.guard.description"
                    defaultMessage="Automated algorithm that identifies significantly under performing sites and limits the campaign’s spend on them, either by capping them or completely blocking them."
                />
            }
            containerClass={styles['input']}
        >
            <ToggleSwitchWithLabel checked={isToggled} onChange={onSelect} label={switchLabel} />
            {!isToggled && (
                <IndicationContent
                    containerClassName={classNameBuilder('spend-guard-disable', commonStyles['warning-indication'])}
                    type={INDICATION_TYPES.WARNING}
                >
                    <FormattedMessage
                        id="campaign.editor.spend.guard.campaign.disable.warning"
                        defaultMessage="SpendGuard is Disabled. This may cause undesired spend on under performing sites."
                    />
                </IndicationContent>
            )}
        </FormField>
    );
};
