import React from 'react';
import { FormField, Input } from 'taboola-ultimate-ui';
import styles from './titleGenerationInputField.module.scss';

export const TitleGenerationInputField = ({ inputId, label, placeholder, value, onChange, disabled }) => (
    <FormField inputId={inputId} label={label} labelClass={styles['label']} containerClass={styles['form-field']}>
        <Input
            id={inputId}
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            inputWrapperClass={styles['input']}
            inputClass={styles['input']}
            disabled={disabled}
        />
    </FormField>
);
