import { useMemo } from 'react';
import moment from 'moment-timezone';
import { ONBOARDING_DURATION_DAYS } from '../consts';

const getDaysSinceFirstCampaign = firstCampaignStartDate => {
    const currentMoment = moment();
    const firstCampaignStartDateMoment = moment(firstCampaignStartDate);
    const daysSinceFirstCampaign = currentMoment.diff(firstCampaignStartDateMoment, 'days');

    return daysSinceFirstCampaign;
};

export const useHasOnboardingExpired = firstCampaignStartDate => {
    const daysSinceFirstCampaign = useMemo(
        () => getDaysSinceFirstCampaign(firstCampaignStartDate),
        [firstCampaignStartDate]
    );
    const hasOnboardingExpired = daysSinceFirstCampaign > ONBOARDING_DURATION_DAYS;

    return hasOnboardingExpired;
};
