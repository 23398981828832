import React from 'react';
import { noop } from 'lodash';
import { useAvailableItems } from 'hooks';
import { BaseGalleryItemDropdown } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/GalleryItemDropdown/BaseGalleryItemDropdown';
import { GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/GalleryItemDropdown/GalleryItemDropdownOptions';
import { useGalleryItemDropdown } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/GalleryItemDropdown/useGalleryItemDropdown';
import { CROPPING_MODAL_COMPONENT_NAME } from '../../constants/croppingModalGtmConstants';

const dropdownMenuStyle = { right: 0, left: 'unset' };

export const OutcroppingGalleryItemDropdown = ({ campaignId, ...rest }) => {
    const { onClick } = useGalleryItemDropdown({
        campaignId,
        eventsLocationPrefix: CROPPING_MODAL_COMPONENT_NAME,
        onSelectMedia: noop,
    });
    const options = useAvailableItems([GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.SAVE_TO_MEDIA_LIBRARY], { isImage: true });

    return (
        <BaseGalleryItemDropdown
            dropdownMenuStyle={dropdownMenuStyle}
            campaignId={campaignId}
            onClick={onClick}
            options={options}
            eventsLocationPrefix={CROPPING_MODAL_COMPONENT_NAME}
            {...rest}
        />
    );
};
