import { configKeyTransformer } from '../services/transformers';

const generateDefaultFlagFromTemplate = configKeyData => {
    const rawKeys = Object.keys(configKeyData);
    const result = {};

    for (let rawKey of rawKeys) {
        const values = configKeyData[rawKey];
        for (let variation of values) {
            const key = configKeyTransformer(rawKey, variation);
            result[key] = variation.defaultValue;
        }
    }

    return result;
};

export default generateDefaultFlagFromTemplate;
