import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLink } from '../../../../../../components';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

const bestPracticesLink = (
    <ExternalLink href="https://help.taboola.com/hc/en-us/articles/115006880507-Title-and-Thumbnail-Best-Practices">
        <FormattedMessage id="creative.creator.thumbnails.description.link" defaultMessage="Best Practices" />
    </ExternalLink>
);

const ThumbnailsFieldDescription = ({ minItems, maxItems }) => (
    <FormattedMessage
        id={minItems ? 'creative.creator.thumbnails.min.max.description' : 'creative.creator.thumbnails.description'}
        defaultMessage="Include at least 2 images or animated GIF or video files (no more than {maxItems}). {bestPracticesLink}."
        values={{ minItems, maxItems, bestPracticesLink }}
    />
);

ThumbnailsFieldDescription.propTypes = {
    maxItems: PropTypes.number,
};

export default ThumbnailsFieldDescription;
