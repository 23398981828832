import React from 'react';
import { nonProgrammaticActiveAccount } from 'modules/account-management/accountType';
import { ControlledLoadingBar } from 'modules/campaigns/components';
import { AccountDropdown } from 'modules/campaigns/modules/common-campaign-form';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useExcelBulkTemplate } from '../../../hooks/useExcelBulkTemplate';
import { CountrySection } from './CountrySection/CountrySection';
import { ExportSection } from './ExportSection/ExportSection';
import { TemplateSection } from './TemplateSection/TemplateSection';
import styles from './bulkDownload.module.scss';

export const BulkDownload = ({ conditionData }) => {
    const {
        downloadTemplate,
        isLoadingTemplate,
        isLoadingDictionaryData,
        entityList,
        totalResources,
        currentResources,
    } = useExcelBulkTemplate({ conditionData });
    const { formAccount, setFormAccount } = useFormDataContext();

    const isDownloadDisabled = isLoadingTemplate || isLoadingDictionaryData;

    return (
        <div>
            <AccountDropdown
                accountForCampaign={formAccount}
                onAccountChange={setFormAccount}
                helpText={
                    <FormattedMessage
                        id="account.select.section.tooltip"
                        defaultMessage="The downloaded excel file will have audience, segment, and rule information relevant to the selected account. Furthermore, selected account's campaign and ads will be exportable."
                    />
                }
                accountFilter={nonProgrammaticActiveAccount}
            />
            <CountrySection />
            <div className={styles['separation-line']} />
            <TemplateSection downloadTemplate={downloadTemplate} isDisabled={isDownloadDisabled} />
            {isLoadingDictionaryData && (
                <ControlledLoadingBar
                    value={currentResources}
                    max={totalResources}
                    containerClassName={styles['loading-bar']}
                    loadingMessageClassName={styles['loading-message']}
                    loadingMessage={`Loading template resources (${currentResources}/${totalResources})`}
                />
            )}
            <div className={styles['separation-line']} />
            <ExportSection
                entityList={entityList}
                downloadTemplate={downloadTemplate}
                isDisabled={isDownloadDisabled}
                isLoadingTemplate={isLoadingTemplate}
            />
        </div>
    );
};
