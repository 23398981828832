import React from 'react';

const VariationsSVGIcon = props => (
    <svg width="81" height="31" viewBox="0 0 81 31" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.182 1H1.818C1.316 1 .91 1.448.91 2v27c0 .552.407 1 .91 1h26.363c.502 0 .909-.448.909-1V2c0-.552-.407-1-.91-1zM1.818 0C.814 0 0 .895 0 2v27c0 1.105.814 2 1.818 2h26.364C29.186 31 30 30.105 30 29V2c0-1.105-.814-2-1.818-2H1.818z"
            fill="#667686"
        />
        <path d="M2 4.053a2 2 0 012-2h22a2 2 0 012 2v15a2 2 0 01-2 2H4a2 2 0 01-2-2v-15z" fill="#EEF0F2" />
        <path
            d="M15.098 21H3.858C2.83 21 2 20.058 2 18.895v-1.241l2.89-3.21c1.196-1.327 3.108-1.327 4.303 0L15.098 21zM28 16.23v2.665C28 20.058 27.169 21 26.143 21h-9.226l-5.346-5.999 6.643-7.454c.628-.862 1.655-.862 2.416 0L28 16.23zM12 6.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM3 24h17v1H3zm0 3h9v1H3z"
            fill="#B8C1CA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.182 1H52.818c-.502 0-.909.448-.909 1v27c0 .552.407 1 .91 1h26.363c.502 0 .909-.448.909-1V2c0-.552-.407-1-.91-1zM52.818 0C51.814 0 51 .895 51 2v27c0 1.105.814 2 1.818 2h26.364C80.186 31 81 30.105 81 29V2c0-1.105-.814-2-1.818-2H52.818z"
            fill="#667686"
        />
        <path d="M53 4.053a2 2 0 012-2h22a2 2 0 012 2v15a2 2 0 01-2 2H55a2 2 0 01-2-2v-15z" fill="#EEF0F2" />
        <path
            d="M66.098 21h-11.24C53.831 21 53 20.058 53 18.895v-1.241l2.89-3.21c1.196-1.327 3.108-1.327 4.303 0L66.099 21zM79 16.23v2.665C79 20.058 78.168 21 77.143 21h-9.226l-5.346-5.999 6.643-7.454c.628-.862 1.655-.862 2.416 0L79 16.23zM63 6.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM54 24h17v1H54zm0 3h9v1h-9z"
            fill="#B8C1CA"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.5 11H36v-1h2.5a2.5 2.5 0 012.5 2.5v4a1.5 1.5 0 001.5 1.5h2.75v1H42.5a2.5 2.5 0 01-2.5-2.5v-4a1.5 1.5 0 00-1.5-1.5z"
            fill="#667686"
        />
        <path
            d="M32 10.5l4-2.165v4.33L32 10.5zm0 8l4-2.165v4.33L32 18.5zm17 0l-4 2.165v-4.33l4 2.165zm0-8l-4 2.165v-4.33l4 2.165zM36 18h2v1h-2v-1z"
            fill="#667686"
        />
        <path d="M43 10h3.25v1H43v-1z" fill="#667686" />
    </svg>
);

export default VariationsSVGIcon;
