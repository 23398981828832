import { useEffect } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { PRICING_MODEL } from '../../../../config';
import { BID_STRATEGIES } from '../../config';

export const useBidModifierLock = ({ resetHandler }) => {
    const {
        data: { bidStrategy, pricingModel },
        isCurrentlyDirty,
    } = useFormDataContext();
    const disabled =
        bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS ||
        bidStrategy === BID_STRATEGIES.TARGET_CPA ||
        pricingModel === PRICING_MODEL.VCPM;
    const shouldResetBoostValue = disabled && (isCurrentlyDirty('bidStrategy') || isCurrentlyDirty('pricingModel'));

    useEffect(() => {
        if (shouldResetBoostValue) {
            resetHandler();
        }
    }, [shouldResetBoostValue, resetHandler]);

    return disabled;
};
