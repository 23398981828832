import React from 'react';
// eslint-disable-next-line no-restricted-syntax
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl-phraseapp';
import { throwCriticalError } from '../../../formData/utils/throwCriticalError';
import { useIntl } from '../../hooks/useIntl';
import { buildFormattedMessageMap } from './buildFormattedMessageMap';

export const FormattedMessage = ({ values, id, children, defaultMessage, tagName, className, ...rest }) => {
    const { getDefaultMessage, formatMessage } = useIntl();
    const mappedValues = buildFormattedMessageMap(values, id);
    const actualDefaultMessage = getDefaultMessage({
        id,
        defaultMessage,
    });

    const props = {
        id,
        defaultMessage: actualDefaultMessage,
        values: mappedValues,
        tagName,
        className,
        ...rest,
    };

    if (!className) {
        return <ReactIntlFormattedMessage {...props}>{children}</ReactIntlFormattedMessage>;
    }

    if (tagName) {
        throwCriticalError(new Error("We don't support TagName prop with className prop"));
        return;
    }

    const message = formatMessage(props, values);

    return <span className={className}>{children ? children(message) : message}</span>;
};

FormattedMessage.defaultProps = {
    values: {},
};
