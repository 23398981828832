import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import styles from './longTextTick.module.scss';

const LongTextTick = ({ x, y, height, payload, totalTicks, visibleTicksCount }) => {
    const widthPercent = 75 / (totalTicks ?? visibleTicksCount);
    const xTranslate = widthPercent / 2;
    const [showTooltip, setShowTooltip] = useState(false);
    const onMouseOver = useCallback(
        e => {
            if (e.target) {
                // show tooltip only if text is cut off by ellipsis
                setShowTooltip(e.target.scrollHeight > e.target.clientHeight);
            }
        },
        [setShowTooltip]
    );
    const onMouseOut = useCallback(
        e => {
            setShowTooltip(false);
        },
        [setShowTooltip]
    );

    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject x={`-${xTranslate}%`} y={-10} dy={0} width={`${widthPercent}%`} height={height}>
                <div className={styles['tick-container']}>
                    <div className={styles['tick-label']} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                        {payload.value}
                    </div>
                    <Tooltip showing={showTooltip} position={TOOLTIP_POSITION.BOTTOM} arrow>
                        {payload.value}
                    </Tooltip>
                </div>
            </foreignObject>
        </g>
    );
};

LongTextTick.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.object,
    totalTicks: PropTypes.number,
};

export default LongTextTick;
