import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FORM_MODES } from '../../../config';
import { MARKETING_OBJECTIVE_KEYS } from '../../common-campaign-form';
import { OS_FAMILY_KEYS } from '../components/AdSetupSection/useAdSetupUrlInputGroupConfig';
import { useOsFamilyCampaign } from '../components/AdSetupSection/useOsFamilyCampaign';
import { useCreativeCampaignData } from './useCreativeCampaignData';

export const useAppInstallFormat = () => {
    const { mode } = useFormDataContext();
    const osFamilyCampaign = useOsFamilyCampaign();
    const [osFamily] = osFamilyCampaign;
    const isAndroidORiOSfamily = osFamily === OS_FAMILY_KEYS.ANDROID || osFamily === OS_FAMILY_KEYS.IOS;
    const isSingleOsFamilySelected = osFamilyCampaign.length === 1;

    const { fetchedCampaign, isSingleCampaignSelected } = useCreativeCampaignData();
    const { marketingObjective } = fetchedCampaign;
    const isAppInstallFormatSelected = marketingObjective === MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL;
    const isCreateMode = mode === FORM_MODES.CREATE;
    const isAppInstall =
        isSingleOsFamilySelected &&
        isSingleCampaignSelected &&
        isAndroidORiOSfamily &&
        isAppInstallFormatSelected &&
        isCreateMode;
    return isAppInstall;
};
