import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedAccount } from '../../../hooks';
import { useUnipFunnelApi } from '../../../services/api/unipFunnelAPI';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useFormDataContext } from '../../taboola-common-frontend-modules/formData';
import { useFormState } from '../../taboola-common-frontend-modules/forms';
import { TRACKING_ROUTE } from '../config';
import { updateFunnel } from '../flows/updateFunnel';
import { funnelTransformerFromGW, funnelTransformerToGW } from '../transformers/funnelsGWTransformer';

export const useUnipFunnelEditorState = () => {
    const dispatch = useDispatch();
    const { funnelId } = useMultiLayerPathParams();
    const { fetchedData: originalFunnel } = useFormDataContext();
    const [{ accountName }] = useSelectedAccount();
    const { getFunnel } = useUnipFunnelApi();

    const formDataFetcher = useCallback(async () => {
        const funnel = await getFunnel(accountName, funnelId);
        return funnelTransformerFromGW(funnel);
    }, [accountName, getFunnel, funnelId]);
    const { updateUnipFunnel } = useUnipFunnelApi();
    const { submit } = useFormState({ formDataFetcher });

    const handleEditFunnelSubmitWrapper = useCallback(() => {
        const updateFunnelWrapper = funnel => {
            const transformFunnel = funnelTransformerToGW(funnel);
            return dispatch(
                updateFunnel({
                    accountId: accountName,
                    originalFunnel,
                    editedFunnel: transformFunnel,
                    updateUnipFunnel,
                })
            );
        };
        return submit(updateFunnelWrapper, TRACKING_ROUTE);
    }, [accountName, originalFunnel, updateUnipFunnel, submit, dispatch]);

    return { submit: handleEditFunnelSubmitWrapper };
};
