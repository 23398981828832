import React, { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { Button, HelpOutlinedIcon } from 'tuui';
import { INDICATION_TYPES, TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { LoadingBar, OutlineEditableText } from 'modules/campaigns/components';
import { AI_GENERATED_IMAGE_SOURCES } from 'modules/campaigns/constants';
import { IMAGE, THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { mergeAITabImagePromptMap } from 'modules/campaigns/modules/creative-editor/actions';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ERROR_CODES } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';
import { fetchAIGeneratedImages } from '../../../../flows';
import { aiTabImagePromptMapSelector } from '../../../../selectors';
import { ImageGallery } from '../../ImageGallery';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import { AIGeneratedTabGalleryItemDropdown } from '../AIGeneratedTabGalleryItemDropdown/AIGeneratedTabGalleryItemDropdown';
import { PromptHelper } from '../PromptHelper/PromptHelper';
import { PromptHelperTagList } from '../PromptHelper/PromptHelperTagList';
import { usePromptHelper } from '../PromptHelper/hooks/usePromptHelper';
import buildImagePromptMap from '../utils/buildImagePromptMap';
import styles from './fromPromptSubTab.module.scss';

const classNameBuilder = classnames.bind(styles);

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);

const GalleryPromptViolationError = (
    <div className={styles['error-message']}>
        <FormattedMessage
            id="creative.editor.tab.aiGenerated.contentViolations.errorMessage"
            defaultMessage="This request may not follow our content policy, please revise the prompt"
        />
    </div>
);
const GalleryGenericError = (
    <div className={styles['error-message']}>
        <FormattedMessage
            id="creative.editor.tab.aiGenerated.generic.errorMessage"
            defaultMessage="We were unable to process the request. Please try again."
        />
    </div>
);

const FromPromptSubTab = ({ onSelectMedia, selectedAccountId, prompt, setPrompt, promptFailedValidationData }) => {
    const dispatch = useDispatch();
    const { campaignId } = useMediaTabsContext();
    const [status, setStatus] = useState(COMPONENT_STATUS.INITIAL);
    const { value: images = [], onChange: setImages } = useFormFieldValue({
        field: 'fromPromptGeneratedImages',
    });
    const [isPromptViolatePolicy, setIsPromptViolatePolicy] = useState(false);
    const galleryStatus = prompt == null || prompt === '' ? COMPONENT_STATUS.ACTIVE : status;
    const imagePromptMap = useSelector(aiTabImagePromptMapSelector);
    const { getPromptHelperParams, clearPromptHelperFormFields } = usePromptHelper();
    const isPromptHelperEnabled = useConfigMatch({
        [FEATURE_FLAGS.AI_PROMPT_HELPER_ENABLED]: 'true',
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'creative.editor.tab.aiGenerated.textarea.placeholder',
        defaultMessage: 'E.g. A businesswoman, age 45 years old, excited, outdoors in the city.',
    });
    const loadingMessage = formatMessage({
        id: 'creative.editor.tab.aiGenerated.loadingMessage',
        defaultMessage: "We're working on generating your images. It will only take a few seconds.",
    });

    const selectMediaHandler = useCallback(
        (data, selected, options) => {
            onSelectMedia(
                () => ({ url: data.url }),
                {
                    id: data.id,
                    mediaUploadSource: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION,
                    fileType: IMAGE,
                    selected,
                },
                options
            );
        },
        [onSelectMedia]
    );

    const onImageGenerationFailure = error => {
        if (error.status === ERROR_CODES.UNPROCESSABLE_ENTITY) {
            setIsPromptViolatePolicy(error);
        }
    };

    const handleClick = async () => {
        const start = Date.now();
        const { results } = await fetchAIGeneratedImages({
            accountId: selectedAccountId,
            campaignId,
            textPrompt: prompt,
            promptHelperParams: getPromptHelperParams(),
            setStatus,
            onFailure: onImageGenerationFailure,
        });

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'AIGeneratedImagesTab - Prompt to Image - Duration',
            value: Math.floor((Date.now() - start) / 1000),
        });

        const imageUrlToPromptMap = buildImagePromptMap({
            results,
            source: AI_GENERATED_IMAGE_SOURCES.PROMPT_TO_IMAGE,
            prompt,
        });
        setImages(results);
        dispatch(mergeAITabImagePromptMap(imageUrlToPromptMap));
    };

    const handleChange = useCallback(
        value => {
            setPrompt(value);
            setIsPromptViolatePolicy(false);
            setStatus(COMPONENT_STATUS.ACTIVE);
        },
        [setPrompt]
    );

    const handleClear = () => {
        setPrompt('');
        setImages([]);
        clearPromptHelperFormFields();
        setStatus(COMPONENT_STATUS.ACTIVE);
    };

    const shouldDisplayGallery = images && images.length > 0;

    const GalleryArea = useMemo(() => {
        if (galleryStatus === COMPONENT_STATUS.LOADING) {
            return <LoadingBar durationInSeconds={15} loadingMessage={loadingMessage} />;
        } else if (galleryStatus === COMPONENT_STATUS.ERROR && isPromptViolatePolicy) {
            return GalleryPromptViolationError;
        } else if (galleryStatus === COMPONENT_STATUS.ERROR) {
            return GalleryGenericError;
        } else if (shouldDisplayGallery) {
            return (
                <div className={styles['image-gallery-container']}>
                    <ImageGallery
                        recommendedImages={images}
                        addImageHandler={selectMediaHandler}
                        hideEmptyGallery
                        status={galleryStatus}
                        columns={3}
                        getItemMetricsAttributes={url => ({
                            'data-metrics-event-name': GTM_EVENTS.USABILITY,
                            'data-metrics-component': 'AIGeneratedImagesTab - Selected as thumbnail',
                            'data-metrics-value': `Prompt: ${imagePromptMap[url]?.prompt}${
                                imagePromptMap[url] ? `, Image: ${url}` : ''
                            }`,
                            'data-metrics-taboola-campaign-id': campaignId,
                        })}
                        GalleryItemDropdown={AIGeneratedTabGalleryItemDropdown}
                        shouldHideOnError
                        gap="0px 12px"
                    />
                </div>
            );
        }
        return null;
    }, [
        campaignId,
        galleryStatus,
        imagePromptMap,
        images,
        isPromptViolatePolicy,
        loadingMessage,
        selectMediaHandler,
        shouldDisplayGallery,
    ]);

    const isSubmitDisabled = !prompt || promptFailedValidationData?.indicationType === INDICATION_TYPES.ERROR;

    return (
        <div>
            <div className={styles['input-container']}>
                <div className={styles['text-area-with-icon']}>
                    <OutlineEditableTextWithIndication
                        id="aiImagesPrompt"
                        className={styles['textarea']}
                        inputClassName={styles['textarea-input']}
                        indicationContainerClass={styles['indication-container']}
                        placeholder={placeholder}
                        value={prompt}
                        onChange={handleChange}
                        children={PromptHelperTagList()}
                        {...promptFailedValidationData}
                    />
                    <div className={styles['help-tooltip']}>
                        <Tooltip position={TOOLTIP_POSITION.LEFT} arrow>
                            <FormattedMessage
                                id="creative.editor.tab.aiGenerated.textarea.tooltip"
                                defaultMessage="More prompt examples:{br}{br}E.g. A young American woman, confident, age 25 years old,
                    sitting in a coffee bar.{br}{br}E.g. A girl, joyful, age 10-15 years old, playing a game on her iPad on the sofa."
                            />
                        </Tooltip>
                        <HelpOutlinedIcon height={20} width={20} />
                    </div>
                </div>
                {isPromptHelperEnabled && <PromptHelper />}
                <div className={styles['button-container']}>
                    <Button
                        size={Button.size.md}
                        variant={Button.variant.text}
                        onClick={handleClear}
                        className={styles['clear-button']}
                    >
                        <FormattedMessage
                            id="creative.editor.tab.aiGenerated.button.clear"
                            defaultMessage="Clear All"
                        />
                    </Button>
                    <Button
                        size={Button.size.md}
                        variant={Button.variant.text}
                        onClick={handleClick}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="AIGeneratedImagesTab - Prompt to Image"
                        data-metrics-value={`Prompt: ${prompt}`}
                        data-metrics-taboola-campaign-id={campaignId}
                        className={classNameBuilder('generate-button', { disabled: isSubmitDisabled })}
                        disabled={isSubmitDisabled}
                    >
                        <FormattedMessage
                            id="creative.editor.tab.aiGenerated.button.generate"
                            defaultMessage="Generate Images"
                        />
                    </Button>
                </div>
            </div>
            {GalleryArea}
        </div>
    );
};

export default FromPromptSubTab;
