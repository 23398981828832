import { createSelector } from 'reselect';
import { appStateSelector } from './baseSelectors';

export const hasUnsavedChangesSelector = createSelector(appStateSelector, appState => appState.hasUnsavedChanges);

export const appStatusSelector = createSelector(appStateSelector, appState => appState.status);

/**
 * @deprecated - should be removed
 */
export const appInitFormLocalStorageSelector = createSelector(
    appStateSelector,
    appState => appState.isLocalStorageInitFinished
);

export const locationSearchSelector = createSelector(appStateSelector, appState => appState.locationSearch);
