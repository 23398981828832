import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreateFormSelectedCampaignId } from '../../../../hooks/useCreateFormSelectedCampaignId';
import { useAiTextSuggestions } from '../../../AiVariationsContextProvider';
import ContentFieldTooltip from '../../../ContentField/ContentFieldTooltip';
import ContentFieldSubDescription from '../../../ContentFieldSubDescription/ContentFieldSubDescription';
import { AiContentTitlesCard, AiContentDescriptionCard, AiContentCTACard } from './Cards';
import styles from '../../../ContentField/contentField.module.scss';
import addedStyles from './aiContentFieldsSection.module.scss';

export const AiContentFieldsSection = ({ className }) => {
    const campaignId = useCreateFormSelectedCampaignId();

    const {
        suggestedTitles,
        suggestedDescriptions,
        loadMoreTitles,
        loadMoreDescriptions,
        titleStatus,
        descriptionStatus,
    } = useAiTextSuggestions({
        campaignId,
    });

    return (
        <FormField
            label={
                <FormattedMessage
                    id="creative.creator.content.description"
                    defaultMessage="Include at least 2 titles to increase your chances for a better performance (no more than 10), 35 - 45 characters is recommended."
                />
            }
            labelClass={styles['content-label']}
            subDescription={<ContentFieldSubDescription />}
            helpText={<ContentFieldTooltip />}
            inputId="creative-creator-content"
            containerClass={className}
        >
            <div className={addedStyles['container']}>
                <AiContentTitlesCard
                    suggestedTitles={suggestedTitles}
                    loadMoreTitles={loadMoreTitles}
                    titleStatus={titleStatus}
                />
                <AiContentDescriptionCard
                    suggestedDescriptions={suggestedDescriptions}
                    loadMoreDescriptions={loadMoreDescriptions}
                    descriptionStatus={descriptionStatus}
                />
                <AiContentCTACard />
            </div>
        </FormField>
    );
};
