import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'taboola-ultimate-ui';
import { OVER_AVERAGE_TYPING_THROTTLE_TIME } from 'hooks/useDelayedState';
import OutlineEditableText from 'modules/campaigns/components/OutlineEditableText';
import { withIndication } from 'modules/errors';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { DeleteButton } from './components/DeleteButton';
import InputLengthIndicator from './components/InputLengthIndicator';
import styles from './contentEditableText.module.scss';

const classNameBuilder = classnames.bind(styles);

const OutlineEditableTextWithIndication = withIndication(OutlineEditableText);

export const ContentEditableText = props => {
    const {
        id,
        field,
        autoFocus,
        className,
        placeholder,
        deleteItem,
        hideDeleteButton,
        validations,
        validationDependencies,
        throttleValue,
        throttleTiming,
        maxLength,
        warningThreshold,
        onChangeCallback,
        disabled,
        disabledPlaceholderId,
        isLoading,
        loadingComponent,
        isLoadingError,
        loadingErrorComponent,
        metricsAttributes,
        customIndicator: CustomIndicator,
        spinnerSize = 14,
        isShowLengthIndicator = true,
        isShowLengthIndicatorAlways = false,
        onIndicationDataChange = noop,
        ...rest
    } = props;

    const {
        value = '',
        onChange,
        indicationData,
        isValidationsRunning,
        scrollRef,
    } = useFormValidatedValue({
        field,
        validations,
        validationDependencies,
        throttleValue,
        throttleTiming,
    });

    useEffect(() => {
        onIndicationDataChange(indicationData);
    }, [indicationData, onIndicationDataChange]);

    const onChangeWithCallback = useCallback(
        val => {
            onChange(val);
            if (onChangeCallback) {
                onChangeCallback(val);
            }
        },
        [onChange, onChangeCallback]
    );

    const { formatMessage } = useIntl();
    const disabledProps = disabled && {
        disabled,
        ...(disabledPlaceholderId && {
            // placeholder prop doesn't work in EditableText because it's a div not an input
            value: value || formatMessage({ id: disabledPlaceholderId }),
        }),
    };

    const handleDeleteItem = useCallback(() => {
        if (deleteItem) {
            deleteItem(id);
        }
    }, [deleteItem, id]);

    return (
        <div name={field} ref={scrollRef}>
            <OutlineEditableTextWithIndication
                id={id}
                value={value}
                onChange={onChangeWithCallback}
                placeholder={placeholder}
                className={classNameBuilder('input', className)}
                inputClassName={classNameBuilder('text', {
                    'hide-value': isLoading || isLoadingError,
                    value: !!value && (isShowLengthIndicatorAlways || isShowLengthIndicator),
                })}
                autoFocus={autoFocus}
                blurOnEnter
                {...disabledProps}
                {...metricsAttributes}
                {...indicationData}
                {...rest}
            >
                {isLoading && loadingComponent}
                {isLoadingError && loadingErrorComponent}
                {isShowLengthIndicator && (
                    <InputLengthIndicator
                        className={classNameBuilder('length-indicator', { dynamic: !isShowLengthIndicatorAlways })}
                        currentLength={value?.length}
                        maxLength={maxLength}
                        warningThreshold={warningThreshold}
                    />
                )}
                {CustomIndicator && <CustomIndicator className={styles['custom-indicator']} />}
                {!hideDeleteButton && <DeleteButton onClick={handleDeleteItem} addDelimiter={true} />}
                {!disabled && isValidationsRunning ? <Spinner size={spinnerSize} /> : null}
            </OutlineEditableTextWithIndication>
        </div>
    );
};

ContentEditableText.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    hideDeleteButton: PropTypes.bool,
    onChangeCallback: PropTypes.func,
    deleteItem: PropTypes.func,
    metricsAttributes: PropTypes.object,
};

ContentEditableText.defaultProps = {
    deleteItem: noop,
    autoFocus: false,
    throttleValue: true,
    throttleTiming: OVER_AVERAGE_TYPING_THROTTLE_TIME,
};
