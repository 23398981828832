import React from 'react';
import classnames from 'classnames/bind';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useValidationState } from 'modules/taboola-common-frontend-modules/validations';
import { useGroupedValidations, ValidationGroup } from '../ValidationGroup';
import styles from './formFooterValidations.module.scss';

const classNameBuilder = classnames.bind(styles);

export function FormFooterValidations() {
    const validationsState = useValidationState();
    const { errors, warnings } = useGroupedValidations(validationsState);
    const issuesCount = errors.length + warnings.length;
    const message = useIssuesText(issuesCount);

    if (!issuesCount) {
        return null;
    }

    return (
        <div className={classNameBuilder('container')}>
            <span>{message}</span>
            <ValidationGroup validationsState={validationsState} />
        </div>
    );
}

function useIssuesText(issuesCount) {
    const { formatMessage } = useIntl();
    const messageId =
        issuesCount === 1 ? 'form.validation.footer.issue.singular' : 'form.validation.footer.issue.plural';
    return formatMessage({ id: messageId, defaultMessage: '{count} Issues' }, { count: issuesCount });
}
