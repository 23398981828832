import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import {
    maxCreativeTitleLength,
    TITLE_WARNING_THRESHOLD,
    creativeTitleValidations,
    useInsignificantChangesValidation,
} from '../../config';
import { ContentEditableText } from '../ContentEditableText/ContentEditableText';

export const ContentTitle = ({
    id,
    placeholder,
    autoFocus,
    validations = creativeTitleValidations,
    validationDependencies,
    field = 'title',
    ...rest
}) => {
    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });
    const finalValidations = useInsignificantChangesValidation({ baseValidations: validations });

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const finalValidationDependencies = useMemo(
        () => ({ isContentValidationEnabled, accountId, ...validationDependencies }),
        [isContentValidationEnabled, accountId, validationDependencies]
    );

    return (
        <ContentEditableText
            id={id}
            field={field}
            autoFocus={autoFocus}
            placeholder={placeholder}
            hideDeleteButton
            validations={finalValidations}
            validationDependencies={finalValidationDependencies}
            maxLength={maxCreativeTitleLength}
            warningThreshold={TITLE_WARNING_THRESHOLD}
            {...rest}
        />
    );
};

ContentTitle.propTypes = {
    id: PropTypes.string,
    status: PropTypes.string,
    existingTitles: PropTypes.array,
    autoFocus: PropTypes.bool,
    value: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    inputRef: PropTypes.func,
};
