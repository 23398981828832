import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { get, identity } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { favoriteMedia } from 'modules/campaigns/flows';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CREATIVE_STUDIO_EVENT_PREFIX } from '../CreativeStudio/creativeStudioEventsPrefix';
import { useCreativeStudioModal } from '../CreativeStudio/useCreativeStudioModal';
import { GALLERY_ITEM_DROPDOWN_OPTIONS } from './GalleryItemDropdownOptions';
import { useMotionStudioModal } from './useMotionStudioModal';

const getErrorIndication = messageId => ({
    message: (
        <FormattedMessage
            id={messageId}
            defaultMessage="Oops, something went wrong. Please contact our support team for further assistance"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
});

export const useGalleryItemDropdown = ({ onSelectMedia, campaignId, eventsLocationPrefix }) => {
    const dispatch = useDispatch();
    const { openModal: openCreativeStudioModal, closeModal: closeCreativeStudioModal } = useCreativeStudioModal();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const onMotionStudioError = useCallback(
        messageId => {
            if (messageId) {
                dispatch(addIndication(getErrorIndication(messageId)));
            }
        },
        [dispatch]
    );
    const { openModal: openMotionStudioModal } = useMotionStudioModal({
        onSelectMedia,
        onUploadErrorMessageChange: onMotionStudioError,
    });

    const onClick = async (
        selectedOption,
        { itemUrl, originalItem, isFavorited, addImageHandler, urlTransformer = identity, isImage }
    ) => {
        const fallbackImageUrl = get(originalItem, 'fallbackImageUrl');
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${CREATIVE_STUDIO_EVENT_PREFIX}_${eventsLocationPrefix}: ${selectedOption} Option Clicked`,
            taboolaCampaignId: campaignId,
            value: `Item URL: ${itemUrl}`,
        });
        if (selectedOption === GALLERY_ITEM_DROPDOWN_OPTIONS.SAVE_TO_MEDIA_LIBRARY) {
            dispatch(favoriteMedia({ accountId, thumbnailUrl: itemUrl, isFavorited }));
        } else if (selectedOption === GALLERY_ITEM_DROPDOWN_OPTIONS.CREATE_MOTION_ADS) {
            openMotionStudioModal(itemUrl);
        } else {
            await openCreativeStudioModal({
                mode: selectedOption,
                src: isImage ? itemUrl : urlTransformer(fallbackImageUrl),
                originalItem,
                accountId,
                campaignId,
                addImageHandler,
                closeCreativeStudioModal,
                urlTransformer,
            });
        }
    };

    return { onClick };
};
