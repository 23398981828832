import { useMemo } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { HOURS_OF_DAY, TARGETING_TYPES } from '../../config';
import { DAY_PART_RULE_FIELD } from '../Dayparting/DaypartingRowWrapper';

const maxAllowedIntervals = 7;
const emptyArray = [];

export const useCampaignSchedulerSupportCheck = ({ isDayPartingPermitted }) => {
    const { value: rules = emptyArray } = useFormFieldValue(
        { field: 'campaignSchedule.rules' },
        { isDayPartingPermitted }
    );
    const { value: isSupported = true } = useFormFieldValue({ field: 'campaignSchedule.isSupported' });
    const rulesAreValid = useMemo(
        () =>
            rules.length <= maxAllowedIntervals &&
            !rules.some(
                rule =>
                    rule[DAY_PART_RULE_FIELD.TYPE] === TARGETING_TYPES.EXCLUDE &&
                    (rule[DAY_PART_RULE_FIELD.START] !== HOURS_OF_DAY.ZERO ||
                        rule[DAY_PART_RULE_FIELD.END] !== HOURS_OF_DAY.TWENTY_FOUR)
            ),
        [rules]
    );

    if (isDayPartingPermitted) {
        return isDayPartingPermitted;
    }

    return rulesAreValid && isSupported;
};
