import React from 'react';
import { isEmpty } from 'lodash';
import { Button, AddIcon, Spinner, AbcSearchIcon } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ItemSuggestionsElement } from './ItemSuggestionsElement';
import styles from './itemSuggestions.module.scss';

export const addSingleItemToArrayIfAbsent = (array, newItem) => {
    if (!array.includes(newItem)) {
        return [...array, newItem];
    }
    return array;
};

export const addAllItemsToArrayIfAbsent = (array, newItems) => {
    const addedItems = [];

    newItems.forEach(newItem => {
        if (!array.includes(newItem)) {
            addedItems.push(newItem);
        }
    });

    return addedItems.length ? [...array, ...addedItems] : array;
};

const noSuggestions = ({ noSuggestionsMessage }) => {
    return (
        <div className={styles['no-suggestions-container']}>
            <div className={styles['no-suggestions-icon-container']}>
                <AbcSearchIcon className={styles['search-icon']} />
            </div>
            <div className={styles['msg']}>{noSuggestionsMessage}</div>
        </div>
    );
};

export const ItemSuggestions = ({
    suggestions,
    onSuggestionClick,
    onAddAllSuggestions,
    isLoading,
    noSuggestionsMessage,
    title,
}) => {
    const hasSuggestions = !isEmpty(suggestions);

    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                <div className={styles['left-container']}>
                    {title}
                    {isLoading && <Spinner size={16} className={styles['spinner']} color="primary" />}
                </div>
                {hasSuggestions && (
                    <div>
                        <Button
                            onClick={onAddAllSuggestions}
                            variant={Button.variant.text}
                            className={styles['add-all-button']}
                            data-metrics-component="addAddSearchKeywordSuggestions"
                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                        >
                            <div className={styles['icon-container']}>
                                <AddIcon className={styles['icon']} />
                                <span>
                                    <FormattedMessage
                                        id="audience.editor.external-audience.suggestions.add-all-button"
                                        defaultMessage="Add All Suggestions"
                                    />
                                </span>
                            </div>
                        </Button>
                    </div>
                )}
            </div>
            {hasSuggestions ? (
                <div className={styles['results-container']}>
                    {suggestions.map(suggestion => (
                        <ItemSuggestionsElement
                            suggestion={suggestion}
                            onSuggestionClick={onSuggestionClick}
                            key={suggestion.label}
                        />
                    ))}
                </div>
            ) : (
                noSuggestions({ noSuggestionsMessage })
            )}
        </div>
    );
};
