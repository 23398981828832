import { requestFetchAccounts, fetchAccountsError, fetchAccountsUnderNetworkSuccess } from '../actions';
import { accountsUnderNetworkSelector, selectedAccountSelector, dimensionSelector } from '../selectors';
import { transformAccountObject } from '../selectors/baseSelectors';
import { accountsApi } from '../services/api';
import { ERROR_CODES } from '../services/api/apiConstants';
import { transformAccountFromGW } from '../services/transformers';

const fetchAccountsUnderNetwork = (search, pageSize, isRetry, onSuccess, onError) => (dispatch, getState) => {
    const state = getState();
    const accountsUnderNetwork = accountsUnderNetworkSelector(state);
    const selectedAccount = selectedAccountSelector(state);
    const dimension = dimensionSelector(state);
    const {
        appReducer: { accountsUnderNetworkTotal },
    } = state;
    const hasMoreAccounts = accountsUnderNetworkTotal !== accountsUnderNetwork.length;

    if (!hasMoreAccounts) {
        onSuccess([], false);
        return;
    }

    const page = Math.ceil(accountsUnderNetwork.length / pageSize);
    const nextPage = page + 1;
    dispatch(requestFetchAccounts());
    accountsApi
        .getAccountsUnderNetwork(search, nextPage, pageSize, selectedAccount.accountId, dimension)
        .then(({ results, metadata }) => {
            const adjustedAccounts = results.map(transformAccountFromGW);

            dispatch(fetchAccountsUnderNetworkSuccess(adjustedAccounts, metadata.total));

            const updatedState = getState();
            const updatedAccounts = accountsUnderNetworkSelector(updatedState);
            const {
                appReducer: { accountsUnderNetworkTotal: updatedAccountsTotal },
            } = updatedState;
            const hasMore = updatedAccounts.length !== updatedAccountsTotal;
            // TODO: should be removed - after DEV-67257.
            //  we transform account object directly here in case of Not retry,
            //  because the data flows directly from api and not after the selector transformation.
            const transformedAccountsToAppend = adjustedAccounts.map(account => ({
                ...transformAccountObject(account),
                isAccountFetched: true,
            }));

            onSuccess(isRetry ? updatedAccounts : transformedAccountsToAppend, hasMore);
        })
        .catch(error => {
            const accountsToSubmit = isRetry ? accountsUnderNetwork : [];
            const totalToSubmit = isRetry ? accountsUnderNetworkTotal : -1;
            if (error.status === ERROR_CODES.NOT_FOUND) {
                dispatch(fetchAccountsUnderNetworkSuccess([], totalToSubmit));
                onSuccess(accountsToSubmit, false);
                return;
            }

            if (error.status === ERROR_CODES.ABORT) {
                dispatch(fetchAccountsUnderNetworkSuccess([], totalToSubmit));
                onSuccess(accountsToSubmit, true);
                return;
            }

            dispatch(fetchAccountsError(error));
            onError(error);
        });
};

export default fetchAccountsUnderNetwork;
