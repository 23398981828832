export const REQUEST_UPDATE_PERFORMANCE_RULE = 'REQUEST_UPDATE_PERFORMANCE_RULE';
export const UPDATE_PERFORMANCE_RULE_SUCCESS = 'UPDATE_PERFORMANCE_RULE_SUCCESS';
export const UPDATE_PERFORMANCE_RULE_ERROR = 'UPDATE_PERFORMANCE_RULE_ERROR';

export const REQUEST_DELETE_PERFORMANCE_RULE = 'REQUEST_DELETE_PERFORMANCE_RULE';
export const DELETE_PERFORMANCE_RULE_SUCCESS = 'DELETE_PERFORMANCE_RULE_SUCCESS';
export const DELETE_PERFORMANCE_RULE_ERROR = 'DELETE_PERFORMANCE_RULE_ERROR';

export const REQUEST_FETCH_PERFORMANCE_RULES_FOR_EDIT = 'REQUEST_FETCH_PERFORMANCE_RULES_FOR_EDIT';
export const FETCH_PERFORMANCE_RULES_FOR_EDIT_SUCCESS = 'FETCH_PERFORMANCE_RULES_FOR_EDIT_SUCCESS';
export const FETCH_PERFORMANCE_RULES_FOR_EDIT_ERROR = 'FETCH_PERFORMANCE_RULES_FOR_EDIT_ERROR';

export const REQUEST_DUPLICATE_PERFORMANCE_RULE = 'REQUEST_DUPLICATE_PERFORMANCE_RULE';
export const DUPLICATE_PERFORMANCE_RULE_SUCCESS = 'DUPLICATE_PERFORMANCE_RULE_SUCCESS';
export const DUPLICATE_PERFORMANCE_RULE_ERROR = 'DUPLICATE_PERFORMANCE_RULE_ERROR';
