import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputTypes, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions, withInputValidations } from 'modules/taboola-common-frontend-modules/validations';
import { useEventValueHandler } from '../../../../../../hooks';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import CurrencyIcon from '../CurrencyIcon/CurrencyIcon';
import RoasGoalTooltip from './RoasGoalTooltip';
import styles from '../commonEditor.module.scss';
import commonStyles from '../commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);

const InputWithValidations = withInputValidations(withIndication(Input), [
    {
        validationFn: validationFunctions.isFloatOrEmpty,
        options: { min: 0 },
        messageId: 'validations.error.campaign.roasGoal.minValue',
        defaultMessage: 'ROAS goal must be a positive number',
    },
]);

const RoasGoal = ({ currency }) => {
    const isPermitted = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'false' });
    const { value: roasGoal, onChange } = useFormFieldValue({
        field: 'roasGoal',
        isPermitted,
    });
    const changeHandler = useEventValueHandler(onChange);

    if (!isPermitted) {
        return null;
    }

    return (
        <FormField
            inputId="roas-goal"
            label={<FormattedMessage id="campaign.editor.roas.goal" defaultMessage="ROAS Goal (optional)" />}
            description={
                <FormattedMessage
                    id="campaign.editor.roas.goal.description"
                    defaultMessage="We recommend setting a Return on Ad Spend (ROAS) goal. This will help you measure how your campaign is performing against your goal."
                />
            }
            helpText={<RoasGoalTooltip />}
            containerClass={styles['input']}
        >
            <FormattedNumber value={0} minimumIntegerDigits={2}>
                {placeholder => (
                    <InputWithValidations
                        id="roasGoal"
                        onChange={changeHandler}
                        icon={<CurrencyIcon currency={currency} />}
                        inputPadding={inputPadding}
                        placeholder={placeholder}
                        value={roasGoal ?? ''}
                        type={InputTypes.NUMBER}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="RoasGoal"
                    />
                )}
            </FormattedNumber>
        </FormField>
    );
};

RoasGoal.propTypes = {
    currency: PropTypes.string,
};

export default RoasGoal;
