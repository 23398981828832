import React, { forwardRef, useState } from 'react';
import ContentLoader from 'react-content-loader';

export const IFrameRenderer = forwardRef((props, ref) => {
    const { src, title, onErrorMessage, ...rest } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const onLoad = () => {
        setIsLoading(false);
    };

    const onError = () => {
        setIsError(true);
    };

    const isShowIFrame = () => {
        return !isLoading && !isError ? 'block' : 'none';
    };

    const isShowLoader = () => {
        return isLoading && !isError;
    };

    return (
        <div>
            {isShowLoader() && <ContentLoader height={165} width={252} speed={2} />}
            <iframe
                src={src}
                title={title}
                ref={ref}
                onLoad={onLoad}
                onError={onError}
                style={{ display: isShowIFrame() }}
                {...rest}
            />
            {isError && onErrorMessage}
        </div>
    );
});
