import { SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';

const CAMPAIGN_SORTABLE_FIELDS = {
    TOTAL_SPENT: 'totalSpent',
    CREATE_DATE: 'createDate',
    NAME: 'name',
};
const defaultSortField = CAMPAIGN_SORTABLE_FIELDS.TOTAL_SPENT;
const defaultSortType = SORTING_ORDER_TYPES.DESCENDANT;

export default CAMPAIGN_SORTABLE_FIELDS;
export { defaultSortField, defaultSortType };
