import { BYPASS_URL_RESPONSE_VALIDATION_FIELD } from '../../creative-creator/components/UrlList/UrlValidatedInput';
import saveCreative from './saveCreative';

export const saveCreativeUrlCellEditor = (newValue, creative) => dispatch => {
    const creativeChange = {
        url: newValue.url,
        [BYPASS_URL_RESPONSE_VALIDATION_FIELD]: creative[BYPASS_URL_RESPONSE_VALIDATION_FIELD],
    };

    dispatch(saveCreative(creativeChange, creative));
};
