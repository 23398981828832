'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

exports.default = withItemsAsChildren;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function withItemsAsChildren(ItemComponent) {
    var propName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'items';

    if (typeof ItemComponent !== 'function') {
        throw new Error('Expected type of ItemComponent to be funciton, got ' + (typeof ItemComponent === 'undefined' ? 'undefined' : _typeof(ItemComponent)));
    }
    return function (WrappedComponent) {
        var _Object$assign;

        var WrapperComponent = function WrapperComponent(props) {
            var additionalChildProps = props.childProps;

            var children = props[propName] ? props[propName].map(function (childProps) {
                return _react2.default.createElement(ItemComponent, Object.assign({}, childProps, additionalChildProps));
            }) : _react.Children.toArray(props.children);
            return _react2.default.createElement(WrappedComponent, Object.assign({}, props, { children: children }));
        };
        WrapperComponent.propTypes = Object.assign({}, WrappedComponent.propTypes || {}, (_Object$assign = {}, _defineProperty(_Object$assign, propName, _propTypes2.default.arrayOf(_propTypes2.default.shape(ItemComponent.propTypes || {}))), _defineProperty(_Object$assign, 'childProps', _propTypes2.default.shape(ItemComponent.propTypes || {})), _Object$assign));
        return WrapperComponent;
    };
}