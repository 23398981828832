import React, { useCallback, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { DASHBOARD_RANGE_PICKER_PRESETS as PRESETS } from 'taboola-ultimate-ui';
import { useComponentStatus, useCurrentValueGetter, useDimension, useNavigate } from 'hooks';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { getMatchedReportId } from 'modules/campaigns/modules/campaigns-reports/components/ReportPicker/getMatchedReportId';
import useAvailableReports from 'modules/campaigns/modules/campaigns-reports/hooks/useAvailableReports';
import { INDICATION_TYPES, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccountFullConfigData } from 'modules/taboola-common-frontend-modules/account-configurations/hooks/useAccountFullConfigData';
import { useLocale } from 'modules/taboola-common-frontend-modules/i18n';
import { mergeQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import { COMPONENT_STATUS } from 'services/constants';
import { useDBStorage } from '../../../taboola-common-frontend-modules/storage/DBStorage/useDBStorage';
import { useCampaignReportStaticIndications } from '../../hooks';
import { useSelectedCampaign } from '../../hooks/useSelectedCampaign';
import { reportDataSelector, reportDataStatusSelector } from '../../selectors';
import { useCampaignGroupExtensionEnabled } from '../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import CampaignsReports from './CampaignsReports';
import { clearRssMetadata, resetReportRowCount, setReportDataStatus } from './actions';
import CampaignGridContextProvider from './components/CampaignGridContextProvider/CampaignGridContextProvider';
import { CustomColumnPickerDrawer } from './components/CustomColumnPicker/CustomColumnPickerDrawer';
import { EmptyStateOverlay } from './components/EmptyStateOverlay/EmptyStateOverlay';
import useConfigFilters from './components/Filters/hooks/useConfigFilters';
import { GridContext } from './components/GridContextProvider/GridContextProvider';
import {
    DEFAULT_REPORT_PAGE,
    REPORT_ID,
    useColumnDefs,
    useDateRange,
    useGraphMetrics,
    useReportPage,
    useSearchTerm,
    useSelectedReportId,
} from './hooks';
import { useColumnState } from './hooks/useColumnState';
import { useComfortableViewAvailable } from './hooks/useComfortableViewAvailable';
import { useGraphEdgesState } from './hooks/useGraphEdgesState';
import { useLastAutoFiltersTrigger } from './hooks/useLastAutoFiltersTrigger';
import { useReportComfortableViewParam } from './hooks/useReportComfortableViewParam';
import { emptyReportFilters, useReportFilters } from './hooks/useReportFilters';
import { useReportFiltersStatus } from './hooks/useReportFiltersStatus';
import { useReportForceFetchFlag } from './hooks/useReportForceFetchFlag';
import { useReportPresetName } from './hooks/useReportPresetName';
import { useReportSorting } from './hooks/useReportSorting';
import { useSelectedGraphConfig } from './hooks/useSelectedGraphConfig';
import { isDynamicColId } from './services';

const defaultDatePreset = PRESETS.SEVEN_DAYS;

const CampaignReportsWrapper = ({
    closeSideDrawer,
    reportConfigMap,
    reportQueryParams,
    datePresets,
    EmptyStateComponent = EmptyStateOverlay,
    triggerGenerator,
    ...rest
}) => {
    const [locale] = useLocale();
    const [externalForceFetchFlag] = useReportForceFetchFlag();
    const [forceFetchFlag, setForceFetchFlag] = useState(0);
    const [isAutoFiltersReady, setAutoFilterReady] = useState(false);
    const [isInitialReport, setIsInitialReport] = useState(true);
    const dispatch = useDispatch();
    const [selectedReport] = useSelectedReportId();
    const reportConfig = reportConfigMap[selectedReport] || {};
    const [reportPresetName] = useReportPresetName();
    const comfortableViewAvailable = useComfortableViewAvailable(reportConfig);
    const [isComfortableViewActive] = useReportComfortableViewParam();
    const isComfortableViewEnabled = comfortableViewAvailable && isComfortableViewActive;
    const reportPresetNameGetter = useCurrentValueGetter(reportPresetName);
    const { columnDefs, footerColumnDefs, allowedSortingColumnDefs } = useColumnDefs(reportConfig, reportPresetName);
    const { columnState, saveColumnStateByPresetAndColumnDefs, saveColumnStateOnGridChange } = useColumnState(
        selectedReport,
        reportPresetName,
        columnDefs
    );
    const { selectedConfig: graphConfig } = useSelectedGraphConfig(reportConfig);
    const [graphEdgesState, setGraphEdgesState] = useGraphEdgesState(selectedReport, reportPresetName);
    const selectedAccount = useAccount();
    const { id: numericAccountId, accountId, isAccountFetched } = selectedAccount;
    const selectedAccountConfig = useAccountFullConfigData()?.selectedAccountConfig;
    const { campaign: selectedCampaign } = useSelectedCampaign();
    const graphMetrics = useGraphMetrics({
        columnDefs,
        columnState,
        selectedReport,
        graphConfig,
        entity: selectedCampaign,
    });
    const campaignId = selectedCampaign.id;
    const [dimension] = useDimension();
    const reportDataStatus = useSelector(reportDataStatusSelector);
    const reportData = useSelector(reportDataSelector);
    const { isReady: isUserSettingsReady } = useDBStorage();

    const [{ startDate, endDate, datePreset }, setDateRange, rangePickerPresetConfigs] = useDateRange(datePresets);

    const [reportPage, setReportPage, unsetReportPage] = useReportPage();
    const [searchTerm] = useSearchTerm();

    const [isFilterActive, setFilterActive] = useReportFiltersStatus();
    const { reportFilters, setReportFilters } = useReportFilters();
    const { initialFilters } = useConfigFilters(reportConfig);
    const activeReportFilters = isFilterActive ? reportFilters : emptyReportFilters;
    const { isModuleReady } = useComponentStatus();
    const { availableReports, isReportVisible } = useAvailableReports();

    const isSelectedReportInvalid = selectedReport && !isEmpty(availableReports) && !isReportVisible(selectedReport);
    const isReady =
        !isNil(reportPage) &&
        selectedReport &&
        isAutoFiltersReady &&
        isModuleReady &&
        !isSelectedReportInvalid &&
        isAccountFetched &&
        isUserSettingsReady;
    const [reportSort, setReportSorting] = useReportSorting(reportConfig);
    const reportSortRef = useRef();
    reportSortRef.current = reportSort;

    const mountRef = useRef();
    const resetReportPaging = useCallback(() => {
        dispatch(resetReportRowCount());
        setReportPage(DEFAULT_REPORT_PAGE);
    }, [dispatch, setReportPage]);

    const navigate = useNavigate();
    const location = useLocation();
    const onSelectReport = useCallback(
        ({ value: reportId }) => {
            if (selectedReport === reportId) {
                return;
            }

            batch(() => {
                setAutoFilterReady(false);
                navigate(`${location.pathname}${mergeQueryParams('', { [REPORT_ID]: reportId })}`);
            });
        },
        [selectedReport, location, navigate]
    );

    useCampaignReportStaticIndications({ selectedReport, startDate, endDate, reportConfig });
    // We clean static indication on new report
    useEffect(() => () => dispatch(removeIndication({ type: INDICATION_TYPES.ERROR })), [selectedReport, dispatch]);

    //On any new pair account&report we invalidate report data. It is intended to cover Back/Forward cases
    useEffect(() => {
        if (reportDataStatus === COMPONENT_STATUS.ACTIVE) {
            dispatch(setReportDataStatus(COMPONENT_STATUS.INITIAL));
        }
        // eslint-disable-next-line
    }, [selectedReport, numericAccountId, campaignId, dispatch]);

    const { shouldSkipAutoFilters, setValue: setAutoFiltersTrigger } = useLastAutoFiltersTrigger();

    // On any new pair account&report we set auto filters
    useEffect(() => {
        if (shouldSkipAutoFilters(selectedReport, accountId)) {
            setAutoFilterReady(true);
            return;
        }

        setAutoFilterReady(false);
        if (!accountId || !isUserSettingsReady) {
            return;
        }
        setAutoFiltersTrigger(selectedReport, accountId);

        if (!isEmpty(reportFilters) || isEmpty(initialFilters)) {
            setAutoFilterReady(true);
            return;
        }

        batch(() => {
            setFilterActive(true);
            setReportFilters(initialFilters);
            setAutoFilterReady(true);
        });
        // We skip all other reportFilters apart those which are selected once other dependencies are ready
        // eslint-disable-next-line
    }, [
        accountId,
        selectedReport,
        initialFilters,
        isUserSettingsReady,
        setFilterActive,
        setReportFilters,
        shouldSkipAutoFilters,
        setAutoFiltersTrigger,
    ]);

    //As soon as we leave account we clean it's dynamic column sorting
    useEffect(
        () => () => {
            const reportSort = reportSortRef.current;
            if (!reportSort) {
                return;
            }

            const reportSortWoDynamic = reportSort.filter(({ colId }) => !isDynamicColId(colId));
            if (reportSort.length === reportSortWoDynamic.length) {
                return;
            }

            setReportSorting(reportSortWoDynamic);
        },
        // eslint-disable-next-line
        [numericAccountId]
    );

    // when the dependencies change, reset the report paging
    useEffect(() => {
        if (!isReady) {
            return;
        }
        if (!mountRef.current) {
            mountRef.current = true;
            return;
        }

        resetReportPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        campaignId,
        numericAccountId,
        isAccountFetched,
        isUserSettingsReady,
        selectedReport,
        activeReportFilters,
        isAutoFiltersReady,
        startDate,
        endDate,
        dimension,
        resetReportPaging,
        searchTerm,
        externalForceFetchFlag,
    ]);

    useEffect(() => () => unsetReportPage(), [unsetReportPage]);

    // trigger report fetch if any of the following values changes
    useEffect(() => {
        if (!isReady) {
            return;
        }
        setForceFetchFlag(triggerGenerator());
    }, [
        triggerGenerator,
        campaignId,
        selectedReport,
        activeReportFilters,
        startDate,
        endDate,
        isReady,
        dimension,
        reportPage,
        dispatch,
        isAutoFiltersReady,
        searchTerm,
        reportSort,
        externalForceFetchFlag,
    ]);

    useEffect(() => {
        dispatch(clearRssMetadata());
    }, [dispatch, selectedReport, campaignId, accountId]);

    // effect for invalid reports
    useEffect(() => {
        if (isSelectedReportInvalid) {
            if (isEmpty(availableReports) && datePreset !== defaultDatePreset) {
                setDateRange({ datePreset: defaultDatePreset });
            }

            onSelectReport({ value: getMatchedReportId({ reportId: selectedReport, dimension, isReportVisible }) });
        }
    }, [
        dimension,
        isReportVisible,
        isSelectedReportInvalid,
        onSelectReport,
        selectedReport,
        availableReports,
        datePreset,
        setDateRange,
    ]);

    // manage whether the current report is the initial load report
    const isInitialReportActive = isInitialReport && reportDataStatus === COMPONENT_STATUS.ACTIVE;
    useEffect(() => {
        if (isInitialReportActive) {
            setIsInitialReport(false);
        }
    }, [isInitialReportActive, setIsInitialReport]);

    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    // if the day report is empty on initial load, redirect to the campaign report
    const isInitialDayReportEmpty = isInitialReportActive && selectedReport === REPORT_TYPE.DAY && isEmpty(reportData);
    useEffect(() => {
        if (isInitialDayReportEmpty) {
            onSelectReport({
                value: campaignGroupExtensionEnabled ? REPORT_TYPE.CAMPAIGNS_GROUPS : REPORT_TYPE.CAMPAIGN,
            });
        }
    }, [isInitialDayReportEmpty, onSelectReport, campaignGroupExtensionEnabled]);
    const requestHashGetter = useCurrentValueGetter(forceFetchFlag);

    return (
        <CampaignGridContextProvider
            reportConfig={reportConfig}
            saveColumnStateOnGridChange={saveColumnStateOnGridChange}
            selectedAccountConfig={selectedAccountConfig}
        >
            <GridContext.Consumer>
                {gridContext => (
                    <>
                        <CampaignsReports
                            forceFetchFlag={forceFetchFlag}
                            requestHashGetter={requestHashGetter}
                            isReportBasisReady={isReady}
                            selectedAccount={selectedAccount}
                            startDate={startDate || rangePickerPresetConfigs?.[defaultDatePreset]?.startDate}
                            endDate={endDate || rangePickerPresetConfigs?.[defaultDatePreset]?.endDate}
                            datePreset={!!startDate ? datePreset : defaultDatePreset}
                            setDateRange={setDateRange}
                            reportPage={reportPage}
                            searchTerm={searchTerm}
                            setReportPage={setReportPage}
                            rangePickerPresetConfigs={rangePickerPresetConfigs}
                            reportConfig={reportConfig}
                            reportConfigMap={reportConfigMap}
                            allowedSortingColumnDefs={allowedSortingColumnDefs}
                            setReportSorting={setReportSorting}
                            reportSort={reportSort}
                            isFilterActive={isFilterActive}
                            reportFilters={activeReportFilters}
                            setGraphEdgesState={setGraphEdgesState}
                            footerColumnDefs={footerColumnDefs}
                            isComfortableViewEnabled={isComfortableViewEnabled}
                            campaignId={campaignId}
                            columnDefs={columnDefs}
                            saveColumnStateByPresetAndColumnDefs={saveColumnStateByPresetAndColumnDefs}
                            saveColumnStateOnGridChange={saveColumnStateOnGridChange}
                            columnState={columnState}
                            graphMetrics={graphMetrics}
                            graphEdgesState={graphEdgesState}
                            reportPresetName={reportPresetName}
                            reportPresetNameGetter={reportPresetNameGetter}
                            selectedCampaign={selectedCampaign}
                            reportQueryParams={reportQueryParams}
                            noRowsOverlayComponentFramework={EmptyStateComponent}
                            locale={locale}
                            graphConfig={graphConfig}
                            gridContext={gridContext}
                            {...rest}
                        />
                        <CustomColumnPickerDrawer closeSideDrawer={closeSideDrawer} reportConfig={reportConfig} />
                    </>
                )}
            </GridContext.Consumer>
        </CampaignGridContextProvider>
    );
};

CampaignReportsWrapper.propTypes = {
    reportConfigMap: PropTypes.object.isRequired,
    triggerGenerator: PropTypes.func,
};

CampaignReportsWrapper.defaultProps = { reportConfigMap: {}, triggerGenerator: Date.now };

export default CampaignReportsWrapper;
