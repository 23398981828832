import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormFieldValue, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import AudienceFormBreadcrumbs from '../components/AudienceFormBreadcrumbs/AudienceFormBreadcrumbs';
import { CombinedSegmentsSizeEstimationWidget } from '../components/CombinedAudiences/SizeEstimationWidget/CombinedSegmentsSizeEstimationWidget';
import { CombinedAudiencesCollapsibleCard } from '../components/CombinedAudiencesCollapsibleCard/CombinedAudiencesCollapsibleCard';
import combinedAudiencesInitialValues from '../config/combinedAudiencesInitialValues';
import { createAudience } from '../flows';
import useAudienceCreatorState from '../hooks/useAudienceCreatorState';
import styleConsts from '../../../globalStyle/styleConsts.module.scss';
import styles from './audiencesCreatorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const CombinedAudiencesAudiencesCreatorPage = ({ onCancel }) => {
    const dispatch = useDispatch();
    const reportId = REPORT_TYPE.COMBINED_AUDIENCES;

    const submitAudience = useCallback(
        (audience, accountId) => dispatch(createAudience(accountId, audience, reportId)),
        [dispatch, reportId]
    );

    useFormFieldValue({ field: 'type' });
    const { formatMessage } = useIntl();

    const title = formatMessage({
        id: 'audience.creator.new.combined.audience',
        defaultMessage: 'New Combined Audience Segment',
    });

    const { step, setStep, submit } = useAudienceCreatorState({
        submitAudience,
    });

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                offset={60}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="audience.creator.audience.settings.step.label"
                            defaultMessage="Audience Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <AudienceFormBreadcrumbs reportId={reportId} title={title} />
                    <div className={classNameBuilder('form-wrapper', 'widget')}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="audience.creator.audience.setup.step.title"
                                    defaultMessage="Audience Setup"
                                />
                            }
                            id="audience-setup"
                        >
                            <CombinedAudiencesCollapsibleCard />
                            <div className={styles['buttons-wrapper']}>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={submit}
                                    onCancel={onCancel}
                                    submitLabel={<FormattedMessage id="app.actionButtons.create.audience" />}
                                    submitIcon={<ChevronRightOutlinedIcon />}
                                    submitDataMetricsComponent="NextButton"
                                    cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                />
                            </div>
                        </WizardStep>
                        <CombinedSegmentsSizeEstimationWidget />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const CombinedAudiencesAudiencesCreatorPageWithFormDataProvider = withFormDataProvider(
    CombinedAudiencesAudiencesCreatorPage,
    {
        defaultValuesConfig: combinedAudiencesInitialValues,
        formContainerId: Wizard.WIZARD_CONTAINER_ID,
        isNetworkOwnerAllowed: true,
    }
);

export { CombinedAudiencesAudiencesCreatorPageWithFormDataProvider as CombinedAudiencesAudiencesCreatorPage };
