import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import {
    CAMPAIGNS_ROUTE,
    CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH,
    CREATIVE_EDITOR_ROUTE_PATH,
    CREATIVE_ID_PARAM_NAME,
    ENTITY,
} from '../config';
import { HIERARCHY_REP_ITEM_ID_PARAM_NAME } from '../config/routes/creativeCarousel';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { CreativeCarouselEditor } from '../modules/creative-carousel-editor/CreativeCarouselEditor';
import { replacePathParams } from '../services/utils';

export const carouselEditPath = replacePathParams(CREATIVE_EDITOR_ROUTE_PATH, {
    entity: [ENTITY.CREATIVE_CAROUSEL],
}).replace(CREATIVE_ID_PARAM_NAME, HIERARCHY_REP_ITEM_ID_PARAM_NAME);

export const CreativeCarouselEditorPage = ({ onCancel }) => {
    useUnsavedChangesController(CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                path={carouselEditPath}
                render={props => <CreativeCarouselEditor onCancel={onCancel} {...props} />}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CreativeCarouselEditorPage.propTypes = {
    onCancel: PropTypes.func,
};
