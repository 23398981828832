import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    creativeDescriptionValidations,
    useInsignificantChangesValidation,
} from 'modules/campaigns/config/validations/creative';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import {
    MAX_DESCRIPTION_LENGTH,
    DESCRIPTION_WARNING_THRESHOLD,
} from '../../modules/creative-creator/components/ContentField/config';
import { ContentEditableText } from '../ContentEditableText/ContentEditableText';

export const DESCRIPTION_FIELD_ID = 'description';

export const ContentDescription = ({
    id,
    placeholder,
    autoFocus,
    validations = creativeDescriptionValidations,
    validationDependencies,
    ...rest
}) => {
    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });

    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const finalValidations = useInsignificantChangesValidation({ baseValidations: validations });

    const finalValidationDependencies = useMemo(
        () => ({ isContentValidationEnabled, accountId, ...validationDependencies }),
        [isContentValidationEnabled, accountId, validationDependencies]
    );

    return (
        <ContentEditableText
            id={id}
            field={DESCRIPTION_FIELD_ID}
            autoFocus={autoFocus}
            placeholder={placeholder}
            hideDeleteButton
            validations={finalValidations}
            validationDependencies={finalValidationDependencies}
            maxLength={MAX_DESCRIPTION_LENGTH}
            warningThreshold={DESCRIPTION_WARNING_THRESHOLD}
            {...rest}
        />
    );
};

ContentDescription.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.node,
    autoFocus: PropTypes.bool,
};
