import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const BusinessModelFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.rate.tooltip"
            defaultMessage="Your Bid Amount indicates how much you will pay per billing event.
                            Please follow the Video Rate Card in order to decide which rate to use.
                            for campaign uses CPM/External CPM business model, the billing event is Video Impression,
                            for campaign uses CPCV business model, the billing event is Completed View,
                            for campaign uses vCPM business model, the billing event is Viewable Impression."
        />
    </TooltipSection>
);

export default BusinessModelFieldTooltip;
