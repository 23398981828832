import { stubFalse } from 'lodash';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { isProgrammaticAccount } from 'modules/account-management/accountType';
import { getAudienceStatusType } from 'modules/audiences/config';
import { getCampaignsGroupStatusType, getCampaignStatusType, isCampaignStatusRejected } from 'modules/campaigns/config';
import {
    BidCellEditor,
    BidModificationCellEditor,
    BidStrategyCellEditor,
    BudgetCellEditor,
    ContentFieldCellEditor,
    CpaGoalCellEditorWithFormData,
    CTADropdownCellEditor,
    DateCellEditor,
    EditableTextCellEditor,
    CpcCapCellEditor,
    SpendingLimitCellEditor,
    UrlCellEditor,
} from 'modules/campaigns/modules/campaigns-reports/components/CellEditors';
import { BidStrategyCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellEditors/BidStrategy/BidStrategyCellRenderer';
import { SimpleCurrencyCellEditorWithProvider } from 'modules/campaigns/modules/campaigns-reports/components/CellEditors/SimpleCurrencyCellEditor';
import { SiteStatusCellEditor } from 'modules/campaigns/modules/campaigns-reports/components/CellEditors/SiteStatusCellEditor';
import {
    AudienceCampaignsUsingCellRenderer,
    AudienceNameCellRenderer,
    AudiencePixelBasedDetailsCellRenderer,
    BidCellRenderer,
    BidModificationCellRenderer,
    BoolValueCellRenderer,
    BudgetCellRenderer,
    CampaignHistoryCampaignNameCellRenderer,
    CampaignsGroupStatusRenderer,
    CpaGoalCellRenderer,
    CvrVsAvgCellRenderer,
    DmaCellRenderer,
    EllipsisCellRenderer,
    EnumValueCellRenderer,
    FormattedNumberCellRenderer,
    LastReceivedCellRenderer,
    LinkCellRenderer,
    PaymentMethodCellRenderer,
    PaymentStatusCellRenderer,
    PerformanceRulesAppliesToCampaignsCellRenderer,
    PredictiveAudienceDetailsCellRenderer,
    ProgressBarCellRenderer,
    RegionCellRenderer,
    SpendingLimitCellRenderer,
    StatusCellRenderer,
} from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers';
import { CampaignHistoryValueCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/CampaignHistoryValueCellRenderer';
import { ConversionPixelBasedDetailsCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/ConversionPixelBasedDetailsCellRenderer';
import { EventsReceivedCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/EventsReceivedCellRenderer';
import { InvoiceStatusCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/InvoiceStatusCellRenderer';
import { PerformanceRuleExecutedActionAccountNameCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/PerformanceRuleExecutedActionAccountNameCellRenderer';
import { PerformanceRuleExecutedActionActivityDetailsCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/PerformanceRuleExecutedActionActivityDetailsCellRenderer';
import { PerformanceRuleExecutedActionActivityTypeCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/PerformanceRuleExecutedActionActivityTypeCellRenderer';
import { PerformanceRuleExecutedActionCampaignNameCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/PerformanceRuleExecutedActionCampaignNameCellRenderer';
import { PerformanceRuleExecutedActionRuleNameCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/PerformanceRuleExecutedActionRuleNameCellRenderer';
import RateVsAvgCellRenderer from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/RateVsAvgCellRenderer';
import { SegmentNameCellRenderer } from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/SegmentNameCellRenderer';
import {
    DateHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
} from 'modules/campaigns/modules/campaigns-reports/components/HeaderRenderers';
import {
    bidModificationValueGetter,
    bidStrategyValueGetter,
    csvEnumFormatter,
    csvStatusFormatter,
    dmaValueGetter,
    enumValueGetter,
    formattedCampaignHistoryActivityDetailsGetter,
    formattedCampaignHistoryActivityGetter,
    formattedCampaignHistoryChangeTimeGetter,
    formattedCampaignHistoryValueGetter,
    formattedDateValueGetter,
    gridCampaignGoalValueFormatter,
    gridCampaignHistoryActivityDetailsGetter,
    gridCampaignHistoryActivityGetter,
    gridCampaignHistoryChangeTimeGetter,
    gridCampaignHistoryValueFormatter,
    gridDateFormatter,
    gridDateTimeFormatter,
    gridEnumFormatter,
    gridNumberFormatter,
    gridPaymentMethodValueFormatter,
    regionValueGetter,
} from 'modules/campaigns/modules/campaigns-reports/components/ValueFormatters';
import { csvConversionConditionsValueFormatter } from 'modules/campaigns/modules/campaigns-reports/components/ValueFormatters/conversionConditionsValueFormatter';
import { dcoInTrialVisibilityCondition } from 'modules/campaigns/modules/campaigns-reports/config/predicates/dcoPredicates';
import modifyExternalUrl from 'modules/campaigns/services/utils/modifyExternalUrl';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PERMISSIONS_OPERATORS } from 'modules/taboola-common-frontend-modules/authentication';
import { getConversionStatusType } from 'modules/tracking/config';
import { AudienceMatchRateCellRenderer } from '../../components/CellRenderers/AudienceMatchRateCellRenderer';
import EMPTY_FIELD_VALUE from '../defaultValues';
import { getBidModificationValues, getNewBidModification, getSpentTotalValue } from './cellRenderHelpers';

export const defaultCurrencyRenderParams = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    style: 'currency',
    currency: 'usd',
};

// used by HeaderRenderers in headerValueGetter of columnDefinitions
export const defaultHeaderComponentParams = {
    tooltipHeaderMsgIdPrefix: 'app.campaigns.reports.grid.column.header.tooltip.header',
    tooltipContentMsgIdPrefix: 'app.campaigns.reports.grid.column.header.tooltip.content',
    messageIdPrefix: 'app.campaigns.reports.grid.column.header.message',
    toStringMessageIdPrefix: 'app.campaigns.reports.grid.column.header.message',
};

export const nonDefaultHeaderComponentParams = suffix => {
    return {
        tooltipHeaderMsgIdPrefix: `app.campaigns.reports.grid.column.header.tooltip.header.${suffix}`,
        tooltipContentMsgIdPrefix: `app.campaigns.reports.grid.column.header.tooltip.content.${suffix}`,
        messageIdPrefix: `app.campaigns.reports.grid.column.header.message.${suffix}`,
        toStringMessageIdPrefix: `app.campaigns.reports.grid.column.header.message.${suffix}`,
    };
};

export const defaultPercentRenderParams = {
    style: 'percent',
    maximumFractionDigits: 2,
};

// setup
export const ID = {
    field: 'id',
    label: 'ID',
};

export const NAME = {
    field: 'name',
    label: 'Name',
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: nonDefaultHeaderComponentParams('campaign'),
    cellEditorFramework: EditableTextCellEditor,
};

export const DATE = {
    field: 'dataDate',
    label: 'Date',
    valueFormatter: gridDateFormatter,
    csvValueGetter: formattedDateValueGetter,
    headerValueGetter: DateHeaderRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const TIME = {
    field: 'dataDate',
    label: 'Hour',
    valueFormatter: gridDateFormatter,
    csvValueGetter: formattedDateValueGetter,
    headerValueGetter: DateHeaderRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const IS_SELECTED = {
    field: 'isRowSelected',
    label: '',
    valueGetter: 'data',
    permissions: {
        permissions: ['CAMPAIGNS_BULK_UPDATE', 'CAMPAIGNS_BULK_UPDATE_NON_ATOMIC'],
        operator: PERMISSIONS_OPERATORS.AND,
    },
    accountConfigurations: {
        [FEATURE_FLAGS.CAMPAIGNS_BULK_UPDATE_API_ENABLED_KEY]: 'true',
    },
};

export const IS_ACTIVE = {
    field: 'isActive',
    label: '',
    renderParams: { hideEditIndication: true },
};

export const IS_DEFAULT = {
    field: 'isDefault',
    renderParams: { hideEditIndication: true },
    label: 'Default',
};

export const STATUS = {
    field: 'status',
    label: 'Delivery Status',
    renderParams: {
        getStatusType: getCampaignStatusType,
        isStatusRejected: isCampaignStatusRejected,
        msgIdPrefix: 'app.campaigns.campaign.status.reject.reason',
    },
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: StatusCellRenderer,
    csvValueGetter: csvStatusFormatter,
};

export const ACCOUNT_ID = {
    ...ID,
    field: 'accountId',
    label: 'Account ID',
};

export const ACCOUNT_DESCRIPTION = {
    ...NAME,
    field: 'accountDescription',
    label: 'Account',
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: { lines: 1 },
};

export const CAMPAIGN_NAME = {
    ...NAME,
    headerComponentParams: defaultHeaderComponentParams,
    field: 'campaignName',
    label: 'Campaign Name',
};

export const CAMPAIGN_HISTORY_CAMPAIGN_NAME = {
    ...CAMPAIGN_NAME,
    cellRendererFramework: CampaignHistoryCampaignNameCellRenderer,
};

export const VIDEO_MODEL_TYPE = {
    field: 'videoModelType',
    label: 'Model',
    headerComponentParams: defaultHeaderComponentParams,
};

export const BID_STRATEGY = {
    field: 'bidStrategy',
    label: 'Bid Strategy',
    cellRenderer: BidStrategyCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: defaultHeaderComponentParams,
};

export const SAFETY_LEVEL = {
    field: 'safetyLevel',
    label: 'Safety Rating',
    renderParams: { msgIdPrefix: 'app.campaigns.campaign.safety.level' },
    cellRenderer: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const BID_STRATEGY_PER_AFFILIATE = {
    field: 'bidStrategyPerAffiliate',
    label: 'Bid Strategy',
    renderParams: {
        msgIdPrefix: 'app.campaigns.campaign.bid.strategy',
    },
    cellRenderer: EnumValueCellRenderer,
    csvValueGetter: bidStrategyValueGetter,
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: BidStrategyCellEditor,
};

export const BID = {
    field: 'bid',
    label: 'Bid',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 3,
    },
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: BidCellRenderer,
    cellEditorFramework: BidCellEditor,
};

export const BID_MODIFICATION = {
    field: 'bidModification',
    label: 'Bid',
    renderParams: {
        currencyRenderParams: {
            ...defaultCurrencyRenderParams,
            minimumFractionDigits: 3,
        },
        bidDiff: 0.1,
        getBidModificationValues,
        getNewBidModification,
    },
    cellRenderer: BidModificationCellRenderer,
    csvValueGetter: bidModificationValueGetter,
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: BidModificationCellEditor,
};

export const BUDGET = {
    field: 'budget',
    label: 'Budget',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 2,
    },
    cellRenderer: BudgetCellRenderer,
    cellEditorFramework: BudgetCellEditor,
    headerComponentParams: defaultHeaderComponentParams,
    csv: [
        {
            headerName: 'Budget',
            field: 'budget.value',
        },
        {
            headerName: 'Budget Type',
            field: 'budget.type',
            csvValueGetterParams: {
                msgIdPrefix: 'app.campaigns.campaign.budget.type',
            },
            csvValueGetter: enumValueGetter,
        },
    ],
};

export const VIDEO_BUDGET = {
    field: 'videoBudget',
    label: 'Budget',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    },
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: BudgetCellRenderer,
    cellEditorFramework: SimpleCurrencyCellEditorWithProvider,
    csv: [
        {
            headerName: 'Budget',
            field: 'videoBudget.budget',
        },
    ],
};

export const SPENDING_LIMIT = {
    field: 'spendingLimit',
    label: 'Spending Limit',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 0,
    },
    cellRenderer: SpendingLimitCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: SpendingLimitCellEditor,
    csv: [
        {
            headerName: 'Spending Limit',
            field: 'budget.spendingLimit.value',
        },
        {
            headerName: 'Spending Limit Type',
            field: 'budget.spendingLimit.type',
            csvValueGetterParams: {
                msgIdPrefix: 'app.campaigns.campaign.budget.type',
            },
            csvValueGetter: enumValueGetter,
        },
    ],
};

export const START_DATE = {
    ...DATE,
    field: 'startDate',
    label: 'Start Date',
    headerComponentParams: defaultHeaderComponentParams,
};

export const END_DATE = {
    ...DATE,
    field: 'endDate',
    label: 'End Date',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: DateCellEditor,
};

export const CHANGE_TIME = {
    field: 'changeTime',
    label: 'Date & Time',
    valueGetter: gridCampaignHistoryChangeTimeGetter,
    csvValueGetter: formattedCampaignHistoryChangeTimeGetter,
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const ACCOUNT_MANAGER = {
    field: 'accountManager',
    label: 'Account Manager',
};

export const PERFORMER = {
    field: 'performer',
    label: 'User',
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const ACCOUNT_MANAGER_GROUP = {
    field: 'departmentName',
    label: 'Account Manager Group',
};

export const SALES_PERSON = {
    field: 'salesRep',
    label: 'Sales Person',
};

export const SALES_GROUP = {
    field: 'departmentName',
    label: 'Sales Group',
};

export const CURRENCY = {
    field: 'currency',
    label: 'Currency',
};

export const URL = {
    field: 'url',
    label: 'URL',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: UrlCellEditor,
};

export const DESCRIPTION = {
    field: 'description',
    label: 'Description',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: ContentFieldCellEditor,
};

export const CONTENT = {
    field: 'content',
    label: 'Headline',
    headerComponentParams: defaultHeaderComponentParams,
};

export const TITLE = {
    field: 'title',
    label: 'Title',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: ContentFieldCellEditor,
};

export const CREATIVE_NAME = {
    field: 'name',
    label: 'Creative Name',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: EditableTextCellEditor,
};

export const CUSTOM_CREATIVE_NAME = {
    ...CREATIVE_NAME,
    field: 'creativeName',
    label: 'Ad Name',
    valueFormatter: ({ value }) => value || EMPTY_FIELD_VALUE,
};

export const CUSTOM_ID = {
    field: 'customId',
    label: 'Custom ID',
    headerComponentParams: defaultHeaderComponentParams,
    cellEditorFramework: EditableTextCellEditor,
    valueFormatter: ({ value }) => value || EMPTY_FIELD_VALUE,
};

export const THUMBNAIL_URL = {
    field: 'thumbnailUrl',
    label: 'Media',
    headerComponentParams: defaultHeaderComponentParams,
    csv: [
        {
            headerName: 'Media Type',
            field: 'performanceStatus',
            csvValueGetterParams: {
                msgIdPrefix: 'app.campaigns.creative.performanceStatus',
            },
            csvValueGetter: csvEnumFormatter,
        },
        {
            headerName: 'Image URL',
            field: 'thumbnailUrl',
        },
        {
            headerName: 'Video URL',
            field: 'videoUrl',
            csvValueGetter: modifyExternalUrl,
        },
    ],
};

export const THUMBNAIL_URL_COMFORTABLE = {
    field: 'thumbnailUrlComfortable',
    label: 'Media',
    headerComponentParams: defaultHeaderComponentParams,
    csv: THUMBNAIL_URL.csv,
};

export const CREATE_TIME = {
    field: 'createTime',
    label: 'Creation Date',
    headerComponentParams: defaultHeaderComponentParams,
    valueFormatter: gridDateFormatter,
    csvValueGetter: formattedDateValueGetter,
};

// performance
export const IMPRESSIONS = {
    field: 'impressions',
    label: 'Impressions',
    headerComponentParams: defaultHeaderComponentParams,
};

export const VISIBLE_IMPRESSIONS = {
    ...IMPRESSIONS,
    field: 'visibleImpressions',
    label: 'Viewable Impressions',
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const COMPLETED_VIEWS = {
    field: 'completedViews',
    label: 'Completed Views',
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const FIRST_QUARTILE = {
    field: 'firstQuartile',
    label: 'First Quartile',
    headerComponentParams: defaultHeaderComponentParams,
};

export const MID_POINT = {
    field: 'midPoint',
    label: 'Mid Point',
    headerComponentParams: defaultHeaderComponentParams,
};

export const THIRD_QUARTILE = {
    field: 'thirdQuartile',
    label: 'Third Quartile',
    headerComponentParams: defaultHeaderComponentParams,
};

export const COMPLETED_VIDEO = {
    field: 'complete',
    label: 'Completed Video',
    headerComponentParams: defaultHeaderComponentParams,
};

export const THIRTY_SECONDS_MARK = {
    field: 'thirtySecondsMark',
    label: '30 Seconds Mark',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CATEGORY = {
    field: 'category',
    label: 'Category',
    headerComponentParams: {
        ...defaultHeaderComponentParams,
        tooltipOptions: {
            position: TOOLTIP_POSITION.BOTTOM_START,
        },
    },
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    renderParams: { msgIdPrefix: 'app.campaigns.category' },
    cellRendererFramework: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
};

export const AUDIENCE_INDEX = {
    field: 'segmentTrafficPercentage',
    label: 'Audience Index',
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const BASELINE_INDEX = {
    field: 'baselineTrafficPercentage',
    label: 'Baseline Index',
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const COMPARE = {
    field: 'relativeTrafficPercentage',
    label: 'Compare',
    renderParams: {
        ...defaultPercentRenderParams,
        forceSign: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    cellRendererFramework: FormattedNumberCellRenderer,
};

export const CLICKS = {
    field: 'clicks',
    label: 'Clicks',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CTR = {
    field: 'ctr',
    label: 'CTR',
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
};

export const VCTR = {
    ...CTR,
    field: 'vctr',
    label: 'vCTR',
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const AVG_BOOST = {
    field: 'avgBoost',
    label: 'Bid Modification',
    renderParams: {
        ...defaultPercentRenderParams,
        maximumFractionDigits: 0,
    },
    headerComponentParams: defaultHeaderComponentParams,
};

export const CPM = {
    field: 'cpm',
    label: 'CPM',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
    },
    headerComponentParams: defaultHeaderComponentParams,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

export const VCPM = {
    ...CPM,
    field: 'vcpm',
    label: 'vCPM',
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

export const CPCV = {
    field: 'cpcv',
    label: 'CPCV',
    renderParams: {
        maximumFractionDigits: 3,
    },
    headerComponentParams: defaultHeaderComponentParams,
};

export const CPA_GOAL = {
    field: 'cpaGoal',
    label: 'Target',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
        showEmptyWhenZero: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
    accountConfigurations: {
        [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true',
    },
    cellRenderer: CpaGoalCellRenderer,
    cellEditorFramework: CpaGoalCellEditorWithFormData,
};

export const CPC_CAP = {
    field: 'cpcCap',
    label: 'CPC Cap',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
        showEmptyWhenZero: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
    accountConfigurations: {
        [FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER]: 'true',
    },
    cellRenderer: FormattedNumberCellRenderer,
    cellEditorFramework: CpcCapCellEditor,
};

export const CPA = {
    field: 'cpa',
    label: 'Actual CPA',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 3,
        showEmptyWhenZero: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

export const CPA_CLICKS = {
    ...CPA,
    field: 'cpaClicks',
    label: 'CPA (Clicks)',
    referenceColId: CPA.field,
};
export const CPA_VIEWS = {
    ...CPA,
    field: 'cpaViews',
    label: 'CPA (Views)',
    referenceColId: CPA.field,
};

export const TARGET_CPA = {
    field: 'targetCpa',
    label: 'Target CPA',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        showEmptyWhenZero: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
};

export const CVR = {
    field: 'cvr',
    label: 'Conversion Rate',
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
};

export const VIDEO_CVR = {
    ...CVR,
    headerComponentParams: nonDefaultHeaderComponentParams('videoCvr'),
};

export const CVR_CLICKS = {
    ...CVR,
    field: 'cvrClicks',
    label: 'Conversion Rate (Clicks)',
    referenceColId: CVR.field,
};
export const CVR_VIEWS = {
    ...CVR,
    field: 'cvrViews',
    label: 'Conversion Rate (Views)',
    referenceColId: CVR.field,
};

export const VIEWABILITY_RATE = {
    ...CVR,
    field: 'viewabilityRate',
    label: 'Viewability Rate',
};

export const COMPLETION_RATE = {
    ...CVR,
    field: 'completionRate',
    label: 'Completion Rate',
};

export const HUNDRED_PCT_VIEWABILITY_RATE = {
    ...CVR,
    field: 'hundredPctViewabilityRate',
    label: '100% In view rate',
};

export const SPENT = {
    field: 'spent',
    label: 'Spent',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
        getTotalValue: getSpentTotalValue,
        totalMinimumFractionDigits: 0,
        totalMaximumFractionDigits: 0,
    },
    cellRenderer: ProgressBarCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

export const CAMPAIGN_GROUP_SPENT = {
    ...SPENT,
    cellRenderer: FormattedNumberCellRenderer,
};

export const PROFIT_MARGIN = {
    field: 'profitMargin',
    label: 'Profit Margin',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
    },
    cellRenderer: FormattedNumberCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    visibilityCondition: ({ accountConfig }) =>
        accountConfig?.[FEATURE_FLAGS.PROFIT_MARGIN_IN_REPORT_ENABLED] === 'true',
};

export const ESTIMATED_BILLABLE_AMOUNT = {
    field: 'estimatedBillableAmount',
    label: 'Estimated Billable Amount',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
    },
    cellRenderer: FormattedNumberCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    visibilityCondition: ({ accountConfig }) =>
        accountConfig?.[FEATURE_FLAGS.PROFIT_MARGIN_IN_REPORT_ENABLED] === 'true',
};

export const CONVERSIONS = {
    field: 'actionsConversions',
    label: 'Conversions',
    headerComponentParams: defaultHeaderComponentParams,
};

export const VIDEO_DURATION = {
    field: 'videoDuration',
    label: 'Video Duration',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CONVERSIONS_CLICKS = {
    field: 'actionsConversionsClicks',
    label: 'Conversions (Clicks)',
    referenceColId: CONVERSIONS.field,
    headerComponentParams: defaultHeaderComponentParams,
};
export const CONVERSIONS_VIEWS = {
    field: 'actionsConversionsViews',
    label: 'Conversions (Views)',
    referenceColId: CONVERSIONS.field,
    headerComponentParams: defaultHeaderComponentParams,
};

// TODO: should be consolidated with CONVERSIONS, column name change should be converted in server
export const CONVERSIONS_IN_GRAPH = {
    field: 'cpaActionsNum',
    label: 'Conversions',
};

export const AVERAGE_CPC = {
    ...BID,
    field: 'avgCpc',
    label: 'Actual CPC',
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: FormattedNumberCellRenderer,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

// misc
// TODO: this is used only for filter. the transformation of filters should be done in the server side, not client
export const SITE_DESCRIPTION = {
    field: 'siteDescription',
};

export const SITE_NAME = {
    field: 'siteUrl',
    label: 'Site',
    renderParams: {
        textColumnName: SITE_DESCRIPTION.field,
        isSearchable: true,
    },
    cellRenderer: LinkCellRenderer,
    csv: [
        {
            headerName: 'Site',
            field: SITE_DESCRIPTION.field,
        },
    ],
};

export const SITE_NAME_ID = {
    field: 'siteName',
    label: 'Site Name',
};

export const SITE_NAME_REALTIME = {
    field: 'siteName',
    label: 'Site',
    renderParams: {
        textColumnName: SITE_DESCRIPTION.field,
        isSearchable: true,
    },
    cellRenderer: EllipsisCellRenderer,
    csv: [
        {
            headerName: 'Site',
            field: SITE_DESCRIPTION.field,
        },
    ],
};

export const SITE_ID = {
    ...ID,
    field: 'siteId',
};

export const PUBLISHER_BLOCKING = {
    field: 'isPublisherBlocked',
    label: '',
    renderParams: {
        toggleValueColumnName: 'isPublisherBlocked',
    },
    csvValueGetterParams: {
        msgIdPrefix: 'app.campaigns.reports.csv.export.bySite.isPublisherBlocked',
    },
    csvValueGetter: enumValueGetter,
};

export const PUBLISHER_BLOCKING_LEVEL = {
    field: 'publisherBlockingLevel',
    label: 'Status',
    renderParams: {
        toggleValueColumnName: 'isPublisherBlocked',
    },
    cellEditorFramework: SiteStatusCellEditor,
};

export const ACTIONS_COLUMN = {
    field: 'actionsColumn',
    label: '',
};

export const PLATFORM_CODE = {
    field: 'platformCode',
    label: 'Platform',
    renderParams: { msgIdPrefix: 'app.platform.code' },
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererFramework: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
};

export const BROWSER = {
    field: 'browser',
    label: 'Browser',
    renderParams: { msgIdPrefix: 'campaign.editor.targeting.browser.options' },
    cellRendererFramework: EnumValueCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    csvValueGetter: enumValueGetter,
};

export const COMPOUND_BROWSER_CODE = {
    field: 'compoundBrowserCode',
};

export const OS_VERSION = {
    field: 'osVersion',
    label: 'Operating System Version',
    headerComponentParams: defaultHeaderComponentParams,
};

export const COMPOUND_OS_VERSION_CODE = {
    field: 'compoundOSVersionCode',
};

export const OS_FAMILY = {
    field: 'osFamily',
    label: 'Operating System',
    headerComponentParams: defaultHeaderComponentParams,
};

export const COMPOUND_OS_FAMILY_CODE = {
    field: 'compoundOSFamilyCode',
};

export const COUNTRY_CODE = {
    field: 'countryCode',
    label: 'Country',
    renderParams: {
        msgIdPrefix: 'app.country.code',
        isSearchable: true,
    },
    cellRendererFramework: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
};

export const CITY = {
    field: 'city',
    label: 'City',
};

export const QUARTILE = {
    field: 'quartile',
    label: 'Quartile',
};

export const QUARTILE_IMPRESSIONS_RATE = {
    ...CVR,
    field: 'quartileImpressionsRate',
    label: 'Quartile Impressions Rate',
};

export const REGION_CODE = {
    field: 'regionCode',
    label: 'Region',
    renderParams: {
        msgIdPrefix: 'app.country.code',
        isSearchable: true,
    },
    cellRendererFramework: RegionCellRenderer,
    csvValueGetter: regionValueGetter,
};

export const COMPOUND_REGION_CODE = {
    field: 'compoundRegionCode',
};

export const DMA_CODE = {
    field: 'dmaCode',
    label: 'DMA',
    renderParams: {
        msgIdPrefix: 'app.country.code.US.dma.code',
        isSearchable: true,
    },
    cellRendererFramework: DmaCellRenderer,
    csvValueGetter: dmaValueGetter,
};

export const DMA_NAME = {
    field: 'dmaName',
};

export const DAY_OF_WEEK = {
    field: 'dayOfWeek',
    label: 'Day of Week',
    cellRendererFramework: EllipsisCellRenderer,
    csvValueGetter: enumValueGetter,
    headerValueGetter: DateHeaderRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const HOUR_OF_DAY = {
    field: 'hourOfDay',
    label: 'Hour of Day',
    cellRendererFramework: EllipsisCellRenderer,
    csvValueGetter: enumValueGetter,
    headerValueGetter: DateHeaderRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const CONVERSIONS_VALUE = {
    field: 'conversionsValue',
    label: 'Value',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
    },
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const ROAS = {
    field: 'roas',
    label: 'ROAS',
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    visibilityCondition: ({ accountConfig, selectedCampaign, permissionsMap }) =>
        dcoInTrialVisibilityCondition({
            accountConfig,
            selectedCampaign,
            permissionsMap,
        }),
};

export const ROAS_CLICKS = {
    ...ROAS,
    field: 'roasClicks',
    label: 'ROAS (Clicks)',
    referenceColId: ROAS.field,
};

export const ROAS_VIEWS = {
    ...ROAS,
    field: 'roasViews',
    label: 'ROAS (Views)',
    referenceColId: ROAS.field,
};

export const PARTNER_NAME = {
    ...NAME,
    field: 'partnerName',
    label: 'Partner',
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_NAME = {
    ...NAME,
    field: 'audienceName',
    label: 'Audience Name',
    cellRenderer: AudienceNameCellRenderer,
    cellRendererParams: { isSearchable: true },
    headerComponentParams: defaultHeaderComponentParams,
};

export const SEGMENT_NAME = {
    ...NAME,
    field: 'audienceName',
    label: 'Segment Name',
    cellRenderer: SegmentNameCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_ACCOUNT_DESCRIPTION = {
    ...NAME,
    field: 'accountDescription',
    label: 'Account Name',
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: { lines: 1 },
};

export const CONTEXT = {
    ...AUDIENCE_NAME,
    label: 'Context',
};

export const CVR_VS_AVG = {
    field: 'cvrUpliftWeighted',
    label: 'CVR vs Avg.',
    cellRenderer: CvrVsAvgCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const VIEWABILITY_RATE_VS_AVG = {
    field: 'viewabilityRateVsAvg',
    label: 'Viewability Rate vs Baseline',
    cellRenderer: RateVsAvgCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
};

export const COMPLETION_RATE_VS_AVG = {
    ...VIEWABILITY_RATE_VS_AVG,
    field: 'completionRateVsAvg',
    label: 'Completion Rate vs Baseline',
};

export const DATA_PARTNER_AUDIENCE_ID = {
    ...ID,
    field: 'dataPartnerAudienceId',
    label: 'Audience ID',
};

export const IMPRESSIONS_PCT = {
    ...CTR,
    field: 'impressionsPct',
    label: '% Impressions',
};

export const CTA = {
    field: 'cta',
    label: 'CTA Button',
    renderParams: { msgIdPrefix: 'creative.creator.content.cta.option' },
    valueGetter: 'data.cta.ctaType',
    cellRenderer: EnumValueCellRenderer,
    cellEditorFramework: CTADropdownCellEditor,
    headerComponentParams: defaultHeaderComponentParams,
    csv: [
        {
            headerName: 'CTA',
            field: 'cta.ctaType',
            csvValueGetter: enumValueGetter,
        },
    ],
};

export const CAMPAIGN_ID = {
    field: 'campaignId',
    label: 'Campaign ID',
    headerComponentParams: defaultHeaderComponentParams,
};

// campaigns group
export const CAMPAIGNS_GROUP_NAME = {
    ...NAME,
    field: 'campaignsGroupName',
    label: 'Campaign Group Name',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CAMPAIGNS_GROUP_ID = {
    field: 'campaignsGroupId',
    label: 'Campaign Group ID',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CAMPAIGNS_GROUP_STATUS = {
    ...STATUS,
    field: 'displayStatus',
    renderParams: {
        getStatusType: getCampaignsGroupStatusType,
        isStatusRejected: stubFalse,
    },
    cellRenderer: CampaignsGroupStatusRenderer,
};

export const CAMPAIGNS_GROUP_LINKED_CAMPAIGNS = {
    field: 'linkedCampaigns',
    label: 'Linked Campaigns',
    headerComponentParams: defaultHeaderComponentParams,
};

export const CAMPAIGNS_GROUP_BUDGET = {
    ...BUDGET,
    csv: [
        {
            headerName: 'Budget',
            field: 'budget.value',
        },
    ],
};

export const CAMPAIGNS_GROUP_REMAINING_BUDGET = {
    field: 'remainingGroupBudget',
    label: 'Remaining Budget',
    renderParams: {
        ...defaultCurrencyRenderParams,
        showEmptyWhenZero: true,
    },
    cellRenderer: FormattedNumberCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    csv: [
        {
            headerName: 'Remaining Budget',
            field: 'remainingGroupBudget',
        },
    ],
};

export const ADVERTISER_DOMAIN_NAME = {
    ...NAME,
    field: 'advertiserDomain',
    label: 'Advertiser Domain',
    headerComponentParams: defaultHeaderComponentParams,
};

export const ATTRIBUTION_GROUP = {
    ...NAME,
    field: 'attributionGroup',
    label: 'Attribution Group',
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_TYPE = {
    field: 'type',
    label: 'Type',
    cellRendererParams: { msgIdPrefix: 'report.audienceType' },
    cellRendererFramework: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: nonDefaultHeaderComponentParams('audience'),
};

export const AUDIENCE_STATUS = {
    field: 'status',
    label: 'Status',
    cellRendererParams: {
        getStatusType: getAudienceStatusType,
        isStatusRejected: stubFalse,
        disableValueTooltip: true,
        msgIdPrefix: 'report.audienceStatus',
    },
    cellRendererFramework: StatusCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: nonDefaultHeaderComponentParams('audience'),
};

export const AUDIENCE_SIZE = {
    field: 'audienceSize',
    label: 'Monthly Reach',
    cellRenderer: FormattedNumberCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_MATCH_RATE = {
    field: 'matchRate',
    label: 'Match Rate',
    cellRenderer: AudienceMatchRateCellRenderer,
    renderParams: defaultPercentRenderParams,
    headerComponentParams: defaultHeaderComponentParams,
};

export const CONDITIONS = {
    field: 'conditions',
    label: 'Conditions',
    cellRenderer: AudiencePixelBasedDetailsCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_DETAILS = {
    field: 'sourceAudience',
    label: 'Based on',
    cellRenderer: PredictiveAudienceDetailsCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_SOURCE = {
    field: 'audienceSource',
    label: 'Source',
    headerComponentParams: defaultHeaderComponentParams,
};

export const AUDIENCE_CAMPAIGN_USING = {
    field: 'campaignUsing',
    label: 'Campaigns Using',
    cellRenderer: AudienceCampaignsUsingCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};
export const APPLIES_TO_CAMPAIGNS = {
    field: 'campaigns',
    label: 'Applies To',
    cellRenderer: PerformanceRulesAppliesToCampaignsCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const EXCLUDE_FROM_CAMPAIGNS = {
    field: 'excludeFromCampaigns',
    label: 'Exclude From Campaigns',
};

export const LAST_MODIFY_BY = {
    field: 'lastModifiedBy',
    label: 'Last Modified By',
    headerComponentParams: defaultHeaderComponentParams,
};

export const LAST_MODIFY_ON = {
    field: 'lastModifiedOn',
    label: 'Last Modified On',
    valueFormatter: gridDateFormatter,
    csvValueGetter: formattedDateValueGetter,
    headerComponentParams: defaultHeaderComponentParams,
};

export const LAST_MODIFY_ON_UPDATE_TIME = {
    ...LAST_MODIFY_ON,
    field: 'updateTime',
};
export const PERFORMANCE_RULES_LAST_MODIFY_TIME = {
    ...LAST_MODIFY_ON,
    field: 'executionTimeInUtc',
};

export const DEMAND_TYPE = {
    field: 'demandType',
    label: 'Demand Type',
    valueFormatter: gridEnumFormatter,
    csvValueGetter: csvEnumFormatter,
    visibilityCondition: ({ selectedAccount }) => isProgrammaticAccount(selectedAccount),
};

export const TEMPLATE_NAME = {
    field: 'creativeTemplateName',
    label: 'Template Name',
    headerComponentParams: defaultHeaderComponentParams,
};

export const TEMPLATE_ID = {
    ...ID,
    field: 'creativeTemplateDbId',
};

export const ITEM_ID = {
    field: 'itemId',
    label: 'Item Id',
    headerComponentParams: defaultHeaderComponentParams,
};

export const BILLING_DATE = {
    ...DATE,
    field: 'billingDate',
    headerComponentParams: defaultHeaderComponentParams,
};

export const DUE_DATE = {
    ...DATE,
    field: 'dueDate',
    label: 'Due Date',
};

export const PAYMENT_METHOD = {
    field: 'paymentMethod',
    label: 'Payment method',
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererFramework: PaymentMethodCellRenderer,
    valueFormatter: gridPaymentMethodValueFormatter,
};

export const INVOICE_NAME = {
    field: 'invoiceName',
    label: 'Invoice',
    headerComponentParams: defaultHeaderComponentParams,
};

export const PAYMENT_AMOUNT = {
    field: 'paymentAmount',
    label: 'Amount',
    renderParams: {
        ...defaultCurrencyRenderParams,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    },
    headerComponentParams: defaultHeaderComponentParams,
};

export const VAT = {
    ...PAYMENT_AMOUNT,
    field: 'vatAmount',
    label: 'VAT',
};

export const FEES = {
    ...VAT,
    field: 'feeAmount',
    label: 'Fees',
};

export const PAYMENT_STATUS = {
    field: 'paymentStatus',
    label: 'Status',
    cellRendererFramework: PaymentStatusCellRenderer,
    renderParams: {
        msgIdPrefix: 'app.campaigns.reports.grid.payments.status',
    },
    headerComponentParams: defaultHeaderComponentParams,
    csvValueGetter: enumValueGetter,
};

export const INVOICE_STATUS = {
    ...PAYMENT_STATUS,
    cellRendererFramework: InvoiceStatusCellRenderer,
    renderParams: {
        msgIdPrefix: 'app.campaigns.reports.grid.invoices.status',
    },
};

export const INVOICE_AMOUNT = {
    ...PAYMENT_AMOUNT,
    field: 'invoiceAmount',
    label: 'Amount',
};

export const INVOICE_ID = {
    field: 'invoiceId',
};

export const INVOICE_TYPE = {
    field: 'invoiceType',
};

export const UNIQUE_IMPRESSIONS = {
    field: 'uniqueImpressions',
    label: 'Unique Impressions Reach',
    valueFormatter: gridNumberFormatter,
    headerComponentParams: defaultHeaderComponentParams,
    accountConfigurations: {
        [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'false',
    },
};

export const UNIQUE_VIEWABLE_IMPRESSIONS = {
    ...UNIQUE_IMPRESSIONS,
    field: 'uniqueViewableImpressions',
    label: 'Unique Viewable Impressions Reach',
};

export const UNIQUE_CLICKS = {
    ...UNIQUE_IMPRESSIONS,
    field: 'uniqueClicks',
    label: 'Unique Clicks Reach',
};

export const CAMPAIGN_CONVERSION_RULES = {
    headerComponentParams: defaultHeaderComponentParams,
    field: 'campaignConversionRules',
    label: 'Campaign Conversion rules',
    cellRendererFramework: EllipsisCellRenderer,
    valueFormatter: gridCampaignGoalValueFormatter,
    accountConfigurations: {
        [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS]: 'true',
        [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'false',
    },
};

export const UNIQUE_COMPLETED_VIEWS = {
    ...UNIQUE_IMPRESSIONS,
    field: 'uniqueCompletedViews',
    label: 'Unique Completed Views Reach',
};

export const ACTIVITY = {
    field: 'activityCodeDescription',
    label: 'Activity',
    valueGetter: gridCampaignHistoryActivityGetter,
    csvValueGetter: formattedCampaignHistoryActivityGetter,
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const ACTIVITY_DETAILS = {
    field: 'activityDetailsDescription',
    label: 'Activity Details',
    valueGetter: gridCampaignHistoryActivityDetailsGetter,
    csvValueGetter: formattedCampaignHistoryActivityDetailsGetter,
    cellRendererFramework: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const OLD_VALUE = {
    field: 'oldValue',
    label: 'Old Value',
    valueGetter: gridCampaignHistoryValueFormatter,
    csvValueGetter: formattedCampaignHistoryValueGetter,
    cellRendererFramework: CampaignHistoryValueCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const NEW_VALUE = {
    field: 'newValue',
    label: 'New Value',
    valueGetter: gridCampaignHistoryValueFormatter,
    csvValueGetter: formattedCampaignHistoryValueGetter,
    cellRendererFramework: CampaignHistoryValueCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const CONVERSION_NAME = {
    field: 'conversionName',
    label: 'Conversion Name',
    cellRenderer: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: {
        lines: 1,
        isSearchable: true,
    },
};

export const CONVERSION_TYPE = {
    field: 'type',
    label: 'Type',
    cellRendererParams: { msgIdPrefix: 'report.conversion.type' },
    cellRendererFramework: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: nonDefaultHeaderComponentParams('conversion'),
};

export const CONVERSION_STATUS = {
    field: 'status',
    label: 'Status',
    cellRendererParams: {
        getStatusType: getConversionStatusType,
        isStatusRejected: stubFalse,
        disableValueTooltip: true,
        msgIdPrefix: 'report.conversionStatus',
    },
    cellRendererFramework: StatusCellRenderer,
    csvValueGetter: enumValueGetter,
    headerComponentParams: nonDefaultHeaderComponentParams('conversion'),
};

export const ACCOUNT_NAME = {
    field: 'accountDescription',
    label: 'Account Name',
    headerComponentParams: defaultHeaderComponentParams,
};

export const LAST_RECEIVED = {
    field: 'lastReceived',
    label: 'Last Received',
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererFramework: LastReceivedCellRenderer,
};

export const EVENT_RECEIVED = {
    field: 'eventsReceived',
    label: 'Events Received (7 days)',
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererFramework: EventsReceivedCellRenderer,
};

export const INCLUDED_IN_TOTAL_CONVERSIONS = {
    field: 'includeInTotalConversions',
    label: 'Include In Total Conversions',
    cellRendererParams: {
        msgIdPrefix: 'app.campaigns.campaign.conversion.includedInTotalConversions',
    },
    cellRendererFramework: BoolValueCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const INCLUDE_IN_TOTAL_VALUE = {
    field: 'includeInTotalValue',
    label: 'Include In Total Value',
    cellRendererParams: {
        msgIdPrefix: 'app.campaigns.campaign.conversion.includeInTotalValue',
    },
    cellRendererFramework: BoolValueCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
};

export const CONVERSION_CONDITIONS = {
    field: 'conditions',
    label: 'Conditions',
    cellRendererFramework: ConversionPixelBasedDetailsCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    csvValueGetter: csvConversionConditionsValueFormatter,
};

export const EVENT_NAME = {
    field: 'eventName',
    label: 'Event Name',
    cellRenderer: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: {
        lines: 1,
        isSearchable: true,
    },
};

export const LAST_DATE_RECEIVED = {
    field: 'lastDateEventReceived',
    label: 'Last Received',
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererFramework: LastReceivedCellRenderer,
};

export const TOTAL_RECEIVED = {
    field: 'totalEventsReceived',
    label: 'Total Received',
    headerComponentParams: defaultHeaderComponentParams,
};

export const DEFINED_CONVERSIONS = {
    field: 'definedConversions',
    label: 'Defined Conversions',
    renderParams: {
        showEmptyWhenZero: true,
    },
    headerComponentParams: defaultHeaderComponentParams,
};

export const FUNNEL_NAME = {
    field: 'name',
    label: 'Funnel Name',
    cellRenderer: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: {
        lines: 1,
        isSearchable: true,
    },
};

export const CAMPAIGNS_USING = {
    field: 'numberOfCampaignsUsing',
    label: 'Campaigns Using',
    headerComponentParams: defaultHeaderComponentParams,
};

export const RULE_DISPLAY_NAMES = {
    field: 'ruleDisplayNames',
    label: 'Conversions ',
    cellRenderer: EllipsisCellRenderer,
    headerComponentParams: defaultHeaderComponentParams,
    cellRendererParams: {
        lines: 1,
        isSearchable: true,
    },
};

export const FUNNEL_ID = {
    ...ID,
    field: 'id',
    label: 'funnel ID',
};

export const PERFORMANCE_RULE_ID = {
    ...ID,
    label: 'Performance Rule ID',
};

export const PERFORMANCE_RULE_NAME = {
    field: 'ruleName',
    label: 'Rule Name',
    renderParams: {
        isSearchable: true,
        lines: 1,
    },
    cellRenderer: EllipsisCellRenderer,
};

export const PERFORMANCE_RULE_TYPE = {
    field: 'dimension',
    label: 'Dimension',
    cellRenderer: EnumValueCellRenderer,
    csvValueGetter: enumValueGetter,
};

export const PERFORMANCE_RULE_EXECUTED_ACTION_RULE_NAME = {
    ...PERFORMANCE_RULE_NAME,
    cellRendererFramework: PerformanceRuleExecutedActionRuleNameCellRenderer,
};

export const PERFORMANCE_RULE_EXECUTED_ACTION_ACCOUNT_NAME = {
    field: 'accountName',
    label: 'Account Name',
    cellRendererFramework: PerformanceRuleExecutedActionAccountNameCellRenderer,
};

export const PERFORMANCE_RULE_EXECUTED_ACTION_CAMPAIGN_NAME = {
    ...CAMPAIGN_NAME,
    cellRendererFramework: PerformanceRuleExecutedActionCampaignNameCellRenderer,
};

export const PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY = {
    field: 'actionType',
    label: 'Activity',
    cellRenderer: EllipsisCellRenderer,
    cellRendererFramework: PerformanceRuleExecutedActionActivityTypeCellRenderer,
};
export const PERFORMANCE_RULE_EXECUTED_ACTION_ACTIVITY_DETAILS = {
    field: 'affectedEntity',
    label: 'Activity Details',
    cellRenderer: EllipsisCellRenderer,
    cellRendererFramework: PerformanceRuleExecutedActionActivityDetailsCellRenderer,
};

export const PERFORMANCE_RULE_EXECUTED_ACTION_DATE = {
    ...CHANGE_TIME,
    field: 'executionTimeInUtc',
    label: 'Date & Time',
    valueFormatter: gridDateTimeFormatter,
};

export const LAST_MODIFIED_BY_PERFORMER = {
    ...PERFORMER,
    label: LAST_MODIFY_BY.label,
    headerComponentParams: nonDefaultHeaderComponentParams(LAST_MODIFY_BY.field),
};

export const SCHEDULED_REPORTS_ID = {
    ...ID,
    label: 'Scheduled Reports ID',
};

export const REPORT_NAME = {
    field: 'reportName',
    label: 'Report Name',
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: EllipsisCellRenderer,
};

export const DATE_RANGE = {
    field: 'dateRange',
    label: 'Date Range',
    headerComponentParams: defaultHeaderComponentParams,
    cellRenderer: EnumValueCellRenderer,
};

export const SENT_TO = {
    field: 'sentTo',
    label: 'Sent To',
    headerComponentParams: defaultHeaderComponentParams,
};

export const FREQUENCY = {
    field: 'frequency',
    label: 'Frequency',
    cellRenderer: EnumValueCellRenderer,
};

export const ESTIMATED_DAILY_CAP = {
    field: 'estimatedDailyCap',
    label: 'Estimated Daily Cap',
    renderParams: {
        ...defaultCurrencyRenderParams,
        maximumFractionDigits: 2,
    },
    headerComponentParams: defaultHeaderComponentParams,
    headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    accountConfigurations: {
        [FEATURE_FLAGS.SHOW_ESTIMATED_DAILY_CAP_COLUMN_CAMPAIGN_REPORT]: 'true',
    },
};
