const PERFORMANCE_RECOMMENDATIONS_SUB_TYPES = {
    BUDGET_REACHED_DEPLETION: 'BUDGET_REACHED_DEPLETION',
    BUDGET_CLOSE_DEPLETION: 'BUDGET_CLOSE_DEPLETION',
    BUDGET_OVER_DELIVERY: 'BUDGET_OVER_DELIVERY',
    BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CONVERSIONS: 'BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CONVERSIONS',
    BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CLICKS: 'BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_CLICKS',
    BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CONVERSIONS:
        'BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CONVERSIONS',
    BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CLICKS: 'BUDGET_DAILY_CAP_PARTIAL_ACTIVITY_DAY_PARTING_CLICKS',
    BUDGET_DAILY_CAP_HIGH_PACING_CONVERSIONS: 'BUDGET_DAILY_CAP_HIGH_PACING_CONVERSIONS',
    BUDGET_DAILY_CAP_HIGH_PACING_CLICKS: 'BUDGET_DAILY_CAP_HIGH_PACING_CLICKS',
};

export default PERFORMANCE_RECOMMENDATIONS_SUB_TYPES;
