import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox, CheckboxGroup, StyledButton, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { addProtocol } from 'modules/taboola-common-frontend-modules/utils/urlUtils';
import { COMPONENT_STATUS } from 'services/constants';
import { useCurrentValueGetter } from '../../../../../../hooks';
import useFormFieldValue from '../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { uniqueUrlValidation, urlValidations } from '../../../../config';
import { UrlValidatedInput } from './UrlValidatedInput';
import styles from './urlItem.module.scss';

const validations = [...urlValidations, uniqueUrlValidation];

export const UrlItem = ({ existingItems, deleteItem, onSetLoadMetadata, disabled }) => {
    const { value: id } = useFormFieldValue({ field: 'id' });
    const validationDependencies = useMemo(
        () => ({
            list: existingItems.filter(el => el.id !== id).map(({ value }) => addProtocol(value)),
        }),
        [id, existingItems]
    );
    const { value, isDirty: isUrlDirty } = useFormFieldValue({
        field: 'value',
    });
    const { value: shouldLoadMetadata, onChange: setShouldLoadMetadata } = useFormFieldValue({
        field: 'shouldLoadMetadata',
    });
    const { onChange: setStatus } = useFormFieldValue({
        field: 'status',
    });

    const deleteUrlItem = () => deleteItem(id);

    const shouldLoadMetadataGetter = useCurrentValueGetter(shouldLoadMetadata);
    const handleLoadFromUrl = newValue => {
        const checked = Boolean(newValue);
        const prev = shouldLoadMetadataGetter();

        setShouldLoadMetadata(checked);
        if (!prev && checked) {
            onSetLoadMetadata(id, value);
        }
    };

    useEffect(() => {
        if (!isUrlDirty || !value) {
            return;
        }
        setStatus(COMPONENT_STATUS.ACTIVE);
    }, [setStatus, isUrlDirty, value]);

    return (
        <div className={styles['item-container']}>
            <UrlValidatedInput
                field="value"
                disabled={disabled}
                validations={validations}
                validationDependencies={validationDependencies}
                className={styles['input']}
                errorMessageClass={styles['error-msg']}
            >
                {!disabled && (
                    <StyledButton
                        aria-label="Remove url item"
                        className={styles['remove-button']}
                        onClick={deleteUrlItem}
                        iconBefore="close"
                        iconBeforeClassName={styles['remove-icon']}
                        size={STYLED_BUTTON_SIZE.SMALL}
                        type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-component="RemoveUrlButton"
                    />
                )}
            </UrlValidatedInput>
            <CheckboxGroup
                onSelectCheckbox={handleLoadFromUrl}
                onUnselectCheckbox={() => handleLoadFromUrl()}
                selectedValues={[shouldLoadMetadata]}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={!shouldLoadMetadata}
                data-metrics-component="shouldLoadLandingPageMetadata"
            >
                <TextCheckbox
                    value
                    title={
                        <FormattedMessage
                            id="creative.creator.load.page.metadata"
                            defaultMessage="Load image and headline from the landing page URL"
                        />
                    }
                    disabled={disabled}
                />
            </CheckboxGroup>
        </div>
    );
};

UrlItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        status: PropTypes.string,
        value: PropTypes.string,
    }),
    existingItems: PropTypes.array,
    deleteItem: PropTypes.func,
};
