import { CardCvcElement } from '@stripe/react-stripe-js';
import React from 'react';
import classnames from 'classnames/bind';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { STRIPE_ELEMENT_DEFAULT_OPTIONS } from 'modules/taboola-common-frontend-modules/stripe';
import commonStyles from '../../common.module.scss';

const classNameBuilder = classnames.bind(commonStyles);

export const StripeCardCVV = ({ indicationType, ...stripeProps }) => {
    const hasError = indicationType === INDICATION_TYPES.ERROR;

    return (
        <div
            className={classNameBuilder(commonStyles['container'], {
                error: hasError,
            })}
        >
            <CardCvcElement
                className={commonStyles['element']}
                {...stripeProps}
                options={{ ...STRIPE_ELEMENT_DEFAULT_OPTIONS, placeholder: '123' }}
            />
        </div>
    );
};
