import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { recommendationsApi } from '../../../services/api';
import { setRecommendations } from '../actions';
import RECOMMENDATION_TYPE from '../recommendationTypes';
import getRecommendationUrl from '../utils/getRecommendationUrl';
import RECOMMENDATION_ACTION from '../utils/recommendationActions';

const audienceHandler =
    ({ history, recommendationData, recommendationMetadata }) =>
    async (dispatch, getState) => {
        const { uddIdsString = '', campaignId, recommendationId } = recommendationMetadata;
        const { accountId } = selectedAccountSelector(getState());
        try {
            const { dataAdditionalCpc } = await recommendationsApi.getDataAdditionalCpc(accountId, {
                campaignId,
                uddIdsString,
            });
            const recommendationDataWithCpc = { ...recommendationData, bid: dataAdditionalCpc };
            const destinationPath = getRecommendationUrl(
                RECOMMENDATION_ACTION.DUPLICATE_CAMPAIGN,
                accountId,
                campaignId
            );

            dispatch(
                setRecommendations({
                    recommendationType: RECOMMENDATION_TYPE.AUDIENCE,
                    recommendationData: recommendationDataWithCpc,
                    recommendationId,
                })
            );
            dispatch(navigate(history, destinationPath));
        } catch (err) {
            console.error(err);
        }
    };

export default audienceHandler;
