'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Buttons = require('../../Buttons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'calendarPresets-module__taboola-svg-icon___2_gNF',
    'calendar-info-panel-buttons': 'calendarPresets-module__calendar-info-panel-buttons___1J2Gt',
    'button': 'calendarPresets-module__button___7U0tF',
    'active': 'calendarPresets-module__active___43qL4'
};


var classNameBuilder = _bind2.default.bind(styles);
var CalendarPresets = function CalendarPresets(_ref) {
    var preset = _ref.preset,
        periods = _ref.periods,
        onPeriodChange = _ref.onPeriodChange,
        extendPresetProps = _ref.extendPresetProps;
    return _react2.default.createElement(
        'div',
        { className: styles['calendar-info-panel-buttons'] },
        Object.keys(periods).map(function (period) {
            return _react2.default.createElement(
                _Buttons.StyledButton,
                Object.assign({
                    className: classNameBuilder('button', { active: period === preset }),
                    key: period,
                    onClick: function onClick() {
                        return onPeriodChange(period);
                    },
                    size: _Buttons.STYLED_BUTTON_SIZE.MEDIUM,
                    type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON
                }, extendPresetProps(periods[period].label)),
                periods[period].label
            );
        })
    );
};

CalendarPresets.propTypes = {
    preset: _propTypes2.default.string,
    periods: _propTypes2.default.object.isRequired,
    onPeriodChange: _propTypes2.default.func.isRequired,
    extendPresetProps: _propTypes2.default.func
};

CalendarPresets.defaultProps = {
    extendPresetProps: function extendPresetProps() {
        return {};
    }
};

exports.default = CalendarPresets;