import { useEffect, useMemo, useState } from 'react';
import { isEmpty, isInteger, isNil } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useFormValidatedValue } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';

export const itemValidations = [
    {
        validationFn: items => {
            return isEmpty(items) || items.every(({ budgetLimit }) => isInteger(budgetLimit ?? 0));
        },
        messageId: 'campaigns.group.editor.sharedBudgetCampaigns.validations.error.integer',
    },
    {
        validationFn: items => {
            return isEmpty(items) || items.every(({ budgetLimit }) => isNil(budgetLimit) || budgetLimit > 0);
        },
        messageId: 'campaigns.group.editor.sharedBudgetCampaigns.validations.error.minValue',
    },
    {
        validationFn: (items, options, { marketingObjective }) => {
            return (
                isEmpty(items) ||
                items.every(
                    ({ marketingObjective: campaignMarketingObjective }) =>
                        campaignMarketingObjective === marketingObjective
                )
            );
        },
        messageId: 'campaigns.group.editor.sharedBudgetCampaigns.validations.error.invalidMarketingObjective',
    },
    {
        validationFn: (items, options, { groupBudget }) => {
            if (!groupBudget) {
                return true;
            }

            return items.every(({ budgetLimit }) => (budgetLimit ?? 0) <= groupBudget);
        },
        messageId: 'campaigns.group.editor.sharedBudgetCampaigns.validations.error.maxBudgetLimit',
        events: ['submit'],
    },
];

const validations = [
    {
        validationFn: (items, options, { initialBudgetType, budgetType }) => {
            return !initialBudgetType || initialBudgetType === budgetType;
        },
        messageId: 'campaigns.group.editor.sharedBudgetCampaigns.validations.error.budgetTypeWasChanged',
        severity: INDICATION_TYPES.WARNING,
    },
    ...itemValidations,
];

export const useSharedBudgetCampaignsValidations = ({ marketingObjective, budgetType, groupBudget }) => {
    const [initialBudgetType, setInitialBudgetType] = useState(budgetType);
    const [isReady, setIsReady] = useState(false);
    const validationDependencies = useMemo(
        () => ({
            initialBudgetType,
            budgetType,
            marketingObjective,
            groupBudget,
        }),
        [budgetType, groupBudget, initialBudgetType, marketingObjective]
    );
    const {
        value: campaigns,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'campaigns',
        validations,
        validationDependencies,
        isReady,
    });

    // This effect is used to update the initial budget type, to show warning in case we had campaigns with budget
    // when the budget type was changed
    useEffect(() => {
        if (!initialBudgetType && campaigns.some(({ budgetLimit }) => budgetLimit)) {
            setInitialBudgetType(budgetType);
        }

        if (isEmpty(campaigns) || campaigns.every(({ budgetLimit }) => !budgetLimit)) {
            setInitialBudgetType(null);
        }
        setIsReady(true);
    }, [campaigns, budgetType, initialBudgetType]);

    return {
        indicationData,
        scrollRef,
    };
};
