import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const getPresetLabel = (formatMessage, preset, defaultMessage) =>
    formatMessage({
        id: `app.campaigns.campaign.date.range.${preset}`,
        defaultMessage,
    });

const getPresetLabels = (formatMessage, defaultMessages) => {
    return Object.keys(defaultMessages).reduce(
        (result, key) => ({
            ...result,
            [key]: getPresetLabel(formatMessage, key, defaultMessages[key]),
        }),
        {}
    );
};

const createPreset = ({ preset, label, startDate, endDate }) => {
    return {
        [preset]: {
            label,
            startDate,
            endDate,
        },
    };
};

const renderWeekHeaderElement = day => (
    <FormattedMessage
        id={`app.campaigns.campaign.date.picker.weekday.${day.toUpperCase()}`}
        defaultMessage={`${day.toUpperCase()}`}
    />
);

export { getPresetLabel, getPresetLabels, createPreset, renderWeekHeaderElement };
