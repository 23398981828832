"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgTrackingSection(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M15.5862 6.99787C15.9767 6.60735 16.6098 6.60735 17.0004 6.99787L21.1952 10.7933C21.5858 11.1838 21.5858 11.8169 21.1952 12.2075L17.0001 15.9982C16.6096 16.3887 15.9764 16.3887 15.5859 15.9982C15.1953 15.6076 15.1953 14.9745 15.5859 14.5839L19.0739 11.5004L15.5862 8.41209C15.1956 8.02156 15.1956 7.3884 15.5862 6.99787Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M7.90199 16.0004C7.51146 16.3909 6.8783 16.3909 6.48777 16.0004L2.29289 12.205C1.90237 11.8145 1.90237 11.1813 2.29289 10.7908L6.48806 7.00011C6.87859 6.60959 7.51175 6.60959 7.90228 7.00011C8.2928 7.39064 8.2928 8.0238 7.90228 8.41433L4.41421 11.4979L7.90199 14.5862C8.29251 14.9767 8.29251 15.6099 7.90199 16.0004Z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M12.9153 4.03432C13.4488 4.17726 13.7654 4.7256 13.6224 5.25907L11.4813 18.2911C11.3384 18.8245 10.79 19.1411 10.2566 18.9982C9.72311 18.8552 9.40653 18.3069 9.54947 17.7734L11.6906 4.74143C11.8335 4.20796 12.3819 3.89138 12.9153 4.03432Z",
            fill: "#212832"
        })
    );
}
exports.default = SvgTrackingSection;