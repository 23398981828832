import React from 'react';

export const PayPalSmallLogo = () => {
    return (
        <svg width="64" height="17" viewBox="0 0 64 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.68001 0.000976562H2.70203C2.36138 0.000976562 2.07168 0.247667 2.01855 0.582877L0.00522592 13.307C-0.0348076 13.5581 0.160264 13.7845 0.41575 13.7845H2.79228C3.13293 13.7845 3.42263 13.5378 3.47577 13.2018L4.01876 9.76993C4.07117 9.43399 4.3616 9.1873 4.70152 9.1873H6.27738C9.55649 9.1873 11.449 7.60558 11.9432 4.47116C12.1659 3.09985 11.9527 2.02239 11.3085 1.26781C10.601 0.439215 9.34613 0.000976562 7.68001 0.000976562ZM8.25431 4.64819C7.98208 6.42872 6.6173 6.42872 5.29765 6.42872H4.54648L5.07347 3.10347C5.10476 2.90249 5.27946 2.75448 5.48326 2.75448H5.82755C6.72648 2.75448 7.57446 2.75448 8.01265 3.26528C8.27396 3.57001 8.35403 4.02276 8.25431 4.64819Z"
                fill="#253B80"
            />
            <path
                d="M22.5601 4.59057H20.1763C19.9732 4.59057 19.7978 4.73858 19.7665 4.93956L19.661 5.60418L19.4943 5.36329C18.9782 4.61669 17.8275 4.3671 16.6789 4.3671C14.0447 4.3671 11.7948 6.35586 11.3566 9.14564C11.1288 10.5373 11.4527 11.8679 12.2446 12.7959C12.971 13.6492 14.0104 14.0047 15.2471 14.0047C17.3696 14.0047 18.5466 12.6443 18.5466 12.6443L18.4403 13.3046C18.4003 13.5571 18.5954 13.7834 18.8494 13.7834H20.9966C21.338 13.7834 21.6263 13.5367 21.6801 13.2008L22.9685 5.06799C23.0092 4.81767 22.8149 4.59057 22.5601 4.59057ZM19.2374 9.21529C19.0073 10.5728 17.9264 11.4841 16.5478 11.4841C15.8556 11.4841 15.3024 11.2628 14.9472 10.8435C14.5949 10.427 14.461 9.8342 14.5731 9.17394C14.7878 7.82802 15.8869 6.88697 17.2444 6.88697C17.9213 6.88697 18.4716 7.11117 18.8341 7.53417C19.1973 7.96152 19.3414 8.55794 19.2374 9.21529Z"
                fill="#253B80"
            />
            <path
                d="M35.2558 4.591H32.8604C32.6318 4.591 32.4171 4.70419 32.2875 4.89356L28.9837 9.74466L27.5832 5.08293C27.4952 4.79125 27.2251 4.591 26.9194 4.591H24.5654C24.2794 4.591 24.0807 4.86962 24.1717 5.13807L26.8102 12.8566L24.3296 16.3473C24.1345 16.6223 24.3311 17.0003 24.6681 17.0003H27.0606C27.2877 17.0003 27.5003 16.89 27.6291 16.7042L35.5965 5.24038C35.7872 4.96612 35.5914 4.591 35.2558 4.591Z"
                fill="#253B80"
            />
            <path
                d="M58.8333 13.3068L60.8765 0.34972C60.9078 0.14874 61.0825 0.000725561 61.2856 0H63.5857C63.8397 0 64.0348 0.227101 63.9947 0.478145L61.98 13.2016C61.9275 13.5375 61.6379 13.7842 61.2965 13.7842H59.2424C58.9884 13.7842 58.7933 13.5578 58.8333 13.3068Z"
                fill="#179BD7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.1868 0.000708556H38.2081C37.8682 0.000708556 37.5785 0.247399 37.5254 0.582608L35.5121 13.3068C35.472 13.5578 35.6671 13.7842 35.9211 13.7842H38.476C38.7133 13.7842 38.9164 13.6115 38.9535 13.3764L39.5249 9.76966C39.5773 9.43372 39.8677 9.18703 40.2076 9.18703H41.7828C45.0626 9.18703 46.9544 7.60531 47.4493 4.47089C47.6728 3.09958 47.4581 2.02212 46.8139 1.26754C46.1071 0.438947 44.853 0.000708556 43.1868 0.000708556ZM43.7611 4.64793C43.4896 6.42845 42.1249 6.42845 40.8045 6.42845H40.054L40.5818 3.10321C40.6131 2.90223 40.7863 2.75421 40.9908 2.75421H41.3351C42.2333 2.75421 43.082 2.75421 43.5202 3.26501C43.7815 3.56974 43.8609 4.02249 43.7611 4.64793Z"
                fill="#179BD7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.0662 4.5903H55.6838C55.4793 4.5903 55.3053 4.73832 55.2747 4.9393L55.1692 5.60391L55.0018 5.36302C54.4857 4.61642 53.3357 4.36683 52.1871 4.36683C49.5529 4.36683 47.3037 6.35559 46.8655 9.14537C46.6384 10.537 46.9609 11.8677 47.7528 12.7957C48.4807 13.6489 49.5187 14.0045 50.7553 14.0045C52.8778 14.0045 54.0548 12.644 54.0548 12.644L53.9485 13.3043C53.9085 13.5568 54.1036 13.7832 54.3591 13.7832H56.5056C56.8455 13.7832 57.1352 13.5365 57.1883 13.2005L58.4774 5.06772C58.5167 4.8174 58.3217 4.5903 58.0662 4.5903ZM54.7434 9.21502C54.5148 10.5725 53.4325 11.4839 52.0539 11.4839C51.3631 11.4839 50.8085 11.2626 50.4533 10.8432C50.101 10.4267 49.9685 9.83393 50.0791 9.17367C50.2953 7.82775 51.3929 6.8867 52.7505 6.8867C53.4274 6.8867 53.9777 7.1109 54.3401 7.5339C54.7048 7.96126 54.8489 8.55767 54.7434 9.21502Z"
                fill="#179BD7"
            />
        </svg>
    );
};
