import uuid from 'uuid/v1';
import {
    ALL_CAMPAIGN_GROUPS,
    SPECIFIC_CAMPAIGN_GROUPS,
} from '../components/ScheduledReportsCampaignGroupsListTypeFiled/scheduledReportsCampaignGroupsListTypes';
import {
    ALL_CAMPAIGNS,
    SPECIFIC_CAMPAIGNS,
} from '../components/ScheduledReportsCampaignsListFiled/scheduledReportsCampaignListTypes';
import { ENTITY_TYPES } from '../components/ScheduledReportsDimensionField/scheduledReportDimensionFieldOptions';

const buildSentFromFromGW = email => {
    return {
        id: uuid(),
        email,
    };
};

const transformSentFromFromGW = emails => {
    return emails.map(e => buildSentFromFromGW(e));
};

export const transformScheduledReportFromGw = report => {
    const transformedReport = {
        ...report,
        sentTo: transformSentFromFromGW(report.sentTo),
        campaignSelectType:
            report.entityType === ENTITY_TYPES.CAMPAIGN && report.entityIds ? SPECIFIC_CAMPAIGNS : ALL_CAMPAIGNS,
        campaignGroupsSelectType:
            report.entityType === ENTITY_TYPES.CAMPAIGNS_GROUP_ID && report.entityIds
                ? SPECIFIC_CAMPAIGN_GROUPS
                : ALL_CAMPAIGN_GROUPS,
        campaignGroupList:
            report.entityType === ENTITY_TYPES.CAMPAIGNS_GROUP_ID ? report.entityIds.map(id => ({ id })) : [],
        campaigns: report.entityType === ENTITY_TYPES.CAMPAIGN ? report.entityIds.map(id => ({ id })) : [],
        performanceRuleIds: report.entityType === ENTITY_TYPES.CUSTOM_RULE ? report.entityIds.map(id => id) : [],
    };

    return transformedReport;
};
