import React from 'react';
import { Radio, RadioGroup } from 'taboola-ultimate-ui';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { VIDEO_PMP_IMPRESSION_TYPE } from '../../../common-campaign-form/config';
import styles from '../radioBtnComponents.module.scss';

export const ImpressionType = () => {
    const { value: selectedImpressionType, onChange: onSelectedImpressionTypeChange } = useFormFieldValue({
        field: 'videoBudget.impressionType',
    });

    return (
        <div className="video-business-model-options">
            <RadioGroup
                id="video-impression-type"
                className={styles['radio-group']}
                selectedValue={selectedImpressionType}
                onChange={onSelectedImpressionTypeChange}
            >
                <Radio
                    labelClassName={styles['radio-label']}
                    value={VIDEO_PMP_IMPRESSION_TYPE.VIDEO}
                    title={
                        <FormattedMessage
                            id="video.pmpDeal.creator.impressionType.option.video"
                            defaultMessage="Video"
                        />
                    }
                />
                <Radio
                    labelClassName={styles['radio-label']}
                    value={VIDEO_PMP_IMPRESSION_TYPE.NATIVE_VIDEO}
                    title={
                        <FormattedMessage
                            id="video.pmpDeal.creator.impressionType.option.nativeVideo"
                            defaultMessage="Native video"
                        />
                    }
                />
            </RadioGroup>
        </div>
    );
};

export default ImpressionType;
