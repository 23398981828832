import React from 'react';
import { Textarea } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TrackingCodeTooltip from './TrackingCodeTooltip';
import styles from '../commonEditor.module.scss';

const TrackingCode = () => {
    const { value: trackingCode, onChange } = useFormFieldValue({ field: 'trackingCode', throttleValue: true });
    const changeHandler = useEventValueHandler(onChange);

    return (
        <FormField
            inputId="tracking-code"
            containerClass={styles['input']}
            label={<FormattedMessage id="campaign.editor.tracking.code.title" defaultMessage="Tracking Code" />}
            description={
                <FormattedMessage
                    id="campaign.editor.tracking.code.description"
                    defaultMessage="This code will be added to your landing page URLs so you will be able to track the campaign performance on other analytics platforms."
                />
            }
            helpText={<TrackingCodeTooltip />}
        >
            <Textarea
                id="tracking-code"
                size="large"
                value={trackingCode}
                onChange={changeHandler}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="TrackingCode"
                placeholder=""
                rows={10}
            />
        </FormField>
    );
};

export default TrackingCode;
