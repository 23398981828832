import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { Radio, DatePicker } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES } from '../../../config';
import styles from './creativesABTestRadioButton.module.scss';

const validations = [
    {
        validationFn: (value, options, { isCreativesABTestSelected }) =>
            !isCreativesABTestSelected || !value || moment(value).isValid(),
        messageId: 'validations.error.creativesABTest.endDate.invalid',
    },
    {
        validationFn: (value, options, { isCreativesABTestSelected }) => !isCreativesABTestSelected || value,
        messageId: 'validations.error.creativesABTest.endDate.required',
    },
    {
        validationFn: (value, options, { campaignEndDate, isCreativesABTestSelected }) =>
            !isCreativesABTestSelected || !campaignEndDate || !value || moment(value).isSameOrBefore(campaignEndDate),
        messageId: 'validations.error.creativesABTest.endDate.beforeCampaignEndDate',
    },
    {
        validationFn: (value, options, { maxEndDate, isCreativesABTestSelected }) =>
            !isCreativesABTestSelected || !value || moment(value).isSameOrBefore(moment(maxEndDate)),
        messageId: 'validations.error.creativesABTest.endDate.beforeMaxEndDate',
    },
    {
        validationFn: (value, options, { minEndDate, isCreativesABTestSelected }) =>
            !isCreativesABTestSelected || !value || moment(value).isSameOrAfter(moment(minEndDate)),
        messageId: 'validations.error.creativesABTest.endDate.afterMinEndDate',
    },
];
const dateDisplayFormat = 'DD MMMM YYYY';
const DatePickerWithIndication = withIndication(DatePicker);
const commonConfig = [
    FEATURE_FLAGS.CREATIVES_AB_TEST_MIN_END_DATE_DAYS,
    FEATURE_FLAGS.CREATIVES_AB_TEST_MAX_END_DATE_DAYS,
    FEATURE_FLAGS.CREATIVES_AB_TEST_DEFAULT_END_DATE_DAYS,
];

const CreativesABTestRadioButton = () => {
    const { formatMessage } = useIntl();
    const {
        [FEATURE_FLAGS.CREATIVES_AB_TEST_MIN_END_DATE_DAYS]: minEndDateDays,
        [FEATURE_FLAGS.CREATIVES_AB_TEST_MAX_END_DATE_DAYS]: maxEndDateDays,
        [FEATURE_FLAGS.CREATIVES_AB_TEST_DEFAULT_END_DATE_DAYS]: defaultEndDateDays,
    } = useAccountConfig(commonConfig);
    const { value: campaignEndDate } = useFormFieldValue({ field: 'endDate' });
    const { value: creativeTrafficAllocationMethod, isDirty: isCreativeTrafficAllocationMethodDirty } =
        useFormFieldValue({ field: 'creativeTrafficAllocationMethod' });
    const minEndDate = useMemo(() => moment().add(minEndDateDays, 'd').startOf('day'), [minEndDateDays]);
    const maxEndDate = useMemo(() => moment().add(maxEndDateDays, 'd').endOf('day'), [maxEndDateDays]);

    const creativesABTestEndDateValidationContext = {
        minEndDate,
        maxEndDate,
        formattedMinEndDate: minEndDate.format(dateDisplayFormat),
        formattedMaxEndDate: maxEndDate.format(dateDisplayFormat),
        campaignEndDate,
        isCreativesABTestSelected: creativeTrafficAllocationMethod === CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN,
    };

    const {
        value: creativesABTestEndDate,
        onChange: onCreativesABTestEndDateChange,
        indicationData,
    } = useFormValidatedValue({
        field: 'creativeTrafficAllocationABTestEndDate',
        validations,
        validationDependencies: creativesABTestEndDateValidationContext,
    });

    const isCreativesABTestDisabled = campaignEndDate && moment(campaignEndDate).isBefore(minEndDate);

    useEffect(() => {
        if (!isCreativeTrafficAllocationMethodDirty) {
            return;
        }

        if (creativeTrafficAllocationMethod === CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.OPTIMIZED) {
            onCreativesABTestEndDateChange(null);
            return;
        }

        if (!creativesABTestEndDate) {
            const defaultEndDate = moment().add(defaultEndDateDays, 'd').startOf('day');
            onCreativesABTestEndDateChange(defaultEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creativeTrafficAllocationMethod, isCreativeTrafficAllocationMethodDirty]);

    return (
        <Radio
            key="creative-optimization-creatives-ab-test"
            value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN}
            title={
                <FormattedMessage
                    id="campaign.editor.creative.optimization.creativesABTest"
                    defaultMessage="A/B Testing"
                />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.creative.optimization.creativesABTest.description"
                    defaultMessage="A/B Test - Ads will receive equal opportunities across Taboola’s network. This means that impressions will be allocated to ads more evenly than Optimized, regardless of performance. When the A/B test reaches the end-date, the campaign will switch to Optimized ad delivery."
                />
            }
            disabled={isCreativesABTestDisabled}
            labelClassName={styles['creatives-ab-test-label']}
            childrenContainerClassName={styles['creatives-end-date-container']}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN}
        >
            <DatePickerWithIndication
                date={creativesABTestEndDate && moment(creativesABTestEndDate)}
                customInputIcon={<FormattedMessage id="creativesABTest.time.frame.end" defaultMessage="End Date" />}
                placeholder={formatMessage({
                    id: 'creativesABTest.time.frame.no.end.date',
                    defaultMessage: 'No End Date',
                })}
                disabled={
                    creativeTrafficAllocationMethod !== CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN ||
                    isCreativesABTestDisabled
                }
                onDateChange={onCreativesABTestEndDateChange}
                displayFormat={dateDisplayFormat}
                {...indicationData}
            />
        </Radio>
    );
};

export default CreativesABTestRadioButton;
