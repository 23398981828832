import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames/bind';
import { FormField, CheckboxGroup, TextCheckbox, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CONNECTION_TYPES, TARGETING_TYPES, PLATFORMS } from '../../config';
import WifiTargetingTooltip from './WifiTargetingTooltip';
import commonStyles from '../commonEditor.module.scss';
import styles from './wifiTargeting.module.scss';

const classNameBuilder = classnames.bind(styles);

const isOnlyDesktopTargeted = platformTargeting =>
    platformTargeting && platformTargeting.length === 1 && platformTargeting[0] === PLATFORMS.DESKTOP;

const connectionTypeProp = 'campaignTargeting.connectionTypeTargeting';

const WifiTargeting = () => {
    const { value: connectionTypeTargetingValues = [], onChange: onValuesChange } = useFormFieldValue({
        field: `${connectionTypeProp}.values`,
    });
    const { onChange: onTypeChange } = useFormFieldValue({ field: `${connectionTypeProp}.type` });
    const { value: platformTargeting } = useFormFieldValue({ field: 'platformTargeting' });
    const isDisabled = isOnlyDesktopTargeted(platformTargeting);

    const clearWifiTargeting = useCallback(() => {
        onTypeChange(TARGETING_TYPES.ALL);
        onValuesChange([]);
    }, [onTypeChange, onValuesChange]);

    const handleSelectValue = useCallback(
        value => {
            onTypeChange(TARGETING_TYPES.INCLUDE);
            onValuesChange([value]);
        },
        [onTypeChange, onValuesChange]
    );

    // Here we are clearing Wifi if user selected Desktop only targeting
    useEffect(() => {
        if (isDisabled && connectionTypeTargetingValues.length) {
            clearWifiTargeting();
        }
    }, [isDisabled, connectionTypeTargetingValues, clearWifiTargeting]);

    return (
        <FormField
            inputId="wifi-targeting"
            label={<FormattedMessage id="campaign.editor.targeting.wifi.title" defaultMessage="Connection Type" />}
            helpText={<WifiTargetingTooltip />}
            containerClass={commonStyles['input']}
        >
            <CheckboxGroup
                onSelectCheckbox={handleSelectValue}
                onUnselectCheckbox={clearWifiTargeting}
                selectedValues={connectionTypeTargetingValues}
            >
                <TextCheckbox
                    disabled={isDisabled}
                    value={CONNECTION_TYPES.WIFI}
                    title={
                        <>
                            <Tooltip
                                placement={TOOLTIP_POSITION.TOP_START}
                                className={classNameBuilder('tooltip', 'prioritized')}
                            >
                                <FormattedMessage
                                    id="campaign.editor.targeting.wifi.help.disabled"
                                    defaultMessage="Wi-Fi targeting is applicable when targeting Mobile or Tablets."
                                />
                            </Tooltip>
                            <FormattedMessage
                                id="campaign.editor.targeting.wifi.checkbox.title"
                                defaultMessage="Wi-Fi Only"
                            />
                        </>
                    }
                />
            </CheckboxGroup>
        </FormField>
    );
};

export default WifiTargeting;
