import React from 'react';
import classnames from 'classnames/bind';
import { defer, last } from 'lodash';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import { SELECTION_MODE } from './utils';
import styles from './tool.module.scss';

const classNameBuilder = classnames.bind(styles);

const findLastPointWithValidMask = points => {
    for (let i = points.length - 1; i >= 0; i--) {
        const point = points[i];
        if (point?.mask?.src) {
            return point;
        }
    }
    return null;
};

export const Tool = ({ onClick, handleMouseMove, onMouseOut: onMouseOutProp, points, clicks, image }) => {
    const { originalImage } = useCreativeStudioContext();

    const onMouseOut = e => {
        e.persist();
        defer(() => {
            onMouseOutProp(e);
        });
    };

    const maskPoint = findLastPointWithValidMask(points);
    const loading = last(points)?.loading;

    return (
        <>
            {image && (
                <img
                    alt="click-detection"
                    onMouseMove={handleMouseMove}
                    onMouseOut={onMouseOut}
                    onClick={onClick}
                    src={image.src}
                    className={classNameBuilder('full-width', { loading: originalImage.url === image.src && loading })}
                />
            )}
            {maskPoint && originalImage.url === image.src && (
                <img
                    alt="mask"
                    src={maskPoint.mask.src}
                    className={classNameBuilder('full-width', 'mask', { hovered: maskPoint.hovered })}
                />
            )}
            {points.length > 0 &&
                originalImage.url === image.src &&
                clicks.map(({ id, x, y, imageScale, clickType }) => (
                    <div
                        key={id}
                        data-testid={`${clickType === SELECTION_MODE.INCLUDE ? 'inclusion' : 'exclusion'}-point`}
                        aria-label="point"
                        className={classNameBuilder('point', { exclude: clickType === 0 })}
                        style={{ left: x / imageScale, top: y / imageScale }}
                    />
                ))}
        </>
    );
};
