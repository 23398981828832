import React from 'react';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { PRICING_MODEL } from '../../../../../config';
import styles from '../../commonEditor.module.scss';

const SubTextCPC = ({ hasError }) => {
    const {
        formAccount: { currency },
    } = useFormDataContext();
    const { value: pricingModel = PRICING_MODEL.CPC } = useFormFieldValue({ field: 'pricingModel' });
    const { value: bid = '' } = useFormFieldValue({ field: 'bid' });
    const { value: budgetType } = useFormFieldValue({ field: 'budget.type' });
    const { value: budgetValue } = useFormFieldValue({ field: 'budget.value' });

    const expectedClicks = Math.floor(budgetValue / bid);
    const displayExpectedClicks =
        pricingModel === PRICING_MODEL.CPC && !hasError && !!bid && !!budgetValue && !isNaN(expectedClicks);

    if (!displayExpectedClicks) {
        return null;
    }

    return (
        <FormattedMessage
            id={`campaign.editor.bid.click.estimate.${budgetType}`}
            defaultValue=""
            values={{
                budget: (
                    <span className={styles['bold-text']}>
                        <FormattedNumber value={budgetValue} variant="currency" currency={currency} />
                    </span>
                ),
                numberOfClicks: (
                    <span className={styles['bold-text']}>
                        <FormattedNumber value={expectedClicks} />
                    </span>
                ),
            }}
        />
    );
};

export default SubTextCPC;
