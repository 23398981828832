/**
 * Created by oreuveni
 * Date: 2019-04-18
 * Time: 13:15
 */
const AD_TYPES = {
    PUBLISHER: 'PUBLISHER',
    BRANDED: 'BRANDED',
    AD_HEAVY: 'AD_HEAVY',
    CTA: 'CTA',
    AGGRESSIVE_CTA: 'AGGRESSIVE_CTA',
    AGGRESSIVE_AFFILIATE: 'AGGRESSIVE_AFFILIATE',
    DIRECT_AD: 'DIRECT_AD',
};

export default AD_TYPES;
