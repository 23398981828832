import { useMemo, useRef } from 'react';
import uuid from 'uuid/v1';

// This is in render sync effect
export const useSyncEffect = (callback, dependencies) => {
    const hashRef = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hash = useMemo(() => uuid(), dependencies);

    if (hashRef.current !== hash) {
        callback();
    }

    hashRef.current = hash;
};
