import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import isNil from 'lodash/isNil';
import { useAvailableItems } from 'hooks/useAvailableItems';
import { authentificationSelector } from 'modules/taboola-common-frontend-modules/authentication/selectors';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { LANGUAGE, defaultLocale, allLocales } from '../config/messages';

export const localeDeserializer = val => {
    if (isNil(val)) {
        return val;
    }

    return LANGUAGE[val];
};

export const LOCALE = 'locale';

export const useLocaleParam = queryParamHookFactory(LOCALE, {
    deserializer: localeDeserializer,
    defaultValue: defaultLocale,
    persist: PERSISTENCE_TYPE.LOCAL_STORAGE,
});

export const useLocale = () => {
    const [localeParam, setLocaleParam] = useLocaleParam();
    const permittedLanguages = useAvailableItems(allLocales);
    const isAuthenticated = useSelector(authentificationSelector);
    const locale = useMemo(() => {
        const permittedLanguagesMap = keyBy(permittedLanguages, 'id');
        if (!isAuthenticated) {
            return defaultLocale;
        }
        return permittedLanguagesMap[localeParam] ? localeParam : defaultLocale;
    }, [isAuthenticated, localeParam, permittedLanguages]);

    const setLocale = useCallback(
        async currentLocale => {
            if (currentLocale === locale) {
                return;
            }
            await setLocaleParam(currentLocale);
        },
        [setLocaleParam, locale]
    );

    return [locale, setLocale];
};
