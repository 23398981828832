import React from 'react';
import { CloseIcon } from 'tuui';
import { pxToRem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { usePromptHelper } from './hooks/usePromptHelper';
import styles from './promptHelperTag.module.scss';

export const PromptHelperTag = ({ category, option }) => {
    const { clearPromptHelperFormField } = usePromptHelper();
    return (
        <div className={styles['tag']}>
            <FormattedMessage id={`creative.editor.tab.aiGenerated.fromPrompt.helper.options.${option}`} />
            <span
                className={styles['delete-icon']}
                onClick={() => {
                    clearPromptHelperFormField(category);
                }}
            >
                <CloseIcon sx={{ fontSize: pxToRem('10px') }} />
            </span>
        </div>
    );
};
