import {
    FETCH_BRAND_SAFETY_CATEGORIES_ERROR,
    FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS,
    REQUEST_FETCH_BRAND_SAFETY_CATEGORIES,
} from './actionTypes';

export const requestFetchBrandSafetyCategories = brandSafetyType => ({
    type: REQUEST_FETCH_BRAND_SAFETY_CATEGORIES,
    meta: { provider: brandSafetyType },
});

export const fetchBrandSafetyCategoriesSuccess = (categories, brandSafetyType) => ({
    type: FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS,
    payload: categories,
    meta: { provider: brandSafetyType },
});

export const fetchBrandSafetyCategoriesError = (error, brandSafetyType) => ({
    type: FETCH_BRAND_SAFETY_CATEGORIES_ERROR,
    payload: error,
    meta: { provider: brandSafetyType },
});
