import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { spendingLimitValidations } from 'modules/campaigns/config/validations/campaign';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import selectedAccountSelector from '../../../../../../../selectors/selectedAccount';
import validationFunctions from '../../../../../../taboola-common-frontend-modules/validations/services/validationFunctions';
import { SPENDING_LIMITS } from '../../../config';
import { NUMBER_OF_DAYS_IN_MONTH } from '../hooks/useBidValidationRatio';
import { useBudgetFormFieldValue } from '../hooks/useBudgetFormFieldValue';
import { useSpendingLimitValidationRatio } from '../hooks/useSpendingLimitValidationRatio';

export const useSpendingLimitValidations = (type, data) => {
    const { currency } = useSelector(selectedAccountSelector);
    const { value: budget = data?.budget } = useBudgetFormFieldValue();
    const spendingLimitRatio = useSpendingLimitValidationRatio();
    const spendingLimit = budget?.spendingLimit?.value;
    const budgetValue = budget?.value;

    const validationDependencies = useMemo(
        () => ({
            currency,
            budget,
            ...data,
            type: <FormattedMessage id={`campaign.editor.spending.limit.${type}`} />,
            spendingLimit,
            budgetValue,
            monthlyBudgetValue: NUMBER_OF_DAYS_IN_MONTH * budgetValue - 1,
            minSpendingLimit: budgetValue && budgetValue * spendingLimitRatio,
        }),
        [currency, budget, data, type, spendingLimit, budgetValue, spendingLimitRatio]
    );

    return {
        field: 'budget.spendingLimit.value',
        validationDependencies: { data: validationDependencies },
        validations:
            type === SPENDING_LIMITS.NONE
                ? [
                      {
                          validationFn: validationFunctions.isEmpty,
                          messageId: 'validations.error.campaign.spendingLimit.notEmpty',
                          defaultMessage: 'Remove a {type}',
                      },
                  ]
                : spendingLimitValidations,
    };
};
