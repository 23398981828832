import React, { useMemo, useCallback } from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { TARGETING_TYPES } from '../../config';
import { useSegmentTypeLimits } from '../Tree/hooks/useSegmentTypeLimits';
import {
    AddMarketplaceAudiencesTargetingButton,
    MarketplaceAudiencesTargeting,
    MarketplaceAudiencesTargetingDelimiter,
} from './MarketplaceAudiencesTargeting';
import { MarketplaceAudiencesTargetingGroup } from './MarketplaceAudiencesTargetingGroup/MarketplaceAudiencesTargetingGroup';
import {
    useIsMarketplaceAudiencesAndLogicEnabled,
    useMarketplaceAudienceSegmentTypeCounts,
    useMarketplaceAudienceTargetingCollection,
} from './hooks';
import styles from './marketplaceAudiences.module.scss';

export const MarketplaceAudiences = ({ selectedAccountId }) => {
    const { marketplaceAudienceTargetingCollection, onAddTargeting, onDeleteTargeting } =
        useMarketplaceAudienceTargetingCollection();
    const handleAddTargeting = useCallback(() => onAddTargeting(TARGETING_TYPES.INCLUDE), [onAddTargeting]);

    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();

    const { maxTargetings } = useSegmentTypeLimits();

    const { setMarketplaceAudienceSegmentTypeByIndex, marketplaceAudienceSegmentTypeCounts } =
        useMarketplaceAudienceSegmentTypeCounts(marketplaceAudienceTargetingCollection);

    const hasExcludeTargeting = useMemo(
        () => marketplaceAudienceTargetingCollection.some(targeting => targeting.type === TARGETING_TYPES.EXCLUDE),
        [marketplaceAudienceTargetingCollection]
    );

    return (
        <div className={styles['container']}>
            {isAndLogicEnabled ? (
                <>
                    {marketplaceAudienceTargetingCollection.map((targeting, index) => {
                        const { id } = targeting;
                        const handleDeleteTargetingByIndex = () => onDeleteTargeting(index);
                        const key = isNil(id) ? index : id;

                        return (
                            <>
                                {index > 0 && (
                                    <MarketplaceAudiencesTargetingDelimiter
                                        key={`delimiter-${key}`}
                                        onDelete={handleDeleteTargetingByIndex}
                                    />
                                )}
                                <MarketplaceAudiencesTargetingGroup
                                    key={key}
                                    index={index}
                                    selectedAccountId={selectedAccountId}
                                    marketplaceAudienceSegmentTypeCount={marketplaceAudienceSegmentTypeCounts[index]}
                                    setMarketplaceAudienceSegmentTypeByIndex={setMarketplaceAudienceSegmentTypeByIndex}
                                    shouldDisableExcludeType={hasExcludeTargeting}
                                />
                            </>
                        );
                    })}
                    {marketplaceAudienceTargetingCollection.length < maxTargetings && (
                        <AddMarketplaceAudiencesTargetingButton onClick={handleAddTargeting} />
                    )}
                </>
            ) : (
                <MarketplaceAudiencesTargeting selectedAccountId={selectedAccountId} />
            )}
        </div>
    );
};

MarketplaceAudiences.propTypes = {
    selectedAccountId: PropTypes.string,
};
