import { AUDIENCE_STATUS_KEYS } from 'modules/audiences/config';
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_KEYS, CREATIVE_STATUS_FILTER_KEYS } from 'modules/campaigns/config';
import { PERFORMANCE_STATUS_FILTER_VALUES } from 'modules/campaigns/config/creativeConsts';
import { DEMAND_TYPE, TOP_RESULTS_TYPE } from 'modules/campaigns/constants';
import { PLATFORMS_KEYS } from 'modules/campaigns/modules/common-campaign-form';
import {
    CAMPAIGN_CONTENT_TYPE,
    FILTERS_CONTENT_SAFETY,
    RANGE_FILTERS_OPERATORS,
} from 'modules/campaigns/modules/common-campaign-form/components/Profile/utils/constants';
import {
    INCLUDE_IN_TOTAL_FILTER_VALUES,
    INCLUDE_IN_TOTAL_VALUE_FILTER_VALUES,
    DEFINED_CONVERSIONS_VALUE_FILTER_VALUES,
} from 'modules/tracking/config';
import { CAMPAIGN_GROUP_STATUS_KEYS } from '../../../../config/campaignsConsts';
import AUDIENCE_DATA_TYPE_MODE from '../../../../constants/audienceDataTypeMode';
import BID_STRATEGIES from '../../../common-campaign-form/config/bidStrategies';
import { FILTER_TYPE } from './filterType';

export { FILTER_TYPE };

const { PLATFORM, BROWSER, COUNTRY, SITE, OS_FAMILY, OS_VERSION } = FILTER_TYPE;

export const RESTRICTED_TYPES = {
    [PLATFORM]: [SITE, COUNTRY, BROWSER, OS_FAMILY, OS_VERSION],
    [COUNTRY]: [SITE, PLATFORM],
    [SITE]: [COUNTRY, PLATFORM],
    [BROWSER]: [PLATFORM],
    [OS_FAMILY]: [PLATFORM],
    [OS_VERSION]: [PLATFORM],
};

export const ARGUMENT_NAME = {
    ACCOUNT_ID: 'accountId',
    SEARCH_INPUT: 'searchInput',
    PAGE: 'page',
    PAGE_SIZE: 'pageSize',
    QUERY_PARAMS: 'queryParams',
};

const rangeFiltersBaseParams = {
    messageIdPrefix: 'app.campaigns.reports.filters.range_based',
    operator: Object.values(RANGE_FILTERS_OPERATORS),
};

export const STATIC_FILTER_TYPE_MAP = {
    [FILTER_TYPE.CAMPAIGN_STATUS]: {
        messageIdPrefix: 'app.campaigns.campaign.status',
        tooltipMessageIdPrefix: 'app.campaigns.campaign.status.tooltip',
        values: CAMPAIGN_STATUS_KEYS.filter(key => key !== CAMPAIGN_STATUS.FROZEN),
    },
    [FILTER_TYPE.CAMPAIGN_GROUP_STATUS]: {
        messageIdPrefix: 'app.campaigns.campaign.group.status',
        tooltipMessageIdPrefix: 'app.campaigns.campaign.group.status.tooltip',
        values: CAMPAIGN_GROUP_STATUS_KEYS,
    },
    [FILTER_TYPE.SITE_BLOCKED_STATUS]: {
        messageIdPrefix: 'app.campaigns.site.blocked.status',
        values: ['true', 'false'],
    },
    [FILTER_TYPE.CAMPAIGN_ITEM_STATUS]: {
        messageIdPrefix: 'app.campaigns.creative.status',
        tooltipMessageIdPrefix: 'app.campaigns.creative.status.tooltip',
        values: CREATIVE_STATUS_FILTER_KEYS,
    },
    [FILTER_TYPE.CAMPAIGN_ITEM_PERFORMANCE_STATUS]: {
        messageIdPrefix: 'app.campaigns.creative.performanceStatus',
        tooltipMessageIdPrefix: 'app.campaigns.creative.performanceStatus.tooltip',
        values: PERFORMANCE_STATUS_FILTER_VALUES,
    },
    [FILTER_TYPE.PLATFORM]: {
        messageIdPrefix: 'app.platform.code',
        values: PLATFORMS_KEYS,
    },
    [FILTER_TYPE.CONTENT_SAFETY]: {
        messageIdPrefix: 'campaign.editor.content.safety',
        values: Object.values(FILTERS_CONTENT_SAFETY),
    },
    [FILTER_TYPE.CONTENT_TYPE]: {
        messageIdPrefix: 'campaign.editor.content.type',
        values: Object.values(CAMPAIGN_CONTENT_TYPE),
    },
    [FILTER_TYPE.DEMAND_TYPE]: {
        messageIdPrefix: 'app.campaigns.campaign.demand.type',
        values: [DEMAND_TYPE.PMP_DEAL, DEMAND_TYPE.RTB_OPEN_EXCHANGE],
    },
    [FILTER_TYPE.TOP_RESULTS]: {
        messageIdPrefix: 'report.topResults',
        values: Object.values(TOP_RESULTS_TYPE),
    },
    [FILTER_TYPE.AUDIENCE_DATA_TYPE_MODE]: {
        messageIdPrefix: 'report.audienceDataTypeMode',
        values: Object.values(AUDIENCE_DATA_TYPE_MODE),
    },
    [FILTER_TYPE.AUDIENCE_STATUS]: {
        messageIdPrefix: 'report.audienceStatus',
        values: AUDIENCE_STATUS_KEYS,
    },
    [FILTER_TYPE.CONVERSION_INCLUDE_IN_TOTAL]: {
        messageIdPrefix: 'report.conversion.includeInTotal',
        values: INCLUDE_IN_TOTAL_FILTER_VALUES,
    },
    [FILTER_TYPE.CONVERSION_INCLUDE_IN_TOTAL_VALUE]: {
        messageIdPrefix: 'report.conversion.includeInTotalValue',
        values: INCLUDE_IN_TOTAL_VALUE_FILTER_VALUES,
    },
    [FILTER_TYPE.DEFINED_CONVERSIONS]: {
        messageIdPrefix: 'report.active.events.defined.conversions',
        values: DEFINED_CONVERSIONS_VALUE_FILTER_VALUES,
    },
    [FILTER_TYPE.CLICKS]: {
        ...rangeFiltersBaseParams,
    },
    [FILTER_TYPE.SPENT]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.VISIBLE_IMPRESSIONS]: {
        ...rangeFiltersBaseParams,
    },
    [FILTER_TYPE.IMPRESSIONS]: {
        ...rangeFiltersBaseParams,
    },
    [FILTER_TYPE.VCTR]: {
        ...rangeFiltersBaseParams,
        isPercent: true,
    },
    [FILTER_TYPE.CTR]: {
        ...rangeFiltersBaseParams,
        isPercent: true,
    },
    [FILTER_TYPE.AVG_CPC]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.CVR]: {
        ...rangeFiltersBaseParams,
        isPercent: true,
    },
    [FILTER_TYPE.ACTIONS_CONVERSIONS]: {
        ...rangeFiltersBaseParams,
    },
    [FILTER_TYPE.CPA]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.VCPM]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.CPM]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.CONVERSIONS]: {
        ...rangeFiltersBaseParams,
        isCurrency: true,
    },
    [FILTER_TYPE.ROAS]: {
        ...rangeFiltersBaseParams,
        isPercent: true,
    },
    [FILTER_TYPE.BID_STRATEGY]: {
        messageIdPrefix: 'app.campaigns.reports.grid.campaign_history.column.value.bidStrategy',
        values: Object.keys(BID_STRATEGIES).filter(
            key => key !== BID_STRATEGIES.TARGET_ROAS && key !== BID_STRATEGIES.MAX_REVENUE
        ),
    },
};
