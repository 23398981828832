import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import styles from './inputEditField.module.scss';

const InputEditField = ({ autoFocus, onChange, ...restProps }) => {
    const inputRef = createRef();

    useEffect(() => {
        if (autoFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus, inputRef]);

    return <Input ref={inputRef} onChange={e => onChange(e.target.value)} {...restProps} />;
};

InputEditField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    /* Automatically focus the input on mount */
    autoFocus: PropTypes.bool,
    ...Input.propTypes,
};

InputEditField.defaultProps = {
    autoFocus: true,
};

export { InputEditField };
export default withIndication(InputEditField, styles['input-edit-field']);
