import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { DesktopWindowsOutlinedIcon, SmartphoneOutlinedIcon } from 'tuui';
import { VIDEO_PREVIEW_MODE } from 'modules/campaigns/modules/common-campaign-form/config/VideoPreviewMode';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './VideoPreviewToolbar.module.scss';

const classNameBuilder = classnames.bind(styles);

export const VideoPreviewToolbar = ({ isDisabled, onSelect, selected, isDesktopAvailable }) => {
    const selectMobile = () => !isDisabled && onSelect(VIDEO_PREVIEW_MODE.MOBILE);
    const selectDesktop = () => !isDisabled && onSelect(VIDEO_PREVIEW_MODE.DESKTOP);
    const isDesktopSelected = useMemo(() => selected === VIDEO_PREVIEW_MODE.DESKTOP, [selected]);

    return (
        <div className={styles['container']}>
            <div className={styles['label']}>
                <FormattedMessage id="creative.preview.placement.toolbar.label" defaultMessage="Placements" />
            </div>
            <div
                className={classNameBuilder('button-container', {
                    disabled: isDisabled || !isDesktopAvailable,
                })}
            >
                <div
                    className={classNameBuilder('toggle-button', 'left', {
                        selected: isDesktopSelected,
                    })}
                    onClick={selectDesktop}
                >
                    <DesktopWindowsOutlinedIcon />
                </div>
                <div
                    className={classNameBuilder('toggle-button', 'right', {
                        selected: !isDesktopSelected,
                    })}
                    onClick={selectMobile}
                >
                    <SmartphoneOutlinedIcon />
                </div>
            </div>
        </div>
    );
};
