'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactTruncate = require('react-truncate');

var _reactTruncate2 = _interopRequireDefault(_reactTruncate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var Truncate = function Truncate(props) {
    var children = props.children,
        rest = _objectWithoutProperties(props, ['children']);

    return _react2.default.createElement(
        _reactTruncate2.default,
        rest,
        children
    );
};

Truncate.propTypes = {
    /** className to apply on the root element */
    className: _propTypes2.default.string,
    /** Specifies how many lines of text should be preserved until it gets truncated.
        false and any integer < 1 will result in the text not getting clipped at all */
    lines: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.bool]),
    /** An ellipsis that is added to the end of the text in case it is truncated */
    ellipsis: _propTypes2.default.node,
    /** If true, whitespace will be removed from before the ellipsis (e.g. words ... will become words... instead) */
    trimWhitespace: _propTypes2.default.bool,
    /** If not 0, the calculation of the content will be based on this number */
    width: _propTypes2.default.number,
    /** Gets invoked on each render. Gets called with true when text got truncated and ellipsis was injected, and with false otherwise */
    onTruncate: _propTypes2.default.func,
    /** The text to be truncated. Anything that can be evaluated as text */
    children: _propTypes2.default.node
};

Truncate.defaultProps = {
    className: '',
    lines: 2,
    ellipsis: '...',
    trimWhitespace: true,
    width: 0
};

exports.default = Truncate;