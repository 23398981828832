import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { getCountryDMAs } from '../../../../../services/api/geoTargetingApi';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import transfromSubCountryFromGW from '../../../services/transformers/subCountryTransformer';
import { requestFetchDMAs, fetchDMAsSuccess, fetchDMAsError } from '../actions';

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.geo.targeting.DMAs.fetch.error"
            defaultMessage="We were not able to fetch list of DMAs"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const fetchDMAs = countryCode => dispatch => {
    dispatch(requestFetchDMAs());

    getCountryDMAs(countryCode)
        .then(rawList => transfromSubCountryFromGW(rawList))
        .then(listOfDMAs => {
            dispatch(fetchDMAsSuccess(listOfDMAs));
        })
        .catch(error => {
            dispatch(addIndication(errorIndication));
            dispatch(fetchDMAsError(error));
        });
};

export default fetchDMAs;
