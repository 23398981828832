import React from 'react';
import PropTypes from 'prop-types';
import { useBudgetValidations } from 'modules/campaigns/modules/common-campaign-form/components/Budget/hooks/useBudgetValidations';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { useCampaignGroupExtensionEnabled } from '../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { useSpendingLimitOptions } from '../../../common-campaign-form/components/Budget/hooks/useSpendingLimitOptions';
import { useCampaignBudgetRestrictions } from '../../../common-campaign-form/components/BudgetSectionSponsored/hooks/useCampaignBudgetRestrictions';
import SimpleCurrencyCellEditor from './SimpleCurrencyCellEditor';

const BudgetCellEditor = props => {
    const isNoneSpendingLimitDisplayed = useSpendingLimitOptions();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const { campaignGroup } = useCampaignBudgetRestrictions();
    const { data } = props;
    const validations = useBudgetValidations(data);
    return (
        <SimpleCurrencyCellEditor
            {...props}
            {...validations}
            formFieldDependencies={{ isNoneSpendingLimitDisplayed, campaignGroupExtensionEnabled, campaignGroup }}
        />
    );
};

BudgetCellEditor.propTypes = {
    /** The grid's context object, passed in by the grid */
    context: PropTypes.object,
    /** The row data object */
    data: PropTypes.object,
};

BudgetCellEditor.defeaultProps = {
    data: {
        budget: { spendingLimit: {} },
    },
};

export default withGridCellFormDataProvider(BudgetCellEditor);
