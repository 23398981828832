import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import OutlineEditableText from '../../../campaigns/components/OutlineEditableText';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../taboola-common-frontend-modules/validations';
import useValidationContext from '../../../taboola-common-frontend-modules/validations/hooks/useValidationContext';
import nameValidations from '../../validations/nameValidations';
import AudienceNameFieldTooltip from './AudienceNameFieldTooltip';
import styles from './audienceNameField.module.scss';

const EditableTextWithValidations = withInputValidations(OutlineEditableText, nameValidations, {
    onChange: { validateEventValue: true, eventValueGetter: e => e },
});

export const AudienceNameField = ({ fieldName = 'audience', metricName = 'AudienceName' }) => {
    const { value: name, onChange: onNameChange } = useFormFieldValue({ field: 'name', throttleValue: true });

    const validationContext = useValidationContext({
        prefix: fieldName,
        maxLen: 100,
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `audience.editor.${fieldName}.name.placeholder`,
        defaultMessage: 'Enter name...',
    });

    return (
        <FormField
            dataTestId="audience-name-field"
            inputId="audience-name-input"
            label={<FormattedMessage id={`audience.editor.${fieldName}.name.title`} defaultMessage="Audience Name" />}
            helpText={<AudienceNameFieldTooltip fieldName={fieldName} />}
        >
            <EditableTextWithValidations
                id="name"
                className={styles['editable-text']}
                value={name}
                onChange={onNameChange}
                placeholder={placeholder}
                validationContext={validationContext}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component={metricName}
            />
        </FormField>
    );
};

export default AudienceNameField;
export const SegmentNameField = () => <AudienceNameField fieldName="segment" metricName="SegmentName" />;
