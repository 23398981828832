import React, { createContext, useContext, useMemo, useEffect, useCallback, useRef } from 'react';
import { noop, pick } from 'lodash';
import { useWizardContext } from 'taboola-ultimate-ui';
import { useValidationContext } from '../ValidationContext';
import { useValidationState } from '../hooks/useValidationState';

const ValidationSectionContext = createContext({
    register: noop,
    unregister: noop,
    sectionState: {},
});

export function useValidationSectionState() {
    const { sectionState } = useContext(ValidationSectionContext);
    return sectionState;
}

export function useValidationSectionRegistration(fieldId) {
    const { step } = useWizardContext();
    const { validationService } = useValidationContext();
    const { register, unregister } = useContext(ValidationSectionContext);

    useEffect(() => {
        if (!validationService) {
            return;
        }
        const unlisten = validationService.onRegistered(fieldId, () => {
            register(fieldId);
            validationService.annotateValidation(fieldId, { step });
        });
        return () => {
            unlisten();
            unregister(fieldId);
        };
    }, [fieldId, register, unregister, step, validationService]);
}

export function ValidationSection({ children }) {
    const validationIds = useRef(new Set());
    const register = useCallback(fieldId => {
        validationIds.current.add(fieldId);
    }, []);
    const unregister = useCallback(fieldId => {
        validationIds.current.delete(fieldId);
    }, []);
    const validationState = useValidationState();
    const sectionState = useMemo(
        () => pick(validationState, Array.from(validationIds.current)),
        [validationIds, validationState]
    );

    const value = useMemo(() => ({ register, unregister, sectionState }), [register, unregister, sectionState]);

    return <ValidationSectionContext.Provider value={value}>{children}</ValidationSectionContext.Provider>;
}

export function withRegisterOnMount(InnerComponent) {
    return props => {
        useValidationSectionRegistration(props.validationId);

        return <InnerComponent {...props} />;
    };
}
