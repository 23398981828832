import { get } from 'lodash';
import { COUNTRY_TARGETING_LEVELS, TARGETING_TYPES } from '../../modules/common-campaign-form/config';
import { getDefaultTargeting } from '../utils/getDefaultTargeting';

// the only valid inputs for the GW are the following
//      1. type: ALL values: null
//      2. type: INCLUDE values: [length at least 1]
//      3. type: EXCLUDE values: [length at least 1]
// this func modifies the input to ensure no invalid states are sent
const transformTargetingObjToGW = (targeting = {}) => {
    const { type = TARGETING_TYPES.ALL, values = [] } = targeting;

    if (values.length === 0 || type === TARGETING_TYPES.ALL) {
        return {
            type: TARGETING_TYPES.ALL,
            values: null,
        };
    }

    return { type, values };
};

const parseGeoTargeting = ({
    countryTargetingLevel,
    countryTargeting,
    regionTargeting,
    cityTargeting,
    postalCodeTargeting,
    DMATargeting,
}) => {
    const parsedTargeting = {};

    parsedTargeting.countryTargeting = transformTargetingObjToGW(countryTargeting);

    if (countryTargetingLevel === COUNTRY_TARGETING_LEVELS.REGIONS) {
        parsedTargeting.regionCountryTargeting = transformTargetingObjToGW(regionTargeting);
    } else if (countryTargetingLevel === COUNTRY_TARGETING_LEVELS.DMAS) {
        parsedTargeting.dmaCountryTargeting = transformTargetingObjToGW(DMATargeting);
    } else if (countryTargetingLevel === COUNTRY_TARGETING_LEVELS.CITIES) {
        parsedTargeting.cityCountryTargeting = transformTargetingObjToGW(cityTargeting);
    } else if (countryTargetingLevel === COUNTRY_TARGETING_LEVELS.ZIPCODES) {
        parsedTargeting.postalCodeTargeting = transformTargetingObjToGW(postalCodeTargeting);
    }

    return parsedTargeting;
};

const composeGeoTargeting = ({
    countryTargeting,
    regionCountryTargeting,
    dmaCountryTargeting,
    cityCountryTargeting,
    postalCodeTargeting,
}) => {
    const geoTargeting = {
        countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
        countryTargeting: getDefaultTargeting(countryTargeting),
        regionTargeting: getDefaultTargeting(regionCountryTargeting),
        cityTargeting: getDefaultTargeting(cityCountryTargeting),
        DMATargeting: getDefaultTargeting(dmaCountryTargeting),
        postalCodeTargeting: getDefaultTargeting(postalCodeTargeting),
    };

    const isLevelSelected = levelObj => !!get(levelObj, 'values.length');

    if (isLevelSelected(regionCountryTargeting)) {
        geoTargeting.countryTargetingLevel = COUNTRY_TARGETING_LEVELS.REGIONS;
    } else if (isLevelSelected(dmaCountryTargeting)) {
        geoTargeting.countryTargetingLevel = COUNTRY_TARGETING_LEVELS.DMAS;
    } else if (isLevelSelected(cityCountryTargeting)) {
        geoTargeting.countryTargetingLevel = COUNTRY_TARGETING_LEVELS.CITIES;
    } else if (isLevelSelected(postalCodeTargeting)) {
        geoTargeting.countryTargetingLevel = COUNTRY_TARGETING_LEVELS.ZIPCODES;
    }

    return geoTargeting;
};

const transformGeoTargetingToGW = campaign => {
    const { campaignTargeting } = campaign;
    const mappedCampaign = campaignTargeting && campaignTargeting.geoTargeting ? { ...campaign } : campaign;

    if (mappedCampaign !== campaign) {
        const { geoTargeting } = campaignTargeting;

        mappedCampaign.campaignTargeting = {
            ...campaignTargeting,
            ...parseGeoTargeting(geoTargeting),
        };
        delete mappedCampaign.campaignTargeting.geoTargeting;
    }

    return mappedCampaign;
};

const transformGeoTargetingFromGW = campaign => {
    const mappedCampaign = { ...campaign };

    mappedCampaign.campaignTargeting = {
        ...mappedCampaign.campaignTargeting,
        geoTargeting: composeGeoTargeting(mappedCampaign.campaignTargeting || {}),
    };

    return mappedCampaign;
};

export { transformGeoTargetingFromGW, transformGeoTargetingToGW };
