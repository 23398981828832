import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { StaticIndications } from '../../taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from '../../taboola-common-frontend-modules/formData';
import AudienceFormBreadcrumbs from '../components/AudienceFormBreadcrumbs/AudienceFormBreadcrumbs';
import PixelBasedAudiencePropertiesSection from '../components/PixelBasedAudiencePropertiesSection/PixelBasedAudiencePropertiesSection';
import PixelBasedAudienceSetupDetailsLabel from '../components/PixelBasedAudienceSetupDetailsSection/PixelBasedAudienceSetupDetailsLabel';
import PixelBasedAudienceSetupDetailsSection from '../components/PixelBasedAudienceSetupDetailsSection/PixelBasedAudienceSetupDetailsSection';
import PixelBasedAudienceSetupSection from '../components/PixelBasedAudienceSetupSection/PixelBasedAudienceSetupSection';
import pixelBasedAudienceInitialValues from '../config/pixelBasedAudienceInitialValues';
import { createAudience } from '../flows';
import useAudienceCreatorState from '../hooks/useAudienceCreatorState';
import styleConsts from '../../../globalStyle/styleConsts.module.scss';
import styles from './audiencesCreatorPage.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const PixelBasedAudiencesCreatorPage = ({ onCancel }) => {
    const dispatch = useDispatch();
    const reportId = REPORT_TYPE.PIXEL_BASED;

    const submitAudience = useCallback(
        (audience, accountId) => dispatch(createAudience(accountId, audience, reportId)),
        [dispatch, reportId]
    );
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'audience.creator.new.pixel.based.audience',
        defaultMessage: 'New Pixel Audience',
    });
    const { step, setStep, submit } = useAudienceCreatorState({
        submitAudience,
    });

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="audience.creator.audience.settings.step.label"
                            defaultMessage="Audience Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <AudienceFormBreadcrumbs reportId={reportId} title={title} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="audience.creator.audience.setup.step.label"
                                    defaultMessage="Audience Setup"
                                />
                            }
                            id="audience-setup"
                        >
                            <PixelBasedAudienceSetupSection />
                        </WizardStep>
                        <WizardStep
                            label={<PixelBasedAudienceSetupDetailsLabel />}
                            id="pixel-based-audience-setup-details"
                        >
                            <PixelBasedAudienceSetupDetailsSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="audience.creator.audience.properties.step.label"
                                    defaultMessage="Audience Properties"
                                />
                            }
                            id="audience-properties"
                        >
                            <PixelBasedAudiencePropertiesSection />
                            <div className={styles['buttons-wrapper']}>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={submit}
                                    onCancel={onCancel}
                                    submitLabel={<FormattedMessage id="app.actionButtons.create.audience" />}
                                    submitIcon={<ChevronRightOutlinedIcon />}
                                    submitDataMetricsComponent="NextButton"
                                    cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                />
                            </div>
                        </WizardStep>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const PixelBasedAudiencesCreatorPageWithFormDataProvider = withFormDataProvider(PixelBasedAudiencesCreatorPage, {
    defaultValuesConfig: pixelBasedAudienceInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { PixelBasedAudiencesCreatorPageWithFormDataProvider as PixelBasedAudiencesCreatorPage };
