import { useCallback } from 'react';
import { Workbook } from 'exceljs';
import { addReportToWorkbook } from '../services/addReportToWorkbook';
import { buildExcelBulkTemplate } from '../services/buildExcelBulkTemplate';
import { convertWorkbookToBlob } from '../utils/convertWorkbookToBlob';
import { addDictionarySheets } from '../utils/dictionaryUtils';
import { useExcelBulkUploadConfigs } from '../utils/useExcelBulkUploadConfigs';
import { useAvailableDictionarySheets } from './useAvailableDictionarySheets';

export const useCreateSubmissionReport = ({ commonMetadata, dictionaryData }) => {
    const { formatMessage } = commonMetadata;
    const availableDictionarySheets = useAvailableDictionarySheets();
    const { isFullConfigAvailable, entityToRowLimitMap } = useExcelBulkUploadConfigs();

    const createReportFile = useCallback(
        async (excelConfig, entityDataMap) => {
            const workbook = await buildExcelBulkTemplate({
                workbook: new Workbook(),
                excelConfig,
                entityDataMap,
                metadata: commonMetadata,
                dictionaryData,
                isFullConfigAvailable,
                entityToRowLimitMap,
            });
            addDictionarySheets({
                workbook,
                ...commonMetadata,
                dictionaryData,
                dictionarySheets: availableDictionarySheets,
            });

            excelConfig.entityList.forEach(entityConfig => {
                const entity = entityConfig.entity;
                addReportToWorkbook({
                    workbook,
                    formatMessage,
                    responseData: entityDataMap[entity],
                    entityConfig,
                });
            });

            const blob = await convertWorkbookToBlob(workbook);
            return blob;
        },
        [
            commonMetadata,
            dictionaryData,
            entityToRowLimitMap,
            isFullConfigAvailable,
            availableDictionarySheets,
            formatMessage,
        ]
    );

    return createReportFile;
};
