import React from 'react';
import { useSelector } from 'react-redux';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { shouldCrawlOneByOneUrlsSelector } from '../../selectors/selectors';
import { OneByOneLoadingOverlay } from '../index';

export const OneByOneLoading = () => {
    const { value: oneByOneData, onChange: setOneByOneData } = useFormFieldValue({ field: 'oneByOneData' });
    const { urlsCrawled, numUrls } = oneByOneData;
    const shouldCrawlOneByOneUrls = useSelector(shouldCrawlOneByOneUrlsSelector);

    const cancelOneByOneCreatives = () => {
        updateOneByOneData({ urlsCrawled: 0 });
    };

    const updateOneByOneData = newData =>
        setOneByOneData(oneByOneData => {
            return { ...oneByOneData, ...newData };
        });

    return (
        <OneByOneLoadingOverlay
            currentUrlIndex={urlsCrawled}
            totalUrls={numUrls}
            shouldCrawlUrls={shouldCrawlOneByOneUrls}
            onCancel={cancelOneByOneCreatives}
        />
    );
};
