import {
    REQUEST_FETCH_SYNDICATOR_RECOMMENDATION_COUNTS,
    FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS,
    FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_ERROR,
} from './actionTypes';

export const requestFetchSyndicatorRecommendationCounts = () => ({
    type: REQUEST_FETCH_SYNDICATOR_RECOMMENDATION_COUNTS,
});

export const fetchSyndicatorRecommendationCountsSuccess = recommendationsCount => ({
    type: FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_SUCCESS,
    payload: recommendationsCount,
});

export const fetchSyndicatorRecommendationCountsError = error => ({
    type: FETCH_SYNDICATOR_RECOMMENDATION_COUNTS_ERROR,
    payload: error,
});
