import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

const campaignGroupsLimit = 100;

export const scheduledReportsCampaignGroupsValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'app.scheduled.reports.campaign.groups.validations.error.empty',
        defaultMessage: 'Select at least one campaign group',
    },
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: campaignGroupsLimit },
        messageId: 'app.scheduled.reports.campaign.groups.validations.error.limit',
        defaultMessage: 'Campaign Groups limit exceeds maximum allowable limit of {max}',
    },
];
