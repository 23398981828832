export const FILTER_TYPE = {
    CAMPAIGN_STATUS: 'campaignStatus',
    CAMPAIGN_GROUP_STATUS: 'campaignGroupStatus',
    CAMPAIGN_ITEM_STATUS: 'campaignItemStatus',
    CAMPAIGN_ITEM_PERFORMANCE_STATUS: 'performanceStatus',
    SITE_BLOCKED_STATUS: 'isPublisherBlocked',
    PLATFORM: 'platform',
    BROWSER: 'browser',
    OS_FAMILY: 'os_family',
    OS_VERSION: 'os_version',
    COUNTRY: 'country',
    SITE: 'site',
    SITE_ID: 'siteId',
    CAMPAIGNS_GROUP: 'campaignsGroupId',
    ITEMS: 'items',

    ACCOUNT_MANAGER: 'media_am',
    ACCOUNT_MANAGER_GROUP: 'media_am_department_id',
    SALES_GROUP: 'media_sales_department_id',
    SALES_PERSON: 'media_sales',
    TARGET_PAGE_TYPE: 'adType',
    CONTENT_SAFETY: 'content_safety',
    CONTENT_TYPE: 'content_type',
    CATEGORY_TYPE: 'category',
    IAB_CATEGORY_TYPE: 'iab_category',
    LANGUAGE: 'language',
    PARTNER_NAME: 'partner_name',
    DEMAND_TYPE: 'demandType',
    TOP_RESULTS: 'topResults',
    AUDIENCE_STATUS: 'audienceStatus',
    CONVERSION_CATEGORY: 'conversionCategory',
    CONVERSION_TYPE: 'conversionType',
    CONVERSION_STATUS: 'conversionStatus',
    CONVERSION_INCLUDE_IN_TOTAL: 'conversionIncludeInTotal',
    CONVERSION_INCLUDE_IN_TOTAL_VALUE: 'conversionIncludeInTotalValue',
    ACCOUNT_NAME: 'accountName',
    EVENT_NAME: 'eventName',
    DEFINED_CONVERSIONS: 'definedConversions',
    AUDIENCE_DATA_TYPE_MODE: 'audienceDataTypeMode',

    BASELINE: 'baseline',

    HISTORY_LOG_ACTIVITY_CODE_TYPE: 'activityCode',
    CLICKS: 'clicks',
    SPENT: 'spent',
    VISIBLE_IMPRESSIONS: 'visible_impressions',
    IMPRESSIONS: 'impressions',
    VCTR: 'vctr',
    CTR: 'ctr',
    AVG_CPC: 'avg_cpc',
    CVR: 'cvr',
    ACTIONS_CONVERSIONS: 'actions_conversions',
    CPA: 'cpa',
    VCPM: 'vcpm',
    CPM: 'cpm',
    CONVERSIONS: 'conversions',
    ROAS: 'roas',
    BID_STRATEGY: 'bid_strategy',

    CAMPAIGN_NAME: 'campaignName',
    RULE_NAME: 'ruleName',
};
