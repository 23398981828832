import { ENTITY } from 'modules/campaigns/config';
import { BUDGET_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import DailySVGIcon from './Icons/DailySVGIcon';
import LifetimeSVGIcon from './Icons/LifetimeSVGIcon';
import MonthlySVGIcon from './Icons/MonthlySVGIcon';
import { UnlimitedSVGIcon } from './Icons/UnlimitedSVGIcon';

export const BUDGET_OPTIONS = [
    {
        id: BUDGET_TYPES.DAILY,
        type: BUDGET_TYPES.DAILY,
        icon: <DailySVGIcon />,
    },
    {
        id: BUDGET_TYPES.MONTHLY,
        type: BUDGET_TYPES.MONTHLY,
        icon: <MonthlySVGIcon />,
    },
    {
        id: BUDGET_TYPES.LIFETIME,
        type: BUDGET_TYPES.LIFETIME,
        icon: <LifetimeSVGIcon />,
    },
    {
        id: BUDGET_TYPES.UNLIMITED,
        type: BUDGET_TYPES.UNLIMITED,
        skipBudgetData: true,
        icon: <UnlimitedSVGIcon />,
        accountConfigurations: {
            [FEATURE_FLAGS.UNLIMITED_BUDGET_ENABLED]: 'true',
            [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true',
        },
        visibilityCondition: ({ entityType }) => {
            return entityType === ENTITY.CAMPAIGNS_GROUP;
        },
    },
];
