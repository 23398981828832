import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DeletableItem, useCollapsibleListContext } from 'taboola-ultimate-ui';
import Section from '../../../campaigns/modules/common-campaign-form/components/ThirdPartyTags/ListItem/Section';

export const ScheduledReportSentTolListItem = ({ item }) => {
    const { deleteItem } = useCollapsibleListContext();
    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);
    return (
        <DeletableItem onDelete={onDelete}>
            <Section id={`${item.email}`} defaultMessage={item.email} ariaLabel={`${item.email}`} />
        </DeletableItem>
    );
};

ScheduledReportSentTolListItem.propTypes = {
    item: PropTypes.shape({
        email: PropTypes.string,
    }),
};
