import React from 'react';
import PropTypes from 'prop-types';
import ThumbnailError from '../../../ThumbnailsField/ThumbnailGallery/ThumbnailError/ThumbnailError';

const ExternalError = ({ error }) => (
    <ThumbnailError
        messageId={`creative.creator.thumbnails.fetching.${error?.status}`}
        defaultMessage="Image can’t be used. Please try a different one"
    />
);

ExternalError.propTypes = {
    error: PropTypes.object,
};

export default ExternalError;
