import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToggleSwitch } from 'tuui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';

// will match one and only one of the string 'true','1', or 'on' regardless
// of capitalization and regardless off surrounding white-space.
const strToBool = str => {
    const regex = /^\s*(true|on|yes|1)\s*$/i;
    return regex.test(str);
};

class ToggleSwitchCellRenderer extends Component {
    handleOnToggled = toggled => {
        const { setValue, toggledToValue } = this.props;

        setValue(toggledToValue(toggled));
    };

    refresh() {
        // avoid "ag-Grid: Framework component is missing the method refresh()" warning
        return true;
    }

    render() {
        const { value, valueToToggled, disabled, ...rest } = this.props;
        const { column } = rest || {};
        const { colDef } = column || {};
        const { field, headerName } = colDef || {};
        const checked = valueToToggled(value);

        return (
            <span
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="ToggleSwitchCell"
                data-metrics-value={!checked}
                data-metrics-column-id={field}
                data-metrics-column-name={headerName}
            >
                <ToggleSwitch disabled={disabled} checked={checked} onChange={this.handleOnToggled} />
            </span>
        );
    }
}

ToggleSwitchCellRenderer.propTypes = {
    /** value which is injected by the grid */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** callback injected by the grid to set cell's value */
    setValue: PropTypes.func,
    /** function that will receive the 'value' prop as param, and determine the toggle should be on/off. should return boolean */
    valueToToggled: PropTypes.func,
    /** function that will receive the toggled boolean value and translate it to report value. should return boolean/string */
    toggledToValue: PropTypes.func,
    /** value which is injected by the grid - Data of current grid row */
    data: PropTypes.object,
    /** is the toggle disabled */
    disabled: PropTypes.bool,
};

ToggleSwitchCellRenderer.defaultProps = {
    valueToToggled: strToBool,
    toggledToValue: val => val,
    disabled: false,
};

export default ToggleSwitchCellRenderer;
