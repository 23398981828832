import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useSelectedAccountId } from 'hooks/queryParams/useSelectedAccountId';
import { useSelectedCampaignId } from 'modules/campaigns/hooks/useSelectedCampaignId';
import { accountsMapSelector, transformAccountObject } from 'selectors/baseSelectors';
import AllCampaignsObject from '../modules/campaigns/config/allCampaignsObject';

const useDeepMemo = data => {
    const currentData = useRef(data);
    const isDataChanged = useMemo(() => !isEqual(data, currentData.current), [data]);

    if (isDataChanged) {
        currentData.current = data;
    }

    return currentData.current;
};

/**
 * @deprecated - please use account = useAccount() instead
 */

export const useSelectedAccount = () => {
    const [selectedAccountId, setSelectedAccountId] = useSelectedAccountId();
    const accountsMap = useSelector(accountsMapSelector);
    const rawSelectedAccount = useDeepMemo(accountsMap[selectedAccountId]);
    const [, setSelectedCampaignId] = useSelectedCampaignId();

    const setSelectedAccount = useCallback(
        (account = { accountId: null }) => {
            const accountId = account.id ?? account.accountId;
            setSelectedAccountId(prevAccountId => {
                if (prevAccountId !== accountId) {
                    setSelectedCampaignId(AllCampaignsObject.id);
                }
                return accountId;
            });
        },
        [setSelectedAccountId, setSelectedCampaignId]
    );

    const selectedAccount = useMemo(
        () => transformAccountObject(rawSelectedAccount || { id: selectedAccountId, accountId: selectedAccountId }),
        [rawSelectedAccount, selectedAccountId]
    );

    return [selectedAccount, setSelectedAccount];
};
