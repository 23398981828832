import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

const ReloadButton = ({ onButtonClick, buttonLabel, className, disabled }) => (
    <StyledButton
        className={className}
        onClick={onButtonClick}
        size={STYLED_BUTTON_SIZE.SMALL}
        type={STYLED_BUTTON_TYPE.GHOST}
        disabled={disabled}
        data-metrics-event-name={GTM_EVENTS.USABILITY}
        data-metrics-component="ReloadMediaButton"
    >
        {buttonLabel}
    </StyledButton>
);

ReloadButton.propTypes = {
    onButtonClick: PropTypes.func,
    buttonLabel: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

ReloadButton.defaultProps = {
    buttonLabel: <FormattedMessage id="creative.creator.thumbnails.reload" defaultMessage="Reload" />,
    onButtonClick: () => {},
    className: '',
    disabled: false,
};

export default ReloadButton;
