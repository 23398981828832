import { BarGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from '../../../../../constants/graphTypes';
import CampaignGraphTooltip from '../../../components/Tooltips/CampainGraphTooltip';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const barGraph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-group-report',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.CTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.VCPM,
        metrics.CPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
        metrics.ROAS_CLICKS,
        metrics.ROAS_VIEWS,
        metrics.CPCV,
        metrics.VIEWABILITY_RATE,
        metrics.COMPLETION_RATE,
        metrics.HUNDRED_PCT_VIEWABILITY_RATE,
        metrics.COMPLETED_VIEWS,
        metrics.UNIQUE_IMPRESSIONS,
        metrics.UNIQUE_VIEWABLE_IMPRESSIONS,
        metrics.UNIQUE_CLICKS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.CAMPAIGNS_GROUP_NAME.field,
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

export const graph = [barGraph];
