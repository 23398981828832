import { useCallback } from 'react';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import { ENTITY, FORM_MODES } from '../../../../../config';
import { useViewableImpressions } from './useViewableImpressions';

export const useReportReachToGTM = () => {
    const { upperBound } = useViewableImpressions({ entityType: ENTITY.CAMPAIGN });

    const {
        formAccount: { value: accountId },
        data: campaign,
        mode,
    } = useFormDataContext();

    const campaignMode = mode ? mode.toLowerCase() : FORM_MODES.EDIT;
    const reportReachToGTM = useCallback(() => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            accountId,
            taboolaCampaignId: campaign.id,
            value: upperBound,
            component: 'Sc Reach Estimation',
            pageName: `${campaignMode} SC campaign`,
        });
    }, [campaignMode, accountId, campaign.id, upperBound]);
    return {
        reportReachToGTM,
    };
};
