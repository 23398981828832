import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import { columnDefs } from './columnDefinitions';
import { defaultColumns } from './reportPresets';

export const paymentsReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.PAYMENTS],
    endpoint: 'billing/payment-history',
    rowIdField: FIELDS.BILLING_DATE.field,
    searchField: FIELDS.PAYMENT_METHOD.field,
    searchPlaceholderMsgId: 'billingAndPayments.reports.byPaymentMethod.search.placeHolder',
    columnsDef: columnDefs,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    footerColumnDef: [],
    autoFitColumnsToScreen: true,
};
