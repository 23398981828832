import React, { useCallback, useContext, useMemo, useState } from 'react';
import { STRIPE_ELEMENT } from './consts';
import { useStripePaymentElementSetup } from './hooks/useStripePaymentElementSetup';

const StripeACHContext = React.createContext({});
export const useStripeACHContext = () => useContext(StripeACHContext);
const { Provider } = StripeACHContext;

const initialElementsData = {
    [STRIPE_ELEMENT.PAYMENT]: { empty: true, error: null, dirty: false },
};

export const StripeACHContextProvider = ({ children }) => {
    const [elementsState, setElementsState] = useState(initialElementsData);

    const updateElementState = useCallback((elementId, elementState) => {
        setElementsState(elementsState => ({ ...elementsState, [elementId]: elementState }));
    }, []);

    const isStripeValid = useCallback(
        () => Object.values(elementsState).every(stripeElementState => stripeElementState.complete),
        [elementsState]
    );

    const { confirmSetup } = useStripePaymentElementSetup({ updateElementState, elementsState });

    const stripeACHContext = useMemo(
        () => ({
            elementsState,
            updateElementState,
            isStripeValid,
            confirmSetup,
        }),
        [elementsState, isStripeValid, confirmSetup, updateElementState]
    );

    return <Provider value={stripeACHContext}>{children}</Provider>;
};
