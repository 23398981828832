import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useRecommendationsApi } from 'services/api/recommendationsApi';

const useFetchCampaignNotificationTargetItemIds = (accountId, campaignId, recommendationTypes) => {
    const recommendationsApi = useRecommendationsApi();
    const key = [accountId, campaignId, 'recommendations'];
    const { data: { results: recommendations } = {}, isLoading } = useQuery(key, () =>
        recommendationsApi.getCampaignRecommendations(accountId, campaignId, recommendationTypes)
    );

    const targetItemIds = useMemo(() => {
        if (isLoading || !recommendations) {
            return [];
        }
        return recommendations.map(recommendation => {
            return recommendation.messageParams.itemIds;
        });
    }, [isLoading, recommendations]);

    return {
        targetItemIds,
        isLoading,
    };
};

export default useFetchCampaignNotificationTargetItemIds;
