import React, { useMemo } from 'react';
import { useSelectedAccount } from 'hooks';
import PERFORMANCE_RECOMMENDATIONS from 'modules/campaigns/modules/performance-recommendations/config/performanceRecommendations';
import useFetchCampaignNotificationTargetItemIds from 'modules/campaigns/modules/performance-recommendations/hooks/useFetchCampaignNotificationTargetItemIds';
import {
    formatItemIds,
    useNavigateToAdsReport,
} from 'modules/campaigns/modules/performance-recommendations/hooks/useNavigateToAdsReport';
import { FormattedRelativeTime } from 'modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';
import { truncateTargetName } from '../../utils';

export const ItemWithoutCTANotification = ({ notification, closeNotificationCenter, timeDiffInMinutes }) => {
    const {
        attributes: { recommendationTargetName, recommendationTargetId },
    } = notification;
    const [{ accountId }] = useSelectedAccount();
    const { targetItemIds } = useFetchCampaignNotificationTargetItemIds(accountId, recommendationTargetId, [
        PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_CTA,
    ]);

    const formattedItemIds = useMemo(() => formatItemIds(targetItemIds), [targetItemIds]);
    const { navigateToAdsReport } = useNavigateToAdsReport({ formattedItemIds });

    const finalRecommendationTargetName = useMemo(
        () => truncateTargetName(recommendationTargetName),
        [recommendationTargetName]
    );

    return (
        <NotificationCard
            notification={notification}
            cardClick={navigateToAdsReport}
            dataMetricsComponent="NotificationAlertCard"
            formattedMessageId="app.notificationsCenter.recommendationCampaignTargetDetails"
            formattedMessageValues={{
                recommendationTargetName: finalRecommendationTargetName,
                recommendationTargetId,
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
            closeNotificationCenter={closeNotificationCenter}
        />
    );
};
