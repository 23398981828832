import { isNil } from 'lodash';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';

const campaignGroupIdsDeserializer = campaignGroupIds => (isNil(campaignGroupIds) ? [] : JSON.parse(campaignGroupIds));

export const BULK_EDIT_CAMPAIGNS_GROUP = 'bulkEditCampaignsGroup';

export const useBulkEditCampaignsGroupIds = queryParamHookFactory(BULK_EDIT_CAMPAIGNS_GROUP, {
    deserializer: campaignGroupIdsDeserializer,
    defaultValue: [],
});
