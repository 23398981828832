import moment from 'moment';
import { FORM_MODES } from 'config/formModes';

export const startTimeFrameValidations = [
    {
        validationFn: (value, options, { mode }) => mode === FORM_MODES.EDIT || value,
        messageId: 'validations.error.campaign.startDate.required',
    },
    {
        validationFn: (value, options, { mode }) => mode === FORM_MODES.EDIT || moment(value).isValid(),
        messageId: 'validations.error.campaign.startDate.invalid',
    },
    {
        validationFn: (value, options, { mode }) => {
            return (
                mode === FORM_MODES.EDIT || !value || moment(value).isAfter(moment().endOf('day').subtract(1, 'day'))
            );
        },
        messageId: 'validations.error.campaign.startDate.beforeNow',
    },
    {
        validationFn: (value, options, { campaignGroupStartDate, mode }) => {
            return (
                mode !== FORM_MODES.CREATE ||
                !value ||
                !campaignGroupStartDate ||
                moment(value).isAfter(moment(campaignGroupStartDate).endOf('day').subtract(1, 'day'))
            );
        },
        messageId: 'validations.error.campaign.startDate.after.campaign.group',
    },
];

export const endTimeFrameValidations = [
    {
        validationFn: value => !value || moment(value).isValid(),
        messageId: 'validations.error.campaign.endDate.invalid',
    },
    {
        validationFn: value => !value || moment(value).isAfter(moment().startOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeNow',
    },
    {
        validationFn: (value, options, { startDate }) =>
            !value || moment(startDate).isBefore(moment(value).endOf('day')),
        messageId: 'validations.error.campaign.endDate.beforeStartDate',
    },
    {
        validationFn: (value, options, { campaignGroupEndDate, mode }) => {
            return (
                mode !== FORM_MODES.CREATE ||
                !value ||
                !campaignGroupEndDate ||
                moment(value).isBefore(campaignGroupEndDate)
            );
        },
        messageId: 'validations.error.campaign.endDate.before.campaign.group',
    },
];
