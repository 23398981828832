import React from 'react';
import { noop, isEmpty } from 'lodash';
import { ReloadIcon, Button, Spinner } from 'tuui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { AI_VARIATIONS_FIELD_NAMES } from '../../../../../constants/aiVariationsConstants';
import { useCreateFormSelectedCampaignId } from '../../../../../hooks/useCreateFormSelectedCampaignId';
import { useAiVariationsEventsPrefix } from '../../../../AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import { AiTextSuggestionsItem } from './AiTextSuggestionsItem';
import styles from './aiTextSuggestions.module.scss';

export const AiTextSuggestions = ({
    results,
    labelType,
    onShowMoreClick = noop,
    onSuggestionClick,
    status,
    currentTexts,
}) => {
    const resultsLength = results.length;
    const isLoading = status === COMPONENT_STATUS.LOADING;

    const { value: productDescription = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_DESCRIPTION,
    });
    const { value: targetAudience = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_TARGET_AUDIENCE,
    });
    const { value: uniqueSellingProposition = '' } = useFormFieldValue({
        field: AI_VARIATIONS_FIELD_NAMES.PRODUCT_USP,
    });
    const eventsPrefix = useAiVariationsEventsPrefix();
    const campaignId = useCreateFormSelectedCampaignId();

    const headerMessageMap = {
        title: (
            <FormattedMessage
                id="creative.creator.aiTitleSuggestions.title.results.header"
                defaultMessage={`AI generated title suggestions (${resultsLength}). We recommend using 2-3 titles`}
                values={{ resultsLength }}
            />
        ),
        description: (
            <FormattedMessage
                id="creative.creator.aiTitleSuggestions.description.results.header"
                defaultMessage={`AI generated description suggestions (${resultsLength}). Please choose one suggestion`}
                values={{ resultsLength }}
            />
        ),
    };
    const headerMessage = headerMessageMap[labelType];

    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                {headerMessage}
                {!isEmpty(productDescription) && !isEmpty(targetAudience) && !isEmpty(uniqueSellingProposition) && (
                    <Button
                        onClick={() => onShowMoreClick()}
                        variant={Button.variant.text}
                        className={styles['show-more-button']}
                        disabled={isLoading}
                        data-metrics-component={`${eventsPrefix}_${labelType}_Show_More`}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-taboola-campaign-id={campaignId}
                        data-testid="ai-suggestions-show-more-button"
                    >
                        <div className={styles['icon-container']}>
                            {isLoading ? (
                                <Spinner size={16} className={styles['spinner']} color="primary" />
                            ) : (
                                <>
                                    <ReloadIcon className={styles['icon']} />
                                    <span>
                                        <FormattedMessage
                                            id="creative.creator.aiTitleSuggestions.showMore"
                                            defaultMessage="Show More"
                                        />
                                    </span>
                                </>
                            )}
                        </div>
                    </Button>
                )}
            </div>
            <div className={styles['results-container']}>
                {results.map(result => (
                    <AiTextSuggestionsItem
                        isSelected={currentTexts && currentTexts.includes(result)}
                        labelType={labelType}
                        suggestionText={result}
                        onSuggestionClick={onSuggestionClick}
                        key={result}
                    />
                ))}
            </div>
        </div>
    );
};
