import { conversionRulesModeValidations } from 'modules/campaigns/modules/common-campaign-form/components/ConversionOptimization/ConversionRulesModeValidations';
import { usePixelWarningPermitted } from 'modules/campaigns/modules/common-campaign-form/components/MarketingObjective/usePixelWarningPermitted';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import useFormValidatedValue from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { useTrackingState } from 'modules/tracking/hooks/useTrackingState';
import { pixelOrS2sInstalled } from '../../../../../../tracking/hooks/pixelOrS2sInstalled';

export const useConversionRulesMode = () => {
    const isCampaignConversionRulesEnabled = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_MULTIPLE_CONVERSIONS_ENABLED]: 'true',
    });

    const isPixelWarningPermitted = usePixelWarningPermitted();
    const isPixelWarningsTesting = useConfigMatch({ [FEATURE_FLAGS.TA_PIXEL_WARNINGS_TESTING]: 'true' });

    const {
        trackingStateData: { pixelTrackingData, s2sTrackingData, hasAtLeastOneActiveConversion },
    } = useTrackingState(isPixelWarningPermitted);
    const isPixelOrS2sInstalled = pixelOrS2sInstalled(pixelTrackingData, s2sTrackingData);
    const { value: marketingObjective } = useFormFieldValue({ field: 'marketingObjective' });

    return useFormValidatedValue({
        field: 'conversionRulesMode',
        validations: conversionRulesModeValidations,
        validationDependencies: {
            isPixelOrS2sInstalled,
            isPixelWarningPermitted,
            isPixelWarningsTesting,
            marketingObjective,
            hasAtLeastOneActiveConversion,
            isCampaignConversionRulesEnabled,
        },
    });
};
