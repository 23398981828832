import React, { useEffect } from 'react';
import classnames from 'classnames/bind';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { useFormDataContext, withFormDataProvider } from '../../taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import CombinedSegmentsSizeEstimationWidget from '../components/CombinedAudiences/SizeEstimationWidget/CombinedSegmentsSizeEstimationWidget';
import { CombinedAudiencesCollapsibleCard } from '../components/CombinedAudiencesCollapsibleCard/CombinedAudiencesCollapsibleCard';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const classNameBuilder = classnames.bind(styles);

const CombinedAudiencesEditorPage = ({ onCancel, setMediumDrawer, resetDrawerSize }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = useAudienceEditorState({
        reportType: REPORT_TYPE.COMBINED_AUDIENCES,
    });

    // Set drawer mode to medium when mounted and revert it to small when close the drawer
    useEffect(() => {
        setMediumDrawer();
        return () => resetDrawerSize();
    }, [setMediumDrawer, resetDrawerSize]);

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="AudienceEditor"
            >
                <div className={classNameBuilder('audience-editor', 'widget')}>
                    <div>
                        <CombinedAudiencesCollapsibleCard />
                    </div>
                    <CombinedSegmentsSizeEstimationWidget />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const CombinedAudiencesEditorPageWithFormDataProvider = withFormDataProvider(CombinedAudiencesEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { CombinedAudiencesEditorPageWithFormDataProvider as CombinedAudiencesEditorPage };
