'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _IndicationBase = require('../IndicationBase/IndicationBase');

var _IndicationBase2 = _interopRequireDefault(_IndicationBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'indication__taboola-svg-icon___2OWLB',
    'highlight': 'indication__highlight___8Gopr'
};


var Indication = function Indication(props) {
    var highlight = props.highlight,
        message = props.message,
        highlightPrefix = props.highlightPrefix,
        baseProps = _objectWithoutProperties(props, ['highlight', 'message', 'highlightPrefix']);

    return _react2.default.createElement(
        _IndicationBase2.default,
        baseProps,
        highlightPrefix && _react2.default.createElement(
            'span',
            { className: styles['highlight'] },
            highlightPrefix
        ),
        _react2.default.createElement(
            'span',
            { className: styles['highlight'] },
            highlight
        ),
        _react2.default.createElement(
            'span',
            { className: styles['message'] },
            message
        )
    );
};

Indication.propTypes = Object.assign({
    /** The highlighted section of the indication */
    highlight: _propTypes2.default.node,
    /** The content of the indication */
    message: _propTypes2.default.node,
    /** The prefix for the highlighted section of the indication */
    highlightPrefix: _propTypes2.default.node
}, _IndicationBase2.default.propTypes);

Indication.defaultProps = Object.assign({}, _IndicationBase2.default.defaultProps);

exports.default = Indication;