import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

export const vtaLookBackWindowValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'tracking.conversion.properties.vta.window.validations.error.hours.empty',
        defaultMessage: 'Enter a specific timeframe',
    },
    {
        validationFn: validationFunctions.isInt,
        messageId: 'tracking.conversion.properties.vta.window.validations.error.hours.not.int',
        defaultMessage: 'View Through Conversion Window must be a whole number',
    },
    {
        validationFn: (value, options, data) => data.isDisabled || validationFunctions.range(value, options, { data }),
        options: { min: 1 },
        messageId: `tracking.conversion.properties.vta.window.validations.error.hours.outside.min.range`,
        defaultMessage: 'View Through Conversion Window cannot be smaller than {min} {vtaLookBackWindowType}',
    },
    {
        validationFn: (value, options, data) => validationFunctions.range(value, options, { data }),
        options: { max: 'maxThreshold' },
        messageId: `tracking.conversion.properties.vta.window.validations.error.hours.outside.max.range`,
        defaultMessage: 'View Through Conversion Window cannot be larger than {maxThreshold} {vtaLookBackWindowType}',
    },
];
