import { useCallback } from 'react';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';

export const useBidStrategyMessage = () => {
    const isMaxConversionsEnabled = useConfigMatch({ [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: 'true' });
    const isTargetCpaEnabled = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' });

    const getMessageIdPrefix = useCallback(
        value => {
            return (isMaxConversionsEnabled || isTargetCpaEnabled) && value === BID_STRATEGIES.SMART
                ? 'app.campaigns.campaign.bid.strategy.enhancedCpc'
                : 'app.campaigns.campaign.bid.strategy';
        },
        [isMaxConversionsEnabled, isTargetCpaEnabled]
    );

    return { getMessageIdPrefix };
};
