import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { authentificationSelector } from 'modules/taboola-common-frontend-modules/authentication/selectors/baseSelectors';
import { COMPONENT_STATUS } from 'services/constants';
import { appStatusSelector, appInitFormLocalStorageSelector } from '../selectors';
import { useSelectedAccountId } from './queryParams';
import { useNonAuthAccess } from './useNonAuthAccess';
import { useOmniboxQuery } from './useOmniboxQuery';

const useAppReady = () => {
    const loadingOmniboxQuery = useOmniboxQuery();
    const status = useSelector(appStatusSelector);
    const isAuthenticated = useSelector(authentificationSelector);
    const isAppInitFromLocalStorage = useSelector(appInitFormLocalStorageSelector);
    const [accountId] = useSelectedAccountId();
    const nonAuthAccess = useNonAuthAccess();

    const isAppReady =
        status !== COMPONENT_STATUS.INITIAL &&
        ((isAppInitFromLocalStorage && !loadingOmniboxQuery && isAuthenticated && !isNil(accountId)) || nonAuthAccess);

    return isAppReady;
};

export default useAppReady;
