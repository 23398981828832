import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from 'taboola-ultimate-ui';
import { getZoom } from './getZoom';
import { useCustomContextMenu } from './useCustomContextMenu';
import styles from './customContextMenu.module.scss';

const DefaultMenuItemComponent = ({ label, onClick, ...rest }) => (
    <div className={styles['menu-item']} onClick={onClick} {...rest}>
        {label}
    </div>
);

DefaultMenuItemComponent.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func.isRequired,
};

export const CustomContextMenu = () => {
    const { menuPosition, data, close } = useCustomContextMenu();
    const [adjustedPosition, setAdjustedPosition] = useState(menuPosition);
    const menuRef = useRef();

    const isOpen = useMemo(() => !!menuPosition, [menuPosition]);

    useOnClickOutside(menuRef, close);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const zoom = getZoom();

        const newPosition = {
            x: menuPosition.x / zoom,
            y: menuPosition.y / zoom,
        };

        if (menuRef.current) {
            const menuBounds = menuRef.current?.getBoundingClientRect();

            // Check if the menu goes beyond the right edge of the window
            if (newPosition.x + menuBounds?.width > window.innerWidth) {
                newPosition.x = window.innerWidth - menuBounds?.width;
            }

            // Check if the menu goes beyond the bottom edge of the window
            if (newPosition.y + menuBounds?.height > window.innerHeight) {
                newPosition.y = window.innerHeight - menuBounds?.height;
            }
        }

        setAdjustedPosition(newPosition);
    }, [menuPosition, isOpen]);

    if (!adjustedPosition || !data) {
        return null;
    }

    const {
        menuComponent: MenuComponent,
        menuItemComponent: MenuItemComponent = DefaultMenuItemComponent,
        menuItems,
    } = data;

    const style = {
        left: `${adjustedPosition.x}px`,
        top: `${adjustedPosition.y}px`,
    };

    return (
        <div ref={menuRef} className={styles['custom-context-menu']} style={style}>
            {MenuComponent ? (
                <MenuComponent items={menuItems} />
            ) : (
                menuItems.map((item, index) => <MenuItemComponent key={index} {...item} />)
            )}
        </div>
    );
};
