import { getGraphTimeScale } from './getGraphTimeScale';

export const getDataWithFullPeriodScaleSingleMetric = (data, keyName, options = {}) => {
    const { getScale = getGraphTimeScale } = options;
    const scaleKeys = getScale(options);
    const resolvedData = [...scaleKeys.map(key => ({ [keyName]: key })), ...data];
    resolvedData.sort((a, b) => b[keyName] - a[keyName]);

    return resolvedData;
};
