'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styleUtils = require('../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'toolbar': 'toolbar__toolbar___g8ivy',
    'children': 'toolbar__children___1uxZ1'
};


var classNameBuilder = _bind2.default.bind(styles);

/**
 * @deprecated - use css
 */
var Toolbar = function Toolbar(_ref) {
    var className = _ref.className,
        childrenClassName = _ref.childrenClassName,
        children = _ref.children,
        marginElements = _ref.marginElements,
        lastChildStyle = _ref.lastChildStyle,
        rest = _objectWithoutProperties(_ref, ['className', 'childrenClassName', 'children', 'marginElements', 'lastChildStyle']);

    return _react2.default.createElement(
        'div',
        Object.assign({ className: classNameBuilder('toolbar', className) }, rest),
        _react2.default.Children.map(children, function (child, i) {
            return child && _react2.default.createElement(
                'span',
                { className: childrenClassName || styles['children'], style: i === children.length - 1 ? lastChildStyle : { marginRight: (0, _styleUtils2.default)(marginElements) } },
                _react2.default.cloneElement(child)
            );
        })
    );
};

Toolbar.propTypes = {
    /** External className to ba added to this conponent */
    className: _propTypes2.default.string,
    /** External className to ba added to each child */
    childrenClassName: _propTypes2.default.string,
    /** Toolbar's content: buttons, menus etc. */
    children: _propTypes2.default.arrayOf(_propTypes2.default.node),
    /** Determines the margin between the components */
    marginElements: _propTypes2.default.number,
    /** Custom style to be applied on the last child */
    lastChildStyle: _propTypes2.default.object
};

Toolbar.defaultProps = {
    className: '',
    children: [],
    marginElements: 5,
    lastChildStyle: {}
};

exports.default = Toolbar;