import React from 'react';
import classnames from 'classnames/bind';
import { ErrorOutlinedIcon } from 'tuui';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useEntityType, useModuleName } from 'hooks';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ERROR_MSG_PARAMS_SYM } from 'modules/taboola-common-frontend-modules/validations/services/validationService';
import { useGroupedValidations } from './useGroupedValidations';
import styles from './validationGroup.module.scss';

const FIELD_TITLE_PREFIX = 'app.forms.fields';
const classNameBuilder = classnames.bind(styles);

export function ValidationGroup({ validationsState, placement = TOOLTIP_POSITION.TOP }) {
    const { errors, warnings } = useGroupedValidations(validationsState);

    if (errors.length === 0 && warnings.length === 0) {
        return null;
    }

    return (
        <div className={classNameBuilder('icon-container')}>
            <ErrorOutlinedIcon
                role="img"
                aria-label="alert"
                className={classNameBuilder({ error: errors.length > 0, warning: !errors.length })}
            />
            <Tooltip className={classNameBuilder('tooltip-content')} arrow interactive position={placement}>
                <div className={classNameBuilder('tooltip-body')}>
                    {errors.map(fieldId => (
                        <ValidationIndication
                            key={fieldId}
                            fieldId={fieldId}
                            validationState={validationsState[fieldId]}
                            indicationType={INDICATION_TYPES.ERROR}
                        />
                    ))}
                    {warnings.map(fieldId => (
                        <ValidationIndication
                            key={fieldId}
                            fieldId={fieldId}
                            validationState={validationsState[fieldId]}
                            indicationType={INDICATION_TYPES.WARNING}
                        />
                    ))}
                </div>
            </Tooltip>
        </div>
    );
}

function ValidationIndication({ fieldId, validationState, indicationType }) {
    const { formatMessage } = useIntl();
    const entity = useEntityType();
    const module = useModuleName();
    const specificId = `${FIELD_TITLE_PREFIX}.${module}.${entity}.${fieldId}`;
    const fallbackId = `${FIELD_TITLE_PREFIX}.${module}._.${fieldId}`;
    const { values, ...msg } = validationState.result?.failedValidationData?.[ERROR_MSG_PARAMS_SYM];

    const defaultMessage = formatMessage(msg, values);
    const defaultFormattedMessage = formatMessage({ id: fallbackId, defaultMessage });

    const message = formatMessage({
        id: specificId,
        defaultMessage: defaultFormattedMessage,
    });

    return (
        <div
            className={classNameBuilder('container')}
            role="button"
            onClick={e => {
                e.stopPropagation();
                const { goto, fieldId, metadata } = validationState;

                goto(metadata, fieldId);
            }}
        >
            <ErrorOutlinedIcon className={classNameBuilder(indicationType)} />
            {message}
        </div>
    );
}
