import { isEmpty, map, filter, get, last, split } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import VideoTypes from '../../config/VideoTypes';
import { MAX_GIF_SIZE, MAX_VIDEO_SIZE } from '../creative-creator/config/thumbnailValidationConsts';
import { campaignConfig } from './config/campaignConfig';
import { creativeConfig } from './config/creativeConfig';
import {
    getMetadataFromConfig,
    getMetadataFromWorkbook,
    isRecentFile,
    isValidMetadata,
} from './utils/excelBulkWorkbookUtils';

const validateMaxRows = (worksheet, maxRows) => worksheet.actualRowCount <= maxRows;
const validateMinRows = (worksheet, minRows) => worksheet.actualRowCount > minRows;

const getFileType = file => last(split(file.name, '.'));
const getFileSize = file => get(file, '_data.uncompressedSize', 0);

const isTooLargeGifFile = file => getFileType(file) === VideoTypes.GIF && getFileSize(file) > MAX_GIF_SIZE;

const isTooLargeVideoFile = file => {
    const fileType = getFileType(file);
    const isVideoFileType = fileType === VideoTypes.MOV || fileType === VideoTypes.MP4;

    return isVideoFileType && getFileSize(file) > MAX_VIDEO_SIZE;
};

export const getExcelWorkbookValidations = ({ maxCampaignRows, maxCreativesRows }) => {
    return [
        {
            validationFn: workbook => {
                return workbook;
            },
            messageId: 'excel.upload.validations.missing.excel',
            defaultMessage: 'Please select an Excel file.',
        },
        {
            validationFn: (workbook, _, { campaignsSheetName, creativesSheetName }) => {
                return (
                    Boolean(workbook.getWorksheet(campaignsSheetName)) ||
                    Boolean(workbook.getWorksheet(creativesSheetName))
                );
            },
            messageId: 'excel.upload.validations.error.upload.missing.sheet',
            defaultMessage: 'Please ensure the Excel file has at least one sheet with the name "Campaigns" or "Ads"',
        },
        {
            validationFn: (workbook, _, { campaignsSheetName, creativesSheetName }) => {
                return (
                    validateMinRows(workbook.getWorksheet(campaignsSheetName), campaignConfig.headerRowIndex) ||
                    validateMinRows(workbook.getWorksheet(creativesSheetName), creativeConfig.headerRowIndex)
                );
            },
            messageId: 'excel.upload.validations.error.upload.empty',
            defaultMessage: 'Invalid number of campaigns and ads, please add at least one campaign or ad',
        },
        {
            validationFn: (workbook, _, { campaignsSheetName }) => {
                const sheet = workbook.getWorksheet(campaignsSheetName);

                return validateMaxRows(sheet, maxCampaignRows + campaignConfig.headerRowIndex);
            },
            messageId: 'excel.upload.validations.error.upload.campaigns.wrong.row.count',
            defaultMessage: 'Invalid number of campaigns, please limit the number of campaigns to {maxCampaigns}',
            messageValues: { maxCampaigns: maxCampaignRows },
        },
        {
            validationFn: (workbook, _, { creativesSheetName }) => {
                const sheet = workbook.getWorksheet(creativesSheetName);

                return validateMaxRows(sheet, maxCreativesRows + creativeConfig.headerRowIndex);
            },
            messageId: 'excel.upload.validations.error.upload.creatives.wrong.row.count',
            defaultMessage: 'Invalid number of ads, please limit the number of ads to {maxCreatives}',
            messageValues: { maxCreatives: maxCreativesRows },
        },
        {
            validationFn: (workbook, _, { excelConfig }) => {
                const configMetadata = getMetadataFromConfig(excelConfig);
                const metadata = getMetadataFromWorkbook(workbook);
                const isValid = isRecentFile(configMetadata, metadata);

                return isValid;
            },
            messageId: 'excel.upload.validations.error.metadata.error',
            defaultMessage: 'Metadata reading error.',
            severity: INDICATION_TYPES.WARNING,
        },
        {
            validationFn: (
                workbook,
                _,
                { excelConfig, locale, parentAppName, accountName, isMetadataCheckDisabled }
            ) => {
                if (isMetadataCheckDisabled) {
                    return true;
                }

                const configMetadata = getMetadataFromConfig(excelConfig, {
                    locale,
                    parentAppName,
                    accountName,
                });
                const metadata = getMetadataFromWorkbook(workbook);
                const isValid = isValidMetadata(configMetadata, metadata);

                return isValid;
            },
            messageId: 'excel.upload.validations.error.metadata.error',
            defaultMessage: 'Metadata reading error.',
            severity: INDICATION_TYPES.WARNING,
        },
    ];
};

export const zipObjValidations = [
    {
        validationFn: (zipObj, _, { excelWorkbook, allFileNames }) => {
            if (!excelWorkbook) {
                return true;
            }

            // this only returns false for the case where the zip has files, but there is no zip file
            //   (the validation below checks if the zip HAS all the files in the worksheet)
            return isEmpty(allFileNames) || zipObj;
        },
        messageId: 'excel.upload.validations.error.missing.zip',
        defaultMessage: 'Please upload a zip file containing the file names described in the Excel file',
    },
    {
        validationFn: async zipObj => {
            if (!zipObj) {
                return true;
            }

            const zipFiles = zipObj.files || {};
            const tooLargeGifFiles = filter(zipFiles, isTooLargeGifFile);

            if (!isEmpty(tooLargeGifFiles)) {
                const tooLargeFileNames = map(tooLargeGifFiles, 'name');
                throw new CustomValidationError({
                    message: 'Gif file size may not exceed 5MB: {value}',
                    messageCode: 'excel.bulk.zip.error.gifSizeTooLarge',
                    templateParameters: {
                        value: tooLargeFileNames.join(', '),
                    },
                });
            }

            return true;
        },
        // this message should never occur, it's just required by the architecture
        messageId: 'excel.bulk.zip.error.missing.file.default',
        defaultMessage: 'Some files are missing from the zip file. Please upload another zip',
    },
    {
        validationFn: async zipObj => {
            if (!zipObj) {
                return true;
            }

            const zipFiles = zipObj.files || {};
            const tooLargeVideoFiles = filter(zipFiles, isTooLargeVideoFile);

            if (!isEmpty(tooLargeVideoFiles)) {
                const tooLargeFileNames = map(tooLargeVideoFiles, 'name');
                throw new CustomValidationError({
                    message: 'Video file size may not exceed 50 MB: {value}',
                    messageCode: 'excel.bulk.zip.error.videoSizeTooLarge',
                    templateParameters: {
                        value: tooLargeFileNames.join(', '),
                    },
                });
            }

            return true;
        },
        // this message should never occur, it's just required by the architecture
        messageId: 'excel.bulk.zip.error.missing.file.default',
        defaultMessage: 'Some files are missing from the zip file. Please upload another zip',
    },
    {
        validationFn: (zipObj, _, { excelWorkbook, allFileNames }) => {
            if (!excelWorkbook || !zipObj) {
                return true;
            }

            const zipFiles = zipObj.files || {};

            const missingFiles = allFileNames.filter(name => !zipFiles[name]);

            if (missingFiles.length !== 0) {
                throw new CustomValidationError({
                    message: 'Missing the following files from the zip: {value}',
                    messageCode: 'excel.bulk.zip.error.missing.file',
                    templateParameters: {
                        value: missingFiles.join(', '),
                    },
                });
            }

            return true;
        },
        // this message should never occur, it's just required by the architecture
        messageId: 'excel.bulk.zip.error.missing.file.default',
        defaultMessage: 'Some files are missing from the zip file. Please upload another zip',
    },
];
