import React from 'react';
import { FormField, useCollapsibleListContext } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ListWithMultilineInput } from '../ListWithMultilineInput';
import PublishersDomainsTooltip from './PublishersDomainsTooltip/PublishersDomainsTooltip';
import { validateUrl } from './utils/utils';
import styles from '../commonEditor.module.scss';

const ListHeader = () => {
    const { items } = useCollapsibleListContext();
    return (
        <FormattedMessage
            id="video.campaign.creator.publishers.domains.urls"
            defaultMessage="Selected Publishers Domains"
            values={{ count: items.length }}
        />
    );
};

const topContentPlaceholder = 'Paste publisher names such as msn.com without http://www.';

const footerErrorMessage = (
    <FormattedMessage
        id="video.campaign.creator.publisher.domains.domain.error.noResultsFound"
        defaultMessage="Invalid publisher domain names"
    />
);

const footerButtonMessage = (
    <FormattedMessage
        id="video.campaign.creator.publishers.domains.urls.addPublishers"
        defaultMessage="ADD PUBLISHERS"
    />
);

const validations = [];

const typeField = 'campaignTargeting.publisherDomainTargeting.type';
const valueField = 'campaignTargeting.publisherDomainTargeting.values';

const PublishersDomains = () => {
    const validateDomains = domains => {
        return domains.filter(validateUrl).map(name => ({ name }));
    };

    return (
        <FormField
            inputId="publishers-domains"
            label={
                <FormattedMessage
                    id="video.campaign.creator.publishers.domains.title"
                    defaultMessage="Publishers Domains"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.creator.publishers.domains.description"
                    defaultMessage="Paste publisher names such as msn.com"
                />
            }
            subDescription={
                <FormattedMessage
                    id="video.campaign.creator.publishers.domains.sub.description"
                    defaultMessage="Separate Domains by a new line, commas or semicolons."
                />
            }
            helpText={<PublishersDomainsTooltip />}
            containerClass={styles['input']}
        >
            <ListWithMultilineInput
                typeField={typeField}
                valueField={valueField}
                validateInput={validateDomains}
                listHeader={<ListHeader />}
                topContentPlaceholder={topContentPlaceholder}
                footerButtonMessage={footerButtonMessage}
                footerErrorMessage={footerErrorMessage}
                validations={validations}
            />
        </FormField>
    );
};

export default PublishersDomains;
