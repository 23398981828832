import {
    set,
    transform,
    isNumber,
    groupBy,
    map,
    isEmpty,
    some,
    uniqueId,
    flatten,
    mapValues,
    sortBy,
    identity,
    partition,
    toString,
    slice,
    join,
} from 'lodash';
import { DEFAULT_PAGE_SIZE, LoadingMode, NO_DATA_LOADING, TAXONOMY_SEPARATOR } from './constants';

const transformLeaves = (
    leaves = [],
    total = 0,
    leafTransformer = () => ({}),
    forceLoadingAll = false,
    getTaxonomyValue = identity
) => {
    const hasMore = isNumber(total) && total > leaves.length;

    const transformedNodes = leaves.map(leaf => ({
        id: leaf.id,
        pathProperty: leaf.id,
        label: getTaxonomyValue(leaf),
        loading: leaf.loading,
        ...leafTransformer(leaf),
    }));

    return !hasMore || forceLoadingAll
        ? transformedNodes
        : [...transformedNodes, { id: uniqueId('load-more-'), hasMore }];
};

export const defaultSortSegments = (segments, level) => sortBy(segments, 'label');
const generateTreeInner = (
    results = [],
    totals,
    leafTransformer,
    forceLoadingAll,
    getTaxonomyValue = identity,
    formatMessage,
    parentTransformer,
    sortSegments = defaultSortSegments
) => {
    const [emptyBranches, branches] = partition(results, ({ taxonomy = [] }) => isEmpty(taxonomy));
    const transformedLeaves = transformLeaves(
        emptyBranches,
        totals,
        leafTransformer,
        forceLoadingAll,
        getTaxonomyValue
    );

    const generatedTree = generateTreeRecursive(
        branches,
        totals,
        0,
        leafTransformer,
        forceLoadingAll,
        getTaxonomyValue,
        formatMessage,
        parentTransformer,
        sortSegments
    );

    return sortSegments([...transformedLeaves, ...generatedTree], 0);
};

export const getSubTaxonomy = (node, level) => {
    if (!node) {
        return '';
    }

    const { taxonomy } = node;
    const subTaxonomyArray = slice(taxonomy, 0, level);

    return join(subTaxonomyArray, TAXONOMY_SEPARATOR);
};

const generateTreeRecursive = (
    results = [],
    totals,
    level = 0,
    leafTransformer,
    forceLoadingAll,
    getTaxonomyValue,
    formatMessage,
    parentTransformer = () => ({}),
    sortSegments
) => {
    const hasNextLevelResults = some(results, ({ taxonomy }) => taxonomy.length > level);
    if (!hasNextLevelResults) {
        return transformLeaves(results, totals, leafTransformer, forceLoadingAll, getTaxonomyValue);
    }

    const groupedByCategoryId = groupBy(results, `taxonomy.${level}`);
    const tree = map(groupedByCategoryId, (itemsInGroup, categoryId) => ({
        id: uniqueId('parent-node-'),
        pathProperty: categoryId,
        label: formatMessage({
            id: `campaign.editor.marketplace.audiences.taxonomies.${categoryId}.title`,
            defaultMessage: getTaxonomyValue({ id: categoryId }),
        }),
        loading: forceLoadingAll,
        nodes: !isEmpty(itemsInGroup)
            ? generateTreeRecursive(
                  itemsInGroup,
                  totals[categoryId],
                  level + 1,
                  leafTransformer,
                  forceLoadingAll,
                  getTaxonomyValue,
                  formatMessage,
                  parentTransformer,
                  sortSegments
              )
            : undefined,
        ...parentTransformer({ itemsInGroup, categoryId, level }),
    }));
    return sortSegments(tree, level);
};

export const splitTaxonomy = taxonomy => (taxonomy ? taxonomy.split(TAXONOMY_SEPARATOR) : []);

const transformNodes = (nodes = []) =>
    nodes.map(({ taxonomy, ...rest }) => ({ taxonomy: splitTaxonomy(taxonomy), ...rest }));

const transformTotals = totals =>
    transform(
        totals,
        (results, value, key) => {
            const path = splitTaxonomy(key);
            set(results, path, value);
        },
        {}
    );

const generateNextPageLoadingData = (loadingPaths, data, totals, pageSize = DEFAULT_PAGE_SIZE) => {
    const loadingNodesLists = map(loadingPaths, (loadingCount, path) => {
        const currentCount = data.filter(({ taxonomy }) => taxonomy === path).length;
        const total = totals[path] || 0;

        const loadingLength = loadingCount * pageSize;
        const restCount = total - currentCount;
        const length = Math.min(restCount, loadingLength);
        return Array.from({ length }).map(() => ({ id: uniqueId('loading-'), taxonomy: path, loading: true }));
    });

    return flatten(loadingNodesLists);
};

const generateAllLoadingData = (leaves, mockLoadingData = NO_DATA_LOADING) => {
    // Optimization: slice amount of leaves to reduce the render time
    const loadingData = isEmpty(leaves) ? mockLoadingData : leaves.slice(0, 10);
    return loadingData.map(({ id, ...rest }) => ({ ...rest, id: id || uniqueId('no-data-'), loading: true }));
};

const generateAllLoadingTotals = leaves => {
    const groupedByTaxonomy = groupBy(leaves, 'taxonomy');
    return mapValues(groupedByTaxonomy, 'length');
};

const generatePath = (id, taxonomy) => {
    if (!taxonomy) {
        return toString(id);
    }

    return `${taxonomy}${TAXONOMY_SEPARATOR}${id}`;
};

const generateSubPaths = taxonomy => {
    if (!taxonomy) {
        return [];
    }

    const nodeNames = splitTaxonomy(taxonomy);
    return nodeNames.map((path, index, all) => all.slice(0, index + 1).join(TAXONOMY_SEPARATOR));
};

const generateSubPathsMap = (leaf, getSelectionModeByPath = () => true) => {
    const { id, taxonomy } = leaf;
    const idPath = generatePath(id, taxonomy);
    const initialResults = { [idPath]: getSelectionModeByPath(idPath, leaf) };

    const subPaths = generateSubPaths(taxonomy);
    return subPaths.reduce(
        (results, path) => ({ ...results, [path]: getSelectionModeByPath(path, leaf) }),
        initialResults
    );
};

const generateTree = ({
    leaves,
    totals,
    leafTransformer,
    loadingPaths,
    loadingMode,
    getTaxonomyValue,
    formatMessage,
    parentTransformer,
    sortSegments,
}) => {
    const forceLoadingAll = loadingMode === LoadingMode.FULL;
    const transformedNodes = forceLoadingAll ? [] : transformNodes(leaves);
    const loadingNodes = forceLoadingAll
        ? generateAllLoadingData(leaves)
        : generateNextPageLoadingData(loadingPaths, leaves, totals);
    const transformedLoadingNodes = transformNodes(loadingNodes);
    const tempTotals = forceLoadingAll ? generateAllLoadingTotals(loadingNodes) : totals;
    const transformedTotals = transformTotals(tempTotals);

    return generateTreeInner(
        [...transformedNodes, ...transformedLoadingNodes],
        transformedTotals,
        leafTransformer,
        forceLoadingAll,
        getTaxonomyValue,
        formatMessage,
        parentTransformer,
        sortSegments
    );
};

export {
    generateSubPathsMap,
    generateSubPaths,
    generatePath,
    generateTree,
    generateAllLoadingData,
    generateNextPageLoadingData,
    generateAllLoadingTotals,
};
