import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import { INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const GtmIndication = ({
    type = INDICATION_TYPES.ERROR,
    labelMessageId = 'tracking.setup.gtm.configuration.gtm.no.account.label',
    descriptionMessageId = 'tracking.setup.gtm.configuration.gtm.no.account.description',
    labelDefault = 'No Active Account',
    descriptionDefault = 'Please make sure to activate and set up your GTM account. <a>Learn more</a>',
}) => {
    return (
        <Indication
            type={type}
            message={
                <FormField
                    label={<FormattedMessage id={labelMessageId} defaultMessage={labelDefault} />}
                    description={<FormattedMessage id={descriptionMessageId} defaultMessage={descriptionDefault} />}
                />
            }
            displayDismissComponent={false}
        />
    );
};
