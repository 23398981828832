import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import OutlineEditableText from '../../../campaigns/components/OutlineEditableText';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { withInputValidations } from '../../../taboola-common-frontend-modules/validations';
import useValidationContext from '../../../taboola-common-frontend-modules/validations/hooks/useValidationContext';
import nameValidations from '../../validations/nameValidations';
import styles from './eventNameField.module.scss';

const EditableTextWithValidations = withInputValidations(OutlineEditableText, nameValidations, {
    onChange: { validateEventValue: true, eventValueGetter: e => e },
});

export const EventNameField = () => {
    const { mode, value: eventName, onChange: onNameChange } = useFormFieldValue({ field: 'eventName' });

    const validationContext = useValidationContext({
        prefix: 'event',
        maxLen: 50,
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'audience.editor.event.name.placeholder',
        defaultMessage: 'Event Name',
    });

    return (
        <FormField
            inputId="event-name-input"
            label={<FormattedMessage id="audience.editor.event.name.title" defaultMessage="Event Name" />}
        >
            <EditableTextWithValidations
                id="eventName"
                className={styles['editable-text']}
                value={eventName}
                disabled={mode === FORM_MODES.EDIT}
                onChange={onNameChange}
                placeholder={placeholder}
                validationContext={validationContext}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="EventName"
            />
        </FormField>
    );
};

export default EventNameField;
