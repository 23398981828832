import { useLayoutEffect, useEffect } from 'react';
import { isEmpty } from 'lodash';

export const useMarketplaceAudienceTargetingEffects = ({
    handleReload,
    reloadProviders,
    reloadTaxonomies,
    handleChangeEnabledProviders,
    selectedLeaves,
    search,
    expandAll,
}) => {
    // flow of initial load, pagination, search and providers change
    useLayoutEffect(() => {
        handleReload();
    }, [handleReload]);

    // flow of initial load of providers
    useEffect(() => {
        reloadProviders();
    }, [reloadProviders]);

    // flow of initial load of taxonomies
    useEffect(() => {
        reloadTaxonomies();
    }, [reloadTaxonomies]);

    // flow to handle change of enabled providers according to selectedLeaves
    useEffect(() => {
        handleChangeEnabledProviders(selectedLeaves);
    }, [handleChangeEnabledProviders, selectedLeaves]);

    // expand all in case of search change and not empty
    useEffect(() => {
        if (!isEmpty(search)) {
            expandAll();
        }
    }, [search, expandAll]);
};
