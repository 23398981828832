import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import CpcvCompletedViewsSubText from '../CpcvCompletedViews/CpcvCompletedViewsSubText';
import Rate from './Rate';
import RateFieldTooltip from './RateFieldTooltip';
import styles from './Rate.module.scss';

export const RateField = ({ currency, label }) => {
    return (
        <FormField
            inputId="rateField"
            label={label}
            description={
                <FormattedMessage
                    id="video.campaign.editor.rate.description"
                    defaultMessage="The amount you are willing to pay each time a billing event occurs."
                />
            }
            helpText={<RateFieldTooltip />}
            containerClass={styles['input']}
            subtext={<CpcvCompletedViewsSubText currency={currency} />}
        >
            <Rate currency={currency} />
        </FormField>
    );
};

RateField.propTypes = {
    currency: PropTypes.string,
    label: PropTypes.node,
};

export default RateField;
