const TABOOLA_TAXONOMY_ID = 'TABOOLA';
const DEMOGRAPHIC_DECLARED_TAXONOMY_ID = 'DEMOGRAPHIC_DECLARED';
const DEMOGRAPHIC_TAXONOMY_ID = 'DEMOGRAPHIC';
const THIRD_PARTY_TAXONOMY_ID = '3P';

export const getIsTaboolaTreeSegment = ({ pathProperty }) => pathProperty === TABOOLA_TAXONOMY_ID;

export const ageGroupsTaxonomyFields = ['AGE', 'AGE_DECLARED'];

export const genderGroupsTaxonomyFields = ['GENDER', 'GENDER_DECLARED'];

export const ageGroupTaxonomyParentsSet = new Set([
    DEMOGRAPHIC_TAXONOMY_ID,
    DEMOGRAPHIC_DECLARED_TAXONOMY_ID,
    TABOOLA_TAXONOMY_ID,
    THIRD_PARTY_TAXONOMY_ID,
]);

export const genderGroupTaxonomyParentsSet = new Set([
    DEMOGRAPHIC_TAXONOMY_ID,
    DEMOGRAPHIC_DECLARED_TAXONOMY_ID,
    TABOOLA_TAXONOMY_ID,
    THIRD_PARTY_TAXONOMY_ID,
]);
