import React from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const StoppedCreativeBulkDisabledTooltip = () => (
    <Tooltip position={TOOLTIP_POSITION.RIGHT} arrow>
        <FormattedMessage
            id="stopped.bulk.action.checkbox.tooltip"
            defaultMessage="The ad has been deleted. Deleted ads cannot be edited or enabled again."
        />
    </Tooltip>
);
