import { ENTITY_TYPE } from '../utils/performanceRulesConsts';

export const fromGWToPerformanceRules = transformedData => {
    const entityRule = transformedData.entityType === ENTITY_TYPE.CAMPAIGN;
    const campaigns = entityRule ? transformedData.campaigns?.map(id => ({ id: parseInt(id) })) : undefined;

    return {
        ...transformedData,
        campaigns,
    };
};
