import React from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './loadingBalance.module.scss';

const classNameBuilder = classnames.bind(styles);
export const LoadingBalance = ({ className }) => {
    const { formatMessage } = useIntl();
    return (
        <div className={classNameBuilder('container', className)} role="progressbar">
            <div className={styles['title']}>
                {formatMessage({
                    id: 'billingAndPayments.balancePanel.credit.title',
                    defaultMessage: 'Current Balance',
                })}
            </div>
            <ContentLoader height={33} width={135} speed={2} className={styles['first-loading']} />
            <ContentLoader height={52} width={277} speed={2} className={styles['second-loading']} />
        </div>
    );
};
