import { DeviceIcon, EmailIcon, ZipcodeIcon } from 'taboola-ultimate-ui';
import { REPORT_TYPE } from '../../../campaigns/config';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { CRM_FILE_TYPE } from '../../config';

export const useCrmFileDataType = (isExternalAudiencesPage = false) => {
    const { value: crmFileType } = useFormFieldValue({ field: 'crmFileType' });

    const firstPartyBaseConfigs = [
        {
            id: CRM_FILE_TYPE.EMAIL,
            icon: EmailIcon,
            label: 'EMAILS',
            subtext: 'Only available in some countries',
        },
        {
            id: CRM_FILE_TYPE.DEVICE_ID,
            icon: DeviceIcon,
            label: 'MOBILE IDS',
            subtext: 'Only available in some countries',
        },
    ];

    const configs = {
        [REPORT_TYPE.FIRST_PARTY]: firstPartyBaseConfigs,
        [REPORT_TYPE.PREDICTIVE]: [
            {
                id: CRM_FILE_TYPE.EMAIL,
                icon: EmailIcon,
                label: 'EMAILS',
                subtext: 'Available only for <strong>United States</strong> based emails',
            },
            {
                id: CRM_FILE_TYPE.DEVICE_ID,
                icon: DeviceIcon,
                label: 'MOBILE IDS',
                subtext: 'Available only for <strong>United States</strong> based device IDs',
            },
            {
                id: CRM_FILE_TYPE.ZIP_CODE,
                icon: ZipcodeIcon,
                label: 'ZIP CODES',
                subtext: 'Available only for <strong>United States</strong> ZIP Codes',
            },
        ],
    };

    const isNotYahooAudience =
        crmFileType === CRM_FILE_TYPE.EMAIL ||
        crmFileType === CRM_FILE_TYPE.DEVICE_ID ||
        crmFileType === CRM_FILE_TYPE.ZIP_CODE;

    return { configs, isNotYahooAudience };
};
