import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';

const BaseCheckboxCellRenderer = ({
    onSelectCheckbox,
    onUnselectCheckbox,
    onIntermediateSelect,
    selected,
    selectedValues,
    disabled,
    isIntermediate,
    labelClassName,
    tooltipContent,
}) => (
    <CheckboxGroup
        onSelectCheckbox={onSelectCheckbox}
        onUnselectCheckbox={onUnselectCheckbox}
        onIntermediateSelect={onIntermediateSelect}
        selectedValues={selectedValues}
    >
        <TextCheckbox
            labelClassName={labelClassName}
            isIntermediate={isIntermediate}
            value={selected}
            disabled={disabled}
        />
        {tooltipContent}
    </CheckboxGroup>
);

BaseCheckboxCellRenderer.propTypes = {
    onSelectCheckbox: PropTypes.func,
    onUnselectCheckbox: PropTypes.func,
    onIntermediateSelect: PropTypes.func,
    selected: PropTypes.any,
    selectedValues: PropTypes.array,
    isIntermediate: PropTypes.bool,
    disabled: PropTypes.bool,
    labelClassName: PropTypes.string,
};

export default BaseCheckboxCellRenderer;
