import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

const audienceLongCreationTimeIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.AUDIENCE_LONG_CREATION_TIME,
    type: INDICATION_TYPES.WARNING,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    message: (
        <FormattedMessage
            defaultMessage="<strong>Note:</strong> Audience creation is currently slower than usual. Please allow up to 2 weeks for your audience to become available."
            id="audience.editor.external-audience.audiences-long-creation-time-indication"
        />
    ),
});

export default audienceLongCreationTimeIndication;
