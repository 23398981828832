import React, { useCallback, useEffect } from 'react';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useContainerDropdown } from 'modules/tracking/hooks/useContainerDropdown';
import { DropdownPlaceholder } from '../GtmConnectForm/DropdownPlaceholder';
import { ContainerDropdownTooltip } from './ContainerDropdownTooltip';
import styles from './containerDropdown.module.scss';

export const ContainerDropdown = () => {
    const { value, options, onChange, isLoading } = useContainerDropdown();
    const { isTokenValid } = useGoogleApi();

    const { value: gtmAccount } = useFormFieldValue({ field: 'gtmAccount' });
    const { onChange: onGtmWorkspaceChange } = useFormFieldValue({ field: 'gtmWorkspace' });

    useEffect(() => {
        if (options?.length === 1) {
            onChange(options[0]);
        }
    }, [options, onChange]);

    const onChangeDropdown = useCallback(
        updatedValue => {
            onChange(updatedValue);
            onGtmWorkspaceChange(null);
        },
        [onChange, onGtmWorkspaceChange]
    );

    return (
        <FormField
            inputId="gtm-container-dropdown"
            label={
                <div className={styles['selector-label']}>
                    <FormattedMessage id="tracking.setup.gtm.container.label" defaultMessage="Select Container" />
                    <ContainerDropdownTooltip />
                </div>
            }
        >
            <div className={styles['dropdown-container']}>
                <BareDropdown
                    id="gtm-container-selector"
                    selectedValueObject={value}
                    disabled={!isTokenValid || !gtmAccount}
                    options={options}
                    placeholder={<DropdownPlaceholder isLoading={isLoading} />}
                    onChange={onChangeDropdown}
                />
            </div>
        </FormField>
    );
};
