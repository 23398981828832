import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import ThirdPartyBrandSafetySmartList from './ThirdPartyBrandSafetySmartList';
import ThirdPartyBrandSafetyTooltip from './ThirdPartyBrandSafetyTooltip';
import styles from '../commonEditor.module.scss';

const ThirdPartyBrandSafety = () => {
    const isPermitted = usePermissions('EXTERNAL_BRAND_SAFETY');

    return isPermitted ? (
        <FormField
            inputId="third-party-brand-safety"
            label={
                <FormattedMessage
                    id="campaign.editor.targeting.3rd.party.brand.safety.title"
                    defaultMessage="Pre-Bid Brand Safety"
                />
            }
            description={
                <FormattedMessage
                    id="campaign.editor.targeting.3rd.party.brand.safety.description"
                    defaultMessage="Protect your brand from serving on articles that do not meet your brand safety standards."
                />
            }
            helpText={<ThirdPartyBrandSafetyTooltip />}
            containerClass={styles['input']}
        >
            <ThirdPartyBrandSafetySmartList />
        </FormField>
    ) : null;
};

export default ThirdPartyBrandSafety;
