import { useCallback } from 'react';
import { stubTrue } from 'lodash';
import styleConsts from 'globalStyle/styleConsts.module.scss';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { TRACKING_ROUTE } from '../config';
import { generateConversionCreatorPath } from '../utils/generateTrackingPath';

export const useTrackingSetupFormState = (handleSubmit = stubTrue) => {
    const { step, setStep, prevStep, nextStep, submit } = useFormState();

    const submitWrapper = useCallback(async () => {
        const conversionCreatorPath = generateConversionCreatorPath();
        await submit(handleSubmit, conversionCreatorPath);
    }, [handleSubmit, submit]);

    const submitNavigateTracking = useCallback(async () => {
        await submit(handleSubmit, TRACKING_ROUTE);
    }, [handleSubmit, submit]);

    return {
        step,
        handleSetStep: setStep,
        prevStep,
        nextStep,
        submit: submitWrapper,
        submitNavigateTracking,
        wizardTemplateColumns: `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`,
    };
};
