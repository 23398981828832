import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import itemStyles from './cityTargetingListItem.module.scss';
import styles from './cityTargetingOptionLabel.module.scss';

const CityTargetingOptionLabel = ({ id, city, region, msgIdPrefix = '' }) => {
    return (
        <div className={styles['container']}>
            <div className="name">
                <FormattedMessage id={`${msgIdPrefix}.${id}.${region}.${city}`} defaultMessage={city} />
            </div>
            <div className={itemStyles['detail']}>
                <FormattedMessage id={`${msgIdPrefix}.${id}.${region}`} defaultMessage={region} />
            </div>
        </div>
    );
};

CityTargetingOptionLabel.propTypes = {
    id: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    msgIdPrefix: PropTypes.string,
};

export default CityTargetingOptionLabel;
