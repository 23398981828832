import { callGetApi } from './apiCallerProxy';

const apiPrefix = '/audience-insights';

const audienceInsightsApi = {
    getBaselines: (accountId, searchText, page, pageSize) =>
        callGetApi(`/${accountId}${apiPrefix}/baselines`, { paginationEnabled: true, searchText, page, pageSize }),
    getSegments: ({ accountId, ...options }) =>
        callGetApi(`/${accountId}${apiPrefix}/segments`, {
            paginationEnabled: true,
            ...options,
        }),
};

export default audienceInsightsApi;
