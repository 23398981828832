import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../modules/taboola-common-frontend-modules/i18n';
import CampaignMissingFeatureItemValue from './CampaignMissingFeatureItemValue';
import styles from './campaignMissingFeatureItem.module.scss';

const CampaignMissingFeatureItem = ({ messageId, tooltipContent, value }) => (
    <li className={styles['list-item']}>
        <FormattedMessage id={messageId} defaultMessage="Feature Name" />
        <CampaignMissingFeatureItemValue value={value} tooltipContent={tooltipContent} />
    </li>
);

CampaignMissingFeatureItem.propTypes = {
    messageId: PropTypes.node.isRequired,
    value: PropTypes.node,
    tooltipContent: PropTypes.node,
};

export default CampaignMissingFeatureItem;
