'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _MissingSVGIcon = require('./MissingSVGIcon');

var _MissingSVGIcon2 = _interopRequireDefault(_MissingSVGIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by dotan.l on 11/04/2019.
 */
var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'missingFeature__taboola-svg-icon___8rrFY',
    'container': 'missingFeature__container___idf5v',
    'icon': 'missingFeature__icon___1l4kR',
    'content': 'missingFeature__content___14FDm',
    'title': 'missingFeature__title___1acby',
    'description': 'missingFeature__description___GMclu'
};


/**
 * @deprecated - use from TA
 */
var MissingFeature = function MissingFeature(_ref) {
    var className = _ref.className,
        children = _ref.children,
        title = _ref.title,
        description = _ref.description,
        missingIcon = _ref.missingIcon;
    return _react2.default.createElement(
        'div',
        { className: styles['container'] + ' ' + className },
        _react2.default.createElement(
            'div',
            { className: styles['icon'] },
            missingIcon
        ),
        _react2.default.createElement(
            'div',
            { className: styles['content'] },
            _react2.default.createElement(
                'div',
                { className: styles['title'] },
                title
            ),
            _react2.default.createElement(
                'div',
                { className: styles['description'] },
                description
            ),
            _react2.default.createElement(
                'div',
                null,
                children
            )
        )
    );
};

MissingFeature.propTypes = {
    /** MissingFeature's class name */
    className: _propTypes2.default.string,
    /** MissingFeature's title */
    title: _propTypes2.default.node.isRequired,
    /** MissingFeature's description of what's missing */
    description: _propTypes2.default.node.isRequired,
    /** MissingFeature's children is meant for rendering some values of the missing feature */
    children: _propTypes2.default.node,
    /** MissingFeature's icon */
    missingIcon: _propTypes2.default.node
};

MissingFeature.defaultProps = {
    missingIcon: _react2.default.createElement(_MissingSVGIcon2.default, null),
    className: ''
};

exports.default = MissingFeature;