import { useQuery } from 'react-query';
import { useSelectedAccount } from 'hooks';
import { validationsApi } from 'services/api';

const HOUR = 60 * 60 * 1000;

export const useSpendingLimitOptions = () => {
    const [{ accountId }] = useSelectedAccount();
    const { data: hasMaxSpendingLimit } = useQuery(
        ['accountHasMaxSpendingLimit', accountId],
        () => validationsApi.hasMaxSpendingLimit(accountId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            cacheTime: HOUR,
            staleTime: HOUR,
        }
    );

    const isNoneSpendingLimitDisplayed = !hasMaxSpendingLimit;
    return isNoneSpendingLimitDisplayed;
};
