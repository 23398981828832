import { useCallback, useEffect, useState } from 'react';
import { isEmpty, isNil, noop, uniq } from 'lodash';
import { Wizard } from 'taboola-ultimate-ui';
import { STEPS } from 'modules/campaigns/modules/common-campaign-form/config/steps';
import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { useCurrentValueGetter } from '../../../../hooks';
import { useChatAgentContext } from '../../chat-agent/hooks/useChatAgentContext';

export const useFormWizardSteps = ({
    validate,
    handleValidationError,
    optionalValidate,
    initialStep = STEPS.FIRST_STEP,
    skipChatContextRegistration,
}) => {
    const { registerWizard } = useChatAgentContext();
    const [step, setStep] = useState(initialStep);
    const [stepList, setStepList] = useState([]);
    const stepListGetter = useCurrentValueGetter(stepList);
    const registerStep = useCallback(newStep => {
        setStepList(prev => {
            const newList = uniq([...prev, newStep]);
            newList.sort();

            return newList;
        });

        setStep(step => {
            if (Wizard.getStepAsArray(newStep)[0] === step && Wizard.getStepAsArray(newStep).length > 1) {
                return newStep;
            }
            return step;
        });

        const unregister = newStep => {
            setStepList(prev => prev.filter(val => val !== newStep));
        };

        return unregister;
    }, []);

    const handleSetStep = useCallback(
        async (value, { skipValidation } = {}) => {
            if (isNil(value)) {
                throwCriticalError(new Error(`Unknown form step navigation ${value}`));
                return;
            }
            const newStep = `${value}`;
            if (newStep < step) {
                setStep(newStep);
                return;
            }
            const isFormValid = skipValidation || (await validate());
            if (!isFormValid) {
                handleValidationError();
                return;
            }

            const isOptionalValid = await optionalValidate(() => setStep(newStep), noop);
            if (isOptionalValid) {
                setStep(newStep);
            }
        },
        [handleValidationError, validate, optionalValidate, step]
    );
    const prevStep = useCallback(async () => {
        if (isEmpty(stepList)) {
            if (step !== STEPS.FIRST_STEP) {
                await handleSetStep(`${+step - 1}`);
            }
            return;
        }
        const smartPrevList =
            step.length === 1 ? stepList : stepList.filter(val => val !== Wizard.getStepAsArray(step)[0]);
        const prevValue = smartPrevList[smartPrevList.findIndex(val => val === step) - 1];
        await handleSetStep(prevValue);
    }, [handleSetStep, step, stepList]);
    const nextStep = useCallback(
        async (event, { skipValidation } = {}) => {
            if (isEmpty(stepList)) {
                await handleSetStep(`${+step + 1}`, { skipValidation });
                return;
            }
            const nextValue = stepList[stepList.findIndex(val => val === step) + 1] || stepList[0];
            await handleSetStep(nextValue);
        },
        [handleSetStep, step, stepList]
    );

    useEffect(() => {
        if (skipChatContextRegistration) {
            return;
        }
        const unregister = registerWizard({
            setWizardStep: (step = 1) => {
                setStep(prev => `${+prev + step}`);
            },
        });

        return unregister;
    }, [registerWizard, skipChatContextRegistration]);

    // we forget all sub steps for step higher than current for dynamic new registration
    const rootStep = Wizard.getStepAsArray(step)[0];
    useEffect(() => {
        if (isEmpty(stepListGetter())) {
            return;
        }
        setStepList(list =>
            list.filter(val => Wizard.getStepAsArray(val).length === 1 || Wizard.getStepAsArray(val)[0] <= rootStep)
        );
    }, [rootStep, stepListGetter]);

    return {
        step,
        setStep: handleSetStep,
        prevStep,
        nextStep,
        registerStep,
    };
};
