import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import { normalizedPlatformReportColumnDefinition } from './columnDefinitions';
import { platformReportFooterColumnDefinition } from './footerColumnDefinitions';
import { graphConfigs } from './graph';
import { defaultColumns, customColumns } from './reportPresets';

export const platformRealtimeReportByPlatformConfig = {
    ...reportsBaseConfig[REPORT_TYPE.PLATFORM_REALTIME],
    endpoint: 'campaign-realtime-report-by-platform',
    graph: graphConfigs,
    rowIdField: FIELDS.PLATFORM_CODE.field,
    columnsDef: normalizedPlatformReportColumnDefinition,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    sortMap: {
        [FIELDS.PLATFORM_CODE.field]: 'platform',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    footerColumnDef: platformReportFooterColumnDefinition,
    customRestrictedFilters: {},
    filters: [
        {
            id: FILTER_TYPE.COUNTRY,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
        {
            id: FILTER_TYPE.SITE,
            permissions: ['TA_REAL_TIME_REPORT_FILTERS'],
        },
    ],
};

export default platformRealtimeReportByPlatformConfig;
