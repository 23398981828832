import React from 'react';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { AggregatedConversionRadio } from 'modules/tracking/components/AggregatedConversionRadio/AggregatedConversionRadio';
import { LookBackWindowField } from 'modules/tracking/components/LookBackWindowField/LookBackWindowField';
import { VtaLookBackWindowField } from 'modules/tracking/components/VtaLookBackWindowField/VtaLookBackWindowField';
import styles from './conversionConfiguration.module.scss';

export const ConversionConfiguration = () => {
    const isEnabled = useConfigMatch({
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    });

    return isEnabled ? (
        <FormNamespaceProvider field="conversionConfiguration">
            <div className={styles['list']}>
                <LookBackWindowField />
                <VtaLookBackWindowField />
                <AggregatedConversionRadio />
            </div>
        </FormNamespaceProvider>
    ) : null;
};
