import React from 'react';
import { PropTypes } from 'prop-types';
import { TooltipV2 as Tooltip, StyledButton, STYLED_BUTTON_TYPE, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './campaignPickerListItem.module.scss';

const CampaignPickerListItemEditButton = ({ onClick }) => {
    return (
        <span className={styles['edit-button-container']}>
            <StyledButton
                aria-label="Edit campaign"
                className={styles['edit-button']}
                iconBefore="edit-o"
                iconBeforeClassName={styles['edit-icon']}
                onClick={onClick}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
            />
            <Tooltip position={TOOLTIP_POSITION.TOP_END} arrow offsetBody={1}>
                <FormattedMessage id="app.campaigns.editCampaign" defaultMessage="Edit Campaign" />
            </Tooltip>
        </span>
    );
};

CampaignPickerListItemEditButton.propTypes = {
    onClick: PropTypes.func,
};

export default CampaignPickerListItemEditButton;
