import React from 'react';
import { batch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { campaignsApi } from 'services/api';
import { errorMessagesUtils } from '../../../services/utils';
import {
    deleteCampaignError,
    deleteCampaignSuccess,
    forceCampaignPickerReload,
    requestDeleteCampaign,
} from '../actions';

export const successIndication = {
    message: <FormattedMessage id="campaign.delete.success" defaultMessage="Campaign successfully deleted." />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage id="campaign.delete.error.highlight" defaultMessage="Unable to delete the campaign." />
    ),
});

const deleteCampaign = (accountId, campaignId, entityType) => async dispatch => {
    dispatch(requestDeleteCampaign());
    try {
        const response = await campaignsApi.deleteCampaign({ accountId, campaignId, entityType });
        batch(() => {
            dispatch(deleteCampaignSuccess(response));
            dispatch(addIndication(successIndication));
            dispatch(forceCampaignPickerReload());
        });
    } catch (error) {
        batch(() => {
            dispatch(deleteCampaignError(error));
            dispatch(addIndication(generateErrorIndication(error)));
        });
    }
};

export default deleteCampaign;
