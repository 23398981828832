import { useMemo } from 'react';
import { get, isNaN, parseInt, sum } from 'lodash';
import { FEATURE_FLAGS, useAccountConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { SEGMENT_GROUP_TYPE } from '../../MarketplaceAudiences/utils/andLogicUtils';

const getSanitizedIntFlag = strValue => {
    const result = parseInt(strValue);

    return isNaN(result) ? 0 : result;
};

const ACCOUNT_CONFIGS = [
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_SEGMENT_LIMIT,
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_SEGMENT_LIMIT,
    FEATURE_FLAGS.MARKETPLACE_AUDIENCES_MIXED_SEGMENT_LIMIT,
];

export const useSegmentTypeLimits = () => {
    const configLimits = useAccountConfig(ACCOUNT_CONFIGS);

    const limits = useMemo(
        () => ({
            [SEGMENT_GROUP_TYPE.AGE]: getSanitizedIntFlag(
                get(configLimits, FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AGE_SEGMENT_LIMIT)
            ),
            [SEGMENT_GROUP_TYPE.GENDER]: getSanitizedIntFlag(
                get(configLimits, FEATURE_FLAGS.MARKETPLACE_AUDIENCES_GENDER_SEGMENT_LIMIT)
            ),
            [SEGMENT_GROUP_TYPE.OTHER]: getSanitizedIntFlag(
                get(configLimits, FEATURE_FLAGS.MARKETPLACE_AUDIENCES_MIXED_SEGMENT_LIMIT)
            ),
        }),
        [configLimits]
    );

    const maxTargetings = useMemo(() => sum(Object.values(limits)), [limits]);

    return { limits, maxTargetings };
};
