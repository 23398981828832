import React from 'react';
import classnames from 'classnames/bind';
import { startCase, lowerCase } from 'lodash';
import { StatusIndicator } from 'tuui';
import { Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { getCampaignStatusType, getStatusIndicatorType } from '../../../../../config';
import { CampaignItemTooltip } from '../CampaignItemTooltip/CampaignItemTooltip';
import styles from './campaignItemDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CampaignItemDropdownOption = ({ data, selectProps: { inputValue, isOptionDisabled } }) => {
    const { id, name, marketingObjective, status, isPartOfSharedBudgetCampaignsGroup } = data;
    const disabled = isOptionDisabled(data);
    const { formatMessage } = useIntl();
    const formattedMarketingObjective = startCase(
        lowerCase(formatMessage({ id: `campaign.marketing.objective.${marketingObjective}.title` }))
    );
    const statusType = getCampaignStatusType(status);
    const statusIndicatorType = getStatusIndicatorType(statusType);

    return (
        <div className={styles['container']} role="listitem">
            <CampaignItemTooltip
                disabled={disabled}
                status={status}
                isPartOfSharedBudgetCampaignsGroup={isPartOfSharedBudgetCampaignsGroup}
            />
            <div className={styles['indicator']}>
                {statusIndicatorType && <StatusIndicator type={statusIndicatorType} />}
            </div>
            <div className={classNameBuilder('name', { disabled })}>
                <Dotdotdot lines={2}>
                    <Highlighter text={`${name} (${id})`} highlightedText={inputValue} />
                </Dotdotdot>
            </div>
            <div className={classNameBuilder('marketing-object', { disabled })}>{formattedMarketingObjective}</div>
        </div>
    );
};
