import { useMutation, useQueryClient } from 'react-query';
import { useBillingApi } from '../../../../services/api';
import { useBillingQueryKeys } from './useBillingQueryKeys';

export const useBillingActions = () => {
    const queryClient = useQueryClient();
    const {
        createPaymentMethod: createPaymentMethodApiCall,
        createAchBankDirectDebitPaymentMethod: createAchBankDirectDebitPaymentMethodApiCall,
        updatePaymentMethod: updatePaymentMethodApiCall,
        deletePaymentMethod: deletePaymentMethodApiCall,
        deletePaymentMethodV2: deletePaymentMethodV2ApiCall,
        addFunds: addFundsApiCall,
        cancelFunds: cancelFundsApiCall,
        cashRefund: cashRefundApiCall,
    } = useBillingApi();
    const { billingDataQueryKey, getPaymentMethodsV2QueryKey } = useBillingQueryKeys();

    const { mutateAsync: createPaymentMethod } = useMutation(createPaymentMethodApiCall, {
        onSuccess: result => {
            queryClient.setQueryData(getPaymentMethodsV2QueryKey, prevData => [...prevData, { ...result }]);
        },
    });

    const { mutateAsync: createAchBankDirectDebitPaymentMethod } = useMutation(
        createAchBankDirectDebitPaymentMethodApiCall,
        {
            onSuccess: result => {
                queryClient.setQueryData(getPaymentMethodsV2QueryKey, prevData => {
                    return [...prevData, { ...result }];
                });
            },
        }
    );

    const { mutateAsync: updatePaymentMethod } = useMutation(updatePaymentMethodApiCall, {
        onSuccess: result => {
            queryClient.setQueryData(getPaymentMethodsV2QueryKey, prevData => {
                return prevData.map(paymentMethod => (paymentMethod.id === result.id ? result : paymentMethod));
            });
        },
    });

    const { mutateAsync: addFunds, isLoading: isLoadingAddFunds } = useMutation(addFundsApiCall, {
        onSuccess: () => {
            queryClient.setQueryData(billingDataQueryKey, prevData => {
                return {
                    ...prevData,
                    hasPendingPrepayment: true,
                };
            });
        },
    });

    const { mutateAsync: cancelFunds } = useMutation(cancelFundsApiCall, {
        onSuccess: () => {
            queryClient.setQueryData(billingDataQueryKey, prevData => {
                return {
                    ...prevData,
                };
            });
        },
    });

    const { mutateAsync: cashRefund } = useMutation(cashRefundApiCall, {
        onSuccess: () => {
            queryClient.setQueryData(billingDataQueryKey, prevData => {
                return {
                    ...prevData,
                };
            });
        },
    });

    const { mutateAsync: deletePaymentMethod } = useMutation(deletePaymentMethodApiCall, {
        onSuccess: (result, variables) => {
            queryClient.setQueryData(getPaymentMethodsV2QueryKey, prevData => {
                const { paymentMethodId, paymentMethodType } = variables;
                return prevData.filter(
                    paymentMethod =>
                        paymentMethod.paymentMethodType !== paymentMethodType ||
                        (paymentMethod.paymentMethodType === paymentMethodType && paymentMethod.id !== paymentMethodId)
                );
            });
        },
    });

    const { mutateAsync: deletePaymentMethodV2 } = useMutation(deletePaymentMethodV2ApiCall, {
        onSuccess: (result, variables) => {
            queryClient.setQueryData(getPaymentMethodsV2QueryKey, prevData => {
                const { paymentMethodId, paymentMethodType } = variables;
                return prevData.filter(
                    paymentMethod =>
                        paymentMethod.paymentMethodType !== paymentMethodType ||
                        (paymentMethod.paymentMethodType === paymentMethodType && paymentMethod.id !== paymentMethodId)
                );
            });
        },
    });

    return {
        createPaymentMethod,
        createAchBankDirectDebitPaymentMethod,
        updatePaymentMethod,
        addFunds,
        cancelFunds,
        cashRefund,
        isLoadingAddFunds,
        deletePaymentMethod,
        deletePaymentMethodV2,
    };
};
