import createGridValueFormatter from './createGridValueFormatter';

export const campaignGoalValueFormatter = ({ value, i18n }) => {
    if (value == null) {
        return i18n.formatMessage({
            id: 'app.campaigns.reports.grid.column.campaignConversionRules.default',
            default: 'Account default',
        });
    }
    return value;
};
export const gridCampaignGoalValueFormatter = createGridValueFormatter(campaignGoalValueFormatter);
