import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

const MatchTypeTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.audience.match-type.broad"
                defaultMessage='"<b>Broad Match</b> (default): Expands your reach by targeting users searching for keywords related to your chosen term. For example, selecting "running shoes" might also capture searches for "sports footwear" or "trail running"."'
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.audience.match-type.exact"
                defaultMessage='"<b>Exact Match</b>: Targets users who search for your specific keyword, ensuring a precise match. If you choose "running shoes," only searches exactly matching "running shoes" will be included."'
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="audience.editor.audience.match-type.exact.note"
                defaultMessage="Note: Broad match is available for English and Traditional Chinese only."
            />
        </TooltipSection>
    </div>
);

export default MatchTypeTooltip;
