import React from 'react';
import { BodySection } from './BodySection/BodySection';
import { HeaderSection } from './HeaderSection/HeaderSection';

export const OnboardingStepDescription = ({
    titleId,
    descriptionId,
    motivationId,
    learnMoreLinkId,
    isComplete,
    isCollapsed,
}) => {
    return (
        <div>
            <HeaderSection
                titleId={titleId}
                learnMoreLinkId={learnMoreLinkId}
                isComplete={isComplete}
                isCollapsed={isCollapsed}
            />
            <BodySection descriptionId={descriptionId} motivationId={motivationId} isCollapsed={isCollapsed} />
        </div>
    );
};
