import { useCallback, useMemo, useState } from 'react';
import { groupBy, reduce } from 'lodash';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { SUBCATEGORY_ALL } from '../../../../../services/transformers/osTargetingTransformer';
import { getUniqId } from '../utils';

const transformLeavesToValues = (groupedListOfOs, leaves) => {
    const groupedLeaves = groupBy(leaves, 'osFamily');

    return reduce(
        groupedLeaves,
        (result, group, osFamily) => {
            if (groupedListOfOs[osFamily].length === group.length) {
                result.push({ osFamily, id: SUBCATEGORY_ALL, subCategory: SUBCATEGORY_ALL });
                return result;
            }

            return [...result, ...group];
        },
        []
    );
};

const transformValuesToLeaves = (groupedListOfOs, values) => {
    return reduce(
        values,
        (result, value) => {
            if (value.id === SUBCATEGORY_ALL) {
                const group = groupedListOfOs[value.osFamily] || [];
                return [...result, ...group];
            }

            result.push(value);
            return result;
        },
        []
    );
};

const transformResourcesToLeaves = (list, valuesToFilter = []) => {
    const groupedListOfOs = groupBy(list, 'osFamily');
    const filteredOs = list.filter(
        ({ osFamily, subCategory: { id } }) =>
            (id !== SUBCATEGORY_ALL && !valuesToFilter.some(value => value === id)) ||
            groupedListOfOs[osFamily].length === 1
    );

    const results = filteredOs.map(({ osFamily, subCategory: { id, label } }) => ({
        id: getUniqId(id, osFamily),
        label,
        taxonomy: id === SUBCATEGORY_ALL ? '' : `${osFamily}`,
        osFamily,
        subCategory: id,
    }));

    return results;
};

const useOsTargetingService = valuesToFilter => {
    const { formatMessage } = useIntl();
    const [listOfOs, setListOfOs] = useState([]);
    const groupedListOfOs = useMemo(() => groupBy(listOfOs, 'osFamily'), [listOfOs]);

    const leavesToValues = useCallback(leaves => transformLeavesToValues(groupedListOfOs, leaves), [groupedListOfOs]);
    const valuesToLeaves = useCallback(values => transformValuesToLeaves(groupedListOfOs, values), [groupedListOfOs]);

    const setListOfOsWrapper = useCallback(
        results => {
            const leaves = transformResourcesToLeaves(results, valuesToFilter);
            setListOfOs(leaves);
            return leaves;
        },
        [valuesToFilter]
    );

    const getTaxonomyValue = useCallback(
        ({ id, label, osFamily }) => {
            const messageId = `app.os.${osFamily ? osFamily + '.' : ''}${id}`;
            return formatMessage({ id: messageId, defaultMessage: label || id });
        },
        [formatMessage]
    );

    return {
        listOfOs,
        leavesToValues,
        valuesToLeaves,
        setListOfOs: setListOfOsWrapper,
        getTaxonomyValue,
    };
};

export default useOsTargetingService;
