import { mapValues } from 'lodash';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { COMPONENT_STATUS } from '../../../../../services/constants';
import * as ACTION_TYPES from '../actions';
import { CONTENT_METHODS } from '../config';

const propsToPersist = ['contentMethod', 'shouldShowRemoveVariationModal', 'shouldCrawlOneByOneUrls'];

const initialState = {
    creativeCreatorFormStatus: COMPONENT_STATUS.ACTIVE,
    contentMethod: CONTENT_METHODS.AI_VARIATIONS,
    shouldShowRemoveVariationModal: true,
    shouldCrawlOneByOneUrls: true,
};

const mapValuesConditionally = (obj, updateObj, checkIfShouldUpdate) => {
    return mapValues(obj, values => {
        if (checkIfShouldUpdate(values)) {
            return { ...values, ...updateObj };
        } else {
            return values;
        }
    });
};

export const getUpdatedPreviews = (creativePreviews, thumbnailUrl, update) => {
    return mapValues(creativePreviews, previews => {
        const checkIfShouldUpdate = ({ thumbnailUrl: imgUrl }) => imgUrl === thumbnailUrl;
        return mapValuesConditionally(previews, update, checkIfShouldUpdate);
    });
};

const reducer = {
    [ACTION_TYPES.SET_CONTENT_METHOD]: (state, action) => updateObject(state, { contentMethod: action.payload }),
    [ACTION_TYPES.SET_SHOULD_SHOW_REMOVE_VARIATION_MODAL]: (state, action) =>
        updateObject(state, { shouldShowRemoveVariationModal: action.payload }),
    [ACTION_TYPES.SET_SHOULD_CRAWL_ONE_BY_ONE_URLS]: (state, action) =>
        updateObject(state, { shouldCrawlOneByOneUrls: action.payload }),
};

export { initialState, reducer, propsToPersist };
