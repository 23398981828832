const CONTENT_METHODS = {
    VARIATIONS: 'VARIATIONS',
    ONE_BY_ONE: 'ONE_BY_ONE',
    RSS: 'RSS',
    AI_VARIATIONS: 'AI_VARIATIONS',
    CAROUSEL: 'CAROUSEL',
};
const CONTENT_METHODS_KEYS_ARRAY = Object.values(CONTENT_METHODS);

export { CONTENT_METHODS, CONTENT_METHODS_KEYS_ARRAY };
