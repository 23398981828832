import React from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './addFundsModal.module.scss';

const classNameBuilder = classnames.bind(styles);

export const DisabledPaymentMethodsDropdown = () => {
    const noPaymentOption = {
        label: (
            <FormattedMessage
                id="billingAndPayments.modal.noPaymentMethods"
                defaultMessage="No available payment methods"
            />
        ),
        value: 'disabled_item',
    };
    const options = [noPaymentOption];

    return (
        <DropdownMenu
            disabled={true}
            options={options}
            onChange={noop}
            className={classNameBuilder('drop-down-menu', 'disabled')}
            selectedValueObject={noPaymentOption}
        />
    );
};
