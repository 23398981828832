import React, { useCallback } from 'react';
import { DeletableItem } from 'taboola-ultimate-ui';

export const ScheduledReportListItem = ({ item, deleteItem }) => {
    const { id: reportId, name } = item;

    const handleOnClick = useCallback(() => {
        deleteItem(reportId);
    }, [deleteItem, reportId]);

    return (
        <DeletableItem
            buttonProps={{
                'aria-label': `${name}`,
            }}
            onDelete={handleOnClick}
        >
            {name}
        </DeletableItem>
    );
};
