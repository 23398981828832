import { createSelector } from 'reselect';
import { accountsMapSelector, appStateSelector } from './baseSelectors';

const currentRootAccountDataSelector = createSelector(appStateSelector, appState => appState.currentRootAccountId);

export const currentRootAccountSelector = createSelector(
    currentRootAccountDataSelector,
    accountsMapSelector,
    (currentRootAccount, accountsMap) => accountsMap[currentRootAccount]
);

export default currentRootAccountSelector;
