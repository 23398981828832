import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LinkIcon, LinkButton, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { useIsHeavyReport } from 'hooks';
import { useConfigMatch, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { PermissionsGuard } from 'modules/taboola-common-frontend-modules/authentication';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { ExternalLink } from '../../../../../../components';
import { OnSitePreviewModal } from '../OnSitePreviewModal';
import styles from './creativePreviewHeader.module.scss';

const CreativePreviewHeader = ({ onEdit, editPermissionsProps, items }) => {
    const { open: openModal } = useModal();
    const isHeavyReport = useIsHeavyReport();
    const openOnSitePreviewModal = useCallback(() => {
        openModal({
            title: <FormattedMessage id="app.modal.onSitePreview.title" tagName="h2" defaultMessage="Open Preview" />,
            contentRenderer: OnSitePreviewModal,
        });
    }, [openModal]);

    const { formatMessage } = useIntl();
    const href = formatMessage({
        id: 'creative.preview.sub.header.link.href',
        defaultMessage: 'https://help.taboola.com/hc/en-us/articles/115006880507-Title-and-Thumbnail-Best-Practices',
    });
    const bestPracticesLink = (
        <ExternalLink
            href={href}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="Preview best practices"
            data-metrics-value="best practices"
        >
            <FormattedMessage id="creative.preview.sub.header.link.text" defaultMessage="best practices" />
        </ExternalLink>
    );
    const isOnSiteFeedPreviewEnabled = useConfigMatch({
        [FEATURE_FLAGS.ON_SITE_FEED_PREVIEW]: 'true',
    });

    const itemCount = items && items.filter(({ deleted }) => !deleted).length;

    return (
        <div className={styles['header']}>
            <div className={styles['header-label']}>
                <FormattedMessage
                    id="creative.preview.header"
                    defaultMessage="Preview {count}"
                    values={{ count: itemCount ? `(${itemCount})` : '' }}
                />
                {!!onEdit && !isHeavyReport && (
                    <PermissionsGuard permissions={editPermissionsProps}>
                        <LinkButton onClick={onEdit} className={styles['edit-button']}>
                            <FormattedMessage id="creative.preview.edit" defaultMessage="Edit" />
                        </LinkButton>
                    </PermissionsGuard>
                )}
            </div>
            <div className={styles['header-sub-label']}>
                <div>
                    <FormattedMessage
                        id="creative.preview.sub.header"
                        defaultMessage="View your ads on various placements across the Taboola network, see {bestPracticesLink}."
                        values={{ bestPracticesLink }}
                    />
                </div>
                {isOnSiteFeedPreviewEnabled && (
                    <div>
                        <LinkIcon
                            className={styles['link-icon']}
                            onClick={openOnSitePreviewModal}
                            data-metrics-event-name={GTM_EVENTS.USABILITY}
                            data-metrics-component="Preview best practices"
                            data-metrics-value="on-site preview"
                        />
                        <Tooltip position={TOOLTIP_POSITION.LEFT} arrow>
                            <FormattedMessage
                                id="creative.preview.sub.header.link.icon.tooltip"
                                defaultMessage="On Site Preview: See how your ad will appear on a real Taboola Feed."
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

CreativePreviewHeader.propTypes = {
    onEdit: PropTypes.func,
    editPermissionsProps: permissionsShape,
};

export default CreativePreviewHeader;
