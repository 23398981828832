import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DailyImpressionCap } from './DailyImpressionCap';
import { DailyImpressionCapFieldTooltip } from './DailyImpressionCapFieldTooltip';
import styles from '../../../common-campaign-form/components/commonEditor.module.scss';

export const DailyImpressionCapField = () => {
    return (
        <FormField
            inputId="daily-impression-cap"
            label={
                <FormattedMessage
                    id="video.campaign.editor.DailyImpressionCap.title"
                    defaultMessage="Daily Impression Cap (Optional)"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.DailyImpressionCap.description"
                    defaultMessage="Defines a daily limitation of Video Impression"
                />
            }
            helpText={<DailyImpressionCapFieldTooltip />}
            containerClass={styles['input']}
        >
            <DailyImpressionCap />
        </FormField>
    );
};
