import React from 'react';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

export const TopicsLanguageFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.setup.topics.field.tooltip.title"
                defaultMessage="Select the topics you wish to include in your Topics segment."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.setup.topics.field.tooltip.text"
                defaultMessage="After you select a topic to include, click again to see related topics."
            />
        </TooltipSection>
    </>
);
