"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCampaigns(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M3.832 16.02l.008.032c.202.729-.243 1.478-.989 1.673-.748.196-1.515-.237-1.716-.965L.048 12.799c-.2-.73.243-1.48.99-1.675a1.418 1.418 0 0 1 1.531.566l9.697-6.469c.777-.399 1.613-.122 1.822.644l2.661 9.688c.209.764-.372 1.413-1.249 1.447l-2.392-.355c.14 1.313-.705 2.567-2.053 2.919l-1.352.354c-1.494.39-3.03-.475-3.43-1.934l-.505-1.833-1.936-.131zM19.567 3.605c.298-.311.79-.301 1.1.025.31.324.32.84.024 1.153l-2.946 3.012c-.295.312-.788.303-1.098-.023-.31-.325-.32-.841-.025-1.154l2.945-3.013zm-.796 8.352l4.53 1.362a.858.858 0 0 1 .572 1.011.743.743 0 0 1-.941.556l-4.41-1.35a.857.857 0 0 1-.57-1.011c.101-.432.402-.694.819-.568zm.618-1.648a.7.7 0 0 1-.856-.483.681.681 0 0 1 .494-.837l3.611-.957a.699.699 0 0 1 .856.483.68.68 0 0 1-.494.837l-3.61.957zm-8.695 7.935a1.365 1.365 0 0 0 .99-1.675l-.007-.02-4.43-.3.378 1.382c.2.729.968 1.162 1.715.967l1.354-.354zm.632-8.542a.677.677 0 0 0 .351-.904.709.709 0 0 0-.927-.342l-5.106 2.248a.677.677 0 0 0-.352.903.71.71 0 0 0 .927.342l5.107-2.247z"
            })
        )
    );
}
exports.default = SvgCampaigns;