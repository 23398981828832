import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    StyledButton,
    FilterIcon,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    TooltipV2 as Tooltip,
    TOOLTIP_POSITION,
} from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useMarketplaceAudiencesContext } from '../MarketplaceAudiencesContext';
import styles from './providerSelectIcon.module.scss';

const classNameBuilder = classnames.bind(styles);

const ProvidersSelectIcon = ({ innerRef, innerProps, selectProps: { menuIsOpen } }) => {
    const { campaignId } = useMarketplaceAudiencesContext();

    return (
        <div ref={innerRef} {...innerProps}>
            <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} arrow duration={0} delay={800}>
                <div className={styles['tooltip-text']}>
                    <FormattedMessage
                        id="campaign.editor.marketplace.audiences.providers.tooltip"
                        defaultMessage="Partners"
                    />
                </div>
            </Tooltip>
            <StyledButton
                className={classNameBuilder('container', { open: menuIsOpen, close: !menuIsOpen })}
                size={STYLED_BUTTON_SIZE.SMALL}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="Marketplace Filter Icon"
                data-metrics-taboola-campaign-id={campaignId}
                aria-label="toggle providers dropdown"
            >
                <FilterIcon />
            </StyledButton>
        </div>
    );
};

ProvidersSelectIcon.propTypes = {
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
    selectProps: PropTypes.shape({ menuIsOpen: PropTypes.bool }),
};

export default ProvidersSelectIcon;
