import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { ERROR_CODES } from '../../../../services/api/apiConstants';
import { gtmTracker, GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import {
    MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID,
    THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
    VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
    VIDEO_URL_UNSUPPORTED_FORMAT_ERROR_MESSAGE_ID,
} from '../../modules/creative-creator/config/thumbnailValidationConsts';

const notificationType = 'Image Validation';
const maxImageSizeEventText = 'Image too large (Max size is 5 Mb.)';
const minImageSizeEventText = 'Image dimensions must be larger than 400x350 pixels';
const imageTypeEventText = 'Image file loading has failed';
const videoTypeEventText = 'Video file loading has failed';
const unsupportedVideoTypeEventText = 'format type unsupported';

const maxImageSizeEvent = {
    pageName: getPageName(),
    notificationType,
    notificationStatus: maxImageSizeEventText,
    notificationMessage: maxImageSizeEventText,
};
const minImageSizeEvent = {
    pageName: getPageName(),
    notificationType,
    notificationStatus: minImageSizeEventText,
    notificationMessage: minImageSizeEventText,
};

const imageTypeEvent = {
    pageName: getPageName(),
    notificationType,
    notificationStatus: imageTypeEventText,
    notificationMessage: imageTypeEventText,
};

const videoTypeEvent = {
    pageName: getPageName(),
    notificationType,
    notificationStatus: videoTypeEventText,
    notificationMessage: videoTypeEventText,
};

const unsupportedVideoTypeEvent = {
    pageName: getPageName(),
    notificationType,
    notificationStatus: unsupportedVideoTypeEventText,
    notificationMessage: unsupportedVideoTypeEventText,
};

const imageErrorEventMap = {
    [ERROR_CODES.TOO_LARGE]: maxImageSizeEvent,
    [MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID]: minImageSizeEvent,
    [THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID]: imageTypeEvent,
    [VIDEO_URL_LOAD_FAILED_ERROR_MESSAGE_ID]: videoTypeEvent,
    [VIDEO_URL_UNSUPPORTED_FORMAT_ERROR_MESSAGE_ID]: unsupportedVideoTypeEvent,
};

const trackImageErrorEvent = error => {
    if (imageErrorEventMap[error.status]) {
        gtmTracker.trackEvent(GTM_EVENTS.NOTIFICATION, imageErrorEventMap[error.status]);
    }
};

export default trackImageErrorEvent;
