import React from 'react';
import favoritedMediaItemsApi from 'services/api/favoritedMediaItemsApi';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

const successIndication = {
    message: (
        <FormattedMessage
            id="creative.studio.mediaLibrary.success"
            defaultMessage="Image is saved to the Media Library"
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const errorIndication = {
    message: (
        <FormattedMessage
            id="creative.studio.mediaLibrary.error"
            defaultMessage="Unable to save image to the Media Library"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const favoriteMedia =
    ({ accountId, thumbnailUrl, isFavorited }) =>
    async dispatch => {
        try {
            await favoritedMediaItemsApi.favoriteMediaItem(accountId, thumbnailUrl, isFavorited);
            dispatch(addIndication(successIndication));
        } catch (error) {
            dispatch(addIndication(errorIndication));
        }
    };
