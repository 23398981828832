import { keys } from 'lodash';
import { FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { isAdminNetworkAccountType, isProgrammaticAccount } from '../../../../../../account-management/accountType';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { CAMPAIGN_STATUS } from '../../../../../config/campaignsConsts';
import { TOP_RESULTS_TYPE } from '../../../../../constants';
import { updateCampaigns } from '../../../../../flows';
import { BULK_ACTION } from '../../../components/BulkActionToolbar/bulkAction';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { graph } from './graph';
import { customColumns, defaultColumns, performanceColumns, setupColumns } from './reportPresets';

export const campaignReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CAMPAIGN],
    endpoint: 'campaign-report-by-campaign-daily',
    graph,
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
        [REPORT_PRESET.PERFORMANCE]: { columns: performanceColumns },
        [REPORT_PRESET.SETUP]: { columns: setupColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    inlineEditPermissions: ['CAMPAIGN_EDIT'],
    searchPlaceholderMsgId: 'app.reports.byCampaign.search.placeholder',

    // TODO: DEV-58584 need to convert filter names in the GW. replace sortField + remove sortMap
    // searchField: NAME.field
    searchField: FIELDS.CAMPAIGN_NAME.field,
    sortMap: {
        [FIELDS.NAME.field]: FIELDS.CAMPAIGN_NAME.field,
        [FIELDS.ID.field]: 'campaignId',
        [FIELDS.STATUS.field]: 'campaignStatus',
        [FIELDS.DEMAND_TYPE.field]: 'type',
    },
    bulkActions: [
        {
            key: BULK_ACTION.PLAY,
            handler:
                ({ accountName, selectedRows }) =>
                dispatch =>
                    dispatch(updateCampaigns(accountName, keys(selectedRows), { isActive: true })),
        },
        {
            key: BULK_ACTION.PAUSE,
            handler:
                ({ accountName, selectedRows }) =>
                dispatch =>
                    dispatch(updateCampaigns(accountName, keys(selectedRows), { isActive: false })),
        },
    ],
    initialFilters: [
        {
            id: FILTER_TYPE.CAMPAIGN_STATUS,
            type: FILTER_TYPE.CAMPAIGN_STATUS,
            values: [
                { value: CAMPAIGN_STATUS.RECENT, label: 'Recent', messageId: 'app.campaigns.campaign.status.RECENT' },
            ],
            accountConfigurations: { [FEATURE_FLAGS.REPORT_AUTO_FILTERS]: 'true' },
        },
        {
            id: FILTER_TYPE.TOP_RESULTS,
            type: FILTER_TYPE.TOP_RESULTS,
            values: [{ value: TOP_RESULTS_TYPE.TOP100, label: 'Top 100', messageId: 'report.topResults.100' }],
            accountConfigurations: {
                [FEATURE_FLAGS.HEAVY_ACCOUNT]: 'true',
                [FEATURE_FLAGS.CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
            },
        },
    ],
    filters: [
        { id: FILTER_TYPE.CAMPAIGN_STATUS, multiSelect: true },
        {
            id: FILTER_TYPE.TOP_RESULTS,
            accountConfigurations: {
                [FEATURE_FLAGS.CAMPAIGN_REPORT_TOP_RESULTS_FILTER_ACTIVE]: 'true',
            },
        },
        { id: FILTER_TYPE.PLATFORM },
        { id: FILTER_TYPE.COUNTRY },
        { id: FILTER_TYPE.SITE },
        {
            id: FILTER_TYPE.OS_FAMILY,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.OS_VERSION,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.BROWSER,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.TARGET_PAGE_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.IAB_CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOULD_DISPLAY_IAB_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.LANGUAGE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.DEMAND_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            visibilityCondition: ({ selectedAccount }) => isProgrammaticAccount(selectedAccount),
        },
        {
            id: FILTER_TYPE.CAMPAIGNS_GROUP,
            permissions: ['ADS_CONSOLE_CAMPAIGNS_GROUP_FILTER'],
        },
        { id: FILTER_TYPE.BID_STRATEGY },
    ],
};

export default campaignReportConfig;
