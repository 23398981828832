import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';
import { creativesApi } from '../../../../../services/api';
import { fetchCreativeSuccess, fetchCreativeError } from '../actions';

const getCreativesApiMap = {
    [CREATIVE_TYPE.MOTION]: creativesApi.getVideoCreative,
    [CREATIVE_TYPE.CREATIVE_TEMPLATE]: () => {},
};

const fetchCreativeForEdit = (accountName, campaignId, creativeId, creativeType) => async dispatch => {
    const getCreativesApi = getCreativesApiMap[creativeType] ?? creativesApi.getCreative;

    let creative;
    try {
        creative = await getCreativesApi(accountName, campaignId, creativeId);
    } catch (error) {
        dispatch(fetchCreativeError(error));
        return;
    }
    dispatch(fetchCreativeSuccess(creative));
};

export default fetchCreativeForEdit;
