import isNil from 'lodash/isNil';
import { ACCOUNT_ID } from 'hooks/queryParams/useSelectedAccountId';
import AllCampaignsObject from 'modules/campaigns/config/allCampaignsObject';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage';
import { MODULE_PARAM_NAME } from '../../../config/routes';
import { MODULE_NAME as BILLING_AND_PAYMENTS_MODULE_NAME } from '../../billing-and-payments/config';

export const campaignIdDeserializer = val => {
    if (isNil(val)) {
        return val;
    }

    const parsedVal = parseInt(val);
    if (Number.isNaN(parsedVal)) {
        return;
    }

    return parsedVal;
};

export const CAMPAIGN_ID = 'campaignId';

export const useSelectedCampaignId = queryParamHookFactory(CAMPAIGN_ID, {
    deserializer: campaignIdDeserializer,
    defaultValue({ [MODULE_PARAM_NAME]: module }) {
        if (module === BILLING_AND_PAYMENTS_MODULE_NAME) {
            return;
        }

        return AllCampaignsObject.id;
    },
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        queryParams: [ACCOUNT_ID],
        pathParams: [MODULE_PARAM_NAME],
    },
});
