import { callAjax } from 'services/callAjax/callAjax';

const liteUnitVersionRegex = /liteUnitVersion', ?'([0-9.]*)/gim;
const tbUrlTemplate =
    'https://15.taboola.com/tb?oid=15&pubnm=unity-dev&unitType=143&tbloc=&pageType=text&pstn=&cisrf=&cirf=https%3A%2F%2Fads.taboola.com%2F&encoded=1&uid=&variant=&callback=&cb={cachebust}';
const liteUnitUrlRaw = 'https://vidstat.taboola.com/lite-unit/{liteUnitVersion}/UnitInlineDesktop.min.js';

class Init {
    lastVast;
    macros;

    async init() {
        this.macros = await this._loadMacros();
        const liteUnitUrl = this._getLiteUnitUrl();
        this._loadLiteUnit(liteUnitUrl);
    }

    _getLiteUnitUrl() {
        const [, liteUnitVersion] = liteUnitVersionRegex.exec(this.macros) || [];
        return liteUnitUrlRaw.replace('{liteUnitVersion}', liteUnitVersion);
    }

    async _loadMacros() {
        const tbUrl = tbUrlTemplate.replace('{cachebust}', new Date().getTime().toString());
        const response = await callAjax(tbUrl, {}, true);
        const responseAsText = await response.text();
        const rawJson = responseAsText.substring(1, responseAsText.length - 1);

        return JSON.parse(rawJson).tags[0].url;
    }

    _loadLiteUnit(liteUnitUrl) {
        const liteUnitTag = document.createElement('script');
        liteUnitTag.async = false;
        liteUnitTag.src = liteUnitUrl;
        liteUnitTag.id = 'ta-lu-script';
        liteUnitTag.onload = () => (this.videoUnitLoaded = true);
        document.head.appendChild(liteUnitTag);
    }

    clearVideoPlacement(placementRef) {
        if (!this.videoUnitLoaded) {
            return;
        }

        if (placementRef.current) {
            placementRef.current.innerHTML = '';
        }
        this.lastVast = null;
    }

    loadUnit(containerId, vast, adNativeSettings, onVideoLoaded = () => {}) {
        if (!this.videoUnitLoaded) {
            return;
        }
        if (this.lastVast === vast) {
            return;
        }
        const videoSelector = `#${containerId}`;

        window.cmTag.set('parentSelector', videoSelector);

        // All preview items need to be single ad (sa=1) to show the end card
        window.cmTag.set('demandConfig.waterfall.tags', [
            {
                vast,
                sa: 1,
                nas: adNativeSettings,
            },
        ]);
        // eslint-disable-next-line no-eval
        const unit = eval(this.macros);
        unit.on('filledImpression', onVideoLoaded);

        this.lastVast = vast;
    }

    setNativeAdSettings(nativeAdSettings) {
        window.setPreviewAdSettings && window.setPreviewAdSettings(nativeAdSettings);
    }
}

export default new Init();
