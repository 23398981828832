import React from 'react';
import { withStylesheet } from 'taboola-ultimate-ui';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './boldEllipsisCellRenderer.module.scss';

const EllipsisCellRendererWithStyles = withStylesheet(EllipsisCellRenderer, styles, {
    [EllipsisCellRenderer.classNames.container]: 'bold',
});

export const BoldEllipsisCellRenderer = props => <EllipsisCellRendererWithStyles {...props} />;

BoldEllipsisCellRenderer.propTypes = {
    ...EllipsisCellRenderer.propTypes,
};

BoldEllipsisCellRenderer.defaultProps = {
    ...EllipsisCellRenderer.defaultProps,
};
