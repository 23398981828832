'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Tooltips = require('../../Tooltips');

var _DragAndDropFile = require('../DragAndDropFile/DragAndDropFile');

var _DropSVGIcon = require('../DropSVGIcon');

var _DropSVGIcon2 = _interopRequireDefault(_DropSVGIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'clickZoneArea__taboola-svg-icon___1FE1y',
    'container': 'clickZoneArea__container___15Lgu',
    'container-with-icon': 'clickZoneArea__container-with-icon___3y8aG',
    'desc-container': 'clickZoneArea__desc-container___XfjnC',
    'action-desc-text': 'clickZoneArea__action-desc-text___2Vl8I',
    'click-dialog-text': 'clickZoneArea__click-dialog-text___2vfY8',
    'disabled': 'clickZoneArea__disabled___1CtLR'
};


var classNameBuilder = _bind2.default.bind(styles);

var renderClickZoneText = function renderClickZoneText(text, clickDialogText, tooltipText, openFileDialog, isHideTooltip, disabled) {
    return _react2.default.createElement(
        'div',
        { className: styles['desc-container'] },
        text,
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('click-dialog-text', { disabled: disabled }), onClick: openFileDialog, role: 'button', tabIndex: 0 },
            clickDialogText
        ),
        !isHideTooltip && tooltipText && _react2.default.createElement(
            _Tooltips.HelpTooltip,
            { position: _Tooltips.TOOLTIP_POSITION.LEFT },
            tooltipText
        )
    );
};

var withIconRender = function withIconRender(text, clickDialogText, tooltipText, openFileDialog, className, clickZoneIcon, actionDescText, disabled) {
    return _react2.default.createElement(
        'div',
        { className: styles['container'] + ' ' + styles['container-with-icon'] + ' ' + className },
        _react2.default.createElement(
            'div',
            null,
            clickZoneIcon
        ),
        renderClickZoneText(text, clickDialogText, tooltipText, openFileDialog, true, disabled),
        _react2.default.createElement(
            'div',
            { className: styles['desc-container'] },
            _react2.default.createElement(
                'div',
                { className: styles['action-desc-text'] },
                actionDescText
            ),
            tooltipText && _react2.default.createElement(
                _Tooltips.HelpTooltip,
                { position: _Tooltips.TOOLTIP_POSITION.LEFT },
                tooltipText
            )
        )
    );
};

var withoutIconRender = function withoutIconRender(text, clickDialogText, tooltipText, openFileDialog, className, disabled) {
    return _react2.default.createElement(
        'div',
        { className: styles['container'] + ' ' + className },
        renderClickZoneText(text, clickDialogText, tooltipText, openFileDialog, false, disabled)
    );
};

var ClickZoneArea = function ClickZoneArea(_ref) {
    var text = _ref.text,
        clickDialogText = _ref.clickDialogText,
        tooltipText = _ref.tooltipText,
        className = _ref.className,
        clickZoneIcon = _ref.clickZoneIcon,
        actionDescText = _ref.actionDescText,
        onOpenFileDialog = _ref.onOpenFileDialog,
        disabled = _ref.disabled;

    var _useDragAndDropFileCo = (0, _DragAndDropFile.useDragAndDropFileContext)(),
        openFileDialog = _useDragAndDropFileCo.openFileDialog;

    var browseFiles = (0, _react.useCallback)(function () {
        onOpenFileDialog();
        openFileDialog();
    });

    return clickZoneIcon ? withIconRender(text, clickDialogText, tooltipText, browseFiles, className, clickZoneIcon, actionDescText, disabled) : withoutIconRender(text, clickDialogText, tooltipText, browseFiles, className, disabled);
};

ClickZoneArea.propTypes = {
    /** Main text to be presented omn the center of the Click zone qrea */
    text: _propTypes2.default.node.isRequired,
    /** Browse text button */
    clickDialogText: _propTypes2.default.node.isRequired,
    /** Second Line Text */
    actionDescText: _propTypes2.default.node,
    /** Tooltip text button */
    tooltipText: _propTypes2.default.node,
    /** Opening File Dialog Programmatically */
    onOpenFileDialog: _propTypes2.default.func,
    /** Apply class to style to the ClickZoneArea */
    className: _propTypes2.default.string,
    /** Indicates the existence of Icon and larger layout */
    clickZoneIcon: _propTypes2.default.node
};

ClickZoneArea.defaultProps = {
    onOpenFileDialog: function onOpenFileDialog() {},
    className: '',
    clickZoneIcon: _react2.default.createElement(_DropSVGIcon2.default, null),
    text: 'Drag and Drop or',
    clickDialogText: 'Browse Files',
    actionDescText: 'Upload image, GIF, or, video file',
    tooltipText: 'tooltip text'
};
exports.default = ClickZoneArea;