import { isEmpty, isArray, isNumber, uniqueId, keyBy, partition, map, flatten, startsWith, size } from 'lodash';
import { NO_DATA_LOADING_FOR_BUNDLES, LoadingMode, NodeSelectionMode } from './constants';
import { generateAllLoadingData, generateAllLoadingTotals, generatePath, generateSubPaths } from './treeUtils';

const DEFAULT_BUNDLE_SIZE = 3;

export const setSegmentAncestorsAsIndeterminate = (segment, pathsMap) => {
    const { taxonomy } = segment;

    if (taxonomy) {
        const allIndeterminatePaths = [...generateSubPaths(taxonomy), taxonomy];
        allIndeterminatePaths.forEach(path => (pathsMap[path] = NodeSelectionMode.INDETERMINATE));
    }

    return pathsMap;
};

export const setNodeAndDescendantsAsSelected = (treeNode, selectedPathsMap) => {
    const { path, nodes } = treeNode;

    if (selectedPathsMap[path] !== NodeSelectionMode.SELECTED) {
        selectedPathsMap[path] = NodeSelectionMode.SELECTED;

        if (nodes) {
            nodes.forEach(childNode => setNodeAndDescendantsAsSelected(childNode, selectedPathsMap));
        }
    }
};

const removeSelectedDescendants = (node, selectedNodes, selectedPathsSet) => {
    selectedNodes.forEach(selectedNode => {
        if (getIsDescendant(selectedNode, node)) {
            selectedPathsSet.delete(selectedNode.path);
        }
    });
};

const addNewSelectedNodes = (nodeToAdd, selectedPathsSet, taxonomyNodesMap) => {
    const { parent, taxonomy, path: nodePath } = nodeToAdd;

    if (parent && parent.isTargetable) {
        const siblings = taxonomyNodesMap[taxonomy];

        const unselectedChildren = siblings.filter(
            ({ path, isTargetable }) => !selectedPathsSet.has(path) && isTargetable
        );
        const isFinalSelectedNode = size(unselectedChildren) === 1 && unselectedChildren[0].path === nodePath;

        if (isFinalSelectedNode) {
            siblings.forEach(({ path }) => selectedPathsSet.delete(path));
            return addNewSelectedNodes(parent, selectedPathsSet, taxonomyNodesMap);
        }
    }

    // If we don't need to add the parent, return this id as the node id to add.
    return nodePath;
};

export const getUpdatedSelectedPathsFromAdd = (nodeToAdd, selectedNodes, taxonomyNodesMap) => {
    const filteredSelectedPaths = new Set(map(selectedNodes, 'path'));

    removeSelectedDescendants(nodeToAdd, selectedNodes, filteredSelectedPaths);
    const nodePathToAdd = addNewSelectedNodes(nodeToAdd, filteredSelectedPaths, taxonomyNodesMap);

    return { nodePathToAdd, filteredSelectedPaths };
};

// If node is selected, returns node id. Else, adds targetable siblings and recurses to parent.
const addSiblingsUntilSelectedAncestorNode = (node, selectedNodePaths, addedSelectedPaths, taxonomyNodesMap) => {
    const { path: nodePath, parent, taxonomy } = node;

    if (selectedNodePaths.has(nodePath)) {
        return nodePath;
    } else if (!parent) {
        return null;
    }

    const siblings = taxonomyNodesMap[taxonomy];
    siblings.forEach(({ path, isTargetable }) => {
        if (isTargetable && path !== nodePath && !selectedNodePaths.has(path)) {
            addedSelectedPaths.add(path);
        }
    });

    return addSiblingsUntilSelectedAncestorNode(parent, selectedNodePaths, addedSelectedPaths, taxonomyNodesMap);
};

export const getUpdatedSelectedPathsFromRemove = (nodeToRemove, selectedNodes, taxonomyNodesMap) => {
    const selectedNodePaths = new Set(map(selectedNodes, 'path'));
    const addedSelectedPaths = new Set();

    const nodePathToRemove = addSiblingsUntilSelectedAncestorNode(
        nodeToRemove,
        selectedNodePaths,
        addedSelectedPaths,
        taxonomyNodesMap
    );

    return { nodePathToRemove, addedSelectedPaths };
};

const addUnselectedTopLevelNodePaths = ({ treeNode, topLevelTreeNodesToAdd, selectedNodePaths }) => {
    if (!treeNode || selectedNodePaths.has(treeNode.path)) {
        return;
    }

    const { nodes, isTargetable } = treeNode;
    if (isTargetable) {
        topLevelTreeNodesToAdd.push(treeNode);
    } else if (nodes) {
        nodes.forEach(treeNode =>
            addUnselectedTopLevelNodePaths({ treeNode, topLevelTreeNodesToAdd, selectedNodePaths })
        );
    }
};

export const getUpdatedSelectedTopLevelPaths = (nodesTree, selectedNodes) => {
    const topLevelTreeNodesToAdd = [];
    const selectedNodePaths = new Set(map(selectedNodes, 'path'));
    const filteredSelectedPaths = new Set(map(selectedNodes, 'path'));

    nodesTree.forEach(treeNode =>
        addUnselectedTopLevelNodePaths({ treeNode, topLevelTreeNodesToAdd, selectedNodePaths })
    );
    topLevelTreeNodesToAdd.forEach(node => removeSelectedDescendants(node, selectedNodes, filteredSelectedPaths));
    const topLevelNodePathsToAdd = new Set(map(topLevelTreeNodesToAdd, 'path'));

    return { topLevelNodePathsToAdd, filteredSelectedPaths };
};

const transformBundlingNodes = (nodes = [], nodeTransformer) =>
    nodes.map(node => {
        const { id, path, loading, taxonomy, label, isBundle, isTargetable } = node;

        return {
            id,
            label,
            loading,
            path,
            pathProperty: id,
            taxonomy,
            isBundle,
            isTargetable,
            ...nodeTransformer(node),
        };
    });

const generateTree = (nodes, nodeTransformer, totals, forceLoadingAll) => {
    const transformedNodes = transformBundlingNodes(nodes, nodeTransformer);
    const nodesMap = keyBy(transformedNodes, 'path');
    const [rootNodes, nodesWithTaxonomy] = partition(transformedNodes, ({ taxonomy }) => !taxonomy);

    // Add child nodes to their parents
    nodesWithTaxonomy.forEach(node => {
        const { taxonomy } = node;
        const parentNode = nodesMap[taxonomy];

        if (!parentNode) {
            return;
        } else if (isArray(parentNode?.nodes)) {
            parentNode.nodes.push(node);
        } else {
            parentNode.nodes = [node];
        }

        node.parent = parentNode;
    });

    // Add load-more node if needed
    transformedNodes.forEach(node => {
        const { nodes, path } = node;

        if (!isEmpty(nodes)) {
            const total = totals[path];
            const hasMore = isNumber(total) && total > nodes.length;

            if (hasMore && !forceLoadingAll) {
                node.nodes.push({ id: uniqueId('load-more-'), hasMore });
            }
        }
    });

    return [rootNodes, nodesMap];
};

const generateBundleLoadingData = (loadingPaths, bundleSize = DEFAULT_BUNDLE_SIZE) => {
    const loadingNodesLists = map(loadingPaths, (loadingCount, path) => {
        const emptyLoadingNodes = Array.from({ length: bundleSize });
        return emptyLoadingNodes.map(() => {
            const id = uniqueId('loading-');
            return { id, path: generatePath(id, path), taxonomy: path, loading: true };
        });
    });

    return flatten(loadingNodesLists);
};

export const generateTreeWithBundles = ({ nodes, totals, nodeTransformer, loadingPaths, loadingMode }) => {
    const forceLoadingAll = loadingMode === LoadingMode.FULL;

    if (forceLoadingAll) {
        const loadingNodes = generateAllLoadingData([], NO_DATA_LOADING_FOR_BUNDLES);
        const tempTotals = generateAllLoadingTotals(loadingNodes);
        return generateTree(loadingNodes, nodeTransformer, tempTotals, true);
    }

    const loadingNodes = generateBundleLoadingData(loadingPaths);
    return generateTree([...nodes, ...loadingNodes], nodeTransformer, totals, false);
};

export const getAllNodePathsMap = (data, getSelectionModeByPath = () => true) =>
    data.reduce((result, node) => {
        const { path } = node;
        return { ...result, [path]: getSelectionModeByPath(node) };
    }, {});

export const getIsDescendant = (node, ancestorNode) => {
    const { taxonomy } = node;
    const { path: ancestorPath } = ancestorNode;

    return startsWith(taxonomy, ancestorPath);
};
