import {
    FEATURE_FLAGS,
    useConfigMatch,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { FORM_MODES } from '../../../../../config';
import { cpaAccountConfig, cpaConfig, eligibilityLabelsEnabled } from '../../BidStrategy/SmartBidRadio/SmartBidRadio';

const targetCpaDuplicateCampaignEnabled = { [FEATURE_FLAGS.TARGET_CPA_DUPLICATE_CAMPAIGN_ENABLED]: 'true' };

export const useTargetCpaAvailability = () => {
    const { value: targetCpaData, mode } = useFormFieldValue({
        field: 'targetCpaData',
    });

    const isEligibilityLabelsEnabled = useConfigMatch({}, eligibilityLabelsEnabled);
    const isTargetCpaBeta = useConfigMatch(cpaConfig);
    const isTargetCpaEligibleAccount = useConfigMatch(cpaAccountConfig);
    const isTargetCpaDuplicateCampaignEnabled = useConfigMatch(targetCpaDuplicateCampaignEnabled);

    const isEligibleCampaign = targetCpaData !== undefined && targetCpaData !== null;
    const shouldRenderTargetCpa =
        isTargetCpaBeta || ((isEligibleCampaign || isTargetCpaEligibleAccount) && isEligibilityLabelsEnabled);
    const disableCpa = !isEligibleCampaign && !isTargetCpaBeta;
    const shouldDisableCpaOnDuplicate =
        !isTargetCpaBeta && !isTargetCpaDuplicateCampaignEnabled && mode === FORM_MODES.DUPLICATE;

    return {
        shouldRenderTargetCpa,
        disableCpa,
        shouldDisableCpaOnDuplicate,
        isEligibleCampaign,
        isTargetCpaEligibleAccount,
        isEligibilityLabelsEnabled,
        isTargetCpaBeta,
    };
};
