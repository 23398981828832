import { useMemo } from 'react';
import { useFormFieldValue } from '../../taboola-common-frontend-modules/formData';
import { KEY_OPTIONS } from '../config/defaultConfig';

export const useAvailableKeys = () => {
    const { value: externalMetadata = [] } = useFormFieldValue({ field: 'externalMetadata.data' });

    const metadataKeys = useMemo(() => {
        return externalMetadata.map(item => item.key);
    }, [externalMetadata]);

    const options = useMemo(() => {
        return KEY_OPTIONS.filter(option => !metadataKeys.includes(option.value));
    }, [metadataKeys]);

    return { options };
};
