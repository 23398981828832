import React, { TextareaHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';
import { scale } from 'tuui/lib/utils/styles';
import { UIC } from 'tuui/lib/utils/typing';

export interface TextareaProps {
    textareaClassName?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement> & TextareaProps>(
    ({
    placeholder = 'Enter your text here...',
    rows = 5,
    value,
    className,
    textareaClassName,
    ...rest
    }, ref) => (
        <ContainerBase className={className}>
            <TextareaBase
                ref={ref}
                {...rest}
                value={value ?? ''}
                className={textareaClassName}
                rows={rows}
                placeholder={placeholder}
            />
        </ContainerBase>
    )
) as UIC<TextareaHTMLAttributes<HTMLTextAreaElement> & TextareaProps>;

const ContainerBase = styled.div`
    &:focus {
        border: ${scale('xxxs')} solid ${({ theme }) => theme.palette.secondary.stroke};
        box-shadow: 0 0 ${scale('lg')} 0 ${({ theme }) => theme.palette.secondary.stroke};
    }
`;

const TextareaBase = styled.textarea`
    width: 100%;
    font-size: ${({ theme }) => theme.typography.sizes.lg};
    resize: none;
    border-radius: ${scale('xs')};
    padding:${scale('md')};
    border: ${scale('xxxs')} solid ${({ theme }) => theme.palette.grayscale[5]};

    ::placeholder {
        color: ${({ theme }) => theme.palette.grayscale[6]};
        font-size: ${({ theme }) => theme.typography.sizes.md};
    }
`;