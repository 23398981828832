import React, { useState } from 'react';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { ENTITY } from '../../config';
import TrackingSetupMethodRadioGroup from '../TrackingSetupMethodRadioGroup/TrackingSetupMethodRadioGroup';

export const TrackingSetupMethodModal = ({ onClose }) => {
    const [trackingSetupMethodType, setTrackingSetupMethodType] = useState(ENTITY.GTM_SETUP);
    return (
        <ModalFormLayout
            onCancel={onClose}
            onSubmit={() => onClose(trackingSetupMethodType)}
            hasCancel={false}
            submitButtonText={
                <FormattedMessage id="app.modal.trackingSetup.button.positive" defaultMessage="Continue" />
            }
        >
            <TrackingSetupMethodRadioGroup type={trackingSetupMethodType} setType={setTrackingSetupMethodType} />
        </ModalFormLayout>
    );
};
