import React from 'react';
import EnumValueCellRenderer from '../../CellRenderers/EnumValueCellRenderer';
import { useBidStrategyMessage } from './useBidStrategyMessage';

export const BidStrategyCellRenderer = props => {
    const { getMessageIdPrefix } = useBidStrategyMessage();
    const msgIdPrefix = getMessageIdPrefix(props.value);

    return <EnumValueCellRenderer {...props} msgIdPrefix={msgIdPrefix} />;
};
