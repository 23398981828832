import PropTypes from 'prop-types';
import { PERMISSIONS_OPERATORS } from '../constants';
import usePermissions from '../hooks';

const PermissionsGuard = ({ permissions, operator, children, fallback = null }) => {
    const isPermitted = usePermissions(permissions, operator);

    if (typeof children === 'function') {
        return children(isPermitted);
    }

    return isPermitted ? children : fallback;
};

export const permissionsShape = PropTypes.oneOfType([
    PropTypes.shape({
        permissions: PropTypes.array,
        operator: PropTypes.oneOf(Object.values(PERMISSIONS_OPERATORS)),
    }),
    PropTypes.string,
    PropTypes.array,
]);

PermissionsGuard.propTypes = {
    permissions: permissionsShape,
    operator: PropTypes.oneOf(Object.values(PERMISSIONS_OPERATORS)),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    fallback: PropTypes.node,
};

PermissionsGuard.defaultProps = {
    permissions: [],
    operator: PERMISSIONS_OPERATORS.AND,
};

export default PermissionsGuard;
