import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { ACCOUNT_MANAGER } from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import { defaultColumns, customColumns } from './reportPresets';

export const accountManagerReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.ACCOUNT_MANAGER],
    endpoint: 'campaign-report-by-account-manager',
    rowIdField: ACCOUNT_MANAGER.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    sortMap: {
        [ACCOUNT_MANAGER.field]: 'am',
    },
    filters: [
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default accountManagerReportConfig;
