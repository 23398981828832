"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDocument(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M11 24h10a2 2 0 002-2v-9.36a2 2 0 00-.505-1.328l-2.347-2.64A2 2 0 0018.653 8H11a2 2 0 00-2 2v12a2 2 0 002 2zm0-17a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3v-9.36a3 3 0 00-.758-1.992l-2.347-2.641A3 3 0 0018.653 7H11z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M11 11.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5z",
            fill: "#212832"
        }),
        React.createElement("path", {
            d: "M11 17.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5z",
            fill: "#212832"
        })
    );
}
exports.default = SvgDocument;