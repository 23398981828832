import React, { useState, useMemo } from 'react';
import { isEmpty, isNil, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { BaseOpenComparableFilter } from '../BaseOpenFilter/BaseOpenComparableFilter';
import { BaseOpenFilter } from '../BaseOpenFilter/BaseOpenFilter';
import { isComparableFilter } from '../filterUtils';

const EMPTY_VALUES = [];
const UNDEFINED_VALUE = undefined;
export const OpenFilterUnit = ({
    initialFilter,
    types,
    disabledTypes,
    getValueOptions,
    onCancel,
    onApply,
    isMultiSelectFilter,
    getApplyBtnMetricsAttrs,
}) => {
    const {
        type: initialType,
        values: initialValues = EMPTY_VALUES,
        operator: initialOperator = UNDEFINED_VALUE,
    } = initialFilter || {};
    const onlyAvailableType = types.length === 1 ? types[0] : null;
    const [type, setType] = useState(initialType || onlyAvailableType);
    const [values, setValues] = useState(initialValues);
    const [operator, setOperator] = useState(initialOperator);
    const { formatMessage } = useIntl();
    const allTypes = types.map(type => ({
        value: type,
        label: formatMessage({
            id: `app.campaigns.reports.filters.type.${type}`,
            defaultMessage: type,
        }),
        isDisabled: disabledTypes.has(type),
    }));

    const sortedTypes = useMemo(() => allTypes.sort((a, b) => a.label.localeCompare(b.label)), [allTypes]);

    const setFilterType = ({ value: newType }) => {
        if (newType !== type) {
            setType(newType);
            setValues([]);
        }
    };

    const handleApply = () => {
        onApply({ type, values, operator });
    };

    const changeValues = newValue => {
        if (isNil(newValue)) {
            setValues([]);
            return;
        }
        if (Array.isArray(newValue)) {
            setValues(newValue);
            return;
        }
        setValues([newValue]);
    };

    const changeOperator = newOperator => {
        if (isEmpty(newOperator)) {
            setOperator(UNDEFINED_VALUE);
            return;
        }
        setOperator(newOperator);
    };

    const applyBtnMetricsAttrs = useMemo(
        () => getApplyBtnMetricsAttrs({ type, values, operator }),
        [getApplyBtnMetricsAttrs, type, values, operator]
    );

    return isComparableFilter(type) ? (
        <BaseOpenComparableFilter
            initialOpen={!type}
            type={type}
            typeOptions={sortedTypes}
            onTypeChange={setFilterType}
            onValuesChange={changeValues}
            onCancel={onCancel}
            onApply={handleApply}
            applyBtnMetricsAttrs={applyBtnMetricsAttrs}
            operator={operator}
            onOperatorChange={changeOperator}
            values={values}
        />
    ) : (
        <BaseOpenFilter
            initialOpen={!type}
            type={type}
            typeOptions={sortedTypes}
            onTypeChange={setFilterType}
            values={values}
            valuesSearchable
            onValuesChange={changeValues}
            getValueOptions={getValueOptions}
            onCancel={onCancel}
            onApply={handleApply}
            isMultiSelectFilter={isMultiSelectFilter}
            applyBtnMetricsAttrs={applyBtnMetricsAttrs}
        />
    );
};

OpenFilterUnit.propTypes = {
    /** Initial filter value */
    initialFilter: PropTypes.shape({
        type: PropTypes.string,
        values: PropTypes.array,
    }),
    /** array of available types to choose from */
    types: PropTypes.array,
    /** es6 set of disabled types, disables the option in type dropdown */
    disabledTypes: PropTypes.object,
    /** async function (type, searchText, page), returns {options: [{value, label}], hasMore} */
    getValueOptions: PropTypes.func,
    /** called when user clicks the x button  */
    onCancel: PropTypes.func,
    /** called when user clicks apply button */
    onApply: PropTypes.func,
    /** return value is spread into apply button for metrics */
    getApplyBtnMetricsAttrs: PropTypes.func,
};

OpenFilterUnit.defaultProps = {
    initialFilter: {},
    types: [],
    disabledTypes: new Set(),
    getValueOptions: () => ({ options: [], hasMore: false }),
    getApplyBtnMetricsAttrs: noop,
};
