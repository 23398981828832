import React from 'react';
import styles from '../BalanceAndPaymentsPanel/balanceAndPayments.module.scss';

export const DropDownSelectionOption = ({ text, logo }) => {
    return (
        <div className={styles['selection-option']}>
            <div>{text}</div>
            <div className={styles['option-logo']}>{logo}</div>
        </div>
    );
};

export default DropDownSelectionOption;
