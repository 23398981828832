import React, { Fragment } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { CheckboxGroup, Collapsible, Indication, INDICATION_TYPES, TextCheckbox } from 'taboola-ultimate-ui';
import { useValidationContext } from 'modules/taboola-common-frontend-modules/validations/ValidationContext';
import FormButtons from '../FormButtons/FormButtons';
import styles from './formLayout.module.scss';

const classNameBuilder = classnames.bind(styles);

export const FormLayout = ({
    hasCancel,
    onCancel,
    onSubmit,
    disableSubmit,
    disableCancel,
    className,
    contentContainerClassName,
    headerContainerClassName,
    bodyContainerClassName,
    footerContainerClassName,
    children,
    header,
    indications,
    footer,
    cancelButtonText,
    cancelDataMetricAttrs,
    submitButtonText,
    submitDataMetricAttrs,
    submitInProgress,
    hideFooter,
    onSelectCheckbox,
    onUnselectCheckbox,
    checkboxSelectedValues,
    checkboxData,
    formClassName,
    bodyRef,
    collapsedHeader,
    showFooterValidations,
    formButtonComponent: FormButtonComponent = FormButtons,
    ...rest
}) => {
    const indicationToDisplay = indications && indications.find(indication => indication.showIndication === true);
    const indicationProps = indicationToDisplay
        ? {
              highlight: indicationToDisplay.title,
              description: indicationToDisplay.message,
              type: indicationToDisplay.type,
          }
        : null;
    const { scrollAreaRef } = useValidationContext();
    const refCallBack = (...args) => {
        if (scrollAreaRef) {
            scrollAreaRef.current = args[0];
        }
        if (bodyRef) {
            bodyRef.current = args[0];
        }
    };
    return (
        <div className={classNameBuilder('wrapper', className, formClassName)} {...rest}>
            <div className={classNameBuilder('container', contentContainerClassName)}>
                {header && (
                    <div
                        className={classNameBuilder(
                            'header-container',
                            (!indicationProps || collapsedHeader) && 'header-border',
                            headerContainerClassName
                        )}
                    >
                        {header}
                    </div>
                )}
                {indicationProps && (
                    <Collapsible collapsed={collapsedHeader}>
                        <Indication
                            className={classNameBuilder('indication-container')}
                            contentClassName={classNameBuilder('indication-title')}
                            descriptionClassName={classNameBuilder('indication-description')}
                            displayDismissComponent={false}
                            {...indicationProps}
                        />
                    </Collapsible>
                )}
                <div
                    ref={refCallBack}
                    className={classNameBuilder('body-container', bodyContainerClassName)}
                    id={FormLayout.BODY_CONTAINER_ID}
                >
                    {children}
                </div>
                {!hideFooter && (
                    <div className={classNameBuilder('footer-container', footerContainerClassName)}>
                        {footer || (
                            <Fragment>
                                {!!checkboxData.length && (
                                    <CheckboxGroup
                                        onSelectCheckbox={onSelectCheckbox}
                                        onUnselectCheckbox={onUnselectCheckbox}
                                        selectedValues={checkboxSelectedValues}
                                    >
                                        {checkboxData.map(({ title, value }) => (
                                            <TextCheckbox key={value} value={value} title={title} />
                                        ))}
                                    </CheckboxGroup>
                                )}
                                <FormButtonComponent
                                    hasCancel={hasCancel}
                                    onSubmit={onSubmit}
                                    onCancel={onCancel}
                                    cancelLabel={cancelButtonText}
                                    cancelDataMetricAttrs={cancelDataMetricAttrs}
                                    submitLabel={submitButtonText}
                                    submitDataMetricAttrs={submitDataMetricAttrs}
                                    disableSubmit={disableSubmit}
                                    disableCancel={disableCancel}
                                    loadingSubmit={submitInProgress}
                                    className={styles['footer-button']}
                                    showFooterValidations={showFooterValidations}
                                />
                            </Fragment>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

FormLayout.propTypes = {
    /** hide cancel button */
    hasCancel: PropTypes.bool,
    /** A callback function to be called upon clicking the cancel button */
    onCancel: PropTypes.func,
    /** A callback function to be called upon clicking the submit button */
    onSubmit: PropTypes.func,
    /** Determines whether to disable submit button */
    disableSubmit: PropTypes.bool,
    /** The components that are inside this component */
    children: PropTypes.node,
    /** The header component */
    header: PropTypes.node,
    /** The footer component */
    footer: PropTypes.node,
    /** The container (body) className */
    contentContainerClassName: PropTypes.string,
    /** The header className */
    headerContainerClassName: PropTypes.string,
    /** The body className */
    bodyContainerClassName: PropTypes.string,
    /** The footer className */
    footerContainerClassName: PropTypes.string,
    /** Right button node */
    submitButtonText: PropTypes.node,
    /** GTM metrics object for Right button */
    submitDataMetricAttrs: PropTypes.object,
    /** Left button node */
    cancelButtonText: PropTypes.node,
    /** Show pending cursor for submit button */
    submitInProgress: PropTypes.bool,
    /** hide the footer */
    hideFooter: PropTypes.bool,
    /** onSelect for checkbox */
    onSelectCheckbox: PropTypes.func,
    /** onUnselect for checkbox */
    onUnselectCheckbox: PropTypes.func,
    /** checkbox selected values */
    checkboxSelectedValues: PropTypes.arrayOf(PropTypes.number),
    /** checkbox data containing titles and values */
    checkboxData: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.node,
            value: PropTypes.number,
        })
    ),
    collapsedHeader: PropTypes.bool,
    /** list of indications */
    indications: PropTypes.arrayOf(
        PropTypes.shape({
            /** Boolean if indication should be shown */
            showIndication: PropTypes.bool,
            /** Component displayed in title (highlighted) */
            title: PropTypes.node,
            /** Component displayed in message under title */
            message: PropTypes.node,
            /** Type of indication */
            type: PropTypes.oneOf(Object.values(INDICATION_TYPES)),
        })
    ),
    showFooterValidations: PropTypes.bool,
};

FormLayout.defaultProps = {
    onSubmit: () => {},
    hasCancel: true,
    contentContainerClassName: '',
    headerContainerClassName: '',
    footerContainerClassName: '',
    checkboxData: [],
};

FormLayout.BODY_CONTAINER_ID = '__form_body_container';

export default FormLayout;
