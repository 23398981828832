import { queryClient } from 'services/query';
import { callGetApi, callPostApi } from './apiCallerProxy';

const thirdPartyTagPrefix = accountId => `/validations/${accountId}/campaigns/third-party-tags`;
const urlPrefix = accountId => `/validations/urls/${accountId}`;
const autoReviewerPrefix = (accountId, campaignId) => `/auto-reviewer/${accountId}/campaigns/${campaignId}/validate`;
const ONE_MINUTE = 1000 * 60;
const THIRTY_MINUTES = 30 * ONE_MINUTE;

export const validationsApiFactory = ({ callGetApi, callPostApi, queryClient }) => ({
    validateThirdPartyTag: (accountId, tag) => callPostApi(thirdPartyTagPrefix(accountId), [tag], {}, true),
    validateCampaignClickers: campaignId => callGetApi(`/validations/campaign-clickers/${campaignId}`),
    hasMaxSpendingLimit: accountId => callGetApi(`/validations/has-max-spending-limit/${accountId}`),
    validateUrls: (accountId, urls) => callGetApi(urlPrefix(accountId), { urls }, true),

    validateContent: ({ accountId, campaignId, fieldToValidate, items, scope }) =>
        queryClient.fetchQuery(
            ['AUTO_REVIEW_VALIDATION_API', accountId, campaignId, fieldToValidate, items, scope],
            () =>
                callPostApi(autoReviewerPrefix(accountId, campaignId), {
                    items,
                    fieldToValidate,
                    scope,
                }),
            { cacheTime: THIRTY_MINUTES, staleTime: THIRTY_MINUTES }
        ),
});

export default validationsApiFactory({ callGetApi, callPostApi, queryClient });
