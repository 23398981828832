import { get, noop } from 'lodash';
import { normalizeCellEditor } from '../components/CellEditors';
import { withEditIndication, withRecommendationIndication } from '../components/CellRenderers';
import { withCellRenderTracker } from '../components/CellRenderers/withCellRenderTracker';
import { editableColumn } from '../config/columns';
import generateColumnId from './generateColumnId';
import suppressKeyboardEvent from './suppressKeyboardEvent';

const emptyComparator = () => 0;

// Here we are assuming that default string value getter is used for nested values as per ag-grid spec.
const sanitizeValueGetter = stringValueGetter => params => get(params, stringValueGetter, null);

const normalizeColumnDefs = (
    columnDefs = [],
    reportId,
    editIndicationHoc = withEditIndication,
    recommendationIndicationHoc = withRecommendationIndication,
    cellEditorHoc = normalizeCellEditor
) =>
    columnDefs.map(colDef => {
        const { type, cellRendererParams = {}, field, referenceColId } = colDef;
        const extendedColumnDef = {
            ...colDef,
            menuTabs: [],
            colId: generateColumnId(reportId, field),
            comparator: emptyComparator,
            lockPinned: true,
        };

        if (referenceColId) {
            extendedColumnDef.referenceColId = generateColumnId(reportId, referenceColId);
        }

        if (typeof extendedColumnDef.valueGetter === 'string') {
            extendedColumnDef.valueGetter = sanitizeValueGetter(extendedColumnDef.valueGetter);
        }

        const isEditable = type && type.includes(editableColumn) && !cellRendererParams.hideEditIndication;

        if (colDef.cellRendererFramework) {
            extendedColumnDef.cellRendererFramework = withCellRenderTracker(extendedColumnDef.cellRendererFramework);
        }

        if (isEditable) {
            extendedColumnDef.cellRendererFramework = editIndicationHoc(extendedColumnDef.cellRendererFramework);
        }
        if (extendedColumnDef?.cellRendererParams?.recommendationTypes) {
            extendedColumnDef.cellRendererFramework = recommendationIndicationHoc(
                extendedColumnDef.cellRendererFramework
            );
        }
        if (colDef.cellEditorFramework) {
            extendedColumnDef.cellEditorFramework = cellEditorHoc(colDef.cellEditorFramework);
            extendedColumnDef.suppressKeyboardEvent = suppressKeyboardEvent;
        }

        return extendedColumnDef;
    });

const emptyFooterColumnMapper = (colDef, reportId) => {
    const { field, permissions, accountConfigurations, visibilityCondition } = colDef;

    return {
        field,
        colId: generateColumnId(reportId, field),
        editable: false,
        valueFormatter: null,
        valueGetter: noop,
        headerComponentFramework: null,
        cellEditorFramework: null,
        cellRendererFramework: null,
        permissions,
        accountConfigurations,
        visibilityCondition,
    };
};

export const createFooterMapper = reportId => colDef => emptyFooterColumnMapper(colDef, reportId);

export default normalizeColumnDefs;
