'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// use this component when rendering a new component causes the scroll to jump,
// misaligning your mouse with where it previously was which is bad UX.
// because reading and setting scroll trigger reflow, this is a pure component.
// you must pass in your own props related to the components being rendered in order to trigger a scroll correction
var ScrollCorrector = function (_PureComponent) {
    _inherits(ScrollCorrector, _PureComponent);

    function ScrollCorrector() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, ScrollCorrector);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ScrollCorrector.__proto__ || Object.getPrototypeOf(ScrollCorrector)).call.apply(_ref, [this].concat(args))), _this), _this.getSnapshotBeforeUpdate = function () {
            var scrollRef = _this.props.scrollRef;

            var prevScroll = null;
            if (scrollRef.current) {
                prevScroll = {
                    top: scrollRef.current.scrollTop,
                    height: scrollRef.current.scrollHeight
                };
            }
            return prevScroll;
        }, _this.componentDidUpdate = function (prevProps, prevState, prevScroll) {
            var scrollRef = _this.props.scrollRef;

            if (scrollRef && scrollRef.current && prevScroll) {
                _this.correctScroll(scrollRef, prevScroll, false);
            }
        }, _this.componentWillUnmount = function () {
            window.cancelAnimationFrame(_this.animationFrame);
            clearTimeout(_this.timeout);
        }, _this.correctScroll = function (scrollRef, prevScroll, prevCorrected) {
            var scrollHeight = scrollRef.current.scrollHeight;

            var ref = scrollRef;
            var prevTop = prevScroll.top;
            var prevDelta = prevScroll.height - prevTop;
            var newTop = scrollHeight - prevDelta;
            if (newTop !== prevTop) {
                ref.current.scrollTop = newTop;
            } else if (!prevCorrected) {
                window.cancelAnimationFrame(_this.animationFrame);
                clearTimeout(_this.timeout);
                _this.animationFrame = window.requestAnimationFrame(function () {
                    _this.timeout = setTimeout(function () {
                        _this.correctScroll(ref, prevScroll, true);
                    });
                });
            }
        }, _this.animationFrame = null, _this.timeout = null, _this.render = function () {
            return null;
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    return ScrollCorrector;
}(_react.PureComponent);

ScrollCorrector.propTypes = {
    /** ref to element which has the scrollbar */
    scrollRef: _propTypes2.default.exact({
        current: _propTypes2.default.any
    }).isRequired,
    /**
     * when any prop is passed into this component, a scroll correction will trigger,
     * however you should use correctionTrigger if possible for good documentation.
     * In the event multiple props cause corrections, you can pass in additional props if needed
     * to trigger corrections
     */
    correctionTrigger: _propTypes2.default.any.isRequired
};

exports.default = ScrollCorrector;