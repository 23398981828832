'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useSearchableList = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _lodash = require('lodash.isempty');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NO_RESULT_FOUND_DUMMY_ITEM = [{ id: 'noResultsFound' }];

var useSearchableList = exports.useSearchableList = function useSearchableList(_ref) {
    var items = _ref.items,
        searchFieldExtractor = _ref.searchFieldExtractor;

    var _useState = (0, _react.useState)(''),
        _useState2 = _slicedToArray(_useState, 2),
        searchText = _useState2[0],
        setSearchText = _useState2[1];

    var searchTextToLowerCase = (0, _react.useMemo)(function () {
        return searchText.trim().toLowerCase();
    }, [searchText]);
    var filteredItems = (0, _react.useMemo)(function () {
        return (0, _lodash2.default)(searchText.trim()) ? items : items.filter(function (item) {
            var fieldToSearchIn = searchFieldExtractor(item);
            return fieldToSearchIn && fieldToSearchIn.toLowerCase().includes(searchTextToLowerCase);
        });
    }, [items, searchFieldExtractor, searchText]);
    var isAllItemsFiltered = !(0, _lodash2.default)(items) && (0, _lodash2.default)(filteredItems);
    return {
        searchText: searchText,
        setSearchText: setSearchText,
        filteredItems: isAllItemsFiltered ? NO_RESULT_FOUND_DUMMY_ITEM : filteredItems,
        isAllItemsFiltered: isAllItemsFiltered
    };
};