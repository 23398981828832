import { useCallback } from 'react';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { PRICING_MODEL } from '../../../../../config';
import { BID_STRATEGIES } from '../../../config';
import { useCpaGoalFormFieldValue } from '../../CpaGoal/useCpaGoalFormFieldValue';
import { getBidCacheKey, useBidStrategyCacheContext } from '../BidStrategyCacheProvider';
import { bidStrategiesCategory } from '../hooks/useBidStrategiesOptions';
import { useBidStrategyFormField } from '../hooks/useBidStrategyFormField';

export const useMaxConversionsState = () => {
    const { value: bidStrategy, onChange: onChangeBidStrategyBase } = useBidStrategyFormField();
    const { targetCpaCache, bidCache, cpcCapCache } = useBidStrategyCacheContext();
    const { resetFieldToInitial: resetTargetCpa, onChange: onChangeTargetCpa } = useCpaGoalFormFieldValue();
    const { onChange: onChangeBid, resetFieldToInitial: resetBid } = useFormFieldValue({ field: 'bid' });
    const { resetFieldToInitial: resetCpcCap, onChange: onChangeCpcCap } = useFormFieldValue({
        field: 'cpcCap',
    });

    const onChangeBidStrategy = useCallback(
        bidStrategy => {
            onChangeBidStrategyBase(bidStrategy);

            const cachedTargetCpa = bidStrategy === BID_STRATEGIES.TARGET_CPA ? targetCpaCache.targetCpa : undefined;
            if (cachedTargetCpa) {
                onChangeTargetCpa(cachedTargetCpa);
            } else {
                resetTargetCpa();
            }

            const cachedCpcCap = bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS ? cpcCapCache.cpcCap : undefined;
            if (cachedCpcCap) {
                onChangeCpcCap(cachedCpcCap);
            } else {
                resetCpcCap();
            }

            const cachedBid =
                bidCache[
                    getBidCacheKey({
                        bidStrategyCategory: bidStrategiesCategory.MAX_CONVERSIONS,
                        pricingModel: PRICING_MODEL.CPC,
                        bidStrategy,
                    })
                ];
            if (cachedBid) {
                onChangeBid(cachedBid);
            } else {
                resetBid();
            }
        },
        [
            bidCache,
            cpcCapCache.cpcCap,
            onChangeBid,
            onChangeBidStrategyBase,
            onChangeCpcCap,
            onChangeTargetCpa,
            resetBid,
            resetCpcCap,
            resetTargetCpa,
            targetCpaCache.targetCpa,
        ]
    );

    return {
        bidStrategy,
        onChangeBidStrategy,
    };
};
