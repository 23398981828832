import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Collapsible } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { COMPONENT_STATUS } from 'services/constants';
import { useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { getCampaignStatusMessages } from '../../../../services/utils';
import { BidStrategyValue } from '../BidStrategyValue/BidStrategyValue';
import Id from '../Id/Id';
import { NameEdit } from '../Name/NameEdit/NameEdit';
import Status from '../Status/Status';
import HeaderLoading from './HeaderLoading/HeaderLoading';
import styles from './campaignEditorHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const CampaignEditorHeader = ({ status, headerClassName, campaignStatusClassName, collapsed }) => {
    const { value: isActive, onChange, mode } = useFormFieldValue({ field: 'isActive' });
    const { value: campaignStatus } = useFormFieldValue({ field: 'status' });
    const { value: id = EMPTY_FIELD_VALUE } = useFormFieldValue({ field: 'id' });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'campaign.name.placeholder',
        defaultMessage: 'Enter Campaign Name',
    });

    const { formattedStatusMsg } = getCampaignStatusMessages(campaignStatus);

    return status === COMPONENT_STATUS.LOADING ? (
        <HeaderLoading />
    ) : (
        <div className={classNameBuilder('section-container', headerClassName)}>
            <NameEdit placeholder={placeholder} />
            {mode === FORM_MODES.EDIT && (
                <Collapsible collapsed={collapsed}>
                    <div className={classNameBuilder('campaign-status-container', campaignStatusClassName)}>
                        <Id id={`${id}`} className={styles['campaign-id']} />
                        <BidStrategyValue />
                        <Status formattedStatusMsg={formattedStatusMsg} isActive={isActive} onChange={onChange} />
                    </div>
                </Collapsible>
            )}
        </div>
    );
};

CampaignEditorHeader.propTypes = {
    /** Status of the Component */
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    headerClassName: PropTypes.string,
    campaignStatusClassName: PropTypes.string,
    collapsed: PropTypes.bool,
};

CampaignEditorHeader.defaultProps = {
    collapsed: false,
};

export default CampaignEditorHeader;
