import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { find, some } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { isNetworkAccountType } from 'modules/account-management';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useCampaignLevelConversionsApi } from 'services/api/campaignLevelConversionsApi';

const emptyObj = [];
export const useClcInitialData = () => {
    const [{ type }] = useSelectedAccount();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { value: campaignId } = useFormFieldValue({ field: 'id' });
    const { getAccountLevelConversionRulesData, getCampaignLevelConversionRulesData } =
        useCampaignLevelConversionsApi();
    const { value: conversionRules } = useFormFieldValue({
        field: 'conversionRules.rules',
    });

    const { data = emptyObj } = useQuery(
        [campaignId, accountId, type, 'clcRules'],
        () => {
            return campaignId && !isNetworkAccountType(type)
                ? getCampaignLevelConversionRulesData(accountId, campaignId)
                : getAccountLevelConversionRulesData(accountId);
        },
        {
            select: data => data?.results,
            refetchOnWindowFocus: false,
        }
    );

    const initialData = useMemo(
        () => data?.filter(item => some(conversionRules, rule => item.apiUnipRuleConfiguration?.id === rule.id)) ?? [],
        [data, conversionRules]
    );
    const accountDefaultRule = useMemo(() => find(data, { isDisabled: true })?.displayName, [data]);

    return { initialData, accountDefaultRule };
};
