import { useCallback } from 'react';
import { groupBy, mapValues, isNil, values, keyBy } from 'lodash';
import { useCurrentValueGetter } from '../../../../../../../hooks';

const getCurrentCount = segments => mapValues(groupBy(segments, 'taxonomy'), 'length');

// given an array of taxonomyIds, convert this to segment paths for the taxonomyIds where there are no children for that taxonomy.
export const useEmptyTaxonomiesGetter = (segments, unbundledNodes) => {
    const segmentsValueGetter = useCurrentValueGetter(segments);

    const getEmptyTaxonomies = useCallback(() => {
        const segments = segmentsValueGetter();
        const segmentsMap = keyBy(segments, 'id');
        const currentCountPerTaxonomy = getCurrentCount(segments);

        const emptyTaxonomies = values(unbundledNodes).reduce((taxonomies, { path, id }) => {
            const taxonomyCount = currentCountPerTaxonomy[path];
            const isTaxonomyEmpty = isNil(taxonomyCount) || taxonomyCount === 0;
            const isNodeInSegments = segmentsMap[id];

            if (isTaxonomyEmpty && isNodeInSegments) {
                taxonomies.push(path);
            }

            return taxonomies;
        }, []);

        return emptyTaxonomies;
    }, [segmentsValueGetter, unbundledNodes]);

    return { getEmptyTaxonomies };
};
