import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './sectionLoading.module.scss';

const SectionLoading = () => (
    <ContentLoader width={313} height={70} speed={2} className={styles['content']}>
        <rect x="0" y="8" rx="2" ry="2" width="40" height="5" />
        <rect x="0" y="22" rx="2" ry="2" width="55" height="5" />
        <rect x="0" y="34" rx="2" ry="2" width="95" height="20" />
    </ContentLoader>
);

export default SectionLoading;
