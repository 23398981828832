import React, { useCallback, useMemo } from 'react';
import { noop, stubTrue } from 'lodash';
import PropTypes from 'prop-types';
import { useMountSafeState, useEventListener } from 'taboola-ultimate-ui';
import { UNCHECKED, INTERMEDIATE, CHECKED } from '../../../../config';
import { BaseCheckboxCellRenderer } from '../CellRenderers';
import { useGridContext } from '../GridContextProvider/GridContextProvider';
import styles from './checkboxHeaderRenderer.module.scss';

const getSelectedState = (editableRowsCount, selectedRowsCount) => {
    const isChecked = editableRowsCount && selectedRowsCount === editableRowsCount;
    const isIntermediate = editableRowsCount && selectedRowsCount && selectedRowsCount < editableRowsCount;

    if (isIntermediate) {
        return INTERMEDIATE;
    } else if (isChecked) {
        return CHECKED;
    } else {
        return UNCHECKED;
    }
};

const calculateEditableRows = (api, isDisabled) => {
    const editableRows = [];
    api.forEachNode((node = { data: {} }) => {
        const { data } = node;
        if (!isDisabled || !isDisabled(data)) {
            editableRows.push(data);
        }
    });
    const selectedRowsCount = api.getSelectedNodes().length;
    const selected = getSelectedState(editableRows.length, selectedRowsCount);

    return { editableRows, selected };
};

const useEditableRowsAndSelected = (api, isDisabled) => {
    const { editableRows: initialEditableRows, selected: initialSelected } = useMemo(
        () => calculateEditableRows(api, isDisabled),
        [api, isDisabled]
    );
    const [editableRows, setEditableRows] = useMountSafeState(initialEditableRows);
    const [selected, setSelected] = useMountSafeState(initialSelected);

    const updateRowCounts = useCallback(() => {
        const { editableRows, selected } = calculateEditableRows(api, isDisabled);
        setEditableRows(editableRows);
        setSelected(selected);
    }, [api, isDisabled, setEditableRows, setSelected]);

    useEventListener('rowDataUpdated', updateRowCounts, api);

    return { editableRows, selected };
};

const CheckboxHeaderRenderer = ({ context: { dispatch }, api, onClick, isDisabled }) => {
    const { isGridEditDisabled } = useGridContext();
    const { editableRows, selected } = useEditableRowsAndSelected(api, isGridEditDisabled ? stubTrue : isDisabled);

    const handleClick = useCallback(value => dispatch(onClick(value, editableRows)), [onClick, dispatch, editableRows]);
    const handleSelect = useCallback(() => handleClick(true), [handleClick]);
    const handleUnselect = useCallback(() => handleClick(false), [handleClick]);

    return (
        <BaseCheckboxCellRenderer
            selectedValues={[CHECKED]}
            onSelectCheckbox={handleSelect}
            onIntermediateSelect={handleSelect}
            onUnselectCheckbox={handleUnselect}
            isIntermediate={selected === INTERMEDIATE}
            selected={selected}
            labelClassName={styles['label']}
            disabled={isGridEditDisabled}
        />
    );
};

CheckboxHeaderRenderer.propTypes = {
    context: PropTypes.object,
    api: PropTypes.object,
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
};

CheckboxHeaderRenderer.defaultProps = {
    context: { dispatch: noop },
};

export default CheckboxHeaderRenderer;
