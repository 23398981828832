import { cloneDeep, isEmpty, omitBy } from 'lodash';

export const cleanEventBeforeSending = event => {
    const cleanEvent = cloneDeep(event);
    cleanEvent.actions.forEach((action, index, array) => {
        array[index] = omitBy(
            action,
            (value, key) =>
                key === 'actionMetadata' || (key === 'actionData' && (isEmpty(value) || isEmpty(action.status)))
        );
    });

    return cleanEvent;
};
