import { useMemo } from 'react';
import { startCase } from 'lodash';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { getReportIdFromQuery } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useEntityType } from './useEntityType';
import { useFormMode } from './useFormMode';
import { useModuleName } from './useModuleName';

export const useDocumentTitle = () => {
    const { name } = useAccount();
    const module = useModuleName();
    const entity = useEntityType();
    const mode = useFormMode();
    const reportType = getReportIdFromQuery();
    const { formatMessage } = useIntl();

    const title = useMemo(() => {
        if (!module) {
            return null;
        }
        const selectedAccountName = name ? ` - ${name}` : '';

        if (entity && mode) {
            return `${startCase(mode.toLowerCase())} ${startCase(entity)}${selectedAccountName}`;
        }
        if (reportType) {
            const reportTitle = formatMessage({
                id: `app.campaigns.reports.picker.report.type.${reportType}`,
                defaultMessage: startCase(reportType),
            });
            return `${reportTitle}${selectedAccountName}`;
        }

        return `${startCase(module)}${selectedAccountName}`;
    }, [name, mode, entity, module, reportType, formatMessage]);

    return title;
};
