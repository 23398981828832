import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './errorMsg.module.scss';

const classNameBuilder = classnames.bind(styles);

const ErrorMsg = ({ errorMessage, errorMessageClass }) => (
    <div aria-label="error-message" className={classNameBuilder('error-message', errorMessageClass)}>
        {errorMessage}
    </div>
);

ErrorMsg.propTypes = {
    /** Error message */
    errorMessage: PropTypes.node,
    /** Optional Class name added to div element */
    errorMessageClass: PropTypes.string,
};

ErrorMsg.defaultProps = {
    errorMessage: '',
    errorMessageClass: '',
};

export default ErrorMsg;
