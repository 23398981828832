import { useMemo } from 'react';
import { useParams } from 'react-router';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from '../../taboola-common-frontend-modules/formData/FormDataContext';
import { getParams } from '../utils/getParams';

export const useMultiLayerPathParams = (customPath, customPattern) => {
    const { path: formPath, pattern: formPattern } = useFormDataContext();

    const path = customPath || formPath;
    const pattern = customPattern || formPattern;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });
    const params = useParams();

    const customizedParams = useMemo(() => {
        if (!isMultiLayerDrawerEnabled) {
            return params;
        }

        return getParams(path, pattern);
    }, [params, path, pattern, isMultiLayerDrawerEnabled]);

    return customizedParams;
};
