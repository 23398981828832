import { useEffect } from 'react';
import { isEmpty, omit, get, includes } from 'lodash';
import moment from 'moment-timezone';
import { LOCAL_STORAGE_ACCOUNT_KEYS } from '../consts';
import { useOnboardingLocalStorage } from '../hooks';

const { CTA_DELAY_DATA } = LOCAL_STORAGE_ACCOUNT_KEYS;

const isCTADelayed = ({ ctaDelayLocalStorage, delayStateDurationMinutes, localStorageName }) => {
    const lastClickedTimeStamp = get(ctaDelayLocalStorage, localStorageName);
    const minutesSinceLastClicked = lastClickedTimeStamp ? moment().diff(lastClickedTimeStamp, 'minutes') : Infinity;
    return minutesSinceLastClicked < delayStateDurationMinutes;
};
export const useIsCTADisabled = ({
    delayStateDurationMinutes,
    stepId,
    isComplete,
    onClick,
    setIsCTADisabled,
    CTAId,
}) => {
    const [ctaDelayLocalStorage, saveCtaDelayLocalStorage, deleteCtaDelayLocalStorage] = useOnboardingLocalStorage({
        key: CTA_DELAY_DATA,
    });

    const localStorageName = stepId + '_' + CTAId;
    const isDisabled =
        delayStateDurationMinutes &&
        isCTADelayed({ ctaDelayLocalStorage, localStorageName, delayStateDurationMinutes });
    // Cleanup function - When a delay has ended remove its timestamp from local storage.
    // If there are no timeStamp left in the object (no cta disabled) remove the field from local storage.
    useEffect(() => {
        const hasDelayEnded = !isDisabled && includes(ctaDelayLocalStorage, stepId);
        if (hasDelayEnded) {
            const omittedStepLocalStorage = omit(ctaDelayLocalStorage, stepId);
            saveCtaDelayLocalStorage(omittedStepLocalStorage);
        }
        if (isEmpty(ctaDelayLocalStorage)) {
            deleteCtaDelayLocalStorage();
        }
    }, [isDisabled, saveCtaDelayLocalStorage, ctaDelayLocalStorage, deleteCtaDelayLocalStorage, stepId]);

    useEffect(() => {
        if (isDisabled && setIsCTADisabled) {
            setIsCTADisabled(true);
        }
    }, [isDisabled, setIsCTADisabled]);

    const handleClick = () => {
        const clickTimeStamp = moment().valueOf();
        if (!isComplete && CTAId) {
            saveCtaDelayLocalStorage({ ...ctaDelayLocalStorage, [localStorageName]: clickTimeStamp });
        }
        onClick();
    };

    return { isDisabled, handleClick };
};
