import { useQuery } from 'react-query';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccountResourcesApi } from 'services/api/accountResourceApi';
import { useIsSupplyTargetingSelectPermitted } from './useIsSupplyTargetingSelectPermitted';

export const useAPISupplyTargetingRestrictions = () => {
    const isSupplyPermitted = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED]: 'true' });
    const isTaboolaDefaultValue = useConfigMatch({
        [FEATURE_FLAGS.SUPPLY_TARGETING_USE_TABOOLA_VALUE_AS_DEFAULT]: 'true',
    });
    const isTaboolaSelectEnabled = useIsSupplyTargetingSelectPermitted();
    const isPermitted = isSupplyPermitted || isTaboolaSelectEnabled;

    const { getSupplyTargeting } = useAccountResourcesApi();

    const { accountName } = useAccount();

    const { data: supplyTargetingResource } = useQuery(
        ['supply-targeting', accountName],
        () => getSupplyTargeting(accountName),
        {
            enabled: isPermitted,
            refetchOnWindowFocus: false,
        }
    );
    return {
        supplyTargetingResource,
        isSupplyPermitted,
        isTaboolaDefaultValue,
        isPermitted,
    };
};
