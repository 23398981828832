import ContentLoader from 'react-content-loader';
import styles from './performanceRecommendationLoading.module.scss';

export const PerformanceRecommendationLoading = () => {
    return (
        <div className={styles['card']}>
            <ContentLoader height={60} width={300} speed={2} className={styles['content']}>
                <rect x="0" y="8" rx="8" ry="8" width="250" height="10" />
                <rect x="0" y="25" rx="8" ry="8" width="182" height="10" />
            </ContentLoader>
        </div>
    );
};
