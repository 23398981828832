import { StatusIndicator } from 'tuui';

export const INVOICE_STATUS_TYPE = {
    OPEN: 'OPEN',
    PAID_IN_FULL: 'PAID_IN_FULL',
    PARTIAL: 'PARTIAL',
};

const invoiceStatusIndicatorTypeMap = {
    [INVOICE_STATUS_TYPE.PAID_IN_FULL]: StatusIndicator.type.success,
    [INVOICE_STATUS_TYPE.OPEN]: StatusIndicator.type.warning,
    [INVOICE_STATUS_TYPE.PARTIAL]: StatusIndicator.type.warning,
};

export const getInvoiceStatusIndicatorType = statusType => invoiceStatusIndicatorTypeMap[statusType];
