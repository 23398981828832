'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _indicationTypes = require('../IndicationBase/indicationTypes');

var _indicationTypes2 = _interopRequireDefault(_indicationTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'indicationContent-module__taboola-svg-icon___1Q7f6',
    'container': 'indicationContent-module__container___1sbFG',
    'icon': 'indicationContent-module__icon___2U_Lm',
    'custom': 'indicationContent-module__custom___2G4xI',
    'info': 'indicationContent-module__info___3WAXn',
    'success': 'indicationContent-module__success___1gfEk',
    'warning': 'indicationContent-module__warning___yAivF',
    'error': 'indicationContent-module__error___x0ZUj',
    'content': 'indicationContent-module__content___2_5a8'
};


var classNameBuilder = _bind2.default.bind(styles);

var IndicationContent = function IndicationContent(_ref) {
    var children = _ref.children,
        contentClassName = _ref.contentClassName,
        containerClassName = _ref.containerClassName,
        displayIcon = _ref.displayIcon,
        type = _ref.type,
        iconTypeOverride = _ref.iconTypeOverride;

    var renderIcon = (0, _react.useCallback)(function () {
        var icon = iconTypeOverride || type;
        var IconGraphic = _indicationTypes.ICONS[icon];
        return displayIcon && IconGraphic && _react2.default.createElement(IconGraphic, { className: classNameBuilder('icon', type) });
    }, [displayIcon, type, iconTypeOverride]);

    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('container', containerClassName), 'aria-label': type },
        renderIcon(),
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('content', contentClassName) },
            children
        )
    );
};

IndicationContent.propTypes = {
    /** The type of the Indication. can be: notification | success | alert | error | custom  */
    type: _propTypes2.default.oneOf(Object.values(_indicationTypes2.default)),
    /** Override the default icon behavior */
    iconTypeOverride: _propTypes2.default.oneOf(Object.values(_indicationTypes2.default)),
    /** Determines if the icon component is visible or not */
    displayIcon: _propTypes2.default.bool,
    /** Container class name */
    containerClassName: _propTypes2.default.string,
    /** Content wrapper class name */
    contentClassName: _propTypes2.default.string,
    children: _propTypes2.default.node
};

IndicationContent.defaultProps = {
    type: _indicationTypes2.default.CUSTOM,
    displayIcon: true
};

exports.default = IndicationContent;