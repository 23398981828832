import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

const ColDefLabel = ({ field, headerName, headerComponentParams }) => {
    if (headerComponentParams) {
        const { messageIdPrefix } = headerComponentParams;
        return <FormattedMessage id={`${messageIdPrefix}.${field}`} defaultMesage={headerName} />;
    }

    return headerName;
};

ColDefLabel.propTypes = {
    field: PropTypes.string,
    headerName: PropTypes.string,
    headerComponentParams: PropTypes.object,
};

export default ColDefLabel;
