import React from 'react';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import styles from './campaignMissingFeatureItemValue.module.scss';

const prefix = ': ';

const CampaignMissingFeatureItemValue = ({ value, tooltipContent }) => {
    if (!value) {
        return null;
    }

    if (!tooltipContent) {
        return <span>{`${prefix}${value}`}</span>;
    }

    return (
        <>
            <span>{prefix}</span>
            <span className={styles['tooltip-text']}>
                {value}
                <Tooltip arrow interactive>
                    {tooltipContent}
                </Tooltip>
            </span>
        </>
    );
};

export default CampaignMissingFeatureItemValue;
