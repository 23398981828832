import { AudienceRecommendation } from '../components/AudienceRecommendation/AudienceRecommendation';
import BudgetPerformanceRecommendation from '../components/BudgetPerformanceRecommendation/BudgetPerformanceRecommendation';
import { CPAPerformanceRecommendation } from '../components/CPAPerformanceRecommendation/CPAPerformanceRecommendation';
import DayPartingPerformanceRecommendation from '../components/DayPartingPerformanceRecommendation/DayPartingPerformanceRecommendation';
import { FatiguedItemPerformanceRecommendation } from '../components/FatiguedItemPerformanceRecommendation/FatiguedItemPerformanceRecommendation';
import ItemWithoutCTAPerformanceRecommendation from '../components/ItemWithoutCTAPerformanceRecommendation/ItemWithoutCTAPerformanceRecommendation';
import { ItemWithoutDescriptionPerformanceRecommendation } from '../components/ItemWithoutDescriptionPerformanceRecommendation/ItemWithoutDescriptionPerformanceRecommendation';
import LowPerformingItemPerformanceRecommendation from '../components/LowPerformingItemPerformanceRecommendation/LowPerformingItemPerformanceRecommendation';
import { LowResolutionPerformanceRecommendation } from '../components/LowResolutionPerformanceRecommendation/LowResolutionPerformanceRecommendation';
import LowerCPCPerformanceRecommendation from '../components/LowerCPCPerformanceRecommendation/LowerCPCPerformanceRecommendation';
import PlatformTargetingPerformanceRecommendation from '../components/PlatformTargetingPerformanceRecommendation/PlatformTargetingPerformanceRecommendation';
import { UnblockSitesPerformanceRecommendation } from '../components/UnblockSitesPerformanceRecommendation/UnblockSitesPerformanceRecommendation';
import { UnrealisticTCPAPerformanceRecommendation } from '../components/UnrealisticTCPAPerformanceRecommendation/UnrealisticTCPAPerformanceRecommendation';
import PERFORMANCE_RECOMMENDATIONS from './performanceRecommendations';

export const PERFORMANCE_RECOMMENDATIONS_CAMPAIGN_CARD_TYPES = {
    [PERFORMANCE_RECOMMENDATIONS.LOWER_CPC]: LowerCPCPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.DAY_PARTING]: DayPartingPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.PLATFORM_TARGETING]: PlatformTargetingPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.BUDGET_V2_IC]: BudgetPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.LOW_PERFORMING_ITEM]: LowPerformingItemPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_CTA]: ItemWithoutCTAPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.ITEM_WITHOUT_DESCRIPTION]: ItemWithoutDescriptionPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.TARGET_CPA]: CPAPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.UNREALISTIC_TCPA]: UnrealisticTCPAPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.AUDIENCE]: AudienceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.LOW_RESOLUTION_IC]: LowResolutionPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.UNBLOCK_SITES]: UnblockSitesPerformanceRecommendation,
    [PERFORMANCE_RECOMMENDATIONS.FATIGUED_ITEM]: FatiguedItemPerformanceRecommendation,
};
