import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import OsTree from '../OsTree/OsTree';

const OsTargetingDropdown = ({ search, onSearchChange, styles }) => {
    return (
        <BareDropdown
            id="os-targeting-dropdown"
            onChange={noop}
            placeholder={
                <FormattedMessage
                    id="campaign.editor.targeting.os.select.placeholder"
                    defaultMessage="Select Operating Systems..."
                />
            }
            searchable
            inputValue={search}
            onInputChange={text => onSearchChange(text)}
            menuListRenderer={OsTree}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            styles={styles}
        />
    );
};

OsTargetingDropdown.propTypes = {
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    width: PropTypes.number,
};

export default OsTargetingDropdown;
