import React, { useCallback, useEffect } from 'react';
import { Radio } from 'taboola-ultimate-ui';
import { useAvailableItems } from 'hooks';
import { RadioGroupField } from 'modules/campaigns/components/Form';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../config';
import { useTargetCpaAdditionalValidations } from '../BidStrategyV2/MaxConversionsCategory/useTargetCpaAdditionalValidations';
import { useBidStrategyValidatedFormValue } from '../BidStrategyV2/hooks/useBidStrategyValidatedFormValue';
import { useUpdateConversionGoalOnBidStrategyChange } from '../BidStrategyV2/hooks/useUpdateConversionGoalOnBidStrategyChange';
import { BidStrategyTooltip } from './BidStrategyTooltip/BidStrategyTooltip';
import { cpaConfig, SmartBidRadio } from './SmartBidRadio/SmartBidRadio';
import { TargetRoasRadio } from './TargetRoasRadio/TargetRoasRadio';
import styles from '../commonEditor.module.scss';

const bidStrategiesOptions = [
    {
        value: BID_STRATEGIES.SMART,
        title: <FormattedMessage id="campaign.editor.smart.bid.title" defaultMessage="Smart Bid" />,
        description: (
            <FormattedMessage
                id="campaign.editor.smart.bid.description"
                defaultMessage="Your bid will adjust according to the conversions you track."
            />
        ),
        passValidationMessage: true,
        Component: SmartBidRadio,
    },
    {
        title: <FormattedMessage id="campaign.editor.target.roas.bid.title" defaultMessage="Target ROAS" />,
        description: (
            <FormattedMessage
                id="campaign.editor.target.roas.bid.description"
                defaultMessage="Sets bids to help you get the most conversion value while maintaining your target return on ad spend."
            />
        ),
        Component: TargetRoasRadio,
        accountConfigurations: { [FEATURE_FLAGS.TARGET_ROAS_ENABLED]: 'true' },
    },
    {
        value: BID_STRATEGIES.FIXED,
        title: <FormattedMessage id="campaign.editor.fixed.bid.title" defaultMessage="Fixed Bid" />,
        description: (
            <FormattedMessage id="campaign.editor.fixed.bid.description" defaultMessage="Manually control your bid." />
        ),
    },
];

const targetCpaDuplicateCampaignEnabled = { [FEATURE_FLAGS.TARGET_CPA_DUPLICATE_CAMPAIGN_ENABLED]: 'true' };

const BidStrategy = () => {
    const bidStrategies = useAvailableItems(bidStrategiesOptions);

    const {
        mode,
        value: bidStrategy,
        onChange: onChangeOrigin,
        indicationData: indicationDataFromBidStrategy,
        scrollRef,
    } = useBidStrategyValidatedFormValue();

    const { targetCpaValidationDependencies, targetCpaValidations } = useTargetCpaAdditionalValidations();

    const isTargetCpaBeta = useConfigMatch(cpaConfig);
    const isTargetCpaDuplicateCampaignEnabled = useConfigMatch(targetCpaDuplicateCampaignEnabled);
    const shouldDisableCpaOnDuplicate =
        !isTargetCpaBeta && !isTargetCpaDuplicateCampaignEnabled && mode === FORM_MODES.DUPLICATE;
    const shouldCleanBidStrategy = shouldDisableCpaOnDuplicate && bidStrategy === BID_STRATEGIES.TARGET_CPA;
    const onChange = useCallback(value => (value ? onChangeOrigin(value) : null), [onChangeOrigin]);

    useUpdateConversionGoalOnBidStrategyChange();

    useEffect(() => {
        if (shouldCleanBidStrategy) {
            onChangeOrigin(null);
        }
    }, [shouldCleanBidStrategy, onChangeOrigin]);

    return (
        <RadioGroupField
            inputId="bid-strategy"
            groupTitle={<FormattedMessage id="campaign.editor.bid.strategy.title" defaultMessage="Bid Strategy" />}
            groupHelpText={<BidStrategyTooltip />}
            className={styles['input']}
            radioGroupName="bid-strategy"
            onChange={onChange}
            selectedValue={bidStrategy}
            data-metrics-component="BidStrategy"
            ref={scrollRef}
            {...indicationDataFromBidStrategy}
        >
            {bidStrategies.map(({ value, title, description, Component, passValidationMessage }) => {
                const isSelected = bidStrategy === value;
                const RadioComponent = Component || Radio;
                const extraProps = passValidationMessage
                    ? {
                          targetCpaValidations,
                          targetCpaValidationDependencies,
                          shouldDisableCpaOnDuplicate,
                      }
                    : {};
                return (
                    <RadioComponent
                        aria-label={`${isSelected ? 'Selected - ' : ''}${value}`}
                        key={`bid-strategy-${value}`}
                        value={value}
                        title={title}
                        description={description}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={value}
                        {...extraProps}
                    />
                );
            })}
        </RadioGroupField>
    );
};

export default BidStrategy;
