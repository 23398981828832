import { useMemo } from 'react';
import { useApi } from '../hooks';
import { createHighImpactTargetingApi } from './highImpactTargetingFactory';

export const useHighImpactTargetingApi = () => {
    const api = useApi();
    const highImpactTargetingApi = useMemo(() => createHighImpactTargetingApi(api), [api]);

    return highImpactTargetingApi;
};
