import { reportsBaseConfig, REPORT_TYPE } from 'modules/campaigns/config';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { updateAudience } from '../../../../../../audiences/flows';
import {
    AudienceActionsCellRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    withCampaignEditIndication,
} from '../../../components';
import {
    staticColumn,
    leftPinnedColumn,
    actionsColumnType,
    rightPinnedColumn,
    numericColumn,
    hiddenColumnType,
} from '../../columns';
import * as FIELDS from '../../fields';

const campaignReportColumnDefinition = [
    {
        headerName: FIELDS.ID.label,
        field: FIELDS.ID.field,
        type: [hiddenColumnType, numericColumn],
    },
    {
        headerName: FIELDS.SEGMENT_NAME.label,
        field: FIELDS.SEGMENT_NAME.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.SEGMENT_NAME.cellRenderer,
    },
    {
        headerName: FIELDS.ACCOUNT_ID.label,
        field: FIELDS.ACCOUNT_ID.field,
    },
    {
        headerName: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.field,
        field: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.field,
        headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.headerComponentParams,
        cellRendererFramework: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.cellRendererFramework,
        cellRendererParams: FIELDS.AUDIENCE_ACCOUNT_DESCRIPTION.cellRendererParams,
    },
    {
        headerName: FIELDS.AUDIENCE_CAMPAIGN_USING.label,
        field: FIELDS.AUDIENCE_CAMPAIGN_USING.field,
        type: [numericColumn],
        cellRendererFramework: FIELDS.AUDIENCE_CAMPAIGN_USING.cellRenderer,
        headerComponentParams: FIELDS.AUDIENCE_CAMPAIGN_USING.headerComponentParams,
        headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
    },
    {
        headerName: FIELDS.LAST_MODIFY_BY.label,
        field: FIELDS.LAST_MODIFY_BY.field,
        headerComponentParams: FIELDS.LAST_MODIFY_BY.headerComponentParams,
    },
    {
        headerName: FIELDS.LAST_MODIFY_ON.label,
        field: FIELDS.LAST_MODIFY_ON.field,
        valueFormatter: FIELDS.LAST_MODIFY_ON.valueFormatter,
        csvValueGetter: FIELDS.LAST_MODIFY_ON.csvValueGetter,
        headerComponentParams: FIELDS.LAST_MODIFY_ON.headerComponentParams,
    },
    {
        headerName: FIELDS.CREATE_TIME.label,
        field: FIELDS.CREATE_TIME.field,
        valueFormatter: FIELDS.CREATE_TIME.valueFormatter,
        csvValueGetter: FIELDS.CREATE_TIME.csvValueGetter,
        headerComponentParams: FIELDS.CREATE_TIME.headerComponentParams,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererParams: {
            onAudienceArchive: updateAudience,
            reportType: REPORT_TYPE.CUSTOM_CONTEXTUAL,
        },
        cellRendererFramework: AudienceActionsCellRenderer,
        csv: false,
        minWidth: 100,
        maxWidth: 100,
        width: 100,
    },
];

export const columnDefinitions = normalizeColumnDefs(
    campaignReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.CUSTOM_CONTEXTUAL].id,
    withCampaignEditIndication
);
