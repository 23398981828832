import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const activeEventsTrackingReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.ACTIVE_EVENTS],
    endpoint: 'active-events',
    rowIdField: FIELDS.EVENT_NAME.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: [],
    searchPlaceholderMsgId: 'tracking.active.events.report.search.placeholder',
    searchField: FIELDS.EVENT_NAME.field,
    filters: [
        //TODO - DEV 129744
        // {
        //     id: FILTER_TYPE.DEFINED_CONVERSIONS,
        // },
        // {
        //     id: FILTER_TYPE.EVENT_NAME,
        // },
    ],
    initialFilters: [],
};

export default activeEventsTrackingReportConfig;
