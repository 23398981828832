import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CSVUploadModule from 'modules/campaigns/components/CSVUploadModule/CSVUploadModule';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { CRM_FILE_TYPE } from '../../config';
import { validateFiles } from '../../config';

const templateFileUrlConfig = {
    [CRM_FILE_TYPE.EMAIL]: 'https://cdn.taboola.com/static/b9/emails_template.csv',
    [CRM_FILE_TYPE.DEVICE_ID]: 'https://cdn.taboola.com/static/b9/mobile_ids_template.csv',
    [CRM_FILE_TYPE.ZIP_CODE]: 'https://cdn.taboola.com/static/b9/zipcode_template.csv',
};

export const CrmFileUpload = ({ crmFileType }) => {
    const { value: file, onChange: onFileChange } = useFormFieldValue({
        field: 'file',
    });
    const onRemoveFileHandler = useCallback(() => onFileChange(null), [onFileChange]);
    const onDropFileHandler = useCallback(acceptedFiles => onFileChange(acceptedFiles[0]), [onFileChange]);
    const templateFileUrl = templateFileUrlConfig[crmFileType] || templateFileUrlConfig[CRM_FILE_TYPE.DEVICE_ID];

    return (
        <CSVUploadModule
            guidelinesMessage={
                <FormattedMessage
                    id={`audience.editor.crm.upload.csv.file.${crmFileType}.guidelines`}
                    defaultMessage="Drag & Drop CSV File"
                />
            }
            templateFileUrl={templateFileUrl}
            onRemoveFileHandler={onRemoveFileHandler}
            file={file}
            onDropFileHandler={onDropFileHandler}
            additionalValidateFiles={validateFiles}
        />
    );
};

CrmFileUpload.propTypes = {
    crmFileType: PropTypes.string.isRequired,
};

export default CrmFileUpload;
