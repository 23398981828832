import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useValidations } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useValidations';
import { itemValidations } from '../MarketplaceAudienceValidations';

const useSegmentListItemValidations = ({ item, field, enabledProvidersMap = {}, isProvidersReady = true }) => {
    const { isDirty } = useFormFieldValue({ field });
    const { mode } = useFormDataContext();
    const { value: countryCodes } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });
    const countriesMap = useMemo(() => keyBy(countryCodes), [countryCodes]);

    const { loading, allowedCountries = [], provider, isActive, id } = item;
    const valueToValidate = useMemo(
        () => [allowedCountries, provider, isActive, id],
        [allowedCountries, provider, isActive, id]
    );
    const { value: countryTargetingType } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.type',
    });

    const { failedValidationData: { message, indicationType } = {} } = useValidations({
        validations: itemValidations,
        validationId: `${field}.${id}`,
        valueToValidate,
        validationDependencies: {
            enabledProvidersMap,
            countriesMap,
            countryTargetingType,
        },
        isDirty,
        isReady: !loading && isProvidersReady,
        hasInitialData: mode !== FORM_MODES.CREATE,
    });

    return { message, indicationType };
};

export default useSegmentListItemValidations;
