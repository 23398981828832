import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { BulkDownload } from './BulkDownload/BulkDownload';

export const DownloadSection = ({ conditionData }) => {
    return (
        <CommonCollapsibleCard
            id="bulk-operations-download-section"
            header={
                <SectionHeader headerText={<FormattedMessage id="excel.bulk.download" defaultMessage="Download" />} />
            }
        >
            <BulkDownload conditionData={conditionData} />
        </CommonCollapsibleCard>
    );
};
