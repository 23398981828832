import React from 'react';
import SectionLoading from './SectionLoading/SectionLoading';

const BodyLoading = ({ className }) => (
    <div className={className} role="progressbar" aria-label="Loading container">
        <SectionLoading />
        <SectionLoading />
    </div>
);

export default BodyLoading;
