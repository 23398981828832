import React, { useState } from 'react';
import { get } from 'lodash';
import { FormField, InputTypes, InputWithButton } from 'taboola-ultimate-ui';
import { FileTypes } from 'taboola-ultimate-ui';
import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { SupportedVideoTypes } from 'modules/campaigns/config/VideoTypes';
import ThumbnailsTooltipForUrl from 'modules/campaigns/modules/creative-creator/components/ThumbnailsPicker/ThumbnailsTooltipForUrl';
import { THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import {
    MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID,
    THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID,
} from 'modules/campaigns/modules/creative-creator/config/thumbnailValidationConsts';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import {
    transformImageItem,
    transformVideoItem,
} from 'modules/campaigns/modules/creative-editor/utils/fetchedMediaTransformationUtils';
import { withIndication } from 'modules/errors';
import ValidationError from 'modules/errors/ValidationError';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { validateImage } from 'modules/taboola-common-frontend-modules/validations';
import { operationsApi } from 'services/api';
import { useMediaTabsContext } from '../MediaTabsContextProvider/MediaTabsContextProvider';
import { GALLERY_ITEM_SOURCES } from '../consts';
import styles from './urlInputField.module.scss';

const UrlInput = withIndication(InputWithButton);
const shouldBlockByFormatType = ({ isMotion, creativeType, isUnifiedCreativesFormDuplicateMode }) => {
    if (isMotion && creativeType === CREATIVE_TYPE.IMAGE && !isUnifiedCreativesFormDuplicateMode) {
        throw new ValidationError('Image file loading has failed', THUMBNAIL_URL_LOAD_FAILED_ERROR_MESSAGE_ID);
    }
};

export const UrlInputField = ({
    initialValue,
    setUploadErrorMessageId,
    onInitUpload,
    onLoadedMedia,
    onRemoveUpload,
}) => {
    const { formatMessage } = useIntl();
    const [value, setValue] = useState(initialValue);
    const { campaignId, titleList } = useMediaTabsContext();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    const handleMotionUrl = async url => {
        const uploadId = onInitUpload();
        const motionObject = { id: uploadId, src: url, fileType: FileTypes.VIDEO, type: THUMBNAIL_SOURCE_TYPES.URL_TA };
        operationsApi
            .uploadMotionAdsByUrlWithImageRecommendation(accountId, motionObject, campaignId, titleList)
            .then(({ url, gifUrl, fallbackImageUrl }) => {
                onLoadedMedia(uploadId, transformVideoItem({ videoUrl: url, gifUrl, thumbnailUrl: fallbackImageUrl }));
            })
            .catch(e => {
                setUploadErrorMessageId(e.messageCode);
                onRemoveUpload(uploadId);
            });
    };

    const handleImageUrl = async url => {
        const uploadId = onInitUpload();
        validateImage(url)
            .then(() => operationsApi.uploadThumbnailFromUrl(accountId, url))
            .then(({ value }) => {
                onLoadedMedia(
                    uploadId,
                    transformImageItem({ thumbnailUrl: value }, GALLERY_ITEM_SOURCES.MEDIA_LIBRARY)
                );
            })
            .catch(e => {
                if (e.status === MIN_THUMBNAIL_SIZE_VALIDATION_ERROR_MESSAGE_ID) {
                    setUploadErrorMessageId('creative.creator.thumbnails.fetching.thumbnailSizeTooSmall');
                } else {
                    setUploadErrorMessageId(`creative.creator.thumbnails.fetching.${e?.status}`);
                }
                onRemoveUpload(uploadId);
            });
    };

    const onAddButtonClick = async url => {
        const urlWithoutQuery = get(`${url}`.split('?'), '[0]', '').toLowerCase();
        const isMotion = SupportedVideoTypes.some(videoType => urlWithoutQuery.endsWith(videoType));
        try {
            shouldBlockByFormatType({
                isMotion,
                creativeType,
                setUploadErrorMessageId,
                isUnifiedCreativesFormDuplicateMode,
            });
        } catch (e) {
            setUploadErrorMessageId('validations.error.field.url.ImageLoadFailed');
            return;
        }
        if (isMotion) {
            await handleMotionUrl(url);
        } else {
            await handleImageUrl(url);
        }
    };

    return (
        <FormField
            helpText={<ThumbnailsTooltipForUrl />}
            label={
                <FormattedMessage
                    id="creative.editor.tab.mediaLibrary.url.input.title"
                    defaultMessage="Option to add files using a URL"
                />
            }
            description={
                <FormattedMessage
                    id="creative.editor.tab.mediaLibrary.url.input.description"
                    defaultMessage="Max file sizes are: Images 2.5 Mb. GIFs 5 mb. Video 50 Mb"
                />
            }
            containerClass={styles['form-field']}
        >
            <UrlInput
                value={value}
                id="media-library-url-input"
                role="textbox"
                type={InputTypes.URL}
                size="large"
                onButtonClick={onAddButtonClick}
                onChange={val => setValue(val)}
                placeholder={formatMessage({
                    id: 'creative.editor.tab.mediaLibrary.url.input.placeholder',
                    defaultMessage: 'Type Media URL here',
                })}
                buttonLabel={
                    <FormattedMessage id="creative.editor.tab.mediaLibrary.url.input.add.button" defaultMessage="ADD" />
                }
                buttonClass={styles['add-button']}
            />
        </FormField>
    );
};
