import React from 'react';
import { errorMessagesUtils } from 'services/utils';
import { accountsApi } from '../../../services/api';
import { addIndication, INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

export const successIndication = {
    message: <FormattedMessage id="account.save.success" defaultMessage="Account successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="account.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const updateAccount =
    ({ account }) =>
    async dispatch => {
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        try {
            const updateAccountResponse = await accountsApi.updateAdvertiser(account.accountName, account);
            dispatch(addIndication(successIndication));
            return updateAccountResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            throw error;
        }
    };
