import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIsSupplyTargetingSelectPermitted } from './useIsSupplyTargetingSelectPermitted';

export const useSupplyTargetingFormField = () => {
    const isSupplyPermitted = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED]: 'true' });
    const isTaboolaDefaultValue = useConfigMatch({
        [FEATURE_FLAGS.SUPPLY_TARGETING_USE_TABOOLA_VALUE_AS_DEFAULT]: 'true',
    });
    const isTaboolaSelectEnabled = useIsSupplyTargetingSelectPermitted();
    const isPermitted = isSupplyPermitted || isTaboolaSelectEnabled;

    const formFieldProps = useFormFieldValue(
        {
            field: 'campaignTargeting.supplyTargeting',
        },
        { isSupplyPermitted, isTaboolaDefaultValue, isPermitted }
    );

    return formFieldProps;
};
