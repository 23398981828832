import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { saveAs as saveAsFile } from 'file-saver';
import { values, keys } from 'lodash';
import memoizeOne from 'memoize-one';
import { ENTITY } from 'modules/campaigns/config';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { useBulkOperationsApi } from 'services/api';
import { generateErrorIndication } from 'services/utils';
import ApiError from '../../../../errors/ApiError';
import { EXCEL_BULK_FILE_NAME } from '../consts/fileName';
import { buildExcelToEntityDataMap } from '../services/buildExcelToEntityDataMap';
import { buildMediaMap } from '../services/buildMediaMap';
import { buildParsingErrorReport } from '../services/buildParsingErrorReport';
import { getDefaultExportableEntitiesState } from '../services/exportableEntityServices';
import { getUploadExcelRequestMap } from '../services/getUploadExcelRequestMap';
import { ExcelBulkError } from '../transformers/ExcelBulkError';
import { sendGtmUploadErrorEvents } from '../utils/bulkUploadGtmUtils';
import { getExcelBulkFileName } from '../utils/excelBulkWorkbookUtils';
import { generateResponseSummary } from '../utils/generateResponseSummary';
import { getExcelBulkErrorIndication } from '../utils/getExcelBulkErrorIndication';
import { useCreateSubmissionReport } from './useCreateSubmissionReport';
import { useDictionaryData } from './useDictionaryData';
import { useExcelBulkCommonMetadata } from './useExcelBulkCommonMetadata';
import { useExcelConfig } from './useExcelConfig';

const DEFAULT_COUNTRY_CODE = 'US';
const getCachedMediaMap = memoizeOne((accountName, mediaFile, mediaZip) =>
    buildMediaMap({ accountName, mediaFile, mediaZip })
);

export const useExcelBulkFormState = ({ accountCountry, conditionData } = {}) => {
    const dispatch = useDispatch();
    const excelConfig = useExcelConfig({ conditionData });
    const [reportFile, setReportFile] = useState();
    const [responseSummary, setResponseSummary] = useState({ errors: [], successes: [] });
    const { accountDescription, accountName } = useAccount();

    const fetchedFormData = useMemo(() => {
        return {
            isSuccess: true,
            data: {
                selectedCountry: accountCountry || DEFAULT_COUNTRY_CODE,
                exportableEntities: getDefaultExportableEntitiesState(excelConfig.entityList),
            },
        };
    }, [accountCountry, excelConfig]);
    const { submit, step, setStep, nextStep, prevStep } = useFormState({ fetchedFormData });
    const commonMetadata = useExcelBulkCommonMetadata();
    const { formatMessage } = commonMetadata;
    const {
        isLoading: isLoadingDictionaryData,
        isError: isErrorDictionaryData,
        ...dictionaryData
    } = useDictionaryData();
    const [processedEntitiesCount, setProcessedEntitiesCount] = useState({});
    const [maxEntitiesCount, setMaxEntitiesCount] = useState({});
    const { [COMMON_FLAGS.BULK_UPLOAD_NUM_CAMPAIGNS_PER_BATCH]: numCampaignsPerBatch } = useCommonConfig([
        COMMON_FLAGS.BULK_UPLOAD_NUM_CAMPAIGNS_PER_BATCH,
    ]);
    const { [COMMON_FLAGS.BULK_UPLOAD_NUM_CREATIVES_PER_BATCH]: numCreativesPerBatch } = useCommonConfig([
        COMMON_FLAGS.BULK_UPLOAD_NUM_CREATIVES_PER_BATCH,
    ]);

    const { bulkCreateAndUpdateCreatives, createBulkCampaigns } = useBulkOperationsApi();

    const createReportFile = useCreateSubmissionReport({ commonMetadata, dictionaryData });

    const uploadExcelBulkFileHandler = useCallback(
        async formData => {
            try {
                const { file, mediaFile, mediaZip } = formData;
                const fullUploadProcessTimeStart = Date.now();
                const mediaMap = await getCachedMediaMap(accountName, mediaFile, mediaZip);

                const { data: entitiesData, hasParsingError } = await buildExcelToEntityDataMap({
                    file,
                    mediaMap,
                    excelConfig,
                    dictionaryData,
                    formatMessage,
                });

                if (hasParsingError) {
                    const { reportFileBlob: parsingReportFile, responseSummary: parsingResponseSummary } =
                        await buildParsingErrorReport({
                            excelConfig,
                            file,
                            formatMessage,
                            entitiesData,
                        });

                    sendGtmUploadErrorEvents({ entitiesData, fullUploadProcessTimeStart });
                    setReportFile(parsingReportFile);
                    setResponseSummary(parsingResponseSummary);
                    return values(entitiesData);
                }

                const requestMap = await getUploadExcelRequestMap({
                    entitiesData,
                    apiCallerMap: {
                        [ENTITY.CAMPAIGN]: {
                            apiCall: createBulkCampaigns,
                            batchSize: numCampaignsPerBatch,
                            eventName: 'Campaign Upload Duration',
                        },
                        [ENTITY.CREATIVE]: {
                            apiCall: bulkCreateAndUpdateCreatives,
                            batchSize: numCreativesPerBatch,
                            eventName: 'Creative Upload Duration',
                        },
                    },
                    setMaxEntitiesCount,
                    setProcessedEntitiesCount,
                    formatMessage,
                });

                const apiResponseList = await Promise.all(values(requestMap));
                const entityDataMap = keys(requestMap).reduce((acc, entity, index) => {
                    acc[entity] = apiResponseList[index].elements;
                    return acc;
                }, {});

                const reportFileBlob = await createReportFile(excelConfig, entityDataMap);
                setReportFile(reportFileBlob);

                setResponseSummary(generateResponseSummary(excelConfig, entityDataMap));

                sendGtmUploadErrorEvents({ entityDataMap, fullUploadProcessTimeStart });

                return apiResponseList;
            } catch (error) {
                if (error instanceof ExcelBulkError) {
                    dispatch(
                        addIndication(
                            getExcelBulkErrorIndication({
                                messageCode: 'only.value.message',
                                templateParameters: { value: error.message },
                            })
                        )
                    );
                } else if (error instanceof ApiError) {
                    dispatch(addIndication(generateErrorIndication(error)));
                } else {
                    dispatch(
                        addIndication(getExcelBulkErrorIndication({ messageCode: 'excel.bulk.upload.transform.error' }))
                    );
                }

                throw error;
            }
        },
        [
            accountName,
            createBulkCampaigns,
            numCampaignsPerBatch,
            bulkCreateAndUpdateCreatives,
            numCreativesPerBatch,
            excelConfig,
            dictionaryData,
            formatMessage,
            createReportFile,
            dispatch,
        ]
    );

    const resetLoadingState = useCallback(() => {
        setProcessedEntitiesCount({});
        setMaxEntitiesCount({});
    }, []);

    const uploadExcelBulkFile = useCallback(async () => {
        resetLoadingState();
        await submit(uploadExcelBulkFileHandler);
    }, [resetLoadingState, submit, uploadExcelBulkFileHandler]);

    const downloadReport = useCallback(() => {
        const filename = getExcelBulkFileName(EXCEL_BULK_FILE_NAME.REPORT_FILE_NAME, formatMessage, accountDescription);
        saveAsFile(reportFile, filename);
    }, [accountDescription, reportFile, formatMessage]);

    const isSubmitDisabled = isLoadingDictionaryData || isErrorDictionaryData;

    return {
        uploadExcelBulkFile,
        isSubmitDisabled,
        downloadReport,
        responseSummary,
        loadingState: {
            valuePerEntity: processedEntitiesCount,
            maxPerEntity: maxEntitiesCount,
        },
        step,
        setStep,
        nextStep,
        prevStep,
    };
};
