import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'hooks';
import { cpaValidations } from 'modules/campaigns/config/validations/campaign/cpaValidations';
import { RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES } from 'modules/campaigns/modules/performance-recommendations/config/PerformanceRecommendationsActionParamTypes';
import { useCampaignRecommendation } from 'modules/campaigns/modules/performance-recommendations/hooks/useCampaignRecommendation';
import { generateCampaignEditorPath } from 'modules/campaigns/services/utils';
import { setRecommendations } from 'modules/recommendations';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useCpaGoalFormFieldValue } from '../../common-campaign-form/components/CpaGoal/useCpaGoalFormFieldValue';
import { useCpaRecommendationMetadata } from './useCpaRecommendationMetadata';
import { useRecommendationActionParam } from './useRecommendationActionParam';

export const useCpaRecommendation = ({ recommendation }) => {
    const { actions = [], messageParams, id: recommendationId, type: recommendationType } = recommendation;
    const { campaign, accountId, campaignId } = useCampaignRecommendation({ recommendation });
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const shouldDisplayWarnings = useConfigMatch({ [FEATURE_FLAGS.TARGET_CPA_ENABLED]: 'true' });
    const shouldRecommendTarget = useConfigMatch(
        {},
        { [COMMON_FLAGS.UNREALISTIC_TCPA_SHOULD_RECOMMEND_TARGET]: 'true' }
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { min_range: minRange, max_range: maxRange } = messageParams;
    const additionalValidations = useMemo(
        () => cpaValidations(minRange, maxRange, shouldDisplayWarnings),
        [maxRange, minRange, shouldDisplayWarnings]
    );
    const { recommendationData, recommendationActionId } = useRecommendationActionParam(recommendation);
    const recommendationMetadata = useCpaRecommendationMetadata(messageParams, shouldRecommendTarget);

    const actionParamValue = useMemo(
        () => ({
            ...recommendationData,
            cpaGoal: targetCpa,
        }),
        [targetCpa, recommendationData]
    );

    const applyParams = useMemo(
        () => ({
            accountId,
            campaignId,
            recommendationId,
            actionId: recommendationActionId,
            actionParams: {
                [RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES.CAMPAIGN]: JSON.stringify({
                    ...actionParamValue,
                }),
            },
        }),
        [accountId, campaignId, recommendationId, recommendationActionId, actionParamValue]
    );
    const navigateToEditCampaign = useCallback(() => {
        dispatch(
            setRecommendations({
                recommendationType,
                recommendationId,
                recommendationData: { bidStrategy: undefined },
                recommendationMetadata,
            })
        );

        navigate(generateCampaignEditorPath({ accountId, campaignId }));
    }, [accountId, campaignId, dispatch, navigate, recommendationId, recommendationMetadata, recommendationType]);

    return {
        actions,
        actionParamValue,
        additionalValidations,
        applyParams,
        campaign,
        campaignId,
        accountId,
        recommendationId,
        messageParams,
        navigateToEditCampaign,
        recommendationData,
        recommendationMetadata,
    };
};
