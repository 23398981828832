import React, { useMemo } from 'react';
import { InformationIcon } from 'tuui';
import { BareDropdown, FormField } from 'taboola-ultimate-ui';
import { ExternalLink } from 'components';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { BidControlCategory } from './BidControlCategory/BidControlCategory';
import { withBidStrategyCacheProvider } from './BidStrategyCacheProvider';
import { MaxConversionsCategory } from './MaxConversionsCategory/MaxConversionsCategory';
import { StrategySelectionIndication } from './StrategySelectionIndication/StrategySelectionIndication';
import { BidStrategyItem } from './TargetCpaStrategy/BidStrategyItem';
import { useBidStrategyDescriptionMessageId } from './TargetCpaStrategy/useBidStrategyDescriptionMessageId';
import { bidStrategiesCategory, useBidStrategiesOptions } from './hooks/useBidStrategiesOptions';
import { useBidStrategyCategoryState } from './hooks/useBidStrategyCategoryState';
import { useBidStrategyState } from './hooks/useBidStrategyState';
import { useUpdateConversionGoalOnBidStrategyChange } from './hooks/useUpdateConversionGoalOnBidStrategyChange';
import commonStyles from '../commonEditor.module.scss';
import styles from './bidStrategyV2.module.scss';

const bidStrategyCategoryToComponent = {
    [bidStrategiesCategory.MAX_CONVERSIONS]: MaxConversionsCategory,
    [bidStrategiesCategory.BID_CONTROL]: BidControlCategory,
};

const FormFieldWithIndication = withIndication(FormField);

const ValueRenderer = ({ data: { value, label } }) => {
    return <FormattedMessage id={`campaign.editor.bid.strategy.category.${value}`} defaultMessage={label} />;
};

const BidStrategyV2 = () => {
    const { indicationData, scrollRef, bidStrategy, onChangeBidStrategy, availableBidStrategies } =
        useBidStrategyState();

    const { onChangeStrategyCategory, bidStrategyCategory } = useBidStrategyCategoryState({
        bidStrategy,
        onChangeBidStrategy,
        availableBidStrategies,
    });
    const { bidStrategyOptions, selectedValue } = useBidStrategiesOptions({
        bidStrategyCategory,
        availableBidStrategies,
    });
    const { formatMessage } = useIntl();

    useUpdateConversionGoalOnBidStrategyChange();

    const descriptionMessageId = useBidStrategyDescriptionMessageId(selectedValue?.value, availableBidStrategies);

    const BidStrategyCategoryComponent = bidStrategyCategoryToComponent[bidStrategyCategory];

    const dropdownStyle = useMemo(
        () => ({
            menuList: props => ({ ...props, padding: 0 }),
        }),
        []
    );

    if (bidStrategyOptions.length === 1) {
        return <BidStrategyCategoryComponent />;
    }

    return (
        <div className={styles['bid-strategy-container']}>
            <FormFieldWithIndication
                inputId="bidStrategyCategory"
                containerClass={styles['field-container']}
                labelClass={styles['label']}
                label={
                    <>
                        <FormattedMessage
                            id="campaign.editor.bidding.strategy.title"
                            defaultMessage="Bidding Strategy"
                        />
                        <ExternalLink
                            href={formatMessage({ id: 'campaign.editor.bidding.strategy.help.center.href' })}
                            className={styles['link']}
                        >
                            <InformationIcon className={styles['info-icon']} />
                            <div>Help Center</div>
                        </ExternalLink>
                    </>
                }
                description={
                    <FormattedMessage
                        id="campaign.editor.bidding.strategy.description"
                        defaultMessage="What do you want to focus on?"
                    />
                }
                ref={scrollRef}
                indicationContainerClass={commonStyles[`${indicationData.indicationType}-indication`]}
                {...indicationData}
            >
                <BareDropdown
                    id="bid-selector"
                    className={styles['dropdown-wrapper']}
                    containerClassName={styles['dropdown-container']}
                    styles={dropdownStyle}
                    selectedValueObject={selectedValue}
                    options={bidStrategyOptions}
                    onChange={onChangeStrategyCategory}
                    optionItemRenderer={BidStrategyItem}
                    availableBidStrategies={availableBidStrategies}
                    valueRenderer={ValueRenderer}
                />
                <div className={styles['description']}>
                    <FormattedMessage id={descriptionMessageId} />
                </div>
                <BidStrategyCategoryComponent availableBidStrategies={availableBidStrategies} />
            </FormFieldWithIndication>
            <StrategySelectionIndication />
        </div>
    );
};

const BidStrategyWithCache = withBidStrategyCacheProvider(BidStrategyV2);
export { BidStrategyWithCache as BidStrategyV2 };
