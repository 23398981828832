import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { PublisherBlockingCellRenderer } from '../../../components';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import { gridNumberFormatter, formattedNumberValueGetter } from '../../../components/ValueFormatters';
import { toggleSiteBlocking } from '../../../flows';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import { leftPinnedColumn, leftPinnedToggleColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const siteReportColumnDefinition = [
    ...[
        FIELDS.SPENT,
        FIELDS.COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
    {
        headerName: FIELDS.PUBLISHER_BLOCKING.label,
        field: FIELDS.PUBLISHER_BLOCKING.field,
        type: [leftPinnedToggleColumn],
        permissions: ['VIDEO_API_BLOCK_SITES_EDIT'],
        cellRendererParams: {
            ...FIELDS.PUBLISHER_BLOCKING.renderParams,
        },
        valueChangeHandler: toggleSiteBlocking,
        valueSetter: params => {
            params.data.isPublisherBlocked = params.newValue;
            params.data.publisherBlockingLevel = 'NONE';
            return true;
        },
        cellRendererFramework: PublisherBlockingCellRenderer,
        csvValueGetter: FIELDS.PUBLISHER_BLOCKING.csvValueGetter,
        csvValueGetterParams: FIELDS.PUBLISHER_BLOCKING.csvValueGetterParams,
    },
    {
        headerName: FIELDS.SITE_NAME.label,
        field: FIELDS.SITE_NAME.field,
        type: [leftPinnedColumn],
        cellRendererFramework: FIELDS.SITE_NAME.cellRenderer,
        cellRendererParams: FIELDS.SITE_NAME.renderParams,
        width: 350,
        csv: [
            {
                ...FIELDS.SITE_NAME.csv[0],
                field: FIELDS.SITE_NAME.field,
            },
        ],
    },
];

export default normalizeColumnDefs(siteReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.SITE_VIDEO].id);
