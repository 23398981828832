/**
 * campaignReportFooterColumnDefinition - list of configurations for columns that contain non-default footer only.
 * This configuration is merged with grid column configuration by DataGrid using `field` as a key for merging.
 * Merge of all props is done with Object.assign mechanism, except `type` property.
 * `type` of footer config is appended to grid column config `type`.
 * `type` is appended because footer config should contain rest of functionality defined by grid column config.
 * Possible to set footer config without any type (not even from grid column config), by setting: `type: null` in footer config.
 *
 * NOTE: The footer `type` should be last in the list of configurations in order to override all props of previous types.
 */
import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

const emptyColumns = [
    FIELDS.IS_SELECTED,
    FIELDS.IS_ACTIVE,
    FIELDS.ID,
    FIELDS.CUSTOM_CREATIVE_NAME,
    FIELDS.THUMBNAIL_URL,
    FIELDS.THUMBNAIL_URL_COMFORTABLE,
    FIELDS.CONTENT,
    FIELDS.TITLE,
    FIELDS.URL,
    FIELDS.STATUS,
    FIELDS.CAMPAIGN_NAME,
    FIELDS.CREATE_TIME,
    FIELDS.ACTIONS_COLUMN,
    FIELDS.ACCOUNT_ID,
    FIELDS.ACCOUNT_DESCRIPTION,
    FIELDS.CTA,
    FIELDS.DESCRIPTION,
    FIELDS.BID_MODIFICATION,
    FIELDS.START_DATE,
    FIELDS.END_DATE,
].map(createFooterMapper(REPORT_TYPE.CREATIVE));

const creativeReportFooterColumnDefinition = [...emptyColumns];

export default creativeReportFooterColumnDefinition;
