import {
    FETCH_ACCOUNT_ADDITIONAL_DATA_ERROR,
    FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS,
    REQUEST_FETCH_ACCOUNT_ADDITIONAL_DATA,
} from './actionTypes';

export const requestFetchAccountAdditionalData = () => ({ type: REQUEST_FETCH_ACCOUNT_ADDITIONAL_DATA });

export const fetchAccountAdditionalDataSuccess = account => ({
    type: FETCH_ACCOUNT_ADDITIONAL_DATA_SUCCESS,
    payload: account,
});

export const fetchAccountAdditionalDataError = error => ({ type: FETCH_ACCOUNT_ADDITIONAL_DATA_ERROR, payload: error });
