import { pick } from 'lodash';
import {
    BUDGET_TYPES,
    campaignInitialValues,
    MARKETING_OBJECTIVE_KEYS,
    SPENDING_LIMITS,
} from 'modules/campaigns/modules/common-campaign-form/config';
import { OS_FAMILY_KEYS } from 'modules/campaigns/modules/creative-creator/components/AdSetupSection/useAdSetupUrlInputGroupConfig';
import { PLACEMENTS } from 'modules/campaigns/modules/creative-preview/config/placements';

export const onboardingJourneyInitialValues = {
    ...pick(campaignInitialValues, [
        'startDate',
        'campaignSchedule',
        'conversionRulesMode',
        'conversionRules',
        'campaignTargeting',
        'campaignProfile',
        'pricingModel',
        'bidStrategy',
        'platformTargeting',
    ]),
    marketingObjective: MARKETING_OBJECTIVE_KEYS.LEADS_GENERATION,
    targetCost: {
        maxValue: '',
        minValue: '',
    },
    budget: {
        type: BUDGET_TYPES.DAILY,
        value: 150,
        spendingLimit: {
            type: SPENDING_LIMITS.MONTHLY,
            value: null,
        },
    },
    selectedPlacement: PLACEMENTS.SIXTEEN_BY_NINE,
    landingPage: {
        landingPageUrl: '',
        appUrl: '',
        osFamily: OS_FAMILY_KEYS.ANDROID,
    },
    creatives: [
        {
            id: 'YjcyMjYyYzAtNGNjZi0xMWVmLWJjNGMtNmQ3NmE1NjFlMDkxYzI1ZmJjNTAtNGNjZi0xMWVmLWJjNGMtNmQ3NmE1NjFlMDkxYzE5MTIxYjAtNGNjZi0xMWVmLWJjNGMtNmQ3NmE1NjFlMDkx',
            deleted: false,
            url: 'https://taboola.com',
            thumbnailUrl: 'https://www.taboola.com//wp-content/uploads/2020/07/thumbnail_1200x630.jpg',
            displayUrl: 'https://www.taboola.com//wp-content/uploads/2020/07/thumbnail_1200x630.jpg',
            creativeCrop: {
                cropData: [],
            },
            title: 'Home Page',
            description: 'Descriptioning a descriptioned description in Description',
            cta: { ctaType: 'LEARN_MORE' },
            thirdPartyTags: null,
            recommendedFBImage: null,
            mediaUploadSource: null,
            motionAdsStudio: null,
            appInstall: null,
            rating: null,
            logo: null,
            creativeName: '',
            customId: '',
            brandingText: 'Some Branding text',
            language: 'EN',
        },
        {
            id: 'MTc0NjY1NjAtNGNkMS0xMWVmLWE2ZGMtOGY0Y2E1YjRlYTY0NjVkMGU3MDAtNGNkMS0xMWVmLWE2ZGMtOGY0Y2E1YjRlYTY0NjU5ZDA1YzAtNGNkMS0xMWVmLWE2ZGMtOGY0Y2E1YjRlYTY0',
            deleted: false,
            url: 'https://www.ynet.co.il',
            thumbnailUrl: 'https://www.ynet.co.il/images/og/logo/www.ynet.co.il_new.png',
            displayUrl: 'https://www.ynet.co.il/images/og/logo/www.ynet.co.il_new.png',
            creativeCrop: {
                cropData: [],
            },
            title: 'ynet - חדשות, כלכלה, ספורט ובריאות - דיווחים שוטפים מהארץ ומהעולם',
            description: 'You can upload your own or generate more creatives',
            cta: { ctaType: 'LEARN_MORE' },
            thirdPartyTags: null,
            recommendedFBImage: null,
            mediaUploadSource: null,
            motionAdsStudio: null,
            appInstall: null,
            rating: null,
            logo: null,
            creativeName: '',
            customId: '',
            brandingText: '',
            language: 'EN',
        },
        {
            id: 'Yj4M5YmU5MzAtNGNkMS0xMWVmLThmmQtMjE5MjdjNDJlODVkZjE1NjEwYzQtNGNkMS0xMWVmLThmZmQtMjE5MjdjNDJlODVkZjEyNjI3MjAtNGNkMS0xMWVmLThmZmQtMjE5MjdjNDJlODVk',
            deleted: false,
            url: 'https://www.n12.co.il',
            thumbnailUrl:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_Apps_Script.svg/1024px-Google_Apps_Script.svg.png',
            displayUrl:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_Apps_Script.svg/1024px-Google_Apps_Script.svg.png',
            creativeCrop: {
                cropData: [],
            },
            title: 'ביגוד ונעלי ספורט באתר הרשמי של אדידס | אדידס ישראל',
            description: 'היוקרה החדשה פחות מעונבת ויותר מודעת',
            cta: { ctaType: 'LEARN_MORE' },
            thirdPartyTags: null,
            recommendedFBImage: null,
            mediaUploadSource: null,
            motionAdsStudio: null,
            appInstall: null,
            rating: null,
            logo: null,
            creativeName: '',
            customId: '',
            brandingText: '',
            language: 'EN',
        },
        {
            id: 'YjM5YmU5MzAtNGNkMS0xMWVmLThmZmQtMjE5MjdjNDJlODVkZjE1NjEwYzQtNGNkMS0xMWVmLThmZmQtMjE5MjdjNDJlODVkZjEyNjI3MjAtNGNkMS0xMWVmLThmZmQtMjE5MjdjNDJlODVk',
            deleted: false,
            url: 'https://www.n12.co.il',
            thumbnailUrl: 'https://img.mako.co.il/2020/02/17/SHAREIMG.png',
            displayUrl: 'https://img.mako.co.il/2020/02/17/SHAREIMG.png',
            creativeCrop: {
                cropData: [],
            },
            title: 'Lorem Ipsum',
            description: 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum',
            cta: { ctaType: 'LEARN_MORE' },
            thirdPartyTags: null,
            recommendedFBImage: null,
            mediaUploadSource: null,
            motionAdsStudio: null,
            appInstall: null,
            rating: null,
            logo: null,
            creativeName: '',
            customId: '',
            brandingText: '',
            language: 'EN',
        },
    ],
};
