import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Textarea } from 'tuui';
import { BareDropdown } from 'taboola-ultimate-ui';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { INCLUDE_EXCLUDE_OPTIONS } from '../../../config';
import { TargetingTypeDropdownOption } from '../../TargetingTypeDropdown';
import styles from './listWithMultilineInputBoxTopContent.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ListWithMultilineInputBoxTopContent = ({
    typeField,
    error,
    requestedIdsText,
    onRequestedIdsChange,
    placeholder,
}) => {
    const { value: type, onChange: onChangeType } = useFormFieldValue({ field: `${typeField}` });
    const handleTypeChange = useCallback(
        ({ value }) => {
            onChangeType(value);
        },
        [onChangeType]
    );

    return (
        <div className={styles['container']}>
            <div className={styles['dropdown-wrapper']}>
                <BareDropdown
                    value={type}
                    options={INCLUDE_EXCLUDE_OPTIONS}
                    optionItemRenderer={TargetingTypeDropdownOption}
                    onChange={handleTypeChange}
                    skipRightBorderRadius
                />
            </div>

            <div className={styles['vertical-delimiter']} />

            <div className={classNameBuilder('input-container')}>
                <Textarea
                    value={requestedIdsText}
                    placeholder={placeholder}
                    onChange={onRequestedIdsChange}
                    className={classNameBuilder({ error })}
                    textareaClassName={styles['textarea']}
                    rows={4}
                />
            </div>
        </div>
    );
};

ListWithMultilineInputBoxTopContent.propTypes = {
    formFieldValuePrefix: PropTypes.string,
    error: PropTypes.bool,
    requestedIdsText: PropTypes.string,
    onRequestedIdsChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default ListWithMultilineInputBoxTopContent;
