import classnames from 'classnames/bind';
import { Input, Wizard, WizardStep } from 'taboola-ultimate-ui';
import Logo from '../../../components/AppHeader/components/Logo';
import useEventValueHandler from '../../../hooks/useEventValueHandler';
import { PAYMENT_TYPE } from '../../billing-and-payments/config/PaymentType';
import { withPaymentProvider } from '../../billing-and-payments/providers/payment-providers/PaymentProvider/PaymentProvider';
import { withIndication } from '../../errors';
import { withFormDataProvider } from '../../taboola-common-frontend-modules/formData';
import { useFormState } from '../../taboola-common-frontend-modules/forms';
import { useFormValidatedValue, validationFunctions } from '../../taboola-common-frontend-modules/validations';
// import { AdsVariations } from './components/creatives/AdsVariations/AdsVariations';
import { onboardingJourneyInitialValues } from './config/onboardingJourneyInitialValues';
import styles from './onboardingJourneyForm.module.scss';

const classNameBuilder = classnames.bind(styles);

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'audience.editor.validations.error.audience.type.empty',
        defaultMessage: 'Select an Audience Type',
    },
];
const DemoTestComponent = ({ field = 'test' }) => {
    const { value = '', onChange, indicationData } = useFormValidatedValue({ field, validations, throttleValue: true });
    const handler = useEventValueHandler(onChange);

    return <InputWithIndication size={Input.SIZE.LARGE} value={value} onChange={handler} {...indicationData} />;
};

const OnboardingJourney = () => {
    const { step, setStep, prevStep, nextStep, registerStep } = useFormState();

    return (
        <div className={classNameBuilder('container')}>
            <div className={classNameBuilder('header')}>
                <Logo className={styles['logo']} />
            </div>
            {/*<AdsVariations />*/}
            <div>
                <button onClick={prevStep}>Prev step</button>
                <button onClick={nextStep}>Next step</button>
            </div>
            <Wizard step={step} onStepChange={setStep} registerStep={registerStep}>
                <WizardStep id="1" label="step 1">
                    <div>
                        <WizardStep id="11" label="sub step 1">
                            Step 1 - sub step 1 content
                            <DemoTestComponent />
                        </WizardStep>
                        <WizardStep id="12" label="sub step 2">
                            Step 1 - sub step 2 content
                            <DemoTestComponent />
                        </WizardStep>
                        <WizardStep id="14" label="sub step 3">
                            Step 1 - sub step 3 content
                        </WizardStep>
                    </div>
                </WizardStep>
                <WizardStep id="2" label="step 2">
                    <WizardStep id="21" label="sub step 1">
                        Step 2 - sub step 1 content
                    </WizardStep>
                    <WizardStep id="22" label="sub step 2">
                        Step 2 - sub step 2 content
                    </WizardStep>
                </WizardStep>
                <WizardStep id="3" label="step 3">
                    No sub steps
                    <hr />
                </WizardStep>
                <WizardStep id="4" label="step 4">
                    <WizardStep id="41" label="sub step 1">
                        Step 4 - sub step 1 content
                    </WizardStep>
                    <WizardStep id="42" label="sub step 2">
                        Step 4 - sub step 2 content
                    </WizardStep>
                </WizardStep>
            </Wizard>
        </div>
    );
};

export const OnboardingJourneyForm = withPaymentProvider(
    withFormDataProvider(OnboardingJourney, {
        defaultValuesConfig: onboardingJourneyInitialValues,
    }),
    { paymentMethodType: PAYMENT_TYPE.CARD }
);
