import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import resourcesApi from '../../../../../services/api/resourcesApi';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { requestFetchBrowsers, fetchBrowsersSuccess, fetchBrowsersError } from '../actions';

const errorIndication = {
    message: (
        <FormattedMessage
            id="campaign.editor.geo.targeting.browsers.fetch.error"
            defaultMessage="We were not able to fetch list of browsers"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export const fetchBrowsers = () => dispatch => {
    dispatch(requestFetchBrowsers());
    resourcesApi
        .getBrowsers()
        .then(browsers => {
            dispatch(fetchBrowsersSuccess(browsers));
        })
        .catch(error => {
            dispatch(addIndication(errorIndication));
            dispatch(fetchBrowsersError(error));
        });
};

export default fetchBrowsers;
