import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { AlertIcon } from 'taboola-ultimate-ui';
import { DESCRIPTION_FIELD_ID } from 'modules/campaigns/components/ContentDescription/ContentDescription';
import { ContentDescriptionField } from 'modules/campaigns/modules/creative-editor/components/fields/ContentDescriptionField';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { withRecommendationFormValidationDataProvider } from 'modules/taboola-common-frontend-modules/formData/withRecommendationFormValidationDataProvider';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import recommendationsApi from 'services/api/recommendationsApi';
import { isNilOrEmptyString } from 'services/utils/stringUtils';
import { RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES } from '../../config/PerformanceRecommendationsActionParamTypes';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';
import styles from './itemWithoutDescriptionSingleItemRecommendation.module.scss';

const ItemWithoutDescriptionSingleItemRecommendation = ({
    recommendation,
    removeRecommendationIndication,
    applyRecommendationIndication,
}) => {
    const { failedValidationData, value: description } = useFormValidatedValue({
        field: DESCRIPTION_FIELD_ID,
    });
    const { formatMessage } = useIntl();
    const { actions, id: recommendationId, itemId, accountName: accountId, campaignId, messageParams } = recommendation;
    const [action] = actions;
    const { actionId } = action;
    const dismissItemRecommendation = recommendationsApi.dismissItemPerformanceRecommendation;
    const applyItemRecommendation = recommendationsApi.executeItemRecommendationAction;
    const applyParams = useMemo(
        () => ({
            accountId,
            campaignId,
            recommendationId,
            actionId,
            itemId,
            actionParams: {
                [RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES.CREATIVE]: JSON.stringify({
                    description,
                }),
            },
        }),
        [accountId, actionId, campaignId, description, itemId, recommendationId]
    );
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        markAsApplied: false,
        applyParams,
        dismissParams: {
            accountId,
            campaignId,
            recommendationId,
            itemId,
        },
        onApply: applyItemRecommendation,
        onApplySuccess: applyRecommendationIndication,
        onDismiss: dismissItemRecommendation,
        onDismissSuccess: removeRecommendationIndication,
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageParams}
            titleCode="performance-recommendations.item-without-description-single-item.title"
            descriptionCode="performance-recommendations.item-without-description-single-item.description"
            isApplied={apply.isSuccess}
            isRenderedInReportCell={true}
            iconImg={AlertIcon}
        >
            <ContentDescriptionField
                label={formatMessage({
                    id: 'creative.creator.content.description.label',
                    defaultMessage: 'Description',
                })}
                className={styles['description-input']}
                shouldShowTooltip={false}
                containerClassName={styles['description-form-field-container']}
            />
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode="performance-recommendations.item-without-description-single-item.action"
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode="performance-recommendations.item-without-description-single-item.tooltip"
                    onClick={apply.invoke}
                    isItemRec={true}
                    disabled={isNilOrEmptyString(description) || !isEmpty(failedValidationData)}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

const ItemWithoutDescriptionSingleItemRecommendationWithForm = withRecommendationFormValidationDataProvider(
    ItemWithoutDescriptionSingleItemRecommendation
);
export { ItemWithoutDescriptionSingleItemRecommendationWithForm as ItemWithoutDescriptionSingleItemRecommendation };
