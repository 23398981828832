import { navigate } from 'actions';
import { generateCreativeEditorPath } from 'modules/campaigns/services/utils';
import { dimensionSelector, selectedAccountSelector } from 'selectors';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { FORM_MODES, PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP } from '../../../config';
import { creativesMapSelector } from '../../../selectors';
import { setCreativeBeingEdited } from '../../creative-editor';
import { INITIAL_CREATIVE_TYPE } from '../../creative-editor/hooks/useInitialCreativeType';

const previewCreative = (data, history) => (dispatch, getState) => {
    const { id: creativeId } = data;
    const state = getState();
    const creativeType = PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP[data.performanceStatus];
    const { accountId: selectedAccountId } = selectedAccountSelector(state);
    const dimension = dimensionSelector(state);
    const creativesMap = creativesMapSelector(state);
    const creative = creativesMap[creativeId] || {};

    const { accountName, campaignId } = creative;
    const accountId = accountName || selectedAccountId;

    const previewCreativePath = generateCreativeEditorPath({
        mode: FORM_MODES.PREVIEW,
        accountId,
        campaignId,
        dimension,
        creativeId,
    });

    const pathWithCreativeType = `${previewCreativePath}${mergeQueryParams('', {
        [INITIAL_CREATIVE_TYPE]: creativeType,
    })}`;

    dispatch(setCreativeBeingEdited(creative));
    dispatch(navigate(history, pathWithCreativeType));
};

export default previewCreative;
