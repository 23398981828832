import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'modules/campaigns/config';
import { BID_STRATEGIES } from 'modules/campaigns/modules/common-campaign-form/config';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

const validations = [
    {
        validationFn: (value, options, { mode, originalBidStrategy }) => {
            return (
                mode !== FORM_MODES.EDIT ||
                value === originalBidStrategy ||
                (value !== BID_STRATEGIES.TARGET_CPA && originalBidStrategy !== BID_STRATEGIES.TARGET_CPA)
            );
        },
        messageId: 'validations.error.campaign.target.cpa.bid.strategy.changed',
        defaultMessage:
            'Please note that as a result of changing a bidding strategy, your campaign might re-enter the learning phase until sufficient data is collected.',
        severity: INDICATION_TYPES.WARNING,
    },
];

export const useBidStrategyValidatedFormValue = () => {
    const {
        mode,
        initialData: { bidStrategy: originalBidStrategy },
    } = useFormDataContext();
    const isMaxConversionsEnabled = useConfigMatch({ [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: 'true' });
    const { value, onChange, indicationData, scrollRef } = useFormValidatedValue({
        field: 'bidStrategy',
        validations,
        validationDependencies: {
            mode,
            originalBidStrategy,
        },
        formFieldDependencies: {
            isMaxConversionsEnabled,
        },
    });

    return {
        value,
        onChange,
        indicationData,
        scrollRef,
        mode,
    };
};
