import React, { useCallback } from 'react';
import { FormField, TextCheckbox } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { TooltipSection } from '../../../campaigns/modules/common-campaign-form';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import { PerformanceRulesExcludeMinAge } from './PerformanceRulesExcludeMinAge';
import styles from './PerformanceRulesExcludeNewCampaigns.module.scss';

export const PerformanceRulesExcludeNewCampaigns = () => {
    const { value: excludeNewCampaigns, onChange } = useFormValidatedValue({
        field: 'excludeNewCampaigns',
    });

    const shouldRenderExcludeNewCampaigns = useConfigMatch({
        [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLE_EXCLUDE_NEW_CAMPAIGNS]: 'true',
    });

    const onSelect = useCallback(() => onChange(true), [onChange]);
    const onUnSelect = useCallback(() => {
        onChange(false);
    }, [onChange]);

    const tooltip = (
        <TooltipSection>
            <FormattedMessage
                id="app.performance.rules.setup.min.campaign.age.tooltip"
                defaultMessage="When enabling this option, custom rules will run only on campaigns that are live and running for at least X days. New campaigns (running less than X days ago) will be excluded from the rule."
            />
        </TooltipSection>
    );

    if (!shouldRenderExcludeNewCampaigns) {
        return null;
    }

    return (
        <FormField
            inputId="performance-rules-min-campaign-age-input"
            label={
                <FormattedMessage
                    id="app.performance.rules.setup.min.campaign.age.title"
                    defaultMessage="Exclude new campaigns"
                />
            }
            helpText={tooltip}
            containerClass={styles['field-container']}
        >
            <TextCheckbox
                value={!!excludeNewCampaigns}
                title={
                    <FormattedMessage
                        id="app.performance.rules.setup.min.campaign.age.checkbox.label"
                        defaultMessage="Apply rules only to campaigns older than X days."
                    />
                }
                labelClassName={styles['label']}
                data-metrics-component="performance-rules-exclude-new-campaigns-checkbox"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                onSelectCheckbox={onSelect}
                onUnselectCheckbox={onUnSelect}
                selectedValues={[true]}
                independent
            />
            {excludeNewCampaigns && <PerformanceRulesExcludeMinAge />}
        </FormField>
    );
};
