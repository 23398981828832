import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { isNil } from 'lodash';
import { FormField } from 'taboola-ultimate-ui';
import { getFormattedSizeAndArticles } from '../../../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiences/utils/MarketplaceAudiencesUtils';
import { useIntl } from '../../../../../taboola-common-frontend-modules/i18n';
import { ArticlesReachTooltip } from './ArticlesReachTooltip';
import { ImpressionsReachTooltip } from './ImpressionsReachTooltip';
import styles from './sizeEstimationSizeField.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SizeEstimationSizeField = ({ field, isLoading, count }) => {
    const { formatMessage } = useIntl();
    const { formattedSize } = useMemo(() => getFormattedSizeAndArticles({ size: count }), [count]);
    const hasData = !isNil(count);
    const loadingNode = <ContentLoader height={12} width={112} speed={2} className={styles['loading']} />;
    const countNode = <span className={styles['count']}>{formattedSize}</span>;
    return (
        <FormField
            inputId="size-estimation-impressions"
            labelClass={classNameBuilder({ 'gray-label': isLoading || !hasData })}
            label={formatMessage({
                id: `custom.contextual.size.estimation.${field}.label`,
                defaultMessage: 'Size',
            })}
            helpText={field === 'impressions' ? <ImpressionsReachTooltip /> : <ArticlesReachTooltip />}
        >
            {isLoading ? loadingNode : countNode}
        </FormField>
    );
};

export const SizeEstimationArticlesField = props => <SizeEstimationSizeField field="articles" {...props} />;
export const SizeEstimationImpressionsField = props => <SizeEstimationSizeField field="impressions" {...props} />;
