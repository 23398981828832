import React from 'react';
import assign from 'lodash/assign';
import { injectIntl, ONLY_VALUE_MESSAGE_ID } from 'modules/taboola-common-frontend-modules/i18n';
import { normalizeHeaderRendererProps } from '../../utils';

// Takes in a Component, normalizes the props such that the headerComponentParams
// are flattened to the first level.
const normalizeHeaderRenderer = Component => originalProps => {
    // HACK: the dragged label when re-ordering columns uses innerHTML, thus the returned
    // component must have a toString property. Ideally, that property is a formattedMessage.
    const result = {
        toString: () => 'loading',
    };

    // updates the result's toString function to contain the translated string
    const updateToString = ({ intl, toStringMessageIdPrefix, field, headerName, useOnlyValue }) => {
        const newString = useOnlyValue
            ? intl.formatMessage({ id: ONLY_VALUE_MESSAGE_ID, defaultValue: headerName }, { value: headerName })
            : intl.formatMessage({ id: `${toStringMessageIdPrefix}.${field}` });

        result.toString = () => newString;
    };

    const NormalizedRenderer = injectIntl(props => {
        // normalize the props passed to the child
        const normalizedProps = normalizeHeaderRendererProps(props);

        // update the results toString property
        updateToString(normalizedProps);

        // pass the normalized props down to the child component
        return <Component {...normalizedProps} />;
    });

    // merge the returned component with the toString property object
    // resulting in a React Component with a toString property. Important
    // the result is first, because it maintains the original reference
    return assign(result, <NormalizedRenderer {...originalProps} />);
};

export default normalizeHeaderRenderer;
