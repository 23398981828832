import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { ImageIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { CAMPAIGNS_ROUTE, CREATIVE_TYPE } from 'modules/campaigns/config';
import { LogoField } from 'modules/campaigns/modules/creative-creator/components/LogoField/LogoField';
import { WochitProvider } from 'modules/campaigns/modules/creative-creator/services/wochit';
import { UnifiedCreativeEditorBody } from 'modules/campaigns/modules/creative-editor/components/UnifiedCreativeEditor/Body/UnifiedCreativeEditorBody';
import { useMatchingCreativeIds } from 'modules/campaigns/modules/creative-editor/hooks';
import { useUnifiedCreativeEditorFormState } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormState';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './unifiedCreativeEditorForm.module.scss';

const mediaSectionTitle = <FormattedMessage id="creative.editor.media" defaultMessage="Media" />;

export const UnifiedCreativeEditorFormMediaSection = ({
    accountId,
    campaignId,
    creativeId,
    addIdToMatchingCreativeIds,
    onCancel,
}) => {
    const {
        creative,
        creativeType,
        isThumbnailLoading,
        setIsThumbnailLoading,
        applyFocusToMatches,
        setApplyFocusToMatches,
        thumbnailStatus,
        setThumbnailStatus,
        videoStatus,
        setVideoStatus,
        isLogoFieldVisible,
        onChangeMatchingIds,
    } = useUnifiedCreativeEditorFormState();

    const { thumbnailUrl } = creative;
    const { matchingCreativeIds, matchingCreativeIdsToSave } = useMatchingCreativeIds(
        accountId,
        campaignId,
        creativeId,
        thumbnailUrl,
        applyFocusToMatches,
        addIdToMatchingCreativeIds
    );

    useEffect(() => {
        if (isEmpty(matchingCreativeIdsToSave)) {
            return;
        }
        onChangeMatchingIds(matchingCreativeIdsToSave);
    }, [matchingCreativeIdsToSave, onChangeMatchingIds]);

    return (
        <WochitProvider>
            <CommonCollapsibleCard
                id={creativeType === CREATIVE_TYPE.MOTION ? 'VIDEO_MEDIA' : 'CREATIVE_MEDIA'}
                header={<SectionHeader headerIcon={<ImageIcon />} headerText={mediaSectionTitle} />}
                sourcePath={CAMPAIGNS_ROUTE}
                onCancel={onCancel}
            >
                <UnifiedCreativeEditorBody
                    campaignId={campaignId}
                    accountId={accountId}
                    thumbnailStatus={thumbnailStatus}
                    videoStatus={videoStatus}
                    setThumbnailStatus={setThumbnailStatus}
                    setVideoStatus={setVideoStatus}
                    formLayoutProps={{
                        headerContainerClassName: styles['editor-header'],
                        hideFooter: true,
                    }}
                    isThumbnailLoading={isThumbnailLoading}
                    setIsThumbnailLoading={setIsThumbnailLoading}
                    applyFocusToMatches={applyFocusToMatches}
                    setApplyFocusToMatches={setApplyFocusToMatches}
                    matchingCreativeIds={matchingCreativeIds}
                />

                {isLogoFieldVisible && <LogoField />}
            </CommonCollapsibleCard>
        </WochitProvider>
    );
};

UnifiedCreativeEditorFormMediaSection.propTypes = {
    accountId: PropTypes.string,
    campaignId: PropTypes.string,
    creativeId: PropTypes.string,
    addIdToMatchingCreativeIds: PropTypes.bool,
    onCancel: PropTypes.func,
};
