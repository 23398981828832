"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgPlatform(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M17.994 17h-4.99a1 1 0 0 0 1.01 1h1.98c.357-.002.688.187.87.496a1.003 1.003 0 0 1-.86 1.504h-8a1 1 0 0 1-1-1c.003-.55.45-.997 1-1H9.97c.267.003.524-.1.714-.29a.995.995 0 0 0 .296-.71H6.007A2.008 2.008 0 0 1 4 15V7a2.002 2.002 0 0 1 2.008-2H17.99A2.006 2.006 0 0 1 20 7v8.01A2 2 0 0 1 17.994 17zm0-10H6.004l.007 8h11.994l-.01-8h-.001z"
            })
        )
    );
}
exports.default = SvgPlatform;