import { isEmpty } from 'lodash';
import URI from 'urijs';

const RECOMMENDATION_TYPE_PARAM_NAME = 'recommendation_type';
const RECOMMENDATION_ID_PARAM_NAME = 'recommendation_id';
const CAMPAIGN_ID_PARAM_NAME = 'campaignId';
const UDD_ID_LIST_PARAM_NAME = 'uddIdList';
const DESTINATION_ACCOUNT_PARAM_NAME = 'destination_account';

const addRecommendationsQueryParams = (basePath = '', queryParams = {}) => {
    if (isEmpty(queryParams)) {
        return basePath;
    }
    const transformedQueryParams = transformRecommendationsQueryParams(queryParams);

    const url = URI(basePath);
    url.addSearch(transformedQueryParams);

    return decodeURIComponent(url.toString());
};

const transformRecommendationsQueryParams = ({
    recommendationType,
    recommendationId,
    campaignId,
    uddIdsString,
    destinationAccount,
}) => {
    const transformedQueryParams = {};
    if (recommendationType) {
        transformedQueryParams[RECOMMENDATION_TYPE_PARAM_NAME] = recommendationType;
    }
    if (recommendationId) {
        transformedQueryParams[RECOMMENDATION_ID_PARAM_NAME] = recommendationId;
    }
    if (campaignId) {
        transformedQueryParams[CAMPAIGN_ID_PARAM_NAME] = campaignId;
    }
    if (uddIdsString) {
        transformedQueryParams[UDD_ID_LIST_PARAM_NAME] = uddIdsString;
    }
    if (destinationAccount) {
        transformedQueryParams[DESTINATION_ACCOUNT_PARAM_NAME] = destinationAccount;
    }

    return transformedQueryParams;
};

export default addRecommendationsQueryParams;
