import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { FormField } from 'taboola-ultimate-ui';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { useModal } from '../../../taboola-common-frontend-modules/modals';
import { isBankTransfer } from '../../config/PaymentType';
import { AddFundsModal } from '../AddFundsModal/AddFundsModal';
import { StripeBankTransferDetailsModal } from '../StripeBankTransferDetailsModal/StripeBankTransferDetailsModal';
import styles from './balanceAndPayments.module.scss';

export const useAddFundsModal = () => {
    const queryClient = useQueryClient();
    const { accountId } = useAccount();
    const { open: openModal } = useModal();

    const refetchPaymentsPreview = useCallback(() => {
        queryClient.invalidateQueries(['getPaymentsReport', accountId]);
    }, [queryClient, accountId]);

    const handleOnClickAddFunds = async () => {
        const addFundsSuccess = await openModal({
            contentRenderer: AddFundsModal,
            className: styles['add-funds-modal'],
        });

        if (addFundsSuccess) {
            if (isBankTransfer(addFundsSuccess.paymentContext)) {
                await openModal({
                    title: (
                        <FormField
                            label={
                                <FormattedMessage
                                    id="billingAndPayments.modal.bankTransferDetails.title"
                                    defaultMessage="Bank Transfer Details"
                                />
                            }
                        />
                    ),
                    contentRenderer: () => <StripeBankTransferDetailsModal pageUrl={addFundsSuccess.data.url} />,
                    className: styles['stripe-bank-transfer-details-modal'],
                    hasCloseButton: true,
                });
            }

            refetchPaymentsPreview();
        }
    };

    return { handleOnClickAddFunds };
};
