'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var emptyArray = [];

var useKeyHandler = function useKeyHandler(callback) {
    var keyList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : emptyArray;

    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        preventDefault = _ref.preventDefault,
        stopPropagation = _ref.stopPropagation,
        _ref$target = _ref.target,
        target = _ref$target === undefined ? window : _ref$target;

    // eslint-disable-next-line consistent-return
    (0, _react.useEffect)(function () {
        if (callback) {
            var cb = function cb(e) {
                if (e && e.keyCode && keyList.includes(e.keyCode)) {
                    callback(e);

                    if (stopPropagation) {
                        e.stopPropagation();
                    }

                    if (preventDefault) {
                        e.preventDefault();
                    }
                }
            };

            target.addEventListener('keydown', cb);
            return function () {
                return target.removeEventListener('keydown', cb);
            };
        }
    }, [callback, keyList, preventDefault, stopPropagation, target]);
};

exports.default = useKeyHandler;