import React from 'react';
import { Button } from 'tuui';
import { ExternalLink } from 'components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { GetHelpButtonTooltip } from './GetHelpButtonTooltip';
import styles from './scheduleNowButton.module.scss';

export const ScheduleNowButton = ({ scheduleNowURL }) => (
    <ExternalLink className={styles['schedule-button']} href={scheduleNowURL}>
        <Button variant="ghost">
            <FormattedMessage id="tracking.get.help.from.expert.button" defaultMessage="Talk To A Tracking Expert" />
            <GetHelpButtonTooltip />
        </Button>
    </ExternalLink>
);
