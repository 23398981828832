export const PUBLISHER_BLOCKING_LEVEL = {
    CAMPAIGN: 'CAMPAIGN',
    ADVERTISER: 'ADVERTISER',
    ADVERTISER_NETWORK: 'ADVERTISER_NETWORK',
    AUTO: 'AUTO',
    EMULATION: 'EMULATION',
    NETWORK: 'NETWORK',
    CUSTOM_RULE: 'CUSTOM_RULE',
};

export const EXPLORATION_STATUS = {
    IN_PROGRESS: 'IN_PROGRESS',
    STOPPED: 'STOPPED',
};

export const PUBLISHER_BLOCKING_LEVEL_SUBTEXT = {
    ADVERTISER: 'ADVERTISER',
    ADVERTISER_NETWORK: 'ADVERTISER_NETWORK',
    AUTO: 'AUTO',
    EMULATION: 'EMULATION',
    CUSTOM_RULE: 'CUSTOM_RULE',
};

export const SAFETY_NETS_STATUS = {
    CAPPING_ENABLED: 'CAPPING_ENABLED',
    CAPPING_DISABLED: 'CAPPING_DISABLED',
    SPEND_GUARDS_DISABLED: 'SPEND_GUARDS_DISABLED',
};

export const SITE_EDITOR_TYPE = {
    SAFETY_NETS_STATUS: 'SAFETY_NETS_STATUS',
    EXPLORATION_STATUS: 'EXPLORATION_STATUS',
};

export const SPEND_GUARDS_ACTION_TYPE = {
    BLOCK: 'BLOCK',
    CAP: 'CAP',
};
