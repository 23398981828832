import { isEmptyAccountId } from 'modules/taboola-common-frontend-modules/storage/DBStorage/DBStorageUtils';
import { transformUserSettingsFromGW } from 'modules/taboola-common-frontend-modules/storage/DBStorage/userSettingsTransformers';

const API_ENDPOINT = '/user_settings';
const userSettingsPrefix = accountName => `${API_ENDPOINT}/${accountName}`;
export const USER_SETTINGS_QUERY_KEY = 'userSettings';
const CACHE_TIMEOUT = 24 * 60 * 60 * 1000;

export const userSettingsApiFactory = ({ queryClient, callPostApi, callGetApi, callPatchApi, callDeleteApi }) => ({
    getSettings: async ({ username, resolveCache, accountId }) =>
        queryClient.fetchQuery(
            [USER_SETTINGS_QUERY_KEY, username, accountId],
            async () => {
                let data;

                if (isEmptyAccountId(accountId)) {
                    return {};
                }

                try {
                    const rowData = await callGetApi(userSettingsPrefix(accountId));
                    data = transformUserSettingsFromGW(rowData);
                } catch (error) {
                    data = {};
                }

                const cache = await resolveCache(data);

                return cache;
            },
            { staleTime: CACHE_TIMEOUT }
        ),
    postSettings: (accountId, data) => callPostApi(userSettingsPrefix(accountId), data),
    patchSettings: (accountId, data) => callPatchApi(userSettingsPrefix(accountId), data),
    deleteViewSetting: ({ version, reportName, presetName, accountId, ...rest }) =>
        callDeleteApi(`${userSettingsPrefix(accountId)}/${version}/${reportName}/${presetName}`, rest),
});

export { API_ENDPOINT as USER_SETTINGS_ENDPOINT };
