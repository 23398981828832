import { useMemo } from 'react';
import moment from 'moment-timezone';
import {
    COMMON_FLAGS,
    useCommonConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import {
    PHASES,
    PROGRESS_COLOR,
    PHASE_TO_CELL_PROGRESS_MESSAGE,
    calculatePendingReviewProgress,
    calculateConversionsProgress,
    isLearningWithWarning,
    calculateRemainingTimeInMs,
} from '../../../services/campaignStatusPhaseService';

export const useCvrLearningStatusState = ({ phase, phaseUpdateTime, totalConversions }) => {
    const {
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS]: maxTimeInReviewInHours,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION]:
            numberOfConversionsInTimeframeForCompletion,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING]: minLearningDaysBeforeWarning,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING]:
            minLearningProgressPercentageBeforeWarning,
        [COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_TO_SHOW_PROGRESS_AFTER_COMPLETION_IN_HOURS]:
            maxTimeToShowProgressAfterCompletionInHours,
    } = useCommonConfig([
        COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_IN_REVIEW_IN_HOURS,
        COMMON_FLAGS.CVR_LEARNING_STATUS_NUMBER_OF_CONVERSIONS_IN_TIMEFRAME_FOR_COMPLETION,
        COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_DAYS_BEFORE_WARNING,
        COMMON_FLAGS.CVR_LEARNING_STATUS_MIN_LEARNING_PROGRESS_PERCENTAGE_BEFORE_WARNING,
        COMMON_FLAGS.CVR_LEARNING_STATUS_MAX_TIME_TO_SHOW_PROGRESS_AFTER_COMPLETION_IN_HOURS,
    ]);

    const { progress, messageValue } = useMemo(() => {
        if (phase === PHASES.CVR_LEARNING_COMPLETE) {
            return {};
        }

        if (phase === PHASES.PENDING_REVIEW) {
            const { remainingTimeInMs, progress } = calculatePendingReviewProgress({
                maxTimeInReviewInHours,
                phaseUpdateTime,
            });
            return {
                progress,
                messageValue: {
                    value: Math.round(moment.duration(remainingTimeInMs).asHours()),
                },
            };
        }

        const { currentConversions, progress } = calculateConversionsProgress({
            totalConversions,
            numberOfConversionsInTimeframeForCompletion,
        });
        const messageValue = {
            current: currentConversions,
            total: numberOfConversionsInTimeframeForCompletion,
        };

        return {
            progress: phase === PHASES.CVR_LEARNING_LIMITED ? null : progress,
            messageValue,
        };
    }, [maxTimeInReviewInHours, numberOfConversionsInTimeframeForCompletion, phase, phaseUpdateTime, totalConversions]);

    const progressBarColor = useMemo(() => {
        if (phase !== PHASES.CVR_LEARNING) {
            return PROGRESS_COLOR.PRIMARY;
        }

        return isLearningWithWarning({
            progress,
            phaseUpdateTime,
            minLearningDaysBeforeWarning,
            minLearningProgressPercentageBeforeWarning,
        })
            ? PROGRESS_COLOR.WARNING
            : PROGRESS_COLOR.PRIMARY;
    }, [minLearningDaysBeforeWarning, minLearningProgressPercentageBeforeWarning, phase, phaseUpdateTime, progress]);

    const isDoneWithLearning = useMemo(() => {
        if (phase !== PHASES.CVR_LEARNING_COMPLETE) {
            return false;
        }

        return calculateRemainingTimeInMs(phaseUpdateTime, maxTimeToShowProgressAfterCompletionInHours) < 0;
    }, [maxTimeToShowProgressAfterCompletionInHours, phase, phaseUpdateTime]);

    return {
        messageId: PHASE_TO_CELL_PROGRESS_MESSAGE[phase],
        messageValue,
        progress,
        progressBarColor,
        shouldShowErrorIcon: phase === PHASES.CVR_LEARNING_LIMITED,
        isDoneWithLearning,
    };
};
