import { creativesApi } from '../../../../../services/api';
import { fetchCreativeSuccess, fetchCreativeError } from '../actions';

const fetchCreativeBase = fetchCreativeCall => (accountName, campaignId, creativeId) => dispatch => {
    fetchCreativeCall(accountName, campaignId, creativeId)
        .then(fetchedCreative => dispatch(fetchCreativeSuccess(fetchedCreative)))
        .catch(error => dispatch(fetchCreativeError(error)));
};

const fetchCreative = fetchCreativeBase(creativesApi.getCreative);

export { fetchCreative };
