import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const UsingGoogleTagManagerCheckBoxToolTip = () => (
    <TooltipSection>
        <FormattedMessage
            id="tracking.setup.google.tag.manager.checkbox.tooltip"
            defaultMessage="Mark this checkbox if you're using google tag manager. The code for google tag manager is slightly different."
        />
    </TooltipSection>
);
