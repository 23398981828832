import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import {
    DAY,
    sortArrayWithoutMutation,
    sortTerms,
    topicToNameAndLabel,
} from '../SegmentTopics/customContextualTopicsUtils';
import { useCountriesField } from '../SegmentTopics/hooks/useCountriesField';

const EMPTY_ARRAY = [];
const useCustomContextualRelatedTopics = () => {
    const { value: languages } = useFormFieldValue({ field: 'languages' });
    const { value: platforms } = useFormFieldValue({ field: 'platforms' });
    const { value: countries } = useCountriesField();
    const { value: terms } = useFormFieldValue({ field: 'terms' });
    const { [COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER]: maxAllowedTopics } = useCommonConfig([
        COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER,
    ]);
    const sortedTerms = useMemo(() => sortTerms(terms), [terms]);
    const queryKey = useMemo(
        () => [sortArrayWithoutMutation(platforms), sortedTerms, countries, languages],
        [countries, languages, platforms, sortedTerms]
    );
    const params = useMemo(
        () => ({
            languages,
            platforms,
            countries,
            terms,
        }),
        [countries, languages, platforms, terms]
    );
    const { getCustomContextualRelatedTopics } = useAudiencesApi();
    const isTermsLengthValid = terms?.include?.[0]?.length <= maxAllowedTopics;
    const { isLoading, data = EMPTY_ARRAY } = useQuery(
        queryKey,
        () => {
            if (!sortedTerms.length) {
                return { results: [] };
            }
            return getCustomContextualRelatedTopics(params);
        },
        {
            select: ({ results }) => results.map(topicToNameAndLabel),
            refetchOnWindowFocus: false,
            staleTime: DAY,
            enabled: isTermsLengthValid,
        }
    );

    return { isRelatedTopicsLoading: isLoading, relatedTopics: data };
};

export default useCustomContextualRelatedTopics;
