import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { setRecommendations } from '../actions';
import RECOMMENDATION_TYPE from '../recommendationTypes';
import getRecommendationUrl from '../utils/getRecommendationUrl';

const onDemandHandler =
    ({ history, recommendationMetadata }) =>
    (dispatch, getState) => {
        const { accountId } = selectedAccountSelector(getState());
        const { campaignId, recommendationId, onDemandRecommendationAction } = recommendationMetadata;
        const destinationPath = getRecommendationUrl(onDemandRecommendationAction, accountId, campaignId);

        dispatch(
            setRecommendations({
                recommendationType: RECOMMENDATION_TYPE.ON_DEMAND,
                recommendationId,
            })
        );
        dispatch(navigate(history, destinationPath));
    };

export default onDemandHandler;
