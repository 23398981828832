"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgPlay(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M9.00102 8.05615C9.00099 7.22152 9.96307 6.75414 10.6192 7.27006L15.0793 10.7773C15.5621 11.157 15.5917 11.8781 15.1417 12.2961L10.6818 16.4388C10.042 17.0331 9.00124 16.5794 9.00122 15.7062L9.00102 8.05615Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgPlay;