import React from 'react';
import { ContentIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { BrandNameFormField } from './Components/BrandName/BrandNameFormField';
import { DescriptionFormField } from './Components/Description/DescriptionFormField';
import { TitleFormField } from './Components/Title/TitleFormField';

export const VideoCreativeContentSection = () => {
    return (
        <CommonCollapsibleCard
            id="contentSection"
            header={
                <SectionHeader
                    headerIcon={<ContentIcon />}
                    headerText={<FormattedMessage id="creative.creator.content.label" defaultMessage="Content" />}
                />
            }
        >
            <BrandNameFormField />
            <TitleFormField />
            <DescriptionFormField />
        </CommonCollapsibleCard>
    );
};
