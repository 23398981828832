import { has, isNil } from 'lodash';
import { ICON_TYPES } from 'modules/campaigns/config/iconTypes';
import { PROGRAMMATIC_DEMAND_TYPES } from 'modules/campaigns/constants/demandType';
import { CREATIVE_STATUS, PERFORMANCE_STATUS } from '../../../../config';
import { isPerformanceRulesActiveOnAccountLevel } from './commonPredicates';

const RSS_EDIT_DISABLED_COLUMNS = new Set(['creative_thumbnailUrl', 'creative_description']);

export const isVideoEditorCreative = data => has(data, 'videoUrl');

export const hasCreativePreviewData = data => data.title && data.thumbnailUrl;

export const isCreativeStopped = data => data.status === CREATIVE_STATUS.STOPPED;

export const isCreativeRejected = data => data.status === CREATIVE_STATUS.REJECTED;

export const isCreativePausedByCustomRule = data =>
    data.status === CREATIVE_STATUS.PAUSED && data.statusReason === 'CUSTOM_RULE';

export const isMotionAd = data => data.performanceStatus === PERFORMANCE_STATUS.MOTION;

export const isAppInstall = data => data.iconType === ICON_TYPES.APP_INSTALL;

export const isRssChild = data => Boolean(data?.rssParentId);

export const isRssFeed = data => Boolean(data?.feedUrl);

export const isCreativeOfProgrammaticCampaign = data => PROGRAMMATIC_DEMAND_TYPES.has(data.demandType);

export const isPerformanceVideoCreative = data => !isNil(data.performanceVideoData);

const isCreativeFromExternalSource = data => isRssChild(data) || isCreativeOfProgrammaticCampaign(data);

export const isCreativeResubmittable = data => data.isResubmittable === true;

export const hasResubmittableData = data => !isNil(data.isResubmittable);

export const isEditCreativeColumnAllowed = (data, colId) => {
    if (isRssChild(data)) {
        return !RSS_EDIT_DISABLED_COLUMNS.has(colId);
    }

    return isEditCreativeAllowed(data);
};

export const isEditCreativeAllowed = data =>
    !isCreativeStopped(data) &&
    !isCreativeFromExternalSource(data) &&
    (!hasResubmittableData(data) || isCreativeResubmittable(data));

export const isDuplicateCreativeAllowed = data => !isCreativeFromExternalSource(data) && !isCreativeStopped(data);

export const isDeleteCreativeAllowed = data => !isCreativeStopped(data) && !isCreativeFromExternalSource(data);

export const isPreviewCreativeAllowed = data =>
    hasCreativePreviewData(data) && !isCreativeFromExternalSource(data) && !isCreativeStopped(data);

export const isBrandsVideoCreative = data => data.performanceStatus === PERFORMANCE_STATUS.VIDEO;

export const isVideoCreativeEditable = ({ context }) => {
    const { userPermissions } = context;
    return userPermissions['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'];
};

export const isHTMLCardCreative = data => data.performanceStatus === PERFORMANCE_STATUS.HTML_CARD;

export const isCreativeStatusEditable = ({ data = {}, context }) => {
    const { selectedAccountConfig } = context;
    return isPerformanceRulesActiveOnAccountLevel(selectedAccountConfig) && !!data.performanceRuleId;
};
