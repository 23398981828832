import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { Spinner } from 'tuui';
import { DropdownMenu, Input, InputTypes } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useAccountAdditionalData, usePageName } from '../../../../hooks';
import CurrencyIcon from '../../../campaigns/modules/common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import { setFatalError } from '../../../errors';
import { COMMON_FLAGS, useCommonConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS, gtmTracker } from '../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from '../../../taboola-common-frontend-modules/i18n';
import { ModalFormLayout } from '../../../taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { ADD_FUNDS_CONTEXT } from '../../config/AddFundsContext';
import { PAYMENT_TYPE } from '../../config/PaymentType';
import { getPaymentMethodInstructionsComponent } from '../../config/PaymentTypeMetadata';
import { useAddFunds } from '../../hooks/useAddFunds';
import { useAddPaymentMethods } from '../../hooks/useAddPaymentMethods';
import { useAllowedPrepaymentMethods } from '../../hooks/useAllowedPrepaymentMethods';
import { useBillingActions } from '../../hooks/useBillingData';
import { AddFundsAddPaymentMethod } from '../AddPaymentMethod/AddFunds/AddFundsAddPaymentMethod';
import { DisabledPaymentMethodsDropdown } from './DisabledPaymentMethodsDropdown';
import { usePaymentMethods } from './PaymentMethods/usePaymentMethods';
import styles from './addFundsModal.module.scss';

const classNameBuilder = classnames.bind(styles);

const sendGTMEvent = (eventName, pageName, component, value, columnName, additionalEventInfo) => {
    gtmTracker.trackEvent(eventName, {
        pageName,
        component,
        value,
        columnName,
        additionalEventInfo,
    });
};

const id = 'payment-methods-dropdown';

const NO_FUNDS = '';

export const AddFundsModal = ({ onClose }) => {
    const pageName = usePageName();
    const { financialRepEmail, isError: isErrorAdditionalMethods } = useAllowedPrepaymentMethods();
    const [funds, setFunds] = useState(NO_FUNDS);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const { isLoadingAddFunds } = useBillingActions();
    const { currency } = useAccount();
    const { accountAdditionalData: { legalEntity } = {} } = useAccountAdditionalData();
    const { [COMMON_FLAGS.LEGAL_ENTITIES_REQUIRE_MANDATE_CONFIG]: legalEntitiesRequireMandateConfig } = useCommonConfig(
        [COMMON_FLAGS.LEGAL_ENTITIES_REQUIRE_MANDATE_CONFIG]
    );
    const isAddMandateMessage = legalEntitiesRequireMandateConfig?.indexOf(legalEntity) !== -1;
    const { dropdownOptions, selectedPaymentMethod, setSelectedPaymentMethod } = usePaymentMethods({
        financialRepEmail,
    });
    const { paymentMethodTypesToAdd } = useAddPaymentMethods();

    const { addFunds } = useAddFunds({ funds, paymentType: selectedPaymentMethod?.type });

    const isDisabled = isEmpty(dropdownOptions);
    const onAddFundsSubmit = useCallback(async () => {
        setIsSubmitting(true);
        const paymentType = selectedPaymentMethod.type;
        sendGTMEvent(GTM_EVENTS.USABILITY, pageName, 'Add Funds', paymentType, funds, currency);

        const addFundsResponse = await addFunds(ADD_FUNDS_CONTEXT.ADD_FUNDS_VIA_BANK_TRANSFER);
        onClose(addFundsResponse);
    }, [onClose, addFunds, funds, selectedPaymentMethod, pageName, currency]);

    const onAddPaymentMethodClick = () => {
        onClose();
    };

    useEffect(() => {
        if (isErrorAdditionalMethods) {
            onClose();
            dispatch(setFatalError(new Error('getAdditionalMethods error')));
        }
    }, [dispatch, isErrorAdditionalMethods, onClose]);

    const InstructionComponent = getPaymentMethodInstructionsComponent(selectedPaymentMethod?.type);

    return (
        <ModalFormLayout
            onCancel={onClose}
            onSubmit={onAddFundsSubmit}
            disableSubmit={isDisabled || isSubmitting}
            disableCancel={isSubmitting}
            submitButtonText={
                isLoadingAddFunds || isSubmitting ? (
                    <div className={styles['submit-progress']}>
                        <Spinner size={14} />
                    </div>
                ) : (
                    <FormattedMessage
                        id={`billingAndPayments.modal.addFunds.${
                            selectedPaymentMethod?.type ?? PAYMENT_TYPE.CARD
                        }.button`}
                    />
                )
            }
            bodyContainerClassName={styles['modal-body-container']}
        >
            <div className={styles['modal-header']}>
                <FormattedMessage id="billingAndPayments.modal.header" defaultMessage="Add Funds" />
            </div>
            <div className={styles['sub-header']}>
                <FormattedMessage
                    id="billingAndPayments.modal.selectPaymentMethod"
                    defaultMessage="Select the payment method and type the amount you would like to add to your balance."
                />
            </div>
            <div className={styles['modal-content']}>
                {isDisabled ? (
                    <DisabledPaymentMethodsDropdown />
                ) : (
                    <DropdownMenu
                        id={id}
                        onChange={setSelectedPaymentMethod}
                        selectedValueObject={selectedPaymentMethod}
                        options={dropdownOptions}
                        className={styles['drop-down-menu']}
                    />
                )}
                <div className={styles['add-payment-methods']}>
                    {paymentMethodTypesToAdd.map(paymentMethodTypeToAdd => (
                        <AddFundsAddPaymentMethod
                            key={paymentMethodTypeToAdd}
                            paymentMethodType={paymentMethodTypeToAdd}
                            onClick={onAddPaymentMethodClick}
                        />
                    ))}
                </div>
                <FormattedNumber value={0}>
                    {placeholder => (
                        <Input
                            type={InputTypes.NUMBER}
                            icon={<CurrencyIcon currency={currency} />}
                            disabled={isDisabled}
                            inputClass={classNameBuilder('currency-input', { disabled: isDisabled })}
                            onChange={e => setFunds(e.target.value)}
                            placeholder={placeholder}
                            value={funds}
                            size={Input.SIZE.LARGE}
                        />
                    )}
                </FormattedNumber>
            </div>
            {isAddMandateMessage && (
                <ul className={styles['mandate-disclaimer']}>
                    <li>
                        <FormattedMessage
                            id="billingAndPayments.modal.mandateDisclaimer"
                            defaultMessage="Please allow up to 2 business days for the payment to be reflected in your account balance."
                        />
                    </li>
                </ul>
            )}
            {InstructionComponent && <InstructionComponent financialRepEmail={financialRepEmail} />}
        </ModalFormLayout>
    );
};
