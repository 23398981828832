import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { nameToLabel } from '../../../../../campaigns/services/utils/dropdownUtils';
import { useFormDataContext } from '../../../../../taboola-common-frontend-modules/formData';

const FALLBACK_COUNTRY_IN_CASE_NO_COUNTRY_OR_INVALID = { label: 'United States', value: 'US' };

export const useCustomContextualCountriesApi = () => {
    const { getCustomContextualCountriesList } = useAudiencesApi();
    const { data } = useQuery(['getCustomContextualCountriesList'], getCustomContextualCountriesList, {
        refetchOnWindowFocus: false,
        select: data => data?.results,
        staleTime: Infinity,
    });

    const options = useMemo(() => data?.map(nameToLabel), [data]);

    const [displayedOption] = useState(null);

    const {
        formAccount: { country: accountCountry },
    } = useFormDataContext();

    const defaultCountry = useMemo(
        () => options?.find(l => l.value === accountCountry) ?? FALLBACK_COUNTRY_IN_CASE_NO_COUNTRY_OR_INVALID,
        [accountCountry, options]
    );

    const [returnOptions, setReturnOptions] = useState([]);

    useEffect(() => {
        if (options && defaultCountry) {
            const optionsWithoutDisplayOption = options?.filter(l => l?.value !== defaultCountry?.value);
            const orderedOptions = [defaultCountry, ...optionsWithoutDisplayOption];
            setReturnOptions(orderedOptions);
        }
    }, [defaultCountry, displayedOption, options]);

    return {
        options: returnOptions,
        displayDefaultCountry: defaultCountry?.value,
        isLoadingOptions: !options,
    };
};
