import React from 'react';
import styled from 'styled-components';
import { ITheme, StatusType } from 'tuui/lib/theme/base';
import { zIndex } from 'tuui/lib/theme/primitives';
import { getScaledSize, scale } from 'tuui/lib/utils/styles';

export type Position = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export interface BadgeProps {
    badgeContent: React.ReactNode,
    children?: React.ReactNode,
    type?: StatusType,
    position?: Position,
    contentClassName?: string,
    hideBadge?: boolean,
};

const POSITION = {
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left',
};

const TYPE = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
};

const getPosition = ({ theme, $position }: { theme: ITheme, $position: Position }) => POSITION_STYLES(theme)[$position];

const POSITION_STYLES = (theme: ITheme) => ({
    'top-right': `
        top: 0;
        right: ${getScaledSize(theme, 'xs')};
        transform: translate(100%, -50%);
    `,
    'top-left': `
        top: 0;
        left: ${getScaledSize(theme, 'xs')};
        transform: translate(-100%, -50%);
    `,
    'bottom-right': `
        bottom: 0;
        right: ${getScaledSize(theme, 'xs')};
        transform: translate(100%, 50%);
    `,
    'bottom-left': `
        bottom: 0;
        left: ${getScaledSize(theme, 'xs')};
        transform: translate(-100%, 50%);
    `,
});

export const Badge = ({
    badgeContent,
    children,
    type = 'info',
    position = 'top-right',
    contentClassName,
    hideBadge,
}: BadgeProps) => (
    <BadgeContainer>
        {children}
        {!hideBadge && <BadgeBase className={contentClassName} $type={type} $position={position}>{badgeContent}</BadgeBase>}
    </BadgeContainer>
);

const BadgeContainer = styled.div`
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
    align-items: center;
`;

const BadgeBase = styled.span<{ $type: StatusType; $position: Position; }>`
    position: absolute;

    height: ${scale('lg')};
    min-width: ${scale('lg')};
    padding: 0 ${scale('xs')};
    border-radius: ${scale('md')};

    font-size: ${({ theme }) => theme.typography.sizes.md};
    font-weight: ${({ theme }) => theme.typography.weight.bold};

    z-index: ${zIndex.firstLayer};

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    box-sizing: border-box;

    &:empty {
        height: ${scale('sm')};
        min-width: ${scale('sm')};
    }

    ${getPosition}

    background-color: ${({ theme, $type }) => theme.palette[$type].fill};
    color: ${({ theme, $type }) => theme.palette[$type].text};
`;

Badge.POSITION = POSITION;
Badge.TYPE = TYPE;