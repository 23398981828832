import React from 'react';
import styles from './motionAdsPreviewImages.module.scss';

export const MotionAdsPreviewImages = ({ imageSetNumber }) => {
    return (
        <div className={styles['wrapper']}>
            <img
                src={`${process.env.PUBLIC_URL}/previewsDrawerAssets/motionAds-static-${imageSetNumber}.jpeg`}
                alt="motionAds-preview-card"
            />
        </div>
    );
};
