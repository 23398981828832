import React, { useState } from 'react';
import { FormLayout } from 'modules/campaigns/components';
import { VIDEO_PREVIEW_MODE } from 'modules/campaigns/modules/common-campaign-form/config/VideoPreviewMode';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { VideoPreviewHeader, PreviewBody, VideoPreviewToolbar } from './Components';
import { useVastForPreview } from './hooks/useVastForPreview';
import styles from './videoCreativeCreatorPreview.module.scss';

export const VideoCreativeCreatorPreview = () => {
    const { value: videoUploadItems } = useFormFieldValue({
        field: 'videoUploadItems',
    });
    const isDesktopPreviewAvailable = videoUploadItems?.some(asset => asset.type === VIDEO_PREVIEW_MODE.DESKTOP);

    // Mobile is always available as a preview mode, desktop might not be if original video is in a low resolution
    const [selectedMode, setSelectedMode] = useState(VIDEO_PREVIEW_MODE.MOBILE);
    const vast = useVastForPreview(selectedMode);

    return (
        <FormLayout
            contentContainerClassName={styles['preview-container']}
            headerContainerClassName={styles['preview-header']}
            header={<VideoPreviewHeader />}
            hideFooter
            bodyContainerClassName={styles['form-body']}
        >
            <VideoPreviewToolbar
                isDisabled={!vast}
                onSelect={setSelectedMode}
                selected={selectedMode}
                isDesktopAvailable={isDesktopPreviewAvailable}
            />
            <PreviewBody previewMode={selectedMode} vast={vast} />
        </FormLayout>
    );
};
