import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AsyncDropDownPairSmartList } from 'taboola-ultimate-ui';
import { AsyncDropdownLoader } from '../../../../../../../../components/Dropdown';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { INCLUDE_EXCLUDE_OPTIONS } from '../../../../config/TargetingTypes';
import { TargetingTypeDropdownOption } from '../../../TargetingTypeDropdown';
import CityTargetingListItem from './CityTargetingListItem';
import CityTargetingOptionLabel from './CityTargetingOptionLabel';

const Placeholder = () => (
    <FormattedMessage id="campaign.editor.targeting.cities.select.placeholder" defaultMessage="Select Cities..." />
);

const ListHeaderTitle = () => (
    <FormattedMessage id="campaign.editor.geo.targeting.cities" defaultMessage="Selected Cities" />
);

const optionMsgIdPrefix = 'campaign.editor.targeting.city.option';

const convertOptionToDropdownOption = (option = {}) => {
    const { id } = option;

    return {
        value: id,
        ...option,
    };
};

const convertSelectedValueToListItem = (selectedVal, country) => {
    const { id, city, region } = selectedVal;

    return {
        value: `${country}.${id}.${city}`,
        label: city,
        detailValue: `${country}.${id}.${region}`,
        detailLabel: region,
        ...selectedVal,
    };
};

const CityTargeting = ({
    type,
    values,
    country,
    fetchCitiesFlow,
    addCity,
    removeCity,
    setTargetingType,
    clearItems,
}) => {
    const [hasMoreOptions, setHasMoreOptions] = useState(true);
    const [fetchError, setFetchError] = useState(false);

    const [selectedIds, formattedValues] = useMemo(() => {
        const ids = values.map(({ id } = {}) => id).filter(val => val);

        const formatted = values.map(val => convertSelectedValueToListItem(val, country));

        return [ids, formatted];
    }, [country, values]);

    const loadMoreOptions = useCallback(
        (searchText, page) =>
            fetchCitiesFlow(country, searchText, page)
                .then(values => {
                    const shouldLoadMore = values.length > 0;

                    setHasMoreOptions(shouldLoadMore);
                    setFetchError(false);

                    return {
                        options: values.map(convertOptionToDropdownOption),
                        hasMore: shouldLoadMore,
                    };
                })
                .catch(() => {
                    setHasMoreOptions(false);
                    setFetchError(true);

                    return {
                        options: [],
                        hasMore: false,
                    };
                }),
        [country, fetchCitiesFlow]
    );

    const onMenuClose = useCallback(() => setFetchError(false), [setFetchError]);

    return (
        <AsyncDropDownPairSmartList
            itemType={type}
            allItemTypes={INCLUDE_EXCLUDE_OPTIONS}
            itemTypeRenderer={TargetingTypeDropdownOption}
            onSelectItemType={setTargetingType}
            listHeaderTitle={<ListHeaderTitle />}
            itemPlaceholder={<Placeholder />}
            selectedItems={formattedValues}
            renderItem={<CityTargetingListItem targetingType={type} msgIdPrefix={optionMsgIdPrefix} />}
            onAddItem={addCity}
            clearItems={clearItems}
            onRemoveItem={removeCity}
            loadMoreOptions={loadMoreOptions}
            optionLabelRenderer={props => <CityTargetingOptionLabel {...props} msgIdPrefix={optionMsgIdPrefix} />}
            searchable
            loadingMessageRenderer={AsyncDropdownLoader}
            error={fetchError}
            onMenuClose={onMenuClose}
            hasMore={hasMoreOptions}
            blacklist={selectedIds}
        />
    );
};

CityTargeting.propTypes = {
    type: PropTypes.string,
    values: PropTypes.array,
    fetchCitiesFlow: PropTypes.func,
    addCity: PropTypes.func,
    removeCity: PropTypes.func,
    setTargetingType: PropTypes.func,
    clearItems: PropTypes.func,
};

CityTargeting.defaultProps = {
    values: [],
};

export default CityTargeting;
