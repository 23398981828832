import encodeurl from 'encodeurl';
import { flatten, values } from 'lodash';
import uuid from 'uuid/v1';
import { reduceAndSortObjectKeys } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { COMPONENT_STATUS } from 'services/constants';
import { isFileTypeVideo } from '../services/utils';
import { generateCreativeName } from '../services/utils/generateCreativeName';

export const creativeToContentFormObjectTransformer = creative => ({
    id: uuid(),
    title: creative.title,
    status: COMPONENT_STATUS.ACTIVE,
    descriptionEnabled: !!creative.description,
    description: creative.description,
    ctaEnabled: !!creative.cta,
    cta: creative.cta?.ctaType,
});

export const creativeToUrlFormObjectTransformer = creative => ({
    id: uuid(),
    value: creative.url,
    status: COMPONENT_STATUS.ACTIVE,
});

const getActivePreviews = creativePreviewList => {
    const previews = values(creativePreviewList);
    const activePreviewArrays = previews.map(urlWidgets => values(urlWidgets).filter(preview => !preview.deleted));

    return flatten(activePreviewArrays);
};

export const transformCreatePreviews = creativePreviews => {
    const creativePreviewList = reduceAndSortObjectKeys(creativePreviews);
    const activePreviews = getActivePreviews(creativePreviewList);
    const encodedActivePreviews = activePreviews.map(({ url, ...rest }) => ({
        url: encodeurl(url),
        ...rest,
    }));
    const activeVideoCreatives = encodedActivePreviews
        .filter(preview => isFileTypeVideo(preview.fileType))
        .map(
            (
                {
                    title,
                    thumbnailUrl,
                    cta,
                    url,
                    description,
                    fallbackImageUrl,
                    gifUrl,
                    mediaUploadSource,
                    recommendedFBImage,
                    motionAdsStudio,
                    appInstall,
                    rating,
                    logo,
                    creativeName,
                    customId,
                },
                index
            ) => ({
                title,
                thumbnailUrl: fallbackImageUrl,
                videoUrl: thumbnailUrl,
                description,
                cta,
                gifUrl,
                url,
                mediaUploadSource,
                recommendedFBImage,
                motionAdsStudio,
                appInstall,
                rating,
                logo,
                creativeName: generateCreativeName(activePreviews, creativeName, index),
                customId,
            })
        );

    const activeCreatives = activePreviews
        .filter(preview => !isFileTypeVideo(preview.fileType))
        .map(
            (
                {
                    title,
                    thumbnailUrl,
                    description,
                    cta,
                    url,
                    creativeFocus,
                    appInstall,
                    rating,
                    logo,
                    creativeCrop,
                    thirdPartyTags,
                    creativeName,
                    customId,
                },
                index
            ) => ({
                title,
                thumbnailUrl,
                description,
                cta,
                url,
                creativeFocus,
                appInstall,
                rating,
                logo,
                creativeCrop,
                thirdPartyTags,
                creativeName: generateCreativeName(activePreviews, creativeName, index),
                customId,
            })
        );

    return { activeVideoCreatives, activeCreatives, encodedActivePreviews };
};
