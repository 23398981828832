import React from 'react';
// eslint-disable-next-line no-restricted-syntax
import { useIntl } from 'react-intl';

export const FormattedAnchor = ({ children, id, values }) => {
    const intl = useIntl();

    return (
        <a
            href={intl.formatMessage(
                {
                    id: `${id}.href`,
                },
                values
            )}
            target={intl.formatMessage({ id: `${id}.target`, defaultMessage: '_blank' }, values)}
            rel={intl.formatMessage({ id: `${id}.rel`, defaultMessage: 'noopener noreferrer' }, values)}
        >
            {children}
        </a>
    );
};
