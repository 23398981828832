import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

const AppAdsTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="creative.creator.ad.setup.tooltip"
                defaultMessage="Please insert app url from Apple App Store or Google Play. From the app store url, your app name, logo and the average rating will be retrieved and displayed as appears in the app."
            />
        </TooltipSection>
    </>
);

export default AppAdsTooltip;
