import React from 'react';

export const DinersSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8412 20.159C18.2178 20.1802 22.2125 16.5572 22.2125 12.1497C22.2125 7.32991 18.2178 3.9984 13.8412 4H10.0746C5.64554 3.9984 2 7.33091 2 12.1497C2 16.5582 5.64554 20.1802 10.0746 20.159H13.8412V20.159Z"
                fill="url(#paint0_linear_2007_166341)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0902 4.67328C6.04305 4.67456 2.76353 7.98492 2.76257 12.0706C2.76353 16.1555 6.04302 19.4655 10.0902 19.4668C14.1383 19.4655 17.4185 16.1555 17.419 12.0706C17.4184 7.98492 14.1383 4.67456 10.0902 4.67328V4.67328ZM5.44538 12.0706V12.0706C5.4492 10.0741 6.68482 8.37164 8.4272 7.6951V16.445C6.68482 15.7689 5.44917 14.0673 5.44538 12.0706V12.0706ZM11.7525 16.447V16.447V7.69484C13.4955 8.36974 14.733 10.0732 14.7362 12.0706C14.733 14.0686 13.4955 15.7708 11.7525 16.447V16.447Z"
                fill="#F4F6F7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2007_166341"
                    x1="26.407"
                    y1="17.0666"
                    x2="21.2233"
                    y2="0.126363"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#009BE0" />
                    <stop offset="1" stopColor="#006BA8" />
                </linearGradient>
            </defs>
        </svg>
    );
};
