import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

const hasDuplicateConditions = condition => {
    const uniqueConditions = condition.map(c => c.predicate + c.url).filter(onlyUnique);
    return uniqueConditions.length === condition.length;
};

const hasConditionWithError = (condition, options) => !condition.some(({ url }) => url.length > options.length);

export const conversionSetupUrlConditionValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'tracking.conversion.setup.validations.error.url.conditions.empty',
        defaultMessage: 'Create at least 1 condition',
    },
    {
        validationFn: hasDuplicateConditions,
        messageId: 'tracking.conversion.setup.validations.error.url.condition.duplicated',
        defaultMessage: 'Some of your url conditions are duplicated',
    },
    {
        validationFn: hasConditionWithError,
        options: { length: 500 },
        messageId: 'tracking.conversion.setup.validations.error.url.condition.long',
        defaultMessage: 'Some of your url conditions exceed 500 characters',
    },
];
