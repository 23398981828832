import React from 'react';
import { FORM_MODES } from 'config/formModes';
import { useComponentStatus, useFormMode } from 'hooks';
import { useInitialFormAccountName } from '../../../hooks/queryParams/useInitialFormAccountName';
import { COMPONENT_STATUS } from '../../../services/constants';
import { isNetworkAccountType } from '../../account-management';
import { useMultiLayerPathParams } from '../../multi-layer-drawer/hooks/useMultiLayerPathParams';
import { useAccount } from '.././account-configurations/hooks/useAccount';
import { AccountProvider } from '../account-configurations/AccountProvider';
import { ValidationContextProvider } from '../validations/ValidationContext';
import { FormDataProvider } from './FormDataContext';
import useFormValidation from './hooks/useFormValidation';

const useAccountUrlParams = ({ isNetworkOwnerAllowed, path, pattern }) => {
    const account = useAccount();
    const { accountId: accountNameParam } = useMultiLayerPathParams(path, pattern);
    const [initialFormAccountName] = useInitialFormAccountName();

    const accountName = accountNameParam || initialFormAccountName;
    const networkAccountName =
        !accountName && isNetworkAccountType(account.type) && !isNetworkOwnerAllowed ? account.accountName : undefined;
    const accountId = accountName || networkAccountName ? undefined : account.id;

    return { accountId, accountName, networkAccountName };
};

export const withFormDataProvider =
    (Component, { defaultValuesConfig = {}, formContainerId, isNetworkOwnerAllowed, mode: propMode } = {}) =>
    props => {
        const urlMode = useFormMode();
        const { path, pattern } = props;
        //TODO: Should be removed and be fixed in ticket DEV-133374;

        const mode = propMode || urlMode;

        const { status: fetchStatus, setStatus: setFetchStatus } = useComponentStatus();
        const { status: submitStatus, setStatus: setSubmitStatus } = useComponentStatus();
        const submitInProgress = submitStatus === COMPONENT_STATUS.LOADING;
        const isSubmitDisabled =
            submitInProgress || mode === FORM_MODES.PREVIEW || fetchStatus !== COMPONENT_STATUS.ACTIVE;
        const { validationContext } = useFormValidation({ formContainerId });
        const accountUrlParams = useAccountUrlParams({ isNetworkOwnerAllowed, path, pattern });

        return (
            <AccountProvider {...accountUrlParams}>
                <FormDataProvider
                    defaultValuesConfig={defaultValuesConfig}
                    mode={mode}
                    fetchStatus={fetchStatus}
                    setFetchStatus={setFetchStatus}
                    submitStatus={submitStatus}
                    setSubmitStatus={setSubmitStatus}
                    submitInProgress={submitInProgress}
                    isSubmitDisabled={isSubmitDisabled}
                    path={path}
                    pattern={pattern}
                >
                    <ValidationContextProvider key={validationContext.version} value={validationContext}>
                        <Component {...props} />
                    </ValidationContextProvider>
                </FormDataProvider>
            </AccountProvider>
        );
    };
