import React from 'react';
import { Highlighter } from 'taboola-ultimate-ui';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import styles from './ReportSearchHighlight.module.scss';

export const ReportSearchHighlight = ({ text, isCaseSensitive }) => {
    const [searchTerm] = useSearchTerm();
    return (
        <Highlighter
            text={text}
            highlightedText={searchTerm}
            caseSensitive={isCaseSensitive}
            highlightedTextClassName={styles['highlighted-text']}
            className={styles['container']}
        />
    );
};
