import React from 'react';
import { isEmpty, map, orderBy } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validateValue } from '../services';

export const getValidationIndicationType = (validation, severityProperty = 'severity') =>
    !isEmpty(validation) ? validation[severityProperty] ?? INDICATION_TYPES.ERROR : INDICATION_TYPES.NONE;

export const isErrorValidation = (validation, severityProperty) =>
    getValidationIndicationType(validation, severityProperty) === INDICATION_TYPES.ERROR;

export const isWarningValidation = (validation, severityProperty) =>
    getValidationIndicationType(validation, severityProperty) === INDICATION_TYPES.WARNING;

export const getHighestSeverityValidation = (validations, severityProperty) => {
    const sortedValidation = orderBy(validations, v => {
        if (isErrorValidation(v, severityProperty)) {
            return 0;
        }
        if (isWarningValidation(v, severityProperty)) {
            return 1;
        }
        return 2;
    });

    const [highest] = sortedValidation;
    if (isEmpty(highest)) {
        return null;
    }
    return highest;
};

export const getHighestSeverityValidationResult = validationResults => {
    const highestSeverityValidation = getHighestSeverityValidation(map(validationResults, 'validation'));

    return (
        highestSeverityValidation &&
        validationResults.find(({ validation }) => validation === highestSeverityValidation)
    );
};

export const getHighestSeverityValidationData = validations => {
    return getHighestSeverityValidation(validations, 'indicationType');
};

export const getFailedValidation = async (validations, context) => {
    const [nonPassingValidationsResults] = await validateValue(validations, context);
    const nonPassingValidations = map(nonPassingValidationsResults, 'validation');
    const highestSeverityFailedValidation = getHighestSeverityValidation(nonPassingValidations);

    return highestSeverityFailedValidation;
};

export const partitionValidationResults = async (validations, context) => {
    const [nonPassingValidationsResults, passingValidationResults] = await validateValue(validations, context);

    const errors = nonPassingValidationsResults.filter(({ validation }) => isErrorValidation(validation));
    const warnings = nonPassingValidationsResults.filter(({ validation }) => isWarningValidation(validation));

    const highestSeverityFailedValidationResult = getHighestSeverityValidationResult(nonPassingValidationsResults);
    return {
        // failure is { validation: validation object, error: thrown error of the validationFn }
        failure: highestSeverityFailedValidationResult,
        partitions: { errors, warnings, passed: passingValidationResults },
    };
};

export const getValidationMessageParams = (validation, validationError, { data, ...rest } = {}) => {
    if (!validation) {
        return null;
    }

    if (validationError instanceof CustomValidationError && validationError.messageCode) {
        const { messageCode, message, templateParameters, options = {} } = validationError;

        return {
            id: messageCode,
            defaultMessage: message,
            values: { ...options, ...data, ...rest, ...templateParameters },
        };
    }

    const { defaultMessage, messageValues, options = {} } = validation;
    let { messageId } = validation;

    if (typeof messageId === 'function' && data) {
        messageId = messageId(data);
    }

    return {
        id: messageId,
        defaultMessage,
        values: { ...options, ...data, ...rest, ...(messageValues ?? {}) },
    };
};

export const getValidationMessage = (validation, validationError, { data, ...rest } = {}) => {
    const params = getValidationMessageParams(validation, validationError, { data, ...rest });
    if (params) {
        return <FormattedMessage {...params} />;
    }
    return null;
};
