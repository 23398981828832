import React from 'react';
import { noop } from 'lodash';
import { LoadingBar } from 'modules/campaigns/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { TitleGenerationInputField } from './TitleGenerationInputField';
import { TitleGenerationLanguageDropdown } from './TitleGenerationLanguageDropdown';
import styles from './titlesGenerationDetailsFormBase.module.scss';

export const TitlesGenerationDetailsFormBase = ({
    isLoadingLp,
    productDescription,
    targetAudience,
    uniqueSellingProposition,
    language,
    onProductDescriptionChange,
    onTargetAudienceChange,
    onUniqueSellingPropositionChange,
    onLanguageChange = noop,
    isGenerating,
}) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <div className={styles['search-container']}>
                <TitleGenerationInputField
                    inputId="aiSuggestionsProductDescription"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.productDescription.label',
                        defaultMessage: 'What services do you offer?',
                    })}
                    placeholder={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.productDescription.placeholder',
                        defaultMessage: 'e.g. Socks',
                    })}
                    value={productDescription}
                    onChange={onProductDescriptionChange}
                    disabled={isLoadingLp}
                />
                <TitleGenerationInputField
                    inputId="aiSuggestionsTargetAudience"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.targetAudience.label',
                        defaultMessage: 'What is your target audience?',
                    })}
                    placeholder={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.targetAudience.placeholder',
                        defaultMessage: 'e.g. 20-30 year old who love fashion and comfort',
                    })}
                    value={targetAudience}
                    onChange={onTargetAudienceChange}
                    disabled={isLoadingLp}
                />
                <TitleGenerationInputField
                    inputId="aiSuggestionsUniqueSellingProposition"
                    label={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.uniqueSellingProposition.label',
                        defaultMessage: 'What is your unique selling proposition (USP)?',
                    })}
                    placeholder={formatMessage({
                        id: 'creative.creator.aiTitleSuggestions.uniqueSellingProposition.placeholder',
                        defaultMessage: 'e.g. comfortable and fashionable high quality socks',
                    })}
                    value={uniqueSellingProposition}
                    onChange={onUniqueSellingPropositionChange}
                    disabled={isLoadingLp}
                />
                <TitleGenerationLanguageDropdown
                    language={language}
                    onLanguageChange={onLanguageChange}
                    disabled={isLoadingLp}
                />
            </div>
            {isGenerating && (
                <LoadingBar
                    durationInSeconds={40}
                    loadingMessage={
                        <FormattedMessage
                            id="creative.creator.aiVariationsSetup.generate.loading"
                            defaultMessage="We're working on generating your titles and descriptions. It will only take a few seconds."
                        />
                    }
                    containerClassName={styles['loading-container']}
                />
            )}
        </>
    );
};
