import { startCase } from 'lodash';
import { csvReportsApi } from '../../../../../services/api';
import { EXPORT_TYPES as EXPORT_TYPE } from '../../../../../services/api/apiConstants';
import { EXPORT_TYPES } from '../components/GridToolbar/ExportDropdown/constants';
import addLastUpdateTime from '../utils/lastUpdateTimeReportRowTransformer';
import { getDateRangeQueryParam, getReportFiltersParam, getSortingQueryParams } from '../utils/queryFilterUtils';
import { flatDynamicFields } from './dynamicColumnsService';
import { filterReportSortByColumnDefs } from './gridStateService';

const buildFilename = ({
    intl,
    selectedAccountName,
    selectedCampaignName,
    selectedReport,
    startDate,
    endDate,
    buildReportNameForExport,
    reportFilters = [],
}) => {
    const selectedCampaignNameText = intl.formatMessage({
        id: selectedCampaignName,
        defaultMessage: selectedCampaignName,
    });
    const selectedFilters = reportFilters
        .map(filter => filter.values.map(item => `-Filter_${filter.type}(${item.label})`))
        .join('');

    const selectedReportContext = buildReportNameForExport
        ? buildReportNameForExport(selectedReport)
        : ` - ${startCase(selectedReport)} - ${selectedCampaignNameText} - ${startDate.format(
              'MMM-DD-YYYY'
          )} - ${endDate.format('MMM-DD-YYYY')}`;
    return `${selectedAccountName}${selectedReportContext}${selectedFilters}.csv`;
};

const transformReportData = ({
    data: { results = [], lastUpdateTime, ...restData },
    lastUpdateTimeField,
    timeZoneName,
}) => {
    const flattenedResults = results.map(({ dynamicFields, ...restRow }) => ({
        ...restRow,
        ...addLastUpdateTime({ rowData: restRow, lastUpdateTimeField, lastUpdateTime, timeZoneName }),
        ...flatDynamicFields(dynamicFields),
    }));

    return { ...restData, results: flattenedResults };
};

const reportsFetcher = (reportUrl, accountId, allQueryParams, lastUpdateTimeField, timeZoneName) => async () => {
    const data = await csvReportsApi(reportUrl, accountId, allQueryParams);
    return transformReportData({ data, lastUpdateTimeField, timeZoneName });
};

export const csvOptionsBuilder = ({
    accountId,
    campaignId,
    dimension,
    selectedReport,
    searchTerm,
    reportSort,
    startDate,
    endDate,
    selectedAccountName,
    selectedCampaignName,
    columnDefs,
    selectedColumns,
    currency,
    intl,
    timeZoneName,
    buildReportNameForExport,
    reportFilters,
    reportQueryParams,
    reportConfig,
    allowedSortingColumnDefs,
}) => {
    // Build dataProvider func
    const { searchField, sortMap, endpoint, lastUpdateTimeField } = reportConfig;
    const resolvedReportSort = filterReportSortByColumnDefs(reportSort, columnDefs, allowedSortingColumnDefs);
    const allQueryParams = {
        ...getDateRangeQueryParam(startDate, endDate),
        ...getReportFiltersParam({ campaignId, searchTerm, searchField, dynamicFilters: reportFilters }),
        ...getSortingQueryParams({ reportSort: resolvedReportSort, sortMap }),
        ...{ [EXPORT_TYPE.EXPORT_TYPE]: EXPORT_TYPES.CSV },
        ...reportQueryParams,
        dimension,
    };
    const getAllDataProvider = reportsFetcher(endpoint, accountId, allQueryParams, lastUpdateTimeField, timeZoneName);

    // build filename
    const filename = buildFilename({
        intl,
        selectedAccountName,
        selectedCampaignName,
        selectedReport,
        startDate,
        endDate,
        buildReportNameForExport,
        reportFilters,
    });

    // build injectables
    const injectables = { i18n: intl, currency, timeZoneName };

    return {
        columnDefs,
        selectedColumns,
        filename,
        dataProvider: getAllDataProvider,
        injectables,
    };
};
