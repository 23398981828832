import React, { useEffect, useRef } from 'react';
import classnames from 'classnames/bind';
import { isEqual } from 'lodash';
import uuid from 'uuid/v1';
import { ContentIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { AI_VARIATIONS_FIELD_NAMES } from '../../../../constants/aiVariationsConstants';
import { useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { useHelpWriteModal } from '../../hooks/useHelpWriteModal';
import { HelpWriteButton } from '../HelpWrite/HelpWriteButton';
import { AiContentFieldsSection } from './AiContentFieldsSection';
import styles from './aiVariationsContentSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const AiVariationsContentSection = ({ className }) => {
    const { onChange: setContent } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.CONTENT });
    const { value: titles } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.TITLES });
    const { value: description } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.DESCRIPTION });
    const { value: cta } = useFormFieldValue({ field: AI_VARIATIONS_FIELD_NAMES.CTA });
    const { aiVariationsContentSectionRef } = useAiVariationsContext();
    const openModal = useHelpWriteModal({ eventType: 'Header' });
    const prevTitles = useRef(titles);
    const helpWriteButtonOnClick = e => {
        openModal();
        e.stopPropagation();
    };

    useEffect(() => {
        if (!description && !cta && isEqual(prevTitles.current, titles)) {
            return;
        }
        const newContent = titles.map(title => ({
            id: uuid(),
            title,
            status: title ? COMPONENT_STATUS.ACTIVE : COMPONENT_STATUS.EMPTY,
            description,
            cta,
            descriptionEnabled: !!description,
            ctaEnabled: !!cta,
        }));
        setContent(newContent);
        prevTitles.current = titles;
    }, [cta, description, setContent, titles]);

    return (
        <div ref={aiVariationsContentSectionRef}>
            <CommonCollapsibleCard
                id="CREATIVE_AI_CONTENT"
                header={
                    <div className={styles['header']}>
                        <SectionHeader
                            headerIcon={<ContentIcon />}
                            headerText={
                                <FormattedMessage id="creative.creator.content.label" defaultMessage="Content" />
                            }
                        />
                        <HelpWriteButton className={styles['help-button']} onClick={helpWriteButtonOnClick} />
                    </div>
                }
                containerClassName={classNameBuilder('collapsible-card', className)}
            >
                <AiContentFieldsSection className={styles['field']} />
            </CommonCollapsibleCard>
        </div>
    );
};
