import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useMarketplaceAudiencesContext } from '../MarketplaceAudiencesContext';
import styles from './DisabledProviderLeafTooltip.module.scss';

const tailStyle = { zIndex: 1 };

const DisabledProviderLeafTooltip = ({ provider, type }) => {
    const { getPartnerByProvider, enabledPartnerGroup } = useMarketplaceAudiencesContext();

    const isMultiPartnerForSegmentType = getPartnerByProvider(provider) === null;
    const idPostfix = isMultiPartnerForSegmentType ? '.multi.partner' : '';
    const partnerToDisplay = isMultiPartnerForSegmentType && enabledPartnerGroup ? enabledPartnerGroup : provider;

    return (
        <Tooltip
            position={TOOLTIP_POSITION.BOTTOM_START}
            arrow
            className={styles['tooltip-content']}
            tailStyle={tailStyle}
            duration={0}
            delay={800}
        >
            <FormattedMessage
                id={`campaign.editor.marketplace.audiences.disabled.${type}.tooltip` + idPostfix}
                defaultMessage="{provider} does not support multi-partner targeting."
                values={{ provider: partnerToDisplay }}
            />
        </Tooltip>
    );
};
DisabledProviderLeafTooltip.propTypes = {
    provider: PropTypes.string,
    type: PropTypes.string,
};

export default DisabledProviderLeafTooltip;
