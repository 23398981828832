import React, { useLayoutEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router';
import { setLocationSearch } from './actions';
import { locationSearchSelector } from './selectors';

/**
 * @deprecated - this it temporary sync. solution until all legacy selectors be removed from the app. DEV-104259
 * This patch is necessary for react-router v5. Removing this fix is a prerequisite for upgrading to the next react-router version
 */
export const CustomRouter = ({ children, history }) => {
    const dispatch = useDispatch();
    const routerRef = useRef();
    const locationSearch = useSelector(locationSearchSelector);

    useLayoutEffect(
        function syncInitialUrlToReduxStore() {
            const historySearch = history.location.search;
            if (locationSearch === historySearch) {
                return;
            }
            dispatch(setLocationSearch(historySearch));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    useLayoutEffect(() => {
        const router = routerRef.current;

        if (!router) {
            return;
        }

        router.unlisten();
        router.unlisten = history.listen(location => {
            if (router._isMounted) {
                batch(() => {
                    router.setState({ location });
                    dispatch(setLocationSearch(location.search));
                });
                return;
            }
            router._pendingLocation = location;
        });
    }, [history, dispatch]);

    return (
        <Router ref={routerRef} history={history}>
            {children}
        </Router>
    );
};
