import { callGetApi, callPostApi, callPatchApi, callDeleteApi } from 'services/api/apiCallerProxy';
import { queryClient } from 'services/query';
import { unipFunnelApiFactory } from './unipFunnelApiFactory';

export * from './hooks';
export default unipFunnelApiFactory({
    callGetApi,
    callPostApi,
    callPatchApi,
    callDeleteApi,
    queryClient,
});
