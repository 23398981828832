import React from 'react';
import { FormBreadcrumbs } from 'modules/campaigns/modules/common-campaign-form';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useTrackingBreadcrumbs } from '../../hooks/useTrackingBreadcrumbs';
import styles from './trackingFormBreadcrumbs.module.scss';

export const TrackingFormBreadcrumbs = ({ msgId, defaultMessage }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({ id: msgId, defaultMessage });
    const { steps } = useTrackingBreadcrumbs({
        title,
    });
    return <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />;
};
