import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { get, merge, set } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import UnsupportedConfiguration from 'components/UnsupportedConfiguration/UnsupportedConfiguration';
import { FORM_MODES } from 'config/formModes';
import { useSelectedAccount } from 'hooks';
import TARGETING_TYPES from 'modules/campaigns/modules/common-campaign-form/config/TargetingTypes';
import { getDefaultTargeting } from 'modules/campaigns/services/utils/getDefaultTargeting';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import withValidationContextConsumer from 'modules/taboola-common-frontend-modules/validations/components/hoc/withValidationContextConsumer';
import { dimensionSelector } from 'selectors';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { invalidateSubCountryData } from '../../actions';
import { COUNTRY_TARGETING_LEVELS } from '../../config';
import { fetchCities, fetchDMAs, fetchRegions } from '../../flows';
import { CountryTargeting, CountryTargetingTooltip } from './CountryTargeting';
import { SubCountryTargeting, SubCountryTargetingTooltip } from './SubCountryTargeting';
import styles from './geoTargeting.module.scss';

const classNameBuilder = classnames.bind(styles);

const ALL_COUNTRIES_CONFIRMATION_MODAL_MESSAGES = {
    title: <FormattedMessage id="app.modal.allCountries.title" tagName="h2" defaultMessage="Are You Sure?" />,
    formProps: {
        submitButtonText: (
            <FormattedMessage id="app.modal.allCountries.button.positive" defaultMessage="Yes, Proceed" />
        ),
        cancelButtonText: <FormattedMessage id="app.modal.allCountries.button.negative" defaultMessage="Stay here" />,
    },
    content: (
        <FormattedMessage
            id="app.modal.allCountries.content"
            defaultMessage="This campaign is targeting all of the countries worldwide.{br} Are you sure you want to proceed? "
        />
    ),
};

const MOST_COUNTRIES_CONFIRMATION_MODAL_MESSAGES = {
    title: <FormattedMessage id="app.modal.mostCountries.title" tagName="h2" defaultMessage="Are You Sure?" />,
    formProps: {
        submitButtonText: (
            <FormattedMessage id="app.modal.mostCountries.button.positive" defaultMessage="Yes, Proceed" />
        ),
        cancelButtonText: <FormattedMessage id="app.modal.mostCountries.button.negative" defaultMessage="Stay here" />,
    },
    content: (
        <FormattedMessage
            id="app.modal.mostCountries.content"
            defaultMessage="This campaign is targeting all of the countries worldwide except for those that you have selected.{br}{br} Are you sure you want to proceed? "
        />
    ),
};

const GeoTargeting = props => {
    const {
        value: geoTargeting,
        onChange: onGeoTargetingChange,
        mode,
    } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting',
    });
    const { value: countryTargetingLevel, onChange: onCountryTargetingLevelChange } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargetingLevel',
    });
    const {
        value: countryValues,
        onChange: onChangeCountryValues,
        isDirty: isCountryValuesDirty,
    } = useFormFieldValue({
        field: 'campaignTargeting.geoTargeting.countryTargeting.values',
    });

    const formDataContext = useFormDataContext();
    const isFormAccountFla = formDataContext?.formAccount?.isFla;
    const [{ isFla: isSelectedAccountFla }] = useSelectedAccount();
    const showPostalCode = !(isFormAccountFla || isSelectedAccountFla);

    // This is temporary solution until full migration from GeoTargetingLegacy
    useEffect(() => {
        if (isCountryValuesDirty) {
            onChangeCountryValues(countryValues);
        }
    }, [countryValues, onChangeCountryValues, isCountryValuesDirty]);

    return (
        <GeoTargetingLegacy
            geoTargeting={geoTargeting}
            onGeoTargetingChange={onGeoTargetingChange}
            mode={mode}
            countryTargetingLevel={countryTargetingLevel}
            onCountryTargetingLevelChange={onCountryTargetingLevelChange}
            showPostalCode={showPostalCode}
            {...props}
        />
    );
};

class GeoTargetingLegacy extends React.Component {
    componentDidMount() {
        const { mode } = this.props;
        if (mode !== FORM_MODES.EDIT) {
            this.checkIfTargetingAllCountries(false);
        }
    }

    getGeoTargetingProps() {
        const { geoTargeting } = this.props;

        return merge(
            {
                countryTargeting: getDefaultTargeting(),
                regionTargeting: getDefaultTargeting(),
                DMATargeting: getDefaultTargeting(),
                cityTargeting: getDefaultTargeting(),
                postalCodeTargeting: getDefaultTargeting(),
                countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
            },
            geoTargeting
        );
    }

    onCountryTargetingChange = countryTargeting => {
        const { invalidateSubCountryDataAction, onCountryTargetingLevelChange } = this.props;

        invalidateSubCountryDataAction();
        this.updateCampaign('', {
            countryTargeting,
            cityTargeting: getDefaultTargeting(),
            regionTargeting: getDefaultTargeting(),
            DMATargeting: getDefaultTargeting(),
            postalCodeTargeting: getDefaultTargeting(),
            countryTargetingLevel: COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY,
        });

        onCountryTargetingLevelChange(COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY);

        this.checkIfTargetingAllCountries(true, countryTargeting);
    };

    checkIfTargetingAllCountries = (targetingChanged, newCountryTargeting) => {
        const { setOptionalValidationNote, selectedDimension } = this.props;
        const { countryTargeting: countryTargetingPrev } = this.getGeoTargetingProps();
        const countryTargeting = newCountryTargeting || countryTargetingPrev;

        if (selectedDimension !== CAMPAIGN_DIMENSION.VIDEO && get(countryTargeting, 'values.length') === 0) {
            setOptionalValidationNote('countryTargeting', ALL_COUNTRIES_CONFIRMATION_MODAL_MESSAGES, targetingChanged);
        } else if (get(countryTargeting, 'type') === TARGETING_TYPES.EXCLUDE) {
            setOptionalValidationNote('countryTargeting', MOST_COUNTRIES_CONFIRMATION_MODAL_MESSAGES, targetingChanged);
        } else {
            setOptionalValidationNote('countryTargeting', null, targetingChanged);
        }
    };

    updateRegionTargetingType = targetingType => this.updateTargetingType('regionTargeting', targetingType);

    updateDMATargetingType = targetingType => this.updateTargetingType('DMATargeting', targetingType);

    updateCityTargetingType = targetingType => this.updateTargetingType('cityTargeting', targetingType);

    updateRegionTargeting = targeting => this.updateCampaign('regionTargeting', targeting);

    updateDMATargeting = targeting => this.updateCampaign('DMATargeting', targeting);

    updateCityTargeting = targeting => this.updateCampaign('cityTargeting', targeting);

    updateTargetingType = (key, targetingType) => {
        const { geoTargeting } = this.props;

        const newTargeting = {
            type: targetingType,
            values: [...get(geoTargeting, `${key}.values`, [])],
        };

        this.updateCampaign(key, newTargeting);
    };

    updateCampaign(key, value) {
        const { geoTargeting, onGeoTargetingChange } = this.props;
        let newGeoTargeting = { ...geoTargeting };
        if (key) {
            set(newGeoTargeting, key, value);
        } else {
            newGeoTargeting = { ...newGeoTargeting, ...value };
        }

        onGeoTargetingChange(newGeoTargeting);
    }

    isUnsupportedGeoTargeting() {
        const { countryTargeting } = this.getGeoTargetingProps();
        const { countryTargetingLevel } = this.props;
        const loadedWithEmptyCounty =
            countryTargetingLevel !== COUNTRY_TARGETING_LEVELS.ENTIRE_COUNTRY &&
            get(countryTargeting, 'values.length') !== 1;

        return loadedWithEmptyCounty;
    }

    render() {
        const {
            listOfRegions,
            listOfDMAs,
            fetchCitiesFlow,
            fetchRegionsFlow,
            fetchDMAsFlow,
            isListOfRegionsLoading,
            isListOfDMAsLoading,
            onCountryTargetingLevelChange,
            countryTargetingLevel,
            countryValidations,
            showDma,
            showPostalCode,
            showCities,
            skipSubCountry,
        } = this.props;
        const { countryTargeting, regionTargeting, DMATargeting, cityTargeting } = this.getGeoTargetingProps();
        const fieldClassName = classNameBuilder('input', 'location-field', 'prioritized');

        if (this.isUnsupportedGeoTargeting()) {
            return (
                <FormField
                    label={<FormattedMessage id="campaign.editor.geo.targeting.location" defaultMessage="Location" />}
                    containerClass={fieldClassName}
                >
                    <UnsupportedConfiguration />
                </FormField>
            );
        }

        return (
            <React.Fragment>
                <FormField
                    inputId="country-targeting"
                    label={<FormattedMessage id="campaign.editor.geo.targeting.location" defaultMessage="Location" />}
                    description={
                        <FormattedMessage
                            id="campaign.editor.geo.targeting.location.details.title"
                            defaultMessage="Target specific countries or regions."
                        />
                    }
                    helpText={<CountryTargetingTooltip />}
                    containerClass={fieldClassName}
                >
                    <CountryTargeting
                        onTargetingChange={this.onCountryTargetingChange}
                        countryValidations={countryValidations}
                    />
                </FormField>
                {!skipSubCountry && (
                    <FormField
                        inputId="sub-country-targeting"
                        label={
                            <FormattedMessage
                                id="campaign.editor.geo.targeting.subcountry"
                                defaultMessage="Locations to be included"
                            />
                        }
                        description={
                            <FormattedMessage
                                id="campaign.editor.geo.targeting.subcountry.details"
                                defaultMessage="Target multiple countries OR multiple Regions, Cities or Postal Codes in 1 country"
                            />
                        }
                        containerClass={styles['input']}
                        helpText={<SubCountryTargetingTooltip />}
                    >
                        <SubCountryTargeting
                            countryTargetingType={countryTargeting.type}
                            countryTargetingLevel={countryTargetingLevel}
                            selectedCountries={countryTargeting.values}
                            regionTargetingType={regionTargeting.type}
                            allRegions={listOfRegions}
                            selectedRegions={regionTargeting.values}
                            DMATargetingType={DMATargeting.type}
                            allDMAs={listOfDMAs}
                            selectedDMAs={DMATargeting.values}
                            cityTargetingType={cityTargeting.type}
                            selectedCities={cityTargeting.values}
                            onCountryTargetingLevelChange={onCountryTargetingLevelChange}
                            onRegionTargetingTypeChange={this.updateRegionTargetingType}
                            onDMATargetingTypeChange={this.updateDMATargetingType}
                            onCityTargetingTypeChange={this.updateCityTargetingType}
                            onRegionTargetingChange={this.updateRegionTargeting}
                            onCityTargetingChange={this.updateCityTargeting}
                            onDMATargetingChange={this.updateDMATargeting}
                            fetchRegionsFlow={fetchRegionsFlow}
                            fetchCitiesFlow={fetchCitiesFlow}
                            fetchDMAsFlow={fetchDMAsFlow}
                            isListOfRegionsLoading={isListOfRegionsLoading}
                            isListOfDMAsLoading={isListOfDMAsLoading}
                            showDma={showDma}
                            showCities={showCities}
                            showPostalCode={showPostalCode}
                        />
                    </FormField>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { listOfRegions, listOfDMAs, isListOfRegionsLoading, isListOfDMAsLoading } = state.campaignsReducer;
    const selectedDimension = dimensionSelector(state);

    return {
        listOfRegions,
        listOfDMAs,
        isListOfRegionsLoading,
        isListOfDMAsLoading,
        selectedDimension,
    };
};

const mapDispatchToProps = {
    fetchRegionsFlow: fetchRegions,
    fetchCitiesFlow: fetchCities,
    fetchDMAsFlow: fetchDMAs,
    invalidateSubCountryDataAction: invalidateSubCountryData,
};

GeoTargeting.propTypes = {
    listOfRegions: PropTypes.array,
    listOfDMAs: PropTypes.array,
    fetchCitiesFlow: PropTypes.func.isRequired,
    fetchRegionsFlow: PropTypes.func.isRequired,
    fetchDMAsFlow: PropTypes.func.isRequired,
    invalidateSubCountryDataAction: PropTypes.func.isRequired,
    isListOfRegionsLoading: PropTypes.bool,
    isListOfDMAsLoading: PropTypes.bool,
    showDma: PropTypes.bool,
    showPostalCode: PropTypes.bool,
    showCities: PropTypes.bool,
};

GeoTargeting.defaultProps = {
    listOfRegions: [],
    listOfDMAs: [],
};

const GeoTargetingWithValidations = withValidationContextConsumer(GeoTargeting);
GeoTargetingWithValidations.displayName = 'GeoTargeting';

export { GeoTargeting };

export default connect(mapStateToProps, mapDispatchToProps)(GeoTargetingWithValidations);
