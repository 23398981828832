import React from 'react';
import PropTypes from 'prop-types';
import { useSelectedAccount } from 'hooks';
import ConversionStatusCellRenderer from './ConversionStatusCellRenderer';
import ToggleSwitchCellRenderer from './ToggleSwitchCellRenderer';

export const ConversionStateCellRenderer = ({ data, ...rest }) => {
    const selectedAccountData = useSelectedAccount();
    return (
        <ConversionStatusCellRenderer
            data={{
                ...data,
                selectedAccountData,
            }}
            {...rest}
            defaultMessage="Conversion Status"
        />
    );
};
ConversionStateCellRenderer.propTypes = {
    ...ToggleSwitchCellRenderer.propTypes,
    isDisabled: PropTypes.func,
};

ConversionStateCellRenderer.defaultProps = {
    isDisabled: () => false,
};
