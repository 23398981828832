import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BlockIcon, OkOIcon, DeletableItem } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingTypes from '../../config/TargetingTypes';
import styles from './listItem.module.scss';

const ListItem = ({ targetingType, item, msgIdPrefix, deleteItem }) => {
    const { formatMessage } = useIntl();
    const { value, label } = item;

    const handleOnClick = useCallback(() => deleteItem(item), [item, deleteItem]);

    return (
        <DeletableItem
            buttonProps={{
                'aria-label': `${formatMessage({ id: 'app.modal.removeVariation.button.remove' })} - ${value}`,
            }}
            onDelete={handleOnClick}
            isItemDeletable={!!deleteItem}
        >
            <div className={styles['container']}>
                <span>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={styles['include']} />
                    ) : (
                        <BlockIcon className={styles['exclude']} />
                    )}
                </span>
                <div className={styles['label']}>
                    <FormattedMessage id={`${msgIdPrefix}.${value}`} defaultMessage={label} />
                </div>
            </div>
        </DeletableItem>
    );
};

ListItem.propTypes = {
    item: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
    msgIdPrefix: PropTypes.string,
    targetingType: PropTypes.string,
};

export default ListItem;
