import { isEmpty, noop } from 'lodash';
import { aiGenerationApi } from 'services/api';
import { COMPONENT_STATUS } from 'services/constants';

const fetchAiGeneratedTitleSuggestions = async ({
    accountId,
    campaignId,
    productDescription,
    targetAudience,
    uniqueSellingProposition,
    setStatus = noop,
}) => {
    if (isEmpty(productDescription) || isEmpty(targetAudience)) {
        return { titles: [] };
    }

    try {
        setStatus(COMPONENT_STATUS.LOADING);

        const results = await aiGenerationApi.getAIGeneratedTitles({
            accountId,
            campaignId,
            productDescription,
            targetAudience,
            uniqueSellingProposition,
        });

        setStatus(COMPONENT_STATUS.ACTIVE);

        return results;
    } catch (error) {
        setStatus(COMPONENT_STATUS.ERROR);
        return { titles: [], error };
    }
};

export default fetchAiGeneratedTitleSuggestions;
