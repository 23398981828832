import { useUserPermissionOrFeatureFlagEnabled } from '../../taboola-common-frontend-modules/./account-configurations/hooks/useUserPermissionOrFeatureFlagEnabled';
import { FEATURE_FLAGS } from '../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../taboola-common-frontend-modules/formData';

export const useAudienceTypeFormField = () => {
    const pixelLookalikePermitted = useUserPermissionOrFeatureFlagEnabled(
        'CREATE_PIXEL_PREDICTIVE',
        FEATURE_FLAGS.PIXEL_PREDICTIVE_ENABLED
    );
    return useFormFieldValue({ field: 'type' }, { pixelLookalikePermitted });
};
