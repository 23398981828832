import React from 'react';
import { ErrorOutlinedIcon, StatusIndicator } from 'tuui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CvrLearningProgressTooltip } from '../../Tooltips/CvrLearningProgressTooltip/CvrLearningProgressTooltip';
import StatusCellRenderer from '../StatusCellRenderer/StatusCellRenderer';
import { useCvrLearningStatusState } from './useCvrLearningStatusState';

export const CvrLearningStatusRenderer = props => {
    const {
        data: {
            cvrLearningStatus: phase,
            cvrLearningStatusUpdateTimeInUtc: phaseUpdateTime,
            totalConversions,
            isCvrRelearning,
        },
        campaignStatusMessages,
        value: { status },
    } = props;
    const { phaseMsgIdPrefix } = campaignStatusMessages;

    const { messageId, messageValue, progress, progressBarColor, shouldShowErrorIcon, isDoneWithLearning } =
        useCvrLearningStatusState({
            phase,
            phaseUpdateTime,
            totalConversions,
        });
    const { formatMessage } = useIntl();

    const timeLeft = messageId
        ? formatMessage({ id: `app.campaigns.campaign.status.time.left.${messageId}` }, messageValue)
        : null;

    return (
        <StatusCellRenderer
            phase={isDoneWithLearning ? null : phase}
            tooltipBody={
                isDoneWithLearning ? null : (
                    <CvrLearningProgressTooltip
                        phase={phase}
                        phaseUpdateTime={phaseUpdateTime}
                        totalConversions={totalConversions}
                        isCvrRelearning={isCvrRelearning}
                        phaseMsgIdPrefix={phaseMsgIdPrefix}
                    />
                )
            }
            progress={progress}
            progressBarColor={progressBarColor}
            SubStatusIcon={shouldShowErrorIcon ? ErrorOutlinedIcon : null}
            subStatusIconIndication={shouldShowErrorIcon ? StatusIndicator.type.error : null}
            timeLeft={timeLeft}
            {...props}
            {...campaignStatusMessages}
            value={status}
        />
    );
};
