import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { TextCheckbox, CheckboxGroup, DropdownMenu } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TARGETING_TYPES } from '../../config';
import DaypartingButton from './DaypartingButton';
import styles from './daypartingRow.module.scss';

const HOUR_DEFAULTS = {
    startTime: 0,
    endTime: 24,
};

const classNameBuilder = classnames.bind(styles);

const DropdownColumnDivider = () => (
    <div className={styles['dropdown-column-divider']}>
        <span>-</span>
    </div>
);

const DaypartingRow = ({
    day,
    startTime,
    endTime,
    onStartHourChange,
    onEndHourChange,
    onToggleDayOn,
    onToggleDayOff,
    type,
    onCopy,
    error,
    endTimeOptions,
    startTimeOptions,
}) => {
    const isDisabled = type !== TARGETING_TYPES.INCLUDE;
    const disabledClass = isDisabled && styles['disabled'];

    const hideInvalidOptions = {
        option: (provided, state) =>
            state.value <= startTime
                ? {
                      ...provided,
                      display: 'none',
                  }
                : { ...provided },
    };

    return (
        <div className={classNameBuilder('row', disabledClass, day)}>
            <CheckboxGroup
                onSelectCheckbox={onToggleDayOn}
                onUnselectCheckbox={onToggleDayOff}
                selectedValues={!isDisabled ? [day] : []}
                className={classNameBuilder('day-column', 'justify-center')}
            >
                <TextCheckbox
                    labelClassName={styles['checkbox-label']}
                    value={day}
                    title={<FormattedMessage id={`campaign.editor.schedule.dayparting.day.${day}`} />}
                />
            </CheckboxGroup>
            <DropdownMenu
                disabled={isDisabled}
                onChange={onStartHourChange}
                selectedValueObject={{ value: startTime }}
                options={startTimeOptions}
                className={classNameBuilder('hour-column', 'start-time')}
            />
            <DropdownColumnDivider />
            <DropdownMenu
                disabled={isDisabled}
                onChange={onEndHourChange}
                selectedValueObject={{ value: endTime }}
                options={endTimeOptions}
                className={classNameBuilder('hour-column', 'end-time')}
                styles={hideInvalidOptions}
            />

            {!error && (
                <div className={styles['copy-wrapper']}>
                    <DaypartingButton iconBefore="duplicate" className={styles['copy-timeframe']} onClick={onCopy}>
                        <FormattedMessage id="campaign.editor.schedule.dayparting.row.copy.timeframe" />
                    </DaypartingButton>
                </div>
            )}
        </div>
    );
};

DaypartingRow.propTypes = {
    day: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    onStartHourChange: PropTypes.func,
    onEndHourChange: PropTypes.func,
    onToggleDayOn: PropTypes.func,
    onToggleDayOff: PropTypes.func,
    type: PropTypes.string,
    onCopy: PropTypes.func,
    error: PropTypes.bool,
    startTimeOptions: PropTypes.array,
    endTimeOptions: PropTypes.array,
};

DaypartingRow.defaultProps = {
    startTime: HOUR_DEFAULTS.startTime,
    endTime: HOUR_DEFAULTS.endTime,
};

export default DaypartingRow;
