import { useCallback } from 'react';
import { DASHBOARD_RANGE_PICKER_PRESETS as PRESETS } from 'taboola-ultimate-ui';
import { useNavigate } from 'hooks';
import { CAMPAIGNS_ROUTE, REPORT_TYPE } from 'modules/campaigns/config';
import { FILTER_TYPE } from 'modules/campaigns/modules/campaigns-reports/config';
import { mergeQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import { DATE_PRESET, REPORT_ID, useDateRange, useSelectedReportId } from '../../campaigns-reports/hooks';
import { REPORT_FILTER_STATUS } from '../../campaigns-reports/hooks/useReportFiltersStatus';
import { TEMP_REPORT_FILTERS } from '../../campaigns-reports/hooks/useTempReportFilters';

export const formatItemIds = itemIds => itemIds.join(', ');

export const useNavigateToAdsReport = ({ formattedItemIds: value, label }) => {
    const navigate = useNavigate();
    const [selectedReport] = useSelectedReportId();
    const [{ datePreset }] = useDateRange();

    const navigateToAdsReport = useCallback(() => {
        const getDatePreset = () => {
            if (selectedReport === REPORT_TYPE.CAMPAIGN_REALTIME) {
                return PRESETS.SEVEN_DAYS;
            }
            return datePreset;
        };
        const route = `${CAMPAIGNS_ROUTE}${mergeQueryParams('', {
            [REPORT_ID]: REPORT_TYPE.CREATIVE,
            [REPORT_FILTER_STATUS]: true,
            [DATE_PRESET]: getDatePreset(),
            [TEMP_REPORT_FILTERS]: [
                {
                    id: FILTER_TYPE.ITEMS,
                    type: FILTER_TYPE.ITEMS,
                    values: [
                        {
                            value,
                            label: label ?? value,
                        },
                    ],
                },
            ],
        })}`;
        navigate(route);
    }, [datePreset, value, navigate, selectedReport, label]);

    return { navigateToAdsReport };
};
