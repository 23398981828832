const BRAND_SAFETY_RISK_LEVEL = {
    HIGH: 'HIGH',
    MEDIUM: 'MEDIUM',
    LOW: 'LOW',
};

export const BRAND_SAFETY_RISK_LEVEL_ORDER = [
    BRAND_SAFETY_RISK_LEVEL.HIGH,
    BRAND_SAFETY_RISK_LEVEL.MEDIUM,
    BRAND_SAFETY_RISK_LEVEL.LOW,
];

export default BRAND_SAFETY_RISK_LEVEL;
