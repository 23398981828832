'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabPanel = exports.TabList = exports.Tab = exports.Tabs = undefined;

var _reactTabs = require('react-tabs');

var _Tabs = require('./Tabs');

var _Tabs2 = _interopRequireDefault(_Tabs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Created by oreuveni
 * Date: 2019-04-24
 * Time: 15:32
 */
exports.Tabs = _Tabs2.default;
exports.Tab = _reactTabs.Tab;
exports.TabList = _reactTabs.TabList;
exports.TabPanel = _reactTabs.TabPanel;