import React from 'react';
import { isEmpty, noop } from 'lodash';
import { Gallery } from 'taboola-ultimate-ui';
import { COMPONENT_STATUS } from 'services/constants';
import { GalleryItem } from '../../ImageGallery/GalleryItem';
import { OriginalImageIndication } from '../OriginalImageIndication/OriginalImageIndication';
import { useCreativeStudioContext } from '../providers/CreativeStudioContext';
import styles from './creativesStudioGallery.module.scss';

const itemDropdownMenuStyle = { right: 0, left: 'unset' };

export const CreativesStudioGallery = ({
    imageResults,
    GalleryItemDropdown,
    MainGalleryComponent = GalleryItem,
    setSegmentMask,
    imageResultsStatus = COMPONENT_STATUS.INITIAL,
    sendResultClickEvent = noop,
    children,
    shouldHideDropdown,
}) => {
    const { originalImage, selectedImageUrl, setSelectedImageUrl } = useCreativeStudioContext();
    const images = [originalImage, ...imageResults];
    const isResultsLoading = imageResultsStatus === COMPONENT_STATUS.LOADING;
    const isResultsVisible = isResultsLoading
        ? !isEmpty(imageResults)
        : imageResultsStatus !== COMPONENT_STATUS.INITIAL;

    const onResultClick = image => {
        if (isResultsLoading) {
            return;
        }

        setSelectedImageUrl(image.url);
        sendResultClickEvent(image.url);
    };

    const selectedImage = { url: selectedImageUrl, id: selectedImageUrl };

    return (
        <div>
            <MainGalleryComponent
                image={selectedImage}
                addImageHandler={noop}
                GalleryItemDropdown={GalleryItemDropdown}
                className={styles['selected-image']}
                showLargePreview={false}
                itemDropdownMenuStyle={itemDropdownMenuStyle}
                shouldHideDropdown={shouldHideDropdown}
                setSegmentMask={setSegmentMask}
            />
            {children}
            {isResultsVisible && (
                <div className={styles['results-container']} data-testid="creative-studio-gallery-results-container">
                    <OriginalImageIndication />
                    <Gallery gap="24px" columns={4} className={styles['results-gallery']}>
                        {images.map(image => (
                            <GalleryItem
                                key={image.id}
                                image={image}
                                selected={selectedImageUrl === image.url && !isResultsLoading}
                                clickHandler={() => onResultClick(image)}
                                status={originalImage === image ? COMPONENT_STATUS.ACTIVE : imageResultsStatus}
                                wrapperClass={styles['adjusted-aspect-ratio']}
                                shouldHideOnError
                            />
                        ))}
                    </Gallery>
                </div>
            )}
        </div>
    );
};
