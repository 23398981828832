'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _TaboolaDataGrid = require('./TaboolaDataGrid');

var _TaboolaDataGrid2 = _interopRequireDefault(_TaboolaDataGrid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _TaboolaDataGrid2.default;