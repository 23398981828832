import React from 'react';
import PropTypes from 'prop-types';
import { AlertIcon } from 'taboola-ultimate-ui';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import recommendationsApi from 'services/api/recommendationsApi';
import { useFatiguedItemRecommendation } from '../../hooks/useFatiguedItemRecommendation';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const FatiguedItemSingleItemRecommendation = ({ recommendation, removeRecommendationIndication }) => {
    const { id: recommendationId, itemId, accountName: accountId, campaignId, messageParams } = recommendation;
    const dismissItemRecommendation = recommendationsApi.dismissItemPerformanceRecommendation;
    const { dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        dismissParams: {
            accountId,
            campaignId,
            recommendationId,
            itemId,
        },
        onDismiss: dismissItemRecommendation,
        onDismissSuccess: removeRecommendationIndication,
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });
    const { navigateToCreativeCreator } = useFatiguedItemRecommendation({
        accountId,
        itemId,
        campaignId,
        recommendation,
    });

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={messageParams}
            titleCode="performance-recommendations.fatigued-item-single-item.title"
            descriptionCode="performance-recommendations.fatigued-item-single-item.description"
            isRenderedInReportCell={true}
            iconImg={AlertIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.general-recommendation.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode="performance-recommendations.fatigued-item.action"
                    tooltipCode="performance-recommendations.fatigued-item-single-item.tooltip"
                    onClick={navigateToCreativeCreator}
                    isItemRec={true}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

FatiguedItemSingleItemRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
