import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { INPUT_GROUP_TYPES } from 'taboola-ultimate-ui';
import { DropdownOption } from '../../../components/Dropdown';
import { useEventValueHandler } from '../../../hooks';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { useAvailableKeys } from './useAvailableKeys';
import styles from '../components/MetadataInput/metadataInput.module.scss';

const KeyPlaceholder = () => (
    <div className={styles['placeholder-text']}>
        <FormattedMessage id="account.editor.input.key.placeholder" defaultMessage="Key" />
    </div>
);
export const useMetadataInput = onAdd => {
    const [data, setData] = useState({ key: '', value: '' });
    const isDisabled = !data.key || !data.value;
    const onChangeKey = useCallback(
        ({ value: newKey }) => {
            setData(prevData => ({
                ...prevData,
                key: newKey,
            }));
        },
        [setData]
    );
    const { options } = useAvailableKeys();

    const setValue = useCallback(value => setData(prevData => ({ ...prevData, value })), [setData]);
    const setValueWrapper = useEventValueHandler(setValue);
    const config = useMemo(
        () => [
            {
                type: INPUT_GROUP_TYPES.DROPDOWN,
                dataKey: 'metadataKey',
                props: {
                    selectedValueObject: isEmpty(data.key) ? null : { value: data.key },
                    placeholder: <KeyPlaceholder />,
                    optionItemRenderer: props => {
                        return <DropdownOption msgIdPrefix="account.editor.externalMetadata.key" {...props} />;
                    },
                    data: { value: data.key },
                    onChange: onChangeKey,
                    width: '150px',
                    options,
                },
            },
            {
                dataKey: `metadataValue`,
                type: INPUT_GROUP_TYPES.INPUT,
                props: {
                    placeholder: 'Value',
                    value: data.value,
                    onChange: setValueWrapper,
                    inputClass: styles['input'],
                    mainClass: styles['input-container'],
                },
            },
        ],
        [data, onChangeKey, options, setValueWrapper]
    );

    const onClick = useCallback(() => {
        onAdd({ key: data.key, value: data.value });
        setData({ key: '', value: '' });
    }, [onAdd, setData, data]);

    return { config, isDisabled, onClick };
};
