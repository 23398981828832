import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';

export const HYBRID_SRC = 'hybridUrlSrc';

export const useHybridUrlSrc = queryParamHookFactory(HYBRID_SRC, {
    defaultValue: '',
    serializer: val => (val ? encodeURIComponent(val) : ''),
    deserializer: val => (val ? decodeURIComponent(val) : ''),
    order: Number.MAX_VALUE,
});
