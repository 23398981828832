import { useCallback, useMemo, useRef, useState } from 'react';
import { groupBy, isEmpty, mapValues } from 'lodash';
import { LoadingMode } from '../utils';

const useLoadingWrapper = ({ totals, leaves }) => {
    const [loadingCounter, setLoadingCounter] = useState(0);
    const startedLoadingMode = useRef(LoadingMode.FULL);
    const loadingMode = useMemo(
        () => (loadingCounter !== 0 ? startedLoadingMode.current : LoadingMode.NONE),
        [loadingCounter]
    );

    const wrapWithLoading = useCallback(
        async (paths, cb) => {
            const countOfLeavesByTaxonomy = mapValues(groupBy(leaves, 'taxonomy'), 'length');
            const hasMore = isEmpty(paths) || paths.some(path => totals[path] > countOfLeavesByTaxonomy[path]);
            if (!hasMore) {
                return cb();
            }

            startedLoadingMode.current = isEmpty(paths) ? LoadingMode.FULL : LoadingMode.PARTIAL;
            setLoadingCounter(prev => prev + 1);
            const returnValue = await cb();
            setLoadingCounter(prev => prev - 1);

            return returnValue;
        },
        [totals, leaves, setLoadingCounter]
    );

    return { wrapWithLoading, loadingMode };
};

export default useLoadingWrapper;
