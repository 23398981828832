import { uniq, compact, flatMap, map, countBy, get, toPairs } from 'lodash';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { BULK_ERROR_TYPES } from '../consts/bulkErrorTypes';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';

const ONE_SECOND = 1000;
export const calcDurationInSeconds = (timeStart, timeEnd = Date.now()) =>
    Math.round((timeEnd - timeStart) / ONE_SECOND);

export const wrapApiCallWithEvent =
    (apiCall, eventName) =>
    async (...args) => {
        const apiCallTimeStart = Date.now();
        const numRows = get(args, '0.requestBody.elements.length') || 'Unknown';

        const res = await apiCall(...args);

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${BULK_UPLOAD_GTM_PREFIX}: ${eventName}`,
            value: calcDurationInSeconds(apiCallTimeStart),
            additionalEventInfo: numRows,
        });

        return res;
    };

export const sendGtmUploadErrorEvents = ({ entityDataMap, fullUploadProcessTimeStart }) => {
    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
        component: `${BULK_UPLOAD_GTM_PREFIX}: Process Duration (seconds)`,
        value: calcDurationInSeconds(fullUploadProcessTimeStart),
    });

    toPairs(entityDataMap).forEach(([entity, entityData]) => {
        const entityDataErrors = map(entityData, 'error');
        const {
            successCount = 0,
            skippedCount = 0,
            [BULK_ERROR_TYPES.PARSING]: parsingErrorCount = 0,
            [BULK_ERROR_TYPES.API]: apiErrorCount = 0,
        } = countBy(entityDataErrors, error => {
            if (!error) {
                return 'successCount';
            } else if (error.skippedUpload) {
                return 'skippedCount';
            } else {
                return error.type || BULK_ERROR_TYPES.API;
            }
        });

        const allOffendingFields = flatMap(entityDataErrors, error => {
            if (error) {
                const { type, offendingFields, offendingField } = error;
                return type === BULK_ERROR_TYPES.PARSING ? offendingFields : offendingField;
            }

            return null;
        });
        const uniqueOffendingFields = uniq(compact(allOffendingFields)).join(',');

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${BULK_UPLOAD_GTM_PREFIX}_${entity}: Report status counts`,
            value: JSON.stringify({ successCount, skippedCount, parsingErrorCount, apiErrorCount }),
            additionalEventInfo: uniqueOffendingFields ? `offending fields: ${uniqueOffendingFields}` : '',
        });
    });
};
