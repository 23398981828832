export const EVENT_KEY = {
    pageName: 'pageName',
    pagePath: 'pagePath',
    tableDatePreset: 'tableDatePreset',
    accountId: 'accountId',
    taboolaCampaignId: 'taboolaCampaignId',
    state: 'state',
    visibility: 'visibility',
    component: 'component',
    value: 'value',
    columnId: 'columnId',
    columnName: 'columnName',
    reportId: 'reportId',
    notificationMessage: 'notificationMessage',
    notificationType: 'notificationType',
    notificationStatus: 'notificationStatus',
    additionalEventInfo: 'additionalEventInfo',
};
