import { forwardRef, useEffect } from 'react';
import { increaseMemoryCounter } from 'modules/taboola-common-frontend-modules/memory/useMemoryControl';

export const withCellRenderTracker = Component => {
    // We intentionally skip forwardRef here, earlier it was done accidentally in unconditional withRecommendationIndication
    const WrappedComponent = forwardRef((props, _) => {
        useEffect(() => {
            increaseMemoryCounter();
        }, []);

        return <Component {...props} />;
    });
    WrappedComponent.name = 'CellRenderTrackerWrapper';

    return WrappedComponent;
};
