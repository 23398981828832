import React from 'react';

export const DiscoverLogo = () => {
    return (
        <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.61798 7.0464C4.06767 7.54671 3.35254 7.76492 2.2207 7.76492H1.75054V1.78879H2.2207C3.35254 1.78879 4.03956 1.99269 4.61798 2.51988C5.2238 3.06243 5.58813 3.90385 5.58813 4.76935C5.58813 5.63695 5.2238 6.50384 4.61798 7.0464ZM2.57146 0.257874H0V9.2945H2.55793C3.91808 9.2945 4.90038 8.9719 5.76228 8.25129C6.7869 7.39836 7.39273 6.1125 7.39273 4.78265C7.39273 2.11561 5.41252 0.257874 2.57146 0.257874Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.19867 9.29455H9.95056V0.257568H8.19867V9.29455Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2342 3.7253C13.1829 3.33392 12.8744 3.07591 12.8744 2.58748C12.8744 2.01839 13.4243 1.58581 14.1794 1.58581C14.704 1.58581 15.1356 1.80297 15.5919 2.3176L16.5086 1.10994C15.7554 0.446936 14.8542 0.108276 13.8695 0.108276C12.2804 0.108276 11.0684 1.21852 11.0684 2.69745C11.0684 3.94246 11.6326 4.57998 13.2779 5.17561C13.9639 5.4193 14.313 5.5813 14.4889 5.69023C14.839 5.92031 15.0142 6.24605 15.0142 6.62556C15.0142 7.35769 14.4358 7.90025 13.654 7.90025C12.8185 7.90025 12.1454 7.47954 11.7422 6.69469L10.6104 7.79132C11.4174 8.98361 12.3869 9.51185 13.72 9.51185C15.5402 9.51185 16.8175 8.29372 16.8175 6.54456C16.8175 5.10857 16.2269 4.45883 14.2342 3.7253Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3701 4.78218C17.3701 7.43839 19.4429 9.49793 22.1105 9.49793C22.8645 9.49793 23.5105 9.34885 24.3069 8.97144V6.89653C23.6067 7.60178 22.9863 7.88633 22.192 7.88633C20.4276 7.88633 19.1754 6.59907 19.1754 4.76891C19.1754 3.03407 20.4672 1.66546 22.1105 1.66546C22.946 1.66546 23.5785 1.96537 24.3069 2.68214V0.608284C23.5379 0.215858 22.9058 0.0531616 22.1518 0.0531616C19.4977 0.0531616 17.3701 2.1546 17.3701 4.78218Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.1969 6.32761L35.8021 0.257568H33.8881L37.6993 9.52637H38.6421L42.522 0.257568H40.623L38.1969 6.32761Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.3142 9.29443H48.2822V7.76452H45.0647V5.32512H48.1639V3.79452H45.0647V1.78875H48.2822V0.257446H43.3142V9.29443Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.7041 4.41836H51.1923V1.6815H51.7318C52.8231 1.6815 53.4161 2.14131 53.4161 3.02077C53.4161 3.92922 52.8231 4.41836 51.7041 4.41836ZM55.2186 2.92553C55.2186 1.23362 54.0604 0.257446 52.0403 0.257446H49.4424V9.29443H51.1923V5.66413H51.4209L53.8456 9.29443H56L53.1725 5.48747C54.492 5.21724 55.2186 4.30914 55.2186 2.92553Z"
                fill="#212D34"
            />
            <g filter="url(#filter0_i_2005_151511)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.4344 4.80722C34.4344 7.46239 32.2953 9.6148 29.6565 9.6148C27.0177 9.6148 24.879 7.46239 24.879 4.80722C24.879 2.15206 27.0177 0 29.6565 0C32.2953 0 34.4344 2.15206 34.4344 4.80722Z"
                    fill="url(#paint0_linear_2005_151511)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_2005_151511"
                    x="24.879"
                    y="0"
                    width="9.55542"
                    height="9.61481"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.71875" dy="0.71875" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2005_151511" />
                </filter>
                <linearGradient
                    id="paint0_linear_2005_151511"
                    x1="35.5093"
                    y1="5.72622"
                    x2="29.8398"
                    y2="-0.97906"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA700" />
                    <stop offset="1" stopColor="#E75A10" />
                </linearGradient>
            </defs>
        </svg>
    );
};
