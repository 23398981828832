import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { trackPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameService';
import {
    getLocationData,
    getPageName,
    isInterimPageName,
} from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import { navigationTimeStampSelector } from '../selectors';

export const usePageName = () => {
    useSelector(navigationTimeStampSelector);
    const { accountId } = useAccount();
    const { pathname, search } = getLocationData();
    const pageName = useMemo(() => getPageName({ pathname, search }), [pathname, search]);

    useEffect(() => {
        if (isInterimPageName(pageName) || !accountId) {
            return;
        }
        trackPageName(pageName, accountId);
    }, [pageName, accountId]);

    return pageName;
};

export default usePageName;
