import React from 'react';
import moment from 'moment';
import { CREDIT_LIMIT_MODEL } from '../../config/CreditLimitModel';
import { useBillingData } from '../../hooks/useBillingData';
import { CreditCardBalance } from './CreditCardBalance/CreditCardBalance';
import { CreditLimitBalance } from './CreditLimitBalance/CreditLimitBalance';
import { LoadingBalance } from './LoadingBalance/LoadingBalance';

export const BalanceSection = ({ className, currency }) => {
    const { isFetching, billingData } = useBillingData();
    if (isFetching) {
        return <LoadingBalance className={className} />;
    }

    const {
        creditLimitModel,
        ccbBillingCycle,
        spentSinceLastBillingCycle,
        remaining,
        lastFund,
        creditLimit,
        hasPendingPrepayment,
    } = billingData;
    const { amount: lastFundAmount, createDateInUtc } = lastFund ?? {};
    const dateLastFundAdded = createDateInUtc ? moment.utc(createDateInUtc) : null;
    switch (creditLimitModel) {
        case CREDIT_LIMIT_MODEL.CCB:
            return (
                <CreditCardBalance
                    className={className}
                    billingCycle={ccbBillingCycle}
                    spentSinceLastBillingCycle={spentSinceLastBillingCycle}
                    currency={currency}
                />
            );
        case CREDIT_LIMIT_MODEL.ENTIRE:
            return (
                <CreditLimitBalance
                    className={className}
                    remainingAmount={remaining}
                    dateLastFundAdded={dateLastFundAdded}
                    lastAddedAmount={lastFundAmount}
                    currency={currency}
                    hasPendingPrepayment={hasPendingPrepayment}
                    creditLimitModel={creditLimitModel}
                />
            );
        case CREDIT_LIMIT_MODEL.MONTHLY:
            return (
                <CreditLimitBalance
                    className={className}
                    currency={currency}
                    creditAmount={creditLimit}
                    remainingAmount={remaining}
                    progressBarTextMessageId="billingAndPayments.balancePanel.monthly.progressbar.text"
                    lastAddedAmount={lastFundAmount}
                    dateLastFundAdded={dateLastFundAdded}
                    hasPendingPrepayment={hasPendingPrepayment}
                    creditLimitModel={creditLimitModel}
                />
            );
        default:
            return <LoadingBalance className={className} />;
    }
};
