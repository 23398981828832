import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { AsyncDropdown, DropdownIcon, SearchIcon, BackgroundOverlay, pxToRem } from 'taboola-ultimate-ui';
import { useFormMode, useSelectedAccount } from 'hooks';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { resetAccounts, retryFetchAccounts } from '../../actions';
import { fetchAccounts } from '../../flows';
import { accountsSelector } from '../../selectors';
import AccountPickerLoader from './AccountPickerLoader/AccountPickerLoader';
import { AccountPickerMenuItem } from './AccountPickerMenuItem/AccountPickerMenuItem';
import AccountPickerRetryMenuItem from './AccountPickerRetryMenuItem';
import AccountPickerSelectedItem from './AccountPickerSelectedItem';
import styles from './accountPicker.module.scss';

const classNameBuilder = classnames.bind(styles);

const searchIconRenderer = () => (
    <div className={styles['search-icon']}>
        <SearchIcon />
    </div>
);
const placeholder = (
    <FormattedMessage id="account.picker.placeholder" defaultMessage="Select Account...">
        {([text]) => <span className={styles['placeholder']}>{text}</span>}
    </FormattedMessage>
);

const accountPickerDefaultStyles = {
    container: provided => ({
        ...provided,
        width: styles.outerMenuWidth,
        zIndex: 1,
    }),

    control: provided => ({
        ...provided,
        color: styles.inputColor,
        margin: pxToRem('10px'),
        flexDirection: 'row-reverse',
        boxShadow: 'none',
        outline: 'none',
        borderColor: styles.outerMenuBorderColor,
        '&:hover': {
            borderColor: styles.outerMenuBorderColor,
        },
    }),

    menu: () => ({
        backgroundColor: styles.outerMenuBackgroundColor,
        border: `solid ${pxToRem('1px')}`,
        borderColor: styles.outerMenuBorderColor,
        borderRadius: `0 0 ${styles.outerMenuBorderRadius} ${styles.outerMenuBorderRadius}`,
        padding: `${styles.outerMenuBorderRadius} 0`,
        boxShadow: `0 ${pxToRem('2px')} ${pxToRem('4px')} 0 rgba(0, 0, 0, 0.2)`,
    }),

    menuList: provided => ({
        ...provided,
        maxHeight: pxToRem('540px'),
        padding: `0 ${pxToRem('5px')}`,
        boxSizing: 'border-box',
    }),
    noOptionsMessage: provided => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        height: pxToRem('58px'),
    }),
};

const AccountPickerWrapper = props => {
    const [selectedAccount, setSelectedAccount] = useSelectedAccount();
    const mode = useFormMode();
    const isDisabled = !!mode;
    return (
        <ConnectedAccountPicker
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            disabled={isDisabled}
            {...props}
        />
    );
};

class AccountPicker extends Component {
    state = {
        isOpen: false,
        inputValue: '',
        isRetry: false,
    };

    componentDidMount() {
        const { fetchMoreAccountsFlow, accounts, pageSize } = this.props;
        const { inputValue, isRetry } = this.state;

        if (accounts.length) {
            return;
        }

        fetchMoreAccountsFlow(
            inputValue,
            pageSize,
            isRetry,
            () => {},
            () => {}
        );
    }

    getOptions = inputValue =>
        new Promise((resolve, reject) => {
            const { fetchMoreAccountsFlow, pageSize } = this.props;
            const { isRetry } = this.state;
            const onSuccess = (accounts, hasMore) => resolve({ options: accounts, hasMore });
            const onError = error => {
                reject(error);
            };

            fetchMoreAccountsFlow(inputValue, pageSize, isRetry, onSuccess, onError);

            this.setState({ isRetry: false });
        });

    componentRef = React.createRef();

    handleToggle = () => {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
        }
    };

    handleClose = () => {
        const { resetAccountsAction } = this.props;
        const { inputValue } = this.state;

        if (inputValue) {
            resetAccountsAction();
        }

        this.setState({ isOpen: false });
    };

    handleSelectAccount = selectedAccount => {
        const { setSelectedAccount, selectedAccount: currentlySelectedAccount } = this.props;

        this.handleClose();
        if (selectedAccount.id !== currentlySelectedAccount.id) {
            setSelectedAccount(selectedAccount);
        }
    };

    handleRetry = () => {
        const { retryFetchAccountsAction } = this.props;

        this.setState({ isRetry: true });
        retryFetchAccountsAction();
    };

    handleInputChange = inputValue => {
        const { resetAccountsAction } = this.props;

        resetAccountsAction();
        this.setState({
            inputValue,
        });
    };

    hasMore() {
        const { accountsTotal, accounts } = this.props;

        return accountsTotal > accounts.length;
    }

    renderPicker = label => {
        const { selectedAccount, accounts, status, disabled } = this.props;
        const { isOpen, inputValue } = this.state;
        const pickerClassName = classNameBuilder('selected-account', { disabled });

        return (
            <div
                className={styles['container']}
                aria-label={label}
                data-automation="account-picker"
                ref={this.componentRef}
            >
                <div role="presentation" className={pickerClassName} onClick={this.handleToggle}>
                    <AccountPickerSelectedItem data={selectedAccount} className={styles['item-wrapper']} />
                    <div>
                        <DropdownIcon />
                    </div>
                </div>
                {isOpen ? (
                    <div className={styles['dropdown-container']}>
                        <AsyncDropdown
                            styles={accountPickerDefaultStyles}
                            getOptions={this.getOptions}
                            options={accounts}
                            onChange={this.handleSelectAccount}
                            valueRenderer={AccountPickerSelectedItem}
                            optionRenderer={AccountPickerMenuItem}
                            arrowRenderer={searchIconRenderer}
                            loadingMessageRenderer={AccountPickerLoader}
                            placeholder={placeholder}
                            noOptionsMessage={() => (
                                <FormattedMessage id="account.picker.noResults" defaultMessage="No results found..." />
                            )}
                            retryComponentRenderer={AccountPickerRetryMenuItem}
                            selectedValueObject={selectedAccount}
                            hasMore={this.hasMore()}
                            inputValue={inputValue}
                            onInputChange={this.handleInputChange}
                            error={status === COMPONENT_STATUS.ERROR}
                            onRetry={this.handleRetry}
                            autoFocus
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            tabSelectsValue={false}
                            menuIsOpen
                            loadOptionsOnMenuOpen={!!inputValue}
                        />
                        <BackgroundOverlay onOutsideClick={this.handleClose} foregroundElementRef={this.componentRef} />
                    </div>
                ) : null}
            </div>
        );
    };

    render() {
        return (
            <FormattedMessage id="account.picker.label" defaultMessage="Account Picker">
                {([label]) => this.renderPicker(label)}
            </FormattedMessage>
        );
    }
}

AccountPicker.propTypes = {
    accounts: PropTypes.array,
    selectedAccount: PropTypes.object,
    accountsTotal: PropTypes.number,
    pageSize: PropTypes.number,
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    fetchMoreAccountsFlow: PropTypes.func.isRequired,
    resetAccountsAction: PropTypes.func.isRequired,
    retryFetchAccountsAction: PropTypes.func.isRequired,
    setSelectedAccount: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

AccountPicker.defaultProps = {
    accounts: [],
    selectedAccount: {},
    pageSize: 10,
};

const mapStateToProps = state => {
    const { accountsTotal, fetchAccountsStatus: status } = state.appReducer;

    const accounts = accountsSelector(state);

    return {
        accounts,
        accountsTotal,
        status,
    };
};

const mapDispatchToProps = {
    fetchMoreAccountsFlow: fetchAccounts,
    resetAccountsAction: resetAccounts,
    retryFetchAccountsAction: retryFetchAccounts,
};

export { AccountPicker };
const ConnectedAccountPicker = connect(mapStateToProps, mapDispatchToProps)(AccountPicker);
export default AccountPickerWrapper;
