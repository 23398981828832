import { generatePath } from 'react-router';
import {
    ENTITY_PARAM_NAME,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_NAME,
    MODULE_PARAM_NAME,
} from '../../../../config/routes';
import { PAYMENT_TYPE } from '../PaymentType';
import { ACCOUNT_ID_PARAM_NAME } from './account';
import { PAYMENT_METHOD_TYPE_TO_PATH_MAP } from './entity';
import { MODULE_NAME } from './module';
import { PAYMENT_METHOD_ID_PARAM_NAME } from './paymentMethod';
import { EDITOR_ROUTE_PATH } from './routeTypes';

export const generatePaymentMethodFormPath = ({
    paymentMethodType = PAYMENT_TYPE.CARD,
    accountName,
    mode,
    paymentMethodId,
}) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = PAYMENT_METHOD_TYPE_TO_PATH_MAP[paymentMethodType];

    return generatePath(EDITOR_ROUTE_PATH, {
        [MODULE_PARAM_NAME]: MODULE_NAME,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountName,
        [PAYMENT_METHOD_ID_PARAM_NAME]: paymentMethodId,
    });
};
