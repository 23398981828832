import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { setRecommendations } from '../actions';
import getRecommendationUrl from '../utils/getRecommendationUrl';
import RECOMMENDATION_ACTION from '../utils/recommendationActions';

const budgetHandler =
    ({ history, recommendationMetadata, recommendationType, recommendationData }) =>
    (dispatch, getState) => {
        const { accountId } = selectedAccountSelector(getState());
        const { campaignId, recommendationId } = recommendationMetadata;
        const destinationPath = getRecommendationUrl(RECOMMENDATION_ACTION.UPDATE_CAMPAIGN, accountId, campaignId);

        dispatch(setRecommendations({ recommendationType, recommendationId, recommendationData }));
        dispatch(navigate(history, destinationPath));
    };

export default budgetHandler;
