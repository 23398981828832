import React, { useMemo } from 'react';
import { useStripeCardContext } from '../../../../taboola-common-frontend-modules/stripe/StripeCardContext';
import { PaymentContextProvider } from '../PaymentProvider/PaymentContext';

export const StripeCardPaymentProvider = ({ children }) => {
    const { isStripeValid, setupCard } = useStripeCardContext();

    const paymentContext = useMemo(
        () => ({
            isPaymentMethodValid: isStripeValid,
            generatePaymentMethodToken: setupCard,
        }),
        [isStripeValid, setupCard]
    );

    return <PaymentContextProvider value={paymentContext}>{children}</PaymentContextProvider>;
};
