import React, { useCallback } from 'react';
import { DeletableItem, useCollapsibleListContext } from 'taboola-ultimate-ui';
import styles from './campaignGroupListItem.module.scss';

export const CampaignGroupListItem = ({ item }) => {
    const { deleteItem } = useCollapsibleListContext();
    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);

    return (
        <div className={styles['container']}>
            <DeletableItem contentClassName={styles['content']} onDelete={onDelete}>
                {item.name}
            </DeletableItem>
        </div>
    );
};
