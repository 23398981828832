import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl, FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { UrlValidatedInput } from '../../../../creative-creator/components/UrlList/UrlValidatedInput';
import styles from './vastTagField.module.scss';

export const VASTTagField = () => {
    const { formatMessage } = useIntl();

    return (
        <FormField
            containerClass={styles['input']}
            label={formatMessage({ id: 'video.creative.creator.setup.vast.title' })}
            description={formatMessage({ id: 'video.creative.creator.setup.vast.description' })}
            // TODO: get from product the help text for this
            helpText={<FormattedMessage id="video.creative.creator.setup.vast.helptext" />}
        >
            <UrlValidatedInput
                className={styles['editable-text']}
                field="vastTagUrl"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="vastUrl"
                isResetFieldToInitialOnUnmount={true}
            />
        </FormField>
    );
};
