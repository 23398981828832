import React from 'react';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import FirstPartyAudiencePropertiesSection from '../components/FirstPartyAudiencePropertiesSection/FirstPartyAudiencePropertiesSection';
import FirstPartyAudienceSetupSection from '../components/FirstPartyAudienceSetupSection/FirstPartyAudienceSetupSection';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const FirstPartyAudiencesEditorPage = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = useAudienceEditorState({ reportType: REPORT_TYPE.FIRST_PARTY });

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="AudienceEditor"
            >
                <FirstPartyAudienceSetupSection />
                <div id="audience-properties">
                    <FirstPartyAudiencePropertiesSection />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const FirstPartyAudiencesEditorPageWithFormDataProvider = withFormDataProvider(FirstPartyAudiencesEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { FirstPartyAudiencesEditorPageWithFormDataProvider as FirstPartyAudiencesEditorPage };
