import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getDisabledFilterTypes } from '../../../utils/queryFilterUtils';
import { AddFilterButton } from '../AddFilterButton/AddFilterButton';
import { FilterUnit } from '../FilterUnit/FilterUnit';
import styles from './filtersToolbar.module.scss';

const useDisabledTypes = (filters, reportConfig) =>
    useMemo(() => {
        const selectedTypes = filters.map(({ type }) => type);
        const customRestrictedFilters = reportConfig?.customRestrictedFilters;

        const allDisabledTypes = getDisabledFilterTypes(selectedTypes, customRestrictedFilters);
        const disabledTypesEditMap = {};
        selectedTypes.forEach(selectedType => {
            const remainingTypes = selectedTypes.filter(type => type !== selectedType);
            disabledTypesEditMap[selectedType] = getDisabledFilterTypes(remainingTypes, customRestrictedFilters);
        });

        return [allDisabledTypes, disabledTypesEditMap];
    }, [filters, reportConfig]);

export const FiltersToolbar = ({
    filters,
    addFilter,
    updateFilter,
    removeFilter,
    editingFilterIndex,
    setEditingFilterIndex,
    stopEditing,
    isAddingNewFilter,
    startAddingNewFilter,
    stopAddingNewFilter,
    availableFilters,
    getValueOptions,
    getApplyBtnMetricsAttrs,
    reportConfig,
}) => {
    const availableTypes = useMemo(() => availableFilters.map(({ id }) => id), [availableFilters]);
    const multiSelectFilters = useMemo(() => {
        const filterTypes = availableFilters.filter(({ multiSelect }) => multiSelect).map(({ id }) => id);
        return new Set(filterTypes);
    }, [availableFilters]);
    const isMultiSelectFilter = useCallback(type => multiSelectFilters.has(type), [multiSelectFilters]);
    const [allDisabledTypes, disabledTypesEditMap] = useDisabledTypes(filters, reportConfig);
    const unusedTypes = availableTypes.filter(type => !allDisabledTypes.has(type));

    const handleAddNewFilter = () => {
        stopEditing();
        startAddingNewFilter();
    };

    const filterUnits = filters.map((filter, index) => {
        const { type } = filter;
        const isEditing = editingFilterIndex === index;
        const handleEditFilter = () => {
            stopAddingNewFilter();
            setEditingFilterIndex(index);
        };
        const handleUpdateFilter = updatedFilter => {
            updateFilter(index, updatedFilter);
            stopEditing();
        };
        const handleRemoveFilter = () => removeFilter(filter);

        return (
            <FilterUnit
                key={type}
                isOpen={isEditing}
                closeFilter={stopEditing}
                openFilter={handleEditFilter}
                filter={filter}
                onRemoveFilter={handleRemoveFilter}
                onUpdateFilter={handleUpdateFilter}
                availableTypes={availableTypes}
                disabledTypes={disabledTypesEditMap[type]}
                getValueOptions={getValueOptions}
                isMultiSelectFilter={isMultiSelectFilter}
                getApplyBtnMetricsAttrs={getApplyBtnMetricsAttrs}
            />
        );
    });

    return (
        <div className={styles['container']}>
            {filterUnits}
            {isAddingNewFilter ? (
                <FilterUnit
                    isOpen
                    closeFilter={stopAddingNewFilter}
                    onUpdateFilter={addFilter}
                    availableTypes={availableTypes}
                    disabledTypes={allDisabledTypes}
                    getValueOptions={getValueOptions}
                    isMultiSelectFilter={isMultiSelectFilter}
                    getApplyBtnMetricsAttrs={getApplyBtnMetricsAttrs}
                />
            ) : (
                <AddFilterButton
                    aria-label="Add new filter"
                    disabled={unusedTypes.length === 0}
                    onClick={handleAddNewFilter}
                />
            )}
        </div>
    );
};

FiltersToolbar.propTypes = {
    /** filters and functions to modify filters */
    filters: PropTypes.array,
    addFilter: PropTypes.func,
    updateFilter: PropTypes.func,
    removeFilter: PropTypes.func,

    /** the filter index being edited and functions to set the index */
    editingFilterIndex: PropTypes.number,
    setEditingFilterIndex: PropTypes.func,
    stopEditing: PropTypes.func,

    /** flag and helper functions to show if the user is creating a new filter */
    isAddingNewFilter: PropTypes.bool,
    startAddingNewFilter: PropTypes.func,
    stopAddingNewFilter: PropTypes.func,

    /** array of all types of filters that are applicable to the report */
    availableTypes: PropTypes.array,
    /** set of all types of filters that cannot be removed */
    requiredTypesSet: PropTypes.object,
    /** function passed into BaseFilterUnit to get value options */
    getValueOptions: PropTypes.func,
    /** return value is spread into apply button for metrics */
    getApplyBtnMetricsAttrs: PropTypes.func,
};
