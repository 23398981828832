import { useCallback, useState } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SegmentNodeContent } from '../../../campaigns/modules/common-campaign-form/components/MarketplaceAudiencesWithBundling/SegmentNodeContent/SegmentNodeContent';
import { Tree } from '../../../campaigns/modules/common-campaign-form/components/Tree';
import LoadMoreNode from '../../../campaigns/modules/common-campaign-form/components/Tree/LoadMoreNode/LoadMoreNode';
import SelectAll from '../../../campaigns/modules/common-campaign-form/components/Tree/SelectAll/SelectAll';
import { useCombinedAudiencesContext } from './CombinedAudiencesContext';
import styles from './combinedSegmentsTreeWithBundling.module.scss';

const classNameBuilder = classnames.bind(styles);

const getIsNodeSelectable = ({ node }) => {
    const { isTargetable } = node;
    return isTargetable;
};

export const CombinedSegmentsTreeWithBundling = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        nodesTree,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        collapsedPathMap,
        totalCount,
        handleCollapseChange,
        handleLoadMore,
        limit,
        getIsNodeBundled,
        unbundleNode,
    } = useCombinedAudiencesContext();
    const [isAllChecked, setIsAllChecked] = useState(false);

    const handleSelectAllWrapper = useCallback(() => {
        handleSelectAll(!isAllChecked);
        setIsAllChecked(prev => !prev);
    }, [handleSelectAll, isAllChecked]);

    const renderSelectAll = useCallback(
        () =>
            isSearchMode ? (
                <SelectAll onClick={handleSelectAllWrapper} disabled={totalCount > limit}>
                    <FormattedMessage
                        id={`campaign.editor.marketplace.audiences.${isAllChecked ? 'deselectAll' : 'selectAll'}.title`}
                        defaultMessage="Select all {totalItems} results (limited to {limit})"
                        values={{
                            totalItems: totalCount,
                            limit,
                        }}
                    />
                </SelectAll>
            ) : null,
        [isSearchMode, handleSelectAllWrapper, totalCount, limit, isAllChecked]
    );

    const getLabelClassName = useCallback(
        ({ node, hasChildren }) => {
            const { loading } = node;
            const isBundled = getIsNodeBundled(node, hasChildren);

            return classNameBuilder('label', {
                loading,
                bundled: isBundled,
            });
        },
        [getIsNodeBundled]
    );

    const renderNodeText = useCallback(
        (domProps, nodeProps) => {
            const { hasMore, id } = nodeProps.node;
            if (hasMore) {
                const { path } = nodeProps.parent;
                return (
                    <LoadMoreNode
                        key={id}
                        onClick={() => handleLoadMore(path)}
                        title={<FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />}
                    />
                );
            }
            const { hasChildren, node } = nodeProps;
            const isBundled = getIsNodeBundled(node, hasChildren);

            return (
                <SegmentNodeContent
                    searchText={search}
                    nodeProps={nodeProps}
                    isBundled={isBundled}
                    unbundleNode={unbundleNode}
                />
            );
        },
        [getIsNodeBundled, handleLoadMore, search, unbundleNode]
    );

    return (
        <div className={styles['container']} ref={innerRef}>
            <Tree
                nodesTree={nodesTree}
                onSelect={handleSelectNode}
                selectedItemsMap={selectedPathsMap}
                totalItems={totalCount}
                collapsed={collapsedPathMap}
                onCollapsedChange={handleCollapseChange}
                renderNodeText={renderNodeText}
                onLoadMore={handleLoadMore}
                renderSelectAll={renderSelectAll}
                noOptionsContent={<FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />}
                loadMoreContent={<FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />}
                loadingMode={loadingMode}
                needRenderCheckbox={getIsNodeSelectable}
                getIsNodeSelectable={getIsNodeSelectable}
                labelClassName={getLabelClassName}
                checkNodesRecursive={false}
            />
        </div>
    );
};

CombinedSegmentsTreeWithBundling.propTypes = {
    innerRef: PropTypes.func,
};
