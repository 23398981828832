import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedValue } from 'taboola-ultimate-ui';
import { pushRBox, pushTRecs } from '../../../../../taboola-common-frontend-modules/rbox-loader';
import { pushRBoxMode } from '../../../../../taboola-common-frontend-modules/rbox-loader/utils';

const DEFAULT_DELAY = 100;

const PreviewPlacements = ({ placements, placementKey, delay }) => {
    const debouncedPlacementKey = useDebouncedValue(placementKey, delay);

    useEffect(() => {
        reloadPlacements({ placements });
    }, [debouncedPlacementKey, placements]);

    return placements.map(placement => {
        const { containerId } = placement;
        const key = `${debouncedPlacementKey}${containerId}`;
        return <div key={key} id={containerId} />;
    });
};

const reloadPlacements = ({ placements }) => {
    const { location } = document;
    const { origin, pathname } = location;

    pushTRecs({ notify: 'newPageLoad' });
    placements.forEach(placement => {
        const { mode, containerId, placementName } = placement;
        pushRBoxMode(mode, containerId, placementName);
    });
    pushRBox(
        { overrideConfig: { global: { 'enable-item-override': true } } },
        { article: 'auto' },
        { url: `${origin}${pathname}` },
        { flush: true }
    );
};

PreviewPlacements.propTypes = {
    placements: PropTypes.arrayOf(
        PropTypes.shape({
            containerId: PropTypes.string,
            placementName: PropTypes.string,
            mode: PropTypes.string,
        })
    ),
    placementKey: PropTypes.string,
    delay: PropTypes.number,
};

PreviewPlacements.defaultProps = {
    delay: DEFAULT_DELAY,
};

export default PreviewPlacements;
