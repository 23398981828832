'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.BaseBreadcrumbs = undefined;

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Icons = require('../Icons/Icons');

var _hoc = require('../hoc');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'breadcrumbs__taboola-svg-icon___K90wD',
    'breadcrumbs': 'breadcrumbs__breadcrumbs___2RvTF',
    'icon': 'breadcrumbs__icon___3P3OD',
    'breadcrumb': 'breadcrumbs__breadcrumb___3xS2S',
    'disabled': 'breadcrumbs__disabled___Ap6uA'
};


var ELLIPSIS = '...';
var ELLIPSIS_LENGTH = 3;

var classNameBuilder = _bind2.default.bind(styles);

var AnchorBreadcrumb = function AnchorBreadcrumb(_ref) {
    var className = _ref.className,
        disabledClassName = _ref.disabledClassName,
        propsOnClick = _ref.onClick,
        href = _ref.href,
        children = _ref.children,
        rest = _objectWithoutProperties(_ref, ['className', 'disabledClassName', 'onClick', 'href', 'children']);

    var onClick = function onClick(e) {
        if (propsOnClick) {
            e.preventDefault();
            propsOnClick();
        }
    };
    var isDisabled = !propsOnClick && !href;

    return _react2.default.createElement(
        'a',
        Object.assign({ href: href || '#', onClick: onClick, className: isDisabled ? disabledClassName : className }, rest),
        children
    );
};

AnchorBreadcrumb.propTypes = {
    className: _propTypes2.default.string,
    disabledClassName: _propTypes2.default.string,
    onClick: _propTypes2.default.func,
    href: _propTypes2.default.string,
    children: _propTypes2.default.node
};

var Breadcrumb = function Breadcrumb(_ref2) {
    var label = _ref2.label,
        maxCharacterCount = _ref2.maxCharacterCount,
        DisplayComponent = _ref2.DisplayComponent,
        children = _ref2.children,
        rest = _objectWithoutProperties(_ref2, ['label', 'maxCharacterCount', 'DisplayComponent', 'children']);

    var displayLabel = maxCharacterCount && label.length > maxCharacterCount ? '' + label.substring(0, maxCharacterCount - ELLIPSIS_LENGTH) + ELLIPSIS : label;

    return _react2.default.createElement(
        DisplayComponent,
        Object.assign({
            className: styles['breadcrumb'],
            disabledClassName: classNameBuilder('breadcrumb', 'disabled')
        }, rest),
        displayLabel || children
    );
};

Breadcrumb.propTypes = {
    label: _propTypes2.default.string,
    // the maximum characters that will be displayed in a segment, including
    // three ellipsis characters which will be added if label length exceeds this value
    maxCharacterCount: _propTypes2.default.number,
    DisplayComponent: _propTypes2.default.func,
    children: _propTypes2.default.node
};

Breadcrumb.defaultProps = {
    label: '',
    DisplayComponent: AnchorBreadcrumb
};

var BaseBreadcrumbs = function BaseBreadcrumbs(_ref3) {
    var children = _ref3.children;
    return _react2.default.createElement(
        'div',
        { className: styles.breadcrumbs },
        children.map(function (crumb, idx) {
            return _react2.default.createElement(
                _react.Fragment,
                { key: crumb.key || idx },
                crumb,
                idx + 1 < children.length ? _react2.default.createElement(_Icons.RightIcon, { className: styles['icon'] }) : null
            );
        })
    );
};

BaseBreadcrumbs.propTypes = {
    children: _propTypes2.default.node
};

var Breadcrumbs = (0, _hoc.withItemsAsChildren)(Breadcrumb, 'steps')(BaseBreadcrumbs);

Breadcrumbs.Breadcrumb = Breadcrumb;

exports.BaseBreadcrumbs = BaseBreadcrumbs;
exports.default = Breadcrumbs;