/* eslint-disable react/no-unused-state */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { batch } from 'react-redux';
import PropTypes from 'prop-types';
import { SearchInput, SearchIcon, withStylesheet, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { useCurrentValueGetter, useEventValueHandler } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { EMPTY_MESSAGE_ID, FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useSearchTerm } from '../../hooks';
import styles from './searchBar.module.scss';

const MAX_LENGTH = 200;

const StyledInput = withStylesheet(
    SearchInput.defaultInput,
    styles,
    {
        [SearchInput.defaultInput.classNames.inputWrapper]: 'search-input',
        [SearchInput.defaultInput.classNames.input]: ['filter-input', 'input-prioritized', 'input-high-prioritized'],
    },
    'StyledInput'
);

const StyledSearchInput = withStylesheet(SearchInput, styles, {
    [SearchInput.classNames.inputIcon]: ['filter-icon', 'icon-prioritized'],
    [SearchInput.classNames.inputContainer]: 'search-input-wrapper',
    [SearchInput.classNames.open]: 'open',
});

const SearchBar = ({
    isSearchHidden: propsIsSearchHidden,
    campaignId,
    accountId,
    selectedReport,
    searchPlaceholderMsgId,
}) => {
    const [searchTerm = '', setSearchTerm, unsetSearchTerm] = useSearchTerm();

    const [inputValue, setInputValue] = useState(searchTerm);
    const [isSearchHidden, setIsSearchHidden] = useState(propsIsSearchHidden);
    const [searchElementKey, setSearchElementKey] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const mountRef = useRef();
    const inputValueGetter = useCurrentValueGetter(inputValue);

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: searchPlaceholderMsgId,
        defaultMessage: '',
    });

    //We clear search term on every new account/report/campaign selection,
    useEffect(() => {
        if (!mountRef.current) {
            mountRef.current = true;
            return;
        }

        batch(() => {
            setSearchElementKey(accountId + selectedReport + campaignId);
        });
    }, [selectedReport, accountId, campaignId]);

    //We show/hide search bar as soon as new report config ready
    useEffect(() => {
        setIsSearchHidden(propsIsSearchHidden);
    }, [propsIsSearchHidden]);

    // we change input value if we have new value in url
    useEffect(() => {
        if (searchTerm !== inputValueGetter()) {
            setInputValue(searchTerm);
        }
    }, [searchTerm, inputValueGetter]);

    const handleSearchTermChange = useEventValueHandler(setInputValue);

    const onSubmit = useCallback(
        ({ target }) => {
            const { value } = target;
            if (value) {
                setSearchTerm(value.trim());
            } else {
                unsetSearchTerm();
            }
        },
        [setSearchTerm, unsetSearchTerm]
    );

    const onFocus = () => setIsFocused(true);
    const onBlur = () => {
        const isSearchFilled = inputValue.length !== 0;
        setIsFocused(isSearchFilled);
    };

    if (isSearchHidden) {
        return null;
    }

    return (
        <div
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="SearchInput"
            className={styles['search']}
        >
            <StyledSearchInput
                key={searchElementKey}
                id="grid-quick-filter"
                type="text"
                onSubmit={onSubmit}
                onChange={handleSearchTermChange}
                placeholder={placeholder}
                value={inputValue}
                expandableIcon={<SearchIcon />}
                InputComponent={StyledInput}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={MAX_LENGTH}
            />

            {!isFocused ? (
                <Tooltip arrow interactive position={TOOLTIP_POSITION.BOTTOM_START}>
                    <FormattedMessage id="app.gridToolbar.searchButton.tooltip" defaultMessage="Search Table" />
                </Tooltip>
            ) : null}
        </div>
    );
};

SearchBar.propTypes = {
    /** Current selected report */
    selectedReport: PropTypes.string,
    /** If should show the search input */
    isSearchHidden: PropTypes.bool,
    /** Search placeholder message id */
    searchPlaceholderMsgId: PropTypes.string,
    /** Campaign id */
    campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Account id */
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SearchBar.defaultProps = {
    isSearchHidden: true,
    searchPlaceholderMsgId: EMPTY_MESSAGE_ID,
};

export default SearchBar;
