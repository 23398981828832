import { creativesApi } from 'services/api';
import { requestFetchMediaLibraryItems, fetchMediaLibraryItemsSuccess, fetchMediaLibraryItemsError } from '../actions';
import { GALLERY_ITEM_SOURCES } from '../components/MediaTabs/consts';
import { transformMediaResponseToMediaItems } from '../utils/fetchedMediaTransformationUtils';

const ONE_MINUTE = 60 * 1000;

const fetchMediaLibraryItems =
    ({ accountId, page, pageSize, contentType, queryClient }) =>
    async dispatch => {
        dispatch(requestFetchMediaLibraryItems());

        try {
            const { results: response, metadata: { total } = {} } = await queryClient.fetchQuery({
                queryKey: ['fetchMediaLibraryItems', { accountId, page, pageSize, contentType }],
                queryFn: () => creativesApi.getRecentCreativesMedia({ accountId, page, pageSize, contentType }),
                cacheTime: ONE_MINUTE,
                staleTime: ONE_MINUTE,
            });

            const results = transformMediaResponseToMediaItems(response, GALLERY_ITEM_SOURCES.MEDIA_LIBRARY);

            dispatch(fetchMediaLibraryItemsSuccess(results));

            return [results, total];
        } catch (error) {
            dispatch(fetchMediaLibraryItemsError(error));
            return [error];
        }
    };

export default fetchMediaLibraryItems;
