'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var styles = {
  'rootFontSize': '10px',
  'fontSizeXSmall': '0.8rem',
  'fontSizeSmaller': '1.1rem',
  'fontSizeSmall': '1.2rem',
  'fontSizeMediumSmall': '1.3rem',
  'fontSizeMedium': '1.4rem',
  'fontSizeLarge': '1.6rem',
  'fontSizeLarger': '1.8rem',
  'fontSizeXLarge': '2rem',
  'fontSizeXxLarge': '3.2rem',
  'maxWidthMobile': '480px'
};


var pxToRem = function pxToRem(pixels) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : styles.rootFontSize;
  return parseInt(pixels, 10) / parseInt(context, 10) + 'rem';
};

var remToPx = exports.remToPx = function remToPx(rems) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : styles.rootFontSize;
  return parseFloat(rems, 10) * parseInt(context, 10) + 'px';
};

exports.default = pxToRem;