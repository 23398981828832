import { generatePath } from 'react-router';
import { ENTITY_PARAM_NAME, FORM_MODE_TO_PATH_MAP, MODE_PARAM_NAME, MODULE_PARAM_NAME } from 'config/routes';
import {
    ENTITY_TO_PATH_MAP,
    MODULE_NAME,
    ACCOUNT_PARAM_NAME,
    PERFORMANCE_RULES_ID_PARAM,
} from 'modules/performance-rules/config';

export const generatePerformanceRulesFormPath = (
    pathTemplate,
    { module = MODULE_NAME, entity, mode, accountName, ruleId }
) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    const path = generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_PARAM_NAME]: accountName,
        [PERFORMANCE_RULES_ID_PARAM]: ruleId,
    });
    return path;
};
