import React from 'react';
import PropTypes from 'prop-types';
import { useComfortableViewAvailable } from '../../hooks/useComfortableViewAvailable';
import { ReportPicker } from '../ReportPicker/ReportPicker';
import ReportPresetPicker from '../ReportPresetPicker/ReportPresetPicker';
import SearchBar from '../SearchBar/SearchBar';
import { ComfortableViewToggle } from './ComfortableViewToggle/ComfortableViewToggle';
import CreateButton from './CreateButton/CreateButton';
import ExportDropdown from './ExportDropdown';
import FilterButton from './FilterButton/FilterButton';
import { GraphToggle } from './GraphToggle/GraphToggle';
import RefreshButton from './RefreshButton/RefreshButton';
import styles from './gridToolbar.module.scss';

const GridToolbar = ({
    selectedReport,
    className,
    reportDuration,
    csvOptionsProvider,
    isSearchHidden,
    campaignId,
    accountId,
    searchPlaceholderMsgId,
    reportConfig,
    reportConfigMap,
    forceFetchFlag,
}) => {
    const comfortableViewAvailable = useComfortableViewAvailable(reportConfig);
    const { disableExport, disableForceFetch } = reportConfig;

    return (
        <div className={`${styles['container']} ${className}`}>
            <ReportPicker
                selectedValue={selectedReport}
                reportDuration={reportDuration}
                reportConfigMap={reportConfigMap}
            />
            <div className={styles['controls']}>
                {!disableForceFetch && <RefreshButton lastUpdateTime={forceFetchFlag} />}
                {comfortableViewAvailable && <ComfortableViewToggle />}
                <GraphToggle reportConfig={reportConfig} />
                <SearchBar
                    isSearchHidden={isSearchHidden}
                    campaignId={campaignId}
                    accountId={accountId}
                    selectedReport={selectedReport}
                    searchPlaceholderMsgId={searchPlaceholderMsgId}
                />
                <FilterButton reportConfig={reportConfig} />
                {!disableExport && <ExportDropdown csvOptionsProvider={csvOptionsProvider} />}
                <ReportPresetPicker reportConfig={reportConfig} />
                <CreateButton />
            </div>
        </div>
    );
};

GridToolbar.propTypes = {
    /** Current selected report */
    selectedReport: PropTypes.string,
    /** Current selected report columns view */
    selectedColumns: PropTypes.string,
    /** Optional class name */
    className: PropTypes.string,
    /** The report duration */
    reportDuration: PropTypes.number,
    /** If should show the search input */
    isSearchHidden: PropTypes.bool,
    /** Search placeholder message id */
    searchPlaceholderMsgId: PropTypes.string,
    /** gets the options and setting for the csv exporter */
    csvOptionsProvider: PropTypes.func,
    /** Campaign id */
    campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Account id */
    accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

GridToolbar.defaultProps = {
    className: '',
    reportDuration: 0,
    isSearchHidden: false,
};

export default GridToolbar;
