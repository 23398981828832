import { useMemo } from 'react';
import { filter } from 'lodash';
import { useCombinedAudienceIngredientsTargetingCollection } from './useCombinedAudienceIngredientsTargetingCollection';

// Given the index of this targeting collection group, return the segment ids that should be hidden for this group
export const useCombinedAudienceIngredientsHiddenIdsSet = index => {
    const { combinedAudienceIngredientsTargetingCollection } = useCombinedAudienceIngredientsTargetingCollection();

    const hiddenIdsSet = useMemo(() => {
        const otherTargetings = filter(
            combinedAudienceIngredientsTargetingCollection,
            (_, otherIndex) => otherIndex !== index
        );

        const otherTargetedIdsList = otherTargetings.map(({ include, exclude }) => [].map(id => id.toString())).flat();

        return new Set(otherTargetedIdsList);
    }, [index, combinedAudienceIngredientsTargetingCollection]);

    return hiddenIdsSet;
};
