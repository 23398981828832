import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import ImagePixelCodeSnippet from './ImagePixelCodeSnippet';
import styles from '../PixelBasedEventJSTag/pixelBasedEventJSTagField.module.scss';

export const PixelBasedEventImageTagField = () => {
    return (
        <FormField
            inputId="pixel-based-event-image-tag-description"
            label={
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.events.code.image.tag.description.label"
                    defaultMessage="Image Pixel Code"
                />
            }
            description={
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.events.code.image.tag.description"
                    defaultMessage="If you have limitations preventing you from you to implement the JavaScript pixel you can implement the image pixel. {br} We do no recommend you to edit or modify the code. Any changes in the code could cause the pixel not to work properly."
                />
            }
            descriptionClass={styles['code-event-description']}
            subtext={
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.events.code.section.snippet.code.subDescription"
                    defaultMessage="It may take up to 20 minutes for your pixel status to change after installing it. {br} You can always go to the Help Center to learn more about pixel management."
                />
            }
        >
            <ImagePixelCodeSnippet />
        </FormField>
    );
};

export default PixelBasedEventImageTagField;
