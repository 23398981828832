import { useCallback } from 'react';
import { isUndefined } from 'lodash';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { PRICING_MODEL } from '../../../../../config';
import { BID_STRATEGIES } from '../../../config';
import { useCpaGoalFormFieldValue } from '../../CpaGoal/useCpaGoalFormFieldValue';
import { useIsApplyCapActiveField } from '../ApplyCapSection/useIsApplyCapActiveField';
import { getBidCacheKey, getBidStrategyCacheKey, useBidStrategyCacheContext } from '../BidStrategyCacheProvider';
import { bidStrategiesCategory, categoryToStrategyMap, strategyToCategoryMap } from './useBidStrategiesOptions';

export const useBidStrategyCategoryState = ({ bidStrategy, onChangeBidStrategy, availableBidStrategies }) => {
    const { bidStrategyCache, pricingModelCache, bidCache, targetCpaCache, cpcCapCache, isApplyCapActiveCache } =
        useBidStrategyCacheContext();
    const { onChange: onPricingModelChange } = useFormFieldValue({
        field: 'pricingModel',
    });
    const { onChange: onChangeBid, resetFieldToInitial: resetBid } = useFormFieldValue({ field: 'bid' });
    const { onChange: onChangeCpaGoal, resetFieldToInitial: resetTargetCpa } = useCpaGoalFormFieldValue();
    const { onChange: onChangeCpcCap, resetFieldToInitial: resetCpcCap } = useFormFieldValue({
        field: 'cpcCap',
    });
    const {
        value: isApplyCapActive,
        onChange: setIsApplyCapActive,
        resetFieldToInitial: resetIsApplyCapActive,
    } = useIsApplyCapActiveField();
    const getDefaultStrategyForCategory = useCallback(
        bidStrategy => {
            if (bidStrategy !== bidStrategiesCategory.MAX_CONVERSIONS) {
                return categoryToStrategyMap[bidStrategy];
            }

            return availableBidStrategies[BID_STRATEGIES.MAX_CONVERSIONS]
                ? BID_STRATEGIES.MAX_CONVERSIONS
                : BID_STRATEGIES.TARGET_CPA;
        },
        [availableBidStrategies]
    );

    const onChangeStrategyCategory = useCallback(
        ({ value: bidStrategyCategory }) => {
            const calculatedPricingModel = pricingModelCache[bidStrategyCategory]
                ? pricingModelCache[bidStrategyCategory]
                : PRICING_MODEL.CPC;
            onPricingModelChange(calculatedPricingModel);

            const cachedIsApplyCapActive =
                bidStrategyCategory === bidStrategiesCategory.MAX_CONVERSIONS
                    ? isApplyCapActiveCache.isApplyCapActive
                    : undefined;
            if (!isUndefined(cachedIsApplyCapActive)) {
                setIsApplyCapActive(cachedIsApplyCapActive);
            } else {
                resetIsApplyCapActive();
            }
            const calculatedIsApplyCapActive =
                bidStrategyCategory === bidStrategiesCategory.MAX_CONVERSIONS
                    ? cachedIsApplyCapActive ?? isApplyCapActive
                    : undefined;

            const cachedBidStrategy =
                bidStrategyCache[
                    getBidStrategyCacheKey({
                        bidStrategyCategory,
                        pricingModel: calculatedPricingModel,
                        isApplyCapActive: calculatedIsApplyCapActive,
                    })
                ];
            const calculatedBidStrategy = cachedBidStrategy
                ? cachedBidStrategy
                : getDefaultStrategyForCategory(bidStrategyCategory);
            onChangeBidStrategy(calculatedBidStrategy);

            const cachedBid =
                bidCache[
                    getBidCacheKey({
                        bidStrategyCategory,
                        pricingModel: calculatedPricingModel,
                        bidStrategy: calculatedBidStrategy,
                    })
                ];
            if (cachedBid) {
                onChangeBid(cachedBid);
            } else {
                resetBid();
            }

            const cachedTargetCpa =
                calculatedBidStrategy === BID_STRATEGIES.TARGET_CPA ? targetCpaCache.targetCpa : undefined;
            if (cachedTargetCpa) {
                onChangeCpaGoal(cachedTargetCpa);
            } else {
                resetTargetCpa();
            }

            const cachedCpcCap =
                calculatedBidStrategy === BID_STRATEGIES.MAX_CONVERSIONS && calculatedIsApplyCapActive
                    ? cpcCapCache.cpcCap
                    : undefined;
            if (cachedCpcCap) {
                onChangeCpcCap(cachedCpcCap);
            } else {
                resetCpcCap();
            }
        },
        [
            pricingModelCache,
            onPricingModelChange,
            isApplyCapActiveCache.isApplyCapActive,
            isApplyCapActive,
            bidStrategyCache,
            getDefaultStrategyForCategory,
            onChangeBidStrategy,
            bidCache,
            targetCpaCache.targetCpa,
            cpcCapCache.cpcCap,
            setIsApplyCapActive,
            resetIsApplyCapActive,
            onChangeBid,
            resetBid,
            onChangeCpaGoal,
            resetTargetCpa,
            onChangeCpcCap,
            resetCpcCap,
        ]
    );

    return {
        bidStrategyCategory: strategyToCategoryMap[bidStrategy],
        onChangeStrategyCategory,
    };
};
