import React from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import { DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../../config';
import BidAdjustmentsTooltip from './BidAdjustmentsTooltip';
import styles from './bidAdjustmentsSetting.module.scss';

const BidAdjustmentsSetting = () => {
    return (
        <TextCheckbox
            helpText={<BidAdjustmentsTooltip />}
            value={DUPLICATE_CAMPAIGN_SETTINGS_TYPE.BID_ADJUSTMENTS}
            title={
                <FormattedMessage
                    id="campaign.editor.duplicateCampaignSettings.bidAdjustments"
                    defaultMessage="Bid Adjustments"
                />
            }
            labelClassName={styles['label']}
        />
    );
};

BidAdjustmentsSetting.propTypes = {
    checked: PropTypes.bool,
};

export default BidAdjustmentsSetting;
