import React from 'react';
import PropTypes from 'prop-types';
import { useAvailableItems } from 'hooks';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DeleteButton } from '../../../../components/ContentEditableText/components/DeleteButton';
import { SAFETY_NETS_STATUS, SITE_EDITOR_TYPE, SPEND_GUARDS_ACTION_TYPE } from '../../../../config/siteConsts';
import { SafetyNetsStatusActionDetails } from '../SafetyNetsStatusActionDetails/SafetyNetsStatusActionDetails';
import RadioGroupCellEditor from './RadioGroupCellEditor';
import { SafetyNetsStatusCellEditorTooltip } from './SafetyNetsStatusCellEditorTooltip';
import styles from './safetyNetsStatusCellEditor.module.scss';

// TODO DEV-143525
const SpendCapOptions = [
    {
        radioValue: SAFETY_NETS_STATUS.CAPPING_ENABLED,
        title: (
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.CAPPING_ENABLED"
                defaultMessage="Enable SpendGuard"
            />
        ),
    },
    {
        radioValue: SAFETY_NETS_STATUS.CAPPING_DISABLED,
        title: (
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.CAPPING_DISABLED"
                defaultMessage="Disable SpendGuard"
            />
        ),
    },
];
const SpendCapOptionsWithSpendGuards = [
    {
        radioValue: SAFETY_NETS_STATUS.CAPPING_ENABLED,
        title: (
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.CAPPING_ENABLED"
                defaultMessage="Enable SpendGuard"
            />
        ),
        isDisabled: true,
        disableMessage: (
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.hover.message"
                defaultMessage="SpendGuard is disabled for this campaign and cannot be enabled for a specific site.
You can enable SpendGuard for the campaign via the campaign settings."
            />
        ),
    },
    {
        radioValue: SAFETY_NETS_STATUS.SPEND_GUARDS_DISABLED,
        title: (
            <FormattedMessage
                id="app.campaigns.sites.safety.nets.status.SPEND_GUARDS_DISABLED"
                defaultMessage="Disable SpendGuard"
            />
        ),
    },
];

const SpendGuardTitle = ({ isSiteBlockedBySpendGuard, spendGuardActionType }) => {
    return (
        <div>
            <span className={styles['title']}>
                <FormattedMessage
                    id={`app.campaigns.sites.safety.nets.status.action.details.title.${spendGuardActionType}`}
                    defaultMessage="Blocked by SpendGuard"
                />
                {!isSiteBlockedBySpendGuard && <SafetyNetsStatusCellEditorTooltip />}
            </span>
        </div>
    );
};

const BlockDetails = ({ details, currency }) => {
    const { siteDescription, spendGuardsActionReason, spendGuardsActionReasonParams, spendGuardsActionTimeInUtc } =
        details;

    return (
        <div className={styles['description']}>
            <div className={styles['rectangle']} />
            <SafetyNetsStatusActionDetails
                siteDescription={siteDescription}
                spendGuardsActionReason={spendGuardsActionReason}
                spendGuardsActionReasonParams={spendGuardsActionReasonParams}
                spendGuardsActionTimeInUtc={spendGuardsActionTimeInUtc}
                currency={currency}
            />
        </div>
    );
};

const NestedSafetyNetsStatusCellEditor = props => {
    const { value, onSave, onCancel, context } = props;
    const { currency } = context;
    const isDisabled = value.safetyNetsStatus === SAFETY_NETS_STATUS.SPEND_GUARDS_DISABLED;

    const { isPublisherBlocked } = value;

    const spendGuardActionType = isPublisherBlocked ? SPEND_GUARDS_ACTION_TYPE.BLOCK : SPEND_GUARDS_ACTION_TYPE.CAP;

    const options = useAvailableItems(isDisabled ? SpendCapOptionsWithSpendGuards : SpendCapOptions, {
        safetyNetsStatus: value,
        isBlocked: isPublisherBlocked,
    });

    const onSubmitHandle = newValue => {
        onSave({ type: SITE_EDITOR_TYPE.SAFETY_NETS_STATUS, value: newValue });
    };

    return isPublisherBlocked ? (
        <div className={styles['blocked-container']}>
            <DeleteButton className={styles['button']} onClick={onCancel} />
            <SpendGuardTitle
                isSiteBlockedBySpendGuard
                spendGuardActionType={spendGuardActionType}
                onCancel={onCancel}
            />
            <BlockDetails details={value} currency={currency} />
        </div>
    ) : (
        // </DeletableItem>
        <RadioGroupCellEditor
            {...props}
            radioOptions={options}
            fieldPath="safetyNetsStatus"
            onSave={onSubmitHandle}
            label={<SpendGuardTitle isBlocked={false} spendGuardActionType={spendGuardActionType} />}
        >
            <div className={styles['description']}>
                <FormattedMessage
                    id="app.campaigns.sites.safety.nets.status.description"
                    defaultMessage="SpendGuard is controlling and limiting spend on a site we’ve identified as underperforming."
                />
            </div>
        </RadioGroupCellEditor>
    );
};

export const SafetyNetsStatusCellEditor = withGridCellFormDataProvider(NestedSafetyNetsStatusCellEditor);

NestedSafetyNetsStatusCellEditor.propTypes = {
    value: PropTypes.any,
    /* Column definition */
    colDef: PropTypes.object,
};
