import React, { useCallback, useState } from 'react';
import { noop } from 'lodash';
import uuid from 'uuid/v1';
import { FileTypes } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { useWochit } from 'modules/campaigns/modules/creative-creator/services/wochit';
import useMediaLibrary from 'modules/campaigns/modules/creative-editor/components/MediaTabs/MediaLibraryTab/services/useMediaLibrary';
import { getValidationErrorMessage } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/services/utils/fileValidationHelpers';
import { useUnifiedCreativeEditorFormConfig } from 'modules/campaigns/modules/creative-editor/hooks/useUnifiedCreativeEditorFormConfig';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useLocale } from 'modules/taboola-common-frontend-modules/i18n';
import { LANGUAGE } from 'modules/taboola-common-frontend-modules/i18n/config';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { ModalFormLayout } from 'modules/taboola-common-frontend-modules/modals/components/ModalFormLayout/ModalFormLayout';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { getFailedValidation } from 'modules/taboola-common-frontend-modules/validations/utils';
import { operationsApi } from 'services/api';
import styles from './useMotionStudioModalBase.module.scss';

const createGalleryAssets = loadedMediaLibraryItems => {
    const assets = loadedMediaLibraryItems.map(mediaItem => ({
        url: mediaItem.thumbnailUrl,
        type: 'image',
    }));

    return [{ assets }];
};

const supportedLanguage = {
    en: 'English',
    ja: 'Japanese',
    ko: 'Korean',
    pt: 'Portuguese',
};
const validations = [{ validationFn: validationFunctions.isURL, messageId: 'validations.error.field.url.invalid' }];

const getMediaObject = (url, fileType, typeSource, vendorTemplateType, vendorVideoId) => ({
    src: url,
    id: uuid(),
    fileType,
    type: typeSource,
    mediaUploadSource: typeSource,
    motionAdsStudio: {
        vendorTemplateType,
        vendorVideoId,
    },
});

const sendGTMEvent = (eventName, component, value) => {
    gtmTracker.trackEvent(eventName, {
        component,
        value,
    });
};

export const useMotionStudioModalBase = ({
    campaignId,
    titleList,
    onSelectMedia,
    onUploadErrorMessageChange = noop,
}) => {
    const { wochitSDK, wochitAccessToken } = useWochit() || {};
    const modal = useModal();
    const { loadedMediaLibraryItems } = useMediaLibrary();
    const [locale] = useLocale();
    const destLanguage = supportedLanguage[locale] ? locale : LANGUAGE.en;
    const categoryName = supportedLanguage[destLanguage] || supportedLanguage[LANGUAGE.en];
    const [failedValidationVideoUrl, setFailedValidationVideoUrl] = useState('');
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const { mode } = useFormDataContext();
    const isUnifiedCreativesFormDuplicateMode = useUnifiedCreativeEditorFormConfig();

    const uploadMotionAdsStudioByUrl = useCallback(
        async ({ src, id, fileType, mediaUploadSource, motionAdsStudio, ...rest }) => {
            const metadata = {
                id,
                fileType,
                mediaUploadSource,
                motionAdsStudio,
            };
            const mediaType = {
                IMAGE: 'image',
                VIDEO: 'video',
            };

            const errorMessage = await getValidationErrorMessage({
                url: src,
                mode,
                creativeType,
                mediaType,
                isUnifiedCreativesFormDuplicateMode,
            });

            onUploadErrorMessageChange(errorMessage);

            if (errorMessage) {
                return;
            }

            const motionObject = { id, src, fileType: 'video/*', type: THUMBNAIL_SOURCE_TYPES.URL_TA };
            const urlResolver = () =>
                operationsApi
                    .uploadMotionAdsByUrlWithImageRecommendation(accountId, motionObject, campaignId, titleList)
                    .then(({ url, gifUrl, fallbackImageUrl }) => ({
                        url,
                        gifUrl,
                        fallbackImageUrl,
                        recommendedFBImage: fallbackImageUrl,
                    }));

            onSelectMedia(urlResolver, metadata);
        },
        [
            accountId,
            campaignId,
            creativeType,
            mode,
            onSelectMedia,
            onUploadErrorMessageChange,
            titleList,
            isUnifiedCreativesFormDuplicateMode,
        ]
    );

    const validateWochitVideoUrl = useCallback(
        async wochitPayload => {
            const { videoUrl, vendorTemplateType, vendorVideoId } = wochitPayload;
            const failedValidation = await getFailedValidation(validations, { value: videoUrl });

            if (failedValidation) {
                setFailedValidationVideoUrl(failedValidation.messageId);
                return;
            } else {
                setFailedValidationVideoUrl('');
            }

            const video = getMediaObject(
                videoUrl,
                FileTypes.VIDEO,
                THUMBNAIL_SOURCE_TYPES.WOCHIT,
                vendorTemplateType,
                vendorVideoId
            );

            if (mode === FORM_MODES.CREATE) {
                sendGTMEvent(
                    GTM_EVENTS.USABILITY,
                    'Motion Ads Studio',
                    `Create Item - ${THUMBNAIL_SOURCE_TYPES.WOCHIT}`
                );
            }

            uploadMotionAdsStudioByUrl(video);
        },
        [mode, uploadMotionAdsStudioByUrl]
    );

    // opens the modal, initializes the wochit SDK, and sets up the event listeners for the wochit SDK
    // the event listeners are:
    //      PRODUCE_DONE - triggered when a wochit video is finished being generated
    //      ABORT - triggered when the wochit modal is closed
    const triggerWochit = useCallback(
        async (e, selectedUrl) => {
            if (!wochitSDK) return;
            const galleryAssets = createGalleryAssets(loadedMediaLibraryItems);
            wochitSDK.wt(
                'initShortcut',
                {
                    containerEl: e,
                    userToken: wochitAccessToken,
                    videoContext: 'video',
                    galleryAssets,
                    categoryNames: categoryName,
                    destLanguage,
                    linkedFields: selectedUrl
                        ? {
                              selectedAsset: selectedUrl,
                          }
                        : undefined,
                },
                function onInitShortcut(status) {
                    if (status.error) {
                        setFailedValidationVideoUrl('status.error');
                    } else {
                        const fnOffProduceDone = wochitSDK.wt.on('PRODUCE_DONE', function (event, payload) {
                            const { videoUrl, title: vendorTemplateType, videoId: vendorVideoId } = payload;
                            fnOffProduceDone();
                            validateWochitVideoUrl({ videoUrl, vendorTemplateType, vendorVideoId });
                        });
                        const fnOffAbort = wochitSDK.wt.on('ABORT', function () {
                            fnOffAbort();
                            fnOffProduceDone();
                            modal.close();
                        });
                    }
                }
            );
        },
        [
            categoryName,
            destLanguage,
            loadedMediaLibraryItems,
            modal,
            validateWochitVideoUrl,
            wochitAccessToken,
            wochitSDK,
        ]
    );

    const openModal = useCallback(
        selectedUrl => {
            const wochitContentModal = () => {
                return (
                    <ModalFormLayout hideFooter>
                        <div id="divWochit" ref={divRef} style={{ height: '80vh' }} />
                    </ModalFormLayout>
                );
            };
            const divRef = React.createRef();

            modal.open({
                className: styles['wochit-modal'],
                contentRenderer: wochitContentModal,
                hasCloseButton: false,
                shouldOverlayClose: false,
            });

            requestAnimationFrame(() => {
                triggerWochit(divRef.current, selectedUrl);
            });
        },
        [modal, triggerWochit]
    );

    return { openModal, failedValidationVideoUrl };
};
