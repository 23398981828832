import { useCallback, useMemo } from 'react';
import { findIndex, map } from 'lodash';
import { useCurrentValueGetter } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { cropDataTransformer, isImageCropped } from '../utils/cropDataUtils';

export const useCropImageModalFormFields = (src, setSrc) => {
    const { value: thumbnails } = useFormFieldValue({ field: 'thumbnails' });
    const { value: editCreativeCrop, onChange: onSubmitEditCreativeCrop } = useFormFieldValue({
        field: 'creativeCrop',
    });

    const thumbnailIndex = useMemo(() => findIndex(thumbnails, { displayUrl: src }), [thumbnails, src]);

    const { onChange: onChangeThumbnailByIndex } = useFormFieldValue({ field: `thumbnails.${thumbnailIndex}` });
    const { onChange: onChangeThumbnailUrl } = useFormFieldValue({ field: `thumbnailUrl` });
    const getOnChangeThumbnailByIndex = useCurrentValueGetter(onChangeThumbnailByIndex);
    const getOnChangeThumbnailUrl = useCurrentValueGetter(onChangeThumbnailUrl);

    const setCropData = useCallback(
        cropData => {
            if (!cropData) {
                return;
            }

            const sanitizedCropData = map(cropData, data => {
                // if the image is cropped (crop is not { x: 0, y: 0 } OR if the zoom is NOT 1)
                //      keep the existing data
                if (isImageCropped(data)) {
                    return data;
                }

                // else, the crop and zoom are unmodified,so we should remove the cropped area pixels
                return {
                    ...data,
                    croppedAreaPixels: null,
                };
            });

            const isCropped = sanitizedCropData.some(data => data?.croppedAreaPixels);
            const finalCropData = cropDataTransformer(sanitizedCropData);

            // if this is the edit screen, update the creativeCrop field
            if (editCreativeCrop) {
                onSubmitEditCreativeCrop({ cropData: finalCropData });
                return;
            }

            // otherwise, add the creative crop field to the submit form
            const onChangeThumbnail = getOnChangeThumbnailByIndex();
            onChangeThumbnail(prev => ({
                ...prev,
                cropped: isCropped,
                creativeCrop: {
                    cropData: finalCropData,
                },
            }));
        },
        [editCreativeCrop, getOnChangeThumbnailByIndex, onSubmitEditCreativeCrop]
    );

    const setThumbnailSrc = useCallback(
        src => {
            if (editCreativeCrop) {
                const onChangeThumbnailUrl = getOnChangeThumbnailUrl();
                onChangeThumbnailUrl(src);
                return;
            }

            const onChangeThumbnail = getOnChangeThumbnailByIndex();

            onChangeThumbnail(prev => ({
                ...prev,
                displayUrl: src,
                id: src,
                value: src,
            }));

            setSrc(src);
        },
        [editCreativeCrop, getOnChangeThumbnailByIndex, getOnChangeThumbnailUrl, setSrc]
    );

    return { setCropData, setThumbnailSrc };
};
