import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { HelpTooltip, RangeSlider } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { BRAND_SAFETY_RISK_LEVEL_ORDER } from '../../config/BrandSafetyRiskLevel';
import { ThirdPartyBrandSafetyConfigOverrides } from './ThirdPartyBrandSafetyConfigOverrides';
import styles from './brandSafetyRiskLevelRangeSlider.module.scss';

const classNameBuilder = classnames.bind(styles);

const BrandSafetyRiskLevelRangeSlider = ({ riskLevel, onRiskLevelChange, msgIdPrefix, maxValue }) => {
    const { formatMessage } = useIntl();
    const riskLevelValueChanged = (event, newValue) => {
        onRiskLevelChange(BRAND_SAFETY_RISK_LEVEL_ORDER[newValue]);
    };

    const thirdPartyBrandSafetyOverrides = useMemo(() => ({ msgIdPrefix }), [msgIdPrefix]);

    return (
        <div className={styles['container']}>
            <div className={styles['title']}>
                <div className={styles['label']}>
                    <FormattedMessage id={`${msgIdPrefix}.risk.level.slider.title`} defaultMessage="Risk Tier" />
                </div>
                <HelpTooltip iconLabel="tooltip-third-party-brand-safety-risk-level-range">
                    <FormattedMessage
                        id={`${msgIdPrefix}.risk.level.slider.tooltip`}
                        defaultMessage="Select tiers that align with your Brand Safety and Suitability tolerance for certain content.  I.e. A food brand may want to avoid a user-forum that includes a discussion about \'alcohol abuse\' (high risk), but may be comfortable appearing alongside content that discusses a \'recipe for alcoholic cocktails\' (low risk). Selecting High Risk only will give your campaign the most scale."
                    />
                </HelpTooltip>
            </div>
            <div className={styles['risk-label-section']}>
                <div className={classNameBuilder('slider-label-left')}>
                    <FormattedMessage id={`${msgIdPrefix}.risk.level.left.label`} defaultMessage="High" />
                </div>
                <div className={styles['slider-container']}>
                    <ThirdPartyBrandSafetyConfigOverrides overrides={thirdPartyBrandSafetyOverrides}>
                        <RangeSlider
                            value={BRAND_SAFETY_RISK_LEVEL_ORDER.indexOf(riskLevel)}
                            min={0}
                            max={maxValue}
                            step={1}
                            marks
                            onChange={riskLevelValueChanged}
                            disabled={false}
                            valueLabelFormat={value =>
                                formatMessage({
                                    id: `${msgIdPrefix}.risk.level.slider.${value}`,
                                    defaultMessage: 'Moderate',
                                })
                            }
                            aria-label="third-party-brand-safety-risk-level-range"
                        />
                    </ThirdPartyBrandSafetyConfigOverrides>
                </div>
                <div className={classNameBuilder('slider-label-right')}>
                    <FormattedMessage id={`${msgIdPrefix}.risk.level.right.label`} defaultMessage="Low" />
                </div>
            </div>
        </div>
    );
};

BrandSafetyRiskLevelRangeSlider.propTypes = {
    riskLevel: PropTypes.string,
    onRiskLevelChange: PropTypes.func,
    msgIdPrefix: PropTypes.string,
    maxValue: PropTypes.number,
};

export default BrandSafetyRiskLevelRangeSlider;
