import styles from './stripeElements.module.scss';

export const STRIPE_PAYMENT_ELEMENT_APPEARANCE = {
    // TODO how to use css vars here ???
    variables: {
        colorPrimary: '#212832',
        colorText: '#212832',
        colorDanger: '#e23e57',
    },
    rules: {
        '.Input': {
            border: `1px solid #b8c1ca`,
            borderRadius: '6px',
        },
        '.Input--invalid': {
            border: '1px',
            color: '#212832',
        },
        '.Input--invalid:focus': {
            border: '1px solid #e23e57',
            boxShadow: 'none',
        },
        '.Input:focus': {
            border: '1px solid #4472C4',
            boxShadow: '0 0 4px 0 #4472C4',
            outline: 'none',
        },
        '.Input::placeholder': {
            color: '#b8c1ca',
            fontSize: '14px',
        },

        '.Label': {
            color: '#212832',
            fontWeight: '700',
            fontSize: '14px',
        },
        '.Error': {
            color: '#e23e57',
        },
    },
};

export const STRIPE_ELEMENT_DEFAULT_OPTIONS = {
    style: {
        base: {
            fontSize: styles.baseFontSize,
            fontWeight: styles.baseFontWeight,
            fontFamily: styles.baseFontFamily,
            '::placeholder': {
                color: styles.placeHolderColor,
            },
        },
        invalid: {
            color: styles.invalidColor,
        },
    },
    disabled: true,
};

export const STRIPE_ELEMENT = {
    CARD_NUMBER: 'cardNumber',
    CARD_EXPIRY: 'cardExpiry',
    CARD_CVC: 'cardCvc',
    PAYMENT: 'payment',
};

export const stripeErrorMap = {
    // Card number
    incomplete_number: STRIPE_ELEMENT.CARD_NUMBER,
    incorrect_number: STRIPE_ELEMENT.CARD_NUMBER,
    invalid_number: STRIPE_ELEMENT.CARD_NUMBER,
    expired_card: STRIPE_ELEMENT.CARD_NUMBER,
    card_declined: STRIPE_ELEMENT.CARD_NUMBER,
    setup_intent_authentication_failure: STRIPE_ELEMENT.CARD_NUMBER,

    // Card expiry
    incomplete_expiry: STRIPE_ELEMENT.CARD_EXPIRY,
    incorrect_expiry: STRIPE_ELEMENT.CARD_EXPIRY,
    invalid_expiry: STRIPE_ELEMENT.CARD_EXPIRY,
    incomplete_expiry_year: STRIPE_ELEMENT.CARD_EXPIRY,
    incorrect_expiry_year: STRIPE_ELEMENT.CARD_EXPIRY,
    invalid_expiry_year: STRIPE_ELEMENT.CARD_EXPIRY,
    invalid_expiry_year_past: STRIPE_ELEMENT.CARD_EXPIRY,
    incomplete_expiry_month: STRIPE_ELEMENT.CARD_EXPIRY,
    incorrect_expiry_month: STRIPE_ELEMENT.CARD_EXPIRY,
    invalid_expiry_month: STRIPE_ELEMENT.CARD_EXPIRY,
    invalid_expiry_month_past: STRIPE_ELEMENT.CARD_EXPIRY,

    // CVC/CCV/CVV
    incomplete_cvc: STRIPE_ELEMENT.CARD_CVC,
    incorrect_cvc: STRIPE_ELEMENT.CARD_CVC,
    invalid_cvc: STRIPE_ELEMENT.CARD_CVC,
};
