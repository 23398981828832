import React from 'react';
import PropTypes from 'prop-types';
import { TextCheckbox } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';
import DisabledProviderLeafTooltip from '../DisabledProviderLeafTooltip/DisabledProviderLeafTooltip';
import { useMarketplaceAudiencesContext } from '../MarketplaceAudiencesContext';
import styles from './providerOption.module.scss';

const ProviderOption = ({ data: { id }, innerRef, innerProps }) => {
    const { isProviderEnabled, campaignId } = useMarketplaceAudiencesContext();

    const isEnabled = isProviderEnabled(id);
    const pageName = getPageName();

    return (
        <div className={styles['provider-option']} {...innerProps}>
            {!isEnabled && <DisabledProviderLeafTooltip provider={id} type="provider" />}
            <TextCheckbox
                value={id}
                title={id}
                disabled={!isEnabled}
                ref={innerRef}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-page-name={pageName}
                data-metrics-component="Marketplace Filter"
                data-metrics-value={id}
                data-metrics-taboola-campaign-id={campaignId}
                labelClassName={styles['label']}
            />
        </div>
    );
};

ProviderOption.propTypes = {
    data: PropTypes.object,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object,
};

export default ProviderOption;
