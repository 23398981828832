import React from 'react';
import { AutoAwesomeOutlinedIcon } from 'tuui';
import { pxToRem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { VariationsSVGIcon, OneByOneSVGIcon, RssSVGIcon, AiVariationsSVGIcon } from '../../../icons';
import { CarouselIcon } from '../../../icons/CarouselIcon';
import styles from './contentMethodConfig.module.scss';

const CONTENT_METHOD_CONFIGS = {
    VARIATIONS: {
        subtext: 'Automatically multiply titles and images to get ad variations.',
        title: 'VARIATIONS',
        icon: <VariationsSVGIcon className={styles['icon']} />,
    },
    ONE_BY_ONE: {
        subtext: 'Add multiple URLs to create new ads in bulk.',
        title: 'ONE BY ONE',
        icon: <OneByOneSVGIcon className={styles['icon']} />,
    },
    RSS: {
        subtext: 'Allows to create multiple single creatives or uploading a RSS feed link.',
        title: 'RSS',
        icon: <RssSVGIcon className={styles['icon']} />,
    },
    AI_VARIATIONS: {
        getSubtext: ({ isAiVariationsReplacementEnabled }) => (
            <FormattedMessage
                id={`creative.creator.creation.mode.AI_VARIATIONS.subtext${
                    isAiVariationsReplacementEnabled ? '.alt' : ''
                }`}
                defaultMessage={
                    isAiVariationsReplacementEnabled
                        ? "Create ad variations of headlines and images, with the help of Taboola's Generative AI Admaker"
                        : 'Use AI to generate ad variations.'
                }
            />
        ),
        getTitle: ({ isAiVariationsReplacementEnabled }) => (
            <FormattedMessage
                id={`creative.creator.creation.mode.AI_VARIATIONS${isAiVariationsReplacementEnabled ? '.alt' : ''}`}
                defaultMessage={isAiVariationsReplacementEnabled ? 'VARIATIONS' : 'AI WIZARD'}
            />
        ),
        icon: <AiVariationsSVGIcon className={styles['icon']} />,
        isInBeta: true,
        getTagMessage: ({ isAiVariationsReplacementEnabled }) =>
            isAiVariationsReplacementEnabled ? (
                <>
                    <AutoAwesomeOutlinedIcon sx={{ fontSize: pxToRem('10px') }} />
                    <FormattedMessage id="gen.ai" defaultMessage="GenAI" />
                </>
            ) : (
                <FormattedMessage id="app.new" defaultMessage="New" />
            ),
    },
    CAROUSEL: {
        title: 'CAROUSEL',
        subtext:
            'Use carousel ads to tell a story, share a process, or highlight a set of products, features or services(images & videos).',
        icon: <CarouselIcon className={styles['icon']} />,
    },
};

export { CONTENT_METHOD_CONFIGS };
