import React, { useState, useCallback, useMemo } from 'react';
import { ContentPasteOutlinedIcon } from 'tuui';
import { PaginatedDropdown, BareDropdown, ArrowIcon } from 'taboola-ultimate-ui';
import { DropdownOption } from 'components/Dropdown';
import { readFromClipboard } from 'modules/campaigns/services/utils/clipboard';
import { LINE_DELIMITER, parseMultilineContent } from 'services/utils/stringUtils';
import TargetingDropdownBox from '../TargetingDropdownBox/TargetingDropdownBox';
import { TargetingMultilineInputBox } from './TargetingMultilineInputBox';

export const TargetingBox = ({
    onAddItem,
    itemSelectionDisabled,
    msgIdPrefix,
    isClipboardEnabled,
    dropdownPlaceholder,
    allItems,
    onOptionsLoaded,
    loadPage,
    id,
    textAreaPlaceholder,
    scrollRef,
    submitMultiline,
    submitBtnMetricsAttributes,
    footerErrorMessage,
    footerButtonMessage,
    hasError,
    setHasError,
    submittedValuesCount,
    onSelectItemType,
    ...commonDropdownProps
}) => {
    const [isMultiline, setIsMultiline] = useState(false);
    const [text, setText] = useState('');

    const handleTextAreaChange = useCallback(
        e => {
            setText(e.target.value);
            hasError && setHasError(false);
        },
        [hasError, setHasError]
    );
    const inputValues = useMemo(() => parseMultilineContent(text), [text]);
    const valuesCount = inputValues.length;

    const onPasteFromClipboard = async () => {
        try {
            setIsMultiline(true);
            const clipboardItems = await readFromClipboard();
            const parsedItems = parseMultilineContent(clipboardItems).join(LINE_DELIMITER);
            setText(parsedItems);
        } catch (e) {}
    };

    const dropDownProps = {
        allItems,
        itemOptionRenderer: ({ data, ...rest }) => <DropdownOption {...rest} msgIdPrefix={msgIdPrefix} data={data} />,
        MainDropDownComponent: PaginatedDropdown,
        mainDropDownComponentProps: {
            id,
            DropdownMenuComponent: BareDropdown,
            enabledWhileSearching: true,
            estimatedTotal: 300,
            pageSize: 10,
            onOptionsLoaded,
            loadPage,
            value: null,
            arrowRenderer: () => (
                <>
                    {isClipboardEnabled && (
                        <ContentPasteOutlinedIcon onClick={onPasteFromClipboard} aria-label="Enable paste content" />
                    )}
                    <ArrowIcon />
                </>
            ),
        },
    };

    return (
        <>
            {isMultiline ? (
                <TargetingMultilineInputBox
                    id={id}
                    textAreaPlaceholder={textAreaPlaceholder}
                    scrollRef={scrollRef}
                    submitMultiline={submitMultiline}
                    submitBtnMetricsAttributes={submitBtnMetricsAttributes}
                    footerErrorMessage={footerErrorMessage}
                    footerButtonMessage={footerButtonMessage}
                    hasError={hasError}
                    setHasError={setHasError}
                    submittedValuesCount={submittedValuesCount}
                    setIsMultiline={setIsMultiline}
                    text={text}
                    setText={setText}
                    inputValues={inputValues}
                    valuesCount={valuesCount}
                    handleTextAreaChange={handleTextAreaChange}
                    {...commonDropdownProps}
                />
            ) : (
                <TargetingDropdownBox
                    itemSelectionDisabled={itemSelectionDisabled}
                    onSelectItemType={onSelectItemType}
                    itemPlaceholder={dropdownPlaceholder}
                    onAddItem={onAddItem}
                    {...commonDropdownProps}
                    {...dropDownProps}
                />
            )}
        </>
    );
};
