import { STATUS_TYPE, getStatusType } from './statusConsts';

export const CAMPAIGNS_GROUP_STATUS = {
    RUNNING: 'RUNNING',
    PAUSED: 'PAUSED',
    DEPLETED: 'DEPLETED',
    DEPLETED_IN_MONTHLY: 'DEPLETED_IN_MONTHLY',
    EXPIRED: 'EXPIRED',
    FROZEN: 'FROZEN',
    PENDING: 'PENDING',
    PENDING_START_DATE: 'PENDING_START_DATE',
    TERMINATED: 'TERMINATED',
};

const campaignsGroupStatusConfig = {
    [CAMPAIGNS_GROUP_STATUS.RUNNING]: { statusType: STATUS_TYPE.SUCCESS },
    [CAMPAIGNS_GROUP_STATUS.PAUSED]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGNS_GROUP_STATUS.DEPLETED]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGNS_GROUP_STATUS.DEPLETED_IN_MONTHLY]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGNS_GROUP_STATUS.EXPIRED]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGNS_GROUP_STATUS.FROZEN]: { statusType: STATUS_TYPE.DANGER },
    [CAMPAIGNS_GROUP_STATUS.PENDING]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGNS_GROUP_STATUS.PENDING_START_DATE]: { statusType: STATUS_TYPE.INFO },
    [CAMPAIGNS_GROUP_STATUS.TERMINATED]: { statusType: STATUS_TYPE.INFO },
};

export const getCampaignsGroupStatusType = campaignsGroupStatus =>
    getStatusType(campaignsGroupStatusConfig, campaignsGroupStatus);
