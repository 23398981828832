import React, { useMemo } from 'react';
import { stubTrue } from 'lodash';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { urlValidations } from 'modules/campaigns/config/validations/creative';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { UrlValidatedInput } from '../../../creative-creator/components/UrlList/UrlValidatedInput';
import styles from './creativeURL.module.scss';

const trueValidations = [{ validationFn: stubTrue }];

const CreativeURL = ({
    selected,
    onSelect,
    renderLabel,
    label,
    checkboxLabel,
    placeholder,
    field = 'url',
    validationDependencies,
    helpText,
    onClick,
}) => {
    const disabled = selected === false;
    const { value } = useFormFieldValue({ field });
    const renderedLabel = useMemo(() => (renderLabel ? renderLabel(value) : label), [renderLabel, label, value]);

    return (
        <FormField
            label={renderedLabel}
            checkboxLabel={checkboxLabel}
            containerClass={styles['container']}
            helpText={helpText}
            selected={selected}
            onSelect={onSelect}
        >
            <div name={field}>
                <UrlValidatedInput
                    field={field}
                    disabled={disabled}
                    validations={selected === false ? trueValidations : urlValidations}
                    validationDependencies={validationDependencies}
                    disabledPlaceholder={placeholder}
                    onClick={onClick}
                />
            </div>
        </FormField>
    );
};

CreativeURL.protoTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    label: PropTypes.node,
    checkboxLabel: PropTypes.node,
    disabledPlaceholderId: PropTypes.string,
    renderLabel: PropTypes.func,
};

export default CreativeURL;
