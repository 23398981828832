import { MY_AUDIENCES_TYPES } from '../../../config/MyAudiencesTypes';

const isPredictiveAudience = type => {
    return (
        type === MY_AUDIENCES_TYPES.CRM_LOOKALIKE ||
        type === MY_AUDIENCES_TYPES.PIXEL_LOOKALIKE ||
        type === MY_AUDIENCES_TYPES.PIXEL_BASED_PREDICTIVE
    );
};

const isPixelLookalikeAudience = type => {
    return type === MY_AUDIENCES_TYPES.PIXEL_LOOKALIKE;
};

const isCrmLookalikeAudience = type => {
    return type === MY_AUDIENCES_TYPES.CRM_LOOKALIKE;
};

const isPixelBasedPredictiveAudience = type => {
    return type === MY_AUDIENCES_TYPES.PIXEL_BASED_PREDICTIVE;
};

const defaultSimilarityLevelForPixelLookalike = 5;
const defaultSimilarityLevelForCrmLookalike = 20;
const defaultSimilarityLevelForPixelBasedPredictive = 1;
const attentiveAudienceType = 'Attentive';

const audienceTypeToDefaultSimilarityLevel = {
    [MY_AUDIENCES_TYPES.PIXEL_LOOKALIKE]: defaultSimilarityLevelForPixelLookalike,
    [MY_AUDIENCES_TYPES.CRM_LOOKALIKE]: defaultSimilarityLevelForCrmLookalike,
    [MY_AUDIENCES_TYPES.PIXEL_BASED_PREDICTIVE]: defaultSimilarityLevelForPixelBasedPredictive,
};

export {
    isPredictiveAudience,
    isPixelLookalikeAudience,
    isCrmLookalikeAudience,
    isPixelBasedPredictiveAudience,
    defaultSimilarityLevelForPixelLookalike,
    defaultSimilarityLevelForCrmLookalike,
    attentiveAudienceType,
    audienceTypeToDefaultSimilarityLevel,
};
