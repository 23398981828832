import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { get } from 'lodash';
import { useEventListener, useDebouncedValue } from 'taboola-ultimate-ui';
import { useAppReady, useSelectedAccountId } from 'hooks';
import setFatalError from '../../../errors/actions/setFatalError';
import { authTokenManager } from '../../authentication';
import { useTokenRefreshTrigger } from '../../authentication/hooks/useTokenRefreshTigger';
import { GTM_EVENTS, gtmTracker } from '../../gtmTracker';
import { MESSAGE_TYPE } from '../messageType';
import { getHashedUrl } from '../utils/getHashedUrl';
import { getTokenFromMessage } from '../utils/getTokenFromMessage';
import { isIframeMode } from '../utils/isIframeMode';
import { isMessageBasedAuth } from '../utils/isMessageBasedAuth';
import { isSeparateAppInstanceForbidden } from '../utils/isSeparateAppInstanceForbidden';
import { notifyIfNotReceivedToken } from '../utils/notifyIfNotReceivedToken';
import { sendMessage } from '../utils/sendMessage';
import { isConfiguredParentAppDomain } from './isConfiguredParentAppDomain';
import { useParentHostParam } from './useParentHostParam';

const WHITELISTED_HOSTS = new Set(['https://js.stripe.com']);

const USER_FETCH_TIMEOUT = 5 * 60 * 1000;

export const useIframeService = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentUrl = useDebouncedValue(`${window.location.origin}${location.pathname}${location.search}`);
    const [, setAccountId] = useSelectedAccountId();
    const [parentHost] = useParentHostParam();
    const [, setTokenRefreshTrigger] = useTokenRefreshTrigger();
    const isAppReady = useAppReady();
    const handlers = useMemo(
        () => ({
            [MESSAGE_TYPE.ACCOUNT_CHANGE]: value => setAccountId(value),
            [MESSAGE_TYPE.AUTH_TOKEN_CHANGE]: value => {
                notifyIfNotReceivedToken.cancel();
                const token = getTokenFromMessage(value);

                authTokenManager.setToken(token, Date.now() + USER_FETCH_TIMEOUT);
                setTokenRefreshTrigger(Date.now());
            },
        }),
        [setAccountId, setTokenRefreshTrigger]
    );
    const messageHandler = useCallback(
        event => {
            if (!isIframeMode() || WHITELISTED_HOSTS.has(event.origin)) {
                return;
            }

            if (parentHost && event.origin !== parentHost) {
                return;
            }

            if (!isConfiguredParentAppDomain(event.origin)) {
                gtmTracker.trackError(new Error(`Forbidden parent origin message - ${event.origin}`));
                return;
            }

            const type = get(event, 'data.type');
            const value = get(event, 'data.value');
            const handler = handlers[type];

            if (handler) {
                handler(value);
            }
        },
        [handlers, parentHost]
    );

    useEffect(() => {
        sendMessage(MESSAGE_TYPE.URL_CHANGE, getHashedUrl(currentUrl));
    }, [currentUrl]);

    useEffect(() => {
        if (isSeparateAppInstanceForbidden()) {
            dispatch(setFatalError(new Error(`Can't run app as a separate instance - ${window.location.origin}`)));
            gtmTracker.trackEvent(GTM_EVENTS.NOTIFICATION, { component: 'SeparateInstanceError' });
        }
    }, [dispatch]);

    useEffect(() => {
        if (isMessageBasedAuth()) {
            sendMessage(MESSAGE_TYPE.GET_AUTH_TOKEN);
            notifyIfNotReceivedToken();
        }
    }, []);

    useEffect(() => {
        if (isAppReady) {
            sendMessage(MESSAGE_TYPE.HIDE_LOADING);
            sendMessage(MESSAGE_TYPE.IFRAME_READY);
            return;
        }
        sendMessage(MESSAGE_TYPE.SHOW_LOADING);
    }, [isAppReady]);

    useEventListener('message', messageHandler);
};
