import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './bulkUrlsFieldErrorMessage.module.scss';

const classNameBuilder = classnames.bind(styles);

const BulkUrlsFieldErrorMessage = ({ error, hasInvalidUrls }) => {
    if (error) {
        return (
            <div className={classNameBuilder('message', 'error-message')}>
                <FormattedMessage id={error.messageId} defaultMessage="Unknown Error" />
            </div>
        );
    } else if (hasInvalidUrls) {
        return (
            <div className={classNameBuilder('message', 'warning-message')}>
                <FormattedMessage
                    id="validations.warning.creative.creator.creation.mode.ONE_BY_ONE.urls.invalid"
                    defaultMessage="One or more URLs are invalid"
                />
            </div>
        );
    }
    return null;
};

BulkUrlsFieldErrorMessage.propTypes = {
    error: PropTypes.object,
    hasInvalidUrls: PropTypes.bool,
};

export default BulkUrlsFieldErrorMessage;
