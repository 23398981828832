import bulkCreateCreativesBase from './bulkCreateCreativesBase';

const bulkCreateCreatives =
    ({
        accountId,
        selectedCampaigns,
        creatives,
        videoCreatives,
        ctaData,
        encodedActivePreviews,
        aiImagesPromptData,
        recommendationId,
        onSuccess,
    }) =>
    async dispatch => {
        return dispatch(
            bulkCreateCreativesBase({
                accountId,
                selectedCampaigns,
                creatives,
                videoCreatives,
                ctaData,
                encodedActivePreviews,
                aiImagesPromptData,
                recommendationId,
                onSuccess,
            })
        );
    };

export default bulkCreateCreatives;
