import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useSegmentTypeLimits } from '../../../../campaigns/modules/common-campaign-form/components/Tree/hooks/useSegmentTypeLimits';
import {
    AddCombinedAudiencesWithoutBundlingTargetingButton,
    CombinedAudiencesWithoutBundlingTargeting,
    CombinedAudiencesWithoutBundlingTargetingDelimiter,
} from './CombinedAudiencesWithoutBundlingTargeting';
import {
    useCombinedAudienceIngredientsTargetingCollection,
    useCombinedAudienceIngredientsTargetingTypes,
    useCombinedAudienceIngredientsTypeCounts,
} from './hooks';

export const CombinedAudiencesWithoutBundling = ({ selectedAccountId }) => {
    const { combinedAudienceIngredientsTargetingCollection, onAddTargeting, onDeleteTargeting, onChangeTargetingType } =
        useCombinedAudienceIngredientsTargetingCollection();

    const { maxTargetings } = useSegmentTypeLimits();

    const { setCombinedAudienceIngredientsTypeByIndex, combinedAudienceIngredientsTypeCounts } =
        useCombinedAudienceIngredientsTypeCounts(combinedAudienceIngredientsTargetingCollection);

    const {
        combinedAudienceIngredientsTypes,
        hasExcludeTargeting,
        setCombinedAudienceIngredientsType,
        handleAddTargeting,
        handleDeleteTargeting,
    } = useCombinedAudienceIngredientsTargetingTypes({
        combinedAudienceIngredientsTargetingCollection,
        onAddTargeting,
        onDeleteTargeting,
        onChangeTargetingType,
    });

    return (
        <div>
            {combinedAudienceIngredientsTargetingCollection.map((targeting, index) => {
                const { id } = targeting;
                const handleDeleteTargetingByIndex = () => handleDeleteTargeting(index);
                const key = isNil(id) ? index : id;

                return (
                    <>
                        {index > 0 && (
                            <CombinedAudiencesWithoutBundlingTargetingDelimiter
                                key={`delimiter-${key}`}
                                onDelete={handleDeleteTargetingByIndex}
                            />
                        )}
                        <CombinedAudiencesWithoutBundlingTargeting
                            key={`audiencesWithoutBundling-${key}`}
                            index={index}
                            selectedAccountId={selectedAccountId}
                            combinedAudienceIngredientsTypeCounts={combinedAudienceIngredientsTypeCounts[index]}
                            setCombinedAudienceIngredientsTypeByIndex={setCombinedAudienceIngredientsTypeByIndex}
                            targetingType={combinedAudienceIngredientsTypes[index]}
                            setTargetingType={type => setCombinedAudienceIngredientsType(type, index)}
                            shouldDisableExcludeType={hasExcludeTargeting}
                        />
                    </>
                );
            })}
            {combinedAudienceIngredientsTargetingCollection.length < maxTargetings && (
                <AddCombinedAudiencesWithoutBundlingTargetingButton onClick={handleAddTargeting} />
            )}
        </div>
    );
};

CombinedAudiencesWithoutBundling.propTypes = {
    selectedAccountId: PropTypes.string,
};
