import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useEntityType } from 'hooks';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormState } from 'modules/taboola-common-frontend-modules/forms';
import { INITIAL_FORM_ACCOUNT_NAME } from '../../../../../hooks/queryParams/useInitialFormAccountName';
import { useCampaignGroupApi } from '../../../../../services/api';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import {
    REPORT_TYPE,
    resolveReportIdByCampaignDimension,
    CAMPAIGNS_ROUTE,
    PATH_TO_ENTITY_MAP,
    ENTITY,
} from '../../../config';
import { LAST_CREATED_CAMPAIGN_GROUP_ID } from '../../../hooks/useLastCreatedCampaignGroupId';
import { generateCampaignCreatorPath } from '../../../services/utils';
import { createCampaignGroup } from '../../campaigns-group/flows';
import { REPORT_ID } from '../../campaigns-reports/hooks';
import { useCampaignGroupExtensionEnabled } from './useCampaignGroupExtensionEnabled';

export const useCampaignsGroupCreatorState = () => {
    const dispatch = useDispatch();
    const entityType = useEntityType(PATH_TO_ENTITY_MAP);
    const {
        formAccount: { accountId, accountName },
    } = useFormDataContext();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const campaignGroupApi = useCampaignGroupApi();

    const { submit } = useFormState();

    const createCampaignGroupHandler = useCallback(
        path => {
            const saveCampaignsGroupFlow = campaignsGroup =>
                dispatch(
                    createCampaignGroup(
                        accountId,
                        campaignsGroup,
                        entityType,
                        campaignGroupExtensionEnabled ? campaignGroupApi : undefined
                    )
                );
            return submit(saveCampaignsGroupFlow, path);
        },
        [accountId, entityType, dispatch, submit, campaignGroupExtensionEnabled, campaignGroupApi]
    );

    const submitWrapper = useCallback(() => {
        const reportId = resolveReportIdByCampaignDimension(REPORT_TYPE.CAMPAIGNS_GROUPS);
        const campaignsGroupReportPath = `${CAMPAIGNS_ROUTE}?${REPORT_ID}=${reportId}`;
        return createCampaignGroupHandler(campaignsGroupReportPath);
    }, [createCampaignGroupHandler]);
    const submitAndNavigateCampaign = useCallback(() => {
        const campaignCreatorPath = generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN });
        const pathWithCampaignGroupId = ({ campaignsGroupId }) =>
            `${campaignCreatorPath}${mergeQueryParams('', {
                [LAST_CREATED_CAMPAIGN_GROUP_ID]: campaignsGroupId,
                [INITIAL_FORM_ACCOUNT_NAME]: accountName,
            })}`;

        return createCampaignGroupHandler(pathWithCampaignGroupId);
    }, [accountName, createCampaignGroupHandler]);

    return {
        submit: submitWrapper,
        submitAndNavigateCampaign,
    };
};
