import { useNavigate } from 'hooks';
import {
    generatePerformanceDuplicatePath,
    generatePerformanceEditPath,
} from 'modules/performance-rules/utils/generatePerformanceRulesPath';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useDeletePerformanceRule } from '../../../../../performance-rules/hooks/useDeletePerformanceRule';
import ActionsCellRenderer from './ActionsCellRenderer';
import styles from './CampaignActionsCellRenderer.module.scss';

export const PerformanceRulesActionsCellRenderer = ({ data, context, ...rest }) => {
    const deletePerformanceRule = useDeletePerformanceRule();
    const navigate = useNavigate();
    const isEditEnabled = useConfigMatch({
        [FEATURE_FLAGS.EDIT_PERFORMANCE_RULES_ENABLED]: 'true',
    });

    const isDuplicateEnabled = useConfigMatch({
        [FEATURE_FLAGS.DUPLICATE_PERFORMANCE_RULES_ENABLED]: 'true',
    });

    const { accountName: currRuleAccountName } = data;

    const buttonConfigs = [
        {
            onClick: () => {
                navigate(generatePerformanceEditPath(currRuleAccountName, data.id));
            },
            iconLabel: 'edit-o',
            className: styles['edit-button'],
            isDisabled: !isEditEnabled,
            dataMetricComponent: 'EditActionButton',
            tooltipContent: (
                <FormattedMessage
                    id="app.performance.rules.reports.actionButtons.toolbar.edit"
                    defaultMessage="Edit Rule"
                />
            ),
        },
        {
            onClick: () => {
                deletePerformanceRule(data);
            },
            iconLabel: 'delete',
            className: styles['delete-button'],
            dataMetricComponent: 'DeleteActionButton',
            tooltipContent: (
                <FormattedMessage
                    id="app.performance.rules.reports.actionButtons.toolbar.delete"
                    defaultMessage="Delete Rule"
                />
            ),
        },
        {
            onClick: () => {
                navigate(generatePerformanceDuplicatePath(currRuleAccountName, data.id));
            },
            iconLabel: 'duplicate',
            isDisabled: !isEditEnabled || !isDuplicateEnabled,
            dataMetricComponent: 'DuplicateActionButton',
            tooltipContent: (
                <FormattedMessage
                    id="app.performance.rules.reports.actionButtons.toolbar.duplicate"
                    defaultMessage="Duplicate Rule"
                />
            ),
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};
