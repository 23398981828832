import { useSelectedAccount } from 'hooks';
import { COMPLETION_SCORES } from '../../consts';
import { getAccountCompletionData } from './getAccountCompletionData';
import { useCampaignCompletionData } from './useCampaignCompletionData/useCampaignCompletionData';
import { useReviewCampaignData } from './useReviewCampaignData';

//goes through every subSteps and return the total progress of all the subSteps according to the COMPLETION_SCORES map
const getTotalProgressPercent = stepsData => {
    let progress = 0;
    for (const [stepName, stepData] of Object.entries(stepsData)) {
        const stepScore = COMPLETION_SCORES[stepName];
        const { additionalData, ...stepCompleteData } = stepData;
        for (const [subStepName, completionValue] of Object.entries(stepCompleteData)) {
            if (completionValue) {
                progress += stepScore[subStepName];
            }
        }
    }
    return progress;
};

const getStepProgressPercent = stepData => {
    const { additionalData, ...stepCompleteData } = stepData;
    const stepCompleteValues = Object.values(stepCompleteData);
    const completedStep = stepCompleteValues.filter(Boolean).length;
    const progress = (completedStep / stepCompleteValues.length) * 100;
    return progress;
};

export const useStepsCompleteData = () => {
    const [accountData] = useSelectedAccount();
    const { accountId } = accountData;
    const accountCompletionData = getAccountCompletionData({ accountData });
    const { isError, isLoading, lowestCreativesStatus, ...campaignCompletionData } = useCampaignCompletionData({
        accountId,
    });
    const { completeStep, ...reviewCampaignData } = useReviewCampaignData({ accountId });
    const stepsCompleteData = { ...accountCompletionData, ...campaignCompletionData, ...reviewCampaignData };
    const totalProgressPercent = getTotalProgressPercent(stepsCompleteData);
    for (const [step, stepData] of Object.entries(stepsCompleteData)) {
        stepsCompleteData[step] = { ...stepData, progressPercent: getStepProgressPercent(stepData) };
    }

    return {
        ...stepsCompleteData,
        additionalData: {
            isError,
            isLoading,
            totalProgressPercent,
            lowestCreativesStatus,
            completeReviewCampaign: completeStep,
        },
    };
};
