'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactVirtualized = require('react-virtualized');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * @deprecated
 */
var InfiniteScroller = function (_Component) {
    _inherits(InfiniteScroller, _Component);

    function InfiniteScroller(props) {
        _classCallCheck(this, InfiniteScroller);

        var _this = _possibleConstructorReturn(this, (InfiniteScroller.__proto__ || Object.getPrototypeOf(InfiniteScroller)).call(this, props));

        _this.isRowLoaded = _this.isRowLoaded.bind(_this);
        _this.loadMoreRows = _this.loadMoreRows.bind(_this);
        return _this;
    }

    _createClass(InfiniteScroller, [{
        key: 'loadMoreRows',
        value: function loadMoreRows(_ref) {
            var startIndex = _ref.startIndex,
                stopIndex = _ref.stopIndex;
            var loadNextPage = this.props.loadNextPage;

            return loadNextPage(startIndex, stopIndex);
        }
    }, {
        key: 'isRowLoaded',
        value: function isRowLoaded(_ref2) {
            var index = _ref2.index;
            var list = this.props.list;

            return list[index];
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var _props = this.props,
                rowCount = _props.rowCount,
                list = _props.list,
                rowRenderer = _props.rowRenderer,
                rowHeight = _props.rowHeight,
                minimumBatchSize = _props.minimumBatchSize,
                className = _props.className,
                listClassName = _props.listClassName,
                rest = _objectWithoutProperties(_props, ['rowCount', 'list', 'rowRenderer', 'rowHeight', 'minimumBatchSize', 'className', 'listClassName']);

            return _react2.default.createElement(
                'div',
                Object.assign({ className: className }, rest),
                _react2.default.createElement(
                    _reactVirtualized.AutoSizer,
                    null,
                    function (_ref3) {
                        var height = _ref3.height,
                            width = _ref3.width;
                        return _react2.default.createElement(
                            _reactVirtualized.InfiniteLoader,
                            {
                                isRowLoaded: _this2.isRowLoaded,
                                loadMoreRows: _this2.loadMoreRows,
                                rowCount: rowCount,
                                minimumBatchSize: minimumBatchSize
                            },
                            function (_ref4) {
                                var onRowsRendered = _ref4.onRowsRendered,
                                    registerChild = _ref4.registerChild;
                                return _react2.default.createElement(_reactVirtualized.List, {
                                    ref: registerChild,
                                    onRowsRendered: onRowsRendered,
                                    rowRenderer: rowRenderer,
                                    height: height,
                                    rowHeight: rowHeight,
                                    rowCount: rowCount,
                                    width: width,
                                    className: listClassName
                                });
                            }
                        );
                    }
                )
            );
        }
    }]);

    return InfiniteScroller;
}(_react.Component);

InfiniteScroller.propTypes = {
    /* Are there more items to load? (This information comes from the most recent API request.) */
    rowCount: _propTypes2.default.number.isRequired,
    /* List of items loaded so far */
    list: _propTypes2.default.array.isRequired,
    /* Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    loadNextPage: _propTypes2.default.func.isRequired,
    /* row height */
    rowHeight: _propTypes2.default.number,
    /* The batch size to load */
    minimumBatchSize: _propTypes2.default.number,
    /* Renderer for a single row in the list */
    rowRenderer: _propTypes2.default.func.isRequired,
    /* Optional className for the infinite scroll container */
    className: _propTypes2.default.string,
    /* Optional className for the infinite scroll list */
    listClassName: _propTypes2.default.string
};

InfiniteScroller.defaultProps = {
    rowHeight: 30,
    minimumBatchSize: 50,
    className: '',
    listClassName: ''
};

exports.default = InfiniteScroller;