import { stringifyQueryParams } from '../../../modules/taboola-common-frontend-modules/query-params/queryParamUtils';

export const billingApiFactory = ({ callGetApi, callPostApi, callPutApi, callDeleteApi }) => ({
    getCcbBillingData: ({ accountId, ...options }) => callGetApi(`${billingPrefix(accountId)}/ccb`, options),
    getCreditLimitBillingData: ({ accountId, ...options }) =>
        callGetApi(`${billingPrefix(accountId)}/credit-limit`, options),
    getHostedInvoiceConfig: ({ accountId }) =>
        callGetApi(`${billingPrefix(accountId)}/payment-methods/hosted-invoice-config`),
    getAllowedPrepaymentMethods: ({ accountId }) =>
        callGetApi(`${billingPrefix(accountId)}/payment-methods/allowed-prepayment-methods`),
    createSetupIntent: ({ accountId }) => callGetApi(`${billingPrefix(accountId)}/stripe/setup-intent`),
    createPaymentMethod: ({ accountId, paymentMethod }) =>
        callPostApi(`${billingPrefix(accountId)}/payment-methods`, paymentMethod),
    updatePaymentMethod: ({ accountId, paymentMethodId, paymentMethodChanges }) =>
        callPutApi(`${billingPrefix(accountId)}/payment-methods/${paymentMethodId}`, paymentMethodChanges),
    deletePaymentMethod: ({ accountId, paymentMethodId }) =>
        callDeleteApi(`${billingPrefix(accountId)}/payment-methods/${paymentMethodId}`),
    addFunds: ({ accountId, amount, fundsType }) =>
        callPostApi(`${billingPrefix(accountId)}/funds`, { amount, fundsType }),
    cancelFunds: ({ accountId, fundId }) => callDeleteApi(`${billingPrefix(accountId)}/funds/${fundId}`),
    cashRefund: ({ accountId, apiRefund }) => callPostApi(`${billingPrefix(accountId)}/refund-cash-funds`, apiRefund),
    getPaymentsReport: ({ accountId }) => callGetApi(`${billingReportsPrefix(accountId)}/payment-history`),
    getInvoicesReport: ({ accountId }) => callGetApi(`${billingReportsPrefix(accountId)}/invoices`),
    getInvoice: ({ accountId, invoiceId, invoiceType }) =>
        callGetApi(
            `${billingPrefix(accountId)}/invoice${stringifyQueryParams({ id: invoiceId, type: invoiceType })}`,
            {},
            true
        ),
    getPaymentMethods: ({ accountId }) => callGetApi(`${billingPrefixV2(accountId)}/payment/methods`),
    getAllowedPrepaymentMethodsV2: ({ accountId }) =>
        callGetApi(`${billingPrefixV2(accountId)}/payment/methods/allowed-for-prepayment`),
    getPaymentMethodRequest: ({ accountId }) => callGetApi(`${billingPrefixV2(accountId)}/payment/method-request`),
    createCardPaymentMethod: ({ accountId, paymentMethod }) =>
        callPostApi(`${billingPrefixV2(accountId)}/payment/method/card`, paymentMethod),
    createAchBankDirectDebitPaymentMethod: ({ accountId, paymentMethod }) =>
        callPostApi(`${billingPrefixV2(accountId)}/payment/method/ach`, paymentMethod),
    deletePaymentMethodV2: ({ accountId, paymentMethodId }) =>
        callDeleteApi(`${billingPrefixV2(accountId)}/payment/method/${paymentMethodId}`),
});

const billingPrefix = accountId => `/${accountId}/billing`;
const billingPrefixV2 = accountId => `/${accountId}/billing-v2`;
const billingReportsPrefix = accountId => `/${accountId}/reports/billing`;
