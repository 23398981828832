'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Slider = require('@material-ui/core/Slider');

var _Slider2 = _interopRequireDefault(_Slider);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ValueLabelComponent = require('./ValueLabelComponent');

var _ValueLabelComponent2 = _interopRequireDefault(_ValueLabelComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'rangeSlider__taboola-svg-icon___3wQHg',
    'tooltip': 'rangeSlider__tooltip___2rocI',
    'root': 'rangeSlider__root___bLlP4',
    'thumb': 'rangeSlider__thumb___3f5J4',
    'track': 'rangeSlider__track___3ib4R',
    'rail': 'rangeSlider__rail___1bNiN',
    'mark': 'rangeSlider__mark___3Uiqf',
    'markActive': 'rangeSlider__markActive___3XUMD'
};


var classes = {
    root: styles['root'],
    thumb: styles['thumb'],
    track: styles['track'],
    rail: styles['rail'],
    mark: styles['mark'],
    markActive: styles['markActive']
};

var RangeSlider = function RangeSlider(props) {
    return _react2.default.createElement(_Slider2.default, Object.assign({
        ValueLabelComponent: _ValueLabelComponent2.default,
        classes: classes
    }, props));
};

RangeSlider.propTypes = {
    value: _propTypes2.default.number,
    min: _propTypes2.default.number,
    max: _propTypes2.default.number,
    step: _propTypes2.default.number,
    marks: _propTypes2.default.oneOfType([_propTypes2.default.bool, _propTypes2.default.array]),
    classes: _propTypes2.default.object,
    onChange: _propTypes2.default.func,
    disabled: _propTypes2.default.bool
};

RangeSlider.defaultProps = {
    min: 1,
    max: 5,
    marks: true
};

exports.default = RangeSlider;