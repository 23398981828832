import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

const hasDuplicateConditions = conditions => {
    const uniqueConditions = conditions.map(c => c.predicate + c.url).filter(onlyUnique);
    return uniqueConditions.length === conditions.length;
};

const hasConditionWithError = (conditions, options) => !conditions.some(({ url }) => url.length > options.length);

const pixelBasedUrlConditionValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 1 },
        messageId: 'audience.editor.validations.error.url.conditions.empty',
        defaultMessage: 'Create at least 1 condition',
    },
    {
        validationFn: hasDuplicateConditions,
        messageId: 'audience.editor.validations.error.url.condition.duplicated',
        defaultMessage: 'Some of your url conditions are duplicated',
    },
    {
        validationFn: hasConditionWithError,
        options: { length: 500 },
        messageId: 'audience.editor.validations.error.url.condition.long',
        defaultMessage: 'Some of your url conditions exceed 500 characters',
    },
];

export default pixelBasedUrlConditionValidations;
