'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSelect = require('react-select');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _DropdownMenu = require('../Dropdown/DropdownMenu');

var _DropdownMenu2 = _interopRequireDefault(_DropdownMenu);

var _styleUtils = require('../../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

var _useOnClickOutside = require('../../../hooks/useOnClickOutside');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'headlessDropdown__taboola-svg-icon___233zu',
    'container': 'headlessDropdown__container___1lJMi',
    'scrollable': 'headlessDropdown__scrollable___2H1lC'
};


var classNameBuilder = _bind2.default.bind(styles);

var dropdownStyles = function dropdownStyles(width, menuStyles, parentStyles) {
    return Object.assign({}, parentStyles, {
        menu: function menu(provided) {
            return Object.assign({}, provided, menuStyles, {
                width: (0, _styleUtils2.default)(width)
            });
        },
        control: function control() {
            return { visibility: 'hidden', height: 0 };
        }
    });
};

var withMenuListRef = function withMenuListRef(MenuList, ref, isScrollable) {
    return function (props) {
        return _react2.default.createElement(
            'div',
            { className: classNameBuilder({ scrollable: isScrollable }), ref: ref },
            _react2.default.createElement(MenuList, props)
        );
    };
};

var HeadlessDropdown = function HeadlessDropdown(_ref) {
    var children = _ref.children,
        _ref$width = _ref.width,
        width = _ref$width === undefined ? null : _ref$width,
        _ref$menuStyles = _ref.menuStyles,
        menuStyles = _ref$menuStyles === undefined ? {} : _ref$menuStyles,
        parentStyles = _ref.styles,
        onChange = _ref.onChange,
        keepOpen = _ref.keepOpen,
        open = _ref.open,
        onToggleDropdown = _ref.onToggleDropdown,
        menuListRenderer = _ref.menuListRenderer,
        disabled = _ref.disabled,
        isScrollable = _ref.isScrollable,
        containerClassName = _ref.containerClassName,
        rest = _objectWithoutProperties(_ref, ['children', 'width', 'menuStyles', 'styles', 'onChange', 'keepOpen', 'open', 'onToggleDropdown', 'menuListRenderer', 'disabled', 'isScrollable', 'containerClassName']);

    var dropdownMenuRef = (0, _react.useRef)(null);
    // if the document is clicked on (but not the dropdown itself) close the dropdown
    var calculatedStyles = (0, _react.useMemo)(function () {
        return dropdownStyles(width, menuStyles, parentStyles);
    }, [width, menuStyles, parentStyles]);
    var closeDropdown = (0, _react.useCallback)(function () {
        return onToggleDropdown(false);
    }, [onToggleDropdown]);

    (0, _useOnClickOutside.useOnClickOutside)(dropdownMenuRef, function () {
        return closeDropdown();
    });

    var openDropdown = (0, _react.useCallback)(function () {
        if (disabled) {
            return;
        }
        onToggleDropdown(true);
    }, [disabled, onToggleDropdown]);

    var handleOnChange = (0, _react.useCallback)(function (value) {
        if (!keepOpen) {
            onToggleDropdown(false);
        }

        onChange(value);
    }, [onChange, keepOpen, onToggleDropdown]);

    var dropdownMenu = _react2.default.createElement(_DropdownMenu2.default, Object.assign({
        menuIsOpen: open,
        styles: calculatedStyles,
        onChange: handleOnChange,
        menuListRenderer: withMenuListRef(menuListRenderer, dropdownMenuRef, isScrollable),
        controlShouldRenderValue: false
    }, rest));

    // if the children prop is a function, we use render props to give our user control of the toggle function
    if (typeof children === 'function') {
        return _react2.default.createElement(
            'div',
            { className: classNameBuilder('container', containerClassName) },
            children(),
            dropdownMenu
        );
    }
    // otherwise default attach the handler to the wrapping div
    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('container', containerClassName) },
        _react2.default.createElement(
            'div',
            {
                role: 'button',
                tabIndex: 0,
                onClick: openDropdown,
                className: classNameBuilder('container', containerClassName)
            },
            children
        ),
        dropdownMenu
    );
};

HeadlessDropdown.propTypes = {
    open: _propTypes2.default.bool,
    children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    onChange: _propTypes2.default.func,
    onToggleDropdown: _propTypes2.default.func,
    width: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
    menuStyles: _propTypes2.default.object,
    styles: _propTypes2.default.object,
    keepOpen: _propTypes2.default.bool,
    initialOpen: _propTypes2.default.bool,
    menuListRenderer: _propTypes2.default.func,
    disabled: _propTypes2.default.bool,
    isScrollable: _propTypes2.default.bool,
    containerClassName: _propTypes2.default.string
};

HeadlessDropdown.defaultProps = {
    open: false,
    onToggleDropdown: function onToggleDropdown() {},
    menuListRenderer: _reactSelect.components.MenuList,
    styles: {},
    disabled: false,
    isScrollable: false
};

exports.default = HeadlessDropdown;