import { cloneDeep, compact } from 'lodash';
import { isDefined } from '../../formData/utils/isDefined';

const MAX_READABLE_LENGTH = 75;

export const getTreePath = bubbleTree =>
    compact(bubbleTree.map(({ displayName }) => displayName))
        .reverse()
        .join('->');

// tooltip provider skip is intended to avoid everything to be clickable
const isClickRelevant = target =>
    (target.role === 'button' ||
        target.tagName.toLowerCase() === 'button' ||
        target.tagName.toLowerCase() === 'a' ||
        (!!target.onclick && target?.contentEditable !== 'true')) &&
    !`${target.selector}`.includes('DIV#tooltip-portal');

const getEventCoreData = ({ id, value, innerText, ariaLabel, bubbleTree }) => ({
    id: id || undefined,
    value,
    displayText: innerText || ariaLabel,
    contextAccountName: bubbleTree.find(({ contextAccountName }) => isDefined(contextAccountName))?.contextAccountName,
});

export const transformClickEvent = event => {
    const transformedEvent = getEventCoreData(event);
    const bubbleTree = cloneDeep(event.bubbleTree);
    const clickTarget = bubbleTree.find(isClickRelevant);

    if (!clickTarget) {
        return;
    }

    bubbleTree.forEach(target => {
        if (isClickRelevant(target)) {
            target.displayName = target.displayName || target.textContent.slice(0, MAX_READABLE_LENGTH);
        }
    });

    transformedEvent.uiComponentPath = getTreePath(bubbleTree);
    transformedEvent.value = clickTarget.textContent.slice(0, MAX_READABLE_LENGTH);

    return transformedEvent;
};

export const transformChangeEvent = (event, skipCondition) => {
    const transformedEvent = getEventCoreData(event);
    const bubbleTree = cloneDeep(event.bubbleTree);
    const target = bubbleTree[0];

    if (skipCondition && skipCondition(target)) {
        return;
    }

    transformedEvent.uiComponentPath = getTreePath(bubbleTree);
    transformedEvent.value = event.value || event.textContent;

    return transformedEvent;
};

export const transformHoverEvent = event => {
    const transformedEvent = getEventCoreData(event);
    const bubbleTree = cloneDeep(event.bubbleTree);

    if (!event.onhover) {
        return;
    }

    transformedEvent.uiComponentPath = getTreePath(bubbleTree);
    transformedEvent.value = event.innerText;

    return transformedEvent;
};
