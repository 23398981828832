import React, { useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { stubFalse } from 'lodash';
import PropTypes from 'prop-types';
import { StatusIndicator } from 'tuui';
import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { ENTITY } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { getStatusIndicatorType, isCreativeStatusNeedToEditWithReason } from '../../../../../config';
import { useIsCreativeResubmitEnabled } from '../../../hooks/useCreativeResubmit';
import EllipsisCellRenderer from '../EllipsisCellRenderer';
import EnumValueCellRenderer from '../EnumValueCellRenderer';
import { ResubmitButton } from './ResubmitButton';
import helpTooltipStyles from '../helpTooltip.module.scss';
import styles from './statusCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);

const isValidNumber = n => Number(n) === n;

const contentCreator = ({ msgIdPrefix, value, emptyValue, formattedTooltipMsg }) =>
    formattedTooltipMsg || (
        <FormattedMessage id={`${msgIdPrefix}.tooltip.${value}`} defaultMessage={value || emptyValue} />
    );

contentCreator.propTypes = {
    msgIdPrefix: PropTypes.string,
    value: PropTypes.string,
    emptyValue: PropTypes.string,
    formattedTooltipMsg: PropTypes.node,
};

const StatusCellRenderer = ({
    getStatusType,
    value,
    disableValueTooltip,
    msgIdPrefix,
    emptyValue,
    data,
    isStatusRejected = stubFalse,
    phase,
    phaseMsgIdPrefix,
    hidePhaseLabel,
    SubStatusIcon,
    subStatusIconIndication,
    hideTooltip,
    tooltipBody: propTooltipBody,
    helpTooltipClassContainer,
    progress,
    progressBarColor,
    timeLeft,
    formattedStatusMsg,
    formattedStatusTooltipMsg,
    entityType,
    staticIconTooltip,
}) => {
    const statusType = getStatusType(value);
    const statusIndicatorType = getStatusIndicatorType(statusType);
    const containerClassName = classNameBuilder('container', statusIndicatorType);
    const isIndicatorVisible = statusIndicatorType && statusIndicatorType !== StatusIndicator.type.warning;
    const statusIndicatorClassName = classNameBuilder('indicator', { hidden: !isIndicatorVisible });
    const subsStatusLabelClassName = classNameBuilder('sub-status-label', { hidden: hidePhaseLabel });
    const progressBarClassName = classNameBuilder('progress-bar', progressBarColor);
    const subStatusIconClassName = classNameBuilder('sub-status-icon', subStatusIconIndication);
    const { rejectReason, reviewerNotes, statusReason, id } = data;
    const statusNeedToEditWithReason = isCreativeStatusNeedToEditWithReason(data);
    const isRejected = isStatusRejected(data) && rejectReason;

    const isCreativeResubmissionEnabled = useIsCreativeResubmitEnabled();

    const [hovered, setHovered] = useState(false);
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    let tooltipBody = propTooltipBody;
    let tooltipMessageProps = null;
    const hasValidTooltip = tooltipBody || isRejected || statusNeedToEditWithReason;
    const helpTooltipClassName = tooltipBody ? helpTooltipStyles['help-tooltip'] : null;
    if (statusNeedToEditWithReason) {
        tooltipMessageProps = {
            id: `${msgIdPrefix}.reason.${statusReason}`,
            defaultMessage: statusReason,
        };
    } else if (isRejected) {
        tooltipBody = (
            <div>
                {reviewerNotes && (
                    <div className={styles['reviewer-notes']}>
                        <FormattedMessage
                            id={`${msgIdPrefix}.reject.main.note`}
                            values={{ reviewerNotes }}
                            defaultMessage="<b>Rejection details</b>: {reviewerNotes}"
                        />
                    </div>
                )}
                <FormattedMessage id={`${msgIdPrefix}.reject.main.violation`} />
                <FormattedMessage id={`${msgIdPrefix}.reject.main.learnMore`} />
            </div>
        );
        tooltipMessageProps = {
            id: `${msgIdPrefix}.reject.reason.${rejectReason}`,
            defaultMessage: rejectReason,
        };
    }

    let subStatusProps = null;
    if (isRejected) {
        subStatusProps = {
            value: rejectReason,
            msgIdPrefix: `${msgIdPrefix}.reject.reason`,
        };
    } else if (phase) {
        subStatusProps = {
            value: phase,
            msgIdPrefix: phaseMsgIdPrefix,
        };
    }

    const [EnumValueRenderer, EllipsisValueRenderer] = useMemo(
        () =>
            [EnumValueCellRenderer, EllipsisCellRenderer].map(Component => props => (
                <TooltipWrapper
                    wrap={!disableValueTooltip}
                    tooltipContent={!disableValueTooltip && contentCreator(props)}
                    tooltipOptions={{
                        arrow: true,
                        interactive: true,
                        position: TOOLTIP_POSITION.BOTTOM_START,
                    }}
                >
                    <Component {...props} />
                </TooltipWrapper>
            )),
        [disableValueTooltip]
    );

    return (
        <div className={containerClassName}>
            <div className={styles['left-container']}>
                <div className={styles['status-container']}>
                    <div className={styles['status-indicator-and-label-container']} aria-label="value">
                        <StatusIndicator type={statusIndicatorType} className={statusIndicatorClassName} />
                        {formattedStatusMsg ? (
                            <EllipsisValueRenderer
                                value={value}
                                valueFormatted={formattedStatusMsg}
                                formattedTooltipMsg={formattedStatusTooltipMsg}
                                emptyValue={emptyValue}
                                lines={1}
                            />
                        ) : (
                            <EnumValueRenderer
                                value={value}
                                msgIdPrefix={msgIdPrefix}
                                emptyValue={emptyValue}
                                lines={1}
                            />
                        )}
                    </div>
                    {!hideTooltip && hasValidTooltip && (
                        <div className={helpTooltipClassContainer}>
                            <HelpTooltip
                                iconClassName={helpTooltipStyles['help-icon']}
                                position={TOOLTIP_POSITION.RIGHT}
                                className={helpTooltipClassName}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                showing={hovered}
                            >
                                {tooltipMessageProps && <FormattedMessage {...tooltipMessageProps} />}
                                {tooltipBody}
                            </HelpTooltip>
                        </div>
                    )}
                </div>
                {subStatusProps && (
                    <div className={styles['sub-status-container']} aria-label="subValue">
                        <div
                            className={subsStatusLabelClassName}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            role="heading"
                            aria-level={5}
                        >
                            {SubStatusIcon && <SubStatusIcon className={subStatusIconClassName} />}
                            <EnumValueCellRenderer emptyValue="" lines={1} {...subStatusProps} />
                        </div>
                        {timeLeft && (
                            <div className={styles['time-left']} role="heading" aria-level={6}>
                                {timeLeft}
                            </div>
                        )}
                    </div>
                )}
                {phase && isValidNumber(progress) && (
                    <div className={styles['progress-bar-container']}>
                        <progress className={progressBarClassName} value={progress} max={100} />
                    </div>
                )}
                {isCreativeResubmissionEnabled && entityType === ENTITY.CREATIVE && <ResubmitButton creativeId={id} />}
            </div>
            {staticIconTooltip}
        </div>
    );
};

StatusCellRenderer.propTypes = {
    /** Function that maps from value prop to STATUS_TYPE value */
    getStatusType: PropTypes.func,
    data: PropTypes.object,
    isStatusRejected: PropTypes.func,
    reportType: PropTypes.string,
    phase: PropTypes.string,
    phaseMsgIdPrefix: PropTypes.string,
    hidePhaseLabel: PropTypes.bool,
    tooltipBody: PropTypes.node,
    progress: PropTypes.number,
    progressBarColor: PropTypes.string,
    timeLeft: PropTypes.node,
    formattedStatusMsg: PropTypes.node,
    formattedStatusTooltipMsg: PropTypes.node,
    disableValueTooltip: PropTypes.bool,
    entityType: PropTypes.oneOf(Object.values(ENTITY)),
    staticIconTooltip: PropTypes.node,
    ...EnumValueCellRenderer.propTypes,
};

StatusCellRenderer.defaultProps = {
    getStatusType: () => {},
    data: { rejectReason: '', statusReason: '' },
    hidePhaseLabel: false,
};

export default StatusCellRenderer;
