import React from 'react';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import AudienceEditorHeader from '../components/AudienceEditorHeader/AudienceEditorHeader';
import PixelBasedAudiencePropertiesSection from '../components/PixelBasedAudiencePropertiesSection/PixelBasedAudiencePropertiesSection';
import { PixelBasedAudienceSetupDetailsSection } from '../components/PixelBasedAudienceSetupDetailsSection/PixelBasedAudienceSetupDetailsSection';
import PixelBasedAudienceSetupSection from '../components/PixelBasedAudienceSetupSection/PixelBasedAudienceSetupSection';
import useAudienceEditorState from '../hooks/useAudienceEditorState';
import styles from './audienceEditorPage.module.scss';

const PixelBasedAudiencesEditorPage = ({ onCancel }) => {
    const { isSubmitDisabled, submitInProgress, fetchStatus, mode } = useFormDataContext();
    const { submit } = useAudienceEditorState({
        mode,
        reportType: REPORT_TYPE.PIXEL_BASED,
    });

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AudienceEditorHeader status={fetchStatus} headerClassName={styles['audience-header']} />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['audience-editor']}
                bodyContainerClassName={styles['audience-editor-body']}
                footerContainerClassName={styles['audience-editor-footer']}
                data-automation="AudienceEditor"
            >
                <PixelBasedAudienceSetupSection />
                <PixelBasedAudienceSetupDetailsSection />
                <div id="audience-properties">
                    <PixelBasedAudiencePropertiesSection />
                </div>
            </FormLayout>
        </FormInitBase>
    );
};

const PixelBasedAudiencesEditorPageWithFormDataProvider = withFormDataProvider(PixelBasedAudiencesEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { PixelBasedAudiencesEditorPageWithFormDataProvider as PixelBasedAudiencesEditorPage };
