import { entries, get, isEqual, isNil, map, uniq, reject } from 'lodash';
import moment from 'moment';
import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { CustomValidationError } from '../../../../errors/CustomValidationError';
import { OPERATION_REQUIREMENT_TYPES } from '../consts/columnRequiredTypes';
import { SHEET_NAME } from '../consts/sheetName';
import { ExcelBulkError } from '../transformers/ExcelBulkError';
import { METADATA_SHEET_VISIBILITY, readKeyValueSheet, setRangeProp } from './excelUtils';

export const DEFAULT_WIDTH = 20;
export const COLUMN_WIDTH_MEDIUM = 30;
export const COLUMN_WIDTH_LARGE = 60;
export const MAX_CELL_LENGTH = 32767;
export const DEFAULT_WORKBOOK_VIEW = { x: 0, y: 0, width: 1, height: 1 };
export const METADATA_VERSION = 'excel.v.1.1.1';
const EXCEL_COLUMN_PREFIX = 'excel.bulk';
const STALE_METADATA_TIMEOUT_DAYS = 3;
const START_DATE = new Date(2000, 0, 1);
const METADATA_PROP_NAME = {
    UTC_DATE: 'utcDate',
};

const getColumnRequirementText = (operationRequirementType, formatMessage) => {
    if (operationRequirementType === OPERATION_REQUIREMENT_TYPES.CREATE) {
        const requiredForCreateText = formatMessage({
            id: `${EXCEL_COLUMN_PREFIX}.column.requiredForCreate`,
            defaultMessage: '#Required for create',
        });

        return { font: { color: { argb: 'FF0000' } }, text: requiredForCreateText };
    } else if (operationRequirementType === OPERATION_REQUIREMENT_TYPES.UPDATE) {
        const requiredForUpdateText = formatMessage({
            id: `${EXCEL_COLUMN_PREFIX}.column.requiredForUpdate`,
            defaultMessage: '#Required for update',
        });

        return { font: { color: { argb: '0000FF' } }, text: requiredForUpdateText };
    } else {
        const optionalText = formatMessage({
            id: `${EXCEL_COLUMN_PREFIX}.column.optional`,
            defaultMessage: '#Optional',
        });

        return { text: optionalText };
    }
};

export const getIsRowCampaignUpdate = ({ rowData }) => Boolean(get(rowData, 'id'));

export const getIsRowCreativeUpdate = ({ rowData }) => Boolean(get(rowData, 'id'));

export const getSheetName = (sheetName, formatMessage) =>
    formatMessage({ id: `${EXCEL_COLUMN_PREFIX}.sheet.${sheetName}`, defaultMessage: sheetName });

export const getExcelBulkFileName = (filename, formatMessage, accountDescription) => {
    const formattedFileName = formatMessage({
        id: `${EXCEL_COLUMN_PREFIX}.file.${filename}`,
        defaultMessage: filename,
    });
    const formattedDate = moment().format('YYYY-MM-DD');

    return `${formattedFileName} - ${accountDescription} - ${formattedDate}.xlsx`;
};

export const getColumnMessageIdPrefix = (entity, field) => `${EXCEL_COLUMN_PREFIX}.column.${entity}.${field}`;

export const getHeaderName = ({ entity, column, formatMessage }) => {
    const { field, getCustomHeaderName } = column;
    return getCustomHeaderName
        ? getCustomHeaderName({ entity, column, formatMessage })
        : formatMessage({
              id: getColumnMessageIdPrefix(entity, field),
              defaultMessage: field,
          });
};

export const getBaseExampleText = ({ entity, column, formatMessage, dictionaryData }) => {
    const { field, getCustomExampleText } = column;
    return getCustomExampleText
        ? getCustomExampleText({ entity, column, formatMessage, dictionaryData })
        : formatMessage({
              id: `${getColumnMessageIdPrefix(entity, field)}.example`,
              defaultMessage: field,
          });
};

export const getExampleText = ({ entity, column, formatMessage, dictionaryData }) => {
    const { operationRequirementType } = column;
    const columnRequirementText = getColumnRequirementText(operationRequirementType, formatMessage);
    const baseExampleText = getBaseExampleText({ entity, column, formatMessage, dictionaryData });

    return { richText: [columnRequirementText, { text: `\n\n${baseExampleText}` }] };
};

export const getExampleTextWithCurrency = ({ entity, formatMessage, column, dictionaryData }) =>
    formatMessage(
        {
            id: `${getColumnMessageIdPrefix(entity, column.field)}.example`,
            defaultMessage: column.field,
        },
        { currency: get(dictionaryData, 'selectedAccount.currency') }
    );

export const getThirdPartyHeaderName = ({ entity, formatMessage, column, thirdPartyField, trackingSetNumber }) =>
    formatMessage(
        {
            id: `${getColumnMessageIdPrefix(entity, 'thirdPartyTags')}.${thirdPartyField}`,
            defaultMessage: column.field,
        },
        { trackingSetNumber }
    );

export const getThirdPartyExampleText = ({ entity, formatMessage, thirdPartyField, column }) =>
    formatMessage({
        id: `${getColumnMessageIdPrefix(entity, 'thirdPartyTags')}.${thirdPartyField}.example`,
        defaultMessage: column.field,
    });

const marketplaceAudiencesTargetingIncludeField = 'campaignTargeting.marketplaceAudienceTargeting.include';
export const getMarketplaceAudiencesAndHeaderName = ({ entity, formatMessage, targetingGroupIndex }) =>
    formatMessage(
        {
            id: `${getColumnMessageIdPrefix(entity, marketplaceAudiencesTargetingIncludeField)}.and`,
            defaultMessage: `${marketplaceAudiencesTargetingIncludeField} - {targetingGroupNumber}`,
        },
        { targetingGroupNumber: targetingGroupIndex + 1 }
    );

export const getMarketplaceAudiencesAndExampleText = ({ entity, formatMessage, column, segmentType }) => {
    const typeLabel = formatMessage({ id: `excel.bulk.column.audience.segmentType.${segmentType}`, segmentType });

    return formatMessage(
        {
            id: `${getColumnMessageIdPrefix(entity, marketplaceAudiencesTargetingIncludeField)}.and.example`,
            defaultMessage: `${marketplaceAudiencesTargetingIncludeField} - {segmentType}`,
        },
        { segmentType: typeLabel }
    );
};

export const getSupplyTargetingSelectHeaderName = ({ entity, formatMessage, column }) =>
    formatMessage({
        id: `${getColumnMessageIdPrefix(entity, column.field)}.select`,
        defaultMessage: 'Environment',
    });

export const getEntityMetadataWorksheetName = entityConfig => `${entityConfig.sheetName}_${SHEET_NAME.METADATA}`;

export const getMetadataFromConfig = (excelConfig, { locale, parentAppName, accountName } = {}) => ({
    [METADATA_PROP_NAME.UTC_DATE]: moment().toISOString(),
    version: METADATA_VERSION,
    locale,
    parentAppName,
    accountName,
    entities: map(excelConfig.entityList, 'entity').toString(),
});
export const getMetadataFromWorkbook = workbook => {
    const sheet = workbook.getWorksheet(SHEET_NAME.METADATA);
    const metadata = readKeyValueSheet(sheet);

    return metadata;
};

export const isRecentFile = (configMetadata, workbookMetadata) => {
    const currentDateString = configMetadata[METADATA_PROP_NAME.UTC_DATE];
    const workbookDateString = workbookMetadata[METADATA_PROP_NAME.UTC_DATE];

    const diffDays = Math.abs(moment(currentDateString).diff(moment(workbookDateString), 'days'));
    const isStale = diffDays > STALE_METADATA_TIMEOUT_DAYS;
    if (isStale) {
        throw new CustomValidationError({
            messageCode: 'excel.upload.validations.error.invalid.metadata.stale',
            message: 'File is more than {diffDays} days old, consider downloading a new template.',
            templateParameters: { diffDays },
        });
    }
    return !isStale;
};

const warningMetadataKeys = new Set([METADATA_PROP_NAME.UTC_DATE]);
export const isValidMetadata = (configMetadata, workbookMetadata) => {
    const keys = uniq([...Object.keys(configMetadata), ...Object.keys(workbookMetadata)]);
    const errorKeys = reject(keys, key => warningMetadataKeys.has(key));

    const isValid = errorKeys.every(key => {
        const configValue = configMetadata[key];
        const workbookValue = workbookMetadata[key];

        const isValueValid = isEqual(configValue, workbookValue);
        if (!isValueValid) {
            throw new CustomValidationError({
                messageCode: 'excel.upload.validations.error.invalid.metadata',
                message: 'Incompatible file metadata {details}.',
                templateParameters: { details: `${key} (${configValue} ≠ ${workbookValue})` },
            });
        }
        return isValueValid;
    });

    return isValid;
};

export const getEntityMetadataFromWorkbook = (workbook, entityConfig) => {
    const metadataWorksheetName = getEntityMetadataWorksheetName(entityConfig);
    const sheet = workbook.getWorksheet(metadataWorksheetName);
    const metadata = readKeyValueSheet(sheet);

    return metadata;
};

export const addMetadataToWorkbook = (workbook, metadata) => {
    const sheet = workbook.addWorksheet(SHEET_NAME.METADATA);
    sheet.state = METADATA_SHEET_VISIBILITY;
    entries(metadata).forEach(([key, value]) => {
        const row = sheet.addRow([]);
        row.getCell(1).value = key;
        row.getCell(2).value = value;
        sheet.getColumn(1).width = COLUMN_WIDTH_MEDIUM;
        sheet.getColumn(2).width = COLUMN_WIDTH_MEDIUM;
    });
};

export const getColumnOptionExcelValues = (column, formatMessage) => {
    const labels = column.optionMessagePrefix
        ? column.options.map(id => formatMessage({ id: `${column.optionMessagePrefix}.${id}` }))
        : column.options;

    return [`"${labels.join(',')}"`];
};

export const setColumnStyle = ({ sheet, column, entityConfig, columnIndex, totalRowCount, formatMessage }) => {
    const range = {
        rowStart: entityConfig.headerRowIndex + 1,
        rowEnd: totalRowCount,
        columnStart: columnIndex,
        columnEnd: columnIndex,
    };
    if (column.options) {
        const options = getColumnOptionExcelValues(column, formatMessage);
        setRangeProp({
            sheet,
            ...range,
            propName: 'dataValidation',
            value: { type: 'list', formulae: options },
        });
    }
    if (column.datePicker) {
        setRangeProp({
            sheet,
            ...range,
            propName: 'dataValidation',
            value: {
                type: 'date',
                operator: 'greaterThan',
                allowBlank: true,
                formulae: [START_DATE],
            },
        });
    }
    if (column.isText) {
        sheet.getColumn(columnIndex).numFmt = '@';
    }

    setRangeProp({
        sheet,
        ...range,
        propName: 'alignment',
        value: { vertical: 'top', horizontal: 'left', wrapText: true },
    });

    if (column.right) {
        setRangeProp({
            sheet,
            ...range,
            propName: 'alignment.horizontal',
            value: 'right',
        });
    }
};

export const getColumnField = column => {
    const { field, dynamicFieldPathGetter } = column;
    return dynamicFieldPathGetter ? dynamicFieldPathGetter(column) : field;
};

export const getExcelFieldValue = ({ entityData, column, formatMessage, dictionaryData }) => {
    const { field, transformToExcel, dynamicFieldPathGetter } = column;
    const rawValue = get(entityData, field);

    if ((isNil(rawValue) || rawValue === '') && !dynamicFieldPathGetter) {
        return;
    }

    if (transformToExcel) {
        return transformToExcel(rawValue, { column, formatMessage, entityData, ...dictionaryData });
    }

    if (typeof rawValue === 'object') {
        throwCriticalError(new Error(`Missed excel transformer ${field}`));
    }

    return rawValue;
};

export const getBrandSafetySegmentExcelLabel = (categoryName, riskLevel) => {
    return `${categoryName} (${riskLevel})`;
};

export const checkObjectListForMissingFields = (objectList, requiredFieldsList, formatMessage, cellHeaderName) => {
    const missingRequiredFields = new Set();

    objectList.forEach(object => {
        requiredFieldsList.forEach(requiredField => {
            if (!object.hasOwnProperty(requiredField)) {
                missingRequiredFields.add(requiredField);
            }
        });
    });

    if (missingRequiredFields.size > 0) {
        const missingFieldsMessage = formatMessage(
            { id: 'excel.bulk.upload.transform.fields.missing' },
            { value: [...missingRequiredFields].join(', ') }
        );

        throw new ExcelBulkError(
            formatMessage(
                { id: 'excel.bulk.upload.transform.error.value' },
                { cellHeaderName, value: missingFieldsMessage }
            )
        );
    }
};
