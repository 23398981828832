import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const NameCreateTooltip = ({ messageIdPrefix, showTip }) => (
    <TooltipSection
        tip={
            showTip ? (
                <FormattedMessage
                    id={`${messageIdPrefix}.name.help.subtext`}
                    defaultMessage="To help keep your campaigns organized, decide on a naming convention that works for you, and be consistent. For example: 20% Sale - Desktop - USA"
                />
            ) : null
        }
    >
        <FormattedMessage
            id={`${messageIdPrefix}.name.help`}
            defaultMessage="This is an internal name only - it does not display in your ads."
        />
    </TooltipSection>
);

export default NameCreateTooltip;
