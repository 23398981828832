import React from 'react';
import { noop } from 'lodash';
import creativesApi from 'services/api/creativesApi';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

const successIndication = {
    message: <FormattedMessage id="creative.resubmit.success" defaultMessage="Ads successfully resubmitted" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const errorIndication = {
    message: <FormattedMessage id="creative.resubmit.error" defaultMessage="Please try again" />,
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id="creative.resubmit.error.highlight"
            defaultMessage="There was a technical error. Please try again."
        />
    ),
};

const resubmissionReason = 'LANDING_PAGE_FIX';

export const resubmitCreativesBase =
    ({ creativeIds: itemIds, accountId, onInit = noop, onError = noop, onSuccess = noop }) =>
    dispatch => {
        onInit();

        creativesApi
            .bulkResubmitCreatives(accountId, {
                itemIds,
                resubmissionReason,
            })
            .then(({ results }) => {
                dispatch(addIndication(successIndication));
                onSuccess(results);
            })
            .catch(error => {
                dispatch(addIndication(errorIndication));
                onError();
            });
    };
