'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.CustomDropdownInput = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var inputStyle = function inputStyle(isHidden) {
    return {
        label: 'input',
        background: 0,
        border: 0,
        fontSize: 'inherit',
        opacity: isHidden ? 0 : 1,
        outline: 0,
        padding: 0,
        color: 'inherit',
        width: '100%'
    };
};
// Custom input came to solve autosize issue when long input(search) value is entered
var CustomDropdownInput = function CustomDropdownInput(_ref) {
    var getStyles = _ref.getStyles,
        theme = _ref.theme,
        cx = _ref.cx,
        className = _ref.className,
        innerRef = _ref.innerRef,
        isHidden = _ref.isHidden,
        isDisabled = _ref.isDisabled,
        selectProps = _ref.selectProps,
        props = _objectWithoutProperties(_ref, ['getStyles', 'theme', 'cx', 'className', 'innerRef', 'isHidden', 'isDisabled', 'selectProps']);

    return (
        // eslint-disable-next-line react/no-unknown-property
        _react2.default.createElement(
            'div',
            { css: getStyles('input', Object.assign({ theme: theme }, props)), style: { width: isHidden ? 0 : '100%' } },
            _react2.default.createElement('input', Object.assign({
                className: cx({ input: true }, className),
                ref: innerRef,
                style: inputStyle(isHidden),
                disabled: isDisabled
            }, props))
        )
    );
};

exports.CustomDropdownInput = CustomDropdownInput;
CustomDropdownInput.propTypes = {
    getStyles: _propTypes2.default.func,
    theme: _propTypes2.default.object,
    cx: _propTypes2.default.func,
    className: _propTypes2.default.string,
    innerRef: _propTypes2.default.func,
    isHidden: _propTypes2.default.bool,
    isDisabled: _propTypes2.default.bool,
    selectProps: _propTypes2.default.object
};