import React from 'react';
import { Spinner } from 'tuui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';

export const DropdownPlaceholder = ({ isLoading }) => {
    const { formatMessage } = useIntl();

    return isLoading ? (
        <Spinner size={17} />
    ) : (
        formatMessage({
            id: 'tracking.setup.gtm.label.select',
            defaultMessage: 'Click to select',
        })
    );
};
