const notificationsPrefix = accountName => `/${accountName}/notifications`;
const getNotificationsPath = accountName => `${notificationsPrefix(accountName)}`;
const notificationsEventsPath = accountName => `${notificationsPrefix(accountName)}/events`;

const buildGenericEvent = (notificationIds, keyTime) =>
    notificationIds.map(notificationId => ({
        notificationId,
        [keyTime]: Date.now(),
    }));

export const notificationsApiFactory = ({ callGetApi, callPostApi }) => ({
    readNotifications: accountName => callGetApi(getNotificationsPath(accountName)),
    dismissNotification: ({ accountName, notificationIds }) =>
        callPostApi(notificationsEventsPath(accountName), buildGenericEvent(notificationIds, 'dismissTime')),
    viewNotifications: ({ accountName, notificationIds }) =>
        callPostApi(notificationsEventsPath(accountName), buildGenericEvent(notificationIds, 'viewTime')),
    clickNotification: ({ accountName, notificationIds }) =>
        callPostApi(notificationsEventsPath(accountName), buildGenericEvent(notificationIds, 'clickTime')),
    actionNotification: ({ accountName, notificationIds }) =>
        callPostApi(notificationsEventsPath(accountName), buildGenericEvent(notificationIds, 'actionTime')),
});
