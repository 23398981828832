import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { TaboolaGraph, GraphTooltip } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from '../../../../../../services/constants';
import GraphMessage from './GraphMessage';
import styles from './graph.module.scss';

const classNameBuilder = classnames.bind(styles);
const EMPTY_METRICS = [];

export const Graph = ({
    dataStatus,
    values,
    legendValues,
    config,
    metrics = EMPTY_METRICS,
    className,
    overlay,
    locale,
    reportStartDate,
    reportEndDate,
    type = GRAPHS.LINE,
    ...rest
}) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const localMetrics = useMemo(
        () =>
            metrics.map(({ name, label, suffix = '', labelMessageSuffix, ...rest }) => {
                const message = formatMessage({
                    id: labelMessageSuffix
                        ? `app.campaigns.reports.grid.column.header.message.${label}`
                        : `app.campaigns.reports.grid.column.header.message.${name}`,
                    defaultMessage: label,
                });
                return {
                    ...rest,
                    name,
                    label: `${message}${suffix}`,
                };
            }),
        [metrics, formatMessage]
    );

    // prettier-ignore
    const {
        graphProps = {},
        maxAllowedSelectedMetrics,
        maxXAxisValues,
        numLegendValues,
        graphTooltip = GraphTooltip,
        graphLegendTooltip,
        singleYAxisId,
        singleYAxisColor,
        singleYAxisMetricForFormatter,
        emptyMetricsMsgIdOverride,
    } = config || {};

    let {
        xAxisDataKey = '',
        xAxisDataKeyWithIntl,
        xAxisMinTickGap,
        xAxisFormatter,
        getXAxisFormatter: getXAxisFormatterProp,
        xAxisComponentRenderer,
    } = graphProps;

    const finalXAxisDataKey = useMemo(() => {
        if (xAxisDataKeyWithIntl) {
            return xAxisDataKeyWithIntl(intl);
        }

        return xAxisDataKey;
    }, [intl, xAxisDataKey, xAxisDataKeyWithIntl]);

    if (!config) {
        return null;
    }

    const getIndividualConfig = config => {
        if (typeof config === 'function') {
            return config({ reportStartDate, reportEndDate });
        }
        return config;
    };

    const getXAxisFormatter = () => {
        if (xAxisFormatter) {
            return xAxisFormatter;
        }
        if (getXAxisFormatterProp) {
            return getXAxisFormatterProp({ reportStartDate, reportEndDate });
        }
        return null;
    };

    const renderTooltipContent = (disabled, payload) =>
        graphLegendTooltip
            ? graphLegendTooltip(payload)
            : disabled && (
                  <FormattedMessage
                      id="app.campaigns.graph.disabled.metric.tooltip"
                      defaultMessage="Only {value} metrics can be active at a time"
                      values={{ value: maxAllowedSelectedMetrics }}
                  />
              );
    const NoDataOverlayGraphMessage = () => (
        <GraphMessage status={dataStatus} hidden={Boolean(dataStatus === COMPONENT_STATUS.ACTIVE && values?.length)} />
    );

    const NoEdgesOverlayGraphMessage = () => (
        <GraphMessage msgIdOverride={emptyMetricsMsgIdOverride} status={COMPONENT_STATUS.EMPTY} />
    );
    const SpinnerContainer = () => (
        <div className={styles['spinner-container']}>
            <Spinner size={35} />
        </div>
    );

    const shouldDisplayGraph = !overlay && dataStatus === COMPONENT_STATUS.ACTIVE && !isEmpty(config);
    const shouldDisplaySpinner = !overlay && (dataStatus === COMPONENT_STATUS.LOADING || isEmpty(config));

    return (
        <div className={classNameBuilder('container', className)}>
            {shouldDisplayGraph && (
                <TaboolaGraph
                    {...rest}
                    type={type}
                    data={{ values, legendValues }}
                    config={localMetrics}
                    {...graphProps}
                    xAxisDataKey={finalXAxisDataKey}
                    maxAllowedSelectedEdges={maxAllowedSelectedMetrics}
                    renderTooltipContent={renderTooltipContent}
                    containerClassName={styles['graph-container']}
                    NoDataOverlayComponent={NoDataOverlayGraphMessage}
                    NoEdgesOverlayComponent={NoEdgesOverlayGraphMessage}
                    graphTooltip={graphTooltip}
                    singleYAxisId={singleYAxisId}
                    singleYAxisColor={singleYAxisColor}
                    singleYAxisMetricForFormatter={singleYAxisMetricForFormatter}
                    isLineAnimationActive={false}
                    isBarAnimationActive={false}
                    isTooltipAnimationActive={false}
                    xAxisMinTickGap={getIndividualConfig(xAxisMinTickGap)}
                    xAxisFormatter={getXAxisFormatter()}
                    xAxisComponentRenderer={xAxisComponentRenderer}
                    legendFooter={
                        (maxXAxisValues || numLegendValues) && (
                            <FormattedMessage
                                id="app.campaigns.graph.legend.footer"
                                defaultMessage="*Displaying report's first {value} results."
                                values={{ value: maxXAxisValues || numLegendValues }}
                            />
                        )
                    }
                />
            )}
            {shouldDisplaySpinner && <SpinnerContainer />}
            {overlay && <div className={styles['overlay-container']}>{overlay}</div>}
        </div>
    );
};

Graph.propTypes = {
    dataStatus: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    values: PropTypes.array,
    legendValues: PropTypes.object,
    config: PropTypes.object,
    className: PropTypes.string,
    overlay: PropTypes.node,
    type: PropTypes.oneOf(Object.values(GRAPHS)),
};

export default Graph;
