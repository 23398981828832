import React from 'react';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { sharedBudgetApi } from 'services/api/sharedBudgetApi/sharedBudgetApi';
import { errorMessagesUtils } from 'services/utils';
import { requestSaveCampaignsGroup, saveCampaignsGroupSuccess, saveCampaignsGroupError } from '../actions';

const successIndication = {
    message: <FormattedMessage id="campaigns.group.save.success" defaultMessage="Group successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="campaigns.group.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const saveCampaignsGroup =
    (accountName, campaignsGroupId, changes, onError, onSuccess, api = sharedBudgetApi) =>
    async dispatch => {
        const campaignChanges = changes;

        dispatch(requestSaveCampaignsGroup());
        try {
            const response = await api.updateCampaignGroup(accountName, campaignsGroupId, campaignChanges);
            dispatch(addIndication(successIndication));
            dispatch(saveCampaignsGroupSuccess(response));
            onSuccess?.();

            return response;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(saveCampaignsGroupError(campaignsGroupId, error));
            onError?.(error);
            throw error;
        }
    };

export default saveCampaignsGroup;
