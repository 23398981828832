'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Buttons = require('../../Buttons');

var _GalleryItem = require('../GalleryItem');

var _GalleryItem2 = _interopRequireDefault(_GalleryItem);

var _AddGallerySVGIcon = require('./AddGallerySVGIcon');

var _AddGallerySVGIcon2 = _interopRequireDefault(_AddGallerySVGIcon);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'IconButtonGalleryItem__taboola-svg-icon___xVpSA',
    'inner-button': 'IconButtonGalleryItem__inner-button___kART_'
};


var IconButtonGalleryItem = function IconButtonGalleryItem(_ref) {
    var iconComponent = _ref.iconComponent,
        onClick = _ref.onClick,
        rest = _objectWithoutProperties(_ref, ['iconComponent', 'onClick']);

    return _react2.default.createElement(
        _GalleryItem2.default,
        rest,
        _react2.default.createElement(
            _Buttons.StyledWithModeButton,
            {
                className: styles['inner-button'],
                onClick: onClick,
                size: _Buttons.STYLED_WITH_MODE_BUTTON_SIZE.REGULAR,
                type: _Buttons.STYLED_WITH_MODE_BUTTON_TYPE.ICON_BUTTON
            },
            iconComponent
        )
    );
};

IconButtonGalleryItem.propTypes = {
    /** Class added to img element */
    className: _propTypes2.default.string,
    /** Componnet inside the button */
    iconComponent: _propTypes2.default.node,
    /** button onClick handler */
    onClick: _propTypes2.default.func
};

IconButtonGalleryItem.defaultProps = {
    className: '',
    onClick: function onClick() {},
    iconComponent: _react2.default.createElement(_AddGallerySVGIcon2.default, null)
};

exports.default = IconButtonGalleryItem;