/**
 * Created by oreuveni
 * Date: 2019-01-15
 * Time: 17:07
 */
import encodeurl from 'encodeurl';
import selectedAccountSelector from '../../../../../selectors/selectedAccount';
import { saveCreative as campaignsSaveCreative, saveBrandsVideoCreative, saveRssCreative } from '../../../flows';
import { isBrandsVideoCreative, isRssChild } from '../config/predicates';

const saveCreative = (newValue, creative) => (dispatch, getState) => {
    const { accountName } = creative;
    const { accountId } = selectedAccountSelector(getState());
    const { url } = newValue;
    const savedValue = url ? { ...newValue, url: encodeurl(url) } : newValue;

    if (isRssChild(creative)) {
        dispatch(saveRssCreative(accountName || accountId, creative, savedValue));
    } else if (isBrandsVideoCreative(creative)) {
        dispatch(saveBrandsVideoCreative(accountName || accountId, creative, savedValue));
    } else {
        dispatch(campaignsSaveCreative(accountName || accountId, creative, savedValue));
    }
};

export default saveCreative;
