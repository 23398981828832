import React from 'react';
import PropTypes from 'prop-types';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import FormLayout from '../../../../components/Form/FormLayout';
import CampaignEditorFormBase from '../../../common-campaign-form/CampaignEditorFormBase';
import { videoCampaignDuplicateValues } from '../../../common-campaign-form/config/campaignDuplicateValues';
import { VideoCampaignEditorContent } from '../../components/VideoCampaignEditorContent/VideoCampaignEditorContent';

const VideoCampaignEditor = ({ onCancel }) => (
    <CampaignEditorFormBase onCancel={onCancel} ContentComponent={VideoCampaignEditorContent} />
);

VideoCampaignEditor.propTypes = { onCancel: PropTypes.func };

const VideoCampaignEditorWithFormDataProvider = withFormDataProvider(VideoCampaignEditor, {
    defaultValuesConfig: videoCampaignDuplicateValues,
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { VideoCampaignEditorWithFormDataProvider as VideoCampaignEditor };
