import { useMemo } from 'react';
import { FORM_MODES } from 'config/formModes';
import {
    insignificantChangeValidations,
    insignificantURLChangeValidations,
    bulkInsignificantURLChangeValidations,
    bulkInsignificantChangeValidations,
} from 'modules/campaigns/config/validations/creative/insignificantChangeValidations';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';

//undefined is needed for the grid in-line editors
const ELIGIBLE_FORM_MODES = [FORM_MODES.BULK_EDIT, FORM_MODES.EDIT, undefined];

export const useInsignificantChangesValidation = ({ baseValidations, isForUrl }) => {
    const isBlockInsignificantChangesEnabled = useConfigMatch({
        [FEATURE_FLAGS.BLOCK_INSIGNICANT_CREATIVE_CHANGES_ENABLED]: 'true',
    });

    const { mode: formMode } = useFormDataContext();

    const isEligibleFormMode = ELIGIBLE_FORM_MODES.includes(formMode) || ELIGIBLE_FORM_MODES.includes(formMode);

    return useMemo(() => {
        if (!isBlockInsignificantChangesEnabled || !isEligibleFormMode) {
            return baseValidations;
        }
        if (formMode === FORM_MODES.BULK_EDIT && isForUrl) {
            return [...bulkInsignificantURLChangeValidations, ...baseValidations];
        }
        if (formMode === FORM_MODES.BULK_EDIT) {
            return [...bulkInsignificantChangeValidations, ...baseValidations];
        }
        if (isForUrl) {
            return [...insignificantURLChangeValidations, ...baseValidations];
        }
        return [...insignificantChangeValidations, ...baseValidations];
    }, [formMode, isBlockInsignificantChangesEnabled, isEligibleFormMode, isForUrl, baseValidations]);
};
