export const YahooIcon = ({ props }) => (
    <svg
        width={22}
        height={19}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 21"
        name="yahoo-account-icon"
        {...props}
        style={{ marginLeft: 2, marginTop: 5 }}
    >
        <path d="M 0.417969 4.382812 L 4.074219 4.382812 L 6.199219 10.136719 L 8.355469 4.382812 L 11.914062 4.382812 L 6.554688 17.996094 L 2.976562 17.996094 L 4.441406 14.390625 Z M 16.0625 8.980469 L 12.074219 8.980469 L 15.613281 0.00390625 L 19.582031 0.00390625 Z M 13.117188 9.835938 C 14.34375 9.835938 15.332031 10.886719 15.332031 12.175781 C 15.332031 13.464844 14.34375 14.515625 13.117188 14.515625 C 11.894531 14.515625 10.90625 13.464844 10.90625 12.175781 C 10.90625 10.886719 11.894531 9.835938 13.117188 9.835938 Z M 13.117188 9.835938 " />
    </svg>
);
