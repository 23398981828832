"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgRightSmall(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M9.20921 8.21936C8.93026 7.94042 8.93026 7.48816 9.20921 7.20921C9.48816 6.93026 9.94042 6.93026 10.2194 7.20921L14.5051 11.4949C14.784 11.7739 14.784 12.2261 14.5051 12.5051L10.2194 16.7908C9.94042 17.0697 9.48816 17.0697 9.20921 16.7908C8.93026 16.5118 8.93026 16.0596 9.20921 15.7806L12.9898 12L9.20921 8.21936Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgRightSmall;