import { matchPath, generatePath } from 'react-router-dom';
import { get, isEmpty, isNil, keys, omitBy, values, keyBy, identity } from 'lodash';
import { ACCOUNT_ID } from 'hooks/queryParams/useSelectedAccountId';
import { FORM_MODES } from '../../../../config/formModes';
import {
    ENTITY_PARAM_TEMPLATE,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_TEMPLATE,
    MODULE_TEMPLATE,
    PATH_TO_FORM_MODE_MAP,
} from '../../../../config/routes';
import { ACCOUNT_PARAM_TEMPLATE } from '../../../campaigns/config/routes/account';
import { SEARCH_TERM } from '../../../campaigns/modules/campaigns-reports/hooks/useSearchTerm';
import { parseQueryParams } from '../../query-params';

export const ENTITY_ID_PARAM_NAME = 'entityId';
export const ENTITY_ID_PARAM_TEMPLATE = `/:${ENTITY_ID_PARAM_NAME}?`;
export const PARENT_ENTITY_ID_PARAM_NAME = 'parentEntityId';
export const PARENT_ENTITY_ID_PARAM_TEMPLATE = `/:${PARENT_ENTITY_ID_PARAM_NAME}?`;
const BASE_FORM_ROUTE_PATH = `${MODULE_TEMPLATE}?${ENTITY_PARAM_TEMPLATE}?${MODE_PARAM_TEMPLATE}?`;
export const FORM_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${PARENT_ENTITY_ID_PARAM_TEMPLATE}${ENTITY_ID_PARAM_TEMPLATE}`;

const PARAMS_TO_CONTEXT_MAP = {
    [ACCOUNT_ID]: {
        queryParamName: ACCOUNT_ID,
        contextKey: 'accountId',
        toQueryParamValue: (accountName, { accountMap }) => {
            const accountId = values(accountMap).find(account => account.accountName === accountName)?.accountId;

            return accountId;
        },
        toContextValue: (accountId, { accountMap }) => get(accountMap, `${accountId}.accountName`, accountId),
    },
    [SEARCH_TERM]: {
        queryParamName: SEARCH_TERM,
        contextKey: 'reportSearch',
        toQueryParamValue: identity,
        toContextValue: identity,
    },
};

const CONTEXT_TO_PARAMS_MAP = keyBy(PARAMS_TO_CONTEXT_MAP, 'contextKey');

const cleanEmptyParams = params => omitBy(params, value => isNil(value));

export const getUrl = (context = {}, accountMap) => {
    if (isEmpty(context)) {
        return '';
    }
    if (context.mode) {
        try {
            const url = generatePath(FORM_ROUTE_PATH, { ...context, mode: FORM_MODE_TO_PATH_MAP[context.mode] });
            return url;
        } catch (error) {
            return '';
        }
    }

    const queryParams = `${keys(context)
        .filter(contextKey => {
            return CONTEXT_TO_PARAMS_MAP[contextKey] && !isNil(context[contextKey]);
        })
        .map(contextKey => {
            const config = CONTEXT_TO_PARAMS_MAP[contextKey];
            const queryParamName = config.queryParamName;
            return `${queryParamName}=${config.toQueryParamValue(context[contextKey], {
                accountMap,
                context,
            })}`;
        })
        .join('&')}`;

    if (!queryParams && context.metadata?.currentUrl) {
        return context.metadata.currentUrl;
    }

    return `?${queryParams}`;
};

export const getContext = ({ url, userId, accountMap, accountName, globalAccountName, campaignId }) => {
    const result = {
        metadata: { currentUrl: url },
    };
    const [path, search] = url.split('?');
    const match = matchPath(path, FORM_ROUTE_PATH);
    const matchParams = match?.params;
    const queryParams = parseQueryParams(search);
    const isForm = matchParams && matchParams.mode;

    const contextFromQueryParams = keys(queryParams).reduce((acc, queryParamName) => {
        const config = PARAMS_TO_CONTEXT_MAP[queryParamName];
        if (config) {
            acc[config.contextKey] = config.toContextValue(queryParams[queryParamName], {
                accountMap,
                queryParams,
            });
        }
        return acc;
    }, {});
    if (!isForm) {
        return {
            userId,
            globalAccountName,
            campaignId,
            ...result,
            ...matchParams,
            ...contextFromQueryParams,
        };
    }

    const accountIdContextKey = PARAMS_TO_CONTEXT_MAP[ACCOUNT_ID].contextKey;
    const accountId = matchParams.accountId || accountName || contextFromQueryParams[accountIdContextKey];
    matchParams.mode = PATH_TO_FORM_MODE_MAP[matchParams.mode];

    if (matchParams.mode !== FORM_MODES.CREATE && !matchParams.entityId) {
        const updatedParams = { ...matchParams, entityId: matchParams.parentEntityId };
        delete updatedParams.parentEntityId;
        return {
            userId,
            campaignId,
            ...result,
            ...cleanEmptyParams(updatedParams),
            accountId,
            globalAccountName,
        };
    }

    return {
        userId,
        campaignId,
        ...result,
        ...cleanEmptyParams(matchParams),
        accountId,
        globalAccountName,
    };
};
