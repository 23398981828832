import { generatePath } from 'react-router';
import { ENTITY_PARAM_NAME, FORM_MODE_TO_PATH_MAP, MODE_PARAM_NAME, MODULE_PARAM_NAME } from 'config/routes';
import { ENTITY_TO_PATH_MAP, MODULE_NAME, ACCOUNT_ID_PARAM_NAME, SCHEDULED_REPORTS_ID_PARAM } from '../config';

export const generateScheduledReportFormPath = (
    pathTemplate,
    { module = MODULE_NAME, entity, mode, accountId, rsId }
) => {
    const modePath = FORM_MODE_TO_PATH_MAP[mode];
    const entityPath = ENTITY_TO_PATH_MAP[entity];
    return generatePath(pathTemplate, {
        [MODULE_PARAM_NAME]: module,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
        [SCHEDULED_REPORTS_ID_PARAM]: rsId,
    });
};
