import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../hooks';
import { accountsApiFactory } from './accountsApiFactory';
import { additionalDataByNameKey } from './keys';

export const useAccountsApi = () => {
    const api = useApi();
    const accountsApi = useMemo(() => accountsApiFactory(api), [api]);

    return accountsApi;
};

export const useGetAccountAdditionalDataByName = ({ accountName }) => {
    const { getAccountAdditionalDataByName } = useAccountsApi();

    const queryKey = useMemo(() => additionalDataByNameKey({ accountName }), [accountName]);
    const query = useQuery(queryKey, () => getAccountAdditionalDataByName(accountName), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    return query;
};
