import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { DropdownField } from '../../../../components/Form';
import TooltipSection from '../TooltipSection';

const TimezoneDropdown = ({ selectedTimezone, timezones, onChange, helpText }) => {
    const { formatMessage } = useIntl();
    const timeZoneOptions = useMemo(
        () =>
            timezones
                .map(({ value, label }) => {
                    const tzValue = moment().tz(value);
                    return {
                        value,
                        label: `(GMT ${tzValue.format('Z')}) ${formatMessage({
                            id: `campaign.editor.schedule.time.zone.${value}`,
                            defaultMessage: label,
                        })}`,
                        utcOffset: tzValue.utcOffset(),
                    };
                })
                .sort((prev, curr) => prev.utcOffset - curr.utcOffset),
        [formatMessage, timezones]
    );

    return (
        <DropdownField
            id="timezone-dropdown"
            label={<FormattedMessage id="campaign.editor.schedule.time.zone" defaultMessage="Time Zone" />}
            helpText={
                helpText ?? (
                    <TooltipSection>
                        <FormattedMessage
                            id="campaign.editor.schedule.timezone.tooltip.text"
                            defaultMessage="We’ll schedule your campaign to run based on the selected time zone. By default your account time zone is selected."
                        />
                    </TooltipSection>
                )
            }
            options={timeZoneOptions}
            selectedValueObject={{ value: selectedTimezone }}
            onChange={onChange}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'TimezoneDropdown',
            }}
        />
    );
};

TimezoneDropdown.propTypes = {
    timezones: PropTypes.array,
    selectedTimezone: PropTypes.string,
    onChange: PropTypes.func,
};

export default TimezoneDropdown;
