import { useCallback, useState } from 'react';
import { useDebouncedValue } from 'taboola-ultimate-ui';
import { TARGETING_TYPES } from '../../../../campaigns/modules/common-campaign-form';
import { useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import useCustomContextualRelatedTopics from '../../CustomContextualTopicsSection/useCustomContextualRelatedTopics';
import useSearchCustomContextualTopics from '../../CustomContextualTopicsSection/useSearchCustomContextualTopics';

export const useCustomContextualTopicsDropdown = () => {
    const { value: includedTopics, onChange: setIncludedTopics } = useFormFieldValue({
        field: 'terms.include',
    });
    const { value: excludedTopics, onChange: setExcludedTopics } = useFormFieldValue({ field: 'terms.exclude' });

    const [targetingTypeOption, setTargetingTypeOption] = useState(TARGETING_TYPES.INCLUDE);
    const onItemClick = useCallback(
        topic => {
            const [topicToAddTo, setTopicToAddTo] =
                targetingTypeOption === TARGETING_TYPES.INCLUDE
                    ? [includedTopics, setIncludedTopics]
                    : [excludedTopics, setExcludedTopics];

            const newTopics = [...topicToAddTo];
            if (topicToAddTo === includedTopics) {
                if (newTopics.length === 0) {
                    newTopics.push([]);
                }
                newTopics[0] = [...newTopics[0], topic.name];
                if (includedTopics[0] && includedTopics[0].includes(topic.name)) {
                    setTopicToAddTo([newTopics[0].filter(to => to !== topic.name)]);
                    return;
                }
            } else {
                newTopics.push(topic.name);
            }
            setTopicToAddTo(newTopics);
        },
        [includedTopics, setIncludedTopics, targetingTypeOption, excludedTopics, setExcludedTopics]
    );
    const [searchInputValue, setSearchInputValue] = useState('');
    const debouncedSearchInputValue = useDebouncedValue(searchInputValue);

    const onSelectItemType = useCallback(
        ({ value }) => {
            setTargetingTypeOption(value);
        },
        [setTargetingTypeOption]
    );
    const { searchTopics, isSearchTopicsLoading } = useSearchCustomContextualTopics(debouncedSearchInputValue);
    const { relatedTopics, isRelatedTopicsLoading } = useCustomContextualRelatedTopics();

    return {
        searchTopics,
        relatedTopics,
        onItemClick,
        searchInputValue,
        setSearchInputValue,
        onSelectItemType,
        isSearchTopicsLoading,
        isRelatedTopicsLoading,
        targetingTypeOption,
    };
};
