import classnames from 'classnames/bind';
import { NotificationsOutlinedIcon } from 'tuui';
import { useSelectedAccount } from 'hooks';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { NewNotificationTooltip } from '../NewNotificationTooltip/NewNotificationTooltip';
import { NewNotificationsIndication } from '../NewNotificationsIndication/NewNotificationsIndication';
import styles from './newNotificationsIndicator.module.scss';

const classNameBuilder = classnames.bind(styles);

export const NewNotificationsIndicator = ({ toggleNotificationsCenter, newNotificationsCount, open }) => {
    const [{ id: accountNumericId }] = useSelectedAccount();

    return (
        <div
            className={styles['indication-container']}
            onClick={toggleNotificationsCenter}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-account-id={accountNumericId}
            data-metrics-component="NotificationsCenterButton"
        >
            <NewNotificationsIndication notificationsCount={newNotificationsCount} />
            <div
                className={classNameBuilder('icon-container', {
                    ring: newNotificationsCount,
                })}
            >
                <NotificationsOutlinedIcon
                    className={classNameBuilder('icon', {
                        ring: newNotificationsCount,
                    })}
                    aria-label="Notifications Icon"
                />
            </div>
            {!open && <NewNotificationTooltip newNotificationsCount={newNotificationsCount} />}
        </div>
    );
};
