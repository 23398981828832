import React from 'react';
import { useMountedState } from 'react-use';
import { noop, isEqual } from 'lodash';
import { Button, Spinner } from 'tuui';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import { useAiTextSuggestions, useAiVariationsContext } from '../../../AiVariationsContextProvider';
import { useAiVariationsEventsPrefix } from '../../../AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import styles from './generateDetailsButton.module.scss';

const tooltipMessage = () => (
    <FormattedMessage
        id="creative.creator.aiVariationsSetup.generate.button.tooltip"
        defaultMessage="Once the product details are entered either through a landing page or manually inputted, the Generate with AI feature will become available."
    />
);

export const GenerateDetailsButton = ({
    isGenerating,
    setGenerationStatus,
    disabled,
    campaignId,
    onSuccess = noop,
    onError = noop,
}) => {
    const { fetchNewSuggestedTextBatch } = useAiTextSuggestions({
        campaignId,
    });
    const { productDescription, targetAudience, uniqueSellingProposition, latestGeneratedProductDetails } =
        useAiVariationsContext();
    const eventsPrefix = useAiVariationsEventsPrefix();
    const isMounted = useMountedState();
    const getTitlesAndDescriptions = async () => {
        setGenerationStatus(COMPONENT_STATUS.LOADING);
        const currentProductDetails = {
            productDescription,
            targetAudience,
            uniqueSellingProposition,
        };
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${eventsPrefix}_Generate_With_AI_Button`,
            taboolaCampaignId: campaignId,
        });
        if (latestGeneratedProductDetails && !isEqual(latestGeneratedProductDetails, currentProductDetails)) {
            const value = JSON.stringify({ latestGeneratedProductDetails, currentProductDetails });
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${eventsPrefix}_LP_Product_Details_Manual_Change`,
                taboolaCampaignId: campaignId,
                value,
            });
        }
        const { results, error } = await fetchNewSuggestedTextBatch();
        if (!isMounted()) {
            return;
        }
        if (error) {
            setGenerationStatus(COMPONENT_STATUS.ERROR);
            return onError(error);
        }
        if (results) {
            setGenerationStatus(COMPONENT_STATUS.ACTIVE);
            return onSuccess(results);
        }
    };

    return (
        <TooltipWrapper
            wrap={disabled}
            tooltipContent={tooltipMessage()}
            tooltipOptions={{ position: TOOLTIP_POSITION.TOP, arrow: true }}
        >
            <Button
                onClick={getTitlesAndDescriptions}
                disabled={disabled || isGenerating}
                variant={Button.variant.text}
                className={styles['button']}
            >
                {isGenerating ? (
                    <Spinner size={14} className={styles['spinner']} color="primary" />
                ) : (
                    <FormattedMessage
                        id="creative.creator.aiVariationsSetup.generate.button"
                        defaultMessage="Generate with AI"
                    />
                )}
            </Button>
        </TooltipWrapper>
    );
};
