import { useState, useMemo, useCallback } from 'react';
import { forEach, map } from 'lodash';
import { set as fpSet } from 'lodash/fp';
import { EMPTY_SEGMENT_GROUP_COUNT, SEGMENT_GROUP_TYPE } from '../utils/andLogicUtils';

const getTypeCountMap = (segmentTypesArray, index) => {
    const typeCountMap = { ...EMPTY_SEGMENT_GROUP_COUNT };

    forEach(segmentTypesArray, (segmentType, otherIndex) => {
        if (index !== otherIndex) {
            typeCountMap[segmentType]++;
        }
    });

    return typeCountMap;
};

export const useMarketplaceAudienceSegmentTypeCounts = marketplaceAudienceTargetingCollection => {
    const [marketplaceAudienceSegmentType, setMarketplaceAudienceSegmentType] = useState([]);

    const setMarketplaceAudienceSegmentTypeByIndex = useCallback((index, type) => {
        // only update if the type is different
        setMarketplaceAudienceSegmentType(prev => (prev[index] === type ? prev : fpSet(index, type, prev)));
    }, []);

    // map index to an object with a count representing the other targetings segment type
    //  (using marketplaceAudienceTargetingCollection so that we don't have to clean up marketplaceAudienceSegmentType)
    // e.g. [{ AGE: 1, GENDER: 0, OTHER: 1, EMPTY: 1 }, { AGE: 0, GENDER: 1, OTHER: 1, EMPTY: 1 }]
    const marketplaceAudienceSegmentTypeCounts = useMemo(() => {
        const segmentTypesArray = map(
            marketplaceAudienceTargetingCollection,
            (_, index) => marketplaceAudienceSegmentType[index] || SEGMENT_GROUP_TYPE.EMPTY
        );

        return map(marketplaceAudienceTargetingCollection, (_, index) => getTypeCountMap(segmentTypesArray, index));
    }, [marketplaceAudienceTargetingCollection, marketplaceAudienceSegmentType]);

    return { setMarketplaceAudienceSegmentTypeByIndex, marketplaceAudienceSegmentTypeCounts };
};
