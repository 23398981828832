"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgEdit(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M19.091 9.306l-.438.436-4.398-4.393.438-.437a3.107 3.107 0 1 1 4.398 4.394zm-1.85 1.85l-2.918 2.916-3.934 3.933a4.435 4.435 0 0 1-1.74.985l-3.845.961a.573.573 0 0 1-.755-.755l.961-3.843a4.42 4.42 0 0 1 .985-1.741l3.934-3.934 2.918-2.916 4.394 4.393z"
            })
        )
    );
}
exports.default = SvgEdit;