import { FORM_MODES } from 'modules/campaigns/config';
import { CREATIVE_FORMAT_TYPE } from 'modules/campaigns/modules/creative-creator/components/AdFormatsSection/formatType';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useIsAppInstallCreativeInEditMode = () => {
    const { value: appInstall } = useFormFieldValue({ field: 'appInstall' });
    const { mode } = useFormDataContext();
    const isAppInstall = !!appInstall && (mode === FORM_MODES.EDIT || mode === FORM_MODES.PREVIEW);
    const editFormatType = isAppInstall ? CREATIVE_FORMAT_TYPE.APP_INSTALL : CREATIVE_FORMAT_TYPE.IMAGE_AND_MOTION_AD;
    return { isAppInstall, editFormatType };
};
