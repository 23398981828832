import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { NodeLabel } from '../../Tree';
import { Tree } from '../../Tree';
import SelectAll from '../../Tree/SelectAll/SelectAll';
import { useMarketplaceAudiencesContext } from '../MarketplaceAudiencesContext';
import SegmentLeafContent from '../SegmentLeafContent/SegmentLeafContent';
import { getIsTaboolaTreeSegment } from '../consts/taxonomyFields';
import styles from './segmentsTree.module.scss';

const needRenderCheckbox = ({ depth }, isSeparatedTree) => {
    const minimumRenderCheckboxDepth = isSeparatedTree ? 3 : 2;

    return depth >= minimumRenderCheckboxDepth;
};

const SegmentsTree = ({ innerRef }) => {
    const {
        search,
        isSearchMode,
        loadingMode,
        getNodesTree,
        handleSelectNode,
        handleSelectAll,
        selectedPathsMap,
        disabledPathsMap,
        collapsedPathMap,
        handleCollapseChange,
        handleLoadMore,
        limit,
    } = useMarketplaceAudiencesContext();
    const { tree: displayedTree, count: displayedTreeCount } = useMemo(() => getNodesTree(), [getNodesTree]);

    const [isAllChecked, setIsAllChecked] = useState(false);

    const renderNodeText = useCallback(
        (domProps, { hasChildren, node }) => {
            if (hasChildren) {
                const { label } = node;
                return <NodeLabel node={node} searchText={search} title={label} />;
            }

            return <SegmentLeafContent item={node} searchText={search} />;
        },
        [search]
    );

    const handleSelectAllWrapper = useCallback(() => {
        handleSelectAll(!isAllChecked);
        setIsAllChecked(prev => !prev);
    }, [handleSelectAll, isAllChecked]);

    const isSeparatedTree = useMemo(() => displayedTree.some(getIsTaboolaTreeSegment), [displayedTree]);

    const renderSelectAll = useCallback(() => {
        if (isSearchMode) {
            const messageIdPrefix = isSeparatedTree
                ? 'campaign.editor.marketplace.audiences.separated'
                : 'campaign.editor.marketplace.audiences';

            return (
                <SelectAll onClick={handleSelectAllWrapper} disabled={displayedTreeCount > limit}>
                    <FormattedMessage
                        className={styles['separated-select-all']}
                        id={`${messageIdPrefix}.${isAllChecked ? 'deselectAll' : 'selectAll'}.title`}
                        defaultMessage="Select all {totalItems} results (limited to {limit})"
                        values={{ totalItems: displayedTreeCount, limit }}
                    />
                </SelectAll>
            );
        }
        return null;
    }, [isSearchMode, isSeparatedTree, handleSelectAllWrapper, displayedTreeCount, limit, isAllChecked]);

    return (
        <div className={styles['container']} ref={innerRef} data-testid="segments-tree">
            <Tree
                nodesTree={displayedTree}
                onSelect={handleSelectNode}
                selectedItemsMap={selectedPathsMap}
                totalItems={displayedTreeCount}
                collapsed={collapsedPathMap}
                onCollapsedChange={handleCollapseChange}
                renderNodeText={renderNodeText}
                onLoadMore={handleLoadMore}
                renderSelectAll={renderSelectAll}
                disabledPathsMap={disabledPathsMap}
                noOptionsContent={<FormattedMessage id="app.dropdowns.no.options" defaultMessage="No options" />}
                loadMoreContent={<FormattedMessage id="app.tree.loadMore" defaultMessage="Load More" />}
                loadingMode={loadingMode}
                needRenderCheckbox={node => needRenderCheckbox(node, isSeparatedTree)}
            />
        </div>
    );
};

SegmentsTree.propTypes = {
    innerRef: PropTypes.func,
};

export default SegmentsTree;
