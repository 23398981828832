import { useEffect, useMemo } from 'react';
import { useUpdate } from 'react-use';
import { pxToRem, HeadlessDropdown } from 'taboola-ultimate-ui';

const menuStyles = {
    marginTop: '0%',
    minWidth: '100%',
    borderRadius: `0px 0px ${pxToRem('4px')} ${pxToRem('4px')}`,
};

export const SuggestionOptions = ({
    menuStyles: propMenuStyles,
    dropdownStyles,
    options,
    open,
    onDropdownChange,
    ...rest
}) => {
    const finalMenuStyles = useMemo(
        () => ({
            ...menuStyles,
            ...propMenuStyles,
        }),
        [propMenuStyles]
    );
    const update = useUpdate();

    useEffect(() => {
        if (open) {
            update();
        }
    }, [open, update]);

    return (
        <HeadlessDropdown
            styles={dropdownStyles}
            menuStyles={finalMenuStyles}
            options={options}
            open={open}
            onChange={onDropdownChange}
            selectedValueObject={null}
            {...rest}
        />
    );
};
