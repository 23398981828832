import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './loadingOverlay.module.scss';

const classNameBuilder = classnames.bind(styles);

const defaultLoadingMessage = <FormattedMessage id="loadingOverlay.message.default" defaultMessage="LOADING ..." />;

export const LoadingOverlay = ({ className, children }) => (
    <div className={classNameBuilder('loader-overlay', className)} role="progressbar">
        {children}
    </div>
);

LoadingOverlay.propTypes = {
    /** Class name for custom styling */
    className: PropTypes.string,
    /** Content to display on top the overlay */
    children: PropTypes.node,
};

LoadingOverlay.defaultProps = {
    children: defaultLoadingMessage,
};

export default LoadingOverlay;
