import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../../common-campaign-form/components/TooltipSection';

export const CustomPresetsTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.customPresets.tooltip"
            defaultMessage="Presets are used to set custom targeting options which are created in other internal tools such as offstage."
        />
    </TooltipSection>
);
