import React from 'react';
import EMPTY_FIELD_VALUE from 'modules/campaigns/modules/campaigns-reports/config/defaultValues';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const creativeMsgIdPrefix = 'app.campaigns.creative.status';
const campaignMsgIdPrefix = 'app.campaigns.campaign.status';
const campaignsGroupMsgIdPrefix = 'app.campaigns.campaignsGroup.status';

const buildStatusMessages = msgIdPrefix => status => {
    const msgId = `${msgIdPrefix}.${status}`;
    const tooltipMsgIdPrefix = `${msgIdPrefix}.tooltip`;
    const phaseMsgIdPrefix = `${msgIdPrefix}.phase`;
    const tooltipMsgId = `${tooltipMsgIdPrefix}.${status}`;
    const defaultMessage = status || EMPTY_FIELD_VALUE;
    const formattedStatusMsg = <FormattedMessage id={msgId} defaultMessage={defaultMessage} />;
    const formattedStatusTooltipMsg = <FormattedMessage id={tooltipMsgId} defaultMessage={defaultMessage} />;

    return {
        msgIdPrefix,
        msgId,
        tooltipMsgIdPrefix,
        phaseMsgIdPrefix,
        tooltipMsgId,
        defaultMessage,
        formattedStatusMsg,
        formattedStatusTooltipMsg,
    };
};

const [getCreativeStatusMessages, getCampaignStatusMessages, getCampaignsGroupStatusMessages] = [
    creativeMsgIdPrefix,
    campaignMsgIdPrefix,
    campaignsGroupMsgIdPrefix,
].map(buildStatusMessages);
export { buildStatusMessages, getCreativeStatusMessages, getCampaignStatusMessages, getCampaignsGroupStatusMessages };
