import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from '../modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../modules/taboola-common-frontend-modules/i18n';

export const getAdBlockerDetectedIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.AD_BLOCKER_DETECTED,
    type: INDICATION_TYPES.ERROR,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage
            id="app.campaign.indication.adblockerDetected.highlight"
            defaultMessage="Ad Blocker Detected."
        />
    ),
    message: (
        <FormattedMessage
            id="app.campaign.indication.adblockerDetected.message"
            defaultMessage="It looks like you are using an Ad Blocker. For an improved user experience, we recommend disabling your Ad Blocker for this site and refresh the page."
        />
    ),
});

export default getAdBlockerDetectedIndication;
