import { REQUEST_SAVE_CAMPAIGNS_GROUP, SAVE_CAMPAIGNS_GROUP_SUCCESS, SAVE_CAMPAIGNS_GROUP_ERROR } from './actionTypes';

export const requestSaveCampaignsGroup = () => ({ type: REQUEST_SAVE_CAMPAIGNS_GROUP });

export const saveCampaignsGroupSuccess = campaignsGroup => ({
    type: SAVE_CAMPAIGNS_GROUP_SUCCESS,
    payload: campaignsGroup,
});

export const saveCampaignsGroupError = (campaignsGroupId, error) => ({
    type: SAVE_CAMPAIGNS_GROUP_ERROR,
    payload: { campaignsGroupId, error },
});
