import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useCollapsibleCardContext } from 'components/CommonCollapsibleCard/CollapsibleCardContext';
import { useCurrentValueGetter, useShallowObject } from 'hooks';
import { scrollToElement } from 'modules/taboola-common-frontend-modules/formData/scroll-utils';
import { useFormDataContext } from '../../formData';
import { useSyncEffect } from '../../utils/useSyncEffect';
import { useValidationContext } from '../ValidationContext';
import { useValidationSectionRegistration } from '../components/ValidationSection';

const useSyncDependencyCacheUpdate = ({ isReady, initialDependencies, validationDependenciesGetter }) => {
    const wasSet = useRef(false);

    if (isReady && !wasSet.current) {
        wasSet.current = true;
        initialDependencies.current = validationDependenciesGetter();
    }
};

const useDependenciesOptimizations = ({ isReady, validationDependencies, isDirty, hasInitialData }) => {
    const initialDependencies = useRef(validationDependencies);
    const validationDependenciesShallow = useShallowObject(validationDependencies);
    const getIsDirty = useCurrentValueGetter(isDirty);
    const getHasInitialData = useCurrentValueGetter(hasInitialData);
    const validationDependenciesGetter = useCurrentValueGetter(validationDependencies);

    useSyncDependencyCacheUpdate({
        isReady,
        initialDependencies,
        validationDependenciesGetter,
    });

    const getAreDependenciesAsInitial = useCallback(() => {
        return isEqual(initialDependencies.current, validationDependenciesShallow);
    }, [validationDependenciesShallow]);
    const shouldSkipValidation = useCallback(() => {
        return !getIsDirty() && getHasInitialData() && getAreDependenciesAsInitial();
    }, [getIsDirty, getHasInitialData, getAreDependenciesAsInitial]);

    return { shouldSkipValidation, validationDependenciesShallow, getAreDependenciesAsInitial };
};

export const useValidations = ({
    validations,
    validationId,
    valueToValidate,
    validationDependencies = {},
    isDirty,
    isReady = true,
    hasInitialData = false,
}) => {
    const scrollRef = useRef(null);
    const initialValue = useRef(valueToValidate);
    const { mode } = useFormDataContext();
    const { validationService, onValidationUnmount, scrollAreaRef } = useValidationContext();
    const { setCollapsed } = useCollapsibleCardContext();
    const [failedValidationData, setFailedValidationData] = useState({});
    const [numValidationsRunning, setNumValidationsRunning] = useState(0);
    useValidationSectionRegistration(validationId);
    const isValidationsRunning = numValidationsRunning > 0;
    const isValidRef = useRef(true);

    const triggerValidationEvent = useCallback(
        validationEvent => {
            return validationService.validate(validationEvent);
        },
        [validationService]
    );
    const { shouldSkipValidation, validationDependenciesShallow, getAreDependenciesAsInitial } =
        useDependenciesOptimizations({
            isDirty,
            isReady,
            hasInitialData,
            validationDependencies: { ...validationDependencies, initialValue: initialValue.current, formMode: mode },
            validationId,
        });

    // We return true because of using negative flag in or conditions only
    const isDependenciesAsInitial = useMemo(() => getAreDependenciesAsInitial(), [getAreDependenciesAsInitial]);

    useEffect(
        function registerValidation() {
            validationService.registerValidation(validationId, {
                getFocusPriorityValue: () => scrollRef.current?.getBoundingClientRect().y,
            });
        },
        [validationId, validationService]
    );
    useEffect(
        function setValidations() {
            if (validations) {
                validationService.setValidations(validationId, validations);
            }
        },
        [validationId, validationService, validations]
    );
    useEffect(
        function openCollapsibleCardAndScrollOnInvalidState() {
            const unlisten = validationService.onInvalid(validationId, ({ triggeredManually, isTopPriorityError }) => {
                setCollapsed?.(() => false);
                if (triggeredManually && isTopPriorityError) {
                    scrollToElement(scrollRef.current, scrollAreaRef.current);
                }
            });
            return unlisten;
        },
        [validationService, validationId, setCollapsed, scrollAreaRef]
    );
    useEffect(
        function setMessageOnInvalid() {
            const unlisten = validationService.onInvalid(
                validationId,
                ({ failedValidationData, triggeredManually, forceIgnore }) => {
                    if (forceIgnore) {
                        return;
                    }
                    isValidRef.current = false;
                    const shouldIndicateFailedValidation =
                        isDirty || !isDependenciesAsInitial || hasInitialData || triggeredManually;
                    // Any conditionally visible component should behave like for create if wasn't initially rendered
                    if (shouldIndicateFailedValidation) {
                        setFailedValidationData(failedValidationData);
                    } else {
                        setFailedValidationData({});
                    }
                }
            );
            return unlisten;
        },
        [validationId, validationService, shouldSkipValidation, isDirty, hasInitialData, isDependenciesAsInitial]
    );
    useEffect(
        function clearMessageOnValid() {
            const unlisten = validationService.onValid(validationId, () => {
                isValidRef.current = true;
                setFailedValidationData({});
            });
            return unlisten;
        },
        [validationId, validationService]
    );
    useEffect(
        function markValidationsAsRunning() {
            const unlisten = validationService.onValidationStart(validationId, () => {
                setNumValidationsRunning(prev => prev + 1);
            });
            return unlisten;
        },
        [validationId, validationService]
    );
    useEffect(
        function markValidationsAsNotRunning() {
            const unlisten = validationService.onValidationEnd(validationId, () => {
                setNumValidationsRunning(prev => prev - 1);
            });
            return unlisten;
        },
        [validationId, validationService]
    );

    const applyChange = useCallback(
        (forceChange = false) => {
            if (valueToValidate === undefined && !forceChange) {
                return;
            }
            const metadata = { treatErrorsAsWarning: shouldSkipValidation() };
            validationService.applyChange(validationId, valueToValidate, validationDependenciesShallow, {
                metadata,
            });
        },
        [shouldSkipValidation, validationService, validationId, valueToValidate, validationDependenciesShallow]
    );

    useEffect(
        function syncChangesBeforeSubmit() {
            const unlisten = validationService.onExplicitValidationCalled(() => {
                applyChange(true);
            });

            return unlisten;
        },
        [applyChange, validationService]
    );

    // This effect runs the validations when a change occurs that might effect the validity
    // of the field such as value, dependency etc.
    useEffect(() => {
        if (!isReady) {
            return;
        }

        if (!(hasInitialData || isDirty || !isDependenciesAsInitial)) {
            return;
        }

        applyChange();
    }, [applyChange, isReady, hasInitialData, isDirty, isDependenciesAsInitial]);

    // We want in first render cycle detect if validation should be run
    //(identical criteria as in hook who will trigger applyChange)
    // and mark field as invalid until valid event triggered
    useSyncEffect(() => {
        if (!isReady) {
            return;
        }
        if (!hasInitialData && !isDirty && !isDependenciesAsInitial) {
            return;
        }

        isValidRef.current = false;
    }, [applyChange, isReady, hasInitialData, isDirty, isDependenciesAsInitial]);

    useEffect(() => () => onValidationUnmount?.(validationId), [onValidationUnmount, validationId]);

    return {
        failedValidationData,
        scrollRef,
        triggerValidationEvent,
        isValidationsRunning,
        isValid: isValidRef.current,
    };
};

export default useValidations;
