import { FORM_MODES } from 'config/formModes';
import {
    BULK_DUPLICATE_ROUTE_PATH,
    BULK_EDITOR_ROUTE_PATH,
    CAMPAIGN_GROUP_EDITOR_ROUTE_PATH,
    CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH,
    DIMENSION_TO_PATH_MAP,
    EDITOR_ROUTE_PATH,
    ENTITY,
    PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH,
} from 'modules/campaigns/config/routes';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { generateFormPath } from './generateFormPath';

export const generateCampaignEditorPath = ({
    accountId,
    campaignId,
    mode = FORM_MODES.EDIT,
    dimension = CAMPAIGN_DIMENSION.SPONSORED,
    entity = ENTITY.CAMPAIGN,
}) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        campaignId,
        entity,
        mode,
        dimension,
    });

export const generateCreativeEditorPath = ({
    accountId,
    campaignId,
    mode = FORM_MODES.EDIT,
    dimension = CAMPAIGN_DIMENSION.SPONSORED,
    creativeId,
}) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        accountId,
        campaignId,
        entity: dimension === CAMPAIGN_DIMENSION.VIDEO ? ENTITY.CREATIVE_VIDEO : ENTITY.CREATIVE,
        mode,
        dimension,
        creativeId,
    });

export const generateCreativeCarouselEditorPath = ({ accountId, campaignId, hierarchyRepItemId }) =>
    generateFormPath(CREATIVE_CAROUSEL_EDITOR_ROUTE_PATH, {
        entity: ENTITY.CREATIVE_CAROUSEL,
        mode: FORM_MODES.EDIT,
        dimension: DIMENSION_TO_PATH_MAP[CAMPAIGN_DIMENSION.SPONSORED],
        accountId,
        campaignId,
        hierarchyRepItemId,
    });

export const generateBulkCreativeEditorPath = ({ accountId, dimension }) =>
    generateFormPath(BULK_EDITOR_ROUTE_PATH, {
        accountId,
        entity: ENTITY.CREATIVE,
        mode: FORM_MODES.BULK_EDIT,
        dimension,
    });

export const generateBulkDuplicateEditorPath = ({ accountId, dimension }) =>
    generateFormPath(BULK_DUPLICATE_ROUTE_PATH, {
        accountId,
        entity: ENTITY.CREATIVE,
        mode: FORM_MODES.BULK_DUPLICATE,
        dimension,
    });

export const generateCampaignsGroupEditorPath = ({ accountId, campaignsGroupId, mode = FORM_MODES.EDIT }) =>
    generateFormPath(CAMPAIGN_GROUP_EDITOR_ROUTE_PATH, {
        accountId,
        campaignsGroupId,
        entity: ENTITY.CAMPAIGNS_GROUP,
        mode,
    });

export const generateBulkCampaignsGroupEditorPath = ({ accountId }) =>
    generateFormPath(BULK_EDITOR_ROUTE_PATH, {
        accountId,
        entity: ENTITY.CAMPAIGNS_GROUP,
        mode: FORM_MODES.BULK_EDIT,
    });

export const generatePerformanceRecommendationsReviewerPath = ({ accountId, campaignId }) =>
    generateFormPath(PERFORMANCE_RECOMMENDATIONS_ROUTE_PATH, {
        accountId,
        campaignId,
        entity: ENTITY.PERFORMANCE_RECOMMENDATION,
        mode: FORM_MODES.EDIT,
    });
