import React from 'react';
import { ArticleIcon } from 'tuui';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './EmptyArticle.module.scss';

export const EmptyArticle = () => {
    return (
        <div className={styles['container']}>
            <ArticleIcon className={styles['icon']} data-testid="article-icon" aria-label="Atricle icon" />
            <FormattedMessage
                id="custom.contextual.editor.topics.preview.empty.articles"
                defaultMessage="No available articles on this topic"
                values="No available articles on this topic"
                className={styles['text']}
            />
        </div>
    );
};
