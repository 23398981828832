import React, { useCallback } from 'react';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { TotalValueTooltip } from './TotalValueTooltip/TotalValueTooltip';

export const TotalValueCheckBox = () => {
    const { value: checkBoxValue, onChange } = useFormFieldValue({ field: 'includeInTotalValue' });
    const onSelect = useCallback(() => onChange(true), [onChange]);

    const onUnSelect = useCallback(() => onChange(false), [onChange]);
    return (
        <FormField
            inputId="total-value"
            label={
                <FormattedMessage
                    id="tracking.conversion.properties.total.value.checkbox.label"
                    defaultMessage="Total Conversions’ value"
                />
            }
            description={
                <FormattedMessage
                    id="tracking.conversion.properties.total.value.checkbox.description"
                    defaultMessage="Total conversions' value are the primary conversions’ value to be used for performance reporting."
                />
            }
            helpText={<TotalValueTooltip />}
        >
            <CheckboxGroup onSelectCheckbox={onSelect} onUnselectCheckbox={onUnSelect} selectedValues={[checkBoxValue]}>
                <TextCheckbox
                    value
                    title={
                        <FormattedMessage
                            id="tracking.conversion.properties.total.value.checkbox.title"
                            defaultMessage="Include in total conversions’ value"
                        />
                    }
                    data-metrics-component="Total value checkbox"
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-value={!checkBoxValue}
                />
            </CheckboxGroup>
        </FormField>
    );
};
