import React, { useCallback } from 'react';
import { BareDropdown, CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { ConversionItemDropdownOption } from 'modules/campaigns/modules/common-campaign-form/components/ConversionOptimization/ConversionItemDropdownOption/ConversionItemDropdownOption';
import { withIndication } from 'modules/errors/components/withIndication';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useUnipConversions } from 'modules/tracking/hooks/useUnipConversions';
import { useFormValidatedValue, validationFunctions } from '../../../taboola-common-frontend-modules/validations';
import { ConversionItem } from './ConversionItem';
import { ReactComponent as ConversionFunnelSvg } from './smartlist.svg';
import styles from './funnelConversionsList.module.scss';

const validations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { min: 2 },
        messageId: 'tracking.unip.funnel.setup.validation.two.conversions.minimum',
        defaultMessage: 'A funnel requires at least 2 different conversion events. Please add another event.',
    },
];

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const FunnelConversionsList = () => {
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const {
        value: rules,
        onChange: setRules,
        indicationData,
    } = useFormValidatedValue({
        field: 'rules',
        validations,
    });
    const totalItems = rules?.length;
    const { formatMessage } = useIntl();

    const { options, loadConversionRules, setCurrentOptions } = useUnipConversions(accountId, rules);

    const deleteItem = useCallback(
        ({ id: idToDelete }) => {
            setRules(prev => prev.filter(val => val.id !== idToDelete));
        },
        [setRules]
    );

    const clearItems = useCallback(() => {
        setRules([]);
    }, [setRules]);

    const addItem = useCallback(
        item => {
            const { id, label } = item;
            setRules(prev => [...prev, { id, label }]);
        },
        [setRules]
    );

    const onReorder = useCallback(
        reOrderList => {
            setRules(reOrderList);
        },
        [setRules]
    );

    return (
        <>
            <PaginatedDropdown
                id="funnel-selector"
                containerClassName={styles['container']}
                onChange={addItem}
                searchable
                disabled={!options.length}
                enabledWhileSearching
                options={options}
                loadPage={loadConversionRules}
                placeholder={formatMessage({
                    id: 'tracking.unip.funnel.setup.conversion.in.funnel.placeholder',
                    defaultMessage: 'Select conversion...',
                })}
                onOptionsLoaded={setCurrentOptions}
                DropdownMenuComponent={BareDropdown}
                optionItemRenderer={ConversionItemDropdownOption}
                selectedValueObject={null}
            />
            {totalItems === 0 && (
                <div className={styles['conversionsImg-container']}>
                    <ConversionFunnelSvg className={styles['conversions-img']} alt="Sort conversions by order img" />
                    <div className={styles['conversionsImg-text']}>
                        <FormattedMessage
                            id="tracking.unip.funnel.setup.conversion.in.funnel.img.text"
                            defaultMessage="Sort conversions in a sequence from upper to lower funnel"
                        />
                    </div>
                </div>
            )}
            <CollapsibleListWithIndication
                items={rules}
                metadata={{ totalItems }}
                containerClassName={styles['lists']}
                data-automation="conversion-collapsible-list"
                ItemComponent={ConversionItem}
                onReorder={onReorder}
                isDraggable
                deleteItem={deleteItem}
                clearItems={clearItems}
                listHeaderTitle={
                    <FormattedMessage
                        id="tracking.unip.funnel.setup.conversion.in.funnel.header"
                        defaultMessage="Upper Funnel"
                    />
                }
                listFooterTitle={
                    <FormattedMessage
                        id="tracking.unip.funnel.setup.conversion.in.funnel.footer"
                        defaultMessage="Lower Funnel"
                    />
                }
                {...indicationData}
            />
        </>
    );
};
