"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgPieChart(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 16,
            height: 16,
            viewBox: "0 0 16 16",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M15.9714 8C15.9848 7.80968 16 7.62105 16 7.42905C16 3.3261 12.6739 0 8.57095 0C8.37895 0 8.19032 0.0151579 8 0.0286316V8H15.9714Z",
            fill: "#667686"
        }),
        React.createElement("path", {
            d: "M6.85642 9.14355V1.17218C2.98874 1.46792 0.00103213 4.69195 0 8.57092C0 8.7646 0.0151579 8.95323 0.0286316 9.14355C0.310094 12.7915 3.20847 15.6899 6.85642 15.9713C7.04674 15.9848 7.23537 16 7.42905 16C11.308 15.9989 14.532 13.0112 14.8278 9.14355H6.85642Z",
            fill: "#667686"
        })
    );
}
exports.default = SvgPieChart;