import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { GTM_EVENTS } from '../../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import OutlineEditableText from '../../../../../components/OutlineEditableText';
import { ENTITY_MESSAGE_PREFIX } from '../../../../../config/routes/entity';
import { campaignNameValidations } from '../../../../../config/validations';
import NameCreateTooltip from './NameCreateTooltip';
import styles from './nameCreate.module.scss';

const NameCreate = ({ messageIdPrefix, showTip = true, validations = campaignNameValidations }) => {
    const {
        value: name,
        onChange,
        scrollRef,
        indicationData,
    } = useFormValidatedValue({
        field: 'name',
        validations,
        throttleValue: true,
    });
    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `${messageIdPrefix}.name.placeholder`,
    });

    return (
        <FormField
            ref={scrollRef}
            inputId="campaign-name"
            containerClass={styles['input']}
            label={<FormattedMessage id={`${messageIdPrefix}.name`} />}
            helpText={<NameCreateTooltip messageIdPrefix={messageIdPrefix} showTip={showTip} />}
        >
            <OutlineEditableText
                id="campaign-name"
                className={styles['editable-text']}
                value={name}
                onChange={onChange}
                placeholder={placeholder}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="NameCreate"
                {...indicationData}
            />
        </FormField>
    );
};

NameCreate.defaultProps = {
    messageIdPrefix: ENTITY_MESSAGE_PREFIX.CAMPAIGN,
};

export default NameCreate;
