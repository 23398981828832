import ExternalLink from '../../ExternalLink/ExternalLink';
import { getYahooWrapperLink } from '../utils/getYahooWrapperLink';
import { YahooIcon } from './YahooIcon';
import styles from './AccountItemIcon.module.scss';

export const YahooIconWrapper = ({ hasOuterLink, id }) => {
    if (hasOuterLink) {
        return <YahooIcon />;
    }

    return (
        <ExternalLink
            href={getYahooWrapperLink(id)}
            className={styles['icon-link']}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <YahooIcon />
        </ExternalLink>
    );
};
