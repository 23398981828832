import { REPORT_TYPE } from 'modules/campaigns/config';
import * as ROUTES from './routes';

const { MODULE_NAME } = ROUTES;
const DEFAULT_REPORT = REPORT_TYPE.SEGMENT_CATEGORY;
const DEFAULT_SEGMENT_ID = -1;

export { MODULE_NAME };
export { DEFAULT_REPORT };
export { DEFAULT_SEGMENT_ID };
export {
    default as SEGMENT_SORTABLE_FIELDS,
    defaultSortField,
    defaultSortType,
    sortFieldsOptions,
} from './segmentSortableFields';
