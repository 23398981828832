import { useCallback } from 'react';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';

export const TBL_CREATIVE_PREVIEW = 'tbl_creative_preview';
const useTblCreativePreview = queryParamHookFactory(TBL_CREATIVE_PREVIEW, {
    defaultValue: [],
    serializer: JSON.stringify,
    deserializer: value => {
        if (!value) {
            return [];
        }

        try {
            const parsedValue = JSON.parse(value);
            return parsedValue;
        } catch (err) {
            return [];
        }
    },
});

const TBL_SHOULD_OVERRIDE_ALL_SLOTS = 'tbl_should_override_all_slots';
const useTblShouldOverrideAllSlots = queryParamHookFactory(TBL_SHOULD_OVERRIDE_ALL_SLOTS, {
    defaultValue: false,
});

const TBL_SHOULD_REPEAT_OVERRIDE_ITEMS = 'tbl_should_repeat_override_items';
const useTblShouldRepeatOverrideItems = queryParamHookFactory(TBL_SHOULD_REPEAT_OVERRIDE_ITEMS, {
    defaultValue: true,
});

const CM_MULTI_UNIT_MANAGER_IS_ACTIVE = 'cm_multiUnitManager.isActive';
const useCmMultiUnitManagerIsActive = queryParamHookFactory(CM_MULTI_UNIT_MANAGER_IS_ACTIVE, {
    defaultValue: false,
});
export const TBL_PROPERTIES_OVERRIDE = 'tbl_properties_override';
export const propertiesOverride = {
    'disclosure-position': 'none',
    'before-detail-order-syndicated': 'branding',
    'detail-order-syndicated': 'title,description',
};
const useTblPropertiesOverride = queryParamHookFactory(TBL_PROPERTIES_OVERRIDE, {
    serializer: val => (val ? JSON.stringify(propertiesOverride) : ''),
});

export const useCreativePreviewQueryParams = () => {
    const [creativePreviewParams, setCreativePreviewParams] = useTblCreativePreview();
    const [isRboxItemOverrideActive, setIsRboxItemOverrideActive] = useTblShouldOverrideAllSlots();
    const [shouldRepeatPreviewItems, setShouldRepeatPreviewItems] = useTblShouldRepeatOverrideItems();
    const [shouldAllowMultiVideoPlay, setShouldAllowMultiVideoPlay] = useCmMultiUnitManagerIsActive();
    const [shouldPropertiesOverride, setShouldPropertiesOverride, unsetValueOverride] = useTblPropertiesOverride();

    const setter = useCallback(
        ({ creativePreviewParams, shouldRepeatPreviewItems, shouldPropertiesOverride }) => {
            setCreativePreviewParams(creativePreviewParams);
            setShouldRepeatPreviewItems(shouldRepeatPreviewItems);
            setIsRboxItemOverrideActive(true);
            setShouldAllowMultiVideoPlay(false);
            if (shouldPropertiesOverride) {
                setShouldPropertiesOverride(true);
            } else {
                unsetValueOverride();
            }
        },
        [
            setCreativePreviewParams,
            setShouldRepeatPreviewItems,
            setIsRboxItemOverrideActive,
            setShouldAllowMultiVideoPlay,
            setShouldPropertiesOverride,
            unsetValueOverride,
        ]
    );

    return {
        creativePreviewParams,
        isRboxItemOverrideActive,
        shouldRepeatPreviewItems,
        shouldAllowMultiVideoPlay,
        setCreativePreviewParams: setter,
        shouldPropertiesOverride,
    };
};
