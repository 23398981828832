import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { BareDropdown, PaginatedDropdown } from 'taboola-ultimate-ui';
import { useLastCreatedCampaignGroupId } from 'modules/campaigns/hooks/useLastCreatedCampaignGroupId';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import validationFunctions from 'modules/taboola-common-frontend-modules/validations/services/validationFunctions';
import { FORM_MODES } from '../../../../../../../../config/formModes';
import { useFormDataContext } from '../../../../../../../taboola-common-frontend-modules/formData';
import { campaignsGroupMapSelector } from '../../../../../campaigns-group/selectors';
import { isCampaignGroupDeleted } from '../../../../../campaigns-reports/config/predicates/campaignGroupPredicates';
import { useCampaignGroupDropdownData } from '../../hooks/useCampaignGroupDropdownData';
import { useFetchedCampaignGroup } from '../../hooks/useFetchedCampaignGroup';
import { CampaignGroupOption } from '../CampaignGroupOption/CampaignGroupOption';
import styles from './CampaignGroup.module.scss';

const PAGE_SIZE = 10;
const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'campaign.group.placeholder',
        defaultMessage: 'Select a Campaign Group',
    },
];
const DropdownWithIndication = withIndication(PaginatedDropdown);
const invalidValueStyle = {
    control: props => ({ ...props, border: `1px solid ${styles.invalidValueColor}` }),
};
export const CampaignGroup = ({ disabled }) => {
    const { mode } = useFormDataContext();
    const [initialCampaignGroupId] = useLastCreatedCampaignGroupId();
    const { value, onChange, scrollRef, failedValidationData } = useFormValidatedValue({
        field: 'campaignGroupId',
        validations,
        formFieldDependencies: { initialCampaignGroupId },
        hasInitialData: false,
    });
    const {
        initialData: { campaignGroupId: initialValue },
    } = useFormDataContext();
    const cache = useSelector(campaignsGroupMapSelector);
    useFetchedCampaignGroup(initialValue);

    const selectedValueObject = value
        ? {
              value,
              label: get(cache, `${value}.name`) || get(cache, `${value}.campaignsGroupName`),
          }
        : null;
    const { options, setOptions, loadPage } = useCampaignGroupDropdownData();
    const selectedCampaignGroup = get(cache, value);
    const isNewForm = mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE;
    useEffect(() => {
        if (isNewForm && isCampaignGroupDeleted(selectedCampaignGroup)) {
            onChange('');
        }
    }, [selectedCampaignGroup, onChange, isNewForm]);

    return (
        <DropdownWithIndication
            id="campaign-group-dropdown"
            ref={scrollRef}
            options={options}
            loadPage={loadPage}
            onOptionsLoaded={setOptions}
            pageSize={PAGE_SIZE}
            onChange={({ value }) => onChange(value)}
            placeholder={<FormattedMessage id="campaign.group.placeholder" defaultMessage="Select a Campaign Group" />}
            styles={isEmpty(failedValidationData) ? undefined : invalidValueStyle}
            disabled={disabled}
            selectedValueObject={selectedValueObject}
            DropdownMenuComponent={BareDropdown}
            optionItemRenderer={CampaignGroupOption}
            containerClassName={styles['dropdown-container']}
            className={styles['item-container']}
            isOptionDisabled={option => isCampaignGroupDeleted(option)}
            {...failedValidationData}
        />
    );
};
