import { navigate } from 'actions';
import { generateCampaignEditorPath } from 'modules/campaigns/services/utils';
import { dimensionSelector, selectedAccountSelector } from 'selectors';
import { FORM_MODES } from '../../../config';
import { getCampaignEntity } from '../../../config/routes/entity';

const duplicateCampaign = (rowData, history) => (dispatch, getState) => {
    const { accountName } = rowData;
    const state = getState();
    const { accountId: selectedAccountId } = selectedAccountSelector(state);
    const dimension = dimensionSelector(state);
    const accountId = accountName || selectedAccountId;
    const entity = getCampaignEntity(rowData);
    // TODO: should take "rowData.id" in a generic way, so if the name of the field changes we wont need to change the code. Example: rowData[FIELDS.ID.field].
    const duplicateCampaignPath = generateCampaignEditorPath({
        mode: FORM_MODES.DUPLICATE,
        accountId,
        campaignId: rowData.id,
        dimension,
        entity,
    });

    dispatch(navigate(history, duplicateCampaignPath));
};

export default duplicateCampaign;
