import React, { useCallback } from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { ConversionRulesDropDown } from '../ConversionRulesDropDown/ConversionRulesDropDown';
import { CampaignConversionRuleItem } from './CampaignConversionRuleItem';
import styles from './CampaignConversionRules.module.scss';

export const CampaignConversionRules = ({ conversionRules, onChangeConversionRules, setHasOptions, disabled }) => {
    const deleteItem = useCallback(
        ({ id: idToDelete }) => {
            onChangeConversionRules(prev => prev.filter(val => val.id !== idToDelete));
        },
        [onChangeConversionRules]
    );

    const clearItems = useCallback(() => {
        onChangeConversionRules([]);
    }, [onChangeConversionRules]);

    return (
        <div>
            <ConversionRulesDropDown setHasOptions={setHasOptions} disabled={disabled} />
            {conversionRules && !disabled && (
                <CollapsibleList
                    items={conversionRules}
                    containerClassName={styles['conversions-list']}
                    data-automation="conversion-collapsible-list"
                    ItemComponent={CampaignConversionRuleItem}
                    metadata={{ totalItems: conversionRules?.length }}
                    deleteItem={deleteItem}
                    clearItems={clearItems}
                />
            )}
        </div>
    );
};
