import React, { useCallback } from 'react';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { isOpenExchangeActiveAccount } from '../../../../account-management/accountType';
import { FEATURE_FLAGS, useAccountConfig } from '../../../../taboola-common-frontend-modules/account-configurations';
import { FORM_MODES } from '../../../config';
import {
    DuplicateCampaignSettingsSection,
    SetupSection,
    TargetingSection,
    TimeFrameSection,
    TrackingSection,
    TrackingCode,
    BudgetSectionOpenExchange,
    ProfileSection,
} from '../../common-campaign-form/components';
import { AudienceTargetingSectionCampaignEditor } from '../../common-campaign-form/components/AudienceTargetingSection/AudienceTargetingSectionCampaignEditor';

const OpenExchangeCampaignEditorContent = ({ shouldRenderAccountDropdown }) => {
    const { mode } = useFormDataContext();
    const accountFilter = useCallback(account => isOpenExchangeActiveAccount(account), []);
    const { [FEATURE_FLAGS.IS_DCO_ACCOUNT]: isDcoAccountStr } = useAccountConfig([FEATURE_FLAGS.IS_DCO_ACCOUNT]);
    const isDcoAccount = isDcoAccountStr === 'true';

    return (
        <>
            {mode === FORM_MODES.DUPLICATE && <DuplicateCampaignSettingsSection />}
            {shouldRenderAccountDropdown && (
                <SetupSection
                    isAccountSelectionEnabled={shouldRenderAccountDropdown}
                    accountFilter={accountFilter}
                    noAccountsMessageId="campaign.editor.setup.no.open.exchange.accounts"
                />
            )}
            <TimeFrameSection />
            <TargetingSection isIncludeSupplyTargeting={false} />
            <AudienceTargetingSectionCampaignEditor />
            <BudgetSectionOpenExchange />
            <TrackingSection>
                <TrackingCode />
            </TrackingSection>
            {isDcoAccount && <ProfileSection />}
        </>
    );
};

export default OpenExchangeCampaignEditorContent;
