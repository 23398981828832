import moment from 'moment';
import BID_STRATEGIES from './bidStrategies';

const campaignDuplicateValues = {
    name: (_, { name }) => name && `Copy of ${name}`,
    startDate: (_, { startDate }) => startDate && moment.max([moment(startDate), moment()]),
    duplicateCampaignSettings: {
        includeCreatives: true,
        includeBlockedPublishers: true,
        includePublisherBidModifiers: (_, { bidStrategy }) =>
            bidStrategy !== BID_STRATEGIES.MAX_CONVERSIONS && bidStrategy !== BID_STRATEGIES.TARGET_CPA,
        includeThirdPartyTracking: true,
    },
    isPartOfSharedBudgetCampaignsGroup: false,
    campaignSchedule: {
        isMultipleSlicesPerDay: ({ isDayPartingPermitted }) => isDayPartingPermitted,
    },
};

export const openExchangeCampaignDuplicateValues = {
    ...campaignDuplicateValues,
    duplicateCampaignSettings: {
        includeBlockedPublishers: true,
        includeThirdPartyTracking: true,
    },
};

export const pmpDealDuplicateValues = {
    ...campaignDuplicateValues,
    duplicateCampaignSettings: {
        includeBlockedPublishers: true,
        includeThirdPartyTracking: true,
    },
};

export const videoCampaignDuplicateValues = {
    ...campaignDuplicateValues,
    duplicateCampaignSettings: {
        includeCreatives: true,
    },
};

export default campaignDuplicateValues;
