import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { operationsApi } from '../../../../../services/api';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import {
    requestUploadCreativeThumbnail,
    uploadCreativeThumbnailSuccess,
    uploadCreativeThumbnailError,
} from '../actions';

const errorIndication = {
    message: (
        <FormattedMessage
            id="creative.save.error.thumbnail"
            defaultMessage="We were not able to upload ad thumbnail."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const uploadCreativeThumbnail = (accountId, thumbnail) => async dispatch => {
    dispatch(requestUploadCreativeThumbnail());

    try {
        const { value: thumbnailUrl } = await operationsApi.uploadThumbnail(accountId, thumbnail);
        dispatch(uploadCreativeThumbnailSuccess());
        return { thumbnailUrl };
    } catch (error) {
        dispatch(uploadCreativeThumbnailError());
        dispatch(addIndication(errorIndication));
        return { error };
    }
};

export default uploadCreativeThumbnail;
