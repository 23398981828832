import { keys } from 'lodash';

export const parseUIEvent = event => {
    const currentTarget = event.currentTarget;
    let target = event.target;
    const parsedEvent = {
        DOMEventType: event.type,
        elementType: target.type,
        value: target.value,
        textContent: target.textContent,
        checked: target.checked,
        id: target.id,
        bubbleTree: [],
        contentEditable: target.contentEditable,
        //Disclaimer: we are tightly related to react-mint implementation
        onhover:
            event.target.onmouseenter &&
            (event.target?.firstElementChild?.className === 'tooltip-source' ||
                event.target?.lastElementChild?.className === 'tooltip-source'),
        innerText: target.innerText,
        ariaLabel: target.ariaLabel,
    };

    while (target !== currentTarget) {
        const reactDetailsKey = keys(target).find(neededKey => neededKey.startsWith('__reactInternalInstanc'));
        const reactMetadata = target[reactDetailsKey];
        const reactReturn = reactMetadata?.return;
        const displayName =
            reactReturn?.elementType?.prototype?.constructor?.name ||
            reactReturn?.elementType?.prototype?.constructor?.displayName ||
            reactReturn?.type?.displayName ||
            target.tagName.toLowerCase();
        const type = reactReturn?.type;
        const className = reactReturn?.stateNode?.className;
        const selector = `${target?.nodeName}${target?.id ? `#${target?.id}` : ''}`;
        const label = displayName || className || selector;

        parsedEvent.bubbleTree.push({
            displayName,
            label,
            nodeType: type,
            selector,
            textContent: target.textContent,
            elementType: target.type,
            tagName: target.tagName,
            role: target.role,
            value: target.value,
            type: target.type,
            onclick: target.onclick,
            contentEditable: target.contentEditable,
            contextAccountName: target?.dataset?.contextAccountName,
        });

        target = target.parentElement;
    }

    return parsedEvent;
};
