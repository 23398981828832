import { DeletableItem } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './combinedAudiencesWithoutBundlingTargetingDelimiter.module.scss';

export const CombinedAudiencesWithoutBundlingTargetingDelimiter = ({ onDelete }) => (
    <DeletableItem
        onDelete={onDelete}
        buttonProps={{ 'data-testid': 'combined-audience-ingredients-targeting-delete-button' }}
    >
        <div className={styles['container']}>
            <div className={styles['prefix']}>
                <div className={styles['spacer']} />
                <FormattedMessage
                    defaultMessage="AND"
                    id="app.forms.fields.campaigns._.campaignTargeting.marketplaceAudienceTargeting.add.button"
                />
                <div className={styles['spacer']} />
            </div>
            <div className={styles['description']}>
                <FormattedMessage
                    defaultMessage="must match at least one of the following (<b>narrows the target audience</b>)"
                    id="app.forms.fields.campaigns._.campaignTargeting.marketplaceAudienceTargeting.delimiter"
                />
            </div>
        </div>
    </DeletableItem>
);
