import React from 'react';
import { CollapsibleList, FormField, InputGroupWithButton } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ConversionSetupUrlConditionListItem } from './ConversionSetupUrlConditionListItem';
import { ConversionSetupUrlConditionsTooltip } from './ConversionSetupUrlConditionsTooltip';
import { useConversionSetupUrlConditions } from './hooks/useConversionSetupUrlConditions';
import { useConversionSetupUrlInputGroupConfig } from './hooks/useConversionSetupUrlInputGroupConfig';
import styles from './conversionSetupUrlConditionsField.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const ConversionSetupUrlConditionsField = () => {
    const { inputGroupConfig, predicate, url, setUrl } = useConversionSetupUrlInputGroupConfig();

    const {
        condition,
        handleAddCondition,
        handleRemoveCondition,
        handleRemoveAllConditions,
        indicationData,
        scrollRef,
    } = useConversionSetupUrlConditions(predicate, url, setUrl);

    return (
        <FormField
            inputId="condition"
            label={<FormattedMessage id="tracking.conversion.setup.conditions.title" defaultMessage="Conditions" />}
            helpText={<ConversionSetupUrlConditionsTooltip />}
            ref={scrollRef}
            description={
                <FormattedMessage
                    id="tracking.conversion.setup.conditions.description"
                    defaultMessage="Add conditions for conversion, please note that conditions are separated by “OR” operators."
                />
            }
        >
            <InputGroupWithButton
                config={inputGroupConfig}
                className={styles['container']}
                delimiterClassName={styles['delimiter']}
                buttonProps={{
                    onClick: handleAddCondition,
                    children: (
                        <FormattedMessage id="tracking.conversion.setup.conditions.add.button" defaultMessage="ADD" />
                    ),
                }}
            />
            <CollapsibleListWithIndication
                id="conversion-setup-url-condition-list"
                items={condition}
                ItemComponent={ConversionSetupUrlConditionListItem}
                deleteItem={handleRemoveCondition}
                clearItems={handleRemoveAllConditions}
                listHeaderTitle={
                    <FormattedMessage
                        id="tracking.conversion.setup.conditions.list.title"
                        defaultMessage="Included Conditions"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </FormField>
    );
};
