import React from 'react';
import { creativesApi } from '../../../../../services/api';
import { errorMessagesUtils } from '../../../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { bulkEditDrawerUpdateCreativesSuccess } from '../../../actions';
import { setSelectedRows } from '../../campaigns-reports/actions';

const successIndication = {
    message: <FormattedMessage id="creatives.save.success" defaultMessage="Ads successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="creatives.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const updateMotionCreatives =
    ({ accountId, creativeIds, changes }) =>
    async dispatch => {
        const bulkUpdate = {
            motionAdPojo: {
                ids: creativeIds,
                update: changes,
            },
        };
        try {
            const { scVideoItems: motionAdsResults } = await creativesApi.bulkEditCreatives(accountId, bulkUpdate);
            dispatch(addIndication(successIndication));
            if (motionAdsResults) {
                dispatch(bulkEditDrawerUpdateCreativesSuccess(motionAdsResults));
                dispatch(setSelectedRows(true, motionAdsResults));
            }
            return { results: motionAdsResults };
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            throw error;
        }
    };
