import { useMemo } from 'react';
import useAvailableItems from 'hooks/useAvailableItems';
import { allLocales } from '../config/messages';

export const useAvailableLocales = () => {
    const permittedLanguages = useAvailableItems(allLocales);

    const availableLocales = useMemo(() => permittedLanguages.map(({ id }) => id), [permittedLanguages]);

    return availableLocales;
};
