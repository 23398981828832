import { useMemo } from 'react';
import { useFormState } from '../../hooks/useFormState';
import { FormContext } from '../FormContext/FormContext';

export const FormContextProvider = ({ formDataFetcher, fetchedFormData, recommendationData, autoScroll, children }) => {
    const { submit } = useFormState({
        formDataFetcher,
        fetchedFormData,
        recommendationData,
        autoScroll,
    });

    const value = useMemo(
        () => ({
            submit,
        }),
        [submit]
    );

    return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
