import React from 'react';
import classnames from 'classnames/bind';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { StyledButton, STYLED_BUTTON_TYPE, STYLED_BUTTON_SIZE } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import styles from './actionButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const COLOR_OPTIONS = {
    RED: 'red',
    GRAY: 'gray',
};

export const ActionButton = props => {
    const {
        isDisabled,
        isLoading,
        onClick,
        dataMetricComponent,
        dataMetricsAdditionalProps,
        iconLabel,
        color,
        className,
        iconClassName,
        onKeyDown,
    } = props;
    const iconBeforeClassName = classNameBuilder('icon', 'xl', color, { disabled: isDisabled }, iconClassName);
    const buttonClassName = classNameBuilder('cell-btn', { disabled: isDisabled }, className);

    return isLoading ? (
        <Spinner size={14} className={buttonClassName} />
    ) : (
        <StyledButton
            className={buttonClassName}
            iconBefore={iconLabel}
            iconBeforeClassName={iconBeforeClassName}
            onClick={onClick}
            onKeyDown={onKeyDown}
            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
            size={STYLED_BUTTON_SIZE.SMALL}
            disabled={isDisabled}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component={dataMetricComponent}
            {...(dataMetricsAdditionalProps || {})}
        />
    );
};

ActionButton.propTypes = {
    /** On button click function */
    onClick: PropTypes.func.isRequired,
    /** Is action button disabled */
    isDisabled: PropTypes.bool,
    /** Is action button should display spinner */
    isLoading: PropTypes.bool,
    /** Data matric name */
    dataMetricComponent: PropTypes.string,
    /** Data metrics additional properties */
    dataMetricsAdditionalProps: PropTypes.object,
    /** Button icon to display */
    iconLabel: PropTypes.string.isRequired,
    /** Button color */
    color: PropTypes.oneOf(Object.values(COLOR_OPTIONS)),
    onKeyDown: PropTypes.func,
};

ActionButton.defaultProps = {
    isLoading: false,
    isDisabled: false,
    color: COLOR_OPTIONS.GRAY,
    onKeyDown: noop,
};

export default ActionButton;
