import { useMemo } from 'react';
import { values } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';

export const useEnabledExportableEntityConfigs = () => {
    const { value: exportableEntities = {} } = useFormFieldValue({
        field: 'exportableEntities',
    });
    const enabledExportableEntityConfigs = useMemo(
        () => values(exportableEntities).filter(({ isEnabled }) => isEnabled),
        [exportableEntities]
    );

    return enabledExportableEntityConfigs;
};
