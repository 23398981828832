'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; } /**
                                                                                                                                                                                                                              * Created by oreuveni
                                                                                                                                                                                                                              * Date: 2019-01-30
                                                                                                                                                                                                                              * Time: 11:36
                                                                                                                                                                                                                              */


var SmartListItemsContainer = function SmartListItemsContainer(_ref) {
    var children = _ref.children,
        rest = _objectWithoutProperties(_ref, ['children']);

    return _react2.default.createElement(
        'ul',
        rest,
        children
    );
};

SmartListItemsContainer.propTypes = {
    children: _propTypes2.default.node
};

exports.default = SmartListItemsContainer;