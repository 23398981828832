import React from 'react';

export const UnlimitedSVGIcon = () => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path
                d="M41 10H45C46.153 10 47 10.839 47 12V39C47 40.161 46.153 41 45 41H7C5.847 41 5 40.161 5 39V12C5 10.839 5.847 10 7 10H11V11H18V10H34V11H41V10ZM46 12C46 11.495 45.498 11 45 11H7C6.502 11 6 11.495 6 12V39C6 39.505 6.502 40 7 40H45C45.498 40 46 39.505 46 39V12Z"
                fillRule="nonzero"
            />
            <path
                d="M46 34V30.5H47V34.5C47 34.6326 46.9473 34.7598 46.8536 34.8536C46.7598 34.9473 46.6326 35 46.5 35H5.5C5.36739 35 5.24021 34.9473 5.14645 34.8536C5.05268 34.7598 5 34.6326 5 34.5V30.71H6V34H46Z"
                fillRule="nonzero"
            />
            <path
                d="M10.5 37C10.6326 37 10.7598 37.0527 10.8536 37.1464C10.9473 37.2402 11 37.3674 11 37.5C11 37.6326 10.9473 37.7598 10.8536 37.8536C10.7598 37.9473 10.6326 38 10.5 38H7.5C6.83696 38 6.20107 37.7366 5.73223 37.2678C5.26339 36.7989 5 36.163 5 35.5V33.037C5 32.9044 5.05268 32.7772 5.14645 32.6834C5.24021 32.5897 5.36739 32.537 5.5 32.537C5.63261 32.537 5.75979 32.5897 5.85355 32.6834C5.94732 32.7772 6 32.9044 6 33.037V35.5C6 35.8978 6.15804 36.2794 6.43934 36.5607C6.72064 36.842 7.10218 37 7.5 37H10.5ZM41.5 38C41.3674 38 41.2402 37.9473 41.1464 37.8536C41.0527 37.7598 41 37.6326 41 37.5C41 37.3674 41.0527 37.2402 41.1464 37.1464C41.2402 37.0527 41.3674 37 41.5 37H44.5C44.8978 37 45.2794 36.842 45.5607 36.5607C45.842 36.2794 46 35.8978 46 35.5V33.979C46 33.8464 46.0527 33.7192 46.1464 33.6254C46.2402 33.5317 46.3674 33.479 46.5 33.479C46.6326 33.479 46.7598 33.5317 46.8536 33.6254C46.9473 33.7192 47 33.8464 47 33.979V35.5C47 36.163 46.7366 36.7989 46.2678 37.2678C45.7989 37.7366 45.163 38 44.5 38H41.5ZM12.5 15C12.3674 15 12.2402 14.9473 12.1464 14.8536C12.0527 14.7598 12 14.6326 12 14.5V7.5C12 7.36739 12.0527 7.24021 12.1464 7.14645C12.2402 7.05268 12.3674 7 12.5 7H16.5C16.6326 7 16.7598 7.05268 16.8536 7.14645C16.9473 7.24021 17 7.36739 17 7.5V14.5C17 14.6326 16.9473 14.7598 16.8536 14.8536C16.7598 14.9473 16.6326 15 16.5 15H12.5ZM13 14H16V8H13V14ZM35.5 15C35.3674 15 35.2402 14.9473 35.1464 14.8536C35.0527 14.7598 35 14.6326 35 14.5V7.5C35 7.36739 35.0527 7.24021 35.1464 7.14645C35.2402 7.05268 35.3674 7 35.5 7H39.5C39.6326 7 39.7598 7.05268 39.8536 7.14645C39.9473 7.24021 40 7.36739 40 7.5V14.5C40 14.6326 39.9473 14.7598 39.8536 14.8536C39.7598 14.9473 39.6326 15 39.5 15H35.5ZM36 14H39V8H36V14Z"
                fillRule="nonzero"
            />
            <path
                d="M28.996 22.337L29.0015 22.332L29.0068 22.3268C29.9284 21.4051 31.2242 20.87 32.6 20.87C35.4434 20.87 37.75 23.1596 37.75 25.99C37.75 28.821 35.4428 31.12 32.6 31.12C31.2161 31.12 29.9292 30.5861 28.9559 29.6223L28.9508 29.6173L28.9454 29.6125L27.8873 28.6794L29.0201 27.6741L29.9886 28.5322C30.7138 29.2547 31.6349 29.63 32.6 29.63C34.6071 29.63 36.24 28.009 36.24 26C36.24 23.991 34.6071 22.37 32.6 22.37C31.6268 22.37 30.7153 22.744 30.0286 23.4279L23.004 29.663L22.9985 29.668L22.9932 29.6732C22.0716 30.5949 20.7758 31.13 19.4 31.13C16.5575 31.13 14.25 28.8213 14.25 26C14.25 23.1787 16.5575 20.87 19.4 20.87C20.774 20.87 22.0703 21.4039 23.0432 22.3768L23.0488 22.3824L23.0547 22.3876L24.1026 23.3107L22.9698 24.316L22.0113 23.4678C21.2864 22.7456 20.3754 22.37 19.4 22.37C17.3929 22.37 15.76 23.991 15.76 26C15.76 28.009 17.3929 29.63 19.4 29.63C20.3732 29.63 21.2847 29.256 21.9714 28.5721L28.996 22.337Z"
                fillRule="nonzero"
            />
        </g>
    </svg>
);
