import { useMemo, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { zipObject, isEqual, startCase } from 'lodash';
import { AllCampaignsId, CTA } from '../../../config';
import { useCtaTranslationsApi } from '../api';

const ctaTranslationsKey = 'cta-translations';
const allCampaignsIdArr = [AllCampaignsId];

const useCtaTranslations = (accountId, campaign = []) => {
    const sortedCampaigns = useMemo(() => {
        return campaign[0] ? campaign.map(camp => camp?.id).sort() : allCampaignsIdArr;
    }, [campaign]);

    const { getCtaTranslation } = useCtaTranslationsApi();
    function getValues(translations) {
        return translations.map(t => t?.ctaTranslationsByLanguage[Object.keys(t?.ctaTranslationsByLanguage)[0]]);
    }

    const queryClient = useQueryClient();
    const { data: ctaData } = useQuery(
        [ctaTranslationsKey, sortedCampaigns.join('/')],
        () =>
            Promise.all(sortedCampaigns.map(campaign => getCtaTranslation(accountId, campaign))).then(translations =>
                zipObject(sortedCampaigns, getValues(translations))
            ),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: !isEqual(sortedCampaigns, allCampaignsIdArr),
        }
    );

    useEffect(() => {
        if (ctaData && sortedCampaigns.length > 1) {
            Object.keys(ctaData).forEach(key => {
                queryClient.setQueryData([ctaTranslationsKey, key], ctaData);
            });
        }
    }, [ctaData, sortedCampaigns, queryClient]);

    const getCtaText = (ctaType, campaignId) => {
        if (!ctaType || ctaType === CTA.NONE) {
            return '';
        }

        return ctaData?.[campaignId]?.[ctaType] ?? ctaData?.[campaignId]?.[ctaType] ?? startCase(ctaType.toLowerCase());
    };

    return { getCtaText };
};

export default useCtaTranslations;
