import React from 'react';
import PropTypes from 'prop-types';
import { STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton, FileIcon } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import { validationFunctions, withContainerValidations } from '../../../taboola-common-frontend-modules/validations';
import CSVFileIcon from './CSVFileIcon';
import styles from './CSVFileDropZone.module.scss';

export const CSVFileDropZone = ({ filename, removeFile, children, containerClassName = styles['container'] }) => (
    <div className={containerClassName}>
        <CSVFileIcon className={styles['csv-big-icon']} />
        {filename ? (
            <div className={styles['filename-container']}>
                <span className={styles['file-icon']}>
                    <FileIcon />
                </span>
                <span className={styles['filename']}>{filename}</span>
                <StyledButton
                    data-testid="close"
                    iconBefore="close"
                    size={STYLED_BUTTON_SIZE.SMALL}
                    type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                    onClick={removeFile}
                    className={styles['remove-button']}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                />
            </div>
        ) : (
            <span className={styles['description']}>
                <FormattedMessage
                    id="audience.editor.crm.upload.drag.and.drop.description"
                    defaultMessage="Drag & Drop CSV File"
                />
                {children}
            </span>
        )}
    </div>
);

CSVFileDropZone.propTypes = {
    filename: PropTypes.string,
    removeFile: PropTypes.func.isRequired,
};

//TODO use useFormValidatedValue - consider postponing it
export const CSVDropZoneWithError = withContainerValidations(
    withIndication(CSVFileDropZone),
    [
        {
            validationFn: validationFunctions.isNotEmpty,
            messageId: 'audience.editor.validations.error.crm.upload.file.empty',
            defaultMessage: 'Add a CSV file',
        },
    ],
    'filename'
);
