import React from 'react';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import styles from '../campaignEditorHeader.module.scss';

const HeaderLoading = props => {
    const { name } = props;

    return (
        <div className={styles['section-container']} role="progressbar">
            <div className={styles['campaign-name']}>
                <div className={styles['campaign-name']}>{name}</div>
            </div>
            <div>
                <ContentLoader width={313} height={25} speed={2} className={styles['content']}>
                    <rect x="0" y="8" rx="2" ry="2" width="90" height="5" />
                    <rect x="0" y="20" rx="2" ry="2" width="150" height="5" />
                </ContentLoader>
            </div>
        </div>
    );
};

HeaderLoading.propTypes = {
    /** Campaign's name */
    name: PropTypes.string,
};

export default HeaderLoading;
