import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { CREATIVE_TYPE } from '../../../../config';
import { UnknownCreativeType } from '../../../creative-creator/components/UnknownCreativeType/UnknownCreativeType';
import { ThumbnailEditorBaseWithIndication } from '../ThumbnailEditor';
import ThumbnailMotionEditorBody from '../ThumbnailMotionEditor/Body/ThumbnailMotionEditorBody';

const creativeEditorComponentMap = {
    [CREATIVE_TYPE.IMAGE]: ThumbnailEditorBaseWithIndication,
    [CREATIVE_TYPE.MOTION]: ThumbnailMotionEditorBody,
};

const CreativeEditorField = ({ selected, onSelect, checkboxLabel, ...rest }) => {
    const { value: creativeType } = useFormFieldValue({ field: 'creativeType' });
    const Component = creativeEditorComponentMap[creativeType] || UnknownCreativeType;

    return (
        <FormField checkboxLabel={checkboxLabel} selected={selected} onSelect={onSelect}>
            <Component skipValidations={!selected} {...rest} />
        </FormField>
    );
};

CreativeEditorField.propTypes = {
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    label: PropTypes.node,
};

export default CreativeEditorField;
