import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { DropdownMenu, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import styles from './dropdownWrapper.module.scss';

const classNameBuilder = classnames.bind(styles);

// TODO: remove this wrapper when DropDown in TUUI is refactored
const DropdownWrapper = ({
    metricsAttributes,
    dropdownWrapperRef,
    dropdownWrapperClassName,
    dropdownRef,
    tooltipContent,
    ...rest
}) => (
    <div
        className={classNameBuilder('dropdown-container', dropdownWrapperClassName)}
        ref={dropdownWrapperRef}
        {...metricsAttributes}
    >
        {tooltipContent && (
            <Tooltip arrow interactive>
                {tooltipContent}
            </Tooltip>
        )}
        <DropdownMenu {...rest} ref={dropdownRef} style={{ width: '100%' }} />
    </div>
);

DropdownWrapper.propTypes = {
    ...DropdownMenu.propTypes,
    metricsAttributes: PropTypes.object,
    dropdownWrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default DropdownWrapper;
