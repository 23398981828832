'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.isSamePreset = exports.isEqualDate = exports.getPresetConfigs = exports.generatePresetConfig = exports.getMonthAbbreviation = undefined;

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

var _constants = require('./constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var YESTERDAY = _constants.PRESETS.YESTERDAY,
    TODAY = _constants.PRESETS.TODAY,
    SEVEN_DAYS = _constants.PRESETS.SEVEN_DAYS,
    FOURTEEN_DAYS = _constants.PRESETS.FOURTEEN_DAYS,
    THIRTY_DAYS = _constants.PRESETS.THIRTY_DAYS,
    NINETY_DAYS = _constants.PRESETS.NINETY_DAYS,
    LAST_MONTH = _constants.PRESETS.LAST_MONTH,
    THIS_MONTH = _constants.PRESETS.THIS_MONTH,
    THIS_YEAR = _constants.PRESETS.THIS_YEAR,
    THIS_QUARTER = _constants.PRESETS.THIS_QUARTER,
    LAST_QUARTER = _constants.PRESETS.LAST_QUARTER;


var getQuarterName = function getQuarterName(monthString) {
    var month = parseInt(monthString, 10);
    if (month > 0 && month <= 3) {
        return '(Q1)';
    }if (month <= 6) {
        return '(Q2)';
    }if (month <= 9) {
        return '(Q3)';
    }if (month <= 12) {
        return '(Q4)';
    }
    return null;
};

var getMonthAbbreviation = exports.getMonthAbbreviation = function getMonthAbbreviation(date) {
    var monthIndex = date.month() + 1;
    switch (monthIndex) {
        case 5:
            // May
            return date.format('MMM');
        case 6: // June
        case 7:
            // July
            return date.format('MMMM');
        case 9:
            // Sept.
            return 'Sept.';
        default:
            return date.format('MMM.');
    }
};

var generatePresetConfig = exports.generatePresetConfig = function generatePresetConfig(preset) {
    var labels = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.PRESET_LABELS;

    var prevDay = (0, _momentTimezone2.default)().subtract(1, 'days');
    var month = (0, _momentTimezone2.default)();
    var prevMonth = (0, _momentTimezone2.default)().subtract(1, 'month');
    var yearName = (0, _momentTimezone2.default)().format('YYYY');

    switch (preset) {
        case YESTERDAY:
            return {
                label: labels[YESTERDAY],
                startDate: prevDay,
                endDate: prevDay
            };
        case TODAY:
            return {
                label: labels[TODAY],
                startDate: (0, _momentTimezone2.default)(),
                endDate: (0, _momentTimezone2.default)()
            };
        case SEVEN_DAYS:
            return {
                label: labels[SEVEN_DAYS],
                startDate: (0, _momentTimezone2.default)().subtract(7, 'days'),
                endDate: prevDay
            };
        case FOURTEEN_DAYS:
            return {
                label: labels[FOURTEEN_DAYS],
                startDate: (0, _momentTimezone2.default)().subtract(14, 'days'),
                endDate: prevDay
            };
        case THIRTY_DAYS:
            return {
                label: labels[THIRTY_DAYS],
                startDate: (0, _momentTimezone2.default)().subtract(30, 'days'),
                endDate: prevDay
            };
        case LAST_MONTH:
            return {
                label: labels[LAST_MONTH] + ' (' + getMonthAbbreviation(prevMonth) + ')',
                startDate: (0, _momentTimezone2.default)().subtract(1, 'month').startOf('month'),
                endDate: (0, _momentTimezone2.default)().subtract(1, 'month').endOf('month')
            };
        case THIS_MONTH:
            return {
                label: labels[THIS_MONTH] + ' (' + getMonthAbbreviation(month) + ')',
                startDate: (0, _momentTimezone2.default)().startOf('month'),
                endDate: (0, _momentTimezone2.default)().endOf('month')
            };
        case NINETY_DAYS:
            return {
                label: labels[NINETY_DAYS],
                startDate: (0, _momentTimezone2.default)().subtract(90, 'days'),
                endDate: prevDay
            };
        case THIS_YEAR:
            return {
                label: labels[THIS_YEAR] + ' (' + yearName + ')',
                startDate: (0, _momentTimezone2.default)().startOf('year'),
                endDate: (0, _momentTimezone2.default)().endOf('year')
            };
        case THIS_QUARTER:
            return {
                label: labels[THIS_QUARTER] + ' ' + getQuarterName((0, _momentTimezone2.default)().format('M')),
                startDate: (0, _momentTimezone2.default)().startOf('quarter'),
                endDate: (0, _momentTimezone2.default)().endOf('quarter')
            };
        case LAST_QUARTER:
            return {
                label: labels[LAST_QUARTER] + ' ' + getQuarterName((0, _momentTimezone2.default)().subtract(3, 'month').format('M')),
                startDate: (0, _momentTimezone2.default)().subtract(1, 'quarter').startOf('quarter'),
                endDate: (0, _momentTimezone2.default)().subtract(1, 'quarter').endOf('quarter')
            };
        default:
            return null;
    }
};

var getPresetConfigs = exports.getPresetConfigs = function getPresetConfigs() {
    var labels = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _constants.PRESET_LABELS;
    return _constants.PRESETS_LIST.reduce(function (config, preset) {
        var newConfig = Object.assign({}, config, _defineProperty({}, preset, generatePresetConfig(preset, labels)));

        return newConfig;
    }, {});
};

// returns true if
//      a and b are both false-y OR
//      both a and b are not falsey and represent the same date
var isEqualDate = exports.isEqualDate = function isEqualDate(a, b) {
    return !a && !b || a && b && a.format(_constants.COMPARE_DATE_FORMAT) === b.format(_constants.COMPARE_DATE_FORMAT);
};

var isSamePreset = exports.isSamePreset = function isSamePreset() {
    var configA = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var configB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return isEqualDate(configA.startDate, configB.startDate) && isEqualDate(configA.endDate, configB.endDate);
};