import { cloneDeep, omit } from 'lodash';
import { ENTITIES_TYPES } from 'modules/performance-rules/components/PerformanceRuleSetupSection/entitiesRuleTypes';

export const performanceRulesTransformerToGW = performanceRule => {
    const entityRule = performanceRule.entityType === ENTITIES_TYPES.CAMPAIGN;
    const campaigns = entityRule ? performanceRule.campaigns.map(campaign => campaign.id) : null;
    const isExcludeTime = performanceRule.daysToExclude !== null;
    const updateConditions = isExcludeTime ? cloneDeep(performanceRule.conditions) : [...performanceRule.conditions];
    if (isExcludeTime && updateConditions?.length > 0) {
        updateConditions[0].isDirty = true;
    }

    const transformedData = omit(performanceRule, 'campaigns', 'templates');
    return {
        ...transformedData,
        campaigns,
        conditions: updateConditions,
    };
};
