import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { availableColumnDefinitions } from './columnDefinitions';
import { defaultColumns, customColumns } from './reportPresets';

export const scheduledReportsReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.SCHEDULED_REPORTS],
    endpoint: 'scheduled-reports',
    rowIdField: FIELDS.SCHEDULED_REPORTS_ID.field,
    columnsDef: availableColumnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    disableExport: true,
    disableForceFetch: true,
    autoFitColumnsToScreen: true,
};
