import { topRightPositionIcon, bottomLeftPositionIcon, bottomRightPositionIcon, topLeftPositionIcon } from 'tuui';

export const LOGO_POSITIONS = {
    TOP_LEFT_CORNER: 'topLeftCorner',
    TOP_RIGHT_CORNER: 'topRightCorner',
    BOTTOM_LEFT_CORNER: 'bottomLeftCorner',
    BOTTOM_RIGHT_CORNER: 'bottomRightCorner',
};

const LOGO_POSITIONS_VALUES = {
    NORTH_WEST: 'NORTH_WEST',
    SOUTH_WEST: 'SOUTH_WEST',
    SOUTH_EAST: 'SOUTH_EAST',
    NORTH_EAST: 'NORTH_EAST',
};

export const LOGO_POSITIONS_CONFIG = {
    [LOGO_POSITIONS.TOP_RIGHT_CORNER]: {
        icon: topRightPositionIcon,
        value: LOGO_POSITIONS_VALUES.NORTH_EAST,
    },
    [LOGO_POSITIONS.BOTTOM_LEFT_CORNER]: {
        icon: bottomLeftPositionIcon,
        value: LOGO_POSITIONS_VALUES.SOUTH_WEST,
    },
    [LOGO_POSITIONS.BOTTOM_RIGHT_CORNER]: {
        icon: bottomRightPositionIcon,
        value: LOGO_POSITIONS_VALUES.SOUTH_EAST,
    },
    [LOGO_POSITIONS.TOP_LEFT_CORNER]: {
        icon: topLeftPositionIcon,
        value: LOGO_POSITIONS_VALUES.NORTH_WEST,
    },
};

export const defaultLogoPosition = LOGO_POSITIONS_CONFIG[LOGO_POSITIONS.TOP_RIGHT_CORNER].value;
