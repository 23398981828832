'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.STATUS = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _TOOLTIP_POSITION$LEF, _TOOLTIP_POSITION$RIG, _imagePreviewPosition;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _lodash = require('lodash.get');

var _lodash2 = _interopRequireDefault(_lodash);

var _hooks = require('../../../hooks');

var _Image = require('../../Image');

var _Overlay = require('../../Overlay');

var _Overlay2 = _interopRequireDefault(_Overlay);

var _Tooltips = require('../../Tooltips');

var _Video = require('../../Video');

var _DragAndDropFile = require('../../DragAndDropFile');

var _CloudinaryImage = require('../../Image/CloudinaryImage/CloudinaryImage');

var _CloudinaryImage2 = _interopRequireDefault(_CloudinaryImage);

var _GalleryItem = require('../GalleryItem');

var _GalleryItem2 = _interopRequireDefault(_GalleryItem);

var _AspectRatioKeeper = require('../AspectRatioKeeper/AspectRatioKeeper');

var _AspectRatioKeeper2 = _interopRequireDefault(_AspectRatioKeeper);

var _MediaGalleryTooltip = require('./MediaGalleryTooltip');

var _MediaGalleryTooltip2 = _interopRequireDefault(_MediaGalleryTooltip);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'mediaGalleryItem__taboola-svg-icon___1Gv9P',
    'container': 'mediaGalleryItem__container___3aXkd',
    'media': 'mediaGalleryItem__media___Lmx4l',
    'fallback-img': 'mediaGalleryItem__fallback-img___2jVKZ',
    'selection-overlay': 'mediaGalleryItem__selection-overlay___1EBgk',
    'selected': 'mediaGalleryItem__selected___tIC0w',
    'overlay': 'mediaGalleryItem__overlay___3MZUV',
    'tooltip': 'mediaGalleryItem__tooltip___2FyoW'
};


var classNameBuilder = _bind2.default.bind(styles);

var STATUS = exports.STATUS = {
    ACTIVE: 'ACTIVE', LOADING: 'LOADING', ERROR: 'ERROR', EMPTY: 'EMPTY'
};

var getImageGravity = function getImageGravity(creativeFocus) {
    if ((0, _lodash2.default)(creativeFocus, 'type', null) !== 'COORDINATES') {
        return null;
    }

    var _creativeFocus$coordi = creativeFocus.coordinates,
        x = _creativeFocus$coordi.x,
        y = _creativeFocus$coordi.y;

    var gravity = 'xy_center,x_' + x + ',y_' + y;
    return encodeURIComponent(gravity);
};

var imagePreviewPosition = (_imagePreviewPosition = {}, _defineProperty(_imagePreviewPosition, _Tooltips.TOOLTIP_POSITION.LEFT, (_TOOLTIP_POSITION$LEF = {}, _defineProperty(_TOOLTIP_POSITION$LEF, _Tooltips.TOOLTIP_POSITION.TOP, _Tooltips.TOOLTIP_POSITION.LEFT_START), _defineProperty(_TOOLTIP_POSITION$LEF, _Tooltips.TOOLTIP_POSITION.BOTTOM, _Tooltips.TOOLTIP_POSITION.LEFT_END), _TOOLTIP_POSITION$LEF)), _defineProperty(_imagePreviewPosition, _Tooltips.TOOLTIP_POSITION.RIGHT, (_TOOLTIP_POSITION$RIG = {}, _defineProperty(_TOOLTIP_POSITION$RIG, _Tooltips.TOOLTIP_POSITION.TOP, _Tooltips.TOOLTIP_POSITION.RIGHT_START), _defineProperty(_TOOLTIP_POSITION$RIG, _Tooltips.TOOLTIP_POSITION.BOTTOM, _Tooltips.TOOLTIP_POSITION.RIGHT_END), _TOOLTIP_POSITION$RIG)), _imagePreviewPosition);

var getCompByStatus = function getCompByStatus(emptyComponent, loadingComponent, errorComponent, status, imgSrc, thumbnail, onEditFallbackImageClick, onCloseButtonClick, videoContainerClass, onError, playVideoOnHover) {
    if (status === STATUS.ERROR) {
        return errorComponent;
    }
    if (status === STATUS.LOADING) {
        return loadingComponent;
    }
    if (status === STATUS.EMPTY) {
        return emptyComponent;
    }
    if (!thumbnail) {
        return _react2.default.createElement(_Image.Image, {
            className: styles['media'],
            errorComponent: errorComponent,
            loadingComponent: loadingComponent,
            src: imgSrc,
            onError: onError
        });
    }if ((0, _DragAndDropFile.isFileTypeVideo)(thumbnail.fileType)) {
        return _react2.default.createElement(_Video.Video, {
            className: styles['media'],
            containerClassName: videoContainerClass,
            src: imgSrc,
            onCloseButtonClick: onCloseButtonClick,
            playVideoOnHover: playVideoOnHover
        });
    }if ((0, _DragAndDropFile.isFileTypeFallbackImage)(thumbnail.fileType)) {
        return _react2.default.createElement(_Video.FallbackImage, {
            onEditFallbackImageClick: onEditFallbackImageClick,
            imgSrc: imgSrc,
            errorComponent: errorComponent,
            loadingComponent: loadingComponent,
            status: status
        });
    }if (thumbnail.creativeFocus) {
        return _react2.default.createElement(_CloudinaryImage2.default, {
            className: styles['media'],
            src: imgSrc,
            gravity: getImageGravity(thumbnail.creativeFocus),
            onError: onError
        });
    }

    return _react2.default.createElement(_Image.Image, {
        className: styles['media'],
        errorComponent: errorComponent,
        loadingComponent: loadingComponent,
        src: imgSrc,
        onError: onError
    });
};

var getPreviewVerticalPosition = function getPreviewVerticalPosition(height, boundingClientRect, imagePreviewRatio) {
    if (window.innerHeight - boundingClientRect.top < height * imagePreviewRatio) {
        return _Tooltips.TOOLTIP_POSITION.BOTTOM;
    }
    return _Tooltips.TOOLTIP_POSITION.TOP;
};

var getPreviewHorizontalPosition = function getPreviewHorizontalPosition(width, boundingClientRect, imagePreviewRatio) {
    if (window.innerWidth - boundingClientRect.right < width * imagePreviewRatio) {
        return _Tooltips.TOOLTIP_POSITION.LEFT;
    }
    return _Tooltips.TOOLTIP_POSITION.RIGHT;
};

var addOverlay = function addOverlay(renderOverlayComponent, isOpacityActive, setTooltipImagePosition, showLargePreview, previewPositionClass, element, largePreviewWidth, largePreviewHeight, isVideo, imgSrc, largePreviewErrorComponent, tooltipContentStyle, isOverlayAlwaysOn) {
    return _react2.default.createElement(
        _Overlay2.default,
        { className: styles['overlay'], isOpacityActive: isOpacityActive, onHover: setTooltipImagePosition, isOverlayAlwaysOn: isOverlayAlwaysOn, ariaLabel: 'media item overlay' },
        showLargePreview && _react2.default.createElement(_MediaGalleryTooltip2.default, {
            previewPositionClass: previewPositionClass,
            element: element,
            largePreviewWidth: largePreviewWidth,
            largePreviewHeight: largePreviewHeight,
            isVideo: isVideo,
            imgSrc: imgSrc,
            largePreviewErrorComponent: largePreviewErrorComponent,
            tooltipContentStyle: tooltipContentStyle
        }),
        renderOverlayComponent()
    );
};

var MediaGalleryItem = function MediaGalleryItem(_ref) {
    var renderOverlayComponent = _ref.renderOverlayComponent,
        className = _ref.className,
        wrapperClass = _ref.wrapperClass,
        coverClass = _ref.coverClass,
        videoContainerClass = _ref.videoContainerClass,
        isOpacityActive = _ref.isOpacityActive,
        emptyComponent = _ref.emptyComponent,
        loadingComponent = _ref.loadingComponent,
        errorComponent = _ref.errorComponent,
        status = _ref.status,
        imgSrc = _ref.imgSrc,
        clickHandler = _ref.clickHandler,
        selected = _ref.selected,
        thumbnail = _ref.thumbnail,
        onEditFallbackImageClick = _ref.onEditFallbackImageClick,
        onCloseButtonClick = _ref.onCloseButtonClick,
        showLargePreview = _ref.showLargePreview,
        shouldAddOverlay = _ref.shouldAddOverlay,
        onError = _ref.onError,
        imagePreviewRatio = _ref.imagePreviewRatio,
        playVideoOnHover = _ref.playVideoOnHover,
        children = _ref.children,
        isOverlayAlwaysOn = _ref.isOverlayAlwaysOn,
        rest = _objectWithoutProperties(_ref, ['renderOverlayComponent', 'className', 'wrapperClass', 'coverClass', 'videoContainerClass', 'isOpacityActive', 'emptyComponent', 'loadingComponent', 'errorComponent', 'status', 'imgSrc', 'clickHandler', 'selected', 'thumbnail', 'onEditFallbackImageClick', 'onCloseButtonClick', 'showLargePreview', 'shouldAddOverlay', 'onError', 'imagePreviewRatio', 'playVideoOnHover', 'children', 'isOverlayAlwaysOn']);

    var _useElementSize = (0, _hooks.useElementSize)(),
        height = _useElementSize.height,
        width = _useElementSize.width,
        containerRef = _useElementSize.ref,
        element = _useElementSize.element;

    var _useState = (0, _react.useState)(_Tooltips.TOOLTIP_POSITION.RIGHT_END),
        _useState2 = _slicedToArray(_useState, 2),
        previewPositionClass = _useState2[0],
        setPreviewPositionClass = _useState2[1];

    var setTooltipImagePosition = (0, _react.useCallback)(function () {
        if (!showLargePreview || !element) {
            return;
        }
        var boundingClientRect = element.getBoundingClientRect();
        setPreviewPositionClass(imagePreviewPosition[getPreviewHorizontalPosition(width, boundingClientRect, imagePreviewRatio)][getPreviewVerticalPosition(height, boundingClientRect, imagePreviewRatio)]);
    }, [element, window.innerHeight, window.innerWidth, height, width, imagePreviewRatio]);
    var largePreviewWidth = width * imagePreviewRatio;
    var largePreviewHeight = height * imagePreviewRatio;
    var largePreviewErrorComponent = _react2.default.createElement(_Image.ImageContentLoader, {
        width: largePreviewWidth,
        height: largePreviewHeight
    });
    var isVideo = thumbnail && (0, _DragAndDropFile.isFileTypeVideo)(thumbnail.fileType);
    var tooltipContentStyle = { width: largePreviewWidth, height: largePreviewHeight, objectFit: 'cover' };

    return _react2.default.createElement(
        _GalleryItem2.default,
        Object.assign({ className: classNameBuilder('container', className), onClick: clickHandler }, rest),
        _react2.default.createElement(
            _AspectRatioKeeper2.default,
            { wrapperClass: wrapperClass, coverClass: coverClass, containerRef: containerRef },
            getCompByStatus(emptyComponent, loadingComponent, errorComponent, status, imgSrc, thumbnail, onEditFallbackImageClick, onCloseButtonClick, videoContainerClass, onError, playVideoOnHover)
        ),
        renderOverlayComponent && (shouldAddOverlay ? addOverlay(renderOverlayComponent, isOpacityActive, setTooltipImagePosition, showLargePreview, previewPositionClass, element, largePreviewWidth, largePreviewHeight, isVideo, imgSrc, largePreviewErrorComponent, tooltipContentStyle, isOverlayAlwaysOn) : _react2.default.createElement(_MediaGalleryTooltip2.default, {
            previewPositionClass: previewPositionClass,
            element: element,
            largePreviewWidth: largePreviewWidth,
            largePreviewHeight: largePreviewHeight,
            isVideo: isVideo,
            imgSrc: imgSrc,
            largePreviewErrorComponent: largePreviewErrorComponent,
            tooltipContentStyle: tooltipContentStyle
        })),
        _react2.default.createElement('div', { className: classNameBuilder('selection-overlay', { selected: selected }) }),
        children
    );
};

MediaGalleryItem.propTypes = {
    /** Class added to img element */
    className: _propTypes2.default.string,
    /** Class added to content aspect ratio wrapper element */
    wrapperClass: _propTypes2.default.string,
    /** Class added to content cover element */
    coverClass: _propTypes2.default.string,
    /** Class added to prevent video cutoff */
    videoContainerClass: _propTypes2.default.string,
    /** Source of image */
    imgSrc: _propTypes2.default.string,
    /** Component that describe Error status */
    emptyComponent: _propTypes2.default.node,
    /** Component that describe Loading status */
    loadingComponent: _propTypes2.default.node,
    /** Component that describe Error status */
    errorComponent: _propTypes2.default.node,
    /** Overlay Component render function added on top of the img - when hovered */
    renderOverlayComponent: _propTypes2.default.func,
    /** Glass pane divv on top of overlay */
    isOpacityActive: _propTypes2.default.bool,
    /** status of component - determind what to render */
    status: _propTypes2.default.oneOf(Object.values(STATUS)),
    /** callback when the mediaGalleryItem is clicked on */
    clickHandler: _propTypes2.default.func,
    /** boolean that describes if the item is selected */
    selected: _propTypes2.default.bool,
    /** thumbnail of image/video */
    thumbnail: _propTypes2.default.object,
    /** function to invoke on fallback image selection */
    onEditFallbackImageClick: _propTypes2.default.func,
    /** function to invoke on thumbnail deletion */
    onCloseButtonClick: _propTypes2.default.func,
    /** boolean that describes whether to show large preview */
    showLargePreview: _propTypes2.default.bool,
    /** function to invoke if image fails to load */
    onError: _propTypes2.default.func,
    shouldAddOverlay: _propTypes2.default.bool,
    imagePreviewRatio: _propTypes2.default.number,
    playVideoOnHover: _propTypes2.default.bool,
    children: _propTypes2.default.node,
    isOverlayAlwaysOn: _propTypes2.default.bool
};

MediaGalleryItem.defaultProps = {
    errorComponent: _react2.default.createElement(
        'h1',
        null,
        'Error'
    ),
    loadingComponent: _react2.default.createElement(_Image.ImageContentLoader, null),
    renderOverlayComponent: function renderOverlayComponent() {},
    clickHandler: function clickHandler() {},
    isOpacityActive: true,
    shouldAddOverlay: true,
    showLargePreview: false,
    onError: function onError() {},
    imagePreviewRatio: 2.5,
    playVideoOnHover: true,
    isOverlayAlwaysOn: false
};

exports.default = MediaGalleryItem;