const scheduledReportsAPIPrefix = accountId => `/${accountId}/reports/scheduled-reports`;

export const scheduledReportsApiFactory = ({ callPutApi, callPostApi, callDeleteApi, callGetApi }) => ({
    updateScheduledReport: ({ accountId, scheduledReportId, changes, executeNow }) =>
        callPutApi(`${scheduledReportsAPIPrefix(accountId)}/${scheduledReportId}?schedule_once=${executeNow}`, changes),

    createScheduledReport: (accountId, queryParams, executeNow) =>
        callPostApi(`${scheduledReportsAPIPrefix(accountId)}?schedule_once=${executeNow}`, queryParams),

    deleteScheduledReport: (accountId, scheduledReportId) =>
        callDeleteApi(`${scheduledReportsAPIPrefix(accountId)}/${scheduledReportId}`),

    getScheduledReportData: (accountId, scheduledReportId) =>
        callGetApi(`${scheduledReportsAPIPrefix(accountId)}/${scheduledReportId}`),

    getScheduledReportsList: (accountId, dimensions) =>
        callGetApi(`${scheduledReportsAPIPrefix(accountId)}/list?dimensions=${dimensions.join(',')}`),
});
