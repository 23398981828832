import React from 'react';
import { withRouter } from 'react-router';
import classnames from 'classnames/bind';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { CREDIT_LIMIT_MODEL } from 'modules/billing-and-payments/config/CreditLimitModel';
import { BILLING_AND_PAYMENTS_ROUTE } from 'modules/billing-and-payments/config/routes';
import { useIsBillingModulePermitted } from 'modules/billing-and-payments/hooks/useIsBillingModulePermitted';
import { numberFormatter } from 'modules/campaigns/modules/campaigns-reports/config/fields/transformers';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage, useLocale, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import { InternalLink } from '../InternalLink';
import styles from './balance.module.scss';

const classNameBuilder = classnames.bind(styles);
const moduleLinkClassName = classNameBuilder('balance', 'link');

const BalanceText = ({ remaining, currency }) => (
    <FormattedNumber
        value={remaining}
        variant="currency"
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
    />
);

const Balance = () => {
    const { currency, creditData } = useAccount();
    const [locale] = useLocale();
    const isBillingAndPaymentsPermitted = useIsBillingModulePermitted();
    const shouldHideCurrentBalance = useConfigMatch({ [FEATURE_FLAGS.SHOULD_HIDE_CURRENT_BALANCE]: 'true' });
    const payingAccountRemainingCredit = creditData?.remaining;
    const compactBalanceFormatter = numberFormatter({
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        notation: 'compact',
    })(locale);

    if (!payingAccountRemainingCredit || creditData?.creditLimitModel === CREDIT_LIMIT_MODEL.CCB) {
        return null;
    }

    const balanceInCompactFormat = compactBalanceFormatter(payingAccountRemainingCredit);

    return (
        <div>
            <InternalLink
                className={moduleLinkClassName}
                path={BILLING_AND_PAYMENTS_ROUTE}
                disabled={!isBillingAndPaymentsPermitted}
            >
                {!shouldHideCurrentBalance && balanceInCompactFormat}
            </InternalLink>
            <Tooltip position={TOOLTIP_POSITION.BOTTOM_START} arrow interactive>
                <div className={styles['balance']}>
                    <FormattedMessage id="app.appHeader.balance.currentBalance" defaultMessage="Current Balance: " />
                    <BalanceText remaining={payingAccountRemainingCredit} currency={currency} />
                </div>
                <FormattedMessage
                    id="app.appHeader.balance.tooltip"
                    defaultMessage="Please make sure to have enough funds. Your campaigns will be paused once you reach your limit.{br}You can increase your account limit by reaching out to your Account Manager"
                />
            </Tooltip>
        </div>
    );
};

export { Balance };
export default withRouter(Balance);
