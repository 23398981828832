'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useMountSafeState = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var useMountSafeState = exports.useMountSafeState = function useMountSafeState(initialState) {
    var _useState = (0, _react.useState)(initialState),
        _useState2 = _slicedToArray(_useState, 2),
        state = _useState2[0],
        setState = _useState2[1];

    var mountedRef = (0, _react.useRef)(true);
    var safeSetter = (0, _react.useCallback)(function (value) {
        if (mountedRef.current) {
            setState(value);
        }
    }, [setState]);

    (0, _react.useLayoutEffect)(function () {
        return function () {
            mountedRef.current = false;
        };
    }, []);

    return [state, safeSetter];
};