import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropdownOptions, useDropdownValue } from '../../../../../../hooks';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { DropdownField } from '../../../../components/Form';
import { FORM_MODES } from '../../../../config';
import { VIDEO_PRICING_MODEL } from '../../../common-campaign-form/config';
import PreferredViewabilityRateTooltip from './PreferredViewabilityRateTooltip';
import { useViewabilityRatesApi } from './hooks';

export const PreferredViewabilityRate = () => {
    const { value, onChange } = useFormFieldValue({
        field: 'campaignTargeting.viewabilityTargeting',
    });

    const {
        onChange: onBusinessModelChange,
        mode,
        value: businessModel,
    } = useFormFieldValue({
        field: 'videoBudget.businessModel',
    });

    const changeHandler = useCallback(
        ({ id: preferredViewabilityRate }) => {
            onChange(preferredViewabilityRate);
            const isSelectedPreferredViewabilityRate = preferredViewabilityRate && preferredViewabilityRate !== 'NONE';
            if (
                (mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE) &&
                businessModel !== VIDEO_PRICING_MODEL.CPM_EXTERNAL
            ) {
                const toChange = isSelectedPreferredViewabilityRate ? VIDEO_PRICING_MODEL.CPM : businessModel;
                onBusinessModelChange(toChange);
            }
        },
        [onChange, mode, onBusinessModelChange, businessModel]
    );

    const { viewabilityRates, isResourcesLoading } = useViewabilityRatesApi();
    const selectedValue = useDropdownValue(viewabilityRates?.find(o => o.id === value) ?? { value });
    const options = useDropdownOptions(!isResourcesLoading && viewabilityRates ? viewabilityRates : []);

    if (isResourcesLoading) {
        return null;
    }

    return (
        <DropdownField
            id="preferred-viewability-rate"
            label={
                <FormattedMessage
                    id="video.campaign.editor.preferredViewabilityRate.title"
                    defaultMessage="Preferred Viewability Rate"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.preferredViewabilityRate.description"
                    defaultMessage="Select the preferred viewability rate the campaign will optimize towards for CPM campaigns only."
                />
            }
            helpText={<PreferredViewabilityRateTooltip />}
            options={options}
            selectedValueObject={selectedValue}
            onChange={changeHandler}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'PreferredViewabilityRate',
            }}
            placeholder={
                <FormattedMessage
                    id="video.campaign.editor.preferredViewabilityRate.placeholder"
                    defaultMessage="Select Preferred Viewability Rate..."
                />
            }
        />
    );
};

PreferredViewabilityRate.propTypes = {
    account: PropTypes.object,
};

export default PreferredViewabilityRate;
