import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'tuui';
import { TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { PermissionsGuard } from '../../../../../taboola-common-frontend-modules/authentication';
import { ActionButton, COLOR_OPTIONS as BUTTON_COLOR_OPTIONS } from '../ActionButton/ActionButton';
import styles from './actionsCellRenderer.module.scss';

const ActionsCellRenderer = ({ buttonConfigs }) => {
    const buttons = buttonConfigs.map(
        ({
            onClick,
            isDisabled,
            isLoading,
            dataMetricComponent,
            dataMetricsAdditionalProps,
            iconLabel,
            tooltipContent,
            tooltipProps,
            permissionsGuardProps,
            buttonColor,
            className,
            onKeyDown,
            badgeData = { hideBadge: true },
        }) => {
            const button = (
                <ActionButton
                    key={dataMetricComponent}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onClick={onClick}
                    dataMetricComponent={dataMetricComponent}
                    iconLabel={iconLabel}
                    color={buttonColor}
                    className={className}
                    dataMetricsAdditionalProps={dataMetricsAdditionalProps}
                    onKeyDown={onKeyDown}
                    badgeData={badgeData}
                />
            );

            const wrappedButton = (
                <span key={dataMetricComponent}>
                    <Badge contentClassName={styles['badge']} {...badgeData}>
                        {button}
                    </Badge>
                    {tooltipContent && (
                        <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow {...tooltipProps}>
                            {tooltipContent}
                        </Tooltip>
                    )}
                </span>
            );
            return permissionsGuardProps ? (
                <PermissionsGuard key={dataMetricComponent} {...permissionsGuardProps}>
                    {wrappedButton}
                </PermissionsGuard>
            ) : (
                wrappedButton
            );
        }
    );

    return <div className={styles['action-cell']}>{buttons}</div>;
};

ActionsCellRenderer.propTypes = {
    /** row data which is injected by the grid */
    data: PropTypes.object,
    /** context of AgGrid */
    context: PropTypes.shape({
        /** component of DataGrid */
        dispatch: PropTypes.func,
        router: PropTypes.object,
    }),
    /** array of button configuration objects */
    buttonConfigs: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            onKeyDown: PropTypes.func,
            isDisabled: PropTypes.bool,
            dataMetricComponent: PropTypes.string,
            iconLabel: PropTypes.string,
            tooltipContent: PropTypes.node,
            tooltipProps: PropTypes.object,
            permissionsGuardProps: PropTypes.object,
        })
    ),
    /** button color */
    buttonColor: PropTypes.oneOf(Object.values(BUTTON_COLOR_OPTIONS)),
};

ActionsCellRenderer.defaultProps = {
    buttonConfigs: [],
};

export default ActionsCellRenderer;
