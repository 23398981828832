import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// TODO this hook to be removed once duplicaton for data src URL vs Redux is aligned
let unlisten = null;
const usePostNavigationCallback = () => {
    const history = useHistory();
    const removeCallback = useCallback(() => {
        if (unlisten) {
            unlisten();
            unlisten = null;
        }
    }, []);
    const setCallback = useCallback(
        callback => {
            unlisten = history.listen(() => {
                removeCallback();
                callback();
            });
        },
        [history, removeCallback]
    );

    return { setCallback, removeCallback };
};

export default usePostNavigationCallback;
