import React from 'react';
import classnames from 'classnames/bind';
import { Indication } from 'taboola-ultimate-ui';
import { FORM_MODES } from '../../../../campaigns/config';
import { COMMON_FLAGS, useCommonConfig } from '../../../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from '../../../../taboola-common-frontend-modules/i18n';
import { Banner } from '../../CombinedAudiences/SizeEstimationWidget/Banner';
import { checkInputValid } from '../../CombinedAudiences/SizeEstimationWidget/CombinedSegmentsSizeEstimationWidget';
import { useCountriesField } from '../hooks/useCountriesField';
import SizeEstimationCountryField from './SizeEstimationCountryField/SizeEstimationCountryField';
import SizeEstimationGraphField from './SizeEstimationGraphField/SizeEstimationGraphField';
import SizeEstimationPlatformField from './SizeEstimationPlatformField/SizeEstimationPlatformField';
import { ArticlesPreview } from './SizeEstimationSizeField/ArticlesPreview/ArticlesPreview';
import {
    SizeEstimationArticlesField,
    SizeEstimationImpressionsField,
} from './SizeEstimationSizeField/SizeEstimationSizeField';
import { useCustomContextualSizeEstimateApi } from './hooks/useCustomContextualSizeEstimateApi';
import styles from './customContextualSizeEstimationWidget.module.scss';

const GraphAndCounts = ({ graphPoints, impressions, articles, isSizeEstimateLoading, isSmallReach, isServerError }) => {
    return (
        <div className={styles['graph-and-counts']} aria-label="size-estimation-widget-graph-and-counts">
            <SizeEstimationGraphField
                graphPoints={isSmallReach ? null : graphPoints}
                isLoading={isSizeEstimateLoading}
            />
            {isServerError ? (
                <ErrorMessage msgId="custom.contextual.size.estimation.server.error.warning" />
            ) : (
                isSmallReach && <ErrorMessage msgId="custom.contextual.size.estimation.small.reach.warning" />
            )}
            <SizeEstimationImpressionsField
                count={isSmallReach || isServerError ? 0 : impressions}
                isLoading={isSizeEstimateLoading}
            />
            <SizeEstimationArticlesField
                count={isSmallReach || isServerError ? 0 : articles}
                isLoading={isSizeEstimateLoading}
            />
        </div>
    );
};

const ErrorMessage = ({ msgId }) => {
    const { formatMessage } = useIntl();
    return (
        <span className={styles['notice']} role="alert">
            <Indication
                type="error"
                className={styles['notice-container']}
                message={formatMessage({
                    id: msgId,
                })}
                contentClassName={styles['notice']}
                displayDismissComponent={false}
            />
        </span>
    );
};

const getMsgId = (isTermsSelected, isPlatformSelected, isCountriesSelected) => {
    if (!isTermsSelected) {
        return 'custom.contextual.size.estimation.no-terms-selected';
    }

    if (!isPlatformSelected) {
        return 'custom.contextual.size.estimation.no-platform-selected';
    }

    if (!isCountriesSelected) {
        return 'custom.contextual.size.estimation.no-country-selected';
    }

    return 'custom.contextual.size.estimation.error';
};
const classNameBuilder = classnames.bind(styles);

export const CustomContextualSizeEstimationWidget = () => {
    const { value: platforms } = useFormFieldValue({
        field: 'platforms',
    });
    const { value: include = [] } = useFormFieldValue({
        field: 'terms.include',
    });
    const { value: countries } = useCountriesField();

    const { [COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER]: maxAllowedTopics } = useCommonConfig([
        COMMON_FLAGS.MAX_ALLOWED_TERMS_NUMBER,
    ]);
    const checkTopicsValid = ({ audiences }) => {
        return audiences && audiences[0] && audiences[0].length > 0 && audiences[0].length <= maxAllowedTopics;
    };

    const { isPlatformSelected, isAudiencesSelected, isCountriesSelected, isInputValid } = checkInputValid({
        platforms,
        countries,
        audiences: include,
        isAudiencesValid: checkTopicsValid,
    });
    const customContextualSizeData = useCustomContextualSizeEstimateApi(isInputValid);
    const msgId = getMsgId(isAudiencesSelected, isPlatformSelected, isCountriesSelected);
    const { formatMessage } = useIntl();
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.EDIT;
    const { isSmallReach, isServerError } = customContextualSizeData;

    return (
        <div
            className={classNameBuilder('container', {
                'edit-mode': isEditMode,
                'small-reach-or-server-error': isSmallReach || isServerError,
            })}
        >
            <div className={styles['header']}>
                <FormattedMessage id="custom.contextual.size.estimation.widget" defaultMessage="Reach (last 30 days)" />
            </div>
            <div className={styles['content']}>
                <span className={styles['notice']}>
                    <Indication
                        type="info"
                        className={styles['notice-container']}
                        message={formatMessage({
                            id: 'custom.contextual.size.estimation.notice',
                            defaultMessage: 'This will not affect the segment and is only for estimation purposes.',
                        })}
                        contentClassName={styles['notice']}
                        displayDismissComponent={false}
                    />
                </span>
                <SizeEstimationCountryField />
                <SizeEstimationPlatformField />
                {isInputValid ? (
                    <>
                        <GraphAndCounts {...customContextualSizeData} />
                        <ArticlesPreview />
                    </>
                ) : (
                    <Banner msgId={msgId} className={styles['banner']} />
                )}
            </div>
        </div>
    );
};

export default CustomContextualSizeEstimationWidget;
