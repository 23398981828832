import { REPORT_TYPE } from 'modules/campaigns/config';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';
import * as FIELDS from '../../fields';

const defaultColumns = [
    FIELDS.PAYMENT_METHOD.field,
    FIELDS.BILLING_DATE.field,
    FIELDS.PAYMENT_AMOUNT.field,
    FIELDS.FEES.field,
    FIELDS.VAT.field,
    FIELDS.PAYMENT_STATUS.field,
].map(field => generateColumnId(REPORT_TYPE.PAYMENTS, field));

export { defaultColumns };
