import encodeurl from 'encodeurl';
import { validationsApi } from 'services/api';

export const bulkValidateUrls = (accountId, urls) =>
    validationsApi.validateUrls(accountId, encodeurl(urls.join())).then(res => res.json());

export async function validateUrl(accountId, url) {
    if (!url) {
        return false;
    }
    const encodedUrl = encodeurl(url);
    try {
        const response = await validationsApi.validateUrls(accountId, encodedUrl);
        const result = await response.json();
        return result[encodedUrl]?.valid;
    } catch (e) {
        return false;
    }
}
