import { FormField } from 'taboola-ultimate-ui';
import { CustomRulesDropdown } from 'modules/scheduled-reports/components/ScheduledReportsCustomRuleListFiled/CustomRulesDropdown';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ScheduledReportsCustomRuleListSelect = () => {
    return (
        <FormField
            inputId="custom-rules-select-type"
            label={
                <FormattedMessage id="app.create.scheduled.reports.custom.rules.select.title" defaultMessage="Rules" />
            }
            description={
                <FormattedMessage
                    id="app.create.scheduled.reports.custom.rules.select.description"
                    defaultMessage="Select rules to include in report"
                />
            }
        >
            <CustomRulesDropdown />
        </FormField>
    );
};
