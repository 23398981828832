import React from 'react';
import { batch } from 'react-redux';
import { recommendationsIdSelector, recommendationsTypeSelector } from 'modules/recommendations/selectors';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { selectedAccountSelector } from 'selectors';
import { campaignsApi } from 'services/api';
import { errorMessagesUtils } from '../../../services/utils';
import { isNetworkAccountType } from '../../account-management';
import { isProgrammaticAccount } from '../../account-management/accountType';
import { duplicateCampaignError, duplicateCampaignSuccess, requestDuplicateCampaign } from '../actions';
import { REPORT_TYPE } from '../config';
import { fromGWtransformers, toGWtransformers } from './flowsUtils';
import selectCampaignAndReport from './selectCampaignAndReport';

const successIndication = {
    message: <FormattedMessage id="campaign.duplicate.success" defaultMessage="Campaign successfully duplicated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage id="campaign.duplicate.error.highlight" defaultMessage="Unable to duplicate the campaign." />
    ),
});

const duplicateCampaign =
    ({ accountId, campaignId, changes, passedDestinationAccountName, entityType }) =>
    async (dispatch, getState) => {
        const campaignChanges = toGWtransformers[entityType](changes || {});
        const state = getState();
        const recommendationType = recommendationsTypeSelector(state);
        const recommendationId = recommendationsIdSelector(state);
        const account = selectedAccountSelector(state);
        const { type } = account;
        const destinationAccountName = isNetworkAccountType(type) ? passedDestinationAccountName : '';

        dispatch(requestDuplicateCampaign());
        try {
            const response = await campaignsApi.duplicateCampaign({
                accountId,
                campaignId,
                changes: campaignChanges,
                destinationAccount: destinationAccountName,
                recommendationType,
                recommendationId,
                entityType,
            });
            batch(() => {
                const reportId = isProgrammaticAccount(account) ? REPORT_TYPE.CAMPAIGN : REPORT_TYPE.CREATIVE;
                dispatch(duplicateCampaignSuccess(response));
                dispatch(addIndication(successIndication));
                dispatch(selectCampaignAndReport(response, reportId));
            });

            return fromGWtransformers[entityType](response);
        } catch (error) {
            batch(() => {
                dispatch(duplicateCampaignError(error));
                dispatch(addIndication(generateErrorIndication(error)));
            });
            throw error;
        }
    };

export default duplicateCampaign;
