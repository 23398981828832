import React from 'react';
import { RUNTIME_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { RunTimeAdScheduler } from './RunTimeAdScheduler';
import { TimeFrame } from './TimeFrame';
import { useAdScheduler } from './useAdScheduler';

export const AdScheduler = ({ startDateCampaign, endDateCampaign, timeZoneName }) => {
    const { handleRunTimeModeChange, mode } = useAdScheduler();

    return (
        <>
            <RunTimeAdScheduler mode={mode} onModeChange={handleRunTimeModeChange} />
            {mode === RUNTIME_TYPES.CUSTOM && (
                <TimeFrame
                    startDateCampaign={startDateCampaign}
                    endDateCampaign={endDateCampaign}
                    timeZoneName={timeZoneName}
                />
            )}
        </>
    );
};
