import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const nameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.campaign.name.empty',
        defaultMessage: 'Enter a Campaign Name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 200 },
        messageId: 'validations.error.campaign.name.length',
        defaultMessage: 'Campaign name can not exceed 200 characters',
    },
];

export default nameValidations;
