import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const languageOptions = [
    {
        value: 'English',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.english" defaultMessage="English" />,
    },
    {
        value: 'Spanish',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.spanish" defaultMessage="Spanish" />,
    },
    {
        value: 'French',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.french" defaultMessage="French" />,
    },
    {
        value: 'German',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.german" defaultMessage="German" />,
    },
    {
        value: 'Italian',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.italian" defaultMessage="Italian" />,
    },
    {
        value: 'Portuguese',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.portuguese" defaultMessage="Portuguese" />
        ),
    },
    {
        value: 'Dutch',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.dutch" defaultMessage="Dutch" />,
    },
    {
        value: 'Russian',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.russian" defaultMessage="Russian" />,
    },
    {
        value: 'Arabic',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.arabic" defaultMessage="Arabic" />,
    },
    {
        value: 'Mandarin Chinese',
        label: (
            <FormattedMessage
                id="creative.creator.aiVariationsSetup.language.mandarinChinese"
                defaultMessage="Mandarin Chinese"
            />
        ),
    },
    {
        value: 'Japanese',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.japanese" defaultMessage="Japanese" />,
    },
    {
        value: 'Swedish',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.swedish" defaultMessage="Swedish" />,
    },
    {
        value: 'Korean',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.korean" defaultMessage="Korean" />,
    },
    {
        value: 'Turkish',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.turkish" defaultMessage="Turkish" />,
    },
    {
        value: 'Indonesian',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.indonesian" defaultMessage="Indonesian" />
        ),
    },
    {
        value: 'Thai',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.thai" defaultMessage="Thai" />,
    },
    {
        value: 'Vietnamese',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.vietnamese" defaultMessage="Vietnamese" />
        ),
    },
    {
        value: 'Bengali',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.bengali" defaultMessage="Bengali" />,
    },
    {
        value: 'Hindi',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.hindi" defaultMessage="Hindi" />,
    },
    {
        value: 'Hebrew',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.hebrew" defaultMessage="Hebrew" />,
    },
    {
        value: 'Polish',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.polish" defaultMessage="Polish" />,
    },
    {
        value: 'Czech',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.czech" defaultMessage="Czech" />,
    },
    {
        value: 'Greek',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.greek" defaultMessage="Greek" />,
    },
    {
        value: 'Ukranian',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.ukrainian" defaultMessage="Ukrainian" />
        ),
    },
    {
        value: 'Hungarian',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.hungarian" defaultMessage="Hungarian" />
        ),
    },
    {
        value: 'Romanian',
        label: <FormattedMessage id="creative.creator.aiVariationsSetup.language.romanian" defaultMessage="Romanian" />,
    },
    {
        value: 'Bulgarian',
        label: (
            <FormattedMessage id="creative.creator.aiVariationsSetup.language.bulgarian" defaultMessage="Bulgarian" />
        ),
    },
];
