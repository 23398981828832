import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Workbook } from 'exceljs';
import { saveAs as saveAsFile } from 'file-saver';
import { values, keys, map } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useSelectedAccountId } from 'hooks';
import { addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { useAccount, useAccountCache } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useBulkOperationsApi } from 'services/api';
import { ENTITY } from '../../../config';
import { EXCEL_BULK_FILE_NAME } from '../consts/fileName';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';
import { CAMPAIGNS_FETCH_LEVEL, CREATIVE_STATUS_FILTER } from '../consts/statusFilterConsts';
import { buildExcelBulkTemplate } from '../services/buildExcelBulkTemplate';
import { getExportExcelRequestMap } from '../services/exportableEntityServices';
import { ExcelBulkError } from '../transformers/ExcelBulkError';
import { calcDurationInSeconds } from '../utils/bulkUploadGtmUtils';
import { convertWorkbookToBlob } from '../utils/convertWorkbookToBlob';
import { addDictionarySheets } from '../utils/dictionaryUtils';
import { getExcelBulkFileName } from '../utils/excelBulkWorkbookUtils';
import { getExcelBulkErrorIndication } from '../utils/getExcelBulkErrorIndication';
import { useExcelBulkUploadConfigs } from '../utils/useExcelBulkUploadConfigs';
import { useAvailableDictionarySheets } from './useAvailableDictionarySheets';
import { useDictionaryData } from './useDictionaryData';
import { useEnabledExportableEntityConfigs } from './useEnabledExportableEntities';
import { useExcelBulkCommonMetadata } from './useExcelBulkCommonMetadata';
import { useExcelConfig } from './useExcelConfig';

const INDICATION_DOWNLOAD_SUCCESS = {
    message: (
        <FormattedMessage id="excel.bulk.template.success" defaultMessage="Template has been generated successfully." />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const INDICATION_DOWNLOAD_FAILED = {
    message: (
        <FormattedMessage
            id="campaign.creator.bulk.upload.template.download.error"
            defaultMessage="Failed to download template, please try again later."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const FETCH_DATA_ERROR_INDICATION = {
    message: (
        <FormattedMessage id="excel.bulk.fetch.template.data.error" defaultMessage="Unable to fetch template data." />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

export const useExcelBulkTemplate = ({ conditionData } = {}) => {
    const { formatMessage } = useIntl();
    const excelConfig = useExcelConfig({ conditionData });
    const entityList = excelConfig.entityList;
    const dispatch = useDispatch();

    const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
    const [isErrorTemplate, setIsErrorTemplate] = useState(false);

    const [queryAccountId] = useSelectedAccountId();
    const getAccount = useAccountCache();
    const accountDescription = getAccount(queryAccountId)?.accountDescription;
    const { accountId } = useAccount();
    const commonMetadata = useExcelBulkCommonMetadata();
    const {
        isLoading: isLoadingDictionaryData,
        isError: isErrorDictionaryData,
        totalResources,
        currentResources,
        ...dictionaryData
    } = useDictionaryData();
    const { getBulkAccountCampaigns, getBulkAccountCreatives } = useBulkOperationsApi();

    const enabledExportableEntityConfigs = useEnabledExportableEntityConfigs();
    const { value: selectedCampaignGroups } = useFormFieldValue({
        field: 'selectedCampaignGroups',
    });

    const { isFullConfigAvailable, entityToRowLimitMap } = useExcelBulkUploadConfigs();

    const getExportDataMap = useCallback(async () => {
        const selectedCampaignGroupIds = map(selectedCampaignGroups, 'id').join(',') || null;

        try {
            const requestMap = getExportExcelRequestMap({
                enabledExportableEntityConfigs,
                apiCallerMap: {
                    [ENTITY.CAMPAIGN]: ({ shouldFilterActive }) =>
                        getBulkAccountCampaigns({
                            accountId,
                            fetchLevel: shouldFilterActive ? CAMPAIGNS_FETCH_LEVEL.ACTIVE : null,
                            selectedCampaignGroupIds,
                        }),
                    [ENTITY.CREATIVE]: ({ shouldFilterActive }) =>
                        getBulkAccountCreatives({
                            accountId,
                            statusFilter: shouldFilterActive ? CREATIVE_STATUS_FILTER.ACTIVE : null,
                            selectedCampaignGroupIds,
                        }),
                },
            });
            const apiResponseList = await Promise.all(values(requestMap));

            const requestMapKeys = keys(requestMap);
            const exportDataMap = apiResponseList.reduce((acc, apiResponse, index) => {
                const entity = requestMapKeys[index];
                acc[entity] = map(apiResponse.results, value => ({ value }));
                return acc;
            }, {});

            return exportDataMap;
        } catch (error) {
            console.log(error);
            dispatch(addIndication(FETCH_DATA_ERROR_INDICATION));
        }
    }, [
        selectedCampaignGroups,
        enabledExportableEntityConfigs,
        getBulkAccountCampaigns,
        accountId,
        getBulkAccountCreatives,
        dispatch,
    ]);

    const isError = isErrorTemplate || isErrorDictionaryData;
    const availableDictionarySheets = useAvailableDictionarySheets();
    const downloadTemplate = useCallback(
        async (mode = FORM_MODES.CREATE) => {
            const exportTimeStart = Date.now();

            if (isErrorDictionaryData) {
                dispatch(addIndication(INDICATION_DOWNLOAD_FAILED));
                return;
            }

            const shouldExportData = mode === FORM_MODES.EDIT;

            setIsLoadingTemplate(true);

            const exportDataMap = shouldExportData ? await getExportDataMap() : {};

            if (shouldExportData && !exportDataMap) {
                setIsLoadingTemplate(false);
                return;
            }

            try {
                let workbook = buildExcelBulkTemplate({
                    workbook: new Workbook(),
                    excelConfig,
                    entityDataMap: exportDataMap,
                    metadata: commonMetadata,
                    dictionaryData,
                    isFullConfigAvailable,
                    entityToRowLimitMap,
                });
                workbook = addDictionarySheets({
                    workbook,
                    ...commonMetadata,
                    dictionaryData,
                    dictionarySheets: availableDictionarySheets,
                });

                const blob = await convertWorkbookToBlob(workbook);

                setIsErrorTemplate(false);
                const fileName = getExcelBulkFileName(
                    shouldExportData ? EXCEL_BULK_FILE_NAME.DATA_FILE_NAME : EXCEL_BULK_FILE_NAME.TEMPLATE_FILE_NAME,
                    formatMessage,
                    accountDescription
                );
                saveAsFile(blob, fileName);
                dispatch(addIndication(INDICATION_DOWNLOAD_SUCCESS));

                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                    component: `${BULK_UPLOAD_GTM_PREFIX}: Export Data Fetch Duration (seconds)`,
                    value: calcDurationInSeconds(exportTimeStart),
                });
            } catch (error) {
                console.log(error);
                setIsErrorTemplate(true);
                if (error instanceof ExcelBulkError) {
                    dispatch(
                        addIndication(
                            getExcelBulkErrorIndication({
                                messageCode: 'only.value.message',
                                templateParameters: { value: error.message },
                            })
                        )
                    );
                } else {
                    dispatch(addIndication(INDICATION_DOWNLOAD_FAILED));
                }
            } finally {
                setIsLoadingTemplate(false);
            }
        },
        [
            isErrorDictionaryData,
            getExportDataMap,
            dispatch,
            excelConfig,
            commonMetadata,
            dictionaryData,
            isFullConfigAvailable,
            entityToRowLimitMap,
            formatMessage,
            accountDescription,
            availableDictionarySheets,
        ]
    );

    return {
        entityList,
        downloadTemplate,
        isLoadingDictionaryData,
        isLoadingTemplate,
        isError,
        totalResources,
        currentResources,
    };
};
