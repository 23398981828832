"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AddGallerySVGIcon = function AddGallerySVGIcon() {
    return _react2.default.createElement(
        "svg",
        { width: "57px", height: "50px", viewBox: "0 0 57 50", version: "1.1" },
        _react2.default.createElement(
            "title",
            null,
            "Add_img_icon"
        ),
        _react2.default.createElement(
            "desc",
            null,
            "Created with Sketch."
        ),
        _react2.default.createElement(
            "defs",
            null,
            _react2.default.createElement("path", { d: "M52.9285714,33.0710511 C52.9285714,34.0372643 51.9922425,35.005078 50.8928571,35.1379918 L23.4107143,35.1379918 L23.4107143,12.4016442 L52.9285714,12.4016442 L52.9285714,33.0710511 Z", id: "path-1" }),
            _react2.default.createElement("path", { d: "M6.10714286,35.1379918 C5.1171551,35.0080698 4.07142857,34.072616 4.07142857,33.0710511 L4.07142857,21.7028773 L29.5178571,21.7028773 L29.5178571,35.1379918 L6.10714286,35.1379918 L6.10714286,35.1379918 Z", id: "path-3" }),
            _react2.default.createElement("path", { d: "M24,35.6521739 C23.3078042,35.8396739 23,35.5318697 23,35.6521739 C23,34.7724781 23.3078042,34.4646739 24,34.6521739 L28,34.6521739 L28,30.6521739 C27.8125,29.9599781 28.1203042,29.6521739 29,29.6521739 C28.8796958,29.6521739 29.1875,29.9599781 29,30.6521739 L29,34.6521739 L33,34.6521739 C33.6921958,34.4646739 34,34.7724781 34,35.6521739 C34,35.5318697 33.6921958,35.8396739 33,35.6521739 L29,35.6521739 L29,39.6521739 C29.1875,40.3443697 28.8796958,40.6521739 29,40.6521739 C28.1203042,40.6521739 27.8125,40.3443697 28,39.6521739 L28,35.6521739 L24,35.6521739 Z", id: "path-5" })
        ),
        _react2.default.createElement(
            "g",
            { id: "Style-Guides", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            _react2.default.createElement(
                "g",
                { id: "BS_Styleguide_Illustrations", transform: "translate(-1186.000000, -585.000000)" },
                _react2.default.createElement(
                    "g",
                    { id: "Add_img_icon", transform: "translate(1186.000000, 585.000000)" },
                    _react2.default.createElement(
                        "g",
                        { id: "Group-5" },
                        _react2.default.createElement("path", { d: "M52.546875,38.7718732 C54.6647434,38.7718732 56.5,36.8918172 56.5,34.7053763 L56.5,4.56649688 C56.5,2.38005597 54.6647434,0.5 52.546875,0.5 L4.453125,0.5 C2.33525664,0.5 0.5,2.38005597 0.5,4.56649688 L0.5,34.7053763 C0.5,36.8918172 2.33525664,38.7718732 4.453125,38.7718732 L52.546875,38.7718732 Z", id: "Stroke-15", stroke: "#667686", fill: "#FFFFFF" }),
                        _react2.default.createElement("path", { d: "M6.07142857,4.13388139 L50.9285714,4.13388139 C52.0331409,4.13388139 52.9285714,5.02931189 52.9285714,6.13388139 L52.9285714,33.1379918 C52.9285714,34.2425613 52.0331409,35.1379918 50.9285714,35.1379918 L6.07142857,35.1379918 C4.96685907,35.1379918 4.07142857,34.2425613 4.07142857,33.1379918 L4.07142857,6.13388139 C4.07142857,5.02931189 4.96685907,4.13388139 6.07142857,4.13388139 Z", id: "Rectangle-Copy-2", fill: "#EEF0F2" }),
                        _react2.default.createElement("path", { d: "M25.4464286,12.4016442 C25.4464286,14.6841419 23.6237333,16.5355255 21.375,16.5355255 C19.1262667,16.5355255 17.3035714,14.6841419 17.3035714,12.4016442 C17.3035714,10.1191464 19.1262667,8.26776277 21.375,8.26776277 C23.6237333,8.26776277 25.4464286,10.1191464 25.4464286,12.4016442", id: "Fill-12", fill: "#B8C1CA" }),
                        _react2.default.createElement(
                            "mask",
                            { id: "mask-2", fill: "white" },
                            _react2.default.createElement("use", { xlinkHref: "#path-1" })
                        ),
                        _react2.default.createElement("g", { id: "Clip-2" }),
                        _react2.default.createElement("path", { d: "M23.4107143,24.8032883 L33.5892857,35.1379918 L59.0357143,35.1379918 L38.6785714,13.4351145 C37.3963235,12.1203187 35.6650616,12.1203187 34.6071429,13.4351145 L23.4107143,24.8032883 Z", id: "Fill-1", fill: "#B8C1CA", mask: "url(#mask-2)" }),
                        _react2.default.createElement(
                            "mask",
                            { id: "mask-4", fill: "white" },
                            _react2.default.createElement("use", { xlinkHref: "#path-3" })
                        ),
                        _react2.default.createElement("g", { id: "Clip-5" }),
                        _react2.default.createElement("path", { d: "M1.01785714,35.1379918 L12.2142857,35.1379918 L30.5357143,35.1379918 L17.9030078,22.4467015 C16.7341483,21.2724215 14.8346584,21.2680272 13.6603784,22.4368867 C13.6570993,22.4401507 13.6538277,22.4434223 13.6505637,22.4467015 L1.01785714,35.1379918 Z", id: "Fill-4", fill: "#B8C1CA", mask: "url(#mask-4)" })
                    ),
                    _react2.default.createElement("circle", { id: "Oval", stroke: "#B5E8D6", strokeWidth: "3", fill: "#01D98E", cx: "28.5", cy: "35.5", r: "13" }),
                    _react2.default.createElement(
                        "mask",
                        { id: "mask-6", fill: "white" },
                        _react2.default.createElement("use", { xlinkHref: "#path-5" })
                    ),
                    _react2.default.createElement("use", { id: "Plus", fill: "#FFFFFF", fillRule: "nonzero", xlinkHref: "#path-5" })
                )
            )
        )
    );
};

exports.default = AddGallerySVGIcon;