import React, { useCallback } from 'react';
import { FormField, TextCheckbox, CheckboxGroup, IndicationContent, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { PlatformFiltersTooltip } from './PlatformFilterTooltip';
import styles from './sizeEstimationPlatformField.module.scss';

const platformsConfig = [
    { value: 'DESK', id: 'custom.contextual.size.estimation.platform.desktop', defaultMessage: 'Desktop' },
    { value: 'PHON', id: 'custom.contextual.size.estimation.platform.mobile', defaultMessage: 'Mobile' },
    { value: 'TBLT', id: 'custom.contextual.size.estimation.platform.tablet', defaultMessage: 'Tablet' },
];

export const SizeEstimationPlatformField = () => {
    const { value: platformTargeting, onChange: setPlatformTargeting } = useFormFieldValue({
        field: 'platforms',
    });

    const { formatMessage } = useIntl();

    const onSelectPlatform = useCallback(
        selectedPlatform =>
            setPlatformTargeting(platformTargeting ? [...platformTargeting, selectedPlatform] : [selectedPlatform]),
        [platformTargeting, setPlatformTargeting]
    );

    const onUnSelectPlatform = useCallback(
        unSelectedPlatform => {
            setPlatformTargeting(platformTargeting.filter(value => value !== unSelectedPlatform));
        },
        [platformTargeting, setPlatformTargeting]
    );

    const selectedValues = platformTargeting || [];

    const isShowWarningMessage = !selectedValues.length;

    return (
        <FormField
            inputId="size-estimation-platform"
            label={formatMessage({
                id: 'custom.contextual.size.estimation.platform.label',
                defaultMessage: 'Platform',
            })}
            labelClass={styles['label']}
            containerClass={styles['border']}
            helpText={<PlatformFiltersTooltip />}
            dataTestId="size-estimation-platform-field"
        >
            <CheckboxGroup
                id="estimationPlatformsCheckboxGroup"
                selectedValues={selectedValues}
                onSelectCheckbox={onSelectPlatform}
                onUnselectCheckbox={onUnSelectPlatform}
                className={styles['simulated-platform-checkbox-group']}
            >
                {platformsConfig.map(({ value, id, defaultMessage }) => (
                    <TextCheckbox
                        label={`${value}-checkbox`}
                        value={value}
                        key={value}
                        title={formatMessage({
                            id,
                            defaultMessage,
                        })}
                        labelClassName={styles['checkbox-label']}
                    />
                ))}
            </CheckboxGroup>
            {isShowWarningMessage && (
                <IndicationContent
                    type={INDICATION_TYPES.WARNING}
                    containerClassName={styles['simulated-platform-warning-label']}
                >
                    {formatMessage({
                        id: 'custom.contextual.size.estimation.platform.no-platform-warning',
                        defaultMessage: 'Select at least 1 platform',
                    })}
                </IndicationContent>
            )}
        </FormField>
    );
};

export default SizeEstimationPlatformField;
