/* Baselines */
export const REQUEST_FETCH_BASELINES = 'REQUEST_FETCH_BASELINES';
export const FETCH_BASELINES_SUCCESS = 'FETCH_BASELINES_SUCCESS';
export const FETCH_BASELINES_ERROR = 'FETCH_BASELINES_ERROR';

/* Segments */
export const REQUEST_FETCH_SEGMENTS = 'REQUEST_FETCH_SEGMENTS';
export const FETCH_SEGMENTS_SUCCESS = 'FETCH_SEGMENTS_SUCCESS';
export const FETCH_SEGMENTS_ERROR = 'FETCH_SEGMENTS_ERROR';

/* Segment picker */
export const TOGGLE_SEGMENT_PICKER_PINNED = 'TOGGLE_SEGMENT_PICKER_PINNED';
export const SET_SEGMENT_PICKER_SORTING = 'SET_SEGMENT_PICKER_SORTING';
export const SELECT_SEGMENT = 'SELECT_SEGMENT';
