import React from 'react';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';
import { useThirdPartyTagsConfig } from '../config';

export const TagTypeTooltip = () => {
    const { MSG_ID_PREFIX, TAG_TYPE_TOOLTIP_POSITION } = useThirdPartyTagsConfig();

    return (
        <Tooltip arrow placement={TAG_TYPE_TOOLTIP_POSITION}>
            <FormattedMessage
                id={`${MSG_ID_PREFIX}.tooltip.tagType`}
                defaultMessage="Accepted types are image/text pixels, and javascript from integrated vendors."
            />
        </Tooltip>
    );
};

export const InputTooltip = () => {
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();

    return (
        <Tooltip arrow>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.tooltip.tagValue.base`}
                    defaultMessage="For pixel, enter the pixel URL."
                />
            </TooltipSection>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.tooltip.tagValue.js`}
                    defaultMessage="For Javascript tags provided by the vendor - please copy the tag exactly as you received it."
                />
            </TooltipSection>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.tooltip.tagValue.warning`}
                    defaultMessage="Altering the code will result in an error"
                />
            </TooltipSection>
        </Tooltip>
    );
};

export const EventTypeTooltip = () => {
    const { MSG_ID_PREFIX } = useThirdPartyTagsConfig();

    return (
        <Tooltip arrow>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.tooltip.eventType.types`}
                    defaultMessage="Choose the event type that will trigger your Pixel tag: Impression, Viewable Impression, or Click."
                />
            </TooltipSection>
            <TooltipSection>
                <FormattedMessage
                    id={`${MSG_ID_PREFIX}.tooltip.eventType.js.types`}
                    defaultMessage="For Javascript tags, the event type is impressions only."
                />
            </TooltipSection>
        </Tooltip>
    );
};
