import React from 'react';
import classnames from 'classnames/bind';
import { Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import styles from './SiteItemDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SiteItemDropdownOption = ({ data, selectProps: { inputValue, isOptionDisabled } }) => {
    const { name } = data;

    return (
        <div className={styles['container']} role="listitem">
            <div className={classNameBuilder('name')}>
                <Dotdotdot lines={2}>
                    <Highlighter text={`${name}`} highlightedText={inputValue} />
                </Dotdotdot>
            </div>
        </div>
    );
};
