import { get } from 'lodash';
import { set as fpSet } from 'lodash/fp';

const myAudiencePath = 'value.campaignTargeting.customAudienceTargeting';

export const ignoreMyAudiencesDefaultExclude = rows =>
    rows.map(row => {
        const myAudiences = get(row, `${myAudiencePath}.collection`, []);
        return fpSet(myAudiencePath, { collection: myAudiences, ignoreDefaultExclude: true }, row);
    });

export const toGWCampaignTransformers = [ignoreMyAudiencesDefaultExclude];
