import { batch } from 'react-redux';
import { updateScheduledReport } from '../../../services/api/scheduledReportApi';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication, INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { updateScheduledReportError, updateScheduledReportSuccess } from '../actions/requestUpdateScheduledReport';

export const successIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.reports.scheduledReports.save.success"
            defaultMessage="Report successfully updated"
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const expandedSuccessIndication = {
    message: (
        <FormattedMessage
            id="app.campaigns.reports.scheduledReports.save.success.expanded"
            defaultMessage="Report successfully updated. Please allow up to 5 minutes for the report processing and delivery."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Unable to save changes." />,
});

export const updateScheduledReports =
    (changes, rowData, oldValue, rowIndex, newValue, context, executeNow = false) =>
    async dispatch => {
        const { accountName } = context;
        const { id: scheduledReportId } = rowData;
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));

        try {
            const updateScheduledReportResponse = await updateScheduledReport({
                accountId: accountName,
                scheduledReportId,
                changes,
                executeNow,
            });

            batch(() => {
                dispatch(updateScheduledReportSuccess(updateScheduledReportResponse));
                dispatch(addIndication(executeNow ? expandedSuccessIndication : successIndication));
            });
            return updateScheduledReportResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateScheduledReportError(error));
            throw error;
        }
    };
