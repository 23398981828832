import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { userSelector } from '../../authentication/selectors/baseSelectors';
import { useDBStorageQueryMutation } from './useDBStorageQueryMutation';

export const useDBStorage = () => {
    const { username } = useSelector(userSelector);
    const { data, mutate, remove, isReady } = useDBStorageQueryMutation(username);
    const getCurrentData = useCurrentValueGetter(data);

    const getter = useCallback(
        key => {
            if (!isReady) {
                return;
            }

            return get(getCurrentData(), key);
        },
        [isReady, getCurrentData]
    );

    const setter = useCallback(
        (key, value, context = {}) => {
            if (!isReady) {
                return;
            }

            mutate({ key, value, context });
        },
        [isReady, mutate]
    );

    const remover = useCallback(
        key => {
            if (!isReady) {
                return;
            }

            return remove(key);
        },
        [isReady, remove]
    );

    return { getter, setter, remover, isReady };
};
