import { useMemo } from 'react';
import { countBy, filter, get, isEmpty, sumBy, size, groupBy, mapValues } from 'lodash';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { TARGETING_TYPES } from '../../../config';
import { useIsMarketplaceAudiencesAndLogicEnabled } from './useIsMarketplaceAudiencesAndLogicEnabled';

const getIsValidTargeting = (rawIsValidTargeting, targetingCollection, isAndLogicEnabled) => {
    if (isAndLogicEnabled) {
        return rawIsValidTargeting;
    }

    const targetingCollectionWithValues = filter(targetingCollection, ({ values }) => !isEmpty(values));
    const { [TARGETING_TYPES.INCLUDE]: includeGroupCount = 0, [TARGETING_TYPES.EXCLUDE]: excludeGroupCount = 0 } =
        countBy(targetingCollectionWithValues, 'type');

    return rawIsValidTargeting && includeGroupCount <= 1 && excludeGroupCount <= 1;
};

const getTotalCollectionLength = collection => sumBy(collection, group => size(group?.values));
const getLengthsByType = collection => {
    const collectionByType = groupBy(collection, 'type');
    return mapValues(collectionByType, getTotalCollectionLength);
};

const emptyCollection = [];

export const useMarketplaceAudienceTargetingValues = () => {
    const isAndLogicEnabled = useIsMarketplaceAudiencesAndLogicEnabled();
    const { value: targetingValue = {} } = useFormFieldValue({
        field: 'campaignTargeting.marketplaceAudienceTargeting',
    });

    const targetingCollection = get(targetingValue, 'collection', emptyCollection);
    const rawIsValidTargeting = get(targetingValue, 'validTargeting', true);

    return useMemo(() => {
        const isValidTargeting = getIsValidTargeting(rawIsValidTargeting, targetingCollection, isAndLogicEnabled);
        const { [TARGETING_TYPES.INCLUDE]: includeLength = 0, [TARGETING_TYPES.EXCLUDE]: excludeLength = 0 } =
            getLengthsByType(targetingCollection);

        return { isValidTargeting, includeLength, excludeLength };
    }, [targetingCollection, rawIsValidTargeting, isAndLogicEnabled]);
};
