import { useMemo } from 'react';
import { keyBy } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';

export const useAudienceSeedFormFields = audienceList => {
    const { [COMMON_FLAGS.MIN_PBP_ELIGIBLE_SEED_EVENT_THRESHOLD]: minAudienceSize } = useCommonConfig([
        COMMON_FLAGS.MIN_PBP_ELIGIBLE_SEED_EVENT_THRESHOLD,
    ]);
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.EDIT;

    const pixelBasedPredictiveAudienceUrlValidations = useMemo(() => {
        const hasMinAudienceSize = audience => {
            const audienceById = keyBy(audienceList, 'value');
            return isEditMode || audienceById[audience].receivedEventCount >= minAudienceSize;
        };

        return [
            {
                validationFn: hasMinAudienceSize,
                options: { minimumUsers: minAudienceSize },
                messageId: 'audience.editor.select.audience.min.size.validation.error',
                defaultMessage: `Audience size is too small for Predictive Audience creation. Predictive audience must be a minimum of ${minAudienceSize} users.`,
            },
        ];
    }, [audienceList, isEditMode, minAudienceSize]);

    const {
        value: audienceSeedId,
        onChange: onAudienceSeedChange,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'audienceSeedId',
        validations: pixelBasedPredictiveAudienceUrlValidations,
    });

    return {
        audienceSeedId,
        onAudienceSeedChange,
        failedValidationData,
    };
};
