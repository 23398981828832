import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { FormField, Radio, RadioGroup, TimeFramePicker } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { CAMPAIGN_STATUS } from 'modules/campaigns/config';
import { withIndication } from 'modules/errors/components';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { useCampaignGroupExtensionEnabled } from '../../../campaigns-group-form/hooks/useCampaignGroupExtensionEnabled';
import { getDateWithTimeZone } from '../../../campaigns-reports/components/ValueFormatters/dateFormatter';
import TimeFrameTooltip from './TimeFrameTooltip';
import { useCampaignTimeFrameRestrictions } from './hooks/useCampaignTimeFrameRestrictions';
import { endTimeFrameValidations, startTimeFrameValidations } from './timeFrameValidations';
import styles from '../commonEditor.module.scss';

const TIME_FRAME_METHODS = {
    APPROVAL: 'approval',
    MANUAL: 'manual',
};

export const TimeFramePickerWithIndication = withIndication(TimeFramePicker);

const TimeFrame = () => {
    const {
        formAccount: { timeZoneName: formAccountTimeZoneName },
        isCurrentlyDirty,
        mode,
    } = useFormDataContext();
    const { formatMessage } = useIntl();
    const [selectedValue, setSelectedValue] = useState(
        mode !== FORM_MODES.EDIT ? TIME_FRAME_METHODS.APPROVAL : TIME_FRAME_METHODS.MANUAL
    );
    const { campaignGroupStartDate, campaignGroupEndDate } = useCampaignTimeFrameRestrictions();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();
    const disabled = selectedValue === TIME_FRAME_METHODS.APPROVAL && !campaignGroupExtensionEnabled;
    const startValidationDependencies =
        selectedValue === TIME_FRAME_METHODS.MANUAL || campaignGroupExtensionEnabled
            ? { mode, campaignGroupStartDate }
            : {};
    const {
        value: startDate,
        onChange: onChangeStartDate,
        failedValidationData: startFailedValidation,
    } = useFormValidatedValue({
        field: 'startDate',
        formFieldDependencies: { campaignGroupStartDate },
        validations: startTimeFrameValidations,
        validationDependencies: startValidationDependencies,
    });
    const endValidationDependencies =
        selectedValue === TIME_FRAME_METHODS.MANUAL || campaignGroupExtensionEnabled
            ? { mode, campaignGroupEndDate, startDate }
            : {};
    const {
        value: endDate,
        onChange: onChangeEndDate,
        failedValidationData: endFailedValidation,
    } = useFormValidatedValue({
        field: 'endDate',
        validations: endTimeFrameValidations,
        validationDependencies: endValidationDependencies,
    });
    const { value: status } = useFormFieldValue({ field: 'status' });
    const { onChange: onChangeHasEndDate } = useFormFieldValue({ field: 'hasEndDate' });
    const isCampaignGroupChanged = isCurrentlyDirty('campaignGroupId');
    const isEditMode = mode === FORM_MODES.EDIT;
    const showRadioGroup = !isEditMode && !campaignGroupExtensionEnabled;

    const onRadioChange = useCallback(
        option => {
            setSelectedValue(option);
            if (mode !== FORM_MODES.DUPLICATE) {
                onChangeStartDate(moment());
                onChangeEndDate(null);
            }
        },
        [mode, onChangeEndDate, onChangeStartDate]
    );

    const handleEndDate = useCallback(
        date => {
            onChangeHasEndDate(date !== null);
            onChangeEndDate(date);
        },
        [onChangeEndDate, onChangeHasEndDate]
    );

    const isStartDateDisabled =
        disabled ||
        (isEditMode && status !== CAMPAIGN_STATUS.PENDING_START_DATE && status !== CAMPAIGN_STATUS.PENDING_APPROVAL);

    const outsideRangeHandle = useCallback(
        day => {
            if (!campaignGroupExtensionEnabled || !campaignGroupStartDate) {
                return day.isBefore(moment().startOf('day'));
            }
            return (
                day.isBefore(moment().startOf('day')) ||
                day.isBefore(moment(campaignGroupStartDate)) ||
                day.isAfter(moment(campaignGroupEndDate))
            );
        },
        [campaignGroupExtensionEnabled, campaignGroupStartDate, campaignGroupEndDate]
    );

    const failedValidation = useMemo(() => {
        if (selectedValue === TIME_FRAME_METHODS.APPROVAL) {
            return false;
        }
        return { ...startFailedValidation, ...endFailedValidation };
    }, [selectedValue, startFailedValidation, endFailedValidation]);

    useEffect(() => {
        if (!isCampaignGroupChanged) {
            return;
        }

        if (!moment(campaignGroupStartDate).isBefore(moment().startOf('day'))) {
            onChangeStartDate(moment(campaignGroupStartDate));
        }
    }, [isCampaignGroupChanged, campaignGroupStartDate, onChangeStartDate]);

    return (
        <FormField
            containerClass={styles['input']}
            inputId="time-frame"
            label={<FormattedMessage id="campaign.time.frame.title" defaultMessage="Time Frame" />}
            helpText={<TimeFrameTooltip />}
        >
            {showRadioGroup && (
                <RadioGroup
                    name="time-frame"
                    onChange={onRadioChange}
                    selectedValue={selectedValue}
                    data-metrics-component="Timeframe"
                >
                    <Radio
                        value={TIME_FRAME_METHODS.APPROVAL}
                        title={
                            <FormattedMessage
                                id="campaign.time.frame.running.as.approved"
                                defaultMessage="Start running as soon as approved."
                            />
                        }
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={TIME_FRAME_METHODS.APPROVAL}
                    />
                    <Radio
                        value={TIME_FRAME_METHODS.MANUAL}
                        title={
                            <FormattedMessage
                                id="campaign.time.frame.set.start.and.end"
                                defaultMessage="Specify a start and end date."
                            />
                        }
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value={TIME_FRAME_METHODS.MANUAL}
                    />
                </RadioGroup>
            )}
            <TimeFramePickerWithIndication
                startDate={getDateWithTimeZone(startDate, formAccountTimeZoneName, null)}
                endDate={getDateWithTimeZone(endDate, formAccountTimeZoneName, null)}
                onStartDateChange={onChangeStartDate}
                onEndDateChange={handleEndDate}
                disableStartDate={isStartDateDisabled}
                disableEndDate={disabled}
                validationId="timeFrame"
                endDatePlaceholderText={formatMessage({
                    id: 'campaign.time.frame.no.end.date',
                    defaultMessage: 'No End Date',
                })}
                startDataInputIcon={<FormattedMessage id="campaign.time.frame.start" defaultMessage="START" />}
                endDataInputIcon={<FormattedMessage id="campaign.time.frame.end" defaultMessage="END" />}
                isOutsideRange={outsideRangeHandle}
                {...failedValidation}
            />
        </FormField>
    );
};

export default TimeFrame;
