import { invert, isEmpty, isNil, keyBy, set, flow, mapValues, some, map } from 'lodash';
import { isDefined } from 'modules/taboola-common-frontend-modules/formData/utils/isDefined';
import { BULK_ERROR_TYPES } from '../consts/bulkErrorTypes';
import { ExcelBulkError } from '../transformers/ExcelBulkError';
import { EXCEL_DEFAULT_VALUE_TERM } from '../transformers/excelValueTransformers';
import { convertFileToWorkbook } from '../utils/convertFileToWorkbook';
import { getEntityMetadataFromWorkbook, getSheetName } from '../utils/excelBulkWorkbookUtils';

const buildRowData = ({ row, headerRow, headerNameFieldMap, fieldMap }) => {
    const rowValuesArray = map(row.values, (cellData, index) => {
        const cellHeaderName = headerRow[index];
        const field = headerNameFieldMap[cellHeaderName];
        const column = fieldMap[field];

        return { cellData, field, cellHeaderName, column };
    });

    const rowData = {};
    rowValuesArray.forEach(({ field, cellData }) => set(rowData, field, cellData));

    return { rowValuesArray, rowData };
};

const buildRowEntity = ({ row, headerRow, fieldMap, headerNameFieldMap, dictionaryData, formatMessage, mediaMap }) => {
    const entityData = {};
    const parsingErrors = [];
    const offendingFields = [];

    // rowData is the "raw" data from this row without transformations, useful for transformers or disabling functions that need to know other values in this row.
    // the entityData passed into a column's transformer only contains values to the left of that column, so it is unreliable to use.
    const { rowValuesArray, rowData } = buildRowData({ row, headerRow, headerNameFieldMap, fieldMap });

    rowValuesArray.forEach(({ cellData, field, cellHeaderName, column }) => {
        if ((isNil(cellData) || cellData === '') && !column?.isEmptyAllowed) {
            return;
        }

        const isColumnDisabled = column?.getIsDisabled && column.getIsDisabled({ rowData });
        if (isColumnDisabled || !field) {
            return;
        }

        const transformFromExcel = fieldMap[field]?.transformFromExcel;
        const resetToDefaultValue = cellData === EXCEL_DEFAULT_VALUE_TERM;

        try {
            if (resetToDefaultValue && !column.hasOwnProperty('defaultValue')) {
                const defaultValueError = new ExcelBulkError(
                    formatMessage(
                        { id: 'excel.bulk.default.value.error' },
                        { cellHeaderName, default: EXCEL_DEFAULT_VALUE_TERM }
                    )
                );

                throw defaultValueError;
            }

            let parsedValue;

            if (resetToDefaultValue && !column.dynamicFieldPathGetter) {
                parsedValue = column?.defaultValue;
            } else if (transformFromExcel) {
                parsedValue = transformFromExcel(cellData, {
                    column,
                    formatMessage,
                    cellHeaderName,
                    entityData,
                    rowData,
                    mediaMap,
                    ...dictionaryData,
                });
            } else {
                parsedValue = cellData;
            }

            if (isDefined(parsedValue)) {
                set(entityData, field, parsedValue);
            }
        } catch (err) {
            set(entityData, field, cellData);
            const parsingErrorMessage =
                err instanceof ExcelBulkError
                    ? err.message
                    : formatMessage({ id: 'excel.bulk.generic.parsing.error' }, { value: err.message });
            const errorMessage = `${cellHeaderName}: ${parsingErrorMessage}`;

            parsingErrors.push(errorMessage);
            offendingFields.push(field);
        }
    });

    return { value: entityData, error: parsingErrors.join('\n'), offendingFields };
};

export const getDataWithSkipErrors = data =>
    mapValues(data, rows =>
        rows.map(row => {
            return row.error ? row : { ...row, error: { skippedUpload: true } };
        })
    );

export const buildExcelToEntityDataMap = async ({ file, mediaMap, excelConfig, dictionaryData, formatMessage }) => {
    const workbook = await convertFileToWorkbook(file);
    let hasParsingError = false;
    let data = {};

    excelConfig.entityList.forEach(sheetConfig => {
        const metadata = getEntityMetadataFromWorkbook(workbook, sheetConfig);
        const headerNameFieldMap = invert(metadata);
        const sheetName = getSheetName(sheetConfig.sheetName, formatMessage);
        const sheet = workbook.getWorksheet(sheetName);
        const startingReadRowNumber = sheetConfig.headerRowIndex + 1;
        const allRows = sheet.getRows(startingReadRowNumber, sheet.rowCount);
        const headerRow = sheet.getRow(sheetConfig.headerRowIndex).values;
        const rows = allRows.filter(row => !isEmpty(row.values));
        const fieldMap = keyBy(sheetConfig.columns, 'field');
        const { toGWTransformers = [] } = sheetConfig;
        const postProcessData = flow(toGWTransformers);

        const fromWorkbookData = rows.map(row => {
            const { value, error, offendingFields } = buildRowEntity({
                row,
                sheetConfig,
                headerRow,
                headerNameFieldMap,
                fieldMap,
                dictionaryData,
                formatMessage,
                mediaMap,
            });

            return {
                value,
                error: error ? { message: error, type: BULK_ERROR_TYPES.PARSING, offendingFields } : null,
            };
        });

        hasParsingError = hasParsingError || some(fromWorkbookData, 'error');
        data[sheetConfig.entity] = postProcessData(fromWorkbookData);
    });

    if (hasParsingError) {
        data = getDataWithSkipErrors(data);
    }

    return { data, hasParsingError };
};
