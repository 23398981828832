import React from 'react';

export const MasterCardSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.72222 19.4444C11.4348 19.4444 14.4444 16.4348 14.4444 12.7222C14.4444 9.00964 11.4348 6 7.72222 6C4.00964 6 1 9.00964 1 12.7222C1 16.4348 4.00964 19.4444 7.72222 19.4444Z"
                fill="#D8232A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 17.9079C13.1623 18.8678 14.6527 19.4444 16.2778 19.4444C19.9904 19.4444 23 16.4348 23 12.7222C23 9.00964 19.9904 6 16.2778 6C14.6527 6 13.1623 6.57667 12 7.53654C13.4929 8.76951 14.4444 10.6347 14.4444 12.7222C14.4444 14.8097 13.4929 16.6749 12 17.9079Z"
                fill="#ED9F2D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.53656C10.507 8.76953 9.55554 10.6348 9.55554 12.7222C9.55554 14.8097 10.507 16.6749 12 17.9079C13.4929 16.6749 14.4444 14.8097 14.4444 12.7222C14.4444 10.6348 13.4929 8.76953 12 7.53656Z"
                fill="#D8232A"
            />
        </svg>
    );
};
