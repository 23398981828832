import React, { useMemo } from 'react';
import { keyBy, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { LightningRoundIcon } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import usePerformanceRecommendationExecution from '../../hooks/usePerformanceRecommendationExecution';
import { ApplyButton } from '../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

const generateActionSpecificParams = action => {
    return mapValues(keyBy(action.params, 'name'), 'value');
};

const params = {
    titleCode: 'performance-recommendations.platform-targeting.title',
    iconImg: LightningRoundIcon,
    descriptionCode: 'performance-recommendations.platform-targeting.description',
    actionCode: 'performance-recommendations.platform-targeting.action',
    tooltipCode: 'performance-recommendations.platform-targeting.action.tooltip',
    dismissCode: 'performance-recommendations.general-recommendation.dismiss',
    successCode: 'performance-recommendations.general-recommendation.success-message',
    errorCode: 'performance-recommendations.general-recommendation.error-message',
};

export const PlatformTargetingPerformanceRecommendation = ({ recommendation, messageParams = {} }) => {
    const { formatMessage } = useIntl();
    const { campaignId, id: recommendationId, actions, accountName: accountId } = recommendation;
    const [action] = actions; // Single action card
    const { NEW_VALUE } = useMemo(() => generateActionSpecificParams(action), [action]);

    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId: action.actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: params.errorCode,
    });

    const actionDetails = {
        target: formatMessage({ id: `${params.actionCode}.${NEW_VALUE}` }),
    };

    const transformedMessageParams = {
        ...messageParams,
        recommendation_platform: formatMessage({
            id: `${params.descriptionCode}.${messageParams.recommendation_platform}`,
            defaultMessage: `${messageParams.recommendation_platform}`,
        }),
    };
    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            messageParams={transformedMessageParams}
            isApplied={apply.isSuccess}
            {...params}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode={params.dismissCode}
                    successCode={params.successCode}
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ApplyButton
                    textCode={params.actionCode}
                    actionParams={actionDetails}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    tooltipCode={params.tooltipCode}
                    onClick={apply.invoke}
                    recommendation={recommendation}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

PlatformTargetingPerformanceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};

export default PlatformTargetingPerformanceRecommendation;
