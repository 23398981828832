import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { SideDrawer } from 'components';
import { FORM_MODES } from 'modules/campaigns/config';
import { LayerRoute } from '../../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../../taboola-common-frontend-modules/account-configurations';
import {
    CONVERSION_EDITOR_ROUTE_PATH,
    CONVERSIONS_EDITOR_ROUTE_PATH,
    FUNNEL_EDITOR_ROUTE_PATH,
    PATH_TO_ENTITY_MAP,
    TRACKING_ROUTE,
    TRACKING_SETTINGS_ROUTE,
} from '../../config';
import { ConversionEditorPage } from '../../pages/ConversionEditorPage';
import { TrackingSettingsEditorPage } from '../../pages/TrackingSettingsEditorPage';
import { UnipFunnelEditorPage } from '../../pages/UnipFunnelEditorPage';

export const EditDrawer = ({ onCancel }) => {
    const { params: { mode, entity: entityParam } = {} } = useRouteMatch(CONVERSIONS_EDITOR_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={TRACKING_SETTINGS_ROUTE}
                        render={props => <TrackingSettingsEditorPage {...props} onCancel={close} />}
                    />
                    <LayerRoute
                        exact
                        path={CONVERSION_EDITOR_ROUTE_PATH}
                        render={props => <ConversionEditorPage {...props} onCancel={close} />}
                    />
                    <LayerRoute
                        exact
                        path={FUNNEL_EDITOR_ROUTE_PATH}
                        render={props => <UnipFunnelEditorPage {...props} onCancel={close} />}
                    />
                    <Redirect to={TRACKING_ROUTE} />
                </Switch>
            )}
        </SideDrawer>
    );
};

EditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
};
