import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import styles from './cardActions.module.scss';

const classNameBuilder = classnames.bind(styles);
const CardActions = ({ className, editPath, onDelete }) => {
    return (
        <div className={classNameBuilder('container', className)}>
            <div className={styles['buttons']}>
                {editPath && (
                    <Link to={editPath}>
                        <StyledButton
                            className={styles['action-button']}
                            iconBefore="edit-o"
                            type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                        />
                    </Link>
                )}
                <StyledButton
                    className={classNameBuilder(['action-button'], { disabled: !onDelete })}
                    iconBefore="delete"
                    disabled={!onDelete}
                    onClick={onDelete}
                    type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                />
            </div>
        </div>
    );
};

export default CardActions;
