import React, { forwardRef } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import BID_STRATEGIES from '../../../common-campaign-form/config/bidStrategies';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import LabelLayoutCellRenderer from './LabelLayoutCellRenderer';

export const CpaGoalCellRenderer = props => {
    const {
        value,
        data: { bidStrategy },
    } = props;

    return value && bidStrategy === BID_STRATEGIES.TARGET_CPA ? (
        <LabelLayoutCellRenderer
            className="cpa-goal"
            labelClassName="targetType"
            msgId={`app.campaigns.campaign.target.type.${bidStrategy}`}
        >
            <FormattedNumberCellRenderer {...props} />
        </LabelLayoutCellRenderer>
    ) : (
        <FormattedMessage id="app.campaigns.campaignsReports.cpaGoal.empty" defaultMessage="-" />
    );
};

export const CpaGoalCellRendererWithFormData = forwardRef((props, ref) => <CpaGoalCellRenderer {...props} />);
