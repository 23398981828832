import { useMemo } from 'react';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const NewNotificationTooltip = ({ newNotificationsCount }) => {
    const tooltipCode = useMemo(() => {
        if (!newNotificationsCount) {
            return 'app.notificationsCenter.tooltip.empty';
        }

        if (newNotificationsCount === 1) {
            return 'app.notificationsCenter.tooltip.single';
        }

        return 'app.notificationsCenter.tooltip.multiple';
    }, [newNotificationsCount]);

    return (
        <Tooltip position={TOOLTIP_POSITION.BOTTOM_END} arrow>
            <FormattedMessage id={tooltipCode} values={{ count: newNotificationsCount }} />
        </Tooltip>
    );
};
