'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.InputGroup = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.noop');

var _lodash2 = _interopRequireDefault(_lodash);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _config = require('./config');

var _DelimiterWrapper = require('../DelimiterWrapper');

var _DelimiterWrapper2 = _interopRequireDefault(_DelimiterWrapper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'inputGroup-module__taboola-svg-icon___3AIN8',
    'container': 'inputGroup-module__container___2awCt',
    'delimiter': 'inputGroup-module__delimiter___1Hcvt',
    'tooltip-container': 'inputGroup-module__tooltip-container___1jTAk'
};


var convertConfigToComponent = function convertConfigToComponent(options) {
    var _options$config = options.config,
        config = _options$config === undefined ? {} : _options$config,
        _options$data = options.data,
        data = _options$data === undefined ? {} : _options$data,
        updateData = options.updateData;
    var type = config.type,
        dataKey = config.dataKey,
        tooltip = config.tooltip,
        tooltipContainerClass = config.tooltipContainerClass,
        props = config.props;


    var FinalComponent = _config.ComponentMap[type] || type;
    var normalizedProps = (0, _config.normalizeInputProps)({
        type: type,
        data: data,
        dataKey: dataKey,
        updateData: updateData,
        props: props
    });

    if (FinalComponent) {
        var result = _react2.default.createElement(FinalComponent, Object.assign({
            key: dataKey
        }, normalizedProps));

        if (tooltip) {
            return _react2.default.createElement(
                'div',
                { className: (0, _classnames2.default)(styles['tooltip-container'], tooltipContainerClass), key: dataKey },
                result,
                tooltip
            );
        }

        return result;
    }

    return null;
};

var InputGroup = exports.InputGroup = (0, _react.forwardRef)(function (_ref, ref) {
    var configArray = _ref.config,
        data = _ref.data,
        setData = _ref.setData,
        className = _ref.className,
        hideDelimiters = _ref.hideDelimiters,
        Delimiter = _ref.delimiter,
        delimiterClassName = _ref.delimiterClassName;

    var childrenFromConfig = (0, _react.useMemo)(function () {
        var updateData = function updateData(dataKey, newData) {
            return setData(function (prevData) {
                return Object.assign({}, prevData, _defineProperty({}, dataKey, newData));
            });
        };

        if (!configArray || configArray.length === 0) {
            return null;
        }

        return configArray.map(function (config) {
            return convertConfigToComponent({ config: config, data: data, updateData: updateData });
        });
    }, [configArray, data, setData]);

    return _react2.default.createElement(
        'div',
        { className: (0, _classnames2.default)(styles['container'], className), ref: ref },
        hideDelimiters ? childrenFromConfig : _react2.default.createElement(
            _DelimiterWrapper2.default,
            { delimiter: Delimiter, delimiterClassName: delimiterClassName },
            childrenFromConfig
        )
    );
});

var ConfigPropType = {
    /** enum representing what type of input this config should be converted to
     * (can also be a function/class component if you dont want to use one of the prebuilt inputs) */
    type: _propTypes2.default.oneOfType([_propTypes2.default.oneOf(_config.INPUT_TYPES_LIST), _propTypes2.default.elementType]).isRequired,
    /** unique string representing this input */
    dataKey: _propTypes2.default.string.isRequired,
    /** if provided, adds container around input for tooltip to have as parent */
    tooltip: _propTypes2.default.node,
    /** override class name for container added when tooltip is present */
    tooltipContainerClass: _propTypes2.default.string,
    /** props passed to the generated component */
    props: _propTypes2.default.object
};
InputGroup.propTypes = {
    /** array of configs representing inputs */
    config: _propTypes2.default.arrayOf(_propTypes2.default.shape(ConfigPropType)),
    /** class name for container holding inputs */
    className: _propTypes2.default.string,
    /** state object modified by setData; the dataKey for each config will map to a key in this object */
    data: _propTypes2.default.object,
    /** modifier func for data; the default behavior of inputs will be to update their dataKey using this
     * function (e.g. the default behavior of an INPUT is to set its data key to e.target.value) */
    setData: _propTypes2.default.func,
    /** flag to turn off the delimiters in between inputs */
    hideDelimiters: _propTypes2.default.bool,
    /** delimiter passed to DelimiterWrapper; used to override delimiter between inputs */
    delimiter: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.node]),
    /** adds class name to DelimiterWrapper delimiters */
    delimiterClassName: _propTypes2.default.string
};

InputGroup.defaultProps = {
    data: {},
    setData: _lodash2.default
};

exports.default = InputGroup;