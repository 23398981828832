import React from 'react';
import identity from 'lodash/identity';
import PropTypes from 'prop-types';
import styles from './graphTooltipMetrics.module.scss';

const defaultFormatter = identity;

const GraphTooltipMetrics = ({ payload }) => {
    return payload.map(({ dataKey, stroke, fill, value, name, formatter = defaultFormatter }) => (
        <div key={dataKey} className={styles['row']}>
            <span className={styles['name']}>{name}</span>
            <span style={{ color: stroke || fill }}>{formatter(value)}</span>
        </div>
    ));
};

GraphTooltipMetrics.propTypes = {
    payload: PropTypes.array,
};

GraphTooltipMetrics.defaultProps = {
    payload: [],
};

export default GraphTooltipMetrics;
