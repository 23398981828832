import React from 'react';
import { dateFormatter } from '../../config/fields/transformers';
import { CampaignHistoryFlagTooltip } from './CampaignHistoryFlagTooltip';
import styles from './campaignHistoryFlag.module.scss';

const timelineDateFormatter = dateFormatter('MMM DD, YYYY');

export const CampaignHistoryFlag = ({ x, y, activityData }) => {
    const { activityDate, activities = {} } = activityData;
    const formattedDate = activityDate && timelineDateFormatter(activityDate);
    return formattedDate ? (
        <svg
            width="24"
            height="24"
            x={x - 12}
            y={y - 12}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['flag-icon']}
            role="svg"
        >
            <rect x="2" y="2" width="20" height="20" fill="transparent" />
            <rect
                x="3.58579"
                y="12"
                width="11.8995"
                height="11.8995"
                rx="2"
                transform="rotate(-45 3.58579 12)"
                fill="#667686"
                stroke="#667686"
                strokeWidth="2"
                className={styles['flag']}
            />
            <path
                d="M18.1522 11.2929L11.9296 5.07035V18.9296L18.1522 12.7071C18.5427 12.3166 18.5427 11.6834 18.1522 11.2929Z"
                fill="white"
            />
            <CampaignHistoryFlagTooltip date={formattedDate} activityDetails={activities} />
        </svg>
    ) : null;
};
