import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormLayout } from 'modules/campaigns/components';
import { permissionsShape } from 'modules/taboola-common-frontend-modules/authentication/components/PermissionsGuard';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useCreativeCampaignData } from '../../../creative-creator/hooks/useCreativeCampaignData';
import { useIsAppInstallCreativeInEditMode } from '../../../creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { isAppleTargeting } from '../../../creative-creator/utils/isAppleTargeting';
import config from '../../config';
import { CreativePreviewBody } from '../Body';
import { CreativePreviewHeader } from '../Header';
import styles from './creativePreviewBase.module.scss';

const getPlacements = (isAppInstallInEdit, isTargetingApple) => {
    if (isTargetingApple) {
        return config.applePlacements;
    }
    if (isAppInstallInEdit) {
        return config.appInstallPlacements;
    }
    return config.feedBPPlacements;
};

const CreativePreviewBase = ({ onCancel, onEdit, editPermissionsProps, formLayoutProps, brandingText, language }) => {
    const {
        data: { title, description, cta, thumbnailUrl, creativeId, creativeFocus, creativeCrop, logo, rating },
    } = useFormDataContext();
    const items = useMemo(() => {
        return [
            {
                title,
                description,
                cta,
                thumbnailUrl,
                creativeId,
                brandingText,
                creativeFocus,
                creativeCrop,
                language,
                logo,
                rating,
            },
        ];
    }, [
        title,
        description,
        cta,
        thumbnailUrl,
        creativeId,
        brandingText,
        creativeFocus,
        creativeCrop,
        language,
        logo,
        rating,
    ]);

    const { isAppInstall: isAppInstallInEdit } = useIsAppInstallCreativeInEditMode();
    const { fetchedCampaign: campaign } = useCreativeCampaignData();
    const isTargetingApple = isAppleTargeting(campaign);
    const placements = getPlacements(isAppInstallInEdit, isTargetingApple);

    return (
        <FormLayout
            className={styles['container']}
            onCancel={onCancel}
            header={<CreativePreviewHeader onEdit={onEdit} editPermissionsProps={editPermissionsProps} />}
            hideFooter
            {...formLayoutProps}
        >
            <div className={styles['body-container']}>
                <CreativePreviewBody items={items} placements={placements} />
            </div>
        </FormLayout>
    );
};

CreativePreviewBase.propTypes = {
    onCancel: PropTypes.func,
    onEdit: PropTypes.func,
    editPermissionsProps: permissionsShape,
    formLayoutProps: PropTypes.shape(FormLayout.propTypes),
    title: PropTypes.string,
    description: PropTypes.string,
    cta: PropTypes.shape({
        ctaType: PropTypes.string,
    }),
    thumbnailUrl: PropTypes.string,
    creativeId: PropTypes.string,
    brandingText: PropTypes.string,
    creativeFocus: PropTypes.shape({
        type: PropTypes.string,
        coordinates: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }),
    language: PropTypes.string,
};

export default CreativePreviewBase;
