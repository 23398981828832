'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactSpinners = require('react-spinners');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'loadingAnimationColor': 'var(--blue)',
    'taboola-svg-icon': 'loadingAnimation__taboola-svg-icon___3M6lA',
    'moon-loader-container': 'loadingAnimation__moon-loader-container___2jcNv',
    'loading-label': 'loadingAnimation__loading-label___1iAF7'
};

/**
 * @deprecated - use LoadingAnimation from TA
 */

var LoadingAnimation = function LoadingAnimation(_ref) {
    var animaSizePx = _ref.animaSizePx,
        label = _ref.label,
        className = _ref.className;
    return _react2.default.createElement(
        'div',
        { className: styles['moon-loader-container'] + ' ' + className },
        _react2.default.createElement(_reactSpinners.MoonLoader, {
            size: animaSizePx,
            color: styles.loadingAnimationColor,
            loading: true
        }),
        _react2.default.createElement(
            'div',
            { className: styles['loading-label'] },
            label
        )
    );
};

LoadingAnimation.propTypes = {
    /** Animation size in PX */
    animaSizePx: _propTypes2.default.number,
    /** Text Label below the loading animation */
    label: _propTypes2.default.node,
    /** Class added to the container */
    className: _propTypes2.default.string
};

LoadingAnimation.defaultProps = {
    animaSizePx: 20,
    className: ''
};

exports.default = LoadingAnimation;