import React, { useCallback, useState } from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useGoogleApi } from 'modules/taboola-common-frontend-modules/googleApi';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { AccountDropdown } from './GtmAccount/AccountDropdown';
import { GtmConnectButton } from './GtmConnectForm/GtmConnectButton';
import { ContainerDropdown } from './GtmContainer/ContainerDropdown';
import { WorkspaceDropdown } from './GtmWorkspace/WorkspaceDropdown';
import styles from '../GtmConfiguration/configuration.module.scss';

export const Configuration = () => {
    const { checkAuth, getToken } = useGoogleApi();
    const [, setAccessToken] = useState(getToken());
    const { onChange: onGtmAccountChange } = useFormFieldValue({ field: 'gtmAccount' });
    const { onChange: onGtmContainerChange } = useFormFieldValue({ field: 'gtmContainer' });
    const { onChange: onGtmWorkspaceChange } = useFormFieldValue({ field: 'gtmWorkspace' });

    const handleConnect = useCallback(async () => {
        onGtmWorkspaceChange(null);
        onGtmContainerChange(null);
        onGtmAccountChange(null);
        await checkAuth(true);
        setAccessToken(getToken());
    }, [checkAuth, getToken, onGtmAccountChange, onGtmContainerChange, onGtmWorkspaceChange]);

    return (
        <CommonCollapsibleCard
            id="Quick.Integration"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="tracking.setup.gtm.configuration.label"
                            defaultMessage="Quick Integration"
                        />
                    }
                />
            }
        >
            <div className={styles['description']}>
                <FormattedMessage
                    id="tracking.setup.gtm.configuration.description"
                    defaultMessage="Make sure your site has an active Google Tag Manager account and implementation before starting. To create and implement a new GTM account, <a>click here</a>"
                />
            </div>

            <GtmConnectButton handleConnect={handleConnect} />

            <AccountDropdown />
            <ContainerDropdown />
            <WorkspaceDropdown />
        </CommonCollapsibleCard>
    );
};
