import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { get, reject } from 'lodash';
import { CAMPAIGNS_GROUP_STATUS } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useAudiencesApi, useResourcesApi, useCampaignGroupApi, useAccountsApi } from 'services/api';
import { useCampaignLevelConversionsApi } from 'services/api/campaignLevelConversionsApi';
import { useGeoTargetingApi } from 'services/api/geoTargetingApi';
import { COMPONENT_STATUS } from 'services/constants';
import { TARGETING_TYPES } from '../../common-campaign-form';
import BRAND_SAFETY_TYPES from '../../common-campaign-form/config/BrandSafeTypes';
import { BULK_UPLOAD_GTM_PREFIX } from '../consts/gtmConsts';
import { calcDurationInSeconds } from '../utils/bulkUploadGtmUtils';
import { useAvailableDictionarySheets } from './useAvailableDictionarySheets';
import { useStaticDictionaryData } from './useStaticDictionaryData';

const EXCEL_BULK_DICTIONARY = 'excelBulkDictionary';
const CACHE_TIME = 5 * 60 * 1000;
const CONVERSION_RULE_PAGE_SIZE = 999;
const CAMPAIGN_GROUPS_PAGE_SIZE = 999;

const REJECTED_CAMPAIGN_GROUPS_STATUSES = new Set([CAMPAIGNS_GROUP_STATUS.TERMINATED]);
export const ACCEPTED_CAMPAIGN_GROUPS_STATUSES = reject(CAMPAIGNS_GROUP_STATUS, status =>
    REJECTED_CAMPAIGN_GROUPS_STATUSES.has(status)
);

const getSafeApiList = async apiPromise => {
    try {
        return await apiPromise;
    } catch {
        return [];
    }
};

export const useDictionaryData = () => {
    const [totalResources, setTotalResources] = useState(0);
    const [currentResources, setCurrentResources] = useState(0);

    const { value: countryCode } = useFormFieldValue({ field: 'selectedCountry' });
    const { formAccount: selectedAccount, fetchStatus } = useFormDataContext();
    const { accountName, id: accountId } = selectedAccount;

    const { getOsList, getBrowsers, getCountries, getTimeZones, getBrandSafetyCategories } = useResourcesApi();
    const { getAllAccountsUnderNetwork } = useAccountsApi();
    const { getAccountLevelConversionRulesData } = useCampaignLevelConversionsApi();
    const geoTargetingApi = useGeoTargetingApi();
    const audienceApi = useAudiencesApi();
    const campaignGroupsApi = useCampaignGroupApi();
    const dictionaryCallerMap = useMemo(
        () => ({
            countryList: getCountries,
            regionList: () => geoTargetingApi.getCountryRegions(countryCode),
            dmaList: () => getSafeApiList(geoTargetingApi.getCountryDMAs(countryCode)),
            cityList: () => geoTargetingApi.getCities(countryCode),
            osList: getOsList,
            browserList: getBrowsers,
            brandSafetyDV: () => getBrandSafetyCategories(accountId, BRAND_SAFETY_TYPES.DV),
            brandSafetyIAS: () => getBrandSafetyCategories(accountId, BRAND_SAFETY_TYPES.IAS),
            conversionRules: () => getAccountLevelConversionRulesData(accountName, 1, CONVERSION_RULE_PAGE_SIZE),
            contextualSegments: () => audienceApi.getContextualSegments(accountName),
            marketplaceAudiences: () => audienceApi.getMarketplaceSegments(accountName),
            myAudiences: () => audienceApi.getMyAudiences(accountName, TARGETING_TYPES.INCLUDE, countryCode),
            timeZones: getTimeZones,
            campaignGroups: () =>
                campaignGroupsApi.getCampaignGroupList(accountName, {
                    page: 1,
                    pageSize: CAMPAIGN_GROUPS_PAGE_SIZE,
                    statuses: ACCEPTED_CAMPAIGN_GROUPS_STATUSES,
                }),
            accounts: () => getAllAccountsUnderNetwork(accountName),
        }),
        [
            getCountries,
            geoTargetingApi,
            getAccountLevelConversionRulesData,
            audienceApi,
            campaignGroupsApi,
            accountId,
            accountName,
            getOsList,
            getBrowsers,
            getTimeZones,
            getBrandSafetyCategories,
            countryCode,
            getAllAccountsUnderNetwork,
        ]
    );
    const availableDictionarySheets = useAvailableDictionarySheets();
    const {
        data: dictionaryData,
        isLoading,
        isError,
    } = useQuery(
        [EXCEL_BULK_DICTIONARY, accountName, countryCode, fetchStatus],
        async () => {
            if (fetchStatus !== COMPONENT_STATUS.ACTIVE) {
                return {};
            }

            const processingTimeStart = Date.now();
            const dynamicConfigs = availableDictionarySheets.filter(sheet => !sheet.isStaticData);
            setTotalResources(dynamicConfigs.length);
            setCurrentResources(0);
            const callerList = dynamicConfigs.map(({ dataPath }) => {
                if (!dictionaryCallerMap[dataPath]) {
                    throwCriticalError(`No caller for ${dataPath} in dictionaryCallerMap`);
                }

                return new Promise(resolve => {
                    dictionaryCallerMap[dataPath]().then(response => {
                        setCurrentResources(current => current + 1);
                        resolve(response);
                    });
                });
            });
            const responseList = await Promise.all(callerList);
            const result = responseList.reduce((acc, value, index) => {
                const dataPath = dynamicConfigs[index].dataPath;
                const responseTransformer = get(dynamicConfigs[index], 'responseTransformer', value => ({
                    [dataPath]: value,
                }));
                const transformedData = responseTransformer(value, dataPath);
                const mergedData = { ...acc, ...transformedData };
                return mergedData;
            }, {});

            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component: `${BULK_UPLOAD_GTM_PREFIX}_Dictionary_Data: Processing Duration (seconds)`,
                value: calcDurationInSeconds(processingTimeStart),
            });

            return result;
        },
        { cacheTime: CACHE_TIME, staleTime: CACHE_TIME }
    );
    const staticDictionaryData = useStaticDictionaryData();

    return {
        ...staticDictionaryData,
        ...dictionaryData,
        selectedAccount,
        isLoading,
        isError,
        totalResources,
        currentResources,
    };
};
