const operationsPrefix = accountId => `/${accountId}/operations`;
const pageMetadataAPIPrefix = accountId => `${operationsPrefix(accountId)}/page-metadata`;
const uploadImageAPIPrefix = accountId => `${operationsPrefix(accountId)}/images/upload`;
const uploadLogoAPIPrefix = accountId => `${uploadImageAPIPrefix(accountId)}/logo`;
const uploadZipAPIPrefix = accountId => `${uploadImageAPIPrefix(accountId)}/zip`;
const uploadImageFromUrlAPIPrefix = accountId => `${operationsPrefix(accountId)}/images/upload/image-url`;
const uploadVideoAPIPrefix = accountId => `${operationsPrefix(accountId)}/video/upload`;
const uploadMotionAdsAPIPrefix = accountId => `${operationsPrefix(accountId)}/video/upload-url`;
const getWochitAuthenticationAPIPrefix = accountId => `${operationsPrefix(accountId)}/wochit-authentication`;
const recommendedImagesAPIPrefix = accountId => `${operationsPrefix(accountId)}/images/recommended`;
const recommendedVideosAPIPrefix = accountId => `${operationsPrefix(accountId)}/videos/recommended`;
const keywordsAPIPrefix = accountId => `${operationsPrefix(accountId)}/keywords`;

const getFormDataOptions = file => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return {
        body: formData,
        headers: { Accept: 'application/json' },
    };
};

const getFormDataVideoOptions = (thumbnail, campaignId, titleList) => {
    const formData = new FormData();
    formData.append('videoFile', thumbnail.file, thumbnail.file.name);
    if (thumbnail.fallbackImage?.file) {
        formData.append('thumbnailFile', thumbnail.fallbackImage.file, thumbnail.fallbackImage.file.name);
    }
    if (campaignId || titleList) {
        appendDefaultImageInputOption(formData, campaignId, titleList);
    }

    return {
        body: formData,
        headers: { Accept: 'application/json' },
    };
};
const getFormDataMotionAdsOptions = (thumbnail, campaignId, titleList) => {
    const formData = new FormData();
    formData.append('apiVideo', thumbnail.src);
    if (thumbnail.fallbackImage) {
        formData.append('fallbackFile', thumbnail.fallbackImage.file, thumbnail.fallbackImage.file.name);
    }
    if (campaignId || titleList) {
        appendDefaultImageInputOption(formData, campaignId, titleList);
    }

    return {
        body: formData,
        headers: { Accept: 'application/json' },
    };
};

const appendDefaultImageInputOption = (formData, campaignId, titleList) => {
    const defaultImageInputPojo = { campaignId, titles: titleList };
    const defaultImageInputBlob = new Blob([JSON.stringify(defaultImageInputPojo)], {
        type: 'application/json',
    });
    formData.append('defaultImageInput', defaultImageInputBlob);
};

export const operationsApiFactory = ({ callGetApi, callPostApi }) => ({
    getPageMetadata: (accountId, url, options) =>
        callGetApi(`${pageMetadataAPIPrefix(accountId)}`, { urlPageMetadata: url, ...options }),
    uploadThumbnailFromUrl: (accountId, thumbnail) =>
        callPostApi(`${uploadImageFromUrlAPIPrefix(accountId)}`, { imageUrl: thumbnail }),
    uploadThumbnail: (accountId, thumbnail) =>
        callPostApi(`${uploadImageAPIPrefix(accountId)}`, thumbnail, getFormDataOptions(thumbnail)),
    uploadLogo: (accountId, logo) => callPostApi(`${uploadLogoAPIPrefix(accountId)}`, logo, getFormDataOptions(logo)),
    uploadZip: (accountId, zip) => callPostApi(uploadZipAPIPrefix(accountId), zip, getFormDataOptions(zip)),
    uploadVideoThumbnailWithImageRecommendation: (accountId, thumbnail, campaignId, titleList) => {
        return callPostApi(
            `${uploadVideoAPIPrefix(accountId)}/with-image-recommendations`,
            thumbnail,
            getFormDataVideoOptions(thumbnail, campaignId, titleList)
        );
    },
    getRecommendedImages: ({ accountId, ...options }) =>
        callGetApi(`${recommendedImagesAPIPrefix(accountId)}`, { paginationEnabled: true, ...options }),
    uploadRecommendedImages: (accountId, recommendedImage) =>
        callPostApi(`${recommendedImagesAPIPrefix(accountId)}`, recommendedImage),
    uploadRecommendedVideo: (accountId, recommendedVideo) =>
        callPostApi(`${recommendedVideosAPIPrefix(accountId)}`, recommendedVideo),
    getRecommendedVideos: ({ accountId, ...options }) =>
        callGetApi(`${recommendedVideosAPIPrefix(accountId)}`, { paginationEnabled: true, ...options }),
    getPageKeywords: (accountId, url) => callGetApi(`${keywordsAPIPrefix(accountId)}`, { urlPageMetadata: url }),
    uploadMotionAdsByUrlWithImageRecommendation: (accountId, thumbnail, campaignId, titleList) => {
        return callPostApi(
            `${uploadMotionAdsAPIPrefix(accountId)}/with-image-recommendations`,
            thumbnail,
            getFormDataMotionAdsOptions(thumbnail, campaignId, titleList)
        );
    },
    getWochitAuthentication: accountId => callPostApi(`${getWochitAuthenticationAPIPrefix(accountId)}`),
});
