import React from 'react';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { operationsApi } from '../../../../../services/api';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import {
    requestUploadCreativeThumbnail,
    uploadCreativeThumbnailSuccess,
    uploadCreativeThumbnailError,
} from '../actions';

const errorIndication = {
    message: (
        <FormattedMessage
            id="creative.save.error.recommended.image"
            defaultMessage="We were not able to upload ad recommended image."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

const uploadCreativeRecommendedImage = (accountId, recommendedImage) => async dispatch => {
    dispatch(requestUploadCreativeThumbnail());

    try {
        const { cdnUrl: thumbnailUrl } = await operationsApi.uploadRecommendedImages(accountId, recommendedImage);
        dispatch(uploadCreativeThumbnailSuccess());
        return { thumbnailUrl };
    } catch (error) {
        dispatch(uploadCreativeThumbnailError());
        dispatch(addIndication(errorIndication));
        return { error };
    }
};

export default uploadCreativeRecommendedImage;
