import React from 'react';
import classnames from 'classnames/bind';
import styles from './uiAssistant.module.scss';

const classNameBuilder = classnames.bind(styles);

export const UIAssistant = ({ label, headerIcon, headerIconClassName, backgroundUIAssistant, isCollapsed }) => {
    return (
        <div className={classNameBuilder('assistant-container', backgroundUIAssistant, { extended: !isCollapsed })}>
            {headerIcon && <div className={classNameBuilder('header-icon', headerIconClassName)}>{headerIcon}</div>}
            <div className={classNameBuilder('title-text')}>{label}</div>
        </div>
    );
};
