import React from 'react';
import { INDICATION_TYPES } from '../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';

const formValidationErrorIndication = {
    message: (
        <FormattedMessage
            id="form.validation.error"
            defaultMessage="One or more fields in the form requires your attention before proceeding"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
};

export default formValidationErrorIndication;
