import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { RadioGroup, FormField } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors';
import styles from '../../../Form/FormFields/formFields.module.scss';

const classNameBuilder = classnames.bind(styles);

const RadioGroupWithIndications = withIndication(RadioGroup);

export const RadioGroupField = forwardRef(
    (
        {
            groupTitle,
            groupDescription,
            groupHelpText,
            inputId,
            radioGroupId,
            onChange,
            className,
            radioGroupName,
            radioGroupClassName,
            selectedValue,
            children,
            ...rest
        },
        ref
    ) => (
        <FormField
            inputId={inputId}
            label={groupTitle}
            description={groupDescription}
            helpText={groupHelpText}
            containerClass={classNameBuilder('input', className)}
            ref={ref}
        >
            <RadioGroupWithIndications
                id={radioGroupId}
                name={radioGroupName}
                className={radioGroupClassName}
                onChange={onChange}
                selectedValue={selectedValue}
                {...rest}
            >
                {children}
            </RadioGroupWithIndications>
        </FormField>
    )
);

RadioGroupField.propTypes = {
    groupTitle: PropTypes.node,
    description: PropTypes.node,
    groupHelpText: PropTypes.node,
    inputId: PropTypes.string,
    radioGroupId: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    radioGroupClassName: PropTypes.string,
    radioGroupName: PropTypes.string,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    children: PropTypes.node.isRequired,
};

RadioGroupField.defaultProps = {
    groupTitle: '',
    groupHelpText: '',
    inputId: '',
    onChange: () => {},
    className: '',
    radioGroupClassName: '',
    radioGroupName: '',
};

export default RadioGroupField;
