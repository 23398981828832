import React from 'react';
import classnames from 'classnames/bind';
import { FormattedNumber, useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { BaseCard } from '../BaseCard/BaseCard';
import styles from './creditLimit.module.scss';

const classNameBuilder = classnames.bind(styles);
const CreditLimit = ({ className, creditLimit, currency, limitTitle, renewedOnDate }) => {
    const { formatMessage } = useIntl();
    return (
        <BaseCard
            title={formatMessage({ id: 'billingAndPayments.creditLimit.title', defaultMessage: 'Credit Limit' })}
            detailsFirstLine={limitTitle}
            detailsSecondLine={
                <FormattedNumber
                    value={creditLimit}
                    variant="currency"
                    currency={currency}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                />
            }
            dateTitle={
                renewedOnDate
                    ? formatMessage({
                          id: 'billingAndPayments.creditLimit.renewed.on',
                          defaultMessage: 'Renewed on',
                      })
                    : null
            }
            date={renewedOnDate ?? null}
            className={classNameBuilder('credit-limit-background', className)}
        />
    );
};

export default CreditLimit;
