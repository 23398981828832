import { getLocationSearch, mergeQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import { REPORT_ID } from '../modules/campaigns-reports/hooks/useSelectedReportId';
import { SELECT_REPORT } from './actionTypes';

/**
 * @deprecated - use useSelectedReportId
 */
export default reportId => ({
    type: SELECT_REPORT,
    payload: reportId,
    meta: {
        query: mergeQueryParams(getLocationSearch(), {
            [REPORT_ID]: reportId,
        }),
    },
});
