import { DinersClub, Discover, MasterCard, Amex, Visa } from './icons';
import { Ach } from './icons/Ach';
import { CartesBancaires } from './icons/CartesBancaires';
import {
    AmericanExpressLogo,
    DinersLogo,
    DiscoverLogo,
    JCBLogo,
    PayPalLogo,
    UnionPayLogo,
    MasterCardLogo,
    VisaLogo,
} from './logos';
import {
    AmericanExpressSmallLogo,
    DinersSmallLogo,
    DiscoverSmallLogo,
    JCBSmallLogo,
    PayPalSmallLogo,
    UnionPaySmallLogo,
    MasterCardSmallLogo,
    VisaSmallLogo,
} from './smallLogos';
import { BoletoSmallLogo } from './smallLogos/BoletoSmallLogo';

export const BRAND_TYPE = {
    DINERS: 'DINERS',
    AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
    MASTER_CARD: 'MASTERCARD',
    VISA: 'VISA',
    PAYPAL: 'PAYPAL',
    DISCOVER: 'DISCOVER',
    JCB: 'JCB',
    UNION_PAY: 'UNION_PAY',
    BOLETO: 'BOLETO',
    ACH: 'ACH',
    CARTES_BANCAIRES: 'CARTES_BANCAIRES',
};

export const LOGO_TYPE = {
    SMALL_LOGO: 'SMALL_LOGO',
    LOGO: 'LOGO',
    ICON: 'ICON',
};

const BRAND_TO_LOGOS_MAP = {
    [BRAND_TYPE.DINERS]: {
        [LOGO_TYPE.ICON]: DinersClub(),
        [LOGO_TYPE.LOGO]: DinersLogo(),
        [LOGO_TYPE.SMALL_LOGO]: DinersSmallLogo(),
    },
    [BRAND_TYPE.AMERICAN_EXPRESS]: {
        [LOGO_TYPE.ICON]: Amex(),
        [LOGO_TYPE.LOGO]: AmericanExpressLogo(),
        [LOGO_TYPE.SMALL_LOGO]: AmericanExpressSmallLogo(),
    },
    [BRAND_TYPE.MASTER_CARD]: {
        [LOGO_TYPE.ICON]: MasterCard(),
        [LOGO_TYPE.LOGO]: MasterCardLogo(),
        [LOGO_TYPE.SMALL_LOGO]: MasterCardSmallLogo(),
    },
    [BRAND_TYPE.VISA]: {
        [LOGO_TYPE.ICON]: Visa(),
        [LOGO_TYPE.LOGO]: VisaLogo(),
        [LOGO_TYPE.SMALL_LOGO]: VisaSmallLogo(),
    },
    [BRAND_TYPE.PAYPAL]: {
        [LOGO_TYPE.ICON]: null,
        [LOGO_TYPE.LOGO]: PayPalLogo(),
        [LOGO_TYPE.SMALL_LOGO]: PayPalSmallLogo(),
    },
    [BRAND_TYPE.DISCOVER]: {
        [LOGO_TYPE.ICON]: Discover(),
        [LOGO_TYPE.LOGO]: DiscoverLogo(),
        [LOGO_TYPE.SMALL_LOGO]: DiscoverSmallLogo(),
    },
    [BRAND_TYPE.JCB]: {
        [LOGO_TYPE.ICON]: null,
        [LOGO_TYPE.LOGO]: JCBLogo(),
        [LOGO_TYPE.SMALL_LOGO]: JCBSmallLogo(),
    },
    [BRAND_TYPE.UNION_PAY]: {
        [LOGO_TYPE.ICON]: null,
        [LOGO_TYPE.LOGO]: UnionPayLogo(),
        [LOGO_TYPE.SMALL_LOGO]: UnionPaySmallLogo(),
    },
    [BRAND_TYPE.BOLETO]: {
        [LOGO_TYPE.ICON]: null,
        [LOGO_TYPE.LOGO]: null,
        [LOGO_TYPE.SMALL_LOGO]: BoletoSmallLogo(),
    },
    [BRAND_TYPE.ACH]: {
        [LOGO_TYPE.ICON]: Ach(),
        [LOGO_TYPE.LOGO]: null,
        [LOGO_TYPE.SMALL_LOGO]: null,
    },
    [BRAND_TYPE.CARTES_BANCAIRES]: {
        [LOGO_TYPE.ICON]: CartesBancaires(),
        [LOGO_TYPE.LOGO]: null,
        [LOGO_TYPE.SMALL_LOGO]: null,
    },
};

export const BrandMark = ({ type, brand }) => {
    return BRAND_TO_LOGOS_MAP[brand]?.[type] ?? null;
};
