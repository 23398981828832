import { AiVariationsSetupSection } from './components/AiVariationsCreation/components/SetupSection/AiVariationsSetupSection';
import { OneByOneSetupSection } from './components/OneByOneCreation/OneByOneSetupSection';
import { RssSetupSection } from './components/RssCreation/RssSetupSection';
import { VariationsSetupSection } from './components/VariationsCreation/components/VariationsSetupSection';
import { CONTENT_METHODS } from './config';

export const contentMethodSetupSectionMap = {
    [CONTENT_METHODS.RSS]: RssSetupSection,
    [CONTENT_METHODS.VARIATIONS]: VariationsSetupSection,
    [CONTENT_METHODS.ONE_BY_ONE]: OneByOneSetupSection,
    [CONTENT_METHODS.AI_VARIATIONS]: AiVariationsSetupSection,
};
