import React, { useCallback } from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';

export const usePreventDragModal = () => {
    const { open } = useModal();
    const openHandler = useCallback(
        () =>
            open({
                title: (
                    <FormattedMessage
                        id="creative.creator.carousel.cards.cant.drag.title"
                        defaultMessage="Unable to reorder the cards."
                    />
                ),
                content: (
                    <FormattedMessage
                        id="creative.creator.carousel.cards.cant.drag.content"
                        defaultMessage="Please wait until all media files loaded or remove all error thumbnails."
                    />
                ),
                formProps: {
                    hasCancel: false,
                    submitButtonText: <FormattedMessage id="app.ok" defaultMessage="Ok" />,
                },
            }),
        [open]
    );

    return openHandler;
};
