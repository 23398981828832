import React from 'react';
import { getCampaignsGroupStatusMessages } from '../../../../services/utils';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

const CampaignsGroupStatusRenderer = props => {
    const {
        value: { status, displayStatus },
    } = props;
    // TODO DEV-142896-remove-shared-budget-from-ui-gw
    const resolvedStatus = status || displayStatus;
    const campaignsGroupStatusMessages = getCampaignsGroupStatusMessages(resolvedStatus);

    return <StatusCellRenderer {...props} {...campaignsGroupStatusMessages} value={resolvedStatus} />;
};

export default CampaignsGroupStatusRenderer;
