import { APP_EVENT_TYPE } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { CHAT_AGENT_ACTION_STATUS } from 'modules/taboola-common-frontend-modules/chat-agent/config/chatAgentActionStatus';

const editGridCell =
    ({
        // TODO DEV-66093 newValue, oldValue, rowIndex - have to be removed along with site data consistency issue solved
        valueChangeHandler,
        field,
        rowData = {},
        newValue,
        oldValue,
        rowIndex,
        context,
    }) =>
    async dispatch => {
        const { pushAppEvent } = context;
        // TODO DEV-66093 { [field]: oldValue }, rowIndex - have to be removed along with site data consistency issue solved
        try {
            const result = await dispatch(
                valueChangeHandler(
                    { [field]: rowData[field] },
                    rowData,
                    { [field]: oldValue },
                    rowIndex,
                    newValue,
                    context
                )
            );
            if (result && !result.error) {
                pushAppEvent({
                    status: CHAT_AGENT_ACTION_STATUS.SUCCESS,
                    data: result,
                    type: APP_EVENT_TYPE.EDIT_GRID_CELL,
                    id: field,
                });
            } else {
                pushAppEvent({
                    status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                    type: APP_EVENT_TYPE.EDIT_GRID_CELL,
                    message: result?.error?.message,
                    id: field,
                });
            }
        } catch (e) {
            pushAppEvent({
                status: CHAT_AGENT_ACTION_STATUS.FAILURE,
                type: APP_EVENT_TYPE.EDIT_GRID_CELL,
                message: e?.message,
                id: field,
            });
            throw e;
        }
    };

export default editGridCell;
