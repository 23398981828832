import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { noop } from 'lodash';
import { Resizable as ReResizable } from 're-resizable';

const defaultSize = { width: 0, height: 0 };
export const Resizable = ({
    children,
    minSize: minSizeProp,
    maxSize: maxSizeProp,
    size: sizeProp,
    onSizeChange = noop,
    ...rest
}) => {
    const [minSize, setMinSize] = useState(() => minSizeProp || defaultSize);
    const [maxSize, setMaxSize] = useState(() => maxSizeProp || {});
    const [size, setSize] = useState(() => sizeProp || minSizeProp || defaultSize);
    const mountRef = useRef(false);

    const onResizeStop = useCallback(
        (_, __, ___, delta) => {
            setSize(({ width, height }) => {
                const newSize = {
                    width: width + delta.width,
                    height: height + delta.height,
                };

                onSizeChange(newSize);
                return newSize;
            });
        },
        [onSizeChange]
    );

    const { width: minWidth, height: minHeight } = minSize;
    const { width: maxWidth, height: maxHeight } = maxSize;

    const minSizePropWidth = minSizeProp?.width;
    const minSizePropHeight = minSizeProp?.height;
    useLayoutEffect(() => {
        if (!mountRef.current) {
            return;
        }

        setMinSize(prevMinSize => {
            setSize(({ width, height }) => {
                let newWidth = width;
                let newHeight = height;

                if (minSizePropWidth > prevMinSize.width || !prevMinSize.width) {
                    newWidth = Math.max(minSizePropWidth, width);
                }

                if (minSizePropWidth < prevMinSize.width) {
                    newWidth = Math.min(minSizePropWidth, width);
                }

                if (minSizePropHeight > prevMinSize.height || !prevMinSize.height) {
                    newHeight = Math.max(minSizePropHeight, height);
                }

                if (minSizePropHeight < prevMinSize.height) {
                    newHeight = Math.min(minSizePropHeight, height);
                }

                const newSize = { width: newWidth, height: newHeight };

                onSizeChange({ width: newWidth, height: newHeight });

                return newSize;
            });

            return { width: minSizePropWidth, height: minSizePropHeight };
        });
    }, [minSizePropWidth, minSizePropHeight, onSizeChange]);

    const maxSizePropWidth = maxSizeProp?.width;
    const maxSizePropHeight = maxSizeProp?.height;
    useLayoutEffect(() => {
        if (!mountRef.current) {
            mountRef.current = true;
            return;
        }

        setMaxSize(prevMaxSize => {
            setSize(({ width, height }) => {
                let newWidth = width;
                let newHeight = height;

                if (maxSizePropWidth < prevMaxSize.width) {
                    newWidth = Math.min(maxSizePropWidth, width);
                }

                if (maxSizePropHeight < prevMaxSize.height) {
                    newHeight = Math.min(maxSizePropHeight, height);
                }

                const newSize = { width: newWidth, height: newHeight };

                onSizeChange({ width: newWidth, height: newHeight });

                return newSize;
            });

            return { width: maxSizePropWidth, height: maxSizePropHeight };
        });
    }, [maxSizePropWidth, maxSizePropHeight, onSizeChange]);

    return (
        <ReResizable
            size={size}
            minWidth={minWidth}
            minHeight={minHeight}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            onResizeStop={onResizeStop}
            {...rest}
        >
            {children}
        </ReResizable>
    );
};
