import React, { useMemo } from 'react';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './contentItemCheckboxes.module.scss';

export const ContentItemCheckboxes = ({ config }) => {
    const [selectedValues, configMap] = useMemo(() => {
        const values = config.map(({ id, value, isPermitted }) => (isPermitted && value ? id : '')).filter(identity);
        const map = config.reduce((reduction, { id, onChange }) => {
            reduction[id] = onChange;
            return reduction;
        }, {});
        return [values, map];
    }, [config]);

    const checkboxHandlers = useMemo(
        () => ({
            onSelectCheckbox: key => configMap[key](true),
            onUnselectCheckbox: key => configMap[key](false),
        }),
        [configMap]
    );

    const checkboxes = useMemo(
        () =>
            config.map(({ id, isPermitted, defaultMessage }) =>
                isPermitted ? (
                    <TextCheckbox
                        key={id}
                        labelClassName={styles['label']}
                        value={id}
                        disabled={false}
                        title={
                            <FormattedMessage
                                id={`creative.creator.content.checkboxes.${id}.label`}
                                defaultMessage={defaultMessage}
                            />
                        }
                    />
                ) : null
            ),
        [config]
    );

    return (
        <CheckboxGroup className={styles['container']} selectedValues={selectedValues} {...checkboxHandlers}>
            {checkboxes}
        </CheckboxGroup>
    );
};

ContentItemCheckboxes.propTypes = {
    config: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.bool,
            isPermitted: PropTypes.bool,
            defaultMessage: PropTypes.string,
        })
    ),
};

export default ContentItemCheckboxes;
