import { isEmpty } from 'lodash';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations/services';
import { generateValidations } from '../ContextualSegments/audiencesValidationsGenerator';
import { MAX_SEGMENTS_TO_SELECT } from '../Tree';

export const excludeErrorListValidations = [
    {
        validationFn: validationFunctions.arrayLengthRange,
        options: { max: MAX_SEGMENTS_TO_SELECT },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.maxAmountOfSegments',
        defaultMessage: 'Targeting is limited to {limit} audiences',
    },
    {
        validationFn: (values, options, { allSegmentsIdMap = {} }) => {
            if (isEmpty(allSegmentsIdMap)) {
                return true;
            }

            return values.every(id => allSegmentsIdMap[id]?.isActive ?? true);
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.inactiveItemInList',
        defaultMessage: 'Some of the targeted audiences are not available for targeting. Please remove them to proceed',
    },
];

export const includeErrorListValidations = [...excludeErrorListValidations];

export const excludeWarningListValidations = [];

export const includeWarningListValidations = [
    ...generateValidations('customContextualSegmentsValuesLength', [
        'myAudienceIncludeValuesLength',
        'marketPlaceAudienceValuesLength',
    ]),
];

export const itemValidations = [
    {
        validationFn: ([, , isActive]) => {
            return isActive;
        },
        messageId: 'validations.error.campaign.targeting.marketplace.audiences.inactiveItem',
        defaultMessage: 'This audience is not available for targeting',
    },
];
