import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker/gtmTracker';
import { GTM_EVENTS } from '../../modules/taboola-common-frontend-modules/gtmTracker';
import { MAX_FETCH_ATTEMPTS_LIMIT } from './maxFetchAttemptsLimit';

const RETRY_TIMEOUT = 500;
const MESSAGES_TO_RETRY = ['failed to fetch', 'load failed', 'networkerror when attempting to fetch resource.'];
export const customFetch = async (...args) => {
    const url = args[0];
    let leftAttempts = MAX_FETCH_ATTEMPTS_LIMIT;

    do {
        const firstAttempt = leftAttempts === MAX_FETCH_ATTEMPTS_LIMIT;
        leftAttempts--;
        try {
            const caller = () => window.fetch(...args);
            const result = await (firstAttempt
                ? caller()
                : new Promise(resolve => setTimeout(() => resolve(caller()), RETRY_TIMEOUT)));
            return result;
        } catch (error) {
            if (MESSAGES_TO_RETRY.includes(error.message?.toLowerCase())) {
                if (leftAttempts > 0) {
                    console.info(`Failed to fetch (retry, left attempts - ${leftAttempts}):`, url);
                    continue;
                }
                console.error('Failed to fetch:', url);
                gtmTracker.trackEvent(GTM_EVENTS.USABILITY, { component: 'NetworkFailure', value: url });
            }
            throw error;
        }
    } while (leftAttempts > 0);
};
