'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.generateCloudinarySrc = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _lodash = require('lodash.keys');

var _lodash2 = _interopRequireDefault(_lodash);

var _lodash3 = require('lodash.isnil');

var _lodash4 = _interopRequireDefault(_lodash3);

var _lodash5 = require('lodash.omit');

var _lodash6 = _interopRequireDefault(_lodash5);

var _TaboolaImage = require('../Image/TaboolaImage');

var _TaboolaImage2 = _interopRequireDefault(_TaboolaImage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var SEPARATOR = '%2C';
var VAR_MAP = {
    format: 'f',
    quality: 'q',
    height: 'h',
    width: 'w',
    crop: 'c',
    gravity: 'g',
    aspectRatio: 'ar',
    xCoord: 'x',
    yCoord: 'y',
    background: 'b'
};

var DEFAULT_OPTIONS = {
    cloudinaryImagesUrlPrefix: 'https://images.taboola.com/taboola/image/fetch',
    width: 600,
    height: 400,
    sharpen: false,
    format: 'jpg',
    quality: 'auto',
    crop: 'fill'
};

var sanitizeCloudinaryImageOptions = function sanitizeCloudinaryImageOptions(options) {
    var newOptions = Object.assign({}, options);

    Object.keys(DEFAULT_OPTIONS).forEach(function (key) {
        if (newOptions[key] === undefined) {
            newOptions[key] = DEFAULT_OPTIONS[key];
        }
    });

    return newOptions;
};

var generateCloudinarySrc = exports.generateCloudinarySrc = function generateCloudinarySrc(_ref) {
    var src = _ref.src,
        options = _ref.options;

    var sanitizedOptions = sanitizeCloudinaryImageOptions(options);
    var cloudinaryImagesUrlPrefix = sanitizedOptions.cloudinaryImagesUrlPrefix,
        sharpen = sanitizedOptions.sharpen;


    var varNames = (0, _lodash2.default)(VAR_MAP);
    var cloudinaryVariables = varNames.map(function (key) {
        var prop = sanitizedOptions[key];
        return !(0, _lodash4.default)(prop) ? VAR_MAP[key] + '_' + prop : null;
    }).filter(function (item) {
        return item;
    });

    if (sharpen) {
        cloudinaryVariables.push('e_sharpen');
    }
    var cloudinaryVariablesStr = cloudinaryVariables.join(SEPARATOR);

    return cloudinaryImagesUrlPrefix + '/' + cloudinaryVariablesStr + '/' + src;
};

var CloudinaryImage = function CloudinaryImage(_ref2) {
    var src = _ref2.src,
        options = _objectWithoutProperties(_ref2, ['src']);

    var cloudinarySrc = generateCloudinarySrc({ src: src, options: options });
    var varNames = (0, _lodash2.default)(VAR_MAP);
    var restOfRest = (0, _lodash6.default)(options, [].concat(_toConsumableArray(varNames), ['cloudinaryImagesUrlPrefix', 'sharpen']));

    return _react2.default.createElement(_TaboolaImage2.default, Object.assign({
        src: cloudinarySrc
    }, restOfRest));
};

CloudinaryImage.propTypes = Object.assign({}, _TaboolaImage2.default.propTypes, {
    /** Cloudinary images url prefix */
    cloudinaryImagesUrlPrefix: _propTypes2.default.string,
    /** Class added to img element */
    className: _propTypes2.default.string,
    /** The image URL */
    src: _propTypes2.default.string.isRequired,
    /** alternative text description of the image */
    alt: _propTypes2.default.string,
    /** load event handler */
    onLoad: _propTypes2.default.func,
    /** error event handler */
    onError: _propTypes2.default.func,
    /** width of a transformed image */
    width: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /** height of a transformed image */
    height: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /** Apply a sharpening filter */
    sharpen: _propTypes2.default.bool,
    /** Decides which part of the image to keep while the crop mode is used */
    gravity: _propTypes2.default.string,
    /**  Format conversion to the given image format */
    format: _propTypes2.default.string,
    /**  Control the JPEG, WebP, GIF, JPEG XR and JPEG 2000 compression quality */
    quality: _propTypes2.default.string,
    /** determines how to transform the image for fitting into the desired width */
    crop: _propTypes2.default.string,
    /** y coordinate for cropping */
    xCoord: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /** y coordinate for cropping */
    yCoord: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.number]),
    /** if provided, calculates either width or height based on which dimension was provided */
    aspectRatio: _propTypes2.default.string,
    /** calculates background for padded images */
    background: _propTypes2.default.string
});

CloudinaryImage.defaultProps = {
    className: '',
    onLoad: function onLoad() {},
    onError: function onError() {}
};

exports.default = CloudinaryImage;