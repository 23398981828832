import React from 'react';
import PropTypes from 'prop-types';

class SimpleCellRenderer extends React.Component {
    refresh = () => false;

    render() {
        const { value, valueFormatted } = this.props;

        return <span>{valueFormatted || value}</span>;
    }
}

SimpleCellRenderer.propTypes = {
    value: PropTypes.any,
    valueFormatted: PropTypes.any,
};

export default SimpleCellRenderer;
