import React, { useEffect } from 'react';
import { FormField, Input, InputTypes } from 'taboola-ultimate-ui';
import { useEventValueHandler } from 'hooks';
import { withIndication } from 'modules/errors';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './titleFormField.module.scss';

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: 180 },
        messageId: 'video.creative.creator.content.brand.validation.error',
        defaultMessage: 'Title should not exceed 180 characters',
    },
];
export const TitleFormField = () => {
    const {
        value: title,
        onChange: setTitle,
        indicationData,
    } = useFormValidatedValue({
        field: 'title',
        validations,
    });

    const changeHandler = useEventValueHandler(setTitle);
    const { formatMessage } = useIntl();

    useEffect(() => {
        return () => setTitle('');
    }, [setTitle]);

    return (
        <FormField
            inputId="title"
            label={<FormattedMessage id="video.creative.creator.content.title.label" defaultMessage="Title" />}
            helpText={<FormattedMessage id="video.creative.creator.content.title.tooltip" defaultMessage="Title" />}
            containerClass={styles['input']}
        >
            <InputWithIndication
                id="title"
                placeholder={formatMessage({
                    id: 'video.creative.creator.content.title.placeholder',
                    defaultMessage: 'Type to add title...',
                })}
                type={InputTypes.TEXT}
                value={title}
                onChange={changeHandler}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="title"
                {...indicationData}
                size={Input.SIZE.LARGE}
            />
        </FormField>
    );
};
