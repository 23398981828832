"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgUp(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            fillRule: "evenodd",
            d: "M10.94 7.944A1.485 1.485 0 0 1 12 7.5c.401 0 .778.158 1.06.443l6.648 6.833c.39.395.39 1.033 0 1.428a.993.993 0 0 1-1.414 0L12 9.73l-6.294 6.476a.993.993 0 0 1-1.414 0 1.017 1.017 0 0 1 0-1.428l6.647-6.833z"
        })
    );
}
exports.default = SvgUp;