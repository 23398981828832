import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PermissionsContextProvider } from '../PermissionsContext';

const PermissionsProvider = ({ children, allUserPermissions }) => (
    <PermissionsContextProvider value={allUserPermissions}>{children}</PermissionsContextProvider>
);

PermissionsProvider.propTypes = {
    children: PropTypes.node,
    allUserPermissions: PropTypes.object,
};

const mapStateToProps = ({ authenticationReducer }) => {
    const allUserPermissions = get(authenticationReducer, 'user.permissions', {});

    return { allUserPermissions };
};

export { PermissionsProvider };
export default connect(mapStateToProps)(PermissionsProvider);
