import { generateCampaignCreatorPath, generateCampaignEditorPath } from 'modules/campaigns/services/utils';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { ENTITY, FORM_MODES } from '../../campaigns/config';

const recommendationActionToUrlMap = {
    CREATE_CAMPAIGN: () => generateCampaignCreatorPath({ entity: ENTITY.CAMPAIGN }),
    UPDATE_CAMPAIGN: (accountId, campaignId) =>
        generateCampaignEditorPath({
            accountId,
            campaignId,
            mode: FORM_MODES.EDIT,
            dimension: CAMPAIGN_DIMENSION.SPONSORED,
        }),
    DUPLICATE_CAMPAIGN: (accountId, campaignId) =>
        generateCampaignEditorPath({
            accountId,
            campaignId,
            mode: FORM_MODES.DUPLICATE,
            dimension: CAMPAIGN_DIMENSION.SPONSORED,
        }),
};

const getRecommendationUrl = (recommendationAction, accountId, campaignId) =>
    recommendationActionToUrlMap[recommendationAction](accountId, campaignId);

export default getRecommendationUrl;
