'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _Icons = require('../Icons/Icons');

var _StyledButton = require('../Buttons/StyledButton/StyledButton');

var _StyledButton2 = _interopRequireDefault(_StyledButton);

var _StyledButtonType = require('../Buttons/StyledButton/StyledButtonType');

var _StyledButtonType2 = _interopRequireDefault(_StyledButtonType);

var _StyledButtonSize = require('../Buttons/StyledButton/StyledButtonSize');

var _StyledButtonSize2 = _interopRequireDefault(_StyledButtonSize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'tag__taboola-svg-icon___1ZbVm',
    'container': 'tag__container___3G1Rk',
    'text': 'tag__text___2smvI',
    'close-icon': 'tag__close-icon___1Rj2N'
};


var classNameBuilder = _bind2.default.bind(styles);

var generateStopPropagationCallback = function generateStopPropagationCallback(callback) {
    return function (e) {
        e.stopPropagation();
        callback();
    };
};

var Tag = (0, _react.forwardRef)(function (_ref, ref) {
    var closeButton = _ref.closeButton,
        closeButtonProps = _ref.closeButtonProps,
        onClick = _ref.onClick,
        onRemove = _ref.onRemove,
        containerClassName = _ref.containerClassName,
        textClassName = _ref.textClassName,
        closeButtonClassName = _ref.closeButtonClassName,
        children = _ref.children;
    return _react2.default.createElement(
        'div',
        {
            ref: ref,
            className: classNameBuilder('container', containerClassName),
            role: 'button',
            tabIndex: 0,
            onClick: onClick
        },
        _react2.default.createElement(
            'div',
            { className: classNameBuilder('text', textClassName) },
            children
        ),
        closeButton || _react2.default.createElement(_StyledButton2.default, Object.assign({}, closeButtonProps, {
            className: classNameBuilder('close-icon', closeButtonClassName),
            iconBefore: _react2.default.createElement(_Icons.CloseIcon, { height: 16, width: 16 }),
            onClick: generateStopPropagationCallback(onRemove),
            type: _StyledButtonType2.default.BORDERLESS_ICON,
            size: _StyledButtonSize2.default.SMALL
        }))
    );
});

Tag.propTypes = {
    /** Click callback */
    onClick: _propTypes2.default.func,
    /** Remove callback */
    onRemove: _propTypes2.default.func,
    /** Child elements in Tag */
    children: _propTypes2.default.node,
    /** Close button in Tag */
    closeButton: _propTypes2.default.node,
    /** Class name overrides for CSS purposes */
    containerClassName: _propTypes2.default.string,
    textClassName: _propTypes2.default.string,
    closeButtonClassName: _propTypes2.default.string,
    closeButtonProps: _propTypes2.default.object
};

Tag.defaultProps = {
    onClick: function onClick() {},
    onRemove: function onRemove() {}
};

exports.default = Tag;