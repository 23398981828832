import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { isFunction } from 'lodash';
import { navigate } from 'actions';
import CampaignReports from 'modules/campaigns/modules/campaigns-reports';
import * as reportConfigIndex from 'modules/campaigns/modules/campaigns-reports/config/reports/reportConfigIndex';
import useReportConfigMap from 'modules/campaigns/modules/campaigns-reports/config/reports/useReportConfigMap';
import { MODULE_NAME } from 'modules/scheduled-reports/config/routes';
import { INDICATION_TYPES, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { ScheduledReportEditDrawer } from '../EditDrawer/ScheduledReportEditDrawer';
import { ScheduledReportsEmptyState } from '../EmptyState/ScheduledReportsEmptyState';
import { ScheduledReportsReportBreadcrumbs } from '../components/ScheduledReportsReportBreadcrumbs/ScheduledReportsReportBreadcrumbs';
import styles from './scheduledReports.module.scss';

export const ScheduledReportsPage = () => {
    const location = useLocation();
    const containerRef = useRef();
    const { state } = location;
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch(null);

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, match.path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history, match.path]
    );

    const reportConfigMap = useReportConfigMap(reportConfigIndex);

    return (
        <div className={styles['container']}>
            <div className={styles['scheduled-reports-page-container']} ref={containerRef}>
                <CampaignReports
                    skipFetchReport={state && state.skipFetchReport}
                    parentContainerRef={containerRef}
                    module={MODULE_NAME}
                    header={<ScheduledReportsReportBreadcrumbs />}
                    rangePickerPresetConfigs={{}}
                    buildReportNameForExport={{}}
                    reportConfigMap={reportConfigMap}
                    closeSideDrawer={handleNavigation}
                    EmptyStateComponent={ScheduledReportsEmptyState}
                    datePreset={null}
                />
                <ScheduledReportEditDrawer onCancel={handleNavigation} />
            </div>
        </div>
    );
};
