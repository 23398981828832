import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { BareDropdown, PaginatedDropdown } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useCampaignLevelConversions } from '../../ConversionOptimization/hook/useCampaignLevelConversions';
import { CampaignRuleItemRender } from './CampaignRuleItemRender';
import styles from './conversionRulesDropDown.module.scss';

export const ConversionRulesDropDown = ({ setHasOptions, disabled }) => {
    const { value: conversionRules, onChange: onChangeConversionRules } = useFormFieldValue({
        field: 'conversionRules.rules',
    });

    const { options, loadConversionRules, setCurrentOptions } = useCampaignLevelConversions();

    const { formatMessage } = useIntl();

    const addItem = useCallback(
        item => {
            const { id, displayName, status, avgCvr, conversions } = item;
            onChangeConversionRules(prev => [...prev, { id, displayName, status, avgCvr, conversions }]);
        },
        [onChangeConversionRules]
    );

    useEffect(() => {
        setHasOptions(prev => prev || !isEmpty(options));
    }, [options, setHasOptions]);

    return (
        <div className={styles['dropdown-container']}>
            <PaginatedDropdown
                id="rule-selector"
                onChange={addItem}
                searchable
                disabled={disabled}
                containerClassName={styles['dropdown']}
                value={conversionRules?.[0]?.id.toString() ?? null}
                enabledWhileSearching
                options={options}
                loadPage={loadConversionRules}
                onOptionsLoaded={setCurrentOptions}
                placeholder={formatMessage({
                    id: 'campaign.editor.conversion.goal.placeholder',
                    defaultMessage: 'Select Conversion...',
                })}
                DropdownMenuComponent={BareDropdown}
                optionItemRenderer={CampaignRuleItemRender}
                selectedValueObject={null}
            />
        </div>
    );
};
