import React from 'react';
import { batch } from 'react-redux';
import { creativesApi } from '../../../services/api';
import { INDICATION_TYPES, addIndication } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { requestDeleteCreative, deleteCreativeError, deleteCreativeSuccess } from '../actions';
import { removeRssItemsRows } from '../modules/campaigns-reports/actions';

const indications = {
    error: {
        message: <FormattedMessage id="rss.indication.delete.error" defaultMessage="Unable to delete RSS Feed." />,
        type: INDICATION_TYPES.ERROR,
        highlight: <FormattedMessage id="error.highlight" defaultMessage="Error!" />,
    },
    success: {
        message: (
            <FormattedMessage id="rss.indication.delete.success" defaultMessage="Rss Feed deleted successfully." />
        ),
        type: INDICATION_TYPES.SUCCESS,
        highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
    },
};

const deleteRssFeed =
    ({ accountId, campaignId, id }) =>
    async dispatch => {
        dispatch(requestDeleteCreative());
        try {
            const creativeItem = await creativesApi.deleteCreative(accountId, campaignId, id);
            batch(() => {
                dispatch(deleteCreativeSuccess(creativeItem));
                dispatch(addIndication(indications.success));
                dispatch(removeRssItemsRows(id));
            });
        } catch (error) {
            dispatch(deleteCreativeError(error));
            dispatch(addIndication(indications.error));
        }
    };

export default deleteRssFeed;
