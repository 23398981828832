import React, { useState } from 'react';
import classnames from 'classnames/bind';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton, DuplicateIcon, OkOIcon } from 'taboola-ultimate-ui';
import styles from './codeSnippet.scss';

const classNameBuilder = classnames.bind(styles);

export const CodeSnippet = ({ codeSnippetText, containerClass, codeSnippetTextClass }) => {
    const [copied, setCopied] = useState(false);
    if (!codeSnippetText) {
        return null;
    }

    const onCopy = () => {
        const copiedSuccess = copy(codeSnippetText);
        setCopied(copiedSuccess);
    };

    return (
        <div
            className={classNameBuilder('container', containerClass)}
            onMouseLeave={() => {
                setCopied(false);
            }}
        >
            <code className={classNameBuilder('code-snippet-text', codeSnippetTextClass)}>{codeSnippetText}</code>
            <div className={classNameBuilder('overlay')}>
                <StyledButton
                    onClick={onCopy}
                    iconBefore={copied ? <OkOIcon /> : <DuplicateIcon />}
                    size={STYLED_BUTTON_SIZE.MEDIUM}
                    type={STYLED_BUTTON_TYPE.GHOST}
                    className={classNameBuilder('copy-to-clipboard-button')}
                >
                    {copied ? 'SUCCESSFULLY COPIED' : 'COPY TO CLIPBOARD'}
                </StyledButton>
            </div>
        </div>
    );
};

CodeSnippet.propTypes = {
    /** code Snippet  text/component */
    codeSnippetText: PropTypes.node.isRequired,
    /** Class added to code snippet element */
    codeSnippetTextClass: PropTypes.string,
    /** Class added to the component container */
    containerClass: PropTypes.string,
};

CodeSnippet.defaultProps = {
    containerClass: '',
    codeSnippetTextClass: '',
};

export default CodeSnippet;
