import { isNil } from 'lodash';
import { isNetworkAccountType } from '../../../../../account-management';
import { FEATURE_FLAGS } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { AllCampaignsObject } from '../../../../config';

export const onlySelectedCampaignColumn = ({ selectedCampaign }) =>
    !isNil(selectedCampaign?.id) && selectedCampaign?.id !== AllCampaignsObject.id;

export const onlyNetworkColumn = ({ selectedAccount }) => isNetworkAccountType(selectedAccount?.type);

export const isPerformanceRulesActiveOnAccountLevel = selectedAccountConfig =>
    selectedAccountConfig?.[FEATURE_FLAGS.PERFORMANCE_RULES_REPORTS_ENABLED] === 'true';
