import React from 'react';
import { Button, AddIcon } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './itemSuggestionsElement.module.scss';

export const ItemSuggestionsElement = ({ suggestion, onSuggestionClick }) => {
    const { label } = suggestion;

    return (
        <Button
            onClick={() => onSuggestionClick(suggestion)}
            variant={Button.variant.ghost}
            className={styles['suggested-item']}
            data-metrics-component="clickKeywordSuggestion"
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={label}
        >
            <AddIcon className={styles['plus-icon']} />
            <div className={styles['text']}>{label}</div>
        </Button>
    );
};
