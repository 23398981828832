import React, { useMemo, useState } from 'react';
import { isEmpty, map, reduce, sumBy } from 'lodash';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';

export const CustomTooltip = ({ payload }) => {
    if (isEmpty(payload)) {
        return null;
    }

    const { events, noEventsReceived } = payload[0].payload;

    return <>{events ? events.toLocaleString() : noEventsReceived}</>;
};

const isOnOfTwoCellsInRowHasEvents = (graphData, currentCell, currentCellIndex) =>
    currentCell || (currentCellIndex > 0 && graphData[currentCellIndex - 1].events);

function checkIfDataHasEventsAfterNoEvents(arr) {
    let noEvents = false;
    return reduce(
        arr,
        (acc, { events, noEventsReceived }) => {
            if (noEventsReceived === 0) {
                noEvents = true;
            } else if (events && noEvents) {
                // eslint-disable-next-line
                acc = true;
            }
            return acc;
        },
        false
    );
}

const useEventsReceivedCellRendererGraphData = graphData => {
    const data = useMemo(
        () =>
            map(graphData, ({ date, events }, index) => ({
                date,
                events: isOnOfTwoCellsInRowHasEvents(graphData, events, index) ? events : undefined,
                noEventsReceived: !events ? 0 : undefined,
            })),
        [graphData]
    ); // Recharts doesn't allow to change Line's stroke in the middle of the graph, so we need to create two lines, and render one line with an itemValue > 0 and another one when itemValue equals to 0

    const totalEvents = useMemo(() => sumBy(graphData, 'events'), [graphData]);

    const secondLineStroke = totalEvents ? '#e43c54' : '#667686';

    return { data: checkIfDataHasEventsAfterNoEvents(data) ? graphData : data, secondLineStroke };
};

export const EventsReceivedCellRenderer = ({ value: graphData }) => {
    const [tooltipPosition, setTooltipPosition] = useState();

    const { data, secondLineStroke } = useEventsReceivedCellRendererGraphData(graphData);

    if (isEmpty(graphData)) {
        return EMPTY_FIELD_VALUE;
    }

    return (
        <ResponsiveContainer width="100%">
            <LineChart data={data} onMouseOver={setTooltipPosition}>
                <Tooltip content={<CustomTooltip />} position={tooltipPosition} />
                <Line dot={false} isAnimationActive={false} dataKey="events" stroke="#667686" />
                <Line dot={false} isAnimationActive={false} dataKey="noEventsReceived" stroke={secondLineStroke} />
            </LineChart>
        </ResponsiveContainer>
    );
};
