import { useMemo } from 'react';
import { isEmpty } from 'lodash';

export const useSettingValue = performanceRulesSettingsData => {
    const settingValue = useMemo(() => {
        const settingValue = {};
        (performanceRulesSettingsData?.results || []).forEach(field => {
            const { metric, conversionEvent, timeFrame, predicateGroupType, valueType } = field;
            if (!isEmpty(settingValue[metric])) {
                return;
            }
            settingValue[metric] = {
                conversionEvent,
                timeFrame,
                predicateGroupType,
                valueType,
            };
        });
        return settingValue;
    }, [performanceRulesSettingsData?.results]);

    return { settingValue };
};
