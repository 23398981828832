import { useCallback, useMemo, useRef } from 'react';
import { useUnmount, useMount } from 'react-use';
import { throttle } from 'lodash';
import { useEventListener } from 'taboola-ultimate-ui';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { useAppEventsApi } from 'services/api/appEventsApi/useAppEventsApi';
import { authTokenManager } from '../../authentication';

export const REMOTE_PUSH_TIMEOUT = 3000;
export const QUEUE_SIZE_THRESHOLD = 50;

export const useAppEventQueueRelease = (queue, register, throttleTimeout = REMOTE_PUSH_TIMEOUT) => {
    const releaseRef = useRef();
    const { sendEventList } = useAppEventsApi();
    const release = useCallback(async () => {
        if (releaseRef.current || !authTokenManager.getToken()) {
            return;
        }
        const length = queue.current.length;
        if (!length) {
            return;
        }

        releaseRef.current = true;
        const listToSend = queue.current.slice(0, length);

        try {
            await sendEventList(listToSend);
            queue.current = queue.current.slice(length);
            releaseRef.current = false;
        } catch (error) {
            gtmTracker.trackError('Failed to send custom events');
            releaseRef.current = false;
        }
    }, [sendEventList, queue]);

    const throttledRelease = useMemo(
        () => throttle(release, throttleTimeout, { leading: false }),
        [release, throttleTimeout]
    );

    useMount(
        register(() => {
            if (queue.current.length > QUEUE_SIZE_THRESHOLD) {
                release();
                return;
            }
            throttledRelease.cancel();
            throttledRelease();
        })
    );

    // On hiding tab we do instant release
    useEventListener(
        'visibilitychange',
        () => {
            if (document.hidden) {
                release();
            }
        },
        document
    );

    useUnmount(release);
};
