'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var TOOLTIP_TRIGGER = {
    HOVER: 'mouseenter focus',
    CLICK: 'click',
    MANUAL: 'manual'
};

exports.default = TOOLTIP_TRIGGER;