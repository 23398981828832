import { FETCH_CREATIVE_ERROR, FETCH_CREATIVE_SUCCESS } from './actionTypes';

export const fetchCreativeSuccess = creative => ({
    type: FETCH_CREATIVE_SUCCESS,
    payload: creative,
});

export const fetchCreativeError = error => ({
    type: FETCH_CREATIVE_ERROR,
    payload: error,
});
