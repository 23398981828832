import { accountByIdKey, additionalDataByNameKey } from './keys';

const accountsAPIPrefix = '/accounts';
const API_CACHE_KEY = 'accountsApiCache';
const API_CACHE_KEY_ALL = 'allAccountsApiCache';

export const accountsApiFactory = ({ callGetApi, callPostApi, callPutApi, queryClient }) => ({
    getAccountById: id => queryClient.fetchQuery(accountByIdKey(id), () => callGetApi(`${accountsAPIPrefix}/`, { id })),
    getAccountByName: name => callGetApi(`${accountsAPIPrefix}/`, { name }),
    getAdvertiser: name => callGetApi(`${accountsAPIPrefix}/advertiser`, { id: name }),
    updateAdvertiser: (name, account) => callPostApi(`${accountsAPIPrefix}/advertiser?name=${name}`, { ...account }),
    getCurrentRootAccount: () => callGetApi(`${accountsAPIPrefix}/current`),
    getAccountAdditionalDataByName: accountName =>
        callGetApi(`${accountsAPIPrefix}/account/additional-data`, { id: accountName }),
    getLegacyAccountAdditionalDataByName: accountName =>
        queryClient.fetchQuery(additionalDataByNameKey({ accountName }), () =>
            callGetApi(`${accountsAPIPrefix}/account/additional-data`, { id: accountName })
        ),
    getAccounts: (searchText, page, pageSize) =>
        callGetApi(`${accountsAPIPrefix}/base`, {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
            singleRequestCacheKey: API_CACHE_KEY,
        }),
    getAccountsUnderNetwork: (searchText, page, pageSize, selectedAccountId, dimension) =>
        callGetApi(`${accountsAPIPrefix}/base_under_network`, {
            paginationEnabled: true,
            page,
            pageSize,
            searchText,
            id: selectedAccountId,
            dimension,
            singleRequestCacheKey: API_CACHE_KEY,
        }),
    getAllAccountsUnderNetwork: (selectedAccountId, dimension) =>
        callGetApi(`${accountsAPIPrefix}/base_under_network`, {
            id: selectedAccountId,
            dimension,
            singleRequestCacheKey: API_CACHE_KEY_ALL,
        }),
    isDefaultPlatformTaboolaAds: () => callGetApi(`${accountsAPIPrefix}/default_platform`),
    updateDefaultPlatform: isDefaultPlatformTaboolaAds =>
        callPutApi(
            `${accountsAPIPrefix}/default_platform?default_platform_taboola_ads=${isDefaultPlatformTaboolaAds}`,
            {},
            true
        ),
});

export { API_CACHE_KEY };
