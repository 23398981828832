import { isAdminNetworkAccountType } from '../../../../../../account-management';
import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { FILTER_TYPE } from '../../filters';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { defaultColumns, customColumns } from './reportPresets';

export const countryReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.COUNTRY],
    endpoint: 'campaign-report-by-country-performance',
    graph,
    rowIdField: FIELDS.COUNTRY_CODE.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.byCountry.search.placeholder',

    // TODO: DEV-58584 need to convert filter names in the GW. replace searchField + remove sortMap
    searchField: 'countryName',
    sortMap: {
        [FIELDS.COUNTRY_CODE.field]: 'countryName',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
    filters: [
        {
            id: FILTER_TYPE.SITE,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.BROWSER,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.PLATFORM,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.OS_FAMILY,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.OS_VERSION,
            accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true' },
            visibilityCondition: ({ selectedAccount: { type } }) => !isAdminNetworkAccountType(type),
        },
        {
            id: FILTER_TYPE.CONTENT_SAFETY,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CONTENT_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
        },
        {
            id: FILTER_TYPE.CATEGORY_TYPE,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS'],
            accountConfigurations: { [FEATURE_FLAGS.SHOW_LEGACY_CATEGORY_FILTER]: 'true' },
        },
        {
            id: FILTER_TYPE.ACCOUNT_MANAGER,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_PERSON,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
        {
            id: FILTER_TYPE.SALES_GROUP,
            permissions: ['ADS_CONSOLE_ACCOUNTS_FILTERS', 'INTERNAL_FILTERS'],
        },
    ],
};

export default countryReportConfig;
