import { ACCOUNT_ID } from 'hooks';
import { CAMPAIGN_ID } from 'modules/campaigns/hooks/useSelectedCampaignId';
import { REPORT_ID } from 'modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { navigate } from '../../../actions';
import { selectedAccountSelector } from '../../../selectors';
import { CAMPAIGNS_ROUTE, REPORT_TYPE } from '../../campaigns/config';

const lowResolutionHandler =
    ({ history, recommendationMetadata }) =>
    (dispatch, getState) => {
        const { id } = selectedAccountSelector(getState());
        const { campaignId } = recommendationMetadata;
        const reportId = REPORT_TYPE.CREATIVE;
        const destinationPath = `${CAMPAIGNS_ROUTE}?${ACCOUNT_ID}=${id}&${CAMPAIGN_ID}=${campaignId}&${REPORT_ID}=${reportId}`;

        dispatch(navigate(history, destinationPath));
    };

export default lowResolutionHandler;
