"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgInvoice(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 14,
            height: 18,
            viewBox: "0 0 14 18",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M5 2H2C0.89543 2 0 2.89543 0 4V16C0 17.1046 0.89543 18 2 18H12C13.1046 18 14 17.1046 14 16V4C14 2.82266 13.1866 2 12 2H9V4H12V16H2V4H5V2Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M3 2C3 0.895431 3.89543 0 5 0H9C10.1046 0 11 0.89543 11 2V4C11 5.10457 10.1046 6 9 6H5C3.89543 6 3 5.10457 3 4V2ZM5 2V4H9V2H5Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M4 9C4 8.44772 4.44772 8 5 8H9C9.55228 8 10 8.44772 10 9C10 9.55228 9.55228 10 9 10H5C4.44772 10 4 9.55228 4 9Z",
            fill: "currentColor"
        }),
        React.createElement("path", {
            d: "M4 13C4 12.4477 4.44772 12 5 12H7C7.55228 12 8 12.4477 8 13C8 13.5523 7.55228 14 7 14H5C4.44772 14 4 13.5523 4 13Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgInvoice;