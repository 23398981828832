import { forEach } from 'lodash';

export const setSearchParams = (url, params = {}) => {
    const parsedUrl = new URL(url);
    const { searchParams } = parsedUrl;

    forEach(params, (value, key) => searchParams.set(key, value));

    return parsedUrl.toString();
};
