import { useMemo } from 'react';
import { isLowTouchAccount, isPartnerAccountType } from 'modules/account-management';
import {
    FEATURE_FLAGS,
    useAccount,
    useConfigMatch,
} from '../../../modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from '../../../modules/taboola-common-frontend-modules/authentication';

export const useShowNotificationsCenter = () => {
    const isNotificationsCenterEnabled = useConfigMatch({ [FEATURE_FLAGS.NOTIFICATIONS_CENTER_ENABLED]: 'true' });
    const hasNotificationsCenterViewPermissions = usePermissions('NOTIFICATIONS_CENTER_VIEW');
    const { tier, type } = useAccount();
    const isLowTouch = isLowTouchAccount(tier);
    const isPartner = isPartnerAccountType(type);
    const isOnlyLowTouchConfigActivated = useConfigMatch({
        [FEATURE_FLAGS.NOTIFICATIONS_CENTER_ONLY_FOR_LOW_TOUCH]: 'true',
    });
    const showNotificationsCenter = useMemo(() => {
        return Boolean(
            isPartner &&
                hasNotificationsCenterViewPermissions &&
                isNotificationsCenterEnabled &&
                (!isOnlyLowTouchConfigActivated || isLowTouch)
        );
    }, [
        hasNotificationsCenterViewPermissions,
        isLowTouch,
        isNotificationsCenterEnabled,
        isOnlyLowTouchConfigActivated,
        isPartner,
    ]);

    return { showNotificationsCenter };
};
