import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import { EllipsisCellRenderer } from '../../../components/CellRenderers';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import {
    csvDateHeaderValueGetter,
    formattedNumberValueGetter,
    gridNumberFormatter,
} from '../../../components/ValueFormatters';
import createCSVValueGetter from '../../../components/ValueFormatters/createCSVValueGetter';
import createGridValueFormatter from '../../../components/ValueFormatters/createGridValueFormatter';
import dateWithLastUpdateTimeFormatter from '../../../components/ValueFormatters/dateWithLastUpdateTimeFormatter';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import { keyColumn, leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const dayReportColumnDefinition = [
    {
        headerName: FIELDS.DATE.label,
        headerValueGetter: FIELDS.DATE.headerValueGetter,
        headerComponentParams: FIELDS.DATE.headerComponentParams,
        field: FIELDS.DATE.field,
        type: [leftPinnedColumn, keyColumn],
        valueFormatter: createGridValueFormatter(dateWithLastUpdateTimeFormatter),
        maxWidth: 180,
        csvValueGetter: createCSVValueGetter(dateWithLastUpdateTimeFormatter),
        cellRendererFramework: EllipsisCellRenderer,
        cellRendererParams: { lines: 2 },
        csvHeaderValueGetter: csvDateHeaderValueGetter,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CPA,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
    ].map(
        ({
            label,
            headerValueGetter,
            headerComponentParams,
            field,
            renderParams,
            csvValueGetter,
            referenceColId,
            permissions,
            visibilityCondition,
        }) => ({
            headerName: label,
            headerValueGetter: headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams,
            field,
            referenceColId,
            type: [numericColumn],
            valueFormatter: gridNumberFormatter,
            cellRendererParams: renderParams,
            csvValueGetter: csvValueGetter || formattedNumberValueGetter,
            permissions,
            visibilityCondition,
        })
    ),
];

export default normalizeColumnDefs(dayReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.DAY_VIDEO].id);
