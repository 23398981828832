import React from 'react';
import PropTypes from 'prop-types';
import { MARKETING_OBJECTIVE_KEYS } from 'modules/campaigns/modules/common-campaign-form/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const MARKETING_OBJECTIVE_KEYS_ARRAY = Object.values(MARKETING_OBJECTIVE_KEYS);

const emptyDescription = <div />;
const descriptionMap = {
    [MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL]: (
        <FormattedMessage id={`campaign.targeting.platform.${MARKETING_OBJECTIVE_KEYS.MOBILE_APP_INSTALL}.subtext`} />
    ),
};

export const PlatformTargetingDescription = ({ selectedMarketingObjective }) => {
    return descriptionMap[selectedMarketingObjective] || emptyDescription;
};

PlatformTargetingDescription.propTypes = {
    selected: PropTypes.oneOf(MARKETING_OBJECTIVE_KEYS_ARRAY),
};

export default PlatformTargetingDescription;
