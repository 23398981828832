"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDelete(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M8 10l.878 8h6.336l.878-8H8zm6-3V6h-4v1H5a1 1 0 1 1 0-2h2.937l.875-1h6.375l.875 1H19a1 1 0 0 1 0 2h-5zM8.866 20c-.886 0-1.632-.751-1.742-1.752L6 8h12.358l-1.124 10.248c-.11 1-.856 1.752-1.741 1.752H8.866z"
            })
        )
    );
}
exports.default = SvgDelete;