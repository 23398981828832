import {
    FEATURE_FLAGS,
    useAccountConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';

export const useShouldRenderBidStrategyV2 = () => {
    const {
        [FEATURE_FLAGS.BID_STRATEGY_V2_ENABLED]: isBidStrategyV2Enabled,
        [FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]: isMaxConversionsEnabled,
    } = useAccountConfig([FEATURE_FLAGS.BID_STRATEGY_V2_ENABLED, FEATURE_FLAGS.MAX_CONVERSIONS_ENABLED]);

    return isBidStrategyV2Enabled === 'true' || isMaxConversionsEnabled === 'true';
};
