import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../../../components/Form';
import { useCampaignGroupExtensionEnabled } from '../../hooks/useCampaignGroupExtensionEnabled';
import styles from './campaignsGroupCreatorFormButtons.module.scss';

export const CampaignsGroupCreatorFormButtons = ({ onCancel, onSubmit, onSubmitNavigateCampaign }) => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    return (
        <div className={styles['buttons-wrapper']}>
            <FormButtons
                className={styles['form-buttons']}
                onSubmit={onSubmit}
                disableSubmit={false}
                submitLabel={
                    <FormattedMessage id="campaigns.group.creator.submit.button" defaultMessage="CREATE GROUP" />
                }
                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" defaultMessage="Cancel" />}
                onCancel={onCancel}
                submitButtonType="submit"
            />
            {campaignGroupExtensionEnabled && (
                <Button
                    onClick={onSubmitNavigateCampaign}
                    size={Button.variant.md}
                    variant={Button.variant.primary}
                    className={styles['last-button']}
                >
                    <FormattedMessage
                        id="campaigns.group.creator.submit.and.add.campaign.button"
                        defaultMessage="CREATE & ADD CAMPAIGN"
                    />
                </Button>
            )}
        </div>
    );
};

CampaignsGroupCreatorFormButtons.propTypes = {
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};
