import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const AttributionGroupTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="pmpDeal.attribution.group.help"
            defaultMessage="Use this label as a way to identify a specific advertiser when a single seat represents multiple buyers."
        />
    </TooltipSection>
);

export default AttributionGroupTooltip;
