import { useRef, useState, useEffect, useCallback } from 'react';
import { useMountedState } from 'react-use';
import { concat } from 'lodash';
import { useCreativesApi } from 'services/api';

const emptyCreativeIds = [];

const useMatchingCreativeIds = (
    accountId,
    campaignId,
    creativeId,
    thumbnailUrl,
    applyFocusToMatches,
    includeSelfForSave = false
) => {
    const thumbnailUrlRef = useRef(null);
    const [matchingCreativeIds, setMatchingCreativeIds] = useState(emptyCreativeIds);
    const [matchingCreativeIdsToSave, setMatchingCreativeIdsToSave] = useState(emptyCreativeIds);

    const isMounted = useMountedState();
    const creativesApi = useCreativesApi();
    const updateMatchingCreativeIds = useCallback(async () => {
        try {
            const { matchingCreativeIds } = await creativesApi.getMatchingThumbnailCreatives(
                accountId,
                campaignId,
                creativeId,
                thumbnailUrl
            );
            if (!isMounted()) {
                return;
            }
            setMatchingCreativeIds(matchingCreativeIds);
        } catch {}
    }, [creativesApi, accountId, campaignId, creativeId, thumbnailUrl, isMounted]);

    // If the thumbnailUrl has changed, immediately set matching creatives to empty.
    // Then, if thumbnailUrl is truthy, attempt to fetch.
    useEffect(() => {
        if (thumbnailUrl !== thumbnailUrlRef.current) {
            thumbnailUrlRef.current = thumbnailUrl;
            setMatchingCreativeIds(emptyCreativeIds);

            if (thumbnailUrl) {
                updateMatchingCreativeIds();
            }
        }
    }, [thumbnailUrl, matchingCreativeIds, setMatchingCreativeIds, updateMatchingCreativeIds]);

    useEffect(() => {
        if (applyFocusToMatches && includeSelfForSave) {
            setMatchingCreativeIdsToSave(concat(matchingCreativeIds, parseInt(creativeId)));
        } else if (applyFocusToMatches) {
            setMatchingCreativeIdsToSave(matchingCreativeIds);
        }
    }, [applyFocusToMatches, creativeId, includeSelfForSave, matchingCreativeIds]);

    return {
        matchingCreativeIds,
        matchingCreativeIdsToSave,
    };
};

export default useMatchingCreativeIds;
