import { CardNumberElement } from '@stripe/react-stripe-js';
import React from 'react';
import classnames from 'classnames/bind';
import { CardIcon, INDICATION_TYPES } from 'taboola-ultimate-ui';
import { STRIPE_ELEMENT_DEFAULT_OPTIONS } from 'modules/taboola-common-frontend-modules/stripe';
import { COMMON_FLAGS } from '../../../../../taboola-common-frontend-modules/account-configurations';
import useConfigMatch from '../../../../../taboola-common-frontend-modules/account-configurations/hooks/useConfigMatch';
import commonStyles from '../../common.module.scss';
import styles from './stripeCardNumber.module.scss';

const classNameBuilder = classnames.bind(styles);
const commonClassNameBuilder = classnames.bind(commonStyles);

export const StripeCardNumber = ({ indicationType, ...stripeProps }) => {
    const hasError = indicationType === INDICATION_TYPES.ERROR;
    const allowCartesBancaires = useConfigMatch({}, { [COMMON_FLAGS.ALLOW_CARTES_BANCAIRES]: 'true' });
    const cardNumberElementOptions = { ...STRIPE_ELEMENT_DEFAULT_OPTIONS, showIcon: allowCartesBancaires };

    return (
        <div
            className={commonClassNameBuilder(commonStyles['container'], {
                error: hasError,
            })}
        >
            {!allowCartesBancaires && <CardIcon className={styles['card-icon']} />}
            <div
                className={classNameBuilder(styles['divider'], {
                    error: hasError,
                })}
            />
            <CardNumberElement
                className={commonStyles['element']}
                {...stripeProps}
                options={cardNumberElementOptions}
            />
        </div>
    );
};
