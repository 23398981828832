import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'modules/taboola-common-frontend-modules/forms';
import { useRecommendationsApi } from 'services/api/recommendationsApi';
import { generateErrorIndication } from 'services/utils';
import { addIndication } from '../../../../taboola-common-frontend-modules/Indications';
import { applyRecommendationSuccess } from '../../../actions/applyRecommendationSuccess';
import { dismissRecommendationSuccess } from '../../../actions/dismissRecommendationSuccess';
import { useRecommendationService } from './useRecommendationService';

export const usePerformanceRecommendationExecution = ({
    recommendation,
    markAsApplied = true,
    errorCode = 'performance-recommendations.general-recommendation.error-message',
    applyParams,
    dismissParams,
    onApply: onApplyCallback,
    onApplySuccess: onApplySuccessCallback,
    onApplyError: onApplyErrorCallback,
    onDismiss: onDismissCallback,
    onDismissSuccess: onDismissSuccessCallback,
    onDismissError: onDismissErrorCallback,
}) => {
    const dispatch = useDispatch();
    const recommendationsApi = useRecommendationsApi();
    const { applyRecommendation, dismissRecommendation } = useRecommendationService();
    const { submit } = useFormContext();
    const onApply = () => {
        if (onApplyCallback) {
            return onApplyCallback(applyParams);
        }
        if (applyParams?.actionParams) {
            return submit(() => recommendationsApi.executeCampaignRecommendationAction(applyParams));
        }
        return recommendationsApi.executeCampaignRecommendationAction(applyParams);
    };

    const onApplySuccess = data => {
        if (markAsApplied) {
            applyRecommendation(recommendation.id);
        }
        if (onApplySuccessCallback) {
            return onApplySuccessCallback(data, recommendation.type);
        }
        dispatch(applyRecommendationSuccess(data));
    };

    const onApplyError = () => {
        if (onApplyErrorCallback) {
            return onApplyErrorCallback(applyParams);
        }
        dispatch(addIndication(generateErrorIndication({ messageCode: errorCode })));
    };

    const onDismiss = () => {
        if (onDismissCallback) {
            return onDismissCallback(dismissParams);
        }
        return recommendationsApi.dismissCampaignsPerformanceRecommendation(dismissParams);
    };

    const onDismissSuccess = data => {
        if (markAsApplied) {
            dismissRecommendation(recommendation.id);
        }
        if (onDismissSuccessCallback) {
            return onDismissSuccessCallback(data);
        }
        dispatch(dismissRecommendationSuccess(data));
    };

    const onDismissError = params => {
        if (onDismissErrorCallback) {
            return onDismissErrorCallback(params);
        }
        dispatch(addIndication(generateErrorIndication({ messageCode: errorCode })));
    };

    const { mutate: invokeApply, ...applyState } = useMutation(onApply, {
        onSuccess: onApplySuccess,
        onError: onApplyError,
    });

    const { mutate: invokeDismiss, ...dismissState } = useMutation(onDismiss, {
        onSuccess: onDismissSuccess,
        onError: onDismissError,
    });

    const apply = {
        ...applyState,
        invoke: invokeApply,
    };

    // marking past applied recommendations as successfully applied
    if (recommendation.usedTimeUtc) {
        apply.isSuccess = true;
    }

    const dismiss = {
        ...dismissState,
        invoke: invokeDismiss,
    };

    return { apply, dismiss };
};

export default usePerformanceRecommendationExecution;
