import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { FORM_MODES } from 'config/formModes';
import { CAMPAIGN_CREATOR_ROUTE_PATH, CAMPAIGNS_ROUTE } from 'modules/campaigns/config';
import { OpenExchangeCampaignCreator } from 'modules/campaigns/modules/open-exchange-campaign-creator';
import { PmpDealCreator } from 'modules/campaigns/modules/pmp-deal-creator';
import { SponsoredCampaignCreator } from 'modules/campaigns/modules/sponsored-campaign-creator';
import { replacePathParams } from 'modules/campaigns/services/utils';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ENTITY } from '../config';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { ExcelBulkForm } from '../modules/excel-bulk-operations/ExcelBulkForm';
import { VideoCampaignCreator } from '../modules/video-campaigns-form/forms/video-campaign-creator';
import { VideoPMPDealCreator } from '../modules/video-campaigns-form/forms/video-pmp-deal-creator/VideoPMPDealCreator';

const path = CAMPAIGN_CREATOR_ROUTE_PATH;
export const CampaignCreatorPage = ({ onCancel }) => {
    const sponsoredPath = replacePathParams(path, { entity: [ENTITY.CAMPAIGN], mode: [FORM_MODES.CREATE] });
    const videoPath = replacePathParams(path, { entity: [ENTITY.CAMPAIGN_VIDEO] });
    const openExchangePath = replacePathParams(path, { entity: [ENTITY.RTB_OPEN_EXCHANGE] });
    const pmpDealPath = replacePathParams(path, { entity: [ENTITY.PMP_DEAL] });
    const videoPmpDealPath = replacePathParams(path, { entity: [ENTITY.VIDEO_PMP_DEAL] });
    const bulkSponsoredPath = replacePathParams(path, { entity: [ENTITY.EXCEL_BULK], mode: [FORM_MODES.BULK_CREATE] });
    useUnsavedChangesController(CAMPAIGN_CREATOR_ROUTE_PATH);

    const isCampaignBulkUploadFFEnabled = useConfigMatch({ [FEATURE_FLAGS.CAMPAIGN_BULK_UPLOAD]: 'true' });

    return (
        <Switch>
            <LayerRoute
                strict
                path={sponsoredPath}
                render={props => <SponsoredCampaignCreator {...props} onCancel={onCancel} />}
            />
            <LayerRoute
                strict
                path={openExchangePath}
                render={props => <OpenExchangeCampaignCreator {...props} onCancel={onCancel} />}
            />
            <LayerRoute strict path={pmpDealPath} render={props => <PmpDealCreator {...props} onCancel={onCancel} />} />
            <LayerRoute
                strict
                path={videoPath}
                render={props => <VideoCampaignCreator {...props} onCancel={onCancel} />}
            />
            <LayerRoute
                strict
                path={videoPmpDealPath}
                render={props => <VideoPMPDealCreator {...props} onCancel={onCancel} />}
            />
            {isCampaignBulkUploadFFEnabled && (
                <LayerRoute
                    strict
                    path={bulkSponsoredPath}
                    render={props => <ExcelBulkForm {...props} onCancel={onCancel} />}
                />
            )}
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CampaignCreatorPage.propTypes = {
    onCancel: PropTypes.func,
};

export default CampaignCreatorPage;
