import React, { useCallback } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { useDropdownValue, useDropdownOptions } from 'hooks';
import { withIndication } from 'modules/errors/components/withIndication';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { DropdownMenu } from '../../../../../../components';
import { PremiumSiteTooltip } from './PremiumSiteTooltip';
import styles from '../commonEditor.module.scss';

const DropdownMenuWithIndications = withIndication(DropdownMenu);

export const premiumSiteOptions = [
    { value: 'ALL', label: 'All' },
    { value: 'PREMIUM', label: 'Premium' },
    { value: 'REGULAR', label: 'Regular' },
];

const premiumSiteValues = premiumSiteOptions.map(({ value }) => value);

const dropdownValidations = [
    {
        validationFn: validationFunctions.isIn,
        options: { list: premiumSiteValues },
        messageId: 'validations.error.campaign.premium.site.empty',
        defaultMessage: 'Select a Profile',
    },
];
const emptyValidations = [];
const MSG_ID_PREFIX = 'campaign.editor.premium.site';
const PremiumSite = ({ permissions = 'QUALITY_TARGETING_EDIT_TA' }) => {
    const isPermitted = usePermissions(permissions);
    const { value, onChange, indicationData } = useFormValidatedValue({
        field: 'premiumSiteTargeting',
        validations: isPermitted ? dropdownValidations : emptyValidations,
        isPermitted,
    });
    const changeHandler = useCallback(
        ({ value }) => {
            onChange(value);
        },
        [onChange]
    );
    const { isMessageIdExists } = useIntl();
    const msgIdPrefix = isMessageIdExists(MSG_ID_PREFIX + value) ? MSG_ID_PREFIX : null;

    const selectedValue = useDropdownValue({ value }, msgIdPrefix);
    const options = useDropdownOptions(premiumSiteOptions, msgIdPrefix);

    if (!isPermitted) {
        return null;
    }

    return (
        <FormField
            inputId="premium-site"
            label={<FormattedMessage id="campaign.editor.premium.site.title" defaultMessage="Premium Site" />}
            description={
                <FormattedMessage
                    id="campaign.editor.premium.site.description"
                    defaultMessage="Visible to internal users only."
                />
            }
            helpText={<PremiumSiteTooltip />}
            placeholder={
                <FormattedMessage
                    id="campaign.editor.premium.site.placeholder"
                    defaultMessage="Select site profile..."
                />
            }
            containerClass={styles['input']}
        >
            <DropdownMenuWithIndications
                id="premium-site"
                placeholder={
                    <FormattedMessage
                        id="campaign.editor.premium.site.placeholder"
                        defaultMessage="Select site profile..."
                    />
                }
                options={options}
                selectedValueObject={selectedValue}
                onChange={changeHandler}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'PremiumSiteTargeting',
                }}
                {...indicationData}
            />
        </FormField>
    );
};

// For general usage
export default PremiumSite;
