import { flow, values } from 'lodash';
import * as fromGWTransformers from './fromGWindex';
import * as fromGWVideoTransformers from './fromGWindexVideo';
import * as toGWTransformers from './toGWindex';
import * as toGWVideoTransformers from './toGWindexVideo';

export const transformFromGW = flow(values(fromGWTransformers));
export const transformToGW = flow(values(toGWTransformers));

export const transformVideoFromGw = flow(values(fromGWVideoTransformers));
export const transformVideoToGW = flow(values(toGWVideoTransformers));
