import React, { useCallback } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useDropdownOptions } from 'hooks';
import { withIndication } from 'modules/errors/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FORM_MODES } from '../../../../../../config/formModes';
import styles from './dimensionDropdown.module.scss';

const FormFieldWithIndication = withIndication(FormField);
export const CONDITION_DIMENSIONS = { SITE_PER_CAMPAIGN: 'SITE_PER_CAMPAIGN', AD: 'AD' };

const dimensionOptions = [
    { value: CONDITION_DIMENSIONS.SITE_PER_CAMPAIGN, label: 'SITES' },
    { value: CONDITION_DIMENSIONS.AD, label: 'ADS' },
];
const msgIdPrefix = 'performance.rule.create.settings.dimension';
const formFieldId = 'dimension-selector';
export const DimensionDropdown = ({ setDimension }) => {
    const { value: dimension, mode } = useFormFieldValue({ field: 'dimension' });
    const disabled = mode === FORM_MODES.EDIT;
    const options = useDropdownOptions(dimensionOptions, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: dimension }) => {
            setDimension(dimension);
        },
        [setDimension]
    );
    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['dimension-container']}
            label={
                <FormattedMessage id="app.create.performance.rules.setup.dimension.label" defaultMessage="Dimension" />
            }
            labelClass={styles['label']}
        >
            <div className={styles['dimension']}>
                <span className={styles['dimension-icon']}>
                    <FormattedMessage id="app.create.performance.rules.for.condition" />
                </span>
                <DropdownMenu
                    required
                    id={formFieldId}
                    selectedValueObject={{
                        value: dimension,
                    }}
                    options={options}
                    onChange={changeHandler}
                    searchable
                    aria-label="dimension-dropdown"
                    disabled={disabled}
                />
            </div>
        </FormFieldWithIndication>
    );
};
