import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION, HelpTooltip } from 'taboola-ultimate-ui';
import { AUDIENCE_TYPE } from 'modules/audiences/config/audiencesConsts';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './audiencePixelBasedDetailsCellRenderer.module.scss';
import helpTooltipStyles from './helpTooltip.module.scss';

const AudiencePixelBasedDetailsCellRenderer = ({ value, data }) => {
    const { type, category, eventName } = data;
    const { formatMessage } = useIntl();

    let cell = '-';
    let tooltip;
    if (category && category.includes('BASIC_ENGAGEMENT')) {
        cell = formatMessage({
            id: 'app.campaigns.reports.grid.audience.engagement.basic',
            defaultMessage: 'Browsing users have clicked on your ad and briefly visited your landing page.',
        });
        tooltip = formatMessage({
            id: 'app.campaigns.reports.grid.audience.engagement.basic.tooltip',
            defaultMessage:
                'Browsing users are people who have previously interacted with your site for at least 4 seconds',
        });
    } else if (category && category.includes('HIGH_ENGAGEMENT')) {
        cell = formatMessage({
            id: 'app.campaigns.reports.grid.audience.engagement.high',
            defaultMessage:
                'Attentive users have clicked on your ad and spent a significant amount of time on your website based on time-spent, session-depth and scroll length metrics.',
        });
        tooltip = formatMessage({
            id: 'app.campaigns.reports.grid.audience.engagement.high.tooltip',
            defaultMessage:
                'Attentive users are people who have showed significant interaction with your content based on signals like time on site, scroll depth and more. Taboola predicts that these users have the highest probability to convert.',
        });
    } else if (type === AUDIENCE_TYPE.EVENT_BASED) {
        cell = formatMessage(
            {
                id: 'app.campaigns.reports.grid.audience.conditions.event',
                defaultMessage: 'Event: {eventName}',
            },
            { eventName }
        );
    } else if (type === AUDIENCE_TYPE.BASIC && value) {
        const conditions = JSON.parse(value);

        cell = conditions
            .map(condition => {
                return formatMessage(
                    {
                        id: `app.campaigns.reports.grid.audience.conditions.prefix.${condition.conditionType.toLowerCase()}`,
                        defaultMessage: `${condition.conditionType}: {url}`,
                    },
                    { url: condition.url }
                );
            })
            .join(
                formatMessage({
                    id: 'app.campaigns.reports.grid.audience.conditions.or',
                    defaultMessage: ' OR ',
                })
            );
    }

    return (
        <div>
            <div className={styles['container']}>
                <EllipsisCellRenderer value={cell} lines={2} />
                {tooltip && (
                    <HelpTooltip
                        iconClassName={helpTooltipStyles['help-icon']}
                        position={TOOLTIP_POSITION.RIGHT}
                        className={helpTooltipStyles['help-tooltip']}
                    >
                        {tooltip}
                    </HelpTooltip>
                )}
            </div>
        </div>
    );
};

AudiencePixelBasedDetailsCellRenderer.propTypes = {
    data: PropTypes.object,
    ...EllipsisCellRenderer.propTypes,
};

export default AudiencePixelBasedDetailsCellRenderer;
