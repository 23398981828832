import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useMultiLayerDrawerContext } from '../../modules/multi-layer-drawer/components/MultiLayerDrawerProvider/MultiLayerDrawerProvider';
import { useModuleName } from '../useModuleName';

export const useModulePage = routes => {
    const { modulePages } = useMultiLayerDrawerContext();
    const module = useModuleName();
    const { pathname } = useLocation();

    const { modulePage, route, path } = useMemo(
        () => ({ modulePage: modulePages[`/${module}`]?.component, route: routes[module], path: pathname }),
        [module, pathname, routes, modulePages]
    );

    return { modulePage, route, path };
};
