import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Radio, RadioGroup } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { RUNTIME_TYPES } from '../../config';
import SchedulerDayTimeToolTip from '../CampaignScheduler/SchedulerDayTimeToolTip';
import RuntimePickerTooltip from './RuntimePickerTooltip';

const RuntimePicker = ({ selection, onChange, runtimeCustomDescription, isDayPartingPermitted }) => (
    <FormField
        inputId="runtime-picker"
        label={<FormattedMessage id="campaign.editor.schedule.runtime.title" defaultMessage="Days and Hours" />}
        helpText={isDayPartingPermitted ? <SchedulerDayTimeToolTip /> : <RuntimePickerTooltip />}
    >
        <RadioGroup
            name="runtime-picker"
            onChange={onChange}
            selectedValue={selection}
            data-metrics-component="RuntimePicker"
        >
            <Radio
                value={RUNTIME_TYPES.ALWAYS}
                title={<FormattedMessage id="campaign.editor.schedule.runtime.radio.always" defaultMessage="24/7" />}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={RUNTIME_TYPES.ALWAYS}
            />
            <Radio
                value={RUNTIME_TYPES.CUSTOM}
                title={
                    <FormattedMessage
                        id="campaign.editor.schedule.runtime.radio.specific"
                        defaultMessage="Set Specific Days or Hours"
                    />
                }
                description={runtimeCustomDescription}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-value={RUNTIME_TYPES.CUSTOM}
            />
        </RadioGroup>
    </FormField>
);

RuntimePicker.propTypes = {
    selection: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default RuntimePicker;
