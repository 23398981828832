import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const DealIdTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="pmpDeal.name.help"
            defaultMessage="The ID will be automatically added after the deal will be created"
        />
    </TooltipSection>
);

export default DealIdTooltip;
