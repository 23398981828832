import React from 'react';

export const DinersClub = props => (
    <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
            fill="#F4F6F7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
            fill="#E5E5E5"
            fillOpacity="0.01"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3134 15.4802C20.0165 15.4932 22.4837 13.2556 22.4837 10.5335C22.4837 7.55663 20.0165 5.49901 17.3134 5.5H14.987C12.2516 5.49901 10 7.55725 10 10.5335C10 13.2562 12.2516 15.4933 14.987 15.4802H17.3134Z"
            fill="url(#paint0_linear)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9969 5.91602C12.4973 5.91681 10.4718 7.96136 10.4712 10.4847C10.4718 13.0077 12.4973 15.0521 14.9969 15.0528C17.4971 15.0521 19.523 13.0077 19.5234 10.4847C19.523 7.96136 17.4971 5.91681 14.9969 5.91602ZM12.1282 10.4847C12.1305 9.25168 12.8937 8.20019 13.9698 7.78235V13.1865C12.8937 12.7689 12.1305 11.718 12.1282 10.4847ZM16.0236 13.1877V7.78217C17.1001 8.19901 17.8644 9.25108 17.8664 10.4847C17.8644 11.7188 17.1001 12.7701 16.0236 13.1877Z"
            fill="#F4F6F7"
        />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="25.0743"
                y1="13.5703"
                x2="21.8728"
                y2="3.10755"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#009BE0" />
                <stop offset="1" stopColor="#006BA8" />
            </linearGradient>
        </defs>
    </svg>
);
