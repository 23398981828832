import { BrandIcon, LeadIcon, MobileBigIcon, OnlineIcon, WebsiteIcon } from 'taboola-ultimate-ui';
import { FEATURE_FLAGS } from '../../../../taboola-common-frontend-modules/account-configurations';

const LEADS_GENERATION = 'LEADS_GENERATION';
const ONLINE_PURCHASES = 'ONLINE_PURCHASES';
const DRIVE_WEBSITE_TRAFFIC = 'DRIVE_WEBSITE_TRAFFIC';
const BRAND_AWARENESS = 'BRAND_AWARENESS';
const MOBILE_APP_INSTALL = 'MOBILE_APP_INSTALL';
const NONE = 'NONE';

const MARKETING_OBJECTIVE_KEYS = {
    LEADS_GENERATION,
    ONLINE_PURCHASES,
    DRIVE_WEBSITE_TRAFFIC,
    BRAND_AWARENESS,
    MOBILE_APP_INSTALL,
    NONE,
};

const MARKETING_OBJECTIVES = [
    {
        id: LEADS_GENERATION,
        icon: LeadIcon,
    },
    {
        id: ONLINE_PURCHASES,
        icon: OnlineIcon,
        accountConfigurations: { [FEATURE_FLAGS.ONLINE_PURCHASES_MARKETING_OBJECTIVE_ENABLED]: 'true' },
    },
    {
        id: DRIVE_WEBSITE_TRAFFIC,
        icon: WebsiteIcon,
    },
    {
        id: BRAND_AWARENESS,
        icon: BrandIcon,
    },
    {
        id: MOBILE_APP_INSTALL,
        icon: MobileBigIcon,
    },
];

export { MARKETING_OBJECTIVE_KEYS };
export default MARKETING_OBJECTIVES;
