import React from 'react';
import PropTypes from 'prop-types';
import { StepperButton, withStylesheet } from 'taboola-ultimate-ui';
import { FormattedNumber } from '../../../../../taboola-common-frontend-modules/i18n';
import { getMaxDigitPointNumber, restrictNumberWithDigits } from '../../utils/numberOperations';
import styles from './modificationButtons.module.scss';

const StyledStepperButton = withStylesheet(StepperButton, styles, {
    [StepperButton.classNames.main]: 'stepper-container',
    [StepperButton.classNames.downButton]: 'stepper-button',
    [StepperButton.classNames.upButton]: ['stepper-button', 'stepper-up'],
});

const getTooltipProps = (value, tooltipProps) => ({
    children: <FormattedNumber value={value} variant="percent" forceSign />,
    offset: 0,
    className: styles['stepper-tooltip'],
    ...tooltipProps,
});

// prettier-ignore
const ModificationButtons = ({
    value,
    diff,
    setValue,
    tooltipProps,
    upDisabled,
    downDisabled,
}) => {
    const maxDigitNumber = getMaxDigitPointNumber(value, diff);
    const stepUp = () => setValue(restrictNumberWithDigits(value + diff, maxDigitNumber));
    const stepDown = () => setValue(restrictNumberWithDigits(value - diff, maxDigitNumber));

    return (
        <StyledStepperButton
            onStepUp={stepUp}
            onStepDown={stepDown}
            upTooltipProps={getTooltipProps(diff, tooltipProps)}
            downTooltipProps={getTooltipProps(-diff, tooltipProps)}
            upDisabled={upDisabled}
            downDisabled={downDisabled}
        />
    );
};

ModificationButtons.propTypes = {
    value: PropTypes.number,
    diff: PropTypes.number,
    setValue: PropTypes.func,
    /* Override any of the default tooltip props */
    tooltipProps: PropTypes.object,
    upDisabled: PropTypes.bool,
    downDisabled: PropTypes.bool,
};

export default ModificationButtons;
