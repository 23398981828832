import React from 'react';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { createMenuListWithFooter } from 'components/Dropdown';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownOption } from '../../../../../../components/Dropdown';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import TARGETING_TYPES from '../../config/TargetingTypes';
import TargetingDropdownBox from '../TargetingDropdownBox/TargetingDropdownBox';
import { CustomContextualSegmentListItemContent } from './CustomContextualSegmentListItemContent/CustomContextualSegmentListItemContent';
import { DropdownCreateSegmentOption } from './DropdownCreateNewSegmentItem/DropdownCreateSegmentOption';
import { useCustomContextualSegments } from './hooks/useCustomContextualSegments';
import { useCustomContextualSegmentsValidation } from './hooks/useCustomContextualSegmentsValidation';
import commonStyles from '../commonEditor.module.scss';
import styles from './customContextualSegments.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
const customContextualSegmentsMenuListRenderer = createMenuListWithFooter(DropdownCreateSegmentOption);

export const CustomContextualSegments = ({ selectedAccountId }) => {
    const {
        options,
        clearAllExclude,
        clearAllInclude,
        deleteItemFromInclude,
        deleteItemFromExclude,
        addItem,
        targetingType,
        setTargetingType,
        includeSegments,
        excludeSegments,
        isLoadingOptions,
        isLoadingSegmentsByIds,
        isOptionsLoadedSuccessfully,
        isSegmentsByIdsLoadedSuccessfully,
        allSegmentsByIdMap,
    } = useCustomContextualSegments(selectedAccountId);

    const { includeFailedValidationData, excludeFailedValidationData } = useCustomContextualSegmentsValidation({
        allSegmentsByIdMap,
        isOptionsLoadedSuccessfully,
        isSegmentsByIdsLoadedSuccessfully,
    });

    return (
        <div className={styles['container']} aria-label="custom-contextual-segments-component">
            <TargetingDropdownBox
                itemType={targetingType}
                onSelectItemType={setTargetingType}
                itemPlaceholder={
                    <FormattedMessage
                        id="campaign.editor.targeting.custom.contextual.dropdown.placeholder"
                        defaultMessage="Select Segments ..."
                    />
                }
                onAddItem={addItem}
                allItems={options}
                itemOptionRenderer={DropdownOption}
                metricsAttributes={{
                    'data-metrics-event-name': GTM_EVENTS.USABILITY,
                    'data-metrics-component': 'CustomContextualTargeting',
                }}
                mainDropDownComponentProps={{
                    menuListRenderer: customContextualSegmentsMenuListRenderer,
                }}
                isItemsLoading={isLoadingOptions}
            />
            <CollapsibleListWithIndication
                id="campaignTargeting.customContextualSegmentsTargeting.include"
                containerClassName={styles['collapsible']}
                items={includeSegments}
                ItemComponent={CustomContextualSegmentListItemContent}
                metadata={{
                    targetingType: TARGETING_TYPES.INCLUDE,
                    isLoading: isLoadingSegmentsByIds,
                }}
                deleteItem={deleteItemFromInclude}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.custom.contextual.segments.title.included"
                        defaultMessage="Included Topics Segments"
                    />
                }
                clearItems={clearAllInclude}
                aria-label="include-list"
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                indicationContainerClass={commonStyles['warning-indication']}
                {...includeFailedValidationData}
            />
            <CollapsibleListWithIndication
                id="campaignTargeting.customContextualSegmentsTargeting.exclude"
                containerClassName={styles['collapsible']}
                items={excludeSegments}
                ItemComponent={CustomContextualSegmentListItemContent}
                metadata={{
                    targetingType: TARGETING_TYPES.EXCLUDE,
                    isLoading: isLoadingSegmentsByIds,
                }}
                deleteItem={deleteItemFromExclude}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.targeting.custom.contextual.segments.title.excluded"
                        defaultMessage="Excluded Topics Segments"
                    />
                }
                clearItems={clearAllExclude}
                aria-label="exclude-list"
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                clearAllLabel={<FormattedMessage id="app.actionButtons.clear.all" defaultMessage="Clear All" />}
                {...excludeFailedValidationData}
            />
        </div>
    );
};
