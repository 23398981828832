import {
    reportsBaseConfig,
    REPORT_TYPE,
    urlValidations,
    getCreativeStatusType,
    isCreativeStatusRejected,
} from '../../../../../config';
import {
    ContentCellRenderer,
    DcoCreativeActionsCellRenderer,
    CreativeIdCellRenderer,
    CreativeStatusRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    ImageCellRenderer,
    IMAGE_CELL_RENDERER_MODES,
    LinkCellRenderer,
    StateCellRenderer,
} from '../../../components';
import { previewCreative } from '../../../flows';
import { normalizeColumnDefs } from '../../../utils';
import {
    actionsColumnType,
    bypassMetadataFilterType,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from '../../columns';
import * as FIELDS from '../../fields';

const dcoCreativeReportColumnDefinition = [
    {
        headerName: FIELDS.IS_ACTIVE.label,
        field: FIELDS.IS_ACTIVE.field,
        type: [lockedColumn, toggleColumn, leftPinnedColumn, staticColumn],
        cellRendererParams: {
            ...FIELDS.IS_ACTIVE.renderParams,
            isDisabled: () => true,
            reportId: REPORT_TYPE.DCO_CAMPAIGN_CREATIVE,
        },
        cellRendererFramework: StateCellRenderer,
    },
    {
        headerName: FIELDS.STATUS.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.STATUS.field,
        type: [bypassMetadataFilterType, leftPinnedColumn],
        cellRendererFramework: CreativeStatusRenderer,
        flex: 1,
        minWidth: 120,
        cellRendererParams: {
            getStatusType: getCreativeStatusType,
            isStatusRejected: isCreativeStatusRejected,
        },
        csvValueGetter: FIELDS.STATUS.csvValueGetter,
    },
    {
        headerName: FIELDS.TEMPLATE_NAME.label,
        type: [bypassMetadataFilterType, leftPinnedColumn],
        field: FIELDS.TEMPLATE_NAME.field,
        cellRendererParams: {
            lines: 1,
        },
        width: 120,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.TITLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL.field,
        type: [leftPinnedColumn],
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.DEFAULT,
        },
        minWidth: 120,
        visibilityCondition: ({ isComfortableViewEnabled }) => !isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.THUMBNAIL_URL_COMFORTABLE.headerComponentParams,
        field: FIELDS.THUMBNAIL_URL_COMFORTABLE.field,
        type: [leftPinnedColumn, bypassMetadataFilterType],
        valueGetter: ({ data }) => {
            const { thumbnailUrl } = data;
            return thumbnailUrl;
        },
        cellRendererFramework: ImageCellRenderer,
        cellRendererParams: {
            mode: IMAGE_CELL_RENDERER_MODES.COMFORTABLE,
        },
        width: 186,
        csv: [
            {
                headerName: FIELDS.THUMBNAIL_URL_COMFORTABLE.label,
                field: FIELDS.THUMBNAIL_URL.field,
            },
        ],
        visibilityCondition: ({ isComfortableViewEnabled }) => isComfortableViewEnabled,
    },
    {
        headerName: FIELDS.CONTENT.label,
        type: [bypassMetadataFilterType],
        field: FIELDS.TITLE.field,
        cellRendererFramework: ContentCellRenderer,
        cellRendererParams: {
            lines: 2,
        },
        width: 270,
    },
    {
        headerName: FIELDS.URL.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.URL.headerComponentParams,
        field: FIELDS.URL.field,
        type: [],
        cellRendererFramework: LinkCellRenderer,
        width: 270,
        cellEditorParams: {
            validations: urlValidations,
        },
    },
    {
        headerName: FIELDS.TEMPLATE_ID.label,
        field: FIELDS.TEMPLATE_ID.field,
        type: [numericColumn],
        cellRendererFramework: CreativeIdCellRenderer,
    },
    {
        headerName: FIELDS.CAMPAIGN_NAME.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.STATUS.headerComponentParams,
        field: FIELDS.CAMPAIGN_NAME.field,
        cellRendererFramework: FIELDS.CAMPAIGN_NAME.cellRenderer,
        minWidth: 140,
    },
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        cellRendererParams: {
            onPreview: previewCreative,
        },
        cellRendererFramework: DcoCreativeActionsCellRenderer,
        csv: false,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CPA,
        FIELDS.VCPM,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(
    dcoCreativeReportColumnDefinition,
    reportsBaseConfig[REPORT_TYPE.DCO_CAMPAIGN_CREATIVE].id
);
