export const PERFORMANCE_RECOMMENDATIONS = {
    PLATFORM_TARGETING: 'PLATFORM_TARGETING',
    LOWER_CPC: 'LOWER_CPC',
    DAY_PARTING: 'EARLY_SPENT_UTILIZATION',
    BUDGET_V2_IC: 'BUDGET_V2_IC',
    LOW_PERFORMING_ITEM: 'LOW_PERFORMING_ITEM',
    TARGET_CPA: 'TARGET_CPA',
    TARGET_CPA_EDITABLE: 'TARGET_CPA_EDITABLE',
    AUDIENCE: 'AUDIENCE_IC',
    LOW_RESOLUTION_IC: 'LOW_RESOLUTION_IC',
    AUDIENCE_RETARGETING_IC: 'AUDIENCE_RETARGETING_IC',
    ATTENTIVE_AUDIENCE: 'ATTENTIVE_AUDIENCE',
    ITEM_WITHOUT_DESCRIPTION: 'ITEM_WITHOUT_DESCRIPTION',
    ITEM_WITHOUT_CTA: 'ITEM_WITHOUT_CTA',
    UNBLOCK_SITES: 'UNBLOCK_SITES',
    FATIGUED_ITEM: 'FATIGUED_ITEM',
    UNREALISTIC_TCPA: 'UNREALISTIC_TCPA',
};

export default PERFORMANCE_RECOMMENDATIONS;
