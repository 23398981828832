import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';

const inactiveAccountIndication = () => ({
    staticIndicationType: STATIC_INDICATION_TYPES.INACTIVE_ACCOUNT,
    type: INDICATION_TYPES.ERROR,
    iconTypeOverride: INDICATION_TYPES.WARNING,
    highlight: (
        <FormattedMessage id="app.campaign.indication.inactive.highlight" defaultMessage="This account is inactive" />
    ),
    message: (
        <FormattedMessage
            defaultMessage="Account has been set as inactive - Please contact your account manager or {support}"
            id="app.campaign.indication.inactive.message"
            values={{ support: <a href="mailto:support@taboola.com">support@taboola.com.</a> }}
        />
    ),
});

export default inactiveAccountIndication;
