import React, { useState } from 'react';
import classnames from 'classnames/bind';
import { ExternalLink } from 'components';
import { useTopicsPreviewTracker } from '../hooks/useTopicsPreviewTracker';
import styles from './ArticleCard.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ArticleCard = ({ articleDetails, topicName }) => {
    const { title, description, url, thumbnail_url, logo_url } = articleDetails;
    const [isValidThumbnail, setIsValidThumbnail] = useState(true);
    const { reportTopicsPreviewEvents } = useTopicsPreviewTracker();
    const handleClick = () => {
        reportTopicsPreviewEvents(`Article Clicked, Topic Name: ${topicName}, URL: ${url}`, 'Topics Preview Drawer');
    };

    const handleImageError = () => {
        reportTopicsPreviewEvents(
            `Invalid Image, Topic Name: ${topicName}, Image URL: ${thumbnail_url}, Article URL: ${url}`,
            'Topics Preview Drawer'
        );
        setIsValidThumbnail(false);
    };

    return (
        <ExternalLink
            href={url}
            onClick={handleClick}
            onAuxClick={handleClick}
            className={classNameBuilder('container')}
        >
            <div className={classNameBuilder('logo-and-title')}>
                <img src={logo_url} alt="Logo" className={classNameBuilder('logo')} />
                <span className={classNameBuilder('title')}>{title}</span>
            </div>
            <div className={classNameBuilder('description-container')}>
                <span className={classNameBuilder('description')}>{description}</span>
            </div>
            {thumbnail_url && isValidThumbnail && (
                <img
                    src={thumbnail_url}
                    alt="Thumbnail"
                    onError={handleImageError}
                    className={classNameBuilder('thumbnail')}
                />
            )}
        </ExternalLink>
    );
};
