import React, { useCallback, useMemo, useState } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import {
    CREATIVE_TYPE,
    FORM_MODES,
    thumbnailAutoReviewValidations,
    useInsignificantChangesValidation,
} from 'modules/campaigns/config';
import { ONLY_IMAGE_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { MediaPicker } from 'modules/campaigns/modules/creative-editor/components/MediaPicker/MediaPicker';
import { useCropImageModal } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/CroppingToolsForm/hooks/useCropImageModal';
import { FOCUS_TYPES, FOCUS_TYPES_ARRAY } from 'modules/campaigns/modules/creative-editor/config';
import { withIndication } from 'modules/errors';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import validationFunctions from 'modules/taboola-common-frontend-modules/validations/services/validationFunctions';
import { COMPONENT_STATUS } from 'services/constants';
import ThumbnailEditorImage from '../Image/ThumbnailEditorImage';
import styles from './thumbnailEditorBase.module.scss';

export const baseValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.creative.editor.thumbnail.empty',
        defaultMessage: 'Media file cannot be empty',
    },
];

const emptyValidations = [];

const ThumbnailEditorBase = ({
    setIsThumbnailLoading,
    isThumbnailLoading,
    applyFocusToMatches,
    setApplyFocusToMatches,
    matchingCreativeIds,
    disabled,
    isFocalPointEditorEnabled,
    skipValidations,
    campaignId,
    selectedRowsValues,
}) => {
    const [error, setError] = useState(null);

    const isContentValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CONTENT_VALIDATIONS_ENABLED]: 'true',
    });
    const isCroppingImageEnabled = useConfigMatch({ [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true' });

    const validationsWithFF = useMemo(
        () => (isContentValidationEnabled ? [...baseValidations, ...thumbnailAutoReviewValidations] : baseValidations),
        [isContentValidationEnabled]
    );
    const urlValidations = useInsignificantChangesValidation({ baseValidations: validationsWithFF, isForUrl: true });
    const {
        formAccount: { accountId },
        mode,
        setSubmitStatus,
    } = useFormDataContext();
    const {
        value: thumbnailUrl,
        onChange: onChangeThumbnailUrl,
        failedValidationData,
        scrollRef,
    } = useFormValidatedValue({
        validations: skipValidations ? emptyValidations : urlValidations,
        field: 'thumbnailUrl',
        validationDependencies: { selectedRowsValues: selectedRowsValues?.thumbnailUrls, accountId },
    });
    const { value: creativeFocus, onChange: onChangeCreativeFocus } = useFormFieldValue({ field: 'creativeFocus' });

    const setThumbnailData = useCallback(
        thumbnailUrl => {
            onChangeThumbnailUrl(thumbnailUrl);
            onChangeCreativeFocus({
                type: FOCUS_TYPES.AUTOMATIC,
                coordinates: null,
            });

            setError(null);
            setApplyFocusToMatches(false);
        },
        [onChangeThumbnailUrl, onChangeCreativeFocus, setApplyFocusToMatches]
    );
    const [lastSelectedId, setLastSelectedId] = useState();
    const selectedMedia = useMemo(() => (lastSelectedId ? [{ id: lastSelectedId }] : []), [lastSelectedId]);

    const { openModal: openCropModal } = useCropImageModal({ campaignId });
    const { onChange: onChangeCropped } = useFormFieldValue({ field: 'creativeCrop' });
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    const isCroppedModeEnable = (mode === FORM_MODES.DUPLICATE || mode === FORM_MODES.EDIT) && !isAppInstall;

    const onSelectMedia = useCallback(
        async (urlResolver, metadata) => {
            if (metadata.selected) {
                return;
            }
            setIsThumbnailLoading(true);
            setSubmitStatus(COMPONENT_STATUS.LOADING);

            try {
                const { url } = await urlResolver();
                setThumbnailData(url, { cdnUrl: url, ...metadata });
                setLastSelectedId(metadata?.id);
                onChangeCropped({ cropData: [] });
                if (isCroppedModeEnable && isCroppingImageEnabled) {
                    openCropModal({ src: url, metadata });
                }
            } catch {}

            setIsThumbnailLoading(false);
            setSubmitStatus(COMPONENT_STATUS.ACTIVE);
        },
        [
            setIsThumbnailLoading,
            setSubmitStatus,
            setThumbnailData,
            openCropModal,
            isCroppingImageEnabled,
            isCroppedModeEnable,
            onChangeCropped,
        ]
    );
    const isThumbnailSelected = thumbnailUrl || isThumbnailLoading || error;

    return (
        <MediaPicker
            fileTypes={ONLY_IMAGE_FILES}
            value={thumbnailUrl}
            onSelectMedia={onSelectMedia}
            disabled={disabled}
            tabsConditionData={{ creativeType: CREATIVE_TYPE.IMAGE }}
            tabsContext={{ thumbnails: selectedMedia, campaignId }}
            showFileBrowser
            openCropModal={openCropModal}
        >
            <div className={styles['creative-editor']}>
                {isThumbnailSelected && (
                    <>
                        <ThumbnailEditorImage
                            imageSrc={thumbnailUrl}
                            creativeFocus={creativeFocus}
                            onChangeCreativeFocus={onChangeCreativeFocus}
                            isThumbnailLoading={isThumbnailLoading}
                            imageError={error}
                            applyFocusToMatches={applyFocusToMatches}
                            setApplyFocusToMatches={setApplyFocusToMatches}
                            numMatchingCreatives={matchingCreativeIds.length}
                            isFocalPointEditorEnabled={isFocalPointEditorEnabled}
                            disabled={disabled}
                            failedValidationData={failedValidationData}
                            scrollRef={scrollRef}
                            openCropModal={openCropModal}
                        />
                    </>
                )}
            </div>
        </MediaPicker>
    );
};

ThumbnailEditorBase.propTypes = {
    /* Creative object and handler to set new changes */
    creative: PropTypes.shape({
        thumbnailUrl: PropTypes.string,
        thumbnailFile: PropTypes.object,
        thumbnailRecommendedImage: PropTypes.shape({
            id: PropTypes.string,
        }),
        creativeFocus: PropTypes.shape({
            type: PropTypes.oneOf(FOCUS_TYPES_ARRAY),
            coordinates: PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number,
            }),
        }),
    }),
    onUpdateCreative: PropTypes.func,
    /* Thumbnail loading flag and setter */
    isThumbnailLoading: PropTypes.bool,
    setIsThumbnailLoading: PropTypes.func,
    /* Boolean for whether to apply focal point changes to matching thumbnails */
    applyFocusToMatches: PropTypes.bool,
    setApplyFocusToMatches: PropTypes.func,
    matchingCreativeIds: PropTypes.array,
    disabled: PropTypes.bool,
    uploadDescription: PropTypes.node,
    campaignId: PropTypes.string,
};

ThumbnailEditorBase.defaultProps = {
    applyFocusToMatches: false,
    setApplyFocusToMatches: noop,
    matchingCreativeIds: [],
    campaignId: '-1',
};

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'validations.error.creative.editor.thumbnail.empty',
        defaultMessage: 'Media file cannot be empty',
    },
];

const ThumbnailEditorBaseWithIndication = withIndication(ThumbnailEditorBase);

export { ThumbnailEditorBaseWithIndication, validations as thumbnailEditorValidationsBase };
