import React from 'react';
import PropTypes from 'prop-types';
import { BlockIcon, DeletableItem, OkOIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingTypes from '../../../common-campaign-form/config/TargetingTypes';
import styles from './AdUnitTypeListItem.module.scss';

const AdUnitTypeListItem = ({ item, deleteItem }) => {
    const { targetingType, label = '', id } = item;

    const handleOnClick = () => {
        deleteItem({ id, targetingType });
    };

    return (
        <DeletableItem onDelete={handleOnClick}>
            <div className={styles['container']}>
                <span>
                    {targetingType === TargetingTypes.INCLUDE ? (
                        <OkOIcon className={styles['include']} role="ok-icon" />
                    ) : (
                        <BlockIcon className={styles['exclude']} role="block-icon" />
                    )}
                </span>
                <div className={styles['label']}>
                    <FormattedMessage
                        id={`video.campaign.creator.language.targeting.options.${label}`}
                        defaultMessage={label}
                    />
                </div>
            </div>
        </DeletableItem>
    );
};

AdUnitTypeListItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
        targetingType: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
};

export default AdUnitTypeListItem;
