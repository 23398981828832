import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import styles from './galleryTitle.module.scss';

const GalleryTitle = ({ name }) => (
    <div className={styles['gallery-title']}>
        <FormattedMessage id={`creative.creator.media.${name}`} defaultMessage={name} />
    </div>
);

GalleryTitle.propTypes = {
    name: PropTypes.string,
};

export default GalleryTitle;
