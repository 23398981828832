import React from 'react';
import { batch } from 'react-redux';
import { addIndication, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { videoCreativesApi } from 'services/api';
import { errorMessagesUtils } from 'services/utils';
import { requestUpdateCreative, updateCreativeError, updateCreativeSuccess } from '../actions';

const successIndication = {
    message: <FormattedMessage id="video.creative.update.success" defaultMessage="Creative successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="creative.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const updateCreative =
    ({ accountName, campaignId, creativeId, creativeChanges }) =>
    async dispatch => {
        dispatch(requestUpdateCreative());

        let updatedCreative;

        try {
            const response = await videoCreativesApi.updateVideoCreative(
                accountName,
                campaignId,
                creativeId,
                creativeChanges
            );

            updatedCreative = { id: response.id, name: response.name, advertiserName: response.accountName };
            batch(() => {
                dispatch(updateCreativeSuccess(updatedCreative));
                dispatch(addIndication(successIndication));
            });
        } catch (error) {
            batch(() => {
                dispatch(addIndication(generateErrorIndication(error)));
                dispatch(updateCreativeError(error));
            });
            throw error;
        }

        return updatedCreative;
    };

export default updateCreative;
