import { useCallback, useState } from 'react';
import { get } from 'lodash';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useAiGenerationApi } from 'services/api/aiGenerationApi';
import { COMPONENT_STATUS } from 'services/constants';
import { useRephraseApiCall } from './useRephraseApiCall';

export const SUGGESTION_TYPES = {
    REPHRASE: 'REPHRASE',
    FIX_DKI: 'FIX_DKI',
};

export const useTitleSuggestions = ({ type, campaignId }) => {
    const { accountName } = useAccount();

    const [status, setStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [suggestions, setSuggestions] = useState([]);

    const [isFetchingError, setIsFetchingError] = useState(false);
    const [previousInvalidTitle, setPreviousInvalidTitle] = useState(null);

    const getAiRephrasedTitles = useRephraseApiCall();
    const { getDKIFixSuggestions } = useAiGenerationApi();

    const resetError = useCallback(() => {
        setStatus(COMPONENT_STATUS.INITIAL);
        setPreviousInvalidTitle(null);
        setIsFetchingError(false);
    }, []);

    const reset = useCallback(() => {
        resetError();
        setSuggestions([]);
    }, [resetError]);

    const getSuggestionsFromApi = useCallback(
        originalText => {
            let apiCall;

            if (type === SUGGESTION_TYPES.FIX_DKI) {
                apiCall = async (...args) => {
                    const apiRes = await getDKIFixSuggestions(...args);

                    return { titles: get(apiRes, 'results.0.items.0.titleSuggestions', []) };
                };
            } else {
                apiCall = getAiRephrasedTitles;
            }

            return apiCall({ originalText, accountId: accountName, campaignId });
        },
        [accountName, campaignId, getAiRephrasedTitles, getDKIFixSuggestions, type]
    );

    const getSuggestions = useCallback(
        async title => {
            try {
                setStatus(COMPONENT_STATUS.LOADING);
                setPreviousInvalidTitle(null);

                const { titles } = await getSuggestionsFromApi(title);

                setSuggestions(
                    titles.map(title => ({
                        label: title,
                        value: title,
                    }))
                );

                if (titles.length === 0) {
                    setPreviousInvalidTitle(title);
                    return setStatus(COMPONENT_STATUS.ERROR);
                }

                setStatus(COMPONENT_STATUS.ACTIVE);
            } catch (e) {
                setPreviousInvalidTitle(title);
                setIsFetchingError(true);
                setStatus(COMPONENT_STATUS.ERROR);
            }
        },
        [getSuggestionsFromApi]
    );

    return {
        suggestions,
        status,
        isFetchingError,
        previousInvalidTitle,

        getSuggestions,
        reset,
        resetError,
    };
};
