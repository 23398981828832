import { useMediaFetchService } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/services';
import { fetchRecommendedMedia } from 'modules/campaigns/modules/creative-editor/flows';

const useRecommendedMediaFetchService = ({ accountId, tags, mediaType, isMediaTypeChanged, maxTotalItems }) =>
    useMediaFetchService({
        accountId,
        fetch: fetchRecommendedMedia,
        loadParams: {
            tags,
            mediaType,
            isMediaTypeChanged,
            maxTotalItems,
        },
    });

export default useRecommendedMediaFetchService;
