import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-report-by-os-family-performance',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.CTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CVR_CLICKS,
        metrics.CVR_VIEWS,
        metrics.CONVERSIONS,
        metrics.CONVERSIONS_CLICKS,
        metrics.CONVERSIONS_VIEWS,
        metrics.CPA,
        metrics.CPA_CLICKS,
        metrics.CPA_VIEWS,
        metrics.VCPM,
        metrics.CPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
        metrics.ROAS_CLICKS,
        metrics.ROAS_VIEWS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    graphProps: {
        xAxisDataKeyWithIntl:
            intl =>
            ({ [FIELDS.PLATFORM_CODE.field]: platform, [FIELDS.OS_FAMILY.field]: operatingSystemFamily }) =>
                `${operatingSystemFamily}/${intl.formatMessage({
                    id: `${FIELDS.PLATFORM_CODE.renderParams.msgIdPrefix}.${platform}`,
                    defaultMessage: platform,
                })}`,
    },
    enableLongTextTickFormatting: true,
};

export default graph;
