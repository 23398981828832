import { useCallback, useEffect, useMemo, useRef } from 'react';
import { get, isEqual, isFunction, isUndefined, keys, set } from 'lodash';
import { capitalize } from 'lodash';
import { useCurrentValueGetter, useShallowObject } from '../../../../hooks';
import { useFormDataContext } from '../FormDataContext';
import { throwCriticalError } from '../utils/throwCriticalError';
import { useNamespaceField } from './useNamespaceField';

const getMessageHint = fieldHashId =>
    `Please try to find already existed hook it may be called like use${capitalize(fieldHashId)}FormFieldValue or 
    use${capitalize(fieldHashId)}FormValidatedValue. Using one hook in all places may solve the issue.`;

const useDependenciesCheck = (fieldHashId, dependencies) => {
    const { defaultValuesCache } = useFormDataContext();
    const contextRenderCounter = defaultValuesCache.renderCounter;
    const ownRenderCounter = useRef(0);
    const renderCounterKeyName = useMemo(() => [fieldHashId, 'renderCounter'], [fieldHashId]);
    const dependenciesKeyName = useMemo(() => [fieldHashId, 'dependencies'], [fieldHashId]);

    ++ownRenderCounter.current;
    useEffect(() => {
        ownRenderCounter.current = 1;
    }, [contextRenderCounter]);

    // We do cache of dependencies per field for each context render
    useEffect(() => {
        if (get(defaultValuesCache, renderCounterKeyName) !== contextRenderCounter) {
            // Initialization
            set(defaultValuesCache, renderCounterKeyName, contextRenderCounter);
            set(defaultValuesCache, dependenciesKeyName, dependencies);
        }
    }, [contextRenderCounter, dependencies, defaultValuesCache, renderCounterKeyName, dependenciesKeyName]);

    // On any dependencies change we do comparison control
    useEffect(() => {
        if (ownRenderCounter.current > 1) {
            return;
        }
        const cachedDependencies = get(defaultValuesCache, dependenciesKeyName);
        const cachedKeys = keys(cachedDependencies).join(',');
        const currentKeys = keys(dependencies).join(',');

        if (cachedKeys !== currentKeys) {
            throwCriticalError(
                new Error(
                    `You have different dependencies structure for field (${fieldHashId}), ${cachedKeys} vs ${currentKeys}.  ${getMessageHint(
                        fieldHashId
                    )}`
                )
            );
            return;
        }

        if (!isEqual(dependencies, cachedDependencies)) {
            throwCriticalError(
                new Error(
                    `You have different dependencies values for field (${fieldHashId}) - 
                value 1 [${JSON.stringify(cachedDependencies, null, ' ')}] vs 
                value 2 [${JSON.stringify(dependencies, null, ' ')}]. 
                ${getMessageHint(fieldHashId)}`
                )
            );
        }
    }, [dependencies, defaultValuesCache, dependenciesKeyName, fieldHashId]);
};

const useDefaultValueCache = fieldHashId => {
    const { defaultValuesCache } = useFormDataContext();
    const defaultValueKeyName = useMemo(() => [fieldHashId, 'lastDefaultValue'], [fieldHashId]);
    const dependenciesKeyName = useMemo(() => [fieldHashId, 'lastDependencies'], [fieldHashId]);
    const cacheDefaultValue = useCallback(
        (defaultValue, dependencies) => {
            set(defaultValuesCache, defaultValueKeyName, defaultValue);
            set(defaultValuesCache, dependenciesKeyName, dependencies);
        },
        [defaultValueKeyName, dependenciesKeyName, defaultValuesCache]
    );
    const isDefaultValueCached = useCallback(
        (defaultValue, dependencies) => {
            const cachedDefaultValue = get(defaultValuesCache, defaultValueKeyName);
            const cachedDependencies = get(defaultValuesCache, dependenciesKeyName);
            const isAlreadyCached =
                isEqual(defaultValue, cachedDefaultValue) && isEqual(dependencies, cachedDependencies);

            return isAlreadyCached;
        },
        [defaultValueKeyName, dependenciesKeyName, defaultValuesCache]
    );

    return { cacheDefaultValue, isDefaultValueCached };
};
export const useDefaultValue = ({
    field,
    isNewForm,
    defaultValuesConfig,
    dependencies,
    isAbsolute,
    isPermitted = true,
}) => {
    const { fetchStatus, isSelfOrParentDirty, setField, fetchedData } = useFormDataContext();
    const memoDependencies = useShallowObject(dependencies);
    const dependenciesGetter = useCurrentValueGetter(memoDependencies);

    const fieldHashIdRaw = useNamespaceField(field, true);
    const fieldHashId = isAbsolute ? field : fieldHashIdRaw;
    useDependenciesCheck(fieldHashId, memoDependencies);
    const { cacheDefaultValue, isDefaultValueCached } = useDefaultValueCache(fieldHashId);

    const defaultValue = useMemo(() => {
        if (!isNewForm || !isPermitted) {
            return;
        }

        const rawValue = get(defaultValuesConfig, field);

        if (isFunction(rawValue)) {
            return rawValue(memoDependencies || {}, fetchedData || {});
        }

        return rawValue;
    }, [isNewForm, isPermitted, defaultValuesConfig, field, memoDependencies, fetchedData]);

    // Update value with default value in form data context
    useEffect(() => {
        const dependenciesExtended = { ...dependenciesGetter(), fetchStatus };

        if (
            isUndefined(defaultValue) ||
            isSelfOrParentDirty(field) ||
            isDefaultValueCached(defaultValue, dependenciesExtended)
        ) {
            return;
        }

        cacheDefaultValue(defaultValue, dependenciesExtended);
        setField({
            field,
            value: defaultValue,
            isInitial: true,
        });
    }, [
        defaultValue,
        setField,
        isSelfOrParentDirty,
        field,
        dependenciesGetter,
        cacheDefaultValue,
        isDefaultValueCached,
        fetchStatus,
    ]);

    return defaultValue;
};
