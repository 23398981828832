'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactContentLoader = require('react-content-loader');

var _reactContentLoader2 = _interopRequireDefault(_reactContentLoader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var ImageContentLoader = function ImageContentLoader(_ref) {
    var width = _ref.width,
        height = _ref.height,
        rest = _objectWithoutProperties(_ref, ['width', 'height']);

    return _react2.default.createElement(
        _reactContentLoader2.default,
        Object.assign({
            width: width,
            height: height,
            speed: 2,
            viewBox: '0 0 ' + width + ' ' + height
        }, rest),
        _react2.default.createElement('rect', { x: '0', y: '0', rx: '7', ry: '7', width: width, height: height })
    );
};

ImageContentLoader.propTypes = {
    width: _propTypes2.default.number,
    height: _propTypes2.default.number
};

ImageContentLoader.defaultProps = {
    width: 250,
    height: 200
};

exports.default = ImageContentLoader;