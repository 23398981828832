import { useMemo } from 'react';
import { get, values } from 'lodash';
import { isCreateFormMode } from 'config/formModes';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useNamespaceField } from '../../formData/hooks/useNamespaceField';
import { getParentArrayField, isArrayElementField } from '../../formData/utils/formContextUtils';
import { useValidations } from './useValidations';

export const useFormValidatedValue = ({
    isPermitted,
    field,
    formFieldDependencies,
    validations,
    validationDependencies,
    isReady,
    hasInitialData = true,
    validateOnParentChange = false,
    throttleValue,
    throttleTiming,
}) => {
    const { isSelfOrParentDirty, data } = useFormDataContext();
    const {
        formContextValue,
        mode,
        isDirty,
        indicationData: indicationDataField,
        ...restFormFieldValue
    } = useFormFieldValue(
        {
            isPermitted,
            field,
            throttleValue,
            throttleTiming,
        },
        formFieldDependencies
    );
    const validationId = useNamespaceField(field, true);
    const fullFieldName = useNamespaceField(field);
    const shouldCheckParent = useMemo(
        () =>
            validateOnParentChange ||
            (isArrayElementField(fullFieldName) &&
                values(validationDependencies).includes(get(data, getParentArrayField(fullFieldName)))),
        [validateOnParentChange, validationDependencies, fullFieldName, data]
    );

    const { failedValidationData, scrollRef, triggerValidationEvent, isValidationsRunning, isValid } = useValidations({
        validations,
        validationId,
        valueToValidate: formContextValue,
        validationDependencies,
        isDirty: isDirty || (shouldCheckParent && isSelfOrParentDirty(fullFieldName)),
        isReady,
        hasInitialData: !isCreateFormMode(mode) && hasInitialData,
    });

    const indicationData = useMemo(
        () => ({
            ...indicationDataField,
            ...failedValidationData,
            indicationType: failedValidationData?.indicationType || indicationDataField?.indicationType,
        }),
        [indicationDataField, failedValidationData]
    );

    return {
        mode,
        isDirty,
        failedValidationData,
        scrollRef,
        triggerValidationEvent,
        isValidationsRunning,
        isValid,
        validationId,
        indicationData,
        ...restFormFieldValue,
    };
};

export default useFormValidatedValue;
