'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIsMobile = undefined;

var _MobileContext = require('../components/MobileContext');

var useIsMobile = exports.useIsMobile = function useIsMobile() {
  return (0, _MobileContext.useMobileContext)();
};