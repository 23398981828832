import { validateUrl, validationFunctions } from 'modules/taboola-common-frontend-modules/validations/services';
import { splitOnNewLine } from 'services/utils/stringUtils';

const emptyValidation = {
    validationFn: validationFunctions.isNotEmpty,
    messageId: 'validations.error.field.url.empty',
};
const oneByOneEmptyValidation = {
    validationFn: validationFunctions.isNotEmpty,
    messageId: 'validations.error.ONE_BY_ONE.field.urls.empty',
};

const urlSyntaxValidation = {
    validationFn: validationFunctions.isURL,
    options: { require_protocol: true },
    messageId: 'validations.error.field.url.invalid',
};
export const uniqueUrlValidation = {
    validationFn: validationFunctions.notIn,
    messageId: 'validations.error.field.url.unique.values',
};
const accessibleUrlValidation = {
    validationFn: async (value, _, { formAccountId, bypassUrlResponseValidation, accessibleUrlCache }) => {
        if (bypassUrlResponseValidation || value === accessibleUrlCache.current) {
            return true;
        }
        return await validateUrl(formAccountId, value);
    },
    messageId: 'validations.error.field.url.fetch.invalid.with.SubmitAnyway',
    defaultMessage:
        'URL can not be accessed. Please check there are no errors, typos or geo location blocking on the URL. {submitAnyway}',
};
const noValidUrlsValidation = {
    validationFn: text => {
        const urls = splitOnNewLine(text, { removeWhitespace: true });
        return urls.some(url => validationFunctions.isURL(url));
    },
    messageId: 'validations.error.ONE_BY_ONE.field.urls.all.invalid',
};

const urlValidations = [emptyValidation, urlSyntaxValidation, accessibleUrlValidation];
const oneByOneURLValidations = [oneByOneEmptyValidation, noValidUrlsValidation];

export { urlValidations, oneByOneURLValidations };
