import { useCallback, useEffect, useMemo, useRef } from 'react';
import { difference, identity, isEmpty, keyBy } from 'lodash';
import { FORM_MODES } from 'config/formModes';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations/hooks/useFormValidatedValue';
import { MAX_SEGMENTS_TO_SELECT } from '../../../../../campaigns/modules/common-campaign-form/components/Tree';
import TargetingTypes from '../../../../../campaigns/modules/common-campaign-form/config/TargetingTypes';
import { useCombinedAudienceIngredientsTargetingValues } from './useCombinedAudienceIngredientsTargetingValues';

const useSelectedSegments = ({
    selectedLeaves,
    setSelectedLeavesByIds,
    targetingType,
    includeIdsFormFieldName,
    includeErrorListValidations = [],
    includeWarningListValidations = [],
    setIsReady,
    isReadyToValidate,
}) => {
    const { mode } = useFormDataContext();

    const { includeLength: combinedAudienceIngredientsValuesLength } = useCombinedAudienceIngredientsTargetingValues();

    const leavesByIdMap = useMemo(() => keyBy(selectedLeaves, 'id'), [selectedLeaves]);

    const errorValidationDependencies = useMemo(() => {
        return {
            leavesByIdMap,
            limit: MAX_SEGMENTS_TO_SELECT,
        };
    }, [leavesByIdMap]);

    const { value: { length: fieldLength } = [] } = useFormFieldValue({
        field: `${includeIdsFormFieldName}`,
    });

    const warningValidationDependencies = useMemo(() => {
        return {
            data: {
                combinedAudienceIngredientsValuesLength,
                fieldLength,
            },
        };
    }, [combinedAudienceIngredientsValuesLength, fieldLength]);

    const includeValidations = useMemo(
        () => [...includeErrorListValidations, ...includeWarningListValidations],
        [includeErrorListValidations, includeWarningListValidations]
    );
    const validationDependencies = useMemo(
        () => ({ ...errorValidationDependencies, ...warningValidationDependencies }),
        [errorValidationDependencies, warningValidationDependencies]
    );
    const {
        value: includeIds = [],
        onChange: onChangeIncludeIds,
        failedValidationData: includeFailedValidationData,
        scrollRef: includeScrollRef,
    } = useFormValidatedValue({
        field: includeIdsFormFieldName,
        validations: includeValidations,
        validationDependencies,
        isReady: mode === FORM_MODES.CREATE || isReadyToValidate,
    });

    // flow to sync selectedLeaves with selectedIncludeSegmentsIds and selectedExcludeSegmentsIds
    const leavesInitRef = useRef(false);
    const initLeavesCallback = async () => {
        const selectedIds = [...includeIds];
        if (!isEmpty(selectedIds)) {
            await setSelectedLeavesByIds(selectedIds);
        } else {
            setIsReady(true);
        }
        leavesInitRef.current = true;
    };

    useEffect(() => {
        if (leavesInitRef.current) {
            return;
        }

        initLeavesCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!leavesInitRef.current) {
            return;
        }

        const treeSelectedLeafIds = selectedLeaves.map(item => item.id);
        const listSelectedIds = [...includeIds];
        const addedIds = difference(treeSelectedLeafIds, listSelectedIds);
        if (addedIds.length) {
            onChangeIncludeIds(prev => [...prev, ...addedIds]);
        }

        const removedIds = difference(listSelectedIds, treeSelectedLeafIds);
        if (removedIds.length === 0) {
            return;
        }

        const treeSelectedLeafIdsMap = keyBy(treeSelectedLeafIds, identity);
        const removedIncludeIds = difference(includeIds, treeSelectedLeafIds);
        if (removedIncludeIds.length) {
            onChangeIncludeIds(prev => prev.filter(id => treeSelectedLeafIdsMap[id]));
        }
    }, [includeIds, onChangeIncludeIds, selectedLeaves, targetingType]);

    const { includeLeaves } = useMemo(() => {
        return {
            includeLeaves: includeIds.map(id => ({
                id,
                ...leavesByIdMap[id],
                loading: !leavesByIdMap[id],
                targetingType: TargetingTypes.INCLUDE,
            })),
        };
    }, [includeIds, leavesByIdMap]);

    const removeIncludeLeaves = useCallback(() => setSelectedLeavesByIds([]), [setSelectedLeavesByIds]);

    return {
        includeLeaves,
        removeIncludeLeaves,
        includeFailedValidationData,
        scrollRef: includeScrollRef,
    };
};

export default useSelectedSegments;
