import React from 'react';
import { batch } from 'react-redux';
import { removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { INDICATION_TYPES, addIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { sharedBudgetApi } from 'services/api/sharedBudgetApi/sharedBudgetApi';
import { errorMessagesUtils } from 'services/utils';
import { toGWtransformers, fromGWtransformers } from '../../../flows/flowsUtils';
import { requestCreateCampaignsGroup, createCampaignsGroupSuccess, createCampaignsGroupError } from '../actions';

const successIndication = {
    message: <FormattedMessage id="campaigns.group.create.success" defaultMessage="Group successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="campaigns.group.create.error.highlight" defaultMessage="Unable to create Group" />,
});

const createCampaignGroup =
    (accountName, campaignsGroup, entityType, api = sharedBudgetApi) =>
    async dispatch => {
        dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
        dispatch(requestCreateCampaignsGroup());
        let createdCampaignsGroup;

        const transformedCampaignsGroup = toGWtransformers[entityType](campaignsGroup);

        try {
            const response = await api.createCampaignGroup(accountName, transformedCampaignsGroup);
            createdCampaignsGroup = { campaignsGroupId: response.campaignsGroupId, name: response.name };
            batch(() => {
                dispatch(createCampaignsGroupSuccess(fromGWtransformers[entityType](response)));
                dispatch(addIndication(successIndication));
            });
        } catch (error) {
            batch(() => {
                dispatch(addIndication(generateErrorIndication(error)));
                dispatch(createCampaignsGroupError(error));
            });
            throw error;
        }

        return createdCampaignsGroup;
    };

export default createCampaignGroup;
