import React from 'react';
import { MultiStepDescription } from '../../OnboardingStepDescription/MultiStepDescription';
import { STEP_IDS } from '../../consts';
import { useCompleteAccountStepData } from '../../hooks/useCompleteAccountStepData';

const { COMPLETE_ACCOUNT_SETUP } = STEP_IDS;

export const CompleteAccountStep = ({ isCollapsed, stepCompleteData }) => {
    const { subStepData } = useCompleteAccountStepData({ stepCompleteData });
    return (
        <MultiStepDescription
            delayMsgId="onboarding.assistant.CTA.delay.pixel"
            titleId={COMPLETE_ACCOUNT_SETUP}
            subStepData={subStepData}
            isCollapsed={isCollapsed}
        />
    );
};
