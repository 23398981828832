import React from 'react';

export const Style = props => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.77996 16.6737L7.62627 17.0274V11.3242L6.09154 15.0253C5.83259 15.6695 6.14206 16.4084 6.77996 16.6737ZM19.0957 14.3368L15.9631 6.77684C15.7673 6.30316 15.3063 6.01263 14.82 6C14.6557 6 14.4852 6.02526 14.321 6.09474L9.66627 8.02105C9.19259 8.21684 8.90206 8.67158 8.88943 9.15789C8.88312 9.32842 8.91469 9.49895 8.98417 9.66316L12.1168 17.2232C12.3126 17.7032 12.78 17.9937 13.2726 18C13.4368 18 13.601 17.9684 13.7589 17.9053L18.4073 15.9789C19.0515 15.7137 19.361 14.9747 19.0957 14.3368ZM13.2852 16.7368L10.1526 9.18316L14.7947 7.26316H14.801L17.9273 14.8105L13.2852 16.7368Z"
            fill="#667686"
        />
        <path
            d="M12.1294 10.5789C12.4782 10.5789 12.761 10.2962 12.761 9.94737C12.761 9.59856 12.4782 9.31579 12.1294 9.31579C11.7806 9.31579 11.4979 9.59856 11.4979 9.94737C11.4979 10.2962 11.7806 10.5789 12.1294 10.5789Z"
            fill="#667686"
        />
        <path
            d="M8.89575 16.7368C8.89575 17.4316 9.46417 18 10.1589 18H11.0747L8.89575 12.7326V16.7368Z"
            fill="#667686"
        />
    </svg>
);
