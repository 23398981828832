import { useCallback, useState } from 'react';
import { useDimension } from 'hooks/queryParams/useDimension';
import { CAMPAIGN_DIMENSION, getSecondaryCampaignDimension } from '../../../../../services/campaignDimension';

const useCampaignTotals = ({ loadCampaignsTotalsByDimension, defaultPageSize }) => {
    const [selectedCampaignDimension] = useDimension();
    const [totalCampaignsByDimension, setTotalCampaignsByDimension] = useState({});

    const updateTotals = (dimension, campaignTotals) => {
        setTotalCampaignsByDimension(prevTotalCampaignByType => ({
            ...prevTotalCampaignByType,
            [dimension]: campaignTotals,
        }));
    };

    const loadSecondaryCampaignsTotals = useCallback(async () => {
        const secondaryCampaignDimension = getSecondaryCampaignDimension(selectedCampaignDimension);
        const secondaryTotals = await loadCampaignsTotalsByDimension(secondaryCampaignDimension);
        updateTotals(secondaryCampaignDimension, secondaryTotals);
    }, [loadCampaignsTotalsByDimension, selectedCampaignDimension]);

    const setSelectedCampaignTotals = useCallback(
        totalCampaigns => {
            updateTotals(selectedCampaignDimension, totalCampaigns);
        },
        [selectedCampaignDimension]
    );

    const reset = useCallback(() => {
        setTotalCampaignsByDimension({
            [CAMPAIGN_DIMENSION.VIDEO]: defaultPageSize,
            [CAMPAIGN_DIMENSION.SPONSORED]: defaultPageSize,
        });
    }, [defaultPageSize]);

    return {
        loadSecondaryCampaignsTotals,
        totalCampaignsByDimension,
        reset,
        setSelectedCampaignTotals,
    };
};

export default useCampaignTotals;
