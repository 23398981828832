import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { CATEGORIES } from '../config/categories';

export const usePromptHelperFormFieldValue = () => {
    return {
        [CATEGORIES.FOCUS]: {
            ...useFormFieldValue({ field: 'promptHelper.focus' }),
        },
        [CATEGORIES.STYLE]: {
            ...useFormFieldValue({ field: 'promptHelper.style' }),
        },
        [CATEGORIES.COLOR]: {
            ...useFormFieldValue({ field: 'promptHelper.color' }),
        },
        [CATEGORIES.LIGHTING]: {
            ...useFormFieldValue({ field: 'promptHelper.lighting' }),
        },
        [CATEGORIES.EMOTION]: {
            ...useFormFieldValue({ field: 'promptHelper.emotion' }),
        },
    };
};
