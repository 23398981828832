import {
    REQUEST_CREATE_CAMPAIGNS_GROUP,
    CREATE_CAMPAIGNS_GROUP_SUCCESS,
    CREATE_CAMPAIGNS_GROUP_ERROR,
} from './actionTypes';

export const requestCreateCampaignsGroup = () => ({ type: REQUEST_CREATE_CAMPAIGNS_GROUP });

export const createCampaignsGroupSuccess = campaignsGroup => ({
    type: CREATE_CAMPAIGNS_GROUP_SUCCESS,
    payload: campaignsGroup,
});

export const createCampaignsGroupError = error => ({ type: CREATE_CAMPAIGNS_GROUP_ERROR, payload: error });
