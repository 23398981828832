import React from 'react';
import { HelpTooltip } from 'taboola-ultimate-ui';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './workspaceDropdown.module.scss';

export const WorkspaceDropdownTooltip = () => {
    return (
        <HelpTooltip
            iconClassName={styles['help-icon']}
            content={
                <TooltipSection>
                    <FormattedMessage
                        id="tracking.setup.gtm.workspace.label.tooltip"
                        defaultMessage="Select your Google Tag Manager workspace"
                    />
                </TooltipSection>
            }
        />
    );
};
