import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from '../../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage, FormattedNumber } from '../../../../taboola-common-frontend-modules/i18n';

const getNearCreditLimitIndication = (currency, remaining) => {
    const remainingBalance = (
        <FormattedNumber
            value={remaining}
            variant="currency"
            currency={currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
        />
    );
    return {
        staticIndicationType: STATIC_INDICATION_TYPES.NEAR_CREDIT_LIMIT,
        type: INDICATION_TYPES.WARNING,
        iconTypeOverride: INDICATION_TYPES.WARNING,
        displayDismissComponent: false,
        highlight: <FormattedMessage id="app.campaign.indication.nearCreditLimit.highlight" />,
        message: (
            <FormattedMessage
                id="app.campaign.indication.nearCreditLimit.message"
                values={{ balance: remainingBalance }}
            />
        ),
    };
};

export default getNearCreditLimitIndication;
