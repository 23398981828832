'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _EmptyStateOverlay = require('./EmptyStateOverlay');

var _EmptyStateOverlay2 = _interopRequireDefault(_EmptyStateOverlay);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _EmptyStateOverlay2.default;