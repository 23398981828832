import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router';
import { isFunction } from 'lodash';
import { navigate } from '../../../actions';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { ACHPaymentMethodCreator } from '../components/ACHPaymentMethodCreator/ACHPaymentMethodCreator';
import { CardPaymentMethodCreator } from '../components/CardPaymentMethodCreator/CardPaymentMethodCreator';
import {
    ACH_PAYMENT_METHOD_CREATOR_ROUTE_PATH,
    BILLING_AND_PAYMENTS_ROUTE,
    CARD_PAYMENT_METHOD_CREATOR_ROUTE_PATH,
} from '../config';
import { BillingAndPaymentsPage } from './BillingAndPaymentsPage';

const path = BILLING_AND_PAYMENTS_ROUTE;

export const BillingAndPaymentsModulePage = ({ history }) => {
    const dispatch = useDispatch();
    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history]
    );

    return (
        <Switch>
            <LayerRoute
                path={CARD_PAYMENT_METHOD_CREATOR_ROUTE_PATH}
                exact
                render={props => <CardPaymentMethodCreator {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                path={ACH_PAYMENT_METHOD_CREATOR_ROUTE_PATH}
                exact
                render={props => <ACHPaymentMethodCreator {...props} onCancel={handleNavigation} />}
            />
            <LayerRoute
                path={path}
                render={props => <BillingAndPaymentsPage {...props} onCancel={handleNavigation} />}
                hasNestedRoutes
            />
        </Switch>
    );
};
