import { useMemo } from 'react';
import { RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES } from 'modules/campaigns/modules/performance-recommendations/config/PerformanceRecommendationsActionParamTypes';
import { PERFORMANCE_RECOMMENDATIONS_ACTION_TYPES } from 'modules/campaigns/modules/performance-recommendations/config/PerformanceRecommendationsActionTypes';

export const useRecommendationActionParam = recommendation => {
    const { actions = [], type: recommendationType } = recommendation;

    const recommendationAction = useMemo(() => {
        return actions.find(
            ({ actionType }) => actionType === PERFORMANCE_RECOMMENDATIONS_ACTION_TYPES[recommendationType]
        );
    }, [actions, recommendationType]);
    const { actionId: recommendationActionId } = recommendationAction || {};
    const recommendationData = useMemo(() => {
        const { usedTimeUtc } = recommendation;
        if (usedTimeUtc) {
            return null;
        }
        if (recommendationAction) {
            const { params = [] } = recommendationAction;
            return JSON.parse(
                params.find(({ name }) => name === RECOMMENDATIONS_ACTION_TYPE_TO_PARAM_TYPES.CAMPAIGN)?.value ?? null
            );
        }
    }, [recommendation, recommendationAction]);

    return {
        recommendationData,
        recommendationActionId,
    };
};
