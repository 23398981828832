import { BUDGET_TYPES } from 'modules/campaigns/modules/common-campaign-form/config/budgetTypes';
import { DELIVERY_METHODS } from 'modules/campaigns/modules/common-campaign-form/config/deliveryMethods';
import { SPENDING_LIMITS } from 'modules/campaigns/modules/common-campaign-form/config/spendingLimits';

const isDailyBudget = type => type === BUDGET_TYPES.DAILY;

const isUnlimitedBudget = type => type === BUDGET_TYPES.UNLIMITED;
const getInitialSpending = ({ budgetType, isNoneSpendingLimitEnabled }) => {
    if (!isDailyBudget(budgetType) || isUnlimitedBudget(budgetType)) {
        return null;
    }

    return { type: isNoneSpendingLimitEnabled ? SPENDING_LIMITS.NONE : SPENDING_LIMITS.MONTHLY, value: '' };
};

export const getInitialDeliveryMethod = type =>
    isDailyBudget(type) || isUnlimitedBudget(type) ? null : DELIVERY_METHODS.BALANCED;

export const getEmptyBudgetObject = ({ prev, type, isNoneSpendingLimitEnabled, budgetCache }) => ({
    ...prev,
    spendingLimit: getInitialSpending({
        budgetType: type,
        isNoneSpendingLimitEnabled,
    }),
    deliveryMethod: getInitialDeliveryMethod(type),
    value: '',
    ...budgetCache?.current[type],
    type,
});
