import Cookies from 'js-cookie';
import { SimpleEventEmitter } from 'modules/taboola-common-frontend-modules/validations/services/validationService/simpleEventEmitter';

const TA_ASSETS_VERSION_COOKIE_NAME = 'ta-assets-version';
const VERSION_UPDATED_EVENT = 'versionUpdated';

class Config extends SimpleEventEmitter {
    constructor() {
        super();
        this._assetsVersion = Cookies.get(TA_ASSETS_VERSION_COOKIE_NAME);
        this._gwVersion = '';
    }
    init() {}

    getGWVersionFromResponse(response) {
        return response?.headers?.get?.('X-TA-GW-Version');
    }

    get assetsVersion() {
        return this._assetsVersion;
    }

    get gwVersion() {
        return this._gwVersion;
    }

    setGWVersion(response) {
        this._gwVersion = this.getGWVersionFromResponse(response);
    }

    setAssetsVersion(value) {
        this._assetsVersion = value;
        Cookies.set(TA_ASSETS_VERSION_COOKIE_NAME, value);
    }

    updateVersion(response) {
        const gwVersion = this.getGWVersionFromResponse(response);
        if (gwVersion && this.assetsVersion !== gwVersion) {
            this.trigger(VERSION_UPDATED_EVENT, gwVersion);
        }
    }

    onVersionUpdated(callback) {
        const off = this.on(VERSION_UPDATED_EVENT, () => {
            off();
            callback();
        });
        return off;
    }
}

export const config = new Config();
