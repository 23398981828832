import { useEffect, useLayoutEffect } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useTaxonomiesResources } from '../../MarketplaceAudiences/hooks';
import useSelectedSegments from '../../MarketplaceAudiences/hooks/useSelectedSegments';
import { MAX_SEGMENTS_TO_SELECT, useTreeState } from '../../Tree';
import {
    includeErrorListValidations,
    includeWarningListValidations,
    excludeErrorListValidations,
    excludeWarningListValidations,
} from '../ContextualSegmentsValidations';
import useContextualSegmentsTreeData from './useContextualSegmentsTreeData';

const leafTransformer = ({ provider, size, articles, description, allowedCountries }) => ({
    provider,
    size,
    articles,
    description,
    allowedCountries,
});

const useContextualSegments = ({
    search,
    targetingType,
    selectedAccountId,
    excludeContextualSegmentsFieldName,
    includeContextualSegmentsFieldName,
}) => {
    const { isSegmentsLoading, ...treeStateParams } = useContextualSegmentsTreeData({
        search,
        selectedAccountId,
    });
    const { getTaxonomyValue, reloadTaxonomies } = useTaxonomiesResources({ selectedAccountId });
    const { formatMessage } = useIntl();
    const { selectedLeaves, setSelectedLeavesByIds, handleReload, expandAll, isReady, setIsReady, ...treeProps } =
        useTreeState({
            search,
            leafTransformer,
            formatMessage,
            getTaxonomyValue,
            ...treeStateParams,
            defaultMaxAmountToSelect: MAX_SEGMENTS_TO_SELECT,
        });

    const {
        includeLeaves,
        excludeLeaves,
        removeIncludeLeaves,
        removeExcludeLeaves,
        includeFailedValidationData,
        excludeFailedValidationData,
    } = useSelectedSegments({
        targetingType,
        selectedLeaves,
        setSelectedLeavesByIds,
        includeIdsFormFieldName: includeContextualSegmentsFieldName,
        excludeIdsFormFieldName: excludeContextualSegmentsFieldName,
        includeErrorListValidations,
        includeWarningListValidations,
        excludeErrorListValidations,
        excludeWarningListValidations,
        setIsReady,
        isReadyToValidate: isReady,
    });

    useEffect(
        function loadInitialTaxonomies() {
            reloadTaxonomies();
        },
        [reloadTaxonomies]
    );

    useLayoutEffect(
        function loadInitialSegments() {
            handleReload();
        },
        [handleReload]
    );

    useEffect(
        function expandTreeForSearching() {
            if (!isEmpty(search)) {
                expandAll();
            }
        },
        [search, expandAll]
    );

    return {
        isSegmentsLoading,
        treeStateParams,
        treeProps,
        includeLeaves,
        excludeLeaves,
        removeIncludeLeaves,
        removeExcludeLeaves,
        includeFailedValidationData,
        excludeFailedValidationData,
    };
};

export default useContextualSegments;
