import React from 'react';
import PropTypes from 'prop-types';
import { BudgetIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { Budget } from '../../../common-campaign-form/components/Budget/Budget';

const CampaignsGroupBudgetSection = () => (
    <CommonCollapsibleCard
        id="CAMPAIGNS_GROUP_BUDGET_BIDDING"
        header={
            <SectionHeader
                headerIcon={<BudgetIcon />}
                headerText={
                    <span role="heading" aria-level="1">
                        <FormattedMessage id="campaigns.group.editor.budget" />
                    </span>
                }
            />
        }
    >
        <Budget entityTypeMessagePrefix="campaigns.group" />
    </CommonCollapsibleCard>
);

CampaignsGroupBudgetSection.propTypes = {
    currency: PropTypes.string,
    mode: PropTypes.oneOf(Object.values(FORM_MODES)),
};

export default CampaignsGroupBudgetSection;
