import { DSPLoader } from 'components/DSPLoader/DSPLoader';
import { useParentAppName } from 'modules/taboola-common-frontend-modules/i18n/hooks/useParentAppName';
import TaboolaLoader from '../TaboolaLoader/TaboolaLoader';

const loaderMap = {
    yahoo: DSPLoader,
};

export const useFormLoadingOverlayLoader = ({ defaultLoader = TaboolaLoader } = {}) => {
    const themeName = useParentAppName();

    return loaderMap[themeName] || defaultLoader;
};
