import { useQuery } from 'react-query';
import { nameToLabel } from 'modules/campaigns/services/utils/dropdownUtils';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useUnipFunnelApi } from 'services/api/unipFunnelAPI';

const emptyObj = [];

export const useAccountFunnels = () => {
    const { getAllFunnels } = useUnipFunnelApi();

    const {
        formAccount: { accountName },
    } = useFormDataContext();

    const {
        data = emptyObj,
        isLoading,
        isError,
    } = useQuery(['getAllFunnels', accountName], () => getAllFunnels(accountName), {
        select: data => data?.map(nameToLabel) ?? [],
        enabled: !!accountName,
        refetchOnWindowFocus: false,
    });

    return { data, isLoading, isError };
};
