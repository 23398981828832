import { useMemo } from 'react';

export const useTemplateInitialValues = (
    performanceRulesSettingsData,
    performanceRulesTemplatesData,
    fieldSettings
) => {
    const {
        templateValue = {},
        action,
        templateKey,
    } = useMemo(() => {
        const templateValue = {};
        let action = '';
        let templateKey = [];
        if (performanceRulesTemplatesData?.results && performanceRulesSettingsData?.results) {
            const performanceRulesTemplatesDataResults = performanceRulesTemplatesData.results.sort((a, b) =>
                a.templateName.localeCompare(b.templateName)
            );
            performanceRulesTemplatesDataResults.forEach(result => {
                action = result.actionType;
                templateKey.push(result.templateKey);
                templateValue[result.templateName] = { ifCondition: [], andCondition: [] };
                const conditions = result.performanceRuleTemplateCondition;
                conditions.forEach((condition, index) => {
                    const metricProperty = condition.performanceRuleDefaultValue
                        .filter(value => value.property === 'metricName')
                        .map(value => value.defaultValue);

                    if ((metricProperty && fieldSettings[metricProperty]) || metricProperty.includes('')) {
                        const settings = condition.performanceRuleDefaultValue.reduce((acc, value) => {
                            const { property, defaultValue, enabled } = value;
                            const { conversionEvent = false } = fieldSettings[metricProperty] ?? {};

                            acc[property] = { defaultValue, enabled, conversionEvent };

                            return acc;
                        }, {});

                        if (index === 0) {
                            templateValue[result.templateName].ifCondition.push(settings);
                        }
                        if (index === 1) {
                            templateValue[result.templateName].andCondition.push(settings);
                        }
                    }
                });
            });
        }

        return { templateValue, action, templateKey };
    }, [fieldSettings, performanceRulesSettingsData?.results, performanceRulesTemplatesData?.results]);

    return { templateValue, action, templateKey };
};
