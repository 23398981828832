import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ExternalLink } from '../../../../../../components';

const LearnMore = props => (
    <ExternalLink {...props}>
        <FormattedMessage id="campaign.editor.learnMore" defaultMessage="Learn More" />
    </ExternalLink>
);

export default LearnMore;
