import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import {
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
} from '../../../components/HeaderRenderers';
import { formattedNumberValueGetter, gridNumberFormatter } from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import { alwaysVisibleColumnType, leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const osFamilyReportColumnDefinition = [
    {
        headerName: FIELDS.PLATFORM_CODE.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.PLATFORM_CODE.headerComponentParams,
        field: FIELDS.PLATFORM_CODE.field,
        type: [leftPinnedColumn, alwaysVisibleColumnType],
        cellRendererFramework: FIELDS.PLATFORM_CODE.cellRendererFramework,
        cellRendererParams: FIELDS.PLATFORM_CODE.renderParams,
        csvValueGetter: FIELDS.PLATFORM_CODE.csvValueGetter,
    },
    {
        headerName: FIELDS.OS_FAMILY.label,
        headerValueGetter: FormattedMessageHeaderRenderer,
        headerComponentParams: FIELDS.OS_FAMILY.headerComponentParams,
        field: FIELDS.OS_FAMILY.field,
        type: [leftPinnedColumn, alwaysVisibleColumnType],
        csvValueGetter: FIELDS.OS_FAMILY.csvValueGetter,
    },
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.VCTR,
        FIELDS.AVERAGE_CPC,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(osFamilyReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.OS_FAMILY].id);
