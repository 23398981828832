import React from 'react';
import { batch } from 'react-redux';
import getChangesObject from 'modules/taboola-common-frontend-modules/utils/objectDiff';
import { requestUpdateFunnel, updateFunnelError, updateFunnelSuccess } from 'modules/tracking/actions';
import { errorMessagesUtils } from 'services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';

export const successIndication = {
    message: <FormattedMessage id="funnel.save.success" defaultMessage="Funnel successfully updated" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
};

export const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="conversion.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const updateFunnel =
    ({ accountId, originalFunnel, editedFunnel, onError, updateUnipFunnel }) =>
    async dispatch => {
        const funnelChanges = getChangesObject(editedFunnel, originalFunnel);
        const { id: funnelId } = editedFunnel;
        dispatch(requestUpdateFunnel());
        try {
            const updateFunnelResponse = await updateUnipFunnel(accountId, funnelId, funnelChanges);

            batch(() => {
                dispatch(updateFunnelSuccess(updateFunnelResponse));
                dispatch(addIndication(successIndication));
            });
            return updateFunnelResponse;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(updateFunnelError(error));
            if (onError) {
                onError();
            }
            throw error;
        }
    };
