'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SearchInput = undefined;

var _withStylesheet2;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _keycodeJs = require('keycode-js');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactTransitionGroup = require('react-transition-group');

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

var _hoc = require('../../hoc');

var _hoc2 = _interopRequireDefault(_hoc);

var _Icons = require('../../Icons/Icons');

var _Buttons = require('../../Buttons');

var _Input = require('../Input/Input');

var _Input2 = _interopRequireDefault(_Input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'searchInput__taboola-svg-icon___2rENo',
    'input': 'searchInput__input___24hvZ',
    'taboola-input': 'searchInput__taboola-input___3wt9K',
    'hide-arrow-buttons': 'searchInput__hide-arrow-buttons___1YF1q',
    'input-wrapper': 'searchInput__input-wrapper___31TkC',
    'label': 'searchInput__label___l_FwH',
    'description': 'searchInput__description___35D2l',
    'error': 'searchInput__error___22Mzv',
    'warning': 'searchInput__warning___3bOXg',
    'suggestion': 'searchInput__suggestion___2Dlyc',
    'full-width': 'searchInput__full-width___3KpWe',
    'with-icon': 'searchInput__with-icon___1Bw6V',
    'input-icon': 'searchInput__input-icon___6QHSR',
    'expandable-input-container': 'searchInput__expandable-input-container___2e2hY',
    'open': 'searchInput__open___2mDs4',
    'expandable-input': 'searchInput__expandable-input___nOPmZ',
    'clear-icon': 'searchInput__clear-icon___1BmG8',
    'expandable-input-clear-animation-enter': 'searchInput__expandable-input-clear-animation-enter___2BMD7',
    'expandable-input-clear-animation-enter-active': 'searchInput__expandable-input-clear-animation-enter-active___2vGr8',
    'expandable-input-clear-animation-leave': 'searchInput__expandable-input-clear-animation-leave___3_y8b',
    'expandable-input-clear-animation-leave-active': 'searchInput__expandable-input-clear-animation-leave-active___3lUue'
};

var SearchInput = function (_PureComponent) {
    _inherits(SearchInput, _PureComponent);

    function SearchInput(props) {
        _classCallCheck(this, SearchInput);

        var _this = _possibleConstructorReturn(this, (SearchInput.__proto__ || Object.getPrototypeOf(SearchInput)).call(this, props));

        _initialiseProps.call(_this);

        var value = props.value;


        _this.inputElement = _react2.default.createRef();
        _this.state = {
            isFocused: false,
            lastSubmitValue: value
        };
        return _this;
    }

    _createClass(SearchInput, [{
        key: 'render',
        value: function render() {
            var _classnames;

            var isFocused = this.state.isFocused;

            var _props = this.props,
                value = _props.value,
                expandableIcon = _props.expandableIcon,
                inputClass = _props.inputClass,
                inputIconClass = _props.inputIconClass,
                inputContainerClass = _props.inputContainerClass,
                inputContainerOpenClass = _props.inputContainerOpenClass,
                expandable = _props.expandable,
                width = _props.width,
                inputPadding = _props.inputPadding,
                onSubmit = _props.onSubmit,
                classes = _props.classes,
                InputComponent = _props.InputComponent,
                rest = _objectWithoutProperties(_props, ['value', 'expandableIcon', 'inputClass', 'inputIconClass', 'inputContainerClass', 'inputContainerOpenClass', 'expandable', 'width', 'inputPadding', 'onSubmit', 'classes', 'InputComponent']);

            var isInputOpen = !expandable || isFocused || value;
            var containerStyle = expandable ? { width: '' } : { width: width };
            var inputContainerClassName = (0, _classnames3.default)(classes.inputContainer, inputContainerClass, (_classnames = {}, _defineProperty(_classnames, classes.open, isInputOpen), _defineProperty(_classnames, inputContainerOpenClass, inputContainerOpenClass && isInputOpen), _classnames));
            var inputIconClassName = (0, _classnames3.default)(classes.inputIcon, inputIconClass);

            return _react2.default.createElement(
                'div',
                { className: inputContainerClassName, style: containerStyle },
                _react2.default.createElement(
                    InputComponent,
                    Object.assign({}, rest, {
                        ref: this.inputElement,
                        inputPadding: inputPadding,
                        className: inputClass,
                        onFocus: this.onFocus,
                        onBlur: this.onBlur,
                        onKeyDown: this.handleKeyDown,
                        value: value,
                        size: _Input2.default.SIZE.LARGE
                    }),
                    _react2.default.createElement(
                        'div',
                        { className: classes.iconContainer },
                        _react2.default.createElement(
                            'div',
                            {
                                className: inputIconClassName,
                                onClick: this.focusInput,
                                role: 'button',
                                tabIndex: 0
                            },
                            expandableIcon
                        ),
                        _react2.default.createElement(
                            _reactTransitionGroup.CSSTransitionGroup,
                            {
                                transitionName: {
                                    enter: classes.onEnter,
                                    enterActive: classes.onEnterActive,
                                    leave: classes.onLeave,
                                    leaveActive: classes.onLeaveActive
                                },
                                transitionEnterTimeout: 500,
                                transitionLeaveTimeout: 300
                            },
                            value && _react2.default.createElement(SearchButton, {
                                onClick: this.clearInput,
                                iconBefore: 'close',
                                type: 'borderless-icon'
                            })
                        )
                    )
                )
            );
        }
    }]);

    return SearchInput;
}(_react.PureComponent);

var _initialiseProps = function _initialiseProps() {
    var _this2 = this;

    this.onBlur = function (e) {
        var _props2 = _this2.props,
            onBlur = _props2.onBlur,
            value = _props2.value;


        _this2.setState({
            isFocused: false,
            lastSubmitValue: value
        });

        onBlur(e);
        _this2.handleSubmit(value);
    };

    this.onFocus = function (e) {
        var onFocus = _this2.props.onFocus;


        _this2.setState({
            isFocused: true
        });

        onFocus(e);
    };

    this.focusInput = function () {
        _this2.inputElement.current.focus();
    };

    this.clearInput = function () {
        var onChange = _this2.props.onChange;


        _this2.inputElement.current.focus();

        onChange({ target: { value: '' } });
        _this2.handleSubmit('');
    };

    this.handleKeyDown = function (e) {
        var value = _this2.props.value;


        if (e.keyCode === _keycodeJs.KEY_RETURN) {
            _this2.handleSubmit(value);
        }
    };

    this.handleSubmit = function (value) {
        var lastSubmitValue = _this2.state.lastSubmitValue;
        var onSubmit = _this2.props.onSubmit;


        if (value === lastSubmitValue) {
            return;
        }

        var eventValue = { target: { value: value } };
        onSubmit(eventValue);

        _this2.setState({ lastSubmitValue: value });
    };
};

var SearchButton = (0, _hoc.withStylesheet)(_Buttons.StyledButton, styles, _defineProperty({}, _Buttons.StyledButton.classNames.main, 'clear-icon'));

var StyledInput = (0, _hoc.withStylesheet)(_Input2.default, styles, (_withStylesheet2 = {}, _defineProperty(_withStylesheet2, _Input2.default.classNames.main, 'expandable-input'), _defineProperty(_withStylesheet2, _Input2.default.classNames.input, 'input'), _defineProperty(_withStylesheet2, _Input2.default.classNames.inputWrapper, 'input-wrapper'), _defineProperty(_withStylesheet2, _Input2.default.classNames.inputContainer, 'expandable-input'), _withStylesheet2), 'DefaultSearchInputComponent');

SearchInput.classNames = {
    inputContainer: 'expandable-input-container',
    open: 'open',
    inputIcon: 'input-icon',
    iconContainer: 'icon-container',
    onEnter: 'expandable-input-clear-animation-enter',
    onEnterActive: 'expandable-input-clear-animation-enter-active',
    onLeave: 'expandable-input-clear-animation-leave',
    onLeaveActive: 'expandable-input-clear-animation-leave-active'
};

SearchInput.propTypes = {
    /** Id of the input component */
    id: _propTypes2.default.string,
    /** Type of the input component */
    type: _propTypes2.default.string,
    /** Class added to input element */
    inputClass: _propTypes2.default.string,
    /** Class added to icon wrapper element */
    inputIconClass: _propTypes2.default.string,
    /** Class added to input container element */
    inputContainerClass: _propTypes2.default.string,
    /** Class added to input container element when open */
    inputContainerOpenClass: _propTypes2.default.string,
    /** The icon element that is featured */
    expandableIcon: _propTypes2.default.node,
    /** Left padding of text - to match icon element width */
    inputPadding: _propTypes2.default.number,
    /** Standard onBlur prop */
    onBlur: _propTypes2.default.func,
    /** Standard onChange prop */
    onChange: _propTypes2.default.func,
    /** Called when the search value is submitted */
    onSubmit: _propTypes2.default.func,
    /** Standard onFocus prop */
    onFocus: _propTypes2.default.func,
    /** Standard input value prop */
    value: _propTypes2.default.string,
    /** Should expand */
    expandable: _propTypes2.default.bool,
    /** Standard input width prop */
    width: _propTypes2.default.string,
    /** debounce in millis */
    debounceMillis: _propTypes2.default.number,
    classes: _propTypes2.default.object,
    InputComponent: _propTypes2.default.elementType
};

SearchInput.defaultProps = {
    type: 'text',
    expandableIcon: _react2.default.createElement(_Icons.SearchIcon, null),
    onChange: function onChange() {},
    onBlur: function onBlur() {},
    onFocus: function onFocus() {},
    onSubmit: function onSubmit() {},
    expandable: true,
    width: '100%',
    classes: SearchInput.classNames,
    InputComponent: StyledInput
};

var WrappedSearchInput = (0, _hoc.withMergedStyles)((0, _hoc2.default)(SearchInput), styles);
WrappedSearchInput.defaultInput = StyledInput;

exports.SearchInput = SearchInput;
exports.default = WrappedSearchInput;