import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { isFunction } from 'lodash';
import { navigate } from '../../../actions';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { INDICATION_TYPES, removeIndication } from '../../taboola-common-frontend-modules/Indications';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { EditDrawer } from '../components/EditDrawer/EditDrawer';
import { ACCOUNT_EDITOR_ROUTE_PATH } from '../config';
import { AccountEditorPage } from './AccountEditorPage';
import styles from './accountPage.module.scss';

export const AccountPage = () => {
    const containerRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch(null);

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, match.path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history, match.path]
    );

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    return (
        <div className={styles['account-page-container']} ref={containerRef}>
            {isMultiLayerDrawerEnabled && (
                <LayerRoute exact path={ACCOUNT_EDITOR_ROUTE_PATH} render={props => <AccountEditorPage {...props} />} />
            )}
            <EditDrawer onCancel={handleNavigation} />
        </div>
    );
};
