import { createContext } from 'react';
import { noop } from 'lodash';

export const ChatAgentContext = createContext({
    userAction: noop,
    registerGrid: noop,
    openChat: noop,
    clearWindowList: noop,
    setChatReady: noop,
    registerWizard: noop,
});
