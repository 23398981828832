import React from 'react';
import { UploadOutlinedIcon } from 'tuui';
import { DragAndDropFile, DragAndDropFileContext } from 'taboola-ultimate-ui';
import { FileClickOrDropZone } from './FileClickOrDropZone/FileClickOrDropZone';
import styles from './fileSelect.module.scss';

export const FileSelect = ({ isLoading, file, fileTypes, isMultipleFilesAllowed, description, onDrop, onRemove }) => {
    return (
        <DragAndDropFile
            fileTypes={fileTypes}
            multiple={isMultipleFilesAllowed}
            onDrop={onDrop}
            dropIcon={<UploadOutlinedIcon fontSize="large" className={styles['drop-icon']} />}
            text={description}
        >
            <DragAndDropFileContext.Consumer>
                {({ openFileDialog }) => (
                    <FileClickOrDropZone
                        isLoading={isLoading}
                        filename={file?.name}
                        onRemoveFileHandler={onRemove}
                        openFileDialog={openFileDialog}
                        description={description}
                        containerClassName={styles['file-zone-container']}
                    />
                )}
            </DragAndDropFileContext.Consumer>
        </DragAndDropFile>
    );
};
