import { useCallback } from 'react';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useCreativePreviewQueryParams } from '../../Body/hooks/useCreativePreviewQueryParams';

const TBL_CREATIVE_PREVIEW = 'tbl_creative_preview';
const TBL_CAROUSEL_PROPERTIES_OVERRIDE = 'tbl_properties_override';
const TBL_DISABLE_CACHE = 'tbl_disable_cache';
const TBL_OVERRIDE_PLACEMENT_ITEM_COUNT = 'tbl_override_placement_item_count';
const carousel_prop_override = JSON.stringify({ scft: 'carousel' });
export const useCreativePreviewParameters = () => {
    const { creativePreviewParams, shouldPropertiesOverride } = useCreativePreviewQueryParams();
    const { value: carouselCards } = useFormFieldValue({ field: 'carouselCards' });

    const getAppInstallParameters = useCallback(
        creativePreview => {
            if (!!shouldPropertiesOverride && shouldPropertiesOverride.length > 0) {
                return `${creativePreview}&tbl_properties_override=${encodeURIComponent(shouldPropertiesOverride)}`;
            }
            return creativePreview;
        },
        [shouldPropertiesOverride]
    );

    // maxItemsLimit params is used for limiting the number of items in the creative preview as too long url is an issue.
    return useCallback(
        maxItemsLimit => {
            if (!creativePreviewParams.length) {
                return;
            }

            let creativePreviewItems = creativePreviewParams.filter(item => {
                const additionalProperties = item['additional-properties'];
                return !additionalProperties?.hideMe;
            });

            if (!creativePreviewItems.length) {
                return;
            }

            if (maxItemsLimit) {
                creativePreviewItems = creativePreviewItems.slice(0, maxItemsLimit);
            }

            let creativePreview;
            const parameters = JSON.stringify(creativePreviewItems);
            if (parameters) {
                creativePreview = `${TBL_CREATIVE_PREVIEW}=${encodeURIComponent(parameters)}`;
                if (carouselCards) {
                    creativePreview += `&${TBL_CAROUSEL_PROPERTIES_OVERRIDE}=${encodeURIComponent(
                        carousel_prop_override
                    )}`;
                    creativePreview += `&${TBL_OVERRIDE_PLACEMENT_ITEM_COUNT}=true`;
                }
                creativePreview += `&${TBL_DISABLE_CACHE}=true`;
            }
            creativePreview = getAppInstallParameters(creativePreview);
            return creativePreview;
        },
        [creativePreviewParams, getAppInstallParameters, carouselCards]
    );
};
