import React from 'react';
import { DeleteOutlinedIcon, ContentCopyOutlinedIcon } from 'tuui';
import { CollapsibleList } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMMON_FLAGS, useConfigMatch } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from '../../../../../../taboola-common-frontend-modules/formData';
import { FORM_MODES } from '../../../../../config';
import SiteTargetingListItem from '../SiteTargetingListItem/SiteTargetingListItem';
import { useCampaignLevelSmartBidBlockedSites } from '../hooks/useCampaignLevelSmartBidBlockedSites';
import { useSiteClipboardOperations } from '../hooks/useSiteClipboardOperations';
import styles from '../SiteTargetingList/siteTargetingList.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);
const ITEM_HEIGHT = 40;

const CampaignSmartBidBlockedSitesInner = () => {
    const { copySitesToClipboard } = useSiteClipboardOperations();
    const readonly = useConfigMatch({}, { [COMMON_FLAGS.IS_AUTO_BLOCKED_SITES_FORM_EDITABLE]: 'false' });
    const { siteItems, allowedSites, isAllowedSitesReady, deleteOne, clearAll, failedValidationData } =
        useCampaignLevelSmartBidBlockedSites();

    return (
        <CollapsibleListWithIndication
            clearAllLabel={<DeleteOutlinedIcon aria-label="clear-all-auto-blocked" />}
            data-testid="site-auto-blocked-list"
            items={siteItems}
            ItemComponent={SiteTargetingListItem}
            deleteItem={readonly ? null : deleteOne}
            clearItems={readonly ? null : clearAll}
            itemHeight={ITEM_HEIGHT}
            listHeaderClass={styles['list-header']}
            listHeaderTitle={
                <FormattedMessage
                    id="campaign.editor.targeting.site.smartBid.header"
                    defaultMessage="Auto Blocked by SpendGuard"
                />
            }
            showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
            showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
            containerClassName={styles['list']}
            collapsedItemsLimit={5}
            copyToClipboard={() => copySitesToClipboard(siteItems)}
            copyToClipboardLabel={<ContentCopyOutlinedIcon aria-label="copy-all-auto-blocked" />}
            metadata={{
                isAllowedSitesReady,
                allowedSites,
                readonly,
            }}
            {...failedValidationData}
        />
    );
};

export const CampaignSmartBidBlockedSites = () => {
    const { mode } = useFormDataContext();
    if (mode !== FORM_MODES.EDIT && mode !== FORM_MODES.PREVIEW) {
        return null;
    }
    return <CampaignSmartBidBlockedSitesInner />;
};
