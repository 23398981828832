import { invert } from 'lodash';
import { FORM_MODES } from 'config/formModes';

export const FORM_MODE_TO_PATH_MAP = {
    [FORM_MODES.EDIT]: 'edit',
    [FORM_MODES.BULK_EDIT]: 'bulkEdit',
    [FORM_MODES.BULK_DUPLICATE]: 'bulkDuplicate',
    [FORM_MODES.BULK_CREATE]: 'bulkNew',
    [FORM_MODES.CREATE]: 'new',
    [FORM_MODES.DUPLICATE]: 'duplicate',
    [FORM_MODES.PREVIEW]: 'preview',
};

export const PATH_TO_FORM_MODE_MAP = invert(FORM_MODE_TO_PATH_MAP);

export const MODE_PARAM_NAME = 'mode';

const MODE_PATHS = Object.values(FORM_MODE_TO_PATH_MAP);
export const MODE_PARAM_TEMPLATE = `/:${MODE_PARAM_NAME}(${MODE_PATHS.join('|')})`;
