import React from 'react';
import { identity } from 'lodash';
import {
    getCombinedAudience,
    getCustomContextualSegment,
    getFirstPartyAudience,
    getPredictiveAudience,
    getMyAudience,
    getMRTAudience,
    getSRTAudience,
} from 'services/api/audiencesApi';
import { REPORT_TYPE } from '../../campaigns/config';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../taboola-common-frontend-modules/i18n';
import { fetchAudienceForEditError, fetchAudienceForEditSuccess, requestFetchAudienceForEdit } from '../actions';
import { AUDIENCE_TYPE, ENTITY } from '../config';
import {
    transformCombinedAudiencesSegmentFromGW,
    transformExternalAudiencesSegmentFromGW,
    transformPixelBasedAudienceFromGW,
} from '../transformers';

const indication = {
    message: (
        <FormattedMessage
            id="campaign.drawer.error"
            defaultMessage="Something went wrong when we tried to fetch the requested campaign."
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const apis = {
    [REPORT_TYPE.PIXEL_BASED]: getMyAudience,
    [REPORT_TYPE.PREDICTIVE]: getPredictiveAudience,
    [REPORT_TYPE.FIRST_PARTY]: getFirstPartyAudience,
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: getCustomContextualSegment,
    [REPORT_TYPE.COMBINED_AUDIENCES]: getCombinedAudience,
    [ENTITY.SEARCH_KEYWORDS]: getSRTAudience,
    [ENTITY.MAIL_DOMAINS]: getMRTAudience,
};

const transformers = {
    [REPORT_TYPE.PIXEL_BASED]: transformPixelBasedAudienceFromGW,
    [REPORT_TYPE.PREDICTIVE]: identity,
    [REPORT_TYPE.FIRST_PARTY]: identity,
    [REPORT_TYPE.CUSTOM_CONTEXTUAL]: identity,
    [REPORT_TYPE.COMBINED_AUDIENCES]: transformCombinedAudiencesSegmentFromGW,
    [AUDIENCE_TYPE.SEARCH_KEYWORDS]: transformExternalAudiencesSegmentFromGW,
    [AUDIENCE_TYPE.MAIL_DOMAINS]: transformExternalAudiencesSegmentFromGW,
};

const fetchAudienceForEdit =
    (accountId, audienceId, reportType, entity, { hideError } = {}) =>
    async dispatch => {
        dispatch(requestFetchAudienceForEdit());

        try {
            const audience = await getAudience(accountId, audienceId, reportType, entity);
            dispatch(fetchAudienceForEditSuccess(audience));
            return audience;
        } catch (error) {
            if (!hideError) {
                dispatch(addIndication(indication));
                dispatch(fetchAudienceForEditError(error));
            }
        }
    };

const getAudience = async (accountId, audienceId, reportType, entity) => {
    if (entity === ENTITY.SEARCH_KEYWORDS || entity === ENTITY.MAIL_DOMAINS) {
        return fetchAndTransformAudienceByType(accountId, audienceId, entity);
    }
    return fetchAndTransformAudienceByType(accountId, audienceId, reportType);
};

const fetchAndTransformAudienceByType = async (accountId, audienceId, type) => {
    const audience = await apis[type](accountId, audienceId);
    return transformers[type](audience);
};

export default fetchAudienceForEdit;
