import React from 'react';
import { Button } from 'tuui';
import { TargetCpaInput } from 'modules/campaigns/modules/common-campaign-form/components/BidStrategy/TargetCpaRadio/TargetCpaInput';
import { useCpaRecommendation } from 'modules/campaigns/modules/performance-recommendations/hooks/useCpaRecommendation';
import { withRibbonFormValidationDataProvider } from '../../../../../../taboola-common-frontend-modules/formData/withRecommendationFormValidationDataProvider';
import { useCpaGoalFormFieldValue } from '../../../../common-campaign-form/components/CpaGoal/useCpaGoalFormFieldValue';
import { PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE } from '../../../config/performanceRecommendationIndicationType';
import { usePerformanceRecommendationExecution } from '../../../hooks/usePerformanceRecommendationExecution';
import AppliedText from '../../AppliedText/AppliedText';
import { ActionRecommendationButton } from '../../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import { ApplyButton } from '../../Buttons/ApplyButton/ApplyButton';
import ButtonsSection from '../../ButtonsSection/ButtonsSection';
import { PerformanceRecommendationRibbon } from '../PerformanceRecommendationRibbon';
import styles from './CPARibbon.module.scss';

const CPARibbon = ({ recommendation }) => {
    const { value: targetCpa } = useCpaGoalFormFieldValue();
    const { actionParamValue, applyParams, additionalValidations, messageParams, navigateToEditCampaign } =
        useCpaRecommendation({
            recommendation,
        });
    const { min_range: minRange, max_range: maxRange } = messageParams;

    const { apply } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams,
    });

    return (
        <PerformanceRecommendationRibbon
            recommendation={recommendation}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.set-cpa.title"
            descriptionCode="performance-recommendations.set-cpa.description"
            recommendationIndicationType={PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE.ACTION}
        >
            {apply.isSuccess && (
                <AppliedText successCode="performance-recommendations.general-recommendation.success-message" />
            )}
            <div className={styles['ribbon-input']}>
                <TargetCpaInput
                    additionalValidations={additionalValidations}
                    minRange={minRange}
                    maxRange={maxRange}
                    containerClass={styles['ribbon-input-container']}
                    disabled={apply.isSuccess}
                    shouldUseTooltipMessageIndication={true}
                />
            </div>
            <ButtonsSection variant={ButtonsSection.variants.RIBBON}>
                <ApplyButton
                    onClick={apply.invoke}
                    isApplied={apply.isSuccess}
                    isApplyLoading={apply.isLoading}
                    textCode="performance-recommendations.set-cpa.action"
                    actionParams={actionParamValue}
                    tooltipCode="performance-recommendations.set-cpa.action.tooltip"
                    size={Button.size.lg}
                    dataMetricsComponent="RecommendationRibbonActionButton"
                    disabled={targetCpa < minRange || targetCpa > maxRange}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToEditCampaign}
                    recommendation={recommendation}
                    textCode="performance-recommendations.set-cpa-specific.edit-campaign.button"
                    size={Button.size.lg}
                    dataMetricsComponent="RecommendationRibbonEditCampaignButton"
                />
            </ButtonsSection>
        </PerformanceRecommendationRibbon>
    );
};

const CPARibbonWithForm = withRibbonFormValidationDataProvider(CPARibbon);
export { CPARibbonWithForm as CPARibbon };
