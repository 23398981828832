import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../TooltipSection';

const PlatformTargetingTooltip = () => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.targeting.platform.help.overview"
                defaultMessage="Choose the platforms on which your ads will appear."
            />
        </TooltipSection>
        <TooltipSection
            tip={
                <FormattedMessage
                    id="campaign.targeting.platform.help.tip"
                    defaultMessage="consider setting up a separate campaign for each platform. This allows you to compare results and optimize performance."
                />
            }
        >
            <FormattedMessage
                id="campaign.targeting.platform.help.example"
                defaultMessage="For example, if you're offering a mobile app, it would make sense to target mobile devices only."
            />
        </TooltipSection>
    </div>
);

export default PlatformTargetingTooltip;
