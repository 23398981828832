import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useIsHeavyReport } from 'hooks';
import {
    addStaticIndication,
    removeStaticIndication,
    STATIC_INDICATION_TYPES,
} from 'modules/taboola-common-frontend-modules/Indications';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { REPORT_TYPE } from '../config';
import {
    AVAILABLE_BROWSER_OS_REPORT_START_DATE,
    AVAILABLE_MSV2_CREATIVE_REPORT_FILTERS_START_DATE,
    CATEGORY_REPORT_MISSING_DATA_END_DATE,
    CATEGORY_REPORT_MISSING_DATA_START_DATE,
    CONVERSION_DATA_EXISTS_FROM_DATE,
    getCategoryReportMissingDataIndication,
    getHeavyAccountReportFreshnessIndication,
    getPartialReportDataIndication,
    getReportDataUnavailableIndication,
    realtimeReportDisclaimer,
    reportHistoricalConversionsUnavailableIndication,
    reportsWithPartialData,
    siteReportMSV2Disclaimer,
    UNAVAILABLE_MSV2_CREATIVE_REPORT_FILTERS,
    USE_WIDE_PERFORMANCE_TABLE_REPORTS,
} from '../config/indications/staticIndications';
import { useReportFilters } from '../modules/campaigns-reports/hooks/useReportFilters';
import { useReportFiltersStatus } from '../modules/campaigns-reports/hooks/useReportFiltersStatus';
import { useRealtime } from './useRealtime';

const useCampaignReportStaticIndications = ({ selectedReport, startDate, endDate, reportConfig }) => {
    const dispatch = useDispatch();
    const isHeavyReport = useIsHeavyReport();
    const { isRealtimeActive } = useRealtime();
    const { reportFilters } = useReportFilters();
    const [isFilterActive] = useReportFiltersStatus();
    const isCategoryReportMissingDataIndicationEnabled = useConfigMatch({
        [FEATURE_FLAGS.SHOW_CATEGORY_REPORT_MISSING_DATA_INDICATION]: 'true',
    });
    const isWideConfigEnabledInCampaignReport = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
    });
    const isWideConfigEnabledInSiteReport = useConfigMatch({
        [FEATURE_FLAGS.SITE_REPORT_USE_WIDE_CONFIG_MSV2]: 'true',
    });

    const { [COMMON_FLAGS.WIDE_PERFORMANCE_TABLE_DATA_RETENTION_IN_MONTHS]: widePerformanceDataRetentionMonths } =
        useCommonConfig([COMMON_FLAGS.WIDE_PERFORMANCE_TABLE_DATA_RETENTION_IN_MONTHS]);
    const removeIndication = useCallback(
        staticIndicationType => dispatch(removeStaticIndication({ staticIndicationType })),
        [dispatch]
    );

    // Browser report indication
    useEffect(() => {
        const shouldShowIndication =
            reportsWithPartialData[selectedReport] &&
            moment(startDate).isBefore(AVAILABLE_BROWSER_OS_REPORT_START_DATE);

        if (!shouldShowIndication) {
            return;
        }

        dispatch(addStaticIndication(getPartialReportDataIndication()));
        return () => removeIndication(STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA);
    }, [dispatch, selectedReport, startDate, removeIndication]);

    // Category missed data indications
    useEffect(() => {
        const shouldShowIndication =
            isCategoryReportMissingDataIndicationEnabled &&
            selectedReport === REPORT_TYPE.CATEGORY &&
            moment(startDate).isBefore(CATEGORY_REPORT_MISSING_DATA_END_DATE) &&
            moment(endDate).isAfter(CATEGORY_REPORT_MISSING_DATA_START_DATE);
        if (!shouldShowIndication) {
            return;
        }
        dispatch(addStaticIndication(getCategoryReportMissingDataIndication()));
        return () => removeIndication(STATIC_INDICATION_TYPES.CATEGORY_REPORT_MISSING_DATA);
    }, [dispatch, selectedReport, startDate, endDate, isCategoryReportMissingDataIndicationEnabled, removeIndication]);

    //Heavy account report freshness indication
    useEffect(() => {
        if (!isHeavyReport) {
            return;
        }

        dispatch(addStaticIndication(getHeavyAccountReportFreshnessIndication(selectedReport)));
        return () => removeIndication(STATIC_INDICATION_TYPES.HEAVY_ACCOUNT_REPORT_FRESHNESS);
    }, [selectedReport, isHeavyReport, dispatch, removeIndication]);

    // Realtime disclaimer
    useEffect(() => {
        if (!isRealtimeActive) {
            return;
        }

        dispatch(addStaticIndication(realtimeReportDisclaimer));
        return () => removeIndication(STATIC_INDICATION_TYPES.REALTIME_REPORT_DISCLAIMER);
    }, [isRealtimeActive, dispatch, removeIndication]);

    // Ads report new performance table data availability indication
    useEffect(() => {
        const momentStartDate = moment(startDate);
        const dataUnavailableReasonStartPopulated = momentStartDate.isBefore(
            AVAILABLE_MSV2_CREATIVE_REPORT_FILTERS_START_DATE
        );
        const dataUnavailableReasonRetention = momentStartDate.isBefore(
            moment().subtract(widePerformanceDataRetentionMonths, 'months')
        );
        const shouldShowIndication =
            (selectedReport === REPORT_TYPE.CREATIVE ||
                (isWideConfigEnabledInCampaignReport &&
                    (selectedReport === REPORT_TYPE.DAY ||
                        selectedReport === REPORT_TYPE.WEEK ||
                        selectedReport === REPORT_TYPE.MONTH ||
                        selectedReport === REPORT_TYPE.DAY_OF_WEEK ||
                        selectedReport === REPORT_TYPE.CAMPAIGN ||
                        selectedReport === REPORT_TYPE.ACCOUNT ||
                        selectedReport === REPORT_TYPE.COUNTRY ||
                        selectedReport === REPORT_TYPE.REGION ||
                        selectedReport === REPORT_TYPE.PLATFORM ||
                        selectedReport === REPORT_TYPE.BROWSER ||
                        selectedReport === REPORT_TYPE.OS_FAMILY ||
                        selectedReport === REPORT_TYPE.OS_VERSION))) &&
            isFilterActive &&
            reportFilters &&
            reportFilters.length !== 0 &&
            reportFilters.some(({ type }) => UNAVAILABLE_MSV2_CREATIVE_REPORT_FILTERS.includes(type)) &&
            (dataUnavailableReasonRetention || dataUnavailableReasonStartPopulated);

        if (!shouldShowIndication) {
            return;
        }

        dispatch(
            addStaticIndication(
                getReportDataUnavailableIndication(dataUnavailableReasonRetention, widePerformanceDataRetentionMonths)
            )
        );
        return () => removeIndication(STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA);
    }, [
        dispatch,
        selectedReport,
        reportFilters,
        isFilterActive,
        startDate,
        removeIndication,
        isWideConfigEnabledInCampaignReport,
        widePerformanceDataRetentionMonths,
    ]);

    // History conversions is not available
    useEffect(() => {
        const shouldShowIndication =
            USE_WIDE_PERFORMANCE_TABLE_REPORTS.some(report => report === selectedReport) &&
            moment(startDate).isBefore(CONVERSION_DATA_EXISTS_FROM_DATE);
        if (!shouldShowIndication) {
            return;
        }
        dispatch(addStaticIndication(reportHistoricalConversionsUnavailableIndication));
        return () => removeIndication(STATIC_INDICATION_TYPES.PARTIAL_REPORT_DATA);
    }, [dispatch, selectedReport, startDate, removeIndication]);

    // Site MSV2 Disclaimer
    useEffect(() => {
        const shouldShowIndication =
            selectedReport === REPORT_TYPE.SITE &&
            isWideConfigEnabledInSiteReport &&
            moment(startDate).isBefore(AVAILABLE_MSV2_CREATIVE_REPORT_FILTERS_START_DATE);

        if (!shouldShowIndication) {
            return;
        }

        dispatch(addStaticIndication(siteReportMSV2Disclaimer));
        return () => removeIndication(STATIC_INDICATION_TYPES.SITE_MSV2_DISCLAIMER);
    }, [selectedReport, dispatch, removeIndication, isWideConfigEnabledInSiteReport, startDate]);
};

export default useCampaignReportStaticIndications;
