import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserMenu as ExternalUserMenu } from 'taboola-ultimate-ui';
import { DEFAULT_PLATFORM_TYPES } from '../../modules/campaigns/modules/common-campaign-form/config';
import { currentRootAccountSelector } from '../../selectors/currentRootAccount';
import { DefaultPlatform } from '../DefaultPlatform';
import styles from './userMenu.module.scss';

const isUserMigratedTaboolaAds = currentRootAccount =>
    currentRootAccount?.defaultPlatform === DEFAULT_PLATFORM_TYPES.TABOOLA_ADS;

const UserMenu = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [defaultPlatformHasError, setDefaultPlatformHasError] = useState(false);
    const currentRootAccount = useSelector(currentRootAccountSelector);

    const toggleMenu = useCallback(() => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    }, [setIsOpen]);
    const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
    const handleDefaultPlatformError = useCallback(
        () => setDefaultPlatformHasError(true),
        [setDefaultPlatformHasError]
    );

    const renderDefaultPlatformComponent = useMemo(() => {
        if (isUserMigratedTaboolaAds(currentRootAccount) || defaultPlatformHasError) {
            return null;
        }
        return () => <DefaultPlatform onError={handleDefaultPlatformError} />;
    }, [currentRootAccount, defaultPlatformHasError, handleDefaultPlatformError]);

    return (
        <div className={styles['container']}>
            <ExternalUserMenu
                onClick={toggleMenu}
                isOpen={isOpen}
                onClose={closeMenu}
                renderDefaultPlatformComponent={renderDefaultPlatformComponent}
                popupClassName={styles['userMenu']}
                {...props}
            />
        </div>
    );
};

UserMenu.propTypes = {
    ...ExternalUserMenu.propTypes,
};

export default UserMenu;
