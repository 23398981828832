import { SITE_EDITOR_TYPE } from 'modules/campaigns/config/siteConsts';
import { EXPLORATION_STATUS } from '../../../../common-campaign-form/components/SiteExploration/siteExplorationConstants';

export const siteStatusValueSetter = ({ newValue, data }) => {
    const { value, type } = newValue;
    if (!type) {
        return false;
    }
    if (type === SITE_EDITOR_TYPE.SAFETY_NETS_STATUS) {
        data.safetyNetsStatus = value;
    } else if (type === SITE_EDITOR_TYPE.EXPLORATION_STATUS && value === EXPLORATION_STATUS.STOPPED) {
        data.explorationStatus = value;
    }
    return true;
};
