import { useAiVariationsEventsPrefix } from 'modules/campaigns/modules/creative-creator/components/AiVariationsContextProvider/hooks/useAiVariationsEventsPrefix';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { getPageName } from 'modules/taboola-common-frontend-modules/gtmTracker/urlPageNameUtil';

export const useAiVariationsOnSuccess = () => {
    const eventsPrefix = useAiVariationsEventsPrefix();

    const onSuccess = ([results = {}] = []) => {
        const motionItems = results.scVideoItems || [];
        const imageItems = results.sponsoredItems || [];
        const allItems = [...motionItems, ...imageItems];
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: JSON.stringify(allItems.map(({ id: creativeId, campaignId }) => ({ creativeId, campaignId }))),
            component: `${eventsPrefix}_Create_Form`,
            pageName: getPageName(),
        });
    };
    return onSuccess;
};
