import { reduce } from 'lodash';
import { isCampaignTargetingField } from 'modules/campaigns/modules/common-campaign-form/utils/targetingRestrictions/isCampaignTargetingField';
import { CustomValidationError } from 'modules/errors/CustomValidationError';

export const isTargetingRestrictionEnforcedForAffiliate = (item, currentCampaignTargeting) => {
    return reduce(
        item.targetingRestrictions,
        (acc, restriction) => {
            const { isFieldValid, targetingField } = isCampaignTargetingField(restriction, currentCampaignTargeting);
            return {
                isFieldValid: acc.isFieldValid && isFieldValid,
                targetingField: isFieldValid ? acc.targetingField : [...acc.targetingField, targetingField],
            };
        },
        { isFieldValid: true, targetingField: [] }
    );
};

export const isSiteTargetingRestrictionEnforced = ({
    item,
    currentCampaignTargeting,
    field,
    affiliateIdsToUseOnlyPrefix,
}) => {
    if (
        field !== 'campaignTargeting.publisherTargeting.include' ||
        !item?.targetingRestrictions ||
        !currentCampaignTargeting
    ) {
        return true;
    }
    const { isFieldValid, targetingField } = isTargetingRestrictionEnforcedForAffiliate(item, currentCampaignTargeting);
    if (isFieldValid) {
        return isFieldValid;
    }
    const description = item?.accountDescription ?? '';
    const siteDescription = affiliateIdsToUseOnlyPrefix.includes(item?.accountId)
        ? description.split(' ')[0]
        : description;
    const targetingFieldDescription = targetingField.join(', ');
    const verb = targetingField.length > 1 ? 'are' : 'is';

    throw new CustomValidationError({
        messageCode: 'campaign.editor.targeting.site.unsupported.site.targeting',
        message: `Please note: ${targetingFieldDescription} targeting ${verb} not supported by ${siteDescription}`,
        templateParameters: { siteDescription, targetingFieldDescription, verb },
    });
};
