import React from 'react';
import formatNumberCompact from '../../../../campaigns/services/utils/formatNumberCompact';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import styles from './TopicArticlesHeader.module.scss';

export const TopicArticlesHeader = ({ topicName, sampleArticles, totalArticles }) => {
    const formattedSampleArticlesNumber = formatNumberCompact(sampleArticles, 2);
    const formattedTotalArticlesNumber = formatNumberCompact(totalArticles, 2);

    return (
        <div className={styles['header-container']}>
            <div className={styles['title']}>
                <FormattedMessage
                    id="custom.contextual.editor.topics.preview.articles.title"
                    defaultMessage="Article Samples | <strong>{topicName}</strong>"
                    values={{ topicName }}
                />
            </div>
            <FormattedMessage
                id="custom.contextual.editor.topics.preview.articles"
                defaultMessage="{formattedSampleArticlesNumber} articles out of {formattedTotalArticlesNumber}"
                values={{ formattedSampleArticlesNumber, formattedTotalArticlesNumber }}
                className={styles['text']}
            />
        </div>
    );
};
