import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { useFetchedCampaignGroup } from '../../CampaignGroupField/hooks/useFetchedCampaignGroup';

export const useCampaignBudgetRestrictions = () => {
    const {
        data: { campaignGroupId },
    } = useFormDataContext();
    const campaignGroup = useFetchedCampaignGroup(campaignGroupId);

    return {
        campaignGroup,
        campaignGroupBudget: campaignGroup?.budget,
    };
};
