"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSearch(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                fillRule: "nonzero",
                d: "M19.295 19.703a.989.989 0 0 0 1.412 0 1.02 1.02 0 0 0 0-1.43l-3.081-3.08a7.007 7.007 0 1 0-1.42 1.423l3.089 3.087zM7.002 11.007a5.005 5.005 0 1 1 10.01 0 5.005 5.005 0 0 1-10.01 0z"
            })
        )
    );
}
exports.default = SvgSearch;