import React, { ReactNode, FunctionComponent, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { defaultTheme } from './base';
import type { ITheme } from './base';

export type { Scale } from './base';
export type { ITheme };

export * from './types';

interface TuuiThemeProps {
    children: ReactNode;
    theme?: ITheme;
}

export { defaultTheme };
export const TuuiTheme: FunctionComponent<TuuiThemeProps> = ({ theme = defaultTheme, children }: TuuiThemeProps) => {
    const { icons } = theme;

    const muiTheme = useMemo(
        () =>
            createTheme({
                components: {
                    MuiIcon: icons,
                    MuiSvgIcon: icons,
                },
            }),
        [icons]
    );

    return (
        <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
                <>{children}</>
            </ThemeProvider>
        </MuiThemeProvider>
    );
};
