import { generatePath } from 'react-router';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from '../../../config/routes';
import { FORM_MODES } from '../../campaigns/config';
import { replacePathParams } from '../../campaigns/services/utils';
import { MODULE_NAME } from './module';
import { ONBOARDING_ENTITY } from './onboardingEntity';

const ENTITY_TO_PATH_MAP = {
    [ONBOARDING_ENTITY.ONBOARDING_CHAT]: 'onboarding',
    [ONBOARDING_ENTITY.ONBOARDING_JOURNEY]: 'onboarding-journey',
};
const KEY_TO_PATH_MAP_MAP = {
    module: {
        [MODULE_NAME]: MODULE_NAME,
    },
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const ONBOARDING_FORM_PATH = replacePathParams(
    BASE_FORM_ROUTE_PATH,
    {
        module: [MODULE_NAME],
        entity: [ONBOARDING_ENTITY.ONBOARDING_CHAT],
        mode: [FORM_MODES.CREATE],
    },
    {},
    KEY_TO_PATH_MAP_MAP
);

export const ONBOARDING_JOURNEY_PATH = replacePathParams(
    BASE_FORM_ROUTE_PATH,
    {
        module: [MODULE_NAME],
        entity: [ONBOARDING_ENTITY.ONBOARDING_JOURNEY],
        mode: [FORM_MODES.CREATE],
    },
    {},
    KEY_TO_PATH_MAP_MAP
);

export const ONBOARDING_FORM_REDIRECT_PATH = generatePath(ONBOARDING_FORM_PATH, {
    module: MODULE_NAME,
    entity: ENTITY_TO_PATH_MAP[ONBOARDING_ENTITY.ONBOARDING_CHAT],
    mode: FORM_MODE_TO_PATH_MAP[FORM_MODES.CREATE],
});
