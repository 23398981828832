import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const EXCEL_FILE_TYPE = { 'text/*': ['.xlsx'] };
export const EXCEL_MULTIPLE_FILES_INDICATION = {
    message: (
        <FormattedMessage
            id="excel.upload.validations.error.upload.too.many.files"
            defaultMessage="Multiple files were selected, please select a single Excel file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};
export const EXCEL_WRONG_FORMAT_INDICATION = {
    message: (
        <FormattedMessage
            id="excel.upload.validations.error.upload.wrong.format"
            defaultMessage="Wrong file format was selected, please add a single Excel file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};
export const ZIP_FILE_TYPE = { 'application/zip': ['.zip'] };
export const ZIP_MULTIPLE_FILES_INDICATION = {
    message: (
        <FormattedMessage
            id="excel.upload.validations.error.upload.too.many.files.zip"
            defaultMessage="Multiple files were selected, please select a single .zip file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};
export const ZIP_WRONG_FORMAT_INDICATION = {
    message: (
        <FormattedMessage
            id="excel.upload.validations.error.upload.wrong.format.zip"
            defaultMessage="Wrong file format was selected, please add a single .zip file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};
