import React from 'react';
import { TaboolaCustomHeader, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';

const CustomHeader = props => {
    const getContainerProps = headerProps => {
        const { column, displayName } = headerProps;
        // because the GA event fires before the prop can update, we set the value to the "next" sort so the right
        // value gets fired when clicked on
        let nextSort;
        switch (column.getSort()) {
            case SORTING_ORDER_TYPES.DESCENDANT:
                nextSort = 'ascending';
                break;
            case SORTING_ORDER_TYPES.ASCENDANT:
                nextSort = 'none';
                break;
            default:
                nextSort = 'descending';
        }

        return {
            'data-metrics-component': 'Column Sorting',
            'data-metrics-column-name': displayName,
            'data-metrics-value': nextSort,
            'data-metrics-event-name': GTM_EVENTS.USABILITY,
        };
    };
    return <TaboolaCustomHeader {...props} getContainerProps={getContainerProps} />;
};

export default CustomHeader;
