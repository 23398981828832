import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ChevronRightOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { useFormFieldValue, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { FormButtons } from '../../campaigns/components';
import { REPORT_TYPE } from '../../campaigns/config';
import AudienceFormBreadcrumbs from '../components/AudienceFormBreadcrumbs/AudienceFormBreadcrumbs';
import { MailDomainsPropertiesSection } from '../components/ExternalAudiencePropertiesSection/MailDomainsPropertiesSection/MailDomainsPropertiesSection';
import { SearchKeywordsPropertiesSection } from '../components/ExternalAudiencePropertiesSection/SearchKeywordsPropertiesSection/SearchKeywordsPropertiesSection';
import FirstPartyAudiencePropertiesSection from '../components/FirstPartyAudiencePropertiesSection/FirstPartyAudiencePropertiesSection';
import FirstPartyAudienceSetupSection from '../components/FirstPartyAudienceSetupSection/FirstPartyAudienceSetupSection';
import { CRM_FILE_TYPE } from '../config';
import firstPartyAudienceInitialValues from '../config/firstPartyAudienceInitialValues';
import { createAudience } from '../flows';
import useAudienceCreatorState from '../hooks/useAudienceCreatorState';
import styleConsts from '../../../globalStyle/styleConsts.module.scss';
import styles from './audiencesCreatorPage.module.scss';

const wizardTemplateColumns = `${styleConsts.leftPaneWidth}% ${100 - styleConsts.leftPaneWidth}%`;

const configs = {
    [CRM_FILE_TYPE.EMAIL]: <FirstPartyAudiencePropertiesSection />,
    [CRM_FILE_TYPE.DEVICE_ID]: <FirstPartyAudiencePropertiesSection />,
    [CRM_FILE_TYPE.SEARCH_KEYWORDS]: <SearchKeywordsPropertiesSection />,
    [CRM_FILE_TYPE.MAIL_DOMAINS]: <MailDomainsPropertiesSection />,
};
const FirstPartyAudiencesCreatorPage = ({ onCancel }) => {
    const dispatch = useDispatch();
    const reportId = REPORT_TYPE.FIRST_PARTY;
    const { value: crmFileType } = useFormFieldValue({ field: 'crmFileType' });

    const audiencePropertiesSection = configs[crmFileType];
    const submitAudience = useCallback(
        (audience, accountId) => dispatch(createAudience(accountId, audience, reportId)),
        [dispatch, reportId]
    );
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'audience.creator.new.first.party.audience',
        defaultMessage: 'New Customer File Audience',
    });

    const { step, setStep, submit } = useAudienceCreatorState({
        submitAudience,
    });

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={setStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="audience.creator.audience.settings.step.label"
                            defaultMessage="Audience Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <AudienceFormBreadcrumbs reportId={reportId} title={title} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="audience.creator.audience.setup.step.title"
                                    defaultMessage="Audience Setup"
                                />
                            }
                            id="audience-setup"
                        >
                            <FirstPartyAudienceSetupSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="audience.creator.audience.properties.step.title"
                                    defaultMessage="Audience Properties"
                                />
                            }
                            id="audience-properties"
                        >
                            {audiencePropertiesSection}
                            <div className={styles['buttons-wrapper']}>
                                <FormButtons
                                    className={styles['form-buttons']}
                                    onSubmit={submit}
                                    onCancel={onCancel}
                                    submitLabel={<FormattedMessage id="app.actionButtons.create.audience" />}
                                    submitIcon={<ChevronRightOutlinedIcon />}
                                    submitDataMetricsComponent="NextButton"
                                    cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                                />
                            </div>
                        </WizardStep>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const FirstPartyAudiencesCreatorPageWithFormDataProvider = withFormDataProvider(FirstPartyAudiencesCreatorPage, {
    defaultValuesConfig: firstPartyAudienceInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { FirstPartyAudiencesCreatorPageWithFormDataProvider as FirstPartyAudiencesCreatorPage };
