import React from 'react';
import classnames from 'classnames/bind';
import { useSelectedAccount, useAccountAdditionalData } from '../../../../hooks';
import { configMapValueTransformer } from '../../../taboola-common-frontend-modules/./account-configurations/services/transformers/ConfigValueTrasformer';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { PaymentMethodPan } from '../PaymentMethodPan/PaymentMethodPan';
import { PaymentMethodTan } from '../PaymentMethodTan/PaymentMethodTan';
import { PaymentMethodTaxRegistered } from '../PaymentMethodTaxRegistered/PaymentMethodTaxRegistered';
import { PaymentMethodVatId } from '../PaymentMethodVatId/PaymentMethodVatId';
import commonStyles from '../CardPaymentMethodCreator/cardPaymentMethodCreator.module.scss';

const classNameBuilder = classnames.bind(commonStyles);

export const PaymentMethodTaxSection = () => {
    const { value: isTaxRegistered } = useFormFieldValue({ field: 'taxData.isTaxRegistered' });
    const isWithTaxRegistration = useConfigMatch({ [FEATURE_FLAGS.IS_WITH_TAX_REGISTRATION]: 'true' });
    const [{ country }] = useSelectedAccount();
    const { accountAdditionalData: { legalEntity } = {} } = useAccountAdditionalData();
    const { [COMMON_FLAGS.COUNTRY_TO_LEGAL_ENTITIES_WITH_TAX_REGISTRATION]: countyToLegalEntitiesWithTaxRegistration } =
        useCommonConfig([COMMON_FLAGS.COUNTRY_TO_LEGAL_ENTITIES_WITH_TAX_REGISTRATION]);

    const transformedCountryToLegalEntitiesWithTaxRegistration = configMapValueTransformer(
        countyToLegalEntitiesWithTaxRegistration
    );

    const isCountryAndLegalEntityWithTaxRegistration = transformedCountryToLegalEntitiesWithTaxRegistration
        .get(country)
        ?.includes(legalEntity);

    const taxRegistrationRequired = isWithTaxRegistration || isCountryAndLegalEntityWithTaxRegistration;

    if (taxRegistrationRequired) {
        return (
            <>
                <PaymentMethodTaxRegistered />
                <div className={classNameBuilder('fields-container', 'tax-fields-container')}>
                    {isTaxRegistered && <PaymentMethodVatId isTaxRegistration={taxRegistrationRequired} />}
                    <PaymentMethodPan />
                    <PaymentMethodTan />
                </div>
            </>
        );
    }

    return <PaymentMethodVatId />;
};
