import { validationFunctions } from '../../taboola-common-frontend-modules/validations';

export const nameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        options: { prefix: 'prefix' },
        messageId: `validations.error.empty.field.name`,
        defaultMessage: 'Enter an {prefix} name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 'maxLen', prefix: 'prefix' },
        messageId: `validations.error.name.length`,
        defaultMessage: '{prefix} name can not exceed {maxLen} characters',
    },
];
