import { useMemo } from 'react';
import { isEqual, keys } from 'lodash';
import { PERMISSIONS_OPERATORS } from '../../authentication/constants';
import useAccountConfig from './useAccountConfig';
import useCommonConfig from './useCommonConfig';

const emptyObj = {};

const useConfigMatch = (
    expectedAccountConfigurations = emptyObj,
    expectedCommonConfigurations = emptyObj,
    operator = PERMISSIONS_OPERATORS.AND
) => {
    const accountConfigurationKeys = useMemo(
        () => keys(expectedAccountConfigurations),
        [expectedAccountConfigurations]
    );
    const commonConfigurationKeys = useMemo(() => keys(expectedCommonConfigurations), [expectedCommonConfigurations]);

    const accountConfigurationSubset = useAccountConfig(accountConfigurationKeys);
    const commonConfigurationSubset = useCommonConfig(commonConfigurationKeys);

    const configMatch = useMemo(
        () =>
            [
                isEqual(accountConfigurationSubset, expectedAccountConfigurations),
                isEqual(commonConfigurationSubset, expectedCommonConfigurations),
            ][operator](val => val),
        [
            accountConfigurationSubset,
            commonConfigurationSubset,
            expectedAccountConfigurations,
            expectedCommonConfigurations,
            operator,
        ]
    );

    return configMatch;
};

export default useConfigMatch;
