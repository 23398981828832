import React from 'react';
import { SpeakerIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FORM_MODES } from 'modules/campaigns/config';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FEATURE_FLAGS, useConfigMatch } from '../../../../../taboola-common-frontend-modules/account-configurations';
import { useCampaignGroupExtensionEnabled } from '../../hooks/useCampaignGroupExtensionEnabled';
import { CampaignsGroupMarketingObjective } from '../CampaignsGroupMarketingObjective/CampaignsGroupMarketingObjective';
import CampaignsGroupNameCreate from '../Name/CampaignsGroupNameCreate/CampaignsGroupNameCreate';
import { PoNumber } from '../PoNumber/PoNumber';

const CampaignsGroupSetupSection = () => {
    const { mode } = useFormDataContext();
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    const showCampaignGroupBaseInfo = campaignGroupExtensionEnabled && mode !== FORM_MODES.CREATE;

    const isPoNumberEnabled = useConfigMatch({
        [FEATURE_FLAGS.SHOW_PO_NUMBER]: 'true',
    });

    if (showCampaignGroupBaseInfo && !isPoNumberEnabled) {
        return null;
    }

    return (
        <CommonCollapsibleCard
            id="CAMPAIGNS_GROUP_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SpeakerIcon />}
                    headerText={
                        <FormattedMessage id="campaigns.group.editor.setup.full" defaultMessage="Group Setup" />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && <CampaignsGroupNameCreate />}
            {isPoNumberEnabled && <PoNumber />}
            <CampaignsGroupMarketingObjective />
        </CommonCollapsibleCard>
    );
};

export default CampaignsGroupSetupSection;
