import React from 'react';
import { FormLayout } from 'modules/campaigns/components';
import { useFormDataContext, withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormInitBase } from '../../taboola-common-frontend-modules/forms';
import ExternalMetadataSection from '../components/ExternalMetadataSection/ExternalMetadataSection';
import AccountEditorHeader from '../components/Header/AccountEditorHeader';
import { useAccountEditorState } from '../hooks/useAccountEditorState';
import styles from './accountEditorPage.module.scss';

const AccountEditorPage = ({ onCancel }) => {
    const { submit } = useAccountEditorState();
    const { isSubmitDisabled, submitInProgress, mode } = useFormDataContext();
    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                header={<AccountEditorHeader />}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['account-editor']}
                headerContainerClassName={styles['account-header']}
                bodyContainerClassName={styles['account-editor-body']}
                footerContainerClassName={styles['account-editor-footer']}
            >
                <ExternalMetadataSection />
            </FormLayout>
        </FormInitBase>
    );
};

const AccountEditorWithFormDataProvider = withFormDataProvider(AccountEditorPage, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { AccountEditorWithFormDataProvider as AccountEditorPage };
