import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const DisabledCreateVideoViaWochitTooltip = () => (
    <FormattedMessage
        id="creative.editor.tab.motion.ads.studiror"
        defaultMessage="Oops, something went wrong. Please contact our support team for further assistance"
    />
);

export default DisabledCreateVideoViaWochitTooltip;
