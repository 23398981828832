import React from 'react';
import classnames from 'classnames/bind';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ContentCtaDropdown } from './ContentCtaDropdown/ContentCtaDropdown';
import ContentCtaTooltip from './ContentCtaTooltip';
import styles from './contentCta.module.scss';
import commonStyles from './fields.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ContentCta = ({ className, labelClassName }) => (
    <FormField
        inputId="content-cta-type"
        containerClass={styles['container']}
        labelClass={classNameBuilder(commonStyles['header'], labelClassName)}
        label={<FormattedMessage id="creative.creator.content.cta.label" defaultMessage="CTA Button" />}
        helpText={<ContentCtaTooltip />}
    >
        <ContentCtaDropdown className={className} />
    </FormField>
);
export default ContentCta;
