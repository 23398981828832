import React, { useMemo } from 'react';
import { useStripeACHContext } from '../../../../taboola-common-frontend-modules/stripe/StripeACHContext';
import { PaymentContextProvider } from '../PaymentProvider/PaymentContext';

export const StripeACHPaymentProvider = ({ children }) => {
    const { isStripeValid, confirmSetup } = useStripeACHContext();

    const paymentContext = useMemo(
        () => ({
            isPaymentMethodValid: isStripeValid,
            generatePaymentMethodToken: confirmSetup,
        }),
        [confirmSetup, isStripeValid]
    );

    return <PaymentContextProvider value={paymentContext}>{children}</PaymentContextProvider>;
};
