import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';

const nameValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.campaignsGroup.name.empty',
        defaultMessage: 'Enter a Campaign Group Name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 200 },
        messageId: 'validations.error.campaignsGroup.name.length',
        defaultMessage: 'Campaign Group name can not exceed 200 characters',
    },
];

export default nameValidations;
