import React from 'react';
import { SpeakerIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CommonCollapsibleCard from '../../../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { useCampaignGroupExtensionEnabled } from '../../hooks/useCampaignGroupExtensionEnabled';
import { SharedBudgetCampaigns } from '../SharedBudgetCampaigns/SharedBudgetCampaigns';
import styles from './sharedBudgetCampaignsSection.module.scss';

export const SharedBudgetCampaignsSection = () => {
    const campaignGroupExtensionEnabled = useCampaignGroupExtensionEnabled();

    if (campaignGroupExtensionEnabled) {
        return null;
    }

    return (
        <div className={styles['container']}>
            <CommonCollapsibleCard
                id="CAMPAIGNS_GROUP_CAMPAIGNS"
                header={
                    <SectionHeader
                        headerIcon={<SpeakerIcon />}
                        headerText={<FormattedMessage id="campaigns.group.editor.sharedBudgetCampaigns" />}
                    />
                }
            >
                <SharedBudgetCampaigns />
            </CommonCollapsibleCard>
        </div>
    );
};
