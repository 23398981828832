import React, { useContext, useMemo } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication/hooks';
import { useBidModificationConfig } from '../../hooks/useBidModificationConfig';
import { computeBidModificationFromDecimal, roundToMillionths } from '../../utils/bidModificationUtils';
import { GridContext } from '../GridContextProvider/GridContextProvider';
import ModificationButtons from '../ModificationButtons';
import FormattedMessageCellRenderer from './FormattedMessageCellRenderer';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import styles from './bidModificationCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);

const ModificationPercent = ({ percent }) =>
    percent ? (
        // eslint-disable-next-line react/style-prop-object
        <FormattedNumberCellRenderer value={percent} style="percent" forceSign />
    ) : (
        <FormattedMessageCellRenderer value="app.campaigns.campaignsReports.bid.modification.default" />
    );

ModificationPercent.propTypes = {
    /* The percent as a decimal */
    percent: PropTypes.number,
};

const BidModificationCellRenderer = React.forwardRef(
    (
        {
            data,
            value,
            context,
            currencyRenderParams,
            bidDiff,
            setValue: setBidModification,
            getBidModificationValues,
            getNewBidModification,
        },
        ref
    ) => {
        const { isGridEditDisabled } = useContext(GridContext);
        const hasModificationPermissions = usePermissions('PUBLISHER_BID_ACTIONS') && !isGridEditDisabled;
        const {
            minBoost: minBidModificationBoost,
            maxBoost: maxBidModificationBoost,
            minBid,
        } = useBidModificationConfig();

        const modificationChangeHandler = newValue => {
            const newBidMod = getNewBidModification(newValue);

            setBidModification(roundToMillionths(newBidMod));
        };

        const { bidModification, decimalPercent, actualBid } = useMemo(
            () => getBidModificationValues(data, value),
            [getBidModificationValues, data, value]
        );
        const { bid } = data;

        const { shouldDisableBoost, shouldDisableDeboost } = useMemo(() => {
            const bidModOnBoost = roundToMillionths(decimalPercent + bidDiff);
            const bidModOnDeboost = roundToMillionths(decimalPercent - bidDiff);
            const bidOnDeboost = roundToMillionths(computeBidModificationFromDecimal(bidModOnDeboost) * bid);

            return {
                // disable up step if on boost, it will exceed the maxBidModification boost
                shouldDisableBoost: bidModOnBoost > maxBidModificationBoost,
                // disable down step if on deboost, it will go below the minBidModification boost OR if on deboost,
                // the bidMod * bid is less than the allowed minimum bid per account
                shouldDisableDeboost: bidModOnDeboost < minBidModificationBoost || bidOnDeboost < minBid,
            };
        }, [maxBidModificationBoost, minBidModificationBoost, decimalPercent, bid, minBid, bidDiff]);

        const currentBidIsInvalid = roundToMillionths(actualBid) < minBid;

        return (
            <div className={styles['main-container']} ref={ref}>
                <div
                    className={classNameBuilder('inner-container', 'left-container', {
                        error: currentBidIsInvalid,
                    })}
                >
                    <FormattedNumberCellRenderer {...currencyRenderParams} context={context} value={actualBid} />
                    <div className={styles['modification-percentage-container']}>
                        <ModificationPercent percent={decimalPercent} />
                    </div>
                </div>
                <div className={`${styles['inner-container']} ${styles['right-container']}`}>
                    <ModificationButtons
                        value={bidModification}
                        diff={bidDiff}
                        downDisabled={shouldDisableDeboost || !hasModificationPermissions}
                        upDisabled={shouldDisableBoost || !hasModificationPermissions}
                        setValue={modificationChangeHandler}
                    />
                </div>
            </div>
        );
    }
);

BidModificationCellRenderer.propTypes = {
    /** Value to display */
    value: PropTypes.number,
    /** value which is injected by the grid - Data of current grid row */
    data: PropTypes.object,
    /** injected by the grid - context of current grid */
    context: PropTypes.shape({
        bidModification: PropTypes.shape({
            minBidModificationBoost: PropTypes.number,
            maxBidModificationBoost: PropTypes.number,
        }),
    }),
    /** static currency params passed into the formatted number renderer */
    currencyRenderParams: PropTypes.object,
    /** The bid difference on changes */
    bidDiff: PropTypes.number,
    /** The ag-grid setValue function */
    setValue: PropTypes.func,
    /** Parses the current bid and bid modification values - render params */
    getBidModificationValues: PropTypes.func,
    /** Given modification value, returns value to set in data - from render params */
    getNewBidModification: PropTypes.func,
};

BidModificationCellRenderer.defaultProps = {
    bidDiff: 0.1,
};

export default BidModificationCellRenderer;
