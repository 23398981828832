import { queryParamHookFactory } from '../../query-params';
import { PERSISTENCE_TYPE } from '../../storage/persistenceType';
import { authenticationTypeStore } from './autheticationTypeStore';

export const AUTHENTICATION_TYPE_QUERY_PARAM = 'authenticationType';

export const useAuthenticationTypeInner = queryParamHookFactory(AUTHENTICATION_TYPE_QUERY_PARAM, {
    persist: PERSISTENCE_TYPE.MEMORY,
});

export const useAuthenticationType = () => {
    const [value, ...rest] = useAuthenticationTypeInner();
    authenticationTypeStore.setValue(value);

    return [value, ...rest];
};
