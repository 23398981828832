import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import { isEqual } from 'lodash';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { OnboardingStep } from '../OnboardingStep/OnboardingStep';
import { OnboardingCompletedDescription } from '../OnboardingStepDescription/OnboardingCompletedDescription';
import { STEP_IDS, STEP_NUMBER } from '../consts';
import { AddPaymentStep } from './OnboardingStepData/AddPaymentStep';
import { CompleteAccountStep } from './OnboardingStepData/CompleteAccountStep';
import { CreateCampaignStep } from './OnboardingStepData/CreateCampaignStep';
import { LaunchCampaignStep } from './OnboardingStepData/LaunchCampaignStep';
import { ReviewCampaignStep } from './OnboardingStepData/ReviewCampaignStep';
import styles from './onboardingStepInformation.module.scss';

const classNameBuilder = classnames.bind(styles);

const {
    ADD_PAYMENT_METHOD,
    COMPLETE_ACCOUNT_SETUP,
    CREATE_CAMPAIGN,
    LAUNCH_YOUR_CAMPAIGN,
    REVIEW_CAMPAIGN_PERFORMANCE,
} = STEP_IDS;

const getNextIncompleteStep = stepsProgressData => {
    // Reduce the array of step complete data to get the step name of the lowest ordered incomplete step if possible, return null otherwise.
    const lowestIncompleteStep = Object.keys(stepsProgressData).reduce((lowestIncompleteStepName, currStepName) => {
        const { progressPercent: currProgressPercent } = stepsProgressData[currStepName];
        const isCurrStepIncomplete = currProgressPercent !== 100;
        const isCurrStepLower = STEP_NUMBER[currStepName] < STEP_NUMBER[lowestIncompleteStepName];

        if (isCurrStepIncomplete && (lowestIncompleteStepName === null || isCurrStepLower)) {
            return currStepName;
        }

        return lowestIncompleteStepName;
    }, null);

    if (lowestIncompleteStep === null) {
        return 0;
    }
    return STEP_NUMBER[lowestIncompleteStep];
};

export const OnboardingStepInformation = ({ isCollapsed, stepsCompleteData, closeOnboardingAssistant }) => {
    const [step, setStep] = useState();
    const stepsProgressDataRef = useRef();
    const { additionalData, ...stepsProgressData } = stepsCompleteData;
    const { totalProgressPercent, completeReviewCampaign, lowestCreativesStatus } = additionalData;
    const { addPaymentData, completeAccountData, createCampaignData, launchCampaignData, reviewCampaignData } =
        stepsProgressData;
    const isOnboardingComplete = totalProgressPercent === 100;

    useEffect(() => {
        if (isEqual(stepsProgressData, stepsProgressDataRef.current)) {
            return;
        }
        setStep(getNextIncompleteStep(stepsProgressData));
        stepsProgressDataRef.current = stepsProgressData;
    }, [stepsProgressData]);

    return (
        <div className={styles['container']}>
            {isOnboardingComplete && (
                <OnboardingCompletedDescription
                    containerClassName={styles['completed-description-container']}
                    onClickCTA={closeOnboardingAssistant}
                    isCollapsed={isCollapsed}
                />
            )}
            <div className={styles['wizard-container']}>
                <Wizard
                    wizardContentClassName={styles['description']}
                    wizardNavigationClassName={styles['steps']}
                    className={classNameBuilder('step-container', { collapsed: isCollapsed })}
                    activeClassName={classNameBuilder({ collapsed: isCollapsed })}
                    stepClassName={styles['step']}
                    step={`${step}`}
                    onStepChange={setStep}
                    wizardTemplateColumns="45% 55%"
                    buttonNumberClassName={styles['display-number']}
                >
                    <WizardStep
                        label={
                            <OnboardingStep
                                titleId={CREATE_CAMPAIGN}
                                stepCompleteData={createCampaignData}
                                isCollapsed={isCollapsed}
                                isSelected={step === 2}
                            />
                        }
                        id={CREATE_CAMPAIGN}
                    >
                        <CreateCampaignStep isCollapsed={isCollapsed} stepCompleteData={createCampaignData} />
                    </WizardStep>
                    <WizardStep
                        label={
                            <OnboardingStep
                                titleId={COMPLETE_ACCOUNT_SETUP}
                                stepCompleteData={completeAccountData}
                                isCollapsed={isCollapsed}
                                isSelected={step === 1}
                            />
                        }
                        id={COMPLETE_ACCOUNT_SETUP}
                    >
                        <CompleteAccountStep isCollapsed={isCollapsed} stepCompleteData={completeAccountData} />
                    </WizardStep>
                    <WizardStep
                        label={
                            <OnboardingStep
                                titleId={ADD_PAYMENT_METHOD}
                                stepCompleteData={addPaymentData}
                                isCollapsed={isCollapsed}
                                isSelected={step === 0}
                            />
                        }
                        id={ADD_PAYMENT_METHOD}
                    >
                        <AddPaymentStep isCollapsed={isCollapsed} stepCompleteData={addPaymentData} />
                    </WizardStep>
                    <WizardStep
                        label={
                            <OnboardingStep
                                titleId={LAUNCH_YOUR_CAMPAIGN}
                                stepCompleteData={launchCampaignData}
                                isCollapsed={isCollapsed}
                                isSelected={step === 3}
                            />
                        }
                        id={LAUNCH_YOUR_CAMPAIGN}
                    >
                        <LaunchCampaignStep
                            isCollapsed={isCollapsed}
                            stepCompleteData={launchCampaignData}
                            lowestCreativesStatus={lowestCreativesStatus}
                        />
                    </WizardStep>
                    <WizardStep
                        label={
                            <OnboardingStep
                                titleId={REVIEW_CAMPAIGN_PERFORMANCE}
                                stepCompleteData={reviewCampaignData}
                                isCollapsed={isCollapsed}
                                isSelected={step === 4}
                            />
                        }
                        id={REVIEW_CAMPAIGN_PERFORMANCE}
                    >
                        <ReviewCampaignStep
                            isCollapsed={isCollapsed}
                            stepCompleteData={reviewCampaignData}
                            completeStep={completeReviewCampaign}
                        />
                    </WizardStep>
                </Wizard>
            </div>
        </div>
    );
};
