/**
 * Created by oreuveni
 * Date: 2018-12-27
 * Time: 17:32
 */
import { REQUEST_CREATE_CAMPAIGN, CREATE_CAMPAIGN_SUCCESS, CREATE_CAMPAIGN_ERROR } from './actionTypes';

export const requestCreateCampaign = () => ({ type: REQUEST_CREATE_CAMPAIGN });

export const createCampaignSuccess = campaign => ({ type: CREATE_CAMPAIGN_SUCCESS, payload: campaign });

export const createCampaignError = error => ({ type: CREATE_CAMPAIGN_ERROR, payload: error });
