import React from 'react';
import { CollapsibleList, FormField, InputGroupWithButton } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import PixelBasedAudienceUrlConditionsTooltip from './PixelBasedAudienceUrlConditionsTooltip';
import PixelBasedUrlConditionListItem from './PixelBasedUrlConditionListItem';
import usePixelBasedUrlConditions from './hooks/usePixelBasedUrlConditions';
import usePixelBasedUrlInputGroupConfig from './hooks/usePixelBasedUrlInputGroupConfig';
import styles from './pixelBasedAudienceUrlConditionsField.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const PixelBasedAudienceUrlConditionsField = () => {
    const { inputGroupConfig, predicate, url, setUrl } = usePixelBasedUrlInputGroupConfig();

    const {
        conditions,
        handleAddCondition,
        handleRemoveCondition,
        handleRemoveAllConditions,
        indicationData,
        scrollRef,
    } = usePixelBasedUrlConditions(predicate, url, setUrl);

    return (
        <FormField
            inputId="conditions"
            label={
                <FormattedMessage
                    id="audience.editor.pixel.based.audience.conditions.title"
                    defaultMessage="Conditions"
                />
            }
            helpText={<PixelBasedAudienceUrlConditionsTooltip />}
            ref={scrollRef}
        >
            <InputGroupWithButton
                config={inputGroupConfig}
                className={styles['container']}
                delimiterClassName={styles['delimiter']}
                buttonProps={{
                    onClick: handleAddCondition,
                    children: (
                        <FormattedMessage
                            id="audience.editor.pixel.based.audience.conditions.add.button"
                            defaultMessage="ADD"
                        />
                    ),
                }}
            />
            <CollapsibleListWithIndication
                id="pixel-based-audience-url-condition-list"
                items={conditions}
                ItemComponent={PixelBasedUrlConditionListItem}
                deleteItem={handleRemoveCondition}
                clearItems={handleRemoveAllConditions}
                listHeaderTitle={
                    <FormattedMessage
                        id="audience.editor.pixel.based.audience.conditions.list.title"
                        defaultMessage="Included Conditions"
                    />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </FormField>
    );
};

export default PixelBasedAudienceUrlConditionsField;
