import React from 'react';
import PropTypes from 'prop-types';
import PreviewItemLoading from './PreviewItemLoading';

const PreviewLoading = ({ className }) => (
    <div className={className} alt="Loading" role="progressbar">
        <PreviewItemLoading />
        <PreviewItemLoading />
    </div>
);

PreviewLoading.propTypes = {
    className: PropTypes.string,
};

export default PreviewLoading;
