import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './previewsDrawerCard.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PreviewsDrawerCard = ({
    titleText,
    bodyText,
    PreviewImages,
    onClick,
    bannerText,
    cardIcon,
    shouldDisableButton,
    imageSetNumber,
}) => {
    return (
        <div className={styles['card-container']}>
            <div className={styles['card-image-previews']}>
                {bannerText && <div className={classNameBuilder('card-overlay', 'banner')}> {bannerText} </div>}
                <PreviewImages imageSetNumber={imageSetNumber} />
                {cardIcon && <div className={classNameBuilder('card-overlay', 'icon')}>{cardIcon}</div>}
            </div>
            <div className={styles['card-body']}>
                <div className={styles['card-text']}>
                    <div className={styles['card-title']}> {titleText}</div>
                    <div> {bodyText}</div>
                </div>
                <div className={styles['card-button-container']}>
                    <Button
                        variant={Button.variant.primary}
                        className={classNameBuilder({ 'card-button': !shouldDisableButton })}
                        onClick={onClick}
                        disabled={shouldDisableButton}
                    >
                        <FormattedMessage id="previews.drawer.card.submit.button" defaultMessage="Try It Now" />
                    </Button>
                </div>
            </div>
        </div>
    );
};
