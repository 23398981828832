import React from 'react';
import classnames from 'classnames/bind';
import { PaginationBar } from 'taboola-ultimate-ui';
import { CookieConsent } from 'components/CookieConsent/CookieConsent';
import { useDimension } from 'hooks';
import { MeasuredByImgTag } from 'modules/campaigns/modules/creative-editor/components/MeasuredBy/MeasuredBy';
import oracleAdvertisingFooterSVG from 'modules/campaigns/modules/creative-editor/components/MeasuredBy/Oracle_Advertising_rgb_black.svg';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isIframeMode } from 'modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { isVideoCampaign } from 'services/campaignDimension';
import styles from './customPaginationBar.module.scss';

const classNameBuilder = classnames.bind(styles);

const CustomPaginationBar = ({ className, ...rest }) => {
    const [selectedCampaignDimension] = useDimension();

    return (
        <PaginationBar className={classNameBuilder(className, 'container')} {...rest}>
            {isVideoCampaign(selectedCampaignDimension) ? (
                <>
                    <MeasuredByImgTag img={oracleAdvertisingFooterSVG} />
                    <span className={styles['separator']}>|</span>
                </>
            ) : null}
            <div className={styles['note']}>
                <FormattedMessage id="app.campaigns.reports.datagrid.legal" />
                {isIframeMode() && <CookieConsent />}
            </div>
        </PaginationBar>
    );
};

export default CustomPaginationBar;
