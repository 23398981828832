import { generatePath } from 'react-router';
import { FORM_MODES } from 'config/formModes';
import {
    BASE_FORM_ROUTE_PATH,
    ENTITY_PARAM_NAME,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_NAME,
    MODULE_PARAM_NAME,
} from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { ACCOUNT_ID_PARAM_NAME, ACCOUNT_PARAM_TEMPLATE } from './account';
import { ENTITY, ENTITY_TO_PATH_MAP } from './entity';
import { MODULE_NAME } from './module';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
};

export const ACCOUNTS_EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}`;

export const ACCOUNT_EDITOR_ROUTE_PATH = replacePathParams(
    ACCOUNTS_EDITOR_ROUTE_PATH,
    {
        entity: [ENTITY.ACCOUNT],
        mode: [FORM_MODES.EDIT],
    },
    ['accountId'],
    KEY_TO_PATH_MAP_MAP
);

export const generateAccountFormPath = accountId => {
    const modePath = FORM_MODE_TO_PATH_MAP[FORM_MODES.EDIT];
    const entityPath = ENTITY_TO_PATH_MAP[ENTITY.ACCOUNT];
    const path = generatePath(ACCOUNTS_EDITOR_ROUTE_PATH, {
        [MODULE_PARAM_NAME]: MODULE_NAME,
        [ENTITY_PARAM_NAME]: entityPath,
        [MODE_PARAM_NAME]: modePath,
        [ACCOUNT_ID_PARAM_NAME]: accountId,
    });
    return path;
};
