import { creativesApi } from 'services/api';
import { GALLERY_ITEM_SOURCES } from '../components/MediaTabs/consts';
import { transformMediaResponseToMediaItems } from '../utils/fetchedMediaTransformationUtils';

export const fetchFavoritedMedia =
    ({ accountId, page, pageSize, contentType }) =>
    async () => {
        try {
            const {
                results: response,
                metadata: { total },
            } = await creativesApi.getFavoritedMedia({ accountId, page, pageSize, contentType });

            const results = transformMediaResponseToMediaItems(response, GALLERY_ITEM_SOURCES.FAVORITED_MEDIA);

            return [results, total];
        } catch (error) {
            return [error];
        }
    };
