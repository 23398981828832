import { HelpTooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { BetaTag } from '../../../../../../../../components';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { AddConversion } from '../../../AddConversionButton/AddConversionButton';
import { AddTrackingButton } from '../../../AddTrackingButton/AddTrackingButton';
import { getTagProps } from '../../../BidStrategy/SmartBidRadio/SmartBidRadio';
import styles from './targetCpaTitle.module.scss';

export const TargetCpaTitle = ({
    shouldDisableCpaOnDuplicate,
    isTargetCpaEligibleAccount,
    isEligibilityLabelsEnabled,
    isTargetCpaBeta,
    isEligibleCampaign,
    titleMsgId = 'campaign.editor.target.cpa.bid.checkbox.title',
    isPixelOrS2sInstalled = true,
    hasAtLeastOneActiveConversion = true,
    isTcpaPixelValidationEnabled,
}) => {
    const tagProps = getTagProps(
        isEligibilityLabelsEnabled,
        isTargetCpaBeta,
        isTargetCpaEligibleAccount,
        isEligibleCampaign
    );
    const shouldRenderBetaTag = tagProps !== false && isEligibilityLabelsEnabled;
    const shouldRenderMissingPixel =
        isTcpaPixelValidationEnabled && !isPixelOrS2sInstalled && !shouldDisableCpaOnDuplicate;
    const shouldRenderMissingActiveConversions =
        isTcpaPixelValidationEnabled && !shouldRenderMissingPixel && !hasAtLeastOneActiveConversion;

    return (
        <div className={styles['container']}>
            <FormattedMessage id={titleMsgId} defaultMessage="Set a target cost per action (CPA)" />
            {shouldDisableCpaOnDuplicate && (
                <HelpTooltip position={TOOLTIP_POSITION.RIGHT}>
                    <FormattedMessage
                        id="campaign.editor.target.cpa.bid.duplicate.tooltip"
                        defaultMessage="Target CPA is not supported on duplicated campaigns yet, use Smart Bid to maximize the campaign results instead."
                    />
                </HelpTooltip>
            )}
            {shouldRenderBetaTag && <BetaTag {...tagProps} />}
            {shouldRenderMissingPixel && (
                <HelpTooltip iconClassName={styles['help-tooltip']}>
                    <FormattedMessage
                        id="campaign.editor.target.cpa.bid.noPixelImplementation.tooltip"
                        values={{
                            addTracking: (
                                <AddTrackingButton
                                    id="campaign.editor.target.cpa.bid.noPixelImplementation.action"
                                    defaultMessage="Set up tracking"
                                />
                            ),
                        }}
                    />
                </HelpTooltip>
            )}
            {shouldRenderMissingActiveConversions && (
                <HelpTooltip iconClassName={styles['help-tooltip']}>
                    <FormattedMessage
                        id="campaign.editor.target.cpa.bid.noConversions.tooltip"
                        values={{
                            createConversion: (
                                <AddConversion
                                    id="campaign.editor.target.cpa.bid.noConversions.action"
                                    defaultMessage="Set up conversion event"
                                />
                            ),
                        }}
                    />
                </HelpTooltip>
            )}
        </div>
    );
};
