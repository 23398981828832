import patchOperation from '../patchOperation';

const allSitesApiPrefix = accountId => `/accounts/${accountId}/sites`;
const blockSiteAPIPrefix = accountId => `/${accountId}/blocked-sites`;

export const createSitesApi = ({ callGetApi, callPatchApi, callPostApi, queryClient }) => ({
    getAllSites: (accountId, searchText, page, pageSize, supplyGroup) =>
        queryClient.fetchQuery(['all-sites', page, pageSize, searchText, supplyGroup], () =>
            callGetApi(allSitesApiPrefix(accountId), {
                searchText,
                page,
                pageSize,
                paginationEnabled: true,
                supplyGroup,
            })
        ),
    checkValidSites: (accountName, affiliatesId, supplyGroup) => {
        const requestUrl = supplyGroup
            ? `${allSitesApiPrefix(accountName)}?supplyGroup=${supplyGroup}`
            : allSitesApiPrefix(accountName);
        return callPostApi(requestUrl, affiliatesId);
    },
    blockSite: (accountId, site) => callPatchApi(blockSiteAPIPrefix(accountId), { sites: [site] }, patchOperation.ADD),
    unblockSite: (accountId, site) =>
        callPatchApi(blockSiteAPIPrefix(accountId), { sites: [site] }, patchOperation.REMOVE),
    unblockSites: (accountId, sites) => callPatchApi(blockSiteAPIPrefix(accountId), { sites }, patchOperation.REMOVE),
});
