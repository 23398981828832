import React, { useState } from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { SettingsOutlinedIcon } from 'tuui';
import { HeadlessDropdown, pxToRem } from 'taboola-ultimate-ui';
import { useDropdownOptions } from 'hooks';
import { useAvailableLocales, useIntl, useLocale } from 'modules/taboola-common-frontend-modules/i18n';
import LocalesList from './LocalesList';
import OptionWrapper from './OptionWrapper';
import styles from './adminDropdown.module.scss';

const menuStyles = {
    right: 0,
    marginTop: pxToRem(styles['dropdown-marginTop']),
    color: styles['dropdown-color'],
    boxShadow: styles['dropdown-box-shadow'],
};

const routesTransformer = (routes, dep) =>
    routes
        .filter(route => route.hideNavItem && !route.hidden)
        .map(({ permissions, id, basePath = '', href = `${basePath}/${id}` }) => ({
            permissions,
            href: isFunction(href) ? href(dep) : href,
            value: id,
            label: id,
        }));

export const AdminDropdown = ({ availableRoutes }) => {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(false);
    const handleToggleDropdown = () => setOpen(!open);
    const sectionLabel = formatMessage({
        id: 'navigation.caption.label',
        defaultMessage: 'Navigation Menu',
    });
    const options = useDropdownOptions(availableRoutes, 'navigation.caption', routesTransformer);
    const [locale, setLocale] = useLocale();

    const availableLocales = useAvailableLocales();

    return (
        <div className={styles['container']} aria-label={sectionLabel}>
            <HeadlessDropdown
                width={styles['dropdown-width']}
                menuStyles={menuStyles}
                className={styles['dropdown']}
                options={options}
                onChange={() => {}}
                optionWrapper={OptionWrapper}
                value={{}}
                open={open}
                onToggleDropdown={handleToggleDropdown}
                menuListRenderer={params => (
                    <LocalesList
                        locale={locale}
                        setLocale={setLocale}
                        availableLocales={availableLocales}
                        {...params}
                    />
                )}
            >
                <div className={styles['icon-container']}>
                    <SettingsOutlinedIcon aria-label="admin-menu-icon" />
                </div>
            </HeadlessDropdown>
        </div>
    );
};

AdminDropdown.propTypes = { availableRoutes: PropTypes.array };

AdminDropdown.defaultProps = { availableRoutes: [] };

export default AdminDropdown;
