import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { INDICATION_TYPES, useDebouncedValue } from 'taboola-ultimate-ui';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useCropValidationSingleAspectRatio } from '../../hooks/useCropValidationSingleAspectRatio';
import getCroppedImg from '../../utils/getCropImage';
import { CroppingModalWarningBadge } from '../CroppingModalWarningBadge/CroppingModalWarningBadge';
import styles from './previewCroppedImage.module.scss';

const classNameBuilder = classnames.bind(styles);

const calculateClip = ratio => {
    const containerWidth = styles.defaultWidth;
    const containerHeight = styles.defaultHeight;

    const containerRatio = containerWidth / containerHeight;
    const imageRatio = ratio;

    let clipWidth, clipHeight, clipLeft, clipTop;

    // Check if the container is wider or taller than the image
    if (containerRatio > imageRatio) {
        // Container is wider, adjust height
        clipHeight = containerHeight;
        clipWidth = containerHeight * imageRatio;
        clipLeft = (containerWidth - clipWidth) / 2;
        clipTop = 0;
    } else {
        // Container is taller, adjust width
        clipWidth = containerWidth;
        clipHeight = containerWidth / imageRatio;
        clipTop = (containerHeight - clipHeight) / 2;
        clipLeft = 0;

        if (ratio === '2:1') {
            const idealClipHeight = containerWidth / 2;
            const diff = clipHeight - idealClipHeight;
            clipTop -= diff / 2;
        }
    }

    return `polygon(${clipLeft}px ${clipTop}px, ${clipLeft + clipWidth}px ${clipTop}px, ${clipLeft + clipWidth}px ${
        clipTop + clipHeight
    }px, ${clipLeft}px ${clipTop + clipHeight}px)`;
};

export const PreviewCroppedImage = ({
    src,
    ratio = 16 / 9,
    isActive = true,
    onClick,
    ratioName,
    shouldShowOutcropWarning,
    index,
}) => {
    const [croppedImage, setCroppedImage] = useState(null);
    const isComponentUnmounted = useRef(false);

    const { [COMMON_FLAGS.CROPPING_SIGNIFICANT_CHANGE_THRESHOLD_KEY]: significantChangeThreshold } = useCommonConfig([
        COMMON_FLAGS.CROPPING_SIGNIFICANT_CHANGE_THRESHOLD_KEY,
    ]);
    const {
        formAccount: { accountId },
    } = useFormDataContext();

    const failedValidation = useCropValidationSingleAspectRatio(src, index, accountId, significantChangeThreshold);

    // BUG: this should be value: rotation, however, rotation is not implemented yet, thus
    // I'd rather not add a possible regression by fixing this as its a dependency of the
    // getCroppedImg function
    const { rotation } = useFormFieldValue({ field: 'rotation' });
    const { value: croppedAreaPixels } = useFormFieldValue({ field: 'croppedAreaPixels' });
    const { message: errorMessage, indicationType } = failedValidation || {};

    const showWarningIndication = !isActive && indicationType === INDICATION_TYPES.WARNING;

    const croppedAreaPixelsDebounced = useDebouncedValue(croppedAreaPixels);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(src, croppedAreaPixelsDebounced, rotation);

            // prevents error when image does not finish loading before component is unmounted
            if (!isComponentUnmounted.current) {
                setCroppedImage(croppedImage);
            }
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixelsDebounced, rotation, src]);

    useEffect(() => {
        showCroppedImage();
    }, [showCroppedImage]);

    useEffect(
        () => () => {
            isComponentUnmounted.current = true;
        },
        []
    );

    return (
        <div className={styles['wrapper']}>
            <div className={styles['container']} onClick={() => onClick(ratio)} aria-label={`preview of ${ratioName}`}>
                <div className={styles['image-container']}>
                    <img
                        className={classNameBuilder('image', { 'image-active': isActive })}
                        src={croppedImage || src}
                        alt="cropped"
                        style={
                            !croppedImage
                                ? {
                                      position: 'absolute',
                                      clipPath: calculateClip(ratio),
                                      objectFit: 'cover',
                                  }
                                : {
                                      objectFit: 'contain',
                                  }
                        }
                    />
                </div>
                <div
                    className={classNameBuilder({
                        'active-border': isActive,
                        'warning-border': showWarningIndication,
                    })}
                />
                {(showWarningIndication || shouldShowOutcropWarning) && (
                    <CroppingModalWarningBadge
                        className={styles['badge-container']}
                        errorMessage={errorMessage}
                        shouldShowOutcropWarning={shouldShowOutcropWarning}
                    />
                )}
            </div>
            <span>{ratioName}</span>
        </div>
    );
};
