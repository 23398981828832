import React from 'react';

export const AmericanExpressLogo = () => {
    return (
        <svg width="56" height="32" viewBox="0 0 56 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2005_151505)">
                <path
                    d="M10.7737 11.1176H8.99481L8.07562 13.1567H1L7.36781 0H12.5245L14.6647 4.47178V0H21.6029L22.9993 3.20769L24.3204 0H45.2452L46.5986 1.72216L48.0004 0H56.1308L50.6496 6.37704L56.4422 13.1567H48.0783L46.458 11.1522L44.8065 13.1567H11.6601L10.7737 11.1176Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.37551 7.61703L9.90203 4.25701H9.93486L11.4121 7.61703H8.37551ZM8.62176 2L4.18994 11.1567H6.78338L7.70257 9.1176H12.0851L12.9715 11.1567H15.647L11.2644 2H8.62176ZM16.6648 2V11.1567H19.0776V4.73162H19.1105L21.9829 11.1567H23.969L26.8415 4.6675H26.8743V11.1567H29.2872V2H25.6597L23.0663 8.29683H23.0334L20.2923 2H16.6648ZM31.5523 2V11.1567H40.4488V9.46386H34.1293V7.21957H39.8086V5.65498H34.1293V3.69283H40.3174V2H31.5523ZM45.0611 6.36033L40.974 11.1567H43.8629L46.4728 7.98904L49.0334 11.1567H52.1028L48.0157 6.37316L51.7745 2H48.9513L46.5712 4.92399L44.2733 2H41.2859L45.0611 6.36033Z"
                    fill="#379BD7"
                />
            </g>
            <defs>
                <clipPath id="clip0_2005_151505">
                    <rect width="56" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
