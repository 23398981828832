import VIDEO_PMP_IMPRESSION_TYPE from '../videoPMPImpressionType';
import VIDEO_PRICING_MODEL from '../videoPricingModel';
import { videoCampaignInitialValues } from './videoCampaignInitialValues';

export const pmpDealVideoCampaignInitialValues = {
    ...videoCampaignInitialValues,
    campaignTargeting: {
        ...videoCampaignInitialValues.campaignTargeting,
        viewabilityTargeting: 'OVER_40_PERCENT',
    },
    videoBudget: {
        businessModel: VIDEO_PRICING_MODEL.CPM_EXTERNAL,
        impressionType: VIDEO_PMP_IMPRESSION_TYPE.VIDEO,
        dailyImpressionCap: '',
        rate: '',
        totalImpressionCap: '',
    },
};
