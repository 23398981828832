import { useMemo } from 'react';
import { isValidItem } from '../utils/isValidItem';

export const useInvalidSites = (sites, resourcesById, isAllowedSitesReady) => {
    const invalidList = useMemo(
        () =>
            isAllowedSitesReady ? sites.filter(site => !isValidItem({ item: site, allowedSites: resourcesById })) : [],
        [sites, resourcesById, isAllowedSitesReady]
    );

    return invalidList;
};
