import { useCallback } from 'react';
import { useStripeACHContext } from '../StripeACHContext';

export const useStripePaymentElement = stripeElementId => {
    const { updateElementState } = useStripeACHContext();

    const onChange = useCallback(
        ({ empty, complete, error = null }) => {
            updateElementState(stripeElementId, { empty, complete, error, dirty: true });
        },
        [stripeElementId, updateElementState]
    );

    return { onChange };
};
