import React, { useEffect, useMemo, useCallback } from 'react';
import { CheckboxGroup, FormField } from 'taboola-ultimate-ui';
import useFormFieldValue from '../../../../../taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { DUPLICATE_CAMPAIGN_SETTINGS_TYPE } from '../../config';
import BID_STRATEGIES from '../../config/bidStrategies';
import { useBidStrategyFormField } from '../BidStrategyV2/hooks/useBidStrategyFormField';
import AdsSetting from './AdsSetting/AdsSetting';
import BidAdjustmentsSetting from './BidAdjustmentsSetting/BidAdjustmentsSetting';
import BlockedPublishersSetting from './BlockedPublishersSetting/BlockedPublishersSetting';
import ThirdPartyTrackingSetting from './ThirdPartyTrackingSetting/ThirdPartyTrackingSetting';
import styles from './duplicateCampaignSettings.module.scss';

const DuplicateCampaignSettings = () => {
    const { value: includeCreatives, onChange: onChangeIncludeCreatives } = useFormFieldValue({
        field: 'duplicateCampaignSettings.includeCreatives',
    });
    const { value: includeBlockedPublishers, onChange: onChangeIncludeBlockedPublishers } = useFormFieldValue({
        field: 'duplicateCampaignSettings.includeBlockedPublishers',
    });
    const { value: includePublisherBidModifiers, onChange: onChangeIncludeBidModifiers } = useFormFieldValue({
        field: 'duplicateCampaignSettings.includePublisherBidModifiers',
    });
    const { value: includeThirdPartyTracking, onChange: onChangeIncludeThirdPartyTracking } = useFormFieldValue({
        field: 'duplicateCampaignSettings.includeThirdPartyTracking',
    });
    const { isEqualToInitial: isBlockedPublishersInitial } = useFormFieldValue({
        field: 'campaignTargeting.publisherTargeting.exclude',
    });
    const { value: bidStrategy, isDirty: isBidStrategyDirty } = useBidStrategyFormField();

    const selectedSettings = useMemo(() => {
        const settings = [];
        if (includeCreatives) {
            settings.push(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.ADS);
        }
        if (includeThirdPartyTracking) {
            settings.push(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.TRACKING_CODE_THIRD_PARTY_PIXEL);
        }
        if (includePublisherBidModifiers) {
            settings.push(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.BID_ADJUSTMENTS);
        }
        if (includeBlockedPublishers) {
            settings.push(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.SITE_BLOCKS);
        }
        return settings;
    }, [includeCreatives, includePublisherBidModifiers, includeBlockedPublishers, includeThirdPartyTracking]);

    const handleToggleSetting = useCallback(
        (setting, checked) => {
            switch (setting) {
                case DUPLICATE_CAMPAIGN_SETTINGS_TYPE.ADS: {
                    onChangeIncludeCreatives(checked);
                    break;
                }
                case DUPLICATE_CAMPAIGN_SETTINGS_TYPE.SITE_BLOCKS: {
                    onChangeIncludeBlockedPublishers(checked);
                    break;
                }
                case DUPLICATE_CAMPAIGN_SETTINGS_TYPE.BID_ADJUSTMENTS: {
                    onChangeIncludeBidModifiers(checked);
                    break;
                }
                case DUPLICATE_CAMPAIGN_SETTINGS_TYPE.TRACKING_CODE_THIRD_PARTY_PIXEL: {
                    onChangeIncludeThirdPartyTracking(checked);
                    break;
                }
                default:
                    break;
            }
        },
        [
            onChangeIncludeCreatives,
            onChangeIncludeBlockedPublishers,
            onChangeIncludeBidModifiers,
            onChangeIncludeThirdPartyTracking,
        ]
    );

    const handleSelectSetting = useCallback(
        setting => {
            handleToggleSetting(setting, true);
        },
        [handleToggleSetting]
    );

    const handleUnselectSetting = useCallback(
        setting => {
            handleToggleSetting(setting, false);
        },
        [handleToggleSetting]
    );

    const handleBlockedPublishersChange = useCallback(() => {
        if (!isBlockedPublishersInitial) {
            handleUnselectSetting(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.SITE_BLOCKS);
        }
    }, [isBlockedPublishersInitial, handleUnselectSetting]);

    const isBidAdjustmentAllowed =
        bidStrategy !== BID_STRATEGIES.MAX_CONVERSIONS && bidStrategy !== BID_STRATEGIES.TARGET_CPA;

    useEffect(() => {
        handleBlockedPublishersChange();
    }, [handleBlockedPublishersChange]);

    useEffect(() => {
        if (isBidStrategyDirty && !isBidAdjustmentAllowed) {
            handleUnselectSetting(DUPLICATE_CAMPAIGN_SETTINGS_TYPE.BID_ADJUSTMENTS);
        }
    }, [handleUnselectSetting, isBidAdjustmentAllowed, isBidStrategyDirty]);

    return (
        <FormField
            inputId="duplicate-campaign-settings"
            label={<FormattedMessage id="campaign.editor.duplicateCampaignSettings.label" defaultMessage="Settings" />}
            description={
                <FormattedMessage
                    id="campaign.editor.duplicateCampaignSettings.description"
                    defaultMessage="Choose the setting you would like to duplicate for this campaign."
                />
            }
        >
            <CheckboxGroup
                id="duplicate-campaign-settings"
                className={styles['group']}
                selectedValues={selectedSettings}
                onSelectCheckbox={handleSelectSetting}
                onUnselectCheckbox={handleUnselectSetting}
                data-metrics-component="DuplicateCampaignSettings"
            >
                <AdsSetting checked={includeCreatives} />
                {isBidAdjustmentAllowed && <BidAdjustmentsSetting />}
                <BlockedPublishersSetting checked={includeBlockedPublishers} />
                <ThirdPartyTrackingSetting checked={includeThirdPartyTracking} />
            </CheckboxGroup>
        </FormField>
    );
};

export default DuplicateCampaignSettings;
