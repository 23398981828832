import { useMemo } from 'react';
import useAvailableItems from 'hooks/useAvailableItems';
import { useRealtime } from 'modules/campaigns/hooks';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { emptyReportFilters } from '../../../hooks/useReportFilters';

const useConfigFilters = reportConfig => {
    const { formatMessage } = useIntl();
    const selectedAccount = useAccount();
    const { isRealtimeActive } = useRealtime();
    const { initialFilters = emptyReportFilters, filters: allConfigFilters = emptyReportFilters } = reportConfig;
    const requiredFiltersSet = useMemo(
        () => new Set(allConfigFilters.filter(({ required }) => required).map(({ id }) => id)),
        [allConfigFilters]
    );

    const permittedFilters = useAvailableItems(allConfigFilters, { selectedAccount, isRealtimeActive });
    const permittedInitialFilters = useAvailableItems(initialFilters, { selectedAccount, isRealtimeActive });
    const mappedInitialFilters = useMemo(
        () =>
            permittedInitialFilters.map(({ type, values }) => ({
                type,
                values: values.map(({ value, label, messageId }) => ({
                    value,
                    label: formatMessage ? formatMessage({ id: messageId, defaultMessage: label }) : label,
                })),
            })),
        [permittedInitialFilters, formatMessage]
    );

    return {
        initialFilters: mappedInitialFilters,
        requiredFiltersSet,
        permittedFilters,
    };
};

export default useConfigFilters;
