import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

const emptyColumns = [
    FIELDS.IS_ACTIVE,
    FIELDS.ID,
    FIELDS.THUMBNAIL_URL,
    FIELDS.THUMBNAIL_URL_COMFORTABLE,
    FIELDS.CONTENT,
    FIELDS.TITLE,
    FIELDS.URL,
    FIELDS.STATUS,
    FIELDS.CTA,
    FIELDS.DESCRIPTION,
    FIELDS.ACTIONS_COLUMN,
].map(createFooterMapper(REPORT_TYPE.CREATIVE_REALTIME));

const creativeRealtimeReportFooterColumnDefinition = [...emptyColumns];

export default creativeRealtimeReportFooterColumnDefinition;
