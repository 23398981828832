import { values } from 'lodash';
import { REPORT_PRESET } from '../config/pickers';

const staticReportPresetSet = new Set(values(REPORT_PRESET));

export const isCustomReportPreset = presetName => {
    const isStaticReportPreset = staticReportPresetSet.has(presetName);

    return !isStaticReportPreset;
};
