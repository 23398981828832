import { generatePath } from 'react-router';
import { InternalLink } from '../../../../components';
import { FORM_MODES } from '../../../../config/formModes';
import {
    ENTITY_PARAM_NAME,
    FORM_MODE_TO_PATH_MAP,
    MODE_PARAM_NAME,
    MODULE_PARAM_NAME,
} from '../../../../config/routes';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import {
    ACCOUNT_ID_PARAM_NAME,
    AUDIENCE_ID_PARAM_NAME,
    EDITOR_ROUTE_PATH,
    ENTITY,
    ENTITY_TO_PATH_MAP,
    MODULE_NAME,
} from '../../config';
import styles from './customAudiencesRejectedStatusTooltip.module.scss';

export const CustomAudiencesRejectedStatusTooltip = ({ id, ...rest }) => {
    const account = useAccount();
    const { accountName } = account;

    const customAudienceEditorPagePath =
        generatePath(EDITOR_ROUTE_PATH, {
            [MODULE_PARAM_NAME]: MODULE_NAME,
            [ENTITY_PARAM_NAME]: ENTITY_TO_PATH_MAP[ENTITY.FIRST_PARTY],
            [MODE_PARAM_NAME]: FORM_MODE_TO_PATH_MAP[FORM_MODES.EDIT],
            [ACCOUNT_ID_PARAM_NAME]: accountName,
            [AUDIENCE_ID_PARAM_NAME]: id,
        }) + '?reportId=custom-audiences';

    return (
        <div className={styles['content']}>
            <FormattedMessage
                id="report.audienceStatus.reject.reason"
                defaultMessage="<b>Rejected due to policy restrictions</b>"
            />
            <FormattedMessage
                id="report.audienceStatus.reject.note"
                defaultMessage="This audience segment contains words that do not meet policy restrictions. Please ensure compliance by reviewing and correcting any problematic words.{newline}"
            />
            <InternalLink
                className={styles['link-container']}
                to={customAudienceEditorPagePath}
                rel="noopener noreferrer"
                {...rest}
            >
                <div className={styles['children']}>
                    <FormattedMessage
                        id="report.audienceStatus.reject.editPathTitle"
                        defaultMessage="Edit your audience"
                    />
                </div>
            </InternalLink>
        </div>
    );
};
