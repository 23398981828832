import { useCallback } from 'react';
import { useCurrentValueGetter } from 'hooks';
import { useAccount } from '../../account-configurations/hooks';
import { useAsyncAccountGetter } from './useAsyncAccountGetter';

export const useContextAccountsData = () => {
    const account = useAccount();
    const accountGetter = useCurrentValueGetter(account);
    const getAsyncAccount = useAsyncAccountGetter();

    const getAccountsData = useCallback(async () => {
        const isAccountFetched = accountGetter()?.isAccountFetched;
        const account = isAccountFetched ? accountGetter() : await getAsyncAccount(accountGetter()?.id);

        const accountName = account?.accountName;
        const globalAccountName = account?.accountName;

        return { accountName, globalAccountName };
    }, [accountGetter, getAsyncAccount]);

    return getAccountsData;
};
