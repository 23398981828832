import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ConversionGoalTitle = ({ hasOptions }) => {
    const events = hasOptions ? 'events' : 'noEvents';

    return (
        <FormattedMessage
            id={`campaign.editor.conversion.goal.conversion.${events}`}
            defaultMessage="Conversion events (No conversions)"
        />
    );
};
