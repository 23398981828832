import React from 'react';
import { FormField, Input, InputTypes, TooltipV2 as Tooltip, TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { CAMPAIGN_STATUS } from 'modules/campaigns/config';
import { withIndication } from 'modules/errors/components/withIndication';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { CAMPAIGN_DIMENSION } from 'services/campaignDimension';
import { FORM_MODES } from '../../../../config';
import BrandingTooltip from './BrandingTooltip';
import styles from '../commonEditor.module.scss';

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.campaign.branding.empty',
        defaultMessage: 'Enter a Brand Name',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 200 },
        messageId: 'validations.error.campaign.branding.moreThen',
        defaultMessage: 'Branding Text can not exceed 200 characters',
    },
];

const Branding = () => {
    const {
        value: brandingText = '',
        onChange: onBrandingTextChange,
        mode,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'brandingText',
        validations,
        throttleValue: true,
    });
    const { value: status } = useFormFieldValue({ field: 'status' });
    const { value: dimension } = useFormFieldValue({ field: 'dimension' });
    const isDuplicateMode = mode === FORM_MODES.DUPLICATE;
    const isPermitted = usePermissions('CAMPAIGN_ADMIN_EDIT');
    const isCampaignRunning =
        !!status &&
        status !== CAMPAIGN_STATUS.PENDING_APPROVAL &&
        status !== CAMPAIGN_STATUS.PENDING_START_DATE &&
        status !== CAMPAIGN_STATUS.REJECTED;
    const isCampaignAlwaysEditable = dimension === CAMPAIGN_DIMENSION.SPONSORED;
    const changeHandler = e => onBrandingTextChange(e.target.value);

    const disabled = !isDuplicateMode && !isPermitted && isCampaignRunning && !isCampaignAlwaysEditable;

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'campaign.branding.placeholder',
        defaultMessage: 'Enter Brand Name',
    });
    return (
        <FormField
            ref={scrollRef}
            inputId="branding"
            containerClass={styles['input']}
            label={<FormattedMessage id="campaign.branding" defaultMessage="Branding" />}
            description={
                <FormattedMessage
                    id="campaign.branding.description"
                    defaultMessage="The name of your product, brand or website."
                />
            }
            subDescription={
                <FormattedMessage
                    id="campaign.branding.sub.description"
                    defaultMessage="This name will appear in your ad, below the title."
                />
            }
            helpText={<BrandingTooltip />}
        >
            <div className={styles['inline']}>
                <InputWithIndication
                    id="branding"
                    placeholder={placeholder}
                    type={InputTypes.TEXT}
                    value={brandingText}
                    onChange={changeHandler}
                    disabled={disabled}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="Branding"
                    inputWrapperClass={styles['inline']}
                    mainClass={styles['inline']}
                    {...indicationData}
                />
                {disabled && (
                    <Tooltip arrow position={TOOLTIP_POSITION.RIGHT}>
                        <FormattedMessage id="campaign.branding.disabled.tooltip" />
                    </Tooltip>
                )}
            </div>
        </FormField>
    );
};

export default Branding;
