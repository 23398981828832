import { cloneDeep, get, intersection, isArray, isEmpty, isNil, keys, map, unset } from 'lodash';
import { throwCriticalError } from 'modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';
import { ExcelBulkError } from '../transformers/ExcelBulkError';
import { getColumnField } from './excelBulkWorkbookUtils';

const removeAllEmptyObjectsKeys = obj => {
    keys(obj).forEach(key => {
        const value = obj[key];

        if (typeof value !== 'object' || value === null || value instanceof Array) {
            return;
        }

        if (isEmpty(value)) {
            unset(obj, key);
            return;
        }

        removeAllEmptyObjectsKeys(value);

        // when after processing of all children parent gets empty we should remove it
        if (isEmpty(obj[key])) {
            unset(obj, key);
        }
    });

    return obj;
};

export const hasPojoField = (entityData, field) => {
    const fieldAsArray = typeof field === 'string' ? field.split('.') : field;
    do {
        const value = get(entityData, fieldAsArray);
        if (value !== undefined) {
            return true;
        }
        fieldAsArray.pop();
    } while (!isEmpty(fieldAsArray));
    return false;
};

const unsetWithParent = (data, field) => {
    unset(data, field);

    const fieldAsArray = isArray(field) ? field : field.split('.');

    while (!isEmpty(fieldAsArray)) {
        fieldAsArray.pop();
        const value = get(data, fieldAsArray);
        if (value === null) {
            unset(data, fieldAsArray);
            break;
        }
        if (!isNil(value)) {
            break;
        }
    }
};

const getMissedFieldsData = (entityData, columns, omitFields) => {
    const clonedData = cloneDeep(entityData);

    columns.forEach(column => unsetWithParent(clonedData, getColumnField(column)));
    omitFields.forEach(field => unsetWithParent(clonedData, field));

    const cleanObject = removeAllEmptyObjectsKeys(clonedData);

    return cleanObject;
};

export const verifyMissedFields = ({ entityData, entity, columns, omitFields = [] }) => {
    const missedFieldsData = getMissedFieldsData(entityData, columns, omitFields);

    if (!isEmpty(missedFieldsData)) {
        throwCriticalError(
            new ExcelBulkError(
                `There are missed POJO fields in excel bulk config - ${entity.toLowerCase()}Config.js.
                             Please double check if each field definitely should be included into POJO, if no remove it on GW side. 
                             If field should be included consider on add it to excelColumns.js and update config accordingly,
                             If a field is not intended for export to Excel, ensure it's added to the 'neverUploadPOJOFields' list (${entity.toLowerCase()}NeverUploadPOJOFields.js).
                             Missed data:
                             ${JSON.stringify(missedFieldsData, null, 2)}`
            )
        );
    }
};

export const verifyEntityConfigConsistency = (entityConfig, entityData) => {
    const configCollisionFields = intersection(
        entityConfig.neverUploadPOJOFields || [],
        map(entityConfig.columns, 'field')
    );

    if (!isEmpty(configCollisionFields)) {
        throwCriticalError(
            new ExcelBulkError(
                `There are fields that are defined in both neverUploadPOJOFields and columns in ${entityConfig.entity.toLowerCase()}Config.js.
                Please remove them from one of the lists.
                Collision fields: ${configCollisionFields.join(', ')}`
            )
        );
    }

    (entityConfig.neverUploadPOJOFields || []).forEach(field => {
        if (!hasPojoField(entityData, field)) {
            throwCriticalError(
                new ExcelBulkError(
                    `Field ${field} is defined in neverUploadPOJOFields but not found in entityData, ${entityConfig.entity.toLowerCase()}Config.js`
                )
            );
        }
    });

    entityConfig.columns.forEach(column => {
        const field = getColumnField(column);
        if (!hasPojoField(entityData, field) && !column.isExcelOnly) {
            throwCriticalError(
                new ExcelBulkError(
                    `Field ${field} is defined in columns but not found in entityData, ${entityConfig.entity.toLowerCase()}Config.js`
                )
            );
        }
    });

    verifyMissedFields({
        entityData,
        entity: entityConfig.entity,
        columns: entityConfig.columns,
        omitFields: entityConfig.neverUploadPOJOFields,
    });
};
