import { useQuery } from 'react-query';
import { useSelectedAccount } from 'hooks';
import { usePerformanceRulesApi } from '../../../services/api/performanceRulesApi/usePerformanceRulesApi';

const emptyObj = {};

export const usePerformanceRulesSettingsData = dimension => {
    const { getPerformanceRulesSettings } = usePerformanceRulesApi();
    const [{ accountName }] = useSelectedAccount();

    const { data: performanceRulesSettingsData = emptyObj } = useQuery(
        ['performanceRulesSettings', accountName, dimension],
        () => getPerformanceRulesSettings(accountName, dimension),
        {
            enabled: !!accountName && !!dimension,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: Infinity,
            retry: false,
        }
    );

    return performanceRulesSettingsData;
};
