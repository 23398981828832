import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { DRAWER_MODE } from 'components';
import {
    sponsoredPath,
    creativeDuplicatePath,
    creativeEditorPath,
    videoCreativeEditorPath,
} from 'modules/campaigns/config';
import { useReachEstimatorEnabled } from '../../../modules/common-campaign-form/components/ReachEstimator/hooks/useReachEstimatorEnabled';

export const useEditDrawerMode = () => {
    const matchSponsored = useRouteMatch({ path: sponsoredPath });
    const isReachEstimatorEnabled = useReachEstimatorEnabled() && Boolean(matchSponsored);
    const matchCreative = useRouteMatch({ path: [creativeDuplicatePath, creativeEditorPath] });
    const isRouteMatchVideoCreativeEditor = useRouteMatch([videoCreativeEditorPath]);
    const drawerMode = useMemo(() => {
        if (isRouteMatchVideoCreativeEditor) {
            return DRAWER_MODE.LARGE;
        }
        if (Boolean(matchCreative)) {
            return DRAWER_MODE.LARGE;
        }

        if (isReachEstimatorEnabled) {
            return DRAWER_MODE.MEDIUM;
        }

        return DRAWER_MODE.SMALL;
    }, [isReachEstimatorEnabled, matchCreative, isRouteMatchVideoCreativeEditor]);

    return drawerMode;
};
