'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _hooks = require('../../hooks');

var _Buttons = require('../Buttons');

var _Tooltips = require('../Tooltips');

var _Collapsible = require('../Collapsible');

var _sideDrawerAnchorDirection = require('./sideDrawerAnchorDirection');

var _sideDrawerAnchorDirection2 = _interopRequireDefault(_sideDrawerAnchorDirection);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'sideDrawer__taboola-svg-icon___3LwVX',
    'container': 'sideDrawer__container___1MLjz',
    'content': 'sideDrawer__content___WCwnP',
    'right': 'sideDrawer__right___7RXzL',
    'open': 'sideDrawer__open___3f1_W',
    'left': 'sideDrawer__left___2be0Q',
    'close-button-container': 'sideDrawer__close-button-container___FI_1A',
    'close-button': 'sideDrawer__close-button___31ZrG',
    'overlay': 'sideDrawer__overlay___3FVb9'
};


var classNameBuilder = _bind2.default.bind(styles);

var SideDrawer = function SideDrawer(_ref) {
    var _classNameBuilder;

    var children = _ref.children,
        rootClassName = _ref.rootClassName,
        drawerClassName = _ref.drawerClassName,
        overlayClassName = _ref.overlayClassName,
        anchorDirection = _ref.anchorDirection,
        openDrawer = _ref.openDrawer,
        onClose = _ref.onClose,
        hasCloseButton = _ref.hasCloseButton,
        fitToScreen = _ref.fitToScreen,
        style = _ref.style;

    (0, _hooks.useEscapeHandler)(openDrawer ? onClose : null);
    var childrenContent = typeof children === 'function' ? children(onClose) : children;
    return _react2.default.createElement(
        'div',
        { className: classNameBuilder(rootClassName) },
        _react2.default.createElement(
            _Collapsible.Collapsible,
            {
                collapsed: !openDrawer,
                direction: _Collapsible.Collapsible.DIRECTION.HORIZONTAL,
                className: classNameBuilder('container', (_classNameBuilder = {}, _defineProperty(_classNameBuilder, anchorDirection, anchorDirection), _defineProperty(_classNameBuilder, 'open', openDrawer), _classNameBuilder)),
                fitToScreen: fitToScreen,
                style: style
            },
            _react2.default.createElement(
                _Tooltips.TooltipV2Root,
                {
                    className: classNameBuilder('content', drawerClassName)
                },
                hasCloseButton && openDrawer ? _react2.default.createElement(
                    'div',
                    {
                        className: classNameBuilder('close-button-container')
                    },
                    _react2.default.createElement(_Buttons.StyledButton, {
                        iconBeforeClassName: classNameBuilder('close-button'),
                        iconBefore: 'close',
                        onClick: onClose,
                        type: _Buttons.STYLED_BUTTON_TYPE.BORDERLESS_ICON,
                        size: _Buttons.STYLED_BUTTON_SIZE.SMALL
                    })
                ) : null,
                openDrawer ? childrenContent : null
            )
        ),
        openDrawer && _react2.default.createElement('div', {
            className: classNameBuilder('overlay', overlayClassName),
            role: 'presentation',
            onClick: onClose
        })
    );
};

SideDrawer.propTypes = {
    /** Elements inside the SideDrawer tag */
    children: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.func]),
    /** Apply class to style the root element of the drawer and the overlay */
    rootClassName: _propTypes2.default.string,
    /** Apply class to style the drawer element */
    drawerClassName: _propTypes2.default.string,
    /** Apply class to style the overlay element */
    overlayClassName: _propTypes2.default.string,
    /** Open / Close drawer */
    openDrawer: _propTypes2.default.bool,
    /** Callback that's triggered when clicking the overlay */
    onClose: _propTypes2.default.func,
    /** Set drawer anchor direction - left / right */
    anchorDirection: _propTypes2.default.oneOf(Object.values(_sideDrawerAnchorDirection2.default)),
    /** The prefix of the transition name for the slide in animation */
    transitionName: _propTypes2.default.string,
    /** The slide in transition enter timeout */
    transitionEnterTimeout: _propTypes2.default.number,
    /** The slide in transition leave timeout */
    transitionLeaveTimeout: _propTypes2.default.number,
    /** Should show button */
    hasCloseButton: _propTypes2.default.bool,
    /** Should fit to screen */
    fitToScreen: _propTypes2.default.bool,
    style: _propTypes2.default.object
};

SideDrawer.defaultProps = {
    onClose: function onClose() {},
    anchorDirection: _sideDrawerAnchorDirection2.default.RIGHT,
    transitionName: 'drawer-animation',
    transitionEnterTimeout: 300,
    transitionLeaveTimeout: 300,
    hasCloseButton: true,
    openDrawer: false
};

exports.default = SideDrawer;