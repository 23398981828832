import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-syntax
import { useIntl } from 'react-intl-phraseapp';
import { useCurrentValueGetter } from 'hooks/useCurrentValueGetter';
import { IS_DEBUG_MODE, throwOnDebug } from 'services/isDebugMode';
import { translateTermsByParentApp } from '../utils/translateTermsByParentApp';
import { useParentAppName } from './useParentAppName';

const useIntlWrapper = () => {
    const { formatMessage, messages, ...rest } = useIntl();
    const getFormatMessage = useCurrentValueGetter(formatMessage);
    const parentApp = useParentAppName();

    const isMessageIdExists = useCallback(messageId => !!messages[messageId], [messages]);

    const getDefaultMessage = useCallback(
        ({ id, defaultMessage }) => {
            if (!isMessageIdExists(id)) {
                const { [id]: newDefaultMessage } = translateTermsByParentApp({ [id]: defaultMessage }, parentApp);

                return newDefaultMessage;
            }

            return defaultMessage;
        },
        [isMessageIdExists, parentApp]
    );

    const formatMessageWrapper = useCallback(
        (...args) => {
            const formatMessage = getFormatMessage();
            const [{ id, defaultMessage, ...rest }] = args;
            const actualDefaultMessage = getDefaultMessage({ id, defaultMessage });

            const result = formatMessage({ id, defaultMessage: actualDefaultMessage, ...rest }, ...args.slice(1));

            if (IS_DEBUG_MODE()) {
                if (result.includes('<') && result.includes('>')) {
                    throwOnDebug(
                        'FormatMessage returned a string including < and >. This function should only output strings. Be careful!'
                    );
                }
            }

            return result;
        },
        [getDefaultMessage, getFormatMessage]
    );

    return { ...rest, messages, formatMessage: formatMessageWrapper, isMessageIdExists, getDefaultMessage };
};

export { useIntlWrapper as useIntl };
