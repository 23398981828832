import { isEmptyAccountId } from '../../modules/taboola-common-frontend-modules/account-configurations/utils/isEmptyAccountId';
import { throwCriticalError } from '../../modules/taboola-common-frontend-modules/formData/utils/throwCriticalError';

export const transformAccountFromGW = account => {
    if (isEmptyAccountId(account.accountId)) {
        throwCriticalError(new Error(`Account id is empty ${JSON.stringify(account)}`));
    }
    return {
        ...account,
        id: account.accountId,
        value: `${account.accountId}`,
        label: account.accountDescription,
    };
};
