import flash from '../images/flash.webp';
import golden from '../images/golden.webp';
import midday from '../images/midday.webp';
import natural from '../images/natural.webp';
import photojournalism from '../images/photojournalism.webp';
import professional from '../images/professional.webp';
import street from '../images/street.webp';

export const OPTIONS = {
    CLOSE_UP: 'closeUp',
    MID_SHOT: 'midShot',
    LONG_SHOT: 'longShot',
    PHOTOJOURNALISM: 'photojournalism',
    PROFESSIONAL: 'professional',
    FLASH: 'flash',
    STREET: 'street',
    NATURAL: 'natural',
    GOLDEN: 'golden',
    MIDDAY: 'midday',
    STUDIO: 'studio',
    BLACK_AND_WHITE: 'grayscale',
    COLOR: 'color',
    CONFIDENT: 'confident',
    EXCITED: 'excited',
    FRUSTRATED: 'frustrated',
    MOTIVATED: 'motivated',
    NERVOUS: 'nervous',
    OPTIMISTIC: 'optimistic',
    PASSIONATE: 'passionate',
    RELAXED: 'relaxed',
};

export const optionToImageMap = {
    [OPTIONS.PHOTOJOURNALISM]: {
        image: photojournalism,
    },
    [OPTIONS.PROFESSIONAL]: {
        image: professional,
    },
    [OPTIONS.FLASH]: {
        image: flash,
    },
    [OPTIONS.STREET]: {
        image: street,
    },
    [OPTIONS.NATURAL]: {
        image: natural,
    },
    [OPTIONS.GOLDEN]: {
        image: golden,
    },
    [OPTIONS.MIDDAY]: {
        image: midday,
    },
};
