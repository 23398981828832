import React, { useCallback } from 'react';
import { useSelectedAccount } from 'hooks';
import { generatePaymentMethodFormPath } from 'modules/billing-and-payments/config/routes/generatePaymentMethodFormPath';
import { useBillingData } from 'modules/billing-and-payments/hooks/useBillingData';
import { FORM_MODES } from 'modules/campaigns/config';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';

const MODAL_CONFIG = {
    title: (
        <FormattedMessage id="campaign.creator.addPayment.modal.title" tagName="h2" defaultMessage="You're so close!" />
    ),
    content: (
        <FormattedMessage
            id="campaign.creator.addPayment.modal.content"
            defaultMessage="Add your billing information and payment method to launch ads."
        />
    ),
    formProps: {
        submitButtonText: (
            <FormattedMessage id="campaign.creator.addPayment.modal.submit" defaultMessage="Add Payment Method" />
        ),
        cancelButtonText: (
            <FormattedMessage id="campaign.creator.addPayment.modal.cancel" defaultMessage="I'll do it later" />
        ),
    },
};

export const useAddPaymentModal = () => {
    const { open } = useModal();
    const { isManagingBilling } = useBillingData();
    const [{ isFrozen }] = useSelectedAccount();
    const paymentFormPath = generatePaymentMethodFormPath({ mode: FORM_MODES.CREATE });

    const openModal = useCallback(async () => {
        if (isManagingBilling && isFrozen) {
            return open(MODAL_CONFIG);
        }
        return false;
    }, [isFrozen, isManagingBilling, open]);

    const openModalAndGetPath = useCallback(
        async () => ((await openModal()) ? paymentFormPath : null),
        [openModal, paymentFormPath]
    );

    return { openModalAndGetPath, paymentFormPath };
};
