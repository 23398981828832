import React, { useState } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import TypeRadioIconGroup from 'components/TypeRadioIconGroup/TypeRadioIconGroup';
import { FORM_MODES } from 'config/formModes';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { useTypeSubtext } from '../../../../hooks';
import { CRM_FILE_TYPE_TO_AUDIENCE_TYPE_MAP } from '../../config';
import CrmFileFirstPartyCountriesDropdown from '../CrmFileFirstPartyCountriesDropdown/CrmFileFirstPartyCountriesDropdown';
import CrmFileUpload from '../CrmFileUpload/CrmFileUpload';
import { useCrmFileDataType } from './useCrmFileDataType';
import styles from './crmFileDataTypeField.module.scss';

const validations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'audience.editor.validations.error.audience.type.empty',
        defaultMessage: 'Select an Audience Type',
    },
];

export const CrmFileDataTypeField = ({ reportType, isExternalAudiencePage, onTypeChange }) => {
    const msgPrefix =
        reportType === REPORT_TYPE.FIRST_PARTY
            ? 'audience.editor.first.party.audience.setup'
            : 'audience.editor.predictive.audience.setup';
    const { mode, value: crmFileType, onChange: onCrmFileTypeChange } = useFormFieldValue({ field: 'crmFileType' });
    const [countryCode, setCountryCode] = useState();

    const onChange = key => {
        onCrmFileTypeChange(key);
        setCountryCode(null);
        onTypeChange && onTypeChange(CRM_FILE_TYPE_TO_AUDIENCE_TYPE_MAP[key]);
    };

    const { isNotYahooAudience, configs } = useCrmFileDataType(isExternalAudiencePage);
    const { subtext, setHovered } = useTypeSubtext(crmFileType, configs, msgPrefix);

    return (
        <>
            <FormField
                inputId="crm-file-type"
                label={<FormattedMessage id="audience.editor.audience.setup.title" defaultMessage="Data Type" />}
                description={
                    <FormattedMessage
                        id={`${msgPrefix}.description`}
                        defaultMessage="Please note, it may take up to 4 days for your audience to be ready."
                    />
                }
                subtext={subtext}
            >
                <TypeRadioIconGroup
                    name="crm-file-type"
                    type={crmFileType}
                    onTypeChange={onChange}
                    onHover={key => setHovered(key)}
                    configs={configs[reportType]}
                    msgPrefix={msgPrefix}
                    titleClassName={styles['title']}
                    disabled={mode === FORM_MODES.EDIT}
                    validations={validations}
                    field="crmFileType"
                />
            </FormField>
            {mode === FORM_MODES.CREATE && isNotYahooAudience && (
                <>
                    <br />
                    {reportType === REPORT_TYPE.FIRST_PARTY && (
                        <CrmFileFirstPartyCountriesDropdown countryCode={countryCode} setCountryCode={setCountryCode} />
                    )}
                    <CrmFileUpload crmFileType={crmFileType} />
                </>
            )}
        </>
    );
};

export default CrmFileDataTypeField;
