import React from 'react';
import PropTypes from 'prop-types';

export const ExternalLink = ({ children, ...restProps }) => (
    <a target="_blank" rel="noopener noreferrer" {...restProps}>
        {children}
    </a>
);

ExternalLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node,
};

export default ExternalLink;
