import { CREATIVE_TYPE } from 'modules/campaigns/config';
import { ITEM_CREATIVE_TYPE } from 'modules/campaigns/modules/creative-creator/constants/itemCreativeType';
import { FOCUS_TYPES } from 'modules/campaigns/modules/creative-editor/config';

const updateCreativeFields = (initialCreative, cdnThumbnailUrl) => {
    const { thumbnailFile, thumbnailRecommendedImage, creativeFocus, ...creative } = initialCreative;

    return {
        ...creative,
        ...(creativeFocus?.type !== FOCUS_TYPES.PADDING && { creativeFocus }),
        ...(cdnThumbnailUrl && { thumbnailUrl: cdnThumbnailUrl }),
    };
};

const buildUnifiedCreativeObject = creative => {
    const {
        videoUrl,
        gifUrl,
        mediaUploadSource,
        recommendedFBImage,
        motionAdsStudio,
        creativeType,
        ...reducedCreative
    } = creative;

    const isCreativeTypeMotionAd = creativeType === CREATIVE_TYPE.MOTION;
    const itemCreativeType = isCreativeTypeMotionAd
        ? ITEM_CREATIVE_TYPE.PERFORMANCE_VIDEO
        : ITEM_CREATIVE_TYPE.STATIC_IMAGE;
    const performanceVideoData = isCreativeTypeMotionAd
        ? {
              fallbackUrl: reducedCreative.thumbnailUrl,
              videoUrl,
              gifUrl,
              mediaUploadSource,
              recommendedFallbackImage: recommendedFBImage,
              motionAdsStudio,
          }
        : null;
    return {
        ...reducedCreative,
        itemCreativeType,
        performanceVideoData,
    };
};

export const getUnifiedCreativeObject = (initialCreative, cdnThumbnailUrl) => {
    const updatedCreative = updateCreativeFields(initialCreative, cdnThumbnailUrl);
    return buildUnifiedCreativeObject(updatedCreative);
};
