import { useQuery } from 'react-query';
import { isNil } from 'lodash';
import { useSelectedAccount } from 'hooks';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useTrackingApi } from 'services/api';
import { useGoogleApi } from '../../taboola-common-frontend-modules/googleApi';

const emptyArray = [];
const defaultResponse = { workspaces: emptyArray };

export const useWorkspaceDropdown = () => {
    const { getWorkspaces } = useTrackingApi();
    const [{ accountId }] = useSelectedAccount();
    const { getToken, isTokenValid, exponentialBackoff, staleTime } = useGoogleApi();

    const { value: gtmAccount } = useFormFieldValue({ field: 'gtmAccount' });
    const { value: gtmContainer } = useFormFieldValue({ field: 'gtmContainer' });
    const { value, onChange } = useFormFieldValue({ field: 'gtmWorkspace' });

    const { data = defaultResponse, isLoading } = useQuery(
        ['getWorkspaces', accountId, gtmAccount, gtmContainer, getToken()?.access_token],
        () => getWorkspaces(getToken()?.access_token, accountId, gtmAccount?.value, gtmContainer?.value),
        {
            enabled: isTokenValid && !isNil(gtmAccount) && !isNil(gtmContainer),
            retryDelay: exponentialBackoff,
            staleTime,
        }
    );

    const { workspaces } = data;
    const options = workspaces?.map(gtmWorkspace => ({ value: gtmWorkspace.id, label: gtmWorkspace.name }));

    return {
        value,
        options,
        isLoading,
        onChange,
    };
};
