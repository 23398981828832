import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'tuui';
import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { STATIC_FILTER_TYPE_MAP } from 'modules/campaigns/modules/campaigns-reports/config';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { isComparableFilter } from '../filterUtils';
import { comparableFilterValuesResolver } from './comparableFilterValuesResolver';
import styles from './closedFilterUnit.module.scss';

const TagWoTooltip = forwardRef(({ message, ...rest }, ref) => {
    return (
        <Tag {...rest} ref={ref}>
            {message}
        </Tag>
    );
});

const TagWithTooltip = ({ content, ...rest }) => (
    <span>
        <Tooltip arrow interactive>
            {content}
        </Tooltip>
        <TagWoTooltip color="info" {...rest} />
    </span>
);

export const ClosedFilterUnit = ({ onEditFilter, onRemoveFilter, filter }) => {
    const { type, values, operator } = filter;
    const typeLabel = <FormattedMessage id={`app.campaigns.reports.filters.type.${type}`} />;
    const comparableFilter = isComparableFilter(type);
    const tooltipMessageIdPrefix = STATIC_FILTER_TYPE_MAP[type]?.tooltipMessageIdPrefix;
    const { currency } = useAccount();
    const jointValues = !comparableFilter
        ? values.map(value => value.label).join(', ')
        : comparableFilterValuesResolver({ type, values, operator, currency });

    const message = comparableFilter ? (
        <span className={styles['filter-container']}>
            <FormattedMessage
                id="app.campaigns.reports.filters.comparable.closed.label.withOperator"
                values={{
                    type: typeLabel,
                    values: jointValues,
                    operator: operator.label,
                }}
                defaultMessage="{type} - {operator}: {values}"
            />
        </span>
    ) : (
        <span className={styles['filter-container']}>
            <FormattedMessage
                id="app.campaigns.reports.filters.closed.label"
                values={{
                    type: typeLabel,
                    values: jointValues,
                }}
                defaultMessage="{type}: {values}"
            />
        </span>
    );
    const tagProps = {
        onClick: onEditFilter,
        onRemove: onRemoveFilter,
        message,
    };

    if (tooltipMessageIdPrefix && values.length === 1) {
        return (
            <TagWithTooltip
                content={<FormattedMessage id={`${tooltipMessageIdPrefix}.${values[0].value}`} />}
                {...tagProps}
            />
        );
    }

    return <TagWoTooltip {...tagProps} />;
};

ClosedFilterUnit.propTypes = {
    filter: PropTypes.object.isRequired,
    onEditFilter: PropTypes.func.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    closeDisabled: PropTypes.bool,
};
