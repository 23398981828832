import { TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './bundleIconTooltip.module.scss';

export const BundleIconTooltip = ({ bundleIcon, position }) => (
    <Tooltip position={position} className={styles['tooltip-content']} arrow>
        <FormattedMessage
            id="expand.bundle.tooltip"
            defaultMessage="This bundle was created to include the most relevant
                                    segments in the category. It is recommended to target the bundle segment
                                    as a whole. Click {bundleIcon} only if you want to <b>narrow</b> your targeting by
                                    selecting specific segments from this bundle."
            values={{ bundleIcon }}
        />
    </Tooltip>
);
