import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import pixelGif from './pixel.gif';

const PasteCode = () => {
    return (
        <FormField
            label={
                <FormattedMessage id="tracking.setup.add.code.to.website" defaultMessage="Add the code to website" />
            }
            description={
                <FormattedMessage
                    id="tracking.setup.paste.code.description"
                    defaultMessage="Paste the code snippet inside the '<head></head>' HTML element of your website.
                    To ensure the best results, apply this to every page on your site."
                />
            }
        >
            <img src={pixelGif} alt="loading pixel example" />
        </FormField>
    );
};

export default PasteCode;
