import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'tuui';
import { StaticIndications } from '../taboola-common-frontend-modules/Indications';
import { IframeWrapper } from './IframeWrapper';
import HandlerMessageType from './messages';
import styles from './hybridModuleIframe.module.scss';

const LOADING_TIMEOUT = 30000;

class HybridModuleIframe extends Component {
    constructor(props) {
        super(props);
        this.iframe = React.createRef();
        this.loadingTimer = null;
        this.state = { loading: true };
    }

    componentDidMount() {
        this.startLoading();
        window.addEventListener('message', this.messageHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
        this.stopLoading();
    }

    startLoading() {
        const { loadingTimeout } = this.props;

        clearTimeout(this.loadingTimer);
        this.loadingTimer = setTimeout(this.handleTimeout, loadingTimeout);
        this.setState({ loading: true });
    }

    stopLoading() {
        this.loadingTimer = clearTimeout(this.loadingTimer);
        this.setState({ loading: false });
    }

    handleIframeLoad = () => this.stopLoading();

    handleUrlChange = newUrl => {
        const { url, onUrlChange } = this.props;

        if (url !== newUrl) {
            onUrlChange({ url: newUrl });
        }
    };

    handleSelfRender = () => {
        const { url } = this.props;
        this.iframe.current.src = url;
    };

    handleLogout = () => {
        const { onLogout } = this.props;

        onLogout();
    };

    handleTimeout = () => {
        const { onError } = this.props;

        this.stopLoading();
        onError();
    };

    messageHandler = e => {
        const { externalMessageHandler, history } = this.props;
        const { contentWindow } = this.iframe.current;
        if (e.source !== contentWindow) {
            return;
        }

        const { type, value } = e.data || {};

        if (type === HandlerMessageType.HASHCHANGE || type === HandlerMessageType.LOAD) {
            this.handleUrlChange(value);
        }

        if (type === HandlerMessageType.SELF) {
            this.handleSelfRender();
        }

        if (type === HandlerMessageType.UNLOAD) {
            this.startLoading();
        }

        if (type === HandlerMessageType.AUTH) {
            this.startLoading();
            this.handleLogout();
        }

        externalMessageHandler({ ...e.data, history });
    };

    render() {
        const { loading } = this.state;
        const { url, title, iframeProps } = this.props;

        return (
            <div className={styles['container']}>
                <StaticIndications containerClassName={styles['static-indications']} />
                {loading ? (
                    <div className={styles['full-screen']}>
                        <Spinner />
                    </div>
                ) : null}
                <IframeWrapper
                    key={url}
                    src={url}
                    ref={this.iframe}
                    className={styles['iframe']}
                    onLoad={this.handleIframeLoad}
                    title={title}
                    data-hj-allow-iframe={true}
                    {...iframeProps}
                />
            </div>
        );
    }
}

HybridModuleIframe.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    onUrlChange: PropTypes.func,
    onLogout: PropTypes.func,
    onError: PropTypes.func,
    loadingTimeout: PropTypes.number,
    externalMessageHandler: PropTypes.func,
    history: PropTypes.object,
};

HybridModuleIframe.defaultProps = {
    loadingTimeout: LOADING_TIMEOUT,
    externalMessageHandler: noop,
};

export { HybridModuleIframe };
export default withRouter(HybridModuleIframe);
