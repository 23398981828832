import { chunk } from 'lodash';
import { update as fpUpdate } from 'lodash/fp';
import { BULK_ERROR_TYPES } from '../consts/bulkErrorTypes';

const makeBatchRequest = async (apiCall, entity, batch, formatMessage) => {
    try {
        const { elements = [] } = await apiCall({ entity, requestBody: { elements: batch } });
        return elements;
    } catch (err) {
        debugger;
        const defaultMessage =
            err.message ||
            formatMessage({
                id: 'excel.report.creation.unknown.error',
                defaultMessage: 'Unknown Server Error Occurred',
            });
        const errorMessage = err.messageCode ? formatMessage({ id: err.messageCode, defaultMessage }) : defaultMessage;

        return batch.map(el => ({
            ...el,
            error: { type: BULK_ERROR_TYPES.API, message: errorMessage },
        }));
    }
};

export const batchApiCall =
    ({ apiCall, batchSize, formatMessage, setProcessedEntitiesCount }) =>
    async ({ entity, requestBody }) => {
        const { elements } = requestBody;

        const safeNumPerBatch = batchSize || elements.length;
        const batchedArray = chunk(elements, safeNumPerBatch);

        const finalResult = [];

        for (let batch of batchedArray) {
            const res = await makeBatchRequest(apiCall, entity, batch, formatMessage);
            setProcessedEntitiesCount(fpUpdate(entity, (prevCount = 0) => prevCount + batch.length));
            finalResult.push(res || []);
        }

        return { elements: finalResult.flat() };
    };
