import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import createCSVValueGetter from './createCSVValueGetter';
import createGridValueFormatter from './createGridValueFormatter';

const numberFormatter = ({ value, formatOptions, i18n, currency }) => {
    const {
        style,
        minimumFractionDigits,
        maximumFractionDigits,
        forceSign,
        showEmptyWhenZero = false,
        showEmptyWhenNull = false,
    } = formatOptions || {};
    const format = {
        style,
        minimumFractionDigits,
        maximumFractionDigits,
        forceSign,
    };

    if (value === null && showEmptyWhenNull) {
        return EMPTY_FIELD_VALUE;
    }

    if (format.style === 'currency') {
        if (currency) {
            format.currency = currency;
        } else {
            delete format.style;
        }
    }

    const resolvedValue = value ?? 0;
    if (resolvedValue === 0 && showEmptyWhenZero) {
        return EMPTY_FIELD_VALUE;
    }

    return i18n.formatNumber(resolvedValue, format);
};

const gridNumberFormatter = createGridValueFormatter(numberFormatter);
const formattedNumberValueGetter = createCSVValueGetter(numberFormatter);

export { gridNumberFormatter, formattedNumberValueGetter };
export default numberFormatter;
