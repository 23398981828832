import React from 'react';

export const MasterCardLogo = () => {
    return (
        <svg width="48" height="30" viewBox="0 0 48 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="15" fill="#EB001B" />
            <circle cx="33" cy="15" r="15" fill="#F79E1B" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9999 27C28.1406 24.6047 30.9265 20.1277 30.9265 15C30.9265 9.87231 28.1406 5.39531 23.9999 3C19.8591 5.39531 17.0732 9.87231 17.0732 15C17.0732 20.1277 19.8591 24.6047 23.9999 27Z"
                fill="#FF5F00"
            />
        </svg>
    );
};
