import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { BID_STRATEGIES } from '../../../config';
import { useBidStrategyFormField } from '../../BidStrategyV2/hooks/useBidStrategyFormField';

export const useIsConversionRuleDisable = () => {
    const isAllowedTargetCpaBidStrategy = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_TARGET_CPA]: 'true',
    });
    const isAllowedMaxConversionsBidStrategy = useConfigMatch({
        [FEATURE_FLAGS.CAMPAIGN_LEVEL_CONVERSIONS_BID_STRATEGY_ALLOW_MAX_CONVERSIONS]: 'true',
    });
    const { value: bidStrategy } = useBidStrategyFormField();
    const isMaxConversions = BID_STRATEGIES.MAX_CONVERSIONS === bidStrategy && !isAllowedMaxConversionsBidStrategy;
    return isMaxConversions || (BID_STRATEGIES.TARGET_CPA === bidStrategy && !isAllowedTargetCpaBidStrategy);
};
