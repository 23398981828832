import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import DailyRevenueCap from './DailyRevenueCap';
import DailyRevenueCapFieldTooltip from './DailyRevenueCapFieldTooltip';
import styles from './DailyRevenueCapField.module.scss';

export const DailyRevenueCapField = ({ currency }) => {
    return (
        <FormField
            inputId="dailyRevenueCapField"
            label={
                <FormattedMessage
                    id="video.campaign.editor.dailyRevenueCap.title"
                    defaultMessage="Daily revenue Cap (Optional)"
                />
            }
            description={
                <FormattedMessage
                    id="video.campaign.editor.dailyRevenueCap.description"
                    defaultMessage="Defines a daily limitation of spent."
                />
            }
            helpText={<DailyRevenueCapFieldTooltip />}
            containerClass={styles['input']}
        >
            <DailyRevenueCap currency={currency} />
        </FormField>
    );
};

DailyRevenueCapField.propTypes = {
    currency: PropTypes.string,
};

export default DailyRevenueCapField;
