import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup, TextCheckbox } from 'taboola-ultimate-ui';
import { CHECKED, UNCHECKED } from 'modules/campaigns/config/checkboxStates';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const CrawlUrlsCheckbox = ({ shouldCrawlUrls, onChange }) => (
    <CheckboxGroup
        onSelectCheckbox={() => onChange(true)}
        onUnselectCheckbox={() => onChange(false)}
        selectedValues={[CHECKED]}
    >
        <TextCheckbox
            value={shouldCrawlUrls ? CHECKED : UNCHECKED}
            title={
                <FormattedMessage
                    id="creative.creator.creation.mode.ONE_BY_ONE.checkbox.label"
                    defaultMessage="Load image and headline from landing page URL"
                />
            }
        />
    </CheckboxGroup>
);

CrawlUrlsCheckbox.propTypes = {
    landingPageFlag: PropTypes.bool,
    onChange: PropTypes.func,
};

export default CrawlUrlsCheckbox;
