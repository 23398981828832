import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from '../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import YES_NO_TYPES from '../../../../config/yesNoConsts';

const YesNoRadioGroup = ({ selectedValue, onChange, name, id }) => (
    <RadioGroup
        selectedValue={selectedValue ? YES_NO_TYPES.YES : YES_NO_TYPES.NO}
        onChange={value => onChange(value === YES_NO_TYPES.YES)}
        data-metrics-component={name}
        name={name}
        id={id}
    >
        <Radio
            value={YES_NO_TYPES.NO}
            title={<FormattedMessage id="app.form.field.type.no" defaultMessage={YES_NO_TYPES.NO} />}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={YES_NO_TYPES.NO}
        />
        <Radio
            value={YES_NO_TYPES.YES}
            title={<FormattedMessage id="app.form.field.type.yes" defaultMessage={YES_NO_TYPES.YES} />}
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-value={YES_NO_TYPES.YES}
        />
    </RadioGroup>
);

YesNoRadioGroup.propTypes = {
    selectedValue: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default YesNoRadioGroup;
