import { getStatusType, STATUS_TYPE } from '../../campaigns/config/statusConsts';

export const AUDIENCE_STATUS = {
    READY: 'READY',
    ACTIVE: 'ACTIVE',
    NO_ACTIVITY_YET: 'NO_ACTIVITY_YET',
    NO_RECENT_ACTIVITY: 'NO_RECENT_ACTIVITY',
    DISABLED: 'DISABLED',
    ARCHIVED: 'ARCHIVED',
    PROCESSING: 'PROCESSING',
    ERROR: 'ERROR',
    GAINING_SCALE: 'GAINING_SCALE',
    ERROR_SEED_SIZE_TOO_SMALL: 'ERROR_SEED_SIZE_TOO_SMALL',
    ALL_BUT_ARCHIVED: 'ALL_BUT_ARCHIVED',
    REJECTED: 'REJECTED',
};

export const AUDIENCE_TYPE = {
    BASIC: 'BASIC',
    EVENT_BASED: 'EVENT_BASED',
    LOOKALIKE: 'LOOKALIKE',
    PIXEL_LOOKALIKE: 'PIXEL_LOOKALIKE',
    CUSTOM_CONTEXTUAL: 'CUSTOM_CONTEXTUAL',
    MAIL_DOMAINS: 'MAIL_DOMAINS',
    SEARCH_KEYWORDS: 'SEARCH_KEYWORDS',
    COMBINED_AUDIENCES: 'COMBINED_AUDIENCES',
    PBP_LOOKALIKE: 'PBP_LOOKALIKE',
};

export const CRM_FILE_TYPE = {
    EMAIL: 'EMAIL',
    DEVICE_ID: 'MOBILE_ADVERTISING_ID',
    ZIP_CODE: 'ZIP_CODE',
    SEARCH_KEYWORDS: 'SEARCH_KEYWORDS',
    MAIL_DOMAINS: 'MAIL_DOMAINS',
};

export const CRM_FILE_TYPE_TO_AUDIENCE_TYPE_MAP = {
    [CRM_FILE_TYPE.EMAIL]: AUDIENCE_TYPE.LOOKALIKE,
    [CRM_FILE_TYPE.DEVICE_ID]: AUDIENCE_TYPE.LOOKALIKE,
    [CRM_FILE_TYPE.ZIP_CODE]: AUDIENCE_TYPE.LOOKALIKE,
    [CRM_FILE_TYPE.SEARCH_KEYWORDS]: AUDIENCE_TYPE.SEARCH_KEYWORDS,
    [CRM_FILE_TYPE.MAIL_DOMAINS]: AUDIENCE_TYPE.MAIL_DOMAINS,
};

export const TTL_TYPE = {
    SPECIFIC_TIMEFRAME: 'SPECIFIC_TIMEFRAME',
    CURRENT_MONTH: 'CURRENT_MONTH',
};

export const CRM_TARGETING_TYPE = {
    ALL: 'ALL',
    LOOKALIKE: 'LOOKALIKE',
    TARGETING_SUPPRESSION: 'TARGETING_SUPPRESSION',
};

export const MATCH_TYPES = {
    EXACT: 'EXACT',
    BROAD: 'BROAD',
};

export const AUDIENCE_STATUS_KEYS = Object.keys(AUDIENCE_STATUS);

const nonInfoAudienceStatusConfig = {
    [AUDIENCE_STATUS.READY]: { statusType: STATUS_TYPE.SUCCESS },
    [AUDIENCE_STATUS.ACTIVE]: { statusType: STATUS_TYPE.SUCCESS },
    [AUDIENCE_STATUS.PROCESSING]: { statusType: STATUS_TYPE.WARNING },
    [AUDIENCE_STATUS.GAINING_SCALE]: { statusType: STATUS_TYPE.WARNING },
    [AUDIENCE_STATUS.ERROR]: { statusType: STATUS_TYPE.DANGER },
    [AUDIENCE_STATUS.ERROR_SEED_SIZE_TOO_SMALL]: { statusType: STATUS_TYPE.DANGER },
    [AUDIENCE_STATUS.REJECTED]: { statusType: STATUS_TYPE.WARNING },
};

export const audiencesTypesToHideIndication = [AUDIENCE_STATUS.PROCESSING, AUDIENCE_STATUS.GAINING_SCALE];

export const getAudienceStatusType = audienceStatus => getStatusType(nonInfoAudienceStatusConfig, audienceStatus);
