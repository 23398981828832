import { ExtendedComponent } from 'tuui/lib/utils/typing';
import { CircleProgressIndicator as Base, CircleProgressIndicatorProps } from './CircleProgressIndicator';

export const CircleProgressIndicator: ExtendedComponent<
    typeof Base,
    Pick<CircleProgressIndicatorProps, 'variant'>
> = Object.assign(Base, {
    variant: { primary: 'primary', secondary: 'secondary' },
    displayName: 'CircleProgressIndicator',
} as const);

export type { CircleProgressIndicatorProps } from './CircleProgressIndicator';
