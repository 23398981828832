import React, { useCallback } from 'react';
import { useDropdownOptions, useDropdownValue } from 'hooks';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { DropdownField } from '../../../../components/Form';
import { BRAND_SAFETY } from './brandSafetyValue';

export const brandSafetyOptions = [
    { value: BRAND_SAFETY.ALL, label: 'All' },
    { value: BRAND_SAFETY.SAFE, label: 'Safe' },
];

const BrandSafety = () => {
    const isPermitted = usePermissions(['SAFETY_TARGETING_EDIT_TA']);
    const { value, onChange } = useFormFieldValue({
        field: 'brandSafetyTargeting',
        isPermitted,
    });
    const msgIdPrefix = 'campaign.editor.brand.safety';
    const options = useDropdownOptions(brandSafetyOptions, msgIdPrefix);
    const selectedValue = useDropdownValue({ value }, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: brandSafetyTargeting }) => {
            onChange(brandSafetyTargeting);
        },
        [onChange]
    );

    return isPermitted ? (
        <DropdownField
            id="brand-safety"
            label={<FormattedMessage id="campaign.editor.brand.safety.title" defaultMessage="Brand Safety" />}
            description={
                <FormattedMessage
                    id="campaign.editor.brand.safety.description"
                    defaultMessage="Visible to internal users only."
                />
            }
            placeholder={
                <FormattedMessage
                    id="campaign.editor.brand.safety.placeholder"
                    defaultMessage="Select brand safety category..."
                />
            }
            selectedValueObject={selectedValue}
            options={options}
            onChange={changeHandler}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': 'BrandSafetyTargeting',
            }}
        />
    ) : null;
};

export default BrandSafety;
