import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { DRAWER_MODE, SideDrawer } from 'components';
import { FORM_MODES } from 'modules/campaigns/config';
import { LayerRoute } from 'modules/multi-layer-drawer/components/LayerRoute/LayerRoute';
import { PerformanceRulesDuplicatePage } from 'modules/performance-rules/pages/PerformanceRulesDuplicatePage';
import { PerformanceRulesEditPage } from 'modules/performance-rules/pages/PerformanceRulesEditPage';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import {
    PATH_TO_ENTITY_MAP,
    PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH,
    PERFORMANCE_RULES_EDIT_ROUTE_PATH,
    PERFORMANCE_RULES_ROUTE,
    PERFORMANCE_RULES_ROUTE_PATH,
} from '../config';

export const PerformanceRulesEditDrawer = ({ onCancel }) => {
    const { params: { mode, entity: entityParam } = {} } = useRouteMatch(PERFORMANCE_RULES_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel} drawerMode={DRAWER_MODE.LARGE}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={PERFORMANCE_RULES_EDIT_ROUTE_PATH}
                        render={props => <PerformanceRulesEditPage {...props} onCancel={close} />}
                    />
                    <LayerRoute
                        exact
                        path={PERFORMANCE_RULES_DUPLICATE_ROUTE_PATH}
                        render={() => <PerformanceRulesDuplicatePage onCancel={close} />}
                    />
                    <Redirect to={PERFORMANCE_RULES_ROUTE} />
                </Switch>
            )}
        </SideDrawer>
    );
};

PerformanceRulesEditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
};
