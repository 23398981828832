import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { CampaignPerformanceRulesDropDown } from './CampaignPerformanceRulesDropDown';

export const CampaignPerformanceRules = () => {
    const isPerformanceRulesEnabled = useConfigMatch({ [FEATURE_FLAGS.CAMPAIGN_PERFORMANCE_RULES_ENABLED]: 'true' });
    if (!isPerformanceRulesEnabled) {
        return null;
    }

    return (
        <CommonCollapsibleCard
            id="CAMPAIGN_PERFORMANCE_RULES"
            header={<SectionHeader headerText={<FormattedMessage id="campaign.editor.performance.rules.header" />} />}
        >
            <FormField
                inputId="custom-rules"
                label={<FormattedMessage id="campaign.editor.performance.rules.header" defaultMessage="Custom Rules" />}
                description={
                    <FormattedMessage
                        id="campaign.editor.performance.rules.description"
                        defaultMessage="Associate your predefined rules with this campaign."
                    />
                }
            />
            <CampaignPerformanceRulesDropDown />
        </CommonCollapsibleCard>
    );
};
