'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePresetConfigs = undefined;

var _usePresetConfigs = require('./usePresetConfigs');

var _usePresetConfigs2 = _interopRequireDefault(_usePresetConfigs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// eslint-disable-next-line import/prefer-default-export
exports.usePresetConfigs = _usePresetConfigs2.default;