import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useNavigate } from '../../../../../../hooks';
import { isEditDisabled } from '../../../../../scheduled-reports/config';
import { useDeleteScheduledReport } from '../../../../../scheduled-reports/hooks/useDeleteScheduledReport';
import { generateScheduledReportsEditPath } from '../../../../../scheduled-reports/utils/generateScheduledReportsPath';
import ActionsCellRenderer from './ActionsCellRenderer';
import styles from './CampaignActionsCellRenderer.module.scss';

export const ReportsScheduleActionsCellRenderer = ({ data, context, ...rest }) => {
    const deleteScheduledReport = useDeleteScheduledReport();
    const navigate = useNavigate();
    const { accountName } = context;
    const isDisabled = isEditDisabled({ data, context });
    const deleteParams = { accountName, scheduledReportName: data.reportName, scheduledReportId: data.id };
    const buttonConfigs = [
        {
            onClick: () => {
                navigate(generateScheduledReportsEditPath(accountName, data.id));
            },
            isDisabled,
            iconLabel: 'edit-o',
            className: styles['edit-button'],
            dataMetricComponent: 'EditReportButton',
            tooltipContent: (
                <FormattedMessage
                    id="app.scheduled.reports.reports.actionButtons.toolbar.edit"
                    defaultMessage="Edit Report"
                />
            ),
        },
        {
            onClick: () => deleteScheduledReport(deleteParams),
            isDisabled,
            dataMetricComponent: 'DeleteReportButton',
            iconLabel: 'delete',
            tooltipContent: (
                <FormattedMessage
                    id="app.scheduled.reports.reports.actionButtons.toolbar.delete"
                    defaultMessage="Delete Report"
                />
            ),
        },
    ];

    return <ActionsCellRenderer data={data} buttonConfigs={buttonConfigs} context={context} {...rest} />;
};
