import { isEmpty, isNil, uniqBy } from 'lodash';
import { SORTING_ORDER_TYPES, TaboolaGraph } from 'taboola-ultimate-ui';
import { BarGraphIcon, LineGraphIcon } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { FEATURE_FLAGS } from '../../../../../../taboola-common-frontend-modules/account-configurations';
import CampaignGraphTooltip from '../../../components/Tooltips/CampainGraphTooltip';
import { DEFAULT_GRAPH_PALETTE } from '../../../services/dynamicColumnsService';
import { getDataWithFullPeriodScaleSingleMetric } from '../../../transformers/getDataWithFullPeriodScaleSingleMetric';
import * as FIELDS from '../../fields';
import { DATE } from '../../fields';
import { dayWeekMonthXAxisMinTickGap, hourXAxisFormatterUTC, tooltipHourFormatterUTC } from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

const barGraph = {
    type: GRAPHS.BAR,
    endpoint: 'campaign-realtime-report-by-campaign',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 4,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.NAME.field,
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: BarGraphIcon,
};

const singleMetricGraph = {
    type: GRAPHS.SINGLE_METRIC_LINE,
    graphIdField: DATE.field,
    endpoint: 'campaign-realtime-report-by-time-bucket',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
        metrics.CONVERSIONS_VALUE,
        metrics.ROAS,
    ],
    maxAllowedSelectedMetrics: 10,
    collatingKey: 'campaign',
    skipSyncWithGrid: true,
    emptyMetricsMsgIdOverride: 'metrics.empty.compareCampaigns',
    numLegendValues: 10,
    getGridDataDependentFilters: reportData => {
        if (!isEmpty(reportData)) {
            return {
                campaignId: reportData.slice(0, 10).map(({ id }) => id),
            };
        }
    },
    defaultSort: [{ field: 'date', order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: FIELDS.DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: hourXAxisFormatterUTC,
        toolTipLabelFormatter: tooltipHourFormatterUTC,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
        isSingleYAxisDomain: true,
    },
    getMetrics: ({ graphData: { results } }) => {
        const sanitizedResults = uniqBy(results, 'campaign').filter(({ campaign }) => !isNil(campaign));
        const colors = DEFAULT_GRAPH_PALETTE;
        const metrics = sanitizedResults.map((dataPoint, index) => ({
            name: String(dataPoint.campaign),
            label: resolveLabel(dataPoint),
            suffix: resolveSuffix(dataPoint),
            labelMessageSuffix: labelIsMessageSuffix(dataPoint),
            color: colors[index],
            active: index < 2,
        }));

        return metrics;
    },
    graphTooltip: CampaignGraphTooltip,
    graphToggleIcon: LineGraphIcon,
    accountConfigurations: {
        [FEATURE_FLAGS.SHOW_COMPARE_CAMPAIGNS_GRAPH]: 'true',
    },
    dataViewTransformer: getDataWithFullPeriodScaleSingleMetric,
};

const labelIsMessageSuffix = dataPoint => dataPoint.campaignName === '';

const resolveLabel = dataPoint => dataPoint.campaignName || 'campaignNameId';

const resolveSuffix = dataPoint => (dataPoint.campaignName === '' ? String(dataPoint.campaign) : '');

export const graph = [singleMetricGraph, barGraph];
