import React, { useCallback } from 'react';
import { Radio } from 'taboola-ultimate-ui';
import styles from 'modules/campaigns/modules/campaigns-reports/components/CellRenderers/internalLinkCellRenderer.module.scss';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { ExternalLink } from '../../../../../../../components';

const title = (
    <FormattedMessage
        id="campaign.editor.bid.strategy.roas.modal.title"
        tagName="h2"
        defaultMessage="Thanks for your interest in Target ROAS!"
    />
);
const content = (
    <>
        <FormattedMessage
            id="campaign.editor.bid.strategy.roas.modal.content1"
            defaultMessage="We are not quite there yet. While Target ROAS is in development, you can implement pixel-based revenue tracking at any time. This will be a requirement prior to being able to apply Target ROAS as your bid strategy."
        />
        <br />
        <FormattedMessage
            id="campaign.editor.bid.strategy.roas.modal.content2"
            defaultMessage="We recommend following the instructions "
        />
        <ExternalLink
            href="https://help.taboola.com/hc/en-us/articles/360009027493-Adding-Values-to-Conversions"
            className={styles.link}
        >
            <FormattedMessage id="app.here" defaultMessage="here" />
        </ExternalLink>

        <FormattedMessage
            id="campaign.editor.bid.strategy.roas.modal.content3"
            defaultMessage=", or contacting your account manager for more details."
        />
    </>
);

export const dataMetricsComponent = 'Target ROAS pop_up';

export const TargetRoasRadio = props => {
    const { open: openModal } = useModal();
    const { formatMessage } = useIntl();
    const onSelect = useCallback(async () => {
        const result = await openModal({
            title,
            content,
            formProps: {
                cancelButtonText: formatMessage({
                    id: 'campaign.editor.bid.strategy.roas.not.interested',
                    defaultMessage: 'NO THANKS',
                }),
                submitButtonText: formatMessage({
                    id: 'campaign.editor.bid.strategy.roas.interested',
                    defaultMessage: "I'M INTERESTED",
                }),
            },
        });

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value: result ? 'Yes agree' : 'Not interested',
            component: dataMetricsComponent,
        });
    }, [openModal, formatMessage]);

    return <Radio {...props} onClick={onSelect} data-metrics-value="TARGET_ROAS" value="" />;
};
