"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDropdownLarge(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 14,
            height: 9,
            viewBox: "0 0 14 9",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M12.2929 0.448228C12.6834 0.0577034 13.3166 0.0577034 13.7071 0.448228C14.0976 0.838752 14.0976 1.47192 13.7071 1.86244L7.70711 7.86244C7.31658 8.25296 6.68342 8.25296 6.29289 7.86244L0.292893 1.86244C-0.0976305 1.47192 -0.0976305 0.838752 0.292893 0.448228C0.683417 0.0577034 1.31658 0.0577034 1.70711 0.448228L7 5.74112L12.2929 0.448228Z",
            fill: "currentColor"
        })
    );
}
exports.default = SvgDropdownLarge;