import { useFormFieldValue } from '../../taboola-common-frontend-modules/formData';

export const useConditionsField = ({ selectedTemplate }) => {
    const props = useFormFieldValue(
        {
            field: 'conditions',
        },
        { template: selectedTemplate }
    );
    return props;
};
