import { useCallback } from 'react';
import { FormField } from 'taboola-ultimate-ui';
import { OutlineEditableText } from 'modules/campaigns/components';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import styles from './poNumber.module.scss';

const validations = [
    {
        validationFn: validationFunctions.isLength,
        options: { max: 50 },
        messageId: 'validations.error.field.poNumber.length',
    },
];

export const PoNumber = () => {
    const {
        value: poNumber,
        onChange,
        scrollRef,
        indicationData,
    } = useFormValidatedValue({
        field: 'poNumber',
        throttleValue: true,
        validations,
    });

    const { formatMessage } = useIntl();

    const placeholder = formatMessage({
        id: `campaign.group.poNumber.placeholder`,
    });

    const onChangeHandler = useCallback(
        value => {
            if (value === null) {
                onChange('');
                return;
            }
            onChange(value);
        },
        [onChange]
    );

    return (
        <FormField
            ref={scrollRef}
            inputId="po-number"
            containerClass={styles['input']}
            label={<FormattedMessage id="campaign.group.poNumber.label" defaultMessage="PO Number (optional)" />}
        >
            <OutlineEditableText
                id="poNumber"
                className={styles['editable-text']}
                value={poNumber}
                placeholder={placeholder}
                onChange={onChangeHandler}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="PoNumber"
                {...indicationData}
            />
        </FormField>
    );
};
