const isValidAbsolutUrl = string => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
};

const isValidHttpUrlRegex =
    '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\#[-a-z\\d_]*)?$';

const isValidHttpUrlRegexPattern = new RegExp(isValidHttpUrlRegex, 'i');

const isValidHttpUrl = str => {
    if (str.startsWith('www')) {
        return false;
    }
    return isValidHttpUrlRegexPattern.test(str);
};

export const validateUrl = url => {
    return isValidHttpUrl(url) && !isValidAbsolutUrl(url);
};
