import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'tuui';
import { TOOLTIP_POSITION, TooltipV2 as Tooltip } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

const defaultTooltipMessage = (
    <FormattedMessage id="app.beta.tooltip" defaultMessage="This new capability is currently in beta." />
);

const defaultTagMessage = <FormattedMessage id="app.beta" defaultMessage="Beta" />;

export const BetaTag = ({
    tagMessage,
    tooltipMessage,
    containerClassName,
    tagMessageClassName,
    tooltipPosition,
    tooltipOffset,
}) => (
    <span className={containerClassName}>
        <Tag variant={Tag.variant.secondary} size={Tag.size.sm}>
            <span className={tagMessageClassName}>{tagMessage}</span>
        </Tag>
        {tooltipMessage && (
            <Tooltip arrow position={tooltipPosition} offset={tooltipOffset}>
                {tooltipMessage}
            </Tooltip>
        )}
    </span>
);

BetaTag.propTypes = { toolTipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) };

BetaTag.defaultProps = {
    tooltipMessage: defaultTooltipMessage,
    tagMessage: defaultTagMessage,
    tooltipOffset: -1,
    tooltipPosition: TOOLTIP_POSITION.RIGHT,
};
