import { keyBy, identity, some } from 'lodash';
import memoizeOne from 'memoize-one';
import { invertedReportPreset } from '../config/pickers';
import { isCustomReportPreset } from '../utils';

export const reportUnderscoreRegexp = /^[\d\w-]+_/g;

const getChildFieldMap = memoizeOne(columnDefs =>
    keyBy(
        columnDefs.filter(el => el.referenceColId),
        'colId'
    )
);

const getParentFieldMap = memoizeOne(columnDefs => {
    const childRefs = columnDefs.map(({ referenceColId }) => referenceColId).filter(identity);
    const childRefsMap = keyBy(childRefs);
    const parentsMap = keyBy(
        columnDefs.filter(({ colId }) => childRefsMap[colId]),
        'colId'
    );

    return parentsMap;
});

const getColIdsFromColDefs = memoizeOne(columnDefs => columnDefs.map(colDef => colDef.colId));

const isParentColId = (columnDefs, colId = '') => getParentFieldMap(columnDefs)[colId];

const isChildColId = (columnDefs, colId = '') => getChildFieldMap(columnDefs)[colId];

const isChildColIdOfParent = (columnDefs, colId, parentColId) =>
    columnDefs.some(el => el.colId === colId && el.referenceColId === parentColId);

const removeChildColIds = (columnDefs, selectedColIds, colId) =>
    selectedColIds.filter(id => !isChildColIdOfParent(columnDefs, id, colId));

const getChildrenColIdsByParent = (columnDefs, parentColId) =>
    getColIdsFromColDefs(columnDefs).filter(id => isChildColIdOfParent(columnDefs, id, parentColId));

const hasAnyChild = columnsDef => columnsDef.some(({ referenceColId }) => referenceColId);

const hasConversions = groupedColumns =>
    Object.keys(groupedColumns).some(presetName => !invertedReportPreset[presetName]);

const toggleChildColumns = (columnDefs, selectedColIds, shouldAddChilds) => {
    if (!shouldAddChilds) {
        return selectedColIds.filter(colId => !isChildColId(columnDefs, colId));
    }

    const finalColIds = selectedColIds.reduce((result, colId) => {
        result.push(colId);
        if (isParentColId(columnDefs, colId)) {
            return result.concat(getChildrenColIdsByParent(columnDefs, colId));
        }
        return result;
    }, []);

    return finalColIds;
};

const isCompareColumnsShown = (columnDefs, selectedColumnIds) =>
    some(selectedColumnIds, colId => isChildColId(columnDefs, colId));

const getCleanColId = colId => colId.replace(reportUnderscoreRegexp, '');

const getFieldIdsByColIds = (columnDefs, selectedColumnIds = []) => selectedColumnIds.map(getCleanColId);

const hasMissedChildrenForVisibleParent = (presetName, columnDefs, columnState) => {
    if (!isCustomReportPreset(presetName)) {
        return false;
    }
    const columnStateMap = keyBy(columnState, 'colId');
    const hasHiddenChilds = columnDefs.some(
        ({ colId, referenceColId }) =>
            referenceColId &&
            !columnStateMap[colId] &&
            columnStateMap[referenceColId] &&
            !columnStateMap[referenceColId].hide
    );

    return hasHiddenChilds;
};

const injectMissedChildrenForVisibleParents = (columnDefs, columnState) => {
    const columnDefsMap = keyBy(columnDefs, 'colId');
    const atLeastOneChildVisible = columnState.some(
        ({ colId, hide }) => columnDefsMap[colId] && isChildColId(columnDefs, colId) && !hide
    );
    const columnStateWOChildren = columnState.filter(
        ({ colId }) => !columnDefsMap[colId] || !isChildColId(columnDefs, colId)
    );
    let result = [];

    columnStateWOChildren.forEach(el => {
        const { colId } = el;

        result.push(el);
        if (!columnDefsMap[el.colId] || !isParentColId(columnDefs, colId)) {
            return;
        }

        //el is parent here and we should append it's children
        const children = getChildrenColIdsByParent(columnDefs, colId).map(colId => ({
            ...el,
            hide: !atLeastOneChildVisible || el.hide,
            colId,
        }));

        result = [...result, ...children];
    });

    return result;
};

export {
    isParentColId,
    isChildColId,
    hasAnyChild,
    hasConversions,
    isCompareColumnsShown,
    removeChildColIds,
    toggleChildColumns,
    getFieldIdsByColIds,
    getCleanColId,
    getChildrenColIdsByParent,
    hasMissedChildrenForVisibleParent,
    injectMissedChildrenForVisibleParents,
};
