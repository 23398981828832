import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useCurrentValueGetter } from 'hooks';
import { COMPONENT_STATUS } from '../../../../services/constants';
import { useSelectedReportId } from '../../../campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { columnIdDelimiter } from '../../../campaigns/modules/campaigns-reports/utils/generateColumnId';
import { reportDataSelector, reportDataStatusSelector } from '../../../campaigns/selectors';

export const useCanEditCell = () => {
    const [selectedReport] = useSelectedReportId();
    const reportData = useSelector(reportDataSelector);
    const reportDataStatus = useSelector(reportDataStatusSelector);

    const selectedReportGetter = useCurrentValueGetter(selectedReport);
    const reportDataGetter = useCurrentValueGetter(reportData);
    const reportDataStatusGetter = useCurrentValueGetter(reportDataStatus);

    const canEditCell = useCallback(
        ({ colId } = {}) => {
            const [report, colName] = `${colId}`.split(columnIdDelimiter);
            const hasColumn = get(reportDataGetter(), '0', {}).hasOwnProperty(colName);
            const canEdit =
                reportDataStatusGetter() === COMPONENT_STATUS.ACTIVE && report === selectedReportGetter() && hasColumn;

            return canEdit;
        },
        [reportDataStatusGetter, selectedReportGetter, reportDataGetter]
    );

    return canEditCell;
};
