import { batch } from 'react-redux';
import { fetchAccountSuccess, requestFetchAccount, setCurrentRootAccount } from '../actions';
import { gtmTracker } from '../modules/taboola-common-frontend-modules/gtmTracker';
import { accountsApi } from '../services/api';

const fetchCurrentRootAccount = () => async dispatch => {
    dispatch(requestFetchAccount());

    try {
        const currentRootAccount = await accountsApi.getCurrentRootAccount();
        batch(() => {
            dispatch(fetchAccountSuccess(currentRootAccount));
            dispatch(setCurrentRootAccount(currentRootAccount.accountId));
        });
    } catch (error) {
        gtmTracker.trackError(error);
    }
};

export default fetchCurrentRootAccount;
