import { keyBy } from 'lodash';
import { DELETE_CAMPAIGN_GROUP_SUCCESS } from 'modules/campaigns/actions';
import { REPORT_DATA_MAP_VALUES } from 'modules/campaigns/config';
import { createReducer } from 'modules/taboola-common-frontend-modules/utils/reducerUtils';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';
import { addPersistHandler } from 'services/persister';
import {
    updateReportDimensionMapOnError,
    updateReportDimensionMapOnSuccess,
} from '../../../reducer/reportDimensionStateUpdater';
import {
    CREATE_CAMPAIGNS_GROUP_SUCCESS,
    FETCH_CAMPAIGN_GROUP_LIST_SUCCESS,
    FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR,
    FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS,
    SAVE_CAMPAIGNS_GROUP_ERROR,
    SAVE_CAMPAIGNS_GROUP_SUCCESS,
} from '../actions';

const REDUCER_NAME = 'campaignsGroupReducer';

export const initialState = {
    [REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS]: {},
};

const fetchCampaignsGroupForEditSuccessHandler = (state, action) => {
    const extendedCampaignsGroup = { ...action.payload };
    const stateWithUpdatedCampaignsGroup = updateReportDimensionMapOnSuccess(
        state,
        action,
        REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS
    );
    return updateObject(stateWithUpdatedCampaignsGroup, {
        campaignsGroupBeingEdited: extendedCampaignsGroup.campaignsGroupId,
    });
};

const saveCampaignsGroupErrorHandler = (state, action) =>
    updateReportDimensionMapOnError(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS);

const saveCampaignsGroupSuccessHandler = (state, action) => {
    const stateWithUpdatedCampaignsGroup = updateReportDimensionMapOnSuccess(
        state,
        action,
        REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS
    );

    return updateObject(stateWithUpdatedCampaignsGroup, {
        campaignsGroupBeingEdited: null,
    });
};

const deleteCampaignGroupSuccessHandler = (state, action) => {
    return updateReportDimensionMapOnSuccess(state, action, REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS);
};

export const fetchCampaignGroupListSuccess = (state, { payload }) => {
    const newValues = keyBy(payload, 'id');

    return updateObject(state, {
        [REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS]: { ...state[REPORT_DATA_MAP_VALUES.CAMPAIGNS_GROUPS], ...newValues },
    });
};

const reducer = {
    [FETCH_CAMPAIGNS_GROUP_FOR_EDIT_SUCCESS]: fetchCampaignsGroupForEditSuccessHandler,
    [FETCH_CAMPAIGN_GROUP_LIST_SUCCESS]: fetchCampaignGroupListSuccess,
    [FETCH_CAMPAIGNS_GROUP_FOR_EDIT_ERROR]: state => updateObject(state, { campaignsGroupBeingEdited: null }),
    [SAVE_CAMPAIGNS_GROUP_SUCCESS]: saveCampaignsGroupSuccessHandler,
    [CREATE_CAMPAIGNS_GROUP_SUCCESS]: saveCampaignsGroupSuccessHandler,
    [DELETE_CAMPAIGN_GROUP_SUCCESS]: deleteCampaignGroupSuccessHandler,
    [SAVE_CAMPAIGNS_GROUP_ERROR]: saveCampaignsGroupErrorHandler,
};

const campaignsGroupReducer = createReducer(initialState, addPersistHandler(reducer, REDUCER_NAME));

export default campaignsGroupReducer;
export { reducer };
