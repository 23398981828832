import React from 'react';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './videoPreviewHeader.module.scss';

export const VideoPreviewHeader = () => {
    return (
        <>
            <div className={styles['header-label']}>
                <FormattedMessage id="video.creative.preview.header" defaultMessage="Preview" />
            </div>
            <div className={styles['header-sub-label']}>
                <FormattedMessage
                    id="video.creative.creator.preview.best.practice"
                    defaultMessage="View your ads on various placements across the Taboola network, see <a>best practices.</a>"
                />
            </div>
        </>
    );
};
