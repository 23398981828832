import { VTA_LOOKBACK_WINDOW_INPUT_TYPE } from 'modules/tracking/components/VtaLookBackWindowField/VtaLookbackWindowInputType';
import { AGGREGATION_TYPE } from './aggregationTypeOptions';
import { CONVERSION_TYPE } from './constants';

export const conversionInitialValues = {
    type: CONVERSION_TYPE.BASIC,
    dynamicParameters: [],
    condition: [],
    lookBackWindow: 30,
    vtaLookBackWindow: {
        type: ({ shouldReturnDaysType }) =>
            shouldReturnDaysType ? VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS : VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS,
        value: ({ value }) => value,
    },
    includeInTotalConversions: true,
    includeInTotalValue: true,
    excludeFromCampaigns: false,
    aggregationType: AGGREGATION_TYPE.AGGREGATED,
};
