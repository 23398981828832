import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { addIndication } from '../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { getColumnsNumber } from '../../config';

const indicationUnsupportedFileFormat = {
    message: (
        <FormattedMessage
            id="audience.editor.external-audience.keywords.CSV.validation.error"
            defaultMessage="Unsupported file name, the file name must be in English characters without spaces"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

export const useExternalAudienceValidations = () => {
    const dispatch = useDispatch();

    const validateFileFormat = useCallback(
        parsedCSV => {
            if (getColumnsNumber(parsedCSV) !== 1) {
                dispatch(addIndication(indicationUnsupportedFileFormat));
                return false;
            }
            return true;
        },
        [dispatch]
    );

    return { validateFileFormat };
};
