// import React from 'react';

// import { MeasuredByImgTag } from '../../creative-editor/components/MeasuredBy/MeasuredBy';
// import OracleAdvertisingTooltipSVG from '../../creative-editor/components/MeasuredBy/Oracle_Advertising_rgb_white.svg';
// import { HeaderTooltip } from '../components/HeaderRenderers/FormattedMessageWithTooltipHeaderRenderer';

// HACK: We need to temporarily disable this feature until we enable moat again
// ticket: https://jira.taboola.com/browse/DEV-151977

// const HEADER_SUFFIX = '(Moat)';

export const addMeasuredByMoatToMetric = metric => metric;
//     ({
//     ...metric,
//     suffix: ` ${HEADER_SUFFIX}`,
// });

const addMeasuredByMoatToField = field => {
    return field;
    // const { label, headerComponentParams } = field;
    // return {
    //     ...field,
    //     label: `${label} ${HEADER_SUFFIX}`,
    //     headerComponentParams: {
    //         ...headerComponentParams,
    //         useOnlyValue: true,
    //         tooltipContent: props => (
    //             <HeaderTooltip {...props}>
    //                 <br />
    //                 <br />
    //                 <MeasuredByImgTag img={OracleAdvertisingTooltipSVG} imageClassName="tooltip-img" />
    //             </HeaderTooltip>
    //         ),
    //     },
    // };
};

export default addMeasuredByMoatToField;
