import React from 'react';
import { REPORT_TYPE } from 'modules/campaigns/config';
import { CREATIVE_STATUS } from '../../../../../../config';
import { useSelectedReportId } from '../../../../hooks';
import { MultiStepDescription } from '../../OnboardingStepDescription/MultiStepDescription';
import { STEP_IDS, SUB_STEP_IDS } from '../../consts';

const { LAUNCH_YOUR_CAMPAIGN } = STEP_IDS;
const { LAUNCH_YOUR_CAMPAIGN_1, LAUNCH_YOUR_CAMPAIGN_2 } = SUB_STEP_IDS;
const { RUNNING, PENDING_APPROVAL, REJECTED } = CREATIVE_STATUS;
const { CAMPAIGN, CREATIVE } = REPORT_TYPE;

const adsStatusDescriptions = {
    [RUNNING]: 'approved',
    [PENDING_APPROVAL]: 'pending',
    [REJECTED]: 'rejected',
};

export const LaunchCampaignStep = ({ isCollapsed, stepCompleteData, lowestCreativesStatus }) => {
    const { hasMinimumAdsPerCampaignRunning, hasLearningOrScalingCampaigns } = stepCompleteData;
    const adsStatusDescription = adsStatusDescriptions[lowestCreativesStatus] || 'default';
    const [selectedReport, setSelectedReport] = useSelectedReportId();

    const checkAndSetReport = report => {
        if (selectedReport !== report) {
            setSelectedReport(report);
        }
    };

    return (
        <MultiStepDescription
            titleId={LAUNCH_YOUR_CAMPAIGN}
            subStepData={[
                {
                    descriptionId: `${LAUNCH_YOUR_CAMPAIGN_1}.${adsStatusDescription}`,
                    buttons: [
                        {
                            stepId: LAUNCH_YOUR_CAMPAIGN_1,
                            onClickCTA: () => checkAndSetReport(CREATIVE),
                            isComplete: hasMinimumAdsPerCampaignRunning,
                        },
                    ],
                    learnMoreLinkId: LAUNCH_YOUR_CAMPAIGN_1,
                },
                {
                    descriptionId: LAUNCH_YOUR_CAMPAIGN_2,
                    buttons: [
                        {
                            stepId: LAUNCH_YOUR_CAMPAIGN_2,
                            onClickCTA: () => checkAndSetReport(CAMPAIGN),
                            isComplete: hasLearningOrScalingCampaigns,
                        },
                    ],
                    learnMoreLinkId: LAUNCH_YOUR_CAMPAIGN_2,
                },
            ]}
            isCollapsed={isCollapsed}
        />
    );
};
