import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { PERFORMANCE_RECOMMENDATIONS_CAMPAIGN_CARD_TYPES } from '../../config/performanceRecommendationsCampaignCardTypes';
import { ReactComponent as EmptyRecommendationsIcon } from './empty-recommendations.svg';
import styles from './performanceRecommendationsContent.module.scss';

const EmptyState = () => (
    <div className={styles['empty-state']}>
        <EmptyRecommendationsIcon />
        <div className={styles['message-container']}>
            <FormattedMessage
                id="app.performance-recommendations.no-recommendations"
                defaultMessage="No recommendations for this campaign yet"
            />
        </div>
    </div>
);

export const PerformanceRecommendationsContent = ({ recommendations = [] }) => {
    const isEmpty = recommendations?.length < 1;
    const activeRecommendations = useMemo(
        () =>
            recommendations.filter(recommendation => {
                return !recommendation.usedTimeUtc;
            }),
        [recommendations]
    );
    const appliedRecommendations = useMemo(
        () =>
            _(recommendations)
                .filter(recommendation => {
                    return recommendation.usedTimeUtc;
                })
                .sortBy(rec => +new Date(rec.usedTimeUtc))
                .reverse()
                .value(),
        [recommendations]
    );
    if (isEmpty) {
        return <EmptyState />;
    }

    return (
        <div aria-label="Performance Recommendations Content">
            {activeRecommendations.map(recommendation => {
                const { id, type } = recommendation;
                const RecommendationCard = PERFORMANCE_RECOMMENDATIONS_CAMPAIGN_CARD_TYPES[type];
                return RecommendationCard ? (
                    <RecommendationCard key={id} recommendation={recommendation} {...recommendation} />
                ) : null;
            })}
            {appliedRecommendations?.length > 0 && (
                <div className={styles['applied-recs-label']}>
                    <FormattedMessage
                        id="app.performance-recommendations.applied-recommendations"
                        defaultMessage="Applied Recommendations"
                    />
                </div>
            )}
            {appliedRecommendations.map(recommendation => {
                const { id, type } = recommendation;
                const RecommendationCard = PERFORMANCE_RECOMMENDATIONS_CAMPAIGN_CARD_TYPES[type];
                return <RecommendationCard key={id} recommendation={recommendation} {...recommendation} />;
            })}
        </div>
    );
};

PerformanceRecommendationsContent.propTypes = {
    recommendations: PropTypes.array,
    applyRecommendation: PropTypes.func,
};

export default PerformanceRecommendationsContent;
