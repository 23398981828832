import React from 'react';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const AdsTooltip = () => (
    <TooltipSection
        tip={
            <FormattedMessage
                id="campaign.editor.duplicateCampaignSettings.ads.tooltip.tip"
                defaultMessage="select this option only if you plan to use the same (or very similar) ads in your new campaign."
            />
        }
    >
        <FormattedMessage
            id="campaign.editor.duplicateCampaignSettings.ads.tooltip.main"
            defaultMessage="Duplicate all ads from the original campaign. (Includes any related settings - e.g. campaign review status, ad review status and campaign safety rating.)"
        />
    </TooltipSection>
);

export default AdsTooltip;
