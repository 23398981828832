export const PLATFORM_PARTING_DEFAULT = [
    {
        platform: 'DESK',
        cpcModification: 0,
    },
    {
        platform: 'PHON',
        cpcModification: 0,
    },
    {
        platform: 'TBLT',
        cpcModification: 0,
    },
];
