import React, { useMemo } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { BareDropdown } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import ContextualSegmentsTree from '../ContextualSegmentsTree/ContextualSegmentsTree';

const getMenuStyle = width => ({
    menu: provided => ({
        ...provided,
        width,
        right: 0,
    }),
});

const ContextualSegmentsDropdown = ({ search, onSearchChange, width }) => {
    const dropdownStyle = useMemo(() => getMenuStyle(width), [width]);
    return (
        <BareDropdown
            id="contextual-targeting-dropdown"
            onChange={noop}
            placeholder={
                <FormattedMessage
                    id="campaign.editor.targeting.contextual.segments.select.placeholder"
                    defaultMessage="Select segments..."
                />
            }
            searchable
            inputValue={search}
            onInputChange={text => onSearchChange(text)}
            menuListRenderer={ContextualSegmentsTree}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            styles={dropdownStyle}
        />
    );
};

ContextualSegmentsDropdown.propTypes = {
    search: PropTypes.string,
    onSearchChange: PropTypes.func,
    width: PropTypes.number,
};

export default ContextualSegmentsDropdown;
