import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const BudgetFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.budget.tooltip"
            defaultMessage="The overall spending limit for this campaign. The campaign stops when this limit is reached, or when the campaign reaches its end date (whichever comes first)."
        />
    </TooltipSection>
);

export default BudgetFieldTooltip;
