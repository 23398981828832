import { REPORT_TYPE } from 'modules/campaigns/config/reportsBaseConfig';
import { useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks/useSelectedReportId';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useIsHeavyAccount } from './useIsHeavyAccount';

export const useIsHeavyReport = () => {
    const [reportId] = useSelectedReportId();
    const campaignsReport = useIsHeavyAccount();
    const creativesReport = useConfigMatch({ [FEATURE_FLAGS.HEAVY_CREATIVES_REPORT]: 'true' });

    switch (reportId) {
        case REPORT_TYPE.CAMPAIGN:
            return campaignsReport;
        case REPORT_TYPE.CREATIVE:
            return creativesReport;
        default:
            return false;
    }
};
