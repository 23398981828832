import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MESSAGE_TYPE } from 'modules/taboola-common-frontend-modules/iframe/messageType';
import { sendMessage } from 'modules/taboola-common-frontend-modules/iframe/utils/sendMessage';
import { authentificationSelector, usePermissions } from '../../../taboola-common-frontend-modules/authentication';
import styles from './genericError.module.scss';

const GenericError = props => {
    const { heading, message, errorImgUrl } = props;
    const isErrorDetailsPermitted = usePermissions('SHOW_GRID_LOAD_TIME');
    const isAuthenticated = useSelector(authentificationSelector);
    const error = useSelector(state => state?.errorReducer?.detail);

    return (
        <div className={styles['generic-error']}>
            <img src={errorImgUrl} alt="error" />
            <h1 className={styles['error-heading']}>{heading}</h1>
            <p className={styles['error-message']}>{message}</p>
            <div className={styles['error-message']}>
                {(isErrorDetailsPermitted || !isAuthenticated) && error?.message}
            </div>
            <Button
                className={styles['reload-button']}
                onClick={() => {
                    sendMessage(MESSAGE_TYPE.FATAL_ERROR);
                    window.location = window.location.origin;
                }}
                variant={Button.variant.ghost}
            >
                <FormattedMessage id="app.common.reload" defaultMessage="Reload" />
            </Button>
        </div>
    );
};

GenericError.propTypes = {
    /** The header of the error message */
    heading: PropTypes.node,
    /** The error message to display */
    message: PropTypes.node,
    /** The url of the image to display */
    errorImgUrl: PropTypes.string,
};

export default GenericError;
