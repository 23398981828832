import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { EditSmallIcon } from 'taboola-ultimate-ui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import styles from './thumbnailEditBanner.module.scss';

const classNameBuilder = classnames.bind(styles);

const ThumbnailEditBanner = ({ className, onClick, text, dataMetrics }) => (
    <div
        className={classNameBuilder('banner', className)}
        onClick={onClick}
        data-metrics-event-name={GTM_EVENTS.USABILITY}
        data-metrics-component={dataMetrics}
        aria-label={`thumbnail ${dataMetrics} banner`}
    >
        <EditSmallIcon aria-label="banner icon" />
        <span className={styles['edit-label']}>{text}</span>
    </div>
);

ThumbnailEditBanner.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.node,
};

export default ThumbnailEditBanner;
