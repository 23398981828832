import React from 'react';
import { StatusIndicator } from 'tuui';
import EnumValueCellRenderer from './EnumValueCellRenderer';
import styles from './statusIndicatorCellRenderer.module.scss';

export const StatusIndicatorCellRenderer = ({ value, indicatorType, msgIdPrefix, emptyValue }) => {
    return (
        <div className={styles['container']}>
            <div className={styles['status-container']}>
                <div className={styles['status-indicator-and-label-container']}>
                    {indicatorType ? <StatusIndicator type={indicatorType} className={styles['indicator']} /> : null}
                    <EnumValueCellRenderer value={value} msgIdPrefix={msgIdPrefix} emptyValue={emptyValue} />
                </div>
            </div>
        </div>
    );
};
