import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { FormattedMessageWithTooltipHeaderRenderer } from '../../../components/HeaderRenderers';
import {
    gridNumberFormatter,
    formattedNumberValueGetter,
    csvDateHeaderValueGetter,
} from '../../../components/ValueFormatters';
import { normalizeColumnDefs } from '../../../utils';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import { leftPinnedColumn, keyColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const weekReportColumnDefinition = [
    {
        headerName: FIELDS.DATE.label,
        field: FIELDS.DATE.field,
        headerValueGetter: FIELDS.DATE.headerValueGetter,
        headerComponentParams: FIELDS.DATE.headerComponentParams,
        type: [leftPinnedColumn, keyColumn],
        valueFormatter: FIELDS.DATE.valueFormatter,
        maxWidth: 180,
        csvValueGetter: FIELDS.DATE.csvValueGetter,
        csvHeaderValueGetter: csvDateHeaderValueGetter,
    },
    ...[
        FIELDS.SPENT,
        FIELDS.COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CPA,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(weekReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.WEEK_VIDEO].id);
