import { useQuery } from 'react-query';
import { useResourcesApi } from '../../../services/api';
import { useIntl } from '../../taboola-common-frontend-modules/i18n';

const emptyArray = [];
export const useConversionCategory = () => {
    const { getConversionCategories } = useResourcesApi();
    const { formatMessage } = useIntl();

    const { data: conversionCategoriesData = emptyArray } = useQuery(
        ['getConversionCategories'],
        () => getConversionCategories(),
        {
            refetchOnWindowFocus: false,
            select: data =>
                data.map(({ value, name }) => ({
                    value: name,
                    label: formatMessage({
                        id: `tracking.conversion.category.${name}`,
                        defaultMessage: { value },
                    }),
                })),
        }
    );
    return conversionCategoriesData;
};
