'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _creatable = require('react-select/creatable');

var _creatable2 = _interopRequireDefault(_creatable);

var _reactSelect = require('react-select');

var _uuid = require('uuid');

var _styleUtils = require('../../../style-utils');

var _styleUtils2 = _interopRequireDefault(_styleUtils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'grayFive': 'var(--gray-5)',
    'white': 'var(--white)',
    'blue': 'var(--blue)',
    'blueSix': 'var(--blue-6)',
    'taboola-svg-icon': 'tagsInput__taboola-svg-icon___3Sdvs'
};

// overrides the createable select value container using react-select components api

var ValueContainer = function ValueContainer(_ref) {
    var children = _ref.children,
        props = _objectWithoutProperties(_ref, ['children']);

    return (
        // renders the value container children, but need to remove the built in placeholder
        // that is passed by the top level select component
        // then replace that placeholder with our own placeholder component that is based on
        // the input instead of the value
        _react2.default.createElement(
            _reactSelect.components.ValueContainer,
            props,
            _react2.default.Children.map(children, function (child) {
                return child && child.type !== _reactSelect.components.Placeholder ? child : null;
            }),
            props.selectProps.inputValue ? null : _react2.default.createElement(
                _reactSelect.components.Placeholder,
                props,
                props.selectProps.placeholder
            )
        )
    );
};

ValueContainer.propTypes = {
    children: _propTypes2.default.node,
    selectProps: _propTypes2.default.any
};

var ACTION_TYPE_REMOVE_VALUE = 'remove-value';

var tagsInputStyles = {
    control: function control() {
        return {
            borderStyle: 'solid',
            borderRadius: (0, _styleUtils2.default)(6),
            borderWidth: (0, _styleUtils2.default)(1),
            borderColor: styles.grayFive,
            backgroundColor: styles.white,
            cursor: 'text',
            padding: (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(0) + ' ' + (0, _styleUtils2.default)(0) + ' ' + (0, _styleUtils2.default)(6)
        };
    },
    placeholder: function placeholder() {
        return {
            margin: (0, _styleUtils2.default)(0) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(0),
            padding: (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(8) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(0),
            color: styles.grayFive
        };
    },
    input: function input(provided) {
        return Object.assign({}, provided, {
            marginBottom: (0, _styleUtils2.default)(12),
            marginTop: (0, _styleUtils2.default)(6)
        });
    },
    multiValue: function multiValue(provided) {
        return Object.assign({}, provided, {
            margin: (0, _styleUtils2.default)(0) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(0),
            padding: (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(2) + ' ' + (0, _styleUtils2.default)(6) + ' ' + (0, _styleUtils2.default)(8),
            border: 'none',
            backgroundColor: styles.blue,
            borderRadius: (0, _styleUtils2.default)(4),
            color: styles.white
        });
    },
    valueContainer: function valueContainer(provided) {
        return Object.assign({}, provided, {
            padding: 0
        });
    },
    multiValueLabel: function multiValueLabel() {
        return {};
    },
    multiValueRemove: function multiValueRemove(provided) {
        return Object.assign({}, provided, {
            marginLeft: (0, _styleUtils2.default)(2),
            paddingLeft: (0, _styleUtils2.default)(2),
            paddingRight: (0, _styleUtils2.default)(2),
            '&:hover': {
                backgroundColor: styles.blueSix,
                color: styles.white,
                cursor: 'pointer'
            }
        });
    }
};

var TagsInput = function (_React$Component) {
    _inherits(TagsInput, _React$Component);

    function TagsInput(props) {
        _classCallCheck(this, TagsInput);

        var _this = _possibleConstructorReturn(this, (TagsInput.__proto__ || Object.getPrototypeOf(TagsInput)).call(this, props));

        _this.tagsInputChangeHandler = function (value, action) {
            if (action.action === ACTION_TYPE_REMOVE_VALUE) {
                _this.props.handleDelete(action.removedValue);
            }
        };

        _this.inputChangeHandler = function (input) {
            _this.setState({ input: input });
        };

        _this.keyDownHandler = function (event) {
            if (event.key === 'Enter' && _this.state.input) {
                var id = (0, _uuid.v1)();
                _this.props.handleAddition({
                    label: _this.state.input,
                    value: id
                });
                _this.setState({ input: '' });
                event.preventDefault();
            }
        };

        _this.state = {
            input: ''
        };
        return _this;
    }

    _createClass(TagsInput, [{
        key: 'render',
        value: function render() {
            var props = Object.assign({
                inputValue: this.state.input,
                value: this.props.tags,
                components: { ValueContainer: ValueContainer, DropdownIndicator: null },
                onChange: this.tagsInputChangeHandler,
                onInputChange: this.inputChangeHandler,
                onKeyDown: this.keyDownHandler,
                className: styles['tags-input-container'],
                classNamePrefix: 'tags-input',
                styles: Object.assign({}, tagsInputStyles)
            }, this.props);

            return _react2.default.createElement(_creatable2.default, props);
        }
    }]);

    return TagsInput;
}(_react2.default.Component);

TagsInput.propTypes = {
    tags: _propTypes2.default.arrayOf(_propTypes2.default.object),
    placeholder: _propTypes2.default.string,
    handleDelete: _propTypes2.default.func.isRequired,
    handleAddition: _propTypes2.default.func.isRequired,
    isClearable: _propTypes2.default.bool,
    isMulti: _propTypes2.default.bool,
    menuIsOpen: _propTypes2.default.bool,
    isDisabled: _propTypes2.default.bool
};

TagsInput.defaultProps = {
    isClearable: false,
    isMulti: true,
    menuIsOpen: false,
    placeholder: 'Add keywords...'
};

exports.default = TagsInput;