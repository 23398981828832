import { useMemo } from 'react';
import { useAvailableItemsService } from './useAvailableItemsService';

export const useAvailableItems = (list, conditionData) => {
    const availableItemsHandler = useAvailableItemsService(conditionData);
    const availableList = useMemo(() => availableItemsHandler(list), [availableItemsHandler, list]);

    return availableList;
};

export default useAvailableItems;
