import { useCallback } from 'react';
import { useAudienceSeedFormFields } from './useAudienceSeedFormFields';
import { usePredictiveSeedAudiencesApi } from './usePredictiveSeedAudiencesApi';

export const useAudienceSeed = selectedAccountId => {
    const { audienceList, isLoadingAudienceList } = usePredictiveSeedAudiencesApi(selectedAccountId);
    const { audienceSeedId, onAudienceSeedChange, failedValidationData } = useAudienceSeedFormFields(audienceList);

    const audienceChangeHandler = useCallback(
        ({ value: audienceSeedId }) => {
            onAudienceSeedChange(audienceSeedId);
        },
        [onAudienceSeedChange]
    );

    return {
        audienceSeedId,
        audienceChangeHandler,
        audienceList,
        isLoadingAudienceList,
        failedValidationData,
    };
};
