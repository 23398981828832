import { has, pick } from 'lodash';
import { pricingModelConfig } from 'modules/campaigns/modules/common-campaign-form/components/Bid/BidInputList';
import { BRAND_SAFETY } from 'modules/campaigns/modules/common-campaign-form/components/BrandSafety/brandSafetyValue';
import { TIME_ZONE_METHODS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeParting/consts/timeZoneOptions';
import {
    ALLOW_INCLUDE_SITES_PERMISSION,
    API_BLOCK_SITES_PERMISSION,
} from 'modules/campaigns/modules/common-campaign-form/components/SiteTargeting/const';
import {
    supplyTargetingDropdownOptions,
    supplyTargetingRadioOptions,
} from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/const';
import { getIsSupplyTargetingSelectPermitted } from 'modules/campaigns/modules/common-campaign-form/components/SupplyTargeting/utils/supplyTargetingUtils';
import {
    EVENT_TYPES,
    TAG_TYPE_OPTIONS,
} from 'modules/campaigns/modules/common-campaign-form/components/ThirdPartyTags/config';
import { BUDGET_TYPES, SPENDING_LIMITS } from 'modules/campaigns/modules/common-campaign-form/config';
import BRAND_SAFETY_TYPES from 'modules/campaigns/modules/common-campaign-form/config/BrandSafeTypes';
import TARGETING_TYPES from 'modules/campaigns/modules/common-campaign-form/config/TargetingTypes';
import BID_STRATEGIES from 'modules/campaigns/modules/common-campaign-form/config/bidStrategies';
import CREATIVE_TRAFFIC_METHOD from 'modules/campaigns/modules/common-campaign-form/config/creativeTrafficAllocationMethodTypes';
import MARKETING_OBJECTIVES, {
    MARKETING_OBJECTIVE_KEYS,
} from 'modules/campaigns/modules/common-campaign-form/config/marketingObjectives';
import { OPERATION_REQUIREMENT_TYPES } from 'modules/campaigns/modules/excel-bulk-operations/consts/columnRequiredTypes';
import {
    transformValuesToOptionIds,
    transformOptionsToOptionsWithIds,
} from 'modules/campaigns/modules/excel-bulk-operations/transformers/excelOptionsTransformers';
import {
    campaignTargetingAudienceDataPathGetter,
    transformABTestEndDateFromExcel,
    transformAudiencesFromExcel,
    transformAudiencesToExcel,
    transformBlockSitesFromExcel,
    transformCampaignGroupNameFromExcel,
    transformCampaignGroupIDToExcel,
    transformCityTargetingFromExcel,
    transformCityTargetingToExcel,
    transformConversionRulesFromExcel,
    transformConversionRulesToExcel,
    transformDateFromExcel,
    transformDateToExcel,
    transformDayPartingRulesFromExcel,
    transformDayTimeBidModifierFromExcel,
    transformDayTimeBidModifierToExcel,
    transformMediaFileNameFromExcel,
    transformHyperLinkObjectToString,
    transformListToString,
    transformObjectListToDelimitedString,
    transformOSListFromExcel,
    transformOSToExcel,
    transformPlatformBidModifierFromExcel,
    transformPlatformListToString,
    transformPlatformStringToList,
    transformPublisherTargetingToExcel,
    transformStringToList,
    transformTargetingTypeToExcel,
    transformTargetingTypeFromExcel,
    translateOptionFromExcel,
    translateOptionToExcel,
    transformCTATypeFromExcel,
    transformCTATypeToExcel,
    transformTextToExcel,
    transformBudgetTypeFromExcel,
    viewThroughWindowDisabledFromExcel,
    transformThirdPartyBrandSafetyListToExcel,
    translateThirdPartyBrandSafetyOptionToExcel,
    transformThirdPartyBrandSafetyListFromExcel,
    transformAccountNameFromExcel,
    transformPublisherBidModificationFromExcel,
    transformPublisherBidModificationToExcel,
} from 'modules/campaigns/modules/excel-bulk-operations/transformers/excelValueTransformers';
import {
    getExampleTextWithCurrency,
    getIsRowCampaignUpdate,
    getIsRowCreativeUpdate,
    getMarketplaceAudiencesAndExampleText,
    getMarketplaceAudiencesAndHeaderName,
    getSupplyTargetingSelectHeaderName,
    getThirdPartyExampleText,
    getThirdPartyHeaderName,
} from 'modules/campaigns/modules/excel-bulk-operations/utils/excelBulkWorkbookUtils';
import { COMMON_FLAGS, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { VTA_LOOKBACK_WINDOW_INPUT_TYPE } from 'modules/tracking/components/VtaLookBackWindowField/VtaLookbackWindowInputType';
import { AGGREGATION_TYPE } from 'modules/tracking/config/aggregationTypeOptions';

export const STATIC_OPTION_MESSAGE_PREFIX = 'excel.bulk.static.global.option';

const getIsFeatureFlagEnabled = (accountConfig, featureFlag) => accountConfig?.[featureFlag] === 'true';

const getIsThirdPartyTagsEnabled = ({ accountConfig, permissionsMap }) => {
    const isTrackingSectionEnabledByConfig = getIsFeatureFlagEnabled(
        accountConfig,
        FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING
    );
    const isTrackingSectionEnabledByPermission = has(permissionsMap, 'CREATIVE_EDIT_TRACKING');
    const isTrackingSectionEnabled = isTrackingSectionEnabledByConfig || isTrackingSectionEnabledByPermission;
    const isTrackingPixelsEditPermitted = has(permissionsMap, 'CAMPAIGN_TRACKING_PIXELS_EDIT');

    return isTrackingSectionEnabled && isTrackingPixelsEditPermitted;
};

export const id = {
    field: 'id',
    width: 30,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.UPDATE,
};

export const name = {
    field: 'name',
    width: 30,
    transformToExcel: transformTextToExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const brandingText = {
    field: 'brandingText',
    defaultValue: '',
    transformToExcel: transformTextToExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const marketingObjective = {
    field: 'marketingObjective',
    options: MARKETING_OBJECTIVES,
    optionMessagePrefix: 'campaign.marketing.objective',
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    defaultValue: MARKETING_OBJECTIVE_KEYS.LEADS_GENERATION,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const conversionRulesRules = {
    field: 'conversionRules.rules',
    transformToExcel: transformConversionRulesToExcel,
    transformFromExcel: transformConversionRulesFromExcel,
};

export const dayPartingTimezone = {
    field: 'campaignSchedule.timeZone',
    defaultValue: TIME_ZONE_METHODS.USER_TIME_ZONE,
    accountConfigurations: { [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true' },
};

export const dayPartingRules = {
    field: 'campaignSchedule.rules',
    transformToExcel: value => transformObjectListToDelimitedString(value, { keysToOmit: ['id'] }),
    transformFromExcel: transformDayPartingRulesFromExcel,
    defaultValue: [],
};

const countryTargetingValuesField = 'campaignTargeting.countryTargeting.values';
export const countryTargetingType = {
    field: 'campaignTargeting.countryTargeting.type',
    options: [{ id: TARGETING_TYPES.ALL }, { id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: countryTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: countryTargetingValuesField }),
    defaultValue: TARGETING_TYPES.ALL,
};

export const countryTargetingValues = {
    field: countryTargetingValuesField,
    transformToExcel: transformListToString,
    transformFromExcel: transformStringToList,
    defaultValue: null,
    isText: true,
};

export const platformTargeting = {
    field: 'platformTargeting',
    transformToExcel: transformPlatformListToString,
    transformFromExcel: transformPlatformStringToList,
};

const osTargetingValuesField = 'campaignTargeting.osTargeting.values';
export const osTargetingType = {
    field: 'campaignTargeting.osTargeting.type',
    options: [{ id: TARGETING_TYPES.ALL }, { id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: osTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: osTargetingValuesField }),
    defaultValue: TARGETING_TYPES.ALL,
};

export const osTargetingValues = {
    field: osTargetingValuesField,
    transformToExcel: transformOSToExcel,
    transformFromExcel: transformOSListFromExcel,
    defaultValue: [],
};

const browserTargetingValuesField = 'campaignTargeting.browserTargeting.values';
export const browserTargetingType = {
    field: 'campaignTargeting.browserTargeting.type',
    options: [{ id: TARGETING_TYPES.ALL }, { id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: browserTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: browserTargetingValuesField }),
    defaultValue: TARGETING_TYPES.ALL,
};

export const browserTargetingValues = {
    field: browserTargetingValuesField,
    transformFromExcel: transformStringToList,
    transformToExcel: transformListToString,
    defaultValue: [],
};

export const blockSitesExclude = {
    field: 'campaignTargeting.publisherTargeting.exclude',
    transformToExcel: transformPublisherTargetingToExcel,
    transformFromExcel: transformBlockSitesFromExcel,
    permissions: [API_BLOCK_SITES_PERMISSION],
    defaultValue: [],
};

export const blockSitesInclude = {
    field: 'campaignTargeting.publisherTargeting.include',
    transformToExcel: transformPublisherTargetingToExcel,
    transformFromExcel: transformBlockSitesFromExcel,
    permissions: [API_BLOCK_SITES_PERMISSION, ALLOW_INCLUDE_SITES_PERMISSION],
    defaultValue: [],
};

export const supplyTargeting = {
    field: 'campaignTargeting.supplyTargeting',
    visibilityCondition: ({ accountConfig }) =>
        getIsFeatureFlagEnabled(accountConfig, FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED),
    optionMessagePrefix: 'campaign.editor.targeting.supply',
    options: supplyTargetingDropdownOptions,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
};

export const supplyTargetingSelect = {
    field: 'campaignTargeting.supplyTargeting',
    visibilityCondition: ({ selectedAccount, accountConfig }) => {
        const isSupplyTargetingEnabled = getIsFeatureFlagEnabled(accountConfig, FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED);
        const isTaboolaSelectEnabled = getIsFeatureFlagEnabled(accountConfig, FEATURE_FLAGS.TABOOLA_SELECT_ENABLED);
        const isSelectForPMPEnabled = getIsFeatureFlagEnabled(
            accountConfig,
            FEATURE_FLAGS.SUPPLY_TARGETING_PMP_ENABLED
        );
        const isSelectForVideoEnabled = getIsFeatureFlagEnabled(
            accountConfig,
            FEATURE_FLAGS.SUPPLY_TARGETING_VIDEO_ENABLED
        );
        const isSupplyTargetingSelectPermitted = getIsSupplyTargetingSelectPermitted({
            selectedAccount,
            isTaboolaSelectEnabled,
            isSelectForPMPEnabled,
            isSelectForVideoEnabled,
        });

        return !isSupplyTargetingEnabled && isSupplyTargetingSelectPermitted;
    },
    getCustomHeaderName: getSupplyTargetingSelectHeaderName,
    optionMessagePrefix: 'campaign.editor.targeting.supply',
    options: supplyTargetingRadioOptions,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
};

export const brandSafety = {
    field: 'brandSafetyTargeting',
    options: [{ id: BRAND_SAFETY.ALL }, { id: BRAND_SAFETY.SAFE }],
    permissions: ['SAFETY_TARGETING_EDIT_TA'],
};

export const bidStrategy = {
    field: 'bidStrategy',
    optionMessagePrefix: 'campaign.editor.bid.strategy',
    options: [
        { id: BID_STRATEGIES.SMART },
        { id: BID_STRATEGIES.MAX_CONVERSIONS },
        { id: BID_STRATEGIES.TARGET_CPA },
        { id: BID_STRATEGIES.FIXED },
    ],
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const cpaGoal = {
    field: 'cpaGoal',
    right: true,
    getCustomExampleText: getExampleTextWithCurrency,
};

export const pricingModel = {
    field: 'pricingModel',
    options: pricingModelConfig.map(config => pick(config, ['id', 'permissions', 'visibilityCondition'])),
    getIsDisabled: getIsRowCampaignUpdate,
};

export const bid = {
    field: 'bid',
    right: true,
    getCustomExampleText: getExampleTextWithCurrency,
};

export const budgetType = {
    field: 'budget.type',
    options: [{ id: BUDGET_TYPES.DAILY }, { id: BUDGET_TYPES.MONTHLY }, { id: BUDGET_TYPES.LIFETIME }],
    transformFromExcel: transformBudgetTypeFromExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const budgetValue = {
    field: 'budget.value',
    right: true,
    getCustomExampleText: getExampleTextWithCurrency,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const budgetSpendingLimitType = {
    field: 'budget.spendingLimit.type',
    options: [{ id: SPENDING_LIMITS.NONE }, { id: SPENDING_LIMITS.MONTHLY }, { id: SPENDING_LIMITS.LIFETIME }],
    defaultValue: SPENDING_LIMITS.NONE,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const budgetSpendingLimitValue = {
    field: 'budget.spendingLimit.value',
    right: true,
    getCustomExampleText: getExampleTextWithCurrency,
};

export const adOptimization = {
    field: 'creativeTrafficAllocationMethod',
    options: [{ id: CREATIVE_TRAFFIC_METHOD.OPTIMIZED }, { id: CREATIVE_TRAFFIC_METHOD.EVEN }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
};

export const abTestEndDate = {
    field: 'creativeTrafficAllocationABTestEndDate',
    isText: true,
    transformFromExcel: transformABTestEndDateFromExcel,
    transformToExcel: transformDateToExcel,
    right: true,
};

export const trackingCode = {
    field: 'trackingCode',
};

export const campaignGroupId = {
    field: 'campaignGroupId',
    accountConfigurations: { [FEATURE_FLAGS.CAMPAIGN_GROUP_EXTENSION_ENABLED]: 'true' },
    transformFromExcel: transformCampaignGroupNameFromExcel,
    transformToExcel: transformCampaignGroupIDToExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
    getIsDisabled: getIsRowCampaignUpdate,
};

export const lookBackWindow = {
    field: 'conversionConfiguration.lookBackWindow',
    right: true,
    accountConfigurations: {
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    },
    defaultValue: 0,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const vtaLookBackWindowType = {
    field: 'conversionConfiguration.vtaLookBackWindow.type',
    options: [{ id: VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS }, { id: VTA_LOOKBACK_WINDOW_INPUT_TYPE.HOURS }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    accountConfigurations: {
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    },
    defaultValue: VTA_LOOKBACK_WINDOW_INPUT_TYPE.DAYS,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const vtaLookBackWindowValue = {
    field: 'conversionConfiguration.vtaLookBackWindow.value',
    right: true,
    accountConfigurations: {
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    },
    defaultValue: 0,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const aggregationType = {
    field: 'conversionConfiguration.aggregationType',
    options: [{ id: AGGREGATION_TYPE.AGGREGATED }, { id: AGGREGATION_TYPE.LAST_VALUE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    accountConfigurations: {
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    },
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const isViewThroughWindowDisabled = {
    field: 'conversionConfiguration.isViewThroughWindowDisabled',
    options: [{ id: 'true' }, { id: 'false' }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: translateOptionToExcel,
    transformFromExcel: viewThroughWindowDisabledFromExcel,
    accountConfigurations: {
        [FEATURE_FLAGS.CONVERSION_AGGREGATED_TYPE_ENABLED]: 'true',
        [FEATURE_FLAGS.CAMPAIGN_CONVERSION_CONFIGURATION_ENABLED]: 'true',
    },
    defaultValue: false,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const startDate = {
    field: 'startDate',
    isText: true,
    transformFromExcel: transformDateFromExcel,
    transformToExcel: transformDateToExcel,
    right: true,
    getIsDisabled: getIsRowCampaignUpdate,
};

export const endDate = {
    field: 'endDate',
    isText: true,
    transformFromExcel: transformDateFromExcel,
    transformToExcel: transformDateToExcel,
    right: true,
};

export const regionTargetingValuesField = 'campaignTargeting.regionCountryTargeting.values';
export const regionCountryTargetingType = {
    field: 'campaignTargeting.regionCountryTargeting.type',
    options: [{ id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: regionTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: regionTargetingValuesField }),
    defaultValue: null,
};

export const regionCountryTargetingValues = {
    field: regionTargetingValuesField,
    transformToExcel: transformListToString,
    transformFromExcel: transformStringToList,
    defaultValue: null,
    isText: true,
    characterLimitDependencies: [countryTargetingValuesField],
};

export const dmaTargetingValuesField = 'campaignTargeting.dmaCountryTargeting.values';
export const dmaCountryTargetingType = {
    field: 'campaignTargeting.dmaCountryTargeting.type',
    options: [{ id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: dmaTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: dmaTargetingValuesField }),
    defaultValue: null,
};

export const dmaCountryTargetingValues = {
    field: dmaTargetingValuesField,
    transformToExcel: transformListToString,
    transformFromExcel: transformStringToList,
    defaultValue: null,
    isText: true,
    characterLimitDependencies: [countryTargetingValuesField],
};

export const cityTargetingValuesField = 'campaignTargeting.cityCountryTargeting.values';
export const cityCountryTargetingType = {
    field: 'campaignTargeting.cityCountryTargeting.type',
    options: [{ id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: cityTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: cityTargetingValuesField }),
    defaultValue: null,
};

export const cityCountryTargetingValues = {
    field: cityTargetingValuesField,
    transformToExcel: transformCityTargetingToExcel,
    transformFromExcel: transformCityTargetingFromExcel,
    defaultValue: null,
    isText: true,
    characterLimitDependencies: [countryTargetingValuesField],
};

export const postalCodeTargetingValuesField = 'campaignTargeting.postalCodeTargeting.values';
export const postalCodeTargetingType = {
    field: 'campaignTargeting.postalCodeTargeting.type',
    options: [{ id: TARGETING_TYPES.INCLUDE }, { id: TARGETING_TYPES.EXCLUDE }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX,
    transformToExcel: (value, options) =>
        transformTargetingTypeToExcel(value, { ...options, valuesFieldName: postalCodeTargetingValuesField }),
    transformFromExcel: (value, options) =>
        transformTargetingTypeFromExcel(value, { ...options, valuesFieldName: postalCodeTargetingValuesField }),
    defaultValue: null,
};

export const postalCodeTargetingValues = {
    field: postalCodeTargetingValuesField,
    transformToExcel: transformListToString,
    transformFromExcel: transformStringToList,
    defaultValue: null,
    isText: true,
    characterLimitDependencies: [countryTargetingValuesField],
};

export const contextualSegmentsInclude = {
    field: 'campaignTargeting.contextualSegmentsTargeting.include',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
};

export const contextualSegmentsExclude = {
    field: 'campaignTargeting.contextualSegmentsTargeting.exclude',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
};

export const marketplaceAudienceInclude = {
    field: 'campaignTargeting.marketplaceAudienceTargeting.include',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    visibilityCondition: ({ accountConfig }) =>
        accountConfig?.[FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED] !== 'true',
    isText: true,
};

export const getMarketplaceAudienceTargetingIncludeAnd = (index, segmentType) => ({
    field: `campaignTargeting.marketplaceAudienceTargeting.include.${index}`,
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE, index }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    getCustomHeaderName: props => getMarketplaceAudiencesAndHeaderName({ ...props, targetingGroupIndex: index }),
    getCustomExampleText: props => getMarketplaceAudiencesAndExampleText({ ...props, segmentType }),
    visibilityCondition: ({ accountConfig }) =>
        accountConfig?.[FEATURE_FLAGS.MARKETPLACE_AUDIENCES_AND_LOGIC_ENABLED] === 'true',
    isText: true,
});

export const marketplaceAudienceExclude = {
    field: 'campaignTargeting.marketplaceAudienceTargeting.exclude',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    isText: true,
};

export const myAudiencesInclude = {
    field: 'campaignTargeting.customAudienceTargeting.include',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
};

export const myAudiencesExclude = {
    field: 'campaignTargeting.customAudienceTargeting.exclude',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
};

export const customContextualInclude = {
    field: 'campaignTargeting.customContextualTargeting.include',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    ignore: true,
};

export const customContextualExclude = {
    field: 'campaignTargeting.customContextualTargeting.exclude',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    ignore: true,
};

export const lookalikeAudienceInclude = {
    field: 'campaignTargeting.lookalikeAudienceTargeting.include',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.INCLUDE }),
    ignore: true,
};

export const lookalikeAudienceExclude = {
    field: 'campaignTargeting.lookalikeAudienceTargeting.exclude',
    dynamicFieldPathGetter: campaignTargetingAudienceDataPathGetter,
    transformToExcel: (value, options) =>
        transformAudiencesToExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    transformFromExcel: (value, options) =>
        transformAudiencesFromExcel(value, { ...options, targetingType: TARGETING_TYPES.EXCLUDE }),
    ignore: true,
};

export const profitMargin = {
    field: 'finance.profitMargin',
    accountConfigurations: { [FEATURE_FLAGS.PROFIT_MARGIN_ENABLED]: 'true' },
    right: true,
};

export const dayTimeBidModifierValues = {
    field: 'dayTimeBidModifier.values',
    accountConfigurations: { [FEATURE_FLAGS.BID_DAY_TIME_ENABLED]: 'true' },
    transformToExcel: transformDayTimeBidModifierToExcel,
    transformFromExcel: transformDayTimeBidModifierFromExcel,
    defaultValue: [],
};

export const dayTimeBidModifierTimeZone = {
    field: 'dayTimeBidModifier.timeZone',
    defaultValue: null,
    accountConfigurations: { [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true', [FEATURE_FLAGS.BID_DAY_TIME_ENABLED]: 'true' },
};

export const platformBidModifierValues = {
    field: 'platformBidModifier.values',
    accountConfigurations: { [FEATURE_FLAGS.BID_PLATFORM_ENABLED]: 'true' },
    transformToExcel: transformObjectListToDelimitedString,
    transformFromExcel: transformPlatformBidModifierFromExcel,
    defaultValue: [],
};

export const publisherBidModificationValues = {
    field: 'adsConsoleBidModifications.values',
    transformToExcel: transformPublisherBidModificationToExcel,
    transformFromExcel: transformPublisherBidModificationFromExcel,
    defaultValue: [],
};

export const processingStatus = {
    field: 'processingStatus',
};

export const isActive = {
    field: 'isActive',
    options: [{ id: 'true' }, { id: 'false' }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX + '.status',
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const accountName = {
    field: 'accountName',
    isEmptyAllowed: true,
    transformFromExcel: transformAccountNameFromExcel,
};

export const landingPageUrl = {
    field: 'url',
    defaultValue: '',
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
    transformFromExcel: transformHyperLinkObjectToString,
};

export const creativeName = {
    field: 'creativeName',
    transformToExcel: transformTextToExcel,
    accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
};

export const customId = {
    field: 'customId',
    transformToExcel: transformTextToExcel,
    accountConfigurations: { [FEATURE_FLAGS.SHOW_CUSTOM_DATA_IN_CREATIVE_FORM]: 'true' },
};

export const title = {
    field: 'title',
    transformToExcel: transformTextToExcel,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const creativeDescription = {
    field: 'description',
    transformToExcel: transformTextToExcel,
    accountConfigurations: { [FEATURE_FLAGS.CREATIVE_FORM_DESCRIPTION_ENABLED]: 'true' },
    permissions: ['CAMPAIGN_ITEM_DESCRIPTION'],
};

export const cta = {
    field: 'cta.ctaType',
    transformToExcel: transformCTATypeToExcel,
    transformFromExcel: transformCTATypeFromExcel,
    accountConfigurations: {
        [FEATURE_FLAGS.CREATIVE_FORM_CTA_ENABLED]: 'true',
        [FEATURE_FLAGS.BACKSTAGE_ADVERTISER_ENABLE_CTA]: 'true',
    },
    permissions: ['CREATIVE_CTA_EDIT'],
};

export const thumbnailFileName = {
    field: 'thumbnailFileName',
    transformFromExcel: transformMediaFileNameFromExcel,
    dynamicFieldPathGetter: () => 'thumbnailUrl',
    isFileName: true,
    isExcelOnly: true,
};

export const thumbnailUrl = { field: 'thumbnailUrl', transformFromExcel: transformHyperLinkObjectToString };

export const motionAdFileName = {
    field: 'motionAdFileName',
    transformFromExcel: transformMediaFileNameFromExcel,
    dynamicFieldPathGetter: () => `performanceVideoData.videoUrl`,
    isFileName: true,
    isExcelOnly: true,
    permissions: ['ADS_CONSOLE_PERFORMANCE_VIDEO'],
};

export const motionAdVideoUrl = {
    field: 'performanceVideoData.videoUrl',
    transformFromExcel: transformHyperLinkObjectToString,
    permissions: ['ADS_CONSOLE_PERFORMANCE_VIDEO'],
};

export const motionAdGifUrl = {
    field: 'performanceVideoData.gifUrl',
    transformFromExcel: transformHyperLinkObjectToString,
    permissions: ['ADS_CONSOLE_PERFORMANCE_VIDEO'],
};

export const motionAdDefaultImageUrl = {
    field: 'performanceVideoData.fallbackUrl',
    transformFromExcel: transformHyperLinkObjectToString,
    permissions: ['ADS_CONSOLE_PERFORMANCE_VIDEO'],
};

export const motionAdDefaultImageFileName = {
    field: 'motionAdDefaultImageFileName',
    transformFromExcel: transformMediaFileNameFromExcel,
    dynamicFieldPathGetter: () => 'performanceVideoData.fallbackUrl',
    isFileName: true,
    isExcelOnly: true,
    permissions: ['ADS_CONSOLE_PERFORMANCE_VIDEO'],
};

export const logoFileName = {
    field: 'logoFileName',
    accountConfigurations: { [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' },
    transformFromExcel: transformMediaFileNameFromExcel,
    isFileName: true,
    dynamicFieldPathGetter: () => 'logo.logo',
};

export const logoUrl = {
    field: 'logo.logo',
    accountConfigurations: { [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' },
};

export const creativeStatus = {
    field: 'isActive',
    options: [{ id: 'true' }, { id: 'false' }],
    optionMessagePrefix: STATIC_OPTION_MESSAGE_PREFIX + '.status',
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    getIsDisabled: props => {
        const isRowCreativeCreate = !getIsRowCreativeUpdate(props);
        return isRowCreativeCreate;
    },
};

export const thirdPartyTrackingPixelUrl = trackingSetIndex => ({
    field: `thirdPartyTags.${trackingSetIndex}.tagValue.value`,
    getCustomHeaderName: props =>
        getThirdPartyHeaderName({
            ...props,
            thirdPartyField: 'tagValue.value',
            trackingSetNumber: trackingSetIndex + 1,
        }),
    getCustomExampleText: props => getThirdPartyExampleText({ ...props, thirdPartyField: 'tagValue.value' }),
    transformFromExcel: transformHyperLinkObjectToString,
    visibilityCondition: getIsThirdPartyTagsEnabled,
});

export const thirdPartyTrackingTagType = trackingSetIndex => ({
    field: `thirdPartyTags.${trackingSetIndex}.type`,
    options: transformOptionsToOptionsWithIds(TAG_TYPE_OPTIONS),
    optionMessagePrefix: 'creative.editor.thirdPartyTags.tagType',
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    getCustomHeaderName: props =>
        getThirdPartyHeaderName({ ...props, thirdPartyField: 'type', trackingSetNumber: trackingSetIndex + 1 }),
    getCustomExampleText: props => getThirdPartyExampleText({ ...props, thirdPartyField: 'type' }),
    visibilityCondition: getIsThirdPartyTagsEnabled,
});

export const thirdPartyTrackingPixelEvent = trackingSetIndex => ({
    field: `thirdPartyTags.${trackingSetIndex}.eventType`,
    options: transformValuesToOptionIds(EVENT_TYPES),
    optionMessagePrefix: 'creative.editor.thirdPartyTags.eventType',
    transformToExcel: translateOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    getCustomHeaderName: props =>
        getThirdPartyHeaderName({ ...props, thirdPartyField: 'eventType', trackingSetNumber: trackingSetIndex + 1 }),
    getCustomExampleText: props => getThirdPartyExampleText({ ...props, thirdPartyField: 'eventType' }),
    visibilityCondition: getIsThirdPartyTagsEnabled,
});

export const creativeId = {
    field: 'id',
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.UPDATE,
};

export const campaignId = {
    field: 'campaignId',
    transformFromExcel: transformStringToList,
    operationRequirementType: OPERATION_REQUIREMENT_TYPES.CREATE,
};

export const performanceRuleIds = {
    field: 'performanceRuleIds',
    accountConfigurations: { [FEATURE_FLAGS.PERFORMANCE_RULES_ENABLED]: 'true' },
    transformToExcel: transformListToString,
    transformFromExcel: transformStringToList,
    defaultValue: [],
    ignore: true,
};

export const thirdPartyBrandSafetyType = {
    field: 'thirdPartyBrandSafetyTargeting.type',
    options: [{ id: BRAND_SAFETY_TYPES.DV }, { id: BRAND_SAFETY_TYPES.IAS }],
    optionMessagePrefix: 'campaign.editor.targeting.3rd.party.brand.safety.type',
    transformToExcel: translateThirdPartyBrandSafetyOptionToExcel,
    transformFromExcel: translateOptionFromExcel,
    permissions: ['EXTERNAL_BRAND_SAFETY'],
};

export const thirdPartyBrandSafetyTargeting = {
    field: 'thirdPartyBrandSafetyTargeting.values',
    transformToExcel: transformThirdPartyBrandSafetyListToExcel,
    transformFromExcel: transformThirdPartyBrandSafetyListFromExcel,
    permissions: ['EXTERNAL_BRAND_SAFETY'],
};

export const cpcCap = {
    field: 'cpcCap',
    accountConfigurations: { [FEATURE_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED_FOR_PUBLISHER]: 'true' },
    commonConfigurations: { [COMMON_FLAGS.MAX_CONVERSIONS_CPC_CAP_ENABLED]: 'true' },
    getCustomExampleText: getExampleTextWithCurrency,
    right: true,
};
