import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { StepperButton, withStylesheet } from 'taboola-ultimate-ui';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './platformInfo.module.scss';

const Border = () => <div className={styles['border']} />;

const StyledStepperButton = withStylesheet(StepperButton, styles, {
    [StepperButton.classNames.main]: 'stepper-container',
    [StepperButton.classNames.downButton]: 'stepper-button',
    [StepperButton.classNames.upButton]: ['stepper-button', 'stepper-up'],
});

const classNameBuilder = classnames.bind(styles);
const boostStepperValue = 10;

const calcBoost = boost => boost * 100 - 100;

export const PlatformInfo = ({ icon, platform }) => {
    const { value: boost, onChange: setBoost } = useFormFieldValue({ field: 'cpcModification' });

    const { [COMMON_FLAGS.PLATFORM_BOOST_MAX_VALUE]: maxBoost } = useCommonConfig([
        COMMON_FLAGS.PLATFORM_BOOST_MAX_VALUE,
    ]);
    const { [COMMON_FLAGS.PLATFORM_BOOST_MIN_VALUE]: minBoost } = useCommonConfig([
        COMMON_FLAGS.PLATFORM_BOOST_MIN_VALUE,
    ]);

    const onMinusClick = useCallback(() => {
        setBoost(boost => boost - boostStepperValue);
    }, [setBoost]);

    const onPlusClick = useCallback(() => {
        setBoost(boost => boost + boostStepperValue);
    }, [setBoost]);

    return (
        <tr className={styles['table-row']}>
            <td className={styles['column-body']}>
                <div className={styles['column-end-container']}>
                    {icon}
                    <FormattedMessage
                        id={`campaign.editor.bid.platformParting.${platform}`}
                        defaultMessage={platform}
                    />
                </div>
            </td>
            <td className={styles['buttons-container']}>
                <Border />
                <span className={styles['boost-number']}>{`${boost}%`}</span>
                <StyledStepperButton
                    onStepUp={onPlusClick}
                    onStepDown={onMinusClick}
                    upDisabled={boost >= calcBoost(maxBoost)}
                    downDisabled={boost <= calcBoost(minBoost)}
                    className={classNameBuilder('stepperButton')}
                />
            </td>
        </tr>
    );
};
