import { useMemo } from 'react';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations';

export const useBillingQueryKeys = () => {
    const { accountId } = useAccount();

    const billingDataQueryKey = useMemo(() => ['getBillingData', accountId], [accountId]);
    const getPaymentMethodsV2QueryKey = useMemo(() => ['getPaymentMethodsV2', accountId], [accountId]);
    const getAllowedPrepaymentMethodsV2QueryKey = useMemo(
        () => ['getAllowedPrepaymentMethodsV2', accountId],
        [accountId]
    );

    return { billingDataQueryKey, getPaymentMethodsV2QueryKey, getAllowedPrepaymentMethodsV2QueryKey };
};
