import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { EditOIcon } from 'taboola-ultimate-ui';
import { InternalLink } from 'components';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import CreativeEditorFormId from './CreativeEditorFormId';
import CreativeEditorFormStatus from './CreativeEditorFormStatus';
import { useCreativeEditorFormHeader } from './useCreativeEditorFormHeader';
import styles from './creativeEditorFormHeader.module.scss';

const classNameBuilder = classnames.bind(styles);

const CreativeEditorFormHeader = ({ headerLabel, hideStatusSection, children }) => {
    const { carouselLink, renderEditCarousel, isCarousel } = useCreativeEditorFormHeader();

    return (
        <div>
            <div className={classNameBuilder({ 'label-wrapper': isCarousel })}>
                {headerLabel}
                {renderEditCarousel && (
                    <InternalLink
                        className={styles['carousel-edit-link']}
                        to={carouselLink}
                        data-metrics-event-name={GTM_EVENTS.USABILITY}
                        data-metrics-value="edit carousel"
                    >
                        <EditOIcon width={20} height={20} />
                        <FormattedMessage id="creative.edit.carousel.form.link" defaultMessage="Edit Carousel" />
                    </InternalLink>
                )}
            </div>
            {!hideStatusSection && (
                <div className={styles['status-section']}>
                    <CreativeEditorFormId />
                    <CreativeEditorFormStatus disabled={isCarousel} />
                </div>
            )}
            {children}
        </div>
    );
};

CreativeEditorFormHeader.propTypes = {
    headerLabel: PropTypes.node,
    hideStatusSection: PropTypes.bool,
    children: PropTypes.node,
};

CreativeEditorFormHeader.defaultProps = {
    hideStatusSection: false,
};

export default CreativeEditorFormHeader;
