import React, { useMemo } from 'react';
import { DeletableItem, Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import styles from '../../../../../campaigns/modules/common-campaign-form/components/commonAudienceListItemContent.module.scss';

export const SearchKeywordsListItem = ({ item, deleteItem, searchText, rejectedKeywords }) => {
    const { label } = item;

    const isRejected = useMemo(() => {
        return label && rejectedKeywords && rejectedKeywords.includes(label.toLowerCase());
    }, [label, rejectedKeywords]);

    const handleOnClick = () => {
        deleteItem(item);
    };

    return (
        <DeletableItem onDelete={handleOnClick} className={isRejected ? styles['rejected-item'] : ''}>
            <Dotdotdot lines={3}>
                <Highlighter text={label} highlightedText={searchText} highlightedTextClassName={styles['highlight']} />
            </Dotdotdot>
        </DeletableItem>
    );
};
