import { get } from 'lodash';
import { SUBCATEGORY_ALL } from '../../../../services/transformers/osTargetingTransformer';
import { ARGUMENT_NAME, STATIC_FILTER_TYPE_MAP } from '../../config';

export const PAGE_SIZE = 10;

export const defaultArgNames = [ARGUMENT_NAME.SEARCH_INPUT, ARGUMENT_NAME.PAGE, ARGUMENT_NAME.PAGE_SIZE];

export const emptyValueOptions = { options: [], hasMore: false };

const byNameMapper = ({ name }) => ({
    value: name,
    label: name,
});

export const byAccountDescriptionMapper = ({ accountDescription }) => ({
    value: accountDescription,
    label: accountDescription,
});

export const bySiteIdMapper = ({ accountId, accountDescription }) => ({
    value: accountId,
    label: accountDescription,
});

const byValueMapper = value => ({
    value,
    label: value,
});

const byOSVersionMapper = ({ subCategory: { label } }) => ({
    value: label,
    label,
});

export const byNameValueMapper = ({ name, value }) => ({
    value,
    label: name,
});

export const byRuleNameMapper = ({ ruleName }) => ({
    value: ruleName,
    label: ruleName,
});

export const byNameUddIdMapper = ({ uddId, name }) => ({ value: uddId, label: name });

export const byCampaignsGroupMapper = ({ id, name }) => ({
    value: id,
    label: name,
});
export const dataHandlerByName = data => data.map(({ name }) => name);

const dataAndMapperHandlerToOptions = (data, optionsMapper) => ({
    options: data.map(optionsMapper),
    hasMore: false,
});

export const dataHandlerToOptions = data => dataAndMapperHandlerToOptions(data, byNameValueMapper);

export const createPaginatedDataHandlerToOptions =
    (optionsMapper = byNameMapper) =>
    data => {
        const result = get(data, 'results', []);

        return {
            options: result.map(optionsMapper),
            hasMore: result.length > 0,
        };
    };

export const filterBySearchInput = (allOptions, searchInput) => {
    const normalizedInput = searchInput ? searchInput.toLowerCase() : '';
    if (normalizedInput) {
        return allOptions.filter(
            ({ value, label }) =>
                value.toLowerCase().indexOf(normalizedInput) !== -1 ||
                label.toLowerCase().indexOf(normalizedInput) !== -1
        );
    }

    return allOptions;
};

export const generateStaticFilteredOptions = params => {
    const formatMessage = params.formatMessage;
    const messageIdPrefix = params.messageIdPrefix;
    const values = params.values;
    const allOptions = values.map(value => ({
        value,
        label: formatMessage({
            id: `${messageIdPrefix}.${value}`,
            defaultMessage: value,
        }),
    }));

    const searchInput = params.searchInput;
    const sortedOptions = allOptions.sort((a, b) => a.label.localeCompare(b.label));
    const options = filterBySearchInput(sortedOptions, searchInput);

    return { options, hasMore: false };
};

const UNDERSCORE = /_/g;

export const dataHandlerToOSFamily = data => {
    const result = get(data, 'operatingSystemTargetings', []);
    const osFamilies = result
        .filter(item => item.subCategory.id === SUBCATEGORY_ALL)
        .map(item => item.osFamily.replace(UNDERSCORE, ' '));

    return dataAndMapperHandlerToOptions(osFamilies, byValueMapper);
};

export const dataHandlerToOSVersion = data => {
    const result = get(data, 'operatingSystemTargetings', []);
    const osVersions = result.filter(item => item.subCategory.id !== SUBCATEGORY_ALL);
    return dataAndMapperHandlerToOptions(osVersions, byOSVersionMapper);
};

export const dataHandlerToCampaignsGroup = data => {
    return dataAndMapperHandlerToOptions(data.results ?? [], byCampaignsGroupMapper);
};

export const isComparableFilter = type => {
    return STATIC_FILTER_TYPE_MAP[type]?.operator && true;
};
