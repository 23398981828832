'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var STYLED_BUTTON_TYPE = {
    GHOST: 'ghost',
    STRONG: 'strong',
    BORDERLESS_ICON: 'borderless-icon',
    LINK: 'link',
    REJECT: 'reject'
};

exports.default = STYLED_BUTTON_TYPE;