import React from 'react';
import { FormField } from 'taboola-ultimate-ui';
import OutlineEditableText from 'modules/campaigns/components/OutlineEditableText';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { withInputValidations } from 'modules/taboola-common-frontend-modules/validations';
import useValidationContext from 'modules/taboola-common-frontend-modules/validations/hooks/useValidationContext';
import { nameValidations } from 'modules/tracking/validations/nameValidations';
import styles from './funnelNameField.module.scss';

const EditableTextWithValidations = withInputValidations(OutlineEditableText, nameValidations, {
    onChange: { validateEventValue: true, eventValueGetter: e => e },
});

export const FunnelNameField = () => {
    const { value: name, onChange: onNameChange } = useFormFieldValue({ field: 'name' });

    const validationContext = useValidationContext({
        prefix: 'Funnel',
        maxLen: 100,
    });

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: `tracking.unip.funnel.setup.name.placeholder`,
        defaultMessage: 'Type name here...',
    });

    return (
        <FormField
            inputId="funnel-name-input"
            label={<FormattedMessage id="tracking.unip.funnel.setup.name.title" defaultMessage="Name" />}
            containerClass={styles['field-container']}
        >
            <EditableTextWithValidations
                id="name"
                className={styles['editable-text']}
                value={name}
                onChange={onNameChange}
                placeholder={placeholder}
                validationContext={validationContext}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="FunnelName"
            />
        </FormField>
    );
};
