import { useCallback, React } from 'react';
import { CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import { useSiteDropdownData } from '../../SiteTargeting/hooks/useSiteDropdownData';
import { useSiteExploration } from '../../SiteTargeting/hooks/useSiteExploration';
import { SiteExplorationListItem } from '../SiteExplorationListItem/SiteExplorationListItem';
import { SiteItemDropdownOption } from '../SiteItemDropdownOption/SiteItemDropdownOption';
import styles from './siteExplorationList.module.scss';

const CollapsibleListWithIndication = withIndication(CollapsibleList);

export const SiteExplorationList = () => {
    const { siteItems, addOne, allowedSites, isAllowedSitesReady, deleteOne, failedValidationData, scrollRef } =
        useSiteExploration();
    const optionFilter = useCallback(
        ({ site, excludedSitesSet }) => {
            const siteItemsMap = new Map(siteItems.map(obj => [obj.value, obj]));
            if (siteItemsMap.has(site.value)) {
                return false;
            }
            if (excludedSitesSet.has(site.value)) {
                return false;
            }
            return true;
        },
        [siteItems]
    );
    const { options, setOptions: setCurrentOptions, loadPage: loadSites } = useSiteDropdownData(false, optionFilter);

    const { formatMessage } = useIntl();
    return (
        <div>
            <PaginatedDropdown
                id="site-exploration-select"
                selectedValueObject={null}
                containerClass={styles['form-field']}
                dropdownWrapperClassName={styles['dropdown-container']}
                options={options}
                loadPage={loadSites}
                onOptionsLoaded={setCurrentOptions}
                searchable
                enabledWhileSearching
                placeholder={formatMessage({
                    id: 'campaign.editor.site.exploration.placeholder',
                    defaultMessage: 'Type to search',
                })}
                onChange={addOne}
                optionItemRenderer={SiteItemDropdownOption}
            />
            <CollapsibleListWithIndication
                id="site-exploration-list"
                data-testid="site-exploration-list"
                items={siteItems}
                ItemComponent={SiteExplorationListItem}
                deleteItem={deleteOne}
                listHeaderTitle={
                    <FormattedMessage
                        id="campaign.editor.site.exploration.list.label"
                        defaultMessage="Site in Exploration"
                    />
                }
                ref={scrollRef}
                listHeaderClass={styles['list-header']}
                containerClassName={styles['list']}
                metadata={{
                    isAllowedSitesReady,
                    allowedSites,
                }}
                {...failedValidationData}
            />
        </div>
    );
};
