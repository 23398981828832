import moment from 'moment';

export const startTimeFrameValidations = [
    {
        validationFn: value => value,
        messageId: 'validations.error.ad.startDate.required',
    },
    {
        validationFn: value => moment(value).isValid(),
        messageId: 'validations.error.ad.startDate.invalid',
    },
    {
        validationFn: value => {
            return !value || moment(value).isAfter(moment().endOf('day').subtract(1, 'day'));
        },
        messageId: 'validations.error.ad.startDate.beforeNow',
    },
    {
        validationFn: (value, options, { startDateCampaign }) => {
            return (
                !value ||
                !startDateCampaign ||
                moment(value).isAfter(moment(startDateCampaign).endOf('day').subtract(1, 'day'))
            );
        },
        messageId: 'validations.error.ad.startDate.after.campaign',
    },
];

export const endTimeFrameValidations = [
    {
        validationFn: value => value,
        messageId: 'validations.error.ad.endDate.required',
    },
    {
        validationFn: value => !value || moment(value).isValid(),
        messageId: 'validations.error.ad.endDate.invalid',
    },
    {
        validationFn: value => !value || moment(value).isAfter(moment().startOf('day')),
        messageId: 'validations.error.ad.endDate.beforeNow',
    },
    {
        validationFn: (value, options, { startDate }) => !value || moment(startDate).isSameOrBefore(moment(value)),
        messageId: 'validations.error.ad.endDate.beforeStartDate',
    },
    {
        validationFn: (value, options, { endDateCampaign }) => {
            return !value || !endDateCampaign || moment(value).isBefore(endDateCampaign);
        },
        messageId: 'validations.error.ad.endDate.before.campaign',
    },
];
