import { TaboolaGraph, SORTING_ORDER_TYPES } from 'taboola-ultimate-ui';
import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import { addMeasuredByMoatToMetric } from '../../../utils/addMeasuredByMoat';
import { DATE } from '../../fields';
import {
    dayWeekMonthGetXAxisFormatter,
    dayWeekMonthXAxisMinTickGap,
    tooltipDateFormatter,
} from '../../fields/transformers';
import metrics from '../../metrics';

const { AXIS_SCALE, AXIS_TYPE, AXIS_DOMAIN } = TaboolaGraph;

const graph = {
    type: GRAPHS.LINE,
    endpoint: 'campaign-report-by-week-performance',
    metrics: [
        metrics.SPENT,
        metrics.COMPLETED_VIEWS,
        addMeasuredByMoatToMetric(metrics.IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToMetric(metrics.CPM),
        addMeasuredByMoatToMetric(metrics.VCPM),
        metrics.CPCV,
        addMeasuredByMoatToMetric(metrics.VIEWABILITY_RATE),
        addMeasuredByMoatToMetric(metrics.HUNDRED_PCT_VIEWABILITY_RATE),
        metrics.COMPLETION_RATE,
        metrics.CLICKS,
        metrics.CTR,
        metrics.VCTR,
        metrics.CPA,
        metrics.CVR,
        metrics.CONVERSIONS,
    ],
    defaultSort: [{ field: DATE.field, order: SORTING_ORDER_TYPES.ASCENDANT }],
    graphProps: {
        xAxisDataKey: DATE.field,
        xAxisMinTickGap: dayWeekMonthXAxisMinTickGap,
        getXAxisFormatter: dayWeekMonthGetXAxisFormatter,
        toolTipLabelFormatter: tooltipDateFormatter,
        xAxisScale: AXIS_SCALE.LINEAR,
        xAxisType: AXIS_TYPE.NUMBER,
        xAxisDomain: [AXIS_DOMAIN.DATA_MIN, AXIS_DOMAIN.DATA_MAX],
    },
};

export default graph;
