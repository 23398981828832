import React from 'react';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import LearnMore from '../../LearnMore';
import TooltipSection from '../../TooltipSection';
import styles from './bidStrategyTooltip.module.scss';

export const BidStrategyTooltip = () => {
    const { formatMessage } = useIntl();
    const href = formatMessage({
        id: 'campaign.editor.bid.strategy.help.href',
        defaultMessage:
            'https://help.taboola.com/hc/en-us/articles/360008291014-Setting-Your-Bid-Strategy-Smart-Bid-Fixed-Bid',
    });
    return (
        <div>
            <TooltipSection>
                <strong>
                    <FormattedMessage id="campaign.editor.bid.strategy.help.smart" defaultMessage="Smart Bid: " />
                </strong>
                <FormattedMessage
                    id="campaign.editor.bid.strategy.help.smart.details"
                    defaultMessage="Use this bid strategy when you want to automatically maximize your campaign performance at scale."
                />
            </TooltipSection>
            <TooltipSection containerClass={styles['nested']}>
                <strong>
                    <FormattedMessage
                        id="campaign.editor.bid.strategy.help.smart.standard"
                        defaultMessage="Enhanced CPC: "
                    />
                </strong>
                <FormattedMessage
                    id="campaign.editor.bid.strategy.help.smart.standard.details"
                    defaultMessage="Your campaign Bid will increase or decrease to win impressions most likely to generate conversions. Bid modifications for new campaigns and new ads are made to win the right number of impressions and clicks to accurately learn your ad’s potential."
                />
            </TooltipSection>
            <TooltipSection containerClass={styles['nested']}>
                <strong>
                    <FormattedMessage id="campaign.editor.bid.strategy.help.smart.cpa" defaultMessage="Target CPA: " />
                </strong>
                <FormattedMessage
                    id="campaign.editor.bid.strategy.help.smart.cpa.details"
                    defaultMessage="Use this bid strategy when you want to control the cost of conversions generated by your campaign. Your campaign Bid will increase or decrease to win impressions according to your CPA target."
                />
            </TooltipSection>
            <TooltipSection>
                <strong>
                    <FormattedMessage id="campaign.editor.bid.strategy.help.fixed" defaultMessage="Fixed Bid: " />
                </strong>
                <FormattedMessage
                    id="campaign.editor.bid.strategy.help.fixed.details"
                    defaultMessage=" Use this bid strategy if you want to maintain control over your baseline bid. You will need to manually adjust your bids in order to optimize for conversions."
                />
            </TooltipSection>
            <TooltipSection>
                <LearnMore href={href} />
            </TooltipSection>
        </div>
    );
};
