import { useCallback } from 'react';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { withIndication } from '../../../../../../../errors';
import { useAccount } from '../../../../../../../taboola-common-frontend-modules/account-configurations';
import { GTM_EVENTS } from '../../../../../../../taboola-common-frontend-modules/gtmTracker';
import { FormattedNumber } from '../../../../../../../taboola-common-frontend-modules/i18n';
import CurrencyIcon from '../../../CurrencyIcon/CurrencyIcon';
import { useCpcCapFormValidatedFieldValue } from './useCpcCapFormValidatedFieldValue';
import commonStyles from '../../../commonEditor.module.scss';

const inputPadding = parseInt(commonStyles.currencyInputLeftPadding);
const InputWithIndication = withIndication(Input);

export const CpcCapInput = () => {
    const {
        value: cpcCap,
        onChange: onChangeCpcCap,
        failedValidationData,
        scrollRef,
    } = useCpcCapFormValidatedFieldValue();
    const { currency } = useAccount();

    const changeHandler = useCallback(
        e => {
            const value = parseFloat(e.target.value);
            onChangeCpcCap(Number.isNaN(value) ? '' : value);
        },
        [onChangeCpcCap]
    );

    return (
        <FormattedNumber value={0} minimumFractionDigits={2}>
            {placeholder => (
                <InputWithIndication
                    id="cpcCap"
                    type={InputTypes.NUMBER}
                    placeholder={placeholder}
                    value={cpcCap ?? ''}
                    onChange={changeHandler}
                    icon={<CurrencyIcon currency={currency} />}
                    inputPadding={inputPadding}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="CpcCap"
                    step=".01"
                    ref={scrollRef}
                    {...failedValidationData}
                />
            )}
        </FormattedNumber>
    );
};
