import React from 'react';
import classnames from 'classnames/bind';
import { StatusIndicator } from 'tuui';
import { Dotdotdot, Highlighter } from 'taboola-ultimate-ui';
import { getStatusIndicatorType } from 'modules/campaigns/config';
import { getConversionRulesStatusType } from 'modules/campaigns/config/conversionRulesConsts';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { ConversionItemTooltip } from '../ConversionItemTooltip/ConversionItemTooltip';
import styles from './ConversionItemDropdownOption.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ConversionItemDropdownOption = ({ data, selectProps: { inputValue, isOptionDisabled } }) => {
    const { displayName, avgCvr, status, conversions, numDays } = data;
    const disabled = isOptionDisabled(data);
    const { formatMessage } = useIntl();
    const shortAvgCvr = avgCvr?.toFixed(2);
    const formattedAvgCvr = formatMessage(
        {
            id: 'conversion.goal.avg.cvr',
            defaultMessage: `Avg CVR: ${shortAvgCvr}%`,
        },
        { shortAvgCvr }
    );
    const statusType = getConversionRulesStatusType(status);
    const statusIndicatorType = getStatusIndicatorType(statusType);
    const NO_CONVERSIONS = '-';
    const conversionDisplay = conversions ?? NO_CONVERSIONS;
    return (
        <div className={styles['container']} role="listitem">
            <ConversionItemTooltip
                disabled={disabled}
                status={status}
                avgCvr={shortAvgCvr}
                conversions={conversionDisplay}
                numDays={numDays}
            />
            {statusIndicatorType && (
                <div className={styles['indicator']}>
                    <StatusIndicator type={statusIndicatorType} />
                </div>
            )}
            <div className={classNameBuilder('name', { disabled })}>
                <Dotdotdot lines={2}>
                    <Highlighter
                        text={`${displayName}`}
                        highlightedText={inputValue}
                        className={styles['highlighter']}
                    />
                </Dotdotdot>
            </div>
            <div className={classNameBuilder('avg-cvr', { disabled })}>{formattedAvgCvr}</div>
        </div>
    );
};
