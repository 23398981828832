import { TextCheckbox } from 'taboola-ultimate-ui';
import { PerformanceRuleEmailNotificationsScheduledReportsList } from 'modules/performance-rules/components/PerformanceRuleSetupSection/AdvancedSettingsSection/EmailNotifications/PerformanceRuleEmailNotificationsScheduledReportsList';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

export const scheduledReportsListValidations = [
    {
        validationFn: (value, options, { isEmailNotificationActive }) => {
            const isCheckBoxClicked = value !== '';
            if (!isEmailNotificationActive || !isCheckBoxClicked) {
                return true;
            }

            return validationFunctions.arrayLengthRange(value, options);
        },
        options: { min: 1 },
        messageId: 'app.performance.rules.setup.email.notifications.existing.reports.validation',
        defaultMessage: 'Select at least one report',
    },
];

export const ChooseFromExistingReportsCheckBox = () => {
    const { value: isEmailNotificationActive } = useFormFieldValue({
        field: 'isEmailNotificationActive',
    });

    const {
        value: scheduledReportIds,
        onChange: onScheduleReportIdsChange,
        failedValidationData,
    } = useFormValidatedValue({
        field: 'scheduledReportIds',
        validations: scheduledReportsListValidations,
        validationDependencies: { isEmailNotificationActive },
    });

    return (
        <>
            <TextCheckbox
                value={scheduledReportIds !== null}
                title={
                    <FormattedMessage
                        id="app.performance.rules.setup.email.notifications.existing.reports.checkbox.label"
                        defaultMessage="Choose from existing reports"
                    />
                }
                data-metrics-component="performance-rules-emial-notifications-existing-reports-checkbox"
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                onSelectCheckbox={() => onScheduleReportIdsChange([])}
                onUnselectCheckbox={() => onScheduleReportIdsChange(null)}
                selectedValues={[true]}
                independent
            />
            {scheduledReportIds && (
                <PerformanceRuleEmailNotificationsScheduledReportsList
                    scheduledReportIds={scheduledReportIds}
                    onScheduleReportIdsChange={onScheduleReportIdsChange}
                    failedValidationData={failedValidationData}
                />
            )}
        </>
    );
};
