import classnames from 'classnames/bind';
import { INDICATION_TYPES, IndicationContent, Radio } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { TRACKING_ROUTE } from '../../../../../../../tracking/config';
import { useTrackingDataForBidStrategy } from '../../../../../../../tracking/hooks/useTrackingDataForBidStrategy';
import { BID_STRATEGIES } from '../../../../config';
import { TargetCpaStrategy } from '../../TargetCpaStrategy/TargetCpaStrategy';
import { TargetCpaTitle } from '../../TargetCpaStrategy/TargetCpaTitle/TargetCpaTitle';
import { useTargetCpaAvailability } from '../../hooks/useTargetCpaAvailability';
import commonStyles from '../../../commonEditor.module.scss';
import styles from './targetCpaRadio.module.scss';

const classNameBuilder = classnames.bind(styles);

export const TargetCpaRadio = ({ bidStrategy }) => {
    const targetCpaAvailabilityProps = useTargetCpaAvailability();
    const { disableCpa, shouldDisableCpaOnDuplicate } = targetCpaAvailabilityProps;
    const { isPixelOrS2sInstalled, hasAtLeastOneActiveConversion, isTcpaPixelValidationEnabled } =
        useTrackingDataForBidStrategy();
    const missingPixelOrActiveConversion =
        isTcpaPixelValidationEnabled && (!isPixelOrS2sInstalled || !hasAtLeastOneActiveConversion);

    return (
        <Radio
            labelClassName={styles['radio-label']}
            childrenContainerClassName={styles['radio-child']}
            title={<TargetCpaTitle {...targetCpaAvailabilityProps} titleMsgId="campaign.editor.target.cpa.bid.title" />}
            description={
                <FormattedMessage
                    id="campaign.editor.applyCap.target.cpa.bid.description"
                    defaultMessage="Your bid will adjust to reach your target cost per action."
                />
            }
            value={BID_STRATEGIES.TARGET_CPA}
            disabled={missingPixelOrActiveConversion || disableCpa || shouldDisableCpaOnDuplicate}
        >
            {bidStrategy === BID_STRATEGIES.TARGET_CPA && <TargetCpaStrategy showLabel={false} />}
            {missingPixelOrActiveConversion && (
                <IndicationContent
                    containerClassName={classNameBuilder('warning-indication', commonStyles['warning-indication'])}
                    contentClassName={styles['indication-content']}
                    type={INDICATION_TYPES.WARNING}
                >
                    <FormattedMessage
                        id="validations.error.campaign.target.cpa.noPixelImplementationOrConversions"
                        defaultMessage="Target CPA requires tracking and at least one active conversion event.{newline}<a><b>Setup up tracking</b></a>"
                        values={{
                            href: TRACKING_ROUTE,
                        }}
                    />
                </IndicationContent>
            )}
        </Radio>
    );
};
