import React, { useCallback, useMemo } from 'react';
import { DropdownMenu, FormField, PaginatedDropdown } from 'taboola-ultimate-ui';
import { useAccountConversions } from 'modules/campaigns/modules/common-campaign-form/components/ConversionOptimization/hook/useAccountConversions';
import { withIndication } from 'modules/errors/components';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { useFetchConversionRules } from './useFetchConversionRules';
import styles from './converstionEvent.module.scss';

const FormFieldWithIndication = withIndication(FormField, styles['conversion-field']);
const conversionValidations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.performance.rules.conversion.no.selection',
        defaultMessage: 'Please select a Conversion Event',
    },
];
const formFieldId = 'rule-selector';
export const ConversionEvent = ({ index }) => {
    const {
        value: conversionRuleId,
        onChange: onChangeConversionRuleId,
        indicationData,
        isDirty,
    } = useFormValidatedValue({
        field: 'subMetric.propertyValue',
        validations: conversionValidations,
    });
    const { formatMessage } = useIntl();
    const { fetchedData } = useFormDataContext();
    const { accountId } = useAccount();
    const data = useFetchConversionRules(fetchedData?.conditions, accountId, isDirty);

    const conversionName = useMemo(
        () => data?.find(conversion => conversion.id === parseInt(conversionRuleId))?.conversionName,
        [conversionRuleId, data]
    );

    const onChangeRuleHandler = useCallback(
        ({ id }) => {
            onChangeConversionRuleId(id);
        },
        [onChangeConversionRuleId]
    );

    const { options, loadConversionRules, setOptions } = useAccountConversions();

    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            label={
                <FormattedMessage id="app.create.performance.rules.conversion" defaultMessage="From conversion event" />
            }
            labelClass={styles['label']}
            containerClass={styles['field-container']}
            {...indicationData}
        >
            <div className={styles['dropdown-container']}>
                <PaginatedDropdown
                    id={formFieldId}
                    onChange={onChangeRuleHandler}
                    searchable
                    enabledWhileSearching
                    options={options}
                    loadPage={loadConversionRules}
                    onOptionsLoaded={setOptions}
                    placeholder={formatMessage({
                        id: 'campaign.editor.conversion.goal.placeholder',
                        defaultMessage: 'Select Conversion...',
                    })}
                    DropdownMenuComponent={DropdownMenu}
                    dropdownWrapperClassName={styles['dropdown-wrapper']}
                    selectedValueObject={{
                        value: parseInt(conversionRuleId),
                        label: conversionName,
                    }}
                />
            </div>
        </FormFieldWithIndication>
    );
};
