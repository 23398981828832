import { queryClient } from '../../query';
import { callGetApi, callPostApi } from '../apiCallerProxy';
import { trackingApiFactory } from './trackingApiFactory';

export * from './hooks';
export default trackingApiFactory({
    callGetApi,
    callPostApi,
    queryClient,
});
