import { useCallback, useEffect, useRef } from 'react';
import { CROPPING_SIGNIFICANT_CHANGE_EVENT, thumbnailCroppedImageSafetyValidations } from 'modules/campaigns/config';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { isSignificantChange, singleRatioCropDataTransformer } from '../utils/cropDataUtils';

export const useCropValidationSingleAspectRatio = (src, index, accountId, significantChangeThreshold) => {
    const lastValidatedCropDataRef = useRef({});

    const isCroppedImageSafetyValidationEnabled = useConfigMatch({
        [FEATURE_FLAGS.AUTO_REVIEWER_CROPPED_IMAGE_SAFETY_VALIDATIONS_ENABLED]: 'true',
    });

    const {
        value: currentCropData,
        failedValidationData,
        triggerValidationEvent,
    } = useFormValidatedValue({
        field: index,
        validations: [thumbnailCroppedImageSafetyValidations],
        validationDependencies: { isCroppedImageSafetyValidationEnabled, accountId, src },
    });

    const validateIfSignificantChange = useCallback(
        (cropData = {}) => {
            const transformedOldCropData = singleRatioCropDataTransformer(lastValidatedCropDataRef.current);
            const transformedNewCropData = singleRatioCropDataTransformer(cropData);
            triggerValidationEvent(CROPPING_SIGNIFICANT_CHANGE_EVENT);
            if (isSignificantChange(transformedOldCropData, transformedNewCropData, significantChangeThreshold)) {
                lastValidatedCropDataRef.current = cropData;
            }
        },
        [lastValidatedCropDataRef, significantChangeThreshold, triggerValidationEvent]
    );

    useEffect(() => {
        if (!currentCropData) {
            return;
        }

        validateIfSignificantChange(currentCropData);
    }, [currentCropData, validateIfSignificantChange]);

    return failedValidationData;
};
