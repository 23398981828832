import { useCallback, useMemo, useRef, useState } from 'react';
import { FORM_MODES } from 'config/formModes';
import { CREATIVE_TYPE } from 'modules/campaigns/config/routes/creativeType';
import { useIsAppInstallCreativeInEditMode } from 'modules/campaigns/modules/creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import { isFileTypeImage } from 'modules/campaigns/modules/creative-creator/services/utils';
import { useCropImageModal } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/CroppingToolsForm/hooks/useCropImageModal';
import { FOCUS_TYPES } from 'modules/campaigns/modules/creative-editor/config';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import useFormFieldValue from 'modules/taboola-common-frontend-modules/formData/hooks/useFormFieldValue';
import { COMPONENT_STATUS } from 'services/constants';

export const useUnifiedCreativeEditorBodyState = (
    campaignId,
    setThumbnailStatus,
    setVideoStatus,
    setIsThumbnailLoading,
    setApplyFocusToMatches,
    videoStatus,
    thumbnailStatus
) => {
    const [thumbnailError, setThumbnailError] = useState(null);
    const [videoError, setVideoError] = useState(null);
    const [editFallbackImageMode, setEditFallbackImageMode] = useState(false);
    const [lastSelectedId, setLastSelectedId] = useState();
    const selectedMedia = useMemo(() => (lastSelectedId ? [{ id: lastSelectedId }] : []), [lastSelectedId]);
    const onFileDialogCancel = useCallback(() => setEditFallbackImageMode(false), [setEditFallbackImageMode]);
    const { fetchStatus, setSubmitStatus, mode } = useFormDataContext();
    const { value: thumbnailUrl, onChange: onChangeThumbnailUrl } = useFormFieldValue({ field: 'thumbnailUrl' });
    const { value: videoUrl, onChange: onChangeVideoUrl } = useFormFieldValue({ field: 'videoUrl' });
    const { onChange: onChangeGifUrl } = useFormFieldValue({ field: 'gifUrl' });
    const { onChange: onChangeMediaUploadSource } = useFormFieldValue({ field: 'mediaUploadSource' });
    const { onChange: onChangeMotionAdsStudio } = useFormFieldValue({ field: 'motionAdsStudio' });
    const { value: creativeType, onChange: onChangeCreativeType } = useFormFieldValue({ field: 'creativeType' });
    const initialCreativeTypeRef = useRef(creativeType);
    const { onChange: onChangeCreativeFocus } = useFormFieldValue({ field: 'creativeFocus' });
    const { onChange: onChangeCreativeCrop } = useFormFieldValue({ field: 'creativeCrop' });
    const { openModal: openCropModal } = useCropImageModal({ campaignId });
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    const isCroppingImageEnabled = useConfigMatch({ [FEATURE_FLAGS.CROPPING_IMAGE_ENABLED]: 'true' });
    const isCroppedModeEnable = (mode === FORM_MODES.DUPLICATE || mode === FORM_MODES.EDIT) && !isAppInstall;

    const onSelectMedia = useCallback(
        async (urlResolver, metadata) => {
            const setCreativeStatus = status => {
                setThumbnailStatus(status);
                setVideoStatus(status);
                setIsThumbnailLoading(status === COMPONENT_STATUS.LOADING);
            };

            const setCreative = data => {
                if (data.selected) {
                    return;
                }

                const {
                    motionAdsStudio,
                    mediaUploadSource,
                    gifUrl,
                    cdnUrl,
                    url,
                    dropped = true,
                    fileType,
                    fallbackImageUrl,
                } = data;
                const creativeUrl = cdnUrl || url;
                const isUploadFromComputerClicked = !dropped;
                const isStaticImage = isFileTypeImage(fileType);
                const isEditFallbackImageMode = isUploadFromComputerClicked && isStaticImage;

                if (isEditFallbackImageMode) {
                    onChangeThumbnailUrl(creativeUrl);
                    onChangeCreativeCrop({ cropData: [] });
                    onChangeCreativeFocus({
                        type: FOCUS_TYPES.AUTOMATIC,
                        coordinates: null,
                    });
                    return;
                }

                if (isStaticImage) {
                    onChangeCreativeType(CREATIVE_TYPE.IMAGE);
                    initialCreativeTypeRef.current = CREATIVE_TYPE.IMAGE;
                    onChangeThumbnailUrl(creativeUrl);
                    onChangeCreativeFocus({
                        type: FOCUS_TYPES.AUTOMATIC,
                        coordinates: null,
                    });
                    setApplyFocusToMatches(false);
                    onChangeVideoUrl(null);
                    onChangeMediaUploadSource(null);
                    onChangeMotionAdsStudio(null);
                    onChangeGifUrl(null);
                    onChangeCreativeCrop({ cropData: [] });
                    if (isCroppedModeEnable && isCroppingImageEnabled) {
                        openCropModal({ src: creativeUrl, metadata: data, shouldSkipPreview: true });
                    }
                    return;
                }

                onChangeCreativeType(CREATIVE_TYPE.MOTION);
                initialCreativeTypeRef.current = CREATIVE_TYPE.MOTION;
                onChangeVideoUrl(creativeUrl);
                onChangeGifUrl(gifUrl);
                onChangeMediaUploadSource(mediaUploadSource);
                onChangeMotionAdsStudio(motionAdsStudio);
                onChangeThumbnailUrl(fallbackImageUrl);
            };

            const setCreativeError = error => {
                setThumbnailError(error);
                setVideoError(error);
                setIsThumbnailLoading(false);
            };

            setCreativeStatus(COMPONENT_STATUS.LOADING);
            try {
                const { url, ...dependentUrls } = await urlResolver();

                setLastSelectedId(metadata?.id);
                setCreative({ ...metadata, ...dependentUrls, cdnUrl: url, displayUrl: url });
                setCreativeStatus(COMPONENT_STATUS.ACTIVE);
                setCreativeError(null);
            } catch (error) {
                setCreativeStatus(COMPONENT_STATUS.ERROR);
                setCreativeError(error);
            }

            if (!metadata.dropped) {
                setEditFallbackImageMode(false);
            }
        },
        [
            setThumbnailStatus,
            setVideoStatus,
            onChangeVideoUrl,
            onChangeMediaUploadSource,
            onChangeMotionAdsStudio,
            onChangeThumbnailUrl,
            onChangeGifUrl,
            onChangeCreativeType,
            setEditFallbackImageMode,
            setIsThumbnailLoading,
            isCroppedModeEnable,
            isCroppingImageEnabled,
            openCropModal,
            onChangeCreativeFocus,
            onChangeCreativeCrop,
            setApplyFocusToMatches,
            setLastSelectedId,
            setThumbnailError,
            setVideoError,
        ]
    );

    const handleBrowseFilesClick = openFileDialog => () => {
        if (videoStatus === COMPONENT_STATUS.LOADING || thumbnailStatus === COMPONENT_STATUS.LOADING) {
            return;
        }

        setEditFallbackImageMode(false);
        openFileDialog();
    };

    return {
        thumbnailError,
        videoError,
        editFallbackImageMode,
        setEditFallbackImageMode,
        selectedMedia,
        onFileDialogCancel,
        fetchStatus,
        setSubmitStatus,
        mode,
        thumbnailUrl,
        videoUrl,
        creativeType,
        initialCreativeTypeRef,
        openCropModal,
        onSelectMedia,
        handleBrowseFilesClick,
    };
};
