import { useQuery } from 'react-query';
import { useResourcesApi } from '../../../../../../../services/api';

const useAppsApi = () => {
    const { getApps } = useResourcesApi();
    const { data: apps, isLoading: isResourcesLoading } = useQuery(['apps'], () => getApps(), {
        refetchOnWindowFocus: false,
        select: data =>
            data?.map(({ name, value, ...rest }) => ({
                id: name,
                label: value,
                ...rest,
            })),
    });

    return {
        apps,
        isResourcesLoading,
    };
};

export default useAppsApi;
