const RED_HEX = 'FF0000';
const LIGHT_RED_HEX = 'F4CCCC';
const LIGHT_GREEN_HEX = 'D9EAD3';
const GRAY_HEX = 'B7B7B7';

export const RED_BORDER = { style: 'thin', color: { argb: RED_HEX } };
export const GRAY_BORDER = { style: 'thin', color: { argb: GRAY_HEX } };

export const GREEN_FILL_STYLE = {
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_GREEN_HEX },
    },
    border: {
        top: GRAY_BORDER,
        left: GRAY_BORDER,
        bottom: GRAY_BORDER,
        right: GRAY_BORDER,
    },
};

export const RED_FILL_STYLE = {
    fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: LIGHT_RED_HEX },
    },
    border: {
        top: GRAY_BORDER,
        left: GRAY_BORDER,
        bottom: GRAY_BORDER,
        right: GRAY_BORDER,
    },
};

export const ALL_RED_BORDERS_STYLE = {
    border: {
        top: RED_BORDER,
        left: RED_BORDER,
        bottom: RED_BORDER,
        right: RED_BORDER,
    },
};

export const GENERAL_CELL_STYLE = { alignment: { vertical: 'top', wrapText: true } };

export const HEADER_ROW_STYLE = {
    font: { bold: true, size: 12 },
};
