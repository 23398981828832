import moment from 'moment';
import { PHASES } from './phases';

const ONE_DAY_MS = moment.duration(1, 'd').asMilliseconds();
export const HOUR_IN_MS = moment.duration(1, 'h').asMilliseconds();

export const DURATION_MS = {
    ONE_DAY: ONE_DAY_MS,
    HALF_DAY: ONE_DAY_MS * 0.5,
};

export const SCALING_ADDITIONAL_TIME_MS = {
    AFTER_DURATION: ONE_DAY_MS * 8,
    NO_PIXEL: ONE_DAY_MS * 6,
};

export const DURATION_MINIMUM = {
    [PHASES.PENDING_REVIEW]: 12,
    [PHASES.LEARNING]: 1,
    [PHASES.LEARNING_LIMITED]: 1,
    [PHASES.EXTENDED_LEARNING]: 1,
    [PHASES.EXPEDITED_LEARNING]: 1,
    [PHASES.SCALING]: 1,
};

export const PHASE_DURATION_MS = {
    PENDING_REVIEW: ONE_DAY_MS,
    PENDING_REVIEW_FIRST_FROM_WIZARD: ONE_DAY_MS * 2,
    LEARNING: ONE_DAY_MS * 11,
    LEARNING_LIMITED: ONE_DAY_MS * 11,
    EXPEDITED_LEARNING: ONE_DAY_MS,
    EXTENDED_LEARNING: ONE_DAY_MS * 22,
    SCALING: ONE_DAY_MS * 26,
};
