import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { isFunction, keyBy } from 'lodash';
import PropTypes from 'prop-types';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useNavigate } from '../../hooks';
import { useModulePage } from '../../hooks/queryParams/useModulePage';
import formValidationErrorIndication from '../../modules/campaigns/config/indications/validationIndication';
import { MultiLayerDrawer } from '../../modules/multi-layer-drawer/MultiLayerDrawer';
import {
    addIndication,
    INDICATION_TYPES,
    removeIndication,
} from '../../modules/taboola-common-frontend-modules/Indications';
import useAvailableRoutes from './hooks/useAvailableRoutes';

export const HOME_PATH = '/';
const idToPath = ({ id, basePath = '' }, keepQueryParams) =>
    `${basePath}/${id}${keepQueryParams ? window.location.search : ''}`;

const renderRoute = (route, defaultRoute, isAvailable, keepQueryParams) => {
    const { id, component: Component, componentProps = {} } = route;

    return (
        <Route
            key={id}
            render={props =>
                isAvailable ? (
                    <Component {...props} {...componentProps} />
                ) : (
                    <Redirect from={HOME_PATH} to={idToPath(defaultRoute, keepQueryParams)} />
                )
            }
            path={idToPath(route)}
        />
    );
};

const RouteList = ({ routes, keepQueryParams }) => {
    const routesArr = useMemo(() => Object.values(routes), [routes]);
    const availableRoutes = useAvailableRoutes(routes);
    const dispatch = useDispatch();

    const isRenderReportConstantlyEnabled = useConfigMatch({
        [FEATURE_FLAGS.RENDER_REPORT_CONSTANTLY_ENABLED]: 'true',
    });

    const { modulePage: ModulePage, route = {}, path: modulePagePath } = useModulePage(routes);

    const defaultRoute = useMemo(
        () =>
            availableRoutes.find(elm => elm.isDefault) ||
            (availableRoutes[0] && !availableRoutes[0].nonAuthAccess ? availableRoutes[0] : undefined),
        [availableRoutes]
    );

    const navigate = useNavigate();

    const routesToRender = useMemo(() => {
        const availableRoutesMap = keyBy(availableRoutes, 'id');

        return routesArr.map(route => renderRoute(route, defaultRoute, availableRoutesMap[route.id], keepQueryParams));
    }, [routesArr, defaultRoute, availableRoutes, keepQueryParams]);

    const path = modulePagePath ?? idToPath(route);

    const history = useHistory();

    const onValidationError = useCallback(() => {
        dispatch(addIndication(formValidationErrorIndication));
    }, [dispatch]);

    const handleNavigation = useCallback(
        cancelFunc => {
            if (isFunction(cancelFunc)) {
                cancelFunc();
            }

            dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
            dispatch(
                navigate(history, path, {
                    skipFetchReport: true,
                })
            );
        },
        [dispatch, history, path, navigate]
    );

    if (!defaultRoute) {
        return null;
    }

    return (
        <>
            {ModulePage && isRenderReportConstantlyEnabled && (
                <Route
                    path={path}
                    render={params => (
                        <ModulePage
                            path={path}
                            {...params}
                            onCancel={handleNavigation}
                            onValidationError={onValidationError}
                        />
                    )}
                />
            )}
            <Switch>
                {routesToRender}
                <Redirect from={HOME_PATH} to={idToPath(defaultRoute, keepQueryParams)} />
            </Switch>
            <MultiLayerDrawer />
        </>
    );
};

RouteList.propTypes = {
    /** Map of route configurations */
    routes: PropTypes.object,
};

RouteList.defaultProps = {
    setFatalError: () => {},
};

export default RouteList;
