import { useMemo, useEffect } from 'react';
import { isNil, sum, values } from 'lodash';
import { getSegmentGroupTypesFromNodes, SEGMENT_GROUP_TYPE } from '../../MarketplaceAudiences/utils/andLogicUtils';
import { useSegmentTypeLimits } from './useSegmentTypeLimits';

export const useHiddenSegmentType = ({
    selectedNodes,
    marketplaceAudienceSegmentTypeCount,
    setMarketplaceAudienceSegmentTypeByIndex,
    index,
    isFullLoadingMode,
    getSplitTaxonomyList,
    ageGroupTaxonomyList,
    genderGroupTaxonomyList,
}) => {
    const { hasOnlyAge, hasOnlyGender, hasAge, hasGender, hasOther } = useMemo(
        () =>
            getSegmentGroupTypesFromNodes(selectedNodes, getSplitTaxonomyList, {
                ageGroupTaxonomyList,
                genderGroupTaxonomyList,
            }),
        [ageGroupTaxonomyList, genderGroupTaxonomyList, selectedNodes, getSplitTaxonomyList]
    );
    const { limits: segmentLimits } = useSegmentTypeLimits();

    useEffect(() => {
        if (isNil(index)) {
            return;
        }

        let type;

        if (hasOnlyAge) {
            type = SEGMENT_GROUP_TYPE.AGE;
        } else if (hasOnlyGender) {
            type = SEGMENT_GROUP_TYPE.GENDER;
        } else if (hasOther) {
            type = SEGMENT_GROUP_TYPE.OTHER;
        } else {
            type = SEGMENT_GROUP_TYPE.EMPTY;
        }

        setMarketplaceAudienceSegmentTypeByIndex(index, type);
    }, [hasOnlyAge, hasOnlyGender, hasOther, index, setMarketplaceAudienceSegmentTypeByIndex]);

    // Total number of groupings excluding this one
    const totalTypeCount = useMemo(
        () => sum(values(marketplaceAudienceSegmentTypeCount)),
        [marketplaceAudienceSegmentTypeCount]
    );

    const { shouldHideAge, shouldHideGender, shouldHideOther } = useMemo(() => {
        // if this instance doesn't have an index or if it is loading or if it is the only grouping, hide nothing, otherwise the loaders will be filtered
        if (isNil(index) || isFullLoadingMode || totalTypeCount === 0) {
            return { shouldHideAge: false, shouldHideGender: false, shouldHideOther: false };
        }

        // BUSINESS LOGIC:
        // limit the max number of each types
        // if Age or Gender are the only ones selected, only show that type
        // always show a type if it's selected (i.e. if you have Age and Other, always need to show Age)
        const shouldHideAge =
            !hasAge &&
            (hasOnlyGender ||
                marketplaceAudienceSegmentTypeCount[SEGMENT_GROUP_TYPE.AGE] >= segmentLimits[SEGMENT_GROUP_TYPE.AGE]);
        const shouldHideGender =
            !hasGender &&
            (hasOnlyAge ||
                marketplaceAudienceSegmentTypeCount[SEGMENT_GROUP_TYPE.GENDER] >=
                    segmentLimits[SEGMENT_GROUP_TYPE.GENDER]);
        const shouldHideOther =
            !hasOther &&
            (hasOnlyAge ||
                hasOnlyGender ||
                marketplaceAudienceSegmentTypeCount[SEGMENT_GROUP_TYPE.OTHER] >=
                    segmentLimits[SEGMENT_GROUP_TYPE.OTHER]);

        return {
            shouldHideAge,
            shouldHideGender,
            shouldHideOther,
        };
    }, [
        hasAge,
        hasGender,
        hasOnlyAge,
        hasOnlyGender,
        hasOther,
        index,
        isFullLoadingMode,
        marketplaceAudienceSegmentTypeCount,
        segmentLimits,
        totalTypeCount,
    ]);

    return { shouldHideAge, shouldHideGender, shouldHideOther };
};
