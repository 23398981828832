import { Color, ITheme } from 'tuui/lib/theme';
import { mapColorByHSL } from 'tuui/lib/utils/styles';

type ThemeColorProducer = (theme: ITheme) => Color;

export type ButtonVariant = 'primary' | 'outline' | 'ghost' | 'text';

export interface ButtonVariantSpec {
    fill: ThemeColorProducer;
    text: ThemeColorProducer;
    stroke: ThemeColorProducer;
    hover?: Partial<Pick<ButtonVariantSpec, 'fill' | 'text' | 'stroke'>>;
    active?: Partial<Pick<ButtonVariantSpec, 'fill' | 'text' | 'stroke'>>;
    disabled?: Partial<Pick<ButtonVariantSpec, 'fill' | 'text' | 'stroke'>>;
}
type ButtonVariantValue = keyof Pick<ButtonVariantSpec, 'fill' | 'text' | 'stroke'>;
type ButtonVariantState = keyof Omit<ButtonVariantSpec, ButtonVariantValue>;

const primary: ButtonVariantSpec = {
    fill: theme => theme.palette.primary.fill,
    text: theme => theme.palette.primary.text,
    stroke: theme => theme.palette.primary.stroke,
    hover: {
        fill: theme =>
            mapColorByHSL(theme.palette.primary.fill, hsl => {
                hsl.l -= 10;
            }),
        stroke: theme =>
            mapColorByHSL(theme.palette.primary.stroke, hsl => {
                hsl.l -= 10;
            }),
    },
    active: {
        fill: theme =>
            mapColorByHSL(theme.palette.primary.fill, hsl => {
                hsl.l -= 20;
            }),
        stroke: theme =>
            mapColorByHSL(theme.palette.primary.stroke, hsl => {
                hsl.l -= 20;
            }),
    },
    disabled: {
        stroke: theme => theme.palette.disabled.fill,
    },
};

const outline: ButtonVariantSpec = {
    text: theme => theme.palette.primary.fill,
    fill: theme => theme.palette.base.fill,
    stroke: theme => theme.palette.primary.stroke,
    hover: {
        text: theme => theme.palette.primary.text,
        fill: theme =>
            mapColorByHSL(theme.palette.primary.fill, hsl => {
                hsl.l -= 10;
            }),
        stroke: theme =>
            mapColorByHSL(theme.palette.primary.stroke, hsl => {
                hsl.l -= 10;
            }),
    },
    active: {
        text: theme => theme.palette.primary.text,
        fill: theme =>
            mapColorByHSL(theme.palette.primary.fill, hsl => {
                hsl.l -= 20;
            }),
        stroke: theme =>
            mapColorByHSL(theme.palette.primary.stroke, hsl => {
                hsl.l -= 20;
            }),
    },
};

const ghost: ButtonVariantSpec = {
    text: theme => theme.palette.secondary.text,
    fill: theme => theme.palette.base.fill,
    stroke: theme => theme.palette.grayscale[6],
    hover: {
        text: theme =>
            mapColorByHSL(theme.palette.secondary.text, hsl => {
                hsl.l -= 10;
            }),
        fill: theme => theme.palette.grayscale[2],
        stroke: theme =>
            mapColorByHSL(theme.palette.grayscale[6], hsl => {
                hsl.l -= 10;
            }),
    },
    active: {
        text: theme =>
            mapColorByHSL(theme.palette.secondary.text, hsl => {
                hsl.l -= 20;
            }),
        fill: theme => theme.palette.grayscale[4],
        stroke: theme =>
            mapColorByHSL(theme.palette.grayscale[6], hsl => {
                hsl.l -= 20;
            }),
    },
};

const text: ButtonVariantSpec = {
    fill: theme => theme.palette.none,
    stroke: theme => theme.palette.none,
    text: theme => theme.palette.secondary.text,
    hover: {
        text: theme =>
            mapColorByHSL(theme.palette.secondary.text, hsl => {
                hsl.l -= 10;
            }),
        fill: theme => theme.palette.grayscale[2],
        stroke: theme => theme.palette.grayscale[2],
    },
    active: {
        text: theme =>
            mapColorByHSL(theme.palette.secondary.text, hsl => {
                hsl.l -= 20;
            }),
        fill: theme => theme.palette.grayscale[4],
        stroke: theme => theme.palette.grayscale[4],
    },
    disabled: {
        fill: theme => theme.palette.none,
        stroke: theme => theme.palette.none,
        text: theme => theme.palette.grayscale[5],
    },
};

export const buttonVariants: Record<ButtonVariant, ButtonVariantSpec> = {
    primary,
    outline,
    ghost,
    text,
};

export const variantStateVariable = (state: ButtonVariantState, varName: ButtonVariantValue) => ({
    theme,
    $variant,
}: {
    theme: ITheme;
    $variant: ButtonVariant;
}) => {
    const variantSpec = buttonVariants[$variant];
    const varValueForState = variantSpec[state]?.[varName]?.(theme);
    if (!varValueForState) {
        return null;
    }
    return `--${varName}: ${varValueForState}`;
};
