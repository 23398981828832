import React from 'react';
import { batch } from 'react-redux';
import { identity } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { addIndication, removeIndication } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import {
    createCombinedAudience,
    createCrmFileAudience,
    createCustomContextualSegment,
    createSRTAudience,
    createMRTAudience,
    createPixelBasedAudience,
    createPixelBasedPredictiveAudience,
    sendCrmFileContentSampleForDebug,
} from 'services/api/audiencesApi';
import { errorMessagesUtils } from 'services/utils';
import { REPORT_TYPE } from '../../campaigns/config';
import { createAudienceError, createAudienceSuccess, requestCreateAudience } from '../actions/';
import { AUDIENCE_STATUS, AUDIENCE_TYPE } from '../config';
import {
    transformExternalAudiencesSegmentToGW,
    transformLookalikeAudienceToGW,
    transformPixelBasedAudienceToGW,
    transformCombinedAudiencesSegmentToGW,
    transformPixelBasedPredictiveAudienceToGW,
} from '../transformers';
import { CrmFileError } from '../transformers/lookalikeCrmFileTransformer';

const successIndication = {
    message: <FormattedMessage id="audience.creator.save.success" defaultMessage="Audience successfully created" />,
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" />,
};

const generateErrorIndication = (errorObject = {}) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="audience.creator.save.error.highlight" defaultMessage="Unable to save changes." />,
});

const apis = {
    [AUDIENCE_TYPE.BASIC]: createPixelBasedAudience,
    [AUDIENCE_TYPE.EVENT_BASED]: createPixelBasedAudience,
    [AUDIENCE_TYPE.LOOKALIKE]: createCrmFileAudience,
    [AUDIENCE_TYPE.CUSTOM_CONTEXTUAL]: createCustomContextualSegment,
    [AUDIENCE_TYPE.SEARCH_KEYWORDS]: createSRTAudience,
    [AUDIENCE_TYPE.MAIL_DOMAINS]: createMRTAudience,
    [AUDIENCE_TYPE.COMBINED_AUDIENCES]: createCombinedAudience,
    [AUDIENCE_TYPE.PBP_LOOKALIKE]: createPixelBasedPredictiveAudience,
};

const transformers = {
    [AUDIENCE_TYPE.BASIC]: transformPixelBasedAudienceToGW,
    [AUDIENCE_TYPE.EVENT_BASED]: transformPixelBasedAudienceToGW,
    [AUDIENCE_TYPE.LOOKALIKE]: transformLookalikeAudienceToGW,
    [AUDIENCE_TYPE.CUSTOM_CONTEXTUAL]: identity,
    [AUDIENCE_TYPE.SEARCH_KEYWORDS]: transformExternalAudiencesSegmentToGW,
    [AUDIENCE_TYPE.MAIL_DOMAINS]: transformExternalAudiencesSegmentToGW,
    [AUDIENCE_TYPE.COMBINED_AUDIENCES]: transformCombinedAudiencesSegmentToGW,
    [AUDIENCE_TYPE.PBP_LOOKALIKE]: transformPixelBasedPredictiveAudienceToGW,
};

const generateWorkerErrorIndication = ({ msgId, defaultMessage }) => ({
    message:
        msgId && defaultMessage ? (
            <FormattedMessage id={msgId} defaultMessage={defaultMessage} />
        ) : (
            'Unable to process file'
        ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="audience.creator.save.error.highlight" defaultMessage="Unable to save changes." />,
});

export const createAudience = (accountName, audience, reportType) => async dispatch => {
    dispatch(removeIndication({ type: INDICATION_TYPES.ERROR }));
    dispatch(requestCreateAudience());

    const apiType = getApiType(audience.type, reportType);

    let transformedAudience;
    try {
        transformedAudience = await transformers[apiType](audience);
    } catch (error) {
        if (error instanceof CrmFileError) {
            dispatch(addIndication(generateWorkerErrorIndication(error)));
        } else {
            dispatch(addIndication(generateErrorIndication(error)));
        }
        throw error;
    }

    if (apiType === AUDIENCE_TYPE.LOOKALIKE) {
        try {
            await sendCrmFileContentSampleForDebug(accountName, transformedAudience);
        } catch (error) {
            console.error('sendCrmFileContentSampleForDebug failed' + JSON.stringify(error));
        }
    }

    if (apiType === AUDIENCE_TYPE.SEARCH_KEYWORDS || apiType === AUDIENCE_TYPE.MAIL_DOMAINS) {
        try {
            const createdAudience = await apis[apiType](accountName, transformedAudience);
            if (createdAudience.reviewStatus !== AUDIENCE_STATUS.REJECTED) {
                batch(() => {
                    dispatch(createAudienceSuccess(createdAudience));
                    dispatch(addIndication(successIndication));
                });
            }
            return createdAudience;
        } catch (error) {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(createAudienceError(error));
            throw error;
        }
    }

    try {
        const createdAudience = await apis[apiType](accountName, transformedAudience);
        batch(() => {
            dispatch(createAudienceSuccess(createdAudience));
            dispatch(addIndication(successIndication));
        });
        return createdAudience;
    } catch (error) {
        batch(() => {
            dispatch(addIndication(generateErrorIndication(error)));
            dispatch(createAudienceError(error));
        });
        throw error;
    }
};

const getApiType = (audienceType, reportType) => {
    return reportType !== REPORT_TYPE.FIRST_PARTY ||
        audienceType === AUDIENCE_TYPE.SEARCH_KEYWORDS ||
        audienceType === AUDIENCE_TYPE.MAIL_DOMAINS
        ? audienceType
        : AUDIENCE_TYPE.LOOKALIKE;
};

export default createAudience;
