/* Audience For Edit */
export const REQUEST_FETCH_AUDIENCE_FOR_EDIT = 'REQUEST_FETCH_AUDIENCE_FOR_EDIT';
export const FETCH_AUDIENCE_FOR_EDIT_SUCCESS = 'FETCH_AUDIENCE_FOR_EDIT_SUCCESS';
export const FETCH_AUDIENCE_FOR_EDIT_ERROR = 'FETCH_AUDIENCE_FOR_EDIT_ERROR';

export const REQUEST_CREATE_AUDIENCE = 'REQUEST_CREATE_AUDIENCE';
export const CREATE_AUDIENCE_SUCCESS = 'CREATE_AUDIENCE_SUCCESS';
export const CREATE_AUDIENCE_ERROR = 'CREATE_AUDIENCE_ERROR';

export const REQUEST_UPDATE_AUDIENCE = 'REQUEST_UPDATE_AUDIENCE';
export const UPDATE_AUDIENCE_SUCCESS = 'UPDATE_AUDIENCE_SUCCESS';
export const UPDATE_AUDIENCE_ERROR = 'UPDATE_AUDIENCE_ERROR';
