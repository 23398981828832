import { useMemo } from 'react';
import classnames from 'classnames/bind';
import { get, keyBy } from 'lodash';
import { DevicesIcon, PhoneIphoneIcon, PersonalVideoIcon } from 'tuui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import PLATFORMS from '../../../../campaigns/modules/common-campaign-form/config/platforms';
import { useAccount } from '../../../../taboola-common-frontend-modules/account-configurations';
import styles from './MediaPlanBudget.module.scss';

const classNameBuilder = classnames.bind(styles);

export const getPlatformMessageId = value => {
    const platformTargeting = get(value, 'platformTargeting');
    const platformMap = keyBy(platformTargeting);

    if (platformMap[PLATFORMS.DESKTOP] && platformMap[PLATFORMS.MOBILE] && platformMap[PLATFORMS.TABLET]) {
        return 'ALL';
    }

    return get(platformTargeting, '0');
};

const iconMap = {
    [PLATFORMS.DESKTOP]: <PersonalVideoIcon />,
    [PLATFORMS.MOBILE]: <PhoneIphoneIcon />,
    ALL: <DevicesIcon />,
};

export const MediaPlanBudget = () => {
    const { value: budgetList } = useFormFieldValue({ field: 'mediaPlan.budget' });
    const { currency } = useAccount();
    const monthlyTotal = useMemo(() => {
        const total = (budgetList || []).reduce((acc, item) => acc + (get(item, 'budget.spendingLimit.value') ?? 0), 0);

        return total;
    }, [budgetList]);

    return (
        <>
            <div className={styles['wrapper']}>
                {budgetList.map((campaignBudget, index) => (
                    <div className={styles['budget']} key={`${index}_${campaignBudget?.budget?.value}`}>
                        <div>
                            <FormattedMessage id="onboarding.form.campaign" defaultMessage="Campaign" /> {index + 1}
                        </div>
                        <div className={styles['budget-details']}>
                            <span className={styles['icon-wrapper']}>
                                {iconMap[getPlatformMessageId(campaignBudget)]}
                            </span>
                            <FormattedMessage
                                id={`campaign.editor.bid.platformParting.${getPlatformMessageId(campaignBudget)}`}
                                className={styles['label']}
                            />
                            <span>
                                {campaignBudget?.budget?.value && (
                                    <FormattedNumber
                                        value={campaignBudget?.budget?.value}
                                        currency={currency}
                                        variant="currency"
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className={classNameBuilder('label', 'total')}>
                <FormattedMessage id="onboarding.form.media.plan.budget.monthly.total" defaultMessage="Monthly total" />
            </div>
            <div>
                {monthlyTotal ? <FormattedNumber value={monthlyTotal} currency={currency} variant="currency" /> : ''}
            </div>
        </>
    );
};
