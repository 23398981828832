import React from 'react';
import { useNavigateToAdsReport } from 'modules/campaigns/modules/performance-recommendations/hooks/useNavigateToAdsReport';
import { FormattedRelativeTime } from 'modules/taboola-common-frontend-modules/i18n';
import { NotificationCard } from '../../NotificationCard';

export const InaccessibleImageNotification = ({ notification, closeNotificationCenter, timeDiffInMinutes }) => {
    const {
        attributes: { targetItems },
    } = notification;

    const { navigateToAdsReport } = useNavigateToAdsReport({
        formattedItemIds: targetItems,
        label: 'Inaccessible images',
    });

    return (
        <NotificationCard
            notification={notification}
            cardClick={navigateToAdsReport}
            closeNotificationCenter={closeNotificationCenter}
            dataMetricsComponent="NotificationAlertCard"
            formattedMessageId="app.notificationsCenter.timeAgo-message"
            formattedMessageValues={{
                notificationTimeAgo: <FormattedRelativeTime timeDiffInMinutes={timeDiffInMinutes} />,
            }}
        />
    );
};
