import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { stripeErrorMap } from '../consts';
import { transformConfirmSetupResponse } from '../transformers';

export const useStripePaymentElementSetup = ({ updateElementState, elementsState }) => {
    const stripeObject = useStripe();
    const elements = useElements();

    const markInvalidField = useCallback(
        error => {
            const { code = 'other' } = error;
            const errorField = stripeErrorMap[code];

            if (errorField) {
                const currentElementState = elementsState[errorField];
                updateElementState(errorField, { ...currentElementState, error });
            }
        },
        [elementsState, updateElementState]
    );

    const confirmSetup = useCallback(async () => {
        const response = await stripeObject.confirmSetup({ elements, redirect: 'if_required' });
        const { error } = response;
        if (error) {
            markInvalidField(error);
        }

        return transformConfirmSetupResponse(response);
    }, [markInvalidField, elements, stripeObject]);

    return { confirmSetup };
};
