import { useCallback } from 'react';
import uuid from 'uuid/v1';
import { marketplaceAudiencesCollectionPath } from 'modules/campaigns/modules/common-campaign-form/components/MarketplaceAudiences/utils/andLogicUtils';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { TARGETING_TYPES } from '../../../config';

const DEFAULT_COLLECTION = [];

export const useMarketplaceAudienceTargetingCollection = () => {
    const { value: marketplaceAudienceTargetingCollection = DEFAULT_COLLECTION, onChange: onChangeCollection } =
        useFormFieldValue({
            field: marketplaceAudiencesCollectionPath,
        });

    const onAddTargeting = useCallback(
        (targetingType = TARGETING_TYPES.INCLUDE) => {
            onChangeCollection([
                ...marketplaceAudienceTargetingCollection,
                { type: targetingType, values: [], id: uuid() },
            ]);
        },
        [marketplaceAudienceTargetingCollection, onChangeCollection]
    );

    const onDeleteTargeting = useCallback(
        indexToDelete => {
            onChangeCollection(marketplaceAudienceTargetingCollection.filter((t, index) => indexToDelete !== index));
        },
        [marketplaceAudienceTargetingCollection, onChangeCollection]
    );

    return {
        marketplaceAudienceTargetingCollection,
        onAddTargeting,
        onDeleteTargeting,
    };
};
