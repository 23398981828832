import React from 'react';
import { useSelector } from 'react-redux';
import { CurrencyInput } from 'taboola-ultimate-ui';
import { selectedAccountSelector } from '../../../../../selectors';
import { useIntl } from '../../hooks';

const LocalCurrencyInput = ({ disableGroupSeparators = true, allowNegativeValue = false, ...rest }) => {
    const { currency } = useSelector(selectedAccountSelector);
    const { locale } = useIntl();
    return (
        <CurrencyInput
            disableGroupSeparators={disableGroupSeparators}
            allowNegativeValue={allowNegativeValue}
            {...rest}
            intlConfig={{
                locale,
                currency,
            }}
        />
    );
};

export default LocalCurrencyInput;
