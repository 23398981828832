import { config as authenticationConfig } from '../../modules/taboola-common-frontend-modules/authentication';
import { config as googleApiConfig } from '../../modules/taboola-common-frontend-modules/googleApi';
import { gtmTracker, config as gtmConfig } from '../../modules/taboola-common-frontend-modules/gtmTracker';
import { config as i18nConfig } from '../../modules/taboola-common-frontend-modules/i18n';
import { config as logRocketConfig } from '../../modules/taboola-common-frontend-modules/log-rocket';
import { config as rboxLoaderConfig } from '../../modules/taboola-common-frontend-modules/rbox-loader';
import { videoUnitLoader } from '../../modules/taboola-common-frontend-modules/video-unit-loader';
import Config from '../configuration/configurationService';
import initiator from './initiator';

export function setupInitiator() {
    initiator.register(authenticationConfig);
    initiator.register(googleApiConfig);
    initiator.register(gtmConfig);
    initiator.register(i18nConfig);
    initiator.register(rboxLoaderConfig);
    initiator.register(videoUnitLoader);
    initiator.register(logRocketConfig);
}

const Bootstrap = initStore => {
    setupInitiator();

    return Config.init().then(() => {
        const config = Config.configObj;
        initiator.initAll(config);
        gtmTracker.init(gtmConfig.gtmContainerId, {}, gtmConfig.sendClientSideErrorsWithStacktrace);

        initStore();
    });
};

export default Bootstrap;
