import React from 'react';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { useMediaTabsContext } from '../../MediaTabsContextProvider/MediaTabsContextProvider';
import { GenerativeAiDisclaimerModalContent } from './GenerativeAiDisclaimerModalContent';
import { useHasAcceptedGenerativeAiDisclaimer } from './useHasAcceptedGenerativeAiDisclaimer';
import styles from './generativeAiDisclaimerModal.module.scss';

export const useGenerativeAiDisclaimerModal = onSelectMedia => {
    const { open: openModal } = useModal();
    const { setHasAcceptedGenerativeAiDisclaimer } = useHasAcceptedGenerativeAiDisclaimer();
    const { campaignId } = useMediaTabsContext();

    const openDisclaimerModal = async (...onSelectMediaArgs) => {
        const accepted = await openModal({
            formProps: {
                hasCancel: false,
                submitButtonText: (
                    <FormattedMessage
                        id="creative.editor.tab.aiGenerated.disclaimer.submitButtonText"
                        defaultMessage="Accept & Continue"
                    />
                ),
                footerContainerClassName: styles['footer'],
            },
            className: styles['container'],
            headerClassName: styles['header'],
            title: (
                <FormattedMessage
                    id="creative.editor.tab.aiGenerated.disclaimer.title"
                    tagName="h3"
                    defaultMessage="Generative AI Content Disclaimer"
                />
            ),
            hasCloseButton: false,
            content: <GenerativeAiDisclaimerModalContent />,
        });
        if (accepted) {
            onSelectMedia(...onSelectMediaArgs);
            setHasAcceptedGenerativeAiDisclaimer(true);
        }
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `AIGeneratedImagesTab - ${accepted ? 'Accepted' : 'Declined'} disclaimer`,
            taboolaCampaignId: campaignId,
        });
    };

    return { openDisclaimerModal };
};
