import { navigate } from '../../../actions';
import { setRecommendations } from '../actions';
import RECOMMENDATION_TYPE from '../recommendationTypes';
import getRecommendationUrl from '../utils/getRecommendationUrl';
import RECOMMENDATION_ACTION from '../utils/recommendationActions';

const audienceRetargetingHandler =
    ({ history, recommendationData, recommendationMetadata }) =>
    dispatch => {
        const { recommendationId } = recommendationMetadata;
        const destinationPath = getRecommendationUrl(RECOMMENDATION_ACTION.CREATE_CAMPAIGN);

        dispatch(
            setRecommendations({
                recommendationType: RECOMMENDATION_TYPE.AUDIENCE_RETARGETING,
                recommendationData,
                recommendationId,
            })
        );
        dispatch(navigate(history, destinationPath));
    };

export default audienceRetargetingHandler;
