import { GENERATIVE_AI_TAB_PREFIX } from '../../CreativeStudio/creativeStudioEventsPrefix';
import { GalleryItemDropdown } from '../../GalleryItemDropdown/GalleryItemDropdown';
import { GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS } from '../../GalleryItemDropdown/GalleryItemDropdownOptions';

const options = [
    GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.SAVE_TO_MEDIA_LIBRARY,
    GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.REPLACE_BACKGROUND,
    GALLERY_ITEM_DROPDOWN_OPTION_CONFIGS.IMAGE_VARIATIONS,
];

export const AIGeneratedTabGalleryItemDropdown = props => {
    return <GalleryItemDropdown options={options} eventsLocationPrefix={GENERATIVE_AI_TAB_PREFIX} {...props} />;
};
