import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import FormattedNumberCellRenderer from './FormattedNumberCellRenderer';
import styles from './progressBarCellRenderer.module.scss';

const classNameBuilder = classnames.bind(styles);
const isValidProgressData = (totalValue, value) => {
    const total = parseFloat(totalValue);
    const val = parseFloat(value);

    return val >= 0.0 && val <= total;
};

const ProgressBarCellRenderer = ({
    value,
    getTotalValue,
    data,
    totalMaximumFractionDigits,
    totalMinimumFractionDigits,
    barVisibilityCondition,
    ...rest
}) => {
    const progressTotalValue = parseFloat(getTotalValue(data));
    const progressProps = {
        ...rest,
        value: progressTotalValue,
        maximumFractionDigits: totalMaximumFractionDigits,
        minimumFractionDigits: totalMinimumFractionDigits,
    };
    const shouldShowProgress = isValidProgressData(progressTotalValue, value);
    const shouldShowBar = !barVisibilityCondition || barVisibilityCondition(data);
    const progressClassName = classNameBuilder('progress-bar', { 'max-reached': value === progressTotalValue });

    return shouldShowProgress ? (
        <div className={styles['container']}>
            <FormattedNumberCellRenderer value={value} {...rest} />
            <div className={styles['total-value-container']}>
                <div className={styles['total-value-of']}>
                    <FormattedMessage id="app.reports.byCampaign.spent.totalValueOf" />
                </div>
                <div>
                    <FormattedNumberCellRenderer {...progressProps} />
                </div>
            </div>
            {shouldShowBar ? <progress className={progressClassName} value={value} max={progressTotalValue} /> : null}
        </div>
    ) : (
        <FormattedNumberCellRenderer value={value} {...rest} />
    );
};

ProgressBarCellRenderer.propTypes = {
    /** value which is injected by the grid  - The progress bar value */
    value: PropTypes.number,
    /** injected by the grid - Function which determines the total value of the Progress bar */
    getTotalValue: PropTypes.func,
    /** value which is injected by the grid - Data of current grid row */
    data: PropTypes.object,
    /** value defines maximum number of fraction digits in total FormattedNumberCellRenderer component */
    totalMaximumFractionDigits: PropTypes.number,
    /** value defines minimum number of fraction digits in total FormattedNumberCellRenderer component */
    totalMinimumFractionDigits: PropTypes.number,
    /** Bar visibility condition callback */
    barVisibilityCondition: PropTypes.func,
};

ProgressBarCellRenderer.defaultProps = {
    barVisibilityCondition: () => true,
};

export default ProgressBarCellRenderer;
