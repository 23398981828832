import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { startCase } from 'lodash';
import { Button } from 'tuui';
import { Input, InputTypes } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useConditionCollection } from 'modules/performance-rules/hooks/useConditionCollection';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useConditions } from '../../../../hooks/useConditions';
import { AddConditionButton } from '../AddConditionButton/AddConditionButton';
import { Condition } from '../Condition/Condition';
import { DimensionDropdown } from '../DimensionDropdown/DimensionDropdown';
import styles from './conditionsAction.module.scss';

const classNameBuilder = classnames.bind(styles);

const maxConditionNumber = 5;
export const ConditionsAction = () => {
    const { formatMessage } = useIntl();
    const {
        setDimension,
        settingValue,
        selectedTemplate,
        extractedTemplateKeys,
        onTemplateChange,
        templateKey,
        action,
        conditions,
        setConditions,
    } = useConditions();

    const { onAddCondition, onDeleteCondition } = useConditionCollection(conditions, setConditions);

    const handleAddTargeting = useCallback(() => {
        onAddCondition();
    }, [onAddCondition]);

    const {
        value: actionValue,
        onChange: actionOnChange,
        mode,
    } = useFormFieldValue({ field: 'actions[0].actionType' }, { action });

    return (
        <div>
            <DimensionDropdown setDimension={setDimension} />
            {mode !== FORM_MODES.EDIT && (
                <div className={styles['template-button-container']}>
                    {extractedTemplateKeys?.map((obj, index) => {
                        const isSelectedKey = obj?.key === selectedTemplate?.key;
                        return (
                            <Button
                                variant="ghost"
                                key={obj?.key}
                                className={classNameBuilder('button', {
                                    selected: isSelectedKey,
                                })}
                                size={Button.size.lg}
                                onClick={() => {
                                    onTemplateChange(obj);
                                }}
                                aria-label="template"
                            >
                                <FormattedMessage
                                    id={`app.create.performance.rules.template.${templateKey[index]}`}
                                    defaultMessage={obj?.key}
                                />
                            </Button>
                        );
                    })}
                </div>
            )}
            <FormNamespaceProvider field="conditions">
                <div className={styles['condition']}>
                    {conditions?.map(({ metricName, timeFrame, predicateType, value, subMetric, id: key }, index) => {
                        return (
                            <FormNamespaceProvider field={index} itemKey={key} key={`condition_${key}`}>
                                <Condition
                                    settingValue={settingValue}
                                    setConditions={setConditions}
                                    conditions={conditions}
                                    index={index}
                                    onDeleteCondition={() => onDeleteCondition(index)}
                                />
                            </FormNamespaceProvider>
                        );
                    })}
                    {conditions?.length < maxConditionNumber && (
                        <AddConditionButton onClick={handleAddTargeting} index={conditions.length} />
                    )}
                </div>
            </FormNamespaceProvider>
            <div className={styles['action']}>
                <span className={styles['action-icon']}>
                    <FormattedMessage id="app.create.performance.rules.then.condition" />
                </span>
                <Input
                    inputClass={styles['input-action-text']}
                    id="action"
                    className={styles['input-action']}
                    value={
                        actionValue
                            ? formatMessage({
                                  id: `performance.rule.create.settings.action.${actionValue}`,
                              })
                            : startCase(actionValue)
                    }
                    onChange={actionOnChange}
                    type={InputTypes.TEXT}
                    data-metrics-event-name={GTM_EVENTS.USABILITY}
                    data-metrics-component="action"
                    aria-label="action"
                    disabled
                />
            </div>
        </div>
    );
};
