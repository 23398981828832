import React from 'react';
import PropTypes from 'prop-types';
import { TOOLTIP_POSITION } from 'taboola-ultimate-ui';
import { TooltipWrapper } from 'components/TooltipWrapper/TooltipWrapper';
import { FormattedMessage, ONLY_VALUE_MESSAGE_ID } from 'modules/taboola-common-frontend-modules/i18n';
import normalizeHeaderRenderer from './normalizeHeaderRenderer';
import styles from './formattedMessageWithTooltipHeaderRenderer.module.scss';

export const HeaderTooltip = ({ tooltipHeaderMsgId, tooltipContentMsgId }) => (
    <div className={styles['tooltip-container']}>
        <div className={styles['tooltip-header']}>
            <FormattedMessage id={tooltipHeaderMsgId} />
        </div>
        <FormattedMessage id={tooltipContentMsgId} />
    </div>
);

HeaderTooltip.propTypes = {
    tooltipHeaderMsgId: PropTypes.string,
    tooltipContentMsgId: PropTypes.string,
};

export const FormattedMessageWithTooltipHeaderRenderer = props => {
    // extract values from the column definition
    const {
        tooltipHeaderMsgIdPrefix,
        tooltipContentMsgIdPrefix,
        messageIdPrefix,
        field,
        headerName,
        tooltipContent,
        tooltipOptions = {},
        useOnlyValue,
    } = props;

    // construct formatted message IDs
    const tooltipHeaderMsgId = `${tooltipHeaderMsgIdPrefix}.${field}`;
    const tooltipContentMsgId = `${tooltipContentMsgIdPrefix}.${field}`;
    const finalMessageId = useOnlyValue ? ONLY_VALUE_MESSAGE_ID : `${messageIdPrefix}.${field}`;
    const contentCreator = tooltipContent || HeaderTooltip;

    return (
        <TooltipWrapper
            tooltipContent={contentCreator({
                tooltipHeaderMsgId,
                tooltipContentMsgId,
            })}
            tooltipOptions={{ interactive: true, arrow: true, position: TOOLTIP_POSITION.BOTTOM, ...tooltipOptions }}
        >
            <FormattedMessage id={finalMessageId} defaultMessage={headerName} values={{ value: headerName }} />
        </TooltipWrapper>
    );
};

FormattedMessageWithTooltipHeaderRenderer.propTypes = {
    // formatted message id prefixes
    tooltipHeaderMsgIdPrefix: PropTypes.string,
    tooltipContentMsgIdPrefix: PropTypes.string,
    messageIdPrefix: PropTypes.string,
    // options passed to the Tooltip component
    tooltipOptions: PropTypes.object,
    // values passed from colDef
    field: PropTypes.string,
    // header title caption
    headerName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // should use only value message id
    useOnlyValue: PropTypes.bool,
};

FormattedMessageWithTooltipHeaderRenderer.defaultValues = {
    tooltipOptions: {},
};

export default normalizeHeaderRenderer(FormattedMessageWithTooltipHeaderRenderer);
