import { Color } from './types';

export const zIndex: Record<string, number> = {
    firstLayer: 1,
    secondLayer: 2,
};

export const COLORS: Record<string, Color> = {
    White: '#ffffff',
    Black: '#000000',

    Green100: '#F3FCF0',
    Green200: '#88DD66',
    Green300: '#00de8d',
    Green400: '#01D98E',
    Green500: '#00CB84',
    Green600: '#00A86D',
    Green700: '#009963',

    Gray100: '#f6f7f8',
    Gray200: '#eef0f2',
    Gray300: '#eaecef',
    Gray400: '#d4d9df',
    Gray500: '#b8c1ca',
    Gray600: '#9ca8b5',
    Gray700: '#667686',
    Gray800: '#4c5560',
    Gray900: '#212832',

    Blue100: '#4D86EC',
    Blue200: '#4D86EC',
    Blue300: '#004B7A',
    Blue400: '#02324E',

    Yellow100: '#FEF1C1',
    Yellow200: '#FEF6DA',
    Yellow300: '#FFCB0A',
    Yellow400: '#FAC607',
    Yellow500: '#FEF9E6',

    Red100: '#FDF0EF',
    Red200: '#E86861',
    Red300: '#E23E57',
};
