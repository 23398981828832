import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import EMPTY_FIELD_VALUE from '../../config/defaultValues';
import { regionValueFormatter } from '../ValueFormatters';
import EllipsisCellRenderer from './EllipsisCellRenderer';

class RegionCellRenderer extends Component {
    render() {
        const {
            msgIdPrefix,
            emptyValue,
            context: { intl },
            data,
            isSearchable,
        } = this.props;
        const regionView = !isEmpty(data)
            ? regionValueFormatter({ rowData: data, formatOptions: { msgIdPrefix }, i18n: intl })
            : `${emptyValue} (${emptyValue})`;

        return <EllipsisCellRenderer value={regionView} isSearchable={isSearchable} />;
    }
}

RegionCellRenderer.propTypes = {
    /** Cell string data */
    data: PropTypes.object,
    /** Cell msgId Prefix */
    msgIdPrefix: PropTypes.string,
    /** Cell context */
    context: PropTypes.object,
    /** Cell empty value */
    emptyValue: PropTypes.string,
};

RegionCellRenderer.defaultProps = {
    emptyValue: EMPTY_FIELD_VALUE,
    data: {},
    context: {},
};

export default RegionCellRenderer;
