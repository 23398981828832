import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'modules/taboola-common-frontend-modules/authentication/selectors';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

export const saveItem = (key, item) => {
    try {
        localStorage.setItem(key, JSON.stringify(item));
        return true;
    } catch (error) {
        gtmTracker.trackError(error);
        return false;
    }
};

export const getItem = (key, defaultValue) => {
    try {
        return JSON.parse(localStorage.getItem(key)) || defaultValue;
    } catch (error) {
        gtmTracker.trackError(error);
        return defaultValue;
    }
};

const getStorageKey = (username, key) => `${username}_${key}`;

export const getFromLocalStorage = (username, key) => getItem(getStorageKey(username, key));

export const unsetLocalStorage = (username, key) => {
    try {
        localStorage.removeItem(getStorageKey(username, key));
    } catch (err) {}
};

export const useLocalStorage = () => {
    const { username } = useSelector(userSelector);
    const getter = useCallback(
        key => {
            if (!username) {
                return;
            }

            return getItem(getStorageKey(username, key));
        },
        [username]
    );

    const setter = useCallback(
        (key, value) => {
            if (!username) {
                return;
            }

            return saveItem(getStorageKey(username, key), value);
        },
        [username]
    );

    return [getter, setter, !!username];
};
