import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MODULE_PARAM_NAME } from 'config/routes/module';
import { DIMENSION } from 'hooks/queryParams/useDimension';
import { ACCOUNT_ID } from 'hooks/queryParams/useSelectedAccountId';
import { getDefaultReportId } from 'modules/campaigns/config/reportsBaseConfig';
import { setReportDataStatus } from 'modules/campaigns/modules/campaigns-reports/actions';
import { queryParamHookFactory } from 'modules/taboola-common-frontend-modules/query-params';
import { PERSISTENCE_TYPE } from 'modules/taboola-common-frontend-modules/storage/persistenceType';
import { COMPONENT_STATUS } from 'services/constants';

export const REPORT_ID = 'reportId';

export const useSelectedReportIdParam = queryParamHookFactory(REPORT_ID, {
    defaultValue: ({ [DIMENSION]: dimension }) => getDefaultReportId(dimension),
    persist: PERSISTENCE_TYPE.MEMORY,
    dependencies: {
        pathParams: [MODULE_PARAM_NAME],
        queryParams: [DIMENSION, ACCOUNT_ID],
    },
});

export const useSelectedReportId = () => {
    const dispatch = useDispatch();
    const [reportId, setReportId] = useSelectedReportIdParam();

    const setReport = useCallback(
        id => {
            dispatch(setReportDataStatus(COMPONENT_STATUS.INITIAL));
            setReportId(id);
        },
        [dispatch, setReportId]
    );

    return [reportId, setReport];
};
