import React from 'react';
import { TooltipSection } from '../../../../../campaigns/modules/common-campaign-form';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';

export const ImpressionsTitleTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="custom.contextual.size.estimation.impressions.title.tooltip.title"
                defaultMessage="Visualization of impressions for the last 30 days for the selected Country, Platform, and topics."
            />
        </TooltipSection>
    </>
);
