import { get, set } from 'lodash';
import { ACCOUNT_ID, DIMENSION } from 'hooks';
import { campaignNameValidations, CAMPAIGNS_ROUTE, REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import { normalizeColumnDefs } from 'modules/campaigns/modules/campaigns-reports/utils';
import { selectCampaignAndReport } from '../../../../../flows';
import { CAMPAIGN_ID } from '../../../../../hooks';
import {
    ActionableTextWithInternalLinkCellRenderer,
    CampaignActionsCellRenderer,
    CampaignStatusRenderer,
    FormattedMessageHeaderRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
    StateCellRenderer,
} from '../../../components';
import { duplicateCampaign, saveCampaign } from '../../../flows';
import { DATE_PRESET, END_DATE, REPORT_ID, START_DATE } from '../../../hooks';
import addMeasuredByMoatToField from '../../../utils/addMeasuredByMoat';
import {
    actionsColumnType,
    editableColumn,
    keyColumn,
    leftPinnedColumn,
    lockedColumn,
    numericColumn,
    rightPinnedColumn,
    staticColumn,
    toggleColumn,
} from '../../columns';
import * as FIELDS from '../../fields';
import { nonDefaultHeaderComponentParams } from '../../fields';
import { isCampaignEditable, isVideoBudgetEditable, isVideoCampaignEditable } from '../../predicates';

const creativeReportId = REPORT_TYPE.CREATIVE_VIDEO;
const campaignNameClickAction = campaign => selectCampaignAndReport(campaign, creativeReportId);
const isDisabled = data => !isCampaignEditable({ data });

const campaignReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.IS_ACTIVE.label,
            field: FIELDS.IS_ACTIVE.field,
            type: [toggleColumn, leftPinnedColumn, staticColumn, lockedColumn],
            cellRendererParams: {
                ...FIELDS.IS_ACTIVE.renderParams,
                isDisabled,
                reportId: REPORT_TYPE.CAMPAIGN_VIDEO,
            },
            cellRendererFramework: StateCellRenderer,
            csv: false,
            permissions: {
                permissions: ['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'],
            },
        },
        {
            headerName: FIELDS.ID.label,
            field: FIELDS.ID.field,
            type: [numericColumn],
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: nonDefaultHeaderComponentParams(REPORT_TYPE.CAMPAIGN_VIDEO),
        },
        {
            headerName: FIELDS.NAME.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.NAME.headerComponentParams,
            field: FIELDS.NAME.field,
            type: [leftPinnedColumn, keyColumn, editableColumn],
            editable: isVideoCampaignEditable,
            cellRendererFramework: ActionableTextWithInternalLinkCellRenderer,
            cellRendererParams: {
                lines: 2,
                to: CAMPAIGNS_ROUTE,
                preventRouting: true,
                clickAction: campaignNameClickAction,
                paramsToKeep: [ACCOUNT_ID, DIMENSION, START_DATE, END_DATE, DATE_PRESET],
                paramsFactory: ({ id, accountId }) => ({
                    [ACCOUNT_ID]: accountId,
                    [REPORT_ID]: creativeReportId,
                    [CAMPAIGN_ID]: id,
                }),
            },
            cellEditorFramework: FIELDS.NAME.cellEditorFramework,
            cellEditorParams: {
                validations: campaignNameValidations,
                editableTextClassName: 'with-formatted-spaces',
            },
        },
        {
            headerName: FIELDS.STATUS.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.STATUS.headerComponentParams,
            field: FIELDS.STATUS.field,
            type: [leftPinnedColumn],
            cellRendererFramework: CampaignStatusRenderer,
            cellRendererParams: FIELDS.STATUS.renderParams,
            csvValueGetter: FIELDS.STATUS.csvValueGetter,
            valueGetter: params => ({ status: get(params, 'data.status'), isActive: get(params, 'data.isActive') }),
        },
        {
            headerName: FIELDS.VIDEO_MODEL_TYPE.label,
            field: FIELDS.VIDEO_MODEL_TYPE.field,
            valueGetter: 'data.videoBudget.businessModel',
            valueSetter: params => set(params, 'data.videoBudget.businessModel', +params.newValue),
        },
        {
            headerName: FIELDS.VIDEO_BUDGET.label,
            headerValueGetter: FormattedMessageWithTooltipHeaderRenderer,
            headerComponentParams: FIELDS.VIDEO_BUDGET.headerComponentParams,
            field: FIELDS.VIDEO_BUDGET.field,
            type: [numericColumn, editableColumn],
            editable: isVideoBudgetEditable,
            cellRendererFramework: FIELDS.VIDEO_BUDGET.cellRenderer,
            cellRendererParams: FIELDS.VIDEO_BUDGET.renderParams,
            csv: FIELDS.VIDEO_BUDGET.csv,
            cellEditorFramework: FIELDS.VIDEO_BUDGET.cellEditorFramework,
            valueGetter: 'data.videoBudget.budget',
            cellEditorParams: {
                fieldPath: 'videoBudget.budget',
            },

            valueSetter: params => set(params, 'data.videoBudget.budget', +params.newValue),
        },
        {
            headerName: FIELDS.START_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.START_DATE.headerComponentParams,
            field: FIELDS.START_DATE.field,
            valueFormatter: FIELDS.START_DATE.valueFormatter,
            csvValueGetter: FIELDS.START_DATE.csvValueGetter,
        },
        {
            headerName: FIELDS.END_DATE.label,
            headerValueGetter: FormattedMessageHeaderRenderer,
            headerComponentParams: FIELDS.END_DATE.headerComponentParams,
            field: FIELDS.END_DATE.field,
            type: [editableColumn],
            editable: isVideoCampaignEditable,
            valueFormatter: FIELDS.END_DATE.valueFormatter,
            csvValueGetter: FIELDS.END_DATE.csvValueGetter,
            cellEditorFramework: FIELDS.END_DATE.cellEditorFramework,
            cellEditorParams: {
                placeholderMsgId: 'campaign.time.frame.no.end.date',
                defaultPlaceholderMsg: 'No End Date',
                inputIconMsgId: 'campaign.time.frame.end',
                defaultInputIconMsg: 'End',
            },
        },
    ].map(colDef => ({ valueChangeHandler: saveCampaign, ...colDef })),
    ...[
        {
            ...FIELDS.SPENT,
            renderParams: {
                ...FIELDS.SPENT.renderParams,
                getTotalValue: data => get(data, 'videoBudget.budget'),
            },
        },
        FIELDS.COMPLETED_VIEWS,
        FIELDS.UNIQUE_IMPRESSIONS,
        FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS,
        FIELDS.UNIQUE_COMPLETED_VIEWS,
        addMeasuredByMoatToField(FIELDS.IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.VISIBLE_IMPRESSIONS),
        addMeasuredByMoatToField(FIELDS.CPM),
        addMeasuredByMoatToField(FIELDS.VCPM),
        FIELDS.CPCV,
        addMeasuredByMoatToField(FIELDS.VIEWABILITY_RATE),
        addMeasuredByMoatToField(FIELDS.HUNDRED_PCT_VIEWABILITY_RATE),
        FIELDS.COMPLETION_RATE,
        FIELDS.CLICKS,
        FIELDS.CTR,
        FIELDS.VCTR,
        FIELDS.CPA,
        FIELDS.VIDEO_CVR,
        FIELDS.CONVERSIONS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.cellRendererParams || fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
    {
        headerName: FIELDS.ACTIONS_COLUMN.label,
        field: FIELDS.ACTIONS_COLUMN.field,
        type: [rightPinnedColumn, actionsColumnType, staticColumn],
        cellRendererParams: {
            onDuplicate: duplicateCampaign,
            onTogglePolicyState: saveCampaign,
        },
        cellRendererFramework: CampaignActionsCellRenderer,
        csv: false,
        minWidth: 100,
        maxWidth: 150,
        width: 150,
        permissions: {
            permissions: ['ADS_CONSOLE_CREATE_VIDEO_CAMPAIGN'],
        },
    },
];

export default normalizeColumnDefs(campaignReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.CAMPAIGN_VIDEO].id);
