import React from 'react';

export const DiscoverSmallLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.97915 13.0199C1.74331 13.2343 1.43683 13.3278 0.95175 13.3278H0.750255V10.7666H0.95175C1.43683 10.7666 1.73126 10.854 1.97915 11.0799C2.23879 11.3125 2.39493 11.6731 2.39493 12.044C2.39493 12.4158 2.23879 12.7874 1.97915 13.0199M1.10205 10.1105H0V13.9833H1.09625C1.67918 13.9833 2.10016 13.8451 2.46955 13.5362C2.90867 13.1707 3.16831 12.6196 3.16831 12.0497C3.16831 10.9066 2.31965 10.1105 1.10205 10.1105"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.51367 13.9833H4.26448V10.1104H3.51367V13.9833Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.10044 11.5966C5.64986 11.4289 5.51766 11.3183 5.51766 11.109C5.51766 10.8651 5.75336 10.6797 6.07694 10.6797C6.30179 10.6797 6.48678 10.7727 6.68232 10.9933L7.0752 10.4757C6.75236 10.1916 6.36618 10.0464 5.94415 10.0464C5.26308 10.0464 4.74365 10.5223 4.74365 11.1561C4.74365 11.6897 4.98545 11.9629 5.69061 12.2182C5.9846 12.3226 6.1342 12.392 6.20959 12.4387C6.35963 12.5373 6.43473 12.6769 6.43473 12.8396C6.43473 13.1533 6.18684 13.3859 5.8518 13.3859C5.49372 13.3859 5.20523 13.2056 5.03244 12.8692L4.54736 13.3392C4.89325 13.8502 5.30873 14.0766 5.88006 14.0766C6.66017 14.0766 7.20755 13.5545 7.20755 12.8049C7.20755 12.1894 6.95445 11.911 6.10044 11.5966"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.44434 12.0495C7.44434 13.1879 8.3327 14.0706 9.47595 14.0706C9.79908 14.0706 10.076 14.0067 10.4172 13.8449V12.9557C10.1172 13.258 9.85128 13.3799 9.51089 13.3799C8.75473 13.3799 8.21805 12.8282 8.21805 12.0439C8.21805 11.3004 8.77168 10.7138 9.47595 10.7138C9.83403 10.7138 10.1051 10.8423 10.4172 11.1495V10.2607C10.0877 10.0926 9.81678 10.0228 9.49364 10.0228C8.3562 10.0228 7.44434 10.9234 7.44434 12.0495"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.3701 12.7118L15.3437 10.1104H14.5234L16.1568 14.0827H16.5608L18.2237 10.1104H17.4098L16.3701 12.7118Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.5632 13.9833H20.6924V13.3276H19.3135V12.2822H20.6417V11.6262H19.3135V10.7666H20.6924V10.1103H18.5632V13.9833Z"
                fill="#212D34"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1589 11.8936H21.9395V10.7206H22.1708C22.6385 10.7206 22.8926 10.9177 22.8926 11.2946C22.8926 11.6839 22.6385 11.8936 22.1589 11.8936M23.6651 11.2538C23.6651 10.5287 23.1687 10.1103 22.3029 10.1103H21.1896V13.9833H21.9395V12.4274H22.0375L23.0766 13.9833H24L22.7882 12.3517C23.3537 12.2359 23.6651 11.8467 23.6651 11.2538"
                fill="#212D34"
            />
            <g filter="url(#filter0_i_2007_166371)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7575 12.0602C14.7575 13.1982 13.8408 14.1206 12.7099 14.1206C11.579 14.1206 10.6624 13.1982 10.6624 12.0602C10.6624 10.9223 11.579 10 12.7099 10C13.8408 10 14.7575 10.9223 14.7575 12.0602"
                    fill="url(#paint0_linear_2007_166371)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_i_2007_166371"
                    x="10.6624"
                    y="10"
                    width="4.09521"
                    height="4.12061"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.71875" dy="0.71875" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2007_166371" />
                </filter>
                <linearGradient
                    id="paint0_linear_2007_166371"
                    x1="15.2182"
                    y1="12.4541"
                    x2="12.7884"
                    y2="9.5804"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFA700" />
                    <stop offset="1" stopColor="#E75A10" />
                </linearGradient>
            </defs>
        </svg>
    );
};
