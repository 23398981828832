import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { COMPONENT_STATUS } from '../../../../../../services/constants';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import styles from './graphMessage.module.scss';

const classNameBuilder = classnames.bind(styles);

const GraphMessage = ({ status, msgIdOverride, className }) => {
    let msgId;
    switch (status) {
        case COMPONENT_STATUS.ERROR:
            msgId = 'error';
            break;
        case COMPONENT_STATUS.EMPTY:
            msgId = 'metrics.empty';
            break;
        default:
            msgId = 'empty';
    }

    return (
        <div className={classNameBuilder('container', className)}>
            <span className={styles['header']}>
                <FormattedMessage id={`app.campaigns.graph.${msgIdOverride || msgId}.header`} />
            </span>
            <span className={styles['content']}>
                <FormattedMessage id={`app.campaigns.graph.${msgIdOverride || msgId}.content`} />
            </span>
        </div>
    );
};

GraphMessage.propTypes = {
    status: PropTypes.oneOf(Object.values(COMPONENT_STATUS)),
    msgIdOverride: PropTypes.string,
    className: PropTypes.string,
};

export default GraphMessage;
