'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Icon = function Icon(props) {
    var name = props.name,
        className = props.className;

    return _react2.default.createElement('i', Object.assign({}, props, { className: 'tbl-icon tbl-' + name + ' ' + className }));
};

Icon.propTypes = {
    /** Name/Class-Name of icon, without the 'tbl' prefix */
    name: _propTypes2.default.string.isRequired,
    /** Custom class name to be added to this icon */
    className: _propTypes2.default.string
};

Icon.defaultProps = {
    className: ''
};

exports.default = Icon;