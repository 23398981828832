export const PAGINATION = { PAGE: 'page', PAGE_SIZE: 'page_size' };
export const FILTERS = {
    SEARCH: 'search_text',
    ID: 'id',
    NAME: 'name',
    FILTERS: 'filters',
    KEYWORDS: 'keywords',
    THUMBNAIL_URL: 'thumbnailUrl',
    DIMENSION: 'dimension',
    STATUS: 'status',
    STATUSES: 'statuses',
    QUERY: 'query',
    CONTENT_TYPE: 'contentType',
    AFFILIATES_ID: 'affiliates_id',
    RECOMMENDATION_TYPES: 'recommendationTypes',
    PRODUCT_DESCRIPTION: 'productDescription',
    TARGET_AUDIENCE: 'targetAudience',
    UNIQUE_SELLING_PROPOSITION: 'uniqueSellingProposition',
    LANDING_PAGE_TITLE: 'landingPageTitle',
    LANDING_PAGE_DESCRIPTION: 'landingPageDescription',
    LANDING_PAGE_CONTENT: 'landingPageContent',
    LANGUAGE: 'language',
    ORIGINAL_TEXT: 'originalText',
    IMAGE_URL: 'imageUrl',
    TEXT_PROMPT: 'textPrompt',
    SUPPLY_GROUP: 'supplyGroup',
    IS_IMAGE_VARIATIONS: 'isImageVariations',
    CAMPAIGN_ID: 'campaignId',
    CAMPAIGN_IDS: 'campaign_ids',
    RULE_IDS: 'ruleIds',
};
export const SORTING = { SORT: 'sort' };
export const VALIDATIONS = { URLS: 'urls' };

export const EXPORT_TYPES = { EXPORT_TYPE: 'export_type' };

export const PAGE_METADATA = { URL: 'url' };
export const ERROR_CODES = {
    ABORT: 20,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_ERROR: 500,
    NETWORK_UNAVAILABLE: 0,
    TOO_LARGE: 413,
    UNPROCESSABLE_ENTITY: 422,
};

export const ERROR_MESSAGES = {
    BAD_REQUEST: 'api.action.bad_request',
    FORBIDDEN: 'api.action.forbidden',
    SERVER_ERROR: 'api.action.unknown_server_error',
    NOT_ALLOWED_ON_RESOURCE: 'api.action.method_not_allowed_on_resource',
    NOT_FOUND: 'api.action.resource_not_found',
    UNAUTHORIZED: 'api.action.unauthorized',
};

export const ERROR_MESSAGES_SET = new Set([
    ERROR_MESSAGES.BAD_REQUEST,
    ERROR_MESSAGES.FORBIDDEN,
    ERROR_MESSAGES.SERVER_ERROR,
    ERROR_MESSAGES.NOT_ALLOWED_ON_RESOURCE,
    ERROR_MESSAGES.NOT_FOUND,
    ERROR_MESSAGES.UNAUTHORIZED,
]);
