import React from 'react';

export const Focus = props => (
    <svg width="25" height="24" viewBox="0 -6 13 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.5 4.6999L5.889 6.0389L4.55 6.6499L5.889 7.2609L6.5 8.5999L7.111 7.2609L8.45 6.6499L7.111 6.0389L6.5 4.6999ZM11.7 1.4499H9.6395L8.45 0.149902H4.55L3.3605 1.4499H1.3C0.585 1.4499 0 2.0349 0 2.7499V10.5499C0 11.2649 0.585 11.8499 1.3 11.8499H11.7C12.415 11.8499 13 11.2649 13 10.5499V2.7499C13 2.0349 12.415 1.4499 11.7 1.4499ZM11.7 10.5499H1.3V2.7499H3.9325L4.316 2.3274L5.122 1.4499H7.878L8.684 2.3274L9.0675 2.7499H11.7V10.5499ZM6.5 3.3999C4.706 3.3999 3.25 4.8559 3.25 6.6499C3.25 8.4439 4.706 9.8999 6.5 9.8999C8.294 9.8999 9.75 8.4439 9.75 6.6499C9.75 4.8559 8.294 3.3999 6.5 3.3999ZM6.5 8.5999C5.4275 8.5999 4.55 7.7224 4.55 6.6499C4.55 5.5774 5.4275 4.6999 6.5 4.6999C7.5725 4.6999 8.45 5.5774 8.45 6.6499C8.45 7.7224 7.5725 8.5999 6.5 8.5999Z"
            fill="#667686"
        />
    </svg>
);
