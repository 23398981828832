import { useMemo } from 'react';
import {
    FEATURE_FLAGS,
    useAccountConfig,
} from '../../../../../../taboola-common-frontend-modules/account-configurations';
import { usePermissions } from '../../../../../../taboola-common-frontend-modules/authentication';
import { BID_STRATEGIES } from '../../../../common-campaign-form';

export const useCvrLearningStatusDisplayValidator = ({ data: { cvrLearningStatus, bidStrategy } }) => {
    const { [FEATURE_FLAGS.CVR_LEARNING_STATUS_ENABLED]: isCvrLearningStatusEnabledForAccount } = useAccountConfig(
        FEATURE_FLAGS.CVR_LEARNING_STATUS_ENABLED
    );
    const isCvrLearningViewPermitted = usePermissions('CVR_LEARNING_STATUS_VIEW');

    return useMemo(() => {
        return (
            isCvrLearningStatusEnabledForAccount === 'true' &&
            isCvrLearningViewPermitted &&
            bidStrategy === BID_STRATEGIES.TARGET_CPA &&
            cvrLearningStatus
        );
    }, [bidStrategy, cvrLearningStatus, isCvrLearningStatusEnabledForAccount, isCvrLearningViewPermitted]);
};
