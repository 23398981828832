'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'arrowPath__taboola-svg-icon___Yr5b7',
    'arrow': 'arrowPath__arrow___t7bj3',
    'arrow-shape': 'arrowPath__arrow-shape___1NmWP',
    'arrow-stroke': 'arrowPath__arrow-stroke___1yQln'
};


var ArrowPath = function ArrowPath(props) {
    var optClassName = props.optClassName;

    return _react2.default.createElement(
        'svg',
        { className: styles['arrow'] + ' ' + (optClassName || '') },
        _react2.default.createElement('path', { className: styles['arrow-shape'], d: 'M0,10 20,10 10,0z' }),
        _react2.default.createElement('path', { className: styles['arrow-stroke'], d: 'M0,10 10,0 20,10' })
    );
};

ArrowPath.propTypes = {
    /** Define component optional styled class name */
    optClassName: _propTypes2.default.string
};

exports.default = ArrowPath;