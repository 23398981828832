import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { REPORT_TYPE } from '../../reportsBaseConfig';

const getIndicationMessageDetails = {
    [REPORT_TYPE.CAMPAIGN]: {
        id: 'app.campaign.indication.heavyAccountReportFreshness.message',
        defaultMessage:
            'Campaign setup and performance data are not available instantly, some recent campaign changes can be shown in a delay of up to 5 minutes.',
    },
    [REPORT_TYPE.CREATIVE]: {
        id: 'app.campaign.indication.heavyAccountReportFreshness.ads.message',
        defaultMessage:
            'Ad setup and performance data are not available instantly, some recent ad changes can be shown in a delay of up to 5 minutes.',
    },
};

const getHeavyAccountReportFreshnessIndication = selectedReport => {
    const indicationMessageDetails = getIndicationMessageDetails[selectedReport] ?? {};
    return {
        staticIndicationType: STATIC_INDICATION_TYPES.HEAVY_ACCOUNT_REPORT_FRESHNESS,
        type: INDICATION_TYPES.WARNING,
        highlight: <FormattedMessage id="note.highlight" defaultMessage="Note:" />,
        message: (
            <FormattedMessage
                id={indicationMessageDetails.id}
                defaultMessage={indicationMessageDetails.defaultMessage}
            />
        ),
    };
};

export default getHeavyAccountReportFreshnessIndication;
