'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TaboolaImage = exports.STATUS = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ImagePlaceholder = require('./ImagePlaceholder');

var _ImagePlaceholder2 = _interopRequireDefault(_ImagePlaceholder);

var _ImageContentLoader = require('./ImageContentLoader');

var _ImageContentLoader2 = _interopRequireDefault(_ImageContentLoader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var styles = {
    'taboola-img': 'taboolaImage__taboola-img___2p3M9'
};
var STATUS = exports.STATUS = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    READY: 'READY'
};

var TaboolaImage = exports.TaboolaImage = function (_Component) {
    _inherits(TaboolaImage, _Component);

    function TaboolaImage() {
        var _ref;

        var _temp, _this, _ret;

        _classCallCheck(this, TaboolaImage);

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = TaboolaImage.__proto__ || Object.getPrototypeOf(TaboolaImage)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
            status: STATUS.LOADING
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(TaboolaImage, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            return this.validateImage();
        }
    }, {
        key: 'componentDidUpdate',
        value: function componentDidUpdate(prevProps) {
            var src = this.props.src;

            if (src !== prevProps.src) {
                this.validateImage();
            }
        }
    }, {
        key: 'componentWillUnmount',
        value: function componentWillUnmount() {
            clearTimeout(this.validationTimeoutId);
            this.validationTimeoutId = null;
        }
    }, {
        key: 'validateImage',
        value: function validateImage() {
            var _this2 = this;

            var _props = this.props,
                src = _props.src,
                onError = _props.onError;

            if (!src) {
                this.setState({ status: STATUS.ERROR });
                return;
            }
            this.validationTimeoutId = setTimeout(function () {
                var img = new Image();
                img.onload = function () {
                    if (_this2.validationTimeoutId) {
                        _this2.setState({ status: STATUS.READY });
                    }
                };
                img.onerror = function (e) {
                    if (_this2.validationTimeoutId) {
                        _this2.setState({ status: STATUS.ERROR });
                    }
                    onError(e);
                };
                img.src = src;
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _props2 = this.props,
                className = _props2.className,
                loadingComponent = _props2.loadingComponent,
                errorComponent = _props2.errorComponent,
                src = _props2.src,
                alt = _props2.alt,
                forwardedRef = _props2.forwardedRef,
                rest = _objectWithoutProperties(_props2, ['className', 'loadingComponent', 'errorComponent', 'src', 'alt', 'forwardedRef']);

            var status = this.state.status;

            var imgClassName = styles['taboola-img'] + ' ' + className;
            switch (status) {
                case STATUS.LOADING:
                    {
                        return loadingComponent;
                    }
                case STATUS.ERROR:
                    {
                        return errorComponent;
                    }
                case STATUS.READY:
                    {
                        return _react2.default.createElement('img', Object.assign({
                            className: imgClassName,
                            src: src,
                            alt: alt || src,
                            ref: forwardedRef
                        }, rest));
                    }
                default:
                    {
                        return errorComponent;
                    }
            }
        }
    }]);

    return TaboolaImage;
}(_react.Component);

TaboolaImage.propTypes = {
    /** Class added to img element */
    className: _propTypes2.default.string,
    /** The image URL */
    src: _propTypes2.default.string,
    /** alternative text description of the image */
    alt: _propTypes2.default.string,
    /** load event handler */
    onLoad: _propTypes2.default.func,
    /** error event handler */
    onError: _propTypes2.default.func,
    /** To be shown on error state when image is not loaded */
    errorComponent: _propTypes2.default.node,
    /** To be shown when Loading the image */
    loadingComponent: _propTypes2.default.node,
    forwardedRef: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.object])
};

TaboolaImage.defaultProps = {
    className: '',
    onLoad: function onLoad() {},
    onError: function onError() {},
    errorComponent: _react2.default.createElement(_ImagePlaceholder2.default, null),
    loadingComponent: _react2.default.createElement(_ImageContentLoader2.default, null),
    forwardedRef: function forwardedRef() {}
};

exports.default = (0, _react.forwardRef)(function (props, ref) {
    return _react2.default.createElement(TaboolaImage, Object.assign({}, props, { forwardedRef: ref }));
});