import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { HOURS_OF_DAY, RUNTIME_TYPES, TARGETING_TYPES } from '../../modules/common-campaign-form/config';

const isAllDay = (fromHour, untilHour) => fromHour === 0 && untilHour === 24;

// This transformation is needed for users who has UTC date change point during working hours, actually all UTC-* time zones
// When user selected TODAY value we shift it to the time of data transformation with request sending time buffer to avoid issue in case of sending request in on one UTC date
// and validating on BS side on another
const REQUEST_SENDING_BUFFER_MIN = 1;

const shiftToAccountTimezone = date => {
    return !moment.isMoment(date) || date.isUTC() ? moment(date) : moment(date).add(date.utcOffset(), 'minutes');
};

const transformDateToGW = date => {
    if (!date) {
        return date;
    }
    const resolvedDate = moment().isSame(date, 'day')
        ? moment().add(REQUEST_SENDING_BUFFER_MIN, 'minutes')
        : shiftToAccountTimezone(date);
    const dateView = resolvedDate.valueOf();

    return dateView;
};

const transformCampaignScheduleToGW = ({ campaignSchedule, startDate, endDate, ...restCampaign }) => {
    const campaign = {
        ...restCampaign,
        startDate: transformDateToGW(startDate),
        endDate: transformDateToGW(endDate),
    };

    if (!campaignSchedule) {
        return campaign;
    }

    const { rules, mode, isMultipleSlicesPerDay } = campaignSchedule;

    if (isMultipleSlicesPerDay) {
        return {
            ...campaign,
            campaignSchedule,
        };
    }

    if (!mode) {
        return campaign;
    }

    const transformedRules =
        mode === RUNTIME_TYPES.ALWAYS
            ? []
            : rules.reduce((all, rule) => {
                  const { type, fromHour, untilHour } = rule;

                  if (type === TARGETING_TYPES.EXCLUDE) {
                      all.push({ ...rule, fromHour: HOURS_OF_DAY.ZERO, untilHour: HOURS_OF_DAY.TWENTY_FOUR });
                  } else if (type === TARGETING_TYPES.INCLUDE && !isAllDay(fromHour, untilHour)) {
                      all.push(rule);
                  }

                  return all;
              }, []);

    const transformedMode =
        transformedRules.length === 0 && mode === RUNTIME_TYPES.CUSTOM ? RUNTIME_TYPES.ALWAYS : mode;

    return {
        ...campaign,
        campaignSchedule: {
            ...campaignSchedule,
            mode: transformedMode,
            rules: transformedRules,
        },
    };
};

const transformCampaignScheduleFromGW = campaign => {
    const { campaignSchedule = {}, startDate, endDate } = campaign;
    const transformedCampaign = { ...campaign };

    if (!isNil(startDate)) {
        transformedCampaign.startDate = moment(startDate);
    }
    if (!isNil(endDate)) {
        transformedCampaign.endDate = moment(endDate);
    }

    if (!campaignSchedule) {
        return transformedCampaign;
    }
    const { rules = [], mode } = campaignSchedule;

    const invalidConfig = mode === RUNTIME_TYPES.CUSTOM && rules && rules.length === 0;

    const sortedRows = rules
        ? rules.sort((a, b) => moment(a.day, 'dddd')?.weekday() - moment(b.day, 'dddd')?.weekday())
        : [];

    transformedCampaign.campaignSchedule = {
        ...campaignSchedule,
        rules: sortedRows,
        isSupported: !invalidConfig,
    };

    return transformedCampaign;
};

export { transformCampaignScheduleToGW, transformCampaignScheduleFromGW };
