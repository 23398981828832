import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './indicatorContentLoader.module.scss';

const IndicatorContentLoader = () => (
    <ContentLoader className={styles['content']} height={40} width={200} speed={2}>
        <rect x="7" y="11.45" rx="10" ry="10" width="187.87" height="16.4" />
    </ContentLoader>
);

export default IndicatorContentLoader;
