import { CommonCollapsibleCard } from 'components';
import SectionHeader from 'modules/campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { LoadingBar } from '../../../campaigns/components';
import styles from './ConnectShopify.module.scss';

export const ConnectShopify = ({ showLoading }) => {
    return (
        <CommonCollapsibleCard
            id="Shopify_Connect"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage id="tracking.setup.connect.label" defaultMessage="Connect to Shopify" />
                    }
                />
            }
        >
            <div className={styles['text']}>
                <FormattedMessage
                    id="tracking.setup.shopify.connect.first.step"
                    defaultMessage="Click the <b>Connect Shopify</b> button below to finish the setup and create the required conversions on Taboola Ads."
                />
            </div>
            {showLoading && (
                <LoadingBar
                    durationInSeconds={1}
                    loadingMessage={
                        <FormattedMessage
                            id="tracking.setup.shopify.connect.loading"
                            defaultMessage="We’re creating your new conversions for Shopify"
                        />
                    }
                />
            )}
        </CommonCollapsibleCard>
    );
};
