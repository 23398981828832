import React from 'react';
import classnames from 'classnames/bind';
import { Button } from 'tuui';
import { Input } from 'taboola-ultimate-ui';
import styles from './creativesStudioTextInput.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CreativesStudioTextInput = ({
    input,
    setInput,
    onGenerateClick,
    placeholder,
    isAllInputDisabled,
    isGenerateDisabled,
    buttonMessage,
    ...rest
}) => (
    <div className={styles['container']}>
        <div className={styles['input-container']}>
            <Input
                inputClass={classNameBuilder('background-prompt-input', { disabled: isAllInputDisabled })}
                onChange={e => setInput(e.target.value)}
                placeholder={placeholder}
                value={input}
                size={Input.SIZE.LARGE}
                disabled={isAllInputDisabled}
            />
            <Button
                variant={Button.variant.text}
                disabled={isAllInputDisabled || isGenerateDisabled}
                onClick={onGenerateClick}
                className={classNameBuilder('generate-button', { disabled: isAllInputDisabled || isGenerateDisabled })}
                {...rest}
            >
                {buttonMessage}
            </Button>
        </div>
    </div>
);
