import {
    RESUBMIT_CREATIVE_SUCCESS,
    BULK_RESUBMIT_CREATIVE_SUCCESS,
    BULK_RESUBMIT_CREATIVE_ERROR,
    REQUEST_RESUBMIT_CREATIVE,
} from './actionTypes';

export const requestResubmitCreative = () => ({
    type: REQUEST_RESUBMIT_CREATIVE,
});

export const resubmitCreativeSuccess = creative => ({
    type: RESUBMIT_CREATIVE_SUCCESS,
    payload: creative,
});

export const bulkResubmitCreativeSuccess = creatives => ({
    type: BULK_RESUBMIT_CREATIVE_SUCCESS,
    payload: creatives,
});

export const bulkResubmitCreativeError = () => ({
    type: BULK_RESUBMIT_CREATIVE_ERROR,
});
