import React from 'react';
import TooltipSection from 'modules/campaigns/modules/common-campaign-form/components/TooltipSection';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const EvergreenFieldTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="pmpDeal.evergreen.tooltip"
            defaultMessage="Check Evergreen deal to set your campaign as evergreen (always-on deal). Evergreen is a PMP campaign with a deal ID which considered 'open exchange' in the auction."
        />
    </TooltipSection>
);
