import { updateCreativesSuccess, updateCreativesError, requestUpdateCreatives } from '../actions';
import updateCreativesBase from './updateCreativesBase';

const updateCreatives =
    (accountId, creativeIds, changes, { suppressSuccessIndication, suppressErrorIndication } = {}) =>
    async dispatch => {
        dispatch(requestUpdateCreatives());

        const { results, error } = await dispatch(
            updateCreativesBase(accountId, creativeIds, changes, { suppressSuccessIndication, suppressErrorIndication })
        );

        if (results) {
            dispatch(updateCreativesSuccess(results));
            return { results };
        } else if (error) {
            dispatch(updateCreativesError(creativeIds, error));
            return { error };
        }
    };

export default updateCreatives;
