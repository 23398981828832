import { useCallback } from 'react';
import { DeletableItem } from 'taboola-ultimate-ui';
import { ENTITY_TYPE } from 'modules/performance-rules/utils/performanceRulesConsts';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from 'modules/tracking/components/FunnelConversions/funnelConversionsList.module.scss';
import { RuleType } from './RuleType';

export const PerformanceRuleItem = ({ item, deleteItem }) => {
    const { formatMessage } = useIntl();
    const { ruleName, entityType } = item;
    const isDeleteAble = entityType === ENTITY_TYPE.CAMPAIGN;
    const handleOnClick = useCallback(() => deleteItem(item), [item, deleteItem]);

    return (
        <DeletableItem
            buttonProps={{
                'aria-label': `${formatMessage({ id: 'app.modal.removeVariation.button.remove' })} - ${ruleName}`,
            }}
            onDelete={handleOnClick}
            isItemDeletable={isDeleteAble}
        >
            <div className={styles['rule-container']}>
                <span className={styles['label']}>{ruleName}</span>
                <RuleType entityType={entityType} />
            </div>
        </DeletableItem>
    );
};
