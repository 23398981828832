import React from 'react';
import { AccountBlockedSites } from '../AccountBlockedSites/AccountBlockedSites';
import { CampaignSmartBidBlockedSites } from '../CampaignSmartBidBlockedSites/CampaignSmartBidBlockedSites';
import { IncludedExcludedSites } from '../IncludedExcludedSites/IncludedExcludedSites';

export const SiteTargetingList = () => (
    <>
        <IncludedExcludedSites />
        <CampaignSmartBidBlockedSites />
        <AccountBlockedSites />
    </>
);
