import React from 'react';
import { FormField, Input } from 'taboola-ultimate-ui';
import useEventValueHandler from 'hooks/useEventValueHandler';
import { withIndication } from 'modules/errors/components/withIndication';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormValidatedValue, validationFunctions } from 'modules/taboola-common-frontend-modules/validations';
import { FORM_MODES } from '../../../../config/formModes';
import { GTM_EVENTS } from '../../../taboola-common-frontend-modules/gtmTracker';
import commonStyles from '../../../campaigns/modules/common-campaign-form/components/commonEditor.module.scss';

const InputWithIndication = withIndication(Input);
const validations = [
    {
        validationFn: validationFunctions.isNotEmptyString,
        messageId: 'validations.error.paymentMethod.street.empty',
        defaultMessage: 'Enter a street address',
    },
    {
        validationFn: validationFunctions.isLength,
        options: { max: 110 },
        messageId: 'validations.error.paymentMethod.street.moreThen',
        defaultMessage: 'Street address can not exceed 110 characters',
    },
];

export const PaymentMethodStreet = () => {
    const {
        value: street,
        onChange,
        indicationData,
        scrollRef,
        mode,
    } = useFormValidatedValue({
        field: 'billingAddress.street',
        validations,
    });
    const changeHandler = useEventValueHandler(onChange);

    const { formatMessage } = useIntl();
    const placeholder = formatMessage({
        id: 'paymentMethod.creator.CARD.street.placeholder',
        defaultMessage: 'Enter Address...',
    });

    return (
        <FormField
            inputId="street"
            ref={scrollRef}
            containerClass={commonStyles['input']}
            label={<FormattedMessage id="paymentMethod.creator.CARD.street" defaultMessage="Street Address" />}
        >
            <InputWithIndication
                id="street"
                value={street ?? ''}
                onChange={changeHandler}
                placeholder={placeholder}
                size={Input.SIZE.LARGE}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="PaymentMethodStreet"
                disabled={mode !== FORM_MODES.CREATE}
                {...indicationData}
            />
        </FormField>
    );
};
