import { useMemo } from 'react';

export const convertSitesToDropdownFormat = sites =>
    sites.map((site = {}) => ({
        ...site,
        value: site.accountName,
        label: site.accountDescription,
        id: site.accountName,
    }));

export const useConvertSitesToDropdownFormat = sites => {
    const sitesAsOptions = useMemo(() => convertSitesToDropdownFormat(sites), [sites]);

    return sitesAsOptions;
};
