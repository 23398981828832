import React from 'react';
import PropTypes from 'prop-types';
import { InputTypes } from 'taboola-ultimate-ui';
import { withGridCellFormDataProvider } from 'modules/taboola-common-frontend-modules/formData/withGridCellFormDataProvider';
import { FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import CurrencyIcon from '../../../common-campaign-form/components/CurrencyIcon/CurrencyIcon';
import BaseCellEditor from './BaseCellEditor';
import InputEditField from './InputEditField';
import { useSingleFieldCellEditorData } from './useSingleFieldCellEditorData';

const parseInputValue = (inputChangeValue, currentValue) => {
    if (inputChangeValue === '') {
        return '';
    }

    const newValue = parseFloat(inputChangeValue);
    return Number.isNaN(newValue) ? currentValue : newValue;
};

const SimpleCurrencyCellEditor = props => {
    const {
        context: { currency },
        validationDependencies,
        onCancel,
        colDef,
    } = props;
    const {
        hasError,
        submitHandler,
        triggerValidationEvent,
        isValidationsRunning,
        isDirty,
        failedValidationData,
        ...fieldData
    } = useSingleFieldCellEditorData({
        ...props,
        validationDependencies,
        parseInputValue,
    });

    return (
        <BaseCellEditor onCancel={onCancel} onSubmit={submitHandler} disableSubmit={hasError} colDef={colDef}>
            <FormattedNumber value={0} minimumFractionDigits={2}>
                {placeholder => (
                    <InputEditField
                        placeholder={placeholder}
                        type={InputTypes.NUMBER}
                        icon={<CurrencyIcon currency={currency} />}
                        inputPadding={45}
                        step="0.01"
                        {...fieldData}
                        {...failedValidationData}
                    />
                )}
            </FormattedNumber>
        </BaseCellEditor>
    );
};

SimpleCurrencyCellEditor.propTypes = {
    /** The grid's context object, passed in by the grid */
    context: PropTypes.object,
    data: PropTypes.object,
    validationContextConfig: PropTypes.object,
    validationContextExtension: PropTypes.object,
};

export const SimpleCurrencyCellEditorWithProvider = withGridCellFormDataProvider(SimpleCurrencyCellEditor);
export default SimpleCurrencyCellEditor;
