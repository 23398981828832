import React from 'react';
import classnames from 'classnames/bind';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { BaseCard } from '../BaseCard/BaseCard';
import { LOGO_TYPE, BRAND_TYPE, BrandMark } from '../BrandMark/BrandMark';
import CreditNumberWithExp from './CreditNumberWithExp';
import styles from './creditCard.module.scss';

const classNameBuilder = classnames.bind(styles);
const PAYPAL_HEADER = 'paypal';
const CREDIT_CARD_HEADER = 'creditCard';
export const CreditCard = ({
    className,
    cardHolderName,
    lastDigits,
    expMonth,
    expYear,
    brand,
    lastBillingDate,
    ...rest
}) => {
    const { formatMessage } = useIntl();
    const creditCardHeader = brand === BRAND_TYPE.PAYPAL ? PAYPAL_HEADER : CREDIT_CARD_HEADER;

    return (
        <BaseCard
            className={classNameBuilder('credit-card-background', className)}
            title={formatMessage({ id: `billingAndPayments.creditCard.${creditCardHeader}.title` })}
            icon={<BrandMark brand={brand} type={LOGO_TYPE.LOGO} />}
            detailsFirstLine={cardHolderName}
            detailsSecondLine={
                <CreditNumberWithExp expirationMonth={expMonth} fourLastDigits={lastDigits} expirationYear={expYear} />
            }
            dateTitle={formatMessage({
                id: 'billingAndPayments.baseCard.lastBillingDate.label',
                defaultMessage: 'Last billing Date',
            })}
            date={lastBillingDate}
            showCardActions
            {...rest}
        />
    );
};
