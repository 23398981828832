import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import {
    DEFAULT_CAMPAIGN_ID,
    DEFAULT_CREATIVE_ID,
    FIELD_TYPES,
} from 'modules/campaigns/components/ContentEditableText/services';
import { CustomValidationError } from 'modules/errors/CustomValidationError';
import { whitelistedEmojiRegex } from 'services/utils/regExUtils';
import { isEmojiInString } from 'services/utils/stringUtils';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations';
import { getAutoReviewValidationResults, getRejectReasonFromResults } from './autoReviewValidationUtils';

const maxCreativeTitleLength = 100;
const TITLE_WARNING_THRESHOLD = 0.59;

const DKI_VALIDATION_ERROR = 'TITLE_INCORRECT_PLACEHOLDER_FORMAT';

const notEmptyValidation = {
    validationFn: validationFunctions.isNotEmptyString,
    messageId: 'validations.error.field.title.empty',
    defaultMessage: 'Title can not be empty',
};
const lengthValidation = {
    validationFn: validationFunctions.isLength,
    options: { min: 0, max: maxCreativeTitleLength },
    messageId: 'validations.error.field.title.length',
};
const lengthWarningValidation = {
    validationFn: validationFunctions.isLength,
    options: { min: 0, max: 59 },
    messageId: 'validations.warning.field.title.length',
    defaultMessage: 'Headlines over 60 characters may be truncated on some publisher sites',
    severity: INDICATION_TYPES.WARNING,
};
const emojiValidation = {
    validationFn: title => !isEmojiInString(title, whitelistedEmojiRegex),
    messageId: 'validations.error.field.title.emoji',
};

const getContentTitleValidationError = results => {
    const reason = getRejectReasonFromResults(results);

    if (!reason) {
        return true;
    }

    throw new CustomValidationError({
        messageCode: `app.campaigns.campaign.status.reject.reason.${reason}`,
        message: 'This title is not compliant with our policy, please replace it',
    });
};
const titleContentValidation = {
    validationFn: async (
        value,
        _,
        {
            isContentValidationEnabled,
            isDKIValidationEnabled,
            accountId,
            campaignId = DEFAULT_CAMPAIGN_ID,
            creativeId = DEFAULT_CREATIVE_ID,
        }
    ) => {
        if (!isContentValidationEnabled || validationFunctions.isEmpty(value)) {
            return true;
        }

        const onError = results => {
            const reason = getRejectReasonFromResults(results);

            if (isDKIValidationEnabled && reason === DKI_VALIDATION_ERROR) {
                throw new CustomValidationError({
                    messageCode: `creative.creator.aiTitleSuggestions.invalid.dki`,
                    message:
                        'Invalid dynamic keyword insertion detected. Click the "Fix DKI" button to get correct suggestions.',
                });
            }

            return getContentTitleValidationError(results);
        };

        return getAutoReviewValidationResults(
            {
                accountId,
                campaignId,
                fieldToValidate: FIELD_TYPES.TITLE,
                item: {
                    title: value,
                    id: creativeId,
                },
            },
            onError
        );
    },
    messageId: 'validations.error.field.content.title.not.compliant.with.policy',
    defaultMessage: 'This title is not compliant with our policy, please replace it',
};

const titleSuggestionPolicyValidation = {
    validationFn: (__, _, { previousInvalidTitle, isSuggestionFetchingError }) =>
        previousInvalidTitle === null || Boolean(isSuggestionFetchingError),
    messageId: 'creative.creator.aiTitleSuggestions.error.policy.violation',
    defaultMessage:
        'The headlines request may not follow our <a>Content Policy</a>. Please revise the prompt and try again.',
    isExcludeFromSubmitOnlyValidating: true,
};

const titleSuggestionFetchingValidation = {
    validationFn: (__, _, { isSuggestionFetchingError }) => !isSuggestionFetchingError,
    messageId: 'creative.studio.image.variations.banner.error',
    defaultMessage: 'We were unable to process the request. Please try again.',
    isExcludeFromSubmitOnlyValidating: true,
};

const bulkTitleContentValidation = {
    validationFn: async (value, _, { isContentValidationEnabled, validatedTitles }) => {
        if (!isContentValidationEnabled || validationFunctions.isEmpty(value)) {
            return true;
        }

        return getContentTitleValidationError([validatedTitles[value]]);
    },
    messageId: 'validations.error.field.content.title.not.compliant.with.policy',
    defaultMessage: 'This title is not compliant with our policy, please replace it',
};

const baseTitleValidations = [lengthValidation, lengthWarningValidation, emojiValidation];

const creativeTitleBulkEditValidations = [...baseTitleValidations, notEmptyValidation, titleContentValidation];

const creativeTitleValidations = [
    ...baseTitleValidations,
    notEmptyValidation,
    titleContentValidation,
    titleSuggestionPolicyValidation,
    titleSuggestionFetchingValidation,
];

const aiVariationsCreativeTitleValidations = [
    ...baseTitleValidations,
    titleContentValidation,
    titleSuggestionPolicyValidation,
    titleSuggestionFetchingValidation,
];

const csvCreativeTitleValidations = [...baseTitleValidations, notEmptyValidation, bulkTitleContentValidation];

export {
    maxCreativeTitleLength,
    TITLE_WARNING_THRESHOLD,
    notEmptyValidation as creativeTitleNotEmptyValidation,
    creativeTitleValidations,
    csvCreativeTitleValidations,
    aiVariationsCreativeTitleValidations,
    creativeTitleBulkEditValidations,
};
