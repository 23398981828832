'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactMomentProptypes = require('react-moment-proptypes');

var _reactMomentProptypes2 = _interopRequireDefault(_reactMomentProptypes);

var _momentTimezone = require('moment-timezone');

var _momentTimezone2 = _interopRequireDefault(_momentTimezone);

require('react-dates/initialize');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _TimeFramePicker = require('../TimeFramePicker/TimeFramePicker');

var _TimeFramePicker2 = _interopRequireDefault(_TimeFramePicker);

var _RangePickerDay = require('./RangePickerDay');

var _RangePickerDay2 = _interopRequireDefault(_RangePickerDay);

var _constants = require('./constants');

var _DashboardRangePickerHeader = require('./DashboardRangePickerHeader');

var _DashboardRangePickerHeader2 = _interopRequireDefault(_DashboardRangePickerHeader);

var _DashboardRangePickerContent = require('./DashboardRangePickerContent');

var _DashboardRangePickerContent2 = _interopRequireDefault(_DashboardRangePickerContent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'dashboardRangePicker__taboola-svg-icon___33kkI',
    'footer': 'dashboardRangePicker__footer___1rSnP',
    'navigation-items-panel': 'dashboardRangePicker__navigation-items-panel___2nsYv',
    'buttons-panel': 'dashboardRangePicker__buttons-panel___2ZGil',
    'buttons-panel-item': 'dashboardRangePicker__buttons-panel-item___3OkUl',
    'dashboard-range-picker': 'dashboardRangePicker__dashboard-range-picker___3jjRl'
};


var classNameBuilder = _bind2.default.bind(styles);

var PICKER_TOGGLE_DEBOUNCE_TIME = 300;

var DashboardRangePicker = function DashboardRangePicker(_ref) {
    var startDate = _ref.startDate,
        endDate = _ref.endDate,
        preset = _ref.preset,
        className = _ref.className,
        customPresetText = _ref.customPresetText,
        defaultPreset = _ref.defaultPreset,
        onRangeConfirmed = _ref.onRangeConfirmed,
        onClose = _ref.onClose,
        presetConfigs = _ref.presetConfigs,
        restProps = _objectWithoutProperties(_ref, ['startDate', 'endDate', 'preset', 'className', 'customPresetText', 'defaultPreset', 'onRangeConfirmed', 'onClose', 'presetConfigs']);

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        pickerOpen = _useState2[0],
        setPickerOpen = _useState2[1];

    var pickerJustToggled = (0, _react.useRef)(false);

    var debouncePickerToggle = function debouncePickerToggle() {
        if (pickerJustToggled.current) {
            return true;
        }

        pickerJustToggled.current = true;
        setTimeout(function () {
            pickerJustToggled.current = false;
        }, PICKER_TOGGLE_DEBOUNCE_TIME);

        return false;
    };

    var handleOnClose = function handleOnClose() {
        if (debouncePickerToggle()) {
            return;
        }

        if (onClose) {
            onClose();
        }

        setPickerOpen(false);
    };

    var togglePicker = function togglePicker() {
        if (debouncePickerToggle()) {
            return;
        }

        setPickerOpen(!pickerOpen);
    };

    var handleOnDatesConfirmed = function handleOnDatesConfirmed(newStart, newEnd, newPreset) {
        onRangeConfirmed(newStart, newEnd, newPreset);

        handleOnClose();
    };

    var handleOnPresetChange = function handleOnPresetChange(newPreset) {
        var presetConfig = presetConfigs[newPreset];

        if (!presetConfig) {
            return;
        }

        var newStartDate = presetConfig.startDate,
            newEndDate = presetConfig.endDate;


        handleOnDatesConfirmed(newStartDate, newEndDate, newPreset);
    };

    // sanitize initial values
    (0, _react.useEffect)(function () {
        var presetConfig = presetConfigs[preset];
        var defaultPresetConfig = presetConfigs[defaultPreset];
        var invalidInitialProps = !presetConfig && !(startDate || endDate);

        // if no valid props were provided and a default preset was provided, set the user to the default preset
        if (defaultPresetConfig && invalidInitialProps) {
            handleOnPresetChange(defaultPreset);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return _react2.default.createElement(
        'div',
        { className: classNameBuilder('dashboard-range-picker', className) },
        _react2.default.createElement(_DashboardRangePickerHeader2.default, {
            startDate: startDate,
            endDate: endDate,
            pickerOpen: pickerOpen,
            togglePicker: togglePicker,
            preset: presetConfigs[preset] && presetConfigs[preset].label || customPresetText
        }),
        _react2.default.createElement(_DashboardRangePickerContent2.default, Object.assign({
            startDate: startDate,
            endDate: endDate,
            preset: preset,
            presetConfigs: presetConfigs,
            pickerOpen: pickerOpen,
            onClose: handleOnClose,
            onDatesConfirmed: handleOnDatesConfirmed,
            onPresetChange: handleOnPresetChange
        }, restProps))
    );
};

DashboardRangePicker.propTypes = {
    // calendar presentation and interaction related props
    /** Fires when date range was confirmed */
    onRangeConfirmed: _propTypes2.default.func,
    /** controlled input value for selected preset (determines startDate and endDate) */
    preset: _propTypes2.default.string,
    /** if no preset and both dates aren't provided, default the date range to this preset */
    defaultPreset: _propTypes2.default.oneOf(_constants.PRESETS_LIST),
    /** Controlled input value for start date */
    startDate: _reactMomentProptypes2.default.momentObj,
    /** Controlled input value for end date */
    endDate: _reactMomentProptypes2.default.momentObj,
    /** Enables the selection of out-of-range dates */
    enableOutsideDays: _propTypes2.default.bool,
    /** Number of months to present */
    numberOfMonths: _propTypes2.default.number,
    /** Sets the initial visible month */
    initialVisibleMonth: _propTypes2.default.func,
    /** Fires on click outside the picker */
    onOutsideClick: _propTypes2.default.func,
    /** Controls the picker border */
    noBorder: _propTypes2.default.bool,
    /** Displays a keyboard shortcut panel */
    hideKeyboardShortcutsPanel: _propTypes2.default.bool,
    /** Determines the text on the confirm button */
    confirmButtonText: _propTypes2.default.string,
    /** Determines the text on the cancel button */
    cancelButtonText: _propTypes2.default.string,

    // navigation related props
    /** Fires when the previous month button is clicked */
    onPrevMonthClick: _propTypes2.default.func,
    /** Fires when the next month button is clicked */
    onNextMonthClick: _propTypes2.default.func,
    /** Fires when the calendar closes */
    onClose: _propTypes2.default.func,

    // day presentation and interaction related props
    /** Change the visibility of a day */
    renderDayContents: _propTypes2.default.func,
    /** Determines which dates are out of the range of selection */
    isOutsideRange: _propTypes2.default.func,
    /** Determines which dates cannot be selected */
    isDayBlocked: _propTypes2.default.func,
    /** Determines which dates highlighted */
    isDayHighlighted: _propTypes2.default.func,
    /** Changes the day size */
    daySize: _propTypes2.default.number,

    // internationalization props
    /** Intl - formats the display of the selected date */
    displayFormat: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    /** Intl - format of month (MomentJS formats) */
    monthFormat: _propTypes2.default.string,
    /** Additional class for container div */
    className: _propTypes2.default.string,
    /** Determines custom period label */
    customPresetText: _propTypes2.default.string,
    /** Custom TimeFramePicker component rendered in the footer (for things like validations). Important props being passed in are startDate, endDate, onStartDateChange, and onEndDateChange */
    customTimeFramePicker: _propTypes2.default.element,
    extendPresetProps: _propTypes2.default.func,
    extendConfirmProps: _propTypes2.default.func,
    presetConfigs: _propTypes2.default.objectOf(_propTypes2.default.shape({
        label: _propTypes2.default.string,
        startDate: _reactMomentProptypes2.default.momentObj,
        endDate: _reactMomentProptypes2.default.momentObj
    })).isRequired
};

DashboardRangePicker.defaultProps = {
    daySize: 38,
    hideKeyboardShortcutsPanel: true,
    startDate: null,
    endDate: null,
    defaultPreset: null,
    onRangeConfirmed: function onRangeConfirmed() {},
    renderDayContents: function renderDayContents(day) {
        return (0, _RangePickerDay2.default)({ day: day });
    },
    confirmButtonText: 'SELECT',
    cancelButtonText: 'CANCEL',
    isOutsideRange: function isOutsideRange(day) {
        return (0, _momentTimezone2.default)().isBefore(day, 'day');
    },
    numberOfMonths: 2,
    customPresetText: 'Custom',
    customTimeFramePicker: _react2.default.createElement(_TimeFramePicker2.default, null)
};

exports.default = DashboardRangePicker;