import { FORM_MODES } from 'config/formModes';
import { ENTITY, EDITOR_ROUTE_PATH } from 'modules/campaigns/config/routes';
import { CAMPAIGN_DIMENSION } from '../../../../services/campaignDimension';
import { generateFormPath } from './generateFormPath';

export const generateCampaignCreatorPath = ({ entity }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity,
        mode: FORM_MODES.CREATE,
    });

export const generateBulkCampaignCreatorPath = ({ entity }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity,
        mode: FORM_MODES.BULK_CREATE,
    });

export const generateCampaignsGroupCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.CAMPAIGNS_GROUP,
        mode: FORM_MODES.CREATE,
    });

export const generateCreativeCreatorPath = ({ entity }) =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity,
        mode: FORM_MODES.CREATE,
    });

export const generateVideoCreativeCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.CREATIVE_VIDEO,
        mode: FORM_MODES.CREATE,
        dimension: CAMPAIGN_DIMENSION.VIDEO,
    });

export const generateVideoPMPCreatorPath = () =>
    generateFormPath(EDITOR_ROUTE_PATH, {
        entity: ENTITY.VIDEO_PMP_DEAL,
        mode: FORM_MODES.CREATE,
        dimension: CAMPAIGN_DIMENSION.VIDEO,
    });
