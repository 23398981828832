import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const CollapsibleCardContext = createContext({});

export const CollapsibleCardProvider = ({ children, setCollapsed }) => {
    const value = useMemo(() => ({ setCollapsed }), [setCollapsed]);
    return <CollapsibleCardContext.Provider value={value}>{children}</CollapsibleCardContext.Provider>;
};

export const CollapsibleCardContextConsumer = CollapsibleCardContext.Consumer;

export const useCollapsibleCardContext = () => useContext(CollapsibleCardContext);

CollapsibleCardProvider.propTypes = {
    children: PropTypes.node,
    setCollapsed: PropTypes.func,
};
