import React, { useRef, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import videoUnitLoader from 'modules/taboola-common-frontend-modules/video-unit-loader/config';
import { VIDEO_PREVIEW_MODE } from '../../../campaigns/modules/common-campaign-form/config/VideoPreviewMode';
import styles from './VideoUnitPlacement.module.scss';

const PREVIEW_MODE_TO_CLASS_NAME_MAP = {
    [VIDEO_PREVIEW_MODE.MOBILE]: 'ta-video-placement-mobile',
    [VIDEO_PREVIEW_MODE.DESKTOP]: 'ta-video-placement-desktop',
};

export const VideoUnitPlacement = ({ previewMode, containerId, vast }) => {
    const [isUnitLoading, setIsUnitLoading] = useState(true);
    const placementRef = useRef();
    const { value: title } = useFormFieldValue({
        field: 'title',
    });
    const { value: description } = useFormFieldValue({
        field: 'description',
    });
    const { value: branding } = useFormFieldValue({
        field: 'brandName',
    });

    const adNativeSettings = useMemo(
        () => ({
            title,
            description,
            branding,
        }),
        [title, description, branding]
    );

    const adNativeSettingsInitialValue = useRef(adNativeSettings);

    const containerWithModeId = useMemo(() => `${containerId}-${previewMode}`, [containerId, previewMode]);

    useEffect(() => {
        setIsUnitLoading(true);
        videoUnitLoader.loadUnit(containerWithModeId, vast, adNativeSettingsInitialValue.current, () =>
            setIsUnitLoading(false)
        );
        return () => videoUnitLoader.clearVideoPlacement(placementRef);
    }, [containerWithModeId, previewMode, vast, setIsUnitLoading]);

    useEffect(() => {
        videoUnitLoader.setNativeAdSettings(adNativeSettings);
    }, [adNativeSettings]);

    return (
        <>
            {isUnitLoading && (
                <div>
                    <ContentLoader height={55} width={112} speed={2} />
                    <ContentLoader height={5} width={60} speed={2} />
                    <ContentLoader height={4} width={60} speed={2} />
                    <ContentLoader height={3} width={60} speed={2} />
                </div>
            )}
            <div
                id={containerWithModeId}
                className={styles[PREVIEW_MODE_TO_CLASS_NAME_MAP[previewMode]]}
                ref={placementRef}
                data-testid="videoPlacement"
            ></div>
        </>
    );
};
