import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ENTITY_TYPE } from 'modules/performance-rules/utils/performanceRulesConsts';
import { usePerformanceRulesApi } from 'services/api';

const emptyOBJ = [];

const isAccountLevelRule = rule => rule.entityType === ENTITY_TYPE.ADVERTISER;

export const useCampaignPerformanceRules = (campaignId, accountName, performanceRulesValue) => {
    const { getAccountRules } = usePerformanceRulesApi();

    const { data = emptyOBJ } = useQuery(
        ['fetchedPerformanceRules', accountName, campaignId],
        () => getAccountRules(accountName),
        {
            enabled: !!accountName,
            select: data => data?.results,
        }
    );

    const campaignRules = useMemo(() => {
        if (data && !!performanceRulesValue.length) {
            return data.filter(rule => {
                return performanceRulesValue.includes(rule.id) || isAccountLevelRule(rule);
            });
        }
        return data?.filter(rule => isAccountLevelRule(rule));
    }, [data, performanceRulesValue]);
    return { campaignRules };
};
