import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CONVERSION_TYPE } from 'modules/tracking/config/constants';
import EllipsisCellRenderer from './EllipsisCellRenderer';
import styles from './audiencePixelBasedDetailsCellRenderer.module.scss';

export const ConversionPixelBasedDetailsCellRenderer = ({ value, data }) => {
    const { type, eventName } = data;
    const { formatMessage } = useIntl();
    let cell = '-';
    if (type === CONVERSION_TYPE.EVENT_BASED) {
        cell = formatMessage(
            {
                id: `app.campaigns.reports.grid.conversion.conditions.event`,
                defaultMessage: 'Event: {eventName}',
            },
            { eventName }
        );
    } else if (type === CONVERSION_TYPE.BASIC && value) {
        const conditions = JSON.parse(value);

        cell = conditions
            .map(condition => {
                return formatMessage(
                    {
                        id: `app.campaigns.reports.grid.conversion.conditions.prefix.${condition.conditionType.toLowerCase()}`,
                        defaultMessage: `${condition.conditionType}: {url}`,
                    },
                    { url: condition.url }
                );
            })
            .join(
                formatMessage({
                    id: `app.campaigns.reports.grid.conversion.conditions.or`,
                    defaultMessage: ' OR ',
                })
            );
    }

    return (
        <div className={styles['container']}>
            <EllipsisCellRenderer value={cell} lines={2} />
        </div>
    );
};

ConversionPixelBasedDetailsCellRenderer.propTypes = {
    data: PropTypes.object,
    msgIdPrefix: PropTypes.string,
    ...EllipsisCellRenderer.propTypes,
};
