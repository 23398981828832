import { get } from 'lodash';
import { ACCOUNT_CACHE_KEY } from '../hooks/useAccountCache';

// This is manual workaround because queryClient bug - it does not return cache by root key
export const getAccountFromQueryCache = (queryClient, accountName) => {
    const allQueryKeys = queryClient
        .getQueryCache()
        .queries.filter(query => get(query, ['queryKey', 0]) === ACCOUNT_CACHE_KEY)
        .map(query => query.queryKey);
    const accountKey = allQueryKeys.find(queryKey => queryClient.getQueryData(queryKey)?.accountName === accountName);

    if (accountKey) {
        return queryClient.getQueryData(accountKey);
    }
};
