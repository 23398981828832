import React from 'react';
import classnames from 'classnames/bind';
import { MyLocationOutlinedIcon } from 'tuui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { ConditionsAction } from './ConditionsAction/ConditionsAction';
import styles from './performanceRulesConditionAndActionSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PerformanceRulesConditionAndActionSection = () => {
    return (
        <CommonCollapsibleCard
            id="PERFORMANC_ERULES_CONDITION_AND_ACTION_SECTION"
            header={
                <SectionHeader
                    headerIcon={<MyLocationOutlinedIcon />}
                    headerText={
                        <FormattedMessage
                            id="app.create.performance.rules.conditions.and.actions.title"
                            defaultMessage="Conditions and Actions"
                        />
                    }
                />
            }
            containerClassName={classNameBuilder('card-container')}
        >
            <ConditionsAction />
        </CommonCollapsibleCard>
    );
};
