import { useEffect } from 'react';
import { FEATURE_FLAGS, useConfigMatch } from '../../account-configurations';
import * as logRocketService from '../../log-rocket/logRocketService';

const EVENT_NAME = 'app_event';

export const useLogRocketLinkedEvents = register => {
    const isEnabled = useConfigMatch({ [FEATURE_FLAGS.CUSTOM_EVENT_AGGREAGATOR_ENABLED]: 'true' });
    useEffect(() => {
        if (!isEnabled) {
            return;
        }
        const unregister = register(event => {
            logRocketService.trackEvent(EVENT_NAME, { id: event.id, type: event.event?.type });
        });
        return unregister;
    }, [register, isEnabled]);
};
