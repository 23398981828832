const HandlerMessageType = {
    HASHCHANGE: 'hashchange',
    LOAD: 'load',
    SELF: 'self',
    UNLOAD: 'unload',
    AUTH: 'auth',
    RECOMMENDATIONS: 'recommendations',
    VIDEO_MANAGEMENT: 'video_management',
};

export default HandlerMessageType;
