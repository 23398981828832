'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Truncate = require('./Truncate');

var _Truncate2 = _interopRequireDefault(_Truncate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Truncate2.default;