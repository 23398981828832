import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
    DragAndDropFile,
    INDICATION_TYPES,
    STYLED_BUTTON_SIZE,
    STYLED_BUTTON_TYPE,
    StyledButton,
    UploadIcon,
    DragAndDropFileContext,
} from 'taboola-ultimate-ui';
import { ExternalLink } from '../../../../components';
import { addIndication } from '../../../taboola-common-frontend-modules/Indications';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { CSVFileDropZone, CSVDropZoneWithError } from './CSVFileDropZone';
import CSVFileIcon from './CSVFileIcon';
import styles from './CSVUploadModule.module.scss';

const indicationMultipleFilesError = {
    message: (
        <FormattedMessage
            id="audience.editor.validations.error.crm.upload.too.much.files"
            defaultMessage="Multiple files were selected, please select a single CSV file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

const indicationWrongFormat = {
    message: (
        <FormattedMessage
            id="audience.editor.validations.error.crm.upload.wrong.format"
            defaultMessage="Wrong file format was selected, please add a single CSV file"
        />
    ),
    type: INDICATION_TYPES.ERROR,
    highlight: <FormattedMessage id="error.highlight" />,
};

export const CSV = { 'text/*': ['.csv'] };

const baseValidateFiles = (rejectedFiles, dispatch) => {
    if (rejectedFiles && rejectedFiles.length > 1) {
        dispatch(addIndication(indicationMultipleFilesError));
        return false;
    }
    if (rejectedFiles.length === 1 && !rejectedFiles[0].name.toLocaleLowerCase().endsWith(CSV)) {
        dispatch(addIndication(indicationWrongFormat));
        return false;
    }
    return true;
};

export const uploadButton = openFileDialog => {
    return (
        <div className={styles['upload-button-container']}>
            <StyledButton
                onClick={openFileDialog}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.GHOST}
                iconBeforeClassName={styles['upload-button-icon']}
                iconBefore={<UploadIcon />}
            >
                <div className={styles['upload-button-text']}>
                    <FormattedMessage id="creative.editor.tab.upload.device.icon" defaultMessage="Upload CSV file" />
                </div>
            </StyledButton>
        </div>
    );
};

const classNameBuilder = classnames.bind(styles);

export const CSVUploadModule = ({
    guidelinesMessage,
    templateFileUrl,
    onRemoveFileHandler,
    file,
    onDropFileHandler: onDropFileHandlerProp,
    additionalValidateFiles,
    containerClassName,
    inZoneButton = false,
    isMandatory = true,
}) => {
    const dispatch = useDispatch();
    const validateFiles = useCallback(
        (acceptedFiles, rejectedFiles) =>
            baseValidateFiles(rejectedFiles, dispatch) &&
            additionalValidateFiles({ acceptedFiles, rejectedFiles, dispatch }),
        [additionalValidateFiles, dispatch]
    );
    const onDropFileHandler = useCallback(
        (acceptedFiles, rejectedFiles) => {
            if (validateFiles(acceptedFiles, rejectedFiles, dispatch) && acceptedFiles && acceptedFiles.length === 1) {
                onDropFileHandlerProp(acceptedFiles, rejectedFiles);
            }
        },
        [dispatch, onDropFileHandlerProp, validateFiles]
    );
    const dropZoneClassName = !inZoneButton ? classNameBuilder('container') : null;
    const dropZone = openFileDialog =>
        isMandatory ? (
            <CSVDropZoneWithError
                filename={file?.name}
                removeFile={onRemoveFileHandler}
                id="CsvDropZoneValidationId"
                children={
                    inZoneButton && (
                        <div>
                            <FormattedMessage id="csv.upload.module.drop.zone.or" defaultMessage="or" />
                            {uploadButton(openFileDialog)}
                        </div>
                    )
                }
                containerClassName={classNameBuilder('in-zone-container')}
            />
        ) : (
            <CSVFileDropZone
                filename={file?.name}
                removeFile={onRemoveFileHandler}
                id="CsvDropZoneValidationId"
                children={
                    inZoneButton && (
                        <div>
                            <FormattedMessage id="csv.upload.module.drop.zone.or" defaultMessage="or" />
                            {uploadButton(openFileDialog)}
                        </div>
                    )
                }
                containerClassName={classNameBuilder('in-zone-container')}
            />
        );

    return (
        <div className={containerClassName}>
            <DragAndDropFile
                fileTypes={CSV}
                multiple={false}
                onDrop={onDropFileHandler}
                dropIcon={<CSVFileIcon className={styles['csv-file-icon']} />}
                text={
                    <FormattedMessage
                        id="audience.editor.crm.upload.drag.and.drop.description"
                        defaultMessage="Drag & Drop CSV File"
                    />
                }
            >
                <DragAndDropFileContext.Consumer>
                    {({ openFileDialog }) => {
                        return (
                            <div className={dropZoneClassName}>
                                {dropZone(openFileDialog)}
                                {!inZoneButton && uploadButton(openFileDialog)}
                            </div>
                        );
                    }}
                </DragAndDropFileContext.Consumer>
            </DragAndDropFile>
            <div>
                <br />
                <span className={styles['guidelines-title']}>
                    <FormattedMessage
                        id="audience.editor.crm.upload.csv.file.guidelines.title"
                        defaultMessage="CSV Guidelines:"
                    />
                </span>
                {templateFileUrl && (
                    <ExternalLink href={templateFileUrl} className={styles['link']}>
                        <FormattedMessage
                            id="audience.editor.crm.upload.csv.file.download.template"
                            defaultMessage="Download Template File"
                        />
                    </ExternalLink>
                )}
                <div className={styles['guidelines']}>{guidelinesMessage}</div>
            </div>
        </div>
    );
};

CSVUploadModule.propTypes = {
    guidelinesMessage: PropTypes.node,
    templateFileUrl: PropTypes.string,
    onRemoveFileHandler: PropTypes.func,
    file: PropTypes.object,
    onDropFileHandler: PropTypes.func,
    additionalValidateFiles: PropTypes.func,
    containerClassName: PropTypes.string,
};

CSVUploadModule.defaultProps = {
    additionalValidateFiles: () => true,
};

export default CSVUploadModule;
