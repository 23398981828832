"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgCompletionCircle50(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("circle", {
            cx: 12,
            cy: 12,
            r: 7.5,
            fill: "white",
            stroke: "currentColor"
        }),
        React.createElement(
            "mask",
            { id: "path-2-inside-1_503_909", fill: "white" },
            React.createElement("path", { d: "M12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18L12 12V6Z" })
        ),
        React.createElement("path", {
            d: "M12 6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18L12 12V6Z",
            fill: "currentColor",
            stroke: "currentColor",
            strokeWidth: 2,
            mask: "url(#path-2-inside-1_503_909)"
        })
    );
}
exports.default = SvgCompletionCircle50;