import { generateCreativeEditorPath } from 'modules/campaigns/services/utils';
import { dimensionSelector } from 'selectors';
import { navigate } from '../../../../../actions';
import { mergeQueryParams } from '../../../../taboola-common-frontend-modules/query-params';
import { PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP, FORM_MODES } from '../../../config';
import { cleanCreativesEditingResources } from '../../creative-editor/flows';
import { INITIAL_CREATIVE_TYPE } from '../../creative-editor/hooks/useInitialCreativeType';

const duplicateCreative = (data, history) => (dispatch, getState) => {
    const { accountName: accountId, campaignId, id: creativeId, performanceStatus } = data;
    const dimension = dimensionSelector(getState());
    const creativeType = PERFORMANCE_STATUS_TO_CREATIVE_TYPE_MAP[performanceStatus];

    const duplicateCreativePath = generateCreativeEditorPath({
        mode: FORM_MODES.DUPLICATE,
        accountId,
        campaignId,
        dimension,
        creativeId,
    });

    const pathWithCreativeType = `${duplicateCreativePath}${mergeQueryParams('', {
        [INITIAL_CREATIVE_TYPE]: creativeType,
    })}`;

    dispatch(cleanCreativesEditingResources());
    dispatch(navigate(history, pathWithCreativeType));
};

export default duplicateCreative;
