import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { MAX_SEGMENTS_TO_SELECT } from '../../Tree';

export const useNodeHandlersWithMetrics = ({
    handleSelectNode,
    handleSelectAll,
    search,
    selectedLeaves,
    setDefaultEnabledProviders,
}) => {
    // when selected leaves are empty need to set defaultProviders in case of node select
    // it needed for tree to check only enabled leaves
    const handleSelectNodeWrapper = useCallback(
        (...params) => {
            const [path, { label }] = params;
            const component = search ? 'Marketplace Search' : 'Marketplace Browse';
            const columnName = path.split('>').slice(0, -1).join('>');
            gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                component,
                value: label,
                columnName,
            });

            if (isEmpty(selectedLeaves)) {
                setDefaultEnabledProviders();
            }

            handleSelectNode(...params, MAX_SEGMENTS_TO_SELECT);
        },
        [handleSelectNode, setDefaultEnabledProviders, selectedLeaves, search]
    );

    const handleSelectAllWrapper = useCallback(
        check => {
            if (isEmpty(selectedLeaves)) {
                setDefaultEnabledProviders();
            }

            handleSelectAll(check, MAX_SEGMENTS_TO_SELECT);
        },
        [handleSelectAll, setDefaultEnabledProviders, selectedLeaves]
    );

    return { handleSelectNodeWrapper, handleSelectAllWrapper };
};
