import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { buildCodeSnippetWithParams } from 'modules/taboola-common-frontend-modules/account-configurations/utils/buildPixelCodeSnippet';
import { selectedAccountSelector } from 'selectors';
import { CodeSnippet } from '../CodeSnippet/CodeSnippet';

export const JSPixelCodeSnippet = ({ eventName }) => {
    const { [COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET]: codeSnippet } = useCommonConfig([
        COMMON_FLAGS.UNIP_EVENT_CODE_SNIPPET,
    ]);
    const { id: accountId } = useSelector(selectedAccountSelector);

    const codeSnippetWithParams = useMemo(() => {
        return buildCodeSnippetWithParams(codeSnippet, accountId, eventName);
    }, [codeSnippet, accountId, eventName]);

    return <CodeSnippet codeSnippetText={codeSnippetWithParams} />;
};

JSPixelCodeSnippet.propTypes = {
    eventName: PropTypes.string,
};

export default JSPixelCodeSnippet;
