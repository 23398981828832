import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import { AddIcon, ArrowLeftIcon, ArrowRightIcon, RemoveIcon, ZoomInIcon } from 'tuui';
import { LinkButton } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { OUTCROPPING_COMPONENT_NAME } from '../../constants/croppingModalGtmConstants';
import styles from './cropImageControls.module.scss';

const classNameBuilder = classnames.bind(styles);

const ZOOM_STEP = 0.1;

export const CropImageControls = ({
    src,
    updateSrc,
    originalSrc,
    srcHistory,
    setSrcHistory,
    isOutcroppingEnabled,
    shouldShowOutcroppingControls,
    maxZoom,
    setCroppedAreaPixels,
    initialCropData,
}) => {
    const curSrcIndex = srcHistory?.indexOf(src);
    const { value: zoom, onChange: setZoom } = useFormFieldValue({ field: 'zoom' });
    const { onChange: setCrop } = useFormFieldValue({ field: 'crop' });

    const onResetCrop = useCallback(() => {
        setZoom(1);
        setCrop(initialCropData.crop);
        setCroppedAreaPixels(initialCropData.croppedAreaPixels);
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'Cropping Modal',
            value: 'reset crop',
        });
    }, [setZoom, setCrop, setCroppedAreaPixels, initialCropData]);

    const onResetOutcropping = useCallback(() => {
        updateSrc(originalSrc);
        setSrcHistory([]);
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: `${OUTCROPPING_COMPONENT_NAME}: Reset Clicked`,
            value: src,
        });
    }, [originalSrc, setSrcHistory, src, updateSrc]);

    return (
        <div className={styles['crop-controls']}>
            <div className={styles['reset-container']}>
                <LinkButton
                    onClick={isOutcroppingEnabled && srcHistory?.length > 0 ? onResetOutcropping : onResetCrop}
                    className={styles['reset-button']}
                >
                    <FormattedMessage id="cropping.content.reset.button" defaultMessage="Reset" />
                </LinkButton>
                {shouldShowOutcroppingControls && (
                    <>
                        <div className={styles['divider']} />
                        <div className={styles['history-container']}>
                            <FormattedMessage id="cropping.content.history.label" defaultMessage="Variations" />
                            <div
                                className={classNameBuilder('arrow', { disabled: curSrcIndex === 0 })}
                                onClick={() => {
                                    updateSrc(srcHistory[curSrcIndex - 1]);

                                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                                        component: `${OUTCROPPING_COMPONENT_NAME}: Variations History Clicked`,
                                    });
                                }}
                            >
                                <ArrowLeftIcon />
                            </div>
                            <div>
                                {curSrcIndex + 1}/{srcHistory.length}
                            </div>
                            <div
                                className={classNameBuilder('arrow', {
                                    disabled: curSrcIndex === srcHistory.length - 1,
                                })}
                                onClick={() => {
                                    updateSrc(srcHistory[curSrcIndex + 1]);
                                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                                        component: `${OUTCROPPING_COMPONENT_NAME}: Variations History Clicked`,
                                    });
                                }}
                            >
                                <ArrowRightIcon />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles['zoom-container']}>
                <ZoomInIcon className={styles['zoom-icon']} />
                <div className={styles['zoom-controls']}>
                    <button
                        className={styles['zoom-button']}
                        onClick={() => setZoom(Math.max(zoom - ZOOM_STEP, 1))}
                        disabled={zoom === 1}
                        aria-label="zoom out"
                    >
                        <RemoveIcon fontSize="small" />
                    </button>
                    <div>{Math.round(zoom * 100)}%</div>
                    <button
                        className={styles['zoom-button']}
                        onClick={() => setZoom(Math.min(zoom + ZOOM_STEP, maxZoom))}
                        disabled={zoom === maxZoom}
                        aria-label="zoom in"
                    >
                        <AddIcon fontSize="small" />
                    </button>
                </div>
            </div>
        </div>
    );
};
