import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useCurrentValueGetter } from 'hooks';
import { ClearButton } from 'modules/campaigns/modules/campaigns-reports/components/Toolbar';
import { useSelectedReportId } from 'modules/campaigns/modules/campaigns-reports/hooks';
import { useReportFilters } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportFilters';
import { useReportFiltersStatus } from 'modules/campaigns/modules/campaigns-reports/hooks/useReportFiltersStatus';
import { useAccount } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { accountsApi, audienceInsightsApi, campaignGroupApi, resourcesApi, sitesApi } from 'services/api';
import { performanceRulesApi } from 'services/api';
import { campaignsApi } from 'services/api';
import { getMarketplaceProviders } from 'services/api/audiencesApi';
import { ARGUMENT_NAME, FILTER_TYPE, STATIC_FILTER_TYPE_MAP } from '../../config';
import { FiltersToolbar } from './FiltersToolbar/FiltersToolbar';
import {
    byAccountDescriptionMapper,
    byCampaignsGroupMapper,
    byNameUddIdMapper,
    byNameValueMapper,
    byRuleNameMapper,
    bySiteIdMapper,
    createPaginatedDataHandlerToOptions,
    dataHandlerByName,
    dataHandlerToOptions,
    dataHandlerToOSFamily,
    dataHandlerToOSVersion,
    defaultArgNames,
    emptyValueOptions,
    generateStaticFilteredOptions,
    PAGE_SIZE,
} from './filterUtils';
import useConfigFilters from './hooks/useConfigFilters';
import styles from './filters.module.scss';

const fetcherMap = {
    [FILTER_TYPE.COUNTRY]: {
        fetcher: async () => {
            const { results: countries } = await resourcesApi.getCountries();
            return countries
                .filter(country => !country.group)
                .map(country => ({ value: country.name, label: country.value }));
        },
        messagePrefix: 'app.country.code',
        responseTransformer: data => data.map(({ value }) => value),
    },
    [FILTER_TYPE.SITE]: {
        fetcher: sitesApi.getAllSites,
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(byAccountDescriptionMapper),
    },
    [FILTER_TYPE.SITE_ID]: {
        fetcher: sitesApi.getAllSites,
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(bySiteIdMapper),
    },
    [FILTER_TYPE.CATEGORY_TYPE]: {
        fetcher: resourcesApi.getCategories,
        messagePrefix: 'campaign.editor.category',
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.IAB_CATEGORY_TYPE]: {
        fetcher: resourcesApi.getIabCategories,
        messagePrefix: 'campaign.editor.category.iab',
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.LANGUAGE]: {
        fetcher: resourcesApi.getLanguages,
        messagePrefix: 'app.language.code',
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.PARTNER_NAME]: {
        fetcher: getMarketplaceProviders,
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ARGUMENT_NAME.QUERY_PARAMS, ...defaultArgNames],
        responseTransformer: ({ results }) => ({
            options: results.map(({ provider }) => ({ value: provider, label: provider })),
            hasMore: false,
        }),
    },
    [FILTER_TYPE.TARGET_PAGE_TYPE]: {
        fetcher: resourcesApi.getTargetPageType,
        responseTransformer: dataHandlerToOptions,
    },
    [FILTER_TYPE.ACCOUNT_MANAGER]: {
        fetcher: resourcesApi.getAccountManagers,
        argsNames: defaultArgNames,
        responseTransformer: createPaginatedDataHandlerToOptions(),
    },
    [FILTER_TYPE.ACCOUNT_MANAGER_GROUP]: {
        fetcher: resourcesApi.getAccountManagerGroups,
        argsNames: defaultArgNames,
        responseTransformer: createPaginatedDataHandlerToOptions(byNameValueMapper),
    },
    [FILTER_TYPE.SALES_GROUP]: {
        fetcher: resourcesApi.getSalesGroups,
        argsNames: defaultArgNames,
        responseTransformer: createPaginatedDataHandlerToOptions(),
    },
    [FILTER_TYPE.SALES_PERSON]: {
        fetcher: resourcesApi.getSalesPeople,
        argsNames: defaultArgNames,
        responseTransformer: createPaginatedDataHandlerToOptions(),
    },
    [FILTER_TYPE.BROWSER]: {
        fetcher: resourcesApi.getBrowsers,
        messagePrefix: 'campaign.editor.targeting.browser.options',
        argsNames: defaultArgNames,
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.OS_FAMILY]: {
        fetcher: resourcesApi.getOsList,
        argsNames: defaultArgNames,
        responseTransformer: dataHandlerToOSFamily,
    },
    [FILTER_TYPE.OS_VERSION]: {
        fetcher: resourcesApi.getOsList,
        argsNames: defaultArgNames,
        responseTransformer: dataHandlerToOSVersion,
    },
    [FILTER_TYPE.CAMPAIGNS_GROUP]: {
        fetcher: (accountName, searchText, page, pageSize) =>
            campaignGroupApi.getCampaignGroupList(accountName, { searchText, page, pageSize }),
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(byCampaignsGroupMapper),
    },
    [FILTER_TYPE.BASELINE]: {
        fetcher: audienceInsightsApi.getBaselines,
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(byNameUddIdMapper),
    },
    [FILTER_TYPE.HISTORY_LOG_ACTIVITY_CODE_TYPE]: {
        messagePrefix: 'app.campaigns.reports.grid.campaign_history.activity_code',
        fetcher: resourcesApi.getHistoryLogActivityCodes,
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.CONVERSION_TYPE]: {
        messagePrefix: 'report.conversionType',
        fetcher: resourcesApi.getConversionRuleTypes,
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.CONVERSION_CATEGORY]: {
        messagePrefix: 'tracking.conversion.category',
        fetcher: resourcesApi.getConversionCategories,
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.CONVERSION_STATUS]: {
        messagePrefix: 'report.conversionStatus',
        fetcher: resourcesApi.getConversionStatuses,
        responseTransformer: dataHandlerByName,
    },
    [FILTER_TYPE.ACCOUNT_NAME]: {
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ARGUMENT_NAME.PAGE, ARGUMENT_NAME.PAGE_SIZE],
        fetcher: (...args) => {
            const [accountId, page, pageSize] = args.slice();
            return accountsApi.getAccountsUnderNetwork('', page, pageSize, accountId);
        },
        responseTransformer: createPaginatedDataHandlerToOptions(byAccountDescriptionMapper),
    },
    [FILTER_TYPE.CAMPAIGN_NAME]: {
        fetcher: (accountName, searchText, page, pageSize) =>
            campaignsApi.getCampaigns({
                accountId: accountName,
                page,
                pageSize,
                searchText,
            }),
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(),
    },
    [FILTER_TYPE.RULE_NAME]: {
        fetcher: (accountName, searchText, page, pageSize) =>
            performanceRulesApi.getAccountRules(accountName, { searchText, page, pageSize }),
        argsNames: [ARGUMENT_NAME.ACCOUNT_ID, ...defaultArgNames],
        responseTransformer: createPaginatedDataHandlerToOptions(byRuleNameMapper),
    },
};

export const Filters = ({ reportConfig, getApplyBtnMetricsAttrs }) => {
    const [isAddingNewFilter, setIsAddingNewFilter] = useState(false);
    const { formatMessage } = useIntl();
    const { permittedFilters, requiredFiltersSet } = useConfigFilters(reportConfig);
    const [editingFilterIndex, setEditingFilterIndex] = useState(-1);
    const [selectedReport] = useSelectedReportId();
    const { accountId } = useAccount();
    const [isFilterActive, setFilterActive] = useReportFiltersStatus();
    const { reportFilters, addReportFilter, updateReportFilter, removeReportFilter, clearReportFilters } =
        useReportFilters();
    const reportFiltersGetter = useCurrentValueGetter(reportFilters);

    const stopEditing = useCallback(() => setEditingFilterIndex(-1), []);
    const startAddingNewFilter = useCallback(() => setIsAddingNewFilter(true), []);
    const stopAddingNewFilter = useCallback(() => setIsAddingNewFilter(false), []);

    const addFilter = filter => {
        stopAddingNewFilter();
        addReportFilter(filter);
        setFilterActive(true);
    };
    const updateFilter = (index, filter) => {
        updateReportFilter(index, filter);
        stopAddingNewFilter();
        stopEditing();
    };
    const removeFilter = filter => {
        if (reportFilters.length === 1) {
            setFilterActive(false);
        }
        removeReportFilter(filter);
    };
    const clearFilters = () => {
        stopAddingNewFilter();
        setFilterActive(false);
        clearReportFilters();
    };

    const safeFetching = (fetcherObj, params) => {
        if (!fetcherObj) {
            return emptyValueOptions;
        }

        const { generateOptions } = params;
        const { fetcher, messagePrefix, responseTransformer, argsNames = [] } = fetcherObj;
        const responseHandler = data =>
            messagePrefix
                ? generateOptions(messagePrefix, responseTransformer(data, params))
                : responseTransformer(data, params);

        const args = argsNames.map(name => params[name]);

        return fetcher(...args)
            .then(data => responseHandler(data))
            .catch(() => emptyValueOptions);
    };

    const getValueOptions = (type, searchInput, page) => {
        const generateOptions = (messageIdPrefix, values) =>
            generateStaticFilteredOptions({ formatMessage, messageIdPrefix, values, searchInput });
        const staticTypeMap = STATIC_FILTER_TYPE_MAP[type];

        if (staticTypeMap) {
            const { messageIdPrefix, values } = staticTypeMap;
            return generateOptions(messageIdPrefix, values);
        }

        return safeFetching(fetcherMap[type], {
            generateOptions,
            searchInput,
            page,
            accountId,
            pageSize: PAGE_SIZE,
        });
    };

    useEffect(() => {
        if (isEmpty(reportFilters) && isFilterActive) {
            setFilterActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportFilters]);

    useEffect(() => {
        stopAddingNewFilter();
        stopEditing();
    }, [selectedReport, stopAddingNewFilter, stopEditing]);

    useEffect(() => {
        if (!isFilterActive) {
            stopAddingNewFilter();
            return;
        }

        if (isEmpty(reportFiltersGetter())) {
            startAddingNewFilter();
        }
    }, [isFilterActive, reportFiltersGetter, startAddingNewFilter, stopAddingNewFilter]);

    if (!isFilterActive) {
        return null;
    }

    return (
        <div className={styles['toolbar']}>
            <div className={styles['filters-label']}>
                <FormattedMessage id="app.campaigns.reports.filters.toolbar.title" defaultMessage="Filters" />
            </div>
            <FiltersToolbar
                filters={reportFilters}
                addFilter={addFilter}
                updateFilter={updateFilter}
                removeFilter={removeFilter}
                editingFilterIndex={editingFilterIndex}
                setEditingFilterIndex={setEditingFilterIndex}
                stopEditing={stopEditing}
                isAddingNewFilter={isAddingNewFilter}
                startAddingNewFilter={startAddingNewFilter}
                stopAddingNewFilter={stopAddingNewFilter}
                availableFilters={permittedFilters}
                getValueOptions={getValueOptions}
                getApplyBtnMetricsAttrs={getApplyBtnMetricsAttrs}
                reportConfig={reportConfig}
            />
            {requiredFiltersSet.size === 0 && (
                <ClearButton onClick={clearFilters}>
                    <FormattedMessage
                        id="app.campaigns.reports.filters.toolbar.buttons.clearAll"
                        defaultMessage="Clear All"
                    />
                </ClearButton>
            )}
        </div>
    );
};

Filters.propTypes = {
    /** return value is spread into apply button for metrics */
    getApplyBtnMetricsAttrs: PropTypes.func,
};
