'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _react = require('react');

var useCollapseAnimation = function useCollapseAnimation(collapsed, ref) {
    var _useState = (0, _react.useState)(collapsed),
        _useState2 = _slicedToArray(_useState, 2),
        animationClosed = _useState2[0],
        setAnimationClosed = _useState2[1];

    var _useState3 = (0, _react.useState)(false),
        _useState4 = _slicedToArray(_useState3, 2),
        animationOpen = _useState4[0],
        setAnimationOpen = _useState4[1];

    var localRef = (0, _react.useRef)(null);
    var collapsibleRef = ref || localRef;

    (0, _react.useEffect)(function () {
        // animationClosed is only true when the card is 100% collapsed (after the full animation closing & before the opening animation starts)
        // we need this extra logic since it's technically delayed from the *real* collapsed prop
        // animationOpen is only true when the card is 100% open (after the full animation opening & before the closing animation starts)
        // extremely similar to animationClosed but it tracks the "opposite" state in this desynced animation state flow
        // there are 2 state variables since these 2 states can't be generated by simply notting the other because there are 4 total states in the fsm not 2
        // (a good example of why you can't just have 1 state and not it for the other is that during the actual collapsing animation,
        // animationClosed and animationOpen both need to be false)
        if (!collapsed && animationClosed) {
            setAnimationClosed(false);
        } else if (collapsed && animationOpen) {
            setAnimationOpen(false);
        }
    }, [collapsed, animationClosed, animationOpen]);
    (0, _react.useEffect)(function () {
        if (collapsibleRef.current) {
            var el = collapsibleRef.current;
            var onTransitionEnd = function onTransitionEnd() {
                if (collapsed) {
                    setAnimationClosed(true);
                } else {
                    setAnimationOpen(true);
                }
            };
            el.addEventListener('transitionend', onTransitionEnd);
            return function () {
                el.removeEventListener('transitionend', onTransitionEnd);
            };
        }
        return undefined;
    }, [collapsed, collapsibleRef]);
    return [collapsibleRef, animationOpen, animationClosed];
};

exports.default = useCollapseAnimation;