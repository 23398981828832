import React, { useMemo } from 'react';
import classnames from 'classnames/bind';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useDropdownOptions } from '../../../../../../../../hooks';
import { Check } from './icons/Check';
import styles from './promptHelper.module.scss';

const classNameBuilder = classnames.bind(styles);

export const PromptHelperCategoryDropdownButton = ({ category, options, icon, setHoveredCategory }) => {
    const { value: optionValue, onChange: optionOnChange } = useFormFieldValue({ field: `promptHelper.${category}` });
    const selectedItem = useMemo(() => (optionValue === null ? null : { value: optionValue }), [optionValue]);

    const optionsTransformer = options =>
        options.map(option => ({
            value: option,
            label: <FormattedMessage id={`creative.editor.tab.aiGenerated.fromPrompt.helper.options.${option}`} />,
        }));
    const dropdownOptions = useDropdownOptions(options, null, optionsTransformer);

    const customStyles = {
        control: () => ({
            display: 'flex',
        }),
        placeholder: () => ({
            padding: 0,
            position: 'static',
            width: '100%',
            marginLeft: 0,
        }),
        option: baseStyle => ({
            ...baseStyle,
            whiteSpace: 'nowrap',
        }),
        menu: baseStyle => ({
            ...baseStyle,
            width: 'max-content',
            minWidth: '100%',
        }),
    };

    const valueRenderer = () => (
        <div
            className={classNameBuilder('dropdown-value', { selected: !!optionValue })}
            onMouseOver={() => {
                setHoveredCategory(category);
            }}
            onMouseOut={() => {
                setHoveredCategory(null);
            }}
        >
            <div className={styles['dropdown-icon']}>{!!optionValue ? <Check /> : icon}</div>
            <FormattedMessage
                id={`creative.editor.tab.aiGenerated.fromPrompt.helper.categories.${category}`}
                defaultMessage={category}
            />
        </div>
    );

    return (
        <DropdownMenu
            key={category}
            onChange={({ value }) => {
                optionOnChange(value);
            }}
            selectedValueObject={selectedItem}
            options={dropdownOptions}
            className={styles['dropdown-menu']}
            valueRenderer={valueRenderer}
            placeholder={valueRenderer()}
            styles={customStyles}
            metricsAttributes={{
                'data-metrics-event-name': GTM_EVENTS.USABILITY,
                'data-metrics-component': `PromptHelperDropdown - Category: ${category}`,
                'data-metrics-value': selectedItem,
            }}
        />
    );
};
