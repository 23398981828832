'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _PaginationBar = require('./PaginationBar');

var _PaginationBar2 = _interopRequireDefault(_PaginationBar);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _PaginationBar2.default;