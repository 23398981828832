'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.PaginatedDropdownPlaceholder = PaginatedDropdownPlaceholder;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactContentLoader = require('react-content-loader');

var _reactContentLoader2 = _interopRequireDefault(_reactContentLoader);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'paginatedDropdownPlaceholder__taboola-svg-icon___2jdWn',
    'placeholder': 'paginatedDropdownPlaceholder__placeholder___1405W'
};


var classNameBuilder = _bind2.default.bind(styles);
function PaginatedDropdownPlaceholder(_ref) {
    var fontSize = _ref.fontSize,
        paddingX = _ref.paddingX,
        paddingY = _ref.paddingY,
        className = _ref.className,
        loaderClassName = _ref.loaderClassName,
        preserveAspectRatio = _ref.preserveAspectRatio;

    return _react2.default.createElement(
        'div',
        {
            className: classNameBuilder('placeholder', className),
            style: {
                '--paddingX': paddingX + 'px',
                '--paddingY': paddingY + 'px'
            }
        },
        _react2.default.createElement(_reactContentLoader2.default, { height: fontSize, speed: 2, className: loaderClassName, preserveAspectRatio: preserveAspectRatio })
    );
}

PaginatedDropdownPlaceholder.propTypes = {
    fontSize: _propTypes2.default.number.isRequired,
    paddingX: _propTypes2.default.number.isRequired,
    paddingY: _propTypes2.default.number.isRequired,
    className: _propTypes2.default.string,
    loaderClassName: _propTypes2.default.string,
    preserveAspectRatio: _propTypes2.default.string
};

exports.default = PaginatedDropdownPlaceholder;