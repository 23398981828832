import moment from 'moment-timezone';

const transformTrafficAllocationEndDateFromGW = campaign => {
    const { creativeTrafficAllocationABTestEndDate } = campaign;

    if (!creativeTrafficAllocationABTestEndDate) {
        return campaign;
    }

    const formattedCreativeTrafficAllocationABTestEndDate = moment
        .utc(creativeTrafficAllocationABTestEndDate)
        .format('YYYY-MM-DD');
    return {
        ...campaign,
        creativeTrafficAllocationABTestEndDate: moment(formattedCreativeTrafficAllocationABTestEndDate).endOf('day'),
    };
};

export default transformTrafficAllocationEndDateFromGW;
