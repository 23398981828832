import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ENTITY, getCreativeStatusType } from 'modules/campaigns/config';
import { resolveCreativeStatusWithParentCampaignStatus } from 'modules/campaigns/config/creativeConsts';
import { isCreativePausedByCustomRule } from 'modules/campaigns/modules/campaigns-reports/config/predicates';
import { PHASES } from 'modules/campaigns/modules/campaigns-reports/services/campaignStatusPhaseService';
import { CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES } from 'modules/campaigns/modules/common-campaign-form/config';
import { getCreativeStatusMessages } from 'modules/campaigns/services/utils';
import PhaseStatusTooltip from '../Tooltips/PhaseStatusTooltip';
import EnumValueCellRenderer from './EnumValueCellRenderer';
import RejectionReasonTooltip from './RejectionReasonTooltip';
import StatusCellRenderer from './StatusCellRenderer/StatusCellRenderer';

const CreativeStatusRenderer = ({ value, data, isStatusRejected, getStatusType: getStatusTypeProp, ...rest }) => {
    const creativeStatus = resolveCreativeStatusWithParentCampaignStatus(value, data);
    const creativeStatusMessages = getCreativeStatusMessages(creativeStatus);
    const { phaseMsgIdPrefix } = creativeStatusMessages;

    const { phase, tooltipBody, getStatusTypeFunc } = useMemo(() => {
        if (isCreativePausedByCustomRule(data)) {
            return {
                phase: PHASES.PAUSED,
                tooltipBody: null,
                getStatusTypeFunc: getCreativeStatusType,
            };
        }

        const itemPhase = getPhase(data);
        return {
            phase: itemPhase,
            tooltipBody: itemPhase ? (
                <PhaseStatusTooltip phase={itemPhase} phaseMsgIdPrefix={phaseMsgIdPrefix} />
            ) : null,
            getStatusTypeFunc: getStatusTypeProp,
        };
    }, [data, getStatusTypeProp, phaseMsgIdPrefix]);

    const { rejectReason, reviewerNotes } = data;
    const isRejected = isStatusRejected(data) && rejectReason;

    const rejectionTooltip = (
        <RejectionReasonTooltip rejectReason={rejectReason} reviewerNotes={reviewerNotes} {...creativeStatusMessages} />
    );

    return (
        <StatusCellRenderer
            phase={phase}
            tooltipBody={tooltipBody}
            value={creativeStatus}
            data={data}
            entityType={ENTITY.CREATIVE}
            isStatusRejected={isStatusRejected}
            hideTooltip={isRejected}
            staticIconTooltip={isRejected && rejectionTooltip}
            getStatusType={getStatusTypeFunc}
            {...rest}
            {...creativeStatusMessages}
        />
    );
};

const getPhase = data => {
    const { learningStatus, creativeTrafficAllocationMethod, creativeTrafficAllocationABTestEndDate } = data;

    if (
        creativeTrafficAllocationMethod === CREATIVE_TRAFFIC_ALLOCATION_METHOD_TYPES.EVEN &&
        creativeTrafficAllocationABTestEndDate
    ) {
        return PHASES.AB_TESTING;
    }

    return learningStatus;
};

CreativeStatusRenderer.propTypes = {
    data: PropTypes.object,
    isStatusRejected: PropTypes.func,
    getStatusType: PropTypes.func,
    ...EnumValueCellRenderer.propTypes,
};

export default CreativeStatusRenderer;
