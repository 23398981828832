import React from 'react';
import BidDealFloor from '../BidDealFloor/BidDealFloor';
import { BudgetSectionFrame } from '../BudgetSectionFrame';

const BudgetSectionPmpDeal = () => (
    <BudgetSectionFrame>
        <BidDealFloor />
    </BudgetSectionFrame>
);

export default BudgetSectionPmpDeal;
