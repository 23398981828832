'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.itemHeight = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'itemHeight': '46px',
    'taboola-svg-icon': 'withDropdownItemContainer__taboola-svg-icon___2sfoD',
    'container': 'withDropdownItemContainer__container___KNeex',
    'is-option': 'withDropdownItemContainer__is-option___2Dl3R',
    'is-focused': 'withDropdownItemContainer__is-focused___2Kob1'
}; /**
    * Created by itamar.c
    * Date: 2019-26-12
    */

var classNameBuilder = _bind2.default.bind(styles);

var itemHeight = exports.itemHeight = parseInt(styles.itemHeight.replace('px', ''), 10);

var withDropdownItemContainer = function withDropdownItemContainer(WrappedComponent, className) {
    function DropDownItemWithContainer(_ref) {
        var data = _ref.data,
            innerRef = _ref.innerRef,
            innerProps = _ref.innerProps,
            isFocused = _ref.isFocused,
            selectProps = _ref.selectProps;

        var isDisabled = selectProps.isOptionDisabled(data);
        var containerClassName = classNameBuilder('container', className, {
            'is-focused': !isDisabled && isFocused,
            'is-option': innerProps
        });
        return _react2.default.createElement(
            'div',
            Object.assign({ className: containerClassName, ref: innerRef }, innerProps),
            _react2.default.createElement(WrappedComponent, { data: data, selectProps: selectProps })
        );
    }

    DropDownItemWithContainer.propTypes = {
        innerRef: _propTypes2.default.func,
        innerProps: _propTypes2.default.object,
        isFocused: _propTypes2.default.bool,
        data: _propTypes2.default.object,
        selectProps: _propTypes2.default.object
    };

    return DropDownItemWithContainer;
};

exports.default = withDropdownItemContainer;