import React from 'react';
import { FormField, Indication } from 'taboola-ultimate-ui';
import { CommonCollapsibleCard } from 'components';
import { SectionHeader } from 'modules/campaigns/components';
import { COMMON_FLAGS, useCommonConfig } from 'modules/taboola-common-frontend-modules/account-configurations';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useIsScheduleMeetingPermitted } from '../../hooks/useIsScheduleMeetingPermitted';
import { ScheduleNowButton } from './ScheduleNowButton';
import styles from './getHelpFromExpertSection.module.scss';

export const GetHelpFromExpertSection = ({
    headerMessageId,
    headerDefaultMessage,
    descriptionMessageId,
    descriptionDefaultMessage,
}) => {
    const { [COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL]: scheduleMeetingUrl } = useCommonConfig([
        COMMON_FLAGS.SCHEDULE_TRACKING_MEETING_URL,
    ]);

    const isSchedulePermitted = useIsScheduleMeetingPermitted();

    return (
        isSchedulePermitted && (
            <CommonCollapsibleCard
                id="GET HELP FROM EXPERT"
                header={
                    <SectionHeader
                        headerText={<FormattedMessage id={headerMessageId} defaultMessage={headerDefaultMessage} />}
                    />
                }
            >
                <div className={styles['container']}>
                    <FormField
                        description={
                            <FormattedMessage id={descriptionMessageId} defaultMessage={descriptionDefaultMessage} />
                        }
                    />
                    <Indication
                        type="info"
                        message={
                            <FormField
                                label={
                                    <FormattedMessage
                                        id="tracking.get.help.from.expert.button"
                                        defaultMessage="Talk To A Tracking Expert"
                                    />
                                }
                                description={
                                    <FormattedMessage
                                        id="tracking.setup.get.help.from.expert.description"
                                        defaultMessage="Schedule with a tracking expert to implement the Taboola Pixel fast and easy."
                                    />
                                }
                            >
                                <ScheduleNowButton scheduleNowURL={scheduleMeetingUrl} />
                            </FormField>
                        }
                        displayDismissComponent={false}
                    />
                </div>
            </CommonCollapsibleCard>
        )
    );
};
