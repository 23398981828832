import { PLACEMENTS } from '../../creative-preview/config/placements';

export const creativeInitialValues = {
    creativeType: ({ initialCreativeType }) => initialCreativeType,
    creativeName: (_, { creativeName }) => `Copy of ${creativeName ?? ''}`,
    selectedPlacement: ({ isAppInstall, isTargetingApple }) => {
        if (isAppInstall) {
            return PLACEMENTS.TWO_BY_ONE;
        }
        if (isTargetingApple) {
            return PLACEMENTS.ONE_DOT_NINETY_ONE_BY_ONE;
        }
        return PLACEMENTS.SIXTEEN_BY_NINE;
    },
};
