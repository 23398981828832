import React from 'react';
import { STATIC_INDICATION_TYPES, INDICATION_TYPES } from 'modules/taboola-common-frontend-modules/Indications';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const realtimeReportDisclaimer = {
    staticIndicationType: STATIC_INDICATION_TYPES.REALTIME_REPORT_DISCLAIMER,
    type: INDICATION_TYPES.WARNING,
    message: (
        <FormattedMessage
            id="app.campaign.indication.realtime.report.disclaimer"
            defaultMessage="<b>Get campaign insights in real-time</b> - Note: The amounts below are not billable accurate data."
        />
    ),
};
