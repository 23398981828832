import { REPORT_TYPE, reportsBaseConfig } from 'modules/campaigns/config';
import * as FIELDS from 'modules/campaigns/modules/campaigns-reports/config/fields';
import { REPORT_PRESET } from 'modules/campaigns/modules/campaigns-reports/config/pickers';
import { generateColumnId } from 'modules/campaigns/modules/campaigns-reports/utils';

const reportId = reportsBaseConfig[REPORT_TYPE.FUNNELS].id;

const customColumns = {
    [REPORT_PRESET.DEFAULT]: [
        FIELDS.IS_DEFAULT.field,
        FIELDS.FUNNEL_NAME.field,
        FIELDS.CAMPAIGNS_USING.field,
        FIELDS.ACCOUNT_NAME.field,
        FIELDS.RULE_DISPLAY_NAMES.field,
        FIELDS.LAST_MODIFY_ON.field,
    ].map(field => generateColumnId(reportId, field)),
};

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.IS_DEFAULT.field,
    FIELDS.FUNNEL_NAME.field,
    FIELDS.CAMPAIGNS_USING.field,
    FIELDS.ACCOUNT_NAME.field,
    FIELDS.RULE_DISPLAY_NAMES.field,
    FIELDS.LAST_MODIFY_ON.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns };
