/* Audiences For Targeting */
export const CLEAR_CAMPAIGN_DRAWER_RESOURCES = 'CLEAR_CAMPAIGN_DRAWER_RESOURCES';

/* OS */
export const REQUEST_FETCH_LIST_OF_OS = 'REQUEST_FETCH_LIST_OF_OS';
export const FETCH_OS_SUCCESS = 'FETCH_OS_SUCCESS';
export const FETCH_OS_ERROR = 'FETCH_OS_ERROR';

/* Regions */
export const REQUEST_FETCH_REGIONS = 'REQUEST_FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_ERROR = 'FETCH_REGIONS_ERROR';

/* DMAs */
export const REQUEST_FETCH_DMAS = 'REQUEST_FETCH_DMAS';
export const FETCH_DMAS_SUCCESS = 'FETCH_DMAS_SUCCESS';
export const FETCH_DMAS_ERROR = 'FETCH_DMAS_ERROR';

/* Cities */
export const REQUEST_FETCH_CITIES = 'REQUEST_FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_ERROR = 'FETCH_CITIES_ERROR';

/* Time Zones */
export const REQUEST_FETCH_TIME_ZONES = 'REQUEST_FETCH_TIME_ZONES';
export const FETCH_TIME_ZONES_SUCCESS = 'FETCH_TIME_ZONES_SUCCESS';
export const FETCH_TIME_ZONES_ERROR = 'FETCH_TIME_ZONES_ERROR';

/* Browsers */
export const REQUEST_FETCH_BROWSERS = 'REQUEST_FETCH_BROWSERS';
export const FETCH_BROWSERS_SUCCESS = 'FETCH_BROWSERS_SUCCESS';
export const FETCH_BROWSERS_ERROR = 'FETCH_BROWSERS_ERROR';

/* 3rd Party Brand Safety */
export const REQUEST_FETCH_BRAND_SAFETY_CATEGORIES = 'REQUEST_FETCH_BRAND_SAFETY_CATEGORIES';
export const FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS = 'FETCH_BRAND_SAFETY_CATEGORIES_SUCCESS';
export const FETCH_BRAND_SAFETY_CATEGORIES_ERROR = 'FETCH_BRAND_SAFETY_CATEGORIES_ERROR';

/* Misc */
export const INVALIDATE_SUBCOUNTRY_DATA = 'INVALIDATE_SUBCOUNTRY_DATA';
