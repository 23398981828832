import EVERGREEN_OPTIONS from '../../modules/common-campaign-form/config/InitialValues/evergreenOptions';

const transformEvergreenFromGW = form => {
    if (!form) {
        return;
    }
    return {
        ...form,
        evergreen: form.evergreen ? [EVERGREEN_OPTIONS.EVERGREEN] : [],
    };
};
const transformEvergreenToGW = form => {
    if (!form) {
        return;
    }

    const { evergreen } = form;
    if (!evergreen) {
        return form;
    }

    return {
        ...form,
        evergreen: !!evergreen.length,
    };
};

export { transformEvergreenFromGW, transformEvergreenToGW };
