import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { ImageIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { useConfigMatch, FEATURE_FLAGS } from 'modules/taboola-common-frontend-modules/account-configurations';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from 'services/constants';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { CAMPAIGNS_ROUTE, CREATIVE_TYPE } from '../../../../config';
import { LogoField } from '../../../creative-creator/components/LogoField/LogoField';
import { useIsAppInstallCreativeInEditMode } from '../../../creative-creator/hooks/useIsAppInstallCreativeInEditMode';
import CreativePreviewMotion from '../../../creative-preview/components/CreativePreviewMotion/CreativePreviewMotion';
import { useIsCarouselCreative } from '../../hooks/useIsCarouselCreative';
import { ThumbnailMotionEditorBody } from '../ThumbnailMotionEditor/Body';
import { CreativeEditorFormBody } from './Body';
import styles from './creativeEditorForm.module.scss';

const mediaSectionTitle = <FormattedMessage id="creative.editor.media" defaultMessage="Media" />;

export const CreativeMotionEditorForm = ({
    accountId,
    campaignId,
    campaign: { brandingText, campaignProfile, startDate: startDateCampaign, endDate: endDateCampaign },
    onCancel,
    headerLabel,
    hideStatusSection,
    showSetupSection,
    selectedAccount,
}) => {
    const [thumbnailStatus, setThumbnailStatus] = useState(COMPONENT_STATUS.INITIAL);
    const [videoStatus, setVideoStatus] = useState(COMPONENT_STATUS.INITIAL);
    const isTrackingSectionEnabledByConfig = useConfigMatch({ [FEATURE_FLAGS.ENABLE_CREATIVE_TRACKING]: 'true' });
    const isTrackingSectionEnabledByPermission = usePermissions('CREATIVE_EDIT_TRACKING');
    const isTrackingSectionEnabled = isTrackingSectionEnabledByConfig || isTrackingSectionEnabledByPermission;
    const isLogoFieldEnabled = useConfigMatch({ [FEATURE_FLAGS.CREATIVE_FORM_LOGO_FIELD_ENABLED]: 'true' });
    const isCarouselCreative = useIsCarouselCreative();
    const { isAppInstall } = useIsAppInstallCreativeInEditMode();
    const isLogoFieldVisible = isLogoFieldEnabled && !isCarouselCreative && !isAppInstall;
    const language = get(campaignProfile, 'language', 'EN');

    return (
        <>
            <CreativeEditorFormBody
                accountId={accountId}
                className={styles['editor']}
                onCancel={onCancel}
                headerLabel={headerLabel}
                showSetupSection={showSetupSection}
                hideStatusSection={hideStatusSection}
                selectedAccount={selectedAccount}
                creativeType={CREATIVE_TYPE.MOTION}
                language={language}
                isIncludeTrackingSection={isTrackingSectionEnabled}
                mediaSection={
                    <CommonCollapsibleCard
                        id="VIDEO_MEDIA"
                        header={<SectionHeader headerIcon={<ImageIcon />} headerText={mediaSectionTitle} />}
                        sourcePath={CAMPAIGNS_ROUTE}
                        onCancel={onCancel}
                    >
                        <ThumbnailMotionEditorBody
                            campaignId={campaignId}
                            accountId={accountId}
                            thumbnailStatus={thumbnailStatus}
                            videoStatus={videoStatus}
                            setThumbnailStatus={setThumbnailStatus}
                            setVideoStatus={setVideoStatus}
                            formLayoutProps={{
                                headerContainerClassName: styles['editor-header'],
                                hideFooter: true,
                            }}
                        />
                        {isLogoFieldVisible && <LogoField />}
                    </CommonCollapsibleCard>
                }
                startDateCampaign={startDateCampaign}
                endDateCampaign={endDateCampaign}
            />
            <CreativePreviewMotion brandingText={brandingText} language={language} />
        </>
    );
};

CreativeMotionEditorForm.propTypes = {
    accountId: PropTypes.string,
    campaign: PropTypes.object,
    onCancel: PropTypes.func,
    headerLabel: PropTypes.node,
    hideStatusSection: PropTypes.bool,
    showSetupSection: PropTypes.bool,
    selectedAccount: PropTypes.object,
};

CreativeMotionEditorForm.defaultProps = {
    showSetupSection: false,
    campaign: {},
};
