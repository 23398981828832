'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var FileTypes = {
    IMAGE: 'image/*',
    VIDEO: 'video/*',
    AUDIO: 'audio/*',
    GIF: 'image/gif',
    STATIC_IMAGE: 'image/jpeg, image/png'
};

exports.default = FileTypes;