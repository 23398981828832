import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { validationFunctions } from '../../../../taboola-common-frontend-modules/validations/services';
import { audienceTargetingBidValidator, bidValidationsBase, selfFactorValidations } from './bidValidations';

const cpcValidationsBase = [
    ...bidValidationsBase,
    {
        validationFn: validationFunctions.range,
        options: { min: 'minAccountCpc' },
        messageId: 'validations.error.campaign.cpc.specificAccountMinValue',
        defaultMessage: 'CPC Amount cannot be lower than {minAccountCpc} {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'maxAccountCpc' },
        messageId: 'validations.error.campaign.cpc.specificAccountMaxValue',
        defaultMessage: 'Bid has to be lower than {currency}',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'budgetMaxThreshold' },
        messageId: 'validations.error.campaign.cpc.budgetMaxThreshold',
        defaultMessage: 'Bid cannot be higher than {budgetMaxThreshold} {currency} with this campaign budget setup',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: audienceTargetingBidValidator,
        options: { min: 'cpcDataMinValue' },
        messageId: 'validations.error.campaign.cpc.data.specificAccountMinValue',
        defaultMessage:
            'CPC Amount cannot be lower than {cpcDataMinValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
    {
        validationFn: audienceTargetingBidValidator,
        options: { max: 'cpcDataMaxValue' },
        messageId: 'validations.error.campaign.cpc.data.specificAccountMaxValue',
        defaultMessage:
            'CPC Amount cannot be higher than {cpcDataMaxValue} {currency} for campaigns using audience targeting',
        severity: INDICATION_TYPES.ERROR,
    },
];

export const cpcValidationsForCreate = [
    ...cpcValidationsBase,
    {
        validationFn: validationFunctions.range,
        options: { min: 'cpcMinWarning' },
        messageId: 'validations.error.campaign.cpc.network.minValue',
        defaultMessage: 'Please note: Bid is lower than normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
    {
        validationFn: validationFunctions.range,
        options: { max: 'cpcMaxWarning' },
        messageId: 'validations.error.campaign.cpc.network.maxValue',
        defaultMessage: 'Please note: Bid is above normal network range.',
        severity: INDICATION_TYPES.WARNING,
    },
];

export const cpcValidationsForEdit = [...cpcValidationsBase, ...selfFactorValidations];
