import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Radio, RadioGroup } from 'taboola-ultimate-ui';
import { TIME_ZONE_METHODS } from 'modules/campaigns/modules/common-campaign-form/components/DayTimeParting/consts/timeZoneOptions';
import transformTimeZonesFromGW from 'modules/campaigns/services/transformers/timeZonesTransformer';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TimezoneDropdown from '../../../common-campaign-form/components/TimezoneDropdown/TimezoneDropdown';
import { SchedulerDateTime } from './SchedulerDateTime';
import { TimezoneDropdownTooltip } from './TimezoneDropdownTooltip';
import { useFetchTimeZones } from './useFetchTimeZones';
import styles from './timeFrame.module.scss';

export const TimeFrame = ({ startDateCampaign, endDateCampaign, timeZoneName }) => {
    const timeZoneEnabled = useConfigMatch({ [FEATURE_FLAGS.TIME_ZONE_ENABLED]: 'true' });
    const userTimeZoneEnabled = useConfigMatch({ [FEATURE_FLAGS.USER_TIME_ZONE_ENABLED]: 'true' });
    const { value: timeZone, onChange: onTimeZoneChange } = useFormFieldValue({
        field: 'adSchedule.timeZone',
    });
    const [selectedTimeZoneMethod, setSelectedTimeZoneMethod] = useState(TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE);
    const [selectedTimeZone, setSelectedTimeZone] = useState(timeZoneName);

    useEffect(() => {
        if (timeZone === TIME_ZONE_METHODS.USER_TIME_ZONE || !timeZoneEnabled) {
            setSelectedTimeZoneMethod(timeZone);
            onTimeZoneChange(TIME_ZONE_METHODS.USER_TIME_ZONE);
            return;
        }

        if (timeZone) {
            setSelectedTimeZone(timeZone);
            return;
        }
        onTimeZoneChange(selectedTimeZone);
    }, [timeZone, setSelectedTimeZoneMethod, setSelectedTimeZone, onTimeZoneChange, selectedTimeZone, timeZoneEnabled]);

    const setTimezoneMethod = useCallback(
        timeZoneMethod => {
            setSelectedTimeZoneMethod(timeZoneMethod);
            if (timeZoneMethod === TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE) {
                onTimeZoneChange(selectedTimeZone);
                return;
            }

            onTimeZoneChange(timeZoneMethod);
        },
        [onTimeZoneChange, selectedTimeZone]
    );

    const { data, isSuccess } = useFetchTimeZones();

    const listOfTimeZones = useMemo(() => {
        if (isSuccess) {
            return transformTimeZonesFromGW(data);
        }

        return [];
    }, [isSuccess, data]);

    return (
        <div>
            <SchedulerDateTime startDateCampaign={startDateCampaign} endDateCampaign={endDateCampaign} />
            {timeZoneEnabled &&
                (userTimeZoneEnabled ? (
                    <RadioGroup
                        id="schedulerTimeZoneradioGroup"
                        selectedValue={selectedTimeZoneMethod}
                        onChange={setTimezoneMethod}
                        className={styles['group']}
                    >
                        <Radio
                            value={TIME_ZONE_METHODS.USER_TIME_ZONE}
                            title={
                                <FormattedMessage
                                    id="creative.editor.edit.ad.schedule.time.zone.as.user.time.zone"
                                    defaultMessage="User Time Zone"
                                />
                            }
                        />
                        <Radio
                            value={TIME_ZONE_METHODS.SPECIFIC_TIME_ZONE}
                            title={
                                <FormattedMessage
                                    id="campaign.time.zone.as.specific.time.zone"
                                    defaultMessage="Specific Time Zone"
                                />
                            }
                        />
                        {selectedTimeZoneMethod !== TIME_ZONE_METHODS.USER_TIME_ZONE && (
                            <div className={styles['time-zone-dropdown']}>
                                <TimezoneDropdown
                                    selectedTimezone={selectedTimeZone}
                                    timezones={listOfTimeZones}
                                    onChange={({ value }) => {
                                        onTimeZoneChange(value);
                                        setSelectedTimeZone(value);
                                    }}
                                    helpText={<TimezoneDropdownTooltip />}
                                />
                            </div>
                        )}
                    </RadioGroup>
                ) : (
                    <div className={styles['time-zone-dropdown']}>
                        <TimezoneDropdown
                            selectedTimezone={selectedTimeZone}
                            timezones={listOfTimeZones}
                            onChange={({ value }) => {
                                onTimeZoneChange(value);
                                setSelectedTimeZone(value);
                            }}
                            helpText={<TimezoneDropdownTooltip />}
                        />
                    </div>
                ))}
        </div>
    );
};
