import { isEmpty } from 'lodash';
import { navigate } from '../../../../../actions';
import getChangesObject from '../../../../taboola-common-frontend-modules/utils/objectDiff';
import { saveCreativeError } from '../../../actions';
import { saveCreative as campaignsSaveCreative } from '../../../flows';
import { editedCreativeSelector } from '../../../selectors';
import { isMediaLibraryItem } from '../components/MediaTabs/services/utils';
import saveFocalPoints from './saveFocalPoints';
import uploadCreativeRecommendedImage from './uploadCreativeRecommendedImage';
import uploadCreativeThumbnail from './uploadCreativeThumbnail';

const getThumbnailUrl = async (dispatch, accountId, initialCreativeChanges) => {
    const { thumbnailUrl, thumbnailFile, thumbnailRecommendedImage } = initialCreativeChanges;

    if (thumbnailFile) {
        return await dispatch(uploadCreativeThumbnail(accountId, thumbnailFile));
    } else if (thumbnailRecommendedImage) {
        return isMediaLibraryItem(thumbnailRecommendedImage)
            ? thumbnailRecommendedImage
            : await dispatch(uploadCreativeRecommendedImage(accountId, thumbnailRecommendedImage));
    }

    return { thumbnailUrl };
};

const updateCreativeChanges = (initialCreativeChanges, thumbnailUrl) => {
    const { thumbnailFile, thumbnailRecommendedImage, ...creativeChanges } = initialCreativeChanges;

    return thumbnailUrl ? { ...creativeChanges, thumbnailUrl } : creativeChanges;
};

const saveCreative =
    ({ accountId }, creative, matchingCreativeIds = [], history, sourcePath) =>
    async (dispatch, getState) => {
        const creativeBeingEdited = editedCreativeSelector(getState());
        const initialCreativeChanges = getChangesObject(creative, creativeBeingEdited);
        //TODO remove this in DEV-114778
        const goBack = () => (sourcePath ? dispatch(navigate(history, sourcePath)) : null);

        // If no changes, do nothing.
        if (isEmpty(initialCreativeChanges)) {
            goBack();
            return { result: creativeBeingEdited };
        }

        // Else, try to do partial update. Upload thumbnail and compute creative changes.
        const { thumbnailUrl, error: uploadError } = await getThumbnailUrl(dispatch, accountId, initialCreativeChanges);
        if (uploadError) {
            dispatch(saveCreativeError(creative.id, uploadError));
            return { error: uploadError };
        }
        const creativeChanges = updateCreativeChanges(initialCreativeChanges, thumbnailUrl);

        // If creative focus changed and matching creatives are being passed in, update their focal points.
        const { error: focalPointsSaveError } = await dispatch(
            saveFocalPoints(creative, accountId, matchingCreativeIds)
        );
        if (focalPointsSaveError) {
            dispatch(saveCreativeError(creative.id, focalPointsSaveError));
            return { focalPointsSaveError };
        }

        // Finally, update the creative.
        const { result, error: saveError } = await dispatch(
            campaignsSaveCreative(accountId, creativeBeingEdited, creativeChanges)
        );
        if (saveError) {
            throw saveError;
        }

        goBack();
        return { result };
    };

export default saveCreative;
