'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useEventListener = undefined;

var _react = require('react');

// Borrowed from https://usehooks.com/useEventListener/
var useEventListener = exports.useEventListener = function useEventListener(eventName, handler) {
    var element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window;

    var eventHandler = (0, _react.useRef)(handler);
    eventHandler.current = handler;

    (0, _react.useLayoutEffect)(function () {
        var eventListener = function eventListener(event) {
            return eventHandler.current(event);
        };

        element.addEventListener(eventName, eventListener);
        return function () {
            return element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};