import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const ConversionSetupTypeFieldTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.setup.type.tooltip.URL"
                defaultMessage="URL Based: Track every time someone visits a website, a section of a website, or certain web pages as a conversion."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="tracking.conversion.setup.type.tooltip.EVENT"
                defaultMessage="Event Based: Track every time someone completes a specific action as a conversion, such as subscribing to newsletter or purchasing an item."
            />
        </TooltipSection>
    </>
);
