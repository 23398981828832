"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgUnarchive(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor",
            xmlns: "http://www.w3.org/2000/svg"
        }, props),
        React.createElement("path", {
            d: "M7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5H17C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3H7Z",
            fill: "#667686"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M18 7H6C4.89543 7 4 7.89543 4 9V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V9C20 7.89543 19.1046 7 18 7ZM13 13.5806L13.8753 14.2809C14.3066 14.6259 14.9359 14.556 15.2809 14.1247C15.6259 13.6934 15.556 13.0641 15.1247 12.7191L12 10.2194L8.87531 12.7191C8.44404 13.0641 8.37412 13.6934 8.71913 14.1247C9.06414 14.556 9.69343 14.6259 10.1247 14.2809L11 13.5806V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13.5806Z",
            fill: "#667686"
        })
    );
}
exports.default = SvgUnarchive;