import React, { useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { FormField } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData/hooks';
import { BidInputList } from '../BidInputList';
import { useFormBidValidatedValue } from '../useFormBidValidatedValue';
import SubTextCPC from './SubTextCPC';
import styles from '../../commonEditor.module.scss';

export const BidField = ({ label, description, helpText, dataMetricsComponent, hasHighImpactPackage = false }) => {
    const [bidValues, setBidValues] = useState({});
    const {
        value: pricingModel,
        onChange: baseOnPricingModelChange,
        mode,
    } = useFormFieldValue({
        field: 'pricingModel',
    });

    const {
        value: bid = '',
        onChange: baseOnBidChange,
        failedValidationData: failedBidValidationData,
        resetFieldToInitial: resetBid,
        scrollRef,
    } = useFormBidValidatedValue({ pricingModel });

    const onPricingModelChange = value => {
        baseOnPricingModelChange(value);
        const bidValue = bidValues[value];
        baseOnBidChange(bidValue);
        if (isNil(bidValue) || bidValue === '') {
            resetBid();
        }
    };
    const onBidChange = value => {
        baseOnBidChange(value);
        setBidValues(prev => ({ ...prev, [pricingModel]: value }));
    };
    const hasError = !isEmpty(failedBidValidationData);
    return (
        <FormField
            inputId="bid"
            label={label}
            description={description}
            helpText={helpText}
            containerClass={styles['input']}
            subtext={<SubTextCPC hasError={hasError} />}
            data-metrics-component={dataMetricsComponent}
            ref={scrollRef}
        >
            <BidInputList
                mode={mode}
                pricingModel={pricingModel}
                onPricingModelChange={onPricingModelChange}
                bid={bid}
                onBidChange={onBidChange}
                hasHighImpactPackage={hasHighImpactPackage}
                failedValidationData={failedBidValidationData}
            />
        </FormField>
    );
};
