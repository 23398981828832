import React from 'react';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import { RssFields } from '../index';

export const RssSetupSection = () => {
    const { value: rssData, onChange: setRssData } = useFormFieldValue({ field: 'rssData' });

    const updateRssUrls = urls => setRssData(prevRssData => ({ ...prevRssData, urls }));
    const updateRssValidatedUrls = validatedUrls => setRssData(prevRssData => ({ ...prevRssData, validatedUrls }));

    return <RssFields urls={rssData.urls} updateUrls={updateRssUrls} updateValidatedUrls={updateRssValidatedUrls} />;
};
