import { useCallback } from 'react';
import { uniqueId, isEmpty } from 'lodash';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { conversionSetupUrlConditionValidations } from '../validations/conversionSetupUrlConditionValidations';

const generateUniqueId = () => {
    return uniqueId('condition');
};

export const useConversionSetupUrlConditions = (predicate, url, setUrl) => {
    const {
        value: condition,
        onChange: onConditionsChange,
        indicationData,
        scrollRef,
    } = useFormValidatedValue({
        field: 'condition',
        validations: conversionSetupUrlConditionValidations,
    });

    const handleAddCondition = useCallback(() => {
        if (!isEmpty(url)) {
            const conditionToAdd = {
                id: generateUniqueId(),
                predicate,
                url: url?.trim(),
            };

            onConditionsChange([...condition, conditionToAdd]);
            setUrl('');
        }
    }, [predicate, url, setUrl, onConditionsChange, condition]);

    const handleRemoveCondition = useCallback(
        ({ id: idToRemove }) => {
            onConditionsChange(condition => condition.filter(({ id }) => id !== idToRemove));
        },
        [onConditionsChange]
    );

    const handleRemoveAllConditions = useCallback(() => onConditionsChange([]), [onConditionsChange]);

    return {
        condition,
        handleAddCondition,
        handleRemoveCondition,
        handleRemoveAllConditions,
        indicationData,
        scrollRef,
    };
};
