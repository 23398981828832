import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { OfflineBoltOutlinedIcon } from 'tuui';
import { Button } from 'tuui';
import { FORM_MODES } from 'config/formModes';
import { useNavigate } from 'hooks';
import { useCampaignRecommendation } from 'modules/campaigns/modules/performance-recommendations/hooks/useCampaignRecommendation';
import usePerformanceRecommendationExecution from 'modules/campaigns/modules/performance-recommendations/hooks/usePerformanceRecommendationExecution';
import { transformMarketplaceAudienceTargetingFromGW } from 'modules/campaigns/services/transformers/marketplaceAudienceTargetingTransformer';
import { generateCampaignEditorPath } from 'modules/campaigns/services/utils';
import { setRecommendations } from 'modules/recommendations';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { useRecommendationActionParam } from '../../hooks/useRecommendationActionParam';
import { ActionRecommendationButton } from '../Buttons/ActionRecommendationButton/ActionRecommendationButton';
import ButtonsSection from '../ButtonsSection/ButtonsSection';
import DismissSection from '../DismissSection/DismissSection';
import PerformanceRecommendationCard from '../PerformanceRecommendationCard/PerformanceRecommendationCard';

export const AudienceRecommendation = ({ recommendation }) => {
    const { actions = [], id: recommendationId, messageParams, type: recommendationType } = recommendation;
    const { segments_count: segmentCount } = messageParams;
    const { accountId, campaignId } = useCampaignRecommendation({ recommendation });
    const { recommendationData: actionParamData } = useRecommendationActionParam(recommendation);
    const { formatNumber } = useIntl();

    const recommendationData = actionParamData
        ? transformMarketplaceAudienceTargetingFromGW(actionParamData)
        : undefined;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateToDuplicateCampaign = useCallback(() => {
        dispatch(
            setRecommendations({
                recommendationType,
                recommendationId,
                recommendationData,
            })
        );
        navigate(generateCampaignEditorPath({ accountId, campaignId, mode: FORM_MODES.DUPLICATE }));
    }, [accountId, campaignId, dispatch, navigate, recommendationId, recommendationType, recommendationData]);
    const { apply, dismiss } = usePerformanceRecommendationExecution({
        recommendation,
        applyParams: {
            accountId,
            campaignId,
            recommendationId,
            actionId: actions.actionId,
        },
        dismissParams: {
            recommendationId,
            accountId,
            campaignId,
        },
        errorCode: 'performance-recommendations.general-recommendation.error-message',
    });
    const messageDetails = useMemo(() => {
        return {
            ...messageParams,
            audiencesNum: formatNumber(segmentCount),
        };
    }, [messageParams, segmentCount, formatNumber]);

    return (
        <PerformanceRecommendationCard
            recommendationId={recommendationId}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.audience-targeting.title"
            descriptionCode="performance-recommendations.audience-targeting.description"
            messageParams={messageDetails}
            iconImg={OfflineBoltOutlinedIcon}
        >
            <ButtonsSection>
                <DismissSection
                    dismissCode="performance-recommendations.set-cpa.dismiss"
                    successCode="performance-recommendations.general-recommendation.success-message"
                    isApplied={apply.isSuccess}
                    dismissInvoker={dismiss.invoke}
                    recommendation={recommendation}
                />
                <ActionRecommendationButton
                    onClick={navigateToDuplicateCampaign}
                    recommendation={recommendation}
                    textCode="performance-recommendations.audience-targeting.duplicate-campaign.button"
                    dataMetricsComponent="RecommendationRibbonDuplicateCampaignButton"
                    defaultMessage="Duplicate Campaign"
                    variant={Button.variant.primary}
                    disabled={apply.isSuccess}
                />
            </ButtonsSection>
        </PerformanceRecommendationCard>
    );
};

AudienceRecommendation.propTypes = {
    recommendation: PropTypes.object,
};
