import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import TooltipSection from '../../TooltipSection';

const SpendingLimitTooltip = ({ entityTypeMessagePrefix }) => (
    <div>
        <TooltipSection>
            <FormattedMessage
                id="campaign.editor.spending.limit.help.overview"
                defaultMessage="<i>In addition to</i> your <strong>Daily Budget</strong>, you may set a <strong>Spending Limit:</strong>"
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage id={`${entityTypeMessagePrefix}.editor.spending.limit.help.none`} defaultMessage=" " />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id={`${entityTypeMessagePrefix}.editor.spending.limit.help.monthly`}
                defaultMessage="<strong>Monthly Limit:</strong> The <i>monthly</i> spending limit for this campaign. The budget resets at the start of each calendar month."
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id={`${entityTypeMessagePrefix}.editor.spending.limit.help.lifetime`}
                defaultMessage="<strong>Lifetime Limit:</strong> The <i>overall</i> spending limit for this campaign. The campaign stops when this limit is reached, or when the campaign reaches its end date (whichever comes first)."
            />
        </TooltipSection>
    </div>
);

SpendingLimitTooltip.propTypes = {
    entityTypeMessagePrefix: PropTypes.string,
};

SpendingLimitTooltip.defaultProps = {
    entityTypeMessagePrefix: 'campaign',
};

export default SpendingLimitTooltip;
