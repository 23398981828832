import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useAudiencesApi } from '../../../../services/api';

const EMPTY_ARRAY = [];

export const useSuggestionsApi = ({ selectedValues, apiFunctionName }) => {
    const queryKey = useMemo(() => [apiFunctionName, selectedValues], [apiFunctionName, selectedValues]);
    const params = useMemo(
        () => ({
            selectedValues,
        }),
        [selectedValues]
    );
    const { [apiFunctionName]: apiCall } = useAudiencesApi();
    const { isFetching: isLoadingSuggestions, data: suggestionsFromApi = EMPTY_ARRAY } = useQuery(
        queryKey,
        () => apiCall(params),
        {
            select: ({ values }) => values.map(value => ({ label: value, id: value, value })),
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            enabled: !isEmpty(selectedValues),
        }
    );

    return { suggestionsFromApi: !isEmpty(selectedValues) ? suggestionsFromApi : EMPTY_ARRAY, isLoadingSuggestions };
};
