import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tuui';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './cancelCreationButton.module.scss';

const CancelCreationButton = ({ onClick }) => {
    return (
        <div className={styles['container']}>
            <Button
                onClick={onClick}
                variant={Button.variant.ghost}
                data-metrics-event-name={GTM_EVENTS.USABILITY}
                data-metrics-component="CancelCreationButton"
            >
                <FormattedMessage
                    id="creative.creator.creation.mode.ONE_BY_ONE.loading.overlay.button"
                    defaultMessage="Cancel Creation"
                />
            </Button>
        </div>
    );
};

CancelCreationButton.propTypes = {
    onClick: PropTypes.func,
};

export default CancelCreationButton;
