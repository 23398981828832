import { useMemo } from 'react';
import { FEATURE_FLAGS, useAccountConfig } from '../../../../../taboola-common-frontend-modules/account-configurations';

export const useCpmAccountConfig = () => {
    const { cpmMinValue, cpmMaxValue, cpmMinWarn, cpmMaxWarn, cpmDataMinValue, cpmDataMaxValue } = useAccountConfig([
        FEATURE_FLAGS.ACCOUNT_CPM_MIN_VALUE,
        FEATURE_FLAGS.ACCOUNT_CPM_MAX_VALUE,
        FEATURE_FLAGS.ACCOUNT_CPM_MIN_WARN,
        FEATURE_FLAGS.ACCOUNT_CPM_MAX_WARN,
        FEATURE_FLAGS.ACCOUNT_CPM_DATA_MIN_VALUE,
        FEATURE_FLAGS.ACCOUNT_CPM_DATA_MAX_VALUE,
    ]);
    return useMemo(
        () => ({
            cpmMinValue,
            cpmMaxValue,
            cpmMinWarn,
            cpmMaxWarn,
            cpmDataMinValue,
            cpmDataMaxValue,
        }),
        [cpmMinValue, cpmMaxValue, cpmMinWarn, cpmMaxWarn, cpmDataMinValue, cpmDataMaxValue]
    );
};
