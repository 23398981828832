import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';

const reportId = reportsBaseConfig[REPORT_TYPE.CAMPAIGN_VIDEO].id;

const defaultColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.NAME.field,
    FIELDS.STATUS.field,
    FIELDS.VIDEO_MODEL_TYPE.field,
    FIELDS.SPENT.field,
    FIELDS.VIDEO_BUDGET.field,
    FIELDS.COMPLETED_VIEWS.field,
    FIELDS.CPCV.field,
    FIELDS.CLICKS.field,
    FIELDS.CTR.field,
    FIELDS.CPA.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

const customColumns = {
    [REPORT_PRESET.PERFORMANCE]: [
        FIELDS.ID.field,
        FIELDS.VIDEO_MODEL_TYPE.field,
        FIELDS.SPENT.field,
        FIELDS.VIDEO_BUDGET.field,
        FIELDS.COMPLETED_VIEWS.field,
        FIELDS.IMPRESSIONS.field,
        FIELDS.VISIBLE_IMPRESSIONS.field,
        FIELDS.CPM.field,
        FIELDS.VCPM.field,
        FIELDS.CPCV.field,
        FIELDS.VIEWABILITY_RATE.field,
        FIELDS.HUNDRED_PCT_VIEWABILITY_RATE.field,
        FIELDS.COMPLETION_RATE.field,
        FIELDS.CLICKS.field,
        FIELDS.CTR.field,
        FIELDS.VCTR.field,
        FIELDS.CPA.field,
        FIELDS.START_DATE.field,
        FIELDS.END_DATE.field,
        FIELDS.VIDEO_CVR.field,
        FIELDS.CONVERSIONS.field,
        FIELDS.UNIQUE_IMPRESSIONS.field,
        FIELDS.UNIQUE_VIEWABLE_IMPRESSIONS.field,
        FIELDS.UNIQUE_COMPLETED_VIEWS.field,
    ].map(field => generateColumnId(reportId, field)),
};

const setupColumns = [
    FIELDS.IS_ACTIVE.field,
    FIELDS.NAME.field,
    FIELDS.STATUS.field,
    FIELDS.ID.field,
    FIELDS.VIDEO_MODEL_TYPE.field,
    FIELDS.VIDEO_BUDGET.field,
    FIELDS.START_DATE.field,
    FIELDS.END_DATE.field,
    FIELDS.ACTIONS_COLUMN.field,
].map(field => generateColumnId(reportId, field));

export { customColumns, defaultColumns, setupColumns };
