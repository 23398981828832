import { PERMISSIONS_OPERATORS } from '../constants';

/* checks if array of permissions satisfies the operator
    e.g
        checkPermissions([X, Y, true], PERMISSIONS_OPERATORS.OR, { X: 'X' })
        ===
        (X || Y || true)
*/
const checkPermissions = (permissionArr = [], operator = PERMISSIONS_OPERATORS.AND, allUserPermissions = {}) =>
    permissionArr[operator](permission => permission === true || allUserPermissions[permission]);

// recursive function that iterates through the nested permissions and checks if the permissions satisfy the operator
const parseRecursivePermissions = (permission, allUserPermissions) => {
    const { permissions: permissionsArr, operator } = permission;

    if (!permissionsArr) {
        return permission;
    }

    const flattenedPermissions = permissionsArr.map(perm => parseRecursivePermissions(perm, allUserPermissions));

    return checkPermissions(flattenedPermissions, operator, allUserPermissions);
};

export default parseRecursivePermissions;
