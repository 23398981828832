"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDragAndDrop(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            fill: "currentColor",
            viewBox: "0 0 24 24"
        }, props),
        React.createElement("path", {
            fill: "currentColor",
            d: "M9.5 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM14.5 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM14.5 11a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM13 17.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8 12.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM9.5 16a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        })
    );
}
exports.default = SvgDragAndDrop;