import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useCurrentValueGetter } from 'hooks';
import { useFormValidatedValue } from 'modules/taboola-common-frontend-modules/validations';
import { CustomValidationError } from '../../../../errors/CustomValidationError';

const internalValidations = [
    {
        validationFn: (value, _, { uploadError }) => {
            if (uploadError) {
                throw new CustomValidationError({
                    messageCode: 'app.creative.file.upload.error',
                    message: 'File upload error.',
                    templateParameters: { details: uploadError.message },
                });
            }
            return true;
        },
    },
    {
        validationFn: value => !value?.file || !!value.uploadedData,
        messageId: 'app.creative.file.loading.in.progress.error',
        defaultMessage: 'File is still being loaded...',
        events: 'only_submit',
    },
];
const queryKeyPrefix = 'fileFieldUpload';
const getFileHash = file => {
    if (!file) {
        return '';
    }
    return `${file.name}${file.size}${file.type}${file.lastModified}`;
};

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const CACHE_TIME = 30 * 60 * 1000;
export const useFileFieldValue = ({
    field,
    validations: externalValidations = EMPTY_ARRAY,
    fileUploader,
    dependencies = EMPTY_ARRAY,
    validationDependencies: externalValidationDependencies = EMPTY_OBJECT,
}) => {
    const [uploadError, setUploadError] = useState();
    const validations = useMemo(() => [...internalValidations, ...externalValidations], [externalValidations]);
    const validationDependencies = useMemo(
        () => ({ uploadError, ...externalValidationDependencies }),
        [uploadError, externalValidationDependencies]
    );
    const { value, onChange, isDirty, isValid, ...rest } = useFormValidatedValue({
        field,
        validations,
        validationDependencies,
    });
    const enableRef = useRef(false);
    enableRef.current = enableRef.current || (!!value?.file && isValid && isDirty);

    const onDrop = acceptedFiles => {
        enableRef.current = false;
        setUploadError(null);
        onChange({ file: acceptedFiles[0], uploadedData: null });
    };
    const hash = getFileHash(value?.file);
    const { data, isSuccess, isLoading } = useQuery(
        [queryKeyPrefix, field, hash, ...dependencies],
        () => fileUploader({ file: value.file }),
        {
            enabled: enableRef.current,
            onError: error => setUploadError(error),
            staleTime: CACHE_TIME,
            cacheTime: CACHE_TIME,
        }
    );
    const queryClient = useQueryClient();
    const cleanValue = useCallback(() => {
        enableRef.current = false;
        setUploadError(null);
        onChange({});
        queryClient.cancelQueries([queryKeyPrefix, field]);
    }, [onChange, queryClient, field]);
    const dataGetter = useCurrentValueGetter(data);
    const cleanCache = useCallback(() => {
        queryClient.removeQueries([queryKeyPrefix, field]);
    }, [queryClient, field]);

    // Once file is loaded we set it into form context
    useEffect(() => {
        if (!isSuccess) {
            return;
        }
        onChange(prev => ({ ...prev, uploadedData: dataGetter() }));
    }, [dataGetter, isSuccess, onChange]);

    return {
        isLoading,
        onDrop,
        value,
        cleanValue,
        cleanCache,
        isValid,
        isDirty,
        ...rest,
    };
};
