import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { allCampaignsNameIdSelector } from '../../../../../../selectors';
import ReportBreadcrumbs from '../../../../components/ReportBreadcrumbs/ReportBreadcrumbs';
import { AllCampaignsObject } from '../../../../config';
import { useOpenCampaignEditor } from '../../../../hooks';
import useCampaignBreadcrumbs from '../../../../hooks/useCampaignBreadcrumbs';
import { selectedCampaignSelector } from '../../../../selectors';

export const CampaignReportBreadcrumbs = () => {
    const { formatMessage } = useIntl();
    const { id, name } = useSelector(selectedCampaignSelector);
    const allCampaignsNameId = useSelector(allCampaignsNameIdSelector);
    const title =
        id === AllCampaignsObject.id ? formatMessage({ id: allCampaignsNameId, defaultMessage: name }) : name || '';
    const onEditCampaign = useOpenCampaignEditor();
    const { steps, shouldRenderEdit, handleEdit } = useCampaignBreadcrumbs({
        title,
        onEditCampaign,
        isTitleAsLastStep: id !== AllCampaignsObject.id,
    });

    return (
        <ReportBreadcrumbs title={title} steps={steps} shouldRenderEdit={shouldRenderEdit} handleEdit={handleEdit} />
    );
};

export default CampaignReportBreadcrumbs;
