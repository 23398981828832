import React from 'react';
import classnames from 'classnames/bind';
import { DeleteOutlineIcon, KeyboardArrowUpIcon } from 'tuui';
import { Dotdotdot } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import Status from 'modules/campaigns/modules/common-campaign-form/components/Status/Status';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { MediaPreview } from '../MediaPreview/MediaPreview';
import commonStyles from '../CarouserlCard/CarouselCard.module.scss';
import styles from './CarouselCardPreview.module.scss';

const classNameBuilder = classnames.bind(styles);

export const CarouselCardPreview = ({ dragContent, toggleCollapsed, deleteCard, thumbnail, readOnly, isHovered }) => {
    const { mode } = useFormDataContext();
    const isEditMode = mode === FORM_MODES.EDIT;
    const { value: title = <FormattedMessage id="creative.creator.carousel.cards.title.preview.placeholder" /> } =
        useFormFieldValue({ field: 'title' });
    const {
        value: description = <FormattedMessage id="creative.creator.carousel.cards.description.preview.placeholder" />,
    } = useFormFieldValue({ field: 'description' });
    const { value: url = <FormattedMessage id="creative.creator.carousel.cards.url.preview.placeholder" /> } =
        useFormFieldValue({ field: 'url' });
    const { value: isActive, onChange: setIsActive } = useFormFieldValue({ field: 'isActive' });
    const handleToggleCollapsed = () => {
        if (!readOnly) {
            toggleCollapsed();
        }
    };
    const isDeleteButtonVisible = (isHovered && isEditMode) || !readOnly;

    return (
        <>
            <div className={styles['drag-wrapper']}>{dragContent}</div>
            <MediaPreview thumbnail={thumbnail} />
            <div
                className={classNameBuilder('content', { 'hidden-pointer': !readOnly })}
                onClick={handleToggleCollapsed}
            >
                <Dotdotdot lines={1} className={styles['title']}>
                    {title || ''}
                </Dotdotdot>
                <Dotdotdot lines={1} className={styles['description']}>
                    {description || ''}
                </Dotdotdot>
                <Dotdotdot lines={1} className={styles['url']}>
                    {url || ''}
                </Dotdotdot>
            </div>
            {isDeleteButtonVisible && (
                <div className={styles['icon-wrapper']}>
                    <DeleteOutlineIcon
                        className={commonStyles['icon']}
                        onClick={deleteCard}
                        role="button"
                        aria-label="Delete Card"
                    />
                </div>
            )}
            {isEditMode && (
                <div className={classNameBuilder('is-active-toggle', 'icon-wrapper')}>
                    <Status isActive={isActive} onChange={setIsActive} />
                </div>
            )}
            {!readOnly && (
                <div className={styles['icon-wrapper']}>
                    <KeyboardArrowUpIcon
                        className={commonStyles['icon']}
                        onClick={toggleCollapsed}
                        role="button"
                        aria-label="Toggle Card"
                    />
                </div>
            )}
        </>
    );
};
