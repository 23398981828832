import React from 'react';
import classnames from 'classnames/bind';
import { Button, RedoIcon, UndoIcon } from 'tuui';
import { useCreativeStudioContext } from 'modules/campaigns/modules/creative-editor/components/MediaTabs/CreativeStudio/providers/CreativeStudioContext';
import { GTM_EVENTS, gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './historyButtons.module.scss';

const classNameBuilder = classnames.bind(styles);

export const HistoryButtons = ({ hasUndo, undo, hasRedo, redo, resetHistory, gtmEventPrefix }) => {
    const { campaignId } = useCreativeStudioContext();

    return (
        <div className={styles['container']}>
            <Button
                variant={Button.variant.text}
                disabled={!hasUndo}
                className={styles['history-button-container']}
                aria-label="Undo Button"
                onClick={() => {
                    undo();
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${gtmEventPrefix}: Undo Clicked`,
                        taboolaCampaignId: campaignId,
                    });
                }}
            >
                <UndoIcon className={classNameBuilder('history-button', { inactive: !hasUndo })} />
            </Button>
            <Button
                variant={Button.variant.text}
                disabled={!hasRedo}
                aria-label="Redo Button"
                onClick={() => {
                    redo();
                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${gtmEventPrefix}: Redo Clicked`,
                        taboolaCampaignId: campaignId,
                    });
                }}
                className={styles['history-button-container']}
            >
                <RedoIcon className={classNameBuilder('history-button', { inactive: !hasRedo })} />
            </Button>

            <Button
                variant={Button.variant.text}
                aria-label="Reset Button"
                onClick={() => {
                    resetHistory();

                    gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
                        component: `${gtmEventPrefix}: Reset Clicked`,
                        taboolaCampaignId: campaignId,
                    });
                }}
            >
                <FormattedMessage
                    id="creative.studio.replace.background.segmentAnythingStage.reset.button"
                    defaultMessage="Reset"
                />
            </Button>
        </div>
    );
};
