import React from 'react';
import { ExternalLink } from 'components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';

export const ContentFieldSubDescription = () => {
    const { formatMessage } = useIntl();
    const href = formatMessage({ id: 'creative.creator.titles.description.link.href' });
    const policyLink = (
        <ExternalLink href={href}>
            <FormattedMessage id="creative.creator.titles.description.link" defaultMessage="Policy" />
        </ExternalLink>
    );

    return (
        <FormattedMessage
            id="creative.creator.titles.sub.description"
            defaultMessage="Make sure that titles conform to our {policyLink}."
            values={{ policyLink }}
        />
    );
};

export default ContentFieldSubDescription;
