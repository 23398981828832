import React from 'react';
import ContentLoader from 'react-content-loader';
import { ImageContentLoader } from 'taboola-ultimate-ui';
import styles from './thumbnailEditorBaseLoader.module.scss';

const ThumbnailEditorBaseLoader = () => (
    <div className={styles['container']} role="progressbar">
        <ImageContentLoader className={styles['image']} />
        <ContentLoader width={600} height={60} speed={2} className={styles['under-image']}>
            <rect x="0" y="0" rx="6" ry="6" width="600" height="60" />
        </ContentLoader>
    </div>
);

export default ThumbnailEditorBaseLoader;
