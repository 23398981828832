import React, { useCallback } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelectedAccount } from '../../../hooks';
import { NOTIFICATION_ICONS } from '../config/NotificationIcons';
import { useNotificationsActions } from '../hooks/useNotificationsActions';
import { useNotificationsService } from '../hooks/useNotificationsService';
import { NotificationCardContent } from './components/NotificationCardContent/NotificationCardContent';
import { NotificationCardStateSection } from './components/NotificationCardStateSection/NotificationCardStateSection';
import styles from './notificationCard.module.scss';

const classNameBuilder = classnames.bind(styles);
export const NotificationCard = ({
    notification,
    cardClick,
    cardClickParams = {},
    closeNotificationCenter,
    formattedMessageId,
    formattedMessageValues,
    descriptionCodeSuffix,
}) => {
    const { id, type } = notification;
    const Icon = NOTIFICATION_ICONS[type];

    const { removeNotification, getNotificationStatus } = useNotificationsService();

    const { isNew, isInactive } = getNotificationStatus(notification);

    const [{ accountName }] = useSelectedAccount();

    const { onCardClick, onDismiss } = useNotificationsActions({
        onCardClickSuccess: cardClick,
        cardClickParams: {
            ...cardClickParams,
            accountName,
            notificationIds: [id],
        },
        dismissParams: {
            accountName,
            notificationIds: [id],
            errorCode: 'app.notificationsCenter.dismiss.error-message',
        },
    });

    const dismissNotificationCallback = useCallback(
        event => {
            onDismiss();
            removeNotification(id);
            event.stopPropagation();
        },
        [onDismiss, removeNotification, id]
    );

    const cardClickCallback = useCallback(
        event => {
            if (isInactive) {
                return;
            }

            onCardClick();
            closeNotificationCenter();
            event.stopPropagation();
        },
        [onCardClick, closeNotificationCenter, isInactive]
    );

    return (
        <div
            className={classNameBuilder('notification-card', { 'is-inactive': isInactive })}
            onClick={cardClickCallback}
        >
            <Icon isInactive={isInactive} />
            {formattedMessageValues && (
                <NotificationCardContent
                    notification={notification}
                    dataMetricsComponent="NotificationAlertCard"
                    formattedMessageId={formattedMessageId}
                    formattedMessageValues={formattedMessageValues}
                    descriptionCodeSuffix={descriptionCodeSuffix}
                />
            )}
            <NotificationCardStateSection
                isNew={isNew}
                isInactive={isInactive}
                dismissNotificationCallback={dismissNotificationCallback}
            />
        </div>
    );
};

NotificationCard.propTypes = { notification: PropTypes.object };
