import React from 'react';
import { Redirect, Switch } from 'react-router';
import PropTypes from 'prop-types';
import { CAMPAIGN_EDITOR_ROUTE_PATH, CAMPAIGNS_ROUTE } from 'modules/campaigns/config';
import { replacePathParams } from 'modules/campaigns/services/utils';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { ENTITY } from '../config';
import useUnsavedChangesController from '../hooks/useUnsavedChangesController';
import { OpenExchangeCampaignEditor } from '../modules/open-exchange-campaign-editor';
import { PmpDealEditor } from '../modules/pmp-deal-editor';
import { SponsoredCampaignEditor } from '../modules/sponsored-campaign-editor';
import { VideoCampaignEditor } from '../modules/video-campaigns-form/forms/video-campaign-editor';
import { VideoPMPDealEditor } from '../modules/video-campaigns-form/forms/video-pmp-deal-editor';

const path = CAMPAIGN_EDITOR_ROUTE_PATH;
export const CampaignEditorPage = ({ onCancel, drawerMode }) => {
    const sponsoredPath = replacePathParams(path, { entity: [ENTITY.CAMPAIGN] });
    const videoPath = replacePathParams(path, { entity: [ENTITY.CAMPAIGN_VIDEO] });
    const openExchangePath = replacePathParams(path, { entity: [ENTITY.RTB_OPEN_EXCHANGE] });
    const pmpDealPath = replacePathParams(path, { entity: [ENTITY.PMP_DEAL] });
    const videoPmpDealPath = replacePathParams(path, { entity: [ENTITY.VIDEO_PMP_DEAL] });

    useUnsavedChangesController(CAMPAIGN_EDITOR_ROUTE_PATH);

    return (
        <Switch>
            <LayerRoute
                exact
                path={sponsoredPath}
                render={props => <SponsoredCampaignEditor {...props} onCancel={onCancel} />}
                drawerMode={drawerMode}
            />
            <LayerRoute
                exact
                path={openExchangePath}
                render={props => <OpenExchangeCampaignEditor {...props} onCancel={onCancel} />}
                drawerMode={drawerMode}
            />
            <LayerRoute
                exact
                path={pmpDealPath}
                render={props => <PmpDealEditor {...props} onCancel={onCancel} />}
                drawerMode={drawerMode}
            />
            <LayerRoute
                exact
                path={videoPath}
                render={props => <VideoCampaignEditor {...props} onCancel={onCancel} />}
                drawerMode={drawerMode}
            />
            <LayerRoute
                exact
                path={videoPmpDealPath}
                render={props => <VideoPMPDealEditor {...props} onCancel={onCancel} />}
                drawerMode={drawerMode}
            />
            <Redirect to={CAMPAIGNS_ROUTE} />
        </Switch>
    );
};

CampaignEditorPage.propTypes = { onCancel: PropTypes.func };

export default CampaignEditorPage;
