import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { EditableText } from 'taboola-ultimate-ui';
import { withIndication } from 'modules/errors/components/withIndication';
import styles from './outlineEditableText.module.scss';

const classNameBuilder = classnames.bind(styles);

const OutlineEditableText = ({ className, children, ...rest }) => {
    const { scrollAreaRef: _, ...restWithoutProp } = rest;
    return (
        <EditableText
            className={classNameBuilder('input-container', className)}
            useInternalContainer
            {...restWithoutProp}
        >
            {children}
        </EditableText>
    );
};

OutlineEditableText.propTypes = {
    className: PropTypes.string,
    ...EditableText.propTypes,
};

export { OutlineEditableText };
export default withIndication(OutlineEditableText);
