import React from 'react';
import CommonCollapsibleCard from '../../../../components/CommonCollapsibleCard/CommonCollapsibleCard';
import SectionHeader from '../../../campaigns/components/Form/SectionHeader/SectionHeader';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { PaymentMethodCity } from '../PaymentMethodCity/PaymentMethodCity';
import { PaymentMethodCountry } from '../PaymentMethodCountry/PaymentMethodCountry';
import { PaymentMethodRegion } from '../PaymentMethodRegion/PaymentMethodRegion';
import { PaymentMethodStreet } from '../PaymentMethodStreet/PaymentMethodStreet';
import { PaymentMethodTaxSection } from '../PaymentMethodTaxSection/PaymentMethodTaxSection';
import { PaymentMethodZipCode } from '../PaymentMethodZipCode/PaymentMethodZipCode';

export const PaymentMethodAddressSection = () => {
    return (
        <CommonCollapsibleCard
            id="BILLING_ADDRESS"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="paymentMethod.creator.CARD.billingAddress"
                            defaultMessage="Billing Address"
                        />
                    }
                />
            }
        >
            <PaymentMethodStreet />
            <PaymentMethodCountry />
            <PaymentMethodRegion />
            <PaymentMethodCity />
            <PaymentMethodZipCode />
            <PaymentMethodTaxSection />
        </CommonCollapsibleCard>
    );
};
