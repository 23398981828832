import { noop } from 'lodash';

const createGridValueFormatter =
    valueFormatter =>
    ({
        data,
        colDef: { field: fieldName, cellRendererParams: formatOptions },
        context: { intl, currency, timeZoneName } = {
            intl: {
                formatMessage: noop,
                formatNumber: noop,
            },
        },
    }) =>
        valueFormatter({
            value: data[fieldName],
            rowData: data,
            formatOptions,
            i18n: intl,
            currency,
            timeZoneName,
        });

export default createGridValueFormatter;
