import moment from 'moment-timezone';

export const transformFromGW = ({ expMonth, expYear, ...data }) => {
    const expDate = moment(`${expMonth}/${expYear}`, 'MM/YYYY').format('MM / YY');
    return {
        expDate,
        ...data,
    };
};

export const transformToGW = ({ expDate, ...data }) => {
    if (!expDate) {
        return { ...data };
    }

    const rawExpiryDate = expDate.replace(' / ', '');
    const month = rawExpiryDate.slice(0, 2);
    const year = rawExpiryDate.slice(2, 4);
    const formattedYear = moment(year, 'YY').format('YYYY');

    return {
        expMonth: month,
        expYear: formattedYear,
        ...data,
    };
};
