import { Component } from 'react';
import PropTypes from 'prop-types';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: error };
    }
    componentDidCatch(error, info) {
        if (info?.componentStack) {
            error.componentStack = info.componentStack;
        }

        gtmTracker.trackError(error);
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return fallback;
        }
        return children;
    }
}

ErrorBoundary.propTypes = {
    /** The components that are inside this Error Boundary */
    children: PropTypes.node,
    /** The fallback node (component) to render on error */
    fallback: PropTypes.node.isRequired,
};

export default ErrorBoundary;
