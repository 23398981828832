import { Redirect, Switch, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { DRAWER_MODE, SideDrawer } from '../../../components';
import { FORM_MODES } from '../../../config/formModes';
import { LayerRoute } from '../../multi-layer-drawer/components/LayerRoute/LayerRoute';
import { FEATURE_FLAGS, useConfigMatch } from '../../taboola-common-frontend-modules/account-configurations';
import { PATH_TO_ENTITY_MAP, SCHEDULED_REPORTS_EDIT_ROUTE_PATH, SCHEDULED_REPORTS_ROUTE_PATH } from '../config';
import { ScheduledReportEditorForm } from '../pages/ScheduledReportEditorForm';

export const ScheduledReportEditDrawer = ({ onCancel }) => {
    const { params: { mode, entity: entityParam } = {} } = useRouteMatch(SCHEDULED_REPORTS_ROUTE_PATH) || {};
    const entity = PATH_TO_ENTITY_MAP[entityParam];
    const isOpen = entity && mode && mode !== FORM_MODES.CREATE;

    const isMultiLayerDrawerEnabled = useConfigMatch({ [FEATURE_FLAGS.MULTI_LAYER_DRAWER_ENABLED]: 'true' });

    if (isMultiLayerDrawerEnabled) {
        return null;
    }

    return (
        <SideDrawer openDrawer={isOpen} onClose={onCancel} drawerMode={DRAWER_MODE.SMALL}>
            {close => (
                <Switch>
                    <LayerRoute
                        exact
                        path={SCHEDULED_REPORTS_EDIT_ROUTE_PATH}
                        render={props => <ScheduledReportEditorForm {...props} onCancel={close} />}
                    />
                    <Redirect to={SCHEDULED_REPORTS_ROUTE_PATH} />
                </Switch>
            )}
        </SideDrawer>
    );
};

ScheduledReportEditDrawer.propTypes = {
    onCancel: PropTypes.func.isRequired,
};
