import React from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import { UIC } from 'tuui/lib/utils/typing';
import { useTheme } from 'styled-components';
import { ThemeColor } from 'tuui/lib/theme/base';

export interface ToggleSwitchProps extends ReactSwitchProps {
    color?: ThemeColor;
}

const SwitchFixed = Switch as unknown as React.FC<ReactSwitchProps>;

export const ToggleSwitch: UIC<ToggleSwitchProps> = ({
    color = 'secondary',
    height = 11,
    width = 21,
    checked,
    checkedIcon,
    uncheckedIcon,
    ...rest
}: ToggleSwitchProps) => {
    const theme = useTheme();
    const switchColor = theme.palette[color].fill;

    return (
        <SwitchFixed
            {...rest}
            onColor={switchColor}
            height={height}
            width={width}
            checked={checked ?? false}
            checkedIcon={checkedIcon ?? false}
            uncheckedIcon={uncheckedIcon ?? false}
        />
    );
};
