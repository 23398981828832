import { isEmpty, reduce, get } from 'lodash';
import { FORM_MODE_TO_PATH_MAP } from 'config/routes/mode';
import { DIMENSION_TO_PATH_MAP } from 'modules/campaigns/config/routes/campaignDimension';
import { ENTITY_TO_PATH_MAP } from 'modules/campaigns/config/routes/entity';

const replaceParam = (path, param, options = []) => {
    const paramsOptionsRegexp = new RegExp(`(/:${param})(\\([a-zA-Z0-9-|]*\\))?(\\?)?`, 'ig');
    if (isEmpty(options)) {
        const needToRemove = !KEY_TO_PATH_MAP_MAP[param];
        return path.replace(paramsOptionsRegexp, needToRemove ? '' : '$1');
    }

    const optionsContent = `(${options.join('|')})`;
    return path.replace(paramsOptionsRegexp, `$1${optionsContent}`);
};

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: ENTITY_TO_PATH_MAP,
    dimension: DIMENSION_TO_PATH_MAP,
};

const makeParamsMandatory = (path, params) =>
    reduce(params, (interimPath, paramName) => interimPath.replace(`:${paramName}?`, `:${paramName}`), path);

export const replacePathParams = (path, params, mandatoryParams, keyToPathMap = KEY_TO_PATH_MAP_MAP) => {
    const pathWithNewParams = reduce(
        params,
        (result, values, key) =>
            replaceParam(
                result,
                key,
                values.map(item => get(keyToPathMap, [key, item]))
            ),
        path
    );

    return makeParamsMandatory(pathWithNewParams, mandatoryParams);
};
