import React from 'react';
import { LightningIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import styles from './motivationSection.module.scss';

export const MotivationSection = ({ motivationId }) => {
    return (
        <div className={styles['container']}>
            <LightningIcon className={styles['lightning-icon']} />
            <FormattedMessage id={`onboarding.assistant.${motivationId}.motivation`} defaultMessage="Motivation" />
        </div>
    );
};
