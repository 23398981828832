import React from 'react';
import { DropdownField } from '../../../../campaigns/components';
import { FormattedMessage } from '../../../../taboola-common-frontend-modules/i18n';
import { SupportedCountriesDropdownTooltip } from './SupportedCountriesDropdownTooltip';
import { useSupportedCountries } from './useSupportedCountries';
import styles from '../../AudienceSeedField/audienceSeedField.module.scss';

export const SupportedCountriesDropdown = ({ configName }) => {
    const { selectedCountry, onCountryChange, supportedCountriesList } = useSupportedCountries(configName);

    return (
        <DropdownField
            containerClass={styles['input']}
            label={<FormattedMessage id="audience.editor.select.audience.country.title" defaultMessage="Country" />}
            helpText={<SupportedCountriesDropdownTooltip />}
            required={true}
            id="SearchKeywordsSupportedCountriesDropdown"
            placeholder={
                <FormattedMessage id="audience.editor.select.audience.placeholder" defaultMessage="Click to Select" />
            }
            selectedValueObject={selectedCountry}
            options={supportedCountriesList}
            onChange={onCountryChange}
            searchable={true}
        />
    );
};
