import { useMemo } from 'react';
import { useSelectedAccount } from 'hooks';
import { useIntl } from '../../../../taboola-common-frontend-modules/i18n';

export const useCpaRecommendationMetadata = (shouldRecommendTarget, messageParams) => {
    const { formatNumber } = useIntl();
    const [{ currency }] = useSelectedAccount();

    return useMemo(() => {
        if (!shouldRecommendTarget) {
            return {};
        }

        const { cpa_goal: cpaGoal } = messageParams;
        return {
            recommendedTargetCpa: cpaGoal,
            formattedRecommendedTargetCpa: formatNumber(cpaGoal, {
                style: 'currency',
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }),
        };
    }, [currency, formatNumber, messageParams, shouldRecommendTarget]);
};
