import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import Svg from './PreviewWidgetsGalleryEmptySVGIcon';
import styles from './emptyPreviewWidgetGallery.module.scss';

const classNameBuilder = classnames.bind(styles);

const defaultMessages = {
    title: (
        <FormattedMessage
            id="creative.creator.preview.widgets.empty.text.title"
            defaultMessage="No variations to show."
        />
    ),
    description: (
        <FormattedMessage
            id="creative.creator.preview.widgets.empty.text.description"
            defaultMessage="Add titles and media files to create new variations."
        />
    ),
};

const EmptyPreviewWidgetGallery = ({ className, title, description, Icon }) => (
    <div className={classNameBuilder('container', className)}>
        {Icon}
        <div className={styles['title']}>{title}</div>
        <div>{description}</div>
    </div>
);

EmptyPreviewWidgetGallery.propTypes = {
    className: PropTypes.string,
    title: PropTypes.node,
    description: PropTypes.node,
    Icon: PropTypes.node,
};

EmptyPreviewWidgetGallery.defaultProps = {
    title: defaultMessages.title,
    description: defaultMessages.description,
    Icon: <Svg />,
};

export default EmptyPreviewWidgetGallery;
