"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgSortDown(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M15.187 13a1.1 1.1 0 0 1 1.502 0 .98.98 0 0 1 0 1.433l-3.563 3.123c-.302.286-.7.444-1.127.444a1.62 1.62 0 0 1-1.126-.444L7.31 14.433a.98.98 0 0 1 0-1.433 1.1 1.1 0 0 1 1.502 0l2.122 1.753L11 7c0-.552.411-1 .998-1A.98.98 0 0 1 13 7v7.753L15.187 13z"
            })
        )
    );
}
exports.default = SvgSortDown;