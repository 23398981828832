import { cloneDeep, get, set, merge, keys, pick, isEmpty } from 'lodash';
import moment from 'moment';

export const REPORT_STATE_SETTINGS = {
    EDGES: 'edges',
    IS_OPEN: 'isOpen',
};

export const REPORT_STATE_SETTINGS_DEFAULTS = {
    [REPORT_STATE_SETTINGS.IS_OPEN]: true,
};

const getDefaultGraphEdges = (metrics = []) => {
    const edges = {};
    let selectionCounter = 0;

    metrics.forEach(metric => {
        edges[metric.name] = {
            selected: !!metric.active,
        };
        if (edges[metric.name].selected) {
            edges[metric.name].selectionOrder = selectionCounter;
            selectionCounter += 1;
        }
    });

    return edges;
};

const getEmptyEdges = metrics =>
    (metrics || []).reduce((obj, metric) => {
        obj[metric.name] = { selected: false };

        return obj;
    }, {});

const getReportGraphKey = report => report;

const getDefaultSetting = (metrics, settingsName) => {
    if (settingsName === REPORT_STATE_SETTINGS.EDGES) {
        return getDefaultGraphEdges(metrics);
    }
    return REPORT_STATE_SETTINGS_DEFAULTS[settingsName];
};

const getExistingEdges = (metrics, edges) =>
    merge(getEmptyEdges(metrics), edges || getDefaultSetting(metrics, REPORT_STATE_SETTINGS.EDGES));

const updateGraphStateByReport = (graphState, selectedReport, newValues) => {
    const reportKey = getReportGraphKey(selectedReport);

    return {
        ...graphState,
        [reportKey]: {
            ...graphState[reportKey],
            ...newValues,
        },
    };
};

const insertReferenceLines = ({ metrics, edges, selectedCampaign, intl, selectedAccount }) => {
    if (!metrics || !intl) {
        return edges;
    }
    const newEdges = cloneDeep(edges);
    const { currency } = selectedAccount;

    metrics.forEach(({ name, lineParams }) => {
        if (lineParams) {
            const { dataKey, area, getOffset, labelFormatter } = lineParams;
            const dataIsFloat = !Number.isNaN(parseFloat(selectedCampaign[dataKey]));
            const value = dataIsFloat ? selectedCampaign[dataKey] : 0;
            const line = {
                value,
                area,
                offset: getOffset(value),
                label: labelFormatter(value, intl, { currency }),
            };
            set(newEdges, [name, 'line'], line);
        }
    });

    return newEdges;
};

export const getEdges = ({ metrics, edges, selectedCampaign, intl, selectedAccount }) => {
    const resolvedEdges = getExistingEdges(metrics, edges);
    const filteredEdges = pick(
        resolvedEdges,
        metrics.map(({ name }) => name)
    );

    return insertReferenceLines({ metrics, edges: filteredEdges, selectedCampaign, intl, selectedAccount });
};

export const getGraphOpenState = (graphState, selectedReport) => {
    const reportKey = getReportGraphKey(selectedReport);

    return get(graphState, [reportKey, REPORT_STATE_SETTINGS.IS_OPEN], null);
};

export const updateOpenState = (graphState, selectedReport, isOpen) =>
    updateGraphStateByReport(graphState, selectedReport, { [REPORT_STATE_SETTINGS.IS_OPEN]: isOpen });

export const getMergedEdgesStateWithMetrics = (metrics, edges) => {
    if (isEmpty(metrics)) {
        return edges;
    }

    const configEdges = getDefaultGraphEdges(metrics);
    const validRestoredEdges = pick(edges, keys(configEdges));
    const mergedEdges = { ...configEdges, ...validRestoredEdges };

    return mergedEdges;
};

/** Builds a set of props for a weekend area from friday noon to sunday noon.
 * Timestamps are shifted 12 hours because each timestamp is at midnight. */
const buildWeekendArea = (weekStartTimestamp, color) => {
    const weekendMoment = moment.utc(weekStartTimestamp);
    const start = weekendMoment.subtract(36, 'hours').valueOf();
    const end = weekendMoment.add(2, 'days').valueOf();

    return {
        x1: start,
        x2: end,
        fill: color,
        fillOpacity: 1,
        ifOverflow: 'hidden',
    };
};

// Returns an array of referenceArea prop objects
export const getWeekendAreas = (graphData, color) => {
    if (Array.isArray(graphData) && graphData.length > 0) {
        const startsOfWeeks = graphData
            .filter(({ dataDate }) => dataDate)
            .map(({ dataDate }) => moment.utc(dataDate).add(1, 'day').startOf('week').valueOf());
        const uniqueWeeks = new Set(startsOfWeeks);

        return [...uniqueWeeks].map(weekStartTimestamp => buildWeekendArea(weekStartTimestamp, color));
    }

    return [];
};
