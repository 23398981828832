import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { CONTENT_METHODS } from '../config';
import { useRssHandler } from './useRssHandler';
import { useSaveAiVariationsHandler } from './useSaveAiVariationsHandler';
import { useSaveOneByOneHandler } from './useSaveOneByOneHandler';
import { useSaveVariationsCarouselHandler } from './useSaveVariationsCarouselHandler';
import { useSaveVariationsHandler } from './useSaveVariationsHandler';
import { useUnifiedCreativeSaveAiVariationsHandler } from './useUnifiedCreativeSaveAiVariationsHandler';
import { useUnifiedCreativeSaveVariationsCarouselHandler } from './useUnifiedCreativeSaveVariationsCarouselHandler';
import { useUnifiedCreativeSaveVariationsHandler } from './useUnifiedCreativeSaveVariationsHandler';

export const useCreativeCreatorSubmitHandlersMap = (accountId, data, dispatch) => {
    const isUnifiedCreativeEndpointsEnabled = useConfigMatch({
        [FEATURE_FLAGS.UNIFIED_CREATIVE_ENDPOINTS_ENABLED]: 'true',
    });
    const submitHandlersMap = {
        [CONTENT_METHODS.VARIATIONS]: useSaveVariationsHandler(accountId, data, dispatch),
        [CONTENT_METHODS.CAROUSEL]: useSaveVariationsCarouselHandler(accountId, data, dispatch),
        [CONTENT_METHODS.AI_VARIATIONS]: useSaveAiVariationsHandler(accountId, data, dispatch),
        [CONTENT_METHODS.ONE_BY_ONE]: useSaveOneByOneHandler(accountId, data, dispatch),
        [CONTENT_METHODS.RSS]: useRssHandler(accountId, data, dispatch),
    };

    const unifiedCreativeEndpointSubmitHandlersMap = {
        [CONTENT_METHODS.VARIATIONS]: useUnifiedCreativeSaveVariationsHandler(accountId, data, dispatch),
        [CONTENT_METHODS.AI_VARIATIONS]: useUnifiedCreativeSaveAiVariationsHandler(accountId, data, dispatch),
        [CONTENT_METHODS.CAROUSEL]: useUnifiedCreativeSaveVariationsCarouselHandler(accountId, data, dispatch),
    };

    return isUnifiedCreativeEndpointsEnabled
        ? { ...submitHandlersMap, ...unifiedCreativeEndpointSubmitHandlersMap }
        : submitHandlersMap;
};
