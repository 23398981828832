import { useQuery } from 'react-query';
import { useResourcesApi } from '../../../../../../../services/api';

const useLanguagesApi = () => {
    const { getLanguages } = useResourcesApi();
    const { data: languages, isLoading: isResourcesLoading } = useQuery(['languages'], () => getLanguages(), {
        refetchOnWindowFocus: false,
        select: data =>
            data?.map(({ name, value, ...rest }) => ({
                id: name,
                label: value,
                ...rest,
            })),
    });

    return {
        languages,
        isResourcesLoading,
    };
};

export default useLanguagesApi;
