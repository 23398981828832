// when scrolling down/up flag passed
import { useEffect, useRef, useState } from 'react';

const useVerticalScrollDirection = () => {
    const [scrollElement, setScrollElement] = useState();
    const [down, setDown] = useState(false);
    const prevScrollPosition = useRef(0);
    const prevClientHeight = useRef(0);
    const downRef = useRef(down);
    downRef.current = down;

    useEffect(() => {
        if (!scrollElement) {
            return;
        }

        const onScroll = e => {
            const scrollPosition = e.target.scrollTop;
            const clientHeight = e.target.clientHeight;

            // if while real scrolling container height is being changed we have position collision hence skipping such event
            if (clientHeight !== prevClientHeight.current || scrollPosition === prevScrollPosition.current) {
                prevClientHeight.current = clientHeight;
                prevScrollPosition.current = scrollPosition;
                return;
            }

            const isForwardScrolling = scrollPosition > prevScrollPosition.current;

            if (isForwardScrolling !== downRef.current) {
                setDown(isForwardScrolling);
            }

            prevClientHeight.current = clientHeight;
            prevScrollPosition.current = scrollPosition;
        };

        prevClientHeight.current = scrollElement.clientHeight || 0;
        prevScrollPosition.current = scrollElement.scrollTop || 0;
        scrollElement.addEventListener('scroll', onScroll);

        return () => scrollElement.removeEventListener('scroll', onScroll);
    }, [scrollElement]);

    return { scrollElementRef: setScrollElement, down, up: !down };
};

export default useVerticalScrollDirection;
