'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactScroll = require('react-scroll');

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

var _constants = require('./constants');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Created by oreuveni
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Date: 2018-11-28
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Time: 14:40
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */


var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'wizardSubstepNavigationButton__taboola-svg-icon___21nUl',
    'wizard-substep-navigation-button': 'wizardSubstepNavigationButton__wizard-substep-navigation-button___3nOzY',
    'active': 'wizardSubstepNavigationButton__active___FpwtY',
    'wizard-substep-navigation-icon': 'wizardSubstepNavigationButton__wizard-substep-navigation-icon___3Feva',
    'wizard-substep-navigation-label': 'wizardSubstepNavigationButton__wizard-substep-navigation-label___13ADn'
};


var classNameBuilder = _bind2.default.bind(styles);

var WizardSubstepNavigationButton = function (_Component) {
    _inherits(WizardSubstepNavigationButton, _Component);

    function WizardSubstepNavigationButton() {
        _classCallCheck(this, WizardSubstepNavigationButton);

        var _this = _possibleConstructorReturn(this, (WizardSubstepNavigationButton.__proto__ || Object.getPrototypeOf(WizardSubstepNavigationButton)).call(this));

        _this.toggleActive = function (active) {
            var _this$props = _this.props,
                setActiveSubStep = _this$props.setActiveSubStep,
                subStep = _this$props.subStep,
                controlled = _this$props.controlled;


            if (controlled) {
                return;
            }

            _this.setState({ active: active });

            if (active && setActiveSubStep) {
                setActiveSubStep(subStep);
            }
        };

        _this.state = {
            active: false
        };
        return _this;
    }

    _createClass(WizardSubstepNavigationButton, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _props = this.props,
                registerStep = _props.registerStep,
                subStep = _props.subStep;


            if (registerStep) {
                registerStep(subStep);
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _classNameBuilder,
                _this2 = this;

            var _props2 = this.props,
                label = _props2.label,
                id = _props2.id,
                className = _props2.className,
                activeClassName = _props2.activeClassName,
                completeClassName = _props2.completeClassName,
                subStep = _props2.subStep,
                activeSubStep = _props2.activeSubStep,
                setActiveSubStep = _props2.setActiveSubStep,
                controlled = _props2.controlled,
                navigationCondition = _props2.navigationCondition;
            var activeState = this.state.active;

            var active = activeState || subStep && subStep === activeSubStep || false;
            var complete = subStep < activeSubStep;
            var buttonClassName = classNameBuilder('wizard-substep-navigation-button', className, (_classNameBuilder = { active: active }, _defineProperty(_classNameBuilder, activeClassName, active), _defineProperty(_classNameBuilder, completeClassName, complete), _classNameBuilder));
            return _react2.default.createElement(
                _reactScroll.Link,
                {
                    className: buttonClassName,
                    href: '/',
                    to: id,
                    spy: true,
                    smooth: true,
                    offset: -100,
                    duration: 500,
                    onSetActive: function onSetActive() {
                        return _this2.toggleActive(true);
                    },
                    onSetInactive: function onSetInactive() {
                        return _this2.toggleActive(false);
                    },
                    containerId: _constants.WIZARD_CONTAINER_ID,
                    onClick: function onClick() {
                        navigationCondition(function () {
                            setActiveSubStep(subStep);
                        }, {
                            isComplete: complete,
                            controlled: controlled,
                            newStep: subStep
                        });
                    }
                },
                _react2.default.createElement('span', { className: styles['wizard-substep-navigation-icon'] }),
                _react2.default.createElement(
                    'span',
                    { className: styles['wizard-substep-navigation-label'] },
                    label
                )
            );
        }
    }]);

    return WizardSubstepNavigationButton;
}(_react.Component);

WizardSubstepNavigationButton.propTypes = {
    id: _propTypes2.default.string,
    label: _propTypes2.default.node,
    className: _propTypes2.default.string,
    activeClassName: _propTypes2.default.string,
    completeClassName: _propTypes2.default.string,
    subStep: _propTypes2.default.string,
    activeSubStep: _propTypes2.default.string,
    setActiveSubStep: _propTypes2.default.func,
    controlled: _propTypes2.default.bool,
    registerSubStep: _propTypes2.default.func,
    navigationCondition: _propTypes2.default.func,
    registerStep: _propTypes2.default.func
};

WizardSubstepNavigationButton.defaultProps = {
    navigationCondition: function navigationCondition(callback, _ref) {
        var isComplete = _ref.isComplete,
            controlled = _ref.controlled;

        if (isComplete && controlled) {
            callback();
        }
    }
};

exports.default = WizardSubstepNavigationButton;