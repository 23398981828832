import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CTADropdown as CTADropdownBase } from 'modules/campaigns/modules/creative-editor/components/fields/index';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './bulkEditCTADropdown.module.scss';

const ctaLabel = <FormattedMessage id="creative.editor.bulk.content.cta.label" defaultMessage="Edit CTA (Optional)" />;

const ctaDropdownStyles = {
    placeholder: provided => ({
        ...provided,
        color: styles.gray5,
    }),
    control: provided => ({
        ...provided,
        border: `${styles.dropdownBorderWidth} solid ${styles.gray5}}`,
    }),
};

const BulkEditCTADropdown = ({ isSelected, setIsSelected }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownWrapperRef = useRef(null);

    useEffect(() => {
        if (isDropdownOpen) {
            dropdownRef.current.focus();
        }
    }, [isDropdownOpen]);

    return (
        <div
            onMouseUp={e => {
                const targetIsDropdownWrapper = e.target.isEqualNode(dropdownWrapperRef.current);
                const targetIsChildOfDropdownWrapper = dropdownWrapperRef.current.contains(e.target);
                if (targetIsDropdownWrapper || targetIsChildOfDropdownWrapper) {
                    setIsSelected(true);
                    setIsDropdownOpen(true);
                }
            }}
        >
            <CTADropdownBase
                searchable
                checkboxLabel={ctaLabel}
                selected={isSelected}
                onSelect={() => {
                    setIsSelected();
                    setIsDropdownOpen(!isSelected);
                }}
                disabled={isSelected === false}
                disabledPlaceholderId="creative.editor.bulk.content.placeholder"
                styles={ctaDropdownStyles}
                selectClassPrefixOverride={styles.selectDropdownPrefix}
                onBlur={() => setIsDropdownOpen(false)}
                onChange={() => setIsDropdownOpen(false)}
                menuIsOpen={isDropdownOpen}
                dropdownRef={dropdownRef}
                dropdownWrapperRef={dropdownWrapperRef}
            />
        </div>
    );
};

BulkEditCTADropdown.protoTypes = {
    isSelected: PropTypes.bool,
    setIsSelected: PropTypes.func,
};

export default BulkEditCTADropdown;
