import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { cloneDeep, get, has, isEmpty, isEqual, isFunction, keys, noop, set, unset, values } from 'lodash';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';
import { FORM_MODES } from 'config/formModes';
import { useAppEventContext, APP_EVENT_TYPE } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { useCurrentValueGetter, useShallowObject } from '../../../hooks';
import { AccountContext } from '.././account-configurations/components/AccountContext';
import { getParentArrayField, isArrayElementField } from './utils/formContextUtils';

const getNewValue = (data, field, value) => {
    const currentValue = get(data, field);
    const newValue = isFunction(value) ? value(currentValue) : value;

    return newValue;
};

export const FormDataContext = createContext({
    data: Object.freeze({}),
    setData: noop,
    setFetchedData: noop,
    setField: noop,
    initialData: Object.freeze({}),
    touchedFormFields: Object.freeze({}),
    mutatedFormFields: Object.freeze({}),
    isFormTouched: false,
    isCurrentlyDirty: () => false,
    registerField: noop,
    isFieldEqualToInitial: noop,
    setDelayedFieldState: noop,
    waitDelayedFields: noop,
    defaultValuesCache: {},
    isExternalData: noop,
});

const unsetWithChildren = (obj, field) => {
    keys(obj)
        .filter(key => key === field || key.startsWith(`${field}.`))
        .forEach(key => {
            unset(obj, key);
        });
};

const useDelayedFields = () => {
    const delayedValuesRef = useRef({});
    const setDelayedFieldState = useCallback((field, synced) => {
        if (delayedValuesRef.current[field]?.synced === synced || (!delayedValuesRef.current[field] && synced)) {
            return;
        }

        if (!synced) {
            let resolver;
            const promise = new Promise(resolve => (resolver = resolve));

            delayedValuesRef.current[field] = {
                synced: false,
                promise,
                resolver,
            };
            return;
        }

        delayedValuesRef.current[field].synced = true;
        delayedValuesRef.current[field].resolver();
        unset(delayedValuesRef.current[field], 'promise');
        unset(delayedValuesRef.current[field], 'resolver');
    }, []);
    const unsetDelayedFieldState = useCallback(field => unset(delayedValuesRef.current, field), []);
    const waitDelayedFields = useCallback(() => {
        const promises = values(delayedValuesRef.current)
            .filter(({ synced }) => !synced)
            .map(({ promise }) => promise);

        return Promise.all(promises);
    }, []);

    return {
        setDelayedFieldState,
        unsetDelayedFieldState,
        waitDelayedFields,
    };
};

export const FormDataProvider = ({ children, initialData = {}, isSubmitDisabled, mode, formContainerId, ...rest }) => {
    const defaultValuesCache = useRef({ renderCounter: 0 });
    ++defaultValuesCache.current.renderCounter;
    const [data, setData] = useImmer(initialData);
    const dataGetter = useCurrentValueGetter(data);
    const memoizedInitialData = useMemo(() => cloneDeep(initialData), [initialData]);
    const { setDelayedFieldState, unsetDelayedFieldState, waitDelayedFields } = useDelayedFields();
    const initialDataRef = useRef(memoizedInitialData);
    const fetchedDataRef = useRef(memoizedInitialData);
    const touchedFormFields = useRef({});
    const externalData = useRef({});
    const setExternalData = useCallback(passedData => {
        externalData.current = passedData;
    }, []);
    const mutatedFormFields = useRef({});
    const isFormTouched = !isEmpty(touchedFormFields.current);

    // TODO remove this bridge
    const accountContextValue = useContext(AccountContext);
    const formAccountProps = {
        formAccount: accountContextValue.account,
        setFormAccount: account => accountContextValue.setAccountId(account.id),
        isFormAccountTouched: accountContextValue.isAccountTouched,
    };

    const memoRest = useShallowObject({ ...formAccountProps, ...rest });

    const setFetchedData = useCallback(fetchedData => {
        fetchedDataRef.current = fetchedData;
        initialDataRef.current = cloneDeep(fetchedData);
    }, []);

    const updateMutatedFields = useCallback(
        (field, value) => {
            const newValue = getNewValue(dataGetter(), field, value);

            if (isEqual(get(initialDataRef.current, field), newValue)) {
                unsetWithChildren(mutatedFormFields.current, field);
                return;
            }

            mutatedFormFields.current[field] = true;
        },
        [dataGetter]
    );
    const hasDataChanges = useCallback(() => !isEmpty(mutatedFormFields.current), []);
    const isFieldEqualToInitial = useCallback(field => get(mutatedFormFields.current, field) !== true, []);

    const unregisterField = useCallback(field => unsetDelayedFieldState(field), [unsetDelayedFieldState]);
    const registerField = useCallback(
        field => {
            if (has(externalData.current, field)) {
                touchedFormFields.current[field] = true;
                mutatedFormFields.current[field] = true;
            }

            return () => unregisterField(field);
        },
        [unregisterField]
    );
    const isExternalData = useCallback(
        field =>
            has(externalData.current, field) && isEqual(get(externalData.current, field), get(dataGetter(), field)),
        [dataGetter]
    );
    const isCurrentlyDirty = useCallback(field => get(touchedFormFields.current, field) === true, []);

    const isSelfOrParentDirty = useCallback(
        field => isCurrentlyDirty(field) || isCurrentlyDirty(getParentArrayField(field)),
        [isCurrentlyDirty]
    );
    const { push: pushAppEvent } = useAppEventContext();
    const setField = useCallback(
        ({ field, value, isInitial = false }) => {
            if (isInitial) {
                set(initialDataRef.current, field, value);
            } else {
                touchedFormFields.current[field] = true;
                updateMutatedFields(field, value);
                if (isArrayElementField(field)) {
                    const parentField = getParentArrayField(field);
                    touchedFormFields.current[parentField] = true;
                }
            }

            setData(draft => {
                const currentValue = get(draft, field);
                const valueToSet = getNewValue(draft, field, value);

                if (isEqual(valueToSet, currentValue)) {
                    return draft;
                }

                pushAppEvent({
                    type: APP_EVENT_TYPE.FORM_FIELD_CHANGE,
                    id: field,
                    value: valueToSet,
                    prevValue:
                        typeof currentValue === 'object' && currentValue !== null
                            ? cloneDeep(currentValue)
                            : currentValue,
                });

                return set(draft, field, valueToSet);
            });
        },
        [setData, updateMutatedFields, pushAppEvent]
    );

    const resetFormToInitial = useCallback(() => {
        touchedFormFields.current = {};
        mutatedFormFields.current = {};
        setData(initialDataRef.current);
    }, [setData]);

    const resetFieldToInitial = useCallback(
        field => {
            const initialValue = get(initialDataRef.current, field);
            unsetWithChildren(touchedFormFields.current, field);
            unsetWithChildren(mutatedFormFields.current, field);
            setData(draft => set(draft, field, initialValue));
            return initialValue;
        },
        [setData]
    );
    const hasNoEditChanges = isEmpty(mutatedFormFields.current) && mode === FORM_MODES.EDIT;

    const value = useMemo(
        () => ({
            defaultValuesCache: defaultValuesCache.current,
            data,
            setData,
            setFetchedData,
            setField,
            initialData: initialDataRef.current,
            fetchedData: fetchedDataRef.current,
            resetFormToInitial,
            touchedFormFields: touchedFormFields.current,
            isFormTouched,
            isCurrentlyDirty,
            isSelfOrParentDirty,
            resetFieldToInitial,
            hasDataChanges,
            isFieldEqualToInitial,
            hasNoEditChanges,
            isSubmitDisabled: isSubmitDisabled || hasNoEditChanges,
            setExternalData,
            registerField,
            mode,
            setDelayedFieldState,
            waitDelayedFields,
            isExternalData,
            ...memoRest,
        }),
        [
            data,
            setData,
            setFetchedData,
            setField,
            resetFormToInitial,
            isFormTouched,
            isCurrentlyDirty,
            isSelfOrParentDirty,
            resetFieldToInitial,
            hasDataChanges,
            isFieldEqualToInitial,
            mode,
            isSubmitDisabled,
            hasNoEditChanges,
            memoRest,
            setExternalData,
            registerField,
            setDelayedFieldState,
            waitDelayedFields,
            isExternalData,
        ]
    );

    return <FormDataContext.Provider value={value}>{children}</FormDataContext.Provider>;
};

FormDataProvider.propTypes = { children: PropTypes.node };

export const useFormDataContext = () => useContext(FormDataContext);
