import React, { forwardRef } from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { INDICATION_TYPES, IndicationContent } from 'taboola-ultimate-ui';
import { isErrorValidation } from 'modules/taboola-common-frontend-modules/validations/utils';
import { ChatAgentIndication } from '../../taboola-common-frontend-modules/chat-agent/components/ChatAgentIndication/ChatAgentIndication';
import { TooltipMessageIndication } from './TooltipMessageIndication/TooltipMessageIndication';
import styles from './withIndication.module.scss';

const classNameBuilder = classnames.bind(styles);

export const withIndication = (InnerComponent, className = '') => {
    const MsgWrapperComp = forwardRef(
        (
            {
                errorMessageClass,
                indicationContainerClass,
                message,
                indicationType,
                shouldUseTooltipMessageIndication,
                scrollAreaRef,
                isEqualToInitial,
                isRecommended,
                ...rest
            },
            ref
        ) => {
            const isError = isErrorValidation({ severity: indicationType });
            const IndicationContentComponent = shouldUseTooltipMessageIndication
                ? TooltipMessageIndication
                : IndicationContent;

            return (
                <div className={classNameBuilder('wrapper', className)} ref={ref}>
                    <ChatAgentIndication {...rest} isRecommended={isRecommended}>
                        <InnerComponent {...rest} indicationType={indicationType} />
                    </ChatAgentIndication>
                    {message && (
                        <IndicationContentComponent
                            type={indicationType}
                            containerClassName={classNameBuilder('content', indicationContainerClass)}
                            contentClassName={classNameBuilder({
                                error: isError,
                                [errorMessageClass]: !!errorMessageClass && isError,
                            })}
                        >
                            {message}
                        </IndicationContentComponent>
                    )}
                </div>
            );
        }
    );

    MsgWrapperComp.propTypes = {
        errorMessageClass: PropTypes.string,
        message: PropTypes.node,
        indicationType: PropTypes.oneOf(Object.values(INDICATION_TYPES)),
    };

    return MsgWrapperComp;
};

export const ErrorMessageIndication = withIndication(() => null);

export default withIndication;
