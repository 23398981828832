import { useMemo } from 'react';
import config from 'modules/taboola-common-frontend-modules/authentication/config';
import { isIframeMode } from 'modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';

const parentAppPattern = /ads-(.*?)(?:-staging)?\.taboola\.com/;
const extractParentAppName = text => {
    const match = (text || '').match(parentAppPattern);
    return match ? match[1] : 'yahoo';
};

export const getParentAppName = () => {
    return isIframeMode() ? extractParentAppName(config.getIframeDomain()) : '';
};

export const useParentAppName = () => {
    const parentAppName = useMemo(() => {
        return getParentAppName();
    }, []);

    return parentAppName;
};
