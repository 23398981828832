import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetSet } from 'react-use';
import { isNil, noop } from 'lodash';
import PropTypes from 'prop-types';
import { createFetchAccountErrorIndication } from '../../../flows';
import { useCurrentValueGetter, useSelectedAccountId } from '../../../hooks';
import { transformAccountObject } from '../../../selectors/baseSelectors';
import { setFatalError } from '../../errors/actions/setFatalError';
import { addIndication } from '../Indications';
import { AccountContext } from './components/AccountContext';
import { defaultRetryCounter } from './components/defaultRetryCounter';
import { useFetchedAccount } from './hooks/useFetchedAccount';
import { useFetchedConfigData } from './hooks/useFetchedConfigData';
import { isEmptyAccountId } from './utils/isEmptyAccountId';

export const AccountProvider = ({
    accountId: passedAccountId,
    accountName,
    networkAccountName,
    children,
    onError = noop,
    fetchRetryCount = defaultRetryCounter,
}) => {
    const dispatch = useDispatch();
    const [contextAccountId, setAccountId] = useState(passedAccountId);
    const [queryAccountId] = useSelectedAccountId();
    const accountId =
        isNil(accountName) && isNil(networkAccountName) ? contextAccountId ?? queryAccountId : contextAccountId;
    const isGlobal = useCurrentValueGetter(isNil(accountName ?? networkAccountName ?? passedAccountId));
    const accountKey = useCurrentValueGetter(accountName ?? networkAccountName ?? accountId);
    const account = useFetchedAccount({
        accountId,
        accountName,
        networkAccountName,
        setAccountId,
        isGlobal: isGlobal(),
    });
    const accountConfig = useFetchedConfigData(account, fetchRetryCount);
    const [isAccountTouched, setAccountTouched] = useGetSet(false);
    const setAccountIdHandler = useCallback(
        accountId => {
            setAccountTouched(true);
            setAccountId(accountId);
        },
        [setAccountTouched]
    );
    const value = useMemo(
        () => ({
            account: {
                ...transformAccountObject(account || { id: accountId, accountId }),
                isAccountFetched: account?.isAccountFetched && accountConfig.isReady,
                isError: account.isError || accountConfig.isError,
            },
            setAccountId: setAccountIdHandler,
            isAccountTouched,
            accountConfig,
        }),
        [account, accountConfig, accountId, setAccountIdHandler, isAccountTouched]
    );

    const isError = value.account.isError;
    useEffect(() => {
        if (!isError) {
            return;
        }
        dispatch(addIndication(createFetchAccountErrorIndication({ accountId: accountKey() })));

        if (isGlobal() && isEmptyAccountId(accountKey())) {
            dispatch(setFatalError('Failed to fetch account.'));
        }

        if (onError) {
            onError();
        }
    }, [isError, onError, accountKey, isGlobal, dispatch]);

    return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

AccountProvider.propTypes = {
    children: PropTypes.node,
    account: PropTypes.object,
};
