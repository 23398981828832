"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgDevice(props) {
    return React.createElement(
        "svg",
        Object.assign({
            width: 32,
            height: 32,
            viewBox: "0 0 32 32",
            xmlns: "http://www.w3.org/2000/svg",
            fill: "currentColor"
        }, props),
        React.createElement("path", {
            d: "M17 23a1 1 0 11-2 0 1 1 0 012 0zM14 7.5a.5.5 0 000 1h4a.5.5 0 000-1h-4z",
            fill: "#212832"
        }),
        React.createElement("path", {
            fillRule: "evenodd",
            clipRule: "evenodd",
            d: "M12 5a3 3 0 00-3 3v16a3 3 0 003 3h8a3 3 0 003-3V8a3 3 0 00-3-3h-8zm8 1h-8a2 2 0 00-2 2v16a2 2 0 002 2h8a2 2 0 002-2V8a2 2 0 00-2-2z",
            fill: "#212832"
        })
    );
}
exports.default = SvgDevice;