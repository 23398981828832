import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { BlockIcon, DeletableItem, OkOIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TARGETING_TYPES from '../../../config/TargetingTypes';
import styles from './renderItem.module.scss';

export const RenderItem = ({ item, deleteItem, targetingType, msgIdPrefix }) => {
    const { label = '', code } = item;
    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);
    const msgId = `${msgIdPrefix}.${code}`;

    return (
        <DeletableItem
            onDelete={onDelete}
            isItemDeletable={!!deleteItem}
            buttonProps={{ 'aria-label': `Remove ${label} item` }}
        >
            <div className={styles['container']}>
                <span className={styles['icon']} aria-label={`${targetingType.toLowerCase()} ${label} icon`}>
                    {targetingType === TARGETING_TYPES.INCLUDE ? (
                        <OkOIcon className={styles['include']} />
                    ) : (
                        <BlockIcon className={styles['exclude']} />
                    )}
                </span>
                {label && (
                    <div className={styles['label']}>
                        <FormattedMessage id={msgId} defaultMessage={label} />
                    </div>
                )}
            </div>
        </DeletableItem>
    );
};

RenderItem.propTypes = {
    item: PropTypes.object,
    deleteItem: PropTypes.func,
    targetingType: PropTypes.string,
    msgIdPrefix: PropTypes.string,
};

RenderItem.defaultProps = {
    targetingType: TARGETING_TYPES.INCLUDE,
};
