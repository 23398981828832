import { REQUEST_UPDATE_CREATIVE, UPDATE_CREATIVE_ERROR, UPDATE_CREATIVE_SUCCESS } from './actionTypes';

export const requestUpdateCreative = () => ({ type: REQUEST_UPDATE_CREATIVE });

export const updateCreativeSuccess = creative => ({
    type: UPDATE_CREATIVE_SUCCESS,
    payload: creative,
});

export const updateCreativeError = error => ({ type: UPDATE_CREATIVE_ERROR, payload: error });
