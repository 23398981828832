import { selectedAccountSelector } from '../../../../../selectors';
import { GTM_EVENTS, gtmTracker } from '../../../../taboola-common-frontend-modules/gtmTracker';
import { getCampaignEntity } from '../../../config';
import { addToCampaignProperty, removeFromCampaignProperty, replaceCampaignProperty } from '../../../flows';
import { selectedCampaignSelector } from '../../../selectors';
import setReportData from '../actions/setReportData';
import { calcBidModificationActionName } from '../utils/bidModificationUtils';
import { prepareRollBackData } from './saveCampaign';

// TODO DEV-66093 { bidModification: prevBidModification }, rowIndex - have to be removed along with site data consistency issue solved
const saveCampaignBidModification =
    ({ bidModification }, rowData, { bidModification: prevBidModification }, rowIndex) =>
    (dispatch, getState) => {
        const state = getState();
        const { campaignsReducer } = state;
        const { reportData } = campaignsReducer;
        const { accountId } = selectedAccountSelector(state);
        const { id: selectedCampaignId, demandType } = selectedCampaignSelector(state);
        const currRow = rowData || reportData[rowIndex];
        // prefer accountName from report row, to support network level operations
        const finalAccountName = currRow.accountName || accountId;
        const rollBackData = prepareRollBackData(rowIndex, { bidModification: prevBidModification }, reportData);
        const rollBack = () => dispatch(setReportData(rollBackData));
        const entityType = getCampaignEntity({ demandType });

        const bidChangesForServer = {
            bidModifier: {
                publisher: currRow.siteName,
                bidModification,
            },
        };

        if (prevBidModification) {
            if (bidModification) {
                dispatch(
                    replaceCampaignProperty({
                        accountId: finalAccountName,
                        campaignId: selectedCampaignId,
                        changes: bidChangesForServer,
                        onError: rollBack,
                        entityType,
                    })
                );
            } else {
                // null is reset
                dispatch(
                    removeFromCampaignProperty({
                        accountId: finalAccountName,
                        campaignId: selectedCampaignId,
                        changes: bidChangesForServer,
                        onError: rollBack,
                        entityType,
                    })
                );
            }
        } else {
            dispatch(
                addToCampaignProperty({
                    accountId: finalAccountName,
                    campaignId: selectedCampaignId,
                    changes: bidChangesForServer,
                    onError: rollBack,
                    entityType,
                })
            );
        }

        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            component: 'InlineEditor',
            columnId: calcBidModificationActionName(prevBidModification, bidModification),
            columnName: 'Bid',
            value: bidModification,
            accountId,
            taboolaCampaignId: selectedCampaignId,
        });
    };

export default saveCampaignBidModification;
