import { GRAPHS } from 'modules/campaigns/constants/graphTypes';
import DcoItemGraphTooltip from '../../../components/Tooltips/DcoItemGraphTooltip';
import * as FIELDS from '../../fields';
import metrics from '../../metrics';

const graph = {
    type: GRAPHS.BAR,
    endpoint: 'dco-by-item-performance',
    metrics: [
        metrics.SPENT,
        metrics.VISIBLE_IMPRESSIONS,
        metrics.CLICKS,
        metrics.VCTR,
        metrics.AVERAGE_CPC,
        metrics.CVR,
        metrics.CONVERSIONS,
        metrics.CPA,
        metrics.VCPM,
    ],
    maxAllowedSelectedMetrics: 3,
    maxXAxisValues: 8,
    shouldUseGridDataSrc: true,
    displayTotalsInLegendRow: false,
    enableLongTextTickFormatting: true,
    graphProps: {
        xAxisDataKey: FIELDS.ITEM_ID.field,
    },
    graphTooltip: DcoItemGraphTooltip,
};

export default graph;
