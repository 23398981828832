import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Gallery } from 'taboola-ultimate-ui';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import ThumbnailLoading from '../../../../components/ThumbnailsField/ThumbnailGallery/ThumbnailLoading/ThumbnailLoading';
import ExternalError from '../ExternalError';
import { ValidatedMediaGalleryItem } from '../ValidatedMediaGalleryItem';

const ImageGallery = ({
    gap,
    minRowHeight,
    columns,
    className,
    images,
    left,
    renderOverlayComponent,
    onEditFallbackImageClick,
    onRemoveThumbnailById,
}) => {
    return (
        <Gallery gap={gap} minRowHeight={minRowHeight} columns={columns} className={className} aria-label="gallery">
            {images.map((thumbnail, i) => (
                <FormNamespaceProvider
                    field={get(thumbnail, 'originalIndex')}
                    itemKey={thumbnail.id}
                    key={thumbnail.id}
                >
                    <ValidatedMediaGalleryItem
                        loadingComponent={<ThumbnailLoading />}
                        status={thumbnail.status}
                        left={left}
                        imgSrc={thumbnail.displayUrl}
                        thumbnail={thumbnail}
                        renderOverlayComponent={renderOverlayComponent(thumbnail)}
                        onEditFallbackImageClick={onEditFallbackImageClick}
                        onCloseButtonClick={() => onRemoveThumbnailById(thumbnail)}
                        errorComponent={<ExternalError error={thumbnail.error} />}
                    />
                </FormNamespaceProvider>
            ))}
        </Gallery>
    );
};

ImageGallery.propTypes = {
    gap: PropTypes.string,
    minRowHeight: PropTypes.string,
    columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    images: PropTypes.array,
    left: PropTypes.number,
    renderOverlayComponent: PropTypes.func,
    onEditFallbackImageClick: PropTypes.func,
    onRemoveThumbnailById: PropTypes.func,
};

export default ImageGallery;
