import React from 'react';
import classnames from 'classnames/bind';
import { CircleProgressIndicator } from 'tuui';
import { FormattedMessage, FormattedNumber } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './headerSection.module.scss';

const classNameBuilder = classnames.bind(styles);

export const HeaderSection = ({ progressPercent, isCollapsed }) => {
    return (
        <div className={styles['container']}>
            <div className={styles['header']}>
                <FormattedMessage id="onboarding.assistant.title" defaultMessage="Onboarding Score:" />
            </div>
            <span className={classNameBuilder('completion-status', { hidden: !isCollapsed })}>
                <h3>
                    <FormattedNumber value={progressPercent / 100} variant="percent" />
                </h3>
                <div className={styles['circle-progress-container']}>
                    <CircleProgressIndicator progress={progressPercent} strokeWidth={50} />
                </div>
            </span>
        </div>
    );
};
