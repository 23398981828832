import React from 'react';
import classnames from 'classnames/bind';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import styles from './subStepNavigator.module.scss';

const classNameBuilder = classnames.bind(styles);

export const SubStepNavigator = ({ currentSubStepIndex, totalSubSteps, onNextStep, onPreviousStep }) => {
    const isPreviousStepActive = currentSubStepIndex !== 0;
    const isNextStepActive = currentSubStepIndex + 1 < totalSubSteps;

    return (
        <div className={styles['container']}>
            <span className={styles['step-number']}>{`${currentSubStepIndex + 1}/${totalSubSteps}`}</span>
            <span
                onClick={onPreviousStep}
                className={classNameBuilder('previous-step', { active: isPreviousStepActive })}
            >
                <FormattedMessage id="onboarding.assistant.previous.step" defaultMessage="Previous" />
            </span>
            <span onClick={onNextStep} className={classNameBuilder('next-step', { active: isNextStepActive })}>
                <FormattedMessage id="onboarding.assistant.next.step" defaultMessage="Next Step" />
            </span>
        </div>
    );
};
