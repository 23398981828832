import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, isFunction, isEmpty } from 'lodash';
import { INDICATION_TYPES } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useDelayedState } from 'hooks/useDelayedState';
import { RECOMMENDATION_SOURCE } from '../../../recommendations/recommendationSource';
import { recommendationsSourceSelector } from '../../../recommendations/selectors';
import { useFormDataContext } from '../FormDataContext';
import { isDefined } from '../utils/isDefined';
import { useDefaultValue } from './useDefaultValue';
import { useNamespaceField } from './useNamespaceField';

const calculateValueToReturn = (isNewForm, value, defaultValue) => {
    if (isNewForm) {
        return isDefined(value) && !isFunction(value) ? value : defaultValue;
    }
    return value;
};

export const useFormFieldValue = (
    { field: basicField, throttleValue, throttleTiming, isAbsolute = false, isPermitted },
    dependencies
) => {
    const nameSpacedField = useNamespaceField(basicField);
    const field = isAbsolute ? basicField : nameSpacedField;
    const {
        data,
        isFieldEqualToInitial,
        setField,
        defaultValuesConfig,
        mode,
        isCurrentlyDirty,
        resetFieldToInitial,
        registerField,
        setDelayedFieldState,
        isExternalData,
    } = useFormDataContext();

    const isNewForm = mode === FORM_MODES.CREATE || mode === FORM_MODES.DUPLICATE;
    const defaultValue = useDefaultValue({
        field,
        isNewForm,
        defaultValuesConfig,
        data,
        dependencies,
        isAbsolute,
        isPermitted,
    });
    const value = get(data, field);
    const isEqualToInitial = isFieldEqualToInitial(field);
    const valueToReturn = calculateValueToReturn(isNewForm, value, defaultValue);
    const isDirty = isCurrentlyDirty(field);

    const onChange = useCallback(
        newValue => {
            if (mode === FORM_MODES.PREVIEW) {
                return;
            }

            setField({ field, value: newValue });
        },
        [mode, field, setField]
    );

    const { value: cachedValue, onChange: cacheOnChange } = useDelayedState(valueToReturn, onChange, {
        throttleTiming,
        field,
    });

    const resetToInitial = useCallback(() => {
        const initialValue = resetFieldToInitial(field);
        cacheOnChange(initialValue, true);
    }, [resetFieldToInitial, field, cacheOnChange]);

    const recommendationSource = useSelector(recommendationsSourceSelector);

    const indicationData = useMemo(
        () => ({
            isRecommended: isExternalData(field),
            indicationType:
                isExternalData(field) && recommendationSource === RECOMMENDATION_SOURCE.CHAT_AGENT
                    ? INDICATION_TYPES.SUGGESTION
                    : undefined,
            isEqualToInitial,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [field, isExternalData, isEqualToInitial, valueToReturn]
    );

    useLayoutEffect(() => registerField(field), [registerField, field]);

    useLayoutEffect(() => {
        if (throttleValue) {
            setDelayedFieldState(
                field,
                valueToReturn === cachedValue || (isEmpty(valueToReturn) && isEmpty(cachedValue))
            );
        }
    }, [cachedValue, valueToReturn, field, throttleValue, setDelayedFieldState]);

    return {
        value: throttleValue ? cachedValue : valueToReturn,
        onChange: throttleValue ? cacheOnChange : onChange,
        formContextValue: valueToReturn,
        resetFieldToInitial: resetToInitial,
        mode,
        isDirty,
        isEqualToInitial,
        indicationData,
    };
};

export default useFormFieldValue;
