import React, { useCallback } from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { BlockIcon, OkOIcon, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import TargetingTypes from '../../../../config/TargetingTypes';
import styles from './cityTargetingListItem.module.scss';

const CityTargetingListItem = ({ targetingType, item, msgIdPrefix, deleteItem }) => {
    const { value, label, detailValue, detailLabel, ...original } = item;

    const handleOnClick = useCallback(() => {
        deleteItem(original);
    }, [original, deleteItem]);

    return (
        <div className={styles['container']}>
            <span aria-label={`${startCase(`${targetingType}`.toLowerCase())} ${label} icon`}>
                {targetingType === TargetingTypes.INCLUDE ? (
                    <OkOIcon className={styles['include']} />
                ) : (
                    <BlockIcon className={styles['exclude']} />
                )}
            </span>
            <div className={styles['content']}>
                <div className="name">
                    <FormattedMessage id={`${msgIdPrefix}.${value}`} defaultMessage={label} />
                </div>
                <div className={styles['detail']}>
                    <FormattedMessage id={`${msgIdPrefix}.${detailValue}`} defaultMessage={detailLabel} />
                </div>
            </div>
            <StyledButton
                aria-label={`Remove ${label} item`}
                className={styles['remove-button']}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.BORDERLESS_ICON}
                iconBefore="close"
                onClick={handleOnClick}
            />
        </div>
    );
};

CityTargetingListItem.propTypes = {
    item: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
    msgIdPrefix: PropTypes.string,
    targetingType: PropTypes.string,
};

export default CityTargetingListItem;
