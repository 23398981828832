import React from 'react';
import { CollapsibleList, FormField, InputGroupWithButton } from 'taboola-ultimate-ui';
import { withIndication } from '../../../errors';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { useScheduledReportsSentTo } from '../../hooks/useScheduledReportsSentTo';
import { ScheduledReportSentTolListItem } from '../ScheduledReportsSentToListItem/ScheduledReportSentTolListItem';
import styles from './scheduledReportSentToInput.module.scss';

const IndicationContent = withIndication(() => <div></div>);
const CollapsibleListWithIndication = withIndication(CollapsibleList);
const emailRecipientsLimit = 10;

export const ScheduledReportSentToInput = () => {
    const {
        sentTo,
        handleAddSentTo,
        handleRemoveEmail,
        handleRemoveAllEmails,
        indicationData,
        scrollRef,
        mergedConfig,
        failedValidationData,
        disabled,
    } = useScheduledReportsSentTo();

    const { inputGroupConfig } = mergedConfig;

    return (
        <FormField
            inputId="sentTo"
            label={<FormattedMessage id="app.scheduled.reports.sent.to.title" defaultMessage="Email Recipients" />}
            ref={scrollRef}
            description={
                <FormattedMessage
                    id="app.scheduled.reports.sent.to.description"
                    defaultMessage="Enter up to {limit} email recipients."
                    values={{ limit: emailRecipientsLimit }}
                />
            }
        >
            <InputGroupWithButton
                config={inputGroupConfig}
                className={styles['container']}
                delimiterClassName={styles['delimiter']}
                buttonProps={{
                    onClick: handleAddSentTo,
                    children: <FormattedMessage id="app.scheduled.reports.sent.to.add.button" defaultMessage="ADD" />,
                    disabled,
                }}
            />
            <IndicationContent {...failedValidationData} />
            <CollapsibleListWithIndication
                id="scheduled-report-sent-to-list"
                items={sentTo}
                ItemComponent={ScheduledReportSentTolListItem}
                deleteItem={handleRemoveEmail}
                clearItems={handleRemoveAllEmails}
                listHeaderTitle={
                    <FormattedMessage id="app.scheduled.reports.sent.to.list.title" defaultMessage="Email Recipients" />
                }
                showLessLabel={<FormattedMessage id="app.actionButtons.show.less" defaultMessage="Show less" />}
                showAllLabel={<FormattedMessage id="app.actionButtons.show.all" defaultMessage="Show all" />}
                containerClassName={styles['list']}
                {...indicationData}
            />
        </FormField>
    );
};
