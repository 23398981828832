import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { useAccount } from '../../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { useIntl } from '../../../taboola-common-frontend-modules/i18n';
import { useBillingData } from '../../hooks/useBillingData';
import { BalanceSection } from '../BalanceSection/BalanceSection';
import { PaymentMethodsSection } from '../PaymentMethodsSection/PaymentMethodsSection';
import styles from './balanceAndPayments.module.scss';

const updateDateFormat = 'MMM DD, YYYY HH:mm';

export const BalanceAndPaymentsPanel = () => {
    const { dataUpdatedAt, isFetching } = useBillingData();
    const { currency } = useAccount();
    const { formatMessage } = useIntl();
    const formattedDate = useMemo(() => moment(dataUpdatedAt).format(updateDateFormat), [dataUpdatedAt]);
    const lastUpdateTimeMessage = formatMessage(
        {
            id: 'billingAndPayments.billingManagement.dataUpdatedAt',
            defaultMessage: 'Data updated on',
        },
        {
            date: formattedDate,
        }
    );

    return (
        <div>
            <div className={styles['panel']}>
                <BalanceSection className={styles['balance-section']} currency={currency} />
                <PaymentMethodsSection className={styles['payment-method-section']} currency={currency} />
            </div>
            {isFetching ? null : <div className={styles['last-update-time']}>{lastUpdateTimeMessage}</div>}
        </div>
    );
};
