import React from 'react';
import { MailOutlinedIcon, MediaCampaignIcon } from 'tuui';
import { FormField, RadioGroup, RadioIcon } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { VIDEO_CREATIVE_CREATION_METHOD } from '../../../video-creative-creator/config/CreationMethod';
import styles from './VideoCreationTypeField.module.scss';

const CREATIVE_CREATION_METHOD_CONFIG = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: {
        title: 'CREATE NEW VIDEO',
        icon: <MediaCampaignIcon />,
    },
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VAST]: {
        title: 'INSERT A VAST URL',
        icon: <MailOutlinedIcon />,
    },
};

export const VideoCreationTypeField = ({ onChange, selected, options }) => {
    return (
        <FormField
            label={
                <FormattedMessage
                    id="video.creative.creator.setup.creation.mode.title"
                    defaultMessage="Creation Type"
                />
            }
            containerClass={styles['container']}
            labelContainerClass={styles['label-container']}
            description={
                <FormattedMessage
                    id="video.creative.creator.setup.creation.mode.description"
                    defaultMessage="upload MP4 file & tags to create Taboola Vast."
                />
            }
            helpText={
                <FormattedMessage
                    id="video.creative.creator.setup.creation.mode.helptext"
                    defaultMessage="In order to run a video campaign on Taboola, you must create a Taboola Vast tag. In order to generate a Taboola vast tag, you can upload an MP4 video or paste your existing 3rd party VPAID or VAST tag. Please note viewability can only be measured via VPAID only."
                />
            }
        >
            <RadioGroup className={styles['group']} selectedValue={selected} onChange={onChange}>
                {options.map(key => {
                    return (
                        <div key={key} className={styles['radio-container']}>
                            <RadioIcon
                                title={
                                    <FormattedMessage
                                        id={`video.creative.creator.creation.type.${key}`}
                                        defaultMessage={CREATIVE_CREATION_METHOD_CONFIG[key].title}
                                    />
                                }
                                iconComponent={CREATIVE_CREATION_METHOD_CONFIG[key].icon}
                                value={key}
                                labelClassName={styles['radio']}
                                titleClassName={styles['title']}
                            />
                        </div>
                    );
                })}
            </RadioGroup>
        </FormField>
    );
};
