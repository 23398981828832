import React from 'react';
import { SpeakerIcon } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useFormFieldValue } from '../../../../../taboola-common-frontend-modules/formData';
import SectionHeader from '../../../../components/Form/SectionHeader/SectionHeader';
import { VIDEO_CREATIVE_CREATION_METHOD } from '../../../video-creative-creator/config/CreationMethod';
import { VideoCreativeCreatorLandingPageField } from './LandingPageField/VideoCreativeCreatorLandingPageField';
import { VASTTagField } from './VASTTagField/VASTTagField';
import { VastUrlPreviewField } from './VastUrlPreviewField/VastUrlPreviewField';

const contentMethodAditionalDataMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoCreativeCreatorLandingPageField,
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VAST]: VASTTagField,
};
export const VideoCreativeSetupSectionEditMode = () => {
    const { value: videoCreationType } = useFormFieldValue({
        field: 'mediaType',
    });
    const { value: adId } = useFormFieldValue({
        field: 'id',
    });

    const AdditionalDataComponent = contentMethodAditionalDataMap[videoCreationType];

    return (
        <CommonCollapsibleCard
            id="VIDEO_CREATIVE_SETUP"
            header={
                <SectionHeader
                    headerIcon={<SpeakerIcon />}
                    headerText={<FormattedMessage id="video.creative.creator.setup" defaultMessage="Setup" />}
                />
            }
        >
            <AdditionalDataComponent />
            <VastUrlPreviewField adId={adId} />
        </CommonCollapsibleCard>
    );
};
