import { Radio } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../../taboola-common-frontend-modules/i18n';
import { BID_STRATEGIES } from '../../../../config';
import { CpcCapInput } from '../CpcCapInput/CpcCapInput';
import styles from '../applyCapSection.module.scss';

export const CpcCapRadio = ({ bidStrategy }) => {
    return (
        <Radio
            childrenContainerClassName={styles['radio-child']}
            title={<FormattedMessage id="campaign.editor.maxConversions.cpcCap" defaultMessage="Cpc Cap" />}
            description={
                <FormattedMessage
                    id="campaign.editor.maxConversions.cpcCap.description"
                    defaultMessage="Set the maximum CPC you're willing to bid in the auction. Note that this might limit your scale."
                />
            }
            value={BID_STRATEGIES.MAX_CONVERSIONS}
        >
            {bidStrategy === BID_STRATEGIES.MAX_CONVERSIONS && <CpcCapInput />}
        </Radio>
    );
};
