import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAudiencesApi } from 'services/api/audiencesApi';
import { COMMON_FLAGS, useCommonConfig } from '../../../../taboola-common-frontend-modules/account-configurations';
import { useFormDataContext, useFormFieldValue } from '../../../../taboola-common-frontend-modules/formData';
import { useCountriesField } from '../../SegmentTopics/hooks/useCountriesField';

const STALE_TIME = 10 * 60 * 1000;

export const useCombinedSegmentsSizeEstimateApi = isInputValid => {
    const { [COMMON_FLAGS.COMBINED_SEGMENTS_REACH_THRESHOLD]: impressionsThreshold } = useCommonConfig([
        COMMON_FLAGS.COMBINED_SEGMENTS_REACH_THRESHOLD,
    ]);
    const { [COMMON_FLAGS.COMBINED_SEGMENTS_REACH_LIMIT]: reachLimit } = useCommonConfig([
        COMMON_FLAGS.COMBINED_SEGMENTS_REACH_LIMIT,
    ]);

    const { value: audiences } = useFormFieldValue({
        field: 'audienceSetup',
    });
    const { value: platforms } = useFormFieldValue({
        field: 'platforms',
    });
    const { value: countries } = useCountriesField();

    const combinedSegmentComponents = useMemo(
        () =>
            audiences.map(({ audiences, relation }) => {
                return { uddIds: audiences, relation: relation === 'EXCLUDE' ? 'NOT' : relation };
            }),
        [audiences]
    );

    const {
        formAccount: { accountId: selectedAccountId },
    } = useFormDataContext();
    const queryKey = useMemo(
        () => ['getCombinedSegmentsSizeEstimate', combinedSegmentComponents, platforms, countries],
        [platforms, countries, combinedSegmentComponents]
    );
    const { getCombinedSegmentsSizeEstimate } = useAudiencesApi();

    const { data, isLoading, isSuccess } = useQuery(
        queryKey,
        () =>
            getCombinedSegmentsSizeEstimate(selectedAccountId, {
                combinedSegmentComponents,
                platforms,
                countries,
            }),
        {
            refetchOnWindowFocus: false,
            staleTime: STALE_TIME,
            enabled: !!isInputValid,
        }
    );

    return {
        ...data,
        isInputValid,
        isLoading,
        isSmallReach: data && data.lowerBound < impressionsThreshold,
        isReachOverLimit: data && data.lowerBound > reachLimit,
        reachLimit,
        isError: !isLoading && (!isSuccess || !data || data.lowerBound === undefined),
        reachThreshold: impressionsThreshold,
    };
};
