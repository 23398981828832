import { useCallback } from 'react';
import { BareDropdown, CollapsibleList, PaginatedDropdown } from 'taboola-ultimate-ui';
import { useFormDataContext, useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { PerformanceRuleItem } from './PerformanceRuleItem';
import { useAccountPerformanceRules } from './hooks/useAccountPerformanceRules';
import { useCampaignPerformanceRules } from './hooks/useCampaignPerformanceRules';
import styles from './campaignPerformanceRulesDropdown.module.scss';

const performanceRuleDropDownOption = ({ data: { ruleName } }) => ruleName;

export const CampaignPerformanceRulesDropDown = () => {
    const {
        data: { id: campaignId },
        formAccount: { accountId: accountName },
    } = useFormDataContext();

    const { formatMessage } = useIntl();
    const { value: performanceRulesValue, onChange: setPerformanceRules } = useFormFieldValue({
        field: 'performanceRuleIds',
    });

    const { loadPerformanceRules, options, setOptions } = useAccountPerformanceRules(
        accountName,
        performanceRulesValue
    );
    const { campaignRules } = useCampaignPerformanceRules(campaignId, accountName, performanceRulesValue);

    const addItem = useCallback(
        item => {
            const { id } = item;
            setPerformanceRules(prev => [...prev, id]);
        },
        [setPerformanceRules]
    );

    const deleteItem = useCallback(
        ({ id }) => {
            setPerformanceRules(prev => prev.filter(ruleId => ruleId !== id));
        },
        [setPerformanceRules]
    );

    return (
        <>
            <PaginatedDropdown
                id="performance-rule-selector"
                containerClassName={styles['container']}
                onChange={addItem}
                totalItemCount={options ? options.length : null}
                searchable
                disabled={!options.length}
                enabledWhileSearching
                options={options}
                loadPage={loadPerformanceRules}
                placeholder={formatMessage({
                    id: 'performance.rules.select.in.campaign.placeholder',
                    defaultMessage: 'Select rule...',
                })}
                onOptionsLoaded={setOptions}
                DropdownMenuComponent={BareDropdown}
                optionItemRenderer={performanceRuleDropDownOption}
                selectedValueObject={null}
            />
            <CollapsibleList
                items={campaignRules}
                metadata={{ options }}
                containerClassName={styles['lists']}
                data-automation="performance-rule-collapsible-list"
                ItemComponent={PerformanceRuleItem}
                deleteItem={deleteItem}
            />
        </>
    );
};
