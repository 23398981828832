export const computeBidModificationFromPercent = percent => {
    const percentValue = percent || 0;
    return roundToMillionths(percentValue / 100 + 1);
};

export const computeBidModificationFromDecimal = decimal => {
    const decimalValue = decimal || 0;
    return roundToMillionths(decimalValue + 1);
};

export const computeBidModificationPercentFromValue = coefficient =>
    !coefficient || isNaN(coefficient) ? 0 : Math.round((coefficient - 1) * 100);

// floating point precision from float multiplication can result in
// miscalculations with < & >. Sanitizing the floats to 3 digits past the decimal
export const roundToMillionths = val => {
    return parseFloat(parseFloat(val).toFixed(4));
};

export const calcBidModificationActionName = (oldBidMod, newBidMod) => {
    if (oldBidMod) {
        if (newBidMod) {
            return newBidMod >= oldBidMod ? 'Bid boost' : 'Bid deboost';
        } else {
            return oldBidMod > 1 ? 'Bid deboost' : 'Bid boost';
        }
    } else {
        return newBidMod >= 1 ? 'Bid boost' : 'Bid deboost';
    }
};
