import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import normalizeHeaderRenderer from './normalizeHeaderRenderer';

const FormattedMessageHeaderRenderer = ({ messageIdPrefix, field, headerName }) => (
    <FormattedMessage id={`${messageIdPrefix}.${field}`} defaultMessage={headerName} />
);

FormattedMessageHeaderRenderer.propTypes = {
    messageIdPrefix: PropTypes.string,
    // values passed from colDef
    field: PropTypes.string,
    headerName: PropTypes.string,
};

export default normalizeHeaderRenderer(FormattedMessageHeaderRenderer);
