import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DeletableItem, DelimiterWrapper, TooltipV2 as Tooltip, useCollapsibleListContext } from 'taboola-ultimate-ui';
import Section from '../../../campaigns/modules/common-campaign-form/components/ThirdPartyTags/ListItem/Section';
import styles from './pixelBasedUrlConditionListItem.module.scss';

const PixelBasedUrlConditionListItem = ({ item }) => {
    const { deleteItem } = useCollapsibleListContext();

    const onDelete = useCallback(() => deleteItem(item), [deleteItem, item]);

    return (
        <div className={styles['container']}>
            <DeletableItem onDelete={onDelete}>
                <DelimiterWrapper delimiterClassName={styles['delimiter']}>
                    <Section
                        id="audience.editor.pixel.based.audience.conditions.or"
                        defaultMessage="OR"
                        className={styles['or']}
                        loaderWidth={styles.orWidth}
                    />
                    <Section
                        id={`audience.editor.pixel.based.audience.conditions.predicate.${item.predicate}`}
                        className={styles['predicate']}
                        loaderWidth={styles.predicateWidth}
                    />
                    <Section
                        id="audience.editor.pixel.based.audience.conditions.url.fake.id.using.default.message"
                        defaultMessage={item.url}
                        className={styles['url']}
                        tooltip={
                            <Tooltip interactive arrow>
                                {item.url}
                            </Tooltip>
                        }
                        loaderWidth={styles.urlWidth}
                    />
                </DelimiterWrapper>
            </DeletableItem>
        </div>
    );
};

PixelBasedUrlConditionListItem.propTypes = {
    item: PropTypes.shape({
        predicate: PropTypes.string,
        url: PropTypes.string,
    }),
    deleteItem: PropTypes.func,
};

export default PixelBasedUrlConditionListItem;
