import { InformationIcon } from 'tuui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './noCampaignsSelectedIndication.module.scss';

export const NoCampaignsSelectedIndication = () => (
    <div className={styles['indication-wrapper']}>
        <InformationIcon className={styles['indication-icon']} />
        <FormattedMessage
            id="app.create.performance.rules.entities.campaign.none.selected"
            defaultMessage="Pay Attention! The rule does not apply to any campaign, we recommend adding at least one campaign to the rule."
        />
    </div>
);
