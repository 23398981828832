import React, { useMemo, useState } from 'react';
import _, { isEmpty } from 'lodash';
import { CodeIcon } from 'tuui';
import { FormField } from 'taboola-ultimate-ui';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { SectionHeader } from 'modules/campaigns/components';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormNamespaceProvider } from 'modules/taboola-common-frontend-modules/formData/FormNamespaceProvider';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { SelectPixelEventsTypes } from './Components/Pixel/SelectPixelEventsTypes';
import { UrlList } from './Components/Pixel/Urls/UrlList';
import styles from './VideoCreativePixelManagementSection.module.scss';

const getPixelFormattedLabel = pixelType => {
    return <FormattedMessage id={`video.creative.creator.pixel.${pixelType}.label`} defaultMessage={pixelType} />;
};

const defaultSelectedPixels = [
    {
        pixelType: 'impression',
        label: getPixelFormattedLabel('impression'),
        value: 'IMPRESSION',
        order: 1,
    },
];
const pixelEventsTypesList = [
    {
        pixelType: 'impression',
        label: getPixelFormattedLabel('impression'),
        value: 'IMPRESSION',
        order: 1,
    },
    {
        pixelType: 'start',
        label: getPixelFormattedLabel('start'),
        value: 'START',
        order: 2,
    },
    {
        pixelType: 'firstQuartile',
        label: getPixelFormattedLabel('firstQuartile'),
        value: 'FIRST_QUARTILE',
        order: 3,
    },
    {
        pixelType: 'midPoint',
        label: getPixelFormattedLabel('midPoint'),
        value: 'MID_POINT',
        order: 4,
    },
    {
        pixelType: 'thirdQuartile',
        label: getPixelFormattedLabel('thirdQuartile'),
        value: 'THIRD_QUARTILE',
        order: 5,
    },
    {
        pixelType: 'complete',
        label: getPixelFormattedLabel('complete'),
        value: 'COMPLETE',
        order: 6,
    },
    {
        pixelType: 'clickTracking',
        label: getPixelFormattedLabel('clickTracking'),
        value: 'CLICK_TRACKING',
        order: 7,
    },
];

export const VideoCreativePixelManagementSection = () => {
    const { value: pixels } = useFormFieldValue({ field: 'pixels' });
    const selectedPixels = isEmpty(pixels) ? [] : Object.keys(pixels);
    const [types, setTypes] = useState(
        isEmpty(selectedPixels)
            ? defaultSelectedPixels
            : pixelEventsTypesList.filter(pixelType => selectedPixels.includes(pixelType.value))
    );
    const orderedTypes = useMemo(() => _.sortBy(types, [o => o.order]), [types]);

    return (
        <CommonCollapsibleCard
            id="pixelManagementSection"
            header={
                <SectionHeader
                    headerIcon={<CodeIcon className={styles['icon']} />}
                    headerText={
                        <FormattedMessage
                            id="video.creative.creator.pixel.section.label"
                            defaultMessage="Pixel Management"
                        />
                    }
                />
            }
        >
            <SelectPixelEventsTypes value={types} onChange={setTypes} options={pixelEventsTypesList} />
            <FormNamespaceProvider field="pixels">
                {orderedTypes.map(pixelEventType => (
                    <FormField
                        key={pixelEventType.value}
                        label={
                            <FormattedMessage
                                id={`video.creative.creator.pixel.${pixelEventType.pixelType}.label`}
                                defaultMessage={pixelEventType.label}
                            />
                        }
                        helpText={
                            <FormattedMessage
                                id={`video.creative.creator.pixel.${pixelEventType.value}.tooltip`}
                                defaultMessage="Add Pixel To Fire"
                            />
                        }
                        containerClass={styles['input']}
                    >
                        <UrlList
                            dataTestId={`${pixelEventType.value}-test-id`}
                            fieldName={pixelEventType.value}
                            pixelEventType={pixelEventType}
                            className={styles['values-dropdown']}
                        />
                    </FormField>
                ))}
            </FormNamespaceProvider>
        </CommonCollapsibleCard>
    );
};
