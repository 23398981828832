import { invert } from 'lodash';

export const ENTITY = {
    PERFORMANCE_RULES: 'PERFORMANCE_RULES',
    PERFORMANCE_RULE_EXECUTED_ACTIONS: 'PERFORMANCE_RULE_EXECUTED_ACTIONS',
    RULE: 'RULE',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.PERFORMANCE_RULES]: 'performance-rules',
    [ENTITY.PERFORMANCE_RULE_EXECUTED_ACTIONS]: 'performance-rule-executed-actions',
    [ENTITY.RULE]: 'rule',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
