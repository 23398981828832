import { isEmpty, keys } from 'lodash';
import identity from 'lodash/identity';
import { mergeQueryParams } from './queryParamUtils';

export const HISTORY_METHOD = {
    PUSH: 'push',
    REPLACE: 'replace',
};

export class QueryParamBatcher {
    constructor() {
        this.reset();
        this._history = null;
    }

    hasHistory() {
        return !!this._history;
    }

    setHistory(history) {
        this._history = history;
    }

    reset() {
        this._lastQueuedFrame = null;
        this._queuedChanges = {
            [HISTORY_METHOD.PUSH]: {},
            [HISTORY_METHOD.REPLACE]: {},
        };
        this._resolvers = [];
    }

    releaseChange = (change, { method = HISTORY_METHOD.PUSH, paramNamesMap } = {}) => {
        if (!this.hasHistory()) {
            return;
        }

        const { location } = this._history;
        const { search, pathname, hash } = location;
        const newSearch = mergeQueryParams(search, change, paramNamesMap);

        if (newSearch === search) {
            return;
        }

        this._history[method]({
            pathname,
            search: newSearch,
            hash,
        });
    };

    commitChange(paramName, value, { serializer = identity, method, paramNamesMap } = {}) {
        const change = { [paramName]: serializer(value) };
        this.releaseChange(change, { method, paramNamesMap });
    }

    enqueueChange(paramName, value, { serializer = identity, method = HISTORY_METHOD.PUSH, paramNamesMap } = {}) {
        if (!this.hasHistory()) {
            console.error('called query param batcher before history was initialized. Ignoring change');
            return;
        }

        cancelAnimationFrame(this._lastQueuedFrame);

        this._queuedChanges[method][paramName] = serializer(value);

        this._lastQueuedFrame = requestAnimationFrame(() => {
            const resolvers = this._resolvers;
            const changes = this._queuedChanges;
            this.reset();

            keys(this._queuedChanges).forEach(method => {
                const methodChanges = changes[method];
                if (!isEmpty(methodChanges)) {
                    this.releaseChange(methodChanges, { paramNamesMap, method });
                }
            });
            resolvers.forEach(resolve => resolve());
        });

        return new Promise(resolve => {
            this._resolvers.push(resolve);
        });
    }
}
