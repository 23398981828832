import React from 'react';
import CommonCollapsibleCard from 'components/CommonCollapsibleCard/CommonCollapsibleCard';
import { FORM_MODES } from 'config/formModes';
import { SectionHeader } from '../../../campaigns/components';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import AudienceNameField from '../AudienceNameField/AudienceNameField';
import PixelBasedAudienceTypeField from '../PixelBasedAudienceTypeField/PixelBasedAudienceTypeField';

export const PixelBasedAudienceSetupSection = () => {
    const { mode } = useFormFieldValue({});

    return (
        <CommonCollapsibleCard
            id="PIXEL_AUDIENCE_SETUP"
            header={
                <SectionHeader
                    headerText={
                        <FormattedMessage
                            id="audience.editor.audience.setup.section.title"
                            defaultMessage="Audience Setup"
                        />
                    }
                />
            }
        >
            {mode === FORM_MODES.CREATE && <AudienceNameField />}
            <br />
            <PixelBasedAudienceTypeField />
        </CommonCollapsibleCard>
    );
};

export default PixelBasedAudienceSetupSection;
