import { ACCOUNT_ID, DIMENSION } from 'hooks';
import { reportsBaseConfig, REPORT_TYPE, CAMPAIGNS_ROUTE } from '../../../../../config';
import {
    ActionableTextWithInternalLinkCellRenderer,
    FormattedMessageWithTooltipHeaderRenderer,
    gridNumberFormatter,
    formattedNumberValueGetter,
} from '../../../components';
import { DATE_PRESET, END_DATE, START_DATE } from '../../../hooks';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const accountReportColumnDefinition = [
    ...[
        {
            headerName: FIELDS.ACCOUNT_ID.label,
            field: FIELDS.ACCOUNT_ID.field,
        },
        {
            headerName: FIELDS.ACCOUNT_DESCRIPTION.label,
            field: FIELDS.ACCOUNT_DESCRIPTION.field,
            type: [leftPinnedColumn],
            cellRendererFramework: ActionableTextWithInternalLinkCellRenderer,
            cellRendererParams: {
                lines: 2,
                to: CAMPAIGNS_ROUTE,
                paramsToKeep: [DIMENSION, START_DATE, END_DATE, DATE_PRESET],
                paramsFactory: ({ accountId }) => ({
                    [ACCOUNT_ID]: accountId,
                }),
            },
        },
    ],
    ...[
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.CLICKS,
        FIELDS.CONVERSIONS,
        FIELDS.CONVERSIONS_CLICKS,
        FIELDS.CONVERSIONS_VIEWS,
        FIELDS.CPA,
        FIELDS.CPA_CLICKS,
        FIELDS.CPA_VIEWS,
        FIELDS.CVR,
        FIELDS.CVR_CLICKS,
        FIELDS.CVR_VIEWS,
        FIELDS.AVERAGE_CPC,
        FIELDS.VCTR,
        FIELDS.VCPM,
        FIELDS.IMPRESSIONS,
        FIELDS.CPM,
        FIELDS.CTR,
        FIELDS.SPENT,
        FIELDS.CONVERSIONS_VALUE,
        FIELDS.ROAS,
        FIELDS.ROAS_CLICKS,
        FIELDS.ROAS_VIEWS,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        cellRendererFramework: fieldConfig.cellRenderer,
        valueFormatter: !fieldConfig.cellRenderer && gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(accountReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.ACCOUNT].id);
