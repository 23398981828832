import { useMemo } from 'react';
import { BID_STRATEGIES } from '../../../config';
import { bidStrategiesCategory } from '../hooks/useBidStrategiesOptions';

export const useBidStrategyDescriptionMessageId = (value, availableBidStrategies) => {
    const descriptionMessageIdPrefix = 'campaign.editor.bid.strategy.category.description';

    return useMemo(() => {
        if (
            value !== bidStrategiesCategory.MAX_CONVERSIONS ||
            (availableBidStrategies[BID_STRATEGIES.MAX_CONVERSIONS] &&
                availableBidStrategies[BID_STRATEGIES.TARGET_CPA])
        ) {
            return `${descriptionMessageIdPrefix}.${value}`;
        }

        if (availableBidStrategies[BID_STRATEGIES.MAX_CONVERSIONS]) {
            return `${descriptionMessageIdPrefix}.onlyMaxConversions.${value}`;
        }

        return `${descriptionMessageIdPrefix}.onlyTargetCpa.${value}`;
    }, [availableBidStrategies, value]);
};
