import { validationFunctions } from 'modules/taboola-common-frontend-modules/validations';

const lookBackWindowValidations = [
    {
        validationFn: validationFunctions.isNotEmpty,
        messageId: 'audience.editor.validations.error.include.people.from.days.empty',
        defaultMessage: 'Enter a specific timeframe',
    },
    {
        validationFn: validationFunctions.range,
        options: { min: 'minDays', max: 'maxDays' },
        messageId: 'audience.editor.validations.error.include.people.from.days.outside.range',
        defaultMessage: 'Enter a specific timeframe from {minDays} to {maxDays} days',
    },
];

export default lookBackWindowValidations;
