import { Button } from 'tuui';
import { withRibbonFormValidationDataProvider } from '../../../../../../taboola-common-frontend-modules/formData/withRecommendationFormValidationDataProvider';
import { TargetCpaInput } from '../../../../common-campaign-form/components/BidStrategy/TargetCpaRadio/TargetCpaInput';
import { PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE } from '../../../config/performanceRecommendationIndicationType';
import { useUnrealisticTCPARecommendation } from '../../../hooks/useUnrealisticTCPARecommendation';
import AppliedText from '../../AppliedText/AppliedText';
import { ApplyButton } from '../../Buttons/ApplyButton/ApplyButton';
import { PerformanceRecommendationRibbon } from '../PerformanceRecommendationRibbon';
import styles from './unrealisticTCPARibbon.module.scss';

const UnrealisticTCPARibbon = ({ recommendation }) => {
    const { isApplyDisabled, recommendationMetadata, apply, additionalDependencies } = useUnrealisticTCPARecommendation(
        { recommendation }
    );

    return (
        <PerformanceRecommendationRibbon
            recommendation={recommendation}
            isApplied={apply.isSuccess}
            titleCode="performance-recommendations.unrealistic-tcpa.title"
            descriptionCode="performance-recommendations.unrealistic-tcpa.shortDescription"
            messageParams={recommendationMetadata}
            recommendationIndicationType={PERFORMANCE_RECOMMENDATIONS_INDICATION_TYPE.WARNING}
        >
            {apply.isSuccess && (
                <AppliedText successCode="performance-recommendations.general-recommendation.success-message" />
            )}
            <div className={styles['ribbon-input']}>
                <TargetCpaInput
                    containerClass={styles['ribbon-input-container']}
                    disabled={apply.isSuccess}
                    showLabel={false}
                    additionalDependencies={additionalDependencies}
                    shouldUseTooltipMessageIndication={true}
                />
            </div>
            <ApplyButton
                textCode="performance-recommendations.unrealistic-tcpa.action"
                isApplied={apply.isSuccess}
                isApplyLoading={apply.isLoading}
                tooltipCode="performance-recommendations.unrealistic-tcpa.action.tooltip"
                onClick={apply.invoke}
                size={Button.size.lg}
                dataMetricsComponent="RecommendationRibbonActionButton"
                disabled={isApplyDisabled}
                recommendation={recommendation}
            />
        </PerformanceRecommendationRibbon>
    );
};

const UnrealisticTCPARibbonWithForm = withRibbonFormValidationDataProvider(UnrealisticTCPARibbon);
export { UnrealisticTCPARibbonWithForm as UnrealisticTCPARibbon };
