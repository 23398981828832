import { isNil, merge } from 'lodash';
import { immutableMergeWith } from 'modules/taboola-common-frontend-modules/utils/customMergers';
import updateObject from 'modules/taboola-common-frontend-modules/utils/updateObject';

const updateReportDimensionMapOnSuccess = (state, action, dimensionName) => {
    const data = action.payload;

    const id = data.id || data.campaignsGroupId;

    if (isNil(id)) {
        return state;
    }

    const dimensionMap = immutableMergeWith(state[dimensionName], { [id]: data });

    return updateObject(state, { [dimensionName]: dimensionMap });
};

const updateReportDimensionMapOnError = (state, action, dimensionName) => {
    const { id } = action.payload;
    const dimensionMap = merge({}, state[dimensionName], {
        [id]: { ...state[dimensionName][id] },
    });
    return updateObject(state, { [dimensionName]: dimensionMap });
};

export { updateReportDimensionMapOnSuccess, updateReportDimensionMapOnError };
