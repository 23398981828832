import React, { useCallback } from 'react';
import { DropdownMenu } from 'taboola-ultimate-ui';
import { useAvailableItems, useDropdownOptions } from 'hooks';
import { FEATURE_FLAGS, useAccountConfig } from '../../../taboola-common-frontend-modules/account-configurations';
import { useFormFieldValue } from '../../../taboola-common-frontend-modules/formData';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { formFieldId, FormFieldWithIndication, msgIdPrefix } from './ScheduledReportDimensionFieldConsts';
import { dimensionOptions, dspDimensionOptions } from './scheduledReportDimensionFieldOptions';
import styles from './scheduledReportDimensionField.module.scss';

export const ScheduledReportDimensionField = () => {
    const { value: dimension, onChange: setDimension } = useFormFieldValue({ field: 'dimension' });
    const { [FEATURE_FLAGS.SCHEDULED_REPORTS_CAMPAIGN_GROUP_REPORT_ENABLED]: isCampaignGroupReportEnabled } =
        useAccountConfig([FEATURE_FLAGS.SCHEDULED_REPORTS_CAMPAIGN_GROUP_REPORT_ENABLED]);
    const selectOptions = isCampaignGroupReportEnabled === 'true' ? dspDimensionOptions : dimensionOptions;

    const availableOptions = useAvailableItems(selectOptions, {});

    const options = useDropdownOptions(availableOptions, msgIdPrefix);
    const changeHandler = useCallback(
        ({ value: dimension }) => {
            setDimension(dimension);
        },
        [setDimension]
    );
    return (
        <FormFieldWithIndication
            inputId={formFieldId}
            containerClass={styles['dimension-container']}
            label={
                <FormattedMessage
                    id="app.scheduled.reports.create.settings.dimension.title.new"
                    defaultMessage="Report"
                />
            }
            labelClass={styles['label']}
            description={
                <FormattedMessage
                    id="app.scheduled.reports.create.settings.dimension.description.new"
                    defaultMessage="Select a report template to define the report breakdown."
                />
            }
        >
            <div className={styles['dimension']}>
                <DropdownMenu
                    id={formFieldId}
                    selectedValueObject={{
                        value: dimension,
                    }}
                    options={options}
                    onChange={changeHandler}
                    searchable
                    aria-label="dimension-dropdown"
                />
            </div>
        </FormFieldWithIndication>
    );
};
