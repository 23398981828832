import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './MediaPlanItem.module.scss';

const classNameBuilder = classnames.bind(styles);

export const MediaPlanItem = ({ propName, value, children }) => {
    return (
        <div className={classNameBuilder('container')}>
            <ContentLoader width={300} height={30} speed={2} className={styles['loader']}>
                <rect x="0" y="8" rx="2" ry="2" width="200" height="5" />
                <rect x="0" y="22" rx="2" ry="2" width="250" height="5" />
            </ContentLoader>
            <div className={classNameBuilder('content')} key={value}>
                <div className={styles['title']}>
                    <FormattedMessage id={`onboarding.form.media.plan.${propName}`} />:
                </div>
                <div className={styles['value']}>{children}</div>
            </div>
        </div>
    );
};
