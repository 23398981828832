import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { THUMBNAIL_SOURCE_TYPES } from 'modules/campaigns/modules/creative-creator/config';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { useFormDataContext } from 'modules/taboola-common-frontend-modules/formData';
import { resetMediaLibraryItems } from '../../../actions';
import { emptyGalleryTypes } from '../ImageGallery';
import { PaginatedGallery } from '../PaginatedGallery';
import { MediaLibraryGalleryItemDropdown } from './MediaLibraryGalleryItemDropdown';
import { RecentlyUsedMediaTabItem } from './RecentlyUsedMediaTabItem';
import { UploadMediaError } from './UploadMediaError';
import { UrlInputField } from './UrlInputField';
import { useRecentlyUsedMediaSubTabTooltip } from './hooks/useRecentlyUsedMediaSubTabTooltip';
import { useRecentlyUsedMediaSubTab } from './useRecentlyUsedMediaSubTab';
import styles from './recentlyUsedMediaSubTab.module.scss';

const emptyGalleryMessagesPrefix = 'creative.editor.mediaLibrary';

export const RecentlyUsedMediaSubTab = ({
    contentType,
    columnCount,
    onSelectMedia,
    selectMediaHandler: selectMediaHandlerProp,
    value,
    disabled,
}) => {
    const selectMediaHandler = useCallback(
        (data, selected, options = {}) => {
            selectMediaHandlerProp(data, selected, options, THUMBNAIL_SOURCE_TYPES.MEDIA_LIBRARY);
        },
        [selectMediaHandlerProp]
    );
    const dispatch = useDispatch();
    const {
        formAccount: { accountId },
    } = useFormDataContext();
    const {
        items,
        reload,
        loadNextPage,
        isItemLoaded,
        isModuleReady,
        paginationStatus: status,
        totalValues = 0,
        setUploadErrorMessageId,
        uploadErrorMessageId,
        isUploadEnabled,
        onInitUpload,
        onLoadedMedia,
        onRemoveUpload,
    } = useRecentlyUsedMediaSubTab(accountId, { contentType });
    useEffect(() => {
        if (!isModuleReady || !accountId) {
            return;
        }
        dispatch(resetMediaLibraryItems());
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModuleReady, accountId]);

    const { initialGalleryItemTooltip, initialGalleryItemTooltipProps } = useRecentlyUsedMediaSubTabTooltip({
        disabled,
    });

    return (
        <>
            {isUploadEnabled && (
                <UrlInputField
                    initialValue={value}
                    setUploadErrorMessageId={setUploadErrorMessageId}
                    onInitUpload={onInitUpload}
                    onLoadedMedia={onLoadedMedia}
                    onRemoveUpload={onRemoveUpload}
                />
            )}
            <UploadMediaError errorMessageId={uploadErrorMessageId} />
            <div className={styles['image-gallery-container']}>
                <PaginatedGallery
                    accountId={accountId}
                    items={items}
                    itemOnClick={selectMediaHandler}
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadNextPage}
                    totalItemCount={Math.min(totalValues, 180)}
                    scrollbarWidth={11}
                    status={status}
                    urlTransformer={modifyExternalUrl}
                    emptyGalleryType={emptyGalleryTypes.MEDIA_LIBRARY_EMPTY}
                    emptyGalleryMessagesPrefix={emptyGalleryMessagesPrefix}
                    columnCount={columnCount}
                    GalleryItemDropdown={MediaLibraryGalleryItemDropdown}
                    gridItemComponent={RecentlyUsedMediaTabItem}
                    onSelectMedia={onSelectMedia}
                    initialGalleryItemTooltip={initialGalleryItemTooltip}
                    initialGalleryItemTooltipProps={initialGalleryItemTooltipProps}
                />
            </div>
        </>
    );
};
