import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE } from 'taboola-ultimate-ui';
import { useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import styles from './AccountPickerRetryMenuItem.module.scss';

const AccountPickerRetryMenuItem = ({ onRetry }) => {
    const { formatMessage } = useIntl();
    const tryAgainMsg = formatMessage({ id: 'account.picker.placeholder.retry', defaultMessage: 'Try Again' });

    return (
        <div className={styles['container']}>
            <StyledButton
                onClick={onRetry}
                size={STYLED_BUTTON_SIZE.MEDIUM}
                type={STYLED_BUTTON_TYPE.GHOST}
                className={styles['retry-button']}
            >
                {tryAgainMsg}
            </StyledButton>
        </div>
    );
};

AccountPickerRetryMenuItem.propTypes = {
    onRetry: PropTypes.func,
};

export default AccountPickerRetryMenuItem;
