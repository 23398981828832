import React from 'react';
import PropTypes from 'prop-types';
import { isCampaignEditCellDisabled } from '../../config/predicates';
import withEditIndication from './withEditIndication';

const withCampaignEditIndication = WrappedComponent => {
    const ComponentWithEditIndication = withEditIndication(WrappedComponent);

    class CampaignWithEditIndication extends React.Component {
        render() {
            const {
                data,
                forwardedRef,
                colDef,
                colDef: { colId },
                ...rest
            } = this.props;
            const { context } = rest;
            const isEditDisabled = isCampaignEditCellDisabled(data, context?.userPermissions, colId);
            return (
                <ComponentWithEditIndication
                    {...rest}
                    data={data}
                    colDef={colDef}
                    isEditDisabled={isEditDisabled}
                    ref={forwardedRef}
                />
            );
        }
    }

    CampaignWithEditIndication.propTypes = {
        data: PropTypes.object,
        forwardedRef: PropTypes.object,
    };

    return React.forwardRef((props, ref) => <CampaignWithEditIndication {...props} forwardedRef={ref} />);
};

export default withCampaignEditIndication;
