import { isNil } from 'lodash';
import { usePermissions } from '../../../../../../taboola-common-frontend-modules/authentication';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { HIGH_IMPACT_PACKAGE_PERMISSION, NO_HIP_TARGETING_ID } from '../../../config/highImpactPackageOptions';

export const useHighImpactPackageIndication = () => {
    const canUseHighImpactPackage = usePermissions(HIGH_IMPACT_PACKAGE_PERMISSION);
    const { value: highImpactPackage } = useFormFieldValue({
        field: 'campaignTargeting.highImpactPackageTargetingId',
        isPermitted: canUseHighImpactPackage,
    });
    const hasHighImpactPackage = !isNil(highImpactPackage) && highImpactPackage !== NO_HIP_TARGETING_ID;

    return hasHighImpactPackage && canUseHighImpactPackage;
};
