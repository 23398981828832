"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require("react");

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function SvgOverview(props) {
    return React.createElement(
        "svg",
        Object.assign({
            xmlns: "http://www.w3.org/2000/svg",
            width: 24,
            height: 24,
            viewBox: "0 0 24 24",
            fill: "currentColor"
        }, props),
        React.createElement(
            "g",
            { fill: "none", fillRule: "evenodd" },
            React.createElement("path", {
                fill: "currentColor",
                d: "M4.5 13A1.5 1.5 0 0 0 3 14.501V16.5a1.5 1.5 0 1 0 2.999 0v-1.999A1.5 1.5 0 0 0 4.5 13m5-5a1.5 1.5 0 0 0-1.501 1.5v7a1.5 1.5 0 0 0 3 0v-7A1.5 1.5 0 0 0 9.5 8m5 3a1.5 1.5 0 0 0-1.501 1.5v4a1.5 1.5 0 0 0 3 0v-4A1.5 1.5 0 0 0 14.5 11m4.999-6A1.5 1.5 0 0 0 18 6.5v10a1.5 1.5 0 1 0 2.999 0v-10a1.5 1.5 0 0 0-1.5-1.5"
            })
        )
    );
}
exports.default = SvgOverview;