import { useSelector } from 'react-redux';
import encodeurl from 'encodeurl';
import { flatten } from 'lodash';
import useServerCtaData from 'modules/campaigns/modules/creative-creator/components/ContentField/ContentItem/hooks/useServerCtaData';
import { CONTENT_METHODS } from 'modules/campaigns/modules/creative-creator/config';
import { bulkCreateCreatives } from 'modules/campaigns/modules/creative-creator/flows';
import { isFileTypeVideo } from 'modules/campaigns/modules/creative-creator/services/utils';
import { aiTabImagePromptMapSelector } from 'modules/campaigns/modules/creative-editor';
import { reduceAndSortObjectKeys } from 'modules/taboola-common-frontend-modules/utils/objectUtils';
import { ITEM_CREATIVE_TYPE } from '../constants/itemCreativeType';
import { sendVariationsMetrics } from '../services/sendVariationsMetrics';
import { generateCreativeName } from '../services/utils/generateCreativeName';

export const useUnifiedCreativeSaveVariationsHandler = (accountId, data, dispatch, onSuccess) => {
    const {
        thumbnails: thumbnailList,
        creativePreviews: variationsCreativePreviews,
        aiVariationsCreativePreviews,
        recommendationId,
    } = data;

    const ctaData = useServerCtaData();
    const aiImagesPromptData = useSelector(aiTabImagePromptMapSelector);

    const getActivePreviews = creativePreviewList => {
        const previews = Object.values(creativePreviewList);
        const activePreviewArrays = previews.map(urlWidgets =>
            Object.values(urlWidgets).filter(preview => !preview.deleted)
        );

        return flatten(activePreviewArrays);
    };

    return ({ contentMethod }) => {
        const { campaigns, content: variationsContent, aiVariationsContent } = data;
        const creativePreviews =
            contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsCreativePreviews : variationsCreativePreviews;

        const creativePreviewList = reduceAndSortObjectKeys(creativePreviews);
        const content = contentMethod === CONTENT_METHODS.AI_VARIATIONS ? aiVariationsContent : variationsContent;
        const activePreviews = getActivePreviews(creativePreviewList);
        const encodedActivePreviews = activePreviews.map(({ url, ...rest }) => ({
            url: encodeurl(url),
            ...rest,
        }));

        const activeCreatives = activePreviews.map(
            (
                {
                    title,
                    thumbnailUrl,
                    description,
                    cta,
                    url,
                    fallbackImageUrl,
                    gifUrl,
                    mediaUploadSource,
                    recommendedFBImage,
                    motionAdsStudio,
                    creativeFocus,
                    appInstall,
                    rating,
                    logo,
                    creativeCrop,
                    thirdPartyTags,
                    creativeName,
                    customId,
                    fileType,
                },
                index
            ) => {
                const isFileOfTypeVideo = isFileTypeVideo(fileType);
                const thumbUrl = isFileOfTypeVideo ? fallbackImageUrl : thumbnailUrl;
                const itemCreativeType = isFileOfTypeVideo
                    ? ITEM_CREATIVE_TYPE.PERFORMANCE_VIDEO
                    : ITEM_CREATIVE_TYPE.STATIC_IMAGE;
                const performanceVideoData = isFileOfTypeVideo
                    ? {
                          fallbackUrl: fallbackImageUrl,
                          videoUrl: thumbnailUrl,
                          gifUrl,
                          mediaUploadSource,
                          recommendedFallbackImage: recommendedFBImage,
                          motionAdsStudio,
                      }
                    : null;

                return {
                    title,
                    thumbnailUrl: thumbUrl,
                    description,
                    cta,
                    url,
                    creativeFocus,
                    appInstall,
                    rating,
                    logo,
                    creativeCrop,
                    thirdPartyTags,
                    itemCreativeType,
                    performanceVideoData,
                    creativeName: generateCreativeName(activePreviews, creativeName, index),
                    customId,
                };
            }
        );
        sendVariationsMetrics({
            content,
            thumbnailList,
            campaigns,
        });

        return dispatch(
            bulkCreateCreatives({
                accountId,
                selectedCampaigns: campaigns,
                creatives: activeCreatives,
                ctaData,
                encodedActivePreviews,
                aiImagesPromptData,
                recommendationId,
                onSuccess,
            })
        );
    };
};
