import React, { isValidElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import classnames from 'classnames/bind';
import { groupBy, isString, map, uniqueId } from 'lodash';
import { AutoDismissedIndication } from 'taboola-ultimate-ui';
import { APP_EVENT_TYPE, useAppEventContext } from 'modules/taboola-common-frontend-modules/app-events-aggregator';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { gtmTracker } from '../../../gtmTracker';
import { removeIndication } from '../../actions';
import { indicationsListSelector } from '../../selectors/indicationSelectors';
import styles from './indications.module.scss';

const classNameBuilder = classnames.bind(styles);

const getMessageWithTypeKey = ({ message, type }) => {
    if (isString(message)) {
        return `${message}-${type}`;
    }

    if (isValidElement(message) && message.type === FormattedMessage) {
        return `${message.props.id}-${type}`;
    }

    gtmTracker.trackError(new Error('Invalid indication message type: ', message.type));
    return uniqueId('invalid-indication-message');
};

const SingleIndication = props => {
    const { push: pushAppEvent } = useAppEventContext();

    useMount(() => {
        const { type, message } = props;
        const resolvedMessage = isString(message) ? message : message?.props?.defaultMessage;
        pushAppEvent({
            type: APP_EVENT_TYPE.INDICATION,
            status: type,
            id: message?.props?.id,
            value: resolvedMessage,
        });
    });

    return <AutoDismissedIndication {...props} />;
};

export const Indications = ({ className }) => {
    const indications = useSelector(indicationsListSelector);
    const dispatch = useDispatch();
    const handleDismiss = (ids, onDismiss) => () => {
        dispatch(removeIndication(ids.map(id => ({ id }))));
        onDismiss();
    };

    const groupedIndicationsByMessageWithType = groupBy(indications, getMessageWithTypeKey);
    const clusteredIndications = map(groupedIndicationsByMessageWithType, sameTypeIndications => ({
        ...sameTypeIndications[0],
        count: sameTypeIndications.length,
        id: sameTypeIndications.map(indication => indication.id),
    }));

    return (
        <div className={classNameBuilder('indications', className)}>
            {clusteredIndications.map(({ id, onDismiss = () => {}, count, className, ...rest }) => (
                <SingleIndication
                    className={classNameBuilder('indication', className)}
                    key={`${id[0]}-${count}`}
                    onDismiss={handleDismiss(id, onDismiss)}
                    {...(count > 1 ? { highlightPrefix: `(${count})` } : {})}
                    {...rest}
                />
            ))}
        </div>
    );
};

export default Indications;
