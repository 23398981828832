import { useCallback, useEffect, useRef, useState } from 'react';

const defaultTransformer = input => input;
/**
 * @deprecated It should be used useQuery instead
 */
const useFetcher = ({ emptyValue, fetcher, errorIndication, addIndicationFlow, transformer = defaultTransformer }) => {
    const [result, setResult] = useState(emptyValue);
    const destroyFlagRef = useRef(false);
    const safeSetResult = useCallback(
        value => {
            if (destroyFlagRef.current) {
                return;
            }
            setResult(value);
        },
        [setResult]
    );
    const fetcherCallback = useCallback(async () => {
        if (!fetcher) {
            return;
        }
        try {
            const response = await fetcher();

            safeSetResult(transformer(response));
        } catch (error) {
            safeSetResult(emptyValue);
            if (errorIndication) {
                addIndicationFlow(errorIndication);
            }
        }
    }, [fetcher, transformer, addIndicationFlow, errorIndication, emptyValue, safeSetResult]);

    // Here we are calling fetching
    useEffect(() => {
        fetcherCallback();
    }, [fetcherCallback]);

    // Here we track unmounting
    useEffect(
        () => () => {
            destroyFlagRef.current = true;
        },
        [destroyFlagRef]
    );

    return result;
};

export default useFetcher;
