import React from 'react';
import classnames from 'classnames/bind';
import { STYLED_BUTTON_SIZE, STYLED_BUTTON_TYPE, StyledButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useCreativeResubmitButton } from '../../../hooks/useCreativeResubmit';
import { useReportComfortableViewParam } from '../../../hooks/useReportComfortableViewParam';
import styles from './resubmitButton.module.scss';

const classNameBuilder = classnames.bind(styles);

export const ResubmitButton = ({ creativeId }) => {
    const { isEligibleForResubmission, onClick } = useCreativeResubmitButton({
        creativeIds: [creativeId],
    });
    const [isComfortableViewEnabled] = useReportComfortableViewParam();

    return isEligibleForResubmission ? (
        <div
            className={classNameBuilder('container', {
                'comfortable-view': isComfortableViewEnabled,
            })}
        >
            <StyledButton
                className={styles['button']}
                onClick={onClick}
                type={STYLED_BUTTON_TYPE.GHOST}
                size={STYLED_BUTTON_SIZE.SMALL}
            >
                <FormattedMessage id="creative.resubmit.button" defaultMessage="Resubmit" />
            </StyledButton>
        </div>
    ) : null;
};
