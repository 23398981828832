import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const EventNameTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="tracking.conversion.properties.event.name.field.tooltip"
            defaultMessage="This name will appear in the code snippet and will help you or your webmaster recognize it when you implement the code in your site."
        />
    </TooltipSection>
);
