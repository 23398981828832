import { FORM_MODES } from 'config/formModes';
import { BASE_FORM_ROUTE_PATH, FORM_MODE_TO_PATH_MAP } from 'config/routes';
import { replacePathParams } from 'modules/campaigns/services/utils/replacePathParams';
import { PAYMENT_TYPE } from '../PaymentType';
import { ACCOUNT_PARAM_TEMPLATE } from './account';
import { PAYMENT_METHOD_TYPE_TO_PATH_MAP } from './entity';
import { BILLING_AND_PAYMENTS_ROUTE } from './module';
import { PAYMENT_METHOD_ID_PARAM_TEMPLATE } from './paymentMethod';

const KEY_TO_PATH_MAP_MAP = {
    mode: FORM_MODE_TO_PATH_MAP,
    entity: PAYMENT_METHOD_TYPE_TO_PATH_MAP,
};

const INVOICES_AND_PAYMENTS_PATH = `/invoices-and-payments`;
export const EDITOR_ROUTE_PATH = `${BASE_FORM_ROUTE_PATH}${ACCOUNT_PARAM_TEMPLATE}${PAYMENT_METHOD_ID_PARAM_TEMPLATE}`;
export const INVOICES_AND_PAYMENTS_ROUTE = `${BILLING_AND_PAYMENTS_ROUTE}${INVOICES_AND_PAYMENTS_PATH}`;

export const CARD_PAYMENT_METHOD_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [PAYMENT_TYPE.CARD],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const CARD_PAYMENT_METHOD_EDITOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [PAYMENT_TYPE.CARD],
        mode: [FORM_MODES.EDIT],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);

export const ACH_PAYMENT_METHOD_CREATOR_ROUTE_PATH = replacePathParams(
    EDITOR_ROUTE_PATH,
    {
        entity: [PAYMENT_TYPE.ACH_BANK_DIRECT_DEBIT],
        mode: [FORM_MODES.CREATE],
    },
    [],
    KEY_TO_PATH_MAP_MAP
);
