import React from 'react';
import moment from 'moment';
import { Collapsible } from 'taboola-ultimate-ui';
import { useFormFieldValue } from 'modules/taboola-common-frontend-modules/formData';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { COMPONENT_STATUS } from '../../../../services/constants';
import HeaderLoading from '../../../campaigns/modules/common-campaign-form/components/CampaignEditorHeader/HeaderLoading/HeaderLoading';
import styles from './paymentMethodEditorHeader.module.scss';

const dateFormat = 'DD MMMM YYYY';

export const PaymentMethodEditorHeader = ({ fetchStatus, collapsed }) => {
    const { value: lastBillingDate } = useFormFieldValue({ field: 'lastBillingDate' });
    const formattedDate = lastBillingDate ? moment(lastBillingDate).format(dateFormat) : null;

    return fetchStatus !== COMPONENT_STATUS.ACTIVE ? (
        <HeaderLoading />
    ) : (
        <div className={styles['container']}>
            <FormattedMessage id="paymentMethod.editor.title" defaultMessage="Edit Payment" />
            {formattedDate ? (
                <Collapsible collapsed={collapsed}>
                    <div className={styles['billing-date']}>
                        <FormattedMessage
                            id="paymentMethod.editor.lastBillingDate"
                            defaultMessage="Last billing date"
                            values={{ date: formattedDate }}
                        />
                    </div>
                </Collapsible>
            ) : null}
        </div>
    );
};
