import { REPORT_TYPE } from '../../../../../config';
import { createFooterMapper } from '../../../utils/normalizeColumnDefs';
import * as FIELDS from '../../fields';

export const siteReportFooterColumnDefinition = [
    FIELDS.SITE_NAME,
    FIELDS.SITE_ID,
    FIELDS.PUBLISHER_BLOCKING,
    FIELDS.PUBLISHER_BLOCKING_LEVEL,
    FIELDS.BID_MODIFICATION,
    FIELDS.BID_STRATEGY_PER_AFFILIATE,
].map(createFooterMapper(REPORT_TYPE.SITE_REALTIME));
