import React from 'react';

export const Color = props => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 7.77474V16.7368C9.93125 16.7368 8.25 15.0758 8.25 13.0295C8.25 12.0442 8.6375 11.1158 9.34375 10.4147L12 7.77474ZM12 6L8.46875 9.51158C7.5625 10.4147 7 11.6589 7 13.0295C7 15.7768 9.2375 18 12 18C14.7625 18 17 15.7768 17 13.0295C17 11.6589 16.4375 10.4147 15.5312 9.51158L12 6Z"
            fill="#667686"
        />
    </svg>
);
