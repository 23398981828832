import { flatten, identity, keyBy } from 'lodash';
import { REPORT_FIELD_TYPE } from 'modules/campaigns/config';
import { flatDynamicFields } from '../services';

const transformGraphData = (results, metadata, options = {}) => {
    const { graphConfig: { graphIdField, dataViewTransformer = identity, metrics = [] } = {} } = options;
    const flatResults = results.map(({ dynamicFields, ...restRow }) => ({
        ...restRow,
        ...flatDynamicFields(dynamicFields),
    }));
    const referenceLineFields = flatten(metrics.map(({ referenceLines }) => referenceLines).filter(identity));
    const referenceLineFieldsMap = keyBy(referenceLineFields.map(({ field }) => field));

    // Convert any graphData fields (of numeric/money/percent type) that are null to 0, so the fields will be included/visible in graph
    const keysToConvertNulls =
        metadata?.staticFields.filter(
            field =>
                !referenceLineFieldsMap[field.id] &&
                (field.dataType === REPORT_FIELD_TYPE.NUMERIC ||
                    field.dataType === REPORT_FIELD_TYPE.MONEY ||
                    field.dataType === REPORT_FIELD_TYPE.PERCENT)
        ) ?? [];

    const noNullsResult = flatResults.map(data => {
        const newData = { ...data };
        keysToConvertNulls.forEach(({ id }) => (newData[id] = newData[id] || 0));
        return newData;
    });

    const viewTransformedResult = dataViewTransformer(noNullsResult, graphIdField, options);

    return viewTransformedResult;
};

const transformTotal = ({ dynamicFields, ...restTotal } = {}) => ({
    ...restTotal,
    ...flatDynamicFields(dynamicFields),
});

export const transformGraphFromGW = (
    { results, total, metadata, ...rest },
    { maxXAxisValues, displayTotalsInLegendRow = true, graphConfig, startDate } = {}
) => ({
    results: maxXAxisValues
        ? transformGraphData(results, metadata, { graphConfig, startPoint: startDate }).slice(0, maxXAxisValues)
        : transformGraphData(results, metadata, { graphConfig, startPoint: startDate }),
    total: total && displayTotalsInLegendRow ? transformTotal(total) : null,
    metadata,
    ...rest,
});
