/**
 * Created by oreuveni
 * Date: 2019-07-15
 * Time: 10:51
 */
import React from 'react';
import { get, identity } from 'lodash';
import PropTypes from 'prop-types';
import GraphTooltipMetrics from './GraphTooltipMetrics';
import styles from './campaignGraphTooltip.module.scss';

const defaultFormatter = identity;

const CampaignGraphTooltip = ({ label, payload, labelFormatter }) => {
    if (!payload || !payload.length) {
        return null;
    }
    const campaignId = get(payload, '0.payload.id', null);
    return (
        <div className={styles['container']}>
            <div className={styles['label']}>{labelFormatter(label)}</div>
            <div className={styles['sub-label']}>{campaignId && `ID: ${campaignId}`}</div>
            <GraphTooltipMetrics payload={payload} />
        </div>
    );
};

CampaignGraphTooltip.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payload: PropTypes.array,
    labelFormatter: PropTypes.func,
};

CampaignGraphTooltip.defaultProps = {
    labelFormatter: defaultFormatter,
};

export default CampaignGraphTooltip;
