import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ChevronRightOutlinedIcon, ChevronLeftOutlinedIcon } from 'tuui';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms/components';
import { FormattedMessage, useIntl } from 'modules/taboola-common-frontend-modules/i18n';
import { CAMPAIGN_DIMENSION } from '../../../../services/campaignDimension';
import { isOpenExchangeActiveAccount } from '../../../account-management/accountType';
import { useCustomContextualPermitted } from '../../../audiences/hooks/useCustomContextualPermitted';
import { FormButtons } from '../../components';
import { REPORT_TYPE } from '../../config';
import useCampaignBreadcrumbs from '../../hooks/useCampaignBreadcrumbs';
import {
    AudienceTargetingSectionLegacy,
    FormBreadcrumbs,
    NameCreate,
    SetupSection,
    TargetingSection,
    TrackingSection,
    useCampaignCreatorState,
    BudgetSectionOpenExchange,
    TimeFrameSection,
    TrackingCode,
    ContextualTargetingSection,
    AudienceTargetingSection,
} from '../common-campaign-form';
import { openExchangeCampaignInitialValues } from '../common-campaign-form/config';
import commonStyles from '../common-campaign-form/components/commonEditor.module.scss';
import styles from '../sponsored-campaign-creator/sponsoredCampaignCreator.module.scss';

const dimension = CAMPAIGN_DIMENSION.SPONSORED;

const OpenExchangeCampaignCreator = ({ onCancel }) => {
    const { formatMessage } = useIntl();
    const title = formatMessage({
        id: 'campaign.creator.new.open.exchange.campaign',
        defaultMessage: 'New Open Exchange Campaign',
    });
    const { steps } = useCampaignBreadcrumbs({
        selectedReport: REPORT_TYPE.CAMPAIGN,
        title,
    });

    const { step, prevStep, nextStep, handleSetStep, submit, wizardTemplateColumns } = useCampaignCreatorState({
        dimension,
    });
    const accountFilter = useCallback(account => isOpenExchangeActiveAccount(account), []);

    const isCustomContextualPermitted = useCustomContextualPermitted();

    const audiencesTargetingSectionWithWizard = isCustomContextualPermitted ? (
        <>
            <WizardStep
                label={
                    <FormattedMessage id="campaign.editor.contextual.targeting" defaultMessage="Contextual Targeting" />
                }
                id="contextual-targeting"
            >
                <ContextualTargetingSection />
            </WizardStep>
            <WizardStep
                label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
                id="audiences-targeting"
            >
                <AudienceTargetingSection />
            </WizardStep>
        </>
    ) : (
        <WizardStep
            label={<FormattedMessage id="campaign.editor.audience.targeting" defaultMessage="Audience Targeting" />}
            id="audiences-targeting"
        >
            <AudienceTargetingSectionLegacy />
        </WizardStep>
    );

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="campaign.creator.campaign.settings.step.label"
                            defaultMessage="Campaign Settings"
                        />
                    }
                    id="settings"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.setup" defaultMessage="Campaign Setup" />}
                            id="setup"
                        >
                            <SetupSection
                                isAccountSelectionEnabled
                                accountFilter={accountFilter}
                                noAccountsMessageId="campaign.editor.setup.no.open.exchange.accounts"
                            >
                                <NameCreate />
                            </SetupSection>
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.schedule" defaultMessage="Schedule" />}
                            id="schedule"
                        >
                            <TimeFrameSection />
                        </WizardStep>
                        <WizardStep
                            label={
                                <FormattedMessage id="campaign.editor.targeting" defaultMessage="Campaign Targeting" />
                            }
                            id="targeting"
                        >
                            <TargetingSection isIncludeSupplyTargeting={false} />
                        </WizardStep>
                        {audiencesTargetingSectionWithWizard}
                        <FormButtons
                            onSubmit={nextStep}
                            onCancel={onCancel}
                            submitLabel={<FormattedMessage id="app.actionButtons.next" />}
                            submitIcon={<ChevronRightOutlinedIcon />}
                            submitDataMetricsComponent="NextButton"
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            submitButtonType="submit"
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>
                <WizardStep
                    label={
                        <FormattedMessage id="campaign.creator.budget.bidding" defaultMessage="Budget and Bidding" />
                    }
                    id="budgeting"
                >
                    <StaticIndications />
                    <FormBreadcrumbs title={title} steps={steps} className={styles['breadcrumbs-container']} />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.budget" defaultMessage="Budget" />}
                            id="budget"
                        >
                            <BudgetSectionOpenExchange />
                        </WizardStep>
                        <WizardStep
                            label={<FormattedMessage id="campaign.editor.tracking" defaultMessage="Tracking" />}
                            id="tracking"
                        >
                            <TrackingSection>
                                <TrackingCode />
                            </TrackingSection>
                        </WizardStep>
                        <FormButtons
                            onSubmit={submit}
                            onCancel={onCancel}
                            onBack={prevStep}
                            submitLabel={
                                <FormattedMessage
                                    id="campaign.creator.submit.button"
                                    defaultMessage="CREATE CAMPAIGN"
                                />
                            }
                            backLabel={<FormattedMessage id="app.actionButtons.back" />}
                            backIcon={<ChevronLeftOutlinedIcon />}
                            cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            submitButtonType="submit"
                            className={commonStyles['form-buttons']}
                        />
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

OpenExchangeCampaignCreator.propTypes = { onCancel: PropTypes.func };

const OpenExchangeCampaignCreatorWithFormDataProvider = withFormDataProvider(OpenExchangeCampaignCreator, {
    defaultValuesConfig: openExchangeCampaignInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
});

export { OpenExchangeCampaignCreatorWithFormDataProvider as OpenExchangeCampaignCreator };
