import { useCallback, useMemo } from 'react';
import {
    COMMON_FLAGS,
    FEATURE_FLAGS,
    useCommonConfig,
    useConfigMatch,
} from 'modules/taboola-common-frontend-modules/account-configurations';
import { useLocalStorage } from 'modules/taboola-common-frontend-modules/storage';
import { GEN_AI_TOOLTIP_CLOSES_KEY } from '../consts/tryGenAiTooltipConsts';

export const useTryGenAiTooltip = () => {
    const tooltipEnabled = useConfigMatch({
        [FEATURE_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_ENABLED]: 'true',
    });
    const { [COMMON_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES]: maxGenAiTooltipCloseCount } = useCommonConfig([
        COMMON_FLAGS.GEN_AI_EDIT_DUPLICATE_TOOLTIP_MAX_CLOSES,
    ]);
    const [getLocalStorage] = useLocalStorage();

    const maxCloseCount = useMemo(() => {
        let maxGenAiTooltipCloseCountInt = parseInt(maxGenAiTooltipCloseCount);

        if (isNaN(maxGenAiTooltipCloseCount)) {
            maxGenAiTooltipCloseCountInt = 0;
        }

        return maxGenAiTooltipCloseCountInt;
    }, [maxGenAiTooltipCloseCount]);

    const getClosedCount = useCallback(() => {
        let count = parseInt(getLocalStorage(GEN_AI_TOOLTIP_CLOSES_KEY));
        if (isNaN(count)) {
            count = 0;
        }

        return count;
    }, [getLocalStorage]);

    return {
        tooltipEnabled,
        maxCloseCount,
        getClosedCount,
    };
};
