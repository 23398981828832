import React from 'react';
import { Wizard, WizardStep } from 'taboola-ultimate-ui';
import { FormButtons } from 'modules/campaigns/components';
import { StaticIndications } from 'modules/taboola-common-frontend-modules/Indications';
import { withFormDataProvider } from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from 'modules/taboola-common-frontend-modules/forms';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FunnelSetup } from 'modules/tracking/components/FunnelSetup/FunnelSetup';
import { TrackingFormBreadcrumbs } from 'modules/tracking/components/TrackingFormBreadcrumbs/TrackingFormBreadcrumbs';
import { unipFunnelInitialValues } from 'modules/tracking/config/initialValues/unipFunnelInitialValues';
import { useUnipFunnelFormState } from 'modules/tracking/hooks/useUnipFunnelFormState';
import styles from './unipFunnelCreatorPage.module.scss';

const UnipFunnelCreatorPage = ({ onCancel }) => {
    const { step, handleSetStep, submit, wizardTemplateColumns } = useUnipFunnelFormState();

    return (
        <FormInitBase>
            <Wizard
                wizardContentClassName={styles['wizard-content']}
                step={step}
                onStepChange={handleSetStep}
                wizardTemplateColumns={wizardTemplateColumns}
            >
                <WizardStep
                    label={
                        <FormattedMessage
                            id="tracking.unip.funnel.first.step.label"
                            defaultMessage="Conversion Funnel"
                        />
                    }
                    id="conversion-funnel"
                >
                    <StaticIndications />
                    <TrackingFormBreadcrumbs
                        msgId="tracking.unip.funnel.creator.step.label"
                        defaultMessage="Create Conversion Funnel"
                    />
                    <div className={styles['form-wrapper']}>
                        <WizardStep
                            label={
                                <FormattedMessage
                                    id="tracking.unip.funnel.creator.setup.step.title"
                                    defaultMessage="Setup"
                                />
                            }
                            id="funnel-setup"
                        >
                            <FunnelSetup />
                        </WizardStep>
                        <div>
                            <FormButtons
                                className={styles['form-buttons']}
                                onSubmit={submit}
                                onCancel={onCancel}
                                submitLabel={<FormattedMessage id="app.actionButtons.create.unip.funnel" />}
                                cancelLabel={<FormattedMessage id="app.actionButtons.cancel" />}
                            />
                        </div>
                    </div>
                </WizardStep>
            </Wizard>
        </FormInitBase>
    );
};

const UnipFunnelCreatorPageWithFormDataProvider = withFormDataProvider(UnipFunnelCreatorPage, {
    defaultValuesConfig: unipFunnelInitialValues,
    formContainerId: Wizard.WIZARD_CONTAINER_ID,
    isNetworkOwnerAllowed: true,
});

export { UnipFunnelCreatorPageWithFormDataProvider as UnipFunnelCreatorPage };
