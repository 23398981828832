import React from 'react';
import { Indication, INDICATION_TYPES, IndicationContent } from 'taboola-ultimate-ui';
import { FormattedMessage, useIntl } from '../../../../../../taboola-common-frontend-modules/i18n';
import formatNumberCompact from '../../../../../services/utils/formatNumberCompact';
import styles from './reachRange.module.scss';

export const ReachRange = ({
    isSmallReach,
    isError,
    lowerBound,
    upperBound,
    isOnlyLowerBound,
    isReachOverLimit,
    formattedReachLimit,
    reachThreshold,
}) => {
    const { formatMessage } = useIntl();

    if (isError) {
        return (
            <span className={styles['notice']}>
                <Indication
                    type="error"
                    className={styles['notice-container']}
                    message={formatMessage({
                        id: 'campaign.reach.estimator.widget.error',
                        defaultMessage: 'Sorry, we were not able to estimate the potential scale.',
                    })}
                    contentClassName={styles['notice']}
                    displayDismissComponent={false}
                />
            </span>
        );
    }

    if (isSmallReach) {
        return (
            <IndicationContent
                type={INDICATION_TYPES.ERROR}
                containerClassName={styles['notice']}
                contentClassName={styles['notice-content']}
            >
                <FormattedMessage
                    id="campaign.reach.estimator.widget.small.reach.warning.text"
                    defaultMessage="Your targeting is too narrow (estimated viewable impressions are fewer than {reachThreshold}). Expand your targeting to ensure better results."
                    values={{ reachThreshold: formatNumberCompact(reachThreshold, 0) }}
                />
            </IndicationContent>
        );
    }

    if (isReachOverLimit) {
        return (
            <FormattedMessage
                id="campaign.reach.estimator.widget.reach.over.limit"
                defaultMessage="Over {formattedReachLimit}"
                values={{ formattedReachLimit }}
            />
        );
    }
    if (isOnlyLowerBound) {
        return (
            <FormattedMessage
                id="audience.creator.combined.audiences.size.estimation.widget.reach.min"
                defaultMessage="{lowerBound}"
                values={{ lowerBound }}
            />
        );
    }
    return (
        <FormattedMessage
            id="campaign.reach.estimator.widget.reach.min.max"
            defaultMessage="{lowerBound} - {upperBound}"
            values={{ lowerBound, upperBound }}
        />
    );
};
