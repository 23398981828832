import { noop } from 'lodash';
import { FileTypes } from 'taboola-ultimate-ui';
import { modifyExternalUrl } from 'modules/campaigns/services/utils';
import { aiGenerationApi } from 'services/api';
import THUMBNAIL_SOURCE_TYPES from '../../creative-creator/config/thumbnailSourceTypes';

export const fetchAIGeneratedReplacedBackgrounds = async ({
    accountId,
    campaignId,
    imageUrl,
    textPrompt,
    foregroundMask,
    onBeforeLoad = noop,
    onLoad = noop,
    onError = noop,
}) => {
    onBeforeLoad();

    try {
        const { results: response } = await aiGenerationApi.getAIGeneratedReplacedBackgrounds({
            accountId,
            campaignId,
            imageUrl,
            textPrompt,
            foregroundMask,
        });

        const results = response.map(item => ({
            url: item.url,
            id: item.id,
            cdnUrl: modifyExternalUrl(item.url),
            thumbnailUrl: modifyExternalUrl(item.url),
            fileType: FileTypes.IMAGE,
            source: THUMBNAIL_SOURCE_TYPES.STABLE_DIFFUSION_BACKGROUND,
        }));

        onLoad();

        return { results };
    } catch (error) {
        onError(error);
        return { error, results: [] };
    }
};
