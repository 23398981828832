import React from 'react';
import PropTypes from 'prop-types';
import { HelpTooltip, LinkButton } from 'taboola-ultimate-ui';
import { FormattedMessage } from '../../../../../../taboola-common-frontend-modules/i18n';
import styles from './focalPointEditorHeader.module.scss';

const tooltipText =
    'Taboola utilizes an automated cropping solution to tailor each image to customized placement sizes across Taboola’s network. In case the automated cropping is not working well for your image you can choose to set a custom focal point. Choose Custom, drag and drop the focal point and click Preview Placements to view your image across common placements. A landscape-oriented image with an aspect ratio of 16:9, 4:3, or 1:1 is best practice.';

const FocalPointEditorHeader = ({ isResetVisible, onReset }) => (
    <div className={styles['container']}>
        <div className={styles['label-section']}>
            <span className={styles['label']}>
                <FormattedMessage id="focal.point.label" defaultMessage="Focal Point" />
            </span>
            <HelpTooltip>
                <FormattedMessage id="focal.point.tooltip.text" defaultMessage={tooltipText} />
            </HelpTooltip>
        </div>
        {isResetVisible && (
            <LinkButton onClick={onReset}>
                <FormattedMessage id="focal.point.reset" defaultMessage="Reset to default" />
            </LinkButton>
        )}
    </div>
);

FocalPointEditorHeader.propTypes = {
    isResetVisible: PropTypes.bool,
    onReset: PropTypes.func,
};

export default FocalPointEditorHeader;
