import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { useAudiencesApi } from '../../../services/api';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations';
import { useYahooExternalAudiencesFF } from './useYahooExternalAudiencesFF';

export const useRejectedAudiencesIndication = () => {
    const { getRejectedAudiences } = useAudiencesApi();
    const { accountName } = useAccount();
    const rejectedAudiencesIndicationPermitted = useYahooExternalAudiencesFF();

    const {
        data: rejectedAudiences = [],
        isLoading: isLoadingRejectedAudiences,
        isSuccess: isRejectedAudiencesSuccess,
    } = useQuery(['getRejectedAudiences', accountName], () => getRejectedAudiences(accountName), {
        enabled: rejectedAudiencesIndicationPermitted,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return {
        rejectedAudiences,
        isRejectedAudiencesSuccess,
        hasRejectedAudiences:
            !isLoadingRejectedAudiences && !isEmpty(rejectedAudiences) && rejectedAudiencesIndicationPermitted,
    };
};
