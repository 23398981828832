import { reduce } from 'lodash';
import TargetingTypes from 'modules/campaigns/modules/common-campaign-form/config/TargetingTypes';
import { isCampaignTargetingField } from 'modules/campaigns/modules/common-campaign-form/utils/targetingRestrictions/isCampaignTargetingField';
import { CustomValidationError } from 'modules/errors/CustomValidationError';

export const isTargetingRestrictionEnforcedForBrowser = (browser, currentCampaignTargeting) => {
    return reduce(
        browser.targetingRestrictions,
        (acc, restriction) => {
            const { isFieldValid, targetingField } = isCampaignTargetingField(restriction, currentCampaignTargeting);
            return {
                isFieldValid: acc.isFieldValid && isFieldValid,
                targetingField: isFieldValid ? acc.targetingField : [...acc.targetingField, targetingField],
            };
        },
        { isFieldValid: true, targetingField: [] }
    );
};

export const isBrowserTargetingRestrictionEnforced = ({
    item: browserName,
    type,
    listOfBrowsers,
    currentCampaignTargetingByType,
}) => {
    const browser = listOfBrowsers.find(obj => obj.name === browserName);

    if (type !== TargetingTypes.INCLUDE || !browser?.targetingRestrictions || !currentCampaignTargetingByType) {
        return true;
    }
    const { isFieldValid, targetingField } = isTargetingRestrictionEnforcedForBrowser(
        browser,
        currentCampaignTargetingByType
    );
    if (isFieldValid) {
        return isFieldValid;
    }

    const targetingFieldDescription = targetingField.join(', ');
    const verb = targetingField.length > 1 ? 'are' : 'is';

    throw new CustomValidationError({
        messageCode: 'campaign.editor.targeting.browser.unsupported.browser.targeting',
        message: `Please note: ${targetingFieldDescription} targeting ${verb} not supported by ${browserName}`,
        templateParameters: { browserName, targetingFieldDescription, verb },
    });
};
