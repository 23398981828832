import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { metrics, MetricsElement } from 'react-metrics';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import classnames from 'classnames/bind';
import { invoke } from 'lodash';
import { TooltipV2Root as TooltipRoot, useIsMobile } from 'taboola-ultimate-ui';
import 'taboola-ultimate-ui/lib/styles/index.css';
import styles from 'app.module.scss';
import { RouteList } from 'components';
import AppHeader from 'components/AppHeader';
import config from 'config';
import { useAdBlockDetector, useAppReady, useDocumentTitle, usePageName } from 'hooks';
import { useHugeChangesVersion } from 'hooks/useHugeChangesVersion';
import { ErrorPage } from 'modules/errors';
import Indications from 'modules/taboola-common-frontend-modules/Indications';
import { useCookieConsent } from 'modules/taboola-common-frontend-modules/consent';
import { TextChangeTranslateDetector, useLocale } from 'modules/taboola-common-frontend-modules/i18n';
import { isPhraseEnabled } from 'modules/taboola-common-frontend-modules/i18n/phrase';
import { intercomService } from 'modules/taboola-common-frontend-modules/intercom/services/IntercomService';
import { useMemoryControl } from 'modules/taboola-common-frontend-modules/memory/useMemoryControl';
import { Modal } from 'modules/taboola-common-frontend-modules/modals';
import { useAppVersion } from 'modules/taboola-common-frontend-modules/version/hooks/useAppVersion';
import { ChatAgent } from './components/./ChatAgent/ChatAgent';
import { ContextMenuProvider } from './components/CustomContextMenu/ContextMenuContext';
import { CustomContextMenu } from './components/CustomContextMenu/CustomContextMenu';
import { routingConfigStore } from './config/routingConfigStore';
import { useLogRocketHotKey } from './hooks/useLogRocketHotKey';
import { useSyncedAppState } from './hooks/useSyncedAppState';
import { useOnboardingFlow } from './modules/onboarding';
import { useAuth } from './modules/taboola-common-frontend-modules/authentication/hooks/useAuth';
import { useParentAppName } from './modules/taboola-common-frontend-modules/i18n/hooks/useParentAppName';
import { useIframeService } from './modules/taboola-common-frontend-modules/iframe/hooks/useIframeService';
import { isIframeMode } from './modules/taboola-common-frontend-modules/iframe/utils/isIframeMode';
import { useNetworkIndication } from './modules/taboola-common-frontend-modules/network-inication/useNetworkIndication';
import { useDebugQueryParam } from './services/useDebugQueryParam';
import yahooStyle from './taboola-ultimate-ui.yahoo.theme.module.scss';

const classNameBuilder = classnames.bind(styles);

const themeClassMap = {
    yahoo: {
        appHeaderContainerClass: yahooStyle['app-header-container'],
        appHeaderBackgroundClass: yahooStyle['app-header-background'],
        appHeaderIconsClass: yahooStyle['app-header-icons'],
        appHeaderActiveItemClassName: yahooStyle['active-nav-item'],
        appHeaderBrandingClass: yahooStyle['header-branding'],
        appHeaderButtonsPanelClass: yahooStyle['app-header-buttons-panel'],
        navItemsContainerClass: yahooStyle['nav-items-container'],
        navitemContainerClass: yahooStyle['nav-item-container'],
    },
};

routingConfigStore.setValue(config);

const App = () => {
    const parentThemeClass = useParentAppName();
    const error = useSelector(state => state.errorReducer.error);
    const [locale] = useLocale();
    const pageName = usePageName();
    const isAppReady = useAppReady();
    const loading = !isAppReady;
    const defaultTooltipConfig = useMemo(() => (isPhraseEnabled() ? { delay: [150, 7500] } : {}), []);
    const iFrameMode = isIframeMode();
    const bodyClass = `${parentThemeClass} ${yahooStyle[parentThemeClass] ? yahooStyle[parentThemeClass].trim() : ''}`;

    useAuth();
    useAppVersion();
    useHugeChangesVersion();
    useCookieConsent();
    useIframeService();
    useMemoryControl();
    const online = useNetworkIndication();

    useAdBlockDetector();
    useSyncedAppState();
    const title = useDocumentTitle();
    useDebugQueryParam();
    useOnboardingFlow();
    useLogRocketHotKey();

    // We stop bodymovin animation to avoid extra CPU consuming
    useEffect(() => {
        const bodyMovinState = loading ? 'play' : 'stop';
        invoke(window, `bodymovin.${bodyMovinState}`);
    }, [loading]);

    // Hide intercom icon on mobile
    const isMobile = useIsMobile();
    const isIntercomBooted = intercomService.isServiceBooted();
    useEffect(() => {
        if (!isIntercomBooted) {
            return;
        }
        if (isMobile) {
            intercomService.hideIcon();
        } else {
            intercomService.showIcon();
        }
    }, [isMobile, isIntercomBooted]);

    if (!error) {
        return loading ? null : (
            <>
                <div className={classNameBuilder({ 'offline-glass': !online })} />
                <TextChangeTranslateDetector />
                <MetricsElement key={locale}>
                    <ContextMenuProvider>
                        <TooltipRoot
                            className={classNameBuilder('container', { offline: !online })}
                            data-metrics-page-name={pageName}
                            {...defaultTooltipConfig}
                        >
                            <Helmet htmlAttributes={{ lang: locale }}>
                                <title>{title}</title>
                                <body className={bodyClass} />
                            </Helmet>
                            <AppHeader
                                height={styles.headerHeight.replace('px', '')}
                                config={config}
                                showButtonsPanel={!iFrameMode}
                                {...themeClassMap[parentThemeClass]}
                            />
                            <div className={classNameBuilder('content-wrapper')}>
                                <Modal />
                                <Indications className={classNameBuilder('indications')} />
                                <RouteList routes={config.routingMap} />
                            </div>
                            <ChatAgent />
                        </TooltipRoot>
                        {iFrameMode && <CustomContextMenu />}
                    </ContextMenuProvider>
                </MetricsElement>
            </>
        );
    }

    return (
        <MetricsElement>
            <>
                <Indications className={classNameBuilder('indications')} />
                <ErrorPage config={config} />
            </>
        </MetricsElement>
    );
};

const { config: metricsConfig, options: metricsOptions } = config.metrics;

const AppWithMetrics = metrics(metricsConfig, metricsOptions)(App);
const ConnectedAppWithMetricsWithRouter = withRouter(AppWithMetrics);

export default ConnectedAppWithMetricsWithRouter;
