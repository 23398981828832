import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import {
    FormattedMessageWithTooltipHeaderRenderer,
    formattedNumberValueGetter,
    gridNumberFormatter,
} from '../../../components';
import { normalizeColumnDefs } from '../../../utils';
import { leftPinnedColumn, numericColumn } from '../../columns';
import * as FIELDS from '../../fields';

const contextReportColumnDefinition = [
    {
        headerName: FIELDS.CONTEXT.label,
        field: FIELDS.CONTEXT.field,
        label: 'Context',
        type: [leftPinnedColumn],
    },
    {
        headerName: FIELDS.VIEWABILITY_RATE_VS_AVG.label,
        field: FIELDS.VIEWABILITY_RATE_VS_AVG.field,
        cellRendererFramework: FIELDS.VIEWABILITY_RATE_VS_AVG.cellRenderer,
        headerComponentParams: FIELDS.VIEWABILITY_RATE_VS_AVG.headerComponentParams,
        headerValueGetter: FIELDS.VIEWABILITY_RATE_VS_AVG.headerValueGetter,
    },
    {
        headerName: FIELDS.COMPLETION_RATE_VS_AVG.label,
        field: FIELDS.COMPLETION_RATE_VS_AVG.field,
        cellRendererFramework: FIELDS.COMPLETION_RATE_VS_AVG.cellRenderer,
        headerComponentParams: FIELDS.COMPLETION_RATE_VS_AVG.headerComponentParams,
        headerValueGetter: FIELDS.COMPLETION_RATE_VS_AVG.headerValueGetter,
    },
    ...[
        FIELDS.IMPRESSIONS,
        FIELDS.IMPRESSIONS_PCT,
        FIELDS.VISIBLE_IMPRESSIONS,
        FIELDS.COMPLETED_VIEWS,
        FIELDS.VIEWABILITY_RATE,
        FIELDS.COMPLETION_RATE,
    ].map(fieldConfig => ({
        headerName: fieldConfig.label,
        headerValueGetter: fieldConfig.headerValueGetter || FormattedMessageWithTooltipHeaderRenderer,
        headerComponentParams: fieldConfig.headerComponentParams,
        field: fieldConfig.field,
        type: [numericColumn],
        valueFormatter: gridNumberFormatter,
        cellRendererParams: fieldConfig.renderParams,
        csvValueGetter: fieldConfig.csvValueGetter || formattedNumberValueGetter,
        referenceColId: fieldConfig.referenceColId,
        permissions: fieldConfig.permissions,
        visibilityCondition: fieldConfig.visibilityCondition,
    })),
];

export default normalizeColumnDefs(contextReportColumnDefinition, reportsBaseConfig[REPORT_TYPE.CONTEXTUAL_VIDEO].id);
