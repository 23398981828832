import React from 'react';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import { FormattedMessage } from '../../../taboola-common-frontend-modules/i18n';
import { FormLayout } from '../../components/Form';
import { REPORT_TYPE } from '../../config';
import { VideoCreativeContentSection } from '../video-campaigns-form/components/VideoCreativeContentSection/VideoCreativeContentSection';
import { VideoCreativeCreatorPreview } from '../video-campaigns-form/components/VideoCreativeCreatorPreview/VideoCreativeCreatorPreview';
import { VideoCreativePixelManagementSection } from '../video-campaigns-form/components/VideoCreativePixelManagementSection/VideoCreativePixelManagementSection';
import { VideoCreativeSetupSectionEditMode } from '../video-campaigns-form/components/VideoCreativeSetupSection/VideoCreativeSetupSectionEditMode.new';
import { VideoLogoSection } from '../video-campaigns-form/components/VideoLogoSection/VideoLogoSection';
import { VideoMediaFileUploadSection } from '../video-campaigns-form/components/VideoMediaFileUploadSection/VideoMediaFileUploadSection';
import { VIDEO_CREATIVE_CREATION_METHOD } from '../video-creative-creator/config/CreationMethod';
import VideoCreativeEditorHeader from './VideoCreativeEditorHeader';
import { useVideoCreativeEditorState } from './hooks';
import styles from './videoCreativeEditor.new.module.scss';

const creationMethodContentComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoCreativeContentSection,
};

const creationMethodLogoComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoLogoSection,
};

const creationMethodMediaUploadComponentMap = {
    [VIDEO_CREATIVE_CREATION_METHOD.FROM_VIDEO]: VideoMediaFileUploadSection,
};

const EmptyComponent = () => null;

const VideoCreativeEditor = ({ onCancel, mode }) => {
    const { submitInProgress, isSubmitDisabled, fetchStatus } = useFormDataContext();
    const { submit } = useVideoCreativeEditorState({
        reportType: REPORT_TYPE.CAMPAIGN_VIDEO,
    });

    const { value: videoCreationType } = useFormFieldValue({
        field: 'mediaType',
    });

    const LogoComponent = creationMethodLogoComponentMap[videoCreationType] || EmptyComponent;
    const ContentComponent = creationMethodContentComponentMap[videoCreationType] || EmptyComponent;
    const MediaUploadComponent = creationMethodMediaUploadComponentMap[videoCreationType] || EmptyComponent;

    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                submitButtonText={<FormattedMessage id={`app.actionButtons.${mode}.save`} defaultMessage="Save" />}
                submitInProgress={submitInProgress}
                disableSubmit={isSubmitDisabled}
                contentContainerClassName={styles['content']}
                bodyContainerClassName={styles['content-body']}
                footerContainerClassName={styles['content-footer']}
            >
                <FormLayout
                    className={styles['section-container']}
                    headerContainerClassName={styles['editor-header']}
                    contentContainerClassName={styles['content-container']}
                    bodyContainerClassName={styles['body-container']}
                    header={<VideoCreativeEditorHeader status={fetchStatus} headerClassName={styles['header']} />}
                    hideFooter
                >
                    <div className={styles['flex-content']}>
                        <VideoCreativeSetupSectionEditMode />
                        <ContentComponent />
                        <MediaUploadComponent />
                        <LogoComponent />
                        <VideoCreativePixelManagementSection />
                    </div>
                </FormLayout>
                <VideoCreativeCreatorPreview />
            </FormLayout>
        </FormInitBase>
    );
};
const VideoCreativeEditorWithFormDataProvider = withFormDataProvider(VideoCreativeEditor, {
    formContainerId: FormLayout.BODY_CONTAINER_ID,
});

export { VideoCreativeEditorWithFormDataProvider as VideoCreativeEditor };
