import React from 'react';
import { identity } from 'lodash';
import PropTypes from 'prop-types';
import withValidations from './withValidations';

/**
 * @deprecated Please consider to use useFormValidatedValue
 */
const withContainerValidations = (
    ContainerComponent,
    validations = [],
    itemsPropName,
    itemsTransformer = identity,
    options
) => {
    const ValidationsWrapper = withValidations(ContainerComponent, {
        validations,
        validateOnValueChange: true,
        options,
    });
    const ContainerWithValidations = ({ id, [itemsPropName]: items, ...rest }) => (
        <ValidationsWrapper
            valueToValidate={itemsTransformer(items)}
            validationId={id}
            id={id}
            {...{ [itemsPropName]: items }}
            {...rest}
        />
    );

    ContainerWithValidations.propTypes = {
        onValidationChange: PropTypes.func,
        triggerValidation: PropTypes.bool,
        id: PropTypes.string.isRequired,
    };

    return ContainerWithValidations;
};

export default withContainerValidations;
