import { isEmpty } from 'lodash';
import { ALL_MEDIA_FILES, ONLY_IMAGE_FILES } from 'modules/campaigns/modules/creative-creator/config/fileTypeConfigs';
import { usePermissions } from 'modules/taboola-common-frontend-modules/authentication';

export const useThumbnailsPickerData = thumbnailList => {
    const isMotionPermitted = usePermissions('ADS_CONSOLE_PERFORMANCE_VIDEO');
    const fileTypes = !isMotionPermitted ? ONLY_IMAGE_FILES : ALL_MEDIA_FILES;
    const isEmptyThumbnailList = isEmpty(thumbnailList);

    return { fileTypes, isEmptyThumbnailList };
};
