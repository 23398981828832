import React from 'react';
import { TooltipSection } from 'modules/campaigns/modules/common-campaign-form';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';

export const DynamicParametersTooltip = () => (
    <>
        <TooltipSection>
            <FormattedMessage
                id="tracking.dynamic.field.tooltip.1"
                defaultMessage="With dynamic conversion parameters, you can take your marketing strategy a step further, by more accurately tracking Taboola’s value to your business. Dynamic values are used when you want to assign a different value each time the same conversion happens. So in this case you may value a purchase of one product at $10 and another product at $20. Learn more"
            />
        </TooltipSection>
        <TooltipSection>
            <FormattedMessage
                id="tracking.dynamic.field.tooltip.2"
                defaultMessage="Dynamic values are used when you want to assign a different value each time the same conversion happens. So in this case you may value a purchase of one product at $10 and another product at $20. <a>Learn more</a>"
            />
        </TooltipSection>
    </>
);
