import React, { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { BlockIcon, OkOIcon } from 'taboola-ultimate-ui';
import { FORM_MODES } from 'config/formModes';
import { useFormFieldValue } from '../../../../../../taboola-common-frontend-modules/formData';
import { useValidations } from '../../../../../../taboola-common-frontend-modules/validations/hooks/useValidations';
import TargetingTypes from '../../../config/TargetingTypes';
import { SegmentDetails } from '../SegmentDetails/SegmentDetails';
import { itemValidations } from '../customContextualSegmentsValidations';
import commonStyles from '../../commonAudienceListItemContent.module.scss';

export const CustomContextualSegmentListItemContent = ({ item, deleteItem, targetingType, isLoading }) => {
    const { label = '', id, isActive } = item;

    const handleOnClick = () => {
        deleteItem({ id, targetingType });
    };

    const { isDirty, mode } = useFormFieldValue({
        field: `campaignTargeting.customContextualTargeting.${targetingType.toLowerCase()}`,
    });

    const valueToValidate = useMemo(() => [label, id, isActive], [label, id, isActive]);

    const { failedValidationData = {} } = useValidations({
        validations: itemValidations,
        validationId: `CustomContextualSegmentListItemContent.${id}`,
        valueToValidate,
        validationDependencies: {},
        isDirty,
        isReady: !isLoading,
        hasInitialData: mode !== FORM_MODES.CREATE,
    });

    if (isLoading) {
        return (
            <ContentLoader width={580} height={32} speed={2}>
                <rect x="10" y="12" rx="8" ry="8" width="250" height="12" />
                <rect x="510" y="12" rx="8" ry="8" width="60" height="12" />
            </ContentLoader>
        );
    }

    return (
        <div className={commonStyles['container']} aria-label="custom-contextual-segment-list-item-content">
            {targetingType === TargetingTypes.INCLUDE ? (
                <OkOIcon className={commonStyles['include']} />
            ) : (
                <BlockIcon className={commonStyles['exclude']} />
            )}
            <SegmentDetails name={label} handleOnClick={handleOnClick} {...failedValidationData} />
        </div>
    );
};
