import React, { useContext, useEffect, useMemo, useState } from 'react';

const CreativeStudioContext = React.createContext({});

export const CreativeStudioContextProvider = ({
    children,
    src,
    mode,
    setMode,
    galleryItemDropdownOptions,
    accountId,
    campaignId,
    selectedImageUrl,
    setSelectedImageUrl,
    addImageHandler,
    originalItem,
    urlTransformer,
    formAccount,
    segmentMask,
    setSegmentMask,
}) => {
    const [originalImage, setOriginalImage] = useState({ id: src, url: src });

    useEffect(() => {
        // if the src is different than the prev originalImage src, update the originalImage
        //  (helps prevent originalImage from changing on mount)
        setOriginalImage(prev => (prev?.url === src ? prev : { id: src, url: src }));
    }, [src]);

    const value = useMemo(
        () => ({
            mode,
            setMode,
            accountId,
            campaignId,
            galleryItemDropdownOptions,
            selectedImageUrl,
            setSelectedImageUrl,
            originalImage,
            setOriginalImage,
            addImageHandler,
            originalItem,
            formAccount,
            urlTransformer,
            segmentMask,
            setSegmentMask,
        }),
        [
            mode,
            setMode,
            accountId,
            campaignId,
            formAccount,
            galleryItemDropdownOptions,
            selectedImageUrl,
            setSelectedImageUrl,
            originalImage,
            addImageHandler,
            originalItem,
            urlTransformer,
            segmentMask,
            setSegmentMask,
        ]
    );

    return <CreativeStudioContext.Provider value={value}>{children}</CreativeStudioContext.Provider>;
};
export const useCreativeStudioContext = () => useContext(CreativeStudioContext);
