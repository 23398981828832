import getChangesObject from '../../../../taboola-common-frontend-modules/utils/objectDiff';
import { forceCampaignPickerReload } from '../../../actions';
import { saveCampaignsGroup } from '../../campaigns-group/flows';
import { editedCampaignsGroupSelector } from '../../campaigns-group/selectors';

export const updateCampaignGroup = (accountName, campaignsGroup, api) => (dispatch, getState) => {
    const campaignsGroupBeingEdited = editedCampaignsGroupSelector(getState());
    const campaignsGroupChanges = getChangesObject(campaignsGroup, campaignsGroupBeingEdited);

    const { campaignsGroupId } = campaignsGroup;

    return dispatch(
        saveCampaignsGroup(
            accountName,
            campaignsGroupId,
            campaignsGroupChanges,
            null,
            () => {
                dispatch(forceCampaignPickerReload());
            },
            api
        )
    );
};
