import { useSelectedAccount } from 'hooks';
import { isProgrammaticAccount, isVideoAccount, isNetworkAccountType } from 'modules/account-management/accountType';
import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { useHasOnboardingExpired } from './useHasOnboardingExpired';

export const useIsOnboardingEnabled = () => {
    const [accountData] = useSelectedAccount();
    const { firstCampaignStartDate, partnerTypes, type } = accountData;

    const hasOnboardingExpired = useHasOnboardingExpired(firstCampaignStartDate);
    const isOnboardingEnabledFF = useConfigMatch({
        [FEATURE_FLAGS.ONBOARDING_ASSISTANT_ENABLED]: 'true',
    });

    const isFirstCampaignStartDateLoading = firstCampaignStartDate === undefined;
    if (isFirstCampaignStartDateLoading) {
        return false;
    }

    const isValidAccountType =
        !isProgrammaticAccount(accountData) && !isVideoAccount(partnerTypes) && !isNetworkAccountType(type);

    const isEnabled = isOnboardingEnabledFF && !hasOnboardingExpired && isValidAccountType;

    return isEnabled;
};
