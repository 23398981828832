'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _bind = require('classnames/bind');

var _bind2 = _interopRequireDefault(_bind);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var styles = {
    'rootFontSize': '10px',
    'fontSizeXSmall': '0.8rem',
    'fontSizeSmaller': '1.1rem',
    'fontSizeSmall': '1.2rem',
    'fontSizeMediumSmall': '1.3rem',
    'fontSizeMedium': '1.4rem',
    'fontSizeLarge': '1.6rem',
    'fontSizeLarger': '1.8rem',
    'fontSizeXLarge': '2rem',
    'fontSizeXxLarge': '3.2rem',
    'maxWidthMobile': '480px',
    'defaultBorderThickness': '1px',
    'taboola-svg-icon': 'badge__taboola-svg-icon___1_1fV',
    'container': 'badge__container___10Szo',
    'badge': 'badge__badge___TAmrM',
    'top-right': 'badge__top-right___2Llze',
    'top-left': 'badge__top-left___3HA6z',
    'bottom-right': 'badge__bottom-right___3CC2D',
    'bottom-left': 'badge__bottom-left___1M10l',
    'info': 'badge__info___39p3F',
    'warning': 'badge__warning___2kiij',
    'error': 'badge__error___1jr_P'
};


var classNameBuilder = _bind2.default.bind(styles);

var POSITION = {
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left'
};

var TYPE = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
};

/**
 * @deprecated - use Badge from tuui
 */
var Badge = function Badge(_ref) {
    var _classNameBuilder;

    var badgeContent = _ref.badgeContent,
        hideBadge = _ref.hideBadge,
        children = _ref.children,
        position = _ref.position,
        type = _ref.type,
        contentClassName = _ref.contentClassName;

    var containerClass = classNameBuilder('container');
    var badgeClass = classNameBuilder('badge', contentClassName, (_classNameBuilder = {}, _defineProperty(_classNameBuilder, position, position), _defineProperty(_classNameBuilder, type, type), _classNameBuilder));

    return _react2.default.createElement(
        'div',
        { className: containerClass },
        children,
        !hideBadge && _react2.default.createElement(
            'span',
            { className: badgeClass },
            badgeContent
        )
    );
};

Badge.propTypes = {
    children: _propTypes2.default.node,
    badgeContent: _propTypes2.default.node,
    hideBadge: _propTypes2.default.bool,
    position: _propTypes2.default.oneOf(Object.values(POSITION)),
    type: _propTypes2.default.oneOf(Object.values(TYPE)),
    contentClassName: _propTypes2.default.string
};

Badge.defaultProps = {
    position: POSITION.TOP_RIGHT,
    type: TYPE.INFO
};

Badge.TYPE = TYPE;
Badge.POSITION = POSITION;

exports.default = Badge;