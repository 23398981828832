import { invert } from 'lodash';

export const ENTITY = {
    ACCOUNT: 'ACCOUNT',
};

export const ENTITY_TO_PATH_MAP = {
    [ENTITY.ACCOUNT]: 'account',
};

export const PATH_TO_ENTITY_MAP = invert(ENTITY_TO_PATH_MAP);
