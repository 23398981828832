import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import columnDefinitions from './columnDefinitions';
import footerColumnDefinitions from './footerColumnDefinitions';
import graph from './graph';
import { defaultColumns, customColumns } from './reportPresets';

export const dmaReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.DMA],
    endpoint: 'campaign-report-by-dma-performance',
    graph,
    rowIdField: FIELDS.DMA_CODE.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    footerColumnDef: footerColumnDefinitions,
    searchPlaceholderMsgId: 'app.reports.byDMA.search.placeholder',

    // TODO: DEV-58584 need to convert filter names in the GW. replace searchField + remove sortMap
    searchField: 'dmaName',
    sortMap: {
        [FIELDS.DMA_CODE.field]: 'dma',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.AVERAGE_CPC.field]: 'cpc',
        [FIELDS.CONVERSIONS.field]: 'cpaActionsNum',
        [FIELDS.CONVERSIONS_CLICKS.field]: 'cpaActionsNumFromClicks',
        [FIELDS.CONVERSIONS_VIEWS.field]: 'cpaActionsNumFromViews',
        [FIELDS.CVR.field]: 'cpaConversionRate',
        [FIELDS.CVR_CLICKS.field]: 'cpaConversionRateClicks',
        [FIELDS.CVR_VIEWS.field]: 'cpaConversionRateViews',
    },
};

export default dmaReportConfig;
