import React from 'react';
import { Button, UndoIcon } from 'tuui';
import {
    useFormDataContext,
    useFormFieldValue,
    withFormDataProvider,
} from 'modules/taboola-common-frontend-modules/formData';
import { GTM_EVENTS } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { FormInitBase } from '../../../taboola-common-frontend-modules/forms';
import FormLayout from '../../components/Form/FormLayout';
import { CarouselCardList } from '../creative-creator/components/CarouselCardList/CarouselCardList';
import { CarouselEditorHeader } from './components/CarouselEditorHeader/CarouselEditorHeader';
import { useCreativeCarouselEditorState } from './hooks/useCreativeCarouselEditorState';
import styles from './CreativeCarouselEditor.module.scss';

const UndoAllChangesButton = () => {
    const { hasNoEditChanges } = useFormDataContext();
    const { resetFieldToInitial: resetCardsToInitial } = useFormFieldValue({ field: 'carouselCards' });

    return (
        <Button
            variant={Button.variant.text}
            disabled={hasNoEditChanges}
            className={styles['undo-button']}
            aria-label="Undo Button"
            data-metrics-event-name={GTM_EVENTS.USABILITY}
            data-metrics-component="CreativeCarouselEditor - Edit Carousel- Undo Form Changes"
            onClick={() => {
                resetCardsToInitial();
            }}
        >
            <div className={styles['undo-content']}>
                <UndoIcon />
                <span className={styles['undo-message']}>
                    <FormattedMessage
                        id="creative.editor.carousel.cards.undo.all.changes"
                        defaultMessage="Undo All Changes"
                    />
                </span>
            </div>
        </Button>
    );
};

const CreativeCarouselEditor = ({ match, onCancel }) => {
    const { campaignId, hierarchyRepItemId } = match.params;
    const { isSubmitDisabled } = useFormDataContext();

    const { submit } = useCreativeCarouselEditorState({ campaignId, hierarchyRepItemId });
    return (
        <FormInitBase>
            <FormLayout
                onCancel={onCancel}
                onSubmit={submit}
                contentContainerClassName={styles['form-container']}
                bodyContainerClassName={styles['form-body']}
                footerContainerClassName={styles['form-footer']}
                header={<CarouselEditorHeader hierarchyRepItemId={hierarchyRepItemId} />}
                disableSubmit={isSubmitDisabled}
            >
                <CarouselCardList className={styles['cards']} />
                <UndoAllChangesButton />
            </FormLayout>
        </FormInitBase>
    );
};

const CreativeCarouselEditorWithFormDataProvider = withFormDataProvider(CreativeCarouselEditor);

export { CreativeCarouselEditorWithFormDataProvider as CreativeCarouselEditor };
