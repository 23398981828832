import { REPORT_TYPE, reportsBaseConfig } from '../../../../../config';
import * as FIELDS from '../../fields';
import { REPORT_PRESET } from '../../pickers';
import { columnDefinitions } from './columnDefinitions';
import { customColumns, defaultColumns } from './reportPresets';

export const customContextualReportConfig = {
    ...reportsBaseConfig[REPORT_TYPE.CUSTOM_CONTEXTUAL],
    endpoint: 'custom-contextual-report',
    rowIdField: FIELDS.ID.field,
    columnsDef: columnDefinitions,
    presets: {
        [REPORT_PRESET.DEFAULT]: { columns: defaultColumns },
    },
    customPreset: { columns: customColumns, initialState: defaultColumns },
    isEmptyFooter: true,
    searchPlaceholderMsgId: 'app.reports.custom-contextual.search.placeholder',

    // TODO: DEV-58584 need to convert filter names in the GW. replace sortField + remove sortMap
    // searchField: NAME.field
    searchField: FIELDS.SEGMENT_NAME.field,
    sortMap: {
        [FIELDS.NAME.field]: FIELDS.SEGMENT_NAME.field,
    },
};
