import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'modules/taboola-common-frontend-modules/i18n';
import { useModal } from 'modules/taboola-common-frontend-modules/modals';
import { errorMessagesUtils } from '../../../services/utils';
import { addIndication, INDICATION_TYPES } from '../../taboola-common-frontend-modules/Indications';
import { useAccount } from '../../taboola-common-frontend-modules/account-configurations/hooks/useAccount';
import { useBillingActions } from './useBillingData';

export const successIndication = ({ paymentMethod }) => ({
    message: (
        <FormattedMessage
            id={`app.payments.cancel.payment.${paymentMethod}.success.highlight`}
            defaultMessage="Funds canceled successfully."
        />
    ),
    type: INDICATION_TYPES.SUCCESS,
    highlight: <FormattedMessage id="success.highlight" defaultMessage="Success!" />,
});

export const generateErrorIndication = ({ paymentMethod, errorObject = {} }) => ({
    message: errorMessagesUtils.getErrorMessage(errorObject),
    type: INDICATION_TYPES.ERROR,
    highlight: (
        <FormattedMessage
            id={`app.payments.cancel.payment.${paymentMethod}.error.highlight`}
            defaultMessage="Unable to cancel the funds."
        />
    ),
});

export const useCancelFunds = ({ paymentId, paymentMethod }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { accountId, accountName } = useAccount();
    const { open: openModal } = useModal();
    const { cancelFunds: requestCancelFunds } = useBillingActions();

    const refreshBillingDataQueries = useCallback(() => {
        queryClient.invalidateQueries(['getBillingData', accountId]);
        queryClient.invalidateQueries(['getPaymentsReport', accountId]);
    }, [queryClient, accountId]);

    return useCallback(async () => {
        const result = await openModal({
            title: (
                <FormattedMessage
                    id={`app.payments.cancel.payment.${paymentMethod}.confirmation.title`}
                    defaultMessage="Cancel Funds?"
                    tagName="h2"
                />
            ),
            content: (
                <FormattedMessage
                    id={`app.payments.cancel.payment.${paymentMethod}.confirmation.message`}
                    defaultMessage="You are about to cancel payment. Do you wish to proceed?"
                />
            ),
            formProps: {
                submitButtonText: (
                    <FormattedMessage
                        id={`app.payments.cancel.payment.${paymentMethod}.confirmation.actionButtons.CONTINUE`}
                        defaultMessage="Proceed"
                    />
                ),
                cancelButtonText: (
                    <FormattedMessage
                        id={`app.payments.cancel.payment.${paymentMethod}.confirmation.actionButtons.BACK`}
                        defaultMessage="Cancel"
                    />
                ),
            },
        });

        if (result) {
            try {
                await requestCancelFunds({ accountId: accountName, fundId: paymentId });
                dispatch(addIndication(successIndication({ paymentMethod })));
                refreshBillingDataQueries();
            } catch (error) {
                dispatch(addIndication(generateErrorIndication({ paymentMethod, errorObject: error })));
            }
        }
    }, [openModal, dispatch, accountName, paymentId, paymentMethod, requestCancelFunds, refreshBillingDataQueries]);
};
