import { useCallback } from 'react';
import { GTM_EVENTS, gtmTracker } from '../../../../taboola-common-frontend-modules/gtmTracker';

export const useTopicsPreviewTracker = () => {
    const reportTopicsPreviewEvents = useCallback((value, pageName) => {
        gtmTracker.trackEvent(GTM_EVENTS.USABILITY, {
            value,
            component: 'Topics Preview',
            pageName,
        });
    }, []);

    const reportTopicsUniquePublishers = useCallback(
        (articleSamples, topicName) => {
            if (!articleSamples || articleSamples.length === 0) {
                return;
            }

            const uniquePublishers = new Set(articleSamples.map(article => article.logo_url));
            reportTopicsPreviewEvents(
                `${uniquePublishers.size} Unique Publishers for ${articleSamples.length} Articles, Topic Name: ${topicName}`,
                'Topics Preview Drawer'
            );
        },
        [reportTopicsPreviewEvents]
    );

    const reportTermsUniquePublishers = useCallback(
        (articles, numOfArticles) => {
            if (!articles || articles.length === 0) {
                return;
            }

            const uniquePublishers = new Set(articles.map(article => article.logo_url));
            const topics = Array.from(new Set(articles.map(article => article.term))).join(', ');
            reportTopicsPreviewEvents(
                `${uniquePublishers.size} Unique Publishers for ${numOfArticles} Articles, Topics: [${topics}]`,
                'Topics Preview Whether Card'
            );
        },
        [reportTopicsPreviewEvents]
    );

    return {
        reportTopicsPreviewEvents,
        reportTopicsUniquePublishers,
        reportTermsUniquePublishers,
    };
};
