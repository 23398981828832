import { getLocationSearch, mergeQueryParams } from 'modules/taboola-common-frontend-modules/query-params';
import { REPORT_FORCE_FETCH_FLAG } from '../../campaigns/modules/campaigns-reports/hooks/useReportForceFetchFlag';
import { SEGMENT } from '../hooks';
import { SELECT_SEGMENT } from './actionTypes';

export default selectedSegment => ({
    type: SELECT_SEGMENT,
    payload: selectedSegment,
    meta: {
        query: mergeQueryParams(getLocationSearch(), {
            [SEGMENT]: selectedSegment.uddId,
            [REPORT_FORCE_FETCH_FLAG]: Date.now(),
        }),
    },
});
