import { TARGETING_TYPES } from '../../campaigns/modules/common-campaign-form/config';

const uddIdsTransformer = recommendationMetadata => {
    const { uddIdsString } = recommendationMetadata;
    const include = uddIdsString.split(',').filter(Boolean).map(Number);

    return {
        campaignTargeting: {
            marketplaceAudienceTargeting: {
                collection: [{ type: TARGETING_TYPES.INCLUDE, values: include }],
                validTargeting: true,
            },
        },
    };
};

export default uddIdsTransformer;
