import React from 'react';
import { FormattedMessage } from '../../../../../taboola-common-frontend-modules/i18n';
import { TooltipSection } from '../../../common-campaign-form';

export const PreferredViewabilityRateTooltip = () => (
    <TooltipSection>
        <FormattedMessage
            id="video.campaign.editor.preferredViewabilityRate.tooltip"
            defaultMessage="An automated optimization which ensures the campaign will reach at least a certain viewability rate. Recommended for CPM campaigns only."
        />
    </TooltipSection>
);

export default PreferredViewabilityRateTooltip;
