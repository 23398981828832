import { useCallback } from 'react';
import { gtmTracker } from 'modules/taboola-common-frontend-modules/gtmTracker';
import { GIS_SRC_URL } from '../constants';
import { useLoadScript } from './useLoadScript';

export const useLoadGIS = ({ clientId, scopes }) => {
    const { isSuccess } = useLoadScript(GIS_SRC_URL);

    const requestToken = useCallback(
        () =>
            // Settle this promise in the response callback for requestAccessToken()
            new Promise((resolve, reject) => {
                try {
                    if (!clientId) {
                        reject();
                    }

                    // we add a focus event listener here to resolve this promise if the user closes the
                    // google sign-in popup
                    // found on this post: https://stackoverflow.com/questions/72387245/google-identity-service-oauth2-detect-if-consent-pop-up-is-closed
                    const focusEventHandler = () => {
                        reject({
                            error: 'client_focused_back_to_window',
                        });
                        window.removeEventListener('focus', focusEventHandler); // removing the event listener to avoid memory leaks
                    };
                    // adding an event listener to detect if user is back to the webpage
                    // if the user "focus" back to window then we shall close the current auth session
                    window.addEventListener('focus', focusEventHandler);

                    const tokenClient = window.google.accounts.oauth2.initTokenClient({
                        client_id: clientId,
                        scope: scopes.join(' '),
                        callback: resp => {
                            if (resp.error !== undefined) {
                                reject(resp);
                            }
                            // GIS has automatically updated gapi.client with the newly issued access token.
                            resolve(resp);
                        },
                    });
                    tokenClient.requestAccessToken();
                } catch (e) {
                    gtmTracker.trackError(e);
                    console.error(e);
                    reject(e);
                }
            }),
        [clientId, scopes]
    );

    const getToken = useCallback(() => {
        return window.gapi?.client?.getToken();
    }, []);

    const revokeToken = useCallback(() => {
        const token = getToken();
        if (token !== null) {
            window.google.accounts?.oauth2.revoke(token.access_token);
            window.gapi?.client?.setToken(null);
        }
    }, [getToken]);

    const getIsPermitted = useCallback(() => {
        const token = getToken();
        return token ? scopes.every(scope => token.scope.includes(scope)) : false;
    }, [getToken, scopes]);

    return { getToken, revokeToken, isSuccess, getIsPermitted, requestToken };
};
