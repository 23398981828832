import {
    AI_VARIATIONS_EVENTS_PREFIX,
    AI_VARIATIONS_WIZARD_EVENTS_PREFIX,
} from '../../../constants/aiVariationsConstants';
import { useHasAiWizardOnboarding } from './useHasAiWizardOnboarding';

export const useAiVariationsEventsPrefix = () => {
    const hasAiWizardOnboarding = useHasAiWizardOnboarding();
    if (hasAiWizardOnboarding) {
        return AI_VARIATIONS_WIZARD_EVENTS_PREFIX;
    }
    return AI_VARIATIONS_EVENTS_PREFIX;
};
