import { reportsBaseConfig, REPORT_TYPE } from '../../../../../config';
import { generateColumnId } from '../../../utils';
import * as FIELDS from '../../fields';

const reportId = reportsBaseConfig[REPORT_TYPE.CATEGORY].id;

// columns used by the 'All' column view
const defaultColumns = [
    FIELDS.CATEGORY.field,
    FIELDS.AUDIENCE_INDEX.field,
    FIELDS.BASELINE_INDEX.field,
    FIELDS.COMPARE.field,
].map(field => generateColumnId(reportId, field));

export { defaultColumns };
