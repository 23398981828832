'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

exports.PaginatedDropdownMenuList = PaginatedDropdownMenuList;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactWindowInfiniteLoader = require('react-window-infinite-loader');

var _reactWindowInfiniteLoader2 = _interopRequireDefault(_reactWindowInfiniteLoader);

var _reactWindow = require('react-window');

var _lodash = require('lodash.sum');

var _lodash2 = _interopRequireDefault(_lodash);

var _PaginatedDropdownContext = require('./PaginatedDropdownContext');

var _PaginatedDropdownPlaceholder = require('./PaginatedDropdownPlaceholder');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var WindowedList = (0, _react.forwardRef)(function (_ref, ref) {
    var options = _ref.options,
        rest = _objectWithoutProperties(_ref, ['options']);

    var listRef = (0, _react.useRef)();
    var setRefs = (0, _react.useCallback)(function (node) {
        if (typeof ref === 'function') {
            ref(node);
        }
        listRef.current = node;
    }, [ref]);
    (0, _react.useLayoutEffect)(function () {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [options]);

    return _react2.default.createElement(_reactWindow.VariableSizeList, Object.assign({ ref: setRefs }, rest));
});

WindowedList.propTypes = {
    className: _propTypes2.default.string,
    height: _propTypes2.default.number,
    itemCount: _propTypes2.default.number,
    estimatedItemSize: _propTypes2.default.number,
    itemSize: _propTypes2.default.func,
    onItemsRendered: _propTypes2.default.func,
    options: _propTypes2.default.array,
    children: _propTypes2.default.func
};

function PaginatedDropdownMenuList(_ref2) {
    var options = _ref2.options,
        children = _ref2.children,
        maxHeight = _ref2.maxHeight,
        placeholderProps = _ref2.placeholderProps;

    var _usePaginatedDropdown = (0, _PaginatedDropdownContext.usePaginatedDropdownContext)(),
        fontSize = _usePaginatedDropdown.fontSize,
        paddingX = _usePaginatedDropdown.paddingX,
        paddingY = _usePaginatedDropdown.paddingY,
        estimatedHeight = _usePaginatedDropdown.estimatedHeight,
        itemHeight = _usePaginatedDropdown.itemHeight,
        totalItemCount = _usePaginatedDropdown.totalItemCount,
        loadNextPage = _usePaginatedDropdown.loadNextPage,
        isItemLoaded = _usePaginatedDropdown.isItemLoaded,
        calculateTextHeight = _usePaginatedDropdown.calculateTextHeight;

    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        usingScrollbar = _useState2[0],
        setUsingScrollbar = _useState2[1];

    var scrollerRef = (0, _react.useRef)(null);

    (0, _react.useLayoutEffect)(function () {
        if (scrollerRef.current) {
            scrollerRef.current.resetloadMoreItemsCache(true);
        }
    }, [options]);

    var heightRefCache = (0, _react.useRef)({});
    (0, _react.useLayoutEffect)(function () {
        heightRefCache.current = {};
    }, [usingScrollbar]);

    var itemSize = (0, _react.useCallback)(function (index) {
        if (itemHeight) {
            return itemHeight;
        }

        var option = options[index] || {};
        if (!option.label) {
            return estimatedHeight;
        }

        if (!heightRefCache.current[option.label]) {
            heightRefCache.current[option.label] = calculateTextHeight(option.label, usingScrollbar);
        }
        return heightRefCache.current[option.label];
    }, [options, usingScrollbar]);

    var listHeight = (0, _react.useMemo)(function () {
        if (estimatedHeight * totalItemCount > maxHeight) {
            return maxHeight;
        }
        var itemSizes = Array.from({
            length: totalItemCount
        }).map(function (_, idx) {
            return itemSize(idx);
        });
        // padded for borders
        var sumOfSizes = (0, _lodash2.default)(itemSizes) + 2;
        if (sumOfSizes > maxHeight) {
            setUsingScrollbar(true);
        }
        return Math.min(maxHeight, sumOfSizes);
    }, [itemSize, totalItemCount, maxHeight, estimatedHeight]);

    if (!totalItemCount) {
        return children;
    }

    return _react2.default.createElement(
        _reactWindowInfiniteLoader2.default,
        {
            ref: scrollerRef,
            isItemLoaded: isItemLoaded,
            itemCount: totalItemCount,
            loadMoreItems: loadNextPage
        },
        function (_ref3) {
            var onItemsRendered = _ref3.onItemsRendered,
                ref = _ref3.ref;
            return _react2.default.createElement(
                WindowedList
                // used for automation compatibility
                ,
                { className: 'virtual-menu-list',
                    ref: ref,
                    height: listHeight,
                    itemCount: totalItemCount,
                    estimatedItemSize: estimatedHeight,
                    itemSize: itemSize,
                    onItemsRendered: onItemsRendered,
                    options: options
                },
                function (_ref4) {
                    var index = _ref4.index,
                        style = _ref4.style;
                    return _react2.default.createElement(
                        'div',
                        { style: style },
                        isItemLoaded(index) ? children[index] : _react2.default.createElement(_PaginatedDropdownPlaceholder.PaginatedDropdownPlaceholder, Object.assign({
                            fontSize: fontSize,
                            paddingX: paddingX,
                            paddingY: paddingY
                        }, placeholderProps))
                    );
                }
            );
        }
    );
}
PaginatedDropdownMenuList.propTypes = {
    options: _propTypes2.default.array,
    children: _propTypes2.default.oneOfType([_propTypes2.default.array, _propTypes2.default.node]),
    maxHeight: _propTypes2.default.number,
    placeholderProps: _propTypes2.default.shape({
        fontSize: _propTypes2.default.number,
        paddingX: _propTypes2.default.number,
        paddingY: _propTypes2.default.number,
        className: _propTypes2.default.string,
        loaderClassName: _propTypes2.default.string,
        preserveAspectRatio: _propTypes2.default.string
    }),
    getValue: _propTypes2.default.func
};

exports.default = PaginatedDropdownMenuList;