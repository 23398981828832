import { isEmpty } from 'lodash';
import { TARGETING_RESTRICTION_TYPES } from 'modules/campaigns/modules/common-campaign-form/config/TatgetingRestrictionTyps';

const targetingTypesToFieldNames = [
    { type: 'DMA', typeName: 'dma', field: 'DMATargeting', restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED },
    {
        type: 'REGION',
        typeName: 'region',
        field: 'regionTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'CITY',
        typeName: 'city',
        field: 'cityTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'ZIP_CODE',
        typeName: 'zip code',
        field: 'postalCodeTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'CONNECTION_TYPE',
        typeName: 'connection type (wifi)',
        field: 'connectionTypeTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'CONTEXTUAL_CATEGORIES',
        typeName: 'contextual categories',
        field: 'contextualCategoriesTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'TOPIC_SEGMENTS',
        typeName: 'topic segments',
        field: 'topicSegmentsTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED,
    },
    {
        type: 'AUDIENCE',
        typeName: 'audience',
        field: 'audienceIncludeTargeting',
        restrictionType: TARGETING_RESTRICTION_TYPES.UNSUPPORTED_INCLUDE,
    },
];

export const isCampaignTargetingField = (targetingRestriction, currentCampaignTargeting) => {
    if (!currentCampaignTargeting || !targetingRestriction) {
        return { isFieldValid: true };
    }
    const { restrictionType, targetingType } = targetingRestriction;
    if (restrictionType === TARGETING_RESTRICTION_TYPES.REQUIRED) {
        return { isFieldValid: true };
    }

    for (const typeToCheck of targetingTypesToFieldNames) {
        if (targetingType === typeToCheck.type && restrictionType === typeToCheck.restrictionType) {
            const targetingField = currentCampaignTargeting[typeToCheck.field];

            if (!isEmpty(targetingField)) {
                return {
                    isFieldValid: false,
                    targetingField: typeToCheck.typeName,
                };
            }
        }
    }

    return { isFieldValid: true };
};
