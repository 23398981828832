import { FEATURE_FLAGS, useConfigMatch } from 'modules/taboola-common-frontend-modules/account-configurations';
import { SupplyTargetingSelectField } from './components/SupplyTargetingSelectField/SupplyTargetingSelectField';
import { SupplyTargetingField } from './components/SupplyTargettingDDField/SupplyTargetingField';
import { useIsSupplyTargetingSelectPermitted } from './hooks/useIsSupplyTargetingSelectPermitted';

export const SupplyTargeting = () => {
    const isSupplyPermitted = useConfigMatch({ [FEATURE_FLAGS.SUPPLY_TARGETING_ENABLED]: 'true' });
    const isTaboolaSelectEnabled = useIsSupplyTargetingSelectPermitted();
    const isPermitted = isSupplyPermitted || isTaboolaSelectEnabled;
    if (!isPermitted) {
        return null;
    }
    return isSupplyPermitted ? <SupplyTargetingField /> : <SupplyTargetingSelectField />;
};
